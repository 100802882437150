import React, { useEffect, useState } from "react";
import { Box, Typography, styled } from "@mui/material";
import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress";

import PersonaCard from "./PersonaCard";
import { LandscapeOutlined } from "@mui/icons-material";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
	height: 16,
	borderRadius: 20,
	marginTop: "16px",
	[`&.${linearProgressClasses.colorPrimary}`]: {
		backgroundColor: theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
	},
	[`& .${linearProgressClasses.bar}`]: {
		borderRadius: 20,
		backgroundColor: "#DE0085",
	},
}));

export default function Motivations({ persona }) {
	const [personaData, setpersonaData] = useState(persona);

	useEffect(() => {
		setpersonaData(persona);
	}, [persona]);

	const MOTIVATIONS = [
		{ title: "Price", value: personaData?.motivations?.price || 0 },
		{ title: "Comfort", value: personaData?.motivations?.comfort || 0 },
		{ title: "Convenience", value: personaData?.motivations?.convenience || 0 },
		{ title: "Speed", value: personaData?.motivations?.speed || 0 },
		{ title: "Loyalty", value: personaData?.motivations?.loyalty || 0 },
	];

	return (
		<PersonaCard isLarge={true} title="Motivations" icon={<LandscapeOutlined fontSize="large" />}>
			<Box mt={3}>
				{MOTIVATIONS.map((item) => (
					<Box key={item.title} px="60px" mb="30px">
						<Typography variant="card_text">{item.title}</Typography>
						<BorderLinearProgress variant="determinate" value={item.value * 10} />
					</Box>
				))}
			</Box>
		</PersonaCard>
	);
}
