import {
  Typography,
  Box,
  Divider,
  Card,
  Button,
  MenuItem,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import LoopIcon from "@mui/icons-material/Loop";
import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import theme from "../../../constants/Theme";
import constants from "../../../constants";
import * as actions from "../../../modules/actions";
import DownloadMenu from "../../../common/DownloadMenu";
import { sosPrimary } from "../../../constants/Theme/colors";
import MessageHandlerModal from "../../../common/modal/MessageHandler";
import MuiTooltip from "../../../common/MuiTooltip";
import TemporaryDrawer from "../../../common/Drawer";
import { archiveApiCall, createNewVersionApiCall } from "../../../utils/api";
import ROUTES_PATH from "../../../constants/routes";

function ValuePropSummary({
  powerUpExcercises,
  handleBackClick,
  moduleId,
  setExcerciseConfigs,
  versionId,
}) {
  const tabitems = constants.SUMMARY_TAB_ITEMS;
  const history = useHistory();
  const [archiveConfirmDialog, setArchiveConfirmDialog] = useState(false);
  const [archiveDrawerOpen, setArchiveDrawerOpen] = useState(false);
  const [currenttab, setcurrenttab] = useState(tabitems[0].key);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    setcurrenttab(tabitems[0].key);
  }, []);
  const createNewVersion = (moduleId, uuid, history) => {
    setExcerciseConfigs(null);
    createNewVersionApiCall(moduleId, uuid, history);
  };
  const TextComponent = ({ label, value }) => {
    return (
      <Box display={"flex"} sx={{ mt: 2 }}>
        <Typography variant="label_xlarge">{label}</Typography>
        &nbsp;
        <Typography variant="subhead4" color={sosPrimary[500]}>
          {value}
        </Typography>
      </Box>
    );
  };
  const archiveCancelDialogHandler = () => {
    setArchiveConfirmDialog(false);
  };

  const archiveSuccessDialogHandler = () => {
    setArchiveConfirmDialog(false);
    let payload = {
      responseId: versionId,
      status: true,
    };

    archiveApiCall(payload, history);
  };

  return (
    <>
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          [theme.breakpoints.down("md")]: {
            //background: theme.palette.secondary.greyBg,
          },
        }}
      >
        <Box flexDirection={"column"}>
          <Box sx={{ mt: 3 }}>
            <Typography variant="largeTitle">Summary</Typography>
          </Box>
          <Box sx={{ mt: 3 }}>
            <Typography variant="subhead1">
              Congratulations! Below is a summary of the work you’ve completed
              in this PowerUp. To edit or review your completed steps, select
              the steps above to navigate back. You can take this PowerUp as
              many times as you’d like. Don’t forget to get feedback on your
              completed work.
            </Typography>
          </Box>

          {/*Main Container*/}
          <Box
            sx={theme => ({
              borderRadius: theme.shape.standard5.borderRadius,
              p: 4,
              mt: 3,
              background: theme.palette.secondary.greyBg,

              [theme.breakpoints.down("md")]: {
                borderRadius: "0px",
              },
            })}
          >
            {/*Download icon is hidden as download functionality not added*/}
            <Box sx={{ textAlign: "end", display: "none" }}>
              <DownloadMenu />
            </Box>
            <Box>
              <TextComponent
                label={"Our"}
                value={powerUpExcercises[4]?.answers[0]?.value}
              />
              <TextComponent
                label={"help(s)"}
                value={powerUpExcercises[4]?.answers[1]?.value}
              />
              <TextComponent
                label={"who want to"}
                value={powerUpExcercises[4]?.answers[2]?.value}
              />
              <TextComponent
                label={"by"}
                value={powerUpExcercises[4]?.answers[3]?.value}
              />
              <TextComponent
                label={"and"}
                value={powerUpExcercises[4]?.answers[4]?.value}
              />
            </Box>
          </Box>
          <Divider
            sx={{
              mt: 3,
              borderColor: "secondary.light",
              display: { sm: "none", xs: "none", md: "flex" },
            }}
          />
          {/* This section needs to revisited once feedback functionality implemented. */}
          <Box
            sx={theme => ({
              display: { sm: "none", xs: "none", md: "none" },
              background: theme.palette.secondary.white,
              borderRadius: theme.shape.standard.borderRadius,
              p: 3,
              mt: 3,
            })}
          >
            <Typography variant="title_medium">Want feedback?</Typography>
            <Box sx={{ mt: 3 }}>
              <Typography variant="subhead2">
                Your StartupOS Advisor is here to help if you would like
                feedback for this completed PowerUp.
              </Typography>
            </Box>
            <Box sx={{ textAlign: "center", mt: 4 }}>
              <Button>Submit for feedback</Button>
            </Box>
          </Box>
          <Box
            sx={{
              mt: 3,
              display: { sm: "none", xs: "none", md: "flex" },
            }}
          >
            <MuiTooltip title="Archive">
              <ArchiveOutlinedIcon
                sx={{
                  cursor: "pointer",
                  color: "secondary.main",
                  p: 1,
                  "&:hover": {
                    background: "rgba(73, 69, 79, 0.08)",
                    borderRadius: "50%",
                  },
                }}
                onClick={() => {
                  setArchiveConfirmDialog(true);
                }}
              />
            </MuiTooltip>

            <MuiTooltip title="Take again">
              <LoopIcon
                sx={{
                  cursor: "pointer",
                  ml: 1,
                  color: "secondary.main",
                  p: 1,
                  "&:hover": {
                    background: "rgba(73, 69, 79, 0.08)",
                    borderRadius: "50%",
                  },
                }}
                onClick={() => {
                  createNewVersion(moduleId, "md-value-prop", history);
                }}
              />
            </MuiTooltip>
            <Box sx={{ ml: "auto" }}>
              <Button
                variant="outlined"
                onClick={() => {
                  handleBackClick();
                }}
              >
                Back
              </Button>
            </Box>
            <Box sx={{ ml: 2 }}>
              <Button
                onClick={() => {
                  history.push(ROUTES_PATH.STARTUPS_HOME);
                }}
              >
                Done
              </Button>
            </Box>
          </Box>
        </Box>
      </Card>
      <Box
        sx={{
          background: theme.palette.secondary.white,
          position: "fixed",
          bottom: 72,
          width: 1,
          textAlign: "center",
          alignItems: "center",
          py: 2,
          left: 0,
          display: { sm: "flex", xs: "flex", md: "none" },
        }}
      >
        <MoreHorizIcon
          sx={{
            borderRadius: "50%",
            border: `1px solid ${theme.palette.secondary.dark}`,
            mr: 1,
            ml: 2,
            p: 1,
          }}
          onClick={() => {
            setArchiveDrawerOpen(true);
          }}
        />
        <Button
          variant="outlined"
          onClick={() => {
            handleBackClick();
          }}
        >
          Back
        </Button>
        <Button
          sx={{ ml: 1, mr: 2 }}
          onClick={() => {
            history.push(ROUTES_PATH.STARTUPS_HOME);
          }}
        >
          Done
        </Button>
      </Box>
      <TemporaryDrawer
        open={archiveDrawerOpen}
        onClose={() => {
          setArchiveDrawerOpen(false);
        }}
      >
        <Box sx={{ py: 3 }}>
          <MenuItem
            onClick={() => {
              setArchiveDrawerOpen(false);
              setArchiveConfirmDialog(true);
            }}
          >
            <ArchiveOutlinedIcon
              sx={{
                color: "secondary.main",
                p: 1,
              }}
            />
            <Typography variant="label_xlarge" sx={{ ml: 2 }}>
              Archive
            </Typography>
          </MenuItem>
          <MenuItem
            onClick={() => {
              createNewVersion(moduleId, "md-value-prop", history);
            }}
          >
            <LoopIcon
              sx={{
                color: "secondary.main",
                p: 1,
              }}
            />
            <Typography variant="label_xlarge" sx={{ ml: 2 }}>
              Take again
            </Typography>
          </MenuItem>
        </Box>
      </TemporaryDrawer>
      <MessageHandlerModal
        isOpen={archiveConfirmDialog}
        heading="Archive"
        text={"Are you sure you want to archive this?"}
        messageType={"primary"}
        cancelText={"No thanks"}
        okText={"Archive"}
        style={{
          maxWidth: "450px",
        }}
        onOk={archiveSuccessDialogHandler}
        onCancel={archiveCancelDialogHandler}
      />
    </>
  );
}

const mapStoreToProps = ({ powerUp }) => ({
  powerUpExcercises: powerUp?.powerUpExcercises,
});

const mapDispatchToProps = dispatch => ({
  setPowerUpExcercises: updatedSteps =>
    dispatch(actions.setPowerUpExcercises(updatedSteps)),
});

export default connect(mapStoreToProps, mapDispatchToProps)(ValuePropSummary);
