import { Skeleton, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import UpdatedTextInputField from "../../../../common/UpdatedTextInputField";
import services from "../../../../services";

const StartupQA = ({ selectedRowId }) => {
	const [partnerQA, setPartnerQA] = useState([]);
	const [initialized, setInitialized] = useState(false);
	const [loading, setLoading] = useState(false);
	const loadingArray = [1, 2, 3, 4, 5, 6];

	const getPartnerQA = async (selectedRowId) => {
		try {
			const entityId = selectedRowId;
			setLoading(true);
			const response = await services.getPartnerQuestionAnswersAPI({ entityId });
			setLoading(false);
			setPartnerQA(response?.data?.data);
		} catch (error) {
			setLoading(false);
			console.log(`Error while getting partner questions answers `, error);
		}
	};
	useEffect(() => {
		if (selectedRowId !== 0 || initialized) {
			getPartnerQA(selectedRowId);
			setInitialized(true);
		}
	}, [selectedRowId]);

	return (
		<>
			<Stack spacing={4} sx={{ px: 2 }}>
				{loading ? (
					<Stack spacing={2}>
						{loadingArray.map((item) => {
							return <Skeleton variant="rectangular" height={50} width={"100%"} sx={{ borderRadius: "30px" }} />;
						})}
					</Stack>
				) : (
					partnerQA?.map((partnerItem, idx) => {
						return (
							<Stack>
								<Typography sx={{ color: "#344054" }} component={"p"} variant="Text/sm/Semibold">
									{idx + 1}. {partnerItem?.question?.name}{" "}
								</Typography>
								<UpdatedTextInputField
									label={""}
									handleChange={() => {}}
									handleBlur={() => {}}
									inputValue={partnerItem?.answers?.[0]?.answerText || ""}
									placeholder={""}
									inputProps={{ readOnly: true }}
									textFieldProps={{
										fullwidth: true,
										width: "100%",
										height: "48px",
									}}
								/>
							</Stack>
						);
					})
				)}
			</Stack>
		</>
	);
};

export default StartupQA;
