import { Card, Grid, Typography, Box, useMediaQuery } from "@mui/material";
import React from "react";
import theme from "../../../../constants/Theme";
import TungstenOutlinedIcon from "@mui/icons-material/TungstenOutlined";

export default function PowerupExcerciseTitleBox({
	iconBackgroundColor,
	contentBackgroundColor,
	iconUrl,
	subTitle,
	title,
	contentTextColor,
	isGradient = false,
}) {
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
	return (
		<Card
			sx={(theme) => ({
				borderRadius: theme.shape.standard3.borderRadius,
				backgroundColor: isGradient ? "" : contentBackgroundColor,
				background: isGradient ? contentBackgroundColor : "",
			})}
		>
			<Box display="flex" alignItems="center">
				<Box
					display="flex"
					justifyContent={"left"}
					alignItems="center"
					sx={{
						backgroundColor: isGradient ? "" : contentBackgroundColor,
						background: isGradient ? contentBackgroundColor : "",
					}}
				>
					<Box
						sx={(theme) => ({
							backgroundColor: iconBackgroundColor,
							borderRadius: theme.shape.standard3.borderRadius,
							m: 1,
						})}
					>
						<TungstenOutlinedIcon
							sx={{
								"-webkit-transform": "rotate(180deg)",
								"-moz-transform": "rotate(180deg)",
								"-ms-transform": "rotate(180deg)",
								"-o-transform": "rotate(180deg)",
								transform: "rotate(180deg)",
								color: "secondary.white",
								fontSize: "50px",
							}}
						/>
						{/*<img src={iconUrl} height={70} width={70} />*/}
					</Box>
				</Box>
				<Box
					display="flex"
					alignItems="center"
					sx={{
						backgroundColor: isGradient ? "" : contentBackgroundColor,
						background: isGradient ? contentBackgroundColor : "",
					}}
				>
					<Box sx={{ m: 1 }}>
						{/*Keep optional values until previous component builds*/}
						<Typography variant="label_small" color={contentTextColor}>
							{subTitle || "subtitle"}
						</Typography>
						<br />
						<Typography variant="headline6" color={contentTextColor} sx={{ wordBreak: "break-word" }}>
							{title || "Title"}
						</Typography>
					</Box>
				</Box>
			</Box>
		</Card>
	);
}
