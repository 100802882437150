import React, { createContext, useState, useEffect } from "react";
import { Box, Grid, Button, Stack } from "@mui/material";
import ProfilePhotoSection from "./ProfilePhotoSection";
import { useSelector, useDispatch } from "react-redux";
import LoadingButton from "../../../common/Loadingbutton";
import services from "../../../services";
import { setUserProfileData } from "../../../modules/actions/userProfileActions";
import { toastContainer } from "../../../utils/toast";
import messages from "../../../constants/messages";

const MyPhotosTabContext = createContext();

const PhotosSection = ({ handleClose }) => {
	const dispatch = useDispatch();
	const userDetails = useSelector((state) => state?.userProfileData?.userData);
	const [payLoad, setPayLoad] = useState({ ...userDetails });
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (userDetails) {
			setPayLoad({ ...userDetails });
		}
	}, [userDetails]);

	const handleUpdateUser = () => {
		setIsLoading(true);
		let payloadData = payLoad ? payLoad : userDetails;
		const updatedTagList =
			payloadData?.companyTags?.length > 0
				? payloadData?.companyTags?.map((item) => {
						return Number(item?.scoreTag?.id);
				  })
				: [];
		services
			.updateCompanyProfile({
				...payloadData,
				companyTags: updatedTagList,
			})
			.then((response) => {
				setIsLoading(false);
				if (response.data.message === "Success") {
					handleClose();

					// toastContainer(messages.CHANGES_SAVE_SUCCESS, "success");
					dispatch(
						setUserProfileData({
							userData: response?.data?.data,
						})
					);
				}
			})
			.catch((e) => {
				console.log("Submit", e);
				setIsLoading(false);
			});
	};

	return (
		<MyPhotosTabContext.Provider
			value={{
				payLoad,
				setPayLoad,
				setIsLoading,
			}}
		>
			<Box>
				<Grid container>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<ProfilePhotoSection context={MyPhotosTabContext} />
					</Grid>
				</Grid>
				<Box
					sx={{
						display: "flex",
						gap: 2,
						justifyContent: "end",
						alignItems: "center",
						mt: 3,
					}}
				>
					<Button
						onClick={handleClose}
						variant="noOutlineNew"
						sx={{
							height: "40px",
							m: 0,
							width: "fit-content",
							fontSize: "18px",
							lineHeight: "28px",
							fontFamily: "PoppinsSemiBold",
						}}
					>
						Cancel
					</Button>
					<LoadingButton
						loaderColor={"#7B61FF"}
						loadingStyles={{
							border: "4px solid",
							backgroundColor: "white",
							borderColor: "#7B61FF",
							height: "60px",
							px: 4,
						}}
						loading={isLoading}
						onClick={!isLoading ? handleUpdateUser : () => {}}
						variant="DS1"
					>
						Save
					</LoadingButton>
				</Box>
			</Box>
		</MyPhotosTabContext.Provider>
	);
};

export default PhotosSection;
