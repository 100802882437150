import React, { useEffect, useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

import services from "../../../../services";
import WorkspaceHeader, { ActionBtn } from "../../../../components/Investor/Workspace/View/Header";
import PowerUpIcon from "../../../../components/Investor/Workspace/Assets/new-home-powerup-icon.svg";
import StartupLabsIcon from "../../../../components/Investor/Workspace/Assets/new-home-startuplabs-icon.svg";
import ActivityIcon from "../../../../components/Investor/Workspace/Assets/new-home-activity-icon.svg";
import RecommendationIcon from "../../../../components/Investor/Workspace/Assets/new-home-todo-recommendation-icon.svg";
import PopularIcon from "../../../../components/Investor/Workspace/Assets/new-home-todo-popular-icon.svg";
import { HomeSectionTitle } from "../../../../components/Investor/Workspace/View/HomeTitle";
import HomeCard from "../../../../components/Investor/Workspace/View/HomeCard";
import { CARD_GROUP_OPTIONS } from "../../../../components/Investor/Workspace/View/Data";
import PowerPackDetailModal from "../../../../components/Investor/Workspace/View/PowerPackDetailModal";

ChartJS.register(ArcElement, Tooltip, Legend);

// TODO: back up later
// const BootstrapInput = styled(InputBase)(({ theme }) => ({
// 	"label + &": {
// 		marginTop: theme.spacing(3),
// 	},
// 	"& .MuiInputBase-input": {
// 		border: "none",
// 		position: "relative",
// 		backgroundColor: theme.palette.background.paper,
// 		fontSize: "20px",
// 		lineHeight: "30px",
// 		paddingLeft: "12px",
// 		paddingTop: "10px",
// 		paddingBottom: "10px",
// 		paddingRight: "40px !important",
// 		transition: theme.transitions.create(["border-color", "box-shadow"]),
// 		fontFamily: "PoppinsSemiBold",
// 		"&:focus": {
// 			borderRadius: 4,
// 			borderColor: "#80bdff",
// 			boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
// 		},
// 	},
// }));

export default function ThingsToDo() {
	const [todoCards, setTodoCards] = useState([]);
	const [popularCards, setPopularCards] = useState([]);
	const [openPowerPackModal, setOpenPowerPackModal] = useState(false);
	const [loading, setLoading] = useState(false);
	const [loadingPopular, setLoadingPopular] = useState(false);
	const [packDetail, setPackDetail] = useState({ title: "", desc: "", items: [] });

	useEffect(() => {
		getTodo();
		getCurrentGroup("recommandation");
	}, []);

	const getTodo = async () => {
		setLoading(true);
		try {
			const todos = await services.getToDosList("popular");
			setPopularCards(todos?.data?.data || []);
		} catch (error) {
			console.log(error);
		}
		setLoading(false);
	};

	const getCurrentGroup = async (group) => {
		setLoadingPopular(true);
		try {
			const recommendation = await services.getToDosList(group);
			setTodoCards(recommendation?.data?.data || []);
		} catch (error) {
			console.log(error);
			setTodoCards([]);
		}
		setLoadingPopular(false);
	};

	const getPackDetailInfoById = async (packId) => {
		try {
			const packInfo = popularCards.filter((packCard) => packCard?.uuid === packId)[0];
			const packItemsInfo = await services.getToDosList(packId);
			setPackDetail({ title: packInfo?.title, desc: packInfo?.shortDescription, items: packItemsInfo?.data?.data || [] });
			setOpenPowerPackModal(true);
		} catch (error) {
			console.log(error);
		}
	};

	const handlePowerPackDetail = () => {
		setOpenPowerPackModal(false);
	};

	// TODO: back later
	// const handleSelectChange = (event) => {
	// 	setCurrentGroup(event.target.value);
	// 	setTodoCards([]);
	// };

	const Actions = (
		<Box
			display="flex"
			sx={(theme) => ({
				gap: "30px",
				flexDirection: "row",
				[theme.breakpoints.down("sm")]: {
					flexDirection: "column",
					alignItems: "flex-start",
					width: "100%",
				},
			})}
		>
			{/* <ActionBtn color="#095CED" title="PowerPacks" tabIndex={0} actionIndex={0} avatar={StartupLabsIcon} onAction={() => {}} />
			<ActionBtn color="#7B61FF" title="PowerUps" tabIndex={0} actionIndex={1} avatar={PowerUpIcon} onAction={() => {}} />
			<ActionBtn color="#DE0085" title="Activity" tabIndex={0} actionIndex={2} avatar={ActivityIcon} onAction={() => {}} /> */}
		</Box>
	);

	return (
		<Box>
			<WorkspaceHeader id={0} actions={Actions} />
			<Box mt={4}>
				{/* TODO: back later */}
				{/* <Select
					sx={{
						"& .MuiSelect-icon": {
							fontSize: "30px",
							color: "black",
						},
						"& .MuiInputBase-input.MuiSelect-select": {
							pl: 0,
							background: "inherit",
						},
						"& .MuiInputBase-input:focus": {
							boxShadow: "none",
						},
					}}
					value={currentGroup}
					onChange={handleSelectChange}
					input={<BootstrapInput />}
					IconComponent={KeyboardArrowDown}
				>
					{CARD_GROUP_OPTIONS?.map((group) => (
						<MenuItem key={group.id} value={group.value}>
							{`StartupOS Goal: ${group.label}`}
						</MenuItem>
					))}
				</Select> */}

				<Box mb={4}>
					<HomeSectionTitle icon={RecommendationIcon} title="Our Recommendations for You!" />
					{loading ? (
						<Box
							sx={{
								display: "flex",
								width: 1,
								justifyContent: "center",
								alignItems: "center",
								height: "50vh",
							}}
						>
							<CircularProgress />
						</Box>
					) : (
						<Box display="flex" flexWrap={"wrap"} gap="30px">
							{todoCards.map((todo) => (
								<HomeCard
									key={todo.id}
									isPremium={todo?.isPremiumFeature ? todo?.isPremiumFeature : false}
									variant={todo?.cardType === "POWERUP_PACK" ? "secondary" : todo?.cardType === "ACTIVITY" ? "third" : "primary"}
									uuid={todo?.uuid}
									title={todo?.title}
									redirectUrl={todo?.redirectUrl}
									cardType={todo?.cardType}
									cardStatus={todo?.cardStatus}
									taskStatus={todo?.cardProgressDetails?.taskStatus}
									linkedEntityId={todo?.linkedEntityId}
									imgSrc={todo?.imageUrl}
									isAI={todo?.isAiPowered ? todo?.isAiPowered : false}
									desc={todo?.shortDescription ? todo?.shortDescription : ""}
									onDetail={getPackDetailInfoById}
								/>
							))}
						</Box>
					)}
				</Box>

				<Box mb={4}>
					<HomeSectionTitle icon={PopularIcon} title="Some of the most popular things to do on StartupOS!" />
					{loadingPopular ? (
						<Box
							sx={{
								display: "flex",
								width: 1,
								justifyContent: "center",
								alignItems: "center",
								height: "50vh",
							}}
						>
							<CircularProgress />
						</Box>
					) : (
						<Box display="flex" flexWrap={"wrap"} gap="30px">
							{popularCards.map((popular) => (
								<HomeCard
									key={popular?.orderNo}
									status={popular?.cardStatus}
									uuid={popular?.uuid}
									title={popular?.title}
									desc={popular?.shortDescription ? popular?.shortDescription : ""}
									subTitle={popular?.subTitle}
									redirectUrl={popular?.redirectUrl}
									cardType={popular?.cardType}
									cardStatus={popular?.cardStatus}
									taskStatus={popular?.cardProgressDetails?.taskStatus}
									linkedEntityId={popular?.linkedEntityId}
									imgSrc={popular?.imageUrl}
									isPremium={popular?.isPremiumFeature ? popular?.isPremiumFeature : false}
									isAI={popular?.isAiPowered ? popular?.isAiPowered : false}
									variant={popular?.cardType === "POWERUP_PACK" ? "secondary" : popular?.cardType === "ACTIVITY" ? "third" : "primary"}
									onDetail={getPackDetailInfoById}
								/>
							))}
						</Box>
					)}
				</Box>
				<PowerPackDetailModal open={openPowerPackModal} onClose={handlePowerPackDetail} data={packDetail} />
			</Box>
		</Box>
	);
}
