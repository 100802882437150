import React, { Fragment } from "react";
import { Box, Tabs, Tab, Card, Typography } from "@mui/material";
import { Info, KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import ReactShowMoreText from "react-show-more-text";
import ProfileCompletion from "../../../../components/Profile/StartupProfile/ProfileCompletion";

function FoundingTeamVideoTabController({ STARTUP, value, handleTabChange, completionPercentage }) {
	return (
		<Fragment>
			<Box>
				<Tabs
					value={value}
					onChange={handleTabChange}
					sx={{
						mb: {
							lg: 4,
							md: 3,
							xs: 2,
						},
						"& .MuiTab-root": {
							mr: 3, // Set margin-right between tabs
						},
						"& .MuiTabs-indicator": {
							backgroundColor: (theme) => theme.palette.primary.main,
							borderRadius: "5px",
							height: "4px",
						},
					}}
				>
					<Tab
						sx={{ textTransform: "none", px: 0 }}
						label={
							<Typography
								variant="customValue"
								sx={{
									color: value === 0 ? "#000" : "#878787",
									fontWeight: "600",
									fontFamily: "PoppinsSemiBold",
									fontSize: "20px",
									lineHeight: "20px",
								}}
							>
								About Us
							</Typography>
						}
					/>
					<Tab
						sx={{ textTransform: "none" }}
						label={
							<Typography
								variant="customValue"
								sx={{
									color: value === 1 ? "#000" : "#878787",
									fontWeight: "600",
									fontFamily: "PoppinsSemiBold",
									fontSize: "20px",
									lineHeight: "20px",
								}}
							>
								Founder Story
							</Typography>
						}
					/>
					{typeof completionPercentage !== "undefined"  && (
						<Tab disabled sx={{ textTransform: "none" }} label={<ProfileCompletion percentage={completionPercentage} />} />
					)}
				</Tabs>
			</Box>
			<Box>
				{value === 0 ? (
					<Box>
						{STARTUP?.about ? (
							<Typography variant="title_medium" color="secondary.black" component={"p"}>
								<ReactShowMoreText
									lines={8}
									more={
										<Box display={"flex"} alignItems="center" gap={1} sx={{ cursor: "pointer", mt: 2 }}>
											<Typography variant="title_portfolio" color="secondary.black">
												{" "}
												Read More
											</Typography>
											<Box
												sx={{
													display: "flex",
													alignItems: "center",
													border: `2px solid black`,
													borderRadius: "50%",
													p: 1,
													height: "8px",
													width: "8px",
													justifyContent: "center",
												}}
											>
												<KeyboardArrowDown fontSize="small" />
											</Box>
										</Box>
									}
									less={
										<Box display={"flex"} alignItems="center" gap={1} sx={{ cursor: "pointer" }}>
											<Typography variant="title_portfolio" color="secondary.black">
												{" "}
												Read Less
											</Typography>
											<Box
												sx={{
													display: "flex",
													alignItems: "center",
													border: `2px solid black`,
													borderRadius: "50%",
													p: 1,
													height: "8px",
													width: "8px",
													justifyContent: "center",
												}}
											>
												<KeyboardArrowUp fontSize="small" />
											</Box>
										</Box>
									}
									className="content-css"
									anchorClass="my-anchor-css-class"
									expanded={false}
									//width={280}
									truncatedEndingComponent={"... "}
								>
									<Box>
										{" "}
										{STARTUP?.about !== "" && STARTUP?.about !== "<p><br></p>" ? (
											<span
												dangerouslySetInnerHTML={{
													__html: STARTUP?.about,
												}}
											/>
										) : (
											<Box sx={{ mt: 2 }}>
												<Typography variant="title_medium" color="secondary.black">
													{"-"}
												</Typography>
											</Box>
										)}
									</Box>
								</ReactShowMoreText>
							</Typography>
						) : (
							<Card
								sx={{
									borderRadius: "40px",
									p: {
										xs: 2,
										md: 3,
										lg: 4,
									},
									boxShadow: "0px 10px 15px rgba(0, 0, 0, 0.05)",
									display: "flex",
									gap: 2,
								}}
							>
								<Box>
									<Info
										sx={{
											color: (theme) => theme.palette.primary.main,
										}}
									/>
								</Box>
								<Box>
									<Typography color="black" variant="poppinsMedium16" component={"p"}>
										No 'About Us' statement is available for {STARTUP?.name} at this time. We will continue to check for updates.
									</Typography>
								</Box>
							</Card>
						)}
					</Box>
				) : value === 1 ? (
					<Box>
						{STARTUP?.founderStory ? (
							<Typography variant="title_medium" color="secondary.black" component={"p"}>
								<ReactShowMoreText
									lines={8}
									more={
										<Box display={"flex"} alignItems="center" gap={1} sx={{ cursor: "pointer", mt: 2 }}>
											<Typography variant="title_portfolio" color="secondary.black">
												{" "}
												Read More
											</Typography>
											<Box
												sx={{
													display: "flex",
													alignItems: "center",
													border: `2px solid black`,
													borderRadius: "50%",
													p: 1,
													height: "8px",
													width: "8px",
													justifyContent: "center",
												}}
											>
												<KeyboardArrowDown fontSize="small" />
											</Box>
										</Box>
									}
									less={
										<Box display={"flex"} alignItems="center" gap={1} sx={{ cursor: "pointer" }}>
											<Typography variant="title_portfolio" color="secondary.black">
												{" "}
												Read Less
											</Typography>
											<Box
												sx={{
													display: "flex",
													alignItems: "center",
													border: `2px solid black`,
													borderRadius: "50%",
													p: 1,
													height: "8px",
													width: "8px",
													justifyContent: "center",
												}}
											>
												<KeyboardArrowUp fontSize="small" />
											</Box>
										</Box>
									}
									className="content-css"
									anchorClass="my-anchor-css-class"
									expanded={false}
									//width={280}
									truncatedEndingComponent={"... "}
								>
									<Box>
										{" "}
										{STARTUP?.founderStory !== "" && STARTUP?.founderStory !== "<p><br></p>" ? (
											<span
												dangerouslySetInnerHTML={{
													__html: STARTUP?.founderStory,
												}}
											/>
										) : (
											<Box sx={{ mt: 2 }}>
												<Typography variant="title_medium" color="secondary.black">
													{"-"}
												</Typography>
											</Box>
										)}
									</Box>
								</ReactShowMoreText>
							</Typography>
						) : (
							<Card
								sx={{
									borderRadius: "40px",
									p: {
										xs: 2,
										md: 3,
										lg: 4,
									},
									mb: 4,
									boxShadow: "0px 10px 15px rgba(0, 0, 0, 0.05)",
									display: "flex",
									gap: 2,
								}}
							>
								<Box>
									<Info
										sx={{
											color: (theme) => theme.palette.primary.main,
										}}
									/>
								</Box>
								<Box>
									<Typography color="black" variant="poppinsMedium16" component={"p"}>
										No 'Founder Story' is available for {STARTUP?.name} at this time. We will continue to check for updates.
									</Typography>
								</Box>
							</Card>
						)}
					</Box>
				) : null}
			</Box>
		</Fragment>
	);
}

export default FoundingTeamVideoTabController;
