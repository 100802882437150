import React from "react";
import { Box, Typography } from "@mui/material";
import { get } from "lodash";
import { USER_TYPES } from "../../../../utils/userTypes";
import { BUILDING_ICON_V3, LOCATION_ICON_V3, PRODUCT_STATE_ICON_V2 } from "../../../../constants";
import { STATES } from "../../../../constants/powerup-data";
import { useDispatch, useSelector } from "react-redux";
import { setSlidingProfileData } from "../../../../modules/actions/slidingProfileActions";

const getSelectedIndustryFormatted = (input) => {
	if (input) {
		try {
			let inputParsed = JSON.parse(input);
			if (Array.isArray(inputParsed)) {
				return inputParsed;
			} else {
				return [inputParsed];
			}
		} catch (error) {
			return [];
		}
	} else {
		return [];
	}
};

function SmallDetails({ icon, details }) {
	return (
		<Box display="flex" gap={"12px"} alignItems="center" sx={{ minHeight: "30px", height: "30px", overflow: "hidden" }}>
			<Box component={"img"} src={icon} alt="icon" sx={{ width: "24px", height: "24px" }} />
			{Array.isArray(details) ? (
				<Typography variant="Text/sm/Regular" sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
					{details.length > 0 ? details?.join(", ") : "No Data"}
				</Typography>
			) : (
				<Typography variant="Text/sm/Regular">{details?.length > 0 ? details : "No Data"}</Typography>
			)}
		</Box>
	);
}

function CompanyInfo({ companyData, userTypes }) {
	const { userType } = useSelector((store) => store?.auth);

	const dispatch = useDispatch();
	const companyName = get(companyData, "companyName", "No Data");
	const companyStage = get(companyData, "currentStage", "No Data");

	const companyState = get(companyData, "state", "");
	const companyCity = get(companyData, "city", "");

	const industries = getSelectedIndustryFormatted(get(companyData, "industry", []));
	let location = "";

	let area = STATES.find((s) => s.name === companyState);
	area = get(area, "abbreviation", companyState);
	if (companyCity && area) {
		location = `${companyCity}, ${area}`;
	} else if (!companyCity && companyState) {
		location = companyState;
	} else {
		location = "No Data";
	}

	const showStartupProfile = () => {
		if (companyData?.investorView && (userType === USER_TYPES.MENTOR_USER || userType === USER_TYPES.INVESTOR_USER)) {
			dispatch(
				setSlidingProfileData({
					open: true,
					profileType: "STARTUP_PROFILE",
					profileId: companyData?.id,
				})
			);
		}
	};

	return (
		<Box display="flex" flexDirection="column" sx={{ gap: 2 }}>
			<Box
				onClick={showStartupProfile}
				sx={{
					minHeight: "24px",
					height: "24px",
					overflow: "hidden",
					textOverflow: "ellipsis",
					whiteSpace: "nowrap",
					...(companyData?.investorView && { cursor: "pointer" }),
				}}
			>
				<Typography variant="Text/sm/Regular" sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
					{companyName}
				</Typography>
			</Box>
			<Box display="flex" flexDirection="column" sx={{ gap: 1 }}>
				<SmallDetails details={companyStage} icon={PRODUCT_STATE_ICON_V2} />
				<SmallDetails details={location} icon={LOCATION_ICON_V3} />
				<SmallDetails details={industries} icon={BUILDING_ICON_V3} />
			</Box>
		</Box>
	);
}

export default CompanyInfo;
