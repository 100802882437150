import { Download } from "@mui/icons-material";
import { Button } from "@mui/material";
import { usePDF } from "@react-pdf/renderer";
import React from "react";

function PDFDownload({ document, fileName, buttonText }) {
	const [instance, updateInstance] = usePDF({ document: document });

	if (instance.loading) return <div></div>;

	if (instance.error) return <div></div>;

	return (
		<Button
			startIcon={<Download />}
			variant="contained"
			sx={{
				width: "max-content",
				"&:disabled": {
					color: "rgba(0, 0, 0, 0.26)",
				},
			}}
			href={instance.url}
			download={fileName ? `${fileName}.pdf` : "StartupOS-Report.pdf"}
		>
			{buttonText ? buttonText : "Download Report"}
		</Button>
	);
}

export default PDFDownload;
