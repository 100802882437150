import ArrowBack from "@mui/icons-material/ArrowBack";
import { Box, useMediaQuery, Alert, Skeleton } from "@mui/material";
import React, { useState, useEffect } from "react";
import DateTimeSlotPicker from "../../../../common/DateTimeslotPicker";
import moment from "moment";
import services from "../../../../services";
import { getCharDay } from "../../../../utils/date";
import { createDayTimesMap } from "../functions";
import { convertTimeToTargetTimezone } from "../../../../utils/commonFunctions";

export default function AvailabilityCalendar({ eventData, setMeetingObject, calUser }) {
	const [selectedTimeZone, setSelectedTimeZone] = useState(eventData?.timezone || moment.tz.guess());
	const [scheduleTimezone, setScheduleTimezone] = useState(moment.tz.guess());
	const [availabilities, setAvailabilities] = useState([]);
	const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
	const [timeDrawer, setTimeDrawer] = useState();
	const [dayTimesMap, setDayTimesMap] = useState({
		1: [],
		2: [],
		3: [],
		4: [],
		5: [],
		6: [],
		7: [],
	});
	const [availableTimes, setAvailableTimes] = useState([]);

	const [disableTimesAsPerAvailability, setDisableTimesAsPerAvailability] = useState(false);

	const [timeStampsLoading, setTimeStampsLoading] = useState(true);

	useEffect(() => {
		setDayTimesMap(createDayTimesMap(availabilities, selectedTimeZone, scheduleTimezone));
	}, [selectedTimeZone]);

	const [responses, setResponses] = useState([]);
	const [selectedTime, setSelectedTime] = useState(null);
	const [selectedTimeData, setSelectedTimeData] = useState(null);
	useEffect(() => {
		if (selectedTimeData) {
			setMeetingObject({
				...eventData,
				meetingDate: selectedTimeData?.meetingDate,
				startTime: selectedTimeData?.startTime,
			});
		}
	}, [selectedTimeData]);

	useEffect(() => {
		if (responses) {
			setMeetingObject({
				...eventData,
				meetingDate: responses?.meetingDate,
				startTime: responses?.startTime,
			});
		}
	}, [responses]);

	const getAvailabilitiesByDate = async (userId, date, meetingLength) => {
		const originalMoment = moment(date);

		const formattedDateTime = originalMoment.format("YYYY-MM-DDTHH:mm:ss");
		setTimeStampsLoading(true);
		await services
			.getAvailablitiesByDateApi(userId, formattedDateTime, meetingLength, moment.tz.guess())
			.then((res) => {
				if (res.data?.data) {
					const schedule = res.data?.data;

					setAvailabilities(schedule);
					setAvailableTimes(schedule);
					setTimeStampsLoading(false);
					if (eventData && eventData.timezone) {
						setDayTimesMap(schedule);
					} else {
						setDayTimesMap(schedule);
					}
				}
			})
			.catch((err) => {
				console.log(err);
				setTimeStampsLoading(false);
			});
	};

	return (
		<Box>
			{eventData ? (
				<DateTimeSlotPicker
					responses={responses}
					setResponses={setResponses}
					minTimeSlots={1}
					maxTimeSlots={1}
					selectedDate={selectedTime}
					setSelectedDate={(date) => {
						setSelectedTime(date);
						getAvailabilitiesByDate(eventData?.rescheduling ? calUser?.id : calUser?.id, date, eventData.duration);
					}}
					buttonsPerRow={1}
					customSlot
					startTime={"00:00"}
					endTime={"23:59"}
					duration={
						eventData?.rescheduling
							? (new Date(eventData?.endTime)?.getTime() - new Date(eventData?.startTime)?.getTime()) / (1000 * 60)
							: eventData.duration
					}
					singleDateSelect
					showDivider
					timeButtonVariant={"nofill"}
					mobileUI={isSmallScreen ? true : false}
					timeDrawer={timeDrawer}
					setTimeDrawer={setTimeDrawer}
					availableTimes={availableTimes}
					disableTimesAsPerAvailability={disableTimesAsPerAvailability}
					selectedTimeZone={selectedTimeZone}
					scheduleTimezone={scheduleTimezone}
					dayTimesMap={dayTimesMap}
					monthChanged={(selectedMonth) => {
						console.log("selectedMonth", selectedMonth);
					}}
					setSelectedTimeData={setSelectedTimeData}
					timeStampsLoading={timeStampsLoading}
				/>
			) : (
				<Box>
					<Skeleton variant="rectangular" animation="wave" width={210} height={118} />
				</Box>
			)}
		</Box>
	);
}
