import * as actionTypes from "../../actions/actionTypes";
import { updateObject } from "../../../utils/updateObject";

const initialState = {
  accessToken: "",
  idToken: "",
  userData: {},
  authenticatedAppUser: false,
  userType: "",
  eventId: null,
  socialConnectFlow: false,
  showAccountSettingPopup: false,
  decodedPayload: {},
  userSessionChange: false,
  tellUsMoreData: {},
  routeAfterLogin: "",
  companyLogoUrl: "",
  impersonateProviderId: null,
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_AUTH_DATA: {
      return action.payload === null
        ? initialState
        : updateObject(state, action.payload);
    }

    default:
      return state;
  }
};

export default auth;
