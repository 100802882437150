import { Box, Button, Drawer, Typography, alpha } from "@mui/material";
import React from "react";

function FilterDrawer({ isFilterOpen, closeDrawer, title, filters, clearAll }) {
	return (
		<Drawer
			anchor={"right"}
			open={isFilterOpen}
			onClose={() => {
				if (typeof closeDrawer === "function") {
					closeDrawer();
				}
			}}
			elevation={4}
		>
			<Box sx={{ display: "flex", flexDirection: "column", flex: 1, maxWidth: "600px", minWidth: "300px", gap: 3 }}>
				<Box
					sx={{
						display: "flex",
						gap: 2,
						px: "24px",
						pt: "24px",
					}}
				>
					<Box
						sx={{
							height: "40px",
							width: "40px",
							p: "10",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							borderRadius: "28px",
							border: (theme) => `6px solid ${alpha(theme?.palette?.primary?.light, 0.5)}`,
							background: (theme) => theme?.palette?.primary?.light,
							boxSizing: "border-box",
							backgroundClip: "padding-box",
							"& > svg": {
								stroke: (theme) => theme?.palette?.primary?.main,
							},
						}}
					>
						<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 26 26" fill="none">
							<path
								d="M17.0673 9H4.33333C2.49238 9 1 7.50762 1 5.66667C1 3.82572 2.49238 2.33333 4.33333 2.33333H17.0673M8.93266 23.6667H21.6667C23.5076 23.6667 25 22.1743 25 20.3333C25 18.4924 23.5076 17 21.6667 17H8.93266M1 20.3333C1 22.9107 3.08934 25 5.66667 25C8.244 25 10.3333 22.9107 10.3333 20.3333C10.3333 17.756 8.244 15.6667 5.66667 15.6667C3.08934 15.6667 1 17.756 1 20.3333ZM25 5.66667C25 8.244 22.9107 10.3333 20.3333 10.3333C17.756 10.3333 15.6667 8.244 15.6667 5.66667C15.6667 3.08934 17.756 1 20.3333 1C22.9107 1 25 3.08934 25 5.66667Z"
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
						</svg>
					</Box>
					<Box sx={{ flex: 1, display: "flex", alignSelf: "center" }}>
						<Typography variant={"Text/md/Semibold"}>{title}</Typography>
					</Box>
					<Button
						variant="DS1"
						iconButton={true}
						color={"clear"}
						small={true}
						sx={{ display: "flex", justifySelf: "flex-end" }}
						onClick={() => {
							if (typeof closeDrawer === "function") {
								closeDrawer();
							}
						}}
					>
						<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
							<path d="M15 5L5 15M5 5L15 15" stroke="#667085" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
						</svg>
					</Button>
				</Box>
				<Box
					sx={{
						flex: 1,
						display: "flex",
						flexDirection: "column",
						gap: 2,
						px: "24px",
					}}
				>
					{filters}
				</Box>
				<Box
					sx={{
						mt: "auto",
						bottom: 0,
						display: "flex",
						justifyContent: "flex-end",
						alignItems: "center",
						gap: 2,
						borderTop: "2px solid #eeeeee",
						padding: "16px 24px",
					}}
				>
					<Button
						variant="DS1"
						color="secondary"
						small={true}
						onClick={() => {
							if (typeof clearAll === "function") {
								clearAll();
								closeDrawer();
							}
						}}
					>
						Clear
					</Button>
					<Button
						variant="DS1"
						small={true}
						onClick={() => {
							if (typeof closeDrawer === "function") {
								closeDrawer();
							}
						}}
					>
						Close
					</Button>
				</Box>
			</Box>
		</Drawer>
	);
}

export default FilterDrawer;
