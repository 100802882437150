import React from "react";
import { Box, Typography } from "@mui/material";

export const Welcome = () => {
	return (
		<Box>
			<Box
				display="flex"
				justifyContent="center"
				sx={(theme) => ({
					p: "64px 0",
					[theme.breakpoints.down("md")]: {
						p: "48px 0",
					},
					[theme.breakpoints.down("sm")]: {
						p: "32px 0",
					},
				})}
			>
				<Typography variant="Text/2xl/Semibold">Welcome to StartupOS!</Typography>
			</Box>
			<Box
				display="flex"
				flexDirection="column"
				gap={2.5}
				alignItems="center"
				sx={(theme) => ({
					mb: "128px",
					[theme.breakpoints.down("md")]: {
						mb: "60px",
					},
					[theme.breakpoints.down("sm")]: {
						mb: "48px",
					},
				})}
			>
				<Typography variant="main_title_card">Help us customize your experience by telling us a bit about yourself.</Typography>
				<Box>
					<Typography variant="subhead1">
						The answers below will help accelerate your performance and raise your visibility on our platform!
					</Typography>
				</Box>
			</Box>
		</Box>
	);
};
