import { Avatar, Box, Stack, Typography } from "@mui/material";
import React, { Fragment } from "react";
import { removePartnerFromFounder, untrackPartnerFromFounder } from "./functions";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { ALERT_ICON_V1 } from "../../../../constants";
import { setCommonDialogConfirmationData } from "../../../../modules/actions";
import { useDispatch } from "react-redux";

function formatDollar(amount) {
	try {
		return new Intl.NumberFormat("en-US", {
			style: "currency",
			currency: "USD",
		}).format(amount);
	} catch (e) {
		console.log("Unable to format $ amount, failed with error:", e);
		return amount;
	}
}

export function StartupTableColumns(buttonLoader, setButtonLoader, reloadStartupsTable) {
	const dispatch = useDispatch();

	const handleCloseConfirmation = () => {
		dispatch(
			setCommonDialogConfirmationData({
				isOpen: false,
				text: "",
			})
		);
	};
	const handleRemovePartnerDialog = (partnerId) => {
		dispatch(
			setCommonDialogConfirmationData({
				isOpen: true,
				title: "Untrack Partner",
				subTitle: `Are you sure you want to untrack partner?`,
				text: "",
				primaryButtonText: "Confirm",
				secondaryButtonText: "Cancel",
				primaryButtonAction: () => untrackPartnerFromFounder(partnerId, setButtonLoader, reloadStartupsTable, handleCloseConfirmation),
				secondaryButtonAction: () => handleCloseConfirmation(),
				primaryButtonVariant: "PurpleRounded",
				secondaryButtonVariant: "noOutlineNew",
				primaryButtonLoading: buttonLoader,
				titleIcon: ALERT_ICON_V1,
			})
		);
	};
	const columns = [
		//{
		//	field: "id",
		//	headerName: "ID",
		//	sortable: false,
		//	filterable: false,wrapText: true,
		//},
		{
			field: "partner",
			headerName: "Origin",
			sortable: false,
			filterable: false,
			wrapText: true,
			width: 200,
			renderCell: (params) => (
				<Fragment>
					<Stack direction={"row"} gap={1} sx={{ alignItems: "center" }}>
						{" "}
						<Typography variant="Text/xs/Regular">{params.value?.partnerName}</Typography>
						<Box>
							{params.value?.inTracked && (
								<RemoveCircleOutlineIcon
									onClick={(e) => {
										e.stopPropagation();
										handleRemovePartnerDialog(params?.id);
									}}
								/>
							)}
						</Box>
					</Stack>
				</Fragment>
			),
			renderHeader: (params) => <Typography variant="Text/xs/Medium">{params.colDef.headerName}</Typography>,
		},
		{
			field: "score",
			headerName: "Score",
			sortable: false,
			filterable: false,
			wrapText: true,
			width: 80,
			renderCell: (params) => <Typography variant="Text/xs/Regular">{params.value}</Typography>,
			renderHeader: (params) => <Typography variant="Text/xs/Medium">{params.colDef.headerName}</Typography>,
		},
		{
			field: "name",
			headerName: "Name",
			sortable: false,
			filterable: false,
			wrapText: true,
			width: 200,
			renderCell: (params) => (
				<Fragment>
					<Avatar src={params.value?.img} sx={{ mr: 1 }} />
					<Typography variant="Text/xs/Regular">{params.value?.name}</Typography>
				</Fragment>
			),
			renderHeader: (params) => <Typography variant="Text/xs/Medium">{params.colDef.headerName}</Typography>,
		},
		{
			field: "location",
			headerName: "Location",
			sortable: false,
			filterable: false,
			wrapText: true,
			width: 150,
			renderCell: (params) => <Typography variant="Text/xs/Regular">{params.value}</Typography>,
			renderHeader: (params) => <Typography variant="Text/xs/Medium">{params.colDef.headerName}</Typography>,
		},
		{
			field: "founded",
			headerName: "Founded",
			sortable: false,
			filterable: false,
			wrapText: true,
			renderCell: (params) => <Typography variant="Text/xs/Regular">{params.value}</Typography>,
			renderHeader: (params) => <Typography variant="Text/xs/Medium">{params.colDef.headerName}</Typography>,
		},

		{
			field: "size",
			headerName: "Co. Size",
			sortable: false,
			filterable: false,
			wrapText: true,
			renderCell: (params) => <Typography variant="Text/xs/Regular">{params.value}</Typography>,
			renderHeader: (params) => <Typography variant="Text/xs/Medium">{params.colDef.headerName}</Typography>,
		},
		{
			field: "revenue",
			headerName: "Rev.",
			sortable: false,
			filterable: false,
			wrapText: true,
			renderCell: (params) => <Typography variant="Text/xs/Regular">{params.value}</Typography>,
			renderHeader: (params) => <Typography variant="Text/xs/Medium">{params.colDef.headerName}</Typography>,
		},
		{
			field: "capitalRaised",
			headerName: "Cap. Raised",
			sortable: false,
			filterable: false,
			wrapText: true,
			renderCell: (params) => <Typography variant="Text/xs/Regular">{formatDollar(params.value)}</Typography>,
			renderHeader: (params) => (
				<Typography variant="Text/xs/Medium" sx={{ whiteSpace: "wrap" }}>
					{params.colDef.headerName}
				</Typography>
			),
		},
		{
			field: "financing",
			headerName: "Financing",
			sortable: false,
			filterable: false,
			wrapText: true,
			renderCell: (params) => <Typography variant="Text/xs/Regular">{params.value}</Typography>,
			renderHeader: (params) => <Typography variant="Text/xs/Medium">{params.colDef.headerName}</Typography>,
		},
		{
			field: "currentRound",
			headerName: "Current",
			sortable: false,
			filterable: false,
			wrapText: true,
			renderCell: (params) => <Typography variant="Text/xs/Regular">{params.value}</Typography>,
			renderHeader: (params) => <Typography variant="Text/xs/Medium">{params.colDef.headerName}</Typography>,
		},
		{
			field: "seeking",
			headerName: "Seeking",
			sortable: false,
			filterable: false,
			wrapText: true,
			renderCell: (params) => <Typography variant="Text/xs/Regular">{params.value}</Typography>,
			renderHeader: (params) => <Typography variant="Text/xs/Medium">{params.colDef.headerName}</Typography>,
		},
		{
			field: "ask",
			headerName: "Asking",
			sortable: false,
			filterable: false,
			wrapText: true,
			renderCell: (params) => <Typography variant="Text/xs/Regular">{formatDollar(params.value)}</Typography>,
			renderHeader: (params) => <Typography variant="Text/xs/Medium">{params.colDef.headerName}</Typography>,
		},
	];
	return columns;
}

export const StartupSortOptions = [
	{ name: "Newest", colName: "createdAt" },
	{ name: "Score", colName: "currentScore" },
	{ name: "Founded", colName: "foundedYear" },
	{ name: "Co. Size", colName: "companySize" },
	{ name: "Cap. Raised", colName: "capitalRaised" },
	{ name: "Ask", colName: "ask" },
];
