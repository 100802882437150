import { DialogTitle, Dialog, IconButton, Typography, Divider, DialogContent, DialogActions, Slide, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { Fragment } from "react";
import theme from "../../constants/Theme";
const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="left" ref={ref} {...props} />;
});

const NoTransition = React.forwardRef(function Transition(props, ref) {
	return <Box direction="left" ref={ref} {...props} />;
});
export default function CommonSlideDialog({
	isOpen,
	onClose,
	title,
	children,
	dialogAction,
	titleVariant,
	contentStyle,
	footerStyle,
	titleStyle,
	colseIconStyle,
	disableBackDropClick,
	transitionDuration = 600,
	closeButton = true,
	addtlCloseComp,
	slidingEffect = true,
	...props
}) {
	const handleClose = () => {
		onClose();
	};
	return (
		<Dialog
			TransitionComponent={slidingEffect ? Transition : NoTransition}
			transitionDuration={transitionDuration}
			aria-describedby="alert-dialog-slide-description"
			keepMounted
			open={isOpen}
			onClose={disableBackDropClick ? () => {} : onClose}
			PaperProps={{ sx: { position: "fixed", top: 50, left: 50, m: 0 } }}
			{...props}
		>
			{title && (
				<DialogTitle
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
						...titleStyle,
					}}
				>
					<Typography sx={{ lineHeight: "initial" }} variant={titleVariant}>
						{title}
					</Typography>
					{closeButton && (
						<Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
							<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
								<IconButton
									aria-label="close"
									onClick={handleClose}
									sx={{
										color: theme.palette.secondary.dark,
										...colseIconStyle,
									}}
								>
									<CloseIcon sx={{ color: (theme) => theme.palette.secondary.black }} />
								</IconButton>
							</Box>
							{addtlCloseComp}
						</Box>
					)}
				</DialogTitle>
			)}
			<DialogContent
				sx={{
					...contentStyle,
				}}
			>
				{children}
			</DialogContent>
			{dialogAction && (
				<DialogActions
					sx={{
						...footerStyle,
					}}
				>
					{dialogAction}
				</DialogActions>
			)}
		</Dialog>
	);
}
