import React, { Fragment, useEffect, useState } from "react";
import { Box } from "@mui/system";
import { Avatar, Link, Typography } from "@mui/material";
import { Person } from "@mui/icons-material";
import RatingComponent from "../../../common/rating";
import { FullReview } from "./FullReview";
import theme from "../../../constants/Theme";

const ReviewText = ({ ...props }) => {
  const [showModal, setShowModal] = useState(false);
  if (
    props.review &&
    props.review.description &&
    props.review.description.length > 100
  ) {
    let tempStr = props.review.description.slice(0, 100) + "...";

    return (
      <Fragment>
        <Box>
          {tempStr}{" "}
          <Link
            underline={"none"}
            sx={{ cursor: "pointer" }}
            onClick={() => {
              if (!props.review.isRatedByMe) {
                setShowModal(true);
              }
            }}
          >
            Read More
          </Link>
        </Box>
        {/* We need to make sure not to compete with the potential 'edit' modal for reviews 'isRatedByMe'. See ReviewWrapper */}
        {!props.review.isRatedByMe && (
          <FullReview
            isOpen={showModal}
            onClose={() => setShowModal(false)}
            review={props.review}
          />
        )}
      </Fragment>
    );
  } else {
    return (
      <Typography variant="body_large">
        {props.review && props.review.description
          ? props.review.description
          : ""}
      </Typography>
    );
  }
};

const ReviewWrapper = ({ ...props }) => {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <Box
        key={props.key}
        sx={theme => ({
          cursor: props.review.isRatedByMe ? "pointer" : "inherit",
          border: props.review.isRatedByMe
            ? ""
            : "1px solid rgba(124, 117, 127, 0.16)",
          borderRadius: theme.shape.standard.borderRadius,
          p: 2,
          transition: "all .3s",
          background: props.review.isRatedByMe
            ? "rgba(106, 117, 117, 0.12)"
            : "inherit",
          "&:hover": {
            background: props.review.isRatedByMe
              ? "rgba(106, 117, 117, 0.12)"
              : "rgba(118, 73, 160, 0.08)",
            boxShadow:
              "0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
          },
        })}
        onClick={() => {
          if (props.review.isRatedByMe) {
            setShowModal(true);
          }
        }}
      >
        {props.children}
      </Box>
      {props.review.isRatedByMe && (
        <FullReview
          isOpen={showModal}
          onClose={() => setShowModal(false)}
          review={props.review}
          title={"My Review"}
        />
      )}
    </>
  );
};

export const ReviewTile = ({ reviewObj, key, parentWidth }) => {
  const [review, setReview] = useState(reviewObj || {});
  const [user, setUser] = useState({});

  useEffect(() => {
    setReview(reviewObj);
    if (reviewObj.userModel) {
      setUser(reviewObj.userModel);
    }
  }, [reviewObj]);

  return (
    <ReviewWrapper key={key} review={review}>
      <Box
        sx={{
          display: "flex",
          gap: parentWidth > 350 ? 2 : 1,
        }}
      >
        <Avatar sx={{ backgroundColor: "#DADCE0", color: "#9AA0A6" }}>
          {/* Needs to have avatar image/icon URL in the object */}
          {/* Users do not yet have an image attached to their profile. */}
          <Person />
        </Avatar>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "4px",
            alignItems: "flex-start",
            justifyContent: "flex-start",
          }}
        >
          <Typography
            component={"p"}
            variant="label_xlarge"
            sx={{ wordBreak: "break-all" }}
          >
            {user.name || user.email}
          </Typography>
          <Typography variant="label_large">{user.companyName}</Typography>
          <RatingComponent
            rating={review.rating}
            iconColor="#4B454E"
            color="#4B454E"
          />
        </Box>
      </Box>
      <Box sx={{ mt: 2 }}>
        <ReviewText review={review} />
      </Box>
    </ReviewWrapper>
  );
};
