import React from "react";
import { FormControl, Select, MenuItem, InputLabel, Box, TextField } from "@mui/material";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";

export default function UpdatedSelectInput({
	label,
	onInputChange,
	selectedValue,
	fullWidth,
	options,
	inputKey,
	placeholder,
	labelStyle = {},
	borderNone = false,
	children,
	labelId = "select-label",
	id = "demo-simple-select",
	displayEmpty = false,
	compact = false,
	multiple = false,
	height,
	handleMenuOpen,
	handleMenuClose,
	...props
}) {
	return (
		<FormControl fullWidth={fullWidth} sx={{ ...props.wrapperstyle }}>
			{label && (
				<InputLabel id="select-label" sx={labelStyle}>
					{label}
				</InputLabel>
			)}
			<Select
				multiple={multiple}
				displayEmpty
				placeholder={placeholder}
				labelId={labelId}
				id={id}
				onOpen={(e) => handleMenuOpen(true)}
				onClose={(e) => handleMenuClose(false)}
				label={label}
				fullWidth={fullWidth}
				IconComponent={KeyboardArrowDownRoundedIcon}
				select
				onChange={(e) => onInputChange(e, inputKey)}
				value={selectedValue || []}
				{...props}
				PaperProps={{
					sx: {
						borderRadius: "40px",
					},
				}}
				sx={{
					width: compact ? "max-content" : 1,
					height: height,
					"& .MuiOutlinedInput-notchedOutline": {
						border: borderNone && "none",
					},
					"& .MuiSvgIcon-root": {
						color: (theme) => theme.palette.secondary.black,
					},
					"& .MuiMenu-root ": {
						borderRadius: "40px",
					},
				}}
				//renderValue={
				//  !multiple &&
				//  (selectedValue => {
				//    if (selectedValue?.length === 0) {
				//      return placeholder;
				//    }
				//    return selectedValue;
				//  })
				//}
			>
				{children
					? children
					: options?.length > 0 &&
					  options?.map((option) => (
							<MenuItem key={option} value={option}>
								{option}
							</MenuItem>
					  ))}
			</Select>
		</FormControl>
	);
}
