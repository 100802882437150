import { Box, Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import useDimensions from "react-cool-dimensions";
import _ from "lodash";

function ColumnControl({ children, minWidth, wrapperSX, itemSX }) {
	const [isTwoCol, setIsTwoCol] = useState(false);

	const triggerWidth = minWidth;

	const { observe, unobserve, width, height, entry } = useDimensions({
		onResize: _.throttle(() => {
			setIsTwoCol(width && width >= triggerWidth);
		}, 500),
	});

	useEffect(() => {
		observe();
	}, []);

	useEffect(() => {
		setIsTwoCol(width && width >= triggerWidth);
	}, [width]);

	return (
		<Box
			ref={observe}
			sx={{
				...(isTwoCol
					? {
							...wrapperSX?.beforeStyle,
					  }
					: {
							...wrapperSX?.afterStyle,
					  }),
				...wrapperSX?.style,
			}}
		>
			{React.Children &&
				children &&
				React.Children.map(children, (child, index) => (
					<Box
						sx={{
							...(isTwoCol
								? {
										...itemSX?.beforeStyle,
										...child?.props?.beforeStyle,
								  }
								: {
										...itemSX?.afterStyle,
										...child?.props?.afterStyle,
								  }),
							...itemSX?.style,
							...child?.props?.style,
						}}
					>
						{child}
					</Box>
				))}
		</Box>
	);
}

export default ColumnControl;
