import React, { useState, useEffect } from "react";
import { useLocation, useHistory, matchPath } from "react-router-dom";
import { Grid, Box, Typography, Button, CircularProgress, TextField, Divider, Badge, Stack, Avatar, useMediaQuery, Link } from "@mui/material";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import FileDownloadDoneOutlinedIcon from "@mui/icons-material/FileDownloadDoneOutlined";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import theme from "../../../constants/Theme";
import constants, { BUSINESS_MODEL_TYPE, FUNDING_TYPES, INDUSTRY_TYPES, REVENUE_MODEL_TYPE } from "../../../constants";
import CloseIcon from "@mui/icons-material/Close";
import LoadingButton from "../../../common/Loadingbutton";
import { RICH_TEXT_EDITOR_INLINE_CONTROLS, RICH_TEXT_EDITOR_MAIN_CONTROLS } from "../../../constants/plugin-constants";
import SelectInput from "../../../common/SelectInput";
import CustomizedDatePicker from "../../../components/Workspace/PowerUp/Datepicker";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import EditTeam from "../Teams/EditTeam";
import ScriptEditor from "../../../common/ScriptEditor";
import { CURRENT_STAGE_LIST, EXPERTISE_LIST, FINANCING_LIST, SEEKING_LIST, WHATS_YOUR_INDUSTRY_FOCUS } from "../../../constants/updated-onboarding";
import AutocompleteInput from "../../../common/AutocompleteInput";
import MessageHandlerModal from "../../../common/modal/MessageHandler";
import DialogComponent from "../../../common/CommonDialog";
import CustomMuiChip from "../../../common/CustomMuiChip";
import InputAdornment from "@mui/material/InputAdornment";
import ProfileProgress from "../../../common/ProfileProgress";
import StartupOsTextField from "../../../common/StartupOsInput/StartupOsTextField";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import UnpublishedOutlinedIcon from "@mui/icons-material/UnpublishedOutlined";
import CompleteYourProfile from "../../../common/CompleteYourProfile";
import { COVER_DEFAULT_IMAGE, CAMERA_ICON, NEW_STARTUP_LOGO } from "../../../constants/image";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { SmallAvatar } from "../MyProfile/styles";
import UploadImageModal from "../../../common/UploadImageModal";
import { InvestorToggleSwitch } from "../styles";
import { PITCH_DECK_LOGO } from "../../../constants/image";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import UploadVideoModal from "../../../common/UploadVideoModal";
import AddVideo from "../../../common/AddVideo";
import VideocamRoundedIcon from "@mui/icons-material/VideocamRounded";
import VideoPlayerComponent from "../../../common/videoPlayer";
import UploadFileModal from "../../../common/UploadFile";
import { REGEX } from "../../../constants/regex";
import messages from "../../../constants/messages";
import RouterPrompt from "../../../common/RouterPrompt";
import SelectCheckedInput from "../../../common/SelectCheckedInput";
import SelectIndustryModal from "../../../components/MyStartup/SelectIndustryModal";
import { getListOfCategoryApiCall } from "../../Investor/Dashboard/Functions/common";
import ROUTES_PATH from "../../../constants/routes";
import FirstbaseCTA from "../../../components/FirstbaseCTA";
import StartupOSCoPilot from "./StartupOSCoPilot";
import AvatarWithFallBack from "../../../common/AvatarWithFallBack";
import { startupProfileProgress } from "../../../utils/profilePercentageCalculation";

let FileType = {
	img: ".png, .jpg, .jpeg",
	all: ".pdf, .xlsx, .docx, .csv, .ppt, .pptx, .key, .xls, .odt, .txt",
	video: ".mp4, .3gp, .avi, .webm, .ogg",
};

export default function EditForm({
	saveDisabled,
	saveLoading,
	handleCloseForm,
	handleChange,
	handleUploadAction,
	handleValidations,
	companyDataError,
	setCompanyDataError,
	showOther,
	setShowOther,
	about,
	logo,
	loading,
	setLoading,
	handleClear,
	browseFlag,
	setSaveDisabled,
	handleSave,
	browseVidFlag,
	payload,
	vidLoading,
	compLocation,
	postalCode,
	inputChange,
	setBrowseFlag,
	setOpenVideoUpload,
	openVideoUpload,
	setBrowseVidFlag,
	handleDeleteUploadAttachment,
	handleDeleteImageUploadAttachment,
	investerToggle,
	isInvestorView,
	setIsInvestorView,
	handleUpdateAssetsUrl,
	handleUploadPitchDeck,
	handleGetHarmonicData,
	harmonicUpdate,
	setHarmonicUpdate,
	setHarmonicOpen,
	discard,
}) {
	const [productOfferingData, setProductOfferingData] = useState(constants.PRODUCT_OFFERING);
	const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
	const [aboutText, setAboutText] = useState(payload?.about);
	const [version, setVersion] = useState(1);
	const [confirmPopup, setConfirmPopup] = useState(false);
	const [maxFile, setMaxFile] = useState(false);
	const [attachmentName, setAttachmentName] = useState("");
	const [selectedIndustries, setSelectedIndustries] = useState(payload?.industry);

	//const [openVideoUpload, setOpenVideoUpload] = useState(false);

	const [lastImageSegment, setLastImageSegment] = useState("");
	const [lastVideoSegment, setLastVideoSegment] = useState("");
	const [openBackgroundPhotoModal, setOpenBackgroundPhotoModal] = useState(false);
	const [openProfilePhotoModal, setOpenProfilePhotoModal] = useState(false);
	const [backgroundPhoto, setBackgroundPhoto] = useState(payload?.coverImgUrl);
	const [profilePhoto, setProfilePhoto] = useState();
	const [showDeleteButton, setShowDeleteButton] = useState(false);
	const [deleteConfirmPopup, setDeleteConfirmPopup] = useState(false);

	const [openAboutUsVideoModal, setOpenAboutUsVideoModal] = useState(false);
	const [openFounderStoryVideoModal, setOpenFounderStoryVideoModal] = useState(false);
	const [openDeck, setOpenDeck] = useState(false);
	const [pitchDeck, setPitchDeck] = useState("");

	const [aboutUsVideoUploadUrl, setAboutUsVideoUploadUrl] = useState(payload?.aboutUsVideoUrl);
	const [founderStoryVideoUploadUrl, setFounderStoryVideoUploadUrl] = useState(payload?.founderStoryVideo);
	const [profileCompletion, setProfileCompletion] = useState(Math.round(startupProfileProgress(payload).totalPercentage || 0));

	const location = useLocation();
	const history = useHistory();
	const [currentPath, setCurrentPath] = useState();
	const [pitchType, setPitchType] = useState("");
	const [openIndustryModal, setOpenIndustryModal] = useState(false);
	const [industryList, setIndustryList] = useState([]);
	const [productOfferingList, setProductOfferingList] = useState([]);
	const [businessTypeList, setBusinessTypeList] = useState([]);
	const [revenueTypeList, setRevenueTypeList] = useState([]);

	const shouldShowPrompt = () => {
		return discard;
	};

	useEffect(() => {
		setCurrentPath(location.pathname);

		getCategoryList("industry");
		getCategoryList("product-offering");
		getCategoryList("business-type");
		getCategoryList("revenue-model-type");
	}, []);
	useEffect(() => {
		try {
			payload["industry"] = selectedIndustries;
		} catch (e) {
			console.log(e);
		}
	}, [selectedIndustries]);

	const getCategoryList = (category) => {
		getListOfCategoryApiCall(category)
			.then((res) => {
				const responseData = res.data;
				let categoryArray = [];
				responseData.map((categoryItem) => {
					categoryArray.push(categoryItem?.lovValue);
				});
				if (category === "industry") {
					setIndustryList(categoryArray);
				} else if (category === "product-offering") {
					setProductOfferingList(categoryArray);
				} else if (category === "business-type") {
					setBusinessTypeList(categoryArray);
				} else if (category === "revenue-model-type") {
					setRevenueTypeList(categoryArray);
				}
			})
			.catch((e) => {
				console.log("Error", e);
			});
	};

	//useEffect(() => {
	//  history.listen(prompt => {
	//    if (prompt.pathname != currentPath) {
	//      setCurrentPath(prompt.pathname);
	//      setConfirmPopup(true);
	//      return "true";
	//    } else {
	//      //history.block(() => {});
	//      //return false;
	//    }
	//  });
	//}, [history]);

	useEffect(() => {
		if (payload?.logoUrl) {
			setProfilePhoto(payload?.logoUrl);
			if (harmonicUpdate) {
				setAboutText(payload?.about);
				setVersion((version) => version + 1);
				setHarmonicUpdate(false);
			}
		}
		const { totalPercentage } = startupProfileProgress(payload);
		setProfileCompletion(totalPercentage);
	}, [payload]);

	const getUrlParams = (url) => {
		const finalUrl = url;
		return finalUrl.split("/").pop();
	};

	useEffect(() => {
		if (payload?.pitchDeckLink) {
			setPitchDeck(payload?.pitchDeckLink);
		}
		if (payload?.videoUrl && payload?.videoUrl !== null) {
			setLastVideoSegment(getUrlParams(payload?.videoUrl));
			setBrowseVidFlag(true);
		} else {
			setBrowseVidFlag(false);
		}
	}, []);

	useEffect(() => {
		if (pitchDeck) {
			try {
				let type = pitchDeck?.substr(pitchDeck?.length - 3);
				if (type === "pdf") {
					setPitchType("pdf");
				} else {
					setPitchType("ppt");
				}
			} catch (e) {
				console.log(e);
			}
		} else if (payload?.pitchDeckLink) {
			try {
				let type = payload?.pitchDeckLink?.substr(payload?.pitchDeckLink?.length - 3);
				if (type === "pdf") {
					setPitchType("pdf");
				} else {
					setPitchType("ppt");
				}
			} catch (e) {
				console.log(e);
			}
		}
	}, [pitchDeck, payload]);

	useEffect(() => {
		if (payload?.logoUrl && payload?.logoUrl !== null) {
			setLastImageSegment(getUrlParams(payload?.logoUrl));
			setBrowseFlag(true);
		} else {
			setBrowseFlag(false);
		}
	}, []);

	useEffect(() => {
		//setAboutText(payload.about);
		//setVersion(version => version + 1);
	}, []);

	const handleAboutChange = (value) => {
		handleChange(value.value, "about");
		setAboutText(value.value);
		inputChange();
	};

	const handleUploadClose = () => {
		setOpenVideoUpload(false);
	};

	const handleFileChange = (type) => (event) => {
		const file = event.target.files[0];
		setAttachmentName(file?.name);
		const fileSize = (file?.size / 1024 / 1024).toFixed(2);

		if (file === undefined) {
			handleUploadAction("", "");
			if (type == "img") {
				setCompanyDataError((prevState) => ({
					...prevState,
					logoError: "Please attach a logo",
				}));
			} else if (type == "video") {
				setCompanyDataError((prevState) => ({
					...prevState,
					videoError: "Please attach a video",
				}));
			} else {
				setCompanyDataError((prevState) => ({
					...prevState,
					pitchDeckError: "Please attach a pitch deck",
				}));
			}
		} else if (fileSize >= 100) {
			setMaxFile(true);
		} else {
			setMaxFile(false);
			setOpenVideoUpload(true);
			handleUploadAction(file, type);
		}
	};

	const uploadButton = (type, handler) => {
		return (
			<Button
				variant="outlined"
				startIcon={!loading && (browseFlag ? <FileDownloadDoneOutlinedIcon /> : <FileUploadOutlinedIcon />)}
				sx={{
					width: "max-content",
				}}
				component="label"
			>
				{/*{loading ? (
          <CircularProgress
            sx={{
              display: "flex",
              justifyContent: "center",
              maxHeight: "25px",
            }}
          />
        ) : browseFlag ? (
          "Uploaded"
        ) : (
          "Upload"
        )}*/}
				{browseFlag ? "Uploaded" : "Upload"}
				<input
					type="file"
					accept={FileType[type]}
					hidden
					multiple={false}
					//onChange={handleSave}
					onChange={handleFileChange(type)}
				/>
			</Button>
		);
	};

	const getSelectedFundingFormatted = (input) => {
		if (input) {
			try {
				let inputParsed = JSON.parse(input);
				if (Array.isArray(inputParsed)) {
					return inputParsed;
				} else {
					return [inputParsed];
				}
			} catch (error) {
				return [];
			}
		} else {
			return [];
		}
	};

	const getSelectedExpertiseFormatted = (input) => {
		if (input) {
			try {
				let inputParsed = JSON.parse(input);
				if (Array.isArray(inputParsed)) {
					return inputParsed;
				} else {
					return [inputParsed];
				}
			} catch (error) {
				//return [input];
				return input.split(",");
			}
		} else {
			return [];
		}
	};

	const handleRequestCancelConfirm = (action) => {
		//if (action === "cancel") {
		//  handleCloseForm();
		//} else {
		//  history.block(() => {});
		//  history.push(currentPath);
		//}
		handleCloseForm();
		setConfirmPopup(false);
		setSaveDisabled(true);
	};

	const handleCancel = () => {
		if (!saveDisabled) {
			setConfirmPopup(true);
		} else {
			handleCloseForm();
		}
	};

	const handleBlur = (e, key) => {
		const value = e.target.value;

		switch (key) {
			//case "phone":
			//  if (REGEX.US_PHONE.test(value)) {
			//    setCompanyDataError(prevState => ({
			//      ...prevState,
			//      phoneError: "",
			//    }));
			//  } else {
			//    setCompanyDataError(prevState => ({
			//      ...prevState,
			//      phoneError: messages.INVALID_PHONE_NUMBER,
			//    }));
			//  }
			//  break;

			case "websiteLink":
				if (value.length) {
					let val = "https://" + value;
					if (val.match(REGEX.VALID_URL)) {
						setCompanyDataError((prevState) => ({
							...prevState,
							companySiteError: "",
						}));
						setSaveDisabled(false);
					} else {
						setCompanyDataError((prevState) => ({
							...prevState,
							companySiteError: messages.INVALID_URL,
						}));
						setSaveDisabled(true);
					}
				} else {
					setCompanyDataError((prevState) => ({
						...prevState,
						companySiteError: messages.INVALID_URL,
						companySiteError: "",
					}));
					setSaveDisabled(false);
				}
				break;

			case "linkedInUrl":
				if (!value) {
					setCompanyDataError((prevState) => ({
						...prevState,
						linkedinError: messages.INVALID_LINKEDIN_COMPANY_URL,
					}));
					setSaveDisabled(true);
				} else if (!REGEX.LINKEDIN_NAME.test(value)) {
					setCompanyDataError((prevState) => ({
						...prevState,
						linkedinError: messages.INVALID_LINKEDIN_COMPANY_URL,
					}));
					setSaveDisabled(true);
				} else {
					setCompanyDataError((prevState) => ({
						...prevState,
						linkedinError: "",
					}));
					setSaveDisabled(false);
				}
				break;

			case "city":
				if (!e.target.value || e.target.value == "") {
					setCompanyDataError((prevState) => ({
						...prevState,
						cityError: "Please enter your city",
					}));
					setSaveDisabled(true);
				} else {
					setCompanyDataError((prevState) => ({
						...prevState,
						cityError: "",
					}));
					setSaveDisabled(false);
				}

				break;

			case "state":
				if (!e.target.value || e.target.value == "") {
					setCompanyDataError((prevState) => ({
						...prevState,
						stateError: "Please enter your state",
					}));
					setSaveDisabled(true);
				} else {
					setCompanyDataError((prevState) => ({
						...prevState,
						stateError: "",
					}));
					setSaveDisabled(false);
				}

				break;

			case "fundingType":
				if (!e.target.value || e.target.value == "") {
					setCompanyDataError((prevState) => ({
						...prevState,
						fundingTypeError: "Funding type cannot be empty",
					}));
					setSaveDisabled(true);
				} else {
					setCompanyDataError((prevState) => ({
						...prevState,
						fundingTypeError: "",
					}));
					setSaveDisabled(false);
				}
		}

		handleValidations();
	};

	return (
		<>
			<Box sx={{ px: { lg: 6, md: 2, xs: 0 } }}>
				<RouterPrompt when={shouldShowPrompt} stepPath={""} />
				<Grid container spacing={2}>
					<Grid
						item
						xs={12}
						md={12}
						sx={{
							display: "flex",
							width: "100%",
							flexDirection: "row",
							justifyContent: "space-between!important",
						}}
					>
						<Grid container>
							<Grid item xs={12} md={4}>
								<Box>
									<Typography variant="headline3">Edit Startup Profile</Typography>
								</Box>
							</Grid>
							<Grid
								item
								xs={12}
								md={8}
								sx={{
									display: "flex",
									width: "100%",
									flexDirection: "row",
									alignItems: "center",
									justifyContent: !isSmallScreen ? "end" : "start",
									pt: { xs: 2, md: 0 },
								}}
							>
								<Box>
									<Typography variant="label_large" sx={{ color: theme.palette.secondary.korophKoal }}>
										Allow Investors to View Profile
									</Typography>
								</Box>
								<Box
									sx={{
										cursor: "pointer",
									}}
								>
									<InvestorToggleSwitch checked={investerToggle} setIsInvestorView={setIsInvestorView} />
								</Box>
							</Grid>
						</Grid>
					</Grid>

					<Grid item xs={12} sx={{ mt: 5 }}>
						<ProfileProgress percentage={profileCompletion} />
					</Grid>

					<Grid item xs={12} md={12}>
						<Box sx={{ position: "relative", pt: 3 }}>
							{!matchPath(location.pathname, { path: ROUTES_PATH.EDIT_MY_STARTUP_PROFILE }) && (
								<Box
									textAlign={"center"}
									sx={(theme) => ({
										mb: 1,
									})}
								>
									<img
										style={{
											width: "100%",
											borderRadius: "8px",
										}}
										src={backgroundPhoto ? backgroundPhoto : COVER_DEFAULT_IMAGE}
									/>
								</Box>
							)}
							<Box>
								<Grid container mt={3}>
									<Grid item xs={6}>
										<Box
											sx={{
												pl: { xs: 2, md: 4 },
												position: "absolute",
												bottom: "-80px",
											}}
										>
											<Badge
												overlap="circular"
												anchorOrigin={{
													vertical: "bottom",
													horizontal: "right",
												}}
												badgeContent={
													<SmallAvatar
														onClick={() => setOpenProfilePhotoModal(true)}
														src={CAMERA_ICON}
														sx={{
															height: "40px",
															width: "40px",
															border: "none",
															cursor: "pointer",
														}}
													/>
												}
											>
												<AvatarWithFallBack
													// alt="profile_picture"
													placeHolder={NEW_STARTUP_LOGO}
													src={profilePhoto}
													sx={{
														height: "120px",
														width: "120px",
														border: !profilePhoto ? "none" : `3px solid ${theme.palette.background.paper}`,
														borderRadius: "12px",
													}}
												/>
											</Badge>
										</Box>
									</Grid>
									{!matchPath(location.pathname, { path: ROUTES_PATH.EDIT_MY_STARTUP_PROFILE }) && (
										<Grid item xs={6}>
											{!isSmallScreen ? (
												<Box
													sx={{
														width: "max-content",
														mr: { xs: 1, md: 3 },
														mb: 3,
														background:
															"linear-gradient(0deg, rgba(106, 117, 117, 0.02), rgba(106, 117, 117, 0.02)), linear-gradient(0deg, rgba(245, 248, 250, 0.05), rgba(245, 248, 250, 0.05)), #FCFCFC",
														boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
														borderRadius: "4px",
														position: "absolute",
														bottom: "10px",
														right: 0,
													}}
												>
													<Button
														variant="outlined"
														startIcon={<PhotoCameraIcon />}
														onClick={() => {
															setOpenBackgroundPhotoModal(true);
														}}
													>
														Edit cover image
													</Button>
												</Box>
											) : (
												<Box
													sx={{
														width: "max-content",
														mr: { xs: 1, md: 3 },
														mb: 1,
														position: "absolute",
														bottom: "10px",
														right: 0,
													}}
												>
													<PhotoCameraIcon
														sx={(theme) => ({
															color: theme.palette.primary.main,
															background: "#fff",
															p: 1,
															borderRadius: "100px",
														})}
														onClick={() => {
															setOpenBackgroundPhotoModal(true);
														}}
													/>
												</Box>
											)}
										</Grid>
									)}
								</Grid>
							</Box>
							<Box
								sx={{
									display: "flex",
									flexDirection: { xs: "column-reverse", md: "row" },
									gap: 2,
									float: "right",
									alignItems: "center",
									display: { xs: "none", md: "flex" },
									mt: 5,
								}}
							>
								<StartupOSCoPilot
									{...{
										handleSave,
										saveLoading,
										saveDisabled,
									}}
								/>
								<CloseIcon
									onClick={handleCancel}
									sx={{
										fill: theme.palette.secondary.main,
										cursor: "pointer",
									}}
								/>
								<MessageHandlerModal
									isOpen={confirmPopup}
									onCancel={() => setConfirmPopup(false)}
									onOk={() => handleRequestCancelConfirm("cancel")}
									okText="Ok"
									cancelText={"Cancel"}
									heading="Confirm"
									text={"Are you sure you want to abandon your changes?"}
									messageType="primary"
								/>
							</Box>
						</Box>
					</Grid>

					<Grid item xs={12} md={12}>
						<Box
							sx={{
								pt: { xs: 8, md: 1 },
								pl: { xs: 0, md: 4 },
								pr: { xs: 0, md: 3 },
							}}
						>
							<Divider
								sx={{
									mt: 3,
									borderColor: "secondary.light",
								}}
							/>
						</Box>
					</Grid>

					{/*<Box sx={{ pl: { xs: 0, md: 4 }, pr: { xs: 0, md: 3 } }}>
            <Divider sx={{ mt: 3, borderColor: "secondary.light" }} />
          </Box>*/}
					{/*<Grid item xs={12} md={12} sx={{ mt: 2 }}>
            <Typography variant="title_medium">Logo</Typography>
          </Grid>
          <Grid item xs={12} md={12} sx={{ mb: 4 }}>
            {browseFlag ? (
              <Box sx={{ width: "max-content", mb: 2, mt: "-10px" }}>
                <CustomMuiChip
                  startIcon={
                    <ImageOutlinedIcon
                      sx={theme => ({
                        fill: theme.palette.primary.main,
                      })}
                    />
                  }
                  endIcon={<CloseIcon style={{ marginLeft: "auto" }} />}
                  label={
                    <Typography
                      variant="onsurface_subtext_medium"
                      sx={theme => ({
                        color: theme.palette.secondary.main,
                      })}
                    >
                      {`${attachmentName}`
                        ? `${attachmentName}`
                        : `${lastImageSegment}`}
                    </Typography>
                  }
                  onDelete={handleDeleteImageUploadAttachment}
                />
              </Box>
            ) : (
              <Button
                variant="outlined"
                startIcon={
                  !loading &&
                  (browseFlag ? (
                    <FileDownloadDoneOutlinedIcon />
                  ) : (
                    <FileUploadOutlinedIcon />
                  ))
                }
                sx={{
                  width: "max-content",
                  mb: 2,
                }}
                component="label"
              >
                Upload
                <input
                  type="file"
                  accept={FileType["img"]}
                  hidden
                  multiple={false}
                  onChange={handleFileChange("img")}
                />
              </Button>
            )}
          </Grid>*/}
				</Grid>

				<Grid
					container
					spacing={4}
					sx={{
						display: "flex",
						flexDirection: "row",
						mt: 4,
						pl: { xs: 0, md: 4 },
						pr: { xs: 0, md: 3 },
					}}
				>
					<Grid sx={{ mb: 2 }} item xs={12} md={12}>
						<Typography variant="subhead6">Profile Intro</Typography>
					</Grid>
					<Grid item xs={12} md={12}>
						{/*<TextField
              label="Startup Name*"
              spellCheck="true"
              fullWidth
              onChange={(e, type) => {
                handleChange(e, "companyName");
              }}
              onInput={inputChange}
              onBlur={handleValidations}
              error={!!companyDataError?.companyNameError}
              helperText={companyDataError?.companyNameError}
              value={payload?.companyName}
              inputProps={{ maxLength: "255", minLength: "2" }}
            />*/}
						<StartupOsTextField
							label="Startup Name"
							placeholder="Startup Name"
							spellCheck="true"
							fullWidth
							onChange={(e, type) => {
								handleChange(e, "companyName");
							}}
							onInput={inputChange}
							onBlur={handleValidations}
							error={!!companyDataError?.companyNameError}
							//helperText={companyDataError?.companyNameError ? "*required" : ""}
							// helperText={"*required"}
							value={payload?.companyName}
							inputProps={{ maxLength: "255", minLength: "2" }}
							// required
						/>
					</Grid>
					<Grid item xs={12} md={12}>
						<StartupOsTextField
							label="Tagline"
							fullWidth
							spellCheck="true"
							onChange={(e, type) => {
								handleChange(e, "tagLine");
							}}
							onInput={inputChange}
							error={!!companyDataError.taglineError}
							helperText={companyDataError.taglineError}
							value={payload?.tagLine}
							inputProps={{ maxLength: "255", minLength: "2" }}
						/>
					</Grid>
					<Grid item xs={12} md={4}>
						<StartupOsTextField
							fullWidth
							label={"City"}
							spellCheck="true"
							value={payload?.city}
							helperText={companyDataError?.cityError ? companyDataError?.cityError : ""}
							onChange={(e, type) => {
								handleChange(e, "city");
							}}
							onInput={inputChange}
							onBlur={(e) => handleBlur(e, "city")}
							error={!!companyDataError?.cityError}
						/>
					</Grid>
					<Grid item xs={12} md={4}>
						<StartupOsTextField
							fullWidth
							label={"State"}
							spellCheck="true"
							helperText={companyDataError?.stateError ? companyDataError?.stateError : ""}
							value={payload?.state}
							onChange={(e, type) => {
								handleChange(e, "state");
							}}
							onInput={inputChange}
							onBlur={(e) => handleBlur(e, "state")}
							error={!!companyDataError?.stateError}
						/>
					</Grid>
					<Grid item xs={12} md={4}>
						<StartupOsTextField
							label="Zip Code"
							fullWidth
							spellCheck="true"
							onChange={(e, type) => {
								handleChange(e, "zipcode");
							}}
							onInput={inputChange}
							value={payload?.zipcode}
							onBlur={handleValidations}
							error={!!companyDataError?.locationError}
							//helperText={companyDataError?.locationError}
							inputProps={{ maxLength: "25", minLength: "2" }}
							//onClick={handleClear}
							helperText={companyDataError?.locationError ? companyDataError?.locationError : ""}
						/>
					</Grid>
					{/*<Grid item xs={12} md={5}>
            <TextField
              label="LinkedIn Profile"
              spellCheck="true"
              fullWidth
              onChange={(e, type) => {
                handleChange(e, "linkedInUrl");
              }}
              onInput={inputChange}
              error={!!companyDataError.linkedinError}
              helperText={companyDataError.linkedinError}
              inputProps={{ minLength: "2" }}
              value={payload.linkedInUrl}
              onBlur={handleValidations}
            />
          </Grid>*/}
				</Grid>
				<Box sx={{ pl: { xs: 0, md: 4 }, pr: { xs: 0, md: 3 } }}>
					<Divider sx={{ pt: 3 }} />
				</Box>
				<Grid container spacing={4} sx={{ pl: { xs: 0, md: 4 }, pr: { xs: 0, md: 3 } }}>
					<Grid
						item
						xs={12}
						md={7}
						sx={{
							display: "flex",
							flexDirection: "row",
							width: 1,
							mt: 2,
						}}
					>
						<Box
							sx={{
								display: "flex",
								width: 1,
								flexDirection: "column",
								gap: 2,
								height: "max-content",
							}}
						>
							<Grid item xs={12} md={12}>
								<Box
									sx={{
										width: 1,
									}}
								>
									<Typography variant="largeTitle">Elevator Pitch</Typography>
									<Box
										sx={(theme) => ({
											minHeight: "300px",
											border: `1px solid ${theme.palette.containerBorder.lightOutline}`,
											px: 2,
											mt: 4,
											borderRadius: theme.shape.standard.borderRadius,
										})}
									>
										<ScriptEditor
											minHeight={"300px"}
											version={version}
											label={"Elevator Pitch..."}
											initialValue={aboutText}
											onChange={(value) => handleAboutChange(value)}
											onInput={inputChange}
											readOnly={false}
											toolbar={true}
											inlineToolbar={false}
											inlineToolbarControls={RICH_TEXT_EDITOR_INLINE_CONTROLS}
											controls={RICH_TEXT_EDITOR_MAIN_CONTROLS}
											showValidatorIcon
										/>
									</Box>
								</Box>
							</Grid>

							{/*<Grid
                item
                xs={12}
                md={12}
                sx={{ display: "none", flexDirection: "column", mt: 2 }}
              >
                <Typography variant="title_medium">Video</Typography>
                {maxFile && (
                  <Box sx={{ py: 1 }}>
                    <Typography
                      variant="caption"
                      sx={theme => ({ color: theme.palette.error.main })}
                    >
                      Maximum upload file size : 100 MB
                    </Typography>
                  </Box>
                )}
                <Box sx={{ pt: 1, width: "max-content" }}>
                  {browseVidFlag ? (
                    <CustomMuiChip
                      startIcon={
                        <ImageOutlinedIcon
                          sx={theme => ({
                            fill: theme.palette.primary.main,
                          })}
                        />
                      }
                      endIcon={<CloseIcon style={{ marginLeft: "auto" }} />}
                      label={
                        <Typography
                          variant="onsurface_subtext_medium"
                          sx={theme => ({
                            color: theme.palette.secondary.main,
                          })}
                        >
                          {`${attachmentName}`
                            ? `${attachmentName}`
                            : `${lastVideoSegment}`}
                        </Typography>
                      }
                      onDelete={handleDeleteUploadAttachment}
                    />
                  ) : (
                    <Button
                      variant="outlined"
                      startIcon={
                        !vidLoading &&
                        (browseVidFlag ? (
                          <FileDownloadDoneOutlinedIcon />
                        ) : (
                          <FileUploadOutlinedIcon />
                        ))
                      }
                      sx={{
                        width: "max-content",
                        mb: 2,
                        mt: 1,
                      }}
                      component="label"
                    >
                      Upload
                      <input
                        type="file"
                        accept={FileType["video"]}
                        hidden
                        multiple={false}
                        onChange={handleFileChange("video")}
                      />
                    </Button>
                  )}

                  <DialogComponent
                    isOpen={openVideoUpload}
                    onClose={handleUploadClose}
                    title=""
                    titleVariant="title_medium"
                    maxWidth="xs"
                    dialogAction={() => {}}
                    contentStyle={{ px: 0 }}
                    footerStyle={{
                      border: "none",
                    }}
                    PaperProps={{
                      style: {
                        borderRadius: 8,
                        width: "100%",
                      },
                    }}
                    disableBackDropClick
                  >
                    <Box
                      sx={theme => ({
                        p: 2,
                        pb: 0,
                      })}
                    >
                      <Box
                        sx={theme => ({
                          display: "flex",
                          justifyContent: "center",
                        })}
                      >
                        <CircularProgress
                          sx={theme => ({
                            height: "80px",
                            width: "80px",
                            color: theme.palette.text.darkBlue,
                          })}
                        />
                      </Box>
                      <Box
                        sx={theme => ({
                          textAlign: "center",
                          pt: 5,
                        })}
                      >
                        <Typography variant="title_medium">
                          File upload in progress
                        </Typography>
                      </Box>
                    </Box>
                  </DialogComponent>
                </Box>
              </Grid>*/}

							<Grid item xs={12} md={12}>
								<Grid container flexDirection={"row"} sx={{ pt: 1 }}>
									<Grid item xs={12} md={6}>
										{!isSmallScreen && (
											<Stack flexDirection={"column"} gap={1} sx={{ pt: 5 }}>
												<Box>
													<Typography
														variant="Body/16pxBold"
														sx={(theme) => ({
															color: theme.palette.secondary.korophKoal,
														})}
													>
														Elevator Pitch Video
													</Typography>
												</Box>
												<Box>
													<Typography
														variant="body_large"
														sx={(theme) => ({
															color: theme.palette.secondary.outline,
														})}
													>
														Upload a video about your startup’s overall mission, values, and current progress.
													</Typography>
												</Box>
											</Stack>
										)}
										{isSmallScreen && (
											<Stack flexDirection={"column"} gap={1}>
												<Box>
													<Typography
														variant="Body/16pxBold"
														sx={(theme) => ({
															color: theme.palette.secondary.korophKoal,
														})}
													>
														Elevator Pitch Video
													</Typography>
												</Box>
												<Box>
													<Typography
														variant="body_large"
														sx={(theme) => ({
															color: theme.palette.secondary.outline,
														})}
													>
														Upload a video about your startup’s overall mission, values, and current progress.
													</Typography>
												</Box>
												<Box>
													<Stack flexDirection={"column"} gap={1}>
														{!aboutUsVideoUploadUrl && (
															<Box>
																<AddVideo
																	height={200}
																	handelAddMedia={() => {
																		setOpenAboutUsVideoModal(true);
																	}}
																/>
															</Box>
														)}
														{aboutUsVideoUploadUrl && (
															<Box sx={{ position: "relative" }}>
																<Box
																	sx={(theme) => ({
																		borderRadius: theme.shape.standard.borderRadius,
																		boxShadow: "none",
																		overflow: "hidden",
																		mt: 3,
																	})}
																>
																	<Box>
																		<VideoPlayerComponent height="200px" videoUrl={aboutUsVideoUploadUrl} />
																	</Box>
																</Box>
																<Box
																	sx={{
																		mr: 3,
																		background:
																			"linear-gradient(0deg, rgba(106, 117, 117, 0.02), rgba(106, 117, 117, 0.02)), linear-gradient(0deg, rgba(245, 248, 250, 0.05), rgba(245, 248, 250, 0.05)), #FCFCFC",
																		boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
																		borderRadius: "4px",
																		width: "max-content",
																		position: "absolute",
																		top: "48px",
																		right: 0,
																	}}
																>
																	<Button
																		variant="outlined"
																		startIcon={<VideocamRoundedIcon />}
																		onClick={() => {
																			setOpenAboutUsVideoModal(true);
																		}}
																	>
																		{aboutUsVideoUploadUrl ? "Edit Media" : "Add media"}
																	</Button>
																</Box>
															</Box>
														)}
													</Stack>
												</Box>
											</Stack>
										)}
									</Grid>
									<Grid item xs={12} md={6} sx={{ pl: { xs: 0, md: 2 }, pt: { xs: 3, md: 0 } }}>
										{!isSmallScreen && (
											<Stack flexDirection={"column"} gap={1} sx={{ pt: 5 }}>
												<Box>
													<Typography
														variant="Body/16pxBold"
														sx={(theme) => ({
															color: theme.palette.secondary.korophKoal,
														})}
													>
														Founder Story Video
													</Typography>
												</Box>
												<Box>
													<Typography
														variant="body_large"
														sx={(theme) => ({
															color: theme.palette.secondary.outline,
														})}
													>
														Upload a video about your startup’s unique story and background.
													</Typography>
												</Box>
												<Box sx={{ pt: 2 }}>
													<Typography
														variant="body_large"
														sx={(theme) => ({
															color: theme.palette.secondary.outline,
															fontStyle: "italic",
														})}
													>
														Note: Our{" "}
														<Link
															onClick={() => history.push("/workspace/powerUp/2")}
															underline="hover"
															color={"#1A73E8"}
															sx={{ cursor: "pointer" }}
														>
															Founder Story PowerUp
														</Link>{" "}
														walks you through crafting and organizing the perfect story.
													</Typography>
												</Box>
											</Stack>
										)}
										{isSmallScreen && (
											<Stack flexDirection={"column"} gap={1}>
												<Box>
													<Typography
														variant="Body/16pxBold"
														sx={(theme) => ({
															color: theme.palette.secondary.korophKoal,
														})}
													>
														Founder Story Video
													</Typography>
												</Box>
												<Box>
													<Typography
														variant="body_large"
														sx={(theme) => ({
															color: theme.palette.secondary.outline,
														})}
													>
														Upload a video about your startup’s unique story and background.
													</Typography>
												</Box>
												<Box sx={{ pt: 2 }}>
													<Typography
														variant="body_large"
														sx={(theme) => ({
															color: theme.palette.secondary.outline,
															fontStyle: "italic",
														})}
													>
														Note: Our{" "}
														<Link
															onClick={() => history.push("/workspace/powerUp/2")}
															underline="hover"
															color={"#1A73E8"}
															sx={{ cursor: "pointer" }}
														>
															Founder Story PowerUp
														</Link>{" "}
														walks you through crafting and organizing the perfect story.
													</Typography>
												</Box>
												<Box>
													<Stack flexDirection={"column"} gap={1}>
														{!founderStoryVideoUploadUrl && (
															<Box>
																<AddVideo
																	height={200}
																	handelAddMedia={() => {
																		setOpenFounderStoryVideoModal(true);
																	}}
																/>
															</Box>
														)}
														{founderStoryVideoUploadUrl && (
															<Box sx={{ position: "relative" }}>
																<Box
																	sx={(theme) => ({
																		borderRadius: theme.shape.standard.borderRadius,
																		boxShadow: "none",
																		overflow: "hidden",
																		mt: 3,
																	})}
																>
																	<Box>
																		<VideoPlayerComponent height="200px" videoUrl={founderStoryVideoUploadUrl} />
																	</Box>
																</Box>
																<Box
																	sx={{
																		mr: 3,
																		background:
																			"linear-gradient(0deg, rgba(106, 117, 117, 0.02), rgba(106, 117, 117, 0.02)), linear-gradient(0deg, rgba(245, 248, 250, 0.05), rgba(245, 248, 250, 0.05)), #FCFCFC",
																		boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
																		borderRadius: "4px",
																		width: "max-content",
																		position: "absolute",
																		top: "48px",
																		right: 0,
																	}}
																>
																	<Button
																		variant="outlined"
																		startIcon={<VideocamRoundedIcon />}
																		onClick={() => {
																			setOpenFounderStoryVideoModal(true);
																		}}
																	>
																		{founderStoryVideoUploadUrl ? "Edit Media" : "Add media"}
																	</Button>
																</Box>
															</Box>
														)}
													</Stack>
												</Box>
											</Stack>
										)}
									</Grid>
								</Grid>

								{!isSmallScreen && (
									<Grid container flexDirection={"row"} sx={{ pt: 2 }} spacing={3}>
										<Grid item xs={12} md={6}>
											<Stack flexDirection={"column"} gap={1}>
												{!aboutUsVideoUploadUrl && (
													<Box>
														<AddVideo
															height={200}
															handelAddMedia={() => {
																setOpenAboutUsVideoModal(true);
															}}
														/>
													</Box>
												)}
												{aboutUsVideoUploadUrl && (
													<Box sx={{ position: "relative" }}>
														<Box
															sx={(theme) => ({
																borderRadius: theme.shape.standard.borderRadius,
																boxShadow: "none",
																overflow: "hidden",
																mt: 3,
															})}
														>
															<Box>
																<VideoPlayerComponent height="200px" videoUrl={aboutUsVideoUploadUrl} />
															</Box>
														</Box>
														<Box
															sx={{
																mr: 3,
																background:
																	"linear-gradient(0deg, rgba(106, 117, 117, 0.02), rgba(106, 117, 117, 0.02)), linear-gradient(0deg, rgba(245, 248, 250, 0.05), rgba(245, 248, 250, 0.05)), #FCFCFC",
																boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
																borderRadius: "4px",
																width: "max-content",
																position: "absolute",
																top: "48px",
																right: 0,
															}}
														>
															<Button
																variant="outlined"
																startIcon={<VideocamRoundedIcon />}
																onClick={() => {
																	setOpenAboutUsVideoModal(true);
																}}
															>
																{aboutUsVideoUploadUrl ? "Edit Media" : "Add media"}
															</Button>
														</Box>
													</Box>
												)}
											</Stack>
										</Grid>
										<Grid item xs={12} md={6}>
											<Stack flexDirection={"column"} gap={1}>
												{!founderStoryVideoUploadUrl && (
													<Box>
														<AddVideo
															height={200}
															handelAddMedia={() => {
																setOpenFounderStoryVideoModal(true);
															}}
														/>
													</Box>
												)}
												{founderStoryVideoUploadUrl && (
													<Box sx={{ position: "relative" }}>
														<Box
															sx={(theme) => ({
																borderRadius: theme.shape.standard.borderRadius,
																boxShadow: "none",
																overflow: "hidden",
																mt: 3,
															})}
														>
															<Box>
																<VideoPlayerComponent height="200px" videoUrl={founderStoryVideoUploadUrl} />
															</Box>
														</Box>
														<Box
															sx={{
																mr: 3,
																background:
																	"linear-gradient(0deg, rgba(106, 117, 117, 0.02), rgba(106, 117, 117, 0.02)), linear-gradient(0deg, rgba(245, 248, 250, 0.05), rgba(245, 248, 250, 0.05)), #FCFCFC",
																boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
																borderRadius: "4px",
																width: "max-content",
																position: "absolute",
																top: "48px",
																right: 0,
															}}
														>
															<Button
																variant="outlined"
																startIcon={<VideocamRoundedIcon />}
																onClick={() => {
																	setOpenFounderStoryVideoModal(true);
																}}
															>
																{founderStoryVideoUploadUrl ? "Edit Media" : "Add media"}
															</Button>
														</Box>
													</Box>
												)}
											</Stack>
										</Grid>
									</Grid>
								)}
							</Grid>
							<Box>
								<Divider sx={{ pt: 3 }} />
							</Box>
							<Grid item xs={12} md={12}>
								<Box sx={{ pt: 2 }}>
									<Typography variant="largeTitle">Pitch Perfect Pitch Deck</Typography>
								</Box>
								<Box
									sx={(theme) => ({
										width: 1,
										minHeight: "400px",
										background:
											"linear-gradient(0deg, #DADCE0, #DADCE0), linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), #DADCE0;",
										border: "1px solid transparent",
										borderRadius: 2,
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										position: "relative",
										mt: 3,
									})}
								>
									{payload?.pitchDeckLink ? (
										<div
											className="App"
											style={{
												display: "flex",
												justifyContent: "center",
												width: "100%",
											}}
										>
											{pitchType === "ppt" && (
												<iframe
													src={`https://view.officeapps.live.com/op/embed.aspx?src=${payload?.pitchDeckLink}`}
													width="100%"
													height="400px"
													frameBorder="0"
													title="slides"
												></iframe>
											)}

											{pitchType === "pdf" && <iframe src={payload?.pitchDeckLink} width="100%" height="400px" frameBorder="0"></iframe>}
										</div>
									) : (
										<Box
											component={"img"}
											src={PITCH_DECK_LOGO}
											sx={{
												height: 100,
												width: 100,
											}}
										></Box>
									)}

									<Box
										sx={{
											mr: 3,
											background:
												"linear-gradient(0deg, rgba(106, 117, 117, 0.02), rgba(106, 117, 117, 0.02)), linear-gradient(0deg, rgba(245, 248, 250, 0.05), rgba(245, 248, 250, 0.05)), #FCFCFC",
											boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
											borderRadius: "4px",
											width: "max-content",
											position: "absolute",
											top: "24px",
											right: 0,
										}}
									>
										<Button variant="outlined" startIcon={<FileUploadIcon />} onClick={() => setOpenDeck(true)}>
											{payload?.pitchDeckLink ? "Edit deck" : "Upload deck"}
										</Button>
									</Box>
								</Box>
							</Grid>
						</Box>

						<Divider orientation="vertical" sx={{ display: { xs: "none", md: "flex" }, pl: 2 }} />
					</Grid>

					<Grid item xs={12} md={5} sx={{ mt: 2 }}>
						<Divider sx={{ display: { xs: "flex", md: "none" }, mb: 2 }} />
						<Typography variant="largeTitle">Details</Typography>
						<Grid container spacing={3} sx={{ display: "flex", gap: 2, mt: 1 }}>
							<Grid item xs={12} md={12}>
								<StartupOsTextField
									fullWidth
									label={"LinkedIn Startup Profile"}
									placeholder={"LinkedIn Startup Profile"}
									spellCheck="true"
									onChange={(e, type) => {
										handleChange(e, "linkedInUrl");
									}}
									onInput={inputChange}
									error={!!companyDataError.linkedinError}
									helperText={companyDataError.linkedinError}
									InputProps={{
										startAdornment: <Typography sx={{ whiteSpace: "nowrap" }}>https://www.linkedin.com/company/</Typography>,
									}}
									value={payload?.linkedInUrl?.replace(/^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(company\/)/gm, "") || ""}
									onBlur={(e) => handleBlur(e, "linkedInUrl")}
								/>
								{payload.linkedInUrl === "" && (
									<Box sx={{ my: 2 }}>
										<CompleteYourProfile
											title={"Complete your profile faster"}
											sub_title={
												"Providing your startup’s LinkedIn profile link will autofill key information here needed to complete your profile."
											}
										/>
									</Box>
								)}
								{payload.linkedInUrl && (
									<Box sx={{ my: 1 }}>
										<Button variant="outlined" onClick={() => setHarmonicOpen(true)}>
											Copy profile from Linkedin
										</Button>
									</Box>
								)}
								{""}
							</Grid>
							<Grid item xs={12} md={12}>
								<CustomizedDatePicker
									value={payload?.foundedDate ? payload?.foundedDate : ""}
									views={["year"]}
									onChange={(e) => handleChange(e, "foundedYear")}
									OpenPickerIcon={ArrowDropDownIcon}
									disablePast={false}
									disableFuture={true}
									toolbarComp={null}
									Component={(params) => (
										<TextField
											{...params}
											fullWidth
											variant="outlined"
											label={"Date founded"}
											value={payload?.foundedDate}
											sx={{
												width: 1,
												cursor: "pointer",
											}}
											onKeyDown={(e) => {
												e.preventDefault(); // Prevent user from manually typing a value
											}}
										/>
									)}
								/>
							</Grid>
							<Grid item xs={12} md={12}>
								<SelectInput
									label="Number of Co-Founders"
									fullWidth
									onInputChange={handleChange}
									selectedValue={payload.noOfCoFounders ? payload.noOfCoFounders : "0"}
									inputKey="noOfCoFounders"
									options={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
								/>
								{/*<StartupOsTextField
                  label="Number of Co-Founders"
                  fullWidth
                  spellCheck="true"
                  onChange={(e, type) => {
                    handleChange(e, "noOfCoFounders");
                  }}
                  onInput={inputChange}
                  value={payload.noOfCoFounders}
                  error={payload.noOfCoFounders >= 0 ? false : true}
                  inputType={"number"}
                />*/}
							</Grid>
							<Grid item xs={12} md={12}>
								<StartupOsTextField
									value={payload.companySize?.toString()}
									label="Company Size"
									fullWidth
									onChange={(e, type) => handleChange(e, "companySize")}
									onInput={inputChange}
								/>
							</Grid>

							<Grid item xs={12} md={12}>
								<StartupOsTextField
									label="Startup Website"
									fullWidth
									spellCheck="true"
									onChange={(e, type) => {
										handleChange(e, "websiteLink");
									}}
									onInput={inputChange}
									value={payload.websiteLink && payload.websiteLink.replace("https://", "").replace("http://", "")}
									InputProps={{
										startAdornment: <Typography>https://</Typography>,
									}}
									error={!!companyDataError?.companySiteError}
									helperText={companyDataError.companySiteError}
									onBlur={(e) => handleBlur(e, "websiteLink")}
								/>
							</Grid>

							<Grid item xs={12} md={12}>
								<StartupOsTextField
									label="Phone"
									fullWidth
									spellCheck="true"
									onChange={(e, type) => {
										handleChange(e, "phone");
									}}
									onInput={inputChange}
									value={payload.phone}
									//error={!!companyDataError?.phoneError}
									//helperText={companyDataError.phoneError}
									//onBlur={e => handleBlur(e, "phone")}
									placeholder={"000-000-0000"}
								/>
							</Grid>

							<Grid item xs={12} md={12}>
								{!showOther ? (
									<SelectInput
										label="Product Offering"
										fullWidth
										onInputChange={(e, type) => handleChange(e, "productOffering")}
										onInput={inputChange}
										onBlur={handleValidations}
										selectedValue={payload.productOffering}
										inputKey="productOffering"
										options={productOfferingList}
									/>
								) : (
									<TextField
										label="Product Offering"
										spellCheck="true"
										fullWidth
										onChange={(e, type) => {
											handleChange(e, "productOffering");
										}}
										onInput={inputChange}
										onBlur={handleValidations}
										value={payload.productOffering}
										InputProps={{
											endAdornment: (
												<CloseIcon
													onClick={() => {
														setShowOther(!showOther);
													}}
													sx={{
														color: `${theme.palette.secondary.textFieldBorder}`,
														cursor: "pointer",
													}}
												/>
											),
										}}
									/>
								)}
							</Grid>

							{/*<Grid item xs={12} md={12}>
                <SelectInput
                  label="Industry Focus*"
                  fullWidth
                  onInputChange={(e, type) => handleChange(e, "targetMarket")}
                  onBlur={handleValidations}
                  selectedValue={payload.targetMarket}
                  inputKey="targetMarket"
                  options={WHATS_YOUR_INDUSTRY_FOCUS.map(item => item.text)}
                />
                {companyDataError.targetMarket == "" && (
                  <Typography variant="required_text" sx={{ pl: 2 }}>
                    *required
                  </Typography>
                )}
              </Grid>*/}

							{/* <Grid item xs={12} md={12} display={"flex"} alignItems={"center"} gap={2}>
								<AutocompleteInput
									label="Industry"
									fullWidth
									inputKey="value"
									isKeyValue={true}
									readOnly
									autoCompleteData={[]}
									typingEnabled={false}
									multiple
									sx={{ maxWidth: { xl: "400px", lg: "330px", md: "300px" } }}
									handleInputChange={(e, type) => {
										handleChange({ target: { value: JSON.stringify(e.target.value) } }, "industry");
										setSelectedIndustries(e.target.value);
									}}
									inputValue={payload["industry"] ? getSelectedFundingFormatted(payload["industry"]) : []}
									inputValid={() => true}
									endAdornment={
										<Box sx={{ float: "right", my: 1 }}>
											{payload && getSelectedFundingFormatted(payload.industry)?.length ? (
												<InputAdornment position="end">
													<CheckCircleIcon sx={{ color: "success.main" }} />
												</InputAdornment>
											) : (
												<InputAdornment position="end">
													<svg width={0} height={0}>
														<linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
															<stop offset={0} stopColor="#FFD141" />
															<stop offset={1} stopColor="#E97300" />
														</linearGradient>
													</svg>
													<UnpublishedOutlinedIcon
														sx={{
															fill: "url(#linearColors)",
														}}
													/>
												</InputAdornment>
											)}{" "}
										</Box>
									}
								/>
								<Box>
									<Button onClick={() => setOpenIndustryModal(true)}>Add</Button>
								</Box>
							</Grid> */}

							<Grid item xs={12} md={12}>
								<AutocompleteInput
									label="Industry"
									fullWidth
									handleInputChange={(e, type) => {
										handleChange({ target: { value: JSON.stringify(e.target.value) } }, "industry");
									}}
									inputKey="industry"
									autoCompleteData={industryList}
									typingEnabled={false}
									multiple
									inputValue={payload ? getSelectedFundingFormatted(payload.industry) : []}
									inputValid={() => true}
									endAdornment={
										<Box sx={{ float: "right", my: 1 }}>
											{payload && getSelectedFundingFormatted(payload.industry).length ? (
												<InputAdornment position="end">
													<CheckCircleIcon sx={{ color: "success.main" }} />
												</InputAdornment>
											) : (
												<InputAdornment position="end">
													<svg width={0} height={0}>
														<linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
															<stop offset={0} stopColor="#FFD141" />
															<stop offset={1} stopColor="#E97300" />
														</linearGradient>
													</svg>
													<UnpublishedOutlinedIcon
														sx={{
															fill: "url(#linearColors)",
														}}
													/>
												</InputAdornment>
											)}{" "}
										</Box>
									}
								/>
							</Grid>

							<Grid item xs={12} md={12}>
								<AutocompleteInput
									label="Funding Type"
									fullWidth
									handleInputChange={(e, type) => {
										handleChange({ target: { value: JSON.stringify(e.target.value) } }, "fundingType");
									}}
									inputKey="fundingType"
									autoCompleteData={FUNDING_TYPES}
									typingEnabled={false}
									multiple
									inputValue={payload ? getSelectedFundingFormatted(payload.fundingType) : []}
									inputValid={() => true}
									errorMessage={companyDataError.fundingTypeError}
									isError={companyDataError.fundingTypeError}
									//handleOnBlurEvent={e => handleBlur(e, "fundingType")}
									endAdornment={
										<Box sx={{ float: "right", my: 1 }}>
											{payload && getSelectedFundingFormatted(payload.fundingType).length ? (
												<InputAdornment position="end">
													<CheckCircleIcon sx={{ color: "success.main" }} />
												</InputAdornment>
											) : (
												<InputAdornment position="end">
													<svg width={0} height={0}>
														<linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
															<stop offset={0} stopColor="#FFD141" />
															<stop offset={1} stopColor="#E97300" />
														</linearGradient>
													</svg>
													<UnpublishedOutlinedIcon
														sx={{
															fill: "url(#linearColors)",
														}}
													/>
												</InputAdornment>
											)}{" "}
										</Box>
									}
								/>
							</Grid>

							<Grid item xs={12} md={12}>
								<AutocompleteInput
									label="Revenue Model Type"
									fullWidth
									handleInputChange={(e, type) => {
										handleChange({ target: { value: JSON.stringify(e.target.value) } }, "revenueModel");
									}}
									inputKey="revenueModel"
									autoCompleteData={revenueTypeList}
									typingEnabled={false}
									multiple
									inputValue={payload ? getSelectedFundingFormatted(payload.revenueModel) : []}
									inputValid={() => true}
									endAdornment={
										<Box sx={{ float: "right", my: 1 }}>
											{payload && getSelectedFundingFormatted(payload.revenueModel).length ? (
												<InputAdornment position="end">
													<CheckCircleIcon sx={{ color: "success.main" }} />
												</InputAdornment>
											) : (
												<InputAdornment position="end">
													<svg width={0} height={0}>
														<linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
															<stop offset={0} stopColor="#FFD141" />
															<stop offset={1} stopColor="#E97300" />
														</linearGradient>
													</svg>
													<UnpublishedOutlinedIcon
														sx={{
															fill: "url(#linearColors)",
														}}
													/>
												</InputAdornment>
											)}{" "}
										</Box>
									}
								/>
							</Grid>

							<Grid item xs={12} md={12}>
								<SelectCheckedInput
									label="Business Model Type"
									fullWidth
									onInputChange={(e, type) => handleChange(e, "businessModel")}
									onInput={inputChange}
									selectedValue={payload.businessModel}
									inputKey="businessModel"
									options={businessTypeList}
								/>
							</Grid>
							<Grid item xs={12} md={12}>
								<StartupOsTextField
									value={payload.capitalRaised?.toString()}
									inputProps={{
										maxlength: 15,
									}}
									label="Capital Raised"
									fullWidth
									onChange={(e, type) => handleChange(e, "capitalRaised")}
									onInput={inputChange}
									onBlur={handleValidations}
									InputProps={{
										startAdornment: payload?.capitalRaised && <InputAdornment position="start">$</InputAdornment>,
									}}
								/>
							</Grid>

							<Grid item xs={12} md={12}>
								<SelectInput
									label="Is your startup incorporated?"
									fullWidth
									onInputChange={(e, type) => handleChange(e, "incorporatedStatus")}
									onInput={inputChange}
									onBlur={handleValidations}
									selectedValue={payload?.incorporatedStatus ? payload?.incorporatedStatus : []}
									inputKey="incorporatedStatus"
									options={["Yes", "No", "In Progress"]}
								/>
							</Grid>

							{(payload?.incorporatedStatus === "No" || payload?.incorporatedStatus === "In Progress") && (
								<Box sx={{ ml: "24px" }}>
									<FirstbaseCTA params={{ bgColor: "#fff" }} />
								</Box>
							)}

							<Grid item xs={12} md={12}>
								<SelectCheckedInput
									label="Revenue"
									fullWidth
									onInputChange={(e, type) => handleChange(e, "revenue")}
									onInput={inputChange}
									onBlur={handleValidations}
									selectedValue={payload?.revenue}
									inputKey="revenue"
									options={["Yes", "No"]}
								/>
							</Grid>
							<Grid item xs={12} md={12}>
								<SelectCheckedInput
									label="Financing"
									fullWidth
									onInputChange={(e, type) => handleChange(e, "financing")}
									onInput={inputChange}
									onBlur={handleValidations}
									selectedValue={payload.financing}
									inputKey="financing"
									options={FINANCING_LIST}
								/>
							</Grid>
							<Grid item xs={12} md={12}>
								<SelectCheckedInput
									label="Current Stage"
									fullWidth
									onInputChange={(e, type) => handleChange(e, "currentStage")}
									onInput={inputChange}
									onBlur={handleValidations}
									selectedValue={payload.currentStage}
									inputKey="currentStage"
									options={CURRENT_STAGE_LIST}
								/>
							</Grid>
							<Grid item xs={12} md={12}>
								<SelectCheckedInput
									label="Seeking"
									fullWidth
									onInputChange={(e, type) => handleChange(e, "seeking")}
									onInput={inputChange}
									onBlur={handleValidations}
									selectedValue={payload.seeking}
									inputKey="seeking"
									options={SEEKING_LIST}
								/>
							</Grid>
							<Grid item xs={12} md={12}>
								<StartupOsTextField
									value={payload.ask?.toString()}
									label="Ask"
									fullWidth
									onChange={(e, type) => handleChange(e, "ask")}
									onInput={inputChange}
									onBlur={handleValidations}
									InputProps={{
										startAdornment: payload?.ask && <InputAdornment position="start">$</InputAdornment>,
									}}
								/>
							</Grid>
							{/*<Grid item xs={12} md={12} onClick={inputChange}>
                <AutocompleteInput
                  label="Area of expertise"
                  fullWidth
                  handleInputChange={(e, type) =>
                    handleChange(
                      { target: { value: JSON.stringify(e.target.value) } },
                      "expertise"
                    )
                  }
                  onInput={inputChange}
                  inputKey="expertise"
                  autoCompleteData={EXPERTISE_LIST}
                  typingEnabled={false}
                  multiple
                  inputValue={
                    payload
                      ? getSelectedExpertiseFormatted(
                          payload?.expertise
                        )
                      : []
                  }
                  inputValid={() => true}
                />
              </Grid>*/}
						</Grid>
						<Divider sx={{ my: 2 }} />
						<Box>
							<EditTeam />
						</Box>
					</Grid>
				</Grid>
				{/* Mob: form actions */}
				<Grid
					item
					xs={12}
					md={12}
					sx={{
						display: { xs: "block", md: "none" },
						width: 1,
						backgroundColor: (theme) => theme.palette.secondary.white,
						zIndex: 999,
						pb: 4,
					}}
				>
					<Divider sx={{ mb: 2 }} />
					<Grid container spacing={2} sx={{ px: 2 }}>
						<Grid item xs={6} md={6} sx={{ display: "flex", alignItems: "center" }}>
							<Button variant="contained" sx={{ width: 1 }} onClick={handleCancel}>
								Discard
							</Button>
						</Grid>
						<Grid item xs={6} md={6} sx={{ display: "flex", alignItems: "center" }}>
							<Button sx={{ width: 1 }} disabled={saveDisabled} onClick={handleSave}>
								{saveLoading ? (
									<CircularProgress
										sx={(theme) => ({
											display: "flex",
											maxWidth: theme.shape.standard10.borderRadius,
											justifyContent: "center",
											color: theme.palette.secondary.white,
										})}
									/>
								) : (
									"Save"
								)}
							</Button>
						</Grid>
					</Grid>
				</Grid>

				<UploadImageModal
					open={openBackgroundPhotoModal}
					onClose={() => setOpenBackgroundPhotoModal(false)}
					photo={backgroundPhoto}
					setPhoto={setBackgroundPhoto}
					title="Background Photo"
					selectFileText="Select a file to upload background image"
					dimensionText="Recommended dimensions 1400px x 350px (Max 2GB)"
					aspectRatio={4 / 1}
					imageType="BACKGROUND_PHOTO"
					fileType={{ "image/png": [], "image/jpg": [], "image/jpeg": [] }}
					circularCropper={false}
					handleUpdateAssetsUrl={handleUpdateAssetsUrl}
				/>

				<SelectIndustryModal
					open={openIndustryModal}
					selectedIndustries={getSelectedFundingFormatted(selectedIndustries)}
					setSelectedIndustries={(selected) => {
						setSelectedIndustries(JSON.stringify(selected));
						payload["industry"] = JSON.stringify(selected);
					}}
					onClose={() => setOpenIndustryModal(false)}
					title="Select Industry"
				/>

				<UploadImageModal
					open={openProfilePhotoModal}
					photo={profilePhoto}
					onClose={() => setOpenProfilePhotoModal(false)}
					setPhoto={setProfilePhoto}
					title="Startup Logo"
					selectFileText="Select a file to upload profile image"
					dimensionText="Recommended dimensions 240px x 240px (Max 2GB)"
					aspectRatio={1 / 1}
					imageType="COMPANY_PHOTO"
					circularCropper={false}
					fileType={{ "image/png": [], "image/jpg": [], "image/jpeg": [] }}
					handleUpdateAssetsUrl={handleUpdateAssetsUrl}
				/>
				<UploadVideoModal
					open={openAboutUsVideoModal}
					photo={aboutUsVideoUploadUrl}
					onClose={() => setOpenAboutUsVideoModal(false)}
					setPhoto={setAboutUsVideoUploadUrl}
					title="Elevator Pitch Video"
					selectFileText="Select a file to upload Elevator Pitch video"
					dimensionText="Accepted file types .mp4, .mov, .3gp, .webm, .ogg (Max 2GB)"
					aspectRatio={1 / 1}
					imageType="COMPANY_ABOUTUS_VIDEO"
					fileType={{
						"video/3gpp": [".3gp"],
						"video/mp4": [".mp4"],
						"video/mov": [".mov"],
						"video/webm": [".webm"],
						"video/ogg": [".ogg"],
					}}
					handleUpdateAssetsUrl={handleUpdateAssetsUrl}
				/>
				<UploadVideoModal
					open={openFounderStoryVideoModal}
					photo={founderStoryVideoUploadUrl}
					onClose={() => setOpenFounderStoryVideoModal(false)}
					setPhoto={setFounderStoryVideoUploadUrl}
					title="Founder Story Video"
					selectFileText="Select a file to upload Founder Story video"
					dimensionText="Accepted file types .mp4, .mov, .3gp, .webm, .ogg (Max 2GB)"
					aspectRatio={1 / 1}
					imageType="COMPANY_FOUNDERSTORY_VIDEO"
					fileType={{
						"video/3gpp": [".3gp"],
						"video/mp4": [".mp4"],
						"video/mov": [".mov"],
						"video/webm": [".webm"],
						"video/ogg": [".ogg"],
					}}
					handleUpdateAssetsUrl={handleUpdateAssetsUrl}
				/>
				<UploadFileModal
					open={openDeck}
					onClose={() => setOpenDeck(false)}
					file={pitchDeck}
					setFile={setPitchDeck}
					title="Pitch Perfect Pitch Deck"
					selectFileText="Select a file to upload your deck"
					dimensionText="Accepted file types .pdf, .ppt, .pptx(Max 500MB)"
					aspectRatio={4 / 1}
					imageType="PITCH_DECK"
					fileType={{
						"application/vnd.openxmlformats-officedocument.presentationml.presentation": [],
						"application/vnd.ms-powerpoint": [],
						"application/pdf": [],
					}}
					circularCropper={false}
					updatePayload={handleUploadPitchDeck}
				/>
			</Box>
		</>
	);
}
