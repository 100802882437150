import React, { useEffect, useState } from "react";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import styled from "@emotion/styled";
import AddToCalendar from "../../../common/AddToCalendar";

export default function MenuDrawer({ open, onClose, menuOptions, event }) {
  const drawerBleeding = 0;

  const Puller = styled(Box)(({ theme }) => ({
    width: 80,
    height: 4,
    backgroundColor: "grey",
    borderRadius: 3,
    top: 8,
    cursor: "pointer",
  }));

  return (
    <>
      <SwipeableDrawer
        anchor="bottom"
        open={open}
        onClose={onClose}
        onOpen={() => {
          return;
        }}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={false}
        sx={{
          display: { xs: "flex", md: "none" },
          zIndex: 9998,

          "& .MuiPaper-root": {
            borderRadius: "16px 16px 0px 0px",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: 1,
            justifyContent: "center",
            py: 1,
          }}
        >
          <Puller onClick={onClose} />
        </Box>
        <Box
          sx={{
            width: 1,
            height: "max-content",
          }}
          role="presentation"
          onClick={onClose}
          onKeyDown={onClose}
        >
          <List>
            {menuOptions.map((item, i) => {
              return (
                <ListItem sx={{ pl: 0 }}>
                  {i != 1 ? (
                    <ListItemButton onClick={item.action}>
                      <ListItemIcon>{item.icon}</ListItemIcon>
                      <ListItemText>
                        <Typography variant="subhead5">{item.text}</Typography>
                      </ListItemText>
                    </ListItemButton>
                  ) : (
                    <AddToCalendar
                      calendarEvent={event}
                      buttonVariant="nooutlined"
                    />
                  )}
                </ListItem>
              );
            })}
          </List>
        </Box>
      </SwipeableDrawer>
    </>
  );
}
