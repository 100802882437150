import styled from "@emotion/styled";

export const StayOnTrackTitle = styled.div(({ theme }) => {
  return {
    display: "flex",
    alignItems: "center",
  };
});

export const CalenderBox = styled.div(({ theme }) => {
  return {
    border: "1px solid #7C757F",
    borderRadius: theme.shape.standard3.borderRadius,
    padding: "0px 13px 4px 13px",
    marginTop: "18px",
  };
});
