import React from "react";
import { Snackbar } from "@mui/material";
import theme from "../../constants/Theme";

const MuiSnackBar = ({
  message,
  vertical,
  horizontal,
  open,
  setOpenSnackbar,
}) => {
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open={open}
      autoHideDuration={3000}
      message={message}
      key={vertical + horizontal}
      onClose={handleClose}
      ContentProps={{
        sx: {
          background: theme.palette.containers.onSurfaceHighEmphasis,
          boxShadow: theme.palette.primary.surfaceDp04,
        },
      }}
    />
  );
};

export default MuiSnackBar;
