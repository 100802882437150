import React, { useEffect, useState } from "react";
import { Box, Button, useMediaQuery } from "@mui/material";
import theme from "../../../../../constants/Theme";
import LoadingButton from "../../../../../common/Loadingbutton";
import ScriptEditor from "../../../../../common/ScriptEditor";
import {
  RICH_TEXT_EDITOR_INLINE_CONTROLS,
  RICH_TEXT_EDITOR_MAIN_CONTROLS,
} from "../../../../../constants/plugin-constants";
const InterviewScript = ({
  readOnly = false,
  hideRestoreOption = false,
  scriptConfigData,
  containerStyle,
  handleSaveAction,
  resetAction,
  customSaveButton = false,
  getChildData,
  getChildEventData,
  buttonLoading,
  triggerSave,
  setTriggerSave,
  getCustomNotes,
  saveNotesState,
  setInputChange,
}) => {
  const [currentData, setCurrentData] = useState({ key: "", value: "" });

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [scriptData, setScriptData] = useState(scriptConfigData);

  useEffect(() => {
    if (triggerSave) {
      handleSaveAction(scriptData);
      setTriggerSave(false);
    }
  }, [triggerSave]);

  const [version, setVersion] = useState(1); // maintaining version to re-render the script component after Reset to default action
  useEffect(() => {
    if (scriptConfigData) {
      setVersion(() => version + 1);
    }
  }, [scriptConfigData]);

  useEffect(() => {
    if (currentData.value === "") return;

    let rte_markdown = currentData.value;

    const objIdx = scriptData.findIndex(obj => obj.key === currentData.key);

    if (objIdx !== -1) {
      const updatedScriptData = [...scriptData];
      updatedScriptData[objIdx] = {
        ...updatedScriptData[objIdx],
        value: rte_markdown,
      };

      setScriptData(updatedScriptData);
      //setInputChange(true);
    }
  }, [currentData]);

  useEffect(() => {
    if (getChildData) getChildData(scriptData);
  }, [getChildEventData]);

  return (
    <>
      <Box display={"flex"} sx={{ width: "max-content", ml: "auto" }}>
        {!hideRestoreOption && (
          <Button
            variant="nofill"
            onClick={() => {
              resetAction();
              setVersion(version + 1);
            }}
            sx={{ width: "max-content" }}
          >
            Reset to default
          </Button>
        )}

        {customSaveButton ? (
          <></>
        ) : (
          !readOnly &&
          !isSmallScreen && (
            <LoadingButton
              variant="contained"
              sx={{ width: "max-content" }}
              onClick={() => {
                handleSaveAction(scriptData);
              }}
              loading={buttonLoading}
              loadingStyles={{ px: 7 }}
            >
              Save Changes
            </LoadingButton>
          )
        )}
      </Box>
      <Box sx={{ py: 2, ...containerStyle }}>
        {scriptConfigData.map(ele => (
          <Box sx={{ pb: 2 }} onClick={() => setInputChange(true)}>
            <ScriptEditor
              version={version}
              id={ele.key}
              label={ele.label}
              initialValue={ele.value}
              onChange={data => {
                setCurrentData(data);
              }}
              readOnly={readOnly}
              toolbar={true}
              inlineToolbar={false}
              inlineToolbarControls={RICH_TEXT_EDITOR_INLINE_CONTROLS}
              controls={readOnly ? [] : RICH_TEXT_EDITOR_MAIN_CONTROLS}
            />
          </Box>
        ))}
      </Box>
    </>
  );
};

export default InterviewScript;
