import * as actionTypes from "./actionTypes";

export const setMentorProfile = payload => {
  return dispatch => {
    dispatch({
      type: actionTypes.SET_MENTOR_PROFILE,
      payload,
    });
  };
};
