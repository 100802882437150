import React, { useEffect, useState } from "react";
import BILineChart from "./BILineChart/BILineChart";
import { Box, Typography, useTheme, CircularProgress } from "@mui/material";
import ChartCard from "./ChartCard";
import DisplayLastestParams from "./DisplayLastestParams";
import { get } from "lodash";
import { convertNumberToLocale, getGradientBgColor } from "../Functions/common";
import ZeroLineChart from "./BILineChart/EmptyLineChart";
import { useSelector } from "react-redux";
import { BURN_RUNWAY_BENCHMARKS } from "../../../../constants";

function BurnRunChartContainer({ onChartClick, data, loading, readOnly }) {
	const theme = useTheme();
	const chartData = get(data, "data", []);

	const latestRunway = get(data, "latestRunway", null);
	const latestCashBalance = get(data, "latestCashBalance", null);
	const latestBurn = get(data, "latestBurn", null);
	const companyStage = useSelector((store) => store?.biDashboard?.currentStage);

	const latestArributes = {
		"Cash Balance": latestCashBalance,
		Burn: latestBurn,
	};

	return (
		<ChartCard
			title={"Burn & Runway"}
			subheader={latestRunway !== null && !loading ? `${convertNumberToLocale(latestRunway)} Months Runway` : "0"}
			backgroundColor={theme.palette.secondary.white}
			onChartClick={() => onChartClick("burnAndRunway", data)}
			toolTipBody={
				<>
					<Typography variant="Text/sm/Regular">Burn measures the change in a company’s cash balance in a month.</Typography>
					<Typography variant="Text/sm/Regular" sx={{ fontStyle: "italic" }}>
						Burn for month x = Cash balance in month x - cash balance in month x-1
					</Typography>
					<Typography variant="Text/sm/Regular">
						For quarterly and annual aggregations cash balance as of the end of the period is used, showing quarterly/annual burn figures. For
						example:
					</Typography>
					<Typography variant="Text/sm/Regular" sx={{ fontStyle: "italic" }}>
						Burn for quarter x = Cash balance in last month of quarter x - cash balance in last month of quarter x-1
					</Typography>

					<Typography variant="Text/sm/Regular">
						Runway measure how long a company can continue to operate without running out of cash at current levels of burn. There are many reasons
						why burn may diverge from GAAP net income. For example, annual contracts with upfront payments create positive cash flow cycles, while
						loan products create negative ones. Analyzing runway on a cash basis reveals these cash flow patterns and helps companies ensure they
						have the reserves they need to survive.
					</Typography>
				</>
			}
			data={data?.data}
		>
			{loading ? (
				<Box sx={{ alignSelf: "center" }}>
					<CircularProgress />
				</Box>
			) : chartData.length === 0 ? (
				<ZeroLineChart readOnly={readOnly} />
			) : (
				<>
					<Box sx={{ mb: 3 }}>
						<DisplayLastestParams latestArributes={latestArributes} type="currency" />
					</Box>
					<Box sx={{ position: "relative", width: "100%", mt: "auto" }}>
						<BILineChart
							data={chartData}
							tooltipYLable={"Runway"}
							yAxisKey={"runway"}
							lineBorderColor={theme.palette.primary.main}
							lineBackgroundColor={(context) => {
								const ctx = context.chart.ctx;
								return getGradientBgColor(ctx, context);
							}}
							datasets={[
								{
									label: `${companyStage} Benchmark`,
									data:
										chartData?.map((dataItem) => ({
											id: get(dataItem, "id", null),
											date: get(dataItem, "date", null),
											benchamark: BURN_RUNWAY_BENCHMARKS[companyStage],
										})) || [],
									borderWidth: 2,
									borderColor: theme.palette.bidashboard.paramLightBlue,
									tension: 0.15,
									parsing: {
										xAxisKey: "date",
										yAxisKey: "benchamark",
									},
									pointRadius: 0,
									pointHoverRadius: 3,
									clip: false,
									borderDash: [5, 5],
								},
							]}
						/>
					</Box>
				</>
			)}
		</ChartCard>
	);
}

export default BurnRunChartContainer;
