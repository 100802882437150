import styled from "@emotion/styled";

export const UserAboutVideoCardContainer = styled.div(({ theme }) => {
	return {
		borderRadius: "40px",
		width: "100%",
		display: "flex",
		justifyContent: "center",
		backgroundColor: "white",
		boxSizing: "border-box",
		boxShadow: "0px 10px 25px rgba(0, 0, 0, 0.05)",
	};
});
