import { Box, useMediaQuery } from "@mui/material";
import React from "react";

export default function DashboardLayout({ topbar, main, isFull, height = "100vh" }) {
	return (
		<Box
			sx={{
				height: "100vh",
				width: 1,
				boxSizing: "border-box",
				height: height,
			}}
		>
			{/* <Box
				textAlign={"center"}
				sx={theme => ({
					px: 2,
					mx: 2,
					[theme.breakpoints.down("md")]: {
						flexDirection: "column",
						px: 1,
						mx: 1,
					},
					mb: 1,
					cursor: "pointer",
				})}
			></Box> */}
			<Box
				sx={(theme) => ({
					minHeight: 1,
				})}
			>
				{/* <Box
					sx={{
						display: "flex",
						boxSizing: "border-box",
					}}
				>
					{topbar}
				</Box> */}

				<Box
					sx={{
						display: "flex",
						flex: 1,
						flexDirection: "column",
					}}
				>
					<Box
						sx={(theme) => ({
							flex: 1,
							mx: isFull ? 0 : 3,
							boxSizing: "border-box",
							[theme.breakpoints.down("md")]: {
								mt: 0,
							},
						})}
					>
						{main}
					</Box>
				</Box>
			</Box>
		</Box>
	);
}
