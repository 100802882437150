import React, { useRef } from "react";
import useChart from "../../../utils/useChart";

function LineGraph({ label_array, data_array }) {
	const canvasRef = useRef(null);

	const data = {
		labels: label_array,
		datasets: [
			{
				label: "First dataset",
				data: data_array,
				fill: "start",
				backgroundColor: (ScriptableContext) => {
					const ctx = ScriptableContext.chart.ctx;
					const gradient = ctx.createLinearGradient(0, 0, 0, 200);
					gradient.addColorStop(0.4054, "rgba(123, 97, 255, 0)");
					gradient.addColorStop(0.8968, "rgba(123, 97, 255, 0.15)");
					return gradient;
				},
				borderWidth: 8,
				borderCapStyle: "round",
				borderColor: "rgb(123, 97, 255)",
				pointStyle: "circle",
				pointRadius: (context) => {
					const index = context.dataIndex;
					const firstIndex = 0;
					const lastIndex = context.dataset.data.length - 1;
					return index === firstIndex || index === lastIndex ? 7 : 0;
				},
				pointBorderWidth: 5,
				pointBackgroundColor: ["white"],
				pointBorderColor: "rgb(123, 97, 255)",
				pointHoverBorderColor: "rgb(123, 97, 255)",
				pointHoverRadius: (context) => {
					const index = context.dataIndex;
					const firstIndex = 0;
					const lastIndex = context.dataset.data.length - 1;
					return index === firstIndex || index === lastIndex ? 7 : 0;
				},
				pointHoverBorderWidth: 5,
				pointHoverBackgroundColor: ["white"],
			},
		],
	};

	const options = {
		animation: false,
		maintainAspectRatio: false,
		responsive: false,
		scales: {
			x: {
				display: false,
				grid: {
					display: false,
				},
			},
			y: {
				display: false,
				grid: {
					display: false,
				},
			},
		},
		elements: {
			line: {
				tension: 0.4,
			},
			point: {
				label: {
					display: false,
				},
			},
		},
		plugins: {
			filler: {
				propagate: false,
			},
			legend: {
				display: false,
			},
			datalabels: {
				display: false,
			},
			tooltip: {
				enabled: false,
			},
		},
		interaction: {
			intersect: false,
		},
	};

	useChart(canvasRef, {
		type: "line",
		data: data,
		options: options,
	});

	return <canvas id="scoreTrendChart" ref={canvasRef} />;
}

export default LineGraph;
