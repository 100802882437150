import React from "react";
import CommonSlideDialog from "../CommonSlideDialog";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { Cropper } from "react-cropper";

const CommonImageCropper = ({ openCropper, handleOnClose, cropperLoader, handleCancel, aspectRatio, localFile, getCropData, setCropper, circularCropper }) => {
	return (
		<CommonSlideDialog
			disableBackDropClick={true}
			title={"Crop Image"}
			titleVariant={"title_large"}
			isOpen={openCropper}
			closeButton={!cropperLoader}
			onClose={handleOnClose}
			contentStyle={{ px: 0 }}
			maxWidth={cropperLoader ? "sm" : "md"}
			fullWidth
			footerStyle={{
				border: cropperLoader && "none",
			}}
			PaperProps={{
				sx: (theme) => ({
					borderRadius: theme.shape.standard13.borderRadius,
					boxShadow: "0px 5px 16px rgba(0, 0, 0, 0.3)",
					p: 3,
				}),
			}}
			dialogAction={
				<>
					{!cropperLoader && (
						<Box sx={{ display: "flex", gap: 1, m: 2 }}>
							<Box>
								<Button variant="DS1" color="clear" onClick={handleOnClose} disabled={cropperLoader} >
									Cancel
								</Button>
							</Box>
							<Button variant="DS1" onClick={() => getCropData()}>
								Crop
							</Button>
						</Box>
					)}
				</>
			}
			cropperLoader={cropperLoader}
		>
			<Box
				className="SOSShowVerticalScroll"
				sx={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					maxHeight: "660px",
					overflowY: "auto",
					overflowX: "auto",
					justifyContent: "center",
				}}
			>
				{" "}
				{cropperLoader ? (
					<Box justifyContent={"center"} sx={{ display: "flex", mt: 7, minHeight: "150px" }}>
						<Box>
							<Box
								sx={(theme) => ({
									display: "flex",
									justifyContent: "center",
								})}
							>
								<CircularProgress
									sx={(theme) => ({
										height: "80px",
										width: "80px",
										color: theme.palette.text.darkBlue,
									})}
								/>
							</Box>
							<Box
								sx={(theme) => ({
									textAlign: "center",
									pt: 5,
								})}
							>
								<Typography variant="title_medium">File upload in progress</Typography>
							</Box>
						</Box>
					</Box>
				) : (
					<>
						<Box
							className={circularCropper ? "circular-cropper" : "normal-cropper"}
							sx={{
								borderRadius: "4px",
								maxHeight: "400px",

								textAlign: "center",
								cursor: "pointer",
							}}
						>
							<Cropper
								aspectRatio={aspectRatio}
								preview=".img-preview"
								src={localFile}
								style={{ height: 400, width: "100%" }}
								viewMode={2}
								movable={false}
								background={false}
								responsive={true}
								autoCropArea={0}
								checkOrientation={false}
								onInitialized={(instance) => {
									setCropper(instance);
								}}
								guides={false}
							/>
						</Box>
					</>
				)}
			</Box>
		</CommonSlideDialog>
	);
};

export default CommonImageCropper;
