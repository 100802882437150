import { getBoltHistory, updateBoltHistory } from "../../../utils/sessionStorage";

export const getBoltSessionHistory = () => {
	return getBoltHistory();
};

export const updateBoltSessionHistory = (data) => {
	updateBoltHistory(data);
	return getBoltSessionHistory();
};

export const responseFormatter = (response) => {
	let transformed = response;
	transformed = transformWebAddress(response);
	return transformed;
};

function transformWebAddress(inputString) {
	if (typeof inputString === "string") {
		// Regular expression for matching Zendesk URLs
		const zendeskUrlRegex = /\[([^\]]+)\]\(https:\/\/startupos\.zendesk\.com\/hc\/en-us\/articles\/(\d+)-[^\s]+\)/g;

		// Regular expression for matching PowerUp URLs
		const powerUpUrlRegex = /\[([^\]]+)\]\(https:\/\/app\.startupos\.com\/workspace\/powerUp\/(\d+)\)/g;

		// Replace Zendesk URLs with the desired format
		const zendeskTransformedString = inputString.replace(zendeskUrlRegex, (match, title, articleId) => {
			const transformedUrl = `/resource-center/articles/${articleId}`;
			return `<a href="${transformedUrl}">${title}</a>`;
		});

		// Replace PowerUp URLs with the desired format
		const powerUpTransformedString = zendeskTransformedString.replace(powerUpUrlRegex, (match, title, powerUpId) => {
			const transformedUrl = `/workspace/powerUp/${powerUpId}`;
			return `<a href="${transformedUrl}">${title}</a>`;
		});
		return powerUpTransformedString;
	} else return inputString;
}
