import React, { Suspense, useContext, useEffect, useState } from "react";
import { PowerUPContext } from "../../../PowerUPController";
import { Box, Button, Dialog, Slide } from "@mui/material";
import { Card } from "../../cards/Card";
import WorkModalHeader from "./WorkModalHeader";
import TextOnlyReadOnly from "../../layout/TextOnlyReadOnly";

/* 
	Lazy Load Custom ReadOnly Modules
	Used in cases where there is a custom element rendered ie. image, table, etc... that is not shared with other PowerUPs.
	In any other case where it is just text, add the path to the module file in the [currentVersion] useEffect switch.
	ie. 
	case "md-module-name":
				textOnlyModule("../../../compositions/DoIt/ModuleExample/md-module-name");
				break;
	Where that file would have the required exports { INITIAL_VALUE_STATE, TEXT_MAP, getPDFDownload }
*/
const LeanCanvas = React.lazy(() => import("../../../compositions/DoIt/LeanCanvas/md-lean-canvas-readonly"));
const PitchDeck = React.lazy(() => import("../../../compositions/DoIt/PitchDeckGenerator/md-pitchdeck-generator-readonly"));

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="left" ref={ref} {...props} />;
});

const WorkViewModal = () => {
	const { workViewModalOpen, setWorkViewModalOpen, currentVersion, setcurrentVersion, setActiveTab } = useContext(PowerUPContext);
	const [key, setKey] = useState(0);
	const [moduleData, setModuleData] = useState(null);
	const [currentModuleID, setCurrentModuleID] = useState(null);
	const [noDownload, setNoDownload] = useState(false);

	useEffect(() => {
		const currentModuleIDVal = currentVersion?.module?.moduleUuid;
		setKey((prevKey) => prevKey + 1);

		switch (currentModuleIDVal) {
			case "md-prob-sol":
				import("../../../compositions/DoIt/ProblemSolutionFit/md-prob-sol").then((module) => {
					const { INITIAL_VALUE_STATE, TEXT_MAP, getPDFDownload } = module;
					setModuleData({ INITIAL_VALUE_STATE, TEXT_MAP, getPDFDownload });
					setCurrentModuleID(currentModuleIDVal);
				});
				break;
			case "md-story":
				import("../../../compositions/DoIt/FounderStory/md-story").then((module) => {
					const { INITIAL_VALUE_STATE, TEXT_MAP, getPDFDownload } = module;
					setModuleData({ INITIAL_VALUE_STATE, TEXT_MAP, getPDFDownload });
					setCurrentModuleID(currentModuleIDVal);
				});
				break;
			case "md-elevator-pitch":
				import("../../../compositions/DoIt/ElevatorPitch/md-elevator-pitch").then((module) => {
					const { INITIAL_VALUE_STATE, TEXT_MAP, getPDFDownload } = module;
					setModuleData({ INITIAL_VALUE_STATE, TEXT_MAP, getPDFDownload });
					setCurrentModuleID(currentModuleIDVal);
				});
				break;
			case "md-lean-canvas":
				setCurrentModuleID(currentModuleIDVal);
				break;
			case "md-pitchdeck-generator":
				setCurrentModuleID(currentModuleIDVal);
				break;
			case "md-value-prop":
				import("../../../compositions/DoIt/ValueProp/md-value-prop").then((module) => {
					const { INITIAL_VALUE_STATE, TEXT_MAP, getPDFDownload } = module;
					setModuleData({ INITIAL_VALUE_STATE, TEXT_MAP, getPDFDownload });
					setCurrentModuleID(currentModuleIDVal);
				});
				break;
			case "md-exit-strategy":
				import("../../../compositions/DoIt/ExitStrategy/md-exit-strategy").then((module) => {
					const { INITIAL_VALUE_STATE, TEXT_MAP, getPDFDownload } = module;
					setModuleData({ INITIAL_VALUE_STATE, TEXT_MAP, getPDFDownload });
					setCurrentModuleID(currentModuleIDVal);
				});
				break;
			case "md-are-you-fundable":
				import("../../../compositions/DoIt/AreYouFundable/md-are-you-fundable").then((module) => {
					const { INITIAL_VALUE_STATE, TEXT_MAP, getPDFDownload } = module;
					setModuleData({ INITIAL_VALUE_STATE, TEXT_MAP, getPDFDownload });
					setCurrentModuleID(currentModuleIDVal);
				});
				break;
			case "md-discovery-interview":
				import("../../../compositions/DoIt/DiscoveryInterview/md-discovery-interview").then((module) => {
					const { INITIAL_VALUE_STATE, TEXT_MAP, getPDFDownload } = module;
					setModuleData({ INITIAL_VALUE_STATE, TEXT_MAP, getPDFDownload });
					setCurrentModuleID(currentModuleIDVal);
				});
				break;
			case "md-moat":
				import("../../../compositions/DoIt/Moat/md-moat").then((module) => {
					const { INITIAL_VALUE_STATE, TEXT_MAP, getPDFDownload } = module;
					setModuleData({ INITIAL_VALUE_STATE, TEXT_MAP, getPDFDownload });
					setCurrentModuleID(currentModuleIDVal);
				});
				break;
			case "md-pricing-model-saas":
				import("../../../compositions/DoIt/SaaSPricingModeler/md-pricing-model-saas").then((module) => {
					const { INITIAL_VALUE_STATE, TEXT_MAP, getPDFDownload } = module;
					setModuleData({ INITIAL_VALUE_STATE, TEXT_MAP, getPDFDownload });
					setCurrentModuleID(currentModuleIDVal);
				});
				break;

			case "md-hardware-pricing-modeler":
				import("../../../compositions/DoIt/HardwarePricingModeler/md-hardware-pricing-modeler").then((module) => {
					const { INITIAL_VALUE_STATE, TEXT_MAP, getPDFDownload } = module;
					setModuleData({ INITIAL_VALUE_STATE, TEXT_MAP, getPDFDownload });
					setCurrentModuleID(currentModuleIDVal);
				});
				break;
			case "md-retail-pricing-modeler":
				import("../../../compositions/DoIt/RetailPricingModeler/md-retail-pricing-modeler").then((module) => {
					const { INITIAL_VALUE_STATE, TEXT_MAP, getPDFDownload } = module;
					setModuleData({ INITIAL_VALUE_STATE, TEXT_MAP, getPDFDownload });
					setCurrentModuleID(currentModuleIDVal);
				});
				break;
			case "md-services-pricing-modeler":
				import("../../../compositions/DoIt/ServicesPricingModeler/md-services-pricing-modeler").then((module) => {
					const { INITIAL_VALUE_STATE, TEXT_MAP, getPDFDownload } = module;
					setModuleData({ INITIAL_VALUE_STATE, TEXT_MAP, getPDFDownload });
					setCurrentModuleID(currentModuleIDVal);
				});
				break;
					
			case "md-tam":
				import("../../../compositions/DoIt/TamSamSom/md-tam").then((module) => {
					const { INITIAL_VALUE_STATE, TEXT_MAP, getPDFDownload } = module;
					setModuleData({ INITIAL_VALUE_STATE, TEXT_MAP, getPDFDownload });
					setCurrentModuleID(currentModuleIDVal);
				});
				break;
			default:
				return null; // TODO -- Need "sorry was unable to load at this time. Message."
		}
	}, [currentVersion]);

	const getWorkComponent = () => {
		if (!currentModuleID) return "Loading..."; // TODO -- Need "sorry was unable to load at this time. Message."

		switch (currentModuleID) {
			case "md-prob-sol": {
				return (
					<TextOnlyReadOnly
						key={key}
						INITIAL_VALUE_STATE={moduleData?.INITIAL_VALUE_STATE}
						TEXT_MAP={moduleData?.TEXT_MAP}
						getPDFDownload={moduleData?.getPDFDownload}
					/>
				);
			}
			case "md-story": {
				return (
					<TextOnlyReadOnly
						key={key}
						INITIAL_VALUE_STATE={moduleData?.INITIAL_VALUE_STATE}
						TEXT_MAP={moduleData?.TEXT_MAP}
						getPDFDownload={moduleData?.getPDFDownload}
					/>
				);
			}
			case "md-elevator-pitch": {
				return (
					<TextOnlyReadOnly
						key={key}
						INITIAL_VALUE_STATE={moduleData?.INITIAL_VALUE_STATE}
						TEXT_MAP={moduleData?.TEXT_MAP}
						getPDFDownload={moduleData?.getPDFDownload}
					/>
				);
			}
			case "md-value-prop": {
				return (
					<TextOnlyReadOnly
						key={key}
						INITIAL_VALUE_STATE={moduleData?.INITIAL_VALUE_STATE}
						TEXT_MAP={moduleData?.TEXT_MAP}
						getPDFDownload={moduleData?.getPDFDownload}
					/>
				);
			}
			case "md-lean-canvas":
				return (
					<Suspense fallback={<Box>Loading...</Box>}>
						<LeanCanvas key={key} />
					</Suspense>
				);
			case "md-pitchdeck-generator":
				return (
					<Suspense fallback={<Box>Loading...</Box>}>
						<PitchDeck key={key} />
					</Suspense>
				);
			case "md-exit-strategy": {
				return (
					<TextOnlyReadOnly
						key={key}
						INITIAL_VALUE_STATE={moduleData?.INITIAL_VALUE_STATE}
						TEXT_MAP={moduleData?.TEXT_MAP}
						getPDFDownload={moduleData?.getPDFDownload}
					/>
				);
			}
			case "md-are-you-fundable": {
				return (
					<TextOnlyReadOnly
						key={key}
						INITIAL_VALUE_STATE={moduleData?.INITIAL_VALUE_STATE}
						TEXT_MAP={moduleData?.TEXT_MAP}
						getPDFDownload={moduleData?.getPDFDownload}
					/>
				);
			}
			case "md-discovery-interview": {
				return (
					<TextOnlyReadOnly
						key={key}
						INITIAL_VALUE_STATE={moduleData?.INITIAL_VALUE_STATE}
						TEXT_MAP={moduleData?.TEXT_MAP}
						getPDFDownload={moduleData?.getPDFDownload}
					/>
				);
			}
			case "md-moat": {
				return (
					<TextOnlyReadOnly
						key={key}
						INITIAL_VALUE_STATE={moduleData?.INITIAL_VALUE_STATE}
						TEXT_MAP={moduleData?.TEXT_MAP}
						getPDFDownload={moduleData?.getPDFDownload}
					/>
				);
			}
			case "md-pricing-model-saas": {
				return (
					<TextOnlyReadOnly
						key={key}
						INITIAL_VALUE_STATE={moduleData?.INITIAL_VALUE_STATE}
						TEXT_MAP={moduleData?.TEXT_MAP}
						getPDFDownload={moduleData?.getPDFDownload}
					/>
				);
			}

			case "md-hardware-pricing-modeler": {
				return (
					<TextOnlyReadOnly
						key={key}
						INITIAL_VALUE_STATE={moduleData?.INITIAL_VALUE_STATE}
						TEXT_MAP={moduleData?.TEXT_MAP}
						getPDFDownload={moduleData?.getPDFDownload}
					/>
				);
			}
			case "md-retail-pricing-modeler": {
				return (
					<TextOnlyReadOnly
						key={key}
						INITIAL_VALUE_STATE={moduleData?.INITIAL_VALUE_STATE}
						TEXT_MAP={moduleData?.TEXT_MAP}
						getPDFDownload={moduleData?.getPDFDownload}
					/>
				);
			}
			case "md-services-pricing-modeler": {
				return (
					<TextOnlyReadOnly
						key={key}
						INITIAL_VALUE_STATE={moduleData?.INITIAL_VALUE_STATE}
						TEXT_MAP={moduleData?.TEXT_MAP}
						getPDFDownload={moduleData?.getPDFDownload}
					/>
				);
			}
			case "md-tam": {
				return (
					<TextOnlyReadOnly
						key={key}
						INITIAL_VALUE_STATE={moduleData?.INITIAL_VALUE_STATE}
						TEXT_MAP={moduleData?.TEXT_MAP}
						getPDFDownload={moduleData?.getPDFDownload}
					/>
				);
			}
			default:
				return null; // TODO -- Need "sorry was unable to load at this time. Message."
		}
	};
	return (
		<Dialog
			TransitionComponent={Transition}
			transitionDuration={600}
			open={workViewModalOpen}
			onClose={(event, reason) => {
				if (reason !== "backdropClick") {
					setWorkViewModalOpen(false);
				} else {
					setcurrentVersion(null);
					setWorkViewModalOpen(false);
				}
			}}
			sx={{
				"& .MuiDialog-container": {
					justifyContent: "flex-end",
				},
			}}
			PaperComponent={Card}
			PaperProps={{
				sxOverride: {
					borderTopRightRadius: 0,
					borderBottomRightRadius: 0,
					padding: 0,
					maxWidth: "56rem",
					overflow: "hidden",
					boxSizing: "border-box",
				},
			}}
		>
			<Box sx={{ display: "flex", flexDirection: "column", height: "100%", flex: 1, py: "3.75rem", px: "3.75rem", gap: "1.5rem", overflowY: "auto" }}>
				<Box sx={{ width: "100%" }}>
					<WorkModalHeader/>
				</Box>
				<Box sx={{ flex: 1 }}>{getWorkComponent()}</Box>
				<Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", gap: 1, mt: "auto", width: "100%" }}>
					<Button
						variant="DS1"
						color="clear"
						onClick={() => {
							setcurrentVersion(null);
							setWorkViewModalOpen(false);
						}}
					>
						Close
					</Button>
					<Button
						variant="DS1"
						color="secondary"
						onClick={() => {
							setWorkViewModalOpen(false);
							setActiveTab(1);
						}}
					>
						Edit
					</Button>
				</Box>
			</Box>
		</Dialog>
	);
};
export default WorkViewModal;
