import { Box, Typography, useTheme, Button, Fab, Badge, Autocomplete, Select, OutlinedInput, MenuItem, InputLabel, FormControl } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import TableController from "../../../../components/Tables/TableController";
import { StartupSortOptions, StartupTableColumns } from "./StartupTableColumns";
import { FounderSortOptions, FounderTableColumns } from "./FounderTableColumns";
import EditIcon from "@mui/icons-material/Edit";
import { getPartnerFounders, getPartnerStartups, removePartnerFromFounder } from "./functions";
import { useDispatch, useSelector } from "react-redux";
import UpdatedTextInputField from "../../../../common/UpdatedTextInputField";
import { setSlidingProfileData } from "../../../../modules/actions/slidingProfileActions";
import ThemeEditFlyout from "./ThemeEditFlyout";
import services from "../../../../services";
import { useHistory } from "react-router-dom";
import ROUTES_PATH from "../../../../constants/routes";
import SortComponent from "../../../../components/Tables/SortComponent";
import FilterDrawer from "./FilterDrawer";
import SelectDrop from "./SelectDrop";
import ToggleButtons from "./ToggleButtons";
import FormHelperText from "@mui/material/FormHelperText";
import { SelectIconComponent } from "../../../NewOnboard/Components";
import { setCommonDialogConfirmationData } from "../../../../modules/actions";
import UpdatedDialogConfirmation from "../../../../common/UpdatedDialogConfirmation";
import { ALERT_ICON_V1 } from "../../../../constants";
import Groups from "./Groups";

function CompanyGroupTable({ partnerCode, updatedSelection, selectedIds }) {
	const theme = useTheme();
	const history = useHistory();
	const dispatch = useDispatch();
	const [partnerCodeSelect, setPartnerCodeSelect] = useState(null);
	const [loadedPartnerCode, setloadedPartnerCode] = useState(null);
	const isAdmin = useSelector((store) => store?.auth?.isAdmin);
	const userType = useSelector((store) => store?.auth?.userType);

	const [startupsTableKey, setKeyForStartupsTable] = useState(0);

	const [foundersTableKey, setKeyForFoundersTable] = useState(0);
	const [sortCriteriaStartups, setSortCriteriaStartups] = useState({ colName: "", direction: "" });
	const [filterCriteriaStartups, setFilterCriteriaStartups] = useState({});
	const [filterDrawerOpen, setFilterDrawerOpen] = useState({ startups: false, founders: false });
	const [options, setOptions] = useState(false);
	const [buttonLoader, setButtonLoader] = useState(false);

	const [rowSelectionModel, setRowSelectionModel] = React.useState(selectedIds);

	const [selectedRecords, setSelectedRecords] = useState([]);

	useEffect(() => {
		if (typeof updatedSelection === "function") updatedSelection(rowSelectionModel);
	}, [rowSelectionModel]);

	const handleSortChange = (newSortCriteria, type) => {
		if (type === "startups") setSortCriteriaStartups(newSortCriteria);
	};

	const reloadStartupsTable = () => {
		setKeyForStartupsTable((prevKey) => prevKey + 1);
	};

	const reloadFoundersTable = () => {
		setKeyForFoundersTable((prevKey) => prevKey + 1);
	};

	const reloadTables = (pc) => {
		setloadedPartnerCode(pc); //Prevent table flash on field clear.
		reloadStartupsTable();
		reloadFoundersTable();
	};

	useEffect(() => {
		if (partnerCode) {
			setloadedPartnerCode(partnerCode); //Prevent table flash on field clear.
			setPartnerCodeSelect(partnerCode);
		}
	}, [partnerCode]);

	useEffect(() => {
		sortfilterStartups();
	}, [sortCriteriaStartups]);

	useEffect(() => {
		sortfilterStartups();
	}, [filterCriteriaStartups]);

	const sortfilterStartups = async () => {
		reloadStartupsTable();
	};

	const getStartups = async (currentPage, pageSize) => {
		const result = await getPartnerStartups(
			currentPage,
			pageSize,
			partnerCodeSelect,
			sortCriteriaStartups?.direction,
			sortCriteriaStartups?.colName,
			formatFiltersForQuery(filterCriteriaStartups)
		);
		return result;
	};

	const openStartupProfile = (id) => {
		dispatch(
			setSlidingProfileData({
				open: true,
				profileType: "STARTUP_PROFILE",
				profileId: id,
			})
		);
	};

	const formatFiltersForQuery = (filters) => {
		try {
			return Object.entries(filters)
				.map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
				.join("&");
		} catch (e) {
			console.log("Unable to process filter for query string, failed with ", e);
			return "";
		}
	};

	const handleStartupFilter = (column, value) => {
		setFilterCriteriaStartups((prevFilters) => {
			const newFilters = { ...prevFilters };
			// If the value is not empty, blank, or null, set the filter
			if (value !== "" && value !== null && value !== undefined) {
				newFilters[column] = value;
			} else {
				// If the value is empty, blank, or null, delete the filter
				delete newFilters[column];
			}
			return newFilters;
		});
	};

	const clearStartupFilters = () => {
		setFilterCriteriaStartups({});
	};

	return (
		<Box
			sx={{
				//px: { lg: 8, md: 4, xs: 2 },
				pb: "30px",
				display: "flex",
				flexDirection: "column",
				gap: "32px",
				margin: "0 auto",
				maxWidth: "1920px", // Figma specifies max content width
			}}
		>
			{(partnerCodeSelect || loadedPartnerCode) && (
				<TableController
					checkboxSelection={true}
					rowSelectionModel={rowSelectionModel}
					setRowSelectionModel={setRowSelectionModel}
					key={`startups_${startupsTableKey}`}
					requestData={(currentPage, pageSize) => getStartups(currentPage, pageSize)}
					columnDefs={StartupTableColumns(buttonLoader, setButtonLoader, reloadStartupsTable)}
					title="Startups"
					description="Select startups for this group."
					showCount
					countLabel={{ singular: "Startup", plural: "Startups" }}
					icon={
						<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
							<path
								d="M20 28V20.8C20 20.0533 20 19.6799 19.8547 19.3947C19.7268 19.1438 19.5229 18.9398 19.272 18.812C18.9868 18.6667 18.6134 18.6667 17.8667 18.6667H14.1333C13.3866 18.6667 13.0132 18.6667 12.728 18.812C12.4771 18.9398 12.2732 19.1438 12.1453 19.3947C12 19.6799 12 20.0533 12 20.8V28M25.3333 28V8.26667C25.3333 6.77319 25.3333 6.02646 25.0427 5.45603C24.787 4.95426 24.3791 4.54631 23.8773 4.29065C23.3069 4 22.5601 4 21.0667 4H10.9333C9.43986 4 8.69312 4 8.12269 4.29065C7.62093 4.54631 7.21298 4.95426 6.95732 5.45603C6.66667 6.02646 6.66667 6.77319 6.66667 8.26667V28M28 28H4M12.6667 10.6667H12.68M19.3333 10.6667H19.3467M13.3333 10.6667C13.3333 11.0349 13.0349 11.3333 12.6667 11.3333C12.2985 11.3333 12 11.0349 12 10.6667C12 10.2985 12.2985 10 12.6667 10C13.0349 10 13.3333 10.2985 13.3333 10.6667ZM20 10.6667C20 11.0349 19.7015 11.3333 19.3333 11.3333C18.9651 11.3333 18.6667 11.0349 18.6667 10.6667C18.6667 10.2985 18.9651 10 19.3333 10C19.7015 10 20 10.2985 20 10.6667Z"
								stroke={theme?.palette?.primary?.main}
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
						</svg>
					}
					noResultsMessage={
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
								gap: "16px",
								alignSelf: "stretch",
								flex: 1,
								justifyContent: "center",
							}}
						>
							<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
								<path
									d="M28 28L23.3335 23.3333M26.6667 15.3333C26.6667 21.5926 21.5926 26.6667 15.3333 26.6667C9.07411 26.6667 4 21.5926 4 15.3333C4 9.07411 9.07411 4 15.3333 4C21.5926 4 26.6667 9.07411 26.6667 15.3333Z"
									stroke={theme?.palette?.primary?.main}
									stroke-width="1.5"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
							</svg>
							<Typography variant="Text/sm/Semibold">No startups found</Typography>
						</Box>
					}
					sortComponent={
						<SortComponent
							sortOptions={StartupSortOptions}
							onSortChange={(obj) => handleSortChange(obj, "startups")}
							currentSort={sortCriteriaStartups}
						/>
					}
					filterComponent={
						<Fragment>
							<Badge
								badgeContent={Object.keys(filterCriteriaStartups).length ?? 0}
								color={"tertiary"}
								overlap="circular"
								sx={{
									"& .MuiBadge-badge": {
										fontFamily: (theme) => theme.typography["Text/xs/Semibold"],
										fontSize: ".8rem",
									},
								}}
							>
								<Button
									variant="DS1"
									iconButton={true}
									onClick={() =>
										setFilterDrawerOpen((prevState) => ({
											...prevState,
											startups: true,
										}))
									}
								>
									<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 26 26" fill="none">
										<path
											d="M17.0673 9H4.33333C2.49238 9 1 7.50762 1 5.66667C1 3.82572 2.49238 2.33333 4.33333 2.33333H17.0673M8.93266 23.6667H21.6667C23.5076 23.6667 25 22.1743 25 20.3333C25 18.4924 23.5076 17 21.6667 17H8.93266M1 20.3333C1 22.9107 3.08934 25 5.66667 25C8.244 25 10.3333 22.9107 10.3333 20.3333C10.3333 17.756 8.244 15.6667 5.66667 15.6667C3.08934 15.6667 1 17.756 1 20.3333ZM25 5.66667C25 8.244 22.9107 10.3333 20.3333 10.3333C17.756 10.3333 15.6667 8.244 15.6667 5.66667C15.6667 3.08934 17.756 1 20.3333 1C22.9107 1 25 3.08934 25 5.66667Z"
											stroke="white"
											stroke-width="1.5"
											stroke-linecap="round"
											stroke-linejoin="round"
										/>
									</svg>
								</Button>
							</Badge>
							<FilterDrawer
								isFilterOpen={filterDrawerOpen?.startups}
								closeDrawer={() =>
									setFilterDrawerOpen((prevState) => ({
										...prevState,
										startups: false,
									}))
								}
								clearAll={() => clearStartupFilters()}
								title={"Startup Filters"}
								filters={
									<Fragment>
										<ToggleButtons
											label={"Revenue"}
											selectOptions={["Yes", "No"]}
											onOptionChange={(value) => handleStartupFilter("revenue", value)}
											currentOption={filterCriteriaStartups?.revenue}
										/>
										<SelectDrop
											label={"Financing"}
											sortOptions={["None", "Priced Round", "Safe", "Conv. Note"]}
											onSortChange={(value) => handleStartupFilter("financing", value)}
											currentSort={filterCriteriaStartups?.financing}
										/>
										<SelectDrop
											label={"Current Round"}
											sortOptions={["Pre-Seed", "Seed", "Series A", "Growth"]}
											onSortChange={(value) => handleStartupFilter("currentStage", value)}
											currentSort={filterCriteriaStartups?.currentStage}
										/>
										input
										<SelectDrop
											label={"Seeking"}
											sortOptions={["Seed", "Series A"]}
											onSortChange={(value) => handleStartupFilter("seeking", value)}
											currentSort={filterCriteriaStartups?.seeking}
										/>
									</Fragment>
								}
							/>
						</Fragment>
					}
					onRowClick={(res) => openStartupProfile(res?.id)}
					dataGridProps={{ disableColumnMenu: true }}
				/>
			)}

			{/*<div>
				<h3>Selected Records:</h3>
				<ul>
					{selectedRecords.map((record) => (
						<li key={record.id}>
							{record.name}
							<button onClick={() => handleDeselect(record.id)}>Deselect</button>
						</li>
					))}
				</ul>
			</div>*/}
		</Box>
	);
}

export default CompanyGroupTable;
