import { Box, Typography } from "@mui/material";
import React from "react";
import { LOCATION_NEW_ICON, CALENDAR_BLANK, BUILDINGS_ICON, GREY_BUILDING_ICON } from "../../../../constants";
import SvgComponent from "../../../../utils/SVGColor";

const formatDateRange = (dateRange) => {
	const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

	const startDate = new Date(dateRange.startYear, dateRange.startMonth - 1);
	const endDate = dateRange?.endMonth && dateRange?.endYear ? new Date(dateRange.endYear, dateRange.endMonth - 1) : null;

	const formattedStartDate = `${months[startDate.getMonth()]} ${startDate.getFullYear()}`;
	const formattedEndDate = endDate ? `${months[endDate.getMonth()]} ${endDate.getFullYear()}` : "";

	return `${formattedStartDate} - ${endDate ? formattedEndDate : "Current"}`;
};

function PublicEducationItem({ item }) {
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				p: 2,
				backgroundColor: (theme) => theme.palette.sos.gray100,
				borderRadius: "20px",
			}}
		>
			<Typography
				variant="Display/sm/Semibold"
				component={"div"}
				sx={{
					mb: 2,
					overflow: "hidden",
					display: "-webkit-box",
					WebkitLineClamp: 2,
					WebkitBoxOrient: "vertical",
					width: "200px",
				}}
			>
				{item?.course}
			</Typography>
			<Box
				sx={{
					display: "flex",
					gap: 1,
					mt: "auto",
					mb: 1,
				}}
			>
				<Box component={"img"} src={GREY_BUILDING_ICON} alt="building" sx={{ height: "24px", width: "24px" }} />
				<Typography
					variant="Text/sm/Regular"
					component={"div"}
					sx={{
						overflow: "hidden",
						display: "-webkit-box",
						WebkitLineClamp: 2,
						WebkitBoxOrient: "vertical",
						width: "200px",
					}}
				>
					{item?.university}
				</Typography>
			</Box>
			{item?.location && (
				<Box
					sx={{
						display: "flex",
						gap: 1,
						mt: "auto",
						mb: 2,
					}}
				>
					<SvgComponent strokeColor={"#98A2B3"} svgUrl={LOCATION_NEW_ICON} style={{ height: "24px", width: "24px" }} />
					<Typography
						variant="Text/sm/Regular"
						component={"div"}
						sx={{
							overflow: "hidden",
							display: "-webkit-box",
							WebkitLineClamp: 2,
							WebkitBoxOrient: "vertical",
							width: "200px",
						}}
					>
						{item?.location}
					</Typography>
				</Box>
			)}
			<Box
				sx={{
					display: "flex",
					gap: 1,
				}}
			>
				<SvgComponent strokeColor={"#98A2B3"} svgUrl={CALENDAR_BLANK} style={{ height: "24px", width: "24px" }} />
				<Typography
					variant="Text/sm/Regular"
					component={"div"}
					sx={{
						overflow: "hidden",
						display: "-webkit-box",
						WebkitLineClamp: 2,
						WebkitBoxOrient: "vertical",
						width: "200px",
					}}
				>
					{formatDateRange({
						endMonth: item?.endMonth ? item?.endMonth : "",
						endYear: item?.endYear ? item?.endYear : "",
						startMonth: item?.startMonth ? item?.startMonth : "",
						startYear: item?.startYear ? item?.startYear : "",
					})}
				</Typography>
			</Box>
		</Box>
	);
}

export default PublicEducationItem;
