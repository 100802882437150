import * as actionTypes from "../../actions/actionTypes";
import { updateObject } from "../../../utils/updateObject";

const initialState = {
	showCommunityPartners: false,
	selectedOption: "",
	communityPartners: [],
};

const communityPartners = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SET_COMMUNITY_PARTNERS: {
			return action.payload === null ? initialState : updateObject(state, action.payload);
		}

		default:
			return state;
	}
};

export default communityPartners;
