import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import PersonaCard from "./PersonaCard";
import { CARD_ITEM } from "./Bio";
import NoDataPill from "./NoDataPill";
import { ZoomOutMapOutlined } from "@mui/icons-material";

export default function Brands({ persona }) {
	const [personaData, setpersonaData] = useState(persona);

	useEffect(() => {
		setpersonaData(persona);
	}, [persona]);

	const BRANDS = personaData?.brands?.map((obj) => {
		return {
			title: obj,
		};
	});

	return (
		<PersonaCard title="Brands interest" icon={<ZoomOutMapOutlined fontSize="large" />}>
			<Box mt={3}>
				<Box display="flex" gap="10px" flexWrap="wrap">
					{BRANDS?.map((item) => (
						<CARD_ITEM key={item.title}>
							<Typography variant="card_text">{item.title}</Typography>
						</CARD_ITEM>
					))}
				</Box>
				{!BRANDS && <NoDataPill />}
			</Box>
		</PersonaCard>
	);
}
