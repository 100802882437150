import React from "react";
import { Avatar, Typography, useTheme } from "@mui/material";

function ProfileCompletion({ percentage }) {
	const theme = useTheme();

	const getBgColor = () => {
		if (percentage === 100) {
			return theme.palette.primary.bgGreen;
		}
		return theme.palette.primary.main;
	};
	return (
		<Avatar sx={{ bgcolor: getBgColor(), width: "70px", height: "70px", borderRadius: "50%" }}>
			<Typography variant="sub_title_portfolio" color="secondary.white">
				{Math.round(percentage ? percentage : 0)}%
			</Typography>
		</Avatar>
	);
}

export default ProfileCompletion;
