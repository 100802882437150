import { Box, Divider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import OnboardingCardSelector from "../../../../components/Onboarding/OnboardingCardSelector";
import PageTitle from "../../../../components/Onboarding/PageTitle";
import {
  HOW_MANY_DEALS,
  WHAT_IS_INVESTMENT_RANGE,
} from "../../../../constants/updated-onboarding";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

export default function WhatsYourDeal({ setComplete }) {
  const history = useHistory();
  const [deals, setDeals] = useState("");
  const [investmentRange, setInvestmentRange] = useState("");
  const savedData = useSelector(state => state?.onboarding);

  useEffect(() => {
    if (savedData) {
      setDeals(savedData.totalDeals);
      setInvestmentRange(savedData.investmentRange);
    }
  }, [savedData]);

  const callComplete = (complete, data) => {
    if (setComplete) {
      setComplete(complete, data);
    }
  };

  useEffect(() => {
    if (deals && investmentRange) {
      callComplete(1, { totalDeals: deals, investmentRange: investmentRange });
    } else {
      callComplete(0, { totalDeals: deals, investmentRange: investmentRange });
    }
  }, [deals, investmentRange]);

  return (
    <Box>
      <PageTitle
        title={"What’s your deal?"}
        description="Let’s match you with the startups you’re looking for."
      />
      <OnboardingCardSelector
        cardItems={WHAT_IS_INVESTMENT_RANGE}
        flex={1 / 4}
        gridColWidth={10}
        setUserResponse={setInvestmentRange}
        userResponse={investmentRange}
        savedData={savedData.investmentRange}
        titleComp={
          <Box sx={{ my: 2, ml: 1 }}>
            <Typography variant="title_medium" sx={{ display: "flex" }}>
              1. I usually invest{" "}
              <Divider
                sx={{
                  borderColor: "primary.main",
                  width: "100px",
                }}
                flexItem
              />{" "}
              per deal.
            </Typography>
          </Box>
        }
      />
      <Box sx={{ my: 5 }}>
        <OnboardingCardSelector
          cardItems={HOW_MANY_DEALS}
          flex={1 / 4}
          gridColWidth={10}
          setUserResponse={setDeals}
          userResponse={deals}
          savedData={savedData.totalDeals}
          titleComp={
            <Box sx={{ my: 2, ml: 1 }}>
              <Typography variant="title_medium" sx={{ display: "flex" }}>
                2. I do{" "}
                <Divider
                  sx={{
                    borderColor: "primary.main",
                    width: "100px",
                  }}
                  flexItem
                />{" "}
                deals per year.
              </Typography>
            </Box>
          }
        />
      </Box>
    </Box>
  );
}
