import { TextField, Typography, Box } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import RichTextEditor from "../../../../common/RichTextEditor";
import { stateToMarkdown } from "draft-js-export-markdown";
import messages from "../../../../constants/messages";
import { REGEX } from "../../../../constants/regex";
import theme from "../../../../constants/Theme";
import {
  RICH_TEXT_EDITOR_MAIN_CONTROLS,
  RICH_TEXT_EDITOR_INLINE_CONTROLS,
} from "../../../../constants/plugin-constants";
import { EditorState, ContentState } from "draft-js";
import { stateFromMarkdown } from "draft-js-import-markdown";
import { convertDraftJsToPlainText } from "../../../../utils/rte-utils";

export default function TextAreaInput({
  userInputs,
  setUserInputs,
  handleNextClick,
  stepNo,
}) {
  const [inputValue, setInputValue] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [stepNoState, setStepNoState] = useState(null);

  const handleChange = value => {
    const inputVal = stateToMarkdown(value.getCurrentContent());
    setInputValue(inputVal);
    setUserInputs([inputVal]);
  };

  useEffect(() => {
    setStepNoState(stepNo);
    setInputValue("");
    setErrorMessage("");
  }, [stepNo]);

  useEffect(() => {
    if (userInputs.length) {
      setInputValue(userInputs[0]);
    } else {
      setInputValue("");
    }
  }, [userInputs]);

  const handleBlur = () => {
    let eventValue = convertDraftJsToPlainText(inputValue);
    if (eventValue?.trim().length <= 2000) {
      setErrorMessage("");
    } else {
      setErrorMessage(messages.STORYTELLER_TEXTBOX_VALIDATION);
    }
  };

  const handleSave = () => {
    handleNextClick();
  };

  return (
    <>
      <Box
        sx={theme => ({
          minHeight: "500px",
          border: `1px solid ${theme.palette.secondary.dark}`,
          borderRadius: theme.shape.standard3.borderRadius,
          px: 2,
        })}
      >
        {stepNo === stepNoState && (
          <RichTextEditor
            label=""
            value={inputValue}
            toolbar={true}
            inlineToolbar={false}
            inlineToolbarControls={RICH_TEXT_EDITOR_INLINE_CONTROLS}
            controls={RICH_TEXT_EDITOR_MAIN_CONTROLS}
            readOnly={false}
            onChange={handleChange}
            onSave={handleSave}
            onBlur={handleBlur}
            minHeight={"500px"}
            showAutoFocus
          />
        )}
      </Box>
      <Box>
        {errorMessage && (
          <Typography sx={{ color: "error.main" }}>{errorMessage}</Typography>
        )}
      </Box>
    </>
  );
}
