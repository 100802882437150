import { Box } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import useDimensions from "react-cool-dimensions";
import { useSelector } from "react-redux";

function PublicPitchDeckViewer() {
	const [isSmallWidth, setIsSmallWidth] = useState(false);
	const startupData = useSelector((state) => state?.startupPublicProfileData?.startupPublicData);

	const { observe, width } = useDimensions({
		breakpoints: { SM: 650 },
		updateOnBreakpointChange: true,
	});

	useEffect(() => {
		observe();
	}, []);

	useEffect(() => {
		if (width >= 650) {
			setIsSmallWidth(false);
		} else {
			setIsSmallWidth(true);
		}
	}, [width]);

	return (
		<Box ref={observe} sx={{ position: "relative", overflow: "hidden", width: "100%", height: isSmallWidth ? "600px" : "800px" }}>
			{startupData?.pitchDeckLink?.substr(startupData?.pitchDeckLink.length - 3) === "ppt" ||
				(startupData?.pitchDeckLink?.substr(startupData?.pitchDeckLink.length - 4) === "pptx" && (
					<iframe
						src={`https://view.officeapps.live.com/op/embed.aspx?src=${startupData?.pitchDeckLink}&&slide=12`}
						frameBorder="0"
						id="iframe-slides"
						title="slides"
						style={{
							width: "100%",
							height: isSmallWidth ? "600px" : "800px",
							borderRadius: "30px",
							border: "none",
							margin: 0,
							padding: 0,
							overflow: "hidden",
						}}
					></iframe>
				))}

			{startupData?.pitchDeckLink.substr(startupData?.pitchDeckLink.length - 3) === "pdf" && (
				<iframe
					src={startupData?.pitchDeckLink}
					id="iframe-pdf"
					frameBorder="0"
					style={{
						width: "100%",
						height: isSmallWidth ? "600px" : "800px",
						borderRadius: "30px",
						border: "none",
						margin: 0,
						padding: 0,
						overflow: "hidden",
					}}
				></iframe>
			)}
		</Box>
	);
}

export default PublicPitchDeckViewer;
