import { REGEX } from "../../constants/regex";

export const requestFormValid = (key, input) => {
  switch (key) {
    case "firstName":
    case "lastName":
    case "profession":
      if (input.match(REGEX.LETTERS_WITH_SPACES) && input.length > 0) {
        return true;
      } else {
        return false;
      }
    case "email":
      if (input.match(REGEX.EMAIL)) {
        return true;
      } else {
        return false;
      }
    case "linkedIn":
      if (input.match(REGEX.VALID_URL)) {
        return true;
      } else {
        return false;
      }
    case "website":
      if (input.match(REGEX.VALID_URL)) {
        return true;
      } else {
        return false;
      }
    case "description":
      if (input.match(REGEX.DESCRIPTION)) {
        return true;
      } else {
        return false;
      }
  }
};

export const buttonDisable = userInputs => {
  const inputKeys = [
    "firstName",
    "lastName",
    "email",
    "profession",
    "linkedIn",
  ];

  if (userInputs?.description?.length > 0) {
    inputKeys.push("description");
  }
  if (userInputs?.website?.length > 0) {
    inputKeys.push("website");
  }

  const validateRes = inputKeys
    .map(input => requestFormValid(input, userInputs[input]))
    .every(ele => ele);

  return validateRes;
};
