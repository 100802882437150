import {
  Bookmark,
  BookmarkBorder,
  PersonSearchRounded,
  Check,
} from "@mui/icons-material";
import {
  Box,
  Divider,
  Grid,
  Typography,
  Button,
  Card,
  useMediaQuery,
  Icon,
  Badge,
  Chip,
} from "@mui/material";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import { sosPrimary } from "../../../../../constants/Theme/colors";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import services from "../../../../../services";
import AddIcon from "@mui/icons-material/Add";
import theme from "../../../../../constants/Theme";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../../modules/actions";
import InviteCandidateComp from "../../../MarketTest/InviteCandidate/index";
import moment from "moment";
import ROUTES_PATH from "../../../../../constants/routes";
import InviteCandidateMobile from "../../../MarketTest/InviteCandidate/InviteMobileResposiveUI";
import {
  EventCard,
  IconBox,
} from "../../../../../common/EventsContainer/styles";
import AddExternalCandidate from "./ExternalCandidatePopup";
import { addInviteCandidate } from "../Functions/common";
import { toastContainer } from "../../../../../utils/toast";
import messages from "../../../../../constants/messages";
import { AVATAR_DEFAULT } from "../../../../../constants/image";

export default function ManageCandidates({
  candidates,
  externalCandidates,
  currentModuleObject,
  fetchCallback,
  fetchData,
  stepId,
}) {
  const [selectedCandidates, setSelectedCandidates] = useState([]);
  const [externalCandidatesPopup, setExternalCandidatesPopup] = useState(false);
  const [invitedCandidates, setInvitedCandidates] = useState([]);
  const [savedCandidates, setSavedCandidates] = useState([]);

  const [currentCandidateObj, setCurrentCandidateObj] = useState({});
  const marketTest = useSelector(store => store.powerUp?.marketTest);
  const [showInviteCandidatePopup, setShowInviteCandidatePopup] =
    useState(false);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const history = useHistory();

  const dispatch = useDispatch();

  const returnToDetailsPage = () => {
    history.push(ROUTES_PATH.MARKET_TEST + "/" + marketTest.id);
  };

  const handleInviteSuccess = () => {
    fetchCallback(true);
  };

  const handleAdd = (input, type) => {
    input = { ...input, inviteRole: "CANDIDATE" };
    addInviteCandidate(input)
      .then(result => {
        const data = {
          ...result,
          name: `${result.platformInvitation.firstName} ${result.platformInvitation.lastName}`,
          email: result.platformInvitation.email,
          invited: result.platformInvitation.inviteStatus === "SENT",
        };
        let updatedData = { ...data, isExternalCandidate: true };

        setExternalCandidatesPopup(false);
        if (type === "INVITE_NOW") {
          handleInviteCandidate(updatedData);
        } else {
          fetchCallback(!fetchData);
        }
      })
      .catch(error => {
        const message = error.response?.data?.message;
        toastContainer(message, "error");
      });
  };

  useEffect(() => {
    if (currentModuleObject) {
      const responses = currentModuleObject?.moduleStepResponses;
      if (responses.length) {
        setSelectedCandidates(
          JSON.parse(responses[0].answers).candidates || []
        );
      }
    }
  }, [currentModuleObject]);

  useEffect(() => {
    const savedCandidates = selectedCandidates.filter(
      candidate => candidate.selected === true
    );
    setSavedCandidates(savedCandidates);

    const invitedCandidates = selectedCandidates.filter(
      candidate => candidate.invited === true
    );
    setInvitedCandidates(invitedCandidates);
  }, [selectedCandidates]);

  const handleSelect = data => {
    let newCandidates = [...selectedCandidates];
    //check if selected
    const existingIndex = newCandidates.findIndex(
      candidate => candidate.email === data.email
    );
    if (existingIndex >= 0) {
      const existingCandidate = newCandidates[existingIndex];

      //if (existingCandidate?.invited) {
      const candidate = {
        ...existingCandidate,
        selected: !existingCandidate.selected,
      };

      newCandidates[existingIndex] = candidate;
      //} else {
      //  //remove previously selected
      //  //newCandidates = newCandidates.filter(
      //  //  candidate => candidate.email !== data.email
      //  //);
      //}
    } else {
      //add in selected candidates

      const candidate = {
        ...data,
        selected: true,
      };

      newCandidates.push(candidate);
    }
    services
      .storeModuleStepResponses(currentModuleObject.moduleId, {
        answers: JSON.stringify({ candidates: newCandidates }),
        moduleResponseId: currentModuleObject.id,
        isLastStep: true,
        isCompleted: true,
        //fixed module stepid
        moduleStepId: stepId,
      })
      .then(res => {
        handleInviteSuccess();
      })
      .catch(error => {
        console.log("error", error);
      });
    setSelectedCandidates(newCandidates);
  };

  const isSelected = data => {
    const selected = selectedCandidates?.find(
      candidate => candidate.email === data.email
    );

    if (selected && selected.selected) {
      return true;
    } else {
      return false;
    }
  };

  const isInvited = data => {
    const invited = selectedCandidates?.find(
      candidate => candidate.email === data.email
    );

    if (invited && invited.invited) {
      return true;
    } else {
      return false;
    }
  };

  const handleInviteCandidate = data => {
    const candidateObj = {
      currentModuleObject,
      ...data,
    };

    setCurrentCandidateObj(candidateObj);
    setShowInviteCandidatePopup(true);
  };

  const handleViewProfile = data => {
    const candidateObj = {
      currentModuleObject,
      ...data,
    };
    dispatch(actions.setCandidateDetails(candidateObj));
    dispatch(actions.setExternalCandidateDetails(externalCandidates));

    history.push(ROUTES_PATH.CANDIDATE_DETAILS.replace(":id", data.id));
  };

  const CandidateCard = ({ data, isExternalCandidate }) => {
    let updatedData = { ...data, isExternalCandidate: isExternalCandidate };

    return (
      <Card
        variant="primary"
        sx={theme => ({
          boxShadow:
            "rgb(0 0 0 / 30%) 0px 1px 2px, rgb(0 0 0 / 15%) 0px 1px 3px 1px",
          borderRadius: theme.shape.standard.borderRadius,
          backgroundColor: theme => theme.palette.text.background,
        })}
      >
        <Grid container sx={{ p: 2 }}>
          <Grid item xs={11}>
            <Box display={"flex"}>
              <img
                src={data.avatar || AVATAR_DEFAULT}
                height="56"
                width="56"
                style={{ borderRadius: "50%" }}
              />

              <Box sx={{ ml: 1 }} display={"flex"} flexDirection="column">
                <Typography variant="headline6">
                  {data.name?.trim() || data.email}
                </Typography>

                {externalCandidates.find(
                  cand => cand?.email === data?.email
                ) ? (
                  <Grid container spacing={1} alignItems="center">
                    <Grid item xs={12}>
                      <Typography
                        variant="label_large"
                        color={"secondary.dark"}
                        sx={{
                          display: "block",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "80%",
                        }}
                      >
                        {data.email}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Box
                        color="text.custom0Container"
                        sx={{
                          p: 1,
                          background: theme.palette.containers.custom0Container,
                          display: "flex",
                          alignItems: "center",
                          borderRadius: 2,
                          borderStyle: "solid",
                          borderWidth: 1,
                          borderColor: theme.palette.text.custom0,
                          mt: 1,
                          width: "max-content",
                        }}
                      >
                        <Typography variant="label_small" color="text.custom0">
                          External Candidate
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                ) : (
                  <>
                    <Grid
                      container
                      spacing={1}
                      alignItems="center"
                      sx={{ py: 1 }}
                    >
                      <Grid item>
                        <Typography variant="label_large">Title: {data.title}</Typography>
                      </Grid>
                    </Grid>

                    <Grid container spacing={1} alignItems="center">
                      <Grid item>
                        <Typography
                          variant="label_large"
                        >
                          Email: <a href={"mailto:"+data.email}>{data.email}</a>
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                )}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={1}>
            {isSelected(data) ? (
              <Bookmark
                style={{
                  cursor: "pointer",
                  color: theme.palette.secondary.dark,
                }}
                onClick={() => handleSelect(data)}
              />
            ) : (
              <BookmarkBorder
                style={{
                  cursor: "pointer",
                  color: theme.palette.secondary.dark,
                }}
                onClick={() => handleSelect(data)}
              />
            )}
          </Grid>
        </Grid>
        {/* <Divider sx={{ mt: 2, borderColor: "secondary.light" }} />

        <Box
          display={"flex"}
          alignItems="center"
          sx={{ width: "max-content", ml: "auto", my: 2, mr: 2 }}
        >
          {isInvited(data) ? (
            <Button
              variant="text"
              startIcon={<Check />}
              sx={{ width: "max-content", mr: 1, color: "text.custom3" }}
            >
              Invite Sent
            </Button>
          ) : (
            <Button
              variant="outlined"
              startIcon={<AddIcon />}
              sx={{ width: "max-content", mr: 1 }}
              onClick={() => handleInviteCandidate(updatedData)}
            >
              Invite
            </Button>
          )}

          <Button
            variant="contained"
            sx={{ width: "max-content" }}
            onClick={() => handleViewProfile(updatedData)}
          >
            View Profile
          </Button>
        </Box> */}
      </Card>
    );
  };

  const NoTestsComponent = () => {
    return (
      <>
        <Box sx={{ textAlign: "center", mx: "auto", my: 4 }}>
          <Box
            sx={{
              backgroundColor: sosPrimary[50],
              borderRadius: "var(--icon-border-radius)",
              p: 2,
              maxWidth: "max-content",
              display: "flex",
              mx: "auto",
            }}
          >
            <PersonSearchRounded
              fontSize={"large"}
              sx={{ color: "primary.main" }}
            />
          </Box>

          <Box sx={{ mt: 1 }}>
            <Typography variant="label_large" color={"secondary.dark"}>
              Coming Soon
            </Typography>
          </Box>
          {/* <Box sx={{ mt: 1 }}>
            <Typography variant="caption" color={"secondary.dark"}>
              Your added external candidates will show up here.
            </Typography>
          </Box> */}
        </Box>
      </>
    );
  };

  return (
    <Box>
      {isSmallScreen ? (
        <InviteCandidateMobile
          showInviteCandidatePopup={showInviteCandidatePopup}
          setShowInviteCandidatePopup={setShowInviteCandidatePopup}
          candidateObj={currentCandidateObj}
          handleInviteSuccess={handleInviteSuccess}
          stepId={stepId}
        />
      ) : (
        <InviteCandidateComp
          showInviteCandidatePopup={showInviteCandidatePopup}
          setShowInviteCandidatePopup={setShowInviteCandidatePopup}
          candidateObj={currentCandidateObj}
          handleInviteSuccess={handleInviteSuccess}
          stepId={stepId}
        />
      )}

      {invitedCandidates.length > 0 && (
        <Box sx={{ mb: 3 }}>
          <Box sx={{ my: isSmallScreen ? 4 : 2 }}>
            <Typography
              variant={isSmallScreen ? "title_medium" : "title_large"}
              sx={{ my: isSmallScreen ? 4 : 1 }}
            >
              Invited Candidates
            </Typography>
          </Box>

          <Grid container spacing={2}>
            {invitedCandidates &&
              invitedCandidates.map(data => {
                return (
                  <Grid item lg={6} md={12} sm={12} xs={12}>
                    <CandidateCard data={data} isExternalCandidate={false} />
                  </Grid>
                );
              })}
          </Grid>
        </Box>
      )}

      {savedCandidates.length > 0 && (
        <Box sx={{ mb: 3 }}>
          <Box sx={{ my: isSmallScreen ? 4 : 2 }}>
            <Typography
              variant={isSmallScreen ? "title_medium" : "title_large"}
              sx={{ my: isSmallScreen ? 4 : 1 }}
            >
              Saved Candidates
            </Typography>
          </Box>
          <Grid container spacing={2}>
            {savedCandidates &&
              savedCandidates.map(data => {
                return (
                  <Grid item lg={6} md={12} sm={12} xs={12}>
                    <CandidateCard data={data} isExternalCandidate={false} />
                  </Grid>
                );
              })}
          </Grid>
        </Box>
      )}

      {candidates.length > 0 && (
        <Box sx={{ mb: 3 }}>
          <Box sx={{ my: isSmallScreen ? 4 : 2 }}>
            <Typography
              variant={isSmallScreen ? "title_medium" : "title_large"}
            >
              Candidates in StartupOS{" "}
            </Typography>
          </Box>
          <Grid container spacing={2}>
            {candidates &&
              candidates.map(data => {
                return (
                  <Grid item lg={6} md={12} sm={12} xs={12}>
                    <CandidateCard data={data} isExternalCandidate={false} />
                  </Grid>
                );
              })}
          </Grid>
        </Box>
      )}

      <Box>
        <Box
          sx={{
            my: isSmallScreen ? 4 : 2,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Typography
              variant={isSmallScreen ? "title_medium" : "title_large"}
            >
              External Candidates{" "}
            </Typography>
          </Box>
          <Box>
            {/* <Button
              variant="link"
              sx={{ color: theme.palette.primary.main }}
              startIcon={<AddIcon />}
              onClick={() => setExternalCandidatesPopup(true)}
            >
              Add external candidate
            </Button> */}
          </Box>
        </Box>
        <Grid container spacing={2}>
          {externalCandidates.length > 0 ? (
            <>
              {externalCandidates &&
                externalCandidates.map(data => {
                  return (
                    <Grid item lg={6} md={12} sm={12} xs={12}>
                      <CandidateCard data={data} isExternalCandidate={true} />
                    </Grid>
                  );
                })}
            </>
          ) : (
            <NoTestsComponent />
          )}
        </Grid>
      </Box>

      {!isSmallScreen && <Divider sx={{ mt: 3, mb: 2 }} />}

      <Box display="flex">
        {!isSmallScreen && (
          <Button
            onClick={returnToDetailsPage}
            sx={{ mr: "initial", width: "max-content" }}
          >
            Return to PowerUp Details
          </Button>
        )}
      </Box>

      <AddExternalCandidate
        open={externalCandidatesPopup}
        onClose={() => setExternalCandidatesPopup(false)}
        addCandidate={handleAdd}
      />
    </Box>
  );
}
