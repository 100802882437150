import React, { Suspense, useContext, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { PowerUPContext } from "../../PowerUPController";

const ElevatorPitch = React.lazy(() => import("./ElevatorPitch/md-elevator-pitch"));
const FounderStory = React.lazy(() => import("./FounderStory/md-story"));
const ValueProp = React.lazy(() => import("./ValueProp/md-value-prop"));
const LeanCanvas = React.lazy(() => import("./LeanCanvas/md-lean-canvas"));
const PersonaBuilder = React.lazy(() => import("./PersonaBuilder/md-persona"));
const ExitStrategy = React.lazy(() => import("./ExitStrategy/md-exit-strategy"));
const AreYouFundable = React.lazy(() => import("./AreYouFundable/md-are-you-fundable"));
const DiscoveryInterview = React.lazy(() => import("./DiscoveryInterview/md-discovery-interview"));
const Moat = React.lazy(() => import("./Moat/md-moat"));
const ProblemSolutionFit = React.lazy(() => import("./ProblemSolutionFit/md-prob-sol"));
const SaaSPricingModeler = React.lazy(() => import("./SaaSPricingModeler/md-pricing-model-saas"));
const TamSamSom = React.lazy(() => import("./TamSamSom/md-tam"));
const MVPPrioritizer = React.lazy(() => import("./MVPPrioritizer/md-mv-prioritizer"));
const RetailPricingModeler = React.lazy(() => import("./RetailPricingModeler/md-retail-pricing-modeler"));
const ServicesPricingModeler = React.lazy(() => import("./ServicesPricingModeler/md-services-pricing-modeler"));
const HardwarePricingModeler = React.lazy(() => import("./HardwarePricingModeler/md-hardware-pricing-modeler"));
const PitchDeckGenerator = React.lazy(() => import("./PitchDeckGenerator/md-pitchdeck-generator"));
const AreYouEnterprise = React.lazy(() => import("./AreYouEnterprise/md-are-you-enterprise"));

// const Template = React.lazy(() => import("./PowerUpTemplate/md-powerup-template-module"));

function DoIt() {
	const { powerupDetails, currentVersion } = useContext(PowerUPContext);
	const [key, setKey] = useState(0);

	useEffect(() => {
		// Update the key whenever currentVersion changes
		setKey((prevKey) => prevKey + 1);
	}, [currentVersion]);

	const getDoItComponent = () => {
		const currentModuleID = powerupDetails?.modules?.[0]?.moduleUuid;
		if (!currentModuleID) return null; // TODO -- Need "sorry was unable to load at this time. Message."

		switch (currentModuleID) {
			case "md-story":
				return <FounderStory key={key} />;
			case "md-persona":
				return <PersonaBuilder key={key} />;
			case "md-elevator-pitch":
				return <ElevatorPitch key={key} />;
			case "md-value-prop":
				return <ValueProp key={key} />;
			case "md-lean-canvas":
				return <LeanCanvas key={key} />;
			case "md-exit-strategy":
				return <ExitStrategy key={key} />;
			case "md-are-you-fundable":
				return <AreYouFundable key={key} />;
			case "md-discovery-interview":
				return <DiscoveryInterview key={key} />;
			case "md-moat":
				return <Moat key={key} />;
			case "md-prob-sol":
				return <ProblemSolutionFit key={key} />;
			case "md-pricing-model-saas":
				return <SaaSPricingModeler key={key} />;
			case "md-tam":
				return <TamSamSom key={key} />;
			case "md-mv-prioritizer":
				return <MVPPrioritizer key={key} />;
			case "md-enterprise-ready":
				return <AreYouEnterprise key={key} />;
			case "md-retail-pricing-modeler":
				return <RetailPricingModeler key={key} />;
			case "md-services-pricing-modeler":
				return <ServicesPricingModeler key={key} />;
			case "md-hardware-pricing-modeler":
				return <HardwarePricingModeler key={key} />;
			case "md-pitchdeck-generator":
				return <PitchDeckGenerator key={key} />;
			//case "md-powerup-template-module":
			//	return <Template key={key} />;
			default:
				return null; // TODO -- Need "sorry was unable to load at this time. Message."
		}
	};

	return <Suspense fallback={<Box>Loading...</Box>}>{getDoItComponent()}</Suspense>;
}

export default DoIt;
