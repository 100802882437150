import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { SOS_LOGO_BLACK } from "../../../constants";
import theme from "../../../constants/Theme";

function MissingAsset({ sxProps, message, bgColor = theme.palette.sos.blueLight }) {
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
				gap: "15px",
				borderRadius: "30px",
				backgroundColor: bgColor,
				...sxProps,
			}}
		>
			<img src={SOS_LOGO_BLACK} style={{ filter: "invert(.8)" }} />
			<Typography
				variant="customValue"
				sx={{ fontFamily: "PoppinsMedium", fontSize: "20px", lineHeight: "30px", color: (theme) => theme.palette.sos.gray400, textAlign: "center" }}
			>
				{message}
			</Typography>
		</Box>
	);
}

export default MissingAsset;
