import { useEffect, useState } from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import { PersistGate } from "redux-persist/integration/react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-chat-elements/dist/main.css";
/// Commented Out - In favor of using Pendo Tours/Guides.
/// Decided to comment rather than remove in case we want to revert back to using in house tour components.
// import TourComp from "./components/Workspace/Tour";
import { store, persistor } from "./modules";
import Routes from "./routes";
import { displayVersion } from "./utils/console-reporting";
import "./App.css";
import "./overrides.css";
import { hotjar } from "react-hotjar";

import PowerUps from "./containers/Partner/stores/powerups";
import { WebSocketControl } from "./utils/notificationControl";
import client from "./utils/apolloClient";

import { ApolloProvider } from "@apollo/client";
import ZendeskChatFAB from "./utils/ZendeskChatFAB";
import services from "./services";
import { CustomThemeProvider } from "./constants/Theme/ThemeContext";

// const tagManagerArgs = {
//   gtmId: "GTM-PKCXDDD",
//   dataLayer: {
//     userId: "001",
//     userProject: "project",
//     companyName: "StartupOS",
//     userType: "founder",
//   },
//   dataLayerName: "App.js Data Layer",
// };

ReactGA.initialize("G-J999ZCX0NF"); // Development
ReactGA.initialize("G-XL1YPWR9TY"); // PRODUCTION

function App() {
	const isPublicPowerup = window?.location?.pathname?.includes("/public/powerups") ?? false;

	useEffect(() => {
		displayVersion();
	}, []);

	//Hotjar Tracking Code
	useEffect(() => {
		hotjarInitialization();
	}, []);

	function hotjarInitialization() {
		const queryString = window.location;
		if (queryString.origin === "https://app.startupos.com") {
			hotjar.initialize(3280583, 6);
		}
	}

	return (
		<ApolloProvider client={client}>
			<PowerUps.Context.Provider value={PowerUps.values()}>
				<Provider store={store}>
					<PersistGate loading={null} persistor={persistor}>
						<CustomThemeProvider>
							<WebSocketControl />
							<Router
								getUserConfirmation={() => {
									/* Empty callback to block the default browser prompt */
								}}
							>
								<Routes />
								<ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={true} newestOnTop={true} icon={false} />
								{!isPublicPowerup && <ZendeskChatFAB />}

								{/*
          /// Commented Out - In favor of using Pendo Tours/Guides.
          /// Decided to comment rather than remove in case we want to revert back to using in house tour components.
          <TourComp />
          */}
							</Router>
						</CustomThemeProvider>
					</PersistGate>
				</Provider>
			</PowerUps.Context.Provider>
		</ApolloProvider>
	);
}

export default App;
