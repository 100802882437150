import React, { useState, useEffect, version } from "react";
import DialogComponent from "../../../common/CommonDialog";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined";
import {
	Button,
	Typography,
	Box,
	Card,
	Divider,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	TableContainer,
	tableCellClasses,
	CardActions,
	useMediaQuery,
	CircularProgress,
} from "@mui/material";
import { Add } from "@mui/icons-material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import theme from "../../../constants/Theme";
import services from "../../../services";
import { getCurrentDate } from "../../../utils/date";
import { toastContainer } from "../../../utils/toast";
import messages from "../../../constants/messages";
import MessageHandlerModal from "../../../common/modal/MessageHandler";
import LoadingButton from "../../../common/Loadingbutton";
import { handleMixPanel } from "../../../utils/mixPanelEventHandling";

export default function InProgressPowerupsPopup({
	open,
	onClose,
	powerupId,
	handleResumePowerup,
	handleStartPowerup,
	inProgressWorks,
	getDetailsApiCall,
	startNewPowerupLoader,
}) {
	const [isAscending, setIsAscending] = useState(false);
	const [isDateAscending, setIsDateAscending] = useState(false);
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
	const [versionsList, setVersionsList] = useState([]);
	const [filter, setFilter] = useState("lastModifiedAt");
	const [pageData, setPageData] = useState({
		totalPages: 1,
		totalElements: 1,
		isFirst: true,
		isLast: true,
	});
	const [confirmPopup, setConfirmPopup] = useState(false);
	const [currentModuleId, setCurrentModuleId] = useState(null);
	const [loading, setLoading] = useState(false);

	const closeConfirmPopup = () => {
		setConfirmPopup(false);
	};

	const handleArchive = (id) => {
		setCurrentModuleId(id);
		setConfirmPopup(true);
	};

	const onOk = () => {
		archivePowerupModule(currentModuleId);
		closeConfirmPopup();
	};

	const archivePowerupModule = (responseId) => {
		services
			.archiveModuleResponse({
				responseId: responseId,
				status: true,
			})
			.then((result) => {
				if (result.data.code === 200) {
					const versions = [...versionsList].filter((item) => item.id !== responseId);
					setVersionsList(versions);
					toastContainer(messages.ARCHIVE_SUCCESS, "success");
					if (!versions.length) {
						getDetailsApiCall();
						setTimeout(() => {
							onClose();
						}, 1000);
					}
				}
			})
			.catch((error) => {
				toastContainer(messages.ARCHIVE_ERROR, "error");
			});
	};

	const getInProgressWorksApiCall = () => {
		setLoading(true);
		let sort = "ASC";
		if (filter === "name") {
			sort = isAscending ? "ASC" : "DESC";
		} else {
			sort = isDateAscending ? "ASC" : "DESC";
		}
		services
			.getMyWorkDetails(powerupId, 1, sort, filter, false, false, inProgressWorks)
			.then((result) => {
				const data = result?.data?.data;
				setLoading(false);
				// setPageData({
				// 	totalPages: data?.totalPages || 0,
				// 	totalElements: data.totalElements || 0,
				// 	isFirst: data.isFirst,
				// 	isLast: data.isLast,
				// });
				const filteredData = data?.filter((item) => !item.isArchived);

				setVersionsList(filteredData || []);
			})
			.catch((error) => {
				setLoading(false);
				console.log(error);
			});
	};

	useEffect(() => {
		if (open) {
			getInProgressWorksApiCall();
		}
	}, [open, isAscending, isDateAscending]);

	return (
		<>
			<MessageHandlerModal
				onOk={onOk}
				onClose={closeConfirmPopup}
				text={"Are you sure you want to archive this?"}
				heading="Archive"
				okText={"Archive"}
				cancelText={"No thanks"}
				messageType="primary"
				isOpen={confirmPopup}
				onCancel={closeConfirmPopup}
			/>
			<DialogComponent
				isOpen={open}
				onClose={onClose}
				title={`PowerUps in Progress`}
				titleVariant={"title_large"}
				contentStyle={{ px: 0 }}
				maxWidth={isSmallScreen ? "sm" : "md"}
				fullWidth
				PaperProps={{
					style: {
						borderRadius: isSmallScreen ? 2 : 8,
					},
				}}
				dialogAction={
					<Box display="flex" sx={{ mr: 2, py: 2 }}>
						<LoadingButton
							loading={startNewPowerupLoader}
							startIcon={<Add />}
							onClick={() => {
								handleStartPowerup("NEW_VERSION");
							}}
						>
							Start new
						</LoadingButton>
					</Box>
				}
			>
				<Box sx={{ p: 3 }}>
					<Box sx={{ my: 2 }}>
						<Typography variant="subhead1">
							You have multiple PowerUps that are in progress. Start a new instance or select which you would like to resume.
						</Typography>
					</Box>

					{loading ? (
						<Box textAlign={"center"}>
							{" "}
							<CircularProgress />{" "}
						</Box>
					) : (
						<Box>
							<Table aria-label="simple table">
								<TableHead>
									<TableRow>
										<TableCell
											sx={{
												display: "flex",
												alignItems: "center",
												justifyContent: "flex-start",
												py: 2,
												px: 0,
											}}
											onClick={() => {
												setFilter("name");
												setIsAscending(!isAscending);
											}}
										>
											<Typography variant="title_small">Name</Typography>
											<Box
												sx={{
													ml: 1,
													cursor: "pointer",
													display: "flex",
													alignItems: "center",
												}}
											>
												{isAscending ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />}
											</Box>
										</TableCell>
										<TableCell align="left">
											<Typography variant="title_small">Step</Typography>
										</TableCell>
										{!isSmallScreen && (
											<TableCell
												sx={{ display: "flex", alignItems: "center" }}
												onClick={() => {
													setFilter("lastModifiedAt");
													setIsDateAscending(!isDateAscending);
												}}
											>
												<Typography variant="title_small" sx={{ whiteSpace: "nowrap" }}>
													Modified Date
												</Typography>
												<Box
													sx={{
														ml: 1,
														cursor: "pointer",
														display: "flex",
														alignItems: "center",
													}}
												>
													{isDateAscending ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />}
												</Box>
											</TableCell>
										)}

										{!isSmallScreen && (
											<TableCell align="left" sx={{ whiteSpace: "nowrap" }}>
												<Typography variant="title_small">Modified By</Typography>
											</TableCell>
										)}

										<TableCell align="left"></TableCell>
										<TableCell align="left"></TableCell>
									</TableRow>
								</TableHead>
								<TableBody
									sx={{
										[`& .${tableCellClasses.root}`]: {
											borderBottom: "none",
										},
									}}
								>
									{versionsList.map((row) => (
										<TableRow key={row.name} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
											<TableCell component="th" scope="row" sx={{ px: 0, py: 2 }}>
												<Typography variant="title_small" sx={{ whiteSpace: "nowrap" }}>
													{row.name}
												</Typography>
											</TableCell>
											<TableCell align="left">
												<Typography sx={{ whiteSpace: "nowrap" }}>
													{row.completedSteps} of {row.totalSteps}
												</Typography>
											</TableCell>
											{!isSmallScreen && (
												<TableCell align="left" sx={{ whiteSpace: "nowrap" }}>
													{getCurrentDate(new Date(row.lastModifiedAt))}
												</TableCell>
											)}
											{!isSmallScreen && (
												<TableCell align="left">
													<Typography sx={{ whiteSpace: "nowrap" }}>{row.modifiedBy}</Typography>{" "}
												</TableCell>
											)}
											<TableCell align="left">
												<Button
													variant="outlined"
													startIcon={<ArrowForwardIosIcon />}
													sx={{ width: "max-content" }}
													onClick={() => {
														switch (powerupId) {
															case 1:
																handleMixPanel("Powerup Resumed", {
																	"Percent Complete": "",
																	"Powerup ID": powerupId,
																	"Powerup Name": "Value Proposition",
																});
																break;
															case 2:
																handleMixPanel("Powerup Resumed", {
																	"Percent Complete": "",
																	"Powerup ID": powerupId,
																	"Powerup Name": "Founder Story",
																});
																break;
															case 3:
																handleMixPanel("Powerup Resumed", {
																	// new design
																	"Percent Complete": "",
																	"Powerup ID": powerupId,
																	"Powerup Name": "Persona Builder",
																});
																break;
															case 5:
																handleMixPanel("Powerup Resumed", {
																	"Percent Complete": "",
																	"Powerup ID": powerupId,
																	"Powerup Name": "Problem Solution Fit",
																});
																break;
															case 6:
																handleMixPanel("Powerup Resumed", {
																	"Percent Complete": "",
																	"Powerup ID": powerupId,
																	"Powerup Name": "Brandstorming",
																});
																break;
															case 7:
																handleMixPanel("Powerup Resumed", {
																	"Percent Complete": "",
																	"Powerup ID": powerupId,
																	"Powerup Name": "TAM/SAM/SOM",
																});
																break;
															case 8:
																handleMixPanel("Powerup Resumed", {
																	"Percent Complete": "",
																	"Powerup ID": powerupId,
																	"Powerup Name": "Value Proposition",
																});
																break;
															case 9:
																handleMixPanel("Powerup Resumed", {
																	"Percent Complete": "",
																	"Powerup ID": powerupId,
																	"Powerup Name": "Pricing Modeler",
																});
																break;
															case 20:
																handleMixPanel("Powerup Resumed", {
																	// new design
																	"Percent Complete": "",
																	"Powerup ID": powerupId,
																	"Powerup Name": "MVP Prioritizer",
																});
																break;
														}

														handleResumePowerup(row.module?.id, row.module?.moduleUuid, row.id);
													}}
												>
													Resume
												</Button>
											</TableCell>
											<TableCell align="left" onClick={() => handleArchive(row.id)}>
												<ArchiveOutlinedIcon sx={{ cursor: "Pointer" }} />
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</Box>
					)}
				</Box>
			</DialogComponent>
		</>
	);
}
