import { Box, FormControl, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import RatingComponent from "../../../common/rating";
import { RATING_LABELS } from "../../../constants";

export const ReviewForm = ({ ...props }) => {
  const [value, setValue] = useState(
    props.currentValue ? props.currentValue : 0
  );
  // added negative value to handle the rating component hover state as per mui documentation
  const [hover, setHover] = useState(-1);
  const [inputValue, setInputValue] = useState(
    props.currentInput ? props.currentInput : ""
  );

  const handleChange = e => {
    const val = e.target.value.trimStart();

    if (val.length > 4000) {
      return;
    } else {
      setInputValue(val);
      props.inputChange(val);
    }
  };

  function getLabelText(label_val) {
    return `${label_val} Star${label_val !== 1 ? "s" : ""}, ${
      RATING_LABELS[label_val]
    }`;
  }

  const onChange = (event, newValue) => {
    setValue(newValue);
    props.starValueChange(newValue || 0);
  };

  const onChangeActive = (event, newHover) => {
    console.log("newHover", newHover, event);
    setHover(newHover);
  };

  return (
    <>
      <Box sx={{ p: 3, textAlign: "center" }}>
        <Box sx={{ ml: 2, pb: 2 }}>
          {/*added negative value to handle the rating component hover state as per mui documentation*/}
          <Typography variant="label_medium">
            {(value !== null && RATING_LABELS[hover !== -1 ? hover : value]) ||
              "How would you rate?"}
          </Typography>
        </Box>
        <RatingComponent
          isReadOnly={false}
          rating={value}
          onChange={onChange}
          onChangeActive={onChangeActive}
          getLabelText={getLabelText}
          starPadding={"10px"}
          iconColor="#4B454E"
          color="#4B454E"
        />
      </Box>

      <Box sx={{ mx: 2 }}>
        <FormControl sx={{ width: 1 }}>
          <TextField
            spellCheck="true"
            label="Your feedback (optional)"
            fullWidth
            value={inputValue}
            onChange={e => handleChange(e)}
            multiline
            minRows={4}
            sx={{
              mt: 2,
            }}
          />
          <Typography align="right">{inputValue.length}/4000</Typography>
        </FormControl>
      </Box>
    </>
  );
};
