import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import {
  Switch,
  useRouteMatch,
  Route,
  Redirect,
  useHistory,
} from "react-router-dom";
import { Layout as OnboardingLayout } from "../Layout/index";
import { AdvisorOnboarding } from "./AdvisorController";
import { FounderOnboarding } from "./FounderController";
import { InvestorOnboarding } from "./InvestorController";
import { MentorOnboarding } from "./MentorController";
import { TeammateOnboarding } from "./TeammateController";
import { StudentOnboarding } from "./StudentController";
import { PartnerOnboarding } from "./PartnerController";
import { ProfessorOnboarding } from "./ProfessorController";
import { OnboardingView } from "./OnboardingView";
import WhoAreYou from "../../../containers/Onboarding/Updated/WhoAreYou";
import { useDispatch } from "react-redux";
import { setOnboardingData } from "../../../modules/actions";
import ROUTES_PATH from "../../../constants/routes";
import WhoAreYouBackdoor from "../../../containers/Onboarding/Updated/WhoAreYouBackdoor";

export const OnboardingPageController = () => {
  const { url, path } = useRouteMatch();
  const [role, setRole] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const [stepObj, setStepObj] = useState([
    {
      label: "Select Your Role",
      complete: role ? true : false,
    },
    {
      label: "Continue Your Journey",
      complete: false,
    },
  ]);

  const goToRoleOnboarding = () => {
    // Forward choice to correct onboarding.
    // Next button action

    if (role.role) {
      dispatch(setOnboardingData({ role: role.role }));
      history.push(
        `${ROUTES_PATH.ONBOARDING_BASEPATH}/${role.role.toLowerCase()}/2`
      );
    }
  };

  return (
    <Switch>
      <Route path={path} exact>
        <OnboardingView
          stepObj={stepObj}
          currentStep={1}
          getNextPage={goToRoleOnboarding}
          content={
            <WhoAreYou
              setComplete={(value, data) => {
                if (value) {
                  setStepObj([
                    {
                      label: "Select Your Role",
                      complete: true,
                    },
                    {
                      label: `Continue ${data.role} Journey`,
                      complete: false,
                    },
                  ]);
                  setRole(data);
                } else {
                  setStepObj([
                    {
                      label: "Select Your Role",
                      complete: false,
                    },
                    {
                      label: `Continue Your Journey`,
                      complete: false,
                    },
                  ]);
                  setRole(null);
                }
              }}
            />
          }
          sidebarTitle={"My Journey"}
        />
      </Route>

      <Route path={`${path}/backdoor`}>
        <OnboardingView
          stepObj={stepObj}
          currentStep={1}
          getNextPage={goToRoleOnboarding}
          content={
            <WhoAreYouBackdoor
              setComplete={(value, data) => {
                if (value) {
                  setStepObj([
                    {
                      label: "Select Your Role",
                      complete: true,
                    },
                    {
                      label: `Continue ${data.role} Journey`,
                      complete: false,
                    },
                  ]);
                  setRole(data);
                } else {
                  setStepObj([
                    {
                      label: "Select Your Role",
                      complete: false,
                    },
                    {
                      label: `Continue Your Journey`,
                      complete: false,
                    },
                  ]);
                  setRole(null);
                }
              }}
            />
          }
          sidebarTitle={"My Journey"}
        />
      </Route>
      <Route path={`${path}/founder`}>
        <FounderOnboarding />
      </Route>
      <Route path={`${path}/mentor`}>
        <MentorOnboarding />
      </Route>
      <Route path={`${path}/investor`}>
        <InvestorOnboarding />
      </Route>
      <Route path={`${path}/teammate`}>
        <TeammateOnboarding />
      </Route>
      <Route path={`${path}/student`}>
        <StudentOnboarding />
      </Route>
      <Route path={`${path}/advisor`}>
        <AdvisorOnboarding />
      </Route>
      <Route path={`${path}/partner`}>
        <PartnerOnboarding />
      </Route>
      <Route path={`${path}/instructor`}>
        <ProfessorOnboarding />
      </Route>

      <Route path={`${path}/*`}>
        <Redirect to={path} />
      </Route>
    </Switch>
  );
};
