import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";

export const StyledTeamCard = styled.div(({ theme, isHidden = false }) => {
	return {
		display: "flex",
		alignItems: "center",
		background: isHidden ? theme.palette.secondary.bgGray : theme.palette.secondary.white,
		boxShadow: "0px 10px 15px rgba(0, 0, 0, 0.05)",
		borderRadius: theme.shape.standard13.borderRadius,
		padding: theme.spacing(1),
		gap: theme.spacing(1),
	};
});

export const UploadLogo = styled(Typography)(({ theme, width, height, borderWidth = "9px" }) => ({
	display: "flex",
	alignItems: "center",
	cursor: "pointer",
	justifyContent: "center",
	border: `${borderWidth} solid ${theme.palette.secondary.black}`,
	borderRadius: "50%",
	width: { width },
	height: { height },
	filter: "drop-shadow(0px 5.26984px 10.5397px rgba(0, 0, 0, 0.25))",
}));

export const StyledProfileCard = styled(Box)(({ theme, sx }) => {
	return {
		borderRadius: "40px",
		backgroundColor: "white",
		boxSizing: "border-box",
		padding: "32px 16px", // default padding
		[theme.breakpoints.between("sm", "md")]: {
			padding: "32px 32px", // padding between sm and md
		},
		[theme.breakpoints.up("md")]: {
			padding: "32px 40px 48px", // padding above md
		},
		// boxShadow: "0px 10px 25px rgba(0, 0, 0, 0.05)",
		...sx,
	};
});
