import React, { useContext, useEffect, useState, Fragment } from "react";
import { useHistory } from "react-router-dom";
import { Box, Skeleton, Typography } from "@mui/material";
import { USERS_THREE_ICON } from "../../../../constants";
import SvgComponent from "../../../../utils/SVGColor";
import PublicExperienceItem from "./PublicExperienceItem";
import { useSelector } from "react-redux";

function PublicExperienceCard() {
	const history = useHistory();
	const isLoading = useSelector((state) => state?.accountPublicProfileData?.loading);
	const profileData = useSelector((state) => state?.accountPublicProfileData?.accountPublicData);

	return (
		<Box
			sx={(theme) => ({
				borderRadius: "40px",
				backgroundColor: "white",
				boxSizing: "border-box",
				display: "flex",
				flexDirection: "column",
				gap: 3,
				padding: "32px 16px",
				[theme.breakpoints.between("sm", "md")]: {
					padding: "32px 32px",
				},
				[theme.breakpoints.up("md")]: {
					padding: "32px 40px 48px",
				},
				boxShadow: "0px 10px 25px rgba(0, 0, 0, 0.05)",
			})}
		>
			<Box
				sx={{
					display: "flex",
					alignItems: "center",
					justifyContent: "space-between",
					flex: 1,
				}}
			>
				{isLoading ? (
					<Skeleton
						variant="rounded"
						animation="wave"
						sx={{
							height: "40px",
							width: 1,
						}}
					/>
				) : (
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							gap: "12px",
						}}
					>
						<SvgComponent svgUrl={USERS_THREE_ICON} strokeColor={"#0A141B"} style={{ height: "35px", width: "35px" }} />
						<Typography
							variant="customValue"
							component={"div"}
							sx={{
								color: "black",
								fontFamily: "PoppinsSemiBold",
								fontSize: "26px",
								lineHeight: "39px",
							}}
						>
							Experience[{profileData?.experienceDetails ? profileData?.experienceDetails?.length : 0}]
						</Typography>
					</Box>
				)}
			</Box>
			{profileData?.experienceDetails && profileData?.experienceDetails?.length > 0 ? (
				<Box
					sx={{
						display: "grid",
						gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
						gridAutoRows: "1fr",
						gridGap: "36px",
						alignItems: "stretch",
					}}
				>
					{isLoading ? (
						<Fragment>
							<Skeleton variant="rounded" animation="wave" sx={{ height: "200px", width: 1 }} />
							<Skeleton variant="rounded" animation="wave" sx={{ height: "200px", width: 1 }} />
							<Skeleton variant="rounded" animation="wave" sx={{ height: "200px", width: 1 }} />
							<Skeleton variant="rounded" animation="wave" sx={{ height: "200px", width: 1 }} />
						</Fragment>
					) : (
						profileData?.experienceDetails?.map((item, index) => <PublicExperienceItem item={item} key={index} />)
					)}
				</Box>
			) : (
				"No Data"
			)}
		</Box>
	);
}

export default PublicExperienceCard;
