import * as actionTypes from "../../actions/actionTypes";
import { updateObject } from "../../../utils/updateObject";

const initialState = {
	utmSource: null,
	utmCampaign: null,
	utmMedium: null,
	partner: null,
};

const utmParams = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SET_UTM_PARAMS: {
			return action.payload === null ? initialState : updateObject(state, action.payload);
		}

		default:
			return state;
	}
};

export default utmParams;
