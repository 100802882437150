import React from "react";
import { Box, Typography, Tabs, Tab } from "@mui/material";

export default function SurveyMenu({ currentMenu, handleMenu }) {
  const menuButtonClick = (e, newValue) => {
    handleMenu(newValue);
  };

  return (
    <Box sx={{ borderBottom: 2, borderColor: "divider", mb: 4 }}>
      <Tabs
        value={currentMenu}
        onChange={menuButtonClick}
        aria-label="Survey Menu"
      >
        <Tab
          sx={{ textTransform: "none", px: 0, mx: 2, minWidth: "43px" }}
          label={<Typography variant="body_large">About</Typography>}
          value={"about"}
        />
        <Tab
          sx={{ textTransform: "none", px: 0, mx: 2 }}
          label={
            <Typography variant="body_large">Manage Participants</Typography>
          }
          value={"participants"}
        />
        <Tab
          disableRipple
          sx={{ textTransform: "none", px: 0, mx: 2 }}
          label={<Typography variant="body_large">Analyze Results</Typography>}
          value={"results"}
        />
      </Tabs>
    </Box>
  );
}
