import styled from "@emotion/styled";

export const WorkspacePopoverBtn = styled.div(({ theme }) => {
	return {
		cursor: "pointer",
		width: "100%",
	};
});

export const UserPopoverBtn = styled.div(({ theme }) => {
	return {
		padding: theme.spacing(1),
		cursor: "pointer",
	};
});

export const UserSection = styled.div(({ theme }) => {
	return {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		objectFit: "cover",
		border: "1px solid transparent",
		cursor: "pointer",
	};
});
