import { combineReducers } from "redux";
import onboarding from "./onboarding";
import auth from "./auth";
import powerUp from "./powerUp";
import mentor from "./mentor";
import startup from "./startup";
import meetings from "./meetings";
import tokenReducer from "../../containers/Messaging/store/reducers/tokenReducer.js";
import convoReducer from "../../containers/Messaging/store/reducers/convoReducer.js";
import sidReducer from "../../containers/Messaging/store/reducers/currentConvoReducer.js";
import lastReadIndexReducer from "../../containers/Messaging/store/reducers/lastReadIndexReducer.js";
import messageReducer from "../../containers/Messaging/store/reducers/messageListReducer.js";

import loadingReducer from "../../containers/Messaging/store/reducers/loadingReducer.js";

import chatStateReducer from "../../containers/Messaging/store/reducers/chatStateReducer.js";
import participantReducer from "../../containers/Messaging/store/reducers/participantsReducer.js";
import unreadMessagesReducer from "../../containers/Messaging/store/reducers/unreadMessagesReducer.js";
import attachmentsReducer from "../../containers/Messaging/store/reducers/attachmentsReducer.js";
import typingDataReducer from "../../containers/Messaging/store/reducers/typingDataReducer.js";
// Comment out the Twilio Notifications component. Will need refactored to work with global notification system.
//import notificationsReducer from "../../containers/Messaging/store/reducers/notificationsReducer.js";
import { ActionType } from "../../containers/Messaging/store/action-types";
import notifications from "./notifications";
import applicationProps from "./applicationProps";
import investorDashboard from "./investorDashboard";
import mixpanel from "./mixpanel";
import founderDashboard from "./founderDashboard";
import startupProfile from "./startupProfile";
import feedPost from "./feed";
import biDashboard from "./biDashboard";
import startupPublicProfileData from "./publicProfile/StartupPublicProfile";
import accountPublicProfileData from "./publicProfile/AccountPublicProfile";
import userProfileData from "./userProfile";
import networkingReducer from "./networking";
import commonDialogConfirmation from "./commonDialogConfirmation";
import slidingProfileReducer from "./SlidingProfile";
import event from "./event";
import utmParams from "./utmParams/index.js";
import slackWorkspace from "./slackWorkspace/index.js";
import emailToken from "./emailToken/index.js";
import communityPartners from "./communityPartners/index.js";

export const initialState = {
	token: "",
	sid: "",
	messages: {},
	attachments: {},
	participants: {},
	convos: [],
	unreadMessages: {},
	loadingStatus: true,
	chatState: {
		createConversationStatus: false,
		newConversationWith: [],
	},
	typingData: {},
	lastReadIndex: -1,
	notifications: {
		hasNewUnread: false,
		connectedStatus: false,
		connectionLoading: false,
		websocketClient: null,
		lastNotification: null,
		notificationList: null,
		notificationLoading: false,
	},
	investorDashboard: {},
	applicationProps: {
		sidebarWidth: 0,
		sidebarOpenPref: true,
	},
	founderDashboard: {},
	biDashboard: {},
	commonDialogConfirmation: {},
	//notifications: [], // Comment out the Twilio Notifications component. Will need refactored to work with global notification system.
	networking: {},
	slidingProfile: {},
	event: {},
	slackWorkspace: {},
	emailToken: {},
	communityPartners: {},
};

const rootReducer = combineReducers({
	onboarding,
	auth,
	powerUp,
	mentor,
	startup,
	meetings,
	token: tokenReducer,
	convos: convoReducer,
	sid: sidReducer,
	lastReadIndex: lastReadIndexReducer,
	messages: messageReducer,
	loadingStatus: loadingReducer,
	chatState: chatStateReducer,
	participants: participantReducer,
	unreadMessages: unreadMessagesReducer,
	attachments: attachmentsReducer,
	typingData: typingDataReducer,
	notifications: notifications,
	investorDashboard,
	applicationProps,
	mixpanel,
	founderDashboard,
	startupProfile,
	feedPost,
	biDashboard,
	event,
	startupPublicProfileData,
	accountPublicProfileData,
	userProfileData,
	commonDialogConfirmation,
	//notifications: notificationsReducer, // Comment out the Twilio Notifications component. Will need refactored to work with global notification system.
	networking: networkingReducer,
	slidingProfile: slidingProfileReducer,
	utmParams: utmParams,
	slackWorkspace,
	emailToken,
	communityPartners,
});

//eslint-disable-next-line
export default rootReducer;
