import styled from "@emotion/styled";

export const EyeIcon = styled.div(({ theme }) => {
  return {
    position: "relative",
    float: "right",
    marginTop: "-37px",
    paddingRight: "15px",
    cursor: "pointer",
  };
});
