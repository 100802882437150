import React from "react";
import { Box, Grid, Typography, Divider, Button } from "@mui/material";
import PlayCircleRoundedIcon from "@mui/icons-material/PlayCircleRounded";
import VideocamRoundedIcon from "@mui/icons-material/VideocamRounded";

const AddVideo = ({ height, handelAddMedia, ...props }) => {
  return (
    <Box
      sx={theme => ({
        width: 1,
        minHeight: `${height}px`,
        background:
          "linear-gradient(0deg, #DADCE0, #DADCE0), linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), #DADCE0;",
        border: "1px solid transparent",
        borderRadius: 2,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        mt: 3,
      })}
    >
      <Box>
        <PlayCircleRoundedIcon
          sx={{
            height: 100,
            width: 100,
            color: "rgba(70, 75, 78, 0.6);",
          }}
        />
      </Box>
      <Box
        sx={{
          mr: 3,
          background:
            "linear-gradient(0deg, rgba(106, 117, 117, 0.02), rgba(106, 117, 117, 0.02)), linear-gradient(0deg, rgba(245, 248, 250, 0.05), rgba(245, 248, 250, 0.05)), #FCFCFC",
          boxShadow:
            "0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
          borderRadius: "4px",
          width: "max-content",
          position: "absolute",
          top: "24px",
          right: 0,
        }}
      >
        <Button
          variant="outlined"
          startIcon={<VideocamRoundedIcon />}
          onClick={handelAddMedia}
        >
          Add Media
        </Button>
      </Box>
    </Box>
  );
};

export default AddVideo;
