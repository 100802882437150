import React, { useContext } from "react";
import { Box, Typography } from "@mui/material";
import {
	CALENDAR_BLANK_BLACK,
	VIDEO_CAMERA_SIMPLE_ICON,
	GLOBE_HEMISPHERE_EAST_ICON,
	CLOCK_LIGHT_ICON,
	HOURGLASS_LIGHT_ICON,
	ENVELOPE_LIGHT_ICON,
} from "../../../constants";
import { ScheduleMeetingContext } from ".";
import moment from "moment";
import { USER_TYPES } from "../../../utils/userTypes";
import { convertTimeToTargetTimezone } from "../../../utils/commonFunctions";

function RequestDetail({ withUserType }) {
	const getUserType = () => {
		switch (withUserType) {
			case USER_TYPES.MENTOR_USER:
				return "Mentor";
			case USER_TYPES.STARTUP_OWNER:
				return "Founder";
			case USER_TYPES.INVESTOR_USER:
				return "Investor";
			default:
				return "";
		}
	};
	const { userPayload, confirmedBooking, selectedMeetingLength } = useContext(ScheduleMeetingContext);
	const descriptions = [
		{ text: `Awaiting approval from ${userPayload?.name}`, icon: HOURGLASS_LIGHT_ICON }, // userPayload?.name
		{ text: `${selectedMeetingLength?.duration} Mins`, icon: CLOCK_LIGHT_ICON }, //selectedMeetingLength?.duration Mins
		{
			text: `${moment(confirmedBooking?.data?.startTime?.split("T")?.[1], "HH:mm:ss").format("LT")} , ${moment(
				confirmedBooking?.data?.startTime?.split("T")?.[0]
			).format("dddd, MMMM D")} `,
			icon: CALENDAR_BLANK_BLACK,
		}, // moment(confirmedBooking?.startTime).format('dddd, MMMM D, h:mm A');
		{ text: `${confirmedBooking?.data?.timezone || "No Data"}`, icon: GLOBE_HEMISPHERE_EAST_ICON }, //confirmedBooking?.attendees?.[0]?.timeZone?.replace(/_/g, " ")
		{
			text: (
				<a href={confirmedBooking?.data?.meetingUrl} target="_blank">
					{confirmedBooking?.data?.meetingUrl}
				</a>
			),
			icon: VIDEO_CAMERA_SIMPLE_ICON,
		},
		{ text: "A calendar invitation has been sent to your email address", icon: ENVELOPE_LIGHT_ICON },
	];
	return (
		<Box display="flex" rowGap={2} flexDirection="column">
			<Typography variant="Text/md/Semibold">
				{getUserType()} {selectedMeetingLength?.duration} Mins. meeting request sent
			</Typography>
			{descriptions.map((item) => (
				<Box display="flex" columnGap={2} alignItems="center">
					<Box component={"img"} src={item?.icon} sx={{ height: "30px", width: "30px" }} />
					<Typography variant="Text/sm/Regular">{item?.text}</Typography>
				</Box>
			))}
		</Box>
	);
}

export default RequestDetail;
