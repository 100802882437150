import * as actionTypes from "../../actions/actionTypes";
import { updateObject } from "../../../utils/updateObject";

const initialState = {
	isOpen: false,
};

const commonDialogConfirmation = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SET_COMMON_DIALOG_CONFIRMATION_DATA: {
			return action.payload === null ? initialState : updateObject(state, action.payload);
		}
		default:
			return state;
	}
};
export default commonDialogConfirmation;
