import { InfoRounded } from "@mui/icons-material";
import { Box, Popover, Typography } from "@mui/material";
import React, { Fragment } from "react";
import JsxParser from "react-jsx-parser";

function InfoTip({ title, bodyText }) {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		event.stopPropagation();
		setAnchorEl(event.currentTarget);
	};
	const handleClose = (event) => {
		event.stopPropagation();
		setAnchorEl(null);
	};

	return (
		<Fragment>
			<InfoRounded
				sx={{
					color: (theme) => theme?.palette?.tertiary?.main,
					height: "20px",
					width: "20px",
					cursor: "pointer",
					transition: "all .2s",
					"&:hover": {
						scale: "1.1",
					},
				}}
				onClick={handleClick}
			/>
			<Popover
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "center",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "left",
				}}
				PaperProps={{
					sx: {
						p: "30px",
						borderRadius: "30px",
						background: "white",
						boxShadow: "0px 10px 85px rgba(0, 0, 0, 0.1)",
					},
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						maxWidth: "300px",
						gap: 1,
						maxHeight: "350px",
						overflowY: "auto",
						pr: 1,
					}}
					className="SOSShowVerticalScroll"
				>
					<Typography component={"p"} variant="Text/sm/Semibold">
						{title}
					</Typography>
					{bodyText}
				</Box>
			</Popover>
		</Fragment>
	);
}

export default InfoTip;
