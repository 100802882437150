import { TableCell, TableHead, TableRow, tableCellClasses } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledTableCell = styled(TableCell)(() => ({
	[`&.${tableCellClasses.body}`]: {
		backgroundColor: "white",
	},
	[`&.${tableCellClasses.head}`]: {
		borderBottom: "1px solid rgba(208, 213, 221, 1)",
		borderTop: "1px solid rgba(208, 213, 221, 1)",
	},
	backgroundColor: "rgba(242, 244, 247, 1)",

	"&:last-child": {
		borderRadius: "0px 16px 16px 0px",
	},
}));

export const StyledTableHead = styled(TableHead)(() => ({
	backgroundColor: "rgba(242, 244, 247, 1)",
	// position: "sticky",
	// top: 0,
	// zIndex: 1,
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
	"&:last-child": {
		borderBottom: "2px solid #E0E0E0",
	},
	borderTop: "2px solid #E0E0E0",
}));
