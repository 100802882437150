import React, { useContext } from "react";
import { PowerUPContext } from "../../PowerUPController";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, Typography } from "@mui/material";
import VersionHistory from "../cards/VersionHistory";
import { Add } from "@mui/icons-material";

const ResetConfirmModal = () => {
	const { startNew, resetConfirmModalOpen, setResetConfirmModalOpen } = useContext(PowerUPContext);
	const [loading, setLoading] = React.useState(false);

	const loadingCallback = (value) => {
		setLoading(value);
	};

	return (
		<Dialog
			maxWidth="sm"
			fullWidth
			open={resetConfirmModalOpen}
			onClose={() => setResetConfirmModalOpen(false)}
			PaperProps={{ sx: { borderRadius: "24px" } }}
		>
			<Box sx={{ px: "2rem", pt: "2rem", display: "flex", justifyContent: "flex-end" }}>
				<Typography variant="Text/md/Semibold">Reset PowerUP?</Typography>
				<CloseIcon onClick={() => setResetConfirmModalOpen(false)} sx={{ ml: "auto", cursor: "pointer" }} />
			</Box>
			<DialogContent
				sx={{
					border: "none",
					py: 1,
					px: "2rem",
				}}
			>
				<Typography variant="Text/sm/Regular">
					Resetting your answers will start a new version of this PowerUP. Would you like to start fresh?
				</Typography>
			</DialogContent>
			<DialogActions sx={{ padding: "2rem" }}>
				<Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", gap: 1 }}>
					<Button disabled={loading} variant="DS1" color="gray" small onClick={() => setResetConfirmModalOpen(false)}>
						Cancel
					</Button>
					<Button
						disabled={loading}
						variant="DS1"
						color="secondary"
						small
						startIcon={loading && <CircularProgress size={"1.25rem"} sx={{ color: "white" }} />}
						onClick={() => startNew(loadingCallback)}
					>
						Yes, Start New Version
					</Button>
				</Box>
			</DialogActions>
		</Dialog>
	);
};
export default ResetConfirmModal;
