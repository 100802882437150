import React, { useContext, useEffect } from "react";
import { Avatar, Typography, Box, Chip } from "@mui/material";
import { CLOCK_LIGHT_ICON, ENERGY_CIRCLE_YELLOW_ICON } from "../../../constants";
import { ScheduleMeetingContext } from ".";
import { USER_TYPES } from "../../../utils/userTypes";
import { convertUTCtoLocal, formatTimeRange } from "../../../containers/Meetings/Bookings/BookedMeetings";
import moment from "moment";

function UserInfo({ withUserType = "" }) {
	const { userPayload, selectedMeetingLength, currentStep } = useContext(ScheduleMeetingContext);

	function findApprovedUserTypes() {
		const approvedUserTypes = [];

		approvedUserTypes.push({ type: userPayload?.userTypes?.[0], isVerified: userPayload?.isVerified });

		return approvedUserTypes;
	}

	const getTypeName = () => {
		if (withUserType === USER_TYPES.MENTOR_USER) {
			return "Mentor";
		}
		if (withUserType === USER_TYPES.INVESTOR_USER) {
			return "Investor";
		}
		if (withUserType === USER_TYPES.STARTUP_OWNER) {
			return "Founder";
		}
	};

	function getVerificationBadges() {
		const approvedTypes = findApprovedUserTypes();
		const appbadges = [];
		if (approvedTypes) {
			approvedTypes?.forEach((type, index) => {
				switch (type) {
					case "MENTOR_USER":
						appbadges.push(
							<Box key={`${index}_${type}`} display="flex" alignItems="center" gap={1} sx={{ minHeight: "30px", height: "30px" }}>
								<Box component="img" src={ENERGY_CIRCLE_YELLOW_ICON} sx={{ width: "30px", height: "30px" }} />
								<Typography variant="Text/sm/Regular" sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
									Verified Mentor
								</Typography>
							</Box>
						);
						break;
					case "STARTUP_OWNER":
						appbadges.push(
							<Box key={`${index}_${type}`} display="flex" alignItems="center" gap={1} sx={{ minHeight: "30px", height: "30px" }}>
								<Box component="img" src={ENERGY_CIRCLE_YELLOW_ICON} sx={{ width: "30px", height: "30px" }} />
								<Typography variant="Text/sm/Regular" sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
									Verified Founder
								</Typography>
							</Box>
						);
						break;
					default:
						console.log("No matching user type.");
				}
			});
		}
		return appbadges;
	}

	return (
		<Box p={3} display="flex" flexDirection="column">
			<Avatar src={userPayload?.picture} alt="profile-pic" sx={{ width: "200px", height: "200px", alignSelf: "center" }} />
			<Box mt={3} display="flex" flexDirection="column" rowGap={2}>
				<Box display="flex" alignItems="center" gap={1} sx={{ minHeight: "30px", height: "30px" }}>
					<Typography variant="Text/md/Semibold" sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
						{userPayload?.name}
					</Typography>
				</Box>
				<Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>{getVerificationBadges()}</Box>
				{userPayload?.about && (
					<Box display="flex" sx={{ minHeight: "48px", height: "48px" }}>
						<Typography
							variant="Text/sm/Regular"
							sx={{
								overflow: "hidden",
								whiteSpace: "pre-line",
								textOverflow: "ellipsis",
								WebkitLineClamp: "2",
								MozLineClamp: "2",
								display: ["-webkit-box", "-moz-box", "box"],
								WebkitBoxOrient: "vertical",
								MozBoxOrient: "vertical",
								boxOrient: "vertical",
							}}
						>
							{userPayload?.about?.replace(/(<([^>]+)>)/gi, "")?.replace(/&amp;/g, "&")}
						</Typography>
					</Box>
				)}
				{userPayload?.expertise && (
					<Box display="flex" alignItems="center" gap={1} flexWrap="wrap">
						{(() => {
							const value = userPayload?.expertise;
							const regex = /^\[.*\]$/; // Regex to check if the string is in array format
							const regexCommaSeparated = /^[^,\[\]]+(,[^,\[\]]+)*$/; // Regex to check if the string is comma-separated

							if (regex.test(value) || regexCommaSeparated.test(value)) {
								try {
									let parsedValue = null;
									if (regex.test(value)) {
										parsedValue = JSON.parse(value);
									} else {
										parsedValue = value.split(",").map((item) => item.trim());
									}

									if (Array.isArray(parsedValue)) {
										return parsedValue?.map((item, index) => (
											<Chip
												key={index}
												variant="outlined"
												sx={{
													border: "1px solid",
													borderColor: (theme) => theme.palette.bidashboard.grey,
												}}
												label={
													<Typography
														variant="Text/xs/Regular"
														color="sos.gray700"
														sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
													>
														{item}
													</Typography>
												}
											/>
										));
									}
								} catch {
									console.error("Invalid JSON format");
								}
							} else if (value)
								return (
									<Chip
										variant="outlined"
										sx={{
											border: "1px solid",
											borderColor: (theme) => theme.palette.bidashboard.grey,
										}}
										label={
											<Typography
												variant="Text/xs/Regular"
												color="sos.gray700"
												sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
											>
												{value}
											</Typography>
										}
									/>
								);
						})()}
					</Box>
				)}
				{selectedMeetingLength && !selectedMeetingLength?.rescheduling && currentStep !== 0 && currentStep !== 3 && (
					<Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
						<Typography variant="Text/md/Semibold">
							{/* {getTypeName()} {selectedMeetingLength?.duration} */}
							{selectedMeetingLength?.title}
						</Typography>
						<Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
							<Box component={"img"} src={CLOCK_LIGHT_ICON} sx={{ height: "30px", width: "30px" }} />
							<Typography variant="Text/sm/Regular">{selectedMeetingLength?.duration} Mins</Typography>
						</Box>
					</Box>
				)}
				{selectedMeetingLength && selectedMeetingLength?.rescheduling && (
					<Box sx={{ display: "flex", flexDirection: "column" }}>
						<Typography variant="Text/md/Semibold" sx={{ mb: 1 }}>
							Rescheduling Meeting
						</Typography>
						<Box sx={{ display: "flex", gap: 1, alignItems: "center", mb: 1 }}>
							<Box component={"img"} src={CLOCK_LIGHT_ICON} sx={{ height: "30px", width: "30px" }} />
							<Typography variant="Text/sm/Regular">{selectedMeetingLength?.eventType?.meetingLength} Mins</Typography>
						</Box>
						<Typography variant="Text/sm/Regular">{moment(selectedMeetingLength?.startTime?.split("T")[0]).format("LL")}</Typography>
						<Typography variant="Text/sm/Regular">{`${moment(selectedMeetingLength?.startTime?.split("T")[1], "HH:mm:ss").format("LT")} - ${moment(
							selectedMeetingLength?.endTime?.split("T")[1],
							"HH:mm:ss"
						).format("LT")}`}</Typography>
					</Box>
				)}
			</Box>
		</Box>
	);
}

export default UserInfo;
