import React, { useEffect, useState } from "react";
import { Provider } from "react-redux";
import { store } from "../../Messaging/store";
import { Theme } from "@twilio-paste/core/theme";
import App from "./components/App";
import styles from "./styles";
import { Alert, AlertTitle, Box, useMediaQuery, Typography } from "@mui/material";
import constants, { CHAT_MAIN_BLACK, PIPEDRIVEIMG } from "../../../constants";
import AdBanner from "../../../components/AdBanner";
import { CircularProgress } from "@mui/material";
import services from "../../../services";

export default function Messaging() {
	const IntercomSafeArea = true;
	const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
	const [loading, setLoading] = useState(true);
	const [advertsDetails, setAdvertsDetails] = useState();

	const getAdvertsById = (id) => {
		services
			.getAdvertsById(id)
			.then((res) => {
				setLoading(false);
				const resData = res.data.data;
				if (res.data.code === 200) {
					setAdvertsDetails(resData[0]);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	useEffect(() => {
		getAdvertsById(5);
	}, []);

	return (
		<Box
			sx={(theme) => ({
				display: "flex",
				flexDirection: "column",
				gap: 2,
				maxWidth: theme.breakpoints.values.md,
				gap: "32px",
				// maxWidth: "1920px", // Figma specifies max content width
				margin: "0 auto",
				flex: 1,
			})}
		>
			{/* <Box
				sx={{
					display: "flex",
					alignItems: "center",
					gap: "12px",
				}}
			>
				<Box component={"img"} src={CHAT_MAIN_BLACK} alt="dashbaord" sx={{ width: "30px", height: "30px" }} />
				<Typography variant="growth_card_subtitle" color="secondary.black" >Messages</Typography>
			</Box> */}

			{/* {loading ? (
				<Box
					sx={{
						display: "flex",
						width: 1,
						justifyContent: "center",
						py: 4,
					}}
				>
					<CircularProgress />
				</Box>
			) : (
				<Box sx={{ mt: 2 }}>
					<AdBanner
						backgroundSize={isSmallScreen ? "contain" : "cover"}
						image={advertsDetails?.adUrl}
						smallImage={"https://1000logos.net/wp-content/uploads/2020/08/Pipedrive-Symbol.jpg"}
						title={advertsDetails?.adName}
						subtitle={advertsDetails?.adCopy}
						buttonText={"Special Offer"}
						action={{
							text: "Special Offer",
							props: {
								href: advertsDetails?.serviceUrl,
								target: "_blank",
							},
						}}
					/>
				</Box>
			)} */}
			{/* Helper message box */}
			{/*<Alert severity="info">
        <AlertTitle>
          <strong>Power Tip</strong>
        </AlertTitle>
        To start a conversation: find one of your{" "}
        <a href="/network" style={{ textDecoration: "none" }}>
          network connections.
        </a>
      </Alert>*/}
			<Box
				sx={{
					flex: 1,
					//height: "calc(100% - 15px)",
					//width: "100%",
					boxSizing: "border-box",
					position: "relative",
					"&": () => {
						if (IntercomSafeArea && !isSmallScreen) {
							return {
								width: "calc(100%)",
							};
						}
					},
				}}
			>
				<Theme.Provider theme="default">
					<Box style={styles.app}>
						<App />
					</Box>
				</Theme.Provider>
			</Box>
		</Box>
	);
}
