import TodayIcon from "@mui/icons-material/Today";
import {
  Box,
  Button,
  Grid,
  Stack,
  Typography,
  Icon,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { PEOPLE_PURPLE, PEOPLE_DARK_BLUE } from "../../../constants/image";
import ROUTES_PATH from "../../../constants/routes";
import theme from "../../../constants/Theme";
import services from "../../../services";
import BrowseStartups from "./Components/BrowseStartups";
import EventsContainer from "../../../common/EventsContainer";
import { EventCard } from "../../../common/EventsContainer/styles";
import { WelcomeMessageBoard } from "./Components/WelcomeMessageDashboard";
import { MentorMessageCard } from "./Components/WelcomeMessageDashboard/styles";
import { useSelector } from "react-redux";
import MyBookingCard from "../../../components/Meetings/MyBookingCard";
import BookingCard from "./Components/BookingCard";
import ConnectedStartups from "./Components/ConnectedStartups";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { handleMixPanel } from "../../../utils/mixPanelEventHandling";

export default function MentorDashboard() {
  const [startups, setStartups] = useState([]);
  const history = useHistory();
  const [bookings, setBookings] = useState([]);
  const userData = useSelector(state => state?.auth);

  const [totalPages, settotalPages] = useState(0);
  const [totalElements, settotalElements] = useState(0);
  const [currentPage, setcurrentPage] = useState(1);
  const [currentCount, setcurrentCount] = useState(0);
  const [isFirstPage, setisFirstPage] = useState(false);
  const [isLastPage, setisLastPage] = useState(false);
  const [itemList, setitemList] = useState(null);
  const pageSize = 3;

  const [nextPageButton, setNextPageButton] = useState({ active: false });
  const [previousPageButton, setPreviousPageButton] = useState({
    active: false,
  });

  useEffect(() => {
    if (currentPage === 1) {
      setPreviousPageButton({ active: false });
      setNextPageButton({ active: true });
      if (currentPage === totalPages) {
        setNextPageButton({ active: false });
        setPreviousPageButton({ active: false });
      }
    } else if (isLastPage) {
      setNextPageButton({ active: false });
      setPreviousPageButton({ active: true });
    } else {
      setNextPageButton({ active: true });
      setPreviousPageButton({ active: true });
    }
  }, [currentPage, totalPages]);

  const getMentorMatches = () => {
    services
      .getMentorMatches(1)
      .then(res => {
        if (res.data) {
          const resData = res.data?.data;
          setStartups(resData?.models || []);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  const getAllBookings = () => {
    if (userData.calUserId) {
      services
        .calGetAllBookings(userData.calUserId)
        .then(res => {
          if (res?.data?.bookings) {
            const bookingsArr = res?.data.bookings.filter(
              x => x.status != "CANCELLED"
            );
            setBookings(bookingsArr);
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  };

  const getConnections = page => {
    services
      .getStartupConnections({ perPage: pageSize, page: page })
      .then(resp => {
        if (resp.data?.data?.models) {
          setitemList(resp.data.data.models || []);
          setisLastPage(resp.data.data.isLast);
          setisFirstPage(resp.data.data.isFirst);
          settotalElements(resp.data.data.totalElements);
          settotalPages(resp.data.data.totalPages);
          if (resp.data.data.totalPages === 0) {
            setcurrentPage(0);
          } else {
            setcurrentPage(page);
          }
        }
      })
      .catch(e => {
        console.log("Could not retrieve list of startup connections.", e);
      });
  };
  const getFirstPage = () => {
    if (!isFirstPage) {
      getConnections(1);
    }
  };
  const getLastPage = () => {
    if (!isLastPage) {
      getConnections(totalPages);
    }
  };
  const getNextPage = () => {
    if (!isLastPage) {
      getConnections(currentPage + 1);
    }
  };
  const getPrevPage = () => {
    if (!isFirstPage) {
      getConnections(currentPage - 1);
    }
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    handleMixPanel("Home Viewed", { "Page": "Mentor Dashboard" });
    getMentorMatches();
    getAllBookings();
    getConnections(1);
  }, []);

  return (
    <Stack sx={theme => ({})}>
      <Box
        sx={theme => ({
          maxWidth: theme.breakpoints.values.xl,
          m: "auto",
          width: 1,
        })}
      >
        <MentorMessageCard
          sx={{
            mx: 3,
            backgroundColor: theme.palette.primary.homeBlueBackground,
          }}
        >
          <WelcomeMessageBoard />
        </MentorMessageCard>
        <EventCard sx={{ mx: 3, my: 3 }}>
          <Box>
            <Typography variant="title_medium">Upcoming Events</Typography>
          </Box>
          {bookings.length === 0 && (
            <Box sx={{ py: 3 }}>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Icon
                  sx={{
                    borderRadius: "50%",
                    p: 2,
                    color: "primary.main",
                  }}
                >
                  <TodayIcon />
                </Icon>
              </Box>

              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Typography
                  variant="lable_large"
                  align="center"
                  sx={{
                    display: "block",
                    pt: 2,
                    color: theme => theme.palette.text.onSurfaceVariant,
                  }}
                >
                  No upcoming events found
                </Typography>
              </Box>
            </Box>
          )}
          <Box>
            <Grid container spacing={3} sx={{ mt: 1 }}>
              {bookings.map(data => (
                <Grid item sm={12}>
                  <BookingCard data={data} />
                </Grid>
              ))}
            </Grid>
          </Box>
        </EventCard>

        <EventCard sx={{ mx: 3 }}>
          <Typography variant="title_large">Mentor Overview</Typography>
          <Grid container spacing={3} sx={{ mt: 3 }}>
            <Grid item lg={6} md={12} sm={12} xs={12}>
              {" "}
              {!itemList && (
                <EventsContainer
                  heading={"Your Startups"}
                  title={"No startups yet"}
                  customIcon={
                    <img src={PEOPLE_DARK_BLUE} height={30} width={30} />
                  }
                  titleVariant={"title_medium"}
                  variantType={"bordered"}
                  height="497px"
                />
              )}
              {itemList && (
                <EventCard
                  variant="bordered"
                  sx={{ minHeight: "497px", position: "relative" }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      mb: 3,
                    }}
                  >
                    <Typography variant="title_medium">
                      Your Startups
                    </Typography>
                  </Box>
                  <Box>
                    <ConnectedStartups
                      itemList={itemList}
                      currentPage={currentPage}
                      totalPages={totalPages}
                      getLastPage={getLastPage}
                      getNextPage={getNextPage}
                      getPrevPage={getPrevPage}
                      getFirstPage={getFirstPage}
                      pageSize={pageSize}
                      totalElements={totalElements}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      mt: "auto",
                      position: "absolute",
                      right: "20px",
                      bottom: "20px",
                    }}
                  >
                    <IconButton
                      disabled={!previousPageButton.active}
                      onClick={getPrevPage}
                    >
                      <ArrowBackIosIcon />
                    </IconButton>
                    <IconButton
                      disabled={!nextPageButton.active}
                      onClick={getNextPage}
                    >
                      <ArrowForwardIosIcon />
                    </IconButton>
                  </Box>
                </EventCard>
              )}
            </Grid>
            <Grid item lg={6} md={12} sm={12} xs={12}>
              {" "}
              <EventCard variant="bordered" sx={{ minHeight: "497px" }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: 3,
                  }}
                >
                  <Typography variant="title_medium">
                    Browse Startups
                  </Typography>
                  <Box>
                    <Button
                      variant="nofill"
                      onClick={() => {
                        if (userData.userType === "PROFESSOR_USER") {
                          history.push(ROUTES_PATH.INSTRUCTOR_NETWORK);
                        } else if (userData.userType === "MENTOR_USER") {
                          history.push(ROUTES_PATH.MENTOR_NETWORK);
                        }
                      }}
                    >
                      View all
                    </Button>
                  </Box>
                </Box>
                <Box>
                  <Typography variant="subhead2">
                    Connect with startups that interest you to set up an initial
                    meeting.
                  </Typography>
                </Box>

                <Box>
                  <BrowseStartups
                    startups={startups}
                    setStartups={setStartups}
                  />
                </Box>
              </EventCard>
            </Grid>
          </Grid>
        </EventCard>
      </Box>
    </Stack>
  );
}
