import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { AddOutlined } from "@mui/icons-material";
import { PINK_CHECK_ICON } from "../../../constants";

export default function UpdatedProfileWatchlistSection({ status, iconSrc, name, handleOnClickSection }) {
	return (
		<Button
			onClick={handleOnClickSection}
			variant="containedGrayStyle"
			sx={{ backgroundColor: "rgba(242, 244, 247, 1)" }}
			startIcon={
				status ? (
					<Box component={"img"} src={PINK_CHECK_ICON} sx={{ width: "24px", height: "24px" }} />
				) : (
					<AddOutlined sx={{ fontSize: "23px", color: (theme) => theme.palette.secondary.black }} className="add-icon-hover" />
				)
			}
		>
			{name}
		</Button>
	);
}
