import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Box, Grid } from "@mui/material";
import TreeItem from "@mui/lab/TreeItem";
import TreeView from "@mui/lab/TreeView";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";

import { getListOfCountryStateApiCall } from "../../../../../containers/Investor/Dashboard/Functions/common";
import { setInvestorDashboardData } from "../../../../../modules/actions";
import UpdatedBasicCheckBoxTree from "../../../../../common/UpdatedCheckBoxTree";
import FilterDropDownComponent from "../Common/FilterDropDown";
import CheckBoxComponent from "../Common/CheckBoxComp";
import FoundedYearComponent from "./YearFounded";
import CohortFilter from "./CohortFilter";
import ScoreRangeFilter from "./ScoreRangeFilter";
import { TEMP_COUNTRIES, USA_REGION_DATA } from "../../../../../constants";

const FilterCompanySection = ({ filterData, lable, handleUncheck }) => {
	const [selectedIndustries, setSelectedIndustries] = useState([]);
	const dispatch = useDispatch();
	const locationData = useSelector((state) => state?.investorDashboard?.locationList);
	const companyData = filterData[lable];

	useEffect(() => {
		getListOfCountryStateApiCall()
			.then((res) => {
				if (res.data.code == 200) {
					let resposeData = res.data?.data || [];
					const newKey = "countryName";
					let tempCountryStates = [];
					const filteredLocations = resposeData.map((array) => {
						tempCountryStates.push({ value: false, type: array.countryName });
						return {
							...array,
							["states"]: array?.states.map((obj) => {
								return { ...obj, [newKey]: obj.stateName };
							}),
						};
					});
					filteredLocations.splice(1, 0, USA_REGION_DATA);
					let newRegions = [{ type: "USA Regions", value: false }, ...TEMP_COUNTRIES];
					const finalFilteredLocations = filteredLocations.concat(TEMP_COUNTRIES);
					const finalCountryStates = tempCountryStates.concat(newRegions);
					dispatch(
						setInvestorDashboardData({
							locationList: finalFilteredLocations,
							partiallySelected: finalCountryStates,
						})
					);
				}
			})
			.catch((err) => console.log(err));
	}, []);

	return (
		<Box
			sx={{
				p: 5,
			}}
		>
			<Grid container>
				<Grid item xs={12} sm={12} md={6}>
					<Box
						sx={{
							display: "flex",
							width: 1,
							alignItems: "center",
						}}
					>
						<Typography sx={{ color: (theme) => theme.palette.secondary.black, width: "fit-content" }} variant="card_name">
							{lable}
						</Typography>
					</Box>
					<TreeView
						defaultCollapseIcon={
							<KeyboardArrowDownOutlinedIcon
								sx={{
									fontSize: "large",
									border: "2px solid",
									borderRadius: "50%",
								}}
							/>
						}
						defaultExpandIcon={
							<KeyboardArrowRightOutlinedIcon
								sx={{
									fontSize: "large",
									border: "2px solid",
									borderRadius: "50%",
								}}
							/>
						}
						sx={{ mt: 2 }}
						defaultExpanded={["Location"]}
					>
						<TreeItem
							nodeId="Location"
							label={
								<Typography sx={{ pt: "4px", display: "inline-block" }} variant="testHeading">
									Location
								</Typography>
							}
							sx={{
								"& .MuiTreeItem-content.Mui-selected": {
									background: (theme) => theme.palette.secondary.white,
									"&:hover": {
										background: (theme) => theme.palette.secondary.white,
									},
									"&:active": {
										background: (theme) => theme.palette.secondary.white,
									},
									"&:focused": {
										background: (theme) => theme.palette.secondary.white,
									},
									"&:selected": {
										background: (theme) => theme.palette.secondary.white,
									},
								},
								"& .MuiTreeItem-content.Mui-selected.Mui-focused": {
									background: (theme) => theme.palette.secondary.white,
									"&:hover": {
										background: (theme) => theme.palette.secondary.white,
									},
									"&:active": {
										background: (theme) => theme.palette.secondary.white,
									},
									"&:focused": {
										background: (theme) => theme.palette.secondary.white,
									},
									"&:selected": {
										background: (theme) => theme.palette.secondary.white,
									},
								},
							}}
						>
							{locationData?.map((treeData) => {
								// const treeDataArray = Object.entries(treeData).map((e) => ({ [e[0]]: e[1] }));
								// const treeDataObj = Object.values(treeDataArray[0])[0];

								return (
									<Grid item lg={6}>
										<UpdatedBasicCheckBoxTree treeData={treeData} lable={"Location"} type="location" />
									</Grid>
								);
							})}
						</TreeItem>
					</TreeView>
					<CheckBoxComponent companyData={companyData} handleUncheck={handleUncheck} lable={"Current Stage"} type="stages" />
					<CheckBoxComponent companyData={companyData} handleUncheck={handleUncheck} lable={"Financing"} type={"financings"} />
					<CheckBoxComponent
						companyData={companyData}
						handleUncheck={handleUncheck}
						lable={"Startups Joined Platform Within"}
						type={"startupsJoined"}
					/>
				</Grid>
				<Grid item xs={12} sm={12} md={6}>
					<ScoreRangeFilter />
					<CohortFilter />
					<FoundedYearComponent companyData={companyData} lable={"Stages"} />
				</Grid>
			</Grid>
		</Box>
	);
};

export default FilterCompanySection;
