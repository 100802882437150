import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getMagicNumberGridData } from "../Functions/common";
import { handleMixPanel } from "../../../../utils/mixPanelEventHandling";
import { setBIDashboardData } from "../../../../modules/actions/biDashboardActions";
import BIDataGridDialog from "../DataGrid/BIDataGridDialog";
import MagicNumberChartContainer from "../Charts/MagicNumberChartContainer";
import MagicNumberGrid from "../DataGrid/MagicNumberGrid";
import { get } from "lodash";

function MagicNumber({ companyId, readOnly }) {
	const dispatch = useDispatch();
	const magicNumber = useSelector((store) => store?.biDashboard?.magicNumber);
	const loadingMagicNumber = useSelector((store) => store?.biDashboard?.loadingMagicNumber);
	const openMagicNumber = useSelector((store) => store?.biDashboard?.openMagicNumber);
	const [isEdited, setEdited] = useState(false);

	const gridData = magicNumber?.data ? magicNumber?.data : [];

	const ownCompanyId = useSelector((state) => state?.auth?.companyId);
	const usedCompanyId = companyId ? companyId : ownCompanyId;

	const fetchData = async () => {
		try {
			dispatch(setBIDashboardData({ loadingMagicNumber: true }));
			const responseData = await getMagicNumberGridData(usedCompanyId);
			if (responseData) {
				const updateMagicNumberDataRes = get(responseData, "data", []);
				dispatch(
					setBIDashboardData({
						magicNumber: {
							latestMagicNumber: updateMagicNumberDataRes?.[updateMagicNumberDataRes?.length - 1]?.magicNumber,
							data: updateMagicNumberDataRes,
						},
					})
				);
			}
			dispatch(setBIDashboardData({ loadingMagicNumber: false }));
		} catch (error) {
			dispatch(setBIDashboardData({ loadingMagicNumber: false }));
			console.log("error", error);
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	const onChartClick = () => {
		handleMixPanel("Grid Magic Number Viewed");
		dispatch(setBIDashboardData({ openMagicNumber: true }));
	};

	const handleClose = useCallback(() => {
		if (isEdited) {
			dispatch(
				setBIDashboardData({
					openMagicNumberLeave: true,
				})
			);
		} else {
			dispatch(setBIDashboardData({ openMagicNumber: false }));
		}
	}, [isEdited]);

	return (
		<>
			<MagicNumberChartContainer data={magicNumber} onChartClick={onChartClick} loading={loadingMagicNumber} readOnly={readOnly} />
			<BIDataGridDialog title="Magic Number" isOpen={openMagicNumber} onClose={handleClose}>
				<MagicNumberGrid gridData={gridData} readOnly={readOnly} usedCompanyId={usedCompanyId} isEdited={isEdited} setEdited={setEdited} />
			</BIDataGridDialog>
		</>
	);
}

export default MagicNumber;
