import * as React from "react";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import parse from "autosuggest-highlight/parse";
import { debounce } from "@mui/material/utils";
import usePlacesAutocomplete, { getDetails } from "use-places-autocomplete";
import UpdatedTextInputField from "../../common/UpdatedTextInputField";
import { styled } from "@mui/material/styles";
import { Paper } from "@mui/material";
import InfoHelper from "../Founder/InfoHelper";

const GOOGLE_MAPS_API_KEY = "AIzaSyCx9m10eJVrqnFNSHJFFBKMS1asCTma5iM";

function loadScript(src, position, id) {
	if (!position) {
		return;
	}

	const script = document.createElement("script");
	script.setAttribute("async", "");
	script.setAttribute("id", id);
	script.src = src;
	position.appendChild(script);
}

const autocompleteService = { current: null };

const StyledPaper = styled(Paper)(({ theme, noOptions }) => ({
	borderRadius: noOptions ? "0 0 0 0" : "0 0 20px 20px",
	boxShadow: noOptions ? "none" : theme.shadows[1],
}));

export default function AddressAutoCompleteField({ onValueSet, parentValue, fieldLabel, fieldPlaceholder, name }) {
	const [value, setValue] = React.useState(null);
	const [inputValue, setInputValue] = React.useState("");
	const [options, setOptions] = React.useState([]);
	const loaded = React.useRef(false);

	if (typeof window !== "undefined" && !loaded.current) {
		if (!document.querySelector("#google-maps")) {
			loadScript(`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`, document.querySelector("head"), "google-maps");
		}

		loaded.current = true;
	}

	const fetch = React.useMemo(
		() =>
			debounce((request, callback) => {
				autocompleteService.current.getPlacePredictions(request, callback);
			}, 400),
		[]
	);

	React.useEffect(() => {
		setValue(parentValue);
	}, [parentValue]);

	React.useEffect(() => {
		let active = true;

		if (!autocompleteService.current && window.google) {
			autocompleteService.current = new window.google.maps.places.AutocompleteService();
		}
		if (!autocompleteService.current) {
			return undefined;
		}

		fetch({ input: inputValue }, (results) => {
			if (active) {
				let newOptions = [];

				if (value) {
					newOptions = [value];
				}

				if (results) {
					newOptions = [...newOptions, ...results];
				}

				setOptions(newOptions);
			}
		});

		return () => {
			active = false;
		};
	}, [value, inputValue, fetch]);

	React.useEffect(() => {
		if (inputValue === "") {
			setOptions(value ? [value] : []);
		}
		onValueSet({
			streetAddress: inputValue,
			userInputValue: inputValue,
		});
	}, [inputValue]);

	React.useEffect(() => {
		if (value && value?.place_id) {
			const parameter = {
				placeId: value?.place_id,
			};

			getDetails(parameter)
				.then((details) => {
					const { streetAddress, city, state, country_code, postalCode } = extractAddressComponents(details?.address_components);
					onValueSet({
						streetAddress: streetAddress,
						city: city,
						state: state,
						country_code: country_code,
						zipcode: postalCode,
						userInputValue: inputValue,
					});
				})
				.catch((error) => {
					console.log("Error: ", error);
					onValueSet({
						streetAddress: "",
						city: "",
						state: "",
						country_code: "",
						zipcode: "",
						userInputValue: inputValue,
					});
				});
		}
	}, [value]);

	function extractAddressComponents(addressComponents) {
		let streetNumber = null;
		let route = null;
		let locality = null;
		let administrativeAreaLevel1 = null;
		let postalCode = null;
		let country_code = null;

		addressComponents.forEach((component) => {
			if (component.types.includes("street_number")) {
				streetNumber = component.long_name;
			}
			if (component.types.includes("route")) {
				route = component.long_name;
			}
			if (component.types.includes("locality")) {
				locality = component.long_name;
			}
			if (component.types.includes("administrative_area_level_1")) {
				administrativeAreaLevel1 = { long_name: component?.long_name, short_name: component?.short_name };
			}
			if (component.types.includes("postal_code")) {
				postalCode = component.long_name;
			}
			if (component.types.includes("country")) {
				country_code = component.short_name;
			}
		});

		const streetAddress = streetNumber && route ? `${streetNumber} ${route}` : null;
		if (country_code == "US") {
			administrativeAreaLevel1 = administrativeAreaLevel1?.short_name ?? "";
		} else {
			administrativeAreaLevel1 = administrativeAreaLevel1?.long_name ?? "";
		}
		return {
			streetAddress,
			city: locality,
			state: administrativeAreaLevel1,
			country_code,
			postalCode,
		};
	}

	return (
		<Box sx={{ minWidth: 120, width: "100%" }}>
			<Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
				<Typography variant="count_title">{fieldLabel}</Typography>
				<InfoHelper
					title={"Address will not be visible to others"}
					body={"We do not display your address to others on StartupOS. We use your address to accurately prefill the other location fields. "}
				/>
			</Box>

			<Autocomplete
				freeSolo
				name={name ?? ""}
				getOptionLabel={(option) =>
					typeof option === "string"
						? option
						: option?.structured_formatting?.main_text
						? option?.structured_formatting?.main_text
						: option?.description
				}
				PaperComponent={({ children }) => <StyledPaper noOptions={options?.length === 0}>{children}</StyledPaper>}
				filterOptions={(x) => x}
				options={options}
				autoComplete
				includeInputInList
				filterSelectedOptions
				value={value}
				noOptionsText="No locations"
				onChange={(event, newValue) => {
					setOptions(newValue ? [newValue, ...options] : options);
					setValue(newValue);
				}}
				onInputChange={(event, newInputValue) => {
					setInputValue(newInputValue);
				}}
				sx={{
					"& .MuiAutocomplete-inputRoot": {
						height: "60px",
					},
					"& .MuiAutocomplete-input": {
						paddingLeft: "10.5px !important",
					},
				}}
				renderInput={(params) => <UpdatedTextInputField {...params} label="" placeholder={fieldPlaceholder} fullWidth />}
				renderOption={(props, option) => {
					const { key, ...optionProps } = props;
					const matches = option?.structured_formatting?.main_text_matched_substrings || [];

					const parts = parse(
						option?.structured_formatting?.main_text,
						matches.map((match) => [match?.offset, match?.offset + match?.length])
					);
					return (
						<li key={key} {...optionProps}>
							<Grid container sx={{ alignItems: "center" }}>
								<Grid item sx={{ display: "flex", width: 44 }}>
									<LocationOnIcon sx={{ color: "text.secondary" }} />
								</Grid>
								<Grid item sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}>
									{parts.map((part, index) => (
										<Box key={index} component="span" sx={{ fontWeight: part?.highlight ? "bold" : "regular" }}>
											{part.text}
										</Box>
									))}
									<Typography variant="body2" color="text.secondary">
										{option?.structured_formatting?.secondary_text}
									</Typography>
								</Grid>
							</Grid>
						</li>
					);
				}}
			/>
		</Box>
	);
}
