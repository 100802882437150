import React, { useEffect, useState } from "react";
import theme from "../../../constants/Theme";
import { Stack, Grid, Typography, Button, Box } from "@mui/material";
import TodayIcon from "@mui/icons-material/Today";
import { PEOPLE_PURPLE } from "../../../constants/image";
import EventsContainer from "../../../common/EventsContainer";

export default function AdvisorDashboard() {
  return (
    <Stack
      sx={theme => ({
        width: 1,
        borderRadius: theme.shape.standard.borderRadius,
        background:
          "linear-gradient(0deg, rgba(118, 73, 160, 0.05), rgba(118, 73, 160, 0.05)), #FCFCFC",
        [theme.breakpoints.down("md")]: {
          borderRadius: 0,
        },
      })}
    >
      <Box
        sx={{
          maxWidth: theme.breakpoints.values.xl,
          margin: "auto",
          width: "100%",
        }}
      >
        <EventsContainer
          heading={"Upcoming Events"}
          title={"No events yet"}
          subText={"Your events scheduled in StartupOS will show up here."}
          customIcon={<TodayIcon />}
          titleVariant={"title_large"}
          margin={3}
        />

        <EventsContainer
          heading={"Advisor Overview"}
          title={"No requests yet"}
          customIcon={<img src={PEOPLE_PURPLE} height={20} width={25} />}
          titleVariant={"title_large"}
          subText={
            "Finish creating your profile and start browsing startups to begin advising."
          }
          margin={3}
        />
      </Box>
    </Stack>
  );
}
