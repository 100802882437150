import React, { useEffect, useState } from "react";
import { Box, Divider, Stack, Typography, Grid, useTheme } from "@mui/material";
import { AnimatedBtn, CardFounderWrapper } from "../styles";
import PitchDayCount from "./PitchDayCount";
import { ArrowForwardOutlined } from "@mui/icons-material";
import PitchProgress from "./PitchProgress";
import { useDispatch, useSelector } from "react-redux";
import { setFounderDashboardData } from "../../../modules/actions";
import { useHistory } from "react-router-dom";
import ROUTES_PATH from "../../../constants/routes";
import { timeDifferenceToEvent } from "../../../utils/commonFunctions";
import InfoHelper from "../InfoHelper";
import PaymentPlanDialog from "../../../components/PaymentPlanDialog";

function PitchDayCountDown() {
	const dispatch = useDispatch();
	const powerUpPacksList = useSelector((state) => state?.founderDashboard?.powerUpPacks);
	const [pitchProgressList, setPitchProgressList] = useState([]);
	const [averagePercentage, setAveragePercentage] = useState(0);
	const history = useHistory();
	const [countdown, setCountDown] = useState({ d: 0, h: 0, m: 0 });
	const theme = useTheme();
	const isAdmin = useSelector((store) => store?.auth?.isAdmin);
	const isPremiumPlanAccess = useSelector((store) => store?.auth?.isPremiumPlanAccess);
	const [showPlanModal, setShowPlanModal] = useState(false);

	const toggleApplyToPitch = () => {
		if (isPremiumPlanAccess || isAdmin) {
			dispatch(
				setFounderDashboardData({
					applyToPitchOpen: true,
				})
			);
		} else {
			setShowPlanModal(true);
		}
	};

	useEffect(() => {
		if (powerUpPacksList?.length) {
			let percentageTotal = 0;
			const tempPitchProgressList = powerUpPacksList.map((powerUpPack) => {
				const { powerupCompleted = 0, pluginCompleted = 0, packTotal = 0, powerUpPackName = "" } = powerUpPack;
				const totalCompleted = powerupCompleted + pluginCompleted;
				const percentage = packTotal !== 0 ? (totalCompleted / packTotal) * 100 : 0;
				percentageTotal = percentageTotal + percentage;
				return { title: powerUpPackName, percentage: percentage };
			});
			setPitchProgressList(tempPitchProgressList);
			setAveragePercentage(percentageTotal / powerUpPacksList?.length);
		}
	}, [powerUpPacksList]);

	const handlePitchProgressClick = (tabIndex) => {
		history.push(`${ROUTES_PATH.STARTUPS_HOME}/to-do`, { tabIndex: tabIndex });
	};

	const handleClosePlanModal = () => {
		setShowPlanModal(false);
	};

	useEffect(() => {
		const futureEvent = new Date("2023-05-04"); //event date 4 may 2023
		const { days, hours, minutes } = timeDifferenceToEvent(futureEvent);
		setCountDown({ d: days, h: hours, m: minutes });
		let timer = setInterval(() => {
			const { days, hours, minutes, remainingTime } = timeDifferenceToEvent(futureEvent);
			if (remainingTime < 0) {
				clearInterval(timer);
				setCountDown({ d: 0, h: 0, m: 0 });
				return;
			}
			setCountDown({ d: days, h: hours, m: minutes });
		}, 6 * 10 * 1000); // Update every mintutes

		return () => {
			clearInterval(timer); // clearing when unmount
		};
	}, []);

	return (
		<CardFounderWrapper>
			<Box display="flex" flexDirection="column" gap={4}>
				{/* <Box>
					<Box sx={{ display: "flex", alignItems: "center", gap: "12px" }}>
						<Typography variant="main_title" color="secondary.black">
							Pitch Day Countdown!
						</Typography>
						<InfoHelper
							title={"Pitch Day Countdown!"}
							body={
								"Check out the time remaining until our next pitch event with investors. Raise your StartupOS score to potentially meet for funding opportunities."
							}
						/>
					</Box>
				</Box>
				<Box>
					<Box display="flex" alignItems="center" gap={6} flexWrap="wrap">
						<PitchDayCount value={countdown.d} title="DAYS" bgColor={theme.palette.primary.main_new} />
						<PitchDayCount value={countdown.h} title="HOURS" bgColor={theme.palette.primary.primaryPink} />
						<PitchDayCount value={countdown.m} title="MINUTES" bgColor={theme.palette.primary.primaryMelon} />
					</Box>
				</Box>
				<Divider
					orientation="horizontal"
					sx={{
						border: "1px dashed",
						borderColor: theme.palette.text.investorDashGrayLabelMuted,
					}}
				/> */}
				<Box>
					<Stack>
						<Typography variant="poppinsSemiBold16" color="secondary.black">
							Pitch Readiness
						</Typography>

						<Box display="flex" alignItems="center">
							<Box display="flex" alignItems="center" gap={1} sx={{ "&:hover": { cursor: "pointer" } }} onClick={toggleApplyToPitch}>
								<Typography variant="poppinsSemiBold14" color="primary.primaryPink">
									{isPremiumPlanAccess || isAdmin ? "Investors are watching!" : "Ready to be seen by investors?"}
								</Typography>
								<ArrowForwardOutlined sx={{ fontSize: "16px" }} />
							</Box>
							{/* <Box display="flex" alignItems="center">
								<AnimatedBtn
									variant="link"
									sx={{
										color: theme.palette.secondary.black,
										fontSize: "16px",
										lineHeight: "27px",
										fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
									}}
									startIcon={<ArrowForwardOutlined fontSize="large" />}
									endIcon={<ArrowForwardOutlined fontSize="large" />}
									onClick={toggleApplyToPitch}
								>
									<Box display={"flex"} alignItems="center">
										<Typography variant="poppinsSemiBold16" color="primary.primaryPink">
											Are you ready to be showcased to investors?
										</Typography>
									</Box>
								</AnimatedBtn>
							</Box> */}
						</Box>
					</Stack>
				</Box>
				<Divider
					orientation="horizontal"
					sx={{
						border: "1px dashed",
						borderColor: theme.palette.text.investorDashGrayLabelMuted,
					}}
				/>
				<Box display="flex" flexDirection="column" rowGap={2}>
					{/* <Box>
						<Typography variant="main_title" color="secondary.black">
							Complete these PowerUps to be eligible for Pitch Day!
						</Typography>
					</Box> */}
					<Grid container rowSpacing={{ xs: 4, lg: 2 }} columnSpacing={2}>
						{pitchProgressList.map((pitchProgress, index) => (
							<Grid item xs={12} md={12} lg={12} xl={6} key={pitchProgress.title}>
								<PitchProgress
									title={pitchProgress.title}
									percentage={pitchProgress.percentage}
									handlePitchProgressClick={() => handlePitchProgressClick(index)}
								/>
							</Grid>
						))}
					</Grid>
				</Box>
				{showPlanModal && <PaymentPlanDialog open={showPlanModal} onClose={handleClosePlanModal} />}
			</Box>
		</CardFounderWrapper>
	);
}

export default PitchDayCountDown;
