export const AREAS_OF_EXPERTISE = [
	"AR/VR",
	"Artificial intelligence",
	"B2B",
	"B2C",
	"Bootstrapping",
	"Content marketing",
	"Continuous delivery",
	"Conversion rate optimisation",
	"Copywriting",
	"Customer development",
	"Customer discovery",
	"Customer success",
	"Data science",
	"Design / UX",
	"Email marketing",
	"Fundraising",
	"Go to market strategy",
	"Growth marketing",
	"Idea validation",
	"Investor psychology",
	"Marketing automation",
	"Mindset coaching",
	"Minimum viable product",
	"Networking",
	"No-code",
	"Pay per click strategies",
	"Pricing strategy",
	"Product analytics",
	"Product launches",
	"Product management",
	"Product market fit",
	"Product marketing",
	"Productized services",
	"Public relations",
	"Recruiting/Team building",
	"SaaS/Cloud",
	"Sales",
	"SEO",
	"Social media",
	"Technology and tools",
];

export const EXPERTISE_OPTIONS = [
	"Startup Strategy & Execution",
	"Marketing & Growth",
	"Operations & Infrastructure",
	"User Experience & Design",
	"Team Building & Leadership",
	"Engineering & Technical",
];
