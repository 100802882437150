import { ArrowForwardOutlined } from "@mui/icons-material";
import { Box, Button, Divider, Typography } from "@mui/material";
import React from "react";
import { CardFounderSmallWrapper } from "../styles";

function OtherSmallCard({ title, subtitle, icon, color, buttonText, onClickLink }) {
	const handleButtonLinkClick = () => {
		onClickLink?.();
	};

	return (
		<CardFounderSmallWrapper
			onClick={handleButtonLinkClick}
			sx={{
				display: "flex",
				//flex: 1,
				flexDirection: "column",
				justifyContent: "space-between",
				cursor: "pointer",
				transition: "all .2s ease-in-out",
				"&:hover": {
					transform: "translateY(-10px) scale(1.02)",
				},
			}}
		>
			<Box display="flex" flexDirection="column" justifyContent="space-between" gap={"20px"} sx={{ flex: 1 }}>
				<Box display="flex" alignItems="center" gap={"10px"}>
					<Box component="img" src={icon} sx={{ width: "30px", height: "30px" }} />
					<Typography variant="customValue" sx={{ fontFamily: "PoppinsSemiBold", fontSize: "20px", lineHeight: "30px" }} color="secondary.black">
						{title}
					</Typography>
				</Box>
				<Box display="flex" gap={"20px"} sx={{ flex: 1 }}>
					<Divider orientation="vertical" sx={{ borderWidth: "4px", bgcolor: color, alignSelf: "stretch", borderRadius: "10px" }} />
					<Box display="flex" flexDirection="column" sx={{ flex: 1 }}>
						<Typography
							variant="customValue"
							sx={{ fontFamily: "PoppinsRegular", fontSize: "16px", lineHeight: "24px", mb: "20px" }}
							color="secondary.black"
						>
							{subtitle}
						</Typography>
						<Button
							variant="link"
							sx={{
								padding: "0px",
								minWidth: "unset",
								height: "auto",
								fontFamily: "PoppinsSemiBold",
								fontSize: "16px",
								lineHeight: "24px",
								textAlign: "left",
								justifyContent: "flex-start",
								color: "black",
								margin: "auto 0 0 0",
							}}
							endIcon={<ArrowForwardOutlined fontSize="large" color="secondary.black" sx={{ color: (theme) => theme.palette.secondary.black }} />}
							onClick={handleButtonLinkClick}
						>
							{buttonText}
						</Button>
					</Box>
				</Box>
			</Box>
		</CardFounderSmallWrapper>
	);
}

export default OtherSmallCard;
