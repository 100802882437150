import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  Typography,
  MenuItem,
  Autocomplete,
  Grid,
} from "@mui/material";
import { CHALLENGES } from "../../../../../../../constants/powerup-data";
import MultiValueSelectInput from "../../../../../../../common/MultiValueSelectInput";
import messages from "../../../../../../../constants/messages";
import AutocompleteInput from "../../../../../../../common/AutocompleteInput";

export default function JobResponsiblities({
  userInputs,
  setUserInputs,
  jobResponsibilityInputValid,
  fieldDisable,
}) {
  const handleInputChange = (e, key) => {
    let value = e.target.value;
    let currentInput = { ...userInputs };
    currentInput[key] = value;

    setUserInputs(currentInput);
  };

  return (
    <Box>
      <Box sx={{ mt: 8 }}>
        <Typography variant="h5">
          What are their goals or objectives?
        </Typography>
        <AutocompleteInput
          label={"Goals or Objectives"}
          handleInputChange={handleInputChange}
          inputKey={"goals"}
          autoCompleteData={[]}
          inputValue={userInputs ? [...userInputs.goals] : []}
          inputValid={jobResponsibilityInputValid}
          errorMessage={messages.MULTITAG_INPUT_INVALID}
          sx={{ mt: 2 }}
          typingEnabled
          multiple
          disabled={fieldDisable}
          handleOnBlurEvent={true}
        />
      </Box>

      <Box sx={{ mt: 8 }}>
        <Typography variant="h5">What are their biggest challenges?</Typography>
        <AutocompleteInput
          label={"Challenges"}
          handleInputChange={handleInputChange}
          inputKey={"challenges"}
          autoCompleteData={CHALLENGES}
          inputValue={userInputs ? [...userInputs.challenges] : []}
          inputValid={jobResponsibilityInputValid}
          errorMessage={messages.MULTITAG_INPUT_INVALID}
          sx={{ mt: 2 }}
          typingEnabled
          multiple
          disabled={fieldDisable}
          handleOnBlurEvent={true}
        />
      </Box>

      <Box sx={{ mt: 8 }}>
        <Typography variant="h5">
          What are their job responsibilities?
        </Typography>
        <AutocompleteInput
          label={"Job Responsibilities"}
          handleInputChange={handleInputChange}
          inputKey={"jobResponsibilities"}
          autoCompleteData={[]}
          inputValue={userInputs ? [...userInputs.jobResponsibilities] : []}
          inputValid={jobResponsibilityInputValid}
          errorMessage={messages.MULTITAG_INPUT_INVALID}
          sx={{ mt: 2 }}
          typingEnabled
          multiple
          disabled={fieldDisable}
          handleOnBlurEvent={true}
        />
      </Box>
    </Box>
  );
}
