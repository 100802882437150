import { Box, Switch, Typography, styled } from "@mui/material";
import React from "react";
import TimeItem from "./TimeItem";

const IOSSwitch = styled((props) => <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />)(({ theme }) => ({
	width: 42,
	height: 26,
	padding: 0,
	"& .MuiSwitch-switchBase": {
		padding: 0,
		margin: 2,
		transitionDuration: "300ms",
		"&.Mui-checked": {
			transform: "translateX(16px)",
			color: "#fff",
			"& + .MuiSwitch-track": {
				backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#7B61FF",
				opacity: 1,
				border: 0,
			},
			"&.Mui-disabled + .MuiSwitch-track": {
				opacity: 0.5,
			},
		},
		"&.Mui-focusVisible .MuiSwitch-thumb": {
			color: "#33cf4d",
			border: "6px solid #fff",
		},
		"&.Mui-disabled .MuiSwitch-thumb": {
			color: theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[600],
		},
		"&.Mui-disabled + .MuiSwitch-track": {
			opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
		},
	},
	"& .MuiSwitch-thumb": {
		boxSizing: "border-box",
		width: 22,
		height: 22,
	},
	"& .MuiSwitch-track": {
		borderRadius: 26 / 2,
		backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
		opacity: 1,
		transition: theme.transitions.create(["background-color"], {
			duration: 500,
		}),
	},
}));

function DayCard({ dayData, selected, selectChange, updateDayData, updateDeleteArray }) {
	const updateDayTimeData = (data) => {
		try {
			const localData = { ...dayData };
			localData.timeList = data;
			updateDayData(localData);
		} catch (e) {
			console.log(e);
		}
	};

	return (
		<Box
			sx={{
				boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #F2F4F7",
				borderRadius: "24px",
				p: "20px",
				flex: 1,
				backgroundColor: "white",
			}}
		>
			<Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
				<Typography variant="Text/sm/Semibold" component={"p"}>
					{dayData?.label}
				</Typography>
				<IOSSwitch
					checked={selected}
					onChange={(e) => {
						if (selectChange) {
							selectChange(e?.target?.checked);
						}
					}}
				/>
			</Box>
			{selected && (
				<Box sx={{ mt: "32px" }}>
					<TimeItem
						setAvailabilitiesToDelete={(id) => updateDeleteArray(id)}
						timeList={dayData?.timeList}
						updateDayTimeData={(data) => updateDayTimeData(data)}
					/>
				</Box>
			)}
		</Box>
	);
}

export default DayCard;
