import { Box, Typography } from "@mui/material";
import React from "react";
import ColumnControl from "./ColumnControl";
import InsightCardComp from "./InsightCardComp";
import InsightSubSection from "./InsightSubSection";

const MissingDataCard = ({ name }) => (
	<Box sx={{ display: "flex", justifyContent: "center" }}>
		<InsightCardComp
			elevation={1}
			sx={{
				maxWidth: "800px",
			}}
		>
			<Typography color="black" variant="poppinsSemiBold18" component={"p"} sx={{ mb: 3 }}>
				No Data
			</Typography>
			<Typography color="black" variant="poppinsMedium16" component={"p"}>
				At present, we don't have any information on {name} for this startup. As the startup advances and becomes more active on the platform, this
				panel will be updated with relevant data. Please keep checking back or add this startup to your watchlist to stay informed.
			</Typography>
		</InsightCardComp>
	</Box>
);

function InsightTabContent({ selectedTabID, tabID, subSegments, index, segmentName }) {
	return (
		<Box role="tabpanel" hidden={selectedTabID !== index}>
			{selectedTabID === index && subSegments ? (
				<ColumnControl
					minWidth={1200}
					wrapperSX={{ afterStyle: { columnCount: 1 }, beforeStyle: { columnCount: 2 }, style: { columnGap: 2 } }}
					itemSX={{ style: { mb: 2 } }}
				>
					{subSegments?.map((obj, index) => (
						<InsightSubSection data={obj} key={index} />
					))}
				</ColumnControl>
			) : (
				<MissingDataCard name={segmentName} />
			)}
		</Box>
	);
}

export default InsightTabContent;
