import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography, Grid, Avatar, IconButton, styled, Tooltip, tooltipClasses, ClickAwayListener, Divider, Button } from "@mui/material";
import PartnerWidget from "./PartnerWidget";
import { USER_TYPES } from "../../../../utils/userTypes";
import { HOME_TITLES, HOME_TOOLSTIPS } from "./Data";

import AlertIcon from "../Assets/new-home-alert-icon.svg";
import { setCommonDialogConfirmationData } from "../../../../modules/actions";

const CustomTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
	[`& .${tooltipClasses.tooltip}`]: {
		marginRight: "100px",
		borderRadius: "30px",
		padding: 0,
	},
	[`.MuiTooltip-popper`]: {
		borderRadius: "30px",
	},
});

export default function WorkspaceHeader({ id, actions }) {
	const userType = useSelector((store) => store?.auth?.userType);
	const { icon, title, desc } = HOME_TITLES[id];
	const dispatch = useDispatch();

	const handleOpenInvestorApplyToPitch = () => {
		dispatch(
			setCommonDialogConfirmationData({
				applyToPitchOpen: true,
			})
		);
	};

	return (
		<Fragment sx={{ position: "relative", width: "100%" }}>
			{userType === USER_TYPES.STARTUP_OWNER && <PartnerWidget />}
			<Grid container justifyContent="space-between" rowGap={4} sx={{ mt: "32px" }}>
				<Grid item xs={12} xl={6}>
					<Box display="flex" flexDirection="column" mr="60px">
						<Box display="flex" alignItems="flex-start" mb={0.5}>
							<img src={icon} alt={title} style={{ paddingTop: "4px" }} />
							<Typography variant="main_title" sx={{ marginLeft: 1.5 }}>
								{title}
							</Typography>
						</Box>

						<Typography variant="StartupOS/subhead/subhead1Black">{desc}</Typography>
					</Box>
				</Grid>
				<Grid item xs={12} xl={6}>
					<Box
						height="100%"
						display="flex"
						alignItems="center"
						gap={2}
						sx={(theme) => ({
							justifyContent: "end",
							[theme.breakpoints.down("xl")]: {
								justifyContent: "start",
							},
						})}
					>
						<Box display={"flex"} flexDirection={"column"}>
							<Typography variant="Text/md/Semibold">Pitch to investors!</Typography>
							<Typography variant="Text/sm/Regular">Multiple events available!</Typography>
						</Box>
						<Box>
							<Button variant="DS1" onClick={() => handleOpenInvestorApplyToPitch()}>
								Apply Now!
							</Button>
						</Box>
					</Box>
				</Grid>

				<Grid item xs={12} xl={6}>
					<Box
						height="100%"
						display="flex"
						alignItems="flex-end"
						sx={(theme) => ({
							[theme.breakpoints.down("xl")]: {
								justifyContent: "start",
							},
						})}
					>
						{actions}
					</Box>
				</Grid>
			</Grid>
			{actions && <Divider sx={{ mt: 4 }} />}
		</Fragment>
	);
}

export const ActionBtn = ({ color, avatar, title, tabIndex = 0, actionIndex = 0, onAction }) => {
	const [openValidationDetail, setOpenValidationDetail] = useState(false);

	const handleTooltipClose = () => {
		setOpenValidationDetail(false);
	};

	const handleTooltipOpen = () => {
		setOpenValidationDetail(true);
	};

	return (
		<Box display="flex" alignItems="center" gap="5px" sx={{ cursor: "pointer" }} onClick={onAction}>
			<Avatar src={avatar} sx={{ bgcolor: color, width: "20px", height: "20px", padding: "10px" }} />
			<Typography variant="main_title">{title}</Typography>
			<ClickAwayListener onClickAway={handleTooltipClose}>
				<CustomTooltip
					disableHoverListener
					disableTouchListener
					placement={"bottom-end"}
					onClose={handleTooltipClose}
					open={openValidationDetail}
					title={
						<Box
							width="340px"
							sx={{
								background: "#FFFFFF",
								boxShadow: "0px 10px 85px rgba(0, 0, 0, 0.09)",
								borderRadius: "30px",
								p: "30px",
								textAlign: "left",
							}}
						>
							<Typography variant="custom070" color="#000" sx={{ display: "block", mb: 2 }}>
								{HOME_TOOLSTIPS[tabIndex][actionIndex].title}
							</Typography>
							<Typography variant="StartupOS/subhead/subhead1Black">{HOME_TOOLSTIPS[tabIndex][actionIndex].desc}</Typography>
						</Box>
					}
				>
					<IconButton sx={{ p: 0 }} onClick={handleTooltipOpen}>
						<img src={AlertIcon} alt="PowerUp" />
					</IconButton>
				</CustomTooltip>
			</ClickAwayListener>
		</Box>
	);
};
