import { ArrowForward, NotificationsOff } from "@mui/icons-material";
import { Box, CircularProgress, Typography, Button, Tooltip } from "@mui/material";

import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { PullNotifications } from "../../utils/notificationControl";
import NotificationItem from "./NotificationItem";

import { CSSTransition, TransitionGroup } from "react-transition-group";

import ROUTES_PATH from "../../constants/routes";
import { handleMixPanel } from "../../utils/mixPanelEventHandling";

import "./styles.css";

function NotificationPanel({ ...props }) {
	const notificationData = useSelector((state) => state?.notifications);
	const notificationLoading = useSelector((state) => state?.notifications?.notificationLoading);
	const [loading, setloader] = useState(false);
	const [notificationList, setnotificationList] = useState([]);

	useEffect(() => {
		setloader(notificationLoading);
	}, [notificationLoading]);

	useEffect(() => {
		if (notificationData) {
			// Ensure that the '5' count rule is excluding any system messages.
			try {
				const rebuiltArray = [
					...notificationData?.notificationList?.filter((x) => x.type && x.type === "system-message"),
					...notificationData?.notificationList?.filter((x) => !x.type || x.type !== "system-message")?.slice(0, 5),
				];
				setnotificationList(rebuiltArray);
			} catch (e) {
				console.log(e);
			}
		}
	}, [notificationData]);

	useEffect(() => {
		PullNotifications({ lastIndex: 0, perPage: 5 }); // Show latest 5 notifications in the popover.
	}, [props?.open]);

	useEffect(() => {
		/* 
            This function adds a delay to the UI loader. 
            In many cases the calls complete very quickly, which lead to 'flashing' 
            of the loader component. Here we wait a second after the global load state 
            completes, then update the local component state.
            Ex:
            If the local state is true (show loader) and 
            the global loading state goes to false. We will wait one second before matching
            the local state to the global. 
            If the local state is false (no loader shown) and the global state is loading
            we immediately match the local state to show the loader. 
        */
		if (loading && !notificationLoading) {
			setTimeout(() => {
				setloader(false);
			}, 1000);
		} else if (!loading && notificationLoading) {
			setloader(true);
		}
	}, [notificationLoading]);

	const notificationNavigationElement = (
		<Box sx={{ px: 2, py: 1, borderTop: "1px solid lightgray", display: "flex", justifyContent: "flex-end" }}>
			<Button
				variant="link"
				href={ROUTES_PATH.NOTIFICATIONSLIST}
				sx={{ color: (theme) => theme.palette.primary, display: "flex", alignItems: "center", gap: 2, width: "auto", m: 0, p: 0 }}
				onClick={() => {
					handleMixPanel("Notifications Dashboard Viewed", {
						"All Notifications Count": notificationList.length
					});
				}}
			>
				<Typography variant="label_medium" sx={{ color: (theme) => theme.palette.primary.main }}>
					Notifications Dashboard
				</Typography>
				<ArrowForward sx={{ color: (theme) => theme.palette.primary.main }} />
			</Button>
		</Box>
	);

	return (
		<Box
			sx={{
				width: "360px",
			}}
		>
			<Box sx={{ px: 2, borderBottom: "1px solid lightgray", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
				<Box sx={{ py: 2, display: "flex", alignItems: "center" }}>
					<Typography variant="label_medium" color="black">
						Latest Notifications
					</Typography>
				</Box>
				{loading && notificationList?.length > 0 && (
					<Tooltip title="Checking for new notifications...">
						<CircularProgress size={20} />
					</Tooltip>
				)}
			</Box>

			<TransitionGroup component="div">
				{notificationList?.map((item) => (
					<CSSTransition key={`panel_${item.NOTIFICATION_ID}`} timeout={500} classNames="notificationPanelItem">
						<NotificationItem notification={item} swipeable={item?.type === "system-message" ? false : true} />
					</CSSTransition>
				))}
			</TransitionGroup>
			{(notificationList?.length <= 0 || !notificationList) && (
				<Box
					sx={{
						width: "100%",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						flexDirection: "column",
						my: 2,
						gap: 1,
						color: (theme) => theme.palette.secondary.main,
					}}
				>
					{loading ? (
						<Fragment>
							<Box>
								<CircularProgress />
							</Box>
							<Box>
								<Typography variant="label_large">Checking for Notifications ...</Typography>
							</Box>
						</Fragment>
					) : (
						<Fragment>
							<Box>
								<NotificationsOff />
							</Box>
							<Box>
								<Typography variant="label_large">No Notifications</Typography>
							</Box>
						</Fragment>
					)}
				</Box>
			)}

			{notificationList?.length > 0 ? notificationNavigationElement : notificationList?.length <= 0 && !loading && notificationNavigationElement}
		</Box>
	);
}

export default NotificationPanel;
