import { ClearOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  tableCellClasses,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { getCurrentDate } from "../../../../utils/date";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import theme from "../../../../constants/Theme";
import MessageHandlerModal from "../../../../common/modal/MessageHandler";
import ROUTES_PATH from "../../../../constants/routes";

export default function TestTable({ marketTest, handleRemove }) {
  const history = useHistory();
  const [tests, setTests] = useState(marketTest);
  const [isAscending, setIsAscending] = useState(true);
  const [modalConfigs, setModalConfigs] = useState({
    show: false,
  });

  //Delete the test from market test list and hide the delete modal
  const handleDelete = testId => {
    handleRemove(testId);
    setModalConfigs({ show: false });
  };

  const handleView = testId => {
    history.push(ROUTES_PATH.MARKET_TEST + "/" + testId);
  };

  //Sorting the market test list
  const handelSort = () => {
    setIsAscending(!isAscending);
    sortTests();
  };

  //sorting the market test list
  const sortTests = () => {
    let data = [...tests];
    if (isAscending) {
      data.sort((a, b) => {
        return a.powerUpModel.powerUpName.toLowerCase() >
          b.powerUpModel.powerUpName.toLowerCase()
          ? 1
          : -1;
      });
    } else {
      data.sort((a, b) => {
        return a.powerUpModel.powerUpName.toLowerCase() <
          b.powerUpModel.powerUpName.toLowerCase()
          ? 1
          : -1;
      });
    }
    setTests(data);
  };

  //open the delete confirmation modal
  const removeTest = testId => {
    setModalConfigs({ show: true, testId: testId });
  };

  //Close the modal
  const handleCancel = () => {
    setModalConfigs({ show: false });
  };

  return (
    <Box>
      {tests?.length !== 0 && (
        <Box>
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell
                    onClick={handelSort}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Typography variant="title_small">PowerUp</Typography>
                    <Box
                      sx={{
                        ml: 1,
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {isAscending ? (
                        <ArrowDownwardIcon />
                      ) : (
                        <ArrowUpwardIcon />
                      )}
                    </Box>
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      [theme.breakpoints.down("md")]: {
                        display: "none",
                      },
                    }}
                  >
                    <Typography variant="title_small">Type</Typography>
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      [theme.breakpoints.down("md")]: {
                        display: "none",
                      },
                    }}
                  >
                    <Typography variant="title_small">Start Date</Typography>
                  </TableCell>
                  <TableCell align="left"></TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      [theme.breakpoints.down("md")]: {
                        display: "none",
                      },
                    }}
                  ></TableCell>
                </TableRow>
              </TableHead>
              <TableBody
                sx={{
                  [`& .${tableCellClasses.root}`]: {},
                }}
              >
                {tests?.map(row => (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <Box display={"flex"} alignItems={"center"}>
                        <Typography sx={{ ml: 1 }} variant="title_small">
                          {row.powerUpModel.powerUpName}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        [theme.breakpoints.down("md")]: {
                          display: "none",
                        },
                      }}
                    >
                      {`${row.marketTestModel.testType.charAt(
                        0
                      )}${row.marketTestModel.testType
                        .substr(1)
                        .toLowerCase()}`}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        [theme.breakpoints.down("md")]: {
                          display: "none",
                        },
                      }}
                    >
                      {getCurrentDate(new Date(row.startedDate))}
                    </TableCell>
                    <TableCell align="left">
                      <Button
                        variant="outlined"
                        size="small"
                        startIcon={<ArrowForwardIosIcon fontSize="small" />}
                        onClick={() => handleView(row.id)}
                      >
                        View
                      </Button>
                    </TableCell>
                    <TableCell
                      align="right"
                      onClick={() => removeTest(row.id)}
                      sx={{
                        [theme.breakpoints.down("md")]: {
                          display: "none",
                        },
                      }}
                    >
                      <ClearOutlined sx={{ cursor: "pointer" }} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
      <MessageHandlerModal
        isOpen={modalConfigs.show}
        onCancel={handleCancel}
        onOk={() => handleDelete(modalConfigs.testId)}
        text="Are you sure you want to remove test?"
        okText="Delete"
        cancelText="No Thanks"
        messageType="primary"
        heading={"Delete"}
      />
    </Box>
  );
}
