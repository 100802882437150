import React from "react";
import { Grid, Stack, Box } from "@mui/material";
import Divider from "@mui/material/Divider";
import { useHistory } from "react-router-dom";
import { POWERUP_LOGO_WHITE } from "../../../constants";
import ListItemComponent from "../../../components/Workspace/PowerUp/ListItem";
import ROUTES_PATH from "../../../constants/routes";

const PowerupListViewContainer = ({ powerupItems }) => {
  const history = useHistory();

  const handleButtonClick = listItem => {
    if (listItem === "Value Proposition") {
      history.push(ROUTES_PATH.VALUE_PROPOSITON);
    }
  };

  return (
    <Stack>
      <Grid container>
        {powerupItems.map((item, index) => {
          return (
            <Grid item md={12} xs={12} sx={{ mt: 3 }}>
              <ListItemComponent
                powerupLogo={POWERUP_LOGO_WHITE}
                title={item.powerUpName}
                buttonText="Start"
                onClick={() => handleButtonClick(item.title)}
              />
              <Divider
                sx={{
                  color: theme => theme.palette.secondary.borderLight,
                  mt: 3,
                }}
              />
            </Grid>
          );
        })}
      </Grid>
    </Stack>
  );
};

export default PowerupListViewContainer;
