import React, { useState, useEffect } from "react";
import { Grid, Typography, Button, LinearProgress, Divider } from "@mui/material";
import { Box } from "@mui/system";

import { ProgressDarkIcon, ProgressPrimaryIcon } from "../../../common/icons";
import { WorkspaceCard } from "../../../components/Workspace/Card/styles";

import CustomizedProgressBars from "../../../common/progressBar";

import ProgressBar from "./CircularProgress";
import { ProgressInnerValue } from "./styles";

import { useHistory } from "react-router-dom";

import ROUTES_PATH from "../../../constants/routes";
import { PROGRESS_ICON } from "../../../constants";

import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { BorderLinearProgress } from "../../../common/progressBar/styles";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import CompleteYourProfile from "../../../common/CompleteYourProfile";
import services from "../../../services";
import { accountProfileProgress, startupProfileProgress } from "../../../utils/profilePercentageCalculation";

const ProgressCard = ({ data, gradientId = "progressDesktop" }) => {
	const history = useHistory();

	const [userDetails, setUserDetails] = useState();
	const [averageValue, setAverageValue] = useState();

	const handleNext = () => {
		history.push(ROUTES_PATH.WORKSPACE);
	};

	const [powerupsProgress, setPowerupsProgress] = useState({
		pitchPerfect: { completed: false, value: 0, first: true },
		mvp: { completed: false, value: 0 },
		productMarketFit: { completed: false, value: 0 },
		businessStarter: { completed: false, value: 0 },
		customerDiscovery: { completed: false, value: 0 },
		marketExploration: { completed: false, value: 0 },
		startupProfile: { completed: false, value: 0 },
		personalProfile: { completed: false, value: 0 },
	});

	const handleUserDetails = () => {
		services
			.getUserProfile()
			.then((response) => {
				if (response.data.message === "Success") {
					setUserDetails(response.data.data);
				}
			})
			.catch((e) => {
				console.log("Submit", e);
			});
	};

	useEffect(() => {
		handleUserDetails();
	}, []);
	useEffect(() => {
		let dashboardData = { ...data };
		if (dashboardData.circleTotalUnits) {
			const value =
				(parseInt(data.totalPluginsCompleted + data.totalPowerUpsCompleted) / parseInt(data.unitDetails.pluginUnits + data.unitDetails.powerUpsUnits)) *
				100;
			averageValueCounter(value);
		}
	}, [userDetails]);

	const startupProfilePercentage = startupProfileProgress(userDetails?.companyModel).totalPercentage;
	const accountProfilePercentage = accountProfileProgress(userDetails).totalPercentage;

	const RenderProgress = ({ disabled, title, value, isProfile = false, thresholdProps }) => {
		if (!disabled) {
			return (
				<Box sx={{ my: 2 }}>
					<Box
						sx={{
							mb: 1,
							mt: 1,
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
						}}
					>
						<Typography variant="title_small">{title}</Typography>

						<Box>
							{isProfile ? (
								<OpenInNewIcon
									fontSize="small"
									sx={{ color: (theme) => theme.palette.text.atomic }}
									onClick={() => history.push(title === "Startup Profile" ? ROUTES_PATH.MY_STARTUP_PROFILE : ROUTES_PATH.MY_ACCOUNT_PROFILE)}
								/>
							) : (
								<LockOpenIcon fontSize="small" sx={{ color: (theme) => theme.palette.text.atomic }} />
							)}
						</Box>
					</Box>

					<CustomizedProgressBars amountComplete={value} displayPercent barColor={"orange"} thresholdProps={thresholdProps} />
				</Box>
			);
		} else {
			return (
				<Box sx={{ my: 2 }}>
					<Box
						sx={{
							mb: 1,
							mt: 1,
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
						}}
					>
						<Typography variant="label_small" color="secondary">
							{title}
						</Typography>

						<Box>
							<LockIcon fontSize="small" sx={{ color: "secondary.light" }} />
						</Box>
					</Box>
					<BorderLinearProgress variant="determinate" value={0} color="secondary" />
				</Box>
			);
		}
	};

	useEffect(() => {
		let dashboardData = { ...data };
		if (dashboardData.circleTotalUnits) {
			const value =
				(parseInt(data.totalPluginsCompleted + data.totalPowerUpsCompleted) / parseInt(data.unitDetails.pluginUnits + data.unitDetails.powerUpsUnits)) *
				100;

			averageValueCounter(value);

			let completed = false;

			if (value == 100) {
				completed = true;
			}

			setPowerupsProgress({
				...powerupsProgress,
				pitchPerfect: {
					completed,
					value,
					first: true,
				},
			});
		}
	}, [data]);

	const averageValueCounter = (value) => {
		const averageCount = Math.ceil(startupProfilePercentage + accountProfilePercentage + value) / 3;
		setAverageValue(Math.round(averageCount));
	};

	return (
		<WorkspaceCard
			variant="outlined"
			sx={{
				boxShadow: (theme) => theme.palette.primary.elevationLight2,
				// backgroundColor: theme => theme.palette.text.background,
				border: "0px !important", //Added important to override border property
			}}
		>
			<Box
				sx={{
					p: 3,
				}}
			>
				<Grid
					container
					display={"flex"}
					sx={[
						(theme) => ({
							[theme.breakpoints.down("md")]: {
								paddingBottom: 4,
							},
							[theme.breakpoints.up("md")]: {
								paddingBottom: 2,
							},
						}),
					]}
				>
					<Grid item lg={6} md={6} sm={12} xs={12}>
						<Box display="flex">
							<ProgressPrimaryIcon />
							<Typography variant="headline6" sx={{ ml: 2 }}>
								Progress
							</Typography>
						</Box>
					</Grid>
					{/*
          <Grid
            item
            lg={6}
            md={6}
            sm={12}
            xs={12}
            display={{ sm: "none", xs: "none", md: "block", lg: "block" }}
          >
            <Typography
              variant="h5"
              color="primary"
              align="right"
              onClick={handleNext}
            >
              Go to Workspace
            </Typography>
          </Grid>*/}
				</Grid>

				{/*<Grid
          container
          lg={12}
          md={12}
          sm={12}
          xs={12}
          display="flex"
          alignItems={"center"}
          justifyContent="center"
        >
          <Grid
            item
            lg={7}
            md={7}
            sm={12}
            xs={12}
            display="flex"
            justifyContent={{
              xs: "center",
              sm: "center",
              md: "center",
              lg: "center",
            }}
          >
            <Box>
              <ProgressBar
                //value={
                //  data.circleTotalUnits
                //    ? Math.ceil(
                //        (parseInt(
                //          data.totalPluginsCompleted +
                //            data.totalPowerUpsCompleted
                //        ) /
                //          parseInt(
                //            data.unitDetails.pluginUnits +
                //              data.unitDetails.powerUpsUnits
                //          )) *
                //          100
                //      )
                //    : 0
                //}
                value={averageValue}
                gradientId={gradientId}
              >
                <ProgressInnerValue>
                  <Typography variant="h1" className="test">
                    {`${data.circleTotalUnits}%`}
                  </Typography>
                  <Typography variant="h4" className="test">
                    {"Total"}
                  </Typography>
                </ProgressInnerValue>
              </ProgressBar>
            </Box>
          </Grid>
          <Grid item lg={5} md={5} sm={12} xs={12} sx={{ pt: 3, mb: "auto" }}>
            <Typography
              variant="h5"
              align="left"
              sx={[
                theme => ({
                  [theme.breakpoints.down("md")]: {
                    paddingBottom: 2,
                  },
                  [theme.breakpoints.up("md")]: {
                    paddingBottom: 3,
                  },
                }),
              ]}
            >
              Breakdown
            </Typography>

            <Box display="flex" justifyContent={"space-between"}>
              <Typography variant="h5" align="left">
                Power Ups
              </Typography>

              <Typography variant="h5" align="right">
                {data.totalPowerUpsCompleted}/{data.unitDetails.powerUpsUnits}
              </Typography>
            </Box>

            <CustomizedProgressBars
              value={
                data.totalPowerUpsCompleted
                  ? Math.ceil(
                      (parseInt(data.totalPowerUpsCompleted) /
                        parseInt(data.unitDetails.powerUpsUnits)) *
                        100
                    )
                  : 0
              }
            />
            <Box
              sx={{ textAlign: "end", display: { xs: "block", md: "none" } }}
            >
              <Button sx={{ mt: 6, width: "auto" }} onClick={handleNext}>
                Go to Workspace
              </Button>
            </Box>
          </Grid>
        </Grid>*/}
				<Box>
					<Box>
						<Typography variant="title_small">Profile Completion</Typography>
					</Box>

					{(startupProfilePercentage < 80 || accountProfilePercentage < 80) && (
						<Box sx={{ mt: 2 }}>
							<CompleteYourProfile sub_title="Fill in your profiles to at least 80% completion in order to be seen by Investors and connect with Mentors. " />
						</Box>
					)}
					<Box>
						<RenderProgress
							title={"Startup Profile"}
							disabled={false}
							value={startupProfilePercentage}
							isProfile={true}
							thresholdProps={{
								percent: 80,
								direction: ">=",
								barColor: "success",
							}}
						/>

						<RenderProgress
							title={"Personal Profile"}
							disabled={false}
							value={accountProfilePercentage}
							isProfile={true}
							thresholdProps={{
								percent: 80,
								direction: ">=",
								barColor: "success",
							}}
						/>
					</Box>
				</Box>
				<Divider sx={{ background: "rgba(124, 117, 127, 0.16)", mt: 5 }} />
				<Box sx={{ mt: 5 }}>
					<Box>
						<Typography variant="title_small">PowerPacks</Typography>
					</Box>

					<Box>
						<RenderProgress title={"Pitch Perfect"} disabled={false} value={powerupsProgress.pitchPerfect.value} />

						<RenderProgress
							title={"Minimum Viable Product"}
							disabled={!powerupsProgress.pitchPerfect.completed}
							value={powerupsProgress.mvp.value}
						/>

						<RenderProgress
							title={"Product Market Fit"}
							disabled={!powerupsProgress.mvp.completed}
							value={powerupsProgress.productMarketFit.value}
						/>

						<RenderProgress
							title={"Business Starter"}
							disabled={!powerupsProgress.productMarketFit.completed}
							value={powerupsProgress.businessStarter.value}
						/>

						<RenderProgress
							title={"Customer Discovery"}
							disabled={!powerupsProgress.businessStarter.completed}
							value={powerupsProgress.customerDiscovery.value}
						/>

						<RenderProgress
							title={"Market Exploration"}
							disabled={!powerupsProgress.customerDiscovery.completed}
							value={powerupsProgress.marketExploration.value}
						/>
					</Box>
				</Box>
			</Box>
		</WorkspaceCard>
	);
};

export default ProgressCard;
