import React, { Fragment, useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Divider,
  useMediaQuery,
  useTheme,
  Grid,
} from "@mui/material";
import PowerUPLayout from "../../../../layout/PowerUP";
import Sidebar from "../../../../components/Workspace/PowerUp/Sidebar";
import BreadcrumbsComp from "../../PowerUP/IdeaValidation/Components/BreadcrumbsComp";
import services from "../../../../services";
import {
  getSidebarContent,
  getCandidateDetails,
  getCandidatesList,
  candidateInvitationDetails,
} from "../../PowerUP/IdeaValidation/Functions/common";
import CardWrapper from "../../PowerUP/IdeaValidation/Components/CardWrapper";
import { useHistory, useParams, useLocation } from "react-router-dom";
import ROUTES_PATH from "../../../../constants/routes";
import CandidateHeader from "../../../../components/Workspace/MarketTest/CandidateHeader";
import CandidateAbout from "../../../../components/Workspace/MarketTest/CandidateAbout";
import InviteCandidateComp from "../../MarketTest/InviteCandidate/index";
import InviteMobileResponsiveUI from "../../MarketTest/InviteCandidate/InviteMobileResposiveUI";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import ProfileHeader from "../../../../components/Workspace/MarketTest/ExternalCandidate/ProfileHeader";
import CandidatesNotes from "../../../../components/Workspace/MarketTest/ExternalCandidate/CandidatesNotes";
import Details from "../../../../components/Workspace/MarketTest/ExternalCandidate/Details";
import InviteTable from "../../../../components/Workspace/MarketTest/InviteTable";

function CandidateProfile({ candidateObj, externalCandidates }) {
  const [SIDEBARDATA, setSidebarData] = useState({});
  const [loader, setLoader] = useState(true);
  const [details, setDetails] = useState(null);
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("md"));
  const history = useHistory();
  const powerUpId = "4";
  const marketTest = useSelector(store => store.powerUp?.marketTest);
  const params = useParams();
  const candidateId = parseInt(params.id);

  const [saved, setSaved] = useState(false);
  const [selectedCandidates, setSelectedCandidates] = useState([]);
  const [savedCandidates, setSavedCandidates] = useState([]);
  const [invitedCandidates, setInvitedCandidates] = useState([]);

  const location = useLocation();
  const [invited, setInvited] = useState(false);
  const [excerciseSteps, setExcerciseSteps] = useState("");
  const [showInviteCandidatePopup, setShowInviteCandidatePopup] =
    useState(false);

  const [isCandidateExternal, setIsCandidateExternal] = useState(false);
  //const [notes, setNotes] = useState("");
  const [saveLoading, setSaveLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const MainContent = () => {
    return !isCandidateExternal ? (
      <Box>
        <CandidateHeader
          candidateName={details?.name}
          candidateEmail={details?.email}
          linkedInUrl={details?.info?.linkedInUrl}
          avatar={details?.userIdentities[0]?.picture}
          saved={saved}
          setSaved={setSaved}
          setShowInviteCandidatePopup={setShowInviteCandidatePopup}
          invitedCandidates={invitedCandidates}
          handleSelect={handleSelect}
          invited={invited}
        />
        {invited && (
          <>
            <Divider sx={{ p: 2 }} />
            <Box
              gridTemplateColumns={"repeat(12,1fr)"}
              display="grid"
              gap={2}
              sx={{ my: 3 }}
            >
              {" "}
              <Box gridColumn={"span 12"}>
                <InviteTable details={details} />
              </Box>
            </Box>
          </>
        )}

        <Divider sx={{ p: 2 }} />
        <CandidateAbout details={details} />
      </Box>
    ) : (
      <Box>
        <Grid container>
          <Grid item xs={12} md={12}>
            <ProfileHeader
              saved={saved}
              setSaved={setSaved}
              setShowInviteCandidatePopup={setShowInviteCandidatePopup}
              invitedCandidates={invitedCandidates}
              handleSelect={handleSelect}
              invited={invited}
              details={details}
            />
            <Divider sx={{ p: 2 }} />
          </Grid>

          <Grid item xs={12} md={8} sx={{ display: "flex", gap: 1 }}>
            <CandidatesNotes
              handleSaveNotes={handleSaveNotes}
              saveLoading={saveLoading}
              isEdit={isEdit}
              setIsEdit={setIsEdit}
              details={{ ...details, selected: saved }}
            />
            <Divider
              sx={{ mt: 2, px: 1, display: { xs: "none", md: "block" } }}
              orientation="vertical"
              flexItem
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <Divider sx={{ p: 2, display: { xs: "block", md: "none" } }} />
            <Details details={details} />
          </Grid>
        </Grid>
      </Box>
    );
  };

  const mainTitleAction = () => {
    history.push(ROUTES_PATH.MARKET_TEST + "/" + marketTest.id);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });

    if (externalCandidates.find(obj => obj.email === candidateObj.email)) {
      setIsCandidateExternal(true);

      setDetails(
        JSON.parse(
          candidateObj.currentModuleObject.moduleStepResponses[0].answers
        ).candidates.find(obj => obj.email === candidateObj.email)
      );
    } else {
      setIsCandidateExternal(false);
      getCandidateDetails(candidateId).then(res => {
        setDetails(res);
      });
    }

    setLoader(true);
    getSidebarContent(powerUpId).then(res => {
      setSidebarData(res);
    });
    getModuleStep();
  }, []);

  const getModuleStep = () => {
    //getting moduleStepId
    services
      .getModulesSteps("md-interview-test")
      .then(result => {
        if (result.data?.data) {
          const steps = result.data.data;
          steps.sort((a, b) => (a.stepNo > b.stepNo ? 1 : -1));
          setExcerciseSteps(steps);
        }
      })
      .catch(err => console.log(err));

    setLoader(false);
  };

  useEffect(() => {
    if (candidateObj?.currentModuleObject?.moduleStepResponses[0]?.answers) {
      setSelectedCandidates(
        JSON.parse(
          candidateObj?.currentModuleObject?.moduleStepResponses[0]?.answers
        )?.candidates
      );
    }
  }, [candidateObj]);

  useEffect(() => {
    if (selectedCandidates.length > 0) {
      //check if saved
      if (
        selectedCandidates?.find(candidate => candidate.id == candidateId)
          ?.selected
      ) {
        setSaved(true);
      } else {
        setSaved(false);
      }

      //check if Invited
      if (candidateObj?.invited) {
        setInvited(true);
      }

      const savedCandidates = selectedCandidates.filter(
        candidate => candidate.selected === true
      );
      setSavedCandidates(savedCandidates);

      const invitedCandidates = selectedCandidates.filter(
        candidate => candidate.invited === true
      );
      setInvitedCandidates(invitedCandidates);
    }
  }, [selectedCandidates]);

  const handleSelect = () => {
    let newCandidates = [...selectedCandidates]; //All invited candidates
    newCandidates = [...selectedCandidates];
    let currentModuleObject = candidateObj.currentModuleObject; // current candidate

    let flag = saved;

    //check if selected
    const existingIndex = newCandidates.findIndex(
      candidate => candidate.email === details.email
    );
    newCandidates[existingIndex].notes =
      externalCandidates[existingIndex]?.notes;
    if (existingIndex >= 0) {
      //if candidate is invited and selected
      const existingCandidate = newCandidates[existingIndex];

      //if (existingCandidate?.invited) {
      const candidate = {
        ...existingCandidate,
        selected: !existingCandidate.selected,
      };

      newCandidates[existingIndex] = candidate;

      flag = false;
      //} else {
      //  //remove previously selected
      //  newCandidates = newCandidates.filter(
      //    candidate => candidate.email !== details.email
      //  );
      //  flag = false;
      //}
    } else {
      //add in selected candidates

      const candidate = {
        ...details,
        selected: true,
      };

      newCandidates.push(candidate);
      flag = true;
    }
    //API call to save
    services.storeModuleStepResponses(currentModuleObject.moduleId, {
      answers: JSON.stringify({ candidates: newCandidates }),
      moduleResponseId: currentModuleObject.id,
      isLastStep: true,
      isCompleted: true,
      moduleStepId: excerciseSteps[0].id,
    });

    setSelectedCandidates(newCandidates);
    setSaved(flag);
  };

  const isSelected = () => {
    const selected = selectedCandidates?.find(
      candidate => candidate.email === details.email
    );

    if (selected && selected.selected) {
      setSaved(true);
    } else {
      setSaved(false);
    }
  };

  const handleInviteSuccess = () => {
    setInvited(true);
  };

  const handleSaveNotes = notes => {
    setSaveLoading(true);
    //Add to answers
    let allCandidates = [...externalCandidates]; //all external candidate
    let currentModuleObject = candidateObj.currentModuleObject;
    let pushIndex = allCandidates.findIndex(
      element => element.id === details.id
    );

    let currentInviteStatus = selectedCandidates.filter(
      selectedItem => selectedItem.id === details.id
    );

    allCandidates[pushIndex].notes = notes;
    allCandidates[pushIndex].selected = saved;
    allCandidates[pushIndex].invited = currentInviteStatus[0]?.invited;
    services
      .storeModuleStepResponses(currentModuleObject.moduleId, {
        answers: JSON.stringify({ candidates: allCandidates }),
        moduleResponseId: currentModuleObject.id,
        isLastStep: true,
        isCompleted: true,
        moduleStepId: excerciseSteps[0].id,
      })

      .then(response => {
        setDetails(
          JSON.parse(
            response.data.data.moduleStepResponses[0].answers
          ).candidates.find(obj => obj.email === candidateObj.email)
        );

        setSaveLoading(false);
      });

    setIsEdit(false);
  };

  const handleRefresh = () => {
    let currentModuleObject = candidateObj.currentModuleObject;
    //getModuleStepResponses
  };

  return (
    <PowerUPLayout
      main={
        <Fragment>
          {loader && !details ? (
            <Box justifyContent={"center"} sx={{ display: "flex", mt: 5 }}>
              <CircularProgress />
            </Box>
          ) : (
            <CardWrapper>
              {isSmallScreen ? (
                <InviteMobileResponsiveUI
                  showInviteCandidatePopup={showInviteCandidatePopup}
                  setShowInviteCandidatePopup={setShowInviteCandidatePopup}
                  candidateObj={candidateObj}
                  handleInviteSuccess={handleInviteSuccess}
                  stepId={excerciseSteps[0]?.id}
                />
              ) : (
                <InviteCandidateComp
                  showInviteCandidatePopup={showInviteCandidatePopup}
                  setShowInviteCandidatePopup={setShowInviteCandidatePopup}
                  candidateObj={candidateObj}
                  handleInviteSuccess={handleInviteSuccess}
                  stepId={excerciseSteps[0]?.id}
                />
              )}

              <MainContent />
            </CardWrapper>
          )}
        </Fragment>
      }
      sidebar={
        isSmallScreen ? (
          <Box display={"flex"} flexDirection={"column"}>
            <Sidebar sidebar_content={SIDEBARDATA} />
          </Box>
        ) : (
          <Sidebar sidebar_content={SIDEBARDATA} />
        )
      }
      breadcrumbs={
        <BreadcrumbsComp
          mainTitle="Idea Validation Interview"
          handleMainTitleAction={mainTitleAction}
        />
      }
    />
  );
}

const mapStoreToProps = ({ powerUp }) => ({
  candidateObj: powerUp.candidateDetails,
  externalCandidates: powerUp.externalCandidateDetails,
});

export default connect(mapStoreToProps, undefined)(CandidateProfile);
