export const INDUSTRY_FOCUS = [
	"Aerospace & Defense",
	"Automotive",
	"Agriculture",
	"Banking",
	"Blockchain",
	"Capital Markets",
	"Chemical & Process",
	"Cleantech & Sustainability",
	"Computer",
	"Construction",
	"Consumer Packaged Goods (CPG)",
	"Consumer Services",
	"Cyber Security",
	"Durable Goods (DG)",
	"E-Commerce",
	"Education",
	"Electronics",
	"Energy & Utilities",
	"Financial Services",
	"Food & Food Services",
	"Healthcare",
	"Heavy Machinery",
	"Hi-Tech",
	"Industrial Manufacturing",
	"Insurance",
	"Internet & Cloud",
	"Media & Entertainment",
	"Mining & Natural Resources",
	"Music",
	"Life Sciences & Healthcare",
	"Oil & Gas",
	"Pharmaceutical",
	"Retail",
	"Telecommunication",
	"Transport & Logistics",
	"Travel & Hospitality",
	"Other",
];

export const TYPE_OF_INVESTOR = ["Angel", "Corporate Venture Capital", "Family Office", "Foundation", "Venture Capital"];

export const CHALLENGES_DATA = [
	"Uncertainty",
	"Collaboration",
	"Communications",
	"Customer services",
	"Digitalization",
	"Diversity & Inclusion",
	"Financial management",
	"Monitoring performance",
	"Regulation & Compliance",
	"Recruiting right talent",
	"Technology",
];

export const FOUNDER_SUPER_POWER_DATA = ["Supervision", "Networking", "Execution", "Luck", "Grit", "Leadership", "Empathy", "Passion"];

export const GravatarURL = "https://s.gravatar.com/avatar/";