import React, { useContext, useEffect, useMemo, useState } from "react";
import { BreadcrumbsComponent } from "../../../../components/Breadcrumbs";
import { AdminPowerpackContext } from "..";
import { Box, Button, Chip, CircularProgress, Skeleton, Typography, useMediaQuery } from "@mui/material";
import DraggablePowerups from "../DraggablePowerups";
import services from "../../../../services";
import { toast } from "react-toastify";
import { toastContainer } from "../../../../utils/toast";
import messages from "../../../../constants/messages";
import { useHistory } from "react-router-dom";
import ROUTES_PATH from "../../../../constants/routes";

const PowerupPackSettings = () => {
	const [powerPacks, setPowerPacks] = useState(null);
	const [powerupPacksLoading, setPowerupPacksLoading] = useState(false);
	const [loading, setLoading] = useState(false);
	const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
	const history = useHistory();
	const getNestedRoutes = useMemo(() => {
		let bedcrumDetails = [
			{ title: "PowerPack Management Dashboard", route: ROUTES_PATH.ADMIN_POWERPACK_DASHBOARD },
			{ title: "Edit PowerPack Settings", route: "" },
		];

		return { bedcrumDetails };
	}, []);

	useEffect(() => {
		getAllGroupCards();
	}, []);

	const onClose = () => {
		history.push(ROUTES_PATH.ADMIN_POWERPACK_DASHBOARD);
	};

	const getAllGroupCards = async () => {
		try {
			setPowerupPacksLoading(true);

			const res = await services.getAllGroupCardsApi({ group: "powerpacks" });

			if (res.status === 200) {
				setPowerupPacksLoading(false);
				setPowerPacks(res?.data?.data);
			}
		} catch (error) {
			setPowerupPacksLoading(false);
			console.log(`Error fetching group cards`, error);
		}
	};

	const onSubmit = async () => {
		try {
			setLoading(true);
			const payload = powerPacks?.map((item, index) => {
				return {
					cardId: item?.cardId,
					status: item?.entityStatus,
					orderNo: index + 1,
				};
			});

			const res = await services.updatePowerupPackSettingsApi(payload);
			if (res.status === 200) {
				setLoading(false);
				toastContainer(messages.POWERUP_PACKS_UPDATED);
			}
		} catch (error) {
			console.log(`Error updating Poweurp Pack Settings`, error);
			toastContainer(messages.POWERUP_PACKS_UPDATED_ERROR);
			setLoading(false);
		}
	};

	const PowerupSkeleton = () => (
		<Box display="flex" flexDirection={isSmallScreen ? "column" : "row"} gap={1} p={1} flexWrap="wrap" alignItems="center">
			<Box sx={{ width: isSmallScreen ? "100%" : "180px", position: "relative", ml: isSmallScreen ? "0" : "40px" }}>
				<Skeleton variant="rectangular" width="100%" height="163px" sx={{ borderRadius: "15px" }} />
			</Box>
			<Box sx={{ width: isSmallScreen ? "100%" : "calc(100% - 250px)", ml: 2 }}>
				<Skeleton width="20%" height="30px" sx={{ marginBottom: 1 }} />
				<Skeleton width="80%" height="30px" sx={{ marginBottom: 1 }} />
				<Skeleton width="80%" height="30px" sx={{ marginBottom: 1 }} />
				<Skeleton width="80%" height="30px" />
			</Box>
		</Box>
	);

	return (
		<Box
			sx={{
				px: {
					lg: 8,
					md: 4,
					xs: 2,
				},
				pb: "30px",
				display: "flex",
				flexDirection: "column",
				gap: "32px",
				maxWidth: "1920px",
				margin: "0 auto",
			}}
		>
			<BreadcrumbsComponent bedcrumDetails={getNestedRoutes?.bedcrumDetails} />

			<Box sx={{ backgroundColor: "#FFFFFF", borderRadius: "24px", p: 3 }} mt={3}>
				<Box display={"flex"} flexDirection={"column"} gap={2}>
					<Typography variant="Text/sm/Semibold">{"Edit PowerPack Settings"}</Typography>
					<Typography variant="Text/sm/Regular">{"Edit your PowerPack order and visibility!"}</Typography>

					<Box sx={{ backgroundColor: "white", borderRadius: "15px", p: 2, border: "1px solid #D0D5DD" }}>
						<Typography variant="Text/sm/Semibold" color={"#667085"}>
							{"PowerPacks"}
						</Typography>

						{powerupPacksLoading ? (
							Array.from({ length: 4 }).map((_, index) => <PowerupSkeleton key={index} />)
						) : (
							<Box
								sx={{
									mt: 2,
									backgroundColor: "white",
									borderRadius: "30px",
									p: powerPacks?.length > 0 ? 0 : 2,
									border: powerPacks?.length > 0 ? "none" : "1px solid #D0D5DD",
									minHeight: "100px",
								}}
							>
								{powerPacks?.length > 0 ? (
									<DraggablePowerups powerups={powerPacks} setPowerups={setPowerPacks} />
								) : (
									<Box display={"flex"} justifyContent={"center"} mt={5}>
										<Typography variant="Text/xs/Regular" color={"#475467"}>
											{"No PowerUps Added Yet!"}
										</Typography>
									</Box>
								)}
							</Box>
						)}
					</Box>

					<Box>
						<Box display={"flex"} alignItems={"center"} justifyContent={"flex-end"}>
							<Box>
								{powerupPacksLoading ? (
									<Skeleton variant="rectangular" width={75} height={36} sx={{ borderRadius: "20px", mr: 3 }} />
								) : (
									<Button sx={{ color: (theme) => theme.palette.secondary.light }} onClick={onClose} variant="noOutlineNew">
										Cancel
									</Button>
								)}
							</Box>
							<Box>
								{powerupPacksLoading ? (
									<Skeleton variant="rectangular" width={75} height={36} sx={{ borderRadius: "20px" }} />
								) : (
									<Button
										startIcon={loading && <CircularProgress size={"1.25rem"} sx={{ color: "inherit" }} />}
										variant="DS1"
										type="submit"
										onClick={onSubmit}
										disabled={powerPacks?.length < 0}
									>
										{"Save"}
									</Button>
								)}
							</Box>
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export default PowerupPackSettings;
