import services from "../../../services";
import {
  convertFormattedStringToDate,
  getCurrentDate,
  getCurrentTime,
  getFormattedDate,
  getGlobalTime,
} from "../../../utils/date";

export const getInviteDetails = async eventId => {
  return services
    .getCalendarInviteEventDetails(eventId)
    .then(res => {
      return res?.data?.data;
    })
    .catch(error => {
      throw error;
    });
};

export const confirmInterviewInvite = (eventId, data) => {
  return services
    .updateCalendarInviteEvent(eventId, data)
    .then(result => {
      const res = result?.data;
      if (res.code == 200) {
        return res.data;
      }
    })
    .catch(error => {
      throw error;
    });
};

export const convertToTimeStamp = (date, time) => {
  let ns = convertFormattedStringToDate(date).setHours(time?.split(":")[0]);
  ns = new Date(ns).setMinutes(time?.split(":")[1]);
  return ns;
};

export const parseDateTimePayload = responses => {
  let responsesData = [...responses];
  responsesData.forEach(element => {
    element.date = getFormattedDate(new Date(element.date), "YYYY-MM-DD");
    element.times = [
      ...element.times.map(time => {
        let ns = new Date(time).getHours() + ":" + new Date(time).getMinutes();
        ns = getCurrentTime(ns);
        ns = getGlobalTime(ns);

        return ns;
      }),
    ];
  });
  return responsesData;
};

export const getEndTime = (startTime, duration) => {
  if (duration) {
    let date = new Date();
    date.setHours(parseInt(startTime.split(":")[0]));
    date.setMinutes(parseInt(startTime.split(":")[1]));
    date.setMinutes(date.getMinutes() + duration);
    return `${date.getHours()} : ${date.getMinutes()}`;
  }
};
