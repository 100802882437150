import React, { useEffect, useState } from "react";
import { Box, Grid, Typography, Divider, Button, Link } from "@mui/material";
import MentorExperience from "./MentorExperience";
import JsxParser from "react-jsx-parser";
import VideoPlayerComponent from "../../../common/videoPlayer";
import constants from "../../../constants";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { USER_TYPES } from "../../../utils/userTypes";
import RolesCard from "../RolesCard";
import { getCurrentDate } from "../../../utils/date";
import { RICH_TEXT_EDITOR_INLINE_CONTROLS, RICH_TEXT_EDITOR_MAIN_CONTROLS } from "../../../constants/plugin-constants";
import ScriptEditor from "../../../common/ScriptEditor";
import DeleteIcon from "@mui/icons-material/Delete";
import VideocamRoundedIcon from "@mui/icons-material/VideocamRounded";
import MessageHandlerModal from "../../../common/modal/MessageHandler";
import CompleteYourProfile from "../../../common/CompleteYourProfile";
import PlayCircleRoundedIcon from "@mui/icons-material/PlayCircleRounded";
import { AddMediaWrapper } from "./styles";
import AddVideo from "../../../common/AddVideo";
import UploadImageModal from "../../../common/UploadImageModal";
import UploadVideoModal from "../../../common/UploadVideoModal";

export default function ProfileAbout({
	mentorData,
	handleDeleteVideo,
	setBrowseFlag,
	showDeleteButton,
	setShowDeleteButton,
	candidateProfileId,
	inputChange,
	handleUpdateAssetsUrl,
	payload,
	setHarmonicOpen,
}) {
	const userType = useSelector(store => store?.auth?.userType);
	const [deleteConfirmPopup, setDeleteConfirmPopup] = useState(false);
	const [openVideoModal, setOpenVideoModal] = useState(false);
	const [mentorDetails, setMentorDetails] = useState(mentorData);
	const [videoUploadUrl, setVideoUploadUrl] = useState(mentorData?.videoUrl);
	const params = useParams();
	const paramsId = params.id;

	const getRoleValue = () => {
		if (userType === USER_TYPES.MENTOR_USER) {
			return "Mentor";
		} else if (userType === USER_TYPES.ADVISOR_USER) {
			return "Advisor";
		} else if (userType === USER_TYPES.CANDIDATE_USER) {
			return "Candidate";
		} else if (userType === USER_TYPES.INVESTOR_USER) {
			return "Investor";
		} else if (userType === USER_TYPES.PARTNER_USER) {
			return "Partner";
		} else if (userType === USER_TYPES.PROFESSOR_USER) {
			return "Instructor";
		} else if (userType === USER_TYPES.STUDENT_USER) {
			return "Student";
		} else if (userType === USER_TYPES.STARTUP_USER) {
			return "Teammate";
		} else {
			return "Founder";
		}
	};

	useEffect(() => {
		if (videoUploadUrl && videoUploadUrl !== null && videoUploadUrl !== "") {
			setShowDeleteButton(true);
		}
	}, []);

	useEffect(() => {
		setVideoUploadUrl(mentorData?.videoUrl);
		setMentorDetails(mentorData);
	}, [mentorData]);

	const deleteVideoHandler = () => {
		setShowDeleteButton(false);
		setBrowseFlag(false);
		setDeleteConfirmPopup(false);
		handleDeleteVideo();
	};

	//const renderExpertiseType = data => {
	//  if (data && data.expertise) {
	//    try {
	//      return JSON.parse(data?.expertise).join(", ");
	//    } catch (error) {
	//      return data.expertise;
	//    }
	//  } else {
	//    return "--";
	//  }
	//};
	const renderExpertiseType = data => {
		if (data) {
			try {
				return JSON.parse(data).join(", ");
			} catch (error) {
				return data;
			}
		} else {
			return "--";
		}
	};

	const renderSuperpowers = data => {
		if (data && data.superpower) {
			try {
				return JSON.parse(data?.superpower).join(", ");
			} catch (error) {
				return data.superpower;
			}
		} else {
			return "--";
		}
	};
	return (
		<>
			<Grid container sx={{ mt: 2, pl: { md: 4 }, pr: { md: 6 } }}>
				<Grid
					item
					xs={12}
					md={8}
					sx={{
						display: "flex",
						width: 1,
						flexDirection: "row",
						gap: 2,
						pr: { md: 2 },
					}}
				>
					<Box sx={{ width: 1 }}>
						<Typography variant="largeTitle" sx={theme => ({ color: theme.palette.secondary.korophKoal })}>
							About{" "}
						</Typography>
						{/*{mentorData?.about !== "" &&
            mentorData?.about != "<p><br></p>" ? (
              <Divider sx={{ display: { xs: "block", md: "none" }, py: 1 }} />
            ) : (
              ""
            )}*/}
						<Box sx={{ mt: 3 }}>
							<Typography
								variant="body_large"
								sx={theme => ({
									color: theme.palette.secondary.outline,
								})}
							>
								{mentorData?.about !== "" && mentorData?.about !== "<p><br></p>" ? (
									//<ScriptEditor
									//  initialValue={mentorData?.about}
									//  onChange={() => {}}
									//  readOnly={true}
									//  toolbar={false}
									//  inlineToolbar={false}
									//  inlineToolbarControls={RICH_TEXT_EDITOR_INLINE_CONTROLS}
									//  controls={RICH_TEXT_EDITOR_MAIN_CONTROLS}
									///>
									<span
										dangerouslySetInnerHTML={{
											__html: mentorData?.about,
										}}
									/>
								) : (
									<Box sx={{ mt: 2 }}>
										<Typography>{"--"}</Typography>
									</Box>
								)}
							</Typography>
						</Box>

						{!videoUploadUrl && !paramsId && (
							<>
								<Box sx={{ mt: 2 }}>
									<Box>
										<Typography
											variant="Body/16pxBold"
											sx={theme => ({
												color: theme.palette.secondary.korophKoal,
											})}
										>
											My Story Video{" "}
										</Typography>
									</Box>
									<Box sx={{ pt: 1 }}>
										<Typography
											variant="body_large"
											sx={theme => ({
												color: theme.palette.secondary.outline,
											})}
										>
											Upload a video about your story so other StartupOS members can get to know you better.
										</Typography>
									</Box>
								</Box>
								<AddVideo
									height={400}
									handelAddMedia={() => {
										setOpenVideoModal(true);
									}}
								/>
							</>
						)}

						{videoUploadUrl && (
							<>
								{!paramsId && (
									<Box sx={{ mt: 2 }}>
										<Box>
											<Typography
												variant="Body/16pxBold"
												sx={theme => ({
													color: theme.palette.secondary.korophKoal,
												})}
											>
												My Story Video{" "}
											</Typography>
										</Box>
										<Box sx={{ pt: 1 }}>
											<Typography
												variant="body_large"
												sx={theme => ({
													color: theme.palette.secondary.outline,
												})}
											>
												Upload a video about your story so other StartupOS members can get to know you better.
											</Typography>
										</Box>
									</Box>
								)}

								<Box sx={{ position: "relative" }}>
									<Box
										sx={theme => ({
											borderRadius: theme.shape.standard.borderRadius,
											boxShadow: "none",
											overflow: "hidden",
											mt: 3,
										})}
									>
										<Box>
											<VideoPlayerComponent height="auto" videoUrl={videoUploadUrl} />
										</Box>
									</Box>
									<Box
										sx={{
											mr: 3,
											background:
												"linear-gradient(0deg, rgba(106, 117, 117, 0.02), rgba(106, 117, 117, 0.02)), linear-gradient(0deg, rgba(245, 248, 250, 0.05), rgba(245, 248, 250, 0.05)), #FCFCFC",
											boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
											borderRadius: "4px",
											width: "max-content",
											position: "absolute",
											top: "24px",
											right: 0,
										}}
									>
										{!paramsId && (
											<Button variant="outlined" startIcon={<VideocamRoundedIcon />} onClick={() => setOpenVideoModal(true)}>
												{videoUploadUrl ? "Edit Media" : "Add media"}
											</Button>
										)}
									</Box>
								</Box>
							</>
						)}
						{/*{videoUploadUrl !== "" && <Divider sx={{ py: 1 }} />}*/}
						{/*<Box sx={{ py: 3 }}>
              <Typography variant="title_large">StartupOS Roles</Typography>
              <Box sx={{ mt: 3 }}>
                {(mentorData.userType === USER_TYPES.STARTUP_OWNER ||
                  mentorData.userType === USER_TYPES.STARTUP_USER ||
                  userType === USER_TYPES.STUDENT_USER) && (
                  <RolesCard
                    title={"Startups"}
                    startupRole={getRoleValue()}
                    startupName={mentorData?.companyModel?.companyName}
                    city={mentorData?.city}
                    state={mentorData?.state}
                    zipcode={mentorData?.zipcode}
                    startDate={getCurrentDate()}
                    endDate={getCurrentDate()}
                    id={mentorData?.companyModel?.id}
                  />
                )}
              </Box>
              <Box sx={{ mt: 3 }}>
                {(userType === USER_TYPES.MENTOR_USER ||
                  userType === USER_TYPES.PROFESSOR_USER) && (
                  <RolesCard
                    title={"Mentor"}
                    startupRole={null}
                    startupName={mentorData?.companyModel?.companyName}
                    city={mentorData?.city}
                    state={mentorData?.state}
                    zipcode={mentorData?.zipcode}
                    startDate={getCurrentDate()}
                    endDate={getCurrentDate()}
                    id={mentorData?.companyModel?.id}
                  />
                )}
              </Box>
            </Box>*/}
					</Box>

					<Divider orientation="vertical" flexItem sx={{ display: { xs: "none", md: "block" } }} />
				</Grid>

				<Grid
					item
					xs={12}
					md={4}
					sx={{
						display: "flex",
						flexDirection: "column",
						gap: 1,
						pl: { md: 1 },
						pt: { xs: 3, md: 0 },
					}}
				>
					<Box>
						<Typography
							variant="largeTitle"
							sx={theme => ({
								color: theme.palette.text.korophKoal,
								px: { xs: 0, md: 1 },
							})}
						>
							Details
						</Typography>
					</Box>

					<Box sx={{ pt: 2, px: { xs: 0, md: 1 } }}>
						<Typography variant="label_xlarge" sx={theme => ({ color: theme.palette.text.atomic })}>
							{"LinkedIn Personal Profile"}
						</Typography>
					</Box>
					<Box sx={{ px: { xs: 0, md: 1 } }}>
						{/*<Typography
              variant="body_large"
              sx={theme => ({ color: theme.palette.text.lightOnsurface })}
            >
              {mentorData?.linkedInUrl || "--"}
            </Typography>*/}
						{mentorData?.linkedInUrl ? (
							<Link
								variant="body_large"
								target="_blank"
								onClick={() => window.open(mentorData?.linkedInUrl)}
								rel="noopener"
								sx={theme => ({
									color: theme.palette.text.link,
									cursor: "pointer",
									display: "flex",
									maxWidth: 1,
									overflow: "hidden",
									wordBreak: "break-word",
								})}
							>
								{mentorData?.linkedInUrl}
							</Link>
						) : (
							<Box>
								<Typography variant="body_large" sx={theme => ({ color: theme.palette.text.lightOnsurface })}>
									{"--"}
								</Typography>
							</Box>
						)}
					</Box>

					{!paramsId && mentorData?.linkedInUrl && (
						<Box sx={{ my: 1, px: { xs: 0, md: 1 } }}>
							<Button variant="outlined" onClick={() => setHarmonicOpen(true)}>
								Copy profile from Linkedin
							</Button>
						</Box>
					)}

					{!paramsId && mentorData?.linkedInUrl === "" && (
						<Box sx={{ pt: 1 }}>
							<CompleteYourProfile
								title="Complete your profile faster"
								sub_title="Providing your personal LinkedIn profile link will autofill key information here needed to complete your profile."
							/>
						</Box>
					)}
					<Box
						sx={{
							display: "flex",
							width: 1,
							gap: 2,
							flexDirection: "row",
							justifyContent: "space-between",
							px: { xs: 0, md: 1 },
						}}
					>
						<Box
							sx={{
								display: "flex",
								gap: 1,
								flexDirection: "column",
								mt: 1,
							}}
						>
							<Box>
								<Typography variant="label_xlarge" sx={theme => ({ color: theme.palette.text.atomic })}>
									{"Startup Superpowers"}
								</Typography>
							</Box>
							<Box>
								<Typography variant="body_large" sx={theme => ({ color: theme.palette.text.lightOnsurface })}>
									{renderSuperpowers(mentorData)}
								</Typography>
							</Box>
							<Box sx={{ pt: 1 }}>
								<Typography variant="label_xlarge" sx={theme => ({ color: theme.palette.text.atomic })}>
									{"My Expertise"}
								</Typography>
							</Box>
							<Box>
								<Typography variant="body_large" sx={theme => ({ color: theme.palette.text.lightOnsurface })}>
									{renderExpertiseType(mentorData?.expertise)}
								</Typography>
							</Box>
							<Box sx={{ pt: 1 }}>
								<Typography variant="label_xlarge" sx={theme => ({ color: theme.palette.text.atomic })}>
									{"Phone"}
								</Typography>
							</Box>
							<Box
								sx={{
									width: 1,
									display: "grid",
								}}
							>
								<Typography
									variant="body_large"
									sx={theme => ({
										color: theme.palette.text.lightOnsurface,
										overflow: "hidden",
										textOverflow: "ellipsis",
										width: "inherit",
									})}
								>
									{mentorData?.phone || "--"}
								</Typography>
							</Box>
							{/*<Typography
                variant="title_medium"
                sx={theme => ({ color: theme.palette.secondary.korophKoal })}
              >
                {"Startup Experience"}
              </Typography>
              <Typography
                variant="body_large"
                sx={theme => ({ color: theme.palette.text.lightOnsurface })}
              >
                {mentorData?.experienceDetails
                  ? mentorData
                      ?.experienceDetails
                  : "-"}
              </Typography>
              <Typography
                variant="title_medium"
                sx={theme => ({ color: theme.palette.secondary.korophKoal })}
              >
                {"Certifications"}
              </Typography>
              <Typography
                variant="body_large"
                sx={theme => ({ color: theme.palette.text.lightOnsurface })}
              >
                {mentorData?.certifications
                  ? mentorData?.certifications
                  : "-"}
              </Typography>*/}
						</Box>
					</Box>

					<MentorExperience isEdit={false} harmonicData={payload} />
				</Grid>
				<MessageHandlerModal
					isOpen={deleteConfirmPopup}
					onCancel={() => setDeleteConfirmPopup(false)}
					onOk={() => {
						deleteVideoHandler();
					}}
					okText="Delete"
					cancelText={"No thanks"}
					heading="Delete Video"
					text={"Are you sure you want to delete the video?"}
					messageType="primary"
				/>
				<UploadVideoModal
					open={openVideoModal}
					photo={videoUploadUrl}
					onClose={() => setOpenVideoModal(false)}
					setPhoto={setVideoUploadUrl}
					title="Elevator Pitch Video"
					selectFileText="Select a file to upload Elevator Pitch video"
					dimensionText="Accepted file types .mp4, .mov, .3gp, .webm, .ogg (Max 2GB)"
					aspectRatio={1 / 1}
					imageType="USER_STORY"
					fileType={{
						"video/3gpp": [".3gp"],
						"video/mp4": [".mp4"],
						"video/mov": [".mov"],
						"video/webm": [".webm"],
						"video/ogg": [".ogg"],
					}}
					handleUpdateAssetsUrl={handleUpdateAssetsUrl}
					source="PERSONAL_PROFILE"
				/>
			</Grid>
		</>
	);
}
