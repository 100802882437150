import React, { useEffect, useState } from "react";
import DialogComponent from "../../../PowerUP/IdeaValidation/Components/Dialog";
import {
  Box,
  Divider,
  Typography,
  CircularProgress,
  Grid,
  Button,
} from "@mui/material";
import theme from "../../../../../constants/Theme";
import {
  getDetails,
  validateAdvisorPluginInput,
  getPluginResponse,
  addPluginResponse,
  updatePluginResponse,
  sanitizeDateTimePayload,
  parseDateTimePayload,
} from "../Functions/common";
import DateTimeSlotPicker from "../../../../../common/DateTimeslotPicker";
import MobileDatePopover from "../Components/MobileDatePopover";
import LoadingButton from "../../../../../common/Loadingbutton";
import MeetingDetailComponent from "../../../../../components/Workspace/PowerUp/MeetingDetails";
import SuccessPopup from "../Components/SuccessPopup";
import DatePickerWarning from "../Components/DatePickerWarning";

const ScheduleMobileSession = ({
  showAdvisorPluginPopup,
  setShowAdvisorPluginPopup,
}) => {
  const [details, setDetails] = useState(null);
  const [showDatePicker, setShowDatePicker] = useState(true);

  const [selectedDate, setSelectedDate] = useState(null);
  const [responses, setResponses] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [userInputs, setUserInputs] = useState({
    agenda: "",
    duration: "",
    email: "jmessina+1@startupos.com",
  });
  const [resultId, setResultId] = useState(null);
  const [openSuccessPopup, setOpenSuccessPopup] = useState(false);
  const [timeDrawer, setTimeDrawer] = useState(false);
  const contRef = React.useRef();

  useEffect(() => {
    getDetails()
      .then(data => {
        setDetails(data.data);
      })
      .catch(err => console.log(err));

    getPluginResponse()
      .then(result => {
        if (result.code === 200) {
          if (result.data.length) {
            const data = result.data[0];
            setResultId(data.id);
            setUserInputs({
              agenda: data.agenda,
              duration: data.duration + " min",
              email: data.email,
            });
            setResponses(parseDateTimePayload(data.responseDetails));
          }
        }
      })
      .catch(err => console.log(err));
  }, []);

  useEffect(() => {
    if (details) {
      for (const item of details.preRequisites) {
        if (item?.companyTask?.taskStatus !== "COMPLETED") {
          setShowDatePicker(false);
        }
      }
    }
  }, [details]);

  const handleconfirmDisable = () => {
    if (currentStep === 1) {
      return !responses.length;
    } else {
      if (validateAdvisorPluginInput(userInputs)) {
        return false;
      } else {
        return true;
      }
    }
  };

  const handleNext = () => {
    if (currentStep === 1) {
      setCurrentStep(2);
    } else {
      //Api call & close popup
      setButtonLoading(true);
      if (resultId) {
        updatePluginResponse(resultId, {
          ...userInputs,
          duration: parseInt(userInputs.duration.slice(0, 2)),
          responseDetails: sanitizeDateTimePayload(responses),
        })
          .then(result => {
            if (result.code === 200) {
              setButtonLoading(false);
              setOpenSuccessPopup(true);
            }
          })
          .catch(err => {
            setButtonLoading(false);
            console.log(err);
          });
      } else {
        addPluginResponse({
          ...userInputs,
          duration: parseInt(userInputs.duration.slice(0, 2)),
          responseDetails: sanitizeDateTimePayload(responses),
        })
          .then(result => {
            if (result.code === 200) {
              setButtonLoading(false);

              setOpenSuccessPopup(true);
            }
          })
          .catch(err => {
            setButtonLoading(false);
            console.log(err);
          });
      }
    }
  };

  const handleBack = () => {
    setCurrentStep(1);
  };

  const handlePopupsClose = () => {
    setOpenSuccessPopup(false);
    setShowAdvisorPluginPopup(false);
  };

  const successContentComponent = () => {
    return (
      <Box>
        <Typography sx={{ textAlign: "center", px: 10 }}>
          Your advisor will be notified and select a time that works or will
          suggest a new time.
        </Typography>
      </Box>
    );
  };

  return (
    <DialogComponent
      isOpen={showAdvisorPluginPopup}
      onClose={() => setShowAdvisorPluginPopup(false)}
      title={"Schedule your Advisor Plugin"}
      titleVariant={"title_large"}
      contentStyle={{ px: 0 }}
      maxWidth={currentStep === 1 ? (selectedDate ? "lg" : "md") : "md"}
      fullWidth
      sx={{ display: openSuccessPopup ? "none" : "block" }}
      dialogAction={
        <Box display={"flex"} gap={2}>
          {currentStep === 1 ? (
            <MobileDatePopover
              responses={responses}
              setResponses={setResponses}
              mobileUI
            />
          ) : (
            <Button variant="outlined" onClick={handleBack}>
              Back
            </Button>
          )}

          <LoadingButton
            disabled={handleconfirmDisable()}
            loading={buttonLoading}
            onClick={() => handleNext()}
          >
            Confirm
          </LoadingButton>
        </Box>
      }
      disableBackDropClick
    >
      {details ? (
        <Box ref={contRef}>
          <Divider />

          {currentStep === 1 ? (
            <Box sx={{ mt: 3, px: 3 }}>
              <Grid container spacing={2}>
                <Grid item md={selectedDate ? 7 : 6}>
                  <Box>
                    <Typography variant="headline6">
                      Select dates & times you are available
                    </Typography>
                    <br />
                    <Typography variant="bodyMedium">
                      We recommend choosing your available time slots no more
                      than 7 days out from the day you send the invite.
                    </Typography>
                  </Box>
                  {showDatePicker ? (
                    <DateTimeSlotPicker
                      responses={responses}
                      setResponses={setResponses}
                      minTimeSlots={1}
                      maxTimeSlots={5}
                      selectedDate={selectedDate}
                      setSelectedDate={setSelectedDate}
                      containerStyles={theme => ({
                        background:
                          "linear-gradient(0deg, rgba(118, 73, 160, 0.05), rgba(118, 73, 160, 0.05)), #FCFCFC",
                        border: `1px solid ${theme.palette.secondary.light}`,
                        borderRadius: theme.shape.standard3.borderRadius,
                        p: 1,
                        mt: 3,
                      })}
                      mobileUI={true}
                      timeDrawer={timeDrawer}
                      setTimeDrawer={setTimeDrawer}
                    />
                  ) : (
                    <DatePickerWarning
                      containerStyles={theme => ({
                        background:
                          "linear-gradient(0deg, rgba(118, 73, 160, 0.05), rgba(118, 73, 160, 0.05)), #FCFCFC",
                        border: `1px solid ${theme.palette.secondary.light}`,
                        borderRadius: theme.shape.standard3.borderRadius,
                        p: 1,
                        mt: 3,
                      })}
                    />
                  )}
                </Grid>
              </Grid>
            </Box>
          ) : (
            <MeetingDetailComponent
              selectedDateTimes={responses}
              userInputs={userInputs}
              setUserInputs={setUserInputs}
            />
          )}
        </Box>
      ) : (
        <Box justifyContent={"center"} sx={{ display: "flex", my: 5 }}>
          <CircularProgress />
        </Box>
      )}

      <SuccessPopup
        open={openSuccessPopup}
        close={handlePopupsClose}
        ContentComponent={successContentComponent}
      />
    </DialogComponent>
  );
};

export default ScheduleMobileSession;
