import * as React from "react";
import PropTypes from "prop-types";
import { alpha, Box, Typography } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
function CustomizedProgressBars(props) {
  const [value, setvalue] = useState(0); // 0-100 ~ integer representation of a percent.
  useEffect(() => {
    setvalue(
      props.amountComplete && props.amountComplete > 0
        ? props.amountComplete > 1
          ? props.amountComplete
          : props.amountComplete * 100
        : 0
    );
  }, [props.amountComplete]);

  const colorMap = type => {
    switch (type) {
      case "primary":
        return {
          bar: theme => theme.palette.primary.main,
          background: theme => alpha(theme.palette.primary.main, 0.2),
        };
      case "orange":
        return {
          bar: theme =>
            `linear-gradient(92.58deg, ${theme.palette.secondary.progressGradient2} 0%, ${theme.palette.secondary.progressGradient1} 100%)`,
          background:
            "linear-gradient(92.58deg, rgba(233, 115, 0, 0.15) 0%, rgba(255, 209, 65, 0.15) 100%)",
        };
      case "success":
        return {
          bar: theme => theme.palette.success.main,
          background: theme => alpha(theme.palette.success.main, 0.2),
        };
      case "danger":
        return {
          bar: theme => theme.palette.danger.main,
          background: theme => alpha(theme.palette.danger.main, 0.2),
        };
      default:
        return {
          bar: theme => theme.palette.primary.main,
          background: theme => alpha(theme.palette.primary.main, 0.2),
        };
    }
  };

  const getColor = () => {
    if (
      props.thresholdProps?.percent &&
      props.thresholdProps?.direction &&
      props.thresholdProps?.barColor
    ) {
      switch (props.thresholdProps.direction) {
        case ">":
          return props.amountComplete > props.thresholdProps?.percent
            ? colorMap(props.thresholdProps?.barColor)
            : colorMap(props?.barColor);
        case "<":
          return props.amountComplete < props.thresholdProps?.percent
            ? colorMap(props.thresholdProps?.barColor)
            : colorMap(props?.barColor);
        case ">=":
          return props.amountComplete >= props.thresholdProps?.percent
            ? colorMap(props.thresholdProps?.barColor)
            : colorMap(props?.barColor);
        case "<=":
          return props.amountComplete <= props.thresholdProps?.percent
            ? colorMap(props.thresholdProps?.barColor)
            : colorMap(props?.barColor);
        default:
          return colorMap(props?.barColor);
      }
    } else {
      return colorMap(props?.barColor);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: 1,
        width: "100%",
      }}
    >
      <LinearProgress
        variant="determinate"
        value={value}
        sx={{
          width: "100%",
          height: "5px",
          [`&.${linearProgressClasses.colorPrimary}`]: {
            background: getColor().background,
          },
          [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 3,
            background: getColor().bar,
          },
        }}
      />
      {props.displayPercent && (
        <Typography variant="label_large">{Math.round(value)}%</Typography>
      )}
    </Box>
  );
}

CustomizedProgressBars.propTypes = {
  amountComplete: PropTypes.number,
  displayPercent: PropTypes.bool,
  barColor: PropTypes.string,
  thresholdProps: PropTypes.shape({
    percent: PropTypes.number,
    direction: PropTypes.string,
    barColor: PropTypes.any,
  }),
};
export default CustomizedProgressBars;
