import { Box, Typography } from "@mui/material";
import React, { Fragment, useContext, useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import { ResourceCenterContext } from "../Stores/ResourceCenterStore";

import "../Common/styles.css";
import ArticleAttachments from "./ArticleAttachments";
import { handleMixPanel } from "../../../utils/mixPanelEventHandling";

const parseHTMLString = (htmlString) => {
	const parser = new DOMParser();
	const parsedHtml = parser.parseFromString(htmlString, "text/html");

	const elements = parsedHtml.body.children;

	return Array.from(elements).map((element, index) => {
		const tagName = element.tagName.toLowerCase();
		switch (tagName) {
			case "h1":
				return (
					<Typography key={index} variant={"h1"} component={"h1"}>
						{element.innerHTML}
					</Typography>
				);
			case "h2":
				return (
					<Typography key={index} variant={"h2"} component={"h2"}>
						{element.innerHTML}
					</Typography>
				);
			case "h3":
				return (
					<Typography key={index} variant={"h3"} component={"h3"}>
						{element.innerHTML}
					</Typography>
				);
			case "h4":
				return (
					<Typography key={index} variant={"h4"} component={"h4"}>
						{element.innerHTML}
					</Typography>
				);
			default:
				const ElementComponent = () => <div dangerouslySetInnerHTML={{ __html: element.outerHTML }} />;
				return <ElementComponent key={index} />;
		}
	});
};

//{
//	"id": 15841055149197,
//	"url": "https://startupos.zendesk.com/api/v2/help_center/en-us/articles/15841055149197.json",
//	"html_url": "https://startupos.zendesk.com/hc/en-us/articles/15841055149197-Hello-World",
//	"author_id": 15839005510541,
//	"comments_disabled": false,
//	"draft": true,
//	"promoted": false,
//	"position": 0,
//	"vote_sum": 0,
//	"vote_count": 0,
//	"section_id": 16091724496781,
//	"created_at": "2023-05-16T21:10:11Z",
//	"updated_at": "2023-05-24T16:37:53Z",
//	"name": "Hello World",
//	"title": "Hello World",
//	"source_locale": "en-us",
//	"locale": "en-us",
//	"outdated": false,
//	"outdated_locales": [],
//	"edited_at": "2023-05-16T21:10:11Z",
//	"user_segment_id": 15839343110413,
//	"permission_group_id": 15839357998605,
//	"content_tag_ids": [],
//	"label_names": [],
//	"body": "<p>Hello world article.</p>\n<p>Here we are testing the capabilities of Zendesk and what information is returned on API requests.</p>\n<p> </p>\n<h1>Heading 1</h1>\n<h2>Heading 2</h2>\n<h3>Heading 3</h3>\n<h4>Heading 4</h4>\n<p><strong>Bold Text</strong></p>\n<p><em>Italic Text</em></p>\n<p><span class=\"wysiwyg-underline\">Underlined Text</span></p>\n<pre>Code snippet</pre>\n<ol>\n<li>Ordered list</li>\n<li>Two\n<ol>\n<li>subsection of 2\n<ol>\n<li>subsection of 2, 2</li>\n</ol>\n</li>\n</ol>\n</li>\n</ol>\n<ul>\n<li>Unordered list</li>\n<li>2nd bullet\n<ul>\n<li>sub-bullet\n<ul>\n<li>sub-sub-bullet</li>\n</ul>\n</li>\n</ul>\n</li>\n</ul>"
//  }

function ArticlePage() {
	const { article_id } = useParams();
	const { currentArticle, currentArticleAttachments } = useContext(ResourceCenterContext);
	const [content, setcontent] = useState(null);
	useEffect(() => {
		if (currentArticle && currentArticle?.body) {
			try {
				setcontent(parseHTMLString(currentArticle?.body));
			} catch (e) {
				console.log("Failed processing article content to render.", e);
			}
		}
	}, [currentArticle]);

	useEffect(() => {
		handleMixPanel("Article Viewed", { "Artical id": article_id })
	}, [])

	return (
		<Fragment>
			<Box
				sx={{
					px: 4,
					py: 6,
					backgroundColor: "white",
					borderRadius: "40px",
					boxShadow: "0px 10px 85px rgba(0, 0, 0, 0.09)",
					mb: 3,
				}}
			>
				<Typography component={"h1"} variant="h1" sx={{ mb: 3, color: (theme) => theme.palette.primary.main }}>
					{currentArticle?.title}
				</Typography>
				{content}
			</Box>
			{currentArticleAttachments && currentArticleAttachments?.count > 0 && <ArticleAttachments />}
		</Fragment>
	);
}

export default ArticlePage;
