import React, { useState, useEffect } from "react";
import { Grid, Box, useTheme, Typography, Avatar } from "@mui/material";
import useDimensions from "react-cool-dimensions";
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import { NEW_STARTUP_LOGO, PAGE_STARTUP_GRID_ICON } from "../../../constants";
import Arr from "./Wrappers/Arr";
import BurnRunway from "./Wrappers/BurnRunway";
import Customers from "./Wrappers/Customers";
import NetArr from "./Wrappers/NetArr";
import Mrr from "./Wrappers/Mrr";
import Payback from "./Wrappers/Payback";
import Ownership from "./Wrappers/Ownership";
import AmountRaised from "./Wrappers/AmountRaised";
import Revenue from "./Wrappers/Revenue";
import Acv from "./Wrappers/Acv";
import SmExpenseVsNewSales from "./Wrappers/SmExpenseVsNewSales";
import { handleMixPanel } from "../../../utils/mixPanelEventHandling";
import MetricCard from "./Charts/MetricCard";
import { setBIDashboardData } from "../../../modules/actions/biDashboardActions";
import StartupGridSettings from "./StartupGridSettings";
import StartupGridEdit from "./StartupGridEdit";
import PartnerIntegration from "./StartupGridSettings/PartnerIntegration";
import Headcount from "./Charts/MetricCard/Headcount";
import RefreshData from "./Charts/RefreshData";
import BurnMultiple from "./Wrappers/BurnMultiple";
import MagicNumber from "./Wrappers/MagicNumber";
import { useLocation } from "react-router-dom";
import NewSingleSelect from "../../../common/NewSingleSelect";

function Dashboard() {
	const theme = useTheme();
	const dispatch = useDispatch();
	const [isSmallWidth, setIsSmallWidth] = useState(false);
	const { observe, unobserve, width, height, entry } = useDimensions({
		breakpoints: { SM: 650 },
		updateOnBreakpointChange: true,
	});
	const { companyName, isAdmin } = useSelector((state) => state?.auth);
	const companyLogoUrl = useSelector((state) => state?.auth?.companyLogoUrl);

	// const [showPlanModal, setShowPlanModal] = useState(false);
	const annualRecurringRevenue = useSelector((store) => store?.biDashboard?.annualRecurringRevenue);
	const cacPayback = useSelector((store) => store?.biDashboard?.cacPayback);
	const burnAndRunway = useSelector((store) => store?.biDashboard?.burnAndRunway);
	const customers = useSelector((store) => store?.biDashboard?.customers);
	const location = useLocation();
	const startupDetails = location?.state?.startupDetails;
	const [currentStage, setCurrentStage] = useState("");
	const searchParams = new URLSearchParams(location.search);
	const code = searchParams.get("code");
	const state = searchParams.get("state");

	const loadingAnnualRecurringRevenue = useSelector((store) => store?.biDashboard?.loadingAnnualRecurringRevenue);
	const loadingCacPayback = useSelector((store) => store?.biDashboard?.loadingCacPayback);
	const loadingBurnAndRunway = useSelector((store) => store?.biDashboard?.loadingBurnAndRunway);
	const loadingCustomers = useSelector((store) => store?.biDashboard?.loadingCustomers);
	const companyStage = useSelector((store) => store?.biDashboard?.currentStage);

	const { isPremiumPlanAccess, userType } = useSelector((store) => store?.auth);

	useEffect(() => {
		handleMixPanel("Grid Viewed", { subscription_status: isPremiumPlanAccess ? "Subscribed" : "Not Subscribed", user_type: userType });
		closeAllDialog();
		observe();
	}, []);
	useEffect(() => {
		setCurrentStage(companyStage);
	}, [companyStage]);

	useEffect(() => {
		if (width >= 650) {
			setIsSmallWidth(false);
		} else {
			setIsSmallWidth(true);
		}
	}, [width]);

	const closeAllDialog = () => {
		dispatch(
			setBIDashboardData({
				openAcv: false,
				openCapData: false,
				openAnnualRecurringRevenue: false,
				openBurnAndRunway: false,
				openCustomers: false,
				openMonthlyRecurringRevenue: false,
				openNetNewArr: false,
				openCacPayback: false,
				openRevenue: false,
				openSmExpense: false,
				openSettings: false,
				openConnect: false,
				openEdit: false,
				openPlaidAccountsSelect: false,
				openBurnMultiple: false,

				...(!code && !state && { openPartnerIntegration: false }),
			})
		);
	};

	return (
		<>
			<Helmet>
				<meta charSet="utf-8" />
				<title>StartupOS - StartupGrid</title>
			</Helmet>

			<Box
				ref={observe}
				sx={{
					px: {
						lg: 8,
						md: 4,
						xs: 2,
					},
					pb: "30px",
					display: "flex",
					flexDirection: "column",
					gap: "32px",
					maxWidth: "1920px", // Figma specifies max content width
					margin: "0 auto",
				}}
			>
				<Box display="flex" alignItems="center">
					<Box component={"img"} src={PAGE_STARTUP_GRID_ICON} alt="dashbaord" sx={{ width: "30px", height: "30px" }} />
					<Typography ml={1.5} variant="Display/xs/Semibold">
						Startup Grid
					</Typography>
					{(!startupDetails || isAdmin) && (
						<Box
							display={"flex"}
							columnGap={1}
							flexWrap={"wrap"}
							mt={{ xs: 2, sm: 0 }}
							justifyContent={{ xs: "flex-start", sm: "flex-end" }}
							alignItems={"center"}
							ml={"auto"}
						>
							<RefreshData />
							<StartupGridEdit />
							<StartupGridSettings />
						</Box>
					)}
				</Box>
				<Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
					{
						<Box display="flex" alignItems="center">
							<Avatar
								src={startupDetails ? startupDetails?.logoUrl || NEW_STARTUP_LOGO : companyLogoUrl || NEW_STARTUP_LOGO}
								alt="companylogo"
								sx={{ width: "30px", height: "30px" }}
							/>
							<Typography ml={1} variant="Text/xl/Semibold">
								{startupDetails ? startupDetails?.companyName : companyName}
							</Typography>
						</Box>
					}
					<Box sx={{ width: "180px" }}>
						<NewSingleSelect
							id={"stage"}
							sx={{ height: "48px", background: (theme) => theme.palette.gray.light }}
							onInputChange={(event) => {
								setCurrentStage(event.target.value);
								dispatch(setBIDashboardData({ currentStage: event.target.value }));
							}}
							selectedValue={currentStage}
							inputKey={"currentStage"}
							options={["Pre-Seed", "Seed", "Series A", "Growth"]}
							borderNone={true}
						/>
					</Box>
				</Box>
				<Box sx={{ borderRadius: "24px", backgroundColor: theme.palette.secondary.white }}>
					<Grid container>
						<Grid item xs={6} lg={2.4}>
							<MetricCard
								name={"ARR"}
								onClick={() => {
									handleMixPanel("Grid ARR Viewed", {
										subscription_status: isPremiumPlanAccess ? "Subscribed" : "Not Subscribed",
										user_type: userType,
									});
									dispatch(setBIDashboardData({ openAnnualRecurringRevenue: true }));
								}}
								data={annualRecurringRevenue}
								loading={loadingAnnualRecurringRevenue}
								isGridOwner={!startupDetails}
							/>
						</Grid>
						<Grid item xs={6} lg={2.4}>
							<MetricCard
								name={"CAC Payback"}
								onClick={() => {
									handleMixPanel("Grid CAC Payback Viewed", {
										subscription_status: isPremiumPlanAccess ? "Subscribed" : "Not Subscribed",
										user_type: userType,
									});
									dispatch(setBIDashboardData({ openCacPayback: true }));
								}}
								data={cacPayback}
								loading={loadingCacPayback}
								isGridOwner={!startupDetails}
							/>
						</Grid>
						<Grid item xs={6} lg={2.4}>
							<MetricCard
								name={"Burn & Runway"}
								onClick={() => {
									handleMixPanel("Grid Burn Viewed", {
										subscription_status: isPremiumPlanAccess ? "Subscribed" : "Not Subscribed",
										user_type: userType,
									});
									dispatch(setBIDashboardData({ openBurnAndRunway: true }));
								}}
								data={burnAndRunway}
								loading={loadingBurnAndRunway}
								isGridOwner={!startupDetails}
							/>
						</Grid>
						<Grid item xs={6} lg={2.4}>
							<MetricCard
								name={"Customers"}
								onClick={() => {
									handleMixPanel("Grid Customers Viewed", {
										subscription_status: isPremiumPlanAccess ? "Subscribed" : "Not Subscribed",
										user_type: userType,
									});
									dispatch(setBIDashboardData({ openCustomers: true }));
								}}
								data={customers}
								loading={loadingCustomers}
								isGridOwner={!startupDetails}
							/>
						</Grid>
						<Grid item xs={6} lg={2.4}>
							<Headcount />
						</Grid>
					</Grid>
				</Box>
				<Grid container spacing={{ xs: 3, xl: 4 }}>
					<Grid item xs={12} sm={6} md={6} lg={6}>
						<Ownership companyId={startupDetails && startupDetails?.id} readOnly={startupDetails} />
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={6}>
						<AmountRaised companyId={startupDetails && startupDetails?.id} readOnly={startupDetails} />
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={4}>
						<Customers companyId={startupDetails && startupDetails?.id} readOnly={startupDetails} />
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={4}>
						<Arr companyId={startupDetails && startupDetails?.id} readOnly={startupDetails} />
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={4}>
						<NetArr companyId={startupDetails && startupDetails?.id} readOnly={startupDetails} />
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={4}>
						<Mrr companyId={startupDetails && startupDetails?.id} readOnly={startupDetails} />
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={4}>
						<Payback companyId={startupDetails && startupDetails?.id} readOnly={startupDetails} />
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={4}>
						<BurnRunway companyId={startupDetails && startupDetails?.id} readOnly={startupDetails} />
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={4}>
						<Revenue companyId={startupDetails && startupDetails?.id} readOnly={startupDetails} />
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={4}>
						<Acv companyId={startupDetails && startupDetails?.id} readOnly={startupDetails} />
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={4}>
						<SmExpenseVsNewSales companyId={startupDetails && startupDetails?.id} readOnly={startupDetails} />
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={4}>
						<BurnMultiple companyId={startupDetails && startupDetails?.id} readOnly={startupDetails} />
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={4}>
						<MagicNumber companyId={startupDetails && startupDetails?.id} readOnly={startupDetails} />
					</Grid>
				</Grid>
				<PartnerIntegration companyId={startupDetails && startupDetails?.id} readOnly={startupDetails} />
				{/* {showPlanModal && <PaymentPlanDialog open={showPlanModal} onClose={handleClosePlanModal} />} */}
			</Box>
		</>
	);
}

export default Dashboard;
