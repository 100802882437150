import styled from "@emotion/styled";
import { ListItem, Box } from "@mui/material";

export const ListItemComp = styled(Box, {
  shouldForwardProp: prop => prop !== "selected",
})(({ theme, selected }) => ({
  cursor: "Pointer",
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  marginBottom: theme.spacing(2),
  borderBottom: `2px solid transparent`,
  "&:hover": {
    color: theme.palette.primary.main,
    borderBottom: `2px solid ${theme.palette.primary.main}`,
  },
  ...(selected && {
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
  }),
  margin: "20px 4px",
  display: "flex",
  alignItems: "center",
  gap: "6px",
}));
