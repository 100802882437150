import { formatPhoneNumberString } from "../../../commonFunctions";
import { competitorLandscapeAI } from "../AI/competitorLandscape";
import { contentSummaryAI } from "../AI/contentSummary";
import { fundingProcessingAI } from "../AI/fundingProcessing";
import { moatProcessingAI } from "../AI/moatProcessing";
import { personaProcessingAI } from "../AI/personaProcessing";
import { pitchHookAI } from "../AI/pitchHook";
import { probSolProcessingAI } from "../AI/probSolProcessing";
import { getBio, getFounderStory, getPersonas, getProbSol, getTSS, getTSSImage, getValueProp } from "./infoSource";

function wait(ms) {
	return new Promise((resolve) => setTimeout(resolve, ms));
}

const callBackFunct = (data, setState) => {
	setState(data?.key, data?.values);
};

const getPitchDeckData = async (setState) => {
	const forwardCB = (data) => {
		callBackFunct(data, setState);
	};

	try {
		const [profiles, valueProp, founderStory, problemSolution, personas, tss] = await Promise.all([
			getBio(forwardCB),
			getValueProp(forwardCB),
			getFounderStory(forwardCB),
			getProbSol(forwardCB),
			getPersonas(forwardCB),
			getTSS(forwardCB),
		]);
		forwardCB({ key: "loadingData", values: { loading: false, complete: true } });
		return {
			userProfile: profiles?.profile,
			companyProfile: profiles?.company,
			teamList: profiles?.teamList,
			valueProp,
			founderStory,
			problemSolution,
			personas,
			tss,
		};
	} catch (err) {
		console.error("Error fetching all data:", err);
	}
};

const getAIProcessedData = async (data, setState) => {
	try {
		const forwardCB = (data) => {
			callBackFunct(data, setState);
		};
		// Split into 3 groups of Promise.Alls to not overwhelm backend with too many parallel requests.
		const [hook, originStory, probSol, valuePropSummary] = await Promise.all([
			pitchHookAI(data?.companyProfile?.companyName, data?.founderStory, forwardCB),
			contentSummaryAI(
				data?.founderStory,
				"Summarize the founder story for use in a pitch-deck presentation slide. Summary must be in 1st person, so use of 'we' in place of the company name. Your target is to generate the summary under 50 words in length.",
				forwardCB,
				"storyAI"
			),
			probSolProcessingAI(data?.problemSolution, forwardCB),
			contentSummaryAI(
				data?.valueProp,
				"Summarize the value proposition for use in a pitch-deck presentation slide. Summary must be in 1st person, so use of 'we' in place of the company name. Your target is to generate the summary under 50 words in length.",
				forwardCB,
				"valuePropAI"
			)
		]);
		const [personas, tssOverview, competitors] = await Promise.all([
			personaProcessingAI(data?.personas, forwardCB),
			contentSummaryAI(
				data?.tss?.overview,
				"The context here is that the summary of a TAM SAM SOM report that will be placed in a pitch deck presentation slide alongside the related TAM-SAM-SOM graphic. Max 3 lines of 1 sentence each. Each line should represent TAM, SAM, and SOM. Separate each line in the response string with encoded newline character '\n'",
				forwardCB,
				"tssAI"
			),
			competitorLandscapeAI(data?.companyProfile?.companyName, data?.valueProp, forwardCB),
		]);
		const [funding, moat] = await Promise.all([
			fundingProcessingAI(data?.valueProp, data?.companyProfile, forwardCB),
			moatProcessingAI(data?.valueProp, competitors, forwardCB)
		]);

		forwardCB({ key: "AILoading", values: { loading: false, complete: true } });
		return {
			hook,
			originStory,
			probSol,
			valuePropSummary,
			personas,
			tssOverview,
			competitors,
			moat,
			funding
		};
	} catch (err) {
		console.error("Error fetching all data:", err);
	}
};

const prepareObject = async (setState, data) => {
	//const data = await getPitchDeckData(setState);
	setState("AILoading", { loading: true, complete: false });
	await wait(1000);
	const { hook, originStory, probSol, valuePropSummary, personas, tssOverview, competitors, moat, funding } = await getAIProcessedData(data, setState);

	const payload = {
		THEMING: {
			PRIMARY_COLOR: "",
			SECONDARY_COLOR: "",
			TERTIARY_COLOR: "",
		},
		COMPANY_NAME: data?.companyProfile?.companyName,
		COMPANY_LOGO: data?.companyProfile?.logoUrl,
		PRESENTER_NAME: data?.userProfile?.name,
		PRESENTER_EMAIL: data?.userProfile?.email,
		PRESENTER_WEBSITE: data?.companyProfile?.websiteLink,
		PRESENTER_PHONE: formatPhoneNumberString(data?.companyProfile?.phone),
		INTRO_QUOTE: hook ? hook : "Attention grabbing hook statement to open the show.",
		COMPANY_ORIGIN_STORY: originStory ? originStory : "Briefly introduce how your startup came to be.",
		GENERAL_PROBLEM_STATEMENT: probSol?.generalProblem ? probSol?.generalProblem : "Briefly introduce the general problem your company is solving.",
		PROBLEM_1: probSol?.problems?.[0]?.summary ? probSol?.problems?.[0]?.summary : "Summary of the 1st main problem you are solving for.",
		PROBLEM_2: probSol?.problems?.[1]?.summary ? probSol?.problems?.[1]?.summary : "Summary of the 2nd main problem you are solving for.",
		PROBLEM_3: probSol?.problems?.[2]?.summary ? probSol?.problems?.[2]?.summary : "Summary of the 3rd main problem you are solving for.",
		GENERAL_SOLUTION_STATEMENT: probSol?.generalSolution ? probSol?.generalSolution : "Briefly introduce the general solution to the overarching problem.",
		PROBLEM_1_TITLE: probSol?.problems?.[0]?.title ? probSol?.problems?.[0]?.title : "Problem",
		PROBLEM_1_SOLUTION: probSol?.problems?.[0]?.solution ? probSol?.problems?.[0]?.solution : "Solution Description.",
		PROBLEM_2_TITLE: probSol?.problems?.[1]?.title ? probSol?.problems?.[1]?.title : "Problem",
		PROBLEM_2_SOLUTION: probSol?.problems?.[1]?.solution ? probSol?.problems?.[1]?.solution : "Solution Description.",
		PROBLEM_3_TITLE: probSol?.problems?.[2]?.title ? probSol?.problems?.[2]?.title : "Problem",
		PROBLEM_3_SOLUTION: probSol?.problems?.[2]?.solution ? probSol?.problems?.[2]?.solution : "Solution Description.",
		VALUE_PROPOSITION: valuePropSummary ? valuePropSummary : "Provide a summary of your value proposition. Drive home why your company should exist.",
		//TSS_GRAPHIC: data?.tss?.image
		//	? {
		//			type: "image",
		//			content: data?.tss?.image,
		//	  }
		//	: "",
		TSS_TAM: data?.tss?.tam ? data?.tss?.tam : "$##.#M",
		TSS_SAM: data?.tss?.sam ? data?.tss?.sam : "$##.#M",
		TSS_SOM: data?.tss?.som ? data?.tss?.som : "$##.#M",
		TSS_OVERVIEW: tssOverview ? tssOverview : "Provide an overview on your company's TAM/SAM/SOM. Focus on justifying the market size.",
		...competitors,
		PERSONA_1_NAME: "Buyer",
		PERSONA_1_AGE: personas?.[0]?.age ? personas?.[0]?.age : "# yrs",
		PERSONA_1_INCOME: personas?.[0]?.income_level ? personas?.[0]?.income_level : "$###k",
		PERSONA_1_EDUCATION: personas?.[0]?.education ? personas?.[0]?.education : "Degree Level",
		PERSONA_1_TRAITS: personas?.[0]?.traits ? personas?.[0]?.traits : "The responsibilities of your persona encompass the tasks, roles, and obligations they undertake in their daily life or professional pursuits.",
		PERSONA_1_GOALS: personas?.[0]?.goals ? personas?.[0]?.goals : "Goals of this persona that your product/service should support.",
		PERSONA_1_PAIN: personas?.[0]?.pain_points ? personas?.[0]?.pain_points : "The pain points of your persona refer to the challenges, frustrations, and obstacles they encounter in their journey.",
		PERSONA_2_NAME: "User",
		PERSONA_2_AGE: personas?.[1]?.age ? personas?.[1]?.age : "# yrs",
		PERSONA_2_INCOME: personas?.[1]?.income_level ? personas?.[1]?.income_level : "$###k",
		PERSONA_2_EDUCATION: personas?.[1]?.education ? personas?.[1]?.education : "Degree Level",
		PERSONA_2_TRAITS: personas?.[1]?.traits ? personas?.[1]?.traits : "These can include managing projects, making purchasing decisions, maintaining work-life balance, staying informed about industry trends, engaging with social media, pursuing personal interests, and possibly overseeing household matters.",
		PERSONA_2_GOALS: personas?.[1]?.goals ? personas?.[1]?.goals : "Goals of this persona that your product/service should support.",
		PERSONA_2_PAIN: personas?.[1]?.pain_points ? personas?.[1]?.pain_points : "These could encompass struggles such as time constraints due to busy schedules, difficulty finding reliable information, feeling overwhelmed by choices, managing work-life balance, or facing financial constraints.",
		PERSONA_3_NAME: "Influencer",
		PERSONA_3_AGE: personas?.[2]?.age ? personas?.[2]?.age : "# yrs",
		PERSONA_3_INCOME: personas?.[2]?.income_level ? personas?.[2]?.income_level : "$###k",
		PERSONA_3_EDUCATION: personas?.[2]?.education ? personas?.[2]?.education : "Degree Level",
		PERSONA_3_TRAITS: personas?.[2]?.traits ? personas?.[2]?.traits : "Understanding these responsibilities provides valuable context for tailoring your products or services to meet their needs effectively.",
		PERSONA_3_GOALS: personas?.[2]?.goals ? personas?.[2]?.goals : "Goals of this persona that your product/service should support.",
		PERSONA_3_PAIN: personas?.[2]?.pain_points ? personas?.[2]?.pain_points : "Identifying these pain points is crucial as it helps you create solutions that directly address their specific needs and improve their overall experience.",
		...data?.teamList,
		//WHY_US: moat?.whyUs ? moat?.whyUs : "Why us?\nThis is your opportunity to hammer home why someone should invest in your company.",
		//WHY_NOW: moat?.whyNow ? moat?.whyNow?.replace(/^\d+\.\s/gm, '') : "Why now?\nExplain why this is the optimal time to bring on an investor. Why is the market ready?",
		//BARRIERS_TO_ENTRY: moat?.barriers ? moat?.barriers?.replace(/^\d+\.\s/gm, '') : "Barriers to entry?\nExplain what barriers to entry exist for new competitors.",
		MOAT_1: moat?.[0] ? moat?.[0] : "Provide a reason to substantiate your product/service's moat. Think 'barriers to entry' for new competitors and features of your product/service that outshines existing competition.",
		MOAT_2: moat?.[1] ? moat?.[1] : "Provide a reason to substantiate your product/service's moat. Think 'barriers to entry' for new competitors and features of your product/service that outshines existing competition.",
		MOAT_3: moat?.[2] ? moat?.[2] : "Provide a reason to substantiate your product/service's moat. Think 'barriers to entry' for new competitors and features of your product/service that outshines existing competition.",
		MOAT_4: moat?.[3] ? moat?.[3] : "Provide a reason to substantiate your product/service's moat. Think 'barriers to entry' for new competitors and features of your product/service that outshines existing competition.",
		MOAT_5: moat?.[4] ? moat?.[4] : "Provide a reason to substantiate your product/service's moat. Think 'barriers to entry' for new competitors and features of your product/service that outshines existing competition.",
		FUNDING_AMOUNT: funding?.amount ? funding?.amount : "How much are you asking from investors?",
		FUNDING_TERMS: funding?.terms ? funding?.terms : "What are the terms of your funding request?",
		FUNDING_MILESTONES: funding?.milestones ? funding?.milestones : "The specific goals or achievements you intend to reach using the funds you're raising. They help potential investors understand what their money will be used for and what outcomes they can expect as a result of their investment.",
		//FUNDING_ADDITIONAL_REQUESTS: funding?.requests ? funding?.requests : "Is there any additional needs you may have past funding?",
		//FUNDING_HOW_1: funding?.how?.[0]?.title ? funding?.how?.[0]?.title : "Need 1",
		//FUNDING_HOW_2: funding?.how?.[1]?.title ? funding?.how?.[1]?.title : "Need 2",
		//FUNDING_HOW_3: funding?.how?.[2]?.title ? funding?.how?.[2]?.title : "Need 3",
		//FUNDING_HOW_1_EXP: funding?.how?.[0]?.description ? funding?.how?.[0]?.description : "Identify specifics on what the funding would go towards;\nie. justify the ask.",
		//FUNDING_HOW_2_EXP: funding?.how?.[1]?.description ? funding?.how?.[1]?.description : "Ex:\nHire 3 FT employees to increase production and delivery by x%",
		//FUNDING_HOW_3_EXP: funding?.how?.[2]?.description ? funding?.how?.[2]?.description : "Ex:\nContract marketing resource to enhance customer acquisition and boost SEO.",
		LOOKING_AHEAD_1: funding?.future?.[0] ? funding?.future?.[0] : "Insight to what's next/planned growth + direction.",
		LOOKING_AHEAD_2: funding?.future?.[1] ? funding?.future?.[1] : "How extensible is this product/solution?",
		LOOKING_AHEAD_3: funding?.future?.[2] ? funding?.future?.[2] : "Future planned features/solutions?",
	};

	return payload;
};

export const getReadiness = async (setState, setPowerupData) => {
	setState("loadingData", { loading: true, complete: false });
	await wait(1000);
	const data = await getPitchDeckData(setState);
	if (setPowerupData) setPowerupData(data);
};

export const generatePitchDeck = async (setState, powerupdata) => {
	const obj = await prepareObject(setState, powerupdata);
	return obj;
};
