import React, { useState } from "react";
import { Button, Box, useMediaQuery } from "@mui/material";
import DialogComponent from "../../Workspace/PowerUP/IdeaValidation/Components/Dialog";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EventDetails from "../../Workspace/Plugins/AdvisorPlugin/Components/EventDetails";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import theme from "../../../constants/Theme";
import MenuDrawer from "../../../components/ExternalCandidates/Drawers/MenuDrawer";
import DeleteHandlerModal from "../../../common/modal/DeleteHandler";
import DeleteDrawer from "../../../components/ExternalCandidates/Drawers/DeleteDrawer";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import services from "../../../services";
import { toastContainer } from "../../../utils/toast";
import ProposeNewTime from "../ProposeNewTime";
import ProposeNewTimeMobile from "../ProposeNewTime/ProposeNewTimeMobile";
import {
  getCharDay,
  getCurrentDate,
  getCurrentTime,
  getLocalTimeZone,
} from "../../../utils/date";
import {
  convertToTimeStamp,
  getEndTime,
} from "../../Candidates/Functions/common";
import AddToCalendar from "../../../common/AddToCalendar";

export default function EventDetailsPopup(props) {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [showDelete, setShowDelete] = useState({
    show: false,
  });
  const [showDeleteDrawer, setShowDeleteDrawer] = useState(false);
  const [openProposeNewTime, setOpenProposeNewTime] = useState(false);

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  let startTime =
    props.currentEventDetails?.calendarEvent?.timings[0]?.times[0]?.split(
      "T"
    )[1];
  let duration = props?.currentEventDetails?.calendarEvent?.duration;
  let startDate = props?.currentEventDetails?.calendarEvent?.timings[0]?.date;
  let compayName = props?.currentEventDetails?.companyModel?.companyName;
  const selectedEndTime = convertToTimeStamp(
    startDate,
    getEndTime(startTime, duration)
  ).toString();

  const event = {
    title: `Interview with ${compayName}`,
    description: "Interview Scheduled",
    address: getLocalTimeZone(),
    startDate: new Date(startDate),
    endDate: new Date(parseInt(selectedEndTime)),
    durationInMinutes: duration,
  };
  const FooterComponent = () => {
    return (
      <>
        {/* WEB */}
        <Box
          sx={{
            width: 1,
            px: 2,
            display: { xs: "none", md: "flex" },
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <DeleteOutlineIcon
              sx={{ cursor: "pointer" }}
              onClick={() => handleDelete()}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: 1,
            }}
          >
            <AddToCalendar calendarEvent={event} buttonVariant="outlined" />

            <Button
              startIcon={<AccessTimeIcon />}
              sx={{ width: "max-content" }}
              onClick={() => {
                handleProposeNewTime();
              }}
            >
              Propose new time
            </Button>
          </Box>
        </Box>

        {/* MOB */}
        <Box
          sx={{
            width: 1,
            px: 2,
            display: { xs: "flex", md: "none" },
            justifyContent: "flex-end",
            alignItems: "center",
            gap: 2,
          }}
        >
          <MoreHorizOutlinedIcon
            sx={{ cursor: "pointer", fill: theme.palette.secondary.main }}
            onClick={() => setOpenDrawer(true)}
          />
        </Box>
      </>
    );
  };

  const handleDelete = email => {
    setShowDelete({ show: true, email: email });
  };

  const handleCancel = () => {
    setShowDelete({ show: false });
  };

  const handleRemove = email => {};

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  //Mob
  const handleDeleteDrawer = email => {
    setShowDeleteDrawer(true);
  };
  const handleDeleteDrawerClose = () => {
    setShowDeleteDrawer(false);
  };

  //delete event
  const handleDeleteEvent = () => {
    services
      .deleteEvent(props.currentEventDetails.id)
      .then(res => {
        props.setOpen(false);
        handleCancel();
        handleDrawerClose();
        setShowDeleteDrawer(false);
        props.getCandidateInvites();
      })
      .catch(err => {
        if (err.response.data.error) {
          toastContainer(err.response.data.message, "error");
        }
      });
  };
  const handleProposeNewTime = () => {
    setOpenProposeNewTime(true);
    props.setOpen(false);
  };

  //Keep at the bottom
  const menuOptions = [
    {
      icon: <AccessTimeIcon />,
      text: "Propose new time",
      action: handleProposeNewTime,
    },
    { icon: <AddIcon />, text: "Add to calendar", action: "" },
    {
      icon: <DeleteOutlineOutlinedIcon />,
      text: "Delete",
      action: handleDeleteDrawer,
    },
  ];

  return (
    <Box>
      <DialogComponent
        isOpen={props.open}
        onClose={() => props.setOpen(false)}
        title={"Event Details"}
        dialogAction={<FooterComponent />}
        titleVariant="largeTitle"
        sx={theme => ({
          display: "flex",
          width: 1,
          flexDirection: "column",
          borderRadius: theme.shape.standard23.borderRadius,
          "& .MuiDialog-paper": {
            width: "800px",
            borderRadius: 3,
          },
          [theme.breakpoints.down("md")]: {
            maxHeight: "90%",
          },
        })}
        contentStyle={{
          mt: 2,
        }}
        footerStyle={{
          py: 2,
        }}
        disableBackDropClick
      >
        <EventDetails
          eventDetails={props?.currentEventDetails?.calendarEvent}
          pluginName={`Interview with ${compayName}`}
          userType={"external"}
          inviteStatus={true}
          inviteDate={`${getCharDay(startDate)}, ${getCurrentDate(startDate)}`}
          inviteTime={`${getCurrentTime(startTime)} - ${getCurrentTime(
            getEndTime(startTime, duration)
          )} `}
        />
      </DialogComponent>

      {/* Delete popup */}
      <DeleteHandlerModal
        isOpen={showDelete.show}
        onCancel={handleCancel}
        onOk={() => handleDeleteEvent()} //add eventId in params
        text="Are you sure you want to discard the changes you made?"
        okText="Delete"
        cancelText="Cancel"
        messageType="primary"
        heading={"Delete event"}
      />

      {/* Mob */}
      <MenuDrawer
        open={openDrawer}
        onClose={handleDrawerClose}
        menuOptions={menuOptions}
        event={event}
      />

      <DeleteDrawer
        open={showDeleteDrawer}
        onClose={() => handleDeleteDrawerClose()}
        title={"Are you sure you want to delete?"}
        primaryActionText={"Delete"}
        secondaryActionText={"Cancel"}
        primaryAction={() => handleDeleteEvent()}
        secondaryAction={handleDeleteDrawerClose}
      />

      {isSmallScreen ? (
        <ProposeNewTimeMobile
          openProposeNewTime={openProposeNewTime}
          setOpenProposeNewTime={setOpenProposeNewTime}
          responseObj={props.currentEventDetails}
          onClose={props.getCandidateInvites}
        />
      ) : (
        <ProposeNewTime
          openProposeNewTime={openProposeNewTime}
          setOpenProposeNewTime={setOpenProposeNewTime}
          responseObj={props.currentEventDetails}
          onClose={props.getCandidateInvites}
        />
      )}
    </Box>
  );
}
