import * as actionTypes from "./actionTypes";

export const setUtmParams = (payload) => {
	return (dispatch) => {
		dispatch({
			type: actionTypes.SET_UTM_PARAMS,
			payload,
		});
	};
};
