import * as actionTypes from "../../actions/actionTypes";
import { updateObject } from "../../../utils/updateObject";

const initialState = {
  calUser: {},
  currentMeetingObject: {},
  comingFromPlugin: null,
};

const meetings = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_CURRENT_MEETING_OBJECT:
      return updateObject(state, {
        currentMeetingObject:
          action.payload == null
            ? {}
            : { ...state.currentMeetingObject, ...action.payload },
      });
    case actionTypes.SET_CAL_USER:
      return updateObject(state, {
        calUser:
          action.payload == null ? {} : { ...state.calUser, ...action.payload },
      });
    case actionTypes.SET_COMING_FROM_PLUGIN:
      return updateObject(state, {
        comingFromPlugin:
          action.payload == null
            ? {}
            : { ...state.comingFromPlugin, ...action.payload },
      });
    default:
      return state;
  }
};
export default meetings;
