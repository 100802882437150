import * as React from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { Typography } from "@mui/material";
import theme from "../../constants/Theme";
import { ArrowDropUp } from "@mui/icons-material";

export default function ExportPopover({
  text,
  onAction,
  prefixIcon,
  children,
  variant,
}) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <React.Fragment>
      <ButtonGroup
        variant="outlined"
        ref={anchorRef}
        aria-label="split button"
        sx={{ borderRadius: theme.shape.standard3.borderRadius }}
        onClick={handleToggle}
      >
        <Button variant={variant || "contained"} startIcon={prefixIcon}>
          {text}
        </Button>
        <Button
          variant={variant || "contained"}
          size="small"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          sx={{ width: "max-content" }}
        >
          {open ? <ArrowDropUp /> : <ArrowDropDownIcon />}
        </Button>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement="top"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
              background: theme => theme.palette.secondary.greyBg,
              borderRadius: theme => theme.shape.standard3.borderRadius,
              boxShadow: "2px",
              marginBottom: "2px",
            }}
          >
            <Paper sx={{ py: 1, px: 3 }}>
              <ClickAwayListener onClickAway={handleClose}>
                {children}
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
}
