import { Grid, useMediaQuery } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Client } from "@twilio/conversations";
import { bindActionCreators } from "redux";
import { MainArea, HeaderArea } from "./styles";
import RightSidebar from "../../components/Workspace/Sidebar/RightSidebar";
import ARCHIVE_LeftSidebar from "../../components/Workspace/Sidebar/ARCHIVE_LeftSidebar";
import BottomNavbar from "../../components/Workspace/BottomNavbar";
import WorkspaceHeader from "../../components/Workspace/Header";
import ChatIcons from "../../components/Workspace/ChatIcons";
import { connect, useSelector, useDispatch } from "react-redux";
import * as actions from "../../modules/actions";
import { Box } from "@mui/material";
import { ApolloProvider } from "@apollo/client";
import { useLocation } from "react-router-dom";
import PowerupController from "../../components/NewPowerup/PowerupController";
import { actionCreators } from "../../containers/Messaging/store";
import { getToken } from "../../containers/Messaging/api";
import { handlePromiseRejection } from "../../containers/Messaging/helpers";
import LeftSidebar from "../../components/Workspace/Sidebar/LeftSidebar";
import UpdatedHeader from "../../components/Workspace/UpdatedHeader";
import { DrawerHeader } from "../../components/Workspace/Sidebar/LeftSidebar/styles";
import MobileNav from "../../components/Workspace/Sidebar/MobileNav";

function UpdatedLayoutWrapper({
	children,
	onLogout,
	userData,
	showAccountSettingPopup,
	closeAccountSettingsPopup,
	handleOpen,
	companyName,
	userName,
	companyLogoUrl,
}) {
	/*
    The following blocks are to observe "unread message count".
  */
	const [hasUpdates, setHasUpdates] = useState("");
	const [margin, setMargin] = useState(false);
	const location = useLocation();
	const unreadMessagesData = useSelector((state) => state?.unreadMessages);
	const unreadMessagesArray = Object.values(unreadMessagesData);
	const userID = String(useSelector((state) => state?.auth?.userId));
	const userType = String(useSelector((state) => state?.auth?.userType));
	const token = useSelector((state) => state.token);
	const [open, setOpen] = React.useState(false);
	const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 980);

	const dispatch = useDispatch();
	const { updateUnreadMessages, login } = bindActionCreators(actionCreators, dispatch);

	useEffect(() => {
		const handleResize = () => {
			setIsSmallScreen(window.innerWidth <= 980);
		};

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	async function loadUnreadMessagesCount(convo) {
		let count = 0;
		try {
			count = (await convo.getUnreadMessagesCount()) ?? (await convo.getMessagesCount());
		} catch (e) {
			console.error("getUnreadMessagesCount threw an error", e);
		}
		updateUnreadMessages(convo.sid, count);
	}

	async function processMissedMessages(client) {
		let conversations = await getSubscribedConversations(client);
		if (conversations) {
			conversations.forEach((convo) => {
				loadUnreadMessagesCount(convo);
			});
		}
	}

	async function getSubscribedConversations(client) {
		let subscribedConversations = await client.getSubscribedConversations();
		let conversations = subscribedConversations.items;
		while (subscribedConversations.hasNextPage) {
			subscribedConversations = await subscribedConversations.nextPage();
			conversations = [...conversations, ...subscribedConversations.items];
		}
		return conversations;
	}

	useEffect(() => {
		getToken(userID)
			.then((token) => {
				if (token?.accessToken?.length > 0) login(token.accessToken);
				else throw "accessToken missing from getToken call.";
			})
			.catch((e) => {
				console.log("Something went wrong with messaging login.", e);
			});
	}, []);

	useEffect(() => {
		if (token) {
			const client = new Client(token);
			//On load, get any missed messages.
			processMissedMessages(client);
			//Message received listener.
			client.on("messageAdded", () => {
				//On message received, start counting missed messages,
				processMissedMessages(client);
			});
			client.on("tokenAboutToExpire", () => {
				if (userID && userID.length > 0) {
					getToken(userID)
						.then((token) => {
							if (token?.accessToken?.length > 0) login(token.accessToken);
							else throw "accessToken missing from getToken call.";
						})
						.catch((e) => {
							console.log("Something went wrong with messaging login.", e);
						});
				}
			});
		}
	}, [token]);

	useEffect(() => {
		if (unreadMessagesArray.length > 0) {
			let totalCount = unreadMessagesArray?.reduce((x, y) => {
				return x + y;
			});
			setHasUpdates(totalCount);
		}
	}, [unreadMessagesArray]);

	const handleDrawerOpen = () => {
		setOpen(true);
	};

	return (
		<Box
			sx={(theme) => ({
				height: "100vh",
				display: "flex",

				[theme.breakpoints.down(theme.breakpoints.values.mobile)]: {
					flexDirection: "column",
				},
			})}
		>
			{/* Sidebar Container */}
			{userType && (
				<Box sx={{ zIndex: 1 }}>
					{!isSmallScreen ? (
						<LeftSidebar
							userData={userData}
							companyName={companyName}
							userName={userName}
							companyLogoUrl={companyLogoUrl}
							onLogout={onLogout}
							hasUpdates={hasUpdates}
							setMargin={() => {
								//setMargin
							}}
							open={open}
							setOpen={setOpen}
						/>
					) : (
						<MobileNav />
					)}
				</Box>
			)}
			<Box
				sx={{
					display: "flex",
					flex: 1,
					flexDirection: "column",
					overflowY: "scroll",
				}}
			>
				<UpdatedHeader
					userData={userData}
					onLogout={onLogout}
					hasUpdates={hasUpdates}
					setMargin={setMargin}
					handleDrawerOpen={handleDrawerOpen}
					open={open}
				/>

				<Box sx={{ flex: 1 }}>
					{location.pathname.includes("/workspace/power-ups/") ? (
						<>{children}</>
					) : (
						<>
							{/* <Box
									sx={(theme) => ({
										// mb: margin ? 40 : 10,
										maxWidth: "inherit",
										[theme.breakpoints.down("md")]: {
											mb: margin ? 25 : 10,
										},
									})}
								></Box> */}
							<MainArea>{children}</MainArea>
						</>
					)}
				</Box>
			</Box>
		</Box>
		//<Grid container height={"100vh"} sx={{ display: "flex", justifyContent: "center" }}>
		//	<Grid item xs={open ? 2 : 1} sm={open ? 2 : 1} md={open ? 4 : 1} lg={open ? 2.5 : 1} xl={open ? 2 : 1}>
		//		<Box display={{ xs: "none", sm: "none", md: "block" }}>
		//<UpdatedLeftSidebar
		//	userData={userData}
		//	companyName={companyName}
		//	userName={userName}
		//	companyLogoUrl={companyLogoUrl}
		//	onLogout={onLogout}
		//	hasUpdates={hasUpdates}
		//	setMargin={setMargin}
		//	open={open}
		//	setOpen={setOpen}
		///>
		//		</Box>
		//	</Grid>
		//	<Grid item xs={open ? 10 : 11} sm={open ? 10 : 11} md={open ? 8 : 11} lg={open ? 9.5 : 11} xl={open ? 10 : 11}>
		//<Grid container>
		//	<Grid item xs={12} sm={12} md={12} lg={12} sx={12}>
		//		<DrawerHeader>
		//			<UpdatedHeader
		//				userData={userData}
		//				onLogout={onLogout}
		//				hasUpdates={hasUpdates}
		//				setMargin={setMargin}
		//				handleDrawerOpen={handleDrawerOpen}
		//				open={open}
		//			/>
		//		</DrawerHeader>
		//	</Grid>
		//	<Grid
		//		item
		//		xs={12}
		//		sm={12}
		//		md={12}
		//		lg={12}
		//		xl={12}
		//		//sx={(theme) => ({
		//		//	[theme.breakpoints.down(1520)]: {
		//		//		pl: open ? 0 : 2,
		//		//	},
		//		//	[theme.breakpoints.down(1240)]: {
		//		//		pl: open ? 0 : 4,
		//		//	},
		//		//})}
		//	>
		//		{location.pathname.includes("/workspace/power-ups/") ? (
		//			<>{children}</>
		//		) : (
		//			<>
		//				{/* <Box
		//					sx={(theme) => ({
		//						// mb: margin ? 40 : 10,
		//						maxWidth: "inherit",
		//						[theme.breakpoints.down("md")]: {
		//							mb: margin ? 25 : 10,
		//						},
		//					})}
		//				></Box> */}
		//				<MainArea>{children}</MainArea>
		//			</>
		//		)}
		//	</Grid>
		//</Grid>
		//	</Grid>
		//</Grid>
	);
}

const mapStoreToProps = ({ auth }) => ({
	companyName: auth?.companyName,
	userType: auth?.userType,
	userName: auth?.userName,
	showAccountSettingPopup: auth?.showAccountSettingPopup,
	companyLogoUrl: auth?.companyLogoUrl,
});

const mapDispatchToProps = (dispatch) => ({
	setAuthData: (updatedData) => dispatch(actions.setAuthData(updatedData)),
});

export default connect(mapStoreToProps, mapDispatchToProps)(UpdatedLayoutWrapper);
