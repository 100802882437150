import React, { useEffect } from "react";
import { Grid, Box, Typography, Button } from "@mui/material";
import {
  ROADRUNNERICON,
  DASHBOARDIMG,
  POWERUP_TEXT_ICON,
  COMMUNITY_TEXT_ICON,
  MENTORSHIP_TEXT_ICON,
  RADAR_TEXT_ICON,
} from "../../constants/image";
import theme from "../../constants/Theme";
import "./home.css";
import { useHistory } from "react-router-dom";
import PersonaTab from "../../common/PersonaTab";
import constants from "../../constants";
import ROUTES_PATH from "../../constants/routes";
import MobileCarousel from "./components/MobileCarousel";
import { Blob } from "./components/Blob";

const AppHome = props => {
  const history = useHistory();
  const path = props.location.pathname.substr(
    1,
    props.location.pathname.length
  );
  const tabitems = constants.MENTOR_PERSONA_TAB_ITEMS;
  const [currenttab, setcurrenttab] = React.useState("founders");
  const [homePageData, setHomePageData] = React.useState({});

  useEffect(() => {
    if (path) {
      if (path === "" || path === "terms" || path === "privacy") {
        setcurrenttab("founders");
      } else if (path === "advisors" || path === "mentors") {
        setcurrenttab(path);
      } else {
        setcurrenttab("founders");
      }
    }
  }, []);

  useEffect(() => {
    let temp = constants.HOME_PAGE_DATA.find(item => item.key === currenttab);
    setHomePageData(temp);
  }, [currenttab]);

  return (
    <>
      {/*Web */}
      <Box sx={{ display: { xs: "none", md: "block" } }}>
        <Grid container justifyContent="center">
          <Box
            sx={{
              maxWidth: theme.breakpoints.values.xl,
              px: 6,
              pb: 6,
              position: "relative",
              zIndex: 100,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box>
                <Box
                  component={"img"}
                  src={ROADRUNNERICON}
                  alt="roadRunnerIcon"
                />
              </Box>
              <Box sx={{ my: 3 }}>
                <Typography
                  component="h1"
                  variant="xLarge"
                  color="primary"
                  textAlign={"center"}
                >
                  {homePageData.landingTitle}
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="subhead1"
                  component={"h2"}
                  textAlign={"center"}
                >
                  {homePageData.landingDescription}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                position: "relative",
                zIndex: "2",
                mt: 20,
                [theme.breakpoints.between(
                  theme.breakpoints.values.md,
                  theme.breakpoints.values.lg
                )]: {
                  mt: currenttab !== "founders" ? 29 : 20,
                },
              }}
            >
              <PersonaTab
                tabitems={tabitems}
                currenttab={currenttab}
                setcurrenttab={setcurrenttab}
              />
            </Box>
            <Grid
              container
              xs={12}
              columnSpacing={4}
              sx={{ mt: 5 }}
              alignItems="center"
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                sx={{
                  color: theme.palette.secondary.white,
                  position: "relative",
                  zIndex: "2",
                }}
              >
                <Box>
                  <Box>
                    <Typography variant="h1" component={"h3"}>
                      {homePageData.caption}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      my: 3,
                    }}
                  >
                    <Typography variant="subhead1" component={"h4"}>
                      {homePageData.subcaption}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      borderLeft: "1px solid",
                      pl: 2,
                      display: currenttab === "founders" ? "grid" : "none",
                      gridAutoRows: "1fr",
                      gridRowGap: "10px",
                    }}
                  >
                    <Box component="img" src={POWERUP_TEXT_ICON} />
                    <Box component="img" src={MENTORSHIP_TEXT_ICON} />
                    <Box component="img" src={COMMUNITY_TEXT_ICON} />
                    <Box component="img" src={RADAR_TEXT_ICON} />
                  </Box>
                  <Box sx={{ mt: 3 }}>
                    <Button
                      sx={{
                        width: "auto",
                        background: theme.palette.secondary.greyBg,
                        color: theme.palette.primary.main,
                      }}
                      onClick={() =>
                        history.push(
                          currenttab === "founders"
                            ? ROUTES_PATH.ONBOARDING_BASEPATH
                            : ROUTES_PATH.REQUEST_FORM
                        )
                      }
                    >
                      {currenttab === "founders"
                        ? "Create Your Account"
                        : currenttab === "mentors"
                        ? `Request to be a mentor`
                        : `Request to be an advisor`}
                    </Button>
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                sx={{
                  position: "relative",
                  overflow: "visible",
                  zIndex: "1",
                }}
              >
                <Box
                  component={"img"}
                  src={DASHBOARDIMG}
                  sx={{
                    width: "100%",
                    position: "relative",
                    zIndex: "2",
                  }}
                  alt="StartupOS Dashboard Preview"
                />
                <Blob></Blob>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Box>

      {/*Mobile screens*/}
      <Box sx={{ display: { xs: "block", md: "none" } }}>
        <Box
          sx={{
            maxWidth: "100vw",
            overflow: "hidden",
            background: theme.palette.primary.homeBlueBackground,
            pt: 1,
          }}
        >
          <MobileCarousel
            tabitems={tabitems}
            currenttab={currenttab}
            setcurrenttab={setcurrenttab}
          />
        </Box>
        <Box
          sx={{
            pb: 5,
            pt: 5,
            px: 2,
            //background: "var(--home-blue-background)",
          }}
        >
          <Box sx={{ textAlign: "center" }}>
            <Box component={"img"} src={ROADRUNNERICON} alt="roadRunnerIcon" />
          </Box>

          <Box sx={{ mt: 3, textAlign: "center" }}>
            <Typography
              component="h1"
              variant="Homeh1"
              color="primary"
              sx={{ width: 1, textAlign: "center" }}
            >
              {homePageData.landingTitle}
            </Typography>
          </Box>

          <Box sx={{ mt: 4, textAlign: "center" }}>
            <Typography variant="subhead1">
              {homePageData.landingDescription}
            </Typography>
          </Box>
          <Box sx={{ mt: 5, textAlign: "center" }}>
            <Button
              fullWidth
              sx={{ maxWidth: "unset" }}
              onClick={() =>
                history.push(
                  currenttab === "founders"
                    ? ROUTES_PATH.ONBOARDING_BASEPATH
                    : ROUTES_PATH.REQUEST_FORM
                )
              }
            >
              {currenttab === "founders"
                ? "Create Your Account"
                : currenttab === "mentors"
                ? `Request to be a mentor`
                : `Request to be an advisor`}
            </Button>
          </Box>
        </Box>
        <Box sx={{ pb: 5, pt: 5, px: 2, overflow: "hidden" }}>
          <Grid
            container
            spacing={2}
            sx={{ color: theme.palette.secondary.white }}
          >
            <Grid item xs={12}>
              <Box>
                <Box>
                  <Box sx={{ position: "relative", zIndex: "2" }}>
                    <Typography variant="h1" component={"h3"}>
                      {homePageData.caption}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  mt: 5,
                  position: "relative",
                  overflow: "visible",
                  zIndex: "1",
                }}
              >
                <Box
                  component={"img"}
                  src={DASHBOARDIMG}
                  sx={{
                    width: "100%",
                    position: "relative",
                    zIndex: "2",
                    width: 1,
                  }}
                  alt="StartupOS Dashboard Preview"
                />
                <Blob></Blob>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  my: 3,
                  position: "relative",
                  zIndex: "2",
                }}
              >
                <Typography variant="subhead1" component={"h4"}>
                  {homePageData.subcaption}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  borderLeft: "1px solid",
                  pl: 2,
                  display: currenttab === "founders" ? "grid" : "none",
                  gridAutoRows: "1fr",
                  gridRowGap: "10px",
                }}
              >
                <Box component="img" src={POWERUP_TEXT_ICON} />
                <Box component="img" src={MENTORSHIP_TEXT_ICON} />
                <Box component="img" src={COMMUNITY_TEXT_ICON} />
                <Box component="img" src={RADAR_TEXT_ICON} />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box>
                <Button
                  sx={{
                    maxWidth: "unset",

                    background: theme.palette.secondary.greyBg,
                    color: theme.palette.primary.main,
                    mt: 3,
                  }}
                  onClick={() =>
                    history.push(
                      currenttab === "founders"
                        ? ROUTES_PATH.ONBOARDING_BASEPATH
                        : ROUTES_PATH.REQUEST_FORM
                    )
                  }
                >
                  {currenttab === "founders"
                    ? "Create Your Account"
                    : currenttab === "mentors"
                    ? `Request to be a mentor`
                    : `Request to be an advisor`}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default AppHome;
