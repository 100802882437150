import { Box, Typography } from "@mui/material";
import InfoHelper from "../../../../components/Founder/InfoHelper";
import { CHECK_SQUARE_BROKEN_ICON, CHECK_SQUARE_ICON } from "../../../../constants";

export const CustomCheckboxList = ({ label = "", ItemList = [], setItemList = [] }) => {
	const handleCheckBoxClick = (checkedObj) => {
		const tempCheckedList = [...ItemList];
		tempCheckedList?.map((item) => {
			if (checkedObj?.id === item?.id) {
				return (item.checked = !checkedObj?.checked);
			}
		});

		setItemList(tempCheckedList);
	};

	return (
		<Box ml="17px" gap={4}>
			<Typography variant="Text/sm/Semibold" component={"p"}>
				{label}
			</Typography>
			<Box>
				{ItemList?.map((checkBoxItem, idx) => {
					return (
						<Box key={idx} sx={{ cursor: "pointer" }} display={"flex"} alignItems={"center"} gap={1}>
							<InfoHelper title={checkBoxItem?.label} body={checkBoxItem?.helperText} iconType={"gray"} />
							<Box
								sx={{ cursor: "pointer", width: "92%" }}
								display={"flex"}
								alignItems={"start"}
								justifyContent={"space-between"}
								onClick={() => handleCheckBoxClick(checkBoxItem)}
							>
								<Typography variant="Text/sm/Regular" color="#667085" component={"p"}>
									{checkBoxItem?.label}
								</Typography>
								{checkBoxItem?.checked ? (
									<Box component="img" src={CHECK_SQUARE_BROKEN_ICON} />
								) : (
									<Box component="img" src={CHECK_SQUARE_ICON} />
								)}
							</Box>
						</Box>
					);
				})}
			</Box>
		</Box>
	);
};
