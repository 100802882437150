import React, { useState, useEffect } from "react";
import theme from "../../../constants/Theme";
import {
  Stack,
  Box,
  Typography,
  Button,
  Grid,
  Icon,
  useMediaQuery,
} from "@mui/material";
import { EventCard } from "../../../common/EventsContainer/styles";
import SelectInterviewTimePopup from "../Components/SelectInterviewTimePopup";
import {
  confirmInterviewInvite,
  convertToTimeStamp,
  getEndTime,
  getInviteDetails,
  parseDateTimePayload,
} from "../Functions/common";
import TimeSelectedSuccessPopup from "../Components/TimeSelectedSuccessPopup";
import services from "../../../services";
import { IconBox } from "../../../common/EventsContainer/styles";
import TodayIcon from "@mui/icons-material/Today";
import { sosPrimary } from "../../../constants/Theme/colors";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../../modules/actions";
import { toastContainer } from "../../../utils/toast";
import { EventMobileCarousel } from "../Components/EventMobileCarousel";
import { EventWebCarousel } from "./components/EventWebCarousel";
import EventDetailsPopup from "../../ExternalCandidate/DashboardEvent";

export default function CandidateDashboard() {
  const [interviewPopupOpen, setInterviewPopupOpen] = useState(false);
  const [inviteEventData, setInviteEventData] = useState({});
  const [selectedDateTime, setSelectedDatetime] = useState(null);
  const [timeSuccessPopupOpen, setTimeSuccessPopupOpen] = useState(false);
  const [currentEventDetails, setCurrentEventDetails] = useState({});
  const [open, setOpen] = useState(false);
  const eventId = useSelector(store => store.auth?.eventId);
  const [events, setEvents] = useState([]);
  const [confirmButtonLoading, setConfirmButtonLoading] = useState(false);

  const dispatch = useDispatch();

  const inviteDetailsApiCall = eventId => {
    getInviteDetails(eventId)
      .then(data => {
        const formattedData = {
          ...data,
          calendarEvent: {
            ...data.calendarEvent,
            calendarEventDetails: parseDateTimePayload(
              data.calendarEvent.timings
            ),
          },
        };
        setInviteEventData(formattedData);
        setInterviewPopupOpen(true);
      })
      .catch(error => console.log(error));
  };

  useEffect(() => {
    if (eventId) {
      inviteDetailsApiCall(eventId);
    }
  }, [eventId]);

  useEffect(() => {
    getCandidateInvites();
  }, []);

  const handlTimeSelectPopupClose = () => {
    setInterviewPopupOpen(false);
    getCandidateInvites();
  };

  const getCandidateInvites = () => {
    services
      .getCalendarInvites()
      .then(res => {
        setEvents(res.data?.data || []);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const EventCardActionComponent = ({ eventData }) => {
    return (
      <Box
        sx={{
          mt: 4,
          display: "flex",
        }}
      >
        {eventData?.calendarEventStatus === "PENDING" ? (
          <Button
            sx={{ width: "auto", mr: "initial" }}
            onClick={() => {
              inviteDetailsApiCall(eventData.id);
            }}
          >
            Select time
          </Button>
        ) : (
          <Button
            sx={{ width: "auto", mr: "initial" }}
            onClick={() => {
              //Show poppup on button click
              setCurrentEventDetails(eventData);
              setOpen(true);
            }}
          >
            View
          </Button>
        )}
      </Box>
    );
  };

  const handlTimeSelectSuccessPopupClose = () => {
    setTimeSuccessPopupOpen(false);
  };

  const handleConfirm = eventId => {
    const data = {
      selectedDate: selectedDateTime.date,
      selectedTime: convertToTimeStamp(
        selectedDateTime.date,
        selectedDateTime.time
      ).toString(),
      calendarEventStatus: "ACCEPTED",
    };
    const selectedEndTime = convertToTimeStamp(
      selectedDateTime.date,
      getEndTime(selectedDateTime.time, inviteEventData.calendarEvent.duration)
    ).toString();

    setSelectedDatetime({
      ...selectedDateTime,
      newTime: new Date(parseInt(data.selectedTime)),
      newEndTime: new Date(parseInt(selectedEndTime)),
    });
    setConfirmButtonLoading(true);
    confirmInterviewInvite(eventId, data)
      .then(result => {
        dispatch(actions.setAuthData({ eventId: null }));
        setConfirmButtonLoading(false);
        handlTimeSelectPopupClose();
        setTimeSuccessPopupOpen(true);
      })
      .catch(error => {
        console.log(error);
        setConfirmButtonLoading(false);
        handlTimeSelectPopupClose();
        toastContainer(error.message, "error");
      });
  };

  const handleProposeNewTime = () => {};

  const NoDataComponent = () => {
    return (
      <IconBox>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Icon
            sx={{
              backgroundColor: sosPrimary[50],
              borderRadius: "50%",
              p: 2,
              color: sosPrimary[500],
            }}
          >
            <TodayIcon />
          </Icon>
        </Box>

        <Box>
          <Typography
            variant="lable_large"
            color="dark"
            align="center"
            sx={{ display: "block", pt: 3 }}
          >
            No events yet
          </Typography>
        </Box>
        <Box>
          <Typography
            variant="caption"
            align="center"
            sx={{ display: "block", pt: 1, color: "secondary.outlineXLight" }}
          >
            Your events scheduled in StartupOS will show up here.
          </Typography>
        </Box>
      </IconBox>
    );
  };

  return (
    <>
      {" "}
      <Stack
        sx={theme => ({
          border: `1px solid ${theme.palette.secondary.outline2}`,
          background: theme.palette.secondary.white,
          borderRadius: theme.shape.standard.borderRadius,
          [theme.breakpoints.down("md")]: {
            borderRadius: 0,
          },
        })}
      >
        <SelectInterviewTimePopup
          open={interviewPopupOpen}
          onClose={handlTimeSelectPopupClose}
          data={inviteEventData}
          selectedDateTime={selectedDateTime}
          setSelectedDatetime={setSelectedDatetime}
          onConfirm={handleConfirm}
          confirmButtonLoading={confirmButtonLoading}
        />
        {selectedDateTime && (
          <TimeSelectedSuccessPopup
            open={timeSuccessPopupOpen}
            onClose={handlTimeSelectSuccessPopupClose}
            selectedDateTime={selectedDateTime}
            startupName={inviteEventData?.companyModel?.companyName}
          />
        )}

        <Box
          sx={{
            maxWidth: theme.breakpoints.values.xl,
            margin: "auto",
            width: "100%",
          }}
        >
          <EventCard sx={{ m: 3 }}>
            <Grid container sx={{ pb: 2, pl: 1 }}>
              {!events.length && (
                <Grid textAlign={"left"} item xs={12}>
                  <Typography variant="title_large">Upcoming Events</Typography>
                </Grid>
              )}
            </Grid>

            {events?.length > 0 ? (
              <Box
                sx={{
                  [theme.breakpoints.down("sm")]: {
                    display: "none",
                  },
                }}
              >
                <EventWebCarousel
                  eventsData={events}
                  EventCardActionComponent={EventCardActionComponent}
                />
                <EventMobileCarousel
                  eventsData={events}
                  EventCardActionComponent={EventCardActionComponent}
                />
              </Box>
            ) : (
              <Grid
                item
                md={12}
                sm={12}
                xs={12}
                lg={12}
                sx={{ mt: 3 }}
                justifyContent="center"
              >
                <NoDataComponent />
              </Grid>
            )}
          </EventCard>
        </Box>
      </Stack>
      <EventDetailsPopup
        currentEventDetails={currentEventDetails}
        getCandidateInvites={getCandidateInvites}
        setOpen={setOpen}
        open={open}
      />
    </>
  );
}
