import React, { useEffect, useState } from "react";
import { Button, Typography, Box } from "@mui/material";
import { setFeedData } from "../../../modules/actions";
import { useDispatch, useSelector } from "react-redux";
import CommonSlideDialog from "../../../common/CommonSlideDialog";
import DragDropFileUploader from "../../../common/DragDropFileUploader";
import { CREATE_POST_ICON, EDIT_PURPLE_ICON, RED_DELETE_ICON } from "../../../constants/image";
import { createFeedPostApiCall, editFeedPostApiCall, deleteFeedPostApiCall } from "../../../containers/Feed/Functions/common";
import LoadingButton from "../../../common/Loadingbutton";
import ReusableQuill from "../../../common/ReusableQuill";
import { handleMixPanel } from "../../../utils/mixPanelEventHandling";
import { getRoleValue } from "../../../utils/commonFunctions";
import UpdatedMetaLink from "../../UpdatedMetaLink";

const PostOperationComponet = ({}) => {
	const [loading, setLoading] = useState(false);
	const [postDescription, setPostDescription] = React.useState("");
	const [postFileLink, setPostFileLink] = React.useState("");
	const [documentName, setDocumentName] = useState("");
	const [documentSize, setDocumentSize] = useState("");
	const [uploadingFileType, setUploadingFileType] = useState("");
	const PostOperationIsOpen = useSelector((state) => state?.feedPost?.createPostOpen);
	const allowedFileTypes = useSelector((state) => state?.feedPost?.fileType);
	const currentPostOperationType = useSelector((state) => state?.feedPost?.postOperationType);
	const currentPostObject = useSelector((state) => state?.feedPost?.currentPostObj);
	const [version, setVersion] = useState(1); // maintaining version to re-render the script component after Reset to default action
	const authData = useSelector((state) => state?.auth);
	const feedData = useSelector((state) => state?.feedPost);
	const [metaLinkType, setMetaLinkType] = useState("OTHER");
	const { isPremiumPlanAccess, userType } = useSelector((store) => store?.auth);

	const dispatch = useDispatch();

	useEffect(() => {
		setMetaLinkType("OTHER");
		setPostDescription(currentPostObject?.description);
		if (currentPostObject?.attachments && currentPostObject?.attachments?.length > 0) {
			setPostFileLink(currentPostObject?.attachments[0]?.url);
			setDocumentName(currentPostObject?.attachments[0]?.name);
			setDocumentSize(currentPostObject?.attachments[0]?.size);
			setUploadingFileType(currentPostObject?.attachments[0]?.fileType);
		}
	}, [currentPostObject]);

	const handleClose = () => {
		if (PostOperationIsOpen == true) {
			dispatch(setFeedData({ createPostOpen: false, metaLinks: [], currentPostObj: {} }));
			setTimeout(() => {
				setPostDescription("");
				setPostFileLink("");
				setDocumentName("");
				setDocumentSize("");
				dispatch(setFeedData({ currentPostObj: null, postOperationType: "", metaLinks: [] }));
			}, 1000);
		} else {
			dispatch(setFeedData({ createPostOpen: true, currentPostObj: null, postOperationType: "", metaLinks: [], currentPostObj: {} }));
		}
	};

	const handlePostDescriptionChnge = (value) => {
		setPostDescription(value);
		setMetaLinkType("CREATE");
	};
	const handleMixPanelEvent = () => {
		handleMixPanel("Posted to Feed", {
			"Posting user name": authData?.userName,
			"Posting user id": authData?.userId,
			"Posting user role": getRoleValue(authData?.userType),
			subscription_status: isPremiumPlanAccess ? "Subscribed" : "Not Subscribed",
			user_type: userType,
		});
	};
	const handleCreatePost = () => {
		setVersion(() => version + 1);
		setLoading(true);
		const newAttachment = [
			{
				url: postFileLink,
				name: documentName,
				fileType: uploadingFileType,
				size: documentSize,
			},
		];
		let metaData = feedData?.metaLinks?.length > 0 ? JSON.stringify(feedData?.metaLinks) : null;
		const payload = {
			description: postDescription,
			attachments: postFileLink ? newAttachment : [],
			metaData: metaData,
		};

		createFeedPostApiCall(payload)
			.then((data) => {
				dispatch(setFeedData({ createPostOpen: false, metaLinks: [] }));
				handleMixPanelEvent();
				setTimeout(() => {
					setPostDescription("");
					setPostFileLink("");
					setDocumentName("");
					setDocumentSize("");
					dispatch(setFeedData({ currentPostObj: null, postOperationType: "", currentPostObj: {} }));
					setLoading(false);
				}, 1000);
			})
			.catch((error) => {
				setLoading(false);
			});
	};

	const handleEditPost = () => {
		const newAttachment = [
			{
				url: postFileLink,
				name: documentName,
				fileType: uploadingFileType,
				size: documentSize,
			},
		];
		let metaData = feedData?.metaLinks?.length > 0 ? JSON.stringify(feedData?.metaLinks) : null;

		const payload = {
			description: postDescription,
			attachments: postFileLink ? newAttachment : [],
			metaData: metaData,
		};
		setLoading(true);
		editFeedPostApiCall(currentPostObject?.id, payload)
			.then((data) => {
				dispatch(setFeedData({ createPostOpen: false, metaLinks: [], currentPostObj: {} }));
				setTimeout(() => {
					setPostDescription("");
					setPostFileLink("");
					setDocumentName("");
					setDocumentSize("");
					dispatch(setFeedData({ currentPostObj: null, postOperationType: "" }));
					setLoading(false);
				}, 1000);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const handleDeletePost = () => {
		setLoading(true);
		deleteFeedPostApiCall(currentPostObject?.id)
			.then((data) => {
				setLoading(false);
				dispatch(setFeedData({ createPostOpen: false, currentPostObj: {} }));
				setTimeout(() => {
					setPostDescription("");
					setPostFileLink("");
					setDocumentName("");
					setDocumentSize("");
					dispatch(setFeedData({ currentPostObj: null, postOperationType: "" }));
					setLoading(false);
				}, 1000);
			})
			.catch((error) => {
				console.log(error);
			});
	};
	const CeratePostTitle = () => {
		return (
			<Box sx={{ display: "flex", flexDirection: "column", gap: 3, mb: 3 }}>
				<Box height={25} width={25} component={"img"} src={CREATE_POST_ICON} alt="create-post" />
				<Typography variant={"Text/md/Semibold"}>Create Post</Typography>
			</Box>
		);
	};

	const CeratePostAction = () => {
		return (
			<LoadingButton
				loaderColor={"#7B61FF"}
				loadingStyles={{
					border: "4px solid",
					backgroundColor: "white",
					borderColor: "#7B61FF",
					height: "40px",
					px: 8,
				}}
				loading={loading}
				onClick={!loading && handleCreatePost}
				variant="DS1"
				disabled={postDescription && postDescription !== "<p><br></p>" ? false : true}
				
			>
				Create Post
			</LoadingButton>
		);
	};

	const EditPostTitle = () => {
		return (
			<Box sx={{ display: "flex", flexDirection: "column", gap: 3, mb: 3 }}>
				<Box height={25} width={25} component={"img"} src={EDIT_PURPLE_ICON} alt="create-post" />
				<Typography variant={"Text/md/Semibold"}>Edit Post</Typography>
			</Box>
		);
	};

	const EditPostAction = () => {
		return (
			<Box
				sx={{
					display: "flex",
					gap: 2,
				}}
			>
				<Button onClick={handleClose} variant="noOutlineNew" sx={{ height: "40px" }}>
					Cancel
				</Button>
				<LoadingButton
					loaderColor={"#7B61FF"}
					loadingStyles={{
						border: "4px solid",
						backgroundColor: "white",
						borderColor: "#7B61FF",
						height: "40px",
						px: 4,
					}}
					loading={loading}
					onClick={!loading && handleEditPost}
					variant="DS1"
					disabled={postDescription && postDescription !== "<p><br></p>" ? false : true}
					
				>
					save
				</LoadingButton>
			</Box>
		);
	};

	const DeletePostTitle = () => {
		return (
			<Box sx={{ display: "flex", flexDirection: "column", gap: 3, mb: 3 }}>
				<Box height={25} width={25} component={"img"} src={RED_DELETE_ICON} alt="create-post" />
				<Typography variant={"Text/xl/Semibold"}>Delete Post</Typography>
			</Box>
		);
	};

	const DeletePostAction = () => {
		return (
			<Box
				sx={{
					display: "flex",
					gap: 2,
				}}
			>
				<Button onClick={handleClose} variant="noOutlineNew" sx={{ height: "40px" }}>
					Cancel
				</Button>
				<LoadingButton
					loaderColor={"#D92D20"}
					loadingStyles={{
						border: "4px solid",
						backgroundColor: "white",
						borderColor: "#D92D20",
						height: "40px",
						px: 5,
					}}
					loading={loading}
					onClick={!loading && handleDeletePost}
					variant="RedRounded"
					sx={{ height: "40px" }}
				>
					Delete
				</LoadingButton>
			</Box>
		);
	};

	return (
		<Box>
			<CommonSlideDialog
				type={"EditPost"}
				disableBackDropClick={true}
				title={
					<>
						{currentPostOperationType && currentPostOperationType === "create" ? (
							<CeratePostTitle />
						) : currentPostOperationType === "edit" ? (
							<EditPostTitle />
						) : currentPostOperationType === "delete" ? (
							<DeletePostTitle />
						) : (
							<></>
						)}
					</>
				}
				titleVariant={"poppinsSemiBold30"}
				isOpen={PostOperationIsOpen}
				onClose={handleClose}
				contentStyle={{ p: 0 }}
				footerStyle={{ p: 0, pt: 3 }}
				titleStyle={{ alignItems: "baseline", px: 0, py: 0 }}
				maxWidth={"sm"}
				fullWidth
				PaperProps={{
					sx: (theme) => ({
						borderRadius: theme.shape.standard10.borderRadius,
						boxShadow: "0px 5px 16px rgba(0, 0, 0, 0.3)",
						p: 3,
						height: currentPostOperationType === "delete" ? "fit-content" : "560px",
					}),
				}}
				dialogAction={
					<>
						{currentPostOperationType && currentPostOperationType === "create" ? (
							<CeratePostAction />
						) : currentPostOperationType === "edit" ? (
							<EditPostAction />
						) : currentPostOperationType === "delete" ? (
							<DeletePostAction />
						) : (
							<></>
						)}
					</>
				}
			>
				{currentPostOperationType === "delete" ? (
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
						}}
					>
						<Typography variant="flyout_text">Are you sure you want to delete this Post? This action cannot be undone.</Typography>
					</Box>
				) : (
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							gap: 3,
						}}
					>
						<Typography variant="flyout_text">Description</Typography>
						<ReusableQuill id={"feed"} value={postDescription} onChange={handlePostDescriptionChnge} className={"editor-container"} />
						{postDescription && (
							<UpdatedMetaLink
								data={postDescription}
								feedObj={currentPostObject}
								type={currentPostOperationType === "create" ? "CREATE" : metaLinkType}
							/>
						)}
						<Box>
							<DragDropFileUploader
								postFileLink={postFileLink}
								setPostFileLink={setPostFileLink}
								fileGroup={"SOCIAL_POST"}
								fileType={allowedFileTypes}
								documentName={documentName}
								setDocumentName={setDocumentName}
								documentSize={documentSize}
								setDocumentSize={setDocumentSize}
								setUploadingFileType={setUploadingFileType}
								uploadingFileType={uploadingFileType}
							/>
						</Box>
					</Box>
				)}
			</CommonSlideDialog>
		</Box>
	);
};

export default PostOperationComponet;
