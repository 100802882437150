import React from "react";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

export const SingleSelect = ({ questionId, question, value, disabled = false, onChange, handleAnswer }) => {
	const { id, unique_name, label, enum_options } = question;

	const handleSelectChange = e => {
		onChange(e.target.value);
		handleAnswer(questionId, id, e.target.value);
	};

	return (
		<FormControl fullWidth>
			<InputLabel id={unique_name}>{label ? label : "Select"}</InputLabel>
			<Select
				labelId={unique_name}
				id={unique_name}
				label={label ? label : "Select"}
				defaultValue={""}
				value={value ? value : ""}
				disabled={disabled}
				MenuProps={MenuProps}
				onChange={handleSelectChange}
			>
				{enum_options.map(option => (
					<MenuItem key={option.value} value={option.value}>
						{option.display_value}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};
