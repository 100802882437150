import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";

import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { InputAdornment, MenuItem, Select, Typography } from "@mui/material";
import JsxParser from "react-jsx-parser";
import numeral from "numeral";

import { StyledTextField } from "./styles";
import { StyledTableCell, StyledTableRow } from "./styles";
import CustomizedDatePicker from "../../../../../../../../components/Workspace/PowerUp/Datepicker";

export default function SelfServiceSubscriptionsTable({
  subscription,
  setSubscription,
  getNotesChurnRate,
}) {
  const onChange = e => {
    const formattedNumber = numeral(e.target.value).value();

    const number = numeral(formattedNumber)?.format("0,0");

    setSubscription({ ...subscription, arpu: number });
  };

  const getFormattedNotesValues = () => {
    if (subscription.primarilyBill === "Yearly") {
      return "<u>ARPU</u> <i>divided by</i> (<u>Expected Yearly Churn Rate</u> <i>divided by</i> <u>12</u>)";
    } else if (subscription.primarilyBill === "Quarterly") {
      return "<u>ARPU</u> <i>divided by</i> (<u>Expected Yearly Churn Rate</u> <i>divided by</i> <u>4</u>)";
    } else {
      return "<u>ARPU</u> <i>divided by</i> <u>Expected Yearly Churn Rate</u>";
    }
  };

  return (
    <TableContainer
      sx={theme => ({
        borderRadius: 12,
        border: `1px solid ${theme.palette.secondary.borderLight}`,
        [theme.breakpoints.down("md")]: {
          border: `1px solid ${theme.palette.secondary.dark}`,
        },
      })}
    >
      <Table sx={{ minWidth: 700 }}>
        <TableHead>
          <TableRow>
            <StyledTableCell align="center" width={"40%"}>
              <Typography variant="label_medium"> Business Model</Typography>
            </StyledTableCell>
            <StyledTableCell align="center" width={"30%"}>
              <Typography variant="label_medium">Output</Typography>
            </StyledTableCell>
            <StyledTableCell align="center" width={"40%"}>
              <Typography variant="label_medium">Notes</Typography>
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <StyledTableRow>
            <StyledTableCell>
              <Typography variant="sys_light_on_surface">
                Average Monthly Subscription Price (ARPU)
              </Typography>
            </StyledTableCell>
            <StyledTableCell>
              <Typography color={"primary"} variant="sys_light_on_surface">
                <StyledTextField
                  variant="outlined"
                  value={subscription.arpu}
                  onChange={onChange}
                  onKeyPress={event => {
                    if (event?.key === "-") {
                      event.preventDefault();
                    }
                  }}
                  InputProps={{
                    disableUnderline: true,
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        sx={{ p: 0, width: 0, pr: "1px" }}
                      >
                        <Typography variant="h5" color="primary">
                          ${" "}
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    "&& fieldset": {
                      border: "none",
                    },
                  }}
                />
              </Typography>
            </StyledTableCell>
            <StyledTableCell>
              <Typography variant="caption">
                The average revenue per user, per month. If you have multiple
                plans, provide an average of the plan price.
              </Typography>
            </StyledTableCell>
          </StyledTableRow>

          <StyledTableRow>
            <StyledTableCell>
              <Typography variant="sys_light_on_surface">
                How you will primarily bill your customers
              </Typography>
            </StyledTableCell>
            <StyledTableCell sx={{ p: 0 }}>
              <Select
                value={subscription.primarilyBill}
                variant="outlined"
                color="primary"
                onChange={e =>
                  setSubscription({
                    ...subscription,
                    primarilyBill: e.target.value,
                  })
                }
              >
                <MenuItem value={"Yearly"}>Yearly</MenuItem>
                <MenuItem value={"Quarterly"}>Quarterly</MenuItem>
                <MenuItem value={"Monthly"}>Monthly</MenuItem>
              </Select>
            </StyledTableCell>
            <StyledTableCell></StyledTableCell>
          </StyledTableRow>

          <StyledTableRow>
            <StyledTableCell>
              <Typography variant="sys_light_on_surface">
                Expected Launch
              </Typography>
            </StyledTableCell>
            <StyledTableCell>
              <CustomizedDatePicker
                value={subscription?.expectedLaunchDate}
                onChange={value =>
                  setSubscription({
                    ...subscription,
                    expectedLaunchDate: value,
                  })
                }
                Component={params => (
                  <StyledTextField
                    {...params}
                    spellCheck="true"
                    variant="outlined"
                    color="primary"
                    sx={{
                      "&& fieldset": {
                        border: "none",
                      },
                      width: 1,
                    }}
                  />
                )}
              />
            </StyledTableCell>
            <StyledTableCell></StyledTableCell>
          </StyledTableRow>

          <StyledTableRow>
            <StyledTableCell>
              <Typography variant="sys_light_on_surface">
                Expected Yearly Churn Rate
              </Typography>
            </StyledTableCell>
            <StyledTableCell>
              <Typography color={"primary"} variant="sys_light_on_surface">
                {subscription.churnRate}.00%
              </Typography>
            </StyledTableCell>
            <StyledTableCell>
              <Typography variant="caption">{getNotesChurnRate()}</Typography>
            </StyledTableCell>
          </StyledTableRow>

          <StyledTableRow>
            <StyledTableCell>
              <Typography variant="sys_light_on_surface">
                Expected Lifetime Value
              </Typography>
            </StyledTableCell>
            <StyledTableCell>
              <Typography color={"primary"} variant="sys_light_on_surface">
                ${numeral(subscription.expectedLifetimeValue)?.format("0,0")}
              </Typography>
            </StyledTableCell>
            <StyledTableCell>
              <Typography variant="caption">
                <JsxParser
                  components={{ Typography }}
                  jsx={getFormattedNotesValues()}
                />
              </Typography>
            </StyledTableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
