import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import MultiTagDisplayButton from "../MultiTagDisplayButton";
import { useDispatch } from "react-redux";
import { setUserProfileData } from "../../../modules/actions/userProfileActions";

const UserProfileTagList = ({ tagList }) => {
	const dispatch = useDispatch();

	const TagItem = ({ icon, value, isSvg, type }) => {
		const handleOnClick = () => {
			dispatch(
				setUserProfileData({
					isPopUpOpen: true,
					popUpData: value,
					popUpType: type,
				})
			);
		};
		return (
			<Box
				sx={{
					display: "flex",
					gap: 1,
					alignItems: "center",
				}}
				onClick={Array.isArray(value) && value?.length > 0 && value?.length > 1 ? handleOnClick : () => {}}
			>
				<Box
					component={"img"}
					src={icon}
					alt="icon"
					sx={{
						height: "24px",
						width: "24px",
					}}
				/>

				{Array.isArray(value) && value?.length > 0 ? (
					value?.length > 1 ? (
						<Box>
							<MultiTagDisplayButton tagValue={value} tagType={type} />
						</Box>
					) : (
						<Typography variant="Text/sm/Regular" component={"div"}>
							{value[0]}
						</Typography>
					)
				) : (
					<Typography
						variant="Text/sm/Regular"
						component={"div"}
						sx={{
							maxWidth: "200px",
							overflow: "hidden",
						}}
					>
						{value}
					</Typography>
				)}
			</Box>
		);
	};
	return (
		<Stack spacing={1}>
			{tagList && tagList?.length > 0 ? (
				tagList?.map((item) => {
					return <TagItem icon={item?.icon} value={item?.value} isSvg={item?.isSvg} type={item?.type} />;
				})
			) : (
				<Typography>No Data</Typography>
			)}
		</Stack>
	);
};

export default UserProfileTagList;
