import React, { Fragment, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { AppBar, Box, Toolbar, Typography } from "@mui/material";

import NewOnboardLogo from "../../Assets/new-onboard-logo.svg";
import { EditTabsItem } from "../../../StartupProfileEdit/style";
import ROUTES_PATH from "../../../../constants/routes";
import { useThemeContext } from "../../../../constants/Theme/ThemeContext";
import { useSelector } from "react-redux";

export const OnboardHeader = () => {
	const location = useLocation();
	const history = useHistory();
	const [currentTab, setCurrentTab] = useState("1");
	const { logoOverride, appNameOverride } = useThemeContext();
	// const searchParams = new URLSearchParams(location.search);
	// const utm_source = searchParams.get("utm_source");
	// const utm_campaign = searchParams.get("utm_campaign");
	// const utm_medium = searchParams.get("utm_medium");
	// const partner = searchParams.get("partner");
	// const { utmSource, utmCampaign, utmMedium, partner } = useSelector((store) => store.auth);

	useEffect(() => {
		if (location?.pathname === ROUTES_PATH.LOG_IN) {
			setCurrentTab("2");
		} else {
			setCurrentTab("1");
		}
	}, [location?.pathname]);

	return (
		<AppBar
			component="nav"
			sx={(theme) => ({
				padding: "32px 64px 32px 64px",
				background: "#F5F9FA",
				borderBottom: "1px solid #D0D5DD",
				[theme.breakpoints.down("sm")]: {
					padding: "32px 16px 32px 16px",
				},
			})}
		>
			<Toolbar
				sx={(theme) => ({
					p: "0 !important",
					minHeight: "57px !important",
					[theme.breakpoints.down("sm")]: {
						minHeight: "136px !important",
						flexDirection: "column",
						alignItems: "flex-start",
					},
				})}
			>
				<Box display="flex" alignItems="center" gap={1}>
					{logoOverride ? (
						<Fragment>
							<img src={logoOverride} height={57} alt={appNameOverride} />
							<Typography variant="Text/lg/Semibold" color="#000">
								{appNameOverride}
							</Typography>
						</Fragment>
					) : (
						<Fragment>
							<img src={NewOnboardLogo} width={57} height={57} alt="Onboarding Logo" />
							<Typography variant="Text/lg/Semibold" color="#000">
								StartupOS
							</Typography>
						</Fragment>
					)}
				</Box>

				<Box sx={{ flexGrow: 1 }} />
				<Box sx={{ display: "flex", gap: 1.5 }}>
					<EditTabsItem
						selected={currentTab === "1" ? true : false}
						onClick={() => {
							history.push(ROUTES_PATH.SIGN_UP);
						}}
						sx={{
							padding: "0px 16px !important",
						}}
						disableRipple
						label={
							<Typography
								variant="Text/sm/Semibold"
								sx={{
									color: "inherit",
									background: "inherit",
									borderRadius: "100px",
								}}
							>
								Create Account
							</Typography>
						}
					/>

					<EditTabsItem
						selected={currentTab === "2" ? true : false}
						onClick={() => {
							history.push(ROUTES_PATH.LOG_IN);
						}}
						sx={{
							padding: "0px 16px !important",
						}}
						disableRipple
						label={
							<Typography
								variant="Text/sm/Semibold"
								sx={{
									color: "inherit",
									background: "inherit",
									borderRadius: "100px",
								}}
							>
								Login
							</Typography>
						}
					/>
				</Box>
			</Toolbar>
		</AppBar>
	);
};
