import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Box, CircularProgress, IconButton, Typography } from "@mui/material";
import FileDownloadRoundedIcon from "@mui/icons-material/FileDownloadRounded";
import theme from "../../constants/Theme";
import MuiTooltip from "../MuiTooltip";

const DownloadMenu = ({ loading, handleExportClick }) => {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [isLoading, setIsloading] = React.useState(loading);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const handleMenuItemClick = (type) => {
		handleExportClick(type);
	};

	React.useEffect(() => {
		setIsloading(loading);
	}, [loading]);

	React.useEffect(() => {
		if (isLoading) {
			handleClose();
		}
	}, [isLoading]);

	return (
		<Box>
			<MuiTooltip title="Download">
				<IconButton
					id="basic-button"
					aria-controls={open ? "basic-menu" : undefined}
					aria-haspopup="true"
					aria-expanded={open ? "true" : undefined}
					onClick={handleClick}
					sx={{
						"&:hover": {
							background: "rgba(73, 69, 79, 0.08)",
							p: 1,
						},
					}}
				>
					{isLoading ? <CircularProgress size={25} /> : <FileDownloadRoundedIcon sx={{ color: "secondary.main" }} />}
				</IconButton>
			</MuiTooltip>

			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					"aria-labelledby": "basic-button",
				}}
				PaperProps={{
					sx: {
						boxShadow: theme.palette.primary.elevationLight2,
					},
				}}
				transformOrigin={{ vertical: 10, horizontal: 10 }}
			>
				<Box>
					<MenuItem onClick={() => handleMenuItemClick("png")}>
						<Typography variant="bodyLarge">PNG</Typography>
					</MenuItem>
					<MenuItem onClick={() => handleMenuItemClick("pdf")}>
						<Typography variant="bodyLarge">PDF</Typography>
					</MenuItem>
				</Box>
			</Menu>
		</Box>
	);
};

export default DownloadMenu;
