import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, CircularProgress, Typography } from "@mui/material";
import services from "../../../services";
import { CheckCircle } from "@mui/icons-material";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ROUTES_PATH from "../../../constants/routes";

export default function CopyToProfileModal({ newValue }) {
	const [open, setOpen] = React.useState(false);
	const [loading, setLoading] = React.useState(false);
	const [companyProfile, setCompanyProfile] = React.useState(null);
	const [prevResponse, setPrevResponse] = React.useState(null);
	const [complete, setComplete] = React.useState(false);

	const history = useHistory();

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
		setLoading(false);
		setPrevResponse(null);
		setComplete(false);
	};

	const handleUpdate = async () => {
		let payload = {
			...companyProfile,
			founderStory: newValue,
		};
		setLoading(true);
		let response = await services.updateCompanyProfile(payload).catch((e) => {
			console.log("Submit Error", e);
		});

		console.log(response);

		if (response.data.message === "Success") {
			setComplete(true);
		}

		setLoading(false);
	};

	const getProfileObject = async () => {
		setLoading(true);
		let response = await services.getCompanyProfile().catch((e) => console.log(e));
		setLoading(false);
		setCompanyProfile(response?.data?.data);
		setPrevResponse(
			response?.data?.data?.founderStory?.length > 0 && response?.data?.data?.founderStory !== "​\n" ? response?.data?.data?.founderStory : null
		);
	};

	React.useEffect(() => {
		if (open) {
			getProfileObject();
		}
	}, [open]);

	return (
		<div>
			<Button
				variant="outlined"
				sx={{
					whiteSpace: "nowrap",
				}}
				onClick={handleClickOpen}
			>
				Copy to Profile
			</Button>
			<Dialog
				open={open}
				onClose={handleClose}
				PaperProps={{
					sx: {
						flex: 1,
						maxWidth: complete ? "600px" : "1200px",
					},
				}}
			>
				{open && (
					<React.Fragment>
						{!complete && (
							<DialogTitle>
								<Typography variant="title_large" component={"h4"}>
									Copy to Profile
								</Typography>
							</DialogTitle>
						)}
						{loading ? (
							<Box
								sx={{
									height: "400px",
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
								}}
							>
								<CircularProgress />
							</Box>
						) : !complete ? (
							<React.Fragment>
								<DialogContent>
									<Typography variant="title_small" component={"p"} sx={{ mt: 1, mb: 2 }}>
										{prevResponse
											? "Do you want to replace your current founder story with the new one created?"
											: "Do you want to add this as your founder story on your startup profile?"}
									</Typography>

									{prevResponse && (
										<React.Fragment>
											<Typography variant="title_medium" component={"h6"}>
												Current Founder Story
											</Typography>
											<Typography variant="title_small" component={"p"} sx={{ my: 1 }}>
												{prevResponse}
											</Typography>
										</React.Fragment>
									)}

									<Typography variant="title_medium" component={"h6"}>
										New Founder Story
									</Typography>
									<Typography variant="title_small" component={"p"} sx={{ my: 1 }}>
										{newValue}
									</Typography>
								</DialogContent>
								<DialogActions>
									<Box
										sx={{
											display: "flex",
											alignItems: "center",
											justifyContent: "flex-end",
											gap: 2,
										}}
									>
										<Button onClick={handleClose} variant="outlined">
											Cancel
										</Button>
										<Button onClick={handleUpdate} autoFocus sx={{ whiteSpace: "nowrap", minWidth: "unset" }}>
											{prevResponse ? "Yes, Replace It" : "Yes, Add It"}
										</Button>
									</Box>
								</DialogActions>
							</React.Fragment>
						) : (
							<React.Fragment>
								<DialogContent>
									<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", gap: 3 }}>
										<CheckCircle color="success" sx={{ height: "80px", width: "80px" }} />
										<Typography variant="title_medium" component={"h4"} sx={{ mb: 2 }}>
											Founder Story Updated
										</Typography>
									</Box>
								</DialogContent>
								<DialogActions>
									<Box
										sx={{
											display: "flex",
											alignItems: "center",
											justifyContent: "flex-end",
											gap: 2,
										}}
									>
										<Button onClick={handleClose} variant="outlined">
											Close
										</Button>
										<Button
											onClick={() => {
												history.push(ROUTES_PATH.MY_STARTUP_PROFILE);
											}}
											sx={{ whiteSpace: "nowrap", minWidth: "unset" }}
										>
											Go To My Profile
										</Button>
									</Box>
								</DialogActions>
							</React.Fragment>
						)}
					</React.Fragment>
				)}
			</Dialog>
		</div>
	);
}
