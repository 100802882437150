import { Avatar } from "@mui/material";
import { styled } from "@mui/material/styles";

export const NumberContainer = styled(Avatar)(
  ({ theme, bordercolor, bgColor = theme.palette.secondary.white }) => ({
    width: 32,
    height: 32,
    color: theme.palette.text.primary,
    backgroundColor: bgColor,
    background: bgColor,

    [theme.breakpoints.down("md")]: {
      backgroundColor: theme.palette.secondary.backgroundGradient,
    },
    border: `1px solid ${bordercolor}`,
  })
);
