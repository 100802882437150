import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Divider,
  CircularProgress,
  useTheme,
  Button,
} from "@mui/material";
import { connect } from "react-redux";
import PowerUPLayout from "../../../layout/PowerUP";
import Sidebar from "../../../components/Workspace/PowerUp/Sidebar";
import BreadcrumbsComp from "../PowerUP/IdeaValidation/Components/BreadcrumbsComp";
import {
  getDefaultProblemSolutionScriptData,
  getPowerUPData,
  getSidebarContent,
  getMarketPageDetails,
  getMarketTestResponses,
  getDefaultValuePropScriptData,
  getDefaultPersonalScriptData,
} from "../PowerUP/IdeaValidation/Functions/common";
import { DetailsOverview } from "./Components/DetailsOverview";
import DialogComponent from "../PowerUP/IdeaValidation/Components/Dialog";
import * as actions from "../../../modules/actions";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import InterviewScript from "../PowerUP/IdeaValidation/Components/InterviewScript";
import MessageHandlerModal from "../../../common/modal/MessageHandler";
import services from "../../../services";
import { TEST_TYPE } from "../../../constants/powerup-data";
import { useSelector } from "react-redux";
import { getScriptDescriptionData } from "../../../utils/commonFunctions";

const MainContent = ({
  powerupDetails,
  marketDetails,
  marketTestId,
  sidebar_content,
  marketResponse,
}) => {
  const theme = useTheme();

  return (
    <Box>
      <Box>
        <DetailsOverview
          marketResponse={marketResponse}
          marketData={marketDetails}
          marketTestId={marketTestId}
        />
      </Box>
    </Box>
  );
};

const MarketTestIdeaValidationDetails = ({
  showInterviewScriptDialog,
  setInterviewScriptDialog,
  powerupScriptDetails,
  setPowerupScriptDetails,
  setMarketTest,
}) => {
  const [powerupDetails, setPowerupDetails] = useState("");
  const [powerupDetailsLoader, setPowerupDetailsLoader] = useState(false);
  const [SIDEBARDATA, setSidebarData] = useState({});
  const [discardConfirmDialog, setDiscardConfirmDialog] = useState(false);
  const [deleteConfirmDialog, setDeleteConfirmDialog] = useState(false);
  const marketTest = useSelector(store => store.powerUp?.marketTest);
  const [scriptData, setScriptData] = useState(
    getScriptDescriptionData(marketTest?.powerUpModel?.powerUpName)
  );
  const [currentModule, setCurrentModule] = useState();
  const [updatePowerupDetails, setUpdatePowerupDetails] = useState(false);
  const [getChildScriptData, setChildScriptData] = useState(false);
  const marketTestUuid = TEST_TYPE[0].uuid;
  const powerUpId = "4";
  const params = useParams();
  const marketTestId = parseInt(params.id);
  const [marketDetails, setMarketDetails] = useState("");
  const [marketResponse, setMarketResponse] = useState(null);
  const [inputChange, setInputChange] = useState(false);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });

    setPowerupDetailsLoader(true);
    getPowerUPData(powerUpId).then(res => {
      setPowerupDetails(res);
    });
    getSidebarContent(powerUpId).then(res => {
      setSidebarData(res);
    });
    getMarketPageDetails(marketTestUuid).then(res => {
      setMarketDetails(res);
    });
    getMarketTestResponses(marketTestUuid, marketTestId).then(res => {
      res.moduleResponseModels.sort((a, b) => {
        return a.id > b.id ? 1 : -1;
      });
      setMarketResponse(res);
      setMarketTest(res);
    });
    setPowerupDetailsLoader(false);
  }, [updatePowerupDetails]);

  useEffect(() => {
    const fetchedPowerupScriptDetails =
      powerupScriptDetails?.data?.moduleStepResponses?.find(
        moduleStep => moduleStep?.isLastStep === true
      );

    if (fetchedPowerupScriptDetails) {
      setCurrentModule({
        ...fetchedPowerupScriptDetails,
        responseId: powerupScriptDetails?.data?.id,
      });

      if (fetchedPowerupScriptDetails?.answers) {
        setScriptData(
          JSON.parse(fetchedPowerupScriptDetails?.answers)?.interviewScript
        );
      }
    }
  }, [powerupScriptDetails]);

  const handleSaveAction = updatedScriptData => {
    const currentAnswer = JSON.parse(currentModule.answers);

    const data = {
      moduleResponseId: currentModule.responseId,
      stepNo: 2,
      answers: JSON.stringify({
        ...currentAnswer,
        interviewScript: updatedScriptData || scriptData,
      }),
      isLastStep: true,
      isCompleted: true,
      moduleStepId: currentModule.moduleStep?.id,
    };
    updateModuleStepResponseApiCall(currentModule.responseId, data);
  };

  const handleDeleteAction = (cb = () => {}) => {
    const data = {
      moduleResponseId: currentModule.responseId,
      stepNo: 2,
      answers: JSON.stringify({ interviewScript: [] }),
      isLastStep: true,
      isCompleted: true,
      moduleStepId: currentModule.moduleStep?.id,
    };
    updateModuleStepResponseApiCall(currentModule.responseId, data, cb);
  };

  const updateModuleStepResponseApiCall = (moduleId, data, cb = () => {}) => {
    services
      .storeModuleStepResponses(moduleId, data)
      .then(result => {
        setPowerupScriptDetails({
          id: "build-script",
          data: result?.data?.data,
        });
        cb();
      })
      .catch(error => console.log(error));
  };

  const FooterComponent = () => {
    return (
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        sx={{ width: 1, px: 2 }}
      >
        <Box sx={{ width: 1 }}>
          <Button
            variant="nofill"
            sx={{ width: "max-content" }}
            startIcon={<DeleteOutlineOutlinedIcon />}
            onClick={() => setDeleteConfirmDialog(true)}
          >
            Delete
          </Button>
        </Box>

        <Box>
          <Button
            sx={{ width: "max-content" }}
            onClick={() => {
              setChildScriptData(!getChildScriptData);
              discardSuccessDialogHandler();
            }}
          >
            Save
          </Button>
        </Box>
      </Box>
    );
  };

  const discardSuccessDialogHandler = () => {
    setDiscardConfirmDialog(false);
    setInterviewScriptDialog(false);
  };

  const discardCancelDialogHandler = () => {
    setDiscardConfirmDialog(false);
  };

  const deleteSuccessDialogHandler = () => {
    handleDeleteAction(() => setUpdatePowerupDetails(true));
    setDeleteConfirmDialog(false);
    setInterviewScriptDialog(false);
  };

  const deleteCancelDialogHandler = () => {
    setDeleteConfirmDialog(false);
  };
  const getChildData = data => {
    handleSaveAction(data);
  };

  const resetAction = () => {
    setScriptData(
      getScriptDescriptionData(marketTest.powerUpModel.powerUpName)
    );
  };

  const discardPopupHandler = () => {
    if (inputChange) {
      setDiscardConfirmDialog(true);
    } else {
      setInterviewScriptDialog(false);
    }
    setInputChange(false);
  };

  return (
    <>
      <MessageHandlerModal
        isOpen={discardConfirmDialog}
        text={"Are you sure you want to discard the changes you made?"}
        messageType={"primary"}
        cancelText={"No thanks"}
        okText={"Discard"}
        style={{
          maxWidth: "450px",
        }}
        onOk={discardSuccessDialogHandler}
        onCancel={discardCancelDialogHandler}
      />

      <MessageHandlerModal
        isOpen={deleteConfirmDialog}
        heading="Delete"
        text={"Are you sure you want to delete this?"}
        messageType={"primary"}
        cancelText={"No thanks"}
        okText={"Delete"}
        style={{
          maxWidth: "450px",
        }}
        onOk={deleteSuccessDialogHandler}
        onCancel={deleteCancelDialogHandler}
      />

      <DialogComponent
        isOpen={showInterviewScriptDialog}
        onClose={discardPopupHandler}
        title={"Your Interview Script"}
        dialogAction={<FooterComponent />}
        titleVariant="largeTitle"
        sx={theme => ({
          display: "flex",
          width: 1,
          flexDirection: "column",
          borderRadius: theme.shape.standard.borderRadius,
          "& .MuiDialog-paper": {
            width: 1,
            borderRadius: theme.shape.standard.borderRadius,
          },
        })}
        contentStyle={{
          mt: 2,
        }}
        footerStyle={{
          py: 2,
        }}
        disableBackDropClick
      >
        <Box>
          <Box
            sx={[
              theme => ({
                [theme.breakpoints.up("md")]: {
                  border: `1px solid ${theme.palette.secondary.dark}`,
                  py: 2,
                  px: 3,
                },
                borderRadius: theme.shape.standard.borderRadius,
              }),
            ]}
          >
            <InterviewScript
              scriptConfigData={scriptData}
              customSaveButton={true}
              resetAction={resetAction}
              getChildData={getChildData}
              getChildEventData={getChildScriptData}
              setInputChange={setInputChange}
            />
          </Box>
        </Box>
      </DialogComponent>

      <PowerUPLayout
        main={
          <Fragment>
            {powerupDetailsLoader ? (
              <Box justifyContent={"center"} sx={{ display: "flex", mt: 18 }}>
                <CircularProgress />
              </Box>
            ) : (
              <MainContent
                marketResponse={marketResponse}
                powerupDetails={powerupDetails}
                marketDetails={marketDetails}
                marketTestId={marketTestId}
                sidebar_content={powerupDetails}
              />
            )}
          </Fragment>
        }
        sidebar={<Sidebar sidebar_content={powerupDetails} />}
        breadcrumbs={<BreadcrumbsComp powerupDetails={powerupDetails} />}
      />
    </>
  );
};

const mapStoreToProps = ({ powerUp }) => ({
  showInterviewScriptDialog: powerUp.showInterviewScriptDialog,
  powerupScriptDetails: powerUp.powerupDetails,
});

const mapDispatchToProps = dispatch => ({
  setInterviewScriptDialog: updatedData =>
    dispatch(actions.setInterviewScriptDialog(updatedData)),
  setPowerupScriptDetails: updatedData =>
    dispatch(actions.setPowerupDetails(updatedData)),
  setMarketTest: data => dispatch(actions.setMarketTest(data)),
});

export default connect(
  mapStoreToProps,
  mapDispatchToProps
)(MarketTestIdeaValidationDetails);
