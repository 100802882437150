import { Typography, Box, Divider, Grid, Card, Button, CircularProgress, useMediaQuery, MenuItem } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import LoopIcon from "@mui/icons-material/Loop";
import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined";
import FileDownloadRoundedIcon from "@mui/icons-material/FileDownloadRounded";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { categories } from "../PowerUP/ProblemSolution/functions/common";
import theme from "../../../constants/Theme";
import PowerupExcerciseTitleBox from "../../../components/Workspace/PowerUp/PowerupTitleBox";
import constants, { POWERUP_TEST_DATA } from "../../../constants";
import { TUNGSTEN_ICON } from "../../../constants/image";
import services from "../../../services";
import { sosPrimary } from "../../../constants/Theme/colors";
import ProblemSolutionSummaryBox from "../../../components/Workspace/PowerUp/ProblemSolutionSummaryBox";
import ProblemSolutionExport, { ProblemSolutionExportPDF } from "../../../components/Workspace/PowerUp/ProblemSolutionExport";
import GoogleTrendsContainer from "../PowerUP/ProblemSolution/Components/GoogleTrends/Container";
import DownloadMenu from "../../../common/DownloadMenu";
import MessageHandlerModal from "../../../common/modal/MessageHandler";
import MuiTooltip from "../../../common/MuiTooltip";
import TemporaryDrawer from "../../../common/Drawer";
import { archiveApiCall, createNewVersionApiCall } from "../../../utils/api";
import TestPowerupBox from "../../../components/Workspace/PowerUp/TestPowerupBox";
import ROUTES_PATH from "../../../constants/routes";

function ProblemSolutionSummary({ moduleId, versionId, handleBackClick }) {
	const tabitems = constants.SUMMARY_TAB_ITEMS;
	const history = useHistory();
	const [loader, setLoader] = useState(false);
	const [currenttab, setcurrenttab] = useState(tabitems[0].key);
	const [stepsData, setStepsData] = useState([]);
	const summaryGridRef = useRef(null);
	const storeItems = useSelector((store) => store.powerUp?.powerUpExcercises);
	const [answersData, setAnswersData] = useState(storeItems.sort((a, b) => (a.stepNo > b.stepNo ? 1 : -1)));
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

	const [problemsData, setProblemsData] = useState([]);
	const [archiveConfirmDialog, setArchiveConfirmDialog] = useState(false);
	const [archiveDrawerOpen, setArchiveDrawerOpen] = useState(false);
	const [downloadDrawerOpen, setDownloadDrawerOpen] = useState(false);

	const [exportLoading, setexportLoading] = useState(false);

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		});
		setLoader(true);
		getExerciseDetailsApiCall();
		setcurrenttab(tabitems[0].key);
		setAnswersData(storeItems);

		storeItems[1].answers?.problems.map((item, index) => {
			item.title = `Problem ${index + 1}`;
			item.solutionList = Object.values(storeItems[2]?.answers)[index];
		});

		storeItems[0].answers.categoryId = categories.filter((cat) => cat.name === storeItems[0]?.answers?.category)[0].id;
		setProblemsData(storeItems[1]?.answers?.problems);
	}, []);
	const handleExportClick = async (type) => {
		if (type === "pdf") {
			setexportLoading(true);
			await ProblemSolutionExportPDF({
				problemSolutionList: problemsData,
				answersData: answersData[0]?.answers,
			});
			setexportLoading(false);
		} else {
			summaryGridRef.current.handleExport(type);
		}
	};
	//Api call to get powerup steps data
	const getExerciseDetailsApiCall = () => {
		services
			.getModulesSteps("md-prob-sol")
			.then((result) => {
				if (result.data?.data) {
					setLoader(false);
					let steps = result.data.data;
					steps.sort((a, b) => (a.stepNo > b.stepNo ? 1 : -1));
					steps.map((item) => {
						item.index = item.stepNo && item.stepNo <= 9 ? "0" + item.stepNo : item.stepNo;
						item.label = item.title;
						return item;
					});
					setStepsData(steps);
				}
			})
			.catch((err) => console.log(err));
	};
	const archiveCancelDialogHandler = () => {
		setArchiveConfirmDialog(false);
	};

	const archiveSuccessDialogHandler = () => {
		setArchiveConfirmDialog(false);
		let payload = {
			responseId: versionId,
			status: true,
		};
		archiveApiCall(payload, history);
	};
	const createNewVersion = (moduleId, uuid, history) => {
		createNewVersionApiCall(moduleId, uuid, history);
	};

	return (
		<>
			{loader ? (
				<Box justifyContent={"center"} sx={{ display: "flex", mt: 18 }}>
					<CircularProgress />
				</Box>
			) : (
				<Box>
					<Card
						sx={{
							display: "flex",
							flexDirection: "column",
						}}
					>
						<Box flexDirection={"column"}>
							<Box sx={{ mt: 3 }}>
								<Typography variant="largeTitle">Summary</Typography>
							</Box>
							<Box sx={{ mt: 3 }}>
								<Typography variant="subhead1">
									Congratulations! Below is a summary of the work you’ve completed in this PowerUp. To edit or review your completed steps,
									select the steps above to navigate back. You can take this PowerUp as many times as you’d like. Don’t forget to get feedback
									on your completed work.
								</Typography>
							</Box>
							<Box sx={{ mt: 3 }}>
								<TestPowerupBox title={POWERUP_TEST_DATA.title} description={POWERUP_TEST_DATA.description} />
							</Box>

							{/*Main Container*/}
							<Box
								sx={(theme) => ({
									borderRadius: theme.shape.standard5.borderRadius,
									p: 4,
									mt: 3,
									background: theme.palette.secondary.greyBg,

									[theme.breakpoints.down("md")]: {
										background: theme.palette.secondary.greyBg,
									},
								})}
							>
								<Box
									sx={{
										textAlign: "end",
										display: {
											sm: "none",
											xs: "none",
											md: "block",
											lg: "block",
											xl: "block",
										},
									}}
								>
									<DownloadMenu loading={exportLoading} handleExportClick={(type) => handleExportClick(type)} />
								</Box>
								<Box
									sx={{
										textAlign: "end",
										display: {
											sm: "block",
											xs: "block",
											md: "none",
											lg: "none",
											xl: "none",
										},
									}}
								>
									<FileDownloadRoundedIcon
										onClick={() => {
											setDownloadDrawerOpen(true);
										}}
										sx={{ color: "secondary.main" }}
									/>
								</Box>
								<Box>
									<ProblemSolutionExport
										problemSolutionList={problemsData}
										answersData={answersData[0]?.answers}
										ref={summaryGridRef}
									></ProblemSolutionExport>
								</Box>

								<Box>
									<PowerupExcerciseTitleBox
										iconUrl={TUNGSTEN_ICON}
										title={answersData[0]?.answers?.idea}
										subTitle={"Your Idea"}
										iconBackgroundColor={sosPrimary[500]}
										contentBackgroundColor={"transparent"}
										contentTextColor={sosPrimary[500]}
										isGradient={true}
									/>
								</Box>

								<Divider
									sx={{
										background: "rgba(124, 117, 127, 0.16)",
										mt: 1,
									}}
								/>
								<Box sx={{ mt: 2 }}>
									<Grid container>
										<Grid item sm={3} xs={3} md={2} lg={2}>
											<Typography variant="label_medium" color={theme.palette.secondary.black}>
												Type
											</Typography>
										</Grid>
										<Grid item sm={9} xs={9} md={10} lg={10}>
											<Typography
												sx={(theme) => ({
													border: `1px solid ${sosPrimary[500]}`,
													borderRadius: theme.shape.standard25.borderRadius,
													p: "2px 8px",
												})}
												variant="label_medium"
												color={sosPrimary[500]}
											>
												{answersData[0]?.answers?.type}
											</Typography>
										</Grid>
										<Grid item sm={3} xs={3} md={2} lg={2} sx={{ mt: 2 }}>
											<Typography variant="label_medium" color={theme.palette.secondary.black}>
												Category
											</Typography>
										</Grid>
										<Grid item sm={9} xs={9} md={10} lg={10} sx={{ mt: 2 }}>
											<Typography
												sx={(theme) => ({
													border: `1px solid ${sosPrimary[500]}`,
													borderRadius: theme.shape.standard25.borderRadius,
													p: "2px 8px",
												})}
												variant="label_medium"
												color={sosPrimary[500]}
											>
												{answersData[0]?.answers?.category}
											</Typography>
										</Grid>
									</Grid>
								</Box>
								<Divider
									sx={{
										background: "rgba(124, 117, 127, 0.16)",
										mt: 3,
									}}
								/>
								<Box sx={{ mt: 3 }}>
									<Typography variant="label_large" color={sosPrimary[500]}>
										Your Customer
									</Typography>
									<Box sx={{ mt: 1 }}>
										<Typography variant="bodyMedium">{answersData[0]?.answers?.customer.join(", ")}</Typography>
									</Box>
								</Box>
								<Divider
									sx={{
										background: "rgba(124, 117, 127, 0.16)",
										mt: 3,
									}}
								/>
								<Box sx={{ mt: 3 }}>
									<Typography variant="label_large" color={sosPrimary[500]}>
										Problem/Solution
									</Typography>
									<Box>
										{problemsData.map((item) => {
											return (
												<Box sx={{ mt: 3 }}>
													<ProblemSolutionSummaryBox problemSolutionObj={item} />
													<Divider
														sx={{
															background: theme.palette.text.primary,
															mt: 3,
															display: {
																xs: "block",
																sm: "block",
																md: "none",
																lg: "none",
															},
														}}
													/>
												</Box>
											);
										})}
									</Box>
								</Box>

								<Box sx={{ pt: 2 }}>
									<Typography variant="onsurface_subtext_primary">Insight Results</Typography>
									<Box sx={{ mt: 3 }}>
										<GoogleTrendsContainer
											category={storeItems[0]?.answers?.categoryId}
											keywords={storeItems[0]?.answers?.topicTags}
											id={"summary"}
										/>
									</Box>
								</Box>
							</Box>
							<Divider
								sx={{
									background: theme.palette.secondary.dark,
									mt: 3,
									display: {
										xs: "none",
										sm: "none",
										md: "block",
										lg: "block",
									},
								}}
							/>
							<Box
								sx={{
									mt: 3,
									alignItems: "center",
									display: { sm: "none", xs: "none", md: "flex" },
								}}
							>
								<MuiTooltip title="Archive">
									<ArchiveOutlinedIcon
										sx={{
											cursor: "pointer",
											color: "secondary.main",
											p: 1,
											"&:hover": {
												background: "rgba(73, 69, 79, 0.08)",
												borderRadius: "50%",
											},
										}}
										onClick={() => {
											setArchiveConfirmDialog(true);
										}}
									/>
								</MuiTooltip>

								<MuiTooltip title="Take again">
									<LoopIcon
										sx={{
											cursor: "pointer",
											ml: 1,
											color: "secondary.main",
											p: 1,
											"&:hover": {
												background: "rgba(73, 69, 79, 0.08)",
												borderRadius: "50%",
											},
										}}
										onClick={() => {
											createNewVersion(moduleId, "md-prob-sol", history);
										}}
									/>
								</MuiTooltip>

								<Box sx={{ ml: "auto" }}>
									<Button
										variant="outlined"
										onClick={() => {
											handleBackClick();
										}}
									>
										Back
									</Button>
								</Box>
								<Box sx={{ ml: 2 }}>
									<Button
										onClick={() => {
											history.push(ROUTES_PATH.STARTUPS_HOME);
										}}
									>
										Done
									</Button>
								</Box>
							</Box>
						</Box>
					</Card>

					<Box
						sx={{
							background: theme.palette.secondary.white,
							position: "fixed",
							bottom: 72,
							width: 1,
							textAlign: "center",
							alignItems: "center",
							py: 2,
							left: 0,
							display: { sm: "flex", xs: "flex", md: "none" },
						}}
					>
						<MoreHorizIcon
							sx={{
								borderRadius: "50%",
								border: `1px solid ${theme.palette.secondary.dark}`,
								mr: 1,
								ml: 2,
								p: 1,
							}}
							onClick={() => {
								setArchiveDrawerOpen(true);
							}}
						/>
						<Button
							variant="outlined"
							onClick={() => {
								handleBackClick();
							}}
						>
							Back
						</Button>
						<Button
							sx={{ ml: 1, mr: 2 }}
							onClick={() => {
								history.push(ROUTES_PATH.STARTUPS_HOME);
							}}
						>
							Done
						</Button>
					</Box>

					<TemporaryDrawer
						open={downloadDrawerOpen}
						onClose={() => {
							setDownloadDrawerOpen(false);
						}}
					>
						<Box sx={{ py: 3 }}>
							<MenuItem onClick={() => handleExportClick("png")}>
								<Typography variant="bodyLarge">PNG</Typography>
							</MenuItem>
							<MenuItem onClick={() => handleExportClick("pdf")}>
								<Typography variant="bodyLarge">
									PDF
									{exportLoading && <CircularProgress size={20} sx={{ mx: 1 }} />}
								</Typography>
							</MenuItem>
						</Box>
					</TemporaryDrawer>

					<TemporaryDrawer
						open={archiveDrawerOpen}
						onClose={() => {
							setArchiveDrawerOpen(false);
						}}
					>
						<Box sx={{ py: 3 }}>
							<MenuItem
								onClick={() => {
									setArchiveDrawerOpen(false);
									setArchiveConfirmDialog(true);
								}}
							>
								<ArchiveOutlinedIcon
									sx={{
										color: "secondary.main",
										p: 1,
									}}
								/>
								<Typography variant="label_xlarge" sx={{ ml: 2 }}>
									Archive
								</Typography>
							</MenuItem>
							<MenuItem
								onClick={() => {
									createNewVersion(moduleId, "md-prob-sol", history);
								}}
							>
								<LoopIcon
									sx={{
										color: "secondary.main",
										p: 1,
									}}
								/>
								<Typography variant="label_xlarge" sx={{ ml: 2 }}>
									Take again
								</Typography>
							</MenuItem>
						</Box>
					</TemporaryDrawer>
					<MessageHandlerModal
						isOpen={archiveConfirmDialog}
						heading="Archive"
						text={"Are you sure you want to archive this?"}
						messageType={"primary"}
						cancelText={"No thanks"}
						okText={"Archive"}
						style={{
							maxWidth: "450px",
						}}
						onOk={archiveSuccessDialogHandler}
						onCancel={archiveCancelDialogHandler}
					/>
				</Box>
			)}
		</>
	);
}

export default ProblemSolutionSummary;
