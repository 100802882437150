import React from "react";
import { Box, Divider, Typography } from "@mui/material";

export const Stepper = ({ step, totalStep = 4 }) => {
	return (
		<Box display="flex" alignItems="center" justifyContent="space-between" gap={2} mb={1.5}>
			<Typography variant="subhead1" color="#667085">
				{`${step} of ${totalStep}`}
			</Typography>
			<Divider sx={{ borderColor: "#D0D5DD", width: "calc(100% - 220px)" }} />
			<StepDots currentStep={step} totalStep={totalStep} />
		</Box>
	);
};

const StepDots = ({ currentStep, totalStep }) => {
	return (
		<Box display="flex" alignItems="center" gap={1.5}>
			{Array.apply(null, Array(totalStep)).map((item, index) => (
				<Box
					key={`step-${index}`}
					sx={{
						width: "12px",
						height: "12px",
						borderRadius: "100px",
						background: currentStep === index + 1 ? "#00CE7E" : "#D0D5DD",
					}}
				/>
			))}
		</Box>
	);
};
