import { Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Client } from "@twilio/conversations";
import { bindActionCreators } from "redux";
import { MainArea, HeaderArea } from "./styles";
import RightSidebar from "../../components/Workspace/Sidebar/RightSidebar";
import LeftSidebar from "../../components/Workspace/Sidebar/ARCHIVE_LeftSidebar";
import BottomNavbar from "../../components/Workspace/BottomNavbar";
import WorkspaceHeader from "../../components/Workspace/Header";
import ChatIcons from "../../components/Workspace/ChatIcons";
import { connect, useSelector, useDispatch } from "react-redux";
import * as actions from "../../modules/actions";
import { Box } from "@mui/material";
import { ApolloProvider } from "@apollo/client";
import { useLocation } from "react-router-dom";
import PowerupController from "../../components/NewPowerup/PowerupController";
import { actionCreators } from "../../containers/Messaging/store";
import { getToken } from "../../containers/Messaging/api";
import { handlePromiseRejection } from "../../containers/Messaging/helpers";

function LayoutWrapper({
	children,
	onLogout,
	userData,
	showAccountSettingPopup,
	closeAccountSettingsPopup,
	handleOpen,
	companyName,
	userName,
	companyLogoUrl,
}) {
	/*
    The following blocks are to observe "unread message count".
  */
	const [hasUpdates, setHasUpdates] = useState("");
	const [margin, setMargin] = useState(false);
	const location = useLocation();
	const unreadMessagesData = useSelector((state) => state?.unreadMessages);
	const unreadMessagesArray = Object.values(unreadMessagesData);
	const userID = String(useSelector((state) => state?.auth?.userId));
	const token = useSelector((state) => state.token);

	const dispatch = useDispatch();
	const { updateUnreadMessages, login } = bindActionCreators(actionCreators, dispatch);

	async function loadUnreadMessagesCount(convo) {
		let count = 0;
		try {
			count = (await convo.getUnreadMessagesCount()) ?? (await convo.getMessagesCount());
		} catch (e) {
			console.error("getUnreadMessagesCount threw an error", e);
		}
		updateUnreadMessages(convo.sid, count);
	}

	async function processMissedMessages(client) {
		let conversations = await getSubscribedConversations(client);
		if (conversations) {
			conversations.forEach((convo) => {
				loadUnreadMessagesCount(convo);
			});
		}
	}

	async function getSubscribedConversations(client) {
		let subscribedConversations = await client.getSubscribedConversations();
		let conversations = subscribedConversations.items;
		while (subscribedConversations.hasNextPage) {
			subscribedConversations = await subscribedConversations.nextPage();
			conversations = [...conversations, ...subscribedConversations.items];
		}
		return conversations;
	}

	useEffect(() => {
		getToken(userID)
			.then((token) => {
				if (token?.accessToken?.length > 0) login(token.accessToken);
				else throw "accessToken missing from getToken call.";
			})
			.catch((e) => {
				console.log("Something went wrong with messaging login.", e);
			});
	}, []);

	useEffect(() => {
		if (token) {
			const client = new Client(token);
			//On load, get any missed messages.
			processMissedMessages(client);
			//Message received listener.
			client.on("messageAdded", () => {
				//On message received, start counting missed messages,
				processMissedMessages(client);
			});
			client.on("tokenAboutToExpire", () => {
				if (userID && userID.length > 0) {
					getToken(userID)
						.then((token) => {
							if (token?.accessToken?.length > 0) login(token.accessToken);
							else throw "accessToken missing from getToken call.";
						})
						.catch((e) => {
							console.log("Something went wrong with messaging login.", e);
						});
				}
			});
		}
	}, [token]);

	useEffect(() => {
		if (unreadMessagesArray.length > 0) {
			let totalCount = unreadMessagesArray?.reduce((x, y) => {
				return x + y;
			});
			setHasUpdates(totalCount);
		}
	}, [unreadMessagesArray]);

	/* 
    End unread message count functions.
  */
	return (
		<Grid container height={"100vh"} width={"100vw"}>
			<Grid width={"230px"} item md={3} lg={2.3} xl={2} display={{ xs: "none", sm: "none", md: "block" }}>
				<LeftSidebar userData={userData} companyName={companyName} userName={userName} companyLogoUrl={companyLogoUrl} />
			</Grid>
			<Grid item xs={12} sm={12} md={9} lg={9.7} xl={10}>
				{location.pathname.includes("/workspace/power-ups/") ? (
					<>{children}</>
				) : (
					<>
						<Box
							sx={(theme) => ({
								mb: margin ? 40 : 10,
								maxWidth: "inherit",
								[theme.breakpoints.down("md")]: {
									mb: margin ? 25 : 10,
								},
							})}
						>
							<WorkspaceHeader userData={userData} onLogout={onLogout} hasUpdates={hasUpdates} setMargin={setMargin} />
						</Box>
						<MainArea>{children}</MainArea>
					</>
				)}

				{/*Creating user in DynamoDB if they already dont exist */}
				{/*<ApolloProvider client={client}>
          <CreateUser setUserId={setUserId} />
          <GetConversations setHasUpdates={setHasUpdates} userId={userId} />
        </ApolloProvider>*/}
				{/*<ChatIcons />  // Removed for now in favor of Pendo supplied button */}
			</Grid>
			{/*<Grid
        item
        md={0.5}
        lg={0.4}
        xl={0.4}
        display={{ sm: "none", md: "block", xs: "none" }}
      >
        <RightSidebar
          onLogout={onLogout}
          userData={userData}
          showAccountSettingPopup={showAccountSettingPopup}
          closeAccountSettingsPopup={closeAccountSettingsPopup}
          handleOpen={handleOpen}
        />
      </Grid>*/}
		</Grid>
	);
}

const mapStoreToProps = ({ auth }) => ({
	companyName: auth?.companyName,
	userType: auth?.userType,
	userName: auth?.userName,
	showAccountSettingPopup: auth?.showAccountSettingPopup,
	companyLogoUrl: auth?.companyLogoUrl,
});

const mapDispatchToProps = (dispatch) => ({
	setAuthData: (updatedData) => dispatch(actions.setAuthData(updatedData)),
});

export default connect(mapStoreToProps, mapDispatchToProps)(LayoutWrapper);
