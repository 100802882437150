import { Svg } from "./Svg";

export const Help2Icon = ({ className, fill = "#667085", width = 30, height = 26 }) => (
	<Svg className={className} width={width} height={height} viewBox="0 0 30 26" fill="none">
		<path
			d="M23.6665 18.3333C26.2438 18.3333 28.3332 14.4531 28.3332 9.66667C28.3332 4.8802 26.2438 1 23.6665 1M23.6665 18.3333C21.0892 18.3333 18.9998 14.4531 18.9998 9.66667C18.9998 4.8802 21.0892 1 23.6665 1M23.6665 18.3333L6.2579 15.1681C5.02124 14.9433 4.40291 14.8309 3.90294 14.5853C2.8858 14.0856 2.12832 13.178 1.8187 12.0879C1.6665 11.5521 1.6665 10.9236 1.6665 9.66667C1.6665 8.40973 1.6665 7.78127 1.8187 7.24543C2.12832 6.15532 2.8858 5.2477 3.90294 4.74806C4.40291 4.50247 5.02124 4.39005 6.2579 4.1652L23.6665 1M5.6665 15.6667L6.19165 23.0187C6.24152 23.7169 6.26645 24.0659 6.41834 24.3305C6.55206 24.5634 6.75302 24.7506 6.99489 24.8674C7.2696 25 7.61959 25 8.31956 25H10.6961C11.4963 25 11.8964 25 12.1925 24.8404C12.4527 24.7002 12.659 24.4775 12.7788 24.2073C12.9152 23.8998 12.8845 23.5009 12.8231 22.703L12.3332 16.3333"
			stroke={fill}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</Svg>
);
