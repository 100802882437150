import React from "react";
import { Box, TableCell, TableRow, Typography } from "@mui/material";
import { CheckCircle } from "@mui/icons-material";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";

function InsightSubSectionRow({ name, current, change, percentChange, previous, isSmallComponent }) {
	const getState = () => {
		// If there was no points previously and no points currently, we match the figma disabled style.
		if (current == 0 && previous == 0) {
			return false;
		} else {
			return true;
		}
	};

	const getIcon = () => {
		if (getState()) {
			return (
				<CheckCircle
					sx={{
						color: (theme) => theme.palette.primary.main,
						height: "1.25rem",
					}}
				/>
			);
		} else {
			return <CircleOutlinedIcon sx={{ color: "lightgrey", height: "1.25rem" }} />;
		}
	};

	return (
		<TableRow>
			<TableCell
				sx={{
					border: "none",
				}}
			>
				<Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
					<Box>{getIcon()}</Box>
					<Box>
						<Typography variant="poppinsSemiBold14" color={getState() ? "black" : "lightgray"}>
							{name}
						</Typography>
					</Box>
				</Box>
			</TableCell>
			<TableCell sx={{ textAlign: "center", opacity: getState() ? 1 : 0 }}>
				<Typography variant="poppinsMedium14" color={"black"}>
					{current}
				</Typography>
			</TableCell>
			<TableCell sx={{ textAlign: "center", opacity: getState() ? 1 : 0 }}>
				<Typography variant="poppinsMedium14" color={"black"}>
					{change}
				</Typography>
			</TableCell>
			<TableCell
				sx={{ textAlign: "center", borderRight: "1px solid rgba(224, 224, 224, 1)", borderBottomRightRadius: "8px", opacity: getState() ? 1 : 0 }}
			>
				<Typography variant="poppinsMedium14" color={"black"}>
					{percentChange}
				</Typography>
			</TableCell>
		</TableRow>
	);
}

export default InsightSubSectionRow;
