import React, { useEffect, useState } from "react";
import { Box, Grid, Typography, TextField, IconButton, InputAdornment } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import theme from "../../../constants/Theme";
import PasswordValidation from "../../../common/passwordValidation/PasswordValidation";
// import PasswordInput from "../../../common/PasswordInputField/index";
import { REGEX } from "../../../constants/regex";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { BACK_ARROW_ICON_V2 } from "../../../constants";
export default function ChangePassword({ handleBack, setSaveDisable, setCurrentPassword, setNew }) {
	const [validationErrorArray, setValidationErrorArray] = useState([true, true, true, true, true]);
	const [showPassword, setShowPassword] = useState(false);
	const [showNewPassword, setShowNewPassword] = useState(false);
	const [showRePassword, setShowRePassword] = useState(false);
	const [password, setPassword] = useState("");
	const [newPassword, setNewPassword] = useState("");
	const [newRePassword, setRePassword] = useState("");
	const [matchError, setMatchError] = useState(false);
	const [duplicateError, setDuplicateError] = useState(false);

	const handleValidation = (e) => {
		setNewPassword(e);
		let error = [...validationErrorArray];
		if (e.length >= 8) {
			error[0] = false;
		} else {
			error[0] = true;
		}
		if (e.match(REGEX.LOWERCASELETTERS)) {
			error[1] = false;
		} else {
			error[1] = true;
		}
		if (e.match(REGEX.UPPERCASELETTERS)) {
			error[2] = false;
		} else {
			error[2] = true;
		}
		if (e.match(REGEX.NUMBERS)) {
			error[3] = false;
		} else {
			error[3] = true;
		}
		if (e.match(REGEX.SPECIAL_CHARACTERS)) {
			error[4] = false;
		} else {
			error[4] = true;
		}
		setValidationErrorArray(error);
	};

	useEffect(() => {
		if (newRePassword && newPassword) {
			if (newRePassword != newPassword) {
				setMatchError(true);
			} else {
				setMatchError(false);
			}
		}
	}, [newRePassword, newPassword]);

	const handleOnBlur = () => {
		if (newPassword.length > 7 && password.length > 7) {
			if (newPassword === password) {
				setDuplicateError(true);
			} else {
				setDuplicateError(false);
			}
		}
	};

	useEffect(() => {
		if (
			password.length > 7 &&
			newPassword.length > 7 &&
			newRePassword.length > 7 &&
			!matchError &&
			!duplicateError &&
			!validationErrorArray?.includes(true)
		) {
			setCurrentPassword(password);
			setNew(newPassword);
			setSaveDisable(false);
		} else {
			setSaveDisable(true);
		}
	}, [newPassword, newRePassword, matchError, duplicateError, validationErrorArray]);

	return (
		<>
			<Box sx={{ width: 1 }}>
				<Grid container spacing={2} sx={{ px: 2, pb: 1 }}>
					<Grid item xs={12} md={12}>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								rowGap: 2,
							}}
						>
							<Box>
								<IconButton onClick={handleBack} sx={{ p: "0px" }}>
									<Box component={"img"} src={BACK_ARROW_ICON_V2} alt="back" />
								</IconButton>
							</Box>
							<Typography variant="Text/md/Semibold">Change Password</Typography>
						</Box>
					</Grid>
					<Grid item xs={12} md={12}>
						<PasswordInput
							placeholder="Current Password"
							show={showPassword}
							value={password}
							onChange={(e) => setPassword(e.target.value)}
							setShow={setShowPassword}
							fullWidth
						/>
					</Grid>
					<Grid item xs={12} md={12}>
						<PasswordInput
							placeholder="New Password"
							show={showNewPassword}
							value={newPassword}
							onChange={(e) => handleValidation(e.target.value)}
							onBlur={(e) => handleOnBlur(e.target.value)}
							setShow={setShowNewPassword}
							fullWidth
						/>
						{duplicateError && <Typography variant="error_text">New Password and current password cannot be same.</Typography>}
					</Grid>
					<Grid item xs={12} md={12}>
						<PasswordInput
							placeholder="Re-enter New Password"
							show={showRePassword}
							value={newRePassword}
							onChange={(e) => setRePassword(e.target.value)}
							setShow={setShowRePassword}
							fullWidth
						/>
						{matchError && <Typography variant="error_text">Please check the re-enterd password.</Typography>}
					</Grid>
					<Grid item xs={12} md={12}>
						<PasswordValidation validationErrorArray={validationErrorArray} />
					</Grid>
				</Grid>
			</Box>
		</>
	);
}

export const FormInputElementStyle = {
	borderRadius: "30px",
	fontWeight: "500",
	fontSize: "16px",
	lineHeight: "24px",
	fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
	padding: "1.5px 6px",
	background: "#fff",
	boxShadow: "0px 10px 25px 0px #0000000D",
};

const PasswordInput = ({ show, setShow, ...props }) => {
	const handleShowInput = () => {
		setShow(!show);
	};
	return (
		<TextField
			type={show ? "text" : "password"}
			sx={{
				"& .MuiOutlinedInput-notchedOutline": {
					height: "auto",
					boxShadow: "0px 10px 25px 0px rgba(0, 0, 0, 0.05)",
					border: "none",
					borderRadius: "100px",
				},
				input: {
					"&, &::placeholder": (theme) => ({
						fontFamily: theme.typography["Text/sm/Regular"],
						color: "rgba(102, 112, 133, 1)",
					}),
				},
			}}
			InputProps={{
				style: FormInputElementStyle,
				endAdornment: (
					<InputAdornment position="end">
						<IconButton aria-label="toggle password visibility" onClick={handleShowInput}>
							{show ? <VisibilityIcon /> : <VisibilityOffIcon />}
						</IconButton>
					</InputAdornment>
				),
			}}
			{...props}
		/>
	);
};
