import React from "react";
import PropTypes from "prop-types";
import { PageWrapper, HeaderArea, MainArea, FooterArea, BackgroundContainer, BGCTop } from "./styles";
import Footer from "../../pages/AppHome/components/Footer";
import AppHeader from "../../pages/AppHome/components/AppHeader";
import { Box } from "@mui/material";

export default function AppHomeLayout({ children }) {
	return (
		<Box sx={{ position: "relative" }}>
			<PageWrapper>
				<HeaderArea>
					<AppHeader />
				</HeaderArea>
				<MainArea>{children}</MainArea>
				<FooterArea>
					<Footer />
				</FooterArea>
			</PageWrapper>
			<BackgroundContainer>
				<BGCTop></BGCTop>
			</BackgroundContainer>
		</Box>
	);
}
AppHomeLayout.propTypes = {
	children: PropTypes.element.isRequired,
};
