import React, { useEffect, useState } from "react";
import { Chat } from "@mui/icons-material";
import { Badge, Box, Fab } from "@mui/material";
import { UPDATED_LOGO_ICON_NEW } from "../constants";
import { useSelector } from "react-redux";
import axios from "axios";
import services from "../services";
import jwtDecode from 'jwt-decode';


const ZendeskChatFAB = () => {
	const [isOpen, setIsOpen] = useState(false);
	const [unreadCount, setUnreadCount] = useState(0);
	//const [zdLoginToken, setzdLoginToken] = useState(null);
	const userAuth = useSelector((store) => store?.auth);
	const userName = useSelector((store) => store?.auth?.userName);
	const userEmail = useSelector((store) => store?.auth?.email);
	const companyScore = useSelector((store) => store?.auth?.companyData?.startUpScore);

	const [subscriptionInformation, setSubscriptionInformation] = useState(null);
	const [sessionStart, setsessionStart] = useState("");

	useEffect(() => {
		if (userAuth?.idToken && userEmail && userName) {
			handleLogin();
		} else {
			logoutChatWindow();
		}
	}, [userAuth, userEmail, userName]);

	useEffect(()=>{
		if(sessionStart){
			window.zE('messenger:set', 'conversationFields', [
				{ id: '22366532398989', value: sessionStart ?? ""}, // Login Token Time
			])
		}
	}, [sessionStart])

	useEffect(()=>{
		if(subscriptionInformation){
			window.zE('messenger:set', 'conversationFields', [
				{ id: '22329528076173', value: subscriptionInformation?.subscriptionId ?? ""}, // SubscriptionID
				{ id: '22329573243661', value: subscriptionInformation?.subscriptionStatus ?? ""}, // SubscriptionStatus
				{ id: '22356723106829', value: subscriptionInformation?.subscriptionCancelledAt ?? ""}, // SubscriptionCanceledDate
			])
		}
	}, [subscriptionInformation])

	const handleLogin = async () => {
		const LambdaBaseURL = process.env.REACT_APP_AWS_LAMBDA_ZENDESK_URL;
		if (userAuth && userEmail && userName) {
			const resp = await axios.post(`${LambdaBaseURL}/login`, {
				name: userName,
				email: userEmail,
				userType: userAuth?.userType,
				companyName: userAuth?.companyName,
				companyID: userAuth?.companyId,
				isPremiumPlanAccess: userAuth?.isPremiumPlanAccess,
			});

			try{
				const decodedJwt = jwtDecode(userAuth?.idToken);
				if(decodedJwt?.updated_at)
				{
					setsessionStart(new Date(decodedJwt?.updated_at).toLocaleString());
				}
			} catch(e){
				console.log("Unable to decode ID Token and extract Login Timestamp. Failed with error:", e)
			}

			const subscriptionData = await services.getSubscriptionDetails().catch(e => {console.log(e)});

			if(subscriptionData?.data?.data){
				setSubscriptionInformation({
					subscriptionId: subscriptionData?.data?.data?.subscriptionId,
					subscriptionStatus: subscriptionData?.data?.data?.subscriptionStatus,
					subscriptionCancelledAt: subscriptionData?.data?.data?.subscriptionCancelledAt
				})
			}
			loginChatWindow(resp?.data);
		}
	};

	const logoutChatWindow = () => {
		try {
			window.zE("messenger", "logoutUser");
		} catch (e) {
			console.log("Error logging out zendesk chat. Failed with error:", e);
		}
	};

	// Function to login to the chat window
	const loginChatWindow = (JWT) => {
		try {
			window.zE("messenger", "loginUser", function (callback) {
				callback(JWT.toString("base64"));
			});
			window.zE('messenger:set', 'conversationFields', [
				{ id: '22329792013581', value: userAuth?.userId ?? ""}, // UserID
				{ id: '22330853449869', value: userAuth?.userType ?? ""}, // UserType
				{ id: '22330856487181', value: userAuth?.companyName ?? ""}, // CompanyName
				{ id: '22330808976013', value: userAuth?.companyId ?? ""}, // CompanyID
				{ id: '22354211373069', value: companyScore ?? ""}, // CompanyScore
			])
		} catch (e) {
			console.log("Error opening zendesk chat. Failed with error:", e);
		}
	};

	// Function to open the chat window
	const openChatWindow = () => {
		try {
			window.zE("messenger", "open");
		} catch (e) {
			console.log("Error opening zendesk chat. Failed with error:", e);
		}
	};

	// Function to close the chat window
	const closeChatWindow = () => {
		try {
			window.zE("messenger", "close");
		} catch (e) {
			console.log("Error closing zendesk chat. Failed with error:", e);
		}
	};

	// Event listener for unread messages
	const handleUnreadMessages = (count) => {
		setUnreadCount(count);
	};

	useEffect(() => {
		try {
			// Attach the unreadMessages event listener
			window.zE("messenger:on", "unreadMessages", handleUnreadMessages);

			window.zE("messenger:on", "open", function () {
				setIsOpen(true);
			});
			window.zE("messenger:on", "close", function () {
				setIsOpen(false);
			});
		} catch (e) {
			console.log("Error loading zendesk chat messages listeners. Failed with error:", e);
		}
	}, []);

	// Toggle the chat window
	const toggleChatWindow = () => {
		if (isOpen) {
			closeChatWindow();
		} else {
			openChatWindow();
		}
		setIsOpen(!isOpen);
	};

	return (
		<Fab
			onClick={toggleChatWindow}
			sx={{
				position: "fixed",
				bottom: "16px",
				right: "16px",
				bgcolor: "black",
				border: "2px solid",
				borderColor: "transparent",
				transition: "all .2s",
				"&:hover": {
					borderColor: "black",
					"& img": {
						filter: "brightness(0)",
					},
				},
			}}
		>
			<Badge badgeContent={unreadCount} color="secondary">
				<Box component="img" src={UPDATED_LOGO_ICON_NEW} sx={{ height: "30px", width: "30px" }} />
			</Badge>
		</Fab>
	);
};

export default ZendeskChatFAB;
