import React, { useEffect, useState } from "react";
import { Box, Typography, Card, styled } from "@mui/material";
import { useSelector } from "react-redux";
import { Route, Switch, Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";

import { DISCOVERY_MAIN_BLACK, PAGE_RESOURCE_CENTER_ICON } from "../../constants";
import SearchField from "./Common/SearchField";
import Navigation from "./Common/Navigation";
import CategoryList from "./Categories/CategoryList";
import CategoryPage from "./Categories/CategoryPage";
import SectionPage from "./Sections/SectionPage";
import ArticlePage from "./Articles/ArticlePage";
import { ResourceCenterStore } from "./Stores/ResourceCenterStore";
import SearchResults from "./SearchResults/SearchResults";
import SectionList from "./Sections/SectionList";
import PaymentPlanDialog from "../../components/PaymentPlanDialog";
import { USER_TYPES } from "../../utils/userTypes";
import { handleMixPanel } from "../../utils/mixPanelEventHandling";

export const CardWrapper = styled(Card)(({ theme, sx }) => ({
	backgroundColor: theme.palette.secondary.white,
	borderRadius: "40px",
	boxShadow: "0px 10px 85px rgba(0, 0, 0, 0.09)",
	padding: "45px",
	...sx,
}));

function ResourceCenter() {
	const isAdmin = useSelector((store) => store?.auth?.isAdmin);
	const [showPlanModal, setShowPlanModal] = useState(false);
	const { isPremiumPlanAccess, userType } = useSelector((store) => store?.auth);

	useEffect(() => {
		handleMixPanel("Resource Center Viewed", { subscription_status: isPremiumPlanAccess ? "Subscribed" : "Not Subscribed", user_type: userType });
	}, []);

	useEffect(() => {
		if (isPremiumPlanAccess || isAdmin || userType === USER_TYPES.MENTOR_USER || userType === USER_TYPES.INVESTOR_USER) {
		} else {
			// Override, always allow Resource Center
			// setShowPlanModal(true);
		}
	}, [isPremiumPlanAccess, isAdmin, userType]);

	const handleClosePlanModal = () => {
		setShowPlanModal(false);
	};

	return (
		<>
			<Helmet>
				<meta charSet="utf-8" />
				<title>StartupOS - Resource Center</title>
			</Helmet>
			<ResourceCenterStore>
				<Box
					sx={{
						px: {
							lg: 8,
							md: 4,
							xs: 2,
						},
						display: "flex",
						flexDirection: "column",
					}}
				>
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							gap: "12px",
							mb: 5,
						}}
					>
						<Box component={"img"} src={PAGE_RESOURCE_CENTER_ICON} sx={{ width: "30px", height: "30px" }} />
						<Typography variant="growth_card_subtitle" color="secondary.black">
							Resource Center
						</Typography>
					</Box>
					<Box
						sx={{
							maxWidth: (theme) => theme.breakpoints.values.xl,
							margin: "0 auto",
							width: 1,
							gap: 4,
							display: "flex",
							flexDirection: "column",
						}}
					>
						<CardWrapper>
							<SearchField />
						</CardWrapper>
						<Navigation />
						{/* This may need to be an internal scroll component, but leaving as body scroll for now. */}
						<Box
							sx={{
								pb: 5,
							}}
						>
							<Switch>
								<Route exact path="/resource-center/sections/:section_id/articles/:article_id" component={ArticlePage} />
								{/*<Route exact path="/resource-center/categories/:category_id/sections/:section_id/articles/:article_id" component={ArticlePage} />*/}
								{/*<Route exact path="/resource-center/categories/:category_id/sections/:section_id" component={SectionPage} />*/}
								<Route exact path="/resource-center/articles/:article_id" component={ArticlePage} />
								<Route exact path="/resource-center/sections/:section_id" component={SectionPage} />
								<Route exact path="/resource-center/categories/:category_id" component={CategoryPage} />
								<Route exact path="/resource-center" component={SectionList} />
								{/*<Route exact path="/resource-center" component={CategoryList} />*/}
								<Route exact path="/resource-center/search-results/articles/:article_id" component={ArticlePage} />
								<Route exact path="/resource-center/search-results" component={SearchResults} />
								<Route exact path="/resource-center/sections">
									<Redirect to="/resource-center" />
								</Route>
							</Switch>
						</Box>
					</Box>
				</Box>
				{showPlanModal && <PaymentPlanDialog open={showPlanModal} onClose={handleClosePlanModal} />}
			</ResourceCenterStore>
		</>
	);
}

export default ResourceCenter;
