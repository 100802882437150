import { Alert, AlertTitle, Box, Divider, FormControlLabel, Switch, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

function ReviewSummary({ response, setChecked, userValues }) {
	const [reviewObj, setreviewObj] = useState(null);
	const [suggestionState, setsuggestionState] = useState(null);
	const [currentValues, setCurrentValues] = useState(null);

	useEffect(() => {
		if (userValues) {
			setCurrentValues(userValues);
		}
	}, [userValues]);

	useEffect(() => {
		if (response) {
			try {
				setreviewObj(response);
				const initialState = {};
				response?.items?.forEach((value) => {
					if (value?.suggested_edit && value?.suggested_value) {
						initialState[value?.name] = { checked: true, value: value?.suggested_value };
					}
				});
				setsuggestionState(initialState);
			} catch (e) {
				console.log(e);
			}
		}
	}, [response]);

	const fieldNameMap = (name) => {
		switch (name) {
			case "companyName":
				return "Company Name";
			case "about":
				return "About";
			case "websiteLink":
				return "Website";
			case "tagLine":
				return "Tagline";
			default:
				return name;
		}
	};

	const handleCheck = (event) => {
		const name = event.target.name;
		setsuggestionState((prevState) => {
			return {
				...prevState,
				[name]: {
					...prevState[name],
					checked: event.target.checked,
				},
			};
		});
	};

	useEffect(() => {
		if (suggestionState && Object.keys(suggestionState)?.length > 0) {
			setChecked(suggestionState);
		} else {
			setChecked(null);
		}
	}, [suggestionState]);

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				gap: 2,
			}}
		>
			<Typography variant={"Display/xs/Semibold"} component={"p"} sx={{ mb: 1 }}>
				StartupOS Bolt AI Review
			</Typography>
			<Box
				sx={{
					backgroundColor: "#F2EFFF",
					p: 4,
					borderRadius: "20px",
					mb: 1,
				}}
			>
				<Typography variant={"Display/xs/Semibold"} component={"p"} sx={{ mb: 1 }}>
					Note on AI
				</Typography>
				<Typography variant={"Text/md/Regular"} component={"p"}>
					Bolt is an AI working with StartupOS. It's still getting the hang of things, so its responses may not be entirely helpful from time to time.
					We are constantly working together to improve our working relationship to deliver the best results to you.Have feedback? Contact{" "}
					<a href="mailto:success@startupos.com?subject=Feedback on StartupOS Bolt AI">success@startupos.com</a>
				</Typography>
			</Box>

			<Box>
				<Typography variant={"Display/xs/Semibold"} component={"p"} sx={{ mb: 1 }}>
					StartupOS Bolt AI Summary
				</Typography>
				<Typography variant={"Text/md/Regular"} component={"p"}>
					{reviewObj?.review_overview}
				</Typography>
			</Box>

			{reviewObj?.items?.map((item, index) => (
				<Box sx={{ mb: 4 }}>
					<Divider sx={{ my: 2 }} />
					<Typography component={"h6"} variant="Text/xl/Semibold" sx={{ mb: 1 }}>
						{fieldNameMap(item?.name)}
					</Typography>
					{!item?.suggested_edit ? (
						<Alert severity="success">
							<AlertTitle>
								<strong>Good Job</strong>
							</AlertTitle>
							{item?.comments}
						</Alert>
					) : (
						<Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
							<Alert severity="info">
								<AlertTitle>
									<strong>Insight</strong>
								</AlertTitle>
								{item?.comments?.length > 0 ? item?.comments : "Bolt AI didn't offer any comments."}
							</Alert>
							<Alert severity="error">
								<AlertTitle>
									<strong>Needs work!</strong>
								</AlertTitle>
								{currentValues && currentValues?.[item?.name]?.length > 0
									? currentValues?.[item?.name]?.replace(/(<([^>]+)>)/gi, "")
									: "You left this blank."}
							</Alert>
							<Alert severity="success">
								<AlertTitle>
									<strong>Bolt AI Suggests</strong>
								</AlertTitle>
								{item?.suggested_value?.length > 0
									? item?.suggested_value?.replace(/(<([^>]+)>)/gi, "")
									: "Bolt AI didn't offer any specific suggestions. However Bolt AI did mark this response as needing work."}
							</Alert>
							<FormControlLabel
								sx={{ alignSelf: "flex-end", m: 0 }}
								control={<Switch color="primary" checked={suggestionState[item?.name]?.checked} name={item?.name} onChange={handleCheck} />}
								label={
									<span
										style={{
											fontFamily: "PoppinsSemiBold",
											fontSize: "16px",
											lineHight: "24px",
											color: suggestionState[item?.name]?.checked ? "#7B61FF" : "lightgray",
										}}
									>
										Use Suggestion
									</span>
								}
								labelPlacement="start"
							/>
						</Box>
					)}
				</Box>
			))}
		</Box>
	);
}

export default ReviewSummary;
