import React, { useEffect, useState } from "react";
import BILineChart from "./BILineChart/BILineChart";
import { Box, useTheme, Typography, CircularProgress } from "@mui/material";
import ChartCard from "./ChartCard";
import { get } from "lodash";
import { convertNumberToLocale, getGradientBgColor } from "../Functions/common";
import ZeroLineChart from "./BILineChart/EmptyLineChart";

function SmExpenseVsNewSalesChart({ onChartClick, data, loading, readOnly }) {
	const theme = useTheme();
	const chartData = get(data, "data", []);

	const latestNewSalesArr = get(data, "latestNewSalesArr", null);
	const latestSalesAndMarketing = get(data, "latestSalesAndMarketing", null);

	let latestValue = latestNewSalesArr !== null && latestSalesAndMarketing ? 2.6 * (latestNewSalesArr / latestSalesAndMarketing) : null;
	latestValue = latestValue !== null ? parseFloat(Number(latestValue).toFixed(2)) : null;

	return (
		<ChartCard
			title={"S&M Expense vs New Sales ARR"}
			subheader={latestValue !== null && !loading ? `${convertNumberToLocale(latestValue)}` : "0"}
			backgroundColor={theme.palette.secondary.white}
			onChartClick={() => onChartClick("annualRecurringRevenue", data)}
			toolTipBody={
				<>
					<Typography variant="Text/sm/Regular">
						Comparing Sales & Marketing (S&M) spend to ARR from new customers helps show how efficiently S&M spend translates to new revenue.
					</Typography>
					<Typography variant="Text/sm/Regular" sx={{ fontStyle: "italic" }}>
						New Sales ARR = Sum of revenue from new customers x 12
					</Typography>
					<Typography variant="Text/sm/Regular" sx={{ fontStyle: "italic" }}>
						S&M Expense = Inputted S&M
					</Typography>
				</>
			}
			data={data?.data}
		>
			{loading ? (
				<Box sx={{ alignSelf: "center" }}>
					<CircularProgress />
				</Box>
			) : chartData.length === 0 ? (
				<ZeroLineChart readOnly={readOnly} />
			) : (
				<Box sx={{ position: "relative", width: "100%", mt: "auto" }}>
					<BILineChart
						data={chartData}
						tooltipYLable={"New Sales ARR"}
						yAxisKey={"newSalesArr"}
						yAxisType="currency"
						lineBorderColor={theme.palette.primary.main}
						lineBackgroundColor={(context) => {
							const ctx = context.chart.ctx;
							return getGradientBgColor(ctx, context);
						}}
						fill={false}
						datasets={[
							{
								label: "Sales and Marketing",
								data: chartData,
								borderWidth: 2,
								borderColor: theme.palette.primary.primaryPink,
								tension: 0.15,
								parsing: {
									xAxisKey: "date",
									yAxisKey: "salesAndMarketing",
								},
								pointRadius: 0,
								pointHoverRadius: 3,
								clip: false,
								fill: false,
							},
						]}
					/>
				</Box>
			)}
		</ChartCard>
	);
}

export default SmExpenseVsNewSalesChart;
