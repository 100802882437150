import React, { useEffect, useState } from "react";
import theme from "../../../constants/Theme";
import { Stack, Grid, Typography, Button, Box } from "@mui/material";
import TodayIcon from "@mui/icons-material/Today";
import { PEOPLE_PURPLE } from "../../../constants/image";
import EventsContainer from "../../../common/EventsContainer";

export default function StudentDashboard() {
  return (
    <Stack
      sx={theme => ({
        width: 1,
        borderRadius: theme.shape.standard.borderRadius,
        background:
          "linear-gradient(0deg, rgba(118, 73, 160, 0.05), rgba(118, 73, 160, 0.05)), #FCFCFC",
        [theme.breakpoints.down("md")]: {
          borderRadius: 0,
        },
      })}
    >
      <Box
        sx={theme => ({
          maxWidth: theme.breakpoints.values.xl,
          margin: "auto",
          width: "100%",
        })}
      ></Box>
    </Stack>
  );
}
