import React from "react";
import { useHistory } from "react-router-dom";
import { Box, Typography, Grid, Link } from "@mui/material";
import { ROADRUNNERIMG } from "../../../constants/image";
import LoadingButton from "../../../common/Loadingbutton";
import { BtnLogin } from "../../../containers/NewOnboard/Components";
import ROUTES_PATH from "../../../constants/routes";

export const VerifyEmail = ({ isAuthenticated, currentPath, sampleData, email, loading, handleConfirm }) => {
	const history = useHistory();

	return (
		<Box width="422px" display="flex" alignItems="center" flexDirection="column" gap={4}>
			<Typography variant="Text/2xl/Semibold">We are excited to have you join us!</Typography>
			<Typography variant="Text/sm/Regular" textAlign="center">
				Before we get started, we need you verify your account via email.
			</Typography>

			<BtnLogin fullWidth onClick={() => handleConfirm()}>
				Resend verification email
			</BtnLogin>

			<Typography variant="Text/sm/Regular" color="#667085">
				I am already verified.{" "}
				<Link
					sx={{ cursor: "pointer", color: "#667085" }}
					onClick={() => {
						history.push(ROUTES_PATH.LOG_IN);
					}}
				>
					Click here to login.
				</Link>
			</Typography>
		</Box>

		// <>
		// 	<Grid className="signup-grid-container h-80" container alignItems="center" justifyContent="center">
		// 		<Grid
		// 			item
		// 			xs={12}
		// 			sm={12}
		// 			md={12}
		// 			lg={12}
		// 			className="form-container"
		// 			sx={{
		// 				textAlign: "center",
		// 				display: "flex",
		// 				justifyContent: "center",
		// 				alignItems: "center",
		// 				alignContent: "center",
		// 				flexDirection: "column",
		// 				gap: 2,
		// 				mt: 9,
		// 			}}
		// 		>
		// 			<Typography variant="h2_dark">That’s it! Are you ready to change the world? </Typography>
		// 			<Box component="img" src={ROADRUNNERIMG} sx={{ maxWidth: "330px", alignSelf: "center" }} />
		// 			<Typography variant="Onboarding/EmailConfirmation">
		// 				{currentPath === "email-verification" ? "Confirm Your Email" : "Check Your Email"}
		// 			</Typography>

		// 			<Typography variant="body_medium_small" sx={{ color: (theme) => theme.palette.secondary.appBarGray }}>
		// 				<Box sx={{ pt: 2 }}>
		// 					{currentPath === "resend-email" ? sampleData.ResendEmailDescription[0] : sampleData.EmailVerificationDescription[0]}
		// 					<span className={currentPath === "resend-email" ? "resend-email-text" : "signup-email-text"}>
		// 						<Link target="_blank" underline="hover" variant="title_medium">
		// 							{email}
		// 						</Link>
		// 					</span>
		// 					{currentPath === "resend-email" ? sampleData.ResendEmailDescription[1] : sampleData.EmailVerificationDescription[1]}
		// 				</Box>
		// 			</Typography>

		// 			<Grid item sx={{ width: 1, mb: 5 }}>
		// 				<Box textAlign={"center"}>
		// 					<LoadingButton
		// 						sx={{
		// 							mt: 3,
		// 							width: 1,
		// 						}}
		// 						onClick={() => handleConfirm()}
		// 						loading={loading}
		// 					>
		// 						Resend Email
		// 					</LoadingButton>
		// 				</Box>
		// 			</Grid>
		// 		</Grid>
		// 	</Grid>
		// </>
	);
};
