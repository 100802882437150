import React, { useState } from "react";
import {
  Grid,
  Box,
  Divider,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { VIEW_ICON, LOGOICON, STARTUPOSICON } from "../../../constants/image";
import theme from "../../../constants/Theme";
import ProfileContainer from "./Profile";
import AboutContainer from "./About";
import StartupOSScore from "../../Dashboard/QuickStatView/StartupOSScore";

export default function MyProfile(props) {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Grid container>
      <Box
        sx={{
          width: "100%",
          gridColumn: {
            lg: "span 12",
            md: "span 12",
            sm: "span 12",
            xs: "span 12",
          },
          mt: 3,
          pb: 3,
        }}
      >
        <ProfileContainer
          handleEditForm={props.handleEditForm}
          profileArr={props.profileArr}
          compLocation={props.compLocation}
          userData={props.userData}
          handleUpdateAssetsUrl={props.handleUpdateAssetsUrl}
          handleStartupDetails={props.handleStartupDetails}
        />
        <Box sx={{ pl: { md: 4 }, pr: { md: 3 } }}>
          <Divider
            sx={{
              mt: 3,
              borderColor: "secondary.light",
              display: "block",
            }}
          />
        </Box>

        <AboutContainer
          handleEditForm={props.handleEditForm}
          profileArr={props.profileArr}
          productOffering={props.productOffering}
          handleDeleteVideo={props.handleDeleteVideo}
          setBrowseVidFlag={props.setBrowseVidFlag}
          setispitchdeckview={props.setispitchdeckview}
          pitchDeckToggle={props.isPitchDeckView}
          handleUpdateAssetsUrl={props.handleUpdateAssetsUrl}
          handleUploadPitchDeck={props.handleUploadPitchDeck}
          setHarmonicOpen={props.setHarmonicOpen}
        />
      </Box>
    </Grid>
  );
}
