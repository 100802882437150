import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

const emptyDoughnutChart = {
	id: "emptyDoughnut",
	afterDraw(chart, args, options) {
		const { datasets } = chart.data;
		const { color, width, radiusDecrease } = options;
		let hasData = false;

		for (let i = 0; i < datasets?.[0]?.data?.length; i += 1) {
			const dataset = datasets?.[0]?.data?.[i];
			hasData |= dataset > 0;
		}

		if (!hasData) {
			const {
				chartArea: { left, top, right, bottom },
				ctx,
			} = chart;
			const centerX = (left + right) / 2;
			const centerY = (top + bottom) / 2;
			const r = Math.min(right - left, bottom - top) / 2;

			ctx.beginPath();
			ctx.lineWidth = width;
			ctx.strokeStyle = color;
			ctx.arc(centerX, centerY, r - radiusDecrease || 0, 0, 2 * Math.PI);
			ctx.stroke();
		}
	},
};

export default function GrowthProgress({
	width,
	height,
	wrapperProps,
	percentages,
	colors,
	score,
	borderJoinStyle = "round",
	borderRadius = 50,
	borderWidth = 1,
	cutout = 150,
	barWrapperProps,
}) {
	const theme = useTheme();
	return (
		<Box position="relative" display="flex" alignItems="center" justifyContent="center" sx={{ ...wrapperProps }}>
			<Box width={width} height={height} display="flex" alignItems="center" sx={{ ...barWrapperProps }}>
				<Doughnut
					data={{
						datasets: [
							{
								data: percentages,
								backgroundColor: colors,
								borderColor: colors,
								borderWidth: borderWidth,
								borderRadius: borderRadius,
								borderJoinStyle: borderJoinStyle,
								spacing: -30,
							},
						],
					}}
					plugins={[emptyDoughnutChart]}
					options={{
						cutout: cutout,
						legend: {
							display: false,
						},
						plugins: {
							datalabels: {
								display: false,
							},
							tooltip: {
								enabled: false,
							},
							emptyDoughnut: {
								color: theme.palette.secondary.chipGrayBg,
								width: 10,
								radiusDecrease: 5,
							},
						},
						tooltips: {
							callbacks: {
								label: function (tooltipItem) {
									return tooltipItem.yLabel;
								},
							},
						},
					}}
				/>
			</Box>
			{(score !== null || score !== undefined) && (
				<Box
					sx={{
						top: 8,
						left: 0,
						bottom: 0,
						right: 0,
						position: "absolute",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						gap: 1,
					}}
				>
					<Typography variant="growth_mainpercentage_text" component="div" color="secondary.black">
						{score}
					</Typography>
					<Typography component="span" variant="card_name" color="secondary.black">
						%
					</Typography>
				</Box>
			)}
		</Box>
	);
}
