import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { List, Typography, Box, Button } from "@mui/material";
import { ListItemComp } from "./styles";
import { handleMixPanel } from "../../../../../../utils/mixPanelEventHandling";
import { setCommonDialogConfirmationData } from "../../../../../../modules/actions";

const NAV_ITEMS = [
	{ id: 1, title: "Get Started", route: "/home/get-started" },
	{ id: 2, title: "PowerUp Library", route: "/home/powerup-library" },
	{ id: 3, title: "Try a Demo", route: "/home/try-a-demo/41" },
];

export default function WorkspaceNavbar({ activeKey }) {
	const history = useHistory();
	const dispatch = useDispatch();

	const handleListItemClick = (item) => {
		handleMixPanel(`${item.title} clicked`);
		history.push(item.route);
	};

	const handleApplyToPitch = () => {
		handleMixPanel("Apply To Pitch Clicked");
		dispatch(
			setCommonDialogConfirmationData({
				applyToPitchOpen: true,
			})
		);
	};

	return (
		<Box display="flex" alignItems="center" justifyContent="center" flex={1}>
			<List
				sx={(theme) => ({
					display: "flex",
					flexWrap: "wrap",
					gap: 3,
					justifyContent: "center",
				})}
			>
				{NAV_ITEMS.map((item) => (
					<ListItemComp key={item.id} onClick={() => handleListItemClick(item)} selected={activeKey === item.id}>
						<Typography color={activeKey === item.id ? "#FFFFFF" : "#344054"} variant="title_large2">
							{item.title}
						</Typography>
					</ListItemComp>
				))}
			</List>
			<Box sx={{ paddingLeft: "24px", marginLeft: "24px", borderLeft: "1px dashed #000" }}>
				<Button variant={"DS1"} onClick={handleApplyToPitch}>
					Apply to Pitch
				</Button>
			</Box>{" "}
		</Box>
	);
}
