import React from "react";
import { Box, Typography, Dialog, Grid, DialogContent } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ModelTypesTable from "../Components/ModelTypesTable";
import theme from "../../../../../../constants/Theme";

export default function ModelTypesDialog(props) {
  return (
    <>
      <Dialog
        open={props.open}
        onBackdropClick={props.close}
        sx={{
          display: "flex",
          width: 1,
          flexDirection: "column",

          "& .MuiDialog-paper": {
            width: "fit-content",
            maxWidth: "1300px",
          },
        }}
      >
        <Grid
          container
          sx={{
            display: "flex",
            width: 1,
            flexDirection: { xs: "column-reverse", md: "row" },
            p: 4,
          }}
        >
          <Grid item xs={12} md={10}>
            <Typography variant="sys_light_on_surface_heading">
              Types of Revenue Models
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            md={2}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <CloseIcon
              sx={{ cursor: "pointer", color: theme.palette.secondary.dark }}
              onClick={props.close}
            />
          </Grid>
        </Grid>
        <DialogContent sx={{ width: "fit-content" }}>
          <Box>
            <ModelTypesTable data={props.data} />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
