import { DialogTitle, Dialog, IconButton, Typography, Divider, DialogContent, DialogActions, Slide, Button, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import theme from "../../constants/Theme";
import {
	addCompanyToWatchlistOrPortfolioApiCall,
	addOrRemoveCompanyToPortfolioOrWatchlist,
	addOrRemoveCompanyToTrackSection,
	bulkWatchlistOrPorfolioCollectionActionApiCall,
	removeCompanyToWatchlistOrPortfolioApiCall,
} from "../../containers/Investor/Dashboard/Functions/common";
import { useSelector, useDispatch } from "react-redux";
import { setInvestorDashboardData } from "../../modules/actions";
import { useLocation } from "react-router-dom";
const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="left" ref={ref} {...props} />;
});
export default function CommonSlideConfirmation() {
	const investorDashboardData = useSelector((state) => state?.investorDashboard);
	const { isAdmin, userType, partnerCode } = useSelector((state) => state?.auth);
	const dispatch = useDispatch();
	const disableBackDropClick = true;
	const title = investorDashboardData?.slidingConfirmationObject?.title;
	const text = investorDashboardData?.slidingConfirmationObject?.text;
	const subText = investorDashboardData?.slidingConfirmationObject?.subText;
	const primaryButtonText = investorDashboardData?.slidingConfirmationObject?.primaryButtonText;
	const secondaryButtonText = investorDashboardData?.slidingConfirmationObject?.secondaryButtonText;
	const type = investorDashboardData?.slidingConfirmationObject?.type;
	const isOpen =
		investorDashboardData?.removeMyPortfolioOpen ||
		investorDashboardData?.addMyPortfolioOpen ||
		investorDashboardData?.removeWatchlistOpen ||
		investorDashboardData?.addWatchlistOpen;
	const startup = investorDashboardData?.openStartupObj;
	const location = useLocation();
	const page = location.pathname?.split("/")?.pop();

	const handleClose = () => {
		dispatch(
			setInvestorDashboardData({
				removeMyPortfolioOpen: false,
				addMyPortfolioOpen: false,
				removeWatchlistOpen: false,
				addWatchlistOpen: false,
			})
		);
	};

	const handleUpdatePortfolioAction = (status) => {
		const tempStartupList = investorDashboardData?.startupList;

		tempStartupList.map((startupItem) => {
			if (startupItem.id === startup.id) {
				startupItem.inPortfolio = status;
			}
		});
		let newStartupList;

		if (page === "my-portfolio") {
			newStartupList = tempStartupList?.filter((startupItem) => {
				if (startupItem.id === startup?.id && !status) {
					return false;
				}
				return true;
			});
		}

		// dispatch(setInvestorDashboardData({ startupList: [...tempStartupList] }));
		dispatch(setInvestorDashboardData({ startupList: [...(newStartupList ? newStartupList : tempStartupList)] }));
		if (status) {
			addOrRemoveCompanyToPortfolioOrWatchlist({ entityIds: [startup?.id], collectionType: "PORTFOLIO", isRemove: false })
				.then((result) => {
					if (result.code === 200) {
						dispatch(setInvestorDashboardData({ openStartupObj: { ...startup, inPortfolio: true } }));
					}
				})
				.catch((err) => console.log(err));

			handleClose();
		} else {
			addOrRemoveCompanyToPortfolioOrWatchlist({ entityIds: [startup?.id], collectionType: "PORTFOLIO", isRemove: true })
				.then((result) => {
					if (result.code === 200) {
						dispatch(setInvestorDashboardData({ openStartupObj: { ...startup, inPortfolio: false } }));
					}
				})
				.catch((err) => console.log(err));

			handleClose();
		}
	};

	const handleUpdateTrackAction = (status) => {
		const tempStartupList = investorDashboardData?.startupList;

		tempStartupList?.map((startupItem) => {
			if (startupItem.id === startup.id) {
				startupItem.inTracked = status;
			}
		});
		let newStartupList;

		dispatch(setInvestorDashboardData({ startupList: [...(newStartupList ? newStartupList : tempStartupList)] }));
		if (status) {
			addOrRemoveCompanyToTrackSection({ trackedCompanyIds: [startup?.id], isRemove: false })
				.then((result) => {
					if (result.code === 200) {
						dispatch(setInvestorDashboardData({ openStartupObj: { ...startup, inPortfolio: true } }));
					}
				})
				.catch((err) => console.log(err));

			handleClose();
		} else {
			addOrRemoveCompanyToTrackSection({ trackedCompanyIds: [startup?.id], isRemove: true })
				.then((result) => {
					if (result.code === 200) {
						dispatch(setInvestorDashboardData({ openStartupObj: { ...startup, inPortfolio: false } }));
					}
				})
				.catch((err) => console.log(err));

			handleClose();
		}
	};

	const handleUpdateWatchlistAction = (status) => {
		const tempStartupList = investorDashboardData?.startupList;

		tempStartupList.map((startupItem) => {
			if (startupItem.id === startup.id) {
				startupItem.inWatchlist = status;
			}
		});
		let newStartupList;

		if (page === "watchlist") {
			newStartupList = tempStartupList?.filter((startupItem) => {
				if (startupItem.id === startup?.id && !status) {
					return false;
				}
				return true;
			});
		}

		// dispatch(setInvestorDashboardData({ startupList: [...tempStartupList] }));
		dispatch(setInvestorDashboardData({ startupList: [...(newStartupList ? newStartupList : tempStartupList)] }));

		if (status) {
			addOrRemoveCompanyToPortfolioOrWatchlist({ entityIds: [startup?.id], collectionType: "WATCHLIST", isRemove: false })
				.then((result) => {
					if (result.code === 200) {
						dispatch(setInvestorDashboardData({ openStartupObj: { ...startup, inWatchlist: true } }));
					}
				})
				.catch((err) => console.log(err));

			handleClose();
		} else {
			addOrRemoveCompanyToPortfolioOrWatchlist({ entityIds: [startup?.id], collectionType: "WATCHLIST", isRemove: true })
				.then((result) => {
					if (result.code === 200) {
						dispatch(setInvestorDashboardData({ openStartupObj: { ...startup, inWatchlist: false } }));
					}
				})
				.catch((err) => console.log(err));

			handleClose();
		}
	};
	const handleBulkActions = (status) => {
		const tempStartupList = investorDashboardData?.startupList;

		let selectedStartups = [];
		let newStartupList;

		if (status === "BULK_ADD_WATCHLIST") {
			tempStartupList.map((startupItem) => {
				if (startupItem.checked) {
					startupItem.inWatchlist = true;
					selectedStartups.push(startupItem.id);
				}
			});
			addOrRemoveCompanyToPortfolioOrWatchlist({ entityIds: selectedStartups, collectionType: "WATCHLIST", isRemove: false })
				.then((result) => {
					if (result.code === 200) {
					}
				})
				.catch((err) => console.log(err));

			handleClose();
		} else if (status === "BULK_REMOVE_WATCHLIST") {
			tempStartupList.map((startupItem) => {
				if (startupItem.checked) {
					startupItem.inWatchlist = false;
					selectedStartups.push(startupItem.id);
				}
			});
			if (page === "watchlist") {
				newStartupList = tempStartupList?.filter((startupItem) => {
					if (startupItem.checked) {
						return false;
					}
					return true;
				});
			}
			addOrRemoveCompanyToPortfolioOrWatchlist({ entityIds: selectedStartups, collectionType: "WATCHLIST", isRemove: true })
				.then((result) => {
					if (result.code === 200) {
					}
				})
				.catch((err) => console.log(err));

			handleClose();
		} else if (status === "BULK_ADD_PORTFOLIO") {
			tempStartupList.map((startupItem) => {
				if (startupItem.checked) {
					startupItem.inPortfolio = true;
					selectedStartups.push(startupItem.id);
				}
			});
			addOrRemoveCompanyToPortfolioOrWatchlist({ entityIds: selectedStartups, collectionType: "PORTFOLIO", isRemove: false })
				.then((result) => {
					if (result.code === 200) {
					}
				})
				.catch((err) => console.log(err));

			handleClose();
		} else if (status === "BULK_REMOVE_PORTFOLIO") {
			tempStartupList.map((startupItem) => {
				if (startupItem.checked) {
					startupItem.inPortfolio = false;
					selectedStartups.push(startupItem.id);
				}
			});
			if (page === "my-portfolio") {
				newStartupList = tempStartupList?.filter((startupItem) => {
					if (startupItem.checked) {
						return false;
					}
					return true;
				});
			}
			addOrRemoveCompanyToPortfolioOrWatchlist({ entityIds: selectedStartups, collectionType: "PORTFOLIO", isRemove: true })
				.then((result) => {
					if (result.code === 200) {
					}
				})
				.catch((err) => console.log(err));

			handleClose();
		} else if (status === "BULK_ADD_TRACK") {
			tempStartupList.map((startupItem) => {
				if (startupItem.checked) {
					startupItem.inTracked = true;
					selectedStartups.push(startupItem.id);
				}
			});
			addOrRemoveCompanyToTrackSection({ trackedCompanyIds: selectedStartups, isRemove: false })
				.then((result) => {
					if (result.code === 200) {
					}
				})
				.catch((err) => console.log(err));

			handleClose();
		} else if (status === "BULK_REMOVE_TRACK") {
			tempStartupList.map((startupItem) => {
				if (startupItem.checked) {
					console.log(`inside checked`);
					startupItem.inTracked = false;
					selectedStartups.push(startupItem.id);
				}
			});

			console.log(`selectedStartups`, selectedStartups);

			addOrRemoveCompanyToTrackSection({ trackedCompanyIds: selectedStartups, isRemove: true })
				.then((result) => {
					if (result.code === 200) {
					}
				})
				.catch((err) => console.log(err));

			handleClose();
		} else {
			console.log("Error");
		}
		dispatch(setInvestorDashboardData({ startupList: [...(newStartupList ? newStartupList : tempStartupList)] }));
		// dispatch(setInvestorDashboardData({ startupList: [...tempStartupList] }));
	};

	const handleCommonAction = () => {
		switch (type) {
			case "PORTFOLIO":
				handleUpdatePortfolioAction(!startup?.inPortfolio);
				break;
			case "WATCHLIST":
				handleUpdateWatchlistAction(!startup?.inWatchlist);
				break;
			case "BULK_ADD_PORTFOLIO":
				handleBulkActions("BULK_ADD_PORTFOLIO");
				break;
			case "BULK_REMOVE_PORTFOLIO":
				handleBulkActions("BULK_REMOVE_PORTFOLIO");
				break;
			case "BULK_ADD_WATCHLIST":
				handleBulkActions("BULK_ADD_WATCHLIST");
				break;
			case "BULK_REMOVE_WATCHLIST":
				handleBulkActions("BULK_REMOVE_WATCHLIST");
				break;
			case "TRACK":
				handleUpdateTrackAction(!startup?.inTracked);
				break;
			case "BULK_ADD_TRACK":
				handleBulkActions("BULK_ADD_TRACK");
				break;
			case "BULK_REMOVE_TRACK":
				console.log(`inside buld remove`);
				handleBulkActions("BULK_REMOVE_TRACK");
				break;
		}
	};

	return (
		<Dialog
			TransitionComponent={Transition}
			transitionDuration={600}
			aria-describedby="alert-dialog-slide-description"
			keepMounted
			maxWidth={"xs"}
			fullWidth
			open={isOpen}
			onClose={handleClose}
			PaperProps={{
				sx: (theme) => ({
					borderRadius: "30px",
					p: 3,
					ml: "auto",
					mr: 0,
					mb: 0,
					alignSelf: "end",
					position: "fixed",
					right: 0,
				}),
			}}
		>
			{title && (
				<DialogTitle
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<Typography variant={"card_name"}>{title}</Typography>

					<IconButton
						aria-label="close"
						onClick={handleClose}
						sx={{
							color: theme.palette.secondary.dark,
						}}
					>
						<CloseIcon sx={{ color: (theme) => theme.palette.secondary.black }} />
					</IconButton>
				</DialogTitle>
			)}
			<DialogContent
				sx={{
					px: 4,
				}}
			>
				<>
					<Box sx={{ pt: 2 }}>
						<Typography variant="on_surface">{text}</Typography>
					</Box>
					<Box sx={{ mt: 1 }}>
						<Typography variant="sub_title_portfolio">{subText} </Typography>
					</Box>
				</>
			</DialogContent>
			<DialogActions>
				<>
					<Box display={"flex"} gap={2} sx={{ pr: 2 }} alignItems="center">
						<Button
							variant="noOutlineNew"
							onClick={() => {
								handleClose();
							}}
						>
							{secondaryButtonText}
						</Button>
						<Button
							variant="containedNewStyle"
							onClick={() => {
								handleCommonAction();
							}}
						>
							{primaryButtonText}
						</Button>
					</Box>
				</>
			</DialogActions>
		</Dialog>
	);
}
