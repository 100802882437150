import React from "react";
import { Box, Typography } from "@mui/material";
import CommonSlideDialog from "../../../../../common/CommonSlideDialog";

export default function BIDataGridDialog({
	isOpen,
	onClose,
	title,
	children,
	dialogAction,
	titleStyle,
	titleVariant,
	contentStyle,
	footerStyle,
	PaperProps,
	maxWidth="lg",
	...props
}) {
	return (
		<CommonSlideDialog
			isOpen={isOpen}
			// title={
			// 	<Box sx={{ display: "flex", flexDirection: "column" }}>
			// 		<Typography variant={titleVariant ? titleVariant : "Text/md/Semibold"}>{title}</Typography>
			// 	</Box>
			// }
			// titleVariant={titleVariant ? titleVariant : "Text/md/Semibold"}
			onClose={onClose}
			maxWidth={maxWidth}
			// titleStyle={{ p: 0, pb: 2, alignItems: "baseline", ...titleStyle }}
			contentStyle={{ flex: 1, p: 0, ...contentStyle }}
			fullWidth
			PaperProps={{ sx: { borderRadius: "20px", m: 0, p: 4, width: "100%" }, ...PaperProps }}
			dialogAction={dialogAction}
			footerStyle={footerStyle}
			{...props}
		>
			{children}
		</CommonSlideDialog>
		// <Dialog
		// 	TransitionComponent={Transition}
		// 	transitionDuration={transitionDuration}
		// 	aria-describedby="alert-dialog-slide-description"
		// 	open={isOpen}
		// 	onClose={disableBackDropClick ? () => {} : onClose}
		// 	maxWidth="lg"
		// 	fullWidth
		// 	PaperProps={{ sx: { borderRadius: "20px" } }}
		// 	{...props}
		// >
		// 	<DialogTitle
		// 		sx={{
		// 			display: "flex",
		// 			justifyContent: "space-between",
		// 			alignItems: "center",
		// 		}}
		// 	>
		// 		<Typography variant="poppinsSemiBold20" sx={{ lineHeight: "30px" }}>
		// 			{title ? title : ""}
		// 		</Typography>

		// 		<IconButton
		// 			aria-label="close"
		// 			onClick={handleClose}
		// 			sx={{
		// 				color: (theme) => theme.palette.secondary.dark,
		// 			}}
		// 		>
		// 			<CloseIcon sx={{ color: (theme) => theme.palette.secondary.black }} />
		// 		</IconButton>
		// 	</DialogTitle>

		// 	<DialogContent
		// 		sx={{
		// 			...contentStyle,
		// 		}}
		// 	>
		// 		{children}
		// 	</DialogContent>
		// 	<DialogActions
		// 		sx={{
		// 			...footerStyle,
		// 		}}
		// 	>
		// 		{!readOnly && (
		// 			<Box display="flex" sx={{ pr: 3 }}>
		// 				<Button onClick={handleClose} variant="noOutlineNew" sx={{ height: "40px" }}>
		// 					Cancel
		// 				</Button>
		// 				<LoadingButton
		// 					loaderColor={theme.palette.primary.main}
		// 					loadingStyles={{
		// 						border: "4px solid",
		// 						backgroundColor: theme.palette.secondary.white,
		// 						borderColor: theme.palette.primary.main,
		// 						height: "40px",
		// 						px: 4,
		// 					}}
		// 					loading={isSaving}
		// 					onClick={!isSaving ? handleSave : () => {}}
		// 					variant="PurpleRounded"
		// 					disabled={isSaving}
		// 					sx={{ height: "40px" }}
		// 				>
		// 					Save
		// 				</LoadingButton>
		// 			</Box>
		// 		)}
		// 	</DialogActions>
		// </Dialog>
	);
}
