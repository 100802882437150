import React, { Fragment, createContext, useMemo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Switch, useRouteMatch, Route, Redirect, useHistory, useLocation } from "react-router-dom";
import CommonSlideConfirmation from "../../../common/CommonSlideConfirmation";
import DashboardView from "../../../components/Investor/Dashboard/View";
import FundingStagesDialog from "../../../components/Investor/FundingStages";
import IndustryDetailsDialog from "../../../components/Investor/IndustryDetails";
import ScoreBenchmarkDetails from "../../../components/ScoreBenchmarkDetails";
import ScoutingReportDrawer from "../../../components/ScoutingReport/ScoutingReportDrawer";
import InvestorStartups from "./AllStartups";

export const InvestorDashboardContext = createContext();

export default function InvestorDashboard() {
	const location = useLocation();
	const { url, path } = useRouteMatch();
	const [filterResult, setFilterResult] = useState(false);
	const [filterPayload, setFilterPayload] = useState({});
	const [openFilter, setOpenFilter] = useState(false);
	const [isFilterApplied, setIsFilterApplied] = useState(false);
	const [totalCompanyCount, setTotalCompanyCount] = useState(0);
	const [savedFilterPopoverOpen, setSavedFilterPopoverOpen] = useState(false);
	const { email, isAdmin, isInvestorLocked } = useSelector((state) => state?.auth);
	const [isSavedSearchApplied, setIsSavedSearchApplied] = useState(false);

	const history = useHistory();

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		});

		if (!isAdmin && isInvestorLocked) {
			history.push("/investor/dashboard/watchlist");
		}
	}, []);

	useEffect(() => {
		if (location?.pathname === "/investor/dashboard/startups" && !isAdmin && isInvestorLocked) {
			history.push("/investor/dashboard/watchlist");
		}
	}, [email, history, isAdmin, location?.pathname, isInvestorLocked]);

	const isRedirect = useMemo(() => {
		if (location?.pathname === "/investor/dashboard/startups" && !isAdmin && isInvestorLocked) {
			return true;
		}

		return false;
	}, [email, isAdmin, location?.pathname, isInvestorLocked]);

	useEffect(() => {
		if (filterResult) {
			setFilterResult(false);
		}
	}, [filterResult]);

	return (
		<InvestorDashboardContext.Provider
			value={{
				filterResult,
				setFilterResult,
				filterPayload,
				setFilterPayload,
				openFilter,
				setOpenFilter,
				isFilterApplied,
				setIsFilterApplied,
				totalCompanyCount,
				setTotalCompanyCount,
				savedFilterPopoverOpen,
				setSavedFilterPopoverOpen,
				isSavedSearchApplied,
				setIsSavedSearchApplied,
			}}
		>
			<Fragment>
				<Switch>
					<Route path={`${path}/startups`}>{!isRedirect && <DashboardView content={<InvestorStartups activeKey={1} />} activeKey={1} />}</Route>
					<Route path={`${path}/my-portfolio`}>
						<DashboardView content={<InvestorStartups activeKey={2} />} activeKey={2} />
					</Route>
					<Route path={`${path}/watchlist`}>
						<DashboardView content={<InvestorStartups activeKey={3} />} activeKey={3} />
					</Route>
					<Route exact path={`${path}`}>
						<Redirect to={`${path}/startups`} />
					</Route>
				</Switch>
				<ScoreBenchmarkDetails />
				<CommonSlideConfirmation />
				<ScoutingReportDrawer />
				<FundingStagesDialog />
				<IndustryDetailsDialog />
			</Fragment>
		</InvestorDashboardContext.Provider>
	);
}
