import { STORY_TELLER_STEPS } from "../../../constants/powerup-data";
import { REGEX } from "../../../constants/regex";
import POWERUP_JSON from "./powerupExcercises.json";
import { convertDraftJsToPlainText } from "../../../utils/rte-utils";

export const getExcerciseConfigurations = powerUpId => {
  return POWERUP_JSON.powerups.find(
    powerUp => powerUp.power_up_id === powerUpId
  );
};

export const getStepsData = powerUpId => {
  return new Promise((resolve, reject) => {
    //currently taking data from constant later will be replaced by api call
    setTimeout(() => {
      resolve(STORY_TELLER_STEPS);
    }, 2000);
  });
};

export const getExcerciseCompletionStatus = (currentExcercise, userInputs) => {
  switch (currentExcercise.input_type) {
    case "TEXTAREA":
      return true;

    case "MULTIVALUE_TAGS_INPUT":
      return true;

    case "FRAME_STATEMENT":
      if (userInputs.length === currentExcercise.attributes.length) {
        return true;
      } else {
        return false;
      }
  }
};

export const handleButtonDisable = (currentExcercise, userInputs) => {
  if (currentExcercise) {
    switch (currentExcercise.input_type) {
      case "TEXTAREA":
        if (userInputs && userInputs[0]) {
          let input = convertDraftJsToPlainText(userInputs[0]);
          if (input?.trim().length >= 1 && input?.trim().length <= 2000) {
            return false;
          } else {
            return true;
          }
        } else {
          return true;
        }

      case "MULTIVALUE_TAGS_INPUT":
        if (!userInputs.length) {
          return true;
        } else {
          return false;
        }

      case "FRAME_STATEMENT":
        if (userInputs.length < currentExcercise.attributes.length) {
          return true;
        } else {
          return false;
        }
    }
  }
};
