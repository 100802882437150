import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";

const SearchComponent = ({ searchText, handleChange }) => {
	const [searchValue, setSearchValue] = useState("");

	useEffect(() => {
		setSearchValue(searchText);
	}, [searchText]);

	const handleSearch = (e) => {
		setSearchValue(e.target.value);
		handleChange(e);
	};

	return (
		<TextField
			spellCheck="true"
			value={searchValue}
			onChange={handleSearch}
			placeholder={"Search"}
			sx={{
				width: "300px",
				"& .MuiInputBase-root": {
					height: "48px",
					borderRadius: "40px",
					fontSize: "16px",
					fontFamily: '"PoppinsRegular", "Helvetica", "Arial", sans-serif',
					background: "white",
					pl: 1,
					"&:hover": {
						borderColor: "red",
					},
				},
				"& .MuiOutlinedInput-notchedOutline": {
					border: "1px solid #D0D5DD",
				},
				input: {
					"&, &::placeholder": (theme) => ({
						fontSize: "16px",
						fontFamily: '"PoppinsRegular", "Helvetica", "Arial", sans-serif',
						color: "rgba(102, 112, 133, 1)",
						opacity: 1,
					}),
				},
			}}
		/>
	);
};

export default SearchComponent;
