import { Box, IconButton, InputAdornment, ListItemText, MenuItem, Select, Typography } from "@mui/material";
import { useRef } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";

export const NewCustomSelect = ({ error, value, onChange, options, placeholder, MenuProps, sx, renderValue, children, ...otherProps }) => {
	const selectRef = useRef(null);
	const getSelectWidth = () => {
		return selectRef.current ? selectRef.current?.clientWidth : 0;
	};

	return (
		<Box sx={{ borderRadius: "30px", border: "1px solid rgba(0, 0, 0, 0.1)" }}>
			<Select
				fullWidth
				sx={{
					borderRadius: "1rem",
					"& .MuiOutlinedInput-notchedOutline": {
						border: error ? "1px solid rgba(240, 68, 56, 0.3) !important" : "none",
					},
					"& .MuiListItemText-primary": {
						overflow: "hidden",
						textOverflow: "ellipsis",
						whiteSpace: "pre",
					},
					...sx,
				}}
				IconComponent={ExpandMoreIcon}
				renderValue={(value) => {
					if (options && typeof options?.[0] === "string") {
						return (
							<Box sx={{ width: "100%", height: "100%", overflow: "hidden", whiteSpace: "pre-line", textOverflow: "ellipsis" }}>
								<Typography variant="Text/sm/Regular" sx={{ overflow: "hidden", whiteSpace: "pre-line", textOverflow: "ellipsis" }}>
									{value}
								</Typography>
							</Box>
						);
					} else {
						const selected = options?.find((option) => option.value === value);
						return (
							<Box sx={{ width: "100%", height: "100%", overflow: "hidden", whiteSpace: "pre-line", textOverflow: "ellipsis" }}>
								<Typography variant="Text/sm/Regular" component={"p"}>
									<Typography variant="Text/sm/Semibold" component={"span"}>{`${selected?.title}${
										selected?.separator === "-" ? ` ${selected?.separator}` : selected?.separator
									}`}</Typography>
									{selected?.description}
								</Typography>
							</Box>
						);
					}
				}}
				error={error}
				value={value}
				onChange={onChange}
				placeholder={placeholder}
				defaultValue={"Seed"}
				MenuProps={{
					sx: {
						".MuiMenu-paper": { boxShadow: (theme) => theme.palette.secondary.selectNoOutlineShadow },
					},
					anchorOrigin: {
						vertical: "bottom",
						horizontal: "left",
					},
					transformOrigin: {
						vertical: "top",
						horizontal: "left",
					},
					PaperProps: {
						sx: {
							whiteSpace: "normal",
							maxHeight: 300,
							width: getSelectWidth(),
						},
					},
					...MenuProps,
				}}
				startAdornment={
					error && (
						<InputAdornment position="end">
							<IconButton>
								<ErrorOutlineOutlinedIcon sx={{ color: "#F04438" }} />
							</IconButton>
						</InputAdornment>
					)
				}
				{...otherProps}
			>
				{options &&
					options.map((item) => {
						if (typeof item === "string") {
							return (
								<MenuItem key={item} value={item} style={{ whiteSpace: "normal" }}>
									<ListItemText primary={item} primaryTypographyProps={{ variant: "Text/sm/Regular" }} />
								</MenuItem>
							);
						} else {
							return (
								<MenuItem key={item?.value} value={item?.value} style={{ whiteSpace: "normal" }}>
									<Typography variant="Text/sm/Regular" component={"p"}>
										<Typography variant="Text/sm/Semibold" component={"span"}>{`${item?.title}${
											item?.separator === "-" ? ` ${item?.separator}` : item?.separator
										}`}</Typography>
										{item?.description}
									</Typography>
								</MenuItem>
							);
						}
					})}
			</Select>
		</Box>
	);
};
