import React from "react";
import { Card, Typography, Box } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useHistory } from "react-router-dom";
import {
  BoxArrow,
  BoxCard,
  BoxContent,
  BoxDescription,
  BoxHero,
  BoxPowerPack,
  BoxTitle,
  CardMediaHero,
  TypographyHero,
} from "./styles";
import HeroFeatureCard from "./HeroFeatureCard";
import { ONLINE_PREDICTION_ICON } from "../../constants";

export default function PowerPackHero({ powerPackData, cardType = "other" }) {
  const history = useHistory();

  const getPowerPackDemoImage = powerPackData => {
    if (powerPackData.powerUpPackName === "Pitch Perfect") {
      return "https://staging7.startupos.com/wp-content/uploads/2022/09/PowerPack-Pitch-Perfect.jpg";
    } else if (powerPackData.powerUpPackName === "MVP/MLP") {
      return "https://staging7.startupos.com/wp-content/uploads/2022/09/PowerPack-Minimum-Viable-Product-MVP.jpg";
    } else if (powerPackData.powerUpPackName === "Investor Meeting") {
      return "https://staging7.startupos.com/wp-content/uploads/2022/09/PowerPack-Customer-Discovery.jpg";
    } else if (powerPackData.powerUpPackName === "Product Hunt Debut") {
      return "https://staging7.startupos.com/wp-content/uploads/2022/09/PowerPack-Product-Market-Fit.jpg";
    } else if (powerPackData.powerUpPackName === "Show Day") {
      return "https://staging7.startupos.com/wp-content/uploads/2022/09/PowerPack-Go-To-Market.jpg";
    }
    return "https://staging7.startupos.com/wp-content/uploads/2022/09/PowerPack-Pitch-Perfect.jpg";
  };

  const getFeatureDataFromItem = powerPackData => {
    let featureData = {
      id: "",
      imageUrl: "",
      title: "",
      description: "",
      featureType: "",
      featureStatus: "",
    };
    if (cardType === "marketTest") {
      featureData.imageUrl = ONLINE_PREDICTION_ICON;
      featureData.title = "Market Test";
      featureData.description =
        "Interviews are one of the most flexible and powerful methods in the StartupOS framework. They can be used across the entire startup journey - to generate fresh insights and uncover unmet needs, validate the strength of new ideas, refine a value proposition, test an early prototype and pricing scheme.";
      featureData.featureType = "marketTest";
      featureData.featureStatus = "";
    } else {
      if (powerPackData.manifest && powerPackData.manifest.tasks) {
        let item = powerPackData.manifest.tasks[0];
        if (item.taskName === "POWER_UP") {
          featureData.id = item.powerUpId;
          featureData.imageUrl = item.powerUp.imageUrl;
          featureData.title = item.powerUp.powerUpName;
          featureData.description = item.powerUp.shortDescription;
          featureData.featureType = item.taskName;
          featureData.featureStatus = item.powerUp.powerUpStatus;
        } else if (item.taskName === "PLUGIN") {
          featureData.id = item.pluginId;
          featureData.imageUrl = item.plugin.imageUrl;
          featureData.title = item.plugin.powerUpName;
          featureData.description = item.plugin.shortDescription;
          featureData.featureType = item.taskName;
          featureData.featureStatus = item.plugin.powerUpStatus;
        } else if (item.taskName === "FUSION") {
          featureData.id = item.fusionId;
          featureData.imageUrl = item.fusion.imageUrl;
          featureData.title = item.fusion.fusionName;
          featureData.description = item.fusion.shortDescription;
          featureData.featureType = item.taskName;
          featureData.featureStatus = item.fusion.powerUpStatus;
        } else if (item.taskName === "DEMO") {
          featureData.id = item.demoId;
          featureData.imageUrl = item.demo.imageUrl;
          featureData.title = item.demo.demoName;
          featureData.description = item.demo.shortDescription;
          featureData.featureType = item.taskName;
          featureData.featureStatus = item.demo.powerUpStatus;
        }
      }
    }

    return featureData;
  };

  return (
    <Card size="large">
      <BoxCard>
        {/* <CardMediaHero
          component="img"
          height={600}
          image={getPowerPackDemoImage(powerPackData)}
          alt="card image"
        /> */}
        <BoxHero>
          {/* <BoxArrow onClick={() => { history.goBack(); }}>
            <ArrowBackIcon />
          </BoxArrow> */}
          <BoxTitle>
            <Typography variant="StartupOS/headline/headline6">
              {powerPackData.powerUpPackName} PowerPack
            </Typography>
          </BoxTitle>
          {/*<BoxContent>
            <BoxDescription>
              <TypographyHero variant="StartupOS/overline/large">
                {powerPackData.subTitle}
              </TypographyHero>
              <TypographyHero variant="StartupOS/headline/headline1Black">
                {powerPackData.title}
              </TypographyHero>
              <Typography variant="StartupOS/subhead/subhead1Black">
                {powerPackData.description}
              </Typography>
            </BoxDescription>
            <BoxPowerPack>
              <HeroFeatureCard
                featureData={getFeatureDataFromItem(powerPackData)}
              />
            </BoxPowerPack>
          </BoxContent>*/}
          <Box>
            <TypographyHero variant="StartupOS/overline/large" display={"grid"}>
              {powerPackData.subTitle}
            </TypographyHero>
            <TypographyHero
              variant="StartupOS/headline/headline1Black"
              display={"grid"}
            >
              {powerPackData.title}
            </TypographyHero>
            <Typography
              variant="StartupOS/subhead/subhead1Black"
              display={"grid"}
            >
              {powerPackData.description}
            </Typography>
          </Box>
        </BoxHero>
      </BoxCard>
    </Card>
  );
}
