import { Typography, Box, Button } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";

import { GreenCheckIcon } from "../../../../common/icons";
import ROUTES_PATH from "../../../../constants/routes";

const PrerequisitesSteps = ({
  powerupType,
  customHeading,
  prerequisitesData,
  customButtonVariant,
}) => {
  const history = useHistory();

  return (
    <Box>
      <Box sx={{ mt: 2 }}>
        <Typography variant="subhead2" sx={{ alignSelf: "center" }}>
          {customHeading
            ? customHeading
            : `Complete these ${prerequisitesData.length} PowerUps before beginning ${powerupType}:`}
        </Typography>
      </Box>
      {prerequisitesData?.map((item, idx) => {
        return (
          <Box sx={{ display: "flex", mt: 3, alignItems: "center" }} key={idx}>
            <Typography variant="label_medium" sx={{ alignSelf: "center" }}>
              {item?.taskDetails?.powerUp?.powerUpName ||
                item?.taskDetails?.plugin?.pluginName ||
                item?.taskDetails?.marketTest?.testName}
            </Typography>

            <Box sx={{ marginLeft: "auto", display: "flex" }}>
              {item?.companyTask?.taskStatus === "COMPLETED" ? (
                <>
                  <GreenCheckIcon />
                  <Typography variant="h6" sx={{ ml: 2 }}>
                    Complete
                  </Typography>
                </>
              ) : item?.companyTask?.taskStatus === "STARTED" ? (
                <Button
                  variant={customButtonVariant || "contained"}
                  onClick={() => {
                    if (item?.taskDetails?.taskName === "MARKET_TEST") {
                      history.push(ROUTES_PATH.WORKSPACE + `/market-test`);
                    } else {
                      history.push(
                        ROUTES_PATH.WORKSPACE +
                          `/powerup/${item?.taskDetails?.powerUpId}`
                      );
                    }
                  }}
                >
                  Resume
                </Button>
              ) : (
                <Button
                  variant={customButtonVariant || "contained"}
                  onClick={() => {
                    if (item?.taskDetails?.taskName === "MARKET_TEST") {
                      history.push(ROUTES_PATH.WORKSPACE + `/market-test`);
                    } else {
                      history.push(
                        ROUTES_PATH.WORKSPACE +
                          `/powerup/${item?.taskDetails?.powerUpId}`
                      );
                    }
                  }}
                >
                  Start
                </Button>
              )}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default PrerequisitesSteps;
