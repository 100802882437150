import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box, styled } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { Welcome, SelectRole, Help, About, Startup, BtnLogin, QuickMsg, PartnerQuestions } from "./Components";
import { USER_TYPES } from "../../utils/userTypes";
import services from "../../services";
import { getCompany } from "../../utils/auth0-spa";
import { setAuthData } from "../../modules/actions";
import { handleMixPanel } from "../../utils/mixPanelEventHandling";
import moment from "moment";

const userSchemas = {
	aboutSchema: yup.object().shape({
		country: yup.string().required("Country is required"),
		state: yup.string().required("Please select state/province"),
		city: yup.string().required("City is required"),
		zipcode: yup.string().required("Zip Code is required"),
		userLinkedinUrl: yup.string(),
		goals: yup.array().required("Goal is required"),
		address1: yup.string(),
		address2: yup.string(),
		phone: yup.string(),
	}),
	allSchema: yup.object().shape({
		country: yup.string().required("Country is required"),
		state: yup.string().required("Please select state/province"),
		city: yup.string().required("City is required"),
		zipcode: yup.string().required("Zip Code is required"),
		// .when("country", (country, schema) =>
		// 	country === "CA"
		// 		? schema.test("postal-code", "Postal Code is wrong", (value) =>
		// 				value?.length <= 3 ? /^[A-Za-z]\d[A-Za-z]$/.test(value) : /^[A-Za-z]\d[A-Za-z] ?\d[A-Za-z]\d$/.test(value)
		// 		  )
		// 		: yup.string().matches(/(^\d{5}$)|(^\d{5}-\d{4}$)/, "Zip Code is wrong")
		// ),
		userLinkedinUrl: yup.string(),
		goals: yup.array().required("Goal is required"),
		companyName: yup.string().required("Startup Name is required"),
		industry: yup.string().required("Industry is required"),
		foundedDate: yup.string(),
		productOffering: yup.string().required("Product Category is required"),
		companySize: yup.number().nullable(),
		currentStage: yup.string().required("Stage of startup is a required field"),
		websiteLink: yup.string(),
		companyLinkedinUrl: yup.string(),
		capitalRaised: yup.string().required("Funding Raised to Date is a required field").nullable(),
		incubator: yup.string().required("incubator is a required field").nullable(),
		ask: yup.string(),
		monthlyRevenue: yup.string(),
		stateOfProduct: yup.string().required("State of product is a required field"),
		// revenueModel: yup.string().required("Revenue type is a required field"),
		incorporatedStatus: yup.string().required("Level of incorporation is a required field"),
		businessModel: yup.string().required("Business model type is a required field"),
	}),
};

const defaultAboutValues = {
	country: "",
	state: "",
	city: "",
	zipcode: "",
	userLinkedinUrl: "",
	goals: [],
	address1: "",
	address2: "",
};

const defaultStartupValues = {
	companyName: "",
	currentStage: "",
	foundedDate: "",
	businessModel: "",
	companySize: null,
	productOffering: "",
	websiteLink: "",
	ask: "",
	monthlyRevenue: "",
	companyLinkedinUrl: "",
	capitalRaised: null,
	incubator: "",
	// revenueModel: "",
	incorporatedStatus: "",
	industry: "",
	stateOfProduct: "",
};

export const CustomizeOnboard = () => {
	const history = useHistory();
	const dispatch = useDispatch();

	const [totalStep, setTotalStep] = useState(5);
	const [userType, setUserType] = useState("");
	const [superpower, setSuperpower] = useState({});
	const [isLoading, setIsLoading] = useState(false);
	const [schemaType, setSchemaType] = useState("aboutSchema");
	const [errorMsg, setErrorMsg] = useState("");
	const [goalErrorMsg, setGoalErrorMsg] = useState("");
	const [companyErrMsg, setCompanyErrMsg] = useState("");

	const { partnerCode } = useSelector((store) => store?.auth);

	const [schemaData, setSchemaData] = useState([]);

	let dynamicSchema = {};

	schemaData?.forEach((question, index) => {
		const fieldName = `partnerQA${index}`;
		const isRequired = question?.question?.isRequired;

		let fieldSchema = yup.string().trim();

		// if (isRequired) {
		// 	fieldSchema = fieldSchema.required("This field is required")
		// 		.test("no-only-spaces", "Field must contain non-space characters", (value) => {
		// 		return value.trim().length > 0;
		// 	});
		// }

		dynamicSchema[fieldName] = fieldSchema;
	});
	const updatedAllSchema = yup.object().shape({
		...userSchemas.allSchema.fields,
		...dynamicSchema,
	});

	const updatedUserSchemas = {
		...userSchemas,
		allSchema: updatedAllSchema,
	};

	// const { utm_source, utm_campaign, utm_medium, partner } = useSelector((store) => ({
	// 	utm_source: store?.auth?.utmSource,
	// 	utm_campaign: store?.auth?.utmCampaign,
	// 	utm_medium: store?.auth?.utmMedium,
	// 	partner: store?.auth?.partner,
	// }));
	const { utmSource, utmCampaign, utmMedium, partner } = useSelector((store) => store.utmParams);

	const {
		watch,
		handleSubmit,
		getValues,
		setValue,
		control,
		formState: { errors },
	} = useForm({
		mode: "all",
		defaultValues: { ...defaultAboutValues, ...defaultStartupValues },
		resolver: yupResolver(updatedUserSchemas[schemaType]),
		validateTrigger: "onSubmit",
	});

	useEffect(() => {
		if (userType === USER_TYPES.STARTUP_OWNER) {
			if (partnerCode !== "sos") {
				setTotalStep(5);
			} else {
				setTotalStep(4);
			}
		} else if (userType === USER_TYPES.MENTOR_USER) {
			setTotalStep(3);
		} else if (userType === USER_TYPES.INVESTOR_USER || userType === USER_TYPES.CORPORATE_USER) {
			setTotalStep(2);
		}
	}, [userType]);

	const handleUserType = (type) => {
		setUserType(type);

		if (type === USER_TYPES.STARTUP_OWNER) {
			setSchemaType("allSchema");
		} else if (type === USER_TYPES.MENTOR_USER) {
			setSchemaType("aboutSchema");
		} else if (type === USER_TYPES.INVESTOR_USER) {
			setSchemaType("aboutSchema");
		}
	};

	const handleHelpData = (data) => {
		setSuperpower(data);
	};

	const watchCountry = watch("country");

	const loadUserSteps = useMemo(() => {
		if (userType === USER_TYPES.STARTUP_OWNER) {
			return (
				<>
					<Help step={2} userType={userType} totalStep={totalStep} errorMsg={errorMsg} handleHelpData={handleHelpData} />
					<About
						step={3}
						userType={userType}
						totalStep={totalStep}
						errors={errors}
						control={control}
						getValues={getValues}
						setValue={setValue}
						goalErrorMsg={goalErrorMsg}
						setGoalErrorMsg={setGoalErrorMsg}
					/>
					<Startup step={4} totalStep={totalStep} errors={errors} control={control} getValues={getValues} setValue={setValue} />
					{partnerCode !== "sos" && (
						<PartnerQuestions
							step={5}
							totalStep={totalStep}
							setTotalStep={setTotalStep}
							control={control}
							errors={errors}
							setSchemaData={setSchemaData}
						/>
					)}
				</>
			);
		} else if (userType === USER_TYPES.MENTOR_USER) {
			return (
				<>
					<Help step={2} userType={userType} totalStep={totalStep} errorMsg={errorMsg} handleHelpData={handleHelpData} />
					<About
						step={3}
						userType={userType}
						totalStep={totalStep}
						errors={errors}
						control={control}
						getValues={getValues}
						setValue={setValue}
						goalErrorMsg={goalErrorMsg}
						setGoalErrorMsg={setGoalErrorMsg}
					/>
				</>
			);
		} else if (userType === USER_TYPES.INVESTOR_USER) {
			return (
				<>
					<About
						step={2}
						userType={userType}
						totalStep={totalStep}
						errors={errors}
						control={control}
						getValues={getValues}
						setValue={setValue}
						goalErrorMsg={goalErrorMsg}
						setGoalErrorMsg={setGoalErrorMsg}
					/>
					<QuickMsg />
				</>
			);
		} else if (userType === USER_TYPES.CORPORATE_USER) {
			return (
				<>
					<About
						step={2}
						userType={userType}
						totalStep={totalStep}
						errors={errors}
						control={control}
						getValues={getValues}
						setValue={setValue}
						goalErrorMsg={goalErrorMsg}
						setGoalErrorMsg={setGoalErrorMsg}
						companyErrMsg={companyErrMsg}
					/>
				</>
			);
		}

		return <></>;
	}, [userType, totalStep, errors, control, getValues, errorMsg, watchCountry]);

	const handleGetCompanyStatsApiCall = async () => {
		const companyData = await getCompany();
		dispatch(setAuthData({ companyData }));
	};

	const handleGetCompanyDetailsApiCall = async () => {
		await services
			.getCompanyProfile()
			.then((response) => {
				let services = response.data;
				if (services.message === "Success") {
					dispatch(setAuthData({ companyLogoUrl: services.data.logoUrl }));
				}
			})
			.catch((error) => {
				console.log("error", error);
			});
	};

	const handleLaunch = async (data) => {
		setIsLoading(true);
		try {
			const result = await services.updateUserNewOnboard(data);
			const resData = result.data.data;
			const userRole = resData?.userTypes[0];

			if (userRole === USER_TYPES.STARTUP_OWNER || userRole === USER_TYPES.STARTUP_USER || userRole === USER_TYPES.STUDENT_USER) {
				// TODO: Need to improve this Mixpanel track with better solution
				// handleTotalPowerUpsCompletedCountApiCall(userDetails, resData);
				await handleGetCompanyStatsApiCall();
				await handleGetCompanyDetailsApiCall();
			}

			if (!!resData.isTourCompleted === false) {
				dispatch(setAuthData({ showModal: true }));
			}

			dispatch(
				setAuthData({
					companyName: resData.companyModel?.companyName,
					companyId: resData.companyModel?.id,
					userType: resData.userTypes?.[0] || "STARTUP_OWNER",
					userName: resData.name || resData.firstName + " " + resData.lastName,
				})
			);

			await services.updateStreakActionApi({
				actionType: "LOGIN",
				actionOnId: resData.id,
				actionOnType: "USER",
			});

			dispatch(setAuthData({ authenticatedAppUser: true }));

			history.push("/");
		} catch (e) {
			console.log("---launch error---", e);
		}
		setIsLoading(false);
	};

	console.log(`values1`, getValues("address1"));
	console.log(`values2`, getValues("address2"));
	console.log(`country`, getValues("country"));

	const onSubmit = async (formData) => {
		console.log(`formData`, formData);

		const partnerAnswers = [];
		partnerCode !== "sos" &&
			schemaData?.forEach((item, index) => {
				const fieldName = `partnerQA${index}`;
				const answerText = formData[fieldName] || "";
				const resObj = { questionId: item?.question?.id, answerText: answerText };
				partnerAnswers.push(resObj);
			});

		const filteredFormData = Object.keys(formData).reduce((acc, key) => {
			if (!key.startsWith("partnerQA")) {
				acc[key] = formData[key];
			}
			return acc;
		}, {});

		const payload = {
			...filteredFormData,
			...superpower,
			userType,
			goals: JSON.stringify(getValues("goals")),
			ask: parseFloat(getValues("ask")),
			monthlyRevenue: parseFloat(getValues("monthlyRevenue")),
			capitalRaised: parseFloat(getValues("capitalRaised")),
			industry: JSON.stringify([getValues("industry")]),
			companySize: getValues("companySize") ? parseInt(getValues("companySize")) : 0,
			foundedDate: getValues("foundedDate") && moment(getValues("foundedDate")),
			userAnswers: partnerAnswers,
		};

		if (getValues("goals")?.length > 0) {
			handleMixPanel("Launch StartupOS clicked", {
				...(utmSource && { "UTM Source": utmSource }),
				...(utmCampaign && { "UTM Compaign": utmCampaign }),
				...(utmMedium && { "UTM Medium": utmMedium }),
				...(partner && { "Partner Medium": partner }),
			});

			if (userType === USER_TYPES.INVESTOR_USER) {
				handleLaunch({
					...formData,
					userType,
					goals: JSON.stringify(getValues("goals")),
				});
			} else if (userType === USER_TYPES.CORPORATE_USER) {
				if (!companyErrMsg) {
					handleLaunch({
						...formData,
						userType,
						goals: JSON.stringify(getValues("goals")),
					});
				}
			} else {
				if (!superpower?.superpower) {
					setErrorMsg("Please select the following options");
				} else {
					if (JSON.parse(superpower.superpower).length === 0) {
						setErrorMsg("Please select the following options");
					} else {
						setErrorMsg("");
						handleLaunch(payload);
					}
				}
			}
		}
	};

	useEffect(() => {
		handleMixPanel("Onboarding Signup Detail Page Viewed", {
			...(utmSource && { "UTM Source": utmSource }),
			...(utmCampaign && { "UTM Compaign": utmCampaign }),
			...(utmMedium && { "UTM Medium": utmMedium }),
			...(partner && { "Partner Medium": partner }),
		});
	}, []);
	return (
		<Wrapper>
			<Welcome />
			<form onSubmit={handleSubmit(onSubmit)}>
				<Box display="flex" flexDirection="column" alignItems="center">
					<Box display="flex" flexDirection="column" gap="128px" sx={{ maxWidth: "1096px" }}>
						<SelectRole step={1} totalStep={totalStep} onType={handleUserType} />
						{loadUserSteps}
					</Box>
				</Box>
				{userType !== "" && (
					<Box display="flex" justifyContent="center" p="64px 0px 128px 0px">
						<Box>
							<BtnLogin
								onClick={() => {
									if (getValues("goals").length === 0) {
										setGoalErrorMsg("Goal is required");
									} else {
										setGoalErrorMsg("");
									}
									if (userType === USER_TYPES.CORPORATE_USER && !getValues("companyName")) {
										setCompanyErrMsg("Startup name is required");
									} else {
										setCompanyErrMsg("");
									}
								}}
								fullWidth
								type="submit"
								variant="contained"
								loading={isLoading}
								startIcon={<CheckIcon />}
								sx={{ width: "230px" }}
							>
								Launch StartupOS!
							</BtnLogin>
						</Box>
					</Box>
				)}
			</form>
		</Wrapper>
	);
};

const Wrapper = styled(Box)(({ theme }) => ({
	padding: "0 90px",
	[theme.breakpoints.down("lg")]: {
		padding: "0 90px",
	},
	[theme.breakpoints.down("md")]: {
		padding: "0 32px",
	},
	[theme.breakpoints.down("sm")]: {
		padding: "0 24px",
	},
}));
