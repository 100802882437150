import React, { useState, useEffect, Fragment } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import { TAMSAMSOM_BACKGROUND } from "../../../../constants";
import TSSGraphic from "./TSSGraphic";
import TSSText from "./TSSText";
import { useSelector } from "react-redux";
import { getTSSPowerUP } from "./functions";

export default function TamSamSomCard() {
	const STARTUP = useSelector((state) => state?.investorDashboard?.openStartupObj);
	const [TSSValues, setTSSValues] = useState(null);
	const [Loading, setLoading] = useState(true);

	const getTSSValues = async () => {
		try {
			let TSSObj = await getTSSPowerUP(STARTUP?.id);
			setTSSValues(TSSObj);
			setLoading(false);
		} catch (e) {
			console.log("Error when attempting to pull TAM SAM SOM PowerUP Data. Failed with error: ", e);
		}
	};

	useEffect(() => {
		if (STARTUP && STARTUP?.id) {
			getTSSValues();
		} else {
			setLoading(false);
		}
	}, []);

	return (
		<Box
			sx={{
				backgroundImage: `url(${TAMSAMSOM_BACKGROUND})`,
				py: {
					lg: 10,
					md: 6,
					xs: 3,
				},
				flexWrap: "wrap",
				justifyContent: "space-between",
				display: "flex",
				alignItems: "flex-end",
				rowGap: 3,
			}}
		>
			<Box
				sx={{
					flex: 1,
					background: "rgba(255, 255, 255, 0.9)",
					borderRadius: "0px 20px 20px 0px",
					p: {
						lg: 7,
						md: 5,
						xs: 3,
					},
					display: "flex",
					columnGap: 7,
					alignItems: "center",
					flexWrap: "wrap",
					rowGap: 7,
					justifyContent: "center",
				}}
			>
				{!Loading ? (
					<Fragment>
						<TSSGraphic tam={TSSValues?.TAM?.calculatedTam} sam={TSSValues?.SAM?.calculatedSam} som={TSSValues?.SOM?.calculatedSom} />
						<TSSText TSSData={TSSValues} />
					</Fragment>
				) : (
					<Box
						sx={{
							height: "450px",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						<CircularProgress size="80px" />
					</Box>
				)}
			</Box>
			<Box
				sx={{
					alignSelf: "flex-end",
					ml: "auto",
					px: {
						lg: 8,
						md: 5,
						xs: 2,
					},
				}}
			>
				<Typography variant="card_title" color={"#fff"}>
					TAM SAM SOM
				</Typography>
			</Box>
		</Box>
	);
}
