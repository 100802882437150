import * as actionTypes from "./actionTypes";

export const setCurrentMeetingObject = payload => {
  return dispatch => {
    dispatch({
      type: actionTypes.SET_CURRENT_MEETING_OBJECT,
      payload,
    });
  };
};

export const setCalUser = payload => {
  return dispatch => {
    dispatch({
      type: actionTypes.SET_CAL_USER,
      payload,
    });
  };
};

export const setComingFromPlugin = payload => {
  return dispatch => {
    dispatch({
      type: actionTypes.SET_COMING_FROM_PLUGIN,
      payload,
    });
  };
};
