import React from "react";
import { Box } from "@mui/material";

function BoltLoader({ scale = 1 }) {
	// Base size and offset values
	const baseSize = 50; // Base size for the loader
	const positionOffset = `calc(100% + ${3 * scale}px)`; // Dynamically adjust position offset based on scale
	const leftOffset = `calc(50% - ${12.5 * scale}px)`; // Dynamically adjust left position based on scale
	const width = `${25 * scale}px`; // Width of the pseudo-elements, scaled
	const transformOriginOffset = `50% ${-28 * scale}px`; // Dynamically adjust transform-origin based on scale

	return (
		<Box
			sx={{
				width: `${baseSize * scale}px`,
				height: `${baseSize * scale}px`, // Ensure height is also scaled for aspect ratio maintenance
				boxShadow: (theme) => `0 0 0 ${3 * scale}px #000 inset`,
				borderRadius: "50%",
				position: "relative",
				animation: "l11 7s infinite",
				"&:before, &:after": {
					content: '""',
					position: "absolute",
					top: positionOffset,
					left: leftOffset,
					width: width,
					height: width, // Ensure the height is explicitly set for aspect ratio maintenance
					borderRadius: "50%",
					transformOrigin: transformOriginOffset,
					animation: "l11 1.5s infinite",
					boxShadow: (theme) => `0 0 0 ${3 * scale}px #7B61FF inset`,
				},
				"&:after": {
					animationDelay: "-0.75s",
					boxShadow: (theme) => `0 0 0 ${3 * scale}px #DE0085 inset`,
				},
				"@keyframes l11": {
					"100%": { transform: "rotate(360deg)" },
				},
			}}
		/>
	);
}

export default BoltLoader;
