import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Box, Button, IconButton, Typography, useMediaQuery, styled, ListItem, List, Link } from "@mui/material";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import { setFounderDashboardData } from "../../../modules/actions";
import CommonSlideDialog from "../../../common/CommonSlideDialog";
import theme from "../../../constants/Theme";
import services from "../../../services";
import CircleDisabledIcon from "./check-circle-disabled.svg";
import CircleIcon from "./check-circle.svg";
import { startupProfileProgress } from "../../../utils/profilePercentageCalculation";
import ROUTES_PATH from "../../../constants/routes";

export const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
	borderBottom: `1px dashed #98A2B3`,
}));

export const AccordionSummary = styled((props) => (
	<MuiAccordionSummary
		expandIcon={
			<IconButton sx={{ bgcolor: "#DE0085", width: "24px", height: "24px", "&:hover": { bgcolor: "#DE0085" } }}>
				{props.expanded === "true" ? <RemoveIcon sx={{ fontSize: "16px", color: "#fff" }} /> : <AddIcon sx={{ fontSize: "16px", color: "#fff" }} />}
			</IconButton>
		}
		{...props}
	/>
))(({ theme }) => ({
	padding: 0,
	backgroundColor: "#fff",
	paddingLeft: "2px",
	flexDirection: "row-reverse",
}));

export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
	padding: "8px 40px 16px 40px",
}));

const ProfileContent = () => {
	return (
		<Box ml={2}>
			<List
				sx={{
					listStyleType: "disc",
					"& .MuiListItem-root": {
						display: "list-item",
					},
				}}
			>
				<ListItem sx={{ p: 0 }}>
					<Typography variant="subhead1">Gives a more complete picture of your startup.</Typography>
				</ListItem>
				<ListItem sx={{ p: 0 }}>
					<Typography variant="subhead1">Highlights your startup on the opportunity dashboard.</Typography>
				</ListItem>
			</List>
		</Box>
	);
};

const IncorportateContent = () => {
	return (
		<Box ml={2}>
			<List
				sx={{
					listStyleType: "disc",
					"& .MuiListItem-root": {
						display: "list-item",
					},
				}}
			>
				<ListItem sx={{ p: 0 }}>
					<Typography variant="subhead1">Allows your startup to issue stock and secure funding from investors.</Typography>
				</ListItem>
				<ListItem sx={{ p: 0 }}>
					<Link target="_blank" underline="none" href="https://get.firstbase.io/startupos">
						<Typography variant="custom070" color="#7B61FF">
							Click here to Incorporate now with Firstbase.io.
						</Typography>{" "}
					</Link>
					<Typography variant="subhead1">
						Use Coupon Code “
						<Typography variant="custom070" color="#7B61FF">
							PARTNER10
						</Typography>
						” to receive a 10% discount
					</Typography>
				</ListItem>
			</List>
		</Box>
	);
};

const PitchContent = () => {
	return (
		<Box ml={2}>
			<List
				sx={{
					listStyleType: "disc",
					"& .MuiListItem-root": {
						display: "list-item",
					},
				}}
			>
				<ListItem sx={{ p: 0 }}>
					<Typography variant="subhead1">Helps potential investors gain a complete picture of your startup to assess your business.</Typography>
				</ListItem>
			</List>
		</Box>
	);
};

export default function Journey() {
	const dispatch = useDispatch();
	const history = useHistory();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
	const founderDashboardData = useSelector((state) => state?.founderDashboard);
	const [expanded, setExpanded] = useState("panel1");
	const userData = useSelector((store) => ({ companyId: store?.auth?.companyId, userId: store?.auth?.userId, userEmail: store?.auth?.email }));

	const [data, setData] = useState([
		{ id: 0, disabled: true, title: "Complete Startup Profile", route: ROUTES_PATH.MY_STARTUP_PROFILE, desc: <ProfileContent /> },
		{
			id: 1,
			disabled: true,
			title: "Ensure your startup is incorporated",
			route: ROUTES_PATH.MY_STARTUP_PROFILE,
			desc: <IncorportateContent />,
		},
		{ id: 2, disabled: true, title: "Upload your pitch deck", route: ROUTES_PATH.MY_STARTUP_PROFILE, desc: <PitchContent /> },
	]);

	const handleChange = (panel) => (event, newExpanded) => {
		setExpanded(newExpanded ? panel : false);
	};

	const handleClose = () => {
		dispatch(
			setFounderDashboardData({
				applyToPitchOpen: false,
			})
		);
	};

	const getStartupProfileData = async () => {
		try {
			if (userData?.companyId) {
				let response = await services.getStartupDetails(userData?.companyId);
				const { totalPercentage } = startupProfileProgress(response?.data?.data);
				data[0].title = `Complete Startup Profile [${Math.round(totalPercentage || 0)}%]`;
				if (Math.round(totalPercentage || 0) === 100) {
					data[0].disabled = false;
				}
				data[1].disabled = response?.data?.data?.incorporatedStatus === "Yes" ? false : true;
				data[2].disabled = response?.data?.data?.pitchDeckLink ? false : true;
				setData([...data]);
			}
		} catch (error) {
			console.log("error: Unable to successfully retrieve startup profile by ID:" + error);
		}
	};

	const handleRedirect = (route) => {
		handleClose();
		history.push(route);
	};

	useEffect(() => {
		getStartupProfileData();
	}, []);

	return (
		<CommonSlideDialog
			open={founderDashboardData?.applyToPitchOpen}
			onClose={handleClose}
			title={
				<Box display="flex" flexDirection="column" p="0 0 8px">
					<svg width="30" height="22" viewBox="0 0 30 22" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M12.3332 14.3797C13.9779 13.4291 16.0216 13.4291 17.6663 14.3797M1.6665 15.0001L2.60182 5.6469C2.63728 5.29228 2.65501 5.11497 2.68221 4.96286C3.0028 3.16944 4.49477 1.81922 6.31119 1.67867C6.46526 1.66675 6.64345 1.66675 6.99984 1.66675M28.3332 15.0001L27.3979 5.6469C27.3624 5.29229 27.3447 5.11497 27.3175 4.96286C26.9969 3.16944 25.5049 1.81922 23.6885 1.67867C23.5344 1.66675 23.3562 1.66675 22.9998 1.66675M10.7711 11.2288C12.8539 13.3116 12.8539 16.6885 10.7711 18.7713C8.68829 20.8541 5.3114 20.8541 3.2286 18.7713C1.14581 16.6885 1.14581 13.3116 3.2286 11.2288C5.31139 9.14605 8.68827 9.14605 10.7711 11.2288ZM26.7711 11.2288C28.8539 13.3116 28.8539 16.6885 26.7711 18.7713C24.6883 20.8541 21.3114 20.8541 19.2286 18.7713C17.1458 16.6885 17.1458 13.3116 19.2286 11.2288C21.3114 9.14605 24.6883 9.14605 26.7711 11.2288Z"
							stroke="#7B61FF"
							stroke-width="2"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
					</svg>

					<Typography variant="Text/xl/Semibold" sx={{ mt: 1.5 }}>
						Investors are watching!
					</Typography>
				</Box>
			}
			maxWidth="sm"
			PaperProps={{
				sx: {
					position: "fixed",
					bottom: 0,
					right: 0,
					m: 0,
					px: 2,
					py: 2,
					borderRadius: "30px",
					width: isSmallScreen ? "100%" : theme.breakpoints.values.sm,
				},
			}}
			dialogAction={
				<Box display="flex" sx={{ pr: 3 }} alignItems="center">
					<Box>
						<Button
							sx={{
								borderRadius: "100px",
								background: "#F2F4F7",
								color: "#344054",
								"&:hover": {
									color: "#fff",
								},
							}}
							onClick={handleClose}
						>
							Close
						</Button>
					</Box>
				</Box>
			}
		>
			<Box>
				<Box mb={4}>
					<Typography variant="subhead1">To maximize your chances to be contacted by an investor, let’s show the</Typography>{" "}
					<Typography variant="title_bold">best you!</Typography>
					<Typography variant="subhead1">&nbsp;We recommend a few steps to complete:</Typography>
				</Box>

				{data.map((item) => (
					<Accordion key={item.id} expanded={expanded === item.id} onChange={handleChange(item.id)}>
						<AccordionSummary expanded={expanded === item.id ? "true" : "false"}>
							<Box
								ml={1.5}
								width="100%"
								display="flex"
								alignItems="center"
								justifyContent="space-between"
								onClick={() => (item?.disabled !== true ? handleRedirect(item.route) : handleChange(item.id))}
							>
								<Box display="flex" alignItems="center" gap={1.5}>
									<Typography variant="subhead1">{item.title}</Typography>
									<IconButton sx={{ p: 0 }}>
										<ArrowForwardIcon sx={{ color: "#000" }} />
									</IconButton>
								</Box>
								<img src={item.disabled ? CircleDisabledIcon : CircleIcon} alt="CircleIcon" />
							</Box>
						</AccordionSummary>
						<AccordionDetails>
							<Typography variant="subhead1">{item.desc}</Typography>
						</AccordionDetails>
					</Accordion>
				))}
			</Box>
		</CommonSlideDialog>
	);
}
