import Tooltip from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { tooltipClasses } from "@mui/material";
export const EventTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: theme.palette.secondary.white,
		fontSize: theme.typography.pxToRem(12),
		border: `3px solid ${theme.palette.secondary.white}`,
		maxWidth: 500,
	},
	[`& .${tooltipClasses.arrow}`]: {
		color: theme.palette.secondary.white,
		width: "20px",
	},
}));
