import styled from "@emotion/styled";
import { Box, Tabs, Tab, Slider } from "@mui/material";
import theme from "../../constants/Theme";
import { themeShape } from "../../constants/Theme/shape";
const iOSBoxShadow =
  "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)";
const getBackground = type => {
  if (type === "TAM") {
    return theme.palette.text.custom070;
  } else if (type === "SAM") {
    return theme.palette.text.custom050;
  } else {
    return theme.palette.text.custom030;
  }
};

export const PowerupPacksContainerWrapper = styled.div(({ theme }) => {
  return {
    marginTop: theme.spacing(6),
  };
});
export const PersonaDetailsCard = styled.div(({ theme, mt = 0 }) => {
  return {
    borderRadius: theme.shape.standard_small.borderRadius,
    marginTop: theme.spacing(mt),
    padding: theme.spacing(3),
    ".edit-btn": {
      display: "none",
    },
    "&:hover .edit-btn": {
      display: "block",
    },

    background:
      "linear-gradient(0deg, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95)), #0A141B",
  };
});

export const PowerupContainer = styled.div(({ theme }) => {
  return {
    marginTop: theme.spacing(9),
  };
});

export const PowerupWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",

  [theme.breakpoints.down("md")]: { justifyContent: "flex-start" },

  "& .MuiOutlinedInput-root": {
    borderRadius: theme.spacing(1),
    paddingLeft: "0px",
    height: "32px",
    width: "max-content",
    background: "rgba(75, 69, 78, 0.12)",
    border: `1px solid ${theme.palette.secondary.dark}`,
  },

  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: `none`,
  },
}));

export const TabsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  maxWidth: "100%",
  bgcolor: "background.paper",

  "& .MuiTabs-root": {
    borderBottom: "none",
  },
  "& .MuiTab-root.Mui-selected": {
    color: `${theme.palette.text.primary}`,
  },
  "& .MuiTabs-indicator": {
    backgroundColor: `${theme.palette.tabIndicator.main}`,
  },
  [theme.breakpoints.down("md")]: {
    display: "grid",
  },
}));

export const PowerUpTabs = styled(Tabs)(({ theme }) => ({
  display: "flex",
  width: "100%",
  paddingLeft: theme.spacing(6),
  borderBottom: "1px solid rgba(29, 27, 30, 0.12)",
  [theme.breakpoints.down("md")]: {
    overflow: "hidden",
    overflowWrap: "break",
    padding: theme.spacing(0),
  },
}));

export const PowerUpTab = styled(Tab)(({ theme }) => ({
  width: "inherit",
  color: `${theme.palette.text.inactive}`,
  padding: theme.spacing(3),
  [theme.breakpoints.down("md")]: {
    padding: "24px 40px",
  },
}));

export const LegendComponent = styled.div(({ theme, type }) => {
  return {
    width: 24,
    height: 24,
    borderRadius: theme.shape.standard_small.borderRadius,
    marginRight: theme.spacing(1),
    background: getBackground(type),
  };
});

export const ProgressWrapper = styled(Box)({
  display: "flex",
  width: "100%",
  flexDirection: "column",
  margin: theme.spacing(2),
  marginBottom: theme.spacing(8),
  padding: theme.spacing(2),
  gap: theme.spacing(4),
  boxSizing: "border-box",
  border: `1px solid ${theme.palette.secondary.dark}`,
  borderRadius: theme.shape.standard4.borderRadius,
  color: `${theme.palette.secondary.white}`,
  justifyContent: "center",
  alignItems: "center",
  alignContent: "center",
});

export const CustomSlider = styled(Slider)({
  "& .MuiSlider-thumb": {
    height: 28,
    width: 28,
    backgroundColor: "#5630B2",
    boxShadow: iOSBoxShadow,
    "&:focus, &:hover, &.Mui-active": {},
  },
  "& .MuiSlider-track": {
    border: "none",
    backgroundColor: "#bfbfbf",
  },

  "& .MuiSlider-rail": {
    opacity: 0.5,
    backgroundColor: "#bfbfbf",
  },
});
