import React from "react";
import { FEED_COMMENT_ICON, FEED_LIKE_ICON, WHITE_LIKE_V1_ICON } from "../../../constants/image";
import { Box, IconButton, Typography } from "@mui/material";
import { likeOnFeedPostApiCall, disLikeOnFeedPostApiCall } from "../../../containers/Feed/Functions/common";
import { useDispatch, useSelector } from "react-redux";
import { setFeedData } from "../../../modules/actions";
import { debounce } from "lodash";
import { getRoleValue } from "../../../utils/commonFunctions";
import { handleMixPanel } from "../../../utils/mixPanelEventHandling";

const FeedActionComponent = ({ item }) => {
	const userFeedData = useSelector((state) => state?.feedPost?.feedData);
	const currentPostIdNew = useSelector((state) => state?.feedPost?.currentPostId);
	const dispatch = useDispatch();
	const postData = useSelector((state) => state?.feedPost?.detailsPostObject);
	const authData = useSelector((state) => state?.auth);

	const handleCommentSectionOpen = () => {
		if (currentPostIdNew === item?.id) {
			dispatch(
				setFeedData({
					currentPostId: "",
					getCommentsApiCall: false,
				})
			);
		} else {
			dispatch(
				setFeedData({
					currentPostId: item?.id,
					getCommentsApiCall: true,
				})
			);
		}
	};
	const handleMixPanelLikeEvent = () => {
		handleMixPanel("Liked Feed Post", {
			"Like user name": authData?.userName,
			"Like user id": authData?.userId,
			"Like user role": getRoleValue(authData?.userType),
			"Post id": item?.id,
		});
	};
	const handleMixPanelUnLikeEvent = () => {
		handleMixPanel("Unliked Feed Post", {
			"Unlike user name": authData?.userName,
			"Unlike user id": authData?.userId,
			"Unlike user role": getRoleValue(authData?.userType),
			"Post id": item?.id,
		});
	};
	const handleLike = debounce(
		() => {
			if (!item?.isReacted) {
				const payload = {
					reactionType: "LIKE",
				};
				likeOnFeedPostApiCall(item?.id, payload)
					.then((data) => {
						handleMixPanelLikeEvent();
						const tempList = [...userFeedData];
						const tempPostObj = { ...postData };
						tempList.forEach((post, index) => {
							if (post?.id === item?.id) {
								if (!post["isReacted"]) {
									post["reactionsCount"] += 1;

									post["isReacted"] = true;
								}
							}
						});
						if (!tempPostObj["isReacted"]) {
							tempPostObj["reactionsCount"] += 1;
							tempPostObj["isReacted"] = true;
						}

						dispatch(
							setFeedData({
								feedData: tempList,
								detailsPostObject: tempPostObj,
							})
						);
					})
					.catch((error) => {
						console.log("Feed Action Error", error);
					});
			} else {
				const payload = {
					reactionType: "LIKE",
				};
				disLikeOnFeedPostApiCall(item?.id, "deleteReaction", payload)
					.then((data) => {
						handleMixPanelUnLikeEvent();
						const tempList = [...userFeedData];
						const tempPostObj = { ...postData };
						tempList.forEach((post, index) => {
							if (post?.id === item?.id) {
								if (post["isReacted"]) {
									post["reactionsCount"] -= 1;
									post["isReacted"] = false;
								}
							}
						});
						if (tempPostObj["isReacted"]) {
							tempPostObj["reactionsCount"] -= 1;
							tempPostObj["isReacted"] = false;
						}
						dispatch(
							setFeedData({
								feedData: tempList,
								detailsPostObject: tempPostObj,
							})
						);
					})
					.catch((error) => {
						console.log("Feed Action error", error);
					});
			}
		},
		600,
		{
			maxWait: 1000,
		}
	);
	return (
		<Box
			sx={(theme) => ({
				display: "flex",
				gap: 4,
				[theme.breakpoints.down("sm")]: {
					flexDirection: "column",
					gap: 2,
				},
			})}
		>
			<Box
				sx={{
					display: "flex",
					gap: 1,
					alignItems: "center",
				}}
			>
				{item?.isReacted ? (
					<IconButton
						onClick={handleLike}
						sx={{
							width: "40px",
							height: "40px",
							borderRadius: "100%",
							backgroundColor: (theme) => theme.palette.primary.primaryPurple,
							"&:hover": { backgroundColor: (theme) => theme.palette.primary.primaryPurple },
						}}
					>
						<Box component={"img"} src={WHITE_LIKE_V1_ICON} alt="like feed" sx={{ width: "16px", height: "16px" }} />
					</IconButton>
				) : (
					<IconButton onClick={handleLike} sx={{ width: "40px", height: "40px", borderRadius: "100%", backgroundColor: "#F2F4F7" }}>
						<Box component={"img"} src={FEED_LIKE_ICON} alt="like feed" sx={{ width: "16px", height: "16px" }} />
					</IconButton>
				)}
				{item?.reactionsCount ? (
					<Typography variant="Text/sm/Regular">Like ({item?.reactionsCount})</Typography>
				) : (
					<Typography variant="Text/sm/Regular">Like</Typography>
				)}
			</Box>
			<Box
				onClick={handleCommentSectionOpen}
				sx={{
					display: "flex",
					gap: 1,
					alignItems: "center",
					cursor: "pointer",
				}}
			>
				<IconButton sx={{ width: "40px", height: "40px", borderRadius: "100%", backgroundColor: "#F2F4F7" }}>
					<Box component={"img"} src={FEED_COMMENT_ICON} alt="like feed" sx={{ width: "16px", height: "16px" }} />
				</IconButton>
				{item?.commentsCount ? (
					<Typography variant="Text/sm/Regular">Comment ({item?.commentsCount})</Typography>
				) : (
					<Typography variant="Text/sm/Regular">Comment</Typography>
				)}
			</Box>
		</Box>
	);
};

export default FeedActionComponent;
