import * as React from "react";
import { Box, Typography } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { MUIAccordion, MUIAccordionDetails, MUIAccordionSummary } from "./styles";

import { CHECK_SQUARE_BROKEN_ICON, CHECK_SQUARE_ICON } from "../../constants";
import InfoHelper from "../../components/Founder/InfoHelper";

export default function CheckboxAccordion({ label = "", ItemList = [], setItemList = [], background = "white", disableAccordian = false }) {
	const [expanded, setExpanded] = React.useState("panel1");

	const handleChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};

	const handleCheckBoxClick = (checkedObj) => {
		const tempCheckedList = [...ItemList];
		tempCheckedList.map((item) => {
			if (checkedObj?.id === item?.id) {
				return (item.checked = !checkedObj?.checked);
			}
		});

		setItemList(tempCheckedList);
	};

	return (
		<div>
			<MUIAccordion background={background} expanded={expanded === "panel1"} onChange={!disableAccordian && handleChange("panel1")}>
				<MUIAccordionSummary expandIcon={<ArrowDropDownIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
					<Typography variant="Text/sm/Semibold" component={"p"}>
						{label}
					</Typography>
				</MUIAccordionSummary>
				<MUIAccordionDetails>
					{ItemList.map((checkBoxItem, idx) => {
						return (
							<Box key={idx} sx={{ cursor: "pointer" }} display={"flex"} alignItems={"center"} gap={2}>
								<Box
									sx={{ cursor: "pointer" }}
									display={"flex"}
									alignItems={"center"}
									gap={2}
									onClick={() => handleCheckBoxClick(checkBoxItem)}
								>
									{checkBoxItem?.checked ? (
										<Box component="img" src={CHECK_SQUARE_BROKEN_ICON} />
									) : (
										<Box component="img" src={CHECK_SQUARE_ICON} />
									)}
									<Typography variant="Text/sm/Regular" component={"p"}>
										{checkBoxItem?.label}
									</Typography>
								</Box>
								<InfoHelper title={checkBoxItem?.label} body={checkBoxItem?.helperText} iconType={"gray"} />
							</Box>
						);
					})}
				</MUIAccordionDetails>
			</MUIAccordion>
		</div>
	);
}
