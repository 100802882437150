import React from "react";
import { WorkspacePopoverBtn, UserSection, UserPopoverBtn } from "./styles";
import { Popover, Divider, Typography, Box, useMediaQuery, Button } from "@mui/material";
import theme from "../../../constants/Theme";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import LogoutIcon from "@mui/icons-material/Logout";
import SettingsIcon from "@mui/icons-material/Settings";
import AccountSettingsPopup from "../../../containers/Dashboard/AccountSettings/AccountSettingsPopup";
import MessageHandlerModal from "../../../common/modal/MessageHandler";
import ProfileMenuDrawer from "../../../containers/Mentor/Network/StartupProfile/Components/ProfileMenuDrawer";
import { USER_TYPES } from "../../../utils/userTypes";
import ROUTES_PATH from "../../../constants/routes";
import { useHistory } from "react-router-dom";
import { setAuthData } from "../../../modules/actions";
import { NEW_PROFILE_LOGO, STARTUP_LOGO } from "../../../constants";

export default function WorkspaceUser({ onLogout, userData, showAccountSettingPopup, closeAccountSettingsPopup, handleOpen }) {
	const [anchorEl, setAnchorEl] = useState(null);
	const [confirmPopup, setConfirmPopup] = useState(false);
	const [openDrawer, setOpenDrawer] = useState(false);
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
	const userType = useSelector((store) => store?.auth?.userType);
	const history = useHistory();
	const dispatch = useDispatch();

	useEffect(() => {
		if (showAccountSettingPopup) {
			handleClose();
		}
	});

	const StartupUserPopOver = ({ userType }) => {
		return (
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				PaperProps={{
					sx: {
						borderRadius: 1,
						boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)",
						minWidth: "280px",
					},
				}}
			>
				<WorkspacePopoverBtn>
					<List disablePadding>
						<ListItem disablePadding sx={{ minHeight: "60px" }}>
							<ListItemButton
								onClick={() => {
									handelProfileClick();
								}}
							>
								<ListItemIcon sx={{ minWidth: 0, pr: 2 }}>
									<Box>
										<Box
											component="img"
											src={userData?.picture && userData?.picture !== "" ? userData?.picture : NEW_PROFILE_LOGO}
											width={36}
											height={36}
											sx={{
												borderRadius: "50%",
												objectFit: "cover",
											}}
										/>
									</Box>
								</ListItemIcon>
								<ListItemText>
									<Typography variant="body_large">Account Profile</Typography>
								</ListItemText>
							</ListItemButton>
						</ListItem>

						<>
							<Divider />
							<ListItem disablePadding sx={{ minHeight: "60px" }}>
								<ListItemButton onClick={handleOpen}>
									<ListItemIcon sx={{ minWidth: 0, pr: 2 }}>
										<SettingsIcon />
									</ListItemIcon>
									<ListItemText>
										<Typography variant="body_large">Account Settings</Typography>
									</ListItemText>
								</ListItemButton>
							</ListItem>
						</>

						<Divider />
						<ListItem disablePadding sx={{ minHeight: "60px" }}>
							<ListItemButton onClick={handleLogout}>
								<ListItemIcon sx={{ minWidth: 0, pr: 2 }}>
									<LogoutIcon />
								</ListItemIcon>
								<ListItemText>
									<Typography variant="body_large">Logout</Typography>
								</ListItemText>
							</ListItemButton>
						</ListItem>
					</List>
				</WorkspacePopoverBtn>
				<Divider />
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						flex: 1,
					}}
				>
					<Button variant="text" sx={{ flex: 1, fontSize: "12px", p: 1 }} href="https://startupos.com/privacy-policy/" target="_blank">
						Privacy Policy
					</Button>
					<Button variant="text" sx={{ flex: 1, fontSize: "12px", p: 1 }} href="https://startupos.com/terms-of-service/" target="_blank">
						Terms of Service
					</Button>
				</Box>
			</Popover>
		);
	};

	//remove later
	const OtherUserPopOver = () => {
		return (
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "left",
				}}
			>
				<UserPopoverBtn onClick={onLogout}>Logout</UserPopoverBtn>
			</Popover>
		);
	};

	const handleLogout = () => {
		setConfirmPopup(true);
		handleClose();
	};

	const handleClick = (event) => {
		// setAnchorEl(event.currentTarget);
		if (isSmallScreen) {
			setOpenDrawer(true);
		} else {
			setAnchorEl(event.currentTarget);
		}
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const popUpClose = () => {
		setConfirmPopup(false);
		handleClose();
	};
	const handelProfileClick = () => {
		//userType === "MENTOR_USER"
		//  ?
		history.push(ROUTES_PATH.MY_ACCOUNT_PROFILE);
		dispatch(setAuthData({ currentSelectedNavItem: "accountProfile" }));

		//: userType === "FOUNDER_USER"
		//? history.push(ROUTES_PATH.STARTUP_MYPROFILE)
		//: history.push(ROUTES_PATH.ADVISOR_PROFILE);
		handleClose();
	};
	const open = Boolean(anchorEl);
	const id = open ? "simple-popover" : undefined;
	return (
		<>
			<UserSection aria-describedby={id} variant="contained" onClick={handleClick}>
				<img
					src={userData?.picture && userData?.picture !== "" ? userData?.picture : NEW_PROFILE_LOGO}
					style={{
						objectFit: "cover",
						width: 50,
						height: 50,
						borderRadius: "50%",
					}}
				/>
			</UserSection>
			<StartupUserPopOver userType={userType} />

			<ProfileMenuDrawer
				openDrawer={openDrawer}
				setOpenDrawer={setOpenDrawer}
				userData={userData}
				userType={userType}
				handleLogout={handleLogout}
				handleOpen={handleOpen}
				handelProfileClick={handelProfileClick}
				dummyLogo={NEW_PROFILE_LOGO}
			/>

			<MessageHandlerModal
				isOpen={confirmPopup}
				onCancel={popUpClose}
				onOk={() => onLogout()}
				okText="Ok"
				cancelText={"Cancel"}
				heading="Logout"
				text={"Are you sure you want to logout?"}
				messageType="primary"
			/>

			<AccountSettingsPopup open={showAccountSettingPopup} onClose={closeAccountSettingsPopup} />
		</>
	);
}
