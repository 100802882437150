import React, { useState, useEffect } from "react";
import {
  Typography,
  CardContent,
  Box,
  Card,
  Grid,
  Divider,
  Checkbox,
} from "@mui/material";

import { sosPrimary } from "../../../../constants/Theme/colors";
import theme from "../../../../constants/Theme";
import { RIGHT_ARROW } from "../../../../constants";

const ProblemSolutionSummaryBox = ({ problemSolutionObj }) => {
  return (
    <Box position={"relative"}>
      <Grid container alignItems={"center"}>
        <Grid sm={12} xs={12} lg={6} md={6}>
          <Card
            sx={theme => ({
              borderRadius: theme.shape.standard3.borderRadius,
              backgroundColor: sosPrimary[500],
            })}
          >
            <CardContent>
              <Typography
                variant="headline6"
                color={theme.palette.secondary.white}
              >
                {problemSolutionObj.title}
              </Typography>
              <Box sx={{ mt: 1 }}>
                <Typography
                  variant="subhead1"
                  color={theme.palette.secondary.white}
                >
                  {problemSolutionObj.problem}
                </Typography>
              </Box>

              <Divider
                sx={{
                  background: theme.palette.secondary.white,
                  mt: 3,
                }}
              />
              <Box sx={{ mt: 3 }}>
                {" "}
                <Typography
                  variant="lable_large"
                  color={theme.palette.secondary.white}
                >
                  Symptoms
                </Typography>
              </Box>

              <Box sx={{ mt: 1 }}>
                <Typography
                  variant="subhead2"
                  color={theme.palette.secondary.white}
                >
                  {problemSolutionObj.symptoms}
                </Typography>
              </Box>
              <Box sx={{ mt: 3 }}>
                <Typography
                  variant="lable_large"
                  color={theme.palette.secondary.white}
                >
                  Existing Alternatives
                </Typography>
              </Box>

              <Box sx={{ mt: 1 }}>
                <Typography
                  variant="subhead2"
                  color={theme.palette.secondary.white}
                >
                  {problemSolutionObj.existingAlternatives}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Box
          component="img"
          src={RIGHT_ARROW}
          sx={{
            position: "absolute",
            zIndex: "1",
            left: "50%",
            transform: "translateX(-50%)",
            display: { xs: "none", sm: "none", md: "block" },
          }}
        ></Box>

        <Grid sm={12} xs={12} lg={6} md={6}>
          <Card
            sx={theme => ({
              borderRadius: theme.shape.standard3.borderRadius,
              backgroundColor: sosPrimary[25],
              [theme.breakpoints.up("md")]: {
                ml: 1,
              },
              [theme.breakpoints.down("md")]: {
                mt: 3,
              },
            })}
          >
            <CardContent>
              {problemSolutionObj.solutionList.map((item, idx) => {
                return (
                  <Box>
                    <Box sx={idx !== 0 ? { mt: 2 } : ""}>
                      <Typography
                        variant="title_small"
                        color={theme.palette.secondary.lightOnPrimaryContainer}
                      >
                        Solution
                      </Typography>
                    </Box>

                    <Box sx={{ mt: 1 }}>
                      <Typography
                        variant="subhead1"
                        color={theme.palette.secondary.main}
                      >
                        {item}
                      </Typography>
                    </Box>
                    {problemSolutionObj.solutionList.length - 1 !== idx ? (
                      <Divider
                        sx={{
                          background:
                            theme.palette.secondary.lightOnPrimaryContainer,
                          mt: 3,
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </Box>
                );
              })}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProblemSolutionSummaryBox;
