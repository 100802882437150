import React, { useEffect } from "react";
import { Box, Grid, Stack, Typography, CircularProgress, Button, Skeleton } from "@mui/material";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import FeedPostCardComponent from "../../components/Feed/FeedPostCard";
import PostOperationComponet from "../../components/Feed/PostOperationPopup";
import { useDispatch, useSelector } from "react-redux";
import { setFeedData } from "../../modules/actions";
import { PAGE_FEED_ICON } from "../../constants/image";
import FeedPostDetailsPopUp from "../../components/Feed/FeedPostDetailsSection";
import { getFeedPostByIdFunction, getFeedPostList } from "./Functions/common";
import LatestUpdatesComp from "../../components/Feed/LatestUpdates";
import InvestorOptions from "../../common/InvestorOptions";
import UpgradePlan from "../../common/UpgradePlan";
import { handleMixPanel } from "../../utils/mixPanelEventHandling";

const UserFeeds = () => {
	const userFeedData = useSelector((state) => state?.feedPost?.feedData);
	const createPostIsOpen = useSelector((state) => state?.feedPost?.createPostOpen);
	const isAdmin = useSelector((store) => store?.auth?.isAdmin);
	const isPremiumPlanAccess = useSelector((store) => store?.auth?.isPremiumPlanAccess);
	const dispatch = useDispatch();
	const [feedPageIndex, setFeedPageIndex] = React.useState(1);
	const [pageData, setPageData] = React.useState({ isLast: false });
	const [pageLoader, setPageLoader] = React.useState(false);
	const skeletonLoaderArray = [1, 2, 3, 4];
	const location = useLocation();

	useEffect(() => {
		handleMixPanel("Social Feed Viewed");

		dispatch(
			setFeedData({
				createPostOpen: false,
				detailsPopUpOpen: false,
				editOrDeletePopUpOpen: false,
				getCommentsApiCall: false,
			})
		);
	}, []);

	useEffect(() => {
		if (location.pathname.includes("/feed/")) {
			getFeedPostByIdFunction(location.pathname.slice(6))
				.then((response) => {
					dispatch(
						setFeedData({
							detailsPostObject: response?.data?.data,
							currentPostId: response?.data?.data?.id,
							getCommentsApiCall: true,
							detailsPopUpOpen: true,
						})
					);
				})
				.catch((error) => {
					console.log("error", error);
				});
		}
	}, []);

	const getFeedDataApiCall = (page, perPage, filter, sort) => {
		if (page === 1) {
			setPageLoader(true);
		}
		getFeedPostList(page, perPage, filter, sort)
			.then((response) => {
				const newFeedData = response.data.data;

				setPageData({
					totalPages: newFeedData?.totalPages || 0,
					totalElements: newFeedData?.totalElements || 0,
					isFirst: newFeedData?.isFirst,
					isLast: newFeedData?.isLast,
				});
				const postData = [];
				newFeedData?.models?.map((postItem, index) => {
					const newPostItem = { ...postItem, comments: [] };

					postData.push(newPostItem);
				});
				postData.map((metaItem) => {
					metaItem.metaData = metaItem.metaData ? JSON.parse(metaItem.metaData) : null;
				});
				if (!newFeedData.isFirst) {
					let tempList = userFeedData;

					dispatch(setFeedData({ feedData: [...tempList, ...postData] }));
				} else {
					dispatch(setFeedData({ feedData: postData }));
				}
				setPageLoader(false);
			})
			.catch((error) => {
				setPageLoader(false);

				return error;
			});
	};

	useEffect(() => {
		getFeedDataApiCall(feedPageIndex, 10, "", "");
	}, [feedPageIndex]);

	useEffect(() => {
		if (createPostIsOpen === false) {
			getFeedDataApiCall(1, 10, "", "");
		}
	}, [createPostIsOpen]);

	const getNext = () => {
		if (!pageData?.isLast) {
			setFeedPageIndex(feedPageIndex + 1);
		}
	};

	const hnadleCreatePostOpen = () => {
		dispatch(setFeedData({ createPostOpen: true, postOperationType: "create", metaLinks: [] }));
	};

	const SkeletonCardLoader = () => {
		return (
			<Box
				sx={(theme) => ({
					borderRadius: theme.shape.standard10.borderRadius,
					height: "fit-content",
					p: 4,
					display: "flex",
					flexDirection: "column",
					// gap: 3,
					backgroundColor: theme.palette.secondary.white,
					cursor: "pointer",
					boxShadow: "0px 0px 25px 10px rgba(16, 24, 40, 0.03)",
				})}
			>
				{" "}
				<Stack spacing={2}>
					<Box display={"flex"} gap={2}>
						<Box>
							<Skeleton animation="wave" variant="circular" width={50} height={50} />
						</Box>
						<Skeleton variant="rectangular" height={50} width={"100%"} sx={{ borderRadius: "30px" }} />
					</Box>
					<Skeleton animation="wave" variant="rectangular" sx={{ borderRadius: "30px" }} height={100} />
				</Stack>
			</Box>
		);
	};

	return (
		<>
			<Helmet>
				<meta charSet="utf-8" />
				<title>StartupOS - Social Feed</title>
			</Helmet>

			<Stack
				sx={(theme) => ({
					width: 1,
					borderRadius: theme.shape.standard.borderRadius,
					[theme.breakpoints.down("md")]: {
						borderRadius: 0,
					},
				})}
			>
				<Box
					sx={(theme) => ({
						overflowY: "scroll",
						height: "100vh",
						maxWidth: theme.breakpoints.values.xl,
						width: 1,
						display: "flex",
						flexDirection: "column",
						gap: "32px",
						margin: "0 auto",
						pb: 4,
					})}
				>
					<Grid
						container
						sx={(theme) => ({
							display: "flex",
							flexDirection: "row",
							width: 1,
							pl: 4,
						})}
						spacing={4}
					>
						<Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
							<Box
								sx={(theme) => ({
									display: "flex",
									alignItems: "center",
									gap: 2,
									[theme.breakpoints.down("sm")]: {
										flexDirection: "column",
										alignItems: "baseline",
									},
								})}
							>
								<Box
									sx={{
										display: "flex",
										alignItems: "center",
										gap: 1,
									}}
								>
									<Box component={"img"} height={30} width={30} src={PAGE_FEED_ICON} alt="feed" />
									<Typography variant="growth_card_subtitle" color="secondary.black">
										Social Feed
									</Typography>
								</Box>
								<Button
									sx={(theme) => ({
			
										ml: "auto",
						
										[theme.breakpoints.down("sm")]: {
											ml: 0,
										},
									})}
									variant="DS1"
									onClick={hnadleCreatePostOpen}
								>
									Start a post
								</Button>
							</Box>
							<Box
								sx={(theme) => ({
									mt: 4,
									borderRadius: "24px",
								})}
								// height={"calc(100vh + 270px)"}
								// height={"calc(100vh - 170px)"}
							>
								{pageLoader ? (
									<Stack spacing={2}>
										{" "}
										{skeletonLoaderArray.map((item) => {
											return <SkeletonCardLoader />;
										})}
									</Stack>
								) : (
									<InfiniteScroll
										dataLength={userFeedData?.length}
										next={() => {
											getNext();
										}}
										style={{
											overflowX: "hidden",
											borderRadius: "24px",
											display: "flex",
											flexDirection: "column",
											gap: "32px",
										}}
										height={"calc(100vh + 270px)"}
										// height={"calc(100vh - 170px)"}
										hasMore={!pageData?.isLast}
										loader={
											<Box display={"flex"} justifyContent="center" sx={{ my: 2 }}>
												<CircularProgress />
											</Box>
										}
									>
										{userFeedData?.map((item) => {
											return <Box>{<FeedPostCardComponent item={item} />}</Box>;
										})}
									</InfiniteScroll>
								)}
							</Box>
						</Grid>
						<Grid
							item
							xs={12}
							sm={12}
							md={5}
							lg={5}
							xl={5}
							sx={(theme) => ({
								[theme.breakpoints.down("lg")]: {
									display: "none",
								},
							})}
						>
							<Grid container spacing={4}>
								{/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mt={10}>
									{isPremiumPlanAccess || isAdmin ? <InvestorOptions /> : <UpgradePlan />} */}{" "}
								{/* TODO: Need to check when the following component is needed */}
								{/* <UpdatedInvestorImpressions /> */}
								{/* </Grid> */}
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
									<LatestUpdatesComp />
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Box>
				<PostOperationComponet getFeedDataApiCall={getFeedDataApiCall} feedPageIndex={feedPageIndex} />
				<FeedPostDetailsPopUp />
			</Stack>
		</>
	);
};

export default UserFeeds;
