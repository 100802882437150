import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Box, Typography, Dialog, TextField, FormControl, FormControlLabel, Radio, RadioGroup, MenuItem } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { createSavedFilterApiCall, getListOfSavedFilterApiCall, updateSavedFilterApiCall } from "../../../../../containers/Investor/Dashboard/Functions/common";
import { setInvestorDashboardData } from "../../../../../modules/actions";
import LoadingButton from "../../../../../common/Loadingbutton";
import { KeyboardArrowDown, ToggleOffOutlined } from "@mui/icons-material";
import RoundedSelectInput from "../../../../../common/RoundedSelectInput";
import { regionStateObj } from "../../../../../constants";
import { StyledSwitch } from "../../../../../containers/Meetings/Availability/style";
import services from "../../../../../services";
import { getTeamMemberFilterKeys } from "../../../../../utils/commonFunctions";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="left" ref={ref} {...props} />;
});

const SaveAsComponent = ({ open, handleCloseSaveas, disableBackDropClick, setIsFilterApplied, setFilterResult }) => {
	const currentDate = new Date();
	const options = { year: "numeric", month: "long", day: "numeric" };
	const formattedDate = currentDate.toLocaleDateString("en-US", options);
	const defaultName = `${formattedDate} Saved search`;

	const filteredValue = useSelector((state) => state?.investorDashboard?.filteredValue) || [];
	const savedFilterEditData = useSelector((state) => state?.investorDashboard?.savedFilterEditData);
	const dispatch = useDispatch();
	const savedSearchesData = useSelector((state) => state?.investorDashboard?.savedSearchesData) || [];
	const [newSearchValue, setNewSearchValue] = useState(defaultName);
	const [savedSearchesArray, setSavedSearchesArray] = useState([]);
	const [loadingButton, setLoadingButton] = useState(false);

	const [editSearchFilterId, setEditSearchFilterId] = useState(savedFilterEditData?.isEdit ? savedFilterEditData?.id : "");
	const [editSearchFilterName, setEditSearchFilterName] = useState(savedFilterEditData?.isEdit ? savedFilterEditData?.name : "");
	const [operation, setOperation] = useState(savedFilterEditData?.isEdit ? "edit" : "new");
	const [emailNotifications, setEmailNotifications] = useState(false);
	const [cohortList, setCohortList] = useState([]);

	useEffect(() => {
		setOperation(savedFilterEditData?.isEdit ? "edit" : "new");
		setEditSearchFilterId(savedFilterEditData?.isEdit ? savedFilterEditData?.id : "");
		setEditSearchFilterName(savedFilterEditData?.isEdit ? savedFilterEditData?.name : "");
		setEmailNotifications(savedFilterEditData?.isEmailNotificationEnabled || false);
	}, [savedFilterEditData]);

	useEffect(() => {
		setNewSearchValue(newSearchValue ? newSearchValue : defaultName);
		if (!open) {
			setEmailNotifications(false);
		}
	}, [open]);
	useEffect(() => {
		if (operation === "edit") {
			getSavedSearchApiCall();
		}
	}, [operation]);

	const getCohortsList = async () => {
		const list = await services.getCohorts();
		setCohortList(list.data?.data || []);
	};
	useEffect(() => {
		if (cohortList.length === 0) {
			getCohortsList();
		}
	}, [cohortList]);

	const getSavedSearchApiCall = () => {
		getListOfSavedFilterApiCall()
			.then((result) => {
				const data = result?.data?.data;
				data.map((dataItem, idx) => {
					let updatedFilterArray = [];

					Object.keys(dataItem?.searchFilters).map((key) => {
						if (key === "foundedStartYear" && !dataItem?.searchFilters["foundedYearTrailingRange"]) {
							let foundedStartYear = dataItem?.searchFilters[key];
							let foundedEndYear = dataItem?.searchFilters["foundedEndYear"];
							if (foundedStartYear !== null && foundedEndYear !== null) {
								updatedFilterArray.push({
									type: "foundedYear",
									foundedStartYear: foundedStartYear,
									foundedEndYear: foundedEndYear,
									checkboxValue: `${foundedStartYear} - ${foundedEndYear}`,
								});
							}
						} else if (key === "fundingRaisedMin") {
							let fundingRaisedMin = dataItem?.searchFilters[key];
							let fundingRaisedMax = dataItem?.searchFilters["fundingRaisedMax"];
							if (fundingRaisedMax !== null && fundingRaisedMin !== null) {
								updatedFilterArray.push({
									type: "fundingRaised",
									fundingRaisedMin: fundingRaisedMin,
									fundingRaisedMax: fundingRaisedMax,
									checkboxValue: `${Intl.NumberFormat("en-US", {
										notation: "compact",
										maximumFractionDigits: 1,
									}).format(Number(fundingRaisedMin))} - ${Intl.NumberFormat("en-US", {
										notation: "compact",
										maximumFractionDigits: 1,
									}).format(Number(fundingRaisedMax))}`,
								});
							}
						} else if (key === "askMin") {
							let askMin = dataItem?.searchFilters[key];
							let askMax = dataItem?.searchFilters["askMax"];
							if (askMax !== null && askMin !== null) {
								updatedFilterArray.push({
									type: "ask",
									askMin: askMin,
									askMax: askMax,
									checkboxValue: `${Intl.NumberFormat("en-US", {
										notation: "compact",
										maximumFractionDigits: 1,
									}).format(Number(askMin))} - ${Intl.NumberFormat("en-US", {
										notation: "compact",
										maximumFractionDigits: 1,
									}).format(Number(askMax))}`,
								});
							}
						} else if (key === "minMrr") {
							let minMrr = dataItem?.searchFilters[key];
							let maxMrr = dataItem?.searchFilters["maxMrr"];
							if (maxMrr !== null && minMrr !== null) {
								updatedFilterArray.push({
									type: "mrr",
									minMrr: minMrr,
									maxMrr: maxMrr,
									checkboxValue: `${Intl.NumberFormat("en-US", {
										notation: "compact",
										maximumFractionDigits: 1,
									}).format(Number(minMrr))} - ${Intl.NumberFormat("en-US", {
										notation: "compact",
										maximumFractionDigits: 1,
									}).format(Number(maxMrr))}`,
								});
							}
						} else if (key === "minRunway") {
							let minRunway = dataItem?.searchFilters[key];
							let maxRunway = dataItem?.searchFilters["maxRunway"];
							if (maxRunway !== null && minRunway !== null) {
								updatedFilterArray.push({
									type: "runway",
									minRunway: minRunway,
									maxRunway: maxRunway,
									checkboxValue: `${Intl.NumberFormat("en-US", {
										notation: "compact",
										maximumFractionDigits: 1,
									}).format(Number(minRunway))} - ${Intl.NumberFormat("en-US", {
										notation: "compact",
										maximumFractionDigits: 1,
									}).format(Number(maxRunway))}`,
								});
							}
						} else if (key === "minScore") {
							let minScore = dataItem?.searchFilters[key];
							let maxScore = dataItem?.searchFilters["maxScore"];
							if (maxScore !== null && minScore !== null) {
								updatedFilterArray.push({
									type: "ask",
									minScore: minScore,
									maxScore: maxScore,
									checkboxValue: `${Intl.NumberFormat("en-US", {
										notation: "compact",
										maximumFractionDigits: 1,
									}).format(Number(minScore))} - ${Intl.NumberFormat("en-US", {
										notation: "compact",
										maximumFractionDigits: 1,
									}).format(Number(maxScore))}`,
								});
							}
						} else if (
							key === "financings" ||
							key === "industries" ||
							key === "stages" ||
							key === "businessModel" ||
							key === "revenueModel" ||
							key === "productOffering" ||
							key === "incorporatedStatus" ||
							key === "pitchDeckAvailable" ||
							key === "isPremiumPlanAccess"
						) {
							let filterArray = dataItem?.searchFilters && dataItem?.searchFilters[key];
							filterArray &&
								filterArray?.map((filterItem) => {
									updatedFilterArray.push({
										type: key,
										checkboxValue: filterItem,
									});
								});
						} else if (key === "startupsJoined") {
							let filterArray = dataItem?.searchFilters && dataItem?.searchFilters[key];

							filterArray &&
								updatedFilterArray.push({
									type: key,
									checkboxValue: `${filterArray} days`,
								});
						} else if (key === "teamMemberTags") {
							let filterArray = dataItem?.searchFilters[key];
							filterArray?.map((filterItem) => {
								updatedFilterArray.push({
									type: key,
									key: filterItem,
									checkboxValue: getTeamMemberFilterKeys(filterItem),
								});
							});
						} else if (key === "states") {
							let filterArray = dataItem?.searchFilters[key];

							filterArray?.map((filterItem) => {
								updatedFilterArray.push({
									type: "location",
									checkboxValue: filterItem,
								});
							});
						} else if (key === "foundedYearTrailingRange" && dataItem?.searchFilters["foundedYearTrailingRange"]) {
							let foundedStartYear = dataItem?.searchFilters["foundedStartYear"];
							let foundedEndYear = dataItem?.searchFilters["foundedEndYear"];
							if (foundedStartYear !== null && foundedEndYear !== null) {
								updatedFilterArray.push({
									type: "foundedYearTrailingRange",
									foundedStartYear: foundedStartYear,
									foundedEndYear: foundedEndYear,
									checkboxValue: dataItem?.searchFilters[key],
								});
							}
						} else if (key === "cohorts" && dataItem?.searchFilters["cohorts"] && dataItem?.searchFilters["cohorts"].length > 0) {
							updatedFilterArray.push({
								type: "cohorts",
								cohortIds: dataItem?.searchFilters["cohorts"],
								checkboxValue: cohortList.filter((cohort) => dataItem?.searchFilters["cohorts"].includes(cohort.id)).map((item) => item.name),
							});
						}
					});

					let filterObj = {
						savedFilterArray: updatedFilterArray,
						name: dataItem?.name,
						count: dataItem?.count,
						userId: dataItem?.userId,
						id: dataItem?.id,
						isEmailNotificationEnabled: dataItem?.isEmailNotificationEnabled || false,
					};

					let uniqueArray = savedSearchesData;

					let existingObjIndex = uniqueArray.findIndex((obj) => obj.id === filterObj.id);

					if (existingObjIndex >= 0) {
						uniqueArray[existingObjIndex] = filterObj;
					} else {
						uniqueArray.push(filterObj);
					}
					let sortedList = uniqueArray.sort((a, b) => b?.id - a?.id);

					dispatch(
						setInvestorDashboardData({
							savedSearchesData: sortedList,
						})
					);
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const handleChange = (event) => {
		setNewSearchValue(event.target.value);
	};

	const handleUpdateSavedChange = (e) => {
		const selectedSavedSearchForEdit = savedSearchesData.find((savedSearch) => savedSearch.id === e.target.value);
		if (selectedSavedSearchForEdit) {
			setEditSearchFilterId(selectedSavedSearchForEdit?.id);
			setEmailNotifications(selectedSavedSearchForEdit?.isEmailNotificationEnabled);
			setEditSearchFilterName(selectedSavedSearchForEdit?.name);
		}
	};

	const handleActionChange = (e) => {
		setOperation(e.target.value);
	};
	function findStartupsJoinedNumber(arr) {
		let largest = arr[0];
		for (let i = 1; i < arr.length; i++) {
			if (arr[i] > largest) {
				largest = arr[i];
			}
		}
		return largest.split(" ")[0];
	}
	const handleSave = () => {
		setFilterResult(true);
		setIsFilterApplied(true);

		const searchValue = { Name: operation === "edit" ? editSearchFilterName : newSearchValue, FilteredVales: filteredValue };

		setSavedSearchesArray((prevArray) => [...prevArray, searchValue]);

		let fundingRaisedObj = filteredValue.filter((filterItem) => filterItem?.type === "fundingRaised")[0];
		let askObj = filteredValue.filter((filterItem) => filterItem?.type === "ask")[0];

		let mrrObj = filteredValue.filter((filterItem) => filterItem?.type === "mrr")[0];
		let runwayObj = filteredValue.filter((filterItem) => filterItem?.type === "runway")[0];

		let foundedYearObj = filteredValue?.filter((filterItem) => filterItem?.type === "foundedYear")[0];

		let scoreRangeObj = filteredValue?.filter((filterItem) => filterItem?.type === "scoreRange")[0];

		let stagesObj = filteredValue?.filter((filterItem) => filterItem?.type === "stages");
		let stagesArray = stagesObj.map((item) => item["checkboxValue"]);

		let financingsObj = filteredValue?.filter((filterItem) => filterItem?.type === "financings");
		let financingsArray = financingsObj.map((item) => item["checkboxValue"]);

		let teamMemberObj = filteredValue?.filter((filterItem) => filterItem?.type === "teamMember");
		let teamMemberArray = teamMemberObj.map((item) => item["key"]);

		let industryObj = filteredValue?.filter((filterItem) => filterItem?.type === "industry");
		let industryArray = industryObj.map((item) => item["checkboxValue"]);
		let trailingRangesObj = filteredValue && filteredValue.filter((filterItem) => filterItem?.type === "foundedYearTrailingRange")[0];

		let productOfferingObj = filteredValue?.filter((filterItem) => filterItem?.type === "productOffering");
		let productOfferingArray = productOfferingObj.map((item) => item["checkboxValue"]);

		let businessModelTypeObj = filteredValue?.filter((filterItem) => filterItem?.type === "businessModel");
		let businessModelTypeArray = businessModelTypeObj.map((item) => item["checkboxValue"]);

		let revenueModelTypeObj = filteredValue?.filter((filterItem) => filterItem?.type === "revenueModel");
		let revenueModelTypeArray = revenueModelTypeObj.map((item) => item["checkboxValue"]);

		let statesObj = filteredValue?.filter((filterItem) => filterItem?.type === "location");
		let statesArray = statesObj.map((item) => item["checkboxValue"]);

		let stateOfIncorporationObj = filteredValue?.filter((filterItem) => filterItem?.type === "incorporatedStatus");
		let stateOfIncorporationArray = stateOfIncorporationObj.map((item) => item["checkboxValue"]);

		let pitchDeckAvailableObj = filteredValue?.filter((filterItem) => filterItem?.type === "pitchDeckAvailable");
		let pitchDeckAvailableArray = pitchDeckAvailableObj.map((item) => item["checkboxValue"]);

		let isPremiumPlanAccessObj = filteredValue?.filter((filterItem) => filterItem?.type === "isPremiumPlanAccess");
		let isPremiumPlanAccessArray = isPremiumPlanAccessObj.map((item) => item["checkboxValue"]);

		let cohortsObj = filteredValue?.filter((filterItem) => filterItem?.type === "cohorts");
		let cohortIds = cohortsObj.map((item) => item["cohortIds"]);

		let startupsJoinedObj = filteredValue?.filter((filterItem) => filterItem?.type === "startupsJoined");
		let startupsJoinedArray = startupsJoinedObj.map((item) => item["checkboxValue"]);
		let finalStartupsJoinedValue = [];

		if (startupsJoinedArray?.length > 0) {
			finalStartupsJoinedValue = findStartupsJoinedNumber(startupsJoinedArray);
		} else {
			finalStartupsJoinedValue = "";
		}
		let payload = {
			name: operation === "edit" ? editSearchFilterName : newSearchValue,
			isEmailNotificationEnabled: emailNotifications,
			count: filteredValue.length,
			searchFilters: {
				foundedStartYear: foundedYearObj?.foundedStartYear || trailingRangesObj?.foundedStartYear,
				foundedEndYear: foundedYearObj?.foundedEndYear || trailingRangesObj?.foundedEndYear,
				fundingRaisedMax: fundingRaisedObj?.fundingRaisedMax === 3000000 ? null : fundingRaisedObj?.fundingRaisedMax,
				fundingRaisedMin: fundingRaisedObj?.fundingRaisedMin,
				stages: stagesArray,
				financings: financingsArray,
				teamMemberTags: teamMemberArray,
				industries: industryArray,
				states: statesArray,
				foundedYearTrailingRange: trailingRangesObj?.checkboxValue,
				productOffering: productOfferingArray,
				businessModel: businessModelTypeArray,
				revenueModel: revenueModelTypeArray,
				incorporatedStatus: stateOfIncorporationArray,
				pitchDeckAvailable: pitchDeckAvailableArray,
				isPremiumPlanAccess: isPremiumPlanAccessArray,
				askMax: askObj?.askMax === 15000000 ? null : askObj?.askMax,
				askMin: askObj?.askMin,
				maxMrr: mrrObj?.maxMrr === 1000000 ? null : mrrObj?.maxMrr,
				minMrr: mrrObj?.minMrr,
				cohorts: cohortIds.flat(),
				startupsJoined: finalStartupsJoinedValue,
				minScore: scoreRangeObj?.minScore,
				maxScore: scoreRangeObj?.maxScore,
				minRunway: runwayObj?.minRunway,
				maxRunway: runwayObj?.maxRunway === 36 ? null : runwayObj?.maxRunway,
			},
		};
		if (operation === "edit") {
			payload["savedFilterId"] = editSearchFilterId;
		}
		setLoadingButton(true);
		if (operation === "edit") {
			updateSavedFilterApiCall(payload)
				.then((result) => {
					const data = result?.data?.data;
					handleCloseSaveas();
					setNewSearchValue("");

					let filterObj = {
						savedFilterArray: filteredValue,
						name: editSearchFilterName,
						count: filteredValue.length,
						id: editSearchFilterId,
					};
					setLoadingButton(false);

					let uniqueArray = savedSearchesData;

					let existingObjIndex = uniqueArray.findIndex((obj) => obj.id === filterObj.id);
					uniqueArray[existingObjIndex] = filterObj;

					dispatch(
						setInvestorDashboardData({
							savedFilterEditData: {
								isEdit: false,
								name: null,
							},
							FilterDrawerOpen: false,
							savedSearchesData: uniqueArray,
						})
					);
				})
				.catch((error) => {
					console.log(error);
					setLoadingButton(false);
				});
		} else {
			createSavedFilterApiCall(payload)
				.then((result) => {
					const data = result?.data?.data;
					handleCloseSaveas();
					setNewSearchValue("");

					let filterObj = {
						savedFilterArray: filteredValue,
						name: newSearchValue,
						count: filteredValue.length,
						id: data?.id,
					};

					let uniqueArray = savedSearchesData;
					setLoadingButton(false);

					uniqueArray.push(filterObj);

					dispatch(
						setInvestorDashboardData({
							FilterDrawerOpen: false,
							savedSearchesData: uniqueArray,
						})
					);
				})
				.catch((error) => {
					console.log(error);
					setLoadingButton(false);
				});
		}
	};

	return (
		<Box>
			<Dialog
				maxWidth="xs"
				fullWidth={true}
				open={open}
				onClose={!disableBackDropClick && handleCloseSaveas}
				TransitionComponent={Transition}
				transitionDuration={700}
				keepMounted
				PaperProps={{
					sx: {
						position: "fixed",
						bottom: 0,
						right: 0,
						m: 0,
						p: 4,
						borderRadius: "20px",
					},
				}}
			>
				<Box
					sx={{
						display: "flex",
						width: "100%",
						alignItems: "center",
					}}
				>
					<Typography sx={{ color: (theme) => theme.palette.secondary.black, width: "fit-content" }} variant="card_name">
						Save Search
					</Typography>
					<CloseIcon
						onClick={() => {
							handleCloseSaveas();
							setNewSearchValue("");
						}}
						sx={{ ml: "auto", cursor: "pointer" }}
					/>
				</Box>
				{!savedFilterEditData?.id && (
					<FormControl>
						<RadioGroup value={operation} onChange={handleActionChange}>
							{!savedFilterEditData?.isEdit && (
								<Box
									sx={{
										mt: 2,
									}}
								>
									<FormControlLabel
										value="new"
										control={<Radio />}
										label={
											<Typography variant="filter_save_text" color="secondary.black">
												New Saved Search
											</Typography>
										}
									/>
									<TextField
										sx={{
											width: "90%",

											ml: 4,
											color: "black",
											height: "60px",
											".MuiOutlinedInput-root": {
												borderRadius: "30px",
												height: "60px",
											},
											".MuiInputBase-input": (theme) => {
												return { ...theme.typography.subhead3, color: theme.palette.secondary.black, px: 2.5 };
											},
										}}
										value={newSearchValue}
										onChange={handleChange}
										disabled={!(operation === "new")}
									/>
								</Box>
							)}
							<Box
								sx={{
									mt: 2,
								}}
							>
								<FormControlLabel
									value="edit"
									control={<Radio />}
									label={
										<Typography variant="filter_save_text" color="secondary.black">
											Update Saved Search
										</Typography>
									}
								/>
								<RoundedSelectInput
									wrapperstyle={{ width: "90%", ml: 4, "& .MuiSelect-select": { pl: "20px" } }}
									fullWidth
									onInputChange={handleUpdateSavedChange}
									placeholder="select"
									selectedValue={editSearchFilterId}
									inputKey="trailingRanges"
									disabled={!(operation === "edit")}
									height="60px"
									IconComponent={KeyboardArrowDown}
									MenuProps={{ PaperProps: { sx: { maxHeight: 150 } } }}
								>
									{savedSearchesData.map((savedSearch) => (
										<MenuItem key={savedSearch.id} value={savedSearch.id}>
											<Typography variant="subhead3" color="secondary.black">
												{savedSearch.name}
											</Typography>
										</MenuItem>
									))}
								</RoundedSelectInput>
							</Box>
						</RadioGroup>
					</FormControl>
				)}
				{savedFilterEditData?.id && (
					<Box
						sx={{
							mt: 2,
						}}
					>
						<Typography mb={2.5} variant="filter_save_text" color="secondary.black">
							Update Saved Search
						</Typography>
						<RoundedSelectInput
							wrapperstyle={{ width: "96%", mt: 1.25, "& .MuiSelect-select": { pl: "20px" } }}
							fullWidth
							onInputChange={handleUpdateSavedChange}
							placeholder="select"
							selectedValue={editSearchFilterId}
							inputKey="trailingRanges"
							disabled={!(operation === "edit")}
							height="60px"
							IconComponent={KeyboardArrowDown}
							MenuProps={{ PaperProps: { sx: { maxHeight: 150 } } }}
						>
							{savedSearchesData.map((savedSearch) => (
								<MenuItem key={savedSearch.id} value={savedSearch.id}>
									<Typography variant="subhead3" color="secondary.black">
										{savedSearch.name}
									</Typography>
								</MenuItem>
							))}
						</RoundedSelectInput>
					</Box>
				)}
				<Box
					sx={{
						mt: 5,
					}}
				>
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
						}}
					>
						<Typography
							sx={{
								color: (theme) => theme.palette.secondary.black,
								width: "fit-content",
							}}
							variant="filter_save_text"
						>
							Receive an Alert when a new startup meets your filter criteria
						</Typography>
					</Box>
				</Box>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						gap: "10px",
					}}
				>
					<StyledSwitch
						checked={emailNotifications}
						onChange={(e) => {
							setEmailNotifications(e.target.checked);
						}}
						inputProps={{ "aria-label": "controlled" }}
						color="secondary"
					/>
					<Typography
						sx={{
							color: (theme) => theme.palette.secondary.black,
							width: "fit-content",
							textAlign: "center",
							ml: 1,
						}}
						variant="filter_save_text"
					>
						Email
					</Typography>
				</Box>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						gap: "10px",
					}}
				>
					<StyledSwitch disabled inputProps={{ "aria-label": "controlled" }} color="secondary" />
					<Typography
						sx={{
							color: (theme) => theme.palette.secondary.black,
							width: "fit-content",
							textAlign: "center",
							ml: 1,
						}}
						variant="filter_save_text"
					>
						Text (coming soon)
					</Typography>
				</Box>
				<Box display={"flex"} alignItems="center" gap={1} sx={{ ml: "auto", mt: 2 }}>
					<Button
						onClick={() => {
							handleCloseSaveas();
							setNewSearchValue("");
						}}
						sx={{ margin: "0", width: "fit-content" }}
						variant="noOutlineNew"
					>
						Cancel
					</Button>
					<Box>
						{" "}
						<LoadingButton
							sx={{
								width: "max-content",
							}}
							loadingStyles={{ px: 3 }}
							variant="containedNewStyle"
							onClick={handleSave}
							loading={loadingButton}
						>
							Save
						</LoadingButton>
					</Box>
				</Box>
			</Dialog>
		</Box>
	);
};

export default SaveAsComponent;
