import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import SocialMediaShareButtons from "../../SocialMediaShareButtons";

const UserProfileCardSection = ({ icon, title, iconColor, iconHeight, iconWidth, children, count, shareUrl, isSvg = false, email="" }) => {
	return (
		<Stack spacing={1}>
			{icon ? (
				isSvg ? (
					icon
				) : (
					<Box component={"img"} src={icon} sx={{ height: `${iconHeight}`, width: `${iconWidth}` }} />
				)
			) : (
				<Box sx={{ height: `${iconHeight}`, width: `${iconWidth}` }} />
			)}
			<Box
				sx={{
					display: "flex",
					gap: 1,
					alignItems: "center",
				}}
			>
				<Typography variant="flyout_title">{title}</Typography>
				{count ? <Typography variant="flyout_title">[{count}]</Typography> : <></>}
				{shareUrl ? (
					<Box ml={"auto"}>
						<SocialMediaShareButtons
							sharePlatformsList={["FB", "TW", "EM"]}
							url={shareUrl}
							title={`Check out my company profile on StartupOS`}
							description={`Check out my profile on StartupOS`}
							windowWidth={500}
							windowHeight={500}
							emailSeparator="\n"
							email={email}
						/>
					</Box>
				) : (
					<></>
				)}
			</Box>
			<Box>{children}</Box>
		</Stack>
	);
};

export default UserProfileCardSection;
