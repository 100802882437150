import styled from "@emotion/styled";
import { Card, CardMedia, Box, Typography } from "@mui/material";

export const CardHero = styled(Card)(({ theme }) => ({
  color: "darkslategray",
  backgroundColor: "aliceblue",
  borderRadius: 8,
  boxShadow:
    "0px 8px 12px 6px rgba(0, 0, 0, 0.15), 0px 4px 4px rgba(0, 0, 0, 0.3)",
  padding: 0,
  width: 1192,
  height: 600,
  // display: "flex",
  // flexDirection: "column",
  // position: "relative"
}));

export const CardMediaHero = styled(CardMedia)(({ theme }) => ({
  position: "absolute",
  borderRadius: 8,
  height: 600,
  width: "100%",
}));

export const BoxCard = styled(Box)(({ theme }) => ({
  position: "relative",
  //height: 600
}));

export const BoxHero = styled(Box)(({ theme }) => ({
  position: "relative",
  height: "100%",
  width: "100%",
  background:
    "linear-gradient(to right, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, .9), rgba(255, 255, 255, .2))",
}));

export const BoxTitle = styled(Box)(({ theme }) => ({
  position: "relative",
  paddingTop: "34px",
  height: 170,
  display: "flex",
  justifyContent: "center",
}));

export const BoxArrow = styled(Box)(({ theme }) => ({
  position: "absolute",
  paddingTop: "36px",
  paddingLeft: "36px",
  height: 170,
  display: "flex",
  justifyContent: "flex-start",
  zIndex: 1000,
}));

export const BoxContent = styled(Box)(({ theme }) => ({
  position: "relative",
  display: "flex",
}));

export const BoxDescription = styled(Box)(({ theme }) => ({
  position: "relative",
  width: "50%",
  marginLeft: 24,
  marginRight: 24,
  display: "flex",
  flexDirection: "column",
}));

export const BoxPowerPack = styled(Box)(({ theme }) => ({
  position: "relative",
  width: "50%",
  marginLeft: 24,
}));

export const TypographyHero = styled(Typography)(({ theme }) => ({
  marginBottom: 16,
}));
