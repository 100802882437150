import { Button, Dialog, Fade, Typography, DialogActions, DialogContent } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";

import {
	CALENDAR_BLANK_BLACK,
	VIDEO_CAMERA_SIMPLE_ICON,
	GLOBE_HEMISPHERE_EAST_ICON,
	CLOCK_LIGHT_ICON,
	HOURGLASS_LIGHT_ICON,
	ENVELOPE_LIGHT_ICON,
	CALENDAR_BLANK_PURPLE,
} from "../../../constants";
import { convertUTCtoLocal, formatTimeRange } from "../../../containers/Meetings/Bookings/BookedMeetings";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Fade ref={ref} {...props} />;
});

export default function MeetingDetailsPopup({ open, onClose, bookingData, handleReschedule, handleCancel }) {
	const descriptions = [
		{ text: `Confirmed meeting with ${bookingData?.userData?.name}`, icon: HOURGLASS_LIGHT_ICON }, // userPayload?.name
		{ text: `${calculateDuration(bookingData?.startTime, bookingData?.endTime)} Mins`, icon: CLOCK_LIGHT_ICON },
		{ text: `${convertUTCtoLocal(bookingData?.startTime)} ${formatTimeRange(bookingData?.startTime, bookingData?.endTime)}`, icon: CALENDAR_BLANK_BLACK },
	];

	function calculateDuration(startTime, endTime) {
		const start = new Date(startTime);
		const end = new Date(endTime);
		const durationInMinutes = (end.getTime() - start.getTime()) / (1000 * 60);
		return durationInMinutes;
	}

	return (
		<Dialog
			maxWidth="lg"
			fullWidth
			open={open}
			onClose={onClose}
			TransitionComponent={Transition}
			transitionDuration={700}
			PaperProps={{ sx: { borderRadius: "24px" } }}
		>
			<Box sx={{ padding: "32px", pb: 3 }}>
				<Box display="flex" justifyContent="space-between" mb={1}>
					<Box component="img" src={CALENDAR_BLANK_PURPLE} alt="message-icon" />
					<CloseIcon onClick={onClose} sx={{ ml: "auto", cursor: "pointer" }} />
				</Box>
				<Typography variant="Text/xl/Semibold">Meeting Details</Typography>
			</Box>
			<DialogContent
				sx={{
					border: "none",
					py: 0,
					px: "32px",
				}}
			>
				<Box>
					<Box display="flex" rowGap={2} flexDirection="column">
						{descriptions.map((item) => (
							<Box display="flex" columnGap={2} alignItems="center">
								<Box component={"img"} src={item?.icon} sx={{ height: "30px", width: "30px" }} />
								<Typography variant="Text/sm/Regular">{item?.text}</Typography>
							</Box>
						))}
					</Box>
				</Box>
			</DialogContent>
			<DialogActions sx={{ pb: "32px", px: "32px" }}>
				<Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", gap: 1, flex: 1 }}>
					<Button
						sx={{ margin: "0", width: "auto" }}
						variant="RoundedTextMuted"
						onClick={() => {
							handleReschedule();
						}}
					>
						Reschedule
					</Button>

					<Button
						sx={{ margin: "0", width: "auto" }}
						variant="RoundedTextMuted"
						onClick={() => {
							handleCancel();
						}}
					>
						Cancel Meeting
					</Button>

					<Button
						sx={{ margin: "0", width: "auto" }}
						variant="containedPurpleStyle"
						onClick={() => {
							onClose();
						}}
					>
						Close
					</Button>
				</Box>
			</DialogActions>
		</Dialog>
	);
}
