import * as actionTypes from "./actionTypes";

export const setNotificationData = (payload) => {
	return (dispatch) => {
		dispatch({
			type: actionTypes.SET_NOTIFICATION_DATA,
			payload,
		});
	};
};

export const pushNotificationList = (payload) => {
	return (dispatch) => {
		dispatch({
			type: actionTypes.PUSH_NOTIFICATION_LIST,
			payload,
		});
	};
};
