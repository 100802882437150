import React from "react";
import { Box } from "@mui/material";
import VideoPlayerComponent from "../../common/videoPlayer";

import FileCardSectionComponent from "../../common/FileCardSection";

const UploadedPostFileDisplayComp = ({ item, type, onClick, elementStyle }) => {
	if (item?.attachments && item?.attachments.length > 0) {
		if (item?.attachments[0]?.url && item?.attachments[0]?.fileType === "image") {
			return (
				<Box sx={{ ...elementStyle }}>
					<Box
						onClick={onClick}
						component={"img"}
						src={item?.attachments[0]?.url}
						alt="postFile"
						sx={(theme) => ({
							width: 1,
							// height: type && type === "PostDetails" ? "" : "450px",
							objectFit: type && type === "PostDetails" ? "" : "cover",
							borderRadius: type && type === "PostDetails" ? "0px" : theme.shape.standard26.borderRadius,
						})}
					/>
				</Box>
			);
		} else if (item?.attachments[0]?.url && item?.attachments[0]?.fileType === "video") {
			return (
				<Box onClick={onClick} sx={{ ...elementStyle }}>
					<VideoPlayerComponent height={type && type === "PostDetails" ? "600px" : "450px"} width={"99.8%"} videoUrl={item?.attachments[0]?.url} />
				</Box>
			);
		} else if (item?.attachments[0]?.url && item?.attachments[0]?.fileType === "document") {
			return (
				<Box sx={{ ...elementStyle }}>
					<FileCardSectionComponent
						type={"post"}
						fileLink={item?.attachments[0]?.url}
						fileName={item?.attachments[0]?.name}
						fileSize={item?.attachments[0]?.size}
						removeFile={() => {}}
						handleOnClick={onClick}
					/>
				</Box>
			);
		} else {
			return <></>;
		}
	} else {
		return <></>;
	}
};

export default UploadedPostFileDisplayComp;
