import ROUTES_PATH from "../../../constants/routes";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	appContainer: (alertsExist, path) => ({
		display: "flex",
		flexDirection: "row",
		height:
			path === ROUTES_PATH.MESSAGES ? (alertsExist ? "calc(90% - 122px)" : "calc(90% - 72px)") : alertsExist ? "calc(90% - 60px)" : "calc(90% - 10px)",
		width: "100%",
		pointerEvents: alertsExist ? "none" : "auto",
		gap: "16px",
		overflow: "scroll",
	}),
	flex: {
		display: "flex",
	},
	appWrapper: {
		display: "flex",
		flexDirection: "column",
		height: "100%",
		width: "100%",
	},
	convosWrapper: {
		height: "100%",
		width: 320,
		position: "relative",
		background:
			"linear-gradient(0deg, rgba(106, 117, 117, 0.02), rgba(106, 117, 117, 0.02)), linear-gradient(0deg, rgba(245, 248, 250, 0.05), rgba(245, 248, 250, 0.05)), #FCFCFC",
		borderRadius: "10px",
	},
	messagesWrapper: {
		flex: 1,
		display: "contents",
	},
	appHeader: {
		display: "flex",
		justifyContent: "space-between",
		backgroundColor: "#06033A",
		padding: "20px 25px",
	},
	appLogoTitle: {
		fontSize: "16px",
		fontWeight: 500,
		textAlign: "center",
		color: "#FFFFFF",
		lineHeight: "32px",
		paddingLeft: "20px",
	},
	userTile: {
		display: "flex",
		lineHeight: "32px",
		color: "#fff",
	},
	paginationSpinner: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		padding: 16,
	},
	app: {
		flex: 1,
		height: "100%",
		width: "100%",
		position: "absolute",
	},
	buttonWrapper: {
		flexDirection: "row",
		// paddingRight: "16px",
	},
	convosWrapperBox: {
		height: "100%",
		display: "flex",
		flexDirection: "column",
	},
	convoList: {
		overflow: "scroll",
		position: "absolute",
		top: "65px",
		bottom: "60px",
		width: "100%",
	},
	collapseButtonBox: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-end",
		width: "100%",
		paddingTop: "12px",
		paddingBottom: "20px",
		paddingRight: "16px",
		paddingLeft: "16px",
		position: "absolute",
		bottom: 0,
		borderTop: "1px solid #CACDD8",
	},
	newConvoButton: {
		padding: "14px 16px",
		width: "100%",
		borderBottom: "1px solid #CACDD8",
	},
	loginContainer: {
		height: "100%",
		backgroundColor: "#001489",
	},
	logo: {
		width: "42px",
	},
	loginTitle: {
		paddingTop: "2px",
		color: "#FFFFFF",
		fontFamily: "Inter",
		fontSize: "20px",
		lineHeight: "28px",
		fontWeight: 500,
	},
	subTitle: {
		color: "#AEB2C1",
		fontFamily: "Inter",
		fontSize: "14px",
		lineHeight: "20px",
		paddingBottom: "40px",
	},
	loginContent: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		position: "absolute",
		width: "100%",
		height: "100%",
		zIndex: 3,
	},
	loginForm: {
		backgroundColor: "#FFFFFF",
		borderRadius: "8px",
	},
	userInput: {
		paddingTop: "24px",
		width: "320px",
		marginLeft: "24px",
		marginRight: "24px",
	},
	passwordInput: {
		paddingTop: "16px",
		width: "320px",
		marginLeft: "24px",
		marginRight: "24px",
	},
	loginButton: {
		fontFamily: "Inter",
		paddingTop: "32px",
		paddingBottom: "24px",
		marginLeft: "24px",
		marginRight: "24px",
	},
	loginBackground: {
		position: "absolute",
		alignSelf: "flex-end",
		top: "50%",
		height: "50%",
		width: "100%",
		backgroundColor: "#001489",
		overflow: "hidden",
	},
	modalInputLabel: {
		fontFamily: "Inter",
		fontSize: "14px",
		fontWeight: 700,
		lineHeight: "20px",
	},
	settingsWrapper: {
		zIndex: 1,
		paddingTop: "22px",
	},
	optionWrapper: {
		width: "232px",
	},
	collapsedList: {
		width: "70px",
	},
	messageInputAdornedEndSend: {
		position: "absolute",
		bottom: 0,
		right: 0,
	},
};
