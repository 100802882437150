import React from "react";
import { FormControl, Select, MenuItem, InputLabel, Box, TextField, InputAdornment, Typography } from "@mui/material";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { StyledSelect } from "../UpdatedSelectField/style";

export default function NewSingleSelect({
	label,
	onInputChange,
	selectedValue,
	fullWidth,
	options,
	inputKey,
	placeholder,
	labelStyle = {},
	borderNone = false,
	children,
	labelId = "select-label",
	id = "demo-simple-select",
	displayEmpty = false,
	compact = false,
	multiple = false,
	height,
	...props
}) {
	return (
		<Box
			sx={{
				minWidth: 120,
				display: "flex",
				flexDirection: "column",
				gap: 1,
			}}
		>
			{label && (
				<Typography variant="Display/sm/Semibold" mb={1}>
					{label}
				</Typography>
			)}
			<StyledSelect
				multiple={multiple}
				displayEmpty
				placeholder={placeholder}
				labelId={labelId}
				id={id}
				label={label}
				fullWidth={fullWidth}
				IconComponent={KeyboardArrowDownRoundedIcon}
				select
				onChange={(e) => onInputChange(e, inputKey)}
				value={selectedValue || []}
				{...props}
				sx={{
					width: compact ? "max-content" : 1,
					height: "60px",
					background: "white",
					"& .MuiSelect-select": {
						fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
						fontSize: "16px",
						lineHeight: "24px",
					},
				}}
			>
				{children
					? children
					: options.map((option) => (
							<MenuItem key={option} value={option}>
								{option}
							</MenuItem>
					  ))}
			</StyledSelect>
		</Box>
	);
}
