import React, { useEffect, useState } from "react";
import { Box, Button, Typography, CardContent, CardMedia, Grid, CircularProgress } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../../../../../constants/Theme";
import moment from "moment";
import { IN_PROGRESS_ICON, COMPLETED_DEFAULT } from "../../../../../constants/image";
import { cardTheme, PowerUpCardGrid } from "../../../../../components/Workspace/PowerUp/Card/styles";
import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import { POWERUP_VERSION_ICON } from "../../../../../constants";

export const PowerUpCard = ({ details, type, loading, handleArchive = () => {}, handleRedirectior = () => {}, handleDeleteModule = () => {} }) => {
	const VersionButtonContainer = ({ item }) => {
		return (
			<Box sx={{ display: "flex", p: 2 }}>
				<Box sx={{ display: "flex", alignItems: "center" }}>
					<ArchiveOutlinedIcon onClick={() => handleArchive(item?.id)} sx={{ cursor: "pointer" }} />
				</Box>
				<Box sx={{ ml: "auto" }}>
					<Button
						startIcon={<ArrowForwardIosOutlinedIcon />}
						variant="contained"
						sx={{ width: "max-content" }}
						onClick={() => {
							handleRedirectior(item?.isCompleted, item);
						}}
					>
						{item?.isCompleted ? "View" : "Resume"}
					</Button>
				</Box>
			</Box>
		);
	};

	const ArchiveButtonContainer = ({ item }) => {
		return (
			<Box sx={{ display: "flex", p: 2 }}>
				<Box sx={{ display: "flex", alignItems: "center" }}>
					<DeleteOutlineIcon sx={{ cursor: "pointer" }} onClick={() => handleDeleteModule(item?.id, "deleteOne")} />
				</Box>
				<Box sx={{ ml: "auto" }}>
					<Button
						startIcon={<ArchiveOutlinedIcon sx={{ maxHeight: "15px" }} />}
						variant="contained"
						sx={{ width: "max-content" }}
						onClick={() => handleArchive(item?.id, "Unarchive")}
					>
						Unarchive
					</Button>
				</Box>
			</Box>
		);
	};

	return (
		<>
			{loading ? (
				<Box sx={{ display: "flex", width: 1, justifyContent: "center", py: 4 }}>
					<CircularProgress />
				</Box>
			) : (
				<Grid container spacing={2} sx={{ p: 3 }}>
					{details?.map((item, index) => {
						return (
							<Grid item xs={12} sm={12} md={4} lg={4}>
								<ThemeProvider theme={cardTheme}>
									<PowerUpCardGrid color="primary" variant="outlined">
										<Box
											sx={{
												display: "flex",
												alignItems: "flex-start",
											}}
										>
											<Box
												sx={{
													alignItems: "center",
													justifyContent: "flex-start",
													p: 2,
													overflow: "hidden",
												}}
											>
												<CardMedia
													component="img"
													image={POWERUP_VERSION_ICON}
													alt="Startup Image"
													sx={{
														width: "50px",
														height: "50px",
													}}
												/>
												<Typography variant="h3" sx={{ pt: 2, minWidth: 0, textAlign: "left" }}>
													{item?.name ? item?.name : `Version ${index + 1}`}
												</Typography>
											</Box>
										</Box>

										<Box
											sx={{
												borderBottom: `1px solid ${theme.palette.secondary.light}`,
											}}
										></Box>

										<CardContent>
											<Box sx={{ display: "flex", pb: 2 }}>
												<Box>
													<Typography variant="subhead2">Modified Date</Typography>
												</Box>
												<Box sx={{ ml: "auto" }}>
													<Typography variant="subhead2">{moment(item?.lastModifiedAt).format("MMM D, YYYY")}</Typography>
												</Box>
											</Box>

											<Box sx={{ display: "flex", pb: 2 }}>
												<Box>
													<Typography variant="subhead2">Modified By ww</Typography>
												</Box>
												<Box sx={{ ml: "auto" }}>
													<Typography variant="subhead2">{item?.modifiedBy}</Typography>
												</Box>
											</Box>

											<Box sx={{ display: "flex" }}>
												<Box>
													<Typography variant="subhead2">Status</Typography>
												</Box>
												<Box sx={{ ml: "auto" }}>
													<Box
														sx={{
															display: "flex",
															flexDirection: "row",
															gap: 1,
															border: `1px solid ${theme.palette.secondary.dark}`,
															borderRadius: 2,
															width: "max-content",
															p: 1,
														}}
													>
														<Box src={item?.isCompleted ? COMPLETED_DEFAULT : IN_PROGRESS_ICON} component="img" sx={{ maxHeight: "20px" }} />
														{item?.isCompleted ? "Complete" : "In Progress"}
													</Box>
												</Box>
											</Box>
										</CardContent>
										<Box
											sx={{
												borderBottom: `1px solid ${theme.palette.secondary.light}`,
											}}
										></Box>

										{type === "version" && <VersionButtonContainer item={item} />}
										{type === "archive" && <ArchiveButtonContainer item={item} />}
									</PowerUpCardGrid>
								</ThemeProvider>
							</Grid>
						);
					})}
				</Grid>
			)}
		</>
	);
};
