import { Box, Typography } from "@mui/material";
import React, { useContext, useMemo } from "react";
import JsxParser from "react-jsx-parser";
import { ProfileContext } from "../../..";
import VideoPlayerComponent from "../../../../../common/videoPlayer";
import MissingAsset from "../../MissingAsset";

const BoxVideoElem = ({ children }) => (
	<Box
		sx={{
			flex: 1,
			maxHeight: "640px", // Figma value.
			maxWidth: "1000px",
			mb: "30px",
			"& video": {
				borderRadius: "30px",
				objectFit: "contain",
				maxHeight: "640px",
			},
		}}
	>
		{children}
	</Box>
);

function FounderAboutTabContent({ tab }) {
	const { profileData } = useContext(ProfileContext);

	const VideoComp = useMemo(() => {
		if (tab === "story") {
			if (profileData?.founderStoryVideo) {
				return (
					<BoxVideoElem>
						<VideoPlayerComponent videoUrl={profileData?.founderStoryVideo} height="100%" />
					</BoxVideoElem>
				);
			} else {
				return <MissingAsset sxProps={{ height: "450px", p: 2, mb: "30px" }} message={"Founder Story Video Coming Soon"} />;
			}
		} else if (tab === "about") {
			if (profileData?.aboutUsVideoUrl) {
				return (
					<BoxVideoElem>
						<VideoPlayerComponent videoUrl={profileData?.aboutUsVideoUrl} height="100%" />
					</BoxVideoElem>
				);
			} else {
				return <MissingAsset sxProps={{ height: "450px", p: 2, mb: "30px" }} message={"Elevator Pitch Video Coming Soon"} />;
			}
		}
	}, [tab]);

	return (
		<Box>
			{VideoComp}
			<Box
				sx={{
					mb: "20px",
				}}
			>
				<Typography
					variant="customValue"
					component={"div"}
					sx={{
						fontFamily: "PoppinsSemiBold",
						fontSize: "26px",
						lineHeight: "39px",
						color: "black",
					}}
				>
					{tab === "story" && `The ${profileData?.companyName} Story`}
					{tab === "about" && `${profileData?.companyName}`}
				</Typography>
			</Box>
			<Box>
				<Typography
					variant="customValue"
					component={"div"}
					sx={{
						fontFamily: "PoppinsMedium",
						fontSize: "16px",
						lineHeight: "24px",
						color: "black",
					}}
				>
					{tab === "story" && (
						<span
							dangerouslySetInnerHTML={{
								__html: profileData?.founderStory
									? profileData?.founderStory
									: `${profileData?.companyName} has not completed their founder story.`,
							}}
						/>
					)}
					{tab === "about" && (
						<span
							dangerouslySetInnerHTML={{
								__html: profileData?.about ? profileData?.about : `${profileData?.companyName} has not completed their about statement.`,
							}}
						/>
					)}
				</Typography>
			</Box>
		</Box>
	);
}

export default FounderAboutTabContent;
