import React, { Fragment, useEffect, useState } from "react";
import {
  Box,
  Typography,
  CircularProgress,
  useMediaQuery,
  Button,
  Icon,
  Divider,
} from "@mui/material";
import PowerUPLayout from "../../../../layout/PowerUP";
import Sidebar from "../../../../components/Workspace/PowerUp/Sidebar";
import BreadcrumbsComp from "./Components/BreadcrumbsComp";
import {
  getPowerUPData,
  getSidebarContent,
  getCandidatesList,
  getBrowseCandidateModule,
  CANDIDATES_LIST,
} from "./Functions/common";
import { BROWSE_CANDIDATE_ICON } from "../../../../constants/image";
import constants from "../../../../constants";
import Tabs from "../../../../components/Workspace/Tabs";
import CardWrapper from "./Components/CardWrapper";
import ManageCandidates from "./Components/ManageCandidates";
import theme from "../../../../constants/Theme";
import { useHistory, useParams } from "react-router-dom";
import services from "../../../../services";
import ROUTES_PATH from "../../../../constants/routes";
import { useSelector } from "react-redux";
import PersonSearchRoundedIcon from "@mui/icons-material/PersonSearchRounded";

const BrowseInterviewCandidates = () => {
  const [powerupDetails, setPowerupDetails] = useState("");
  const [powerupDetailsLoader, setPowerupDetailsLoader] = useState(false);
  const [SIDEBARDATA, setSidebarData] = useState({});
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("md"));
  const tabitems = constants.EXCERCISE_TAB_ITEMS;
  const [currenttab, setcurrenttab] = useState(tabitems[0].key);
  const history = useHistory();
  const [candidates, setCandidates] = useState([]);
  const [externalCandidates, setExternalCandidates] = useState([]);

  const [loading, setLoading] = useState(false);
  const [currentModuleObject, setCurrentModuleObject] = useState(null);
  const params = useParams();
  const moduleId = params.id;
  const powerUpId = "4";
  const versionId = parseInt(params.version);
  const [fetchData, setFetchData] = useState(false);
  const [excerciseSteps, setExcerciseSteps] = useState([]);
  const marketTest = useSelector(store => store.powerUp?.marketTest);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    setPowerupDetailsLoader(true);
    getPowerUPData(powerUpId).then(res => {
      setPowerupDetails(res);
    });
    getSidebarContent(powerUpId).then(res => {
      setSidebarData(res);
    });

    services
      .getModulesSteps("md-interview-test")
      .then(result => {
        if (result.data?.data) {
          const steps = result.data.data;
          steps.sort((a, b) => (a.stepNo > b.stepNo ? 1 : -1));
          setExcerciseSteps(steps);
        }
      })
      .catch(err => console.log(err));

    setPowerupDetailsLoader(false);
  }, []);

  useEffect(() => {
    setLoading(true);
    //static email address given by backend this 3 candidates will be fetched
    getCandidatesList(CANDIDATES_LIST.join(","))
      .then(result => {
        console.log(result?.startupUsers, "HODOR");

        const staticUsers = [
          {
            id: 246,
            avatar:
              "https://lh3.googleusercontent.com/a-/AOh14GhsLwlEDx2dyixaa3KiLqc9kLKtSPFs-xq4ZuSg=s96-c",
            name: "Jeff Wagner",
            title: "Head of Business Development",
            email: "jwagner@startupos.com",
            invited: false,
            selected: false,
          },
          {
            id: 246,
            avatar:
              "https://media-exp1.licdn.com/dms/image/C5603AQEaPda3Tughyw/profile-displayphoto-shrink_800_800/0/1642784953222?e=2147483647&v=beta&t=UZxkAo_uXWhS-eFf7CNlcXHi0GaOxkkuN57gKG52zTE",
            name: "Paul Pluschkell",
            title: "CEO",
            email: "paul@startupos.com",
            invited: false,
            selected: false,
          },
        ];
        setCandidates(staticUsers);

        // setCandidates(result?.startupUsers);
        // if (result?.externalUsers) {
        //   setExternalCandidates(result?.externalUsers);
        // }

        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });

    services
      .getModuleResponseDetailsByResponseId(moduleId, versionId)
      .then(res => {
        let newResponse = res?.data?.data;
        newResponse.moduleId = newResponse.module.id;
        setCurrentModuleObject(newResponse);
      })
      .catch(err => console.log(err));
  }, [fetchData]);

  const FooterComponent = () => {
    return (
      <Box display={"flex"} justifyContent={"center"}>
        <Button
          fullWidth
          sx={{ my: 2 }}
          onClick={() =>
            history.push(ROUTES_PATH.MARKET_TEST + "/" + params.id)
          }
        >
          Return to PowerUp Details
        </Button>
      </Box>
    );
  };

  const TabsComponent = () => {
    return (
      <Tabs
        tabitems={tabitems}
        currenttab={currenttab}
        setcurrenttab={setcurrenttab}
        color="primary"
        spacing={0}
        gridColumn={{
          lg: "none",
          md: "none",
          sm: "span 6",
          xs: "span 6",
        }}
        width={1}
      />
    );
  };

  const MainContent = () => {
    return (
      <Box
        sx={{
          [theme.breakpoints.up("md")]: {
            background: theme.palette.text.background,
          },
        }}
      >
        <Box sx={{ mt: 2 }}>
          {isSmallScreen && (
            <Typography
              variant="overlineLarge"
              color={"GrayText.secondary"}
              textTransform={"uppercase"}
            >
              {marketTest?.powerUpModel?.powerUpName}
            </Typography>
          )}
        </Box>
        {isSmallScreen ? (
          <Box display={"flex"} alignItems={"center"} sx={{ mt: 3 }} gap={3}>
            <Icon
              sx={{
                borderRadius: "50%",
                borderColor: "primary.main",
                borderStyle: "solid",
                p: 1,
              }}
            >
              <PersonSearchRoundedIcon sx={{ color: "primary.main" }} />
            </Icon>
            <Typography variant="headline5" color="primary">
              Browse Interview Candidates (Coming Soon)
            </Typography>
          </Box>
        ) : (
          <Box display={"flex"} alignItems={"center"} sx={{ mt: 3 }}>
            <img src={BROWSE_CANDIDATE_ICON} />
            <Typography variant="title_medium" sx={{ ml: 2 }}>
              Browse Interview Candidates (Coming Soon)
            </Typography>
          </Box>
        )}

        {/*{isSmallScreen && (
          <Box sx={{ mt: 2 }}>
            <TabsComponent />
          </Box>
        )}*/}
        <Box sx={{ my: 3 }}>
          <Typography variant="subhead2">
            We are currently building up our network to allow you to select and
            save candidates from a list of StartupOS members. In the meantime,
            we invite you to email one of our team members below, or to reach
            out to friends, family, and colleagues to conduct any external
            interviews.
          </Typography>
        </Box>

        {isSmallScreen && <Divider sx={{ my: 5 }} />}

        {loading ? (
          <Box justifyContent={"center"} sx={{ display: "flex", mt: 18 }}>
            <CircularProgress />
          </Box>
        ) : (
          <ManageCandidates
            candidates={candidates}
            externalCandidates={externalCandidates}
            currentModuleObject={currentModuleObject}
            fetchCallback={setFetchData}
            fetchData={fetchData}
            stepId={excerciseSteps[0]?.id}
          />
        )}
      </Box>
    );
  };

  const mainTitleAction = () => {
    history.push(ROUTES_PATH.MARKET_TEST + "/" + marketTest.id);
  };

  return (
    <PowerUPLayout
      main={
        <Fragment>
          {powerupDetailsLoader ? (
            <Box justifyContent={"center"} sx={{ display: "flex", mt: 5 }}>
              <CircularProgress />
            </Box>
          ) : (
            <Box
              sx={{
                [theme.breakpoints.up("md")]: {
                  borderRight: `1px solid ${theme.palette.secondary.main}`,
                  pr: 3,
                },
              }}
            >
              <CardWrapper>
                <MainContent />
              </CardWrapper>
            </Box>
          )}
        </Fragment>
      }
      sidebar={
        isSmallScreen ? (
          <Box display={"flex"} flexDirection={"column"}>
            <Box sx={{ my: 3 }}>
              <TabsComponent />
            </Box>
            <Sidebar sidebar_content={powerupDetails} />
          </Box>
        ) : (
          <Sidebar sidebar_content={powerupDetails} />
        )
      }
      breadcrumbs={
        <BreadcrumbsComp
          mainTitle="Idea Validation Interview"
          sectionTitle={"Browse Interview Candidates"}
          handleMainTitleAction={mainTitleAction}
        />
      }
      tab={currenttab}
      //footerComponent={<FooterComponent />}
    />
  );
};

export default BrowseInterviewCandidates;
