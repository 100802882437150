import React, { useEffect, useState } from "react";
import { Typography, Box, CircularProgress, Grid, Divider, useMediaQuery, Chip, Button } from "@mui/material";
import { sosPrimary } from "../../../constants/Theme/colors";
import TAMSAMSOMGraph from "../../../containers/Workspace/PowerupExcercises/PowerUps/TAMSAMSOM/Components/Graph";
import { getTAMSAMSOMValues } from "../../../containers/Workspace/PowerupExcercises/PowerUps/TAMSAMSOM/functions";
import SelfServiceSubscriptionsTable from "../../../containers/Workspace/PowerupExcercises/PowerUps/RevenueModler/RevenueModelerSummary/Components/SelfServiceSubscriptionsTable";
import { getBirthYear, getFormattedDate } from "../../../utils/date";
import numeral from "numeral";
import theme from "../../../constants/Theme";
import { NumberContainer } from "../../Workspace/PowerUp/SummaryStepList/styles";
import constants from "../../../../src/constants";
import PowerupExcerciseTitleBox from "../../Workspace/PowerUp/PowerupTitleBox";
import {
	AUDIO_CASSETTE_ICON,
	BICYCLE_ICON,
	CINEMA_ICON,
	FRISBEE_ICON,
	ICONIC_TOY_ICON,
	INTERNET_ICON,
	IPOD_ICON,
	MUSIC_DEVICE_ICON,
	RECORD_PLAYER_ICON,
	RUBIK_CUBE_ICON,
	SCREEN_CONTENT_ICON,
	SKATES_ICON,
	SMART_SPEAKER_ICON,
	SPINNER_ICON,
	STREAMING_ICON,
	TUNGSTEN_ICON,
	TV_ICON,
	VCR_ICON,
	WALKMAN_ICON,
} from "../../../constants/image";
import ProblemSolutionSummaryBox from "../../Workspace/PowerUp/ProblemSolutionSummaryBox";
import GoogleTrendsContainer from "../../../containers/Workspace/PowerUP/ProblemSolution/Components/GoogleTrends/Container";
import { categories } from "../../../containers/Workspace/PowerUP/ProblemSolution/functions/common";
import { TEMPLATE_TYPES, BRANDSTORM_SUMMARY_DEFAULTDATA } from "../../../constants/brandstorming-data";
import IconPicker from "../../../common/IconPicker";
import { SliderScale } from "../../../containers/Workspace/PowerupExcercises/PersonaBuilder/components/SliderScale";
import { CONTACT_ICONS_MAP } from "../../../constants/powerup-data";
import PersonaSummaryCard from "../../Workspace/PowerUp/PersonaSummaryCard";
import { PersonaDetailsCard } from "../../../containers/Workspace/styles";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

export default function SummaryContainer({ powerup, response, versionId, moduleId }) {
	const [calculatedTam, setCalculatedTam] = useState(0);
	const [calculatedSam, setCalculatedSam] = useState(0);
	const [calculatedSom, setCalculatedSom] = useState(0);
	const [currentModule, setCurrentModule] = useState(null);
	const [subscription, setSubscription] = useState({
		arpu: 0,
		primarilyBill: "yearly",
		expectedLaunchDate: new Date(),
		churnRate: 2,
		expectedLifetimeValue: 0,
		businessType: "b2b",
	});
	const [problemsData, setProblemsData] = useState([]);
	const [selectedTemplate, setSelectedTemplate] = useState(TEMPLATE_TYPES[0]);
	const [excerciseSteps, setExcerciseSteps] = useState([]);
	const [initialData, setInitialData] = useState(null);
	const [triggerSaveAction, setTriggerSaveAction] = useState(false);
	const [loading, setLoading] = useState(true);

	const isMediumScreen = useMediaQuery(theme.breakpoints.down("lg"));
	const gridContainer = {
		display: isMediumScreen ? "block" : "grid",
		gridTemplateColumns: "repeat(2, 1fr)",
	};

	const TextComponent = ({ label, value }) => {
		return (
			<Box display={"flex"} sx={{ mt: 2 }}>
				<Typography variant="label_xlarge">{label}</Typography>
				&nbsp;
				<Typography variant="subhead4" color={sosPrimary[500]}>
					{value}
				</Typography>
			</Box>
		);
	};
	const IconLabelComp = ({ labelObj, label }) => {
		return (
			<Box sx={{ textAlign: "center" }}>
				<Box component={"img"} src={labelObj.icon} alt="toy" />
				<Typography
					sx={{
						display: "grid",
						color: (theme) => theme.palette.secondary.korophKoal,
					}}
					variant="label_medium"
				>
					{labelObj.name}
				</Typography>
			</Box>
		);
	};

	const getGenerationDetails = (birthYear) => {
		if (birthYear >= 1925 && birthYear <= 1945) {
			return {
				genaratioName: "Builders",
				dateRange: "1925 - 1945",
				iconNames: [
					{ icon: SKATES_ICON, name: "Roller Skates" },
					{ icon: RECORD_PLAYER_ICON, name: "Record Player" },
					{ icon: CINEMA_ICON, name: "Cinema" },
				],
			};
		} else if (birthYear >= 1946 && birthYear <= 1964) {
			return {
				genaratioName: "Baby Boomers",
				dateRange: "1946 - 1964",
				iconNames: [
					{ icon: FRISBEE_ICON, name: "Frisbee" },
					{ icon: AUDIO_CASSETTE_ICON, name: "Audio Cassette" },
					{ icon: TV_ICON, name: "TV" },
				],
			};
		} else if (birthYear >= 1965 && birthYear <= 1979) {
			return {
				genaratioName: "Generation X",
				dateRange: "1965 - 1979",
				iconNames: [
					{ icon: RUBIK_CUBE_ICON, name: "Rubik’s Cube" },
					{ icon: WALKMAN_ICON, name: "Walkman" },
					{ icon: VCR_ICON, name: "VCR" },
				],
			};
		} else if (birthYear >= 1980 && birthYear <= 1994) {
			return {
				genaratioName: "Generation Y",
				dateRange: "1980 - 1994",
				iconNames: [
					{ icon: BICYCLE_ICON, name: "BMX Bike" },
					{ icon: IPOD_ICON, name: "iPod" },
					{ icon: INTERNET_ICON, name: "Internet" },
				],
			};
		} else if (birthYear >= 1995 && birthYear <= 2009) {
			return {
				genaratioName: "Generation Z",
				dateRange: "1995 - 2009",
				iconNames: [
					{ icon: ICONIC_TOY_ICON, name: "Iconic Toy" },
					{ icon: MUSIC_DEVICE_ICON, name: "Music Device" },
					{ icon: SCREEN_CONTENT_ICON, name: "Screen Content" },
				],
			};
		} else if (birthYear >= 2010 && birthYear <= 2024) {
			return {
				genaratioName: "Generation Infinity",
				dateRange: "2010 - 2024",
				iconNames: [
					{ icon: SPINNER_ICON, name: "Fidget Spinner" },
					{ icon: SMART_SPEAKER_ICON, name: "Smart Speakers" },
					{ icon: STREAMING_ICON, name: "Streaming" },
				],
			};
		}
	};
	const ChipListComp = ({ title, chipList }) => {
		return (
			<Box>
				<Typography sx={{ color: sosPrimary[500] }} variant="headline6">
					{title}
				</Typography>
				<Box sx={{ mt: 2, display: "flex", flexWrap: "wrap" }} gap="8px">
					{chipList?.map((behaviorItem, idx) => {
						return (
							<Chip
								label={<Typography variant="body_medium">{behaviorItem}</Typography>}
								key={idx}
								sx={{
									background: "linear-gradient(0deg, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95)), #0A141B",
									borderRadius: theme.shape.standard_small.borderRadius,
								}}
							/>
						);
					})}
				</Box>
			</Box>
		);
	};

	const getFromattedIcons = (arrayType) => {
		const communicationMethodsArray = currentModule && currentModule.filter((exerciseItem) => exerciseItem.stepNo === 4)[0]?.answers?.communicationMethods;
		let newContactArray = [];
		if (arrayType === "contactIcons") {
			CONTACT_ICONS_MAP.map((item) => {
				if (communicationMethodsArray?.includes(item?.url)) {
					item.url = item?.url;
					newContactArray.push(item);
				}
			});

			return newContactArray;
		} else {
			return communicationMethodsArray;
		}
	};
	useEffect(() => {
		if (response?.length > 0) {
			if (powerup === "Pricing Modeler") {
				const lastStep = response.find((step) => step.moduleStep.stepNo === 2);

				setCurrentModule([JSON.parse(lastStep.answers)]);
				const currentAnswer = JSON.parse(lastStep.answers).summaryObj;
				setSubscription({
					...subscription,
					arpu: currentAnswer.subscriptionPrice,
					primarilyBill: currentAnswer.billingCadence,
					expectedLaunchDate: currentAnswer.launchDate || new Date(),
					churnRate: currentAnswer.businessType?.toLowerCase() === "b2b" ? 2 : 7,
					businessType: currentAnswer.businessType?.toLowerCase(),
				});
			} else if (
				powerup === "Founder Story" ||
				powerup === "TAM/SAM/SOM" ||
				powerup === "Value Proposition" ||
				powerup === "Problem Solution Fit" ||
				powerup === "Persona Builder" ||
				powerup === "Brandstorming"
			) {
				const excerciseDetails = response.sort((a, b) => (a.id > b.id ? 1 : -1));

				if (excerciseDetails || excerciseDetails.length > 0) {
					const mappedExcerciseDetails = excerciseDetails.map((excercise) => ({
						moduleResponseId: versionId,
						moduleStepId: excercise.moduleStep.id,
						answers: JSON.parse(excercise.answers),
						stepNo: excercise.moduleStep.stepNo,
						isCompleted: true,
					}));
					setCurrentModule(mappedExcerciseDetails);
					setExcerciseSteps(response?.map((data) => data.moduleStep));
				}
			}
		}
	}, [response]);

	useEffect(() => {
		if (currentModule?.length > 0) {
			if (powerup === "TAM/SAM/SOM") {
				const powerupValues = getTAMSAMSOMValues(currentModule);
				if (powerupValues.tam) {
					setCalculatedTam(powerupValues.tam);
				}
				if (powerupValues.sam) {
					setCalculatedSam(powerupValues.sam);
				}
				if (powerupValues.som) {
					setCalculatedSom(powerupValues.som);
				}
			} else if (powerup === "Problem Solution Fit") {
				let storeItems = [...currentModule];

				storeItems[1].answers?.problems.map((item, index) => {
					item.title = `Problem ${index + 1}`;
					item.solutionList = Object.values(storeItems[2]?.answers)[index];
				});

				storeItems[0].answers.categoryId = categories.filter((cat) => cat.name === storeItems[0]?.answers?.category)[0].id;
				setProblemsData(storeItems[1]?.answers?.problems);
			} else if (powerup === "Brandstorming") {
				const unlockDeck = currentModule.find((excercise) => excercise.stepNo === 3);

				if (unlockDeck && unlockDeck.answers) {
					const templateId = unlockDeck.answers.template;
					const currentTemplate = TEMPLATE_TYPES.find((type) => type.id === templateId);
					if (currentTemplate) setSelectedTemplate(currentTemplate);
				}
			} else if (powerup === "Persona Builder") {
				if (currentModule.length > 0) {
					var initialData = {};
					currentModule.map((exercise) => {
						initialData = { ...initialData, ...exercise.answers };
					});
					setInitialData(initialData);
				}
			}
			setLoading(false);
		}
	}, [currentModule]);

	useEffect(() => {
		let expectedLifetimeValue = 0;
		let formattedArpuValue = parseInt(numeral(subscription.arpu).value());

		if (formattedArpuValue > 0) {
			let avgCustomerLifetimeMonths = 1 / (subscription.churnRate / 100);
			if (subscription.primarilyBill === "Monthly") {
				let lifetimeNumBillings = avgCustomerLifetimeMonths / 1 + 1;
				expectedLifetimeValue = formattedArpuValue * 1 * Math.floor(lifetimeNumBillings);
			} else if (subscription.primarilyBill === "Quarterly") {
				let lifetimeNumBillings = avgCustomerLifetimeMonths / 3 + 1;
				expectedLifetimeValue = formattedArpuValue * 3 * Math.floor(lifetimeNumBillings);
			} else if (subscription.primarilyBill === "Yearly") {
				let lifetimeNumBillings = avgCustomerLifetimeMonths / 12 + 1;
				expectedLifetimeValue = formattedArpuValue * 12 * Math.floor(lifetimeNumBillings);
			}
		}

		setSubscription({
			...subscription,
			expectedLifetimeValue: expectedLifetimeValue,
		});
	}, [subscription.arpu, subscription.primarilyBill]);

	const getNotesChurnRate = () => {
		if (subscription.businessType === "b2b") {
			return "Most B2B companies aim for 0-2% monthly churn. We’ve assigned you the average, 2%.";
		} else if (subscription.businessType === "b2c") {
			return "Most B2C companies aim for 6-8% monthly churn. We’ve assigned you the average, 7%.";
		}
	};

	const getExerciseTitle = (stepNumber) => {
		let storyTellercontents = constants.STORYTELLER_CONTENTS;

		if (stepNumber === 1) {
			return storyTellercontents[0].title;
		} else if (stepNumber === 2) {
			return storyTellercontents[1].title;
		} else {
			return storyTellercontents[2].title;
		}
	};

	const renderBrandStoryDetails = () => {
		if (currentModule) {
			//Getting brandstory step responses
			let brandStory = currentModule.find((excercise) => excercise.stepNo === 2 && excercise.moduleStepId === 22);
			//use default data i no brandstory found use this until previous step integration isnt completed
			if (!brandStory) {
				brandStory = { answers: BRANDSTORM_SUMMARY_DEFAULTDATA };
			}

			if (brandStory && brandStory.answers) {
				return (
					<>
						{Object.keys(brandStory.answers).map((key) => {
							return (
								<Box sx={{ mt: 3 }}>
									{" "}
									<Box sx={{ display: "flex", alignItems: "center" }}>
										<Typography variant="title_medium">{key === "brandMission" ? "Brand Mission" : "Brand Values"}</Typography>
									</Box>
									<Box sx={{ mt: 2 }}>
										<Typography variant="bodyLarge">{brandStory.answers[key]}</Typography>
									</Box>
								</Box>
							);
						})}
					</>
				);
			}
		}
	};

	const tableData = [
		{
			businessModel: "Average Monthly Subscription Price (ARPU)",
			output: "$" + subscription.arpu,
			notes: "The average revenue per user, per month. If you have multiple plans, provide an average of the plan price.",
		},

		{
			businessModel: "How you will primarily bill your customers",
			output: subscription.primarilyBill.toUpperCase(),
			notes: "",
		},
		{
			businessModel: "Expected Launch",
			output: getFormattedDate(subscription.expectedLaunchDate, "MM/DD/YYYY"),
			notes: "",
		},
		{
			businessModel: "Expected Yearly Churn Rate",
			output: subscription.churnRate + "%",
			notes: getNotesChurnRate(),
		},
		{
			businessModel: "Expected Lifetime Value",
			output: "$" + subscription.expectedLifetimeValue,
			notes: "ARPU divided by (Expected Yearly Churn Rate divided by 12)",
		},
	];
	return (
		<Box
			sx={{
				width: 1,
			}}
		>
			{currentModule?.length > 0 && !loading ? (
				powerup === "Problem Solution Fit" ? (
					<Box>
						<Box>
							<PowerupExcerciseTitleBox
								iconUrl={TUNGSTEN_ICON}
								title={currentModule[0]?.answers?.idea}
								subTitle={"Your Idea"}
								iconBackgroundColor={sosPrimary[500]}
								contentTextColor={sosPrimary[500]}
								isGradient={true}
							/>
						</Box>

						<Divider
							sx={{
								background: "rgba(124, 117, 127, 0.16)",
								mt: 1,
							}}
						/>
						<Box sx={{ mt: 2 }}>
							<Grid container>
								<Grid item sm={3} xs={3} md={2} lg={2}>
									<Typography variant="label_medium" color={theme.palette.secondary.black}>
										Type
									</Typography>
								</Grid>
								<Grid item sm={9} xs={9} md={10} lg={10}>
									<Typography
										sx={(theme) => ({
											border: `1px solid ${sosPrimary[500]}`,
											borderRadius: theme.shape.standard25.borderRadius,
											p: "2px 8px",
										})}
										variant="label_medium"
										color={sosPrimary[500]}
									>
										{currentModule[0]?.answers?.type}
									</Typography>
								</Grid>
								<Grid item sm={3} xs={3} md={2} lg={2} sx={{ mt: 2 }}>
									<Typography variant="label_medium" color={theme.palette.secondary.black}>
										Category
									</Typography>
								</Grid>
								<Grid item sm={9} xs={9} md={10} lg={10} sx={{ mt: 2 }}>
									<Typography
										sx={(theme) => ({
											border: `1px solid ${sosPrimary[500]}`,
											borderRadius: theme.shape.standard25.borderRadius,
											p: "2px 8px",
										})}
										variant="label_medium"
										color={sosPrimary[500]}
									>
										{currentModule[0]?.answers?.category}
									</Typography>
								</Grid>
							</Grid>
						</Box>
						<Divider
							sx={{
								background: "rgba(124, 117, 127, 0.16)",
								mt: 3,
							}}
						/>
						<Box sx={{ mt: 3 }}>
							<Typography variant="label_large" color={sosPrimary[500]}>
								Your Customer
							</Typography>
							<Box sx={{ mt: 1 }}>
								<Typography variant="bodyMedium">{currentModule[0]?.answers?.customer.join(", ")}</Typography>
							</Box>
						</Box>
						<Divider
							sx={{
								background: "rgba(124, 117, 127, 0.16)",
								mt: 3,
							}}
						/>
						<Box sx={{ mt: 3 }}>
							<Typography variant="label_large" color={sosPrimary[500]}>
								Problem/Solution
							</Typography>
							<Box>
								{problemsData?.map((item) => {
									return (
										<Box sx={{ mt: 3 }}>
											<ProblemSolutionSummaryBox problemSolutionObj={item} />
											<Divider
												sx={{
													background: theme.palette.text.primary,
													mt: 3,
													display: {
														xs: "block",
														sm: "block",
														md: "none",
														lg: "none",
													},
												}}
											/>
										</Box>
									);
								})}
							</Box>
						</Box>

						<Box sx={{ pt: 2 }}>
							<Typography variant="onsurface_subtext_primary">Insight Results</Typography>
							<Box sx={{ mt: 3, px: { xs: 1, md: 0 } }}>
								<GoogleTrendsContainer
									category={currentModule[0]?.answers?.categoryId}
									keywords={currentModule[0]?.answers?.topicTags}
									id={"summary"}
								/>
							</Box>
						</Box>
					</Box>
				) : powerup === "Value Proposition" ? (
					<>
						<Box sx={{ pl: 4 }}>
							<TextComponent label={"Our"} value={currentModule[4]?.answers[0]?.value} />
							<TextComponent label={"help(s)"} value={currentModule[4]?.answers[1]?.value} />
							<TextComponent label={"who want to"} value={currentModule[4]?.answers[2]?.value} />
							<TextComponent label={"by"} value={currentModule[4]?.answers[3]?.value} />
							<TextComponent label={"and"} value={currentModule[4]?.answers[4]?.value} />
						</Box>
					</>
				) : powerup === "Persona Builder" ? (
					<Box sx={{ width: "100%", height: 1 }}>
						<Box>
							{" "}
							<Box sx={{ mt: 4 }}>
								<PersonaSummaryCard
									iconUrl={currentModule && currentModule?.filter((exerciseItem) => exerciseItem.stepNo === 1)[0]?.answers?.avatar}
									personaName={currentModule && currentModule?.filter((exerciseItem) => exerciseItem.stepNo === 1)[0]?.answers?.personaName}
									description={
										currentModule && currentModule.filter((exerciseItem) => exerciseItem.stepNo === 2)[0]?.answers?.summarize
											? `"${currentModule && currentModule.filter((exerciseItem) => exerciseItem.stepNo === 2)[0]?.answers?.summarize}"`
											: ""
									}
								/>
							</Box>
							<Box alignItems="center" sx={{ mt: 4, display: isMediumScreen ? "block" : "flex" }}>
								<Box>
									<Typography variant="headline4" sx={{ color: sosPrimary[500] }}>
										{
											getGenerationDetails(
												getBirthYear(
													currentModule && currentModule.filter((exerciseItem) => exerciseItem.stepNo === 2)[0]?.answers?.age
												)
											)?.genaratioName
										}
									</Typography>
									<Typography
										variant="bodyLarge"
										sx={{
											mt: 1,
											display: "grid",
											color: (theme) => theme.palette.secondary.outline,
										}}
									>
										{
											getGenerationDetails(
												getBirthYear(
													currentModule && currentModule.filter((exerciseItem) => exerciseItem.stepNo === 2)[0]?.answers?.age
												)
											)?.dateRange
										}
									</Typography>
								</Box>
								<Box
									sx={{
										ml: "auto",
										display: "flex",
										alignItems: "baseline",
										[theme.breakpoints.down("lg")]: {
											mt: 3,
										},
									}}
									gap={"24px"}
								>
									{getGenerationDetails(
										getBirthYear(currentModule && currentModule.filter((exerciseItem) => exerciseItem.stepNo === 2)[0]?.answers?.age)
									)?.iconNames.map((item, idx) => {
										return (
											<Box key={idx}>
												<IconLabelComp labelObj={item} label={item.label} />
											</Box>
										);
									})}
								</Box>
							</Box>
							<Box sx={{ width: 1, mt: 3 }}>
								<Box sx={gridContainer} gap="24px">
									<PersonaDetailsCard>
										<Typography sx={{ color: sosPrimary[500] }} variant="headline6">
											About Me
										</Typography>

										<Grid container sx={{ mt: 3 }}>
											<Grid md={5} lg={5} sm={4} xs={4}>
												<Typography
													variant="label_medium"
													sx={{
														color: (theme) => theme.palette.secondary.korophKoal,
													}}
												>
													Gender:
												</Typography>
											</Grid>
											<Grid md={7} lg={7} sm={8} xs={8}>
												<Typography
													variant="body_medium"
													sx={{
														color: (theme) => theme.palette.secondary.korophKoal,
													}}
												>
													{currentModule && currentModule.filter((exerciseItem) => exerciseItem.stepNo === 2)[0]?.answers?.gender}
												</Typography>
											</Grid>
											<Grid md={5} lg={5} sm={4} xs={4} sx={{ mt: 2 }}>
												<Typography
													variant="label_medium"
													sx={{
														color: (theme) => theme.palette.secondary.korophKoal,
													}}
												>
													Marital Status:
												</Typography>
											</Grid>
											<Grid md={7} lg={7} sm={8} xs={8} sx={{ mt: 2 }}>
												<Typography
													variant="body_medium"
													sx={{
														color: (theme) => theme.palette.secondary.korophKoal,
														wordWrap: "break-word",
													}}
												>
													{currentModule &&
														currentModule.filter((exerciseItem) => exerciseItem.stepNo === 2)[0]?.answers?.maritalStatus}
												</Typography>
											</Grid>
											<Grid md={5} lg={5} sm={4} xs={4} sx={{ mt: 2 }}>
												<Typography
													variant="label_medium"
													sx={{
														color: (theme) => theme.palette.secondary.korophKoal,
													}}
												>
													Age:
												</Typography>
											</Grid>
											<Grid md={7} lg={7} sm={8} xs={8} sx={{ mt: 2 }}>
												<Typography
													variant="body_medium"
													sx={{
														color: (theme) => theme.palette.secondary.korophKoal,
													}}
												>
													{" "}
													{currentModule && currentModule.filter((exerciseItem) => exerciseItem.stepNo === 2)[0]?.answers?.age}
												</Typography>
											</Grid>

											<Grid
												md={5}
												lg={5}
												sm={4}
												xs={4}
												sx={{
													mt: 2,
												}}
											>
												<Typography
													variant="label_medium"
													sx={{
														color: (theme) => theme.palette.secondary.korophKoal,
													}}
												>
													Education:
												</Typography>
											</Grid>
											<Grid md={7} lg={7} sm={8} xs={8} sx={{ mt: 2 }}>
												<Typography
													variant="body_medium"
													sx={{
														color: (theme) => theme.palette.secondary.korophKoal,
														wordWrap: "break-word",
													}}
												>
													{currentModule &&
														currentModule.filter((exerciseItem) => exerciseItem.stepNo === 2)[0]?.answers?.educationLevel}
												</Typography>
											</Grid>
											<Grid
												md={5}
												lg={5}
												sm={4}
												xs={4}
												sx={{
													mt: 2,
												}}
											>
												<Typography
													variant="label_medium"
													sx={{
														color: (theme) => theme.palette.secondary.korophKoal,
														[theme.breakpoints.down("md")]: {
															wordWrap: "break-word",
														},
													}}
												>
													State/Province:
												</Typography>
											</Grid>
											<Grid md={7} lg={7} sm={8} xs={8} sx={{ mt: 2 }}>
												<Typography
													variant="body_medium"
													sx={{
														color: (theme) => theme.palette.secondary.korophKoal,

														wordWrap: "break-word",
													}}
												>
													{currentModule && currentModule.filter((exerciseItem) => exerciseItem.stepNo === 2)[0]?.answers?.state}
												</Typography>
											</Grid>
											<Grid
												md={5}
												lg={5}
												sm={4}
												xs={4}
												sx={{
													mt: 2,
												}}
											>
												<Typography
													variant="label_medium"
													sx={{
														color: (theme) => theme.palette.secondary.korophKoal,
													}}
												>
													City:
												</Typography>
											</Grid>
											<Grid md={7} lg={7} sm={8} xs={8} sx={{ mt: 2 }}>
												<Typography
													variant="body_medium"
													sx={{
														color: (theme) => theme.palette.secondary.korophKoal,
														wordWrap: "break-word",
													}}
												>
													{currentModule && currentModule.filter((exerciseItem) => exerciseItem.stepNo === 2)[0]?.answers?.city}
												</Typography>
											</Grid>
											<Grid
												md={5}
												lg={5}
												sm={4}
												xs={4}
												sx={{
													mt: 2,
												}}
											>
												<Typography
													variant="label_medium"
													sx={{
														color: (theme) => theme.palette.secondary.korophKoal,
													}}
												>
													Industry:
												</Typography>
											</Grid>
											<Grid md={7} lg={7} sm={8} xs={8} sx={{ mt: 2 }}>
												<Typography
													variant="body_medium"
													sx={{
														color: (theme) => theme.palette.secondary.korophKoal,
														wordWrap: "break-word",
													}}
												>
													{currentModule && currentModule.filter((exerciseItem) => exerciseItem.stepNo === 2)[0]?.answers?.industry}
												</Typography>
											</Grid>
											<Grid md={5} lg={5} sm={4} xs={4} sx={{ mt: 2 }}>
												<Typography
													variant="label_medium"
													sx={{
														color: (theme) => theme.palette.secondary.korophKoal,
														wordWrap: "break-word",
													}}
												>
													Occupation:
												</Typography>
											</Grid>
											<Grid md={7} lg={7} sm={8} xs={8} sx={{ mt: 2 }}>
												<Typography
													variant="body_medium"
													sx={{
														color: (theme) => theme.palette.secondary.korophKoal,
													}}
												>
													{currentModule && currentModule.filter((exerciseItem) => exerciseItem.stepNo === 2)[0]?.answers?.jobTitle}
												</Typography>
											</Grid>
										</Grid>
									</PersonaDetailsCard>

									<PersonaDetailsCard mt={isMediumScreen ? 4 : 0}>
										<Typography sx={{ color: sosPrimary[500] }} variant="headline6">
											Bio
										</Typography>

										<Typography
											variant="body_medium"
											display={"grid"}
											sx={{
												mt: 3,
												color: (theme) => theme.palette.secondary.outline,
											}}
										>
											{currentModule && currentModule.filter((exerciseItem) => exerciseItem.stepNo === 3)[0]?.answers?.bio}
										</Typography>
									</PersonaDetailsCard>
								</Box>
							</Box>
							<Box sx={{ mt: 4 }}>
								<ChipListComp
									title={"Behavior"}
									chipList={currentModule && currentModule.filter((exerciseItem) => exerciseItem.stepNo === 3)[0]?.answers?.behavior}
								/>
							</Box>
							<Box sx={{ mt: 4 }}>
								<ChipListComp
									title={"Goals"}
									chipList={currentModule && currentModule.filter((exerciseItem) => exerciseItem.stepNo === 3)[0]?.answers?.goals}
								/>
							</Box>
							<Box sx={{ mt: 4 }}>
								<ChipListComp
									title={"Excitement and Inspirations"}
									chipList={currentModule && currentModule.filter((exerciseItem) => exerciseItem.stepNo === 3)[0]?.answers?.inspiration}
								/>
							</Box>
							<Box sx={{ mt: 4 }}>
								<ChipListComp
									title={"Challenges"}
									chipList={currentModule && currentModule.filter((exerciseItem) => exerciseItem.stepNo === 3)[0]?.answers?.challenges}
								/>
							</Box>
							<Box sx={{ mt: 4 }}>
								<ChipListComp
									title={"Frustrations"}
									chipList={currentModule && currentModule.filter((exerciseItem) => exerciseItem.stepNo === 3)[0]?.answers?.frustration}
								/>
							</Box>
							<Grid container sx={{ mt: 4 }}>
								<Grid item md={6} lg={6} sm={12} xs={12}>
									<Typography sx={{ color: sosPrimary[500] }} variant="headline6">
										Motivations
									</Typography>

									<SliderScale
										value={
											currentModule && currentModule.filter((exerciseItem) => exerciseItem.stepNo === 3)[0]?.answers?.motivations?.price
										}
										key="price"
										title_left="Price"
										disabled={true}
									/>
									<Box sx={{ mt: 2 }}>
										<SliderScale
											value={
												currentModule &&
												currentModule.filter((exerciseItem) => exerciseItem.stepNo === 3)[0]?.answers?.motivations?.comfort
											}
											key="comfort"
											title_left="Comfort"
											disabled={true}
										/>
									</Box>

									<Box sx={{ mt: 2 }}>
										{" "}
										<SliderScale
											value={
												currentModule &&
												currentModule.filter((exerciseItem) => exerciseItem.stepNo === 3)[0]?.answers?.motivations?.convenience
											}
											key="comfort"
											title_left="Convenience"
											disabled={true}
										/>
									</Box>

									<Box sx={{ mt: 2 }}>
										{" "}
										<SliderScale
											value={
												currentModule &&
												currentModule.filter((exerciseItem) => exerciseItem.stepNo === 3)[0]?.answers?.motivations?.speed
											}
											key="comfort"
											title_left="Speed"
											disabled={true}
										/>
									</Box>

									<Box sx={{ mt: 2 }}>
										{" "}
										<SliderScale
											value={
												currentModule &&
												currentModule.filter((exerciseItem) => exerciseItem.stepNo === 3)[0]?.answers?.motivations?.loyalty
											}
											key="comfort"
											title_left="Loyalty"
											disabled={true}
										/>
									</Box>
								</Grid>
								<Grid item md={6} lg={6} sm={12} xs={12} sx={{ mt: { xs: 3, md: 0 }, pl: { md: 3, xs: 0 } }}>
									<Typography sx={{ color: sosPrimary[500] }} variant="headline6">
										Traits
									</Typography>
									<SliderScale
										value={
											currentModule && currentModule.filter((exerciseItem) => exerciseItem.stepNo === 3)[0]?.answers?.traits?.introvert
										}
										key="comfort"
										title_left="Introvert"
										title_right={"Extrovert"}
										disabled={true}
									/>
									<Box sx={{ mt: 2 }}>
										<SliderScale
											value={
												currentModule &&
												currentModule.filter((exerciseItem) => exerciseItem.stepNo === 3)[0]?.answers?.traits?.analytical
											}
											key="comfort"
											title_left="Analytical"
											title_right={"Creative"}
											disabled={true}
										/>
									</Box>

									<Box sx={{ mt: 2 }}>
										{" "}
										<SliderScale
											value={
												currentModule && currentModule.filter((exerciseItem) => exerciseItem.stepNo === 3)[0]?.answers?.traits?.loyal
											}
											key="comfort"
											title_left="Loyal"
											title_right={"Fickle"}
											disabled={true}
										/>
									</Box>

									<Box sx={{ mt: 2 }}>
										{" "}
										<SliderScale
											value={
												currentModule && currentModule.filter((exerciseItem) => exerciseItem.stepNo === 3)[0]?.answers?.traits?.passive
											}
											key="comfort"
											title_left="Passive"
											title_right={"Active"}
											disabled={true}
										/>
									</Box>
								</Grid>
								<Grid item md={6} lg={6} sm={12} xs={12} sx={{ mt: 4 }}>
									<Box>
										<Typography sx={{ color: sosPrimary[500] }} variant="headline6">
											Tech Saviness
										</Typography>

										<SliderScale
											value={
												currentModule &&
												currentModule.filter((exerciseItem) => exerciseItem.stepNo === 3)[0]?.answers?.techSaviness?.newbie
											}
											key="comfort"
											title_left="Newbie"
											title_right={"Guru"}
											disabled={true}
										/>
									</Box>
								</Grid>
								<Grid item md={12} lg={12} sm={12} xs={12} sx={{ mt: 4 }}>
									<ChipListComp
										title={"Brands Interest"}
										chipList={currentModule && currentModule.filter((exerciseItem) => exerciseItem.stepNo === 3)[0]?.answers?.brands}
									/>
								</Grid>

								<Grid item md={12} lg={12} sx={{ mt: 4 }}>
									<ChipListComp
										title={"Job Responsibilities "}
										chipList={
											currentModule && currentModule.filter((exerciseItem) => exerciseItem.stepNo === 4)[0]?.answers?.jobResponsibilities
										}
									/>
								</Grid>
								<Grid item md={12} lg={12} sx={{ mt: 4 }}>
									<ChipListComp
										title={"Tools Needed For JTBD  "}
										chipList={currentModule && currentModule.filter((exerciseItem) => exerciseItem.stepNo === 4)[0]?.answers?.tools}
									/>
								</Grid>
								<Grid item md={12} lg={12} sm={12} xs={12} sx={{ mt: 4 }}>
									<ChipListComp
										title={"What They Do To Add Skills To Their Job"}
										chipList={currentModule && currentModule.filter((exerciseItem) => exerciseItem.stepNo === 4)[0]?.answers?.jobActivity}
									/>
								</Grid>

								<Box sx={{ mt: 4 }}>
									<Typography sx={{ color: sosPrimary[500] }} variant="headline6">
										Preferred Communication
									</Typography>
									<Box>
										{" "}
										<IconPicker
											icons={getFromattedIcons("contactIcons")}
											selectedIcon={getFromattedIcons("selectedIcon")}
											setSelectedIcon={() => {}}
											iconHeight={"18px"}
											iconWidth={"18px"}
											mt={2}
											multiSelect
											isCustomIcon
										></IconPicker>
									</Box>
								</Box>
							</Grid>
						</Box>
					</Box>
				) : powerup === "TAM/SAM/SOM" ? (
					<Grid container flexDirection={"row"}>
						<Grid item xs={12} md={12}>
							<TAMSAMSOMGraph tam={calculatedTam} sam={calculatedSam} som={calculatedSom} />
						</Grid>
					</Grid>
				) : powerup === "Pricing Modeler" ? (
					<Box sx={{ maxWidth: "100%" }} flexGrow={1}>
						<SelfServiceSubscriptionsTable subscription={subscription} setSubscription={setSubscription} getNotesChurnRate={getNotesChurnRate} />
					</Box>
				) : powerup === "Founder Story" ? (
					<Box>
						{currentModule.map((item, idx) => {
							return (
								<Box key={idx} sx={{ mt: 3 }}>
									<Box sx={{ display: "flex", alignItems: "center" }}>
										<NumberContainer bordercolor={theme.palette.secondary.dark} bgColor={theme.palette.secondary.greyBg}>
											<Typography variant="title_medium">0{item.stepNo}</Typography>
										</NumberContainer>
										<Typography sx={{ ml: 2 }} variant="title_medium">
											{getExerciseTitle(item.stepNo)}
										</Typography>
									</Box>
									<Box sx={{ mt: 2, wordBreak: "break-all" }}>
										<Typography variant="bodyMedium">{item.answers[0]}</Typography>
									</Box>
								</Box>
							);
						})}
					</Box>
				) : powerup === "Brandstorming" ? (
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							height: "max-content",
							overflow: "hidden",
							wordBreak: "break-all",
						}}
					>
						{renderBrandStoryDetails()}

						<Box
							sx={{
								display: "flex",
								flexDirection: { xs: "column", md: "row" },
								justifyContent: { xs: "flex-start", md: "space-between" },
								alignItems: { md: "center" },
								mt: 3,
								gap: 3,
							}}
						>
							<Box>
								<Box sx={{ display: "flex", alignItems: "center" }}>
									<Typography variant="title_medium">Download the StartupOS Pitch Deck Template</Typography>
								</Box>
								<Box sx={{ mt: 1 }}>
									<Typography variant="bodyLarge">Try our pitch deck template to start building your perfect pitch.</Typography>
								</Box>
							</Box>

							<Box>
								<Button variant="outlined" startIcon={<FileDownloadOutlinedIcon />} onClick={() => {}} href={selectedTemplate.fileUrl}>
									Download template
								</Button>
							</Box>
						</Box>
					</Box>
				) : (
					""
				)
			) : (
				<Box
					sx={{
						display: "flex",
						width: 1,
						justifyContent: "center",
					}}
				>
					<CircularProgress />
				</Box>
			)}
		</Box>
	);
}
