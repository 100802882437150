import { ArrowForwardRounded } from "@mui/icons-material";
import { Box, Tab, Typography } from "@mui/material";

import React, { Fragment } from "react";

function InsightTabItem({ currentPoints, maxPoints, name, tabID, index, onClick, previousPoints, pointDifference, currentSelected, ...otherprops }) {
	return (
		<Tab
			{...otherprops}
			disableRipple
			tabIndex={index}
			id={`insight-tab-button-${tabID}`}
			sx={{
				textTransform: "unset",
				textAlign: "left",
				p: 0,
				pb: 1,
				whiteSpace: "nowrap",
				maxWidth: "unset",
				opacity: ".3",
				filter: "grayscale(100%)",
				"&.Mui-selected": {
					opacity: 1,
					filter: "grayscale(0%)",
				},
			}}
			label={
				<Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
					<Box>
						<Typography variant="customValue" sx={{ color: "black", fontFamily: "PoppinsMedium", fontSize: "16px", lineHeight: "24px" }}>
							{currentPoints} out of {maxPoints}
						</Typography>
					</Box>
					<Box
						sx={{
							display: "flex",
							gap: 2,
						}}
					>
						<Box>
							<Typography variant="customValue" sx={{ color: "black", fontFamily: "PoppinsMedium", fontSize: "20px", lineHeight: "20px" }}>
								{name}
							</Typography>
							<Box
								sx={{
									mt: 1,
									height: "4px",
									...(currentSelected && {
										backgroundColor: (theme) => theme.palette.primary.main,
										borderRadius: "5px",
									}),
								}}
							></Box>
						</Box>

						<Box
							sx={{
								display: "flex",
								alignItems: "flex-start",
								opacity: ".3",
								filter: "grayscale(100%)",
								...(currentSelected && {
									opacity: 1,
									filter: "grayscale(0%)",
								}),
							}}
						>
							<ArrowForwardRounded
								sx={{
									color: "white",
									height: "26px",
									width: "26px",
									background: "#7B61FF",
									borderRadius: "50%",
									p: "2px",
									rotate: pointDifference > 0 ? "-45deg" : pointDifference === 0 ? "0deg" : "45deg",
								}}
							/>
							<Typography
								variant="customValue"
								sx={{
									fontFamily: "PoppinsMedium",
									fontSize: "16px",
									lineHeight: "20px",
									color: "black",
								}}
							>
								{(() => {
									let updown = previousPoints <= currentPoints && "+";
									return updown + pointDifference;
								})()}
							</Typography>
						</Box>
					</Box>
				</Box>
			}
		/>
	);
}

export default InsightTabItem;
