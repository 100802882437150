import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import services from "../../services";
import { FeedMetaCard } from "../../common/FeedMetaCard";
import { useDispatch, useSelector } from "react-redux";
import { setFeedData } from "../../modules/actions";

export default function UpdatedMetaLink({ data, feedObj = {}, type = "OTHER" }) {
	const [links, setLinks] = useState([]);
	const [metaData, setMetaData] = useState([]);
	const feedData = useSelector((state) => state?.feedPost);
	const dispatch = useDispatch();

	useEffect(() => {
		if (data) {
			const tempElement = document.createElement("div");
			tempElement.innerHTML = data;

			const tempLinks = [];

			const elementsWithUrl = tempElement.querySelectorAll("a, p");
			elementsWithUrl.forEach((element) => {
				let url = "";
				if (element.tagName.toLowerCase() === "a") {
					url = element ? element.href : "";
				} else {
					url = checkUrl(element.textContent.trim());
				}
				if (url) {
					const linkObject = { link: url };
					tempLinks.push(linkObject);
				}
			});
			let filteredLinks = removeDuplicates(tempLinks);
			if (type === "CREATE") {
				if (filteredLinks.length > 0 && feedData?.metaLinks?.length === 0) {
					let metaDataArray = [];

					filteredLinks.map((item) => {
						let metaObj = {
							link: item?.link,
							isRemoved: false,
						};
						metaDataArray.push(metaObj);
					});
					dispatch(setFeedData({ ...feedData, metaLinks: metaDataArray }));
					setLinks(metaDataArray);
				} else if (filteredLinks.length > 0 && feedData?.metaLinks?.length > 0) {
					feedData?.metaLinks.push(...filteredLinks);
					let metaDataArray = [];
					let uniqueMetaArray = removeDuplicates(feedData?.metaLinks);
					uniqueMetaArray.map((item) => {
						let metaObj = {
							title: item.title,
							description: item.description,
							imageUrl: item.imageUrl,
							link: item.link,
							metaLink: item.metaLink,
							isRemoved: item.isRemoved,
						};
						metaDataArray.push(metaObj);
					});
					dispatch(setFeedData({ ...feedData, metaLinks: metaDataArray }));
					setLinks(metaDataArray);
				} else if (filteredLinks.length === 0 && feedData?.metaLinks?.length > 0) {
					let metaDataArray = [];

					feedData?.metaLinks.map((item) => {
						let metaObj = {
							title: item.title,
							description: item.description,
							imageUrl: item.imageUrl,
							link: item.metaLink,
							metaLink: item.metaLink,
							isRemoved: item.isRemoved,
						};
						metaDataArray.push(metaObj);
					});
					dispatch(setFeedData({ ...feedData, metaLinks: metaDataArray }));
					setLinks(metaDataArray);
				} else {
					setMetaData([]);
				}
			} else {
				if (filteredLinks.length > 0 && !feedObj?.metaData) {
					setLinks(filteredLinks);
				} else if (filteredLinks.length > 0 && feedObj?.metaData) {
					setLinks(feedObj?.metaData);
				} else if (filteredLinks.length === 0 && feedObj?.metaData) {
					setLinks(feedObj?.metaData);
				} else {
					setMetaData([]);
				}
			}
		} else {
			if (type === "CREATE" && feedData?.metaLinks) {
				setLinks(feedData?.metaLinks);
			} else {
				setMetaData([]);
			}
		}
	}, [data]);

	const checkUrl = (data) => {
		if (data.indexOf("http://") === 0 || data.indexOf("https://") === 0) {
			return data;
		}
	};

	const removeDuplicates = (linkList) => {
		const uniqueLinks = linkList.reduce((unique, link) => {
			const linkString = link.link;
			if (!unique.some((item) => item.link === linkString)) {
				unique.push(link);
			}
			return unique;
		}, []);
		return uniqueLinks;
	};

	useEffect(() => {
		handleGetMetaData();
	}, [links]);

	//Handle api for fetching metaData
	const handleGetMetaData = () => {
		if (links?.length > 0) {
			let metaLinks = links?.filter((item) => !item.isRemoved);
			if (metaLinks?.length > 0) {
				services
					.getMetadataDetails(metaLinks)
					.then((res) => {
						if (res.data.code === 200) {
							let tempMetaArray = [];
							res?.data?.data.map((item) => {
								let metaObj = {
									title: item.title,
									description: item.description,
									imageUrl: item.imageUrl,
									link: item.metaLink,
									metaLink: item.metaLink,
									isRemoved: false,
								};
								tempMetaArray.push(metaObj);
							});
							setMetaData(tempMetaArray);
							if (type === "CREATE") {
								dispatch(setFeedData({ ...feedData, metaLinks: tempMetaArray }));
							}
						}
					})
					.catch((err) => {
						setMetaData([]);
					});
			} else {
				setMetaData([]);
				dispatch(setFeedData({ ...feedData, metaLinks: [] }));
			}
		} else {
			setMetaData([]);
			dispatch(setFeedData({ ...feedData, metaLinks: [] }));
		}
	};

	return <Box sx={{ width: 1, display: "flex", my: 1 }}>{metaData && <FeedMetaCard metaData={metaData} type={type} setMetaData={setMetaData} />}</Box>;
}
