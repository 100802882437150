import React, { useContext } from "react";
import { Box, Grid, TextField, Typography } from "@mui/material";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { NumericFormat } from "react-number-format";
import UpdatedSelectField from "../../../common/UpdatedSelectField";
import { FUNDING_TYPES } from "../../../constants";
import { getJasonFormatedValue } from "../../../utils/commonFunctions";
import { FINANCING_LIST } from "../../../constants/updated-onboarding";
import UpdatedTextInputField from "../../../common/UpdatedTextInputField";
import NewSingleSelect from "../../../common/NewSingleSelect";

const FinancialSection = ({ context }) => {
	const { payLoad, setPayLoad, revenueTypeList } = useContext(context);

	const fieldList = [
		{ lable: "Funding Type", key: "fundingType", multiSelect: true, options: FUNDING_TYPES },
		{ lable: "Revenue", key: "revenue", multiSelect: false, singleSelect: true, options: ["Yes", "No"] },
		{ lable: "Financing", key: "financing", multiSelect: false, singleSelect: true, options: FINANCING_LIST },
		{ lable: "Capital Raised", key: "capitalRaised", multiSelect: false, options: [] },
		{ lable: "Revenue Model Type", key: "revenueModel", multiSelect: true, options: revenueTypeList },
	];
	function handleSelectChange(event, key) {
		let newValue = event.target.value.length > 0 ? JSON.stringify(event.target.value) : null;
		payLoad[key] = newValue;
		setPayLoad({ ...payLoad });
	}
	const handleValueChange = (value, key) => {
		payLoad[key] = value;
		setPayLoad({ ...payLoad });
	};
	const handleChange = (event) => {
		let key = event.target.id || event.target.name;
		let newValue = event.target.value;
		payLoad[key] = newValue;
		setPayLoad({ ...payLoad });
	};
	const handleSingleSelect = (event, key) => {
		let newValue = event.target.value;

		payLoad[key] = newValue;

		setPayLoad({ ...payLoad });
	};
	function handleDelete(value, key) {
		let tempArray = getJasonFormatedValue(payLoad[key]);
		let filteredArray = tempArray.filter((item) => item !== value);
		if (filteredArray.length > 0) {
			payLoad[key] = JSON.stringify(tempArray.filter((item) => item !== value));
		} else {
			payLoad[key] = null;
		}

		setPayLoad({ ...payLoad });
	}
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				rowGap: "30px",
			}}
		>
			<Box>
				<Typography variant="poppinsSemiBold20">Financial</Typography>
			</Box>

			<Grid container rowSpacing={4} columnSpacing={7}>
				{fieldList?.map((item) => {
					if (item.multiSelect) {
						return (
							<Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
								<UpdatedSelectField
									id={item.key}
									lable={item.lable}
									placeholder={item.lable}
									options={item.options}
									handleChange={handleSelectChange}
									handleDelete={handleDelete}
									inputValue={getJasonFormatedValue(payLoad[item.key]) ? getJasonFormatedValue(payLoad[item.key]) : []}
									selectProps={{
										multiple: true,
										fullWidth: true,
										IconComponent: KeyboardArrowDownRoundedIcon,
										height: "60px",
										width: "100%",
									}}
								/>
							</Grid>
						);
					} else {
						if (item.key === "capitalRaised") {
							return (
								<Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
									<Typography variant="poppinsSemiBold18">{item.lable}</Typography>

									<NumericFormat
										fullWidth
										placeholder={item.lable}
										value={payLoad[item.key] ? Number(payLoad[item.key]) : null}
										customInput={TextField}
										type="text"
										thousandSeparator={true}
										prefix={"$"}
										sx={{
											mt: 1.25,
											"& .MuiOutlinedInput-notchedOutline": {
												border: "none",
											},
											"& .MuiInputBase-root": {
												fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
												fontSize: "16px",
												lineHeight: "24px",
												height: "60px",
												width: "100%",
												borderRadius: "30px",
												background: "white",
												boxShadow: "0px 0px 0px 4px #F2F4F7, 0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
												border: "none",
											},
										}}
										onValueChange={(e) => handleValueChange((e.floatValue || 0).toString(), item.key)}
									/>
								</Grid>
							);
						} else if (item.singleSelect) {
							return (
								<Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
									<NewSingleSelect
										id={item.key}
										label={item.lable}
										fullWidth
										onInputChange={(e) => {
											handleSingleSelect(e, item.key);
										}}
										selectedValue={payLoad[item.key]}
										inputKey={item.key}
										options={item.options}
										borderNone={true}
									/>
								</Grid>
							);
						} else {
							return (
								<Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
									<UpdatedTextInputField
										label={item.lable}
										id={item.key}
										handleChange={handleChange}
										inputValue={payLoad[item.key]}
										placeholder={item.lable}
										textFieldProps={{
											fullwidth: true,
											width: "100%",
											height: "60px",
										}}
									/>
								</Grid>
							);
						}
					}
				})}
			</Grid>
		</Box>
	);
};

export default FinancialSection;
