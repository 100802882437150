import React, { useContext, useEffect, useState } from "react";
import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import { ErrorBoundary } from "react-error-boundary";
import ErrorMessage from "../../../../components/Tables/ErrorMessage";
import { OpportunityTablePaginationUI } from "./OpportunityTablePaginationUI";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
	boltRecommendedRow: {
		backgroundColor: "#F4EBFF",
	},
});

export default function OpportunityTableController({ requestData, columnDefs, dataGridProps, noResultsMessage, onRowClick, onCellClick, autoHeight = true }) {
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [page, setPage] = useState(0);
	const [perPage, setPerPage] = useState(10);
	const [rowCount, setRowCount] = useState(0);
	const [result, setResult] = useState({});
	const classes = useStyles();

	const fetchData = async (page) => {
		setLoading(true);

		try {
			const result = await requestData(page, perPage);
			setResult(result);
			setData(result?.models);
			setRowCount(result?.totalElements);
		} catch (error) {
			console.error("Failed to fetch data:", error);
		}
		setLoading(false);
	};

	useEffect(() => {
		fetchData(page);
	}, [page, perPage]);

	const getRowClassName = (params) => {
		if (params?.row?.boltRecommended) {
			return classes?.boltRecommendedRow || "";
		}
		return "";
	};

	return (
		<Box
			sx={{
				width: "100%",
				borderRadius: "12px",
				border: "1px solid var(--gray-200, #EAECF0)",
				background: "var(--base-white, #FFF)",
				boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)",
			}}
		>
			<ErrorBoundary fallback={<ErrorMessage />}>
				<DataGrid
					className="SOSShowVerticalScroll"
					getRowClassName={getRowClassName}
					disableRowSelectionOnClick={true}
					pagination
					paginationMode="server"
					rows={data}
					columns={columnDefs}
					rowCount={rowCount}
					loading={loading}
					getRowHeight={() => "auto"}
					autoHeight={autoHeight}
					pageSizeOptions={[perPage]}
					{...dataGridProps}
					initialState={{
						pagination: {
							paginationModel: {
								page: 0,
								pageSize: perPage,
							},
						},
					}}
					onRowClick={(params, event, details) => (typeof onRowClick === "function" ? onRowClick(params, event, details) : {})}
					onCellClick={(params, event, details) => (typeof onCellClick === "function" ? onCellClick(params, event, details) : {})}
					slots={{
						noRowsOverlay: () => <GridOverlay>{noResultsMessage}</GridOverlay>,
						noResultsOverlay: () => <GridOverlay>{noResultsMessage}</GridOverlay>,
						pagination: () => (
							<OpportunityTablePaginationUI
								setPage={(newVal) => setPage(newVal)}
								pageSize={perPage}
								setPageSize={setPerPage}
								pageSizeOptions={[10, 50, 100]}
								borderNone={true}
								result={result}
							/>
						),
					}}
					sx={{
						border: "none",
						maxHeight: "780px",
						height: data?.length > 0 ? "auto" : "400px",
						"& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-columnHeader:focus-within": {
							outline: "none",
						},
						"& .MuiDataGrid-columnHeaders": {
							borderBottom: "1px solid var(--gray-200, #EAECF0)",
							background: "#F9FAFB",
							display: "flex",
							height: "44px",
							padding: "12px 24px",
							gap: "12px",
							alignSelf: "stretch",
						},
						"& .MuiDataGrid-cell:focus": {
							outline: "none",
						},
						"& .MuiDataGrid-row": {
							px: "24px",
							cursor: "pointer",
						},
						"& .MuiDataGrid-cell": {
							display: "inline-flex",
							padding: "8px",
							alignItems: "center",
							gap: "8px",
						},
						"& .MuiDataGrid-footerContainer": {
							padding: "12px 24px 16px 24px",
							justifyContent: "unset",
						},
						"& .MuiDataGrid-virtualScroller": {
							"&::-webkit-scrollbar": {
								width: "8px",
								height: "8px",
							},
							"&::-webkit-scrollbar-thumb": {
								backgroundColor: (theme) => theme?.palette?.primary?.main,
								borderRadius: "4px",
							},
							"&::-webkit-scrollbar-track": {
								backgroundColor: (theme) => theme?.palette?.primary?.light,
							},
						},
					}}
				/>
			</ErrorBoundary>
		</Box>
	);
}
