import React, { Component } from "react";
import { Grid, Box, Stack } from "@mui/material";
import "./createPassword.css";
import TextInput from "../../common/input/Input";
import PasswordValidation from "../../common/passwordValidation/PasswordValidation";
import ROUTES_PATH from "../../constants/routes";
import { REGEX } from "../../constants/regex";
import { createUser, manualLogin } from "../../utils/auth0";
import services from "../../services";
import {
  getEmailToken,
  getUserEmail,
  saveUserId,
  saveVerificationType,
  setPrimaryAccountId,
} from "../../utils/sessionStorage";
import { toastContainer } from "../../utils/toast";
import messages from "../../constants/messages";
import LoadingButton from "../../common/Loadingbutton";
import * as actions from "../../modules/actions";
import { connect } from "react-redux";
import { USER_TYPES } from "../../utils/userTypes";

class CreatePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: true,
      confirmPassword: "",
      pasword: "",
      validationErrorArray: new Array(5).fill(true),
      emailError: false,
      confirm_password_error: "",
      signupLoading: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }
  ValidateEmail(value) {
    if (REGEX.EMAIL.test(value)) {
      return true;
    }
    return false;
  }
  handleEmailInputChange = event => {
    this.setState({
      email: event,
    });
    if (this.ValidateEmail(event)) {
      this.setState({ emailError: false });
    } else {
      this.setState({ emailError: true });
    }
  };

  comparePasswords(confirmPassword, password) {
    if (password) {
      if (confirmPassword === password) {
        this.setState({ confirm_password_error: "" });
      } else {
        this.setState({ confirm_password_error: "Passwords do not match" });
      }
    }
  }

  handlePasswordInputChange = event => {
    let { confirmPassword } = this.state;

    this.setState({
      password: event,
    });

    this.comparePasswords(event, confirmPassword);
    let validationErrorArray = [...this.state.validationErrorArray];
    if (event.length >= 8) {
      validationErrorArray[0] = false;
    } else {
      validationErrorArray[0] = true;
    }
    if (event.match(REGEX.LOWERCASELETTERS)) {
      validationErrorArray[1] = false;
    } else {
      validationErrorArray[1] = true;
    }
    if (event.match(REGEX.UPPERCASELETTERS)) {
      validationErrorArray[2] = false;
    } else {
      validationErrorArray[2] = true;
    }
    if (event.match(REGEX.NUMBERS)) {
      validationErrorArray[3] = false;
    } else {
      validationErrorArray[3] = true;
    }
    if (event.match(REGEX.SPECIAL_CHARACTERS)) {
      validationErrorArray[4] = false;
    } else {
      validationErrorArray[4] = true;
    }
    this.setState({ validationErrorArray });
  };

  handleConfirmPasswordInputChange = event => {
    let { password } = this.state;
    this.comparePasswords(event, password);

    this.setState({
      confirmPassword: event,
    });

    if (!event) {
      this.setState({
        confirm_password_error: "",
      });
    }
  };

  isEmpty = o => Object.entries(o).every(([k, v]) => v === "");

  handleLogin = async (emailId, pass) => {
    try {
      await manualLogin({ email: emailId, password: pass });
    } catch (err) {
      toastContainer(err?.error_description, "error");

      console.log(err?.error_description);
    }
  };

  handleSaveData = userId => {
    const tellUsMoreData = this.props.auth.tellUsMoreData;
    let payloadData = {
      firstName: tellUsMoreData.firstName,
      lastName: tellUsMoreData.lastName,
      title: tellUsMoreData.title,
      picture: tellUsMoreData.picture,
      userId: userId,
    };
    services
      .updateUserProfileDetailsApiCall(payloadData)
      .then(response => {
        console.log("response", response);
      })
      .catch(e => {
        console.log("Error", e);
      });
  };

  handleContinue = async () => {
    const { password } = this.state;
    const email = getUserEmail();
    this.setState({ signupLoading: true });
    let emailVerified = false;

    const authData = this.props.auth;
    let decodedObject;
    if (authData && !this.isEmpty(authData?.decodedPayload)) {
      decodedObject = authData?.decodedPayload;
      if (email === decodedObject.email) {
        emailVerified =
          decodedObject.type === USER_TYPES.MENTOR_USER ||
          decodedObject.type === USER_TYPES.ADVISOR_USER ||
          decodedObject.type === USER_TYPES.PROFESSOR_USER
            ? false
            : true;
      }
    }

    createUser({ email, password, emailVerified })
      .then(result => {
        let payload = {};
        payload = {
          id: result.Id,
          token: null,
          emailToken: getEmailToken(),
          emailVerified,
        };
        services
          .userSignup(payload)
          .then(res => {
            this.setState({ signupLoading: false });
            setPrimaryAccountId(res?.data?.data?.primaryAccountId);
            saveVerificationType("signUp");
            if (
              decodedObject &&
              decodedObject.type === USER_TYPES.STARTUP_USER
            ) {
              this.handleSaveData(res?.data?.data?.id);
              this.handleLogin(email, password);

              return;
            }

            if (emailVerified) {
              this.props.history.push(ROUTES_PATH.LOG_IN);
              return;
            }

            this.props.history.push(ROUTES_PATH.EMAIL_VERIFICATION);
          })
          .catch(err => {
            this.setState({ signupLoading: false });
            console.log(err);
            if (err.response.status === 404) {
              toastContainer(messages.INVALID_INVITE, "error");
            } else {
              toastContainer(err?.error_description, "error");
            }
          });
      })
      .catch(err => {
        this.setState({ signupLoading: false });
        if (err?.code === "invalid_signup") {
          toastContainer(messages.USER_ALREADY_EXISTS, "error");
        } else {
          console.log(err);
          toastContainer(err?.error_description, "error");
        }
      });
  };

  render() {
    let {
      password,
      validationErrorArray,
      confirmPassword,
      confirm_password_error,
    } = this.state;
    return (
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: "100%" }}
      >
        <Grid
          className="signup-grid-container"
          sx={{ mt: 9 }}
          container
          alignItems="center"
          justifyContent="center"
        >
          <Grid
            item
            xs={12}
            sm={10}
            md={6}
            lg={6}
            xl={6}
            className="form-container"
          >
            <div className="signup-title">Create New Password</div>

            <TextInput
              type="password"
              label="Password"
              value={password}
              onChange={e => this.handlePasswordInputChange(e)}
            />
            <TextInput
              type="password"
              label="Re-enter Password"
              value={confirmPassword}
              onChange={e => this.handleConfirmPasswordInputChange(e)}
            />
            <span className="error-text">{confirm_password_error}</span>
            <PasswordValidation validationErrorArray={validationErrorArray} />

            <Grid item>
              <Box textAlign="center" sx={{ pb: 2 }}>
                <LoadingButton
                  sx={{ mt: 3, maxWidth: "100%" }}
                  disabled={
                    !password ||
                    !confirmPassword ||
                    validationErrorArray.includes(true) ||
                    confirmPassword !== password
                  }
                  loading={this.state.signupLoading}
                  onClick={() => this.handleContinue()}
                >
                  Continue
                </LoadingButton>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Stack>
    );
  }
}

const mapStoreToProps = ({ auth, onboarding }) => ({
  onboarding: onboarding,
  auth: auth,
});

const mapDispatchToProps = dispatch => ({
  clearOnboardingData: () => dispatch(actions.clearOnboardingData()),
  setAuthData: data => dispatch(actions.setAuthData(data)),
});

export default connect(mapStoreToProps, mapDispatchToProps)(CreatePassword);
