import { Box, Button, CircularProgress, Grid, IconButton, Modal, Skeleton, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { Add, Check } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";

const PowerupActivitiesModal = ({
	openPowerUpPackDialog,
	handleClose,
	loading,
	powerUps,
	setPowerUps,
	powerUpLoading,
	setSelectedPowerups,
	selectedPowerups,
	isEdit,
}) => {
	const handleToggleSelection = (itemUuid) => {
		const updatedPowerUps = powerUps.map((item) => {
			if (item.uuid === itemUuid) {
				return {
					...item,
					selected: !item.selected,
				};
			}
			return item;
		});

		setPowerUps(updatedPowerUps);
	};

	const handleSelect = () => {
		const powerupItems = powerUps.filter((item) => item.selected);

		selectedPowerups?.forEach((item) => {
			const isSelected = powerupItems?.find((powerup) => powerup?.uuid === item?.uuid);
			item["selected"] = isSelected;
		});
		const items = [...selectedPowerups, ...powerupItems];
		const uuidSet = new Set();
		const uniqueItems = [];

		items.forEach((item) => {
			if (item?.selected) {
				if (!uuidSet.has(item.uuid)) {
					uuidSet.add(item.uuid);
					uniqueItems.push(item);
				}
			}
		});

		setPowerUps(uniqueItems);
		setSelectedPowerups(uniqueItems);

		handleClose();
	};

	return (
		<Modal
			open={openPowerUpPackDialog}
			onClose={() => {
				setPowerUps([]);

				handleClose();
			}}
		>
			<Box
				sx={{
					position: "fixed",
					top: "50%",
					left: "50%",
					transform: "translate(-50%, -50%)",
					width: "80vw",
					maxWidth: "700px",
					maxHeight: "90vh",
					overflowY: "auto",
					backgroundColor: "white",
					borderRadius: "20px",
					p: "20px",
					zIndex: 9999,
				}}
			>
				<Box p={1}>
					<Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
						<Box>
							<AddIcon />
						</Box>
						<Box onClick={handleClose} sx={{ cursor: "pointer" }}>
							<ClearIcon sx={{ color: "#475467" }} />
						</Box>
					</Box>
					<Box display={"flex"} flexDirection={"column"} p={1} columnGap={1}>
						<Typography variant="Text/sm/Semibold">{"Select PowerUps"}</Typography>
						<Typography variant="Text/xs/Regular" color={"#475467"}>
							{
								"Select which PowerUps you would like to include in your PowerPack. You can specify the order in which the PowerUps are shown in the PowerPack by dragging with icon on the left-hand side on each individual PowerUp."
							}
						</Typography>
					</Box>
					<Box sx={{ backgroundColor: "white", borderRadius: "10px", p: 1, border: "1px solid #D0D5DD", height: "auto" }}>
						<Grid container spacing={1}>
							{!powerUpLoading ? (
								powerUps?.map((item, index) => {
									return (
										<>
											<Grid item xs={6}>
												<Box display={"flex"} flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"} ml={3}>
													<Typography variant="Text/xs/Regular" color={"#475467"}>
														{item?.name}
													</Typography>
													<Box mr={5}>
														<IconButton
															onClick={() => handleToggleSelection(item?.uuid)}
															sx={{
																border: "1px solid",
																borderColor: "transparent",
																backgroundColor: "#7B61FF",
																color: "white",
																transition: "all .2s",
																"&:hover": {
																	color: "#344054",
																	borderColor: "#F2F4F7",
																	backgroundColor: "#7B61FF",
																},
															}}
														>
															{item?.selected ? <Check /> : <Add />}
														</IconButton>
													</Box>
												</Box>
											</Grid>
										</>
									);
								})
							) : (
								<Grid item xs={12}>
									<Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
										<CircularProgress size={"30px"} sx={{ color: "inherit" }} />
									</Box>
								</Grid>
							)}
						</Grid>
					</Box>
				</Box>
				<Box display={"flex"} alignItems={"center"} justifyContent={"flex-end"}>
					<Box>
						<Button onClick={handleClose} sx={{ color: (theme) => theme.palette.secondary.light }} variant="noOutlineNew">
							Cancel
						</Button>
					</Box>
					<Box>
						<Button
							startIcon={loading && <CircularProgress size={"1.25rem"} sx={{ color: "inherit" }} />}
							variant="DS1"
							disableFocusRipple
							disabled={!powerUps.some((item) => item.selected)}
							onClick={handleSelect}
						>
							{"Select"}
						</Button>
					</Box>
				</Box>
			</Box>
		</Modal>
	);
};

export default PowerupActivitiesModal;
