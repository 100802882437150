import React, { useEffect, useState } from "react";
import { Box, Skeleton } from "@mui/material";
import services from "../../../../services";
import MeetingLengthOption from "./MeetingLengthOption";
import { USER_TYPES } from "../../../../utils/userTypes";

const MeetingLengthOptionList = ({ setMeetingObject, calUser, withUserType }) => {
	const [eventTypes, setEventTypes] = useState([]);

	const getTypeName = () => {
		if (withUserType === USER_TYPES.MENTOR_USER) {
			return "Mentor";
		}
		if (withUserType === USER_TYPES.INVESTOR_USER) {
			return "Investor";
		}
		if (withUserType === USER_TYPES.STARTUP_OWNER) {
			return "Founder";
		}
	};

	useEffect(() => {
		if (calUser) {
			services
				.getAllEventTypesApi(calUser)
				.then((res) => {
					const eventTypes = res?.data?.data;

					let et = eventTypes.filter((x) => !x.hidden);
					setEventTypes(et);
				})
				.catch((err) => {
					console.log(err);
				});
		}
	}, [calUser]);

	const handleScheduleBooking = (item) => {
		if (setMeetingObject) {
			setMeetingObject({
				rescheduling: false,
				duration: item.meetingLength,
				title: item.title,
				eventTypeId: item.id,
				guestCalUserId: item.userId,
				eventLocation: item.locations ? item.locations[0]?.type : "",
			});
		}
	};

	const MeetingLengthOptionListLoader = () => {
		return (
			<Box
				p={3}
				display="flex"
				flexDirection="column"
				rowGap={1}
				sx={{
					boxShadow: "0px 10px 25px rgba(0, 0, 0, 0.05)",
					borderRadius: "24px",
					cursor: "pointer",
				}}
			>
				<Skeleton variant="circular" width={30} height={30} />
				<Skeleton variant="text" width={150} />
				<Skeleton variant="text" width={200} />
			</Box>
		);
	};

	return (
		<Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
			{eventTypes.length > 0 ? (
				eventTypes.map((item, index) => (
					<MeetingLengthOption
						key={item?.id}
						title={`${item?.title}`}
						description={`Meet your ${getTypeName()} for a ${item?.meetingLength} minute session.`}
						setSelected={() => {
							handleScheduleBooking(item);
						}}
						index={index}
					/>
				))
			) : (
				<>
					<MeetingLengthOptionListLoader />
					<MeetingLengthOptionListLoader />
				</>
			)}
		</Box>
	);
};

export default MeetingLengthOptionList;
