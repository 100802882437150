import React from "react";
import { Box, Typography, useTheme, Grid } from "@mui/material";

function LabelList({ labels = [] }) {
	const theme = useTheme();
	return labels.map((label) => (
		<Box key={label?.name} xs={12} display="flex" alignItems="center" mb={0.5}>
			<Box sx={{ width: "12px", height: "12px", borderRadius: "50%", bgcolor: label?.color }} />
			<Typography flex={1} ml={1} noWrap variant="BIChartSmallText" color={theme.palette.bidashboard.cardLatestParamsText}>
				{label?.name ? label?.name : ""}
			</Typography>
		</Box>
	));
}

export default LabelList;
