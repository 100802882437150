import React, { Fragment, useEffect, useState } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, Alert, Divider, AlertTitle } from "@mui/material";

import { BELL_RINGING } from "../../../../constants";
import { Close } from "@mui/icons-material";
import { BoltSolutionExport } from "./BoltSolutionReviewExport";

function BoltResponseModal({ responseObj, open, onClose }) {
	const [response, setResponse] = useState(responseObj);

	useEffect(() => {
		console.log(responseObj);
		setResponse(responseObj);
	}, [responseObj]);

	const handleClose = () => {
		onClose();
	};

	const handleDownload = async () => {
		// handle download then close
		await BoltSolutionExport({ response: response });
		handleClose();
	};

	return (
		<Dialog
			maxWidth={"lg"}
			open={open}
			onClose={onClose}
			PaperProps={{
				sx: {
					borderRadius: "20px",
					p: 4,
				},
			}}
		>
			<DialogTitle sx={{ px: 0, pt: 0 }}>
				<Box
					sx={{
						display: "flex",
						flex: 1,
						justifyContent: "space-between",
					}}
				>
					<img src={BELL_RINGING} />
					<Close sx={{ cursor: "pointer" }} onClick={handleClose} />
				</Box>
			</DialogTitle>
			<DialogContent sx={{ px: 0 }}>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						gap: 2,
					}}
				>
					<Typography variant={"Display/xs/Semibold"} component={"p"} sx={{ mb: 1 }}>
						StartupOS Bolt AI Review
					</Typography>
					<Box
						sx={{
							backgroundColor: "#F2EFFF",
							p: 4,
							borderRadius: "20px",
							mb: 1,
						}}
					>
						<Typography variant={"Display/xs/Semibold"} component={"p"} sx={{ mb: 1 }}>
							Note on AI
						</Typography>
						<Typography variant={"Text/md/Regular"} component={"p"}>
							Bolt is an AI working with StartupOS. It's still getting the hang of things, so its responses may not be entirely helpful from time
							to time. We are constantly working together to improve our working relationship to deliver the best results to you. Have feedback?
							Contact <a href="mailto:success@startupos.com?subject=Feedback on StartupOS Bolt AI">success@startupos.com</a>
						</Typography>
					</Box>

					<Box>
						<Typography component={"h6"} variant="Text/xl/Semibold" sx={{ mb: 1 }}>
							StartupOS Bolt AI Summary
						</Typography>
						<Typography variant={"Text/md/Regular"} component={"p"}>
							{response?.summary}
						</Typography>
					</Box>

					{response?.ranking?.map((item, index) => (
						<Box sx={{ mb: 4 }}>
							<Divider sx={{ my: 2 }} />
							<Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
								<Alert severity="info">
									<AlertTitle>
										<strong>Your Solution</strong>
									</AlertTitle>
									{item?.solution_name}
								</Alert>

								<Typography component={"h6"} variant="Text/xl/Semibold" sx={{ mb: 1 }}>
									Bolt's Review
								</Typography>
								<Typography variant={"Text/md/Regular"} component={"p"}>
									{item?.solution_description}
								</Typography>
							</Box>
						</Box>
					))}

					{response?.proposed_solution && (
						<Box>
							<Divider sx={{ my: 2 }} />
							<Typography variant={"Display/xs/Semibold"} component={"p"} sx={{ mb: 1 }}>
								StartupOS Bolt AI's Proposed Solution
							</Typography>
							<Typography variant={"Text/md/Regular"} component={"p"}>
								{response?.proposed_solution}
							</Typography>
						</Box>
					)}
				</Box>
			</DialogContent>
			<DialogActions sx={{ p: 0 }}>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "flex-end",
						gap: 1,
					}}
				>
					<Button onClick={handleClose} variant="RoundedTextMuted" sx={{ minWidth: "unset", maxWidth: "unset", width: "auto" }}>
						Just Close
					</Button>
					<Button variant={"PurpleRounded"} onClick={handleDownload} sx={{ minWidth: "unset", maxWidth: "unset", width: "auto" }}>
						Download Report and Close
					</Button>
				</Box>
			</DialogActions>
		</Dialog>
	);
}

export default BoltResponseModal;
