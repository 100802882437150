import React, { Fragment, useEffect, useState } from "react";
import { Box, Grid, Button, Typography, Divider } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import services from "../../../../services";

export default function CandidateHeader({
  candidateName,
  candidateEmail,
  linkedInUrl,
  avatar,
  saved,
  setSaved,
  invitedCandidates,
  data,
  handleSelect,
  invited,
  setShowInviteCandidatePopup,
}) {
  return (
    <>
      <Grid container>
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: 1,
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <img
              src={avatar}
              height="56"
              width="56"
              style={{ borderRadius: "50%", paddingTop: 4 }}
            />

            <Box sx={{ display: { xs: "block", md: "none" } }}>
              <Button
                variant="nofill"
                startIcon={saved ? <BookmarkIcon /> : <BookmarkBorderIcon />}
                size="sm"
                sx={{ width: "max-content" }}
                onClick={handleSelect}
              >
                Save
              </Button>
            </Box>
          </Box>
          <Typography variant="largeTitle">{candidateName}</Typography>
          <Typography variant="onsurface_subtext">{candidateEmail}</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={10}
          sx={{
            mt: 3,
          }}
        >
          <Button
            startIcon={<AddIcon />}
            size="sm"
            sx={{ width: "max-content" }}
            onClick={() => setShowInviteCandidatePopup(true)}
            disabled={invited}
          >
            Invite
          </Button>
          <Button
            variant="outlined"
            sx={{ ml: 1, width: "max-content", px: 2, whiteSpace: "nowrap" }}
            href={linkedInUrl}
            target="_blank"
          >
            View LinkedIn profile
          </Button>
        </Grid>
        <Grid
          item
          xs={6}
          md={2}
          sx={{
            display: { xs: "none", md: "flex" },
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}
        >
          <Box>
            <Button
              variant="nofill"
              startIcon={saved ? <BookmarkIcon /> : <BookmarkBorderIcon />}
              size="sm"
              sx={{ width: "max-content" }}
              onClick={handleSelect}
            >
              Save
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
