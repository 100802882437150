import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import InsightCardComp from "./InsightCardComp";
import InsightSubSectionRow from "./InsightSubSectionRow";
//import useDimensions from "react-cool-dimensions";
//import _ from "lodash";

const getChange = (currentPts, previousPts) => {
	const change = currentPts - previousPts;
	return change === 0 ? change : change > 0 ? `+${change}` : change;
};
const getPercentChange = (currentPts, previousPts) => {
	if (previousPts == 0) {
		if (currentPts > 0) {
			return "+100%";
		} else {
			return "0%";
		}
	} else {
		const change = ((currentPts - previousPts) / previousPts) * 100;
		const roundedChange = Math.round(change);
		return roundedChange === 0 ? roundedChange : roundedChange > 0 ? `+${roundedChange}%` : `${roundedChange}%`;
	}
};

function InsightSubSection({ data }) {
	/*
		///TODO -- For mobile displays.
	*/
	//const [isSmallComponent, setSmallComponent] = useState(false);

	//const minWidth = 500;

	//const { observe, unobserve, width, height, entry } = useDimensions({
	//	onResize: _.throttle(() => {
	//		setSmallComponent(width && width <= minWidth);
	//	}, 500),
	//});

	//useEffect(() => {
	//	observe();
	//}, []);

	//useEffect(() => {
	//	setSmallComponent(width && width <= minWidth);
	//}, [width]);

	return (
		<Box>
			<InsightCardComp>
				<Table
					sx={{
						borderCollapse: "inherit",
						"& .MuiTableCell-root": {
							p: 1,
						},
					}}
				>
					<TableHead
						sx={{
							"& .MuiTableCell-root": {
								border: "none",
							},
						}}
					>
						<TableRow sx={{ verticalAlign: "top" }}>
							<TableCell sx={{ p: 1 }} width="100%" align="left">
								<Typography variant="poppinsSemiBold16" color={"black"}>
									{data?.name}
								</Typography>
							</TableCell>
							<TableCell align="right" sx={{ p: 1 }}>
								<Typography variant="poppinsSemiBold14" color={"black"}>
									Current
								</Typography>
							</TableCell>
							{/* Hide the 'change' amount column when space is tight. */}
							<TableCell align="right" sx={{ p: 1 }}>
								<Typography variant="poppinsSemiBold14" color={"black"}>
									Change
								</Typography>
							</TableCell>
							<TableCell align="right" sx={{ p: 1 }}>
								<Typography variant="poppinsSemiBold14" color={"black"} sx={{ whiteSpace: "nowrap" }}>
									% Change
								</Typography>
							</TableCell>
						</TableRow>
					</TableHead>
					<Box sx={{ height: "1rem" }}></Box>
					<TableBody>
						{data?.elements &&
							data?.elements?.map((obj, index) => (
								<Fragment>
									<InsightSubSectionRow
										key={index}
										name={obj?.name}
										current={obj?.currentPoints}
										previous={obj?.previousPoints}
										change={getChange(obj?.currentPoints, obj?.previousPoints)}
										percentChange={getPercentChange(obj?.currentPoints, obj?.previousPoints)}
									/>
									<Box sx={{ height: ".8rem" }}></Box>
								</Fragment>
							))}
					</TableBody>
				</Table>
			</InsightCardComp>
		</Box>
	);
}

export default InsightSubSection;
