import React from "react";
import { Box, Typography, Button } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import theme from "../../../constants/Theme";
import { Quote } from "./Quote";

export const Checkout = ({ quotes, onBack }) => {
	const handleBack = () => {
		onBack(false);
	};

	return (
		<Box
			sx={{
				maxWidth: theme.breakpoints.values.md,
				margin: "auto",
				width: "100%",
			}}
		>
			<Box mb={8}>
				<Box>
					<Button variant="text" size="small" sx={{ padding: 0, maxWidth: "max-content" }} onClick={handleBack} startIcon={<ArrowBackIosIcon />}>
						Back to Quote
					</Button>
				</Box>
			</Box>

			<Box textAlign="center" mb={8}>
				<Typography variant="main_title" sx={{ display: "block", marginBottom: "20px" }}>
					How would you like to pay?
				</Typography>
				<Typography variant="count_title">Basic Package</Typography>
			</Box>
			{quotes.map(quote => (
				<Quote key={quote.id} quote={quote} />
			))}
		</Box>
	);
};
