import React, { useMemo, useState } from "react";
import { Box, Typography, Avatar, IconButton, ClickAwayListener, Tooltip, tooltipClasses, styled, List, ListItem } from "@mui/material";

import InfoIcon from "../../Assets/info-circle.svg";

const CustomTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
	[`& .${tooltipClasses.tooltip}`]: {
		margin: "0 !important",
		borderRadius: "50px",
		padding: 0,
	},
	[`.${tooltipClasses.popper}`]: {
		borderRadius: "50px",
	},
});

export const OnboardCard = ({
	id,
	icon,
	title,
	selectedId,
	desc,
	onSelect,
	multiSelect = false,
	isLargeSpacing = true,
	isExpertise = false,
	subOptions = [],
}) => {
	const currentColor = useMemo(() => {
		if (multiSelect === false) {
			if (selectedId === id) {
				return "linear-gradient(320.08deg, #DE0085 12.44%, #7B61FF 92.01%)";
			}
		} else {
			if (selectedId.findIndex((item) => item === id) > -1) {
				return "linear-gradient(320.08deg, #DE0085 12.44%, #7B61FF 92.01%)";
			}
		}

		return "#DDDDDD";
	}, [selectedId, id, multiSelect]);

	const handleSelect = (curId) => {
		if (!multiSelect) {
			onSelect(curId);
		} else {
			const curIndex = selectedId.findIndex((item) => item === curId);
			if (curIndex > -1) {
				selectedId.splice(curIndex, 1);
				onSelect(selectedId);
			} else {
				if ([...selectedId, curId].length > 3) {
					onSelect([...selectedId]);
				} else {
					onSelect([...selectedId, curId]);
				}
			}
		}
	};

	const [openValidationDetail, setOpenValidationDetail] = useState(false);

	const handleTooltipClose = () => {
		setOpenValidationDetail(false);
	};

	const handleTooltipOpen = (e) => {
		e.stopPropagation();
		setOpenValidationDetail(true);
	};

	return (
		<Box
			flex={1}
			position="relative"
			maxWidth={"280px"}
			p="29px 50px 60px"
			sx={{
				background: "#FFFFFF",
				boxShadow: "0px 10px 85px rgba(0, 0, 0, 0.09)",
				borderRadius: "60px",
				"&:hover": { cursor: "pointer" },
			}}
			onClick={() => handleSelect(id)}
		>
			<Avatar
				sx={{
					position: "relative",
					zIndex: 1,
					width: "100px",
					height: "100px",
					background: currentColor,
					"&::before": {
						content: '""',
						position: "absolute",
						top: 0,
						left: 0,
						width: "100%",
						height: "100%",
						background: "linear-gradient(320.08deg, #DE0085 12.44%, #7B61FF 92.01%)",
						opacity: 0,
						transition: "opacity 0.4s",
						zIndex: -1,
					},
					"&:hover::before": {
						opacity: 1,
					},
				}}
			>
				<img src={icon} alt={title} />
			</Avatar>
			<Box position="relative" zIndex={1}>
				<Typography variant="poppinsSemiBold30" mt="10px" sx={{ display: "block", zIndex: 1 }}>
					{title}
				</Typography>
				<Box mt={isLargeSpacing ? 2 : 1.25}>
					<Typography variant="onsurface_title" sx={{ zIndex: 1 }}>
						{desc}
					</Typography>
				</Box>
			</Box>
			<Box
				position="absolute"
				top="80px"
				left="0px"
				width="100%"
				height="calc(100% - 80px)"
				sx={{ bgcolor: "#F5F9FA", borderRadius: "0px 190px 60px 60px" }}
			></Box>
			{isExpertise && (
				<ClickAwayListener onClickAway={handleTooltipClose}>
					<CustomTooltip
						PopperProps={{
							disablePortal: true,
						}}
						placement={"right-start"}
						onClose={handleTooltipClose}
						open={openValidationDetail}
						sx={{
							background: "#fff",
						}}
						title={
							<Box
								width="340px"
								sx={{
									background: "#FFFFFF",
									boxShadow: "0px 10px 85px rgba(0, 0, 0, 0.1)",
									borderRadius: "30px",
									p: "30px",
									textAlign: "left",
								}}
							>
								<Typography variant="poppinsSemiBold18" color="#000">
									{title}
								</Typography>
								<List
									sx={{
										listStyleType: "disc",
										pl: 2.5,
										"& .MuiListItem-root": {
											display: "list-item",
											color: "#000",
										},
									}}
								>
									{subOptions.map((subOption) => (
										<ListItem key={subOption.id} sx={{ p: "2px 0" }}>
											<Typography variant="onsurface_title" color="#000">
												{subOption.title}
											</Typography>
										</ListItem>
									))}
								</List>
							</Box>
						}
					>
						<Box position="absolute" top="29px" right="32px">
							<IconButton sx={{ p: 0 }} onClick={handleTooltipOpen}>
								<img src={InfoIcon} alt={"Info"} />
							</IconButton>
						</Box>
					</CustomTooltip>
				</ClickAwayListener>
			)}
		</Box>
	);
};
