import services from "../../../services";

export const getFeedPostList = (page, perPage, filter, sort) => {
	return services
		.getFeedData(page, perPage, filter, sort)
		.then((result) => {
			return result;
		})
		.catch((error) => {
			return error;
		});
};

export const createFeedPostApiCall = (payload) => {
	return services
		.createFeedPost(payload)
		.then((result) => {
			return result;
		})
		.catch((error) => {
			return error;
		});
};

export const likeOnFeedPostApiCall = (postId, payload) => {
	return services
		.likeOnFeedPost(postId, payload)
		.then((result) => {
			return result;
		})
		.catch((error) => {
			return error;
		});
};

export const disLikeOnFeedPostApiCall = (postId, action, payload) => {
	return services
		.disLikeOnFeedPost(postId, action, payload)
		.then((result) => {
			return result;
		})
		.catch((error) => {
			return error;
		});
};

export const getFeedPostCommentsList = (postId, page, perPage, filter, sort) => {
	return services
		.getFeedPostComments(postId, page, perPage, filter, sort)
		.then((result) => {
			return result;
		})
		.catch((error) => {
			return error;
		});
};

export const createFeedPostCommentApiCall = (postId, payload) => {
	return services
		.createFeedPostComment(postId, payload)
		.then((result) => {
			return result;
		})
		.catch((error) => {
			return error;
		});
};

export const editFeedPostCommentApiCall = (postId, commentId, payload) => {
	return services
		.editFeedPostComment(postId, commentId, payload)
		.then((result) => {
			return result;
		})
		.catch((error) => {
			return error;
		});
};
export const deleteFeedPostCommentApiCall = (postId, commentId) => {
	return services
		.deleteFeedPostComment(postId, commentId)
		.then((result) => {
			return result;
		})
		.catch((error) => {
			return error;
		});
};

export const editFeedPostApiCall = (postId, payload) => {
	return services
		.editFeedPost(postId, payload)
		.then((result) => {
			return result;
		})
		.catch((error) => {
			return error;
		});
};

export const deleteFeedPostApiCall = (postId) => {
	return services
		.deleteFeedPost(postId)
		.then((result) => {
			return result;
		})
		.catch((error) => {
			return error;
		});
};
export const getFeedPostByIdFunction = (postId) => {
	return services
		.getFeedPostByIdApi(postId)
		.then((result) => {
			return result;
		})
		.catch((error) => {
			return error;
		});
};
