import React, { useEffect, createContext, useState, useMemo } from "react";
import { Box, Fab, Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import { Helmet } from "react-helmet";

import { ProfileContainer } from "./style";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import UserDetailsComponent from "../../common/CommonUserCardComponents/UserDetailsComponent";
import UserAboutCard from "../../common/CommonUserCardComponents/UserAboutCard";
import UserStatsCard from "../../common/CommonUserCardComponents/UserStatsCard";
import UserAboutVideoCard from "../../common/CommonUserCardComponents/UserAboutVideoCard";
import MultiTagDisplayPopUp from "../../common/CommonUserCardComponents/MultiTagDisplayComponent";
import UserEducationDetailsCard from "../../common/CommonUserCardComponents/UserEducationDetailsCard";
import UserExperienceDetailsCard from "../../common/CommonUserCardComponents/UserExperienceDetailsCard";
import { GET_MENTOR_DATA_QUERY } from "./Function/common";
import { useLazyQuery } from "@apollo/client";
import { useHistory, useLocation, useParams } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import ROUTES_PATH from "../../constants/routes";
import { useDispatch, useSelector } from "react-redux";
import { setUserProfileData } from "../../modules/actions/userProfileActions";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import UserProfileEditPopup from "../UserProfileEditPopUp";
import { setAuthData } from "../../modules/actions";
import { getRoleValue, handleExpAndEduSort } from "../../utils/commonFunctions";
import { BLACK_ACCOUNT_PROFILE_ICON, DRAWER_CLOSE_BLACK, PAGE_STARTUP_PROFILE_ICON } from "../../constants";
import { handleMixPanel } from "../../utils/mixPanelEventHandling";
import { setSlidingProfileData } from "../../modules/actions/slidingProfileActions";

export const MentorProfileContext = createContext();

const MentorProfileNew = () => {
	const params = useParams();
	const slidingProfileData = useSelector((store) => ({
		open: store?.slidingProfile?.open,
		profileType: store?.slidingProfile?.profileType,
		profileId: store?.slidingProfile?.profileId,
	}));
	const location = useLocation();
	const userId = slidingProfileData?.open ? slidingProfileData?.profileId : params?.id ? params?.id : null;
	const history = useHistory();
	const dispatch = useDispatch();
	const editorOpen = useSelector((state) => state?.userProfileData?.userProfileEditOpen);
	const [bioPercentage, setBioPercentage] = useState(0);
	const [statsPercentage, setStatsPercentage] = useState(0);
	const [educationPercentage, setEducationPercentage] = useState(0);
	const [experiencePercentage, setExperiencePercentage] = useState(0);
	const userDetails = useSelector((state) => state?.auth);
	const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
	const [mixPanelCalled, setMixPanelCalled] = useState(false); //to makesure mixpanel call once

	const [loadUserData, { loading, error, data }] = useLazyQuery(GET_MENTOR_DATA_QUERY, {
		variables: { id: userId },
		errorPolicy: "all",
		fetchPolicy: "no-cache",
	});

	useEffect(() => {
		loadUserData({
			variables: { id: userId },
		});
	}, [userId]);

	useEffect(() => {
		if (!slidingProfileData?.open) {
			loadUserData({
				variables: { id: userId },
			});
		}
	}, [slidingProfileData?.open]);

	useEffect(() => {
		if (loading) {
			dispatch(
				setUserProfileData({
					loading: loading,
				})
			);
		} else {
			if (!error && data) {
				if (!slidingProfileData?.open && !params?.id) {
					dispatch(
						setAuthData({
							userData: { picture: data?.userById?.picture },
							userName: data?.userById?.name,
						})
					);
				}
				let sortedData = handleExpAndEduSort(data?.userById);
				dispatch(
					setUserProfileData({
						loading: loading,
						userData: sortedData,
					})
				);
			} else {
				console.log(error);
			}
		}
	}, [loading]);

	useEffect(() => {
		if (!editorOpen) {
			loadUserData({
				variables: { id: userId },
			});
		}
	}, [editorOpen]);

	useEffect(() => {
		if (!loading && data && !error && !mixPanelCalled) {
			setMixPanelCalled(true);
			const mixData = {
				"Viewed Mentor id": data?.userById?.id,
				"Viewed Mentor name": data?.userById?.name,
				"User Id": userDetails?.userId,
			};
			if ((!userId || userId === userDetails?.userId) && location.pathname.includes(ROUTES_PATH.MY_MENTOR_PROFILE) && !slidingProfileData?.open) {
				handleMixPanel("Mentor Profile Viewed", mixData);
			} else if (slidingProfileData?.open && location.pathname.includes(ROUTES_PATH.NETWORK)) {
				handleMixPanel("Community Mentor Profile Viewed", mixData);
			}
		}
	}, [loading]);

	const overallPercentageCompetion = (count) => {
		let total = Math.round((bioPercentage + statsPercentage + experiencePercentage + educationPercentage) / count);
		return total;
	};

	const handleEditProfileRedirections = () => {
		// history.push(ROUTES_PATH.EDIT_MY_ACCOUNT_PROFILE);
		if (!slidingProfileData?.open) {
			dispatch(
				setUserProfileData({
					userProfileEditOpen: true,
				})
			);
		}
	};

	const EditButton = () => (
		<Fab
			sx={{
				ml: "auto",
				position: "fixed",
				top: 200,
				right: 20,
				bgcolor: (theme) => theme.palette.primary.main,
				color: (theme) => theme.palette.secondary.white,
				"&:hover": {
					bgcolor: (theme) => theme.palette.primary.dark,
				},
			}}
			aria-label="Edit"
			color="secondary.white"
			onClick={handleEditProfileRedirections}
		>
			<EditIcon />
		</Fab>
	);

	const handleCloseSlidingProfileDialog = () => {
		// history.push(ROUTES_PATH.EDIT_MY_ACCOUNT_PROFILE);
		dispatch(
			setSlidingProfileData({
				open: false,
				profileType: "",
				profileId: null,
			})
		);
	};

	const CloseDrawer = () => (
		<Fab
			sx={{
				position: "fixed",
				top: 60, //sliding drawer top padding
				right: 20,
				bgcolor: (theme) => theme.palette.secondary.black,
				color: (theme) => theme.palette.secondary.white,
				"&:hover": {
					bgcolor: (theme) => theme.palette.secondary.black,
				},
			}}
			aria-label="close"
			color="secondary.white"
			onClick={handleCloseSlidingProfileDialog}
		>
			<Box component={"img"} src={DRAWER_CLOSE_BLACK} alt="close-drawer" />
		</Fab>
	);
	return (
		<MentorProfileContext.Provider
			value={{
				handleEditProfileRedirections,
				setBioPercentage,
				setStatsPercentage,
				setExperiencePercentage,
				setEducationPercentage,
			}}
		>
			<>
				<Helmet>
					<meta charSet="utf-8" />
					<title>StartupOS - Mentor Profile</title>
				</Helmet>
				<Stack
					sx={(theme) => ({
						display: "flex",
						justifyContent: "center",
						pb: 4,
					})}
				>
					{(!userId || userId === userDetails?.userId) && location.pathname.includes(ROUTES_PATH.MY_MENTOR_PROFILE) && !slidingProfileData?.open && (
						<EditButton />
					)}
					{slidingProfileData?.open && <CloseDrawer />}
					<ProfileContainer>
						<Stack direction={isSmallScreen ? "column" : "row"} alignItems={isSmallScreen ? "left" : "center"} spacing={3}>
							<Stack direction="row" alignItems="center" spacing={1}>
								<Box component={"img"} src={BLACK_ACCOUNT_PROFILE_ICON} alt="dashbaord" sx={{ width: "30px", height: "30px" }} />
								<Typography
									// variant="testHeading"
									variant="customValue"
									component={"div"}
									sx={{
										fontFamily: "PoppinsSemiBold",
										fontSize: "26px",
										lineHeight: "39px",
										color: "black",
										whiteSpace: "nowrap",
									}}
								>
									Mentor Profile
								</Typography>
							</Stack>
							{(!userId || userId === userDetails?.userId) && (
								<Stack direction="row" alignItems="center" spacing={1}>
									<Typography variant="testHeading">Progress</Typography>
									<Stack direction="row" alignItems="center" spacing={1}>
										<Typography variant="testHeading">{overallPercentageCompetion(4)}%</Typography>
										<ArrowForwardIcon sx={{ cursor: "pointer" }} onClick={handleEditProfileRedirections} />
									</Stack>
								</Stack>
							)}
						</Stack>
						<UserDetailsComponent />
						<Stack direction="column" spacing={4}>
							<Grid container spacing={4}>
								<Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
									<Stack direction="column" alignItems="center" spacing={4}>
										{isSmallScreen ? <UserAboutVideoCard /> : <UserAboutCard context={MentorProfileContext} />}

										{isSmallScreen ? <UserAboutCard context={MentorProfileContext} /> : <UserStatsCard context={MentorProfileContext} />}
									</Stack>
								</Grid>
								<Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
									{isSmallScreen ? <UserStatsCard context={MentorProfileContext} /> : <UserAboutVideoCard />}
								</Grid>
							</Grid>
							<Grid container>
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
									<Stack direction="column" spacing={4}>
										<UserExperienceDetailsCard context={MentorProfileContext} />
										<UserEducationDetailsCard context={MentorProfileContext} />
									</Stack>
								</Grid>
							</Grid>
						</Stack>
					</ProfileContainer>
					<MultiTagDisplayPopUp />
				</Stack>
				<UserProfileEditPopup disableBackDropClick={true} />
			</>
		</MentorProfileContext.Provider>
	);
};

export default MentorProfileNew;
