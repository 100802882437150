import { Avatar, Box, Typography } from "@mui/material";
import React, { useContext, useEffect } from "react";
import CardItem from "../Common/CardItem";
import { NOTES_ICON_NEW, UPDATED_FOUNDER_ICON, UPDATED_INVESTOR_ICON, UPDATED_MENTOR_ICON } from "../../../constants";
import SvgComponent from "../../../utils/SVGColor";
import { useHistory, useLocation } from "react-router-dom";
import { ResourceCenterContext } from "../Stores/ResourceCenterStore";
import ListSkeleton from "../Common/ListSkeleton";
import { handleMixPanel } from "../../../utils/mixPanelEventHandling";

const ArticleItem = ({ name, description, timeout, id }) => {
	const history = useHistory();
	const location = useLocation();

	const { currentCategory, currentSection } = useContext(ResourceCenterContext);

	const getAvatar = (type) => {
		let icon;
		let bgColor;
		switch (type) {
			case 0:
				icon = <Box component={"img"} src={UPDATED_FOUNDER_ICON} alt="logo" />;
				bgColor = "#7B61FF";
				break;
			case 1:
				icon = <Box component={"img"} src={UPDATED_MENTOR_ICON} alt="logo" />;
				bgColor = "#DE0085";
				break;
			case 2:
				icon = <Box component={"img"} src={UPDATED_INVESTOR_ICON} alt="logo" />;
				bgColor = "#FFD13F";
				break;
			case 3:
				icon = <SvgComponent strokeColor={"white"} svgUrl={NOTES_ICON_NEW} style={{ height: "24px", width: "24px" }} />;
				bgColor = "#AFDDD0";
				break;
			default:
				icon = <Box component={"img"} src={UPDATED_FOUNDER_ICON} alt="logo" />;
				bgColor = "#7B61FF";
				break;
		}
		return (
			<Avatar
				sx={{
					height: "60px",
					width: "60px",
					bgcolor: bgColor,
				}}
			>
				{icon}
			</Avatar>
		);
	};
	return (
		<CardItem
			onClick={() => {
				handleMixPanel("Resource Center: Article Clicked", {
					"Article Name": { name },
				});

				history.push(`/resource-center/sections/${currentSection?.id}/articles/${id}`);
			}}
			//onClick={() => history.push(`/resource-center/categories/${currentCategory?.id}/sections/${currentSection?.id}/articles/${id}`)}
			timeout={timeout}
		>
			<Box
				sx={{
					display: "flex",
					gap: 2,
					...(!description && { alignItems: "center" }),
				}}
			>
				{getAvatar(name)}
				<Box sx={{ flex: 1 }}>
					<Typography variant="Display/sm/Semibold" sx={{ ...(!description && { mb: 1 }) }} component={"h6"}>
						{name}
					</Typography>
					{description && (
						<Typography component={"p"} variant="Text/md/Regular">
							{description}
						</Typography>
					)}
				</Box>
			</Box>
		</CardItem>
	);
};

function ArticleList({ section_id }) {
	const { articles, loadArticles, listLoading } = useContext(ResourceCenterContext);

	useEffect(() => {
		loadArticles();
	}, []);

	useEffect(() => {
		handleMixPanel("Section Viewed", { "Section id": section_id })
	}, [])

	return (
		<Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
			<Typography component={"h2"} variant="Text/xl/Semibold">
				Articles
			</Typography>
			<Box
				sx={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					flexWrap: "wrap",
					gap: 2,
				}}
			>
				{listLoading ? (
					<ListSkeleton />
				) : (
					articles?.map((item, index) => (
						<ArticleItem key={item?.id} id={item?.id} name={item?.name} description={item?.description} timeout={50 + 50 * (index + 1)} />
					))
				)}
			</Box>
		</Box>
	);
}

export default ArticleList;
