import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";

import { USER_TYPES } from "../../../../utils/userTypes";

import { OnboardCard } from "../ui";

import EmpathyIcon from "../../Assets/empathy-icon.svg";
import EnergyIcon from "../../Assets/energy-icon.svg";
import ExecutionIcon from "../../Assets/execution-icon.svg";
import ExpertNetworkIcon from "../../Assets/expert-network-icon.svg";
import GritIcon from "../../Assets/grit-icon.svg";
import LeadershipIcon from "../../Assets/leadership-icon.svg";
import LuckIcon from "../../Assets/luck-icon.svg";
import SupervisionIcon from "../../Assets/supervision-icon.svg";

const SUPER_POWER_OPTIONS = [
	{ id: 0, icon: SupervisionIcon, title: "Super Vision", desc: "Exceptional ability to foresee opportunities, trends, and challenges." },
	{ id: 1, icon: ExpertNetworkIcon, title: "Networking", desc: "Extensive connections with professionals across various domains and industries." },
	{ id: 2, icon: ExecutionIcon, title: "Execution", desc: "Strong focus on turning ideas into tangible results with precision and efficiency." },
	{ id: 3, icon: LuckIcon, title: "Luck", desc: "Possesses an uncanny ability to attract opportunities and serendipitous outcomes." },
	{ id: 4, icon: GritIcon, title: "Grit", desc: "Perseverance, determination, and resilience in the face of challenges and setbacks." },
	{ id: 5, icon: LeadershipIcon, title: "Leadership", desc: "The capability to inspire, guide, and motivate others towards a common goal." },
	{ id: 6, icon: EmpathyIcon, title: "Empathy", desc: "The capability to inspire, guide, and motivate others towards a common goal." },
	{ id: 7, icon: EnergyIcon, title: "Passion", desc: "The capability to inspire, guide, and motivate others towards a common goal." },
];

export const SuperPower = ({ isNext, currentStep, data, userType, onActive, onUpdate }) => {
	const [selectedOption, setSelectedOption] = useState([]);

	const handleSuperPowerOption = (index) => {
		setSelectedOption(userType === USER_TYPES.STARTUP_OWNER ? [index] : [...index]);
		onActive(true);
	};

	useEffect(() => {
		if (data.superpower) {
			setSelectedOption(JSON.parse(data.superpower).map((superpower) => SUPER_POWER_OPTIONS.filter((option) => option.title === superpower)[0]?.id));
			onActive(true);
		}
	}, [data, userType]);

	useEffect(() => {
		if (isNext) {
			onUpdate(currentStep, {
				superpower: JSON.stringify(selectedOption.map((id) => SUPER_POWER_OPTIONS.filter((option) => option.id === id)[0].title)),
			});
		}
	}, [isNext, userType]);

	return (
		<Grid container spacing="30px">
			{SUPER_POWER_OPTIONS.map((account) => (
				<Grid key={account.id} item display="flex" justifyContent="center" xs={12} md={6} lg={4} xl={3}>
					<OnboardCard
						key={account.id}
						isLargeSpacing={false}
						id={account.id}
						title={account.title}
						desc={account.desc}
						icon={account.icon}
						selectedId={userType === USER_TYPES.STARTUP_OWNER ? selectedOption[0] : selectedOption}
						multiSelect={userType === USER_TYPES.STARTUP_OWNER ? false : true}
						onSelect={handleSuperPowerOption}
					/>
				</Grid>
			))}
		</Grid>
	);
};
