// /home/mindbowser/Desktop/Mindbowser/startupos-app-web/src/containers/Investor/Workspace/index.jsx
import React, { useEffect, useState } from "react";
import { Box, ListItemText, MenuItem, OutlinedInput, Select, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";

import DashboardLayout from "./Layout";
import WorkspaceNavbar from "./Navbar";
import { PAGE_HOME_ICON } from "../../../../../constants";
import HomeTourIcon from "../../../../../components/Investor/Workspace/Assets/new-home-tour.png";
import { TourTooltip } from "../../../../../components/Profile/ProfileContent";
import NewSingleSelect from "../../../../../common/NewSingleSelect";
import { useDispatch, useSelector } from "react-redux";
import services from "../../../../../services";
import localStorage from "redux-persist/es/storage";
import { removeFromLocalStorage, retrieveFromLocalStorage } from "../../../../../constants/Theme/ThemeContext";
import { toastContainer } from "../../../../../utils/toast";
import { verifyInvitationToken } from "../../../../Networking/Functions/common";
import { StyledSelect } from "../../../../../common/UpdatedSelectField/style";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { useTheme } from "styled-components";
import UpdatedSelectInput from "../../../../../common/UpdatedSelectInput";
import { setCommunityPartners, setEmailToken } from "../../../../../modules/actions";

export default function WorkspaceView({ activeKey, content }) {
	const emailToken = retrieveFromLocalStorage("emailToken");
	const routeAfterLogin = retrieveFromLocalStorage("routeAfterLogin");
	const [menuOpen, setMenuOpen] = useState(false);
	const showCommunityPartners = useSelector((state) => state?.communityPartners?.showCommunityPartners);
	const showCommunityPartnersOptions = useSelector((state) => state?.communityPartners?.communityPartners);
	const selectedOption = useSelector((state) => state?.communityPartners?.selectedOption);
	const [selectedSort, setSelectedSort] = useState(selectedOption);

	const dispatch = useDispatch();
	const ITEM_HEIGHT = 30;
	const ITEM_PADDING_TOP = 8;
	const MenuProps = {
		PaperProps: {
			style: {
				maxHeight: ITEM_HEIGHT * 7 + ITEM_PADDING_TOP,
				borderRadius: "0px 0px 40px 40px",
				boxShadow: "0px 5px 24px rgba(0, 0, 0, 0.15)",
			},
		},
	};

	const handleChange = (e) => {
		dispatch(setCommunityPartners({ selectedOption: e.target.value }));
		setSelectedSort(e.target.value);
	};

	const handleMenuOpen = () => {};

	const history = useHistory();
	useEffect(() => {
		if (emailToken) {
			verifyInvitationToken(emailToken);
		}
		if (emailToken && routeAfterLogin) {
			history.push(routeAfterLogin);
		}
	}, []);

	return (
		<Box
			sx={{
				px: {
					lg: 8,
					md: 4,
					xs: 2,
				},
				pb: "30px",
				display: "flex",
				flexDirection: "column",
				gap: "32px",
				maxWidth: "1920px", // Figma specifies max content width
				margin: "0 auto",
			}}
		>
			<Box display="flex" alignItems="center">
				{/* <GridViewIcon sx={{ fontSize: 32 }} /> */}
				<Box sx={{ width: "30px" }} component={"img"} src={PAGE_HOME_ICON} alt="home" />
				<Typography variant="growth_card_subtitle" color="secondary.black" sx={{ marginLeft: 1, marginRight: 1 }}>
					Home
				</Typography>
				<TourTooltip title="Click to restart the tour" placement="top-end">
					<Box sx={{ width: "50px", cursor: "pointer" }} component={"img"} src={HomeTourIcon} alt="tour" />
				</TourTooltip>
				{showCommunityPartners && (
					<Box width={"180px"} ml={2}>
						<UpdatedSelectInput
							onInputChange={handleChange}
							MenuProps={MenuProps}
							selectedValue={selectedSort}
							handleMenuOpen={handleMenuOpen}
							handleMenuClose={handleMenuOpen}
							wrapperstyle={{
								"& .MuiInputBase-root": {
									height: "auto",
									boxShadow: "0px 3px 11px rgba(0, 0, 0, 0.05)",
									borderRadius: menuOpen ? "40px 40px 0px 0px" : "40px",
									fontSize: "16px",
									fontFamily: '"PoppinsSemiBold", "Helvetica", "Arial", sans-serif',
									background: (theme) => theme.palette.secondary.white,
									width: "216px",
								},
								"& .MuiOutlinedInput-notchedOutline": {
									border: "none",
								},
								"& .MuiOutlinedInput-input": { pl: 3 },
								width: 1,
							}}
							renderValue={(selected) => {
								console.log(`selected`, selected);
								if (!selected?.length > 0) {
									return (
										<Box sx={{ my: 0, py: 0 }} display={"flex"}>
											<Typography
												variant="poppinsMedium16"
												sx={{
													lineHeight: "19px",
													textOverflow: "ellipsis",
													whiteSpace: "nowrap",
													overflow: "hidden",
												}}
												color="secondary.black"
											>
												Select Community
											</Typography>
										</Box>
									);
								}

								return (
									<Box sx={{ display: "flex" }} alignItems="center">
										<Typography
											variant="poppinsMedium16"
											sx={{
												lineHeight: "19px",
												textOverflow: "ellipsis",
												whiteSpace: "nowrap",
												overflow: "hidden",
											}}
											color="secondary.black"
										>
											{selected}
										</Typography>{" "}
									</Box>
								);
							}}
							inputProps={{ "aria-label": "Without label" }}
						>
							{showCommunityPartnersOptions.map((option) => (
								<MenuItem
									display={"flex"}
									alignItems="center"
									key={option}
									value={option}
									sx={{
										py: 1,
										px: 2,
										"&.Mui-selected": {
											backgroundColor: "rgba(106, 117, 117, 0.08)",
										},
									}}
									onClick={() => {}}
								>
									<ListItemText sx={{ ml: 3 }} primary={<Typography variant="custom070">{option}</Typography>} />{" "}
								</MenuItem>
							))}
						</UpdatedSelectInput>
					</Box>
				)}
			</Box>

			<DashboardLayout topbar={<WorkspaceNavbar activeKey={activeKey} />} main={<Box>{content}</Box>} isFull={true} />
		</Box>
	);
}
