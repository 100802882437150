import React from "react";
import { FormControl, Select, MenuItem, InputLabel, Box, TextField } from "@mui/material";

export default function RoundedSelectInput({
	label,
	onInputChange,
	selectedValue,
	fullWidth,
	options,
	inputKey,
	placeholder,
	labelStyle = {},
	borderNone = false,
	children,
	labelId = "select-label",
	id = "demo-simple-select",
	displayEmpty = false,
	compact = false,
	multiple = false,
	height,
	MenuProps,
	...props
}) {
	return (
		<FormControl fullWidth={fullWidth} sx={{ ...props.wrapperstyle }}>
			{label && (
				<InputLabel id="select-label" sx={labelStyle}>
					{label}
				</InputLabel>
			)}
			<Select
				multiple={multiple}
				displayEmpty
				placeholder={placeholder}
				labelId={labelId}
				id={id}
				label={label}
				fullWidth={fullWidth}
				select
				onChange={(e) => onInputChange(e, inputKey)}
				value={selectedValue || []}
				{...props}
				sx={{
					width: compact ? "max-content" : 1,
					borderRadius: "30px",
					height: height,
					"& .MuiOutlinedInput-notchedOutline": {
						border: borderNone && "none",
					},
				}}
				MenuProps={{
					sx: {
						".MuiMenu-paper": { boxShadow: (theme) => theme.palette.secondary.selectNoOutlineShadow },
					},
					...MenuProps
				}}
			>
				{children
					? children
					: options.map((option) => (
							<MenuItem key={option} value={option}>
								{option}
							</MenuItem>
					  ))}
			</Select>
		</FormControl>
	);
}
