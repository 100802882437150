import React, { useState, useEffect } from "react";
import { Typography, CardContent, Box, Card, Grid, Divider, useMediaQuery, Button } from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { sosPrimary } from "../../../../constants/Theme/colors";
import theme from "../../../../constants/Theme";
import { TableHeader, TableCell, TableRow } from "./styles";
import { Link, useHistory } from "react-router-dom";
import { getCurrentDate } from "../../../../utils/date";
import services from "../../../../services";
import ROUTES_PATH from "../../../../constants/routes";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { CircularProgress } from "@mui/material";

const PersonaWorkCard = ({ powerUpId }) => {
	const [isAscending, setIsAscending] = useState(false);
	const [isDateAscending, setIsDateAscending] = useState(false);
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
	const [versionsList, setVersionsList] = useState([]);
	const [filter, setFilter] = useState("lastModifiedAt");
	const [pageData, setPageData] = useState({});
	const history = useHistory();
	const [page, setPage] = useState(1);
	const [loading, setLoading] = useState(false);

	const getInCompletedWorksApiCall = () => {
		let sort = "ASC";
		if (filter === "name") {
			sort = isAscending ? "ASC" : "DESC";
		} else {
			sort = isDateAscending ? "ASC" : "DESC";
		}
		setLoading(true);
		services
			.getMyWorkDetails(powerUpId, page, sort, filter, false, true, 3)
			.then((result) => {
				setLoading(false);
				const data = result?.data?.data;
				// setPageData({
				// 	totalPages: data?.totalPages || 0,
				// 	totalElements: data.totalElements || 0,
				// 	isFirst: data.isFirst,
				// 	isLast: data.isLast,
				// });
				const filteredData = data.filter((item) => !item.isArchived && item?.isCompleted);
				setVersionsList(filteredData || []);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	useEffect(() => {
		getInCompletedWorksApiCall();
	}, [isAscending, isDateAscending, page]);

	// const prevPage = () => {
	// 	if (!pageData.isFirst) {
	// 		setPage(page - 1);
	// 	}
	// };

	// const nextPage = () => {
	// 	if (!pageData.isLast) {
	// 		setPage(page + 1);
	// 	}
	// };

	return (
		<Box>
			<Card
				sx={(theme) => ({
					//boxShadow: theme.palette.primary.elevationLight2,
					p: 1,
					border: `1px solid ${theme.palette.secondary.outline2}`,
					borderRadius: "4px",
				})}
			>
				<CardContent>
					<Box
						sx={{
							display: "flex",

							alignItems: "center",
							justifyContent: "space-between",
						}}
					>
						<Typography variant="largeTitle" color={theme.palette.text.secondary}>
							Completed Work
						</Typography>
						<Box>
							<Button
								variant="nofill"
								color="primary"
								sx={{
									width: "max-content",
									whiteSpace: "nowrap",
								}}
								onClick={() =>
									history.push({
										pathname: ROUTES_PATH.STARTUPS_HOME_WORK_HISTORY,
										state: {
											showVersionsPage: true,
											powerupId: powerUpId,
										},
									})
								}
							>
								View all{" "}
							</Button>
						</Box>
					</Box>

					<Box>
						<Grid item xs={12} md={12} xl={12} sx={{ width: "100%", mt: 3 }}>
							<TableHeader>
								<TableCell
									sx={{ display: "flex", alignItems: "center" }}
									onClick={() => {
										setFilter("name");
										setIsAscending(!isAscending);
									}}
								>
									<Typography variant="title_small">Name</Typography>
									<Box
										sx={{
											ml: 1,
											cursor: "pointer",
											display: "flex",
											alignItems: "center",
										}}
									>
										{isAscending ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />}
									</Box>
								</TableCell>

								<TableCell
									sx={{ display: "flex", alignItems: "center" }}
									onClick={() => {
										setFilter("lastModifiedAt");
										setIsDateAscending(!isDateAscending);
									}}
								>
									<Typography variant="title_small">Last modified</Typography>
									<Box
										sx={{
											ml: 1,
											cursor: "pointer",
											display: "flex",
											alignItems: "center",
										}}
									>
										{isDateAscending ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />}
									</Box>
								</TableCell>
							</TableHeader>
							<Divider
								sx={{
									mt: 2,
									background: `${theme.palette.secondary.outlineXLight}`,
								}}
							/>
						</Grid>
						{!loading ? (
							<Grid item xs={12} md={12} sx={{ width: 1 }}>
								{versionsList?.map((item, index) => {
									if (index < 11 && item) {
										return (
											<Box key={index}>
												<TableRow>
													<Box
														sx={{
															py: 1,
														}}
													>
														<Typography variant="bodyLarge" sx={{ color: "secondary.black" }}>
															{item.name}
														</Typography>
													</Box>
													<Box sx={{ py: 1 }}>
														<Typography variant="bodyLarge">{getCurrentDate(new Date(item.lastModifiedAt))}</Typography>
													</Box>
												</TableRow>
												<Divider
													sx={{
														background: `${theme.palette.secondary.outlineXLight}`,
													}}
												/>
											</Box>
										);
									}
								})}
							</Grid>
						) : (
							<Grid Grid item xs={12} md={12} sx={{ padding: theme.spacing(1), width: 1, mt: 2 }}>
								<CircularProgress
									sx={{
										display: "flex",
										justifyContent: "center",
										maxWidth: 1,
										px: 3,

										ml: "auto",
										mr: "auto",
									}}
								/>
							</Grid>
						)}
					</Box>

					<Box sx={{ mt: 4, display: "flex", justifyContent: "flex-end", gap: 1 }}>
						<ArrowBackIosIcon
							sx={{
								cursor: "pointer",
								color: pageData.isFirst ? "secondary.outlineXLight" : "text.secondary",
							}}
							// onClick={prevPage}
						/>
						<ArrowForwardIosIcon
							sx={{
								cursor: "pointer",
								color: pageData.isLast ? "secondary.outlineXLight" : "text.secondary",
							}}
							// onClick={nextPage}
						/>
					</Box>
				</CardContent>
			</Card>
		</Box>
	);
};

export default PersonaWorkCard;
