import React, { createContext, useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Button, Stack } from "@mui/material";
import { useLazyQuery } from "@apollo/client";
import AboutMeSection from "./AboutMeSection";
import TagsAboutMeSection from "./TagsAboutMeSection";
import services from "../../../services";
import { setUserProfileData } from "../../../modules/actions/userProfileActions";
import { toastContainer } from "../../../utils/toast";
import messages from "../../../constants/messages";
import { getYear } from "../../../utils/date";
import { getMonth } from "../../../utils/date";
import CopyFromLinkedinConfirmation from "../../UpdatedMentorProfile/MyDetailsSection/CopyFromLinkedinConfirmation";
import { setAuthData } from "../../../modules/actions";
import ProfileCoPilot from "../../ProfileCoPilot";
import { GET_FOUNDER_DATA_QUERY } from "../../Profile/Stores/Function/common";

const MyDetailsTabContext = createContext();

const InvestorDetailsTab = ({ handleClose }) => {
	const dispatch = useDispatch();
	const userDetails = useSelector((state) => state?.userProfileData?.userData);
	const authData = useSelector((state) => state?.auth);

	const [payLoad, setPayLoad] = useState({ ...userDetails });
	const [isLoading, setIsLoading] = useState(false);
	const [isLoadingLinkedInData, setLoadingLinkedInData] = useState(false);
	const [openHarmonic, setOpenHarmonic] = useState(false);
	const userId = userDetails?.id;

	const [loadUserData, { loading, error, data }] = useLazyQuery(GET_FOUNDER_DATA_QUERY, {
		errorPolicy: "all",
		fetchPolicy: "no-cache",
	});

	const [errors, setErrors] = useState({
		firstNameError: "",
		lastNameError: "",
	});

	useEffect(() => {
		if (loading) {
			dispatch(
				setUserProfileData({
					loading: loading,
				})
			);
		} else {
			if (!error && data) {
				if (!userId) {
					dispatch(
						setAuthData({
							userData: { picture: data?.userById?.picture },
							userName: data?.userById?.name,
						})
					);
				}
				dispatch(
					setUserProfileData({
						loading: loading,
						userData: data?.userById,
					})
				);
				setPayLoad(data?.userById);
			} else {
				console.log(error);
			}
		}
	}, [loading]);

	const isValidForm = () => {
		return !!payLoad?.firstName && !!payLoad?.lastName;
	};

	const handleUpdateUser = () => {
		setIsLoading(true);
		let payloadData = payLoad ? payLoad : userDetails;

		const getLinkedInUrl = (url) => {
			if (url) {
				if (url.match(/^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(in\/)/gm)) {
					let cleanedLinkedInUrl = url.replace(/^(https:\/\/www\.linkedin\.com\/in\/)(.*)https:\/\/www\.linkedin\.com\/in\//, "$1$2");

					return cleanedLinkedInUrl;
				} else {
					return "https://www.linkedin.com/in/" + url;
				}
			} else {
				return null;
			}
		};

		services
			.updateUserProfile({
				...payloadData,
				phone: payLoad.phone,
				linkedInUrl: getLinkedInUrl(payloadData.linkedInUrl),
			})
			.then((response) => {
				setIsLoading(false);
				if (response.data.message === "Success") {
					dispatch(
						setUserProfileData({
							userData: response?.data?.data,
						})
					);
				}
			})
			.catch((e) => {
				console.log(e);
				setIsLoading(false);
			});
	};
	const handleAISave = async (aiData, eventType) => {
		let payloadData = null;

		if (eventType && eventType == "ai_suggestions") {
			payloadData = payLoad;
			payloadData = {
				...payloadData,
				about: aiData?.about ? aiData?.about : payloadData?.about,
				headline: aiData?.headline ? aiData?.headline : payloadData?.headline,
			};
		} else {
			payloadData = aiData ? aiData : payLoad;
		}

		return services
			.updateUserProfile({
				...payloadData,
				phone: payLoad.phone,
				linkedInUrl: getLinkedInUrl(payloadData.linkedInUrl),
			})
			.then((response) => {
				if (response.data.message === "Success") {
					dispatch(
						setUserProfileData({
							userData: response.data.data,
						})
					);
					if (eventType === "ai_suggestions") {
						handleClose();
					}

					dispatch(
						setAuthData({
							userData: { picture: response.data.data.picture },
							userName: response.data.data.name,
						})
					);
					setPayLoad(response.data.data);
					return response;
				}
			})
			.catch((e) => {
				console.log("Submit", e);
				toastContainer(messages.API_SUBMIT_ERROR, "error");
			});
	};
	const deleteExperienceApiCall = () => {
		return services
			.deleteAllMentorExperience()
			.then((result) => {
				if (result.data.code === 200) {
					return true;
				}
			})
			.catch((error) => {
				console.log(error);
				throw error;
			});
	};

	const deleteEducationApiCall = () => {
		return services
			.deleteAllMentorEducation()
			.then((result) => {
				if (result.data.code === 200) {
					return true;
				}
			})
			.catch((error) => {
				console.log(error);
				throw error;
			});
	};

	const handleSaveExp = (data) => {
		let payload = {
			title: data?.title,
			companyName: data?.companyName,
			location: data?.location,
			startMonth: parseInt(data?.startMonth),
			startYear: parseInt(data?.startYear),
			endMonth: parseInt(data?.endMonth),
			endYear: parseInt(data?.endYear),
		};

		//return true;
		return services
			.addMentorExperience(payload)
			.then((result) => {
				if (result.data.code === 200) {
				}
			})
			.catch((error) => {
				throw error;
			});
	};

	const handleSaveEdu = (data) => {
		let payload = {
			course: data?.course,
			university: data?.university,
			location: data?.location,
			startYear: parseInt(data?.startYear),
			endYear: parseInt(data?.endYear),
		};

		return services
			.addMentorEducation(payload)
			.then((result) => {
				if (result.data.code === 200) {
					return true;
				}
			})
			.catch((error) => {
				throw error;
			});
	};
	function getLinkedInUrl(url) {
		if (url) {
			if (url.includes("https://www.linkedin.com/in/")) {
				let cleanedLinkedInUrl = url.replace(/^(https:\/\/www\.linkedin\.com\/in\/)(.*)https:\/\/www\.linkedin\.com\/in\//, "$1$2");
				return cleanedLinkedInUrl;
			} else {
				return "https://www.linkedin.com/in/" + url;
			}
		} else {
			return null;
		}
	}
	const updateWithHarmonicData = async (harmonicData) => {
		let data = { ...payLoad };
		let location = harmonicData?.location?.location.split(", ");

		if (harmonicData?.experience && harmonicData?.experience?.length > 0) {
			await deleteExperienceApiCall();
		}
		if (harmonicData?.education && harmonicData?.education?.length > 0) {
			await deleteEducationApiCall();
		}

		if (harmonicData?.first_name || harmonicData?.last_name) {
			data = {
				...data,
				firstName: harmonicData?.first_name,
				lastName: harmonicData?.last_name,
			};
		} else {
			let nameArr = harmonicData?.full_name?.split(" ");
			data = {
				...data,
				firstName: nameArr[0],
				lastName: nameArr[2] ? nameArr[2] : nameArr[1],
			};
		}
		if (harmonicData?.full_name) {
			data = {
				...data,
				name: harmonicData?.full_name,
			};
		}
		if (harmonicData?.profile_picture_url) {
			data = {
				...data,
				picture: harmonicData?.profile_picture_url,
			};
		}
		if (harmonicData?.location?.city) {
			data = {
				...data,
				city: harmonicData?.location?.city,
			};
		}
		if (harmonicData?.location?.state) {
			data = {
				...data,
				state: harmonicData?.location?.state,
			};
		}
		if (harmonicData?.location?.zip) {
			data = {
				...data,
				zipcode: harmonicData?.location?.zip,
			};
		}

		if (harmonicData?.contact?.phone_numbers.length > 0) {
			data = {
				...data,
				phone: harmonicData?.contact?.phone_numbers?.[0],
			};
		}

		if (harmonicData?.education) {
			let educationDetails = [];
			harmonicData?.education.map((item) => {
				const eduObj = {};
				if (item?.degree) {
					eduObj["course"] = item?.degree;
				}
				if (item?.school?.name) {
					eduObj["university"] = item?.school?.name;
				}
				if (item?.start_date) {
					eduObj["startYear"] = getYear(item.start_date);
				}
				if (item?.end_date) {
					eduObj["endYear"] = getYear(item.end_date);
				}

				educationDetails.push(eduObj);
				handleSaveEdu(eduObj);
			});
			data.educationDetails = educationDetails;
		}

		if (harmonicData?.experience) {
			let experienceDetails = [];
			harmonicData?.experience.map((item) => {
				const expObj = {};

				if (item?.is_current_position) {
					data[`profession`] = item?.title;
				}
				if (item.title) {
					expObj["title"] = item.title;
				}
				if (item.company_name) {
					expObj["companyName"] = item.company_name;
				}
				if (item.location) {
					expObj["location"] = item.location;
				}
				if (item.start_date) {
					expObj["startYear"] = getYear(item.start_date);
					expObj["startMonth"] = getMonth(item?.start_date);
				}
				if (item.end_date) {
					expObj["endYear"] = item.end_date ? getYear(item.end_date) : "";
					expObj["endMonth"] = item.end_date ? getMonth(item?.end_date) : "";
				}
				experienceDetails.push(expObj);
				handleSaveExp(expObj);
			});
			data.experienceDetails = experienceDetails;
		}

		setPayLoad(data);
		dispatch(
			setUserProfileData({
				userData: data,
			})
		);
		await services
			.updateUserProfile({
				...data,
				linkedInUrl: getLinkedInUrl(data.linkedInUrl),
			})
			.then((response) => {
				if (response.data.message === "Success") {
					loadUserData({
						variables: { id: null },
					});
				}
			})
			.catch((e) => {
				console.log(e);
			});
	};

	const handleGetHarmonicData = async () => {
		let payloadData = payLoad ? payLoad : userDetails;

		if (payloadData?.linkedInUrl) {
			setLoadingLinkedInData(true);
			services
				.getHarmonicsController({
					userId: payloadData?.id,
					linkedinUrl: getLinkedInUrl(payloadData?.linkedInUrl),
				})
				.then(async (res) => {
					if (res?.data?.data.harmonicData) {
						await updateWithHarmonicData(JSON.parse(res?.data?.data.harmonicData));
						toastContainer("Linkedin data updated successfully.", "Suceess");
						setLoadingLinkedInData(false);
						handleCloseConfirmation();
					} else if (!res?.data?.data.harmonicData) {
						toastContainer(messages.LINKEDIN_ERROR);
						setLoadingLinkedInData(false);
						handleCloseConfirmation();
					}
				})
				.catch((err) => {
					console.log(err);
					if (err?.response?.data?.code === 429) {
						toastContainer("Too many requests.", "error");
					}
					setLoadingLinkedInData(false);
					handleCloseConfirmation();
				});
		}
	};

	const handleCloseConfirmation = () => {
		setOpenHarmonic(false);
	};

	return (
		<MyDetailsTabContext.Provider
			value={{
				payLoad,
				setPayLoad,
				handleUpdateUser,
				setOpenHarmonic,
				errors,
				setErrors,
			}}
		>
			<Stack direction={"column"} spacing={7.5}>
				<AboutMeSection context={MyDetailsTabContext} />
				<TagsAboutMeSection context={MyDetailsTabContext} />
				<Box
					sx={{
						display: "flex",
						gap: 2,
						justifyContent: "end",
						alignItems: "center",
					}}
				>
					<Button
						onClick={handleClose}
						variant="noOutlineNew"
						sx={{
							height: "40px",
							m: 0,
							width: "fit-content",
							fontSize: "18px",
							lineHeight: "28px",
							fontFamily: "PoppinsSemiBold",
						}}
					>
						Cancel
					</Button>

					<ProfileCoPilot
						handleSave={handleUpdateUser}
						handleAISave={handleAISave}
						saveLoading={isLoading}
						saveDisabled={!isValidForm()}
						handleCloseAI={handleClose}
					/>
				</Box>
			</Stack>
			<CopyFromLinkedinConfirmation
				open={openHarmonic}
				handleCloseConfirmation={handleCloseConfirmation}
				handleGetHarmonicData={handleGetHarmonicData}
				isLoading={isLoadingLinkedInData}
			/>
		</MyDetailsTabContext.Provider>
	);
};

export default InvestorDetailsTab;
