import { Box, Grid, Typography } from "@mui/material";
import React, { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { ProfileContext } from "../..";
import { TEAM_ICON_V2, USERS_THREE_ICON } from "../../../../constants";
import SvgComponent from "../../../../utils/SVGColor";
import { StyledProfileCard } from "../../style";
import InviteTeamButton from "../InviteTeamButton";
import TeammateCardItem from "./TeammateCardItem";
import services from "../../../../services";
import StatusButton from "../StatusButton";
import ROUTES_PATH from "../../../../constants/routes";

function TeamCard() {
	const history = useHistory();
	const { profileData, teamArray, viewContext } = useContext(ProfileContext);
	const [details, setDetails] = useState([]);

	const handleHideActionApiCall = (payload) => {
		services
			.updateCompanyUserHideStatusApiCall(payload)
			.then((response) => {
				if (response.data.message === "Success") {
					console.log("Success");
				}
			})
			.catch((e) => {
				console.log("Error", e);
			});
	};
	const handleEyeIconAction = (isHidden, id) => {
		const detailsList = [...teamArray];
		detailsList.map((item) => {
			if (item.id === id) {
				return (item.isHidden = isHidden);
			}
		});
		let payload = {
			flag: isHidden,
			userId: id,
		};
		handleHideActionApiCall(payload);
		setDetails(detailsList);
	};

	const handleRedirection = () => {
		if (viewContext?.component === "startup") {
			history.push(ROUTES_PATH.EDIT_MY_STARTUP_PROFILE);
		}
	};

	return (
		<StyledProfileCard>
			{/* {!viewContext?.id && viewContext?.component === "startup" && (
				<Box mb={4.5}>
					<StatusButton percentage={progressCompletionCalculator(totalFields, completedFields)} handleRedirection={handleRedirection} />
				</Box>
			)} */}
			<Box
				sx={{
					display: "flex",
					alignItems: "center",
					justifyContent: "space-between",
					flex: 1,
					mb: "20px",
				}}
			>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						gap: "12px",
					}}
				>
					<Box component={"img"} src={TEAM_ICON_V2} sx={{ height: "24px", width: "24px" }} />
					<Typography
						variant="Text/sm/Semibold"
						component={"div"}
						sx={{
							color: "black",
						}}
					>
						Meet the Team [{teamArray?.length ? teamArray?.filter((item) => (viewContext?.isEditable ? item : !item?.isHidden))?.length : 0}]
					</Typography>
				</Box>
				{viewContext?.component === "startup" && viewContext?.isEditable && (
					<Box>
						<InviteTeamButton />
					</Box>
				)}
			</Box>

			<Grid container spacing={4}>
				{teamArray?.length > 0 &&
					teamArray
						?.filter((item) => (viewContext?.isEditable ? item : !item?.isHidden))
						?.map((teamItem, index) => {
							return (
								<Grid item xs={12} sm={12} md={4} lg={4} xl={3}>
									<TeammateCardItem teamItem={teamItem} key={index} handleEyeIconAction={handleEyeIconAction} />
								</Grid>
							);
						})}
			</Grid>
		</StyledProfileCard>
	);
}

export default TeamCard;
