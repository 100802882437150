import React from "react";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import { get } from "lodash";
import { convertNumberToLocale } from "../Functions/common";

function DisplayLastestParams({ latestArributes = {}, type = "count" }) {
	const theme = useTheme();
	const colors = [
		theme.palette.primary.main,
		theme.palette.bidashboard.paramLightBlue,
		theme.palette.bidashboard.paramLightGreen,
		theme.palette.bidashboard.paramRed,
		theme.palette.bidashboard.paramBlue,
		theme.palette.bidashboard.paramOrange,
	];
	const params = Object.keys(latestArributes);

	return (
		<Grid container columnSpacing={2} rowSpacing={1}>
			{params.map((key, index) => (
				<Grid item key={key} xs={12} lg={6} display="flex" alignItems="center">
					<Box sx={{ width: "12px", height: "12px", borderRadius: "50%", bgcolor: colors[index] }} />
					<Typography flex={1} ml={1} noWrap variant="BIChartSmallText" color={theme.palette.bidashboard.cardLatestParamsText}>
						{key ? key : ""}
					</Typography>
					<Typography ml={1} alignSelf="flex-end" variant="BIChartSmallText" color="secondary.black">
						{get(latestArributes, `${key}`, null) !== null
							? type === "currency"
								? `$${convertNumberToLocale(get(latestArributes, `${key}`, ""))}`
								: `${convertNumberToLocale(get(latestArributes, `${key}`, ""))}`
							: ""}
					</Typography>
				</Grid>
			))}
		</Grid>
	);
}

export default DisplayLastestParams;
