import services from "../../../services";

//team members list
export const getTeamMembersDetails = async page => {
  return services
    .getTeamMembersDetails(page, 5)
    .then(response => {
      return response;
    })
    .catch(error => console.log(error));
};
