import React from "react";
import { Box, Typography, Avatar } from "@mui/material";

export const HomeSectionTitle = ({ icon, title, count = 0 }) => {
	return (
		<Box display="flex" alignItems="center" gap={1.5} mb={2}>
			<img src={icon} alt={title} />
			<Typography variant="main_title">{title}</Typography>
			{count > 0 && (
				<Avatar sx={{ bgcolor: "#000", width: "30px", height: "30px" }}>
					<Typography variant="on_surface">{count}</Typography>
				</Avatar>
			)}
		</Box>
	);
};
