import { Svg } from "./Svg";

export const Help1Icon = ({ className, fill = "#667085", width = 30, height = 24 }) => (
	<Svg className={className} width={width} height={height} viewBox="0 0 30 24" fill="none">
		<path
			d="M14.9998 1V3.66667M6.08532 6.75215L4.19971 4.86654M23.9142 6.75215L25.7998 4.86654M6.99984 17C6.99984 12.5817 10.5816 9 14.9998 9C19.4181 9 22.9998 12.5817 22.9998 17M28.3332 17H1.6665M24.3332 22.3333H5.6665"
			stroke={fill}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</Svg>
);
