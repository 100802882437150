import {
  VALUE_PROPOSITION,
  AVATAR_DEFAULT,
  AVATAR_1,
  AVATAR_2,
  AVATAR_3,
  AVATAR_4,
  AVATAR_5,
  AVATAR_6,
  AVATAR_7,
  AVATAR_8,
  AVATAR_9,
  AVATAR_10,
  AVATAR_11,
  AVATAR_12,
  AVATAR_13,
  AVATAR_14,
  AVATAR_15,
  AVATAR_16,
  AVATAR_17,
  AVATAR_18,
  PHONE_ICON,
  EMAIL_ICON,
  TEXT_ICON,
  LIKE_ICON,
  SMILE_ICON,
  SVB_PARTNER,
  ADVANCE_PARTNER,
  GUSTO_PARTNER,
  STARTEGY_PARTNER,
  ICONIC_TOY_ICON,
  MUSIC_DEVICE_ICON,
  SCREEN_CONTENT_ICON,
} from "./image";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import TextsmsOutlinedIcon from "@mui/icons-material/TextsmsOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import MoodOutlinedIcon from "@mui/icons-material/MoodOutlined";

export const POWERUP_HEADERS = [
  {
    key: "pitchPerfect",
    label: "Pitch Perfect",
    containerHeading: "Build an Oscar-worthy pitch deck",
    infoText:
      "A pitch deck is a brief presentation that provides investors with an overview of your business. It’s usually focused on showcasing your product, sharing your business model, giving a look into your monetization strategy, and introducing your team. It’s an essential fundraising tool and creating a pitch deck that wins investment is not an easy task.\n\n But don’t worry. You’re about to learn how to nail it.",
  },
  {
    key: "mvp/mlp",
    label: "MVP/MLP",
    containerHeading: "Launch your MVP/MLP (Minimum Lovable Product)",
    infoText:
      "A Minimum Lovable Product (MLP) is an initial offering that users love from the start. It represents the minimum that is required for customers to adore a product, rather than merely tolerating it.\n\n MLP thinking builds upon the concept of the MVP, which aims to create the most basic version of a product with just enough features to be usable. Start these PowerUps to ensure your product is lovable on day one.",
  },
  {
    key: "investorMeeting",
    label: "Investor Meeting",
    containerHeading: "Crush your investor meeting",
    infoText:
      "An investor meeting is a presentation of your business idea to a venture capitalist or angel investor in hopes of obtaining their funding. These meetings are increasingly foundational to startup success. Long after you secure your funding, you can also expect to hold regular meetings with your investors. These meetings typically cover how your company is performing, future plans and projections, and how the invested money is being spent.\n\n We have the perfect plan as you prepare to meet with your potential investor.",
  },
  {
    key: "productHuntDebut",
    label: "Product Hunt Debut",
    containerHeading: "Product Hunt Debut",
    infoText:
      "The Product Hunt community loves new things, so it's a great audience to share your new product with. Posting an upcoming product on Product Hunt is a great way to get valuable feedback which will help you perfect your offering and build awareness in the lead up to your beta and market launch.",
  },
  {
    key: "techCrunchDisrupt",
    label: "TechCrunch Disrupt",
    containerHeading: "Show It (TechCrunch Disrupt)",
    infoText:
      "TechCrunch Disrupt is an annual conference hosted by TechCrunch in San Francisco, New York, Berlin and London. The global startup community comes together to discover insights, collaborate, and celebrate achievements that have defined each founder's journey.\n\n If you plan on attending one of these events, our PowerUps will help you get ready to compete in front of venture capital investors and media to gain coaching and the chance to pitch a panel of top-tier investors on the Disrupt Stage.",
  },
];

export const POWERUP_ITEMS = [
  {
    title: "Value Proposition",
    subText:
      "Create a value proposition (aka an elevator pitch) that summarizes the value of your product in one sentence.",
    image: VALUE_PROPOSITION,
  },
  {
    title: "Storyteller",
    subText:
      "Everybody loves a good story. Start learing the basics of storytelling, and how stories can help in your startup journey.",
    image: VALUE_PROPOSITION,
  },
  {
    title: "Persona Builder",
    subText:
      "Learn about your future users and make a composite persona for them to look back to as you forge ahead.",
    image: VALUE_PROPOSITION,
  },
  {
    title: "Idea Validation Interview",
    subText:
      "Tie all the elements of an interview process together so your focus is on testing your idea.",
    image: VALUE_PROPOSITION,
  },
  {
    title: "Problem Solution Fit",
    subText: "Identify solutions with a higher chance of adoption.",
    image: VALUE_PROPOSITION,
  },
  {
    title: "Brandstorming",
    subText:
      "Create a detailed report on your existing or future brand in the form of a creative brief.",
    image: VALUE_PROPOSITION,
  },
  {
    title: "TAM, SAM, SOM",
    subText:
      "Calculate the market size for your venture and understand where your brand fits in the overall market.",
    image: VALUE_PROPOSITION,
  },
  {
    title: "F&F Fundraiser",
    subText:
      'Learn how to raise and spend "friends & family" money most efficiently.',
    image: VALUE_PROPOSITION,
  },
  {
    title: "Revenue Model",
    subText:
      "Decide how your startup business will generate revenue or income.",
    image: VALUE_PROPOSITION,
  },
];

export const VALUE_PROPOSITION_FORM_FLOW = [
  {
    formField: "customerDescription",
    displayNumber: "01",
    title: "Describe Your Customer",
    description: "",
  },
  {
    formField: "customerJobs",
    displayNumber: "02",
    title: "Customer Jobs",
    description: "",
  },
  {
    formField: "customerPains",
    displayNumber: "03",
    title: "Customer Pains",
    description: "",
  },
  {
    formField: "customerGains",
    displayNumber: "01",
    title: "Customer Gains",
    description: "",
  },
];

export const POWERUP_STATUS = {
  STARTED: "STARTED",
  READY: "READY",
};

export const VALUE_PROP_SIDEBAR = {
  sidebar_id: "string",
  powerup_id: "string",
  steps: [
    { name: "Step One", complete: true, link: "" },
    { name: "Step Two", complete: false, link: "" },
  ],
  tabs: [
    {
      name: "details",
      description:
        "Also known as an elevator pitch, your value proposition is a single sentence that summarizes why a customer should use your product or service.",
      powerup_attributes: [
        {
          id: "powerup_attribute_id_1",
          name: "Time Estimate",
          value: "30 min - 2 hours",
          icon: "time",
        },
        {
          id: "powerup_attribute_id_2",
          name: "Difficulty",
          value: "Easy",
          icon: "steps",
        },
        {
          id: "powerup_attribute_id_3",
          name: "Team Recommended",
          value: "Yes",
          icon: "person",
        },
      ],
    },
    {
      name: "Power Tips",
      html_content: `
        <Typography>Ingredients that go into a great value proposition:</Typography>
        <ul>
          <li><Typography>What problem are you solving for the customer?</Typography></li>
          <li><Typography>A list of all the benefits your product or service offers. Be specific.</Typography></li>
          <li><Typography>A description of what makes those benefits of value to your customer.</Typography></li>
          <li><Typography>How your product or service is different from others in the market, and why the customer should choose you.</Typography></li>
        </ul>
        `,
    },
    {
      name: "inspiration",
      media: [
        {
          id: "media_id_1",
          type: "video",
          title:
            "Dan Demsky, co-founder of Unbound Merino, teams up with Shopify to give an informative breakdown of what value propositions are, and how to identify your company’s own value prop.",
          content_url: "https://www.youtube.com/watch?v=ky67kLp1cOM",
        },
        {
          id: "media_id_2",
          type: "video",
          title:
            "In this video, Dan Olsen, author of The Lean Product Playbook, reviews Product/Solution fit and discusses how to identify and articulate the “secret sauce” of your product or service–the unique differentiator that is the foundation of your value proposition.",
          content_url: "https://www.youtube.com/watch?v=jStheDdShZg",
        },
      ],
    },
    //{
    //  name: "reviews",
    //  reviews: [
    //    //// Todo
    //  ],
    //},
  ],
};

export const STORY_TELLER_STEPS = [
  {
    id: 1,
    title: "Act One - Establishment",
    description:
      "Write down some ideas for how you want to start your story. This has grab your listeners attention! We’d recommend starting with the moment you came up with the idea, the moment you were faced with this problem. ",
    stepNo: 1,
    stepUuid: "",
  },
  {
    id: 2,
    title: "Act Two - Climax",
    description:
      "This is the moment you state your purpose: why people should be interested. Describe your product and how it addresses the problem you stated in Act One. ",
    stepNo: 2,
    stepUuid: "",
  },
  {
    id: 3,
    title: "Act Three - Resolution",
    description:
      "Where is your product going? This is where you can tie your listener in with the story, how can they be a part of your business.",
    stepNo: 3,
    stepUuid: "",
  },
];

export const STORY_TELLER_SIDEBAR = {
  sidebar_id: "string",
  powerup_id: "string",
  tabs: [
    {
      name: "details",
      description:
        "Use the Storyteller PowerUp to create a compelling story of your product in three acts.",
      powerup_attributes: [
        {
          id: "powerup_attribute_id_1",
          name: "Time Estimate",
          value: "30 min - 2 hours",
          icon: "time",
        },
        {
          id: "powerup_attribute_id_2",
          name: "Difficulty",
          value: "Medium",
          icon: "steps",
        },
        {
          id: "powerup_attribute_id_3",
          name: "Team Recommended",
          value: "Optional",
          icon: "person",
        },
        //{
        //  id: "powerup_attribute_id_4",
        //  name: "Feedback Alert!",
        //  value:
        //    "After completing this Power Up, it is highly recommended to seek feedback with your matched Founder. If revisions are needed after feedback, you can easily edit the work you have already completed.",
        //  icon: "info",
        //},
      ],
    },
    {
      name: "Power Tips",
      html_content: `
      <Typography>Three tips for better presentation:</Typography>
      <br></br>
      <Typography>Demonstrate you understand the problem at the beginning of the story. This can be done through personal or customer experience. It sets the stage for how you solve the problem.</Typography>
      <br></br>
      <Typography>The point of your pitch is to persuade people to invest in your product idea. Remember to show why and how you solve the customer problem.</Typography>
      <br></br>
      <Typography>Stick the ending. Make it clear how you solve the problem better than anyone in the market.</Typography>
      `,
    },
    {
      name: "inspiration",
      media: [
        {
          id: "media_id_1",
          type: "video",
          title:
            "There’s a reason this video has over 38M views–great storytelling never gets old.",
          content_url: "https://www.youtube.com/watch?v=MnrJzXM7a6o&t=3s",
        },
        {
          id: "media_id_2",
          type: "text",
          html_content: `
          <Typography>From TED: “How do the world's best leaders and visionaries earn trust? They don't just present data -- they also tell great stories. Leadership consultant Karen Eber demystifies what makes for effective storytelling and explains how anyone can harness it to create empathy and inspire action.”</Typography>
          <Button sx={{my:2}} href='https://www.ted.com/talks/lang/en/karen_eber_how_your_brain_responds_to_stories_and_why_they_re_crucial_for_leaders' target="_blank">Watch on TED.com</Button>
          `,
        },
      ],
    },
    //{
    //  name: "reviews",
    //  reviews: [
    //    //// Todo
    //  ],
    //},
  ],
};

export const PERSONA_BUILDER_SIDEBAR = {
  sidebar_id: "string",
  powerup_id: "string",
  tabs: [
    {
      name: "details",
      description:
        "Using this PowerUp, you’ll create user personas that will guide every facet of your startup, from design to marketing. Personas are foundational artifacts that grow and mature along with your company.",
      powerup_attributes: [
        {
          id: "powerup_attribute_id_1",
          name: "Time Estimate",
          value: "30 min - 2 hours",
          icon: "time",
        },
        {
          id: "powerup_attribute_id_2",
          name: "Difficulty",
          value: "Medium",
          icon: "steps",
        },
        {
          id: "powerup_attribute_id_3",
          name: "Team Recommended",
          value: "Optional",
          icon: "person",
        },
        //{
        //  id: "powerup_attribute_id_4",
        //  name: "Feedback Alert!",
        //  value:
        //    "After completing this Power Up, it is highly recommended to seek feedback with your matched Founder. If revisions are needed after feedback, you can easily edit the work you have already completed.",
        //  icon: "info",
        //},
      ],
    },
    {
      name: "Power Tips",
      html_content: `
        <Typography variant="title_small" sx={{mb: 1}} component="p">Tips for creating good personas:</Typography>
        <Typography  sx={{mb: 1}}>Make your persona well-rounded: Look beyond basic demographic information and dive into the psychographics as well, like activities, interests, values, opinions, and goals. What is their average day like? What kind of hobbies do they have? Where are their frustrations? The clearer the picture, the more useful your persona will be.</Typography>
        <Typography  sx={{mb: 1}}>Update your personas regularly: Your product will change, and your market will shift. Revisit your personas periodically to reflect your current users to assure that you are focusing your attention where it’s needed most. Retire any personas that are no longer relevant to your product journey.</Typography>
        <Typography  sx={{my: 1}}>Check your personal biases at the door: When developing your personas, stick to the data collected. Assumptions–even well-meaning ones–have the power to lead product development astray. Stick to what you can unequivocally prove.</Typography>
        `,
    },
    {
      name: "inspiration",
      media: [
        {
          id: "media_id_1",
          type: "video",
          title:
            "Gregg Bernstein provides a handily illustrated overview of personas, their data collection, and ways to map and synthesize this information to come up with useful artifacts.",
          content_url: "https://www.youtube.com/watch?v=B23iWg0koi8",
        },
        {
          id: "media_id_2",
          type: "video",
          title:
            "Kim Flaherty from the Nielsen/Norman Group on some of the common challenges you might encounter.",
          content_url: "https://www.youtube.com/watch?v=fal4Wylyt3M",
        },
      ],
    },
    //{
    //  name: "reviews",
    //  reviews: [
    //    //// Todo
    //  ],
    //},
  ],
};

export const TAMSAMSOM_SIDEBAR = {
  sidebar_id: "string",
  powerup_id: "string",
  tabs: [
    {
      name: "details",
      description:
        "Using this PowerUp, you’ll calculate the size of your market, the size of that market that you can realistically capture, and the overall market conditions that will determine your odds of success.",
      powerup_attributes: [
        {
          id: "powerup_attribute_id_1",
          name: "Time Estimate",
          value: "2 - 4 hours",
          icon: "time",
        },
        {
          id: "powerup_attribute_id_2",
          name: "Difficulty",
          value: "Hard",
          icon: "steps",
        },
        {
          id: "powerup_attribute_id_3",
          name: "Team Recommended",
          value: "Optional",
          icon: "person",
        },
        //{
        //  id: "powerup_attribute_id_4",
        //  name: "Feedback Alert!",
        //  value:
        //    "After completing this Power Up, it is highly recommended to seek feedback with your matched Founder. If revisions are needed after feedback, you can easily edit the work you have already completed.",
        //  icon: "info",
        //},
      ],
    },
    {
      name: "Power Tips",
      html_content: `
        <ul>
          <li><Typography>Be ready to explain how you calculated your TAM to your investor.</Typography></li>
          <li><Typography>Be ready to defend your Serviceable Market choice and how you plan to enter it.</Typography></li>
          <li><Typography>Don’t inflate obtainable market numbers. Be realistic about separating your product vision and what you can deliver in an MVP.</Typography></li>
        </ul>
      `,
    },
    {
      name: "inspiration",
      media: [
        {
          id: "media_id_1",
          type: "video",
          title:
            "In this video, Heini Zachariassen, founder of Vivino and host of Raw Startup, gives advice and walks through the fundamentals of how to market size your startup.",
          content_url: "https://www.youtube.com/watch?v=hcFUHzMkGBo&t=0s",
        },
      ],
    },
    //{
    //  name: "reviews",
    //  reviews: [
    //    //// Todo
    //  ],
    //},
  ],
};

export const PROBLEM_SOLUTION_SIDEBAR = {
  sidebar_id: "string",
  powerup_id: "string",
  tabs: [
    {
      name: "details",
      description:
        "This PowerUp helps you fine-tune your ideas and clearly articulate the problem your product will solve.",
      powerup_attributes: [
        {
          id: "powerup_attribute_id_1",
          name: "Time Estimate",
          value: "3-4 hours",
          icon: "time",
        },
        {
          id: "powerup_attribute_id_2",
          name: "Difficulty",
          value: "Easy",
          icon: "steps",
        },
        {
          id: "powerup_attribute_id_3",
          name: "Team Recommended",
          value: "Optional",
          icon: "person",
        },
        //{
        //  id: "powerup_attribute_id_4",
        //  name: "Feedback Alert!",
        //  value:
        //    "After completing this Power Up, it is highly recommended to seek feedback with your matched Founder. If revisions are needed after feedback, you can easily edit the work you have already completed.",
        //  icon: "info",
        //},
      ],
    },
    {
      name: "Power Tips",
      html_content: `
      <Typography>Is there a demand or unmet need that your idea can address in a unique way? Start by defining the problem, the symptoms that lead to it, and what if any solutions already exist in the marketplace.</Typography>
      <br></br>
      <Typography variant="sys_light_on_surface" component="p" sx={{mb:1}}>Example 1</Typography>
      <Typography><Typography variant="body_bold">Problem:</Typography> Finding a place to stay that fits my needs for a central location and budget constraints.</Typography>
      <Typography><Typography variant="body_bold">Symptoms:</Typography> I don’t know the area, I want to feel confident that I’ll be staying in the right place and that it fits my budget.</Typography>
      <Typography><Typography variant="body_bold">Existing alternatives:</Typography> Airbnb, Expedia, Hotel Tonight all offer options to help me make my decision.</Typography>
      <br></br>
      <Typography variant="sys_light_on_surface" component="p" sx={{mb:1}}>Example 1</Typography>
      <Typography><Typography variant="body_bold">Problem:</Typography> Creating AR/VR requires coding skills, takes too long, and is expensive.</Typography>
      <Typography><Typography variant="body_bold">Symptoms:</Typography> Uncertainty, want to identify how I can add this functionality with my existing resources.</Typography>
      <Typography><Typography variant="body_bold">Existing alternatives:</Typography> Google AR/VR tools, Apple ARKit, Vuforia, MAXST, Unity.</Typography>
      `,
    },
    {
      name: "inspiration",
      media: [
        {
          id: "media_id_1",
          type: "video",
          title:
            "Hear Dan Olsen, author of The Lean Product Playbook, give guidance on how to identify your target customer and map unmet needs on your journey to find Product-Market fit - and why this crucial step is such a failure point for so many products.",
          content_url: "https://www.youtube.com/watch?v=p3Bl4XWIBcw",
        },
        {
          id: "media_id_2",
          type: "video",
          title:
            "Russ Heddleston is the Co-Founder and CEO of DocSend, which he sold to Dropbox for $165M in 2021. In this Success Story interview with Scott Clary, Russ talks about how he came up with the idea of DocSend, how he researched Product-Market Fit, and his advice for founders who are in search of fundraising.",
          content_url: "https://www.youtube.com/watch?v=HwEWy24UDWs",
        },
      ],
    },
    //{
    //  name: "reviews",
    //  reviews: [
    //    //// Todo
    //  ],
    //},
  ],
};

export const BRANDSTORMING_SIDEBAR = {
  sidebar_id: "string",
  powerup_id: "string",
  tabs: [
    {
      name: "details",
      description:
        "Craft a detailed creative brief that can be used to create or extend your brand.",
      powerup_attributes: [
        {
          id: "powerup_attribute_id_1",
          name: "Time Estimate",
          value: "30 mins - 2 hours",
          icon: "time",
        },
        {
          id: "powerup_attribute_id_2",
          name: "Difficulty",
          value: "Easy",
          icon: "steps",
        },
        {
          id: "powerup_attribute_id_3",
          name: "Team Recommended",
          value: "Yes",
          icon: "person",
        },
        //{
        //  id: "powerup_attribute_id_4",
        //  name: "Feedback Alert!",
        //  value:
        //    "After completing this Power Up, it is highly recommended to seek feedback with your matched Founder. If revisions are needed after feedback, you can easily edit the work you have already completed.",
        //  icon: "info",
        //},
      ],
    },
    {
      name: "Power Tips",
      html_content: `
      <Typography variant="sys_light_on_surface" component="p" sx={{mb:1}}>Common Misconceptions</Typography>
      <Typography sx={{mb:1}}>
      Trying to boil the ocean: The belief that in order to be successful, you must alleviate every customer pain and target every gain. Plainly speaking, this is an impossible task. Don’t try to be all things to all people; instead, focus on only the most critically important pain relievers and deliver them flawlessly, the end result will be customer loyalty.
      </Typography>
      <Typography>
      <Typography variant="body_bold" >Analysis paralysis:</Typography> A great outcome with a “fit” on paper is just an untested fantasy. Use these PowerUps to challenge your assumptions and validate your understanding of what is most important to your customers. Adjust your product to map to these key learnings.
      </Typography>
      `,
    },
    {
      name: "inspiration",
      media: [
        {
          id: "media_id_1",
          type: "video",
          title:
            "via Big Think. Why do we root for some brands, and against others? A brand can be much more than a logo–it can become a relationship or a way of identifying. “We love to think of ourselves as rational, but that’s not how it works,” explains Americus Reed, Professor of Marketing at the Wharton School. Take a turn with this fascinating look at the neuroscience of branding.",
          content_url: "https://www.youtube.com/watch?v=4eIDBV4Mpek",
        },
        {
          id: "media_id_2",
          type: "video",
          title:
            "Michael Bierut, partner at the venerable design firm Pentagram and creator of iconic logos for Mastercard, Saks Fifth Avenue, and the New York Jets, on how the different ways we communicate are changing how we think about brands.",
          content_url: "https://www.youtube.com/watch?v=NP2jyXCpsTU",
        },
        {
          id: "media_id_3",
          type: "text",
          html_content: `
          <Typography>For an inspirational look at branding, watch the TED talk 'How symbols and brands shape our humanity' with Debbie Millman, brand strategist, podcaster, and chair of the Masters in Branding Program at the School of Visual Arts in NYC.</Typography>
          <Button sx={{my:2}} href='https://www.ted.com/talks/debbie_millman_how_symbols_and_brands_shape_our_humanity' target="_blank">Watch on TED.com</Button>
          `,
        },
      ],
    },
    //{
    //  name: "reviews",
    //  reviews: [
    //    //// Todo
    //  ],
    //},
  ],
};

export const REVENUE_MODELER_SIDEBAR = {
  sidebar_id: "string",
  powerup_id: "string",
  tabs: [
    {
      name: "details",
      description:
        "Using this PowerUp, you’ll calculate the overall size of your market, the percentage of that market you can realistically capture, and the overall market conditions that will determine your odds of success.",
      powerup_attributes: [
        {
          id: "powerup_attribute_id_1",
          name: "Time Estimate",
          value: "2 - 4 hours",
          icon: "time",
        },
        {
          id: "powerup_attribute_id_2",
          name: "Difficulty",
          value: "Hard",
          icon: "steps",
        },
        {
          id: "powerup_attribute_id_3",
          name: "Team Recommended",
          value: "Yes",
          icon: "person",
        },
        //{
        //  id: "powerup_attribute_id_4",
        //  name: "Feedback Alert!",
        //  value:
        //    "After completing this Power Up, it is highly recommended to seek feedback with your matched Founder. If revisions are needed after feedback, you can easily edit the work you have already completed.",
        //  icon: "info",
        //},
      ],
    },
    {
      name: "Power Tips",
      html_content: `
        <ul>
          <li><Typography>Be ready to field investor questions on your cost structure, cost of customer acquisition, and the pacing of your new hires.</Typography></li>
          <li><Typography>Prepare for questions that ask how you calculated your margins.</Typography></li>
          <li><Typography>Be clear in your revenue model to demonstrate you know general business terms and have not left the numbers to someone else.</Typography></li>
        </ul>
      `,
    },
    {
      name: "inspiration",
      media: [
        {
          id: "media_id_1",
          type: "video",
          title:
            "This video is designed to acquaint you with the general concept of revenue models and provides examples of eight common models, including unit sale, advertising, data, intermediation, licensing, franchising, and subscription.",
          content_url: "https://www.youtube.com/watch?v=yzZqnMbzWXw",
        },
      ],
    },
    //{
    //  name: "reviews",
    //  reviews: [
    //    //// Todo
    //  ],
    //},
  ],
};

export const IDEA_VALIDATION_SIDEBAR = {
  sidebar_id: "string",
  powerup_id: "string",
  tabs: [
    {
      name: "details",
      description:
        "You’ll create a user interview guide and script that you can use to gather insights from your user base.",
      powerup_attributes: [
        {
          id: "powerup_attribute_id_1",
          name: "Time Estimate",
          value: "2 - 4 hours",
          icon: "time",
        },
        {
          id: "powerup_attribute_id_2",
          name: "Difficulty",
          value: "Hard",
          icon: "steps",
        },
        {
          id: "powerup_attribute_id_3",
          name: "Team Recommended",
          value: "Optional",
          icon: "person",
        },
        //{
        //  id: "powerup_attribute_id_4",
        //  name: "Feedback Alert!",
        //  value:
        //    "After completing this Power Up, it is highly recommended to seek feedback with your matched Founder. If revisions are needed after feedback, you can easily edit the work you have already completed.",
        //  icon: "info",
        //},
      ],
    },
    {
      name: "Power Tips",
      html_content: `
        <ol>
          <li><Typography>Timing of interviews should be determined by the participant preference -- maximum flexibility on your part, while duration should be based your learning goals and tempered by the participant attention and energy threshold. These factors may inform participant screening and selection and should shape the interview content or coverage. Some basic guidelines:</Typography>
            <ul>
              <li><Typography>Maximum interview duration: 60 minutes</Typography></li>
              <li><Typography>Narrowly focused discussion questionnaire: 15-30 minutes</Typography></li>
              <li><Typography>Wider exploratory discussion-only interviews: 30-45 minutes... or consider splitting questions/probes between multiple participants</Typography></li>
              <li><Typography>Prototype UX validation walk-thru and discussion: 45-60 minutes</Typography></li>
              <li><Typography>If participant energy wains, consider eliminating less important questions</Typography></li>
            </ul>
          </li>
          <li><Typography>Ask why, even if you think you know the answer. Don’t assume to know what users are thinking or feeling — sometimes, their answer might surprise you.</Typography></li>
          <li><Typography>Ask participants for their advice rather than their opinion. That one word can make a huge difference; it can convert a critic to a partner. You’ll get someone that is standing with you together, and they will like the idea better because they are part of it.</Typography></li>
          <li><Typography>Ask for anecdotes. When asking users about their experiences, try to tease out stories of what they did in the recent past, rather than asking about generic experiences. For example, ask them about the last time they used a recipe app, instead of asking about their experience with recipe apps in general.</Typography></li>
          <li><Typography>Observe body language — it can tell us more than spoken language.</Typography></li>
          <li><Typography>Don’t use leading questions. Frame questions to be as neutral as possible. For example, rather than asking “Do you think it’s very troublesome finding a mentor?”, instead try “What do you think of the process of finding a mentor?”.</Typography></li>   
          <li><Typography>Designate an interview partner. It’s impossible to take notes while listening to what your interviewee is saying at the same time. If you can’t find a partner, use an audio recording app while you interview so you can be focused on following up and asking questions.</Typography></li>      
        </ol>
      `,
    },
    //{
    //  name: "inspiration",
    //  media: [
    //    {
    //      id: "media_id_1",
    //      type: "video",
    //      title: "Video Title 1",
    //      content_url:
    //        "https://storage.coverr.co/videos/XTV79I01nRiyAWapsHTQlm8VrSPHXS00nR?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBJZCI6Ijg3NjdFMzIzRjlGQzEzN0E4QTAyIiwiaWF0IjoxNjUwNjMwODUzfQ.Jn3ncLceelrB7Vef85e4_h0lFpY6Or7rnaII1oeQSLQ",
    //    },
    //  ],
    //},
    //{
    //  name: "reviews",
    //  reviews: [
    //    //// Todo
    //  ],
    //},
  ],
};
export const AVATARS = [
  AVATAR_1,
  AVATAR_2,
  AVATAR_3,
  AVATAR_4,
  AVATAR_5,
  AVATAR_6,
  AVATAR_7,
  AVATAR_8,
  AVATAR_9,
  AVATAR_10,
  AVATAR_11,
  AVATAR_12,
  AVATAR_13,
  AVATAR_14,
  AVATAR_15,
  AVATAR_16,
  AVATAR_17,
  AVATAR_18,
];

export const PERSONA_EDUCATION_LEVELS = [
  "Less than a high school diploma",
  "High school degree or equivalent (e.g. GED)",
  "Some college, no degree",
  "Associate degree (e.g. AA, AS)",
  "Bachelor's degree (e.g. BA, BS)",
  "Master's degree (MA, MS, MEd)",
  "Professional degree (MD, DDS, DVM)",
  "Doctorate (PhD)",
];

export const PERSONA_INDUSTRY_TYPES = [
  "Advertising",
  "Agriculture/Farming",
  "Aerospace & Defense",
  "Automotive",
  "Banking",
  "Broadcasting",
  "Clothing/Fashion",
  "Construction",
  "Education",
  "Energy/Renewables/Utilities",
  "Entertainments/Sports",
  "Food & Beverage",
  "Freight & Logistics",
  "Furniture",
  "Government",
  "Healthcare",
  "Home Improvement",
  "Hygiene & Wellness",
  "Information Technology",
  "Insurance",
  "Investment",
  "Legal Services",
  "Life Sciences",
  "Manufacturing",
  "Marketing",
  "News/Media/Publishing",
  "Pharmaceutical",
  "Real Estate",
  "Retail",
  "Technology",
  "Toys",
  "Non-applicable",
  "Other",
];

export const STATES = [
  {
    name: "Alabama",
    abbreviation: "AL",
  },
  {
    name: "Alaska",
    abbreviation: "AK",
  },
  {
    name: "Arizona",
    abbreviation: "AZ",
  },
  {
    name: "Arkansas",
    abbreviation: "AR",
  },
  {
    name: "California",
    abbreviation: "CA",
  },
  {
    name: "Colorado",
    abbreviation: "CO",
  },
  {
    name: "Connecticut",
    abbreviation: "CT",
  },
  {
    name: "Delaware",
    abbreviation: "DE",
  },
  {
    name: "District Of Columbia",
    abbreviation: "DC",
  },
  {
    name: "Florida",
    abbreviation: "FL",
  },
  {
    name: "Georgia",
    abbreviation: "GA",
  },
  {
    name: "Hawaii",
    abbreviation: "HI",
  },
  {
    name: "Idaho",
    abbreviation: "ID",
  },
  {
    name: "Illinois",
    abbreviation: "IL",
  },
  {
    name: "Indiana",
    abbreviation: "IN",
  },
  {
    name: "Iowa",
    abbreviation: "IA",
  },
  {
    name: "Kansas",
    abbreviation: "KS",
  },
  {
    name: "Kentucky",
    abbreviation: "KY",
  },
  {
    name: "Louisiana",
    abbreviation: "LA",
  },
  {
    name: "Maine",
    abbreviation: "ME",
  },
  {
    name: "Maryland",
    abbreviation: "MD",
  },
  {
    name: "Massachusetts",
    abbreviation: "MA",
  },
  {
    name: "Michigan",
    abbreviation: "MI",
  },
  {
    name: "Minnesota",
    abbreviation: "MN",
  },
  {
    name: "Mississippi",
    abbreviation: "MS",
  },
  {
    name: "Missouri",
    abbreviation: "MO",
  },
  {
    name: "Montana",
    abbreviation: "MT",
  },
  {
    name: "Nebraska",
    abbreviation: "NE",
  },
  {
    name: "Nevada",
    abbreviation: "NV",
  },
  {
    name: "New Hampshire",
    abbreviation: "NH",
  },
  {
    name: "New Jersey",
    abbreviation: "NJ",
  },
  {
    name: "New Mexico",
    abbreviation: "NM",
  },
  {
    name: "New York",
    abbreviation: "NY",
  },
  {
    name: "North Carolina",
    abbreviation: "NC",
  },
  {
    name: "North Dakota",
    abbreviation: "ND",
  },
  {
    name: "Ohio",
    abbreviation: "OH",
  },
  {
    name: "Oklahoma",
    abbreviation: "OK",
  },
  {
    name: "Oregon",
    abbreviation: "OR",
  },
  {
    name: "Pennsylvania",
    abbreviation: "PA",
  },
  {
    name: "Rhode Island",
    abbreviation: "RI",
  },
  {
    name: "South Carolina",
    abbreviation: "SC",
  },
  {
    name: "South Dakota",
    abbreviation: "SD",
  },
  {
    name: "Tennessee",
    abbreviation: "TN",
  },
  {
    name: "Texas",
    abbreviation: "TX",
  },
  {
    name: "Utah",
    abbreviation: "UT",
  },
  {
    name: "Vermont",
    abbreviation: "VT",
  },
  {
    name: "Virginia",
    abbreviation: "VA",
  },
  {
    name: "Washington",
    abbreviation: "WA",
  },
  {
    name: "West Virginia",
    abbreviation: "WV",
  },
  {
    name: "Wisconsin",
    abbreviation: "WI",
  },
  {
    name: "Wyoming",
    abbreviation: "WY",
  },
  {
    name: "Alberta",
    abbreviation: "AL",
  },
  {
    name: "British Columbia",
    abbreviation: "BC",
  },
  {
    name: "Manitoba",
    abbreviation: "MA",
  },
  {
    name: "Nova Scotia",
    abbreviation: "NS",
  },
  {
    name: "New Brunswick",
    abbreviation: "NB",
  },
  {
    name: "Newfoundland and Labrador",
    abbreviation: "NL",
  },
  {
    name: "Northwest Territories",
    abbreviation: "NT",
  },
  {
    name: "Nunavut",
    abbreviation: "NU",
  },
  {
    name: "Ontario",
    abbreviation: "ON",
  },
  {
    name: "Prince Edward Island",
    abbreviation: "PE",
  },
  {
    name: "Quebec",
    abbreviation: "QU",
  },
  {
    name: "Saskatchewan",
    abbreviation: "SA",
  },
  {
    name: "Yukon",
    abbreviation: "YU",
  },
];

export const CHALLENGES = [
  "Uncertainty",
  "Collaboration",
  "Communications",
  "Customer services",
  "Digitalization",
  "Diversity & Inclusion",
  "Financial management",
  "Monitoring performance",
  "Regulation & Compliance",
  "Recruiting right talent",
  "Technology",
];
export const TEST_TYPE = [
  { name: "Idea Validation Interview", uuid: "market-test-interview" },
];
//export const TOOLS = [
//  "Communication Tools",
//  "Project Management Tools",
//  "Documentation / Product Management Tools",
//  "Scheduling Tools",
//  "Team Culture / Team Building Tools",
//  "Marketing Tools",
//  "Sales Tools",
//  "Customer Success Tools",
//  "Product / Design Tools",
//  "Expense Management Tools",
//  "Other",
//];

export const TOOLS = [
  "Communication Tools",
  "Customer Success Tools",
  "Expense Management Tools",
  "Home Appliance Tools",
  "Marketing Tools",
  "Personal Communication Tools",
  "Personal Device Tools",
  "Product/Design Tools",
  "Project Management Tools",
  "Sales Tools",
  "Scheduling Tools",
  "Team Building Tools",
];

export const PERSONA_BEHAVIOURS = [
  "Organized",
  "Practical",
  "Protective",
  "Hardworking",
  "Calm",
];

export const CONTACT_ICONS = [
  PHONE_ICON,
  EMAIL_ICON,
  TEXT_ICON,
  LIKE_ICON,
  SMILE_ICON,
];

export const CONTACT_ICONS_MAP = [
  { url: LIKE_ICON, component: ThumbUpOutlinedIcon, text: "Social Media" },
  { url: PHONE_ICON, component: LocalPhoneOutlinedIcon, text: "Phone" },
  { url: EMAIL_ICON, component: EmailOutlinedIcon, text: "Email" },
  { url: TEXT_ICON, component: TextsmsOutlinedIcon, text: "Text" },
  { url: SMILE_ICON, component: MoodOutlinedIcon, text: "In Person" },
];

export const GENERATION_ICON_LIST = [
  { url: ICONIC_TOY_ICON, label: "Iconic Toy" },
  { url: MUSIC_DEVICE_ICON, label: "Music Device" },
  { url: SCREEN_CONTENT_ICON, label: "Screen Content" },
];

export const PARTNERS_DATA = [
  {
    image: SVB_PARTNER,
    title: "Silicon Valley Bank",
    subTitle: "The bank of choice for Innovators",
    description:
      "Discover why 63% of U.S. venture capital-backed companies that went public in H1 2021 are SVB clients.",
  },
  {
    image: ADVANCE_PARTNER,
    title: "Advance Creative",
    subTitle: "Content is still king",
    description:
      "Transform your content marketing strategy with unique, inspiring video content that engages, converts, and retains customers.",
  },
  {
    image: GUSTO_PARTNER,
    title: "Gusto",
    subTitle: "The modern HR platform for payroll, benefits, and more.",
    description:
      "A well-supported team is the key to a successful business. With Gusto’s easy-to-use platform, you can empower your people and push your business forward.",
  },
  {
    image: STARTEGY_PARTNER,
    title: "CR Strategy Partners",
    subTitle: "Innovation Strategy & Consulting",
    description:
      "We partner with enterprises and ventures on every step of the innovation journey; from strategy and engineering to launch and traction.",
  },
];

export const MARKET_TEST_SCRIPT_DATA = [
  "Use this script to better understand any unmet needs and to empathize with your prospective customer or end-user. This template serves as a starting point… make it your own by using words, tone, and energy that is natural for you!",
  "This template assumes you have a problem and two ideas to test. This serves as a starting point, so make it your own by using words, tone, and energy that is natural for you.",
  "Use this script to test and refine your initial persona definitions. The template serves a starting point, so make it your own by using words, tone, and energy that are natural for you. \n\nFeedback from some individuals may be more compelling or memorable than others and can skew your overall perception of the group. Conduct at least 5 interviews for each persona to overcome that natural tendency and more accurately represent the group.",
];

export const OCCUPATIONS = [
  "Agricultural worker",
  "Architect",
  "Business analyst",
  "Computer scientist",
  "Construction worker",
  "Cook",
  "Designer",
  "Entrepreneur",
  "Executive",
  "Freelancer",
  "Journalist",
  "Office worker",
  "Retiree",
  "Scientist",
  "Student",
  "Stay-at-home parent",
  "Teacher",
];
