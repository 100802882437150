import React, { useEffect, useState } from "react";
import { Box, Typography, Card, Button } from "@mui/material";
import { LATEST_UPDATE_ICON, MESSAGES_BLACK_ICON, STICKER_BLACK, USERS_BLACK_ROUNDED_ICON } from "../../../constants";
import ROUTES_PATH from "../../../constants/routes";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setNetworkingDashboardData } from "../../../modules/actions/networkingActions";
import { USER_TYPES } from "../../../utils/userTypes";

const LatestUpdatesComp = () => {
	const history = useHistory();
	const unreadMessagesData = useSelector((state) => state?.unreadMessages);
	const unreadMessagesArray = Object.values(unreadMessagesData || {});
	const [unreadMessageCount, setUnreadMessageCount] = useState(0);
	const dispatch = useDispatch();

	useEffect(() => {
		if (unreadMessagesArray.length > 0) {
			let totalCount = unreadMessagesArray?.reduce((x, y) => {
				return x + y;
			}, 0);
			setUnreadMessageCount(totalCount);
		}
	}, [unreadMessagesArray]);

	const UpdateSection = ({ icon, title, buttonText, onBtnTxtClick, redirectionPath }) => {
		return (
			<Box>
				<Box display={"flex"} alignItems={"center"} gap={2}>
					<Box component={"img"} sx={{ width: "24px" }} src={icon} alt="icon" />
					<Typography component={"p"} variant="Text/sm/Regular">
						{title}
					</Typography>
				</Box>
				<Button sx={{ height: "48px", mt: 2 }} onClick={onBtnTxtClick} variant="GreyRounded">
					{buttonText}
				</Button>
			</Box>
		);
	};

	return (
		<Card
			sx={(theme) => ({
				borderRadius: theme.shape.standard10.borderRadius,
				height: "fit-content",
				padding: 4,
				backgroundColor: theme.palette.secondary.white,
				cursor: "pointer",
				boxShadow: "0px 0px 25px 10px rgba(16, 24, 40, 0.03)",
			})}
		>
			{" "}
			<Box component={"img"} src={LATEST_UPDATE_ICON} alt="update" />
			<Typography component={"p"} variant="Text/md/Semibold" sx={{ mt: 1 }} color="secondary.black">
				Latest Updates!
			</Typography>
			<Box sx={{ mt: 4 }} display={"flex"} flexDirection={"column"} gap={4}>
				<UpdateSection
					icon={STICKER_BLACK}
					title="See what's happening in your network!"
					buttonText={"Go to Discovery posts"}
					onBtnTxtClick={() => {
						history.push(ROUTES_PATH.RESEARCH_CURATOR_EXPAND);
					}}
				/>
				<UpdateSection
					icon={MESSAGES_BLACK_ICON}
					title="Send messages to members of your network!"
					onBtnTxtClick={() => {
						dispatch(
							setNetworkingDashboardData({
								openMessageDialog: true,
								messageWithMatch: "",
							})
						);
					}}
					buttonText={`Review Comment (${unreadMessageCount})`}
				/>
				<UpdateSection
					icon={USERS_BLACK_ROUNDED_ICON}
					onBtnTxtClick={() => {
						dispatch(
							setNetworkingDashboardData({
								currentTab: USER_TYPES.MENTOR_USER,
							})
						);
						history.push(ROUTES_PATH.NETWORK);
					}}
					title="Connect with your mentor through the platform!"
					buttonText={"Schedule a Meeting"}
				/>
			</Box>
		</Card>
	);
};

export default LatestUpdatesComp;
