import styled from "@emotion/styled";
import { FOUNDER_DASHBOARD_BG } from "../../constants";

export const PageWrapper = styled.div(({ theme, bgImage = false }) => {
	return {
		minHeight: "100vh",
		display: "grid",
		gridTemplateRows: "auto 1fr auto",
		backgroundImage: bgImage && `url(${FOUNDER_DASHBOARD_BG})`,
		backgroundRepeat: bgImage && "no-repeat",
		position: bgImage && "fixed",
		overflowY: bgImage && "auto",
	};
});
export const HeaderArea = styled.div(({ theme }) => {
	return {
		background: "var(--white-color)",
	};
});
export const MainArea = styled.div(({ theme }) => {
	return {
		height: "100%",
		//margin: "0 8px 0px 24px",
	};
});
