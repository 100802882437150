import React, { createContext, useContext, useEffect, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { getThemePalette } from "./colors";
import { themeTypography } from "./typography";
import { themeButtons } from "./buttons";
import { themeLayout } from "./layout";
import { themeInputs } from "./inputs";
import { themeSelect } from "./select";
import { themeFlag } from "./flag";
import { themeShape } from "./shape";
import { themeAccordion } from "./accordion";
import services from "../../services";
import { GlobalStyles } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setSlackWorkspaceActions } from "../../modules/actions/slackWorkspaceActions";

export const removeFromLocalStorage = (key) => {
	try {
		// Check if the item exists in localStorage
		const item = localStorage.getItem(key);
		if (item !== null) {
			// If the item exists, remove it
			localStorage.removeItem(key);
			console.log(`Item with key '${key}' removed from localStorage.`);
		} else {
			// If the item does not exist, log a message or handle as needed
			console.log(`Item with key '${key}' not found in localStorage.`);
		}
	} catch (error) {
		console.error("Error accessing localStorage", error);
	}
};

export const saveToLocalStorage = (key, object) => {
	try {
		const serializedObject = JSON.stringify(object);
		localStorage.setItem(key, serializedObject);
	} catch (error) {
		console.error("Error saving to localStorage", error);
	}
};

export const retrieveFromLocalStorage = (key) => {
	try {
		const serializedObject = localStorage.getItem(key);
		if (serializedObject === null) {
			return undefined; // or a default value
		}
		return JSON.parse(serializedObject);
	} catch (error) {
		console.error("Error retrieving from localStorage", error);
		return undefined; // or a default value
	}
};

function hexToRGB(hex) {
	// Validate hex color format
	if (!/^#([0-9A-F]{3}){1,2}$/i.test(hex)) {
		throw new Error("Invalid hex color format");
	}

	let r = parseInt(hex.slice(1, 3), 16);
	let g = parseInt(hex.slice(3, 5), 16);
	let b = parseInt(hex.slice(5, 7), 16);
	return [r, g, b];
}

function RGBToHex(r, g, b) {
	return (
		"#" +
		[r, g, b]
			.map((x) => {
				const hex = x.toString(16);
				return hex.length === 1 ? "0" + hex : hex;
			})
			.join("")
	);
}

export function generateTint(hexColor, tintFactor) {
	try {
		let [r, g, b] = hexToRGB(hexColor);
		r = Math.round(r + (255 - r) * tintFactor);
		g = Math.round(g + (255 - g) * tintFactor);
		b = Math.round(b + (255 - b) * tintFactor);
		return RGBToHex(r, g, b);
	} catch (error) {
		console.error(error.message);
		return null;
	}
}

export function generateShade(hexColor, shadeFactor) {
	try {
		let [r, g, b] = hexToRGB(hexColor);
		r = Math.round(r * shadeFactor);
		g = Math.round(g * shadeFactor);
		b = Math.round(b * shadeFactor);
		return RGBToHex(r, g, b);
	} catch (error) {
		console.error(error.message);
		return null;
	}
}

export const ThemeContext = createContext();

export const useThemeContext = () => useContext(ThemeContext);

export const CustomThemeProvider = ({ children }) => {
	const [colorUpdates, setColors] = useState({});
	const [logoOverride, setLogoOverride] = useState(null);
	const [appNameOverride, setAppNameOverride] = useState(null);

	const partnerCode = useSelector((state) => state?.auth?.partnerCode);
	const dispatch = useDispatch();
	useEffect(() => {
		const themeOverrides = retrieveFromLocalStorage("StartupOSThemeOverride");
		if (themeOverrides) {
			setColorUpdates(themeOverrides);
		}
		const logos = retrieveFromLocalStorage("StartupOSLogoOverride");
		if (logos) {
			setLogoOverride(logos);
		}
		const partnerName = retrieveFromLocalStorage("StartupOSAppNameOverride");
		if (logos) {
			setAppNameOverride(partnerName);
		}
	}, []);

	useEffect(() => {
		if (partnerCode) {
			getThemeByUser(partnerCode);
		} else {
			clearThemeObjects();
		}
	}, [partnerCode]);

	useEffect(() => {
		saveToLocalStorage("StartupOSThemeOverride", colorUpdates);
	}, [colorUpdates]);

	useEffect(() => {
		saveToLocalStorage("StartupOSLogoOverride", logoOverride);
	}, [logoOverride]);

	useEffect(() => {
		saveToLocalStorage("StartupOSAppNameOverride", appNameOverride);
	}, [appNameOverride]);

	const clearThemeObjects = () => {
		setColors({});
		setLogoOverride(null);
		setAppNameOverride(null);
		removeFromLocalStorage("StartupOSThemeOverride");
		removeFromLocalStorage("StartupOSLogoOverride");
		removeFromLocalStorage("StartupOSAppNameOverride");
	};

	const setColorUpdates = (obj) => {
		// Example usage
		try {
			let colorObj = {
				palette: {
					primary: {
						...(obj?.palette?.primary?.main && {
							main: obj?.palette?.primary?.main,
							light: obj?.palette?.primary?.light ?? generateTint(obj?.palette?.primary?.main, 0.7),
							dark: obj?.palette?.primary?.dark ?? generateShade(obj?.palette?.primary?.main, 0.7),
						}),
					},
					secondary: {
						...(obj?.palette?.secondary?.main && {
							main: obj?.palette?.secondary?.main,
							light: obj?.palette?.secondary?.light ?? generateShade(obj?.palette?.secondary?.main, 0.7),
							dark: obj?.palette?.secondary?.dark ?? generateShade(obj?.palette?.secondary?.main, 0.7),
						}),
					},
					tertiary: {
						...(obj?.palette?.tertiary?.main && {
							main: obj?.palette?.tertiary?.main,
							light: obj?.palette?.tertiary?.light ?? generateShade(obj?.palette?.tertiary?.main, 0.7),
							dark: obj?.palette?.tertiary?.dark ?? generateShade(obj?.palette?.tertiary?.main, 0.7),
						}),
					},
				},
			};
			setColors(colorObj);
		} catch (error) {
			console.error("Error generating tint or shade: " + error.message);
		}
	};

	const setBrandingUpdates = (obj) => {
		if (obj?.logo) setLogoOverride(obj?.logo);

		if (obj?.partnerName) setAppNameOverride(obj?.partnerName);
	};

	const getThemePayload = async (partner) => {
		const resp = await services.getPartnerTheme(partner).catch((e) => {
			console.log("Unable to grab partner theme. Failed with error:", e);
		});
		const response = resp?.data?.data;
		dispatch(
			setSlackWorkspaceActions({
				slackWorkspaceName: response?.slackWorkspaceName || "",
				slackUrl: response?.slackWorkspaceUrl || "",
			})
		);
		return resp;
	};

	const getTheme = async () => {
		try {
			// get URL param of partner
			const queryParams = new URLSearchParams(window.location.search);
			const partner = queryParams.get("partner");

			if (!partner) return;

			const themePayload = await getThemePayload(partner);
			processThemePayload(themePayload);
		} catch (e) {
			console.log("Unable to identify a partner and get theme payload. Failed with error:", e);
		}
	};

	const getThemeByUser = async (partner) => {
		const themePayload = await getThemePayload(partner);
		processThemePayload(themePayload);
	};

	const processThemePayload = (themePayload) => {
		try {
			if (!themePayload?.data?.data) clearThemeObjects();

			setBrandingUpdates({
				logo: themePayload?.data?.data?.logoUrl,
				partnerName: themePayload?.data?.data?.partnerName,
			});
			setColorUpdates({
				palette: {
					primary: { main: themePayload?.data?.data?.colorPrimary },
					secondary: { main: themePayload?.data?.data?.colorSecondary },
					tertiary: { main: themePayload?.data?.data?.colorTertiary },
				},
			});
		} catch (e) {
			console.log("Unable to process theme payload. Failed with error:", e);
		}
	};

	const themePalette = getThemePalette(colorUpdates);
	const theme = createTheme(
		themePalette,
		themeShape(themePalette),
		themeButtons(themePalette),
		themeTypography(themePalette),
		themeLayout(themePalette),
		themeInputs(themePalette),
		themeSelect(themePalette),
		themeFlag(themePalette),
		themeAccordion(themePalette)
	);

	const contextValue = {
		theme,
		setColorUpdates,
		clearThemeObjects,
		setBrandingUpdates,
		appNameOverride,
		logoOverride,
		getTheme,
		getThemeByUser,
	};

	return (
		<ThemeContext.Provider value={contextValue}>
			<ThemeProvider theme={theme}>
				<GlobalStyles
					styles={{
						".SOSShowHorizontalScroll::-webkit-scrollbar-thumb, .SOSShowVerticalScroll::-webkit-scrollbar-thumb, .SOSShowAllScroll::-webkit-scrollbar-thumb":
							{
								borderRadius: "10px",
								"-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.2)",
								background: theme?.palette?.primary?.main,
							},
					}}
				/>
				{children}
			</ThemeProvider>
		</ThemeContext.Provider>
	);
};
