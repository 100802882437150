import React from "react";
import { Box } from "@mui/material";
import { EDIT_WHITE_ICON_V2 } from "../../../../constants/image";
import { useDispatch } from "react-redux";
import { setBIDashboardData } from "../../../../modules/actions/biDashboardActions";
import UpdateMatrics from "./UpdateMatrics";

function StartupGridEdit() {
	const dispatch = useDispatch();

	return (
		<>
			<Box
				sx={{
					borderRadius: "100px",
					width: "48px",
					height: "48px",
					backgroundColor: (theme) => theme.palette.primary.main,
					cursor: "pointer",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					ml: 1,
				}}
				onClick={() => {
					dispatch(setBIDashboardData({ openEdit: true }));
				}}
			>
				<Box component={"img"} src={EDIT_WHITE_ICON_V2} />
			</Box>
			<UpdateMatrics />
		</>
	);
}

export default StartupGridEdit;
