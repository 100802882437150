import React from "react";
import { Typography, Box } from "@mui/material";
import FilterDropDownComponent from "../Common/FilterDropDown";

const FilterTeamSection = ({ filterData, handleUncheck }) => {
	const teamData = filterData["Team"];

	return (
		<Box
			sx={{
				p: 5,
			}}
		>
			<FilterDropDownComponent
				gridData={teamData}
				handleUncheck={handleUncheck}
				lable={"Team Members"}
				type="teamMember"
				altLabel={"Leading Indicators"}
			/>
		</Box>
	);
};

export default FilterTeamSection;
