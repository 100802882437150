import services from "../../services";
import { BodyCopy, SubtitleH2, SubtitleH3, SubtitleH5, Title, PDFDivider, getComponentImage, ComponentImage } from "../../utils/PDF-Export/utilities";
import { getModuleSteps, getPowerupDetails } from "../NewPowerup/Common/functions";

export const getDetailsById = async (id) => {
	const details = await getPowerupDetails(id)
		.then((details) => {
			return details;
		})
		.catch((error) => {
			console.log("Error loading PowerUP details. Failed with error:", error);
		});
	return details;
};

export const getStartupProfile = async () => {
	const details = await services
		.getCompanyProfile()
		.then((details) => {
			return details?.data?.data;
		})
		.catch((error) => {
			console.log("Error loading startup details. Failed with error:", error);
		});
	return details;
};

export const getModuleStepsById = async (id) => {
	const steps = await getModuleSteps(id)
		.then((result) => {
			return result;
		})
		.catch((err) => console.log("Error loading module steps. Failed with error:", err));

	return steps;
};

export const getVersionsByModuleId = async (id) => {
	const versions = await services
		.getMyWorkDetails(id, null, "DESC", "lastModifiedAt", false, false)
		.then((result) => {
			return result?.data?.data;
		})
		.catch((err) => console.log("Error loading versions. Failed with error:", err));

	return versions;
};

export const getVersionsByPowerUPId = async (id) => {
	const versions = await services
		.getMyWorkDetails(id, null, "DESC", "lastModifiedAt", false, false)
		.then((result) => {
			return result?.data?.data;
		})
		.catch((err) => console.log("Error loading versions. Failed with error:", err));

	return versions;
};

export const startNewVersion = async (id) => {
	const version = await services
		.startModule({ moduleId: id })
		.then((result) => {
			return result?.data?.data;
		})
		.catch((err) => console.log("Error starting a new version. Failed with error:", err));

	return version;
};

export const getModuleResponses = async (modId, responseId) => {
	const responses = await services
		.getModuleResponseDetailsByResponseId(modId, responseId)
		.then((result) => {
			return result?.data?.data;
		})
		.catch((err) => console.log(`Error getting responses for module: ${modId} and response: ${responseId}. Failed with error:`, err));

	return responses;
};

export const saveModuleResponses = async (currentVersion, modStep, data, isLast, isComplete) => {
	if (!modStep && !currentVersion) return;
	const response = await services
		.storeModuleStepResponses(currentVersion?.module?.id, {
			answers: JSON.stringify(data),
			moduleResponseId: currentVersion?.id,
			isLastStep: isLast,
			isCompleted: isComplete,
			moduleStepId: modStep?.id,
		})
		.then((response) => {
			return new Date()?.toLocaleString("en-US", { hour: "numeric", minute: "numeric", hour12: true });
		});
	return response;
};

export const getModuleStepByUUID = (steps, uuid) => {
	const found = steps?.find((obj) => obj?.stepUuid === uuid);
	return found;
};

export const getAnswersByStepUuid = (data, stepUuid) => {
	if (!data) return null;
	const moduleStepResponse = data?.moduleStepResponses?.find((response) => response?.moduleStep?.stepUuid === stepUuid);
	return moduleStepResponse ? JSON.parse(moduleStepResponse?.answers) : null;
};

export const prepareContentArray = (documentData) => {
	// --> documentData <--
	//{
	//	title: ...,
	//	sections: [
	//		{
	//			title: ,
	//			description: ,
	//			response: ,
	//		},
	//		{
	//			type: "image",
	//			title: ,
	//			description: ,
	//			response: ,
	//		},

	//	]
	//}

	let contentArr = [];

	if (documentData?.title) {
		contentArr.push({
			type: "h1",
			content: documentData?.title,
		});
	}
	documentData?.sections?.forEach((item, index) => {
		if (item?.type && item?.type === "image") {
			if (item?.title) {
				contentArr.push({
					type: "h3",
					content: item?.title,
				});
			}
			if (item?.description) {
				contentArr.push({
					type: "p",
					content: item?.description,
				});
			}
			if (item?.response) {
				contentArr.push({
					type: "component",
					content: item?.response,
				});
			}
		} else {
			if (item?.title) {
				contentArr.push({
					type: "h3",
					content: item?.title,
				});
			}
			if (item?.description) {
				contentArr.push({
					type: "p",
					content: item?.description,
				});
			}
			if (item?.response) {
				contentArr.push(
					{
						type: "h5",
						content: item?.type && item?.type === "AI" ? "AI Response:" : "Your Response:",
					},
					{
						type: "p",
						content: item?.response,
					}
				);
			}
		}

		// Add line separator between sections
		if (index !== documentData?.sections?.length - 1) {
			contentArr.push({
				type: "hr",
			});
		}
	});

	return contentArr;
};

export const generatePDFContent = async (documentData) => {
	const contentArr = prepareContentArray(documentData);

	let preProcessed = [];

	for (const item of contentArr) {
		if (item?.type === "component") {
			const compImg = await getComponentImage({
				component: item?.content,
			});
			if (compImg?.imageSrc) {
				preProcessed.push(<ComponentImage imageSrc={compImg?.imageSrc} />);
			}
		} else {
			switch (item?.type) {
				case "h1":
					preProcessed.push(<Title>{item?.content}</Title>);
					break;
				case "h2":
					preProcessed.push(<SubtitleH2>{item?.content}</SubtitleH2>);
					break;
				case "h3":
					preProcessed.push(<SubtitleH3>{item?.content}</SubtitleH3>);
					break;
				case "h5":
					preProcessed.push(<SubtitleH5>{item?.content}</SubtitleH5>);
					break;
				case "p":
					preProcessed.push(<BodyCopy>{item?.content}</BodyCopy>);
					break;
				case "hr":
					preProcessed.push(<PDFDivider />);
					break;
				default:
					preProcessed.push(<BodyCopy>{item?.content}</BodyCopy>);
					break;
			}
		}
	}
	return preProcessed;
};

export const getLatestResponsesByPowerupID = async (pID, moduleStepId, fieldID) => {
	if (!pID && !moduleStepId) return null;

	const versions = await getVersionsByPowerUPId(pID);
	const findLatestVersion = (versions) => {
		if (!Array.isArray(versions) || versions.length === 0) {
			return null;
		}
		return versions.sort((a, b) => new Date(b.completionDate) - new Date(a.completionDate))[0];
	};
	const latestVersion = findLatestVersion(versions);

	if (!latestVersion?.id && !latestVersion?.module?.id) return null;

	const latestResponses = await getModuleResponses(latestVersion?.module?.id, latestVersion?.id);

	if (!latestResponses) return null;

	const stepResponse = getAnswersByStepUuid(latestResponses, moduleStepId);

	if (!stepResponse) return null;

	if (fieldID) {
		return stepResponse?.find((field) => field.id === fieldID)?.value;
	} else {
		return stepResponse;
	}
};

export const getVersionByID = async (pID, versionID) => {
	if (!pID && !versionID) return null;

	const versions = await getVersionsByPowerUPId(pID);

	if (!versions) return null;

	const found = versions?.find((vs) => vs.id === (typeof versionID === "string" ? parseInt(versionID) : versionID));

	return found;
};

export const NESTED_POWER_UPS = [
	{
		title: "Building Your Lean Canvas",
		previous: { title: "Elevator Pitch", route: "/workspace/powerUp/53" },
		next: { title: "Founder Story", route: "/workspace/powerUp/2" },
	},
	{
		title: "Founder Story",
		previous: { title: "Building Your Lean Canvas", route: "/workspace/powerUp/41" },
		next: { title: "Problem Solution Fit", route: "/workspace/powerUp/5" },
	},
	{
		title: "Problem Solution Fit",
		previous: { title: "Founder Story", route: "/workspace/powerUp/2" },
		next: { title: "Value Proposition", route: "/workspace/powerUp/1" },
	},
	{
		title: "Value Proposition",
		previous: { title: "Problem Solution Fit", route: "/workspace/powerUp/5" },
		next: { title: "Building a Business Moat", route: "/workspace/powerUp/10" },
	},
	{
		title: "Building a Business Moat",
		previous: { title: "Value Proposition", route: "/workspace/powerUp/1" },
		next: { title: "SaaS Pricing Modeler", route: "/workspace/powerUp/9" },
	},
	{
		title: "SaaS Pricing Modeler",
		previous: { title: "Building a Business Moat", route: "/workspace/powerUp/10" },
		next: { title: "Persona Builder", route: "/workspace/powerUp/3" },
	},
	{
		title: "Persona Builder",
		previous: { title: "SaaS Pricing Modeler", route: "/workspace/powerUp/9" },
		next: { title: "TAM SAM SOM", route: "/workspace/powerUp/7" },
	},
	{
		title: "TAM SAM SOM",
		previous: { title: "Persona Builder", route: "/workspace/powerUp/3" },
		next: { title: "Developing an Exit Strategy", route: "/workspace/powerUp/13" },
	},
	{
		title: "Developing an Exit Strategy",
		previous: { title: "TAM SAM SOM", route: "/workspace/powerUp/7" },
		next: { title: "Elevator Pitch", route: "/workspace/powerUp/53" },
	},
	{
		title: "Elevator Pitch",
		previous: { title: "Developing an Exit Strategy", route: "/workspace/powerUp/13" },
		next: { title: "Building Your Lean Canvas", route: "/workspace/powerUp/41" },
	},
];
