import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getAcvGridData } from "../Functions/common";
import AcvChart from "../Charts/AcvChart";
import AcvDataGrid from "../DataGrid/AcvGrid";
import { handleMixPanel } from "../../../../utils/mixPanelEventHandling";
import { setBIDashboardData } from "../../../../modules/actions/biDashboardActions";
import BIDataGridDialog from "../DataGrid/BIDataGridDialog";

function Acv({ companyId, readOnly }) {
	const dispatch = useDispatch();
	const acv = useSelector((store) => store?.biDashboard?.acv);
	const loadingAcv = useSelector((store) => store?.biDashboard?.loadingAcv);
	const openAcv = useSelector((store) => store?.biDashboard?.openAcv);
	const [isEdited, setEdited] = useState(false);

	const gridData = acv?.data ? acv?.data : [];

	const ownCompanyId = useSelector((state) => state?.auth?.companyId);
	const usedCompanyId = companyId ? companyId : ownCompanyId;

	const fetchData = async () => {
		try {
			dispatch(setBIDashboardData({ loadingAcv: true }));
			const responseData = await getAcvGridData(usedCompanyId);
			if (responseData) {
				dispatch(setBIDashboardData({ acv: responseData }));
			}
			dispatch(setBIDashboardData({ loadingAcv: false }));
		} catch (error) {
			dispatch(setBIDashboardData({ loadingAcv: false }));
			console.log("error", error);
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	const onChartClick = () => {
		handleMixPanel("Grid ACV Viewed");
		dispatch(setBIDashboardData({ openAcv: true }));
	};

	const handleClose = useCallback(() => {
		if (isEdited) {
			dispatch(
				setBIDashboardData({
					openAcvLeave: true,
				})
			);
		} else {
			dispatch(setBIDashboardData({ openAcv: false }));
		}
	}, [isEdited]);

	return (
		<>
			<AcvChart data={acv} onChartClick={onChartClick} loading={loadingAcv} readOnly={readOnly} />
			<BIDataGridDialog title="ACV" isOpen={openAcv} onClose={handleClose}>
				<AcvDataGrid gridData={gridData} readOnly={readOnly} usedCompanyId={usedCompanyId} isEdited={isEdited} setEdited={setEdited} />
			</BIDataGridDialog>
		</>
	);
}

export default Acv;
