import React from "react";
import { Typography, Box, Button, CircularProgress, Stack, useMediaQuery, Chip } from "@mui/material";
import CommonSlideDialog from "../../../../common/CommonSlideDialog";
import KeyboardDoubleArrowRightRoundedIcon from "@mui/icons-material/KeyboardDoubleArrowRightRounded";

export default function AcceleratorsProgramsDialog({ isOpen, acceleratorsPrograms = [], setOpenAcceleratorsDialog }) {
	const handleClose = () => {
		setOpenAcceleratorsDialog(false);
	};

	return (
		<CommonSlideDialog
			open={isOpen}
			slidingEffect={false}
			onClose={() => handleClose()}
			title={
				<Box display="flex" flexDirection="column" p="0 0 8px">
					<KeyboardDoubleArrowRightRoundedIcon sx={{ color: (theme) => theme.palette.primary.main }} />
					<Typography variant="Text/md/Semibold" sx={{ mt: 1.5 }}>
						Accelerators Programs{" "}
					</Typography>
				</Box>
			}
			titleVariant="Text/md/Semibold"
			maxWidth="lg"
			btnCloseStyle={{ margin: "0 24px 0 0" }}
			dialogAction={
				<Box display={"flex"} alignItems={"center"} gap={2}>
					<Button variant="DS1" onClick={() => handleClose()}>
						Done
					</Button>
				</Box>
			}
			PaperProps={{
				sx: (theme) => ({
					position: "fixed",
					top: 70,
					borderRadius: "30px",
					m: 0,
					p: 2,
					[theme.breakpoints.down("md")]: {
						px: 1,
						py: 1.5,
					},
					[theme.breakpoints.down("sm")]: {
						p: 0,
						m: -1,
					},
				}),
			}}
		>
			<Stack>
				<Typography variant="Text/sm/Regular">Here are all of the accelerator programs I’ve participated in!</Typography>
				<Stack direction={"row"} spacing={1} alignItems={"center"} p={1}>
					{acceleratorsPrograms?.map((acceleratorsItem) => {
						return (
							<Chip
								variant="outlined"
								sx={{ maxWidth: "fit-content" }}
								label={<Typography variant="Text/xs/Regular"> {acceleratorsItem}</Typography>}
							/>
						);
					})}
				</Stack>
			</Stack>
		</CommonSlideDialog>
	);
}
