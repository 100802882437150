import styled from "@emotion/styled";

export const ContentWrapper = styled.div(({ theme }) => {
  return {
    [theme.breakpoints.up("md")]: {
      boxSizing: "border-box",
      borderRadius: theme.shape.standard.borderRadius,
      padding: theme.spacing(3),
      boxShadow: theme.palette.primary.elevationLight2,
    },
    padding: theme.spacing(3),
  };
});

export const ContentHeader = styled.div(({ theme }) => {
  return {
    display: "flex",
    alignItems: "center",
  };
});
