import React, { useState, useEffect, useRef } from "react";
import { Grid, Stack, Box, Typography, TextField, IconButton, CircularProgress } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import CommentCard from "../../components/CommentFeed/CommentCard";
import services from "../../services";
import { toastContainer } from "../../utils/toast";

export default function CommentFeed({ noteId }) {
	const [page, setPage] = useState(1);
	const [totalPages, setTotalPages] = useState(null);
	const [payload, setPayload] = useState({ text: "" });
	const [loading, setLoading] = useState(false);
	const [comments, setComments] = useState("");
	const [isLastComment, setIsLastComment] = useState(false);
	const [lastElement, setLastElement] = useState(null);

	useEffect(() => {
		if (noteId) {
			handleGetNotesComments();
		}
	}, [noteId]);

	const observer = useRef(
		new IntersectionObserver((entries) => {
			const first = entries[0];
			if (first.isIntersecting) {
				callNextPage();
			}
		})
	);

	useEffect(() => {
		const currentElement = lastElement;
		const currentObserver = observer.current;

		if (currentElement) {
			currentObserver.observe(currentElement);
		}

		return () => {
			if (currentElement) {
				currentObserver.unobserve(currentElement);
			}
		};
	}, [lastElement]);

	const callNextPage = () => {
		setPage((page) => page + 1);
	};

	useEffect(() => {
		if (page > 1 && !isLastComment) {
			handleGetNotesComments();
		}
	}, [page]);

	const handlePayload = (e) => {
		let data = { ...payload };
		data["text"] = e.target.value.replace(/^\s+/g, "");
		setPayload(data);
	};

	const resetPayload = () => {
		let data = { ...payload };
		data["text"] = "";
		setPayload(data);
	};

	const handleAddComment = () => {
		setLoading(true);
		services
			.addCommentToNote(noteId, payload)
			.then((res) => {
				if (res.data.code === 200) {
					handleGetNotesComments();
					resetPayload();
					setLoading(false);
				}
			})
			.catch((err) => {
				console.log(err);
				setLoading(false);
				toastContainer(err?.error_description, "error");
			});
	};

	const handleGetNotesComments = () => {
		services
			.getNoteComments(noteId, page, "DESC", null, 10)
			.then((res) => {
				if (res.data.code === 200) {
					setIsLastComment(res.data.data.isLast);
					setTotalPages(res.data.data.totalPages);
					if (page === 1) {
						setComments(res.data.data.models);
					} else {
						setComments([...comments, ...res.data.data.models]);
					}

					setLoading(false);
				}
			})
			.catch((err) => {
				console.log(err);
				setLoading(false);
				//toastContainer(err?.error_description, "error");
			});
	};

	return (
		<Box sx={{ display: "flex", gap: 2, flexDirection: "column" }}>
			<Typography variant="title_medium">Comments</Typography>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					gap: 1,
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<TextField
					label="Add comment..."
					spellCheck="true"
					fullWidth
					value={payload["text"]}
					onChange={(e) => {
						handlePayload(e);
					}}
				/>
				<IconButton onClick={handleAddComment} disabled={payload["text"] == "" ? true : false}>
					<SendIcon
						sx={(theme) => ({
							cursor: "pointer",
							fill: theme.palette.secondary.main,
						})}
					/>
				</IconButton>
			</Box>
			<Box
				sx={{
					textAlign: "right",
					pr: 6,
				}}
			>
				<Typography variant="caption">Want some feedback? Ask a teammate to comment.</Typography>
			</Box>
			<Box>
				{loading ? (
					<Box
						sx={{
							width: 1,
							display: "flex",
							justifyContent: "center",
							flexDirection: "column",
							alignItems: "center",
						}}
					>
						<CircularProgress />
					</Box>
				) : (
					<>
						{comments?.length > 0 ? (
							<Box>
								{comments?.map((item, i) => {
									return <CommentCard item={item} />;
								})}
								{!isLastComment && (
									<Box
										sx={{
											display: "flex",
											width: 1,
											justifyContent: "center",
											py: 4,
										}}
										ref={setLastElement}
									>
										<CircularProgress />
									</Box>
								)}
							</Box>
						) : (
							<Box
								sx={{
									display: "flex",
									width: 1,
									justifyContent: "center",
									alignContent: "center",
									py: 5,
								}}
							>
								<Typography color="primary" variant="h5" sx={(theme) => ({ color: theme.palette.secondary.dark })}>
									Add a comment
								</Typography>
							</Box>
						)}
					</>
				)}
			</Box>
		</Box>
	);
}
