import React, { useState, useEffect, useRef } from "react";
import { Typography, Grid, Box, Divider, IconButton, Button, AppBar, Toolbar, CircularProgress, Badge } from "@mui/material";
import { Add, Block } from "@mui/icons-material";
import PropTypes from "prop-types";
import WorkspaceUser from "../WorkspaceUser";
import { connect, useDispatch, useSelector } from "react-redux";
import theme from "../../../constants/Theme";
import { useHistory, useLocation, useRouteMatch, useParams } from "react-router-dom";
import { USER_TYPES } from "../../../utils/userTypes";
import TooltipWrapper from "../../../common/Tooltip";
import NotificationsIcon from "@mui/icons-material/Notifications";
import * as actions from "../../../modules/actions";
import MenuIcon from "@mui/icons-material/Menu";
import TemporaryDrawer from "../../../common/Drawer";
import HamburgerPanel from "../HamburgerPanel";
import MessageHandlerModal from "../../../common/modal/MessageHandler";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SearchIcon from "@mui/icons-material/Search";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import PanelListView from "../Sidebar/RightSidebar/components/Carousel/PanelListView";
import CreateQuickNote from "../Sidebar/RightSidebar/components/CreateQuickNote";
import CreateResearchCurator from "../Sidebar/RightSidebar/components/CreateResearchCurator";
import CommentFeed from "../../../containers/CommentFeed";
import services from "../../../services";
import { toastContainer } from "../../../utils/toast";
import ResearchCuratorCard from "../../ResearchCuratorCard";
import ROUTES_PATH from "../../../constants/routes";
import { sosPrimary } from "../../../constants/Theme/colors";
import { POWERUP_IDS, POWERUP_LOGO_WHITE } from "../../../constants";
import PowerupHeaderComp from "./PowerupHeaderComp";
import NotificationPanelButton from "../../NotificationCenter/NotificationPanelButton";
import { handleMixPanel } from "../../../utils/mixPanelEventHandling";

function Header({
	onLogout,
	userData,
	companyName,
	userType,
	userName,
	setAuthData,
	showAccountSettingPopup,
	hasUpdates,
	isPowerupPages,
	headerDetails,
	scrolled = false,
	ratingDetails,
}) {
	const history = useHistory();
	const location = useLocation();

	const [active, setActive] = useState("");
	const [openMenu, setOpenMenu] = useState(false);
	const [confirmPopup, setConfirmPopup] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const [openResearchCuratorPanel, setOpenResearchCuratorPanel] = useState(false);
	const [openNewResearchCurator, setOpenNewResearchCurator] = useState(false);
	const [powerupId, setPowerupID] = useState();
	const startupData = useSelector((store) => store?.auth?.companyData);
	const userDataObj = useState(userData);
	const drawerWidth = 400;
	const [loading, setLoading] = useState(true);
	const [cardData, setCardData] = useState("");
	const [currentCardData, setCurrentCardData] = useState({});
	const [page, setPage] = useState(1);
	const [dataLength, setDataLength] = useState(0);
	const [sort, setSort] = useState("DESC"); //ASC,DESC
	const [lastElement, setLastElement] = useState(null);
	const [isLast, setIsLast] = useState(false);
	const [openQuickNote, setOpenQuickNote] = useState(false);
	const currentSelectedNavItem = useSelector((store) => store?.auth?.currentSelectedNavItem);
	const marketTestData = useSelector((data) => data?.powerUp?.marketTest);
	const dispatch = useDispatch();

	const currentLocation = window.location;
	const cursorPointer = { cursor: "pointer" };

	const [deleteConfirmPopup, setDeleteConfirmPopup] = useState(false);

	const [researchCuratorDetails, setResearchCuratorDetails] = useState();
	const [newCuratorId, setNewCuratorId] = useState("");
	const [callback, setCallback] = useState(false);
	const { url, path } = useRouteMatch();
	const [currentPath, setCurrentPath] = useState("");
	const params = useParams();

	const observer = useRef(
		new IntersectionObserver((entries) => {
			const first = entries[0];
			if (first.isIntersecting) {
				callNextPage();
			}
		})
	);

	const [approvalStatus, setApprovalStatus] = useState();

	useEffect(() => {
		if (!approvalStatus) {
			services.getApprovalStatus().then((status) => {
				setApprovalStatus(status);
			});
		}
	}, [approvalStatus]);

	useEffect(() => {
		if (currentLocation.pathname.split("/")[2] === "powerUp") {
			setPowerupID(params.id);
		}
	});

	const handleHistory = () => {
		//history.goBack();
		const paths = currentLocation.pathname.split("/");
		if (paths[1] === "partner") {
			if (paths.length > 3) {
				history.goBack();
			}
		} else if (currentLocation.pathname.split("/")[2] === "powerup") {
			if (currentLocation.pathname.split("/").length < 5) {
				history.goBack();
			} else {
				if (currentLocation.pathname.split("/")[3] === "md-ideavalidation" || currentLocation.pathname.split("/")[4] === "browse-candidates") {
					history.push(`${ROUTES_PATH.MARKET_TEST}/${marketTestData?.id}`);
				} else {
					history.push(`/workspace/powerUp/${POWERUP_IDS.filter((item) => item.value === currentLocation.pathname.split("/")[3])[0].id}`);
				}
			}
		} else {
			if (currentLocation.pathname.split("/")[2] === "market-test") {
				if (params?.id) {
					history.push(`${ROUTES_PATH.MARKET_TEST}`);
				} else {
					history.push(`${ROUTES_PATH.WORKSPACE}`);
				}
			} else {
				history.goBack();
			}
		}
	};
	useEffect(() => {
		const currentElement = lastElement;
		const currentObserver = observer.current;

		if (currentElement) {
			currentObserver.observe(currentElement);
		}

		return () => {
			if (currentElement) {
				currentObserver.unobserve(currentElement);
			}
		};
	}, [lastElement]);

	useEffect(() => {
		if (openResearchCuratorPanel) {
			getCardDetails();
		}
	}, [openResearchCuratorPanel]);

	const getCardDetails = () => {
		services
			.getNotesListPanel(page, sort, null, 10)
			.then((res) => {
				if (res.data.code === 200) {
					setDataLength(res.data.data.totalElements);
					if (page === 1) {
						setCardData(res.data.data.models);
					} else {
						setCardData([...cardData, ...res.data.data.models]);
					}
					setIsLast(res.data.data.isLast);
					setLoading(false);
					setCallback(false);
				}
			})
			.catch((err) => {
				console.log(err);
				setLoading(false);
				toastContainer(err?.error_description, "error");
			});
	};

	const callNextPage = () => {
		setPage((page) => page + 1);
	};

	useEffect(() => {
		if (page !== 1) {
			getCardDetails();
		}
	}, [page]);

	const handleCardClick = (noteItem) => {
		setCurrentCardData(noteItem);
		if (noteItem.noteType === "NOTE") {
			setOpenNewResearchCurator(true);
			handleMixPanel("Quick Note Viewed", {
				"Quick Note ID": noteItem.id,
			});
		} else {
			setOpenQuickNote(true);
			handleMixPanel("PowerDot Viewed", {
				"PowerDot ID": noteItem.id,
			});
		}
	};

	const handleDeleteNote = () => {
		services
			.deleteNote(currentCardData?.id)
			.then((response) => {
				if (response.data.code === 200) {
					handleDeletePopup();
					setOpenNewResearchCurator(false);
					setOpenQuickNote(false);
					setPage(1);
					getCardDetails();
				}
			})
			.catch((e) => {
				console.log("Submit", e);
				toastContainer("Error in deleting Note", "error");
			});
	};
	const handleDeletePopup = () => {
		setDeleteConfirmPopup(!deleteConfirmPopup);
	};

	useEffect(() => {
		if (callback) {
			setPage(1);
			getCardDetails();
		}
	}, [callback]);

	const ResearchCuratorPanel = () => {
		return (
			<Box
				sx={{
					maxWidth: theme.breakpoints.values.xl,
					margin: "auto",
					width: "100%",
				}}
			>
				<Box>
					<Box sx={{ display: "flex", p: 3, justifyContent: "space-between" }} gap={6}>
						<Typography variant="boldHeading">Discovery Board</Typography>
						<Box sx={{ display: "flex", ml: "auto" }} gap={2}>
							<SearchIcon
								sx={cursorPointer}
								onClick={() => {
									history.push(ROUTES_PATH.RESEARCH_CURATOR_EXPAND);
									setOpenResearchCuratorPanel(false);
									dispatch(setAuthData({ currentSelectedNavItem: "discovery-board" }));
								}}
							/>
							<OpenInNewIcon
								sx={cursorPointer}
								onClick={() => {
									history.push(ROUTES_PATH.RESEARCH_CURATOR_EXPAND);
									setOpenResearchCuratorPanel(false);
									dispatch(setAuthData({ currentSelectedNavItem: "discovery-board" }));
								}}
							/>
							<CloseIcon
								sx={cursorPointer}
								onClick={() => {
									setOpenResearchCuratorPanel(false);
								}}
							/>
						</Box>
					</Box>
					<Divider sx={{ background: (theme) => theme.palette.secondary.dark }} />

					<Box sx={{ pr: 3 }}>
						<Grid
							container
							//xs={12}
							sx={{
								pt: 2,
								ml: 2,
							}}
						>
							<Grid
								container
								// xs={6}
							>
								<Grid item xs={6}>
									{" "}
									<Button
										startIcon={<Add />}
										variant="outlined"
										sx={{ mr: 1 }}
										onClick={() => {
											setOpenQuickNote(true);
											setCurrentCardData({});
										}}
									>
										New&nbsp;Quick&nbsp;Note
									</Button>
								</Grid>
								<Grid item xs={6} sx={{ pl: 1 }}>
									<Button
										startIcon={<AddIcon />}
										onClick={() => {
											setOpenNewResearchCurator(true);
											setCurrentCardData({});
										}}
									>
										New PowerDot
									</Button>
								</Grid>
							</Grid>
						</Grid>
						<Box
							sx={{
								maxWidth: theme.breakpoints.values.xl,
								margin: "auto",
								width: "100%",
							}}
						>
							{loading ? (
								<Box
									sx={{
										display: "flex",
										width: 1,
										justifyContent: "center",
										alignContent: "center",
										pt: 4,
									}}
								>
									<CircularProgress />
								</Box>
							) : (
								<>
									<Box sx={{ my: 1, pl: 3 }}>
										<Grid container>
											<PanelListView
												panelCardData={cardData}
												handleCardClick={handleCardClick}
												dataLength={dataLength}
												setCallback={setCallback}
												callback={callback}
											/>
										</Grid>
									</Box>
									{cardData?.length ? (
										<Box sx={{ my: 1, pl: 3 }}>
											<Box sx={{ pb: 2 }}>
												<Typography variant="title_medium" sx={{ pr: 1 }}>
													Other
												</Typography>{" "}
												<Typography variant="caption" sx={{ color: "secondary.dark" }}>
													{dataLength} Total
												</Typography>
											</Box>
											{cardData &&
												cardData?.map((item, i) => {
													return (
														<ResearchCuratorCard
															cardData={item}
															handleCardClick={() => handleCardClick(item)}
															setCallback={setCallback}
														/>
													);
												})}
										</Box>
									) : (
										<Box
											sx={{
												display: "flex",
												width: 1,
												justifyContent: "center",
												alignContent: "center",
												py: 5,
											}}
										>
											<Typography color="primary" variant="h5" sx={(theme) => ({ color: theme.palette.secondary.dark })}>
												No data found.
											</Typography>
										</Box>
									)}
									{!isLast && (
										<Box
											sx={{
												display: "flex",
												width: 1,
												justifyContent: "center",
												py: 4,
											}}
											ref={setLastElement}
										>
											<CircularProgress />
										</Box>
									)}
								</>
							)}
						</Box>
					</Box>
				</Box>
			</Box>
		);
	};

	//const handleListItemClick = () => {
	//  if (
	//    !(
	//      pathname.split("/")[1] === "discovery-board-expand" ||
	//      pathname.split("/")[1] === "create-discovery-item" ||
	//      pathname.split("/")[1] === "discovery-board"
	//    )
	//  ) {
	//    setOpenResearchCuratorPanel(true);
	//  }
	//};

	useEffect(() => {
		if (currentCardData?.id) {
			setNewCuratorId(currentCardData?.id);
		}
	}, [currentCardData?.id]);

	useEffect(() => {
		if (userType === USER_TYPES.STARTUP_USER || userType === USER_TYPES.STARTUP_OWNER || userType === USER_TYPES.STUDENT_USER) {
			return history.listen((location) => {
				setActive(location.pathname.split("/")[1]);
			});
		} else {
			return history.listen((location) => {
				setActive(location.pathname.split("/")[2] ? location.pathname.split("/")[2] : location.pathname.split("/")[1]);
			});
		}
	}, [history]);

	useEffect(() => {
		if (userType === USER_TYPES.STARTUP_USER || userType === USER_TYPES.STARTUP_OWNER || userType === USER_TYPES.STUDENT_USER) {
			setActive(location.pathname.split("/")[1]);
		} else {
			setActive(location.pathname.split("/")[2]);
		}
	}, []);

	const closeAccountSettingsPopup = () => {
		setAuthData({ showAccountSettingPopup: false });
	};
	const handleOpen = () => {
		setAuthData({ showAccountSettingPopup: true });
		// handleMixPanel("Account Settings Viewed", { //calling on dialog open
		// 	Page: "Overview",
		// });
	};

	const handleOpenMenu = () => {
		setOpenMenu(!openMenu);
	};
	const handleLogout = () => {
		setConfirmPopup(true);
		handleClose();
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const popUpClose = () => {
		setConfirmPopup(false);
		handleClose();
	};

	useEffect(() => {
		//get string between /abcd/ and convert to camel case
		let data;
		if (userType === USER_TYPES.STARTUP_OWNER) {
			data = location.pathname.split("/")[1];
			if (location.pathname.split("/")[1] === "events") {
				data = location.pathname.split("/")[2];
			}
		} else {
			data = location.pathname.split("/")[2] ? location.pathname.split("/")[2] : location.pathname.split("/")[1];
		}

		let currentPath = (" " + data).toLowerCase().replace(/[^a-zA-Z]+(.)/g, function (match, chr) {
			return chr.toUpperCase();
		});

		if (currentPath === "DiscoveryBoardExpand") {
			setCurrentPath("Discovery");
		} else if (currentPath === "Mystartup") {
			setCurrentPath("My Startup");
		} else if (currentPath === "EventSettings") {
			setCurrentPath("Event Settings");
		} else if (currentPath === "EventType") {
			setCurrentPath("Event Type");
		} else {
			setCurrentPath(currentPath);
		}
	});

	const handleListItemClick = () => {
		//  (listItem.key);
		history.push(ROUTES_PATH.MEETINGS_BASEPATH);

		dispatch(setAuthData({ currentSelectedNavItem: "meetings" }));
	};

	return (
		<>
			{""}
			<AppBar
				position="fixed"
				component="nav"
				sx={(theme) => ({
					display: "flex",
					maxWidth: "inherit",
					zIndex: 999,
					background: isPowerupPages ? theme.palette.primary.gradient : theme.palette.secondary.white,
					color: isPowerupPages ? theme.palette.secondary.white : theme.palette.text.primary,
					[theme.breakpoints.down("md")]: {
						background: isPowerupPages ? theme.palette.primary.gradient : theme.palette.secondary.white,
						color: isPowerupPages ? theme.palette.secondary.white : theme.palette.text.primary,
						boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)",
					},
				})}
			>
				<Toolbar
					sx={{
						background: "transparent",
						justifyContent: "space-between",
					}}
				>
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "center",
							alignContent: "center",
							alignItems: "center",
						}}
					>
						<Box>
							<Button
								onClick={() => handleHistory()}
								variant="link"
								sx={{
									color: (theme) => (isPowerupPages ? "secondary.white" : theme.palette.secondary.korophKoal),
									display: { xs: "none", md: "flex" },
								}}
								startIcon={<ArrowBackIcon />}
							>
								Back
							</Button>
							<IconButton
								sx={{
									color: isPowerupPages ? "secondary.white" : theme.palette.text.primary,
									display: { xs: "block", md: "none" },
								}}
								size="small"
								onClick={handleOpenMenu}
							>
								<MenuIcon />
							</IconButton>
						</Box>
						{isPowerupPages && scrolled && (
							<Box
								sx={{
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									gap: 2,
								}}
							>
								<Divider
									flexItem
									sx={{
										color: "secondary.white",
										borderColor: "secondary.white",
										borderWidth: "1px",
										display: { xs: "none", md: "block" },
									}}
								/>
								<Box sx={{ display: { xs: "none", md: "block" }, pt: "4px" }}>
									<img src={POWERUP_LOGO_WHITE} />{" "}
								</Box>
								<Box sx={{ ml: { xs: 5, sm: 0 } }}>
									<Typography variant="headline6">{headerDetails?.title}</Typography>
								</Box>
							</Box>
						)}
					</Box>

					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							alignContent: "center",
							gap: 2,
						}}
					>
						<Box sx={{ display: { xs: "none", md: "flex" }, flexDirection: "row" }}>
							<Button
								variant="link"
								sx={(theme) => ({
									borderRadius: theme.shape.standard25.borderRadius,
									"&:hover": {
										border: `1px solid ${isPowerupPages ? theme.palette.secondary.white : theme.palette.primary.sysLight}`,
										backgroundColor: sosPrimary[50],
									},
									border: active === "marketplace" ? `1px solid ${theme.palette.primary.sysLight}` : "1px solid transparent",
									color: isPowerupPages
										? "secondary.white"
										: active === "marketplace"
										? theme.palette.text.darkBlue
										: theme.palette.text.onSurfaceVariant,
									mr: 1,
								})}
								//onClick={() => {
								//	setActive("marketplace");
								//}}
								href="https://startupos.getproven.com/"
								target="_blank"
							>
								Marketplace
							</Button>
							<Button
								variant="link"
								sx={(theme) => ({
									borderRadius: theme.shape.standard25.borderRadius,
									"&:hover": {
										border: `1px solid ${isPowerupPages ? theme.palette.secondary.white : theme.palette.primary.sysLight}`,
										backgroundColor: sosPrimary[50],
									},
									border: active === "events" ? `1px solid ${theme.palette.primary.sysLight}` : "1px solid transparent",
									color: isPowerupPages
										? "secondary.white"
										: active === "events"
										? theme.palette.text.darkBlue
										: theme.palette.text.onSurfaceVariant,
									mr: 1,
								})}
								onClick={() => {
									history.push(ROUTES_PATH.EVENTS_BASEPATH);
								}}
							>
								Events
							</Button>
							{approvalStatus === "APPROVED" && (
								<Button
									variant="link"
									sx={(theme) => ({
										borderRadius: theme.shape.standard25.borderRadius,
										"&:hover": {
											border: `1px solid ${isPowerupPages ? theme.palette.secondary.white : theme.palette.primary.sysLight}`,
											backgroundColor: sosPrimary[50],
										},
										border: active === "meetings" ? `1px solid ${theme.palette.primary.sysLight}` : "1px solid transparent",
										color: isPowerupPages
											? "secondary.white"
											: active === "meetings"
											? theme.palette.text.darkBlue
											: theme.palette.text.onSurfaceVariant,
										mr: 1,
									})}
									onClick={handleListItemClick}
								>
									Meetings
								</Button>
							)}

							{approvalStatus === "APPROVED" && (
								<Badge
									color="primary"
									overlap="circular"
									badgeContent={hasUpdates}
									anchorOrigin={{
										vertical: "top",
										horizontal: "right",
									}}
									invisible={hasUpdates ? false : true}
								>
									<Button
										variant="link"
										sx={(theme) => ({
											borderRadius: theme.shape.standard25.borderRadius,
											"&:hover": {
												border: `1px solid ${isPowerupPages ? theme.palette.secondary.white : theme.palette.primary.sysLight}`,
												backgroundColor: sosPrimary[50],
											},
											border: active === "messages" ? `1px solid ${theme.palette.primary.sysLight}` : "1px solid transparent",
											color: isPowerupPages
												? "secondary.white"
												: active === "messages"
												? theme.palette.text.darkBlue
												: theme.palette.text.onSurfaceVariant,
											mr: 1,
										})}
										onClick={() => {
											history.push(ROUTES_PATH.MESSAGES);
											handleMixPanel("Messages Page Viewed", {
												"Message Thread Count": hasUpdates,
											});
										}}
									>
										Messages
									</Button>
								</Badge>
							)}

							{/*<Button
                variant="link"
                sx={theme => ({
                  borderRadius: theme.shape.standard25.borderRadius,
                  "&:hover": {
                    border: `1px solid ${theme.palette.primary.sysLight}`,
                    backgroundColor: sosPrimary[50],
                  },
                  border:
                    active === "messages"
                      ? `1px solid ${theme.palette.primary.sysLight}`
                      : "1px solid transparent",
                  color:
                    active === "messages"
                      ? theme.palette.text.darkBlue
                      : theme.palette.text.onSurfaceVariant,
                })}
                onClick={() => {
                  history.push(ROUTES_PATH.MESSAGES);
                  dispatch(setAuthData({ currentSelectedNavItem: "messages" }));
                }}
              >
                Messages
              </Button>*/}

							<Button
								variant="link"
								sx={(theme) => ({
									borderRadius: theme.shape.standard25.borderRadius,
									"&:hover": {
										border: `1px solid ${isPowerupPages ? theme.palette.secondary.white : theme.palette.primary.sysLight}`,
										backgroundColor: sosPrimary[50],
									},
									border: active === "notes" ? `1px solid ${theme.palette.primary.sysLight}` : "1px solid transparent",
									color: isPowerupPages
										? "secondary.white"
										: active === "notes"
										? theme.palette.text.darkBlue
										: theme.palette.text.onSurfaceVariant,
								})}
								onClick={() => {
									setOpenResearchCuratorPanel(true);
								}}
							>
								Notes
							</Button>

							<NotificationPanelButton />
						</Box>

						<Box sx={{ display: "flex", flexDirection: "row" }}>
							<WorkspaceUser
								onLogout={onLogout}
								userData={userData}
								showAccountSettingPopup={showAccountSettingPopup}
								closeAccountSettingsPopup={closeAccountSettingsPopup}
								handleOpen={handleOpen}
							/>
						</Box>
					</Box>
				</Toolbar>
				{isPowerupPages && !scrolled && (
					<>
						<Box
							sx={{
								display: { xs: "block", md: "none" },
							}}
						>
							<Button
								onClick={() => handleHistory()}
								variant="link"
								sx={{
									width: "max-content",
									ml: 0,
									color: (theme) => (isPowerupPages ? "secondary.white" : theme.palette.secondary.korophKoal),
								}}
								startIcon={<ArrowBackIcon />}
							>
								Back
							</Button>
						</Box>
						<PowerupHeaderComp data={headerDetails} ratingDetails={ratingDetails} />{" "}
					</>
				)}
			</AppBar>

			<TemporaryDrawer
				open={openMenu}
				position={"left"}
				hideBackdrop={false}
				onClose={handleOpenMenu}
				sx={(theme) => ({
					zIndex: (theme) => theme.zIndex.drawer + 2,
					"& .MuiPaper-root": {
						width: { xs: "70%", sm: "40%" },
						background:
							"linear-gradient(0deg, rgba(106, 117, 117, 0.02), rgba(106, 117, 117, 0.02)), linear-gradient(0deg, rgba(245, 248, 250, 0.05), rgba(245, 248, 250, 0.05)), #FCFCFC",
					},
				})}
			>
				<Box>
					<HamburgerPanel
						handleOpenMenu={handleOpenMenu}
						userData={userData}
						showAccountSettingPopup={showAccountSettingPopup}
						closeAccountSettingsPopup={closeAccountSettingsPopup}
						handleOpen={handleOpen}
						handleLogout={handleLogout}
						userType={userType}
						companyName={companyName}
						userName={userName}
					/>
				</Box>
			</TemporaryDrawer>
			<MessageHandlerModal
				isOpen={confirmPopup}
				onCancel={popUpClose}
				onOk={() => onLogout()}
				okText="Ok"
				cancelText={"Cancel"}
				heading="Logout"
				text={"Are you sure you want to logout?"}
				messageType="primary"
			/>
			<TemporaryDrawer
				open={openResearchCuratorPanel}
				position={"right"}
				hideBackdrop={true}
				onClose={() => {
					setOpenResearchCuratorPanel(false);
				}}
				sx={(theme) => ({
					"& .MuiPaper-root": {
						width: drawerWidth,
						borderLeft: `1px solid ${theme.palette.secondary.outline2}`,
					},
				})}
			>
				<Box>{ResearchCuratorPanel()}</Box>
			</TemporaryDrawer>
			<TemporaryDrawer
				open={openNewResearchCurator}
				position={"right"}
				hideBackdrop={true}
				onClose={() => {
					setOpenNewResearchCurator(false);
				}}
				onBackdropClick={getCardDetails}
				sx={(theme) => ({
					"& .MuiPaper-root": {
						mt: 3,
						width: drawerWidth,
						overflowY: "scroll",
						borderLeft: `1px solid ${theme.palette.secondary.outline2}`,
					},
				})}
			>
				<CreateResearchCurator
					userDataObj={userDataObj}
					startupData={startupData}
					openNewResearchCurator={openNewResearchCurator}
					setOpenNewResearchCurator={setOpenNewResearchCurator}
					openResearchCuratorPanel={openResearchCuratorPanel}
					setOpenResearchCuratorPanel={setOpenResearchCuratorPanel}
					isExpandedView={false}
					researchCuratorId={currentCardData?.id}
					isDetailView={currentCardData?.id && !currentCardData?.isCreatedByUser}
					getCardDetails={getCardDetails}
					handleDeletePopup={handleDeletePopup}
					setResearchCuratorDetails={setResearchCuratorDetails}
					researchCuratorDetails={researchCuratorDetails}
					setNewCuratorId={setNewCuratorId}
					callback={callback}
					setCallback={setCallback}
				/>

				{newCuratorId && (
					<Box sx={{ px: 3, mb: 5, position: "relative" }}>
						<CommentFeed noteId={newCuratorId} />
					</Box>
				)}
			</TemporaryDrawer>
			<TemporaryDrawer
				open={openQuickNote}
				position={"right"}
				hideBackdrop={true}
				onClose={() => {
					setOpenQuickNote(false);
				}}
				onBackdropClick={getCardDetails}
				sx={(theme) => ({
					"& .MuiPaper-root": {
						mt: 3,
						width: drawerWidth,
						overflowY: "scroll",
						background: (theme) => theme.palette.secondary.quickNoteBg,
						borderLeft: `1px solid ${theme.palette.secondary.outline2}`,
					},
				})}
			>
				<CreateQuickNote
					userDataObj={userDataObj}
					startupData={startupData}
					openQuickNote={openQuickNote}
					setOpenQuickNote={setOpenQuickNote}
					openResearchCuratorPanel={openResearchCuratorPanel}
					setOpenResearchCuratorPanel={setOpenResearchCuratorPanel}
					isExpandedView={false}
					researchCuratorId={currentCardData?.id}
					isDetailView={currentCardData?.id && !currentCardData?.isCreatedByUser}
					getCardDetails={getCardDetails}
					handleDeletePopup={handleDeletePopup}
					setResearchCuratorDetails={setResearchCuratorDetails}
					researchCuratorDetails={researchCuratorDetails}
					setNewCuratorId={setNewCuratorId}
					callback={callback}
					setCallback={setCallback}
				/>
			</TemporaryDrawer>
			<MessageHandlerModal
				isOpen={deleteConfirmPopup}
				onCancel={handleDeletePopup}
				onOk={handleDeleteNote}
				okText="Delete"
				cancelText={"No thanks"}
				heading="Delete Note"
				text={"Are you sure you want to delete this note?"}
				messageType="primary"
			/>
		</>
	);
}

Header.propTypes = {
	onLogout: PropTypes.func,
	userData: PropTypes.object,
};

Header.defaultProps = {
	onBack: undefined,
	userData: undefined,
};

const mapStoreToProps = ({ auth, powerUp }) => ({
	companyName: auth?.companyName,
	userType: auth?.userType,
	userName: auth?.userName,
	showAccountSettingPopup: auth?.showAccountSettingPopup,
});

const mapDispatchToProps = (dispatch) => ({
	setAuthData: (updatedData) => dispatch(actions.setAuthData(updatedData)),
});

export default connect(mapStoreToProps, mapDispatchToProps)(Header);
