import { Box, Tab, Tabs, Typography } from "@mui/material";
import React, { Fragment, useState, useEffect } from "react";
import { PowerUpsActivityColumnsDef } from "./PowerUpsActivityColumnsDef";
import OpportunityTableController from "../../../../containers/Investor/Dashboard/AllStartups/OpportunityTableController";
import { MeetingHistoryColumnsDef } from "./MeetingHistoryColumnsDef";
import services from "../../../../services";
import moment from "moment";
import { useSelector } from "react-redux";
const PlatformActivitySection = ({ companyId }) => {
	const [currentTab, setCurrentTab] = useState(0);

	// const companyId = useSelector((store) => store?.auth?.companyData?.companyId);

	const PLATFORM_ACTIVITIES = [
		{ id: 0, title: "PowerUps Activity" },
		{ id: 1, title: "Meeting History" },
	];

	const handleChange = (event, newValue) => {
		setCurrentTab(newValue);
	};

	const getPlatformActivites = async (page, perPage, currentTab, companyId) => {
		if (currentTab === 0 && companyId) {
			try {
				const response = await services.getPowerUpActivityApi({
					companyId: companyId,
					pageNo: page + 1,
					pageSize: perPage,
				});

				const { totalElements, isFirst, isLast, models } = response?.data?.data;
				const newModels = models
					?.map((item) => {
						const latestResponse = item.responses.reduce((latest, response) => {
							const currentStartDate = moment(response.modifiedDate);
							if (!latest || currentStartDate.isAfter(moment(latest.modifiedDate))) {
								return response;
							}
							return latest;
						}, null);

						if (!latestResponse) {
							return null;
						}

						const { id, modifiedDate, modifiedBy, moduleModel, moduleStepResponseModels, isCompleted } = latestResponse;

						return {
							id: id,
							powerUpDateAndTime: modifiedDate,
							powerUp: item?.name,
							status: isCompleted ? "Completed" : "Not started",
							name: modifiedBy,
							resultsLink: {
								answers: moduleStepResponseModels ? moduleStepResponseModels[0]?.answers : "",
								modelUuid: moduleModel?.moduleUuid,
								powerUpName: item?.name,
							},
						};
					})
					.filter((item) => item !== null);

				return {
					models: newModels ? newModels : [],
					totalElements: totalElements,
					isFirst: isFirst,
					isLast: isLast,
				};
			} catch (error) {
				console.log(`Error fetching Powerup Activity`, error);
			}
		} else if (currentTab === 1 && companyId) {
			try {
				const response = await services.getMeetingHisotryApi({
					companyId: companyId,
					pageNo: page + 1,
					pageSize: perPage,
					timezone: moment.tz.guess(),
				});
				if (response) {
					const { totalElements, isFirst, isLast, models } = response?.data?.data;

					const newModels = models?.map((item) => {
						const attendeeNames = item?.attendees?.map((attendee) => attendee?.user?.name).join(", ");
						return {
							id: item?.id,
							meetingDateAndTime: item?.startTime,
							title: item?.title,
							attendee: attendeeNames || "-",
							rescheduled: item?.isRescheduled ? "Yes" : "No",
							cancelled: item?.status && item?.status === "CANCELLED" ? "Yes" : "No",
						};
					});

					return {
						models: newModels ? newModels : [],
						totalElements: totalElements,
						isFirst: isFirst,
						isLast: isLast,
					};
				}
			} catch (error) {
				console.error("Error fetching meeting history:", error);
			}
		} else {
			return new Promise((resolve) => {
				setTimeout(() => {
					const dummyData = {
						models: [],
						totalElements: 10,
						isFirst: true,
						isLast: false,
					};

					resolve(dummyData);
				}, 1000);
			});
		}
	};

	return (
		<Box sx={{ display: "flex", flexDirection: "column", gap: 5 }}>
			<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
				<Tabs
					variant="scrollable"
					scrollButtons="auto"
					allowScrollButtonsMobile
					value={currentTab}
					onChange={handleChange}
					sx={{
						alignItems: "stretch",
						"& .MuiTabs-flexContainer": {
							gap: 4,
						},
						"& .MuiTabs-indicator": {
							backgroundColor: (theme) => theme.palette.primary.main,
							borderRadius: "5px",
							height: "2px",
						},
						"& .MuiTabs-scrollButtons": {
							color: (theme) => theme.palette.primary.main,
							"& > svg": {
								height: "30px",
								width: "30px",
							},
						},
					}}
				>
					{PLATFORM_ACTIVITIES.map((item, index) => {
						const tabItem = (
							<Tab
								selected={currentTab === item.id}
								onClick={(event) => {
									handleChange(event, index);
								}}
								disableRipple
								key={item.id}
								sx={{
									textTransform: "unset",
									textAlign: "left",
									p: 0,
									pb: 1,
									whiteSpace: "nowrap",
									maxWidth: "unset",
									opacity: ".6",
									filter: "grayscale(100%)",
									"&.Mui-selected": {
										opacity: 1,
										filter: "grayscale(0%)",
									},
								}}
								label={
									<Typography
										variant="Text/xs/Semibold"
										sx={{
											color: (theme) => theme.palette.primary.main,
										}}
									>
										{item.title}
									</Typography>
								}
							/>
						);
						return <Box key={index}>{tabItem}</Box>;
					})}
				</Tabs>
			</Box>
			{currentTab === 0 && (
				<Box>
					<OpportunityTableController
						autoHeight={false}
						requestData={(page, perPage) => getPlatformActivites(page, perPage, currentTab, companyId)}
						columnDefs={PowerUpsActivityColumnsDef()}
						title="PowerUps Activity"
						showCount
						icon={
							<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
								<path
									d="M20 28V20.8C20 20.0533 20 19.6799 19.8547 19.3947C19.7268 19.1438 19.5229 18.9398 19.272 18.812C18.9868 18.6667 18.6134 18.6667 17.8667 18.6667H14.1333C13.3866 18.6667 13.0132 18.6667 12.728 18.812C12.4771 18.9398 12.2732 19.1438 12.1453 19.3947C12 19.6799 12 20.0533 12 20.8V28M25.3333 28V8.26667C25.3333 6.77319 25.3333 6.02646 25.0427 5.45603C24.787 4.95426 24.3791 4.54631 23.8773 4.29065C23.3069 4 22.5601 4 21.0667 4H10.9333C9.43986 4 8.69312 4 8.12269 4.29065C7.62093 4.54631 7.21298 4.95426 6.95732 5.45603C6.66667 6.02646 6.66667 6.77319 6.66667 8.26667V28M28 28H4M12.6667 10.6667H12.68M19.3333 10.6667H19.3467M13.3333 10.6667C13.3333 11.0349 13.0349 11.3333 12.6667 11.3333C12.2985 11.3333 12 11.0349 12 10.6667C12 10.2985 12.2985 10 12.6667 10C13.0349 10 13.3333 10.2985 13.3333 10.6667ZM20 10.6667C20 11.0349 19.7015 11.3333 19.3333 11.3333C18.9651 11.3333 18.6667 11.0349 18.6667 10.6667C18.6667 10.2985 18.9651 10 19.3333 10C19.7015 10 20 10.2985 20 10.6667Z"
									stroke={(theme) => theme?.palette?.primary?.main}
									stroke-width="1.5"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
							</svg>
						}
						dataGridProps={{
							disableColumnMenu: true,
							checkboxSelection: false,
						}}
						noResultsMessage={
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
									gap: "16px",
									alignSelf: "stretch",
									flex: 1,
									justifyContent: "center",
								}}
							>
								<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
									<path
										d="M28 28L23.3335 23.3333M26.6667 15.3333C26.6667 21.5926 21.5926 26.6667 15.3333 26.6667C9.07411 26.6667 4 21.5926 4 15.3333C4 9.07411 9.07411 4 15.3333 4C21.5926 4 26.6667 9.07411 26.6667 15.3333Z"
										stroke={(theme) => theme?.palette?.primary?.main}
										stroke-width="1.5"
										stroke-linecap="round"
										stroke-linejoin="round"
									/>
								</svg>
								<Typography variant="Text/sm/Semibold">Platform Activity Not Found</Typography>
							</Box>
						}
					/>
				</Box>
			)}

			{currentTab === 1 && (
				<Box>
					<OpportunityTableController
						autoHeight={false}
						requestData={(page, perPage) => getPlatformActivites(page, perPage, currentTab, companyId)}
						columnDefs={MeetingHistoryColumnsDef()}
						title="Meeting History"
						showCount
						icon={
							<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
								<path
									d="M20 28V20.8C20 20.0533 20 19.6799 19.8547 19.3947C19.7268 19.1438 19.5229 18.9398 19.272 18.812C18.9868 18.6667 18.6134 18.6667 17.8667 18.6667H14.1333C13.3866 18.6667 13.0132 18.6667 12.728 18.812C12.4771 18.9398 12.2732 19.1438 12.1453 19.3947C12 19.6799 12 20.0533 12 20.8V28M25.3333 28V8.26667C25.3333 6.77319 25.3333 6.02646 25.0427 5.45603C24.787 4.95426 24.3791 4.54631 23.8773 4.29065C23.3069 4 22.5601 4 21.0667 4H10.9333C9.43986 4 8.69312 4 8.12269 4.29065C7.62093 4.54631 7.21298 4.95426 6.95732 5.45603C6.66667 6.02646 6.66667 6.77319 6.66667 8.26667V28M28 28H4M12.6667 10.6667H12.68M19.3333 10.6667H19.3467M13.3333 10.6667C13.3333 11.0349 13.0349 11.3333 12.6667 11.3333C12.2985 11.3333 12 11.0349 12 10.6667C12 10.2985 12.2985 10 12.6667 10C13.0349 10 13.3333 10.2985 13.3333 10.6667ZM20 10.6667C20 11.0349 19.7015 11.3333 19.3333 11.3333C18.9651 11.3333 18.6667 11.0349 18.6667 10.6667C18.6667 10.2985 18.9651 10 19.3333 10C19.7015 10 20 10.2985 20 10.6667Z"
									stroke={(theme) => theme?.palette?.primary?.main}
									stroke-width="1.5"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
							</svg>
						}
						dataGridProps={{
							disableColumnMenu: true,
							checkboxSelection: false,
						}}
						noResultsMessage={
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
									gap: "16px",
									alignSelf: "stretch",
									flex: 1,
									justifyContent: "center",
								}}
							>
								<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
									<path
										d="M28 28L23.3335 23.3333M26.6667 15.3333C26.6667 21.5926 21.5926 26.6667 15.3333 26.6667C9.07411 26.6667 4 21.5926 4 15.3333C4 9.07411 9.07411 4 15.3333 4C21.5926 4 26.6667 9.07411 26.6667 15.3333Z"
										stroke={(theme) => theme?.palette?.primary?.main}
										stroke-width="1.5"
										stroke-linecap="round"
										stroke-linejoin="round"
									/>
								</svg>
								<Typography variant="Text/sm/Semibold">Meeting History Not Found</Typography>
							</Box>
						}
					/>
				</Box>
			)}
		</Box>
	);
};

export default PlatformActivitySection;
