import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import numeral from "numeral";

import messages from "../../../../../../../constants/messages";
import { InputFieldsWrapper, InputLabelField, TextInpuField } from "../../styles";

export default function MarketTemperature({ userInputs, setUserInputs, marketingTemperatureInputValid }) {
	const [errorMessages, setErrorMessages] = useState({
		largerMarket: "",
		largerMarketValue: "",
		marketQuote: "",
		personQuoted: "",
		projectedGrowth: "",
	});

	const handleInputChange = (event, key) => {
		let value = event.target.value.trimStart();
		if (value && key === "largerMarketValue") {
			value = numeral(value).format("0,0");
		}

		const currentInput = { ...userInputs };
		currentInput[key] = value;
		setUserInputs(currentInput);
	};

	const handleBlur = (event, key) => {
		const value = event.target.value;

		switch (key) {
			case "largerMarket":
				if (marketingTemperatureInputValid(key, value)) {
					setErrorMessages({ ...errorMessages, largerMarket: "" });
				} else {
					setErrorMessages({
						...errorMessages,
						largerMarket: messages.TAM_SAM_SOM_TEXTFIELD,
					});
				}
				break;

			case "largerMarketValue":
				if (marketingTemperatureInputValid(key, value)) {
					setErrorMessages({ ...errorMessages, largerMarketValue: "" });
				} else {
					setErrorMessages({
						...errorMessages,
						largerMarketValue: messages.TAM_SAM_SOM_NUMBERFIELD,
					});
				}
				break;

			case "personQuoted":
				if (marketingTemperatureInputValid(key, value)) {
					setErrorMessages({ ...errorMessages, personQuoted: "" });
				} else {
					setErrorMessages({
						...errorMessages,
						personQuoted: messages.TAM_SAM_SOM_TEXTFIELD,
					});
				}
				break;

			case "marketQuote":
				if (marketingTemperatureInputValid(key, value)) {
					setErrorMessages({ ...errorMessages, marketQuote: "" });
				} else {
					setErrorMessages({
						...errorMessages,
						marketQuote: messages.TAM_SAM_SOM_TEXTFIELD.replace("30", "300"),
					});
				}
				break;

			case "projectedGrowth":
				if (marketingTemperatureInputValid(key, value)) {
					setErrorMessages({ ...errorMessages, projectedGrowth: "" });
				} else {
					setErrorMessages({
						...errorMessages,
						projectedGrowth: "Invalid",
					});
				}
				break;
		}
	};

	return (
		<Box>
			<InputFieldsWrapper>
				<InputLabelField variant="h4" sx={{ whiteSpace: "nowrap" }}>
					My larger market (TAM),
				</InputLabelField>
				<TextInpuField
					placeholder="trucking"
					onChange={e => handleInputChange(e, "largerMarket")}
					value={userInputs?.largerMarket}
					fullWidth
					onBlur={e => handleBlur(e, "largerMarket")}
					error={!!errorMessages.largerMarket}
					helperText={errorMessages.largerMarket}
				/>
				<InputLabelField>, is currently worth</InputLabelField>
			</InputFieldsWrapper>

			<InputFieldsWrapper>
				<TextInpuField
					placeholder="#"
					isNumber={false}
					value={userInputs?.largerMarketValue}
					onChange={e => handleInputChange(e, "largerMarketValue")}
					error={!!errorMessages.largerMarketValue}
					onBlur={e => handleBlur(e, "largerMarketValue")}
					helperText={errorMessages.largerMarketValue}
				/>
				<InputLabelField>dollars, and it’s</InputLabelField>
				<InputLabelField>projected to grow</InputLabelField>
				<TextInpuField
					placeholder="#"
					isNumber={false}
					value={userInputs?.projectedGrowth}
					onChange={e => handleInputChange(e, "projectedGrowth")}
					error={!!errorMessages.projectedGrowth}
					onBlur={e => handleBlur(e, "projectedGrowth")}
				/>
			</InputFieldsWrapper>

			<InputFieldsWrapper>
				<InputLabelField wrap={true}>percent over the next 5 years.</InputLabelField>
			</InputFieldsWrapper>
			<InputFieldsWrapper>
				<InputLabelField>Thought leader/investment guru/etc.</InputLabelField>
				<TextInpuField
					placeholder="Warren Buffet"
					fullWidth
					onChange={e => handleInputChange(e, "personQuoted")}
					value={userInputs?.personQuoted}
					error={!!errorMessages.personQuoted}
					onBlur={e => handleBlur(e, "personQuoted")}
					helperText={errorMessages.personQuoted}
				/>
			</InputFieldsWrapper>
			<InputFieldsWrapper>
				<InputLabelField>was quoted as saying this about my market:</InputLabelField>
			</InputFieldsWrapper>
			<InputFieldsWrapper>
				<TextInpuField
					placeholder="“Trucking, and more so individual truckers, are the lifeblood of this country.”"
					onChange={e => handleInputChange(e, "marketQuote")}
					value={userInputs?.marketQuote}
					error={!!errorMessages.marketQuote}
					onBlur={e => handleBlur(e, "marketQuote")}
					helperText={errorMessages.marketQuote}
					fullWidth
				/>
			</InputFieldsWrapper>
		</Box>
	);
}
