export const steps = [
  {
    label: "My Role",
    complete: false, // Needs to be false for prod
  },

  {
    label: "Startup Interest",
    complete: false, // Needs to be false for prod
  },
  {
    label: "Group Affiliation",
    complete: false,
  },
  {
    label: "About Me",
    complete: false,
  },
  {
    label: "Industry Focus",
    complete: false,
  },
];

export const invitedSteps = [
  {
    label: "Startup Interest",
    complete: false, // Needs to be false for prod
  },
  {
    label: "About Me",
    complete: false,
  },
  {
    label: "Industry Focus",
    complete: false,
  },
  {
    label: "Group Affiliation",
    complete: false,
  },
];
