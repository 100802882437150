import React, { useState, useEffect } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import LoadingButton from "../../../common/Loadingbutton";
import { useDispatch, useSelector } from "react-redux";
import services from "../../../services";
import { toastContainer } from "../../../utils/toast";
import messages from "../../../constants/messages";
import PitchDeckViewer from "../../Profile/StartupProfile/StartupPitchDeck/PitchDeckViewer";
import UploadFileSlidingModal from "../../../common/UploadFileSlidingModal";
import { setUserProfileData } from "../../../modules/actions/userProfileActions";

const PitchDeckTab = ({ handleClose }) => {
	const userDetails = useSelector((state) => state?.userProfileData?.userData);
	const [payLoad, setPayLoad] = useState({ ...userDetails });
	const [isLoading, setIsLoading] = useState(false);
	const openStartupProfileObj = useSelector((state) => state?.startupProfile?.openStartupProfileObj);
	const dispatch = useDispatch();

	useEffect(() => {
		if (userDetails) {
			setPayLoad({ ...userDetails });
		}
	}, [userDetails]);
	useEffect(() => {
		if (openStartupProfileObj?.pitchDeckLink) {
			payLoad["pitchDeckLink"] = openStartupProfileObj?.pitchDeckLink;
			setPayLoad({ ...payLoad });
		}
	}, [openStartupProfileObj]);

	const handleUpdateUser = () => {
		setIsLoading(true);
		let payloadData = payLoad ? payLoad : userDetails;
		const updatedTagList =
			payloadData?.companyTags?.length > 0
				? payloadData?.companyTags?.map((item) => {
						return Number(item?.scoreTag?.id);
				  })
				: [];
		services
			.updateCompanyProfile({
				...payloadData,
				companyTags: updatedTagList,
			})
			.then((response) => {
				setIsLoading(false);
				if (response.data.message === "Success") {
					dispatch(
						setUserProfileData({
							userData: response?.data?.data,
						})
					);
					handleClose();
					// toastContainer(messages.CHANGES_SAVE_SUCCESS, "success");
				}
			})
			.catch((e) => {
				console.log(e);
				setIsLoading(false);
			});
	};

	return (
		<Box>
			<Grid container rowSpacing={3} justifyContent="center" alignItems="center">
				<Grid item xs={12} sm={12} md={6} lg={10} xl={10}>
					<Typography variant="poppinsSemiBold20" component={"p"} sx={{ textAlign: "center" }}>
						Pitch Deck
					</Typography>
					<PitchDeckViewer openStartupProfileObj={{ pitchDeckLink: payLoad["pitchDeckLink"] }} isEdit={true} />
				</Grid>
			</Grid>

			<Box
				sx={{
					display: "flex",
					gap: 2,
					justifyContent: "end",
					alignItems: "center",
					mt: 3,
				}}
			>
				<Button
					onClick={handleClose}
					variant="noOutlineNew"
					sx={{
						height: "40px",
						m: 0,
						width: "fit-content",
						fontSize: "18px",
						lineHeight: "28px",
						fontFamily: "PoppinsSemiBold",
					}}
				>
					Cancel
				</Button>
				<LoadingButton
					loaderColor={"#7B61FF"}
					loadingStyles={{
						border: "4px solid",
						backgroundColor: "white",
						borderColor: "#7B61FF",
						height: "60px",
						px: 4,
					}}
					loading={isLoading}
					onClick={!isLoading ? handleUpdateUser : () => {}}
					variant="DS1"
				>
					Save
				</LoadingButton>
			</Box>
			<UploadFileSlidingModal />
		</Box>
	);
};

export default PitchDeckTab;
