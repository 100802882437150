import React, { useEffect } from "react";
import {
  Box,
  Button,
  Card,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Grid,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useHistory } from "react-router-dom";

export default function InviteTable({ details }) {
  const history = useHistory();

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={12}>
          <Card size="large" variant="outlined" sx={{ mt: 3 }}>
            <Box>
              <TableContainer>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">
                        <Typography
                          variant="title_small"
                          sx={{ whiteSpace: "nowrap" }}
                        >
                          Invite Status
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography
                          variant="title_small"
                          sx={{ whiteSpace: "nowrap" }}
                        >
                          Interview Status
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography
                          variant="title_small"
                          sx={{ whiteSpace: "nowrap" }}
                        >
                          Interview Date
                        </Typography>
                      </TableCell>
                      <TableCell align="left"></TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="left">Sent</TableCell>
                      <TableCell align="left"> -- </TableCell>
                      <TableCell align="left"> -- </TableCell>
                      <TableCell align="left">
                        {" "}
                        <Button
                          startIcon={
                            <ArrowForwardIosIcon sx={{ maxHeight: "15px" }} />
                          }
                          variant="contained"
                          sx={{ width: "max-content" }}
                          onClick={() =>
                            history.push({
                              pathname:
                                "/workspace/powerup/4/interviewing-your-candidate",
                              state: { email: details?.email },
                            })
                          }
                        >
                          View interview details
                        </Button>{" "}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
