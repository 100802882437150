import React from "react";
import { useParams } from "react-router-dom";
import SectionList from "../Sections/SectionList";

// Category Page displays a list of sections.
function CategoryPage() {
	const { category_id } = useParams();
	return <SectionList category_id={category_id} />;
}

export default CategoryPage;
