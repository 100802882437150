import React, { useRef, useState } from 'react'

import MUIRichTextEditor from 'mui-rte'

import { Box } from '@mui/material'
import { Backup } from '@mui/icons-material'

const Editor = ({ label, onSave, defaultValue, getMediaUrl }) => {
	const inputRef = useRef(null)
	const editorRef = useRef(null)
	const [ clicked, setClicked ] = useState(false)

	return <Box sx={theme => ({
		minHeight: 208,
		border: `1px solid ${theme.palette.containerBorder.lightOutline}`,
		px: 2,
		borderRadius: theme.shape.standard.borderRadius
	})}>
		<input
			ref={inputRef}
			type="file"
			accept="image/*, video/*"
			style={{
				position: 'absolute',
				width: 1,
				height: 1,
				padding: 0,
				margin: -1,
				overflow: 'hidden',
				clip: 'rect(0, 0, 0, 0)',
				border: 0
			}}
			onChange={(e) => {
				const files = e.target.files
				if (getMediaUrl && files && files[0]) {
					const type = files[0].type ?? ''
					// EditorState.forceSelection(editorState, selectionState)
					// editorRef?.current?.focus()
					editorRef?.current?.insertAtomicBlockAsync('IMAGE', new Promise(async (resolve, reject) => {
						const url = await getMediaUrl(files[0])
						setClicked(false)
						if (!url) {
							reject()
							return
						}
						resolve({
							data: {
								url,
								alignment: 'left', // or "center", "right"
								type: type.startsWith('video/') ? 'video' : 'image'
							}
						})
					}), 'Uploading media...')
				}
			}}
		/>
		<MUIRichTextEditor
			ref={editorRef}
			label={label}
			onBlur={() => !clicked ? editorRef?.current?.save() : undefined}
			onSave={onSave}
			defaultValue={defaultValue}
			controls={[ 'undo', 'redo', 'title', 'bold', 'italic', 'underline', 'strikethrough', 'highlight', 'link', 'media', 'upload', 'numberList', 'bulletList', 'quote', 'code', 'clear' ]}
			inlineToolbar={true}
			customControls={[ {
				name: 'upload',
				type: 'callback',
				icon: <Backup/>,
				inlineStyle: {},
				onClick: () => {
					setClicked(true)
					inputRef.current?.click()
				}
			} ]}
		/>
	</Box>
}

export default Editor
