import React, { useState, useEffect } from "react";
import services from "../../../services";
import { Box, Typography, Divider, ListItem, ListItemButton, ListItemIcon, ListItemText, List } from "@mui/material";
import ROUTES_PATH from "../../../constants/routes";
import { LOGOICON, PROFILE_ICON } from "../../../constants/image";
import {
	WORKSPACE_LEFT_SIDEBAR_ITEMS,
	DEFAULT_WORKSPACE_LEFT_SIDEBAR_ITEMS,
	MENTOR_WORKSPACE_LEFT_SIDEBAR_ITEMS,
	ADVISOR_WORKSPACE_LEFT_SIDEBAR_ITEMS,
	CANDIDATE_WORKSPACE_LEFT_SIDEBAR_ITEMS,
	INVESTOR_WORKSPACE_LEFT_SIDEBAR_ITEMS,
	PARTNER_WORKSPACE_LEFT_SIDEBAR_ITEMS,
	INSTRUCTOR_WORKSPACE_LEFT_SIDEBAR_ITEMS,
	STUDENT_WORKSPACE_LEFT_SIDEBAR_ITEMS,
	ADMIN_WORKSPACE_LEFT_SIDEBAR_ITEMS,
	MENTOR_APPROVED_WORKSPACE_LEFT_SIDEBAR_ITEMS,
	INVESTOR_APPROVED_WORKSPACE_LEFT_SIDEBAR_ITEMS,
	NAVBAR_TOOLS,
} from "../../../constants/workspace-sidebar";
import { USER_TYPES } from "../../../utils/userTypes";
import { useSelector } from "react-redux";
import HamburgerList from "../../../common/HamburgerList";
import { useHistory, useLocation } from "react-router-dom";

export default function HamburgerPanel({ handleOpenMenu, userData, companyName, userName }) {
	const userType = useSelector((store) => store?.auth?.userType);
	const [active, setActive] = useState("");
	const history = useHistory();
	const location = useLocation();

	// TODO: Check admin by using userType
	const isAdmin = useSelector((store) => store?.auth?.isAdmin);
	const impersonateProviderId = useSelector((store) => store?.auth?.impersonateProviderId);

	// let listItem = WORKSPACE_LEFT_SIDEBAR_ITEMS;

	// if (userType === USER_TYPES.MENTOR_USER) {
	// 	listItem = MENTOR_WORKSPACE_LEFT_SIDEBAR_ITEMS;
	// }

	// if (userType === USER_TYPES.ADVISOR_USER) {
	// 	listItem = ADVISOR_WORKSPACE_LEFT_SIDEBAR_ITEMS;
	// }

	// if (userType === USER_TYPES.CANDIDATE_USER) {
	// 	listItem = CANDIDATE_WORKSPACE_LEFT_SIDEBAR_ITEMS;
	// }
	// // TODO: Check admin by using userType
	// if (isAdmin && !impersonateProviderId) {
	// 	listItem = ADMIN_WORKSPACE_LEFT_SIDEBAR_ITEMS;
	// }

	const [listItem, setListItem] = useState(DEFAULT_WORKSPACE_LEFT_SIDEBAR_ITEMS);
	const [approvalStatus, setApprovalStatus] = useState();

	useEffect(() => {
		if (!approvalStatus) {
			services.getApprovalStatus().then((status) => {
				setApprovalStatus(status);
				setListItem(getListItem(status));
			});
		}
	}, [approvalStatus, setListItem]);

	const getListItem = (approvalStatus) => {
		if (approvalStatus === "APPROVED") {
			if (userType === USER_TYPES.MENTOR_USER) {
				return MENTOR_APPROVED_WORKSPACE_LEFT_SIDEBAR_ITEMS;
			} else if (userType === USER_TYPES.INVESTOR_USER) {
				return INVESTOR_APPROVED_WORKSPACE_LEFT_SIDEBAR_ITEMS;
			} else if (userType === USER_TYPES.ADVISOR_USER) {
				return ADVISOR_WORKSPACE_LEFT_SIDEBAR_ITEMS;
			} else if (userType === USER_TYPES.CANDIDATE_USER) {
				return CANDIDATE_WORKSPACE_LEFT_SIDEBAR_ITEMS;
			} else if (userType === USER_TYPES.PARTNER_USER) {
				return PARTNER_WORKSPACE_LEFT_SIDEBAR_ITEMS;
			} else if (userType === USER_TYPES.PROFESSOR_USER) {
				return INSTRUCTOR_WORKSPACE_LEFT_SIDEBAR_ITEMS;
			} else if (userType === USER_TYPES.STUDENT_USER) {
				return STUDENT_WORKSPACE_LEFT_SIDEBAR_ITEMS;
			} else if (isAdmin && !impersonateProviderId) {
				// TODO: Check admin by using userType
				return ADMIN_WORKSPACE_LEFT_SIDEBAR_ITEMS;
			}

			return WORKSPACE_LEFT_SIDEBAR_ITEMS;
		} else {
			if (userType === USER_TYPES.MENTOR_USER) {
				return MENTOR_WORKSPACE_LEFT_SIDEBAR_ITEMS;
			} else if (userType === USER_TYPES.INVESTOR_USER) {
				return INVESTOR_WORKSPACE_LEFT_SIDEBAR_ITEMS;
			}

			return DEFAULT_WORKSPACE_LEFT_SIDEBAR_ITEMS;
		}
	};

	useEffect(() => {
		if (["user-support-access"].includes(location.pathname.split("/")[1])) {
			setActive("impersonate");
		} else if (["partner"].includes(location.pathname.split("/")[1])) {
			setActive("powerup-builder");
		} else {
			if (USER_TYPES.STARTUP_USER === "STARTUP_USER") {
				if (location.pathname.split("/")[1] === "discovery-board-expand") {
					setActive("discovery-board");
				} else {
					setActive(location.pathname.split("/")[1]);
				}
			} else {
				if (location.pathname.split("/")[1] === "discovery-board-expand") {
					setActive("discovery-board");
				} else {
				}
			}
		}
	}, []);

	const handleClick = (item) => {
		handleOpenMenu();
		if (item.external) {
			if (item.target) {
				window.open(item.link, item.target);
			} else {
				window.open(item.link);
			}
		} else {
			history.push(item.link);
		}
	};

	return (
		<>
			<Box
				sx={{
					background:
						"linear-gradient(0deg, rgba(106, 117, 117, 0.02), rgba(106, 117, 117, 0.02)), linear-gradient(0deg, rgba(245, 248, 250, 0.05), rgba(245, 248, 250, 0.05)), #FCFCFC",
					pb: 4,
				}}
			>
				<Box
					sx={(theme) => ({
						pt: 10,
						display: "block",
						position: "sticky",
						top: 0,
						background:
							"linear-gradient(0deg, rgba(106, 117, 117, 0.02), rgba(106, 117, 117, 0.02)), linear-gradient(0deg, rgba(245, 248, 250, 0.05), rgba(245, 248, 250, 0.05)), #FCFCFC",
						zIndex: 999,
					})}
				>
					<Box
						sx={{
							pb: 1,
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						<img draggable={false} src={LOGOICON} alt="Logo" />
					</Box>
					<Divider />
				</Box>
				{/*<Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 2,
              py: 2,
              px: 3,
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Box
              component="img"
              src={userData?.picture}
              width={40}
              height={40}
              sx={{
                borderRadius: "50%",
                ml: 1,
              }}
            />
            <Typography
              variant="tag"
              sx={theme => ({
                display: "flex",
                overflow: "hidden",
                wordBreak: "break-word",
                color: theme.palette.text.onSurfaceVariant,
              })}
            >
              {userType === USER_TYPES.STARTUP_USER ||
              userType === USER_TYPES.STARTUP_OWNER ||
              userType === USER_TYPES.STUDENT_USER
                ? companyName || "The Roadrunner.corp"
                : userName || "User"}
            </Typography>
          </Box>
        </Box>*/}
				{/*<Divider sx={{ mx: 3, mt: 1 }} />*/}

				<Box sx={{ px: 2, py: 2 }}>
					<HamburgerList listItem={listItem} handleClick={handleClick} active={active} />

					{approvalStatus === "APPROVED" && (
						<div>
							<Divider sx={{ my: 2 }} />

							<Box sx={{ my: 2 }}>
								<Typography variant="overlineMedium" sx={(theme) => ({ color: theme.palette.text.onSurfaceVariant })}>
									TOOLS
								</Typography>
							</Box>

							<Box>
								<HamburgerList listItem={NAVBAR_TOOLS} handleClick={handleClick} active={active} />
							</Box>
						</div>
					)}
				</Box>
			</Box>
		</>
	);
}
