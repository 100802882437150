import React from "react";
import { useParams } from "react-router-dom";
import ArticleList from "../Articles/ArticleList";

// Section Page displays a list of articles.
function SectionPage() {
	const { section_id } = useParams();
	return <ArticleList section_id={section_id} />;
}

export default SectionPage;
