import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Stack, Box, Grid, Typography, Button, IconButton, useMediaQuery, Card, TextField, CircularProgress } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import { Add, ArrowBack, Filter } from "@mui/icons-material";
import GridViewIcon from "@mui/icons-material/GridView";
import SplitscreenIcon from "@mui/icons-material/Splitscreen";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import SearchIcon from "@mui/icons-material/Search";
import { useHistory } from "react-router-dom";
import ROUTES_PATH from "../../../constants/routes";
import SelectInput from "../../../common/SelectInput";
import { debounce } from "lodash";
import TooltipWrapper from "../../../common/Tooltip";
import { useSelector } from "react-redux";
import { USER_TYPES } from "../../../utils/userTypes";
import { PinnedNotesComponent } from "./PinnedNotes";
import SortSelect from "./Components/SortSelect";
import OtherNotesSection from "./Components/OtherNotesSection";
import FilterSelect from "./Components/FilterSelect";
import { PageContentWrapper } from "../../../components/Workspace/PageContentWrapper";
import theme from "../../../constants/Theme";
import AdBanner from "../../../components/AdBanner";
import constants from "../../../constants";
import { CARTA_LOGO, DISCOVERY_MAIN_BLACK, PAGE_DISCOVERY_ICON } from "../../../constants/image";
import { hotjar } from "react-hotjar";
import { handleMixPanel } from "../../../utils/mixPanelEventHandling";
import services from "../../../services";

export default function ResearchCuratorExpandView() {
	const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
	const [view, setView] = useState("MOSAIC");
	const history = useHistory();
	const [filter, setFilter] = useState({ type: "", tags: "" });
	const [sortBy, setSortBy] = useState({ key: "createdAt", order: "DESC" });
	const [searchText, setSearchText] = useState(null);
	const [callback, setCallback] = useState(false);
	const [advertsDetails, setAdvertsDetails] = useState();
	const [loading, setLoading] = useState(true);
	const location = useLocation();
	const { isPremiumPlanAccess, userType } = useSelector((store) => store?.auth);

	const ViewIconButton = ({ icon, ...props }) => {
		return (
			<IconButton color="secondary" aria-label="list-view" component="span" {...props}>
				{icon}
			</IconButton>
		);
	};

	const handleBackClick = () => {
		if ([USER_TYPES.STARTUP_OWNER, USER_TYPES.STARTUP_USER].includes(userType)) {
			history.push(ROUTES_PATH.STARTUPS_HOME);
		} else if (userType === "MENTOR_USER" || userType === "PROFESSOR_USER") {
			history.push(ROUTES_PATH.MENTOR_DASHBOARD);
		}
	};

	const MobileMenu = () => {
		return (
			<Box>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
					}}
				>
					{/*<ArrowBack
            sx={{
              cursor: "pointer",
              color: "secondary",
            }}
            onClick={() => handleBackClick()}
          />*/}

					<Box sx={{ ml: 1 }}>{/* <Typography variant="title_large">Discovery Board</Typography> */}</Box>
				</Box>
			</Box>
		);
	};

	const DesktopMenu = () => {
		return (
			<Box>
				<Box
					sx={{
						display: !isSmallScreen ? "flex" : "none",
						justifyContent: "space-between",
						alignItems: "center",
						px: 2,
					}}
				>
					<Box sx={{ display: "flex", alignItems: "center" }}>
						{/*<ArrowBack
              sx={{
                cursor: "pointer",
                color: "secondary",
                mr: 2,
              }}
              onClick={() => handleBackClick()}
            />*/}
						{/* <Typography variant="title_xlarge">Discovery Board</Typography> */}
					</Box>
					<Box sx={{ alignItems: "center", display: "flex" }}>
						{view === "LIST" ? (
							<ViewIconButton icon={<GridViewIcon sx={{ color: "text.primary" }} />} onClick={() => setView("MOSAIC")} />
						) : (
							<ViewIconButton icon={<SplitscreenIcon sx={{ color: "text.primary" }} />} onClick={() => setView("LIST")} />
						)}
						{/* <IconButton>
              <NoteAddOutlinedIcon
                onClick={() => history.push(ROUTES_PATH.CREATE_QUICK_NOTE)}
                sx={{ color: "text.primary" }}
              />
            </IconButton> */}
						<Button
							startIcon={<Add />}
							variant="outlined"
							sx={{ width: "max-content", ml: 2 }}
							onClick={() => {
								handleMixPanel("Discovery Board: Quick Note Create Clicked");

								history.push(ROUTES_PATH.CREATE_QUICK_NOTE);
							}}
						>
							New Quick Note
						</Button>
						<Button
							startIcon={<Add />}
							sx={{ width: "max-content", ml: 2 }}
							onClick={() => {
								handleMixPanel("Discovery Board: PowerDot Create Clicked");

								history.push(ROUTES_PATH.CREATE_RESEARCH_CURATOR);
							}}
						>
							New PowerDot
						</Button>
					</Box>
				</Box>
			</Box>
		);
	};

	const searchApiCall = (value) => {
		if (!value) {
			setSearchText(null);
		} else {
			setSearchText(value);
		}
	};

	const debounceChange = debounce(
		(e) => {
			searchApiCall(e?.target?.value);
			handleMixPanel("Discovery Board Searched", {
				"Search Term": e?.target?.value,
			});
		},
		600,
		{
			maxWait: 1000,
		}
	);

	// const getAdvertsById = (id) => {
	// 	services
	// 		.getAdvertsById(id)
	// 		.then((res) => {
	// 			setLoading(false);
	// 			const resData = res.data.data;
	// 			if (res.data.code === 200) {
	// 				setAdvertsDetails(resData[0]);
	// 			}
	// 		})
	// 		.catch((err) => {
	// 			console.log(err);
	// 		});
	// };

	useEffect(() => {
		// if (location?.message) handleMixPanel(location?.message);
		// getAdvertsById(3);
		handleMixPanel("Discovery Board Viewed", { subscription_status: isPremiumPlanAccess ? "Subscribed" : "Not Subscribed", user_type: userType });
	}, []);

	useEffect(() => {
		if (isSmallScreen) {
			setView("LIST");
		}
	}, [isSmallScreen]);

	return (
		<>
			<Helmet>
				<meta charSet="utf-8" />
				<title>StartupOS - Discovery</title>
			</Helmet>
			<Stack
				sx={(theme) => ({
					height: "100%",
					flexGrow: 1,
					display: "flex",
					flexDirection: "column",
					// borderRadius: isSmallScreen ? 0 : "8px",
					// background: theme.palette.secondary.white,
					//border: `1px solid ${theme.palette.secondary.outline2}`,
					px: {
						lg: 8,
						md: 4,
						xs: 2,
					},
					pb: "30px",
					gap: "32px",
					maxWidth: "1920px", // Figma specifies max content width
					margin: "0 auto",
				})}
			>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						gap: "12px",
					}}
				>
					<Box component={"img"} src={PAGE_DISCOVERY_ICON} alt="dashbaord" sx={{ width: "30px", height: "30px" }} />
					<Typography variant="growth_card_subtitle">Discovery Board</Typography>
				</Box>
				<Grid
					sx={(theme) => ({
						// maxWidth: theme.breakpoints.values.xl,
						flexGrow: 1,
						height: "100%",
						// margin: "auto",
						display: "flex",
						// px: 3,
					})}
					flexDirection={"column"}
					container
				>
					{/* **** Hidded advert SOS-2252  **** */}
					{/* {loading ? (
					<Box
						sx={{
							display: "flex",
							width: 1,
							justifyContent: "center",
							py: 4,
						}}
					>
						<CircularProgress />
					</Box>
				) : (
					<Box sx={{ pb: 3, mt: 2 }}>
						<AdBanner
							title={advertsDetails?.adName}
							subtitle={advertsDetails?.adCopy}
							image={advertsDetails?.adUrl}
							backgroundSize="cover"
							action={{
								text: "View Services",
								props: {
									href: advertsDetails?.serviceUrl,
									target: "_blank",
								},
							}}
						/>
					</Box>
				)} */}
					<PageContentWrapper padding={theme.spacing(0)} marginTop={theme.spacing(0)}>
						<Card
							sx={(theme) => ({
								// borderRadius: isSmallScreen ? 0 : "8px",
								borderRadius: 0,
								p: 1,
								flexGrow: 1,
								height: "100%",
								background: theme.palette.secondary.white,
								display: "flex",
								flexDirection: "column",
								pt: 2,
								//border: `1px solid ${theme.palette.secondary.outline2}`,
							})}
						>
							<Box>
								{isSmallScreen ? <MobileMenu /> : <DesktopMenu searchText={searchText} setSearchText={setSearchText} />}

								<Grid container spacing={2} sx={{ px: isSmallScreen ? 1 : 2, mt: 1 }}>
									<Grid item md={6} sm={12} xs={12}>
										<TextField
											spellCheck="true"
											InputProps={{
												startAdornment: (
													<Box sx={{ display: "flex", alignItems: "center", mr: 1 }}>
														<SearchIcon />
													</Box>
												),
											}}
											fullWidth
											placeholder={isSmallScreen ? "Search Discovery Board" : "Search"}
											//inputKey="filter"
											sx={{
												"& .MuiInputBase-input": {
													height: "auto",
												},
											}}
											onChange={debounceChange}
										/>
									</Grid>
									<Grid item md={3} sm={12} xs={12}>
										<SortSelect setSortBy={setSortBy} />
									</Grid>
									<Grid item md={3} sm={12} xs={12}>
										<FilterSelect setFilter={setFilter} />
									</Grid>
									{isSmallScreen && (
										<Grid
											container
											xs={12}
											sx={{
												pt: 2,
												pl: 2,
											}}
										>
											<Grid container xs={6}>
												<Button
													sx={{ mr: 1 }}
													variant="outlined"
													startIcon={<Add />}
													onClick={() => history.push(ROUTES_PATH.CREATE_QUICK_NOTE)}
												>
													New Quick Note
												</Button>
											</Grid>
											<Grid container xs={6}>
												<Button sx={{ ml: 1 }} startIcon={<Add />} onClick={() => history.push(ROUTES_PATH.CREATE_RESEARCH_CURATOR)}>
													New PowerDot
												</Button>
											</Grid>
										</Grid>
									)}
								</Grid>
							</Box>

							<Box>
								<PinnedNotesComponent
									view={view}
									searchText={searchText}
									sortBy={sortBy}
									filter={filter}
									callbackRefresh={callback}
									setCallbackRefresh={setCallback}
								/>
							</Box>

							<OtherNotesSection
								view={view}
								searchText={searchText}
								sortBy={sortBy}
								filter={filter}
								callbackRefresh={callback}
								setCallbackRefresh={setCallback}
							/>
						</Card>
					</PageContentWrapper>
				</Grid>
			</Stack>
		</>
	);
}
