import React from "react";
import { Box, Button, Chip, Divider, Stack, Typography } from "@mui/material";
import CommonSlideDialog from "../../../common/CommonSlideDialog";

export default function IndustryDetailsView({ industry, isOpen, handleClose, title }) {
	const getSelectedIndustryFormatted = (input) => {
		if (input) {
			try {
				let inputParsed = JSON.parse(input);
				if (Array.isArray(inputParsed)) {
					return inputParsed;
				} else {
					return [inputParsed];
				}
			} catch (error) {
				return [];
			}
		} else {
			return [];
		}
	};

	return (
		<CommonSlideDialog
			open={isOpen}
			onClose={handleClose}
			title={
				<Box>
					<Typography variant="title_portfolio" color="secondary.black">
						{title} [{getSelectedIndustryFormatted(industry).length}]
					</Typography>
				</Box>
			}
			titleVariant="poppinsSemiBold30"
			fullWidth
			PaperProps={{ sx: { position: "fixed", bottom: 0, right: 0, m: 0, px: 2, py: 2, borderRadius: "30px", maxWidth: "540px" } }}
			contentStyle={{ mt: 1 }}
			dialogAction={
				<Box display="flex" sx={{ pr: 5.2 }}>
					<Button
						variant="containedNewStyle"
						onClick={() => {
							handleClose();
						}}
					>
						Close
					</Button>
				</Box>
			}
		>
			<Stack>
				<Divider fullWidth />

				<Box mt={3} display="flex" gap={1.25} flexWrap="wrap">
					{getSelectedIndustryFormatted(industry).map((industryItem) => {
						return (
							<Chip
								sx={{
									px: 3,
									py: 2.5,
									borderRadius: "30px",
									"& .MuiChip-label": { p: 0 },
									bgcolor: (theme) => theme.palette.secondary.chipGrayBg,
								}}
								label={
									<Typography variant="title_medium" color="secondary.black">
										{industryItem}
									</Typography>
								}
							/>
						);
					})}
				</Box>
			</Stack>
		</CommonSlideDialog>
	);
}
