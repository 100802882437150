import { PDFDownloadLink, Document, Page, Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import { marked } from "marked";
import React, { useEffect, useState } from "react";
import { LetterHead, parseHtmlString, renderElement, styles } from "./utilities";
import moment from "moment";
import { Button, CircularProgress } from "@mui/material";
import { Download } from "@mui/icons-material";

function TextDocDownload({ type, contentString, fileName, buttonText, preProcessedArray, buttonVariant, buttonProps }) {
	if (type !== "markdown" && type !== "html" && type !== "preProcessed") {
		throw new Error(`Invalid type prop: ${type}. Type must be "markdown" or "html" or "preProcessed".`);
	}

	const [loading, setLoading] = useState(true);
	const [pdfInstance, setPdfInstance] = useState(null);

	useEffect(() => {
		let isMounted = true;

		if (type !== "preProcessed" && contentString) {
			const content = type === "markdown" ? parseHtmlString(marked(contentString)) : parseHtmlString(contentString);
			const renderContent = async () => {
				const renderedContent = [];

				for (let i = 0; i < content.length; i++) {
					try {
						const renderedElement = await renderElement(content[i], i);
						renderedContent.push(renderedElement);
					} catch (error) {
						console.error(`Error rendering element at index ${i}:`, error);
					}
				}

				if (isMounted) {
					setPdfInstance(renderedContent);
					setLoading(false); // Set loading state to false when rendering is complete
				}
			};
			renderContent();
		} else if (type === "preProcessed" && preProcessedArray) {
			if (isMounted) {
				setPdfInstance(preProcessedArray);
				setLoading(false); // Set loading state to false when rendering is complete
			}
		}
		return () => {
			isMounted = false;
		};
	}, []); // Empty dependency array to ensure the effect runs only once

	useEffect(() => {
		setPdfInstance(preProcessedArray);
	}, [preProcessedArray]);

	if (loading || !pdfInstance) {
		return <div>Loading document...</div>; // Render a loading indicator while waiting for rendering to complete
	}

	const handleDocumentLoad = () => {
		// Delay the appearance of the download button by 500ms
		setTimeout(() => {
			setLoading(false);
		}, 500);
	};

	return (
		<PDFDownloadLink
			document={
				<Document onLoadSuccess={handleDocumentLoad}>
					<Page style={styles.body}>
						<LetterHead />
						{pdfInstance}
						<Text style={styles.footerCopy} fixed>
							{moment().format("YYYY")} StartupOS Inc.
						</Text>
						<Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`} fixed />
					</Page>
				</Document>
			}
			fileName="test.pdf"
		>
			{({ blob, url }) =>
				loading ? (
					<CircularProgress size={"1.5rem"} />
				) : (
					<Button
						variant={buttonVariant ? buttonVariant : "contained"}
						sx={{
							width: "max-content",
							"&:disabled": {
								color: "rgba(0, 0, 0, 0.26)",
							},
						}}
						href={url}
						download={fileName ? `${fileName}.pdf` : "StartupOS-Report.pdf"}
						{...buttonProps}
					>
						{buttonText ? buttonText : "Download Report"}
					</Button>
				)
			}
		</PDFDownloadLink>
	);
}

export default TextDocDownload;
