import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Box, Grid, Stack, Typography, useTheme, CircularProgress } from "@mui/material";
import { Helmet } from "react-helmet";

import PitchDayCountDown from "../../../components/Founder/PitchDayCountDown";
import InvestorImpressions from "../../../components/Founder/InvestorImpressions";
import CartaOffer from "../../../components/Founder/CartaOffer";
import {
	BLACK_DASHBOARD,
	MESSAGES_BLACK_ICON,
	PAGE_DASHBOARD_ICON,
	STICKER_BLACK,
	USERS_BLACK_ROUNDED_ICON,
	VOUCH_LOGO_PARTNER_CARD,
} from "../../../constants";
import CurrentStreaks from "../../../components/Founder/CurrentStreaks";
import Benchmark from "../../../components/Founder/Benchmark";
import GrowthRate from "../../../components/Founder/GrowthRate";
import CalculatorCard from "../../../components/Founder/CalculatorCard";
import OtherSmallCard from "../../../components/Founder/Other";
import ROUTES_PATH from "../../../constants/routes";
import {
	getAdvertsDataById,
	getCurrentStreaksData,
	getGrowthRateData,
	getInvestorImpressionsValues,
	getPowerUpPacksData,
	getStartupCardData,
} from "./Functions/common";
import { setFounderDashboardData } from "../../../modules/actions";
import services from "../../../services";
import "./styles.css";
import Journey from "../../../components/Founder/Journey";
import { setNetworkingDashboardData } from "../../../modules/actions/networkingActions";
import { USER_TYPES } from "../../../utils/userTypes";
import { handleMixPanel } from "../../../utils/mixPanelEventHandling";

export default function FounderDashboard() {
	const history = useHistory();
	const companyId = useSelector((state) => state?.auth?.companyId);
	const dispatch = useDispatch();
	const RANGE_SELECTION = useSelector((state) => state?.founderDashboard?.scoreTrendRangeSelection);
	const unreadMessagesData = useSelector((state) => state?.unreadMessages);
	const unreadMessagesArray = Object.values(unreadMessagesData || {});
	const [unreadMessageCount, setUnreadMessageCount] = useState(0);
	const advertsDetails = useSelector((state) => state?.founderDashboard?.advertsDetails);
	const startUpCardDetails = useSelector((state) => state?.founderDashboard?.startUpCardData);
	const theme = useTheme();
	const [loading, setLoading] = useState(false);

	function convertDurationToMonths(duration) {
		if (duration === "1M") {
			return 1;
		} else if (duration === "3M") {
			return 3;
		} else if (duration === "6M") {
			return 6;
		} else if (duration === "1Y") {
			return 12;
		} else if (duration === "2Y") {
			return 24;
		} else {
			return 1; // or throw an error, depending on your use case
		}
	}

	const getInvestorImpressions = async () => {
		const investorImpressionsData = await getInvestorImpressionsValues(companyId);
		dispatch(
			setFounderDashboardData({
				investorImpressionsData: investorImpressionsData ?? {},
			})
		);
	};

	const getPowerUpPacks = async () => {
		const getPowerUpPacksValue = await getPowerUpPacksData();
		dispatch(
			setFounderDashboardData({
				powerUpPacks: getPowerUpPacksValue?.packs ?? [],
			})
		);
	};

	const getStartupCard = async () => {
		const getStartupCardValues = await getStartupCardData(companyId);
		dispatch(
			setFounderDashboardData({
				startUpCardData: getStartupCardValues ?? {},
			})
		);
	};

	const getReport = () => {
		try {
			dispatch(setFounderDashboardData({ scoreTrendRangeLoading: true }));
			services
				.getStartupScoreReport(companyId, convertDurationToMonths(RANGE_SELECTION))
				.then((resp) => {
					dispatch(setFounderDashboardData({ ScoreReportObj: resp?.data?.data, scoreTrendRangeLoading: false }));
				})
				.catch((e) => {
					console.log(e);
					dispatch(setFounderDashboardData({ scoreTrendRangeLoading: false }));
				});
		} catch (e) {
			console.log("Error when attempting to grab score report for founder dashboard. Failed with error:", e);
			dispatch(setFounderDashboardData({ scoreTrendRangeLoading: false }));
		}
	};

	const getAdvertsById = async (id) => {
		const advertsDataById = await getAdvertsDataById(id);
		if (advertsDataById) {
			dispatch(
				setFounderDashboardData({
					advertsDetails: advertsDataById,
				})
			);
		}
	};

	const getGrowthRate = async () => {
		const growthRateData = await getGrowthRateData(companyId);
		dispatch(
			setFounderDashboardData({
				growthRateData: growthRateData ?? [],
			})
		);
	};

	const getCurrentStreaks = async () => {
		const currentStreaksData = await getCurrentStreaksData(companyId);
		dispatch(
			setFounderDashboardData({
				currentStreaksData: currentStreaksData ?? {},
			})
		);
	};

	const getFounderDashboardData = async () => {
		try {
			setLoading(true);
			await getInvestorImpressions();
			await getStartupCard();
			await getGrowthRate();
			await getCurrentStreaks();
			await getPowerUpPacks();
			// await getAdvertsById(10);
		} catch (error) {
			console.log(error, "error");
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		handleMixPanel("Founder Dashboard Viewed");
		getFounderDashboardData();
	}, []);

	useEffect(() => {
		getReport(companyId);
	}, [RANGE_SELECTION]);

	useEffect(() => {
		if (unreadMessagesArray.length > 0) {
			let totalCount = unreadMessagesArray?.reduce((x, y) => {
				return x + y;
			}, 0);
			setUnreadMessageCount(totalCount);
		}
	}, [unreadMessagesArray]);

	return (
		<>
			<Helmet>
				<meta charSet="utf-8" />
				<title>StartupOS - Dashboard</title>
			</Helmet>
			<Stack
				sx={(theme) => ({
					width: 1,
					borderRadius: theme.shape.standard.borderRadius,

					[theme.breakpoints.down("md")]: {
						borderRadius: 0,
					},
					height: "100%",
				})}
			>
				<Box
					className="SOSShowVerticalScroll"
					sx={(theme) => ({
						height: "calc(100vh - 135px)",
						overflowY: "scroll",
						maxWidth: theme.breakpoints.values.xl,
						width: 1,
						pb: "30px",
						display: "flex",
						flexDirection: "column",
						gap: "32px",
						maxWidth: "1920px", // Figma specifies max content width
						margin: "0 auto",
					})}
				>
					<Grid
						container
						rowSpacing={4}
						columnSpacing={4}
						sx={{
							px: {
								lg: 8,
								md: 4,
								xs: 2,
							},
						}}
					>
						<Grid item md={6.5} lg={6.5}>
							<Box
								sx={{
									display: "flex",
									alignItems: "center",
									gap: "12px",
								}}
							>
								<Box component={"img"} src={PAGE_DASHBOARD_ICON} alt="dashbaord" sx={{ width: "30px", height: "30px" }} />
								<Typography variant="growth_card_subtitle" color="secondary.black">
									Founder Dashboard
								</Typography>
							</Box>
						</Grid>
						{/* {!loading && ( */}
						{/* <Grid item md={5.5} lg={5.5}>
						<CartaOffer
							title={"Learn about Vouch Insurance"}
							subtext={"Vouch Business Insurance for Startups"}
							image={VOUCH_LOGO_PARTNER_CARD}
							handleOfferClick={() => {
								window.open(ROUTES_PATH.VOUNCH_POWERUP, "_blank");
							}}
						/>
					</Grid> */}
						{/* )} */}
						{loading ? (
							<Box
								sx={{
									display: "flex",
									width: 1,
									justifyContent: "center",
									alignItems: "center",
									height: "50vh",
								}}
							>
								<CircularProgress />
							</Box>
						) : (
							<>
								<Grid item md={6.5} lg={6.5}>
									<Grid container spacing={4}>
										<Grid item md={12} lg={12}>
											<Benchmark />
										</Grid>
										<Grid item md={12} lg={12}>
											<CurrentStreaks />
										</Grid>
										<Grid item md={12} lg={12}>
											<CalculatorCard />
										</Grid>
									</Grid>
								</Grid>
								<Grid item md={5.5} lg={5.5}>
									<Grid container spacing={4}>
										<Grid item xs={12}>
											<PitchDayCountDown />
										</Grid>
										<Grid item xs={12}>
											<InvestorImpressions />
										</Grid>
										<Grid item xs={12}>
											<GrowthRate />
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={12}>
									<Box
										sx={(theme) => ({
											display: "grid",
											gridGap: "16px",
											gridTemplateColumns: "1fr",
											[theme.breakpoints.up("md")]: {
												gridTemplateColumns: "repeat(2, 1fr)",
											},
											[theme.breakpoints.up("lg")]: {
												gridTemplateColumns: "repeat(3, 1fr)",
											},
										})}
									>
										<OtherSmallCard
											title="Messages"
											subtitle="Send messages to members of your network!"
											icon={MESSAGES_BLACK_ICON}
											color={(theme) => theme.palette.primary.primaryPurple}
											buttonText={`Review Feedback Comment (${unreadMessageCount})`}
											onClickLink={() => {
												handleMixPanel("Founder Dashboard: Messages Clicked");

												dispatch(
													setNetworkingDashboardData({
														openMessageDialog: true,
														messageWithMatch: "",
													})
												);
											}}
										/>
										<OtherSmallCard
											title="Mentor Connect"
											subtitle="Connect with your mentor through the platform today!"
											icon={USERS_BLACK_ROUNDED_ICON}
											color={(theme) => theme.palette.primary.primaryPink}
											buttonText="Schedule a Meeting"
											onClickLink={() => {
												handleMixPanel("Founder Dashboard: Mentor Connect Clicked");

												dispatch(
													setNetworkingDashboardData({
														currentTab: USER_TYPES.MENTOR_USER,
													})
												);
												history.push(ROUTES_PATH.NETWORK);
											}}
										/>
										<OtherSmallCard
											title="Get Inspired"
											subtitle="See what’s happening in your network!"
											icon={STICKER_BLACK}
											color={(theme) => theme.palette.primary.primaryMint}
											buttonText="Go to Discovery posts"
											onClickLink={() => {
												handleMixPanel("Founder Dashboard: Get Inspired Clicked");

												history.push(ROUTES_PATH.RESEARCH_CURATOR_EXPAND);
											}}
										/>
									</Box>
								</Grid>
							</>
						)}
					</Grid>
				</Box>
				<Journey />
			</Stack>
		</>
	);
}
