import React from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";
import { PageWrapper } from "./styles";
import { Box } from "@mui/material";

import { getUser, logout } from "../../utils/auth0-spa";
import { authWebLogout } from "../../utils/auth0";
import LayoutWrapper from "./LayoutWrapper";
import * as actions from "../../modules/actions";
import { useLocation } from "react-router-dom";

function WorkspaceLayout({ children, userData, setAuthData, showAccountSettingPopup, companyLogoUrl }) {
	const location = useLocation();

	const onLgoutHandler = async () => {
		await logout();
		await authWebLogout();
	};

	const closeAccountSettingsPopup = () => {
		setAuthData({ showAccountSettingPopup: false });
	};
	const handleOpen = () => {
		setAuthData({ showAccountSettingPopup: true });
	};

	return (
		<Box>
			<PageWrapper bgImage={location.pathname === "/founder/dashboard"}>
				<LayoutWrapper
					onLogout={onLgoutHandler}
					userData={userData}
					showAccountSettingPopup={showAccountSettingPopup}
					closeAccountSettingsPopup={closeAccountSettingsPopup}
					handleOpen={handleOpen}
					companyLogoUrl={companyLogoUrl}
				>
					{children}
				</LayoutWrapper>
			</PageWrapper>
		</Box>
	);
}

WorkspaceLayout.propTypes = {
	children: PropTypes.element.isRequired,
};

const mapStoreToProps = ({ auth }) => ({
	userData: auth.userData,
	companyLogoUrl: auth.companyLogoUrl,
	showAccountSettingPopup: auth?.showAccountSettingPopup,
});
const mapDispatchToProps = (dispatch) => ({
	setAuthData: (updatedData) => dispatch(actions.setAuthData(updatedData)),
});

export default connect(mapStoreToProps, mapDispatchToProps)(WorkspaceLayout);
