import React from "react";
import { Typography, CardContent, Box, Card, Button } from "@mui/material";

export default function FirstbaseCTA({ params }) {
	params.bgColor = params.bgColor || "red";
	params.affiliateLink = params.affiliateLink || "https://get.firstbase.io/startupos";

	const navtoFirstbase = () => {
		window.open(params.affiliateLink, "_blank");
	};

	return (
		<Card
			sx={{
				display: "flex",
				bgcolor: params.bgColor,
				borderRadius: "30px",
				boxShadow: "0px 0px 0px 4px #F2F4F7, 0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
			}}
		>
			<Box sx={{ display: "flex", flexDirection: "column" }}>
				<CardContent sx={{ flex: "1 0 auto" }}>
					<Typography component="div" variant="poppinsSemiBold18">
						You can incorporate right now with our partner, Firstbase.io
					</Typography>
					<Typography variant="subtitle1" color="text.secondary" component="div">
						Use Coupon Code “PARTNER10” to receive a 10% discount
					</Typography>
				</CardContent>
			</Box>
			<Box sx={{ display: "flex", flexDirection: "column", textAlign: "center" }}>
				<CardContent sx={{ flex: "1 0 auto" }}>
					<Button variant="DS1" onClick={() => navtoFirstbase()}>
						Incorporate&nbsp;now
					</Button>
					<Typography variant="subtitle1" color="text.secondary" component="div">
						opens in a new window
					</Typography>
				</CardContent>
			</Box>
		</Card>
	);
}
