import styled from "@emotion/styled";
import { Typography } from "@mui/material";

export const TextWrapper = styled(Typography)(({ wrapLine = 1 }) => ({
	overflow: "hidden",
	display: "-webkit-box",
	WebkitBoxOrient: "vertical",
	WebkitLineClamp: wrapLine,
	lineHeight: "1.2em",
	maxHeight: "2.4em",
	color: "white",
	/* For older versions of Safari */
	whiteSpace: "-webkit-pre-wrap" /* This is for Safari 4-5.1 */,
	wordWrap: "break-word" /* This is for newer versions of Safari */,
	overflowWrap: "break-word" /* This is for newer versions of Safari */,

	/* For modern browsers */
	whiteSpace: "pre-wrap" /* This is for most modern browsers */,
	wordWrap: "break-word" /* This is for most modern browsers */,
	overflowWrap: "break-word" /* This is for most modern browsers */,
}));
