import React, { useCallback, useEffect, useState } from "react";
import AmountRaisedChart from "../Charts/AmountRaisedChart";
import CapGrid from "../DataGrid/CapGrid";
import { getCapTableData } from "../Functions/common";
import { useSelector, useDispatch } from "react-redux";
import { handleMixPanel } from "../../../../utils/mixPanelEventHandling";
import { setBIDashboardData } from "../../../../modules/actions/biDashboardActions";
import BIDataGridDialog from "../DataGrid/BIDataGridDialog";

function AmountRaised({ companyId, readOnly }) {
	const dispatch = useDispatch();
	const capData = useSelector((store) => store?.biDashboard?.capData);
	const openCapData = useSelector((store) => store?.biDashboard?.openCapData);
	// const [loading, setLoading] = useState(true);
	const [isEdited, setEdited] = useState(false);
	const loadingCapData = useSelector((store) => store?.biDashboard?.loadingCapData);

	const ownCompanyId = useSelector((state) => state?.auth?.companyId);
	const usedCompanyId = companyId ? companyId : ownCompanyId;

	const fetchData = async () => {
		try {
			dispatch(setBIDashboardData({ loadingCapData: true }));
			const responseData = await getCapTableData(usedCompanyId);
			if (responseData) {
				dispatch(setBIDashboardData({ capData: responseData }));
			}
			dispatch(setBIDashboardData({ loadingCapData: false }));
		} catch (error) {
			dispatch(setBIDashboardData({ loadingCapData: false }));
			console.log("error", error);
		}
	};

	useEffect(() => {
		dispatch(setBIDashboardData({ loadingCapData: true }));
		fetchData();
	}, []);

	const onChartClick = () => {
		handleMixPanel("Grid Amount Raised Viewed");
		dispatch(setBIDashboardData({ openCapData: true, capTableName: "Amount Raised by Type" }));
	};

	const handleClose = useCallback(() => {
		if (isEdited) {
			dispatch(
				setBIDashboardData({
					openCapDataLeave: true,
				})
			);
		} else {
			dispatch(setBIDashboardData({ openCapData: false }));
		}
	}, [isEdited]);

	return (
		<>
			<AmountRaisedChart data={capData} onChartClick={onChartClick} loading={loadingCapData} readOnly={readOnly} />
			<BIDataGridDialog title="Amount Raised by Type" isOpen={openCapData} onClose={handleClose}>
				<CapGrid
					title="Amount Raised by Type"
					gridData={capData}
					readOnly={readOnly}
					usedCompanyId={usedCompanyId}
					isEdited={isEdited}
					setEdited={setEdited}
				/>
			</BIDataGridDialog>
		</>
	);
}

export default AmountRaised;
