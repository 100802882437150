import React from "react";
import { Box, Button, useTheme } from "@mui/material";
import LoadingButton from "../../../../../common/Loadingbutton";

function SaveData({ isEdited = false, saving, readOnly, onCancelClick, onSaveClick, containerStyle }) {
	const theme = useTheme();
	return (
		<Box display="flex" sx={{ mt: "auto", justifyContent: "flex-end", pt: 2, ...containerStyle }}>
			<Box>
				<Button onClick={onCancelClick} variant="noOutlineNew" sx={{ height: "40px" }}>
					Cancel
				</Button>
			</Box>
			<LoadingButton
				loaderColor={theme.palette.primary.main}
				loadingStyles={{
					border: "4px solid",
					backgroundColor: theme.palette.secondary.white,
					borderColor: theme.palette.primary.main,
					height: "40px",
					px: 4,
				}}
				loading={saving}
				onClick={!saving ? onSaveClick : () => {}}
				variant="DS1"
			
				disabled={!isEdited || readOnly}
			>
				Save
			</LoadingButton>
		</Box>
	);
}

export default SaveData;
