import services from "../../../services";
import { handleMixPanel } from "../../../utils/mixPanelEventHandling";

export const getPowerupDetails = (powerUpId) => {
	return services
		.getPowerupData(powerUpId)
		.then((response) => {
			const res = response.data;

			if (res.code === 200) {
				console.log("location");
				if (!window.location.pathname.includes("/public/powerups")) {
					//service call to update last visited powerup status
					services
						.updateLastVisitedPowerup({
							powerUpId: powerUpId,
						})
						.then((response) => {})
						.catch((error) => {});
				}

				let details = { ...res.data };

				return details;
			}
		})
		.catch((err) => {
			console.log(err);
			return err;
		});
};

export const getPowerupDetailsOnly = (powerUpId) => {
	return services
		.getPowerupData(powerUpId)
		.then((response) => {
			const res = response.data;

			if (res.code === 200) {
				//service call to update last visited powerup status

				let details = { ...res.data };

				return details;
			}
		})
		.catch((err) => {
			console.log(err);
			return err;
		});
};

export const getModuleSteps = (id) => {
	return services
		.getModulesSteps(id)
		.then((result) => {
			if (result?.data?.data) {
				return result.data.data;
			}
		})
		.catch((err) => {
			console.log(err);
			return err;
		});
};

export const startPowerupModule = (moduleId, action) => {
	return services
		.startModule({ moduleId })
		.then((response) => {
			const res = response.data;
			if (res.code === 200) {
				return res.data;
			}
		})
		.catch((error) => {
			const message = error.response?.data?.message;
			console.log(error);
			return message;
		});
};
