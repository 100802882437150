import styled from "@emotion/styled";

export const ItemIcon = styled.div(({ theme, selected }) => {
  return {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: theme.shape.standard10.borderRadius,
    backgroundColor: selected
      ? theme.palette.secondary.light
      : theme.palette.secondary.white,
    padding: theme.spacing(1),
    "&:hover": {
      backgroundColor: theme.palette.grey[200],
    },
    [theme.breakpoints.down("md")]: {
      paddingTop: theme.spacing(0.5),
    },
  };
});

export const RightSidebarWrapper = styled.div(({ theme }) => {
  return {
    backgroundColor: "var(--white-color)",
    height: "100%",
  };
});
