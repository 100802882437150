import React, { useEffect, useState } from "react";
import { Box, Divider, Grid, Button, Typography, TextField, Link, IconButton, InputAdornment } from "@mui/material";
import { REGEX } from "../../../constants/regex";
import messages from "../../../constants/messages";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { BACK_ARROW_ICON_V2 } from "../../../constants";

export default function ChangeEmail({
	setEmail,
	setPassword,
	verificationPending,
	userProfileData,
	handleBack,
	password,
	email,
	setUpdateEnable,
	verificationData,
	setChangeEmail,
	changeEamil,
	showEmail,
}) {
	const [errorMessage, setErrorMessage] = useState("");
	const [showPassword, setShowPassword] = useState(false);

	const handleOnChange = (e, type) => {
		if (type === "email") {
			setEmail(e);
		} else {
			setPassword(e);
		}
	};

	const handleEmailValidations = (email) => {
		if (!email.match(REGEX.EMAIL)) {
			setErrorMessage(messages.INVALID_EMAIL);
		} else {
			setErrorMessage("");
		}
	};

	useEffect(() => {
		if (password.length >= 8 && email.length >= 9 && errorMessage === "") {
			setUpdateEnable(false);
		} else {
			setUpdateEnable(true);
		}
	}, [password, email, errorMessage]);

	return (
		<Box sx={{ width: 1 }}>
			<Grid container spacing={2} sx={{ display: "flex", p: 1, pb: 3 }}>
				<Grid item xs={12} md={12}>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							rowGap: 2,
						}}
					>
						<Box>
							<IconButton onClick={handleBack} sx={{ p: "0px" }}>
								<Box component={"img"} src={BACK_ARROW_ICON_V2} alt="back" />
							</IconButton>
						</Box>
						<Typography variant="Text/md/Semibold">Change Email</Typography>
					</Box>
				</Grid>
				<Grid
					item
					xs={12}
					md={12}
					sx={{
						display: "flex",
						flexDirection: "column",
						gap: 1,
					}}
				>
					<Typography variant="Text/sm/Semibold">Your current email address:</Typography>
					<Typography variant="Text/sm/Regular">{userProfileData?.email ? userProfileData?.email : "-"}</Typography>
				</Grid>

				{verificationPending && (
					<>
						<Grid item xs={12} md={12}>
							<Typography variant="Text/sm/Semibold">Your newly requested email address (pending verification):</Typography>
						</Grid>
						<Grid item xs={12} sm={6} md={6} sx={{ width: "max-content" }}>
							<Typography variant="Text/sm/Regular">{verificationData?.email ? verificationData?.email : "-"}</Typography>
						</Grid>
						<Grid
							item
							xs={12}
							sm={6}
							md={6}
							sx={{
								width: "max-content",
								display: "flex",
								justifyContent: { sm: "flex-end" },
								mt: { xs: 1, sm: 0 },
							}}
						>
							<Link sx={{ cursor: "pointer" }} underline="hover" variant="Text/sm/Semibold" onClick={() => setChangeEmail(true)}>
								Change Email
							</Link>
						</Grid>
					</>
				)}
				{changeEamil && showEmail && (
					<>
						<Grid item xs={12} md={12} sx={{ mt: verificationPending ? 4 : 2, mb: 3 }}>
							<PasswordInput
								fullWidth
								placeholder="Current Password"
								onChange={(e) => handleOnChange(e.target.value, "password")}
								show={showPassword}
								value={password}
								setShow={setShowPassword}
							/>
						</Grid>
						<Grid item xs={12} md={12}>
							<TextField
								fullWidth
								spellCheck="true"
								// label="New Email Address"
								placeholder="New Email Address"
								onChange={(e) => handleOnChange(e.target.value, "email")}
								onBlur={(e) => handleEmailValidations(e.target.value)}
								error={!!errorMessage}
								helperText={errorMessage}
								value={email}
								sx={{
									input: {
										"&, &::placeholder": (theme) => ({
											fontFamily: theme.typography["Text/sm/Regular"],
											color: "#667085",
										}),
									},
									"& .MuiOutlinedInput-notchedOutline": {
										height: "auto",
										boxShadow: "0px 10px 25px 0px rgba(0, 0, 0, 0.05)",
										border: "none",
										borderRadius: "100px",
									},
								}}
								InputProps={{ style: FormInputElementStyle }}
							/>
						</Grid>
					</>
				)}
			</Grid>
		</Box>
	);
}

export const FormInputElementStyle = {
	fontWeight: "500",
	fontSize: "16px",
	lineHeight: "24px",
	fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
	padding: "1.5px 6px",
	background: "#fff",
	borderRadius: "100px",
};

const PasswordInput = ({ show, setShow, ...props }) => {
	const handleShowInput = () => {
		setShow(!show);
	};
	return (
		<TextField
			type={show ? "text" : "password"}
			sx={{
				"& .MuiOutlinedInput-notchedOutline": {
					height: "auto",
					boxShadow: "0px 10px 25px 0px rgba(0, 0, 0, 0.05)",
					border: "none",
					borderRadius: "100px",
				},
				input: {
					"&, &::placeholder": (theme) => ({
						fontFamily: theme.typography["Text/sm/Regular"],
						color: "rgba(102, 112, 133, 1)",
					}),
				},
			}}
			InputProps={{
				style: FormInputElementStyle,
				endAdornment: (
					<InputAdornment position="end">
						<IconButton aria-label="toggle password visibility" onClick={handleShowInput}>
							{show ? <VisibilityIcon /> : <VisibilityOffIcon />}
						</IconButton>
					</InputAdornment>
				),
			}}
			{...props}
		/>
	);
};
