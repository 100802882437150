import React from "react";
import { FormControl, InputLabel, Select, MenuItem, Checkbox, OutlinedInput, ListItemText } from "@mui/material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

export const MultiSelect = ({ questionId, question, value, disabled = false, onChange, handleAnswer }) => {
	const { id, unique_name, label, enum_options } = question;

	const handleSelectChange = event => {
		const {
			target: { value },
		} = event;
		onChange(typeof value === "string" ? value.split(",") : value);

		handleAnswer(questionId, id, value, "Multi-Select");
	};

	return (
		<FormControl fullWidth>
			<InputLabel id={`${unique_name}-label`}>{label ? label : "Multi Select"}</InputLabel>
			<Select
				labelId={`${unique_name}-label`}
				id={unique_name}
				multiple
				value={value ? value : []}
				disabled={disabled}
				onChange={handleSelectChange}
				input={<OutlinedInput label={label ? label : "Multi Select"} />}
				renderValue={selected => selected.join(", ")}
				MenuProps={MenuProps}
			>
				{enum_options.map(option => (
					<MenuItem key={option.value} value={option.value}>
						<Checkbox checked={value.indexOf(option.value) > -1} />
						<ListItemText primary={option.display_value} />
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};
