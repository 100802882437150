import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getBurnMultipeGridData } from "../Functions/common";
import { handleMixPanel } from "../../../../utils/mixPanelEventHandling";
import { setBIDashboardData } from "../../../../modules/actions/biDashboardActions";
import BIDataGridDialog from "../DataGrid/BIDataGridDialog";
import BurnMultipleGrid from "../DataGrid/BurnMultipleGrid";
import BurnMultipleChartContainer from "../Charts/BurnMultipleChartContainer";
import { get } from "lodash";

function BurnMultiple({ companyId, readOnly }) {
	const dispatch = useDispatch();
	const burnMultiple = useSelector((store) => store?.biDashboard?.burnMultiple);
	const loadingBurnMultiple = useSelector((store) => store?.biDashboard?.loadingBurnMultiple);
	const openBurnMultiple = useSelector((store) => store?.biDashboard?.openBurnMultiple);
	const [isEdited, setEdited] = useState(false);

	const gridData = burnMultiple?.data ? burnMultiple?.data : [];

	const ownCompanyId = useSelector((state) => state?.auth?.companyId);
	const usedCompanyId = companyId ? companyId : ownCompanyId;

	const fetchData = async () => {
		try {
			dispatch(setBIDashboardData({ loadingBurnMultiple: true }));
			const responseData = await getBurnMultipeGridData(usedCompanyId);
			if (responseData) {
				const updateBurnMultiplDataRes = get(responseData, "data", []);
				dispatch(
					setBIDashboardData({
						burnMultiple: {
							latestBurnMultiple: updateBurnMultiplDataRes?.[updateBurnMultiplDataRes?.length - 1]?.burnMultiple,
							data: updateBurnMultiplDataRes,
						},
					})
				);
			}
			dispatch(setBIDashboardData({ loadingBurnMultiple: false }));
		} catch (error) {
			dispatch(setBIDashboardData({ loadingBurnMultiple: false }));
			console.log("error", error);
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	const onChartClick = () => {
		handleMixPanel("Grid Burn Multiple Viewed");
		dispatch(setBIDashboardData({ openBurnMultiple: true }));
	};

	const handleClose = useCallback(() => {
		if (isEdited) {
			dispatch(
				setBIDashboardData({
					openBurnMultipleLeave: true,
				})
			);
		} else {
			dispatch(setBIDashboardData({ openBurnMultiple: false }));
		}
	}, [isEdited]);

	return (
		<>
			<BurnMultipleChartContainer data={burnMultiple} onChartClick={onChartClick} loading={loadingBurnMultiple} readOnly={readOnly} />
			<BIDataGridDialog title="Burn Multiple" isOpen={openBurnMultiple} onClose={handleClose}>
				<BurnMultipleGrid gridData={gridData} readOnly={readOnly} usedCompanyId={usedCompanyId} isEdited={isEdited} setEdited={setEdited} />
			</BIDataGridDialog>
		</>
	);
}

export default BurnMultiple;
