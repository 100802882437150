import { gql } from "@apollo/client";
import progressCompletionCalculator from "./progressCompletionCalculator";
import { USER_TYPES } from "./userTypes";
const getJasonFormatedValue = (value) => {
	try {
		if (value) {
			let data = JSON.parse(value);
			if (data.length > 0) {
				return data;
			} else {
				return "No Data";
			}
		}
	} catch (e) {
		if (value) {
			let data = value.split(",");
			if (data.length > 0) {
				return data;
			} else {
				return "No Data";
			}
		} else {
			return "No Data";
		}
	}
};
const isNotEmpty = (val) => val !== null && typeof val !== "undefined" && val !== ""; //for Number field

export function accountProfileProgress(profileData) {
	if (profileData?.userTypes?.includes(USER_TYPES.MENTOR_USER)) {
		return mentorProfileProgress(profileData);
	}

	return founderProfileProgress(profileData);
}

export function mentorProfileProgress(profileData) {
	const aboutPercentage = mentorAboutProgress(profileData);
	const statsPercentage = mentorStatsProgress(profileData);
	const educationPercentage = educationProgress(profileData);
	const experiencePercentage = experienceProgress(profileData);
	const totalPercentage = Math.round((aboutPercentage + statsPercentage + educationPercentage + experiencePercentage) / 4);
	return { totalPercentage, aboutPercentage, statsPercentage, educationPercentage, experiencePercentage };
}

export function mentorAboutProgress(profileData) {
	const totalFields = 1;
	let completedFields = 0;
	if (profileData?.about) {
		completedFields += 1;
	}
	return progressCompletionCalculator(totalFields, completedFields);
}

export function mentorStatsProgress(profileData) {
	const totalFields = 4;
	let completedFields = 0;
	//according to mentor profile
	const expertise = getJasonFormatedValue(profileData?.expertise) ? getJasonFormatedValue(profileData?.expertise) : [];
	const superpower = getJasonFormatedValue(profileData?.superpower) ? getJasonFormatedValue(profileData?.superpower) : [];
	if (profileData?.city || profileData?.state) {
		completedFields += 1;
	}
	if (profileData?.timezone) {
		completedFields += 1;
	}
	if (expertise?.length > 0 || superpower?.length > 0) {
		completedFields += 1;
	}
	if (profileData?.industries) {
		completedFields += 1;
	}
	// if (userDetails?.productOffering) {
	// 	completedFields += 1;
	// }
	return progressCompletionCalculator(totalFields, completedFields);
}

export function founderProfileProgress(profileData) {
	const bioPercentage = founderBioProgress(profileData);
	const aboutPercentage = founderAboutProgress(profileData);
	const educationPercentage = educationProgress(profileData);
	const experiencePercentage = experienceProgress(profileData);
	const totalPercentage = Math.round((bioPercentage + aboutPercentage + educationPercentage + experiencePercentage) / 4);
	return { totalPercentage, bioPercentage, aboutPercentage, educationPercentage, experiencePercentage };
}

export function founderBioProgress(profileData) {
	const totalFields = 8;
	let completedFields = 0;
	if (profileData?.picture) {
		completedFields += 1;
	}
	if (profileData?.name) {
		completedFields += 1;
	}
	if (profileData?.linkedInUrl) {
		completedFields += 1;
	}
	if (profileData?.profession) {
		completedFields += 1;
	}
	if (profileData?.headline) {
		completedFields += 1;
	}
	if (profileData?.companyModel?.companyName) {
		completedFields += 1;
	}
	if (profileData?.city || profileData?.state) {
		completedFields += 1;
	}
	if (profileData?.phone) {
		completedFields += 1;
	}
	return progressCompletionCalculator(totalFields, completedFields);
}

export function founderAboutProgress(profileData) {
	const totalFields = 2;
	let completedFields = 0;
	if (profileData?.videoUrl) {
		completedFields += 1;
	}
	if (profileData?.about) {
		completedFields += 1;
	}
	return progressCompletionCalculator(totalFields, completedFields);
}

export function educationProgress(profileData) {
	const totalFields = 1;
	const completedFields = profileData?.educationDetails && profileData?.educationDetails?.length > 0 ? 1 : 0;
	return progressCompletionCalculator(totalFields, completedFields);
}

export function experienceProgress(profileData) {
	const totalFields = 1;
	const completedFields = profileData?.experienceDetails && profileData?.experienceDetails?.length > 0 ? 1 : 0;
	return progressCompletionCalculator(totalFields, completedFields);
}

export function startupProfileProgress(companyData) {
	const bioPercentage = startupBioProgress(companyData);
	const aboutPercentage = startupAboutProgress(companyData);
	const pitchDeckPercentage = startupPitchDeckProgress(companyData);
	const totalPercentage = Math.round((bioPercentage + aboutPercentage + pitchDeckPercentage) / 3);
	return { totalPercentage, bioPercentage, aboutPercentage, pitchDeckPercentage };
}

export function startupBioProgress(companyData) {
	const totalFields = 18;
	let completedFields = 0;
	if (companyData?.logoUrl) {
		completedFields += 1;
	}
	if (companyData?.companyName) {
		completedFields += 1;
	}
	if (companyData?.incorporatedStatus) {
		completedFields += 1;
	}
	if (companyData?.linkedInUrl) {
		completedFields += 1;
	}
	if (companyData?.tagLine) {
		completedFields += 1;
	}
	if (companyData?.city || companyData?.state) {
		completedFields += 1;
	}
	if (companyData?.foundedYear) {
		completedFields += 1;
	}
	if (companyData?.phone) {
		completedFields += 1;
	}
	if (companyData?.websiteLink) {
		completedFields += 1;
	}
	if (companyData?.productOffering) {
		completedFields += 1;
	}
	if (companyData?.industry) {
		completedFields += 1;
	}
	if (companyData?.fundingType) {
		completedFields += 1;
	}
	if (companyData?.revenue) {
		completedFields += 1;
	}
	if (companyData?.financing) {
		completedFields += 1;
	}
	if (isNotEmpty(companyData?.capitalRaised)) {
		//Number field
		completedFields += 1;
	}
	if (companyData?.currentStage) {
		completedFields += 1;
	}
	if (companyData?.seeking) {
		completedFields += 1;
	}
	if (isNotEmpty(companyData?.ask)) {
		//Number field
		completedFields += 1;
	}
	return progressCompletionCalculator(totalFields, completedFields);
}

export function startupAboutProgress(companyData) {
	const totalFields = 4;
	let completedFields = 0;
	if (companyData?.founderStoryVideo) {
		completedFields += 1;
	}
	if (companyData?.aboutUsVideoUrl) {
		completedFields += 1;
	}
	if (companyData?.founderStory) {
		completedFields += 1;
	}
	if (companyData?.about) {
		completedFields += 1;
	}
	return progressCompletionCalculator(totalFields, completedFields);
}

export function startupPitchDeckProgress(companyData) {
	const totalFields = 1;
	const completedFields = companyData?.pitchDeckLink ? 1 : 0;
	return progressCompletionCalculator(totalFields, completedFields);
}

export const GET_USER_DATA_QUERY = gql`
	query userById($id: ID) {
		userById(id: $id) {
			id
			uuid
			email
			partnerCode
			phone
			invitationStatus
			emailVerified
			primaryAccountId
			userSource
			isTourCompleted
			companyName
			name
			picture
			userTypes
			title
			isVerified
			verificationStatus
			isHidden
			isSaved
			calUserId
			userProfilePercentage
			participantState
			admin
			userInfoPercent
			userAboutPercent
			experiencePercent
			educationPercent
			timezone
			dateOfBirth
			isCompanyUser
			idToken
			firstName
			lastName
			profession
			expertise
			superpower
			linkedInUrl
			about
			headline
			zipcode
			city
			state
			country
			videoUrl
			investorType
			investmentRange
			goals
			experienceDetails {
				id
				createdAt
				title
				companyName
				location
				startMonth
				startYear
				endMonth
				endYear
				isCurrentCompany
			}
			companyModel {
				id
				uuid
				companyName
				logoUrl
				about
				isSaved
				founderDetails {
					id
					uuid
					name
					firstName
					lastName
					phone
					picture
					timezone
					headline
					expertise
					videoUrl
					about
					linkedInUrl
					profession
					city
					state
					zipcode
					industries
					superpower
				}
				tagLine
				entityStatus
				companyProfilePercentage
				revenue
				financing
				currentStage
				seeking
				ask
				industry
				city
				state
				currentScore
				companySize
				founderStory
				curretElevatorPitch
				businessModel
				revenueModel
				founderStoryVideo
				twitterUrl
				country
				address1
				videoUrl
				websiteLink
				pitchDeckLink
				startUpSize
				foundedYear
				investorView
				linkedInUrl
				noOfCoFounders
				headline
				productOffering
				targetMarket
				fundingType
				capitalRaised
				phone
				aboutUsVideoUrl
				pitchDeckVideoUrl
				pitchDeckView
				zipcode
				foundedDate
				fundingDetails
				whyStartupOs
				expertise
				productCategory
				mvpLaunched
				incubator
				companyType
				registraionState
				incorporationCountry
				isPremiumPlanAccess
				isInvestorReady
				incorporatedStatus
				incorporationState
				supportAreas
			}
			educationDetails {
				id
				course
				university
				location
				startYear
				endYear
				degreeType
				createdAt
			}
			industries
			productOffering
		}
	}
`;
