import React from "react";
import { Box, Typography, styled } from "@mui/material";

import LoadingButton from "../../../common/Loadingbutton";

export const Quote = ({ quote }) => {
	const handleCheckout = () => {};

	return (
		<Box display="flex">
			{quote.pricing.map((price, index) => (
				<PriceCard key={`${quote.id}-price-${index}`}>
					<Typography variant="subhead6" sx={{ marginBottom: "8px", textTransform: "uppercase" }}>
						{price?.billing_interval?.interval}
					</Typography>

					<Typography variant="StartupOS/subhead/subhead1">{price?.helper_text}</Typography>
					<Box display="flex" alignItems={"end"} justifyContent={"space-between"} mt={1}>
						<Box>
							<LoadingButton variant="contained" color="primary" onClick={handleCheckout}>
								CHECKOUT
							</LoadingButton>
						</Box>

						<Box display={"flex"} flexDirection={"column"}>
							<Typography variant="headline3" sx={{ marginBottom: "4px", fontWeight: 500 }}>
								{`$${(price.due_at_checkout_cents / 100).toFixed(2)}`}
							</Typography>
							<Typography variant="body_small">due today</Typography>
						</Box>
					</Box>
				</PriceCard>
			))}
		</Box>
	);
};

const PriceCard = styled(Box)({
	display: "flex",
	flexDirection: "column",
	textAlign: "left",
	borderRadius: "10px",
	border: "1px solid #5630B2",
	padding: "40px",
	marginRight: "8px",
	"&:hover": {
		cursor: "pointer",
		backgroundColor: "rgba(106, 117, 117, 0.12)",
	},
});
