import React, { useState, useEffect } from "react";
import { Button, Box } from "@mui/material";
import { useParams } from "react-router-dom";
import { Layout as OnboardingLayout } from "../Layout/index";
import { SidebarControl } from "./NavigationComponents/SidebarControl";
import { MobileControl } from "./NavigationComponents/MobileControl";

export const OnboardingView = ({ stepObj, currentStep, getNextPage, content, sidebarTitle }) => {
	const [stepsState, setStepsState] = useState(null);
	const [step, setStep] = useState(null);

	useEffect(() => {
		setStep(currentStep);
	}, [currentStep]);

	useEffect(() => {
		setStepsState(stepObj);
	}, [stepObj]);

	const handleSidebarClick = (step) => {
		getNextPage(step);
	};

	return (
		<OnboardingLayout
			currentStep={currentStep}
			sidebar={<SidebarControl handleClick={handleSidebarClick} steps={stepsState} currentStep={step} title={sidebarTitle ? sidebarTitle : ""} />}
			main={<Box>{content}</Box>}
			footer={
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						height: 1,
					}}
				>
					<Button
						disabled={stepsState && stepsState[step - 1] && stepsState[step - 1].complete ? false : true}
						onClick={() => {
							getNextPage(step);
						}}
					>
						Next
					</Button>
				</Box>
			}
			mobileSteps={<MobileControl steps={stepsState} currentStep={step} />}
		/>
	);
};
