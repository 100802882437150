import React, { useState, useEffect } from "react";
import { Box, IconButton } from "@mui/material";
import ConnectedCardController from "../ConnectedCardController";
const ConnectedStartups = ({
  itemList,
  currentPage,
  totalPages,
  getFirstPage,
  getLastPage,
  getPrevPage,
  getNextPage,
  pageSize,
  totalElements,
}) => {
  return (
    <>
      {itemList.map((data, index) => {
        return <ConnectedCardController data={data} index={index} />;
      })}
    </>
  );
};

export default ConnectedStartups;
