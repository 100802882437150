import React from "react";
import { Box,Typography, } from "@mui/material";
import { AddCircleOutlineRounded } from "@mui/icons-material";
import { PINK_CHECK_ICON } from "../../../constants";

export default function ProfileWatchlistSection({ status, iconSrc, name, handleOnClickSection }) {
	return (
		<Box sx={{ display: "flex", cursor: "pointer", alignItems: "center", gap: "10px" }} onClick={handleOnClickSection}>
			{status ? (
				<Box component={"img"} src={PINK_CHECK_ICON} sx={{ width: "24px", height: "24px" }} />
			) : (
				<AddCircleOutlineRounded
					sx={{ fontSize: "23px", color: (theme) => theme.palette.text.investorDashGrayLabelMuted }}
					className="add-icon-hover"
				/>
			)}
			<Box sx={{ display: "flex", alignItems: "center", gap: "6px" }}>
				<Box
					component={"img"}
					className="addHovered"
					sx={{
						position: "relative",
						height: "25px",
						width: "25px",
					}}
					src={iconSrc}
					alt="icon"
				/>
				<Typography variant="card_text" sx={{ color: (theme) => theme.palette.secondary.black }}>
					{name}
				</Typography>
			</Box>
		</Box>
	);
}
