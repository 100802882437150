import React, { useEffect, useState } from 'react'

import { cloneDeep } from 'lodash'

import {
	Button,
	Card,
	Divider,
	FormControl,
	FormControlLabel,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	Stack,
	Switch,
	Box,
	TextField,
	Typography,
	Tooltip
} from '@mui/material'
import {
	Clear,
	Delete,
	CopyAll,
	CheckBoxOutlineBlank,
	RadioButtonUncheckedOutlined
} from '@mui/icons-material'

import Editor from '../Editor'

import theme from '../../../../constants/Theme'

function CheckboxRow ({ title: _title, onTitleChange, onRemoveClick, disabled, ...props }) {
	const [ title, setTitle ] = useState(_title)
	useEffect(() => {
		setTitle(_title)
	}, [ _title ])

	return <Stack direction={'row'} alignItems={'center'} gap={2} {...props}>
		<CheckBoxOutlineBlank color={'disabled'}/>
		<TextField
			sx={{ paddingBottom: 0, flex: '1 1 auto' }}
			variant={'standard'}
			value={title}
			onChange={(e) => setTitle(e.target.value)}
			onBlur={() => _title !== title && onTitleChange(title)}
		/>
		<IconButton onClick={onRemoveClick} disabled={disabled}><Clear/></IconButton>
	</Stack>
}

function RadioRow ({ title: _title, onTitleChange, onRemoveClick, disabled, ...props }) {
	const [ title, setTitle ] = useState(_title)
	useEffect(() => {
		setTitle(_title)
	}, [ _title ])

	return <Stack direction={'row'} alignItems={'center'} gap={2} {...props}>
		<RadioButtonUncheckedOutlined color={'disabled'}/>
		<TextField
			sx={{ paddingBottom: 0, flex: '1 1 auto' }}
			variant={'standard'}
			value={title}
			onChange={(e) => setTitle(e.target.value)}
			onBlur={() => _title !== title && onTitleChange(title)}
		/>
		<IconButton onClick={onRemoveClick} disabled={disabled}><Clear/></IconButton>
	</Stack>
}

function ListRow ({ title: _title, onTitleChange, onRemoveClick, disabled, ...props }) {
	const [ title, setTitle ] = useState(_title)
	useEffect(() => {
		setTitle(_title)
	}, [ _title ])

	return <Box component={'li'} sx={theme => ({
		textTransform: 'none',
		fontSize: theme.typography.h5,
		fontFamily: theme.typography.h5
	})} {...props}>
		<Stack direction={'row'} alignItems={'center'} gap={2} ml={3}>
			<TextField
				sx={{ paddingBottom: 0, flex: '1 1 auto' }}
				variant={'standard'}
				value={title}
				onChange={(e) => setTitle(e.target.value)}
				onBlur={() => _title !== title && onTitleChange(title)}
			/>
			<IconButton onClick={onRemoveClick} disabled={disabled}><Clear/></IconButton>
		</Stack>
	</Box>
}

const computeSectionsInputs = ({ id, sid, sections }) => {
	const si = sections.findIndex(({ id }) => id === sid)
	const section = sections[si]
	if (si >= 0) {
		sections = sections.slice(si + 1)
	}
	let inputs = []
	const ii = section.inputs.findIndex(({ id: iid }) => iid === id)
	if (ii >= 0) {
		inputs = section.inputs.slice(ii + 1).map((input) => ({ ...input, sectionTitle: section.title }))
	}
	inputs = inputs.concat(sections.flatMap((section) => section.inputs.map((input) => ({ ...input, sectionTitle: section.title }))))

	return { sections, inputs }
}

function Option ({ Row, onStateChange, onRemoveClick, removedDisabled, title, type: _type, target: _target, display, sections, inputs, outputs }) {
	const type = _type === 'section' && sections.length ? 'section' :
		_type === 'input' && inputs.length ? 'input' :
			_type === 'output' && outputs.length ? 'output' : 'none'
	const target = type === 'section' ? sections.find(({ id }) => id === _target)?.id || sections[0]?.id || null :
		type === 'input' ? inputs.find(({ id }) => id === _target)?.id || inputs[0]?.id || null :
			type === 'output' ? outputs.find(({ id }) => id === _target)?.id || null : null

	useEffect(() => {
		if (type !== _type || target !== _target) {
			onStateChange({
				title,
				type,
				target,
				display
			})
		}
	}, [ _type, _target ])

	const onTitleChange = (title) => {
		onStateChange({
			title,
			type,
			target,
			display
		})
	}

	const onTypeChange = (event) => {
		const type = event.target.value
		const target = type === 'section' ? sections[0]?.id : type === 'input' ? inputs[0]?.id : type === 'output' ? outputs[0]?.id : null

		onStateChange({
			title,
			type,
			target,
			display
		})
	}

	const onTargetChange = (event) => {
		const target = event.target.value
		onStateChange({
			title,
			type,
			target,
			display
		})
	}

	const onDisplayChange = (event) => {
		onStateChange({
			title,
			type,
			target,
			display: event.target.value
		})
	}

	return <Stack direction={'row'} flexWrap={'wrap'} justifyContent={'space-between'} gap={3}>
		<Row title={title} onTitleChange={onTitleChange} onRemoveClick={onRemoveClick} disabled={removedDisabled} flex={'3 1 auto'}/>
		<Stack direction={'row'} justifyContent={'flex-end'} gap={2} flex={'1 3 auto'}>
			<FormControl sx={{ flex: '2 1 auto' }}>
				<InputLabel id="action-type-label">Action Type</InputLabel>
				<Select
					labelId="action-type-label"
					id="action-type"
					label="Action Type"
					autoWidth
					value={type}
					onChange={onTypeChange}
				>
					<MenuItem value="none" selected={'none' === type}>None</MenuItem>
					{inputs.length && <MenuItem value="input" selected={'input' === type}>Modify Input</MenuItem>}
					{sections.length && <MenuItem value="section" selected={'section' === type}>Modify Section</MenuItem>}
					{outputs.length && <MenuItem value="output" selected={'output' === type}>Modify Output</MenuItem>}
				</Select>
			</FormControl>
			{type !== 'none' && <FormControl sx={{ flex: '3 1 auto' }}>
				<InputLabel id="action-target-label">Action Target</InputLabel>
				<Select
					labelId="action-target-label"
					id="action-target"
					label="Action Target"
					autoWidth
					value={target}
					onChange={onTargetChange}
				>
					{type === 'input' ? inputs.map(({ id, title, sectionTitle }) =>
							<MenuItem key={id} value={id} selected={id === target}>{sectionTitle} - {title}</MenuItem>) :
						type === 'section' ? sections.map(({ id, title }) =>
								<MenuItem key={id} value={id} selected={id === target}>{title}</MenuItem>) :
							outputs.map(({ id, title }) => <MenuItem key={id} value={id} selected={id === target}>{title}</MenuItem>)}
				</Select>
			</FormControl>}
			{(type === 'input' || type === 'section') && <FormControl sx={{ flex: '1 1 auto' }}>
				<InputLabel id="action-target-label">Action Display</InputLabel>
				<Select
					labelId="action-target-label"
					id="action-target"
					label="Action Display"
					autoWidth
					value={display}
					onChange={onDisplayChange}
				>
					<MenuItem value="visible" selected={'visible' === display}>Visible</MenuItem>
					<MenuItem value="hidden" selected={'hidden' === display}>Hidden</MenuItem>
					{type === 'input' && <MenuItem value="required" selected={'required' === display}>Required</MenuItem>}
					{type === 'input' && <MenuItem value="optional" selected={'optional' === display}>Optional</MenuItem>}
				</Select>
			</FormControl>}
		</Stack>
	</Stack>
}

function ShortAnswer ({ onStateChange, validation, placeholder: _placeholder }) {

	const [ placeholder, setPlaceholder ] = useState(_placeholder)
	useEffect(() => {
		setPlaceholder(_placeholder)
	}, [ _placeholder ])

	const onValidationChange = (event) => {
		onStateChange({
			validation: event.target.value,
			placeholder
		})
	}

	const onPlaceholderChange = () => {
		if (_placeholder !== placeholder) {
			onStateChange({
				validation,
				placeholder
			})
		}
	}

	return <Stack gap={1}>
		<Typography variant="h5">Short Answer Options</Typography>
		<Stack direction={'row'} alignItems={'center'} gap={2}>
			<TextField
				sx={{ flex: '2 1 12rem' }}
				label="Placeholder"
				value={placeholder}
				onChange={(e) => setPlaceholder(e.target.value)}
				onBlur={onPlaceholderChange}
			/>
			<FormControl fullWidth sx={{ flex: '1 1 8rem' }}>
				<InputLabel id="validation-type-label">Validation Type</InputLabel>
				<Select
					labelId="validation-type-label"
					id="validation-type"
					label="Validation Type"
					value={validation}
					onChange={onValidationChange}
				>
					<MenuItem key="0" value="none" selected={'none' === validation}>None</MenuItem>
					<MenuItem key="1" value="number" selected={'number' === validation}>Number</MenuItem>
					<MenuItem key="2" value="url" selected={'url' === validation}>URL</MenuItem>
					<MenuItem key="3" value="date" selected={'date' === validation}>Date</MenuItem>
					<MenuItem key="4" value="time" selected={'time' === validation}>Time</MenuItem>
				</Select>
			</FormControl>
		</Stack>
	</Stack>
}

function Dropdown ({ onStateChange, options = [], id, sid, sections: _sections = [], outputs = [] }) {
	const { sections, inputs } = computeSectionsInputs({ id, sid, sections: _sections })

	const onAddOption = () => {
		onStateChange({
			options: [ ...options, {
				...STATES.dropdown.options[0],
				title: `Option ${options.length + 1}`
			} ]
		})
	}

	return <>
		<Typography variant={'h5'}>Dropdown Options</Typography>
		<Stack gap={2} flex={'1 1 auto'}>
			<Typography>Options</Typography>
			<Stack component={'ol'} pl={3} gap={2}>
				{options.map((option, i) => <Option key={i} Row={ListRow} {...{ ...option, sections, inputs, outputs }} onStateChange={(state) => {
					options[i] = state
					onStateChange({ options })
				}} onRemoveClick={() => {
					options.splice(i, 1)
					onStateChange({ options })
				}} removedDisabled={options.length <= 1}/>)}
			</Stack>
			<Box pl={5}>
				<Button variant={'outlined'} onClick={onAddOption}>Add Option</Button>
			</Box>
		</Stack>
	</>
}

function MultipleChoice ({ onStateChange, options = [], id, sid, sections: _sections = [], outputs = [] }) {
	const { sections, inputs } = computeSectionsInputs({ id, sid, sections: _sections })

	const onAddOption = () => {
		onStateChange({
			options: [ ...options, {
				...STATES.dropdown.options[0],
				title: `Option ${options.length + 1}`
			} ]
		})
	}

	return <Stack gap={2}>
		<Typography variant="h5">Multiple Choice Options</Typography>
		<Stack direction={'row'} justifyContent={'space-between'}>
			<Typography>Option</Typography>
			<Typography>Action</Typography>
		</Stack>
		{options.map((option, i) => <Option key={i} Row={RadioRow} {...{ ...option, sections, inputs, outputs }} onStateChange={(state) => {
			options[i] = state
			onStateChange({ options })
		}} onRemoveClick={() => {
			options.splice(i, 1)
			onStateChange({ options })
		}} removedDisabled={options.length <= 1}/>)}
		<Box pl={5}>
			<Button variant={'outlined'} onClick={onAddOption}>Add Option</Button>
		</Box>
	</Stack>
}

function Checkboxes ({ onStateChange, options = [], id, sid, sections: _sections = [], outputs = [] }) {
	const { sections, inputs } = computeSectionsInputs({ id, sid, sections: _sections })

	const onAddOption = () => {
		onStateChange({
			options: [ ...options, {
				...STATES.checkboxes.options[0],
				title: `Option ${options.length + 1}`
			} ]
		})
	}

	return <Stack gap={2}>
		<Typography variant="h5">Checkboxes Options</Typography>
		{options.map((option, i) => <Option key={i} Row={CheckboxRow} {...{ ...option, sections, inputs, outputs }} onStateChange={(state) => {
			options[i] = state
			onStateChange({ options })
		}} onRemoveClick={() => {
			options.splice(i, 1)
			onStateChange({ options })
		}} removedDisabled={options.length <= 1}/>)}
		<Box pl={5}>
			<Button variant={'outlined'} onClick={onAddOption}>Add Option</Button>
		</Box>
	</Stack>
}

function MultipleChoiceGrid ({ onStateChange, rows = [], cols = [] }) {

	const onAddRow = () => {
		onStateChange({
			rows: [ ...rows, {
				...STATES.choicegrid.rows[0],
				title: `Row ${rows.length + 1}`
			} ],
			cols
		})
	}

	const onAddCol = () => {
		onStateChange({
			rows,
			cols: [ ...cols, {
				...STATES.choicegrid.cols[0],
				title: `Col ${cols.length + 1}`
			} ]
		})
	}

	return <>
		<Typography variant={'h5'}>Multiple Choice Grid Options</Typography>
		<Stack direction={'row'} gap={3}>
			<Stack gap={2} flex={'1 1 auto'}>
				<Typography>Rows</Typography>
				<ol style={{ display: 'flex', flexDirection: 'column', paddingLeft: '1rem', margin: 0, gap: '1rem' }}>
					{rows.map((row, i) => <ListRow key={i} {...row} onTitleChange={(title) => {
						rows[i] = { title }
						onStateChange({ rows, cols })
					}} onRemoveClick={() => {
						rows.splice(i, 1)
						onStateChange({ rows, cols })
					}} disabled={rows.length <= 1}/>)}
				</ol>
				<Box pl={5}>
					<Button variant={'outlined'} onClick={onAddRow}>Add Row</Button>
				</Box>
			</Stack>
			<Stack gap={2} flex={'1 1 auto'}>
				<Typography>Columns</Typography>
				{cols.map((col, i) => <RadioRow key={i} {...col} onTitleChange={(title) => {
					cols[i] = { title }
					onStateChange({ rows, cols })
				}} onRemoveClick={() => {
					cols.splice(i, 1)
					onStateChange({ rows, cols })
				}} disabled={cols.length <= 1}/>)}
				<Box pl={5}>
					<Button variant={'outlined'} onClick={onAddCol}>Add Column</Button>
				</Box>
			</Stack>
		</Stack>
	</>
}

function CheckboxGrid ({ onStateChange, rows = [], cols = [] }) {

	const onAddRow = () => {
		onStateChange({
			rows: [ ...rows, {
				...STATES.checkboxgrid.rows[0],
				title: `Row ${rows.length + 1}`
			} ],
			cols
		})
	}

	const onAddCol = () => {
		onStateChange({
			rows,
			cols: [ ...cols, {
				...STATES.checkboxgrid.cols[0],
				title: `Col ${cols.length + 1}`
			} ]
		})
	}

	return <>
		<Typography variant={'h5'}>Checkbox Grid Options</Typography>
		<Stack direction={'row'} gap={3}>
			<Stack gap={2} flex={'1 1 auto'}>
				<Typography>Rows</Typography>
				<ol style={{ display: 'flex', flexDirection: 'column', paddingLeft: '1rem', margin: 0, gap: '1rem' }}>
					{rows.map((row, i) => <ListRow key={i} {...row} onTitleChange={(title) => {
						rows[i] = { title }
						onStateChange({ rows, cols })
					}} onRemoveClick={() => {
						rows.splice(i, 1)
						onStateChange({ rows, cols })
					}} disabled={rows.length <= 1}/>)}
				</ol>
				<Box pl={5}>
					<Button variant={'outlined'} onClick={onAddRow}>Add Row</Button>
				</Box>
			</Stack>
			<Stack gap={2} flex={'1 1 auto'}>
				<Typography>Columns</Typography>
				{cols.map((col, i) => <CheckboxRow key={i} {...col} onTitleChange={(title) => {
					cols[i] = { title }
					onStateChange({ rows, cols })
				}} onRemoveClick={() => {
					cols.splice(i, 1)
					onStateChange({ rows, cols })
				}} disabled={cols.length <= 1}/>)}
				<Box pl={5}>
					<Button variant={'outlined'} onClick={onAddCol}>Add Column</Button>
				</Box>
			</Stack>
		</Stack>
	</>
}

function Slider ({ onStateChange, min: _min, max: _max, unit: _unit }) {
	const [ min, setMin ] = useState(_min)
	useEffect(() => {
		setMin(_min)
	}, [ _min ])

	const onMinChange = () => {
		if (_min !== min) {
			onStateChange({
				min
			})
		}
	}

	const [ max, setMax ] = useState(_max)
	useEffect(() => {
		setMax(_max)
	}, [ _max ])

	const onMaxChange = () => {
		if (_max !== max) {
			onStateChange({
				max
			})
		}
	}

	const [ unit, setUnit ] = useState(_unit)
	useEffect(() => {
		setUnit(_unit)
	}, [ _unit ])

	const onUnitChange = () => {
		if (_unit !== unit) {
			onStateChange({
				unit
			})
		}
	}

	return <>
		<Typography variant={'h5'}>Slider Options</Typography>
		<Stack direction={'row'} gap={2}>
			<TextField
				sx={{ flex: '1 1 12rem' }}
				label="Minimum"
				type="number"
				value={min}
				onChange={(e) => setMin(e.target.value)}
				onBlur={onMinChange}
			/>
			<TextField
				sx={{ flex: '1 1 12rem' }}
				label="Maximum"
				type="number"
				value={max}
				onChange={(e) => setMax(e.target.value)}
				onBlur={onMaxChange}
			/>
			<TextField
				sx={{ flex: '2 1 12rem' }}
				label="Unit"
				value={unit}
				onChange={(e) => setUnit(e.target.value)}
				onBlur={onUnitChange}
			/>
		</Stack>
	</>
}

const NAMES = Object.freeze({
	line: 'Short Answer',
	field: 'Paragraph',
	dropdown: 'Dropdown',
	choices: 'Multiple Choice',
	checkboxes: 'Checkboxes',
	choicegrid: 'Multiple Choice Grid',
	checkboxgrid: 'Checkbox Grid',
	slider: 'Slider',
	blank: 'Description Only'
})

const TYPES = Object.freeze({
	line: ShortAnswer,
	dropdown: Dropdown,
	choices: MultipleChoice,
	checkboxes: Checkboxes,
	choicegrid: MultipleChoiceGrid,
	checkboxgrid: CheckboxGrid,
	slider: Slider
})

const STATES = Object.freeze({
	line: {
		validation: 'none',
		placeholder: ''
	},
	field: {},
	dropdown: {
		options: [ {
			title: 'Option 1',
			type: 'none',
			target: null,
			display: 'visible'
		} ]
	},
	choices: {
		options: [ {
			title: 'Option 1',
			type: 'none',
			target: null,
			display: 'visible'
		}, {
			title: 'Option 2',
			type: 'none',
			target: null,
			display: 'visible'
		} ]
	},
	checkboxes: {
		options: [ {
			title: 'Option 1',
			type: 'none',
			target: null,
			display: 'visible'
		}, {
			title: 'Option 2',
			type: 'none',
			target: null,
			display: 'visible'
		} ]
	},
	choicegrid: {
		rows: [ {
			title: 'Row 1'
		} ],
		cols: [ {
			title: 'Col 1'
		}, {
			title: 'Col 2'
		} ]
	},
	checkboxgrid: {
		rows: [ {
			title: 'Row 1'
		} ],
		cols: [ {
			title: 'Col 1'
		}, {
			title: 'Col 2'
		} ]
	},
	slider: {
		min: 0,
		max: 100,
		unit: 'Dollars'
	},
	blank: {}
})

export default function InputGroup ({ section, sections, outputs, total, order, setOrder, state, setState, onDuplicate, onDelete, powerUps }) {

	useEffect(() => {
		if (setState && state.id && !state?.type) {
			setState({
				type: 'line',
				id: state.id,
				title: state?.title || 'Input Title',
				description: state?.description || '',
				visible: true,
				required: true,
				prop: cloneDeep(STATES['line'])
			})
		}
	}, [ state.type ])

	const onInputChange = (event) => {
		const type = event.target.value
		setState({
			type,
			id: state?.id || null,
			title: state?.title || 'Input Title',
			description: state?.description || '',
			visible: true,
			required: true,
			prop: type === state?.type && state?.prop ? state.prop : cloneDeep(STATES[type])
		})
	}

	const onOrderChange = (event) => {
		setOrder(event.target.value)
	}

	const [ title, setTitle ] = useState(state.title || '')
	const [ description, setDescription ] = useState(state.description || '')
	useEffect(() => {
		setTitle(state.title || '')
	}, [ state.title ])
	useEffect(() => {
		setDescription(state.description || '')
	}, [ state.description ])

	const onTitleChange = () => {
		if (state.title !== title) {
			setState({
				...state,
				title
			})
		}
	}

	const onDescriptionChange = (_description) => {
		console.log(_description)
		setDescription(_description)
		if (state.description !== _description) {
			setState({
				...state,
				description: _description
			})
		}
	}

	const Input = TYPES[state?.type]

	return <Card variant={'outlined'}>
		<Stack gap={3} p={3}>
			<Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
				<Typography variant={'h4'} color={theme.palette.text.secondary}>{NAMES[state?.type]}&nbsp;Input</Typography>
				<FormControl sx={{ flex: '0 0 8rem' }} size={'small'}>
					<InputLabel id="order-label">Order</InputLabel>
					<Select
						labelId="order-label"
						id="order"
						label="Order"
						value={order}
						onChange={onOrderChange}
					>{Array(total).fill(0, 0).map((_, i) => <MenuItem key={i} value={i} selected={order === i}>{i + 1}</MenuItem>)}</Select>
				</FormControl>
			</Stack>
			<Divider/>
			<Stack direction={'row'} gap={2} flexWrap={'wrap'} alignItems={'center'}>
				<TextField sx={{ flex: '1 1 8rem' }}
						   label="Input Title"
						   value={title}
						   onChange={(e) => setTitle(e.target.value)}
						   onBlur={() => onTitleChange()}
						   required
				/>
				<FormControl fullWidth sx={{ flex: '1 0 4rem' }}>
					<InputLabel id="input-type-label">Input Type</InputLabel>
					<Select
						labelId="input-type-label"
						id="input-type"
						label="Input Type"
						value={state?.type ?? 'line'}
						onChange={onInputChange}
					>{Object.entries(NAMES).map(([ value, label ]) => <MenuItem key={value} value={value}>{label}</MenuItem>)}</Select>
				</FormControl>
			</Stack>
			<Stack gap={1}>
				<Typography variant="h5">Input Description</Typography>
				<Editor
					label="Description…"
					defaultValue={description}
					onSave={onDescriptionChange}
					getMediaUrl={(file) => powerUps.uploadMedia({ file })}
				/>
			</Stack>
			{/*<TextField multiline*/}
			{/*		   label="Input Description"*/}
			{/*		   value={description}*/}
			{/*		   onChange={(e) => setDescription(e.target.value)}*/}
			{/*		   onBlur={() => onDescriptionChange()}*/}
			{/*/>*/}
			{Input &&
				<Input {...(state?.prop ?? {})} id={state.id} sid={section.id}
					   sections={sections} outputs={outputs}
					   onStateChange={(prop) => setState({ ...state, prop })}/>}
			<Divider/>
			<Stack direction={'row'} justifyContent={'end'} gap={3}>
				<FormControlLabel
					label="Visible"
					control={<Switch checked={!!state?.visible}
									 onChange={() => setState({ ...state, visible: !state.visible })}/>}
				/>
				<FormControlLabel
					label="Required"
					control={<Switch checked={!!state?.required} disabled={!state.visible}
									 onChange={() => setState({ ...state, required: !state.required })}/>}
				/>
				<Tooltip title="Duplicate"><IconButton aria-label="Duplicate" onClick={onDuplicate}><CopyAll/></IconButton></Tooltip>
				<Tooltip title="Delete"><IconButton aria-label="Delete" onClick={onDelete}><Delete/></IconButton></Tooltip>
			</Stack>
		</Stack>
	</Card>
}
