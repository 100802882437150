import React, { useState, useEffect } from "react";
import { Box, Divider, Typography, Grid, Button } from "@mui/material";
import { useSelector } from "react-redux";
import DialogComponent from "../../PowerUP/IdeaValidation/Components/Dialog";
import theme from "../../../../constants/Theme";
import DateTimeSlotPicker from "../../../../common/DateTimeslotPicker";
import SelectedDatesCard from "../../Plugins/AdvisorPlugin/Components/SelectedDatePopover";
import LoadingButton from "../../../../common/Loadingbutton";
import { validateAdvisorPluginInput } from "../../Plugins/AdvisorPlugin/Functions/common";
import services from "../../../../services";
import { sanitizeDateTimePayload } from "../../Plugins/AdvisorPlugin/Functions/common";
import SuccessPopup from "../../Plugins/AdvisorPlugin/Components/SuccessPopup";
import InviteMeetingDetails from "../Components/InviteMeetingDetails";
import PreviewInviteCandidate from "../Components/PreviewInviteCandidate";
import { getLocalTimeZone } from "../../../../utils/date";
import { REGEX } from "../../../../constants/regex";
import { AVATAR_DEFAULT } from "../../../../constants";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";
import { EditorState, ContentState } from "draft-js";
import { stateFromMarkdown } from "draft-js-import-markdown";

const InviteCandidateComp = ({
  showInviteCandidatePopup,
  setShowInviteCandidatePopup,
  candidateObj,
  handleInviteSuccess,
  stepId,
}) => {
  const currentEnv = process.env.NODE_ENV;
  const [userInputs, setUserInputs] = useState({
    agenda: "",
    duration: "",
    email: "",
  });
  const [responses, setResponses] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [currentStep, setCurrentStep] = useState(1);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [subjectContent, setSubjectContent] = useState("");
  const [descriptionContent, setDescriptionContent] = useState("");
  const [openSuccessPopup, setOpenSuccessPopup] = useState(false);
  const marketTestData = useSelector(state => state?.powerUp?.marketTest);

  const handleconfirmDisable = () => {
    if (currentStep === 1) {
      return !responses.length;
    } else {
      const contentState = stateFromHTML(descriptionContent);
      const editorState = EditorState.createWithContent(contentState);
      const eventValue = editorState.getCurrentContent().getPlainText("\u0001");
      if (
        validateAdvisorPluginInput(userInputs) &&
        subjectContent.match(REGEX.EMAIL_SUBJECT) &&
        eventValue.length >= 3 &&
        eventValue.length <= 500
      ) {
        return false;
      } else {
        return true;
      }
    }
  };
  const handleNext = () => {
    if (currentStep === 1) {
      setCurrentStep(2);
    } else if (currentStep === 2) {
      setCurrentStep(3);
    } else {
      sendInviteToCandidate();
    }
  };
  const handleBack = () => {
    if (currentStep === 3) {
      setCurrentStep(2);
    } else if (currentStep === 2) {
      setCurrentStep(1);
    }
  };

  useEffect(() => {
    if (!showInviteCandidatePopup) {
      setResponses([]);
      setCurrentStep(1);
      setSelectedDate(null);
      setSubjectContent("");
      setDescriptionContent("");
      setUserInputs({
        agenda: "",
        duration: "",
        email: "",
      });
    }
  }, [showInviteCandidatePopup]);

  const sendInviteToCandidate = () => {
    setButtonLoading(true);
    const candidateData = {
      userId: candidateObj.isExternalCandidate ? "" : candidateObj.id,
      candidateInvitationId: candidateObj.isExternalCandidate
        ? candidateObj.id
        : "",
      marketTestResponseId: marketTestData.id,
      calendarEvent: {
        calendarEventType: "IDEA_VALIDATION",
        agenda: userInputs?.agenda,
        email: candidateObj?.email,
        duration: parseInt(userInputs?.duration?.replace("min", "")),
        content: descriptionContent,
        subject:
          currentEnv === "development" || currentEnv === "staging"
            ? `[TEST] ${subjectContent}`
            : subjectContent,
        timezone: getLocalTimeZone(),
        calendarEventDetails: sanitizeDateTimePayload(responses),
      },
    };
    const currentModuleObject = candidateObj?.currentModuleObject;

    const currentModuleAnswers =
      currentModuleObject?.moduleStepResponses[0]?.answers;

    const currentCandidates = currentModuleAnswers
      ? JSON.parse(currentModuleAnswers)?.candidates
      : [];

    let candidateIdx = currentCandidates?.findIndex(
      cand => cand?.id === candidateObj?.id
    );

    if (candidateIdx > 0) {
      currentCandidates[candidateIdx] = {
        ...currentCandidates[candidateIdx],
        invited: true,
      };
    } else {
      currentCandidates?.push({
        avatar: candidateObj?.avatar,
        email: candidateObj?.email,
        id: candidateObj?.id,
        invited: true,
        name: candidateObj?.name,
        selected: false,
        invitedDate: new Date(),
      });
    }

    const modulePayload = {
      answers: JSON.stringify({
        candidates: currentCandidates,
      }),
      moduleResponseId: currentModuleObject?.id,
      isLastStep: true,
      isCompleted: true,
      moduleStepId: stepId,
    };

    services
      .sendInterviewInvite(candidateData)
      .then(data => {
        services
          .storeModuleStepResponses(
            currentModuleObject?.moduleId,
            modulePayload
          )
          .then(dt => {
            setButtonLoading(false);
            setShowInviteCandidatePopup(false);
            setOpenSuccessPopup(true);
          })
          .catch(err => {
            console.log(err);
            setButtonLoading(false);
          });
      })
      .catch(err => {
        console.log(err);
        setButtonLoading(false);
      });
  };

  const handlePopupsClose = () => {
    handleInviteSuccess();
    setOpenSuccessPopup(false);
  };
  const onContentChanged = (subject, message) => {
    setSubjectContent(subject);
    setDescriptionContent(message);
  };

  const successContentComponent = () => {
    return (
      <Box textAlign={"center"} sx={{ px: 3 }}>
        <Box sx={{ my: 3 }}>
          <Typography variant="subhead1">
            You've sent your interview invite.
          </Typography>
        </Box>
        <Box>
          <Typography variant="subhead1">
            Your chosen candidate will be notified and select a time that works
            or will suggest a new time.
          </Typography>
        </Box>
        <Box sx={{ my: 3 }}>
          <Typography variant="subhead1">
            All of your candidate invites will now be displayed in the
          </Typography>
          <Typography variant="custom070">
            &nbsp;Idea Validation Interview Overview.
          </Typography>
        </Box>
        <Box>
          <Button
            contained
            onClick={() => {
              window.location.reload();
            }}
          >
            View Candidate Invites
          </Button>
        </Box>
      </Box>
    );
  };

  return (
    <>
      <DialogComponent
        isOpen={showInviteCandidatePopup}
        onClose={() => setShowInviteCandidatePopup(false)}
        title={"Invite Candidate"}
        titleVariant={"title_large"}
        contentStyle={{ px: 0 }}
        maxWidth={"lg"}
        fullWidth
        PaperProps={{
          style: { borderRadius: 28 },
        }}
        dialogAction={
          <Box display="flex" sx={{ mr: 2 }}>
            <Box sx={{ mr: 1 }}>
              {currentStep === 1 ? (
                <SelectedDatesCard
                  responses={responses}
                  setResponses={setResponses}
                />
              ) : (
                <Button variant="outline" onClick={handleBack}>
                  Back
                </Button>
              )}
            </Box>

            <Box item>
              <LoadingButton
                disabled={handleconfirmDisable()}
                loading={buttonLoading}
                onClick={() => handleNext()}
              >
                {currentStep === 3 ? "Confirm" : "Next"}
              </LoadingButton>
            </Box>
          </Box>
        }
        disableBackDropClick
      >
        <Box>
          <Box sx={{ mt: 3, px: 3 }}>
            <Grid container spacing={2}>
              {/*Info section*/}

              <Grid item md={4}>
                <Box sx={{ my: 1, ml: 6 }}>
                  <Box
                    component="img"
                    height="56px"
                    sx={{ borderRadius: "50%" }}
                    src={candidateObj?.avatar || AVATAR_DEFAULT}
                  ></Box>
                  <Box display={"grid"} sx={{ mt: 2, wordBreak: "break-all" }}>
                    <Typography variant="largeTitle">
                      {candidateObj?.name ? candidateObj?.name : ""}
                    </Typography>
                    <Typography variant="subhead1" sx={{ mt: 2 }}>
                      {candidateObj?.email}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item md={1} display="flex" justifyContent={"center"}>
                <Divider orientation="vertical" flexItem />
              </Grid>

              {/*Select DateTime section*/}

              {/*Giving min-width & max-width as this section is going to be

              dynamic with time slots so cant use grid layout here*/}

              <Grid item md={7}>
                {currentStep === 1 ? (
                  <Box>
                    <Box display={"grid"}>
                      <Typography variant="title_medium">
                        Select a Date & Time
                      </Typography>
                      <Typography variant="bodyMedium" sx={{ mt: 3 }}>
                        We recommend choosing your available time slots no more
                        than 7 days out from the day you send the invite.
                      </Typography>
                    </Box>
                    <DateTimeSlotPicker
                      responses={responses}
                      setResponses={setResponses}
                      minTimeSlots={1}
                      maxTimeSlots={5}
                      containerStyles={theme => ({
                        background:
                          "linear-gradient(0deg, rgba(118, 73, 160, 0.05), rgba(118, 73, 160, 0.05)), #FCFCFC",
                        border: `1px solid ${theme.palette.secondary.light}`,
                        borderRadius: theme.shape.standard3.borderRadius,
                        p: 1,
                        mt: 3,
                      })}
                      selectedDate={selectedDate}
                      setSelectedDate={setSelectedDate}
                    />
                  </Box>
                ) : currentStep === 2 ? (
                  <InviteMeetingDetails
                    selectedDateTimes={responses}
                    userInputs={userInputs}
                    setUserInputs={setUserInputs}
                    boxPadding={0}
                    onContentChanged={onContentChanged}
                    durationValue={userInputs?.duration}
                    subjectContent={subjectContent}
                    descriptionContent={descriptionContent}
                  />
                ) : (
                  <PreviewInviteCandidate
                    selectedDateTimes={responses}
                    descriptionContent={descriptionContent}
                    duration={parseInt(
                      userInputs?.duration?.replace("min", "")
                    )}
                  />
                )}
              </Grid>
            </Grid>
          </Box>
        </Box>
      </DialogComponent>
      <SuccessPopup
        title={"Invite Sent"}
        open={openSuccessPopup}
        close={handlePopupsClose}
        ContentComponent={successContentComponent}
      />
    </>
  );
};

export default InviteCandidateComp;
