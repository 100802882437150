//import { Box } from "@twilio-paste/core";
//import { useTheme } from "@twilio-paste/theme";
import { ArrowBack, ArrowBackIos, CallReceived, MenuBook, MoreHoriz, PersonAdd, PersonAddOutlined } from "@mui/icons-material";
import { Box, IconButton, Menu, MenuItem, Typography, useMediaQuery, AvatarGroup, Avatar } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../../../Messaging/store";
import Settings from "../../../../Messaging/components/settings/Settings";
import AddToConversation from "./AddToConversation";
import LeaveConversation from "./LeaveConversation";
import AvatarWithFallBack from "../../../../../common/AvatarWithFallBack";
const ConversationDetails = (props) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const [confOpen, setConfOpen] = useState(false);
	const [addOpen, setAddOpen] = useState(false);
	const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("lg"));
	const dispatch = useDispatch();
	const { updateCurrentConversation } = bindActionCreators(actionCreators, dispatch);
	//const theme = useTheme();
	const getConversationName = () => {
		if (props?.convo?.startuposMeta?.conversationParticipants) {
			let name = "";
			props?.convo?.startuposMeta?.conversationParticipants?.map((participant, index) => {
				name += participant?.name;
				if (index < props?.convo?.startuposMeta?.conversationParticipants?.length - 1) {
					name += " & ";
				}
			});
			return name;
		} else if (props?.convo?.startuposMeta?.name) {
			return props?.convo?.startuposMeta?.name;
		} else {
			return "";
		}
	};

	return (
		<Box
			sx={(theme) => ({
				p: 1,
				mb: 2,
				// borderBottom: "1px solid",
				// borderColor: theme.palette.secondary.light,
			})}
		>
			<Box
				sx={{
					height: "40px",
					display: "flex",
					alignItems: "center",
					justifyContent: "space-between",
				}}
			>
				<Box
					// Name Box
					sx={{
						flex: 1,
						minWidth: 0,
						display: "flex",
						alignItems: "center",
						gap: 2,
					}}
				>
					{/* {isSmallScreen && (
						<IconButton onClick={() => updateCurrentConversation("")}>
							<ArrowBack />
						</IconButton>
					)} */}
					{props?.convo?.startuposMeta?.conversationParticipants && (
						<Box>
							<AvatarGroup
								max={1}
								componentsProps={{
									additionalAvatar: {
										sx: {
											background: (theme) => theme.palette.text.onSurfaceVariant,
											borderWidth: "1px",
											height: "64px",
											width: "64px",
											fontSize: "80%",
											position: "absolute",
											right: "0",
											bottom: "0",
											zIndex: "1",
										},
									},
								}}
								sx={{
									width: "64px",
									height: "64px",
									"&": () => {
										if (
											props?.convo?.startuposMeta?.conversationType === "GROUP" ||
											props?.convo?.startuposMeta?.conversationParticipants?.length > 1
										) {
											return {
												position: "relative",
												overflow: "hidden",
											};
										}
									},
								}}
							>
								{props?.convo?.startuposMeta?.conversationParticipants?.map((item, index) => {
									return (
										<AvatarWithFallBack
											src={item?.picture}
											sx={{
												height: "64px",
												width: "64px",
												"&": () => {
													if (
														props?.convo?.startuposMeta?.conversationType === "GROUP" ||
														props?.convo?.startuposMeta?.conversationParticipants?.length > 1
													) {
														let positionOb =
															index == 0 ? { left: "0", top: "0", zIndex: "0" } : { right: "0", bottom: "0", zIndex: "1" };
														return {
															height: "64px",
															width: "64px",
															borderWidth: "1px !important", // Override the Avatar circle only for groups. Low ROI to figure out global variant for this case. !important is acceptable inline.
															position: "absolute",
															...positionOb,
														};
													}
												},
											}}
										/>
									);
								})}
							</AvatarGroup>
						</Box>
					)}
					<Typography
						component="span"
						variant="Text/md/Semibold"
						color={"#667085"}
						sx={{
							whiteSpace: "nowrap",
							overflow: "hidden",
							textOverflow: "ellipsis",
							display: "block",
						}}
					>
						{getConversationName()}
					</Typography>
				</Box>
				<Box
				// Action Box
				>
					<LeaveConversation
						open={confOpen}
						conversationID={props?.convo?.sid}
						close={() => {
							setAnchorEl(false);
							setConfOpen(false);
						}}
					/>
					<Menu
						elevation={1}
						PaperProps={{
							sx: {
								background: (theme) => theme.palette.primary.read_only_light_surface2,
							},
						}}
						open={Boolean(anchorEl)}
						anchorEl={anchorEl}
						anchorOrigin={{
							vertical: "bottom",
							horizontal: "right",
						}}
						transformOrigin={{
							vertical: "top",
							horizontal: "right",
						}}
						onClose={() => {
							setAnchorEl(null);
						}}
					>
						<MenuItem
							sx={{
								minWidth: "200px",
								display: "flex",
								gap: 2,
								alignItems: "center",
							}}
							onClick={async () => {
								setConfOpen(true);
							}}
						>
							<CallReceived />
							<Typography variant="body_large">Leave</Typography>
						</MenuItem>
						{props?.convo?.startuposMeta?.conversationType === "GROUP" && (
							<MenuItem
								sx={{
									minWidth: "200px",
									display: "flex",
									gap: 2,
									alignItems: "center",
								}}
								// TODO -- On click need to 'leave' conversation
								onClick={async () => {
									setAddOpen(true);
								}}
							>
								<PersonAddOutlined />

								<Typography variant="body_large">Add People</Typography>
								<AddToConversation
									open={addOpen}
									conversationID={props?.convo?.sid}
									close={() => {
										setAnchorEl(false);
										setAddOpen(false);
									}}
								/>
							</MenuItem>
						)}
					</Menu>

					{/*Commented Icon as per Scott's requirement SOS-506*/}

					{/*<IconButton
            sx={{
              color: theme => theme.palette.text.lightOnsurface,
            }}
            onClick={event => {
              setAnchorEl(anchorEl ? null : event.currentTarget);
            }}
          >
            <MoreHoriz />
          </IconButton>*/}
				</Box>
			</Box>
		</Box>
		//<Box
		//  style={{
		//    minHeight: 65,
		//    maxHeight: 65,
		//    paddingLeft: 16,
		//    paddingRight: 16,
		//    borderBottomStyle: "solid",
		//    borderBottomWidth: 1,
		//    borderBottomColor: theme.borderColors.colorBorderWeak,
		//  }}
		//>
		//  <Box
		//    style={{
		//      height: "100%",
		//      display: "flex",
		//      flexDirection: "row",
		//      justifyContent: "space-between",
		//    }}
		//  >
		//    <Box
		//      style={{
		//        display: "flex",
		//        flexDirection: "row",
		//        alignItems: "center",
		//      }}
		//      color="colorText"
		//      fontFamily="fontFamilyText"
		//      fontSize="fontSize50"
		//      lineHeight="lineHeight80"
		//      fontWeight="fontWeightBold"
		//      maxHeight="100%"
		//    >
		//      {props.convo.friendlyName ?? props.convo.sid}
		//    </Box>
		//    {/* Commenting out messaging settings */}
		//    <Box
		//      style={{
		//        display: "flex",
		//        flexDirection: "row",
		//      }}
		//    >
		//      <Box
		//        style={{
		//          height: "100%",
		//          display: "flex",
		//          alignItems: "center",
		//        }}
		//        color="colorTextWeak"
		//        fontFamily="fontFamilyText"
		//        fontSize="fontSize30"
		//        lineHeight="lineHeight40"
		//        fontWeight="fontWeightNormal"
		//        paddingRight="space60"
		//      >
		//        {`${props.participants.length}`}
		//        {props.participants.length > 1 ? " participants" : " participant"}
		//      </Box>
		//      <Settings convo={props.convo} participants={props.participants} />
		//    </Box>
		//  </Box>
		//</Box>
	);
};
export default ConversationDetails;
