import { Box } from "@mui/material";
import React from "react";

export const ContentBlock = ({ children }) => (
	<Box
		sx={{
			display: "flex",
			padding: "1.5rem",
			flexDirection: "column",
			alignItems: "flex-start",
			gap: "1.5rem",
			alignSelf: "stretch",
			borderRadius: "1.5rem",
			background: "#F5F9FA",
		}}
	>
		{children}
	</Box>
);
