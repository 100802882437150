import React, { useEffect } from "react";
import { Box, Grid } from "@mui/material";
import useDimensions from "react-cool-dimensions";

const PublicLayout = ({ children }) => {
	const { observe, width, height } = useDimensions();
    const postSize = () => {
        window.parent.postMessage({
            frameHeight: height,
        }, '*'); // Replace '*' for all site domains 
    };

    useEffect(() => {
        postSize();
    }, [height, width]); // Trigger postSize when height or width changes

	return (
		<Box
			ref={observe}
			position="relative"
			sx={(theme) => ({
				minHeight: "fit-content",
				display: "grid",
				gridTemplateRows: "auto 1fr auto",
				background: theme.palette.secondary.bgGray,
			})}
		>
			<Grid container justifyContent={"center"} alignItems="center">
				<Grid item xs={12} sm={10} md={10} lg={10}>
					{children}
				</Grid>
			</Grid>
		</Box>
	);
};

export default PublicLayout;
