import { Box } from "@mui/material";
import React from "react";

export const HorizontalAppBar = ({ right, center, left }) => {
  return (
    <Box
      sx={{
        p: 2,
        borderBottom: "1px solid #F5F5F5",
        display: "grid",
        gridTemplateColumns: "repeat(12, 1fr)",
        boxSizing: "border-box",
      }}
    >
      <Box
        gridColumn="span 2"
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        {right}
      </Box>
      <Box
        gridColumn="span 8"
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        {center}
      </Box>
      <Box
        gridColumn="span 2"
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        {left}
      </Box>
    </Box>
  );
};
