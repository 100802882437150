import React from "react";
import TreeItem from "@mui/lab/TreeItem";
import TreeView from "@mui/lab/TreeView";
import CropSquareOutlinedIcon from "@mui/icons-material/CropSquareOutlined";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import SquareRoundedIcon from "@mui/icons-material/SquareRounded";
import IndeterminateCheckBoxOutlinedIcon from "@mui/icons-material/IndeterminateCheckBoxOutlined";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setInvestorDashboardData } from "../../modules/actions";
import { capitalizeWords } from "../../utils/textTransformations";

export default function UpdatedBasicCheckBoxTree({ lable, treeData, handleUncheck, type }) {
	const name = lable === "Location" ? "countryName" : "industryname";

	const child = lable === "Location" ? "states" : "child";
	const filteredValue = useSelector((state) => state?.investorDashboard?.filteredValue) || [];
	const selected = useSelector((state) => state?.investorDashboard?.selectedValues) || [];
	const dispatch = useDispatch();
	const partiallySelected = useSelector((state) => state?.investorDashboard?.partiallySelected);

	const selectedCheckBox = (nodeName) => {
		if (nodeName == treeData.countryName) {
			return <SquareRoundedIcon sx={{ fontSize: "20px", color: (theme) => theme.palette.primary.primaryPurple }} />;
		} else {
			// return <SquareRoundedIcon sx={{ fontSize: "20px", color: (theme) => theme.palette.primary.primaryPurple }} />;
		}
	};

	const getChildByName = (node, nodeName) => {
		let array = [];

		const getAllChild = (nodes) => {
			if (nodes === null) return [];
			array.push(nodes[name]);
			// if (Array.isArray(nodes[child])) {
			// 	nodes[child].forEach((node) => {
			// 		array = [...array, ...getAllChild(node)];
			// 		array = array?.filter((v, i) => array.indexOf(v) === i);
			// 	});
			// }
			return array;
		};

		const getNodeByName = (nodes, nodeName) => {
			if (nodes[name] === nodeName) {
				return nodes;
			} else if (Array.isArray(nodes[child])) {
				let result = null;
				nodes[child].forEach((node) => {
					if (!!getNodeByName(node, nodeName)) {
						result = getNodeByName(node, nodeName);
					}
				});
				return result;
			}
			return null;
		};

		return getAllChild(getNodeByName(node, nodeName));
	};

	const removeParentNode = (treeData, nodeName, allNode) => {
		const newFilteredValue = filteredValue?.filter((value) => !allNode.includes(value.checkboxValue));
		const newSelectedValue = selected?.filter((value) => !allNode.includes(value.stateName));
		const currentSelectedValue = selected?.filter((value) => value.cName === treeData.countryName);
		let tempPartiallySelected = partiallySelected;

		if (nodeName == treeData.countryName) {
			tempPartiallySelected.map((partialItem) => {
				if (partialItem.type === treeData.countryName) {
					partialItem.value = false;
				}
			});

			dispatch(
				setInvestorDashboardData({
					partiallySelected: [...tempPartiallySelected],
				})
			);
		} else {
			if (currentSelectedValue.length === 1) {
				tempPartiallySelected.map((partialItem) => {
					if (partialItem.type === treeData.countryName) {
						partialItem.value = false;
					}
				});
				dispatch(
					setInvestorDashboardData({
						partiallySelected: [...tempPartiallySelected],
					})
				);
			} else {
				tempPartiallySelected.map((partialItem) => {
					if (partialItem.type === treeData.countryName) {
						partialItem.value = true;
					}
				});
				dispatch(
					setInvestorDashboardData({
						partiallySelected: [...tempPartiallySelected],
					})
				);
			}
		}

		dispatch(
			setInvestorDashboardData({
				filteredValue: newFilteredValue,
				selectedValues: newSelectedValue,
				partiallySelected: [...tempPartiallySelected],
			})
		);
	};

	function getUniqueListBy(arr, key) {
		return [...new Map(arr.map((item) => [item[key], item])).values()];
	}

	const getOnChange = (checked, nodes, event, indeterminate) => {
		const currentSelectedValue = selected?.filter((value) => value.cName === treeData.countryName);
		if (nodes[name] == treeData.countryName) {
			if (currentSelectedValue.length > 0) {
				let tempPartiallySelected = partiallySelected;

				tempPartiallySelected.map((partialItem) => {
					if (partialItem.type === treeData.countryName) {
						partialItem.value = true;
					}
				});

				dispatch(
					setInvestorDashboardData({
						partiallySelected: [...tempPartiallySelected],
					})
				);
			} else {
				let tempPartiallySelected = partiallySelected;

				tempPartiallySelected.map((partialItem) => {
					if (partialItem.type === treeData.countryName) {
						partialItem.value = false;
					}
				});

				dispatch(
					setInvestorDashboardData({
						partiallySelected: [...tempPartiallySelected],
					})
				);
			}
		} else {
			if (currentSelectedValue.length === treeData.states.length) {
				let tempPartiallySelected = partiallySelected;

				tempPartiallySelected.map((partialItem) => {
					if (partialItem.type === treeData.countryName) {
						partialItem.value = false;
					}
				});
				dispatch(
					setInvestorDashboardData({
						partiallySelected: [...tempPartiallySelected],
					})
				);
			} else {
				let tempPartiallySelected = partiallySelected;

				tempPartiallySelected.map((partialItem) => {
					if (partialItem.type === treeData.countryName) {
						partialItem.value = true;
					}
				});
				dispatch(
					setInvestorDashboardData({
						partiallySelected: [...tempPartiallySelected],
					})
				);
			}
		}
		const allNode = getChildByName(treeData, nodes[name]);
		let newArray = filteredValue;
		allNode.map((item) => {
			let newObj = { type: type, checkboxValue: item };
			newArray.push(newObj);
		});

		let newSelected = [];
		allNode.map((item) => {
			let newObj = { cName: treeData.countryName, stateName: item };
			newSelected.push(newObj);
		});

		if (!checked) {
			removeParentNode(treeData, event.target.value, allNode);
		} else {
			if (
				partiallySelected?.filter((partialItem) => partialItem.type === treeData.countryName)[0]?.value &&
				nodes[name] == treeData.countryName &&
				selected.length > 0
			) {
				// removeParentNode(treeData, event.target.value, allNode);
			} else {
				dispatch(
					setInvestorDashboardData({
						filteredValue: getUniqueListBy(newArray, "checkboxValue"),
						selectedValues: [...selected, ...newSelected],
					})
				);
			}
		}
	};

	const renderTree = (nodes) => (
		<Box sx={{ display: "flex" }}>
			<TreeItem
				key={nodes[name]}
				nodeId={nodes[name]}
				sx={{
					"& .MuiTreeItem-content.Mui-filtteredValue": {
						background: (theme) => theme.palette.secondary.white,
						"&:hover": {
							background: (theme) => theme.palette.secondary.white,
						},
						"&:active": {
							background: (theme) => theme.palette.secondary.white,
						},
						"&:focused": {
							background: (theme) => theme.palette.secondary.white,
						},
						"&:filtteredValue": {
							background: (theme) => theme.palette.secondary.white,
						},
					},
					"& .MuiTreeItem-content.Mui-filtteredValue.Mui-focused": {
						background: (theme) => theme.palette.secondary.white,
						"&:hover": {
							background: (theme) => theme.palette.secondary.white,
						},
						"&:active": {
							background: (theme) => theme.palette.secondary.white,
						},
						"&:focused": {
							background: (theme) => theme.palette.secondary.white,
						},
						"&:filtteredValue": {
							background: (theme) => theme.palette.secondary.white,
						},
					},
				}}
				label={
					<FormControlLabel
						label={
							<Typography variant="title_medium">
								{nodes[name] === "United States of America"
									? "USA"
									: nodes[name] === "USA Regions"
									? nodes[name]
									: capitalizeWords(nodes[name], ["a", "an", "and", "of"])}
							</Typography>
						}
						control={
							<Checkbox
								value={nodes[name]}
								checked={filteredValue.some((filterItem) => filterItem?.checkboxValue === nodes[name] && filterItem?.type === type)}
								onChange={(event) => getOnChange(event.currentTarget.checked, nodes, event)}
								onClick={(e) => e.stopPropagation()}
								icon={<CropSquareOutlinedIcon sx={{ fontSize: "20px" }} />}
								checkedIcon={<SquareRoundedIcon sx={{ fontSize: "20px", color: (theme) => theme.palette.primary.primaryPurple }} />}
								// indeterminate={
								// 	partiallySelected?.filter((partialItem) => partialItem.type === treeData.countryName)[0]?.value &&
								// 	nodes[name] == treeData.countryName &&
								// 	selected.length > 0
								// }
								indeterminateIcon={selectedCheckBox(nodes[name])}
							/>
						}
					></FormControlLabel>
				}
			>
				{Array.isArray(nodes[child]) ? nodes[child].map((node) => renderTree(node)) : null}
			</TreeItem>
		</Box>
	);

	return (
		<TreeView defaultCollapseIcon={<IndeterminateCheckBoxOutlinedIcon />} defaultExpandIcon={<AddBoxOutlinedIcon />}>
			{renderTree(treeData)}
		</TreeView>
	);
}
