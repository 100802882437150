import React, { useState, useMemo, useCallback, useEffect } from "react";
import CommonSlideDialog from "../../../../common/CommonSlideDialog";
import { useDispatch, useSelector } from "react-redux";
import { setBIDashboardData } from "../../../../modules/actions/biDashboardActions";
import { Box, Typography, IconButton, Grid, Chip, useMediaQuery } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import AcvDataGrid from "../DataGrid/AcvGrid";
import CapGrid from "../DataGrid/CapGrid";
import ArrDataGrid from "../DataGrid/ArrGrid";
import BurnRunDataGrid from "../DataGrid/BurnRunGrid";
import CustomersDataGrid from "../DataGrid/CustomersGrid";
import MrrDataGrid from "../DataGrid/MrrDataGrid";
import NetArrDataGrid from "../DataGrid/NetArrGrid";
import PaybackDataGrid from "../DataGrid/PayBackGrid";
import RevenueDataGrid from "../DataGrid/RevenueGrid";
import SmExpenseVsNewSalesDataGrid from "../DataGrid/SmExpenseVsNewSalesGrid";
import { handleMixPanel } from "../../../../utils/mixPanelEventHandling";
import BurnMultipleGrid from "../DataGrid/BurnMultipleGrid";
import MagicNumberGrid from "../DataGrid/MagicNumberGrid";

function UpdateMatrics({ companyId }) {
	const dispatch = useDispatch();
	const openEdit = useSelector((store) => store?.biDashboard?.openEdit);
	const editTableName = useSelector((store) => store?.biDashboard?.editTableName);
	const [isEdited, setEdited] = useState(false);
	const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

	const openRelatedLeave = useMemo(() => {
		switch (editTableName) {
			case tableName[0].name:
				return { openCapDataLeave: true };
			case tableName[1].name:
				return { openCapDataLeave: true };
			case tableName[2].name:
				return { openCustomersLeave: true };
			case tableName[3].name:
				return { openAnnualRecurringRevenueLeave: true };
			case tableName[4].name:
				return { openNetNewArrLeave: true };
			case tableName[5].name:
				return { openMonthlyRecurringRevenueLeave: true };
			case tableName[6].name:
				return { openCacPaybackLeave: true };
			case tableName[7].name:
				return { openBurnAndRunwayLeave: true };
			case tableName[8].name:
				return { openRevenueLeave: true };
			case tableName[9].name:
				return { openAcvLeave: true };
			case tableName[10].name:
				return { openSmExpenseLeave: true };
			case tableName[11].name:
				return { openBurnMultipleLeave: true };
			case tableName[12].name:
				return { openMagicNumberLeave: true };
			default:
				return {};
		}
	}, [editTableName]);

	const handleClose = useCallback(() => {
		if (isEdited) {
			dispatch(
				setBIDashboardData({
					...openRelatedLeave,
				})
			);
		} else {
			setEdited(false);
			dispatch(setBIDashboardData({ openEdit: false }));
		}
	}, [isEdited, openRelatedLeave]);

	useEffect(() => {
		setEdited(false);
		dispatch(
			setBIDashboardData({
				nextEditTableName: "",
			})
		);
		if (openEdit) {
			const tableViewing = tableName.find((table) => table.name === editTableName);
			if (tableViewing) {
				handleMixPanel(tableViewing.mixPanelEvent);
			} else {
				handleMixPanel(tableName[0].mixPanelEvent);
			}
		}
	}, [editTableName, openEdit]);

	const nextEditTableName = useSelector((store) => store?.biDashboard?.nextEditTableName);

	const ownCompanyId = useSelector((state) => state?.auth?.companyId);
	const usedCompanyId = companyId ? companyId : ownCompanyId;

	const acv = useSelector((store) => store?.biDashboard?.acv);
	const annualRecurringRevenue = useSelector((store) => store?.biDashboard?.annualRecurringRevenue);
	const capData = useSelector((store) => store?.biDashboard?.capData);
	const burnAndRunway = useSelector((store) => store?.biDashboard?.burnAndRunway);
	const customers = useSelector((store) => store?.biDashboard?.customers);
	const monthlyRecurringRevenue = useSelector((store) => store?.biDashboard?.monthlyRecurringRevenue);
	const netNewArr = useSelector((store) => store?.biDashboard?.netNewArr);
	const cacPayback = useSelector((store) => store?.biDashboard?.cacPayback);
	const revenue = useSelector((store) => store?.biDashboard?.revenue);
	const smExpense = useSelector((store) => store?.biDashboard?.smExpense);
	const burnMultiple = useSelector((store) => store?.biDashboard?.burnMultiple);
	const magicNumber = useSelector((store) => store?.biDashboard?.magicNumber);

	const showTable = (table) => {
		if (isEdited) {
			dispatch(
				setBIDashboardData({
					...openRelatedLeave,
					nextEditTableName: table.name,
				})
			);
		} else {
			setEdited(false);
			const capTableName = { ...((table.name === tableName[0].name || table.name === tableName[1].name) && { capTableName: table.name }) };
			dispatch(setBIDashboardData({ editTableName: table.name, nextEditTableName: "", ...capTableName }));
		}
	};

	return (
		<CommonSlideDialog
			open={openEdit}
			onClose={handleClose}
			maxWidth="xl"
			contentStyle={{ p: { xs: 3, md: 5 }, p: 0, flex: 1, display: "flex" }}
			fullWidth
			PaperProps={{
				sx: {
					position: "fixed",
					bottom: 0,
					right: 0,
					top: 0,
					m: 0,
					borderRadius: "24px 0px 0px 24px",
					boxShadow: "0px 10px 25px 0px rgba(0, 0, 0, 0.05)",
					minHeight: 1,
					width: "100%",
				},
			}}
		>
			<Grid container sx={{ height: { md: "100%" } }}>
				<Grid item xs={12} sm={12} md={3}>
					<Box sx={{ p: 5, pr: { md: 1 } }}>
						<Box display="flex" justifyContent="space-between" alignItems={"center"} gap={1}>
							<Box display="flex" alignItems={"center"} flexWrap={"wrap"} gap={1}>
								<Typography variant="Text/md/Semibold">Update Metrics</Typography>
								<Chip
									sx={{
										bgcolor: (theme) => theme.palette.secondary.white,
										border: "1px solid",
										borderColor: (theme) => theme.palette.sos.gray300,
									}}
									label={
										<Typography variant="Text/xs/Regular" color="sos.gray700">
											{moment().format("MMMM YYYY")}
										</Typography>
									}
								/>
							</Box>
							{isSmallScreen && (
								<IconButton
									aria-label="close"
									onClick={() => {
										dispatch(setBIDashboardData({ openEdit: false }));
									}}
									sx={{ color: (theme) => theme.palette.secondary.dark }}
								>
									<CloseIcon sx={{ color: (theme) => theme.palette.secondary.black }} />
								</IconButton>
							)}
						</Box>
						<Grid container spacing={2} mt={4}>
							{tableName.map((table) => (
								<Grid item xs={12} key={table.name}>
									<Box
										sx={{ cursor: "pointer" }}
										onClick={() => {
											showTable(table);
										}}
									>
										<Typography key={table.name} variant="Text/sm/Regular" sx={{ color: (theme) => theme.palette.sos.gray500 }}>
											{table.name}
										</Typography>
									</Box>
								</Grid>
							))}
						</Grid>
					</Box>
				</Grid>
				<Grid item xs={12} sm={12} md={9} display={"flex"} flexDirection={"column"} sx={{ backgroundColor: (theme) => theme.palette.sos.gray100 }}>
					<Box sx={{ p: 5, flex: 1, display: "flex", flexDirection: "column", rowGap: 3 }}>
						{openEdit && (
							<Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
								{(editTableName === "Ownership & Fully Diluted Shares" || !editTableName) && (
									<CapGrid
										key={"Ownership & Fully Diluted Shares"}
										title={"Ownership & Fully Diluted Shares"}
										gridData={capData}
										readOnly={false}
										usedCompanyId={usedCompanyId}
										isEdited={isEdited}
										setEdited={setEdited}
									/>
								)}
								{editTableName === "Amount Raised by Type" && (
									<CapGrid
										key={"Amount Raised by Type"}
										title={"Amount Raised by Type"}
										gridData={capData}
										readOnly={false}
										usedCompanyId={usedCompanyId}
										isEdited={isEdited}
										setEdited={setEdited}
									/>
								)}
								{editTableName === "Customers" && (
									<CustomersDataGrid
										key={"Customers"}
										gridData={customers?.data ? customers?.data : []}
										readOnly={false}
										usedCompanyId={usedCompanyId}
										isEdited={isEdited}
										setEdited={setEdited}
									/>
								)}
								{editTableName === "ARR" && (
									<ArrDataGrid
										key={"ARR"}
										gridData={annualRecurringRevenue?.data ? annualRecurringRevenue?.data : []}
										readOnly={false}
										usedCompanyId={usedCompanyId}
										isEdited={isEdited}
										setEdited={setEdited}
									/>
								)}
								{editTableName === "Net New ARR" && (
									<NetArrDataGrid
										key={"Net New ARR"}
										gridData={netNewArr?.data ? netNewArr?.data : []}
										readOnly={false}
										usedCompanyId={usedCompanyId}
										isEdited={isEdited}
										setEdited={setEdited}
									/>
								)}
								{editTableName === "MRR" && (
									<MrrDataGrid
										key={"MRR"}
										gridData={monthlyRecurringRevenue?.data ? monthlyRecurringRevenue?.data : []}
										readOnly={false}
										usedCompanyId={usedCompanyId}
										isEdited={isEdited}
										setEdited={setEdited}
									/>
								)}
								{editTableName === "CAC Payback Period" && (
									<PaybackDataGrid
										key={"CAC Payback Period"}
										gridData={cacPayback?.data ? cacPayback?.data : []}
										readOnly={false}
										usedCompanyId={usedCompanyId}
										isEdited={isEdited}
										setEdited={setEdited}
									/>
								)}
								{editTableName === "Burn & Runway" && (
									<BurnRunDataGrid
										key={"Burn & Runway"}
										gridData={burnAndRunway?.data ? burnAndRunway?.data : []}
										readOnly={false}
										usedCompanyId={usedCompanyId}
										isEdited={isEdited}
										setEdited={setEdited}
									/>
								)}
								{editTableName === "Revenue" && (
									<RevenueDataGrid
										key={"Revenue"}
										gridData={revenue?.data ? revenue?.data : []}
										readOnly={false}
										usedCompanyId={usedCompanyId}
										isEdited={isEdited}
										setEdited={setEdited}
									/>
								)}
								{editTableName === "ACV" && (
									<AcvDataGrid
										key={"ACV"}
										gridData={acv?.data ? acv?.data : []}
										readOnly={false}
										usedCompanyId={usedCompanyId}
										isEdited={isEdited}
										setEdited={setEdited}
									/>
								)}
								{editTableName === "S&M Expense vs New Sales" && (
									<SmExpenseVsNewSalesDataGrid
										key={"S&M Expense vs New Sales"}
										gridData={smExpense?.data ? smExpense?.data : []}
										readOnly={false}
										usedCompanyId={usedCompanyId}
										isEdited={isEdited}
										setEdited={setEdited}
									/>
								)}
								{editTableName === "Burn Multiple" && (
									<BurnMultipleGrid
										key={"Burn Multiple"}
										gridData={burnMultiple?.data ? burnMultiple?.data : []}
										readOnly={false}
										usedCompanyId={usedCompanyId}
										isEdited={isEdited}
										setEdited={setEdited}
									/>
								)}
								{editTableName === "Magic Number" && (
									<MagicNumberGrid
										key={"Magic Number"}
										gridData={magicNumber?.data ? magicNumber?.data : []}
										readOnly={false}
										usedCompanyId={usedCompanyId}
										isEdited={isEdited}
										setEdited={setEdited}
									/>
								)}
							</Box>
						)}
					</Box>
				</Grid>
			</Grid>
		</CommonSlideDialog>
	);
}

export default UpdateMatrics;

const tableName = [
	{ name: "Ownership & Fully Diluted Shares", Component: CapGrid, mixPanelEvent: "Grid Ownership Viewed" },
	{ name: "Amount Raised by Type", Component: CapGrid, mixPanelEvent: "Grid Amount Raised Viewed" },
	{ name: "Customers", component: CustomersDataGrid, mixPanelEvent: "Grid Customers Viewed" },
	{ name: "ARR", component: ArrDataGrid, mixPanelEvent: "Grid ARR Viewed" },
	{ name: "Net New ARR", component: NetArrDataGrid, mixPanelEvent: "Grid New ARR Viewed" },
	{ name: "MRR", component: MrrDataGrid, mixPanelEvent: "Grid MRR Viewed" },
	{ name: "CAC Payback Period", component: PaybackDataGrid, mixPanelEvent: "Grid CAC Payback Viewed" },
	{ name: "Burn & Runway", component: BurnRunDataGrid, mixPanelEvent: "Grid Burn Viewed" },
	{ name: "Revenue", component: RevenueDataGrid, mixPanelEvent: "Grid Revenue Viewed" },
	{ name: "ACV", component: AcvDataGrid, mixPanelEvent: "Grid ACV Viewed" },
	{ name: "S&M Expense vs New Sales", component: SmExpenseVsNewSalesDataGrid, mixPanelEvent: "Grid SM Expense Viewed" },
	{ name: "Burn Multiple", component: BurnMultipleGrid, mixPanelEvent: "Grid Burn Multiple Viewed" },
	{ name: "Magic Number", component: MagicNumberGrid, mixPanelEvent: "Grid Magic Number Viewed" },
];
