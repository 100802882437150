import { Circle, Delete, Event, Forum, GroupAdd, Notifications } from "@mui/icons-material";
import { Avatar, Box, Button, IconButton, Tooltip, Typography } from "@mui/material";
import TimeAgo from "javascript-time-ago";
import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { DeleteNotifications, HandleNotificationNavigation, MarkReadNotifications } from "../../utils/notificationControl";
import SwipeToRevealActions from "../SwipeActions";
import { TRASH_ICON_V3 } from "../../constants";

function NotificationItem({ notification, swipeable, clickCallback }) {
	const history = useHistory();
	const notificationItemRef = useRef();
	const [swiping, setswipe] = useState(false);

	function getNotificationType() {
		const type = notification?.notificationPayload?.type;
		return type ? type : "";
	}
	function getNotificationTime() {
		const dateCreated = notification?.createdOn;
		return dateCreated ? new TimeAgo("en-US").format(new Date(dateCreated), "twitter-now") : "";
	}
	function getNotificationTitle() {
		const title = notification?.notificationPayload?.title;
		return title ? title : "Notification";
	}
	function getNotificationBody() {
		const body = notification?.notificationPayload?.message;
		return body ? body : "";
	}
	function getNotificationRead() {
		const read = notification?.read;
		return read ? true : false;
	}
	function getNotificationAvatar() {
		const type = getNotificationType();
		switch (type) {
			case "connection": {
				return (
					<Avatar>
						<GroupAdd />
					</Avatar>
				);
			}
			case "meeting": {
				return (
					<Avatar>
						<Event />
					</Avatar>
				);
			}
			case "event": {
				return (
					<Avatar>
						<Event />
					</Avatar>
				);
			}
			case "conversation": {
				return (
					<Avatar>
						<Forum />
					</Avatar>
				);
			}
			default: {
				return (
					<Avatar>
						<Notifications />
					</Avatar>
				);
			}
		}
	}

	const markRead = () => {
		MarkReadNotifications([{ NOTIFICATION_ID: notification.NOTIFICATION_ID, read: !notification.read }]);
	};

	const handleDelete = async () => {
		if (notificationItemRef?.current) {
			const parentElement = notificationItemRef?.current?.parentNode;
			if (parentElement) {
				parentElement.classList.remove("open-appear-done", "open-enter-done");
			}
			await new Promise((resolve) => setTimeout(resolve, 500));
			DeleteNotifications([notification.NOTIFICATION_ID]);
		} else {
			DeleteNotifications([notification.NOTIFICATION_ID]);
		}
	};

	const handleClick = () => {
		if (swiping) return;

		if (clickCallback) {
			clickCallback();
		}

		/* 
			If the notification is not yet marked "read" 
			then set read to true when clicked. Otherwise
			leave the read status alone.
		*/
		if (!notification?.read) {
			markRead(notification);
		}
		let link = HandleNotificationNavigation(notification);
		// If something was returned, this means the link was determined to be internal.
		// Else, the link was determined to be external and opened in a new tab.
		if (link) {
			history.push(link);
		}
	};

	const NotificationElement = () => (
		<Box
			ref={notificationItemRef}
			sx={{
				py: 4,
				display: "flex",
				alignItems: "center",
				cursor: "pointer",
			}}
			onClick={() => {
				handleClick();
			}}
		>
			<Box
				sx={{
					display: "flex",
					flex: 1,
					flexDirection: "row",
					alignItems: "center",
					gap: 1,
				}}
			>
				{!getNotificationRead() && (
					<Box
						sx={{
							display: "flex",
							padding: "4px 12px 4px 10px",
							alignItems: "center",
							gap: "6px",
							borderRadius: "16px",
							background: "var(--success-50, #ECFDF3)",
						}}
					>
						<Typography
							variant="Text/xs/Medium"
							sx={{
								color: "var(--success-700, #027A48)",
							}}
						>
							<svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
								<circle cx="4" cy="4" r="3" fill="#00CE7E" />
							</svg>{" "}
							New
						</Typography>
					</Box>
				)}

				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						flex: 1,
						userSelect: "none",
					}}
				>
					<Typography
						variant="Text/sm/Regular"
						sx={{
							color: !getNotificationRead() ? "initial" : "var(--gray-500, #667085)",
						}}
					>
						{getNotificationBody()}
					</Typography>
				</Box>
			</Box>
			{/* Do not provide read action nor delete action to system-messages */}
			{notification?.type !== "system-message" && (
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						justifySelf: "flex-end",
						gap: 2,
					}}
				>
					{!swipeable && (
						<Button
							iconButton
							small
							variant="DS1"
							color="gray"
							onClick={(e) => {
								e.stopPropagation();
								handleDelete();
							}}
						>
							<Box component={"img"} sx={{ height: "24px", width: "24px" }} src={TRASH_ICON_V3} />
						</Button>
					)}
				</Box>
			)}
		</Box>
	);

	return <NotificationElement />;
}

export default NotificationItem;
