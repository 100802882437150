import React, { useState } from "react";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import ClearIcon from "@mui/icons-material/Clear";
import { REFERENCE_ICON } from "../../../constants";

export default function AutoCompleteCustomRef({
  options,
  label,
  placeholder,
  inputValue,
  handleInputChange,
  inputKey,
  InputLabelProps,
  errorMessage,
  inputValid,
}) {
  const [error, setError] = useState(null);
  const processSelection = value => {
    if (!error) {
      handleInputChange({ target: { value: value } }, inputKey);
    } else {
      setError(null);
    }
  };

  return (
    <Autocomplete
      disableClearable={true}
      multiple
      sx={{ mt: 2 }}
      renderInput={params => (
        <TextField
          {...params}
          error={!!error}
          helperText={error}
          InputLabelProps={InputLabelProps}
          label={label}
          placeholder={!inputValue?.length && placeholder}
          onChange={e => {
            if (e.target.value && !inputValid(inputKey, e.target.value)) {
              setError(errorMessage);
            } else {
              setError(null);
            }
          }}
        />
      )}
      onChange={(event, value) => {
        processSelection(value);
      }}
      value={inputValue}
      options={options}
      getOptionLabel={option => option.entityName}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
          {...props}
        >
          <img loading="lazy" width="20" src={REFERENCE_ICON} alt="" />
          {option.entityName}
        </Box>
      )}
      renderTags={(inputValue, getTagProps) =>
        inputValue.map((option, index) => (
          <>
            {console.log(option)}
            <Chip
              label={option.entityName}
              avatar={<img src={REFERENCE_ICON} />}
              {...getTagProps({ index })}
              variant="outlined"
              sx={theme => ({
                borderRadius: theme.shape.standard_small.borderRadius,
              })}
              deleteIcon={<ClearIcon />}
            />
          </>
        ))
      }
    />
  );
}
