import {
  Box,
  TextField,
  Typography,
  FormControl,
  Autocomplete,
  CardContent,
  ClickAwayListener,
  Dialog,
  DialogContent,
  Button,
  DialogTitle,
  IconButton,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { cardTheme, OnboardingCard } from "./styles";
import PropTypes from "prop-types";
import { ThemeProvider } from "@mui/material/styles";
import messages from "../../constants/messages";
import CloseIcon from "@mui/icons-material/Close";

const CardComp = ({
  iconComponent,
  cardText,
  selected,
  cardSubText,
  isInput,
  onSelect,
  index,
  suggestions,
  inputValue,
  maxInputLength,
  type,
  value,
  setValue,
  disabled = false,
  padding,
  updatedText,
  updatedIcon,
  cardStyle,
  isOtherEnabled,
  inputPopupTitle,
  inputPlaceHolder,
  updatedTextColor = "",
  otherText,
  hoverText,
}) => {
  const [error, setError] = useState("");

  const [inputPopup, setInputPopup] = useState(false);

  const [otherValue, setOtherValue] = useState("");

  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    if (otherText) {
      setOtherValue(otherText);
    }
  }, [otherText]);

  useEffect(() => {
    if (inputPopup) {
      setOtherValue("");
    }
  }, [inputPopup]);

  const closeInputPopup = () => {
    setInputPopup(false);
  };

  const handleChange = event => {
    var value = event.target.value;
    if (typeof value === "number") {
      value = event.target.innerText;
      if (type === "PRODUCT_SERVICE") {
        setValue(value);
      }

      onSelect(index, value);
    } else {
      if (!value) {
        if (type === "PRODUCT_SERVICE") {
          setValue(value);
        }
        setError(null);
        onSelect(index, null);
      } else {
        if (type === "PRODUCT_SERVICE") {
          setValue(value);
        }
        if (
          value.length > 0 &&
          (value.length < 2 || value.length > maxInputLength)
        ) {
          setError(
            messages.AUTOCOMPLETE_INPUT_INVALID.replace(
              "{{maxLength}}",
              maxInputLength
            )
          );
          onSelect(index, null);
        } else {
          setError(null);
          onSelect(index, value);
        }
      }
    }
  };

  return (
    <ThemeProvider theme={cardTheme}>
      <OnboardingCard
        alignment={isInput ? "block" : "centered"}
        color="primary"
        variant="outlined"
        selected={selected}
        isUpdated={updatedText !== undefined}
        onClick={() => {
          if (!disabled) {
            if (!isInput) {
              if (isOtherEnabled) {
                if (selected) {
                  onSelect(index, null);
                } else {
                  setInputPopup(true);
                }
              } else {
                onSelect(index, !selected ? cardText || updatedText : null);
              }

              if (type === "PRODUCT_SERVICE") {
                setValue("");
              }
            }
          }
        }}
        sx={{ p: padding, ...cardStyle, cursor: disabled ? "auto" : "pointer" }}
        onMouseEnter={() => hoverText && setHovered(true)}
        onMouseLeave={() => hoverText && setHovered(false)}
      >
        <CardContent>
          {!isInput ? (
            <>
              {iconComponent && cardText && (
                <Box>
                  {iconComponent}
                  <Typography
                    align="center"
                    color="primary"
                    variant="h4"
                    sx={{ mt: 2 }}
                  >
                    {cardText}
                  </Typography>
                  {cardSubText && cardSubText.length > 0 && (
                    <Typography align="center" color="GrayText" sx={{ mt: 2 }}>
                      {cardSubText}
                    </Typography>
                  )}
                </Box>
              )}

              {!hovered ? (
                <Box sx={{ my: 4 }}>
                  {updatedIcon && updatedIcon}
                  {updatedIcon && <br />}
                  <Typography
                    variant="body_large"
                    color={updatedTextColor ? updatedTextColor : ""}
                  >
                    {updatedText && updatedText}
                  </Typography>

                  {isOtherEnabled && otherValue && selected && (
                    <Box>
                      <Typography>( {otherValue} ) </Typography>
                    </Box>
                  )}
                </Box>
              ) : hoverText && hovered ? (
                <Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box>{updatedIcon && updatedIcon}</Box>
                    <Box>
                      <Typography
                        variant="body_large"
                        color={updatedTextColor ? updatedTextColor : ""}
                        sx={{ ml: 1 }}
                      >
                        {updatedText && updatedText}
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={{ mt: 1 }} textAlign="left">
                    <Typography
                      variant="body_small"
                      color={updatedTextColor ? updatedTextColor : ""}
                    >
                      {hoverText}
                    </Typography>
                  </Box>
                </Box>
              ) : (
                ""
              )}
            </>
          ) : (
            <>
              <Typography
                align="center"
                color="primary"
                variant="h4"
                sx={{ mt: 3 }}
              >
                {cardText}
              </Typography>

              <FormControl sx={{ mt: 3, mb: 2 }} fullWidth>
                <Autocomplete
                  freeSolo
                  disableClearable
                  defaultValue={inputValue}
                  options={suggestions?.map(option => option)}
                  renderInput={params => (
                    <>
                      <TextField
                        {...params}
                        spellCheck="true"
                        inputProps={{
                          ...params.inputProps,
                          value:
                            type === "PRODUCT_SERVICE"
                              ? value
                              : params.inputProps.value,
                        }}
                        sx={theme => ({
                          backgroundColor: "white",
                          borderRadius: theme.shape.standard3.borderRadius,
                        })}
                        placeholder="Type here"
                        onChange={handleChange}
                      />
                      {error ? (
                        <Typography
                          color={"error"}
                          sx={{
                            fontSize: "6px",
                            maxWidth: "fit-content",
                            textAlign: "left",
                          }}
                        >
                          {error}
                        </Typography>
                      ) : null}
                    </>
                  )}
                  onChange={handleChange}
                />
              </FormControl>
            </>
          )}
        </CardContent>
      </OnboardingCard>

      <Dialog
        open={inputPopup}
        //onClose={closeInputPopup}
        fullWidth
        PaperProps={{
          sx: theme => ({
            borderRadius: theme.shape.standard2.borderRadius,
            [theme.breakpoints.up("md")]: {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-25%, -65%)",
            },
          }),
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            py: 0,
            px: 3,
            mt: 1,
          }}
        >
          <IconButton aria-label="close" onClick={closeInputPopup} size="sm">
            <CloseIcon fontSize="small" />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ mx: 2 }}>
            <Box sx={{ mt: 1, mb: 4 }} textAlign="center">
              <Typography variant="title_large">{inputPopupTitle}</Typography>
            </Box>

            <TextField
              label={inputPlaceHolder}
              value={otherValue}
              fullWidth
              onChange={e => setOtherValue(e.target.value)}
              required
              helperText={!otherValue && "*required"}
            />

            <Box sx={{ mt: 4 }} textAlign="center">
              <Button
                disabled={!otherValue}
                onClick={() => {
                  onSelect(index, !selected ? otherValue : null);
                  closeInputPopup();
                }}
              >
                Continue
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </ThemeProvider>
  );
};

{
  CardComp.propTypes = {
    cardText: PropTypes.string,
    cardSubText: PropTypes.string,
    iconComponent: PropTypes.func,
    selected: PropTypes.bool,
    isInput: PropTypes.bool,
    onSelect: PropTypes.func,
    index: PropTypes.number,
  };

  CardComp.defaultProps = {
    cardText: "",
    cardSubText: "",
    iconComponent: null,
    selected: false,
    isInput: false,
    onSelect: null,
    index: 0,
  };
}

export default CardComp;
