import * as actionTypes from "../../actions/actionTypes";
import { updateObject } from "../../../utils/updateObject";

const initialState = {
	loading: false,
	openStartupProfileObj: {},
	startupProfileEditOpen: false,
	uploadImageSlidingModalOpen: false,
	openUploadImageModalData: {},
	uploadVideoSlidingModalOpen: false,
	openUploadVideoModalData: {},
	startupCardObj: {},
	uploadFileSlidingModalOpen: false,
	openUploadFileModalData: {},
	teamCount: 0,
	discardSlidingModalOpen: false,
	openDiscardModalData: {},
	industryDetailsOpen: false,
};

const startupProfile = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SET_STARTUP_PROFILE_DATA: {
			return action.payload === null ? initialState : updateObject(state, action.payload);
		}
		default:
			return state;
	}
};
export default startupProfile;
