import styled from "@emotion/styled";
import { Box } from "@mui/material";
import theme from "../../constants/Theme";


export const CompletedWrapper = styled(Box)({
  display: "flex",
  width: "max-content",
  gap: "8px",
  height: "24px",
  background: "rgba(0, 206, 125, 0.15)",
  border: `1px solid ${theme.palette.text.readOnlyGreen}`,
  color: theme.palette.text.readOnlyGreen,
  boxSizing: "border-box",
  backdropFilter: "blur(8px)",
  borderRadius: "100px",
  alignItems: "center",
});

export const PointsWrapper = styled.div(({ theme }) => {
  return {
    color: theme.palette.secondary.black,
    position: "relative",
    padding: theme.spacing(1),
    display: " flex",
    textAlign: "center",
    justifyContent: "center",

    "&::before": {
      content: '""',
      position: "absolute",
      inset: "0",
      borderRadius: "50px",
      padding: "2px",
      background: `linear-gradient(230.14deg, #00CE7D 23.96%, #478FC8 42.3%, #7B61FF 69.28%, #F32836 83.3%)`,
      "-webkit-mask": `linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)`,
      "-webkit-mask-composite": "xor",
      maskComposite: "exclude",
      pointerEvents: "none",
    },
  };
});