import services from "../../../services";

export const getUserPublicDataApiCall = (uuid) => {
	return services
		.getUserPublicDataApi(uuid)
		.then((result) => {
			return result;
		})
		.catch((error) => {
			return error;
		});
};

export const getCompanyPublicDataApiCall = (uuid) => {
	return services
		.getCompanyPublicDataApi(uuid)
		.then((result) => {
			return result;
		})
		.catch((error) => {
			return error;
		});
};
