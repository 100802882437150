import { Alert, Box, Button, Skeleton, Typography } from "@mui/material";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";

import { getCurrentDate, getFormattedDate, getCharDay } from "../../utils/date";

export default function TimePicker({
	selectedDate,
	handleTimesUpdate,
	mobileUI,
	buttonsPerRow,
	timeButtonVariant,
	availableTimes = [],
	disableTimesAsPerAvailability = false,
	timeStampsLoading = false,
}) {
	const [selectedTime, setSelectedTime] = useState(null);

	useEffect(() => {
		availableTimes?.forEach((time) => {
			time.isSelected = false;
		});
	}, []);

	const handleTimeUpdate = (item) => {
		availableTimes.forEach((time) => {
			time.isSelected = time.startTime === item?.startTime;
		});

		setSelectedTime(item);
		handleTimesUpdate(getFormattedDate(selectedDate, "YYYY-MM-DD"), item);
	};

	const getInitialTimeStamp = (time) => {
		return new Date(`1970-01-01T${time}:00`).getTime();
	};

	const isValidAvailableTime = (value) => {
		if (disableTimesAsPerAvailability) {
			if (availableTimes.length) {
				for (const availablity of availableTimes) {
					if (
						getInitialTimeStamp(value) >= getInitialTimeStamp(availablity.startTime) &&
						getInitialTimeStamp(value) < getInitialTimeStamp(availablity.endTime)
					) {
						return true;
					}
				}
			}
			return false;
		} else {
			return true;
		}
	};

	const validTimesExist =
		availableTimes.length > 0 &&
		availableTimes.some((item) => {
			const timeToDisplay = moment(item.startTime?.split(" ")?.[1], "HH:mm:ss");
			const currentTime = moment();
			const currentDate = moment().format("YYYY-MM-DD");
			const testSelectedDate = moment(selectedDate).format("YYYY-MM-DD");

			return testSelectedDate !== currentDate || currentTime.isBefore(timeToDisplay);
		});

	if (!validTimesExist) {
		return (
			<Box sx={{ display: "flex", gap: 2, justifyContent: "space-between", alignItems: "flex-start", flexWrap: "wrap" }}>
				<Alert severity="info">Availability not found!</Alert>
			</Box>
		);
	}

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				gap: 3,
			}}
		>
			<Box textAlign={"left"}>
				<Typography variant="Text/md/Semibold">
					{selectedDate && mobileUI && getCharDay(selectedDate)} {selectedDate && getCurrentDate(selectedDate)}
				</Typography>
			</Box>
			{timeStampsLoading ? (
				<Fragment>
					{Array.from({ length: 3 }).map((_, index) => (
						<Box key={index} variant={timeButtonVariant} display="flex" justifyContent="center">
							<Skeleton animation="wave" height="60px" width="300px" sx={{ borderRadius: "15px" }} />
						</Box>
					))}
				</Fragment>
			) : availableTimes?.length > 0 ? (
				<Box
					className="SOSShowVerticalScroll"
					display={"grid"}
					gridTemplateColumns={"repeat(12, 1fr)"}
					gridAutoRows={"1fr"}
					gap={3}
					sx={{ maxHeight: "300px", overflow: "auto" }}
				>
					{availableTimes?.map((item, index) => {
						const timeToDisplay = moment(item.startTime?.split(" ")?.[1], "HH:mm:ss");
						const currentTime = moment();
						const currentDate = moment().format("YYYY-MM-DD");
						const testSelectedDate = moment(selectedDate).format("YYYY-MM-DD");

						if (testSelectedDate === currentDate && currentTime.isAfter(timeToDisplay)) {
							return <Fragment></Fragment>;
						} else {
							return (
								<Button
									key={index}
									variant={timeButtonVariant}
									sx={{
										transition: "all .2s",
										display: isValidAvailableTime(item.value) ? "block" : "none",
										gridColumn: {
											lg: mobileUI ? "span 12" : "span " + 12 / buttonsPerRow,
											md: mobileUI ? "span 12" : "span " + 12 / buttonsPerRow,
											sm: mobileUI ? "span 12" : "span " + 12 / buttonsPerRow,
											xs: mobileUI ? "span 12" : "span " + 12 / buttonsPerRow,
											borderRadius: "100px",
											padding: "12px 20px",
											color: item?.isSelected ? "white !important" : "initial",
											backgroundColor: item?.isSelected ? "#7B61FF" : "#F2F4F7",
											"&:hover": {
												backgroundColor: "#5644B2",
												color: "white",
											},
										},
										"&": (theme) => ({
											...theme.typography["Text/sm/Semibold"],
										}),
										height: "auto",
									}}
									onClick={() => {
										handleTimeUpdate(item);
									}}
									// disabled={!isValidAvailableTime(item.value)}
								>
									{timeToDisplay.format("hh:mm A") ? timeToDisplay.format("hh:mm A") : ""}
								</Button>
							);
						}
					})}
				</Box>
			) : (
				<Box
					sx={{
						display: "flex",
						gap: 2,
						justifyContent: "space-between",
						alignItems: "flex-start",
						flexWrap: "wrap",
					}}
				>
					<Alert severity="info">Availability not found!</Alert>
				</Box>
			)}
		</Box>
	);
}
