import React, { useContext, useEffect, useState } from "react";
import { Box, CircularProgress, Slider, Typography } from "@mui/material";
import { PowerUPContext } from "../../PowerUPController";
import { getAnswersByStepUuid } from "../../functions";
import TamSamSomGraph from "../graph/TamSamSomGraph";
import TamSamSomMUIGraph from "../graph/TamSamSomMUIGraph";

function TextOnlyReadOnly({ INITIAL_VALUE_STATE, TEXT_MAP, getPDFDownload }) {
	const { powerupDetails, getResponses, setPdfContent, currentVersion } = useContext(PowerUPContext);
	const [textMap, settextMap] = useState(null);
	const [inputValues, setInputValues] = useState(INITIAL_VALUE_STATE);
	const [loadingValues, setloadingValues] = useState(false);

	useEffect(() => {
		updateInputValues();
	}, []);

	useEffect(() => {
		settextMap(TEXT_MAP);
	}, [TEXT_MAP]);

	const updateInputValues = async () => {
		setloadingValues(true);
		const updatedResponses = await getResponses();

		const stepResponse = getAnswersByStepUuid(
			updatedResponses,
			currentVersion?.module?.moduleUuid === "md-tam" ? "md-tam-sam-som_do-it" : `${currentVersion?.module?.moduleUuid}_do-it`
		);
		const updatedInputValues = inputValues?.map((input) => {
			const apiResponseEntry = stepResponse?.find((apiEntry) => apiEntry?.id === input?.id);
			if (apiResponseEntry) {
				return { ...input, value: apiResponseEntry.value, error: apiResponseEntry.value?.length > 500 ? "Cannot exceed 500 characters." : "" };
			}
			return input;
		});

		setInputValues(updatedInputValues);
		setloadingValues(false);
		getPDFDownload(updatedInputValues, powerupDetails?.powerUpName, (resp) => setPdfContent(resp));
	};

	return loadingValues ? (
		<CircularProgress />
	) : (
		<Box sx={{ display: "flex", flexDirection: "column", rowGap: "1.5rem" }}>
			{textMap?.map((item, index) => {
				if (
					item?.id !== `${currentVersion?.module?.moduleUuid}-creativeScale` &&
					item?.id !== `${currentVersion?.module?.moduleUuid}-finalOutput` &&
					item?.id !== `md-tam-tableOutput` &&
					item?.id !== `md-tam-graphOutput`
				)
					return (
						<Box key={index}>
							<Typography variant="Text/sm/Semibold" component={"p"}>
								{textMap?.find((input) => input.id === item?.id)?.title}
							</Typography>
							<Typography variant="Text/sm/Regular" component={"p"}>
								{inputValues?.find((input) => input.id === item?.id)?.value}
							</Typography>
						</Box>
					);
			})}
			<Box>
				<Typography variant="Text/sm/Semibold" component={"p"}>
					{textMap?.find((input) => input.id === `${currentVersion?.module?.moduleUuid}-creativeScale`)?.title}
				</Typography>
				<Box sx={{ maxWidth: "300px", pt: "2rem" }}>
					<Slider
						value={inputValues?.find((input) => input.id === `${currentVersion?.module?.moduleUuid}-creativeScale`)?.value}
						step={1}
						min={1}
						max={5}
						valueLabelDisplay="on"
					/>
				</Box>
			</Box>
			<Box>
				<Typography variant="Text/sm/Semibold" component={"p"} sx={{ mb: "1.5rem" }}>
					{textMap?.find((input) => input.id === `md-tam-graphOutput`)?.title}
				</Typography>
				<Typography variant="Text/sm/Regular" component={"p"} sx={{ whiteSpace: "pre-line" }}>
					{inputValues.find((input) => input.id === "md-tam-graphOutput")?.value.tam &&
						inputValues.find((input) => input.id === "md-tam-graphOutput")?.value.sam &&
						inputValues.find((input) => input.id === "md-tam-graphOutput")?.value.som && (
							<Box>
								<TamSamSomMUIGraph TamSamSomData={inputValues?.find((input) => input.id === "md-tam-graphOutput")?.value} />
							</Box>
						)}
				</Typography>
			</Box>
			<Box>
				<Typography variant="Text/sm/Semibold" component={"p"} sx={{ mb: "1.5rem" }}>
					{textMap?.find((input) => input.id === `${currentVersion?.module?.moduleUuid}-finalOutput`)?.title}
				</Typography>
				<Typography variant="Text/sm/Regular" component={"p"} sx={{ whiteSpace: "pre-line" }}>
					{inputValues?.find((input) => input.id === `${currentVersion?.module?.moduleUuid}-finalOutput`)?.value}
				</Typography>
			</Box>
			<Box>
				<Typography variant="Text/sm/Semibold" component={"p"} sx={{ mb: "1.5rem" }}>
					{textMap?.find((input) => input.id === `md-tam-tableOutput`)?.title}
				</Typography>
				<Typography variant="Text/sm/Regular" component={"p"} sx={{ whiteSpace: "pre-line" }}>
					<span
						dangerouslySetInnerHTML={{
							__html: inputValues?.find((input) => input.id === `md-tam-tableOutput`)?.value,
						}}
					/>
				</Typography>
			</Box>
		</Box>
	);
}

export default TextOnlyReadOnly;
