import React, { useRef, useState, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Box, Typography, Avatar, Grid, IconButton, Menu, MenuItem, Link } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { EDIT_V1_ICON } from "../../../constants";
import { DELETE_ICON_V1 } from "../../../constants";
import FeedActionComponent from "../FeedActions";
import UploadedPostFileDisplayComp from "../FileUploadComp";
import FeedCommentsComponent from "../FeedCommentsSection";
import { setFeedData } from "../../../modules/actions";
import UpdatedMetaLink from "../../UpdatedMetaLink";

const FeedPostCardComponent = ({ item }) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const [anchorEl, setAnchorEl] = React.useState(null);
	const PostOperationIsOpen = useSelector((state) => state?.feedPost?.createPostOpen);

	const isOpen = Boolean(anchorEl);
	const currentPostIdNew = useSelector((state) => state?.feedPost?.currentPostId);
	const isDtailsOpen = useSelector((state) => state?.feedPost?.detailsPopUpOpen);
	const descriptionRef = useRef();
	const [isOverflowing, setIsOverflowing] = useState(false);
	useLayoutEffect(() => {
		const contentElement = descriptionRef.current;
		if (contentElement) {
			setIsOverflowing(contentElement.scrollHeight > contentElement.clientHeight);
		}
	}, [descriptionRef, item?.description]);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const EditOrDeletePostMenu = () => {
		return (
			<Menu
				open={isOpen}
				anchorEl={anchorEl}
				onClose={handleClose}
				elevation={2}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				PaperProps={{
					style: {
						borderRadius: "24px",
						width: "120px",
						display: "flex",
						height: "100px",
					},
				}}
				sx={{
					mt: 1,
					border: "none",
				}}
			>
				<MenuItem
					sx={{ width: "120px", display: "flex", gap: 1, height: "40px" }}
					onClick={() => {
						setAnchorEl("");
						dispatch(setFeedData({ createPostOpen: true, postOperationType: "edit", currentPostObj: item }));
					}}
				>
					<Box component={"img"} sx={{ width: "14px", height: "14px" }} src={EDIT_V1_ICON} alt="edit" />
					<Typography variant="label_large">Edit</Typography>
				</MenuItem>
				<MenuItem
					sx={{ width: "120px", display: "flex", gap: 1, height: "40px" }}
					onClick={() => {
						setAnchorEl("");
						dispatch(setFeedData({ createPostOpen: true, postOperationType: "delete", currentPostObj: item }));
					}}
				>
					<Box sx={{ width: "14px", height: "14px" }} component={"img"} src={DELETE_ICON_V1} alt="edit" />
					<Typography variant="label_large">Delete</Typography>
				</MenuItem>
			</Menu>
		);
	};

	const handleRedirection = () => {
		if (item?.owner?.isCompanyUser) {
			history.push(`/account-profile/${item?.owner?.id}`);
		} else {
			history.push(`/mentor-profile/${item?.owner?.id}`);
		}
	};

	const convertLinksToHyperlinks = (htmlString) => {
		const allMatchesRegex = /<a\b[^>]*>.*?<\/a>|https?:\/\/[^\s<]+/g;

		const convertedString = htmlString?.replace(allMatchesRegex, (match) => {
			// If it's already an <a> tag, return it as is
			if (match.startsWith("<a")) return match;

			// Otherwise, wrap the URL in an <a> tag
			return `<a href="${match}" target="_blank" rel="noopener noreferrer">${match}</a>`;
		});

		return convertedString;
	};

	return (
		<Box
			sx={(theme) => ({
				borderRadius: theme.shape.standard10.borderRadius,
				height: "fit-content",
				p: 4,
				display: "flex",
				flexDirection: "column",
				gap: 3,
				backgroundColor: theme.palette.secondary.white,
				cursor: "pointer",
				// boxShadow: "0px 0px 25px 10px rgba(16, 24, 40, 0.03)",
			})}
		>
			<Grid
				container
				sx={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					position: "relative",
				}}
			>
				<Grid
					item
					sx={{
						display: "flex",
						alignItems: "center",
						maxWidth: "80%",
					}}
				>
					<Box gap={2} display="flex" alignItems="center">
						<Avatar sx={{ height: "50px", width: "50px" }} src={item?.owner?.picture} alt="avatar" onClick={handleRedirection} />
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
							}}
						>
							<Typography variant="flyout_title" onClick={handleRedirection}>
								{item?.owner?.name}
							</Typography>
							<Typography variant="Text/sm/Regular">
								<Link
									color={"#1A73E8"}
									onClick={() => {
										history.push(`/startup-profile/${item?.owner?.companyModel?.id}`);
									}}
								>
									{item?.owner?.companyModel?.companyName}
								</Link>
							</Typography>
						</Box>
					</Box>
				</Grid>
				<Grid
					item
					sx={{
						height: "50px",
					}}
					onClick={() => {
						dispatch(setFeedData({ detailsPopUpOpen: true, detailsPostObject: item, currentPostId: item?.id, getCommentsApiCall: true }));
					}}
				></Grid>
				{item?.isCreatedByUser ? (
					<Grid
						item
						sx={{
							display: "flex",
							justifyContent: "end",
							height: "50px",
							width: "10%",
							ml: "auto",
						}}
					>
						<IconButton
							onClick={handleClick}
							sx={{
								width: "40px",
								height: "40px",
								background: "#F2F4F7",
								ml: "auto",
								"&:hover": {
									background: "#F2F4F7",
								},
							}}
						>
							<MoreHorizIcon />
						</IconButton>
					</Grid>
				) : (
					<></>
				)}
			</Grid>
			<Box
				onClick={(event) => {
					if (event.target.tagName !== "A") {
						dispatch(
							setFeedData({
								detailsPopUpOpen: true,
								detailsPostObject: item,
								currentPostId: item?.id,
								getCommentsApiCall: true,
							})
						);
					}
				}}
				sx={{
					textAlign: "justify",
					ml: "66px",
				}}
			>
				<Typography variant="Text/sm/Regular">
					{" "}
					<Box
						ref={descriptionRef}
						component={"div"}
						sx={{
							display: ["-webkit-box", "-moz-box", "box"],
							WebkitBoxOrient: "vertical",
							MozBoxOrient: "vertical",
							boxOrient: "vertical",
							WebkitLineClamp: 3,
							MozLineClamp: 3,
							lineClamp: 3,
							overflow: "hidden",
							textOverflow: "ellipsis",
							textAlign: "left",
						}}
						onClick={(event) => {
							if (event.target.tagName === "A") {
								event.preventDefault();
								const url = event.target.getAttribute("href");
								window.open(url, "_blank");
							}
						}}
						className="feedHtmlContainer"
						dangerouslySetInnerHTML={{ __html: convertLinksToHyperlinks(item?.description) }}
					/>
				</Typography>

				{isOverflowing && (
					<Link
						target="_blank"
						underline="hover"
						variant="title_medium"
						sx={{ display: "block", textAlign: "right" }}
						onClick={() => {
							dispatch(
								setFeedData({
									detailsPopUpOpen: true,
									detailsPostObject: item,
									currentPostId: item?.id,
									getCommentsApiCall: true,
								})
							);
						}}
					>
						See More
					</Link>
				)}
				{!isDtailsOpen && !PostOperationIsOpen && item?.description && item?.metaData && (
					<Box sx={{ mt: 2 }}> {item?.description && <UpdatedMetaLink data={item?.description} feedObj={item} type="OTHER" />}</Box>
				)}
			</Box>
			<Box>
				<UploadedPostFileDisplayComp
					elementStyle={{ ml: "66px" }}
					item={item}
					onClick={() => {
						dispatch(setFeedData({ detailsPopUpOpen: true, detailsPostObject: item, currentPostId: item?.id, getCommentsApiCall: true }));
					}}
				/>
			</Box>

			<Grid sx={{ ml: "66px" }} container>
				<Grid item md={12} lg={9} xl={6}>
					<FeedActionComponent item={item} />
				</Grid>
				<Grid
					item
					md={12}
					lg={3}
					xl={6}
					onClick={() => {
						dispatch(setFeedData({ detailsPopUpOpen: true, detailsPostObject: item, currentPostId: item?.id, getCommentsApiCall: true }));
					}}
				></Grid>
			</Grid>
			{currentPostIdNew === item?.id && !isDtailsOpen ? (
				<FeedCommentsComponent comments={item?.topComments} postId={item?.id} type={"postCard"} detailsPostObjectData={item} />
			) : (
				<></>
			)}
			<EditOrDeletePostMenu />
		</Box>
	);
};

export default FeedPostCardComponent;
