import services from "../../../../services";

export const fundingProcessingAI = async (valueProp, companyDetails, cb) => {
	cb({ key: "fundingAI", values: { loading: true, complete: false } });

	if (!valueProp) {
		cb({ key: "fundingAI", values: { loading: false, complete: true } });
		return;
	}

	let payload = {
		messages: [
			{
				role: "system",
				content: `Given the company profile of [COMPANY_PROFILE], we're seeking insights into strategic decisions to present to potential investors. Specifically:

				- Considering our financial needs, market position, and growth goals, how much should we ask for in funding?
				- Based on current investor trends and our unique offering, what would be the most appealing funding terms for potential investors?
				- Considering the requested financial investment, what milestones should the company reach?
				- If we secure the investment, name three primary uses for the funds that would drive our company's growth.
				- As we aim for expansion, what direction should we focus on?
				- How can our product/service adapt to cater to a broader audience without losing its core value?
				- Are there any new features or solutions closely aligned with our current offerings that we should consider for future development?
				
				Respond in the following JSON format:
				
				{
					amount: "Dollar amount",
					terms: "Suggested terms for funding",
					milestones: "Suggested milestones the company should reach by using the requested investment.",
					how: [
						{
							title: "Use of Funds Title",
							description: "Brief explanation of the use"
						},
						...
					], 
					future: [
						"Strategic insights for growth and expansion",
						"Strategies for audience expansion",
						"Suggestions for future features or solutions"
					]
				}
				
								
                `,
			},
			{
				role: "user",
				content: `Company Information:
				- Value Proposition: ${valueProp}
				- Primary Offering: ${companyDetails?.productOffering ?? "N/A"}
				- Industry: ${companyDetails?.industry ?? "N/A"}
				- Company Size: ${companyDetails?.companySize ?? "N/A"}
				- Current Business Model: ${companyDetails?.businessModel ?? "N/A"}
				- Current Revenue Model: ${companyDetails?.revenueModel ?? "N/A"}
				- Current Funding Stage: ${companyDetails?.currentStage ?? "N/A"}
				- Has accepted funding from the following types: ${companyDetails?.fundingType ?? "N/A"}
				- Has currently accepted financing type: ${companyDetails?.financing ?? "N/A"}
				- Currently has revenue: ${companyDetails?.revenue ?? "N/A"}
				- Total Capital Raised to Date in USD: ${companyDetails?.capitalRaised ?? "N/A"}
				- Currently seeking the following funding round stage: ${companyDetails?.seeking ?? "N/A"}
				- Currently asking for the following amount in the next funding round in USD: ${companyDetails?.ask ?? "N/A"}
				`,
			},
		],
		maxTokens: 2000,
		model: "gpt-4",
	};

	let attempts = 0;
	while (attempts < 2) {
		try {
			let AIResp = await services.getOpenAIChatResponse(payload).catch((e) => {
				console.log(e);
			});
			cb({ key: "fundingAI", values: { loading: false, complete: true } });
			if (AIResp?.data?.data?.[0]?.text) {
				return JSON.parse(AIResp?.data?.data?.[0]?.text);
			} else if (AIResp?.data?.data?.[0]?.message?.content) {
				return JSON.parse(AIResp?.data?.data?.[0]?.message?.content);
			} else {
				attempts++;
			}
		} catch (error) {
			console.error(error);
		}
	}
	return false;
};

/*

{
	"amount": "$500,000",
	"terms": "Equity financing with a 20% share of the company in exchange for the investment. This aligns with current investor trends favoring equity investments in startups and our unique offering of a niche product with high potential for market growth.",
	"requests": "In addition to financial support, we would appreciate strategic guidance and mentorship to help our business flourish. We would also value introductions to key players in the pet products industry and retail sector, as well as expertise in marketing and scaling consumer goods startups.",
	"how": [
		{
			"title": "Production and Inventory",
			"description": "Expand production capacity and maintain an adequate inventory to meet anticipated demand."
		},
		{
			"title": "Marketing and Brand Awareness",
			"description": "Invest in marketing campaigns to build brand awareness, attract new customers, and penetrate new markets."
		},
		{
			"title": "Research and Development",
			"description": "Invest in research and development to continually improve our products, ensure their quality, and develop new designs and features."
		}
	], 
	"future": [
		"Focus on expanding into new markets, both domestic and international, to boost sales and increase brand visibility. This could include partnerships with pet stores and online retailers.",
		"Consider creating a line of pillows for other small pets, such as gerbils, mice, and guinea pigs. This would allow us to cater to a broader audience while maintaining our core value of providing high-quality, comfort-enhancing products for small pets.",
		"Consider developing other types of bedding products, such as blankets or beds, that align with our current offering of premium-grade pillows. This would allow us to offer a more comprehensive range of products to our customers."
	]
}

*/


/*

Given the company profile of [COMPANY_PROFILE], we're seeking insights into strategic decisions to present to potential investors. Specifically:

- Considering our financial needs, market position, and growth goals, how much should we ask for in funding?
- Based on current investor trends and our unique offering, what would be the most appealing funding terms for potential investors?
- In addition to financial support, what else should we request from our potential investors to help our business flourish?
- If we secure the investment, name three primary uses for the funds that would drive our company's growth.
- As we aim for expansion, what direction should we focus on?
- How can our product/service adapt to cater to a broader audience without losing its core value?
- Are there any new features or solutions closely aligned with our current offerings that we should consider for future development?

Respond in the following JSON format:

{
	amount: "Dollar amount",
	terms: "Suggested terms for funding",
	requests: "Other requests to ask investors",
	how: [
		{
			title: "Use of Funds Title",
			description: "Brief explanation of the use"
		},
		...
	], 
	future: [
		"Strategic insights for growth and expansion",
		"Strategies for audience expansion",
		"Suggestions for future features or solutions"
	]
}
*/





/*
{
valueProp: "Introducing Pillows for Hamsters, where dreams come true for our tiny furry friends. Tired of mediocre bedding options? Say goodbye to the dull and hello to a world of comfort, luxury, and whimsy. Our premium-grade pillows are exclusively designed for hamsters, providing unparalleled comfort and support. Our unique differentiator lies in our commitment to crafting pillows specifically tailored to the needs of hamsters, ensuring a sleep experience like no other. With features like extra fluffy filling and a variety of adorable designs, our pillows offer a cozy retreat for your hamster's slumbers. Our core strengths, including attention to detail and a passion for enhancing the lives of our smallest companions, drive us to create exceptional products that stand apart from the alternatives. Join us in creating a dreamy haven for hamsters, and unlock the true potential of your furry friend's sleep. Hello, hamster happiness awaits you.",

companyDetails:

Company Profile:
    "productOffering": "Consumer Goods",
    "industry": "[\"Consumer Products & Services\"]",
    "companySize": 1,
    "city": "Columbus",
    "state": "Ohio",
    "businessModel": "B2C",
    "revenueModel": "[\"Retailer Model\"]",
	"currentStage": "Pre-Seed",

Has accepted the following funding types:
	"fundingType": "[\"Personal Investor\",\"Philanthropic\"]",

Has taken on financing type:
    "financing": "Conv. Note",

Has Revenue:
	"revenue": "No",

Has Raised To Date:
 	"capitalRaised": 12000000,
    
Seeking Funding:
    "seeking": "Seed",
    "ask": 454545,
}



		FUNDING_AMOUNT: funding?.amount ?? "How much are you asking from investors?",
		FUNDING_TERMS: funding?.terms ?? "What are the terms of your funding request?",
		FUNDING_ADDITIONAL_REQUESTS: funding?.requests ?? "Is there any additional needs you may have past funding?",
		FUNDING_HOW_1: funding?.how?.[0]?.title ?? "Need 1",
		FUNDING_HOW_2: funding?.how?.[1]?.title ?? "Need 2",
		FUNDING_HOW_3: funding?.how?.[2]?.title ?? "Need 3",
		FUNDING_HOW_1_EXP: funding?.how?.[0]?.description ?? "Identify specifics on what the funding would go towards;\nie. justify the ask.",
		FUNDING_HOW_2_EXP: funding?.how?.[1]?.description ?? "Ex:\nHire 3 FT employees to increase production and delivery by x%",
		FUNDING_HOW_3_EXP: funding?.how?.[2]?.description ?? "Ex:\nContract marketing resource to enhance customer acquisition and boost SEO.",
		LOOKING_AHEAD_1: funding?.future?.[0] ?? "Insight to what's next/planned growth + direction.",
		LOOKING_AHEAD_2: funding?.future?.[1] ?? "How extensible is this product/solution?",
		LOOKING_AHEAD_3: funding?.future?.[2] ?? "Future planned features/solutions?",


Company Information:
- Value Proposition: "Introducing Pillows for Hamsters, where dreams come true for our tiny furry friends. Tired of mediocre bedding options? Say goodbye to the dull and hello to a world of comfort, luxury, and whimsy. Our premium-grade pillows are exclusively designed for hamsters, providing unparalleled comfort and support. Our unique differentiator lies in our commitment to crafting pillows specifically tailored to the needs of hamsters, ensuring a sleep experience like no other. With features like extra fluffy filling and a variety of adorable designs, our pillows offer a cozy retreat for your hamster's slumbers. Our core strengths, including attention to detail and a passion for enhancing the lives of our smallest companions, drive us to create exceptional products that stand apart from the alternatives. Join us in creating a dreamy haven for hamsters, and unlock the true potential of your furry friend's sleep. Hello, hamster happiness awaits you."
- Primary Offering: "Consumer Goods"
- Industry: "[Consumer Products & Services]"
- Company Size: 1
- Current Business Model: "B2C"
- Current Revenue Model: "[Retailer Model]"
- Current Funding Stage: "Pre-Seed"
- Has accepted funding from the following types: "[Personal Investor, Philanthropic]"
- Has currently accepted financing type: "Conv. Note"
- Currently has revenue: "No"
- Total Capital Raised to Date in USD: 12000000
- Currently seeking the following funding round stage: "Seed"
- Currently asking for the following amount in the next funding round in USD: 454545 


The responses you provide will be used in a pitch deck presented to potential investors.
Answers to these questions should be focused on this audience and should be terse as they will be used in a slide presentation format.
You need to take into consideration the details provided by the user to answer the following questions:
- How much should the company ask in funding?
- What funding terms should the company set that would be the most attractive to potential investors?
- What additional requests should the company ask of an investor?
- What are 3 ways that the company should use this money if granted from an investor?
- What would be a direction for growth and expansion?
- How could the company's product/service be extensible to capture a larger audience?
- What are some additional features/solutions the company should consider related to their current offering?

Response content should be in the 1st person as the company will use these responses in a pitch deck presentation given to potential investors.
Use terms such as I and We when referring to the company.

Respond in the following JSON format:
{
	amount: [$ amount the company should ask for],
	terms: [Funding terms that would be the most attractive to potential investors],
	requests: [Additional requests should the company ask of an investor],
	how: [
		{
			title: [A 1-3 word title to introduce the specific way the funds will be used],
			description: [A terse summary on the specific use.] // May be a list response stringified with \n newline character separating each item
		},
		...
	], 
	future: [
		"[Insight to what's next/planned growth + direction]", // List response stringified with \n newline character separating each item
		"["Product/service be extensible to capture a larger audience"]", // List response stringified with \n newline character separating each item
		"[Future planned features/solutions]" // List response stringified with \n newline character separating each item
	]
}
*/

