import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";
import ConversationView from "./ConversationView";
import { actionCreators } from "../../../../Messaging/store";
import { getTypingMessage, unexpectedErrorNotification } from "../../../../Messaging/helpers";
import { UNEXPECTED_ERROR_MESSAGE } from "../../../../Messaging/constants";
import { getSdkConversationObject } from "../../../../Messaging/conversations-objects";
import { List, Box, Typography, ListItem, Skeleton } from "@mui/material";
import { Fragment, useMemo, useState } from "react";
import { ChatBubbleOutline } from "@mui/icons-material";
import { useEffect } from "react";
import { handleMixPanel } from "../../../../../utils/mixPanelEventHandling";
import { getRoleValue } from "../../../../../utils/commonFunctions";

function getLastMessage(messages, typingData) {
	if (messages === undefined || messages === null) {
		return "Loading...";
	}
	if (typingData.length) {
		return getTypingMessage(typingData);
	}
	if (messages.length === 0) {
		return "No messages";
	}
	return messages[messages.length - 1].body || "Media message";
}

function isMyMessage(messages) {
	if (messages === undefined || messages === null || messages.length === 0) {
		return false;
	}
	return messages[messages.length - 1].author === localStorage.getItem("username") ? messages[messages.length - 1] : false;
}
async function updateCurrentConvo(setSid, convo, updateParticipants) {
	setSid(convo.sid);
	try {
		const participants = await getSdkConversationObject(convo).getParticipants();
		updateParticipants(participants, convo.sid);
	} catch {
		return Promise.reject(UNEXPECTED_ERROR_MESSAGE);
	}
}
function setUnreadMessagesCount(currentconvoSid, convoSid, unreadMessages, updateUnreadMessages) {
	if (currentconvoSid == convoSid && unreadMessages[convoSid] !== 0) {
		updateUnreadMessages(convoSid, 0);
		return 0;
	}
	if (currentconvoSid == convoSid) {
		return 0;
	}
	return unreadMessages[convoSid];
}
const ConversationsList = () => {
	const sid = useSelector((state) => state.sid);
	const conversations = useSelector((state) => state.convos);
	const messages = useSelector((state) => state.messages);
	const unreadMessages = useSelector((state) => state.unreadMessages);
	const participants = useSelector((state) => state.participants);
	const typingData = useSelector((state) => state.typingData);
	const [isLoading, setIsLoading] = useState(true);
	const authData = useSelector((state) => state?.auth);

	useEffect(() => {
		if (conversations && conversations.length > 0) {
			setIsLoading(false);
		}
	}, [conversations]);

	useEffect(() => {
		setTimeout(() => {
			setIsLoading(false);
		}, 2000);
	}, []);

	const handleMixPanelEvent = () => {
		handleMixPanel("User read a message", {
			"Reader user name": authData?.userName,
			"Reader user id": authData?.userId,
			"Reader user role": getRoleValue(authData?.userType),
		});
	};
	const dispatch = useDispatch();
	const {
		updateCurrentConversation,
		updateParticipants,
		updateUnreadMessages,
		setLastReadIndex,
		addNotifications,
		updateCreatingNewConversationState,
		resetChatState,
	} = bindActionCreators(actionCreators, dispatch);
	if (conversations === undefined || conversations === null) {
		return <div className="empty" />;
	}

	const getSkeleton = (length) => {
		return Array.from({ length: length }, (item, index) => (
			<ListItem key={index} sx={{ alignSelf: "stretch", p: "0 0 0 4px", gap: 1 }}>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						gap: 1,
						flex: 1,
					}}
				>
					<Box sx={{ width: "10px" }}></Box>
					<Box>
						<Skeleton animation="wave" variant="circular" height={40} width={40} />
					</Box>
					<Box
						sx={{
							padding: "12px 4px 12px 0px",
							flex: 1,
						}}
					>
						<Skeleton variant="rectangular" height={64} width={"100%"} />
					</Box>
				</Box>
			</ListItem>
		));
	};
	return (
		<Box sx={{ height: 1, top: 0 }}>
			{isLoading ? (
				<List>{getSkeleton(8)}</List>
			) : (
				<>
					{conversations?.length > 0 ? (
						<List
							sx={{
								"li:not(:last-child)": {
									mb: "3px",
								},
							}}
						>
							{filterConversation(conversations).map((convo) => (
								<ConversationView
									key={convo.sid}
									convoId={convo.sid}
									setSid={updateCurrentConversation}
									currentConvoSid={sid}
									lastMessage={getLastMessage(messages[convo.sid], typingData[convo.sid] ?? []) ?? ""}
									messages={messages[convo.sid]}
									typingInfo={typingData[convo.sid] ?? []}
									myMessage={isMyMessage(messages[convo.sid])}
									unreadMessagesCount={setUnreadMessagesCount(sid, convo.sid, unreadMessages, updateUnreadMessages)}
									updateUnreadMessages={updateUnreadMessages}
									participants={participants[convo.sid] ?? []}
									convo={convo}
									onClick={async () => {
										try {
											resetChatState();
											setLastReadIndex(convo.lastReadMessageIndex ?? -1);
											await updateCurrentConvo(updateCurrentConversation, convo, updateParticipants);
											//update unread messages
											updateUnreadMessages(convo.sid, 0);

											//set messages to be read
											const lastMessage = messages[convo.sid].length && messages[convo.sid][messages[convo.sid].length - 1];
											if (lastMessage && lastMessage.index !== -1) {
												handleMixPanelEvent();
												await getSdkConversationObject(convo).updateLastReadMessageIndex(lastMessage.index);
											}
										} catch {
											unexpectedErrorNotification(addNotifications);
										}
									}}
								/>
							))}
						</List>
					) : (
						<Box
							sx={{
								height: "100%",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<Box
								sx={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									flexDirection: "column",
									gap: 1,
									mt: 6,
								}}
							>
								<ChatBubbleOutline color="primary" fontSize="large" sx={{}} />
								<Typography
									variant="Text/sm/Regular"
									sx={(theme) => ({
										textAlign: "center",
										color: theme.palette.secondary.outline,
									})}
								>
									No messages
								</Typography>
								<Typography
									variant="required_text"
									sx={{
										maxWidth: "230px",
										textAlign: "center",
										color: (theme) => theme.palette.secondary.outline,
										fontFamily: '"PoppinsRegular", "Helvetica", "Arial", sans-serif',
										fontSize: "12px",
										lineHeight: "16px",
									}}
								>
									Your messages in StartupOS will show up here.
								</Typography>
							</Box>
						</Box>
					)}
				</>
			)}
		</Box>
	);
};
export default ConversationsList;

const getConversationName = (convo) => {
	if (convo?.startuposMeta?.conversationParticipants) {
		let name = "";
		convo?.startuposMeta?.conversationParticipants?.map((participant, index) => {
			name += participant?.name;
			if (index < convo?.startuposMeta?.conversationParticipants?.length - 1) {
				name += " & ";
			}
		});
		return name;
	} else if (convo?.startuposMeta?.name) {
		return convo?.startuposMeta?.name;
	} else {
		return "";
	}
};

function filterConversation(conversations) {
	let filteredConvo = [];
	filteredConvo = conversations?.filter((convo) => {
		const name = getConversationName(convo);
		const hasLastMessage = convo?.lastMessage && Object.keys(convo.lastMessage).length > 0;

		return convo?.startuposMeta && name !== "" && hasLastMessage;
	});
	return filteredConvo;
}
