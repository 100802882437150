import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { ProfileContext } from "../..";
import { StyledProfileCard } from "../../style";
import AboutTabs from "./Components/AboutTabs";
import UserAbout from "./Components/UserAbout";
import StatusButton from "../StatusButton";
import ROUTES_PATH from "../../../../constants/routes";
import SocialMediaShareButtons from "../../../../common/SocialMediaShareButtons";
import progressCompletionCalculator from "../../../../utils/progressCompletionCalculator";
import { useDispatch } from "react-redux";
import { setUserProfileData } from "../../../../modules/actions/userProfileActions";

function AboutCard({ setAboutPercentage }) {
	const history = useHistory();
	const dispatch = useDispatch();

	const { viewContext, profileData } = useContext(ProfileContext);
	const homeApiVersion = process.env.REACT_APP_HOME_URL;
	const startupShareUrl = `${homeApiVersion}/startup/${profileData?.uuid}`;
	const accountShareUrl = `${homeApiVersion}/account/${profileData?.uuid}`;
	const totalFields = viewContext?.component === "startup" ? 4 : 2;
	let completedFields = 0;
	if (viewContext && viewContext?.component === "startup") {
		if (profileData?.founderStoryVideo) {
			completedFields += 1;
		}
		if (profileData?.aboutUsVideoUrl) {
			completedFields += 1;
		}
		if (profileData?.founderStory) {
			completedFields += 1;
		}
		if (profileData?.about) {
			completedFields += 1;
		}
	}
	if (viewContext && viewContext?.component === "account") {
		if (profileData?.videoUrl) {
			completedFields += 1;
		}
		if (profileData?.about) {
			completedFields += 1;
		}
	}

	useEffect(() => {
		setAboutPercentage(progressCompletionCalculator(totalFields, completedFields));
	}, [profileData]);

	const handleRedirection = () => {
		if (viewContext?.component === "startup" || viewContext?.component === "account") {
			dispatch(
				setUserProfileData({
					userProfileEditOpen: true,
				})
			);
		}
	};

	return (
		<StyledProfileCard style={{ flex: 1 }}>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
				}}
			>
				{(viewContext?.component === "startup" || viewContext?.component === "account") && (
					<Box pb={viewContext?.component === "startup" ? "32px" : "40px"} display="flex">
						{/* {!viewContext?.id && (
							<StatusButton percentage={progressCompletionCalculator(totalFields, completedFields)} handleRedirection={handleRedirection} />
						)} */}
						<Box
							ml="auto"
							sx={{
								display: "flex",
								flexDirection: "row",
								gap: 1,
								alignItems: "center",
							}}
						>
							<Typography variant="testHeading" component={"div"}>
								Share
							</Typography>
							<SocialMediaShareButtons
								sharePlatformsList={["FB", "TW", "EM"]}
								url={viewContext?.component === "startup" ? startupShareUrl : accountShareUrl}
								title={`Check out my company profile on StartupOS`}
								description={`Check out my profile on StartupOS`}
								windowWidth={500}
								windowHeight={500}
								emailSeparator="\n"
								email={viewContext?.component === "startup" ? profileData?.founderDetails?.email : profileData?.email}
							/>
						</Box>
					</Box>
				)}

				{viewContext && viewContext?.component === "startup" && <AboutTabs />}
				{viewContext && viewContext?.component === "account" && <UserAbout />}
			</Box>
		</StyledProfileCard>
	);
}

export default AboutCard;
