import React, { Fragment, useContext, useEffect, useState } from "react";
import { Box, Skeleton, Divider, Typography } from "@mui/material";
import StartupFinanceTags from "./StartupFinanceTags";
import StartupFundingTags from "./StartupFundingTags";
import AccountBioTags from "./AccountBioTags";
import { ProfileContext } from "../../..";

function AccountBio() {
	const { profileDataLoading, profileData } = useContext(ProfileContext);
	const [loading, setloading] = useState(profileDataLoading);
	const [data, setdata] = useState(profileData);

	useEffect(() => {
		setloading(profileDataLoading);
	}, [profileDataLoading]);
	useEffect(() => {
		setdata(profileData);
	}, [profileData]);

	return (
		<Fragment>
			<Box>{loading ? <Skeleton animation="wave" variant="rounded" sx={{ height: "120px", width: 1 }} /> : <AccountBioTags />}</Box>
			{/* <Divider
				sx={{
					color: "#D0D5DD",
					my: 3,
				}}
			/>
			{loading ? (
				<Skeleton animation="wave" variant="text" sx={{ fontSize: "40px" }} />
			) : (
				<Typography
					component={"div"}
					variant="customValue"
					sx={{
						color: "black",
						fontFamily: "PoppinsSemiBold",
						fontSize: "20px",
						lineHeight: "30px",
						mb: "12px",
					}}
				>
					Guidance Needed
				</Typography>
			)}
			{loading ? (
				<Skeleton animation="wave" variant="text" sx={{ fontSize: "40px" }} />
			) : (
				<Typography
					component={"div"}
					variant="customValue"
					sx={{
						color: "black",
						fontFamily: "PoppinsMedium",
						fontSize: "16px",
						lineHeight: "24px",
						wordWrap: "break-word",
					}}
				>
					{(() => {
						let expertise = data?.expertise;
						if (expertise) {
							try {
								expertise = JSON.parse(expertise);
								return expertise.join(", ");
							} catch (e) {
								if (expertise) {
									return expertise;
								} else {
									return `${data?.name ? data?.name : "This user"} has not completed their experience section of their profile.`;
								}
							}
						} else {
							return `${data?.name ? data?.name : "This user"} has not completed their experience section of their profile.`;
						}
					})()}
				</Typography>
			)} */}
		</Fragment>
	);
}

export default AccountBio;
