import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import theme from "../../../constants/Theme";
import { TEAM_PROFILE_PLACEHOLDER } from "../../../constants/image";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";

export const InviteTeamCard = ({
  details,
  isEdit = false,
  handleEyeIconAction,
  disabled = false,
}) => {
  return (
    <>
      <Grid container>
        {details?.map(item => {
          return (
            <Grid
              item
              sx={{
                borderRadius: `${theme.spacing(1)}`,
                boxShadow:
                  "0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)",
                backgroundColor: "secondary.white",
                mb: 2,
              }}
              xs={12}
              key={item?.index}
            >
              <Box
                sx={{
                  opacity: disabled ? 0.4 : 1,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box sx={{ display: "flex", p: 2 }}>
                  <Box
                    component="img"
                    src={
                      item?.picture ? item?.picture : TEAM_PROFILE_PLACEHOLDER
                    }
                    alt="profile"
                    height={40}
                    width={40}
                    sx={{ borderRadius: "50%", objectFit: "cover" }}
                  />
                </Box>
                {/* maxwidth was used to add the ellipisis property for avoiding data overflow */}
                <Box
                  sx={{ maxWidth: "70%", color: theme.palette.text.primary }}
                >
                  <Box
                    sx={{
                      //whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    <Typography variant="headline6">{item?.name}</Typography>
                  </Box>
                  <Box>
                    <Typography variant="subhead1">{item?.title}</Typography>
                  </Box>
                </Box>
                {isEdit && (
                  <Box sx={{ ml: "auto", mr: 4 }}>
                    {item?.isHidden ? (
                      <VisibilityOffIcon
                        sx={{ cursor: "pointer" }}
                        onClick={() => handleEyeIconAction(false, item?.id)}
                      />
                    ) : (
                      <VisibilityIcon
                        sx={{ cursor: "pointer" }}
                        onClick={() => handleEyeIconAction(true, item?.id)}
                      />
                    )}
                  </Box>
                )}
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};
