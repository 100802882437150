import React from "react";
import { styled, keyframes } from "@mui/material/styles";
import { maxHeight } from "@mui/system";

const blobber = keyframes`
    0%  { border-radius: 48% 52% 68% 32% / 42% 28% 72% 58%; }
    5%  { border-radius: 48% 52% 41% 59% / 66% 37% 63% 34%; }
    10% { border-radius: 69% 31% 71% 29% / 67% 31% 69% 33%; }
    15% { border-radius: 60% 40% 71% 29% / 63% 65% 35% 37%; }
    20% { border-radius: 61% 39% 51% 49% / 57% 58% 42% 43%; }
    25% { border-radius: 66% 34% 25% 75% / 47% 30% 70% 53%; }
    30% { border-radius: 32% 68% 38% 62% / 65% 60% 40% 35%; }
    35% { border-radius: 63% 37% 41% 59% / 35% 38% 62% 65%; }
    40% { border-radius: 57% 43% 49% 51% / 55% 71% 29% 45%; }
    45% { border-radius: 47% 53% 34% 66% / 65% 36% 64% 35%; }
    50% { border-radius: 44% 56% 32% 68% / 69% 26% 74% 31%; }
    55% { border-radius: 28% 72% 37% 63% / 71% 44% 56% 29%; }    
    60% { border-radius: 38% 62% 35% 65% / 74% 53% 47% 26%; }
    65% { border-radius: 73% 27% 46% 54% / 54% 47% 53% 46%; }
    70% { border-radius: 75% 25% 47% 53% / 49% 53% 47% 51%; }
    75% { border-radius: 62% 38% 43% 57% / 55% 60% 40% 45%; }
    80% { border-radius: 41% 59% 65% 35% / 73% 50% 50% 27%; }
    85% { border-radius: 55% 45% 57% 43% / 73% 61% 39% 27%; }
    90% { border-radius: 74% 26% 33% 67% / 40% 65% 35% 60%; }
    95% { border-radius: 58% 42% 57% 43% / 53% 45% 55% 47%; }
    100%{ border-radius: 48% 52% 68% 32% / 42% 28% 72% 58%; }
`;

const blobber_alt = keyframes`
    0%  { border-radius: 48% 52% 68% 32% / 42% 28% 72% 58%; }
    10% { border-radius: 30% 70% 28% 72% / 28% 19% 81% 72% }
    20% { border-radius: 89% 11% 75% 25% / 57% 9% 91% 43% }
    30% { border-radius: 18% 82% 85% 15% / 89% 52% 48% 11%  }
    40% { border-radius: 29% 71% 12% 88% / 37% 82% 18% 63%  }
    50% { border-radius: 56% 44% 70% 30% / 86% 78% 22% 14% }
    60% { border-radius: 30% 70% 28% 72% / 28% 19% 81% 72% }
    70% { border-radius: 89% 11% 75% 25% / 57% 9% 91% 43% }
    80% { border-radius: 18% 82% 85% 15% / 89% 52% 48% 11%  }
    90% { border-radius: 29% 71% 12% 88% / 37% 82% 18% 63%  }
    100%{ border-radius: 48% 52% 68% 32% / 42% 28% 72% 58%; }
`;

const BlobWrap = styled("div")(({ theme }) => ({
  height: "120%",
  width: "120%",
  position: "absolute",
  zIndex: 1,
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
}));

const BlobObj = styled("div")(({ theme, variant }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
  width: variant && variant == "purple" ? "95%" : "90%",
  height: variant && variant == "orange" ? "95%" : "90%",
  overflow: "hidden",
  zIndex: variant && variant == "purple" ? 2 : 1,
  background:
    variant && variant == "purple"
      ? "#592C82"
      : "linear-gradient(92.58deg, #E97300 0%, #FFD141 100%)",
  animation:
    variant && variant == "purple"
      ? `${blobber} 80s 0s linear infinite`
      : `${blobber_alt} 80s 0s linear infinite`,
}));

export const Blob = ({}) => {
  return (
    <BlobWrap>
      <BlobObj variant="purple" />
      <BlobObj variant="orange" />
    </BlobWrap>
  );
};
