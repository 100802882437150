import { createTheme } from "@mui/material/styles";
import { sosPrimary } from "./colors";

export const themeAccordion = (themePalette) => ({
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            backgroundColor: "#F5F8FA",
          },
        },
      },
    },
  },
});
