import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { TwitterPicker } from "react-color";

function ColorPicker({ colors = {}, setColors, flexProp }) {
	const defaultColors = {
		primary: "#356AC3",
		secondary: "#A0C2F9",
		tertiary: "#FFAB40",
	};

	const [currColors, setcurrColors] = useState({
		primary: colors.primary || defaultColors.primary,
		secondary: colors.secondary || defaultColors.secondary,
		tertiary: colors.tertiary || defaultColors.tertiary,
	});

	useEffect(() => {
		setColors(currColors);
	}, [currColors]);

	const setColorVals = (id, value) => {
		setcurrColors((prevColors) => ({
			...prevColors,
			[id]: value,
		}));
	};

	return (
		<Box sx={{ display: "flex", flexDirection: flexProp ? flexProp : "column" , gap: 3, flexWrap:"wrap" }}>
			<Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
				<Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
					<Box
						sx={{
							height: "30px",
							width: "30px",
							borderRadius: "4px",
							backgroundColor: currColors?.primary ? currColors?.primary : "lightgrey",
							ml: "5px",
						}}
					></Box>
					<Typography variant="Text/md/Semibold">Primary</Typography>
				</Box>
				<TwitterPicker color={currColors?.primary ? currColors?.primary : "#356AC3"} onChangeComplete={(color) => setColorVals("primary", color.hex)} />
			</Box>
			<Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
				<Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
					<Box
						sx={{
							height: "30px",
							width: "30px",
							borderRadius: "4px",
							backgroundColor: currColors?.secondary ? currColors?.secondary : "lightgrey",
							ml: "5px",
						}}
					></Box>
					<Typography variant="Text/md/Semibold">Secondary</Typography>
				</Box>
				<TwitterPicker
					color={currColors?.secondary ? currColors?.secondary : "#A0C2F9"}
					onChangeComplete={(color) => setColorVals("secondary", color.hex)}
				/>
			</Box>
			<Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
				<Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
					<Box
						sx={{
							height: "30px",
							width: "30px",
							borderRadius: "4px",
							backgroundColor: currColors?.tertiary ? currColors?.tertiary : "lightgrey",
							ml: "5px",
						}}
					></Box>
					<Typography variant="Text/md/Semibold">Tertiary</Typography>
				</Box>
				<TwitterPicker
					color={currColors?.tertiary ? currColors?.tertiary : "#FFAB40"}
					onChangeComplete={(color) => setColorVals("tertiary", color.hex)}
				/>
			</Box>
		</Box>
	);
}

export default ColorPicker;
