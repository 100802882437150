import { Stack, Skeleton, Box } from "@mui/material";
import React, { useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import StatusButton from "../../../components/Profile/Components/StatusButton";
import PublicEducationItem from "../../../components/PublicProfile/AccountPublicProfile/EducationCard/PublicEducationItems";
import { PURPLE_DEGREE_ICON } from "../../../constants";
import { UserCardContainer } from "../UserAboutCard/style";
import UserProfileCardSection from "../UserProfileCardSection";
import ProgressCompletionCalculator from "../../../utils/progressCompletionCalculator";
import { useParams, useHistory } from "react-router-dom";

const UserEducationDetailsCard = ({ context }) => {
	const params = useParams();
	const userId = params?.id;
	const history = useHistory();
	const educationDetails = useSelector((state) => state?.userProfileData?.userData?.educationDetails);
	const isLoading = useSelector((state) => state?.userProfileData?.loading);
	const { handleEditProfileRedirections, setEducationPercentage } = useContext(context);
	const totalFields = 1;
	const completedFields = educationDetails && educationDetails.length > 0 ? 1 : 0;

	useEffect(() => {
		setEducationPercentage(ProgressCompletionCalculator(totalFields, completedFields));
	}, [completedFields]);

	return (
		<UserCardContainer>
			<Stack direction={"column"} spacing={3}>
				{isLoading ? (
					<Skeleton animation="wave" variant="rectangular" width={100} height={50} />
				) : (
					!userId && (
						<StatusButton
							percentage={ProgressCompletionCalculator(totalFields, completedFields)}
							handleRedirection={handleEditProfileRedirections}
						/>
					)
				)}
				{isLoading ? (
					<Skeleton animation="wave" variant="rectangular" width={"100%"} height={200} />
				) : (
					<UserProfileCardSection
						icon={PURPLE_DEGREE_ICON}
						title={"Education"}
						iconColor={""}
						iconHeight={"26px"}
						iconWidth={"26px"}
						count={educationDetails ? educationDetails.length : null}
					>
						<Box
							sx={{
								display: "grid",
								gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
								gridAutoRows: "1fr",
								gridGap: "36px",
								alignItems: "stretch",
							}}
						>
							{educationDetails && educationDetails.length > 0
								? educationDetails?.map((item, index) => <PublicEducationItem item={item} key={index} />)
								: "No Data"}
						</Box>
					</UserProfileCardSection>
				)}
			</Stack>
		</UserCardContainer>
	);
};

export default UserEducationDetailsCard;
