import React from "react";
import BILineChart from "./BILineChart/BILineChart";
import { Box, useTheme, Typography, CircularProgress } from "@mui/material";
import ChartCard from "./ChartCard";
import { get } from "lodash";
import { convertNumberToLocale, getGradientBgColor } from "../Functions/common";
import ZeroLineChart from "./BILineChart/EmptyLineChart";
import { CAC_BENCHMARKS } from "../../../../constants";
import { useSelector } from "react-redux";

function PaybackChartContainer({ onChartClick, data, loading, readOnly }) {
	const theme = useTheme();
	const chartData = get(data, "data", []);

	const latestCacPaybacks = get(data, "latestCacPayback", null);
	const companyStage = useSelector((store) => store?.biDashboard?.currentStage);

	return (
		<ChartCard
			title={"CAC Payback Period"}
			subheader={latestCacPaybacks !== null && !loading ? `${convertNumberToLocale(latestCacPaybacks)} Months` : "0"}
			backgroundColor={theme.palette.secondary.white}
			onChartClick={() => onChartClick("cacPayback", data)}
			toolTipBody={
				<Typography variant="Text/sm/Regular">
					CAC Payback Period measures how many months it takes a cohort of customers to produce enough Gross Profit to pay back the sales and
					marketing expenses it took to acquire those customers.
				</Typography>
			}
			data={data?.data}
		>
			{loading ? (
				<Box sx={{ alignSelf: "center" }}>
					<CircularProgress />
				</Box>
			) : chartData.length === 0 ? (
				<ZeroLineChart readOnly={readOnly} />
			) : (
				<Box sx={{ position: "relative", width: "100%", mt: "auto" }}>
					<BILineChart
						data={chartData}
						tooltipYLable={"CAC Payback"}
						yAxisKey={"cacPayback"}
						lineBorderColor={theme.palette.primary.main}
						lineBackgroundColor={(context) => {
							const ctx = context.chart.ctx;
							return getGradientBgColor(ctx, context);
						}}
						datasets={[
							{
								label: `${companyStage} Benchmark`,
								data:
									chartData?.map((dataItem) => ({
										id: get(dataItem, "id", null),
										date: get(dataItem, "date", null),
										benchamark: CAC_BENCHMARKS[companyStage],
									})) || [],
								borderWidth: 2,
								borderColor: theme.palette.bidashboard.paramLightBlue,
								tension: 0.15,
								parsing: {
									xAxisKey: "date",
									yAxisKey: "benchamark",
								},
								pointRadius: 0,
								pointHoverRadius: 3,
								clip: false,
								borderDash: [5, 5],
							},
						]}
					/>
				</Box>
			)}
		</ChartCard>
	);
}

export default PaybackChartContainer;
