import { Grid, Box, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import InfoHelper from "../InfoHelper";
import { CardFounderWrapper } from "../styles";
import Impression from "./Impression";

function InvestorImpressions() {
	const investorImpressionsData = useSelector((state) => state?.founderDashboard?.investorImpressionsData);

	const totalImpressionValue = () => {
		let sum = 0;
		for (const impressionObjectKey in investorImpressionsData) {
			if (Object.hasOwnProperty.call(investorImpressionsData, impressionObjectKey)) {
				const impression = investorImpressionsData[impressionObjectKey];
				if (impression !== null) {
					sum = sum + impression;
				}
			}
		}
		return sum;
	};

	return (
		<CardFounderWrapper>
			<Box>
				<Grid container rowSpacing={5} columnSpacing={1}>
					<Grid item xs={12} lg={12}>
						<Box>
							<Box sx={{ display: "flex", alignItems: "center", gap: "12px" }}>
								<Typography variant="main_title" color="secondary.black">
									Investor Impressions
								</Typography>
								<InfoHelper
									title={"Investor Impressions"}
									body={
										"View the number of times an investor has viewed your Startup Profile page, regardless of whether they have interacted or connected with you."
									}
								/>
							</Box>
						</Box>
					</Grid>
					<Grid item xs={12} lg={6}>
						<Impression title="Impressions" score={totalImpressionValue()} scoreVariant="impression_title" />
					</Grid>
					<Grid item xs={12} lg={6}></Grid>
					<Grid item xs={12} lg={6}>
						<Impression title="Viewed by Investors" score={investorImpressionsData?.investorsViewed ?? 0} />
					</Grid>
					<Grid item xs={12} lg={6}>
						<Impression title="Investor Conversations" score={investorImpressionsData?.investorsConversations ?? 0} />
					</Grid>
					<Grid item xs={12} lg={6}>
						<Impression title="Investors Watching" score={investorImpressionsData?.investorsWatching ?? 0} />
					</Grid>
					<Grid item xs={12} lg={6}>
						<Impression title="In Investors Portfolio" score={investorImpressionsData?.investorsPortfolio ?? 0} />
					</Grid>
					<Grid item xs={12} lg={6}>
						<Impression title="Investor Meetings Scheduled" score={investorImpressionsData?.investorsMeetingScheduled ?? 0} />
					</Grid>
				</Grid>
			</Box>
		</CardFounderWrapper>
	);
}

export default InvestorImpressions;
