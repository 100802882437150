import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { useTheme } from "@mui/material";
import { convertNumberToLocale } from "../../Functions/common";

ChartJS.register(ArcElement, Tooltip, Legend);

const emptyDoughnutChart = {
	id: "emptyDoughnut",
	afterDraw(chart, args, options) {
		const { datasets } = chart.data;
		const { color, width, radiusDecrease } = options;
		let hasData = false;

		for (let i = 0; i < datasets?.[0]?.data?.length; i += 1) {
			const dataset = datasets?.[0]?.data?.[i];
			hasData |= dataset > 0;
		}

		if (!hasData) {
			const {
				chartArea: { left, top, right, bottom },
				ctx,
			} = chart;
			const centerX = (left + right) / 2;
			const centerY = (top + bottom) / 2;
			const r = Math.min(right - left, bottom - top) / 2;

			ctx.beginPath();
			ctx.lineWidth = width;
			ctx.strokeStyle = color;
			ctx.arc(centerX, centerY, r - radiusDecrease || 0, 0, 2 * Math.PI);
			ctx.stroke();
		}
	},
};

function BIDoughnutChart({ cutout = "90%", labels = [], data, yLable, type }) {
	const theme = useTheme();
	return (
		<Doughnut
			data={{
				labels: labels.map((label) => label.name),
				datasets: [
					{
						label: yLable,
						data: data,
						backgroundColor: labels.map((label) => label.color),
						borderColor: labels.map((label) => label.color),
						borderWidth: 1,
					},
				],
			}}
			plugins={[emptyDoughnutChart]}
			options={{
				cutout: cutout,
				maintainAspectRatio: false,
				responsive: true,
				plugins: {
					legend: {
						display: false,
					},
					datalabels: {
						display: false,
					},
					tooltip: {
						enabled: true,
						callbacks: {
							label: function (context) {
								const index = context.dataIndex;
								let label = labels?.[index]?.name || "";
								if (label) {
								  label += ":";
								}
								let yValue = context.parsed;
								if ((yValue !== "") & (typeof yValue !== "undefined") && yValue !== null) {
									yValue = convertNumberToLocale(yValue);
									if (type === "currency") {
										yValue = "$ " + yValue;
									}
								}
								return ` ${label} ${yValue}`;
							},
						},
					},
					emptyDoughnut: {
						color: theme.palette.sos.gray200,
						width: 20,
						radiusDecrease: 10,
					},
				},
			}}
		/>
	);
}

export default BIDoughnutChart;
