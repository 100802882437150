import { Backdrop, Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { setInvestorDashboardData } from "../../modules/actions";
import services from "../../services";
import BenchmarkMainContent from "./MainContent";
import "./Panel.css";
import { handleMixPanel } from "../../utils/mixPanelEventHandling";

function ScoreBenchmarkDetails() {
	const STARTUP = useSelector((state) => state?.investorDashboard?.openStartupObj);
	const FLYOUT_OPEN = useSelector((state) => state?.investorDashboard?.benchmarkDetailsOpen);
	const RANGE_SELECTION = useSelector((state) => state?.investorDashboard?.scoreTrendRangeSelection);

	const dispatch = useDispatch();

	function convertDurationToMonths(duration) {
		if (duration === "1M") {
			return 1;
		} else if (duration === "3M") {
			return 3;
		} else if (duration === "6M") {
			return 6;
		} else if (duration === "1Y") {
			return 12;
		} else if (duration === "2Y") {
			return 24;
		} else {
			return 1; // or throw an error, depending on your use case
		}
	}

	const getReport = () => {
		try {
			dispatch(setInvestorDashboardData({ scoreTrendRangeLoading: true }));
			services
				.getStartupScoreReport(STARTUP?.id, convertDurationToMonths(RANGE_SELECTION))
				.then((resp) => {
					dispatch(setInvestorDashboardData({ ScoreReportObj: resp?.data?.data, scoreTrendRangeLoading: false }));
				})
				.catch((e) => {
					console.log(e);
					dispatch(setInvestorDashboardData({ scoreTrendRangeLoading: false }));
				});
		} catch (e) {
			console.log("Error when attempting to grab score report for startup. Failed with error:", e);
			dispatch(setInvestorDashboardData({ scoreTrendRangeLoading: false }));
		}
	};

	useEffect(() => {
		if (FLYOUT_OPEN && STARTUP?.id) {
			getReport();
		}
	}, [RANGE_SELECTION]);

	useEffect(() => {
		if (FLYOUT_OPEN && STARTUP?.id) {
			handleMixPanel("Opportunity DB Startup Score Viewed", {
				startId: STARTUP?.id,
			});
			getReport();
		} else {
			dispatch(setInvestorDashboardData({ ScoreReportObj: {} }));
		}
	}, [FLYOUT_OPEN]);

	return (
		<Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={FLYOUT_OPEN}>
			<TransitionGroup>
				{FLYOUT_OPEN && STARTUP && (
					<CSSTransition key="panel" timeout={500} classNames="slide" unmountOnExit>
						<Box
							className="panel"
							sx={{
								position: "absolute",
								top: 0,
								right: 0,
								width: "98.5%",
								minHeight: "100vh",
								height: "100%",
								overflowY: "scroll",
								background: "white",
								borderTopLeftRadius: (theme) => theme.shape.standard6.borderRadius,
								borderBottomLeftRadius: (theme) => theme.shape.standard6.borderRadius,
							}}
						>
							<BenchmarkMainContent />
						</Box>
					</CSSTransition>
				)}
			</TransitionGroup>
		</Backdrop>
	);
}

export default ScoreBenchmarkDetails;
