import React, { useContext, useEffect, useState } from "react";
import { ProfileContext } from "../../..";
import { LOCATION_NEW_ICON, MOBILE_DEVICE_ICON, BUILDING_ICON_V1 } from "../../../../../constants";
import { STATES } from "../../../../../constants/powerup-data";
import TagList from "./TagList";
import { generatePath, useHistory } from "react-router";
import ROUTES_PATH from "../../../../../constants/routes";
import { useDispatch, useSelector } from "react-redux";
import { setSlidingProfileData } from "../../../../../modules/actions/slidingProfileActions";
import { USER_TYPES } from "../../../../../utils/userTypes";

function AccountBioTags() {
	const history = useHistory();
	const { profileData, viewContext } = useContext(ProfileContext);
	const [items, setitems] = useState([]);
	const userData = useSelector((store) => ({ userType: store?.auth?.userType, companyId: store?.auth?.companyId, userId: store?.auth?.userId }));
	const dispatch = useDispatch();

	useEffect(() => {
		if (profileData) {
			let list = [
				{
					iconUrl: BUILDING_ICON_V1,
					label: profileData?.companyModel?.companyName ? profileData?.companyModel?.companyName : profileData?.companyName || "No Data",
					onClick: () => {
						if (!viewContext?.id || viewContext?.id == userData?.userId) {
							dispatch(
								setSlidingProfileData({
									open: false,
									profileType: "",
									profileId: null,
								})
							);
							history.push(ROUTES_PATH.MY_STARTUP_PROFILE);
						} else {
							if (
								profileData?.companyModel?.investorView &&
								(userData?.userType === USER_TYPES.MENTOR_USER || userData?.userType === USER_TYPES.INVESTOR_USER)
							) {
								dispatch(
									setSlidingProfileData({
										open: false,
										profileType: "",
										profileId: null,
									})
								);
								history.push({
									pathname: generatePath(ROUTES_PATH.STARTUP_PROFILE, {
										...(profileData?.companyModel?.id && { id: parseInt(profileData?.companyModel?.id) }),
									}),
								});
							}
						}
					},
				},
				{
					iconUrl: LOCATION_NEW_ICON,
					label: (() => {
						let city = profileData?.city;
						let area = STATES.find((s) => s.name === profileData?.state);
						area = area ? area?.abbreviation : profileData?.state;
						if (city && area) {
							return `${city}, ${area}`;
						} else if (!city && profileData?.state) {
							return profileData?.state;
						} else if (city && !profileData?.state) {
							return city;
						} else return "No Data";
					})(),
				},
				{
					iconUrl: MOBILE_DEVICE_ICON,
					label: (() => {
						const digits = profileData?.phone?.replace(/\D/g, ""); // removes all non-digit characters
						const digitMatch = digits?.match(/^(\d{3})(\d{3})(\d{4})$/); // matches groups of 3 digits
						if (digitMatch) {
							return `${digitMatch[1]}-${digitMatch[2]}-${digitMatch[3]}`;
						} else {
							return profileData?.phone ? profileData?.phone : "No Data"; // return original if phone number cannot be masked
						}
					})(),
				},
			];
			setitems(list);
		}
	}, [profileData]);

	return <TagList items={items} />;
}

export default AccountBioTags;
