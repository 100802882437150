import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import ProgressGraph from "./progressgraph";
import theme from "../../../constants/Theme";

export const ScoreChart = ({ percentage, scoreValue, stageName, stageValue, scoreObj, toggleOpenScoreBenchmarkDetails, toggleOpenFundingStagesDetails }) => {
	return (
		<Box
			style={{
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			}}
		>
			<Box style={{ display: "block", alignItems: "center", width: "fit-connent" }}>
				<ProgressGraph
					rightColour="#EEEEEE"
					leftColour={theme.palette.primary.primaryPurple}
					knobColour="#7DD2DB"
					heightValue="220"
					widthValue="360"
					marginX={10}
					marginY={50}
					percentage={percentage}
					scoreValue={scoreValue}
					stageName={stageName}
					stageValue={stageValue}
					scoreObj={scoreObj}
					toggleOpenScoreBenchmarkDetails={toggleOpenScoreBenchmarkDetails}
					toggleOpenFundingStagesDetails={toggleOpenFundingStagesDetails}
				/>
			</Box>
		</Box>
	);
};
