import React from "react";
import { CardContent } from "@mui/material";
import { cardTheme, WorkspaceCard } from "./styles";
import PropTypes from "prop-types";
import { ThemeProvider } from "@mui/material/styles";

const Card = ({ children, variant, sx }) => {
  return (
    <ThemeProvider theme={cardTheme}>
      <WorkspaceCard color="primary" variant={variant} sx={{ ...sx }}>
        <CardContent>{children}</CardContent>
      </WorkspaceCard>
    </ThemeProvider>
  );
};

{
  Card.propTypes = {
    //children: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    //  .isRequired,
    variant: PropTypes.string,
    sx: PropTypes.object,
  };

  Card.defaultProps = {
    children: undefined,
    variant: "",
    sx: {},
  };
}

export default Card;
