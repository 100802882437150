import { REGEX } from "../../../../../constants/regex";
export const getExcerciseConfigurations = () => {
  return {
    form_key: "ideaValidationInterview",
    power_up_id: 4,
    form_title: "Idea Validation Interview",
    //summary_path: "",
    excercises: [
      {
        step_id: 28,
        component: "IDENTIFY_INTERVIEW_GOALS",
        path: "identify-interview-goals",
        key: "identifyInterviewGoals",
      },
      {
        step_id: 29,
        component: "BUILD_INTERVIEW_SCRIPT",
        path: "build-interview-script",
        key: "buildInterviewScript",
      },
    ],
  };
};
export const getExcerciseCompletionStatus = currentExcercise => {
  return true;
};

export const addExternalCandidatesValid = (key, input) => {
  switch (key) {
    case "firstName":
    case "lastName":
      if (input.match(REGEX.LETTERS_WITH_SPACES) && input.length >= 0) {
        return true;
      } else {
        return false;
      }

    case "email":
      if (input.match(REGEX.EMAIL)) {
        return true;
      } else {
        return false;
      }
  }
};

export const addCandidatesButtonDisable = userInputs => {
  for (const key in userInputs) {
    if (!addExternalCandidatesValid(key, userInputs[key])) {
      return true;
    }
  }
  return false;
};
