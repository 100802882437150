export var ActionType;
(function (ActionType) {
  ActionType["LOGIN"] = "LOGIN";
  ActionType["LOGOUT"] = "LOGOUT";
  ActionType["ADD_CONVERSATION"] = "ADD_CONVERSATION";
  ActionType["REMOVE_CONVERSATION"] = "REMOVE_CONVERSATION";
  ActionType["UPDATE_CURRENT_CONVERSATION"] = "UPDATE_CURRENT_CONVERSATION";
  ActionType["CONVERSATION_LAST_READ_INDEX"] = "CONVERSATION_LAST_READ_INDEX";
  ActionType["ADD_MESSAGES"] = "ADD_MESSAGES";
  ActionType["PUSH_MESSAGES"] = "PUSH_MESSAGES";
  ActionType["REMOVE_MESSAGES"] = "REMOVE_MESSAGES";
  ActionType["UPDATE_LOADING_STATE"] = "update loading state";
  ActionType["UPDATE_PARTICIPANTS"] = "UPDATE_PARTICIPANTS";
  ActionType["UPDATE_UNREAD_MESSAGES"] = "UPDATE_UNREAD_MESSAGES";
  ActionType["UPDATE_CONVERSATION"] = "UPDATE_CONVERSATION";
  ActionType["ADD_ATTACHMENT"] = "ADD_ATTACHMENT";
  ActionType["CLEAR_ATTACHMENTS"] = "CLEAR_ATTACHMENTS";
  ActionType["TYPING_STARTED"] = "TYPING_STARTED";
  ActionType["TYPING_ENDED"] = "TYPING_ENDED";
  ActionType["ADD_NOTIFICATIONS"] = "ADD_NOTIFICATIONS";
  ActionType["REMOVE_NOTIFICATIONS"] = "REMOVE_NOTIFICATIONS";
  ActionType["CLEAR_ALL_CONVERSATION"] = "CLEAR_ALL_CONVERSATION";
  ActionType["CLEAR_ALL_MESSAGES"] = "CLEAR_ALL_MESSAGES";
  ActionType["CREATING_NEW_CONVERSATION"] = "CREATING_NEW_CONVERSATION";
  ActionType["NEW_CONVERSATION_WITH"] = "NEW_CONVERSATION_WITH";
  ActionType["RESET_CHAT_STATE"] = "RESET_CHAT_STATE";
  ActionType["SORT_CONVERSATIONS"] = "SORT_CONVERSATIONS";
})(ActionType || (ActionType = {}));
