import React from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography, IconButton, Box, Button, Slide } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setNetworkingDashboardData } from "../../../modules/actions/networkingActions";
import CloseIcon from "@mui/icons-material/Close";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="left" ref={ref} {...props} />;
});

function ConfirmationDialog({ onHideConfirm }) {
	const networking = useSelector((state) => state?.networking);
	const dispatch = useDispatch();
	const title = networking?.slidingConfirmationObject?.title;
	// const text = networking?.slidingConfirmationObject?.text;
	const subText = networking?.slidingConfirmationObject?.subText;
	const primaryButtonText = networking?.slidingConfirmationObject?.primaryButtonText;
	const secondaryButtonText = networking?.slidingConfirmationObject?.secondaryButtonText;

	const isOpen = networking?.savedConfirmOpen || networking?.hiddenConfirmOpen;

	const id = networking?.slidingConfirmationObject?.id;

	// const isSaved = networking?.slidingConfirmationObject?.isSaved;

	const isHidden = networking?.slidingConfirmationObject?.isHidden;

	const handleClose = () => {
		dispatch(
			setNetworkingDashboardData({
				// savedConfirmOpen: false,
				hiddenConfirmOpen: false,
			})
		);
	};

	return (
		<Dialog
			TransitionComponent={Transition}
			transitionDuration={600}
			aria-describedby="alert-dialog-slide-description"
			keepMounted
			maxWidth={"xs"}
			fullWidth
			open={isOpen}
			onClose={handleClose}
			PaperProps={{
				sx: (theme) => ({
					borderRadius: "30px",
					p: 3,
					ml: "auto",
					mr: 0,
					mb: 0,
					alignSelf: "end",
					position: "fixed",
					right: 0,
				}),
			}}
		>
			{title && (
				<DialogTitle
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<Typography variant={"card_name"}>{title}</Typography>

					<IconButton
						aria-label="close"
						onClick={handleClose}
						sx={{
							color: (theme) => theme.palette.secondary.dark,
						}}
					>
						<CloseIcon sx={{ color: (theme) => theme.palette.secondary.black }} />
					</IconButton>
				</DialogTitle>
			)}
			<DialogContent
				sx={{
					px: 4,
				}}
			>
				<>
					{/* <Box sx={{ pt: 2 }}>
						<Typography variant="on_surface">{text}</Typography>
					</Box> */}
					<Box sx={{ mt: 1 }}>
						<Typography variant="sub_title_portfolio">{subText}</Typography>
					</Box>
				</>
			</DialogContent>
			<DialogActions>
				<>
					<Box display={"flex"} gap={2} sx={{ pr: 2 }} alignItems="center">
						<Button
							variant="noOutlineNew"
							onClick={() => {
								handleClose();
							}}
						>
							<Typography variant="sub_title_portfolio" color="inherit">{secondaryButtonText}</Typography>
						</Button>
						<Button
							variant="containedNewStyle"
							onClick={() => {
								onHideConfirm(id, isHidden);
								// if (networking?.savedConfirmOpen) {
								// 	onSaveConfirm(id, isSaved);
								// } else {
								// 	onHideConfirm(id, isHidden);
								// }
							}}
						>
							<Typography variant="sub_title_portfolio" color="inherit">{primaryButtonText}</Typography>
						</Button>
					</Box>
				</>
			</DialogActions>
		</Dialog>
	);
}

export default ConfirmationDialog;
