import { StarOutlineRounded } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import { Box } from "@mui/system";
import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import PowerUpPopUp from "../../../../../containers/Workspace/PowerUpPopUp";
import { ReviewForm } from "../../../../../containers/Workspace/Reviews/ReviewForm";
import services from "../../../../../services";
import { toastContainer } from "../../../../../utils/toast";

export const RateThisPowerUP = ({ ...props }) => {
  const [buttonLoading, setButtonLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [starValue, setStarValue] = useState(0);
  const [inputValue, setInputValue] = useState("");
  const powerupData = useSelector(state => state?.powerUp);

  const submitHandler = () => {
    setButtonLoading(true);
    const data = {
      responseId: props.responseID
        ? props.responseID
        : powerupData?.headerDetails?.currentModuleResponseId,
      entityId: props.entityID,
      entityType: "POWER_UP",
      rating: starValue,
      title: "PowerUP Review",
      description: inputValue || "",
    };
    services
      .addUserReview(data)
      .then(result => {
        if (result.data.code === 200) {
          setButtonLoading(false);
          setIsOpen(false);
          setStarValue(0);
          setInputValue("");
          toastContainer("Review submitted.", "success");
          props.refreshList();
        }
      })
      .catch(error => {
        setButtonLoading(false);
        setIsOpen(false);
        setStarValue(0);
        setInputValue("");
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toastContainer(error.response.data.message, "error");
        }
        throw error;
      });
  };
  return (
    <Fragment>
      {/* Needs to have an active module response ID to be able to submit a review. */}
      {(props.responseID ||
        powerupData?.headerDetails?.currentModuleResponseId) && (
        <>
          <Button
            variant="nofill"
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              gap: "8px",
            }}
            onClick={() => setIsOpen(true)}
          >
            <StarOutlineRounded />
            <span>Rate this PowerUp</span>
          </Button>
          <PowerUpPopUp
            open={isOpen}
            onClose={() => {
              setIsOpen(false);
              setInputValue("");
              setStarValue(0);
            }}
            title={"Tell us what you think"}
            mainContent={
              <ReviewForm
                currentValue={starValue}
                currentInput={inputValue}
                starValueChange={resp => setStarValue(resp)}
                inputChange={resp => setInputValue(resp)}
              />
            }
            actionBar={
              <Box sx={{ display: "flex", alignItems: "center", p: 1 }}>
                <LoadingButton
                  sx={{ display: "flex", justifySelf: "flex-end" }}
                  variant="contained"
                  loading={buttonLoading}
                  disabled={starValue == 0}
                  onClick={submitHandler}
                >
                  Submit
                </LoadingButton>
              </Box>
            }
          />
        </>
      )}
    </Fragment>
  );
};
