import React from "react";
import { Box, Breadcrumbs, Link, Typography } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import theme from "../../../../../constants/Theme";

export default function MyWorkBreadCrumbs({ subpath, handleBreadcrumbBack }) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        pl: { xs: 2, md: 4 },
      }}
    >
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<NavigateNextIcon sx={{ ml: 1.5, mr: 1 }} />}
      >
        <Link
          underline="hover"
          color="primary"
          component="button"
          onClick={handleBreadcrumbBack}
          sx={{ fill: theme.palette.secondary.dark }}
        >
          <Typography
            color="primary"
            variant="h5"
            sx={{ color: theme.palette.secondary.dark }}
          >
            My Work
          </Typography>
        </Link>
        <Typography color="text.primary" variant="h5">
          {subpath}
        </Typography>
      </Breadcrumbs>
    </Box>
  );
}
