import React, { useEffect, useState } from "react";
import { TimePicker } from "@mui/x-date-pickers";
import { Box } from "@mui/material";

function TimeSelection({ time, minTime, maxTime, onChange }) {
	const [propTime, setpropTime] = useState(time);
	useEffect(() => {
		setpropTime(time);
	}, [time]);

	return (
		<Box>
			<TimePicker
				value={propTime}
				ampm={true}
				onChange={(newValue) => {
					onChange(newValue);
				}}
				slotProps={{ textField: { placeholder: "12:00 AM", readOnly: true } }}
				timeSteps={{ hours: 1, minutes: 30, seconds: 0 }}
				sx={(theme) => ({
					"& .MuiInputBase-root": {
						borderRadius: "30px",
						backgroundColor: "#F2F4F7",
					},
					"& .MuiOutlinedInput-notchedOutline": {
						border: "none",
					},
					"& input, & input::placeholder": {
						fontFamily: '"PoppinsSemibold", "Helvetica", "Arial", sans-serif',
						fontSize: "16px",
						lineHeight: "24px",
					},
				})}
			/>
		</Box>
	);
}

export default TimeSelection;
