import React, { useState, useEffect } from "react";
import { Box, Grid, Button, Typography, Divider } from "@mui/material";
import theme from "../../../../constants/Theme";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import CloseIcon from "@mui/icons-material/Close";
import LoadingButton from "../../../../common/Loadingbutton";
import RichTextEditor from "./RichTextEditor";
import { stateToMarkdown } from "draft-js-export-markdown";
import {
  RICH_TEXT_EDITOR_MAIN_CONTROLS,
  RICH_TEXT_EDITOR_INLINE_CONTROLS,
} from "../../../../constants/plugin-constants";
export default function CandidateNotes({
  handleSaveNotes,
  saveLoading,
  isEdit,
  setIsEdit,
  details,
}) {
  const [notes, setNotes] = useState(details?.notes);

  const handleChange = value => {
    setNotes(stateToMarkdown(value.getCurrentContent()));
  };
  return (
    <Box sx={{ width: 1 }}>
      <Grid container>
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            py: 2,
          }}
        >
          <Grid container>
            <Grid item xs={6} lg={6}>
              <Typography variant="title_large">Your Notes</Typography>
            </Grid>

            {!isEdit ? (
              <Grid
                item
                xs={6}
                lg={6}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                <EditTwoToneIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    setIsEdit(true);
                  }}
                />
              </Grid>
            ) : (
              <Grid
                item
                xs={6}
                md={12}
                lg={6}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <LoadingButton
                  variant="contained"
                  sx={{ width: "max-content" }}
                  loading={saveLoading}
                  loadingStyles={{ px: 7 }}
                  onClick={() => handleSaveNotes(notes)}
                >
                  Save Changes
                </LoadingButton>
                <CloseIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    setIsEdit(false);
                  }}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} md={12}>
          <Box
            sx={theme => ({
              display: "flex",
              maxWidth: 1,
              borderRadius: theme.shape.standard3.borderRadius,
              background: !isEdit ? theme.palette.secondary.greyBg : "none",
              color: !isEdit ? theme.palette.secondary.main : "inherit",
            })}
          >
            <Box
              sx={theme => ({
                width: 1,
                minHeight: "200px",
                border: `1px solid ${theme.palette.secondary.dark}`,
                px: 1,
                borderRadius: theme.shape.standard3.borderRadius,
              })}
            >
              <RichTextEditor
                label="Type something here..."
                value={notes}
                toolbar={isEdit}
                inlineToolbar={false}
                inlineToolbarControls={RICH_TEXT_EDITOR_INLINE_CONTROLS}
                controls={RICH_TEXT_EDITOR_MAIN_CONTROLS}
                readOnly={!isEdit}
                onChange={handleChange}
                setNotes={setNotes}
                minHeight={"200px"}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
