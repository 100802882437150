import styled from "@emotion/styled";

export const TimerBox = styled.div(({ theme }) => {
  return {
    display: "flex",
    justifyContent: "center",
    gap: "10px",
    [theme.breakpoints.down("md")]: {
      gap: "0px",
    },
    [theme.breakpoints.down("lg")]: {
      gap: "3px",
    },
    alignItems: "center",
  };
});
