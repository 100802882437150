import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, generatePath } from "react-router-dom";
import { Box, Typography, Button, Tooltip as MuiTooltip, Avatar, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DoneIcon from "@mui/icons-material/Done";
import { Line } from "react-chartjs-2";
import { Chart, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend } from "chart.js";

import services from "../../../../services";
import { SPEECH_BUBBLE_ICON, STARTUPOSCIRCLEICON, STARTUP_PROFILE_BACKGROUND_2 } from "../../../../constants";
import { CHART_ICON, BINOCULARS_ICON, PAPERCLIP_ICON, LOCATION_ICON } from "../../../../constants";
import CompanyDetail from "../../../../components/ScoutingReport/FoundingTeam/StartupProfile/CompanyDetail";
import CommonSlideDialog from "../../../../common/CommonSlideDialog";
import { AddIconComp } from "../../../Investor/Dashboard/AllStartups/styles";
import { BoxBackground, BoxProfileBackground, BoxProfileContent } from "./styles";
import { setInvestorDashboardData } from "../../../../modules/actions";
import PublicIcon from "@mui/icons-material/Public";
import { EventAvailable, EventAvailableOutlined, SmsOutlined } from "@mui/icons-material";
import { formatUrl } from "../../../../utils/commonFunctions";
import { setCalUser } from "../../../../modules/actions/meetingsAction";
import ROUTES_PATH from "../../../../constants/routes";
import { getFounderDetails } from "../../functions";
import { getConversationWithID } from "../../../../utils/messaging-helper";
import { updateCurrentConversation } from "../../../Messaging/store/action-creators";
import BOTTOMLEFTIMG from "./bottomLeft1.svg";
import BOTTOMLEFTIMG2 from "./bottomLeft2.svg";
import TOPRIGHTIMG from "./topRight1.svg";
import TOPRIGHTIMG2 from "./topRight2.svg";
import SOSRING from "./Ring_BG.svg";
import SOSBOLT from "./BOLT_BG.svg";
import { UploadLogo } from "../../../../components/Profile/style";
import { addOrRemoveCompanyToPortfolioOrWatchlist } from "../../../Investor/Dashboard/Functions/common";

Chart.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend);

const BKGGraphic = ({ children }) => (
	<Box
		sx={{
			position: "relative",
			backgroundColor: "black",
			overflow: "hidden",
		}}
	>
		<Box sx={{ zIndex: 3, position: "relative" }}>{children}</Box>

		<img
			src={BOTTOMLEFTIMG}
			style={{
				position: "absolute",
				zIndex: 0,
				bottom: 0,
				left: "250px",
			}}
		/>
		<img
			src={BOTTOMLEFTIMG2}
			style={{
				position: "absolute",
				zIndex: 1,
				bottom: 0,
				left: 0,
			}}
		/>
		<img
			src={TOPRIGHTIMG}
			style={{
				position: "absolute",
				zIndex: 1,
				top: 0,
				right: 0,
			}}
		/>
		<img
			src={TOPRIGHTIMG2}
			style={{
				position: "absolute",
				zIndex: 0,
				top: 0,
				right: 0,
			}}
		/>
		<img
			src={SOSRING}
			style={{
				position: "absolute",
				zIndex: 2,
				top: 0,
				right: 0,
			}}
		/>
		<img
			src={SOSBOLT}
			style={{
				position: "absolute",
				zIndex: 2,
				top: 0,
				right: 0,
			}}
		/>
	</Box>
);

export default function StartupProfile({}) {
	const history = useHistory();
	const userData = useSelector((store) => store?.auth);
	const [addWatchlistOpen, setAddWatchlistOpen] = useState(false);
	const [removeWatchlistOpen, setRemoveWatchlistOpen] = useState(false);
	const [companySizeOpen, setCompanySizeOpen] = useState(false);
	const [seeAttachmentsOpen, setSeeAttachmentsOpen] = useState(false);
	const [displayIcons, setDisplayIcons] = useState(false);
	const [pitchdeck, setPitchdeck] = useState("");
	const investorDashboardData = useSelector((state) => state?.investorDashboard);
	const startup = useSelector((state) => state?.investorDashboard?.openStartupObj);
	const dispatch = useDispatch();

	const addCompanyToWatchlistOrPortfolioApiCall = (companyId, type) => {
		addOrRemoveCompanyToPortfolioOrWatchlist({ entityIds: [companyId], collectionType: type, isRemove: false })
			.then((result) => {
				const data = result?.data?.data;
				const tempStartupList = investorDashboardData?.startupList;

				tempStartupList.map((startupItem) => {
					if (startupItem.id === startup.id) {
						if (type === "PORTFOLIO") {
							startupItem.inPortfolio = true;
						} else {
							startupItem.inWatchlist = true;
						}
					}
				});
				dispatch(setInvestorDashboardData({ startupList: [...tempStartupList] }));
			})
			.catch((err) => console.log(err));
	};

	const removeCompanyToWatchlistOrPortfolioApiCall = (type, companyId) => {
		addOrRemoveCompanyToPortfolioOrWatchlist({ entityIds: [companyId], collectionType: type, isRemove: true })
			.then((result) => {
				const data = result?.data?.data;
				const tempStartupList = investorDashboardData?.startupList;

				tempStartupList.map((startupItem) => {
					if (startupItem.id === startup.id) {
						if (type === "PORTFOLIO") {
							startupItem.inPortfolio = false;
						} else {
							startupItem.inWatchlist = false;
						}
					}
				});
				dispatch(setInvestorDashboardData({ startupList: [...tempStartupList] }));
			})
			.catch((err) => console.log(err));
	};

	const handleUpdateWatchlistAction = (status) => {
		const tempStartupList = investorDashboardData?.startupList;

		tempStartupList.map((startupItem) => {
			if (startupItem.id === startup.id) {
				startupItem.inWatchlist = status;
			}
		});
		dispatch(setInvestorDashboardData({ startupList: [...tempStartupList] }));

		if (status) {
			addCompanyToWatchlistOrPortfolioApiCall(startup?.id, "WATCHLIST");
			setAddWatchlistOpen(false);
		} else {
			removeCompanyToWatchlistOrPortfolioApiCall("WATCHLIST", startup?.id);
			setRemoveWatchlistOpen(false);
		}
	};

	const connectClick = () => {
		if (startup.id) {
			history.push({
				pathname: generatePath(`${ROUTES_PATH.STARTUP_PROFILE}/:id?`, {
					id: startup.id,
				}),
			});
		} else {
			console.error("No id found in startup data: ", startup.id, startup);
		}
	};

	const getSelectedIndustryFormatted = (input) => {
		if (input) {
			try {
				let inputParsed = JSON.parse(input);
				if (Array.isArray(inputParsed)) {
					return inputParsed;
				} else {
					return [inputParsed];
				}
			} catch (error) {
				return [];
			}
		} else {
			return [];
		}
	};

	const downloadPitchdeck = () => {
		let a = document.createElement("a");
		a.href = pitchdeck;
		a.download = "Startup Pitchdeck";
		a.click();
	};

	const handleReqMeeting = async () => {
		let founder = await getFounderDetails(startup?.id);
		if (founder) {
			dispatch(
				setCalUser({
					id: founder?.calUserId,
					name: founder?.name,
					email: founder?.email,
					picture: founder?.picture,
				})
			);

			history.push(ROUTES_PATH.MEETING_TYPES_SCHEDULE);
		}
	};

	const handleMessaging = async () => {
		let founder = await getFounderDetails(startup?.id);
		if (founder) {
			try {
				let conversationID = await getConversationWithID({
					idArray: [founder?.id],
				});
				dispatch(updateCurrentConversation(conversationID));
				history.push("/messages");
			} catch (e) {
				console.log("Could not retrieve conversation.", e);
			}
		}
	};

	return (
		<Fragment>
			<BKGGraphic>
				<Box
					sx={{
						display: "flex",
						py: {
							lg: 9,
							md: 6,
							xs: 3,
						},
					}}
				>
					<Box
						sx={{
							display: "flex",
							flex: 1,
							flexDirection: "column",
							background: "rgba(255, 255, 255, 0.93)",
							borderRadius: "0px 20px 20px 0px",
							pb: {
								lg: 4,
								md: 3,
								xs: 2,
							},
							pr: {
								lg: 4,
								md: 3,
								xs: 2,
							},
							pl: {
								lg: 9,
								md: 6,
								xs: 3,
							},
							pt: {
								lg: 4,
								md: 3,
								xs: 2,
							},
						}}
					>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
							}}
						>
							<Box sx={{ display: "flex", justifyContent: "space-between" }}>
								<Box>
									<Box sx={{ display: "flex", alignItems: "center", gap: 2, mb: 3 }}>
										{startup?.logoUrl ? (
											<Avatar
												alt={startup?.companyName}
												src={startup?.logoUrl}
												sx={{
													height: "66px",
													width: "66px",
												}}
											/>
										) : (
											<UploadLogo borderWidth="6px" variant="main_title" id={startup?.companyName} width={66} height={66}>
												{startup?.companyName?.charAt(0)?.toUpperCase()}
											</UploadLogo>
										)}
										<Box>
											<Typography variant="main_title">{startup?.companyName ? startup?.companyName : "-"}</Typography>
										</Box>

										<Box
											sx={{
												display: "flex",
												alignItems: "center",
												gap: 2,
											}}
										>
											{startup?.websiteLink && (
												<Fragment>
													<IconButton
														disableRipple
														sx={{ color: "black", p: 0 }}
														size="medium"
														onClick={() => {
															window.open(formatUrl(startup?.websiteLink), "_blank");
														}}
													>
														<PublicIcon fontSize="inherit" />
													</IconButton>
													<Box sx={{ height: "30px", border: "1px solid gray" }}></Box>
												</Fragment>
											)}
											<IconButton disableRipple sx={{ color: "black", p: 0 }} size="medium" onClick={handleMessaging}>
												<SmsOutlined fontSize="inherit" />
											</IconButton>
											<IconButton disableRipple sx={{ color: "black", p: 0 }} size="medium" onClick={handleReqMeeting}>
												<EventAvailableOutlined fontSize="inherit" />
											</IconButton>
										</Box>
									</Box>
									<Typography
										variant="customValue"
										sx={{
											overflow: "hidden",
											textOverFlow: "ellipsis",
											display: "-webkit-box",
											WebkitLineClamp: "1",
											WebkitBoxOrient: "vertical",
											fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
											fontWeight: "500",
											fontSize: "25px",
											lineHeight: "38px",
											mb: 1,
										}}
									>
										{startup?.tagLine ? startup?.tagLine : "-"}
									</Typography>
									<Box sx={{ display: "flex", alignItems: "center" }}>
										<Box component={"img"} src={LOCATION_ICON} alt="location" sx={{ width: "20px", height: "20px", marginRight: "3px" }} />
										<Typography
											variant="customValue"
											sx={{
												fontFamily: '"PoppinsRegular", "Helvetica", "Arial", sans-serif',
												fontWeight: "500",
												fontSize: "18px",
												lineHeight: "27px",
											}}
										>
											{startup?.city ? startup?.city : "-"}, {startup?.state ? startup?.state : "-"}
										</Typography>
									</Box>
								</Box>
								{/* <Box sx={{ display: "flex", justifyContent: "flex-end", maxHeight: "119px" }}>
									<Button
										sx={{
											width: "114px",
											height: "41px",
											borderRadius: "21px",
											display: "flex",
											flexDirection: "row",
											alignItems: "flex-start",
											padding: "10px 20px",
											gap: "10px",
											background: "#003149",
											fontFamily: '"PoppinsSemiBold", "Helvetica", "Arial", sans-serif',
											fontWeight: "500",
											fontSize: "14px",
											lineHeight: "21px",
											marginRight: "39px",
										}}
									>
										<b>SVB</b>
										{/* &thinsp;Client */}
								{/* &nbsp; */}
								{/* </Button>
								</Box> */}
							</Box>
						</Box>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "space-around",
								rowGap: {
									lg: 3,
									xs: 2,
								},
								my: {
									lg: 4,
									md: 3,
									xs: 2,
								},
							}}
						>
							<Box sx={{ display: "flex" }}>
								<CompanyDetail heading={"Year Founded"} value={startup?.foundedYear ? startup?.foundedYear : "-"} />
								<CompanyDetail heading={"Revenue"} value={startup?.revenue ? startup?.revenue : "-"} />
								<CompanyDetail heading={"Current Stage"} value={startup?.currentStage ? startup?.currentStage : "-"} />
							</Box>
							<Box sx={{ display: "flex" }}>
								{/* Industry Company Detail */}
								<Box
									sx={{
										height: "62px",
										width: "33.3333%",
									}}
								>
									<Box
										sx={{
											color: "rgba(135, 135, 135, 1)",
											fontFamily: '"PoppinsSemiBold", "Helvetica", "Arial", sans-serif',
											fontWeight: "600",
											fontSize: "16px",
											lineHeight: "24px",
										}}
									>
										Industry
									</Box>
									{startup?.industry ? (
										<Typography
											variant="customValue"
											sx={{
												fontFamily: '"PoppinsSemiBold", "Helvetica", "Arial", sans-serif',
												fontWeight: "600",
												fontSize: "30px",
												lineHeight: "45px",
											}}
										>
											{getSelectedIndustryFormatted(startup?.industry)[0]?.value}
										</Typography>
									) : (
										<Typography
											variant="customValue"
											sx={{
												fontFamily: '"PoppinsSemiBold", "Helvetica", "Arial", sans-serif',
												fontWeight: "600",
												fontSize: "30px",
												lineHeight: "45px",
											}}
										>
											-
										</Typography>
									)}
								</Box>
								<CompanyDetail
									heading={"Financing to Date"}
									value={
										startup?.capitalRaised
											? "$" +
											  Intl.NumberFormat("en-US", {
													notation: "compact",
													maximumFractionDigits: 1,
											  }).format(Number(startup?.capitalRaised))
											: "-"
									}
								/>
								<CompanyDetail heading={"Seeking"} value={startup?.seeking ? startup?.seeking : "-"} />
							</Box>
							<Box sx={{ display: "flex" }}>
								<Box
									sx={{
										height: "62px",
										width: "33.3333%",
										"&:hover": {
											cursor: "pointer",
										},
									}}
									onClick={() => {
										setCompanySizeOpen(true);
									}}
								>
									<Box
										sx={{
											color: "rgba(135, 135, 135, 1)",
											fontFamily: '"PoppinsSemiBold", "Helvetica", "Arial", sans-serif',
											fontWeight: "600",
											fontSize: "16px",
											lineHeight: "24px",
										}}
									>
										Company Size
									</Box>
									<Box
										sx={{
											fontFamily: '"PoppinsSemiBold", "Helvetica", "Arial", sans-serif',
											fontWeight: "600",
											fontSize: "30px",
											lineHeight: "45px",
										}}
									>
										{startup?.employeeCount ? startup?.employeeCount : "-"}
									</Box>
								</Box>
								<CompanyDetail heading={"Current Financing"} value={startup?.financing ? startup?.financing : "-"} />
								<CompanyDetail
									heading={"Ask"}
									value={
										startup?.ask
											? "$" +
											  Intl.NumberFormat("en-US", {
													notation: "compact",
													maximumFractionDigits: 1,
											  }).format(Number(startup?.ask))
											: "-"
									}
								/>
							</Box>
						</Box>
						<Box
							sx={{
								mt: "auto",
								display: "flex",
								justifyContent: "flex-end",
								alignItems: "center",
							}}
						>
							{/* icons bar */}
							<Box sx={{ display: pitchdeck ? "block" : "none" }}>
								<Box
									sx={{
										display: "flex",
										alignItems: "center",
										"&:hover": {
											cursor: "pointer",
										},
									}}
									onClick={() => {
										downloadPitchdeck();
									}}
								>
									<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
										<Box sx={{ width: "35px", height: "25.16px", margin: "9.74px" }} component={"img"} src={BINOCULARS_ICON} />
										<AddIconComp className="hovered" isActive={startup?.inWatchlist}>
											{startup.inWatchlist ? (
												<DoneIcon
													sx={{ background: startup.inWatchlist && ((theme) => theme.palette.primary.primaryPurple) }}
													className="add-icon-hover"
													fontSize="12px"
												/>
											) : (
												<AddIcon className="add-icon-hover" fontSize="12px" />
											)}
										</AddIconComp>
									</Box>
									<Box
										sx={{
											fontFamily: '"PoppinsSemiBold", "Helvetica", "Arial", sans-serif',
											fontWeight: "600",
											fontSize: "18px",
											lineHeight: "24px",
											marginRight: "10px",
										}}
									>
										Download pitch
									</Box>
								</Box>
							</Box>
							<Box
								sx={{
									display: "flex",
									alignItems: "center",
									"&:hover": {
										cursor: "pointer",
									},
								}}
								onClick={() => {
									startup.inWatchlist ? setRemoveWatchlistOpen(true) : setAddWatchlistOpen(true);
								}}
							>
								<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
									<Box sx={{ width: "35px", height: "25.16px", margin: "9.74px" }} component={"img"} src={BINOCULARS_ICON} />
									<AddIconComp className="hovered" isActive={startup?.inWatchlist}>
										{startup.inWatchlist ? (
											<DoneIcon
												sx={{ background: startup.inWatchlist && ((theme) => theme.palette.primary.primaryPurple) }}
												className="add-icon-hover"
												fontSize="12px"
											/>
										) : (
											<AddIcon className="add-icon-hover" fontSize="12px" />
										)}
									</AddIconComp>
								</Box>
								<Box
									sx={{
										fontFamily: '"PoppinsSemiBold", "Helvetica", "Arial", sans-serif',
										fontWeight: "600",
										fontSize: "18px",
										lineHeight: "24px",
										marginRight: "10px",
									}}
								>
									Add to Watchlist
								</Box>
							</Box>
							<Box sx={{ display: displayIcons ? "block" : "none" }}>
								<Box
									sx={{
										display: "flex",
										alignItems: "center",
									}}
									onClick={() => {
										setSeeAttachmentsOpen(true);
									}}
								>
									<Box sx={{ width: "25.08px", height: "29.17px", margin: "9.74px" }} component={"img"} src={PAPERCLIP_ICON} />
									<Box
										sx={{
											fontFamily: '"PoppinsSemiBold", "Helvetica", "Arial", sans-serif',
											fontWeight: "600",
											fontSize: "18px",
											lineHeight: "24px",
											// marginRight: "40px", // { /* RIGHT HERE */}
										}}
									>
										See Attachments
									</Box>
								</Box>
							</Box>
						</Box>
					</Box>

					<Box
						sx={{
							width: "30%",
							display: "flex",
							flexDirection: "column",
							justifyContent: "flex-end",
							alignItems: "center",
						}}
					>
						<Box
							sx={{
								color: "rgba(255, 255, 255, 1)",
								fontFamily: '"PoppinsSemiBold", "Helvetica", "Arial", sans-serif',
								fontWeight: "600",
								fontSize: "35px",
								lineHeight: "75px",
							}}
						>
							Startup Profile
						</Box>
					</Box>
				</Box>
			</BKGGraphic>

			{/* Add Startup to Watchlist modal */}
			<CommonSlideDialog
				disableBackDropClick={true}
				title={"Watchlist"}
				titleVariant={"card_name"}
				isOpen={addWatchlistOpen}
				onClose={() => {
					setAddWatchlistOpen(false);
				}}
				contentStyle={{ px: 4 }}
				maxWidth={"xs"}
				transitionDuration={600}
				fullWidth
				PaperProps={{
					sx: (theme) => ({
						borderRadius: "30px",
						p: 3,
						ml: "auto",
						mr: 0,
						mb: 0,
						alignSelf: "end",
					}),
				}}
				dialogAction={
					<Box display={"flex"} gap={2} sx={{ pr: 2 }} alignItems="center">
						<Button
							variant="noOutlineNew"
							onClick={() => {
								setAddWatchlistOpen(false);
							}}
						>
							Cancel
						</Button>
						<Button variant="containedNewStyle" onClick={() => handleUpdateWatchlistAction(true)}>
							Add
						</Button>
					</Box>
				}
			>
				<Box sx={{ pt: 2 }}>
					<Typography variant="on_surface">Confirmation</Typography>
				</Box>
				<Box sx={{ mt: 1 }}>
					<Typography variant="sub_title_portfolio">
						{" "}
						Please confirm that you have invested in this startup, and would like to add it to you watchlist.
					</Typography>
				</Box>
			</CommonSlideDialog>

			{/* Remove Startup from Watchlist modal */}
			<CommonSlideDialog
				disableBackDropClick={true}
				title={"Watchlist"}
				titleVariant={"card_name"}
				isOpen={removeWatchlistOpen}
				onClose={() => {
					setRemoveWatchlistOpen(false);
				}}
				contentStyle={{ px: 4 }}
				maxWidth={"xs"}
				transitionDuration={600}
				fullWidth
				PaperProps={{
					sx: (theme) => ({
						borderRadius: "30px",
						p: 3,
						ml: "auto",
						mr: 0,
						mb: 0,
						alignSelf: "end",
					}),
				}}
				dialogAction={
					<Box display={"flex"} gap={2} sx={{ pr: 2 }} alignItems="center">
						<Button
							variant="noOutlineNew"
							onClick={() => {
								setRemoveWatchlistOpen(false);
							}}
						>
							Cancel
						</Button>
						<Button variant="containedNewStyle" onClick={() => handleUpdateWatchlistAction(false)}>
							Remove
						</Button>
					</Box>
				}
			>
				<Box sx={{ pt: 2 }}>
					<Typography variant="on_surface">Removal Confirmation</Typography>
				</Box>
				<Box sx={{ mt: 1 }}>
					<Typography variant="sub_title_portfolio">Please confirm that you are removing this startup from your watchlist. </Typography>
				</Box>
			</CommonSlideDialog>

			{/* Company Size modal */}
			<CommonSlideDialog
				disableBackDropClick={true}
				title={"Employees"}
				titleVariant={"card_name"}
				isOpen={companySizeOpen}
				onClose={() => {
					setCompanySizeOpen(false);
				}}
				maxWidth={"xs"}
				transitionDuration={600}
				fullWidth
				PaperProps={{
					sx: (theme) => ({
						borderRadius: "30px",
						p: 3,
						ml: "auto",
						mr: 0,
						mb: 0,
						alignSelf: "end",
					}),
				}}
				dialogAction={
					<Box display={"flex"} mt="12px" pr={2}>
						<Button variant="containedNewStyle" onClick={() => setCompanySizeOpen(false)}>
							Close
						</Button>
					</Box>
				}
			>
				<Box>
					<Box display={"flex"} gap={2}>
						<Box display="flex" flexDirection="column">
							<Typography variant="subhead3" sx={{ fontWeight: 600 }}>
								Total Headcount
							</Typography>
							<Box display={"flex"} gap="10px">
								<Typography variant="card_title" sx={{ fontWeight: 600 }}>
									{startup?.employeeCount}
								</Typography>
							</Box>
						</Box>
						<Box display="flex" flexDirection="column">
							<Typography variant="subhead3" sx={{ fontWeight: 600 }}>
								Change
							</Typography>
							<Box display={"flex"} alignItems={"end"}>
								<Typography variant="card_title" sx={{ fontWeight: 600 }}>
									50%
								</Typography>
							</Box>
						</Box>
					</Box>
					<Box textAlign={"center"}>
						<Box>
							<Line
								data={{
									labels: ["jan", "feb", "mar", "apr", "may"],
									datasets: [
										{
											label: "Dataset 1",
											data: [2, 4, 5, 8, 9],
											borderColor: "rgba(123, 97, 255, 0.3)",
											backgroundColor: "rgba(123, 97, 255, 0.3)",
											fill: true,
										},
										{
											label: "Dataset 1",
											data: [3, 5, 6, 9, 10],
											borderColor: "#000",
											backgroundColor: "#000",
											borderDash: [5, 5],
										},
									],
								}}
								options={{
									maintainAspectRatio: false,
									plugins: {
										legend: {
											display: false,
										},
										datalabels: {
											display: false,
										},
										tooltip: {
											enabled: false,
										},
									},
									scales: {
										x: {
											ticks: {
												display: false,
											},
											grid: {
												drawBorder: false,
												display: false,
											},
										},
										y: {
											ticks: {
												display: false,
												beginAtZero: true,
											},
											grid: {
												drawBorder: false,
												display: false,
											},
										},
									},
									elements: {
										point: {
											radius: 0,
										},
									},
								}}
							/>
						</Box>
						<Typography variant="subhead3" sx={{ fontWeight: 600 }}>
							180 days
						</Typography>
					</Box>
				</Box>
			</CommonSlideDialog>

			{/* See Attachments modal */}
			<CommonSlideDialog
				disableBackDropClick={true}
				title={"See Attachments"}
				titleVariant={"card_name"}
				isOpen={seeAttachmentsOpen}
				onClose={() => {
					setSeeAttachmentsOpen(false);
				}}
				contentStyle={{ px: 4 }}
				maxWidth={"xs"}
				transitionDuration={600}
				fullWidth
				PaperProps={{
					sx: (theme) => ({
						borderRadius: "30px",
						p: 3,
						ml: "auto",
						mr: 0,
						mb: 0,
						alignSelf: "end",
					}),
				}}
				dialogAction={
					<Box display={"flex"} gap={2} sx={{ pr: 2 }} alignItems="center">
						<Button
							variant="noOutlineNew"
							onClick={() => {
								setSeeAttachmentsOpen(false);
							}}
						>
							Cancel
						</Button>
						<Button variant="containedNewStyle" onClick={() => handleUpdateWatchlistAction(true)}>
							Add
						</Button>
					</Box>
				}
			>
				<Box sx={{ pt: 2 }}>
					<Typography variant="on_surface">Confirmation</Typography>
				</Box>
				<Box sx={{ mt: 1 }}>
					<Typography variant="sub_title_portfolio"> Attachments and Links[]</Typography>
				</Box>
			</CommonSlideDialog>
		</Fragment>
	);
}
