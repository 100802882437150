import { Box, List, ListItem } from "@mui/material";
import React, { useEffect } from "react";
import { IconButton } from "@mui/material";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import theme from "../../../constants/Theme";

const ListViewButton = props => (
  <IconButton
    onClick={props.onClick}
    color={props.color}
    sx={{
      border: `1px solid ${theme.palette.secondary.main}`,
      margin: 1,
    }}
  >
    {props.children}
  </IconButton>
);

export default function GridListView({ view, setView }) {
  const handleOnChange = value => {
    setView(value);
  };

  return (
    <Box textAlign={"right"}>
      {view === "GRID" ? (
        <ListViewButton
          selected={view === "GRID"}
          onClick={() => handleOnChange("LIST")}
        >
          <GridViewRoundedIcon
            color="secondary"
            sx={{ fill: theme.palette.secondary.main }}
          />
        </ListViewButton>
      ) : (
        <ListViewButton
          selected={view === "LIST"}
          onClick={() => handleOnChange("GRID")}
        >
          <FormatListBulletedIcon color="secondary" />
        </ListViewButton>
      )}
    </Box>
  );
}
