import styled from "@emotion/styled";
import { Select } from "@mui/material";

export const StyledSelect = styled(Select)(({ theme, height, width, error }) => ({
	width: width,
	height: height,
	borderRadius: "30px",
	background: "white",
	paddingLeft: "8px",
	"& .MuiOutlinedInput-notchedOutline": {
		fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
		fontSize: "16px",
		lineHeight: "24px",
		borderRadius: "30px",
		// background: "white",
		/* Shadow/xs focused 4px gray-100 */
		// boxShadow: "0px 0px 0px 4px #F2F4F7, 0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
		border: "1px solid #DDDDDD",
		borderColor: error && "red",
	},
	"& .MuiMenu-root ": {
		borderRadius: "30px",
	},
}));
