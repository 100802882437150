export const steps = [
  {
    label: "My Role",
    complete: false, // Needs to be false for prod
  },
  {
    label: "Why StartupOS?",
    complete: false, // Needs to be false for prod
  },
  {
    label: "Tell us about yourself",
    complete: false,
  },
  {
    label: "Founder Superpower",
    complete: false,
  },
  {
    label: "Background",
    complete: false,
  },
  {
    label: "Startup Information",
    complete: false,
  },
  {
    label: "Funding",
    complete: false,
  },
];
