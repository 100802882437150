import React, { Fragment, useContext, useEffect, useState } from "react";
import { Box, Divider, Skeleton, Typography } from "@mui/material";
import StartupBioTags from "./StartupBioTags";
import StartupFinanceTags from "./StartupFinanceTags";
import StartupFundingTags from "./StartupFundingTags";
import { ProfileContext } from "../../..";

function StartupBio() {
	const { profileDataLoading } = useContext(ProfileContext);
	const [loading, setloading] = useState(profileDataLoading);

	useEffect(() => {
		setloading(profileDataLoading);
	}, [profileDataLoading]);

	return (
		<Fragment>
			<Box>{loading ? <Skeleton animation="wave" variant="rounded" sx={{ height: "120px", width: 1 }} /> : <StartupBioTags />}</Box>
			<Divider
				sx={{
					color: "#D0D5DD",
					my: 3,
				}}
			/>
			{loading ? (
				<Skeleton animation="wave" variant="text" sx={{ fontSize: "40px" }} />
			) : (
				<Typography
					component={"div"}
					variant="customValue"
					sx={{
						color: "black",
						fontFamily: "PoppinsSemiBold",
						fontSize: "20px",
						lineHeight: "30px",
						mb: "12px",
					}}
				>
					Financial
				</Typography>
			)}
			<Box>{loading ? <Skeleton animation="wave" variant="rounded" sx={{ height: "120px", width: 1 }} /> : <StartupFinanceTags />}</Box>
			<Divider
				sx={{
					color: "#D0D5DD",
					my: 3,
				}}
			/>
			{loading ? (
				<Skeleton animation="wave" variant="text" sx={{ fontSize: "40px" }} />
			) : (
				<Typography
					component={"div"}
					variant="customValue"
					sx={{
						color: "black",
						fontFamily: "PoppinsSemiBold",
						fontSize: "20px",
						lineHeight: "30px",
						mb: "12px",
					}}
				>
					Funding
				</Typography>
			)}
			<Box>{loading ? <Skeleton animation="wave" variant="rounded" sx={{ height: "120px", width: 1 }} /> : <StartupFundingTags />}</Box>
		</Fragment>
	);
}

export default StartupBio;
