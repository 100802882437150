import * as React from "react";
import { Box, TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";

const StartEndAdornmentField = ({
  startIcon,
  endIcon,
  label,
  value,
  onChange,
  EndIconAction,
  error,
  onBlur,
}) => {
  return (
    <Box>
      <TextField
        id="input-with-icon-textfield"
        label={label}
        spellCheck="true"
        sx={{ display: "flex" }}
        error={error}
        helperText={error}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">{startIcon}</InputAdornment>
          ),
          endAdornment: (
            <InputAdornment
              position="end"
              onClick={EndIconAction}
              sx={{ cursor: "pointer" }}
            >
              {endIcon}
            </InputAdornment>
          ),
        }}
        onChange={onChange}
        onBlur={onBlur}
        variant="outlined"
        value={value}
      />
    </Box>
  );
};

export default StartEndAdornmentField;
