import React, { useEffect, useState } from "react";
import { Box, Card, Typography } from "@mui/material";
import { formatNumberToString } from "./functions";
import { Info } from "@mui/icons-material";

function TSSText({ TSSData }) {
	const [TSS, setTSS] = useState(TSSData);

	useEffect(() => {
		setTSS(TSSData);
	}, [TSSData]);

	//{
	//	"TAM": {
	//		"tamAcv": 25000,
	//		"tamNoOfPeople": 60000,
	//		"tamTimesPerYear": 1,
	//		"calculatedTam": 1500000000
	//	},
	//	"SAM": {
	//		"focusOn": "Accredited Investors",
	//		"samPercentageOfPopulation": 90,
	//		"furtherFocusOn": "Venture Capitalists",
	//		"basedIn": "based in the United States",
	//		"percentageOfSam": 50,
	//		"calculatedSam": 675000000
	//	},
	//	"SOM": {
	//		"noFocusOn": "Corporate Venture Capitalists",
	//		"percentageOfSam": 15,
	//		"calculatedSom": 573750000
	//	},
	//	"GROWTH": {
	//		"largerMarket": "Pre-Seed and Seed investors",
	//		"largerMarketValue": "1000000",
	//		"projectedGrowth": "25"
	//	}
	//}
	return (
		<Box
			sx={{
				flex: 1,
				display: "flex",
				flexDirection: "column",
				gap: 3,
				minWidth: "300px",
			}}
		>
			{TSS && TSS?.TAM && (
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
					}}
				>
					<Box
						sx={{
							display: "flex",
							gap: 1,
						}}
					>
						<Typography
							variant="customValue"
							sx={{
								fontFamily: "PoppinsBold",
								fontSize: "40px",
								lineHeight: "40px",
							}}
						>
							TAM
						</Typography>
						<Typography
							variant="customValue"
							sx={{
								fontFamily: "PoppinsSemiBold",
								fontSize: "16px",
								lineHeight: "24px",
								alignSelf: "flex-end",
							}}
						>
							(Total Addressable Market)
						</Typography>
					</Box>
					<Box>
						<Typography
							variant="customValue"
							sx={{
								fontFamily: "PoppinsMedium",
								fontSize: "16px",
								lineHeight: "24px",
							}}
						>
							<strong>Founder Comment:</strong> My estimated Annual Contract Value (ACV) is {formatNumberToString(TSS?.TAM?.tamAcv)}. There are{" "}
							{TSS?.TAM?.tamNoOfPeople ? TSS?.TAM?.tamNoOfPeople : "N/A"} people who will buy a product / service in my market an average of{" "}
							{TSS?.TAM?.tamTimesPerYear ? TSS?.TAM?.tamTimesPerYear : "N/A"} times per year.
						</Typography>
					</Box>
				</Box>
			)}

			{TSS && TSS?.SAM && (
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
					}}
				>
					<Box
						sx={{
							display: "flex",
							gap: 1,
						}}
					>
						<Typography
							variant="customValue"
							sx={{
								fontFamily: "PoppinsBold",
								fontSize: "40px",
								lineHeight: "40px",
							}}
						>
							SAM
						</Typography>
						<Typography
							variant="customValue"
							sx={{
								fontFamily: "PoppinsSemiBold",
								fontSize: "16px",
								lineHeight: "24px",
								alignSelf: "flex-end",
							}}
						>
							(Service Addressable Market)
						</Typography>
					</Box>
					<Box>
						<Typography
							variant="customValue"
							sx={{
								fontFamily: "PoppinsMedium",
								fontSize: "16px",
								lineHeight: "24px",
							}}
						>
							<strong>Founder Comment:</strong> Of the population, I will focus on {TSS?.SAM?.focusOn ? TSS?.SAM?.focusOn : "N/A"} who make up{" "}
							{TSS?.SAM?.samPercentageOfPopulation ? TSS?.SAM?.samPercentageOfPopulation + "%" : "N/A"} of the population. Furthermore, I will
							only focus on {TSS?.SAM?.furtherFocusOn ? TSS?.SAM?.furtherFocusOn : "N/A"} who are {TSS?.SAM?.basedIn ? TSS?.SAM?.basedIn : "N/A"}{" "}
							who make up {TSS?.SAM?.percentageOfSam ? TSS?.SAM?.percentageOfSam + "%" : "N/A"} of the SAM.
						</Typography>
					</Box>
				</Box>
			)}

			{TSS && TSS?.SOM && (
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
					}}
				>
					<Box
						sx={{
							display: "flex",
							gap: 1,
						}}
					>
						<Typography
							variant="customValue"
							sx={{
								fontFamily: "PoppinsBold",
								fontSize: "40px",
								lineHeight: "40px",
							}}
						>
							SOM
						</Typography>
						<Typography
							variant="customValue"
							sx={{
								fontFamily: "PoppinsSemiBold",
								fontSize: "16px",
								lineHeight: "24px",
								alignSelf: "flex-end",
							}}
						>
							(Service Obtainable Market)
						</Typography>
					</Box>
					<Box>
						<Typography
							variant="customValue"
							sx={{
								fontFamily: "PoppinsMedium",
								fontSize: "16px",
								lineHeight: "24px",
							}}
						>
							<strong>Founder Comment:</strong> Due to market conditions, competition, and scaling challenges, I will not focus on{" "}
							{TSS?.SOM?.noFocusOn ? TSS?.SOM?.noFocusOn : "N/A"}, which excludes{" "}
							{TSS?.SOM?.percentageOfSam ? TSS?.SOM?.percentageOfSam + "%" : "N/A"} of the SAM.
						</Typography>
					</Box>
				</Box>
			)}

			{!TSS?.TAM && !TSS?.SAM && !TSS?.SOM && (
				<Card
					sx={{
						borderRadius: "40px",
						p: {
							xs: 2,
							md: 3,
							lg: 4,
						},
						boxShadow: "0px 10px 15px rgba(0, 0, 0, 0.05)",
						display: "flex",
						gap: 2,
					}}
				>
					<Box>
						<Info
							sx={{
								color: (theme) => theme.palette.primary.main,
							}}
						/>
					</Box>
					<Box>
						<Typography color="black" variant="poppinsMedium16" component={"p"}>
							This startup has not completed their TAM SAM SOM PowerUP at this time. We will continue to check for updates.
						</Typography>
					</Box>
				</Card>
			)}

			{TSS && TSS?.GROWTH && (
				<Box>
					<Typography
						variant="customValue"
						sx={{
							fontFamily: "PoppinsMedium",
							fontSize: "16px",
							lineHeight: "24px",
						}}
					>
						The larger market, {TSS?.GROWTH?.largerMarket ? TSS?.GROWTH?.largerMarket : "N/A"} is currently worth{" "}
						{formatNumberToString(TSS?.GROWTH?.largerMarketValue)} and it is projected to grow by{" "}
						{TSS?.GROWTH?.projectedGrowth ? TSS?.GROWTH?.projectedGrowth + "%" : "N/A"} over the next 5 years.
					</Typography>
				</Box>
			)}
		</Box>
	);
}

export default TSSText;
