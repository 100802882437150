import React, { useState, useEffect } from "react";
import { Box, Typography, Grid, Divider, TextField } from "@mui/material";
import messages from "../../../../../../../constants/messages";
import theme from "../../../../../../../constants/Theme";
import PowerupRecapTab from "../../../../../../../components/Workspace/PowerUp/RecapTab";
import { REGEX } from "../../../../../../../constants/regex";

export default function BrandStory({
  userInputs,
  setUserInputs,
  recapContent,
  powerUpExcercises,
}) {
  const [errorValuesMessage, setValuesErrorMessage] = useState("");
  const [errorMissionMessage, setMissionErrorMessage] = useState("");
  const [recapData, setRecapData] = useState("");

  const handleInputChange = (e, key) => {
    let value = e.target.value.trimStart();
    const currentInput = { ...userInputs };
    currentInput[key] = value;
    setUserInputs(currentInput);
    handleValidationCheck(key, value);
  };

  const inputValid = input => {
    if (!input.match(REGEX.INPUTBOX_300)) {
      return false;
    } else {
      return true;
    }
  };

  const handleValidationCheck = (key, value) => {
    if (inputValid(value)) {
      setMissionErrorMessage("");
      setValuesErrorMessage("");
    } else {
      if (key == "brandMission")
        setMissionErrorMessage(messages.TEXTBOX_VALIDATION);
      else setValuesErrorMessage(messages.TEXTBOX_VALIDATION);
    }
  };

  useEffect(() => {
    if (userInputs?.length) {
      setMissionErrorMessage("");
      setValuesErrorMessage("");
    }
  }, [userInputs]);

  useEffect(() => {
    const data = powerUpExcercises.filter(obj => {
      return obj.stepNo === 1;
    });
    if (data.length) {
      setRecapData(data[0]);
    }
  }, [powerUpExcercises]);

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} sx={{ mt: 4 }}>
          <Typography variant="subhead1">Brand Mission</Typography>
        </Grid>

        <Grid item xs={12} md={12}>
          <Typography variant="subhead2">
            <b>As best you can, describe your mission.</b> A mission statement
            is a short summary of your purpose. State what you’re offering, who
            you’re selling to, and even why you do it.
          </Typography>
        </Grid>

        <Grid item xs={12} md={12}>
          <TextField
            fullWidth
            spellCheck="true"
            multiline
            minRows={2}
            value={userInputs?.brandMission}
            onChange={e => handleInputChange(e, "brandMission")}
            error={!!errorMissionMessage}
            helperText={errorMissionMessage}
            sx={{
              [theme.breakpoints.down("md")]: {
                "& .MuiOutlinedInput-root": {
                  backgroundColor: "white",
                },
              },
            }}
          />
        </Grid>

        <Grid item xs={12} md={12} sx={{ mt: 4 }}>
          <Typography variant="subhead1">Brand Values</Typography>
        </Grid>

        <Grid item xs={12} md={12}>
          <Typography variant="subhead2">
            <b>As best you can, describe your values.</b> Think bigger than your
            products or services. What motivates you? Which principles should
            guide your actions – big or small? Your values should have special
            meaning to your employees and leadership.
          </Typography>
        </Grid>

        <Grid item xs={12} md={12}>
          <TextField
            fullWidth
            multiline
            spellCheck="true"
            minRows={2}
            value={userInputs?.brandValues}
            onChange={e => handleInputChange(e, "brandValues")}
            error={!!errorValuesMessage}
            helperText={errorValuesMessage}
            sx={{
              [theme.breakpoints.down("md")]: {
                "& .MuiOutlinedInput-root": {
                  backgroundColor: "white",
                },
              },
            }}
          />
        </Grid>

        <Grid item xs={12} md={12} sx={{ mt: 2 }}>
          <Divider />
          <Box>
            <Box sx={{ mt: 3, mb: 3 }}>
              <Typography variant="subhead1" color="text.primary">
                Here’s a recap of what you just brainstormed to inspire building
                your brand above.
              </Typography>
            </Box>
            {recapData && (
              <PowerupRecapTab
                valuePropContent={recapContent}
                powerUpExcercisesData={recapData}
                option={2}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
