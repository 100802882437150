import React, { useContext } from "react";
import { Box, Grid, Stack, Typography } from "@mui/material";
import UpdatedSelectField from "../../../common/UpdatedSelectField";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { EXPERTISE_OPTIONS } from "../../../constants/mentor-data";
import { INDUSTRY_TYPES } from "../../../constants";

const TagsAboutMeSection = ({ context }) => {
	const getJasonFormatedValue = (value) => {
		try {
			if (value) {
				return JSON.parse(value);
			}
		} catch (e) {
			if (value) {
				return value.split(",");
			}
		}
	};
	const { payLoad, setPayLoad } = useContext(context);
	const expertise = getJasonFormatedValue(payLoad?.expertise) ? getJasonFormatedValue(payLoad?.expertise) : [];
	const industries = getJasonFormatedValue(payLoad?.industries) ? getJasonFormatedValue(payLoad?.industries) : [];
	const productOffering = getJasonFormatedValue(payLoad?.productOffering) ? getJasonFormatedValue(payLoad?.productOffering) : [];

	function handleChange(event, key) {
		let newValue = event.target.value.length > 0 ? JSON.stringify(event.target.value) : null;
		payLoad[key] = newValue;
		setPayLoad({ ...payLoad });
	}

	function handleDelete(value, key) {
		let tempArray = getJasonFormatedValue(payLoad[key]);
		let filteredArray = tempArray.filter((item) => item !== value);
		if (filteredArray.length > 0) {
			payLoad[key] = JSON.stringify(tempArray.filter((item) => item !== value));
		} else {
			payLoad[key] = null;
		}

		setPayLoad({ ...payLoad });
	}
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				rowGap: "60px",
			}}
		>
			<Box>
				<Typography variant="poppinsSemiBold20">Tags About Me</Typography>
			</Box>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					gap: 3,
				}}
			>
				<Grid container rowSpacing={4} columnSpacing={7}>
					<Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
						<UpdatedSelectField
							id={"expertise"}
							lable={"My Expertise"}
							placeholder={"My Expertise"}
							options={EXPERTISE_OPTIONS}
							handleChange={handleChange}
							handleDelete={handleDelete}
							inputValue={expertise}
							selectProps={{
								multiple: true,
								fullWidth: true,
								IconComponent: KeyboardArrowDownRoundedIcon,
								height: "60px",
								width: "100%",
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
						<UpdatedSelectField
							id={"industries"}
							lable={"Industry"}
							placeholder={"Industry"}
							options={INDUSTRY_TYPES}
							handleChange={handleChange}
							handleDelete={handleDelete}
							inputValue={industries}
							selectProps={{
								multiple: true,
								fullWidth: true,
								IconComponent: KeyboardArrowDownRoundedIcon,
								height: "60px",
								width: "100%",
							}}
						/>
					</Grid>
				</Grid>
				{/* <Grid container rowSpacing={4} columnSpacing={4}>
					<Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
						<UpdatedSelectField
							id={"productOffering"}
							lable={"Product Offering"}
							placeholder={"Product Offering"}
							options={constants.PRODUCT_OFFERING}
							handleChange={handleChange}
							handleDelete={handleDelete}
							inputValue={productOffering}
							selectProps={{
								multiple: true,
								fullWidth: true,
								IconComponent: KeyboardArrowDownRoundedIcon,
								height: "60px",
								width: "100%",
							}}
						/>
					</Grid>
				</Grid> */}
			</Box>
		</Box>
	);
};

export default TagsAboutMeSection;
