import React, { useEffect } from "react";
import { Button, Typography, Box, Rating, TextField, Stack, CircularProgress } from "@mui/material";
import StarBorderRoundedIcon from "@mui/icons-material/StarBorderRounded";
import StarRateRoundedIcon from "@mui/icons-material/StarRateRounded";
import CommonSlideDialog from "../CommonSlideDialog";
import services from "../../services";

export default function PowerupRatingModal({ openRatingModal, setOpenRatingModal, responseId, entityId }) {
	const [rating, setRating] = React.useState(0);
	const [review, setReview] = React.useState("");
	const [buttonLoader, setButtonLoader] = React.useState(false);
	const [ratingReviewDetails, setRatingReviewDetails] = React.useState(null);

	useEffect(() => {
		if (openRatingModal) {
			getRatingApiCall();
		}
	}, [openRatingModal]);

	const handleOnClose = () => {
		setOpenRatingModal(false);
	};

	const getRatingApiCall = async () => {
		try {
			const response = await services.getRatingReviewApi({
				entityId: entityId,
				entityType: "POWER_UP",
				details: true,
				filter: "RATING",
				sort: "ASC",
				perPage: 50,
			});
			if (response) {
				const responseData = response?.data?.data;
				const ratingAndReviews = responseData?.paginationModel?.models?.filter((ratingItem) => ratingItem?.isRatedByMe);
				setReview(ratingAndReviews?.[0]?.description);
				setRating(ratingAndReviews?.[0]?.rating);
				setRatingReviewDetails(ratingAndReviews);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const ratingApiCall = async () => {
		try {
			const payload = {
				responseId: responseId,
				entityId: entityId,
				entityType: "POWER_UP",
				rating: rating,
				description: review,
			};
			setButtonLoader(true);
			let response = {};
			if (ratingReviewDetails?.length > 0) {
				const reviewId = ratingReviewDetails?.[0]?.id;

				// Add rating review api call
				response = await services.updateRatingReviewApi(reviewId, payload);
			} else {
				// Update rating review api call
				response = await services.addRatingReviewApi(payload);
			}
			if (response) {
				setButtonLoader(false);
				handleOnClose();
			}
		} catch (error) {
			setButtonLoader(false);
			throw error;
		}
	};

	return (
		<CommonSlideDialog
			disableBackDropClick={true}
			title={"Rate this PowerUp!"}
			titleVariant={"Text/md/Semibold"}
			isOpen={openRatingModal}
			onClose={handleOnClose}
			contentStyle={{ px: 0 }}
			maxWidth={"xs"}
			fullWidth
			footerStyle={{
				border: "none",
			}}
			PaperProps={{
				sx: (theme) => ({
					borderRadius: theme.shape.standard13.borderRadius,
					boxShadow: "0px 5px 16px rgba(0, 0, 0, 0.3)",
					p: 3,
				}),
			}}
			dialogAction={
				<Button
					onClick={() => {
						ratingApiCall();
					}}
					startIcon={buttonLoader && <CircularProgress size={"1.25rem"} sx={{ color: "inherit" }} />}
					variant="DS1"
					disabled={!rating}
				>
					Submit
				</Button>
			}
		>
			<Stack sx={{ pl: "20px", pr: "13px" }} spacing={3}>
				<Rating
					name="simple-controlled"
					value={rating}
					onChange={(event, newValue) => {
						setRating(newValue);
					}}
					icon={<StarRateRoundedIcon fontSize="inherit" sx={(theme) => ({ color: theme.palette.primary.primaryYellow })} />}
					emptyIcon={<StarBorderRoundedIcon fontSize="inherit" />}
				/>
				<Box>
					<Typography variant="Text/sm/Semibold" component={"p"}>
						Comment
					</Typography>
					<TextField
						value={review}
						multiline
						onChange={(e) => {
							setReview(e.target.value);
						}}
						sx={(theme) => ({
							width: 1,
							"& .MuiOutlinedInput-notchedOutline": {
								borderRadius: theme.shape.standard.borderRadius,
							},
							"& .MuiOutlinedInput-input": (theme) => ({
								fontFamily: theme.typography["Text/sm/Regular"],
							}),
						})}
						rows={5}
					/>
				</Box>
			</Stack>
		</CommonSlideDialog>
	);
}
