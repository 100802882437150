import React, { useState, useContext, useMemo, useEffect } from "react";
import { Box, Grid, MenuItem, Select, Stack, Typography, Button, InputAdornment, IconButton, TextField } from "@mui/material";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import services from "../../../services";
import UpdatedTextInputField from "../../../common/UpdatedTextInputField";
import { StyledSelect } from "../../../common/UpdatedSelectField/style";
import FirstbaseCTA from "../../../components/FirstbaseCTA";

import UpdatedSelectField from "../../../common/UpdatedSelectField";
import { getJasonFormatedValue } from "../../../utils/commonFunctions";
import { PatternFormat } from "react-number-format";
import SelectCheckedInput from "../../../common/SelectCheckedInput";
import NewSingleSelect from "../../../common/NewSingleSelect";
import { CURRENT_PRODUCT_STATE_DICT, FOUNDING_TEAM_EXP_LIST, FOUNDING_TEAM_SKILLS_LIST, HISTORY_OF_SUCCESS_LIST } from "../../../constants";
import { ATTENDED_OPTIONS } from "../../../containers/Onboard/Components/Options";
import CheckboxAccordion from "../../../common/CustomCheckboxTree";
import { useSelector } from "react-redux";
import { USER_TYPES } from "../../../utils/userTypes";
import CountryAutoComplete from "../../LocationAutoCompleteFields/CountryAutoCompleteField";
import AddressAutoCompleteField from "../../LocationAutoCompleteFields/AddressAutoCompleteField";

const StartupDetailsSection = ({ context }) => {
	const [countryStateData, setCountryStateData] = useState([]);
	const { userType } = useSelector((state) => state.auth);
	const [openDatePicker, setOpenDatePicker] = useState(false);
	const [historySuccessList, setHistorySuccessList] = useState(HISTORY_OF_SUCCESS_LIST);
	const [foundingTeamExpList, setFoundingTeamExpList] = useState(FOUNDING_TEAM_EXP_LIST);
	const [foundingTeamSkillsList, setFoundingTeamSkillsList] = useState(FOUNDING_TEAM_SKILLS_LIST);
	const { payLoad, setPayLoad, setOpenHarmonic, errors, setErrors, industryList, productOfferingList, businessTypeList } = useContext(context);
	const fieldList = [
		{ lable: "Startup Name", key: "companyName", multiSelect: false, options: [], disabled: userType === USER_TYPES.STARTUP_USER },
		{ lable: "Tagline", key: "tagLine", multiSelect: false, options: [], disabled: false },
		{ lable: "Street Address", key: "address1", multiSelect: false, options: [], disabled: false },
		{ lable: "City", key: "city", multiSelect: false, options: [], disabled: false },
		{ lable: "State", key: "state", multiSelect: false, options: [], disabled: false },
		{ lable: "Zipcode", key: "zipcode", multiSelect: false, options: [], disabled: false },
		{ lable: "Country", key: "country", multiSelect: false, options: [], disabled: false },
		{ lable: "Phone", key: "phone", multiSelect: false, options: [], disabled: false },
		{ lable: "Number of Co-Founders", key: "noOfCoFounders", multiSelect: false, options: [], disabled: false },
		{ lable: "Date Founded", key: "foundedDate", multiSelect: false, options: [], disabled: false },
		{ lable: "Company Size", key: "companySize", multiSelect: false, options: [], disabled: false },
		{ lable: "Startup Website", key: "websiteLink", multiSelect: false, options: [], disabled: false },
		{ lable: "Is your startup incorporated", key: "incorporatedStatus", multiSelect: false, options: [], disabled: false },
		{ lable: "Industry", key: "industry", multiSelect: true, options: industryList, disabled: false },
		{ lable: "Product Offering", key: "productOffering", multiSelect: false, singleSelect: true, options: productOfferingList, disabled: false },
		{ lable: "Business Model Type", key: "businessModel", multiSelect: false, singleSelect: true, options: businessTypeList, disabled: false },
		{ lable: "LinkedIn Startup Profile", key: "linkedInUrl", multiSelect: false, options: [], disabled: false },
		{
			lable: "Are you working full-time on your startup?",
			key: "workingFullTime",
			singleSelect: true,
			multiSelect: false,
			options: ["Yes", "No"],
			disabled: false,
		},
		{ lable: "Do you have a cap table?", key: "capTable", singleSelect: true, multiSelect: false, options: ["Yes", "No"] },
		{
			lable: "What is the current state of your product or service?",
			key: "stateOfProduct",
			singleSelect: true,
			multiSelect: false,
			options: ["Idea (or low-fidelity mockup/prototype)", "Minimum Viable Product", "Launched (or sellable product)"],
			disabled: false,
		},
		{
			lable: "Have you attended an accelerator?",
			key: "incubator",
			singleSelect: true,
			multiSelect: true,
			options: ATTENDED_OPTIONS,
			disabled: false,
		},
		{
			lable: "Is your startup scalable?",
			key: "startupScalable",
			singleSelect: true,
			multiSelect: false,
			options: ["Yes", "Partially", "No"],
			disabled: false,
		},
		{
			lable: "History of Success",
			key: "historyOfSuccess",
		},
		{
			lable: "Founding Team Experience ",
			key: "foundingTeamExp",
		},
		{
			lable: "Founding Team Skills",
			key: "foundingTeamSkills",
		},
	];

	const handleChange = (event) => {
		let key = event.target.id || event.target.name;
		let newValue = event.target.value;
		if (key === "companySize" || key === "noOfCoFounders") {
			payLoad[key] = newValue.replace(/[^0-9]/g, "");
		} else {
			payLoad[key] = newValue;
		}
		setPayLoad({ ...payLoad });
	};

	useEffect(() => {
		const updatedSuccessList = historySuccessList.map((item) => {
			const foundScoreTag = payLoad["companyTags"].find((tag) => tag?.scoreTag?.id == item?.id);
			if (foundScoreTag) {
				return { ...item, checked: true };
			}
			return item;
		});
		setHistorySuccessList(updatedSuccessList);

		const updatedFoundingTeamList = foundingTeamExpList.map((item) => {
			const foundScoreTag = payLoad["companyTags"].find((tag) => tag?.scoreTag?.id == item?.id);
			if (foundScoreTag) {
				return { ...item, checked: true };
			}
			return item;
		});

		setFoundingTeamExpList(updatedFoundingTeamList);

		const updatedTeamSkillList = foundingTeamSkillsList.map((item) => {
			const foundScoreTag = payLoad["companyTags"].find((tag) => tag?.scoreTag?.id == item?.id);
			if (foundScoreTag) {
				return { ...item, checked: true };
			}
			return item;
		});

		setFoundingTeamSkillsList(updatedTeamSkillList);

		console.log(payLoad);
	}, []);

	useEffect(() => {
		const companyTags = [
			...historySuccessList.filter((historyItem) => historyItem.checked),
			...foundingTeamExpList.filter((historyItem) => historyItem.checked),
			...foundingTeamSkillsList.filter((historyItem) => historyItem.checked),
		];
		const updatedSuccessList = companyTags.map((item) => {
			return item.id;
		});
		payLoad["companyTags"] = updatedSuccessList;

		setPayLoad({ ...payLoad });
	}, [historySuccessList, foundingTeamExpList, foundingTeamSkillsList]);

	const handleSingleSelect = (event, key) => {
		let newValue = event.target.value;

		payLoad[key] = newValue;
		// }

		setPayLoad({ ...payLoad });
	};
	const handleValueChange = (value, key) => {
		payLoad[key] = value;
		// payLoad["foundedYear"] = moment(payLoad["foundedDate"]).format("Y");
		setPayLoad({ ...payLoad });
	};

	function handleSelectChange(event, key) {
		if (key === "incubator") {
			const selectedValues = event.target.value;
			let newValue;
			if (selectedValues.length > 0 && selectedValues[selectedValues.length - 1] === "None") {
				newValue = JSON.stringify(["None"]);
			} else {
				const newSelectedValues = selectedValues.filter((item) => item !== "None");
				newValue = newSelectedValues.length > 0 ? JSON.stringify(newSelectedValues) : null;
			}
			payLoad[key] = newValue;
			setPayLoad({ ...payLoad });
			return;
		}
		let newValue = event.target.value.length > 0 ? JSON.stringify(event.target.value) : null;
		payLoad[key] = newValue;
		setPayLoad({ ...payLoad });
	}

	function handleDelete(value, key) {
		let tempArray = getJasonFormatedValue(payLoad[key]);
		let filteredArray = tempArray.filter((item) => item !== value);
		if (filteredArray.length > 0) {
			payLoad[key] = JSON.stringify(tempArray.filter((item) => item !== value));
		} else {
			payLoad[key] = null;
		}

		setPayLoad({ ...payLoad });
	}

	const getCountryStateData = async () => {
		const res = await services.getListOfCountryStateApi();
		setCountryStateData(res?.data?.data);
	};

	const getOptions = useMemo(() => {
		let selectedCountry = "USA";
		let stateList = [];

		if (countryStateData.length > 0) {
			stateList = countryStateData.filter((item) => item.countryCode === "USA")[0]?.states;
		}
		return stateList;
	}, [countryStateData]);

	useEffect(() => {
		if (!payLoad["country"]) {
			payLoad["country"] = "US";
			setPayLoad({ ...payLoad });
		}
		getCountryStateData();
	}, []);

	const copyFromLinkedInClick = () => {
		setOpenHarmonic(true);
	};
	const datePickerClick = () => {
		if (openDatePicker === false) {
			setOpenDatePicker(true);
		}
	};

	const autoFillAddress = (values) => {
		if (values?.streetAddress) {
			payLoad["address1"] = values?.streetAddress;
		}
		if (values?.city) {
			payLoad["city"] = values?.city;
		}
		if (values?.state) {
			payLoad["state"] = values?.state;
		}
		if (values?.zipcode) {
			payLoad["zipcode"] = values?.zipcode;
		}
		if (values?.country_code) {
			payLoad["country"] = values?.country_code;
		}
		setPayLoad({ ...payLoad });
	};

	const countryChanged = (e) => {
		payLoad["country"] = e?.code ?? "US";
		payLoad["state"] = "";
		payLoad["zipcode"] = "";
		setPayLoad({ ...payLoad });
	};
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				rowGap: "30px",
			}}
		>
			<Box>
				<Typography variant="poppinsSemiBold20">Startup Details</Typography>
			</Box>
			<Box>
				<Grid container rowSpacing={4} columnSpacing={7}>
					{fieldList?.map((item) => {
						if (item.multiSelect) {
							return (
								<Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
									<UpdatedSelectField
										id={item.key}
										lable={item.lable}
										placeholder={item.lable}
										options={item.options}
										handleChange={handleSelectChange}
										handleDelete={handleDelete}
										inputValue={getJasonFormatedValue(payLoad[item.key]) ? getJasonFormatedValue(payLoad[item.key]) : []}
										selectProps={{
											multiple: true,
											fullWidth: true,
											IconComponent: KeyboardArrowDownRoundedIcon,
											height: "60px",
											width: "100%",
										}}
									/>
								</Grid>
							);
						} else {
							if (item.key === "linkedInUrl") {
								return (
									<Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
										<UpdatedTextInputField
											label={item.lable}
											id={item.key}
											handleChange={handleChange}
											inputValue={payLoad[item.key]?.replace(/^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(company\/)/gm, "") || ""}
											placeholder={item.lable}
											textFieldProps={{
												fullwidth: true,
												width: "100%",
												height: "60px",
											}}
											InputProps={{
												startAdornment: (
													<Typography component={"p"} sx={{ pl: 2 }} variant="title_medium">
														https://www.linkedin.com/company/
													</Typography>
												),
											}}
										/>
										{payLoad[item.key] && (
											<Box sx={{ mt: 1 }}>
												<Button variant="DS1" onClick={copyFromLinkedInClick}>
													Copy profile from Linkedin
												</Button>
											</Box>
										)}
									</Grid>
								);
							} else if (item?.key === "historyOfSuccess") {
								return (
									<Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
										<CheckboxAccordion
											label={item.lable}
											ItemList={historySuccessList}
											setItemList={setHistorySuccessList}
											background="transparent"
											disableAccordian={true}
										/>
									</Grid>
								);
							} else if (item?.key === "foundingTeamExp") {
								return (
									<Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
										<CheckboxAccordion
											label={item.lable}
											ItemList={foundingTeamExpList}
											setItemList={setFoundingTeamExpList}
											background="transparent"
											disableAccordian={true}
										/>
									</Grid>
								);
							} else if (item?.key === "foundingTeamSkills") {
								return (
									<Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
										<CheckboxAccordion
											label={item.lable}
											ItemList={foundingTeamSkillsList}
											setItemList={setFoundingTeamSkillsList}
											background="transparent"
											disableAccordian={true}
										/>
									</Grid>
								);
							} else if (item.key === "state") {
								return (
									<Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
										<Typography variant="poppinsSemiBold18">
											{payLoad["country"] && (payLoad["country"] == "US" || payLoad["country"] == "USA")
												? "State"
												: payLoad["country"] && payLoad["country"] == "CA"
												? "Province"
												: "Region"}
										</Typography>
										{!payLoad["country"] || (payLoad["country"] && (payLoad["country"] == "US" || payLoad["country"] == "USA")) ? (
											<StyledSelect
												fullWidth
												value={payLoad[item.key]}
												name={item.key}
												onChange={handleChange}
												IconComponent={KeyboardArrowDownRoundedIcon}
												sx={{
													mt: 1,
													"& .MuiSelect-select": {
														fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
														fontSize: "16px",
														lineHeight: "24px",
													},
												}}
											>
												{getOptions?.map((option) => (
													<MenuItem key={option.stateCode} value={option.stateCode}>
														{option.stateName}
													</MenuItem>
												))}
											</StyledSelect>
										) : (
											<UpdatedTextInputField
												id={"state"}
												handleChange={handleChange}
												inputValue={payLoad["state"]}
												placeholder={payLoad["country"] && payLoad["country"] == "CA" ? "Province" : "Region"}
												textFieldProps={{
													fullWidth: true,
													width: "100%",
													height: "60px",
												}}
											/>
										)}
									</Grid>
								);
							} else if (item.key === "address1") {
								return (
									<Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
										<AddressAutoCompleteField
											onValueSet={(e) => autoFillAddress(e)}
											fieldLabel={"Street Address"}
											fieldPlaceholder={"Street Address"}
											parentValue={payLoad["address1"]}
										/>
									</Grid>
								);
							} else if (item.key === "country") {
								return (
									<Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
										<CountryAutoComplete
											onValueSet={(e) => countryChanged(e)}
											fieldLabel={"Country"}
											fieldPlaceholder={"Country"}
											parentValue={payLoad["country"] ?? "US"}
										/>
									</Grid>
								);
							} else if (item.key === "foundedDate") {
								return (
									<Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
										<Typography variant="poppinsSemiBold18">{item.lable}</Typography>
										<Box onClick={datePickerClick}>
											<LocalizationProvider dateAdapter={AdapterMoment}>
												<DatePicker
													open={openDatePicker}
													onClose={() => setOpenDatePicker(false)}
													format="MM/DD/YYYY"
													value={payLoad[item.key] ? moment(payLoad[item.key]) : null}
													disableFuture={true}
													onChange={(newValue) => {
														handleValueChange(moment(newValue).toDate(), item.key);
													}}
													slots={{
														OpenPickerIcon: CalendarTodayOutlinedIcon,
													}}
													sx={{
														cursor: "pointer",
														mt: 1.25,
														"& .MuiOutlinedInput-notchedOutline": { border: "none" },
														"& .MuiOutlinedInput-root": {
															fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
															fontSize: "16px",
															lineHeight: "24px",
														},
														"&.MuiTextField-root": {
															width: "100%",
															borderRadius: "30px",
															background: "white",
															boxShadow: "0px 0px 0px 4px #F2F4F7, 0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
															border: "none",
														},
													}}
													slotProps={{
														openPickerButton: {
															sx: {
																mr: "5px",
															},
														},
														field: {
															InputProps: {
																endAdornment: (
																	<InputAdornment position="end" sx={{ mr: 1.5 }}>
																		<IconButton edge="end">
																			<CalendarTodayOutlinedIcon sx={{ color: "#000" }} />
																		</IconButton>
																	</InputAdornment>
																),
																variant: "outlined",
																sx: {
																	borderRadius: "30px",
																	height: "60px",
																	fontWeight: "500",
																	fontSize: "16px",
																	lineHeight: "24px",
																	padding: "1.5px 6px",
																},
															},
														},
													}}
												/>
											</LocalizationProvider>
										</Box>
									</Grid>
								);
							} else if (item.key === "websiteLink") {
								return (
									<Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
										<UpdatedTextInputField
											label={item.lable}
											id={item.key}
											handleChange={handleChange}
											placeholder={item.lable}
											textFieldProps={{
												fullwidth: true,
												width: "100%",
												height: "60px",
												fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
												fontSize: "16px",
												lineHeight: "24px",
											}}
											inputValue={payLoad[item.key] && payLoad[item.key].replace("https://", "").replace("http://", "")}
											InputProps={{
												startAdornment: (
													<Typography component={"p"} sx={{ pl: 2 }} variant="flyout_text">
														https://
													</Typography>
												),
											}}
										/>
									</Grid>
								);
							} else if (item.key === "incorporatedStatus") {
								return (
									<Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
										<Typography variant="poppinsSemiBold18">{item.lable}</Typography>
										<StyledSelect
											fullWidth
											value={payLoad[item.key]}
											name={item.key}
											onChange={handleChange}
											IconComponent={KeyboardArrowDownRoundedIcon}
											sx={{
												mt: 1,
												"& .MuiSelect-select": {
													fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
													fontSize: "16px",
													lineHeight: "24px",
												},
											}}
										>
											{["Yes", "No", "In Progress"]?.map((option) => (
												<MenuItem key={option} value={option}>
													{option}
												</MenuItem>
											))}
										</StyledSelect>
										{(payLoad[item.key] === "No" || payLoad[item.key] === "In Progress") && (
											<Box sx={{ mt: 2 }}>
												<FirstbaseCTA params={{ bgColor: "white" }} />
											</Box>
										)}
									</Grid>
								);
							} else if (item.key === "phone") {
								return (
									<Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
										<Typography variant="poppinsSemiBold18">{item.lable}</Typography>
										<PatternFormat
											fullWidth
											placeholder={item.lable}
											format="(###) ###-####"
											mask="_"
											value={payLoad[item.key]}
											customInput={TextField}
											sx={{
												mt: 1.25,
												"& .MuiOutlinedInput-notchedOutline": {
													border: "none",
												},
												"& .MuiInputBase-root": {
													fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
													fontSize: "16px",
													lineHeight: "24px",
													height: "60px",
													width: "100%",
													borderRadius: "30px",
													background: "white",
													boxShadow: "0px 0px 0px 4px #F2F4F7, 0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
													border: "none",
												},
											}}
											onValueChange={(e) => handleValueChange(e.formattedValue || "", item.key)}
										/>
									</Grid>
								);
							} else if (item.singleSelect) {
								return (
									<Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
										<NewSingleSelect
											id={item.key}
											label={item.lable}
											fullWidth
											onInputChange={(e) => {
												handleSingleSelect(e, item.key);
											}}
											selectedValue={payLoad[item.key]}
											inputKey={item.key}
											options={item.options}
											borderNone={true}
										/>
									</Grid>
								);
							} else {
								return (
									<Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
										<UpdatedTextInputField
											label={item.lable}
											id={item.key}
											handleChange={handleChange}
											inputValue={payLoad[item.key]}
											placeholder={item.lable}
											disabled={item.disabled}
											textFieldProps={{
												fullwidth: true,
												width: "100%",
												height: "60px",
											}}
										/>
									</Grid>
								);
							}
						}
					})}
				</Grid>
			</Box>
		</Box>
	);
};

export default StartupDetailsSection;
