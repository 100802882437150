import { Box, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { ScoreChart } from "../../../Charts/ScoreChart";

function BenchmarkCompare() {
	const STARTUP = useSelector((state) => state?.investorDashboard?.openStartupObj);
	return (
		<Box
			sx={{
				borderRadius: "30px",
				p: 4,
				background: "white",
				flex: 1,
			}}
		>
			<Typography variant="poppinsSemiBold20" color="black">
				Benchmark
			</Typography>
			<ScoreChart
				percentage={parseInt(STARTUP?.scoreCard?.peerGroupPercentile ? STARTUP?.scoreCard?.peerGroupPercentile : "0")}
				scoreValue={STARTUP?.currentScore ? STARTUP?.currentScore : "0"}
				stageName={STARTUP?.currentStage ? STARTUP?.currentStage : ""}
				stageValue={STARTUP?.scoreCard?.peerGroupCount ? STARTUP?.scoreCard?.peerGroupCount : "0"}
				scoreObj={STARTUP?.scoreCard}
			/>
		</Box>
	);
}

export default BenchmarkCompare;
