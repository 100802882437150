import { Box, Chip, Rating, Typography } from "@mui/material";
import React, { useContext, useEffect, useRef } from "react";
import useDimensions from "react-cool-dimensions";
import { Helmet } from "react-helmet";
import ReactPlayer from "react-player";

import { PowerUPContext } from "../../../PowerUPController";
import CompleteStatus from "../VersionHistory/CompleteStatus";
import { useLocation } from "react-router-dom";

const InfoCorner = ({ title, estimatedEffort, whoShouldDoIt, ratingValue, barStyle }) => {
	const location = useLocation();
	return (
		<Box
			sx={{
				...(barStyle
					? {
							borderRadius: "30px",
							px: "2.5rem",
							py: "1.5rem",
							gap: ".25rem",
					  }
					: {
							borderRadius: "0px 100px 0px 0px",
							padding: "2.5rem",
							gap: "0.625rem",
					  }),
				background: (theme) => theme.palette.secondary.main,
				display: "flex",
				flexDirection: "column",
				alignItems: "flex-start",
			}}
		>
			<Typography variant={barStyle ? "Title/20/Semibold" : "Title/30/Semibold"} sx={{ color: "white" }}>
				{title}
			</Typography>
			{/* <Rating
			name="read-only"
			value={ratingValue}
			readOnly
			sx={{
				fontSize: barStyle ? "1.275rem" : "1.875rem",
			}}
		/> */}
			{!location.pathname.includes("/public/powerups") && (
				<>
					<Typography variant="Body/18/Regular" sx={{ color: "white" }}>
						Estimated Effort: {estimatedEffort}
						{whoShouldDoIt && (
							<React.Fragment>
								<br />
								Who Should Do It: {whoShouldDoIt}
							</React.Fragment>
						)}
					</Typography>
					<CompleteStatus />
				</>
			)}
		</Box>
	);
};

function HeroCard() {
	const { powerupDetails, videoPlaying, setVideoPlaying } = useContext(PowerUPContext);

	const { observe, width } = useDimensions({
		onResize: ({ width }) => {},
	});

	const calculateHeight = (width) => {
		const maxWidth = 1630;
		const maxHeight = 750;
		const aspectRatio = maxWidth / maxHeight;
		const calculatedHeight = width / aspectRatio;

		return calculatedHeight > maxHeight ? maxHeight : calculatedHeight;
	};

	const height = calculateHeight(width);
	const infoCornerRef = useRef(null);
	const minHeight = infoCornerRef.current ? infoCornerRef.current.clientHeight : "auto";

	useEffect(() => {
		observe();
	}, [observe]);

	return (
		<React.Fragment>
			<Helmet>
				<meta charSet="utf-8" />
				<title>StartupOS - {powerupDetails?.powerUpName || ""}</title>
			</Helmet>
			<Box
				ref={observe}
				sx={{
					position: "relative",
					width: "100%",
					height: `${height}px`,
					minHeight: `${minHeight}px`,
					maxWidth: "1630px",
					maxHeight: "750px",
					backgroundColor: "gray",
					borderRadius: "1.875rem",
					overflow: "hidden",
					...(!powerupDetails?.videoThumbnailUrl && {
						backgroundImage: `url(${powerupDetails?.imageUrl})`,
						backgroundSize: "contain",
						backgroundPosition: "center",
					}),
					...(!powerupDetails?.videoUrl &&
						powerupDetails?.videoThumbnailUrl && {
							backgroundImage: `url(${powerupDetails?.videoThumbnailUrl})`,
							backgroundSize: "cover",
							backgroundPosition: "right",
						}),
				}}
			>
				{!videoPlaying && powerupDetails?.isAiPowered && (
					<Box
						sx={{
							position: "absolute",
							top: "1.87rem",
							left: "1.87rem",
						}}
					>
						<Chip
							sx={{ background: "white" }}
							label={
								<Typography variant="Text/xs/Medium" color={"#344054"}>
									AI Powered
								</Typography>
							}
						/>
					</Box>
				)}

				{powerupDetails?.videoUrl && (
					<Box
						sx={{
							"& .react-player": {
								video: {
									objectFit: "cover",
								},
							},
						}}
					>
						<ReactPlayer
							className={"react-player"}
							onPlay={() => setVideoPlaying(true)}
							onStart={() => setVideoPlaying(true)}
							onEnded={() => setVideoPlaying(false)}
							onSeek={() => setVideoPlaying(true)}
							controls={true}
							width={"100%"}
							height={height}
							maxHeight="750px"
							url={powerupDetails?.videoUrl}
							config={{
								file: {
									attributes: { poster: powerupDetails?.videoThumbnailUrl },
								},
							}}
						/>
					</Box>
				)}

				{!videoPlaying && width > 1000 && (
					<Box
						ref={infoCornerRef}
						sx={{
							position: "absolute",
							bottom: "0",
							left: "0",
							maxWidth: "35.3125rem",
							width: "100%",
						}}
					>
						<InfoCorner
							title={powerupDetails?.powerUpName}
							estimatedEffort={powerupDetails?.timeEstimate}
							ratingValue={5}
							barStyle={videoPlaying}
						/>
					</Box>
				)}
			</Box>
			{(videoPlaying || width < 1000) && (
				<Box
					sx={{
						my: "1.25rem",
					}}
				>
					<InfoCorner
						title={powerupDetails?.powerUpName}
						estimatedEffort={powerupDetails?.timeEstimate}
						ratingValue={5}
						barStyle={videoPlaying || width < 1000}
					/>
				</Box>
			)}
		</React.Fragment>
	);
}

export default HeroCard;
