import React, { useEffect, useRef, useState } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Slide, Typography } from "@mui/material";
import useDimensions from "react-cool-dimensions";
import { ALERT_HEX, ATOM, BAR_CHART, CURRENCY_DOLLAR_CIRCLE_ICON, GIFT, LIGHTBULB, SCALES, STACKED_COINS, USERS_TWO } from "../../../../../constants";
import { TextArea, HTMLTextArea } from "../../../components/inputs/TextArea";
import LeanCanvasLoader from "./LeanCanvasLoader";
import { debounce } from "lodash";
import { stateFromHTML } from "draft-js-import-html";
import { stateToHTML } from "draft-js-export-html";
import { convertFromRaw, ContentState, convertToRaw } from "draft-js";
import MUIRichTextEditor from "mui-rte";
import { Close } from "@mui/icons-material";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="left" ref={ref} {...props} />;
});

const CanvasItemEditor = ({ value, onSave, title, icon }) => {
	const [initialContent, setInitialContent] = useState("");
	const [open, setopen] = useState(false);

	const editorRef = useRef();

	// Convert the provided value (HTML or plain text) to a format that MUIRichTextEditor understands
	useEffect(() => {
		if (value) {
			let contentState;
			if (/<[a-z][\s\S]*>/i.test(value)) {
				// Check if the value has HTML tags
				contentState = stateFromHTML(value); // Convert HTML to draft-js ContentState
			} else {
				contentState = ContentState.createFromText(value); // Convert plain text to ContentState
			}
			if (contentState) {
				setInitialContent(JSON.stringify(convertToRaw(contentState)));
			}
		}
	}, [value]);

	const handleSave = (data) => {
		const currentContent = typeof data === "string" ? JSON.parse(data) : data;
		if (currentContent) {
			const contentState = convertFromRaw(currentContent);
			if (contentState) {
				const htmlContent = stateToHTML(contentState);
				if (typeof onSave === "function") onSave(htmlContent);
			}
		}
		setopen(false);
	};

	const saveContent = () => {
		if (editorRef.current) {
			editorRef?.current?.save();
		} else {
			setopen(false);
		}
	};

	return (
		<React.Fragment>
			<TextArea usePrintable tight value={value} onClick={() => setopen(true)} />
			<Dialog
				TransitionComponent={Transition}
				transitionDuration={600}
				fullWidth
				open={open}
				disableBackDropClick
				PaperProps={{
					sx: (theme) => ({
						borderRadius: theme.shape.standard13.borderRadius,
						boxShadow: "0px 5px 16px rgba(0, 0, 0, 0.3)",
						p: 3,
					}),
				}}
			>
				<DialogTitle
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<Box
						sx={{
							display: "flex",
							gap: ".75rem",
							alignItems: "center",
						}}
					>
						<Box component={"img"} src={icon} sx={{ height: "2rem", width: "2rem" }} />
						<Typography variant="Text/sm/Semibold">{title}</Typography>
					</Box>
					<IconButton
						aria-label="close"
						onClick={() => saveContent()}
						sx={{
							color: (theme) => theme.palette.secondary.dark,
						}}
					>
						<Close sx={{ color: (theme) => theme.palette.secondary.black }} />
					</IconButton>
				</DialogTitle>

				<DialogContent
					sx={{
						px: 4,
					}}
				>
					<MUIRichTextEditor
						ref={editorRef}
						defaultValue={initialContent}
						controls={["numberList", "bulletList"]}
						onSave={(data) => {
							handleSave(data);
						}}
					/>
				</DialogContent>
				<DialogActions>
					<Box display={"flex"} justifyContent={"flex-end"}>
						<Button variant="DS1" small onClick={() => saveContent()}>
							Close
						</Button>
					</Box>
				</DialogActions>
			</Dialog>
		</React.Fragment>
	);
};

const CanvasCard = ({ title, icon, children, sx }) => (
	<Box
		sx={{
			display: "flex",
			padding: "1.5rem",
			flexDirection: "column",
			alignItems: "flex-start",
			gap: "1.5rem",
			flex: "1 0 0",
			alignSelf: "stretch",
			borderRadius: "2rem",
			background: "#fff",
			boxShadow: "0px 10px 25px 0px rgba(0, 0, 0, 0.05)",
			...sx,
		}}
	>
		<Box
			sx={{
				display: "flex",
				gap: ".75rem",
				alignItems: "center",
			}}
		>
			<Box component={"img"} src={icon} sx={{ height: "2rem", width: "2rem" }} />
			<Typography variant="Text/sm/Semibold">{title}</Typography>
		</Box>
		{children}
	</Box>
);

const LeanCanvasGrid = ({ showLoader, gridData, updateInput, usePrintable }) => {
	const [parsedData, setParsedData] = React.useState(null);
	const [saveReady, setsaveReady] = React.useState(false);
	// useRef to store the latest version of parsedData
	const parsedDataRef = useRef(parsedData);

	const { observe, width } = useDimensions({
		onResize: ({ width }) => {},
	});
	useEffect(() => {
		observe();
	}, [observe]);

	useEffect(() => {
		try {
			if (gridData) {
				const parsed = JSON.parse(gridData);
				setParsedData(parsed);
			} else {
				setParsedData(null);
			}
		} catch (e) {
			console.log("Unable to parse JSON, failed with error:", gridData, e);
		}
	}, [gridData]);

	useEffect(() => {
		parsedDataRef.current = parsedData;
		if (saveReady) saveToApi(parsedData);
	}, [parsedData]);

	const saveToApi = (data) => {
		setsaveReady(false);
		try {
			const stringified = JSON.stringify(data);
			if (typeof updateInput === "function") updateInput(stringified);
		} catch (e) {
			console.log("Unable to stringify JSON, failed with error:", data, e);
		}
	};

	const handleInputChange = (fieldName, data) => {
		setsaveReady(true);
		setParsedData({ ...parsedData, [fieldName]: data });
	};

	return (
		<Box
			ref={observe}
			sx={{
				display: "flex",
				flexDirection: "column",
				gap: ".5rem",
				...(usePrintable && {
					width: "1500px",
				}),
			}}
		>
			{showLoader ? (
				<LeanCanvasLoader useStacked={width <= 950} />
			) : parsedData ? (
				<React.Fragment>
					<Box
						sx={{
							gap: ".5rem",
							...(!usePrintable && width <= 950
								? {
										display: "flex",
										flexDirection: "column",
								  }
								: {
										display: "grid",
										gridTemplateColumns: "repeat(5, 1fr)",
										gridTemplateRows: "repeat(2, auto)",
								  }),
						}}
					>
						<CanvasCard sx={{ gridArea: "1 / 1 / 3 / 2", ...(usePrintable && { border: "2px solid black" }) }} title={"Problem"} icon={ALERT_HEX}>
							{usePrintable ? (
								<TextArea usePrintable tight value={parsedData?.problem} />
							) : (
								<CanvasItemEditor
									value={parsedData?.problem}
									onSave={(e) => handleInputChange("problem", e)}
									title={"Problem"}
									icon={ALERT_HEX}
								/>
							)}
						</CanvasCard>
						<CanvasCard sx={{ gridArea: "1 / 2 / 2 / 3", ...(usePrintable && { border: "2px solid black" }) }} title={"Solution"} icon={LIGHTBULB}>
							{usePrintable ? (
								<TextArea usePrintable tight value={parsedData?.solution} />
							) : (
								<CanvasItemEditor
									value={parsedData?.solution}
									onSave={(e) => handleInputChange("solution", e)}
									title={"Solution"}
									icon={LIGHTBULB}
								/>
							)}
						</CanvasCard>
						<CanvasCard
							sx={{ gridArea: "2 / 2 / 3 / 3", ...(usePrintable && { border: "2px solid black" }) }}
							title={"Key Metrics"}
							icon={BAR_CHART}
						>
							{usePrintable ? (
								<TextArea usePrintable tight value={parsedData?.key_metrics} />
							) : (
								<CanvasItemEditor
									value={parsedData?.key_metrics}
									onSave={(e) => handleInputChange("key_metrics", e)}
									title={"Key Metrics"}
									icon={BAR_CHART}
								/>
							)}
						</CanvasCard>
						<CanvasCard
							sx={{ gridArea: "1 / 3 / 3 / 4", ...(usePrintable && { border: "2px solid black" }) }}
							title={"Value Proposition"}
							icon={GIFT}
						>
							{usePrintable ? (
								<TextArea usePrintable tight value={parsedData?.unique_value_proposition} />
							) : (
								<CanvasItemEditor
									value={parsedData?.unique_value_proposition}
									onSave={(e) => handleInputChange("unique_value_proposition", e)}
									title={"Value Proposition"}
									icon={GIFT}
								/>
							)}
						</CanvasCard>
						<CanvasCard
							sx={{ gridArea: "1 / 4 / 2 / 5", ...(usePrintable && { border: "2px solid black" }) }}
							title={"Unfair Advantage"}
							icon={SCALES}
						>
							{usePrintable ? (
								<TextArea usePrintable tight value={parsedData?.unfair_advantage} />
							) : (
								<CanvasItemEditor
									value={parsedData?.unfair_advantage}
									onSave={(e) => handleInputChange("unfair_advantage", e)}
									title={"Unfair Advantage"}
									icon={SCALES}
								/>
							)}
						</CanvasCard>
						<CanvasCard sx={{ gridArea: "2 / 4 / 3 / 5", ...(usePrintable && { border: "2px solid black" }) }} title={"Channels"} icon={ATOM}>
							{usePrintable ? (
								<TextArea usePrintable tight value={parsedData?.channels} />
							) : (
								<CanvasItemEditor
									value={parsedData?.channels}
									onSave={(e) => handleInputChange("channels", e)}
									title={"Channels"}
									icon={ATOM}
								/>
							)}
						</CanvasCard>
						<CanvasCard
							sx={{ gridArea: "1 / 5 / 3 / 6", ...(usePrintable && { border: "2px solid black" }) }}
							icon={USERS_TWO}
							title={"Customer Segments"}
						>
							{usePrintable ? (
								<TextArea usePrintable tight value={parsedData?.customer_segments} />
							) : (
								<CanvasItemEditor
									value={parsedData?.customer_segments}
									onSave={(e) => handleInputChange("customer_segments", e)}
									icon={USERS_TWO}
									title={"Customer Segments"}
								/>
							)}
						</CanvasCard>
					</Box>
					<Box
						sx={{
							gap: ".5rem",

							...(!usePrintable && width <= 950
								? {
										display: "flex",
										flexDirection: "column",
								  }
								: {
										display: "grid",
										gridTemplateColumns: "repeat(2, 1fr)",
										gridTemplateRows: "1fr",
								  }),
						}}
					>
						<CanvasCard
							sx={{ gridArea: "1 / 1 / 2 / 2", ...(usePrintable && { border: "2px solid black" }) }}
							icon={STACKED_COINS}
							title={"Cost Structure"}
						>
							{usePrintable ? (
								<TextArea usePrintable tight value={parsedData?.cost_structure} />
							) : (
								<CanvasItemEditor
									value={parsedData?.cost_structure}
									onSave={(e) => handleInputChange("cost_structure", e)}
									icon={STACKED_COINS}
									title={"Cost Structure"}
								/>
							)}
						</CanvasCard>
						<CanvasCard
							sx={{ gridArea: "1 / 2 / 2 / 3", ...(usePrintable && { border: "2px solid black" }) }}
							icon={CURRENCY_DOLLAR_CIRCLE_ICON}
							title={"Revenue Streams"}
						>
							{usePrintable ? (
								<TextArea usePrintable tight value={parsedData?.revenue_streams} />
							) : (
								<CanvasItemEditor
									value={parsedData?.revenue_streams}
									onSave={(e) => handleInputChange("revenue_streams", e)}
									icon={CURRENCY_DOLLAR_CIRCLE_ICON}
									title={"Revenue Streams"}
								/>
							)}
						</CanvasCard>
					</Box>
				</React.Fragment>
			) : (
				<CanvasCard title={"Unable to load canvas"} icon={ALERT_HEX}></CanvasCard>
			)}
		</Box>
	);
};

export default LeanCanvasGrid;
