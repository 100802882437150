import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { Typography, Box, Button, Switch, styled, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import services from "../../services";
import ROUTES_PATH from "../../constants/routes";
import { handleMixPanel } from "../../utils/mixPanelEventHandling";

const PAYMENT_PLANS = [
	{
		id: 1,
		plan: "Free Plan",
		title: "Access Learn by Doing Exercises to Quickly Grow Your Startup",
		amount: "0",
		amountTitle: "Per month",
		items: [
			{ title: "Founder Dashboard" },
			{ title: "PowerUps" },
			{ title: "Receive Inbound Messages from Investors & Mentors" },
			{ title: "AI with our SOS Assistant Bolt" },
			{ title: "Social Feed" },
			{ title: "Discovery Board" },
			{ title: "Training: Webinars & Events" },
			{ title: "Calculators" },
			{ title: "Support: In-app Chat" },
		],
	},
	{
		id: 2,
		plan: "Premium Plan",
		title: "Become visible to Investors to help Your Startup Secure Investment",
		amount: "29",
		amountTitle: "Per month billed annually: $348/yr",
		items: [
			{ title: "Become Visible to Investors on the Platform" },
			{ title: "Send Outbound Messages to Investors & Mentors" },
			{ title: "Connect with Investors Viewing Your Profile" },
			{ title: "BI Dashboard" },
			{ title: "AI Assisted Knowledge Center" },
			{ title: "Access to Exclusive Webinars & Events" },
			{ title: "SOS Advisory Services" },
		],
	},
];

const PremiumSwitch = styled(Switch)(({ theme }) => ({
	width: 86,
	height: 24,
	padding: 0,
	// zIndex: 2,
	display: "flex",
	"&:active": {
		"& .MuiSwitch-thumb": {
			width: 20,
		},
		"& .MuiSwitch-switchBase.Mui-checked": {
			transform: "translateX(20px)",
		},
	},
	"& .MuiSwitch-switchBase": {
		padding: 2,
		"&.Mui-checked": {
			transform: "translateX(62px)",
			color: "#fff",
			"& + .MuiSwitch-track": {
				opacity: 1,
				backgroundColor: "#F4F3FF",
			},
		},
	},
	"& .MuiSwitch-thumb": {
		boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
		width: 20,
		height: 20,
		borderRadius: 20,
		transition: theme.transitions.create(["width"], {
			duration: 200,
		}),
	},
	"& .MuiSwitch-track": {
		borderRadius: 20,
		opacity: 1,
		backgroundColor: "#FDF2FA",
		boxSizing: "border-box",
	},
}));

export default function PaymentPlan() {
	const history = useHistory();
	const [isMonthlyPlan, setIsMonthlyPlan] = useState(false);

	useEffect(() => {
		handleMixPanel("Opened Payment Plans");
	}, []);

	const handleClosePaywall = () => {
		history.push(ROUTES_PATH.MY_ACCOUNT_PROFILE);
	};

	const handlePlanChoose = async () => {
		const checkoutData = await services.checkOut(
			isMonthlyPlan ? process.env.REACT_APP_PREMIUM_MONTHLY_PRICE_ID : process.env.REACT_APP_PREMIUM_ANNUAL_PRICE_ID
		);

		if (checkoutData?.data?.data) {
			handleMixPanel("Go to Stripe", {
				"Plan name": isMonthlyPlan ? "Monthly" : "Annual",
			});

			window.open(checkoutData?.data?.data?.url);
		}
	};

	const handlePlanChange = () => {
		setIsMonthlyPlan(!isMonthlyPlan);
	};

	return (
		<Box position="relative">
			<Box sx={{ px: { lg: 8, md: 4, xs: 2 }, pb: "30px", margin: "32px auto 0", maxWidth: "975px" }}>
				<Box display="flex" alignItems="center" justifyContent="center">
					<svg width="37" height="36" viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M9.33325 19.6667L10.6407 22.2816C11.0832 23.1665 11.3044 23.609 11.6 23.9925C11.8622 24.3327 12.1673 24.6377 12.5075 24.9C12.8909 25.1956 13.3334 25.4168 14.2184 25.8593L16.8333 27.1667L14.2184 28.4741C13.3334 28.9166 12.8909 29.1379 12.5075 29.4334C12.1673 29.6957 11.8622 30.0007 11.6 30.341C11.3044 30.7244 11.0832 31.1669 10.6407 32.0518L9.33325 34.6667L8.02582 32.0518C7.58334 31.1669 7.3621 30.7244 7.06653 30.341C6.80427 30.0007 6.49924 29.6957 6.159 29.4334C5.77556 29.1379 5.33308 28.9166 4.44811 28.4741L1.83325 27.1667L4.44811 25.8593C5.33308 25.4168 5.77556 25.1956 6.159 24.9C6.49924 24.6377 6.80427 24.3327 7.06653 23.9925C7.3621 23.609 7.58334 23.1665 8.02582 22.2816L9.33325 19.6667Z"
							stroke="black"
							stroke-width="2.66667"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
						<path
							d="M23.4999 1.33337L25.4643 6.44074C25.9343 7.66279 26.1693 8.27381 26.5348 8.78778C26.8587 9.2433 27.2567 9.64128 27.7122 9.96518C28.2261 10.3306 28.8372 10.5657 30.0592 11.0357L35.1666 13L30.0592 14.9644C28.8372 15.4344 28.2261 15.6694 27.7122 16.0349C27.2567 16.3588 26.8587 16.7568 26.5348 17.2123C26.1693 17.7263 25.9343 18.3373 25.4643 19.5593L23.4999 24.6667L21.5355 19.5593C21.0655 18.3373 20.8305 17.7263 20.4651 17.2123C20.1412 16.7568 19.7432 16.3588 19.2877 16.0349C18.7737 15.6694 18.1627 15.4344 16.9406 14.9644L11.8333 13L16.9406 11.0357C18.1627 10.5657 18.7737 10.3306 19.2877 9.96518C19.7432 9.64128 20.1412 9.2433 20.4651 8.78778C20.8305 8.27381 21.0655 7.66279 21.5355 6.44074L23.4999 1.33337Z"
							stroke="black"
							stroke-width="2.66667"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
					</svg>

					<Typography variant="growth_card_subtitle" sx={{ ml: "12px" }}>
						Premium Subscription Plan
					</Typography>
				</Box>
				<Box
					my={4}
					p={3}
					sx={{
						textAlign: "center",
						background: "#FFFFFF",
						borderRadius: "24px",
					}}
				>
					<Typography variant="custom070" sx={{ pb: 2, display: "block" }}>
						Unlock a world of advanced features tailored specifically to startup founders like you.
					</Typography>
					<Typography variant="Text/md/Regular" sx={{ pb: 2, display: "block" }}>
						By upgrading to our Premium Subscription, you unlock a world of advanced features tailored specifically to startup founders like you,
						including access to pitch events, premium resources, advisors, mentors, and investors.
					</Typography>
					<Typography variant="Text/md/Regular">Don't miss out on these fantastic features! Upgrade to Premium today.</Typography>
				</Box>
				<Box display="flex" gap={4}>
					{PAYMENT_PLANS.map((payment) => (
						<Plan
							key={payment.id}
							isMonthlyPlan={isMonthlyPlan}
							payment={payment}
							handlePlanChoose={handlePlanChoose}
							handlePlanChange={handlePlanChange}
						/>
					))}
				</Box>
			</Box>
			<Box position="absolute" top={-24} right={24}>
				<IconButton
					sx={{
						width: "50px",
						height: "50px",
						background: "#000000",
						"&:hover": {
							background: "#000000",
						},
					}}
					onClick={handleClosePaywall}
				>
					<CloseIcon sx={{ color: "#fff" }} />
				</IconButton>
			</Box>
		</Box>
	);
}

const Plan = ({ payment, isMonthlyPlan, handlePlanChoose, handlePlanChange }) => {
	const { plan, title, amount, amountTitle, items } = payment;

	const currentPlanColor = useMemo(() => {
		if (plan === "Free Plan") {
			return "#12B76A";
		}

		if (isMonthlyPlan) {
			return "#DE0085";
		}

		return "#7B61FF";
	}, [plan, isMonthlyPlan]);

	return (
		<Box
			position="relative"
			display="flex"
			flexDirection="column"
			alignItems="center"
			flex={1}
			p={4}
			sx={{
				background: "#fff",
				boxShadow: "0px 10px 85px rgba(52, 64, 84, 0.09)",
				borderRadius: "16px",
			}}
		>
			{plan !== "Free Plan" && !isMonthlyPlan && (
				<Box
					position="absolute"
					display="flex"
					alignItems="center"
					justifyContent="center"
					top={0}
					sx={{
						background: "#7B61FF",
						width: "100%",
						height: "40px",
						borderTopLeftRadius: "16px",
						borderTopRightRadius: "16px",
					}}
				>
					<Typography variant="title_portfolio" color={"#fff"}>
						Most Popular
					</Typography>
				</Box>
			)}
			<Typography variant="title_portfolio" color={currentPlanColor} mt="38px">
				{plan}
			</Typography>
			<Box display="flex" alignItems="center" my={0.5} pl={plan !== "Free Plan" ? "106px" : 0}>
				<Typography variant="poppinsSemiBold20" color={currentPlanColor}>
					$
				</Typography>
				<Typography variant="card_title" color={currentPlanColor}>
					{isMonthlyPlan && plan !== "Free Plan" ? "49" : amount}
				</Typography>
				{plan !== "Free Plan" && (
					<Box position="relative" ml={2.5} sx={{ "&:hover": { cursor: "pointer" } }} onClick={handlePlanChange}>
						<PremiumSwitch checked={!isMonthlyPlan} />
						<Box position="absolute" top="1px" left={isMonthlyPlan ? "30px" : "14px"}>
							<Typography variant="chip_label" color={currentPlanColor}>
								{isMonthlyPlan ? "Monthly" : "Yearly"}
							</Typography>
						</Box>
					</Box>
				)}
			</Box>
			<Typography variant="subhead3" color="#475467" mb={2.5}>
				{isMonthlyPlan && plan !== "Free Plan" ? "Per month" : amountTitle}
			</Typography>
			<Typography variant="subhead1" color="#475467" sx={{ textAlign: "center", maxWidth: "450px" }}>
				{title}
			</Typography>
			<Box my={3.5}>
				{plan === "Free Plan" ? (
					<Box
						display="flex"
						alignItems="center"
						justifyContent="center"
						sx={{
							background: "#ECFDF3",
							boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
							borderRadius: "100px",
							height: "48px",
							width: "266px",
						}}
					>
						<Typography variant="poppinsSemiBold16" color="#12B76A">
							You’re already in!
						</Typography>
					</Box>
				) : (
					<Button
						onClick={handlePlanChoose}
						sx={{
							height: "48px",
							width: "266px",
							fontWeight: 600,
							fontSize: "16px",
							lineHeight: "24px",
							boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
							borderRadius: "100px",
							background: currentPlanColor,
							"&:hover": {
								background: currentPlanColor,
							},
							"&:active": {
								background: currentPlanColor,
							},
						}}
					>
						Upgrade to Premium
					</Button>
				)}
			</Box>
			<Box display="flex" width="100%" mb={2.5}>
				<Typography variant="subhead1" color="#475467">
					Everything in our &nbsp;
				</Typography>
				<Typography variant="subhead1" color="#475467" sx={{ fontWeight: 600 }}>
					Free Plan
				</Typography>
				{plan !== "Free Plan" && (
					<Typography variant="subhead1" color="#475467">
						&nbsp; plus...
					</Typography>
				)}
			</Box>
			<Box width="100%">
				{items.map((item) => (
					<Box key={item.id} display="flex" alignItems="flex-start" mb={2}>
						<CheckIcon color={currentPlanColor} />
						<Box ml={1.5}>
							<Typography variant="subhead1" color="#475467" sx={{ display: "block" }}>
								{item.title}
							</Typography>
							{item.desc && (
								<Typography variant="subhead1" color="#475467">
									{item.desc}
								</Typography>
							)}
						</Box>
					</Box>
				))}
			</Box>
		</Box>
	);
};

const CheckIcon = ({ color = "#12B76A" }) => {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M22.8122 8.52278L10.7737 20.5603C10.6344 20.6996 10.4691 20.8101 10.2871 20.8855C10.105 20.9609 9.90996 20.9997 9.71295 20.9997C9.51594 20.9997 9.32085 20.9609 9.13884 20.8855C8.95683 20.8101 8.79146 20.6996 8.65217 20.5603L1.93779 13.8103C1.65699 13.529 1.49927 13.1479 1.49927 12.7504C1.49927 12.353 1.65699 11.9718 1.93779 11.6906L4.18779 9.44059C4.46813 9.16018 4.84809 9.00217 5.2446 9.00112C5.64111 9.00006 6.0219 9.15605 6.30373 9.43496L9.73873 12.7415L9.74904 12.7518L18.4425 4.1859C18.7236 3.90595 19.1042 3.74878 19.5009 3.74878C19.8977 3.74878 20.2782 3.90595 20.5594 4.1859L22.8094 6.39465C22.9498 6.53388 23.0614 6.69951 23.1376 6.88203C23.2139 7.06455 23.2533 7.26033 23.2535 7.45813C23.2538 7.65592 23.2149 7.85181 23.1392 8.03453C23.0634 8.21725 22.9523 8.38318 22.8122 8.52278Z"
				fill={color}
			/>
		</svg>
	);
};
