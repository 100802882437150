import React from "react";
import { InputBase, Typography, styled } from "@mui/material";
import JsxParser from "react-jsx-parser";

export const TextArea = styled((props) =>
	props.usePrintable ? (
		<Typography variant="Text/sm/Regular" component="p" {...props} sx={{ width: "100%", boxSizing: "border-box", "& ul": { pl: "1rem" } }}>
			<JsxParser autoCloseVoidElements components={{ Typography }} jsx={props?.value} />
		</Typography>
	) : (
		<InputBase multiline fullWidth minRows={props?.minRows || 4} {...props} />
	)
)(({ theme, ...props }) => ({
	padding: props?.tight ? "1rem" : "1.5rem",
	borderRadius: "1.5rem",
	background: "#F9F9F9",
	boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
	"& .MuiInputBase-input, & .MuiInputBase-input::placeholder": {
		fontFamily: theme.typography["Text/sm/Regular"].fontFamily,
		fontSize: theme.typography["Text/sm/Regular"].fontSize,
		fontStyle: theme.typography["Text/sm/Regular"].fontStyle,
		fontWeight: theme.typography["Text/sm/Regular"].fontWeight,
		lineHeight: theme.typography["Text/sm/Regular"].lineHeight,
	},
	"& .MuiInputBase-input::placeholder": {
		color: "#667085",
		opacity: 1,
	},
	"&.Mui-error": {
		backgroundColor: "rgba(189, 61, 33, 0.1)",
		boxShadow: "0px 0px 0px 4px rgba(189,61,33,.2)",
	},
}));
