import React from "react";
import { Typography, Box } from "@mui/material";
import { CustomSlider } from "./styles";

const MuiSlider = (props) => {
	const handleChange = (event, newValue) => {
		props.setValue(newValue);
	};

	return (
		<Box
			sx={{
				p: 2,
				backgroundColor: (theme) => theme.palette.secondary.lightGrey,
				borderRadius: "4px",
				mt: 1,
				width: "fit-content",
			}}
		>
			<Box sx={{ ml: 2, mr: 2 }}>
				<CustomSlider {...props} defaultValue={0} marks={props.sliderArray} value={props?.value} onChange={handleChange} />
			</Box>

			<Box
				sx={{
					display: "flex",
				}}
			>
				<Box
					sx={{
						mr: "auto",
						textAlign: "center",
					}}
				>
					<Typography
						variant="title_medium"
						sx={{
							width: "50px",
							textAlign: "center",

							backgroundColor: "white",
							borderRadius: "15px",
							boxShadow: "2px 2px 2px rgba(0, 0, 0, 0.06)",
						}}
						display={"grid"}
					>
						{props?.currency && "$"}
						{props?.value?.[0] && props?.value?.[0] !== 0
							? Intl.NumberFormat("en-US", { notation: "compact", maximumFractionDigits: 1 }).format(Number(props.value[0]))
							: "0"}
					</Typography>
					<Typography
						sx={{
							width: "50px",
							textAlign: "center",
						}}
						variant="title_medium"
					>
						Min
					</Typography>
				</Box>
				<Box sx={{ textAlign: "center" }}>
					<Typography
						variant="title_medium"
						display={"grid"}
						sx={{
							width: "50px",
							textAlign: "center",
							boxShadow: "2px 2px 2px rgba(0, 0, 0, 0.06)",
							backgroundColor: "white",
							borderRadius: "15px",
						}}
					>
						{props?.currency && "$"}
						{props?.value?.[1] && props?.value?.[1] !== 0
							? Intl.NumberFormat("en-US", { notation: "compact", maximumFractionDigits: 1 }).format(Number(props?.value?.[1]))
							: props?.maxString || "1M"}
					</Typography>
					<Typography
						sx={{
							width: "50px",
							textAlign: "center",
						}}
						variant="title_medium"
					>
						Max
					</Typography>
				</Box>
			</Box>
		</Box>
	);
};

export default MuiSlider;
