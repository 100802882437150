import React, { useState, useEffect } from "react";
import { Box, Chip, Typography, Divider, TextField, Avatar, Button } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import CloseIcon from "@mui/icons-material/Close";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { POWERUP_BG_ICON } from "../../../../../constants/image";
import { RICH_TEXT_EDITOR_INLINE_CONTROLS, RICH_TEXT_EDITOR_MAIN_CONTROLS } from "../../../../../constants/plugin-constants";
import ROUTES_PATH from "../../../../../constants/routes";
import { Person } from "@mui/icons-material";
import { useHistory } from "react-router-dom";
import BreadcrumbsComp from "../../../../../containers/Workspace/PowerUP/IdeaValidation/Components/BreadcrumbsComp";
import { getCurrentDate } from "../../../../../utils/date";
import services from "../../../../../services";
import ScriptEditor from "../../../../../common/ScriptEditor";
import CuratorCardPopup from "../../../../CuratorCardPopup";
import { AddTagPopup } from "../../../../ResearchCurator/AddTag";
import AddReferencePopUp from "../../../../ResearchCurator/AddReferencePopUp";
import { useDispatch, useSelector } from "react-redux";
import { setAuthData } from "../../../../../modules/actions";
import { convertHtmlToPlainText } from "../../../../../utils/rte-utils";
import { handleMixPanel } from "../../../../../utils/mixPanelEventHandling";
import { USER_TYPES } from "../../../../../utils/userTypes";
import { toastContainer } from "../../../../../utils/toast";

const CreateQuickNote = ({
	userDataObj,
	startupData,
	setOpenQuickNote = () => {},
	setOpenResearchCuratorPanel = () => {},
	isExpandedView,
	researchCuratorId,
	isDetailView = false,
	getCardDetails,
	researchCuratorDetails,
	setNewCuratorId,
	callback,
	setCallback,
	handleDeleteNote,
	handleDeletePopup,
}) => {
	const cursorPointer = { cursor: "pointer" };
	const [title, setTitle] = useState("");
	const [researchCuratorIdValue, setResearchCuratorIdValue] = useState(researchCuratorId);
	const [researchCuratorObj, setResearchCuratorObj] = useState({});
	const history = useHistory();
	const [version, setVersion] = useState(1); // maintaining version to re-render the script component after Reset to default action
	const [description, setDescription] = useState("");
	const [openPopup, setOpenPopup] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const [openTag, setOpenTag] = useState(false);
	const [openReference, setOpenReference] = useState(false);
	const [selectedTags, setSelectedTags] = useState([]);
	const [selectedReferences, setSelectedReferences] = useState([]);
	const [detailsApiStatus, setDetailsApiStatus] = useState(false);
	const [createNoteApiStatus, setCreateNoteApiStatus] = useState(false);
	const dispatch = useDispatch();
	const userType = useSelector((store) => store?.auth?.userType);

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		});
	}, []);
	const handlePopupOpen = (event) => {
		setAnchorEl(event.currentTarget);

		setOpenPopup(true);
	};
	const getRoleValue = () => {
		if (userType === USER_TYPES.MENTOR_USER) {
			return "Mentor";
		} else if (userType === USER_TYPES.ADVISOR_USER) {
			return "Advisor";
		} else if (userType === USER_TYPES.CANDIDATE_USER) {
			return "Candidate";
		} else if (userType === USER_TYPES.INVESTOR_USER) {
			return "Investor";
		} else if (userType === USER_TYPES.PARTNER_USER) {
			return "Partner";
		} else if (userType === USER_TYPES.PROFESSOR_USER) {
			return "Instructor";
		} else if (userType === USER_TYPES.STUDENT_USER) {
			return "Student";
		} else if (userType === USER_TYPES.STARTUP_USER) {
			return "Teammate";
		} else {
			return "Founder";
		}
	};
	const handlePopupClose = (cb = false, id = null) => {
		setOpenPopup(false);
		if (cb) {
			getResearchCuratorDetails(id || researchCuratorIdValue);
		}
	};
	const handleOpenTag = () => {
		setOpenTag(!openTag);
		handlePopupClose();
	};

	const handleOpenReference = () => {
		setOpenReference(!openReference);
		handlePopupClose();
	};

	useEffect(() => {
		if (researchCuratorIdValue) {
			getResearchCuratorDetails(researchCuratorIdValue);
		} else {
			setDetailsApiStatus(true);
		}
	}, []);

	useEffect(() => {
		if (callback) {
			getResearchCuratorDetails(researchCuratorIdValue);
		}
	}, [callback]);

	const handleDescriptionChange = (value) => {
		// if (value.value !== description) {
		// 	handleApiCalls(null, "description", value.value);
		// }
		setDescription(value.value);
	};

	const handleApiCalls = (titleValue, type, descriptionValue) => {
		handleMixPanel("Discovery Board: Quick Note Saved");

		let payload = {
			noteType: "QUICK_NOTE",
			title: titleValue !== null ? titleValue : title,
			description: descriptionValue !== null ? descriptionValue : description,
			isPinned: researchCuratorObj["isPinned"],
		};
		if (researchCuratorIdValue) {
			updateResearchCurator(payload);
		} else {
			createResearchCurator(payload);
			// if (type !== "description") {
			// 	createResearchCurator(payload);
			// } else {
			// 	const plainText = convertHtmlToPlainText(descriptionValue);
			// 	if (plainText.length) {
			// 		createResearchCurator(payload);
			// 	}
			// }
		}
	};

	const handleInputChange = (e) => {
		let value = e.target.value;
		// if (title !== value) {
		// 	handleApiCalls(value, "title", null);
		// }
		setTitle(value);
	};
	const handleEditorData = (value) => {
		setDescription(value);
		setVersion(() => version + 1);
	};

	//Api to get research curator details
	const getResearchCuratorDetails = (researchCuratorId) => {
		services
			.getResearchCuratorDetailsApiCall(researchCuratorId)
			.then((response) => {
				if (response.data.message === "Success") {
					setResearchCuratorObj(response?.data?.data);
					setSelectedReferences(response?.data?.data?.references);
					setTitle(response?.data?.data?.title);
					setDetailsApiStatus(true);
					setResearchCuratorIdValue(response?.data?.data?.id);
					handleEditorData(response?.data?.data?.description);
					setSelectedTags(response?.data?.data?.tags);
					setCallback(false);
				}
			})
			.catch((e) => {
				console.log("Submit", e);
			});
	};

	//Api to create research curator
	const createResearchCurator = (payload) => {
		if (!createNoteApiStatus) {
			setCreateNoteApiStatus(true);

			services
				.addResearchCuratorApiCall(payload)
				.then((response) => {
					if (response.data.message === "Success") {
						setCreateNoteApiStatus(false);
						handleMixPanel("Quick Note Created", {
							"Quick Note ID": response?.data?.data?.id,
						});

						setResearchCuratorObj(response?.data?.data);
						setResearchCuratorIdValue(response?.data?.data?.id);
						setNewCuratorId(response?.data?.data?.id);
						//setTitle(response?.data?.data?.title);
						//setDescription(response?.data?.data?.description);
						toastContainer("Quick Note saved", "success");
					}
				})
				.catch((e) => {
					setCreateNoteApiStatus(false);

					console.log("Submit", e);
				});
		}
	};

	//Api to update research curator
	const updateResearchCurator = (payload) => {
		if (detailsApiStatus) {
			services
				.updateResearchCuratorApiCall(payload, researchCuratorIdValue)
				.then((response) => {
					if (response.data.message === "Success") {
						setResearchCuratorObj(response?.data?.data);
						setResearchCuratorIdValue(response?.data?.data?.id);
						//setTitle(response?.data?.data?.title);
						//setDescription(response?.data?.data?.description);
						toastContainer("Quick Note saved", "success");
					}
				})
				.catch((e) => {
					console.log("Submit", e);
				});
		}
	};

	return (
		<>
			<Box display="flex" justifyContent="flex-end" alignItems="center" mb={1}>
				<Box>
					<Button
						disabled={!title && !convertHtmlToPlainText(description)?.length > 0}
						sx={{ width: "max-content", ml: 2 }}
						onClick={() => handleApiCalls(title, null, description)}
					>
						Save
					</Button>
					<Button variant="outlined" sx={{ width: "max-content", ml: 2 }} onClick={() => history.push(ROUTES_PATH.RESEARCH_CURATOR_EXPAND)}>
						Close
					</Button>
				</Box>
			</Box>
			<Box
				sx={(theme) => ({
					pb: 2,
					pt: 3,
					pb: 4,
					background: theme.palette.secondary.quickNoteBg,
				})}
			>
				{!isExpandedView && (
					<Box sx={{ display: "flex", p: 3 }} gap={2} justifyContent="right">
						<OpenInNewIcon
							sx={cursorPointer}
							onClick={() => {
								setOpenQuickNote(false);
								setOpenResearchCuratorPanel(false);
								history.push(
									researchCuratorIdValue ? `${ROUTES_PATH.CREATE_QUICK_NOTE}/${researchCuratorIdValue}` : ROUTES_PATH.CREATE_QUICK_NOTE
								);
								dispatch(setAuthData({ currentSelectedNavItem: "discovery-board" }));
							}}
						/>
						<CloseIcon
							sx={cursorPointer}
							onClick={() => {
								getCardDetails();
								setOpenQuickNote(false);
							}}
						/>
					</Box>
				)}
				{isExpandedView ? "" : <Divider sx={{ background: (theme) => theme.palette.secondary.dark }} />}

				<Box sx={{ display: "flex", px: 3, pt: isExpandedView ? 0 : 3 }} alignItems="center">
					<TextField
						type="text"
						variant="standard"
						spellCheck="true"
						placeholder="Title"
						multiline
						minRows={1}
						sx={{ pr: 2, width: 1 }}
						InputProps={{
							disableUnderline: true,
							readOnly: isDetailView,
							style: {
								fontSize: "22px",
								lineHeight: "28px",
								fontFamily: '"PoppinsMedium",  "Helvetica", "Arial", sans-serif',
							},
						}}
						value={title}
						onChange={(e) => handleInputChange(e)}
						autoFocus
						//onFocus={e => {
						//  e.target.value === "" &&
						//    handleApiCalls(e.target.value, "title", null);
						//}}
					/>
					{!isDetailView && researchCuratorIdValue && <MoreVertIcon sx={{ ml: "auto", cursor: "pointer" }} onClick={handlePopupOpen} />}
				</Box>
				<Box sx={{ px: 3, pt: 1 }}>
					<Typography variant="caption" sx={{ display: "grid", mt: 1 }}>
						Created {getCurrentDate()}
					</Typography>
					<Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
						<Typography variant="caption" sx={{ display: "grid" }}>
							Created by
						</Typography>
						<br />
						{userDataObj?.picture || researchCuratorObj?.userModel?.picture ? (
							<Avatar
								alt="picture"
								src={researchCuratorObj?.userModel?.picture ? researchCuratorObj?.userModel?.picture : userDataObj?.picture}
								sx={{ ml: 1, width: 28, height: 28 }}
							/>
						) : (
							<Avatar
								sx={{
									backgroundColor: (theme) => theme.palette.secondary.greyBg,
									color: (theme) => theme.palette.secondary.grey,
									ml: 1,
									width: 28,
									height: 28,
								}}
							>
								<Person />
							</Avatar>
						)}

						<Divider />
					</Box>

					<Box sx={{ pt: 2 }}>
						{researchCuratorObj?.references?.map((item) => {
							return (
								<Chip
									sx={(theme) => ({
										border: `1px solid  ${theme.palette.secondary.dark}`,
										mb: 1,
										mr: 1,
									})}
									avatar={<Avatar alt="powerup" src={POWERUP_BG_ICON} />}
									label={<Typography variant="label_large"> {item?.entityName}</Typography>}
									variant="outlined"
								/>
							);
						})}

						<Box
							sx={{
								display: "flex",
								gap: 1,
								flexWrap: "wrap",
							}}
						>
							{researchCuratorObj?.tags?.map((item) => {
								return (
									<Chip
										label={<Typography variant="label_large">{item?.tag?.name}</Typography>}
										variant="outlined"
										sx={(theme) => ({
											border: `1px solid  ${theme.palette.secondary.dark}`,
										})}
									/>
								);
							})}
						</Box>
					</Box>
					{researchCuratorObj?.tags?.length || researchCuratorObj?.references?.length ? <Divider sx={{ my: 2 }} /> : ""}

					<Typography variant="title_medium" display={"grid"}>
						Note
					</Typography>

					<Box sx={{ minHeight: "150px" }}>
						<ScriptEditor
							version={version}
							label={isDetailView ? "" : "Add text here..."}
							initialValue={description}
							onChange={(value) => handleDescriptionChange(value)}
							//onBlur={() => {
							//  handleApiCalls(null);
							//}}
							readOnly={isDetailView}
							toolbar={!isDetailView}
							inlineToolbar={false}
							inlineToolbarControls={RICH_TEXT_EDITOR_INLINE_CONTROLS}
							controls={RICH_TEXT_EDITOR_MAIN_CONTROLS}
						/>
					</Box>
				</Box>
			</Box>

			<CuratorCardPopup
				open={openPopup}
				onClose={handlePopupClose}
				anchorEl={anchorEl}
				handleDeleteNote={handleDeleteNote}
				handleOpenTag={handleOpenTag}
				handleOpenReference={handleOpenReference}
				curatorId={researchCuratorIdValue}
				handleDeletePopup={handleDeletePopup}
				researchCuratorDetails={researchCuratorDetails}
				isQuikNote={true}
				cardData={researchCuratorObj}
			/>
			<AddTagPopup
				open={openTag}
				onClose={handleOpenTag}
				noteId={researchCuratorIdValue}
				selectedTags={selectedTags}
				setCallback={setCallback}
				isQuikNote
				setResearchCuratorIdValue={setResearchCuratorIdValue}
			/>
			<AddReferencePopUp
				open={openReference}
				onClose={handleOpenReference}
				noteId={researchCuratorIdValue}
				selectedReferences={selectedReferences}
				setCallback={setCallback}
				isQuikNote
				setResearchCuratorIdValue={setResearchCuratorIdValue}
			/>
		</>
	);
};

export default CreateQuickNote;
