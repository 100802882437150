import { NotificationsOff } from "@mui/icons-material";
import { Box, CircularProgress, Typography, Container, Tooltip, Button } from "@mui/material";

import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { PullNotifications, DeleteAllNotifications } from "../../utils/notificationControl";
import NotificationItem from "./NotificationItem";
import DialogComponent from "../../common/CommonDialog";

import { CSSTransition, TransitionGroup } from "react-transition-group";

import "./styles.css";
import { NOTIFICATION_BELL } from "../../constants";

function FullNotificationList({ ...props }) {
	const notificationData = useSelector((state) => state?.notifications);
	const notificationLoading = useSelector((state) => state?.notifications?.notificationLoading);
	const [loading, setloader] = useState(notificationLoading);
	const [notificationList, setnotificationList] = useState([]);

	useEffect(() => {
		if (notificationData) {
			// Ensure that the '5' count rule is excluding any system messages.
			try {
				const rebuiltArray = [
					...notificationData?.notificationList?.filter((x) => x.type && x.type === "system-message"),
					...notificationData?.notificationList?.filter((x) => !x.type || x.type !== "system-message"),
					//?.slice(0, 5),
				];
				setnotificationList(rebuiltArray);
			} catch (e) {
				console.log(e);
			}
		}
	}, [notificationData]);

	useEffect(() => {
		if (props?.open == true) PullNotifications({ lastIndex: 0, perPage: 10 }); // Show latest 5 notifications in the popover.
	}, [props?.open]);

	useEffect(() => {
		if (!props?.asPanel) {
			PullNotifications({ lastIndex: 0, perPage: 10 });
		}
	}, []);

	useEffect(() => {
		/* 
            This function adds a delay to the UI loader. 
            In many cases the calls complete very quickly, which lead to 'flashing' 
            of the loader component. Here we wait a second after the global load state 
            completes, then update the local component state.
            Ex:
            If the local state is true (show loader) and 
            the global loading state goes to false. We will wait one second before matching
            the local state to the global. 
            If the local state is false (no loader shown) and the global state is loading
            we immediately match the local state to show the loader. 
        */
		if (loading && !notificationLoading) {
			setTimeout(() => {
				setloader(false);
			}, 1000);
		} else if (!loading && notificationLoading) {
			setloader(true);
		}
	}, [notificationLoading]);

	const [showClearConfirm, setshowClearConfirm] = useState(false);
	const [deleteProcessing, setdeleteProcessing] = useState(false);
	const handleClearAll = () => {
		setdeleteProcessing(true);
		DeleteAllNotifications(() => {
			setshowClearConfirm(false);
			setdeleteProcessing(false);
			PullNotifications();
		});
	};

	const ClearConfirm = (
		<DialogComponent
			isOpen={showClearConfirm}
			onClose={() => setshowClearConfirm(false)}
			title={
				<Box
					display="flex"
					flexDirection="column"
					pl={1}
					pt={2}
					sx={{
						gap: "12px",
						"& > svg > path": {
							stroke: (theme) => theme?.palette?.destructive?.main,
						},
					}}
				>
					<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
						<path
							d="M12 4H20M4 8H28M25.3333 8L24.3983 22.0257C24.258 24.13 24.1879 25.1822 23.7334 25.98C23.3332 26.6824 22.7297 27.247 22.0022 27.5996C21.176 28 20.1215 28 18.0125 28H13.9875C11.8785 28 10.824 28 9.99778 27.5996C9.27034 27.247 8.66678 26.6824 8.26664 25.98C7.81215 25.1822 7.742 24.13 7.60171 22.0257L6.66667 8M13.3333 14V20.6667M18.6667 14V20.6667"
							stroke-width="2"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
					</svg>

					<Typography variant="Text/md/Semibold">Are you sure?</Typography>
				</Box>
			}
			titleVariant="title_medium"
			maxWidth="sm"
			isHeadeBottom={false}
			contentStyle={{ px: 0 }}
			PaperProps={{
				style: {
					borderRadius: 20,
					width: "100%",
				},
			}}
			disableBackDropClick
		>
			<Box sx={{ p: "0px 32px 8px" }}>
				<Typography variant="Text/sm/Regular">This will clear all notifications.</Typography>
				<Box display={"flex"} alignItems="center" justifyContent="flex-end" sx={{ gap: 2 }}>
					<Box>
						<Button
							variant="DS1"
							color="clear"
							onClick={() => {
								setshowClearConfirm(false);
							}}
						>
							Cancel
						</Button>
					</Box>
					<Box>
						<Button
							variant="DS1"
							color="destructive"
							onClick={handleClearAll}
							disabled={deleteProcessing}
							startIcon={
								deleteProcessing && (
									<Box sx={{ pr: 1 }}>
										<CircularProgress size="1.25rem" sx={{ color: "white" }} />
									</Box>
								)
							}
						>
							{deleteProcessing ? "Deleting" : "Yes"}
						</Button>
					</Box>
				</Box>
			</Box>
		</DialogComponent>
	);

	return (
		<Box
			sx={{
				flex: 1,
				...(!props?.asPanel && {
					px: 8,
					py: 2,
				}),
			}}
		>
			{/*{!props?.asPanel ? (
				<Fragment>
					<Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
						<Box sx={{ height: "32px", width: "32px" }} component={"img"} src={NOTIFICATION_BELL} />
						<Typography variant="Text/lg/Semibold" sx={{ flex: 1 }}>
							Notifications
						</Typography>
						<Button
							small
							variant="DS1"
							color="destructive"
							onClick={() => setshowClearConfirm(true)}
							disabled={notificationList?.length <= 0 || !notificationList}
						>
							Clear All
						</Button>
					</Box>
				</Fragment>
			) : (
				<Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
					<Button
						small
						variant="DS1"
						color="destructive"
						onClick={() => setshowClearConfirm(true)}
						disabled={notificationList?.length <= 0 || !notificationList}
					>
						Clear All
					</Button>
				</Box>
			)}*/}
			{ClearConfirm}

			{notificationList?.map((item, index) => (
				<CSSTransition key={`full_${item.NOTIFICATION_ID}`} in={true} appear={true} timeout={100 + index * 50} classNames="open">
					<Box
						sx={{
							transition: "all 0.3s ease-in-out",
							opacity: 0,
							transform: "translateX(50px)",
							maxHeight: 0,
							"&.open-exit-done": {
								opacity: 0,
								transform: "translateX(50px)",
								borderBottom: "none",
								maxHeight: 0,
							},
							"&.open-exit-active": {
								opacity: 1,
								transform: "translateX(0px)",
								borderBottom: "none",
								maxHeight: "500px",
							},
							"&.open-enter-done": {
								opacity: 1,
								transform: "translateX(0px)",
								...(index + 1 != notificationList?.length && {
									borderBottom: "1px solid #D0D5DD",
								}),
								maxHeight: "500px",
							},
							"&.open-enter-active": {
								opacity: 1,
								transform: "translateX(0px)",
								borderBottom: "none",
								maxHeight: "500px",
							},
						}}
					>
						<NotificationItem
							notification={item}
							clickCallback={() => {
								if (typeof props?.clickCallback == "function") props?.clickCallback();
							}}
						/>
					</Box>
				</CSSTransition>
			))}

			{(notificationList?.length <= 0 || !notificationList) && (
				<Box
					sx={{
						flex: 1,
						height: "100%",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						flexDirection: "column",
						my: 2,
						gap: 1,
						color: (theme) => theme.palette.secondary.main,
					}}
				>
					{loading ? (
						<Fragment>
							<Box>
								<CircularProgress />
							</Box>
							<Box>
								<Typography variant="label_large">Checking for Notifications ...</Typography>
							</Box>
						</Fragment>
					) : (
						<Fragment>
							<Box
								sx={{
									"& > svg > path": {
										stroke: (theme) => theme.palette.primary.main,
									},
								}}
							>
								<svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none">
									<path
										d="M10.6666 19.1666C10.6666 19.1666 12.6666 21.8333 16 21.8333C19.3333 21.8333 21.3333 19.1666 21.3333 19.1666M20 12.5H20.0133M12 12.5H12.0133M29.3333 16.5C29.3333 23.8638 23.3638 29.8333 16 29.8333C8.63616 29.8333 2.66663 23.8638 2.66663 16.5C2.66663 9.13616 8.63616 3.16663 16 3.16663C23.3638 3.16663 29.3333 9.13616 29.3333 16.5ZM20.6666 12.5C20.6666 12.8681 20.3681 13.1666 20 13.1666C19.6318 13.1666 19.3333 12.8681 19.3333 12.5C19.3333 12.1318 19.6318 11.8333 20 11.8333C20.3681 11.8333 20.6666 12.1318 20.6666 12.5ZM12.6666 12.5C12.6666 12.8681 12.3681 13.1666 12 13.1666C11.6318 13.1666 11.3333 12.8681 11.3333 12.5C11.3333 12.1318 11.6318 11.8333 12 11.8333C12.3681 11.8333 12.6666 12.1318 12.6666 12.5Z"
										stroke-width="1.5"
										stroke-linecap="round"
										stroke-linejoin="round"
									/>
								</svg>
							</Box>
							<Box>
								<Typography variant="Text/sm/Regular">You’re all caught up! No notifications here.</Typography>
							</Box>
						</Fragment>
					)}
				</Box>
			)}
		</Box>
	);
}

export default FullNotificationList;
