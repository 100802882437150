import * as React from "react";
import Drawer from "@mui/material/Drawer";

export default function TemporaryDrawer({
  open,
  onClose,
  container,
  position = "bottom",
  hideBackdrop = false,
  onBackdropClick = () => {},
  sx = {
    "& .MuiPaper-root": {
      borderRadius: "16px 16px 0px 0px",
    },
  },
  ...props
}) {
  return (
    <React.Fragment>
      <Drawer
        container={container}
        anchor={position}
        open={open}
        BackdropProps={{ invisible: hideBackdrop }}
        onClose={() => onClose()}
        onBackdropClick={onBackdropClick}
        sx={sx}
      >
        {props.children}
      </Drawer>
    </React.Fragment>
  );
}
