import React, { useEffect, useState } from "react";
import { Typography, Box, Button, CircularProgress, Stack, useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setCommonDialogConfirmationData } from "../../../../modules/actions";
import CommonSlideDialog from "../../../../common/CommonSlideDialog";
import { PARTNER_QA_ICON } from "../../../../constants";
import UpdatedTextInputField from "../../../../common/UpdatedTextInputField";
import services from "../../../../services";
import { toastContainer } from "../../../../utils/toast";
import messages from "../../../../constants/messages";
import AddRoundedIcon from "@mui/icons-material/AddRounded";

export default function AddQuestionsDialog() {
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();
	const { isOpen, title, partnerQuestions, isEdit, questionObj, getPartnerQuestions, setCurrentTab } = useSelector(
		(store) => store?.commonDialogConfirmation
	);
	const [question, setQuestion] = useState(null);
	const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
	useEffect(() => {
		setQuestion(questionObj?.name || "");
	}, [isOpen]);
	const handleClose = () => {
		dispatch(setCommonDialogConfirmationData({ text: "", title: "", isOpen: false, isEdit: false, questionObj: null }));
	};

	const handleSave = async () => {
		const payload = {
			name: question,
		};

		setLoading(true);
		if (isEdit) {
			try {
				let response = await services.updatePartnerQuestionAPI(payload, questionObj?.id);
				if (response) {
					setLoading(false);
					toastContainer(messages.CHANGES_SAVE_SUCCESS);
					getPartnerQuestions();
					setCurrentTab(0);
					handleClose();
				}
			} catch (error) {
				setLoading(false);

				console.log(error);
			}
		} else {
			try {
				let response = await services.addPartnerQuestionAPI(payload);
				if (response) {
					setLoading(false);
					getPartnerQuestions();
					setCurrentTab(0);
					toastContainer(messages.QUESTION_ADDED_SUCCESS);
					handleClose();
				}
			} catch (error) {
				setLoading(false);

				console.log(error);
			}
		}
	};

	return (
		<CommonSlideDialog
			open={isOpen}
			slidingEffect={false}
			onClose={loading ? () => {} : () => handleClose()}
			title={
				<Box display="flex" flexDirection="column" p="0 0 8px">
					{/* <Box component={"img"} src={PARTNER_QA_ICON} sx={{ height: "24px", width: "24px" }} /> */}
					{isEdit ? <Box component={"img"} src={PARTNER_QA_ICON} sx={{ height: "24px", width: "24px" }} /> : <AddRoundedIcon />}
					<Typography variant="Text/md/Semibold" sx={{ mt: 1.5 }}>
						{title}
					</Typography>
				</Box>
			}
			titleVariant="Text/md/Semibold"
			maxWidth="lg"
			btnCloseStyle={{ margin: "0 24px 0 0" }}
			dialogAction={
				<Box display={"flex"} alignItems={"center"} gap={2}>
					{" "}
					<Button disabled={loading} onClick={handleClose} variant="noOutlineNew">
						Cancel
					</Button>
					<Button
						disabled={loading}
						onClick={handleSave}
						startIcon={loading && <CircularProgress size={"1.25rem"} sx={{ color: "inherit" }} />}
						variant="DS1"
					>
						{`${isEdit ? "Save" : "Create"}`}
					</Button>
				</Box>
			}
			PaperProps={{
				sx: (theme) => ({
					position: "fixed",
					top: 70,
					borderRadius: "30px",
					m: 0,
					p: 2,
					[theme.breakpoints.down("md")]: {
						px: 1,
						py: 1.5,
					},
					[theme.breakpoints.down("sm")]: {
						p: 0,
						m: -1,
					},
				}),
			}}
		>
			<Stack>
				{<Typography variant="Text/sm/Regular">{`${isEdit ? "Edit a" : "Create a new"} question by filling out the details below!`}</Typography>}
				<Typography sx={{ color: "#344054", pt: 2 }} component={"p"} variant="Text/sm/Semibold">
					{!isEdit && `Question (No. ${partnerQuestions?.length + 1 || 1})`}
				</Typography>

				<UpdatedTextInputField
					id={title}
					label={""}
					inputProps={{ maxLength: 5000 }}
					handleChange={(e) => {
						setQuestion(e.target.value);
					}}
					value={question}
					textFieldProps={{
						fullwidth: true,
						width: isSmallScreen ? "100%" : "600px",
						height: "48px",
					}}
				/>
			</Stack>
		</CommonSlideDialog>
	);
}
