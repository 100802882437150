import {
  Box,
  Button,
  Link,
  Typography,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { Add } from "@mui/icons-material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import GoogleIcon from "@mui/icons-material/Google";
import AppleIcon from "@mui/icons-material/Apple";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import {
  generateGoogleCalendarUrl,
  generateIcsCalendarFile,
  generateYahooCalendarUrl,
} from "./calendars-url-generator";
import theme from "../../constants/Theme";

const AddToCalendar = ({
  calendarEvent,
  buttonVariant,
  toggleType,
  text,
  startIcon,
}) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };
  return (
    <Box>
      {/* Web */}
      <Button
        ref={anchorRef}
        variant={buttonVariant}
        startIcon={startIcon}
        onClick={() => setOpen(!open)}
        sx={{ display: { xs: "none", md: "flex" } }}
      >
        {text}
      </Button>
      {/* Mob */}
      <>
        <ListItemButton
          onClick={() => setOpen(!open)}
          sx={{ display: { xs: "flex", md: "none" } }}
        >
          <ListItemIcon>{startIcon}</ListItemIcon>
          <ListItemText>
            <Typography variant="subhead5">{text}</Typography>
          </ListItemText>
        </ListItemButton>
      </>

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement="top"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper sx={{ p: 1 }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  <MenuItem>
                    <GoogleIcon sx={{ mr: 1 }} />{" "}
                    <Link
                      href={generateGoogleCalendarUrl(calendarEvent)}
                      target="_blank"
                      underline="hover"
                      variant="title_medium"
                      color={theme.palette.secondary.dark}
                    >
                      <Typography>Add To Google</Typography>
                    </Link>
                  </MenuItem>
                  <MenuItem>
                    <AppleIcon sx={{ mr: 1 }} />{" "}
                    <Link
                      href={generateIcsCalendarFile(calendarEvent)}
                      target="_blank"
                      underline="hover"
                      variant="title_medium"
                      color={theme.palette.secondary.dark}
                    >
                      <Typography>Apple Calendar</Typography>
                    </Link>
                  </MenuItem>
                  <MenuItem>
                    <InsertInvitationIcon sx={{ mr: 1 }} />{" "}
                    <Link
                      href={generateYahooCalendarUrl(calendarEvent)}
                      target="_blank"
                      underline="hover"
                      variant="title_medium"
                      color={theme.palette.secondary.dark}
                    >
                      <Typography>Yahoo Calendar</Typography>
                    </Link>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
};

{
  AddToCalendar.propTypes = {
    calendarEvent: PropTypes.object,
    buttonVariant: PropTypes.string,
    text: PropTypes.string,
    startIcon: PropTypes.func,
  };

  AddToCalendar.defaultProps = {
    calendarEvent: null,
    buttonVariant: "contained",
    text: "Add to calendar",
    startIcon: <Add />,
  };
}

export default AddToCalendar;
