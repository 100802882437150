import React, { useEffect } from "react";
import { Card, Typography, Box, Button, useMediaQuery, IconButton } from "@mui/material";
import { Add, AddCommentOutlined, ArrowBack } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { actionCreators } from "../../../../Messaging/store";
import { bindActionCreators } from "redux";
import { useSelector } from "react-redux";
import CreateConversationSearch from "./CreateConversationSearch";
import MessageInputField from "../message/MessageInputField";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import ReplyIcon from "@mui/icons-material/Reply";

export default function NewConversation({ client }) {
	const dispatch = useDispatch();
	const { updateCreatingNewConversationState, newConversationWith, updateCurrentConversation } = bindActionCreators(actionCreators, dispatch);
	const createConversationStatus = useSelector((state) => state.chatState?.createConversationStatus);
	const customPoint = "820"; //figma
	const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down(customPoint));

	useEffect(() => {
		if (createConversationStatus) {
			updateCurrentConversation("");
		}
	}, [createConversationStatus]);

	return (
		<Box
			sx={{
				width: 1,
				height: 1,
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
				backgroundColor: (theme) => theme.palette.secondary.white,
				borderRadius: "8px",
			}}
		>
			{createConversationStatus ? (
				<></>
			) : (
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						flexDirection: "column",
						textAlign: "center",
						gap: 1,
					}}
				>
					<Box>
						<AddCommentOutlined color="primary" fontSize="large" sx={{ mb: 1 }} />
					</Box>
					<Typography
						variant="Text/sm/Regular"
						sx={(theme) => ({
							textAlign: "center",
							color: theme.palette.secondary.outline,
						})}
					>
						Start your first conversation
					</Typography>
					<Box width={1}>
						<Button
							startIcon={<AddRoundedIcon />}
							variant="DS1"
							//sx={{ display: "flex", alignItems: "center", gap: 2 }}
							onClick={() => updateCreatingNewConversationState(true)}
						>
							New Message
						</Button>
					</Box>
				</Box>
			)}
		</Box>
	);
}
