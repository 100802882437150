import React, { useEffect, useState } from "react";
import moment from "moment";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Typography, InputAdornment, IconButton, TextField, Grid } from "@mui/material";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";

import { OnboardContainer, PwdValidate } from "../ui";
import VisibilityOffIcon from "../../Assets/visibility-off-icon.svg";
import VisibilityOnIcon from "../../Assets/visibility-on-icon.svg";

const schema = yup
	.object({
		firstName: yup.string().required("First Name is required"),
		lastName: yup.string().required("Last Name is required"),
		email: yup.string().email().required("Email is required"),
		password: yup.string().min(8).required("Password is required"),
		confirmPassword: yup.string().oneOf([yup.ref("password"), null], "Passwords don't match"),
	})
	.required();

export const CreateAccount = ({ isNext, userType, currentStep, data, onActive, onUpdate, onError }) => {
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);
	const [validatePwd, setValidatePwd] = useState("");

	const {
		setValue,
		getValues,
		control,
		formState: { errors, isValid },
	} = useForm({
		mode: "all",
		resolver: yupResolver(schema),
	});

	useEffect(() => {
		setValue("firstName", data?.firstName || "");
		setValue("lastName", data?.lastName || "");
		setValue("email", data?.email || "");
		setValue("password", data?.password || "");
		setValue("confirmPassword", data?.password || "");
		setValidatePwd(data?.password || "");
	}, [data]);

	const handlePwd = (pwd, onChange) => {
		setValidatePwd(pwd);
		onChange(pwd);
	};

	useEffect(() => {
		if (isValid) {
			onActive(true);
		} else {
			onActive(false);
		}
	}, [isValid]);

	useEffect(() => {
		onError(errors);
	}, [Object.keys(errors).length]);

	useEffect(() => {
		if (isNext) {
			onUpdate(currentStep, {
				firstName: getValues("firstName"),
				lastName: getValues("lastName"),
				email: getValues("email"),
				password: getValues("password"),
				emailVerified: false,
				emailToken: null,
				timeZone: moment.tz.guess(),
			});
		}
	}, [isNext]);

	return (
		<OnboardContainer title="My Account">
			<form>
				<Grid container spacing="40px">
					<Grid item xs={12} md={6}>
						<Box>
							<Typography variant="poppinsSemiBold18">First Name*</Typography>
							<Controller
								name="firstName"
								control={control}
								render={({ field: { onChange, value, name } }) => (
									<TextField
										fullWidth
										color="primary"
										variant="outlined"
										size="lg"
										rounded={true}
										id="firstName"
										placeholder="Enter your first name"
										value={value}
										name={name}
										sx={{
											mt: 1.25,
											"& .MuiOutlinedInput-notchedOutline": {
												border: errors.firstName ? "5px solid rgba(240, 68, 56, 0.3) !important" : "1px solid #DDDDDD",
											},
										}}
										InputProps={{
											style: {
												borderRadius: "30px",
												height: "60px",
												fontWeight: "500",
												fontSize: "16px",
												lineHeight: "24px",
												padding: "1.5px 6px",
											},
											startAdornment: errors.firstName ? (
												<InputAdornment position="end">
													<IconButton>
														<ErrorOutlineOutlinedIcon sx={{ color: "#F04438" }} />
													</IconButton>
												</InputAdornment>
											) : null,
										}}
										onChange={onChange}
									/>
								)}
							/>
						</Box>
					</Grid>
					<Grid item xs={12} md={6}>
						<Box>
							<Typography variant="poppinsSemiBold18">Last Name*</Typography>
							<Controller
								name="lastName"
								control={control}
								render={({ field: { onChange, value, name } }) => (
									<TextField
										fullWidth
										id="lastName"
										placeholder="Enter your last name"
										value={value}
										name={name}
										sx={{
											mt: 1.25,
											"& .MuiOutlinedInput-notchedOutline": {
												border: errors.lastName ? "5px solid rgba(240, 68, 56, 0.3) !important" : "1px solid #DDDDDD",
											},
										}}
										InputProps={{
											style: {
												borderRadius: "30px",
												height: "60px",
												fontWeight: "500",
												fontSize: "16px",
												lineHeight: "24px",
												padding: "1.5px 6px",
											},
											startAdornment: errors.lastName ? (
												<InputAdornment position="end">
													<IconButton>
														<ErrorOutlineOutlinedIcon sx={{ color: "#F04438" }} />
													</IconButton>
												</InputAdornment>
											) : null,
										}}
										onChange={onChange}
									/>
								)}
							/>
						</Box>
					</Grid>
					<Grid item xs={12} md={6}>
						<Box>
							<Typography variant="poppinsSemiBold18">Email Address*</Typography>
							<Controller
								name="email"
								control={control}
								render={({ field: { onChange, value, name } }) => (
									<TextField
										fullWidth
										id="email"
										placeholder="Enter your email"
										value={value}
										name={name}
										sx={{
											mt: 1.25,
											"& .MuiOutlinedInput-notchedOutline": {
												border: errors.email ? "5px solid rgba(240, 68, 56, 0.3) !important" : "1px solid #DDDDDD",
											},
										}}
										InputProps={{
											style: {
												borderRadius: "30px",
												height: "60px",
												fontWeight: "500",
												fontSize: "16px",
												lineHeight: "24px",
												padding: "1.5px 6px",
											},
											startAdornment: errors.email ? (
												<InputAdornment position="end">
													<IconButton>
														<ErrorOutlineOutlinedIcon sx={{ color: "#F04438" }} />
													</IconButton>
												</InputAdornment>
											) : null,
										}}
										onChange={onChange}
									/>
								)}
							/>
						</Box>
					</Grid>
					<Grid
						item
						xs={12}
						md={6}
						sx={(theme) => ({
							display: "block",
							[theme.breakpoints.down("md")]: {
								display: "none",
							},
						})}
					></Grid>
					<Grid item xs={12} md={6}>
						<Box>
							<Typography variant="poppinsSemiBold18">Create Password*</Typography>
							<Controller
								name="password"
								control={control}
								render={({ field: { onChange, value, name } }) => (
									<TextField
										fullWidth
										id="password"
										placeholder="Create your password"
										type={showPassword ? "text" : "password"}
										value={value}
										name={name}
										sx={{
											mt: 1.25,
											"& .MuiOutlinedInput-notchedOutline": {
												border: errors.password ? "5px solid rgba(240, 68, 56, 0.3) !important" : "1px solid #DDDDDD",
											},
										}}
										InputProps={{
											style: {
												borderRadius: "30px",
												height: "60px",
												fontWeight: "500",
												fontSize: "16px",
												lineHeight: "24px",
												padding: "1.5px 6px",
											},
											startAdornment: errors.password ? (
												<InputAdornment position="end">
													<IconButton>
														<ErrorOutlineOutlinedIcon sx={{ color: "#F04438" }} />
													</IconButton>
												</InputAdornment>
											) : null,
											endAdornment: (
												<InputAdornment position="end" sx={{ mr: 2.5 }}>
													<IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
														{showPassword ? (
															<img src={VisibilityOnIcon} alt="Visibility on" />
														) : (
															<img src={VisibilityOffIcon} alt="Visibility Off" />
														)}
													</IconButton>
												</InputAdornment>
											),
										}}
										onChange={(e) => handlePwd(e.target.value, onChange)}
									/>
								)}
							/>
						</Box>
					</Grid>
					<Grid item xs={12} md={6}>
						<Box>
							<Typography variant="poppinsSemiBold18">Confirm Password*</Typography>
							<Controller
								name="confirmPassword"
								control={control}
								render={({ field: { onChange, value, name } }) => (
									<TextField
										fullWidth
										id="confirmPassword"
										placeholder="Confirm your password"
										type={showConfirmPassword ? "text" : "password"}
										value={value}
										name={name}
										sx={{
											mt: 1.25,
											"& .MuiOutlinedInput-notchedOutline": {
												border: errors.confirmPassword ? "5px solid rgba(240, 68, 56, 0.3) !important" : "1px solid #DDDDDD",
											},
										}}
										InputProps={{
											style: {
												borderRadius: "30px",
												height: "60px",
												fontWeight: "500",
												fontSize: "16px",
												lineHeight: "24px",
												padding: "1.5px 6px",
											},
											startAdornment: errors.confirmPassword ? (
												<InputAdornment position="end">
													<IconButton>
														<ErrorOutlineOutlinedIcon sx={{ color: "#F04438" }} />
													</IconButton>
												</InputAdornment>
											) : null,
											endAdornment: (
												<InputAdornment position="end" sx={{ mr: 2.5 }}>
													<IconButton onClick={() => setShowConfirmPassword(!showConfirmPassword)} edge="end">
														{showConfirmPassword ? (
															<img src={VisibilityOnIcon} alt="Visibility on" />
														) : (
															<img src={VisibilityOffIcon} alt="Visibility Off" />
														)}
													</IconButton>
												</InputAdornment>
											),
										}}
										onChange={onChange}
									/>
								)}
							/>
						</Box>
					</Grid>
					<Grid item xs={12} md={6}>
						<PwdValidate pwd={validatePwd} />
					</Grid>
				</Grid>
			</form>
		</OnboardContainer>
	);
};
