import { Box, Typography, Stack } from "@mui/material";
import React from "react";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import DialogComponent from "../../../../common/CommonDialog";
export default function SuccessPopup({
  connectSuccessPopup,
  setConnectSuccessPopup,
  startupName,
  type,
}) {
  return (
    <DialogComponent
      isOpen={connectSuccessPopup}
      onClose={() => setConnectSuccessPopup(false)}
      title={
        type === "CONNECT"
          ? "Connect Request Sent"
          : type === "ACCEPTED"
          ? "Connect Request Accepted"
          : "Connect Request Cancelled"
      }
      titleVariant="title_large"
      PaperProps={{
        sx: theme => ({ borderRadius: theme.shape.standard.borderRadius }),
      }}
      maxWidth={"xs"}
      footerStyle={{
        border: "none",
      }}
    >
      <Box sx={{ m: 6 }} textAlign="center">
        <CheckCircleOutlinedIcon
          sx={{ height: "56px", width: "56px" }}
          color="success"
        />
        <Box sx={{ mt: 3 }}>
          {type === "CONNECT" ? (
            <Stack>
              <Typography variant="headline6">
                Your connection request was sent.
              </Typography>
            </Stack>
          ) : (
            <Typography variant="headline6">
              {type === "ACCEPTED"
                ? "Your connection request was accepted."
                : "Your connection request was cancelled."}
            </Typography>
          )}
        </Box>
        {/*<Box sx={{ mt: 3 }}>
          {type === "CONNECT" ? (
            <Typography variant="bodyLarge">
              You will be notified via email if {startupName} accepts your
              request.
            </Typography>
          ) : (
            ""
          )}
        </Box>*/}
      </Box>
    </DialogComponent>
  );
}
