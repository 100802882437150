import { gql } from "@apollo/client";
import services from "../../../services";

export const GET_MENTOR_DATA_QUERY = gql`
	query userById($id: ID) {
		userById(id: $id) {
			id
			uuid
			name
			invitationStatus
			firstName
			lastName
			email
			picture
			partnerCode
			userSource
			timezone
			headline
			expertise
			videoUrl
			about
			linkedInUrl
			profession
			city
			state
			zipcode
			industries
			superpower
			calUserId
			productOffering
			userTypes
			country
			experienceDetails {
				id
				createdAt
				title
				companyName
				location
				startMonth
				startYear
				endMonth
				endYear
				isCurrentCompany
			}
			educationDetails {
				id
				course
				university
				location
				startYear
				endYear
				degreeType
				createdAt
			}
			companyModel {
				companyName
			}
			isSaved
			isVerified
			verificationStatus
		}
	}
`;

export const saveOrHideMatchedCollections = ({ entityIds, collectionType, entityType = "USER", isRemove = false }) => {
	return services
		.updateCollections({ entityIds, collectionType, entityType }, isRemove)
		.then((result) => {
			return result;
		})
		.catch((error) => {
			return error;
		});
};
