import React, { Fragment, useEffect } from "react";
import { Bookmark, BookmarkBorder, Check } from "@mui/icons-material";
import { Card, Box, Typography, Button, Link, IconButton, Chip } from "@mui/material";
import { DEFAULT_STARTUP_LOGO } from "../../../../constants";
import useDimensions from "react-cool-dimensions";
import CoffeeIcon from "@mui/icons-material/Coffee";
import { ROADRUNNERIMG } from "../../../../constants/image";
import services from "../../../../services";
import { useSelector } from "react-redux";
import { handleMixPanel } from "../../../../utils/mixPanelEventHandling";

const ProfileCard = ({ ...props }) => {
	const authData = useSelector((state) => state?.auth);

	// useEffect(() => {
	// 	console.log(authData?.userType === "INVESTOR_USER", "AUTH");
	// }, [authData]);

	const { observe, unobserve, width, height, entry } = useDimensions({
		onResize: ({ observe, unobserve, width, height, entry }) => {
			// Triggered whenever the size of the target is changed...

			unobserve(); // To stop observing the current target element
			observe(); // To re-start observing the current target element
		},
	});

	return (
		<Card
			ref={observe}
			sx={(theme) => ({
				minWidth: "200px",
				display: "flex",
				//minHeight: "400px",
				flexDirection: "column",
				mb: 2,
				gap: 2,
			})}
			elevation={1}
		>
			{/* Card Header */}
			<Box
				sx={{
					position: "relative",
					display: "flex",
					//alignItems: "center",
					gap: 2,
					pl: 2,
					pr: width > 300 ? 0 : 2,
					pt: 2,
				}}
			>
				{/* Bookmark Abs. Positioned Button */}
				<Box
					sx={{
						display: "flex",
						//alignItems: "center",
						justifyContent: "center",
						position: "absolute",
						height: "56px",
						width: "56px",
						right: "4px",
						top: "8px",
					}}
				>
					{props.startupObj && (
						<IconButton
							onClick={() => {
								if (props.handleBookmark) {
									props.handleBookmark();
								}
							}}
							sx={{ p: 2 }}
						>
							{props.startupObj && props.startupObj.isSaved ? <Bookmark color="secondary.dark" /> : <BookmarkBorder />}
						</IconButton>
					)}
					{props.mentorObj && (
						<IconButton
							onClick={() => {
								if (props.handleBookmark) {
									props.handleBookmark();
								}
							}}
							sx={{ p: 2 }}
						>
							{props.mentorObj && props.mentorObj.isSaved ? <Bookmark color="secondary.dark" /> : <BookmarkBorder />}
						</IconButton>
					)}
				</Box>
				{/* Title and Subtitle */}
				<Box
					sx={{
						display: "flex",
						//alignItems: "center",
						flexDirection: width > 300 ? "row" : "column",
						justifyContent: width > 300 ? "flex-start" : "center",
						gap: 2,
						width: 1,
					}}
				>
					{props.startupObj && (
						<Box
							sx={(theme) => ({
								flex: "0 0 80px",
								height: "80px",
								width: "80px",
								backgroundColor: props.startupObj && props.startupObj.logoUrl ? "none" : theme.palette.secondary.outline2,
								borderRadius: theme.shape.avatar.square,
								backgroundImage: `url(${props.startupObj && props.startupObj.logoUrl ? props.startupObj.logoUrl : DEFAULT_STARTUP_LOGO})`,
								backgroundRepeat: "no-repeat",
								backgroundPosition: "center",
								backgroundSize: "cover",
							})}
						></Box>
					)}
					{props.mentorObj && (
						<Box
							sx={(theme) => ({
								flex: "0 0 80px",
								height: "80px",
								width: "80px",
								backgroundColor: props.mentorObj && props.mentorObj.picture ? "none" : theme.palette.secondary.outline2,
								borderRadius: "50%",
								backgroundImage: `url(${props.mentorObj && props.mentorObj.picture ? props.mentorObj.picture : DEFAULT_STARTUP_LOGO})`,
								backgroundRepeat: "no-repeat",
								backgroundPosition: "center",
								backgroundSize: "cover",
							})}
						></Box>
					)}
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							gap: "4px",
							pr: width > 300 ? "56px" : 0, //Safe Area for Bookmark Button Area
							textAlign: width > 300 ? "left" : "center",
						}}
					>
						<Box
							sx={{
								height: "26px",
								maxWidth: { lg: "330px", xl: "450px" },
								justifyContent: "flex-start",
								display: "flex",
								flexWrap: "wrap",
								gap: 1,
							}}
						>
							<Typography
								variant="headline6"
								card
								sx={{
									display: "block",
									overflow: "hidden",
									textOverflow: "ellipsis",
									whiteSpace: "nowrap",
									maxWidth: "145px",
								}}
							>
								{(props.mentorObj && `${props?.mentorObj?.firstName} ${props?.mentorObj?.lastName}`) ||
									(props.startupObj && props.startupObj.companyName)}
							</Typography>
						</Box>
						{props.mentorObj && (
							<Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
								<Box
									sx={{
										display: "flex",
										flexWrap: "wrap",
										gap: 1,
										// justifyContent: width > 300 ? "flex-start" : "center",
										justifyContent: "flex-start",
									}}
								>
									{props.mentorObj.tag && (
										<Fragment>
											<Box>
												<Typography variant="label_large" textTransform={"capitalize"}>
													{props?.mentorObj && props?.mentorObj.tag}
												</Typography>
											</Box>
											<Box>
												<Typography variant="label_large">•</Typography>
											</Box>
										</Fragment>
									)}
									<Box
										sx={{
											height: "20px",
										}}
									>
										<Link
											underline="none"
											color="text.link"
											variant="label_large"
											sx={{
												display: "block",
												overflow: "hidden",
												textOverflow: "ellipsis",
												maxHeight: "100%",
											}}
										>
											{props?.mentorObj &&
												(props?.mentorObj?.industryFocus?.length > 50
													? (props?.mentorObj?.industryFocus?.slice(0, 50) + "...").replace(/(<([^>]+)>)/gi, "").replace(/,/g, ", ")
													: props?.mentorObj?.industryFocus &&
													  props?.mentorObj?.industryFocus
															.replace(/(<([^>]+)>)/gi, "")
															.replace(/&amp;/g, "&")
															.replace(/,/g, ", "))}
										</Link>
									</Box>
								</Box>

								{/*<Box sx={{ height: "25px" }}>
                  {props?.mentorObj &&
                    props?.mentorObj?.userRoles[0]?.isVerified && (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: width > 300 ? "flex-start" : "center",
                        }}
                      >
                        <Box
                          sx={theme => ({
                            padding: "6px 12px",
                            border: `1px solid ${theme.palette.secondary.customOrange}`,
                            borderRadius:
                              theme.shape.standard_small.borderRadius,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          })}
                        >
                          <Typography
                            color="secondary.customOrange"
                            variant="label_small"
                          >
                            Verified Mentor
                          </Typography>
                        </Box>
                      </Box>
                    )}
                </Box>*/}
								<Box sx={{ height: "25px" }}>
									{props?.mentorObj && props?.mentorObj?.isVerified && (
										<Box
											sx={{
												display: "flex",
												alignItem: "center",
												pb: 2,
												// justifyContent: width > 300 ? "flex-start" : "center",
												justifyContent: "flex-start",
											}}
										>
											<Chip
												icon={<Box component={"img"} width={"30px"} src={ROADRUNNERIMG}></Box>}
												sx={(theme) => ({
													boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
													borderRadius: theme.shape.standard_small.borderRadius,
													"& .MuiChip-icon": {
														color: "success.main",
													},
													pl: 1,
												})}
												label={<Typography variant="label_large">Verified Mentor</Typography>}
												variant="outlined"
											/>
										</Box>
									)}
								</Box>
							</Box>
						)}
						<Box sx={{ height: "20px" }}>
							{props.startupObj && props.startupObj.tagline && (
								<Typography variant="StartupOS/subhead/subhead1">{props.startupObj.tagline}</Typography>
							)}
						</Box>
					</Box>
				</Box>
			</Box>
			{/* Card Body */}
			<Box
				sx={{
					px: 2,
				}}
			>
				<Box
					sx={{
						my: 1,
						display: "flex",
						flexDirection: "column",
						gap: 2,
					}}
				>
					<Box
						sx={{
							height: "90px",
						}}
					>
						<Typography
							variant="StartupOS/body/medium"
							sx={(theme) => ({
								color: theme.palette.text.lightOnsurface,
								display: "block",
								overflow: "hidden",
								textOverflow: "ellipsis",
								// whiteSpace: "nowrap",
								// maxWidth: "200px",
								position: "relative",
								"z-index": "1",
								height: "90px",
								"&::before": {
									background: "linear-gradient(#0000 50px,#fff)",
									content: "''",
									height: "100%",
									left: "0",
									position: "absolute",
									top: "0",
									width: "100%",
									"z-index": "1",
								},
							})}
						>
							{props.startupObj &&
								props.startupObj.about &&
								(props.startupObj.about?.length > 200
									? (props.startupObj.about?.slice(0, 1000) + "...").replace(/(<([^>]+)>)/gi, "").replace(/&amp;/g, "&")
									: props.startupObj.about.replace(/(<([^>]+)>)/gi, ""))}
							{props.mentorObj &&
								props.mentorObj &&
								(props.mentorObj.about?.length > 200
									? (props.mentorObj.about?.slice(0, 1000) + "...").replace(/(<([^>]+)>)/gi, "")
									: props.mentorObj?.about && props.mentorObj?.about.replace(/(<([^>]+)>)/gi, "").replace(/&amp;/g, "&"))}
						</Typography>
					</Box>
				</Box>
				<Box
					sx={{
						my: 1,
						height: "20px",
					}}
				>
					{props.startupObj && props.startupObj.founderDetails && props.startupObj.founderDetails.name && (
						<Typography variant="body/medium">
							Founded by{" "}
							<Link
								underline="none"
								//href={props.startupObj.link}
								color={"text.link"}
								onClick={() => {
									if (props.handleViewFounderProfile) {
										props.handleViewFounderProfile();
									}
								}}
								sx={{ cursor: "pointer" }}
							>
								{props.startupObj.founderDetails.name}
							</Link>
						</Typography>
					)}
				</Box>
			</Box>
			{/* Card Actions */}
			<Box
				sx={{
					px: 2,
					pb: 2,
					display: "flex",
					flexDirection: width > 400 ? "row" : "column",
					justifyContent: width > 400 ? "flex-end" : "center",
					alignItems: "center",
					gap: 1,
					mt: "auto",
				}}
			>
				<Button
					onClick={() => {
						if (props.handleConnect) {
							props.handleConnect();
						}
						if (
							(props.mentorObj && props.mentorObj.connectionStatus === "PENDING" && props.mentorObj?.isCreatedByStartup) ||
							(props.startupObj && props.startupObj.connectionStatus === "PENDING" && !props.startupObj?.isCreatedByStartup)
						) {
							props.handleCancel();
						}
						if (
							(props.mentorObj && props.mentorObj.connectionStatus === "PENDING" && !props.mentorObj?.isCreatedByStartup) ||
							(props.startupObj && props.startupObj.connectionStatus === "PENDING" && props.startupObj?.isCreatedByStartup)
						) {
							props.handleAccept();
						}
					}}
					sx={{
						minWidth: "unset",
						width: width > 400 ? "auto" : "100%",
						m: 0,
						display: "flex",
						gap: 1,
					}}
					variant="outlined"
					disabled={
						(props.connectionRequestCount > 2 &&
							authData?.userType !== "INVESTOR_USER" &&
							props.mentorObj &&
							props.mentorObj.connectionStatus !== "PENDING") ||
						(props.connectionRequestCount > 2 &&
							authData?.userType !== "INVESTOR_USER" &&
							props.startupObj &&
							props.startupObj.connectionStatus !== "PENDING") ||
						(props.mentorObj && props.mentorObj.connectionStatus === "ACCEPTED") ||
						(props.startupObj && props.startupObj.connectionStatus === "ACCEPTED")
					}
				>
					{(props.mentorObj && props.mentorObj.connectionStatus === "PENDING" && props.mentorObj?.isCreatedByStartup) ||
					(props.startupObj && props.startupObj.connectionStatus === "PENDING" && !props.startupObj?.isCreatedByStartup) ? (
						<Fragment>Cancel Request</Fragment>
					) : (props.mentorObj && props.mentorObj.connectionStatus === "PENDING" && !props.mentorObj?.isCreatedByStartup) ||
					  (props.startupObj && props.startupObj.connectionStatus === "PENDING" && props.startupObj?.isCreatedByStartup) ? (
						<Fragment>Accept Request</Fragment>
					) : // (
					//  <Fragment>Cancel Request</Fragment>
					//) : (
					//  <Fragment>Accept Request</Fragment>
					//)
					(props.mentorObj && props.mentorObj.connectionStatus === "ACCEPTED") ||
					  (props.startupObj && props.startupObj.connectionStatus === "ACCEPTED") ? (
						<Fragment>
							<Check />
							<span>Connected</span>
						</Fragment>
					) : (
						"Connect"
					)}
				</Button>

				<Button
					onClick={() => {
						if (props.handleViewProfile) {
							props.handleViewProfile();
						}
						handleMixPanel("Network Profile Viewed", {
							"User ID": props?.mentorObj?.id,
						});
					}}
					sx={{ minWidth: "unset", width: width > 400 ? "auto" : "100%", m: 0 }}
				>
					View profile
				</Button>
			</Box>
		</Card>
	);
};

export default ProfileCard;
