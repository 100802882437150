import React, { createContext, useEffect, useState } from "react";
import { Dialog, Box, Typography, DialogContent, Slide, Button, DialogActions, Grid, Divider, Fade, useMediaQuery, CircularProgress } from "@mui/material";
import { CALENDAR_BLANK_PURPLE } from "../../../constants";
import CloseIcon from "@mui/icons-material/Close";
import Sections from "./Sections";
import UserInfo from "./UserInfo";
import MinuteCard from "./MinuteCard";
import RequestDetail from "./RequestDetail";
import MeetingLengthSelection from "./MeetingLengthSelection";
import { USER_TYPES } from "../../../utils/userTypes";
import AvailabilitySelection from "./AvailabilitySelection";
import { toastContainer } from "../../../utils/toast";
import moment from "moment";
import { useSelector } from "react-redux";
import { handleMixPanel } from "../../../utils/mixPanelEventHandling";
import services from "../../../services";
import { getRoleValue } from "../../../utils/commonFunctions";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Fade ref={ref} {...props} />;
});

export const ScheduleMeetingContext = createContext();

function ScheduleMeetingDialog({ open, title, handleClose, userInfo, withUserType, reschedulingEvent, handleReschedule }) {
	const [currentStep, setCurrentStep] = useState(0);
	const [userPayload, setuserPayload] = useState(userInfo);
	const [selectedMeetingLength, setMeetingLength] = useState(null);
	const [builtMeetingObj, setBuiltMeetingObj] = useState(null);
	const [confirmedBooking, setConfirmedBooking] = useState(null);
	const userData = useSelector((state) => state?.auth);

	const matchesXs = useMediaQuery((theme) => theme.breakpoints.only("xs"));
	const isSmallDisplay = useMediaQuery((theme) => theme.breakpoints.down("lg"));
	const [bookMeetingLoader, setBookMeetingLoader] = useState(false);

	useEffect(() => {
		setuserPayload(userInfo);
	}, [userInfo]);

	useEffect(() => {
		if (reschedulingEvent) {
			const attendeesData = reschedulingEvent?.attendees[0]?.user;
			setuserPayload(attendeesData);
			if (reschedulingEvent?.eventType?.meetingLength) {
				reschedulingEvent["duration"] = reschedulingEvent?.eventType?.meetingLength;
			}
			setMeetingLength(reschedulingEvent);
		}
	}, [reschedulingEvent]);

	useEffect(() => {
		if (selectedMeetingLength) {
			setCurrentStep(1);
		} else {
			setCurrentStep(0);
		}
	}, [selectedMeetingLength]);

	useEffect(() => {
		if (currentStep == 0 && !reschedulingEvent?.rescheduling) {
			// Ensure state resets when user 'backs' to the init step
			setMeetingLength(null);
			setBuiltMeetingObj(null);
			setConfirmedBooking(null);
		}
	}, [currentStep]);

	const getUserType = () => {
		switch (withUserType) {
			case USER_TYPES.MENTOR_USER:
				return "Schedule Mentor Meeting";
			case USER_TYPES.STARTUP_OWNER:
				return "Schedule Founder Meeting";
			case USER_TYPES.INVESTOR_USER:
				return "Schedule Investor Meeting";
			default:
				return "Schedule Meeting";
		}
	};

	const handleConfirm = () => {
		if (!builtMeetingObj.rescheduling) {
			let startTime = builtMeetingObj?.startTime;
			let timestamp = moment(`${startTime?.split(" ")?.[0]} ${startTime?.split(" ")?.[1]}`, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DDTHH:mm:ss");

			setBookMeetingLoader(true);
			let payload = {
				eventTypeId: builtMeetingObj?.eventTypeId,
				startTime: timestamp,
				timezone: moment.tz.guess(),
				attendeeId: userPayload?.id,
			};
			services
				.bookMeetingApi(payload)
				.then((res) => {
					toastContainer("Booking created successfully", "success");
					handleMixPanel("User scheduled a meeting", {
						"Scheduling user name": userData?.userName,
						"Scheduling user id": userData?.userId,
						"Scheduling user role": getRoleValue(userData?.userType),
						"Receiving user name": res?.data?.user?.name,
						"Receiving user id": res?.data?.userId,
						"Meeting ID": res?.data?.id,
						"Meeting Duration": builtMeetingObj?.duration,
					});
					setCurrentStep(2);
					setConfirmedBooking(res?.data);
					const role = getRoleValue(userInfo?.userTypes && userInfo?.userTypes?.[0]);
					handleMixPanel(`${role} Meeting Confirmed`, {
						[`${role} id`]: userInfo?.id,
						[`${role} Name`]: userInfo?.name,
						[`${role} email`]: userInfo?.email,
					});
					// handleClose();
					setBookMeetingLoader(false);
				})
				.catch((err) => {
					console.log(err?.response?.data?.message);
					setBookMeetingLoader(false);
					toastContainer(`Unable to book this time. ${err?.response?.data?.message}`, "error");
				});
		} else {
			let startTime = builtMeetingObj?.startTime;
			let timestamp = moment(`${startTime?.split(" ")?.[0]} ${startTime?.split(" ")?.[1]}`, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DDTHH:mm:ss");

			let payload = {
				startTime: timestamp,
				timezone: moment.tz.guess(),
				eventTypeId: reschedulingEvent?.eventTypeId,
			};

			services
				.rescheduleMeetingApi(reschedulingEvent.id, payload)
				.then((res) => {
					toastContainer("Rescheduled successfully", "success");
					handleReschedule();
					const role = getRoleValue(userInfo?.userTypes && userInfo?.userTypes?.[0]);
					handleMixPanel(`${role} Meeting Confirmed`, {
						[`${role} id`]: userInfo?.id,
						[`${role} Name`]: userInfo?.name,
						[`${role} email`]: userInfo?.email,
					});
				})
				.catch((err) => {
					console.log(err.response.data.message);
					toastContainer("Error while rescheduling", "error");
				});
		}
	};

	useEffect(() => {
		if (open) {
			const role = getRoleValue(userInfo?.userTypes && userInfo?.userTypes?.[0]);
			handleMixPanel(`Schedule ${role} Meeting`, {
				id: userInfo?.id,
				name: userInfo?.name,
				email: userInfo?.email,
			});
		}
	}, [open]);

	return (
		<ScheduleMeetingContext.Provider
			value={{ currentStep, setCurrentStep, userPayload, setMeetingLength, selectedMeetingLength, builtMeetingObj, setBuiltMeetingObj, confirmedBooking }}
		>
			<Dialog
				maxWidth="lg"
				fullWidth
				open={open}
				onClose={handleClose}
				TransitionComponent={Transition}
				transitionDuration={700}
				// keepMounted
				PaperProps={{ sx: { borderRadius: "24px" } }}
			>
				<Box sx={{ padding: "32px", pb: 3 }}>
					<Box display="flex" justifyContent="space-between" mb={1}>
						<Box component="img" src={CALENDAR_BLANK_PURPLE} alt="message-icon" />
						<CloseIcon onClick={handleClose} sx={{ ml: "auto", cursor: "pointer" }} />
					</Box>
					<Typography variant="Text/md/Semibold">{getUserType()}</Typography>
				</Box>
				<DialogContent
					sx={{
						border: "none",
						py: 0,
						px: "32px",
					}}
					dividers
				>
					<Box
						sx={(theme) => ({
							[theme.breakpoints.up("lg")]: {
								display: "grid",
								gridTemplateColumns: "repeat(3, 1fr)",
								gridTemplateRows: "auto 1fr",
								gridColumnGap: 3,
								gridRowGap: 3,
							},
							[theme.breakpoints.down("lg")]: {
								display: "grid",
								gridTemplateColumns: "1fr",
								gridTemplateRows: "repeat(6, auto);",
								gridColumnGap: 0,
								gridRowGap: 3,
							},
						})}
					>
						<Box
							sx={(theme) => ({
								[theme.breakpoints.up("lg")]: {
									gridArea: "1 / 1 / 2 / 2",
									mb: 3,
								},
								[theme.breakpoints.down("lg")]: {
									gridArea: "1 / 1 / 2 / 2",
									...(currentStep === 0 && {
										mb: 3,
									}),
								},
							})}
						>
							<Sections
								title="Meeting Type"
								subTitle="Please select the meeting type"
								isActive={currentStep > -1}
								handleClick={() => {
									if (currentStep !== 2 && !selectedMeetingLength?.rescheduling) setCurrentStep(0);
								}}
							/>
						</Box>
						<Box
							sx={(theme) => ({
								[theme.breakpoints.up("lg")]: {
									gridArea: "1 / 2 / 2 / 3",
									mb: 3,
								},
								[theme.breakpoints.down("lg")]: {
									...(currentStep === 1 && {
										gridArea: "2 / 1 / 3 / 2",
										mb: 3,
									}),
									...(currentStep === 0 && {
										gridArea: "5 / 1 / 6 / 2",
									}),
									...(currentStep === 2 && {
										gridArea: "2 / 1 / 3 / 2",
									}),
								},
							})}
						>
							<Sections
								title="Select a Date and Time"
								subTitle="Please schedule a date and time"
								isActive={currentStep > 0}
								//handleClick={() => {if(currentStep !== 2 && selectedMeetingLength)setCurrentStep(1)}}
							/>
						</Box>
						<Box
							sx={(theme) => ({
								[theme.breakpoints.up("lg")]: {
									gridArea: "1 / 3 / 2 / 4",
									mb: 3,
								},
								[theme.breakpoints.down("lg")]: {
									...(currentStep === 2 && {
										gridArea: "3 / 1 / 4 / 2",
										mb: 3,
									}),
									...(currentStep !== 2 && {
										gridArea: "6 / 1 / 7 / 2",
									}),
								},
							})}
						>
							<Sections
								title="Meeting Confirmation"
								subTitle="Your meeting is confirmed!"
								isActive={currentStep > 1}
								//handleClick={() => setCurrentStep(2)}
							/>
						</Box>

						<Box
							sx={(theme) => ({
								[theme.breakpoints.up("lg")]: {
									gridArea: "2 / 1 / 3 / 2",
								},
								[theme.breakpoints.down("lg")]: {
									...(currentStep == 0 && {
										gridArea: "2 / 1 / 3 / 2",
									}),
									...(currentStep != 0 && {
										display: "none",
									}),
								},
							})}
						>
							<UserInfo withUserType={withUserType} />
							{currentStep === 0 && isSmallDisplay && <MeetingLengthSelection withUserType={withUserType} />}
						</Box>
						{currentStep === 0 && !isSmallDisplay && (
							<Box
								sx={(theme) => ({
									[theme.breakpoints.up("lg")]: {
										gridArea: "2 / 2 / 3 / 3",
									},
								})}
							>
								{!reschedulingEvent?.rescheduling && <MeetingLengthSelection withUserType={withUserType} />}
							</Box>
						)}
						{currentStep === 1 && (
							<Box
								sx={(theme) => ({
									[theme.breakpoints.up("lg")]: {
										gridArea: "2 / 2 / 3 / 4",
									},
									[theme.breakpoints.down("lg")]: {
										gridArea: "3 / 1 / 4 / 2",
									},
									display: "flex",
									flexWrap: "wrap",
									gap: 2,
									justifyContent: "center",
								})}
							>
								<AvailabilitySelection />
							</Box>
						)}
						{currentStep === 2 && (
							<Box
								sx={(theme) => ({
									[theme.breakpoints.up("lg")]: {
										gridArea: "2 / 2 / 3 / 4",
									},
									[theme.breakpoints.down("lg")]: {
										gridArea: "4 / 1 / 5 / 2",
									},
								})}
							>
								<RequestDetail withUserType={withUserType} />
								{isSmallDisplay && <UserInfo withUserType={withUserType} />}
							</Box>
						)}
					</Box>
				</DialogContent>
				<DialogActions sx={{ pb: "32px", px: "32px" }}>
					<Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", gap: 1 }}>
						{!selectedMeetingLength?.rescheduling && currentStep !== 0 && currentStep !== 2 && (
							<Button
								variant="RoundedTextMuted"
								onClick={() => {
									setCurrentStep(currentStep - 1);
								}}
							>
								Back
							</Button>
						)}
						{currentStep == 1 && builtMeetingObj?.startTime && (
							<Button
								variant="containedPurpleStyle"
								startIcon={bookMeetingLoader && <CircularProgress size={"1.25rem"} sx={{ color: "inherit" }} />}
								onClick={() => {
									handleConfirm();
								}}
							>
								Confirm
							</Button>
						)}
						{currentStep == 0 ||
							(currentStep == 2 && (
								<Button
									variant="containedPurpleStyle"
									onClick={() => {
										handleClose();
									}}
								>
									Close
								</Button>
							))}
					</Box>
				</DialogActions>
			</Dialog>
		</ScheduleMeetingContext.Provider>
	);
}

export default ScheduleMeetingDialog;
