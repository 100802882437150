import React, { useEffect, useState } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import CommonSlideDialog from "../../../common/CommonSlideDialog";
import { EDIT_PURPLE_ICON, RED_DELETE_ICON } from "../../../constants";
import { setFeedData } from "../../../modules/actions";
import { deleteFeedPostCommentApiCall, editFeedPostCommentApiCall } from "../../../containers/Feed/Functions/common";

const EditOrDeleteCommentPopUp = () => {
	const isPopUpOpen = useSelector((state) => state?.feedPost?.editOrDeletePopUpOpen);
	const commentOperationType = useSelector((state) => state?.feedPost?.commentOperationType);
	const postId = useSelector((state) => state?.feedPost?.currentPostId);
	const commentId = useSelector((state) => state?.feedPost?.currentCommentId);
	const commentData = useSelector((state) => state?.feedPost?.detailsCommentObject);
	const userFeedData = useSelector((state) => state?.feedPost?.feedData);
	const [commentTextNew, setCommentTextNew] = useState("");
	const dispatch = useDispatch();
	const postData = useSelector((state) => state?.feedPost?.detailsPostObject);

	useEffect(() => {
		setCommentTextNew(commentData?.text);
	}, [commentData]);

	const handleClose = () => {
		if (isPopUpOpen == true) {
			dispatch(setFeedData({ editOrDeletePopUpOpen: false }));
		} else {
			dispatch(setFeedData({ editOrDeletePopUpOpen: true }));
		}
	};

	const handleSave = () => {
		const payload = {
			text: commentTextNew,
		};
		editFeedPostCommentApiCall(postId, commentId, payload)
			.then((response) => {
				dispatch(
					setFeedData({
						editOrDeletePopUpOpen: false,
						getCommentsApiCall: true,
					})
				);
			})
			.catch((error) => {
				dispatch(
					setFeedData({
						editOrDeletePopUpOpen: false,
						getCommentsApiCall: true,
					})
				);
			});
	};

	const handleDelete = () => {
		deleteFeedPostCommentApiCall(postId, commentId)
			.then((response) => {
				dispatch(
					setFeedData({
						editOrDeletePopUpOpen: false,
						getCommentsApiCall: true,
					})
				);
				const newFeedData = [...userFeedData];
				const tempPostObj = { ...postData };
				newFeedData.forEach((post, index) => {
					if (post?.id === postId) {
						post["commentsCount"] -= 1;
					}
				});
				tempPostObj["commentsCount"] -= 1;
				dispatch(
					setFeedData({
						feedData: newFeedData,
						detailsPostObject: tempPostObj,
					})
				);
			})
			.catch((error) => {
				dispatch(
					setFeedData({
						editOrDeletePopUpOpen: false,
						getCommentsApiCall: true,
					})
				);
			});
	};

	const handleChange = (event) => {
		setCommentTextNew(event.target.value);
	};

	const EditTitle = () => {
		return (
			<>
				<Box>
					<Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
						<Box height={25} width={25} component={"img"} src={EDIT_PURPLE_ICON} alt="create-post" />
						<Typography variant={"Text/md/Semibold"}>Edit Your Comment</Typography>
					</Box>
				</Box>
			</>
		);
	};

	const EditAction = () => {
		return (
			<Box
				sx={{
					display: "flex",
					gap: 2,
				}}
			>
				<Button onClick={handleClose} variant="noOutlineNew" sx={{ height: "40px" }} disabled={commentOperationType ? false : true}>
					Cancel
				</Button>
				<Button onClick={handleSave} variant="DS1" sx={{ height: "40px" }} disabled={commentOperationType ? false : true}>
					Save
				</Button>
			</Box>
		);
	};

	const DeleteAction = () => {
		return (
			<Box
				sx={{
					display: "flex",
					gap: 2,
				}}
			>
				<Button onClick={handleClose} variant="noOutlineNew" sx={{ height: "40px" }} disabled={commentOperationType ? false : true}>
					Cancel
				</Button>
				<Button onClick={handleDelete} variant="RedRounded" sx={{ height: "40px" }} disabled={commentOperationType ? false : true}>
					Delete
				</Button>
			</Box>
		);
	};

	const DeleteTitle = () => {
		return (
			<>
				<Box>
					<Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
						<Box height={25} width={25} component={"img"} src={RED_DELETE_ICON} alt="create-post" />
						<Typography variant={"Text/md/Semibold"}>Delete Comment</Typography>
					</Box>
				</Box>
			</>
		);
	};
	return (
		<CommonSlideDialog
			disableBackDropClick={true}
			title={<>{commentOperationType && commentOperationType === "edit" ? <EditTitle /> : <DeleteTitle />}</>}
			titleVariant={"Text/xl/Semibold"}
			isOpen={isPopUpOpen}
			onClose={handleClose}
			contentStyle={{ p: 0 }}
			footerStyle={{ px: 0 }}
			titleStyle={{ alignItems: "baseline", px: 0, py: 0 }}
			colseIconStyle={{ height: "30px", width: "30px" }}
			maxWidth={"sm"}
			fullWidth
			PaperProps={{
				sx: (theme) => ({
					borderRadius: theme.shape.standard10.borderRadius,
					boxShadow: "0px 5px 16px rgba(0, 0, 0, 0.3)",
					p: 3,
					height: "fit-content",
				}),
			}}
			dialogAction={<>{commentOperationType && commentOperationType === "edit" ? <EditAction /> : <DeleteAction />}</>}
		>
			<Box
				sx={{
					py: 3,
					display: "flex",
					flexDirection: "column",
					gap: 3,
				}}
			>
				{commentOperationType && commentOperationType === "edit" ? (
					<></>
				) : (
					<Typography variant="flyout_text">Are you sure you want to delete this comment? This action cannot be undone.</Typography>
				)}

				<Typography variant="flyout_text">Comment</Typography>
				<TextField
					value={commentTextNew}
					multiline="true"
					spellCheck="true"
					maxRows={5}
					fullWidth
					InputProps={{
						style: {
							borderRadius: "8px",
							borderColor: "#D0D5DD",
							height: "150px",
							alignItems: "baseline",
							background: commentOperationType && commentOperationType === "edit" ? "" : "#F9FAFB",
							fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
							fontSize: "16px",
							lineHeight: "24px",
						},
					}}
					onChange={handleChange}
					disabled={commentOperationType && commentOperationType === "edit" ? false : true}
				/>
			</Box>
		</CommonSlideDialog>
	);
};

export default EditOrDeleteCommentPopUp;
