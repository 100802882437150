import styled from "@emotion/styled";
import { TextField } from "@mui/material";

export const StyledTextFieldNew = styled(TextField)(({ theme, height, width }) => ({
	height: height,
	width: width,
	border: "none",
	"& .MuiInputBase-input": {
		cursor: "text",
		height: height,
		width: width,
		borderRadius: "30px",
		border: "none",
	},
	"& .MuiOutlinedInput-root": {
		fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
		fontSize: "16px",
		lineHeight: "24px",
		height: height,
		width: width,
		borderRadius: "30px",
		background: "white",
		boxShadow: "0px 0px 0px 1px #D0D5DD, 0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
		border: "none",
	},
	"& .MuiOutlinedInput-notchedOutline": {
		border: "none",
	},
}));
