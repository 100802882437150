import React from "react";
import {
  Box,
  Breadcrumbs,
  Link,
  Typography,
  IconButton,
  Button,
} from "@mui/material";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";
import { sosPrimary } from "../../../../../constants/Theme/colors";
import { useHistory } from "react-router-dom";
import theme from "../../../../../constants/Theme";
import { MARKET_TEST_SCRIPT_DATA } from "../../../../../constants/powerup-data";

const BuildScriptHeader = ({ marketTest, currentPowerup }) => {
  const history = useHistory();

  const getScriptDescriptionData = () => {
    if (marketTest.powerUpModel.powerUpName === "Problem Solution Fit") {
      return MARKET_TEST_SCRIPT_DATA[0];
    } else if (marketTest.powerUpModel.powerUpName === "Value Proposition") {
      return MARKET_TEST_SCRIPT_DATA[1];
    } else {
      return MARKET_TEST_SCRIPT_DATA[2];
    }
  };

  return (
    <Box sx={{ pb: 3, px: 1 }}>
      <Box display={"flex"} alignItems={"center"} sx={{ mt: 3 }}>
        <SpeakerNotesIcon
          sx={{
            color: sosPrimary[500],
            border: `1px solid ${sosPrimary[500]}`,
            borderRadius: "50%",
            p: 1,
          }}
        />
        <Typography color={sosPrimary[500]} variant="headline5" sx={{ ml: 2 }}>
          View Script
        </Typography>
      </Box>
      <Box
        sx={{
          mt: 3,
          ml: 7,
          [theme.breakpoints.down("md")]: {
            ml: 0,
          },
        }}
        display="grid"
      >
        {/*<Typography variant="subhead1">
          We prepared starter-script. Edit to fine-tune the questions, reorder
          and apply your own unique tone.
        </Typography>
        <Typography variant="subhead1" sx={{ mt: 3 }}>
          Review your {marketTest.powerUpModel.powerUpName} to help identify how
          you would like to shape your script.
        </Typography>*/}

        <Typography variant="subhead1" sx={{ whiteSpace: "break-spaces" }}>
          {getScriptDescriptionData()}
        </Typography>
      </Box>
      {currentPowerup?.moduleResponseModels ? (
        <Button
          variant="outlined"
          sx={{
            maxWidth: "fit-content",
            mt: 3,
            ml: 7,
            [theme.breakpoints.down("md")]: {
              ml: 0,
            },
          }}
          onClick={() => {
            window.open(
              `/workspace/powerup/${currentPowerup?.moduleResponseModels[0]?.module?.moduleUuid}/${currentPowerup?.moduleResponseModels[0]?.moduleId}/${currentPowerup?.moduleResponseModels[0]?.id}/start`,
              "_blank"
            );
          }}
        >
          View {marketTest.powerUpModel.powerUpName}
        </Button>
      ) : (
        ""
      )}
    </Box>
  );
};

export default BuildScriptHeader;
