import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography, IconButton, Avatar } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import { UPDATED_LOGO_ICON_NEW, UPDATED_PLATFORM_JOURNEY_ICON } from "../../../../constants";
import { setFounderDashboardData } from "../../../../modules/actions";
import Journey from "../../../Founder/Journey";
import PlatformJourney from "../../../Founder/Journey/PlatformJourney";
import PaymentPlanDialog from "../../../../components/PaymentPlanDialog";

export default function PartnerWidget({ percentComplete = 0 }) {
	const dispatch = useDispatch();
	const showPartnerJourney = true;
	const [isToggled, setIsToggled] = useState(false);
	const isAdmin = useSelector((store) => store?.auth?.isAdmin);
	const isPremiumPlanAccess = useSelector((store) => store?.auth?.isPremiumPlanAccess);
	const [showPlanModal, setShowPlanModal] = useState(false);
	const [showJourneyModal, setShowJourneyModal] = useState(false);

	const handleBoostScore = () => {
		if (showPartnerJourney) {
			setShowJourneyModal(true);
		} else {
			if (isPremiumPlanAccess || isAdmin) {
				dispatch(
					setFounderDashboardData({
						applyToPitchOpen: true,
					})
				);
			} else {
				setShowPlanModal(true);
			}
		}
	};

	const handleClosePlanModal = () => {
		setShowPlanModal(false);
	};

	const handleClosePlatformJourneyModal = () => {
		setShowJourneyModal(false);
	};

	const toggleWidget = () => {
		handleBoostScore(); //directly open
		// setIsToggled(!isToggled);
	};

	return (
		<Box
			alignItems="center"
			sx={(theme) => ({
				display: { xs: "none", md: "flex" },
				position: "absolute",
				right: "0",
				top: "225px",
				width: isToggled ? "380px" : "65px",
				transitionTimingFunction: "ease-out",
				transition: "0.25s",
				transform: "translateX(0)",
				background: "#FFFFFF",
				borderRadius: "100px 0px 0px 100px",
				padding: "12px 12px",
				marginLeft: "auto",
				marginRight: 0,
				zIndex: 1000,
				boxShadow: "0 1px 2px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.12)",
				"&:hover": {
					boxShadow: "0 3px 6px rgba(0,0,0,0.12), 0 3px 6px rgba(0,0,0,0.12)",
					cursor: "pointer",
				},
				[theme.breakpoints.up("xs")]: {
					display: "flex",
				},
				[theme.breakpoints.up("md")]: {
					display: "flex",
				},
			})}
		>
			<Avatar
				onClick={toggleWidget}
				sx={(theme) => ({
					cursor: "pointer",
					bgcolor: showPartnerJourney ? "#DE0085" : "#000000",
					borderRadius: showPartnerJourney ? "50%" : "50%",
					width: "65px",
					height: "65px",
					backgroundImage: showPartnerJourney ? "url(" + UPDATED_PLATFORM_JOURNEY_ICON + ")" : "url(" + UPDATED_LOGO_ICON_NEW + ")",
					backgroundRepeat: "no-repeat",
					backgroundPosition: "center",
				})}
			>
				{percentComplete !== 0 && (
					<Typography variant="logo_text" sx={{ fontWeight: 600, color: "#fff" }}>
						{`${percentComplete}%`}
					</Typography>
				)}
			</Avatar>
			{isToggled && (
				<Box display="flex" alignItems="flex-start" gap={1.25} ml="20px">
					<Box sx={{ maxWidth: "200px" }} onClick={handleBoostScore}>
						<Typography variant={showPartnerJourney ? "title_portfolio" : "poppinsSemiBold16"} sx={{ textOverflow: "clip", display: "block" }}>
							{showPartnerJourney
								? "Platform Guide"
								: isPremiumPlanAccess || isAdmin
								? "Investors are watching!"
								: "Ready to be seen by investors?"}
						</Typography>
						{showPartnerJourney && (
							<Typography variant="subhead1">
								{showPartnerJourney ? `Learn the ins and outs of how to navigate SOS!` : `Learn the ins and outs of how to navigate SOS!`}
							</Typography>
						)}
					</Box>

					<IconButton sx={{ p: 0, border: "3px solid #000", borderRadius: "50%" }} onClick={handleBoostScore}>
						<ArrowForwardIcon sx={{ fontSize: "22px", color: "#000" }} />
					</IconButton>
				</Box>
			)}
			{showPlanModal && <PaymentPlanDialog open={showPlanModal} onClose={handleClosePlanModal} />}
			<PlatformJourney open={showJourneyModal} onClose={handleClosePlatformJourneyModal} />
			<Journey />
		</Box>
	);
}
