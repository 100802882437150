import React, { useContext } from "react";
import { Box, Grid, TextField, Typography } from "@mui/material";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import UpdatedSelectField from "../../../common/UpdatedSelectField";
import constants, { GEOGRAPHICAL_PREFERENCES, INDUSTRY_TYPES, INVESTMENT_STAGE_PREFERENCES, INVESTOR_TYPES } from "../../../constants";
import NewSingleSelect from "../../../common/NewSingleSelect";
import UpdatedTextInputField from "../../../common/UpdatedTextInputField";
import { getJasonFormatedValue } from "../../../utils/commonFunctions";
import { NumericFormat } from "react-number-format";

const TagsAboutMeSection = ({ context }) => {
	const { payLoad, setPayLoad } = useContext(context);

	const fieldList = [
		{ lable: "Preferred Industries", key: "industries", multiSelect: true, options: INDUSTRY_TYPES },
		{ lable: "Preferred Investment Categories", key: "productOffering", multiSelect: true, singleSelect: false, options: constants.PRODUCT_OFFERING },
		{ lable: "Investor Type", key: "investorType", multiSelect: false, singleSelect: true, options: INVESTOR_TYPES },
		{ lable: "Investor Stage Preference", key: "preferredInvestmentStage", multiSelect: true, options: INVESTMENT_STAGE_PREFERENCES },
		{ lable: "Geographical Preference", key: "preferredGeography", multiSelect: true, options: GEOGRAPHICAL_PREFERENCES },
		{ lable: "Minimum Investment Range", key: "investmentRangeLow", numberField: true },
		{ lable: "Maximum Investment Range", key: "investmentRangeHigh", numberField: true },
	];
	function handleChange(event, key) {
		let newValue = event.target.value.length > 0 ? JSON.stringify(event.target.value) : null;
		payLoad[key] = newValue;
		setPayLoad({ ...payLoad });
	}

	function handleDelete(value, key) {
		let tempArray = getJasonFormatedValue(payLoad[key]);
		let filteredArray = tempArray.filter((item) => item !== value);
		if (filteredArray.length > 0) {
			payLoad[key] = JSON.stringify(tempArray.filter((item) => item !== value));
		} else {
			payLoad[key] = null;
		}

		setPayLoad({ ...payLoad });
	}
	const handleSingleSelect = (event, key) => {
		let newValue = event.target.value;

		payLoad[key] = newValue;

		setPayLoad({ ...payLoad });
	};
	const handleValueChange = (value, key) => {
		payLoad[key] = value;
		setPayLoad({ ...payLoad });
	};

	function handleSelectChange(event, key) {
		let newValue = event.target.value.length > 0 ? JSON.stringify(event.target.value) : null;
		payLoad[key] = newValue;
		setPayLoad({ ...payLoad });
	}
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				rowGap: "60px",
			}}
		>
			<Box>
				<Typography variant="poppinsSemiBold20">Tags About Me</Typography>
			</Box>
			<Box>
				<Grid container rowSpacing={4} columnSpacing={7}>
					{fieldList?.map((item) => {
						if (item.multiSelect) {
							return (
								<Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
									<UpdatedSelectField
										id={item.key}
										lable={item.lable}
										placeholder={item.lable}
										options={item.options}
										handleChange={handleSelectChange}
										handleDelete={handleDelete}
										inputValue={getJasonFormatedValue(payLoad[item.key]) ? getJasonFormatedValue(payLoad[item.key]) : []}
										selectProps={{
											multiple: true,
											fullWidth: true,
											IconComponent: KeyboardArrowDownRoundedIcon,
											height: "60px",
											width: "100%",
										}}
									/>
								</Grid>
							);
						} else {
							if (item.singleSelect) {
								return (
									<Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
										<NewSingleSelect
											id={item.key}
											label={item.lable}
											fullWidth
											onInputChange={(e) => {
												handleSingleSelect(e, item.key);
											}}
											selectedValue={payLoad[item.key]}
											inputKey={item.key}
											options={item.options}
											borderNone={true}
										/>
									</Grid>
								);
							} else if (item.numberField) {
								return (
									<Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
										<Typography variant="poppinsSemiBold18">{item.lable}</Typography>

										<NumericFormat
											fullWidth
											placeholder={item.lable}
											value={payLoad[item.key] ? Number(payLoad[item.key]) : null}
											customInput={TextField}
											type="text"
											thousandSeparator={true}
											prefix={"$"}
											sx={{
												mt: 1.25,
												"& .MuiOutlinedInput-notchedOutline": {
													border: "none",
												},
												"& .MuiInputBase-root": {
													fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
													fontSize: "16px",
													lineHeight: "24px",
													height: "60px",
													width: "100%",
													borderRadius: "30px",
													background: "white",
													boxShadow: "0px 0px 0px 4px #F2F4F7, 0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
													border: "none",
												},
											}}
											onValueChange={(e) => handleValueChange((e.floatValue || 0).toString(), item.key)}
										/>
									</Grid>
								);
							} else {
								return (
									<Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
										<UpdatedTextInputField
											label={item.lable}
											id={item.key}
											handleChange={handleChange}
											inputValue={payLoad[item.key]}
											placeholder={item.lable}
											textFieldProps={{
												fullwidth: true,
												width: "100%",
												height: "60px",
											}}
										/>
									</Grid>
								);
							}
						}
					})}
				</Grid>
			</Box>
		</Box>
	);
};

export default TagsAboutMeSection;
