import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, generatePath } from "react-router-dom";
import { Box, Skeleton, Stack, Typography } from "@mui/material";
import EventCard from "./EventCard";
import { ROCKET_LAUNCH_ICON } from "../../../constants";
import PaymentPlanDialog from "../../PaymentPlanDialog";
import ROUTES_PATH from "../../../constants/routes";
import { setEventData } from "../../../modules/actions";

function EventCards({ events, loading }) {
	const [showPlanModal, setShowPlanModal] = useState(false);
	const [eventItems, seteventItems] = useState([]);
	const [isLoading, setisLoading] = useState(false);
	const history = useHistory();
	const skeletonLoaderArray = [1, 2, 3, 4];

	const dispatch = useDispatch();

	useEffect(() => {
		seteventItems(events);
	}, [events]);

	useEffect(() => {
		setisLoading(loading);
	}, [loading]);

	const handleClosePlanModal = () => {
		setShowPlanModal(false);
	};

	const onEventClick = (event) => {
		dispatch(
			setEventData({
				currentObject: event,
			})
		);
		history.push({
			pathname: generatePath(ROUTES_PATH.EVENTS_DETAILS, {
				id: event?.uuid,
			}),
		});
	};
	const isPastEvent = (eventData) => {
		const currentDate = new Date().getTime();
		let check = new Date(eventData?.deadlineDate);
		if (check < currentDate) {
			return true;
		} else {
			return false;
		}
	};
	const SkeletonCardLoader = () => {
		return (
			<Box
				width="400px"
				sx={{
					display: "flex",
					flexDirection: "column",
					rowGap: "28px",
					p: 4,
					borderRadius: "24px",
					backgroundColor: (theme) => theme.palette.secondary.white,
					boxShadow: "0px 10px 25px 0px rgba(0, 0, 0, 0.05)",
					"&:hover": {
						cursor: "pointer",
						".event-register-btn": { backgroundColor: (theme) => theme.palette.secondary.dark, color: (theme) => theme.palette.secondary.white },
					},
				}}
			>
				{" "}
				<Stack spacing={2}>
					<Skeleton variant="rectangular" height={50} width={"100%"} sx={{ borderRadius: "24px" }} />
					<Skeleton animation="wave" variant="rectangular" sx={{ borderRadius: "24px" }} height={200} />
					<Skeleton variant="rectangular" height={50} width={"100%"} sx={{ borderRadius: "24px" }} />
				</Stack>
			</Box>
		);
	};

	return (
		<Box display="flex" flexDirection={"column"} rowGap={6} alignItems={"center"} sx={{ mb: 5 }}>
			<Stack alignItems={"center"}>
				<Box display={"flex"} justifyContent={"center"} alignItems={"center"} columnGap={1}>
					<Box component="img" src={ROCKET_LAUNCH_ICON} sx={{ width: "32px", height: "32px" }} />
					<Typography variant="Text/md/Semibold">Check our upcoming events!</Typography>
				</Box>
				<Typography variant="Text/sm/Regular" textAlign="center">
					Register now for our Investor Pitch Demo Days, Webinars, Coffee & Chill, BoostCamps and more!
				</Typography>
			</Stack>
			<Box>
				<Box display="flex" flexWrap={"wrap"} justifyContent={"center"} alignItems={"stretch"} gap={3}>
					{isLoading &&
						skeletonLoaderArray?.map((item) => {
							return <SkeletonCardLoader />;
						})}
					{!isLoading &&
						eventItems?.length > 0 &&
						eventItems?.map((event) => (
							<EventCard
								key={event?.title}
								eventName={event.title}
								eventDescription={event.description}
								imageSrc={event.imageUrl}
								isPastEvent={isPastEvent(event)}
								isPremium={event.isPremiumEvent}
								displayDate={event?.displayDate}
								onEventClick={() => {
									onEventClick(event);
								}}
							/>
						))}
				</Box>
			</Box>
			{showPlanModal && <PaymentPlanDialog open={showPlanModal} isRedirect={false} onClose={handleClosePlanModal} />}
		</Box>
	);
}

export default EventCards;
