import React, { useState, useEffect } from "react";
import { Box, Button, Chip, Divider, Grid, Typography, MenuItem, Stack } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DialogComponent from "../../../containers/Workspace/PowerUP/IdeaValidation/Components/Dialog";
import LoadingButton from "../../../common/Loadingbutton";
import AutocompleteInput from "../../../common/AutocompleteInput";
import messages from "../../../constants/messages";
import { customerTagValid } from "../../../containers/Workspace/PowerUP/ProblemSolution/functions/common";
import { REGEX } from "../../../constants/regex";
import CounterBox from "../../../common/CounterBox";
import MessageHandlerModal from "../../../common/modal/MessageHandler";
import { EMAIL_PLACEHOLDER } from "../../../constants/image";
import SimpleDropDown from "../../../common/SimpleDropDown";
import MuiSnackBar from "../../../common/MuiSnackBar";
import services from "../../../services";
import theme from "../../../constants/Theme";
import SwipeableEdgeDrawer from "../../../common/Drawer/index";
import { useSelector } from "react-redux";
import { USER_TYPES } from "../../../utils/userTypes";
import ScriptEditor from "../../../common/ScriptEditor";
import { RICH_TEXT_EDITOR_INLINE_CONTROLS, RICH_TEXT_EDITOR_MAIN_CONTROLS } from "../../../constants/plugin-constants";
import { handleMixPanel } from "../../../utils/mixPanelEventHandling";
import CommonSlideDialog from "../../../common/CommonSlideDialog";

const InviteTeam = ({ showInviteTeamPopup, setShowInviteTeamPopup }) => {
	const [userInputs, setUserInputs] = useState({ users: [] });
	const [helpText, setHelpText] = useState("");
	const [isError, setIsError] = useState(false);
	const [message, setMessage] = useState("");
	const [confirmPopup, setConfirmPopup] = useState(false);
	const [openSnackbar, setOpenSnackbar] = useState(false);
	const [snackBarMsg, setSnackBarMsg] = useState("");
	const [invitedList, setInvitedList] = useState([]);
	const [loader, setLoader] = useState(false);
	const [openDrawer, setOpenDrawer] = useState(false);
	const [deleteConfirmPopup, setDeleteConfirmPopup] = useState(false);
	const [currentInvite, setCurrentInvite] = useState({});

	const contRef = React.useRef();
	const customerItems = React.createRef();
	const [changed, setChanged] = useState(false);
	const userType = useSelector((store) => store?.auth?.userType);
	const [version, setVersion] = useState(1);
	const userData = useSelector((store) => ({
		userId: store?.auth?.userId,
		userName: store?.auth?.userName,
	}));

	useEffect(() => {
		getInvitedTeamMembersApiCall();
	}, []);

	const handleOnClose = () => {
		if (changed) {
			setConfirmPopup(true);
		} else {
			setShowInviteTeamPopup(false);
		}
	};

	const getInvitedTeamMembersApiCall = () => {
		if (userType === USER_TYPES.STARTUP_OWNER || userType === USER_TYPES.STARTUP_USER || userType === USER_TYPES.STUDENT_USER) {
			services
				.getTeamMemberInviteApiCall()
				.then((response) => {
					if (response.data.code === 200) {
						setInvitedList(response.data?.data);
					}
				})
				.catch((error) => {
					console.log(error);
				});
		}
	};
	const onChangeHandler = (e, key) => {
		setChanged(true);
		const value = e.target.value;
		const currentInput = { ...userInputs };
		currentInput[key] = value;
		setUserInputs(currentInput);
	};

	const handleOnChange = (value) => {
		if (!REGEX.EMAIL.test(value) && value !== "") {
			setHelpText(messages.INVALID_EMAIL);
			setIsError(true);
		} else {
			setHelpText("");
			setIsError(false);
		}
	};
	const handleMessageChange = (event) => {
		setMessage(event.target.value);
	};
	const resetValues = () => {
		setUserInputs({ users: [] });
		setIsError(false);
		setHelpText("");
		setMessage("");
	};

	const invitationErrorHandling = (inviteList) => {
		let alreadyExistList = inviteList.filter((item) => item.isExists === true);

		if (alreadyExistList.length === 0) {
			let alreadyInvitedList = inviteList.filter((item) => item.isInvited === true);
			setIsError(true);

			if (alreadyInvitedList.length === 1) {
				setHelpText(`Candidate with email ${alreadyInvitedList[0].email} has already been invited`);
			} else if (alreadyInvitedList.length > 1) {
				setHelpText(`Issue with ${alreadyInvitedList.map((item) => `${item.email}`)}  `);
			}
		} else {
			setIsError(true);
			setHelpText(`${alreadyExistList.map((item) => `${item.email} ,`)}${alreadyExistList.length > 1 ? "," : ""} already exists. Cannot be invited.`);

			if (alreadyExistList.length === 1) {
				setHelpText(`${alreadyExistList[0].email} already exists. Cannot be invited.`);
			} else if (alreadyExistList.length > 1) {
				setHelpText(`Issue with ${alreadyExistList.map((item) => `${item.email}`)}`);
			}
		}
	};

	const handleSendInvitation = () => {
		handleMixPanel("Startup Profile Edited", {
			userName: userData.userName,
			userId: userData.userId,
		});

		setChanged(false);
		let invitationList = [];
		userInputs.users.forEach((item) => {
			let inviteObj = { inviteRole: "TEAM_MEMBER", email: item };
			invitationList.push(inviteObj);
		});

		let payload = {
			users: invitationList,
			message: message,
		};
		setLoader(true);
		services
			.sendTeamMemberInviteApiCall(payload)
			.then((response) => {
				setLoader(false);

				if (response.data.code === 200) {
					getInvitedTeamMembersApiCall();
					setSnackBarMsg("Invitation sent.");
					setOpenSnackbar(true);
					setShowInviteTeamPopup(false);
					resetValues();
				}
			})
			.catch((error) => {
				setLoader(false);
				if (error?.response?.status === 409) {
					invitationErrorHandling(error?.response?.data?.data);
				}
			});
	};

	const resendInviteApiCall = (invitationId) => {
		let payload = {
			id: invitationId,
			inviteRole: "TEAM_MEMBER",
		};
		services
			.resendInvitationApiCall(payload)
			.then((response) => {
				if (response.data.code === 200) {
					setSnackBarMsg("Invitation re-sent.");
					setOpenSnackbar(true);
					setOpenDrawer(false);
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const deleteInviteApiCall = (invitationId) => {
		services
			.deleteInvitationApiCall(invitationId)
			.then((response) => {
				if (response.data.code === 200) {
					setSnackBarMsg("Invitation deleted");
					setOpenSnackbar(true);
					setOpenDrawer(false);
					setDeleteConfirmPopup(false);
					getInvitedTeamMembersApiCall();
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};
	const handleActions = (type, item) => {
		setCurrentInvite(item);
		if (type === "Resend") {
			resendInviteApiCall(item.id);
		} else {
			setOpenDrawer(false);
			setDeleteConfirmPopup(true);
		}
	};
	const AlreadyInvitedSection = () => {
		return (
			invitedList &&
			invitedList?.length > 0 && (
				<>
					<Divider sx={{ mt: 3 }} />
					<Box sx={{ mt: 1 }}>
						<Typography variant="Text/sm/Semibold">Invited members</Typography>
						{invitedList?.map((item) => {
							return (
								<Box sx={{ mt: 4, display: "flex", alignItems: "center" }}>
									<Chip
										avatar={<Box component={"img"} src={EMAIL_PLACEHOLDER} alt="email"></Box>}
										label={<Typography variant="Text/sm/Semibold">{item?.platformInvitation?.email}</Typography>}
										variant="outlined"
										sx={(theme) => ({
											height: "40px",
											borderRadius: theme.shape.standard13.borderRadius,
										})}
									/>

									<Box
										sx={{
											ml: "auto",
											display: {
												sx: "none",
												xs: "none",
												md: "block",
												lg: "block",
												xl: "block",
											},
										}}
									>
										<SimpleDropDown
											handleAction={(type) => {
												handleActions(type, item);
											}}
										/>
									</Box>
									<Box
										sx={{
											ml: "auto",
											display: {
												sx: "block",
												xs: "block",
												md: "none",
												lg: "none",
												xl: "none",
											},
										}}
									>
										<Box>
											<MoreHorizIcon sx={{ cursor: "pointer" }} onClick={() => setOpenDrawer(true)} />
										</Box>

										<SwipeableEdgeDrawer
											open={openDrawer}
											onClose={() => {
												setOpenDrawer(false);
											}}
											container={contRef.current}
										>
											<Box sx={{ py: 4, px: 2 }}>
												<MenuItem onClick={() => handleActions("Resend", item)} sx={{ pr: 13 }}>
													<Typography variant="bodyLarge">Resend</Typography>
												</MenuItem>
												<MenuItem onClick={() => handleActions("Delete", item)} sx={{ pr: 13, mt: 1 }}>
													<Typography variant="bodyLarge">Delete</Typography>
												</MenuItem>
											</Box>
										</SwipeableEdgeDrawer>
									</Box>
								</Box>
							);
						})}
					</Box>
				</>
			)
		);
	};

	const handleDescriptionChange = (value) => {
		setMessage(value.value);
	};

	return (
		<Box>
			<CommonSlideDialog
				isOpen={showInviteTeamPopup}
				onClose={() => {
					handleOnClose();
				}}
				title={"Invite Your Team"}
				titleVariant={"Text/md/Semibold"}
				contentStyle={{ px: 0 }}
				maxWidth={"sm"}
				fullWidth
				PaperProps={{
					sx: (theme) => ({ borderRadius: "24px", py: 2 }),
				}}
				dialogAction={
					<Box
						sx={{
							mr: 2,
							display: "flex",
							[theme.breakpoints.down("md")]: {
								mr: 0,
								display: "contents",
							},
						}}
					>
						{userInputs.users.length === 0 ? (
							<Button
								variant="DS1"
								onClick={() => {
									setShowInviteTeamPopup(false);
									resetValues();
								}}
							>
								Done
							</Button>
						) : (
							<Box item sx={{ display: "flex", gap: 1 }}>
								<Button variant="noOutlineNew" onClick={() => setConfirmPopup(true)}>
									Cancel
								</Button>
								<LoadingButton variant="DS1" onClick={() => handleSendInvitation()} loading={loader} loadingStyles={{ px: 3 }}>
									Send
								</LoadingButton>
							</Box>
						)}
					</Box>
				}
				disableBackDropClick
			>
				{/* <Grid container alignItems={"top"} sx={{ px: 3 }} spacing={2} ref={contRef}>
					<Grid item sm={12} xs={12} md={9} lg={9} xl={9} sx={{ flex: 1 }}>
						<AutocompleteInput
							label={"Add email addresses"}
							handleInputChange={onChangeHandler}
							inputKey={"users"}
							autoCompleteData={[]}
							inputValid={customerTagValid}
							inputValue={userInputs ? [...userInputs?.users] : []}
							errorMessage={messages.MULTITAG_INPUT_INVALID}
							sx={{ mt: 2 }}
							typingEnabled
							multiple
							handleOnBlurEvent={true}
							inputRef={customerItems}
							isEmailField={true}
							helpText={helpText}
							handleOnChange={handleOnChange}
							isError={isError}
						/>
					</Grid>
					<Grid item sm={12} xs={12} md={3} lg={3} xl={3}>
						<Button
							sx={{
								[theme.breakpoints.up("sm")]: {
									mt: 3,
									// ml: "31px",
								},
								// maxWidth: "fit-content",
							}}
							variant="PurpleRoundedOutline"
							onClick={() => customerItems.current.blur()}
							disabled={isError}
						>
							+ Add
						</Button>
					</Grid>
				</Grid> */}

				<Stack gap={2} sx={{ px: 4 }}>
					<Box>
						<Typography variant="Text/sm/Regular">
							Add a team member by entering in their email address! Use the enter key to separate tags.
						</Typography>
					</Box>
					<AutocompleteInput
						placeholder={"Email Address"}
						handleInputChange={onChangeHandler}
						inputKey={"users"}
						autoCompleteData={[]}
						inputValid={customerTagValid}
						inputValue={userInputs ? [...userInputs?.users] : []}
						errorMessage={messages.MULTITAG_INPUT_INVALID}
						typingEnabled
						multiple
						sx={{
							width: 1,
							input: {
								"&, &::placeholder": (theme) => ({
									fontFamily: theme.typography["Text/sm/Regular"],
									color: "rgba(102, 112, 133, 1)",
								}),
							},
							"& .MuiOutlinedInput-notchedOutline": {
								height: "auto",
								boxShadow: "0px 10px 25px 0px rgba(0, 0, 0, 0.05)",
								border: "none",
								borderRadius: "100px",
							},
						}}
						handleOnBlurEvent={false}
						inputRef={customerItems}
						isEmailField={true}
						helpText={helpText}
						handleOnChange={handleOnChange}
						isError={isError}
					/>
					{userInputs.users.length !== 0 && (
						<Box
							sx={(theme) => ({
								minHeight: "150px",
								backgroundColor: "white",
								borderRadius: "30px",
								p: 2,

								boxShadow: "0px 10px 25px 0px rgba(0, 0, 0, 0.05)",
							})}
						>
							<ScriptEditor
								minHeight={"150px"}
								version={version}
								label={"Message"}
								initialValue={message}
								onChange={(value) => handleDescriptionChange(value)}
								inlineToolbar={false}
								inlineToolbarControls={RICH_TEXT_EDITOR_INLINE_CONTROLS}
								controls={RICH_TEXT_EDITOR_MAIN_CONTROLS}
							/>
						</Box>
					)}
					{/* <Button
						sx={{
							maxWidth: "fit-content",
							height: "50px",
						}}
						variant="PurpleRoundedOutline"
						onClick={() => customerItems.current.blur()}
						disabled={isError}
					>
						+ Add
					</Button> */}
					<Box>
						<AlreadyInvitedSection />
					</Box>
				</Stack>
			</CommonSlideDialog>
			<MessageHandlerModal
				isOpen={confirmPopup}
				onCancel={() => setConfirmPopup(false)}
				onOk={() => {
					setShowInviteTeamPopup(false);
					setConfirmPopup(false);
					resetValues();
					setChanged(false);
				}}
				okText="Discard"
				cancelText={"No thanks"}
				heading="Discard changes"
				text={"Are you sure you want to discard your changes?"}
				messageType="primary"
			/>
			<MessageHandlerModal
				isOpen={deleteConfirmPopup}
				onCancel={() => setDeleteConfirmPopup(false)}
				onOk={() => {
					deleteInviteApiCall(currentInvite.id);
				}}
				okText="Delete"
				cancelText={"No thanks"}
				heading="Delete Invitation"
				text={"Are you sure you want to delete the invitation?"}
				messageType="primary"
			/>
			<MuiSnackBar message={snackBarMsg} vertical={"bottom"} horizontal={"left"} open={openSnackbar} setOpenSnackbar={setOpenSnackbar} />
		</Box>
	);
};

export default InviteTeam;
