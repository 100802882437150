import React, { useState } from "react";
import { Grid, Typography, Box, Button, Card } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useHistory } from "react-router-dom";
import theme from "../../../../../constants/Theme";
import { ArrowRightAlt } from "@mui/icons-material";
//import StartupCard from "./StartupCard";
import CardController from "../../../../../containers/Network/Components/CardController";
//import { saveUnsaveStartup } from "../../../Network/StartupProfile/functions/functions";
import { toastContainer } from "../../../../../utils/toast";
import messages from "../../../../../constants/messages";
import ROUTES_PATH from "../../../../../constants/routes";
import { DEFAULT_STARTUP_LOGO } from "../../../../../constants";
import { ConnectedStartupCard } from "../ConnectedStartups/styles";

const ConnectedCardController = ({ data }) => {
	const history = useHistory();
	const [index, setIndex] = useState(0);

	const handleViewProfile = (id) => {
		history.push(ROUTES_PATH.STARTUP_PROFILE.replace(":id", id ? id : ""));
	};

	return (
		<>
			<ConnectedStartupCard>
				<Grid container sx={{ p: 2 }}>
					<Grid item xs={3}>
						<Box
							component="img"
							src={data?.company?.logoUrl ? data?.company?.logoUrl : DEFAULT_STARTUP_LOGO}
							sx={(theme) => ({
								width: "80px",
								height: "80px",
								borderRadius: theme.shape.avatar.square,
								objectFit: "cover",
							})}
						></Box>
					</Grid>
					<Grid item xs={5} sx={{ display: "grid", alignContent: "center", pl: 1 }}>
						<Box>
							<Typography
								variant="headline6"
								sx={{
									display: "block",
									overflow: "hidden",
									textOverflow: "ellipsis",
									whiteSpace: "nowrap",
									maxWidth: "125px",
								}}
							>
								{data?.company?.companyName}
							</Typography>
						</Box>
						<Box>
							<Typography
								variant="label_large"
								sx={{
									display: "block",
									overflow: "hidden",
									textOverflow: "ellipsis",
									maxWidth: "80px",
									maxHeight: "44px",
								}}
							>
								{data?.company?.tagLine}
							</Typography>
						</Box>
					</Grid>
					<Grid item xs={4} sx={{ display: "flex" }}>
						<Button
							onClick={() => {
								handleViewProfile(data.company.id);
							}}
							sx={{ width: "auto" }}
						>
							View Profile
						</Button>
					</Grid>
				</Grid>
			</ConnectedStartupCard>
		</>
	);
};

export default ConnectedCardController;
