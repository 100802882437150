import styled from "@emotion/styled";
import { Box, Button } from "@mui/material";
import { DEFAULT_COVER_IMG } from "../../../../constants";

export const UploadVideoBox = styled.div(({ theme, BG_IMAGE = false }) => {
	return {
		width: "100%",
		minHeight: BG_IMAGE ? "800px" : "600px",
		backgroundImage: `url(${DEFAULT_COVER_IMG})`,
		backgroundRepeat: "no-repeat",
		background: !BG_IMAGE && "linear-gradient(0deg, #DADCE0, #DADCE0), linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), #DADCE0;",
		border: "1px solid transparent",
		borderRadius: theme.shape.standard13.borderRadius,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		position: "relative",
		marginLeft: BG_IMAGE && theme.spacing(3),
	};
});

export const UploadVideoButton = styled(Button)(({ isVideo = false }) => ({
	position: "absolute",
	top: isVideo ? "30px" : "50%",
	left: !isVideo && "50%",
	right: isVideo && "30px",
	transform: !isVideo && "translate(-50%, -50%)",
	width: "max-content",
}));

export const RichTextEditorContainer = styled.div(({ theme }) => {
	return {
		minHeight: "300px",
		border: `1px solid ${theme.palette.containerBorder.lightOutline}`,
		padding: "0px 16px 0px 16px",
		borderRadius: theme.shape.standard13.borderRadius,
	};
});

export const VideoPlayerContainer = styled.div(({ theme }) => {
	return {
		borderRadius: theme.shape.standard13.borderRadius,
		boxShadow: "none",
		overflow: "hidden",
		"& video": {
			borderRadius: theme.shape.standard13.borderRadius,
			objectFit: "cover",
		},
	};
});

export const ImageContainer = styled(Box)(({ theme, BG_IMAGE }) => {
	return {
		width: "100%",
		borderRadius: theme.shape.standard13.borderRadius,
	};
});

export const UploadPitchDeckBox = styled.div(({ theme }) => {
	return {
		width: "100%",
		minHeight: "600px",
		backgroundImage: `url(${DEFAULT_COVER_IMG})`,
		backgroundRepeat: "no-repeat",
		background: "linear-gradient(0deg, #DADCE0, #DADCE0), linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), #DADCE0;",
		border: "1px solid transparent",
		borderRadius: theme.shape.standard13.borderRadius,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		position: "relative",
	};
});