import { InputBase, MenuItem, Select, styled } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { TIMEZONE_LIST } from "../../../../constants";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
	"label + &": {
		marginTop: theme.spacing(3),
	},
	"& .MuiInputBase-input": {
		borderRadius: "100px",
		position: "relative",
		backgroundColor: theme.palette.background.paper,
		border: "none",
		padding: "18px 20px",
		boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #F2F4F7",
		fontFamily: '"PoppinsSemibold", "Helvetica", "Arial", sans-serif',
		fontSize: "16px",
		lineHeight: "24px",
	},
	"& .MuiSelect-select:focus": {
		borderRadius: "100px",
	},
}));

function TimezoneSelection({ selectedTimeZone, setSelectedTimeZone }) {
	// const timeZones = moment.tz.names();
	const timeZones = TIMEZONE_LIST;

	// useEffect(() => {
	// 	if (value) {
	// 		setSelectedTimeZone(value);
	// 	} else {
	// 		//if not found , select brower timezone
	// 		setSelectedTimeZone(moment.tz.guess());
	// 	}
	// }, [value]);

	// useEffect(() => {
	// 	if (onChange) {
	// 		onChange(selectedTimeZone);
	// 	}
	// }, [selectedTimeZone]);

	return (
		<Select
			value={selectedTimeZone}
			onChange={(event) => {
				setSelectedTimeZone(event.target.value);
			}}
			IconComponent={(props) => <ExpandMoreIcon {...props} sx={{ color: "black !important", mr: "10px" }} />}
			sx={{
				display: "flex",
				flex: 1,
				maxWidth: "400px",
			}}
			input={<BootstrapInput />}
		>
			{timeZones.map((option, index) => (
				<MenuItem key={index} value={option.zone}>
					{option.name?.replace(/_/g, " ")}
				</MenuItem>
			))}
		</Select>
	);
}

export default TimezoneSelection;
