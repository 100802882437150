import * as actionTypes from "../../actions/actionTypes";
import { updateObject } from "../../../utils/updateObject";

const initialState = {
  loading: false,
  startupPublicData: [],
};

const startupPublicProfileData = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_STARTUP_PUBLIC_DATA: {
      return action.payload === null ? initialState : updateObject(state, action.payload);
    }
    default:
      return state;
  }
};
export default startupPublicProfileData;
