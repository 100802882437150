import React, { useEffect, useState, useRef } from "react";
import { Box, Button, Card, Typography, Table, TableHead, TableBody, TableRow, TableCell, TableContainer, Grid, CircularProgress } from "@mui/material";
import { IN_PROGRESS_ICON, COMPLETED_DEFAULT } from "../../../../../constants/image";
import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import { useHistory } from "react-router-dom";
import theme from "../../../../../constants/Theme";
import moment from "moment";
import { useDispatch } from "react-redux";
import { setMixpanelData } from "../../../../../modules/actions";

export default function PowerUpTable({
	details,
	isAscendingName,
	isAscendingDate,
	handleRedirectior,
	handleArchive,
	handleGetDetails,
	handleSort,
	page,
	totalPages,
	setPage,
	callNextPage,
	isLast,
}) {
	const [lastElement, setLastElement] = useState(null);
	const dispatch = useDispatch();

	const observer = useRef(
		new IntersectionObserver((entries) => {
			const first = entries[0];
			if (first.isIntersecting) {
				callNextPage();
			}
		})
	);

	useEffect(() => {
		const currentElement = lastElement;
		const currentObserver = observer.current;

		if (currentElement) {
			currentObserver.observe(currentElement);
		}

		return () => {
			if (currentElement) {
				currentObserver.unobserve(currentElement);
			}
		};
	}, [lastElement]);

	return (
		<>
			<Box sx={{ display: "flex", width: 1 }}>
				{details.filter((item) => !item.isArchived)?.length > 0 && (
					<Grid container gridTemplateColumns={"repeat(12,1fr)"} display="grid" gap={2}>
						<Grid item xs={12} md={12} gridColumn={"span 12"}>
							<Card size="large" variant="nooutlined" sx={{ mt: 3 }}>
								<Box>
									<TableContainer>
										<Table aria-label="simple table">
											<TableHead>
												<TableRow>
													<TableCell align="left">
														<Typography
															variant="title_small"
															sx={{
																whiteSpace: "nowrap",
																display: "flex",
																flexDirection: "row",
																gap: 1,
																alignItems: "center",
																cursor: "pointer",
															}}
															onClick={() => {
																isAscendingName ? handleSort("DESC", "name") : handleSort("ASC", "name");
															}}
														>
															Name
															<Box
																style={{
																	cursor: "pointer",
																}}
															>
																{isAscendingName ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
															</Box>
														</Typography>
													</TableCell>

													<TableCell align="left">
														<Typography
															variant="title_small"
															sx={{
																whiteSpace: "nowrap",
																display: "flex",
																flexDirection: "row",
																gap: 1,
																alignItems: "center",
																cursor: "pointer",
															}}
															onClick={() => {
																isAscendingDate ? handleSort("DESC", "lastModifiedAt") : handleSort("ASC", "lastModifiedAt");
															}}
														>
															Modified Date
															<Box
																style={{
																	cursor: "pointer",
																}}
															>
																{isAscendingDate ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
															</Box>
														</Typography>
													</TableCell>
													<TableCell align="left">
														<Typography variant="title_small" sx={{ whiteSpace: "nowrap" }}>
															Modified By
														</Typography>
													</TableCell>
													<TableCell align="left">
														<Typography variant="title_small" sx={{ whiteSpace: "nowrap" }}>
															Status
														</Typography>
													</TableCell>
													<TableCell align="left">{/* button's */}</TableCell>
													<TableCell align="left">{/* Archive */}</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{details
													.filter((item) => !item.isArchived)
													?.map((item, index) => {
														return (
															<>
																<TableRow
																	key={item.id}
																	sx={{
																		"&:last-child td, &:last-child th": {
																			border: 0,
																		},
																	}}
																>
																	<TableCell align="left">
																		<Typography variant="bodyLarge">
																			{item?.name ? item?.name : `Version ${index + 1}`}
																		</Typography>
																	</TableCell>
																	<TableCell align="left">
																		<Typography variant="bodyLarge">
																			{moment(item?.lastModifiedAt).format("MMM D, YYYY")}
																		</Typography>
																	</TableCell>
																	<TableCell align="left">
																		<Typography variant="bodyLarge">{item?.modifiedBy ? item?.modifiedBy : "-"}</Typography>
																	</TableCell>
																	<TableCell align="left">
																		<Box
																			sx={{
																				display: "flex",
																				flexDirection: "row",
																				gap: 1,
																				border: `1px solid ${theme.palette.secondary.dark}`,
																				borderRadius: 2,
																				width: "max-content",
																				p: 1,
																			}}
																		>
																			<Box
																				src={item?.isCompleted ? COMPLETED_DEFAULT : IN_PROGRESS_ICON}
																				component="img"
																				sx={{ maxHeight: "20px" }}
																			/>
																			{item?.isCompleted ? "Complete" : "In Progress"}
																		</Box>
																	</TableCell>
																	<TableCell align="left">
																		<Button
																			startIcon={<ArrowForwardIosOutlinedIcon sx={{ maxHeight: "15px" }} />}
																			variant="contained"
																			sx={{
																				width: "-webkit-fill-available",
																			}}
																			onClick={() => {
																				handleRedirectior(
																					item?.isCompleted,
																					item,
																					item?.name ? item?.name : `Version ${index + 1}`
																				);
																			}}
																		>
																			{item?.isCompleted ? "View" : "Resume"}
																		</Button>
																	</TableCell>
																	<TableCell align="left">
																		<ArchiveOutlinedIcon
																			onClick={() => {
																				handleArchive(item?.id);
																				dispatch(
																					setMixpanelData({
																						powerUpPercentComplete: item.completedSteps / item.totalSteps,
																					})
																				);
																			}}
																			sx={{ cursor: "pointer" }}
																		/>
																	</TableCell>
																</TableRow>
															</>
														);
													})}
											</TableBody>
										</Table>
									</TableContainer>
									{/* {!isLast && (
										<Box
											sx={{
												display: "flex",
												width: 1,
												justifyContent: "center",
												py: 4,
											}}
											ref={setLastElement}
										>
											{console.log("loading")}
											<CircularProgress />
										</Box>
									)} */}
								</Box>
							</Card>
						</Grid>
					</Grid>
				)}
			</Box>
		</>
	);
}
