import React, { Fragment, useEffect, useState } from "react";
import { Box, Stack, Typography, CircularProgress } from "@mui/material";
import RatingComponent from "../../../../../common/rating";
import services from "../../../../../services";
import { RateThisPowerUP } from "./rate-powerup";
import { useParams } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroller";
import { ReviewTile } from "../../../../../containers/Workspace/Reviews/ReviewTile";

const RatingChip = ({ ...props }) => (
  <Box
    sx={theme => ({
      border: "1px solid #7C757F",
      borderRadius: theme.shape.standard23.borderRadius,
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      px: 2,
      gap: "12px",
      height: "44px", // Static height to constrain height to maintain 'pill' shape w/ border radius.
    })}
  >
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "flex-end",
        gap: "2px",
        flex: "none",
        order: 0,
        flexGrow: 0,
      }}
    >
      <Typography
        component={"span"}
        variant="displaySmall"
        color={"#4B454E"}
        sx={{
          fontSize: props.width > 330 ? "initial" : "20px !important",
          lineHeight: props.width > 330 ? "initial" : "20px !important",
        }}
      >
        {props.rating || 0}
      </Typography>
      <Typography
        component={"span"}
        variant="lable_large"
        color={"#7C757F"}
        sx={{
          fontSize: props.width > 330 ? "initial" : "10px !important",
          lineHeight: props.width > 330 ? "initial" : "10px !important",
        }}
      >
        /5
      </Typography>
    </Box>
    <Box>
      <RatingComponent
        rating={props.rating}
        precision={0.5}
        iconColor="#4B454E"
        color="#4B454E"
      />
    </Box>
  </Box>
);

export const ReviewsTab = ({ ...props }) => {
  const [reviewList, setReviewList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isFirst, setIsFirst] = useState(true);
  const [isLast, setIsLast] = useState(true);
  const [totalReviews, setTotalReviews] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [rating, setRating] = useState(0);
  const [sort, setSort] = useState("");
  const [filter, setFilter] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [moduleResponse, setModuleResponse] = useState("");
  const searchParams = useParams();

  const refreshList = () => {
    setReviewList([]);
    setIsFirst(true);
    setIsLast(true);
    setCurrentPage(1);
    setTotalReviews(0);
    setTotalPages(0);
    initList();
  };

  const getMeta = () => {
    //getReviewsList(
    //  perpage,
    //  page,
    //  sort,
    //  filter,
    //  entityID,
    //  entityType,
    //  details)
    return services
      .getReviewsList(10, 1, "", "", props.powerupID, "POWER_UP", true)
      .then(response => {
        let ratingDetails = response.data.data.ratingDetails;
        if (ratingDetails && ratingDetails.totalReviews) {
          setTotalReviews(ratingDetails.totalReviews);
        }
        if (ratingDetails && ratingDetails.rating) {
          setRating(ratingDetails.rating);
        }
      })
      .catch(error => console.log(error));
  };

  const getNext = async () => {
    if (!isLast) {
      setCurrentPage(currentPage + 1);
      return getList();
    }
  };

  const getList = () => {
    //getReviewsList(
    //  perpage,
    //  page,
    //  sort,
    //  filter,
    //  entityID,
    //  entityType,
    //  details)
    return services
      .getReviewsList(
        10,
        currentPage,
        sort,
        filter,
        props.powerupID,
        "POWER_UP",
        false
      )
      .then(response => {
        let paginationModel = response.data.data.paginationModel;
        if (paginationModel && paginationModel.isFirst) {
          setIsFirst(paginationModel.isFirst);
        }
        if (paginationModel && paginationModel.isLast) {
          setIsLast(paginationModel.isLast);
        }
        if (paginationModel && paginationModel.totalPages) {
          setTotalPages(paginationModel.totalPages);
        }
        if (paginationModel && paginationModel.models) {
          setReviewList(rl => rl.concat([...paginationModel.models]));
        }
      })
      .catch(error => console.log(error));
  };

  const initList = async () => {
    await getMeta()
      .then(() => getList())
      .catch(e => {
        console.log("Error initializing reviews list.", e);
      });
    setIsLoading(false);
  };

  useEffect(() => {
    console.log(props.scrollRef);
    // We need the version to submit a powerup review
    if (searchParams && searchParams.version) {
      setModuleResponse(searchParams.version);
    }
    initList();
  }, []);

  return (
    <Fragment>
      <Typography
        variant="title_medium"
        sx={{ textTransform: "capitalize", mb: 2 }}
      >
        Reviews
      </Typography>
      <Box
        sx={{
          mb: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <RatingChip width={props.width} rating={rating} />
        <Typography component="p" variant="caption" sx={{ mt: 1 }}>
          {totalReviews} customer reviews
        </Typography>
      </Box>
      <Box sx={{ my: 1 }}>
        <RateThisPowerUP
          entityID={props.powerupID}
          responseID={moduleResponse}
          refreshList={() => refreshList()}
        />
      </Box>
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <InfiniteScroll
          hasMore={true}
          loadMore={getNext}
          useWindow={false}
          getScrollParent={() => props.scrollRef.current}
        >
          <Stack spacing={2}>
            {reviewList.map((review, index) => (
              <ReviewTile
                reviewObj={review}
                key={index}
                parentWidth={props.width}
              />
            ))}
          </Stack>
        </InfiniteScroll>
      )}
    </Fragment>
  );
};
