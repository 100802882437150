import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useRef } from "react";
import useDimensions from "react-cool-dimensions";
import { CALENER_ICON_V3 } from "../../../constants";
import { TextWrapper } from "../style";

function CarouselCard({ itemDetails, handleItemClick, handleApplyNow }) {
	const { observe, width } = useDimensions({
		onResize: ({ width }) => {},
	});
	const InfoCorner = ({ itemDetails }) => {
		const { title, description, displayDate } = itemDetails;
		return (
			<Box
				sx={{
					...{
						borderRadius: "0px 100px 0px 0px",
						padding: "2.5rem",
						gap: "0.625rem",
					},
					background: (theme) => theme.palette.secondary.main,
					display: "flex",
					flexDirection: "column",
					alignItems: "flex-start",
				}}
			>
				<TextWrapper variant={"Text/xl/Semibold"} wrapLine={1}>
					{title}
				</TextWrapper>

				<TextWrapper
					variant="Text/md/Regular"
					component={"p"}
					wrapLine={1}
					sx={{ "& p": { margin: 0 }, "& ul": { marginBlockStart: 0, marginBlockEnd: 0 } }}
				>
					<span
						dangerouslySetInnerHTML={{
							__html: description,
						}}
					/>
				</TextWrapper>
				<Box display={"flex"} alignItems={"center"} gap={2}>
					<Box component={"img"} src={CALENER_ICON_V3} alt="calender" />
					<Typography variant="Text/sm/Semibold" sx={{ color: "white" }}>
						{displayDate}
					</Typography>
				</Box>

				<Button
					variant="DS1"
					onClick={(e) => {
						e.stopPropagation();
						handleApplyNow(itemDetails);
					}}
				>
					Apply Now!
				</Button>
			</Box>
		);
	};
	const calculateHeight = (width) => {
		const maxWidth = 1630;
		const maxHeight = 950;
		const aspectRatio = maxWidth / maxHeight;
		const calculatedHeight = width / aspectRatio;

		return calculatedHeight > maxHeight ? maxHeight : calculatedHeight;
	};

	const height = calculateHeight(width);
	const infoCornerRef = useRef(null);
	const minHeight = infoCornerRef.current ? infoCornerRef.current.clientHeight : "auto";

	useEffect(() => {
		observe();
	}, [observe]);

	return (
		<React.Fragment>
			<Box
				sx={{
					position: "relative",
					width: 1,
					height: `100%`,
					minHeight: `${minHeight}px`,
					maxWidth: "1630px",
					maxHeight: "510px",
					backgroundColor: "gray",
					borderRadius: "1.875rem",
					overflow: "hidden",
				}}
			>
				{" "}
				<Box
					ref={observe}
					sx={{
						position: "relative",
						width: 1,
						height: `${itemDetails?.heroImageUrl ? height : 525}px`,
						minHeight: `${minHeight}px`,
						maxWidth: "1630px",
						maxHeight: "510px",
						backgroundColor: "gray",
						borderRadius: "1.875rem",
						overflow: "hidden",
						backgroundImage: `url(${itemDetails?.heroImageUrl})`,
						backgroundSize: "cover",
						backgroundPosition: "center",
					}}
				>
					{width > 800 && (
						<Box
							ref={infoCornerRef}
							sx={{
								position: "absolute",
								bottom: "0",
								left: "0",
								maxWidth: "35.3125rem",
								width: 1,
							}}
						>
							<InfoCorner itemDetails={itemDetails} handleItemClick={handleItemClick} />{" "}
						</Box>
					)}
				</Box>
			</Box>
		</React.Fragment>
	);
}

export default CarouselCard;
