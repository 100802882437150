import auth0 from "auth0-js";
import axios from "axios";
import { persistor } from "../modules";

import { clearSessionStorage } from "../utils/sessionStorage";
import { clearLocalStorage } from "./localStorage";

async function webAuth() {
	try {
		let webAuthConfig = new auth0.WebAuth({
			domain: process.env.REACT_APP_AUTH0_DOMAIN,
			clientID: process.env.REACT_APP_AUTH0_CLIENT,
			responseType: "token id_token",
			scope: "offline_access openid",
			redirectUri: process.env.REACT_APP_REDIRECTION_AUTHORIZE,
		});
		return webAuthConfig;
	} catch (e) {
		console.error(e);
	}
}

export const otpStart = ({ email }) => {
	return new Promise(async (resolve, reject) => {
		const variables = {
			email,
			connection: "email",
			send: "link",
		};
		const webAuth0 = await webAuth();

		webAuth0.passwordlessStart(variables, (err, res) => {
			if (err) {
				reject(err);
			} else {
				resolve(res);
			}
		});
	});
};

export const createUser = ({ email, password, emailVerified }) => {
	return new Promise(async (resolve, reject) => {
		const params = {
			connection: "Username-Password-Authentication",
			email: email,
			password: password,
			username: email,
			email_verified: emailVerified,
			verify_email: !emailVerified,
		};

		const webAuth0 = await webAuth();

		webAuth0.signup(params, (err, res) => {
			if (err) {
				reject(err);
			} else {
				resolve(res);
			}
		});
	});
};

export const manualLogin = ({ email, password }) => {
	return new Promise(async (resolve, reject) => {
		const webAuth0 = await webAuth();

		const params = {
			connection: "Username-Password-Authentication",
			username: email,
			password: password,
			grant_type: "password",
		};

		webAuth0.login(params, (err, res) => {
			if (err) {
				reject(err);
			} else {
				resolve(res);
			}
		});
	});
};

export const passwordReset = ({ email }) => {
	return new Promise(async (resolve, reject) => {
		const variables = {
			email,
			connection: "Username-Password-Authentication",
			send: "link",
		};

		const webAuth0 = await webAuth();
		webAuth0.changePassword(variables, (err, res) => {
			if (err) {
				reject(err);
			} else {
				resolve(res);
			}
		});
	});
};

export const authWebLogout = async (returnTo = process.env.REACT_APP_REDIRECTION_SIGNOUT_URL) => {
	try {
		const webAuth0 = await webAuth();
		webAuth0.logout({
			returnTo: returnTo,
		});
		// Clear redux persisted storage on logout.
		persistor.purge();
		clearSessionStorage();
		clearLocalStorage();
	} catch (e) {
		console.log(e);
	}
};

export const verifyPassword = async ({ email, password }) => {
	let data = {
		client_id: process.env.REACT_APP_AUTH0_CLIENT,
		connection: "Username-Password-Authentication",
		grant_type: "password",
		username: email,
		password: password,
		client_secret: "FfjQxK1JRyHCzXklXWKKOFr10rpla9v2jWT0glVah6zwN1XM5jiLZYep4AoalUV8",
	};
	axios.post(`https://${process.env.REACT_APP_AUTH0_DOMAIN}/oauth/token`, data).then((res) => {
		console.log(res);
	});
};
