import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../../../Messaging/store";
import MessagesBox from "../message/MessagesBox";
import MessageInputField from "../message/MessageInputField";
import { Card, Typography, Box, useMediaQuery, IconButton, Divider } from "@mui/material";
import { ChatBubbleOutline } from "@mui/icons-material";
import ConversationDetails from "./ConversationDetails";
import services from "../../../../../services";
import { useState, useEffect } from "react";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

const ConversationContainer = (props) => {
	const sid = useSelector((state) => state.sid);
	const loadingStatus = useSelector((state) => state.loadingStatus);
	const participants = useSelector((state) => state.participants)[sid] ?? [];
	const messages = useSelector((state) => state.messages);
	const typingData = useSelector((state) => state.typingData)[sid] ?? [];
	const lastReadIndex = useSelector((state) => state.lastReadIndex);
	const dispatch = useDispatch();
	const { pushMessages, updateCurrentConversation } = bindActionCreators(actionCreators, dispatch);
	const customPoint = "820"; //figma
	const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down(customPoint));

	const getConvoMeta = async () => {
		//await services
		//  .getConversationByConvoIDServiceCall(sid)
		//  .then(resp => {
		//    setConvoMeta(resp?.data?.data);
		//  })
		//  .catch(e => {
		//    console.log("Error grabbing conversation meta.", e);
		//  });
	};
	//useEffect(() => {
	//  getConvoMeta();
	//}, [props.conversation]);

	return (
		<>
			<Box pt={1.5} height={"10px"}>
				<Divider orientation="vertical" sx={{ position: "fixed", height: "70%" }} />
			</Box>
			{sid && props.conversation && props.client ? (
				<Box
					sx={{
						height: 1,
						overflowY: "hidden",
						display: "flex",
						flexDirection: sid && props.conversation && props.client ? "column " : "row",
						backgroundColor: (theme) => theme.palette.secondary.white,
						borderRadius: "8px",
						width: "-webkit-fill-available",
					}}
				>
					<Box
						sx={{
							display: "flex",
							height: "80%",
							overflowY: "hidden",
							flexDirection: "column",
							position: "absolute",
							pt: 1.5,
							pl: 2,
							width: "-webkit-fill-available",
						}}
					>
						{isSmallScreen && (
							<Box sx={{ cursor: "pointer" }} onClick={() => updateCurrentConversation("")}>
								<KeyboardArrowLeftIcon style={{ width: "30px", height: "30px" }} />
							</Box>
						)}
						<ConversationDetails convoSid={sid} convo={props.conversation} participants={participants} />

						<Box sx={{ flex: 1, height: 1, overflowY: "scroll" }}>
							<MessagesBox
								key={sid}
								convoSid={sid}
								convo={props.conversation}
								addMessage={pushMessages}
								client={props.client}
								messages={messages[sid]}
								loadingState={loadingStatus}
								participants={participants}
								lastReadIndex={lastReadIndex}
							/>
						</Box>
						<Box>
							<MessageInputField
								convoSid={sid}
								client={props.client}
								messages={messages[sid]}
								convo={props.conversation}
								typingData={typingData}
							/>
						</Box>
					</Box>
				</Box>
			) : (
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						flexDirection: "column",
						gap: 1,
						pl: 1,
						ml: "60%",
						mt: "20%",
						position: "absolute",
					}}
				>
					<ChatBubbleOutline color="primary" fontSize="large" sx={{ mb: 1 }} />
					<Typography
						variant="label_large"
						sx={(theme) => ({
							textAlign: "center",
							color: theme.palette.secondary.outline,
						})}
					>
						Select a conversation
					</Typography>
				</Box>
			)}
		</>
	);
};
export default ConversationContainer;
