import React from "react";
import {
  Divider,
  ListItemIcon,
  List,
  ListItemButton,
  ListItem,
  Popover,
  Typography,
} from "@mui/material";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import PushPinOutlinedIcon from "@mui/icons-material/PushPinOutlined";
import PushPinIcon from "@mui/icons-material/PushPin";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import TooltipWrapper from "../../common/Tooltip";
import services from "../../services";

export default function CuratorCardPopup({
  open,
  onClose,
  anchorEl,
  handleOpenTag,
  handleOpenReference,
  handleDeletePopup,
  curatorId,
  cardData,
  isQuikNote = false,
}) {
  const handlePinAction = e => {
    let payload;
    if (cardData.id) {
      payload = {
        title: cardData?.title,
        description: cardData?.description,
        noteType: cardData?.noteType,
        isPinned: !cardData?.isPinned,
      };
    } else {
      payload = {
        title: "",
        description: "",
        noteType: isQuikNote ? "QUICK_NOTE" : "NOTE",
        isPinned: true,
      };
    }

    if (cardData.id) {
      services
        .updateResearchCuratorApiCall(payload, cardData.id)
        .then(response => {
          if (response.data.message === "Success") {
            onClose(true);
          }
        })
        .catch(e => {
          console.log("Submit", e);
        });
    } else {
      services
        .addResearchCuratorApiCall(payload)
        .then(res => {
          services
            .updateResearchCuratorApiCall(payload, res?.data?.data?.id)
            .then(response => {
              if (response.data.message === "Success") {
                onClose(true, res?.data?.data?.id);
              }
            })
            .catch(e => {
              console.log("Submit", e);
            });
        })
        .catch(e => {
          console.log("Submit", e);
        });
    }
  };

  return (
    <>
      <Popover
        open={open}
        onClose={onClose}
        anchorEl={anchorEl}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        PaperProps={{
          sx: theme => ({
            borderRadius: theme.shape.standard5.borderRadius,
            background: theme.palette.secondary.outline2,
            minWidth: "300px",
          }),
        }}
      >
        <List>
          {!isQuikNote && (
            <TooltipWrapper>
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <PersonAddAltIcon
                      sx={{ fill: theme => theme.palette.secondary.main }}
                    />
                  </ListItemIcon>
                  <Typography variant="body_large">Share</Typography>
                </ListItemButton>
              </ListItem>
            </TooltipWrapper>
          )}

          <ListItem disablePadding>
            <ListItemButton onClick={handlePinAction}>
              <ListItemIcon>
                {cardData?.isPinned ? (
                  <PushPinIcon
                    sx={{ fill: theme => theme.palette.secondary.main }}
                  />
                ) : (
                  <PushPinOutlinedIcon
                    sx={{ fill: theme => theme.palette.secondary.main }}
                  />
                )}
              </ListItemIcon>
              <Typography variant="body_large">
                {cardData?.isPinned ? "Unpin" : "Pin"}
              </Typography>
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
            <ListItemButton onClick={handleDeletePopup}>
              <ListItemIcon>
                <DeleteOutlineOutlinedIcon
                  sx={{ fill: theme => theme.palette.secondary.main }}
                />
              </ListItemIcon>
              <Typography variant="body_large">Delete</Typography>
            </ListItemButton>
          </ListItem>

          <Divider sx={{ my: 1 }} />

          <ListItem disablePadding>
            <ListItemButton onClick={handleOpenReference}>
              <ListItemIcon>
                <AddOutlinedIcon
                  sx={{ fill: theme => theme.palette.secondary.main }}
                />
              </ListItemIcon>
              <Typography variant="body_large">Add New Reference</Typography>
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
            <ListItemButton onClick={handleOpenTag}>
              <ListItemIcon>
                <AddOutlinedIcon
                  sx={{ fill: theme => theme.palette.secondary.main }}
                />
              </ListItemIcon>
              <Typography variant="body_large">Add New Tag</Typography>
            </ListItemButton>
          </ListItem>
        </List>
      </Popover>
    </>
  );
}
