import { Card } from "@mui/material";
import React from "react";

function InsightCardComp({ children, sxProps }) {
	return (
		<Card
			sx={{
				borderRadius: "40px",
				p: {
					xs: 2,
					md: 3,
					lg: 4,
				},
				boxShadow: "0px 10px 15px rgba(0, 0, 0, 0.05)",
				...sxProps,
			}}
		>
			{children}
		</Card>
	);
}

export default InsightCardComp;
