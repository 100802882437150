import React, { useEffect } from "react";
import { Box } from "@mui/material";
import { useState } from "react";
import messages from "../../../../../../../constants/messages";
import {
  InputFieldsWrapper,
  InputLabelField,
  TextInpuField,
} from "../../styles";

export default function Sam({ userInputs, setUserInputs, samInputValid }) {
  const [errorMessages, setErrorMessages] = useState({
    focusOn: "",
    percentageOfPopulation: "",
    furtherFocusOn: "",
    basedIn: "",
    percentageOfSam: "",
  });
  const handleInputChange = (event, key) => {
    const value = event.target.value.trimStart();
    const currentInput = { ...userInputs };
    currentInput[key] = value;
    setUserInputs(currentInput);
  };

  const handleBlur = (event, key) => {
    const value = event.target.value;

    switch (key) {
      case "focusOn":
        if (samInputValid(key, value)) {
          setErrorMessages({ ...errorMessages, focusOn: "" });
        } else {
          setErrorMessages({
            ...errorMessages,
            focusOn: messages.TAM_SAM_SOM_TEXTFIELD,
          });
        }
        break;

      case "percentageOfPopulation":
        if (samInputValid(key, value)) {
          setErrorMessages({ ...errorMessages, percentageOfPopulation: "" });
        } else {
          setErrorMessages({
            ...errorMessages,
            percentageOfPopulation: "Invalid",
          });
        }
        break;

      case "basedIn":
        if (samInputValid(key, value)) {
          setErrorMessages({ ...errorMessages, basedIn: "" });
        } else {
          setErrorMessages({
            ...errorMessages,
            basedIn: messages.TAM_SAM_SOM_TEXTFIELD,
          });
        }
        break;

      case "furtherFocusOn":
        if (samInputValid(key, value)) {
          setErrorMessages({ ...errorMessages, furtherFocusOn: "" });
        } else {
          setErrorMessages({
            ...errorMessages,
            furtherFocusOn: messages.TAM_SAM_SOM_TEXTFIELD,
          });
        }
        break;

      case "percentageOfSam":
        if (samInputValid(key, value)) {
          setErrorMessages({ ...errorMessages, percentageOfSam: "" });
        } else {
          setErrorMessages({
            ...errorMessages,
            percentageOfSam: "Invalid",
          });
        }
        break;
    }
  };

  return (
    <Box>
      <InputFieldsWrapper>
        <InputLabelField>Of this population, I will focus on</InputLabelField>
        <TextInpuField
          placeholder="long-distance truck drivers"
          onChange={e => handleInputChange(e, "focusOn")}
          value={userInputs?.focusOn}
          onBlur={e => handleBlur(e, "focusOn")}
          error={!!errorMessages.focusOn}
          helperText={errorMessages.focusOn}
        />  
        <InputLabelField>who make up</InputLabelField>
        <TextInpuField
          placeholder="#"
          isNumber={true}
          value={userInputs?.percentageOfPopulation}
          onChange={e => handleInputChange(e, "percentageOfPopulation")}
          error={!!errorMessages.percentageOfPopulation}
          onBlur={e => handleBlur(e, "percentageOfPopulation")}
        />
      </InputFieldsWrapper>
      <Box sx={{ my: 2 }}>
        <InputLabelField>percentage of the population.</InputLabelField>
      </Box>
      <InputFieldsWrapper>
        <InputLabelField>Furthermore, I will only focus on</InputLabelField>
        <TextInpuField
          placeholder="long-distance truck drivers"
          value={userInputs?.furtherFocusOn}
          onChange={e => handleInputChange(e, "furtherFocusOn")}
          error={!!errorMessages.furtherFocusOn}
          onBlur={e => handleBlur(e, "furtherFocusOn")}
          helperText={errorMessages.furtherFocusOn}
        />
        <InputLabelField>who</InputLabelField>
      </InputFieldsWrapper>
      <InputFieldsWrapper>
        <TextInpuField
          placeholder="are based in California"
          onChange={e => handleInputChange(e, "basedIn")}
          value={userInputs?.basedIn}
          error={!!errorMessages.basedIn}
          onBlur={e => handleBlur(e, "basedIn")}
          helperText={errorMessages.basedIn}
        />
        <InputLabelField>who make up</InputLabelField>
        <TextInpuField
          placeholder="#"
          isNumber={true}
          value={userInputs?.percentageOfSam}
          onChange={e => handleInputChange(e, "percentageOfSam")}
          error={!!errorMessages.percentageOfSam}
          onBlur={e => handleBlur(e, "percentageOfSam")}
        />
        <InputLabelField>percent of the SAM.</InputLabelField>
      </InputFieldsWrapper>
    </Box>
  );
}
