import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getBurnRunwayGridData } from "../Functions/common";
import BurnRunChartContainer from "../Charts/BurnRunChartContainer";
import BurnRunDataGrid from "../DataGrid/BurnRunGrid";
import { handleMixPanel } from "../../../../utils/mixPanelEventHandling";
import { setBIDashboardData } from "../../../../modules/actions/biDashboardActions";
import BIDataGridDialog from "../DataGrid/BIDataGridDialog";

function BurnRunway({ companyId, readOnly }) {
	const dispatch = useDispatch();
	const burnAndRunway = useSelector((store) => store?.biDashboard?.burnAndRunway);
	const loadingBurnAndRunway = useSelector((store) => store?.biDashboard?.loadingBurnAndRunway);
	const openBurnAndRunway = useSelector((store) => store?.biDashboard?.openBurnAndRunway);
	const [isEdited, setEdited] = useState(false);

	const gridData = burnAndRunway?.data ? burnAndRunway?.data : [];

	const ownCompanyId = useSelector((state) => state?.auth?.companyId);
	const usedCompanyId = companyId ? companyId : ownCompanyId;

	const fetchData = async () => {
		try {
			dispatch(setBIDashboardData({ loadingBurnAndRunway: true }));
			const responseData = await getBurnRunwayGridData(usedCompanyId);
			if (responseData) {
				dispatch(setBIDashboardData({ burnAndRunway: responseData }));
			}
			dispatch(setBIDashboardData({ loadingBurnAndRunway: false }));
		} catch (error) {
			dispatch(setBIDashboardData({ loadingBurnAndRunway: false }));
			console.log("error", error);
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	const onChartClick = () => {
		handleMixPanel("Grid Burn Viewed");
		dispatch(setBIDashboardData({ openBurnAndRunway: true }));
	};

	const handleClose = useCallback(() => {
		if (isEdited) {
			dispatch(
				setBIDashboardData({
					openBurnAndRunwayLeave: true,
				})
			);
		} else {
			dispatch(setBIDashboardData({ openBurnAndRunway: false }));
		}
	}, [isEdited]);

	return (
		<>
			<BurnRunChartContainer data={burnAndRunway} onChartClick={onChartClick} loading={loadingBurnAndRunway} readOnly={readOnly} />
			<BIDataGridDialog title="Burn & Runway" isOpen={openBurnAndRunway} onClose={handleClose}>
				<BurnRunDataGrid gridData={gridData} readOnly={readOnly} usedCompanyId={usedCompanyId} isEdited={isEdited} setEdited={setEdited} />
			</BIDataGridDialog>
		</>
	);
}

export default BurnRunway;
