import React, { useState, useEffect } from "react";
import { Typography, Grid, Divider, Link, Box, Button, CircularProgress, Stack, useMediaQuery } from "@mui/material";
import { useSelector } from "react-redux";
import constants from "../../../../constants";
import theme from "../../../../constants/Theme";
import VideoPlayerComponent from "../../../../common/videoPlayer";
import TeamContainer from "../../Teams";
import JsxParser from "react-jsx-parser";
import { useParams } from "react-router-dom";
import { getYear } from "../../../../utils/date";
import ScriptEditor from "../../../../common/ScriptEditor";
import { RICH_TEXT_EDITOR_INLINE_CONTROLS, RICH_TEXT_EDITOR_MAIN_CONTROLS } from "../../../../constants/plugin-constants";
import { GetValidatedUrl } from "../../../../utils/getCorrectLink";
import DeleteIcon from "@mui/icons-material/Delete";
import MessageHandlerModal from "../../../../common/modal/MessageHandler";
import AddVideo from "../../../../common/AddVideo";
import CompleteYourProfile from "../../../../common/CompleteYourProfile";
import VideocamRoundedIcon from "@mui/icons-material/VideocamRounded";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { FILE_DOWNLOAD, PITCH_DECK_LOGO } from "../../../../constants";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import PlayCircleRoundedIcon from "@mui/icons-material/PlayCircleRounded";
import UploadVideoModal from "../../../../common/UploadVideoModal";
import UploadFileModal from "../../../../common/UploadFile";
import { PitchDeckViewSwitch } from "../../styles";

export default function AboutContainer(props) {
	const params = useParams();
	const companyId = params.id;
	const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
	const [website, setWebsite] = useState("");
	const [loading, setLoading] = useState(false);
	const [showDeleteButton, setShowDeleteButton] = useState(false);
	const [deleteConfirmPopup, setDeleteConfirmPopup] = useState(false);

	const [openAboutUsVideoModal, setOpenAboutUsVideoModal] = useState(false);
	const [openFounderStoryVideoModal, setOpenFounderStoryVideoModal] = useState(false);

	const [aboutUsVideoUploadUrl, setAboutUsVideoUploadUrl] = useState(props.profileArr.aboutUsVideoUrl);
	const [founderStoryVideoUploadUrl, setFounderStoryVideoUploadUrl] = useState(props.profileArr.founderStoryVideo);

	const [openDeck, setOpenDeck] = useState(false);
	const [pitchDeck, setPitchDeck] = useState("");
	const [pitchType, setPitchType] = useState("");

	useEffect(() => {
		if (props?.profileArr?.websiteLink) {
			const link = GetValidatedUrl(props?.profileArr?.websiteLink);
			setWebsite(link);
		}
	});

	useEffect(() => {
		if (props.profileArr?.videoUrl !== null && props.profileArr?.videoUrl !== "") {
			setShowDeleteButton(true);
		}
	}, []);

	const deleteVideoHandler = () => {
		props.handleDeleteVideo();
		props.setBrowseVidFlag(false);
		setDeleteConfirmPopup(false);
		setShowDeleteButton(false);
	};

	const renderFundingType = (data) => {
		if (data && data.fundingType) {
			try {
				return JSON.parse(data?.fundingType).join(", ");
			} catch (error) {
				return data.fundingType;
			}
		} else {
			return "";
		}
	};

	const renderExpertiseType = (data) => {
		if (data && data.expertise) {
			try {
				return JSON.parse(data?.expertise).join(", ");
			} catch (error) {
				return data.expertise.replaceAll(",", ", ");
			}
		} else {
			return "";
		}
	};

	useEffect(() => {
		if (props.profileArr?.pitchDeckLink) {
			let type = props.profileArr?.pitchDeckLink.substr(props.profileArr?.pitchDeckLink.length - 3);

			setPitchDeck(props.profileArr?.pitchDeckLink);
			if (type === "pdf") {
				setPitchType("pdf");
			} else {
				setPitchType("ppt");
			}
		}
	}, []);

	return (
		<Grid container spacing={3} flexDirection={"row"}>
			{/* About */}
			<Grid item xs={12} md={8}>
				<Grid container spacing={2} height="max-content" sx={{ pl: { md: 4 }, pt: 3 }}>
					<Grid item xs={12} md={12}>
						<Box
							sx={{
								color: `${theme.palette.text.primary}`,
							}}
						>
							<Typography variant="largeTitle" sx={(theme) => ({ color: theme.palette.secondary.korophKoal })}>
								About
							</Typography>
						</Box>
						<Box
							sx={{
								color: `${theme.palette.text.primary}`,
							}}
						>
							<Typography
								variant="body_large"
								sx={(theme) => ({
									color: theme.palette.secondary.outline,
								})}
							>
								{props.profileArr?.about !== "<p><br></p>" && props.profileArr?.about !== "" ? (
									<ScriptEditor
										initialValue={props?.profileArr?.about}
										onChange={() => {}}
										readOnly={true}
										toolbar={false}
										inlineToolbar={false}
										inlineToolbarControls={RICH_TEXT_EDITOR_INLINE_CONTROLS}
										controls={RICH_TEXT_EDITOR_MAIN_CONTROLS}
									/>
								) : (
									<Box>
										<Typography>{"-"}</Typography>
									</Box>
								)}
							</Typography>
						</Box>
					</Grid>
					<Grid item xs={12} md={12}>
						<Grid container flexDirection={"row"} sx={{ pt: 1 }}>
							<Grid item xs={12} md={6}>
								{!isSmallScreen && (
									<Stack flexDirection={"column"} gap={1}>
										<Box>
											<Typography
												variant="Body/16pxBold"
												sx={(theme) => ({
													color: theme.palette.secondary.korophKoal,
												})}
											>
												Elevator Pitch Video
											</Typography>
										</Box>
										<Box>
											<Typography
												variant="body_large"
												sx={(theme) => ({
													color: theme.palette.secondary.outline,
												})}
											>
												Upload a video about your startup’s overall mission, values, and current progress.
											</Typography>
										</Box>
									</Stack>
								)}
								{isSmallScreen && (
									<Stack flexDirection={"column"} gap={1}>
										<Box>
											<Typography
												variant="Body/16pxBold"
												sx={(theme) => ({
													color: theme.palette.secondary.korophKoal,
												})}
											>
												Elevator Pitch Video
											</Typography>
										</Box>
										<Box>
											<Typography
												variant="body_large"
												sx={(theme) => ({
													color: theme.palette.secondary.outline,
												})}
											>
												Upload a video about your startup’s overall mission, values, and current progress.
											</Typography>
										</Box>
										<Box>
											<Stack flexDirection={"column"} gap={1}>
												{!aboutUsVideoUploadUrl && !companyId && (
													<Box>
														<AddVideo
															height={200}
															handelAddMedia={() => {
																setOpenAboutUsVideoModal(true);
															}}
														/>
													</Box>
												)}
												{aboutUsVideoUploadUrl && (
													<Box sx={{ position: "relative" }}>
														<Box
															sx={(theme) => ({
																borderRadius: theme.shape.standard.borderRadius,
																boxShadow: "none",
																overflow: "hidden",
																mt: 3,
															})}
														>
															<Box>
																<VideoPlayerComponent height="200px" videoUrl={aboutUsVideoUploadUrl} />
															</Box>
														</Box>
														<Box
															sx={{
																mr: 3,
																background:
																	"linear-gradient(0deg, rgba(106, 117, 117, 0.02), rgba(106, 117, 117, 0.02)), linear-gradient(0deg, rgba(245, 248, 250, 0.05), rgba(245, 248, 250, 0.05)), #FCFCFC",
																boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
																borderRadius: "4px",
																width: "max-content",
																position: "absolute",
																top: "48px",
																right: 0,
															}}
														>
															{!companyId && (
																<Button
																	variant="outlined"
																	startIcon={<VideocamRoundedIcon />}
																	onClick={() => {
																		setOpenAboutUsVideoModal(true);
																	}}
																>
																	{aboutUsVideoUploadUrl ? "Edit Media" : "Add media"}
																</Button>
															)}
														</Box>
													</Box>
												)}
											</Stack>
										</Box>
									</Stack>
								)}
							</Grid>
							<Grid item xs={12} md={6} sx={{ pl: { xs: 0, md: 2 }, pt: { xs: 3, md: 0 } }}>
								{!isSmallScreen && (
									<Stack flexDirection={"column"} gap={1}>
										<Box>
											<Typography
												variant="Body/16pxBold"
												sx={(theme) => ({
													color: theme.palette.secondary.korophKoal,
												})}
											>
												Founder Story Video
											</Typography>
										</Box>
										<Box>
											<Typography
												variant="body_large"
												sx={(theme) => ({
													color: theme.palette.secondary.outline,
												})}
											>
												Upload a video about your startup’s unique story and background.
											</Typography>
										</Box>
										<Box sx={{ pt: 2 }}>
											<Typography
												variant="body_large"
												sx={(theme) => ({
													color: theme.palette.secondary.outline,
													fontStyle: "italic",
												})}
											>
												Note: Our ‘Founder Story’ PowerUp walks you through crafting and organizing the perfect story.
											</Typography>
										</Box>
									</Stack>
								)}
								{isSmallScreen && (
									<Stack flexDirection={"column"} gap={1}>
										<Box>
											<Typography
												variant="Body/16pxBold"
												sx={(theme) => ({
													color: theme.palette.secondary.korophKoal,
												})}
											>
												Founder Story Video
											</Typography>
										</Box>
										<Box>
											<Typography
												variant="body_large"
												sx={(theme) => ({
													color: theme.palette.secondary.outline,
												})}
											>
												Upload a video about your startup’s unique story and background.
											</Typography>
										</Box>
										<Box sx={{ pt: 2 }}>
											<Typography
												variant="body_large"
												sx={(theme) => ({
													color: theme.palette.secondary.outline,
													fontStyle: "italic",
												})}
											>
												Note: Our ‘Founder Story’ PowerUp walks you through crafting and organizing the perfect story.
											</Typography>
										</Box>
										<Box>
											<Stack flexDirection={"column"} gap={1}>
												{!founderStoryVideoUploadUrl && !companyId && (
													<Box>
														<AddVideo
															height={200}
															handelAddMedia={() => {
																setOpenFounderStoryVideoModal(true);
															}}
														/>
													</Box>
												)}
												{founderStoryVideoUploadUrl && (
													<Box sx={{ position: "relative" }}>
														<Box
															sx={(theme) => ({
																borderRadius: theme.shape.standard.borderRadius,
																boxShadow: "none",
																overflow: "hidden",
																pr: "2px",
																mt: 3,
															})}
														>
															<Box>
																<VideoPlayerComponent height="200px" videoUrl={founderStoryVideoUploadUrl} />
															</Box>
														</Box>
														<Box
															sx={{
																mr: 3,
																background:
																	"linear-gradient(0deg, rgba(106, 117, 117, 0.02), rgba(106, 117, 117, 0.02)), linear-gradient(0deg, rgba(245, 248, 250, 0.05), rgba(245, 248, 250, 0.05)), #FCFCFC",
																boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
																borderRadius: "4px",
																width: "max-content",
																position: "absolute",
																top: "48px",
																right: 0,
															}}
														>
															{!companyId && (
																<Button
																	variant="outlined"
																	startIcon={<VideocamRoundedIcon />}
																	onClick={() => {
																		setOpenFounderStoryVideoModal(true);
																	}}
																>
																	{founderStoryVideoUploadUrl ? "Edit Media" : "Add media"}
																</Button>
															)}
														</Box>
													</Box>
												)}
											</Stack>
										</Box>
									</Stack>
								)}
							</Grid>
						</Grid>

						{!isSmallScreen && (
							<Grid container flexDirection={"row"} sx={{ pt: 2 }} spacing={3}>
								<Grid item xs={12} md={6}>
									<Stack flexDirection={"column"} gap={1}>
										{!aboutUsVideoUploadUrl && !companyId && (
											<Box>
												<AddVideo
													height={200}
													handelAddMedia={() => {
														setOpenAboutUsVideoModal(true);
													}}
												/>
											</Box>
										)}
										{aboutUsVideoUploadUrl && (
											<Box sx={{ position: "relative" }}>
												<Box
													sx={(theme) => ({
														borderRadius: theme.shape.standard.borderRadius,
														boxShadow: "none",
														overflow: "hidden",
														mt: 3,
													})}
												>
													<Box>
														<VideoPlayerComponent height="200px" videoUrl={aboutUsVideoUploadUrl} />
													</Box>
												</Box>
												<Box
													sx={{
														mr: 3,
														background:
															"linear-gradient(0deg, rgba(106, 117, 117, 0.02), rgba(106, 117, 117, 0.02)), linear-gradient(0deg, rgba(245, 248, 250, 0.05), rgba(245, 248, 250, 0.05)), #FCFCFC",
														boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
														borderRadius: "4px",
														width: "max-content",
														position: "absolute",
														top: "48px",
														right: 0,
													}}
												>
													{!companyId && (
														<Button
															variant="outlined"
															startIcon={<VideocamRoundedIcon />}
															onClick={() => {
																setOpenAboutUsVideoModal(true);
															}}
														>
															{aboutUsVideoUploadUrl ? "Edit Media" : "Add media"}
														</Button>
													)}
												</Box>
											</Box>
										)}
									</Stack>
								</Grid>
								<Grid item xs={12} md={6}>
									<Stack flexDirection={"column"}>
										{!founderStoryVideoUploadUrl && !companyId && (
											<Box>
												<AddVideo
													height={200}
													handelAddMedia={() => {
														setOpenFounderStoryVideoModal(true);
													}}
												/>
											</Box>
										)}
										{founderStoryVideoUploadUrl && (
											<Box sx={{ position: "relative" }}>
												<Box
													sx={(theme) => ({
														borderRadius: theme.shape.standard.borderRadius,
														boxShadow: "none",
														overflow: "hidden",
														pr: "2px",
														mt: 3,
													})}
												>
													<Box>
														<VideoPlayerComponent height="200px" videoUrl={founderStoryVideoUploadUrl} />
													</Box>
												</Box>
												<Box
													sx={{
														mr: 3,
														background:
															"linear-gradient(0deg, rgba(106, 117, 117, 0.02), rgba(106, 117, 117, 0.02)), linear-gradient(0deg, rgba(245, 248, 250, 0.05), rgba(245, 248, 250, 0.05)), #FCFCFC",
														boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
														borderRadius: "4px",
														width: "max-content",
														position: "absolute",
														top: "48px",
														right: 0,
													}}
												>
													{!companyId && (
														<Button
															variant="outlined"
															startIcon={<VideocamRoundedIcon />}
															onClick={() => {
																setOpenFounderStoryVideoModal(true);
															}}
														>
															{founderStoryVideoUploadUrl ? "Edit Media" : "Add media"}
														</Button>
													)}
												</Box>
											</Box>
										)}
									</Stack>
								</Grid>
							</Grid>
						)}
					</Grid>
					{!companyId && (
						<Grid item xs={12} md={12}>
							<Divider sx={{ mt: 3, borderColor: "secondary.light" }} />
						</Grid>
					)}

					{!companyId && (
						<Grid item xs={12} md={12}>
							<Grid container flexDirection={"row"} justifyContent="space-between" alignItems="flex-start" sx={{ pt: 2 }}>
								<Grid item xs={12} md={6} sx={{ pb: 2 }}>
									<Typography
										variant="largeTitle"
										sx={(theme) => ({
											color: theme.palette.secondary.korophKoal,
										})}
									>
										Pitch Perfect Pitch Deck
									</Typography>
								</Grid>
								<Grid item xs={12} md={6}>
									<Stack
										flexDirection={"row"}
										alignItems="center"
										justifyContent="space-between"
										sx={{
											display: "flex",
											justifyContent: !isSmallScreen ? "end" : "space-between",
										}}
									>
										<Box>
											<Typography variant="label_large" sx={{ color: theme.palette.secondary.korophKoal }}>
												Allow Investors to View Pitch Deck
											</Typography>
										</Box>
										<Box
											sx={{
												cursor: "pointer",
												display: "flex",
												justifyContent: "flex-end",
											}}
										>
											<PitchDeckViewSwitch checked={props.pitchDeckToggle} setispitchdeckview={props.setispitchdeckview} />
										</Box>
									</Stack>
								</Grid>
							</Grid>
						</Grid>
					)}
					{!companyId && (
						<Grid item xs={12} md={12}>
							<Stack flexDirection={"column"}>
								<Box sx={{ pt: 4 }}>
									<Typography
										variant="Body/16pxBold"
										sx={(theme) => ({
											color: theme.palette.secondary.korophKoal,
										})}
									>
										1. Download the StartupOS Pitch Deck Template
									</Typography>
								</Box>
								<Box>
									{!isSmallScreen && (
										<Stack flexDirection={"row"} justifyContent="space-between">
											<Box sx={{ pt: 2, display: "flex", justifyContent: "end" }}>
												<Typography
													variant="body_large"
													sx={(theme) => ({
														color: theme.palette.text.lightOnsurface,
													})}
												>
													Try our pitch deck template to start building your perfect pitch.
												</Typography>
											</Box>
											<Box>
												<Button
													variant="outlined"
													startIcon={<FileDownloadOutlinedIcon />}
													onClick={() => {
														window.open(constants.PITCH_DECK_TEMPLATE_URL, "_blank");
													}}
												>
													Download template
												</Button>
											</Box>
										</Stack>
									)}
									{isSmallScreen && (
										<Stack flexDirection={"column"}>
											<Box sx={{ pt: 2 }}>
												<Typography
													variant="body_large"
													sx={(theme) => ({
														color: theme.palette.text.lightOnsurface,
													})}
												>
													Try our pitch deck template to start building your perfect pitch.
												</Typography>
											</Box>
											<Box sx={{ pt: 2, textAlign: "center" }}>
												<Button
													variant="outlined"
													startIcon={<FileDownloadOutlinedIcon />}
													onClick={() => {
														window.open(constants.PITCH_DECK_TEMPLATE_URL, "_blank");
													}}
												>
													Download template
												</Button>
											</Box>
										</Stack>
									)}
								</Box>
							</Stack>
						</Grid>
					)}
					{!companyId && (
						<Grid item xs={12} md={12}>
							<Stack flexDirection={"column"}>
								<Box sx={{ pt: 4 }}>
									<Typography
										variant="Body/16pxBold"
										sx={(theme) => ({
											color: theme.palette.secondary.korophKoal,
										})}
									>
										2. Upload your completed Pitch Deck
									</Typography>
								</Box>
								<Box>
									<Stack flexDirection={"row"} justifyContent="space-between">
										<Box sx={{ pt: 1, display: "flex", justifyContent: "end" }}>
											<Typography
												variant="body_large"
												sx={(theme) => ({
													color: theme.palette.text.lightOnsurface,
												})}
											>
												We recommend following our Pitch Perfect Template to create your deck.
											</Typography>
										</Box>
									</Stack>
								</Box>
							</Stack>
						</Grid>
					)}
					{!companyId && (
						<Grid item xs={12} md={12}>
							<Box
								sx={(theme) => ({
									width: 1,
									minHeight: "400px",
									background:
										"linear-gradient(0deg, #DADCE0, #DADCE0), linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), #DADCE0;",
									border: "1px solid transparent",
									borderRadius: 2,
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									position: "relative",
									mt: 3,
								})}
							>
								{!props?.profileArr?.pitchDeckLink ? (
									<Box
										component={"img"}
										src={PITCH_DECK_LOGO}
										sx={{
											height: 100,
											width: 100,
										}}
									></Box>
								) : (
									<div
										className="App"
										style={{
											display: "flex",
											justifyContent: "center",
											width: "100%",
										}}
									>
										{pitchType === "ppt" && (
											<iframe
												src={`https://view.officeapps.live.com/op/embed.aspx?src=${props?.profileArr?.pitchDeckLink}`}
												width="100%"
												height="400px"
												frameBorder="0"
												title="slides"
											></iframe>
										)}

										{pitchType === "pdf" && (
											<iframe src={props?.profileArr?.pitchDeckLink} width="100%" height="400px" frameBorder="0"></iframe>
										)}
									</div>
								)}

								<Box
									sx={{
										mr: 3,
										background:
											"linear-gradient(0deg, rgba(106, 117, 117, 0.02), rgba(106, 117, 117, 0.02)), linear-gradient(0deg, rgba(245, 248, 250, 0.05), rgba(245, 248, 250, 0.05)), #FCFCFC",
										boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
										borderRadius: "4px",
										width: "max-content",
										position: "absolute",
										top: pitchType === "pdf" ? "60px" : "24px",
										right: 0,
									}}
								>
									<Button variant="outlined" startIcon={<FileUploadIcon />} onClick={() => setOpenDeck(true)}>
										{props?.profileArr?.pitchDeckLink ? "Edit deck" : "Upload deck"}
									</Button>
								</Box>
							</Box>
						</Grid>
					)}

					{/*<Box>
            <Divider sx={{ py: 2 }} />
          </Box>*/}

					{/*<Grid item xs={12} md={12}>
            <Box
              sx={{
                color: `${theme.palette.text.primary}`,
              }}
            >
              <Typography
                variant="body_large"
                sx={theme => ({
                  color: theme.palette.secondary.korophKoal,
                })}
              >
                {props.profileArr?.about !== "<p><br></p>" &&
                props.profileArr?.about !== "" ? (
                  <ScriptEditor
                    initialValue={props?.profileArr?.about}
                    onChange={() => {}}
                    readOnly={false}
                    toolbar={false}
                    inlineToolbar={false}
                    inlineToolbarControls={RICH_TEXT_EDITOR_INLINE_CONTROLS}
                    controls={RICH_TEXT_EDITOR_MAIN_CONTROLS}
                  />
                ) : (
                  <Box>
                    <Typography>{"-"}</Typography>
                  </Box>
                )}
              </Typography>
            </Box>
            <Divider
              sx={{
                mt: 3,
                borderColor: "secondary.light",
                display: { xs: "block", md: "none" },
              }}
            />
          </Grid>*/}

					{/*{props.profileArr?.videoUrl !== null &&
            props.profileArr?.videoUrl !== "" && (
              <>
                <Grid item xs={12} md={12}>
                  <Box
                    sx={{
                      borderRadius: 1,
                      boxShadow: "none",
                      overflow: "hidden",
                      mt: 3,
                    }}
                  >
                    <VideoPlayerComponent
                      videoUrl={props.profileArr?.videoUrl}
                    />
                  </Box>
                </Grid>
                {showDeleteButton && !companyId && (
                  <Grid item xs={12} md={12}>
                    <Box sx={{ display: "grid", justifyContent: "end", pt: 1 }}>
                      <Button
                        variant="outlined"
                        startIcon={<DeleteIcon />}
                        sx={{ width: "max-content" }}
                        onClick={() => {
                          setDeleteConfirmPopup(true);
                        }}
                      >
                        Delete Video
                      </Button>
                    </Box>
                  </Grid>
                )}
              </>
            )}*/}

					{loading ? (
						<Box
							sx={{
								display: "flex",
								width: 1,
								justifyContent: "center",
								alignContent: "center",
								pt: 4,
							}}
						>
							<CircularProgress />
						</Box>
					) : (
						<></>
					)}
				</Grid>
			</Grid>

			<Grid item xs={12} md={4} sx={{ display: "flex", mt: 1 }}>
				<Divider orientation="vertical" flexItem sx={{ display: { xs: "none", md: "block" } }} />
				<Box
					sx={{
						display: "flex",
						width: "100%",
						height: "max-content",
						flexDirection: "column",
						alignContent: "center",
						//alignItems: "start",
						gap: { xs: 1, md: "0px" },
						pl: { xs: 1, md: 1 },
						pr: { md: 3 },
					}}
				>
					<Typography
						variant="largeTitle"
						sx={(theme) => ({
							color: theme.palette.secondary.korophKoal,
							pl: { xs: 0, md: 1 },
							my: 2,
						})}
					>
						Details
					</Typography>

					<Box sx={{ pl: { xs: 0, md: 1 }, pb: 1 }}>
						<Typography variant="label_xlarge" sx={(theme) => ({ color: theme.palette.text.atomic })}>
							{"LinkedIn Startup Profile"}
						</Typography>
					</Box>
					<Box
						sx={{
							color: `${theme.palette.text.slateGray}`,
							pl: { xs: 0, md: 1 },
						}}
					>
						{/*<Typography
              variant="body_large"
              sx={theme => ({ color: theme.palette.text.lightOnsurface })}
            >
              {props.profileArr?.linkedInUrl
                ? props.profileArr?.linkedInUrl
                : "--"}
            </Typography>*/}
						{props?.profileArr?.linkedInUrl ? (
							<Link
								variant="body_large"
								target="_blank"
								onClick={() => window.open(props?.profileArr?.linkedInUrl)}
								rel="noopener"
								sx={{
									color: `${theme.palette.text.link}`,
									cursor: "pointer",
									display: "flex",
									maxWidth: 1,
									overflow: "hidden",
									wordBreak: "break-word",
								}}
							>
								{props?.profileArr?.linkedInUrl}
							</Link>
						) : (
							<Typography variant="body_large" sx={(theme) => ({ color: theme.palette.text.lightOnsurface })}>
								{"--"}
							</Typography>
						)}
					</Box>
					{!companyId && props?.profileArr?.linkedInUrl && (
						<Box sx={{ my: 1, px: { xs: 0, md: 1 } }}>
							<Button variant="outlined" onClick={() => props.setHarmonicOpen(true)}>
								Copy profile from Linkedin
							</Button>
						</Box>
					)}
					{!companyId && props?.profileArr?.linkedInUrl === null && (
						<Box sx={{ pt: 2, pl: { xs: 0, md: 1 } }}>
							<CompleteYourProfile
								title="Complete your profile faster"
								sub_title="Providing your personal LinkedIn profile link will autofill key information here needed to complete your profile."
							/>
						</Box>
					)}

					<Box
						sx={{
							lineHeight: 1,
							p: { xs: 0, md: 1 },
							color: `${theme.palette.text.primary}`,
							mt: 1,
						}}
					>
						<Typography variant="label_xlarge" sx={(theme) => ({ color: theme.palette.secondary.korophKoal })}>
							Date Founded
						</Typography>
					</Box>
					<Box
						sx={{
							color: `${theme.palette.text.slateGray}`,
							pl: { xs: 0, md: 1 },
						}}
					>
						<Typography variant="body_large" sx={(theme) => ({ color: theme.palette.text.lightOnsurface })}>
							{props.profileArr?.foundedDate ? `${getYear(props.profileArr?.foundedDate ? props.profileArr?.foundedDate : "--")}` : "--"}
						</Typography>
					</Box>

					<Box
						sx={{
							lineHeight: 1,
							p: { xs: 0, md: 1 },
							mt: 1,
							color: `${theme.palette.text.primary}`,
						}}
					>
						<Typography variant="label_xlarge" sx={(theme) => ({ color: theme.palette.secondary.korophKoal })}>
							Number of Co-Founders
						</Typography>
					</Box>
					<Box
						sx={{
							color: `${theme.palette.text.slateGray}`,
							pl: { xs: 0, md: 1 },
						}}
					>
						<Typography variant="body_large" sx={(theme) => ({ color: theme.palette.text.lightOnsurface })}>
							{props.profileArr?.noOfCoFounders ? props.profileArr?.noOfCoFounders : "0"}
						</Typography>
					</Box>

					<Box
						sx={{
							lineHeight: 1,
							p: { xs: 0, md: 1 },
							mt: 1,
							color: `${theme.palette.text.primary}`,
						}}
					>
						<Typography variant="label_xlarge" sx={(theme) => ({ color: theme.palette.secondary.korophKoal })}>
							Startup Website
						</Typography>
					</Box>
					{props.profileArr?.websiteLink ? (
						<Link
							variant="body_large"
							target="_blank"
							onClick={() => window.open(website)}
							rel="noopener"
							sx={{
								color: `${theme.palette.text.link}`,
								cursor: "pointer",
								pl: { xs: 0, md: 1 },
								display: "flex",
								maxWidth: 1,
								overflow: "hidden",
								wordBreak: "break-word",
							}}
						>
							{props?.profileArr?.websiteLink}
						</Link>
					) : (
						<Typography
							variant="body_large"
							sx={(theme) => ({
								color: theme.palette.text.lightOnsurface,
								pl: { xs: 0, md: 1 },
							})}
						>
							{"--"}
						</Typography>
					)}

					<Box
						sx={{
							lineHeight: 1,
							p: { xs: 0, md: 1 },
							mt: 1,
							color: `${theme.palette.text.primary}`,
						}}
					>
						<Typography variant="label_xlarge" sx={(theme) => ({ color: theme.palette.secondary.korophKoal })}>
							Phone
						</Typography>
					</Box>
					<Box
						sx={{
							color: `${theme.palette.text.slateGray}`,
							pl: { xs: 0, md: 1 },
						}}
					>
						<Typography variant="body_large" sx={(theme) => ({ color: theme.palette.text.lightOnsurface })}>
							{/*{props.profileArr?.phone
                ? props.profileArr?.phone
                : "--"}*/}
							{props.profileArr?.phone || "--"}
						</Typography>
					</Box>

					{/*<Box
            sx={{
              lineHeight: 1,
              p: { xs: 0, md: 1 },
              mt: 1,
              color: `${theme.palette.text.primary}`,
            }}
          >
            <Typography
              variant="title_medium"
              sx={theme => ({ color: theme.palette.secondary.korophKoal })}
            >
              Is your startup incorporated?
            </Typography>
          </Box>
          <Box
            sx={{
              color: `${theme.palette.text.slateGray}`,
              pl: { xs: 0, md: 1 },
            }}
          >
            <Typography
              variant="body_large"
              sx={theme => ({ color: theme.palette.text.lightOnsurface })}
            >
              {props.profileArr?.incorporatedStatus
                ? props.profileArr?.incorporatedStatus
                : "-"}
            </Typography>
          </Box>*/}

					<Box
						sx={{
							lineHeight: 1,
							p: { xs: 0, md: 1 },
							mt: 1,
							color: `${theme.palette.text.primary}`,
						}}
					>
						<Typography variant="label_xlarge" sx={(theme) => ({ color: theme.palette.secondary.korophKoal })}>
							Product Offering
						</Typography>
					</Box>
					<Box
						sx={{
							color: `${theme.palette.text.slateGray}`,
							pl: { xs: 0, md: 1 },
						}}
					>
						<Typography variant="body_large" sx={(theme) => ({ color: theme.palette.text.lightOnsurface })}>
							{props.profileArr?.productOffering ? props.profileArr?.productOffering : "--"}
						</Typography>
					</Box>

					{/*<Box
            sx={{
              lineHeight: 1,
              p: { xs: 0, md: 1 },
              mt: 1,
              color: `${theme.palette.text.primary}`,
            }}
          >
            <Typography
              variant="title_medium"
              sx={theme => ({ color: theme.palette.secondary.korophKoal })}
            >
              Industry Focus
            </Typography>
          </Box>*/}
					{/*<Box
            sx={{
              color: `${theme.palette.text.slateGray}`,
              pl: { xs: 0, md: 1 },
            }}
          >
            <Typography
              variant="body_large"
              sx={theme => ({ color: theme.palette.text.lightOnsurface })}
            >
              {props.profileArr?.targetMarket
                ? props.profileArr?.targetMarket
                : "-"}
            </Typography>
          </Box>*/}

					<Box
						sx={{
							lineHeight: 1,
							p: { xs: 0, md: 1 },
							mt: 1,
							color: `${theme.palette.text.primary}`,
						}}
					>
						<Typography variant="label_xlarge" sx={(theme) => ({ color: theme.palette.secondary.korophKoal })}>
							Funding Type
						</Typography>
					</Box>
					<Box
						sx={{
							color: `${theme.palette.text.slateGray}`,
							pl: { xs: 0, md: 1 },
						}}
					>
						<Typography variant="body_large" sx={(theme) => ({ color: theme.palette.text.lightOnsurface })}>
							{props?.profileArr?.fundingType ? renderFundingType(props.profileArr) : "--"}
						</Typography>
					</Box>
					{/*<Box
            sx={{
              lineHeight: 1,
              p: { xs: 0, md: 1 },
              mt: 1,
              color: `${theme.palette.text.primary}`,
            }}
          >
            <Typography
              variant="title_medium"
              sx={theme => ({ color: theme.palette.secondary.korophKoal })}
            >
              Area of expertise
            </Typography>
          </Box>
          <Box
            sx={{
              color: `${theme.palette.text.slateGray}`,
              pl: { xs: 0, md: 1 },
            }}
          >
            <Typography
              variant="body_large"
              sx={theme => ({ color: theme.palette.text.lightOnsurface })}
            >
              {props?.profileArr?.expertise
                ? renderExpertiseType(props.profileArr)
                : "-"}
            </Typography>
          </Box>*/}
					<Box
						sx={{
							lineHeight: 1,
							p: { xs: 0, md: 1 },
							mt: 1,
							color: `${theme.palette.text.primary}`,
						}}
					>
						<Typography variant="title_medium" sx={(theme) => ({ color: theme.palette.secondary.korophKoal })}>
							Capital Raised
						</Typography>
					</Box>
					<Box
						sx={{
							color: `${theme.palette.text.slateGray}`,
							pl: { xs: 0, md: 1 },
						}}
					>
						<Typography variant="body_large" sx={(theme) => ({ color: theme.palette.text.lightOnsurface })}>
							{props.profileArr?.capitalRaised ? `$ ${props.profileArr?.capitalRaised}` : "--"}
						</Typography>
					</Box>
					<Box
						sx={{
							lineHeight: 1,
							p: { xs: 0, md: 1 },
							mt: 1,
							color: `${theme.palette.text.primary}`,
						}}
					>
						<Typography variant="label_xlarge" sx={(theme) => ({ color: theme.palette.secondary.korophKoal })}>
							Startup incorporated?
						</Typography>
					</Box>
					<Box
						sx={{
							color: `${theme.palette.text.slateGray}`,
							pl: { xs: 0, md: 1 },
						}}
					>
						<Typography variant="body_large" sx={(theme) => ({ color: theme.palette.text.lightOnsurface })}>
							{props.profileArr?.incorporatedStatus ? props.profileArr?.incorporatedStatus : "--"}
						</Typography>
					</Box>

					{!companyId && (
						<Box sx={{ mt: 2 }}>
							<TeamContainer />
						</Box>
					)}
				</Box>
			</Grid>
			<MessageHandlerModal
				isOpen={deleteConfirmPopup}
				onCancel={() => setDeleteConfirmPopup(false)}
				onOk={() => {
					deleteVideoHandler();
				}}
				okText="Delete"
				cancelText={"No thanks"}
				heading="Delete Video"
				text={"Are you sure you want to delete the video?"}
				messageType="primary"
			/>

			<UploadVideoModal
				open={openAboutUsVideoModal}
				photo={aboutUsVideoUploadUrl}
				onClose={() => setOpenAboutUsVideoModal(false)}
				setPhoto={setAboutUsVideoUploadUrl}
				title="Elevator Pitch Video"
				selectFileText="Select a file to upload Elevator Pitch video"
				dimensionText="Accepted file types .mp4, .mov, .3gp, .webm, .ogg (Max 2GB)"
				aspectRatio={1 / 1}
				imageType="COMPANY_ABOUTUS_VIDEO"
				fileType={{
					"video/3gpp": [".3gp"],
					"video/mp4": [".mp4"],
					"video/mov": [".mov"],
					"video/webm": [".webm"],
					"video/ogg": [".ogg"],
				}}
				handleUpdateAssetsUrl={props.handleUpdateAssetsUrl}
				source="ABOUT_US"
			/>
			<UploadVideoModal
				open={openFounderStoryVideoModal}
				photo={founderStoryVideoUploadUrl}
				onClose={() => setOpenFounderStoryVideoModal(false)}
				setPhoto={setFounderStoryVideoUploadUrl}
				title="Founder Story Video"
				selectFileText="Select a file to upload Founder Story video"
				dimensionText="Accepted file types .mp4, .mov, .3gp, .webm, .ogg (Max 2GB)"
				aspectRatio={1 / 1}
				imageType="COMPANY_FOUNDERSTORY_VIDEO"
				fileType={{
					"video/3gpp": [".3gp"],
					"video/mp4": [".mp4"],
					"video/mov": [".mov"],
					"video/webm": [".webm"],
					"video/ogg": [".ogg"],
				}}
				handleUpdateAssetsUrl={props.handleUpdateAssetsUrl}
				source="FOUNDER_STORY"
			/>

			<UploadFileModal
				open={openDeck}
				onClose={() => setOpenDeck(false)}
				file={pitchDeck}
				setFile={setPitchDeck}
				title="Pitch Perfect Pitch Deck"
				selectFileText="Select a file to upload your deck"
				dimensionText="Accepted file types .pdf, .ppt, .pptx(Max 500MB)"
				aspectRatio={4 / 1}
				imageType="PITCH_DECK"
				fileType={{
					"application/vnd.openxmlformats-officedocument.presentationml.presentation": [],
					"application/vnd.ms-powerpoint": [],
					"application/pdf": [],
				}}
				circularCropper={false}
				updatePayload={props.handleUploadPitchDeck}
			/>
		</Grid>
	);
}
