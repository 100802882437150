import React, { createContext, useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Button, Stack } from "@mui/material";
import moment from "moment/moment";
import { useLazyQuery } from "@apollo/client";
import services from "../../../services";
import { setUserProfileData } from "../../../modules/actions/userProfileActions";
import { toastContainer } from "../../../utils/toast";
import messages from "../../../constants/messages";
import CopyFromLinkedinConfirmation from "../../UpdatedMentorProfile/MyDetailsSection/CopyFromLinkedinConfirmation";
import { GET_COMPANY_DATA_QUERY } from "../../Profile/Stores/Function/common";
import StartupDetailsSection from "./StartupDetailsSection";
import FundingSection from "./FundingSection";
import FinancialSection from "./FinancialSection";
import { getListOfCategoryApiCall } from "../../../containers/Investor/Dashboard/Functions/common";
import StartupProfileCoPilot from "../../../containers/StartupProfileEdit/StartupProfileCoPilot";
import { handleMixPanel } from "../../../utils/mixPanelEventHandling";

import EnterpriseReadySection from "./EnterpriseReadySection";

const MyDetailsTabContext = createContext();

const StartupDetailsTab = ({ handleClose }) => {
	const dispatch = useDispatch();

	const userDetails = useSelector((state) => state?.userProfileData?.userData);
	const [payLoad, setPayLoad] = useState({ ...userDetails });
	const [isLoading, setIsLoading] = useState(false);
	const [isLoadingLinkedInData, setLoadingLinkedInData] = useState(false);
	const [openHarmonic, setOpenHarmonic] = useState(false);
	const userId = userDetails?.id;
	const [industryList, setIndustryList] = useState([]);
	const [productOfferingList, setProductOfferingList] = useState([]);
	const [businessTypeList, setBusinessTypeList] = useState([]);
	const [revenueTypeList, setRevenueTypeList] = useState([]);

	const [loadUserData, { loading, error, data }] = useLazyQuery(GET_COMPANY_DATA_QUERY, {
		errorPolicy: "all",
		fetchPolicy: "no-cache",
	});

	const [errors, setErrors] = useState({
		firstNameError: "",
		lastNameError: "",
	});

	useEffect(() => {
		if (loading) {
			dispatch(
				setUserProfileData({
					loading: loading,
				})
			);
		} else {
			if (!error && data) {
				dispatch(
					setUserProfileData({
						loading: loading,
						userData: data?.companyById,

						// userData: { ...data?.companyById, stateOfProduct: getKeyByValue(CURRENT_PRODUCT_STATE_DICT, data?.companyById?.stateOfProduct) },
					})
				);
				setPayLoad(data?.companyById);
			} else {
				console.log(error);
			}
		}
	}, [loading]);

	useEffect(() => {
		getCategoryList("industry");
		getCategoryList("product-offering");
		getCategoryList("business-type");
		getCategoryList("revenue-model-type");
	}, []);

	const getCategoryList = (category) => {
		getListOfCategoryApiCall(category)
			.then((res) => {
				const responseData = res.data;
				let categoryArray = [];
				responseData.map((categoryItem) => {
					categoryArray.push(categoryItem?.lovValue);
				});
				if (category === "industry") {
					setIndustryList(categoryArray);
				} else if (category === "product-offering") {
					setProductOfferingList(categoryArray);
				} else if (category === "business-type") {
					setBusinessTypeList(categoryArray);
				} else if (category === "revenue-model-type") {
					setRevenueTypeList(categoryArray);
				}
			})
			.catch((e) => {
				console.log("Error", e);
			});
	};
	const isValidForm = () => {
		return !!payLoad?.companyName;
	};

	const handleAISave = (handleForm = true, data) => {
		let payloadData = data ? data : payLoad;
		const updatedTagList =
			payloadData?.companyTags?.length > 0
				? payloadData?.companyTags[0]?.scoreTag
					? payloadData?.companyTags?.map((item) => {
							return Number(item?.scoreTag?.id);
					  })
					: payloadData?.companyTags
				: [];
		payloadData = {
			...payloadData,
			industry:
				payloadData?.industry?.length > 0
					? typeof payloadData?.industry === "string"
						? payloadData?.industry
						: JSON.stringify(payloadData?.industry)
					: "",
			linkedInUrl: getLinkedInUrl(payloadData.linkedInUrl),
			companyTags: updatedTagList,
			noOfCoFounders: payloadData?.noOfCoFounders ? payloadData?.noOfCoFounders : "0",
		};

		return services
			.updateCompanyProfile(payloadData)
			.then((response) => {
				if (response.data.message === "Success") {
					dispatch(
						setUserProfileData({
							userData: response?.data?.data,
						})
					);
					return response?.data;
				}
			})
			.catch((e) => {
				console.log("Submit Error", e);
				toastContainer(messages.API_SUBMIT_ERROR, "error");
			});
	};
	const handleUpdateUser = async (eventType, aiData) => {
		if (eventType && eventType == "ai_assist") {
			let resp = await handleAISave("", aiData ? aiData : payLoad);
			if (payLoad["incorporatedStatus"] !== resp?.data?.incorporatedStatus) {
				const companyName = resp?.data?.companyName || "";
				handleMixPanel(`${companyName} incorporated status updated`, payLoad["incorporatedStatus"]);
			}
			if (aiData && resp?.data) {
				handleClose();
			}

			return resp;
		} else {
			setIsLoading(true);
			let payloadData = payLoad ? payLoad : userDetails;
			const updatedTagList =
				payloadData?.companyTags?.length > 0
					? payloadData?.companyTags?.map((item) => {
							return Number(item?.scoreTag?.id);
					  })
					: [];
			services
				.updateCompanyProfile({
					...payloadData,
					phone: payLoad.phone,
					linkedInUrl: getLinkedInUrl(payloadData.linkedInUrl),
					// companyTags: updatedTagList,
				})
				.then((response) => {
					setIsLoading(false);
					if (response.data.message === "Success") {
						// toastContainer(messages.CHANGES_SAVE_SUCCESS, "success");

						dispatch(
							setUserProfileData({
								userData: response?.data?.data,
							})
						);
					}
				})
				.catch((e) => {
					console.log(e);
					setIsLoading(false);
				});
		}
	};

	const getLinkedInUrl = (url) => {
		if (url) {
			if (url.match(/^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(company\/)/gm)) {
				let cleanedLinkedInUrl = url.replace(
					/^(https:\/\/www\.linkedin\.com\/company\/.*?\/)(.*)https:\/\/www\.linkedin\.com\/company\/.*?\/(.*)$/,
					"$1$2"
				);

				return cleanedLinkedInUrl;
			} else {
				return "https://www.linkedin.com/company/" + url;
			}
		} else {
			return null;
		}
	};
	const updateWithHarmonicData = async (harmonicData) => {
		let data = { ...payLoad };

		if (harmonicData?.name) {
			data = {
				...data,
				companyName: harmonicData?.name,
			};
		}
		if (harmonicData?.website?.url) {
			data = {
				...data,
				websiteLink: harmonicData?.website?.url.replace(/^https?:\/\//, ""),
			};
		}
		if (harmonicData?.logo_url) {
			data = {
				...data,
				logoUrl: harmonicData?.logo_url,
			};
		}

		if (harmonicData?.description) {
			data = {
				...data,
				about: harmonicData?.description,
			};
		}

		if (harmonicData?.founding_date?.date) {
			data = {
				...data,
				foundedDate: harmonicData?.founding_date?.date,
				// foundedYear: moment(harmonicData?.founding_date?.date).format("Y"),
			};
		}
		if (harmonicData?.location?.city) {
			data = {
				...data,
				city: harmonicData?.location?.city,
			};
		}
		if (harmonicData?.location?.state) {
			data = {
				...data,
				state: harmonicData?.location?.state,
			};
		}
		if (harmonicData?.location?.zip) {
			data = {
				...data,
				zipcode: harmonicData?.location?.zip,
			};
		}
		if (harmonicData?.contact?.phone_numbers.length > 0) {
			data = {
				...data,
				phone: harmonicData?.contact?.phone_numbers[0],
			};
		}

		setPayLoad(data);
		dispatch(
			setUserProfileData({
				userData: data,
			})
		);
		const updatedTagList =
			data?.companyTags?.length > 0
				? data?.companyTags?.map((item) => {
						return Number(item?.scoreTag?.id);
				  })
				: [];
		await services
			.updateCompanyProfile({
				...data,
				linkedInUrl: getLinkedInUrl(data.linkedInUrl),
				companyTags: updatedTagList,
			})
			.then((response) => {
				if (response.data.message === "Success") {
					loadUserData({
						variables: { id: null },
					});
				}
			})
			.catch((e) => {
				console.log(e);
			});
	};

	const handleGetHarmonicData = async () => {
		let payloadData = payLoad ? payLoad : userDetails;

		if (payloadData?.linkedInUrl) {
			setLoadingLinkedInData(true);
			services
				.getHarmonicsController({
					userId: payloadData?.id,
					linkedinUrl: getLinkedInUrl(payloadData?.linkedInUrl),
				})
				.then(async (res) => {
					if (res?.data?.data.harmonicData) {
						await updateWithHarmonicData(JSON.parse(res?.data?.data.harmonicData));
						toastContainer("Linkedin data updated successfully.", "Suceess");
						setLoadingLinkedInData(false);
						handleCloseConfirmation();
					} else if (!res?.data?.data.harmonicData) {
						toastContainer(messages.LINKEDIN_ERROR);
						setLoadingLinkedInData(false);
						handleCloseConfirmation();
					}
				})
				.catch((err) => {
					console.log(err);
					if (err?.response?.data?.code === 429) {
						toastContainer("Too many requests.", "error");
					}
					setLoadingLinkedInData(false);
					handleCloseConfirmation();
				});
		}
	};

	const handleCloseConfirmation = () => {
		setOpenHarmonic(false);
	};

	return (
		<MyDetailsTabContext.Provider
			value={{
				payLoad,
				setPayLoad,
				handleUpdateUser,
				setOpenHarmonic,
				errors,
				setErrors,
				industryList,
				productOfferingList,
				businessTypeList,
				revenueTypeList,
			}}
		>
			<Stack direction={"column"} spacing={7.5}>
				<StartupDetailsSection context={MyDetailsTabContext} />
				<FinancialSection context={MyDetailsTabContext} />

				<FundingSection context={MyDetailsTabContext} />
				<EnterpriseReadySection context={MyDetailsTabContext} />
				<Box
					sx={{
						display: "flex",
						gap: 2,
						justifyContent: "end",
						alignItems: "center",
					}}
				>
					<Button
						onClick={handleClose}
						variant="noOutlineNew"
						sx={{
							height: "40px",
							m: 0,
							width: "fit-content",
							fontSize: "18px",
							lineHeight: "28px",
							fontFamily: "PoppinsSemiBold",
						}}
					>
						Cancel
					</Button>
					{/* <LoadingButton
						loaderColor={"#7B61FF"}
						loadingStyles={{
							border: "4px solid",
							backgroundColor: "white",
							borderColor: "#7B61FF",
							height: "60px",
							px: 4,
						}}
						loading={isLoading}
						onClick={!isLoading ? handleUpdateUser : () => {}}
						variant="PurpleRounded"
						disabled={!isValidForm()}
					>
						Save
					</LoadingButton> */}
					<StartupProfileCoPilot handleSave={handleUpdateUser} saveLoading={isLoading} saveDisabled={!isValidForm()} handleCloseAI={handleClose} />
				</Box>
			</Stack>
			<CopyFromLinkedinConfirmation
				open={openHarmonic}
				handleCloseConfirmation={handleCloseConfirmation}
				handleGetHarmonicData={handleGetHarmonicData}
				isLoading={isLoadingLinkedInData}
			/>
		</MyDetailsTabContext.Provider>
	);
};

export default StartupDetailsTab;
