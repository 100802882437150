import React from "react";
import { CircularProgress, Button } from "@mui/material";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import FileDownloadDoneOutlinedIcon from "@mui/icons-material/FileDownloadDoneOutlined";
import theme from "../../constants/Theme";

const FileUploadButton = ({
  imgLoading,
  browseFlag,
  type,
  handleFileChange,
}) => {
  let FileType = {
    img: ".png, .jpg, .jpeg",
  };
  return (
    <Button
      variant="outlined"
      startIcon={
        !imgLoading &&
        (browseFlag ? (
          <FileDownloadDoneOutlinedIcon />
        ) : (
          <FileUploadOutlinedIcon />
        ))
      }
      sx={{
        width: "max-content",
        mb: 2,
      }}
      component="label"
    >
      {imgLoading ? (
        <CircularProgress
          sx={{
            display: "flex",
            justifyContent: "center",
            maxWidth: "30px",
            px: 3,
          }}
        />
      ) : browseFlag ? (
        "Uploaded"
      ) : (
        "Upload"
      )}
      <input
        type="file"
        accept={FileType[type]}
        hidden
        multiple={false}
        onChange={handleFileChange(type)}
      />
    </Button>
  );
};

export default FileUploadButton;
