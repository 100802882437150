import React, { memo, useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Box, Typography, Chip } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import TreeView from "@mui/lab/TreeView";
import TreeItem from "@mui/lab/TreeItem";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { UPDATED_DELETE_ICON } from "../../../constants";
import { setInvestorDashboardData } from "../../../modules/actions";
import { deleteSavedFilterApiCall } from "../../../containers/Investor/Dashboard/Functions/common";
import CommonSlideDialog from "../../../common/CommonSlideDialog";
import LoadingButton from "../../../common/Loadingbutton";
import { InvestorDashboardContext } from "../../../containers/Investor/Dashboard";

const SavedSearchesDetailsComponent = ({ expanded, setExpanded }) => {
	let savedSearchesData = useSelector((state) => state?.investorDashboard?.savedSearchesData);

	const { setSavedFilterPopoverOpen, setIsSavedSearchApplied } = useContext(InvestorDashboardContext);

	const [openDeleteDialogue, setOpenDeleteDialogue] = useState(false);
	const [deleteLoader, setDeleteLoader] = useState(false);
	const [currentSavedFilter, setCurrentSavedFilter] = useState({});

	const dispatch = useDispatch();

	const handleEditSavedFilter = (data) => {
		dispatch(
			setInvestorDashboardData({
				savedFilterEditData: {
					id: data?.id,
					name: data?.name,
					userId: data?.userId,
					isEdit: true,
					isEmailNotificationEnabled: data?.isEmailNotificationEnabled || false,
				},
				filteredValue: data?.savedFilterArray,
				FilterDrawerOpen: true,
				SavedSearchesOpen: false,
			})
		);
		setSavedFilterPopoverOpen(false);
	};

	const handleDeleteSavedFilter = () => {
		let savedDataId = currentSavedFilter?.id;
		setDeleteLoader(true);
		deleteSavedFilterApiCall(savedDataId)
			.then((result) => {
				let filteredArray = savedSearchesData.filter((item) => item.id !== savedDataId);
				dispatch(
					setInvestorDashboardData({
						savedSearchesData: filteredArray,
					})
				);
				setOpenDeleteDialogue(false);
				setDeleteLoader(false);
			})
			.catch((error) => {
				console.log(error);
			});
		setSavedFilterPopoverOpen(false);
	};
	const handleToggle = (event, nodeIds) => {
		setExpanded(nodeIds);
	};

	return (
		<>
			<Box>
				{savedSearchesData.length > 0 ? (
					savedSearchesData.map((data, index) => {
						return (
							<TreeView
								aria-label="controlled"
								onNodeToggle={handleToggle}
								expanded={expanded}
								defaultCollapseIcon={
									<KeyboardArrowDownOutlinedIcon
										sx={{
											fontSize: "large",
											border: "2px solid",
											borderRadius: "50%",
										}}
									/>
								}
								defaultExpandIcon={
									<KeyboardArrowRightOutlinedIcon
										sx={{
											fontSize: "large",
											border: "2px solid",
											borderRadius: "50%",
										}}
									/>
								}
								sx={{ mt: 2 }}
							>
								<TreeItem
									nodeId={`${index}`}
									sx={{
										"& .MuiTreeItem-content.Mui-selected": {
											background: (theme) => theme.palette.secondary.white,
											"&:hover": {
												background: (theme) => theme.palette.secondary.white,
											},
											"&:active": {
												background: (theme) => theme.palette.secondary.white,
											},
											"&:focused": {
												background: (theme) => theme.palette.secondary.white,
											},
											"&:selected": {
												background: (theme) => theme.palette.secondary.white,
											},
										},
										"& .MuiTreeItem-content.Mui-selected.Mui-focused": {
											background: (theme) => theme.palette.secondary.white,
											"&:hover": {
												background: (theme) => theme.palette.secondary.white,
											},
											"&:active": {
												background: (theme) => theme.palette.secondary.white,
											},
											"&:focused": {
												background: (theme) => theme.palette.secondary.white,
											},
											"&:selected": {
												background: (theme) => theme.palette.secondary.white,
											},
										},
									}}
									label={
										<Box>
											<Box
												sx={{
													display: "flex",
													alignItems: "center",
												}}
											>
												<Box
													sx={{
														display: "flex",
														alignItems: "center",
													}}
												>
													<Box
														onClick={() => {
															setIsSavedSearchApplied(true);

															dispatch(
																setInvestorDashboardData({
																	SavedSearchesOpen: false,
																	filteredValue: data?.savedFilterArray,
																})
															);
														}}
													>
														<Typography
															sx={{
																color: (theme) => theme.palette.secondary.black,
																width: "fit-content",
																ml: 1,
															}}
															variant="card_text"
														>
															{data.name}
														</Typography>
													</Box>
												</Box>
												<Box
													sx={{
														display: "flex",
														alignItems: "center",
														ml: "auto",
														mr: 4,
														gap: 1,
													}}
												>
													<EditOutlinedIcon
														sx={{ fontSize: "22px", color: (theme) => theme.palette.text.investorDashGrayLabelMuted, ml: 1 }}
														onClick={() => handleEditSavedFilter(data)}
													/>
													<Box
														component={"img"}
														src={UPDATED_DELETE_ICON}
														alt="delete"
														onClick={() => {
															setCurrentSavedFilter(data);
															setOpenDeleteDialogue(true);
														}}
													/>
												</Box>
											</Box>
											<Box
												sx={{
													display: "flex",
													alignItems: "center",
												}}
												onClick={() => {
													setIsSavedSearchApplied(true);

													dispatch(
														setInvestorDashboardData({
															SavedSearchesOpen: false,
															filteredValue: data?.savedFilterArray,
														})
													);
												}}
											>
												<Box>
													<Typography
														sx={{
															color: (theme) => theme.palette.secondary.black,
															width: "fit-content",
															ml: 1,
														}}
														variant="card_text"
													>
														View Results
													</Typography>
												</Box>
												<Box display={"flex"} alignItems="center">
													<Box>
														<Typography
															sx={{
																color: (theme) => theme.palette.primary.primaryPurple,
																width: "fit-content",
																ml: 1,
															}}
															variant="card_text"
														>
															[{data?.savedFilterArray?.length ? data?.savedFilterArray?.length : 0}]
														</Typography>
													</Box>
													<ArrowForwardOutlinedIcon
														sx={{ fontSize: "15px", ml: 1, color: (theme) => theme.palette.primary.primaryPurple }}
													/>
												</Box>
											</Box>
										</Box>
									}
								>
									<Box
										sx={{
											ml: 3,
										}}
									>
										{data?.savedFilterArray?.map((item) => {
											return <Chip label={`${item?.checkboxValue}`} sx={{ mr: 1, mt: 1 }} />;
										})}
									</Box>
								</TreeItem>
							</TreeView>
						);
					})
				) : (
					<Box
						sx={{
							display: "flex",
							justifyContent: "center",

							width: "inherit",
						}}
					>
						<Typography variant="sub_title_portfolio">No saved searches</Typography>
					</Box>
				)}
			</Box>
			<CommonSlideDialog
				disableBackDropClick={true}
				title={"Delete Filter"}
				titleVariant={"card_name"}
				isOpen={openDeleteDialogue}
				onClose={() => {
					setOpenDeleteDialogue(false);
				}}
				maxWidth={"xs"}
				fullWidth
				transitionDuration={600}
				PaperProps={{
					sx: () => ({
						p: 3,
						ml: "auto",
						alignSelf: "end",
						borderRadius: "30px",
					}),
				}}
				dialogAction={
					<Box display="flex" mt="12px" pr={2}>
						<Button
							variant="noOutlineNew"
							onClick={() => {
								setOpenDeleteDialogue(false);
							}}
						>
							Cancel
						</Button>
						<LoadingButton
							variant="containedNewStyle"
							onClick={() => {
								handleDeleteSavedFilter();
							}}
							loading={deleteLoader}
							loadingStyles={{ px: 3 }}
						>
							Delete
						</LoadingButton>
					</Box>
				}
			>
				<Box mt={2}>
					<Typography variant="sub_title_portfolio">Are you sure you want to delete saved filter ?</Typography>
				</Box>
			</CommonSlideDialog>
		</>
	);
};

export default memo(SavedSearchesDetailsComponent);
