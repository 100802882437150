import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const ListItemComp = styled(Box, {
	shouldForwardProp: (prop) => prop !== "selected",
})(({ theme, selected }) => ({
	cursor: "Pointer",
	display: "block",
	padding: "12px 20px 12px 20px",
	background: selected ? "#000000" : "#F2F4F7",
	borderRadius: "100px",
}));
