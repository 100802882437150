import { ChevronRight, Info } from "@mui/icons-material";
import { Box, Typography, Button, Card } from "@mui/material";
import React from "react";

export const EmptyItem = ({ ...props }) => {
  return (
    <Card
      sx={theme => ({
        display: "flex",
        flex: 1,
        alignItems: "center",
        py: 2,
        px: 1,
        gap: 2,
      })}
    >
      <Box sx={theme => ({})}>
        <Info color={"primary"} />
      </Box>
      <Box sx={{ flex: 1 }}>
        <Typography variant="body_large">{props.message}</Typography>
      </Box>
    </Card>
  );
};
