import styled from "@emotion/styled";
import { Switch, Box } from "@mui/material";
import { sosPrimary } from "../../../../constants/Theme/colors";
import theme from "../../../../constants/Theme";

export const EventToggleSwitch = styled(props => (
  <Switch
    focusVisibleClassName=".Mui-focusVisible"
    checked={props.checked}
    onChange={() => props.setShowMeeting(!props.checked)}
    disableRipple
    {...props}
  />
))(({ theme }) => ({
  width: 42,
  marginRight: "8px",
  marginLeft: "10px",
  height: 26,
  padding: 0,
  background: "none",
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 6,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      margin: 4,
      transform: "translateX(16px)",
      color: theme.palette.secondary.white,
      "& + .MuiSwitch-track": {
        backgroundColor: sosPrimary[400],
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
      "& .MuiSwitch-thumb": {
        boxSizing: "border-box",
        background: theme.palette.secondary.white,
        width: 18,
        height: 18,
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    background: theme.palette.primary.sys_light_outline,
    width: 14,
    height: 14,
  },
  "& .MuiSwitch-track": {
    borderRadius: 13,
    backgroundColor: theme.palette.secondary.lavender,
    height: "auto",
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
    border: "2px solid #6A7575",
  },
}));
