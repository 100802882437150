import React, { useCallback, useEffect, useState } from "react";
import ArrChartContainer from "../Charts/ArrChartContainer";
import ArrDataGrid from "../DataGrid/ArrGrid";
import { useDispatch, useSelector } from "react-redux";
import { getAnnualRevenueGridData } from "../Functions/common";
import { handleMixPanel } from "../../../../utils/mixPanelEventHandling";
import { setBIDashboardData } from "../../../../modules/actions/biDashboardActions";
import BIDataGridDialog from "../DataGrid/BIDataGridDialog";

function Arr({ companyId, readOnly }) {
	const dispatch = useDispatch();
	const annualRecurringRevenue = useSelector((store) => store?.biDashboard?.annualRecurringRevenue);
	const loadingAnnualRecurringRevenue = useSelector((store) => store?.biDashboard?.loadingAnnualRecurringRevenue);
	const openAnnualRecurringRevenue = useSelector((store) => store?.biDashboard?.openAnnualRecurringRevenue);
	const [isEdited, setEdited] = useState(false);

	const gridData = annualRecurringRevenue?.data ? annualRecurringRevenue?.data : [];

	const ownCompanyId = useSelector((state) => state?.auth?.companyId);
	const usedCompanyId = companyId ? companyId : ownCompanyId;

	const fetchData = async () => {
		try {
			dispatch(setBIDashboardData({ loadingAnnualRecurringRevenue: true }));
			const responseData = await getAnnualRevenueGridData(usedCompanyId);
			if (responseData) {
				dispatch(setBIDashboardData({ annualRecurringRevenue: responseData }));
			}
			dispatch(setBIDashboardData({ loadingAnnualRecurringRevenue: false }));
		} catch (error) {
			dispatch(setBIDashboardData({ loadingAnnualRecurringRevenue: false }));
			console.log(error, "error");
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	const onChartClick = () => {
		handleMixPanel("Grid ARR Viewed");
		dispatch(setBIDashboardData({ openAnnualRecurringRevenue: true }));
	};

	const handleClose = useCallback(() => {
		if (isEdited) {
			dispatch(
				setBIDashboardData({
					openAnnualRecurringRevenueLeave: true,
				})
			);
		} else {
			dispatch(setBIDashboardData({ openAnnualRecurringRevenue: false }));
		}
	}, [isEdited]);

	return (
		<>
			<ArrChartContainer data={annualRecurringRevenue} onChartClick={onChartClick} loading={loadingAnnualRecurringRevenue} readOnly={readOnly} />
			<BIDataGridDialog title="ARR" isOpen={openAnnualRecurringRevenue} onClose={handleClose}>
				<ArrDataGrid gridData={gridData} readOnly={readOnly} usedCompanyId={usedCompanyId} isEdited={isEdited} setEdited={setEdited} />
			</BIDataGridDialog>
		</>
	);
}

export default Arr;
