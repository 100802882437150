import React, { memo, useEffect, useState } from "react";
import BIDataGrid from "./BIDataGrid";
import { get, cloneDeep } from "lodash";
import moment from "moment";
import { convertNumberToLocale, getStartAndEndDate, partners } from "../Functions/common";
import { Box, useMediaQuery, Typography, IconButton } from "@mui/material";
import { CALCULATOR_V1_ICON } from "../../../../constants";
import { handleMixPanel } from "../../../../utils/mixPanelEventHandling";
import SaveData from "./SaveData";
import { useSelector, useDispatch } from "react-redux";
import { toastContainer } from "../../../../utils/toast";
import LeaveConfirm from "./LeaveConfirm";
import { setBIDashboardData } from "../../../../modules/actions/biDashboardActions";
import CloseIcon from "@mui/icons-material/Close";

const disabledRowIds = [0];

function BurnMultipleGrid({ title = "Burn Multiple", gridData, readOnly, usedCompanyId, isEdited, setEdited }) {
	const dispatch = useDispatch();
	const rowName = {
		burnMultiple: { id: 0, name: "Burn Multiple" },
	};

	const [rows, setRows] = useState([]);
	const [columns, setColumns] = useState([]);
	const [saving, setSaving] = useState(false);
	const openBurnMultiple = useSelector((store) => store?.biDashboard?.openBurnMultiple);
	const openBurnMultipleLeave = useSelector((store) => store?.biDashboard?.openBurnMultipleLeave);
	const nextEditTableName = useSelector((store) => store?.biDashboard?.nextEditTableName);
	const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
	const openEdit = useSelector((store) => store?.biDashboard?.openEdit);

	const partnerObj = Array.isArray(gridData) && gridData?.sort((a, b) => b.lastModifiedAt - a.lastModifiedAt)?.[0];
	const displayName = partners.filter((item) => item?.title === partnerObj?.partnerType)?.[0]?.displayName;
	const picture = partners.filter((item) => item?.title === partnerObj?.partnerType)?.[0]?.picture;

	function PartnerDesign() {
		return (
			<Box display="flex" alignItems="flex-start" flexDirection={"column"} gap={"4px"}>
				<Box component={"img"} sx={{ width: "70px" }} src={picture} alt="partner" />
				<Typography component={"p"} sx={{ textAlign: "left" }} variant="Text/sm/Medium" color="sos.gray600">
					{`Connected to ${displayName ? displayName : ""} `}
				</Typography>
				<Typography variant="Text/xs/Regular" color="sos.gray600">
					{`${moment(partnerObj?.lastModifiedAt)?.format("MMMM D, YYYY h:mm A")} EST`}{" "}
				</Typography>
			</Box>
		);
	}

	const valueFormatterForCategory = (params) => {
		if (!params.value) {
			return "";
		}
		if (rowName[params.value]) {
			return rowName[params.value].name;
		}
		return params.value;
	};

	const valueFormatterForMonths = (params) => {
		const { id, value, field } = params;

		if (get(rows, `${id}.${field}`, null) === null) {
			if (disabledRowIds.includes(id)) {
				return (
					<>
						<Box component="img" src={CALCULATOR_V1_ICON} sx={{ width: "14px", height: "14px" }} />-
					</>
				);
			}
			return "";
		}

		return `${convertNumberToLocale(value)}`;
	};

	const renderCell = (params) => {
		const { row } = params;
		let value = params.value;
		if (params.colDef.valueFormatter) {
			value = params.colDef.valueFormatter(params);
		}
		if (value === null) {
			if (disabledRowIds.includes(row.id)) {
				return (
					<>
						<Box component="img" src={CALCULATOR_V1_ICON} sx={{ width: "14px", height: "14px" }} />-
					</>
				);
			}
			return value;
		}
		return value;
	};

	const transformGridData = () => {
		const tempColumns = [
			{
				field: "name",
				headerName: "Date",
				width: 200,
				editable: false,
				sortable: false,
			},
		];

		let dates = [];

		if (gridData?.length > 0) {
			dates = [...new Set(gridData.map((item) => item.date))];
		}
		const { startDate, endDate } = getStartAndEndDate(dates);

		const emptyDates = {};
		let currentDate = moment(startDate);
		while (currentDate <= endDate) {
			emptyDates[moment(currentDate).format("YYYY-MM-DD")] = null;
			tempColumns.push({
				field: moment(currentDate).format("YYYY-MM-DD"),
				headerName: moment(currentDate).format("MMM yyyy"),
				type: "number",
				editable: readOnly ? false : true,
				sortable: false,
				align: "center",
				headerAlign: "center",
				minWidth: 120,
			});
			currentDate = moment(currentDate).add(1, "month");
		}

		// Step 2: Create a new array of objects, one for each unique combination of id and name
		let transformedObj = {};

		for (const key in rowName) {
			if (!transformedObj[key]) {
				transformedObj[key] = { id: rowName[key] ? rowName[key].id : Object.keys(transformedObj).length + 1, name: key, ...emptyDates };
			}
		}

		//step3: loop through data and fill data
		for (const dataItem of gridData) {
			const currentData = dataItem["date"];
			for (const key in rowName) {
				transformedObj[key][currentData] = dataItem[key];
			}
		}

		setColumns(tempColumns);
		setRows(cloneDeep(Object.values(transformedObj)));
	};

	useEffect(() => {
		try {
			transformGridData();
		} catch (error) {
			console.log(error, "error");
		}
	}, [gridData, openBurnMultiple]);

	const handleSave = async () => {
		try {
			toastContainer("Data Saved", "Success");
			if (isEdited && setEdited) {
				setEdited(false);
			}

			dispatch(
				setBIDashboardData({
					openBurnMultiple: false,
					openBurnMultipleLeave: false,
				})
			);
		} catch (error) {
			console.log(error, "error");
			toastContainer(error?.response?.data?.message || "Something is wrong!", "error");
			throw error;
		} finally {
			setSaving(false);
		}
	};

	const onCloseIconClick = () => {
		if (isEdited) {
			dispatch(
				setBIDashboardData({
					openBurnMultipleLeave: true,
				})
			);
		} else {
			dispatch(
				setBIDashboardData({
					...(openBurnMultiple && { openBurnMultiple: false }),
					...(openEdit && { openEdit: false }),
				})
			);
		}
	};

	const onNoClick = () => {
		if (isEdited && setEdited) {
			setEdited(false);
		}
		if (nextEditTableName && openEdit) {
			dispatch(
				setBIDashboardData({
					openBurnMultipleLeave: false,
					editTableName: nextEditTableName,
					nextEditTableName: "",
				})
			);
		} else {
			dispatch(
				setBIDashboardData({
					openBurnMultiple: false,
					openBurnMultipleLeave: false,
					openEdit: false,
					nextEditTableName: "",
				})
			);
		}
	};

	const onYesClick = async () => {
		try {
			await handleSave();
			onNoClick();
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<>
			<Box display="flex" justifyContent="space-between" alignItems="center" pb={2}>
				{partnerObj?.partnerType ? <PartnerDesign /> : <Typography variant="Text/md/Semibold">{title}</Typography>}

				{!(isSmallScreen && openEdit) && (
					<IconButton aria-label="close" onClick={onCloseIconClick} sx={{ color: (theme) => theme.palette.secondary.dark }}>
						<CloseIcon sx={{ color: (theme) => theme.palette.secondary.black }} />
					</IconButton>
				)}
			</Box>
			<BIDataGrid
				rows={rows}
				columns={columns.map((columns, index) => ({
					...columns,
					renderCell: index == 0 ? valueFormatterForCategory : valueFormatterForMonths,
				}))}
				disabledRowIds={disabledRowIds}
				readOnly={readOnly}
			/>
			<SaveData isEdited={isEdited} saving={saving} readOnly={true} onCancelClick={onCloseIconClick} onSaveClick={handleSave} />
			<LeaveConfirm
				saving={saving}
				isOpen={openBurnMultipleLeave}
				onClose={() => {
					dispatch(setBIDashboardData({ openBurnMultipleLeave: false, nextEditTableName: "" }));
				}}
				onYesClick={onYesClick}
				onNoClick={onNoClick}
			/>
		</>
	);
}

export default memo(BurnMultipleGrid);
