import React from "react";
import { Box, Typography, Stack } from "@mui/material";

function Impression({ title, score, scoreVariant = "main_title" }) {
	return (
		<Stack>
			<Typography variant="count_title" color="text.textGreyMid">
				{title}
			</Typography>
			<Typography variant={scoreVariant} color="secondary.black">
				{score}
			</Typography>
		</Stack>
	);
}

export default Impression;
