import styled from "@emotion/styled";
import { Card, CardContent, Box, Typography } from "@mui/material";

export const CardEducation = styled(Card)(({ theme }) => ({
	color: "darkslategray",
	backgroundColor: "rgba(255, 255, 255, 1)",
	borderRadius: 8,
	boxShadow: "0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px rgba(0, 0, 0, 0.3)",
	padding: 0,
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
}));

export const CardContentEducation = styled(CardContent)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-end",
}));

export const BoxEducationTitle = styled(Box)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
}));
export const BoxEducationDetails = styled(Box)(({ theme }) => ({
	marginTop: "24px",
	display: "flex",
	alignItems: "center",
}));

export const TypographyEducation = styled(Typography)(({ theme }) => ({
	overflow: "hidden",
	textOverFlow: "ellipsis",
	display: "-webkit-box",
	WebkitLineClamp: "3",
	WebkitBoxOrient: "vertical",
	color: theme.palette.secondary.black,
}));
