import React from "react";
import { TextField, InputAdornment, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import UnpublishedOutlinedIcon from "@mui/icons-material/UnpublishedOutlined";

export default function StateSelectInput({
	label,
	handleBlur,
	inputKey,
	handleChange,
	helperText,
	value,
	maxLength,
	minLength,
	handleClick,
	required,
	placeholder,
	InputProps,
	options,
	children,
	...props
}) {
	const getAdornment = () => {
		if (props.error) {
			return (
				<InputAdornment position="end">
					<svg width={0} height={0}>
						<linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
							<stop offset={0} stopColor="#FFD141" />
							<stop offset={1} stopColor="#E97300" />
						</linearGradient>
					</svg>
					<UnpublishedOutlinedIcon
						sx={{
							fill: "url(#linearColors)",
						}}
					/>
				</InputAdornment>
			);
		} else {
			if (value?.trim()) {
				return (
					<InputAdornment position="end">
						<CheckCircleIcon sx={{ color: "success.main" }} />
					</InputAdornment>
				);
			} else {
				return (
					<InputAdornment position="end">
						<svg width={0} height={0}>
							<linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
								<stop offset={0} stopColor="#FFD141" />
								<stop offset={1} stopColor="#E97300" />
							</linearGradient>
						</svg>
						<UnpublishedOutlinedIcon
							sx={{
								fill: "url(#linearColors)",
							}}
						/>
					</InputAdornment>
				);
			}
		}
	};
	return (
		<>
			<TextField
				{...props}
				select
				SelectProps={{
					MenuProps: {
						PaperProps: {
							style: {
								maxHeight: 48 * 4.5 + 8,
								boxShadow: "0px 5px 16px rgba(0, 0, 0, 0.3)",
							},
						},
					},
					IconComponent: () => <></>,
					displayEmpty: true,
					placeholder: "states",
					renderValue: (value) => {
						if (!value || value === "") {
							return <Typography color="#a1acac">States</Typography>;
						}
						const selectedOption = options?.find((option) => option.stateCode === value);
						return selectedOption ? selectedOption.stateName : value;
					},
				}}
				color={props.error ? "warning" : "primary"}
				InputLabelProps={{
					...props.InputLabelProps,
					shrink: true,
				}}
				InputProps={{
					...props.InputProps,
					endAdornment: getAdornment(),
				}}
				label={label}
				placeholder={placeholder || ""}
				spellCheck="true"
				fullWidth
				onBlur={(e, type) => {
					handleBlur(e, inputKey);
				}}
				onChange={(e, type) => {
					handleChange(e, inputKey);
				}}
				error={!!helperText}
				helperText={helperText || (required && "*required")}
				value={value}
				inputProps={{ maxLength: maxLength, minLength: minLength }}
				sx={{
					"& .MuiOutlinedInput-notchedOutline": { borderRadius: 1 },
				}}
				onClick={handleClick}
			>
				{children && children}
			</TextField>
		</>
	);
}
