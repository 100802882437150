import React, { Component } from "react";
import { Grid, Button, Box, Link } from "@mui/material";
import { connect } from "react-redux";

import "./socialLogin.css";
import TextInput from "../../common/input/Input";
import { REGEX } from "../../constants/regex";
import { getSocialSignupType, saveUserEmail } from "../../utils/sessionStorage";
import services from "../../services";
import axios from "../../axios.js";
import { toastContainer } from "../../utils/toast";
import ROUTES_PATH from "../../constants/routes";
import messages from "../../constants/messages";
import { logout } from "../../utils/auth0-spa";
import { authWebLogout } from "../../utils/auth0";

class SocialLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: true,
      email: "",
      emailError: false,
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  ValidateEmail(value) {
    if (REGEX.EMAIL.test(value)) {
      return true;
    }
    return false;
  }
  handleEmailInputChange = event => {
    this.setState({
      email: event,
    });
    if (this.ValidateEmail(event)) {
      this.setState({ emailError: false });
    } else {
      this.setState({ emailError: true });
    }
  };
  handleBackClick() {
    this.props.history.push(ROUTES_PATH.LOG_IN);
  }

  handleContinue = async () => {
    const { email } = this.state;

    const userData = this.props.userData;

    // update email on DB and create Auth0 from backend
    services
      .updateEmail({
        email: email,
        userId: userData.sub,
      })
      .then(res => {
        const resData = res.data;
        if (!resData.error) {
          toastContainer(resData?.message, "success");
          saveUserEmail(email);
          this.setState({
            email: "",
            emailError: false,
          });
        } else {
          toastContainer(resData?.message, "error");
        }
      })
      .catch(err => {
        if (err.response.data.error) {
          toastContainer(err.response.data.message, "error");
        } else {
          console.log(err);
          toastContainer(err?.error_description, "error");
        }
      });
  };

  onLgoutHandler = async () => {
    await logout();
    await authWebLogout();
  };

  render() {
    let { email, emailError } = this.state;
    return (
      <Box className="signup-container">
        <Grid
          className="signup-grid-container h-80"
          container
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={12} sm={10} md={6} lg={4} className="form-container">
            <div className="signup-title">
              Continue with{" "}
              {getSocialSignupType() === "google" ? "Google" : "LinkedIn"}
            </div>
            {/*<Box sx={{ pt: 2 }} className="signup-sub-text d-flex">
              By signing up, you agree to our
              <span>
                <TermsAndServiceModal />
              </span>
            </Box>*/}
            <TextInput
              type="text"
              label="Email address"
              value={email}
              error={emailError ? "Please enter valid email address" : ""}
              onChange={e => this.handleEmailInputChange(e)}
            />

            <Grid item>
              <Box textAlign={"center"}>
                <Button
                  sx={{ mt: 3 }}
                  disabled={!email || emailError}
                  onClick={this.handleContinue}
                >
                  Continue
                </Button>
              </Box>
            </Grid>

            {this.props.idToken && (
              <Grid item sx={{ mt: 3 }} align="center">
                <Link
                  component="button"
                  variant="bodyLarge"
                  sx={{ ml: 1, textAlign: "start" }}
                  onClick={this.onLgoutHandler}
                >
                  Logout
                </Link>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Box>
    );
  }
}

const mapStoreToProps = ({ auth }) => ({
  idToken: auth.idToken,
  userData: auth.userData,
});

export default connect(mapStoreToProps, undefined)(SocialLogin);
