import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

import { getIdToken, getToken, getUser, getCompany, handleCallback, logout } from "../../utils/auth0-spa";
import { toastContainer } from "../../utils/toast";
import { authWebLogout } from "../../utils/auth0";
import { getEmailToken, saveUserEmail, setPrimaryAccountId } from "../../utils/sessionStorage";
import { USER_TYPES } from "../../utils/userTypes";
import { handleMixPanel, handleMixPanelSetUser } from "../../utils/mixPanelEventHandling";
import * as actions from "../../modules/actions";
import messages from "../../constants/messages";
import constants from "../../constants";
import ROUTES_PATH from "../../constants/routes";
import services from "../../services";
import { getTotalPowerUpsCompletedCount } from "../../utils/getPowerUpsCompletedCount";

const AuthorizeSuccess = ({ setAuthData, onboarding, auth }) => {
	const { accessToken, userData, userType, socialConnectFlow, ...rest } = auth;
	const [loading, setLoading] = useState(true);
	const history = useHistory();
	const dispatch = useDispatch();
	const queryParams = new URLSearchParams(history.location.search);
	// const utm_source = queryParams?.get("utm_source");
	// const utm_campaign = queryParams?.get("utm_campaign");
	// const utm_medium = queryParams?.get("utm_medium");
	// const partner = queryParams?.get("partner");
	const { utmSource, utmCampaign, utmMedium, partner } = useSelector((store) => store.utmParams);
	const isSSO = queryParams?.get("sso");
	useEffect(() => {
		const token = queryParams.get("token");

		if (token) {
			services
				.verifyEmail(token) // Performing email verification in case of user email not found while social login
				.then((result) => {
					if (result.data.code === 200) {
						toastContainer(messages.EMAIL_VERIFIED, "success");
					}
				})
				.catch((err) => {
					toastContainer("Error occured while verifying email " + err, "error");
				});

			setTimeout(() => {
				logout();
				authWebLogout();
			}, 5000);
		}
	}, []);

	const handleRedirect = () => {
		history.push(constants.REQUEST_USER_TYPE.find((user) => user?.type === auth.userTypes?.[0])?.path);
	};

	// TODO: If `powerUpsCompleted` needs to be tracked, need to have a better solution instead of calling APIs by passing id individually.
	const handleTotalPowerUpsCompletedCountApiCall = async (userDetails, resData) => {
		const powerUpsCompleted = await getTotalPowerUpsCompletedCount();
		if (powerUpsCompleted) {
			userDetails["powerUpsCompleted"] = powerUpsCompleted;
		}
	};

	const handleGetCompanyStatsApiCall = async () => {
		const companyData = await getCompany();

		setAuthData({ companyData });
	};

	const GetFeatureEvents = async () => {
		dispatch(
			actions.setCommonDialogConfirmationData({
				open: true,
			})
		);
	};
	const handleGetCompanyDetailsApiCall = async () => {
		await services
			.getCompanyProfile()
			.then((response) => {
				let services = response.data;
				if (services.message === "Success") {
					setAuthData({ companyLogoUrl: services.data.logoUrl });
				}
			})
			.catch((error) => {
				console.log("error", error);
			});
	};

	useEffect(() => {
		const handleAuth = async () => {
			await handleCallback().catch((error) => {
				if (error.message === "Permissions error") {
					logout();
					authWebLogout();
				}
			});

			//call auth0 /token api
			const accessToken = await getToken({
				detailedResponse: false,
			});

			const idToken = await getIdToken();
			const userData = await getUser();
			//Check if current flow is just to connect social account or login
			if (auth.socialConnectFlow) {
				//Backend api call for saving verified user identity
				services
					.linkSocialAccountApiCall({ token: idToken })
					.then((result) => {
						if (result.data?.code === 200) {
							//Set connection flow flag to false
							setAuthData({ socialConnectFlow: false });
							setAuthData({ showAccountSettingPopup: true });
							handleRedirect();
						}
					})
					.catch((error) => {
						toastContainer(error.response?.data?.message, "error");
						//In case of error and redirect to workspace
						setAuthData({ showAccountSettingPopup: false });
						setAuthData({ socialConnectFlow: false });
						handleRedirect();
					});
			} else {
				if (isSSO) {
					await services.createUserSocialOnboard(
						{
							providerId: userData?.sub,
						},
						{
							utm_source: utmSource,
							utm_medium: utmMedium,
							utm_campaign: utmCampaign,
							partner: partner,
						}
					);
				}

				if (userData?.email_verified) {
					setAuthData({
						accessToken,
						idToken,
						userData,
						userSessionChange: false,
					});

					saveUserEmail(userData?.email);
					let payload = {};

					const onboardingData = getOnboardingData();
					if (!isEmpty(onboardingData)) {
						payload = {
							id: null,
							token: idToken,
							onboardingDetails: onboardingData,
						};
					} else {
						payload = {
							id: null,
							token: idToken,
							emailToken: getEmailToken(),
						};
					}

					await services
						.getUserProfile()
						.then(async (result) => {
							if (result.data.code === 200) {
								const resData = result.data.data;

								const isSocialLogin = resData.userIdentities.some((ele) => ele.provider !== "auth0");
								let userDetails = {
									id: resData?.id,
									name: resData?.name,
									$email: resData?.email,
									userType: resData?.userTypes?.[0],
									phone: resData?.phone,
									email: result?.email,
									$avatar: resData?.picture,
									powerUpsCompleted: "",
									companyName: resData?.companyModel?.companyName,
									companyRole: resData?.profession,
									companyId: resData?.companyModel?.id,
								};

								setAuthData({ partnerCode: resData?.partnerCode, isSocialLogin: isSocialLogin });

								const userRole = resData?.userTypes?.[0];

								if (userRole === USER_TYPES.STARTUP_OWNER || userRole === USER_TYPES.STARTUP_USER || userRole === USER_TYPES.STUDENT_USER) {
									GetFeatureEvents();

									// TODO: Need to improve this Mixpanel track with better solution
									// handleTotalPowerUpsCompletedCountApiCall(userDetails, resData);
									await handleGetCompanyStatsApiCall();
									await handleGetCompanyDetailsApiCall();
								}
								handleMixPanelSetUser(userDetails);
								handleMixPanel("Account Login", {
									"User ID": userDetails?.id,
									"User Name": userDetails.name,
									"User Role": resData?.userTypes?.[0],
								});
								setAuthData({
									userId: resData.id,
									utmSource: resData?.utmSource,
									utmCampaign: resData?.utmCampaign,
									utmMedium: resData?.utmMedium,
									isInvestorLocked: resData?.isInvestorLocked,
								});
								setAuthData({
									userData: {
										picture: resData.picture,
									},
								});

								setAuthData({ calUserId: resData.calUserId });
								setAuthData({ email: resData.email });
								setAuthData({ isAdmin: resData?.admin ? true : false });
								// setAuthData({ isPremiumPlanAccess: resData?.companyModel?.isPremiumPlanAccess ? true : false });

								if (!resData.email) {
									history.push(ROUTES_PATH.SOCIAL_LOGIN);
								} else if (!resData.emailVerified) {
									history.push(ROUTES_PATH.EMAIL_VERIFICATION);
								} else if (!userRole) {
									setAuthData({
										userName: isSSO ? "" : resData.name,
									});

									history.push(ROUTES_PATH.NEW_ONBOARD_CUSTOMIZE);
								} else {
									if (!!resData.isTourCompleted === false) {
										setAuthData({ showModal: true });
									}
									setAuthData({ companyName: resData.companyModel?.companyName });
									setAuthData({ companyId: resData.companyModel?.id });
									setAuthData({ companyStage: resData.companyModel?.currentStage });

									//Temporarily keeping startupuser role as optional until login api is not integrated need to remove optional once login api integration complete and roles appear in login api
									setAuthData({ userType: resData.userTypes?.[0] || "STARTUP_OWNER" });
									//Check userdata for mentor and advisor
									if (resData && Object.keys(resData).length) {
										setAuthData({
											userName: isSSO ? "" : resData.firstName + " " + resData.lastName,
										});
									} else {
										setAuthData({
											userName: isSSO ? "" : resData.name,
										});
									}

									await services.updateStreakActionApi({
										actionType: "LOGIN",
										actionOnId: resData.id,
										actionOnType: "USER",
									});
									// .then()
									// .catch();

									setAuthData({ authenticatedAppUser: true }); // mark user authenticated on App backend

									// if (
									// 	constants.REQUEST_USER_TYPE.find(
									// 		(user) => user?.type === resData?.userTypes[0]
									// 	)
									// ) {
									// 	// TODO: Define ADMIN userType on backend and got response for that.
									// 	if (resData.admin) {
									// 		history.push(ROUTES_PATH.INVESTOR_WORKSPACE);
									// 		return;
									// 	}

									// 	const isMentor = resData.userTypes[0] === USER_TYPES.MENTOR_USER;
									// 	const isStartup = [USER_TYPES.STARTUP_OWNER, USER_TYPES.STARTUP_USER].includes(
									// 		resData.userTypes[0]
									// 	);
									// 	if (isMentor || isStartup) {
									// 		const redirectUrl = auth.routeAfterLogin;

									// 		setAuthData({ routeAfterLogin: "" });
									// 		history.push(redirectUrl);
									// 		return;
									// 	}
									// 	history.push(
									// 		constants.REQUEST_USER_TYPE.find(
									// 			(user) => user?.type === resData?.userTypes[0]
									// 		)?.path
									// 	);
									// } else {
									// 	history.push(ROUTES_PATH.INVESTOR_WORKSPACE);
									// }
								}
								setLoading(false);
							}
						})
						.catch((err) => {
							console.log(err);
							if (err.response.status === 301) {
								// Executed this block if user trying to create account without onboarding details, show message to user and redirect them to onboarding

								toastContainer(err.response?.data?.message, "error");
								setTimeout(() => {
									setLoading(false);
									logout();
									authWebLogout(process.env.REACT_APP_REDIRECTION_ONBOARDING_URL);
								}, 3000);
							} else if (err.response.status === 400) {
								toastContainer(err?.response?.data?.message, "error");
								setTimeout(() => {
									setLoading(false);
									logout();
									authWebLogout();
								}, 3000);
							} else if (err.response.status === 404) {
								console.log(err);
								setTimeout(() => {
									setLoading(false);
									logout(process.env.REACT_APP_ACCOUNT_NOT_FOUND_URL);
									authWebLogout(process.env.REACT_APP_ACCOUNT_NOT_FOUND_URL);
								}, 3000);
							} else {
								console.log(err);
								toastContainer(err?.error_description, "error");
								setTimeout(() => {
									setLoading(false);
									logout();
									authWebLogout();
								}, 3000);
							}
						});
				} else {
					// history.push(ROUTES_PATH.EMAIL_VERIFICATION);

					saveUserEmail(userData?.email);
					setPrimaryAccountId(userData?.sub);
					setAuthData({
						userData: userData,
						isAuthenticated: false,
					});
					history.push(ROUTES_PATH.EMAIL_VERIFICATION);
				}
			}
		};

		handleAuth();
	}, []);

	const getOnboardingData = () => {
		let journeyDetailsString = onboarding?.journeyDetails?.join();
		if (onboarding?.autoCompleteDetails?.journeyDetails) {
			journeyDetailsString += "," + onboarding.autoCompleteDetails.journeyDetails;
		}
		const onboardingDetails = {
			companyName: onboarding?.companyName,
			companyType: onboarding?.autoCompleteDetails?.companyType ? onboarding.autoCompleteDetails.companyType : onboarding?.productService,
			journeyDetails: journeyDetailsString,
			experienceDetails: onboarding?.experienceDetails,
			founderSuperpower: onboarding?.founderSuperpower,
			currentCommitment: onboarding?.currentCommitment,
			state: onboarding?.companyLocation?.state,
		};

		return onboardingDetails;
	};

	const isEmpty = (o) => Object.entries(o).every(([k, v]) => v === "");

	return loading ? (
		<Box
			sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				height: "100vh",
			}}
		>
			<CircularProgress />
		</Box>
	) : (
		<></>
	);
};

const mapStoreToProps = ({ auth, onboarding }) => ({
	idToken: auth.idToken,
	userData: auth.userData,
	auth: auth,
	onboarding: onboarding,
});

const mapDispatchToProps = (dispatch) => ({
	setAuthData: (updatedData) => dispatch(actions.setAuthData(updatedData)),
});

export default connect(mapStoreToProps, mapDispatchToProps)(AuthorizeSuccess);
