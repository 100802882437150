import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import CircleGraphic from "./TSSChart";
import { formatNumberToString } from "./functions";

function TSSGraphic({ tam, sam, som }) {
	const [tamVal, settamVal] = useState(tam);
	const [samVal, setsamVal] = useState(sam);
	const [somVal, setsomVal] = useState(som);

	useEffect(() => {
		settamVal(formatNumberToString(tam));
		setsamVal(formatNumberToString(sam));
		setsomVal(formatNumberToString(som));
	}, [tam, sam, som]);

	return (
		<Box
			sx={{
				height: {
					lg: "400px",
					md: "350px",
					xs: "300px",
				},
				width: {
					lg: "400px",
					md: "350px",
					xs: "300px",
				},
			}}
		>
			<CircleGraphic tam={tamVal} sam={samVal} som={somVal} />
		</Box>
	);
}

export default TSSGraphic;
