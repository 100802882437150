import React from "react";
import { Box, Grid } from "@mui/material";
import FeaturedPartner from "../FeaturedPartner";
import InvestorRadar from "../QuickStatView/InverstorRadar";
import StartupOSScore from "../QuickStatView/StartupOSScore";

export default function NetworkUpdates(props) {
  const { data, children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      sx={{ ml: 3, mr: 3, pb: 25 }}
    >
      <Box sx={{ mt: 3, width: "auto" }}>
        <StartupOSScore
          id="mobile"
          value={parseInt(data.startUpScore)}
          maxValue={data.startUpMaxScore}
        />
      </Box>
      {/*<Box sx={{ mt: 3, width: "auto" }}>
        <InvestorRadar id="mobile" level={data.radarValue} />
      </Box>*/}
      {/*<Grid
        container
        sx={{ mt: 3 }}
        backgroundColor="#FCFCFC"
        borderRadius="28px"
        data-tut="reactour__responsive-partner"
      >
        <FeaturedPartner />
      </Grid>*/}
    </Box>
  );
}
