import { Button } from "@mui/material";
import React, { Fragment, useState } from "react";
import InviteTeam from "../../../MyStartup/InviteTeam";

function InviteTeamButton() {
	const [showInviteTeamPopup, setShowInviteTeamPopup] = useState(false);
	return (
		<Fragment>
			<Button
				variant="DS1"
				onClick={() => setShowInviteTeamPopup(true)}
			>
				Invite Team Members
			</Button>
			<InviteTeam showInviteTeamPopup={showInviteTeamPopup} setShowInviteTeamPopup={setShowInviteTeamPopup} />
		</Fragment>
	);
}

export default InviteTeamButton;
