import React, { useEffect, useState } from "react";
import {
	LOCATION_NEW_ICON,
	ROCKET_LAUNCH_ICON,
	MOBILE_DEVICE_ICON,
	GLOBE_HEMISPHERE_EAST_ICON,
	CODESANDBOX_ICON,
	UPDATED_INDUSTRY_ICON,
} from "../../../../../constants";
import { STATES } from "../../../../../constants/powerup-data";
import PublicTagList from "../../../Common/PublicTagList";
import { useSelector } from "react-redux";

function StartupBioTags() {
	const [items, setitems] = useState([]);
	const startupData = useSelector((state) => state?.startupPublicProfileData?.startupPublicData);

	useEffect(() => {
		let list = [
			{
				iconUrl: LOCATION_NEW_ICON,
				label: (() => {
					let city = startupData?.city;
					let area = STATES.find((s) => s.name === startupData?.state);
					area = area ? area?.abbreviation : startupData?.state;
					if (city && area) {
						return `${city}, ${area}`;
					} else if (!city && startupData?.state) {
						return startupData?.state;
					} else return "No Data";
				})(),
			},
			{ iconUrl: ROCKET_LAUNCH_ICON, label: startupData?.foundedYear ? startupData?.foundedYear : "No Data" },
			{
				iconUrl: GLOBE_HEMISPHERE_EAST_ICON,
				label: (() => {
					return startupData?.websiteLink ? (
						<span style={{ cursor: "pointer" }} onClick={() => window.open(startupData?.websiteLink, "_blank")}>
							{startupData?.websiteLink.replace(/^(?:https?:\/\/)?(?:[^\/]+\.)?([^.\/]+\.[^.\/]+).*$/, "$1")}
						</span>
					) : (
						"No Data"
					);
				})(),
			},
			{
				iconUrl: CODESANDBOX_ICON,
				label: startupData?.productOffering ? startupData?.productOffering : "No Data",
			},
			{
				iconUrl: UPDATED_INDUSTRY_ICON,
				label: (() => {
					let industry = "";
					try {
						industry = startupData?.industry?.length > 0 ? JSON.parse(startupData?.industry)[0] : "";
					} catch (e) {
						console.log(e);
					}
					return industry ? industry : "No Data";
				})(),
			},
		];
		setitems(list);
	}, []);

	return <PublicTagList items={items} />;
}

export default StartupBioTags;
