import React from "react";
import Radio from '@mui/material/Radio';
import { useState } from "react";
import { array } from "prop-types";
export default function CustomRadioButton({
  onChange,
  icon,
  checkedIcon,
  value,
  selected,
  setSelected
}) {
  const checked = selected.includes(value) ? true : false
  const handelClick = (e) => {
    if (checked == false) {
      setSelected([])
      setSelected(prevArray => [...prevArray, e.target.value]);
    } else {
      setSelected([])
    }

    e.stopPropagation()
  }
  return (
    <Radio
      value={value}
      checked={checked}
      onClick={handelClick}
      onChange={onChange}
      icon={icon}
      checkedIcon={checkedIcon}
    />
  )
}