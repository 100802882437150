import React, { useState, useEffect } from "react";
import theme from "../../../../../../constants/Theme";
import { Box, Typography, Grid, Divider } from "@mui/material";
import TAMSAMSOMGraph from "../../TAMSAMSOM/Components/Graph";
import { getTAMSAMSOMValues } from "../../TAMSAMSOM/functions";
import services from "../../../../../../services";

export default function Header(props) {
  const [calculatedTam, setCalculatedTam] = useState(0);
  const [calculatedSam, setCalculatedSam] = useState(0);
  const [calculatedSom, setCalculatedSom] = useState(0);
  const [powerUpExcercises, setPowerUpExcercises] = useState(0);

  //useEffect(() => {}, []);

  useEffect(() => {
    const powerupValues = getTAMSAMSOMValues(props.powerUpExcercises);

    if (powerupValues.tam) {
      setCalculatedTam(powerupValues.tam);
    }
    if (powerupValues.sam) {
      setCalculatedSam(powerupValues.sam);
    }
    if (powerupValues.som) {
      setCalculatedSom(powerupValues.som);
    }
  }, [props.powerUpExcercises]);

  return (
    <Box>
      <Grid container flexDirection={"row"} spacing={2}>
        <Grid
          item
          xs={12}
          md={3}
          lg={4}
          xl={5}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            justifyContent: "center",
          }}
        >
          <Typography variant="revenue_title">
            Your sketch of the market
          </Typography>
          <Typography variant="revenue_body">
            {props?.excerciseSteps[props?.step - 1]?.description}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={9}
          lg={8}
          xl={7}
          sx={{
            px: 5,
            display: "flex",
          }}
        >
          <TAMSAMSOMGraph
            tam={calculatedTam}
            sam={calculatedSam}
            som={calculatedSom}
          />
        </Grid>
      </Grid>
      <Divider sx={{ pt: { xs: 4, md: 2 } }} />
    </Box>
  );
}
