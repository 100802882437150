import React from "react";
import { Box } from "@mui/material";

export default function CompanyDetail({ heading, value }) {

    return(
        <Box sx={{ 
            height: "62px", 
            width: "33.3333%"
        }}>
            <Box sx={{
                color: "rgba(135, 135, 135, 1)",
                fontFamily: '"PoppinsSemiBold", "Helvetica", "Arial", sans-serif',
                fontWeight: "600",
                fontSize: "16px",
                lineHeight: "24px"
            }}>
                {heading}
            </Box>
            <Box sx={{
                fontFamily: '"PoppinsSemiBold", "Helvetica", "Arial", sans-serif',
                fontWeight: "600",
                fontSize: "30px",
                lineHeight: "45px"
            }}>
                {value}
            </Box>
        </Box>
    );
}