export const steps = [
  {
    label: "My Role",
    complete: false, // Needs to be false for prod
  },
  {
    label: "Why StartupOS?",
    complete: false, // Needs to be false for prod
  },
  {
    label: "About Me",
    complete: false,
  },
  {
    label: "Superpower",
    complete: false,
  },
  {
    label: "Expertise",
    complete: false,
  },
  {
    label: "Startup Information",
    complete: false,
  },
  {
    label: "Funding",
    complete: false,
  },
];
