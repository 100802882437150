import React, { useState, useEffect, useRef } from "react";
import { Box } from "@mui/material";
import MUIRichTextEditor from "mui-rte";
import { stateToMarkdown } from "draft-js-export-markdown";
import { stateFromMarkdown } from "draft-js-import-markdown";
import { convertToRaw, EditorState } from "draft-js";
import { createTheme, ThemeProvider } from "@mui/material/styles";

export default function RichTextEditor({
  label,
  value,
  toolbar,
  inlineToolbar,
  inlineToolbarControls,
  controls,
  readOnly,
  onSave,
  onChange,
  setNotes,
  minHeight = "100%",
}) {
  const [initial, setInitial] = useState("");
  const [newNotes, setNewNotes] = useState(value);
  const inputRef = useRef();

  useEffect(() => {
    let init_state;
    if (newNotes) {
      init_state = EditorState.createWithContent(stateFromMarkdown(newNotes));
    }
    if (init_state) {
      setInitial(JSON.stringify(convertToRaw(init_state.getCurrentContent())));
    }
  }, []);
  const myTheme = createTheme({
    // Set up your custom MUI theme here
  });
  const handleFocus = () => {
    inputRef.current.focus();
  };

  return (
    <ThemeProvider theme={myTheme}>
      <Box sx={{ minHeight: minHeight }} onClick={() => handleFocus()}>
        <MUIRichTextEditor
          ref={inputRef}
          onChange={data => {
            onChange(data);
          }}
          label={readOnly ? "" : label}
          value={initial}
          toolbar={toolbar}
          inlineToolbar={inlineToolbar}
          inlineToolbarControls={inlineToolbarControls}
          controls={controls}
          readOnly={readOnly}
          draftEditorProps={{ spellCheck: true }}
        />
      </Box>
    </ThemeProvider>
  );
}
