import styled from "@emotion/styled";
import { Card } from "@mui/material";

export const EventCard = styled(props => <Card {...props} />)(
  ({ theme, variant }) => ({
    borderRadius: theme.shape.standard.borderRadius,
    ...(variant !== "bordered" && {
      padding: theme.spacing(3),
      boxShadow:
        "0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
    }),
    ...(variant === "bordered" && {
      padding: theme.spacing(3),
      borderWidth: "1px",
      borderColor: theme.palette.secondary.outlineLight,
      borderStyle: "solid",
    }),
  })
);

export const IconBox = styled.div(({ theme }) => ({
  paddingTop: theme.spacing(8),
  paddingBottom: theme.spacing(10),
}));
