import * as actionTypes from "../../actions/actionTypes";
import { updateObject } from "../../../utils/updateObject";

const initialState = {
	loading: false,
	popUpData: [],
	isPopUpOpen: false,
	popUpType: "",
	userData: null,
	userProfileEditOpen: false,
};

const userProfileData = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SET_USER_PROFILE_DATA: {
			return action.payload === null ? initialState : updateObject(state, action.payload);
		}
		default:
			return state;
	}
};
export default userProfileData;
