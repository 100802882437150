import React, { useEffect, useState } from "react";
import { Container, Grid, Stack, Box, Typography, CircularProgress } from "@mui/material";
import { connect } from "react-redux";

import { TabGroup, TabComponent } from "./styles";
import StayOnTrack from "./StayOnTrack";
import QuickStatView from "./QuickStatView";
import WorkspaceUpdates from "./WorkspaceUpdates";
import NetworkUpdates from "./NetworkUpdates";
import RunwayCalculator from "./Runway Calculator";
import FeaturedPartner from "./FeaturedPartner";
import PowerUp from "./PowerUp";
import { BlueDashboardIcon } from "../../common/icons";
import { getCurrentDate } from "../../utils/date";

/// Commented Out - In favor of using Pendo Tours/Guides.
/// Decided to comment rather than remove in case we want to revert back to using in house tour components.
//import WelcomeStartupOSModal from "../../common/modal/WelcomeStartupOSModal";
import * as actions from "../../modules/actions";
import theme from "../../constants/Theme";
import services from "../../services";
import { toastContainer } from "../../utils/toast";
import { sosPrimary } from "../../constants/Theme/colors";
import ProgressCard from "./QuickStatView/ProgressCard";
import StartupOSScore from "./QuickStatView/StartupOSScore";

import AdBanner from "../../components/AdBanner";
import constants from "../../constants";
import { VOUCH_LOGO, SVB_LOGO } from "../../constants/image";

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

const initialState = {
	demoDate: "",
	runawayDetails: [
		{
			cash: 0,
			burnRate: 0,
			type: "ACTUAL",
		},
		{
			cash: 0,
			burnRate: 0,
			type: "WHAT_IF",
		},
	],
	streakDetails: [
		{
			userId: 0,
			daysMaintained: 1,
			expiresOn: "2022-04-23",
			streakType: {
				period: 0,
				streakName: "POWER_UP",
			},
		},
		{
			userId: 0,
			daysMaintained: 0,
			expiresOn: "2022-04-23",
			streakType: {
				period: 0,
				streakName: "LOGIN",
			},
		},
		{
			userId: 0,
			daysMaintained: 1,
			streakType: {
				period: 0,
				streakName: "TODAYS_TOP",
			},
		},
	],
	lastVisitedPowerUp: null,
	totalPowerUpsCompleted: 0,
	circleTotalUnits: 0,
	startUpScore: 250,
	radarValue: 0,
	unitDetails: {
		powerUpsUnits: 11,
		circleTotalUnits: 13,
		startUpMinScore: 0,
		startUpMaxScore: 850,
		newUserSignupScore: 250,
		powerupMaxScore: 500,
		demoDateRange: 28,
	},
};

const AdBannerComponent = (advertsDetails) => {
	return (
		<AdBanner
			title={advertsDetails?.adName}
			subtitle={advertsDetails?.adCopy}
			image={advertsDetails?.adUrl}
			backgroundSize="cover"
			action={{
				text: "View Services",
				props: {
					href: advertsDetails?.serviceUrl,
					target: "_blank",
				},
			}}
		/>
	);
};

const Workspace = ({ showModal, setAuthData }) => {
	const [value, setValue] = React.useState(0);
	const [dashboardData, setDashboardData] = React.useState(initialState);
	const [stayOnTrackData, setStayOnTrackData] = React.useState();
	const [advertsDetails, setAdvertsDetails] = useState();
	const [loading, setLoading] = useState(true);
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const handleNext = (event) => {
		setAuthData({ showModal: false, showTour: true });
	};

	const getAdvertsById = (id) => {
		services
			.getAdvertsById(id)
			.then((res) => {
				setLoading(false);
				const resData = res.data.data;
				if (res.data.code === 200) {
					setAdvertsDetails(resData[0]);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		});
		services
			.getDashboardStats()
			.then((res) => {
				const data = res.data;
				let resData = data.data;
				// Overriding date to a fixed date -> MLP-2317
				// Overriding date again for the new pitch date -> Dec 8 2022 ; still no function to set from DB
				resData = {
					...resData,
					demoDate: new Date("8 December 2022"),
				};
				setDashboardData(resData);
			})
			.catch((err) => {
				console.log(err);
				// Related to above, fallback to set date even if the call fails.
				setDashboardData((prevState) => ({
					...prevState,
					demoDate: new Date("8 December 2022"),
				}));
				//toastContainer(err?.error_description, "error");
			});

		//services
		//  .getTodaysTop()
		//  .then(res => {
		//    const data = res.data;
		//    const resData = data.data;

		//    setStayOnTrackData(resData);
		//  })
		//  .catch(err => {
		//    console.log(err);
		//    toastContainer(err?.error_description, "error");
		//  });
		getAdvertsById(2);
	}, []);

	return (
		<Container maxWidth="xl">
			{/*
      /// Commented Out - In favor of using Pendo Tours/Guides.
      /// Decided to comment rather than remove in case we want to revert back to using in house tour components.
      <WelcomeStartupOSModal
        isOpen={showModal}
        onClose={() => setAuthData({ showModal: false })}
        onNext={handleNext}
      />
      */}

			<Stack
				sx={(theme) => ({
					minHeight: 1,
					display: { xs: "none", md: "block" },
				})}
			>
				<Grid container sx={{ maxWidth: theme.breakpoints.values.xl }}>
					{loading ? (
						<Box
							sx={{
								display: "flex",
								width: 1,
								justifyContent: "center",
								py: 4,
							}}
						>
							<CircularProgress />
						</Box>
					) : (
						<Grid item md={12} lg={12} sx={{ p: "0px 0px 32px 0px" }}>
							{AdBannerComponent(advertsDetails)}
							{/* <AdBanner
              title="Startup insurance for the Chief Everything Officer."
              subtitle="Top startups choose Vouch for protection from claims, litigation and attack."
              image={VOUCH_LOGO}
              backgroundSize="cover"
              action={{
                text: "View Services",
                props: {
                  href: constants.VOUCH_PARTNER_URL,
                  target: "_blank",
                },
              }}
            /> */}
						</Grid>
					)}
					<Grid item md={4} lg={4} sx={{ pr: 2 }}>
						<Grid container>
							<Grid item md={12} lg={12} sx={{ height: 1 }} data-tut="reactour__runwayCalculator">
								<RunwayCalculator data={dashboardData?.runawayDetails} />
							</Grid>
						</Grid>
					</Grid>
					<Grid item md={4} lg={4} sx={{ pr: 2 }}>
						<Grid container>
							<Grid item md={12} lg={12} sx={{ height: 1 }}>
								<StartupOSScore id="desktop" value={parseInt(dashboardData.startUpScore)} maxValue={dashboardData.startUpMaxScore} />
							</Grid>
							<Grid item md={12} lg={12} sx={{ height: 1, mt: 3 }}>
								<ProgressCard data={dashboardData} />
							</Grid>
						</Grid>
					</Grid>
					<Grid
						item
						md={4}
						lg={4}
						sx={(theme) => ({
							height: 1,
							borderRadius: theme.shape.standard.borderRadius,
							backgroundColor: theme.palette.secondary.white,
						})}
					>
						<Grid
							//Component is hidden for now
							display={"none"}
							container
							alignItems="center"
							justifyContent="center"
							data-tut="reactour__stayOnTrack"
						>
							<Grid item md={12} lg={12} sx={{ height: 1 }}>
								<StayOnTrack data={dashboardData} stayOnTrackData={stayOnTrackData} />
							</Grid>
						</Grid>
						{dashboardData?.lastVisitedPowerUp && (
							<Grid container alignItems="center" justifyContent="center" data-tut="reactour__powerup">
								<PowerUp data={dashboardData?.lastVisitedPowerUp} />
							</Grid>
						)}
					</Grid>
				</Grid>
			</Stack>
			<Stack
				alignItems="center"
				justifyContent="center"
				sx={{
					minHeight: "100%",
					display: { xs: "block", md: "none" },
					//background:
					//  "linear-gradient(0deg, rgba(118, 73, 160, 0.05), rgba(118, 73, 160, 0.05)), #FCFCFC",
				}}
			>
				<Box sx={{ pt: 3 }} data-tut="reactour__responsive-quickStat">
					<Box sx={{ px: 3 }}>{AdBannerComponent(advertsDetails)}</Box>

					{/*<Box
            sx={{ p: 3, display: "flex", alignItems: "center", ml: 3, mr: 3 }}
            backgroundColor="#FCFCFC"
            borderRadius="28px"
          >
            <BlueDashboardIcon />
            <Typography
              variant="h2"
              sx={{ fontSize: "22px !important", ml: 1 }}
            >
              Dashboard
            </Typography>
            <Typography
              sx={{
                marginLeft: "auto",
                fontFamily: "PoppinsRegular !important",
              }}
              variant="h6"
            >
              {getCurrentDate()}
            </Typography>
          </Box>*/}
					<Box sx={{ pl: 3.1, pr: 3.1, pt: 3 }} justifyContent="center" alignItems="center">
						<TabGroup value={value} onChange={handleChange}>
							<TabComponent type="workspace" label="Workspace Updates" {...a11yProps(0)} />
							<TabComponent type="network" label="Network Updates" {...a11yProps(1)} />
						</TabGroup>
					</Box>
					<Box>
						<WorkspaceUpdates value={value} index={0} data={dashboardData} stayOnTrackData={stayOnTrackData}></WorkspaceUpdates>
						<NetworkUpdates value={value} index={1} data={dashboardData}></NetworkUpdates>
					</Box>
				</Box>
			</Stack>
		</Container>
	);
};

const mapStoreToProps = ({ auth }) => ({
	showModal: auth.showModal,
});

const mapDispatchToProps = (dispatch) => ({
	setAuthData: (updatedData) => dispatch(actions.setAuthData(updatedData)),
});

export default connect(mapStoreToProps, mapDispatchToProps)(Workspace);
