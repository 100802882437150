import React, { useState, useEffect, useMemo } from "react";
import useDimensions from "react-cool-dimensions";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import { Box, IconButton, Typography } from "@mui/material";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import "swiper/swiper-bundle.css";
import "swiper/css";

import services from "../../../../services";
import { useWindowSize } from "../../../../utils/windowResize";
import TeamMateCard from "../../../../common/CommonCards/TeamMateCard";
// import FullProfileDrawer from "../../../../components/ScoutingReport/FullProfileDrawer";

export default function MeetTheTeamCarousel({ carouselData, startup }) {
	const size = useWindowSize();
	const [companyUsers, setCompanyUsers] = useState([]);
	const [prevDisabled, setPrevDisabled] = useState(true);
	const [nextDisabled, setNextDisabled] = useState(false);
	const [openFullProfile, setOpenFullProfile] = useState(false);

	const disableArrows = (index) => {
		if (index === 0) {
			setPrevDisabled(true);
		} else if (index !== 0) {
			setPrevDisabled(false);
		} else {
			console.log("Error disabling prev button.");
		}
		if (index >= companyUsers.length - 4) {
			setNextDisabled(true);
		} else if (index < companyUsers.length - 4) {
			setNextDisabled(false);
		} else {
			console.log("Error disabling next button.");
		}
	};

	const slideItems = useMemo(() => {
		let items = [];
		companyUsers.forEach((item, index) => {
			items.push(
				<SwiperSlide key={index} style={{ width: "400px" }}>
					<Box
						sx={{
							width: "400px",
						}}
					>
						<TeamMateCard
							teammateData={{
								id: item?.id,
								imageUrl: item?.picture,
								title: item?.title,
								name: item?.name,
								about: item?.about,
								message: item?.message, // no evidence this works
								role: item?.userTypes[0],
								phone: item?.phone,
								linkedinUrl: item?.linkedinUrl,
								headline: item?.headline,
								about: item?.about,
								city: item?.city,
								state: item?.state,
								zipcode: item?.zipcode,
								superpower: item?.superpower,
								expertise: item?.expertise,
								experienceDetails: item?.experienceDetails,
								educationDetails: item?.educationDetails,
								companyName: item?.companyModel?.companyName,
							}}
							teammate={item}
							// openFullProfile={openFullProfile}
							toggleOpenFullProfile={() => {
								toggleOpenFullProfile(true);
							}}
						/>
					</Box>
				</SwiperSlide>
			);
		});

		return items;
	}, [companyUsers]);

	const getCompanyUserApiCall = (startupId) => {
		services.getCompanyUser(startupId).then((result) => {
			setCompanyUsers(result.data.data);
		});
	};

	const toggleOpenFullProfile = (newOpen) => {
		setOpenFullProfile(newOpen);
	};

	useEffect(() => {
		getCompanyUserApiCall(startup.id);
	}, []);

	const maxWidth = useMemo(() => {
		return size.width > 900 ? size.width - 360 : size.width - 48;
	}, [size]);

	const { observe, unobserve, width, height, entry } = useDimensions();

	useEffect(() => {
		observe();
	}, []);

	return (
		<Box sx={{ flex: 1, overflow: "hidden" }} ref={observe}>
			<Box
				sx={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					marginBottom: 5,
					flexWrap: "wrap",
				}}
			>
				<Box display="flex" alignItems={"center"} gap={1}>
					<Typography
						variant="customValue"
						sx={{
							color: "black",
							fontWeight: "600",
							fontFamily: "PoppinsSemiBold",
							fontSize: "50px",
							lineHeight: "75px",
						}}
					>
						{carouselData.title}
					</Typography>
				</Box>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
					}}
				>
					<Typography
						variant="customValue"
						sx={{
							mr: 2,
							color: "black",
							fontWeight: "700",
							fontFamily: "PoppinsBold",
							fontSize: "18px",
							lineHeight: "27px",
						}}
					>{`Teammates [${companyUsers.length}]`}</Typography>
					<IconButton className={`swiper-button-prev-unique-${carouselData.type}`} disabled={prevDisabled}>
						<ArrowCircleLeftOutlinedIcon
							fontSize="large"
							sx={{ color: (theme) => (prevDisabled ? "#BBBBBB" : theme.palette.secondary.black) }}
							className="swiper-button-prev-unique-icon"
						/>
					</IconButton>
					<IconButton className={`swiper-button-next-unique-${carouselData.type}`} disabled={nextDisabled}>
						<ArrowCircleRightOutlinedIcon
							sx={{ color: (theme) => (nextDisabled ? "#BBBBBB" : theme.palette.secondary.black) }}
							fontSize="large"
							className="swiper-button-next-unique-icon"
						/>
					</IconButton>
				</Box>
			</Box>
			<Box
				sx={{
					width: width,
				}}
			>
				<Swiper
					onActiveIndexChange={(swiperCore) => disableArrows(swiperCore.activeIndex)}
					slidesPerView="auto"
					spaceBetween={20}
					grabCursor={true}
					navigation={{
						nextEl: `.swiper-button-next-unique-${carouselData.type}`,
						prevEl: `.swiper-button-prev-unique-${carouselData.type}`,
					}}
					loop={false}
					centeredSlides={false}
					fade={true}
					modules={[Pagination, Navigation]}
				>
					{slideItems}
				</Swiper>
			</Box>
			{/* <FullProfileDrawer
				open={openFullProfile}
				// toggleOpenFullProfile={() => { toggleOpenFullProfile(true)}}
				onOpen={() => { toggleOpenFullProfile(true) }}
				onClose={() => { toggleOpenFullProfile(false) }}
			/> */}
		</Box>
	);
}
