import { Button, CircularProgress } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import theme from "../../constants/Theme";

const LoadingButton = ({
  loaderColor,
  loading,
  loadingText,
  children,
  loadingStyles,
  ...props
}) => {
  return (
    <Button
      {...props}
      sx={{
        ...props.sx,
        ...(loading && {
          ...loadingStyles,
        }),
      }}
      startIcon={!loading && props.startIcon}
    >
      {loading ? (
        <>
          <CircularProgress sx={{ color: loaderColor }} size={30} />{" "}
        </>
      ) : (
        children
      )}
    </Button>
  );
};

{
  LoadingButton.propTypes = {
    loading: PropTypes.bool,
    loaderColor: PropTypes.string,
    loadingText: PropTypes.string,
  };

  LoadingButton.defaultProps = {
    loading: false,
    loaderColor: theme.palette.secondary.white,
    loadingText: "",
  };
}
export default LoadingButton;
