export default function groupDays(data) {
  const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
  const cont = []
  const disCont = []

  for (let i = 0; i < data.length - 1; i++) {
    const index1 = weekDays.indexOf(data[i].timeList.l.slice(0, 3))
    const index2 = weekDays.indexOf(data[i + 1].timeList.l.slice(0, 3))

    if (index2 - index1 === 1) {
      cont.push(data[i].timeList.l.slice(0, 3))
      cont.push(data[i + 1].timeList.l.slice(0, 3))
    } else {
      if (!disCont.includes(data[i].timeList.l.slice(0, 3))) {
        disCont.push(data[i].timeList.l.slice(0, 3))
      }
      if (data[i + 1].timeList.l.slice(0, 3)) {
        disCont.push(data[i + 1].timeList.l.slice(0, 3))
      }
    }
  }
  const newCont = cont.filter((item, index) => cont.indexOf(item) === index);
  const newDisCont = disCont.filter(value => newCont.indexOf(value) == -1);
  var contStr = ''
  if (newDisCont.length > 0) {
    var contStr = newDisCont.toString() + ", "
  }
  if (newCont.length > 0) {
    if (newCont.length > 3) {
      var index = 0
      for (let i = 0; i < newCont.length - 1; i++) {
        const index1 = weekDays.indexOf(newCont[i])
        const index2 = weekDays.indexOf(newCont[i + 1])
        if (index2 - index1 === 1) {
          continue
        } else {
          index += 1
        }
      }
      if (index > 0) {
        contStr += `${newCont[0]}` + "-" + `${newCont[index]}` + ', ' + `${newCont[index + 1]}` + "-" + `${newCont[newCont.length - 1]}` + ', '
      } else {
        contStr += `${newCont[0]}` + "-" + `${newCont[newCont.length - 1]}` + ', '
      }
      index = 0
    } else {
      contStr += `${newCont[0]}` + "-" + `${newCont[newCont.length - 1]}` + ', '
    }
  }
  return contStr
}