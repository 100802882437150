import React from "react";
import { useHistory } from "react-router-dom";
import JsxParser from "react-jsx-parser";
import { Box, Typography, Avatar, styled } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ROUTES_PATH from "../../../../constants/routes";
import { getRoleValue } from "../../../../utils/commonFunctions";
import { MIGHTY_MENTOR, LOCATION_ICON_V2, GREY_BUILDING_ICON_V2, WATCH_ICON, PRODUCT_ICON } from "../../../../constants";
import UserProfileTagList from "../../../../common/CommonUserCardComponents/UserTagList";
import { STATES } from "../../../../constants/powerup-data";
import { accountProfileProgress } from "../../../../utils/profilePercentageCalculation";

const PowerBtn = styled(Box)`
	border: 1px solid #eee;
	border-radius: 20px;
	padding: 4px 8px;
	margin-bottom: 4px;
	font-size: 14px;
	font-weight: 500;
`;

const getJasonFormatedValue = (value) => {
	try {
		if (value) {
			let data = JSON.parse(value);
			if (data.length > 0) {
				return data;
			} else {
				return "No Data";
			}
		}
	} catch (e) {
		if (value) {
			let data = value.split(",");
			if (data.length > 0) {
				return data;
			} else {
				return "No Data";
			}
		} else {
			return "No Data";
		}
	}
};

export const MentorProfile = ({ profileData }) => {
	const history = useHistory();
	const expertise = getJasonFormatedValue(profileData?.expertise) ? getJasonFormatedValue(profileData?.expertise) : [];
	const superpower = getJasonFormatedValue(profileData?.superpower) ? getJasonFormatedValue(profileData?.superpower) : [];

	const statsTags = [
		{
			icon: LOCATION_ICON_V2,
			value: (() => {
				let city = profileData?.city;
				let area = STATES.find((s) => s.name === profileData?.state);
				area = area ? area?.abbreviation : profileData?.state;
				if (city && area) {
					return `${city}, ${area}`;
				} else if (!city && profileData?.state) {
					return profileData?.state;
				} else return "No Data";
			})(),
			isSvg: true,
			type: "Location",
		},
		{
			icon: WATCH_ICON,
			value: profileData?.timezone ? profileData?.timezone : "No Data",
			isSvg: true,
			type: "Time Zone",
		},
		{
			icon: GREY_BUILDING_ICON_V2,
			value: getJasonFormatedValue(profileData?.industries) ? getJasonFormatedValue(profileData?.industries) : "No Data",
			isSvg: true,
			type: "Industry",
		},
		{
			icon: PRODUCT_ICON,
			value: getJasonFormatedValue(profileData?.productOffering) ? getJasonFormatedValue(profileData?.productOffering) : "No Data",
			isSvg: true,
			type: "Product Offering",
		},
	];

	const handleProfile = () => {
		history.push(ROUTES_PATH.MY_MENTOR_PROFILE);
	};

	return (
		<Box
			sx={{
				backgroundColor: "#fff",
				borderRadius: "40px",
				boxShadow: "0px 10px 85px rgba(0, 0, 0, 0.09)",
				padding: "45px",
			}}
		>
			<Box display="flex" alignItems="center" gap={3} mb={3}>
				<Avatar
					src={profileData.picture}
					sx={{
						height: "120px",
						width: "120px",
					}}
				></Avatar>

				<Box display="flex" flexDirection="column">
					<Box display="flex" alignItems="center" gap={1} mb={1}>
						<Typography variant="growth_card_subtitle">{profileData.name}</Typography>
						<Typography variant="count_title">{`Progress ${Math.round(accountProfileProgress(profileData).totalPercentage || 0)}%`}</Typography>
						<ArrowForwardIcon sx={{ "&:hover": { cursor: "pointer" } }} onClick={handleProfile} />
					</Box>
					<Box display="flex" alignItems="center">
						<Avatar
							sx={{
								width: "30px",
								height: "30px",
								background: "#f7cf09",
								mr: 1,
							}}
							src={MIGHTY_MENTOR}
						/>
						<Typography variant="subhead5">
							{profileData?.isVerified ? "Verified Mentor" : getRoleValue(profileData?.userTypes && profileData?.userTypes[0])}
						</Typography>
					</Box>
				</Box>
			</Box>
			<Box mb={3}>
				<Typography variant="title_portfolio" sx={{ display: "block", mb: 0.5 }}>
					Summary
				</Typography>
				<Typography variant="subhead5">
					<JsxParser autoCloseVoidElements components={{ Typography }} jsx={profileData.about ? `${profileData.about}` : `No Data`} />
				</Typography>
			</Box>
			<Box mb={3}>
				<Typography variant="title_portfolio" sx={{ display: "block", mb: 0.5 }}>
					Superpowers & Expertise
				</Typography>
				<Box display="flex" flexWrap="wrap" gap={0.5}>
					{(() => {
						try {
							return expertise.concat(superpower).map((item) => (
								<PowerBtn key={item} sx={{ "&:hover": { cursor: "pointer" } }}>
									{item}
								</PowerBtn>
							));
						} catch (e) {
							console.log("Unable to parse JSON. Failed with error:", e);
						}
					})()}
				</Box>
			</Box>
			<Box display="flex" alignItems="flex-start" gap={2}>
				<Box>
					<Typography variant="title_portfolio" sx={{ mb: 1, display: "block" }}>
						Stats
					</Typography>
					<UserProfileTagList tagList={statsTags} />
				</Box>
				<Box>
					<Typography variant="title_portfolio" sx={{ mb: 1, display: "block" }}>
						My Bio
					</Typography>
					<Typography variant="subhead5">
						<JsxParser autoCloseVoidElements components={{ Typography }} jsx={profileData.about ? `${profileData.about}` : `No Data`} />
					</Typography>
				</Box>
			</Box>
		</Box>
	);
};
