export const terms = `
<div lang=EN-US link="#0563C1" vlink="#954F72" style='word-wrap:break-word'>

<div class=WordSection1>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
120%'><b><span style='font-size:16.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>Terms
of Service</span></b></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
120%'><i><span style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>Last
Modified: 26th of June, 2022</span></span></i></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
120%'><a name="_heading=h.1fob9te"></a><span style='font-size:11.0pt;
line-height:120%;font-family:"Quattrocento Sans",sans-serif'>Welcome to the
website and online services (collectively, our “<b>Service</b>”) of StartupOS,
Inc. (“<b>we</b>”, “<b>our</b>” or “<b>us</b>”). This page explains the terms
and conditions by which you may use our Service (the “<b>Terms</b>”). When you
access or use our Service, or by clicking a button or checking a box marked “I
Agree” (or something similar), you signify that you have read, understood, and
agree to be bound by these Terms whether or not you are a registered user of
our Service. You also acknowledge that you have read and understood how your
personal information will be collected, used, and shared, as set forth in our
Privacy Notice available at <a href="/privacy">https://app.startupos.com/privacy</a>
(“<b>Privacy Notice</b>”). We reserve the right to modify these Terms and will
provide notice of these changes as described below. These Terms apply to all
visitors, users, and others who access our Service (“<b>Users</b>”).</span></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
120%'><a name="_heading=h.gjdgxs"></a><b><span style='font-size:11.0pt;
line-height:120%;font-family:"Quattrocento Sans",sans-serif'>PLEASE READ THESE
TERMS CAREFULLY TO ENSURE THAT YOU UNDERSTAND EACH PROVISION AS THESE TERMS
CONTAIN COUNTRY-SPECIFIC TERMS.</span></b></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
120%'><b><span style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>IF
YOU ARE LOCATED IN THE UNITED STATES, THESE TERMS CONTAIN A MANDATORY
INDIVIDUAL ARBITRATION AGREEMENT IN SECTION 13.2 (THE “ARBITRATION AGREEMENT”)
AND CLASS ACTION/JURY TRIAL WAIVER PROVISION IN SECTION 13.3 (THE “CLASS
ACTION/JURY TRIAL WAIVER”) THAT REQUIRE, WITH ONLY SPECIFIED EXCEPTIONS IN
SECTIONS 13.1 AND 13.2 OR UNLESS YOU OPT OUT PURSUANT TO THE INSTRUCTIONS IN
SECTION 13.2, THE EXCLUSIVE USE OF FINAL AND BINDING ARBITRATION ON AN INDIVIDUAL
BASIS ONLY TO RESOLVE DISPUTES, RATHER THAN JURY TRIALS OR CLASS, COLLECTIVE,
PRIVATE ATTORNEY GENERAL OR REPRESENTATIVE ACTIONS OR PROCEEDINGS.</span></b></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
120%'><b><span style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>IF
YOU LOCATED IN CANADA, THESE TERMS CONTAIN AN INDIVIDUAL ARBITRATION AGREEMENT
IN SECTION 14.3 AND CLASS ACTION/JURY TRIAL WAIVER PROVISION IN SECTION 14.2
THAT RESTRICT THE MANNER IN WHICH YOU MAY RESOLVE DISPUTES WITH US. YOU MAY OPT
OUT OF THE ARBITRATION AGREEMENT PURSUANT TO SECTION 14.5.</span></b></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
120%'><a name="_heading=h.2et92p0"></a><span style='font-size:11.0pt;
line-height:120%;font-family:"Quattrocento Sans",sans-serif'>Please note that
if you are an individual and you access or use our Service on behalf of a
company or other entity, such as your employer (together with its affiliates,
an “<b>Organization</b>”), then: (a) these Terms are an agreement between us
and you and us and that Organization; (b) you represent and warrant that you
have the authority to bind that Organization to these Terms (and if you do not
have that authority, you may not access or use our Service); (c) your
acceptance of these Terms will bind such Organization to these Terms; (d) your
individual right to access and use our Service may be suspended or terminated
(and ownership and administration of your Service Account may be transferred)
if you cease to be associated with, or cease to use an email address associated
with or provisioned by, that Organization; (e) we may disclose information
regarding you and your use of our Service with such Organization; and (f) the
terms “you” and “your”, as used in these Terms, refer to both you and such
Organization. If you sign up for our Service using an email address associated with
or provisioned by an Organization, or if an Organization pays fees due in
connection with your access to or use of our Service (or reimburses you for
payment of such fees), or otherwise, then, we may deem you to be accessing and
using our Service on behalf of that Organization in our sole discretion.  </span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:0in;text-align:justify;text-indent:0in;line-height:120%;page-break-after:
avoid'><span style='font-size:11.0pt;line-height:120%;color:black'>1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><u><span style='font-size:11.0pt;line-height:120%;font-family:
"Quattrocento Sans",sans-serif'>How We Administer our Service</span></u></b><b><span
style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>.</span></b></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:0in;text-align:justify;text-indent:.5in;line-height:120%;
page-break-after:avoid'><span style='font-size:11.0pt;line-height:120%;
font-family:"Quattrocento Sans",sans-serif;color:black'>1.1<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span style='font-size:11.0pt;line-height:120%;font-family:
"Quattrocento Sans",sans-serif'>Eligibility.</span></b></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
120%'><span style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>You
may use our Service only if you can form a legally binding contract with us
(and on behalf of your Organization, as applicable), and only in compliance
with these Terms and all applicable local, state, provincial, national and
international laws, rules and regulations</span><span style='font-size:11.0pt;
line-height:120%;font-family:"Quattrocento Sans",sans-serif'>. To use our
Service, you must be at least eighteen (18) years of age. You represent and
warrant that you meet the applicable age requirements and are competent to
agree to these Terms. </span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:0in;text-align:justify;text-indent:.5in;line-height:120%;
page-break-after:avoid'><span style='font-size:11.0pt;line-height:120%;
font-family:"Quattrocento Sans",sans-serif;color:black'>1.2<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span style='font-size:11.0pt;line-height:120%;font-family:
"Quattrocento Sans",sans-serif'>Service Accounts.</span></b></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
120%'><span style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>Your
account on our Service (your “<b>Service Account</b>”) gives you access to our
Service that we may establish and maintain from time to time. We may maintain
different types of Service Accounts for different types of users. You
acknowledge that you do not own your Service Account. </span></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
120%'><span style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>You
may not use another User’s Service Account without such User’s permission. You
are solely responsible for the activity that occurs on your Service Account,
and you must keep your Service Account password(s) strong and secure. You
should notify us immediately of any breach of security or unauthorized use of
your Service Account. Any individual with administrator-level access to your
Service Account can modify your Service Account settings, access and billing
information. We will not be liable for any losses caused by any unauthorized
use of your Service Account, or for any changes to your Service Account,
including your ability to access your Service Account or Your Content (defined
below), made by any individual with administrator-level access to your Service
Account.  </span></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
120%'><span style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>You
may control certain aspects of your Service Account profile and how you
interact with our Service by changing the settings in your settings page. By
providing us with your email address, you consent to our using the email
address to send you Service-related notices, including any notices required by
law, in lieu of communication by postal mail. We may also use your email
address to send you other marketing or advertising messages, such as changes to
features of our Service and special offers. If you do not want to receive such
email messages, you may opt out or change your preferences by contacting our
Service support team at support@startupos.com or by clicking the unsubscribe
link within each marketing or advertising message. Opting out will not prevent
you from receiving Service-related notices.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:0in;text-align:justify;text-indent:.5in;line-height:120%;
page-break-after:avoid'><a name="_heading=h.brfrnylrxy3x"></a><span
style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif;
color:black'>1.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span style='font-size:11.0pt;line-height:120%;font-family:
"Quattrocento Sans",sans-serif'>Changes, Suspension, and Termination.</span></b><span
style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>
</span></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
120%;page-break-after:avoid'><span style='font-size:11.0pt;line-height:120%;
font-family:"Quattrocento Sans",sans-serif'>You may cancel your Service Account
at any time. We may change our Service, stop providing our Service or features
of our Service to you or to our users generally, or create usage limits for our
Service. We may permanently or temporarily terminate or suspend your access to
our Service without notice and liability, without cause or for any reason,
including if in our sole determination you violate any provision of these
Terms. Upon termination, you continue to be bound by these Terms. </span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:0in;text-align:justify;text-indent:.5in;line-height:120%;
page-break-after:avoid'><span style='font-size:11.0pt;line-height:120%;
font-family:"Quattrocento Sans",sans-serif;color:black'>1.4<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span style='font-size:11.0pt;line-height:120%;font-family:
"Quattrocento Sans",sans-serif'>Your Interaction with Other Users.</span></b><span
style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>
</span></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
120%;border:none'><span style='font-size:11.0pt;line-height:120%;font-family:
"Quattrocento Sans",sans-serif;font-variant:small-caps;color:black'>YOU ARE
SOLELY RESPONSIBLE FOR YOUR INTERACTIONS AND SHARING OF INFORMATION WITH OTHER
USERS. WE RESERVE THE RIGHT, BUT HAVE NO OBLIGATION, TO MONITOR DISPUTES
BETWEEN YOU AND OTHER USERS. WE EXPRESSLY DISCLAIM ALL LIABILITY ARISING FROM
YOUR INTERACTIONS WITH OTHER USERS, OR FOR ANY USER’S ACTION OR INACTION,
INCLUDING RELATING TO USE OF YOUR CONTENT.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:0in;text-align:justify;text-indent:0in;line-height:120%;border:
none'><span style='font-size:11.0pt;line-height:120%;color:black'>2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><u><span style='font-size:11.0pt;line-height:120%;font-family:
"Quattrocento Sans",sans-serif;color:black'>What Is Included in Your Service Subscription,
and What are the Restrictions</span></u></b><b><span style='font-size:11.0pt;
line-height:120%;font-family:"Quattrocento Sans",sans-serif;color:black'>.<u> </u></span></b></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:0in;text-align:justify;text-indent:.5in;line-height:120%'><span
style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif;
color:black'>2.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span style='font-size:11.0pt;line-height:120%;font-family:
"Quattrocento Sans",sans-serif'>Access to our Service. </span></b></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
120%'><span style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>Subject
to your compliance with these Terms and during the applicable Subscription
Term, you may access and use our Service for your own business purposes or
personal use, as applicable, except as may be limited by your Organization as
described above, all in accordance with these Terms and associated
documentation we provide you. </span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:0in;text-align:justify;text-indent:.5in;line-height:120%'><span
style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif;
color:black'>2.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span style='font-size:11.0pt;line-height:120%;font-family:
"Quattrocento Sans",sans-serif'>Software License.</span></b><span
style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>
</span></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
120%'><span style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>To
the extent you receive any software from us in connection with our Service (“<b>Software</b>”),
subject to your compliance with these Terms, we grant to you a non-exclusive,
non-transferable, non-sublicensable right and license to use our Software
solely as reasonably necessary for your use of our Service in accordance with
these Terms.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:0in;text-align:justify;text-indent:.5in;line-height:120%'><span
style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif;
color:black'>2.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span style='font-size:11.0pt;line-height:120%;font-family:
"Quattrocento Sans",sans-serif'>Restrictions and Acceptable Use.</span></b></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
120%'><span style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>Except
to the extent a restriction is prohibited by law, you agree not to do, and not
to assist, permit or enable any third party to do, any of the following:</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:1.0in;text-align:justify;text-indent:-.5in;line-height:120%'><span
style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif;
color:black'>(a)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>disassemble,
reverse engineer, decode or decompile any part of our Software or Service;</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:1.0in;text-align:justify;text-indent:-.5in;line-height:120%'><span
style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif;
color:black'>(b)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>use
any robot, spider, scraper, data mining tool, data gathering or extraction
tool, or any other automated means, to access, collect, copy or record our
Service;</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:1.0in;text-align:justify;text-indent:-.5in;line-height:120%'><span
style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif;
color:black'>(c)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>copy,
rent, lease, sell, loan, transfer, assign, sublicense, resell, distribute,
modify, alter or create derivative works of any part of our Software, Service
or any of our Intellectual Property (defined below);</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:1.0in;text-align:justify;text-indent:-.5in;line-height:120%'><span
style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif;
color:black'>(d)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>use
our Service in any manner that impacts (i) the stability of our servers, (ii)
the operation or performance of our Service or any User’s use of our Service,
or (iii) the behavior of other applications using our Service;</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:1.0in;text-align:justify;text-indent:-.5in;line-height:120%'><span
style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif;
color:black'>(e)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>use
our Software or Service in any manner or for any purpose that (i) violates or
promotes the violation of any applicable law, regulation, legal requirement,
contractual obligation or right of any person including, but not limited to,
intellectual property rights, rights of privacy, or rights of personality, (ii)
is fraudulent, false, deceptive or defamatory, (iii) promotes hatred, violence
or harm against any individual or group, or (iv) otherwise may be harmful or
objectionable (in our sole discretion) to us, our providers, our suppliers, our
Users, or any other third party; </span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:1.0in;text-align:justify;text-indent:-.5in;line-height:120%'><span
style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif;
color:black'>(f)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>use
or display our Software or Service in competition with us, to develop competing
products or services, for benchmarking or competitive analysis of our Service,
or otherwise to our detriment or disadvantage; </span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:1.0in;text-align:justify;text-indent:-.5in;line-height:120%'><span
style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif;
color:black'>(g)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>attempt
to interfere with, compromise the system integrity or security of, or decipher
any transmissions to or from, the servers running our Service; </span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:1.0in;text-align:justify;text-indent:-.5in;line-height:120%'><span
style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif;
color:black'>(h)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>transmit
viruses, worms or other software agents through our Service; </span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:1.0in;text-align:justify;text-indent:-.5in;line-height:120%'><span
style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif;
color:black'>(i)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>impersonate
another person or misrepresent your affiliation with a person or entity, hide
or attempt to hide your identity, or otherwise use our Service for any invasive
or fraudulent purpose; </span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:1.0in;text-align:justify;text-indent:-.5in;line-height:120%'><span
style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif;
color:black'>(j)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>share
passwords or authentication credentials for our Software or Service, or
otherwise circumvent the measures we may use to prevent or restrict access to
our Software or Service or enforce limitations on use of our Service; or</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:1.0in;text-align:justify;text-indent:-.5in;line-height:120%'><span
style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif;
color:black'>(k)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>identify
or refer to us or our Service in a manner that could reasonably imply an
endorsement, relationship or affiliation with or sponsorship between you (or a
third party) and us, without our prior express written consent.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:0in;text-align:justify;text-indent:0in;line-height:120%;page-break-after:
avoid;border:none'><span style='font-size:11.0pt;line-height:120%;color:black'>3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><u><span style='font-size:11.0pt;line-height:120%;font-family:
"Quattrocento Sans",sans-serif;color:black'>Terms Applying to Your Content</span></u></b><b><span
style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif;
color:black'>.</span></b></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
120%'><a name="_heading=h.3znysh7"></a><span style='font-size:11.0pt;
line-height:120%;font-family:"Quattrocento Sans",sans-serif'>As between us and
you, you (or your licensors) will own any and all information, data, and other
content, in any form or medium, that is collected, downloaded, or otherwise
received, directly or indirectly, from you (or on your behalf) by or through
our Service (“<b>Your Content</b>”).  For an Organizational account, we may
assume, in our sole discretion, that all of Your Content belongs to that
Organization.  </span></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
120%'><span style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>WE
CLAIM NO OWNERSHIP RIGHTS OVER YOUR CONTENT. However, you understand that
certain portions of our Service may allow other Users to view, edit, share,
and/or otherwise interact with Your Content. By providing or sharing Your
Content through our Service, you agree to allow others to view, edit, share,
and/or interact with Your Content in accordance with your settings,
confidentiality markings, and these Terms. You agree to mark any sensitive or
proprietary content as confidential prior to making such content available to
any other User. We have the right (but not the obligation) in our sole
discretion to remove any of Your Content that is shared via our Service. You
hereby grant each User a non-exclusive license to access Your Content through
our Service, and to use, reproduce, distribute, display and perform Your
Content, which you make available to such User through our Service.</span></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
120%'><span style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>You
further grant, and you represent and warrant that you have all rights necessary
to grant, to us an irrevocable, perpetual, transferable, sublicensable (through
multiple tiers), fully paid, royalty-free, and worldwide right and license to
use, copy, store, modify, distribute and display Your Content: (a) to maintain
and provide our Service; (b) solely in <a>anonymized  </a></span><span
class=MsoCommentReference><span style='font-size:9.0pt;line-height:120%;
font-family:"Arial",sans-serif'><a class=msocomanchor id="_anchor_1"
onmouseover="msoCommentShow('_anchor_1','_com_1')"
onmouseout="msoCommentHide('_com_1')" href="#_msocom_1" language=JavaScript
name="_msoanchor_1">[1]</a>&nbsp;</span></span><span style='font-size:11.0pt;
line-height:120%;font-family:"Quattrocento Sans",sans-serif'>form, to improve
our products and services and for our other business purposes, such as data
analysis, customer research, developing new products or features, and
identifying usage trends (and we will own such de-identified data); and (c) to
perform such other actions as described in our Privacy Notice or as authorized
by you in connection with your use of our Service.</span></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
120%'><span style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>In
connection with Your Content, you affirm, represent and warrant the following:</span></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
120%'><span style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>•           You
have the written consent of each and every identifiable natural person in Your
Content, if any, to use such person’s name or likeness in the manner
contemplated by our Service and these Terms, and each such person has released
you from any liability that may arise in relation to such use;</span></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
120%'><span style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>•           You
have obtained and are solely responsible for obtaining all consents as may be
required by law to post any of Your Content relating to third parties;</span></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
120%'><span style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>•           Your
Content and our use thereof as contemplated by these Terms and our Service will
not violate any law or infringe any rights of any third party, including but
not limited to any intellectual property rights and privacy rights;</span></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
120%'><span style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>•           Your
Content does not include any information or material that a governmental body
deems to be sensitive or classified information, and by providing Your Content
to or through our Service, you are not violating the confidentiality rights of
any third party; and</span></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
120%'><span style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>•           We
may exercise the rights to Your Content granted under these Terms without
liability for payment of any fees or royalties.</span></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
120%'><span style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif;
font-variant:small-caps'>WE TAKE NO RESPONSIBILITY AND ASSUME NO LIABILITY FOR
ANY OF YOUR CONTENT THAT YOU OR ANY OTHER USER OR THIRD-PARTY POSTS, SENDS, OR
OTHERWISE MAKES AVAILABLE OVER OUR SERVICE. YOU SHALL BE SOLELY RESPONSIBLE FOR
YOUR CONTENT AND THE CONSEQUENCES OF POSTING, PUBLISHING IT, SHARING IT, OR
OTHERWISE MAKING IT AVAILABLE ON OUR SERVICE, AND YOU AGREE THAT WE ARE ONLY
ACTING AS A PASSIVE CONDUIT FOR YOUR ONLINE DISTRIBUTION AND PUBLICATION OF
YOUR CONTENT. YOU UNDERSTAND AND AGREE THAT ON OUR SERVICE YOU MAY BE EXPOSED
TO CONTENT THAT IS INACCURATE, OBJECTIONABLE, INAPPROPRIATE FOR CHILDREN, OR
OTHERWISE UNSUITED TO YOUR PURPOSE, AND YOU AGREE THAT WE SHALL NOT BE LIABLE
FOR ANY DAMAGES YOU ALLEGE TO INCUR AS A RESULT OF OR RELATING TO ANY CONTENT
ACCESSED ON OR THROUGH OUR SERVICE.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:0in;text-align:justify;text-indent:0in;line-height:120%;page-break-after:
avoid'><span style='font-size:11.0pt;line-height:120%;color:black'>4.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><u><span style='font-size:11.0pt;line-height:120%;font-family:
"Quattrocento Sans",sans-serif'>Our Intellectual Property</span></u></b><b><span
style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>.</span></b></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
120%'><span style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>You
acknowledge and agree that our Service, and all materials and content displayed
or made available on our Service, other than Your Content, and all software,
algorithms, code, technology and intellectual property underlying and included
in or with our Service, and all intellectual property rights therein and
thereto throughout the world (collectively and individually, our “<b>Intellectual
Property</b>”), are our (or our licensors’ as applicable) sole and exclusive
property. Except as explicitly provided herein, nothing in these Terms will be
deemed to create a license in or under any intellectual property rights, and
you agree not to access, sell, license, rent, modify, distribute, copy,
reproduce, transmit, publicly display, publicly perform, publish, adapt, edit
or create derivative works from any of our Intellectual Property.  </span></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
120%'><span style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>You
may choose to, or we may invite you to submit, comments, feedback or ideas
about our Service, including without limitation about how to improve our
Service or our products (“<b>Feedback</b>”). By submitting any Feedback, you
agree that we will own such Feedback, and that we are free to use the Feedback
without any additional compensation to you, and/or to disclose the Feedback on
a non-confidential basis or otherwise to anyone. You further acknowledge that,
by acceptance of your submission, we do not waive any rights to use similar or
related ideas previously known to us, or developed by our employees, or
obtained from sources other than you.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:0in;text-align:justify;text-indent:0in;line-height:120%'><span
style='font-size:11.0pt;line-height:120%;color:black'>5.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><u><span style='font-size:11.0pt;line-height:120%;font-family:
"Quattrocento Sans",sans-serif'>Our Privacy and Data Security Policies</span></u></b><b><span
style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>.</span></b></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:0in;text-align:justify;text-indent:.5in;line-height:120%'><span
style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif;
color:black'>5.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><u><span style='font-size:11.0pt;line-height:120%;font-family:
"Quattrocento Sans",sans-serif'>Privacy</span></u></b><b><span
style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>.</span></b><span
style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>
</span></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
120%'><span style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>We
care about your privacy. By using our Service you acknowledge that we may
collect, use, and disclose your personal information and aggregate and/or
anonymized data as set forth in our Privacy Notice, available at <a href="/privacy">https://app.startupos.com/privacy</a>, and acknowledge that you may have
your personal information collected, used, <a>transferred to and processed in
the United States</a></span><span class=MsoCommentReference><span
style='font-size:9.0pt;line-height:120%;font-family:"Arial",sans-serif'><a
class=msocomanchor id="_anchor_2"
onmouseover="msoCommentShow('_anchor_2','_com_2')"
onmouseout="msoCommentHide('_com_2')" href="#_msocom_2" language=JavaScript
name="_msoanchor_2">[2]</a>&nbsp;</span></span><span style='font-size:11.0pt;
line-height:120%;font-family:"Quattrocento Sans",sans-serif'>.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:0in;text-align:justify;text-indent:.5in;line-height:120%'><span
style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif;
color:black'>5.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><u><span style='font-size:11.0pt;line-height:120%;font-family:
"Quattrocento Sans",sans-serif'>Security</span></u></b><b><span
style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>.</span></b><span
style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>
</span></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
120%'><span style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>We
have implemented commercially reasonable technical and organizational measures
designed to secure your data and information from accidental loss and from
unauthorized access, use, alteration or disclosure. However, we cannot
guarantee that unauthorized third parties will never be able to defeat those
measures or use your data for improper purposes. You understand that internet
technologies have the inherent potential for disclosure. You acknowledge that
you provide your data at your own risk.  </span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:0in;text-align:justify;text-indent:0in;line-height:120%;border:
none'><span style='font-size:11.0pt;line-height:120%;color:black'>6.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><u><span style='font-size:11.0pt;line-height:120%;font-family:
"Quattrocento Sans",sans-serif;color:black'>Payments, Billing and Subscription
Plans</span></u></b><b><span style='font-size:11.0pt;line-height:120%;
font-family:"Quattrocento Sans",sans-serif;color:black'>.</span></b></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:0in;text-align:justify;text-indent:.5in;line-height:120%;
border:none'><span style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif;
color:black'>6.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span style='font-size:11.0pt;line-height:120%;font-family:
"Quattrocento Sans",sans-serif;color:black'>How We Bill for Our Service. </span></b></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
120%;border:none'><span style='font-size:11.0pt;line-height:120%;font-family:
"Quattrocento Sans",sans-serif;color:black'>Certain aspects of our Service may
be provided for free, while certain other aspects of our Service or products
available on our website may be provided for a fee or other charge. If you
elect to use paid aspects of our Service or make a purchase on our website, you
agree to our payment terms set forth herein and on our website at <a href="/terms">https://app.startupos.com/terms</a>, as we may update them from time
to time. We may add new services for additional fees and charges, add or amend
fees and charges for existing services, at any time in our sole discretion. </span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:0in;text-align:justify;text-indent:.5in;line-height:120%;
border:none'><span style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif;
color:black'>6.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span style='font-size:11.0pt;line-height:120%;font-family:
"Quattrocento Sans",sans-serif;color:black'>Payment Information; Payment
Method; Taxes. </span></b></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
120%;border:none'><span style='font-size:11.0pt;line-height:120%;font-family:
"Quattrocento Sans",sans-serif;color:black'>All information that you provide in
connection with a purchase or transaction or other monetary transaction
interaction with our Service must be accurate, complete, and current. You agree
to pay all charges incurred by users of your credit card, debit card, or other
payment method (“<b>Payment Method</b>”) used in connection with a purchase or
transaction or other monetary transaction interaction with our Service at the
prices in effect when such charges are incurred. You must provide us with a
current, valid, accepted Payment Method. When you initiate a purchase
transaction, you authorize us to provide your payment information to third
parties so we can complete your transaction and to charge your Payment Method,
in United States dollars, for the type of transaction you have selected (plus
any applicable taxes and other charges) and any applicable recurring charges as
described below. We currently use Stripe as our third party service provider for
payment services, and by using our Service you agree to be bound by Stripe’s
Services Agreement, available at </span><a href="https://stripe.com/us/legal"><span
style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif;
color:#0563C1'>https://stripe.com/us/legal</span></a><span style='font-size:
11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif;color:black'>.
If your payment is not successfully settled for any reason, you remain
responsible for any amounts not remitted to us. All payments for transactions
are non-refundable and non-transferable except as expressly provided in these
Terms. You will pay any applicable taxes, if any, relating to any such
purchases, transactions or other monetary transaction interactions.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:0in;text-align:justify;text-indent:.5in;line-height:120%;
border:none'><span style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif;
color:black'>6.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span style='font-size:11.0pt;line-height:120%;font-family:
"Quattrocento Sans",sans-serif;color:black'>Subscription Plans.</span></b><span
style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif;
color:black'>  </span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:0in;text-align:justify;text-indent:1.0in;line-height:120%'><span
style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif;
color:black'>(a)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><u><span style='font-size:11.0pt;line-height:120%;font-family:
"Quattrocento Sans",sans-serif'>Subscription Products; Subscription Fees</span></u><span
style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>.
We may make certain portions of our Service available on an automatically
renewing subscription basis, such as Service Account upgrades (the “<b>Subscription
Services</b>”) for recurring fees (“<b>Subscription Fees</b>”). For the most
current information about our Subscription Fees, please review our </span><b><span
style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>Pricing
and Payment Terms</span></b><span style='font-size:11.0pt;line-height:120%;
font-family:"Quattrocento Sans",sans-serif'> at <a href="/terms">https://app.startupos.com/terms</a> which are incorporated by reference herein. We may make any
available Service on a subscription basis, discontinue subscriptions for
Subscription Services, or add or amend the Subscription Fees at our sole
discretion. When we add or amend the Subscription Fees, we will update our
Pricing and Payment Terms. Any change to our Pricing and Payment Terms shall
become effective in the Subscription Term (as defined below) following notice
of such change to you as provided in this Agreement; <i>provided however</i>,
that if we have offered a specific duration and Subscription Fees for your use
of the Subscription Products, we agree that the Subscription Fees will remain
in force for that duration.  YOUR SUBSCRIPTION TO THE SUBSCRIPTION PRODUCTS
WILL AUTOMATICALLY RENEW AT THE END OF YOUR SUBSCRIPTION TERM IDENTIFIED IN
YOUR ORDER FOR SUBSEQUENT TERMS EQUAL IN LENGTH TO THE INITIAL SUBSCRIPTION TERM
(EACH A “<b>SUBSCRIPTION TERM</b>”) UNLESS AND UNTIL YOU CANCEL YOUR
SUBSCRIPTION PRODUCTS IN ACCORDANCE WITH THE CANCELLATION PROCEDURES IDENTIFIED
IN SECTION 6.3(d) OF THIS AGREEMENT.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:0in;text-align:justify;text-indent:1.0in;line-height:120%'><span
style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif;
color:black'>(b)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><u><span style='font-size:11.0pt;line-height:120%;font-family:
"Quattrocento Sans",sans-serif'>Automatic Billing and Policies</span></u><span
style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>.
When you purchase Subscription Services, you expressly acknowledge and agree
that: (1) we and/or our third-party payment processors are authorized to charge
you at the beginning of each Subscription Term the Subscription Fees for your
Subscription Services identified when you sign-up, any applicable taxes, and
any other charges you may incur in connection with your purchase and use of the
Subscription Services, subject to adjustment in accordance with the terms and
conditions of this Agreement, for as long as your subscription continues; and
(2) your subscription is continuous until you cancel it or the Subscription
Services or your subscription to the Subscription Services is suspended,
discontinued or terminated in accordance with this Agreement. You acknowledge
and agree that the amount billed may vary due to promotional offers, changes in
the Subscription Fees for Subscription Services in accordance with Section
6.3(a), and changes in applicable taxes, and you authorize us to charge your
payment method for the changed amounts.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:0in;text-align:justify;text-indent:1.0in;line-height:120%'><span
style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif;
color:black'>(c)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><u><span style='font-size:11.0pt;line-height:120%;font-family:
"Quattrocento Sans",sans-serif'>Cancellation Procedure</span></u><span
style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>.
To cancel your subscription to the Subscription Services, you must notify </span><span
style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>us
at least thirty (30) days before the start of the next Subscription Term using
the appropriate functionalities of our Service or by contacting us at support@startupos.com. 
You will continue to have access to the Subscription Services through the end
of your billing period.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:0in;text-align:justify;text-indent:1.0in;line-height:120%'><span
style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif;
color:black'>(d)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><u><span style='font-size:11.0pt;line-height:120%;font-family:
"Quattrocento Sans",sans-serif'>Cancellation</span></u><span style='font-size:
11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>. You may
cancel your subscription to the Subscription Services at your sole discretion;
however, we grant refunds for cancellation at our sole discretion and you
acknowledge and agree that you may not be refunded any Subscription Fees for
your cancellation of your subscription to the Subscription Services, including
without limitation, for any unused time with your Subscription Services.<b> <span
style='font-variant:small-caps'>IN THE EVENT THAT: (A) WE SUSPEND OR TERMINATE
YOUR SUBSCRIPTION TO THE SUBSCRIPTION SERVICES, YOUR SERVICE ACCOUNT OR THIS
AGREEMENT FOR YOUR BREACH OF THIS AGREEMENT; OR (B) YOU CANCEL YOUR
SUBSCRIPTION TO THE SUBSCRIPTION PRODUCTS, YOU UNDERSTAND AND AGREE THAT YOU
SHALL RECEIVE NO REFUND FOR ANY UNUSED TIME ON YOUR SUBSCRIPTION</span> OR ANY
PRE-PAYMENTS MADE FOR YOUR SUBSCRIPTION PRODUCTS.</b></span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:0in;text-align:justify;text-indent:.5in;line-height:120%;
border:none'><span style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif;
color:black'>6.4<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span style='font-size:11.0pt;line-height:120%;font-family:
"Quattrocento Sans",sans-serif;color:black'>Promotional Offers. </span></b></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
120%;border:none'><span style='font-size:11.0pt;line-height:120%;font-family:
"Quattrocento Sans",sans-serif;color:black'>We may from time to time offer
special promotional offers, plans or memberships (“<b>Promotional Offers</b>”).
Promotional Offer eligibility is determined by us in our sole discretion and we
reserve the right to revoke a Promotional Offer and put your account on hold in
the event that we determine you are not eligible. We may use information such
as device ID, method of payment or an account email address used with an
existing or recent subscription to determine eligibility. The eligibility
requirements and other limitations and conditions will be disclosed when you
sign-up for the Promotional Offer or in other communications made available to
you. You acknowledge and agree that any offers made available through our
Service, including without limitation offers for the Subscription Services, are
subject to change at any time and from time to time.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:0in;text-align:justify;text-indent:0in;line-height:120%;page-break-after:
avoid;border:none'><span style='font-size:11.0pt;line-height:120%;color:black'>7.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><u><span style='font-size:11.0pt;line-height:120%;font-family:
"Quattrocento Sans",sans-serif;color:black'>Additional Terms for Mobile
Applications</span></u></b><b><span style='font-size:11.0pt;line-height:120%;
font-family:"Quattrocento Sans",sans-serif;color:black'>.</span></b></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:0in;text-align:justify;text-indent:.5in;line-height:120%;
border:none'><span style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif;
color:black'>7.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span style='font-size:11.0pt;line-height:120%;font-family:
"Quattrocento Sans",sans-serif;color:black'>Mobile Applications.</span></b><span
style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif;
color:black'> </span></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
120%;border:none'><span style='font-size:11.0pt;line-height:120%;font-family:
"Quattrocento Sans",sans-serif;color:black'>We may make available Software to
access our Service via a compatible mobile device (“<b>Mobile Applications</b>”).
You may incur mobile data charges from your wireless provider in connection
with the Mobile Applications, and you agree that you are solely responsible for
any such charges. We grant you a non-exclusive, non-transferable, revocable
license to use a compiled code copy of the Mobile Applications for your User
Account on one or more mobile devices owned or leased solely by you, solely in
accordance with these Terms. <a>You acknowledge that we may from time to time
issue upgraded versions of the Mobile Applications and may automatically
electronically upgrade the version of the Mobile Applications that you are
using on your mobile device. You consent to such automatic upgrading on your
mobile device and agree that the terms and conditions of these Terms will apply
to all such upgrades</a></span><span class=MsoCommentReference><span
style='font-size:9.0pt;line-height:120%;font-family:"Arial",sans-serif'><a
class=msocomanchor id="_anchor_3"
onmouseover="msoCommentShow('_anchor_3','_com_3')"
onmouseout="msoCommentHide('_com_3')" href="#_msocom_3" language=JavaScript
name="_msoanchor_3">[3]</a>&nbsp;</span></span><span style='font-size:11.0pt;
line-height:120%;font-family:"Quattrocento Sans",sans-serif;color:black'>. Any
third-party code that may be incorporated in the Mobile Applications is covered
by the applicable open source or third-party license, if any, authorizing use
of such code. The foregoing license grant is not a sale of the Mobile
Applications or any copy thereof. We or our third-party partners or suppliers
retain all right, title, and interest in the Mobile Applications (and any copy
thereof). Any attempt by you to transfer any of the rights, duties or
obligations hereunder, except as expressly provided for in these Terms, is
void.<b>  </b></span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:0in;text-align:justify;text-indent:.5in;line-height:120%;
border:none'><span style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif;
color:black'>7.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span style='font-size:11.0pt;line-height:120%;font-family:
"Quattrocento Sans",sans-serif;color:black'>App Store Terms.</span></b><span
style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif;
color:black'> </span></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
120%;border:none'><span style='font-size:11.0pt;line-height:120%;font-family:
"Quattrocento Sans",sans-serif;color:black'>If you acquire any Mobile
Applications from any third-party app store: (i) you acknowledge that these
Terms are between you and us only, and not with such third party; (ii) your use
of such Mobile Applications must comply with such third party’s then-current
app store terms and conditions; (iii) such third party is only a provider of
the app store where you obtained such Mobile Applications; (iv) we, and not
such third party, are solely responsible for our Mobile Applications; (v) such
third-party has no obligation or liability to you with respect to such Mobile
Applications or these Terms; and (vi) you acknowledge and agree that such
third-party is a third-party beneficiary to these Terms as it relates to such
Mobile Applications.<b> </b></span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:0in;text-align:justify;text-indent:0in;line-height:120%;page-break-after:
avoid'><span style='font-size:11.0pt;line-height:120%;color:black'>8.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><u><span style='font-size:11.0pt;line-height:120%;font-family:
"Quattrocento Sans",sans-serif'>Your Use of Third-Party Services</span></u></b><b><span
style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>.</span></b></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
120%'><span style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif;
font-variant:small-caps'>OUR SERVICE MAY CONTAIN LINKS TO, OR ENABLE YOU TO
RECEIVE SERVICES FROM, OR COMMUNICATE WITH, THIRD-PARTY PROVIDERS, SITES,
MATERIALS OR SERVICES (“<b>THIRD-PARTY SERVICES</b>”) THAT ARE NOT AFFILIATED
WITH OR OWNED OR CONTROLLED BY US, AND CERTAIN FUNCTIONALITY OF OUR SERVICE MAY
REQUIRE YOUR USE OF THIRD-PARTY SERVICES. IF YOU USE A THIRD-PARTY SERVICE, YOU
ARE SUBJECT TO AND AGREE TO THE THIRD PARTY’S TERMS AND CONDITIONS AND PRIVACY
POLICY MADE AVAILABLE ON OR AGREED IN CONNECTION WITH THEIR SERVICES. WE DO NOT
ENDORSE OR ASSUME ANY RESPONSIBILITY FOR ANY SUCH THIRD-PARTY SERVICES. IF YOU
ACCESS A THIRD-PARTY SERVICE FROM OUR SERVICE OR SHARE YOUR CONTENT ON OR
THROUGH ANY THIRD-PARTY SERVICE, YOU DO SO AT YOUR OWN RISK, AND YOU UNDERSTAND
THAT THESE TERMS AND OUR PRIVACY POLICY DO NOT APPLY TO YOUR USE OF SUCH
THIRD-PARTY SERVICES. YOU EXPRESSLY RELIEVE US FROM ANY AND ALL LIABILITY
ARISING FROM YOUR USE OF ANY THIRD-PARTY SERVICE, INCLUDING WITHOUT LIMITATION
CONTENT SUBMITTED BY OTHER USERS. ADDITIONALLY, YOUR DEALINGS WITH OR
PARTICIPATION IN PROMOTIONS OF ADVERTISERS FOUND ON OUR SERVICE, INCLUDING
PAYMENT AND DELIVERY OF GOODS, AND ANY OTHER TERMS (SUCH AS WARRANTIES) ARE
SOLELY BETWEEN YOU AND SUCH ADVERTISERS. YOU AGREE THAT WE SHALL NOT BE
RESPONSIBLE FOR ANY LOSS OR DAMAGE OF ANY SORT RELATING TO YOUR DEALINGS WITH
SUCH ADVERTISERS.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:0in;text-align:justify;text-indent:0in;line-height:120%;page-break-after:
avoid'><span style='font-size:11.0pt;line-height:120%;color:black'>9.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><u><span style='font-size:11.0pt;line-height:120%;font-family:
"Quattrocento Sans",sans-serif'>Indemnity</span></u></b><b><span
style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>.</span></b></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
120%'><span style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>You
agree to defend, indemnify and hold us and our affiliates, agents, suppliers or
licensors (and our and their employees, contractors, agents, officers and
directors) harmless from and against any and all claims, damages, obligations,
losses, liabilities, costs or debt, and expenses (including but not limited to
legal fees) arising from: (a) your access to or use of our Service; (b) your violation
of any aspect of these Terms, including without limitation your breach of any
of your representations and warranties; (c) your violation of any third-party
right, including without limitation any right of privacy or intellectual
property rights; (d) your violation of any applicable law, rule or regulation;
(e) Your Content, including without limitation any misleading, false, or
inaccurate information in Your Content; (f) your willful misconduct; or (g) any
third party’s access to or use of our Service with your username(s),
password(s) or other security code(s).</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:0in;text-align:justify;text-indent:0in;line-height:120%;page-break-after:
avoid'><span style='font-size:11.0pt;line-height:120%;color:black'>10.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><u><span style='font-size:11.0pt;line-height:120%;font-family:
"Quattrocento Sans",sans-serif'>No Warranty; Disclaimers</span></u></b><b><span
style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>.</span></b></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
120%'><span style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif;
font-variant:small-caps'>OUR SERVICE IS PROVIDED ON AN “AS IS” AND “AS
AVAILABLE” BASIS. YOUR USE OF OUR SERVICE IS AT YOUR OWN RISK. TO THE MAXIMUM
EXTENT PERMITTED BY APPLICABLE LAW, OUR SERVICE IS PROVIDED WITHOUT WARRANTIES
OR CONDITIONS OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT
LIMITED TO, IMPLIED WARRANTIES AND CONDITIONS OF MERCHANTABILITY, FITNESS FOR A
PARTICULAR PURPOSE, OR NON-INFRINGEMENT. NO ADVICE OR INFORMATION, WHETHER ORAL
OR WRITTEN, OBTAINED BY YOU FROM US OR THROUGH OUR SERVICE WILL CREATE ANY
WARRANTY OR CONDITION NOT EXPRESSLY STATED HEREIN. WITHOUT LIMITING THE
FOREGOING, WE, OUR SUBSIDIARIES, OUR AFFILIATES, AND OUR LICENSORS DO NOT
WARRANT THAT ANY CONTENT ON OUR SERVICE IS ACCURATE, RELIABLE OR CORRECT; THAT
OUR SERVICE WILL MEET YOUR REQUIREMENTS; THAT OUR SERVICE WILL BE AVAILABLE AT
ANY PARTICULAR TIME OR LOCATION, UNINTERRUPTED OR SECURE; THAT ANY DEFECTS OR
ERRORS WILL BE CORRECTED; OR THAT OUR SERVICE IS FREE OF VIRUSES OR OTHER
HARMFUL COMPONENTS. YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR
COMPUTER SYSTEM OR MOBILE DEVICE OR LOSS OF DATA THAT RESULTS FROM YOUR USE OF
OUR SERVICE OR ANY DOWNLOAD OF CONTENT THROUGH THE USE OF OUR SERVICE. YOU MAY
HAVE OTHER STATUTORY RIGHTS, BUT THE DURATION OF STATUTORILY REQUIRED
WARRANTIES OR CONDITIONS, IF ANY, WILL BE LIMITED TO THE SHORTEST PERIOD
PERMITTED BY LAW.</span></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
120%;border:none'><span style='font-size:11.0pt;line-height:120%;font-family:
"Quattrocento Sans",sans-serif;font-variant:small-caps;color:black'>THE CONTENT
PROVIDED THROUGH OR IN CONNECTION WITH OUR SERVICE IS DESIGNED TO PROVIDE
PRACTICAL AND USEFUL INFORMATION ON THE SUBJECT MATTER COVERED. WHILE SUCH
CONTENT MAY CONCERN ISSUES RELATED TO PROFESSIONAL SERVICES, SUCH CONTENT IS
NOT PROFESSIONAL SERVICES ADVICE.  YOU SHOULD NOT ACT OR REFRAIN FROM ACTING ON
THE BASIS OF ANY CONTENT INCLUDED ON THIS SITE OR IN CONNECTION WITH OUR
SERVICE WITHOUT SEEKING THE ADVICE OF A COMPETENT PROFESSIONAL IN THE
APPLICABLE SUBJECT MATTER.  WE EXPRESSLY DISCLAIM ALL LIABILITY IN RESPECT OF
ACTIONS TAKEN OR NOT TAKEN BASED ON ANY CONTENT OF OR IN CONNECTION WITH OUR
SERVICE. </span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:0in;text-align:justify;text-indent:0in;line-height:120%;page-break-after:
avoid'><span style='font-size:11.0pt;line-height:120%;color:black'>11.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><u><span style='font-size:11.0pt;line-height:120%;font-family:
"Quattrocento Sans",sans-serif'>Limitation of Liability</span></u></b><b><span
style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>.</span></b></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
120%'><span style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif;
font-variant:small-caps'>TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN
NO EVENT WILL WE OR OUR AFFILIATES, AGENTS, SUPPLIERS OR LICENSORS (OR OUR OR
THEIR EMPLOYEES, CONTRACTORS, AGENTS, OFFICERS OR DIRECTORS) BE LIABLE FOR ANY
INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES,
INCLUDING WITHOUT LIMITATION, DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA
OR OTHER INTANGIBLE LOSSES, ARISING OUT OF OR RELATING TO THE USE OF, OR
INABILITY TO USE, OUR SERVICE. UNDER NO CIRCUMSTANCES WILL WE BE RESPONSIBLE
FOR ANY DAMAGE, LOSS OR INJURY RESULTING FROM HACKING, TAMPERING OR OTHER
UNAUTHORIZED ACCESS OR USE OF OUR SERVICE OR YOUR ACCOUNT OR THE INFORMATION
CONTAINED THEREIN.</span></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
120%'><span style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif;
font-variant:small-caps'>TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, WE
ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY (A) ERRORS, MISTAKES, OR
INACCURACIES OF CONTENT; (B) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE
WHATSOEVER, RESULTING FROM YOUR ACCESS TO OR USE OF OUR SERVICE; (C) ANY UNAUTHORIZED
ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION
STORED THEREIN; (D) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM
OUR SERVICE; (E) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE THAT MAY BE
TRANSMITTED TO OR THROUGH OUR SERVICE BY ANY THIRD PARTY; (F) ANY ERRORS OR
OMISSIONS IN ANY CONTENT OR FOR ANY LOSS OR DAMAGE INCURRED AS A RESULT OF THE
USE OF ANY CONTENT POSTED, EMAILED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE
THROUGH OUR SERVICE; AND/OR (G) YOUR DATA OR THE DEFAMATORY, OFFENSIVE, OR
ILLEGAL CONDUCT OF ANY THIRD PARTY. </span></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
120%'><span style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif;
font-variant:small-caps'>IN NO EVENT WILL WE OR OUR AFFILIATES, AGENTS,
SUPPLIERS OR LICENSORS (OR OUR OR THEIR EMPLOYEES, CONTRACTORS, AGENTS,
OFFICERS OR DIRECTORS) BE LIABLE TO YOU FOR ANY CLAIMS, PROCEEDINGS,
LIABILITIES, OBLIGATIONS, DAMAGES, </span><span style='font-size:11.0pt;
line-height:120%;font-family:"Quattrocento Sans",sans-serif;font-variant:small-caps'>LOSSES
OR COSTS IN AN AMOUNT EXCEEDING THE AMOUNT YOU PAID TO US HEREUNDER OR $100.00,
WHICHEVER IS GREATER.</span></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
120%'><span style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif;
font-variant:small-caps'>THIS LIMITATION OF LIABILITY SECTION APPLIES WHETHER
THE ALLEGED LIABILITY IS BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY,
OR ANY OTHER BASIS, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH
DAMAGE.</span></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
120%'><span style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif;
font-variant:small-caps'>SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR
LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATIONS OR EXCLUSIONS
MAY NOT APPLY TO YOU. THESE TERMS GIVE YOU SPECIFIC LEGAL RIGHTS, AND YOU MAY
ALSO HAVE OTHER RIGHTS WHICH VARY BY JURISDICTION. THE DISCLAIMERS, EXCLUSIONS,
AND LIMITATIONS OF LIABILITY UNDER THESE TERMS WILL NOT APPLY TO THE EXTENT
PROHIBITED BY APPLICABLE LAW.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:0in;text-align:justify;text-indent:0in;line-height:120%;page-break-after:
avoid'><span style='font-size:11.0pt;line-height:120%;color:black'>12.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><u><span style='font-size:11.0pt;line-height:120%;font-family:
"Quattrocento Sans",sans-serif'>Confidential Information</span></u></b><b><span
style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>.</span></b></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:0in;text-align:justify;text-indent:.5in;line-height:120%;
page-break-after:avoid'><span style='font-size:11.0pt;line-height:120%;
font-family:"Quattrocento Sans",sans-serif;color:black'>12.1<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span style='font-size:11.0pt;line-height:120%;font-family:
"Quattrocento Sans",sans-serif'>Confidential Information.</span></b><span
style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>
</span></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
120%;page-break-after:avoid'><span style='font-size:11.0pt;line-height:120%;
font-family:"Quattrocento Sans",sans-serif'>From time to time, either party to
these Terms (the&nbsp;<b>&quot;Disclosing Party&quot;</b>) may disclose or make
available to the other (the&nbsp;<b>&quot;Receiving Party&quot;</b>)
non-public, proprietary, or confidential information of the Disclosing Party (<b>“Confidential
Information”</b>). Confidential Information includes any information, including
information from other Users shared via our Service, that reasonably should be
understood to be confidential given the nature of the information and the
circumstances of disclosure, including non-public business, product, technology
and marketing information. Confidential Information does not include any
information that: (i) is or becomes generally available to the public other
than as a result of the Receiving Party's breach of this confidentiality
section; (ii) is or becomes available to the Receiving Party on a
non-confidential basis from a third-party source, provided that such third
party is not and was not prohibited from disclosing such Confidential
Information; (iii) was in the Receiving Party's possession prior to the
Disclosing Party's disclosure thereof; or (iv) was or is independently
developed by the Receiving Party without using any of the Disclosing Party’s
Confidential Information. </span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:0in;text-align:justify;text-indent:.5in;line-height:120%;
page-break-after:avoid'><span style='font-size:11.0pt;line-height:120%;
font-family:"Quattrocento Sans",sans-serif;color:black'>12.2<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span style='font-size:11.0pt;line-height:120%;font-family:
"Quattrocento Sans",sans-serif'>Protection and Use of Confidential Information.
</span></b></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
120%;page-break-after:avoid'><span style='font-size:11.0pt;line-height:120%;
font-family:"Quattrocento Sans",sans-serif'>The Receiving Party shall: (i)
protect and safeguard the confidentiality of the Disclosing Party's
Confidential Information with at least the same degree of care as the Receiving
Party would protect its own Confidential Information, but in no event with less
than a commercially-reasonable degree of care; (ii) only use the Disclosing
Party's Confidential Information, and only permit it to be accessed or used,
for the purpose of exercising its rights or performing its obligations under
these Terms, for the purpose of exploring a business relationship (or changes
to the business relationship) between the parties, or for any other purpose
consistent with the Privacy Notice; and (iii) not disclose any of the
Disclosing Party’s Confidential Information to any person or entity, except to
the Receiving Party's service providers or financial/legal advisors who need to
know the Confidential Information and are bound to confidentiality obligations
at least as restrictive as those in these Terms.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:0in;text-align:justify;text-indent:.5in;line-height:120%;
page-break-after:avoid'><span style='font-size:11.0pt;line-height:120%;
font-family:"Quattrocento Sans",sans-serif;color:black'>12.3<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span style='font-size:11.0pt;line-height:120%;font-family:
"Quattrocento Sans",sans-serif'>Compelled Access or Disclosure.</span></b></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
120%;page-break-after:avoid'><span style='font-size:11.0pt;line-height:120%;
font-family:"Quattrocento Sans",sans-serif'>If the Receiving Party is required
by applicable law or legal process to disclose any Confidential Information, it
shall, prior to making such disclosure, use commercially-reasonable efforts to
notify the Disclosing Party of such requirements to afford the Disclosing Party
the opportunity to seek, at the Disclosing Party's sole cost and expense, a
protective order or other remedy.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:0in;text-align:justify;text-indent:.5in;line-height:120%;
page-break-after:avoid'><span style='font-size:11.0pt;line-height:120%;
font-family:"Quattrocento Sans",sans-serif;color:black'>12.4<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span style='font-size:11.0pt;line-height:120%;font-family:
"Quattrocento Sans",sans-serif'>Injunctive Relief. </span></b></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
120%;page-break-after:avoid'><span style='font-size:11.0pt;line-height:120%;
font-family:"Quattrocento Sans",sans-serif'>Each of the parties to these Terms
acknowledges that the other party will be irreparably harmed if Confidential
Information of the other is distributed in breach of this Section, and that
such other party would not have an adequate remedy at law in the event of such
an actual or threatened breach. Therefore, each of the parties agrees that the
other party shall be entitled to seek injunctive relief against any actual or
threatened breaches of this Section by the other party without the necessity of
showing actual damages or showing that monetary damages would not afford an
adequate remedy.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:0in;text-align:justify;text-indent:0in;line-height:120%;page-break-after:
avoid'><a name="_heading=h.30j0zll"></a><span style='font-size:11.0pt;
line-height:120%;color:black'>13.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><u><span style='font-size:11.0pt;line-height:120%;font-family:
"Quattrocento Sans",sans-serif'>Governing Law, Arbitration, and Class
Action/Jury Trial Waiver – United States</span></u></b><b><span
style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>.</span></b></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:0in;text-align:justify;text-indent:.5in;line-height:120%'><span
style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif;
color:black'>13.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span style='font-size:11.0pt;line-height:120%;font-family:
"Quattrocento Sans",sans-serif'>Governing Law. </span></b></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
120%'><span style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>If
you are located in the United States, you agree that: (i) we will be deemed
solely based in the State of California; and (ii) our Service will be deemed a
passive one that does not give rise to personal jurisdiction over us, either
specific or general, in jurisdictions other than California. These Terms will
be governed by the internal substantive laws of California, without respect to
its conflict of laws principles. The parties acknowledge that these Terms
evidence a transaction involving interstate commerce. Notwithstanding the
preceding sentences with respect to the substantive law, the Federal Arbitration
Act (9 U.S.C. §§ 1-16) (“<b>FAA</b>”) governs the interpretation and
enforcement of the Arbitration Agreement in Section 13.2 and preempts all state
laws to the fullest extent permitted by law. If the FAA is determined to not
apply to any issue that arises from or relates to the Arbitration Agreement,
then that issue shall be resolved under and governed by the law of your state
of residence. The application of the United Nations Convention on Contracts for
the International Sale of Goods is expressly excluded. You agree to submit to
the exclusive personal jurisdiction of the federal and state courts located in
San Francisco, California for any actions for which we retain the right to seek
injunctive or other equitable relief in a court of competent jurisdiction to
prevent the actual or threatened infringement, misappropriation or violation of
a our copyrights, trademarks, trade secrets, patents, or other intellectual
property or proprietary rights, as set forth in the Arbitration Agreement
below, including any provisional relief required to prevent irreparable harm.
You agree that San Francisco, California is the proper and exclusive forum for
any appeals of an arbitration award or for trial court proceedings in the event
that the arbitration provision below is found to be unenforceable.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:0in;text-align:justify;text-indent:.5in;line-height:120%;
page-break-after:avoid'><span style='font-size:11.0pt;line-height:120%;
font-family:"Quattrocento Sans",sans-serif;color:black'>13.2<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span style='font-size:11.0pt;line-height:120%;font-family:
"Quattrocento Sans",sans-serif'>Arbitration. </span></b></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
120%'><b><span style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif;
font-variant:small-caps'>READ THIS SECTION CAREFULLY BECAUSE IT REQUIRES THE
PARTIES TO ARBITRATE THEIR DISPUTES AND LIMITS THE MANNER IN WHICH YOU CAN SEEK
RELIEF FROM US.</span></b><span style='font-size:11.0pt;line-height:120%;
font-family:"Quattrocento Sans",sans-serif'> This section 13.2 (the “<b>Arbitration
Agreement</b>”) applies to and governs any dispute, controversy, or claim
between you and us that arises out of or relates to, directly or indirectly:
(a) these Terms, including the formation, existence, breach, termination,
enforcement, interpretation, validity, or enforceability thereof; (b) access to
or use of our Service, including receipt of any advertising, marketing, or
other communications from us; (c) any transactions through, by, or using our
Service; or (d) any other aspect of your relationship or transactions with us,
directly or indirectly, as a user or consumer (“<b>Claim</b>” or collectively,
“<b>Claims</b>”). The Arbitration Agreement shall apply, without limitation, to
all Claims that arose or were asserted before or after your consent to these
Terms. </span></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
120%'><span style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>If
you are a new User, you can reject and opt-out of this Arbitration Agreement
within thirty (30) days of accepting these Terms by emailing us at </span><span
style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>legal@startupos.com
with your first and last name and stating your intent to opt-out of the
Arbitration Agreement. Opting out of this Arbitration Agreement does not affect
the binding nature of any other part of these Terms, including the provisions
regarding controlling law or in which courts any disputes must be brought. </span></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
120%'><span style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>For
any Claim, you agree to first contact us at legal@startupos.com and attempt to
resolve the dispute with us informally. In the unlikely event that we have not
been able to resolve a Claim after sixty (60) days, we each agree to resolve
any Claim through binding arbitration by JAMS, under the Optional Expedited
Arbitration Procedures then in effect for JAMS (the “<b>Rules</b>”), except as
provided herein. JAMS may be contacted at </span><a
href="http://www.jamsadr.com/"><span style='font-size:11.0pt;line-height:120%;
font-family:"Quattrocento Sans",sans-serif;color:#0563C1;text-decoration:none'>www.jamsadr.com</span></a><span
style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>,
where the Rules are available. In the event of any conflict between the Rules
and this Arbitration Agreement, the Arbitration Agreement shall control. The
arbitration will be conducted in the U.S. county where you live or San
Francisco, California, unless you and we agree otherwise. If you are using our
Service for commercial purposes, each party will be responsible for paying any
JAMS filing, administrative and arbitrator fees in accordance with JAMS rules,
and the award rendered by the arbitrator will include costs of arbitration,
reasonable attorneys’ fees and reasonable costs for expert and other witnesses.
If you are an individual using our Service for non-commercial purposes: (i)
JAMS may require you to pay a fee for the initiation of your case, unless you
apply for and successfully obtain a fee waiver from JAMS; (ii) the award
rendered by the arbitrator may include your costs of arbitration, your
reasonable attorney’s fees, and your reasonable costs for expert and other
witnesses; and (iii) you may sue in a small claims court of competent
jurisdiction without first engaging in arbitration, but this does not absolve
you of your commitment to engage in the informal dispute resolution process.
Any judgment on the award rendered by the arbitrator may be entered in any
court of competent jurisdiction. You and we agree that the arbitrator, and not
any federal, state, or local court or agency, shall have exclusive authority to
resolve any disputes relating to the interpretation, applicability,
enforceability or formation of this Arbitration Agreement, including any claim
that all or any part of this Arbitration Agreement is void or voidable. The
arbitrator shall also be responsible for determining all threshold
arbitrability issues, including issues relating to whether these Terms, or any
provision of these Terms, is unconscionable or illusory or any defense to
arbitration, including waiver, delay, laches, unconscionability, or estoppel.</span></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
120%'><span style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif;
font-variant:small-caps'>NOTHING IN THIS SECTION WILL BE DEEMED AS: PREVENTING
US FROM SEEKING INJUNCTIVE OR OTHER EQUITABLE RELIEF FROM THE COURTS AS
NECESSARY TO PREVENT THE ACTUAL OR THREATENED INFRINGEMENT, MISAPPROPRIATION,
OR VIOLATION OF OUR DATA SECURITY, INTELLECTUAL PROPERTY RIGHTS OR OTHER
PROPRIETARY RIGHTS; OR PREVENTING YOU FROM ASSERTING CLAIMS IN SMALL CLAIMS
COURT, IF YOUR CLAIMS QUALIFY AND SO LONG AS THE MATTER REMAINS IN SUCH COURT
AND ADVANCES ON ONLY AN INDIVIDUAL (NON-CLASS, NON-COLLECTIVE, AND
NON-REPRESENTATIVE) BASIS.</span></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
120%'><span style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>If
this Arbitration Agreement is found to be void, unenforceable, or unlawful, in
whole or in part, the void, unenforceable, or unlawful provision, in whole or
in part, shall be severed. Severance of the void, unenforceable, or unlawful
provision, in whole or in part, shall have no impact on the remaining
provisions of the Arbitration Agreement, which shall remain in force, or the
parties’ ability to compel arbitration of any remaining claims on an individual
basis pursuant to the Arbitration Agreement. Notwithstanding the foregoing, if
the Class Action/Jury Trial Waiver is found to be void, unenforceable, or
unlawful, in whole or in part, because it would prevent you from seeking public
injunctive relief, then any dispute regarding the entitlement to such relief
(and only that relief) must be severed from arbitration and may be litigated in
a civil court of competent jurisdiction. All other claims for relief subject to
arbitration under this Arbitration Agreement shall be arbitrated under its
terms, and the parties agree that litigation of any dispute regarding the
entitlement to public injunctive relief shall be stayed pending the outcome of
any individual claims in arbitration.</span></p>

<p class=O-Bullet style='margin-left:0in;text-indent:.5in'><a
name="_Hlk106299492"><span class=normaltextrun><span style='font-size:11.0pt;
font-family:"Quattrocento Sans",sans-serif;color:black'>13.3<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><b><span style='font-size:11.0pt;font-family:"Quattrocento Sans",sans-serif'>Class
Action/Jury Trial Waiver. </span></b></a></p>

<p class=O-Bullet style='text-align:justify;line-height:120%'><span
style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif;
font-variant:small-caps'>WITH RESPECT TO ALL PERSONS AND ENTITIES, REGARDLESS
OF WHETHER THEY HAVE OBTAINED OR USED OUR SERVICE FOR PERSONAL, COMMERCIAL OR
OTHER PURPOSES, ALL CLAIMS MUST BE BROUGHT IN THE PARTIES’ INDIVIDUAL CAPACITY,
AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS ACTION,
COLLECTIVE ACTION, PRIVATE ATTORNEY GENERAL ACTION OR OTHER REPRESENTATIVE
PROCEEDING. THIS WAIVER APPLIES TO CLASS ARBITRATION, AND, UNLESS WE AGREE
OTHERWISE, THE ARBITRATOR MAY NOT CONSOLIDATE MORE THAN ONE PERSON’S CLAIMS.
YOU AND WE AGREE THAT THE ARBITRATOR MAY AWARD RELIEF ONLY TO AN INDIVIDUAL
CLAIMANT AND ONLY TO THE EXTENT NECESSARY TO PROVIDE RELIEF ON INDIVIDUAL
CLAIM(S).  ANY RELIEF AWARDED MAY NOT AFFECT OTHER USERS. YOU AND WE AGREE
THAT, BY ENTERING INTO THESE TERMS, YOU AND WE ARE EACH WAIVING THE RIGHT TO A
TRIAL BY JURY OR TO PARTICIPATE IN A CLASS ACTION, COLLECTIVE ACTION, PRIVATE
ATTORNEY GENERAL ACTION, OR OTHER REPRESENTATIVE PROCEEDING OF ANY KIND.<b> </b></span></p>

<p class=O-Bullet style='margin-left:0in;text-indent:0in'><span
style='font-size:11.0pt;color:black'>14.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><u><span style='font-size:11.0pt;font-family:"Quattrocento Sans",sans-serif'>Governing
Law, Arbitration, Class Action/Jury Trial Waiver and Small Claims Court  –
Canada</span></u></b></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:0in;text-align:justify;text-indent:.5in;line-height:120%;
page-break-after:avoid'><span style='font-size:11.0pt;line-height:120%;
font-family:"Quattrocento Sans",sans-serif;color:black'>14.1<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span style='font-size:11.0pt;line-height:120%;font-family:
"Quattrocento Sans",sans-serif'>Governing Law</span></b><span style='font-size:
11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>.</span></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
120%;page-break-after:avoid'><span style='font-size:11.0pt;line-height:120%;
font-family:"Quattrocento Sans",sans-serif'>If you are located in Canada, these
Terms shall be governed by, construed, and interpreted in accordance with the
laws of the Province of Ontario, Canada, with the exclusion of The United
Nations Convention on Contracts for the International Sale of Goods (“<b>CISG</b>”).</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:0in;text-align:justify;text-indent:.5in;line-height:120%;
page-break-after:avoid'><span style='font-size:11.0pt;line-height:120%;
font-family:"Quattrocento Sans",sans-serif;color:black'>14.2<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span style='font-size:11.0pt;line-height:120%;font-family:
"Quattrocento Sans",sans-serif'>Class Action/Jury Trial Waiver.</span></b></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
120%;page-break-after:avoid'><span style='font-size:11.0pt;line-height:120%;
font-family:"Quattrocento Sans",sans-serif'>Irrespective of whether pursued
pursuant to Section 14.3, 14.4, or 14.5, all claims and disputes must be
brought in the parties’ individual capacity, and not as a class member in any
purported class action, collective action, or other representative proceeding.
This waiver applies to class actions and arbitration. The parties agree that,
by entering into these Terms, each party waives the right to a trial by jury or
to participate in a class action, collective action, or other representative
proceeding of any kind.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:0in;text-align:justify;text-indent:.5in;line-height:120%;
page-break-after:avoid'><span style='font-size:11.0pt;line-height:120%;
font-family:"Quattrocento Sans",sans-serif;color:black'>14.3<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span style='font-size:11.0pt;line-height:120%;font-family:
"Quattrocento Sans",sans-serif'>Arbitration.</span></b></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
120%;page-break-after:avoid'><span style='font-size:11.0pt;line-height:120%;
font-family:"Quattrocento Sans",sans-serif'>The parties shall attempt to
resolve any and all disputes, controversies or claims arising out of, in
connection with, or in relation to these Terms, including but not limited to
the formation, interpretation, breach or termination thereof (“<b>Disputes</b>”),
promptly by amicable negotiation. You agree to commence such amicable
negotiation by delivering written notice of dispute to legal@startupos.com .  </span></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
120%;page-break-after:avoid'><span style='font-size:11.0pt;line-height:120%;
font-family:"Quattrocento Sans",sans-serif'>Any and all Disputes that are not
resolved by amicable negotiation within sixty (60) days of receipt of written
notice of the dispute will be referred to and finally determined by arbitration
in accordance with the JAMS International Arbitration Rules. The Tribunal will
consist of one arbitrator and the language of the Arbitration shall be English.
The seat of the Arbitration shall be Toronto, Ontario, Canada.   </span></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
120%;page-break-after:avoid'><span style='font-size:11.0pt;line-height:120%;
font-family:"Quattrocento Sans",sans-serif'>This Arbitration Agreement shall be
governed by, construed, and interpreted in accordance with the laws of the
Province of Ontario, Canada, with the exclusion of the CISG.  </span></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
120%;page-break-after:avoid'><span style='font-size:11.0pt;line-height:120%;
font-family:"Quattrocento Sans",sans-serif'>The parties agree to vary Articles
6.1 and 6.3 of the JAMS International Arbitration Rules to require that the
tribunal obtain both parties’ consent to consolidate proceedings or join
third-parties in the Arbitration.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:0in;text-align:justify;text-indent:.5in;line-height:120%;
page-break-after:avoid'><span style='font-size:11.0pt;line-height:120%;
font-family:"Quattrocento Sans",sans-serif;color:black'>14.4<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span style='font-size:11.0pt;line-height:120%;font-family:
"Quattrocento Sans",sans-serif'>Small Claims Court.</span></b></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
120%;page-break-after:avoid'><span style='font-size:11.0pt;line-height:120%;
font-family:"Quattrocento Sans",sans-serif'>Notwithstanding the Arbitration
Agreement contained in Section 14.3 herein, any Dispute alleging a monetary
value of less than USD$25,000.00 may be referred to the Ontario Superior Court
of Justice, Small Claims Court.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:0in;text-align:justify;text-indent:.5in;line-height:120%;
page-break-after:avoid'><span style='font-size:11.0pt;line-height:120%;
font-family:"Quattrocento Sans",sans-serif;color:black'>14.5<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span style='font-size:11.0pt;line-height:120%;font-family:
"Quattrocento Sans",sans-serif'>Opt-Out.</span></b></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
120%;page-break-after:avoid'><span style='font-size:11.0pt;line-height:120%;
font-family:"Quattrocento Sans",sans-serif'>If you are a new User, you can
reject and opt-out of the Arbitration Agreement contained within Section 14.3
herein within thirty (30) days of accepting these Terms by emailing us at
legal@startupos.com with your first and last name and stating your intent to
opt-out of the Arbitration Agreement. Opting out of the Arbitration Agreement
does not affect the binding nature of any other part of these Terms, including
the provisions regarding governing law or court jurisdiction. </span></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
120%;page-break-after:avoid'><span style='font-size:11.0pt;line-height:120%;
font-family:"Quattrocento Sans",sans-serif'>If you opt-out of the Arbitration
Agreement contained in Section 14.3 herein, you irrevocably submit to the
jurisdiction of the courts of the Province of Ontario.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:0in;text-align:justify;text-indent:.5in;line-height:120%;
page-break-after:avoid'><span style='font-size:11.0pt;line-height:120%;
font-family:"Quattrocento Sans",sans-serif;color:black'>14.6<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span style='font-size:11.0pt;line-height:120%;font-family:
"Quattrocento Sans",sans-serif'>Interim or Injunctive Relief.</span></b></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
120%;page-break-after:avoid'><span style='font-size:11.0pt;line-height:120%;
font-family:"Quattrocento Sans",sans-serif'>Without limiting the tribunal’s
jurisdiction under Section 14.3 herein, either party may commence or continue a
proceeding in the Ontario Superior Court of Justice for the limited purpose of
seeking injunctive relief, or any form of interim or interlocutory equitable
relief, regardless of whether an arbitration has been commenced pursuant to
Section 14.3 herein. For greater clarity, the parties agree that the tribunal
and the Ontario Superior Court of Justice shall each have jurisdiction to grant
injunctive relief, or any form of interim or interlocutory equitable relief.  </span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:0in;text-align:justify;text-indent:0in;line-height:120%;page-break-after:
avoid'><span style='font-size:11.0pt;line-height:120%;color:black'>15.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><u><span style='font-size:11.0pt;line-height:120%;font-family:
"Quattrocento Sans",sans-serif'>Export Controls</span></u></b><b><span
style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>.</span></b><span
style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>
</span></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
120%'><a name="_heading=h.tyjcwt"></a><span style='font-size:11.0pt;line-height:
120%;font-family:"Quattrocento Sans",sans-serif'>You understand and acknowledge
that our Service may be subject to export control laws and regulations of the
United States and Canada. You agree to comply with all applicable export and
re-export control and trade and economic sanctions laws, including the Export
Administration Regulations maintained by the U.S. Department of Commerce, trade
and economic sanctions maintained by the U.S. Treasury Department’s Office of
Foreign Assets Control (OFAC), the International Traffic in Arms Regulations
maintained by the U.S. State Department, the Customs Act (R.S.C., 1985, c. 1
(2nd Supp.)), the Customs Tariff (S.C. 1997, c. 36), the Export and Import
Permits Act (R.S.C., 1985, c. E-19), the Special Economic Measures Act (S.C.
1992, c. 17), the United Nations Act (R.S.C., 1985, c. U-2), the Justice for
Victims of Corrupt Foreign Officials Act (Sergei Magnitsky Law) (S.C. 2017, c.
21), the Defence Production Act (R.S.C., 1985, c. D-1); and any regulations,
orders directives or other instruments adopted pursuant any of these laws.
Neither you, nor any person to which you make our Service available or that is
acting on your behalf, or, if you are an Organization, any of your
subsidiaries, or any of your or their directors, officers or employees, or any
person owning fifty percent (50%) or more of your equity securities or other
equivalent voting interests, is (a) a person on the Consolidated Canadian
Autonomous Sanctions List, list of Specially Designated Nationals and Blocked
Persons or any other list of sanctioned persons administered by OFAC, the
Special Economic Measures Act, the Justice for Victims of Corrupt Foreign
Officials Act or any other governmental entity, or (b) a national or resident
of, or a segment of the government of, any country or territory for which the
United States and Canada maintains trade and economic sanctions or embargoes. </span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:0in;text-align:justify;text-indent:0in;line-height:120%;page-break-after:
avoid'><span style='font-size:11.0pt;line-height:120%;color:black'>16.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><u><span style='font-size:11.0pt;line-height:120%;font-family:
"Quattrocento Sans",sans-serif'>DMCA Notice</span></u></b><b><span
style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>.</span></b></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
120%'><span style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>We
respect content owner rights, and it is our policy to respond to alleged
infringement notices that comply with the Digital Millennium Copyright Act of
1998 (“<b>DMCA</b>”).</span></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
120%'><span style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>If
you believe that your copyrighted work has been copied in a way that
constitutes copyright infringement and is accessible via our Service, please
notify our copyright agent as set forth in the DMCA. For your complaint to be
valid under the DMCA, you must provide all of the following information in
writing:</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:1.0in;text-align:justify;text-indent:-.5in;line-height:120%;
border:none'><span style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>(a)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>An
electronic or physical signature of a person authorized to act on behalf of the
copyright owner;</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:1.0in;text-align:justify;text-indent:-.5in;line-height:120%;
border:none'><span style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>(b)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>Identification
of the copyrighted work that you claim has been infringed;</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:1.0in;text-align:justify;text-indent:-.5in;line-height:120%;
border:none'><span style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>(c)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>Identification
of the material that is claimed to be infringing and where it is located on our
Service;</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:1.0in;text-align:justify;text-indent:-.5in;line-height:120%;
border:none'><span style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>(d)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>Information
reasonably sufficient to permit us to contact you, such as your address,
telephone number, and, e-mail address;</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:1.0in;text-align:justify;text-indent:-.5in;line-height:120%;
border:none'><span style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>(e)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>A
statement that you have a good faith belief that use of the material in the
manner complained of is not authorized by the copyright owner, its agent, or
law; and</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:1.0in;text-align:justify;text-indent:-.5in;line-height:120%;
border:none'><span style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>(f)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>A
statement, made under penalty of perjury, that the above information is
accurate, and that you are the copyright owner or are authorized to act on
behalf of the owner.</span></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
120%'><span style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>The
above information must be submitted to our DMCA Agent using the following
contact information:</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:.5in;text-align:justify;line-height:120%'><span style='font-size:
11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>StartupOS,
Inc.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:.5in;text-align:justify;line-height:120%'><span style='font-size:
11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>Address:
78680 Highway 111 #5018, La Quinta, CA  92253</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:.5in;text-align:justify;line-height:120%'><span style='font-size:
11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>Email: legal@startupos.com</span></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
120%'><span style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif;
font-variant:small-caps'>UNDER FEDERAL LAW, IF YOU KNOWINGLY MISREPRESENT THAT
ONLINE MATERIAL IS INFRINGING, YOU MAY BE SUBJECT TO CRIMINAL PROSECUTION FOR
PERJURY AND CIVIL PENALTIES, INCLUDING MONETARY DAMAGES, COURT COSTS, AND LEGAL
FEES.</span></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
120%'><span style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>Please
note that the preceding requirements do not constitute legal advice. In
accordance with the DMCA and other applicable law, we have adopted a policy of
terminating, in appropriate circumstances, users who are deemed to be repeat
infringers. We may also at our sole discretion limit access to our Service
and/or terminate our Service Accounts of any users who infringe any
intellectual property rights of others, whether or not there is any repeat
infringement.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:0in;text-align:justify;text-indent:0in;line-height:120%;page-break-after:
avoid'><span style='font-size:11.0pt;line-height:120%;color:black'>17.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><u><span style='font-size:11.0pt;line-height:120%;font-family:
"Quattrocento Sans",sans-serif'>Our Publicity Rights</span></u></b><b><span
style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>.</span></b><span
style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>
</span></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
120%'><span style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>We
may identify you as a User in our promotional materials. We will promptly stop
doing so upon your request sent to support@startupos.com.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:0in;text-align:justify;text-indent:0in;line-height:120%;page-break-after:
avoid'><span style='font-size:11.0pt;line-height:120%;color:black'>18.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><u><span style='font-size:11.0pt;line-height:120%;font-family:
"Quattrocento Sans",sans-serif'>General Provisions</span></u></b><b><span
style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>.</span></b></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:0in;text-align:justify;text-indent:.5in;line-height:120%'><span
style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif;
color:black'>18.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span style='font-size:11.0pt;line-height:120%;font-family:
"Quattrocento Sans",sans-serif'>Assignment. </span></b></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
120%'><span style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>These
Terms, and any rights and licenses granted hereunder, may not be transferred or
assigned by you without our prior express written consent, but may be assigned
by us without restriction. Any attempted transfer or assignment in violation
hereof will be null and void.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:0in;text-align:justify;text-indent:.5in;line-height:120%'><span
style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif;
color:black'>18.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span style='font-size:11.0pt;line-height:120%;font-family:
"Quattrocento Sans",sans-serif'>Notification Procedures and Changes to these
Terms. </span></b></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
120%'><span style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>We
may provide notifications, whether such notifications are required by law or
are for marketing or other business-related purposes, to you via email notice,
written or hard copy notice, or through posting of such notice on our website,
as we determine in our sole discretion. We reserve the right to determine the
form and means of providing notifications to our users, provided that you may
opt out of certain notifications as required under applicable laws or as
described in these Terms or our Privacy Notice. We are not responsible for any
automatic filtering you or your network provider may apply to email
notifications we send to the email address you provide us. We may, in our sole
discretion, modify or update these Terms from time to time, and so you should
review this page periodically. When we change these Terms in a material manner,
we will update the ‘last modified’ date at the top of this page and notify you
that material changes have been made to these Terms. These Terms apply to and
govern your access to and use of our Service effective as of the start of your
access to or use of our Service, even if such access or use began before
publication of these Terms. Your continued use of our Service after any such
change constitutes your acceptance of the new Terms of Service. If you do not
agree to any part of these Terms or any future Terms of Service, do not use or
access (or continue to access) our Service.  </span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:0in;text-align:justify;text-indent:.5in;line-height:120%'><span
style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif;
color:black'>18.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span style='font-size:11.0pt;line-height:120%;font-family:
"Quattrocento Sans",sans-serif'>Entire Agreement/Severability. </span></b></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
120%'><span style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>These
Terms, together with any amendments and any additional agreements you may enter
into with us in connection with our Service, will constitute the entire
agreement between you and us concerning our Service. None of our employees or
representatives are authorized to make any modification or addition to these
Terms. Any statements or comments made between you and any of our employees or
representatives are expressly excluded from these Terms and will not apply to
you or us or your use of our Service.  Except as otherwise stated in these
Terms, if any provision of these Terms is deemed invalid by a court of competent
jurisdiction, the invalidity of such provision will not affect the validity of
the remaining provisions of these Terms, which will remain in full force and
effect. </span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:0in;text-align:justify;text-indent:.5in;line-height:120%'><span
style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif;
color:black'>18.4<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span style='font-size:11.0pt;line-height:120%;font-family:
"Quattrocento Sans",sans-serif'>No Waiver. </span></b></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
120%'><span style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>No
waiver of any term of these Terms will be deemed a further or continuing waiver
of such term or any other term, and our failure to assert any right or
provision under these Terms will not constitute a waiver of such right or
provision.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:0in;text-align:justify;text-indent:.5in;line-height:120%'><span
style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif;
color:black'>18.5<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span style='font-size:11.0pt;line-height:120%;font-family:
"Quattrocento Sans",sans-serif'>Language of Agreement</span></b><span
style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>.</span></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
120%'><span style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>If
you are located in Canada, the parties hereto confirm that they have requested
that these Terms and all related documents be drafted in English. </span><span
lang=FR style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>Les
parties aux présentes ont exigé que la présente entente et tous les documents
connexes soient rédigés en anglais. </span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:0in;text-align:justify;text-indent:.5in;line-height:120%'><span
style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif;
color:black'>18.6<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span style='font-size:11.0pt;line-height:120%;font-family:
"Quattrocento Sans",sans-serif'>Contact. </span></b></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
120%'><span style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>If
you have any questions about these Terms, please contact us at
support@startupos.com.</span></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
120%'><span style='font-size:11.0pt;line-height:120%;font-family:"Quattrocento Sans",sans-serif'>&nbsp;</span></p>

</div>

<div>

<hr class=msocomoff align=left size=1 width="33%">

<div>

<div id="_com_1" class=msocomtxt language=JavaScript
onmouseover="msoCommentShow('_anchor_1','_com_1')"
onmouseout="msoCommentHide('_com_1')"><a name="_msocom_1"></a>

<p class=MsoCommentText><span class=MsoCommentReference><span style='font-size:
9.0pt'>&nbsp;<a href="#_msoanchor_1" class=msocomoff>[1]</a></span></span>In
Canada, although not yet defined in any legislation, de-identified personal
information may still be subject to applicable privacy laws, whereas it is anonymized
personal information is not. </p>

</div>

</div>

<div>

<div id="_com_2" class=msocomtxt language=JavaScript
onmouseover="msoCommentShow('_anchor_2','_com_2')"
onmouseout="msoCommentHide('_com_2')"><a name="_msocom_2"></a>

<p class=MsoCommentText><span class=MsoCommentReference><span style='font-size:
9.0pt'>&nbsp;<a href="#_msoanchor_2" class=msocomoff>[2]</a></span></span>Confirm
if this will continue to occur in the US for Canadian customers or if
information for Canadian customers will be stored or processed in Canada.</p>

</div>

</div>

<div>

<div id="_com_3" class=msocomtxt language=JavaScript
onmouseover="msoCommentShow('_anchor_3','_com_3')"
onmouseout="msoCommentHide('_com_3')"><a name="_msocom_3"></a>

<p class=MsoCommentText><span class=MsoCommentReference><span style='font-size:
9.0pt'>&nbsp;<a href="#_msoanchor_3" class=msocomoff>[3]</a></span></span>Are
authorized users self-installing the software/mobile app on their own personal
devices via a third party app store as referenced below?</p>

<p class=MsoCommentText>&nbsp;</p>

<p class=MsoCommentText>Automatic updates are prohibited under Canada’s
anti-spam legislation in the course of commercial activity unless the
software/app is self-installed by a user on their own personal devices. As
such, we need to be entirely sure that the app is being downloaded/purchased
via a third party app store by the authorized user and owner of a mobile device
rather than it being provided directly by StartupOS / installed by StartupOS on
a user’s mobile device.</p>

<p class=MsoCommentText>&nbsp;</p>

</div>

</div>

</div>

</div>
`;

export const privacy = `
<div lang=EN-US link="#0563C1" vlink="#954F72" style='word-wrap:break-word'>

<div class=WordSection1>

<p class=MsoNormal align=center style='margin-top:6.0pt;margin-right:0in;
margin-bottom:6.0pt;margin-left:0in;text-align:center'><b><span
style='font-family:"Calibri",sans-serif'>STARTUPOS PRIVACY POLICY</span></b></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify'><b><span style='font-family:"Calibri",sans-serif'>&nbsp;</span></b></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify'><b><span style='font-family:"Calibri",sans-serif'>Last
Updated</span></b><span style='font-family:"Calibri",sans-serif'>: 26th of June, 2022</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify'><span style='font-family:"Calibri",sans-serif'>StartupOS,
Inc., (“<b>StartupOS</b>,” “<b>we</b>,” “<b>our</b>,” or “<b>us</b>”) is
committed to protecting your privacy.  This Privacy Policy explains how your
personal information is collected, used, and disclosed by StartupOS.  This
Privacy Policy applies to our websites, including </span><a
href="https://startupos.com/"><span style='font-family:"Calibri",sans-serif'>https://startupos.com/</span></a><span
style='font-family:"Calibri",sans-serif'> and other websites we own and operate
that link to this Privacy Policy (the “<b>Sites</b>”), and the related content,
platform, services, products, and other functionality offered on or through our
services (collectively, our “<b>Service</b>”).   </span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify'><span style='font-family:"Calibri",sans-serif'>By
accessing or using our Service, you signify that you have read, understood, and
agree to our collection, storage, use, and disclosure of your personal
information as described in this Privacy Policy and our <a href="/terms">Terms of Service</a></span><span style='font-family:"Calibri",sans-serif'>.
Please note that this Privacy Policy does not apply to our processing of
information about employees or job applicants.  </span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify'><a><span style='font-family:"Calibri",sans-serif'>Click
on the links below to jump to each section:</span></a></p>

<ol style='margin-top:0in' start=1 type=1>
 <li class=MsoNormal style='margin-top:6.0pt;margin-bottom:6.0pt;text-align:
     justify'><span
class=MsoCommentReference>
<span style='line-height:120%;font-family:"Calibri",sans-serif'>
<a href="#_msoanchor_1" class=msocomoff>What Information Do We Collect and For What Purpose?</a>
</span>
</span></li>
 <li class=MsoNormal style='margin-top:6.0pt;margin-bottom:6.0pt;text-align:
     justify'><span style='font-family:"Calibri",sans-serif'><a href="#_msoanchor_2" class=msocomoff>How We Use Your
     Information</a></span></li>
 <li class=MsoNormal style='margin-top:6.0pt;margin-bottom:6.0pt;text-align:
     justify'><span style='font-family:"Calibri",sans-serif'><a href="#_msoanchor_3" class=msocomoff>Sharing of Your
     Information</a> </span></li>
 <li class=MsoNormal style='margin-top:6.0pt;margin-bottom:6.0pt;text-align:
     justify'><span style='font-family:"Calibri",sans-serif'><a href="#_msoanchor_4" class=msocomoff>Third-Party
     Tracking and Online Advertising</a></span></li>
 <li class=MsoNormal style='margin-top:6.0pt;margin-bottom:6.0pt;text-align:
     justify'><span style='font-family:"Calibri",sans-serif'><a href="#_msoanchor_5" class=msocomoff>Control Over Your
     Information</a></span></li>
 <li class=MsoNormal style='margin-top:6.0pt;margin-bottom:6.0pt;text-align:
     justify'><span style='font-family:"Calibri",sans-serif'><a href="#_msoanchor_6" class=msocomoff>Children’s Privacy</a>
     </span></li>
 <li class=MsoNormal style='margin-top:6.0pt;margin-bottom:6.0pt;text-align:
     justify'><span style='font-family:"Calibri",sans-serif'><a href="#_msoanchor_7" class=msocomoff>Security and
     Location of Processing </a></span></li>
 <li class=MsoNormal style='margin-top:6.0pt;margin-bottom:6.0pt;text-align:
     justify'><span style='font-family:"Calibri",sans-serif'><a href="#_msoanchor_8" class=msocomoff>Links to Other
     Websites and Services</a></span></li>
 <li class=MsoNormal style='margin-top:6.0pt;margin-bottom:6.0pt;text-align:
     justify'><span style='font-family:"Calibri",sans-serif'><a href="#_msoanchor_9" class=msocomoff>How to Contact Us</a></span></li>
 <li class=MsoNormal style='margin-top:6.0pt;margin-bottom:6.0pt;text-align:
     justify'><span style='font-family:"Calibri",sans-serif'><a href="#_msoanchor_10" class=msocomoff>Changes to Our
     Privacy Policy</a></span></li>
 <li class=MsoNormal style='margin-top:6.0pt;margin-bottom:6.0pt;text-align:
     justify'><span style='font-family:"Calibri",sans-serif'><a href="#_msoanchor_11" class=msocomoff>Canadian Privacy
     Rights</a></span></li>
</ol>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify'><b><a class=msocomanchor
id="_anchor_1" onmouseover="msoCommentShow('_anchor_1','_com_1')"
onmouseout="msoCommentHide('_com_1')" href="#_msocom_1"
language=JavaScript name="_msoanchor_1"><span style='font-family:"Calibri",sans-serif'>1.            WHAT
INFORMATION DO WE COLLECT AND FOR WHAT PURPOSE? </span></a></b></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify'><b><i><span style='font-family:"Calibri",sans-serif'>Personal
Information:  </span></i></b><span style='font-family:"Calibri",sans-serif'>When
we use the term “<b>personal information</b>,” we mean any information that can
be used to identify you, directly or indirectly, as an individual person.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify'><span style='font-family:"Calibri",sans-serif'>We
collect personal information in connection with your visits to and use of the
Service.  This collection includes information that you provide in connection
with the Service, information from third parties, and information that is
collected automatically, such as through the use of cookies and other
technologies.  For further information on your choices regarding your
information, see the “Control Over Your Information” section below.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:.5in;text-align:justify'><b><span style='font-family:"Calibri",sans-serif'>A.
           Information You Provide to Us Directly </span></b></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify'><span style='font-family:"Calibri",sans-serif'>We
collect and use personal information you provide in the following ways:</span></p>

<p class=MsoListParagraph style='margin-top:6.0pt;margin-right:0in;margin-bottom:
6.0pt;margin-left:.5in;text-align:justify;text-indent:-.25in'><span
style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span style='font-family:"Calibri",sans-serif'>Account Registration
and Profile Information.  </span></b><span style='font-family:"Calibri",sans-serif'>We
collect personal and/or business information that you provide when you register
for an account to use our Service.  Depending on your account type, this may include
your name, email, billing and payment card information, demographic information
and password.  Once you create an account, you may also be able to create a
profile that may be visible to other users on the Service. For information on
how to adjust profile visibility settings, please see “Control Over Your
Information” </span><span style='font-family:"Calibri",sans-serif'>section
below. We use this information to administer your account, provide you with the
relevant services and information, communicate with you regarding your account,
the Service, and for customer support purposes.  </span></p>

<p class=MsoListParagraph style='margin-top:6.0pt;margin-right:0in;margin-bottom:
6.0pt;margin-left:.5in;text-align:justify;text-indent:-.25in'><span
style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span style='font-family:"Calibri",sans-serif'>Messages and
User Content.  </span></b><span style='font-family:"Calibri",sans-serif'>Depending
on your account type, you may upload or transmit videos, images, data, or
information through your communications with us, your communications with other
users, or your use of the Service (collectively, “User Content”).  User Content
and any information contained in the User Content, including personal
information you may have included, is stored and collected as part of the
Service.  We use the User Content to operate, improve, personalize, and
optimize the Service, and to manage and deliver advertising.  </span></p>

<p class=MsoListParagraph style='margin-top:6.0pt;margin-right:0in;margin-bottom:
6.0pt;margin-left:.5in;text-align:justify;text-indent:-.25in'><span
style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span style='font-family:"Calibri",sans-serif'>Audio and Video
Information.  </span></b><span style='font-family:"Calibri",sans-serif'>If you
use certain features of the Service, such as interview training, we may collect
audio and/or video recordings of these sessions, with your permission.  These
recordings will be used by StartupOS in a manner consistent with this Privacy
Policy.  Note that video backgrounds may inadvertently provide sensitive
personal data.  As such, please be mindful of your surroundings and refrain from
conducting the recording in a location that could potentially disclose this
information.  You may access or delete these recordings at any time through
your user account settings. </span></p>

<ul style='margin-top:0in' type=disc>
 <li class=MsoNormal style='margin-top:6.0pt;margin-bottom:6.0pt;text-align:
     justify'><b><span style='font-family:"Calibri",sans-serif'>Inquiries and
     Other Communications</span></b><span style='font-family:"Calibri",sans-serif'>. 
     If you communicate with us through any form, we may collect your name,
     email address, mailing address, phone number, or any other personal
     information you choose to provide to us.  We use this information to
     investigate and respond to your inquiries, and to communicate with you, to
     enhance the services we offer to our users and to manage and grow our
     organization.   </span></li>
</ul>

<p class=MsoListParagraphCxSpFirst style='margin-top:6.0pt;margin-right:0in;
margin-bottom:6.0pt;margin-left:.5in;text-align:justify;text-indent:-.25in'><span
style='font-family:Symbol;color:#222222'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span style='font-family:"Calibri",sans-serif;color:#222222'>Payment
Information.  </span></b><span style='font-family:"Calibri",sans-serif;
color:#222222'>If you make a purchase through your use of the Service, we
collect payment information, such as credit card information.  Please note that
we use third party payment processors to process credit card payments made to
us.  As such, we do not retain any personally identifiable financial
information in connection with credit card payments, such as credit card
numbers.  Rather, all such information is provided directly by you to our
third-party processor.  The payment processor’s use of your personal
information is governed by their privacy policy.  </span></p>

<p class=MsoListParagraph style='margin-top:6.0pt;margin-right:0in;margin-bottom:
6.0pt;margin-left:.5in;text-align:justify;text-indent:-.25in'><span
style='font-family:Symbol;color:#222222'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span style='font-family:"Calibri",sans-serif;color:#222222'>Other
Data You Share.  </span></b><span style='font-family:"Calibri",sans-serif;
color:#222222'>We also collect personal information at such other points on our
Service so that we may provide the features or functionality of the Service and
for other purposes described in this Privacy Policy.  We may also collect your
personal information or data if you submit it to us in other contexts (e.g., if
you provide us with a testimonial).  </span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:.5in;text-align:justify;page-break-after:avoid'><b><span
style='font-family:"Calibri",sans-serif'>B.            Information We Collect
Automatically through Use of the Service </span></b></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify;page-break-after:avoid'><span
style='font-family:"Calibri",sans-serif'>We use various technologies to automatically
collect certain personal information about the device or browser you use to
navigate our Service, including:</span></p>

<p class=MsoListParagraph style='margin-top:6.0pt;margin-right:0in;margin-bottom:
6.0pt;margin-left:.5in;text-align:justify;text-indent:-.25in;page-break-after:
avoid'><span style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span style='font-family:"Calibri",sans-serif'>Web Logs</span></b><span
style='font-family:"Calibri",sans-serif'>.  Our web servers keep log files that
record data every time a device accesses those servers.  As such, when you
access this Website, we will automatically collect information such as your
browser type and version, device and connection information, the Internet
Protocol (IP) address used to connect your device to the Internet, and standard
web log information.  </span></p>

<ul style='margin-top:0in' type=disc>
 <li class=MsoNormal style='margin-top:6.0pt;margin-bottom:6.0pt;text-align:
     justify'><b><span style='font-family:"Calibri",sans-serif'>Location</span></b><span
     style='font-family:"Calibri",sans-serif'> <b>Information.  </b>We may
     collect general geographic location based on your IP address or more
     precise geolocation with your permission when accessing our online
     services through a mobile device.  </span></li>
</ul>

<p class=MsoListParagraph style='margin-top:6.0pt;margin-right:0in;margin-bottom:
6.0pt;margin-left:.5in;text-align:justify;text-indent:-.25in;page-break-after:
avoid'><span style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span style='font-family:"Calibri",sans-serif'>Cookies and
Pixel Tags.  </span></b><span style='font-family:"Calibri",sans-serif'>We, and
our third-party partners, automatically collect certain types of usage
information when you visit our Service, read our emails, or otherwise engage
with us.  We typically collect this information through a variety of tracking
technologies, including cookies, web beacons, embedded scripts,
location-identifying technologies, file information, and similar technology
(collectively, “tracking technologies”).  For example, we collect information
about your device and its software, such as your IP address, browser type,
Internet service provider, platform type, device type, operating system, date
and time stamp, a unique ID (that allows us to uniquely identify your browser,
mobile device, or your account), and other such information.  We also collect
information about the way you use our Service, for example, the site from which
you came and the site to which you are going when you leave our website, the
pages you visit, the links you click, how frequently you access the Service,
whether you open emails or click the links contained in emails, and other
actions you take on the Service.  Most internet browsers allow you to remove or
manage cookie functions and adjust your privacy and security preferences.  For
information on how to do this, access the “help” menu on your internet browser,
or access </span><a href="http://www.aboutcookies.org/how-to-control-cookies"><span
style='font-family:"Calibri",sans-serif'>http://www.aboutcookies.org/how-to-control-cookies</span></a><span
style='font-family:"Calibri",sans-serif'>.  Please note, however, that
disabling our cookies may mean that you will not be able to take full advantage
of the Service.</span></p>

<p class=MsoListParagraph style='margin-top:6.0pt;margin-right:0in;margin-bottom:
6.0pt;margin-left:.5in;text-align:justify;text-indent:-.25in'><span
style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span style='font-family:"Calibri",sans-serif'>Mobile Device
Identifiers. </span></b><span style='font-family:"Calibri",sans-serif'> When
you access our Service from a mobile device, we may collect unique
identification numbers associated with your device or our mobile application
(including, for example, a UDID, Unique ID for Advertisers (“IDFA”), or Google
AdID), mobile carrier, device type, model and manufacturer, mobile device
operating system brand and model, phone number, and, depending on your mobile
device settings, your geographical location data (with your permission). We may
also be able to approximate a device’s location by analyzing other information,
like an IP address.   </span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify'><span style='font-family:"Calibri",sans-serif'>We
use or may use the data collected through these technologies to:  (a) remember
information so that you will not have to re-enter it during your visit or the
next time you visit the site; (b) provide custom, personalized content and
information, including targeted content and advertising (see “Third Party
Tracking and Online Advertising” below); (c) recognize and contact you across
multiple devices; (d) provide and monitor the effectiveness of our Service; (e)
monitor aggregate metrics such as total number of visitors, traffic, usage, and
demographic patterns on our Service; (f) diagnose or fix technology problems;
and (g) otherwise to plan for and enhance our Service.  </span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify'><span style='font-family:"Calibri",sans-serif'>If
you would prefer not to accept cookies, most browsers will allow you to: (i)
change your browser settings to notify you when you receive a cookie, which
lets you choose whether or not to accept it; (ii) disable existing cookies; or
(iii) set your browser to automatically reject cookies.  Please note that doing
so may negatively impact your experience using the Service, as some features
and services on our Service may not work properly.  Depending on your mobile
device and operating system, you may not be able to delete or block all cookies. 
You may also set your email options to prevent the automatic downloading of
images that may contain technologies that would allow us to know whether you
have accessed our email and performed certain functions with it.   </span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify'><span style='font-family:"Calibri",sans-serif'>We
and our third-party partners may also use cookies and tracking technologies for
advertising purposes.  For more information about tracking technologies, please
see “Third Party Tracking and Online Advertising” below.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:.5in;text-align:justify;page-break-after:avoid'><b><span
style='font-family:"Calibri",sans-serif'>C.            Information From Third
Party Sources </span></b></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify;page-break-after:avoid'><span
style='font-family:"Calibri",sans-serif'>We may receive personal information
about you from third parties and combine this information with other
information we have.</span></p>

<p class=MsoListParagraphCxSpFirst style='margin-top:6.0pt;margin-right:0in;
margin-bottom:6.0pt;margin-left:.5in;text-align:justify;text-indent:-.25in'><span
style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span style='font-family:"Calibri",sans-serif'>Single Sign-On.</span></b><span
style='font-family:"Calibri",sans-serif'> We may use single sign-on (“SSO”) to
allow a user to authenticate their account using one set of login information,
including through Google and LinkedIn.  We will have access to certain
information from those third parties in accordance with the authorization
procedures determined by those third parties, which may include, for example,
your name, username, email address, language preference, and profile picture.  We
use this information to operate, maintain, and provide to you the features and
functionality of the Service.  We may also send you service-related emails or
messages (e.g., account verification, customer support, changes, or updates to
features of the Site, technical and security notices).</span></p>

<p class=MsoListParagraphCxSpLast style='margin-top:6.0pt;margin-right:0in;
margin-bottom:6.0pt;margin-left:.5in;text-indent:-.25in'><span
style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span style='font-family:"Calibri",sans-serif'>Social Media.</span></b><span
style='font-family:"Calibri",sans-serif'> When an individual interacts with our
Services through various social media networks, such as when someone “Likes” us
on Facebook or follows us or shares our content on Facebook, LinkedIn, Twitter,
or other social networks, we may receive some information about individuals
that they permit the social network to share with third parties.  The data we
receive is dependent upon an individual’s privacy settings with the social
network, and may include your profile information, profile picture, gender,
username, user ID associated with your social media account, age range,
language, country, and any other information you permit the social network to
share with third parties.  Individuals should always review and, if necessary,
adjust their privacy settings on third-party websites and social media networks
and services before sharing information and/or linking or connecting them to
other services.  We use this information to operate, maintain, and provide to
you the features and functionality of the Service, as well as to communicate
directly with you, such as to send you email messages about products and
services that may be of interest to you.</span></p>

<ul style='margin-top:0in' type=disc>
 <li class=MsoNormal style='margin-top:6.0pt;margin-bottom:6.0pt;text-align:
     justify'><b><span style='font-family:"Calibri",sans-serif'>Information We
     Receive from Third-Party Platforms/Services You Connect to Our Service.  </span></b><span
     style='font-family:"Calibri",sans-serif'>We may receive personal information
     about you or information about your business from third parties and
     combine that with information we collect through our Service.  For
     example, if you choose to connect your business account to a third-party
     service (via API), such as  banking services, we may collect information about
     you and your business, including your profile information, and any other
     information collected by the platform.  The data we receive from these
     third-party sites is dependent upon that third party’s policies.  The data
     we receive from third-party sites is dependent upon the third party’s
     policies and your privacy settings on that third-party site.  You should
     always review and, if necessary, adjust your privacy settings on
     third-party websites and services before linking or connecting them to our
     website or Service.  </span></li>
</ul>

<p class=MsoListParagraph style='margin-top:6.0pt;margin-right:0in;margin-bottom:
6.0pt;margin-left:.5in;text-align:justify;text-indent:-.25in'><span
style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span style='font-family:"Calibri",sans-serif'>Information
from Other Sources.  </span></b><span style='font-family:"Calibri",sans-serif'>We
may obtain information from other sources, including through third-party
information providers, business partners, or through transactions such as
mergers and acquisitions.  We may combine this information with other
information we collect from or about you.  In these cases, our Privacy Policy
governs the handling of the combined personal information.  We use this information
to operate, maintain, and provide to you the features and functionality of the
Service, as well as to communicate directly with you, such as to send you email
messages about products and services that may be of interest to you.  We may
also collect information about you that is publicly available.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify'><b><span style='font-family:"Calibri",sans-serif'><a class=msocomanchor
id="_anchor_2" onmouseover="msoCommentShow('_anchor_2','_com_2')"
onmouseout="msoCommentHide('_com_2')" href="#_msocom_2"
language=JavaScript name="_msoanchor_2">2.            HOW
WE USE YOUR INFORMATION</a></span></b></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify'><span style='font-family:"Calibri",sans-serif'>We
use the information we collect for purposes described in this Privacy Policy or
as otherwise disclosed to you on our Service or in connection with our Service.
  For example, we use your information to: </span></p>

<ul style='margin-top:0in' type=disc>
 <li class=MsoNormal style='margin-top:6.0pt;margin-bottom:6.0pt;text-align:
     justify'><span style='font-family:"Calibri",sans-serif'>Set up your
     account and deliver the Service to you;</span></li>
 <li class=MsoNormal style='margin-top:6.0pt;margin-bottom:6.0pt;text-align:
     justify'><span style='font-family:"Calibri",sans-serif'>Send you
     transactional information, including confirmations, invoices, technical
     notices, product and services information, updates, security alerts,
     support and administrative messages, and information about your
     transaction with us;</span></li>
 <li class=MsoNormal style='margin-top:6.0pt;margin-bottom:6.0pt;text-align:
     justify'><span style='font-family:"Calibri",sans-serif'>Communicate with
     you such as to respond to your comments and questions, provide customer
     service or feedback, or respond to employment opportunities for which
     you’ve applied, or for any other purposes in connection with the Service;</span></li>
 <li class=MsoNormal style='margin-top:6.0pt;margin-bottom:6.0pt;text-align:
     justify'><span style='font-family:"Calibri",sans-serif'>Provide you with
     news about products and services offered by us;</span></li>
 <li class=MsoNormal style='margin-top:6.0pt;margin-bottom:6.0pt;text-align:
     justify'><span style='font-family:"Calibri",sans-serif'>Operate, maintain,
     test, enhance, update, monitor, diagnose, fix and improve our Service;</span></li>
</ul>

<p class=MsoListParagraph style='margin-top:6.0pt;margin-right:0in;margin-bottom:
6.0pt;margin-left:.5in;text-align:justify;text-indent:-.25in'><span
style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>Conduct research
and analytics to understand our visitors and customers, and tailor our product
offerings;</span></p>

<p class=MsoListParagraph style='margin-top:6.0pt;margin-right:0in;margin-bottom:
6.0pt;margin-left:.5in;text-align:justify;text-indent:-.25in'><span
style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>Provide you with
advertisements based on, for example, your interests, your business stage or
journey, general location and order history;</span></p>

<ul style='margin-top:0in' type=disc>
 <li class=MsoNormal style='margin-top:6.0pt;margin-bottom:6.0pt;text-align:
     justify'><span style='font-family:"Calibri",sans-serif'>Prevent,
     investigate or provide notice of fraud or unlawful or criminal activity,
     unauthorized access to or use of personal information, our data system
     services, and to meet governmental and institutional policy obligations; </span></li>
 <li class=MsoNormal style='margin-top:6.0pt;margin-bottom:6.0pt;text-align:
     justify'><span style='font-family:"Calibri",sans-serif'>Enforce and carry
     out contracts and agreements with our customers; </span></li>
 <li class=MsoNormal style='margin-top:6.0pt;margin-bottom:6.0pt;text-align:
     justify'><span style='font-family:"Calibri",sans-serif'>Investigate and
     resolve disputes and security issues and to enforce our <a href="/terms">Terms of Service</a></span><span
     style='font-family:"Calibri",sans-serif'></span><span
     style='font-family:"Calibri",sans-serif'>;</span></li>
 <li class=MsoNormal style='margin-top:6.0pt;margin-bottom:6.0pt;text-align:
     justify'><span style='font-family:"Calibri",sans-serif'>Comply with legal
     obligations; and</span></li>
 <li class=MsoNormal style='margin-top:6.0pt;margin-bottom:6.0pt;text-align:
     justify'><span style='font-family:"Calibri",sans-serif'>For any other
     lawful, legitimate business purpose.  </span></li>
</ul>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify'><b><span style='font-family:"Calibri",sans-serif'><a class=msocomanchor
id="_anchor_3" onmouseover="msoCommentShow('_anchor_3','_com_3')"
onmouseout="msoCommentHide('_com_3')" href="#_msocom_2"
language=JavaScript name="_msoanchor_3">3. 
           SHARING OF YOUR INFORMATION</a></span></b></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify'><span style='font-family:"Calibri",sans-serif'>We
may share your personal information in the instances described below.  For
further information on your choices regarding your information, see the
“Control Over Your Information” section below.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify'><span style='font-family:"Calibri",sans-serif'>We
may share your personal information with: </span></p>

<ul style='margin-top:0in' type=disc>
 <li class=MsoNormal style='margin-top:6.0pt;margin-bottom:6.0pt;text-align:
     justify'><b><span style='font-family:"Calibri",sans-serif'>Other Users of
     the Service at Your Request/Direction.  </span></b><span style='font-family:
     "Calibri",sans-serif'>If you register for an account and engage on the
     StartupOS platform, you may choose to connect with another user on the
     Service and share, at your request and direction, certain profile and
     company information (e.g., username, profile photo, job title and other
     work-related information and content, your approximate location, links to
     your profiles on various social networks, and other preferences and
     information) and any other information and other content you provide about
     your business.  You have control over certain information that is visible
     to others when you connect with them on the Service. For more information
     on these controls and settings, please see the <a href="#_msoanchor_5" class=msocomoff>Control Over Your
     Information</a> </span><span
     style='font-family:"Calibri",sans-serif'>section.</span></li>
 <li class=MsoNormal style='margin-top:6.0pt;margin-bottom:6.0pt;text-align:
     justify'><b><span style='font-family:"Calibri",sans-serif'>Sponsors</span></b><span
     style='font-family:"Calibri",sans-serif'>. With your permission and at
     your direction, we may also share information about you or your business
     with StartupOS Sponsors, including certain profile and company information
     (e.g., username, job title, company name, company stage, venture score,
     and your approximate location). You may change your sharing permissions at
     any time through your user account setting page.  </span></li>
 <li class=MsoNormal style='margin-top:6.0pt;margin-bottom:6.0pt;text-align:
     justify'><b><span style='font-family:"Calibri",sans-serif'>Affiliates.  </span></b><span
     style='font-family:"Calibri",sans-serif'>We may share personal information
     with other companies and brands owned or controlled by StartupOS and other
     companies owned by or under common ownership as StartupOS, which also
     includes our subsidiaries (i.e., any organization we own or control) or
     our ultimate holding company (i.e., any organization that owns or controls
     us) and any subsidiaries it owns.  These companies will use your personal
     information in the same way as we can under this Privacy Policy.</span></li>
 <li class=MsoNormal style='margin-top:6.0pt;margin-bottom:6.0pt;text-align:
     justify'><b><span style='font-family:"Calibri",sans-serif'>Service
     Providers.  </span></b><span style='font-family:"Calibri",sans-serif'>We
     may share personal information with third-party service providers that
     perform services on our behalf, as needed to carry out their work for us,
     which may include identifying and serving targeted advertisements,
     providing mailing services, payment processors, providing tax and
     accounting services, web hosting, or providing analytic services. </span></li>
 <li class=MsoNormal style='margin-top:6.0pt;margin-bottom:6.0pt;text-align:
     justify'><b><span style='font-family:"Calibri",sans-serif'>Consent.  </span></b><span
     style='font-family:"Calibri",sans-serif'>We may disclose personal
     information with any other entity disclosed to you at the time we collect
     your information or with your consent or at your direction, for example if
     you direct us to share your personal information with an investor or
     mentor on our platform.  </span></li>
 <li class=MsoNormal style='margin-top:6.0pt;margin-bottom:6.0pt;text-align:
     justify'><b><span style='font-family:"Calibri",sans-serif'>Business
     Transfers. </span></b><span style='font-family:"Calibri",sans-serif'> We
     may disclose personal information with other parties in connection with a
     company transaction, such as a merger, sale of company assets or shares,
     reorganization, financing, change of control or acquisition of all or a
     portion of our business by another company or third party, or in the event
     of a bankruptcy or related or similar proceedings. </span></li>
 <li class=MsoNormal style='margin-top:6.0pt;margin-bottom:6.0pt;text-align:
     justify'><b><span style='font-family:"Calibri",sans-serif'>Regulatory or
     Legal Requirements, Safety and Terms Enforcement.  </span></b><span
     style='font-family:"Calibri",sans-serif'>We may disclose personal
     information to third parties as required by law or subpoena or if we
     reasonably believe that such action is necessary to (a) comply with the
     law and the reasonable requests of law enforcement; (b) to enforce our <a href="/terms">Terms of Service</a> </span><span
     style='font-family:"Calibri",sans-serif'>or to protect the security or
     integrity of our Service; and/or (c) to exercise or protect the rights,
     property, or personal safety of StartupOS, our visitors, or others.  We
     may also disclose personal information to governmental regulatory
     authorities as required by law, including for tax or accounting purposes,
     in response to their requests for such information or to assist in
     investigations.  </span></li>
</ul>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify'><span style='font-family:"Calibri",sans-serif'>We
may also use and share information with others in de-identified, aggregated or
otherwise anonymized form that does not reasonably identify you directly as an
individual.   </span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify;page-break-after:avoid'><b><span
style='font-family:"Calibri",sans-serif'><a class=msocomanchor
id="_anchor_4" onmouseover="msoCommentShow('_anchor_4','_com_4')"
onmouseout="msoCommentHide('_com_4')" href="#_msocom_4"
language=JavaScript name="_msoanchor_4">4.             THIRD-PARTY TRACKING
AND ONLINE ADVERTISING </a></span></b></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify;page-break-after:avoid'><span
style='font-family:"Calibri",sans-serif'>We participate in interest-based
advertising and use third-party advertising companies to serve you targeted
advertisements based on your online browsing history and your interests.  We
permit third-party online advertising networks, social media companies, and
other third-party services to collect information about your use of our Service
over time so that they may play or display ads on our Service, on other
websites, apps, or services you may use, and on other devices you may use.  Typically,
though not always, the information used for interest-based advertising is
collected through cookies or similar tracking technologies.  We may share a
common account identifier (such as an e-mail address or user ID) or hashed data
with our third-party advertising partners to help identify you across devices. 
We and our third-party partners use this information to make the advertisements
you see online more relevant to your interests, as well as to provide
advertising-related services such as reporting, attribution, analytics, and
market research.   </span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify;page-break-after:avoid'><span
style='font-family:"Calibri",sans-serif'>To learn more about interest-based
advertising and how you may be able to opt out of some of this advertising, you
may wish to visit the Digital Advertising Alliance’s (DAA) resources and/or the
Network Advertising Initiative (“NAI”)’s online resources, at </span><a
href="http://www.aboutads.info/choices"><span style='font-family:"Calibri",sans-serif'>http://www.aboutads.info/choices</span></a><span
style='font-family:"Calibri",sans-serif'> or </span><a
href="http://www.networkadvertising.org/choices"><span style='font-family:"Calibri",sans-serif'>http://www.networkadvertising.org/choices</span></a><span
style='font-family:"Calibri",sans-serif'>, </span><a href="http://and/"><span
style='font-family:"Calibri",sans-serif'>and</span></a><span style='font-family:
"Calibri",sans-serif'> you can opt out of receiving some interest-based
advertisements on mobile apps by visiting </span><a
href="http://www.aboutads.info/appchoices"><span style='font-family:"Calibri",sans-serif'>http://www.aboutads.info/appchoices</span></a><span
style='font-family:"Calibri",sans-serif'>.  You may also be able to limit
interest-based advertising through the settings menu on your mobile device by turning
off “personalized ads” (iOS) or selecting “opt-out of interest-based ads”
(Android).  You may also be able to opt out of some – but not all –
interest-based advertising served by mobile ad networks by visiting </span><a
href="http://youradchoices.com/appchoices"><span style='font-family:"Calibri",sans-serif'>http://youradchoices.com/appchoices</span></a><span
style='font-family:"Calibri",sans-serif'> and downloading the mobile AppChoices
app.  </span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify;page-break-after:avoid'><span
style='font-family:"Calibri",sans-serif'>Please note that some advertising
opt-outs may not be effective unless your browser is set to accept cookies.  Furthermore,
if you use a different device, change browsers, or delete the opt-out cookies,
you may need to perform the opt-out task again.  </span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify;page-break-after:avoid'><b><i><u><span
style='font-family:"Calibri",sans-serif'>Google Analytics and Advertising</span></u></i></b><span
style='font-family:"Calibri",sans-serif'>.  We use Google Analytics to
recognize you and link the devices you use when you visit our site or Service
on your browser, log in to your account on our Service, or otherwise engage
with us.  We share a unique identifier, like a user ID or hashed e-mail
address, with Google to facilitate the service.  Google Analytics allows us to
better understand how our users interact with our Service and to tailor our
advertisements and content to you.  For information on how Google Analytics
collects and processes data, as well as how you can control information sent to
Google, review Google's site “How Google uses data when you use our partners’ sites
or apps” located at </span><a
href="../../../../../smg1/AppData/Local/Microsoft/Windows/INetCache/Content.Outlook/ZSW31GF3/www.google.com/policies/privacy/partners/"><span
style='font-family:"Calibri",sans-serif'>www.google.com/policies/privacy/partners/</span></a><span
style='font-family:"Calibri",sans-serif'>.  You can learn about Google
Analytics’ currently available opt-outs, including the Google Analytics Browser
Ad-On here </span><a href="https://tools.google.com/dlpage/gaoptout/"><span
style='font-family:"Calibri",sans-serif'>https://tools.google.com/dlpage/gaoptout/</span></a><span
style='font-family:"Calibri",sans-serif'>.  </span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify;page-break-after:avoid'><span
style='font-family:"Calibri",sans-serif'>We may also utilize certain forms of
display advertising and other advanced features through Google Analytics, such
as Remarketing with Google Analytics, Google Display Network Impression
Reporting, <a name="OLE_LINK2">the DoubleClick Campaign Manager Integration</a>,
and Google Analytics Demographics and Interest Reporting.  These features
enable us to use first-party cookies (such as the Google Analytics cookie) and
third-party cookies (such as the DoubleClick advertising cookie) or other
third-party cookies together to inform, optimize, and display ads based on your
past visits to the Service.  You may control your advertising preferences or
opt out of certain Google advertising products by visiting the Google Ads
Preferences Manager, currently available at </span><a
href="https://google.com/ads/preferences"><span style='font-family:"Calibri",sans-serif'>https://google.com/ads/preferences</span></a><span
style='font-family:"Calibri",sans-serif'>, or by visiting NAI’s online
resources at </span><a href="http://www.networkadvertising.org/choices"><span
style='font-family:"Calibri",sans-serif'>http://www.networkadvertising.org/choices</span></a><span
style='font-family:"Calibri",sans-serif'>.    </span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify;page-break-after:avoid'><b><span
style='font-family:"Calibri",sans-serif'><a class=msocomanchor
id="_anchor_5" onmouseover="msoCommentShow('_anchor_5','_com_5')"
onmouseout="msoCommentHide('_com_5')" href="#_msocom_5"
language=JavaScript name="_msoanchor_5">5.            CONTROL OVER YOUR
INFORMATION</a></span></b></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify'><b><span style='font-family:"Calibri",sans-serif'>Modifying
Account Information.  </span></b><span style='font-family:"Calibri",sans-serif'>If
you have an account for our Service, you have the ability to modify certain
information in your account, including your contact information,  audio or
video session recordings, as well as what information about you or your company
is visible to other users at your request, through the account setting page or
a similar option provided on the Service.  If you have any questions about
modifying or updating any information in your account, please contact us at </span><a
href="mailto:support@startupos.com"><span style='font-family:"Calibri",sans-serif'>support@startupos.com</span></a><span
style='font-family:"Calibri",sans-serif'>.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify'><b><span style='font-family:"Calibri",sans-serif'>How
to Control Your Email Communications Preferences. </span></b><span
style='font-family:"Calibri",sans-serif'>You can stop receiving promotional
email communications from us by clicking on the “unsubscribe link” provided in
such communications.  We make every effort to promptly process all unsubscribe
requests.  You may not opt out of service-related communications (e.g., account
verification, transactional communications, changes/updates to features of the
Service, technical and security notices).</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify'><b><span style='font-family:"Calibri",sans-serif'><a class=msocomanchor
id="_anchor_6" onmouseover="msoCommentShow('_anchor_6','_com_6')"
onmouseout="msoCommentHide('_com_6')" href="#_msocom_6"
language=JavaScript name="_msoanchor_6">6. 
           CHILDREN’S PRIVACY</a></span></b></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify'><span style='font-family:"Calibri",sans-serif'>StartupOS
does not knowingly collect or solicit any information from anyone under the age
of 13 on this Service.  In the event that we learn that we have inadvertently
collected personal information from a child under age 13, we will delete that
information as quickly as possible.  If you believe that we might have any
information from a child under 13, please contact us at support@startupos.com. 
 </span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify'><span
style='font-family:"Calibri",sans-serif'><b><a class=msocomanchor
id="_anchor_7" onmouseover="msoCommentShow('_anchor_7','_com_7')"
onmouseout="msoCommentHide('_com_7')" href="#_msocom_7"
language=JavaScript name="_msoanchor_7">7.             SECURITY AND LOCATION
OF PROCESSING</a></b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify'><span style='font-family:"Calibri",sans-serif'>StartupsOS
uses reasonable administrative, technical and physical safeguards to protect the
privacy of personal information. You have a shared responsibility to keep your
personal information safe. You should use a device password, an updated and
supported browser, install the latest security updates and anti-virus software
on your device, and, if you create an account, choose a unique and strong
password.  </span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify'><span style='font-family:"Calibri",sans-serif'>StartupOS
is based in the United States. StartupOS Sponsors and service providers may be
located in other jurisdictions. Personal information is processed in the United
States and in other countries in which our service providers are located. With
your consent, personal information may be disclosed to StartupOS Sponsors in
other jurisdictions than where you are located. We cannot guarantee that the
privacy protections in the countries to which your personal information is
transferred will be as protective as the jurisdiction in which you reside. </span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify'><b><span style='font-family:"Calibri",sans-serif'><a class=msocomanchor
id="_anchor_8" onmouseover="msoCommentShow('_anchor_8','_com_8')"
onmouseout="msoCommentHide('_com_8')" href="#_msocom_8"
language=JavaScript name="_msoanchor_8">8. 
           LINKS TO OTHER WEB SITES AND SERVICES</a></span></b></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify'><span style='font-family:"Calibri",sans-serif'>The
Service may contain links to and from third-party websites of our business
partners, advertisers, and social media sites and our users may post links to
third-party websites.  If you follow a link to any of these websites, please
note that these websites have their own privacy policies and that we do not
accept any responsibility or liability for their policies.  We strongly
recommend that you read their privacy policies and terms and conditions of use
to understand how they collect, use, and share information.  We are not
responsible for the privacy practices or the content on the websites of
third-party sites.  </span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify'><b><span style='font-family:"Calibri",sans-serif'><a class=msocomanchor
id="_anchor_9" onmouseover="msoCommentShow('_anchor_9','_com_9')"
onmouseout="msoCommentHide('_com_9')" href="#_msocom_9"
language=JavaScript name="_msoanchor_9">9. 
           HOW TO CONTACT US</a></span></b></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify'><span style='font-family:"Calibri",sans-serif'>If
you have any questions about this Privacy Policy or the Service, please contact
us at support@startupos.com.   </span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify;page-break-after:avoid'><b><span
style='font-family:"Calibri",sans-serif'><a class=msocomanchor
id="_anchor_10" onmouseover="msoCommentShow('_anchor_10','_com_10')"
onmouseout="msoCommentHide('_com_10')" href="#_msocom_10"
language=JavaScript name="_msoanchor_10">10.          CHANGES TO OUR PRIVACY
POLICY</a></span></b></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify;page-break-after:avoid'><span
style='font-family:"Calibri",sans-serif'>We may modify or update this Privacy
Policy from time to time to reflect the changes in our business and practices,
so you should review this page periodically.  When we change the policy in a
material manner, we will let you know and update the last updated date at the
top of this page.  If you object to any changes, you may close your account.  Continuing
to use our Service after we publish changes to this Privacy Policy means that
you are consenting to the changes.</span></p>

<p class=O-BodyText style='margin-top:6.0pt;margin-right:0in;margin-bottom:
6.0pt;margin-left:0in;text-align:justify'><span style='font-family:"Calibri",sans-serif'><b><a class=msocomanchor
id="_anchor_11" onmouseover="msoCommentShow('_anchor_11','_com_11')"
onmouseout="msoCommentHide('_com_11')" href="#_msocom_11"
language=JavaScript name="_msoanchor_11">11.         CANADIAN PRIVACY RIGHTS</a></b></span></p>

<p class=O-BodyText style='margin-top:6.0pt;margin-right:0in;margin-bottom:
6.0pt;margin-left:0in;text-align:justify'><span lang=EN style='font-family:
"Calibri",sans-serif'>If you are a resident of Canada, you may be able to
exercise certain rights with respect to your personal information that we
collect and control:</span></p>

<p class=O-BodyText style='margin-top:6.0pt;margin-right:0in;margin-bottom:
6.0pt;margin-left:.5in;text-align:justify;text-indent:-.25in'><span lang=EN
style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span lang=EN style='font-family:"Calibri",sans-serif'>Access.</span></b><span
lang=EN style='font-family:"Calibri",sans-serif'> You may ask us in writing
whether we have collected personal information about you and to obtain access
to that personal information. </span></p>

<p class=O-BodyText style='margin-top:6.0pt;margin-right:0in;margin-bottom:
6.0pt;margin-left:.5in;text-align:justify;text-indent:-.25in'><span lang=EN
style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span lang=EN style='font-family:"Calibri",sans-serif'>Correction</span></b><span
lang=EN style='font-family:"Calibri",sans-serif'>. If you believe the personal
information we have about you is inaccurate or incomplete, you can ask us to
correct it. </span></p>

<p class=O-BodyText style='margin-top:6.0pt;margin-right:0in;margin-bottom:
6.0pt;margin-left:.5in;text-align:justify;text-indent:-.25in'><span lang=EN
style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span lang=EN style='font-family:"Calibri",sans-serif'>Deletion</span></b><span
lang=EN style='font-family:"Calibri",sans-serif'>. We will delete personal
information when we no longer have a lawful basis to retain it. You can ask us
to delete personal information that you believe we no longer are required to
retain. </span></p>

<p class=O-BodyText style='margin-top:6.0pt;margin-right:0in;margin-bottom:
6.0pt;margin-left:.5in;text-align:justify;text-indent:-.25in'><span lang=EN
style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span lang=EN style='font-family:"Calibri",sans-serif'>Withdrawal
of Consent</span></b><span lang=EN style='font-family:"Calibri",sans-serif'>.
You can ask us to stop further collection, use and disclosure of your personal
information. If you make this request, we may no longer be able to provide you
with services. We may still retain your personal information if we have a
lawful basis to retain it.</span></p>

<p class=O-BodyText style='margin-top:6.0pt;margin-right:0in;margin-bottom:
6.0pt;margin-left:0in;text-align:justify'><span style='font-family:"Calibri",sans-serif'>To
exercise these rights, please contact us. See “How to Contact Us”. </span></p>

</div>

<div>

<hr class=msocomoff align=left size=1 width="33%">


</div>

</div>
`;
