import React from "react";
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Box,
  TextField,
} from "@mui/material";

export default function SelectInputMvp({
  label,
  onInputChange,
  selectedValue,
  fullWidth,
  options,
  inputKey,
  placeholder,
  labelStyle = {},
  borderNone = false,
  children,
  labelId = "select-label",
  id = "demo-simple-select",
  displayEmpty = false,
  compact = false,
  multiple = false,
  height,
  ...props
}) {
  return (
    <FormControl fullWidth={fullWidth} sx={{ ...props.wrapperstyle }}>
      <Select
        select
        displayEmpty
        value={selectedValue}
        onChange={e => onInputChange(e, inputKey)}
        renderValue={selected => {
          if (selected.length === 0) {
            return placeholder;
          }
          return selected;
        }}
        labelId={labelId}
        id={id}
        fullWidth={fullWidth}
        {...props}
        sx={{
          maxWidth: compact ? "max-content" : 1,
          height: height,
          overflow: "hidden",
          textOverflow: "ellipsis",
          "& .MuiOutlinedInput-notchedOutline": {
            border: borderNone && "none",
          },
        }}
        //inputProps={{ "aria-label": "Without label" }}
      >
        {options.map(option => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
