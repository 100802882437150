import { Box, Typography } from "@mui/material";
import React, { Fragment } from "react";
import moment from "moment";
import { DownloadPdfCell } from "./DownloadPdfCell";

export function PowerUpsActivityColumnsDef() {
	const powerUpsActivityColumns = [
		{
			field: "powerUpDateAndTime",
			headerName: "PowerUps Date and Time",
			sortable: false,
			filterable: false,
			wrapText: true,
			width: 250,
			renderCell: (params) => {
				const dateObj = moment(params.value);
				const formattedDate = dateObj.local().format("MM/DD/YYYY");
				const isFullYearMonthDayFormat = moment(params.value, "YYYY-MM-DD", true).isValid();
				let formattedTime = null;
				if (!isFullYearMonthDayFormat) {
					formattedTime = moment.utc(dateObj).local().format("hh:mm A");
				}

				return (
					<Fragment>
						<Box display="flex" flexDirection="column">
							<Typography variant="Text/xs/Medium">{formattedDate ? formattedDate : "-"}</Typography>
							<Typography variant="Text/xs/Regular">{formattedTime ? formattedTime : "-"}</Typography>
						</Box>
					</Fragment>
				);
			},
			renderHeader: (params) => (
				<Fragment>
					<Typography variant="Text/xs/Medium">{params?.colDef?.headerName}</Typography>
				</Fragment>
			),
		},
		{
			field: "powerUp",
			headerName: "PowerUp",
			sortable: false,
			filterable: false,
			wrapText: true,
			width: 250,
			renderCell: (params) => (
				<Fragment>
					<Box>
						<Typography variant="Text/xs/Regular">{params.value ? params.value : "-"}</Typography>
					</Box>
				</Fragment>
			),
			renderHeader: (params) => <Typography variant="Text/xs/Medium">{params?.colDef?.headerName}</Typography>,
		},
		{
			field: "status",
			headerName: "Status",
			sortable: false,
			filterable: false,
			wrapText: true,
			width: 250,
			renderCell: (params) => (
				<Fragment>
					<Box>
						<Typography variant="Text/xs/Regular">{params.value ? params.value : "-"}</Typography>
					</Box>
				</Fragment>
			),
			renderHeader: (params) => <Typography variant="Text/xs/Medium">{params?.colDef?.headerName}</Typography>,
		},
		{
			field: "name",
			headerName: "Name",
			sortable: false,
			filterable: false,
			wrapText: true,
			width: 250,
			renderCell: (params) => (
				<Fragment>
					<Box>
						<Typography variant="Text/xs/Regular">{params.value ? params.value : "-"}</Typography>
					</Box>
				</Fragment>
			),
			renderHeader: (params) => <Typography variant="Text/xs/Medium">{params?.colDef?.headerName}</Typography>,
		},
		{
			field: "resultsLink",
			headerName: "Results Link",
			sortable: false,
			filterable: false,
			wrapText: true,
			width: 250,
			renderCell: (params) => <DownloadPdfCell value={params} />,
			renderHeader: (params) => <Typography variant="Text/xs/Medium">{params?.colDef?.headerName}</Typography>,
		},
	];

	return powerUpsActivityColumns;
}
