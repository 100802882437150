import { Tab, Typography } from "@mui/material";
import React from "react";

function AboutTab({ label, ...otherProps }) {
	return (
		<Tab
			{...otherProps}
			disableRipple
			sx={{
				transition: "all .2s",
				textTransform: "unset",
				bgcolor: "transparent",
				color: "#98A2B3",
				p: 0,
				"&.Mui-selected": {
					color: "black",
				},
			}}
			label={
				<Typography
					variant="customValue"
					sx={{
						fontFamily: "PoppinsSemiBold",
						fontSize: "16px",
						lineHeight: "24px",
					}}
				>
					{label}
				</Typography>
			}
		/>
	);
}

export default AboutTab;
