import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { useTheme } from "@twilio-paste/theme";
import { MessageStatus } from "../../../../Messaging/store/reducers/messageListReducer";
import SendingIcon from "../../../../Messaging/components/icons/Sending";
import DeliveredIcon from "../../../../Messaging/components/icons/Delivered";
import ReadIcon from "../../../../Messaging/components/icons/Read";
import FailedIcon from "../../../../Messaging/components/icons/Failed";
import MessageActions from "./MessageActions";
import Reactions from "./Reactions";
import { Box } from "@mui/system";
import { Avatar, Typography } from "@mui/material";
import AvatarWithFallBack from "../../../../../common/AvatarWithFallBack";

const statusStyle = {
	display: "inline-block",
	verticalAlign: "middle",
	marginLeft: "4px",
};
const statusIconStyle = {
	marginLeft: "10px",
};
const MessageView = (props) => {
	//const theme = useTheme();
	const { text, getStatus, onDeleteMessage, authorMeta } = props;
	const [status, setStatus] = useState({});
	const sid = useSelector((state) => state.sid);
	const participants = useSelector((state) => state.participants)[sid] ?? [];
	const userID = String(useSelector((state) => state?.auth?.userId));
	const currentUser = useSelector((state) => state?.auth);

	useEffect(() => {
		getStatus.then((value) => setStatus(value));
	}, [getStatus]);

	//id(pin):869
	//email(pin):"mgarrison+5@startupos.com"
	//name(pin):"Mentor Garrison"
	//picture(pin):"https://s.gravatar.com/avatar/bb7cfe33c86c5ff052b4f62a836e4274?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fmg.png"
	//userType(pin):"MENTOR_USER"
	//participantState(pin):"ACTIVE"
	return (
		<>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					alignItems: "flex-start",
					alignSelf: "stretch",
					"&": props?.author === "system" && {
						background: (theme) => theme.palette.secondary.light,
					},
					rowGap: 1,
					m: 1,
				}}
			>
				<Box>
					{currentUser?.userName === authorMeta?.name ? (
						<></>
					) : (
						<AvatarWithFallBack src={authorMeta?.picture} sx={{ width: "64px", height: "64px" }} />
					)}
				</Box>
				<Box width="100%" ml={2}>
					<Box
						sx={{
							display: "flex",
							alignSelf: "stretch",
							gap: 2,
							justifyContent: "space-between",
						}}
					>
						<Typography variant="Text/xs/Medium" color={"sos.gray700"}>
							{currentUser?.userName === authorMeta?.name ? "You" : authorMeta?.name}
						</Typography>

						<Typography variant="Text/xs/Regular" color={"sos.gray600"} sx={{}}>
							{props.messageTime}
						</Typography>
					</Box>
					<Box>
						{currentUser?.userName === authorMeta?.name ? (
							<Box
								sx={(theme) => ({
									width: "100%",
									borderBottomLeftRadius: "12px",
									borderBottomRightRadius: "12px",
									borderTopLeftRadius: "12px",
									display: "flex",
									backgroundColor: theme.palette.primary.main,
									p: "4px",
									minHeight: "50px",
									alignItems: "center",
									mt: "3px",
								})}
							>
								<Typography
									component="pre"
									variant="Text/sm/Regular"
									sx={{ wordBreak: "break-all", whiteSpace: "pre-line", ml: 2, color: "#ffffff" }}
								>
									{props.text}
								</Typography>
							</Box>
						) : (
							<Box
								sx={(theme) => ({
									width: "100%",
									borderBottomLeftRadius: "12px",
									borderBottomRightRadius: "12px",
									borderTopRightRadius: "12px",
									display: "flex",
									backgroundColor: theme.palette.gray.main,
									p: "4px",
									minHeight: "50px",
									alignItems: "center",
									mt: "3px",
								})}
							>
								<Typography component="pre" variant="Text/sm/Regular" sx={{ wordBreak: "break-all", whiteSpace: "pre-line", ml: 2 }}>
									{props.text}
								</Typography>
							</Box>
						)}
					</Box>
				</Box>
			</Box>
		</>
	);
};
export default MessageView;
