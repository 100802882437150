import React, { useContext, useEffect, useState } from "react";
import { LEAF_ICON, TREE_ICON, CURRENCY_DOLLAR_SIMPLE_ICON } from "../../../../../constants";
import PublicTagList from "../../../Common/PublicTagList";
import { useSelector } from "react-redux";

export function formatNumberToString(number) {
	if (isNaN(number)) {
		return "N/A";
	} else if (number < 1000) {
		return "<$1k";
	} else if (number < 1000000) {
		const valueInThousands = Math.floor(number / 1000);
		return "$" + valueInThousands + "k";
	} else if (number < 1000000000) {
		const valueInMillions = Math.floor(number / 1000000);
		return "$" + valueInMillions + "M";
	} else if (number < 1000000000000) {
		const valueInBillions = (number / 1000000000).toFixed(2);
		return "$" + valueInBillions + "B";
	} else {
		return ">$1T";
	}
}

function StartupPublicFundingTags() {
	const [items, setitems] = useState([]);
	const startupData = useSelector((state) => state?.startupPublicProfileData?.startupPublicData);

	useEffect(() => {
		let list = [
			{
				iconUrl: LEAF_ICON,
				label: `Stage: ${startupData?.currentStage}`,
			},
			{
				iconUrl: TREE_ICON,
				label: `Seeking: ${startupData?.seeking}`,
			},
			{
				iconUrl: CURRENCY_DOLLAR_SIMPLE_ICON,
				label: (() => {
					let amount = "";
					if (startupData?.ask) {
						try {
							amount = Intl.NumberFormat("en-US", {
								notation: "compact",
								maximumFractionDigits: 1,
							}).format(Number(startupData?.ask));
						} catch (e) {
							console.log(e);
						}
					}
					return "Ask: " + amount;
				})(),
			},
		];
		setitems(list);
	}, []);

	return <PublicTagList items={items} />;
}

export default StartupPublicFundingTags;
