import React from "react";
import { FormControl, Select, MenuItem, InputLabel } from "@mui/material";

export default function SelectInputNoOutline({
	label,
	onInputChange,
	selectedValue,
	fullWidth = false,
	options,
	inputKey,
	placeholder,
	labelStyle = {},
	borderNone = false,
	children,
	labelId = "select-label-nooutline",
	id = "demo-simple-select-nooutline",
	displayEmpty = false,
	compact = false,
	multiple = false,
	height,
	selectSxProps,
	MenuProps,
	formControlProps,
	...props
}) {
	return (
		<FormControl fullWidth={fullWidth} sx={{ verticalAlign: "unset", ...props.wrapperstyle }} {...formControlProps}>
			{label && (
				<InputLabel id="select-label" sx={labelStyle}>
					{label}
				</InputLabel>
			)}
			<Select
				multiple={multiple}
				displayEmpty
				placeholder={placeholder}
				labelId={labelId}
				id={id}
				label={label}
				fullWidth={fullWidth}
				select
				onChange={(e) => onInputChange(e, inputKey)}
				value={selectedValue || []}
				sx={{
					boxShadow: "none",
					".MuiOutlinedInput-notchedOutline": { border: "none" },
					"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
						border: "none",
					},
					...selectSxProps,
				}}
				MenuProps={{
					sx: {
						".MuiMenu-paper": { boxShadow: (theme) => theme.palette.secondary.selectNoOutlineShadow },
					},
					...MenuProps,
				}}
				{...props}
			>
				{children
					? children
					: options.map((option) => (
							<MenuItem key={option} value={option}>
								{option}
							</MenuItem>
					  ))}
			</Select>
		</FormControl>
	);
}
