import { Box, Typography, Divider, useMediaQuery, Chip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { DELET_ICON_V3, DRAG_ICON_V1 } from "../../../../constants";
import { CustomToggleSwitch } from "../../../PowerUP/compositions/DoIt/AreYouEnterprise/md-are-you-enterprise";

function AdminPowerupCard({
	title,
	imageUrl,
	isAiPowered,
	shortDescription,
	readOnly = true,
	onDelete,
	isSettingsOpen = false,
	entityStatus,
	onStatusChange = () => {},
}) {
	const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
	const [isActive, setIsActive] = useState(entityStatus === "DEACTIVE");

	const handleToggle = () => {
		const newStatus = !isActive;
		setIsActive(newStatus);
		onStatusChange(newStatus ? "DEACTIVE" : "ACTIVE");
	};

	useEffect(() => {
		setIsActive(entityStatus === "DEACTIVE");
	}, [entityStatus, isActive]);

	return (
		<React.Fragment>
			<Box display={"flex"} gap={2} flexWrap={isSmallScreen && "wrap"} sx={{}}>
				{!readOnly && <Box component="img" src={DRAG_ICON_V1} alt="drag" />}

				<Box sx={{ width: "200px", position: "relative", opacity: isSettingsOpen && isActive ? 0.3 : 1 }}>
					<Box
						width={"inherit"}
						component="img"
						src={imageUrl}
						alt="PowerPack"
						sx={{
							height: "163px",
							objectFit: "cover",
							borderRadius: "19px",
							minHeight: "163px",
							minWidth: "200px",
						}}
					/>
					{isAiPowered && (
						<Box
							sx={{
								position: "absolute",
								top: "1rem",
								left: "1rem",
							}}
						>
							<Chip
								sx={{ background: "white" }}
								label={
									<Typography variant="Text/xs/Medium" color={"#344054"}>
										AI Powered
									</Typography>
								}
							/>
						</Box>
					)}
				</Box>
				<Box sx={{ width: 1, opacity: isSettingsOpen && isActive ? 0.3 : 1 }}>
					<Box
						display="flex"
						flexDirection={isSmallScreen ? "column" : "row"}
						alignItems={isSmallScreen ? "flex-start" : "flex-end"}
						justifyContent="space-between"
						gap={2}
						mb={2}
					>
						<Typography
							sx={{
								maxWidth: isSmallScreen ? 1 : "50%",
								overflow: "hidden",
								display: "-webkit-box",
								WebkitBoxOrient: "vertical",
								WebkitLineClamp: 2,
								lineHeight: "1.2em",
								maxHeight: "3.1em",
								height: "50px",
								"& p": { margin: 0 },
								"& ul": { marginBlockStart: 0, marginBlockEnd: 0 },
								wordBreak: "break-word",
							}}
							variant="Text/md/Semibold"
						>
							{title}
						</Typography>
					</Box>

					<Typography variant="Text/sm/Regular">
						{" "}
						<Box
							sx={{
								overflow: "hidden",
								display: "-webkit-box",
								WebkitBoxOrient: "vertical",
								WebkitLineClamp: 6,
								lineHeight: "1.2em",
								maxHeight: "8.1em",
								height: "115px",
								"& p": { margin: 0 },
								"& ul": { marginBlockStart: 0, marginBlockEnd: 0 },
								wordBreak: "break-word",
							}}
							dangerouslySetInnerHTML={{
								__html: shortDescription,
							}}
						/>{" "}
					</Typography>
				</Box>
				{isSettingsOpen ? (
					<Box display={"flex"} flexDirection={"row"} sx={{ opacity: "none" }}>
						<Typography variant="Text/sm/Semibold" color={"#667085"} width={"150px"}>
							Hide PowerPack
						</Typography>

						<CustomToggleSwitch checked={isActive} onChange={handleToggle} />
					</Box>
				) : (
					!readOnly && <Box pr={3} sx={{ cursor: "pointer" }} component="img" src={DELET_ICON_V3} alt="delete" onClick={onDelete} />
				)}
			</Box>
		</React.Fragment>
	);
}

export default AdminPowerupCard;
