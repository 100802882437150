import React, { Fragment, useState } from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import TooltipWrapper from "../../common/Tooltip";
import { INVESTOR_PROFILE_EDIT_LIST } from "../../constants";
import { EditTabs, EditTabsItem } from "./style";
import MyPhotosSection from "../../components/UpdatedMentorProfile/MyPhotosSection";
import ExperienceAndEducationTab from "../../components/UpdatedMentorProfile/ExperienceAndEducationSection";
import Availability from "../Meetings/Availability";
import { useWindowSize } from "../../utils/windowResize";
import InvestorDetailsTab from "../../components/UpdatedInvestorProfile/InvestorDetailsSection";

const EditInvestorProfileController = ({ handleClose }) => {
	const [currentTab, setCurrentTab] = useState(0);
	const size = useWindowSize();
	const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
	const responsiveVideoWidth = size.width * 0.69;
	const responsiveVideoHeight = responsiveVideoWidth * 0.65;
	const handleChange = (event, newValue) => {
		setCurrentTab(newValue);
	};

	return (
		<>
			<Box>
				<EditTabs variant="scrollable" scrollButtons="auto" allowScrollButtonsMobile value={currentTab} onChange={handleChange}>
					{INVESTOR_PROFILE_EDIT_LIST.map((item, index) => {
						const tabItem = (
							<EditTabsItem
								selected={currentTab === item.id}
								onClick={(event) => {
									handleChange(event, index);
								}}
								disabled={item.disabled}
								disableRipple
								key={item.id}
								label={
									<Typography
										variant="Text/sm/Semibold"
										sx={{
											color: "inherit",
											background: "inherit",
											borderRadius: "100px",
										}}
									>
										{item.title}
									</Typography>
								}
							/>
						);
						return <Fragment key={index}>{item.disabled ? <TooltipWrapper>{tabItem}</TooltipWrapper> : tabItem}</Fragment>;
					})}
				</EditTabs>
			</Box>
			<Box>
				{currentTab === 0 && <InvestorDetailsTab handleClose={handleClose} />}
				{currentTab === 1 && <Availability />}
				{currentTab === 2 && <MyPhotosSection handleClose={handleClose} />}

				{currentTab === 3 && <ExperienceAndEducationTab handleClose={handleClose} />}
			</Box>
		</>
	);
};

export default EditInvestorProfileController;
