import React, { useEffect, useContext } from "react";
import { Grid, Stack, Typography, Chip, Box, Skeleton } from "@mui/material";
import { UserCardContainer } from "../UserAboutCard/style";
import UserProfileCardSection from "../UserProfileCardSection";
import { BUILDINGS_ICON, LOCATION_ICON_V2, PRODUCT_ICON, WATCH_ICON, STARS_ICON, GREY_BUILDING_ICON_V2 } from "../../../constants";
import StatusButton from "../../../components/Profile/Components/StatusButton";
import UserProfileTagList from "../UserTagList";
import { STATES } from "../../../constants/powerup-data";
import { useSelector } from "react-redux";
import ProgressCompletionCalculator from "../../../utils/progressCompletionCalculator";
import { useParams, useHistory } from "react-router-dom";

const UserStatsCard = ({ context }) => {
	const getJasonFormatedValue = (value) => {
		try {
			if (value) {
				let data = JSON.parse(value);
				if (data.length > 0) {
					return data;
				} else {
					return "No Data";
				}
			}
		} catch (e) {
			if (value) {
				let data = value.split(",");
				if (data.length > 0) {
					return data;
				} else {
					return "No Data";
				}
			} else {
				return "No Data";
			}
		}
	};

	const userDetails = useSelector((state) => state?.userProfileData?.userData);
	const expertise = getJasonFormatedValue(userDetails?.expertise) ? getJasonFormatedValue(userDetails?.expertise) : [];
	const superpower = getJasonFormatedValue(userDetails?.superpower) ? getJasonFormatedValue(userDetails?.superpower) : [];
	const isLoading = useSelector((state) => state?.userProfileData?.loading);
	const history = useHistory();
	const params = useParams();
	const userId = params?.id;
	const { handleEditProfileRedirections, setStatsPercentage } = useContext(context);
	const totalFields = 4;
	let completedFields = 0;
	if (userDetails?.city || userDetails?.state) {
		completedFields += 1;
	}
	if (userDetails?.timezone) {
		completedFields += 1;
	}
	if (expertise?.length > 0 || superpower?.length > 0) {
		completedFields += 1;
	}
	if (userDetails?.industries) {
		completedFields += 1;
	}
	// if (userDetails?.productOffering) {
	// 	completedFields += 1;
	// }

	useEffect(() => {
		setStatsPercentage(ProgressCompletionCalculator(totalFields, completedFields));
	}, [completedFields]);

	const statsTags = [
		{
			icon: LOCATION_ICON_V2,
			value: (() => {
				let city = userDetails?.city;
				let area = STATES.find((s) => s.name === userDetails?.state);
				area = area ? area?.abbreviation : userDetails?.state;
				if (city && area) {
					return `${city}, ${area}`;
				} else if (!city && userDetails?.state) {
					return userDetails?.state;
				} else if (city && !userDetails?.state) {
					return city;
				} else return "No Data";
			})(),
			isSvg: true,
			type: "Location",
		},
		{
			icon: WATCH_ICON,
			value: userDetails?.timezone ? userDetails?.timezone : "No Data",
			isSvg: true,
			type: "Time Zone",
		},
		{
			icon: GREY_BUILDING_ICON_V2,
			value: getJasonFormatedValue(userDetails?.industries) ? getJasonFormatedValue(userDetails?.industries) : "No Data",
			isSvg: true,
			type: "Industry",
		},
		// {
		// 	icon: PRODUCT_ICON,
		// 	value: getJasonFormatedValue(userDetails?.productOffering) ? getJasonFormatedValue(userDetails?.productOffering) : "No Data",
		// 	isSvg: true,
		// 	type: "Product Offering",
		// },
	];
	return (
		<UserCardContainer>
			<Stack spacing={3}>
				{isLoading ? (
					<Skeleton animation="wave" variant="rectangular" width={100} height={50} />
				) : (
					!userId && (
						<StatusButton
							percentage={ProgressCompletionCalculator(totalFields, completedFields)}
							handleRedirection={handleEditProfileRedirections}
						/>
					)
				)}
				<Grid container rowSpacing={2}>
					<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
						{isLoading ? (
							<Skeleton animation="wave" variant="rectangular" width={200} height={200} />
						) : (
							<UserProfileCardSection icon={STARS_ICON} title={"Stats"} iconColor={""} iconHeight={"26px"} iconWidth={"26px"}>
								<UserProfileTagList tagList={statsTags} />
							</UserProfileCardSection>
						)}
					</Grid>
					<Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
						{isLoading ? (
							<Skeleton animation="wave" variant="rectangular" width={"100%"} height={200} />
						) : (
							<UserProfileCardSection title={"Superpowers & Expertise"} iconColor={""} iconHeight={"26px"} iconWidth={"26px"}>
								<Box>
									{(expertise && expertise.length > 0) || superpower.length > 0
										? expertise.concat(superpower)?.map((item) => {
												return (
													<Chip
														variant="outlined"
														label={<Typography variant="Text/sm/Regular">{item}</Typography>}
														sx={{ mr: 1, mt: 1 }}
													/>
												);
										  })
										: "No Data"}
								</Box>
							</UserProfileCardSection>
						)}
					</Grid>
				</Grid>
			</Stack>
		</UserCardContainer>
	);
};

export default UserStatsCard;
