import React, { useState, useEffect, Fragment, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	Box,
	useMediaQuery,
	Slide,
	Dialog,
	DialogContent,
	Fab,
	Typography,
	useTheme,
	Button,
	CircularProgress,
	FormControlLabel,
	Checkbox,
	TextField,
} from "@mui/material";
import { useWindowSize } from "../../../../utils/windowResize";
import { DRAWER_CLOSE_BLACK } from "../../../../constants/image";
import { generateShade, generateTint, useThemeContext } from "../../../../constants/Theme/ThemeContext";
import ColorPicker from "../../../PowerUP/compositions/DoIt/PitchDeckGenerator/ColorPicker";
import UpdatedTextInputField from "../../../../common/UpdatedTextInputField";
import { updatePartnerTheme } from "./functions";
import services from "../../../../services";
import { PARTNER_SEGMENTATIONS } from "../../../../constants";
import { InputLabelStyle, InputPropsStyle } from "../../../NewOnboard/Components";
import { USER_TYPES } from "../../../../utils/userTypes";
import * as yup from "yup";
import { setSlackWorkspaceActions } from "../../../../modules/actions/slackWorkspaceActions";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="left" ref={ref} {...props} />;
});

const validationSchema = yup.object().shape({
	navigationLabel: yup.string().max(255, "Navigation Label must be at most 255 characters"),
	slackUrl: yup.string().url("Please enter a valid URL for Slack workspace"),
});
function ThemeEditFlyout({ open, partnerCode, onClose }) {
	const size = useWindowSize();
	const { getThemeByUser } = useThemeContext();
	const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
	const responsiveDrawerWidth = isSmallScreen ? size.width * 1 : size.width * 0.85;
	const [show, setShow] = useState(false);
	const theme = useTheme();
	const isAdmin = useSelector((store) => store?.auth?.isAdmin);
	const userType = useSelector((store) => store?.auth?.userType);
	const [errors, setErrors] = useState({});

	const [tempColors, settempColors] = useState({
		primary: theme?.palette?.primary?.main,
		secondary: theme?.palette?.secondary?.main,
		tertiary: theme?.palette?.tertiary?.main,
	});
	const [isSegmented, setIsSegmented] = useState(false);
	const [partnerSegments, setPartnerSegments] = useState(PARTNER_SEGMENTATIONS);

	const [logoUrl, setlogoUrl] = useState(null);

	const [partnerName, setpartnerName] = useState(null);

	const [imgErr, setimgErr] = useState(false);

	const [isLoading, setisLoading] = useState(false);

	const [slackWorkspaceName, setSlackWorkspaceName] = useState("");

	const [slackUrl, setSlackUrl] = useState("");

	const maxWidth = "1920px"; // Figma specifies max content width

	const dispatch = useDispatch();

	useEffect(() => {
		if (logoUrl) {
			setimgErr(false);
		}
	}, [logoUrl]);

	useEffect(() => {
		if (open) {
			setShow(true);
			setisLoading(true);
			getThemeObj();
		} else {
			setTimeout(() => {
				setShow(false);
			}, 700);
		}
	}, [open]);

	useEffect(() => {
		setSlackWorkspaceName("");
		setSlackUrl("");
	}, [partnerCode]);
	const getThemeObj = async () => {
		const resp = await services.getPartnerTheme(partnerCode).catch((e) => {
			console.log("Unable to grab partner theme. Failed with error:", e);
		});

		setlogoUrl(resp?.data?.data?.logoUrl);
		setpartnerName(resp?.data?.data?.partnerName);
		settempColors({
			primary: resp?.data?.data?.colorPrimary,
			secondary: resp?.data?.data?.colorSecondary,
			tertiary: resp?.data?.data?.colorTertiary,
		});
		setIsSegmented(resp?.data?.data?.isSegmented);
		let segments = [...partnerSegments];
		segments[0].checked = resp?.data?.data?.isStartupSegmented;
		segments[1].checked = resp?.data?.data?.isFounderSegmented;
		segments[2].checked = resp?.data?.data?.isMentorSegmented;
		segments[3].checked = resp?.data?.data?.isInvestorSegmented;
		segments[4].checked = resp?.data?.data?.isEventSegmented;

		setPartnerSegments(segments);
		setSlackWorkspaceName(resp?.data?.data?.slackWorkspaceName || "");
		setSlackUrl(resp?.data?.data?.slackWorkspaceUrl || "");

		setisLoading(false);
	};

	// const saveThemeSettings = async () => {
	// 	const res = await updatePartnerTheme(
	// 		{
	// 			partnerName: partnerName,
	// 			logoUrl: logoUrl,
	// 			colorPrimary: tempColors?.primary,
	// 			colorSecondary: tempColors?.secondary,
	// 			colorTertiary: tempColors?.tertiary,
	// 			isStartupSegmented: partnerSegments[0]?.checked ?? false,
	// 			isFounderSegmented: partnerSegments[1]?.checked ?? false,
	// 			isMentorSegmented: partnerSegments[2]?.checked ?? false,
	// 			isInvestorSegmented: partnerSegments[3]?.checked ?? false,
	// 			isEventSegmented: partnerSegments[4]?.checked ?? false,
	// 			slackWorkspaceName: slackWorkspaceName,
	// 			slackWorkspaceUrl: slackUrl,
	// 		},
	// 		isAdmin,
	// 		partnerCode
	// 	);
	// 	dispatch(
	// 		setSlackWorkspaceActions({
	// 			slackWorkspaceName: slackWorkspaceName || "",
	// 			slackUrl: slackUrl || "",
	// 		})
	// 	);
	// 	getThemeByUser(partnerCode);
	// 	setTimeout(() => {
	// 		setShow(false);
	// 		onClose();
	// 	}, 700);
	// };

	const saveThemeSettings = async () => {
		try {
			await validationSchema.validate({
				navigationLabel: slackWorkspaceName,
				slackUrl: slackUrl,
			});

			const res = await updatePartnerTheme(
				{
					partnerName: partnerName,
					logoUrl: logoUrl,
					colorPrimary: tempColors?.primary,
					colorSecondary: tempColors?.secondary,
					colorTertiary: tempColors?.tertiary,
					isStartupSegmented: partnerSegments[0]?.checked ?? false,
					isFounderSegmented: partnerSegments[1]?.checked ?? false,
					isMentorSegmented: partnerSegments[2]?.checked ?? false,
					isInvestorSegmented: partnerSegments[3]?.checked ?? false,
					isEventSegmented: partnerSegments[4]?.checked ?? false,
					slackWorkspaceName: slackWorkspaceName,
					slackWorkspaceUrl: slackUrl,
				},
				isAdmin,
				partnerCode
			);

			dispatch(
				setSlackWorkspaceActions({
					slackWorkspaceName: slackWorkspaceName || "",
					slackUrl: slackUrl || "",
				})
			);
			getThemeByUser(partnerCode);
			setTimeout(() => {
				setShow(false);
				onClose();
			}, 700);
		} catch (error) {
			if (error.name === "ValidationError") {
				const fieldErrors = {};
				error.inner.forEach((e) => {
					fieldErrors[e.path] = e.message;
				});
				setErrors((prevErrors) => ({ ...prevErrors, ...fieldErrors }));
			}
		}
	};

	const getButton = (color, label) => (
		<Button
			variant="DS1"
			sx={{
				backgroundColor: color,
				"&:hover": {
					backgroundColor: generateShade(color, 0.7),
				},
				"&:focus": {
					boxShadow: `0px 0px 0px 4px ${generateTint(color, 0.7)}, 0px 1px 2px 0px rgba(16, 24, 40, 0.05)`,
				},
			}}
		>
			{label} Color
		</Button>
	);

	const handlePartnerSegmentChange = (partnerId, segmentedValue) => {
		let segments = [...partnerSegments];
		segments.forEach((segment) => {
			if (segment.id === partnerId) {
				segment.checked = segmentedValue;
			}
		});
		setPartnerSegments(segments);
	};

	const handleNavigationLabelChange = useCallback(async (event) => {
		const value = event.target.value;
		setSlackWorkspaceName(value);

		try {
			await validationSchema.validateAt("navigationLabel", { navigationLabel: value });
			setErrors((prevErrors) => ({ ...prevErrors, navigationLabel: "" }));
		} catch (err) {
			setErrors((prevErrors) => ({ ...prevErrors, navigationLabel: err.message }));
		}
	}, []);

	const handleSlackUrlChange = useCallback(async (event) => {
		const value = event.target.value;
		setSlackUrl(value);

		try {
			await validationSchema.validateAt("slackUrl", { slackUrl: value });
			setErrors((prevErrors) => ({ ...prevErrors, slackUrl: "" }));
		} catch (err) {
			setErrors((prevErrors) => ({ ...prevErrors, slackUrl: err.message }));
		}
	}, []);

	return (
		<Dialog
			fullWidth
			TransitionComponent={Transition}
			transitionDuration={700}
			open={show}
			onClose={() => {}} //close button is in dialog content
			PaperProps={{
				sx: {
					position: "fixed",
					right: 0,
					top: 0,
					bottom: 0,
					borderRadius: "30px 0px 0px 30px",
					boxShadow: "0px 5px 16px rgba(0, 0, 0, 0.3)",
					background: "#F5F9FA",
					width: `${responsiveDrawerWidth}px`,
					maxWidth: maxWidth, // Set your width here
					m: 0,
					maxHeight: "100%",
					minHeight: "100%",
				},
			}}
		>
			<DialogContent
				sx={{
					border: "none",
					p: 0,
					flex: 1,
					display: "flex",
					mb: "30px",
					pt: "60px",
				}}
			>
				<Box width={responsiveDrawerWidth} sx={{ maxWidth: maxWidth, display: "flex", flexDirection: "column", rowGap: "60px" }}>
					<Box
						sx={{
							px: {
								lg: 8,
								md: 4,
								xs: 2,
							},
							pb: "30px",
							display: "flex",
							flexDirection: "column",
							gap: "32px",
							maxWidth: "1920px", // Figma specifies max content width
						}}
					>
						<Box
							display="flex"
							alignItems={size.width > 1000 ? "center" : "baseline"}
							justifyContent="space-between"
							flexDirection={size.width > 1000 ? "row" : "column"}
						>
							<Box
								sx={{
									ml: "auto",
									position: "fixed",
									right: 20,
									display: "flex",
									gap: "8px",
								}}
							>
								<Fab
									sx={{
										bgcolor: (theme) => theme.palette.secondary.black,
										color: (theme) => theme.palette.secondary.white,
										"&:hover": {
											bgcolor: (theme) => theme.palette.secondary.black,
										},
									}}
									aria-label="close"
									color="secondary.white"
									onClick={() => {
										setShow(false);
										onClose();
									}}
								>
									<Box component={"img"} src={DRAWER_CLOSE_BLACK} alt="close-drawer" />
								</Fab>
							</Box>
						</Box>

						{isLoading ? (
							<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
								<CircularProgress />
							</Box>
						) : (
							<Fragment>
								<Box
									sx={{
										p: 3,
										display: "flex",
										flexDirection: "column",
										gap: 2,
									}}
								>
									<Box sx={{ flex: 1 }}>
										<Typography variant="Text/md/Semibold" component={"p"}>
											Functional Settings for {partnerName ? partnerName : `'${partnerCode}'`}
										</Typography>
										<Typography variant="Text/sm/Regular" component={"p"}>
											Control component functionality and visibility for your Founders and Startups.
										</Typography>
									</Box>
									<Box sx={{ flex: 1, gap: 1, display: "flex", flexWrap: "wrap" }}>
										{partnerSegments.map((partnerSegment) => {
											return (
												<FormControlLabel
													label={<Typography variant="Text/sm/Regular">{partnerSegment?.label}</Typography>}
													control={
														<Checkbox
															value={partnerSegment?.checked}
															checked={partnerSegment?.checked}
															onChange={(event) => handlePartnerSegmentChange(partnerSegment?.id, event.currentTarget.checked)}
														/>
													}
													sx={{
														borderRadius: "100px",
														background: "white",
														boxShadow: "0px 0px 0px 4px rgba(255,255,255,0)",
														padding: "0.75rem 1.25rem",
														width: "fit-content",
													}}
												/>
											);
										})}
										{/* <FormControlLabel
											label={<Typography variant="Text/sm/Regular">Segment my Founders and Startups from StartupOS</Typography>}
											control={
												<Checkbox
													value={isSegmented}
													checked={isSegmented}
													onChange={(event) => setIsSegmented(event.currentTarget.checked)}
												/>
											}
											sx={{
												borderRadius: "100px",
												background: "white",
												boxShadow: "0px 0px 0px 4px rgba(255,255,255,0)",
												padding: "0.75rem 1.25rem",
											}}
										/> */}
									</Box>
								</Box>

								{/* Slack Integration */}
								{(isAdmin || userType === USER_TYPES.PARTNER_USER) && (
									<Box
										sx={{
											p: 3,
											display: "flex",
											alignItems: "center",
										}}
									>
										<Box sx={{ flex: 1 }}>
											<Typography variant="Text/md/Semibold" component={"p"}>
												Slack Instance for {partnerName ? partnerName : `'${partnerCode}'`} Accelerator
											</Typography>
											<Typography variant="Text/sm/Regular" component={"p"}>
												Replace Social Feed with a link to your shared Slack instance
											</Typography>
											<Box sx={{ m: 2 }}>
												<Typography variant="Text/sm/Semibold" component={"p"}>
													Navigation Label
												</Typography>
												<TextField
													fullWidth
													id="navigation-label"
													value={slackWorkspaceName}
													onChange={handleNavigationLabelChange}
													sx={{
														"& .MuiOutlinedInput-notchedOutline": {
															border: false ? "1px solid #F04438 !important" : "1px solid #DDDDDD",
														},
														mt: 2,
													}}
													InputProps={{ style: InputPropsStyle }}
													InputLabelProps={InputLabelStyle}
													error={!!errors.navigationLabel}
													helperText={errors.navigationLabel}
													placeholder="Your Workspace"
												/>
											</Box>
											<Box sx={{ m: 2 }}>
												<Typography variant="Text/sm/Semibold" component={"p"}>
													Slack Url
												</Typography>
												<TextField
													fullWidth
													id="slack-url"
													value={slackUrl}
													onChange={handleSlackUrlChange}
													sx={{
														"& .MuiOutlinedInput-notchedOutline": {
															border: false ? "1px solid #F04438 !important" : "1px solid #DDDDDD",
														},
														mt: 2,
													}}
													InputProps={{ style: InputPropsStyle }}
													InputLabelProps={InputLabelStyle}
													error={!!errors.slackUrl}
													helperText={errors.slackUrl}
													placeholder="Workspace Url"
												/>
											</Box>
										</Box>
									</Box>
								)}

								<Box
									sx={{
										p: 3,
										display: "flex",
										alignItems: "center",
									}}
								>
									<Box sx={{ flex: 1 }}>
										<Typography variant="Text/md/Semibold" component={"p"}>
											Theme Settings for {partnerName ? partnerName : `'${partnerCode}'`}
										</Typography>
										<Typography variant="Text/sm/Regular" component={"p"}>
											Select primary, secondary, and tertiary colors to customize your in app experience.
										</Typography>
									</Box>
								</Box>

								<Box sx={{ display: "flex", flex: 1, p: 3 }}>
									<ColorPicker
										flexProp={"row"}
										colors={tempColors}
										setColors={(val) => {
											settempColors(val);
										}}
									/>
								</Box>

								<Box
									sx={{
										borderRadius: "12px",
										border: "1px solid var(--gray-200, #EAECF0)",
										background: "var(--base-white, #FFF)",
										boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)",
										p: 3,
										display: "flex",
										flexDirection: "column",
										gap: 2,
									}}
								>
									<Box sx={{ flex: 1 }}>
										<Typography variant="Text/sm/Regular" component={"p"}>
											Examples of app elements styled using your theme colors.
										</Typography>
									</Box>
									<Box sx={{ display: "flex", gap: 1 }}>
										{getButton(tempColors?.primary, "Primary")}
										{getButton(tempColors?.secondary, "Secondary")}
										{getButton(tempColors?.tertiary, "Tertiary")}
									</Box>
								</Box>

								<Box
									sx={{
										borderRadius: "12px",
										border: "1px solid var(--gray-200, #EAECF0)",
										background: "var(--base-white, #FFF)",
										boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)",
										p: 3,
										display: "flex",
										flexDirection: "column",
										gap: 1,
									}}
								>
									<Box sx={{ flex: 1 }}>
										<Typography variant="Text/sm/Regular" component={"p"}>
											URL of company logo to personalize the in app experience for your users:
										</Typography>
									</Box>
									<Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
										<UpdatedTextInputField
											fullWidth
											label={"Image URL"}
											value={logoUrl}
											onChange={(event) => setlogoUrl(event?.target?.value)}
											placeholder={"https://your-company.com/img/logo.png"}
											textFieldProps={{
												height: "40px",
											}}
										/>

										{logoUrl && (
											<Fragment>
												<Box sx={{ display: "flex", flex: 1 }}>
													<Box
														sx={{
															height: "60px",
															width: "auto",
															border: "2px dashed lightgray",
															padding: "4px",
															borderRadius: "10px",
														}}
														component={"img"}
														src={logoUrl}
														onError={() => setimgErr(true)}
													/>
												</Box>
												{imgErr && (
													<Typography
														variant="Text/xs/Semibold"
														color="error"
														sx={{ border: "2px dashed lightgray", padding: "4px", borderRadius: "10px" }}
													>
														Unable to load image preview. Check image source.
													</Typography>
												)}
											</Fragment>
										)}
									</Box>
								</Box>

								<Box sx={{ mt: "auto", display: "flex", justifyContent: "flex-end" }} onClick={() => saveThemeSettings()}>
									<Button variant="DS1">Save Theme Settings</Button>
								</Box>
							</Fragment>
						)}
					</Box>
				</Box>
			</DialogContent>
		</Dialog>
	);
}

export default ThemeEditFlyout;
