import React, { useState } from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import { Box, useMediaQuery, useTheme, Select, MenuItem } from "@mui/material";
import { USERS_PURPLE_OUTLINE_ICON } from "../../../constants";
import { KeyboardArrowDownRounded } from "@mui/icons-material";
import { labelOptions } from "../FilterDialog/dataLabel";
import { useDispatch, useSelector } from "react-redux";
import { setNetworkingDashboardData } from "../../../modules/actions/networkingActions";
import { handleMixPanel } from "../../../utils/mixPanelEventHandling";

export const SelectIconComponent = () => (
	<KeyboardArrowDownRounded
		sx={{
			position: "absolute",
			right: "16px",
			top: "calc(50% - 0.5em)",
			color: "rgba(0, 0, 0, 0.54)",
			pointerEvents: "none",
		}}
	/>
);

export default function FindMeButton({ handleFilterChange }) {
	const theme = useTheme();
	const [anchorEl, setAnchorEl] = useState(null);
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
	const [selected, setSelected] = useState(null);
	const filteredValue = useSelector((state) => state.networking.filteredValue);
	const dispatch = useDispatch();

	const handleClick = (event) => {
		// handleMixPanel("Community: Find me a Mentor Clicked");
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		if (selected) {
			handleMixPanel("Find me a Mentor", {
				Category: selected,
			});
			const dataObj = { type: "expertise", checkboxValue: selected };
			const newFilteredValue = [dataObj];
			dispatch(setNetworkingDashboardData({ filteredValue: newFilteredValue }));
			handleFilterChange(newFilteredValue);
		}
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? "simple-popover" : undefined;

	const screenWidth = window.innerWidth;

	return (
		<Box>
			<Button aria-describedby={id} sx={{ borderRadius: "100px" }} variant="containedPurpleStyle" onClick={handleClick}>
				<Typography variant="Text/sm/Semibold" color="inherit">
					Find me a mentor
				</Typography>
			</Button>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				PaperProps={{
					sx: {
						// width: isSmallScreen ? `${window.innerWidth}px` : "500px",
						boxShadow: "0px 10px 25px rgba(0, 0, 0, 0.05)",
						borderRadius: "24px",
						padding: isSmallScreen ? "16px" : "40px",
					},
				}}
			>
				<Box display="flex" flexDirection="column" rowGap={2}>
					<Box display="flex" justifyContent="space-between">
						<Box component="img" src={USERS_PURPLE_OUTLINE_ICON} />
						<CloseIcon onClick={handleClose} sx={{ ml: "auto", cursor: "pointer" }} />
					</Box>
					<Box display="flex" flexDirection="column" rowGap={3}>
						<Typography variant="Text/lg/Semibold">Filter my mentor list</Typography>
						<Typography variant="Text/sm/Regular">I’m looking for someone to help me with</Typography>
						<Select
							fullWidth
							value={selected}
							// name={name}
							placeholder="select"
							displayEmpty
							onChange={(e) => setSelected(e.target.value)}
							IconComponent={SelectIconComponent}
							sx={{
								borderRadius: "40px",
								"& .MuiOutlinedInput-notchedOutline": { borderColor: "#DDDDDD" },
								"& .MuiSelect-select": { fontWeight: "500", fontSize: "16px", lineHeight: "24px" },
								padding: "1.5px 6px",
							}}
							MenuProps={{
								PaperProps: {
									style: {
										maxHeight: 48 * 3.5 + 8,
										boxShadow: "0px 5px 16px rgba(0, 0, 0, 0.3)",
									},
								},
							}}
						>
							{labelOptions["Areas of Expertise"].map((option) => (
								<MenuItem key={option} value={option}>
									{option}
								</MenuItem>
							))}
						</Select>
						<Box display="flex">
							<Box ml="auto">
								<Button sx={{ borderRadius: "100px" }} variant="containedPurpleStyle" onClick={handleClose}>
									<Typography variant="Text/sm/Semibold" color="secondary.white">
										Filter Now!
									</Typography>
								</Button>
							</Box>
						</Box>
					</Box>
				</Box>
			</Popover>
		</Box>
	);
}
