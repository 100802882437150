import React from "react";
import TreeItem from "@mui/lab/TreeItem";
import TreeView from "@mui/lab/TreeView";
import CropSquareOutlinedIcon from "@mui/icons-material/CropSquareOutlined";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import SquareRoundedIcon from "@mui/icons-material/SquareRounded";
import IndeterminateCheckBoxOutlinedIcon from "@mui/icons-material/IndeterminateCheckBoxOutlined";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setInvestorDashboardData } from "../../modules/actions";

export default function BasicCheckBoxTree({ lable, treeData, handleUncheck, type }) {
	const name = lable === "Location" ? "countryName" : "industryname";

	const child = lable === "Location" ? "states" : "child";
	const filteredValue = useSelector((state) => state?.investorDashboard?.filteredValue) || [];
	const dispatch = useDispatch();

	const getChildByName = (node, name) => {
		let array = [];

		const getAllChild = (nodes) => {
			if (nodes === null) return [];
			array.push(nodes[name]);
			if (Array.isArray(nodes[child])) {
				nodes[child].forEach((node) => {
					array = [...array, ...getAllChild(node)];
					array = array.filter((v, i) => array.indexOf(v) === i);
				});
			}
			return array;
		};

		const getNodeByName = (nodes, name) => {
			if (nodes[name] === name) {
				return nodes;
			} else if (Array.isArray(nodes[child])) {
				let result = null;
				nodes[child].forEach((node) => {
					if (!!getNodeByName(node, name)) {
						result = getNodeByName(node, name);
					}
				});
				return result;
			}
			return null;
		};

		return getAllChild(getNodeByName(node, name));
	};

	const removeParentNode = (treeData, nodeName, array) => {
		dispatch(
			setInvestorDashboardData({
				filteredValue: filteredValue.filter((value) => value.checkboxValue !== nodeName),
			})
		);
	};

	const getOnChange = (checked, nodes, event) => {
		const allNode = getChildByName(treeData, nodes[name]);

		let array = allNode;

		array = array.filter((v, i) => array.indexOf(v) === i);

		if (!checked) {
			removeParentNode(treeData, event.target.value, array);
		} else {
			let newObj = { type: type, checkboxValue: event.target.value };

			dispatch(
				setInvestorDashboardData({
					filteredValue: [...filteredValue, newObj],
				})
			);
		}
	};

	const renderTree = (nodes) => (
		<Box sx={{ display: "flex" }}>
			<TreeItem
				key={nodes[name]}
				nodeId={nodes[name]}
				sx={{
					"& .MuiTreeItem-content.Mui-filtteredValue": {
						background: (theme) => theme.palette.secondary.white,
						"&:hover": {
							background: (theme) => theme.palette.secondary.white,
						},
						"&:active": {
							background: (theme) => theme.palette.secondary.white,
						},
						"&:focused": {
							background: (theme) => theme.palette.secondary.white,
						},
						"&:filtteredValue": {
							background: (theme) => theme.palette.secondary.white,
						},
					},
					"& .MuiTreeItem-content.Mui-filtteredValue.Mui-focused": {
						background: (theme) => theme.palette.secondary.white,
						"&:hover": {
							background: (theme) => theme.palette.secondary.white,
						},
						"&:active": {
							background: (theme) => theme.palette.secondary.white,
						},
						"&:focused": {
							background: (theme) => theme.palette.secondary.white,
						},
						"&:filtteredValue": {
							background: (theme) => theme.palette.secondary.white,
						},
					},
				}}
				label={
					<FormControlLabel
						label={<Typography variant="title_medium">{nodes[name]}</Typography>}
						control={
							<Checkbox
								value={nodes[name]}
								checked={filteredValue.some((item) => item.checkboxValue === nodes[name])}
								onChange={(event) => getOnChange(event.currentTarget.checked, nodes, event)}
								onClick={(e) => e.stopPropagation()}
								icon={<CropSquareOutlinedIcon sx={{ fontSize: "20px" }} />}
								checkedIcon={<SquareRoundedIcon sx={{ fontSize: "20px", color: (theme) => theme.palette.primary.primaryPurple }} />}
							/>
						}
					></FormControlLabel>
				}
			>
				{Array.isArray(nodes[child]) ? nodes[child].map((node) => renderTree(node)) : null}
			</TreeItem>
		</Box>
	);

	return (
		<TreeView defaultCollapseIcon={<IndeterminateCheckBoxOutlinedIcon />} defaultExpandIcon={<AddBoxOutlinedIcon />}>
			{renderTree(treeData)}
		</TreeView>
	);
}
