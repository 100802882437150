import React, { useEffect, useState } from "react";
import { Grid, Box } from "@mui/material";

import { OnboardCard } from "../ui";
import FounderIcon from "../../Assets/founder-icon.svg";
import InvestorIcon from "../../Assets/investor-icon.svg";
import MentorIcon from "../../Assets/mentor-icon.svg";
import { USER_TYPES } from "../../../../utils/userTypes";

const ACCOUNT_TYPES = [
	{ id: 0, icon: FounderIcon, title: "Founder", type: USER_TYPES.STARTUP_OWNER, desc: "I am the owner or co-owner of a startup or startup idea!" },
	{ id: 1, icon: MentorIcon, title: "Mentor", type: USER_TYPES.MENTOR_USER, desc: "I am here to guide startups & help them grow!" },
	{ id: 2, icon: InvestorIcon, title: "Investor", type: USER_TYPES.INVESTOR_USER, desc: "I’m here to help startups grow financially." },
];

export const AccountType = ({ isNext, currentStep, data, onActive, onUpdate, onUserType }) => {
	const [selectedType, setSelectedType] = useState(null);

	const handleAccountType = (index) => {
		setSelectedType(index);
		onActive(true);
		onUserType(ACCOUNT_TYPES[index]?.type);
	};

	useEffect(() => {
		if (data.userType) {
			setSelectedType(ACCOUNT_TYPES.findIndex((account) => account.type === data.userType));
			onActive(true);
		}
	}, [data]);

	useEffect(() => {
		if (isNext) {
			onUpdate(currentStep, { userType: ACCOUNT_TYPES[selectedType]?.type });
		}
	}, [isNext]);

	return (
		<Box display="flex" justifyContent="center">
			<Grid
				container
				justifyContent="center"
				spacing="35px"
				sx={{
					maxWidth: "1210px",
				}}
			>
				{ACCOUNT_TYPES.map((account) => (
					<Grid key={account.id} item display="flex" justifyContent="center" xs={12} md={4}>
						<OnboardCard
							key={account.id}
							id={account.id}
							title={account.title}
							desc={account.desc}
							selectedId={selectedType}
							icon={account.icon}
							onSelect={handleAccountType}
						/>
					</Grid>
				))}
			</Grid>
		</Box>
	);
};
