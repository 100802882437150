import React, { useEffect, useState } from "react";
import { Box, Link, Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";

import { handleMixPanel } from "../../../utils/mixPanelEventHandling";
import {
	BANNER_COFEE_CHILL,
	BANNER_LIGHTNING_ROUNDS,
	BANNER_DEMO_DAY,
	BANNER_DEMO_DAY_NO_BUTTON,
	BANNER_MEET_STARTUP,
	CALENDAR_MAIN_BLACK,
	SILICON_VALLEY_BANK_SO_EVENT,
	Get_PREPPED_FOR_STARTUP_EVENT,
	EXPIRED_BANNER_LIGHTNING_ROUNDS,
	EXPIRED_GET_PREPPED_FOR_STARTUP_EVENT,
	PITCH_PANEL_PRACTIVE_WITH_MENTORS_EVENT,
	PITCH_PANEL_PRACTIVE_WITH_MENTORS_EVENT_EXPIRED,
} from "../../../constants";
import services from "../../../services";
import { setCommonDialogConfirmationData } from "../../../modules/actions";

// {
// 	eventName: "TAM SAM SOM",
// 	imageSrc: `${cloudFrontUrl}/Images/Events/banner-tamsamsom-20230127.webp`,
// 	registrationURL: "https://us02web.zoom.us/webinar/register/WN_iQtTaWNpQ9C56Xyb9y9HMg",
// 	recordingURL: "",
// 	isPastEvent: false
// },

// {
// 	eventName: "Pitch Day Prep",
// 	imageSrc: `${cloudFrontUrl}/Images/Events/banner-pitchdayprep.webp`,
// 	registrationURL: "https://us02web.zoom.us/webinar/register/WN_N_cv6OiPQASwRGSiCq1HLw",
// 	recordingURL: "",
// 	isPastEvent: false
// },
//{
//	eventName: "StartupOS Score",
//	imageSrc: `${cloudFrontUrl}/Images/Events/banner-startuposscore.webp`,
//	registrationURL: "https://us02web.zoom.us/webinar/register/WN_UTSQTvIxTMWp0rF2pa8frg",
//	recordingURL: "",
//	isPastEvent: false,
//},

const cloudFrontUrl = process.env.REACT_APP_CLOUDFRONT_URL;
const bannerData = [
	{
		eventName: "Demo day",
		imageSrc: BANNER_DEMO_DAY,
		registrationURL: "",
		recordingURL: "",
		isPastEvent: false,
	},
	{
		eventName: "Silicon Valley Bank & StartupOS Pitch Event!",
		imageSrc: SILICON_VALLEY_BANK_SO_EVENT,
		registrationURL: "",
		recordingURL: "",
		isPastEvent: false,
	},
	{
		eventName: "Pitch Panel Practice with Mentors",
		imageSrc: PITCH_PANEL_PRACTIVE_WITH_MENTORS_EVENT_EXPIRED,
		registrationURL: "https://us02web.zoom.us/webinar/register/3916960115110/WN_lKm0pcK4QSqT_HN9nwPCBA",
		recordingURL: "",
		isPastEvent: true,
	},
	{
		eventName: "Get Prepped for StartupOS Pitch Events!",
		imageSrc: EXPIRED_GET_PREPPED_FOR_STARTUP_EVENT,
		registrationURL: "https://us02web.zoom.us/webinar/register/7216952206500/WN_w8si1qyHTMqnH2MXi8jaiA",
		recordingURL: "",
		isPastEvent: true,
	},
	{
		eventName: "Lightning Rounds with Paul",
		imageSrc: EXPIRED_BANNER_LIGHTNING_ROUNDS,
		registrationURL: "https://us02web.zoom.us/webinar/register/3516932356539/WN_kfTRguMLSUaV27EWW_Lz5w#/registration",
		recordingURL: "",
		isPastEvent: true,
	},
	{
		eventName: "Coffee & Chill",
		imageSrc: BANNER_COFEE_CHILL,
		registrationURL: "",
		recordingURL: "",
		isPastEvent: true,
	},
	{
		eventName: "Meet the New StartupOS",
		imageSrc: `${cloudFrontUrl}/Images/Events/Meet the New StartupOS!.png`,
		registrationURL: "",
		recordingURL: "",
		isPastEvent: true,
	},
	{
		eventName: "Forecastr Past",
		imageSrc: `${cloudFrontUrl}/Images/Events/forecastr-past.png`,
		registrationURL: "",
		recordingURL: "",
		isPastEvent: true,
	},
	{
		eventName: "Weekly Q&A-past",
		imageSrc: `${cloudFrontUrl}/Images/Events/Weekly Q&A-past.png`,
		registrationURL: "",
		recordingURL: "",
		isPastEvent: true,
	},
	{
		eventName: "Mighty Founder",
		imageSrc: `${cloudFrontUrl}/Images/past-banner-MFAS-20230429.png`,
		registrationURL: "",
		recordingURL: "https://www.youtube.com/watch?v=OCTnreyCMyQ&feature=youtu.be",
		isPastEvent: true,
	},
	{
		eventName: "Pitch Perfect 3",
		imageSrc: `${cloudFrontUrl}/Images/20230222-Pitch+Day+3-Banner-Post.png`,
		registrationURL: "",
		recordingURL: "https://www.youtube.com/watch?v=J-g7pz8zPL8",
		isPastEvent: true,
	},
	{
		eventName: "Prepare for Pitch Perfect 3",
		imageSrc: `${cloudFrontUrl}/Images/PP3_Prep_Webinar_Archive.png`,
		registrationURL: "",
		recordingURL: "https://www.youtube.com/watch?v=ZdmrBDdKCuU",
		isPastEvent: true,
	},
	{
		eventName: "Partner Marketplace",
		imageSrc: `${cloudFrontUrl}/Images/Events/past-banner-partnerMplace-20230203.png`,
		registrationURL: "",
		recordingURL: "https://www.youtube.com/watch?v=FshBpM7ivvI",
		isPastEvent: true,
	},
	{
		eventName: "Survey Workshop!",
		imageSrc: `${cloudFrontUrl}/Images/Events/past-banner-20230120-Surveys.webp`,
		registrationURL: "",
		recordingURL: "https://www.youtube.com/watch?v=fN00EPNSvLE",
		isPastEvent: true,
	},
	{
		eventName: "Persona Builder Workshop!",
		imageSrc: `${cloudFrontUrl}/Images/Events/past-banner-persona-20230113.webp`,
		registrationURL: "",
		recordingURL: "https://www.youtube.com/watch?v=sG4yme6Lklc",
		isPastEvent: true,
	},
	{
		eventName: "Get Started",
		imageSrc: `${cloudFrontUrl}/Images/Events/Event-Get-Started-with-StartupOS-website-banner-v2.webp`,
		registrationURL: "",
		recordingURL: "https://www.youtube.com/watch?v=iHRTak0k42A",
		isPastEvent: true,
	},
	{
		eventName: "Pitch Perfect 2",
		imageSrc: `${cloudFrontUrl}/Images/Events/Pitch-Perfect-2-Banner-Dec-8-2022-v1.webp`,
		registrationURL: "",
		recordingURL: "https://www.youtube.com/watch?v=JgyzgJORFLs",
		isPastEvent: true,
	},
	{
		eventName: "Pitch Day Prep",
		imageSrc: `${cloudFrontUrl}/Images/Events/StartupOS-Events-Page-Banner-v1.webp`,
		registrationURL: "",
		recordingURL: "https://www.youtube.com/@StartupOS",
		isPastEvent: true,
	},
];

const EventsBanner = () => {
	const dispatch = useDispatch();
	const userDetails = useSelector((state) => state?.auth);
	const { isPremiumPlanAccess, userType } = useSelector((store) => store?.auth);
	const [isSVGExpand, setIsSVGExpand] = useState(false);
	const [isPitchExpand, setIsPitchExpand] = useState(false);

	useEffect(() => {
		let pastEvents = bannerData.filter((event) => event.isPastEvent).length;
		let upcomingEvents = bannerData.length - pastEvents;
		handleMixPanel("Events List Viewed", {
			"Upcoming Events Count": upcomingEvents,
			"Past Events Count": pastEvents,
			subscription_status: isPremiumPlanAccess ? "Subscribed" : "Not Subscribed",
			user_type: userType,
		});
	}, []);

	const actionApiCall = (eventName) => {
		if (eventName === "Demo day" || eventName === "Silicon Valley Bank & StartupOS Pitch Event!") {
			if (eventName === "Demo day") {
				setIsPitchExpand(true);
				setIsSVGExpand(false);
			} else {
				setIsPitchExpand(false);
				setIsSVGExpand(true);
			}

			dispatch(
				setCommonDialogConfirmationData({
					applyToPitchOpen: true,
				})
			);
		}

		services
			.updateStreakActionApi({
				actionType: "EVENT",
				actionOnId: userDetails?.userId,
				actionOnType: "EVENT",
				metaData: null,
			})
			.then()
			.catch();
	};

	return (
		<>
			<Helmet>
				<meta charSet="utf-8" />
				<title>StartupOS - Events</title>
			</Helmet>
			<Box
				sx={{
					px: {
						lg: 8,
						md: 4,
						xs: 2,
					},
					pb: "30px",
					display: "flex",
					flexDirection: "column",
					gap: "32px",
					maxWidth: "1920px", // Figma specifies max content width
					margin: "0 auto",
				}}
			>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						gap: "12px",
					}}
				>
					<Box component={"img"} src={CALENDAR_MAIN_BLACK} alt="dashbaord" sx={{ width: "30px", height: "30px" }} />
					<Typography variant="growth_card_subtitle" color="secondary.black">
						Events
					</Typography>
				</Box>
				<Box
					textAlign={"center"}
					sx={(theme) => ({
						px: 2,
						mx: 2,
						[theme.breakpoints.down("md")]: {
							flexDirection: "column",
							px: 1,
							mx: 1,
						},
						cursor: "pointer",
					})}
				>
					<Box>
						<Box textAlign="left" mb={2}>
							<Typography variant="h2" align="left">
								Upcoming Events
							</Typography>
						</Box>

						<Box>
							{bannerData.map(
								(banner) =>
									!banner.isPastEvent && (
										<>
											{banner.registrationURL ? (
												<Link href={banner.registrationURL} target={"_blank"}>
													<img
														style={{
															width: "100%",
															maxWidth: "1140px",
															marginBottom: "22px",
														}}
														src={banner.imageSrc}
														alt={banner.eventName}
														onClick={() => {
															handleMixPanel("Event Details Viewed", {
																"Event Name": banner.eventName,
																user_type: userType,
																"Is Upcoming Event": true,
																subscription_status: isPremiumPlanAccess ? "Subscribed" : "Not Subscribed",
															});
															actionApiCall();
														}}
													/>
												</Link>
											) : (
												<img
													style={{
														width: "100%",
														maxWidth: "1140px",
														marginBottom: "22px",
													}}
													src={banner.imageSrc}
													alt={banner.eventName}
													onClick={() => {
														handleMixPanel("Event Details Viewed", {
															"Event Name": banner.eventName,
															user_type: userType,
															"Is Upcoming Event": true,
															subscription_status: isPremiumPlanAccess ? "Subscribed" : "Not Subscribed",
														});
														actionApiCall(banner.eventName);
													}}
												/>
											)}
										</>
									)
							)}
						</Box>

						{/* <Box>
					<img
						style={{
							width: "67%",
							borderRadius: "8px",
						}}
						src={EVENT_BANNER_2}
						alt="Getting Started"
					/>
				</Box> */}
					</Box>
					<Box mt={12}>
						<Box textAlign="left" mb={2}>
							<Typography variant="h2">Past Events</Typography>
						</Box>

						<Box>
							{bannerData.map(
								(banner) =>
									banner.isPastEvent && (
										<>
											{banner.recordingURL ? (
												<Link href={banner.recordingURL} target={"_blank"}>
													<img
														style={{
															width: "100%",
															maxWidth: "1140px",
															marginBottom: "22px",
														}}
														src={banner.imageSrc}
														alt={banner.eventName}
														onClick={() => {
															handleMixPanel("Event Details Viewed", {
																"Event Name": banner.eventName,
																user_type: userType,
																"Is Upcoming Event": false,
															});
															actionApiCall();
														}}
													/>
												</Link>
											) : (
												<img
													style={{
														width: "100%",
														maxWidth: "1140px",
														marginBottom: "22px",
													}}
													src={banner.imageSrc}
													alt={banner.eventName}
													onClick={() => {
														handleMixPanel("Event Details Viewed", {
															"Event Name": banner.eventName,
															user_type: userType,
															"Is Upcoming Event": false,
															subscription_status: isPremiumPlanAccess ? "Subscribed" : "Not Subscribed",
														});
														actionApiCall();
													}}
												/>
											)}
										</>
									)
							)}
						</Box>
					</Box>
				</Box>
			</Box>
		</>
	);
};

export default EventsBanner;
