import React, { useState } from "react";
import { Box, Button, Stack, Typography, LinearProgress, Tooltip, Skeleton, useMediaQuery } from "@mui/material";
import Dropzone from "react-dropzone";
import { NEW_PROFILE_LOGO, UPLOAD_ICON_V2 } from "../../../../constants";
import { toastContainer } from "../../../../utils/toast";
import services from "../../../../services";
import { GravatarURL } from "../../../../constants/profile-data";
import CommonSlideDialog from "../../../../common/CommonSlideDialog";
import { DELETE_ICON_V2, UPLOAD_ICON_NEW, UPLOAD_V1_ICON } from "../../../../constants/image";

const EventImageUploader = ({
	title,
	shape,
	uploadedImage,
	setUploadedImage,
	fileType,
	editTitle,
	uploadIcon = UPLOAD_ICON_V2,
	readOnly = false,
	sx = {},
	imageSizeInstruction,
	aspectRatio = undefined,
	setImageData,
	imgName = "",
	imgSize = 0,
	setImgName,
	setImgSize,
}) => {
	const [isCropperOpen, setIsCropperOpen] = useState(false);
	const [localFile, setLocalFile] = useState(false);
	const [cropData, setCropData] = useState(false);
	const [isDeleteOpen, setIsDeleteOpen] = useState("");
	const [deleteConfirmation, setDeleteConfirmation] = useState(false);
	const [uploadLoader, setUploadLoader] = useState(false);
	const [fileSize, setFileSize] = useState("");
	const [uploadProgress, setUploadProgress] = useState(0);

	const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

	const handleFileSelect = (file) => {
		if (file) {
			const fileSize = (file?.size / (1024 * 1024 * 1024)).toFixed(2);
			setFileSize(fileSize);
			if (fileSize < 2) {
				const reader = new FileReader();
				reader.addEventListener("load", () => setLocalFile(reader.result?.toString() || ""));
				reader.readAsDataURL(file);
				getFiles(file);
				setUploadLoader(true);
				setUploadProgress(0);

				setImgName("");
				setImgSize(0);
				setIsCropperOpen(true);
			} else {
				toastContainer("Please Upload file less than 2GB", "error");
			}
		} else {
			toastContainer(`Invalid file format for '${title}'. Please upload a file in one of the following formats: png, jpg, jpeg`, "error");
		}
	};

	const getFiles = async (data) => {
		var formData = new FormData();
		formData.append("file", data);
		setUploadLoader(true);
		formData.append("fileGroup", "PROFILE");
		services
			.uploadFile(formData, {
				onUploadProgress: (progressEvent) => {
					const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
					setUploadProgress(progress);
				},
			})
			.then((response) => {
				uploadedImage = response?.data?.data?.fileLocation;
				setImgName(response?.data?.data?.fileName);
				setImgSize(response?.data?.data?.size);

				setUploadLoader(false);
				setUploadedImage(uploadedImage);

				setIsCropperOpen(false);
				setLocalFile("");
				setCropData("");

				setImageData({
					name: response?.data?.data?.fileName,
					size: response?.data?.data?.size,
				});
			})
			.catch((e) => {
				setIsCropperOpen(false);
				setUploadLoader(false);
				setLocalFile("");
				setCropData("");
				setImageData({});
			});
	};

	const handleDelete = () => {
		setDeleteConfirmation(false);
		setIsDeleteOpen(false);
		uploadedImage = "";
		setImageData({});
		setUploadedImage(...uploadedImage);
		setImgName("");
		setImgSize(0);
	};

	return (
		<Stack spacing={2} flexDirection={"column"} sx={isSmallScreen ? { width: "370px" } : {}}>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					gap: 1,
					justifyContent: "center",
					width: isSmallScreen ? "370px" : "400px",
					margin: "0 auto",
				}}
			>
				<Typography variant="poppinsSemiBold20" sx={{ textAlign: "start" }}>
					{title}
				</Typography>

				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						borderRadius: shape === "circular" ? "50%" : "12px",
						alignSelf: "center",
						cursor: "pointer",
						...sx,
					}}
				>
					{uploadLoader ? (
						<Box
							sx={(theme) => ({
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								alignItems: "center",
								height: "120px",
								cursor: "default",
								gap: 2,
								py: 2,
							})}
						>
							{" "}
							<Box sx={{ mb: "10px" }}>
								<Box gap={1} sx={{ display: "flex", alignItems: "center", pl: 1 }}>
									<Box component={"img"} src={UPLOAD_ICON_NEW} width={30} height={30} alt="img" />
									<Stack>
										<Tooltip title={imgName} arrow>
											<Typography
												variant="Text/xs/Regular"
												sx={{
													color: (theme) => theme.palette.sos.gray600,
													width: "270px",
													textOverflow: "ellipsis",
													overflow: "hidden",
													whiteSpace: "nowrap",
												}}
											>
												{imgName}
											</Typography>
										</Tooltip>
										<Typography
											variant="Text/xs/Regular"
											sx={{
												color: (theme) => theme.palette.sos.gray600,
											}}
										>
											{imgSize ? (imgSize / 1000000).toFixed(2) : 0} MB
										</Typography>
									</Stack>

									<Box ml="auto" height={"35px"}>
										<Box
											component={"img"}
											src={DELETE_ICON_V2}
											alt="delete"
											sx={{ cursor: "pointer", ml: "30px" }}
											onClick={handleDelete}
										/>{" "}
									</Box>
								</Box>

								<Stack direction="row" alignItems="center" marginLeft="35px" marginTop="50px">
									<LinearProgress
										variant="determinate"
										value={uploadedImage ? 100 : uploadProgress}
										sx={{ width: "300px", height: "7px", borderRadius: "5px" }}
									/>
									<Typography variant="Text/xs/Medium" sx={{ ml: 1 }}>
										{`${uploadedImage ? 100 : uploadProgress}%`}
									</Typography>
								</Stack>
							</Box>
						</Box>
					) : uploadedImage ? (
						<Box
							sx={(theme) => ({
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								alignItems: "center",
								height: "120px",
								cursor: "default",
								gap: 2,
								py: 2,
							})}
						>
							<Box sx={{ mb: "10px" }}>
								<Box gap={1} sx={{ display: "flex", alignItems: "center", pl: 1 }}>
									<Box component={"img"} src={UPLOAD_ICON_NEW} width={30} height={30} alt="img" />
									<Stack>
										<Tooltip title={imgName} arrow>
											<Typography
												variant="Text/xs/Regular"
												sx={{
													color: (theme) => theme.palette.sos.gray600,
													width: "270px",
													textOverflow: "ellipsis",
													overflow: "hidden",
													whiteSpace: "nowrap",
												}}
											>
												{imgName}
											</Typography>
										</Tooltip>

										<Typography
											variant="Text/xs/Regular"
											sx={{
												color: (theme) => theme.palette.sos.gray600,
											}}
										>
											{imgSize ? (imgSize / 1000000).toFixed(2) : 0} MB
										</Typography>
									</Stack>

									<Box ml="auto" height={"35px"}>
										<Box
											component={"img"}
											src={DELETE_ICON_V2}
											alt="delete"
											sx={{ cursor: "pointer", ml: "30px" }}
											onClick={handleDelete}
										/>
									</Box>
								</Box>

								<Stack direction="row" alignItems="center" marginLeft="35px" marginTop="50px">
									<LinearProgress
										variant="determinate"
										value={uploadedImage ? 100 : uploadProgress}
										sx={{ width: "300px", height: "7px", borderRadius: "5px" }}
									/>
									<Typography variant="Text/xs/Medium" sx={{ ml: 1 }}>
										{`${uploadedImage ? 100 : uploadProgress}%`}
									</Typography>
								</Stack>
							</Box>
						</Box>
					) : (
						<Dropzone onDrop={!cropData ? (acceptedFiles) => handleFileSelect(acceptedFiles[0]) : () => {}} accept={fileType}>
							{({ getRootProps, getInputProps }) => (
								<section>
									<Box {...getRootProps()}>
										<input {...getInputProps()} />
										<Box
											sx={(theme) => ({
												display: "flex",
												flexDirection: "column",
												justifyContent: "center",
												alignItems: "center",
												height: "120px",
												gap: 2,
												py: 2,
												ml: 9,
											})}
										>
											<Box component={"img"} src={UPLOAD_V1_ICON} width={50} height={50} alt="img" />
											<Box sx={{ textAlign: "center" }}>
												<Typography
													variant="Text/xs/Semibold"
													sx={{
														color: (theme) => theme.palette.primary.main,
													}}
												>
													Click to upload
												</Typography>
												<Typography
													variant="Text/xs/Regular"
													sx={{
														color: (theme) => theme.palette.sos.gray600,
													}}
												>
													{" "}
													or drag and drop
												</Typography>
												<Typography
													variant="Text/xs/Regular"
													sx={{
														color: (theme) => theme.palette.sos.gray600,
														display: "flex",
														marginLeft: "10px",
													}}
												>
													{imageSizeInstruction}
												</Typography>
											</Box>
										</Box>
									</Box>
								</section>
							)}
						</Dropzone>
					)}
				</Box>

				<CommonSlideDialog
					disableBackDropClick={true}
					title={"Delete Image"}
					titleVariant={"poppinsSemiBold20"}
					isOpen={deleteConfirmation}
					onClose={() => {
						setDeleteConfirmation(false);
					}}
					titleStyle={{ px: 0 }}
					contentStyle={{ px: 0 }}
					footerStyle={{ p: 0 }}
					maxWidth={"sm"}
					fullWidth
					PaperProps={{
						sx: (theme) => ({
							borderRadius: theme.shape.standard13.borderRadius,
							boxShadow: "0px 5px 16px rgba(0, 0, 0, 0.3)",
							p: 3,
						}),
					}}
					dialogAction={
						<Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
							<Box>
								<Button variant="RedRounded" onClick={handleDelete}>
									Delete
								</Button>
							</Box>
							<Box>
								<Button
									variant="noOutlineNew"
									onClick={() => {
										setDeleteConfirmation(false);
									}}
									sx={{
										fontSize: "18px",
										lineHeight: "28px",
										fontFamily: "PoppinsSemiBold",
									}}
								>
									Cancel
								</Button>
							</Box>
						</Box>
					}
				>
					<Box
						sx={(theme) => ({
							textAlign: "left",
						})}
					>
						<Typography variant="title_medium">Are you sure you want to delete this image?</Typography>
					</Box>
				</CommonSlideDialog>
			</Box>

			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					gap: 1,
					height: "100%",
					width: isSmallScreen ? "200px" : "100%",
					mt: "20px",
				}}
			>
				<Box sx={{ mt: "10px" }}>
					<Typography variant="poppinsSemiBold20" sx={{ textAlign: "start" }}>
						{uploadedImage ? `${title} Preview` : `${title}`}
					</Typography>
				</Box>

				<Box
					sx={(theme) => ({
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						textAlign: "center",
						width: aspectRatio ? (aspectRatio >= 2.5 ? "400px" : "300px") : "400px",
						height: "190px",
						border: `1px solid #EAECF0`,
						borderRadius: "12px",
					})}
				>
					{uploadLoader ? (
						<Skeleton
							variant="rectangular"
							width={aspectRatio ? (aspectRatio >= 2.5 ? "400px" : "300px") : "400px"}
							height="190px"
							animation="wave"
							sx={{ borderRadius: "12px" }}
						/>
					) : uploadedImage ? (
						<Box
							component={"img"}
							src={`${uploadedImage ? (!uploadedImage.includes(GravatarURL) ? uploadedImage : NEW_PROFILE_LOGO) : NEW_PROFILE_LOGO}`}
							sx={{
								borderRadius: shape == "circular" ? "50%" : "12px",
								objectFit: "cover",
								height: "100%",
								width: 1,
							}}
							onError={(e) => {
								e.target.src = NEW_PROFILE_LOGO;
							}}
						></Box>
					) : (
						<Typography variant="Text/xs/Regular" sx={{ textAlign: "start", color: (theme) => theme.palette.sos.gray600 }}>
							No preview available
						</Typography>
					)}
				</Box>
			</Box>
		</Stack>
	);
};

export default EventImageUploader;
