import React, { useEffect, useState } from "react";
import { Typography, Box, Divider, Card, Button, Grid } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { DifficultylIcon, TeamRecommendIcon } from "../../../../common/icons";

import { POWERUPLOGO } from "../../../../constants";
import { ContentWrapper, ContentHeader } from "./styles";
import RatingComponent from "../../../../common/rating";
import ContentDetails from "./ContentDetails";
import theme from "../../../../constants/Theme";

export default function PowerUPHeader({
  valuePropsHeaderData,
  valuePropsDetails,
  powerupApiDetails,
  powerupButtonComp,
  ratingDetails,
}) {
  const getDifficultyLevel = difficultyLevel => {
    if (difficultyLevel === "EASY") {
      return "Easy";
    } else if (difficultyLevel === "MEDIUM") {
      return "Medium";
    } else {
      return "Hard";
    }
  };

  return (
    <Card
      size="large"
      sx={{ p: 0, boxShadow: theme.palette.primary.elevationLight2 }}
    >
      <Box sx={{ px: 2, pt: 2 }}>
        <ContentHeader>
          <Box sx={{ textAlign: "left" }}>
            <img src={POWERUPLOGO} alt="powerup logo" />
          </Box>
        </ContentHeader>
        <Typography sx={{ mt: 1 }} variant="h1">
          {valuePropsHeaderData.powerUpName}
        </Typography>
        <Box sx={{ my: 2, display: { xs: "none", sm: "none", md: "block" } }}>
          <Typography variant="subhead1">
            {valuePropsHeaderData.shortDescription}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{ display: { xs: "block", sm: "block", md: "none" }, px: 2, pb: 2 }}
      >
        <ContentDetails
          icon={
            <AccessTimeIcon sx={{ color:(theme)=> theme?.palette.text.primary }} />
          }
          title={"Time Estimate"}
          description={
            valuePropsHeaderData && valuePropsHeaderData.timeEstimate
          }
          titlePadding={1}
        />

        <ContentDetails
          icon={<DifficultylIcon />}
          title={"Difficulty"}
          description={
            valuePropsHeaderData &&
            getDifficultyLevel(valuePropsHeaderData.powerUpDifficulty)
          }
          titlePadding={1.8}
        />

        <ContentDetails
          icon={<TeamRecommendIcon />}
          title={"Team Recommended"}
          description={
            valuePropsHeaderData && valuePropsHeaderData.teamRecommended
          }
          titlePadding={1.5}
        />
        {valuePropsHeaderData &&
          valuePropsHeaderData.feedbackAlertDescription && (
            <ContentDetails
              icon={
                <ErrorOutlineOutlinedIcon
                  sx={{ color: (theme) => theme?.palette.text.primary }}
                />
              }
              title="Feedback Alert"
              description={
                valuePropsHeaderData &&
                valuePropsHeaderData.feedbackAlertDescription
              }
              titlePadding={1}
            />
          )}
      </Box>
      <Box sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
        <Divider />
        <Grid
          container
          sx={{ p: 2 }}
          alignItems="center"
          justifyContent={"space-between"}
        >
          <Grid item>
            <RatingComponent
              Readonly={true}
              //rating={ratingDetails?.rating}
              rating={5}
              precision={0.5}
            />
          </Grid>
          <Grid item>{powerupButtonComp}</Grid>
        </Grid>
      </Box>
    </Card>
  );
}
