import React, { useState, useEffect, useMemo } from "react";
import { Box } from "@mui/material";
import { useLocation } from "react-router-dom";
import PageTitle from "../../common/PageTitle";
import { CALENDAR_MAIN_BLACK } from "../../constants";
import { BreadcrumbsComponent } from "../../components/Breadcrumbs";
import EventDetailsCard from "../../components/Events/EventDetailsCard";
import services from "../../services";

const EventDetails = () => {
	const [eventDetails, setEventDetails] = useState(null);
	const [loading, setLoading] = useState(false);
	const location = useLocation();

	const eventId = location.pathname?.split("/")?.pop();

	const GetEventDetails = async () => {
		try {
			setLoading(true);
			const response = await services.getEventDetailsApi(eventId);
			if (response?.data?.data) {
				setLoading(false);
				setEventDetails(response?.data?.data);
			}
		} catch (error) {
			console.error("Error fetching event details:", error);
		}
	};
	useEffect(() => {
		GetEventDetails();
	}, []);
	const getNestedRoutes = useMemo(() => {
		let bedcrumDetails = [
			{ title: "Events", route: "/events" },
			{ title: eventDetails?.title || "", route: "" },
		];

		return { bedcrumDetails };
	}, [eventDetails]);

	return (
		<Box
			sx={{
				px: {
					lg: 8,
					md: 4,
					xs: 2,
				},
				pb: "30px",
				display: "flex",
				flexDirection: "column",
				gap: 2,
				maxWidth: "1920px",
				margin: "0 auto",
			}}
		>
			<PageTitle icon={CALENDAR_MAIN_BLACK} label={"Events"} />
			<BreadcrumbsComponent bedcrumDetails={getNestedRoutes?.bedcrumDetails} />
			<EventDetailsCard eventDetails={eventDetails} loading={loading} />
		</Box>
	);
};

export default EventDetails;
