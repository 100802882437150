import React, { useEffect } from "react";
import { Box, Typography, Divider } from "@mui/material";
import { useHistory } from "react-router-dom";
import theme from "../../../../constants/Theme";
import PropTypes from "prop-types";
import { useSpringCarousel } from "react-spring-carousel";
import TabsUnstyled from "@mui/base/TabsUnstyled";
import { TabStyle, TabsList } from "./styles";
import ROUTES_PATH from "../../../../constants/routes";
import { useDispatch } from "react-redux";
import { setAuthData } from "../../../../modules/actions/authActions";

export default function MobileCarousel({
  tabitems,
  currenttab,
  setcurrenttab,
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    let userType = tabitems.filter(item => item.key === currenttab)[0];
    dispatch(setAuthData({ userType: userType.type }));
  }, []);

  const { carouselFragment } = useSpringCarousel({
    itemsPerSlide: 2,
    withLoop: false,
    items: tabitems?.map((tabItem, index) => ({
      id: index,
      renderItem: (
        <TabStyle value={tabItem.key} key={index} label={tabItem.label} wrapped>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box component="img" src={tabItem.icon} sx={{ mr: 1 }} />
            <Box sx={{ letterSpacing: "2px" }}>
              <Typography variant="label_large">{tabItem.label}</Typography>
            </Box>
          </Box>
        </TabStyle>
      ),
    })),
  });

  const handleChange = (event, newValue) => {
    if (newValue) {
      setcurrenttab(newValue);
      let userType = tabitems.filter(item => item.key === newValue)[0];
      dispatch(setAuthData({ userType: userType.type }));
      history.push(
        newValue === "mentors"
          ? ROUTES_PATH.MENTORS
          : newValue === "advisors"
          ? ROUTES_PATH.ADVISORS
          : "/"
      );
    }
  };

  return (
    <>
      <Box
        sx={{
          [theme.breakpoints.up("md")]: {
            display: "none",
          },
        }}
      >
        <TabsUnstyled defaultValue={currenttab} onChange={handleChange}>
          <TabsList>
            <Box
              sx={{ maxWidth: { xs: "88vw", sm: "90vw", md: "90vw" }, ml: 1 }}
            >
              {carouselFragment}
            </Box>
          </TabsList>
        </TabsUnstyled>
      </Box>
      <Divider sx={{ mt: 1, borderColor: "secondary.light" }} />
    </>
  );
}

{
  MobileCarousel.propTypes = {
    tabitems: PropTypes.array,
    currenttab: PropTypes.string,
    setcurrenttab: PropTypes.func,
  };

  MobileCarousel.defaultProps = {
    tabitems: [],
    currenttab: "",
    setcurrenttab: null,
  };
}
