import { Box, Button, CircularProgress, Modal, Typography } from "@mui/material";
import { useState } from "react";
import services from "../../../services";

export const DeleteUserProfileDataConfirmationDialog = ({ open, handleClose, handleLogout }) => {
	const [deletePofileDataLoader, setDeletePofileDataLoader] = useState(false);

	const handleDeleteUserProfileDataApiCall = async () => {
		setDeletePofileDataLoader(true);

		services
			.deleteUserProfileDataApi()
			.then((response) => {
				if (response.data.code === 200) {
					setDeletePofileDataLoader(false);
					handleLogout();
				}
			})
			.catch((error) => {
				setDeletePofileDataLoader(false);
				console.log(error);
			});
	};

	return (
		<Modal open={open} onClose={handleClose}>
			<Box
				sx={{
					position: "fixed",
					top: "50%",
					left: "50%",
					transform: "translate(-50%, -50%)",
					width: "30vw",
					maxHeight: "90vh",
					overflowY: "auto",
					backgroundColor: "white",
					borderRadius: "20px",
					p: 4,
					zIndex: 9999,
				}}
			>
				<Box display="flex" flexDirection="column" gap={2}>
					<Typography variant="Text/md/Semibold" display={"block"}>
						Delete My Data
					</Typography>

					<Typography variant="Text/sm/Regular" display={"block"}>
						This action will delete your profile data, and deactivate your account on the platform. This process cannot be undone. Are you sure you
						want to do this?
					</Typography>
				</Box>

				<Box display={"flex"} flexDirection={"row"} alignItems={"center"} mt={3} gap={2} justifyContent={"flex-end"}>
					<Box onClick={handleClose}>
						<Button sx={{ color: (theme) => theme.palette.secondary.light }} variant="noOutlineNew">
							Cancel
						</Button>
					</Box>
					<Box>
						<Button
							startIcon={deletePofileDataLoader && <CircularProgress size={"1.25rem"} sx={{ color: "inherit" }} />}
							variant="DS1"
							onClick={() => {
								handleDeleteUserProfileDataApiCall();
							}}
						>
							Confirm
						</Button>
					</Box>
				</Box>
			</Box>
		</Modal>
	);
};
