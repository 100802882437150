import { Avatar, Typography, CircularProgress, Box, Stack } from "@mui/material";
import React, { Fragment, useState } from "react";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { removePartnerFromFounder } from "./functions";
import { useDispatch } from "react-redux";
import { ALERT_ICON_V1 } from "../../../../constants";
import { setCommonDialogConfirmationData } from "../../../../modules/actions";

export function FounderTableColumns(buttonLoader, setButtonLoader, reloadFoundersTable) {
	const dispatch = useDispatch();

	const handleCloseConfirmation = () => {
		dispatch(
			setCommonDialogConfirmationData({
				isOpen: false,
				text: "",
			})
		);
	};
	const handleRemovePartnerDialog = (partnerId) => {
		dispatch(
			setCommonDialogConfirmationData({
				isOpen: true,
				title: "Remove Partner",
				subTitle: `Are you sure you want to remove partner?`,
				text: "",
				primaryButtonText: "Confirm",
				secondaryButtonText: "Cancel",
				primaryButtonAction: () => removePartnerFromFounder(partnerId, setButtonLoader, handleCloseConfirmation, reloadFoundersTable),
				secondaryButtonAction: () => handleCloseConfirmation(),
				primaryButtonVariant: "PurpleRounded",
				secondaryButtonVariant: "noOutlineNew",
				primaryButtonLoading: buttonLoader,
				titleIcon: ALERT_ICON_V1,
			})
		);
	};
	const columns = [
		{
			field: "partner",
			headerName: "Origin",
			sortable: false,
			filterable: false,
			wrapText: true,
			width: 200,
			renderCell: (params) => (
				<Fragment>
					<Stack direction={"row"} gap={1} sx={{ alignItems: "center" }}>
						{" "}
						<Typography variant="Text/xs/Regular">{params.value?.partnerName}</Typography>
						<Box>
							{params.value?.invitationStatus === "ACCEPTED" && (
								<RemoveCircleOutlineIcon
									onClick={(e) => {
										if (!buttonLoader) {
											e.stopPropagation();
											handleRemovePartnerDialog(params?.id);
										}
									}}
								/>
							)}
						</Box>
					</Stack>
				</Fragment>
			),
			renderHeader: (params) => <Typography variant="Text/xs/Medium">{params.colDef.headerName}</Typography>,
		},
		{
			field: "profileCompletePercent",
			headerName: "Profile %",
			sortable: false,
			filterable: false,
			wrapText: true,
			renderCell: (params) => (
				<Box sx={{ position: "relative", display: "inline-flex" }}>
					<CircularProgress variant="determinate" value={params.value} />
					<Box
						sx={{
							top: 0,
							left: 0,
							bottom: 0,
							right: 0,
							position: "absolute",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						<Typography variant="Text/xs/Medium" component="div" sx={{ scale: "85%" }}>
							{`${Math.round(params?.value)}%`}
						</Typography>
					</Box>
				</Box>
			),
			renderHeader: (params) => <Typography variant="Text/xs/Medium">{params.colDef.headerName}</Typography>,
		},
		{
			field: "name",
			headerName: "Name",
			sortable: false,
			filterable: false,
			wrapText: true,
			width: 200,
			renderCell: (params) => (
				<Fragment>
					<Avatar src={params.value?.img} sx={{ mr: 1 }} />
					<Typography variant="Text/xs/Regular">{params.value?.name}</Typography>
				</Fragment>
			),
			renderHeader: (params) => <Typography variant="Text/xs/Medium">{params.colDef.headerName}</Typography>,
		},
		{
			field: "location",
			headerName: "Location",
			sortable: false,
			filterable: false,
			wrapText: true,
			width: 150,
			renderCell: (params) => <Typography variant="Text/xs/Regular">{params.value}</Typography>,
			renderHeader: (params) => <Typography variant="Text/xs/Medium">{params.colDef.headerName}</Typography>,
		},
		{
			field: "company",
			headerName: "Company",
			sortable: false,
			filterable: false,
			wrapText: true,
			width: 150,
			renderCell: (params) =>
				params.value?.name ? (
					<Typography variant="Text/xs/Medium" color="primary">
						{params.value?.name}{" "}
						<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32" fill="none">
							<path
								d="M20.0002 20.0001V12.0001M20.0002 12.0001H12.0002M20.0002 12.0001L12.0002 19.9999M10.4 28H21.6C23.8402 28 24.9603 28 25.816 27.564C26.5686 27.1805 27.1805 26.5686 27.564 25.816C28 24.9603 28 23.8402 28 21.6V10.4C28 8.15979 28 7.03969 27.564 6.18404C27.1805 5.43139 26.5686 4.81947 25.816 4.43597C24.9603 4 23.8402 4 21.6 4H10.4C8.15979 4 7.03969 4 6.18404 4.43597C5.43139 4.81947 4.81947 5.43139 4.43597 6.18404C4 7.03969 4 8.15979 4 10.4V21.6C4 23.8402 4 24.9603 4.43597 25.816C4.81947 26.5686 5.43139 27.1805 6.18404 27.564C7.03969 28 8.15979 28 10.4 28Z"
								stroke="black"
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
						</svg>
					</Typography>
				) : (
					""
				),
			renderHeader: (params) => <Typography variant="Text/xs/Medium">{params.colDef.headerName}</Typography>,
		},
	];
	return columns;
}

export const FounderSortOptions = [
	{ value: "Newest", key: "createdAt" },
	{ value: "Score", key: "currentScore" },
	{ value: "Company Name", key: "companyName" },
	{ value: "Founded Year", key: "foundedYear" },
	{ value: "Company Size", key: "companySize" },
	{ value: "Financing", key: "financing" },
	{ value: "Current Stage", key: "currentStage" },
	{ value: "Seeking", key: "seeking" },
	{ value: "Ask", key: "ask" },
	{ value: "Revenue", key: "revenue" },
];
