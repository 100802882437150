import React, { useEffect } from "react";
import { Tabs, Box, Typography } from "@mui/material";
import PropTypes from "prop-types";
import theme from "../../constants/Theme";
import TabsUnstyled from "@mui/base/TabsUnstyled";
import { useHistory } from "react-router-dom";
import ROUTES_PATH from "../../constants/routes";
import { TabsList, TabStyle } from "./styles";
import { useDispatch } from "react-redux";
import { setAuthData } from "../../modules/actions/authActions";
import { sosPrimary } from "../../constants/Theme/colors";

export const PersonaTab = ({ currenttab, setcurrenttab, tabitems }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    let userType = tabitems.filter(item => item.key === currenttab)[0];
    dispatch(setAuthData({ userType: userType.type }));
  }, []);

  const handleChange = (event, newValue) => {
    if (newValue) {
      setcurrenttab(newValue);
      let userType = tabitems.filter(item => item.key === newValue)[0];
      dispatch(setAuthData({ userType: userType.type }));
      history.push(
        newValue === "mentors"
          ? ROUTES_PATH.MENTORS
          : newValue === "advisors"
          ? ROUTES_PATH.ADVISORS
          : "/"
      );
    }
  };
  return (
    <TabsUnstyled defaultValue={currenttab} onChange={handleChange}>
      <TabsList>
        {tabitems.map((tabItem, index) => {
          return (
            <TabStyle
              value={tabItem.key}
              key={index}
              label={tabItem.label}
              wrapped
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box component="img" src={tabItem.icon} sx={{ mr: 1 }} />
                <Box>
                  <Typography variant="label_large">{tabItem.label}</Typography>
                </Box>
              </Box>
              {currenttab === tabItem.key ? (
                <Box
                  sx={{
                    position: "absolute",
                    bottom: "-8px", // To fix the pointer in the bottom of the tab
                    borderStyle: "solid",
                    margin: "auto",
                    borderWidth: "8px 8px 0 8px",
                    borderColor: `${sosPrimary[50]} transparent transparent transparent`,
                  }}
                ></Box>
              ) : (
                ""
              )}
            </TabStyle>
          );
        })}
      </TabsList>
    </TabsUnstyled>
  );
};

{
  Tabs.propTypes = {
    currenttab: PropTypes.string,
    setcurrenttab: PropTypes.func,
    tabitems: PropTypes.array,
  };
  Tabs.defaultProps = {
    currenttab: "",
    setcurrenttab: null,
    tabitems: [],
  };
}
export default PersonaTab;
