var percentColors = [
  { pct: 0.0, color: { r: 0xf3, g: 0x28, b: 0x36 } },
  { pct: 0.35, color: { r: 0x7b, g: 0x61, b: 0xff } },
  { pct: 0.5, color: { r: 0x47, g: 0x8f, b: 0xc8 } },
  { pct: 0.7, color: { r: 0x00, g: 0xce, b: 0x7d } },
  { pct: 1, color: { r: 0x00, g: 0xce, b: 0x7d } },
];

export var getColorForPercentage = function (pct) {
  for (var i = 1; i < percentColors.length - 1; i++) {
    if (pct < percentColors[i].pct) {
      break;
    }
  }
  var lower = percentColors[i - 1];
  var upper = percentColors[i];
  var range = upper.pct - lower.pct;
  var rangePct = (pct - lower.pct) / range;
  var pctLower = 1 - rangePct;
  var pctUpper = rangePct;
  var color = {
    r: Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper),
    g: Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper),
    b: Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper),
  };
  return "rgb(" + [color.r, color.g, color.b].join(",") + ")";
  // or output as hex if preferred
};
