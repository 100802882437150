import React, { useEffect, useState } from "react";
import { Box, useTheme, Typography, CircularProgress } from "@mui/material";
import { get } from "lodash";
import { convertNumberToLocale } from "../../Functions/common";

export const keyMatricName = {
	ARR: { name: "ARR", field: "latestAnnualRevenue" },
	"CAC Payback": { name: "CAC Payback", field: "latestCacPayback" },
	"Burn & Runway": { name: "Burn & Runway", field: "latestRunway" },
	Customers: { name: "Customers", field: "customers" },
};

function MetricCard({ name, onClick, data, loading, isGridOwner = true }) {
	const theme = useTheme();
	const [hasValue, setHasValue] = useState(false);
	const [metricValue, setMatricValue] = useState(0);

	useEffect(() => {
		if (data && Object.keys(data).length > 0 && get(data, "data", [])?.length > 0) {
			const fieldName = keyMatricName[name]?.field;
			setMatricValue(get(data, fieldName, 0));
			setHasValue(true);
		} else {
			setMatricValue(0);
		}
	}, [data]);

	return (
		<Box display="flex" flexDirection="column" gap={1.25} justifyContent="center" alignItems="center" py={3}>
			<Typography variant="Text/sm/Regular" color="sos.gray500">
				{name}
			</Typography>
			{loading ? (
				<CircularProgress />
			) : !hasValue && isGridOwner ? (
				<Box
					sx={{
						px: "14px",
						py: "8px",
						textAlign: "center",
						backgroundColor: theme.palette.sos.gray100,
						borderRadius: "100px",
						boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
						cursor: "pointer",
					}}
					onClick={onClick}
				>
					<Typography variant="Text/xs/Semibold">Add Data</Typography>
				</Box>
			) : (
				<Box sx={{ cursor: "pointer", textAlign: "center" }} onClick={onClick}>
					<Typography variant="Text/xl/Semibold">
						{`${name === keyMatricName["ARR"].name ? "$" : ""}${convertNumberToLocale(metricValue ? metricValue : 0)}${
							name === keyMatricName["CAC Payback"].name || name === keyMatricName["Burn & Runway"].name ? ` Months` : ""
						}`}
					</Typography>
				</Box>
			)}
		</Box>
	);
}

export default MetricCard;
