import {
  Typography,
  Box,
  Divider,
  Button,
  IconButton,
  MenuItem,
  Grid,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import FileDownloadRoundedIcon from "@mui/icons-material/FileDownloadRounded";
import LoopIcon from "@mui/icons-material/Loop";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined";
import theme from "../../../../../../constants/Theme";
import constants from "../../../../../../constants";
import { connect } from "react-redux";
import * as actions from "../../../../../../modules/actions";
import { Add } from "@mui/icons-material";
import {
  TEMPLATE_TYPES,
  BRANDSTORM_SUMMARY_DEFAULTDATA,
} from "../../../../../../constants/brandstorming-data";
import MessageHandlerModal from "../../../../../../common/modal/MessageHandler";
import MuiTooltip from "../../../../../../common/MuiTooltip";
import services from "../../../../../../services";
import TemporaryDrawer from "../../../../../../common/Drawer";
import {
  archiveApiCall,
  createNewVersionApiCall,
} from "../../../../../../utils/api";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import ROUTES_PATH from "../../../../../../constants/routes";

function BrandStormingSummary({
  powerUpExcercises,
  handleBackClick,
  moduleId,
  setExcerciseConfigs,
  versionId,
}) {
  const tabitems = constants.SUMMARY_TAB_ITEMS;
  const history = useHistory();
  const [selectedTemplate, setSelectedTemplate] = useState(TEMPLATE_TYPES[0]);

  const [currenttab, setcurrenttab] = useState(tabitems[0].key);
  const [archiveConfirmDialog, setArchiveConfirmDialog] = useState(false);
  const [archiveDrawerOpen, setArchiveDrawerOpen] = useState(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    setcurrenttab(tabitems[0].key);
  }, []);

  useEffect(() => {
    if (powerUpExcercises) {
      const unlockDeck = powerUpExcercises.find(
        excercise => excercise.stepNo === 3
      );

      if (unlockDeck && unlockDeck.answers) {
        const templateId = unlockDeck.answers.template;
        const currentTemplate = TEMPLATE_TYPES.find(
          type => type.id === templateId
        );
        if (currentTemplate) setSelectedTemplate(currentTemplate);
      }
    }
  }, [powerUpExcercises]);

  const renderBrandStoryDetails = () => {
    if (powerUpExcercises) {
      //Getting brandstory step responses
      let brandStory = powerUpExcercises.find(
        excercise => excercise.stepNo === 2 && excercise.moduleStepId === 22
      );

      const handleEditAction = () => {
        history.push(`/workspace/powerup/md-brandstorm/8/brand-story`);
      };

      //use default data i no brandstory found use this until previous step integration isnt completed
      if (!brandStory) {
        brandStory = { answers: BRANDSTORM_SUMMARY_DEFAULTDATA };
      }

      if (brandStory && brandStory.answers) {
        return (
          <>
            {Object.keys(brandStory.answers).map(key => {
              return (
                <Box>
                  {" "}
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography variant="title_medium">
                      {key === "brandMission"
                        ? "Brand Mission"
                        : "Brand Values"}
                    </Typography>
                  </Box>
                  <Box sx={{ mt: 2 }}>
                    <Typography variant="bodyLarge">
                      {brandStory.answers[key]}
                    </Typography>
                  </Box>
                </Box>
              );
            })}
          </>
        );
      }
    }
  };
  const archiveCancelDialogHandler = () => {
    setArchiveConfirmDialog(false);
  };

  const archiveSuccessDialogHandler = () => {
    setArchiveConfirmDialog(false);
    let payload = {
      responseId: versionId,
      status: true,
    };
    archiveApiCall(payload, history);
  };

  const createNewVersion = (moduleId, uuid, history) => {
    setExcerciseConfigs(null);

    createNewVersionApiCall(moduleId, uuid, history);
  };
  return (
    <>
      <Box flexGrow={1} flexDirection={"column"}>
        <Box
          sx={{
            mt: 3,
          }}
        >
          <Typography variant="largeTitle">Summary</Typography>
        </Box>

        <Box display={"grid"} sx={{ mt: 1 }}>
          <Typography variant="subhead1">
            Congratulations! Below is a summary of the work you’ve completed in
            this PowerUp. To edit or review your completed steps, select the
            steps above to navigate back. You can take this PowerUp as many
            times as you’d like. Don’t forget to get feedback on your completed
            work.
          </Typography>
        </Box>

        {/*Main Container*/}
        <Box
          sx={theme => ({
            borderRadius: theme.shape.standard5.borderRadius,
            p: 4,
            mt: 3,
            background: theme.palette.secondary.greyBg,

            [theme.breakpoints.down("md")]: {
              borderRadius: "0px",
            },
          })}
        >
          {renderBrandStoryDetails()}

          <Grid
            container
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              justifyContent: { xs: "flex-start", md: "space-between" },
              alignItems: { md: "center" },
              mt: 3,
              width: 1,
            }}
          >
            <Grid item xs={12} md={6}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography variant="title_medium">
                  Download the StartupOS Pitch Deck Template
                </Typography>
              </Box>
              <Box sx={{ mt: 1 }}>
                <Typography variant="bodyLarge">
                  Try our pitch deck template to start building your perfect
                  pitch.
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: "flex",
                mt: { xs: 2, md: 0 },
                justifyContent: { md: "flex-end" },
                alignContent: { md: "flex-end" },
                alignItems: { md: "flex-end" },
              }}
            >
              <Box>
                <Button
                  variant="outlined"
                  startIcon={<FileDownloadOutlinedIcon />}
                  href={selectedTemplate.fileUrl}
                  //sx={{ width: "max-content", maxWidth: "fit-content" }}
                >
                  Download template
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Divider
          sx={{
            background: theme => theme.palette.secondary.dark,
            mt: 6,
            [theme.breakpoints.down("md")]: {
              background: theme => theme.palette.secondary.borderLight,
            },
            display: { sm: "none", xs: "none", md: "flex" },
          }}
        />
        <Box
          sx={{
            mt: 3,
            display: { sm: "none", xs: "none", md: "flex" },
          }}
        >
          <MuiTooltip title="Archive">
            <ArchiveOutlinedIcon
              sx={{
                cursor: "pointer",
                color: "secondary.main",
                p: 1,
                "&:hover": {
                  background: "rgba(73, 69, 79, 0.08)",
                  borderRadius: "50%",
                },
              }}
              onClick={() => {
                setArchiveConfirmDialog(true);
              }}
            />
          </MuiTooltip>
          <MuiTooltip title="Take again">
            <LoopIcon
              sx={{
                cursor: "pointer",
                ml: 1,
                color: "secondary.main",
                p: 1,
                "&:hover": {
                  background: "rgba(73, 69, 79, 0.08)",
                  borderRadius: "50%",
                },
              }}
              onClick={() => {
                createNewVersion(moduleId, "md-brandstorm", history);
              }}
            />
          </MuiTooltip>
          <Box sx={{ ml: "auto" }}>
            <Button
              variant="outlined"
              onClick={() => {
                handleBackClick();
              }}
            >
              Back
            </Button>
          </Box>
          <Box sx={{ ml: 1 }}>
            <Button
              onClick={() => {
                history.push(ROUTES_PATH.STARTUPS_HOME);
              }}
            >
              Done
            </Button>
          </Box>
        </Box>
        {/* This section needs to revisited once feedback functionality implemented. */}
        <Box
          sx={theme => ({
            display: { sm: "none", xs: "none", md: "none" },
            background: theme.palette.secondary.white,
            borderRadius: theme.shape.standard.borderRadius,
            p: 3,
            mt: 3,
          })}
        >
          <Typography variant="title_medium">Want feedback?</Typography>
          <Box sx={{ mt: 3 }}>
            <Typography variant="subhead2">
              Your StartupOS Advisor is here to help if you would like feedback
              for this completed PowerUp.
            </Typography>
          </Box>
          <Box sx={{ textAlign: "center", mt: 4 }}>
            <Button>Submit for feedback</Button>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          background: theme.palette.secondary.white,
          position: "fixed",
          bottom: 72,
          width: 1,
          textAlign: "center",
          alignItems: "center",
          py: 2,
          left: 0,
          display: { sm: "flex", xs: "flex", md: "none" },
        }}
      >
        <MoreHorizIcon
          sx={{
            borderRadius: "50%",
            border: `1px solid ${theme.palette.secondary.dark}`,
            mr: 1,
            ml: 2,
            p: 1,
          }}
          onClick={() => {
            setArchiveDrawerOpen(true);
          }}
        />
        <Button
          variant="outlined"
          onClick={() => {
            handleBackClick();
          }}
        >
          Back
        </Button>
        <Button
          sx={{ ml: 1, mr: 2 }}
          onClick={() => {
            history.push(ROUTES_PATH.STARTUPS_HOME);
          }}
        >
          Done
        </Button>
      </Box>
      <TemporaryDrawer
        open={archiveDrawerOpen}
        onClose={() => {
          setArchiveDrawerOpen(false);
        }}
      >
        <Box sx={{ py: 3 }}>
          <MenuItem
            onClick={() => {
              setArchiveDrawerOpen(false);
              setArchiveConfirmDialog(true);
            }}
          >
            <ArchiveOutlinedIcon
              sx={{
                color: "secondary.main",
                p: 1,
              }}
            />
            <Typography variant="label_xlarge" sx={{ ml: 2 }}>
              Archive
            </Typography>
          </MenuItem>
          <MenuItem
            onClick={() => {
              createNewVersion(moduleId, "md-brandstorm", history);
            }}
          >
            <LoopIcon
              sx={{
                color: "secondary.main",
                p: 1,
              }}
            />
            <Typography variant="label_xlarge" sx={{ ml: 2 }}>
              Take again
            </Typography>
          </MenuItem>
        </Box>
      </TemporaryDrawer>
      <MessageHandlerModal
        isOpen={archiveConfirmDialog}
        heading="Archive"
        text={"Are you sure you want to archive this?"}
        messageType={"primary"}
        cancelText={"No thanks"}
        okText={"Archive"}
        style={{
          maxWidth: "450px",
        }}
        onOk={archiveSuccessDialogHandler}
        onCancel={archiveCancelDialogHandler}
      />
    </>
  );
}

const mapStoreToProps = ({ powerUp }) => ({
  powerUpExcercises: powerUp?.powerUpExcercises,
});

const mapDispatchToProps = dispatch => ({
  setPowerUpExcercises: updatedSteps =>
    dispatch(actions.setPowerUpExcercises(updatedSteps)),
});

export default connect(
  mapStoreToProps,
  mapDispatchToProps
)(BrandStormingSummary);
