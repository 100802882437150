import { PanoramaFishEye } from "@mui/icons-material";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import React from "react";

//src/constants/Theme/togglebuttons.stories.js it is created from there
const ToggleButtons = ({ value = false, onChange, variant, color, disabled = false, ...args }) => {
	const handleAlignment = (event, newValue) => {
		if (newValue !== null) onChange(event, newValue);
	};

	return (
		<ToggleButtonGroup disabled={disabled} value={value} exclusive onChange={handleAlignment} variant={variant} colorStyle={color} small={args?.small}>
			<ToggleButton value={args?.buttonValueFalse}>
				{args?.iconDisplay?.includes("Start Icon") && <PanoramaFishEye />}
				{args?.buttonLabelFalse}
			</ToggleButton>
			<ToggleButton value={args?.buttonValueTrue}>
				{args?.buttonLabelTrue}
				{args?.iconDisplay?.includes("End Icon") && <PanoramaFishEye />}
			</ToggleButton>
		</ToggleButtonGroup>
	);
};

export default ToggleButtons;
