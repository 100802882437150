import React, { useEffect } from "react";
import { useStore } from "react-redux";
import { Switch, Redirect, useLocation, matchPath, useHistory } from "react-router-dom";

import ROUTES_PATH from "../constants/routes";
import { createInstanceandInjectStore } from "../axios";
import ThemeDemo from "../constants/Theme/themedemo";
import Route from "./Route";

// Partner
import PartnerDashboard from "../containers/Partner/Dashboard";
import PowerUpList from "../containers/Partner/PowerUpBuilder/PowerUpList";
import PowerUpItem from "../containers/Partner/PowerUpBuilder/PowerUpItem";
import Submission from "../containers/Partner/PowerUpBuilder/PowerUpItem/submission";
import PowerUpPreview from "../containers/Partner/PowerUpBuilder/PowerUpPreview";

// -------------------Pages-----------------------
// import SignUp from "../pages/signUp";
// import LogIn from "../pages/logIn";
// import { NewLogin } from "../pages/logIn/NewLogin";
import { AdminLogin } from "../pages/adminLogin";
import CreateAccount from "../pages/createAccount";
import EmailVerification from "../pages/emailVerification";
import SocialLogin from "../pages/socialLogin";
import CreatePassword from "../pages/createPassword";
import Authorize from "../pages/authorize";
import AuthorizeSuccess from "../pages/authorize/authorizeSuccess";
import RequestForm from "../pages/request-form";
import RequestSubmitted from "../pages/request-submitted";
import AccountNotFound from "../pages/account-not-found";
import InvalidEmail from "../pages/invalid-email";
import RequestEmailVerification from "../pages/request-email-verification";
import AppHome from "../pages/AppHome";
import TellUsMore from "../pages/tellUsMore";
import InvalidInvite from "../pages/Invalid-invite/InvalidInvite";
import loginBackdoor from "../pages/loginBackdoor";
import Join from "../pages/Join";
// Admin
import Impersonate from "../pages/impersonate";
// Testing for Vouch
import Vouch from "../pages/vouch";

// ------------------Containers-------------------
import Dashboard from "../containers/Dashboard";
import PowerPack from "../containers/FounderWorkspace/PowerPackSelectedView";
import StartupWorkspace from "../containers/Investor/Workspace/index.jsx";
import OldWorkspace from "../containers/Workspace";
import MyStartup from "../containers/MyStartup";
import StorytellerSummary from "../containers/Workspace/StorytellerSummary";
import RevenueModelerSummary from "../containers/Workspace/PowerupExcercises/PowerUps/RevenueModler/RevenueModelerSummary";
import PowerupExcercises from "../containers/Workspace/PowerupExcercises";
import TAMSAMSOM from "../containers/Workspace/PowerupExcercises/PowerUps/TAMSAMSOM";
import TAMSAMSOMSummary from "../containers/Workspace/PowerupExcercises/PowerUps/TAMSAMSOM/Components/Summary";
import ValuePropSummary from "../containers/Workspace/Summary/ValueProp";
import IdeaValidationDetails from "../containers/Workspace/PowerUP/IdeaValidation/IdeaValidationDetails";
import IdeaValidationExcersice from "../containers/Workspace/PowerUP/IdeaValidation/Excercises";
import PersonaBuilderSummary from "../containers/Workspace/Summary/PersonaBuilderSummary";
import RevenueModler from "../containers/Workspace/PowerupExcercises/PowerUps/RevenueModler";
import MvpPrioritizer from "../containers/Workspace/PowerupExcercises/PowerUps/MvpPrioritizer";
import PersonaBuilder from "../containers/Workspace/PowerupExcercises/PowerUps/PersonaBuilder";
import Brandstorming from "../containers/Workspace/PowerupExcercises/PowerUps/Brandstorming";
import BrandStormingSummary from "../containers/Workspace/PowerupExcercises/PowerUps/Brandstorming/Summary";
import AggregatedNotes from "../containers/Workspace/PowerUP/IdeaValidation/AggregatedNotes";
import BrowseInterviewCandidates from "../containers/Workspace/PowerUP/IdeaValidation/BrowseInterviewCandidates";
import InterviewingYourCandidate from "../containers/Workspace/PowerUP/IdeaValidation/InterviewingYourCandidate";
import MarketTest from "../containers/Workspace/MarketTest";
import ProblemSolution from "../containers/Workspace/PowerUP/ProblemSolution";
import ProblemSolutionSummary from "../containers/Workspace/Summary/ProblemSolutionSummary";
import MarketTestIdeaValidationDetails from "../containers/Workspace/MarketTest/IdeaValidationDetails";
import CandidateProfile from "../containers/Workspace/MarketTest/Components/CandidateProfile";
import AdvisorPluginDetails from "../containers/Workspace/Plugins/AdvisorPlugin/Details";
import StartupProfile from "../containers/Mentor/Network/StartupProfile";
import MentorDashboard from "../containers/Mentor/Dashboard";
import MentorProfile from "../containers/Mentor/Profile";
import AdvisorDashboard from "../containers/Advisor/Dashboard";
import ProposeNewTime from "../containers/ExternalCandidate/ProposeNewTime";
import CandidateDashboard from "../containers/Candidates/Dashboard";
import EventDetailsPopup from "../containers/ExternalCandidate/DashboardEvent";
import ExternalCandidateProfile from "../containers/ExternalCandidate";
import StaticSummary from "../containers/MyStartup/MyWork/StaticSummary";
import VerifyEmail from "../containers/Dashboard/AccountSettings/VerifyEmail";
import ResearchCuratorExpandView from "../containers/ResearchCurator/ExpandView";
import CreateExpandView from "../containers/ResearchCurator/CreateExpandView";
import ResearchCuratorNoteDetails from "../containers/ResearchCurator/ResearchCuratorNoteDetails";
import WhatIsInvestmentRange from "../containers/Onboarding/Updated/WhatIsInvestmentRange";
import SkillsAndExpertise from "../containers/Onboarding/Updated/SkillsAndExpertise";
import InvestorDashboard from "../containers/Investor/Dashboard";
import InvestorWorkspace from "../containers/Investor/Workspace";
import AccountProfile from "../containers/Profile/AccountProfile";
import StudentDashboard from "../containers/Instructor copy/Dashboard";
import { NetworkController } from "../containers/Network/NetworkController";
import MeetingsController from "../containers/Meetings";
import ScheduleMeetingCalendar from "../containers/Meetings/ScheduleMeetingCalendar";
import MeetingDetailsForm from "../containers/Meetings/MeetingDetailsForm";
import MeetingLengthSchedule from "../containers/Meetings/MeetingLengthSchedule";
import Messaging from "../containers/Messaging";
import MVPPrioritizer from "../containers/Workspace/PowerUP/MVPPrioritizer";
import EventsController from "../containers/Events";
import Survey from "../containers/Survey";
import FounderWorkspace from "../containers/FounderWorkspace/FounderWorkspaceView";
import PowerupDetails from "../containers/Workspace/PowerupDetails";
import PersonaBuilderNew from "../containers/NewPowerup/PersonaBuilder";
import CreateMatrix from "../containers/Workspace/PowerUP/MVPPrioritizer/CreateMatrix";
import MeetingScheduledScreen from "../containers/Meetings/MeetingScheduledScreen";
import InstructorDashboard from "../containers/Instructor/Dashboard";
import AreYouAffiliate from "../containers/Onboarding/Updated/AreYouAffiliate";
import IndustryFocus from "../containers/Onboarding/Updated/IndustryFocus";
import MentorNetwork from "../containers/Network/Components/NetworkListingUpdated";
import ScoutingReportHardcode from "../containers/ScoutingReportView/ScoutingReportHardcode";
import BIDashboard from "../containers/BIDashboard/Dashboard";
import UserFeeds from "../containers/Feed";
import { Onboard } from "../containers/Onboard";
import { NewOnboard } from "../containers/NewOnboard";
import { CustomizeOnboard } from "../containers/NewOnboard/CustomizeOnboard";
import FounderDashboard from "../containers/Founder/Dashboard";
import StartupProfileNew from "../containers/Profile/StartupProfile";
import NewWorkspace from "../containers/Founder/NewWorkspace";
//import SelectHQLocation from "../containers/Onboarding/SelectHQLocation";
//import StartupJourney from "../containers/Onboarding/StartupJourney";
//import CompanyName from "../containers/Onboarding/CompanyName";
//import ProductServiceOffering from "../containers/Onboarding/ProductServiceOffering";
//import ExperienceAsFounder from "../containers/Onboarding/ExperienceAsFounder";
//import FounderSuperPowser from "../containers/Onboarding/FounderSuperPower";
//import CommitmentLevels from "../containers/Onboarding/CommitmentLevels";
//import WhoAreYou from "../containers/Onboarding/Updated/WhoAreYou";
//import WhatBringsYouHere from "../containers/Onboarding/Updated/WhatBringsYouHere";
//import StartupsYouHelp from "../containers/Onboarding/Updated/StartupsYouHelp";
//import YourSuperPower from "../containers/Onboarding/Updated/YourSuperPower/YourSuperPower";
//import HowManyDeals from "../containers/Onboarding/Updated/HowManyDeals";
//import WhatTypeOfInvestor from "../containers/Onboarding/Updated/WhatTypeOfInvestor";
//import WhatsYourExpertise from "../containers/Onboarding/Updated/WhatsYourExpertise";
//import WhatFirstThingInStartup from "../containers/Onboarding/Updated/WhatFirstThingInStartup";
//import ReceivedFunding from "../containers/Onboarding/Updated/HaveYouRecievedAnyFunding";

// ---------------------Components-------------------------
import { OnboardingPageController } from "../components/Onboarding/PageController";
import PowerupController from "../components/NewPowerup/PowerupController";
import FullNotificationList from "../components/NotificationCenter/FullNotificationList";
import Profile from "../components/Profile";
import services from "../services";
import ResourceCenter from "../pages/ResourceCenter";
import StartupPublicProfile from "../containers/PublicProfile/StartupPublicProfile";
import AccountPublicProfile from "../containers/PublicProfile/AccountPublicProfile";
import PaymentPlan from "../pages/PaymentPlan";
import Networking from "../containers/Networking";
import MentorProfileNew from "../containers/MentorProfile";
import MentorHome from "../containers/Mentor/Home";
import ToolController from "../components/Tool/ToolController";
import { PowerPackController } from "../containers/PowerPack/PowerPackController";
import { ForgotPwd, NewLogin } from "../containers/NewOnboard/Components";
import PublicLeanCanvas from "../containers/PublicPowerups/LeanCanvas";
import EventBanners from "../containers/NewEvents";
import { useThemeContext } from "../constants/Theme/ThemeContext";
import PartnerAdmin from "../containers/Partner/Dashboard/Admin";
import EventDetails from "../containers/NewEvents/EventDetails.jsx";
import PublicAreYouFundable from "../containers/PublicPowerups/AreYouFundable/index.jsx";
import NewPartners from "../containers/NewPartners/index.jsx";
import EventDashboard from "../containers/Admin/Events/index.jsx";
import Quicksight from "../containers/Quicksight/index.jsx";
import AdminQABoard from "../containers/Partner/AdminQABoard/index.jsx";
import AdminPowerPackController from "../containers/Admin/PowerPack/index.js";
import CompanyUsersImport from "../containers/CompanyUsersImport/index.jsx";
//import { PersonalDetails } from "../components/Onboarding/Updated/PersonalDetails";
//import { StartupDetails } from "../components/Onboarding/Updated/StartupDetails";
//import { CustomThemeProvider, useThemeContext } from "../constants/Theme/ThemeContex";

export default function Routes() {
	const { getTheme } = useThemeContext();

	const st = useStore();
	createInstanceandInjectStore(st);

	const location = useLocation();
	const history = useHistory();

	const RestrictedPaths = [
		{ pattern: ROUTES_PATH.SCHEDULE_MEETING_CALENDAR },
		{ pattern: ROUTES_PATH.INVESTOR_DASHBOARD },
		{ pattern: ROUTES_PATH.INVESTOR_WORKSPACE },
		{ pattern: ROUTES_PATH.FOUNDER_DASHBOARD },
		{ pattern: ROUTES_PATH.SCOUTING_REPORT },
		// { pattern: ROUTES_PATH.MEETINGS_BASEPATH },
		//{ pattern: ROUTES_PATH.MEETING_TYPES_SCHEDULE },
		//{ pattern: ROUTES_PATH.MEETING_DETAILS_FORM },
		{ pattern: ROUTES_PATH.INVALID_INVITE },
		{ pattern: ROUTES_PATH.NETWORK },
		// { pattern: ROUTES_PATH.MESSAGES },
		{ pattern: ROUTES_PATH.MENTOR_NETWORK_STARTUP_PROFILE },
		{ pattern: ROUTES_PATH.INSTRUCTOR_NETWORK },
		// { pattern: ROUTES_PATH.MENTOR_NETWORK },
	];

	const checkApproval = async () => {
		const resp = await services.getApprovalStatus();
		if (resp !== "APPROVED") {
			history.replace(ROUTES_PATH.MY_ACCOUNT_PROFILE);
		}
	};

	useEffect(() => {
		getTheme();
	}, []);

	useEffect(() => {
		for (let i = 0; i < RestrictedPaths.length; i++) {
			const { pattern } = RestrictedPaths[i];
			const match = matchPath(location.pathname, { path: pattern });
			if (match) {
				checkApproval();
			}
		}
	}, [location.pathname]);

	return (
		<Switch>
			<Route path="/" exact component={NewOnboard} />
			<Route path={ROUTES_PATH.LOG_IN_NEW} component={NewLogin} />
			<Route path={ROUTES_PATH.LOG_IN} component={NewLogin} />
			<Route path={ROUTES_PATH.ADMIN} component={AdminLogin} />
			<Route path={ROUTES_PATH.LOG_IN_TEMP} component={loginBackdoor} />
			<Route path={ROUTES_PATH.CREATE_ACCOUNT} component={CreateAccount} />
			<Route path={ROUTES_PATH.EMAIL_VERIFICATION} component={EmailVerification} />
			<Route path={ROUTES_PATH.TELL_US_MORE} component={TellUsMore} />
			<Route path={ROUTES_PATH.SOCIAL_LOGIN} component={SocialLogin} />
			<Route path={ROUTES_PATH.FORGOT_PASSWORD} component={ForgotPwd} />
			<Route path={ROUTES_PATH.AUTHORIZE} component={Authorize} />
			<Route path={ROUTES_PATH.AUTHORIZE_SUCCESS} component={AuthorizeSuccess} />
			<Route path={ROUTES_PATH.REQUEST_FORM} component={RequestForm} />
			<Route path={ROUTES_PATH.REQUEST_SUBMITTED} component={RequestSubmitted} />
			<Route path={ROUTES_PATH.INVALID_EMAIL} component={InvalidEmail} />
			<Route path={ROUTES_PATH.CREATE_PASSWORD} component={CreatePassword} />
			<Route path={ROUTES_PATH.RESEND_EMAIL} component={EmailVerification} />
			<Route path={ROUTES_PATH.VERIFY_EMAIL} component={RequestEmailVerification} />
			<Route path={ROUTES_PATH.SIGN_UP} exact component={NewOnboard} />
			<Route path={ROUTES_PATH.ONBOARDING_BASEPATH} component={OnboardingPageController} />
			{/* <Route path={ROUTES_PATH.ONBOARD} component={Onboard} /> */}
			{/* <Route path={ROUTES_PATH.ONBOARD_TEAMMATE} component={Onboard} /> */}
			<Route path={ROUTES_PATH.NEW_ONBOARD} component={NewOnboard} />
			<Route path={"/demo"} component={ThemeDemo} />
			<Route path="/terms" exact component={AppHome} />
			<Route path="/privacy" exact component={AppHome} />
			<Route path={ROUTES_PATH.ACCOUNT_NOT_FOUND} component={AccountNotFound} />
			<Route path={ROUTES_PATH.SKILLS_AND_EXPERTISE} component={SkillsAndExpertise} />
			<Route path={ROUTES_PATH.WHAT_IS_INVESTMENT_RANGE} component={WhatIsInvestmentRange} />
			{/* Public Profile */}
			<Route path={ROUTES_PATH.STARTUP_PUBLIC_PROFILE} component={StartupPublicProfile} />
			<Route path={ROUTES_PATH.ACCOUNT_PUBLIC_PROFILE} component={AccountPublicProfile} />
			{/* Public PowerUps */}
			<Route path={ROUTES_PATH.PUBLIC_LEAN_CANVAS} component={PublicLeanCanvas} />
			<Route path={ROUTES_PATH.PUBLIC_LEAN_CANVAS} component={PublicAreYouFundable} />
			{/* Admin Routes End */}
			{/*<Route
        path={ROUTES_PATH.TELL_US_ABOUT_YOURSELF}
        component={PersonalDetails}
      />
      <Route
        path={ROUTES_PATH.TELL_US_ABOUT_STARTUP}
        component={StartupDetails}
      />*/}
			{/* ONBOARDING ROUTES */}
			{/*<Route
        path={ROUTES_PATH.ONBOARDING_LOCATION}
        component={SelectHQLocation}
      />
      <Route
        path={ROUTES_PATH.ONBOARDING_STARTUP_JOURNEY}
        component={StartupJourney}
      />
      <Route
        path={ROUTES_PATH.ONBOARDING_PRODUCT_SERVICE}
        component={ProductServiceOffering}
      />
      <Route
        path={ROUTES_PATH.ONBOARDING_FOUNDER_EXPERIENCE_LEVEL}
        component={ExperienceAsFounder}
      />
      <Route
        path={ROUTES_PATH.ONBOARDING_FOUNDER_SUPERPOWER}
        component={FounderSuperPowser}
      />
      <Route
        path={ROUTES_PATH.ONBOARDING_TIME_COMMITMENT}
        component={CommitmentLevels}
      />
      <Route path={ROUTES_PATH.ONBOARDING_BASEPATH} component={CompanyName} />*/}
			{/*<Route path={ROUTES_PATH.WHO_ARE_YOU} component={WhoAreYou} />
      <Route
        path={ROUTES_PATH.WHAT_BRINGS_YOU_HERE}
        component={WhatBringsYouHere}
      />
      <Route
        path={ROUTES_PATH.FIRST_THING_YOU_SEE_IN_STARTUP}
        component={WhatFirstThingInStartup}
      />
      <Route path={ROUTES_PATH.HOW_MANY_DEALS} component={HowManyDeals} />
      <Route path={ROUTES_PATH.STARTUPS_YOU_HELP} component={StartupsYouHelp} />
      <Route path={ROUTES_PATH.INDUSTRY_FOCUS} component={IndustryFocus} />
      <Route path={ROUTES_PATH.YOUR_SUPERPOWER} component={YourSuperPower} />
      <Route
        path={ROUTES_PATH.WHAT_TYPE_OF_INVESTOR}
        component={WhatTypeOfInvestor}
      />
      <Route
        path={ROUTES_PATH.WHAT_IS_INVESTMENT_RANGE}
        component={WhatIsInvestmentRange}
      />
      <Route
        path={ROUTES_PATH.WHATS_YOUR_EXPERTISE}
        component={WhatsYourExpertise}
      />
      <Route path={ROUTES_PATH.RECIEVED_FUNDING} component={ReceivedFunding} />
      <Route path={ROUTES_PATH.ARE_YOU_AFFILIATE} component={AreYouAffiliate} />
      <Route
        path={ROUTES_PATH.SKILLS_AND_EXPERTISE}
        component={SkillsAndExpertise}
      />
      <Route path={ROUTES_PATH.ARE_YOU_AFFILIATE} component={AreYouAffiliate} />*/}
			{/* ----------------------------------------------------------Private Routes--------------------------------------------- */}
			<Route path={ROUTES_PATH.CHANGE_EMAIL} component={VerifyEmail} />
			<Route path={ROUTES_PATH.STATIC_SUMMARY} component={StaticSummary} isPrivate />
			<Route path={"/propose-new-time"} exact component={ProposeNewTime} isPrivate />
			<Route path={ROUTES_PATH.DASHBOARD} component={Dashboard} isPrivate />
			<Route path={ROUTES_PATH.STARTUP_MYPROFILE} component={MyStartup} isPrivate />
			<Route path={ROUTES_PATH.STARTUP_MYWORK} component={MyStartup} isPrivate />
			<Route path={ROUTES_PATH.POWERUP_CONTROLLER} component={PowerupController} isPrivate />
			<Route path={ROUTES_PATH.TOOL_CONTROLLER} component={ToolController} isPrivate />
			<Route path={ROUTES_PATH.TAM_SAM_SOM_POWERUP} component={TAMSAMSOM} isPrivate />
			<Route path={ROUTES_PATH.INTERVIEWING_YOUR_CANDIDATE} component={InterviewingYourCandidate} isPrivate />
			<Route path={ROUTES_PATH.MARKET_TEST_EXERCISE} component={MarketTestIdeaValidationDetails} isPrivate />
			<Route path={ROUTES_PATH.MARKET_TEST} component={MarketTest} isPrivate />
			<Route path={ROUTES_PATH.AGGREGATE_NOTES} component={AggregatedNotes} isPrivate />
			<Route path={ROUTES_PATH.IDEA_VALIDATION_INTERVIEW_POWERUP} component={IdeaValidationExcersice} isPrivate />
			<Route path={ROUTES_PATH.BROWSE_INTERVIEW_CANDIDATES} component={BrowseInterviewCandidates} isPrivate />
			<Route path={ROUTES_PATH.IDEA_VALIDATION_INTERVIEW} component={IdeaValidationDetails} isPrivate />
			<Route path={ROUTES_PATH.CANDIDATE_DETAILS} component={CandidateProfile} isPrivate />
			<Route path={ROUTES_PATH.BRANDSTORMING_POWERUP} component={Brandstorming} isPrivate />
			<Route path={ROUTES_PATH.REVENUE_MODLER} component={RevenueModler} isPrivate />
			<Route path={ROUTES_PATH.MVP_PRIORITIZER} component={MvpPrioritizer} isPrivate />
			<Route path={ROUTES_PATH.PROBLEM_SOLUTION_POWERUP} component={ProblemSolution} isPrivate />
			<Route path={ROUTES_PATH.INITIAL_PERSONNA_BUILDER_POWERUP} component={PersonaBuilder} isPrivate />
			<Route path={ROUTES_PATH.TAM_SAM_SOM_POWERUP} component={TAMSAMSOM} isPrivate />
			<Route path={ROUTES_PATH.WORKSPACE_POWERUP_EXCERCISE} component={PowerupExcercises} isPrivate />
			<Route path={"/demo"} component={ThemeDemo} isPrivate />
			<Route path={ROUTES_PATH.WORKSPACE_POWERUP_DETAILS} component={PowerupController} isPrivate />
			<Route path={ROUTES_PATH.WORKSPACE_POWERPACK_DETAILS} component={PowerPackController} isPrivate />
			{/* testing route */}
			<Route path={ROUTES_PATH.WORKSPACE_POWERUP_TESTING_DETAILS} component={PowerupController} isPrivate />
			<Route path={ROUTES_PATH.STORYTELLER_POWERUP_SUMMARY} component={StorytellerSummary} isPrivate />
			<Route path={ROUTES_PATH.VALUEPROP_POWERUP_SUMMARY} component={ValuePropSummary} isPrivate />
			<Route path={ROUTES_PATH.TAMSAMSOM_POWERUP_SUMMARY} component={TAMSAMSOMSummary} isPrivate />
			<Route path={ROUTES_PATH.REVENUW_MODELER_POWERUP_SUMMARY} component={RevenueModelerSummary} isPrivate />
			<Route path={ROUTES_PATH.PERSONA_BUILDER_POWERUP_SUMMARY} component={PersonaBuilderSummary} isPrivate />
			<Route path={ROUTES_PATH.BRANDSTORMING_SUMMARY} component={BrandStormingSummary} isPrivate />
			<Route path={ROUTES_PATH.PROBLEM_SOLUTION_SUMMARY} component={ProblemSolutionSummary} isPrivate />
			<Route path={ROUTES_PATH.MENTOR_CHECKIN_DETAILS} component={AdvisorPluginDetails} isPrivate />
			<Route path={ROUTES_PATH.MENTOR_DASHBOARD} component={MentorDashboard} isPrivate />
			<Route path={ROUTES_PATH.MENTOR_HOME} component={MentorHome} isPrivate />
			{/* StartupWorkspace Workspace prior to Oct 1 2022 */}
			{/* Commented TODO HOME PAGE */}
			{/* <Route path={ROUTES_PATH.WORKSPACE_POWERPACKS} component={StartupWorkspace} isPrivate />
			<Route path={ROUTES_PATH.WORKSPACE_POWERUPS} component={StartupWorkspace} isPrivate />
			<Route path={ROUTES_PATH.WORKSPACE_PLUGINS} component={StartupWorkspace} isPrivate />
			<Route path={ROUTES_PATH.WORKSPACE_WORK_HISTORY} component={StartupWorkspace} isPrivate /> */}
			{/*  Founder Workspace after Oct 1 2022 */}
			{/* Commented TODO HOME PAGE */}
			{/* <Route path={ROUTES_PATH.FOUNDER_WORKSPACE} component={StartupWorkspace} isPrivate /> */}
			<Route path={ROUTES_PATH.FOUNDER_WORKSPACE} component={NewWorkspace} isPrivate />
			{/*  Founder Workspace after Oct 1 2022 */}
			<Route path={ROUTES_PATH.OLD_WORKSPACE} component={OldWorkspace} isPrivate />
			<Route path={ROUTES_PATH.POWERPACK} component={PowerPack} isPrivate />
			<Route path={ROUTES_PATH.RESEARCH_CURATOR_NOTE} component={ResearchCuratorNoteDetails} isPrivate />
			<Route path={ROUTES_PATH.RESEARCH_CURATOR_EXPAND} component={ResearchCuratorExpandView} isPrivate />
			<Route path={ROUTES_PATH.CREATE_RESEARCH_CURATOR} component={CreateExpandView} isPrivate />
			<Route path={ROUTES_PATH.CREATE_QUICK_NOTE} component={CreateExpandView} isPrivate />
			<Route path={ROUTES_PATH.QUICK_NOTE_DETAILS} component={CreateExpandView} isPrivate />
			<Route path={ROUTES_PATH.EVENTS_DETAILS} exact component={EventDetails} isPrivate />
			<Route path={ROUTES_PATH.EVENTS_BASEPATH} component={EventBanners} isPrivate />
			<Route path={ROUTES_PATH.PARTNER_BASEPATH} component={NewPartners} isPrivate />
			<Route path={ROUTES_PATH.MYPROFILE_CANDIDATE} exact component={ExternalCandidateProfile} isPrivate />
			<Route path={ROUTES_PATH.MENTOR_PROFILE} component={MentorProfile} isPrivate />
			<Route path={ROUTES_PATH.ADVISOR_DASHBOARD} component={AdvisorDashboard} isPrivate />
			<Route path={ROUTES_PATH.STUDENT_DASHBOARD} component={StudentDashboard} isPrivate />
			<Route path={ROUTES_PATH.INSTRUCTOR_DASHBOARD} component={MentorDashboard} isPrivate />
			<Route path={ROUTES_PATH.ADVISOR_PROFILE} component={MentorProfile} isPrivate />
			<Route path={ROUTES_PATH.CANDIDATE_DASHBOARD} component={CandidateDashboard} isPrivate />
			<Route path={ROUTES_PATH.EXTERNAL_CANDIDATE_DASHBOARD} component={EventDetailsPopup} isPrivate />
			<Route path={ROUTES_PATH.CREATE_MATRIX} component={MVPPrioritizer} isPrivate />
			<Route path={ROUTES_PATH.SURVEY} component={Survey} isPrivate />
			<Route path={ROUTES_PATH.NOTIFICATIONSLIST} component={FullNotificationList} isPrivate />
			{/*<Route path={ROUTES_PATH.SCHEDULE_MEETING_CALENDAR} component={ScheduleMeetingCalendar} isPrivate isRestricted />*/}
			<Route path={ROUTES_PATH.INVESTOR_DASHBOARD} component={InvestorDashboard} isPrivate isRestricted />
			<Route path={ROUTES_PATH.INVESTOR_WORKSPACE} component={InvestorWorkspace} isPrivate isRestricted />
			<Route path={ROUTES_PATH.FOUNDER_DASHBOARD} component={FounderDashboard} isPrivate isRestricted />
			{/*<Route path={ROUTES_PATH.STARTUP_PROFILE} component={StartupProfileNew} isPrivate isRestricted />*/}
			<Route path={ROUTES_PATH.EDIT_MY_STARTUP_PROFILE} component={MyStartup} isPrivate />
			<Route path={ROUTES_PATH.STARTUP_PROFILE} component={Profile} isPrivate />
			<Route path={ROUTES_PATH.MY_STARTUP_PROFILE} component={Profile} isPrivate />
			{/*<Route path={ROUTES_PATH.ACCOUNT_PROFILE} component={AccountProfile} isPrivate />
			<Route path={ROUTES_PATH.MY_ACCOUNT_PROFILE} component={AccountProfile} isPrivate />*/}
			<Route path={ROUTES_PATH.EDIT_MY_ACCOUNT_PROFILE} component={MentorProfile} isPrivate />{" "}
			{/* Why this is called MentorProfile... luckily only temporary. */}
			<Route path={ROUTES_PATH.ACCOUNT_PROFILE} component={Profile} isPrivate />
			<Route path={ROUTES_PATH.MY_ACCOUNT_PROFILE} component={Profile} isPrivate />
			<Route path={ROUTES_PATH.SCOUTING_REPORT} component={ScoutingReportHardcode} isPrivate isRestricted />
			<Route path={ROUTES_PATH.MEETINGS_BASEPATH} component={MeetingsController} isPrivate />
			{/*<Route path={ROUTES_PATH.MEETING_TYPES_SCHEDULE} component={MeetingLengthSchedule} isPrivate isRestricted />*/}
			{/*<Route path={ROUTES_PATH.MEETING_DETAILS_FORM} component={MeetingDetailsForm} isPrivate isRestricted />*/}
			<Route path={ROUTES_PATH.INVALID_INVITE} component={InvalidInvite} isPrivate isRestricted />
			{/* <Route path={ROUTES_PATH.NETWORK} component={NetworkController} isPrivate isRestricted /> */}
			<Route path={ROUTES_PATH.NETWORK} component={Networking} isPrivate isRestricted />
			<Route path={ROUTES_PATH.MESSAGES} component={Messaging} isPrivate />
			<Route path={ROUTES_PATH.MENTOR_NETWORK_STARTUP_PROFILE} component={StartupProfile} isPrivate isRestricted />
			{/* <Route path={ROUTES_PATH.INSTRUCTOR_NETWORK} component={NetworkController} isPrivate isRestricted /> */}
			{/* <Route path={ROUTES_PATH.MENTOR_NETWORK} component={NetworkController} isPrivate isRestricted /> */}
			<Route path={ROUTES_PATH.INSTRUCTOR_NETWORK} component={Networking} isPrivate isRestricted />
			<Route path={ROUTES_PATH.MENTOR_NETWORK} component={Networking} isPrivate />
			<Route path={ROUTES_PATH.FEED_PATH} component={UserFeeds} isPrivate />
			<Route path={ROUTES_PATH.PAYWALL} component={PaymentPlan} isPrivate />
			<Route path={ROUTES_PATH.STARTUPGRID} component={BIDashboard} isPrivate isRestricted />
			<Route path={ROUTES_PATH.STARTUPGRID_BY_ID} component={BIDashboard} isPrivate isRestricted />
			<Route path={ROUTES_PATH.FEED_COMMENT_PATH} component={UserFeeds} isPrivate />
			<Route path={ROUTES_PATH.RESOURCE_CENTER} component={ResourceCenter} isPrivate />
			<Route path={ROUTES_PATH.MENTOR_PROFILE_NEW} exact component={MentorProfileNew} isPrivate />
			<Route path={ROUTES_PATH.NEW_ONBOARD_CUSTOMIZE} component={CustomizeOnboard} isPrivate />
			{/*<Route
        path={ROUTES_PATH.SCHEDULAR_AVAILABILITY}
        component={Availability}
        isPrivate
      />*/}
			{/*<Route
        path={ROUTES_PATH.MVP_PRIORITIZER}
        component={MVPPrioritizer}
        isPrivate
      />*/}
			{/*<Route
        path={ROUTES_PATH.MEETING_SCHEDULED}
        component={MeetingScheduledScreen}
        isPrivate
      />*/}
			{/* <Route
        path={ROUTES_PATH.FRAME_YOUR_STATEMENT}
        component={FrameYourStatement}
        isPrivate
      />*/}
			{/* Admin Routes */}
			<Route path={ROUTES_PATH.IMPERSONATE} component={Impersonate} isPrivate />
			{/*Partner*/}
			<Route path={ROUTES_PATH.PARTNER_DASHBOARD} exact component={PartnerDashboard} isPrivate />
			<Route path={ROUTES_PATH.PARTNER_ADMIN_DASHBOARD} exact component={PartnerAdmin} isPrivate />
			<Route path={ROUTES_PATH.EVENT_DASHBOARD} exact component={EventDashboard} isPrivate />
			<Route path={ROUTES_PATH.POWERPACK_DASHBOARD} component={AdminPowerPackController} isPrivate />
			<Route path={ROUTES_PATH.QUICK_SIGHT} exact component={Quicksight} isPrivate />
			<Route path={ROUTES_PATH.PARTNER_ADMIN_QA} exact component={AdminQABoard} isPrivate />
			<Route path={ROUTES_PATH.PARTNER_POWERUPS} exact component={PowerUpList} isPrivate />
			<Route path={ROUTES_PATH.PARTNER_POWERUP} component={PowerUpItem} isPrivate />
			<Route path={ROUTES_PATH.PARTNER_POWERUP_PREVIEW} component={PowerUpPreview} isPrivate />
			<Route path={ROUTES_PATH.PARTNER_POWERUP_VIEW} component={PowerUpPreview} isPrivate />
			<Route path={ROUTES_PATH.PARTNER_POWERUP_SUBMISSION} component={Submission} isPrivate />
			<Route path={ROUTES_PATH.ADMIN_COMPANY_USERS_IMPORT} exact component={CompanyUsersImport} isPrivate />
			{/* Testing for Vouch */}
			<Route path={ROUTES_PATH.VOUCH} exact component={Vouch} isPrivate />
			<Route exact path="*">
				<Redirect to={ROUTES_PATH.LOG_IN} />
			</Route>
		</Switch>
	);
}
