import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Sidebar as SidebarComp } from "../../Sidebar/index";

export const SidebarControl = ({ steps, currentStep, ...props }) => {
  const [stepsState, setStepsState] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const params = useParams();

  const handleClick = step => {
    setActiveStep(step);
    if (props && props.handleClick) {
      props.handleClick(step);
    }
  };
  useEffect(() => {
    setStepsState(steps);
  }, [steps]);

  useEffect(() => {
    if (currentStep) {
      setActiveStep(currentStep - 1); // -1 due to human readable 'step' vs index
    }
  }, [currentStep]);

  return (
    <SidebarComp
      handleClick={handleClick}
      title={props.title ? props.title : ""}
      activeIndex={activeStep}
      steps={stepsState}
    />
  );
};
