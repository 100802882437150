import { Box, Typography } from "@mui/material";
import moment from "moment";
import { Fragment } from "react";

export function MeetingHistoryColumnsDef() {
	const meetingHistoryColumns = [
		{
			field: "meetingDateAndTime",
			headerName: "Meeting Date & Time",
			sortable: false,
			filterable: false,
			wrapText: true,
			width: 250,
			renderCell: (params) => {
				const dateObj = moment(params.value);
				const formattedDate = dateObj.local().format("MM/DD/YYYY");
				const formattedTime = dateObj.local().format("hh:mm A");

				return (
					<Fragment>
						<Box display="flex" flexDirection="column">
							<Typography variant="Text/xs/Medium">{formattedDate ? formattedDate : "-"}</Typography>
							<Typography variant="Text/xs/Regular">{formattedTime ? formattedTime : "-"}</Typography>
						</Box>
					</Fragment>
				);
			},
			renderHeader: (params) => (
				<Fragment>
					<Typography variant="Text/xs/Medium">{params?.colDef?.headerName}</Typography>
				</Fragment>
			),
		},
		{
			field: "title",
			headerName: "Title",
			sortable: false,
			filterable: false,
			wrapText: true,
			width: 250,
			renderCell: (params) => (
				<Fragment>
					<Box>
						<Typography variant="Text/xs/Regular">{params.value ? params.value : "-"}</Typography>
					</Box>
				</Fragment>
			),
			renderHeader: (params) => <Typography variant="Text/xs/Medium">{params?.colDef?.headerName}</Typography>,
		},
		{
			field: "attendee",
			headerName: "Attendee",
			sortable: false,
			filterable: false,
			wrapText: true,
			width: 250,
			renderCell: (params) => (
				<Fragment>
					<Box>
						<Typography variant="Text/xs/Regular">{params.value ? params.value : "-"}</Typography>
					</Box>
				</Fragment>
			),
			renderHeader: (params) => <Typography variant="Text/xs/Medium">{params?.colDef?.headerName}</Typography>,
		},
		{
			field: "rescheduled",
			headerName: "Rescheduled",
			sortable: false,
			filterable: false,
			wrapText: true,
			width: 250,
			renderCell: (params) => (
				<Fragment>
					<Box>
						<Typography variant="Text/xs/Regular">{params.value ? params.value : "-"}</Typography>
					</Box>
				</Fragment>
			),
			renderHeader: (params) => <Typography variant="Text/xs/Medium">{params?.colDef?.headerName}</Typography>,
		},
		{
			field: "cancelled",
			headerName: " Cancelled",
			sortable: false,
			filterable: false,
			wrapText: true,
			width: 250,
			renderCell: (params) => (
				<Fragment>
					<Box>
						<Typography variant="Text/xs/Regular">{params.value ? params.value : "-"}</Typography>
					</Box>
				</Fragment>
			),
			renderHeader: (params) => <Typography variant="Text/xs/Medium">{params?.colDef?.headerName}</Typography>,
		},
	];

	return meetingHistoryColumns;
}
