import {
  Card,
  Grid,
  Typography,
  Box,
  CardActions,
  useMediaQuery,
  Button,
} from "@mui/material";
import React from "react";
import { useHistory, generatePath } from "react-router-dom";

import theme from "../../../../constants/Theme";
import CampaignIcon from "@mui/icons-material/Campaign";
import ROUTES_PATH from "../../../../constants/routes";
export default function TestPowerupBox({
  title,
  description,
  buttonText,
  handleButtonClick,
}) {
  const history = useHistory();

  return (
    <Card
      sx={theme => ({
        borderRadius: theme.shape.standard.borderRadius,
        border: `1px solid ${theme.palette.primary.gray74} `,
        p: 3,
      })}
    >
      <Box display="flex" alignItems="center">
        <CampaignIcon
          sx={{
            color: theme => theme.palette.text.red,
          }}
          fontSize="large"
        />
        <Typography
          variant="testHeading"
          sx={{ color: theme => theme.palette.text.atomic, ml: 1 }}
        >
          {title}
        </Typography>
      </Box>
      <Box sx={{ mt: 3 }}>
        <Typography
          sx={{
            color: theme => theme.palette.secondary.helperText,
          }}
          variant="bodyLarge"
        >
          {description}
        </Typography>
      </Box>
      <CardActions sx={{ mt: 3 }}>
        <Button
          sx={{ maxWidth: "fit-content", mr: "initial" }}
          onClick={() =>
            history.push({
              pathname: ROUTES_PATH.MARKET_TEST,
            })
          }
        >
          {" "}
          Test Now
        </Button>
      </CardActions>
    </Card>
  );
}
