import React from "react";
import { Box } from "@mui/material";
import { OnboardHeader } from "./Header";

import BGLogo from "../../Assets/new-onboard-bg-logo.png";
import { useThemeContext } from "../../../../constants/Theme/ThemeContext";

export const OnboardLayout = ({ children }) => {
	const {appNameOverride} = useThemeContext();

	return (
		<Box position="relative" sx={{ background: "#F5F9FA", overflow: "hidden", minHeight: "100vh" }}>
			<OnboardHeader />
			<Box
				position="relative"
				display="flex"
				alignItems="center"
				justifyContent="center"
				gap={8}
				zIndex={2}
				sx={(theme) => ({
					marginTop: "122px",
					p: "90px 145px",
					flexDirection: "row",
					[theme.breakpoints.down("lg")]: {
						p: "90px 100px",
					},
					[theme.breakpoints.down("md")]: {
						p: "90px 60px",
						flexDirection: "column-reverse",
					},
					[theme.breakpoints.down("sm")]: {
						p: "90px 10px",
					},
				})}
			>
				{children}
			</Box>
			{
				!appNameOverride &&
				<Box position="absolute" bottom="0" left="0">
					<img src={BGLogo} alt="Background Logo" />
				</Box>
			}
			
		</Box>
	);
};
