import * as actionTypes from "../../actions/actionTypes";
import { updateObject } from "../../../utils/updateObject";

const initialState = {
	eventList: [],
	currentObject: {},
};

const event = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SET_EVENT_DATA: {
			return action.payload === null ? initialState : updateObject(state, action.payload);
		}
		default:
			return state;
	}
};
export default event;
