import {
  Tooltip,
  Box,
  tooltipClasses,
  Typography,
  useMediaQuery,
  ClickAwayListener,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";

const DefaultComponent = ({ text }) => {
  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          m: "4px",
          gap: 1,
        }}
      >
        <Typography variant="title_medium" whiteSpace={"nowrap"}>
          {text}
        </Typography>
      </Box>
    </React.Fragment>
  );
};

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: `linear-gradient(0deg, rgba(245, 248, 250, 0.12), rgba(245, 248, 250, 0.12)), #0A141B`,
    color: theme.palette.secondary.white,
    fontSize: theme.typography.pxToRem(12),
    border: "none",
    borderRadius: 0,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: `linear-gradient(0deg, rgba(245, 248, 250, 0.12), rgba(245, 248, 250, 0.12)), #0A141B`,
  },
}));

const HtmlTooltipMobile = styled(({ className, ...props }) => (
  <ClickAwayListener>
    <Tooltip
      {...props}
      classes={{ popper: className }}
      onClose={() => props.handleTooltip()}
      open={props.open}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      onClick={() => props.handleTooltip()}
    />
  </ClickAwayListener>
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: `linear-gradient(0deg, rgba(245, 248, 250, 0.12), rgba(245, 248, 250, 0.12)), #0A141B`,
    color: theme.palette.secondary.white,
    fontSize: theme.typography.pxToRem(12),
    border: "none",
    borderRadius: 0,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: `linear-gradient(0deg, rgba(245, 248, 250, 0.12), rgba(245, 248, 250, 0.12)), #0A141B`,
  },
}));

const CustomTooltipWrapper = ({ children, text, placement, arrow }) => {
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("md"));
  const [open, setOpen] = useState(false);

  const handleTooltip = () => {
    setOpen(!open);
  };
  useEffect(() => {
    if (open) {
      setTimeout(() => {
        handleTooltip();
      }, "2000");
    }
  }, [open]);

  return isSmallScreen ? (
    <HtmlTooltipMobile
      arrow={arrow}
      title={text}
      placement={placement ? placement : "top"}
      isSmallScreen={isSmallScreen}
      handleTooltip={handleTooltip}
      open={open}
    >
      {children}
    </HtmlTooltipMobile>
  ) : (
    <HtmlTooltip
      arrow={arrow}
      title={text}
      placement={placement ? placement : "top"}
    >
      {children}
    </HtmlTooltip>
  );
};

{
  CustomTooltipWrapper.propTypes = {
    children: PropTypes.func,
    text: PropTypes.string || PropTypes.func,
    placement: PropTypes.string,
  };

  CustomTooltipWrapper.defaultProps = {
    children: null,
    text: <DefaultComponent />,
    placement: "top",
  };
}
export default CustomTooltipWrapper;
