import * as React from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { Typography } from "@mui/material";
import theme from "../../../../../constants/Theme";

export default function ButtonPopover({
  text,
  onAction,
  popupText,
  prefixIcon,
  hideRight = false,
  placement = "top",
}) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <React.Fragment>
      <ButtonGroup
        variant="contained"
        ref={anchorRef}
        aria-label="split button"
        sx={theme => ({ borderRadius: theme.shape.standard3.borderRadius })}
      >
        <Button
          startIcon={prefixIcon}
          sx={{ whiteSpace: "nowrap" }}
          onClick={handleToggle}
        >
          {text}
        </Button>
        {!hideRight && (
          <Button
            size="small"
            aria-controls={open ? "split-button-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            sx={{ width: "max-content" }}
            onClick={handleToggle}
          >
            <ArrowDropUpIcon />
          </Button>
        )}
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement={placement}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
              backgroundColor: theme => theme.palette.secondary.textFieldBorder,
              borderRadius: theme => theme.shape.standard5.borderRadius,
              marginBottom: theme => theme.spacing(1),
              boxShadow: "2px",
              marginTop: "3px",
            }}
          >
            <Paper sx={{ p: 1 }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  <MenuItem onClick={event => onAction()}>
                    <Typography variant="bodyLarge">{popupText}</Typography>{" "}
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
}
