import { Grid, TextField, Box, Typography, Stack, Button } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import AutocompleteNew from "../../common/AutocompleteNew";

import SelectInput from "../../common/SelectInput";
import { AREAS_OF_EXPERTISE } from "../../constants/mentor-data";
import messages from "../../constants/messages";
import ROUTES_PATH from "../../constants/routes";
import theme from "../../constants/Theme";
import { customerTagValid } from "../../containers/Workspace/PowerUP/ProblemSolution/functions/common";
import services from "../../services";
import { requestFormValid, buttonDisable } from "./functions";

const RequestForm = () => {
  const [userInputs, setUserInputs] = useState({
    firstName: "",
    lastName: "",
    email: "",
    profession: "",
    areaOfExpertise: "",
    linkedIn: "",
    website: "",
    description: "",
  });
  const [errorMessages, setErrorMessages] = useState({
    firstName: "",
    lastName: "",
    email: "",
    profession: "",
    areaOfExpertise: "",
    linkedIn: "",
    website: "",
    description: "",
  });

  const history = useHistory();

  const userType = useSelector(state => state.auth.userType);

  const handleBlur = (e, key) => {
    switch (key) {
      case "firstName":
        if (requestFormValid("firstName", e.target.value)) {
          setErrorMessages({ ...errorMessages, firstName: "" });
        } else {
          setErrorMessages({
            ...errorMessages,
            firstName: messages.NAME_VALID,
          });
        }
        break;

      case "lastName":
        if (requestFormValid("lastName", e.target.value)) {
          setErrorMessages({ ...errorMessages, lastName: "" });
        } else {
          setErrorMessages({ ...errorMessages, lastName: messages.NAME_VALID });
        }
        break;

      case "email":
        if (requestFormValid("email", e.target.value)) {
          setErrorMessages({ ...errorMessages, email: "" });
        } else {
          setErrorMessages({ ...errorMessages, email: messages.INVALID_EMAIL });
        }
        break;

      case "profession":
        if (requestFormValid("profession", e.target.value)) {
          setErrorMessages({ ...errorMessages, profession: "" });
        } else {
          setErrorMessages({
            ...errorMessages,
            profession: messages.NAME_VALID,
          });
        }
        break;

      case "linkedIn":
        if (requestFormValid("linkedIn", e.target.value)) {
          setErrorMessages({ ...errorMessages, linkedIn: "" });
        } else {
          setErrorMessages({
            ...errorMessages,
            linkedIn: messages.INVALID_URL,
          });
        }
        break;
      case "website":
        if (requestFormValid("website", e.target.value) || !e.target.value) {
          setErrorMessages({ ...errorMessages, website: "" });
        } else {
          setErrorMessages({
            ...errorMessages,
            website: messages.INVALID_URL,
          });
        }
        break;
      case "description":
        if (
          requestFormValid("description", e.target.value) ||
          !e.target.value
        ) {
          setErrorMessages({ ...errorMessages, description: "" });
        } else {
          setErrorMessages({
            ...errorMessages,
            description: messages.DESCRIPTION,
          });
        }
        break;
    }
  };

  const handleInputChange = (e, key) => {
    const value = e.target.value;
    const currentInput = { ...userInputs };
    currentInput[key] = value;
    setUserInputs(currentInput);
  };

  const getHelperText = key => {
    return !!errorMessages[key] ? errorMessages[key] : "*required";
  };

  const handleSubmit = () => {
    const payload = {
      email: userInputs?.email,
      firstName: userInputs?.firstName,
      lastName: userInputs?.lastName,
      profession: userInputs?.profession,
      expertise:
        userInputs?.areaOfExpertise?.length > 0
          ? userInputs?.areaOfExpertise?.join(",")
          : "",
      linkedInUrl: userInputs?.linkedIn,
      website1: userInputs?.website,
      message: userInputs?.description?.trim(),
      userType: userType,
    };
    services
      .userOnboarding(payload)
      .then(res => {
        const resData = res.data;
        if (resData?.code === 200) {
          history.push(ROUTES_PATH.REQUEST_SUBMITTED);
        }
      })
      .catch(err => {
        if (err?.response?.status === 409) {
          history.push(ROUTES_PATH.INVALID_EMAIL);
        }
      });
  };

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{ minHeight: "100%" }}
    >
      <Box
        sx={{
          py: 10,
          [theme.breakpoints.down("md")]: {
            px: 2,
          },
        }}
      >
        <Box sx={{ mr: "auto" }}>
          <Typography variant="headline1">
            Request to be a {userType === "MENTOR_USER" ? "mentor" : "advisor"}
          </Typography>
        </Box>
        <Grid
          className="signup-grid-container"
          sx={{ mt: 4 }}
          container
          alignItems="center"
          justifyContent="center"
          spacing={2}
        >
          <Grid item md={6} lg={6} sm={12} xs={12}>
            <TextField
              label="First Name"
              spellCheck="true"
              required
              fullWidth
              value={userInputs?.firstName}
              style={{ mt: 0 }}
              onBlur={e => handleBlur(e, "firstName")}
              onChange={e => handleInputChange(e, "firstName")}
              error={!!errorMessages.firstName}
              helperText={getHelperText("firstName")}
            />
          </Grid>
          <Grid item md={6} lg={6} sm={12} xs={12}>
            <TextField
              label="Last Name"
              spellCheck="true"
              required
              value={userInputs?.lastName}
              fullWidth
              style={{ mt: 0 }}
              onBlur={e => handleBlur(e, "lastName")}
              onChange={e => handleInputChange(e, "lastName")}
              error={!!errorMessages.lastName}
              helperText={getHelperText("lastName")}
            />
          </Grid>
          <Grid item md={12} lg={12} sm={12} xs={12}>
            <TextField
              label="Email Address"
              spellCheck="true"
              required
              value={userInputs?.email}
              fullWidth
              style={{ mt: 0 }}
              onBlur={e => handleBlur(e, "email")}
              onChange={e => handleInputChange(e, "email")}
              error={!!errorMessages.email}
              helperText={getHelperText("email")}
            />
          </Grid>
          <Grid item md={6} lg={6} sm={12} xs={12}>
            <TextField
              label="Profession"
              spellCheck="true"
              required
              value={userInputs?.profession}
              fullWidth
              style={{ mt: 0 }}
              onBlur={e => handleBlur(e, "profession")}
              onChange={e => handleInputChange(e, "profession")}
              error={!!errorMessages.profession}
              helperText={getHelperText("profession")}
            />
          </Grid>
          <Grid item md={6} lg={6} sm={12} xs={12} sx={{ mb: "auto" }}>
            {/*<AutocompleteInput
              label={"Areas of Expertise"}
              handleInputChange={handleInputChange}
              inputKey={"areaOfExpertise"}
              autoCompleteData={AREAS_OF_EXPERTISE}
              inputValue={userInputs?.areaOfExpertise || []}
              inputValid={customerTagValid}
              errorMessage={messages.MULTITAG_INPUT_INVALID}
              sx={{ mt: 0 }}
              typingEnabled
              multiple
              handleOnBlurEvent={true}
            />*/}

            <AutocompleteNew
              label={"Areas of Expertise"}
              handleInputChange={handleInputChange}
              inputKey={"areaOfExpertise"}
              autoCompleteData={AREAS_OF_EXPERTISE}
              inputValue={userInputs?.areaOfExpertise || []}
              inputValid={customerTagValid}
              errorMessage={messages.MULTITAG_INPUT_INVALID}
              sx={{ mt: 0 }}
              typingEnabled
              multiple
              handleOnBlurEvent={true}
            />
          </Grid>
          <Grid item md={6} lg={6} sm={12} xs={12}>
            <TextField
              label="LinkedIn Profile"
              required
              spellCheck="true"
              fullWidth
              style={{ mt: 0 }}
              value={userInputs?.linkedIn}
              onBlur={e => handleBlur(e, "linkedIn")}
              onChange={e => handleInputChange(e, "linkedIn")}
              error={!!errorMessages.linkedIn}
              helperText={getHelperText("linkedIn")}
            />
          </Grid>
          <Grid item md={6} lg={6} sm={12} xs={12} sx={{ mb: "auto" }}>
            <TextField
              label="Personal Website/Portfolio"
              fullWidth
              style={{ mt: 0 }}
              spellCheck="true"
              value={userInputs?.website}
              onBlur={e => handleBlur(e, "website")}
              onChange={e => handleInputChange(e, "website")}
              error={!!errorMessages.website}
              helperText={errorMessages.website}
            />
          </Grid>
          <Grid item md={12} lg={12} sm={12} xs={12}>
            <Box sx={{ mt: 2, mb: 3 }}>
              <Typography variant="title_medium">
                Why do you want to be a{" "}
                {userType === "MENTOR_USER" ? "mentor" : "advisor"} for
                StartupOS?
              </Typography>
            </Box>
            <TextField
              multiline
              minRows={5}
              fullWidth
              spellCheck="true"
              value={userInputs?.description}
              onBlur={e => handleBlur(e, "description")}
              onChange={e => handleInputChange(e, "description")}
              error={!!errorMessages.description}
              helperText={errorMessages.description}
              sx={{
                [theme.breakpoints.down("md")]: {
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: "white",
                  },
                },
              }}
            />
          </Grid>
          <Grid
            item
            md={12}
            lg={12}
            sm={12}
            xs={12}
            display="flex"
            justifyContent="center"
          >
            <Button
              sx={{ mt: 2 }}
              disabled={!buttonDisable(userInputs)}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Stack>
  );
};

export default RequestForm;
