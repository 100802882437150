import React from 'react';
import { Box, Typography } from "@mui/material";
import { USER_TYPES } from '../../../../utils/userTypes';

function VerifyEmailConfirmation({ userType, userEmail }) {
	const getNameString = () => {
		if (userType === USER_TYPES.STARTUP_OWNER) {
			return "Founder";
		}
		if (userType === USER_TYPES.MENTOR_USER) {
			return "Mentor";
		}
		if (userType === USER_TYPES.INVESTOR_USER) {
			return "Investor";
		}
		if (userType === USER_TYPES.STARTUP_USER) {
			return "Teammate";
		}
		return "User";
	};
	return (
		<Box textAlign="center" display="flex" justifyContent="center" flexDirection="column" alignItems="center" rowGap={4}>
			<Typography variant="poppinsSemiBold30" color="secondary.black" sx={{ lineHeight: "38px" }}>
				{`We are excited for your success, Mighty ${getNameString()}!`}
			</Typography>
			<Typography variant="Text/sm/Regular">Before we get started, we need to verify your account via email.</Typography>
			{/* <Typography variant="poppinsSemiBold30" color="secondary.black" sx={{ lineHeight: "38px" }}>
				We sent an email verification to {userEmail}. Verify your email to login!
			</Typography> */}
		</Box>
	);
}

export default VerifyEmailConfirmation