import React from "react";
import { Grid, Box, Typography, Divider } from "@mui/material";
import { PLUGIN_LOGO } from "../../../constants";

export default function Header({ title = "", subHeader = "" }) {
  return (
    <Box mb={4}>
      <Grid container>
        <Grid item md={9} lg={10}>
          <img src={PLUGIN_LOGO} alt="Plugin Logo" />
          <Box sx={{ mt: 1 }}>
            <Typography variant="headline1">
              <span dangerouslySetInnerHTML={{ __html: title }} />
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            display: "flex",
            gap: 3,
            mt: 1,
            mb: 6,
            flexDirection: { xs: "column-reverse", md: "column" },
          }}
        >
          <Grid item md={9} lg={10}>
            {/*<Typography variant="subhead1">{subHeader}</Typography>*/}
          </Grid>
        </Grid>
      </Grid>

      <Divider sx={{ borderColor: "secondary.light" }} />
    </Box>
  );
}
