import services from "../../../../services";

function getLatestCompleted(data) {
	try {
		const latestCompletedObj = data.reduce((acc, curr) => {
			if (curr.isCompleted && (!acc || new Date(curr.completionDate) > new Date(acc.completionDate))) {
				return curr;
			}
			return acc;
		}, null);

		return latestCompletedObj;
	} catch (e) {
		console.log("Function error when attempting to get the latest completed latestCompleteect data. Failed with error: ", e);
		return {};
	}
}

async function getValueProp(ID) {
	try {
		const resp = await services.getValuePropPowerUPData(ID);
		const latestComplete = getLatestCompleted(resp?.data?.data);
		if (latestComplete) {
			const latestCompleteResponses = latestComplete?.moduleStepResponses?.find((responseObj) => responseObj.moduleStep.stepNo === 5);
			if (latestCompleteResponses) {
				const answers = JSON.parse(latestCompleteResponses.answers);
				const values = {};
				for (const answer of answers) {
					values[answer.key] = answer.value;
				}
				return `Our ${values.productService}, helps ${values.customerType}, who want to ${values.customerJobs}, by ${values.customerPains}, and ${values.customerGains}`;
			}
		}
	} catch (e) {
		console.log("API error when attempting to get Value Prop PowerUP data for company. Failed with error: ", e);
	}
}

async function getBrandValues(ID) {
	try {
		const resp = await services.getBrandstormingPowerUPData(ID);
		const data = resp?.data?.data;
		if (data) {
			const latestComplete = getLatestCompleted(data);
			if (latestComplete) {
				const latestCompleteResponses = latestComplete.moduleStepResponses?.find((responseObj) => responseObj.moduleStep.stepNo === 2);
				if (latestCompleteResponses) {
					const answers = JSON.parse(latestCompleteResponses.answers);
					return {
						mission: answers.brandMission,
						values: answers.brandValues,
					};
				}
			}
		}
		return null;
	} catch (e) {
		console.log("API error when attempting to get Brandstorming PowerUP data for company. Failed with error: ", e);
		return null;
	}
}

export async function getHeroData(companyID) {
	const valueprop = await getValueProp(companyID);
	const brandstorm = await getBrandValues(companyID);
	return {
		valueprop,
		brandstorm,
	};
}
