import React from "react";
import { Box, Typography } from "@mui/material";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

export default function ArrowButtonSection({ label, value }) {
	return (
		<Box display={"flex"} alignItems={"center"} gap={"12px"} sx={{ width: "180px" }}>
			<ArrowForwardIcon sx={{ fontSize: "26px", color: "rgba(152, 162, 179, 1)" }} className="add-icon-hover" />
			<Typography variant="Text/sm/Regular"> {`${label}: ${value}`}</Typography>
		</Box>
	);
}
