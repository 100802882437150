import { store } from "../modules/index";
import { setNotificationData } from "../modules/actions/notificationActions";

export const ProcessPayload = (event) => {
	let incomingMessage = JSON.parse(event?.data);
	if (incomingMessage?.NOTIFICATION_ID) {
		store.dispatch(
			setNotificationData({
				lastNotification: incomingMessage,
			})
		);
	}
};
