import React, { useContext } from "react";
import { ResourceCenterContext } from "../Stores/ResourceCenterStore";
import { Box, Typography } from "@mui/material";
import ListSkeleton from "../Common/ListSkeleton";
import CardItem from "../Common/CardItem";
import JsxParser from "react-jsx-parser";
import { useHistory } from "react-router-dom";

const ResultItem = ({ title, snippet, timeout, id }) => {
	const history = useHistory();
	return (
		<CardItem timeout={timeout} onClick={() => history.push(`/resource-center/search-results/articles/${id}`)}>
			<Box
				sx={{
					display: "flex",
					gap: 2,
					...(!snippet && { alignItems: "center" }),
				}}
			>
				<Box sx={{ flex: 1 }}>
					<Typography variant="Display/sm/Semibold" sx={{ ...(!snippet && { mb: 1 }) }} component={"h6"}>
						{title}
					</Typography>
					{snippet && (
						<Typography component={"p"} variant="Text/md/Regular">
							<JsxParser autoCloseVoidElements jsx={`... ${snippet}...`} />
						</Typography>
					)}
				</Box>
			</Box>
		</CardItem>
	);
};

const ResultList = () => {
	const { searchResults } = useContext(ResourceCenterContext);
	if (searchResults && searchResults?.count > 0) {
		return searchResults?.results?.map((item, index) => (
			<ResultItem key={item?.id} id={item?.id} title={item?.title} snippet={item?.snippet} timeout={50 + 50 * (index + 1)} />
		));
	} else {
		return "No results";
	}
};

function SearchResults() {
	const { searchLoading, currentSearchQuery, searchResults } = useContext(ResourceCenterContext);

	return (
		<Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
			<Typography component={"h2"} variant="Text/xl/Semibold">
				Search results for:{" "}
				<Typography component={"span"} variant="Text/xl/Semibold" sx={{ color: (theme) => theme.palette.primary.main }}>
					{currentSearchQuery}
				</Typography>
			</Typography>
			<Box
				sx={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					flexWrap: "wrap",
					gap: 2,
				}}
			>
				{searchLoading ? <ListSkeleton /> : <ResultList />}
			</Box>
		</Box>
	);
}

export default SearchResults;
