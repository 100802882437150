import { Box, InputAdornment, TextField } from "@mui/material";
import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import UnpublishedOutlinedIcon from "@mui/icons-material/UnpublishedOutlined";

export default function StartupOsTextField({ isEndAdornment = true, ...props }) {
	const getAdornment = () => {
		if (props.error) {
			return (
				<InputAdornment position="end">
					<svg width={0} height={0}>
						<linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
							<stop offset={0} stopColor="#FFD141" />
							<stop offset={1} stopColor="#E97300" />
						</linearGradient>
					</svg>
					<UnpublishedOutlinedIcon
						sx={{
							fill: "url(#linearColors)",
						}}
					/>
				</InputAdornment>
			);
		} else {
			if (props.value?.trim()) {
				return (
					<InputAdornment position="end">
						<CheckCircleIcon sx={{ color: "success.main" }} />
					</InputAdornment>
				);
			} else {
				return (
					<InputAdornment position="end">
						<svg width={0} height={0}>
							<linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
								<stop offset={0} stopColor="#FFD141" />
								<stop offset={1} stopColor="#E97300" />
							</linearGradient>
						</svg>
						<UnpublishedOutlinedIcon
							sx={{
								fill: "url(#linearColors)",
							}}
						/>
					</InputAdornment>
				);
			}
		}
	};
	return (
		<TextField
			{...props}
			color={props.error ? "warning" : "primary"}
			InputLabelProps={{
				...props.InputLabelProps,
				shrink: true,
			}}
			InputProps={{
				...props.InputProps,
				endAdornment: isEndAdornment && getAdornment(),
			}}
		/>
	);
}
