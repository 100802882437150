import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Box, Typography, Button, Container } from "@mui/material";
import TextInput from "../../common/input/Input";
import services from "../../services";
import ROUTES_PATH from "../../constants/routes";
import * as actions from "../../modules/actions";
import { getCompany } from "../../utils/auth0-spa";
import { toastContainer } from "../../utils/toast";
import { getUserUrl } from "../../utils/urls";

const Impersonate = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const [searchStr, setSearchStr] = useState("");
	const searchStrRef = useRef(null);
	const [users, setUsers] = useState([]);
	const authData = useSelector((state) => state?.auth);

	useEffect(() => {
		if (authData.isAdmin === false) {
			history.push(ROUTES_PATH.INVESTOR_WORKSPACE);
		} else {
			getUsers();
		}
	}, [authData.isAdmin]);

	const getUsers = async () => {
		try {
			fetch(getUserUrl(), {
				headers: { Authorization: "Bearer " + authData?.idToken },
			})
				.then((response) => response.json())
				.then((users) => {
					setUsers(users.data.filter((user) => user.name));
				});
		} catch (error) {
			toastContainer(error?.data?.message || "Something is wrong for API side", "error");
		}
	};

	const handleSearchInputChange = (event) => {
		setSearchStr(event);
	};

	const handleRedirection = async (user) => {
		try {
			dispatch(
				actions.setAuthData({
					impersonateProviderId: user?.identities[0]?.provider,
				})
			);

			const companyData = await getCompany()
				.then((resp) => {
					return resp;
				})
				.catch((e) => {
					return {};
				});

			const companyProfile = await services
				.getCompanyProfile()
				.then((resp) => {
					return resp;
				})
				.catch((e) => {
					return {};
				});

			const getUser = await services.getUserProfile();
			const userProfile = getUser.data.data;

			const impersonateAuthData = {
				companyData,
				companyLogoUrl: companyProfile?.data?.data?.logoUrl,
				userId: userProfile.id,
				userData: {
					picture: userProfile.picture,
				},
				calUserId: userProfile.calUserId,
				email: userProfile.email,
				companyName: userProfile.companyModel?.companyName,
				companyId: userProfile.companyModel?.id,
				userType: userProfile.userTypes[0] || "STARTUP_OWNER",
			};

			if (!!userProfile.isTourCompleted === false) {
				impersonateAuthData.showModal = true;
			}

			if (userProfile && Object.keys(userProfile).length) {
				impersonateAuthData.userName = userProfile.firstName + " " + userProfile.lastName;
			} else {
				impersonateAuthData.userName = userProfile.name;
			}

			dispatch(actions.setAuthData(impersonateAuthData));

			if (user?.identities[0]?.provider) {
				window.open(`${process.env.REACT_APP_HOME_URL}`, "_blank");
			} else {
				window.open(`${process.env.REACT_APP_HOME_URL}`, "_self");
			}
		} catch (error) {
			console.log(error);
			dispatch(
				actions.setAuthData({
					impersonateProviderId: null,
				})
			);

			toastContainer(error?.data?.message || "Something is wrong for impersonation", "error");
		}
	};

	const handleSearch = (event) => {
		const { key } = event;
		if (key === "Enter") {
			searchStrRef.current.focus();
			if (searchStr !== "") {
				const tempUsers = users.filter(
					(user) => user.name.toLowerCase().includes(searchStr.toLowerCase()) || user.email.toLowerCase().includes(searchStr.toLowerCase())
				);
				setUsers(tempUsers);
			} else {
				getUsers();
			}
		}
	};

	const handleStop = () => {
		handleRedirection(null);
	};

	return (
		<Container maxWidth="xl">
			<Box width="100%" position="relative">
				<Box display="flex" alignItems="flex-end" flexDirection="column">
					<Box width="100%">
						<TextInput
							type="text"
							label="Enter Startup name or Person name"
							value={searchStr}
							spellCheck="true"
							onChange={(e) => handleSearchInputChange(e)}
							inputRef={searchStrRef}
							inputProps={{
								onKeyPress: handleSearch,
							}}
						/>
					</Box>

					{authData.impersonateProviderId && (
						<Box mt={2}>
							<Button onClick={handleStop}>Stop Impersonation</Button>
						</Box>
					)}
				</Box>

				<Box
					mt={8}
					sx={{
						height: "calc(100vh - 260px)",
						overflow: "scroll",
					}}
				>
					<Box display="flex" alignItems={"center"} justifyContent="space-between">
						<Typography variant="body_large" sx={{ flex: 1, padding: 2, fontWeight: 600 }}>
							No
						</Typography>
						<Typography variant="body_large" sx={{ flex: 3, padding: 2, fontWeight: 600 }}>
							Email
						</Typography>
						<Typography variant="body_large" sx={{ flex: 2, padding: 2, fontWeight: 600 }}>
							Username
						</Typography>
						<Typography variant="body_large" sx={{ flex: 2, padding: 2, fontWeight: 600 }}>
							userID
						</Typography>
						<Typography variant="body_large" sx={{ flex: 2, padding: 2, fontWeight: 600 }}>
							ProviderID
						</Typography>
					</Box>
					{users.map((user, index) => (
						<Box
							key={user.id}
							display="flex"
							alignItems={"center"}
							justifyContent="space-between"
							sx={{
								borderBottom: "1px solid black",
								":hover": { cursor: "pointer" },
							}}
							onClick={() => handleRedirection(user)}
						>
							<Typography variant="body_large" sx={{ flex: 1, padding: 2 }}>
								{index + 1}
							</Typography>
							<Typography variant="body_large" sx={{ flex: 3, padding: 2 }}>
								{user.email}
							</Typography>
							<Typography variant="body_large" sx={{ flex: 2, padding: 2 }}>
								{user.name}
							</Typography>
							<Typography variant="body_large" sx={{ flex: 2, padding: 2 }}>
								{user.user_id}
							</Typography>
							<Typography variant="body_large" sx={{ flex: 2, padding: 2 }}>
								{user.identities[0]?.provider}
							</Typography>
						</Box>
					))}
				</Box>
			</Box>
		</Container>
	);
};

export default Impersonate;
