import { AnnouncementOutlined } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import DialogComponent from "../../../../../common/CommonDialog";
import services from "../../../../../services";
import { toastContainer } from "../../../../../utils/toast";
import CreateConversationSearch from "./CreateConversationSearch";

export default function AddToConversation({ close, open, conversationID }) {
  const [loading, setloading] = useState(false);
  const authData = useSelector(state => state?.auth);
  const [addIds, setaddIds] = useState([]);

  const addConversation = async () => {
    setloading(true);
    services
      .addConversation({
        userIDs: addIds,
        convoID: conversationID,
      })
      .catch(e => {
        console.log("Could not add to conversation.", e);
        toastContainer(
          "Could not add to conversation. Please try again later.",
          "error"
        );
      })
      .finally(() => {
        setloading(false);
        setaddIds([]);
        close();
      });
  };
  return (
    <DialogComponent
      isOpen={open}
      onClose={() => {
        close();
      }}
      title={"Conversation Details"}
      titleVariant="title_large"
      PaperProps={{
        sx: theme => ({
          borderRadius: theme.shape.standard.borderRadius,
        }),
      }}
      maxWidth={"md"}
      fullWidth={true}
      dialogAction={
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: 1,
            "& > button": {
              width: "auto",
              m: 0,
            },
          }}
        >
          <Button
            variant="outlined"
            onClick={() => {
              close();
            }}
            disabled={loading}
          >
            Cancel
          </Button>
          <LoadingButton
            disabled={addIds && addIds.length < 1}
            variant="contained"
            color="primary"
            loading={loading}
            onClick={() => {
              setloading(true);
              addConversation();
            }}
          >
            Add{addIds && addIds.length > 0 ? " " + addIds.length + " " : " "}
            {addIds && addIds.length > 1 ? "People" : "Person"}
          </LoadingButton>
        </Box>
      }
    >
      <Box sx={{ m: 2 }}>
        <CreateConversationSearch
          add
          addConversation={resp => setaddIds(resp)}
        />
      </Box>
    </DialogComponent>
  );
}
