import React from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { DOWNLOAD_V1_ICON, FILE_V1_ICON } from "../../constants";
import CloseIcon from "@mui/icons-material/Close";

const FileCardSectionComponent = ({ type, fileLink, fileName, fileSize, removeFile, handleOnClick }) => {
	const handleDownload = () => {
		if (!e) var e = window.event;
		e.cancelBubble = true;
		if (e.stopPropagation) e.stopPropagation();
		fetch(fileLink).then((response) => {
			response.blob().then((blob) => {
				let url = window.URL.createObjectURL(blob);
				let a = document.createElement("a");
				a.href = url;
				a.download = fileName;
				a.click();
			});
		});
	};

	return (
		<Box
			sx={(theme) => ({
				display: "flex",
				flexDirection: "row",
				gap: 2,
				alignItems: "center",
			})}
		>
			<Box
				sx={(theme) => ({
					display: "flex",
					flexDirection: "row",
					gap: 2,
				})}
				onClick={handleOnClick}
			>
				<IconButton
					sx={(theme) => ({
						borderRadius: "100%",
						width: "40px",
						height: "40px",
						backgroundColor: theme.palette.secondary.secondaryPurple,
					})}
				>
					<Box component={"img"} src={FILE_V1_ICON} alt="file" />
				</IconButton>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
					}}
				>
					<Typography
						variant="flyout_text"
						sx={(theme) => ({
							[theme.breakpoints.down("sm")]: {
								maxWidth: "160px",
								overflow: "hidden",
								textOverflow: "ellipsis",
								whiteSpace: "nowrap",
							},
						})}
					>
						{fileName}
					</Typography>
					<Typography variant="subhead2">{(fileSize / (1024 * 1024)).toFixed(2)} MB</Typography>
				</Box>
			</Box>
			<Box
				sx={{
					ml: "auto",
				}}
			>
				{type === "upload" ? (
					<IconButton
						aria-label="close"
						onClick={removeFile}
						sx={{
							background: "white",
							width: "40px",
							height: "40px",
							ml: "auto",
							"&:hover": {
								background: "white",
							},
						}}
					>
						<CloseIcon sx={{ color: (theme) => theme.palette.secondary.black, fontSize: "20px" }} />
					</IconButton>
				) : (
					<IconButton
						onClick={handleDownload}
						sx={(theme) => ({
							width: "40px",
							height: "40px",
							ml: "auto",
						})}
					>
						<Box component={"img"} src={DOWNLOAD_V1_ICON} alt="download" />
					</IconButton>
				)}
			</Box>
		</Box>
	);
};

export default FileCardSectionComponent;
