import React, { useState, useEffect } from "react";
import { Box, Grid, Button, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import { DEFAULT_AVATAR } from "../../../../constants/image";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import theme from "../../../../constants/Theme";
import { Check } from "@mui/icons-material";

export default function ProfileHeader({
  saved,
  setSaved,
  invitedCandidates,
  handleSelect,
  invited,
  setShowInviteCandidatePopup,
  details,
}) {
  return (
    <>
      <Grid container>
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: 1,
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <img
              src={DEFAULT_AVATAR}
              height="56"
              width="56"
              style={{ borderRadius: "50%", paddingTop: 4 }}
            />
            <Box sx={{ display: { xs: "block", md: "none" } }}>
              <Button
                variant="nofill"
                startIcon={saved ? <BookmarkIcon /> : <BookmarkBorderIcon />}
                size="sm"
                sx={{ width: "max-content" }}
                onClick={handleSelect}
              >
                Save
              </Button>
            </Box>
          </Box>
          <Typography variant="largeTitle">
            {details ? details?.name : "External Candidate"}
          </Typography>
        </Grid>
        <Grid item xs={12} md={12} sx={{ my: 1 }}>
          <Box
            sx={theme => ({
              display: "flex",
              width: "max-content",
              border: `1px solid ${theme.palette.containerBorder.blue}`,
              borderRadius: theme.shape.standard.borderRadius,
              px: 2,
              py: 1,
              background: theme.palette.containerBorder.background1,
              color: theme.palette.containerBorder.blue,
            })}
          >
            <Typography variant="tag">External Candidate</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={12}>
          <Typography
            variant="lable_large"
            sx={{ color: theme.palette.text.secondary }}
          >
            Title
          </Typography>
          <FiberManualRecordIcon
            fontSize="small"
            sx={{
              maxHeight: "8px",
              fill: theme.palette.secondary.dark,
              alignSelf: "center",
            }}
          />
          <Typography
            variant="lable_large"
            sx={{ color: theme.palette.secondary.dark }}
          >
            City, State
          </Typography>
        </Grid>
        <Grid item xs={6} md={6} sx={{ my: 1 }}>
          {details?.invited ? (
            <Button
              variant="text"
              startIcon={<Check />}
              sx={{ width: "max-content", mr: 1, color: "text.custom3" }}
            >
              Invite Sent
            </Button>
          ) : (
            <Button
              variant="outlined"
              startIcon={<AddIcon />}
              sx={{ width: "max-content", mr: 1 }}
              onClick={() => setShowInviteCandidatePopup(true)}
            >
              Invite
            </Button>
          )}
        </Grid>
        <Grid
          item
          xs={6}
          md={6}
          sx={{
            display: { xs: "none", md: "flex" },
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}
        >
          <Box>
            <Button
              variant="nofill"
              startIcon={saved ? <BookmarkIcon /> : <BookmarkBorderIcon />}
              size="sm"
              sx={{ width: "max-content" }}
              onClick={handleSelect}
            >
              Save
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
