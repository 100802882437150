import DesignIcon from "../Assets/design-icon.svg";
import MarketingIcon from "../Assets/marketing-icon.svg";
import OperationsIcon from "../Assets/operations-icon.svg";
import OpportunistIcon from "../Assets/opportunist-icon.svg";
import ProductMgmtIcon from "../Assets/product-mgmt-icon.svg";
import TechnologistIcon from "../Assets/technologist-icon.svg";

export const EXPERTISE_OPTIONS = [
	{ id: 0, icon: OpportunistIcon, title: "Startup Strategy & Execution" },
	{ id: 1, icon: MarketingIcon, title: "Marketing & Growth" },
	{ id: 2, icon: OperationsIcon, title: "Operations & Infrastructure" },
	{ id: 3, icon: DesignIcon, title: "User Experience & Design" },
	{ id: 4, icon: ProductMgmtIcon, title: "Team Building & Leadership" },
	{ id: 5, icon: TechnologistIcon, title: "Engineering & Technical" },
];

export const EXPERTISE_SUB_OPTIONS = [
	{
		parentId: 0,
		content: [
			{ id: 0, title: "Idea validation" },
			{ id: 1, title: "Market sizing" },
			{ id: 2, title: "Acquire first/lead customers" },
			{ id: 3, title: "Go-to-Market strategy (GTM)" },
			{ id: 4, title: "Bootstrapping" },
			{ id: 5, title: "Pricing strategy" },
			{ id: 6, title: "MVP support" },
			{ id: 7, title: "Product launch" },
			{ id: 8, title: "Product Market Fit (PMF)" },
			{ id: 9, title: "Fundraising" },
			{ id: 10, title: "Valuation & Terms sheets" },
		],
	},
	{
		parentId: 1,
		content: [
			{ id: 0, title: "Content marketing" },
			{ id: 1, title: "Pay-per-Click strategies (PPC)" },
			{ id: 2, title: "Product analytics" },
			{ id: 3, title: "Conversion rate optimization" },
			{ id: 4, title: "SEO" },
			{ id: 5, title: "Marketing automation" },
			{ id: 6, title: "Social media" },
			{ id: 7, title: "Copywriting" },
			{ id: 8, title: "Email marketing" },
			{ id: 9, title: "Public relations" },
			{ id: 10, title: "Customer success" },
			{ id: 11, title: "Product marketing" },
		],
	},
	{
		parentId: 2,
		content: [
			{ id: 0, title: "Back-office integration" },
			{ id: 1, title: "Financial reporting" },
			{ id: 2, title: "Legal" },
			{ id: 3, title: "Business formation" },
			{ id: 4, title: "Technology and tools" },
			{ id: 5, title: "Finance" },
			{ id: 6, title: "Operations" },
			{ id: 7, title: "Business development" },
			{ id: 8, title: "HR" },
		],
	},
	{
		parentId: 3,
		content: [
			{ id: 0, title: "Customer experience" },
			{ id: 1, title: "Design/UX" },
			{ id: 2, title: "Visual Design" },
			{ id: 3, title: "Brand" },
			{ id: 4, title: "User research" },
			{ id: 5, title: "Infographics design" },
		],
	},
	{
		parentId: 4,
		content: [
			{ id: 0, title: "OKRs/Performance" },
			{ id: 1, title: "Building a team" },
			{ id: 2, title: "Virtual facilitator" },
			{ id: 3, title: "Storyteller" },
		],
	},
	{
		parentId: 5,
		content: [
			{ id: 0, title: "Technology stack selection" },
			{ id: 1, title: "Software architecture and design patterns" },
			{ id: 2, title: "Agile methodologies and project management" },
			{ id: 3, title: "Quality assurance and testing" },
			{ id: 4, title: "Scalability and performance optimization" },
			{ id: 5, title: "Security best practices" },
			{ id: 6, title: "Database design and management" },
			{ id: 7, title: "API development and integration" },
			{ id: 8, title: "Mobile app development" },
			{ id: 9, title: "Front-end development (HTML, CSS, JavaScript)" },
			{ id: 10, title: "Backend development (server-side languages, frameworks)" },
			{ id: 11, title: "Cloud infrastructure and deployment (AWS, Azure, Google Cloud)" },
			{ id: 12, title: "DevOps tools and practices (CI/CD, automation, monitoring)" },
			{ id: 13, title: "Data management and analytics" },
			{ id: 14, title: "Machine learning and artificial intelligence" },
			{ id: 15, title: "Internet of Things (IoT) and connected devices" },
			{ id: 16, title: "Version control and collaboration (Git, GitHub)" },
			{ id: 17, title: "Continuous learning and professional development in the engineering field" },
		],
	},
];

export const FOUNDER_GOAL_OPTIONS = [
	{ id: 0, label: "Idea & Market Validation" },
	{ id: 1, label: "MVP Design & Development" },
	{ id: 2, label: "Product Market Fit" },
	{ id: 3, label: "Networking & Mentorship" },
	{ id: 4, label: "Pitch Deck and Storytelling" },
	{ id: 5, label: "Partnerships & Collaboration" },
	{ id: 6, label: "Scaling Product & Business" },
	{ id: 7, label: "Investment Opportunities" },
];

export const MENTOR_GOAL_OPTIONS = [
	{ id: 0, label: "Give-back to my community and network" },
	{ id: 1, label: "Stay engaged and challenged during a career transition" },
	{ id: 2, label: "Explore or “try-out” opportunities within new startups" },
	{ id: 3, label: "Advance my personal and professional development" },
	{ id: 4, label: "Expand my network" },
	{ id: 5, label: "Gain familiarity with emerging technologies" },
	{ id: 6, label: "Identify potential investment or service opportunities" },
];

export const INVESTOR_GOAL_OPTIONS = [
	{ id: 3, label: "Networking & Mentorship" },
	{ id: 5, label: "Partnerships & Collaboration" },
	{ id: 6, label: "Scaling Product & Business" },
	{ id: 7, label: "Investment Opportunities" },
];

export const US_STATE_CODES = [
	{
		name: "Alabama",
		abbreviation: "AL",
	},
	{
		name: "Alaska",
		abbreviation: "AK",
	},
	{
		name: "American Samoa",
		abbreviation: "AS",
	},
	{
		name: "Arizona",
		abbreviation: "AZ",
	},
	{
		name: "Arkansas",
		abbreviation: "AR",
	},
	{
		name: "California",
		abbreviation: "CA",
	},
	{
		name: "Colorado",
		abbreviation: "CO",
	},
	{
		name: "Connecticut",
		abbreviation: "CT",
	},
	{
		name: "Delaware",
		abbreviation: "DE",
	},
	{
		name: "District Of Columbia",
		abbreviation: "DC",
	},
	{
		name: "Federated States Of Micronesia",
		abbreviation: "FM",
	},
	{
		name: "Florida",
		abbreviation: "FL",
	},
	{
		name: "Georgia",
		abbreviation: "GA",
	},
	{
		name: "Guam",
		abbreviation: "GU",
	},
	{
		name: "Hawaii",
		abbreviation: "HI",
	},
	{
		name: "Idaho",
		abbreviation: "ID",
	},
	{
		name: "Illinois",
		abbreviation: "IL",
	},
	{
		name: "Indiana",
		abbreviation: "IN",
	},
	{
		name: "Iowa",
		abbreviation: "IA",
	},
	{
		name: "Kansas",
		abbreviation: "KS",
	},
	{
		name: "Kentucky",
		abbreviation: "KY",
	},
	{
		name: "Louisiana",
		abbreviation: "LA",
	},
	{
		name: "Maine",
		abbreviation: "ME",
	},
	{
		name: "Marshall Islands",
		abbreviation: "MH",
	},
	{
		name: "Maryland",
		abbreviation: "MD",
	},
	{
		name: "Massachusetts",
		abbreviation: "MA",
	},
	{
		name: "Michigan",
		abbreviation: "MI",
	},
	{
		name: "Minnesota",
		abbreviation: "MN",
	},
	{
		name: "Mississippi",
		abbreviation: "MS",
	},
	{
		name: "Missouri",
		abbreviation: "MO",
	},
	{
		name: "Montana",
		abbreviation: "MT",
	},
	{
		name: "Nebraska",
		abbreviation: "NE",
	},
	{
		name: "Nevada",
		abbreviation: "NV",
	},
	{
		name: "New Hampshire",
		abbreviation: "NH",
	},
	{
		name: "New Jersey",
		abbreviation: "NJ",
	},
	{
		name: "New Mexico",
		abbreviation: "NM",
	},
	{
		name: "New York",
		abbreviation: "NY",
	},
	{
		name: "North Carolina",
		abbreviation: "NC",
	},
	{
		name: "North Dakota",
		abbreviation: "ND",
	},
	{
		name: "Northern Mariana Islands",
		abbreviation: "MP",
	},
	{
		name: "Ohio",
		abbreviation: "OH",
	},
	{
		name: "Oklahoma",
		abbreviation: "OK",
	},
	{
		name: "Oregon",
		abbreviation: "OR",
	},
	{
		name: "Palau",
		abbreviation: "PW",
	},
	{
		name: "Pennsylvania",
		abbreviation: "PA",
	},
	{
		name: "Puerto Rico",
		abbreviation: "PR",
	},
	{
		name: "Rhode Island",
		abbreviation: "RI",
	},
	{
		name: "South Carolina",
		abbreviation: "SC",
	},
	{
		name: "South Dakota",
		abbreviation: "SD",
	},
	{
		name: "Tennessee",
		abbreviation: "TN",
	},
	{
		name: "Texas",
		abbreviation: "TX",
	},
	{
		name: "Utah",
		abbreviation: "UT",
	},
	{
		name: "Vermont",
		abbreviation: "VT",
	},
	{
		name: "Virgin Islands",
		abbreviation: "VI",
	},
	{
		name: "Virginia",
		abbreviation: "VA",
	},
	{
		name: "Washington",
		abbreviation: "WA",
	},
	{
		name: "West Virginia",
		abbreviation: "WV",
	},
	{
		name: "Wisconsin",
		abbreviation: "WI",
	},
	{
		name: "Wyoming",
		abbreviation: "WY",
	},
];

export const CANADA_PROVINCE_CODES = [
	{ name: "Alberta", abbreviation: "AB" },
	{ name: "British Columbia", abbreviation: "BC" },
	{ name: "Manitoba", abbreviation: "MB" },
	{ name: "New Brunswick", abbreviation: "NB" },
	{ name: "Newfoundland and Labrador", abbreviation: "NL" },
	{ name: "Northwest Territories", abbreviation: "NT" },
	{ name: "Nova Scotia", abbreviation: "NS" },
	{ name: "Nunavut", abbreviation: "NU" },
	{ name: "Ontario", abbreviation: "ON" },
	{ name: "Prince Edward Island", abbreviation: "PE" },
	{ name: "Quebec", abbreviation: "QC" },
	{ name: "Saskatchewan", abbreviation: "SK" },
	{ name: "Yukon Territory", abbreviation: "YT" },
];

export const INCORPORATED_LIST = [
	{ name: "Incorporated", value: "Yes" },
	{ name: "Not Incorporated", value: "No" },
	{ name: "In Progress", value: "In Progress" },
];

export const ATTENDED_OPTIONS = [
	"None",
	"Other",
	"StartupOS",
	"Y Combinator",
	"Techstars",
	"500 Startups",
	"Plug and Play",
	"Google for Startups Accelerator",
	"Alchemist Accelerator",
	"Dreamit Ventures",
	"Founders Factory",
	"MassChallenge",
	"Startupbootcamp",
	"AngelPad",
	"Amplify LA",
	"Entrepreneurs Roundtable Accelerator",
	"SOSV",
	"The Company Lab (CO.LAB)",
	"Newchip Accelerator",
	"Boomtown Accelerators",
	"Capital Factory",
	"Lair East Labs",
	"Seedcamp",
	"Microsoft Accelerator",
	"StartX",
	"Boost VC",
	"AlphaLab",
	"Mucker Capital",
	"MetaProp",
	"Berkeley SkyDeck",
];

export const FINANCING_LIST = ["None", "Priced Round", "Safe", "Conv. Note"];
export const CURRENT_STAGE_LIST = ["Pre-Seed", "Seed", "Series A", "Growth"];
export const SEEKING_LIST = ["Seed", "Series A"];
export const FUNDING_TYPES = ["Angel", "Corporate Venture Capital", "Family Office", "Philanthropic", "Venture Capital", "Personal Investor"];

export const FOUNDING_LIST = ["< $100K", "$100K-3M", "$3M-15M+"];

export const STATE_OF_PRODUCT = ["Idea (or low-fidelity mockup/prototype)", "Minimum Viable Product", "Launched (or sellable product)"];
