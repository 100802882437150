import { Box, Skeleton } from "@mui/material";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { ProfileContext } from "../..";
import EducationItem from "./EducationItem";

function EducationList() {
	const { profileData, profileDataLoading } = useContext(ProfileContext);
	const [loading, setloading] = useState(profileDataLoading);
	const [data, setdata] = useState(profileData);

	useEffect(() => {
		setloading(profileDataLoading);
	}, [profileDataLoading]);
	useEffect(() => {
		setdata(profileData);
	}, [profileData]);

	return (
		<Box
			sx={{
				display: "grid",
				gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
				gridAutoRows: "1fr",
				gridGap: "36px",
				alignItems: "stretch",
			}}
		>
			{loading ? (
				<Fragment>
					<Skeleton variant="rounded" animation="wave" sx={{ height: "200px", width: 1 }} />
					<Skeleton variant="rounded" animation="wave" sx={{ height: "200px", width: 1 }} />
					<Skeleton variant="rounded" animation="wave" sx={{ height: "200px", width: 1 }} />
					<Skeleton variant="rounded" animation="wave" sx={{ height: "200px", width: 1 }} />
				</Fragment>
			) : (
				data?.educationDetails?.map((item, index) => <EducationItem item={item} key={index} />)
			)}
		</Box>
	);
}

export default EducationList;
