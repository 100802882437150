import ROUTES_PATH from "./routes";

export const ONBOARDING_STEPS = [
  {
    key: "companyName",
    label: "My startup name is:",
    path: ROUTES_PATH.ONBOARDING_COMPANY_NAME,
    path: ROUTES_PATH.ONBOARDING_BASEPATH,
    stepNumber: 0,
    content: "Startup1",
  },
  {
    key: "companyLocation",
    label: "My location is:",
    path: ROUTES_PATH.ONBOARDING_LOCATION,
    stepNumber: 1,
    content: "Pune",
  },
  {
    key: "productService",
    label: "My product/service is:",
    path: ROUTES_PATH.ONBOARDING_PRODUCT_SERVICE,
    stepNumber: 2,
    content: "Electric vehicles",
  },
  {
    key: "journeyDetails",
    label: "So far, I:",
    path: ROUTES_PATH.ONBOARDING_STARTUP_JOURNEY,
    stepNumber: 3,
    content: "Electric vehicles",
  },
  {
    key: "experienceDetails",
    label: "My founder experience level is:",
    path: ROUTES_PATH.ONBOARDING_FOUNDER_EXPERIENCE_LEVEL,
    stepNumber: 4,
    content: "10 Years ",
  },
  {
    key: "founderSuperpower",
    label: "My founder superpower is",
    path: ROUTES_PATH.ONBOARDING_FOUNDER_SUPERPOWER,
    stepNumber: 5,
    content: "Management skills",
  },
  {
    key: "currentCommitment",
    label: "My time commitment:",
    path: ROUTES_PATH.ONBOARDING_TIME_COMMITMENT,
    stepNumber: 6,
    content: "Full-time",
  },
];

export const WORKSPACE_STEPS = [
  {
    key: "pitchPerfect",
    label: "Pitch Perfect",
    stepNumber: 0,
  },
  {
    key: "mvp",
    label: "MVP/MLP",
    stepNumber: 1,
  },
  {
    key: "investorMeeting",
    label: "Investor Meeting",
    stepNumber: 2,
  },
  {
    key: "productHuntDebut",
    label: "Product Hunt Debut",
    stepNumber: 3,
  },
  {
    key: "showDay",
    label: "Show Day",
    stepNumber: 4,
  },
];
