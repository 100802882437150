import { Box } from "@mui/material";
import services from "../../../../services";
import { getComponentImage } from "../../../PDF-Export/utilities";
import TamSamSomMUIGraph from "../../../../containers/PowerUP/components/graph/TamSamSomMUIGraph";
import { getModuleResponses } from "../../../../containers/PowerUP/functions";

export const getBio = async (cb) => {
	cb({ key: "profile", values: { loading: true, complete: false } });
	const company = await services.getCompanyProfile().then((resp) => {
		return resp?.data?.data;
	});
	const profile = await services.getUserProfile().then((resp) => {
		return resp?.data?.data;
	});

	const teamList = await services.getCompanyUser(company?.id).then((resp) => {
		let processed = {};
		const arr = resp?.data?.data;
		if(arr?.length < 1)
			return;
		// Loop through the array, but take a maximum of 6 items
		for (let i = 0; i < Math.min(arr.length, 6); i++) {
			if (arr[i].picture) { // Check if the picture value is not empty or undefined
				processed[`TEAM_PERSON_IMAGE_${i + 1}`] = arr[i].picture;
			}
			processed[`TEAM_PERSON_NAME_${i + 1}`] = arr[i].name;
		}
		return processed;
	});
	cb({ key: "profile", values: { loading: false, complete: true } });
	return { company: { ...company }, profile: { ...profile }, teamList: {...teamList} };
};

export const getValueProp = async (cb) => {
	cb({ key: "valueProp", values: { loading: true, complete: false, versionID: false } });
	const version = await services
		.getMyWorkDetails(1, null, "DESC", "lastModifiedAt", false, false)
		.then((result) => {
			console.log(result)
			return result?.data?.data?.filter((model) => model?.isCompleted === true)?.[0];
		})
		.catch((err) => console.log("Error loading versions. Failed with error:", err));

	if (!version?.id) {
		cb({ key: "valueProp", values: { loading: false, complete: true, versionID: null, message: "" } });
		return;
	}

	const data = await getModuleResponses(version?.module?.id, version?.id).then((resp) => {
		const answers = resp?.moduleStepResponses?.[0]?.answers;
		if (typeof answers === "string") {
			try {
				return JSON.parse(answers)?.find((input) => input.id === "md-value-prop-finalOutput")?.value;
			} catch (e) {
				console.log(e);
			}
		}
	});
	if(data){
		cb({ key: "valueProp", values: { loading: false, complete: true, versionID: version?.id, message: "" } });
		return data;
	}
	else{
		cb({ key: "valueProp", values: { loading: false, complete: true, versionID: null, message: `A completed version was found; however you must complete the latest AI version of the <a href="/workspace/powerup/1">Value Proposition PowerUP</a> to use your response in the generated pitch deck.` } });
	}
};

export const getFounderStory = async (cb) => {
	cb({ key: "story", values: { loading: true, complete: false, versionID: false } });
	const version = await services
		.getMyWorkDetails(2, null, "DESC", "lastModifiedAt", false, false)
		.then((result) => {
			return result?.data?.data?.filter((model) => model?.isCompleted === true)?.[0];
		})
		.catch((err) => console.log("Error loading versions. Failed with error:", err));

	if (!version?.id) {
		cb({ key: "story", values: { loading: false, complete: true, versionID: null, message: "" } });
		return;
	}

	const data = await getModuleResponses(version?.module?.id, version?.id).then((resp) => {
		const answers = resp?.moduleStepResponses?.[0]?.answers;
		if (typeof answers === "string") {
			try {
				return JSON.parse(answers)?.find((input) => input.id === "md-story-finalOutput")?.value;
			} catch (e) {
				console.log(e);
			}
		}
	});
	if(data){
		cb({ key: "story", values: { loading: false, complete: true, versionID: version?.id, message: "" } });
		return data;
	}
	else{
		cb({ key: "story", values: { loading: false, complete: true, versionID: null, message: `A completed version was found; however you must complete the latest AI version of the <a href="/workspace/powerup/2">Founder Story PowerUP</a> to use your response in the generated pitch deck.` } });
	}
};

export const getProbSol = async (cb) => {
	cb({ key: "probSol", values: { loading: true, complete: false, versionID: false } });
	const version = await services
		.getMyWorkDetails(5, null, "DESC", "lastModifiedAt", false, false)
		.then((result) => {
			return result?.data?.data?.filter((model) => model?.isCompleted === true)?.[0];
		})
		.catch((err) => console.log("Error loading versions. Failed with error:", err));

	if (!version?.id) {
		cb({ key: "probSol", values: { loading: false, complete: true, versionID: null, message: "" } });
		return;
	}

	const data = await getModuleResponses(version?.module?.id, version?.id).then((resp) => {
		const answers = resp?.moduleStepResponses?.[0]?.answers;
		if (typeof answers === "string") {
			try {
				return JSON.parse(answers)?.find((input) => input.id === "md-prob-sol-finalOutput")?.value;
			} catch (e) {
				console.log(e);
			}
		}
	});
	if(data){
		cb({ key: "probSol", values: { loading: false, complete: true, versionID: version?.id, message: "" } });
		return data;
	}
	else{
		cb({ key: "probSol", values: { loading: false, complete: true, versionID: null, message: `A completed version was found; however you must complete the latest AI version of the <a href="/workspace/powerup/5">Problem Soluion Fit PowerUP</a> to use your response in the generated pitch deck.` } });
	}
};

export const getTSS = async (cb) => {
	cb({ key: "tss", values: { loading: true, complete: false, versionID: false } });
	const version = await services
		.getMyWorkDetails(7, null, "DESC", "lastModifiedAt", false, false)
		.then((result) => {
			return result?.data?.data?.filter((model) => model?.isCompleted === true)?.[0];
		})
		.catch((err) => console.log("Error loading versions. Failed with error:", err));

	if (!version?.id) {
		cb({ key: "tss", values: { loading: false, complete: true, versionID: null, message: "" } });
		return;
	}

	const versiondata = await getModuleResponses(version?.module?.id, version?.id).then((resp) => {
		const answers = resp?.moduleStepResponses?.[0]?.answers;
		if (typeof answers === "string") {
			try {
				const returnData = {
					//image: JSON.parse(answers)?.find((input) => input.id === "md-tam-graphOutput")?.value,
					tam: "$" + Intl.NumberFormat("en-US", {
						notation: "compact",
						maximumFractionDigits: 1,
					}).format(Number(JSON.parse(answers)?.find((input) => input.id === "md-tam-graphOutput")?.value?.tam)),
					sam: "$" + Intl.NumberFormat("en-US", {
						notation: "compact",
						maximumFractionDigits: 1,
					}).format(Number(JSON.parse(answers)?.find((input) => input.id === "md-tam-graphOutput")?.value?.sam)),
					som: "$" + Intl.NumberFormat("en-US", {
						notation: "compact",
						maximumFractionDigits: 1,
					}).format(Number(JSON.parse(answers)?.find((input) => input.id === "md-tam-graphOutput")?.value?.som)),
					overview: JSON.parse(answers)?.find((input) => input.id === "md-tam-finalOutput")?.value,
				};
				return returnData;
			} catch (e) {
				console.log(e);
			}
		}
	});

	//if (!versiondata) return;

	//const imageData = await getTSSImage(versiondata?.image);
	//const returnData = {
	//	image: imageData,
	//	overview: versiondata?.overview,
	//};
	//return returnData;
	if(versiondata){
		cb({ key: "tss", values: { loading: false, complete: true, versionID: version?.id, message: "" } });
		return versiondata;
	}
	else{
		cb({ key: "tss", values: { loading: false, complete: true, versionID: null, message: `A completed version was found; however you must complete the latest AI version of the <a href="/workspace/powerup/7">TamSamSom PowerUP</a> to use your response in the generated pitch deck.` } });
	}
};

export const getPersonas = async (cb) => {
	cb({ key: "persona", values: { loading: true, complete: false, versionID: false } });
	const version = await services
		.getMyWorkDetails(3, null, "DESC", "lastModifiedAt", false, false)
		.then((result) => {
			return result?.data?.data?.filter((model) => model?.isCompleted === true)?.[0];
		})
		.catch((err) => console.log("Error loading versions. Failed with error:", err));

	if (!version?.id) {
		cb({ key: "persona", values: { loading: false, complete: true, versionID: null, message: "" } });
		return;
	}

	const data = await getModuleResponses(version?.module?.id, version?.id).then((resp) => {
		const answers = resp?.moduleStepResponses?.[0]?.answers;
		if (typeof answers === "string") {
			try {
				return JSON.parse(answers)?.find((input) => input.id === "md-persona-builder-finalOutput")?.value;
			} catch (e) {
				console.log(e);
			}
		}
	});
	if(data){
		cb({ key: "persona", values: { loading: false, complete: true, versionID: version?.id, message: "" } });
		return data;
	}
	else{
		cb({ key: "persona", values: { loading: false, complete: true, versionID: null, message: `A completed version was found; however you must complete the latest AI version of the <a href="/workspace/powerup/3">Persona Builder PowerUP</a> to use your response in the generated pitch deck.` } });
	}
};

export const getTSSImage = async (graphData) => {
	if (!graphData) return;

	const compImg = await getComponentImage({
		component: (
			<Box sx={{ width: "500px" }}>
				<TamSamSomMUIGraph TamSamSomData={graphData} />
			</Box>
		),
	});
	return compImg?.imageSrc;
};
