import { createTheme } from "@mui/material/styles";

export const themeShape = (themePalette) => ({
	shape: {
		standard: {
			borderRadius: "8px",
		},
		standard_small: {
			borderRadius: "4px",
		},
		standard1: {
			borderRadius: "6px",
		},
		standard2: {
			borderRadius: "10px",
		},
		standard3: {
			borderRadius: "12px",
		},
		standard4: {
			borderRadius: "14px",
		},
		standard5: {
			borderRadius: "16px",
		},
		standard6: {
			borderRadius: "18px",
		},
		standard10: {
			borderRadius: "24px",
		},
		standard13: {
			borderRadius: "30px",
		},
		standard15: {
			borderRadius: "34px",
		},
		standard22: {
			borderRadius: "50px",
		},
		standard23: {
			borderRadius: "55px",
		},
		standard25: {
			borderRadius: "100px",
		},
		standard26: {
			borderRadius: "20px",
		},

		avatar: {
			square: {
				borderRadius: "12px",
			},
			circle: {
				borderRadius: "28px",
			},
		},
	},
	components: {
		MuiCard: {
			styleOverrides: {
				root: {
					borderRadius: "8px",
				},
			},
		},
		MuiChip:{
			variants: [
				/// --- DS 1 Chips --- ///
				// *** BASE Chip PRIMARY COLOR *** //
				{
					props: { variant: "DS1" },
					style: {
						display: "flex",
						padding: "2px 8px",
						alignItems: "center",
						borderRadius: "16px",
						background: themePalette?.palette?.primary?.light,
						color: themePalette?.palette?.primary?.dark,
						textAlign: "center",
						fontFamily: "PoppinsSemiBold",
						fontSize: "14px",
						fontStyle: "normal",
						lineHeight: "20px"
					}
				},
			]
		}
	},
});
