import { AnnouncementOutlined } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import DialogComponent from "../../../../../common/CommonDialog";
import services from "../../../../../services";
import { toastContainer } from "../../../../../utils/toast";

export default function LeaveConversation({ close, open, conversationID }) {
  const [loading, setloading] = useState(false);
  const authData = useSelector(state => state?.auth);

  const leaveConversation = async () => {
    if (authData?.userId) {
      setloading(true);
      services
        .leaveConversation({
          userIDs: [authData.userId],
          convoID: conversationID,
        })
        .catch(e => {
          console.log("Could not leave conversation.", e);
          toastContainer(
            "Could not leave conversation. Please try again later.",
            "error"
          );
        })
        .finally(() => {
          setloading(false);
          close();
        });
    }
  };
  return (
    <DialogComponent
      isOpen={open}
      onClose={() => {
        close();
      }}
      title={"Leave Conversation"}
      titleVariant="title_large"
      PaperProps={{
        sx: theme => ({
          borderRadius: theme.shape.standard.borderRadius,
        }),
      }}
      maxWidth={"xs"}
      dialogAction={
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: 1,
            "& > button": {
              width: "auto",
              m: 0,
            },
          }}
        >
          <Button
            variant="outlined"
            onClick={() => {
              close();
            }}
            disabled={loading}
          >
            No, Stay.
          </Button>
          <LoadingButton
            variant="contained"
            color="error"
            loading={loading}
            onClick={() => {
              setloading(true);
              leaveConversation();
            }}
          >
            Yes, Leave.
          </LoadingButton>
        </Box>
      }
    >
      <Box sx={{ m: 2 }} textAlign="center">
        <AnnouncementOutlined
          sx={{ height: "56px", width: "56px" }}
          color="primary"
        />
        <Box sx={{ m: 2 }}>
          <Typography variant="body_large">
            Are you sure you want to leave the conversation? It will be removed
            from your conversation list.
          </Typography>
        </Box>
      </Box>
    </DialogComponent>
  );
}
