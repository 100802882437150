import React from "react";
import { Box, Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import { Text, Boolean, Integer, SingleSelect, MultiSelect } from "./";
import { getRules } from "../validation";

const FIELD_TYPES = [
	{ type: "STRING", component: Text },
	{ type: "INTEGER", component: Integer },
	{ type: "CURRENCY", component: Integer },
	{ type: "BOOLEAN", component: Boolean },
	{ type: "SINGLE_SELECT", component: SingleSelect },
	{ type: "MULTI_SELECT", component: MultiSelect },
];

export const Question = ({ question, control, handleAnswer, errors, isSpacing = true, isReadlyOnly = false }) => {
	const fields = question.fields.map((eachControl, key) => {
		const { required, field_validations, provided_value, editable = true, type } = eachControl;
		const index = FIELD_TYPES.findIndex(item => item.type === eachControl.type);
		const Component = FIELD_TYPES[index].component;

		const rules = getRules(required, field_validations);

		if (isReadlyOnly) {
			return <Typography variant="body_small">{`${provided_value}${key < question.fields.length - 1 ? `, ` : ""}`}</Typography>;
		}

		const getErrorMessage = messages => {
			const validatedMessage = messages.filter(item => item?.type.toLowerCase() === errors[eachControl.unique_name]?.type.toLowerCase());

			if (validatedMessage.length > 0) {
				return validatedMessage[0]?.message.replace(eachControl.unique_name, "This field ") || "Something is wrong";
			}

			return "Something is wrong.";
		};

		return (
			<Box mb={isSpacing ? 2 : 0}>
				<Controller
					name={eachControl.unique_name}
					control={control}
					rules={rules}
					defaultValue={provided_value ? provided_value : ""}
					render={({ field }) => (
						<Component
							key={eachControl.unique_name}
							value={field.value}
							question={eachControl}
							questionId={question.id}
							disabled={editable ? false : true}
							onChange={field.onChange}
							handleAnswer={handleAnswer}
							type={type}
							{...field}
						/>
					)}
				/>
				{errors[eachControl.unique_name] && (
					<Typography variant="body_small" style={{ color: "red" }}>
						{field_validations.length > 1 ? getErrorMessage(rules.message) : rules.message.replace(eachControl.unique_name, "This field ")}
					</Typography>
				)}
			</Box>
		);
	});

	return fields;
};
