import React, { useState } from 'react'

import axios from 'axios'
import services from '../../../services'

const APIHOST = process.env.REACT_APP_POWERUPS_URL

export default class PowerUps {
	static Context = React.createContext({})

	static values () {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const [ errors, setErrors ] = useState(null)
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const [ powerUps, setPowerUps ] = useState({ page: {}, sort: {}, data: [] })
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const [ powerUp, setPowerUp ] = useState(null)
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const [ powerUpOutlines, setPowerUpOutlines ] = useState({ id: null, data: [] })
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const [ powerUpSections, setPowerUpSections ] = useState({ id: null, data: [] })
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const [ powerUpOutputs, setPowerUpOutputs ] = useState({ id: null, data: [] })
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const [ powerUpInspirations, setPowerUpInspirations ] = useState({ id: null, data: [] })
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const [ powerUpSubmissions, setPowerUpSubmissions ] = useState({ page: {}, sort: {}, id: null, data: [] })
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const [ powerUpSubmission, setPowerUpSubmission ] = useState(null)
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const [ imageUrl, setImageUrl ] = useState(null)

		return {
			errors,
			powerUps,
			powerUp: {
				...powerUp,
				id: powerUpOutlines.id || powerUpSections.id || powerUpOutputs.id || powerUpInspirations.id,
				updated: {
					at: [
						powerUp?.updated?.at,
						powerUpOutlines?.updated?.at,
						powerUpSections?.updated?.at,
						powerUpOutputs?.updated?.at,
						powerUpInspirations?.updated?.at
					].sort((a, b) => (a && b ? (a > b ? -1 : a === b ? 0 : 1) : 1))[0],
					by: powerUp?.by
				},
				outlines: powerUpOutlines.data,
				sections: powerUpSections.data,
				outputs: powerUpOutputs.data,
				inspirations: powerUpInspirations.data,
				submissions: powerUpSubmissions.data,
				submissionsSort: powerUpSubmissions.sort,
				submission: powerUpSubmission
			},
			setPowerUpSubmission,
			imageUrl,
			async get (id) {
				setErrors(null)
				setPowerUp(null)
				setPowerUpOutlines({ id: null, data: [] })
				setPowerUpSections({ id: null, data: [] })
				setPowerUpOutputs({ id: null, data: [] })
				setPowerUpInspirations({ id: null, data: [] })
				setPowerUpSubmissions({ page: {}, sort: {}, id: null, data: [] })
				try {
					let response = await axios.get(`${APIHOST}/powerups/${id}`)
					setPowerUp(response.data)
					response = await axios.get(`${APIHOST}/powerups/${id}/outlines`)
					setPowerUpOutlines(response.data)
					response = await axios.get(`${APIHOST}/powerups/${id}/sections`)
					setPowerUpSections(response.data)
					response = await axios.get(`${APIHOST}/powerups/${id}/outputs`)
					setPowerUpOutputs(response.data)
					response = await axios.get(`${APIHOST}/powerups/${id}/inspirations`)
					setPowerUpInspirations(response.data)
					response = await axios.get(`${APIHOST}/powerups/${id}/submissions`)
					setPowerUpSubmissions(response.data)
				} catch (error) {
					setErrors(error.response.data)
				}
			},
			async list ({ field, order, states, title } = {}) {
				setErrors(null)
				try {
					const response = await axios.get(`${APIHOST}/powerups`, {
						params: {
							sort_field: field,
							sort_order: order,
							states: states?.length ? states.join(',') : states ? 'none' : undefined,
							title: title ? title : undefined
						}
					})
					setPowerUps(response.data)
				} catch (error) {
					setErrors(error?.response?.data ?? { error: 'No network connection!' })
				}
			},
			async create ({ title } = {}) {
				setErrors(null)
				try {
					const response = await axios.post(`${APIHOST}/powerups`, { title })
					setPowerUp(response.data)
					return response.data
				} catch (error) {
					setErrors(error?.response?.data ?? { error: 'No network connection!' })
				}
			},
			async update ({ id, state, title, image, description, summary, details, timeShort, timeLong, difficulty, recommendation, powertips, webhook } = {}) {
				console.log('update', { id, state, title, image, description, summary, details, timeShort, timeLong, difficulty, recommendation, powertips, webhook })
				setErrors(null)
				try {
					const response = await axios.put(
						`${APIHOST}/powerups/${id}`,
						Object.entries({
							id,
							state,
							title,
							image,
							description,
							summary,
							details,
							timeShort,
							timeLong,
							difficulty,
							recommendation,
							powertips,
							webhook
						}).reduce((o, [ k, v ]) => (v !== undefined ? ((o[k] = v), o) : o), {})
					)
					setPowerUp(response.data)
				} catch (error) {
					setErrors(error?.response?.data ?? { error: 'No network connection!' })
				}
			},
			async updateOutlines ({ id, outlines } = {}) {
				console.log('updateOutlines', id, outlines)
				setErrors(null)
				try {
					const response = await axios.put(`${APIHOST}/powerups/${id}/outlines`, { outlines })
					setPowerUpOutlines(response.data)
				} catch (error) {
					setErrors(error?.response?.data ?? { error: 'No network connection!' })
				}
			},
			async updateSections ({ id, sections } = {}) {
				console.log('updateSections', id, sections)
				setErrors(null)
				try {
					const response = await axios.put(`${APIHOST}/powerups/${id}/sections`, { sections })
					setPowerUpSections(response.data)
				} catch (error) {
					setErrors(error?.response?.data ?? { error: 'No network connection!' })
				}
			},
			async updateOutputs ({ id, outputs } = {}) {
				console.log('updateOutputs', id, outputs)
				setErrors(null)
				try {
					const response = await axios.put(`${APIHOST}/powerups/${id}/outputs`, { outputs })
					setPowerUpOutputs(response.data)
				} catch (error) {
					setErrors(error?.response?.data ?? { error: 'No network connection!' })
				}
			},
			async updateInspirations ({ id, inspirations } = {}) {
				console.log('updateInspirations', id, inspirations)
				setErrors(null)
				try {
					const response = await axios.put(`${APIHOST}/powerups/${id}/inspirations`, { inspirations })
					setPowerUpInspirations(response.data)
				} catch (error) {
					setErrors(error?.response?.data ?? { error: 'No network connection!' })
				}
			},
			// upload
			async uploadMedia ({ file, set } = {}) {
				const type = file?.type ?? ''
				const formData = new FormData()
				formData.append('file', file)
				formData.append('fileGroup', type.startsWith('video/') ? 'VIDEO' : 'LOGO')
				const response = await services.uploadFile(formData)
				const mediaUrl = response.data?.data?.fileLocation ?? ''
				if (set && type.startsWith('image/')) {
					setImageUrl(mediaUrl)
				}
				return mediaUrl
			},
			// submission
			async getSubmissions ({ id }) {
				setErrors(null)
				setPowerUpSubmissions({ page: {}, sort: {}, id: null, data: [] })
				try {
					let response = await axios.get(`${APIHOST}/powerups/${id}/submissions`)
					setPowerUpSubmissions(response.data)
				} catch (error) {
					setErrors(error?.response?.data ?? { error: 'No network connection!' })
				}
			},
			async getSubmission ({ id, sid }) {
				setErrors(null)
				setPowerUpSubmission(null)
				try {
					let response = await axios.get(`${APIHOST}/powerups/${id}/submissions/${sid}`)
					setPowerUpSubmission(response.data ? response.data : null)
				} catch (error) {
					setErrors(error?.response?.data ?? { error: 'No network connection!' })
				}
			},
			async createSubmission ({ id, contact, index, output, contents } = {}) {
				console.log('createSubmission', id, { contact, index, output, contents })
				setErrors(null)
				setPowerUpSubmission(null)
				try {
					const response = await axios.post(`${APIHOST}/powerups/${id}/submissions`, { contact, index, output, contents })
					setPowerUpSubmission(response.data)
					return response.data
				} catch (error) {
					setErrors(error?.response?.data ?? { error: 'No network connection!' })
				}
			},
			async updateSubmission ({ id, sid, state, contact, index, output, contents } = {}) {
				console.log('updateSubmission', id, sid, { state, contact, index, output, contents })
				setErrors(null)
				try {
					const response = await axios.put(`${APIHOST}/powerups/${id}/submissions/${sid}`, { state, contact, index, output, contents })
					setPowerUpSubmission(response.data)
				} catch (error) {
					setErrors(error?.response?.data ?? { error: 'No network connection!' })
				}
			}
		}
	}
}
