import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { Box, Grid, Stack, Typography, Button } from "@mui/material";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import UpdatedTextInputField from "../../../common/UpdatedTextInputField";
import CustomizedYearPicker from "../../MentorProfile/YearPicker";
import EducationDisplayCard from "../../../common/EducationDisplayCard";
import { setCommonDialogConfirmationData } from "../../../modules/actions";
import { PURPLE_ADD_ICON } from "../../../constants";
import messages from "../../../constants/messages";
import ProfileCoPilot from "../../ProfileCoPilot";
import LoadingButton from "../../../common/Loadingbutton";

const EducationSection = ({ context }) => {
	const {
		payLoad,
		setPayLoad,
		handleClose,
		addEducationApiCall,
		updateEducationApiCall,
		deleteEducationApiCall,
		isEducationLoading,
		setIsEducationLoading,
		commonDialogConfirmationData,
		handleCloseConfirmation,
		educationObject,
		setEducationObject,
		setEduObjEmpty,
		handleAISave,
	} = useContext(context);

	const fieldsList = [{ Degree: "course" }, { "School Name": "university" }, { Location: "location" }];
	const [isEdited, setIsEdited] = useState(false);
	const [educationId, setEducationId] = useState(0);
	const dispatch = useDispatch();
	const [startYearError, setStartYearError] = useState("");
	const [endYearError, setEndYearError] = useState("");

	const isFormEmpty = () => {
		if (educationObject.course === "" || educationObject.university === "" || educationObject.location === "") {
			return true;
		} else {
			return false;
		}
	};

	const isValid = () => {
		if (startYearError) {
			return false;
		}
		if (endYearError) {
			return false;
		}
		return true;
	};

	const clearAllErrors = () => {
		setStartYearError("");
		setEndYearError("");
	};
	const handleChange = (event) => {
		let key = event.target.id;
		let newValue = event.target.value;
		educationObject[key] = newValue;
		setEducationObject({ ...educationObject });
	};
	const CheckDateType = (inputYearOrDate) => {
		// Check if the input is a year or a specific date

		if (typeof inputYearOrDate === "number") {
			return inputYearOrDate;
		} else {
			return inputYearOrDate ? new Date(inputYearOrDate).getFullYear() : null;
		}
	};
	const handleYearChange = (value, type) => {
		if (type === "startYear") {
			if (educationObject?.endYear) {
				if (value.getFullYear() > CheckDateType(educationObject?.endYear)) {
					setEducationObject((prevState) => ({
						...prevState,
						startYear: value,
					}));
					setStartYearError(messages.START_DATE_ERROR);
				} else {
					setEducationObject((prevState) => ({
						...prevState,
						startYear: value,
					}));
					clearAllErrors();
				}
			} else {
				setEducationObject((prevState) => ({
					...prevState,
					startYear: value,
				}));
				clearAllErrors();
			}
		} else if (type === "endYear") {
			if (educationObject?.startYear) {
				if (value.getFullYear() < CheckDateType(educationObject?.startYear)) {
					setEducationObject((prevState) => ({
						...prevState,
						endYear: value,
					}));
					setEndYearError(messages.END_DATE_ERROR);
				} else {
					setEducationObject((prevState) => ({
						...prevState,
						endYear: value,
					}));
					clearAllErrors();
				}
			} else {
				setEducationObject((prevState) => ({
					...prevState,
					endYear: value,
				}));
				clearAllErrors();
			}
		}
	};

	const handleEdit = (item) => {
		setEducationObject({
			course: item.course,
			university: item.university,
			location: item.location,
			startYear: item.startYear,
			endYear: item.endYear,
		});
		setEducationId(item.id);
		setIsEdited(true);
	};

	const handleDelete = (id) => {
		dispatch(
			setCommonDialogConfirmationData({
				isOpen: true,
				title: "Delete Education",
				subTitle: "Are you sure you want to delete this education?",
				primaryButtonText: "Delete",
				secondaryButtonText: "Cancel",
				primaryButtonAction: () => deleteEducationApiCall(id),
				secondaryButtonAction: () => handleCloseConfirmation(),
				primaryButtonVariant: "RedRounded",
				secondaryButtonVariant: "noOutlineNew",
				primaryButtonLoading: false,
			})
		);
	};

	const handleSave = () => {
		setIsEdited(false);

		setIsEducationLoading(true);
		const educationPayload = {
			course: educationObject.course,
			university: educationObject.university,
			location: educationObject.location,
			startYear: educationObject?.startYear ? new Date(educationObject?.startYear).getFullYear() : "",
			endYear: educationObject?.endYear ? new Date(educationObject?.endYear).getFullYear() : "",
		};
		setPayLoad({ ...payLoad });
		if (isEdited) {
			updateEducationApiCall(educationPayload, educationId);
		} else {
			addEducationApiCall(educationPayload);
		}
	};

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				gap: 3,
			}}
		>
			<Grid container spacing={3}>
				<Grid item xs={12} sm={12} md={6} lg={6} xl={6} order={{ xs: 2, md: 1 }}>
					<Stack direction={"column"} spacing={2}>
						<Typography variant="poppinsSemiBold20" sx={{ mb: 3 }}>
							Education
						</Typography>
						{payLoad?.educationDetails?.length > 0 ? (
							payLoad?.educationDetails?.map((object) => {
								return (
									<EducationDisplayCard
										name={object?.course}
										university={object?.university}
										item={object}
										handleEdit={handleEdit}
										handleDelete={handleDelete}
									/>
								);
							})
						) : (
							<Typography variant="Text/sm/Regular">No Data</Typography>
						)}
					</Stack>
				</Grid>
				<Grid item xs={12} sm={12} md={6} lg={6} xl={6} order={{ xs: 1, md: 2 }}>
					<Stack direction={"column"} spacing={2}>
						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								mb: 3,
							}}
						>
							<Typography sx={{ mb: 3 }} variant="poppinsSemiBold20">
								{isEdited ? "Edit Educational Experience" : "Add New Educational Experience "}
							</Typography>
							{isEdited && (
								<Box
									sx={{
										display: "flex",
										alignItems: "center",
										ml: "auto",
									}}
									gap={1}
								>
									<Typography variant="poppinsSemiBold20">Add New</Typography>
									<Box
										onClick={() => {
											setEduObjEmpty();
											setIsEdited(false);
										}}
										sx={{ cursor: "pointer" }}
										component={"img"}
										src={PURPLE_ADD_ICON}
										alt="add"
									/>
								</Box>
							)}
						</Box>
						<Stack direction={"column"} spacing={3}>
							{fieldsList?.map((item) => {
								return (
									<UpdatedTextInputField
										label={Object.keys(item)[0]}
										id={Object.values(item)[0]}
										handleChange={handleChange}
										inputValue={educationObject[Object.values(item)[0]]}
										placeholder={Object.keys(item)[0]}
										textFieldProps={{
											fullwidth: true,
											width: "100%",
											height: "60px",
										}}
									/>
								);
							})}
							<Stack direction={"row"} spacing={3}>
								<Box width={"100%"} sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
									<Typography variant="count_title">Start Year</Typography>
									<CustomizedYearPicker
										value={`${educationObject.startYear}`}
										label=""
										onChange={(newValue) => {
											handleYearChange(newValue, "startYear");
										}}
										slotIcon={CalendarTodayOutlinedIcon}
										fieldStyleProps={{
											borderRadius: "30px",
											height: "50px",
											fontWeight: "500",
											fontSize: "16px",
											lineHeight: "24px",
											padding: "5px 6px",
											background: "white",
											boxShadow: "0px 0px 0px 4px #F2F4F7, 0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
											border: "none",
											"& .MuiOutlinedInput-notchedOutline": { borderColor: "#DDDDDD", border: "none" },
											"&.MuiTextField-root": {
												width: "100%",
												border: (theme) =>
													!!startYearError ? `5px solid ${theme.palette.secondary.errorTextFieldRed} !important` : "none",
											},
										}}
										error={startYearError}
									/>
									{startYearError && (
										<Box>
											<Typography variant="Text/sm/Regular" sx={{ color: "error.main" }}>
												{startYearError}
											</Typography>
										</Box>
									)}
								</Box>
								<Box width={"100%"} sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
									<Typography variant="count_title">End Year</Typography>

									<CustomizedYearPicker
										value={`${educationObject.endYear}`}
										label=""
										onChange={(newValue) => {
											handleYearChange(newValue, "endYear");
										}}
										slotIcon={CalendarTodayOutlinedIcon}
										fieldStyleProps={{
											borderRadius: "30px",
											height: "50px",
											fontWeight: "500",
											fontSize: "16px",
											lineHeight: "24px",
											padding: "5px 6px",
											background: "white",
											boxShadow: "0px 0px 0px 4px #F2F4F7, 0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
											border: "none",
											"& .MuiOutlinedInput-notchedOutline": { borderColor: "#DDDDDD", border: "none" },
											"&.MuiTextField-root": {
												width: "100%",
												border: (theme) =>
													!!endYearError ? `5px solid ${theme.palette.secondary.errorTextFieldRed} !important` : "none",
											},
										}}
										error={endYearError}
									/>
									{endYearError && (
										<Box>
											<Typography variant="Text/sm/Regular" sx={{ color: "error.main" }}>
												{endYearError}
											</Typography>
										</Box>
									)}
								</Box>
							</Stack>
							<Box
								sx={{
									display: "flex",
									gap: 2,
									justifyContent: "end",
									alignItems: "center",
								}}
							>
								<Button
									onClick={handleClose}
									variant="noOutlineNew"
									sx={{
										height: "40px",
										m: 0,
										width: "fit-content",
										fontSize: "18px",
										lineHeight: "28px",
										fontFamily: "PoppinsSemiBold",
									}}
								>
									Cancel
								</Button>

								{/* <ProfileCoPilot
									handleSave={handleSave}
									handleAISave={handleEduAISave}
									saveLoading={isEducationLoading}
									saveDisabled={isFormEmpty() || !isValid()}
									handleCloseAI={handleClose}
								/> */}
								<LoadingButton
									loaderColor={"#7B61FF"}
									loadingStyles={{
										border: "4px solid",
										backgroundColor: "white",
										borderColor: "#7B61FF",
										height: "60px",
										px: 4,
									}}
									disabled={isFormEmpty() || !isValid()}
									loading={isEducationLoading}
									onClick={!isEducationLoading ? handleSave : () => {}}
									variant="DS1"
								>
									Save
								</LoadingButton>
							</Box>
						</Stack>
					</Stack>
				</Grid>
			</Grid>
		</Box>
	);
};

export default EducationSection;
