import styled from "@emotion/styled";
import { Dialog, Box, Stack, Tab, Tabs, Switch } from "@mui/material";
import theme from "../../constants/Theme";
import { sosPrimary } from "../../constants/Theme/colors";

export const EditDialog = styled(Dialog)({
	width: "100%",
	height: "100%",

	"& .MuiDialog-paper": {
		borderRadius: "2%",
		"@media (max-width: 769px)": {
			borderRadius: theme.spacing(2),
		},
	},

	"& .MuiInputBase-root": {
		border: `1px solid ${theme.palette.secondary.textFieldBorder}`,
		boxSizing: "border-box",
		paddingLeft: "10px",
		backgroundColor: `${theme.palette.text.background}`,
		width: "100%",
		minHeight: "40px",
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
	},

	"& .MuiOutlinedInput-root": {
		border: "none",
		paddingLeft: "0px",
		height: "40px",
		borderRadius: "0px",
	},

	"& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
		border: `1px solid ${theme.palette.secondary.textFieldBorder}`,
	},
	"& .MuiInputBase-input": {
		borderRadius: "0px !important",
	},
	"& .MuiInputBase-input .MuiInputBase-multiline": {
		borderRadius: "0px !important",
	},
});

export const FormWrapper = styled(Box)({
	display: "flex",
	margin: theme.spacing(2),
	fontSize: "14px",
	color: `${theme.palette.text.formPrimary}`,
	backgroundColor: `${theme.palette.secondary.primary}`,
});

export const TabGroup = styled(Tabs)({
	"& .MuiTabs-indicator": {
		backgroundColor: "rgba(106, 117, 117, 0.12)",
	},
	"& .MuiTabs-flexContainer": {
		justifyContent: "center",
	},
});
export const TabContainer = styled((props) => <Tab disableRipple {...props} />)(({ theme, type }) => ({
	textTransform: "none",
	minWidth: 0,
	[theme.breakpoints.up("sm")]: {
		minWidth: 0,
	},
	[theme.breakpoints.down("md")]: {
		minWidth: "50%",
	},
	fontWeight: theme.typography.fontWeightRegular,
	width: "40%",
	color: sosPrimary[500],
	border: `1px solid ${theme.palette.secondary.dark}`,
	boxSizing: "border-box",
	borderRadius: type === "myProfile" ? "8px 0px 0px 8px" : "0px 8px 8px 0px",
	fontFamily: [
		"-apple-system",
		"BlinkMacSystemFont",
		'"Segoe UI"',
		"Roboto",
		'"Helvetica Neue"',
		"Arial",
		"sans-serif",
		'"Apple Color Emoji"',
		'"Segoe UI Emoji"',
		'"Segoe UI Symbol"',
	].join(","),
	"&:hover": {
		color: theme.palette.primary.main,
		opacity: 1,
	},
	"&.Mui-selected": {
		color: sosPrimary[500],
		background: "rgba(106, 117, 117, 0.12)",
		fontWeight: theme.typography.fontWeightMedium,
		border: `1pc solid ${theme.palette.primary.main}`,
	},
	"&.Mui-focusVisible": {
		backgroundColor: `${theme.palette.focus.main}`,
	},
}));

export const InvestorToggleSwitch = styled((props) => (
	<Switch
		focusVisibleClassName=".Mui-focusVisible"
		checked={props.checked}
		onChange={() => {
			props.setIsInvestorView(!props.checked, "investorView");
		}}
		disableRipple
		{...props}
	/>
))
(({ theme }) => ({
	width: 44,
	height: 24,
	padding: 0,
	'& .MuiSwitch-switchBase': {
	  padding: 0,
	  margin: 2,
	  transitionDuration: '300ms',
	  '&.Mui-checked': {
		transform: 'translateX(20px)',
		color: '#fff',
		'& + .MuiSwitch-track': {
		  backgroundColor: theme.palette.primary.main,
		  opacity: 1,
		  border: 0,
		},
		'&.Mui-disabled + .MuiSwitch-track': {
		  opacity: 0.5,
		},
	  },
	  '&.Mui-focusVisible .MuiSwitch-thumb': {
		color: '#33cf4d',
		border: '6px solid #fff',
	  },
	  '&.Mui-disabled .MuiSwitch-thumb': {
		color:
		  theme.palette.mode === 'light'
			? theme.palette.grey[100]
			: theme.palette.grey[600],
	  },
	  '&.Mui-disabled + .MuiSwitch-track': {
		opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
	  },
	},
	'& .MuiSwitch-thumb': {
	  boxSizing: 'border-box',
	  width: 20,
	  height: 20,
	  boxShadow:"unset"
	},
	'& .MuiSwitch-track': {
	  borderRadius: 26 / 2,
	  backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
	  opacity: 1,
	  transition: theme.transitions.create(['background-color'], {
		duration: 500,
	  }),
	},
}));

export const PitchDeckViewSwitch = styled((props) => (
	<Switch
		focusVisibleClassName=".Mui-focusVisible"
		checked={props.checked}
		onChange={() => {
			props.setispitchdeckview(!props.checked, "pitchDeckView");
		}}
		disableRipple
		{...props}
	/>
))(({ theme }) => ({
	width: 44,
	height: 24,
	padding: 0,
	marginRight: "8px",
	marginLeft: "10px",
	'& .MuiSwitch-switchBase': {
	  padding: 0,
	  margin: 2,
	  transitionDuration: '300ms',
	  '&.Mui-checked': {
		transform: 'translateX(20px)',
		color: '#fff',
		'& + .MuiSwitch-track': {
		  backgroundColor: theme.palette.primary.main,
		  opacity: 1,
		  border: 0,
		},
		'&.Mui-disabled + .MuiSwitch-track': {
		  opacity: 0.5,
		},
	  },
	  '&.Mui-focusVisible .MuiSwitch-thumb': {
		color: '#33cf4d',
		border: '6px solid #fff',
	  },
	  '&.Mui-disabled .MuiSwitch-thumb': {
		color:
		  theme.palette.mode === 'light'
			? theme.palette.grey[100]
			: theme.palette.grey[600],
	  },
	  '&.Mui-disabled + .MuiSwitch-track': {
		opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
	  },
	},
	'& .MuiSwitch-thumb': {
	  boxSizing: 'border-box',
	  width: 20,
	  height: 20,
	  boxShadow:"unset"
	},
	'& .MuiSwitch-track': {
	  borderRadius: 26 / 2,
	  backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
	  opacity: 1,
	  transition: theme.transitions.create(['background-color'], {
		duration: 500,
	  }),
	},
}));
