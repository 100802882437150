import React from "react";
import { Box, Typography, styled, Avatar } from "@mui/material";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";

export default function FitContentCard({ title, icon, isArrow, children }) {
	return (
		<Wrapper>
			{title && (
				<Box display="flex" alignItems="center" justifyContent="space-between" mb={3}>
					<Box display="flex" alignItems="center">
						{icon && <Box sx={{ fontSize: "50px" }}>{icon}</Box>}
						<Typography variant="card_name" sx={{ fontWeight: 600, marginLeft: "8px" }}>
							{title}
						</Typography>
					</Box>
					{isArrow && <KeyboardDoubleArrowRightIcon sx={{ color: "#DE0085", fontSize: "40px" }} />}
				</Box>
			)}
			{children}
		</Wrapper>
	);
}

export const Wrapper = styled(Box)({
	background: "#FFFFFF",
	borderRadius: "30px",
	padding: "42px 60px 40px",
	boxShadow: "0px 10px 25px rgba(0, 0, 0, 0.05)",
});
