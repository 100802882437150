import styled from "@emotion/styled";
import { Card, CardActions, Button, Box, CardContent, CardMedia, CardActionArea } from "@mui/material";

export const CardPlugInH = styled(Card)(({ theme }) => ({
  color: "darkslategray",
  backgroundColor: "rgba(255, 255, 255, 1)", // StartupOS/sys/light/on-primary
  borderRadius: 8,
  boxShadow: "0px 1px 2px rgb(0 0 0 / 30%), 0px 1px 3px 1px rgb(0 0 0 / 15%)",
  padding: 0,
  //   width: "96%",
  marginRight: 24,
  height: 276,
  // display: "flex",
  // flexDirection: "column",
  // justifyContent: "space-between",
  // alignItems: "flex-end"
}));

export const CardContentPlugInH = styled(CardContent)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-end"
}));

export const CardActionsPlugInH = styled(CardActions)(({ theme }) => ({
    height: 55
}));

export const BoxPlugInH = styled(Box) (({theme}) => ({
  height: 80,
  flex: "100%",
  display: "flex",
  alignItems: "center"
}))

export const BoxActionArea = styled(Box) (({theme}) => ({
  display: "flex",
  justifyContent: "flex-start"
}))

export const BoxMarginLeft = styled(Box) (({theme}) => ({
  marginLeft: 16,
  display: "flex",
  width: "100%"
}))

export const BoxMarginTop = styled(Box) (({theme}) => ({
  height: 80,
  marginTop: 16,
  display: "flex",
  alignItems: "center"
}))


export const ButtonPlugInH = styled(Button)(({ theme }) => ({
  width: 122,
  height: 40,
  borderRadius: 4,
  marginRight: 5
}));

export const CardMediaPluginH = styled(CardMedia)(({ theme }) => ({
  width: 80,
  height: 80,
  borderRadius: 12,
  // margin: 5
}));

export const CardActionAreaPlugInH = styled(CardActionArea)(({ theme }) => ({
  padding: "16px"
}));


