import { Box, Typography } from "@mui/material";
import React from "react";
import { LOCATION_NEW_ICON, CALENDAR_BLANK } from "../../../../constants";
import SvgComponent from "../../../../utils/SVGColor";

const formatDateRange = (dateRange) => {
	const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

	const startDate = new Date(dateRange.startYear, dateRange.startMonth - 1);
	const endDate = dateRange?.endMonth && dateRange?.endYear ? new Date(dateRange.endYear, dateRange.endMonth - 1) : null;

	const formattedStartDate = `${months[startDate.getMonth()]} ${startDate.getFullYear()}`;
	const formattedEndDate = endDate ? `${months[endDate.getMonth()]} ${endDate.getFullYear()}` : "";

	return `${dateRange.startYear && dateRange.startMonth ? formattedStartDate : "No Data"} - ${endDate ? formattedEndDate : "Current"}`;
};

function ExperienceItem({ item }) {
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				p: "28px 16px",
				backgroundColor: (theme) => theme.palette.sos.gray100,
				borderRadius: "20px",
			}}
		>
			<Typography
				variant="customValue"
				component={"div"}
				sx={{
					fontFamily: "PoppinsSemiBold",
					fontSize: "20px",
					lineHeight: "30px",
					color: "black",
					mb: 1,
				}}
			>
				{item?.title}
			</Typography>
			<Typography
				variant="customValue"
				component={"div"}
				sx={{
					fontFamily: "PoppinsSemiBold",
					fontSize: "16px",
					lineHeight: "24px",
					color: (theme) => theme.palette.sos.gray400,
					mb: "20px",
				}}
			>
				{item?.companyName}
			</Typography>
			{item?.location && (
				<Box
					sx={{
						display: "flex",
						gap: 1,
						mt: "auto",
						mb: 1,
					}}
				>
					<SvgComponent strokeColor={"#98A2B3"} svgUrl={LOCATION_NEW_ICON} style={{ height: "24px", width: "24px" }} />
					<Typography
						variant="customValue"
						component={"div"}
						sx={{
							fontFamily: "PoppinsSemiBold",
							fontSize: "16px",
							lineHeight: "24px",
							color: "black",
						}}
					>
						{item?.location}
					</Typography>
				</Box>
			)}
			<Box
				sx={{
					display: "flex",
					gap: 1,
					...(!item?.location && {
						mt: "auto",
					}),
				}}
			>
				<SvgComponent strokeColor={"#98A2B3"} svgUrl={CALENDAR_BLANK} style={{ height: "24px", width: "24px" }} />
				<Typography
					variant="customValue"
					component={"div"}
					sx={{
						fontFamily: "PoppinsSemiBold",
						fontSize: "16px",
						lineHeight: "24px",
						color: "black",
					}}
				>
					{formatDateRange({
						endMonth: item?.endMonth,
						endYear: item?.endYear,
						startMonth: item?.startMonth,
						startYear: item?.startYear,
					})}
				</Typography>
			</Box>
		</Box>
	);
}

export default ExperienceItem;
