import { ToggleButtonGroup, ToggleButton, alpha, Box, Typography, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";

const ToggleButtons = ({ selectOptions, onOptionChange, currentOption, label, description }) => {
	const [selectedOption, setSelectedOption] = useState("NULL");

	useEffect(() => {
        
		if (currentOption) {
			setSelectedOption(currentOption);
		} else {
			setSelectedOption("NULL");
		}
	}, [currentOption]);

	const handleChange = (event) => {
		setSelectedOption(event?.target?.value);
		onOptionChange(event?.target?.value === "NULL" ? "" : event?.target?.value);
	};

	return (
		<Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
			<Box>
				{label && (
					<Typography component={"p"} variant="Text/xs/Medium">
						{label}
					</Typography>
				)}
				{description && (
					<Typography component={"p"} variant="Text/xs/Regular" color={"#475467"}>
						{description}
					</Typography>
				)}
			</Box>
			<ToggleButtonGroup

				sx={{
					width: "100%", // Fill the available space
					"& .MuiToggleButtonGroup-grouped": {
						border: "1px solid var(--gray-300, #D0D5DD)",
						boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
						flex: 1, // Make buttons equal width
						borderRadius: 100,
					},
					"& .MuiToggleButton-root": {
						padding: "10px 16px",
						"&": (theme) => ({ ...theme.typography["Text/xs/Semibold"] }),
						"& .Mui-selected": {
							backgroundColor: "#F9FAFB",
						},
					},
				}}
				exclusive
				value={selectedOption}
				onChange={handleChange}
			>
                <ToggleButton value={"NULL"}>
						<svg width="21px" height="24px" version="1.1" viewBox="0 0 21 23" xmlns="http://www.w3.org/2000/svg">
							<path d="m15.742 14.387c1.6641 1.6016 3.3164 3.1875 4.9688 4.7734 0.16406 0.15625 0.30859 0.32031 0.27344 0.55859-0.03125 0.19531-0.15234 0.33984-0.35156 0.41016-0.22266 0.082032-0.41797 0.023438-0.58594-0.13672-0.59766-0.57422-1.1992-1.1445-1.7969-1.7227-1.4258-1.3711-2.8516-2.7422-4.2773-4.1133-0.0625-0.054688-0.10938-0.14062-0.22266-0.14062-0.0625 0.12891-0.03125 0.26953-0.03125 0.40234-0.003906 2.3984 0 4.7969-0.003906 7.1953 0 0.52344-0.1875 0.96094-0.69922 1.2148-0.51953 0.26172-1.0781 0.20703-1.4961-0.19141-1.3047-1.2422-2.5938-2.4922-3.8906-3.7422-0.29297-0.28516-0.34766-0.65234-0.34766-1.0312 0-1.9297-0.007812-3.8594 0.003906-5.7891 0-0.28906-0.089844-0.49219-0.30078-0.69531-2.1797-2.0781-4.3438-4.1641-6.5195-6.2461-0.35547-0.33984-0.54297-0.72266-0.42969-1.2109 0.13672-0.58594 0.61328-0.96875 1.2422-0.98438 0.30078-0.007812 0.60156 0 0.99609 0-0.17578-0.17578-0.28125-0.28906-0.39453-0.39453-0.53125-0.51562-1.0664-1.0234-1.6016-1.5352-0.32422-0.30859-0.36719-0.60937-0.11719-0.84375 0.24609-0.23047 0.57812-0.20312 0.88672 0.10938 1.4883 1.5039 3.043 2.9453 4.5664 4.4141 2.3125 2.2266 4.6328 4.4414 6.9492 6.6602 1.0547 1.0117 2.1094 2.0195 3.1797 3.0391m-4.7266-3.0664c-0.57812-0.55469-1.1523-1.1055-1.7305-1.6602-1.9102-1.8359-3.8164-3.668-5.7266-5.5039-0.10938-0.10547-0.22266-0.19531-0.39453-0.19141-0.58203 0.003906-1.1602 0-1.7422 0-0.11328 0-0.23047 0.007812-0.28516 0.125-0.0625 0.13281 0.023437 0.22656 0.10938 0.3125 0.25781 0.25 0.50781 0.50781 0.76953 0.75781 1.9219 1.8477 3.8398 3.6992 5.7773 5.5352 0.42969 0.40625 0.61328 0.85938 0.60547 1.4375-0.019532 1.8984-0.007813 3.8008-0.007813 5.6992 0 0.16406-0.015625 0.32812 0.12891 0.46484 1.2148 1.1602 2.4219 2.3242 3.6328 3.4883 0.085937 0.078125 0.17188 0.18359 0.31641 0.12891 0.16016-0.0625 0.13281-0.20703 0.13281-0.32812 0-2.8203 0-5.6406 0.003907-8.4609 0-0.19141-0.054688-0.33594-0.19531-0.46875-0.46094-0.42969-0.91016-0.87109-1.3945-1.3359z" />
							<path d="m20.445 3.1602c0.70313 0.58203 0.73828 1.3438 0.10547 1.9531-2.0039 1.9258-4.0039 3.8555-6.0039 5.7812-0.33203 0.32031-0.72266 0.33594-0.93359 0.03125-0.14062-0.19922-0.13672-0.39844-0.003906-0.60547 0.14453-0.21875 0.36328-0.37109 0.55078-0.54687 1.8281-1.7617 3.6562-3.5195 5.4883-5.2773 0.054687-0.054688 0.12109-0.10938 0.16797-0.17188 0.11719-0.16016 0.058594-0.3125-0.14453-0.34766-0.10938-0.019531-0.22266-0.011718-0.33594-0.011718h-12.684c-0.14453 0-0.28516 0.003906-0.42969-0.003906-0.30859-0.011719-0.52344-0.21094-0.54297-0.49219-0.015626-0.28125 0.19922-0.50391 0.51562-0.54688 0.29688-0.042969 0.58984-0.003906 0.88672 0 0.99609 0.011719 1.9961 0.015625 2.9922 0.015625 3.1406 0 6.2773 0 9.418-0.003906 0.32812 0 0.65234 0.027344 0.95312 0.22656z" />
						</svg>
					</ToggleButton>
				{/*<Tooltip title="Clear filter">
					
				</Tooltip>*/}
				{selectOptions?.map((option, index) => (
					<ToggleButton key={`${option}+${index}`} value={option}>
						{option}
					</ToggleButton>
				))}
			</ToggleButtonGroup>
		</Box>
	);
};

export default ToggleButtons;
