import { Box, Button, Chip, Typography, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import DialogComponent from "../../../common/CommonDialog";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LoadingButton from "../../../common/Loadingbutton";
import {
  convertFormattedStringToDate,
  getCharDay,
  getCurrentDate,
  getCurrentTime,
} from "../../../utils/date";
import { getEndTime } from "../Functions/common";
import ProposeNewTime from "../../../containers/ExternalCandidate/ProposeNewTime";
import ProposeNewTimeMobile from "../../ExternalCandidate/ProposeNewTime/ProposeNewTimeMobile";
import theme from "../../../constants/Theme";

export default function SelectInterviewTimePopup({
  open,
  onClose,
  data,
  selectedDateTime,
  setSelectedDatetime,
  onConfirm,
  confirmButtonLoading,
}) {
  const [openProposeNewTime, setOpenProposeNewTime] = useState(false);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleSelect = (date, time, timeZone) => {
    setSelectedDatetime({
      date,
      time,
      timeZone,
      duration: data.calendarEvent.duration,
      timezone: data.calendarEvent.timezone,
    });
  };

  const isSelected = (date, time) => {
    return (
      selectedDateTime &&
      selectedDateTime.date === date &&
      selectedDateTime.time === time
    );
  };

  const handleConfirm = () => {
    onConfirm(data?.id);
  };

  return (
    <>
      {" "}
      <DialogComponent
        isOpen={open}
        onClose={onClose}
        title={`Select a time for your interview with ${data.companyModel?.companyName}`}
        titleVariant={"title_large"}
        contentStyle={{ px: 0 }}
        maxWidth={"md"}
        fullWidth
        PaperProps={{
          style: { borderRadius: 28 },
        }}
        dialogAction={
          <Box display="flex" sx={{ mr: 2, py: 2 }}>
            <Box sx={{ mr: 1 }}>
              <Button
                variant="outlined"
                startIcon={<AccessTimeIcon />}
                onClick={() => {
                  setOpenProposeNewTime(true);
                  onClose();
                }}
              >
                Propose new time
              </Button>
            </Box>

            <Box item>
              <LoadingButton
                disabled={!selectedDateTime}
                loading={confirmButtonLoading}
                onClick={handleConfirm}
              >
                Confirm
              </LoadingButton>
            </Box>
          </Box>
        }
        disableBackDropClick
      >
        <Box sx={{ p: 2 }}>
          {data.calendarEvent?.timings?.map(item => {
            return (
              <Box sx={{ my: 2 }}>
                <Box sx={{ ml: 1 }}>
                  <Typography
                    variant="title_medium"
                    sx={{ color: "secondary.main" }}
                  >
                    {getCharDay(convertFormattedStringToDate(item.date))} ,
                    {getCurrentDate(convertFormattedStringToDate(item.date))}-{" "}
                    {data.calendarEvent?.timezone}
                  </Typography>
                </Box>
                <Box sx={{ my: 1 }}>
                  {item.times.map(time => {
                    return (
                      <Chip
                        label={`${getCurrentTime(time)} - ${getCurrentTime(
                          getEndTime(time, data.calendarEvent.duration)
                        )}`}
                        variant={
                          isSelected(item.date, time) ? "contained" : "outlined"
                        }
                        color={
                          isSelected(item.date, time) ? "primary" : "secondary"
                        }
                        sx={theme => ({
                          borderRadius: theme.shape.standard_small.borderRadius,
                          m: 1,
                        })}
                        onClick={() =>
                          handleSelect(
                            item.date,
                            time,
                            data.calendarEvent?.timezone
                          )
                        }
                      />
                    );
                  })}
                </Box>
              </Box>
            );
          })}
        </Box>
      </DialogComponent>
      {isSmallScreen ? (
        <ProposeNewTimeMobile
          openProposeNewTime={openProposeNewTime}
          setOpenProposeNewTime={setOpenProposeNewTime}
          responseObj={data}
          onClose={onClose}
        />
      ) : (
        <ProposeNewTime
          openProposeNewTime={openProposeNewTime}
          setOpenProposeNewTime={setOpenProposeNewTime}
          responseObj={data}
          onClose={onClose}
        />
      )}
    </>
  );
}
