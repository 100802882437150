import { ArrowForwardOutlined } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { UPDATED_INDUSTRY_ICON } from "../../../constants";

const MultiTagDisplayButton = ({ tagValue, tagType }) => {
	return (
		<Box
			sx={{
				display: "flex",
				alignItems: "center",
				cursor: "pointer",
				color: (theme) => theme.palette.secondary.black,
				transition: "transform .2s",
				"&:hover": {
					transform: "translateX(5px)",
				},
			}}
		>
			{/* <Box component={"img"} src={UPDATED_INDUSTRY_ICON} sx={{ height: "25px", width: "25px" }} alt="location" /> */}
			<Typography variant="Text/sm/Regular" color="secondary.black">
				{tagValue && tagValue?.length > 0 && tagType}
			</Typography>
			<Typography variant="Text/sm/Regular" color="secondary.black">
				[{tagValue && tagValue?.length > 0 ? tagValue?.length : 0}]
			</Typography>
			<ArrowForwardOutlined fontSize="small" />
		</Box>
	);
};

export default MultiTagDisplayButton;
