import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { toastContainer } from "../../../../utils/toast";
import PropTypes from "prop-types";
import services from "../../../../services";
import ConnectRequestPopup from "../Popups/ConnectPopup";
import SuccessPopup from "../Popups/ConnectSuccessPopup";
import ProfileCard from "../ProfileCard";
import MessageHandlerModal from "../../../../common/modal/MessageHandler";
import { handleMixPanel } from "../../../../utils/mixPanelEventHandling";
import { getRoleValue } from "../../../../utils/commonFunctions";

const CardController = ({
  connectionRequestCount, 
  incrementConnectionRequests,
  handleViewProfile,
  handleViewFounderProfile,
  cardObject,
  updateStartupsObject,
  handleBookmark,
  mentors,
  startups,
  saved,
  connectionID,
  setSavedStartUp,
}) => {
  const [openConnectRequest, setOpenConnectRequest] = useState(false);
  const [connectSuccessPopup, setConnectSuccessPopup] = useState(false);
  const [cancelConnectSuccessPopup, setCancelConnectSuccessPopup] =
    useState(false);
  const [acceptConnectSuccessPopup, setAcceptConnectSuccessPopup] =
    useState(false);
  const [requestType, setRequestType] = useState("CANCELED");
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [acceptConfirmPopup, setAcceptConfirmPopup] = useState(false);
  const [connectConfirmPopup, setConnectConfirmPopup] = useState(false);
  const [connectStatusForSaved, setConnectStatusForSaved] = useState();
  const [connectIdForSaved, setConnectIdForSaved] = useState();
  const usersType = useSelector(store => store?.auth.userType);
  const handleConnect = () => {
    if (!isDisable()) {
      setConnectConfirmPopup(true);
    }
  };
  const handleCancel = () => {
    setConfirmPopup(true);
  };
  const handleAccept = () => {
    setAcceptConfirmPopup(true);
  };
  const handleRequestCancelConfirm = () => {
    setCancelConnectSuccessPopup(true);
    setConfirmPopup(false);
    if (saved) {
      handleConnectionStatusUpdate({
        connectionId: connectionID,
        connectionStatus: "CANCELED",
      });
    } else {
      handleConnectionStatusUpdate({
        connectionId: cardObject?.connectionId,
        connectionStatus: "CANCELED",
      });
    }
  };
  const handleRequestAcceptConfirm = () => {
    setAcceptConnectSuccessPopup(true);
    setAcceptConfirmPopup(false);
    if (saved) {
      handleConnectionStatusUpdate({
        connectionId: connectionID,
        connectionStatus: "ACCEPTED",
      });
    } else {
      handleConnectionStatusUpdate({
        connectionId: cardObject?.connectionId,
        connectionStatus: "ACCEPTED",
      });
    }
  };
  const handleRequestConnectConfirm = () => {
    //setAcceptConnectSuccessPopup(true);
    let data;
    if (cardObject?.name) {
      let userId = cardObject.id;
      data = {
        userId,
      };
    } else {
      let companyId = cardObject.id;
      data = {
        companyId,
      };
    }
    handleSend(data);

    //handleConnectionStatusUpdate({
    //  connectionId: cardObject?.connectionId,
    //  connectionStatus: "CONECTED",
    //});
  };
  const handleConnectionStatusUpdate = data => {
    services
      .updateStartUpConnection(data)
      .then(result => {
        const response = result.data;
        if (data.connectionStatus === "ACCEPTED") {
          handleMixPanel("Network Connection Accepted", {
						"User ID": data.userId
					});
        } else if (data.connectionStatus === "REJECTED") {
          handleMixPanel("Network Connection Rejected", {
						"User ID": data.userId
					});
        }
        if (response?.code === 200) {
          incrementConnectionRequests(1, true);
          setOpenConnectRequest(false);
          let newData = {
            ...cardObject,
            connectionStatus: data.connectionStatus,
            connectionId: data.connectionId,
          };
          updateStartupsObject(newData);
          setConnectIdForSaved(data.connectionId);
          setConnectStatusForSaved(data.connectionStatus);
        }
      })
      .catch(error => {
        const message = error.response?.data?.message;
        toastContainer(message, "error");
      });
  };
  const handleSend = data => {
    services
      .handleStartUpConnection(data)
      .then(result => {
        handleMixPanel("Network Connection Requested", {
          "User ID": data.userId
        });
        const response = result.data;
        if (response?.code === 200) {
          incrementConnectionRequests(1);
          setOpenConnectRequest(false);
          setConnectConfirmPopup(false);

          setConnectSuccessPopup(true);
          let newData = {
            ...cardObject,
            connectionStatus: "PENDING",
            connectionId: response?.data?.id,
            isCreatedByStartup: response?.data?.isCreatedByStartup,
          };
          updateStartupsObject(newData);
          setConnectIdForSaved(response?.data?.id);
          setConnectStatusForSaved("PENDING");
        }
      })
      .catch(error => {
        const message = error.response?.data?.message;
        toastContainer(message, "error");
      });
  };

  const isDisable = () => {
    if (!cardObject.connectionStatus) {
      return false;
    } else if (cardObject.connectionStatus === "PENDING") {
      return true;
    } else if (cardObject.connectionStatus === "CANCELED") {
      return false;
    }
  };

  return (
    <Fragment>
      {startups && (
        <Fragment>
          <ConnectRequestPopup
            openConnectRequest={openConnectRequest}
            setOpenConnectRequest={setOpenConnectRequest}
            companyId={cardObject.id}
            onConnect={handleSend}
            startupName={cardObject.companyName}
          />
          <SuccessPopup
            connectSuccessPopup={connectSuccessPopup}
            setConnectSuccessPopup={setConnectSuccessPopup}
            startupName={cardObject.companyName}
            type={"CONNECT"}
          />
        </Fragment>
      )}
      {mentors && (
        <Fragment>
          <ConnectRequestPopup
            openConnectRequest={openConnectRequest}
            setOpenConnectRequest={setOpenConnectRequest}
            userId={cardObject.id}
            onConnect={handleSend}
            userName={cardObject.name}
          />
          <SuccessPopup
            connectSuccessPopup={connectSuccessPopup}
            setConnectSuccessPopup={setConnectSuccessPopup}
            userName={cardObject.name}
            type={"CONNECT"}
          />
        </Fragment>
      )}
      {startups && (
        <ProfileCard
          connectionRequestCount={connectionRequestCount}
          startupObj={cardObject}
          connectIdForSaved={connectIdForSaved}
          connectStatusForSaved={connectStatusForSaved}
          handleConnect={() => {
            handleConnect();
          }}
          handleCancel={() => {
            handleCancel();
          }}
          handleAccept={() => {
            handleAccept();
          }}
          handleBookmark={() => {
            handleBookmark(cardObject);
          }}
          handleViewProfile={() => {
            handleViewProfile(cardObject);
          }}
          handleViewFounderProfile={() => {
            handleViewFounderProfile(cardObject);
          }}
        />
      )}
      {mentors && (
        <ProfileCard
          connectionRequestCount={connectionRequestCount}
          mentorObj={cardObject}
          connectIdForSaved={connectIdForSaved}
          connectStatusForSaved={connectStatusForSaved}
          handleConnect={() => {
            handleConnect();
          }}
          handleCancel={() => {
            handleCancel();
          }}
          handleAccept={() => {
            handleAccept();
          }}
          handleBookmark={() => {
            handleBookmark(cardObject);
          }}
          handleViewProfile={() => {
            handleViewProfile(cardObject);
          }}
        />
      )}
      <SuccessPopup
        connectSuccessPopup={cancelConnectSuccessPopup}
        setConnectSuccessPopup={setCancelConnectSuccessPopup}
        userName={cardObject?.name}
        type={"CANCELED"}
      />
      <SuccessPopup
        connectSuccessPopup={acceptConnectSuccessPopup}
        setConnectSuccessPopup={setAcceptConnectSuccessPopup}
        userName={cardObject?.name}
        type={"ACCEPTED"}
      />

      <MessageHandlerModal
        isOpen={confirmPopup}
        onCancel={() => setConfirmPopup(false)}
        onOk={() => {
          handleRequestCancelConfirm();
        }}
        okText="Ok"
        cancelText={"Cancel"}
        heading="Confirm"
        text={"Are you sure you want to cancel connection request?"}
        messageType="primary"
      />
      <MessageHandlerModal
        isOpen={acceptConfirmPopup}
        onCancel={() => setAcceptConfirmPopup(false)}
        onOk={() => {
          handleRequestAcceptConfirm();
        }}
        okText="Ok"
        cancelText={"Cancel"}
        heading="Confirm"
        text={"Are you sure you want to Accept connection request?"}
        messageType="primary"
      />
      <MessageHandlerModal
        isOpen={connectConfirmPopup}
        onCancel={() => setConnectConfirmPopup(false)}
        onOk={() => {
          handleRequestConnectConfirm();
        }}
        okText="Ok"
        cancelText={"Cancel"}
        heading="Confirm"
        text={"Are you sure you want to send the connection request?"}
        messageType="primary"
      />
    </Fragment>
  );
};

{
  CardController.propTypes = {
    handleViewProfile: PropTypes.func,
    handleViewFounderProfile: PropTypes.func,
    cardObject: PropTypes.object,
    updateStartupsObject: PropTypes.object,
    handleBookmark: PropTypes.func,
  };

  CardController.defaultProps = {
    handleViewProfile: null,
    handleViewFounderProfile: null,
    cardObject: {},
    updateStartupsObject: {},
    handleBookmark: null,
  };
}

export default CardController;
