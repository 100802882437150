import React, { useState } from "react";
import { Box, Grid, Typography, Stack } from "@mui/material";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import OnboardingCard from "../../common/card/OnboardingCard";
import constants from "../../constants";
import theme from "../../constants/Theme";
import ROUTES_PATH from "../../constants/routes";
import { setAuthData } from "../../modules/actions/authActions";

function Join() {
  const [productServiceData, setProductServiceData] = useState(
    constants.USER_ROLE_DATA
  );
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSelect = (index, answer) => {
    if (answer === "Founder") {
      dispatch(setAuthData({ userType: "STARTUP_USER" }));
      history.push(ROUTES_PATH.ONBOARDING_BASEPATH);
    } else if (answer === "Mentor") {
      dispatch(setAuthData({ userType: "MENTOR_USER" }));
      history.push(ROUTES_PATH.REQUEST_FORM);
    } else {
      dispatch(setAuthData({ userType: "ADVISOR_USER" }));
      history.push(ROUTES_PATH.REQUEST_FORM);
    }
  };

  return (
    <Stack alignItems="center" justifyContent="center" sx={{ minHeight: 1 }}>
      <Grid container alignItems="center" justifyContent="center">
        <Grid item xs={12}>
          <Typography
            sx={{
              alignItems: "center",
              textAlign: "center",
            }}
            variant="h1"
            color="text.primary"
          >
            Thanks for your interest in StartupOS!
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            sx={{
              alignItems: "center",
              textAlign: "center",
            }}
            variant="h1"
            color="text.primary"
          >
            What are you looking to sign up as?
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{
          maxWidth: theme.breakpoints.values.md,
          py: 4,
        }}
      >
        <Box
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          gridAutoRows="1fr"
          width={1}
        >
          {productServiceData.map((item, index) => {
            return (
              <Box
                sx={{
                  gridColumn: {
                    lg: "span 4",
                    md: "span 4",
                    sm: "span 12",
                    xs: "span 12",
                  },
                  ml: 2,
                  mb: 6,
                }}
              >
                <OnboardingCard
                  cardText={item.text}
                  iconComponent={<item.iconComponent />}
                  selected={item.selected}
                  onSelect={handleSelect}
                  index={index}
                  cardSubText={item.cardSubText}
                  padding={2}
                />
              </Box>
            );
          })}
        </Box>
      </Grid>
    </Stack>
  );
}

export default Join;
