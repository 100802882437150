import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { Join, SignUp, Excited } from "./Components";
import { toastContainer } from "../../utils/toast";

export const NewOnboard = () => {
	const [isExcited, setIsExcited] = useState(false);
	const [signUpData, setSignUpData] = useState(false);
	const location = useLocation();

	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);

		const success = queryParams.get("success");

		const message = queryParams.get("message");

		if (message) {
			if (success === "true") {
				toastContainer(message, "success");
			} else {
				toastContainer(message, "error");
			}
		}
	}, []);

	const handleExcited = (data) => {
		setSignUpData(data);
		setIsExcited(true);
	};

	return (
		<>
			{isExcited ? (
				<Excited signUpData={signUpData} />
			) : (
				<>
					<Join />
					<SignUp handleExcited={handleExcited} />
				</>
			)}
		</>
	);
};
