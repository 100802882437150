import { Box, Tab, Tabs, Typography } from "@mui/material";
import React, { Fragment } from "react";

const QATabSection = ({ TabItems, currentTab, setCurrentTab }) => {
	const handleTabChange = (event, newValue) => {
		setCurrentTab(newValue);
	};
	return (
		<>
			{" "}
			<Box display={"flex"} justifyContent={"space-between"}>
				{" "}
				<Tabs
					variant="scrollable"
					scrollButtons="auto"
					allowScrollButtonsMobile
					value={currentTab}
					onChange={handleTabChange}
					sx={{
						alignItems: "stretch",
						"& .MuiTabs-flexContainer": {
							gap: 5,
						},
						"& .MuiTabs-indicator": {
							backgroundColor: (theme) => theme.palette.primary.main,
							borderRadius: "5px",
							height: "4px",
						},
						"& .MuiTabs-scrollButtons": {
							color: (theme) => theme.palette.primary.main,
							"& > svg": {
								height: "30px",
								width: "30px",
							},
						},
					}}
				>
					{TabItems.map((item, index) => {
						const tabItem = (
							<Tab
								selected={currentTab === item.id}
								onClick={(event) => {
									handleTabChange(event, index);
								}}
								disableRipple
								key={item.id}
								sx={{
									textTransform: "unset",
									textAlign: "left",
									p: 0,
									pb: 1,
									whiteSpace: "nowrap",
									maxWidth: "unset",
									opacity: ".6",
									filter: "grayscale(100%)",
									"&.Mui-selected": {
										opacity: 1,
										filter: "grayscale(0%)",
									},
								}}
								label={
									<Box display={"flex"} alignItems={"center"} gap={1}>
										<Typography
											variant="Text/xs/Semibold"
											sx={{
												color: (theme) => theme.palette.primary.main,
											}}
										>
											{item.title}
										</Typography>
									</Box>
								}
							/>
						);
						return <Fragment key={index}>{tabItem}</Fragment>;
					})}
				</Tabs>
			</Box>
		</>
	);
};

export default QATabSection;
