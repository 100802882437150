import services from "../../services";

export const getSurveyDetails = async (id) => {
	const details = services
		.getSurveyDetails(id)
		.then((details) => {
			return details;
		})
		.catch((error) => {
			console.log("Error loading PowerUP details. Failed with error:", error);
		});
	return details;
};
