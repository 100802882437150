import { Box, Skeleton } from "@mui/material";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { ProfileContext } from "../..";
import ExperienceItem from "./ExperienceItem";
import moment from "moment";

function ExperienceList() {
	const { profileData, profileDataLoading } = useContext(ProfileContext);
	const [loading, setloading] = useState(profileDataLoading);
	const [data, setdata] = useState(profileData);

	useEffect(() => {
		setloading(profileDataLoading);
	}, [profileDataLoading]);
	useEffect(() => {
		setdata(profileData);
	}, [profileData]);

	const getSortedExperienceDetails = (experienceDetails) => {
		if (experienceDetails && Array.isArray(experienceDetails)) {
			const tempExperienceDetails = [...experienceDetails];
			return tempExperienceDetails?.sort((current, next) => {
				const currentStartDate = moment(`${current?.startYear}-${current?.startMonth}`, "YYYY-M");
				const nextStartDate = moment(`${next?.startYear}-${next?.startMonth}`, "YYYY-M");
				return currentStartDate - nextStartDate;
			});
		}
		return experienceDetails;
	};

	return (
		<Box
			sx={{
				display: "grid",
				gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
				gridAutoRows: "1fr",
				gridGap: "36px",
				alignItems: "stretch",
			}}
		>
			{loading ? (
				<Fragment>
					<Skeleton variant="rounded" animation="wave" sx={{ height: "200px", width: 1 }} />
					<Skeleton variant="rounded" animation="wave" sx={{ height: "200px", width: 1 }} />
					<Skeleton variant="rounded" animation="wave" sx={{ height: "200px", width: 1 }} />
					<Skeleton variant="rounded" animation="wave" sx={{ height: "200px", width: 1 }} />
				</Fragment>
			) : (
				data?.experienceDetails?.map((item, index) => <ExperienceItem item={item} key={index} />)
			)}
		</Box>
	);
}

export default ExperienceList;
