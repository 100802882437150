import React, { useContext } from "react";
import { ScheduleMeetingContext } from ".";
import { Box } from "@mui/material";
import AvailabilityCalendar from "../../../containers/Meetings/ScheduleMeetingCalendar/Components/AvailabilityCalendar";

function AvailabilitySelection() {
	const { userPayload, selectedMeetingLength, setBuiltMeetingObj } = useContext(ScheduleMeetingContext);

	return (
		<Box sx={{ flex: 1 }}>
			<AvailabilityCalendar calUser={userPayload} eventData={selectedMeetingLength} setMeetingObject={(payload) => setBuiltMeetingObj(payload)} />
		</Box>
	);
}

export default AvailabilitySelection;
