import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Box, Button, Skeleton, Stack, Typography } from "@mui/material";
import PageTitle from "../../common/PageTitle";
import { HEART_HAND_BLACK } from "../../constants";
import PartnerCard from "./PartnerCard";
import services from "../../services";

const NewPartners = () => {
	const [loading, setLoading] = useState(false);
	const [partners, setPartners] = useState([]);

	const skeletonLoaderArray = [1, 2, 3, 4];

	useEffect(() => {
		GetEventCards();
	}, []);

	const GetEventCards = async () => {
		try {
			setLoading(true);
			const response = await services.getPartnerCardsApi();

			if (response) {
				setLoading(false);

				setPartners(response?.data?.data);
			}
		} catch (error) {
			setLoading(false);

			console.error("Error fetching partners:", error);
		}
	};

	const SkeletonCardLoader = () => {
		return (
			<Box
				width="400px"
				sx={{
					display: "flex",
					flexDirection: "column",
					rowGap: "28px",
					p: 4,
					borderRadius: "24px",
					backgroundColor: (theme) => theme.palette.secondary.white,
					boxShadow: "0px 10px 25px 0px rgba(0, 0, 0, 0.05)",
					"&:hover": {
						cursor: "pointer",
						".event-register-btn": { backgroundColor: (theme) => theme.palette.secondary.dark, color: (theme) => theme.palette.secondary.white },
					},
				}}
			>
				{" "}
				<Stack spacing={2}>
					<Skeleton variant="rectangular" height={50} width={"100%"} sx={{ borderRadius: "24px" }} />
					<Skeleton animation="wave" variant="rectangular" sx={{ borderRadius: "24px" }} height={200} />
					<Skeleton variant="rectangular" height={50} width={"100%"} sx={{ borderRadius: "24px" }} />
				</Stack>
			</Box>
		);
	};
	return (
		<>
			<Helmet>
				<meta charSet="utf-8" />
				<title>StartupOS - Events</title>
			</Helmet>
			<Box
				sx={{
					px: {
						lg: 8,
						md: 4,
						xs: 2,
					},
					pb: "30px",
					display: "flex",
					flexDirection: "column",
					gap: "32px",
					maxWidth: "1920px",
					margin: "0 auto",
				}}
			>
				<PageTitle icon={HEART_HAND_BLACK} label={"Partners"} />

				<Stack alignItems={"center"} spacing={2}>
					<Box display={"flex"} justifyContent={"center"} alignItems={"center"} columnGap={1}>
						<Box component="img" src={HEART_HAND_BLACK} sx={{ width: "32px", height: "32px" }} />
						<Typography variant="Text/md/Semibold">Say Hello to our Partners!</Typography>
					</Box>
					<Typography variant="Text/sm/Regular" textAlign="center">
						Founders, connect with the best vendors to grow your startup{" "}
					</Typography>
					<Button variant="DS1" onClick={() => window.open("https://startupos.getproven.com/vendors?limit=10", "_blank")}>
						View Other Partner Offers
					</Button>
				</Stack>
				<Box display="flex" flexWrap={"wrap"} justifyContent={"center"} alignItems={"stretch"} gap={2}>
					{loading ? (
						skeletonLoaderArray.map((item) => {
							return <SkeletonCardLoader />;
						})
					) : partners?.length > 0 ? (
						partners?.map((partner) => {
							return <PartnerCard partner={partner} />;
						})
					) : (
						<Typography color="primary" variant="Text/md/Semibold" sx={{ color: (theme) => theme.palette.secondary.dark }}>
							No Records Found
						</Typography>
					)}
				</Box>
			</Box>
		</>
	);
};

export default NewPartners;
