import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getMonthlyRevenueGridData } from "../Functions/common";
import MrrChartContainer from "../Charts/MrrChartContainer";
import MrrDataGrid from "../DataGrid/MrrDataGrid";
import { handleMixPanel } from "../../../../utils/mixPanelEventHandling";
import { setBIDashboardData } from "../../../../modules/actions/biDashboardActions";
import BIDataGridDialog from "../DataGrid/BIDataGridDialog";

function Mrr({ companyId, readOnly }) {
	const dispatch = useDispatch();
	const monthlyRecurringRevenue = useSelector((store) => store?.biDashboard?.monthlyRecurringRevenue);
	const loadingMonthlyRecurringRevenue = useSelector((store) => store?.biDashboard?.loadingMonthlyRecurringRevenue);
	const openMonthlyRecurringRevenue = useSelector((store) => store?.biDashboard?.openMonthlyRecurringRevenue);
	const [isEdited, setEdited] = useState(false);

	const gridData = monthlyRecurringRevenue?.data ? monthlyRecurringRevenue?.data : [];

	const ownCompanyId = useSelector((state) => state?.auth?.companyId);
	const usedCompanyId = companyId ? companyId : ownCompanyId;

	const fetchData = async () => {
		try {
			dispatch(setBIDashboardData({ loadingMonthlyRecurringRevenue: true }));
			const responseData = await getMonthlyRevenueGridData(usedCompanyId);
			if (responseData) {
				dispatch(setBIDashboardData({ monthlyRecurringRevenue: responseData }));
			}
			dispatch(setBIDashboardData({ loadingMonthlyRecurringRevenue: false }));
		} catch (error) {
			dispatch(setBIDashboardData({ loadingMonthlyRecurringRevenue: false }));
			console.log("error", error);
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	const onChartClick = () => {
		handleMixPanel("Grid MRR Viewed");
		dispatch(setBIDashboardData({ openMonthlyRecurringRevenue: true }));
	};

	const handleClose = useCallback(() => {
		if (isEdited) {
			dispatch(
				setBIDashboardData({
					openMonthlyRecurringRevenueLeave: true,
				})
			);
		} else {
			dispatch(setBIDashboardData({ openMonthlyRecurringRevenue: false }));
		}
	}, [isEdited]);

	return (
		<>
			<MrrChartContainer data={monthlyRecurringRevenue} onChartClick={onChartClick} loading={loadingMonthlyRecurringRevenue} readOnly={readOnly} />
			<BIDataGridDialog title="MRR" isOpen={openMonthlyRecurringRevenue} onClose={handleClose}>
				<MrrDataGrid gridData={gridData} readOnly={readOnly} usedCompanyId={usedCompanyId} isEdited={isEdited} setEdited={setEdited} />
			</BIDataGridDialog>
		</>
	);
}

export default Mrr;
