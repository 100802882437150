import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { Spinner } from "@twilio-paste/core";
import AppContainer from "./AppContainer";
import { actionCreators } from "../../../Messaging/store";
import { getToken } from "../../../Messaging/api";
import { Alert, Box } from "@mui/material";
function App() {
	const [loading, setLoading] = useState(true);
	const dispatch = useDispatch();
	const { login, clearAllConversations, clearAllMessages } = bindActionCreators(actionCreators, dispatch);
	const token = useSelector((state) => state.token);
	const userID = String(useSelector((state) => state?.auth?.userId));
	const [error, setError] = useState(false);
	useEffect(() => {
		clearAllConversations();
		clearAllMessages();
		if (token) {
			setLoading(false);
		} else if (userID && userID.length > 0) {
			getToken(userID)
				.then((token) => {
					if (token?.accessToken?.length > 0) login(token.accessToken);
					else throw "accessToken missing from getToken call.";
				})
				.catch((e) => {
					console.log("Something went wrong with messaging login.", e);
					setError(true);
				})
				.finally(() => {
					setLoading(false);
				});
		} else {
			setError(true);
		}
	}, []);

	if (error && !loading) {
		return <Alert severity="error">Unable to load messaging at this time.</Alert>;
	}
	if (loading) {
		return (
			<Box display="flex" justifyContent="center" alignItems="center" position="absolute" height="100%" width="100%">
				<Spinner size="sizeIcon110" decorative={false} title="Loading" />
			</Box>
		);
	}
	return <AppContainer />;
}
export default App;
