import React, { useEffect } from "react";
import { Stack, Box, Grid, Typography } from "@mui/material";
import { COMPLETED_DEFAULT } from "../../../constants/image";
import theme from "../../../constants/Theme";
import moment from "moment";

export default function DetailsContainer({ item, powerup, version }) {
  return (
    <>
      <Grid
        container
        sx={theme => ({
          width: 1,
          boxShadow: `0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)`,
          borderRadius: theme.shape.standard.borderRadius,
          px: 2,
          py: 3,
        })}
      >
        <Grid
          item
          xs={6}
          md={2}
          sx={{
            display: "flex",
            gap: 2,
            flexDirection: "column",
            width: "max-content",
          }}
        >
          <Typography variant="onsurface_grey_medium">Type</Typography>
          <Typography variant="onsurface_grey_medium">Name</Typography>
          <Typography variant="onsurface_grey_medium">Version</Typography>
          <Typography variant="onsurface_grey_medium">Modified Date</Typography>
          <Typography variant="onsurface_grey_medium">Modified By</Typography>
          <Typography variant="onsurface_grey_medium">Status</Typography>
        </Grid>

        <Grid
          item
          xs={6}
          md={2}
          sx={{
            display: "flex",
            gap: 2,
            flexDirection: "column",
          }}
        >
          <Typography variant="subhead5">PowerUp</Typography>
          <Typography
            variant="subhead5"
            sx={{ overflow: "hidden", wordWrap: "ellipses" }}
          >
            {powerup ? powerup : "-"}
          </Typography>
          <Typography
            variant="subhead5"
            sx={{ overflow: "hidden", wordWrap: "ellipses" }}
          >
            {version ? version : "-"}
          </Typography>
          <Typography variant="subhead5">
            {item?.lastModifiedAt
              ? moment(item?.lastModifiedAt).format("MMM D, YYYY")
              : "-"}
          </Typography>
          <Typography
            variant="subhead5"
            sx={{ overflow: "hidden", wordWrap: "ellipses" }}
          >
            {item?.modifiedBy ? item?.modifiedBy : "-"}
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 1,
              border: `1px solid ${theme.palette.secondary.dark}`,
              borderRadius: 2,
              width: "max-content",
              p: 1,
            }}
          >
            <Box
              src={COMPLETED_DEFAULT}
              component="img"
              sx={{
                display: "flex",
                maxHeight: "20px",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
              }}
            />
            <Typography variant="onsurface_grey">Complete</Typography>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
