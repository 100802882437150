import React from "react";
import { Typography, Box } from "@mui/material";
import FilterDropDownComponent from "../Common/FilterDropDown";
import CheckBoxComponent from "../Common/CheckBoxComp";
import { useSelector } from "react-redux";

const FilterIndustrySection = ({ filterData, lable, handleUncheck }) => {
	const industryList = useSelector((state) => state?.investorDashboard?.industryList);
	const revenueTypeList = useSelector((state) => state?.investorDashboard?.revenueTypeList);
	const businessTypeList = useSelector((state) => state?.investorDashboard?.businessTypeList);
	const productOfferingList = useSelector((state) => state?.investorDashboard?.productOfferingList);
	const { isAdmin } = useSelector((state) => state?.auth);

	return (
		<Box
			sx={{
				p: 5,
			}}
		>
			<Box
				sx={{
					display: "flex",
					width: 1,
					alignItems: "center",
				}}
			>
				<Typography sx={{ color: (theme) => theme.palette.secondary.black, width: "fit-content" }} variant="card_name">
					{lable}
				</Typography>
			</Box>

			<FilterDropDownComponent gridData={{ Industry: industryList }} handleUncheck={handleUncheck} lable={"Industry"} type={"industry"} />

			<FilterDropDownComponent
				gridData={{ "Product Offering": productOfferingList }}
				handleUncheck={handleUncheck}
				lable={"Product Offering"}
				type={"productOffering"}
			/>

			<CheckBoxComponent
				companyData={{ "Business Model Type": businessTypeList }}
				handleUncheck={handleUncheck}
				lable={"Business Model Type"}
				type={"businessModel"}
			/>
			<FilterDropDownComponent
				gridData={{ "Revenue Model Type": revenueTypeList }}
				handleUncheck={handleUncheck}
				lable={"Revenue Model Type"}
				type={"revenueModel"}
			/>

			<CheckBoxComponent
				companyData={{ "Incorporation Status": ["Yes", "No", "In Progress"] }}
				handleUncheck={handleUncheck}
				lable={"Incorporation Status"}
				type={"incorporatedStatus"}
			/>
			<CheckBoxComponent
				companyData={{ "Pitch Deck Available": ["Yes", "No"] }}
				handleUncheck={handleUncheck}
				lable={"Pitch Deck Available"}
				type={"pitchDeckAvailable"}
			/>
			{isAdmin && (
				<CheckBoxComponent
					companyData={{ "Premium Subscribers": ["Yes", "No"] }}
					handleUncheck={handleUncheck}
					lable={"Premium Subscribers"}
					type={"isPremiumPlanAccess"}
				/>
			)}
		</Box>
	);
};

export default FilterIndustrySection;
