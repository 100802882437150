import * as actionTypes from "../../actions/actionTypes";
import { updateObject } from "../../../utils/updateObject";

const initialState = {
	acv:{},
	loadingAcv: false,
	openAcv: false,
	openAcvLeave:false,
	capData: {},
	loadingCapData: false,
	openCapData: false,
	openCapDataLeave:false,
	capTableName: "OwnerShip & Fully Diluted Shares",
	annualRecurringRevenue: {},
	loadingAnnualRecurringRevenue: false,
	openAnnualRecurringRevenue: false,
	openAnnualRecurringRevenueLeave: false,
	burnAndRunway: {},
	loadingBurnAndRunway: false,
	openBurnAndRunway: false,
	openBurnAndRunwayLeave: false,
	customers: {},
	loadingCustomers: false,
	openCustomers: false,
	openCustomersLeave: false,
	monthlyRecurringRevenue: {},
	loadingMonthlyRecurringRevenue: false,
	openMonthlyRecurringRevenue: false,
	openMonthlyRecurringRevenueLeave: false,
	netNewArr: {},
	loadingNetNewArr:false,
	openNetNewArr: false,
	openNetNewArrLeave: false,
	cacPayback: {},
	loadingCacPayback:false,
	openCacPayback: false,
	openCacPaybackLeave: false,
	revenue:{},
	loadingRevenue:false,
	openRevenue: false,
	openRevenueLeave: false,
	smExpense: {},
	loadingSmExpense:false,
	openSmExpense: false,
	openSmExpenseLeave: false,
	openSettings: false,
	openConnect: false,
	linkPartnerkey: "",
	openEdit: false,
	editTableName: "",
	openPartnerIntegration: false,
	xeroState: '',
	quickbooksState: "",
	plaidCode: '',
	headCount: 0, //companySize
	loadingHeadCount: false,
	openPlaidAccountsSelect: false,
	plaidAccountsData: [],
	plaidAssetReportId: null,
};

const biDashboard = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SET_BI_DASHBOARD_DATA: {
			return action.payload === null ? initialState : updateObject(state, action.payload);
		}
		default:
			return state;
	}
};
export default biDashboard;
