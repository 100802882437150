import React, { useEffect, useState, useRef } from "react";
import { Stack, Grid, Box } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ProgressHeroCard from "./InnerContainer";
import WorkspaceStepper from "../../../components/Workspace/ProgressHeroStepper";
import { StepContainer, ArrowWrapper } from "./styles";
import theme from "../../../constants/Theme";
import { SteppedLineTo } from "react-lineto";
import { useSpringCarousel } from "react-spring-carousel";

export default function ProgressHero(props) {
  const stepsRef = useRef([]);
  const [isLastStep, setIsLastStep] = useState(false);

  useEffect(() => {
    stepsRef.current = stepsRef.current.slice(0, props.powerUps.length);
  }, [props.powerUps]);

  const [delay, setDelay] = useState(0);
  const [top, setTop] = useState(0);

  const nextStep = () => {
    if (props.activeStep < 10) {
      props.setActiveStep(props.activeStep + 1);
    }
  };
  const prevStep = () => {
    if (props.activeStep > 0) {
      props.setActiveStep(props.activeStep - 1);
    }
  };

  const handleKeyDown = () => {
    setDelay(delay === 0 ? 1 : 0);
  };

  useEffect(() => {
    if (props.activeStep === 0) {
      handleKeyDown();
    } else if (props.activeStep === props.powerUps.length - 1) {
      setIsLastStep(true);
    }
  }, [props.activeStep]);

  const {
    carouselFragment,
    useListenToCustomEvent,
    slideToPrevItem,
    slideToNextItem,
  } = useSpringCarousel({
    itemsPerSlide: 1.1,
    withLoop: false,
    initialActiveItem: props.activeStep,

    items: props?.powerUps.map((val, index) => ({
      id: val.id,
      renderItem: (
        <Box sx={{ pl: 5 }}>
          <StepContainer>
            <ProgressHeroCard
              data={val}
              handleStartPowerUp={props.handleStartPowerUp}
              points={props.points}
              setShowAdvisorPluginPopup={props.setShowAdvisorPluginPopup}
              showAdvisorPluginPopup={props.showAdvisorPluginPopup}
              moduleResponse={
                props?.powerUps[props.activeStep]?.moduleResponseModels
              }
              downloadDisable={props.downloadDisable}
              templateUrl={props.templateUrl}
            />
          </StepContainer>
        </Box>
      ),
    })),
  });

  useListenToCustomEvent(event => {
    // Triggered when the slide animation is completed
    if (event.eventName === "onSlideChange") {
      props.setActiveStep(event.currentItem.index);
      stepsRef.current[event.currentItem.index].scrollIntoView({
        behavior: "smooth",
      });
    }
  });

  const moveCaro = (active, change) => {
    if (active < change) {
      let forIndex = change - active;
      Array.from({ length: forIndex }, (x, i) => {
        slideToNextItem();
      });
    } else {
      let forIndex = active - change;

      Array.from({ length: forIndex }, (x, i) => {
        slideToPrevItem();
      });
    }
    props.setActiveStep(change);
  };

  return (
    <Stack
      sx={{
        width: 1,
      }}
    >
      {/* WEB */}
      <Box>
        <SteppedLineTo
          delay={delay}
          from={props.activeStep}
          to="CARD"
          orientation="v"
          fromAnchor="bottom center"
          toAnchor="top center"
          borderColor={theme.palette.secondary.white}
        />
      </Box>
      <Grid
        container
        flexDirection={"row"}
        sx={{
          display: { xs: "none", md: "flex" },
          gap: theme.spacing(16),
          pt: !props.loading ? 0 : 4,
        }}
      >
        {!props.loading && (
          <Grid
            item
            lg={12}
            md={12}
            xs={12}
            sx={{
              position: "relative",
              maxWidth: 1,
              pt: 4,
            }}
          >
            <WorkspaceStepper
              activeStep={props.activeStep}
              setActiveStep={props.setActiveStep}
              steps={props?.powerUps}
              handleKeyDown={handleKeyDown}
              ref={stepsRef}
            />
          </Grid>
        )}

        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          sx={{
            display: "flex",
            width: 1,
            justifyContent: { xs: "center", md: "space-between" },
            pl: { xs: 0, md: 4 },
            pb: { xs: 0, md: 8 },
          }}
        >
          <Grid container sx={{ pt: { xs: 0, md: 8 }, p: { xs: 4, md: 0 } }}>
            <Grid items xs={12} md={7}>
              <StepContainer className="CARD">
                <ProgressHeroCard
                  data={props?.powerUps[props.activeStep]}
                  handleStartPowerUp={props?.handleStartPowerUp}
                  points={props?.points}
                  setShowAdvisorPluginPopup={props.setShowAdvisorPluginPopup}
                  showAdvisorPluginPopup={props.showAdvisorPluginPopup}
                  moduleResponse={
                    props?.powerUps[props.activeStep]?.moduleResponseModels
                  }
                  downloadDisable={props.downloadDisable}
                  templateUrl={props.templateUrl}
                />
              </StepContainer>
            </Grid>

            <Grid
              items
              md={5}
              sx={{
                display: { xs: "none", md: "flex" },
                placeSelf: "flex-end",
                justifyContent: "flex-end",
              }}
            >
              <Box sx={{ display: "flex", pr: 4, gap: theme.spacing(2) }}>
                <ArrowWrapper onClick={prevStep}>
                  <ArrowBackIcon />
                </ArrowWrapper>
                <ArrowWrapper onClick={nextStep}>
                  <ArrowForwardIcon onClick={nextStep} />
                </ArrowWrapper>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* MOB */}
      {!props.loading && (
        <Grid
          container
          flexDirection={"row"}
          sx={{
            display: { xs: "flex", md: "none" },
            width: 1,
            gap: theme.spacing(16),
            pt: !props.loading ? 0 : 4,
            background: `linear-gradient(0deg, rgba(31, 31, 31, 0.9), rgba(31, 31, 31, 0.9)),
            url(${
              props?.powerUps[props.activeStep]?.taskImgUrl
                ? props.powerUps[props.activeStep].taskImgUrl
                : ""
            })`,
          }}
        >
          <Grid
            item
            lg={12}
            md={12}
            xs={12}
            sx={{
              display: "block",
              width: "100vw",
              height: "max-content",
              position: "relative",
              pt: 4,
            }}
          >
            <WorkspaceStepper
              activeStep={props.activeStep}
              setActiveStep={props.setActiveStep}
              steps={props?.powerUps}
              ref={stepsRef}
              moveCaro={moveCaro}
            />
          </Grid>

          <Grid item lg={12} md={12} xs={12}>
            <Box
              sx={{
                width: "100vw",
                overflow: "hidden",
              }}
            >
              {carouselFragment}
            </Box>
          </Grid>
        </Grid>
      )}
    </Stack>
  );
}
