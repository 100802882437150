import services from "../../services";
import * as actionTypes from "./actionTypes";

export const setPowerUpExcercises = (payload) => {
	return (dispatch) => {
		dispatch({
			type: actionTypes.SET_POWERUP_ExcerciseS,
			payload,
		});
	};
};

export const setInterviewScriptDialog = (payload) => {
	return (dispatch) => {
		dispatch({
			type: actionTypes.SET_INTERVIEW_SCRIPT_DIALOG,
			payload,
		});
	};
};

export const setMarketTest = (payload) => {
	return (dispatch) => {
		dispatch({
			type: actionTypes.SET_MARKET_TEST_ID,
			payload,
		});
	};
};

export const setPowerupDetails = (payload) => {
	return (dispatch) => {
		dispatch({
			type: actionTypes.SET_POWERUP_DETAILS,
			payload,
		});
	};
};

export const setInterviewCandidates = (payload) => {
	return (dispatch) => {
		dispatch({
			type: actionTypes.SET_INTERVIEW_CANDIDATES,
			payload,
		});
	};
};

export const setCandidateDetails = (payload) => {
	return (dispatch) => {
		dispatch({
			type: actionTypes.SET_CANDIDATE_DETAILS,
			payload,
		});
	};
};

export const setExternalCandidateDetails = (payload) => {
	return (dispatch) => {
		dispatch({
			type: actionTypes.SET_EXTERNAL_CANDIDATE_DETAILS,
			payload,
		});
	};
};

export const setTamSamSomData = (payload) => {
	return (dispatch) => {
		dispatch({
			type: actionTypes.SET_TAMSAMSOM_DATA,
			payload,
		});
	};
};

export const setPowerupHeaderDetails = (payload) => {
	return (dispatch) => {
		dispatch({
			type: actionTypes.SET_POWERUP_HEADER_DETAILS,
			payload,
		});
	};
};

export const setPowerupLayoutDetails = (payload) => {
	return (dispatch) => {
		dispatch({
			type: actionTypes.SET_POWERUP_LAYOUT_DETAILS,
			payload,
		});
	};
};

export const setWorkHistoryTaskDetails = (payload) => {
	return (dispatch) => {
		dispatch({
			type: actionTypes.SET_WORK_HISTORY_TASK_DETAILS,
			payload,
		});
	};
};
