import { DEFAULT_CARD_IMAGE } from "./image";

export const TIME_ARRAY = [
  { label: "8:00 AM", value: "08:00" },
  { label: "8:30 AM", value: "08:30" },
  { label: "9:00 AM", value: "09:00" },
  { label: "9:30 AM", value: "09:30" },
  { label: "10:00 AM", value: "10:00" },
  { label: "10:30 AM", value: "10:30" },
  { label: "11:00 AM", value: "11:00" },
  { label: "11:30 AM", value: "11:30" },
  { label: "12:00 PM", value: "12:00" },
  { label: "12:30 PM", value: "12:30" },
  { label: "01:00 PM", value: "13:00" },
  { label: "1:30 PM", value: "13:30" },
  { label: "2:00 PM", value: "14:00" },
  { label: "2:30 PM", value: "14:30" },
  { label: "3:00 PM", value: "15:00" },
  { label: "3:30 PM", value: "15:30" },
  { label: "4:00 PM", value: "16:00" },
  { label: "4:30 PM", value: "16:30" },
  { label: "5:00 PM", value: "17:00" },
  { label: "5:30 PM", value: "17:30" },
  { label: "6:00 PM", value: "18:00" },
  { label: "6:30 PM", value: "18:30" },
  { label: "7:00 PM", value: "19:00" },
  { label: "7:30 PM", value: "19:30" },
  { label: "8:00 PM", value: "20:00" },
];

export const RICH_TEXT_EDITOR_MAIN_CONTROLS = [
  "bold",
  "italic",
  "underline",
  "undo",
  "redo",
  "link",
  "numberList",
  "bulletList",
  "my-block",
];

export const RICH_TEXT_EDITOR_INLINE_CONTROLS = ["bold", "italic", "underline"];

export const RESEARCH_CURATOR_LIST = [
  {
    id: 1,
    title: "Untitled Note 1",
    description: "Description of the note",
    noteType: "RESEARCH",
    createdAt: "2022-06-22T13:39:15.792+00:00",
    updatedAt: "2022-06-22T13:39:15.792+00:00",
    isPinned: false,
    userModel: {
      id: 93,
      email: "rohit.kavthekar@mindbowser.com",
      name: "Rohit Kavthekar",
      userType: "STARTUP_USER",
    },
    company: {
      id: 31,
      companyName: "RK",
    },
    references: [
      {
        id: 1,
        entityId: 4,
        entityType: "POWER_UP",
        model: {
          id: 4,
          name: "Persona Builder",
        },
      },
    ],
    tags: [
      {
        id: 1,
        tag: {
          id: 3,
          name: "Tag 1",
        },
      },
    ],
  },
  {
    id: 1,
    title: "Untitled Note 1",
    description: "Description of the note",
    noteType: "RESEARCH",
    createdAt: "2022-06-22T13:39:15.792+00:00",
    updatedAt: "2022-06-22T13:39:15.792+00:00",
    isPinned: false,
    thumbnail:
      "https://startupos-dev-assets.s3.amazonaws.com/uploads/logos/1660921312536profile.jpeg",
    userModel: {
      id: 93,
      email: "rohit.kavthekar@mindbowser.com",
      name: "Rohit Kavthekar",
      userType: "STARTUP_USER",
    },
    company: {
      id: 31,
      companyName: "RK",
    },
    references: [
      {
        id: 1,
        entityId: 4,
        entityType: "POWER_UP",
        model: {
          id: 4,
          name: "Persona Builder",
        },
      },
    ],
    tags: [
      {
        id: 1,
        tag: {
          id: 3,
          name: "Tag 1",
        },
      },
    ],
  },
  {
    id: 1,
    title: "Untitled Note 1",
    description: "Description of the note",
    noteType: "RESEARCH",
    createdAt: "2022-06-22T13:39:15.792+00:00",
    updatedAt: "2022-06-22T13:39:15.792+00:00",
    isPinned: false,
    userModel: {
      id: 93,
      email: "rohit.kavthekar@mindbowser.com",
      name: "Rohit Kavthekar",
      userType: "STARTUP_USER",
    },
    company: {
      id: 31,
      companyName: "RK",
    },
    references: [
      {
        id: 1,
        entityId: 4,
        entityType: "POWER_UP",
        model: {
          id: 4,
          name: "Persona Builder",
        },
      },
    ],
    tags: [
      {
        id: 1,
        tag: {
          id: 3,
          name: "Tag 1",
        },
      },
    ],
  },
  {
    id: 1,
    title: "Untitled Note 1",
    description: "Description of the note",
    noteType: "RESEARCH",
    createdAt: "2022-06-22T13:39:15.792+00:00",
    updatedAt: "2022-06-22T13:39:15.792+00:00",
    isPinned: false,
    thumbnail:
      "https://startupos-dev-assets.s3.amazonaws.com/uploads/logos/1650533926544image1.png",
    userModel: {
      id: 93,
      email: "rohit.kavthekar@mindbowser.com",
      name: "Rohit Kavthekar",
      userType: "STARTUP_USER",
    },
    company: {
      id: 31,
      companyName: "RK",
    },
    references: [
      {
        id: 1,
        entityId: 4,
        entityType: "POWER_UP",
        model: {
          id: 4,
          name: "Persona Builder",
        },
      },
    ],
    tags: [
      {
        id: 1,
        tag: {
          id: 3,
          name: "Tag 1",
        },
      },
    ],
  },
  {
    id: 1,
    title: "Untitled Note 1",
    description: "Description of the note",
    noteType: "RESEARCH",
    createdAt: "2022-06-22T13:39:15.792+00:00",
    updatedAt: "2022-06-22T13:39:15.792+00:00",
    isPinned: false,
    userModel: {
      id: 93,
      email: "rohit.kavthekar@mindbowser.com",
      name: "Rohit Kavthekar",
      userType: "STARTUP_USER",
    },
    company: {
      id: 31,
      companyName: "RK",
    },
    references: [
      {
        id: 1,
        entityId: 4,
        entityType: "POWER_UP",
        model: {
          id: 4,
          name: "Persona Builder",
        },
      },
    ],
    tags: [
      {
        id: 1,
        tag: {
          id: 3,
          name: "Tag 1",
        },
      },
    ],
  },
  {
    id: 1,
    title: "Untitled Note 1",
    description: "Description of the note",
    noteType: "RESEARCH",
    createdAt: "2022-06-22T13:39:15.792+00:00",
    updatedAt: "2022-06-22T13:39:15.792+00:00",
    isPinned: false,
    thumbnail:
      "https://startupos-dev-assets.s3.amazonaws.com/uploads/logos/1650533926544image1.png",
    userModel: {
      id: 93,
      email: "rohit.kavthekar@mindbowser.com",
      name: "Rohit Kavthekar",
      userType: "STARTUP_USER",
    },
    company: {
      id: 31,
      companyName: "RK",
    },
    references: [
      {
        id: 1,
        entityId: 4,
        entityType: "POWER_UP",
        model: {
          id: 4,
          name: "Persona Builder",
        },
      },
    ],
    tags: [
      {
        id: 1,
        tag: {
          id: 3,
          name: "Tag 1",
        },
      },
    ],
  },
  {
    id: 1,
    title: "Untitled Note 1",
    description: "Description of the note",
    noteType: "RESEARCH",
    createdAt: "2022-06-22T13:39:15.792+00:00",
    updatedAt: "2022-06-22T13:39:15.792+00:00",
    isPinned: false,

    userModel: {
      id: 93,
      email: "rohit.kavthekar@mindbowser.com",
      name: "Rohit Kavthekar",
      userType: "STARTUP_USER",
    },
    company: {
      id: 31,
      companyName: "RK",
    },
    references: [
      {
        id: 1,
        entityId: 4,
        entityType: "POWER_UP",
        model: {
          id: 4,
          name: "Persona Builder",
        },
      },
    ],
    tags: [
      {
        id: 1,
        tag: {
          id: 3,
          name: "Tag 1",
        },
      },
    ],
  },
];
