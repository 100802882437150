import React, { useState } from "react";
import { Box, Divider, Typography, Grid } from "@mui/material";
import DialogComponent from "../../Workspace/PowerUP/IdeaValidation/Components/Dialog";
import { AVATAR_DEFAULT, SUCCESS_ICON } from "../../../constants/image";
import theme from "../../../constants/Theme";
import DateTimeSlotPicker from "../../../common/DateTimeslotPicker";
import SelectedDatesCard from "../../Workspace/Plugins/AdvisorPlugin/Components/SelectedDatePopover";
import LoadingButton from "../../../common/Loadingbutton";
import services from "../../../services";
import { sanitizeDateTimePayload } from "../../Workspace/Plugins/AdvisorPlugin/Functions/common";
import SuccessPopup from "../../Workspace/Plugins/AdvisorPlugin/Components/SuccessPopup";
import { getLocalTimeZone } from "../../../utils/date";

const ProposeNewTime = ({
  openProposeNewTime,
  setOpenProposeNewTime,
  responseObj,
  onClose,
}) => {
  const [responses, setResponses] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [openSuccessPopup, setOpenSuccessPopup] = useState(false);

  const handleconfirmDisable = () => {
    return !responses.length;
  };

  const sendInviteToCandidate = () => {
    const candidateData = {
      calendarEventDetails: sanitizeDateTimePayload(responses),
      calendarEventStatus: "NEW_TIME_SUGGESTED",
      timezone: getLocalTimeZone(),
    };
    setButtonLoading(true);

    services
      .updateCalendarInviteEvent(responseObj.id, candidateData)
      .then(data => {
        setButtonLoading(false);
        setOpenProposeNewTime(false);
        setOpenSuccessPopup(true);
      })
      .catch(err => {
        console.log(err);
        setButtonLoading(false);
      });
  };

  const handlePopupsClose = () => {
    setOpenSuccessPopup(false);
    onClose();
  };

  const successContentComponent = () => {
    return (
      <Box textAlign={"center"} sx={{ px: 3 }}>
        <Box sx={{ my: 2 }}>
          <Typography variant="headline6">
            {`You’ve sent new time suggestions to interview with ${responseObj?.companyModel?.companyName}.`}
          </Typography>
        </Box>

        <Box sx={{ my: 3 }}>
          <Typography variant="subhead1">
            {`${responseObj?.companyModel?.companyName} will be notified of your
            new time proposals.`}
          </Typography>
        </Box>
      </Box>
    );
  };
  return (
    <>
      <DialogComponent
        isOpen={openProposeNewTime}
        onClose={() => setOpenProposeNewTime(false)}
        title={"Propose new time"}
        titleVariant={"title_large"}
        contentStyle={{ px: 0 }}
        maxWidth={"lg"}
        fullWidth
        PaperProps={{
          style: { borderRadius: 28 },
        }}
        dialogAction={
          <Box display="flex" sx={{ mr: 2 }}>
            <Box sx={{ mr: 1 }}>
              <SelectedDatesCard
                responses={responses}
                setResponses={setResponses}
              />
            </Box>

            <Box item>
              <LoadingButton
                disabled={handleconfirmDisable()}
                loading={buttonLoading}
                onClick={() => sendInviteToCandidate()}
              >
                Confirm
              </LoadingButton>
            </Box>
          </Box>
        }
        disableBackDropClick
      >
        <Box>
          <Box sx={{ mt: 3, px: 3 }}>
            <Grid container spacing={2}>
              {/*Info section*/}

              <Grid item md={4}>
                <Box sx={{ my: 1, ml: 6 }}>
                  <Box
                    component="img"
                    height="56px"
                    sx={{ borderRadius: "50%" }}
                    src={AVATAR_DEFAULT}
                  ></Box>
                  <Box display={"grid"} sx={{ mt: 2 }}>
                    <Typography variant="largeTitle">
                      {`Interview with ${responseObj?.companyModel?.companyName}`}
                    </Typography>
                    <Typography variant="subhead1" sx={{ mt: 2 }}>
                      Propose new timeslots when you are available to interview.
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item md={1} display="flex" justifyContent={"center"}>
                <Divider orientation="vertical" flexItem />
              </Grid>

              {/*Select DateTime section*/}

              {/*Giving min-width & max-width as this section is going to be

              dynamic with time slots so cant use grid layout here*/}

              <Grid item md={7}>
                <Box>
                  <Box display={"grid"}>
                    <Typography variant="title_medium">
                      Select a Date & Time
                    </Typography>
                    <Typography variant="bodyMedium" sx={{ mt: 3 }}>
                      We recommend choosing your available time slots no more
                      than 7 days out from the day you send the invite.
                    </Typography>
                  </Box>
                  <DateTimeSlotPicker
                    responses={responses}
                    setResponses={setResponses}
                    minTimeSlots={1}
                    maxTimeSlots={5}
                    containerStyles={theme => ({
                      background:
                        "linear-gradient(0deg, rgba(118, 73, 160, 0.05), rgba(118, 73, 160, 0.05)), #FCFCFC",
                      border: `1px solid ${theme.palette.secondary.light}`,
                      borderRadius: theme.shape.standard3.borderRadius,
                      p: 1,
                      mt: 3,
                    })}
                    selectedDate={selectedDate}
                    setSelectedDate={setSelectedDate}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </DialogComponent>
      <SuccessPopup
        title={"New Times Proposed"}
        open={openSuccessPopup}
        close={handlePopupsClose}
        ContentComponent={successContentComponent}
        successIcon={SUCCESS_ICON}
      />
    </>
  );
};

export default ProposeNewTime;
