import React, { useState } from "react";
import { Stack, Grid, useMediaQuery, Box } from "@mui/material";
import { useSelector } from "react-redux";
import CreateResearchCurator from "../../../components/Workspace/Sidebar/RightSidebar/components/CreateResearchCurator";
import CommentFeed from "../../CommentFeed";
import MessageHandlerModal from "../../../common/modal/MessageHandler";
import services from "../../../services";
import { toastContainer } from "../../../utils/toast";
import ROUTES_PATH from "../../../constants/routes";
import { useHistory } from "react-router-dom";
import CreateQuickNote from "../../../components/Workspace/Sidebar/RightSidebar/components/CreateQuickNote";
import { PageContentWrapper } from "../../../components/Workspace/PageContentWrapper";
import theme from "../../../constants/Theme";

export default function CreateExpandView() {
	const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
	const startupData = useSelector((store) => store?.auth?.companyData);
	const userDataObj = useSelector((store) => store?.auth?.userData);
	const pathname = window.location.pathname;
	const [deleteConfirmPopup, setDeleteConfirmPopup] = useState(false);
	const [newCuratorId, setNewCuratorId] = useState("");
	const history = useHistory();
	const [callback, setCallback] = useState(false);
	const pathType = pathname.split("/")[1];
	const localresearchCuratorValue = localStorage.getItem("researchCuratorId");

	const handleDeleteNote = () => {
		services
			.deleteNote(pathname.split("/")[2] || newCuratorId)
			.then((response) => {
				if (response.data.code === 200) {
					handleDeletePopup();
					history.push(ROUTES_PATH.RESEARCH_CURATOR_EXPAND);
				}
			})
			.catch((e) => {
				console.log("Submit", e);
				toastContainer("Error in deleting Note", "error");
			});
	};
	const handleDeletePopup = () => {
		setDeleteConfirmPopup(!deleteConfirmPopup);
	};

	return (
		<Stack
			sx={(theme) => ({
				//minHeight: "100%",
				borderRadius: isSmallScreen ? 0 : "8px",
				p: isSmallScreen ? 2 : 4,
				background: theme.palette.secondary.white,
				//border: `1px solid ${theme.palette.secondary.outline2}`,
			})}
		>
			{pathType === "create-discovery-item" ? (
				<Grid container>
					{" "}
					<Box
						sx={(theme) => ({
							maxWidth: theme.breakpoints.values.xl,
							flexGrow: 1,
							margin: "auto",
							display: "flex",
							background: theme.palette.secondary.white,
							borderRadius: isSmallScreen ? 0 : "8px",
						})}
						flexDirection={"column"}
					>
						<CreateResearchCurator
							userDataObj={userDataObj}
							startupData={startupData}
							isExpandedView={true}
							researchCuratorId={pathname.split("/")[2]}
							handleDeletePopup={handleDeletePopup}
							setNewCuratorId={setNewCuratorId}
							callback={callback}
							setCallback={setCallback}
						/>

						<Box sx={{ px: 3, pt: 2 }}>
							{(newCuratorId || pathname.split("/")[2]) && (
								<CommentFeed noteId={pathname.split("/")[2] ? pathname.split("/")[2] : newCuratorId} />
							)}
						</Box>
					</Box>
				</Grid>
			) : (
				<Grid
					sx={(theme) => ({
						maxWidth: theme.breakpoints.values.xl,
						flexGrow: 1,
						margin: "auto",
						display: "flex",
						//background: theme.palette.secondary.quickNoteBg,
						borderRadius: isSmallScreen ? 0 : "8px",
					})}
					flexDirection={"column"}
					container
				>
					<Box
						sx={(theme) => ({
							// background: theme.palette.secondary.quickNoteBg,
							flexGrow: 1,
							height: 1,
							//m: isSmallScreen ? 0 : 4,
							// pt: 3,
							// pb: 4,
							borderRadius: isSmallScreen ? 0 : "8px",
						})}
					>
						{" "}
						<PageContentWrapper padding={theme.spacing(0)} marginTop={theme.spacing(0)}>
							<CreateQuickNote
								userDataObj={userDataObj}
								startupData={startupData}
								isExpandedView={true}
								researchCuratorId={pathname.split("/")[2]}
								isDetailView={pathType === "quick-note-details"}
								handleDeletePopup={handleDeletePopup}
								setNewCuratorId={setNewCuratorId}
								callback={callback}
								setCallback={setCallback}
							/>
						</PageContentWrapper>
					</Box>
				</Grid>
			)}

			<MessageHandlerModal
				isOpen={deleteConfirmPopup}
				onCancel={handleDeletePopup}
				onOk={handleDeleteNote}
				okText="Delete"
				cancelText={"No thanks"}
				heading="Delete Note"
				text={"Are you sure you want to delete this note?"}
				messageType="primary"
			/>
		</Stack>
	);
}
