import { Svg } from "./Svg";

export const InvestorIcon = ({ className, fill = "#667085", width = 26, height = 26 }) => (
	<Svg className={className} width={width} height={height} viewBox="0 0 26 26" fill="none">
		<path
			d="M9 6.33333H3.13333C2.3866 6.33333 2.01323 6.33333 1.72801 6.47866C1.47713 6.60649 1.27316 6.81046 1.14532 7.06135C1 7.34656 1 7.71993 1 8.46667V22.8667C1 23.6134 1 23.9868 1.14532 24.272C1.27316 24.5229 1.47713 24.7268 1.72801 24.8547C2.01323 25 2.3866 25 3.13333 25H9M9 25H17M9 25L9 3.13333C9 2.3866 9 2.01323 9.14532 1.72801C9.27316 1.47713 9.47713 1.27316 9.72801 1.14532C10.0132 1 10.3866 1 11.1333 1L14.8667 1C15.6134 1 15.9868 1 16.272 1.14532C16.5229 1.27316 16.7268 1.47713 16.8547 1.72801C17 2.01323 17 2.3866 17 3.13333V25M17 11.6667H22.8667C23.6134 11.6667 23.9868 11.6667 24.272 11.812C24.5229 11.9398 24.7268 12.1438 24.8547 12.3947C25 12.6799 25 13.0533 25 13.8V22.8667C25 23.6134 25 23.9868 24.8547 24.272C24.7268 24.5229 24.5229 24.7268 24.272 24.8547C23.9868 25 23.6134 25 22.8667 25H17"
			stroke={fill}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</Svg>
);
