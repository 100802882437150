import { Person } from "@mui/icons-material";
import { Avatar, Box, Typography } from "@mui/material";
import React from "react";
import RatingComponent from "../../../common/rating";
import PowerUpPopUp from "../PowerUpPopUp";

export const FullReview = ({ ...props }) => {
  return (
    <PowerUpPopUp
      open={props.isOpen}
      onClose={props.onClose}
      title={props.title || "What others are saying"}
      mainContent={
        <Box sx={{ p: 3 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "16px",
              textAlign: "center",
            }}
          >
            <Avatar
              sx={{
                width: "80px",
                height: "80px",
                backgroundColor: "#DADCE0",
                color: "#9AA0A6",
              }}
            >
              {/* Needs to have avatar image/icon URL in the object */}
              {/* Users do not yet have an image attached to their profile. */}
              <Person fontSize="large" />
            </Avatar>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "5px",
                textAlign: "center",
              }}
            >
              <Typography component={"h6"} variant="headline6">
                {props.review &&
                props.review.userModel &&
                props.review.userModel.name
                  ? props.review.userModel.name
                  : ""}
              </Typography>
              <Typography variant="label_large">
                {props.review &&
                props.review.userModel &&
                props.review.userModel.companyName
                  ? props.review.userModel.companyName
                  : ""}
              </Typography>
            </Box>
            <RatingComponent
              isReadOnly={true}
              rating={props.review.rating}
              iconColor="#4B454E"
              color="#4B454E"
              starPadding={"10px"}
            />
          </Box>
          <Box sx={{ mt: 3 }}>
            <Typography variant="body_large">
              {props.review && props.review.description
                ? props.review.description
                : ""}
            </Typography>
          </Box>
        </Box>
      }
    />
  );
};
