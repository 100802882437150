import React, { useMemo, useState } from "react";
import { Box, Typography, Avatar, IconButton, ClickAwayListener, Tooltip, tooltipClasses, styled, List, ListItem } from "@mui/material";

import InfoIcon from "../../Assets/info-circle.svg";

const CustomTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
	[`& .${tooltipClasses.tooltip}`]: {
		margin: "0 !important",
		borderRadius: "50px",
		padding: 0,
	},
	[`.${tooltipClasses.popper}`]: {
		borderRadius: "50px",
	},
});

export const NewOnboardCard = ({ id, CardIcon, title, selectedId, desc, onSelect, multiSelect = false, isExpertise = false, subOptions = [] }) => {
	const [openValidationDetail, setOpenValidationDetail] = useState(false);

	const currentColor = useMemo(() => {
		if (multiSelect === false) {
			if (selectedId === id) {
				return { background: "#7B61FF", color: "#fff" };
			}
		} else {
			if (selectedId.findIndex((item) => item === id) > -1) {
				return { background: "#7B61FF", color: "#fff" };
			}
		}

		return { background: "inherit", color: "#667085" };
	}, [selectedId, id, multiSelect]);

	const handleSelect = () => {
		if (!multiSelect) {
			onSelect(id);
		} else {
			const curIndex = selectedId.findIndex((item) => item === id);
			if (curIndex > -1) {
				selectedId.splice(curIndex, 1);
				onSelect(selectedId);
			} else {
				onSelect([...selectedId, id]);
			}
		}
	};

	const handleTooltipClose = () => {
		setOpenValidationDetail(false);
	};

	const handleTooltipOpen = (e) => {
		e.stopPropagation();
		setOpenValidationDetail(true);
	};

	return (
		<Wrapper onClick={handleSelect}>
			<Avatar
				sx={{
					position: "relative",
					width: "48px",
					height: "48px",
					background: currentColor.background,
				}}
			>
				<CardIcon width={30} height={30} fill={currentColor.color} />
			</Avatar>
			<Typography variant="Text/sm/Semibold">{title}</Typography>
			<Typography variant="Text/sm/Regular" color="#667085">
				{desc}
			</Typography>
			{isExpertise && (
				<ClickAwayListener onClickAway={handleTooltipClose}>
					<CustomTooltip
						PopperProps={{
							disablePortal: true,
						}}
						placement={"right-start"}
						onClose={handleTooltipClose}
						open={openValidationDetail}
						sx={{
							background: "#fff",
						}}
						title={
							<TooltipWrapper>
								<Typography variant="Text/sm/Semibold" color="#000">
									{title}
								</Typography>
								<ListWrapper>
									{subOptions.map((subOption) => (
										<ListItem key={subOption.id} sx={{ p: "2px 0" }}>
											<Typography variant="Text/sm/Regular" color="#000">
												{subOption.title}
											</Typography>
										</ListItem>
									))}
								</ListWrapper>
							</TooltipWrapper>
						}
					>
						<Box position="absolute" top="32px" right="32px">
							<IconButton sx={{ p: 0 }} onClick={handleTooltipOpen}>
								<img src={InfoIcon} alt={"Info"} />
							</IconButton>
						</Box>
					</CustomTooltip>
				</ClickAwayListener>
			)}
		</Wrapper>
	);
};

const Wrapper = styled(Box)({
	flex: 1,
	position: "relative",
	maxWidth: "200px",
	padding: "32px",
	display: "flex",
	flexDirection: "column",
	gap: "8px",
	background: "#FFFFFF",
	boxShadow: "0px 10px 25px 0px #0000000D",
	borderRadius: "24px",
	"&:hover": { cursor: "pointer" },
});

const TooltipWrapper = styled(Box)({
	width: "340px",
	background: "#FFFFFF",
	boxShadow: "0px 10px 85px rgba(0, 0, 0, 0.1)",
	borderRadius: "30px",
	padding: "30px",
	textAlign: "left",
});

const ListWrapper = styled(List)({
	listStyleType: "disc",
	paddingLeft: "20px",
	"& .MuiListItem-root": {
		display: "list-item",
		color: "#000",
	},
});
