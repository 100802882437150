import React, { useEffect, useState } from "react";
import { Avatar, Box, Stack, Typography, Skeleton, useMediaQuery, Button } from "@mui/material";
import { useParams, useLocation } from "react-router-dom";
import { CALEDNER_ICON_V2, HEART_ICON_V2, HEART_PINK_FILLED_ICON, MESSAGE_ICON_V2, MIGHTY_MENTOR, PINK_CHECK_ICON } from "../../../constants";
import { useDispatch, useSelector } from "react-redux";
import { getRoleValue } from "../../../utils/commonFunctions";
import { getConversationWithID } from "../../../utils/messaging-helper";
import { updateCurrentConversation } from "../../../containers/Messaging/store/action-creators";
import { setNetworkingDashboardData } from "../../../modules/actions/networkingActions";
import MessageDialog from "../../../components/Networking/MessageDialog";
import ScheduleMeetingDialog from "../../../components/Networking/ScheduleMeetingDialog";
import { USER_TYPES } from "../../../utils/userTypes";
import { saveOrHideMatchedCollections } from "../../../containers/MentorProfile/Function/common";
import { setUserProfileData } from "../../../modules/actions/userProfileActions";
import AvatarWithFallBack from "../../AvatarWithFallBack";
import ROUTES_PATH from "../../../constants/routes";
import { AddOutlined } from "@mui/icons-material";
import { UserCardContainer } from "../UserAboutCard/style";
import { ConfirmationModal } from "../../../components/Profile/Components/BioCard/Components/ConfirmationModal";
import { retrieveFromLocalStorage } from "../../../constants/Theme/ThemeContext";

const UserDetailsComponent = () => {
	const userDetails = useSelector((state) => state?.userProfileData?.userData);
	const isLoading = useSelector((state) => state?.userProfileData?.loading);
	const params = useParams();
	const slidingProfileData = useSelector((store) => ({
		open: store?.slidingProfile?.open,
		profileType: store?.slidingProfile?.profileType,
		profileId: store?.slidingProfile?.profileId,
	}));
	const location = useLocation();
	const userId = !location.pathname.includes(ROUTES_PATH.MY_MENTOR_PROFILE) ? slidingProfileData?.profileId : params?.id ? params?.id : null;
	const authDetails = useSelector((state) => state?.auth);
	const currentNetworkTab = useSelector((state) => state.networking?.currentTab);
	const networkMatches = useSelector((state) => state.networking?.networkMatches ?? []);
	const totalMatches = useSelector((state) => state?.networking?.totalMatches ?? 0);
	const dispatch = useDispatch();
	const [openScheduleMeetingDialog, setOpenScheduleMeetingDialog] = useState({ open: false, userInfo: null, type: null });
	const [openMessagingDialog, setOpenMessagingDialog] = useState(false);
	const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
	const [openConfirmationModel, setOpenConfirmationModel] = useState(false);
	const [invited, setInvited] = useState(false);
	const isAdmin = useSelector((state) => state?.auth?.isAdmin);
	console.log(`userDetails`, userDetails);
	console.log(`networkmatches`, networkMatches);

	useEffect(() => {
		networkMatches?.map((item) => {
			if (item?.id == userDetails?.id) {
				userDetails["partnerCode"] = item?.partnerCode;
			}
		});
	}, [userDetails]);

	const closeConfirmationModal = () => {
		setOpenConfirmationModel(false);
	};
	const removeMatches = (id) => {
		const intUserId = isNaN(id) ? null : parseInt(id);
		const filteredNetworkMatches = networkMatches.filter((match) => match.id !== intUserId);
		dispatch(
			setNetworkingDashboardData({
				networkMatches: filteredNetworkMatches,
				hiddenConfirmOpen: false,
				slidingConfirmationObject: {},
				totalMatches: totalMatches - 1 >= 0 ? totalMatches - 1 : 0,
			})
		);
	};

	const onSaveConfirm = async () => {
		try {
			const isSaved = userDetails?.isSaved ? userDetails?.isSaved : false;
			await saveOrHideMatchedCollections({ entityIds: [userDetails.id], collectionType: "SAVED", entityType: "USER", isRemove: isSaved });
			const tempUserDetails = { ...userDetails, isSaved: !isSaved };
			dispatch(
				setUserProfileData({
					userData: tempUserDetails,
				})
			);
			if (slidingProfileData?.open) {
				if (currentNetworkTab === "SAVED") {
					removeMatches(userDetails?.id);
					return;
				}
				const tempNetworkMatches = [...networkMatches];
				const intUserId = isNaN(userDetails?.id) ? null : parseInt(userDetails?.id);
				const matchedIndex = tempNetworkMatches.findIndex((match) => match.id === intUserId);
				if (matchedIndex !== -1) {
					tempNetworkMatches[matchedIndex] = { ...tempNetworkMatches[matchedIndex], isSaved: !isSaved };
				}
				dispatch(
					setNetworkingDashboardData({
						networkMatches: tempNetworkMatches,
					})
				);
			}
		} catch (error) {
			console.log("Could not perform operation", error);
		}
	};

	const onMessageClick = async () => {
		try {
			let conversationID = await getConversationWithID({
				idArray: [userDetails.id],
			});
			dispatch(updateCurrentConversation(conversationID));
			setOpenMessagingDialog(true);
			dispatch(
				setNetworkingDashboardData({
					openMessageDialog: true,
				})
			);
		} catch (e) {
			console.log("Could not retrieve conversation.", e);
		}
	};

	const getUserType = () => {
		if (userDetails?.userTypes?.includes(USER_TYPES.MENTOR_USER)) {
			return USER_TYPES.MENTOR_USER;
		}
		if (userDetails?.userTypes?.includes(USER_TYPES.STARTUP_OWNER)) {
			return USER_TYPES.STARTUP_OWNER;
		}
		if (userDetails?.userTypes?.includes(USER_TYPES.INVESTOR_USER)) {
			return USER_TYPES.INVESTOR_USER;
		}
		if (userDetails?.isSaved) {
			return "SAVED";
		}
		if (userDetails?.isHidden) {
			return "HIDDEN";
		}
	};

	const onCalendarClick = () => {
		const userTypes = getUserType();
		setOpenScheduleMeetingDialog({ open: true, userInfo: userDetails, type: userTypes });
	};

	const handleCloseScheduleMeetingDialog = () => {
		setOpenScheduleMeetingDialog({ open: false, userInfo: null, type: null });
	};

	return (
		<Stack direction={"row"} spacing={3}>
			<Stack direction="row" alignItems={isSmallScreen ? "start" : "center"} spacing={3}>
				{isLoading ? (
					<Skeleton animation="wave" variant="circular" width={150} height={150} />
				) : (
					<AvatarWithFallBack sx={{ height: isSmallScreen ? "80px" : "150px", width: isSmallScreen ? "80px" : "150px" }} src={userDetails?.picture} />
				)}
				<Stack spacing={1}>
					{isLoading ? (
						<Skeleton animation="wave" variant="rectangular" width={100} height={24} />
					) : (
						<Stack direction="row" spacing={1} alignItems={"center"} height="100px" justifyContent={"flex-start"}>
							<Box
								sx={{
									justifyContent: "center",
									alignItems: "center",
									height: "50px",
									mt: "20px",
									display: "flex",
									flexDirection: "row",
									gap: "10px",
								}}
							>
								<Box
									component={"img"}
									src={userDetails?.isSaved ? HEART_PINK_FILLED_ICON : HEART_ICON_V2}
									alt="heart"
									sx={{ height: "25px", width: "25px", cursor: "pointer" }}
									onClick={onSaveConfirm}
								/>
								{userId !== authDetails?.userId && (
									<>
										<Box
											component={"img"}
											src={CALEDNER_ICON_V2}
											alt="message"
											sx={{ height: "25px", width: "25px", cursor: "pointer" }}
											onClick={onCalendarClick}
										/>
										<Box
											component={"img"}
											src={MESSAGE_ICON_V2}
											alt="message"
											sx={{ height: "25px", width: "25px", cursor: "pointer" }}
											onClick={onMessageClick}
										/>
									</>
								)}
							</Box>

							{(isAdmin || authDetails?.userType === USER_TYPES.PARTNER_USER) &&
								userId !== authDetails?.userId &&
								userDetails?.partnerCode !== authDetails?.partnerCode && (
									<Box sx={{ height: "25px", width: "128px", cursor: "pointer" }}>
										<Button
											onClick={() => {
												!(userDetails?.invitationStatus === "PENDING" || userDetails?.invitationStatus === "ACCEPTED") &&
													setOpenConfirmationModel(true);
											}}
											variant="containedGrayStyle"
											sx={{
												backgroundColor: "rgba(242, 244, 247, 1)",
												cursor:
													userDetails?.invitationStatus === "PENDING" || userDetails?.invitationStatus === "ACCEPTED"
														? "default"
														: "pointer",
											}}
											startIcon={
												userDetails?.invitationStatus === "PENDING" || invited ? (
													<Box component={"img"} src={PINK_CHECK_ICON} sx={{ width: "24px", height: "24px" }} />
												) : (
													!userDetails?.invitationStatus === "ACCEPTED" && (
														<AddOutlined
															sx={{ fontSize: "23px", color: (theme) => theme.palette.secondary.black }}
															className="add-icon-hover"
														/>
													)
												)
											}
										>
											{userDetails?.invitationStatus === "PENDING" || invited
												? "Invited"
												: userDetails?.invitationStatus === "ACCEPTED"
												? "Accepted"
												: "Invite"}
										</Button>
									</Box>
								)}
						</Stack>
					)}
					{isLoading ? (
						<Skeleton animation="wave" variant="rectangular" width={210} height={24} />
					) : (
						<Typography variant={isSmallScreen ? "Text/md/Semibold" : "feed_title"}>{userDetails?.name}</Typography>
					)}
					{isLoading ? (
						<Skeleton animation="wave" variant="rectangular" width={210} height={24} />
					) : (
						<Stack direction="row" spacing={1} display={"flex"} alignItems={"center"}>
							<Box
								component={"img"}
								src={MIGHTY_MENTOR}
								alt="logo"
								sx={{
									height: "30px",
									width: "30px",
								}}
							/>
							<Typography variant="Text/sm/Regular">
								{userDetails?.isVerified ? "Verified Mentor" : getRoleValue(userDetails?.userTypes && userDetails?.userTypes[0])}
							</Typography>
						</Stack>
					)}
				</Stack>

				{openMessagingDialog && <MessageDialog />}
				{openScheduleMeetingDialog?.open && openScheduleMeetingDialog?.userInfo && (
					<ScheduleMeetingDialog
						open={openScheduleMeetingDialog?.open}
						handleClose={handleCloseScheduleMeetingDialog}
						userInfo={openScheduleMeetingDialog?.userInfo}
						withUserType={openScheduleMeetingDialog?.type}
					/>
				)}
				<ConfirmationModal
					open={openConfirmationModel}
					handleClose={closeConfirmationModal}
					setInvited={setInvited}
					invitedUserId={userDetails && userDetails?.id}
					userRole={userDetails && userDetails?.userTypes?.length > 0 ? userDetails?.userTypes[0] : "user"}
					partnerName={authDetails?.partnerCode}
				/>
			</Stack>
		</Stack>
	);
};

export default UserDetailsComponent;
