import React, { useState, useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { Box, Button, Divider, IconButton, Typography, CircularProgress, Stack, Skeleton } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Navigation, Pagination } from "swiper";
import PageTitle from "../../../../common/PageTitle";
import { GLOBE_ICON_V1, GREY_EDIT_ICON_V2, LIGHTNING_ICON_V1, USER_WITH_TICK } from "../../../../constants";
import { useHistory } from "react-router-dom";
import HomePowerCard from "../../../Founder/NewWorkspace/components/HomePowerCard/HomePowerCard";
import AdminPowerupCard from "../PowerupCard/PowerupCourseCard";
import { AdminPowerpackContext } from "..";
import { SkeletonCardLoader } from "../../../Founder/NewWorkspace/GetStarted/PowerPacks";
import ROUTES_PATH from "../../../../constants/routes";

const PowerPackDashboard = () => {
	const [selectedSlide, setSelectedSlide] = useState(0);
	const { powerpacks, loading, powerPackDetails, setPowerPackDetails, powerups, setPowerups, getPackDetailInfoById, getPowerPacks, powerpacksLoading } =
		useContext(AdminPowerpackContext);

	useEffect(() => {
		getPowerPacks();
	}, []);

	const history = useHistory();

	const BREAK_POINTS = {
		120: {
			slidesPerView: 1,
			spaceBetween: 10,
		},

		712: {
			slidesPerView: 2,
			spaceBetween: 10,
		},
		1031: {
			slidesPerView: 2,
			spaceBetween: 10,
		},
		1336: {
			slidesPerView: 3,
			spaceBetween: 10,
		},
		1505: {
			slidesPerView: 3,
			spaceBetween: 10,
		},
		1705: {
			slidesPerView: 4,
			spaceBetween: 10,
		},
	};

	const handleEventClick = (pack, index) => {
		setSelectedSlide(index);
		getPackDetailInfoById(pack.uuid);
		setPowerPackDetails(pack);
	};

	return (
		<>
			<Helmet>
				<meta charSet="utf-8" />
				<title>StartupOS - PowerPack Dashboard</title>
			</Helmet>
			<Box
				sx={{
					px: {
						lg: 8,
						md: 4,
						xs: 2,
					},
					pb: "30px",
					display: "flex",
					flexDirection: "column",
					gap: "32px",
					maxWidth: "1920px",
					margin: "0 auto",
				}}
			>
				{" "}
				<PageTitle icon={USER_WITH_TICK} label={"StartupOS Admin Board"} />
				<Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
					<Box sx={{ backgroundColor: "#000000", borderRadius: "100px", maxWidth: "fit-content", p: "12px 20px 12px 20px" }}>
						<Typography variant="Text/sm/Semibold" sx={{ color: "white" }}>
							PowerPack Management Dashboard{" "}
						</Typography>
					</Box>
					<Box>
						<Button
							startIcon={<Box component={"img"} src={GREY_EDIT_ICON_V2} alt="edit" />}
							variant="DS1"
							color={"gray"}
							onClick={() => {
								history.push(ROUTES_PATH.ADMIN_POWERPACK_SETTINGS);
							}}
							sx={{ mr: 2 }}
						>
							Edit PowerPack Settings{" "}
						</Button>
						<Button
							startIcon={<AddRoundedIcon />}
							onClick={() => {
								history.push(ROUTES_PATH.ADMIN_POWERPACK_ADD);
								setPowerups([]);
							}}
							variant="DS1"
						>
							Create PowerPack{" "}
						</Button>
					</Box>
				</Box>
				{powerpacksLoading ? (
					<Box display={"flex"} flexDirection={"row"} gap={3}>
						{Array.from({ length: 4 }).map((_, index) => {
							return <SkeletonCardLoader />;
						})}
					</Box>
				) : (
					<Box sx={{ position: "relative" }}>
						<IconButton sx={{ position: "absolute", left: 0, zIndex: 1, top: "50%", background: "#F2F4F7" }} className="custom-swiper-button-prev">
							<ArrowBackRoundedIcon sx={{ color: "#344054" }} />
						</IconButton>
						<IconButton sx={{ position: "absolute", right: 0, zIndex: 1, top: "50%", background: "#F2F4F7" }} className="custom-swiper-button-next">
							<ArrowForwardRoundedIcon sx={{ color: "#344054" }} />
						</IconButton>
						<Box px={6}>
							<Swiper
								breakpoints={BREAK_POINTS}
								allowTouchMove={false}
								spaceBetween={30}
								slidesPerView={4}
								grabCursor={false}
								initialSlide={0}
								rewind={false}
								navigation={{
									prevEl: ".custom-swiper-button-prev",
									nextEl: ".custom-swiper-button-next",
								}}
								loop={false}
								centeredSlides={false}
								fade={true}
								modules={[Pagination, Navigation]}
							>
								{powerpacks?.length > 0 &&
									powerpacks?.map((pack, index) => (
										<SwiperSlide key={index}>
											<Box key={index} display={"flex"} gap={20}>
												<HomePowerCard
													key={pack.id}
													variant="secondary"
													uuid={pack.uuid}
													title={pack.title}
													desc={pack?.shortDescription}
													imgSrc={pack.imageUrl}
													isAI={pack?.isAiPowered}
													isPremium={pack?.isPremiumFeature}
													boxshadow="none"
													isSelected={index === selectedSlide}
													onDetail={(uuid) => handleEventClick(pack, index)}
													hideButton={true}
												/>
											</Box>
										</SwiperSlide>
									))}
							</Swiper>
						</Box>
					</Box>
				)}
				{loading ? (
					<Box justifyContent={"center"} sx={{ display: "flex", mt: 3 }}>
						<CircularProgress />
					</Box>
				) : (
					powerups?.length > 0 && (
						<>
							<Box display={"flex"} alignItems={"center"} gap={2}>
								<Typography variant="Text/xl/Semibold">PowerPack</Typography>
								<Divider sx={{ flexGrow: 1 }} />
							</Box>

							<Box
								sx={(theme) => ({
									p: 4,
									borderRadius: theme.shape.standard5.borderRadius,
									backgroundColor: theme.palette.secondary.white,
								})}
							>
								<Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} mb={2}>
									{" "}
									<Box display={"flex"} alignItems={"center"} gap={1}>
										<Box component={"img"} src={GLOBE_ICON_V1} alt="globe" />
										<Typography variant="Text/md/Semibold">PowerPack Overview</Typography>
									</Box>
									{!powerPackDetails?.isDefault && (
										<Button
											startIcon={<Box component={"img"} src={GREY_EDIT_ICON_V2} alt="edit" />}
											variant="DS1"
											color={"gray"}
											onClick={() => {
												history.push("/admin-powerpack/edit");
											}}
										>
											Edit PowerPack{" "}
										</Button>
									)}
								</Box>

								<Typography variant="Text/sm/Regular">
									Validate your idea before you invest in developing it. Our PowerPack provides different methods of idea validation, such as
									market research, talking to potential customers, and prioritizing your minimum viable product (MVP).
								</Typography>
								<Box display={"flex"} alignItems={"center"} mt={3} gap={1}>
									<Box component={"img"} src={LIGHTNING_ICON_V1} alt="lightning" />
									<Typography variant="Text/sm/Semibold">Complete the following PowerUp Courses and Activities</Typography>
								</Box>
								<Stack spacing={2} mt={2}>
									{powerups?.length > 0 &&
										powerups?.map((powerupItem) => {
											return (
												<AdminPowerupCard
													title={powerupItem?.title || powerupItem?.name}
													imageUrl={powerupItem?.imageUrl}
													shortDescription={powerupItem?.shortDescription}
													readOnly={true}
												/>
											);
										})}
								</Stack>
							</Box>
						</>
					)
				)}
			</Box>
		</>
	);
};

export default PowerPackDashboard;
