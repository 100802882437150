import React, { useState, useEffect, useMemo, useContext } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { CROSS_ARROW_ICON, ARROW_DOWN_ICON } from "../../../constants";
import theme from "../../../constants/Theme";
import { Box, Typography } from "@mui/material";
import InfoHelper from "../../Founder/InfoHelper";
import { PowerUPContext } from "../../../containers/PowerUP/PowerUPController";

function ProgressGraph({
	leftColour,
	rightColour,
	heightValue,
	widthValue,
	percentage,
	marginX,
	marginY,
	scoreValue,
	stageName,
	stageValue,
	scoreObj,
	toggleOpenScoreBenchmarkDetails,
	toggleOpenFundingStagesDetails,
}) {
	const percentageValue = percentage;
	const xMin = marginX;
	const yMin = heightValue - marginY;
	const a = (widthValue - (widthValue / 100) * 7) / 10;
	const sliderknob_x = xMin + percentageValue * 2.8;
	const [sliderknob_y, setSliderknob_y] = useState(0);
	const [dxValue, setDxValue] = useState(0);
	const [dyValue, setDyValue] = useState(0);
	const increasedScore = Math.floor(scoreValue - scoreObj?.lastMonthScore);
	const powerupData = useContext(PowerUPContext);

	const location = useLocation();

	const isWorkSpace = useMemo(() => {
		if (location?.pathname.includes("/workspace/powerUp/")) {
			return true;
		}

		return false;
	}, [location?.pathname]);

	useEffect(() => {
		if (percentageValue <= 50) {
			if (percentageValue <= 22) {
				setSliderknob_y(yMin - percentageValue * (percentageValue / 6.45));
			} else if (percentageValue > 22 && percentageValue <= 24) {
				setSliderknob_y(yMin - percentageValue * (percentageValue / 6.65));
			} else if (percentageValue > 24 && percentageValue <= 37) {
				setSliderknob_y(yMin - percentageValue * 3.5);
			} else if (percentageValue > 37 && percentageValue <= 40) {
				setSliderknob_y(yMin - percentageValue * 3.35);
			} else if (percentageValue > 40 && percentageValue <= 44) {
				setSliderknob_y(yMin - percentageValue * 3.15);
			} else if (percentageValue > 44 && percentageValue <= 47) {
				setSliderknob_y(yMin - percentageValue * 3);
			} else if (percentageValue > 47 && percentageValue <= 50) {
				setSliderknob_y(yMin - percentageValue * 2.8);
			}
		} else {
			const newPecentage = 100 - percentageValue;
			if (newPecentage <= 20) {
				setSliderknob_y(yMin - newPecentage * (newPecentage / 6.2));
			} else if (newPecentage > 20 && newPecentage <= 23) {
				setSliderknob_y(yMin - newPecentage * (newPecentage / 6.5));
			} else if (newPecentage > 23 && newPecentage <= 36) {
				setSliderknob_y(yMin - newPecentage * 3.6);
			} else if (newPecentage > 36 && newPecentage <= 40) {
				setSliderknob_y(yMin - newPecentage * 3.4);
			} else if (newPecentage > 40 && newPecentage <= 44) {
				setSliderknob_y(yMin - newPecentage * 3.18);
			} else if (newPecentage > 44 && newPecentage <= 47) {
				setSliderknob_y(yMin - newPecentage * 3);
			} else if (newPecentage > 47 && newPecentage <= 50) {
				setSliderknob_y(yMin - newPecentage * 2.8);
			}
		}
	});

	useEffect(() => {
		if (percentageValue > 2 && percentageValue <= 7) {
			setDxValue(-7);
			setDyValue(-16);
		} else if (percentageValue >= 7 && percentageValue < 50) {
			setDxValue(-16);
			setDyValue(-16);
		} else if (percentageValue > 50 && percentageValue <= 70) {
			setDxValue(15);
			setDyValue(-14);
		} else if (percentageValue > 70 && percentageValue <= 100) {
			setDxValue(19);
			setDyValue(-14);
		} else {
			setDxValue(1);
			setDyValue(-16);
		}
	});

	const onScoreValueClick = (e) => {
		e.stopPropagation();
		toggleOpenScoreBenchmarkDetails?.();
	};

	const onFundingStageClick = (e) => {
		e.stopPropagation();
		toggleOpenFundingStagesDetails?.();
	};

	return (
		<Box
			display="flex"
			flexDirection="column"
			alignItems="center"
			justifyContent="center"
			gap={2}
			sx={{ cursor: "pointer", height: `${heightValue}px`, width: `${widthValue}px` }}
			onClick={onScoreValueClick}
		>
			<Box position={"relative"} display="flex" alignItems="center" sx={{ pt: 3, pr: 3 }}>
				{" "}
				<Typography variant="displaySmall" sx={{ fontSize: "80px !important" }} color="#7B61FF">
					{scoreValue}
				</Typography>
				<Box sx={{ position: "absolute", top: "0px", right: "0px" }}>
					<InfoHelper
						title={"StartupOS Score"}
						body={`StartupOS Score is a value up to 1000, 
based on a mix of qualitative and quanitative metrics. Investors on the platform use to this score to evaluate startups. `}
						iconType={"gray"}
					/>
				</Box>
			</Box>

			<Typography variant="titleXLarge" color="#272727">
				{isWorkSpace ? `${powerupData?.startupProfileDetails?.currentStage || ""} StartupOS Score` : " StartupOS Score"}
			</Typography>
		</Box>
	);

	return (
		<svg height={`${heightValue}`} width={`${widthValue}`} style={{ cursor: "pointer" }} onClick={onScoreValueClick}>
			<defs>
				<linearGradient id={`${scoreObj?.companyId}`}>
					<stop offset={`${percentageValue}%`} stop-color={`${leftColour}`} />
					<stop offset={`${percentageValue}%`} stop-color={`${percentageValue == 100 ? leftColour : rightColour}`} />
				</linearGradient>
			</defs>

			{/*  */}
			<defs>
				<linearGradient id={`${scoreObj?.companyId + "3"}`}>
					<stop offset={`${percentageValue}%`} stop-color={"white"} stop-opacity={0} />
					<stop offset={`${percentageValue}%`} stop-color={"white"} stop-opacity={1} />
				</linearGradient>
			</defs>

			<defs>
				<linearGradient id="myGradient2" gradientTransform="rotate(90)">
					<stop offset={"5%"} stop-color={`${leftColour}`} />
					<stop offset={"95%"} stop-color={`${rightColour}`} />
				</linearGradient>
			</defs>
			{/*  */}

			<path
				d={`M 10 170 C 70 150 70 30 150 30 C 230 30 230 150 290 170`}
				stroke={`url(#${scoreObj?.companyId})`}
				stroke-width="9"
				fill={`url(#myGradient2)`}
				fill-opacity="0.2"
				stroke-linecap="round"
			/>

			{/*  */}
			<path
				d={`M 10 170 C 70 150 70 30 150 30 C 230 30 230 150 290 170`}
				stroke={`url(#${scoreObj?.companyId})`}
				stroke-width="9"
				fill={`url(#${scoreObj?.companyId + "3"})`}
				stroke-linecap="round"
			/>

			{/*  */}
			<g stroke={`${leftColour}`} stroke-width="6.5" fill="white">
				<circle id="pointC" cx={`${sliderknob_x}`} cy={`${sliderknob_y - 2}`} r="7.5" style={{ display: percentageValue == 0 ? "none" : "flex" }} />
			</g>
			{/*  */}

			<g
				style={{
					fontSize: "16",
					fontWeight: percentageValue > 0 && percentageValue <= 25 ? "600" : "400",
					fontFamily: '"Poppins", "Helvetica", "Arial", sans-serif',
					fontStyle: "normal",
				}}
				fill={percentageValue > 0 && percentageValue <= 25 ? `${theme.palette.primary.primaryPurple}` : `${theme.palette.secondary.black}`}
				stroke="none"
				text-anchor="middle"
			>
				<text x={`${xMin + 5}`} y={`${yMin + a + 10}`}>
					Fair
				</text>
			</g>

			<g
				style={{
					fontSize: "16",
					fontWeight: percentageValue > 25 && percentageValue <= 50 ? "600" : "400",
					fontFamily: '"Poppins", "Helvetica", "Arial", sans-serif',
					fontStyle: "normal",
				}}
				fill={percentageValue > 25 && percentageValue <= 50 ? `${theme.palette.primary.primaryPurple}` : `${theme.palette.secondary.black}`}
				stroke="none"
				text-anchor="middle"
			>
				<text x={`${xMin + 3 * a}`} y={`${yMin + a + 10}`}>
					Good
				</text>
			</g>

			<g
				style={{
					fontSize: "16",
					fontWeight: percentageValue > 50 && percentageValue <= 75 ? "600" : "400",
					fontFamily: '"Poppins", "Helvetica", "Arial", sans-serif',
					fontStyle: "normal",
				}}
				fill={percentageValue > 50 && percentageValue <= 75 ? `${theme.palette.primary.primaryPurple}` : `${theme.palette.secondary.black}`}
				stroke="none"
				text-anchor="middle"
			>
				<text x={`${xMin + 5.5 * a}`} y={`${yMin + a + 10}`}>
					Great
				</text>
			</g>

			<g
				style={{
					fontSize: "16",
					fontWeight: percentageValue > 75 && percentageValue <= 100 ? "600" : "400",
					fontFamily: '"Poppins", "Helvetica", "Arial", sans-serif',
					fontStyle: "normal",
				}}
				stroke="none"
				text-anchor="middle"
				fill={percentageValue > 75 && percentageValue <= 100 ? `${theme.palette.primary.primaryPurple}` : `${theme.palette.secondary.black}`}
			>
				<text x={`${xMin + 8.5 * a}`} y={`${yMin + a + 10}`}>
					Best
				</text>
			</g>

			<g
				style={{
					fontSize: "16",
					fontWeight: "600",
					fontFamily: '"Poppins", "Helvetica", "Arial", sans-serif',
					fontStyle: "normal",
				}}
				fill={`${theme.palette.secondary.black}`}
				stroke="none"
				text-anchor="middle"
			>
				<text x={`${xMin + 9.5 * a}`} y={`${yMin + 8}`}>
					100%
				</text>
			</g>

			<g
				style={{
					fontSize: "16",
					fontWeight: "600",
					fontFamily: '"Poppins", "Helvetica", "Arial", sans-serif',
					fontStyle: "normal",
				}}
				fill={`${theme.palette.secondary.black}`}
				stroke="none"
				text-anchor="middle"
			>
				<text x={`${sliderknob_x}`} y={`${sliderknob_y}`} dy={dyValue} dx={dxValue}>
					{percentageValue}%
				</text>
			</g>

			<g
				font-size="35"
				font-family='"PoppinsMedium", "Helvetica", "Arial", sans-serif'
				fill={`${theme.palette.secondary.black}`}
				stroke="none"
				text-anchor="middle"
			>
				<text
					x={scoreValue < 10 ? `${xMin + 4.15 * a}` : `${xMin + 4 * a}`}
					y={`${yMin - (a + 10)}`}
					fill={`${theme.palette.secondary.black}`}
					onClick={onScoreValueClick}
				>
					{scoreValue}
				</text>
			</g>

			<g
				font-size="35"
				font-family="sans-serif"
				fill={`${theme.palette.secondary.black}`}
				stroke="none"
				text-anchor="middle"
				onClick={onScoreValueClick}
				style={{
					cursor: "pointer",
					display: scoreValue == 0 ? "none" : "flex",
				}}
			>
				<circle cx={`${xMin + 5.4 * a}`} cy={`${yMin - (a + 47)}`} r="10" fill="#7B61FF" />
				<image
					x={`${xMin + 5.25 * a}`}
					y={`${yMin - (a + 52)}`}
					href={`${(increasedScore ? increasedScore : 0) >= 0 ? CROSS_ARROW_ICON : ARROW_DOWN_ICON}`}
					height="10"
					width="10"
				/>
			</g>

			<g font-size="15" font-family="sans-serif" fill={`${theme.palette.secondary.black}`} text-align="center" stroke="none" text-anchor="middle">
				<text
					x={`${xMin + 4.1 * a}`}
					y={`${yMin + 0.1 * a}`}
					style={{
						fontSize: "16",
						fontWeight: "400",
						fontFamily: '"Poppins", "Helvetica", "Arial", sans-serif',
						fontStyle: "normal",
					}}
				>
					vs. {stageValue} others in{" "}
					<tspan
						style={{
							fontSize: "16",
							fontWeight: "600",
							fontFamily: '"Poppins", "Helvetica", "Arial", sans-serif',
							fontStyle: "normal",
						}}
						onClick={onFundingStageClick}
					>
						{stageName}
					</tspan>
				</text>
			</g>
		</svg>
	);
}

export default ProgressGraph;
