import { Box, Button } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import OnboardingCardSelector from "../../../../components/Onboarding/OnboardingCardSelector";
import PageTitle from "../../../../components/Onboarding/PageTitle";
import { WHATS_YOUR_EXPERTISE } from "../../../../constants/updated-onboarding";

export default function WhatsYourExpertise({ setComplete }) {
  const [userResponse, setUserResponse] = useState("");
  const savedData = useSelector(state => state?.onboarding?.yourExpertise);

  useEffect(() => {
    if (savedData) {
      setUserResponse(savedData);
    }
  }, [savedData]);

  const callComplete = (complete, data) => {
    if (setComplete) {
      setComplete(complete, data);
    }
  };

  useEffect(() => {
    if (userResponse && userResponse.length) {
      callComplete(1, { yourExpertise: userResponse });
    } else {
      callComplete(0, { yourExpertise: [] });
    }
  }, [userResponse]);
  return (
    <Box>
      <PageTitle
        title={"What’s your expertise?"}
        description={"Pick all that apply."}
      />

      <Box sx={{ my: 5 }}>
        <OnboardingCardSelector
          cardItems={WHATS_YOUR_EXPERTISE}
          flex={1 / 3}
          gridColWidth={7}
          multiSelect
          setUserResponse={setUserResponse}
          userResponse={userResponse}
          savedData={savedData}
        />
      </Box>
    </Box>
  );
}
