import React, { useEffect, useMemo, useState } from "react";
import { useHistory, generatePath } from "react-router-dom";
import { useSelector } from "react-redux";
import { Box, Button, Divider, Typography, useMediaQuery } from "@mui/material";
import { cloneDeep } from "lodash";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CheckIcon from "@mui/icons-material/Check";

import CommonSlideDialog from "../../../../common/CommonSlideDialog";
import theme from "../../../../constants/Theme";
import StarIcon from "../Assets/new-home-star.svg";
import ClockIcon from "../Assets/new-home-powerpack-clock.svg";
import ROUTES_PATH from "../../../../constants/routes";
import ScheduleMeetingDialog from "../../../Networking/ScheduleMeetingDialog";
import { USER_TYPES } from "../../../../utils/userTypes";
import services from "../../../../services";
import PaymentPlanDialog from "../../../PaymentPlanDialog";

export default function PowerPackDetailModal({ open, onClose, data }) {
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
	const { title, desc, items = [] } = data;

	const handleClose = () => {
		onClose();
	};

	const mainItems = cloneDeep(items);
	mainItems.shift();
	mainItems.pop();

	const SectionTitle = useMemo(() => {
		if (title === "Get Funded") {
			return "Review your assessment with StartupOS";
		} else if (title === "Build Your MVP") {
			return "Demo your MVP with StartupOS";
		}

		return "Practice your pitch with StartupOS";
	}, [title]);

	if (title === "Track Your Startup Health") {
		<TrackHealth />;
	}

	return (
		<CommonSlideDialog
			open={open}
			onClose={onClose}
			title={<Typography variant="Text/xl/Semibold">{title}</Typography>}
			maxWidth="md"
			PaperProps={{
				sx: {
					position: "fixed",
					bottom: 0,
					right: 0,
					m: 0,
					px: 3,
					py: 3,
					borderRadius: "30px",
					width: isSmallScreen ? "100%" : theme.breakpoints.values.md,
				},
			}}
			dialogAction={
				<Box display="flex" sx={{ pr: 3 }} alignItems="center">
					<Box>
						<Button
							sx={{
								borderRadius: "100px",
								background: "#000000",
								color: "#fff",
								height: "60px",
								width: "132px",
								fontSize: "18px",
								fontWeight: 600,
								"&:hover": {
									background: "#000000",
								},
							}}
							onClick={handleClose}
						>
							Close
						</Button>
					</Box>
				</Box>
			}
		>
			<Box display="flex" flexDirection="column" gap="30px" mt={2} mb={4}>
				<Box>
					<Typography variant="card_name" sx={{ display: "block", mb: "2px" }}>
						Overview
					</Typography>
					<Typography variant="label_xlarge">{desc}</Typography>
				</Box>
				{title === "Track Your Startup Health" ? (
					<TrackHealth />
				) : (
					<>
						<PowerPackSection index={1} title="Leverage our recommended tools" powerPacks={items.length > 0 ? [items[0]] : []} />
						{mainItems.length !== 0 && <PowerPackSection index={2} title="Complete our recommended PowerUp courses" powerPacks={mainItems} />}
						<PowerPackSection
							index={mainItems.length !== 0 ? 3 : 2}
							title={SectionTitle}
							powerPacks={items.length > 0 ? [items[items.length - 1]] : []}
						/>
					</>
				)}
			</Box>
		</CommonSlideDialog>
	);
}

const PowerPackSection = ({ index = 1, title, powerPacks = [] }) => {
	return (
		<Box display="flex" flexDirection="column" gap={powerPacks.length > 1 ? "0px" : "10px"}>
			<Box display="flex" alignItems="center" gap={3}>
				<Typography variant="poppinsSemiBold30">{`${index}.`}</Typography>
				<Typography variant="growth_progress_text">{title}</Typography>
			</Box>
			<Box>
				{powerPacks?.map((powerPack) => (
					<PowerPack data={powerPack} isSpacing={powerPacks.length > 1 ? true : false} />
				))}
			</Box>
		</Box>
	);
};

const PowerPack = ({ data, isSpacing }) => {
	const history = useHistory();
	const isAdmin = useSelector((store) => store?.auth?.isAdmin);
	const isPremiumPlanAccess = useSelector((store) => store?.auth?.isPremiumPlanAccess);
	const { title, shortDescription, cardStatus, cardType, cardProgressDetails, activityType, linkedEntityId, uuid, redirectUrl } = data;
	const [openScheduleMeetingDialog, setOpenScheduleMeetingDialog] = useState({ open: false, userInfo: null, type: null });
	const [showPlanModal, setShowPlanModal] = useState(false);

	useEffect(() => {
		if (openScheduleMeetingDialog.open) {
			const apiVersion = process.env.REACT_APP_API_URL;
			const USER_ID = apiVersion === "https://api.dev.startupos.com" ? 1688 : apiVersion === "https://api.stage.startupos.com" ? 887 : 4388;

			fetchUserData(USER_ID);
		}
	}, [openScheduleMeetingDialog.open]);

	const fetchUserData = async (userId) => {
		try {
			const userData = await services.getUserByCalUserIdApi(userId);
			const userTypes = getUserType(userData?.data?.data);

			setOpenScheduleMeetingDialog({ open: true, userInfo: userData?.data?.data, type: userTypes });
		} catch (error) {
			return null;
		}
	};

	const isSurvey = () => {
		return (
			title === "Net Promoter Score Survey" ||
			title === "Product Market Fit Survey" ||
			title === "Category Awareness Survey" ||
			title === "Likert Scale Survey" ||
			title === "Logo Testing Survey" ||
			title === "Ad Testing Survey"
		);
	};

	// TODO: not sure this is right since copied from WorkspaceCard
	const getSurveyId = () => {
		let pluginUuidData = uuid ? uuid : linkedEntityId;

		return pluginUuidData.replace("SURVEY_", "");
	};

	const getUserType = (userDetails) => {
		if (userDetails?.userTypes?.includes(USER_TYPES.MENTOR_USER)) {
			return USER_TYPES.MENTOR_USER;
		}
		if (userDetails?.userTypes?.includes(USER_TYPES.STARTUP_OWNER)) {
			return USER_TYPES.STARTUP_OWNER;
		}
		if (userDetails?.userTypes?.includes(USER_TYPES.INVESTOR_USER)) {
			return USER_TYPES.INVESTOR_USER;
		}
		if (userDetails?.isSaved) {
			return "SAVED";
		}
		if (userDetails?.isHidden) {
			return "HIDDEN";
		}
	};

	const handleCloseScheduleMeetingDialog = () => {
		setOpenScheduleMeetingDialog({ open: false, userInfo: null, type: null });
	};

	const handleClosePlanModal = () => {
		setShowPlanModal(false);
	};

	const handleRedirect = () => {
		if (cardType === "POWERUP_BUILDER") {
			if (cardStatus && cardStatus !== "COMING_SOON" && cardStatus !== "NOT_READY") {
				history.push({
					pathname: generatePath(ROUTES_PATH.WORKSPACE_POWERUP_BUILDER_DETAILS, {
						id: linkedEntityId,
					}),
				});
			}
		} else if (cardType === "POWERUP") {
			if (cardStatus && cardStatus !== "COMING_SOON" && cardStatus !== "NOT_READY") {
				history.push({
					pathname: generatePath(ROUTES_PATH.WORKSPACE_POWERUP_DETAILS, {
						id: linkedEntityId,
					}),
				});
			}
		} else if (cardType === "SURVEY") {
			if (title === "Price Sensitive Survey" || title === "Price Sensitivity Survey") {
				// do nothing
			} else if (title === "Mentor Checkin") {
				history.push({
					pathname: generatePath("/workspace/plugin/mentor-check-in"),
				});
			} else if (title === "Mentor Plugin: Fine Tuning" || title === "Fine Tuning") {
				history.push({
					pathname: generatePath("/workspace/plugin/mentor-check-in"),
				});
			} else if (title === "Idea Validation Interview" || title === "Market Test: Persona Interview" || title === "Market Test: Discovery Interview") {
				history.push({
					pathname: generatePath(ROUTES_PATH.MARKET_TEST, {
						id: linkedEntityId,
					}),
				});
			} else if (isSurvey(title)) {
				history.push({
					pathname: generatePath(ROUTES_PATH.TOOL_CONTROLLER, {
						id: getSurveyId(),
					}),
				});
			}
		} else if (cardType === "ACTIVITY") {
			if (activityType === "SCHEDULE_PITCH_SESSION") {
				if (isPremiumPlanAccess || isAdmin) {
					setOpenScheduleMeetingDialog({ open: true, userInfo: null, type: null });
				} else {
					setShowPlanModal(true);
				}
			} else {
				window.location.href = redirectUrl;
			}
		}
	};

	const getStatus = useMemo(() => {
		if (cardStatus === "COMING_SOON") {
			return "coming";
		} else if (activityType === "SCHEDULE_PITCH_SESSION" && cardType === "ACTIVITY") {
			return "schedule";
		} else if (cardStatus === "PUBLISHED" && cardType === "POWERUP") {
			if (cardProgressDetails?.taskStatus === "STARTED") {
				return "progress";
			} else if (cardProgressDetails?.taskStatus === "COMPLETED") {
				return "completed";
			} else {
				return "in-complete";
			}
		}

		return "progress";
	}, [activityType, cardProgressDetails?.taskStatus, cardStatus, cardType]);

	return (
		<Box ml={"48px"} py={isSpacing ? 3 : 0} sx={{ borderBottom: isSpacing ? "1px solid #ddd" : "none" }}>
			<Box display="flex" alignItems={"flex-end"} justifyContent="space-between" mb={0.5}>
				<Typography variant="Display/sm/Semibold">{title}</Typography>
				<Box>
					<BtnPowerPack status={getStatus} handleRedirect={handleRedirect} />
				</Box>
			</Box>
			{(cardType === "ACTIVITY" && activityType === "SCHEDULE_PITCH_SESSION") === false && (
				<Box my={1} display="flex" alignItems="center">
					{[0, 0, 0, 0, 0].map((item) => (
						<img src={StarIcon} alt="Star" />
					))}
				</Box>
			)}
			<Typography variant="StartupOS/subhead/subhead1Black">{shortDescription}</Typography>
			{openScheduleMeetingDialog?.open && openScheduleMeetingDialog?.userInfo && (
				<ScheduleMeetingDialog
					open={openScheduleMeetingDialog?.open}
					handleClose={handleCloseScheduleMeetingDialog}
					userInfo={openScheduleMeetingDialog?.userInfo}
					withUserType={openScheduleMeetingDialog?.type}
				/>
			)}
			{showPlanModal && <PaymentPlanDialog open={showPlanModal} isRedirect={false} onClose={handleClosePlanModal} />}
		</Box>
	);
};

const BtnPowerPack = ({ status, handleRedirect }) => {
	if (status === "completed") {
		return (
			<Button
				onClick={handleRedirect}
				sx={{
					borderRadius: "100px",
					background: "#F2F4F7 !important",
					color: "#344054 !important",
					"&:hover": { background: "#F2F4F7", color: "#344054" },
				}}
				endIcon={<CheckIcon sx={{ fontSize: 20, color: "#344054" }} />}
			>
				Completed
			</Button>
		);
	} else if (status === "in-complete") {
		return (
			<Button onClick={handleRedirect} sx={{ borderRadius: "100px", background: "#7B61FF" }} endIcon={<ArrowForwardIcon sx={{ fontSize: 20 }} />}>
				Complete Now
			</Button>
		);
	} else if (status === "coming") {
		return (
			<Button variant="text" onClick={handleRedirect} sx={{ borderRadius: "100px" }} endIcon={<img src={ClockIcon} alt="Coming Soon" />}>
				Coming Soon
			</Button>
		);
	} else if (status === "schedule") {
		return (
			<Button onClick={handleRedirect} sx={{ borderRadius: "100px", background: "#7B61FF" }} endIcon={<ArrowForwardIcon sx={{ fontSize: 20 }} />}>
				Schedule Now
			</Button>
		);
	}

	return (
		<Button onClick={handleRedirect} sx={{ borderRadius: "100px", background: "#7B61FF" }} endIcon={<ArrowForwardIcon sx={{ fontSize: 20 }} />}>
			In Progress
		</Button>
	);
};

const TrackHealth = () => {
	const history = useHistory();

	const handleRedirect = () => {
		history.push(ROUTES_PATH.STARTUPGRID);
	};

	return (
		<Box display="flex" flexDirection="column" gap={"10px"}>
			<Box>
				<Box display="flex" alignItems="center" gap={3} mb={2}>
					<Typography variant="poppinsSemiBold30">1</Typography>
					<Typography variant="growth_progress_text">Details about your Startup Grid</Typography>
				</Box>
				<Box>
					<Typography variant="Text/sm/Semibold" sx={{ display: "block", pb: 1 }}>
						CAP Table
					</Typography>
					<Typography variant="Text/sm/Regular" sx={{ display: "block" }}>
						A cap table (or capitalization table) is a document, like a spreadsheet or a table, that details who has ownership in a company.
						<br />
						<br />
						It lists all the securities or number of shares of a company. Common stock in a private company is generally directly issued to founders
						and early employees. <br />
						<br /> Preferred stock is primarily issued to investors when they finance funding rounds. It is considered less risky than common stock
						since preferred stockholders get priority on company assets over common stockholders. <br />
						<br /> A warrant is an agreement between two parties that gives one party the right to buy the other party’s stock at a set price, over
						a specified period of time. Once a warrant holder exercises their warrant, they get shares of stock in the issuing party’s company.
					</Typography>
					<Divider sx={{ my: 2 }} />
					<Typography variant="Text/sm/Semibold" sx={{ display: "block", pb: 1 }}>
						Customers
					</Typography>
					<Typography variant="Text/sm/Regular" sx={{ display: "block" }}>
						The Customers chart tracks the number of active, paying customers a company has over time. Similar to MRR growth accounting, customers
						are broken into categories for a given month x: <br />
						<br />
						Total Customers = Retained Customers + New Customers + Resurrected Customers, where: <br />
						<br />
						Retained Customers = Count of customers with revenue in month x and month x-1 <br />
						<br />
						New Customers = Count of customers with revenue in month but not in month x-1 <br />
						<br />
						Resurrected Customers = Count of customers who are not new and who had no revenue in month x-1
					</Typography>
					<Divider sx={{ my: 2 }} />
					<Typography variant="Text/sm/Semibold" sx={{ display: "block", pb: 1 }}>
						ARR
					</Typography>
					<Typography variant="Text/sm/Regular" sx={{ display: "block" }}>
						Annual Recurring Revenue (ARR) is the total annualized value of a company’s customer contracts. <br />
						<br />
						ARR = Sum of revenue from all customers x 12
					</Typography>
					<Divider sx={{ my: 2 }} />
					<Typography variant="Text/sm/Semibold" sx={{ display: "block", pb: 1 }}>
						Net New ARR
					</Typography>
					<Typography variant="Text/sm/Regular" sx={{ display: "block" }}>
						Net New ARR is how much total ARR a company adds in a period, net of churn. <br />
						<br /> Net New ARR for month x = ARR in month x - ARR in month (x-1)
					</Typography>
					<Divider sx={{ my: 2 }} />
					<Typography variant="Text/sm/Semibold" sx={{ display: "block", pb: 1 }}>
						MRR
					</Typography>
					<Typography variant="Text/sm/Regular" sx={{ display: "block" }}>
						Monthly Recurring Revenue (MRR) is the total monthly value of a company’s customer contracts. MRR applies to subscription SaaS
						businesses, but is sometimes also used to refer to monthly revenue for companies with relatively consistent monthly revenue, like usage
						or transaction based SaaS businesses. For a given month x: <br />
						<br />
						MRR = Sum of revenue from all customers in month x
					</Typography>
					<Divider sx={{ my: 2 }} />
					<Typography variant="Text/sm/Semibold" sx={{ display: "block", pb: 1 }}>
						CAC Payback Period
					</Typography>
					<Typography variant="Text/sm/Regular" sx={{ display: "block" }}>
						CAC Payback Period measures how many months it takes a cohort of customers to produce enough Gross Profit to pay back the sales and
						marketing expenses it took to acquire those customers.
					</Typography>
					<Divider sx={{ my: 2 }} />
					<Typography variant="Text/sm/Semibold" sx={{ display: "block", pb: 1 }}>
						{" "}
						Burn & Runway
					</Typography>
					<Typography variant="Text/sm/Regular" sx={{ display: "block" }}>
						Burn measures the change in a company’s cash balance in a month. <br />
						<br />
						Burn for month x = Cash balance in month x - cash balance in month x-1
						<br />
						<br />
						For quarterly and annual aggregations cash balance as of the end of the period is used, showing quarterly/annual burn figures. For
						example: <br />
						<br />
						Burn for quarter x = Cash balance in last month of quarter x - cash balance in last month of quarter x-1 <br />
						<br />
						Runway measure how long a company can continue to operate without running out of cash at current levels of burn. There are many reasons
						why burn may diverge from GAAP net income. For example, annual contracts with upfront payments create positive cash flow cycles, while
						loan products create negative ones. Analyzing runway on a cash basis reveals these cash flow patterns and helps companies ensure they
						have the reserves they need to survive.
					</Typography>
					<Divider sx={{ my: 2 }} />
					<Typography variant="Text/sm/Semibold" sx={{ display: "block", pb: 1 }}>
						Revenue
					</Typography>
					<Typography variant="Text/sm/Regular" sx={{ display: "block" }}>
						Revenue is the sum of revenue in a period across all customers. Revenue is an important KPI for usage/transaction based businesses.
						<br />
						<br />
						Revenue for month x = Sum of revenue for all customers in month x <br />
						<br />
						Revenue differs from MRR/ARR for quarterly and annual aggregations since all months in the period are summed, rather than looking at the
						last month of the period.
					</Typography>
					<Divider sx={{ my: 2 }} />
					<Typography variant="Text/sm/Semibold" sx={{ display: "block", pb: 1 }}>
						ACV
					</Typography>
					<Typography variant="Text/sm/Regular" sx={{ display: "block" }}>
						Average Contract Value (ACV) is the average ARR per active customer. For a given month x. ACV can also be looked at separately for new
						customers and retained customers to better understand how ACV is trending.
					</Typography>
					<Divider sx={{ my: 2 }} />
					<Typography variant="Text/sm/Semibold" sx={{ display: "block", pb: 1 }}>
						S&M Expense vs New Sales ARR
					</Typography>
					<Typography variant="Text/sm/Regular" sx={{ display: "block" }}>
						Comparing Sales & Marketing (S&M) spend to ARR from new customers helps show how efficiently S&M spend translates to new revenue.
						<br />
						<br /> New Sales ARR = Sum of revenue from new customers x 12 <br />
						<br /> S&M Expense = Inputted S&M
					</Typography>
				</Box>
			</Box>
			<Box display="flex" alignItems="center" justifyContent="space-between">
				<Box display="flex" alignItems="center" gap={3}>
					<Typography variant="poppinsSemiBold30">2</Typography>
					<Typography variant="growth_progress_text">Update your Startup Grid today</Typography>
				</Box>
				<Box>
					<Button onClick={handleRedirect} sx={{ borderRadius: "100px", background: "#7B61FF" }} endIcon={<ArrowForwardIcon sx={{ fontSize: 20 }} />}>
						Complete Now
					</Button>
				</Box>
			</Box>
		</Box>
	);
};
