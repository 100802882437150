import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import {
	Box,
	Chip,
	Typography,
	Divider,
	TextField,
	Avatar,
	AvatarGroup,
	Button,
	Link,
	Grid,
	CircularProgress,
	useMediaQuery,
	IconButton,
	Tooltip,
} from "@mui/material";
import { Person } from "@mui/icons-material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import CloseIcon from "@mui/icons-material/Close";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";

import LoadingButton from "../../../../../common/Loadingbutton";
import StartEndAdornmentField from "../../../../../common/StartEndAdornmentField";
import DialogComponent from "../../../../../common/CommonDialog";
import { sosPrimary } from "../../../../../constants/Theme/colors";
import CustomMuiChip from "../../../../../common/CustomMuiChip";
import { POWERUP_BG_ICON } from "../../../../../constants/image";
import { RICH_TEXT_EDITOR_INLINE_CONTROLS, RICH_TEXT_EDITOR_MAIN_CONTROLS } from "../../../../../constants/plugin-constants";
import messages from "../../../../../constants/messages";
import ROUTES_PATH from "../../../../../constants/routes";
import { REGEX } from "../../../../../constants/regex";
import { getCurrentDate } from "../../../../../utils/date";
import VideoPlayerComponent from "../../../../../common/videoPlayer";
import services from "../../../../../services";
import { toastContainer } from "../../../../../utils/toast";
import ScriptEditor from "../../../../../common/ScriptEditor";
import MessageHandlerModal from "../../../../../common/modal/MessageHandler";
import CuratorCardPopup from "../../../../CuratorCardPopup";
import { AddTagPopup } from "../../../../ResearchCurator/AddTag";
import AddReferencePopUp from "../../../../ResearchCurator/AddReferencePopUp";
import MetaLink from "../../../../MetaLink";
import { setAuthData } from "../../../../../modules/actions";
import { convertHtmlToPlainText } from "../../../../../utils/rte-utils";
import { handleMixPanel } from "../../../../../utils/mixPanelEventHandling";
import { USER_TYPES } from "../../../../../utils/userTypes";

const CreateResearchCurator = ({
	userDataObj,
	startupData,
	setOpenNewResearchCurator = () => {},
	setOpenResearchCuratorPanel = () => {},
	isExpandedView,
	researchCuratorId,
	isDetailView = false,
	getCardDetails,
	researchCuratorDetails,
	setNewCuratorId,
	callback,
	setCallback,
	handleDeleteNote,
	handleDeletePopup,
}) => {
	const [title, setTitle] = useState("");
	const [showEmbedLinkPopup, setShowEmbedLinkPopup] = useState(false);
	const [researchCuratorIdValue, setResearchCuratorIdValue] = useState(researchCuratorId);
	const [embedLinkLoader, setEmbedLinkLoader] = useState(false);
	const [linkId, setLinkId] = useState();
	const [linkText, setLinkText] = useState({ value: "", error: "" });
	const [linkUrl, setLinkUrl] = useState({
		value: "",
		error: "",
	});
	const [confirmPopup, setConfirmPopup] = useState(false);
	const [callBackFromMenu, setCallBackFromMenu] = useState(false);
	const [researchCuratorObj, setResearchCuratorObj] = useState({});
	const [version, setVersion] = useState(1); // maintaining version to re-render the script component after Reset to default action
	const [description, setDescription] = useState("");
	const [uploadedType, setUploadedType] = useState("");
	const [attachment, setAttachment] = useState("");
	const [fileLoading, setFileLoading] = useState(false);
	const [attachmentId, setAttachmentId] = useState("");
	const [openPopup, setOpenPopup] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const [openTag, setOpenTag] = useState(false);
	const [openReference, setOpenReference] = useState(false);
	const [selectedTags, setSelectedTags] = useState([]);
	const [selectedReferences, setSelectedReferences] = useState([]);
	const [detailsApiStatus, setDetailsApiStatus] = useState(false);
	const [createNoteApiStatus, setCreateNoteApiStatus] = useState(false);
	const [createdByUser, setCreatedByUser] = useState(true);
	const cursorPointer = { cursor: "pointer" };

	const userType = useSelector((store) => store?.auth?.userType);
	const dispatch = useDispatch();
	const history = useHistory();

	const handlePopupOpen = (event) => {
		setAnchorEl(event.currentTarget);
		setOpenPopup(true);
	};

	const handlePopupClose = (cb = false, id = null) => {
		setOpenPopup(false);
		if (cb) {
			getResearchCuratorDetails(id || researchCuratorIdValue);
		}
	};
	const handleOpenTag = () => {
		setOpenTag(!openTag);
		handlePopupClose();
	};

	const handleOpenReference = () => {
		setOpenReference(!openReference);
		handlePopupClose();
	};

	let FileType = [".png, .jpg, .jpeg, .tiff, .mp4, .MOV, .webm, .mpg, .mpeg, .pdf, .xlsx, .docx, .csv, .ppt, .pptx, .key, .xls"];

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		});
		if (researchCuratorIdValue) {
			getResearchCuratorDetails(researchCuratorIdValue);
		} else {
			setDetailsApiStatus(true);
		}
	}, []);

	useEffect(() => {
		if (callback) {
			getResearchCuratorDetails(researchCuratorIdValue);
		}
	}, [callback]);

	const handleDescriptionChange = (value) => {
		setDescription(value.value);
	};

	const handleBlur = (e, type) => {
		let eventValue = e.target.value;
		switch (type) {
			case "linkText":
				if (!eventValue.match(REGEX.LINK_TITLE)) {
					setLinkText((prevState) => ({
						...prevState,
						error: messages.LINK_TITLE_LENGTH_INVALID,
						value: eventValue,
					}));
				} else {
					setLinkText((prevState) => ({
						...prevState,
						value: eventValue,
						error: "",
					}));
				}
				break;
			case "linkUrl":
				if (!eventValue.match(REGEX.VALID_URL)) {
					setLinkUrl((prevState) => ({
						...prevState,
						error: messages.INVALID_URL,
						value: eventValue,
					}));
				} else {
					setLinkUrl((prevState) => ({
						...prevState,
						value: eventValue,
						error: "",
					}));
				}
				break;
		}
	};
	const handleApiCalls = (titleValue, type, descriptionValue) => {
		handleMixPanel("Discovery Board: PowerDot Saved");

		let payload = {
			noteType: "NOTE",
			title: titleValue !== null ? titleValue : title,
			description: descriptionValue !== null ? descriptionValue : description,
			isPinned: researchCuratorObj["isPinned"],
		};

		if (researchCuratorIdValue) {
			updateResearchCurator(payload);
		} else {
			createResearchCurator(payload);
		}
	};

	const handleInputChange = (e, type) => {
		let value = e.target.value;

		switch (type) {
			case "title":
				setTitle(value);
				break;
			case "linkText":
				setLinkText({ value: value, error: "" });
				break;
			default:
				setLinkUrl({ value: value, error: "" });
				break;
		}
	};
	const handleEditorData = (value) => {
		setDescription(value);
		setVersion(() => version + 1);
	};

	//Api to get research curator details
	const getResearchCuratorDetails = (researchCuratorId) => {
		services
			.getResearchCuratorDetailsApiCall(researchCuratorId)
			.then((response) => {
				if (response.data.message === "Success") {
					setResearchCuratorObj(response?.data?.data);
					setSelectedReferences(response?.data?.data?.references);
					if (!callBackFromMenu) {
						setTitle(response?.data?.data?.title);
						handleEditorData(response?.data?.data?.description);
						setCallBackFromMenu(false);
					}

					setResearchCuratorIdValue(researchCuratorId);
					setDetailsApiStatus(true);
					setLinkText({
						value: response?.data?.data?.links ? response?.data?.data?.links[0]?.displayText : "",
						error: "",
					});
					setLinkUrl({
						value: response?.data?.data?.links ? response?.data?.data?.links[0]?.url : "",
						error: "",
					});

					setLinkId(response?.data?.data?.links && response?.data?.data?.links[0]?.id);

					setAttachment(response?.data?.data?.attachments[0]);
					setAttachmentId(response?.data?.data?.attachments[0]?.id);
					setUploadedType(response?.data?.data?.attachments[0]?.fileType?.substr(0, response?.data?.data?.attachments[0]?.fileType?.indexOf("/")));
					setSelectedTags(response?.data?.data?.tags);
					setCallback(false);
					setCreatedByUser(response?.data?.data?.isCreatedByUser);
				}
			})
			.catch((e) => {
				console.log("Submit", e);
			});
	};

	//Api to create research curator
	const createResearchCurator = (payload, cb = null) => {
		if (!createNoteApiStatus) {
			setCreateNoteApiStatus(true);
			services
				.addResearchCuratorApiCall(payload)
				.then((response) => {
					if (response.data.message === "Success") {
						localStorage.setItem("researchCuratorId", response?.data?.data?.id);
						setCreateNoteApiStatus(false);
						handleMixPanel("PowerDot Created", {
							"PowerDot ID": response?.data?.data?.id,
						});

						setResearchCuratorObj(response?.data?.data);
						setResearchCuratorIdValue(response?.data?.data?.id);
						setNewCuratorId(response?.data?.data?.id);

						setLinkText({
							value: response?.data?.data?.links ? response?.data?.data?.links[0]?.displayText : "",
							error: "",
						});
						setLinkUrl({
							value: response?.data?.data?.links ? response?.data?.data?.links[0]?.url : "",
							error: "",
						});

						if (cb) {
							cb(response?.data?.data?.id);
						}
						if (!cb) {
							toastContainer("PowerDot saved", "success");
						}
					}
				})
				.catch((e) => {
					setCreateNoteApiStatus(false);

					console.log("Submit", e);
				});
		}
	};

	//Api to update research curator
	const updateResearchCurator = (payload) => {
		if (detailsApiStatus) {
			services
				.updateResearchCuratorApiCall(payload, researchCuratorIdValue)
				.then((response) => {
					if (response.data.message === "Success") {
						setResearchCuratorObj(response?.data?.data);
						setResearchCuratorIdValue(response?.data?.data?.id);

						setLinkText({
							value: response?.data?.data?.links[0]?.displayText,
							error: "",
						});
						setLinkUrl({
							value: response?.data?.data?.links[0]?.url,
							error: "",
						});
						toastContainer("PowerDot saved", "success");
					}
				})
				.catch((e) => {
					console.log("Submit", e);
				});
		}
	};

	//Api to add embed link details
	const handleEmbedLinkApiCall = () => {
		const exec = (id) => {
			let payload = {
				displayText: linkText.value,
				url: linkUrl.value,
			};
			setEmbedLinkLoader(true);
			services
				.addLinkToResearchCuratorApiCall(payload, id)
				.then((response) => {
					if (response.data.message === "Success") {
						setShowEmbedLinkPopup(false);
						setEmbedLinkLoader(false);
						setLinkId(response?.data?.data[0]?.id);
						setLinkText({
							value: response?.data?.data[0]?.displayText,
							error: "",
						});
						setLinkUrl({
							value: response?.data?.data[0]?.url,
							error: "",
						});
					}
				})
				.catch((e) => {
					setEmbedLinkLoader(false);
					console.log("Submit", e);
				});
		};

		if (researchCuratorIdValue) {
			exec(researchCuratorIdValue);
		} else {
			let payload = {
				noteType: "NOTE",
				title: title,
				description: description,
				isPinned: researchCuratorObj["isPinned"],
			};
			createResearchCurator(payload, exec);
		}
	};

	//Api to remove embed link details
	const handleRemoveEmbedLinkApiCall = () => {
		if (!isDetailView) {
			services
				.removeLinkFromResearchCuratorApiCall(researchCuratorIdValue, linkId)
				.then((response) => {
					if (response.data.message === "Success") {
						setLinkUrl("");
						setLinkText("");
					}
				})
				.catch((e) => {
					console.log("Submit", e);
				});
		}
	};
	const handleAddAttachement = (event) => {
		setFileLoading(true);
		var formData = new FormData();
		formData.append("file", event.target.files[0]);
		formData.append("fileGroup", "NOTE");
		let size = event.target.files[0].size;
		const fileSize = (size / (1024 * 1024 * 1024)).toFixed(2);
		if (fileSize > 2) {
			event.target.value = "";
			toastContainer("Please Upload file less than 2GB", "error");
			setFileLoading(false);
		} else {
			services
				.uploadFile(formData)
				.then((response) => {
					if (response.data.code === 200) {
						handleAddNotesAttachment(response.data.data);
						setUploadedType(response.data.data?.fileType.substr(0, response.data.data?.fileType.indexOf("/")));
						setFileLoading(false);
					}
				})
				.catch((e) => {
					console.log("File upload Error", e);
					toastContainer(e.response?.data?.message, "error");
					setFileLoading(false);
				});
		}
	};

	const handleRemoveFile = () => {
		setUploadedType("");
		setAttachment("");
	};

	const handleAddNotesAttachment = (data) => {
		const exec = (id) => {
			let payload = {
				fileLocation: data.fileLocation,
				fileName: data.fileName,
				fileType: data.fileType,
				size: data.size,
			};
			services
				.addNotesAttachment(payload, id)
				.then((response) => {
					if (response.data.code === 200) {
						setAttachment(response.data.data[0]);
						setAttachmentId(response.data.data[0].id);
					}
				})
				.catch((e) => {
					handleRemoveFile();
					console.log("Submit", e);
					toastContainer("File upload Error", "error");
				});
		};

		if (researchCuratorIdValue) {
			exec(researchCuratorIdValue);
		} else {
			let payload = {
				noteType: "NOTE",
				title: title,
				description: description,
				isPinned: researchCuratorObj["isPinned"],
			};
			createResearchCurator(payload, exec);
		}
	};

	const handleDeleteNotesAttachment = () => {
		services
			.deleteNotesAttachemnt(researchCuratorIdValue, attachmentId)
			.then((response) => {
				if (response.data.code === 200) {
					handleRemoveFile();
				}
			})
			.catch((e) => {
				console.log("Submit", e);
				toastContainer("Error in deleting file", "error");
			});
	};

	const getValidatedUrl = (link) => {
		let prefix = "https://";

		if (link.indexOf("http://") === 0 || link.indexOf("https://") === 0) {
			return link;
		} else {
			return prefix + link;
		}
	};

	const handleDownload = (attachment) => {
		fetch(attachment.fileLocation).then((response) => {
			response.blob().then((blob) => {
				let url = window.URL.createObjectURL(blob);
				let a = document.createElement("a");
				a.href = url;
				a.download = attachment.fileName;
				a.click();
			});
		});
	};

	return (
		<>
			<Box
				sx={{
					pb: isExpandedView ? 0 : 2,
				}}
			>
				<Box display="flex" justifyContent="flex-end" alignItems="center" mb={1} px={3}>
					<Box>
						<Button
							disabled={!title && !convertHtmlToPlainText(description)?.length > 0 && !linkUrl.value && !attachment}
							sx={{ width: "max-content", ml: 2 }}
							onClick={() => handleApiCalls(title, null, description)}
						>
							Save
						</Button>
						<Button variant="outlined" sx={{ width: "max-content", ml: 2 }} onClick={() => history.push(ROUTES_PATH.RESEARCH_CURATOR_EXPAND)}>
							Close
						</Button>
					</Box>
				</Box>
				{!isExpandedView && (
					<Box sx={{ display: "flex", p: 3 }} gap={2} justifyContent="right">
						<OpenInNewIcon
							sx={cursorPointer}
							onClick={() => {
								setOpenNewResearchCurator(false);
								setOpenResearchCuratorPanel(false);

								isDetailView
									? history.push(`${ROUTES_PATH.RESEARCH_CURATOR_NOTE.replace(":id", researchCuratorIdValue)}`)
									: history.push(
											researchCuratorIdValue
												? `${ROUTES_PATH.CREATE_RESEARCH_CURATOR}/${researchCuratorIdValue}`
												: ROUTES_PATH.CREATE_RESEARCH_CURATOR
									  );
								dispatch(setAuthData({ currentSelectedNavItem: "discovery-board" }));
							}}
						/>
						<CloseIcon
							sx={cursorPointer}
							onClick={() => {
								getCardDetails();
								setOpenNewResearchCurator(false);
							}}
						/>
					</Box>
				)}
				{isExpandedView ? "" : <Divider sx={{ background: (theme) => theme.palette.secondary.dark }} />}

				<Box sx={{ display: "flex", px: 3, pt: isExpandedView ? 0 : 3 }} alignItems="center">
					<TextField
						type="text"
						spellCheck="true"
						variant="standard"
						placeholder="Title"
						multiline
						minRows={1}
						sx={{
							pr: 2,
							width: 1,
						}}
						InputProps={{
							disableUnderline: true,
							readOnly: isDetailView,

							style: {
								fontSize: "22px",
								lineHeight: "28px",
								fontFamily: '"PoppinsMedium",  "Helvetica", "Arial", sans-serif',
							},
						}}
						value={title}
						onChange={(e) => handleInputChange(e, "title")}
						autoFocus
						//onFocus={e => {
						//  e.target.value === "" &&
						//    handleApiCalls(e.target.value, "title", null);
						//}}
					/>
					{!isDetailView &&
						researchCuratorIdValue && ( //createdByUser &&
							<MoreVertIcon sx={{ ml: "auto", cursor: "pointer" }} onClick={handlePopupOpen} />
						)}
				</Box>
				<Box sx={{ px: 3, pt: 1 }}>
					{isExpandedView ? (
						""
					) : researchCuratorObj?.company?.companyName !== startupData?.companyName && researchCuratorIdValue ? (
						<Link underline="none" variant="label_large" color={(theme) => theme.palette.secondary.subTitle}>
							{researchCuratorObj?.company?.companyName}
						</Link>
					) : (
						<Link
							href={ROUTES_PATH.MY_STARTUP_PROFILE}
							target="_blank"
							underline="hover"
							variant="label_large"
							color={(theme) => theme.palette.secondary.subTitle}
						>
							{researchCuratorObj?.company?.companyName ? researchCuratorObj?.company?.companyName : startupData?.companyName}
						</Link>
					)}

					<Typography variant="caption" sx={{ display: "grid", mt: 1 }}>
						Created {getCurrentDate()}
					</Typography>
					<Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
						<Typography variant="caption" sx={{ display: "grid" }}>
							Created by
						</Typography>
						<br />
						{userDataObj?.picture || researchCuratorObj?.userModel?.picture ? (
							<Avatar
								alt="picture"
								src={researchCuratorObj?.userModel?.picture ? researchCuratorObj?.userModel?.picture : userDataObj?.picture}
								sx={{ ml: 1, width: 28, height: 28 }}
							/>
						) : (
							<Avatar
								sx={{
									backgroundColor: (theme) => theme.palette.secondary.greyBg,
									color: (theme) => theme.palette.secondary.grey,
									ml: 1,
									width: 28,
									height: 28,
								}}
							>
								<Person />
							</Avatar>
						)}
						{researchCuratorObj?.userModel?.sharedEntries && createdByUser ? (
							""
						) : (
							<Typography variant="caption" sx={{ ml: "auto" }}>
								Last Saved {getCurrentDate(researchCuratorObj?.lastModifiedAt)}
							</Typography>
						)}

						<Divider />
					</Box>
					{researchCuratorObj?.userModel?.sharedEntries ? (
						<Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
							<Typography variant="caption" sx={{ display: "grid" }}>
								Shared with
							</Typography>

							<AvatarGroup max={3} sx={{ ml: 1 }}>
								{researchCuratorObj?.userModel?.sharedEntries?.map((item) => {
									return <Avatar alt="picture" src={item?.picture} sx={{ width: 28, height: 28 }} />;
								})}
							</AvatarGroup>
							{createdByUser && (
								<Typography variant="caption" sx={{ ml: "auto" }}>
									Last Saved {getCurrentDate(researchCuratorObj?.lastModifiedAt)}
								</Typography>
							)}
						</Box>
					) : (
						""
					)}

					<Divider sx={{ mt: 2 }} />
					<Box sx={{ pt: 2 }}>
						{researchCuratorObj?.references?.map((item) => {
							return (
								<Chip
									sx={(theme) => ({
										border: `1px solid  ${theme.palette.secondary.dark}`,
										mb: 1,
										mr: 1,
									})}
									avatar={<Avatar alt="powerup" src={POWERUP_BG_ICON} />}
									label={<Typography variant="label_large"> {item?.entityName}</Typography>}
									variant="outlined"
								/>
							);
						})}

						<Box
							sx={{
								display: "flex",
								gap: 1,
								flexWrap: "wrap",
							}}
						>
							{researchCuratorObj?.tags?.map((item) => {
								return (
									<Chip
										label={<Typography variant="label_large">{item?.tag?.name}</Typography>}
										variant="outlined"
										sx={(theme) => ({
											border: `1px solid  ${theme.palette.secondary.dark}`,
										})}
									/>
								);
							})}
						</Box>
					</Box>
					{researchCuratorObj?.tags?.length || researchCuratorObj?.references?.length ? <Divider sx={{ my: 2 }} /> : ""}

					<Typography variant="title_medium" display={"grid"}>
						Description
					</Typography>
					{isDetailView ? (
						<Box sx={{ mt: 2 }}>
							<ScriptEditor
								version={version}
								initialValue={description}
								onChange={(value) => handleDescriptionChange(value)}
								readOnly
								toolbar={false}
								inlineToolbar={false}
								inlineToolbarControls={RICH_TEXT_EDITOR_INLINE_CONTROLS}
								controls={RICH_TEXT_EDITOR_MAIN_CONTROLS}
							/>
						</Box>
					) : (
						<Box
							sx={(theme) => ({
								border: `1px solid ${theme.palette.secondary.dark}`,
								minHeight: "200px",
								px: 1,
								borderRadius: theme.shape.standard.borderRadius,
								mt: 2,
							})}
						>
							<ScriptEditor
								version={version}
								label={"Add text here..."}
								initialValue={description}
								onChange={(value) => handleDescriptionChange(value)}
								readOnly={false}
								toolbar={true}
								inlineToolbar={false}
								inlineToolbarControls={RICH_TEXT_EDITOR_INLINE_CONTROLS}
								controls={RICH_TEXT_EDITOR_MAIN_CONTROLS}
								minHeight={"200px"}
							/>
							<Grid container>
								<Grid item xs={12} md={8} lg={6}>
									{description && <MetaLink data={description} />}
								</Grid>
							</Grid>
						</Box>
					)}

					{/*{researchCuratorIdValue && (*/}
					<>
						{createdByUser ? (
							<>
								<Divider sx={{ mt: 3 }} />
								<Typography variant="title_medium" display={"grid"} sx={{ py: 2 }}>
									Attachments
								</Typography>
							</>
						) : (
							!createdByUser &&
							attachment && (
								<>
									<Divider sx={{ mt: 3 }} />
									<Typography variant="title_medium" display={"grid"} sx={{ py: 2 }}>
										Attachments
									</Typography>
								</>
							)
						)}
						{isExpandedView ? (
							<Grid container sx={{ display: "flex", flexDirection: "column" }}>
								{!attachment ? (
									!isDetailView &&
									createdByUser && (
										<Grid item lg={4} md={4} sm={10} xs={10}>
											<Button
												startIcon={fileLoading ? "" : <AttachFileIcon />}
												sx={{
													display: "flex",
													justifyContent: "center",
													maxWidth: "initial",
												}}
												component="label"
											>
												{fileLoading ? (
													<CircularProgress
														sx={{
															display: "flex",
															justifyContent: "center",
															maxWidth: "30px",
															px: 3,
															color: "secondary.white",
														}}
													/>
												) : (
													<>
														Attach file
														<input type="file" accept={FileType} hidden multiple={false} onChange={handleAddAttachement} />
													</>
												)}
											</Button>
										</Grid>
									)
								) : (
									<>
										<Grid container sx={{ display: "flex", flexDirection: "column" }}>
											<Grid item lg={4} md={4}>
												{/* Image */}
												{uploadedType === "image" && (
													<Box
														component="img"
														src={attachment?.fileLocation}
														sx={{
															display: "flex",
															maxWidth: 1,
															py: 1,
															objectFit: "contain",
															borderRadius: 8,
														}}
													/>
												)}
												{/* Video */}
												{uploadedType === "video" && (
													<Box sx={{ maxWidth: 1 }}>
														<VideoPlayerComponent videoUrl={attachment?.fileLocation} />
													</Box>
												)}
											</Grid>

											<Grid item sx={{ flexDirection: "row", display: "flex" }} spacing={0}>
												<Grid item lg={4} md={4} sm={10} xs={10}>
													<CustomMuiChip
														startIcon={
															<ImageOutlinedIcon
																sx={(theme) => ({
																	fill: theme.palette.primary.main,
																})}
															/>
														}
														endIcon={<CloseIcon style={{ marginLeft: "auto" }} />}
														label={
															<Typography
																variant="onsurface_subtext_medium"
																sx={(theme) => ({
																	color: theme.palette.secondary.main,
																})}
															>
																{`${attachment?.fileName} (${attachment?.size / 1000}K)`}
															</Typography>
														}
														disabled={isDetailView}
														onDelete={handleDeleteNotesAttachment}
													/>
												</Grid>
												<Grid
													item
													lg={1}
													md={1}
													sm={2}
													xs={2}
													sx={{
														display: "flex",
														justifyContent: "center",
														alignItems: "center",
														mt: 3,
													}}
												>
													<Box>
														<Tooltip title="Download attachment" placement="right">
															<IconButton
																sx={{
																	cursor: "pointer",
																	height: "max-content",
																}}
																onClick={() => handleDownload(attachment)}
															>
																<FileDownloadIcon />
															</IconButton>
														</Tooltip>
													</Box>
												</Grid>
											</Grid>
										</Grid>
									</>
								)}
								<Grid item lg={4} md={4} sm={10} xs={10}>
									{linkUrl.value ? (
										<CustomMuiChip
											startIcon={
												<InsertLinkIcon
													style={{
														color: sosPrimary[500],
													}}
												/>
											}
											endIcon={
												<CloseIcon
													style={{
														marginLeft: "auto",
														cursor: isDetailView ? "default" : "pointer",
													}}
												/>
											}
											label={
												<Link
													sx={{ cursor: "pointer" }}
													href={getValidatedUrl(linkUrl.value)}
													target="_blank"
													underline="hover"
													color={(theme) => theme.palette.secondary.subTitle}
												>
													{linkText.value ? linkText.value : linkUrl.value}
												</Link>
											}
											disabled={isDetailView}
											onDelete={() => {
												handleRemoveEmbedLinkApiCall();
											}}
										/>
									) : !isDetailView && createdByUser ? (
										<Button
											sx={{
												display: "flex",
												justifyContent: "center",
												maxWidth: "initial",
												mt: 3,
											}}
											startIcon={<InsertLinkIcon />}
											onClick={() => {
												setShowEmbedLinkPopup(true);
											}}
										>
											Embed link
										</Button>
									) : (
										""
									)}
								</Grid>
							</Grid>
						) : (
							<Box>
								{!attachment ? (
									!isDetailView && (
										<Button
											startIcon={fileLoading ? "" : <AttachFileIcon />}
											sx={{
												display: "flex",
												justifyContent: "center",
												maxWidth: "initial",
											}}
											component="label"
										>
											{fileLoading ? (
												<CircularProgress
													sx={{
														display: "flex",
														justifyContent: "center",
														maxWidth: "30px",
														px: 3,
														color: (theme) => theme.palette.secondary.white,
													}}
												/>
											) : (
												<>
													Attach file
													<input type="file" accept={FileType} hidden multiple={false} onChange={handleAddAttachement} />
												</>
											)}
										</Button>
									)
								) : (
									<>
										{/* Image */}
										{uploadedType === "image" && (
											<Box
												component="img"
												src={attachment?.fileLocation}
												sx={{
													display: "flex",
													maxWidth: 1,
													py: 1,
													objectFit: "contain",
													borderRadius: 8,
												}}
											/>
										)}
										{/* Video */}
										{uploadedType === "video" && (
											<Box sx={{ maxWidth: 1 }}>
												<VideoPlayerComponent videoUrl={attachment?.fileLocation} />
											</Box>
										)}
										<CustomMuiChip
											startIcon={
												<ImageOutlinedIcon
													sx={(theme) => ({
														fill: theme.palette.primary.main,
													})}
												/>
											}
											endIcon={<CloseIcon style={{ marginLeft: "auto" }} />}
											label={
												<Typography
													variant="onsurface_subtext_medium"
													sx={(theme) => ({
														color: theme.palette.secondary.main,
													})}
												>
													{`${attachment?.fileName} (${attachment?.size / 1000}K)`}
												</Typography>
											}
											onDelete={handleDeleteNotesAttachment}
										/>
									</>
								)}
								{linkUrl.value ? (
									<CustomMuiChip
										startIcon={
											<InsertLinkIcon
												style={{
													color: sosPrimary[500],
												}}
											/>
										}
										endIcon={
											<CloseIcon
												style={{
													marginLeft: "auto",
													cursor: isDetailView ? "default" : "pointer",
												}}
											/>
										}
										label={
											<Link
												sx={{ cursor: "pointer" }}
												href={getValidatedUrl(linkUrl.value)}
												target="_blank"
												underline="hover"
												color={(theme) => theme.palette.secondary.subTitle}
											>
												{linkText.value ? linkText.value : linkUrl.value}
											</Link>
										}
										disabled={isDetailView}
										onDelete={() => {
											handleRemoveEmbedLinkApiCall();
										}}
									/>
								) : !isDetailView ? (
									<Button
										sx={{
											display: "flex",
											justifyContent: "center",
											maxWidth: "initial",
											mt: 3,
										}}
										startIcon={<InsertLinkIcon />}
										onClick={() => {
											setShowEmbedLinkPopup(true);
										}}
									>
										Embed link
									</Button>
								) : (
									""
								)}
							</Box>
						)}
					</>
					{/*)}*/}

					<Divider sx={{ pt: 2 }} />
				</Box>
			</Box>
			<Box>
				<DialogComponent
					isOpen={showEmbedLinkPopup}
					onClose={() => {
						setConfirmPopup(true);
					}}
					title={"Embed Link"}
					titleVariant={"title_large"}
					contentStyle={{ px: 0 }}
					maxWidth={"sm"}
					fullWidth
					PaperProps={{
						sx: (theme) => ({ borderRadius: theme.shape.standard.borderRadius }),
					}}
					dialogAction={
						<Box
							sx={(theme) => ({
								mr: 2,
								display: "flex",
								[theme.breakpoints.down("md")]: {
									mr: 0,
									display: "contents",
								},
							})}
						>
							<Box item sx={{ display: "flex" }}>
								<Button
									variant="outlined"
									sx={{ mr: 1 }}
									onClick={() => {
										setConfirmPopup(true);
									}}
								>
									Cancel
								</Button>
								<LoadingButton
									loadingStyles={{ px: 3 }}
									loading={embedLinkLoader}
									disabled={linkText.error || linkUrl.error || !linkUrl.value}
									onClick={() => {
										handleEmbedLinkApiCall();
									}}
								>
									Save
								</LoadingButton>
							</Box>
						</Box>
					}
					disableBackDropClick
				>
					<Box sx={{ p: 3 }}>
						<StartEndAdornmentField
							startIcon={<FormatListBulletedIcon />}
							endIcon={<HighlightOffIcon />}
							label={"Text to display"}
							value={linkText.value}
							onChange={(e) => setLinkText({ value: e.target.value })}
							EndIconAction={() => setLinkText({ value: "" })}
							error={linkText.error}
							onBlur={(e) => {
								handleBlur(e, "linkText");
							}}
							isExpandedView={isExpandedView}
						/>
						<Box sx={{ mt: 3 }}>
							<StartEndAdornmentField
								startIcon={<InsertLinkIcon />}
								endIcon={<HighlightOffIcon />}
								label={"Link to URL"}
								value={linkUrl.value}
								onChange={(e) => setLinkUrl({ value: e.target.value })}
								EndIconAction={() => setLinkUrl({ value: "" })}
								error={linkUrl.error}
								onBlur={(e) => {
									handleBlur(e, "linkUrl");
								}}
								isExpandedView={isExpandedView}
							/>
						</Box>
					</Box>
				</DialogComponent>
			</Box>

			<MessageHandlerModal
				isOpen={confirmPopup}
				onCancel={() => setConfirmPopup(false)}
				onOk={() => {
					setShowEmbedLinkPopup(false);
					setConfirmPopup(false);
					setLinkUrl("");
					setLinkText("");
				}}
				okText="Discard"
				cancelText={"No thanks"}
				heading="Discard changes"
				text={"Are you sure you want to discard your changes?"}
				messageType="primary"
			/>
			<CuratorCardPopup
				open={openPopup}
				onClose={handlePopupClose}
				anchorEl={anchorEl}
				handleDeleteNote={handleDeleteNote}
				handleOpenTag={handleOpenTag}
				handleOpenReference={handleOpenReference}
				curatorId={researchCuratorId}
				handleDeletePopup={handleDeletePopup}
				researchCuratorDetails={researchCuratorDetails}
				cardData={researchCuratorObj}
			/>
			<AddTagPopup
				open={openTag}
				onClose={handleOpenTag}
				noteId={researchCuratorIdValue}
				selectedTags={selectedTags}
				setCallback={setCallback}
				setResearchCuratorIdValue={setResearchCuratorIdValue}
				setCallBackFromMenu={setCallBackFromMenu}
			/>
			<AddReferencePopUp
				open={openReference}
				onClose={handleOpenReference}
				noteId={researchCuratorIdValue}
				selectedReferences={selectedReferences}
				setCallback={setCallback}
				setResearchCuratorIdValue={setResearchCuratorIdValue}
				setCallBackFromMenu={setCallBackFromMenu}
			/>
		</>
	);
};

export default CreateResearchCurator;
