import React from "react";
import { Menu, MenuItem, Typography, Box } from "@mui/material";
import { useDispatch } from "react-redux";
import { setFeedData } from "../../modules/actions";
import { DELETE_ICON_V1, EDIT_V1_ICON } from "../../constants";

const EditOrDeleteMwnuComponent = ({ isOpen, anchorEl, onClose, commentObj, setAnchorEl }) => {
	const dispatch = useDispatch();

	const handleEditComment = () => {
		setAnchorEl("");
		dispatch(
			setFeedData({
				editOrDeletePopUpOpen: true,
				currentCommentId: commentObj.id,
				detailsCommentObject: commentObj,
				commentOperationType: "edit",
			})
		);
	};
	const handleDeleteComment = () => {
		setAnchorEl("");
		dispatch(
			setFeedData({
				editOrDeletePopUpOpen: true,
				currentCommentId: commentObj.id,
				detailsCommentObject: commentObj,
				commentOperationType: "delete",
			})
		);
	};
	return (
		<Menu
			open={isOpen}
			anchorEl={anchorEl}
			onClose={onClose}
			elevation={2}
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "right",
			}}
			transformOrigin={{
				vertical: "top",
				horizontal: "right",
			}}
			PaperProps={{
				style: {
					borderRadius: "20px",
					width: "120px",
					display: "flex",
				},
			}}
			sx={{
				mt: 1,
				border: "none",
			}}
		>
			<MenuItem sx={{ width: "120px", display: "flex", gap: 1, height: "40px" }} onClick={handleEditComment}>
				<Box component={"img"} sx={{ width: "14px", height: "14px" }} src={EDIT_V1_ICON} alt="edit" />
				<Typography variant="label_large">Edit</Typography>
			</MenuItem>
			<MenuItem sx={{ width: "120px", display: "flex", gap: 1, height: "40px" }} onClick={handleDeleteComment}>
				<Box sx={{ width: "14px", height: "14px" }} component={"img"} src={DELETE_ICON_V1} alt="edit" />
				<Typography variant="label_large">Delete</Typography>
			</MenuItem>
		</Menu>
	);
};

export default EditOrDeleteMwnuComponent;
