import React from "react";
import { Box, Divider, Typography } from "@mui/material";

import { StepTitle } from "../ui";
import Icon from "../../Assets/onboarding-about.svg";

export const QuickMsg = () => {
	return (
		<Box>
			<Divider sx={{ borderColor: "#D0D5DD", mb: "25px" }} />
			<StepTitle title="Quick Message" icon={Icon} tooltip="" />
			<Typography variant="Text/sm/Regular">
				Hey investor! You wil need to be verified before you have full access to the Opportunity Dashboard. For now, you will have access to limited
				features (profile, events and more) until you have been fully verified. We will be in touch soon!
			</Typography>
		</Box>
	);
};
