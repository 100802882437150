import React, { useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import StartupPublicBioCard from "../../../components/PublicProfile/StartupPublicProfile/StatupPublicBioCard";
import StartupPublicAboutStoryCard from "../../../components/PublicProfile/StartupPublicProfile/StartupPublicAboutStoryCard";
import StartupPublicPitchDeckCard from "../../../components/PublicProfile/StartupPublicProfile/StartupPublicPitchDeckCard";
// import { GetCompanyDataQueryCall } from "../Functions/common";
import { useLocation } from "react-router-dom";
import { getCompanyPublicDataApiCall } from "../Functions/common";
import { setStartupPublicProfileData } from "../../../modules/actions/publicProfileActions";
import { useDispatch } from "react-redux";
import { toastContainer } from "../../../utils/toast";

const StartupPublicProfile = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const uuid = `${location.pathname.slice(9)}`;

	useEffect(() => {
		dispatch(
			setStartupPublicProfileData({
				loading: true,
			})
		);
		getCompanyPublicDataApiCall(uuid)
			.then((response) => {
				if (response?.data?.code === 200) {
					dispatch(
						setStartupPublicProfileData({
							loading: false,
							startupPublicData: response?.data?.data,
						})
					);
				} else if (response?.response?.data?.code === 429) {
					dispatch(
						setStartupPublicProfileData({
							loading: false,
							startupPublicData: null,
						})
					);
					toastContainer("Too many requests.", "error");
				} else {
					console.log("error");
				}
			})
			.catch((err) => {
				dispatch(
					setStartupPublicProfileData({
						loading: false,
					})
				);
				console.log(err);
			});
	}, []);

	// if (uuid) {
	// 	GetCompanyDataQueryCall(uuid);
	// }

	return (
		<Box
			sx={(theme) => ({
				width: "100%",
				position: "relative",
				margin: "auto",
				maxWidth: theme.breakpoints.values.xl,
				my: 4,
			})}
		>
			<Grid container spacing={4}>
				<Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
					<StartupPublicBioCard />
				</Grid>
				<Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
					<StartupPublicAboutStoryCard />
				</Grid>
				<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
					<StartupPublicPitchDeckCard />
				</Grid>
			</Grid>
		</Box>
	);
};

export default StartupPublicProfile;
