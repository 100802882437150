import React from "react";
import BILineChart from "./BILineChart/BILineChart";
import { Box, Typography, useTheme, CircularProgress } from "@mui/material";
import ChartCard from "./ChartCard";
import { get } from "lodash";
import { convertNumberToLocale, getGradientBgColor } from "../Functions/common";
import ZeroLineChart from "./BILineChart/EmptyLineChart";
import DisplayLastestParams from "./DisplayLastestParams";
import { useSelector } from "react-redux";
import { MRR_BENCHMARKS } from "../../../../constants";

function MrrChartContainer({ onChartClick, data, loading, readOnly }) {
	const theme = useTheme();
	const chartData = get(data, "data", []);

	const latestMonthlyRevenue = get(data, "latestMonthlyRevenue", null);
	const momGrowth = get(data, "momGrowth", null);

	const latestRetainedMrr = get(data, "latestRetainedMrr", null);
	const latestNewSalesMrr = get(data, "latestNewSalesMrr", null);
	const latestResurrectedMrr = get(data, "latestResurrectedMrr", null);
	const latestExpansionMrr = get(data, "latestExpansionMrr", null);
	const latestContractionMrr = get(data, "latestContractionMrr", null);
	const latestChurnedMrr = get(data, "latestChurnedMrr", null);
	const companyStage = useSelector((store) => store?.biDashboard?.currentStage);

	const latestArributes = {
		Retained: latestRetainedMrr,
		Resurrected: latestResurrectedMrr,
		"New Sales": latestNewSalesMrr,
		Expansion: latestExpansionMrr,
		Contraction: latestContractionMrr,
		Churned: latestChurnedMrr,
	};

	return (
		<ChartCard
			title={"MRR"}
			subheader={latestMonthlyRevenue !== null && !loading ? `$${convertNumberToLocale(latestMonthlyRevenue)}` : "0"}
			backgroundColor={theme.palette.secondary.white}
			onChartClick={() => onChartClick("monthlyRecurringRevenue", data)}
			momGrowth={momGrowth !== null ? `${convertNumberToLocale(momGrowth)}%` : "N/A"}
			toolTipBody={
				<>
					<Typography variant="Text/sm/Regular">
						Monthly Recurring Revenue (MRR) is the total monthly value of a company’s customer contracts. MRR applies to subscription SaaS
						businesses, but is sometimes also used to refer to monthly revenue for companies with relatively consistent monthly revenue, like usage
						or transaction based SaaS businesses. For a given month x:
					</Typography>
					<Typography variant="Text/sm/Regular" sx={{ fontStyle: "italic" }}>
						MRR = Sum of revenue from all customers in month x
					</Typography>
				</>
			}
			data={data?.data}
		>
			{loading ? (
				<Box sx={{ alignSelf: "center" }}>
					<CircularProgress />
				</Box>
			) : chartData.length === 0 ? (
				<ZeroLineChart readOnly={readOnly} />
			) : (
				<>
					<Box sx={{ mb: 3 }}>
						<DisplayLastestParams latestArributes={latestArributes} type="currency" />
					</Box>
					<Box sx={{ position: "relative", width: "100%", mt: "auto" }}>
						<BILineChart
							data={chartData}
							tooltipYLable={"Total MRR"}
							yAxisKey={"totalMrr"}
							yAxisType="currency"
							lineBorderColor={theme.palette.primary.main}
							lineBackgroundColor={(context) => {
								const ctx = context.chart.ctx;
								return getGradientBgColor(ctx, context);
							}}
							datasets={[
								{
									label: `${companyStage} Benchmark`,
									data:
										chartData?.map((dataItem) => ({
											id: get(dataItem, "id", null),
											date: get(dataItem, "date", null),
											benchamark: MRR_BENCHMARKS[companyStage],
										})) || [],
									borderWidth: 2,
									borderColor: theme.palette.bidashboard.paramLightBlue,
									tension: 0.15,
									parsing: {
										xAxisKey: "date",
										yAxisKey: "benchamark",
									},
									pointRadius: 0,
									pointHoverRadius: 3,
									clip: false,
									borderDash: [5, 5],
								},
							]}
						/>
					</Box>
				</>
			)}
		</ChartCard>
	);
}

export default MrrChartContainer;
