import React, { Component } from "react";
import { connect } from "react-redux";

import { Stack, Grid, Box } from "@mui/material";
import "./emailVerification.css";
import ROUTES_PATH from "../../constants/routes";
import sampleData from "../../constants/text";
import {
  getPrimaryAccountId,
  getVerificationType,
} from "../../utils/sessionStorage";
import { authWebLogout, passwordReset } from "../../utils/auth0";
import services from "../../services";
import { toastContainer } from "../../utils/toast";
import messages from "../../constants/messages";
import * as actions from "../../modules/actions";
import { logout } from "../../utils/auth0-spa";
import { VerifyEmail } from "../../components/Onboarding/VerifyEmail";
import { LOGOICON } from "../../constants/image";

class EmailVerification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPath: "",
      email: "",
      isAuthenticated: false,
      loading: false,
      userDataEmail: "",
    };
  }
  componentDidMount() {
    this.setState({
      currentPath: this.props.location.pathname.split("/")[1],
      email: sessionStorage.getItem("email") || this.props.userData?.email,
      isAuthenticated: !!this.props.idToken,
      userDataEmail: this.props.userData?.email,
    });

    window.scrollTo(0, 0);
  }

  handleBackClick() {
    let { currentPath } = this.state;

    currentPath === "resend-email"
      ? this.props.history.push(ROUTES_PATH.FORGOT_PASSWORD)
      : this.props.history.push(ROUTES_PATH.CREATE_ACCOUNT);
  }

  handleConfirm = async () => {
    const { email, isAuthenticated, userDataEmail } = this.state;
    this.setState({ loading: true });
    if (isAuthenticated) {
      const data = this.props.userData;
      await services
        .resendEmail({ email: userDataEmail, userId: data?.sub })
        .then((resp) => {
          this.setState({ loading: false });
          toastContainer(messages.VERIFICATION_EMAIL, "success");
        })
        .catch(err => {
          this.setState({ loading: false });
          console.log(err);
        });
    } else if (getVerificationType() === "forgotPassword") {
      passwordReset({
        email,
      })
        .then(() => {
          this.setState({ loading: false });
          toastContainer(messages.RESET_PASSWORD, "success");
        })
        .catch(err => {
          this.setState({ loading: false });
          console.log(err);
        });
    } else {
      //resend verification email to userId using auth0 management API
      const userId = getPrimaryAccountId();

      services
        .resendEmail({ email, userId })
        .then(() => {
          this.setState({ loading: false });
          toastContainer(messages.VERIFICATION_EMAIL, "success");
        })
        .catch(err => {
          this.setState({ loading: false });
          console.log(err);
        });
    }
  };

  onLgoutHandler = async () => {
    await logout();
    await authWebLogout();
  };

  render() {
    let { currentPath, email, isAuthenticated } = this.state;
    return (
      <>
        {/*<Box
          sx={{
            display: "flex",
            width: 1,
            justifyContent: "center",
            alignItems: "center",
            mt: 3,
            cursor: "pointer",
          }}
          onClick={() =>
            (window.location.href = process.env.REACT_APP_HOME_URL)
          }
        >
          <Box component="img" src={LOGOICON} />
        </Box>*/}

        <Grid
          className="signup-grid-container h-80"
          container
          alignItems="center"
          justifyContent="center"
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={8}
            className="form-container"
            sx={{
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              alignContent: "center",
              flexDirection: "column",
              gap: 2,
              mt: 9,
            }}
          >
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{ minHeight: "100%" }}
            >
              <VerifyEmail
                isAuthenticated={this.state.isAuthenticated}
                currentPath={currentPath}
                sampleData={sampleData}
                email={email}
                loading={this.state.loading}
                handleConfirm={this.handleConfirm}
              />
            </Stack>
          </Grid>
        </Grid>
      </>
    );
  }
}

const mapStoreToProps = ({ auth }) => ({
  idToken: auth.idToken,
  userData: auth.userData,
  auth: auth,
});

const mapDispatchToProps = dispatch => ({
  setAuthData: updatedData => dispatch(actions.setAuthData(updatedData)),
});

export default connect(mapStoreToProps, mapDispatchToProps)(EmailVerification);
