import styled from "@emotion/styled";
import { sosPrimary } from "../../../constants/Theme/colors";

export const StreakContainer = styled.div(({ theme }) => {
  return {
    width: "20px",
    height: "28px",
    background: sosPrimary[50],
    borderRadius: theme.shape.standard_small.borderRadius,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
});

export const ProgressInnerValue = styled.div(({ theme }) => {
  return {
    background: `linear-gradient(92.58deg, ${theme.palette.secondary.progressGradient2} 0%, ${theme.palette.secondary.progressGradient1} 100%)`,
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
  };
});
