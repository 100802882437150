import React from "react";
import { DialogTitle, Dialog, IconButton, Typography, DialogContent, DialogActions, Slide, Box, Button, useTheme } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import LoadingButton from "../../../common/Loadingbutton";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="left" ref={ref} {...props} />;
});

export default function CopyFromLinkedinConfirmation({ handleGetHarmonicData, handleCloseConfirmation, isLoading, open }) {
	const theme = useTheme();
	const disableBackDropClick = true;
	const title = "Copy profile from Linkedin";
	const subTitle =
		"Continuing to copy data from Linkedin will replace all data in your Profile Intro, Experience, and Education area of the screen. It will not replace Headline, About and Media data loaded: Video, Images, etc.";
	const primaryButtonText = "Continue";
	const secondaryButtonText = "Cancel";
	const primaryButtonAction = handleGetHarmonicData;
	const primaryButtonVariant = "PurpleRounded";
	const secondaryButtonAction = handleCloseConfirmation;
	const secondaryButtonVariant = "noOutlineNew";
	const primaryButtonLoading = isLoading;

	const transitionDuration = 600;

	const handleClose = () => {
		handleCloseConfirmation();
	};

	const FooterComponent = () => {
		return (
			<>
				{
					<Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
						<Box>
							<Button variant={secondaryButtonVariant} onClick={secondaryButtonAction} sx={{ width: "max-content", whiteSpace: "nowrap" }}>
								{secondaryButtonText}
							</Button>
						</Box>

						<LoadingButton
							loadingStyles={{
								height: "60px",
								px: 4,
							}}
							loading={primaryButtonLoading}
							variant={primaryButtonVariant}
							sx={{ mr: 1 }}
							onClick={primaryButtonAction}
						>
							{primaryButtonText}
						</LoadingButton>
					</Box>
				}
			</>
		);
	};
	return (
		<Dialog
			TransitionComponent={Transition}
			transitionDuration={transitionDuration}
			aria-describedby="alert-dialog-slide-description"
			keepMounted
			open={open}
			onClose={disableBackDropClick ? () => {} : handleClose}
			PaperProps={{
				sx: (theme) => ({
					borderRadius: theme.shape.standard13.borderRadius,
					boxShadow: "0px 5px 16px rgba(0, 0, 0, 0.3)",
					p: 3,
				}),
			}}
		>
			{title && (
				<DialogTitle
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<Typography sx={{ lineHeight: "initial" }} variant={"poppinsSemiBold20"}>
						{title}
					</Typography>
					{
						<IconButton
							aria-label="close"
							onClick={handleClose}
							sx={{
								color: theme.palette.secondary.dark,
							}}
						>
							<CloseIcon sx={{ color: (theme) => theme.palette.secondary.black }} />
						</IconButton>
					}
				</DialogTitle>
			)}
			<DialogContent>
				<Box sx={{ pt: 2 }}>
					<Typography variant="title_medium">{subTitle}</Typography>
				</Box>
			</DialogContent>
			{
				<DialogActions>
					<FooterComponent />
				</DialogActions>
			}
		</Dialog>
	);
}
