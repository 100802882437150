import React from "react";
import { Box, Typography } from "@mui/material";

export const OnboardContainer = ({ title, children }) => {
	return (
		<Box
			sx={(theme) => ({
				background: "#FFFFFF",
				boxShadow: "0px 10px 85px rgba(0, 0, 0, 0.09)",
				borderRadius: "50px",
				mx: "240px",
				p: "60px",
				[theme.breakpoints.down("xl")]: {
					mx: "140px",
				},
				[theme.breakpoints.down("lg")]: {
					mx: "100px",
				},
				[theme.breakpoints.down("md")]: {
					mx: "80px",
					p: "60px 40px",
				},
				[theme.breakpoints.down("sm")]: {
					mx: "10px",
					p: "60px 30px",
				},
			})}
		>
			<Typography variant="poppinsSemiBold30">{title}</Typography>
			<Box mt="40px">{children}</Box>
		</Box>
	);
};
