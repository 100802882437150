import React, { useState, useRef } from "react";
import { Grid, Box, Typography, Stack } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import { REGEX } from "../../constants/regex";
import services from "../../services";
import { toastContainer } from "../../utils/toast";
import { manualLogin } from "../../utils/auth0";
import TextInput from "../../common/input/Input";
import LoadingButton from "../../common/Loadingbutton";

export const AdminLogin = () => {
  const textFieldForUsernameRef = useRef(null);
  const textFieldForPasswordRef = useRef(null);
  const [disabled, setDisabled] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [loginLoading, setLoginLoading] = useState(false);

  const [passwordError, setPasswordError] = useState(false);
  const [isUserDeactivated, setIsUserDeactivated] = useState(false);

  const validateEmail = value => {
    if (REGEX.EMAIL.test(value)) {
      return true;
    }
    return false;
  };

  const handleEmailInputChange = event => {
    setIsUserDeactivated(false);

    setEmail(event);
    if (validateEmail(event)) {
      setEmailError(false);
    } else {
      setEmailError(true);
    }
  };

  const handleAuth0Login = async data => {
    try {
      await manualLogin(data);
    } catch (err) {
      this.setState({ loginLoading: false });
      console.log(err);
      toastContainer(err?.error_description, "error");
    }
  };

  const handlePasswordInputChange = event => {
    setPassword(event);

    if (event) {
      setPasswordError(false);
    } else {
      setPasswordError(false);
    }
  };

  const handleRememberMe = event => {
    setRememberMe(event.target.checked);
  };

  const handleLogin = () => {
    if (validateEmail(email) && password) {
      setPasswordError(false);
      setEmailError(false);
      setLoginLoading(true);

      try {
        services
          .getControlRoleAccessApi(email)
          .then(result => {
            if (result.data?.code === 200) {
              let resultData = result?.data;

              if (
                resultData?.data?.companyModel?.entityStatus === "DEACTIVE" &&
                resultData?.data?.emailVerified
              ) {
                setIsUserDeactivated(true);
                setLoginLoading(false);
              } else {
                setIsUserDeactivated(false);

                handleAuth0Login({ email, password });
              }
            }
          })
          .catch(error => {
            console.log(error);
            setLoginLoading(false);
            toastContainer(error?.response?.data?.message, "error");
          });
        //call the auth0 /authorize api
      } catch (err) {
        console.log(err);
        setLoginLoading(false);
        toastContainer(err?.error_description, "error");
      }
    } else {
      if (!validateEmail(email)) {
        setEmailError(true);
      }
      if (!password) {
        setPasswordError(true);
      }
    }
  };

  return (
    <Stack alignItems="center" justifyContent="center" sx={{ minHeight: 1 }}>
      <Grid
        className="signup-grid-container"
        container
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={12} sm={10} md={6} lg={6} className="form-container">
          <div className="signup-title">Admin</div>
          {isUserDeactivated ? (
            <>
              <br></br>
              <Typography variant="body_large" style={{ fontSize: "15px" }}>
                Thank you for onboarding! Please check your email for your
                liftoff date.
                <br />
                <br />
                On either October 25th or November 30th, you will have access to
                log in and begin your StartupOS journey
                <strong>—Stay tuned!</strong>
              </Typography>
            </>
          ) : (
            ""
          )}

          <TextInput
            type="text"
            label="Email address"
            value={email}
            spellCheck="true"
            error={emailError ? "Please enter valid email address" : ""}
            onChange={e => handleEmailInputChange(e)}
            disabled={disabled}
            inputRef={textFieldForUsernameRef}
            inputProps={{
              onKeyPress: event => {
                const { key } = event;
                if (key === "Enter") {
                  textFieldForPasswordRef.current.focus();
                }
              },
            }}
          />
          <TextInput
            type="password"
            label="Password"
            value={password}
            onChange={e => handlePasswordInputChange(e)}
            error={passwordError ? "Please enter a password" : ""}
            disabled={disabled}
            inputRef={textFieldForPasswordRef}
            inputProps={{
              onKeyPress: event => {
                const { key } = event;
                if (key === "Enter") {
                  handleLogin();
                }
              },
            }}
          />
          <Box sx={{ pt: 1.5 }} className="d-flex ">
            {" "}
            <FormControlLabel
              sx={{
                fontSize: 14,
                mt: -1,
              }}
              control={
                <Checkbox
                  sx={{
                    color: "#7C757F",
                  }}
                  size="small"
                  checked={rememberMe}
                  onChange={checked => handleRememberMe(checked)}
                />
              }
              label={<Typography variant="subtitle1">Remember Me </Typography>}
            />
          </Box>

          <Box textAlign={"center"}>
            <LoadingButton
              sx={{ mt: 3, width: 1, maxWidth: "100%" }}
              disabled={emailError || passwordError || disabled}
              onClick={handleLogin}
              loading={loginLoading}
            >
              Login
            </LoadingButton>
          </Box>
        </Grid>
      </Grid>
    </Stack>
  );
};
