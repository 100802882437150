import React, { useMemo } from "react";
import { Box, Typography } from "@mui/material";

function StatusButton({ percentage = 0, handleRedirection }) {
	const selectColor = useMemo(() => {
		if (percentage === 0) {
			return "#667085";
		} else if (percentage === 100) {
			return "#12B76A";
		}

		return "#F79009";
	}, [percentage]);

	return (
		<Box
			width="max-content"
			p="4px 12px"
			sx={{
				border: `2px solid ${selectColor}`,
				borderRadius: "100px",
				"&:hover": {
					cursor: "pointer",
				},
			}}
			onClick={handleRedirection}
		>
			<Typography variant="subhead3" sx={{ color: selectColor }}>
				{percentage === 0 ? "Start now" : percentage === 100 ? "Done" : `In Progress ${percentage}%`}
			</Typography>
		</Box>
	);
}

export default StatusButton;
