import React, { useEffect, useState } from "react";
import { Box, Typography, Grid, Chip, Avatar, Card, CardContent, CardMedia, Link, Tooltip, IconButton, Button } from "@mui/material";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import { REFERENCE_ICON, POWERUP_BG_ICON } from "../../constants/image";
import JsxParser from "react-jsx-parser";
import ROUTES_PATH from "../../constants/routes";
import { DEFAULT_CARD_IMAGE } from "../../constants/image";
import PushPinOutlinedIcon from "@mui/icons-material/PushPinOutlined";
import PushPinIcon from "@mui/icons-material/PushPin";
import theme from "../../constants/Theme";
import VideoPlayerComponent from "../../common/videoPlayer";
import services from "../../services";
import { sosPrimary } from "../../constants/Theme/colors";
import MetaLink from "../MetaLink";
import ScriptEditor from "../../common/ScriptEditor";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { handleMixPanel } from "../../utils/mixPanelEventHandling";

export default function ResearchCuratorCard({ cardData, handleCardClick, setCallback }) {
	const [attachmentType, setAttachmentType] = useState("");

	useEffect(() => {
		setAttachmentType(cardData?.attachments[0]?.fileType.substr(0, cardData?.attachments[0]?.fileType.indexOf("/")));
	}, [cardData]);
	const getValidatedUrl = (link) => {
		let prefix = "https://";

		if (link.indexOf("http://") == 0 || link.indexOf("https://") == 0) {
			return link;
		} else {
			return prefix + link;
		}
	};

	const handlePinAction = (e) => {
		e.stopPropagation();

		let payload = {
			title: cardData?.title,
			description: cardData?.description,
			noteType: cardData?.noteType,
			isPinned: !cardData?.isPinned,
		};

		if (payload) {
			services
				.updateResearchCuratorApiCall(payload, cardData.id)
				.then((response) => {
					if (response.data.message === "Success") {
						setCallback(true);
					}
				})
				.catch((e) => {
					console.log("Submit", e);
				});
		}
	};

	const handleDownload = (attachment) => {
		fetch(attachment.fileLocation).then((response) => {
			response.blob().then((blob) => {
				let url = window.URL.createObjectURL(blob);
				let a = document.createElement("a");
				a.href = url;
				a.download = attachment.fileName;
				a.click();
			});
		});
	};

	const handleTracking = () => {
		handleMixPanel(cardData?.noteType === "NOTE" ? "Discovery Board: PowerDot Clicked" : "Discovery Board: Quick Note Clicked");
	};

	return (
		<>
			<Card
				sx={(theme) => ({
					boxShadow: theme.palette.primary.elevationLight2,
					mt: 1,
					cursor: "pointer",
					maxWidth: 1,
					mx: 1,
					backgroundColor: cardData?.noteType === "NOTE" ? theme.palette.text.background : theme.palette.secondary.quickNoteBg,
					borderRadius: theme.shape.standard.borderRadius,
					position: "relative",
				})}
				onClick={handleTracking}
			>
				{cardData?.attachments[0] && attachmentType === "image" && (
					<Box
						sx={{
							position: "absolute",
							top: 0,
							left: 0,
						}}
					>
						<Tooltip title="Download attachment" placement="right">
							<IconButton
								sx={(theme) => ({
									cursor: "pointer",
									height: "max-content",
									m: 1,

									"&:hover": {
										backgroundColor: theme.palette.containerBorder.lightOutline,
									},
								})}
								onClick={() => handleDownload(cardData.attachments[0])}
							>
								<FileDownloadIcon />
							</IconButton>
						</Tooltip>
					</Box>
				)}
				<Box onClick={handleCardClick}>
					{cardData?.attachments[0] && (
						<>
							{attachmentType === "video" ? (
								<Box
									sx={(theme) => ({
										borderRadius: theme.shape.standard.borderRadius,
										boxShadow: "none",
										overflow: "hidden",
									})}
								>
									<VideoPlayerComponent videoUrl={cardData?.attachments[0]?.fileLocation} className={"react-player-discovery"} />
								</Box>
							) : (
								<CardMedia
									component="img"
									image={attachmentType === "image" ? cardData?.attachments[0]?.fileLocation : ""}
									sx={(theme) => ({
										borderRadius: theme.shape.standard.borderRadius,
									})}
								/>
							)}
						</>
					)}
					<CardContent>
						<Grid container>
							<Grid
								item
								xs={12}
								sx={{
									display: "flex",
									justifyContent: "space-between",
									pt: 1,
									px: 1,
								}}
							>
								<Box sx={{ display: "flex", flexDirection: "column", pt: 1 }}>
									<Typography variant="onsurface_title_thick">{cardData?.title}</Typography>
									{!cardData.isAutoGenerated &&
										(cardData?.isCreatedByUser ? (
											<Link
												href={ROUTES_PATH.MY_STARTUP_PROFILE}
												target="_blank"
												underline="hover"
												variant="label_large"
												color={(theme) => theme.palette.secondary.subTitle}
											>
												{`${cardData?.company?.companyName ? cardData?.company?.companyName : ""}`}
											</Link>
										) : (
											<Typography
												variant="onsurface_subtext_medium"
												sx={{
													pt: 1,
													color: (theme) => theme.palette.text.blue,
												}}
											>
												{`${cardData?.company?.companyName ? cardData?.company?.companyName : ""}`}
											</Typography>
										))}
								</Box>
								<Box
									sx={{
										alignItems: "flex-end",
										justifyContent: "flex-end",
									}}
								>
									<IconButton onClick={handlePinAction}>
										{cardData?.isPinned ? (
											<PushPinIcon sx={{ fill: theme.palette.secondary.main }} />
										) : (
											<PushPinOutlinedIcon sx={{ fill: theme.palette.secondary.main }} />
										)}
									</IconButton>
								</Box>
							</Grid>

							{cardData?.description && (
								<>
									<Grid item xs={12} md={12} sx={{ px: 1 }}>
										<Typography variant="onsurface_text_light" sx={{ wordBreak: "break-word" }}>
											<ScriptEditor
												initialValue={cardData?.description}
												readOnly
												inlineToolbar={false}
												inlineToolbarControls={[]}
												controls={[]}
												onChange={() => {}}
											/>
										</Typography>
									</Grid>
									<Grid item xs={12} md={12} lg={12}>
										{cardData?.description && <MetaLink data={cardData?.description} />}
									</Grid>
								</>
							)}

							{cardData?.references && (
								<Grid
									item
									xs={12}
									sx={{
										px: 2,
										pt: 1,
									}}
								>
									<Box>
										{cardData?.references?.map((item, i) => {
											return (
												<Chip
													sx={{ mb: 1, mr: 1 }}
													avatar={<Avatar alt="powerup" src={POWERUP_BG_ICON} />}
													label={
														<Typography
															variant="label_large"
															sx={(theme) => ({
																color: theme.palette.secondary.main,
															})}
														>
															{" "}
															{item?.entityName}
														</Typography>
													}
													variant="outlined"
												/>
											);
										})}
									</Box>
								</Grid>
							)}
							{cardData?.tags && (
								<Grid
									item
									xs={12}
									sx={{
										px: 2,
										mt: 0,
									}}
								>
									<Box sx={{ display: "flex", flexWrap: "wrap" }}>
										{cardData?.tags?.map((item, i) => {
											return (
												<Chip
													sx={{ mb: 1, mr: 1 }}
													label={
														<Typography
															variant="lable_large"
															sx={(theme) => ({
																color: theme.palette.secondary.main,
															})}
														>
															{" "}
															{item?.tag?.name}
														</Typography>
													}
													variant="outlined"
												/>
											);
										})}
									</Box>
								</Grid>
							)}
						</Grid>
					</CardContent>
				</Box>

				<Grid item xs={12} md={12} lg={12} sx={{ mx: 2 }}>
					{cardData?.links[0]?.url && <MetaLink data={cardData?.links[0]?.url} />}
				</Grid>
				<Box sx={{ display: "flex", width: 1 }}>
					{cardData?.attachments[0] && attachmentType === "application" && (
						<Link
							target="_blank"
							underline="hover"
							variant="button"
							alignItems="center"
							display={"flex"}
							justifyContent={"center"}
							sx={(theme) => ({
								py: 1,
								color: theme.palette.secondary.white,
								backgroundColor: theme.palette.button.greyLight,
								textTransform: "none",
								width: "-webkit-fill-available",
								"&:hover": {
									backgroundColor: "transparent",
									color: theme.palette.secondary.dark,
									textDecoration: "underline",
								},
							})}
							onClick={() => handleDownload(cardData.attachments[0])}
						>
							<FileDownloadIcon sx={{ pr: 1 }} /> Download Attachment
						</Link>
					)}
				</Box>
			</Card>
		</>
	);
}
