import React, { Fragment, useEffect, useState } from "react";
import { Box, Typography, Grid, Select, InputBase, styled, MenuItem, Card } from "@mui/material";

import Customers from "./Customers";
import FitContentCard from "./FitContentCard";
import { useSelector } from "react-redux";
import { getProbSolPowerUP } from "./functions";
import { HelpOutline, Info, KeyboardArrowDown, LightbulbOutlined } from "@mui/icons-material";
import NoDataPill from "../../MarketOpportunity/PersonaBuilderView/NoDataPill";

const MOCK_SOLUTION_DATA = [
	{
		problem: {
			title: "Problem 1",
			desc: "Real-time remote monitoring keeps the member and provider connected to beat chronic diseases together. Symptoms: Dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim. Existing Alternatives: Dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex. ",
		},
		solution: {
			title: "Solutions",
			values: [
				"House calls for comprehensive contextual healthcare on unmatched view into patient real life and SDoH.",
				"Telemedicine and teletherapy to care for the mind and body on your member’s terms.Telemedicine and teletherapy to care for the mind and body on your member’s terms.",
				"Real-time remote monitoring keeps the member and provider connected to beat chronic diseases together.",
			],
		},
	},
	{
		problem: {
			title: "Problem 2",
			desc: "Real-time remote monitoring keeps the member and provider connected to beat chronic diseases together. Symptoms: Dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim. Existing Alternatives: Dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex. ",
		},
		solution: {
			title: "Solutions",
			values: [
				"House calls for comprehensive contextual healthcare on unmatched view into patient real life and SDoH.",
				"Telemedicine and teletherapy to care for the mind and body on your member’s terms.Telemedicine and teletherapy to care for the mind and body on your member’s terms.",
				"Real-time remote monitoring keeps the member and provider connected to beat chronic diseases together.",
			],
		},
	},
];

const MOCK_INSIGHTS = [
	{
		id: 1,
		title: "Interest over time",
		desc: "Worldwide. Past 12 months. Web Search.",
	},
	{
		id: 2,
		title: "Compared breakdown by region",
		desc: "Worldwide. Past 12 months. Web Search.",
	},
];

const BootstrapInput = styled(InputBase)(({ theme }) => ({
	"label + &": {
		marginTop: theme.spacing(3),
	},
	"& .MuiInputBase-input": {
		border: "none",
		position: "relative",
		backgroundColor: theme.palette.background.paper,
		fontSize: "30px",
		lineHeight: "45px",
		paddingLeft: "12px",
		paddingTop: "10px",
		paddingBottom: "10px",
		paddingRight: "60px !important",
		transition: theme.transitions.create(["border-color", "box-shadow"]),
		// Use the system font instead of the default Roboto font.
		fontFamily: "PoppinsSemiBold",
		"&:focus": {
			borderRadius: 4,
			borderColor: "#80bdff",
			boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
		},
	},
}));

export default function FitContent() {
	const STARTUP = useSelector((state) => state?.investorDashboard?.openStartupObj);
	const [ProbSolValues, setProbSolValues] = useState(null);
	const [currentProbSol, setCurrentProbSol] = useState(null);
	const [currentProbSolIndex, setCurrentProbSolIndex] = useState(0);

	useEffect(() => {
		if (ProbSolValues && ProbSolValues[0]) {
			setCurrentProbSol(ProbSolValues[0]);
			setCurrentProbSolIndex(0);
		} else {
			setCurrentProbSol(null);
			setCurrentProbSolIndex(0);
		}
	}, [ProbSolValues]);

	const getProbSolValues = async () => {
		try {
			let ProbSolObj = await getProbSolPowerUP(STARTUP?.id);
			setProbSolValues(ProbSolObj);
		} catch (e) {
			console.log("Error when attempting to pull Problem Solution Fit PowerUP Data. Failed with error: ", e);
		}
	};

	useEffect(() => {
		if (STARTUP && STARTUP?.id) {
			getProbSolValues();
		}
	}, []);

	const handleChange = (event) => {
		setCurrentProbSol(ProbSolValues[event.target.value]);
		setCurrentProbSolIndex(event.target.value);
	};

	return (
		<Box>
			<Box p="60px 60px" sx={{ background: "#fff" }}>
				<Typography variant="main_title">Problem Solution Fit</Typography>
			</Box>
			<Box p="60px" sx={{ background: "#F9F9F9" }}>
				<Box mb={4}>
					<Select
						sx={{
							"& .MuiSelect-icon": {
								fontSize: "50px",
								color: "black",
								opacity: !ProbSolValues || ProbSolValues?.length <= 0 ? ".38" : "1",
							},
						}}
						value={currentProbSolIndex}
						onChange={handleChange}
						input={<BootstrapInput />}
						IconComponent={KeyboardArrowDown}
						disabled={!ProbSolValues || ProbSolValues?.length <= 0}
					>
						{ProbSolValues?.map((obj, index) => {
							return (
								<MenuItem key={index} value={index}>
									{obj.idea}
								</MenuItem>
							);
						})}
						{!ProbSolValues || (ProbSolValues?.length <= 0 && <MenuItem value={0}>No Problem Solution Fit Data Found</MenuItem>)}
					</Select>
				</Box>

				{ProbSolValues && ProbSolValues.length > 0 ? (
					<Fragment>
						<Customers data={currentProbSol} />
						{currentProbSol?.probSol?.map((solution, index) => (
							<Grid key={solution?.problem?.problem} container spacing="60px" sx={{ marginTop: "-20px" }}>
								<Grid item xs={6}>
									<FitContentCard title={`Problem ${index + 1}`} isArrow={true} icon={<HelpOutline fontSize="inherit" />}>
										<Box
											sx={{
												display: "flex",
												flexDirection: "column",
												gap: 5,
											}}
										>
											<Typography
												variant="customValue"
												sx={{
													fontFamily: "PoppinsMedium",
													fontSize: "18px",
													lineHeight: "27px",
												}}
												component={"p"}
											>
												{solution?.problem?.problem}
											</Typography>
											<Typography
												variant="customValue"
												sx={{
													fontFamily: "PoppinsMedium",
													fontSize: "18px",
													lineHeight: "27px",
												}}
												component={"p"}
											>
												<strong>Symptoms:</strong> {solution?.problem?.symptoms ? solution?.problem?.symptoms : <NoDataPill />}
											</Typography>
											<Typography
												variant="customValue"
												sx={{
													fontFamily: "PoppinsMedium",
													fontSize: "18px",
													lineHeight: "27px",
												}}
												component={"p"}
											>
												<strong>Existing Alternatives:</strong>{" "}
												{solution?.problem?.existingAlternatives ? solution?.problem?.existingAlternatives : <NoDataPill />}
											</Typography>
										</Box>
									</FitContentCard>
								</Grid>
								<Grid item xs={6}>
									<FitContentCard
										title={"Solutions"}
										icon={
											<LightbulbOutlined
												fontSize="inherit"
												sx={{
													"&.MuiSvgIcon-root": {
														ml: "-15px", // Lighbulb SVG has baked in padding so it makes the list appear off balance.
													},
												}}
											/>
										}
									>
										{solution?.solution?.map((value, index) => (
											<Box key={value} display="flex" alignItems="flex-start" mb="30px">
												<Typography variant="card_name" sx={{ fontWeight: 600 }}>
													{index + 1}.
												</Typography>
												<Typography
													variant="customValue"
													sx={{
														fontFamily: "PoppinsMedium",
														fontSize: "18px",
														lineHeight: "27px",
														marginLeft: "30px",
													}}
													component={"p"}
												>
													{value}
												</Typography>
											</Box>
										))}
									</FitContentCard>
								</Grid>
							</Grid>
						))}
					</Fragment>
				) : (
					<Card
						sx={{
							borderRadius: "40px",
							p: {
								xs: 2,
								md: 3,
								lg: 4,
							},
							boxShadow: "0px 10px 15px rgba(0, 0, 0, 0.05)",
							display: "flex",
							gap: 2,
						}}
					>
						<Box>
							<Info
								sx={{
									color: (theme) => theme.palette.primary.main,
								}}
							/>
						</Box>
						<Box>
							<Typography color="black" variant="poppinsMedium16" component={"p"}>
								This startup has not completed their Problem Solution Fit PowerUP at this time. We will continue to check for updates.
							</Typography>
						</Box>
					</Card>
				)}
			</Box>
			{/* 
				// TODO -- Commenting out as the google trend comps. are too unstable as iFrames. Need to find a better solution.
			*/}
			{/*<Box p="30px 60px" sx={{ background: "#fff" }}>
				<Typography variant="card_name" sx={{ fontWeight: 600 }}>
					Insight Results
				</Typography>
			</Box>
			<Box px="60px" pb="60px" sx={{ background: "#F9F9F9" }}>
				<Grid container spacing="60px" sx={{ marginTop: "-20px" }}>
					{MOCK_INSIGHTS.map((insight) => (
						<Grid key={insight.id} item xs={6}>
							<FitContentCard title={insight.title}>
								<Box
									width="100%"
									py="106px"
									display="flex"
									alignItems="center"
									justifyContent="center"
									mb="30px"
									sx={{ background: "#F9F9F9", borderRadius: "30px" }}
								>
									<Typography variant="card_title" sx={{ fontWeight: 600, opacity: 0.1 }}>
										Graph FPO
									</Typography>
								</Box>
								<Typography variant="card_text">{insight.desc}</Typography>
							</FitContentCard>
						</Grid>
					))}
				</Grid>
			</Box>*/}
		</Box>
	);
}
