import styled from "@emotion/styled";
import { Button, styled as materialStyled } from "@mui/material";

export const StartupCardComp = styled.div(({ theme, boltRecommended }) => {
	return {
		background: theme.palette.secondary.white,
		borderRadius: theme.shape.standard13.borderRadius,
		// boxShadow: "0px 10px 25px rgba(0, 0, 0, 0.05)",
		padding: "32px",
		border: "5px solid",
		borderColor: boltRecommended ? "#B7A8FF" : "transparent",
	};
});

export const AddIconComp = styled.div(({ theme, isActive, ml = "16px" }) => {
	return {
		position: "absolute",
		marginLeft: ml,
		marginTop: "28px",
		cursor: "pointer",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		borderRadius: "50%",
		height: "18px",
		width: "18px",
		background: isActive ? theme.palette.primary.primaryPurple : theme.palette.secondary.white,
		color: isActive && theme.palette.secondary.white,
	};
});

export const AnimatedBtn = materialStyled(Button)`
	width: 165px;
	white-space: nowrap;
	overflow: hidden;
	transition: padding 0.5s;
	padding-right: 50px;

	&:hover {
		padding-right: 0;
		padding-left: 20px;
		transition: padding 0.5s;
	}
`;
