import React, { Fragment, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Breadcrumbs, Typography, Link, CircularProgress } from "@mui/material";
import { NavigateNext } from "@mui/icons-material";
import { ResourceCenterContext } from "../Stores/ResourceCenterStore";

const Navigation = () => {
	const location = useLocation();
	const { currentCategory, currentSection, currentArticle, article_id } = useContext(ResourceCenterContext);

	const [pathSegments, setPathSegments] = useState([]);
	const [categoryObj, setcategoryObj] = useState({});
	const [sectionObj, setsectionObj] = useState({});
	const [articleObj, setarticleObj] = useState({});

	useEffect(() => {
		setcategoryObj(currentCategory);
	}, [currentCategory]);
	useEffect(() => {
		setsectionObj(currentSection);
	}, [currentSection]);
	useEffect(() => {
		setarticleObj(currentArticle);
	}, [currentArticle]);

	const fetchBreadcrumbName = (type, path) => {
		if (type == "categories") {
			return categoryObj?.name;
		} else if (type == "sections") {
			return sectionObj?.name;
		} else if (type == "articles") {
			return articleObj?.name;
		} else {
			return type;
		}
	};

	useEffect(() => {
		const segments = location.pathname.split("/").filter((segment) => segment !== "");
		setPathSegments(segments);
	}, [location.pathname]);

	return (
		<Breadcrumbs separator={<NavigateNext fontSize="small" />}>
			<Link key="/" underline="none" href="/resource-center">
				<Typography variant="Display/sm/Semibold">Resource Center</Typography>
			</Link>
			{location?.pathname?.includes("/search-results") && (
				<Link key="/search-results" href="/resource-center/search-results" underline="none">
					<Typography variant="Display/sm/Semibold" sx={{ color: "/resource-center/search-results" === location.pathname ? "inherit" : "initial" }}>
						Search Results
					</Typography>
				</Link>
			)}
			{location?.pathname?.includes("/search-results") && article_id && articleObj && (
				<Link key={articleObj?.id} underline="none">
					<Typography variant="Display/sm/Semibold" sx={{ color: (theme) => theme.palette.primary.main }}>
						{articleObj?.title ? articleObj?.title : <CircularProgress size={20} />}
					</Typography>
				</Link>
			)}
			{/*{!location?.pathname?.includes("/search-results") && (
				<Link key="/categories" href="/resource-center" underline="none">
					<Typography variant="Display/sm/Semibold" sx={{ color: "/resource-center" === location.pathname ? "inherit" : "initial" }}>
						Categories
					</Typography>
				</Link>
			)}*/}

			{!location?.pathname?.includes("/search-results") && (
				<Link key="/sections" href="/resource-center" underline="none">
					<Typography variant="Display/sm/Semibold" sx={{ color: "/resource-center" === location.pathname ? "inherit" : "initial" }}>
						Sections
					</Typography>
				</Link>
			)}

			{!location?.pathname?.includes("/search-results") &&
				(() => {
					const breadcrumbs = [];
					let path = "/resource-center";
					for (let i = 1; i < pathSegments.length; i += 2) {
						path += `/${pathSegments[i]}/${pathSegments[i + 1]}`;
						if (pathSegments[i + 1]) {
							const name = fetchBreadcrumbName(pathSegments[i], pathSegments[i + 1]);
							breadcrumbs.push(
								<Link key={path} href={path} underline="none">
									<Typography variant="Display/sm/Semibold" sx={{ color: path === location.pathname ? "inherit" : "initial" }}>
										{name ? name : <CircularProgress size={20} />}
									</Typography>
								</Link>
							);
						}
					}
					return breadcrumbs;
				})()}
		</Breadcrumbs>
	);
};

export default Navigation;
