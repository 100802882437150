import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import CoffeeIcon from "@mui/icons-material/Coffee";
import PsychologyIcon from "@mui/icons-material/Psychology";
import HandshakeIcon from "@mui/icons-material/Handshake";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import EngineeringIcon from "@mui/icons-material/Engineering";
import DesignServicesOutlinedIcon from "@mui/icons-material/DesignServicesOutlined";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import ScienceIcon from "@mui/icons-material/Science";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import SpeedIcon from "@mui/icons-material/Speed";
import PeopleOutlineOutlinedIcon from "@mui/icons-material/PeopleOutlineOutlined";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import AccessibleForwardIcon from "@mui/icons-material/AccessibleForward";
import CodeIcon from "@mui/icons-material/Code";
import {
	DiversityIcon,
	PaymentCardIcon,
	PottedPlantIcon,
	RainyCloudPrimary,
	PaymentCardIconBlue,
	InventoryYellowIcon,
	FourLeafIcon,
	GreetBlueIcon,
	AngelIcon,
	CorporateIcon,
	FamilyIcon,
	FoundationIcon,
	CapitalIcon,
	HackerIcon,
	SalesMonitoringIcon,
	BlueGritIcon,
	PottedPlantBlueIcon,
	Diversity4,
	FollowRightIcon,
	SvenIcon,
	SvbOutlineIcon,
	ModelRed3D,
	CycleRed,
	AiPink,
	DatabasePrimary,
	AwsBrown,
	AutomotiveIcon,
	MovieIcon,
	MapIcon,
	LockIcon,
	LocalShippingIcon,
	HealthIcon,
	FlightIcon,
	FastFoodIcon,
	FactoryIcon,
	DVRIcon,
	EducationIcon,
	DurableGoodsIcon,
	ConsumerServicesIcon,
	ConstructionIcon,
	ComputerIcon,
	CleantechIcon,
	ChemicalIcon,
	CallIcon,
	CableIcon,
	BoltBlueIcon,
	BlockchainIcon,
	BankIcon,
	ALTRouteIcon,
	AgricultureIcon,
	WifiIcon,
	VerifiedUserIcon,
	VaccineNewIcon,
	SpaIcon,
	ShoppingIcon,
	SavingIcon,
	PublicIcon,
	PrecisionIcon,
	PackageIcon,
	OilBarrelIcon,
	MusicIcon,
	LockBlueIcon,
	CapitalNewIcon,
	PinkMagnetIcon,
} from "../common/icons";
import CampaignIcon from "@mui/icons-material/Campaign";
import BuildIcon from "@mui/icons-material/Build";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import RocketIcon from "@mui/icons-material/Rocket";
import BadgeIcon from "@mui/icons-material/Badge";
import SchoolIcon from "@mui/icons-material/School";

import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import StarIcon from "@mui/icons-material/Star";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import PeopleIcon from "@mui/icons-material/People";
import FavoriteIcon from "@mui/icons-material/Favorite";
import BoltIcon from "@mui/icons-material/Bolt";
import AltRouteIcon from "@mui/icons-material/AltRoute";
import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";
import { SVB_PARTNER } from "./image";

export const WHO_ARE_YOU_CARD_ITEMS = [
	{
		text: "Founder",
		icon: <RocketLaunchIcon sx={{ color: "text.darkBlue" }} />,
		hoverText: "I’m the owner (or co-owner) of a startup or startup idea.",
		disabled: false,
	},
	{
		text: "Teammate",
		icon: <DiversityIcon />,
		hoverText: "I work with a founder at a startup.",
		disabled: true,
	},
	{
		text: "Mentor",
		icon: <CoffeeIcon sx={{ color: "success.main" }} />,
		hoverText: "I’m here to guide startups and help them grow—because I’ve been there, done that.",
		disabled: false,
	},
	{
		text: "Advisor",
		icon: <PsychologyIcon sx={{ color: "text.purple" }} />,
		hoverText: "I’m an expert in my field ready to share my knowledge as a Subject-Matter Expert.",
		disabled: true,
	},
	{
		text: "Investor",
		icon: <PottedPlantIcon />,
		hoverText: "I’m here to help startups grow financially.",
		disabled: false,
	},
	{
		text: "Partner",
		icon: <HandshakeIcon sx={{ color: "warning.main" }} />,
		hoverText: "I’m here to support startups with their operational + business needs.",
		disabled: true,
	},
	{
		text: "Student",
		icon: <SchoolIcon sx={{ color: "success.light" }} />,
		hoverText: "I’m here to learn and explore StartupOS.",
		disabled: false,
	},
	{
		text: "Instructor",
		icon: <BadgeIcon sx={{ color: "text.pinkDark" }} />,
		hoverText: "I’m a professional exploring StartupOS.",
		disabled: false,
	},
];

export const WHO_ARE_YOU_BACKDOOR_CARD_ITEMS = [
	{
		text: "Founder",
		icon: <RocketLaunchIcon sx={{ color: "text.darkBlue" }} />,
		hoverText: "I’m the owner (or co-owner) of a startup or startup idea.",
		disabled: false,
	},
	{
		text: "Teammate",
		icon: <DiversityIcon />,
		hoverText: "I work with a founder at a startup.",
		disabled: false,
	},
	{
		text: "Mentor",
		icon: <CoffeeIcon sx={{ color: "success.main" }} />,
		hoverText: "I’m here to guide startups and help them grow—because I’ve been there, done that.",
		disabled: false,
	},
	{
		text: "Advisor",
		icon: <PsychologyIcon sx={{ color: "text.purple" }} />,
		hoverText: "I’m an expert in my field ready to share my knowledge as a Subject-Matter Expert.",
		disabled: false,
	},
	{
		text: "Investor",
		icon: <PottedPlantIcon />,
		hoverText: "I’m here to help startups grow financially.",
		disabled: false,
	},
	{
		text: "Partner",
		icon: <HandshakeIcon sx={{ color: "warning.main" }} />,
		hoverText: "I’m here to support startups with their operational + business needs.",
		disabled: false,
	},
	{
		text: "Student",
		icon: <SchoolIcon sx={{ color: "success.light" }} />,
		hoverText: "I’m here to learn and explore StartupOS.",
		disabled: false,
	},
	{
		text: "Instructor",
		icon: <BadgeIcon sx={{ color: "text.pinkDark" }} />,
		hoverText: "I’m a professional exploring StartupOS.",
		disabled: false,
	},
];

export const WHAT_BRINGS_YOU_HERE = [
	{
		text: "Access to guidance and tools",
		icon: <BuildIcon sx={{ color: "text.darkBlue" }} />,
	},
	{
		text: "Access to capital",
		icon: <PaymentCardIcon />,
	},
	{
		text: "Access to mentors",
		icon: <CoffeeIcon sx={{ color: "success.main" }} />,
	},
	{
		text: "All of the above",
		icon: <DoneAllIcon sx={{ color: "error.main" }} />,
		selectAll: true,
	},
];

export const WHAT_TYPE_OF_INVESTOR = [
	{
		text: "Angel",
		icon: <AngelIcon />,
	},
	{
		text: "Corporate Venture Capital",
		icon: <CorporateIcon />,
	},
	{
		text: "Family Office",
		icon: <FamilyIcon />,
	},
	{
		text: "Foundation",
		icon: <FoundationIcon />,
	},
	{
		text: "Venture Capital",
		icon: <CapitalIcon />,
	},
];

export const FIRST_THING_YOU_LOOK_IN_STARTUP = [
	{
		text: "Industry Type",
		icon: <CorporateFareIcon fontSize="large" sx={{ color: "secondary.cuteCrab" }} />,
	},
	{
		text: "Founder Knowledge",
		icon: <StarIcon fontSize="large" sx={{ color: "text.darkBlue" }} />,
	},
	{
		text: "Innovation",
		icon: <PsychologyIcon fontSize="large" sx={{ color: "secondary.purpleDark" }} />,
	},
	{
		text: "Grit",
		icon: <BlueGritIcon />,
	},
];

export const WHICH_STARTUPS_YOU_HELP = [
	{
		text: "Startups with a good idea",
		icon: <TipsAndUpdatesIcon sx={{ color: "text.purple" }} />,
	},
	{
		text: "Startups with an MVP",
		icon: <RocketLaunchIcon sx={{ color: "text.blueFaint" }} />,
	},
	{
		text: "Startups progressing towards Product-Market Fit",
		icon: <PinkMagnetIcon />,
	},
	{
		text: "Startups ready to launch",
		icon: <RocketIcon sx={{ color: "error.light" }} />,
	},
	{
		text: "Startups with paying customers",
		icon: <PaymentCardIconBlue />,
	},
	{
		text: "Other",
		icon: <AltRouteIcon sx={{ color: "warning.main" }} fontSize="large" />,
		isOtherEnabled: true,
		inputPopupTitle: "Which startups can you help the most?",
		inputPlaceHolder: "Startups",
	},
];

export const YOUR_SUPERPOWER = [
	{
		text: "Super Vision",
		icon: <RemoveRedEyeIcon sx={{ color: "text.yellowDark" }} fontSize="large" />,
	},
	{
		text: "Expert Network",
		icon: <ConnectWithoutContactIcon sx={{ color: "success.light" }} fontSize="large" />,
	},
	{
		text: "Execution",
		icon: <InventoryYellowIcon sx={{ color: "text.yellowLight" }} />,
	},
	{
		text: "Luck",
		icon: <FourLeafIcon sx={{ color: "text.waterBlue" }} />,
	},
	{
		text: "Grit",
		icon: <GreetBlueIcon sx={{ color: "text.link" }} />,
	},
	{
		text: "Leadership",
		icon: <PeopleIcon sx={{ color: "text.darkBlue" }} fontSize="large" />,
	},
	{
		text: "Empathy",
		icon: <FavoriteIcon sx={{ color: "primary.light" }} fontSize="large" />,
	},
	{
		text: "Energy",
		icon: <BoltIcon sx={{ color: "text.red" }} fontSize="large" />,
	},
	{
		text: "Other",
		icon: <AltRouteIcon sx={{ color: "warning.main" }} fontSize="large" />,
		isOtherEnabled: true,
		inputPopupTitle: "What’s your superpower?",
		inputPlaceHolder: "Your superpower",
	},
];
export const HOW_MANY_DEALS = [
	{
		text: "1-3",
		textColor: "text.voiletDark",
	},
	{
		text: "4-6",
		textColor: "text.screaminGreen",
	},
	{
		text: "7-9",
		textColor: "text.Pelorous",
	},
	{
		text: "10+",
		textColor: "text.darkBlue",
	},
];

export const WHATS_YOUR_INDUSTRY_FOCUS = [
	{
		text: "Aerospace & Defense",
		icon: <FlightIcon />,
	},
	{
		text: "Automotive",
		icon: <AutomotiveIcon />,
	},
	{
		text: "Agriculture",
		icon: <AgricultureIcon />,
	},
	{
		text: "Banking",
		icon: <BankIcon />,
	},
	{
		text: "Blockchain",
		icon: <BlockchainIcon />,
	},
	{
		text: "Capital Markets",
		icon: <CapitalNewIcon />,
	},
	{
		text: "Chemical & Process",
		icon: <ChemicalIcon />,
	},
	{
		text: "Cleantech & Sustainability",
		icon: <CleantechIcon />,
	},
	{
		text: "Computer",
		icon: <ComputerIcon />,
	},
	{
		text: "Construction",
		icon: <ConstructionIcon />,
	},
	{
		text: "Consumer Packaged Goods (CPG)",
		icon: <PackageIcon />,
	},
	{
		text: "Consumer Services",
		icon: <ConsumerServicesIcon />,
	},
	{
		text: "Cyber Security",
		icon: <LockBlueIcon />,
	},
	{
		text: "Durable Goods (DG)",
		icon: <DurableGoodsIcon />,
	},
	{
		text: "E-Commerce",
		icon: <ShoppingIcon />,
	},
	{
		text: "Education",
		icon: <EducationIcon />,
	},
	{
		text: "Electronics",
		icon: <CableIcon />,
	},
	{
		text: "Energy & Utilities",
		icon: <BoltBlueIcon />,
	},
	{
		text: "Financial Services",
		icon: <SavingIcon />,
	},
	{
		text: "Food & Food Services",
		icon: <FastFoodIcon />,
	},
	{
		text: "Healthcare",
		icon: <HealthIcon />,
	},
	{
		text: "Heavy Machinery",
		icon: <PrecisionIcon />,
	},
	{
		text: "Hi-Tech",
		icon: <DVRIcon />,
	},
	{
		text: "Industrial Manufacturing",
		icon: <FactoryIcon />,
	},
	{
		text: "Insurance",
		icon: <VerifiedUserIcon />,
	},
	{
		text: "Internet & Cloud",
		icon: <WifiIcon />,
	},
	{
		text: "Media & Entertainment",
		icon: <MovieIcon />,
	},
	{
		text: "Mining & Natural Resources",
		icon: <PublicIcon />,
	},
	{
		text: "Music",
		icon: <MusicIcon />,
	},
	{
		text: "Life Sciences & Healthcare",
		icon: <SpaIcon />,
	},
	{
		text: "Oil & Gas",
		icon: <OilBarrelIcon />,
	},
	{
		text: "Pharmaceutical",
		icon: <VaccineNewIcon />,
	},
	{
		text: "Retail",
		icon: <ShoppingIcon />,
	},
	{
		text: "Telecommunication",
		icon: <CallIcon />,
	},
	{
		text: "Transport & Logistics",
		icon: <LocalShippingIcon />,
	},
	{
		text: "Travel & Hospitality",
		icon: <MapIcon />,
	},
	{
		text: "Other",
		icon: <ALTRouteIcon />,
		isOtherEnabled: true,
		inputPopupTitle: "What’s your industry focus?",
		inputPlaceHolder: "Your industry focus",
	},
];
export const WHAT_IS_INVESTMENT_RANGE = [
	{
		text: "$25k - $50k",
		textColor: "text.voiletDark",
	},
	{
		text: "$50k - $250k",
		textColor: "success.light",
	},
	{
		text: "$250k - $1M",
		textColor: "warning.light",
	},
	{
		text: "$1M+",
		textColor: "primary.main",
	},
];
export const WHATS_YOUR_EXPERTISE = [
	{
		text: "Opportunist",
		icon: <StarIcon sx={{ color: "text.darkBlue" }} fontSize="large" />,
	},
	{
		text: "Finance",
		icon: <AccountBalanceIcon sx={{ color: "text.pinkLight" }} fontSize="large" />,
	},
	{
		text: "Technologist",
		icon: <EngineeringIcon sx={{ color: "success.light" }} fontSize="large" />,
	},
	{
		text: "Design",
		icon: <DesignServicesOutlinedIcon sx={{ color: "text.red" }} fontSize="large" />,
	},
	{
		text: "Product Management",
		icon: <PsychologyIcon sx={{ color: "text.yellowLight" }} fontSize="large" />,
	},
	{
		text: "Marketing",
		icon: <CampaignIcon sx={{ color: "warning.main" }} fontSize="large" />,
	},
	{
		text: "Academic",
		icon: <MenuBookIcon sx={{ color: "success.xLight" }} fontSize="large" />,
	},
	{
		text: "Scientist",
		icon: <ScienceIcon sx={{ color: "text.blueDarkShade" }} fontSize="large" />,
	},
	{
		text: "Hacker",
		icon: <HackerIcon />,
	},
	{
		text: "Sales",
		icon: <SalesMonitoringIcon />,
	},
	{
		text: "Operations",
		icon: <ManageAccountsIcon sx={{ color: "success.main" }} fontSize="large" />,
	},
	{
		text: "Other",
		icon: <AltRouteIcon fontSize="large" />,
		isOtherEnabled: true,
		inputPopupTitle: "What’s your expertise?",
		inputPlaceHolder: "Your expertise",
	},
];

export const HAVE_YOU_RECIEVED_ANY_FUNDING = [
	{
		text: "I am bootstrapping the business",
		icon: <PottedPlantBlueIcon />,
	},
	{
		text: "I have raised funds from family and friends",
		icon: <Diversity4 />,
	},
	{
		text: "I have received funding from angel investors / venture capital",
		icon: <AccountBalanceIcon sx={{ color: "text.voilet" }} />,
	},
	{
		text: "Not yet—that’s why I’m here",
		icon: <FollowRightIcon />,
	},
];

export const ARE_YOU_AFFILIATED = [
	{ text: "SVEN", icon: <SvenIcon /> },
	{ text: "SVB", icon: <SvbOutlineIcon /> },
	{
		text: "Accelerator",
		icon: <SpeedIcon sx={{ color: "text.red" }} fontSize="large" />,
	},
	{
		text: "Academic",
		icon: <SchoolOutlinedIcon sx={{ color: "warning.main" }} fontSize="large" />,
	},
	{
		text: "Partner",
		icon: <PeopleOutlineOutlinedIcon sx={{ color: "text.pinkGlow" }} fontSize="large" />,
	},
	{
		text: "Invidual",
		icon: <EmojiPeopleIcon sx={{ color: "text.darkBlue" }} fontSize="large" />,
	},
	{
		text: "Other Group",
		icon: <AltRouteIcon sx={{ color: "warning.main" }} fontSize="large" />,
		isOtherEnabled: true,
		inputPopupTitle: "Are you affiliated with any group?",
		inputPlaceHolder: "Affiliated group",
	},
];

export const SKILLS_AND_EXPERTISE = [
	{ text: "3D Modelling", icon: <ModelRed3D /> },
	{
		text: "ADA / Accessibility",
		icon: <AccessibleForwardIcon sx={{ color: "text.darkBlue" }} fontSize="large" />,
	},
	{ text: "Agile Methodologies", icon: <CycleRed /> },
	{ text: "Agile Methodologies For goverment", icon: <CycleRed /> },
	{ text: "Artificial Intelligence", icon: <AiPink /> },
	{ text: "Analytics", icon: <SalesMonitoringIcon /> },
	{ text: "AWS", icon: <AwsBrown /> },
	{ text: "Back-office integration", icon: <DatabasePrimary /> },
	{
		text: "Backend Development",
		icon: <CodeIcon sx={{ color: "warning.light" }} fontSize="large" />,
	},
];

export const EXPERTISE_LIST = [
	"Startup Strategy & Execution",
	"Marketing & Growth",
	"Operations & Infrastructure",
	"User Experience & Design",
	"Team Building & Leadership",
	"Engineering & Technical",
];

export const INDUSTRY_LIST = [
	"Auto & Transportation",
	"Biotechnology",
	"Cleantech & Sustainability",
	"Consumer Goods",
	"Consumer Internet Services",
	"Consumer Products & Services",
	"E-commerce",
	"Education",
	"Energy / Sustainability / Natural Resources",
	"Financial / Insurance Services",
	"Financial Services",
	"Games",
	"Hardware & Frontier Tech",
	"Health / Wellness",
	"Industrial Energy",
	"Information Technology Services",
	"Insurance",
	"Life Science and Healthcare",
	"Logistics / Supply Chain",
	"Media & Entertainment",
	"Medical Devices & Equipment",
	"Networking & Equipment",
	"OSS",
	"Payroll & Benefits",
	"Real estate",
	"Regulated Products",
	"Restaurant / Food / Bevarages",
	"Sales / Ads / Marketing/BD",
	"Software",
	"Space",
	"Supply Chain, Logistics and Delivery",
	"Telecommunication",
	"Transportation / Mobility",
	"Travel",
];

export const PRODUCT_OFFERING_LIST = [
	"AI/ML",
	"Blockchain",
	"Climate",
	"Cloud Infrastructure",
	"Collaboration",
	"Consumer Goods",
	"Crypto",
	"Data",
	"Digital Health",
	"Edtech",
	"Fintech",
	"Hardware",
	"Marketplace",
	"Other",
	"SaaS",
	"Security",
	"Services",
	"Social",
];

export const FINANCING_LIST = ["None", "Priced Round", "Safe", "Conv. Note"];
export const CURRENT_STAGE_LIST = ["Pre-Seed", "Seed", "Series A", "Growth"];
export const SEEKING_LIST = ["Seed", "Series A"];
