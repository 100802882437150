import React, { useEffect, useState } from "react";
import { Box, Grid, Button, Typography } from "@mui/material";
import OnlinePredictionIcon from "@mui/icons-material/OnlinePrediction";
import theme from "../../../constants/Theme";
import { useHistory } from "react-router-dom";
import ROUTES_PATH from "../../../constants/routes";

export default function TabDescription(props) {
  const history = useHistory();

  const handleMarketTest = () => {
    history.push(ROUTES_PATH.MARKET_TEST);
  };

  return (
    <Grid item sx={12} md={8}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: theme.spacing(3),
        }}
      >
        <Typography variant="overlineLarge" sx={{ textTransform: "uppercase" }}>
          {props?.selectedPowerupPack?.subTitle}
        </Typography>
        <Typography variant="headline1">
          {props?.selectedPowerupPack?.title}
        </Typography>
        <Typography variant="subhead1">
          {props?.selectedPowerupPack?.description}
        </Typography>
        <Box sx={{ display: { xs: "none", md: "block" } }}>
          {props.marketFlag ? (
            <>
              <Button
                startIcon={<OnlinePredictionIcon />}
                onClick={handleMarketTest}
                sx={{
                  width: "auto",
                  mr: 1,
                }}
              >
                Market test
              </Button>
              {!props.taskName ? (
                ""
              ) : (
                <Button
                  variant="outlined"
                  sx={{
                    width: "auto",
                  }}
                  onClick={() =>
                    props?.taskName === "POWER_UP"
                      ? props.handleStartPowerUp(props.startId)
                      : props?.taskName === "PLUGIN"
                      ? props.setShowAdvisorPluginPopup(true)
                      : props?.taskName === "FUSION"
                      ? ""
                      : ""
                  }
                  href={props?.taskName === "FUSION" ? props?.templateUrl : ""}
                >
                  {props?.taskName === "POWER_UP"
                    ? props?.startStatus
                      ? "Start next PowerUp"
                      : "Start first PowerUp"
                    : props?.taskName === "PLUGIN"
                    ? "Schedule PlugIn session"
                    : props?.taskName === "FUSION"
                    ? "Download deck"
                    : props?.taskName === "DEMO"
                    ? "Schedule investor meeting"
                    : ""}
                </Button>
              )}
            </>
          ) : !props.taskName ? (
            ""
          ) : (
            <Button
              sx={{
                width: "auto",
                mr: 1,
              }}
              onClick={() =>
                props?.taskName === "POWER_UP"
                  ? props.handleStartPowerUp(props.startId)
                  : props?.taskName === "PLUGIN"
                  ? props.setShowAdvisorPluginPopup(true)
                  : props?.taskName === "FUSION"
                  ? ""
                  : ""
              }
            >
              {!props?.startStatus
                ? "Start first PowerUp"
                : props?.taskName === "POWER_UP"
                ? "Start next PowerUp"
                : props?.taskName === "PLUGIN"
                ? "Schedule PlugIn session"
                : props?.taskName === "FUSION"
                ? "Download deck"
                : props?.taskName === "DEMO"
                ? "Schedule investor meeting"
                : ""}
            </Button>
          )}
        </Box>
      </Box>
    </Grid>
  );
}
