import React from "react";
import { Grid, Box, Stack, Typography, Button } from "@mui/material";
import ROUTES_PATH from "../../constants/routes";
import { useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../modules/actions";
import { authWebLogout } from "../../utils/auth0";
import { logout } from "../../utils/auth0-spa";

const InvalidInvite = ({ authData, setAuthData }) => {
  const history = useHistory();
  const location = useLocation();
  return (
    <Stack alignItems="center" justifyContent="center">
      <Grid container alignItems="center" justifyContent="center">
        <Grid item xs={12} sm={10} md={6} lg={6} sx={{ px: 2 }}>
          <Box display="grid" justifyContent="center">
            <Typography variant="headline1" align="center">
              Invitation is Invalid
            </Typography>
          </Box>
          <Box display="grid" sx={{ textAlign: "center" }}>
            <Typography variant="subhead1" sx={{ my: 4 }}>
              You need to log out from {authData?.userData?.email} in order to
              proceed.
            </Typography>
          </Box>
          <Button
            sx={{ width: 1, maxWidth: 1 }}
            onClick={async () => {
              const queryParams = new URLSearchParams(location.search);
              const token = queryParams.get("token");
              const logoutUrl = `${process.env.REACT_APP_SIGNUP_URL}?token=${token}`;
              setAuthData(null);
              await logout(logoutUrl);
              await authWebLogout(logoutUrl);
            }}
          >
            Log out
          </Button>
        </Grid>
      </Grid>
    </Stack>
  );
};

const mapStoreToProps = ({ auth }) => ({
  authData: auth,
});

const mapDispatchToProps = dispatch => ({
  setAuthData: updatedData => dispatch(actions.setAuthData(updatedData)),
});

export default connect(mapStoreToProps, mapDispatchToProps)(InvalidInvite);
