import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormControl, Typography, Box, Slider } from "@mui/material";
import { setInvestorDashboardData } from "../../../../../modules/actions";

const ScoreRangeFilter = () => {
	const filteredValue = useSelector((state) => state?.investorDashboard?.filteredValue) || [];
	const dispatch = useDispatch();
	let scoreRange = filteredValue?.filter((filteredItem) => filteredItem?.type === "scoreRange");
	const [value, setValue] = React.useState([150, 1000]);
	const filterDrawerOpen = useSelector((state) => state?.investorDashboard?.FilterDrawerOpen);

	const setFilteredValues = (scoreObj) => {
		const tempFilteredValue = [...filteredValue];
		let index = tempFilteredValue.findIndex((item) => item.type === scoreObj.type);
		if (index !== -1) {
			tempFilteredValue[index] = scoreObj;
		} else {
			tempFilteredValue.push(scoreObj);
		}
		dispatch(
			setInvestorDashboardData({
				filteredValue: tempFilteredValue,
			})
		);
	};

	useEffect(() => {
		if (value) {
			let scoreObj = {
				type: "scoreRange",
				minScore: value?.[0],
				maxScore: value?.[1],
				checkboxValue: `${value?.[0]} -  ${value?.[1]}`,
			};
			setFilteredValues(scoreObj);
		}
	}, [value]);

	useEffect(() => {
		if (filterDrawerOpen && scoreRange?.length > 0) {
			setValue([scoreRange[0]?.minScore, scoreRange[0]?.maxScore]);
		}
	}, [filterDrawerOpen]);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<Box mb={5}>
			<Typography
				sx={{
					color: (theme) => theme.palette.secondary.black,
					width: "fit-content",
				}}
				variant="testHeading"
			>
				Score
			</Typography>
			<FormControl fullWidth sx={{ mt: 3 }}>
				<Slider
					sx={(theme) => ({
						"& .MuiSlider-valueLabel": {
							fontFamily: theme.typography["Text/sm/Semibold"],
							top: -6,
							backgroundColor: "unset",
							color: theme.palette.text.primary,
							"&:before": {
								display: "none",
							},
							"& *": {
								background: "transparent",
								color: theme.palette.mode === "dark" ? "#fff" : "#000",
							},
						},
					})}
					value={value}
					onChange={handleChange}
					valueLabelDisplay="on"
					defaultValue={150}
					min={0}
					max={1000}
				/>
			</FormControl>
		</Box>
	);
};

export default ScoreRangeFilter;
