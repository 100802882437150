import React, { useEffect, useState } from "react";
import { Box, Grid, Button, Typography, Divider, Link, CircularProgress } from "@mui/material";
import theme from "../../../constants/Theme";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import AddIcon from "@mui/icons-material/Add";
import MentorExperienceForm from "../../MentorProfile/MentorExperience";
import MentorEducationForm from "../../MentorProfile/MentorEducation";
import services from "../../../services";
import { getCandidateDetails } from "../../../containers/Workspace/PowerUP/IdeaValidation/Functions/common";
import { useParams } from "react-router-dom";

export default function MentorExperience({ isEdit, inputChange, harmonicData, harmonicUpdate, setHarmonicUpdate }) {
	const [showExperience, setShowExperience] = useState(false);
	const [showEducation, setShowEducation] = useState(false);
	const [showExperienceEdit, setShowExperienceEdit] = useState(false);
	const [showEducationEdit, setShowEducationEdit] = useState(false);
	const [userDetails, setUserDetails] = useState([]);
	const [userData, setUserData] = useState([]);
	const params = useParams();
	const candidateProfileId = params.id;
	const [eduLoader, setEduLoader] = useState(false);
	const [expLoader, setExpLoader] = useState(false);

	const [editId, setEditId] = useState("");

	const handleEditFormOpen = (key, id) => {
		setEditId(id);
		if (key === "exp") {
			setShowExperienceEdit(true);
		} else {
			setShowEducationEdit(true);
		}
	};

	useEffect(() => {
		//if (harmonicData) {
		//  //setUserData(harmonicData);
		//  //setUserDetails(handleSort(harmonicData));
		//  handleUserDetails();
		//} else {
		//}

		if (candidateProfileId) {
			getUserDetails();
		} else {
			handleUserDetails();
		}
	}, []);

	useEffect(() => {
		if (harmonicUpdate) {
			handleUserDetails();
			setHarmonicUpdate(false);
		}
	}, [harmonicUpdate]);

	//calling get api for user details
	const handleUserDetails = () => {
		services
			.getUserProfile()
			.then((response) => {
				if (response.data.message === "Success") {
					setUserData(response.data.data);
					setUserDetails(handleSort(response.data.data));
				}
			})
			.catch((e) => {
				console.log("Submit", e);
			});
	};

	const getUserDetails = () => {
		getCandidateDetails(candidateProfileId)
			.then((response) => {
				setUserData(response);
				setUserDetails(handleSort(response));
			})
			.catch((e) => {
				console.log("Submit", e);
			});
	};

	const toMonthName = (monthNumber) => {
		const date = new Date();
		date.setMonth(monthNumber - 1);

		return date.toLocaleString("en-US", {
			month: "short",
		});
	};

	const handleSort = (details) => {
		console.log();
		const data = { ...details };
		let exp = details?.experienceDetails;
		let edu = details?.educationDetails;

		data.experienceDetails = exp?.sort((a, b) => (a.startYear < b.startYear ? 1 : -1));
		data.educationDetails = edu?.sort((a, b) => (a.startYear < b.startYear ? 1 : -1));

		return data;
	};

	return (
		<>
			<Grid container>
				<Grid
					item
					xs={12}
					md={12}
					sx={{
						display: "flex",
						flexDirection: "column",
						gap: 2,
						pl: { md: 1 },
					}}
				>
					<Divider sx={{ display: "block", py: 2 }} />

					<Grid container sx={{ display: "flex", alignItems: "center" }}>
						<Grid item xs={12} sm={12} md={12} lg={6}>
							<Typography variant="largeTitle">Experience</Typography>
						</Grid>
						{isEdit && (
							<Grid item xs={12} sm={12} md={12} lg={6}>
								<Link
									variant="nofill"
									sx={{
										display: "flex",
										width: "max-content",
										float: "right",
										[theme.breakpoints.down("md")]: {
											float: "left",
											mt: 1,
										},
										[theme.breakpoints.only("md")]: {
											float: "left",
											mt: 1,
										},
										cursor: "pointer",
										gap: 1,
									}}
									underline="none"
									onClick={() => {
										setShowExperience(true);
										inputChange();
									}}
								>
									<AddIcon sx={{ maxWidth: "20px", height: 1 }} />
									<Typography variant="lable_large">Add Experience</Typography>
								</Link>
							</Grid>
						)}

						{/* Experience form: add */}
						{isEdit && showExperience && (
							<Grid item xs={12} md={12} sx={{ py: 4 }}>
								<MentorExperienceForm
									setShowExperience={setShowExperience}
									isExperienceEdit={false}
									handleUserDetails={handleUserDetails}
									inputChange={inputChange}
								/>
							</Grid>
						)}
					</Grid>

					{expLoader ? (
						<>
							<Box sx={{ display: "flex", width: 1, justifyContent: "center" }}>
								<CircularProgress
									sx={(theme) => ({
										display: "flex",
										justifyContent: "center",
									})}
								/>
							</Box>
						</>
					) : (
						<>
							{userDetails?.experienceDetails?.map(function (item, index) {
								return (
									<>
										{/* Experience form edit */}
										{isEdit && showExperienceEdit && editId == index ? (
											<MentorExperienceForm
												setShowExperience={setShowExperienceEdit}
												experienceDetails={item}
												isExperienceEdit={true}
												handleUserDetails={handleUserDetails}
												inputChange={inputChange}
												setExpLoader={setExpLoader}
											/>
										) : (
											<Box
												sx={{
													display: "flex",
													gap: 2,
													flexDirection: "row",
													justifyContent: "space-between",
												}}
											>
												<Box
													sx={{
														display: "flex",
														gap: 2,
														flexDirection: "column",
														mb: 2,
													}}
												>
													<Typography
														variant="title_medium"
														sx={(theme) => ({
															color: theme.palette.secondary.korophKoal,
														})}
													>
														{item?.title}
													</Typography>

													<Box
														sx={{
															display: "flex",
															flexDirection: "row",
															gap: 1,
														}}
													>
														<Typography
															variant="body_large"
															sx={(theme) => ({
																color: theme.palette.text.lightOnsurface,
															})}
														>
															{item?.companyName}
														</Typography>
														{item?.location && (
															<FiberManualRecordIcon
																fontSize="small"
																sx={{
																	maxHeight: "8px",
																	fill: theme.palette.secondary.dark,
																	alignSelf: "center",
																}}
															/>
														)}
														<Typography
															variant="body_large"
															sx={(theme) => ({
																color: theme.palette.text.lightOnsurface,
															})}
														>
															{item?.location}
														</Typography>
													</Box>

													{item.endYear ? (
														<Typography
															variant="body_large"
															sx={(theme) => ({
																color: theme.palette.text.lightOnsurface,
																display: "flex",
															})}
														>
															{toMonthName(Number(item?.startMonth))}&nbsp;
															{item?.startYear} - {toMonthName(Number(item?.endMonth))}&nbsp;
															{item?.endYear}
														</Typography>
													) : (
														<Typography
															variant="body_large"
															sx={(theme) => ({
																color: theme.palette.text.lightOnsurface,
																display: "flex",
															})}
														>
															{toMonthName(Number(item?.startMonth))}&nbsp;
															{item?.startYear}
														</Typography>
													)}
												</Box>
												{isEdit && (
													<ModeEditOutlineOutlinedIcon
														sx={{
															float: "right",
															cursor: "pointer",
															fill: theme.palette.secondary.main,
														}}
														onClick={() => {
															handleEditFormOpen("exp", index);
															inputChange();
														}}
													/>
												)}
											</Box>
										)}
									</>
								);
							})}
						</>
					)}

					<Divider sx={{ p: 1 }} />

					<Grid container sx={{ display: "flex", alignItems: "center" }}>
						<Grid item xs={12} sm={12} md={12} lg={6}>
							<Typography variant="largeTitle">Education</Typography>
						</Grid>
						{isEdit && (
							<Grid item xs={12} sm={12} md={12} lg={6}>
								<Link
									variant="nofill"
									sx={{
										display: "flex",
										width: "max-content",
										float: "right",
										cursor: "pointer",
										gap: 1,
										[theme.breakpoints.down("md")]: {
											float: "left",
											mt: 1,
										},
										[theme.breakpoints.only("md")]: {
											float: "left",
											mt: 1,
										},
									}}
									underline="none"
									onClick={() => {
										setShowEducation(true);
										inputChange();
									}}
								>
									<AddIcon sx={{ maxWidth: "20px", height: 1 }} />
									<Typography variant="lable_large">Add Education</Typography>
								</Link>
							</Grid>
						)}

						{/* Education form: add new */}
						{isEdit && showEducation && (
							<Grid item xs={12} md={12} sx={{ py: 4 }}>
								<MentorEducationForm
									setShowEducation={setShowEducation}
									isEducationEdit={false}
									handleUserDetails={handleUserDetails}
									setEduLoader={setEduLoader}
								/>
							</Grid>
						)}
					</Grid>

					{eduLoader ? (
						<>
							<Box sx={{ display: "flex", width: 1, justifyContent: "center" }}>
								<CircularProgress
									sx={(theme) => ({
										display: "flex",
										justifyContent: "center",
									})}
								/>
							</Box>
						</>
					) : (
						<>
							{userDetails?.educationDetails?.map(function (item, index) {
								return (
									<>
										{/* Education form edit */}
										{isEdit && showEducationEdit && editId === index ? (
											<MentorEducationForm
												setShowEducation={setShowEducationEdit}
												educationDetails={item}
												isEducationEdit={true}
												handleUserDetails={handleUserDetails}
												setEduLoader={setEduLoader}
											/>
										) : (
											<Box
												sx={{
													display: "flex",
													gap: 2,
													flexDirection: "row",
													justifyContent: "space-between",
												}}
											>
												<Box
													sx={{
														display: "flex",
														gap: 2,
														flexDirection: "column",
														mb: 2,
													}}
												>
													<Typography
														variant="title_medium"
														sx={(theme) => ({
															color: theme.palette.secondary.korophKoal,
														})}
													>
														{item?.course}
													</Typography>
													<Box
														sx={{
															display: "flex",
															flexDirection: "row",
															textAlign: "baseline",
															gap: 1,
														}}
													>
														<Typography
															variant="body_large"
															sx={(theme) => ({
																color: theme.palette.text.lightOnsurface,
																display: "flex",
																flexDirection: "column",
															})}
														>
															{item?.university}
														</Typography>

														{item?.location && (
															<FiberManualRecordIcon
																fontSize="small"
																sx={{
																	maxHeight: "8px",
																	fill: theme.palette.secondary.dark,
																	alignSelf: "center",
																}}
															/>
														)}

														<Typography
															variant="body_large"
															sx={(theme) => ({
																color: theme.palette.text.lightOnsurface,
															})}
														>
															{item?.location}
														</Typography>
													</Box>

													<Typography
														variant="body_large"
														sx={(theme) => ({
															color: theme.palette.text.lightOnsurface,
														})}
													>
														{item?.startYear} - {item?.endYear}
													</Typography>
												</Box>

												{isEdit && (
													<ModeEditOutlineOutlinedIcon
														sx={{
															float: "right",
															cursor: "pointer",
															fill: theme.palette.secondary.main,
														}}
														onClick={() => {
															handleEditFormOpen("edu", index);
															inputChange();
														}}
													/>
												)}
											</Box>
										)}
									</>
								);
							})}
						</>
					)}
				</Grid>
			</Grid>
		</>
	);
}
