import { CheckCircle } from "@mui/icons-material";
import { Box, Popover, Typography } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { PowerUPContext } from "../../../PowerUPController";
import VersionHistory from ".";
import { handleMixPanel } from "../../../../../utils/mixPanelEventHandling";

function CompleteStatus() {
	const { powerupDetails, versions, versionPopoverOpen, setVersionPopoverOpen, setcurrentVersion } = useContext(PowerUPContext);

	const linkRef = React.useRef(null);

	const handleClick = (event) => {
		setVersionPopoverOpen(true);
	};
	const handleClose = () => {
		setVersionPopoverOpen(false);
	};

	const getStatus = () => {
		if (powerupDetails && powerupDetails?.companyTask?.taskStatus) {
			switch (powerupDetails?.companyTask?.taskStatus) {
				case "COMPLETED":
					return "Completed";
				case "STARTED":
					return "In Progress";
				default:
					return "Not Started";
			}
		} else {
			return "Not Started";
		}
	};

	const isComplete = () => {
		if (powerupDetails && powerupDetails?.companyTask?.taskStatus === "COMPLETED") {
			return true;
		} else {
			return false;
		}
	};

	useEffect(() => {
		if (versionPopoverOpen) {
			handleMixPanel("PowerUp Work History Viewed", {
				"Powerup ID": powerupDetails?.id,
				"Powerup Name": powerupDetails?.powerUpName,
			})
		}
	}, [versionPopoverOpen])

	return (
		<Box sx={{ display: "flex", alignItems: "center", gap: "0.625rem" }}>
			<Typography variant={"Text/md/Semibold"} sx={{ color: "white" }}>
				{getStatus()}
			</Typography>
			{isComplete() && <CheckCircle sx={{ color: (theme)=>theme.palette.primary.main, width: "1.875rem", height: "1.875rem" }} />}
			{versions && (
				<React.Fragment>
					<Typography
						ref={linkRef}
						onClick={handleClick}
						variant={"Text/md/Semibold"}
						sx={{ textDecoration: "underline", color: "white", cursor: "pointer" }}
					>
						{versions?.length} {versions?.length == 1 ? "Version" : "Versions"}
					</Typography>
					<Popover
						open={versionPopoverOpen}
						anchorEl={linkRef?.current}
						onClose={handleClose}
						anchorOrigin={{
							vertical: "bottom",
							horizontal: "left",
						}}
						transformOrigin={{
							vertical: "top",
							horizontal: "left",
						}}
						PaperProps={{
							sx: {
								p: "30px",
								borderRadius: "30px",
								background: "white",
								boxShadow: "0px 10px 85px rgba(0, 0, 0, 0.1)",
								maxWidth: "50rem",
								width: "100%",
							},
						}}
					>
						<Typography variant="Text/md/Semibold">Work History</Typography>
						<VersionHistory selectedVersionCallback={(version) => setcurrentVersion(version)} />
					</Popover>
				</React.Fragment>
			)}
		</Box>
	);
}

export default CompleteStatus;
