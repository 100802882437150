import React, { useState } from "react";
import { Box, Button, Typography, IconButton, ClickAwayListener, Tooltip, tooltipClasses, styled } from "@mui/material";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";

const CustomTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
	[`& .${tooltipClasses.tooltip}`]: {
		margin: "0 !important",
		borderRadius: "50px",
		padding: 0,
	},
	[`.MuiTooltip-popper`]: {
		borderRadius: "50px",
	},
});

export const ValidationModal = ({ errors }) => {
	const [openValidationDetail, setOpenValidationDetail] = useState(false);

	const handleTooltipClose = () => {
		setOpenValidationDetail(false);
	};

	const handleTooltipOpen = () => {
		setOpenValidationDetail(true);
	};

	return (
		<ClickAwayListener onClickAway={handleTooltipClose}>
			<CustomTooltip
				PopperProps={{
					disablePortal: true,
				}}
				placement={"right-start"}
				onClose={handleTooltipClose}
				open={openValidationDetail}
				disableFocusListener
				disableHoverListener
				disableTouchListener
				sx={{
					background: "#fff",
				}}
				title={
					<Box
						width="340px"
						sx={{
							background: "#FFFFFF",
							boxShadow: "0px 10px 85px rgba(0, 0, 0, 0.09)",
							borderRadius: "50px",
							p: "60px",
							textAlign: "left",
						}}
					>
						<Typography variant="poppinsSemiBold30" color="#000" sx={{ display: "block" }}>
							Submission Errors
						</Typography>
						<Typography variant="poppinsMedium18" color="#000" sx={{ display: "block" }}>
							Please complete the following
						</Typography>

						<Box display="flex" flexDirection="column" gap="10px" mt={"30px"}>
							{Object.keys(errors).map((error) => (
								<Box key={error} display="flex" alignItems="center" gap="10px">
									<ErrorOutlineOutlinedIcon sx={{ color: "#F04438", fontSize: "24px" }} />
									<Typography variant="poppinsMedium18" color={"#D92D20"}>
										{errors[error].message}
									</Typography>
								</Box>
							))}
						</Box>

						<Box mt="30px" display="flex" justifyContent="flex-end">
							<Box>
								<Button
									sx={{
										height: "48px",
										borderRadius: "100px",
										background: "#7B61FF",
									}}
									onClick={handleTooltipClose}
								>
									Close
								</Button>
							</Box>
						</Box>
					</Box>
				}
			>
				<IconButton onClick={handleTooltipOpen}>
					<ErrorOutlineOutlinedIcon sx={{ color: "#F04438", fontSize: "30px" }} />
				</IconButton>
			</CustomTooltip>
		</ClickAwayListener>
	);
};
