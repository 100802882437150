import React, { useEffect, useState, useMemo } from "react";
import { Box, CircularProgress } from "@mui/material";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

import services from "../../../../services";
import WorkspaceHeader, { ActionBtn } from "../../../../components/Investor/Workspace/View/Header";
import SurveyIcon from "../../../../components/Investor/Workspace/Assets/new-home-survey-icon.svg";
import InterviewsIcon from "../../../../components/Investor/Workspace/Assets/new-home-interviews-icon.svg";
import InterviewIcon from "../../../../components/Investor/Workspace/Assets/new-home-tools-interviews-icon.svg";
import SoonIcon from "../../../../components/Investor/Workspace/Assets/new-home-tools-soon-icon.svg";
import SurveysIcon from "../../../../components/Investor/Workspace/Assets/new-home-tools-survey-icon.svg";
import { HomeSectionTitle } from "../../../../components/Investor/Workspace/View/HomeTitle";
import HomeCard from "../../../../components/Investor/Workspace/View/HomeCard";
import { ShowCards } from "../PowerUps";
import { useWindowSize } from "../../../../utils/windowResize";

ChartJS.register(ArcElement, Tooltip, Legend);

export default function Tools() {
	const size = useWindowSize();
	const [surveyCards, setSurveyCards] = useState([]);
	const [interviewCards, setInterviewCards] = useState([]);
	const [comingCards, setComingCards] = useState([]);
	const [showAllSurvey, setShowAllSurvey] = useState(true);
	const [showAllInterview, setShowAllInterview] = useState(true);
	const [showAllComing, setShowAllComing] = useState(true);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		getCurrentGroup("tools");
		getCurrentGroup("interviews");
	}, []);

	const cardLength = useMemo(() => {
		if (size.width > 1999) {
			return 4;
		} else if (size.width > 1587) {
			return 3;
		} else if (size.width > 1110) {
			return 2;
		} else if (size.width > 980) {
			return 1;
		} else if (size.width > 828) {
			return 2;
		} else {
			return 1;
		}
	}, [size]);

	const getCurrentGroup = async (group) => {
		setLoading(true);
		try {
			const tools = await services.getToDosList(group);
			if (tools?.data?.data) {
				if (group === "tools") {
					setSurveyCards(tools?.data?.data.filter((tool) => tool.cardType === "SURVEY" && tool.cardStatus !== "COMING_SOON"));
					setComingCards(tools?.data?.data.filter((tool) => tool.cardStatus === "COMING_SOON"));
				} else if (group === "interviews") {
					setInterviewCards(tools?.data?.data);
				}
			}
		} catch (error) {
			console.log(error);
		}
		setLoading(false);
	};

	const surveyItems = useMemo(() => {
		return showAllSurvey ? surveyCards : surveyCards.slice(0, cardLength);
	}, [showAllSurvey, surveyCards, cardLength]);

	const interviewItems = useMemo(() => {
		return showAllInterview ? interviewCards : interviewCards.slice(0, cardLength);
	}, [showAllInterview, interviewCards, cardLength]);

	const comingItems = useMemo(() => {
		return showAllComing ? comingCards : comingCards.slice(0, cardLength);
	}, [showAllComing, comingCards, cardLength]);

	const handleSurveyCount = () => {
		setShowAllSurvey(!showAllSurvey);
	};

	const handleInterviewCount = () => {
		setShowAllInterview(!showAllInterview);
	};

	const handleComingCount = () => {
		setShowAllComing(!showAllComing);
	};

	const Actions = (
		<Box display="flex" gap="30px">
			<ActionBtn color="#DE0085" title="Surveys" avatar={SurveyIcon} tabIndex={3} actionIndex={0} onAction={() => {}} />
			<ActionBtn color="#000000" title="Interviews" avatar={InterviewsIcon} tabIndex={3} actionIndex={1} onAction={() => {}} />
		</Box>
	);

	return (
		<Box>
			<WorkspaceHeader id={3} actions={Actions} />
			<Box mt={4}>
				<Box mb={4}>
					<HomeSectionTitle icon={SurveysIcon} title="Surveys" count={surveyCards.length} />
					{loading ? (
						<Box
							sx={{
								display: "flex",
								width: 1,
								justifyContent: "center",
								alignItems: "center",
								height: "50vh",
							}}
						>
							<CircularProgress />
						</Box>
					) : (
						<Box display="flex" flexWrap={"wrap"} gap="30px">
							{surveyItems.map((survey) => (
								<HomeCard
									key={survey?.id}
									variant="Survey"
									uuid={survey?.uuid}
									title={survey?.title}
									cardType={survey?.cardType}
									cardStatus={survey?.cardStatus}
									taskStatus={survey?.cardProgressDetails?.taskStatus}
									linkedEntityId={survey?.linkedEntityId}
									desc={survey?.shortDescription}
									imgSrc={survey?.imageUrl}
									isAI={survey?.isAiPowered}
									isPremium={survey?.isPremiumFeature}
								/>
							))}
						</Box>
					)}
					{surveyCards.length > cardLength && <ShowCards handleShowCard={handleSurveyCount} isShow={showAllSurvey} />}
				</Box>
				<Box mb={4}>
					<HomeSectionTitle icon={InterviewIcon} title="Interviews" count={interviewCards.length} />
					{loading ? (
						<Box
							sx={{
								display: "flex",
								width: 1,
								justifyContent: "center",
								alignItems: "center",
								height: "50vh",
							}}
						>
							<CircularProgress />
						</Box>
					) : (
						<Box display="flex" flexWrap={"wrap"} gap="30px">
							{interviewItems.map((interview) => (
								<HomeCard
									key={interview?.id}
									variant="Interviews"
									title={interview?.title}
									cardType={interview?.cardType}
									cardStatus={interview?.cardStatus}
									taskStatus={interview?.cardProgressDetails?.taskStatus}
									linkedEntityId={interview?.linkedEntityId}
									desc={interview?.shortDescription}
									imgSrc={interview?.imageUrl}
									isAI={interview?.isAiPowered}
									isPremium={interview?.isPremiumFeature}
								/>
							))}
						</Box>
					)}
					{interviewCards.length > cardLength && <ShowCards handleShowCard={handleInterviewCount} isShow={showAllInterview} />}
				</Box>
				<Box mb={4}>
					<HomeSectionTitle icon={SoonIcon} title="Coming Soon" count={comingCards.length} />
					{loading ? (
						<Box
							sx={{
								display: "flex",
								width: 1,
								justifyContent: "center",
								alignItems: "center",
								height: "50vh",
							}}
						>
							<CircularProgress />
						</Box>
					) : (
						<Box display="flex" flexWrap={"wrap"} gap="30px">
							{comingItems.map((coming) => (
								<HomeCard
									key={coming?.id}
									variant="coming"
									title={coming?.title}
									cardType={coming?.cardType}
									cardStatus={coming?.cardStatus}
									taskStatus={coming?.cardProgressDetails?.taskStatus}
									linkedEntityId={coming?.linkedEntityId}
									desc={coming?.shortDescription}
									imgSrc={coming?.imageUrl}
									isAI={coming?.isAiPowered}
									isPremium={coming?.isPremiumFeature}
								/>
							))}
						</Box>
					)}
					{comingCards.length > cardLength && <ShowCards handleShowCard={handleComingCount} isShow={showAllComing} />}
				</Box>
			</Box>
		</Box>
	);
}
