import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import CustomCircularProgressBar from "../../../common/CustomCircularProgressBar";

function SubGrowth({ percentage = 0, color, title, subtitle }) {
	return (
		<Grid container alignItems="center" spacing={2} sx={{ mt: 1 }}>
			<Grid item xs={5}>
				<Box display="flex" alignItems="center" gap={2}>
					<CustomCircularProgressBar inputData={{ percentage: percentage, color: color, width: "50px", height: "50px", size: 50, thickness: 7 }} />

					<Typography variant="growth_otherpercentage_text" color="secondary.black">
						{`${percentage}%`}
					</Typography>
				</Box>
			</Grid>
			<Grid item xs={3}>
				<Typography variant="poppinsSemiBold16" color="secondary.black">
					{title}
				</Typography>
			</Grid>
			<Grid item xs={4}>
				<Typography variant="poppinsSemiBold16" color="secondary.black">
					{subtitle}
				</Typography>
			</Grid>
		</Grid>
	);
}

export default SubGrowth;
