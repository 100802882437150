import React, { useEffect, useRef } from "react";

function SvgComponent({ svgUrl, strokeColor, style }) {
	const svgRef = useRef(null);

	useEffect(() => {
		fetch(svgUrl)
			.then((response) => response.text())
			.then((svgString) => {
				const svgDoc = new DOMParser().parseFromString(svgString, "image/svg+xml");
				const svgElement = svgDoc.documentElement;
				svgElement.setAttribute("width", "100%");
				svgElement.setAttribute("height", "100%");
				const strokeElement = svgElement.querySelector("path, line");
				if (strokeElement) {
					strokeElement.setAttribute("fill", strokeColor);
				}
				svgRef.current.appendChild(svgElement);
			})
			.catch((error) => {
				console.error("Failed to fetch SVG:", error);
			});
	}, [svgUrl, strokeColor]);

	return <div ref={svgRef} style={style} />;
}

export default SvgComponent;
