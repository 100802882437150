import React from "react";
import { Avatar, Box, Button, Typography } from "@mui/material";

function PartnerCard({ partner }) {
	const { logoUrl, title, subtitle, description, redirectLink } = partner;

	return (
		<Box
			position="relative"
			width="400px"
			sx={(theme) => ({
				display: "flex",
				flexDirection: "column",
				rowGap: 2,
				p: 4,
				borderRadius: "24px",
				backgroundColor: theme.palette.secondary.white,
				boxShadow: "0px 10px 25px 0px rgba(0, 0, 0, 0.05)",
				"&:hover": {
					cursor: "pointer",
					".event-register-btn": { backgroundColor: theme.palette.secondary.dark, color: theme.palette.secondary.white },
				},
			})}
			onClick={() => {
				window.open(redirectLink, "_blank");
			}}
		>
			<Box>
				<Avatar sx={{ bgcolor: "grey" }} alt="partner" src={logoUrl} />
			</Box>

			<Box display={"flex"} justifyContent="space-between" alignItems={"center"} flexWrap={"wrap"}>
				<Typography
					variant="Text/lg/Semibold"
					sx={{
						lineHeight: "32px",
						overflow: "hidden",
						display: "-webkit-box",
						WebkitBoxOrient: "vertical",
						WebkitLineClamp: 1,
						lineHeight: "1.2em",
						maxHeight: "2.4em",
						maxWidth: "300px",
					}}
				>
					{title}
				</Typography>
			</Box>
			<Typography
				variant="Text/sm/Semibold"
				component={"p"}
				sx={{
					lineHeight: "32px",
					overflow: "hidden",
					display: "-webkit-box",
					WebkitBoxOrient: "vertical",
					WebkitLineClamp: 1,
					lineHeight: "1.2em",
					maxHeight: "2.4em",
					maxWidth: "300px",
				}}
			>
				{subtitle}
			</Typography>

			<Typography
				variant="Text/sm/Regular"
				component={"p"}
				sx={{
					overflow: "hidden",
					display: "-webkit-box",
					WebkitBoxOrient: "vertical",
					WebkitLineClamp: 6,
					lineHeight: "1.2em",
					maxHeight: "7.4em",
				}}
			>
				<span
					dangerouslySetInnerHTML={{
						__html: description,
					}}
				/>
			</Typography>

			<Button variant="DS1" color={"gray"} className="event-register-btn">
				View More
			</Button>
		</Box>
	);
}

export default PartnerCard;
