import { Button } from "@mui/material";
import React from "react";
import { CSVLink } from "react-csv";
import { handleMixPanel } from "../../utils/mixPanelEventHandling";

const defaultData = [
	["Header1", "Header2", "Header3"],
	["How likely is it that you would recommend companyName to a friend or colleague?", "10", "6"],
	["What changes would this company have to make for you to give it an even higher rating?", "This is an answer", "3"],
];

export default function DownloadResultCsv({ text = "Download full results (.csv)", data = defaultData, filename, surveyId }) {
	return (
		<CSVLink data={data} style={{ textDecoration: "none" }} filename={filename}>
			<Button
				onClick={() => {
					if (surveyId == 2) {
						handleMixPanel(`Plugin Net Promotor Score Survey Download Result`);
					} else if (surveyId == 4) {
						handleMixPanel(`Plugin Product Market Fit Survey Download Result`);
					} else if (surveyId == 5) {
						handleMixPanel(`Plugin Likert Scale Survey Download Result`);
					}
				}}
				variant="DS1"
			>
				{text}
			</Button>
		</CSVLink>
	);
}
