import styled from "@emotion/styled";
import { TextField, Typography } from "@mui/material";

export const InputFieldsWrapper = styled.div(({ theme }) => {
  return {
    display: "flex",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      marginTop: 0,
      marginBottom: 0,
    },
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    alignItems: "center",
    width: "100%",
  };
});

export const InputLabelField = styled(props => (
  <Typography variant="h4" {...props} />
))(({ theme, wrap }) => ({
  whiteSpace: wrap ? "pre-wrap" : "nowrap",
  marginRight: theme.spacing(1),
  [theme.breakpoints.down("md")]: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft: 0,
    marginRight: 0,
  },
}));

export const TextInpuField = styled(props => (
  <TextField autoComplete="off" {...props} />
))(({ theme, isNumber, isLargeNumber }) => ({
  marginRight: theme.spacing(1),
  maxWidth: isNumber ? "50px" : "100%",
  //The number input field for desktop screens should not be smaller so applying min-width
  minWidth: isNumber ? "50px" : "auto",
  ...(isLargeNumber && {
    maxWidth: "90px",
    minWidth: "90px",
  }),
  [theme.breakpoints.down("md")]: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft: 0,
    marginRight: 0,
    maxWidth: "100%",
    width: "100%",
  },
}));
