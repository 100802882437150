export const steps = [
  {
    label: "My Role",
    complete: false, // Needs to be false for prod
  },
  {
    label: "Investor Type",
    complete: false, // Needs to be false for prod
  },
  {
    label: "About Me",
    complete: false,
  },
  {
    label: "Top thing you look for",
    complete: false,
  },
  {
    label: "Startup Interest",
    complete: false,
  },
  {
    label: "Deal and Investment",
    complete: false,
  },
  //{
  //  label: "Investment Range",
  //  complete: false,
  //},
];
