import React, { useEffect, useState } from "react";
import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import { PaginationUI } from "./PaginationUI";
import { ErrorBoundary } from "react-error-boundary";
import TableHeader from "./TableHeader";
import ErrorMessage from "./ErrorMessage";

export default function TableController({
	checkboxSelection = false,
	rowSelectionModel,
	setRowSelectionModel,
	requestData,
	columnDefs,
	pageLength = 10,
	title,
	description,
	icon,
	showCount,
	countLabel = { singular: "", plural: "" },
	dataGridProps,
	noResultsMessage,
	onRowClick,
	onCellClick,
	sortComponent,
	searchComponent,
	filterComponent,
	contextKey,
	searchText = "",
	autoHeight = true,
}) {
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [page, setPage] = useState(0);
	const [pageSize, setPageSize] = useState(pageLength);
	const [rowCount, setRowCount] = useState(0);

	const fetchData = async (currentPage) => {
		setLoading(true);
		try {
			const result = await requestData(currentPage, pageSize, searchText);
			setData(result.data.models);
			setRowCount(result.data.totalElements);
		} catch (error) {
			console.error("Failed to fetch data:", error);
		}
		setLoading(false);
	};

	useEffect(() => {
		fetchData(page);
	}, [page, contextKey, pageSize]);

	return (
		<Box
			sx={{
				width: "100%",
				borderRadius: "12px",
				border: "1px solid var(--gray-200, #EAECF0)",
				background: "var(--base-white, #FFF)",
				boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)",
			}}
		>
			<TableHeader {...{ title, description, showCount, countLabel, icon, sortComponent, filterComponent, searchComponent }} rowCount={rowCount} />

			<ErrorBoundary fallback={<ErrorMessage />}>
				<DataGrid
					checkboxSelection={checkboxSelection}
					keepNonExistentRowsSelected={checkboxSelection}
					onRowSelectionModelChange={(newRowSelectionModel) => {
						if (typeof setRowSelectionModel === "function") setRowSelectionModel(newRowSelectionModel);
					}}
					rowSelectionModel={rowSelectionModel ? rowSelectionModel : []}
					disableRowSelectionOnClick={true}
					pagination
					paginationMode="server"
					rows={data}
					columns={columnDefs}
					rowCount={rowCount}
					loading={loading}
					getRowHeight={() => "auto"}
					autoHeight={autoHeight}
					pageSizeOptions={[pageSize]}
					{...dataGridProps}
					onRowClick={(params, event, details) => (typeof onRowClick === "function" ? onRowClick(params, event, details) : {})}
					onCellClick={(params, event, details) => (typeof onCellClick === "function" ? onCellClick(params, event, details) : {})}
					initialState={{
						pagination: {
							paginationModel: {
								page: 0,
								pageSize: pageSize,
							},
						},
					}}
					slots={{
						noRowsOverlay: () => <GridOverlay>{noResultsMessage}</GridOverlay>,
						noResultsOverlay: () => <GridOverlay>{noResultsMessage}</GridOverlay>,
						pagination: () => (
							<PaginationUI
								setPage={(newVal) => setPage(newVal)}
								pageSize={pageSize}
								setPageSize={setPageSize}
								pageSizeOptions={[10, 50, 100]}
								borderNone={true}
							/>
						),
					}}
					sx={{
						border: "none",
						maxHeight: "780px",
						height: data?.length > 0 ? "auto" : "400px",
						"& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-columnHeader:focus-within": {
							outline: "none",
						},
						"& .MuiDataGrid-columnHeaders": {
							borderBottom: "1px solid var(--gray-200, #EAECF0)",
							background: "#F9FAFB",
							display: "flex",
							height: "44px",
							padding: "12px 24px",
							gap: "12px",
							alignSelf: "stretch",
						},
						"& .MuiDataGrid-cell:focus": {
							outline: "none",
						},
						"& .MuiDataGrid-row": {
							px: "24px",
							cursor: "pointer",
						},
						"& .MuiDataGrid-cell": {
							display: "inline-flex",
							padding: "8px",
							alignItems: "center",
							gap: "8px",
						},
						"& .MuiDataGrid-footerContainer": {
							padding: "12px 24px 16px 24px",
							justifyContent: "unset", // MUI Places an empty Div
						},
						"& .MuiDataGrid-virtualScroller": {
							"&::-webkit-scrollbar": {
								width: "8px",
								height: "8px",
							},
							"&::-webkit-scrollbar-thumb": {
								backgroundColor: (theme) => theme?.palette?.primary?.main,
								borderRadius: "4px",
							},
							"&::-webkit-scrollbar-track": {
								backgroundColor: (theme) => theme?.palette?.primary?.light,
							},
						},
					}}
				/>
			</ErrorBoundary>
		</Box>
	);
}
