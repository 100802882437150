export const steps = [
  {
    label: "About Me",
    complete: false,
  },
  {
    label: "Superpower",
    complete: false,
  },
  {
    label: "Expertise",
    complete: false,
  },
];
