const DemofilterData = {
	Company: {
		"Current Stage": ["Pre-Seed", "Seed", "Series A", "Growth"],
		Financing: ["None", "Priced Round", "SAFE", "Conv-Note"],
		"Leading Indicators": [
			"Top Law Firm",
			"Top Accelerators",
			"High PMF",
			"Mvp in Market",
			"Top Hire",
			"Seeking Next Funding Round",
			"High NPS",
			"Scaling Fast",
			"Generating Revenue",
		],
		"Startups Joined Platform Within": ["30 days", "60 days", "90 days"],
	},
	Team: {
		"Team Members": [
			"Top University",
			"Executive Experience",
			"Seasoned Operator",
			"Top Company Alum",
			"Major Tech Company Experience",
			"Legacy Tech Company Experience",
			"Major Research Institution Experience",
			"Venture Background",
			"Prior VC Backed Executive",
			"Prior Exit",
			"Elite Industry Experience",
		],
	},
	Industry: {
		Industry: [
			{
				id: "0",
				name: "Business Products and Services (B2B)",
				children: [
					{
						id: "1",
						name: "Commercial Products",
					},
					{
						id: "2",
						name: "Commercial Services",
					},
					{
						id: "6",
						name: "Commercial Transportation",
					},
					{
						id: "7",
						name: "Other Business Products and Services",
					},
				],
			},
			{
				id: "0",
				name: "Cunsumer Products and Services(B2c)",
				children: [
					{
						id: "1",
						name: "Commercial Products",
					},
					{
						id: "2",
						name: "Commercial Services",
					},
					{
						id: "6",
						name: "Commercial Transportation",
					},
					{
						id: "7",
						name: "Other Business Products and Services",
					},
				],
			},
			{
				id: "0",
				name: "Energy",
				children: [
					{
						id: "1",
						name: "Commercial Products",
					},
					{
						id: "2",
						name: "Commercial Services",
					},
					{
						id: "6",
						name: "Commercial Transportation",
					},
					{
						id: "7",
						name: "Other Business Products and Services",
					},
				],
			},
			{
				id: "0",
				name: "Financial Services",
				children: [
					{
						id: "1",
						name: "Commercial Products",
					},
					{
						id: "2",
						name: "Commercial Services",
					},
					{
						id: "6",
						name: "Commercial Transportation",
					},
					{
						id: "7",
						name: "Other Business Products and Services",
					},
				],
			},
			{
				id: "0",
				name: "HealthCare",
				children: [
					{
						id: "1",
						name: "Commercial Products",
					},
					{
						id: "2",
						name: "Commercial Services",
					},
					{
						id: "6",
						name: "Commercial Transportation",
					},
					{
						id: "7",
						name: "Other Business Products and Services",
					},
				],
			},
			{
				id: "0",
				name: "Information Technology",
				children: [
					{
						id: "1",
						name: "Commercial Products",
					},
					{
						id: "2",
						name: "Commercial Services",
					},
					{
						id: "6",
						name: "Commercial Transportation",
					},
					{
						id: "7",
						name: "Other Business Products and Services",
					},
				],
			},
			{
				id: "0",
				name: "Materials and Resources",
				children: [
					{
						id: "1",
						name: "Commercial Products",
					},
					{
						id: "2",
						name: "Commercial Services",
					},
					{
						id: "6",
						name: "Commercial Transportation",
					},
					{
						id: "7",
						name: "Other Business Products and Services",
					},
				],
			},
			{
				id: "0",
				name: "Other",
				children: [
					{
						id: "1",
						name: "Commercial Products",
					},
					{
						id: "2",
						name: "Commercial Services",
					},
					{
						id: "6",
						name: "Commercial Transportation",
					},
					{
						id: "7",
						name: "Other Business Products and Services",
					},
				],
			},
		],
		Verticals: [
			{
				id: "0",
				name: "Business Products and Services (B2B)",
				children: [
					{
						id: "1",
						name: "Commercial Products",
					},
					{
						id: "2",
						name: "Commercial Services",
					},
					{
						id: "6",
						name: "Commercial Transportation",
					},
					{
						id: "7",
						name: "Other Business Products and Services",
					},
				],
			},
			{
				id: "0",
				name: "Cunsumer Products and Services(B2c)",
				children: [
					{
						id: "1",
						name: "Commercial Products",
					},
					{
						id: "2",
						name: "Commercial Services",
					},
					{
						id: "6",
						name: "Commercial Transportation",
					},
					{
						id: "7",
						name: "Other Business Products and Services",
					},
				],
			},
			{
				id: "0",
				name: "Energy",
				children: [
					{
						id: "1",
						name: "Commercial Products",
					},
					{
						id: "2",
						name: "Commercial Services",
					},
					{
						id: "6",
						name: "Commercial Transportation",
					},
					{
						id: "7",
						name: "Other Business Products and Services",
					},
				],
			},
			{
				id: "0",
				name: "Financial Services",
				children: [
					{
						id: "1",
						name: "Commercial Products",
					},
					{
						id: "2",
						name: "Commercial Services",
					},
					{
						id: "6",
						name: "Commercial Transportation",
					},
					{
						id: "7",
						name: "Other Business Products and Services",
					},
				],
			},
			{
				id: "0",
				name: "HealthCare",
				children: [
					{
						id: "1",
						name: "Commercial Products",
					},
					{
						id: "2",
						name: "Commercial Services",
					},
					{
						id: "6",
						name: "Commercial Transportation",
					},
					{
						id: "7",
						name: "Other Business Products and Services",
					},
				],
			},
			{
				id: "0",
				name: "Information Technology",
				children: [
					{
						id: "1",
						name: "Commercial Products",
					},
					{
						id: "2",
						name: "Commercial Services",
					},
					{
						id: "6",
						name: "Commercial Transportation",
					},
					{
						id: "7",
						name: "Other Business Products and Services",
					},
				],
			},
			{
				id: "0",
				name: "Materials and Resources",
				children: [
					{
						id: "1",
						name: "Commercial Products",
					},
					{
						id: "2",
						name: "Commercial Services",
					},
					{
						id: "6",
						name: "Commercial Transportation",
					},
					{
						id: "7",
						name: "Other Business Products and Services",
					},
				],
			},
			{
				id: "0",
				name: "Other",
				children: [
					{
						id: "1",
						name: "Commercial Products",
					},
					{
						id: "2",
						name: "Commercial Services",
					},
					{
						id: "6",
						name: "Commercial Transportation",
					},
					{
						id: "7",
						name: "Other Business Products and Services",
					},
				],
			},
			// "3D Printing",
			// "Ad tech",
			// "Advanced Manufacturing",
			// "Ag Tech",
			// "Artificial Inataligence and Machine Learning",
			// "Audio tech",
			// "Augumented Reality",
			// "Autonomous Cars",
			// "B2B Payments",
			// "Beauty",
			// "Big Data",
			// "Cannable",
			// "Car Sharing",
			// "Clean Tech",
			// "Climate Tech",
			// "Cloudtech And DevOps",
			// "Ed tech",
			// "Esports",
			// "Ferm Tech"
		],
	},
};

export default DemofilterData;
