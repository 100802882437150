import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import { Box, useMediaQuery } from "@mui/material";
import * as actions from "../../../../../modules/actions";
import { connect } from "react-redux";
import { useParams, useHistory, Prompt } from "react-router-dom";
import services from "../../../../../services";

import messages from "../../../../../constants/messages";
import constants from "../../../../../constants";
import { toastContainer } from "../../../../../utils/toast";
import LoadingButton from "../../../../../common/Loadingbutton";
import ExcerciseWrapper from "../../../../../components/Workspace/PowerUp/ExcerciseWrapper";
import ExcerciseSection from "../../../../../components/Workspace/PowerUp/ExcerciseSection";
import KnowledgeSidebar from "../../../../../components/Workspace/PowerUp/KnowledgeSidebar";
import {
  getExcerciseConfigurations,
  getExcerciseCompletionStatus,
} from "../Functions/functions";

import IdentifyInterviewGoals from "./IdentifyInterviewGoals";
import BuildInterviewScript from "./BuildInterviewScript";
import { sosPrimary } from "../../../../../constants/Theme/colors";
import ROUTES_PATH from "../../../../../constants/routes";
import theme from "../../../../../constants/Theme";

function PowerupExcercises({
  powerUpExcercises,
  setPowerUpExcercises,
  marketTest,
}) {
  const [userInputs, setUserInputs] = useState(null);
  const [excerciseConfigs, setExcerciseConfigs] = useState();
  const [excerciseSteps, setExcerciseSteps] = useState([]);
  const [currentExcercise, setCurrentExcercise] = useState();
  const [triggerSave, setTriggerSave] = useState(false);
  const params = useParams();
  const history = useHistory();
  const powerUpId = 4;
  const moduleId = params.id;
  const version = parseInt(params.version);
  const excerciseParam = params.excercise;
  const tabitems = constants.EXCERCISE_TAB_ITEMS;
  const [currenttab, setcurrenttab] = useState(tabitems[0].key);
  const [nextButtonLoading, setNextButtonLoading] = useState(false);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleBackClick = () => {
    if (currentExcercise.stepNo === 1) {
      //send to details page
      history.push(ROUTES_PATH.MARKET_TEST + "/" + marketTest.id);
    } else {
      setUserInputs(null);
      let currentStep = excerciseSteps.find(
        excercise => excercise.stepNo === currentExcercise.stepNo - 1
      );
      const newExcerciseData = excerciseConfigs.excercises.find(
        excercise => excercise.step_id === currentStep.id
      );
      setCurrentExcercise(syncData(newExcerciseData));
    }
  };
  const updatepowerUpExcercises = data => {
    return new Promise((resolve, reject) => {
      try {
        let existingPowerUpExcercises = [...powerUpExcercises];
        let currentIndex = existingPowerUpExcercises.findIndex(
          excercise => excercise.stepNo === data.stepNo
        );
        // if excercise already exists update
        let requestBody = null;
        if (currentIndex >= 0) {
          var excercise = existingPowerUpExcercises[currentIndex];
          excercise = {
            ...excercise,
            answers: data.answers,
            isLastStep: data.isLastStep,
            isCompleted: data.isCompleted,
          };
          existingPowerUpExcercises[currentIndex] = excercise;
          requestBody = excercise.answers;
        } else {
          //Else insert new one
          existingPowerUpExcercises.push(data);
          requestBody = data.answers;
        }
        services
          .storeModuleStepResponses(moduleId, {
            ...data,
            answers: JSON.stringify(requestBody),
          })
          .then(response => {
            if (response.data.code === 200) {
              setPowerUpExcercises(existingPowerUpExcercises);
              resolve(true);
            }
          })
          .catch(error => {
            reject(error);
            console.log(error);
          });
      } catch (error) {
        reject(error);
      }
    });
  };
  const handleNextClick = (updatedUserInputs = null) => {
    setNextButtonLoading(true);
    const isLastExcercise =
      currentExcercise.stepNo === excerciseConfigs.excercises.length;
    let data = {
      moduleResponseId: version,
      stepNo: currentExcercise.stepNo,
      answers: updatedUserInputs || userInputs,
      isLastStep: isLastExcercise,
      isCompleted: getExcerciseCompletionStatus(
        currentExcercise,
        updatedUserInputs || userInputs
      ),
      moduleStepId: currentExcercise.id,
    };
    updatepowerUpExcercises(data)
      .then(response => {
        if (response) {
          setNextButtonLoading(false);
          if (!data.isLastStep) {
            let currentStep = excerciseSteps.find(
              excercise => excercise.stepNo === currentExcercise.stepNo + 1
            );
            const newExcerciseData = excerciseConfigs.excercises.find(
              excercise => excercise.step_id === currentStep.id
            );
            setCurrentExcercise(syncData(newExcerciseData));
          } else {
            toastContainer(messages.SAVE_SUCCESS, "success");
            history.push(excerciseConfigs.summary_path);
          }
        }
      })
      .catch(error => {
        console.log(error);
        setNextButtonLoading(false);
        toastContainer(messages.EXCERCISE_STORE_ERROR, "error");
      });
  };

  const shouldShowPrompt = () => {
    if (userInputs && powerUpExcercises && currentExcercise) {
      const currentSavedStep = powerUpExcercises.find(
        ex => ex.stepNo === currentExcercise.step_number
      );
      if (currentSavedStep) {
        if (
          JSON.stringify(currentSavedStep.answers) ===
          JSON.stringify(userInputs)
        ) {
          return false;
        } else {
          return true;
        }
      } else {
        if (Object.keys(userInputs).length === 0) {
          return false;
        } else {
          return true;
        }
      }
    }
  };

  const BackButton = ({ style }) => {
    return (
      //<Button
      //  variant="outlined"
      //  color="primary"
      //  sx={{ mx: 1, ...style }}
      //  onClick={handleBackClick}
      //>
      //  Back
      //</Button>
      <Box></Box>
    );
  };

  const NextButton = () => {
    return (
      <LoadingButton
        variant="contained"
        color="primary"
        sx={{ mx: 1 }}
        loading={nextButtonLoading}
        onClick={() => handleNextClick()}
      >
        Next
      </LoadingButton>
    );
  };

  const syncData = jsonData => {
    const stepDetailsData = excerciseSteps.find(
      step => step.id === jsonData.step_id
    );
    return { ...jsonData, ...stepDetailsData };
  };

  const getLastExcercise = excercises => {
    excercises.sort((a, b) => (a.stepNo > b.stepNo ? 1 : -1));
    return excercises[excercises.length - 1].moduleStepId;
  };

  useEffect(() => {
    if (excerciseConfigs) {
      services
        .getModulesSteps("md-build-script-test")
        .then(result => {
          if (result.data?.data) {
            const steps = result.data.data;
            steps.sort((a, b) => (a.stepNo > b.stepNo ? 1 : -1));
            setExcerciseSteps(steps);
          }
        })
        .catch(err => console.log(err));
    }
  }, [excerciseConfigs]);

  const updateFirstStep = () => {
    let data = {
      moduleResponseId: version,
      stepNo: 1,
      answers: {},
      isLastStep: false,
      isCompleted: true,
      moduleStepId: excerciseConfigs.excercises[0].step_id,
    };
    updatepowerUpExcercises(data)
      .then(response => {
        if (response) {
          console.log();
        }
      })
      .catch(error => {
        console.log(error);
      });
  };
  useEffect(() => {
    if (excerciseConfigs && excerciseSteps && excerciseSteps.length) {
      //services
      //  .getModuleStepResponses(moduleId)
      //  .then(result => {
      //    const tempExcerciseDetails = result.data?.data.find(
      //      excercise => excercise.id === version
      //    );
      services
        .getModuleResponseDetailsByResponseId(moduleId, version)
        .then(res => {
          const tempExcerciseDetails = res?.data?.data;

          if (tempExcerciseDetails) {
            const excerciseDetails = tempExcerciseDetails.moduleStepResponses;
            if (!excerciseDetails || !excerciseDetails.length) {
              setPowerUpExcercises([]);
              updateFirstStep();

              setCurrentExcercise(syncData(excerciseConfigs.excercises[1]));
            } else {
              const mappedExcerciseDetails = excerciseDetails.map(
                excercise => ({
                  moduleResponseId: version,
                  moduleStepId: excercise.moduleStep.id,
                  answers: excercise.answers && JSON.parse(excercise.answers),
                  stepNo: excercise.moduleStep.stepNo,
                })
              );

              setPowerUpExcercises(mappedExcerciseDetails);
              if (excerciseParam === "start") {
                const lastExcerciseStepId = getLastExcercise(
                  mappedExcerciseDetails
                );
                let latestExcercise = excerciseConfigs.excercises.find(
                  excercise => excercise.step_id === lastExcerciseStepId
                );
                if (mappedExcerciseDetails.length === 1) {
                  latestExcercise = excerciseConfigs.excercises.find(
                    excercise => excercise.step_id === lastExcerciseStepId + 1
                  );
                }
                if (latestExcercise) {
                  setCurrentExcercise(syncData(latestExcercise));
                } else {
                  history.push(
                    `/workspace/md-ideavalidation/${moduleId}/${version}/summary`
                  );
                }
              } else if (excerciseParam === "retake") {
                setPowerUpExcercises([]);
                setCurrentExcercise(syncData(excerciseConfigs.excercises[0]));
              } else {
                setCurrentExcercise(
                  syncData(
                    excerciseConfigs.excercises.find(
                      excercise => excercise.path === excerciseParam
                    )
                  )
                );
              }
            }
          }
        })
        .catch(err => console.log(err));
    }
  }, [excerciseSteps]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    if (currentExcercise) {
      const currentItem = powerUpExcercises.find(
        excercise => excercise.stepNo === currentExcercise.stepNo
      );
      //Check if there is previously filled step and see its input
      if (currentItem) {
        if (currentItem.answers && Object.keys(currentItem.answers).length) {
          setUserInputs(currentItem.answers);
        } else {
          setUserInputs();
        }
      } else {
        setUserInputs();
      }
      setcurrenttab(tabitems[0].key);
      history.push(currentExcercise.path);
    }
  }, [currentExcercise]);

  useEffect(() => {
    setPowerUpExcercises([]);
    setExcerciseConfigs(getExcerciseConfigurations(powerUpId));
  }, []);

  const handleStepperStepChange = stepId => {
    const changedStep = excerciseConfigs.excercises.find(
      excercise => excercise.step_id === stepId
    );
    if (changedStep) {
      setCurrentExcercise(syncData(changedStep));
    }
  };

  const redirectToPowerupDetails = () => {
    history.push(ROUTES_PATH.MARKET_TEST + "/" + marketTest.id);
  };

  const redirectToBrowseCandidates = () => {
    let interview = marketTest.moduleResponseModels.find(
      item => item.module.moduleUuid === "md-interview-test"
    );

    history.push(
      `/workspace/powerup/4/browse-candidates/${interview.moduleId}/${interview.id}/browse`
    );
  };

  const FooterComponent = () => {
    return (
      <Box display={"flex"} justifyContent={"space-between"}>
        {currentExcercise?.stepNo === excerciseSteps.length ? (
          <>
            <Box>
              <Button variant="nofill" onClick={redirectToPowerupDetails}>
                Return to PowerUp Details
              </Button>
            </Box>
            <Box display={"flex"}>
              <Box sx={{ pr: 2 }}>
                <BackButton />
              </Box>
              <Box>
                <Button onClick={redirectToBrowseCandidates}>
                  Browse interview candidates (Coming Soon)
                </Button>
              </Box>
            </Box>
          </>
        ) : (
          <>
            <Box>
              <Button
                variant="nofill"
                sx={{ color: sosPrimary[500] }}
                onClick={() => {
                  history.push(ROUTES_PATH.MARKET_TEST + "/" + marketTest.id);
                }}
              >
                Return to Interview Home
              </Button>
            </Box>
            <Box display={"flex"}>
              <Box sx={{ pr: 1 }}>
                <BackButton />
              </Box>
              <Box>
                <NextButton />
              </Box>
            </Box>
          </>
        )}
      </Box>
    );
  };

  const FooterComponentResponsive = () => {
    return (
      <>
        {currentExcercise?.stepNo === excerciseSteps.length ? (
          <Box textAlign={"center"}>
            <LoadingButton
              variant="contained"
              color="primary"
              sx={{ mb: 1 }}
              onClick={() => {
                setTriggerSave(true);
              }}
              loading={nextButtonLoading}
            >
              Save script
            </LoadingButton>
            <BackButton style={{ mx: 0 }} />
          </Box>
        ) : (
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            letterSpacing={2}
          >
            <BackButton />
            <NextButton />
          </Box>
        )}
      </>
    );
  };

  return (
    <>
      {/*<RouterPrompt
        title={messages.UNSAVED_CHANGES_PROMPT_MESSAGE}
        when={shouldShowPrompt}
        onOK={() => true}
        onCancel={() => false}
        okText={"Ok"}
        cancelText={"Cancel"}
        stepPath={`/workspace/new-powerup/${powerUpId}/${currentExcercise?.path}`}
      />*/}
      <ExcerciseWrapper
        NextButton={NextButton}
        BackButton={BackButton}
        excerciseConfigs={excerciseConfigs}
        isSummaryExists={false}
        FooterComponent={FooterComponentResponsive}
        currentExcercise={currentExcercise}
      >
        {excerciseConfigs && (
          <ExcerciseSection
            setcurrenttab={setcurrenttab}
            currenttab={currenttab}
            excerciseConfigurations={excerciseConfigs}
            currentExcercise={currentExcercise}
            excerciseSteps={excerciseSteps}
            NextButton={NextButton}
            BackButton={BackButton}
            powerUpExcercises={powerUpExcercises}
            handleStepperStepChange={handleStepperStepChange}
            isSummaryExists={false}
            FooterComponent={FooterComponent}
            hideStepper={true}
          >
            {currentExcercise &&
              (currentExcercise.component === "IDENTIFY_INTERVIEW_GOALS" ? (
                <IdentifyInterviewGoals
                  userInputs={userInputs}
                  setUserInputs={setUserInputs}
                />
              ) : currentExcercise.component === "BUILD_INTERVIEW_SCRIPT" ? (
                <BuildInterviewScript
                  userInputs={userInputs}
                  setUserInputs={setUserInputs}
                  handleSave={handleNextClick}
                  buttonLoading={nextButtonLoading}
                  triggerSave={triggerSave}
                  setTriggerSave={setTriggerSave}
                />
              ) : null)}
          </ExcerciseSection>
        )}
        <KnowledgeSidebar currenttab={currenttab} powerUpId={powerUpId} />
      </ExcerciseWrapper>
    </>
  );
}

const mapStoreToProps = ({ powerUp }) => ({
  powerUpExcercises: powerUp?.powerUpExcercises,
  marketTest: powerUp?.marketTest,
});

const mapDispatchToProps = dispatch => ({
  setPowerUpExcercises: updatedSteps =>
    dispatch(actions.setPowerUpExcercises(updatedSteps)),
});

export default connect(mapStoreToProps, mapDispatchToProps)(PowerupExcercises);
