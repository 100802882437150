import React, { useEffect } from "react";
import { Switch, useRouteMatch, Route, Redirect } from "react-router-dom";
import MeetingsView from "../../components/Meetings/MeetingsView";
import Availability from "./Availability";
import Bookings from "./Bookings";
import MeetingScheduledScreen from "./MeetingScheduledScreen";
import MeetingSettings from "./MeetingSettings";
import MeetingLength from "./MeetingLength";
import { MeetingLengthForm } from "./MeetingLength/MeetingLengthForm";
import BookAMeeting from "./BookAMeeting";

export default function MeetingsController() {
	const { url, path } = useRouteMatch();
	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		});
	}, []);
	return (
		<Switch>
			<Route exact path={`${path}`}>
				<MeetingsView content={<Bookings />} activeKey={3} />
			</Route>
			<Route>
				<Redirect to={`${path}`} />
			</Route>
		</Switch>
	);
}
