import * as actionTypes from "./actionTypes";

export const setCandidateProfile = payload => {
  return dispatch => {
    dispatch({
      type: actionTypes.SET_CANDIDATE_PROFILE,
      payload,
    });
  };
};
