import React, { useState, useEffect } from "react";
import { Box, Typography, Grid, Select, MenuItem, styled, InputBase, Card } from "@mui/material";

import services from "../../../../services";
import AboutMe from "./AboutMe";
import Bio from "./Bio";
import Brands from "./Brands";
import Communications from "./Communications";
import Excitement from "./Excitement";
import Goals from "./Goals";
import Job from "./Job";
import Motivations from "./Motivations";
import Pain from "./Pain";
import Profile from "./Profile";
import Traits from "./Traits";
import Description from "./Description";
import { getPersonaBuilderPowerUP } from "./functions";
import { useSelector } from "react-redux";
import { Info, KeyboardArrowDown } from "@mui/icons-material";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
	"label + &": {
		marginTop: theme.spacing(3),
	},
	"& .MuiInputBase-input": {
		border: "none",
		position: "relative",
		backgroundColor: theme.palette.background.paper,
		fontSize: "30px",
		lineHeight: "45px",
		paddingLeft: "12px",
		paddingTop: "10px",
		paddingBottom: "10px",
		paddingRight: "60px !important",
		transition: theme.transitions.create(["border-color", "box-shadow"]),
		// Use the system font instead of the default Roboto font.
		fontFamily: "PoppinsSemiBold",
		"&:focus": {
			borderRadius: 4,
			borderColor: "#80bdff",
			boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
		},
	},
}));

export default function PersonaBuilderMarketOpportunity() {
	const STARTUP = useSelector((state) => state?.investorDashboard?.openStartupObj);
	const [personaArray, setPersonaArray] = useState(null);
	const [currentPersona, setCurrentPersona] = useState(null);
	const [currentPersonaIndex, setCurrentPersonaIndex] = useState(0);

	useEffect(() => {
		if (personaArray && personaArray[0]) {
			setCurrentPersona(personaArray[0]);
			setCurrentPersonaIndex(0);
		} else {
			setCurrentPersona(null);
			setCurrentPersonaIndex(0);
		}
	}, [personaArray]);

	const getPBValues = async () => {
		try {
			let PBArray = await getPersonaBuilderPowerUP(STARTUP?.id);
			setPersonaArray(PBArray);
		} catch (e) {
			console.log("Error when attempting to pull Persona Builder PowerUP Data. Failed with error: ", e);
		}
	};

	useEffect(() => {
		if (STARTUP && STARTUP?.id) {
			getPBValues();
		}
	}, []);

	const handleChange = (event) => {
		setCurrentPersona(personaArray[event.target.value]);
		setCurrentPersonaIndex(event.target.value);
	};

	return (
		<Box>
			<Box p="60px 60px 30px" sx={{ background: "#fff" }}>
				<Typography variant="main_title" sx={{ display: "block", mb: 2 }}>
					Persona Builder
				</Typography>
				<Select
					sx={{
						"& .MuiSelect-icon": {
							fontSize: "50px",
							color: "black",
							opacity: !personaArray || personaArray?.length <= 0 ? ".38" : "1",
						},
					}}
					value={currentPersonaIndex}
					onChange={handleChange}
					input={<BootstrapInput />}
					IconComponent={KeyboardArrowDown}
					disabled={!personaArray || personaArray?.length <= 0}
				>
					{personaArray?.map((obj, index) => {
						return (
							<MenuItem key={index} value={index}>
								{obj.objName}
							</MenuItem>
						);
					})}
					{!personaArray || (personaArray?.length <= 0 && <MenuItem value={0}>No Persona Data Found</MenuItem>)}
				</Select>
			</Box>
			<Box p="60px" sx={{ background: "#F9F9F9" }}>
				{personaArray && personaArray?.length > 0 ? (
					<Grid container spacing={"60px"}>
						<Grid item xs={4}>
							<Profile persona={currentPersona} />
							{currentPersona?.description && <Description persona={currentPersona} />}
							<AboutMe persona={currentPersona} />
							<Excitement persona={currentPersona} />
							<Brands persona={currentPersona} />
							<Goals persona={currentPersona} />
							<Job persona={currentPersona} />
						</Grid>
						<Grid item xs={8}>
							<Bio persona={currentPersona} />
							<Traits persona={currentPersona} />
							<Motivations persona={currentPersona} />
							<Pain persona={currentPersona} />
							{/* 
							// TODO -- API not providing the preferred communication values.
						*/}
							{/*<Communications />*/}
						</Grid>
					</Grid>
				) : (
					<Card
						sx={{
							borderRadius: "40px",
							p: {
								xs: 2,
								md: 3,
								lg: 4,
							},
							boxShadow: "0px 10px 15px rgba(0, 0, 0, 0.05)",
							display: "flex",
							gap: 2,
						}}
					>
						<Box>
							<Info
								sx={{
									color: (theme) => theme.palette.primary.main,
								}}
							/>
						</Box>
						<Box>
							<Typography color="black" variant="poppinsMedium16" component={"p"}>
								This startup has not completed their Persona Builder PowerUP at this time. We will continue to check for updates.
							</Typography>
						</Box>
					</Card>
				)}
			</Box>
		</Box>
	);
}
