import React, { useEffect } from "react";

import { Box, InputAdornment, Typography } from "@mui/material";
import { REGEX } from "../../../../../../../constants/regex";
import { useState } from "react";
import messages from "../../../../../../../constants/messages";
import {
  InputFieldsWrapper,
  InputLabelField,
  TextInpuField,
} from "../../styles";
import numeral from "numeral";

export default function Tam({ userInputs, setUserInputs, tamInputValid }) {
  const [errorMessages, setErrorMessages] = useState({
    acv: "",
    noOfPeople: "",
    timesPerYear: "",
  });

  const handleInputChange = (event, key) => {
    let value = event.target.value.trimStart();
    if (value && (key === "noOfPeople" || key === "acv")) {
      value = numeral(value).format("0,0");
    }
    const currentInput = { ...userInputs };
    currentInput[key] = value;
    setUserInputs(currentInput);
  };

  const handleBlur = (event, key) => {
    const value = event.target.value;

    switch (key) {
      case "acv":
        if (tamInputValid(key, value)) {
          setErrorMessages({ ...errorMessages, acv: "" });
        } else {
          setErrorMessages({
            ...errorMessages,
            acv: messages.ACV_INPUT_INVALID,
          });
        }
        break;
      case "noOfPeople":
        if (tamInputValid(key, value)) {
          setErrorMessages({ ...errorMessages, noOfPeople: "" });
        } else {
          setErrorMessages({
            ...errorMessages,
            noOfPeople: "Invalid",
          });
        }
        break;

      case "timesPerYear":
        if (tamInputValid(key, value)) {
          setErrorMessages({ ...errorMessages, timesPerYear: "" });
        } else {
          setErrorMessages({
            ...errorMessages,
            timesPerYear: "Invalid",
          });
        }
        break;
    }
  };

  return (
    <Box>
      <InputFieldsWrapper>
        <InputLabelField>
          My estimated Annual Contract Value, or ACV is
        </InputLabelField>

        <TextInpuField
          placeholder="ACV (USD)"
          onChange={e => handleInputChange(e, "acv")}
          value={userInputs?.acv}
          fullWidth
          onBlur={e => handleBlur(e, "acv")}
          error={!!errorMessages.acv}
          helperText={errorMessages.acv}
          InputProps={{
            startAdornment: userInputs?.acv && (
              <InputAdornment
                position="start"
                sx={{ fontWeight: "bold", fontSize: 40 }}
              >
                <Typography variant="h4" color="secondary">
                  $
                </Typography>
              </InputAdornment>
            ),
          }}
        />
      </InputFieldsWrapper>
      <InputFieldsWrapper>
        <InputLabelField>There are</InputLabelField>
        <TextInpuField
          placeholder="#"
          value={userInputs?.noOfPeople}
          onChange={e => handleInputChange(e, "noOfPeople")}
          error={!!errorMessages.noOfPeople}
          onBlur={e => handleBlur(e, "noOfPeople")}
        />
        <InputLabelField wrap={true}>
          {" "}
          people who will buy a product or service in my market an average of{" "}
        </InputLabelField>
      </InputFieldsWrapper>
      <InputFieldsWrapper>
        <TextInpuField
          placeholder="#"
          isLargeNumber={true}
          onChange={e => handleInputChange(e, "timesPerYear")}
          value={userInputs?.timesPerYear}
          error={!!errorMessages.timesPerYear}
          onBlur={e => handleBlur(e, "timesPerYear")}
        />
        <InputLabelField>times per year.</InputLabelField>
      </InputFieldsWrapper>
    </Box>
  );
}
