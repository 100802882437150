import React from "react";
import PropTypes from "prop-types";
import { Typography, Box, useMediaQuery } from "@mui/material";
import { TIMERCOLONICON } from "../../constants/image";
import { TimerBox } from "./styles";
import { sosPrimary } from "../../constants/Theme/colors";

const CountDownTimer = ({ days, hours, minutes, seconds }) => {
  const isMediumScreen = useMediaQuery(theme =>
    theme.breakpoints.between("md", "lg")
  );
  return (
    <TimerBox>
      <Typography
        sx={{
          textAlign: "center",
          fontSize: "36px !important",
          background: sosPrimary[50],
          borderRadius: "16px 4px 4px 16px",
          py: 1,
          px: isMediumScreen ? 0 : 1,
        }}
        variant="h1"
        color="primary"
      >
        <Box>
          <Typography variant="h6">D</Typography>
        </Box>{" "}
        {days}
      </Typography>
      <Typography variant="h1" color="primary">
        <img src={TIMERCOLONICON} />
      </Typography>
      <Typography
        sx={theme => ({
          textAlign: "center",
          background: sosPrimary[50],
          borderRadius: theme.shape.standard_small.borderRadius,
          py: 1,
          px: isMediumScreen ? 0 : 1,
        })}
        variant="h1"
        color="primary"
      >
        <Box>
          <Typography variant="h6">H</Typography>
        </Box>
        {hours}
      </Typography>
      <Typography variant="h1" color="primary">
        <img src={TIMERCOLONICON} />
      </Typography>

      <Typography
        sx={{
          textAlign: "center",
          background: sosPrimary[50],
          borderRadius: "4px 16px 16px 4px",
          py: 1,
          px: isMediumScreen ? 0 : 1,
        }}
        variant="h1"
        color="primary"
      >
        <Box>
          {" "}
          <Typography variant="h6">M</Typography>
        </Box>
        {minutes}
      </Typography>
      <Typography variant="h1" color="primary">
        <img src={TIMERCOLONICON} />
      </Typography>
      <Typography
        sx={{
          textAlign: "center",
          background: sosPrimary[50],
          borderRadius: "4px 16px 16px 4px",
          py: 1,
          px: isMediumScreen ? 0 : 1,
        }}
        variant="h1"
        color="primary"
      >
        <Box>
          {" "}
          <Typography variant="h6">S</Typography>
        </Box>

        {seconds}
      </Typography>
    </TimerBox>
  );
};

//TODO - add prop-types to all components
CountDownTimer.propTypes = {
  days: PropTypes.number,
  hours: PropTypes.number,
  minutes: PropTypes.number,
};

CountDownTimer.defaultProps = {
  days: 0,
  hours: 0,
  minutes: 0,
};
export default CountDownTimer;
