import styled from "@emotion/styled";

const ContentSeparator = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  justify-content: space-between;
  align-items: center;
`;

const LineSeparator = styled.div`
  border-style: ${props => (props.style ? props.style : "solid")};
  border-width: ${props => (props.height ? props.height : ".5px")};
  border-color: ${props => (props.color ? props.color : "black")};
  background-color: ${props => (props.color ? props.color : "black")};
`;

export const Separator = ({ ...props }) => {
  if (props && props.content) {
    return (
      <ContentSeparator>
        <LineSeparator {...props}></LineSeparator>
        <div>{props.content}</div>
        <LineSeparator {...props}></LineSeparator>
      </ContentSeparator>
    );
  } else {
    return <LineSeparator {...props}></LineSeparator>;
  }
};
