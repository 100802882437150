import { Box, Stack } from "@mui/material";
import React, { useState, useCallback, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import AdminPowerupCard from "../PowerupCard/PowerupCourseCard";
import { useLocation } from "react-router-dom";
import ROUTES_PATH from "../../../../constants/routes";

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);

	return result;
};

const grid = 2;

const getItemStyle = (isDragging, draggableStyle) => ({
	userSelect: "none",
	margin: `0 0 ${grid}px 0`,
	...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
	width: 1,
});

const DraggablePowerups = ({ powerups, setPowerups }) => {
	const location = useLocation();
	const isSettingsOpen = location.pathname.includes(ROUTES_PATH.ADMIN_POWERPACK_SETTINGS);

	const [items, setItems] = useState(powerups);

	useEffect(() => {
		setItems(powerups);
	}, [powerups]);

	const onDragEnd = useCallback(
		(result) => {
			// dropped outside the list
			if (!result.destination) {
				return;
			}

			const newItems = reorder(items, result.source.index, result.destination.index);

			setItems(newItems);
			setPowerups(newItems);
		},
		[items]
	);
	const handleDelete = (uuid) => {
		const newItems = items.filter((item) => item.uuid !== uuid);
		setItems(newItems);
		setPowerups(newItems);
	};

	const handleStatusChange = (powerupCard, newStatus) => {
		const newItems = items.map((item) => (item.cardId === powerupCard?.cardId ? { ...item, entityStatus: newStatus } : item));
		setItems(newItems);
		setPowerups(newItems);
	};
	return (
		<DragDropContext onDragEnd={onDragEnd}>
			<Droppable droppableId="droppable">
				{(provided, snapshot) => (
					<Stack spacing={2} {...provided.droppableProps} ref={provided.innerRef} sx={getListStyle(snapshot.isDraggingOver)}>
						{items?.map((item, index) => (
							<Draggable
								key={isSettingsOpen ? JSON.stringify(item?.id) : item?.uuid}
								draggableId={isSettingsOpen ? JSON.stringify(item?.id) : item?.uuid}
								index={index}
							>
								{(provided, snapshot) => (
									<Box
										ref={provided.innerRef}
										{...provided.draggableProps}
										{...provided.dragHandleProps}
										// sx={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
									>
										<AdminPowerupCard
											title={item?.title || item?.name}
											imageUrl={item?.imageUrl}
											isAiPowered={true}
											shortDescription={item?.shortDescription}
											readOnly={false}
											onDelete={() => handleDelete(item.uuid)}
											isSettingsOpen={isSettingsOpen}
											entityStatus={item.entityStatus}
											onStatusChange={(newStatus) => handleStatusChange(item, newStatus)}
										>
											{item.content}
										</AdminPowerupCard>
									</Box>
								)}
							</Draggable>
						))}
						{provided.placeholder}
					</Stack>
				)}
			</Droppable>
		</DragDropContext>
	);
};

export default DraggablePowerups;
