import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { WorkspaceCard } from "../../../../components/Workspace/Card/styles";
import ScoreProgress from "./ScoreProgress";
import { SO_SCORE_ICON, SCORE_CIRCLE_IMG } from "../../../../constants";
import DialogComponent from "../../../../common/CommonDialog";
import services from "../../../../services";
import { handleMixPanel } from "../../../../utils/mixPanelEventHandling";

const StartupOSScore = ({ id, value }) => {
	const [open, setOpen] = useState(false);
	const [score, setScore] = useState(null);
	const authData = useSelector(state => state?.auth);

	const handleOpen = () => setOpen(true);

	const getCompanyScore = async () => {
		const companyScore = await services.getCompanyScore(authData?.companyId);
		setScore(companyScore?.data?.data);
	};

	useEffect(() => {
		getCompanyScore();
		handleMixPanel("Dashboard Viewed", {
			"Profile": authData?.userType,
			"Score": score?.totalScore
		});
	}, []);

	return (
		<WorkspaceCard
			variant="outlined"
			sx={{
				boxShadow: theme => theme.palette.primary.elevationLight2,
				// backgroundColor: theme => theme.palette.text.background,
				border: "0px !important", //Added important to override border property
			}}
		>
			<Box
				sx={{
					p: 3,
				}}
			>
				<Grid container sx={{ pb: 5 }}>
					<Grid item lg={12} md={12} sm={12} xs={12}>
						<Box display="flex" alignItems={"center"}>
							<Box component={"img"} src={SO_SCORE_ICON} alt="score" />
							<Typography variant="headline6" sx={{ ml: 2 }}>
								StartupOS Score
							</Typography>
						</Box>
					</Grid>
				</Grid>

				<Grid container>
					<Grid
						item
						lg={12}
						md={12}
						sm={12}
						xs={12}
						display={"flex"}
						justifyContent="center"
						position="relative"
						sx={[
							{ pb: 2 },
							theme => ({
								[theme.breakpoints.down("md")]: {
									justifyContent: "center",
								},
							}),
						]}
					>
						<Box
							sx={{
								position: "absolute",
								maxWidth: "90px",
								textAlign: "center",
								left: score?.totalScore ? "50%" : "auto",
								top: score?.totalScore ? "50%" : "auto",
								mt: score?.totalScore ? 0 : "35px",
								transform: score?.totalScore ? "translate(-50%, calc(-50% - 8px))" : null,
							}}
						>
							<Typography variant={score?.totalScore ? "main_title" : "label_small"} sx={{ color: score?.totalScore ? "#5630B2" : "#1D1B1E" }}>
								{score?.totalScore ? score?.totalScore : "Collecting data for your StartupOS score"}
							</Typography>{" "}
						</Box>
						<Box component="img" src={SCORE_CIRCLE_IMG}></Box>
					</Grid>
					<Grid item lg={12} md={12} sm={12} xs={12}>
						<Typography variant="title_medium" sx={{ color: theme => theme.palette.secondary.korophKoal }}>
							Keep it up!
						</Typography>
						<Typography variant="body_medium" sx={{ mt: 1, color: theme => theme.palette.secondary.korophKoal }} display={"grid"}>
							Complete PowerUps and PlugIns to increase your StartupOS score. Once you reach a score threshold, you can share your startup profile with
							potential investors!
						</Typography>
					</Grid>
					{/*<Box sx={{ pt: 4, ml: "auto" }}>
            <InfoOutlinedIcon onClick={handleOpen} />
          </Box>*/}
				</Grid>
			</Box>
			<Box>
				{" "}
				<DialogComponent
					isOpen={open}
					onClose={() => {
						setOpen(false);
					}}
					title={"StartupOS Score"}
					titleVariant={"title_large"}
					contentStyle={{ px: 0 }}
					maxWidth={"sm"}
					fullWidth
					PaperProps={{
						sx: theme => ({ borderRadius: theme.shape.standard.borderRadius }),
					}}
				>
					<Box sx={{ p: 3 }}>
						<Typography variant="body_large">
							Diam varius dictum proin sit donec mauris at dui. Dui condimentum consequat lectus aliquam mauris sed nulla feugiat est. Pellentesque nunc aliquam
							leo vitae quam tincidunt lectus placerat elementum. Et urna, ut sed euismod neque id. Diam, ornare suscipit dignissim massa morbi elit. Pulvinar
							ac eu ac nibh ipsum interdum. Arcu, in sit morbi ut ac elementum ligula.
						</Typography>
					</Box>
				</DialogComponent>
			</Box>
		</WorkspaceCard>
	);
};

export default StartupOSScore;
