import React, { useEffect, useState } from "react";
import { Box, Grid, TextField, Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import { StepTitle, Stepper } from "../ui";
import { PARTNER_QA_ICON } from "../../../../constants";
import { InputLabelStyle, InputPropsStyle } from "./about";
import services from "../../../../services";

export const PartnerQuestions = ({ step, totalStep, control, errors, setSchemaData, setTotalStep }) => {
	const [resData, setResData] = useState([]);

	const handleAnswerChange = (index, newValue) => {
		const updatedResData = [...resData];

		const questionToUpdate = updatedResData[index];

		if (!questionToUpdate.answers) {
			questionToUpdate.answers = {};
		}
		questionToUpdate.answers.answerText = newValue;

		setResData(updatedResData);
		setSchemaData(updatedResData);
	};

	const getPartnerQApiCall = async () => {
		try {
			let response = await services.getPartnerQAAPI();
			const data = response.data?.data;
			if (data) {
				setTotalStep(5);
			} else {
				setTotalStep(4);
			}
			setResData(data);
			setSchemaData(data);
		} catch (error) {
			console.log(error, "Unable to successfully retrieve partner questions and answers");
		}
	};
	useEffect(() => {
		getPartnerQApiCall();
	}, []);
	return (
		<Box width="100%">
			{resData?.length > 0 && (
				<>
					<Stepper step={step} totalStep={totalStep} />
					<StepTitle title="Partner Questions" icon={PARTNER_QA_ICON} />
				</>
			)}
			<Box display="flex" justifyContent="center">
				<Grid container spacing={2}>
					{resData?.map((qData, index) => (
						<Grid item xs={12} key={index}>
							<Box mt={1} ml={2} display="flex" flexDirection="row">
								<Typography variant="subhead1" color="#667085">
									{`${index + 1}. ${qData?.question?.name}`}
								</Typography>
							</Box>
							<Box mt={1}>
								<Controller
									name={`partnerQA${index}`}
									control={control}
									render={({ field: { onChange, value, name } }) => (
										<TextField
											fullWidth
											id={`partnerQA${index}`}
											value={value}
											name={name}
											sx={{
												"& .MuiOutlinedInput-notchedOutline": {
													border: "1px solid #DDDDDD",
												},
											}}
											InputProps={{ style: InputPropsStyle, placeholder: qData?.question?.placeholder || "" }}
											InputLabelProps={InputLabelStyle}
											onChange={(e) => {
												onChange(e);
												handleAnswerChange(index, e.target.value);
											}}
											inputProps={{ maxLength: qData?.question?.maxAnswerLength || 5000 }}
										/>
									)}
								/>
								{errors[`partnerQA${index}`] && (
									<Box mt={1}>
										<Typography variant="subhead2" sx={{ color: "#F04438" }}>
											{errors[`partnerQA${index}`].message}
										</Typography>
									</Box>
								)}
							</Box>
						</Grid>
					))}
				</Grid>
			</Box>
		</Box>
	);
};

export default PartnerQuestions;
