import { Box, Typography, TextField, FormControl } from "@mui/material";
import React, { useState } from "react";
import DialogComponent from "../../../../../common/CommonDialog";
import LoadingButton from "../../../../../common/Loadingbutton";

export default function WebPowerUpPopup({
  open,
  onClose,
  title,
  mainContent,
  actionBar,
}) {
  return (
    <>
      <DialogComponent
        isOpen={open}
        onClose={onClose}
        title={title}
        titleVariant={"title_large"}
        contentStyle={{ px: 0 }}
        maxWidth={"sm"}
        fullWidth
        PaperProps={{
          style: { borderRadius: 28 },
        }}
        dialogAction={actionBar}
        disableBackDropClick
      >
        {mainContent}
      </DialogComponent>
    </>
  );
}
