import React, { useMemo, useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Box, Button, Typography, Link, styled } from "@mui/material";

import { OnboardLayout, Welcome, Title, Stepper, AboutMe, AccountType, CreateAccount, Expertise, StartupInfo, SuperPower, ValidationModal } from "./Components";
import services from "../../services";
import { toastContainer } from "../../utils/toast";
import ROUTES_PATH from "../../constants/routes";
import { USER_TYPES } from "../../utils/userTypes";
import * as url from "../../utils/urls";
import { getEmailToken, getUserEmail } from "../../utils/sessionStorage";

import { setAuthData } from "../../modules/actions";
import { handleMixPanel, handleMixPanelSetUser } from "../../utils/mixPanelEventHandling";
import VerifyEmailConfirmation from "./Components/steps/VerifyEmailConfirmation";
import messages from "../../constants/messages";

const STEP_TITLES = [
	{ id: 0, title: "Select Role" },
	{ id: 1, title: "Create an Account" },
	{ id: 2, title: "About Me" },
	{ id: 3, title: "My Superpower" },
	{ id: 4, title: "Guidance Needed" },
	{ id: 5, title: "Startup Info" },
];

const STEPPER_COMPONENTS = [
	{ id: 0, component: AccountType },
	{ id: 1, component: CreateAccount },
	{ id: 2, component: AboutMe },
	{ id: 3, component: SuperPower },
	{ id: 4, component: Expertise },
	{ id: 5, component: StartupInfo },
];

export const Onboard = () => {
	const location = useLocation();
	const history = useHistory();
	const dispatch = useDispatch();
	const [step, setStep] = useState(0);
	const [isActive, setIsActive] = useState(false);
	const [onboardData, setOnboardData] = useState({});
	const [registerData, setRegisterData] = useState({});
	const [isNext, setIsNext] = useState(null);
	const [errors, setErrors] = useState({});
	const [steps, setSteps] = useState(STEP_TITLES.slice(0, 3));
	const [stepComponents, setStepComponents] = useState(STEPPER_COMPONENTS);
	const [idToken, setIdToken] = useState("");
	const [userType, setUserType] = useState("");
	const [signUpRes, setSignUpRes] = useState({});
	const [isVerifyEmailPageShow, setVerfifyEmailPageShow] = useState(false);

	useEffect(() => {
		if (location.pathname.includes("teammate")) {
			const tempSteps = STEP_TITLES.slice(0, 5);
			tempSteps.shift();
			setSteps(tempSteps);

			const tempComponents = STEPPER_COMPONENTS.slice(0, 5);
			tempComponents.shift();
			setStepComponents(tempComponents);

			setRegisterData({ userType: USER_TYPES.STARTUP_USER, email: getUserEmail() });
			setUserType(USER_TYPES.STARTUP_USER);
		} else {
			setSteps(STEP_TITLES.slice(0, 3));
			setStepComponents(STEPPER_COMPONENTS);
		}
	}, [location?.pathname]);

	useEffect(() => {
		if (userType === USER_TYPES.STARTUP_OWNER) {
			const tempSteps = STEP_TITLES;
			tempSteps[4].title = "Guidance Needed";
			setSteps([...tempSteps]);
		} else if (userType === USER_TYPES.MENTOR_USER) {
			const tempSteps = STEP_TITLES.slice(0, 5);
			tempSteps.splice(3, 1);
			tempSteps[3].title = "My Expertise";
			setSteps([...tempSteps]);
		} else if (userType === USER_TYPES.INVESTOR_USER) {
			setSteps([...STEP_TITLES.slice(0, 3)]);
		}
	}, [userType]);

	const handleUserType = (type) => {
		setUserType(type);
	};

	const handleNextStep = () => {
		if ((step === steps.length - 1 && isVerifyEmailPageShow) === false) {
			console.log("---MixPanel Data---", {
				"User Type": userType,
				Step: steps[step]?.title,
				"Step Number": step,
			});

			handleMixPanel(steps[step]?.title, {
				"User Type": userType,
				Step: steps[step]?.title,
				"Step Number": step,
			});
		}

		setIsNext(step);
		if (step === steps.length - 1) {
			if (isVerifyEmailPageShow) {
				handleResendVerificationEmail();
			}
		}
	};

	const handleStep = (stepIndex) => {
		// setStep(stepIndex);
		// setIsActive(false);
		// setIsNext(stepIndex + 1);
		if (idToken) {
			if (stepIndex <= step && stepIndex > (location.pathname.includes("teammate") ? 0 : 1)) {
				setStep(stepIndex);
				setIsActive(false);
				setIsNext(stepIndex + 1);
			}
		} else {
			if (stepIndex <= step) {
				setStep(stepIndex);
				setIsActive(false);
			}
		}
		setVerfifyEmailPageShow(false);
	};

	const handleActive = (isActive) => {
		setIsActive(isActive);

		if (isActive === false) {
			setIsNext(false);
		}
	};

	const handleError = (errs) => {
		setErrors({ ...errs });
	};

	const handleUpdateData = (currentStep, stepData) => {
		if (currentStep > (location.pathname.includes("teammate") ? 0 : 1)) {
			setOnboardData({
				...onboardData,
				...stepData,
			});
		} else {
			setRegisterData({
				...registerData,
				...stepData,
			});
		}

		if (step === (location.pathname.includes("teammate") ? 0 : 1)) {
			handleRegister({
				...registerData,
				...stepData,
				emailToken: getEmailToken(),
			});
		} else {
			if (step === steps.length - 1) {
				setOnboardData({
					...onboardData,
					...stepData,
				});
				handleOnboard({
					...onboardData,
					...stepData,
				});
				if (!isVerifyEmailPageShow) {
					setVerfifyEmailPageShow(true);
				}
			} else {
				setStep(step + 1);
				setIsActive(false);
			}
		}
	};

	const handleRegister = async (data) => {
		try {
			const register = await services.registerUser(data);
			setStep(step + 1);
			setIsActive(false);
			setIdToken(register?.data?.data?.idToken || "");
			setSignUpRes(register?.data?.data);

			handleMixPanelSetUser(register?.data?.data);
		} catch (e) {
			setIsNext(false);
			toastContainer(e?.response.data?.message || "Something is wrong!", "error");
		}
	};

	const handleOnboard = async (data) => {
		fetch(url.userOnboard(), {
			method: "PUT",
			headers: { "Content-Type": "application/json", Authorization: "Bearer " + idToken },
			body: JSON.stringify(data),
		})
			.then((response) => response.json())
			.then((res) => {
				handleMixPanel("Onboarding Completed", {
					"User ID": signUpRes?.id,
					"User Name": signUpRes?.name,
					"User Role": userType,
					"User Email": signUpRes?.email,
					City: res?.data?.city,
					State: res?.data?.state,
				});
			})
			.catch((e) => {
				setIsNext(false);
				toastContainer(e?.response?.data?.message || "Something is wrong!", "error");
			});
	};

	const updateStateAndRedirect = (profileData) => {
		dispatch(
			setAuthData({
				idToken,
				userType,
				userId: signUpRes?.id,
				email: signUpRes?.email,
				calUserId: signUpRes?.calUserId,
				userName: signUpRes?.name,
				userData: {
					picture: signUpRes.picture,
				},
				isPremiumPlanAccess: signUpRes?.companyModel?.isPremiumPlanAccess ? signUpRes?.companyModel?.isPremiumPlanAccess : false,
				companyName: profileData?.companyModel?.companyName, // use profileData
				companyId: profileData?.companyModel?.id,
				authenticatedAppUser: true,
				userSessionChange: false,
			})
		);

		// I don't think we need to set Mixpanel user here again since this user is already set into Mixpanel just after Register
		// Instead of setting user, I think we need to track this as Account Login event since user logs in without AuthorizeSuccess.
		// handleMixPanelSetUser(userDetails);
		handleMixPanel("Account Login", {
			"User ID": signUpRes?.id,
			"User Name": signUpRes?.name,
			"User Role": userType,
		});

		if (userType === USER_TYPES.STARTUP_OWNER || userType === USER_TYPES.STARTUP_USER) {
			history.push(ROUTES_PATH.STARTUPS_HOME);
		} else if (userType === USER_TYPES.MENTOR_USER) {
			history.push(ROUTES_PATH.MY_MENTOR_PROFILE);
		} else {
			history.push(ROUTES_PATH.MY_ACCOUNT_PROFILE);
		}
	};

	const getProfileData = async () => {
		const resp = await fetch(url.userProfile(), {
			method: "GET",
			headers: { "Content-Type": "application/json", Authorization: "Bearer " + idToken },
		});
		const respData = await resp.json();
		return respData?.data;
	};

	const handleResendVerificationEmail = async () => {
		try {
			const profileData = await getProfileData();
			if (!profileData?.emailVerified) {
				const resp = await fetch(url.resendEmailUrl, {
					method: "POST",
					headers: { "Content-Type": "application/json", Authorization: "Bearer " + idToken },
					body: JSON.stringify({ email: signUpRes?.email }),
				});
				const respData = await resp.json();
				if (!respData?.error) {
					toastContainer(messages.VERIFICATION_EMAIL, "success");
				}
			} else {
				updateStateAndRedirect(profileData);
			}
		} catch (error) {
			toastContainer(error?.response?.data?.message || "Something is wrong!", "error");
		}
	};

	const alreadyVerified = async () => {
		fetch(url.userProfile(), {
			method: "GET",
			headers: { "Content-Type": "application/json", Authorization: "Bearer " + idToken },
		})
			.then((resp) => resp.json())
			.then(async (resp) => {
				if (resp?.data) {
					if (resp?.data?.emailVerified) {
						updateStateAndRedirect(resp?.data);
					} else {
						toastContainer("Email is not verified", "success");
					}
				}
			})
			.catch((e) => {
				toastContainer(e?.response?.data?.message || "Something is wrong!", "error");
			});
	};

	const btnNextStyle = useMemo(() => {
		if (isActive) {
			return {
				background: "#7B61FF",
				color: "#FFFFFF",
			};
		}

		return {
			background: "#FFFFFF",
			color: "#000000",
		};
	}, [isActive]);

	const loadCurrentStepper = useMemo(() => {
		const Component = stepComponents[step].component;

		return (
			<Component
				isNext={isNext === step}
				currentStep={step}
				userType={userType}
				data={step > 1 ? onboardData : registerData}
				onActive={handleActive}
				onUpdate={handleUpdateData}
				onUserType={handleUserType}
				onError={handleError}
			/>
		);
	}, [step, isNext, userType, stepComponents]);

	return (
		<OnboardLayout>
			<Welcome />
			<Title step={step} userType={userType} isVerifyEmailPageShow={isVerifyEmailPageShow} />
			<Stepper step={step} isActive={isActive} steps={steps} subTitle={registerData?.userType} onChooseStep={handleStep} />
			<Box width="100%" mt="40px">
				{!isVerifyEmailPageShow && loadCurrentStepper}
				{isVerifyEmailPageShow && <VerifyEmailConfirmation userType={userType} userEmail={signUpRes?.email} />}
			</Box>
			<Box textAlign="center" p={step === 1 ? "30px 0 0" : "30px 0"} m="0 auto">
				<Box display="flex" alignItems="center" justifyContent="center" gap="14px">
					<Box>
						<BTN_NEXT
							variant="contained"
							step={step}
							stepLength={steps.length - 1}
							btnNextStyle={btnNextStyle}
							disabled={!isActive}
							onClick={handleNextStep}
							isVerifyEmailPageShow={isVerifyEmailPageShow}
						>
							<Typography variant="filter_save_text" color={btnNextStyle.color}>
								{isVerifyEmailPageShow ? "Click here to confirm your account" : "Next"}
								{/* {step === steps.length - 1 ? `Submit & continue to the app` : `Next`} */}
							</Typography>
						</BTN_NEXT>
					</Box>

					{/* {Object.keys(errors).length > 0 && <ValidationModal errors={errors} />} */}
				</Box>

				{step === 1 && (
					<Box mt={2.5}>
						<Typography variant="onsurface_title">By clicking the Next button, you agree to our </Typography>
						<Link target="_blank" underline="none" href="https://startupos.com/terms-of-service/">
							<Typography variant="poppinsSemiBold18" color={"#7B61FF"}>
								Terms & Conditions
							</Typography>
						</Link>
						<Typography variant="onsurface_title">&nbsp;& </Typography>
						<Link target="_blank" underline="none" href="https://startupos.com/privacy-policy/">
							<Typography variant="poppinsSemiBold18" color={"#7B61FF"}>
								Privacy Policy.
							</Typography>
						</Link>
					</Box>
				)}

				{isVerifyEmailPageShow && (
					<Box variant="text" sx={{ mt: 2.5 }}>
						<Typography variant="Text/sm/Regular">I am already verified. </Typography>
						{"  "}
						<Typography variant="Text/sm/Semibold" sx={{ textDecoration: "underline", cursor: "pointer" }} onClick={alreadyVerified}>
							Click here to login.
						</Typography>
					</Box>
				)}
			</Box>
		</OnboardLayout>
	);
};

const BTN_NEXT = styled(Button)((props) => ({
	position: "relative",
	height: "60px",
	padding: "10px 40px",
	minWidth: "120px",
	maxWidth: !props.isVerifyEmailPageShow ? "120px" : "450px",
	// width: props.step < props.stepLength ? "120px" : "330px",
	borderRadius: "100px",
	color: props.btnNextStyle.color,
	background: props.btnNextStyle.background,
	zIndex: 1,
	"&:active": {
		background: props.btnNextStyle.background,
	},
	"&:focus": {
		background: props.btnNextStyle.background,
	},
	"&::before": {
		content: '""',
		position: "absolute",
		borderRadius: "100px",
		top: 0,
		left: 0,
		width: "100%",
		height: "100%",
		color: "#fff",
		background: "linear-gradient(320.08deg, #DE0085 12.44%, #7B61FF 92.01%)",
		opacity: 0,
		transition: "opacity 0.8s",
		zIndex: -1,
	},
	"&:hover::before": {
		opacity: 1,
	},
	"&:disabled": { background: "#FFFFFF", color: "#000000" },
}));
