import React, { useState, useEffect } from "react";
import { Box, Container } from "@mui/material";
import { getSurveyDetails } from "./functions";
import ToolHeroCard from "./components/cards/ToolHeroCard/ToolHeroCard";
import TabBar from "../PowerUP/components/buttons/TabBar";
import LearnIt from "./compositions/LearnIt/LearnIt";
import DoIt from "./compositions/DoIt/DoIt";
import services from "../../services";
import { handleMixPanel } from "../../utils/mixPanelEventHandling";
import { useSelector } from "react-redux";
import { BreadcrumbsComponent } from "../../components/Breadcrumbs";

export const PowerUPContext = React.createContext();

export const ToolContainer = () => {
	const [activeTab, setActiveTab] = useState(0);
	const [knowledgeActiveTab, setKnowledgeTab] = useState(0);
	const [toolDetails, setToolDetails] = useState(null);
	const [moduleSteps, setmoduleSteps] = useState(null);
	const [videoPlaying, setVideoPlaying] = useState(false);
	const [responses, setResponses] = useState(null);
	const [versions, setVersions] = useState(null);
	const [versionModalOpen, setVersionModalOpen] = useState(false);
	const [versionPopoverOpen, setVersionPopoverOpen] = useState(false);
	const [resetConfirmModalOpen, setResetConfirmModalOpen] = useState(false);
	const [lastSaved, setLastSaved] = useState(null);
	const [isCompanySurvey, setIsCompanySurvey] = useState(false);
	const [customImage, setCustomImage] = useState({ imageUrl: "" });

	const [surveyResponseDetails, setSurveyResponseDetails] = useState();
	const currentPath = window.location.pathname;
	const parts = currentPath.split("/");
	const surveyId = parts[parts.length - 1];
	const userDetails = useSelector((state) => state?.auth);

	useEffect(() => {
		getDetails();
	}, []);

	const tabSelected = (ind) => {
		if (ind === 0) setActiveTab(ind);
		else {
			setActiveTab(ind);
		}
	};
	const handleGetSurveyResponseApiCall = (smCollectorId) => {
		services
			.getSurveyResponse(smCollectorId)
			.then((res) => {
				const responseData = res?.data?.data;
				const questionsArray = responseData?.questions ? JSON.parse(responseData?.questions) : [];
				if (questionsArray.length > 0) {
					setCustomImage({ imageUrl: questionsArray[0]?.imageUrl });
				}
				responseData?.answers_for_csv?.rows[0].map((uniqueItem, uniqueIdx) => {
					if (
						uniqueItem.includes("Extremely likely - 10") &&
						responseData.answers_for_csv.rows[0][0] === "How likely is it that you would recommend this company to a friend or colleague?"
					) {
						let temp = uniqueItem.replace("Extremely likely - ", "");
						responseData.answers_for_csv.rows[0][uniqueIdx] = temp;
					}
				});

				setSurveyResponseDetails(responseData);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const handleSurveyEvents = (eventName, details) => {
		if (surveyId && details) {
			handleMixPanel(eventName, {
				"User name": userDetails?.userName,
				"User id": userDetails?.userId,
				"Survey name": details?.surveyName,
				"Survey id": surveyId,
			});
		}
	};

	const getDetails = async () => {
		const currentPath = window.location.pathname;
		const parts = currentPath.split("/");
		const lastPart = parts[parts.length - 1];
		const details = await getSurveyDetails(lastPart);
		const responseData = details?.data?.data;
		if (details) {
			handleSurveyEvents("Survey Viewed", responseData);
		}
		if (responseData?.companySurvey?.length > 0) {
			setIsCompanySurvey(true);
			handleGetSurveyResponseApiCall(responseData?.companySurvey[0]?.smCollectorId);
		}
		setToolDetails(details?.data?.data);
	};

	const resetConfirm = () => {
		setResetConfirmModalOpen(true);
	};

	return (
		<PowerUPContext.Provider
			value={{
				activeTab,
				setActiveTab,
				toolDetails,
				moduleSteps,
				knowledgeActiveTab,
				setKnowledgeTab,
				videoPlaying,
				setVideoPlaying,
				responses,
				setResponses,
				versions,
				versionModalOpen,
				setVersionModalOpen,
				versionPopoverOpen,
				setVersionPopoverOpen,
				lastSaved,
				setLastSaved,
				resetConfirm,
				resetConfirmModalOpen,
				setResetConfirmModalOpen,
				surveyResponseDetails,
				isCompanySurvey,
				surveyId,
				getDetails,
				setToolDetails,
				customImage,
				setCustomImage,
			}}
		>
			<Container sx={{ p: 3 }}>
				<Box pb={4}>
					<BreadcrumbsComponent
						bedcrumDetails={[
							{ title: "Home", route: "/" },
							{ title: toolDetails?.surveyName || "", route: "" },
						]}
					/>
				</Box>

				<ToolHeroCard />
				<Box
					sx={{
						my: "2.5rem",
					}}
				>
					<TabBar onSelect={(ind) => tabSelected(ind)} selectedTabIndex={activeTab} tabGroup={"default"} />
				</Box>
				{activeTab === 0 && <LearnIt />}
				{activeTab === 1 && <DoIt />}
			</Container>
		</PowerUPContext.Provider>
	);
};
