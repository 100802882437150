import {
  Tooltip,
  Box,
  tooltipClasses,
  Typography,
  useMediaQuery,
  ClickAwayListener,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

const DefaultComponent = () => {
  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          m: "4px",
          gap: 1,
        }}
      >
        <AccessTimeIcon />
        <Typography
          color="primary"
          variant="title_medium"
          whiteSpace={"nowrap"}
        >
          Coming Soon ...
        </Typography>
      </Box>
    </React.Fragment>
  );
};

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.secondary.white,
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(12),
    border: `1px solid ${theme.palette.primary.main}`,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.primary.main,
  },
}));

const HtmlTooltipMobile = styled(({ className, ...props }) => (
  <ClickAwayListener>
    <Tooltip
      {...props}
      classes={{ popper: className }}
      onClose={() => props.handleTooltip()}
      onClickAway={() => {
        return;
      }}
      open={props.open}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      onClick={() => props.handleTooltip()}
    />
  </ClickAwayListener>
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.secondary.white,
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(12),
    border: `1px solid ${theme.palette.primary.main}`,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.primary.main,
  },
}));

const TooltipWrapper = ({ children, text, placement }) => {
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("md"));
  const [open, setOpen] = useState(false);

  const handleTooltip = () => {
    setOpen(!open);
  };
  useEffect(() => {
    if (open) {
      setTimeout(() => {
        handleTooltip();
      }, "1000");
    }
  }, [open]);

  return isSmallScreen ? (
    <HtmlTooltipMobile
      arrow
      title={text}
      disableInteractive
      placement={placement ? placement : "top"}
      //isSmallScreen={isSmallScreen}
      handleTooltip={handleTooltip}
      open={open}
    >
      <Box>{children}</Box>
    </HtmlTooltipMobile>
  ) : (
    <HtmlTooltip
      arrow
      title={text}
      disableInteractive
      placement={placement ? placement : "top"}
    >
      <Box>{children}</Box>
    </HtmlTooltip>
  );
};

{
  //TooltipWrapper.propTypes = {
  //  children: PropTypes.func,
  //  text: PropTypes.string || PropTypes.func,
  //  placement: PropTypes.string,
  //};

  TooltipWrapper.defaultProps = {
    children: null,
    text: <DefaultComponent />,
    placement: "top",
  };
}
export default TooltipWrapper;
