import services from "../../../../services";

export const moatProcessingAI = async (valueProp, competitorLandscape, cb) => {
	cb({ key: "moatAI", values: { loading: true, complete: false } });

	/*
	"valueProp": "Introducing Pillows for Hamsters, where dreams come true for our tiny furry friends. Tired of mediocre bedding options? Say goodbye to the dull and hello to a world of comfort, luxury, and whimsy. Our premium-grade pillows are exclusively designed for hamsters, providing unparalleled comfort and support. Our unique differentiator lies in our commitment to crafting pillows specifically tailored to the needs of hamsters, ensuring a sleep experience like no other. With features like extra fluffy filling and a variety of adorable designs, our pillows offer a cozy retreat for your hamster's slumbers. Our core strengths, including attention to detail and a passion for enhancing the lives of our smallest companions, drive us to create exceptional products that stand apart from the alternatives. Join us in creating a dreamy haven for hamsters, and unlock the true potential of your furry friend's sleep. Hello, hamster happiness awaits you.",
    "COMPETITOR_1": "Pillows for Hamsters",
    "COMPETITOR_2": "Carefresh",
    "COMPETITOR_3": "Kaytee",
    "COMPETITOR_4": "Oxbow",
    "FEATURE_1": "Premium-grade materials",
    "FEATURE_2": "Tailored specifically for hamsters",
    "FEATURE_3": "Extra fluffy filling",
    "FEATURE_4": "Variety of adorable designs",
    "FEATURE_5": "Attention to detail and passion for enhancing pet lives",


	
	competitorLandscape:
			valueProp: "...",
			COMPETITOR_1: "...",
			COMPETITOR_2: "...",
			COMPETITOR_3: "...",
			COMPETITOR_4: "...",
			FEATURE_1: "...",
			FEATURE_2: "...",
			FEATURE_3: "...",
			FEATURE_4: "...",
			FEATURE_5: "...",
	*/

	if (!valueProp) {
		cb({ key: "moatAI", values: { loading: false, complete: true } });
		return;
	}

	let payload = {
		messages: [
			{
				role: "system",
				content: `Given the user provided value proposition and details provided about the product and its competitors, please generate five distinct statements/examples that would serve as content for a 'moat' slide in a pitch deck presentation to potential investors. These should highlight the competitive advantages and barriers to entry that the startup has or is building. 
				Format your response as a JSON stringified array.
				
				Example response format:		
				[ "Moat statement 1", "Moat statement 2", "Moat statement 3", "Moat statement 4", "Moat statement 5" ]
                `,
			},
			{
				role: "user",
				content: JSON.stringify({
					valueProp: valueProp,
					COMPETITOR_1: competitorLandscape?.COMPETITOR_1,
					COMPETITOR_2: competitorLandscape?.COMPETITOR_2,
					COMPETITOR_3: competitorLandscape?.COMPETITOR_3,
					COMPETITOR_4: competitorLandscape?.COMPETITOR_4,
					FEATURE_1: competitorLandscape?.FEATURE_1,
					FEATURE_2: competitorLandscape?.FEATURE_2,
					FEATURE_3: competitorLandscape?.FEATURE_3,
					FEATURE_4: competitorLandscape?.FEATURE_4,
					FEATURE_5: competitorLandscape?.FEATURE_5,
				}),
			},
		],
		maxTokens: 1200,
	};

	let attempts = 0;
	while (attempts < 2) {
		try {
			let AIResp = await services.getOpenAIChatResponse(payload).catch((e) => {
				console.log(e);
			});
			cb({ key: "moatAI", values: { loading: false, complete: true } });
			if (AIResp?.data?.data?.[0]?.text) {
				return JSON.parse(AIResp?.data?.data?.[0]?.text);
			} else if (AIResp?.data?.data?.[0]?.message?.content) {
				return JSON.parse(AIResp?.data?.data?.[0]?.message?.content);
			} else {
				attempts++;
			}
		} catch (error) {
			console.error(error);
		}
	}
	return false;
};

