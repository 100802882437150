import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import BIDataGrid from "./BIDataGrid";
import { get } from "lodash";
import { TextField, InputAdornment, Box, useMediaQuery, Typography, IconButton } from "@mui/material";
import { useGridApiContext } from "@mui/x-data-grid";
import { convertNumberToLocale, partners, updateCapGridData } from "../Functions/common";
import { CALCULATOR_V1_ICON } from "../../../../constants";
import { handleMixPanel } from "../../../../utils/mixPanelEventHandling";
import SaveData from "./SaveData";
import { useSelector, useDispatch } from "react-redux";
import { setBIDashboardData } from "../../../../modules/actions/biDashboardActions";
import { toastContainer } from "../../../../utils/toast";
import LeaveConfirm from "./LeaveConfirm";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import { useLocation } from "react-router-dom";

const disabledRowIds = [5];

export const shareClassName = {
	common: "Common",
	seriesSeed: "Series Seed Preferred",
	seriesA: "Series A Preferred",
	poolOutstanding: "Option & Grant Pool - Shares Outstanding",
	poolAvailable: "Option & Grant Pool - Shares Available",
	total: "Total",
};

const emptyFields = {
	authorizedShares: null,
	outstanding: null,
	outstandingOwnership: null,
	fullyDiluted: null,
	fullyDilutedOwnership: null,
	amountRaised: null,
};

const sampleRows = [
	{
		rowId: 0, // this rowId internally become id which makes easier to update rows
		shareClassName: shareClassName.common,
		...emptyFields,
	},
	{
		rowId: 1,
		shareClassName: shareClassName.seriesSeed,
		...emptyFields,
	},
	{
		rowId: 2,
		shareClassName: shareClassName.seriesA,
		...emptyFields,
	},
	{
		rowId: 3,
		shareClassName: shareClassName.poolOutstanding,
		...emptyFields,
	},
	{
		rowId: 4,
		shareClassName: shareClassName.poolAvailable,
		...emptyFields,
	},
	{
		rowId: 5,
		shareClassName: shareClassName.total,
		...emptyFields,
	},
];

const firstColumnwidth = "400px"; //also first column if change this

const columns = [
	{ field: "shareClassName", headerName: "Date", width: 400, editable: false, sortable: false, align: "left", headerAlign: "left" },
	{
		field: "authorizedShares",
		headerName: "Authorized Shares",
		type: "number",
		editable: true,
		sortable: false,
		align: "center",
		headerAlign: "center",
		flex: 1,
		minWidth: 150,
		width: 250,
		cellClassName: (params) => {
			if (params?.row?.shareClassName === shareClassName.poolAvailable) {
				return "disabled--cell";
			}
		},
	},
	{
		field: "outstanding",
		headerName: "Outstanding",
		type: "number",
		editable: true,
		sortable: false,
		align: "center",
		headerAlign: "center",
		flex: 1,
		minWidth: 150,
	},
	{
		field: "outstandingOwnership",
		headerName: "Ownership",
		type: "number",
		editable: false,
		sortable: false,
		align: "center",
		headerAlign: "center",
		flex: 1,
		minWidth: 150,
		cellClassName: "disabled--cell",
		headerClassName: "disabled--cell",
	},
	{
		field: "fullyDiluted",
		headerName: "Fully Diluted",
		type: "number",
		editable: true,
		sortable: false,
		align: "center",
		headerAlign: "center",
		flex: 1,
		minWidth: 150,
	},
	{
		field: "fullyDilutedOwnership",
		headerName: "Ownership",
		type: "number",
		editable: false,
		sortable: false,
		align: "center",
		headerAlign: "center",
		flex: 1,
		minWidth: 150,
		cellClassName: "disabled--cell",
		headerClassName: "disabled--cell",
	},
	{
		field: "amountRaised",
		headerName: "Amount Raised",
		type: "number",
		editable: true,
		sortable: false,
		align: "center",
		headerAlign: "center",
		flex: 1,
		minWidth: 150,
	},
];

function CapGrid({
	// title = "Ownership & Fully Diluted Shares",
	gridData,
	readOnly,
	usedCompanyId,
	isEdited,
	setEdited,
}) {
	const [rows, setRows] = useState(sampleRows);
	const [saving, setSaving] = useState(false);
	const dispatch = useDispatch();
	const openCapData = useSelector((store) => store?.biDashboard?.openCapData);
	const openCapDataLeave = useSelector((store) => store?.biDashboard?.openCapDataLeave);
	const nextEditTableName = useSelector((store) => store?.biDashboard?.nextEditTableName);
	const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
	const openEdit = useSelector((store) => store?.biDashboard?.openEdit);
	const capTableName = useSelector((store) => store?.biDashboard?.capTableName);
	const partnerObj = Array.isArray(gridData) && gridData?.sort((a, b) => b.lastModifiedAt - a.lastModifiedAt)?.[0];
	const displayName = partners.filter((item) => item?.title === partnerObj?.partnerType)?.[0]?.displayName;
	const picture = partners.filter((item) => item?.title === partnerObj?.partnerType)?.[0]?.picture;
	const location = useLocation();
	const startupDetails = location?.state?.startupDetails;
	const { isAdmin } = useSelector((state) => state?.auth);
	function PartnerDesign() {
		return (
			<Box display="flex" alignItems="flex-start" flexDirection={"column"} gap={"4px"}>
				<Box component={"img"} src={picture} alt="partner" />
				<Typography component={"p"} sx={{ textAlign: "left" }} variant="Text/sm/Medium" color="sos.gray600">
					{`Connected to ${displayName ? displayName : ""} `}
				</Typography>
				<Typography variant="Text/xs/Regular" color="sos.gray600">
					{`${moment(partnerObj?.lastModifiedAt)?.format("MMMM D, YYYY h:mm A")} EST`}{" "}
				</Typography>
			</Box>
		);
	}

	useEffect(() => {
		if (gridData?.length > 0) {
			//ordering rows based on shareClassName
			let commonRow = {};
			let seriesSeedRow = {};
			let seriesARow = {};
			let poolOutstandingRow = { ...emptyFields, shareClassName: shareClassName.poolOutstanding }; //adjustment for old records
			let poolAvailableRow = { ...emptyFields, shareClassName: shareClassName.poolAvailable }; //adjustment for old records
			let totalRow = {};
			for (let index = 0; index < gridData.length; index++) {
				const element = gridData[index];
				if (get(element, "shareClassName") === shareClassName.common) {
					commonRow = element;
				}
				if (get(element, "shareClassName") === shareClassName.seriesSeed) {
					seriesSeedRow = element;
				}
				if (get(element, "shareClassName") === shareClassName.seriesA) {
					seriesARow = element;
				}
				if (get(element, "shareClassName") === shareClassName.poolOutstanding) {
					poolOutstandingRow = element;
				}
				if (get(element, "shareClassName") === shareClassName.poolAvailable) {
					poolAvailableRow = element;
				}
				if (get(element, "shareClassName") === shareClassName.total) {
					totalRow = element;
				}
			}
			if (totalRow?.authorizedShares !== null) {
				//adjustment for old records
				totalRow.authorizedShares = null;
			}
			const tempRow = [commonRow, seriesSeedRow, seriesARow, poolOutstandingRow, poolAvailableRow, totalRow];
			setRows(tempRow.map((row, index) => ({ ...row, rowId: index })));
		}
	}, [gridData, openCapData]);

	const valueFormatter = (params) => {
		const { id, field, value } = params;

		if (get(rows, `${id}.${field}`, null) === null) {
			if (disabledRowIds.includes(id) || field === "outstandingOwnership" || field === "fullyDilutedOwnership") {
				if (field === "authorizedShares") {
					return "";
				}
				return (
					<>
						<Box component="img" src={CALCULATOR_V1_ICON} sx={{ width: "14px", height: "14px" }} />-
					</>
				);
			}
			return "";
		}

		let localeValue = convertNumberToLocale(value);

		if (field === "amountRaised") {
			return `$${localeValue}`;
		}

		if (field === "outstandingOwnership" || field === "fullyDilutedOwnership") {
			return `${localeValue}%`;
		}

		return `${localeValue}`;
	};

	const updateColumn = (tempRows, field, totalSum, fieldToUpdate) => {
		let totalSumOfFieldToUpdate = 0;
		let allFieldNull = true;

		for (let index = 0; index < tempRows.length - 1; index++) {
			const element = tempRows[index];
			const calculatedValue = element[field] !== null && totalSum ? (element[field] / totalSum) * 100 : null;
			element[fieldToUpdate] = calculatedValue !== null ? parseFloat(Number(calculatedValue).toFixed(2)) : null;

			if (calculatedValue !== null) {
				allFieldNull = false;
				totalSumOfFieldToUpdate = totalSumOfFieldToUpdate + calculatedValue;
			}
		}

		if (allFieldNull) {
			totalSumOfFieldToUpdate = null;
		}

		tempRows[sampleRows.length - 1][fieldToUpdate] = totalSumOfFieldToUpdate !== null ? parseFloat(Number(totalSumOfFieldToUpdate).toFixed(2)) : null; // last row of total
	};

	const calculateFieldsValues = (id, value, field) => {
		const tempRows = [...rows];

		//current column
		tempRows[id][field] = value;

		let totalSum = 0;
		let allFieldNull = true;

		for (let index = 0; index < tempRows.length - 1; index++) {
			//avoid last row of Total
			const element = tempRows[index];
			if (element[field] !== null) {
				allFieldNull = false;
				totalSum = totalSum + element[field];
			}
		}

		if (allFieldNull) {
			totalSum = null;
		}

		//don't total for authorizedShares column
		if (field !== "authorizedShares") {
			tempRows[sampleRows.length - 1][field] = totalSum !== null ? parseFloat(Number(totalSum).toFixed(2)) : null; // last row of total
		}

		if (field === "outstanding") {
			updateColumn(tempRows, field, totalSum, "outstandingOwnership");
		}

		if (field === "fullyDiluted") {
			updateColumn(tempRows, field, totalSum, "fullyDilutedOwnership");
		}

		setRows(tempRows);
	};

	function EditInputCell(props) {
		const { id, value: valueProp, field } = props;
		const [value, setValue] = useState(valueProp);
		const apiRef = useGridApiContext();
		const textFieldRef = useRef(null);

		const handleChange = (event) => {
			let newValue = event.target.value; // The new value entered by the user
			newValue = newValue !== null && newValue !== "" ? Math.abs(Number(newValue).toFixed(2)) : null;
			apiRef.current.setEditCellValue({ id, field, value: newValue, debounceMs: 200 });
			setValue(newValue);
			const tempRows = [...rows];
			tempRows[id][field] = newValue;
			setRows(tempRows);
			calculateFieldsValues(id, newValue, field);
			if (!isEdited && setEdited) {
				setEdited(true);
			}
		};

		const inputRef = useCallback((node) => {
			if (node) {
				textFieldRef.current.focus();
			}
		}, []);

		useEffect(() => {
			// setValue(valueProp);
			setValue(get(rows, `${id}.${field}`, null));
		}, [valueProp]);

		return (
			<TextField
				type="number"
				value={value !== null ? value : ""}
				onChange={handleChange}
				ref={inputRef}
				inputRef={textFieldRef}
				fullWidth
				InputProps={{
					...(field === "amountRaised" && { startAdornment: <InputAdornment position="start">$</InputAdornment> }),
				}}
			/>
		);
	}

	const renderEditCell = (params) => {
		return <EditInputCell {...params} />;
	};

	const handleCellEditStart = (param, event) => {
		if (param?.field === "authorizedShares" && param?.row?.shareClassName === shareClassName.poolAvailable) {
			event.stopPropagation();
			event.defaultMuiPrevented = true;
		}
	};

	const handleSave = async () => {
		try {
			setSaving(true);

			const payLoad = rows.map((row) => {
				const {
					id = null,
					shareClassName,
					authorizedShares,
					outstanding,
					outstandingOwnership,
					fullyDiluted,
					fullyDilutedOwnership,
					amountRaised,
				} = row;
				return {
					...(id && { id: id }),
					shareClassName,
					authorizedShares,
					outstanding,
					outstandingOwnership,
					fullyDiluted,
					fullyDilutedOwnership,
					amountRaised,
				};
			});

			const capResponse = await updateCapGridData(payLoad);
			dispatch(setBIDashboardData({ capData: capResponse }));

			handleMixPanel(capTableName?.includes("OwnerShip") ? `Grid Ownership Edited` : `Grid Amount Raised Viewed`);

			toastContainer("Data Saved", "Success");
			if (isEdited && setEdited) {
				setEdited(false);
			}
			dispatch(
				setBIDashboardData({
					openCapData: false,
					openCapDataLeave: false,
				})
			);
		} catch (error) {
			console.log(error, "error");
			toastContainer(error?.response?.data?.message || "Something is wrong!", "error");
			throw error;
		} finally {
			setSaving(false);
		}
	};

	const onCloseIconClick = () => {
		if (isEdited) {
			dispatch(
				setBIDashboardData({
					openCapDataLeave: true,
				})
			);
		} else {
			dispatch(
				setBIDashboardData({
					...(openCapData && { openCapData: false }),
					...(openEdit && { openEdit: false }),
				})
			);
		}
	};

	const onNoClick = () => {
		if (isEdited && setEdited) {
			setEdited(false);
		}
		if (nextEditTableName && openEdit) {
			dispatch(
				setBIDashboardData({
					openCapDataLeave: false,
					editTableName: nextEditTableName,
					nextEditTableName: "",
				})
			);
		} else {
			dispatch(
				setBIDashboardData({
					openCapData: false,
					openCapDataLeave: false,
					openEdit: false,
					nextEditTableName: "",
				})
			);
		}
	};

	const onYesClick = async () => {
		try {
			await handleSave();
			onNoClick();
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<>
			<Box display="flex" justifyContent="space-between" alignItems="center" pb={2}>
				{/* <Typography variant="Text/md/Semibold">{capTableName}</Typography> */}
				{partnerObj?.partnerType ? <PartnerDesign /> : <Typography variant="Text/md/Semibold">{capTableName}</Typography>}
				{!(isSmallScreen && openEdit) && (
					<IconButton aria-label="close" onClick={onCloseIconClick} sx={{ color: (theme) => theme.palette.secondary.dark }}>
						<CloseIcon sx={{ color: (theme) => theme.palette.secondary.black }} />
					</IconButton>
				)}
			</Box>
			<BIDataGrid
				rows={rows}
				columns={columns.map((column, index) => ({
					...column,
					...(index !== 0 && { renderCell: valueFormatter }),
					renderEditCell: renderEditCell,
				}))}
				getRowId={(row) => get(row, "rowId", null)}
				disabledFields={["outstandingOwnership", "fullyDilutedOwnership"]}
				disabledRowIds={disabledRowIds}
				disabledCells={[
					{ id: 4, field: "authorizedShares" },
					{ id: 5, field: "authorizedShares" },
				]}
				readOnly={readOnly}
				firstColumnwidth={firstColumnwidth}
				onCellEditStart={handleCellEditStart}
			/>
			<SaveData isEdited={isEdited} saving={saving} readOnly={readOnly} onCancelClick={onCloseIconClick} onSaveClick={handleSave} />
			<LeaveConfirm
				saving={saving}
				isOpen={openCapDataLeave}
				onClose={() => {
					dispatch(setBIDashboardData({ openCapDataLeave: false, nextEditTableName: "" }));
				}}
				onYesClick={onYesClick}
				onNoClick={onNoClick}
			/>
		</>
	);
}

export default memo(CapGrid);
