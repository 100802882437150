import React, { useState, useEffect } from "react";
import { Box, Typography, Divider, Button, IconButton } from "@mui/material";
import { ARROWS_OUT_ICON, PITCH_DECK_LOGO, PPT_YELLOW_ICON, THREE_DOT_CIRCLE_ICON } from "../../../../constants";
import { downloadURI, openInFullScreen } from "../../../../utils/commonFunctions";
import { Download, East, West } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { setStartupProfileData } from "../../../../modules/actions";
import { UploadPitchDeckBox } from "../../../../containers/Profile/StartupProfile/EditStartupProfile/style";

function PitchDeckViewer({ openStartupProfileObj, isEdit }) {
	const [pitchDeck, setPitchDeck] = useState("");
	const [pitchType, setPitchType] = useState("");
	const dispatch = useDispatch();

	useEffect(() => {
		if (openStartupProfileObj?.pitchDeckLink) {
			let type = openStartupProfileObj?.pitchDeckLink.substr(openStartupProfileObj?.pitchDeckLink?.length - 3);

			setPitchDeck(openStartupProfileObj?.pitchDeckLink);
			if (type === "pdf") {
				setPitchType("pdf");
			} else {
				setPitchType("ppt");
			}
		}
	}, [openStartupProfileObj]);

	const handleFullScreen = () => {
		const elem = document.getElementById("iframe-container");
		openInFullScreen(elem);
	};

	const handleOpenFileUploadModal = () => {
		dispatch(
			setStartupProfileData({
				uploadFileSlidingModalOpen: true,
				openUploadFileModalData: {
					title: "Pitch Deck",
					selectFileText: "Select a file to upload your deck",
					dimensionText: "Accepted file types .pdf, .ppt, .pptx(Max 500MB)",
					aspectRatio: 4 / 1,
					imageType: "PITCH_DECK",
					fileType: {
						"application/vnd.openxmlformats-officedocument.presentationml.presentation": [],
						"application/vnd.ms-powerpoint": [],
						"application/pdf": [],
					},
					circularCropper: false,

					videoType: "COMPANY_ABOUTUS_VIDEO",
					videoUrl: "",
				},
			})
		);
	};

	return (
		<Box display="flex" flexDirection="column" rowGap={4}>
			<Box display="flex" alignItems="center">
				<Box display="flex" alignItems="center" gap={2}>
					<Box component="img" src={PPT_YELLOW_ICON} sx={{ width: "65px", height: "65px" }} />
					{/* <Typography variant="growth_progress_text" color="secondary.black">
						Slide
					</Typography>
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							border: `2px solid black`,
							borderRadius: "50%",
							height: "25px",
							width: "25px",
							justifyContent: "center",
						}}
						onClick={() => {
							const leftSlideBtn = document.getElementById("ButtonFastBack-Small14");
							leftSlideBtn?.click();
						}}
					>
						<West fontSize="small" />
					</Box>

					<Typography variant="growth_progress_text" color="secondary.black">
						1 of 22
					</Typography>
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							border: `2px solid black`,
							borderRadius: "50%",
							height: "25px",
							width: "25px",
							justifyContent: "center",
						}}
						onClick={() => {
							// const frame = document.getElementById("iframe-slides");
							// let innerDoc = frame?.contentDocument || frame?.contentWindow?.document;

						}}
					>
						<East fontSize="small" />
					</Box>

					<Divider orientation="vertical" sx={{ height: "25px", width: "5px", borderColor: (theme) => theme.palette.secondary.black }} />
					<Box component="img" src={ARROWS_OUT_ICON} sx={{ width: "35px", height: "35px" }} onClick={handleFullScreen} />
					<Divider orientation="vertical" sx={{ height: "25px", width: "5px", borderColor: (theme) => theme.palette.secondary.black }} />
					<Box component="img" src={THREE_DOT_CIRCLE_ICON} sx={{ width: "35px", height: "35px" }} /> */}
				</Box>
				<Box ml="auto">
					{isEdit ? (
						<Button onClick={handleOpenFileUploadModal} variant="containedNewStyle" color="secondary.black">
							<Typography variant="card_text" color="secondary.white">
								Edit Deck
							</Typography>
						</Button>
					) : (
						<Button
							onClick={() => downloadURI(openStartupProfileObj?.pitchDeckLink)}
							variant="containedNewStyle"
							color="secondary.black"
							startIcon={<Download />}
						>
							<Typography variant="card_text" color="secondary.white">
								Download Now
							</Typography>
						</Button>
					)}
				</Box>
			</Box>
			{!openStartupProfileObj?.pitchDeckLink && <UploadPitchDeckBox />}
			{openStartupProfileObj?.pitchDeckLink && (
				<Box sx={{ position: "relative", overflow: "hidden", width: "100%", paddingTop: "56.25%" }} id="iframe-container">
					{pitchType === "ppt" && (
						<iframe
							src={`https://view.officeapps.live.com/op/embed.aspx?src=${openStartupProfileObj?.pitchDeckLink}&&slide=12`}
							frameBorder="0"
							id="iframe-slides"
							title="slides"
							style={{
								position: "absolute",
								top: 0,
								left: 0,
								bottom: 0,
								right: 0,
								width: "100%",
								height: "100%",
								borderRadius: "30px",
								border: "none",
								margin: 0,
								padding: 0,
								overflow: "hidden",
							}}
						></iframe>
					)}

					{pitchType === "pdf" && (
						<iframe
							src={openStartupProfileObj?.pitchDeckLink}
							id="iframe-pdf"
							frameBorder="0"
							style={{
								position: "absolute",
								top: 0,
								left: 0,
								bottom: 0,
								right: 0,
								width: "100%",
								height: "100%",
								borderRadius: "30px",
								border: "none",
								margin: 0,
								padding: 0,
								overflow: "hidden",
							}}
						></iframe>
					)}
				</Box>
			)}
		</Box>
	);
}

export default PitchDeckViewer;
