import React from "react";
import { Grid } from "@mui/material";

export default function GridLayout({ left, right }) {
	return (
		<Grid container spacing={2}>
			<Grid item sm={12} md={4} lg={4} xl={4}>
				{left}
			</Grid>
			<Grid item sm={12} md={8} lg={8} xl={8}>
				{right}
			</Grid>
		</Grid>
	);
}
