import React, { useState, useEffect } from "react";
import { stateToMarkdown } from "draft-js-export-markdown";
import { useSelector } from "react-redux";
import { Box, Typography, Grid, Button } from "@mui/material";
import DialogComponent from "../../PowerUP/IdeaValidation/Components/Dialog";
import TextEditorComp from "../../../../common/TextEditor";
import theme from "../../../../constants/Theme";
import DateTimeSlotPicker from "../../../../common/DateTimeslotPicker";
import LoadingButton from "../../../../common/Loadingbutton";
import MeetingDetailComponent from "../../../../components/Workspace/PowerUp/MeetingDetails";
import { validateAdvisorPluginInput } from "../../Plugins/AdvisorPlugin/Functions/common";
import services from "../../../../services";
import { sanitizeDateTimePayload } from "../../Plugins/AdvisorPlugin/Functions/common";
import SuccessPopup from "../../Plugins/AdvisorPlugin/Components/SuccessPopup";
import MobileDatePopover from "../../Plugins/AdvisorPlugin/Components/MobileDatePopover";
import PreviewInviteCandidate from "../Components/PreviewInviteCandidate";
import { getLocalTimeZone } from "../../../../utils/date";

const InviteCandidateMobile = ({
  showInviteCandidatePopup,
  setShowInviteCandidatePopup,
  candidateObj,
  handleInviteSuccess,
  stepId,
}) => {
  const [userInputs, setUserInputs] = useState({
    agenda: "",
    duration: "",
    email: "",
  });

  const [responses, setResponses] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [currentStep, setCurrentStep] = useState(1);
  const [mobileStep, setMobileStep] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [timeDrawer, setTimeDrawer] = useState(false);
  const [openSuccessPopup, setOpenSuccessPopup] = useState(false);
  const userData = useSelector(state => state.auth);
  const currentEnv = process.env.NODE_ENV;
  const marketTestData = useSelector(state => state?.powerUp?.marketTest);

  const [subjectContent, setSubjectContent] = useState(
    `${userData.companyName} Interview`
  );
  const [descriptionContent, setDescriptionContent] = useState(
    `My name is ${userData.userData.name} with ${userData.companyName} and would like to interview you. We would like to interview you for our product research. Your feedback will help us refine our idea and find the right audience. If you’re available and would like to participate, please reply to this email to confirm the time or propose a new time. If you have any questions, my email is ${userData.userData.email}.`
  );
  const handleOnChange = (value, type) => {
    let plainText = stateToMarkdown(value.getCurrentContent());
    if (type === "subject") {
      setSubjectContent(plainText);
    } else {
      setDescriptionContent(plainText);
    }
  };

  const handleconfirmDisable = () => {
    if (currentStep === 1) {
      return !responses.length;
    } else {
      if (validateAdvisorPluginInput(userInputs)) {
        return false;
      } else {
        return true;
      }
    }
  };
  const handleNext = () => {
    if (currentStep === 1) {
      setCurrentStep(2);
    } else if (currentStep === 2) {
      setCurrentStep(3);
    } else {
      sendInviteToCandidate();
    }
  };
  const handleBack = () => {
    if (currentStep === 3) {
      setCurrentStep(2);
    } else if (currentStep === 2) {
      setCurrentStep(1);
    }
  };

  useEffect(() => {
    if (!showInviteCandidatePopup) {
      setResponses([]);
      setCurrentStep(1);
      setSelectedDate(null);
      setSubjectContent("");
      setDescriptionContent("");
      setUserInputs({
        agenda: "",
        duration: "",
        email: "",
      });
    }
  }, [showInviteCandidatePopup]);

  const sendInviteToCandidate = (subjectContent, descriptionContent) => {
    setButtonLoading(true);
    const candidateData = {
      userId: candidateObj.isExternalCandidate ? "" : candidateObj.id,
      candidateInvitationId: candidateObj.isExternalCandidate
        ? candidateObj.id
        : "",
      marketTestResponseId: marketTestData.id,
      calendarEvent: {
        calendarEventType: "IDEA_VALIDATION",
        agenda: userInputs?.agenda,
        email: candidateObj?.email,
        duration: parseInt(userInputs?.duration?.replace("min", "")),
        content: descriptionContent,
        subject:
          currentEnv === "development" || currentEnv === "staging"
            ? `[TEST] ${subjectContent}`
            : subjectContent,
        timezone: getLocalTimeZone(),
        calendarEventDetails: sanitizeDateTimePayload(responses),
      },
    };

    const currentModuleObject = candidateObj?.currentModuleObject;

    const currentModuleAnswers =
      currentModuleObject?.moduleStepResponses[0]?.answers;

    const currentCandidates = currentModuleAnswers
      ? JSON.parse(currentModuleAnswers)?.candidates
      : [];

    let candidateIdx = currentCandidates?.findIndex(
      cand => cand?.id === candidateObj?.id
    );

    if (candidateIdx > 0) {
      currentCandidates[candidateIdx] = {
        ...currentCandidates[candidateIdx],
        invited: true,
      };
    } else {
      currentCandidates?.push({
        avatar: candidateObj?.avatar,
        email: candidateObj?.email,
        id: candidateObj?.id,
        invited: true,
        name: candidateObj?.name,
        selected: false,
        invitedDate: new Date(),
      });
    }

    const modulePayload = {
      answers: JSON.stringify({
        candidates: currentCandidates,
      }),
      moduleResponseId: currentModuleObject?.id,
      isLastStep: true,
      isCompleted: true,
      moduleStepId: stepId,
    };

    services
      .sendInterviewInvite(candidateData)
      .then(data => {
        services
          .storeModuleStepResponses(
            currentModuleObject?.moduleId,
            modulePayload
          )
          .then(dt => {
            setButtonLoading(false);
            setShowInviteCandidatePopup(false);
            setOpenSuccessPopup(true);
          })
          .catch(err => {
            console.log(err);
            setButtonLoading(false);
          });
      })
      .catch(err => {
        console.log(err);
        setButtonLoading(false);
      });
  };

  const handlePopupsClose = () => {
    handleInviteSuccess();
    setOpenSuccessPopup(false);
  };

  const successContentComponent = () => {
    return (
      <Box textAlign={"center"} sx={{ px: 3 }}>
        <Box sx={{ my: 3 }}>
          <Typography variant="subhead1">
            You've sent your interview invite.
          </Typography>
        </Box>
        <Box>
          <Typography variant="subhead1">
            Your chosen candidate will be notified and select a time that works
            or will suggest a new time.
          </Typography>
        </Box>
        <Box sx={{ my: 3 }}>
          <Typography variant="subhead1">
            All of your candidate invites will now be displayed in the
          </Typography>
          <Typography variant="custom070">
            &nbsp;Idea Validation Interview Overview.
          </Typography>
        </Box>
        <Box>
          <Button
            contained
            onClick={() => {
              window.location.reload();
            }}
          >
            View Candidate Invites
          </Button>
        </Box>
      </Box>
    );
  };

  return (
    <>
      <DialogComponent
        isOpen={showInviteCandidatePopup}
        onClose={() => setShowInviteCandidatePopup(false)}
        title={"Invite Candidate"}
        titleVariant={"title_large"}
        contentStyle={{ px: 0 }}
        maxWidth={"lg"}
        fullWidth
        PaperProps={{
          style: { borderRadius: 16 },
        }}
        footerStyle={{
          p: 3,
        }}
        dialogAction={
          currentStep === 1 ? (
            <Box display="flex">
              <Box sx={{ mr: 1 }}>
                {mobileStep ? (
                  <MobileDatePopover
                    responses={responses}
                    setResponses={setResponses}
                    mobileUI
                  />
                ) : (
                  <Button onClick={() => setMobileStep(true)}>Next</Button>
                )}
              </Box>
              <Box item>
                {mobileStep && (
                  <LoadingButton
                    disabled={handleconfirmDisable()}
                    loading={buttonLoading}
                    onClick={() => handleNext()}
                  >
                    Next
                  </LoadingButton>
                )}
              </Box>
            </Box>
          ) : (
            <>
              <Box display="flex">
                <Box sx={{ mr: 1 }}>
                  <Button variant="outlined" onClick={() => handleBack()}>
                    Back
                  </Button>
                </Box>
                <Box item>
                  <LoadingButton
                    disabled={handleconfirmDisable()}
                    loading={buttonLoading}
                    onClick={() => handleNext()}
                  >
                    {currentStep === 3 ? "Confirm" : "Next"}
                  </LoadingButton>
                </Box>
              </Box>
            </>
          )
        }
        disableBackDropClick
      >
        <Box>
          <Box sx={{ mt: 3, px: 3 }}>
            <Grid container spacing={2}>
              <Grid item md={12} xs={12}>
                {currentStep === 1 ? (
                  <>
                    {mobileStep ? (
                      <Box>
                        <Box>
                          <Typography variant="headline6">
                            Select dates & times you are available
                          </Typography>
                          <br />
                          <Typography variant="bodyMedium">
                            We recommend choosing your available time slots no
                            more than 7 days out from the day you send the
                            invite.
                          </Typography>
                        </Box>
                        <DateTimeSlotPicker
                          responses={responses}
                          setResponses={setResponses}
                          minTimeSlots={1}
                          maxTimeSlots={5}
                          containerStyles={theme => ({
                            background:
                              "linear-gradient(0deg, rgba(118, 73, 160, 0.05), rgba(118, 73, 160, 0.05)), #FCFCFC",
                            border: `1px solid ${theme.palette.secondary.light}`,
                            borderRadius: theme.shape.standard3.borderRadius,
                            p: 1,
                            mt: 3,
                          })}
                          selectedDate={selectedDate}
                          setSelectedDate={setSelectedDate}
                          mobileUI={true}
                          timeDrawer={timeDrawer}
                          setTimeDrawer={setTimeDrawer}
                        />
                      </Box>
                    ) : (
                      <Box>
                        <Typography variant="title_medium">
                          Your message to candidate
                        </Typography>
                        <Box sx={{ mt: 3 }}>
                          <Typography variant="bodyMedium">
                            Craft a brief introductory message to the interview
                            participants. Include your Zoom scheduling link to
                            have the participant schedule a time if they agree
                            to participate.
                          </Typography>
                        </Box>

                        <Box
                          sx={theme => ({
                            mt: 3,
                            border: `1px solid ${theme.palette.secondary.dark}`,
                            borderRadius: theme.shape.standard5.borderRadius,
                            p: 3,
                          })}
                        >
                          <Box>
                            <TextEditorComp
                              label="Subject:"
                              editorValue={subjectContent}
                              readOnly={false}
                              displayValue="flex"
                              onChange={handleOnChange}
                            />
                          </Box>
                          <Box>
                            <TextEditorComp
                              editorValue={descriptionContent}
                              readOnly={false}
                              displayValue="block"
                              onChange={handleOnChange}
                            />
                          </Box>
                        </Box>
                      </Box>
                    )}{" "}
                  </>
                ) : currentStep === 2 ? (
                  <MeetingDetailComponent
                    selectedDateTimes={responses}
                    userInputs={userInputs}
                    setUserInputs={setUserInputs}
                    boxPadding={0}
                  />
                ) : (
                  <PreviewInviteCandidate
                    selectedDateTimes={responses}
                    duration={parseInt(
                      userInputs?.duration?.replace("min", "")
                    )}
                  />
                )}
              </Grid>
            </Grid>
          </Box>
        </Box>
      </DialogComponent>
      <SuccessPopup
        title={"Invite Sent"}
        open={openSuccessPopup}
        close={handlePopupsClose}
        ContentComponent={successContentComponent}
      />
    </>
  );
};

export default InviteCandidateMobile;
