import React from "react";
import { Grid, Box, Stack, Typography, Button } from "@mui/material";
import ROUTES_PATH from "../../constants/routes";
import { useHistory } from "react-router-dom";

const AccountNotFound = () => {
  const history = useHistory();
  return (
    <Stack alignItems="center" justifyContent="center">
      <Grid container alignItems="center" justifyContent="center">
        <Grid item xs={12} sm={10} md={6} lg={6} sx={{ px: 2 }}>
          <Box display="grid" justifyContent="center">
            <Typography variant="headline1" align="center">
              Account Not Found
            </Typography>
          </Box>
          <Box display="grid" sx={{ textAlign: "center" }}>
            <Typography variant="subhead1" sx={{ my: 4 }}>
              If you have an account, you will need to log in with your the
              email and password you originally provided and connect your social
              accounts in Account Settings once logged in.
            </Typography>
          </Box>
          <Button
            sx={{ width: 1, maxWidth: 1 }}
            onClick={() => {
              history.push(ROUTES_PATH.LOG_IN);
            }}
          >
            Back to Log in
          </Button>
          <Box sx={{ my: 3 }} textAlign={"center"}>
            <Typography variant="caption">
              Don’t have an account? &nbsp;
              <Typography
                variant="span"
                color={"primary"}
                onClick={() => {
                  history.push(ROUTES_PATH.JOIN);
                }}
                sx={{ cursor: "pointer" }}
              >
                Join free today.
              </Typography>
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default AccountNotFound;
