import React from "react";
import { Grid, Box, Typography } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import theme from "../../../../constants/Theme";
import PropTypes from "prop-types";
import { useSpringCarousel } from "react-spring-carousel";
import UpcomingEventCard from "../UpcomingEventCard";

export function EventMobileCarousel({ eventsData, EventCardActionComponent }) {
  const { carouselFragment, slideToPrevItem, slideToNextItem } =
    useSpringCarousel({
      gutter: 16,
      itemsPerSlide: 1,
      withLoop: false,
      items: eventsData?.map((event, index) => ({
        id: index,
        renderItem: (
          <Grid item xs={12} sx={{ mr: 1, ml: 1 }} key={index}>
            <UpcomingEventCard
              eventData={event}
              ActionComponent={() => (
                <EventCardActionComponent eventData={event} />
              )}
            />
          </Grid>
        ),
      })),
    });

  return (
    <Grid
      container
      xs={12}
      sm={12}
      sx={{
        display: "flex",
        position: "relative",
        alignItems: "center",
        [theme.breakpoints.up("md")]: {
          display: "none",
        },
      }}
    >
      <Grid item>
        <ArrowBackIosIcon
          onClick={slideToPrevItem}
          sx={{
            cursor: "pointer",
            position: "absolute",
            zIndex: 2,
            left: 9,
            color: "secondary.dark",
          }}
        />
      </Grid>

      <Grid item xs={12} sm={12}>
        <Box sx={{ mb: 3 }}>
          <Typography variant="title_medium">Upcoming Events</Typography>
        </Box>
        <Box
          xs={12}
          sx={{
            // To handle the horizontal scroll in responsive
            maxWidth: "100vw",
          }}
        >
          {carouselFragment}
        </Box>
      </Grid>

      <Grid item>
        <ArrowForwardIosIcon
          onClick={slideToNextItem}
          sx={{
            cursor: "pointer",
            position: "absolute",
            zIndex: 2,
            right: 3,
            color: "secondary.dark",
          }}
        />
      </Grid>
    </Grid>
  );
}

{
  EventMobileCarousel.propTypes = {
    savedStartup: PropTypes.array,
  };

  EventMobileCarousel.defaultProps = {
    eventData: [],
  };
}
