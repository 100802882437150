import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import {
  Switch,
  Route,
  useRouteMatch,
  Redirect,
  useHistory,
  useParams,
} from "react-router-dom";
import { steps, invitedSteps } from "./variables";
import { OnboardingView } from "../OnboardingView";
import { PersonalDetails } from "../../PersonalDetails";
import WhoAreYou from "../../../../containers/Onboarding/Updated/WhoAreYou";
import StartupsYouHelp from "../../../../containers/Onboarding/Updated/StartupsYouHelp";
import AreYouAffiliate from "../../../../containers/Onboarding/Updated/AreYouAffiliate";
import { useDispatch, useSelector } from "react-redux";
import { setOnboardingData } from "../../../../modules/actions";
import IndustryFocus from "../../../../containers/Onboarding/Updated/IndustryFocus";
import WhatBringsYouHere from "../../../../containers/Onboarding/Updated/WhatBringsYouHere";
import SkillsAndExpertise from "../../../../containers/Onboarding/Updated/SkillsAndExpertise";
import ROUTES_PATH from "../../../../constants/routes";

export const AdvisorOnboarding = () => {
  const { path } = useRouteMatch();
  const [stepsState, setStepsState] = useState([]);
  const history = useHistory();
  const [responseToStore, setResponseToStore] = useState(null);
  const dispatch = useDispatch();
  const savedData = useSelector(state => state?.onboarding);

  useEffect(() => {
    const isInvited = savedData?.invited;

    if (isInvited) {
      setStepsState(steps);
    } else {
      setStepsState(invitedSteps);
    }

    let stepsRefresh = isInvited ? [...invitedSteps] : [...steps];
    if (savedData) {
      let newData = savedData;

      if (savedData.role) {
        if (savedData.role.toLowerCase() !== "advisor") {
          dispatch(setOnboardingData(null));
          newData = {};
        }
      }

      if (!isInvited) {
        if (newData.role) {
          stepsRefresh[0].complete = true;
        }
        if (newData.whatBringHere && savedData.whatBringHere.length) {
          stepsRefresh[1].complete = true;
        }
        if (newData.firstName && savedData.lastName) {
          stepsRefresh[2].complete = true;
        }
        if (newData.industryFocus && newData.industryFocus.length) {
          stepsRefresh[3].complete = true;
        }
        if (newData.skillsAndExpertise && savedData.skillsAndExpertise.length) {
          stepsRefresh[4].complete = true;
        }
      } else {
        if (newData.whatBringHere && savedData.whatBringHere.length) {
          stepsRefresh[0].complete = true;
        }
        if (newData.firstName && savedData.lastName) {
          stepsRefresh[1].complete = true;
        }
        if (newData.industryFocus && newData.industryFocus.length) {
          stepsRefresh[2].complete = true;
        }
        if (newData.skillsAndExpertise && savedData.skillsAndExpertise.length) {
          stepsRefresh[3].complete = true;
        }
      }
    }

    setStepsState(stepsRefresh);
  }, []);

  useEffect(() => {
    if (savedData?.role) {
      if (savedData.role.toLowerCase() !== "advisor") {
        dispatch(setOnboardingData(null));
      }
    }
  }, [savedData]);

  const setStepCompleteness = ({ step, complete, stepResponse }) => {
    if (typeof stepResponse !== "undefined") {
      setResponseToStore({ ...stepResponse });
    }
    if (step) {
      setStepsState(prev =>
        prev.map((elem, index) => {
          if (index === step - 1)
            return { ...elem, complete: complete ? true : false };
          else return elem;
        })
      );
    }
  };
  const getNextPage = currentStep => {
    dispatch(setOnboardingData(responseToStore));
    if (typeof currentStep != "undefined") {
      if (savedData.isInvited && currentStep == 4) {
        history.push(ROUTES_PATH.SIGN_UP);
      } else if (currentStep == 5) {
        history.push(ROUTES_PATH.SIGN_UP);
      } else if (
        currentStep == 1 &&
        responseToStore.hasOwnProperty("role") &&
        responseToStore.role
      ) {
        history.push(
          `${
            ROUTES_PATH.ONBOARDING_BASEPATH
          }/${responseToStore.role.toLowerCase()}/2`
        );
      } else {
        history.push(`${Number(currentStep) + 1}`);
      }
    }
  };

  return (
    <Box>
      {savedData && savedData.invited ? (
        <Switch>
          <Route exact path={`${path}`}>
            <Redirect to={`${path}/1`} />
          </Route>

          <Route path={`${path}/1`}>
            <OnboardingView
              stepObj={stepsState}
              currentStep={1}
              getNextPage={getNextPage}
              content={
                <WhatBringsYouHere
                  setComplete={(value, data) => {
                    setStepCompleteness({
                      step: 1,
                      complete: value,
                      stepResponse: data,
                    });
                  }}
                />
              }
              sidebarTitle={"My Advisor Journey"}
            />
          </Route>
          <Route exact path={`${path}/2`}>
            <OnboardingView
              stepObj={stepsState}
              currentStep={2}
              getNextPage={getNextPage}
              content={
                <PersonalDetails
                  setComplete={(value, data) => {
                    setStepCompleteness({
                      step: 2,
                      complete: value,
                      stepResponse: data,
                    });
                  }}
                />
              }
              sidebarTitle={"My Advisor Journey"}
            />
          </Route>
          <Route path={`${path}/3`}>
            <OnboardingView
              stepObj={stepsState}
              currentStep={3}
              getNextPage={getNextPage}
              content={
                <IndustryFocus
                  setComplete={(value, data) => {
                    setStepCompleteness({
                      step: 3,
                      complete: value,
                      stepResponse: data,
                    });
                  }}
                />
              }
              sidebarTitle={"My Advisor Journey"}
            />
          </Route>
          <Route path={`${path}/4`}>
            <OnboardingView
              stepObj={stepsState}
              currentStep={4}
              getNextPage={getNextPage}
              content={
                <SkillsAndExpertise
                  setComplete={(value, data) => {
                    setStepCompleteness({
                      step: 4,
                      complete: value,
                      stepResponse: data,
                    });
                  }}
                />
              }
              sidebarTitle={"My Advisor Journey"}
            />
          </Route>
        </Switch>
      ) : (
        <Switch>
          <Route exact path={`${path}`}>
            <Redirect to={`${path}/1`} />
          </Route>
          <Route path={`${path}/1`}>
            <OnboardingView
              stepObj={stepsState}
              currentStep={1}
              getNextPage={getNextPage}
              content={
                <WhoAreYou
                  setComplete={(value, data) => {
                    setStepCompleteness({
                      step: 1,
                      complete: value,
                      stepResponse: data,
                    });
                  }}
                />
              }
              sidebarTitle={"My Advisor Journey"}
            />
          </Route>
          <Route path={`${path}/2`}>
            <OnboardingView
              stepObj={stepsState}
              currentStep={2}
              getNextPage={getNextPage}
              content={
                <WhatBringsYouHere
                  setComplete={(value, data) => {
                    setStepCompleteness({
                      step: 2,
                      complete: value,
                      stepResponse: data,
                    });
                  }}
                />
              }
              sidebarTitle={"My Advisor Journey"}
            />
          </Route>
          <Route exact path={`${path}/3`}>
            <OnboardingView
              stepObj={stepsState}
              currentStep={3}
              getNextPage={getNextPage}
              content={
                <PersonalDetails
                  setComplete={(value, data) => {
                    setStepCompleteness({
                      step: 3,
                      complete: value,
                      stepResponse: data,
                    });
                  }}
                />
              }
              sidebarTitle={"My Advisor Journey"}
            />
          </Route>
          <Route path={`${path}/4`}>
            <OnboardingView
              stepObj={stepsState}
              currentStep={4}
              getNextPage={getNextPage}
              content={
                <IndustryFocus
                  setComplete={(value, data) => {
                    setStepCompleteness({
                      step: 4,
                      complete: value,
                      stepResponse: data,
                    });
                  }}
                />
              }
              sidebarTitle={"My Advisor Journey"}
            />
          </Route>
          <Route path={`${path}/5`}>
            <OnboardingView
              stepObj={stepsState}
              currentStep={5}
              getNextPage={getNextPage}
              content={
                <SkillsAndExpertise
                  setComplete={(value, data) => {
                    setStepCompleteness({
                      step: 5,
                      complete: value,
                      stepResponse: data,
                    });
                  }}
                />
              }
              sidebarTitle={"My Advisor Journey"}
            />
          </Route>
        </Switch>
      )}
    </Box>
  );
};
