import React, { useState, useEffect } from "react";
import { Box, Stack, Typography, Grid, TextField, Card, CardContent, Button } from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { REGEX } from "../../../constants/regex";
import messages from "../../../constants/messages";
import CustomizedDatePicker from "../../Workspace/PowerUp/Datepicker";
import { DateTextField } from "../../../containers/Workspace/PowerUP/IdeaValidation/styles";
import constants from "../../../constants";
import SelectInput from "../../../common/SelectInput";
import PageTitle from "../PageTitle";
import { useSelector } from "react-redux";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { NewTextField } from "./styles";
import LinkedInProfileWarning from "./LinkedInProfileWarning";
import FirstbaseCTA from "../../../components/FirstbaseCTA";
import moment from "moment";

export const StartupDetails = ({ setComplete }) => {
	const [startupName, setStartupName] = useState({ value: "", error: "" });
	const [startupWebsite, setStartupWebsite] = useState({
		value: "",
		error: "",
	});
	const [incorporatedStatus, setIncorporatedStatus] = useState("");
	const [foundedDate, setFoundedDate] = useState(null);
	const [companyLinkedInUrl, setCompanyLinkedInUrl] = useState({
		value: "",
		error: "",
	});

	const savedData = useSelector((state) => state?.onboarding);
	const [isOpen, setIsOpen] = useState(false);
	const handleOnclick = () => {
		if (isOpen === false) {
			setIsOpen(true);
		}
	};
	useEffect(() => {
		if (savedData) {
			setStartupName({ ...startupName, value: savedData.startupName || "" });
			setStartupWebsite({
				...startupWebsite,
				value: savedData.startupWebsite || "",
			});
			setIncorporatedStatus(savedData.incorporatedStatus || "");
			setFoundedDate(savedData.foundedDate || null);
			setCompanyLinkedInUrl({
				...companyLinkedInUrl,
				value: savedData.companyLinkedInUrl || "",
			});
		}
	}, [savedData]);

	useEffect(() => {
		/*
      Call Complete:
      This is called on the parent to toggle the 'Next' button -> active or inactive.
      The button lives in an area not directly accessible by this component.
      Therefore we need to notify the parent to unlock the button.
      If there is an error; the button will need to be disabled, so we'd need to pass 'false' instead.
    */
		let payload = {
			startupName: startupName.value,
			startupWebsite: startupWebsite.value,
			foundedDate: foundedDate,
			incorporatedStatus: incorporatedStatus,
			companyLinkedInUrl: companyLinkedInUrl.value,
		};
		if (startupName.value.length > 0 && incorporatedStatus !== "" && !companyLinkedInUrl.error) {
			callComplete(1, payload);
		} else {
			callComplete(0, payload);
		}
	}, [startupName, startupWebsite, foundedDate, incorporatedStatus, companyLinkedInUrl]);

	/*
      Call Complete --> setComplete prop:
      This is called on the parent to toggle the 'Next' button -> active or inactive.
      The button lives in an area not directly accessible by this component.
      Therefore we need to notify the parent to unlock the button.
      If there is an error; the button will need to be disabled, so we'd need to pass 'false' instead.
    */
	const callComplete = (complete, data) => {
		if (setComplete) {
			setComplete(complete, data);
		}
	};

	const handleBlur = (e, type) => {
		let eventValue = e.target.value;

		switch (type) {
			case "startupName":
				if (!eventValue.match(REGEX.COMPANY_NAME)) {
					setStartupName((prevState) => ({
						...prevState,
						error: messages.COMPANY_NAME_LENGTH_INVALID,
						value: eventValue,
					}));
				} else {
					setStartupName((prevState) => ({
						...prevState,
						error: "",
						value: eventValue,
					}));
				}
				break;

			case "companyLinkedInUrl":
				if (!REGEX.LINKEDIN_NAME.test(eventValue)) {
					setCompanyLinkedInUrl((prevState) => ({
						...prevState,
						error: messages.INVALID_LINKEDIN_COMPANY_URL,
						value: eventValue,
					}));
				} else {
					setCompanyLinkedInUrl((prevState) => ({
						...prevState,
						error: "",
						value: eventValue,
					}));
				}
				break;

			default:
				if (eventValue && !eventValue.match(REGEX.VALID_URL)) {
					setStartupWebsite((prevState) => ({
						...prevState,
						error: messages.INVALID_URL,
						value: eventValue,
					}));
				} else {
					setStartupWebsite((prevState) => ({
						...prevState,
						error: "",
						value: eventValue,
					}));
				}
				break;
		}
	};
	const handleOnChange = (e, type) => {
		let eventValue = e.target.value;
		switch (type) {
			case "startupName":
				setStartupName((prevState) => ({
					...prevState,
					error: "",
					value: eventValue,
				}));

				break;

			case "companyLinkedInUrl":
				setCompanyLinkedInUrl((prevState) => ({
					...prevState,
					error: "",
					value: eventValue,
				}));

				break;

			default:
				setStartupWebsite((prevState) => ({
					...prevState,
					value: eventValue,
					error: "",
				}));

				break;
		}
	};
	const handleIncorporateStatusChange = (event) => {
		const value = event.target.value;
		setIncorporatedStatus(value);
	};

	const handleFoundedDateChange = (value) => {
		setFoundedDate(value);
	};
	return (
		<>
			<PageTitle title={"Tell us a little about your startup"} description={"You’ll be able to update this info later on."} />
			<Stack alignItems="center" justifyContent="center">
				<Grid container alignItems="center" justifyContent="center">
					<Grid item xs={12} sm={10} md={6} lg={6} sx={{ px: 2 }}>
						<Box>
							<TextField
								label="Startup Name*"
								spellCheck="true"
								value={startupName.value}
								sx={{ mt: 9, width: 1 }}
								onBlur={(e) => {
									handleBlur(e, "startupName");
								}}
								error={startupName.error}
								helperText={startupName.error}
								onChange={(e) => {
									handleOnChange(e, "startupName");
								}}
							/>
							{/*<Box>
                <CustomizedDatePicker
                  value={foundedDate}
                  onChange={e => handleFoundedDateChange(e)}
                  OpenPickerIcon={CalendarMonthIcon}
                  disablePast={false}
                  disableFuture={true}
                  toolbarComp={null}
                  Component={params => (
                    <DateTextField
                      {...params}
                      fullWidth
                      variant="outlined"
                      label={"Date Founded"}
                      value={foundedDate}
                      sx={{
                        width: 1,
                        mt: 3,
                        cursor: "pointer",
                      }}
                    />
                  )}
                />
              </Box>*/}
							<Box onClick={handleOnclick}>
								<CustomizedDatePicker
									value={foundedDate ? foundedDate : ""}
									views={["year"]}
									onChange={(e) => handleFoundedDateChange(e)}
									OpenPickerIcon={CalendarMonthIcon}
									disablePast={false}
									disableFuture={true}
									toolbarComp={null}
									Component={(params) => (
										<TextField
											{...params}
											fullWidth
											variant="outlined"
											label={"Year Founded"}
											value={foundedDate}
											sx={{
												width: 1,
												cursor: "pointer",
											}}
											onKeyDown={(e) => {
												e.preventDefault(); // Prevent user from manually typing a value
											}}
										/>
									)}
								/>
							</Box>

							<SelectInput
								label="Is your startup incorporated?*"
								fullWidth
								wrapperstyle={{ mt: 3 }}
								onInputChange={(e) => handleIncorporateStatusChange(e)}
								selectedValue={incorporatedStatus}
								inputKey="incorporated_status"
								options={constants.INCORPORATE_STATUS}
							/>

							{(incorporatedStatus === "No" || incorporatedStatus === "In Progress") && (
								<Box sx={{ mt: 2 }}>
									<FirstbaseCTA params={{ bgColor: "#eee" }} />
								</Box>
							)}

							<TextField
								spellCheck="true"
								label="Startup Website"
								sx={{ mt: 3, width: 1 }}
								value={startupWebsite.value}
								onBlur={(e) => {
									handleBlur(e, "startupWebsite");
								}}
								error={startupWebsite.error}
								helperText={startupWebsite.error}
								onChange={(e) => {
									handleOnChange(e, "startupWebsite");
								}}
								InputProps={{
									startAdornment: <Typography>https://</Typography>,
								}}
							/>

							<TextField
								spellCheck="true"
								label="LinkedIn Company Profile Url"
								sx={{ mt: 3, mb: 2, width: 1 }}
								value={
									companyLinkedInUrl.value && companyLinkedInUrl.value.replace(/^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(company\/)/gm, "")
								}
								onBlur={(e) => {
									handleBlur(e, "companyLinkedInUrl");
								}}
								error={companyLinkedInUrl.error}
								helperText={companyLinkedInUrl.error}
								onChange={(e) => {
									handleOnChange(e, "companyLinkedInUrl");
								}}
								InputProps={{
									startAdornment: <Typography sx={{ whiteSpace: "nowrap" }}>https://www.linkedin.com/company/</Typography>,
								}}
							/>

							<Box>
								<LinkedInProfileWarning />
							</Box>
						</Box>
					</Grid>
				</Grid>
			</Stack>
		</>
	);
};
