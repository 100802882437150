import React, { useEffect, useState } from "react";
import { Box, Typography, styled, Avatar } from "@mui/material";
import PersonaCard from "./PersonaCard";
import NoDataPill from "./NoDataPill";
import { ImportContactsOutlined, ZoomOutMapOutlined } from "@mui/icons-material";

export default function Bio({ persona }) {
	const [personaData, setpersonaData] = useState(persona);

	useEffect(() => {
		setpersonaData(persona);
	}, [persona]);
	return (
		<PersonaCard isLarge={true} title="Bio" icon={<ImportContactsOutlined fontSize="large" />}>
			<Box mt="30px">
				<Typography variant="card_text">{personaData?.bio}</Typography>
				{!personaData?.bio && <NoDataPill />}
			</Box>

			<Box display="flex" alignItems="center" mt="40px">
				<Avatar sx={{ bgcolor: "#DE0085", width: "50px", height: "50px" }}>
					<ZoomOutMapOutlined fontSize="large" />
				</Avatar>
				<Typography variant="card_name" sx={{ fontWeight: 600, marginLeft: "8px" }}>
					Behavior
				</Typography>
			</Box>
			<Box mt={3}>
				<Box display="flex" gap="10px" flexWrap="wrap">
					{personaData?.behavior?.map((item) => (
						<CARD_ITEM key={item}>
							<Typography variant="card_text">{item}</Typography>
						</CARD_ITEM>
					))}
				</Box>
				{!personaData?.behavior && <NoDataPill />}
			</Box>
		</PersonaCard>
	);
}
export const CARD_ITEM = styled(Box)(({ isSelected = true }) => ({
	display: "flex",
	alignItems: "center",
	background: isSelected ? "rgba(123, 97, 255, 0.05)" : "#fff",
	padding: "10px 30px",
	borderRadius: "30px",
}));
