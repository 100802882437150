import React, { useState, useEffect } from "react";
import {
	Grid,
	Divider,
	Typography,
	Box,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	ListItemButton,
	Button,
	useMediaQuery,
	CircularProgress,
} from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import FolderIcon from "@mui/icons-material/Folder";
import theme from "../../../constants/Theme";
import moment from "moment";
import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";

import { useHistory } from "react-router-dom";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import MenuDrawerCommon from "../../../common/MenuDrawerCommon";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { getMyWorkPowerups, deleteAllArchiveModuleResponse } from "./functions";
import VersionsPage from "./Components/VersionsPage";
import ArchivedPage from "./Components/ArchivedPage";
import messages from "../../../constants/messages";
import { toastContainer } from "../../../utils/toast";
import MessageHandlerModal from "../../../common/modal/MessageHandler";

export default function MyWork({ showArchivedPage = false, showVersionsPage = false, powerupId }) {
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
	const history = useHistory();

	const [powerUpItems, setPowerUpItems] = useState([]);
	const [isAscendingName, setIsAscendingName] = useState(true);
	const [isAscendingDate, setIsAscendingDate] = useState(true);
	const [currentId, setCurrentId] = useState("");
	const [showVersions, setShowVersions] = useState(false);
	const [subpath, setSubpath] = useState("");
	const [isListView, setIsListView] = useState("LIST");
	const [showGrid, setShowGrid] = useState(false);
	const [showArchived, setShowArchived] = useState(false);
	const [openDrawer, setOpenDrawer] = useState(false);
	const [menuOptions, setMenuOptions] = useState([]);
	const [loading, setLoading] = useState(true);

	const [page, setPage] = useState(1);
	const [sort, setSort] = useState("ASC");
	const [archived, setArchived] = useState(false);
	const [filter, setFilter] = useState("name");
	const [openDel, setOpenDel] = useState(false);
	const [deleteEnable, setDeleteEnable] = useState(true);

	useEffect(() => {
		//for redirection to archive and versions
		if (showArchivedPage) {
			handleArchived();
		} else if (showVersionsPage) {
			handleVersions(powerupId);
		}

		//GRID view for mob view
		if (isSmallScreen) {
			setIsListView("GRID");
		}

		//api call for Get My work powerups
		getMyWorkPowerups().then((res) => {
			setPowerUpItems(res?.data?.data);
			setLoading(false);
		});
	}, []);

	const handelSort = (key, data, cb) => {
		let flag;
		if (key === "powerUpName") {
			setIsAscendingName(!isAscendingName);
			flag = !isAscendingName;
		} else {
			setIsAscendingDate(!isAscendingDate);
			flag = !isAscendingDate;
		}
		sortWorkItems(flag, key, data, cb);
	};

	const sortWorkItems = (flag, key, data, cb) => {
		if (flag) {
			data?.sort((a, b) => {
				return a[key].toLowerCase() > b[key].toLowerCase() ? 1 : -1;
			});
		} else {
			data?.sort((a, b) => {
				return a[key].toLowerCase() < b[key].toLowerCase() ? 1 : -1;
			});
		}
		cb(data);
	};

	const handleVersions = (id) => {
		setCurrentId(id);
		setShowVersions(true);
	};

	useEffect(() => {
		if (powerUpItems.length) {
			setSubpath(powerUpItems?.filter((item) => item?.powerUpId === currentId)[0]?.powerUpName);
		}
	}, [powerUpItems, currentId]);

	const handleBreadcrumbBack = () => {
		setShowVersions(false);
	};

	const handleArchived = () => {
		setShowVersions(false);
		setShowArchived(true);
	};
	const handleArchivedBack = () => {
		setShowArchived(false);
		setShowVersions(false);
	};

	const handleViewPowerUp = () => {
		history.push("/workspace/powerup/" + currentId);
	};

	const handleMenuDrawer = () => {
		let data = [];
		setIsListView("GRID");
		if (!showVersions && !showArchived) {
			data.push({
				icon: <ArchiveOutlinedIcon />,
				text: "View Archived",
				action: handleArchived,
			});
		} else if (showVersions && !showArchived) {
			data.push(
				{
					icon: <FormatListBulletedIcon />,
					text: "View PowerUp Details",
					action: handleViewPowerUp,
				},
				{
					icon: <ArchiveOutlinedIcon />,
					text: "View Archived",
					action: handleArchived,
				}
			);
		} else if (!showVersions && showArchived) {
			data.push({
				icon: <DeleteOutlineOutlinedIcon />,
				text: "Delete all",
				action: handleModal,
			});
		}

		setMenuOptions(data);
	};

	useEffect(() => {
		if (menuOptions.length > 0) {
			setOpenDrawer(true);
		}
	}, [menuOptions]);

	const handleDrawerClose = () => {
		setOpenDrawer(false);
	};

	//Delete all
	const handleModal = () => {
		setOpenDel(!openDel);
	};

	const deleteAllArchived = () => {
		deleteAllArchiveModuleResponse().then((res) => {
			if (res?.status === 200) {
				setLoading(true);
				handleArchivedBack();
				handleArchived();
			} else {
				toastContainer(messages.API_SUBMIT_ERROR, "error");
			}
		});
		handleModal();
	};

	return (
		<Grid container spacing={0} sx={{ mt: 4 }}>
			<Box
				sx={{
					display: "flex",
					width: "100%",
					flexDirection: "column",
					alignItems: "flex-start",
				}}
			>
				<Grid
					item
					xs={12}
					md={12}
					width={"100%"}
					sx={{
						mt: 2,
					}}
				>
					{!showArchived ? (
						<Box
							sx={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "space-between",
							}}
						>
							<Typography variant="StartupOS/headline/headline1" sx={{}}>
								My Work
							</Typography>

							<Box>
								<Button
									variant="nofill"
									startIcon={<ArchiveOutlinedIcon />}
									onClick={handleArchived}
									sx={{ display: { xs: "none", md: "flex" } }}
								>
									View Archived
								</Button>
								<MoreHorizIcon sx={{ display: { xs: "flex", md: "none" } }} onClick={handleMenuDrawer} />
							</Box>
						</Box>
					) : (
						<Box
							sx={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "space-between",
							}}
						>
							<Box
								sx={{
									display: "flex",
									alignContent: "center",
									alignItems: "center",

									gap: 1,
								}}
							>
								<Typography variant="title_xlarge">Archived</Typography>
							</Box>
							{deleteEnable && <MoreHorizIcon sx={{ display: { xs: "flex", md: "none" } }} onClick={handleMenuDrawer} />}
						</Box>
					)}
					<Divider
						sx={{
							mt: 3,
							background: `${theme.palette.secondary.dark}`,
						}}
					/>
				</Grid>

				{loading ? (
					<Box sx={{ display: "flex", width: 1, justifyContent: "center", py: 4 }}>
						<CircularProgress />
					</Box>
				) : (
					<>
						{/* Powerup's */}
						{!showVersions && !showArchived && (
							<>
								<Grid item xs={12} md={12} xl={12} sx={{ width: "100%", mt: 3, px: { xs: 2, md: 3 } }}>
									<Box
										sx={{
											display: "flex",
											flexDirection: "row",
											alignItems: "center",
											justifyContent: "space-between",
											pt: 2,
										}}
									>
										<Box
											sx={{
												width: "max-content",
												display: "flex",
												justifyContent: "center",
												alignItems: "center",
												gap: "12px",
												cursor: "pointer",
											}}
											onClick={() => handelSort("powerUpName", powerUpItems, setPowerUpItems)}
										>
											<Typography variant="bodyLarge" sx={{ ml: 2 }}>
												Name
											</Typography>
											<div style={{ cursor: "pointer" }}>{isAscendingName ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}</div>
										</Box>

										<Box
											sx={{
												width: "max-content",
												display: "flex",
												justifyContent: "center",
												alignItems: "center",
												gap: "12px",
												cursor: "pointer",
											}}
											onClick={() => handelSort("lastModifiedAt", powerUpItems, setPowerUpItems)}
										>
											<Typography variant="bodyLarge" sx={{ ml: 2 }}>
												Modified Date
											</Typography>
											<div style={{ cursor: "pointer" }}>{isAscendingDate ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}</div>
										</Box>
									</Box>
									<Divider
										sx={{
											mt: 2,
											background: `${theme.palette.secondary.outlineXLight}`,
										}}
									/>
								</Grid>
								<Grid item xs={12} md={12} sx={{ px: { xs: 0, md: 1 }, pb: 3, width: 1 }}>
									{powerUpItems.length ? (
										<List>
											{powerUpItems?.map((item, index) => {
												return (
													<ListItem key={index}>
														<ListItemButton onClick={() => handleVersions(item?.powerUpId)}>
															<ListItemIcon>
																<FolderIcon sx={{ fill: theme.palette.secondary.main }} />
															</ListItemIcon>
															<ListItemText>
																<Typography variant="bodyLarge">{item?.powerUpName}</Typography>
															</ListItemText>
															<ListItemText
																sx={{
																	display: "flex",
																	justifyContent: "flex-end",
																}}
															>
																<Typography variant="bodyMedium">
																	{moment(item?.lastModifiedAt).format("MMM D, YYYY")}
																</Typography>
															</ListItemText>
														</ListItemButton>
													</ListItem>
												);
											})}
										</List>
									) : (
										<Box
											sx={{
												display: "flex",
												width: 1,
												justifyContent: "center",
												alignContent: "center",
												py: 5,
											}}
										>
											<Typography color="primary" variant="h5" sx={{ color: theme.palette.secondary.dark }}>
												No data found
											</Typography>
										</Box>
									)}
								</Grid>
							</>
						)}

						{/* Versions */}
						{showVersions && !showArchived && (
							<>
								<VersionsPage
									id={currentId}
									isListView={isListView}
									setIsListView={setIsListView}
									handleArchivedBack={handleArchivedBack}
									subpath={subpath}
									handleBreadcrumbBack={handleBreadcrumbBack}
									handleViewPowerUp={handleViewPowerUp}
								/>
							</>
						)}
					</>
				)}

				{/* Archived */}
				{!showVersions && showArchived && (
					<>
						<ArchivedPage handleArchivedBack={handleArchivedBack} setDeleteEnable={setDeleteEnable} />
					</>
				)}
			</Box>

			{/* Mob Drawer */}
			<MenuDrawerCommon open={openDrawer} onClose={handleDrawerClose} menuOptions={menuOptions} />
			{/*delete all*/}
			<MessageHandlerModal
				isOpen={openDel}
				onCancel={handleModal}
				onOk={deleteAllArchived}
				text="Are you sure you want to delete all?"
				okText="Delete"
				cancelText="No Thanks"
				messageType="primary"
				heading={"Delete"}
			/>
		</Grid>
	);
}
