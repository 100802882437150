import React, { useState, useEffect } from "react";
import { Box, Typography, Button, Divider } from "@mui/material";
import { MVP_SOLUTIONS_BANNER } from "../../../../../../../constants";
import { useHistory } from "react-router-dom";
import services from "../../../../../../../services";
import { useDispatch } from "react-redux";
import { handleMixPanel } from "../../../../../../../utils/mixPanelEventHandling";
import { setMixpanelData } from "../../../../../../../modules/actions";

const StartNewPowerup = () => {
	const history = useHistory();
	return (
		<Box>
			<Box sx={{ mt: 4, textAlign: "center" }}>
				<Typography variant="title_large" color="secondary.korophKoal" sx={(theme) => ({ fontWeight: 700 })}>
					Uh oh! You’re missing a PowerUp.
				</Typography>
			</Box>
			<Box sx={{ mt: 2, textAlign: "center" }}>
				<Typography variant="body_large" color="secondary.outline">
					To identify solutions with a higher chance of adoption, first complete the Problem Solution Fit PowerUp.
				</Typography>
			</Box>
			<Box
				sx={{
					mt: 3,
					mb: 3,
					textAlign: "center",
					width: "max-content",
					display: "flex",
					ml: "auto",
					mr: "auto",
				}}
			>
				<Button
					variant="contained"
					onClick={() => {
						history.push("/workspace/powerup/5");
					}}
				>
					Complete PowerUp
				</Button>
			</Box>
		</Box>
	);
};

const CompletedPowerup = ({ setExpanded, setIsNext, isTaskStatus }) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const [powerupDetails, setPowerupDetails] = useState("");
	const [moduleResponse, setModuleResponse] = useState([]);

	const powerUpId = 20;

	useEffect(() => {
		getDetailsApiCall();
		handleGetMVPVersions();
	}, []);

	const getDetailsApiCall = () => {
		services
			.getPowerupData(powerUpId)
			.then((details) => {
				setPowerupDetails(details.data.data);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const handleGetMVPVersions = () => {
		let mvpModuleId = 12;

		services
			.getModuleStepResponses(mvpModuleId)
			.then((result) => {
				if (result?.status === 200) {
					setModuleResponse(result?.data?.data);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	return (
		<Box>
			<Box sx={{ mt: 4, textAlign: "center" }}>
				<Typography variant="title_large" color="secondary.korophKoal" sx={(theme) => ({ fontWeight: 700 })}>
					Looking good! You have all you need to start this PowerUp.
				</Typography>
			</Box>
			<Box sx={{ mt: 2, textAlign: "center" }}>
				<Typography variant="body_large" color="secondary.outline">
					Let's get prioritizing!
				</Typography>
			</Box>
			<Box sx={{ mt: { xs: 3, md: 4 } }}>
				<Divider />
			</Box>
			<Box
				sx={{
					mt: 3,
					mb: 3,
					width: "max-content",
					display: "flex",
					ml: "auto",
				}}
				gap={2}
			>
				<Box>
					<Button
						variant="outlined"
						onClick={() => {
							setExpanded(0);
							setIsNext(false);
						}}
					>
						Back
					</Button>
				</Box>
				<Box>
					{console.log("powerupDetails", powerupDetails)}
					<Button
						variant="contained"
						onClick={() =>
							{history.push({
								pathname: "/create-matrix",
								state: {
									started: powerupDetails?.companyTask
										? moduleResponse?.length > 0
											? powerupDetails?.companyTask.taskStatus
											: "NOT_STARTED"
										: "NOT_STARTED",
								},
							});

							dispatch(
								setMixpanelData({
									powerUpStep: 2,
									powerUpPercentComplete: 2/3
								})
							);

							handleMixPanel("Powerup Viewed", {
								"Step": "2",
								"Powerup ID": "20",
								"Powerup Name": "MVP Prioritizer"
							})}
						}
					>
						Go to Prioritization Matrix
					</Button>
				</Box>
			</Box>
		</Box>
	);
};

const Solutions = ({ setExpanded, isTaskStatus, completedWorks, setIsNext }) => {
	return (
		<Box>
			<Box component="img" src={MVP_SOLUTIONS_BANNER} sx={{ width: "-webkit-fill-available" }} />

			{/*{isTaskStatus === "COMPLETED" ? (
        <CompletedPowerup setExpanded={setExpanded} />
      ) : (
        <StartNewPowerup />
      )}*/}
			{completedWorks >= 1 ? <CompletedPowerup setExpanded={setExpanded} setIsNext={setIsNext} isTaskStatus={isTaskStatus} /> : <StartNewPowerup />}
		</Box>
	);
};

export default Solutions;
