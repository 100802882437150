import { CheckCircle, ExpandMore } from "@mui/icons-material";
import { Box, Button, CircularProgress, Popover, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { useContext, useEffect, useState } from "react";
import { PowerUPContext } from "../../../PowerUPController";
import VersionHistory from "../../cards/VersionHistory";
import { DATABASE_ICON, DOWNLOAD_ARROW } from "../../../../../constants";
import TextDocDownload from "../../../../../utils/PDF-Export/TextDocDownload";

function WorkModalHeader() {
	const { powerupDetails, versions, setcurrentVersion, currentVersion, setWorkViewModalOpen, pdfContent } = useContext(PowerUPContext);

	const [selectorOpen, setselectorOpen] = useState(false);
	const [hideDownload, setHideDownload] = useState(false);

	useEffect(()=>{
		if(currentVersion?.module?.moduleUuid == "md-pitchdeck-generator")
			setHideDownload(true)
		else
			setHideDownload(false)
	},[currentVersion])

	const linkRef = React.useRef(null);

	const handleClick = (event) => {
		setselectorOpen(true);
	};
	const handleClose = () => {
		setselectorOpen(false);
	};

	const getStatus = () => {
		if (powerupDetails && powerupDetails?.companyTask?.taskStatus) {
			switch (powerupDetails?.companyTask?.taskStatus) {
				case "COMPLETED":
					return "Completed";
				case "STARTED":
					return "In Progress";
				default:
					return "Not Started";
			}
		} else {
			return "Not Started";
		}
	};

	const isComplete = () => {
		if (powerupDetails && powerupDetails?.companyTask?.taskStatus === "COMPLETED") {
			return true;
		} else {
			return false;
		}
	};

	return (
		<React.Fragment>
			<Box sx={{ display: "flex", alignItems: "center", gap: "0.625rem", mb: "2.5rem" }}>
				<Typography variant={"Text/lg/Semibold"} component={"p"} sx={{ flex: 1 }}>
					{currentVersion?.name}
				</Typography>
				<Typography variant={"Text/sm/Regular"}>{getStatus()}</Typography>
				{isComplete() && <CheckCircle sx={{ color: "#7B61FF", width: "1.5rem", height: "1.5rem" }} />}
				<Box
					onClick={() => {
						setcurrentVersion(null);
						setWorkViewModalOpen(false);
					}}
					sx={{
						height: "3.125rem",
						width: "3.125rem",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						cursor: "pointer",
					}}
				>
					<CloseIcon />
				</Box>
			</Box>
			<Box sx={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
				<Typography
					variant="Text/md/Semibold"
					component={"p"}
					ref={linkRef}
					onClick={handleClick}
					sx={{ cursor: "pointer", display: "flex", alignItems: "center", flex: 1 }}
				>
					{currentVersion?.name} <ExpandMore sx={{ ml: ".62rem", fontSize: "1.25rem" }} />
				</Typography>
				<Box>
					{
						!hideDownload &&
						(
							pdfContent ? (
								<TextDocDownload
									type={"preProcessed"}
									buttonText={"Download"}
									fileName={`${currentVersion?.name}-Responses.pdf`}
									preProcessedArray={pdfContent}
									buttonVariant="DS1"
									buttonProps={{
										color: "secondary",
										endIcon: <Box component={"img"} src={DOWNLOAD_ARROW} sx={{ ml: ".62rem", height: "1.875rem", width: "1.875rem" }} />,
									}}
								/>
							) : (
								<Button variant="DS1" color="secondary" disabled>
									Download <CircularProgress size={"1.5rem"} />
								</Button>
							)
						)
					}
					{/*
					// TODO -- Need to port over archive action
					<Button
						variant="DS1"
						color="secondary"
						endIcon={<Box component={"img"} src={DATABASE_ICON} sx={{ ml: ".62rem", height: "1.875rem", width: "1.875rem" }} />}
					>
						Archive
					</Button>*/}
				</Box>
				<Popover
					open={selectorOpen}
					anchorEl={linkRef?.current}
					onClose={handleClose}
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "left",
					}}
					transformOrigin={{
						vertical: "top",
						horizontal: "left",
					}}
					PaperProps={{
						sx: {
							p: "30px",
							borderRadius: "30px",
							background: "white",
							boxShadow: "0px 10px 85px rgba(0, 0, 0, 0.1)",
							maxWidth: "50rem",
							width: "100%",
						},
					}}
				>
					<Typography variant="Text/md/Semibold">Completed Versions</Typography>
					<VersionHistory
						showOnlyCompleted
						selectedVersionCallback={(version) => {
							handleClose();
							setcurrentVersion(version);
						}}
					/>
				</Popover>
			</Box>
			<Box>
				<Typography variant="Text/sm/Regular" component={"p"}>
					{currentVersion?.modifiedBy}
				</Typography>
				<Typography variant="Text/sm/Regular" component={"p"}>
					{new Date(currentVersion?.completionDate)?.toLocaleDateString("en-US", {
						day: "numeric",
						month: "long",
						year: "numeric",
					})}
				</Typography>
			</Box>
		</React.Fragment>
	);
}

export default WorkModalHeader;
