import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Box, Button, IconButton, Radio, Skeleton, Stack, Typography } from "@mui/material";
import { EDIT_V1_ICON, USER_WITH_TICK } from "../../../constants";
import PageTitle from "../../../common/PageTitle";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

import { useDispatch } from "react-redux";
import { setCommonDialogConfirmationData } from "../../../modules/actions";
import MessageSlidingHandler from "../../../common/modal/MessageSlidingHandler";
import { toastContainer } from "../../../utils/toast";
import messages from "../../../constants/messages";
import AddQuestionsDialog from "./Components/AddQuestions";
import QATabSection from "./Components/QATabSection";
import PartnerEntitiesTable from "./Components/PartnerAnswersTable";
import services from "../../../services";
import StartupQA from "./Components/StartupQA";

const AdminQABoard = () => {
	const [loading, setLoading] = useState(false);
	const [partnerQuestions, setPartnerQuestions] = useState([]);
	const [openDeleteQuestion, setOpenDeleteQuestion] = useState(false);
	const [currentQuestion, setCurrentQuestion] = useState(null);
	const [deleteLoading, setDeleteLoading] = useState(false);
	const loadingArray = [1, 2, 3, 4, 5, 6];
	const dispatch = useDispatch();
	const TAB_ITEMS = [
		{ id: 0, title: "Q&A" },
		{ id: 1, title: "Startup Answers" },
	];
	const [currentTab, setCurrentTab] = useState(0);
	const [selectedRowId, setSelectedRowId] = useState(0);

	useEffect(() => {
		getPartnerQuestions();
	}, []);

	const getPartnerQuestions = async () => {
		try {
			setLoading(true);
			let response = await services.getParterQuestionsAPI();
			if (response) {
				setPartnerQuestions(response?.data?.data);
				setLoading(false);
			}
		} catch (error) {
			setLoading(false);

			console.log(error, "Unable to successfully retrieve partner questions and answers");
		}
	};
	const openAddQuestionDialog = () => {
		dispatch(
			setCommonDialogConfirmationData({
				title: "Add Question",
				isOpen: true,
				partnerQuestions: partnerQuestions,
				questionObj: null,
				isEdit: false,
				getPartnerQuestions: getPartnerQuestions,
				setCurrentTab: setCurrentTab,
			})
		);
	};

	const openEditQuestionDialog = (questionObj) => {
		dispatch(
			setCommonDialogConfirmationData({
				title: "Edit Question",
				isOpen: true,
				partnerQuestions: partnerQuestions,
				isEdit: true,
				questionObj: questionObj,
				getPartnerQuestions: getPartnerQuestions,
				setCurrentTab: setCurrentTab,
			})
		);
	};

	const handleDeleteQuestion = async () => {
		try {
			setDeleteLoading(true);
			let response = await services.deletePartnerQuestionAPI(currentQuestion?.id);
			if (response) {
				getPartnerQuestions();
				setOpenDeleteQuestion(false);
				setDeleteLoading(false);
				toastContainer(messages.QUESTION_DELETED_SUCCESS);
			}
		} catch (error) {
			setDeleteLoading(false);

			console.log(error, "Unable to successfully delete partner question");
		}
	};

	const handleRowClick = (rowId) => {
		setSelectedRowId(rowId);
	};

	return (
		<>
			<Helmet>
				<meta charSet="utf-8" />
				<title>StartupOS - Partner Q&A</title>
			</Helmet>
			<Box
				sx={{
					px: {
						lg: 8,
						md: 4,
						xs: 2,
					},
					pb: "30px",
					display: "flex",
					flexDirection: "column",
					gap: "32px",
					maxWidth: "1920px",
					margin: "0 auto",
				}}
			>
				<PageTitle icon={USER_WITH_TICK} label={"StartupOS Partner Admin Board"} />
				<Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
					<Box sx={{ backgroundColor: "#000000", borderRadius: "100px", maxWidth: "fit-content", p: "12px 20px 12px 20px" }}>
						<Typography variant="Text/sm/Semibold" sx={{ color: "white" }}>
							Partner Q&A
						</Typography>
					</Box>
					<Button startIcon={<AddRoundedIcon />} onClick={() => openAddQuestionDialog()} variant="DS1">
						Add Question
					</Button>
				</Box>
				<QATabSection TabItems={TAB_ITEMS} currentTab={currentTab} setCurrentTab={setCurrentTab} />

				{currentTab === 0 && (
					<>
						{loading ? (
							<Stack spacing={2}>
								{loadingArray.map((item) => {
									return <Skeleton variant="rectangular" height={50} width={"100%"} sx={{ borderRadius: "30px" }} />;
								})}
							</Stack>
						) : partnerQuestions?.length > 0 ? (
							partnerQuestions.map((partnerQA, idx) => {
								return (
									<Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
										<Typography variant="Text/sm/Medium" sx={{ color: "#344054" }}>
											{`${idx + 1}. ${partnerQA.name}`}
										</Typography>
										<Box display={"flex"} alignItems={"center"} gap={4}>
											<IconButton sx={{ backgroundColor: "#F2F4F7", p: "14px" }} onClick={() => openEditQuestionDialog(partnerQA)}>
												<Box component={"img"} src={EDIT_V1_ICON} alt="edit" />
											</IconButton>
											<IconButton
												sx={{
													backgroundColor: "#D92D20",
													p: "14px",
													"&:hover": {
														backgroundColor: "#D92D20",
													},
												}}
												onClick={() => {
													setCurrentQuestion(partnerQA);
													setOpenDeleteQuestion(true);
												}}
											>
												<CloseRoundedIcon sx={{ color: "white" }} />
											</IconButton>
										</Box>
									</Box>
								);
							})
						) : (
							<Box display={"flex"} justifyContent={"center"} mt={10}>
								<Typography variant="Text/md/Semibold">No Record Found.</Typography>
							</Box>
						)}
					</>
				)}
				{currentTab === 1 && (
					<>
						<PartnerEntitiesTable selectedRowId={selectedRowId} setSelectedRowId={setSelectedRowId} handleRowClick={handleRowClick} />
						<StartupQA selectedRowId={selectedRowId} />
					</>
				)}
			</Box>
			<MessageSlidingHandler
				isOpen={openDeleteQuestion}
				title={`Delete Question`}
				subText={`Are you sure you want to delete the Question?`}
				primaryButtonText="Delete"
				secondaryButtonText="No Thanks"
				handleClose={() => setOpenDeleteQuestion(false)}
				handlePrimarybtnClick={() => handleDeleteQuestion()}
				loading={deleteLoading}
			/>{" "}
			<AddQuestionsDialog />
		</>
	);
};

export default AdminQABoard;
