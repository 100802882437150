import React, { useContext, useEffect, useState, createRef, useRef } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { convertFromRaw } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { Box, Stack, Paper, Divider, Typography, Grid, Rating, Button, Breadcrumbs, AccordionDetails, Link, Tabs, Tab } from "@mui/material";
import { Bolt, ExpandMore, NavigateNext, StarsRounded, Toc, Whatshot } from "@mui/icons-material";

import PowerUps from "../../stores/powerups";
import { AttributeItem } from "../../../../components/Workspace/PowerUp/Sidebar/components/attribute-item";
import { AccordionWrapper, PowerUpAccordion, PowerUpSummary, PowerUpAvatar } from "../../../../components/Workspace/PowerUp/Accordion/styles";
import ExcerciseWrapper from "../../../../components/Workspace/PowerUp/ExcerciseWrapper";
import { ReviewsTab } from "../../../../components/Workspace/PowerUp/Sidebar/components/reviews-tab";
import { POWERUPKNOWLEDGELOGO, POWERUPLOGO } from "../../../../constants";
import { handleMixPanel } from "../../../../utils/mixPanelEventHandling";
import ROUTES_PATH from "../../../../constants/routes";
import { ShortAnswer, Paragraph, Dropdown, MultipleChoice, Checkboxes, MultipleChoiceGrid, CheckboxGrid, Slider, Blank } from "./inputs";
import stateToHTMLOptions from "./state2html";
import PDFDownload from "../../../../utils/PDF-Export/Download";
import TextDocument from "../../../../utils/PDF-Export/TextDocument";
import { parseHtmlString } from "../../../../utils/PDF-Export/utilities";
import TextDocDownload from "../../../../utils/PDF-Export/TextDocDownload";

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
			{value === index && <>{children}</>}
		</div>
	);
}

String.prototype.capitalize = function () {
	return this.charAt(0).toUpperCase() + this.slice(1);
};

function InspirationItem({ media, description }) {
	return (
		<Stack gap={2}>
			<iframe src={media} style={{ maxWidth: "100%", aspectRatio: "16 / 9", border: 0 }} />
			<Typography variant={"body1"}>{description}</Typography>
		</Stack>
	);
}

function OutlineItem({ index, title, description }) {
	return (
		<PowerUpAccordion disableGutters>
			<PowerUpSummary expandIcon={<ExpandMore />}>
				<PowerUpAvatar>
					<Typography variant={"h5"}>{index}</Typography>
				</PowerUpAvatar>
				<Typography variant={"h5"} alignSelf={"center"} pl={2}>
					{title}
				</Typography>
			</PowerUpSummary>
			<AccordionDetails>
				<Typography variant={"body1"}>{description}</Typography>
			</AccordionDetails>
		</PowerUpAccordion>
	);
}

function KnowledgeBar({ title, details, timeShort, timeLong, difficulty, recommended, powertips, inspirations, submissions, sort, id }) {
	const [knowTabIndex, setKnowTabIndex] = useState(0);
	const scrollRef = createRef();

	return (
		<Stack gap={2}>
			{/* <Paper elevation={1}>
				<Stack p={3} gap={3}>
					<Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
						<Typography variant={"largeTitle"}>Completed Work</Typography>
						<Button variant={"text"} color={"primary"} sx={{ width: "unset", margin: "unset" }}>
							View All
						</Button>
					</Stack>
					<TableContainer>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>
										<Stack direction={"row"} alignItems={"center"} gap={1}>
											<Typography variant={"title_small"}>Name</Typography>
											{sort?.field === "id" && (sort.order ? <ArrowDownward /> : <ArrowUpward />)}
										</Stack>
									</TableCell>
									<TableCell>
										<Stack direction={"row"} alignItems={"center"} justifyContent={"flex-end"} gap={1}>
											<Typography variant={"title_small"}>Last Modified</Typography>
											{sort?.field === "updated" && (sort.order ? <ArrowDownward /> : <ArrowUpward />)}
										</Stack>
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{submissions.map(({ id, updated }) => (
									<TableRow key={id}>
										<TableCell>
											<Typography variant={"body1"}>
												{title} {id}
											</Typography>
										</TableCell>
										<TableCell align={"right"}>
											<Typography variant={"body1"}>{new Date(updated?.at).toLocaleString()}</Typography>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</Stack>
			</Paper> */}
			<Paper elevation={1} sx={{ position: "sticky", top: 76 }}>
				<Box p={3}>
					<img src={POWERUPKNOWLEDGELOGO} alt="Knowledge" style={{ maxHeight: 20 }} />
				</Box>
				<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
					<Tabs variant="fullWidth" aria-label="basic tabs example" value={knowTabIndex} onChange={(e, i) => setKnowTabIndex(i)}>
						<Tab
							icon={<Toc />}
							label="Details"
							{...a11yProps(0)}
							sx={(theme) => ({
								textTransform: "none",
								fontSize: theme.typography.label_medium,
								fontFamily: theme.typography.label_medium,
							})}
						/>
						<Tab
							icon={<Bolt />}
							label="Power Tips"
							{...a11yProps(1)}
							sx={(theme) => ({
								textTransform: "none",
								fontSize: theme.typography.label_medium,
								fontFamily: theme.typography.label_medium,
							})}
						/>
						<Tab
							icon={<Whatshot />}
							label="Inspiration"
							{...a11yProps(2)}
							sx={(theme) => ({
								textTransform: "none",
								fontSize: theme.typography.label_medium,
								fontFamily: theme.typography.label_medium,
							})}
						/>
						<Tab
							icon={<StarsRounded />}
							label="Reviews"
							{...a11yProps(3)}
							sx={(theme) => ({
								textTransform: "none",
								fontSize: theme.typography.label_medium,
								fontFamily: theme.typography.label_medium,
							})}
						/>
					</Tabs>
				</Box>
				<Box>
					<TabPanel value={knowTabIndex} index={0}>
						<Stack gap={3} p={3}>
							<Typography variant="title_medium">Details</Typography>
							<Divider />
							<Typography variant={"body1"}>{details}</Typography>
							<Stack>
								<AttributeItem icon="time" name="Time Estimate" />
								<Stack direction={"row"} alignItems={"baseline"} pl={4}>
									<Typography variant={"body1"}>{timeShort}</Typography>
									<Typography variant={"body1"}> – </Typography>
									<Typography variant={"body1"}>{timeLong}</Typography>
									<Typography variant={"body1"}>&nbsp;hours</Typography>
								</Stack>
							</Stack>
							<Stack>
								<AttributeItem icon="steps" name="Difficulty" />
								<Typography variant={"body1"} pl={4}>
									{difficulty.capitalize()}
								</Typography>
							</Stack>
							<Stack>
								<AttributeItem icon="person" name="Team Recommended" />
								<Typography variant={"body1"} pl={4}>
									{recommended.capitalize()}
								</Typography>
							</Stack>
						</Stack>
					</TabPanel>
					<TabPanel value={knowTabIndex} index={1}>
						<Stack gap={2} p={3}>
							<Typography variant="title_medium">Power Tips</Typography>
							<div
								dangerouslySetInnerHTML={{ __html: powertips ? stateToHTML(convertFromRaw(JSON.parse(powertips)), stateToHTMLOptions) : "" }}
							/>
						</Stack>
					</TabPanel>
					<TabPanel value={knowTabIndex} index={2}>
						<Stack gap={2} p={3} alignItems={"stretch"}>
							<Typography variant="title_medium">Inspiration</Typography>
							<Divider />
							<Stack gap={4}>
								{inspirations?.map(({ media, description }, i) => (
									<InspirationItem key={i} media={media} description={description} />
								))}
							</Stack>
						</Stack>
					</TabPanel>
					<TabPanel value={knowTabIndex} index={3}>
						<Stack gap={2} p={3}>
							<ReviewsTab scrollRef={scrollRef} powerupID={id} />
						</Stack>
					</TabPanel>
				</Box>
				<Divider />
			</Paper>
		</Stack>
	);
}

function SectionHeader({ title }) {
	return (
		<Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
			<img src={POWERUPLOGO} alt="" />
			<Typography variant="overlineLarge" sx={{ textTransform: "uppercase" }}>
				{title}
			</Typography>
		</Stack>
	);
}

function Summary({ title, description, rating, overview, outlines, shareContact, setShareContact, resume, startSection }) {
	return (
		<Stack gap={3} mb={3}>
			<Paper elevation={1}>
				<Stack p={3} gap={2}>
					<img src={POWERUPLOGO} alt="" style={{ alignSelf: "flex-start" }} />
					<Typography variant={"h1"}>{title}</Typography>
					<Typography>{description}</Typography>
				</Stack>
				<Divider />
				<Stack direction={"row"} p={3} alignItems={"center"} justifyContent={"space-between"}>
					<Rating name="read-only" value={rating} readOnly precision={0.5} />
					<Button sx={{ margin: 0, width: "unset" }} onClick={startSection}>
						{resume ? "Resume" : "Start"}
					</Button>
				</Stack>
				{/*<Stack direction={"row"} px={3} pb={3} alignItems={"center"}>*/}
				{/*	<FormControlLabel*/}
				{/*		label="Share my contact info with Nyentek LLC"*/}
				{/*		control={<Switch checked={shareContact} onChange={() => setShareContact(!shareContact)} />}*/}
				{/*	/>*/}
				{/*	<Typography flexGrow={1} variant={"caption"} textAlign={"end"}>*/}
				{/*		Nyentek LLC will be able to view any information you input during this PowerUp*/}
				{/*	</Typography>*/}
				{/*</Stack>*/}
			</Paper>
			<Paper elevation={1}>
				<Stack p={3} gap={3}>
					{/*{media ? <iframe src={media}*/}
					{/*	// TODO: Probably have to parse the media URL to change to the embeddable version*/}
					{/*				 style={{*/}
					{/*					 width: '100%', aspectRatio: '16 / 9', border: 'none', borderRadius: '8px'*/}
					{/*				 }}*/}
					{/*				 allowFullScreen>*/}
					{/*</iframe> : cover ? <img src={process.env.REACT_APP_CLOUDFRONT_URL + cover}*/}
					{/*						 alt="PowerUp Cover Image"*/}
					{/*						 style={{ maxWidth: '100%', borderRadius: '8px' }}/> : ''}*/}
					<Stack gap={2}>
						<Typography variant={"largeTitle"}>Overview</Typography>
						<div dangerouslySetInnerHTML={{ __html: overview ? stateToHTML(convertFromRaw(JSON.parse(overview)), stateToHTMLOptions) : "" }} />
					</Stack>
					{outlines.length > 0 && (
						<Stack gap={2}>
							<Typography variant={"largeTitle"}>Contents</Typography>
							<AccordionWrapper>
								{outlines.map(({ title, description }, i) => (
									<OutlineItem key={i} index={i + 1} title={title} description={description} />
								))}
							</AccordionWrapper>
						</Stack>
					)}
				</Stack>
			</Paper>
		</Stack>
	);
}

function Output({ title, output, outputWeb, onRetakeClick, onFinishClick, currentSubmission }) {
	const iframeRef = useRef(null);
	useEffect(() => {
		if (iframeRef.current) {
			iframeRef.current.height = iframeRef.current.contentWindow?.document?.body?.scrollHeight || 400;
		}
	}, [iframeRef.current]);

	function generateHTML(payload) {
		//outputWeb -- img?
		let htmlString = `<h1>${title}</h1>`;

		payload.contents.forEach((content) => {
			if (content.inputs.length > 0) {
				htmlString += `<h2>${content.title}</h2>`;

				content.inputs.forEach((input, index) => {
					htmlString += `<h3>${input.title}</h3>`;
					if (input?.description) {
						try {
							htmlString += `<p>${JSON.parse(input.description).blocks[0].text}</p>`;
						} catch (e) {
							console.log(e);
						}
					}

					if (input?.value) {
						htmlString += `<h5>Your Response:</h5>`;
						htmlString += `<p>${input.value}</p>`;
						if (index !== content.inputs.length - 1) {
							htmlString += "<hr />";
						}
					}
				});
			}
		});
		if (payload?.outputWeb) {
			htmlString += "<hr />";
			htmlString += `<h3>Output</h3>`;
			htmlString += `<pdfimg data-pdfimg>${payload?.outputWeb}</pdfimg>`;
		}
		return htmlString;
	}

	return (
		<Paper elevation={1}>
			<Stack gap={3} p={3}>
				<SectionHeader title={title} />
				<Divider />
				{/* <Typography variant={"largeTitle"}>Summary</Typography> */}
				{/* <Typography variant={"subhead1"}>
					Congratulations! Below is a summary of the work you’ve completed in this PowerUp. To edit or review your completed steps, select the steps above to
					navigate back. You can take this PowerUp as many times as you’d like. Don’t forget to get feedback on your completed work.
				</Typography> */}
				<Box p={3} borderRadius={"16px"} bgcolor={"rgba(106, 117, 117, 0.08)"}>
					{outputWeb ? (
						<iframe ref={iframeRef} title="Output" srcDoc={outputWeb} style={{ width: "100%", border: "none", borderRadius: "2px" }} />
					) : (
						<>
							<Typography variant={"h2"}>{output?.title}</Typography>
							<div
								dangerouslySetInnerHTML={{
									__html: output?.contents ? stateToHTML(convertFromRaw(JSON.parse(output.contents)), stateToHTMLOptions) : "",
								}}
							/>
						</>
					)}
				</Box>
				<Divider />
				<Stack direction={"row"} alignItems={"center"} justifyContent={"flex-end"} gap={2}>
					{/*<Stack direction={'row'} alignItems={'center'} gap={2}>*/}
					{/*	<Tooltip title="Archive"><IconButton aria-label="Archive"><ArchiveOutlined/></IconButton></Tooltip>*/}
					{/*	<Tooltip title="Take Again"><IconButton aria-label="Take Again"><Loop/></IconButton></Tooltip>*/}
					{/*</Stack>*/}
					{/*<Stack direction={'row'} alignItems={'center'} gap={2}>*/}

					{currentSubmission &&
						currentSubmission?.state === "final" &&
						currentSubmission?.contents &&
						currentSubmission?.contents?.some((content) => content?.inputs?.length > 0) && (
							<TextDocDownload
								type={"html"}
								contentString={generateHTML(currentSubmission)}
								buttonText={"Download Your Responses"}
								fileName={`${title}-Responses.pdf`}
							/>
						)}
					<Button sx={{ width: "unset", margin: "unset" }} variant={"outlined"} onClick={onRetakeClick}>
						Retake
					</Button>
					<Button sx={{ width: "unset", margin: "unset" }} onClick={onFinishClick}>
						Finish
					</Button>
					{/*</Stack>*/}
				</Stack>
			</Stack>
		</Paper>
	);
}

function Content({ title, section, prevSection, nextSection, showSubmit, answers, onAnswersChange, onActionChange, uploadMedia }) {
	console.log("Content", section, answers);

	const onAnswerChange = (answer, index) => {
		const ans = [...answers];
		ans[index] = answer;
		onAnswersChange(ans);
	};

	return (
		<Paper elevation={1} sx={{ padding: 3 }}>
			<Stack gap={4}>
				<SectionHeader title={title} />
				<Divider />
				{/*<WorkspaceStepper/>*/}
				<Stack gap={3}>
					<Typography variant={"h2"}>{section.title}</Typography>
					{/*Rendered HTML*/}
					{/*<iframe style={{ width: '100%', aspectRatio: '16 / 9', borderRadius: 8 }}*/}
					{/*		src="https://www.youtube.com/embed/dQw4w9WgXcQ"*/}
					{/*		frameBorder="0"*/}
					{/*		allowFullScreen></iframe>*/}
					<div
						dangerouslySetInnerHTML={{
							__html: section.description ? stateToHTML(convertFromRaw(JSON.parse(section.description)), stateToHTMLOptions) : "",
						}}
					/>
					<Divider />
				</Stack>
				<Stack gap={3}>
					{section.inputs.map(({ type, id, title, description, visible, required, prop }, index) => {
						if (!visible) {
							return "";
						}
						switch (type) {
							case "line":
								return (
									<ShortAnswer
										key={id}
										id={id}
										title={title}
										description={description}
										required={required}
										prop={prop}
										answer={answers[index]}
										onAnswerChange={(answer) => onAnswerChange(answer, index)}
									/>
								);
							case "field":
								return (
									<Paragraph
										key={id}
										id={id}
										title={title}
										description={description}
										required={required}
										prop={prop}
										answer={answers[index]}
										onAnswerChange={(answer) => onAnswerChange(answer, index)}
										uploadMedia={uploadMedia}
									/>
								);
							case "dropdown":
								return (
									<Dropdown
										key={id}
										id={id}
										title={title}
										description={description}
										required={required}
										prop={prop}
										answer={answers[index]}
										onAnswerChange={(answer) => onAnswerChange(answer, index)}
										onActionChange={onActionChange}
									/>
								);
							case "choices":
								return (
									<MultipleChoice
										key={id}
										id={id}
										title={title}
										description={description}
										required={required}
										prop={prop}
										answer={answers[index]}
										onAnswerChange={(answer) => onAnswerChange(answer, index)}
										onActionChange={onActionChange}
									/>
								);
							case "checkboxes":
								return (
									<Checkboxes
										key={id}
										id={id}
										title={title}
										description={description}
										required={required}
										prop={prop}
										answer={answers[index]}
										onAnswerChange={(answer) => onAnswerChange(answer, index)}
										onActionChange={onActionChange}
									/>
								);
							case "choicegrid":
								return (
									<MultipleChoiceGrid
										key={id}
										id={id}
										title={title}
										description={description}
										required={required}
										prop={prop}
										answer={answers[index]}
										onAnswerChange={(answer) => onAnswerChange(answer, index)}
									/>
								);
							case "checkboxgrid":
								return (
									<CheckboxGrid
										key={id}
										id={id}
										title={title}
										description={description}
										required={required}
										prop={prop}
										answer={answers[index]}
										onAnswerChange={(answer) => onAnswerChange(answer, index)}
									/>
								);
							case "slider":
								return (
									<Slider
										key={id}
										id={id}
										title={title}
										description={description}
										required={required}
										prop={prop}
										answer={answers[index]}
										onAnswerChange={(answer) => onAnswerChange(answer, index)}
									/>
								);
							case "blank":
								return <Blank key={id} id={id} title={title} description={description} />;
							default:
								return "";
						}
					})}
				</Stack>
				{/* <Divider /> */}
				<Stack direction={"row"} justifyContent={"flex-end"} gap={2}>
					<Button variant={"outlined"} sx={{ width: "unset", margin: 0, flexBasis: "12.5%" }} onClick={prevSection}>
						Back
					</Button>
					<Button
						sx={{ width: "unset", margin: 0, flexBasis: "12.5%" }}
						onClick={nextSection}
						disabled={answers
							.filter((_, index) => section.inputs[index]?.visible && section.inputs[index]?.required)
							.reduce((bool, { type, optionTitle, value, options, rows }) => {
								switch (type) {
									case "line":
									case "field":
										return bool || !value;
									case "dropdown":
									case "choices":
										return bool || !optionTitle || isNaN(value);
									case "checkboxes":
										return bool || options.reduce((bool, { value }) => bool && !value, true);
									case "choicegrid":
										return bool || rows.reduce((bool, { cols }) => bool || cols.reduce((bool, { value }) => bool && !value, true), false);
									case "checkboxgrid":
										return bool || rows.reduce((bool, { cols }) => bool && cols.reduce((bool, { value }) => bool && !value, true), true);
									default:
										return bool;
								}
							}, false)}
					>
						{showSubmit ? "Continue" : "Next"}
					</Button>
				</Stack>
			</Stack>
		</Paper>
	);
}

function PowerUpPreview({
	match: {
		params: { id },
	},
	auth,
}) {
	const history = useHistory();
	const powerUps = useContext(PowerUps.Context);
	const powerUp = powerUps.powerUp;

	const reload = () => {
		if (id) {
			powerUps.get(id);
			powerUps.getSubmission({ id, sid: "draft" });
		}
	};
	useEffect(reload, [id]);
	useEffect(() => {
		if (powerUps.errors?.status === 404) {
			history.replace(ROUTES_PATH.STARTUPS_HOME);
		}
	}, [powerUps.errors]);

	const title = powerUp?.title ?? "";
	const description = powerUp?.description ?? "";
	const summary = powerUp?.summary ?? "";
	const details = powerUp?.details ?? "";
	const timeShort = powerUp?.time?.short ?? 0;
	const timeLong = powerUp?.time?.long ?? 0;
	const difficulty = powerUp?.difficulty ?? "easy";
	const recommended = powerUp?.recommendation ?? "optional";
	const powertips = powerUp?.powertips ?? "";
	const rating = powerUp?.rating ?? 0;
	const outlines = powerUp?.outlines ?? [];
	const outputs = (powerUp?.outputs ?? []).reduce((o, v) => ((o[v.id] = v), o), {});
	const inspirations = powerUp?.inspirations ?? [];
	const submissions = powerUp?.submissions ?? [];
	const submissionsSort = powerUp?.submissionsSort;
	const submission = powerUp?.submission;

	const [output, setOutput] = useState(null);
	const [sections, setSections] = useState([]);
	const [contents, setContents] = useState([]);

	function generateHTML(payload) {
		let htmlString = `<h1>${title}</h1>`;

		payload.contents.forEach((content) => {
			if (content.inputs.length > 0) {
				htmlString += `<h2>${content.title}</h2>`;

				content.inputs.forEach((input, index) => {
					htmlString += `<h3>${input.title}</h3>`;
					htmlString += `<p>${JSON.parse(input.description).blocks[0].text}</p>`;
					if (input?.value) {
						htmlString += `<h5>Your Response:</h5>`;
						htmlString += `<p>${input.value}<p>`;
						if (index !== content.inputs.length - 1) {
							htmlString += "<hr />";
						}
					}
				});
			}
		});
		return htmlString;
	}

	useEffect(() => {
		if (powerUp?.sections?.length > 0) {
			const con = contents.reduce(
				(o, v) => (
					(o[v.id] = {
						...v,
						inputs: v.inputs.reduce((o, i) => ((o[i.id] = i), o), {}),
					}),
					o
				),
				{}
			);

			const sub =
				submission?.contents.reduce(
					(o, v) => (
						(o[v.id] = {
							...v,
							inputs: v.inputs.reduce((o, i) => ((o[i.id] = i), o), {}),
						}),
						o
					),
					{}
				) ?? {};

			const sec = sections.reduce(
				(o, v) => (
					(o[v.id] = {
						...v,
						inputs: v.inputs.reduce((o, i) => ((o[i.id] = i), o), {}),
					}),
					o
				),
				{}
			);

			const secs = [];
			const cons = [];
			for (const [index, section] of powerUp?.sections.entries()) {
				const { id: sid, title } = section;
				secs[index] = {
					...section,
					visible: sub[sid]?.visible ?? sec[sid]?.visible ?? section.visible,
					inputs: section.inputs.map((input) => ({
						...input,
						visible: sub[sid]?.inputs[input.id]?.visible ?? sec[sid]?.inputs[input.id]?.visible ?? input.visible,
						required: sub[sid]?.inputs[input.id]?.required ?? sec[sid]?.inputs[input.id]?.required ?? input.required,
					})),
				};
				cons[index] = {
					id: sid,
					title,
					inputs: secs[index].inputs.map(({ type, id, title, description, prop }) => ({
						type,
						id,
						title,
						description,
						...(type === "line" || type === "field" ? { value: sub[sid]?.inputs[id]?.value ?? con[sid]?.inputs[id]?.value } : {}),
						...(type === "slider"
							? { value: sub[sid]?.inputs[id]?.value ?? con[sid]?.inputs[id]?.value ?? (Number(prop.min) + Number(prop.max)) / 2 }
							: {}),
						...(type === "dropdown" || type === "choices"
							? {
									optionTitle: sub[sid]?.inputs[id]?.optionTitle ?? con[sid]?.inputs[id]?.optionTitle,
									value: sub[sid]?.inputs[id]?.value ?? con[sid]?.inputs[id]?.value,
							  }
							: {}),
						...(type === "checkboxes"
							? {
									options: prop.options.map(({ title }, i) => ({
										title,
										value:
											(type === sub[sid]?.inputs[id]?.type && !!sub[sid]?.inputs[id]?.options[i]?.value) ||
											(type === con[sid]?.inputs[id]?.type && !!con[sid]?.inputs[id]?.options[i]?.value),
									})),
							  }
							: {}),
						...(type === "choicegrid" || type === "checkboxgrid"
							? {
									rows: prop.rows.map(({ title }, i) => ({
										title,
										cols: prop.cols.map(({ title }, j) => ({
											title,
											value:
												(type === sub[sid]?.inputs[id]?.type && !!sub[sid]?.inputs[id]?.rows[i]?.cols[j]?.value) ||
												(type === con[sid]?.inputs[id]?.type && !!con[sid]?.inputs[id]?.rows[i]?.cols[j]?.value),
										})),
									})),
							  }
							: {}),
					})),
				};
			}

			setSections(secs);
			setContents(cons);
		}
		if (submission || powerUp?.outputs?.length > 0) {
			setOutput(output ?? submission?.output ?? powerUp?.outputs.find(({ defacto }) => defacto) ?? powerUp?.outputs[0]);
		}
		if (submission) {
			setShareContact(submission.contact);
		}
	}, [powerUp]);

	const [shareContact, setShareContact] = useState(true);
	const [sectionIndex, setSectionIndex] = useState(-1);
	const showOutput = sectionIndex >= sections.length;

	const updateSubmission = ({ state, index, contents }) => {
		if (submission?.id) {
			const sec = sections.reduce(
				(o, v) => (
					(o[v.id] = {
						...v,
						inputs: v.inputs.reduce((o, i) => ((o[i.id] = i), o), {}),
					}),
					o
				),
				{}
			);
			contents = contents.map((content) => ({
				...content,
				visible: sec[content.id]?.visible ?? content.visible,
				inputs: content.inputs.map((input) => ({
					...input,
					visible: sec[content.id]?.inputs[input.id]?.visible ?? input.visible,
					required: sec[content.id]?.inputs[input.id]?.required ?? input.required,
				})),
			}));

			powerUps.updateSubmission({
				id,
				sid: submission?.id,
				state,
				contact: shareContact,
				index,
				output,
				contents,
			});
		}
	};

	const prevSection = () => {
		window.scrollTo(0, 0);

		let index = sectionIndex - 1;
		for (; index >= 0; index--) {
			if (sections[index].visible) {
				break;
			}
		}
		setSectionIndex(index);

		if (index >= 0) {
			updateSubmission({ state: "draft", index, contents });
		}
	};

	const setNextIndex = () => {
		let index = sectionIndex + 1;
		for (; index < sections.length; index++) {
			if (sections[index].visible) {
				break;
			}
		}
		setSectionIndex(index);
		return index;
	};

	const nextSection = () => {
		window.scrollTo(0, 0);

		const index = setNextIndex();

		updateSubmission({ state: index === sections.length ? "final" : "draft", index, contents });
		if (index === sections.length) {
			setTimeout(() => {
				powerUps.getSubmissions({ id });
			}, 1000);
		}
	};

	const startSection = () => {
		if (submission) {
			setSectionIndex(submission.index);
		} else {
			const index = setNextIndex();

			handleMixPanel("PUB PowerUp Started", {
				"Powerup ID": id,
				"Powerup Name": title,
			});

			powerUps.createSubmission({
				id,
				contact: shareContact,
				index,
				output,
				contents,
			});
		}
	};

	const handleFinish = () => {
		handleMixPanel("PUB PowerUp Completed", {
			"Powerup ID": id,
			"Powerup Name": title,
		});

		history.push(ROUTES_PATH.STARTUPS_HOME);
	};

	return (
		<>
			<Stack px={3} pb={3} direction={"row"} gap={3} alignItems={"center"} justifyContent={"space-between"}>
				<Breadcrumbs separator={<NavigateNext fontSize="small" />}>
					<Link underline="hover" sx={{ cursor: "pointer" }} onClick={() => history.push(ROUTES_PATH.STARTUPS_HOME)}>
						<Typography variant="h5">PowerUps</Typography>
					</Link>
					<Link underline="hover" sx={{ cursor: "pointer" }} onClick={() => history.push(ROUTES_PATH.PARTNER_POWERUP_PREVIEW.replace(":id", id))}>
						<Typography variant="h5">{title}</Typography>
					</Link>
				</Breadcrumbs>
			</Stack>
			<ExcerciseWrapper>
				<Grid container spacing={3}>
					<Grid item xl={8} lg={7} md={12}>
						{sectionIndex < 0 && (
							<Summary
								title={title}
								description={description}
								rating={rating}
								overview={summary}
								outlines={outlines}
								shareContact={shareContact}
								setShareContact={setShareContact}
								resume={!!submission}
								startSection={startSection}
							/>
						)}
						{sectionIndex >= 0 && sectionIndex < sections.length && (
							<Content
								title={title}
								section={sections[sectionIndex]}
								prevSection={prevSection}
								nextSection={nextSection}
								showSubmit={sectionIndex === sections.length - 1}
								answers={contents[sectionIndex].inputs}
								onAnswersChange={(answers) => {
									const cons = [...contents];
									cons[sectionIndex] = {
										...contents[sectionIndex],
										inputs: answers,
									};
									console.log("onAnswersChange", cons);
									setContents(cons);

									updateSubmission({ state: "draft", index: sectionIndex, contents: cons });
								}}
								onActionChange={({ type, target, display }) => {
									console.log("onActionChange", { type, target, display });
									switch (type) {
										case "input": {
											const secs = [...sections];
											const input = secs.flatMap((v) => v.inputs).find(({ id }) => id === target);
											input.visible = display === "visible" ? true : display === "hidden" ? false : input.visible;
											input.required = display === "required" ? true : display === "optional" ? false : input.required;
											setSections(secs);
											break;
										}
										case "section": {
											const secs = [...sections];
											secs.find(({ id }) => id === target).visible =
												display === "visible" ? true : display === "hidden" ? false : secs[target].visible;
											setSections(secs);
											break;
										}
										case "output": {
											setOutput({ ...outputs[target] });
											break;
										}
									}
								}}
								uploadMedia={powerUps?.uploadMedia}
							/>
						)}
						{showOutput && (
							<Output
								title={title}
								output={output}
								outputWeb={submission?.outputWeb}
								onRetakeClick={() => {
									setSectionIndex(-1);
									setOutput(null);
									setSections([]);
									setContents([]);
									reload();
								}}
								onFinishClick={handleFinish}
								currentSubmission={submission}
							/>
						)}
					</Grid>
					<Grid item xl={4} lg={5} md={12}>
						<KnowledgeBar
							title={title}
							details={details}
							timeShort={timeShort}
							timeLong={timeLong}
							difficulty={difficulty}
							recommended={recommended}
							powertips={powertips}
							inspirations={inspirations}
							submissions={submissions}
							sort={submissionsSort}
							id={powerUp?.id}
						/>
					</Grid>
				</Grid>
			</ExcerciseWrapper>
		</>
	);
}

const mapStoreToProps = ({ auth }) => ({
	auth,
});

export default connect(mapStoreToProps)(PowerUpPreview);
