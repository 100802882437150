import React from "react";
import { CardFounderWrapper } from "../styles";
import { Box, Grid, Typography } from "@mui/material";
import { ScoreChart } from "../../Charts/ScoreChart";
import BenchmarkTrend from "../../ScoreBenchmarkDetails/Components/HeroSection/benchmarkTrend";
import { useDispatch, useSelector } from "react-redux";
import FounderBenchmarkTrend from "../FounderBenchmarkTrend";
import { setFounderDashboardData } from "../../../modules/actions";
import { InfoRounded } from "@mui/icons-material";
import InfoHelper from "../InfoHelper";

function Benchmark() {
	const STARTUP = useSelector((state) => state?.founderDashboard?.startUpCardData);
	const SCORE_OBJ_TREND = useSelector((state) => state?.founderDashboard?.ScoreReportObj?.scoreTrend);
	const RANGE_SELECTION = useSelector((state) => state?.founderDashboard?.scoreTrendRangeSelection);
	const dispatch = useDispatch();

	const handleChangeSetData = (newValue) => {
		dispatch(setFounderDashboardData({ scoreTrendRangeSelection: newValue }));
	};

	return (
		<CardFounderWrapper>
			<Grid container spacing={5}>
				<Grid item xs={12} container>
					<Grid item xs={12}>
						<Box sx={{ display: "flex", alignItems: "center", gap: "12px" }}>
							<Typography variant="main_title" color="secondary.black">
								Benchmark
							</Typography>
							<InfoHelper
								title={"Benchmark"}
								body={
									"View your StartupOS score, which is calculated based on your completion of the Account Profile, Startup Profile, Mentor Meetings, PowerUps, and other factors, in comparison to your peers throughout the platform."
								}
							/>
						</Box>
					</Grid>
					<Grid item xs={12}>
						<Box display="flex" justifyContent="center">
							<ScoreChart
								percentage={parseInt(STARTUP?.scoreCard?.peerGroupPercentile ? STARTUP?.scoreCard?.peerGroupPercentile : "0")}
								scoreValue={STARTUP?.currentScore ? STARTUP?.currentScore : "0"}
								stageName={STARTUP?.currentStage ? STARTUP?.currentStage : ""}
								stageValue={STARTUP?.scoreCard?.peerGroupCount ? STARTUP?.scoreCard?.peerGroupCount : "0"}
								scoreObj={STARTUP?.scoreCard}
							/>
						</Box>
					</Grid>
				</Grid>
				<Grid item xs={12} container>
					<Grid item xs={12}>
						<Box>
							<Typography variant="main_title" color="secondary.black">
								Score Trend
							</Typography>
						</Box>
					</Grid>
					<Grid item xs={12}>
						<Box display="flex" justifyContent="center">
							<FounderBenchmarkTrend
								showTitle={false}
								SCORE_OBJ_TREND={SCORE_OBJ_TREND}
								RANGE_SELECTION={RANGE_SELECTION}
								handleChangeSetData={handleChangeSetData}
							/>
						</Box>
					</Grid>
				</Grid>
			</Grid>
		</CardFounderWrapper>
	);
}

export default Benchmark;
