import TodoIcon from "../Assets/new-home-todo-icon.svg";
import PowerPacksIcon from "../Assets/new-home-powerpacks-head-icon.svg";
import LabsIcon from "../Assets/new-home-labs-icon.svg";
import ToolsIcon from "../Assets/new-home-tools-icon.svg";
import HistoryIcon from "../Assets/new-home-history-icon.svg";

export const HOME_TITLES = [
	{
		id: 0,
		icon: TodoIcon,
		title: "Get started on the StartupOS platform!",
		desc: "Explore our feature-packed StartupOS platform empowering you to achieve crucial startup milestones, from ideation to fundraising and growth! Check out our vibrant community, where you can connect with investors and mentors, paving the way for your journey towards investor pitch perfection. ",
	},
	{
		id: 1,
		icon: PowerPacksIcon,
		title: "Dive into our PowerPacks to gain knowledge and elevate your startup’s growth!",
		desc: "PowerPacks are a curated group of PowerUps logically sequenced to advance the startup team to a specific decision point or outcome. Complete PowerUps as-prescribed or as-needed.",
	},
	{
		id: 2,
		icon: LabsIcon,
		title: "Jump into our PowerUps to learn, boost and grow your startup!",
		desc: "Our PowerUps are purpose-built learn-by-doing exercises designed to build competency and confidence and produce outcomes that create forward momentum.",
	},
	{
		id: 3,
		icon: ToolsIcon,
		title: "Jump into our PowerUps to learn, boost and grow your startup!",
		desc: "Leverage the potential of interviews and surveys to drive your startup's growth. Uncover valuable insights, connect with your target audience, and unlock new opportunities for success.",
	},
	{ id: 4, icon: HistoryIcon, title: "Teamwork Makes the Dream Work!", desc: "Take a look at the amazing work and collaboration from your startup team!" },
];

export const HOME_TOOLSTIPS = [
	[
		{
			id: 0,
			title: "PowerPacks",
			desc: "PowerPacks are a curated group of PowerUps logically sequenced to advance the startup team to a specific decision point or outcome. Complete PowerUps as-prescribed or as-needed.",
		},
		{
			id: 1,
			title: "PowerUps",
			desc: "Purpose-built learn-by-doing exercises designed to build competency and confidence and produce outcomes that create forward momentum.",
		},
		{
			id: 2,
			title: "Activities",
			desc: `The StartupOS "Operating System"  includes a comprehensive and flexible set of tools that can be used to manage and grow your venture. It helps streamline the complex tasks of managing your business, and navigate the labyrinth of challenges you'll encounter along the entire arc of your journey.`,
		},
	],
	[
		{
			id: 0,
			title: "PowerPacks",
			desc: "PowerPacks are a curated group of PowerUps logically sequenced to advance the startup team to a specific decision point or outcome. Complete PowerUps as-prescribed or as-needed.",
		},
	],
	[
		{
			id: 0,
			title: "PowerUps",
			desc: "Purpose-built learn-by-doing exercises designed to build competency and confidence and produce outcomes that create forward momentum.",
		},
	],
	[
		{
			id: 0,
			title: "Surveys",
			desc: "Surveys are an essential tool for product design and marketing because they allow you to collect feedback from your users and customers. This feedback can help you understand their needs, pain points, and satisfaction with your product or service. This information can be used to improve your product or service, and to better target your marketing efforts.",
		},
		{
			id: 1,
			title: "Interviews",
			desc: "Interviews are essential for startups because they help you understand your users and customers and their needs. By talking to the users of your product or service, you can get valuable feedback to help you improve your design, functionality, and overall messaging. This can lead to a better user experience, which will ultimately lead to more satisfied customers and a more successful business.",
		},
	],
];

export const CARD_GROUP_OPTIONS = [
	{ id: 0, label: "Idea & Market Validation", value: "Idea & Market Validation" },
	{ id: 1, label: "MVP Design & Development", value: "MVP Design & Development" },
	{ id: 2, label: "Product Market Fit", value: "Product Market Fit" },
	{ id: 3, label: "Networking & Mentorship", value: "Networking & Mentorship" },
	{ id: 4, label: "Pitch Deck and Storytelling", value: "Pitch Deck and Storytelling" },
	{ id: 5, label: "Partnerships & Collaboration", value: "Partnerships & Collaboration" },
	{ id: 6, label: "Scaling Product & Business", value: "Scaling Product & Business" },
	{ id: 7, label: "Funding Opportunities", value: "Funding Opportunities" },
];
