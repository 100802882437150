import React from "react";
import { Typography, Box } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { CompletedWrapper, PointsWrapper } from "./styles";
import {
  POWERUPLOGO,
  DEMO_DAY_LOGO,
  FUSION_LOGO,
  PLUGIN_LOGO,
} from "../../constants";

export default function PowerStatus({
  powerStatusData
}) {


  const getLogoSrc = (statusFlag) => {
    if (statusFlag === "POWER_UP") {
      return POWERUPLOGO
    } else if (statusFlag === "PLUGIN") {
      return PLUGIN_LOGO
    } else if (statusFlag === "FUSION") {
      return FUSION_LOGO
    } else if (statusFlag === "DEMO_DAY")  {
      return DEMO_DAY_LOGO
    }
    return ""
  };

  return (
   
          <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  pb: 1,
                }}
              >
                <Box
                  src={getLogoSrc(powerStatusData.statusType)}
                  component="img"
                ></Box>
                {powerStatusData.status === "STARTED" ? (
                    <CompletedWrapper>
                      <Typography variant="overlineLarge">
                        In Progress
                      </Typography>
                    </CompletedWrapper>
                  ) : powerStatusData.status === "COMPLETED" ? (
                    <CompletedWrapper>
                      <CheckCircleIcon />
                      <Typography variant="overlineLarge">Complete</Typography>
                    </CompletedWrapper>
                  ) : (
                    <></>
                    // <PointsWrapper>
                    //   <Typography variant="overlineLarge">25 Points</Typography>
                    // </PointsWrapper>
                  )}
              </Box>
  );
}
