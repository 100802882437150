import React, { useContext } from "react";

import { Typography } from "@mui/material";
import { ProfileContext } from "../..";
import VideoPlayerComponent from "../../../../common/videoPlayer";
import MissingAsset from "../MissingAsset";
import GridLayout from "./GridLayout";
import { VideoContainer } from "./style";

export default function ElevatorPitchSection() {
	const { profileData, viewContext, updateProfile } = useContext(ProfileContext);

	const VideoSection = () => {
		return (
			<>
				{profileData?.aboutUsVideoUrl ? (
					<VideoContainer>
						<VideoPlayerComponent videoUrl={profileData?.aboutUsVideoUrl} height="100%" />
					</VideoContainer>
				) : (
					<MissingAsset sxProps={{ height: "450px", p: 2 }} message={"Elevator Pitch Video Coming Soon"} />
				)}
			</>
		);
	};
	const TextSection = () => {
		return (
			<Typography
				component={"div"}
				variant="Text/sm/Regular"
				sx={{
					color: "black",
				}}
			>
				<span
					dangerouslySetInnerHTML={{
						__html: profileData?.about ? profileData?.about : `${profileData?.companyName} has not completed their elevator pitch.`,
					}}
				/>
			</Typography>
		);
	};

	return <GridLayout left={<TextSection />} right={<VideoSection />} />;
}
