import { Typography, Button, Box, Slider } from "@mui/material";
import { styled } from "@mui/material/styles";

export const CustomSlider = styled(Slider)({
	color: (theme) => theme.palette.primary.primaryPurple,
	height: 8,
	width: 300,
	"& .MuiSlider-track": {
		border: "none",
	},
	"& .MuiSlider-thumb": {
		height: 20,
		width: 20,
		backgroundColor: "#fff",
		border: "5px solid currentColor",
		"&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
			boxShadow: "inherit",
		},
		"&:before": {
			display: "none",
		},
		"& .MuiSlider-valueLabel": {
			display: "none",
		},
		"& .MuiSlider-mark": {
			backgroundColor: "#bfbfbf",
			height: 8,
			width: 1,
			"&.MuiSlider-markActive": {
				opacity: 1,
				backgroundColor: "currentColor",
			},
		},
	},
});
