import moment from "moment";
import momentTimezone from "moment-timezone";

export const getCurrentDate = (date) => moment(date || new Date()).format("MMM DD, YYYY");

export const getDate = (date) => moment(date).date();

export const getCharMonth = (date) => moment(date).format("MMM");

export const getCharDay = (date) => moment(date).format("dddd");
export const getShortCharDay = (date) => moment(date).format("ddd");

export const getYear = (date) => moment(date).format("yyyy");
export const getMonth = (date) => moment(date).format("M");

export const getCharDateMonth = (date) => moment(date).format("MMM DD");

export const getRemainingDays = (date) => {
	let now = new Date();
	let distance = date - now;
	return Math.ceil(distance / (1000 * 60 * 60 * 24));
};

export const getFormattedDate = (date, format) => moment(date).format(format);

export const convertFormattedStringToDate = (string, format) => moment(string, format).toDate();

export const getCurrentTime = (time) => {
	return moment(time, ["HH:mm"]).format("hh:mm A");
};

export const getGlobalTime = (time) => {
	return moment(time, ["HH:mm A"]).format("HH:mm");
};

export const getLocalTimeZone = () => {
	return momentTimezone.tz.guess();
};

export const getTimeSlots = (startTime, endTime, duration) => {
	let fromTime = moment(startTime, "HH:mm");
	let toTime = moment(endTime, "HH:mm");
	let slotArray = [];
	if (fromTime <= toTime) {
		const val = new moment(fromTime).format("HH:mm");
		slotArray.push({
			label: getCurrentTime(val),
			value: val,
		});
		fromTime.add(duration, "minutes");
	}
	return slotArray;
};

export const getTitleDate = (timestamp) => {
	const dateTime = new Date(timestamp);

	return getFormattedDate(dateTime, "dddd, MMM DD");
};

export const getTimeBetweenString = (start, end) => {
	const timeFrom = new Date(start);
	const timeFromStr = getCurrentTime(timeFrom);
	const timeTill = new Date(end);
	const timeTillStr = getCurrentTime(timeTill);
	return `${timeFromStr} - ${timeTillStr}`;
};

export const getBirthYear = (age) => {
	const currentDate = new Date();
	const currentYear = moment(currentDate).format("yyyy");
	return Math.floor(currentYear - age);
};
