import { Box } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import OnboardingCardSelector from "../../../../components/Onboarding/OnboardingCardSelector";
import PageTitle from "../../../../components/Onboarding/PageTitle";
import { SKILLS_AND_EXPERTISE } from "../../../../constants/updated-onboarding";

export default function SkillsAndExpertise({ setComplete }) {
  const [userResponse, setUserResponse] = useState("");
  const callComplete = (complete, data) => {
    if (setComplete) {
      setComplete(complete, data);
    }
  };
  const savedData = useSelector(state => state?.onboarding?.skillsAndExpertise);

  useEffect(() => {
    if (typeof savedData !== "undefined") {
      if (savedData.length) {
        setUserResponse(savedData);
      }
    }
  }, [savedData]);

  useEffect(() => {
    if (userResponse && userResponse.length) {
      callComplete(1, { skillsAndExpertise: userResponse });
    } else {
      callComplete(0, { skillsAndExpertise: [] });
    }
  }, [userResponse]);
  return (
    <Box>
      <PageTitle
        title={"Select your skills and expertise"}
        description={"Pick all that apply."}
      />

      <Box sx={{ my: 5 }}>
        <OnboardingCardSelector
          cardItems={SKILLS_AND_EXPERTISE}
          flex={1 / 3}
          gridColWidth={7}
          multiSelect
          setUserResponse={setUserResponse}
          userResponse={userResponse}
        />
      </Box>
    </Box>
  );
}
