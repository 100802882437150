import React, { useState, useEffect } from "react";
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Box,
  Chip,
} from "@mui/material";

export default function SelectChipInput({
  label,
  onInputChange,
  selectedValue,
  fullWidth,
  options,
  inputKey,
  placeholder,
  labelStyle = {},
  borderNone = false,
  children,
  labelId = "select-label",
  id = "demo-simple-select",
  displayEmpty = false,
  backgroundColor,
  textColor,
  compact = false,
  ...props
}) {
  const [selectedIndex, setSelectedIndex] = useState(0);

  //const handleSelectedData = e => {
  //  setSelectedIndex(options?.indexOf(e.target.value));
  //};

  useEffect(() => {
    if (selectedValue) {
      setSelectedIndex(options.indexOf(selectedValue));
    } else {
      setSelectedIndex(0);
    }
  }, [selectedValue]);

  return (
    <FormControl fullWidth={fullWidth} sx={{ ...props.wrapperstyle }}>
      {label && (
        <InputLabel id="select-label" sx={labelStyle}>
          {label}
        </InputLabel>
      )}
      <Select
        displayEmpty
        placeholder={placeholder}
        labelId={labelId}
        id={id}
        label={label}
        fullWidth={fullWidth}
        select
        onChange={e => {
          onInputChange(e, inputKey);
          //handleSelectedData(e);
        }}
        value={selectedValue || "None"}
        {...props}
        sx={{
          width: compact ? "max-content" : 1,
          "& .MuiOutlinedInput-notchedOutline": {
            border: borderNone && "none",
          },
        }}
        renderValue={selectedValue => {
          if (selectedValue?.length === 0) {
            return placeholder;
          }
          return (
            <Chip
              label={selectedValue}
              sx={{
                borderRadius: 1,
                backgroundColor: backgroundColor[selectedIndex],
                color: textColor[selectedIndex],
              }}
            />
          );
        }}
      >
        {options?.map((option, i) => {
          return (
            <MenuItem key={option} value={option}>
              <Chip
                label={option}
                sx={{
                  borderRadius: 1,
                  backgroundColor: backgroundColor[i],
                  color: textColor[i],
                  cursor: "pointer",
                }}
              />
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
