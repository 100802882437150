import React from "react";
import { useState, useEffect } from "react";
import TreeItem from "@mui/lab/TreeItem";
import TreeView from "@mui/lab/TreeView";
import CropSquareOutlinedIcon from "@mui/icons-material/CropSquareOutlined";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import SquareRoundedIcon from "@mui/icons-material/SquareRounded";
import IndeterminateCheckBoxOutlinedIcon from "@mui/icons-material/IndeterminateCheckBoxOutlined";
import Box from "@mui/material/Box";
import RadioGroup, { useRadioGroup } from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import CustomRadioButton from "./CustomRadioButton";

export default function RadioButtonTree({ treeData, handleSelectedIndustry, selectedIndustries }) {
	const [selected, setSelected] = useState([]);

	const getOnChange = (value, nodes) => {
		console.log(value, nodes);
	};
	useEffect(() => {
		handleSelectedIndustry(selected);
	}, [selected]);

	useEffect(() => {
		let updatedIndustryList = [];
		selectedIndustries.map((industryItem) => {
			updatedIndustryList.push(industryItem?.value);
		});
		setSelected(updatedIndustryList);
	}, [selectedIndustries]);

	const renderTree = (nodes) => (
		<Box sx={{ display: "flex" }}>
			<TreeItem
				key={nodes?.id}
				sx={{
					"& .MuiTreeItem-content.Mui-selected": {
						background: (theme) => theme.palette.secondary.white,
						"&:hover": {
							background: (theme) => theme.palette.secondary.white,
						},
						"&:active": {
							background: (theme) => theme.palette.secondary.white,
						},
						"&:focused": {
							background: (theme) => theme.palette.secondary.white,
						},
						"&:selected": {
							background: (theme) => theme.palette.secondary.white,
						},
					},
					"& .MuiTreeItem-content.Mui-selected.Mui-focused": {
						background: (theme) => theme.palette.secondary.white,
						"&:hover": {
							background: (theme) => theme.palette.secondary.white,
						},
						"&:active": {
							background: (theme) => theme.palette.secondary.white,
						},
						"&:focused": {
							background: (theme) => theme.palette.secondary.white,
						},
						"&:selected": {
							background: (theme) => theme.palette.secondary.white,
						},
					},
				}}
				nodeId={nodes?.id}
				label={
					<FormControlLabel
						label={nodes?.industryname}
						control={
							<CustomRadioButton
								selected={selected}
								setSelected={setSelected}
								value={nodes?.industryname}
								onChange={(event) => getOnChange(event.currentTarget.value, nodes)}
								icon={<CropSquareOutlinedIcon sx={{ fontSize: "large" }} />}
								checkedIcon={<SquareRoundedIcon sx={{ fontSize: "large", color: (theme) => theme.palette.primary.primaryPurple }} />}
							/>
						}
					></FormControlLabel>
				}
			>
				{Array.isArray(nodes?.child) ? nodes?.child.map((node) => renderTree(node)) : null}
			</TreeItem>
		</Box>
	);

	return (
		<RadioGroup>
			<TreeView
				defaultCollapseIcon={<IndeterminateCheckBoxOutlinedIcon sx={{ fontSize: "large" }} />}
				defaultExpandIcon={<AddBoxOutlinedIcon sx={{ fontSize: "large" }} />}
			>
				{renderTree(treeData)}
			</TreeView>
		</RadioGroup>
	);
}
