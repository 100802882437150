import * as actionTypes from "./actionTypes";

export const setSidebarWidth = (payload) => {
	return (dispatch) => {
		dispatch({
			type: actionTypes.SET_SIDEBAR_WIDTH,
			payload,
		});
	};
};
export const setSidebarOpenPref = (payload) => {
	return (dispatch) => {
		dispatch({
			type: actionTypes.SET_SIDEBAR_OPEN_PREF,
			payload,
		});
	};
};
