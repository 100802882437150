import React, { Fragment, useEffect, useState } from "react";
import { Box, Typography, Avatar } from "@mui/material";

import { SCOUTING_REPORT_PITCH_ONE, SCOUTING_REPORT_PITCH_TWO, SCOUTING_REPORT_PITCH_THREE, NEW_LINKEDIN_ICON, NEW_TWITTER_ICON } from "../../../../constants";
import FullProfileWidget from "../../../../components/ScoutingReport/FullProfileWidget";
import { useSelector } from "react-redux";
import { getFounderDetails } from "../../functions";
import { formatUrl } from "../../../../utils/commonFunctions";

const BKGGraphic = ({ children }) => (
	<Box
		sx={{
			position: "relative",
			backgroundColor: "#F5F9FA",
			overflow: "hidden",
		}}
	>
		<Box sx={{ zIndex: 1, position: "relative" }}>{children}</Box>

		<Box
			position="absolute"
			top="64px"
			right={"0"}
			sx={{
				zIndex: 0,
			}}
			component={"img"}
			src={SCOUTING_REPORT_PITCH_ONE}
			alt="MENTOR_BACKGROUND"
		/>

		<Box
			position="absolute"
			bottom="190px"
			left={"0"}
			sx={{
				zIndex: 0,
			}}
			component={"img"}
			src={SCOUTING_REPORT_PITCH_TWO}
			alt="MENTOR_BACKGROUND"
		/>

		<Box
			position="absolute"
			bottom="0px"
			left={"0"}
			sx={{
				zIndex: 0,
			}}
			component={"img"}
			src={SCOUTING_REPORT_PITCH_THREE}
			alt="MENTOR_BACKGROUND"
		/>
	</Box>
);

export default function FounderComment() {
	const STARTUP = useSelector((state) => state?.investorDashboard?.openStartupObj);

	const [founder_obj, setFounderObj] = useState(null);

	const getFounder = async () => {
		let founder = await getFounderDetails(STARTUP?.id);
		setFounderObj(founder);
	};

	useEffect(() => {
		if (STARTUP?.id) {
			getFounder();
		}
	}, []);

	const paddingX = {
		lg: 18,
		md: 9,
		xs: 3,
	};
	return (
		<BKGGraphic>
			<Box
				sx={{
					pl: paddingX,
					pb: {
						lg: 9,
						md: 6,
						xs: 3,
					},
					py: {
						lg: 10,
						md: 8,
						xs: 5,
					},
					pr: 0,
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Box
					sx={{
						borderRadius: "20px 0px 0px 20px",
						backgroundColor: "#fff",
						px: {
							lg: 7,
							md: 5,
							xs: 3,
						},
						display: "flex",
						gap: {
							xs: 2,
							md: 4,
							lg: 6,
						},
						justifyContent: "space-between",
						mt: "120px", // ~ 40% of the profile image circle.
					}}
				>
					<Box
						sx={{
							display: "flex",
							alignSelf: "center",
						}}
					>
						{STARTUP?.currentElevatorPitch ||
							(STARTUP?.tagLine && (
								<Fragment>
									<Box>
										<span
											style={{
												alignSelf: "flex-start",
												color: "black",
												fontFamily: "Times",
												fontSize: "100px",
												lineHeight: "0px",
												verticalAlign: "bottom",
											}}
										>
											&#8220;
										</span>
									</Box>
									<Typography
										variant="customValue"
										sx={{
											fontFamily: "PoppinsSemiBold",
											fontSize: "30px",
											lineHeight: "41px",
										}}
									>
										{STARTUP?.currentElevatorPitch || STARTUP?.tagLine}
										<span
											style={{
												color: "black",
												fontFamily: "Times",
												fontSize: "100px",
												lineHeight: "0px",
												verticalAlign: "bottom",
												marginLeft: "10px",
											}}
										>
											&#8221;
										</span>
									</Typography>
								</Fragment>
							))}
					</Box>

					<Box>
						<Avatar
							sx={{ height: "300px", width: "300px", border: "8px solid #fff", mt: "-120px" }}
							alt={founder_obj?.name}
							src={founder_obj?.picture}
						/>
						<Box
							display="flex"
							flexDirection="column"
							alignItems="center"
							sx={{
								py: {
									md: 4,
									xs: 2,
								},
							}}
						>
							<Typography
								variant="customValue"
								sx={{
									fontFamily: "PoppinsSemiBold",
									fontSize: "20px",
									lineHeight: "20px",
									marginBottom: "2px",
								}}
							>
								{founder_obj?.name}
							</Typography>
							{/* 
								// TODO -- We do not have title information for startup.
							*/}
							<Typography
								variant="customValue"
								sx={{
									fontFamily: "PoppinsSemiBold",
									fontSize: "16px",
									lineHeight: "24px",
									marginBottom: "8px",
								}}
							>
								Founder
							</Typography>
							<Box gap={1} display={"flex"} alignItems="center">
								{founder_obj?.linkedInUrl && (
									<Box
										component={"img"}
										src={NEW_LINKEDIN_ICON}
										alt="linkedin"
										sx={{ height: "35px", width: "35px", cursor: "pointer" }}
										onClick={() => window.open(formatUrl(founder_obj?.linkedInUrl), "_blank")}
									/>
								)}

								{/*
									// TODO -- We do not have twitter information.
									<Box component={"img"} src={NEW_TWITTER_ICON} alt="twitter" sx={{ height: "35px", width: "35px" }} />
								*/}
							</Box>
							<FullProfileWidget teammateData={founder_obj} />
						</Box>
					</Box>
				</Box>
				<Box
					sx={{
						display: "flex",
						justifyContent: "flex-end",
						pr: paddingX,
						my: {
							lg: 3,
							xs: 2,
						},
					}}
				>
					<Typography variant="card_title">{STARTUP?.currentElevatorPitch ? "Elevator Pitch" : STARTUP?.companyName}</Typography>
				</Box>
			</Box>
		</BKGGraphic>
	);
}
