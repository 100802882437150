import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { BACK_OFFICE_READINESS_BACKGROUND } from "../../../../constants";

const MOCK_DATA = [
	{
		id: 1,
		title: "Section Title",
		desc: "Founder Comment: Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ",
	},
	{
		id: 2,
		title: "Section Title",
		desc: "Founder Comment: Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ",
	},
	{
		id: 3,
		title: "Section Title",
		desc: "Founder Comment: Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ",
	},
];

export default function BackOfficeCard({ startup }) {
	return (
		<Box
			pt="80px"
			pb="100px"
			display="flex"
			alignItems="flex-end"
			sx={{
				backgroundImage: `url(${BACK_OFFICE_READINESS_BACKGROUND})`,
			}}
		>
			<Box
				p="76px 60px"
				mr="62px"
				width="70%"
				height="630px"
				sx={{
					background: "rgba(255, 255, 255, 0.9)",
					borderRadius: "0px 20px 20px 0px",
				}}
			>
				{MOCK_DATA.map(item => (
					<Box key={item.id} display="flex" flexDirection="column" mb="30px">
						<Typography variant="main_title">{item.title}</Typography>
						<Typography variant="card_text">{item.desc}</Typography>
					</Box>
				))}
			</Box>
			<Typography variant="card_title" color={"#fff"}>
				Business Model
			</Typography>
		</Box>
	);
}
