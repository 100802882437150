import React, { useEffect, useState } from 'react'

import { cloneDeep } from 'lodash'
import { uid } from 'uid/single'

import {
	Button,
	Stack,
	Typography,
	Divider,
	Input,
	InputAdornment,
	InputLabel,
	FormControl,
	FormControlLabel,
	Checkbox,
	Select,
	MenuItem,
	CircularProgress
} from '@mui/material'
import {
	Delete,
	CopyAll,
	Edit,
	Add, Done, Clear
} from '@mui/icons-material'

import Editor from '../Editor'
import InputGroup from './inputs'

function SectionLoader ({ loading, valid, invalid }) {
	return <Stack width={40} height={40} alignItems={'center'} justifyContent={'center'}>
		{loading ? <CircularProgress/> : valid ?
			<Done color={'success'}/> : invalid ? <Clear color={'warning'}/> : ''}
	</Stack>
}

export default function FormBuilder ({ sections: _sections, setSections, outputs, loading, powerUps }) {
	const sections = Array.isArray(_sections) && _sections.length ? _sections : [ {
		id: uid(),
		title: 'Section 1',
		description: '',
		visible: true,
		inputs: []
	} ]
	useEffect(() => {
		if (_sections !== sections) {
			setSections(sections)
		}
	}, [ sections ])

	const [ selected, setSelected ] = useState(0)
	const section = sections[selected]
	const [ title, setTitle ] = useState(section.title || '')
	useEffect(() => {
		setTitle(section.title || '')
	}, [ section.title ])

	const onPickSection = (selected) => {
		setSelected(selected)
	}

	const onAddSection = () => {
		setSections([ ...sections, {
			id: uid(),
			title: `Section ${sections.length + 1}`,
			description: '',
			visible: true,
			inputs: []
		} ])
		setSelected(sections.length)
	}

	const onDuplicateSection = () => {
		const newSection = cloneDeep(section)
		newSection.id = uid()
		setSections([ ...sections.slice(0, selected + 1), newSection, ...sections.slice(selected + 1) ])
		setSelected(selected + 1)
	}

	const onDeleteSection = () => {
		const secs = sections.filter((_, i) => i !== selected)
		if (secs.length === 1) {
			secs[0] = {
				...secs[0],
				visible: true
			}
		}
		setSections(secs)
		setSelected(secs.length > 0 && secs.length <= selected ? secs.length - 1 : selected)
	}

	const onSectionTitleChange = () => {
		if (section.title !== title) {
			const secs = [ ...sections ]
			secs[selected] = {
				...section,
				title
			}
			setSections(secs)
		}
	}

	const onSectionDescriptionChange = (description) => {
		const secs = [ ...sections ]
		secs[selected] = {
			...section,
			description
		}
		setSections(secs)
	}

	const onSectionVisibleChange = () => {
		const secs = [ ...sections ]
		secs[selected] = {
			...section,
			visible: !section.visible
		}
		setSections(secs)
	}

	const [ _, rerender ] = useState({})
	const onSectionAddInput = () => {
		sections[selected] = {
			...section,
			inputs: [ ...section.inputs, { id: uid() } ]
		}
		rerender({})
	}

	return <>
		<Stack gap={3}>
			<Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
				<Typography variant="h3">Form Builder</Typography>
				<Stack direction={'row'} gap={2} justifyContent={'end'} alignItems={'stretch'}>
					<SectionLoader loading={loading}/>
					<FormControl fullWidth sx={{ flex: '1 0 16rem' }} size={'small'}>
						<InputLabel id="section-select-label">Section</InputLabel>
						<Select
							labelId="section-select-label"
							id="section-select"
							label="Section"
							value={selected}
							onChange={(e) => onPickSection(e.target.value)}
						>{sections.map((section, i) => <MenuItem key={i} value={i}
																 selected={selected === i}>{section.title}</MenuItem>)}</Select>
					</FormControl>
					<Button endIcon={<Add/>}
							sx={{
								whiteSpace: 'nowrap',
								maxWidth: 'unset',
								minWidth: 'unset',
								height: 'unset',
								margin: '0 0 5px 1rem !important'
							}}
							onClick={onAddSection}
					>Add Section</Button>
				</Stack>
			</Stack>
			<Divider/>
			{section && <>
				<Stack gap={3}>
					<Stack direction="row" gap={2} alignItems="center" justifyContent={'space-between'}>
						<Stack direction={'row'} gap={2}>
							<Input
								sx={theme => ({ fontFamily: theme.typography.h3, fontSize: theme.typography.h3 })}
								endAdornment={
									<InputAdornment position={'end'}>
										<Edit/>
									</InputAdornment>
								}
								value={title}
								onChange={(e) => setTitle(e.target.value)}
								onBlur={onSectionTitleChange}
							/>
							<FormControlLabel
								sx={{ whiteSpace: 'nowrap' }}
								label="Visible by default"
								control={<Checkbox checked={selected === 0 || section.visible}
												   disabled={selected === 0 || (section.visible && sections.reduce((c, s) => c + (s.visible ? 1 : 0), 0) <= 1)}
												   onChange={onSectionVisibleChange}/>}
							/>
						</Stack>
						<Stack direction="row" gap={2} alignItems={'center'}>
							<Button sx={{ whiteSpace: 'nowrap' }}
									variant="outlined"
									startIcon={<CopyAll/>}
									onClick={onDuplicateSection}
							>Duplicate</Button>
							<Button startIcon={<Delete/>}
									disabled={sections.length <= 1}
									onClick={onDeleteSection}
							>Delete</Button>
						</Stack>
					</Stack>
					<Stack gap={1}>
						<Typography variant="h5">Section Description</Typography>
						<Editor
							label={'About…'}
							defaultValue={section.description}
							onSave={onSectionDescriptionChange}
							getMediaUrl={(file) => powerUps.uploadMedia({ file })}
						/>
					</Stack>
				</Stack>

				{section.inputs?.map((input, i, inputs) => <InputGroup
					key={input.id}
					section={section}
					sections={sections}
					outputs={outputs}
					total={inputs.length}
					order={i}
					powerUps={powerUps}
					setOrder={(order) => {
						const inps = [ ...inputs ]
						const prevInput = inps.splice(i, 1)[0]
						inps.splice(order, 0, prevInput)
						const secs = [ ...sections ]
						secs[selected] = {
							...section,
							inputs: inps
						}
						setSections(secs)
					}}
					state={input}
					setState={(state) => {
						const inps = [ ...inputs ]
						inps[i] = state
						const secs = [ ...sections ]
						secs[selected] = {
							...section,
							inputs: inps
						}
						setSections(secs)
					}}
					onDuplicate={() => {
						const newInput = cloneDeep(input)
						newInput.id = uid()
						const inps = [ ...inputs ]
						inps.splice(i + 1, 0, newInput)
						const secs = [ ...sections ]
						secs[selected] = {
							...section,
							inputs: inps
						}
						setSections(secs)
					}}
					onDelete={() => {
						const inps = [ ...inputs ]
						inps.splice(i, 1)
						const secs = [ ...sections ]
						secs[selected] = {
							...section,
							inputs: inps
						}
						setSections(secs)
					}}/>)}

				<Stack p={3} sx={theme => ({
					border: `dotted 2px ${theme.palette.containerBorder.lightOutline}`,
					borderRadius: '4px'
				})}>
					<Button endIcon={<Add/>} onClick={onSectionAddInput}>Add Input</Button>
				</Stack>
			</>}
		</Stack>
	</>
}
