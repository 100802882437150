import React, { useEffect, useState, useRef, useCallback, memo } from "react";
import BIDataGrid from "./BIDataGrid";
import { get, cloneDeep } from "lodash";
import { TextField, InputAdornment, Box, useMediaQuery, Typography, IconButton } from "@mui/material";
import moment from "moment";
import {
	convertNumberToLocale,
	getStartAndEndDate,
	getRequestPayloadFromRows,
	updateBurnRunwayGridData,
	partners,
	getBurnRunwayGridData,
	getBurnMultipeGridData,
	calculateBurnMultipleUpdateData,
	updateBurnMultipleGridData,
	getMomGrowth,
	getNetArrGridData,
} from "../Functions/common";
import { useGridApiContext } from "@mui/x-data-grid";
import { CALCULATOR_V1_ICON } from "../../../../constants";
import { handleMixPanel } from "../../../../utils/mixPanelEventHandling";
import SaveData from "./SaveData";
import { useSelector, useDispatch } from "react-redux";
import { setBIDashboardData } from "../../../../modules/actions/biDashboardActions";
import { toastContainer } from "../../../../utils/toast";
import LeaveConfirm from "./LeaveConfirm";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation } from "react-router-dom";

const disabledRowIds = [1, 2];

function BurnRunDataGrid({ title = "Burn & Runway", gridData, readOnly, usedCompanyId, isEdited, setEdited }) {
	const dispatch = useDispatch();
	const rowName = {
		cashBalance: { id: 0, name: "Cash Balance" },
		burn: { id: 1, name: "Burn" },
		runway: { id: 2, name: "Runway" },
	};

	const [rows, setRows] = useState([]);
	const [columns, setColumns] = useState([]);
	const [saving, setSaving] = useState(false);
	const openBurnAndRunway = useSelector((store) => store?.biDashboard?.openBurnAndRunway);
	const openBurnAndRunwayLeave = useSelector((store) => store?.biDashboard?.openBurnAndRunwayLeave);
	const nextEditTableName = useSelector((store) => store?.biDashboard?.nextEditTableName);
	const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
	const openEdit = useSelector((store) => store?.biDashboard?.openEdit);

	const partnerObj = Array.isArray(gridData) && gridData?.sort((a, b) => b.lastModifiedAt - a.lastModifiedAt)?.[0];
	const displayName = partners.filter((item) => item?.title === partnerObj?.partnerType)?.[0]?.displayName;
	const picture = partners.filter((item) => item?.title === partnerObj?.partnerType)?.[0]?.picture;
	const location = useLocation();
	const startupDetails = location?.state?.startupDetails;
	const { isAdmin } = useSelector((state) => state?.auth);

	function PartnerDesign() {
		return (
			<Box display="flex" alignItems="flex-start" flexDirection={"column"} gap={"4px"}>
				<Box component={"img"} sx={{ width: "70px" }} src={picture} alt="partner" />
				<Typography component={"p"} sx={{ textAlign: "left" }} variant="Text/sm/Medium" color="sos.gray600">
					{`Connected to ${displayName ? displayName : ""} `}
				</Typography>
				<Typography variant="Text/xs/Regular" color="sos.gray600">
					{`${moment(partnerObj?.lastModifiedAt)?.format("MMMM D, YYYY h:mm A")} EST`}{" "}
				</Typography>
			</Box>
		);
	}

	const valueFormatterForCategory = (params) => {
		if (!params.value) {
			return "";
		}
		if (rowName[params.value]) {
			return rowName[params.value].name;
		}
		return params.value;
	};

	const valueFormatterForMonths = (params) => {
		const { id, value, field } = params;

		if (get(rows, `${id}.${field}`, null) === null) {
			if (disabledRowIds.includes(id)) {
				return (
					<>
						<Box component="img" src={CALCULATOR_V1_ICON} sx={{ width: "14px", height: "14px" }} />-
					</>
				);
			}
			return "";
		}

		if (id === 2) {
			return `${convertNumberToLocale(value)}`;
		}

		return `$${convertNumberToLocale(value)}`;
	};

	function calculateFieldsValues(id, value, field) {
		try {
			const tempRows = [...rows];
			const previousMonth = moment(field).subtract(1, "months").format("YYYY-MM-DD");
			const nextMonth = moment(field).add(1, "months").format("YYYY-MM-DD");

			//current column
			const currentMonthBurn =
				tempRows?.[id]?.[previousMonth] && value && value - tempRows?.[id]?.[previousMonth] < 0 ? value - tempRows?.[id]?.[previousMonth] : null;
			//next column
			const nextMonthBurn = tempRows?.[id]?.[nextMonth] && value && tempRows?.[id]?.[nextMonth] - value < 0 ? tempRows?.[id]?.[nextMonth] - value : null;

			//current column
			tempRows[id][field] = value;
			tempRows[1][field] = currentMonthBurn !== null ? parseFloat(Number(currentMonthBurn).toFixed(2)) : null;

			//next column
			tempRows[1][nextMonth] = nextMonthBurn !== null ? parseFloat(Number(nextMonthBurn).toFixed(2)) : null;

			//runway
			const currentMonthsRunway = tempRows?.[1]?.[field] && value !== null ? (value / tempRows?.[1]?.[field]) * -1 : null;
			const nextMonthRunWay =
				tempRows?.[id]?.[nextMonth] && tempRows?.[1]?.[nextMonth] ? (tempRows?.[id]?.[nextMonth] / tempRows?.[1]?.[nextMonth]) * -1 : null;
			tempRows[2][field] = currentMonthsRunway !== null ? parseFloat(Number(currentMonthsRunway).toFixed(2)) : null;
			tempRows[2][nextMonth] = nextMonthRunWay !== null ? parseFloat(Number(nextMonthRunWay).toFixed(2)) : null;

			setRows(tempRows);
		} catch (error) {
			console.log(error, "error");
		}
	}

	function EditInputCell(props) {
		const { id, value: valueProp, field } = props;
		const [value, setValue] = useState(valueProp);
		const apiRef = useGridApiContext();
		const textFieldRef = useRef(null);

		const handleChange = (event) => {
			let newValue = event.target.value; // The new value entered by the user
			newValue = newValue !== null && newValue !== "" ? Math.abs(Number(newValue).toFixed(2)) : null;
			apiRef.current.setEditCellValue({
				id,
				field,
				value: newValue,
				debounceMs: 200,
			});
			setValue(newValue);
			calculateFieldsValues(id, newValue, field);
			if (!isEdited && setEdited) {
				setEdited(true);
			}
		};

		const inputRef = useCallback((node) => {
			if (node) {
				textFieldRef.current.focus();
			}
		}, []);

		useEffect(() => {
			// setValue(valueProp);
			setValue(get(rows, `${id}.${field}`, null));
		}, [valueProp]);

		return (
			<TextField
				type="number"
				value={value !== null ? value : ""}
				onChange={handleChange}
				ref={inputRef}
				inputRef={textFieldRef}
				fullWidth
				InputProps={{
					startAdornment: <InputAdornment position="start">$</InputAdornment>,
				}}
			/>
		);
	}

	const renderEditCell = (params) => {
		return <EditInputCell {...params} />;
	};

	const renderCell = (params) => {
		const { row } = params;
		let value = params.value;
		if (params.colDef.valueFormatter) {
			value = params.colDef.valueFormatter(params);
		}
		if (value === null) {
			if (disabledRowIds.includes(row.id)) {
				return (
					<>
						<Box component="img" src={CALCULATOR_V1_ICON} sx={{ width: "14px", height: "14px" }} />-
					</>
				);
			}
			return value;
		}
		return value;
	};

	const transformGridData = () => {
		const tempColumns = [
			{
				field: "name",
				headerName: "Date",
				width: 200,
				editable: false,
				sortable: false,
			},
		];

		let dates = [];

		if (gridData?.length > 0) {
			dates = [...new Set(gridData.map((item) => item.date))];
		}
		const { startDate, endDate } = getStartAndEndDate(dates);

		const emptyDates = {};
		let currentDate = moment(startDate);
		while (currentDate <= endDate) {
			emptyDates[moment(currentDate).format("YYYY-MM-DD")] = null;
			tempColumns.push({
				field: moment(currentDate).format("YYYY-MM-DD"),
				headerName: moment(currentDate).format("MMM yyyy"),
				type: "number",
				editable: readOnly ? false : true,
				sortable: false,
				align: "center",
				headerAlign: "center",
				minWidth: 120,
			});
			currentDate = moment(currentDate).add(1, "month");
		}

		// Step 2: Create a new array of objects, one for each unique combination of id and name
		let transformedObj = {};

		for (const key in rowName) {
			if (!transformedObj[key]) {
				transformedObj[key] = { id: rowName[key] ? rowName[key].id : Object.keys(transformedObj).length + 1, name: key, ...emptyDates };
			}
		}

		//step3: loop through data and fill data
		for (const dataItem of gridData) {
			const currentData = dataItem["date"];
			for (const key in rowName) {
				transformedObj[key][currentData] = dataItem[key];
			}
		}

		setColumns(tempColumns);
		setRows(cloneDeep(Object.values(transformedObj)));
	};

	useEffect(() => {
		try {
			transformGridData();
		} catch (error) {
			console.log(error, "error");
		}
	}, [gridData, openBurnAndRunway]);

	const handleSave = async () => {
		try {
			setSaving(true);
			const transformedData = getRequestPayloadFromRows(rows, gridData);

			const response = await updateBurnRunwayGridData(transformedData);
			const burnAndRunwayResponse = get(response, "data", []);

			// -----------------

			const burnMultipleOldDataRes = await getBurnMultipeGridData(usedCompanyId);
			const burnMultipleOldData = get(burnMultipleOldDataRes, "data", []);
			const netNewArrDataResponse = await getNetArrGridData(usedCompanyId);
			const netNewArrData = get(netNewArrDataResponse, "data", []);
			const updatedBurnMultipleData = calculateBurnMultipleUpdateData(burnMultipleOldData, burnAndRunwayResponse, netNewArrData);
			const burnMultiplDataRes = await updateBurnMultipleGridData(updatedBurnMultipleData);

			const updateBurnMultiplDataRes = get(burnMultiplDataRes, "data", []);
			const momGrowthBurnMultiple = getMomGrowth(updateBurnMultiplDataRes, "burnMultiple");

			// -----------------

			dispatch(
				setBIDashboardData({
					burnMultiple: {
						momGrowth: momGrowthBurnMultiple !== null ? parseFloat(Number(momGrowthBurnMultiple).toFixed(2)) : null,
						latestBurnMultiple: updateBurnMultiplDataRes?.[updateBurnMultiplDataRes?.length - 1]?.burnMultiple,
						data: updateBurnMultiplDataRes,
					},
					burnAndRunway: {
						latestBurn: burnAndRunwayResponse?.[burnAndRunwayResponse?.length - 1]?.burn,
						latestCashBalance: burnAndRunwayResponse?.[burnAndRunwayResponse?.length - 1]?.cashBalance,
						latestRunway: burnAndRunwayResponse?.[burnAndRunwayResponse?.length - 1]?.runway,
						data: burnAndRunwayResponse,
					},
				})
			);

			handleMixPanel("Grid Burn Edited");

			toastContainer("Data Saved", "Success");
			if (isEdited && setEdited) {
				setEdited(false);
			}
			dispatch(
				setBIDashboardData({
					openBurnAndRunway: false,
					openBurnAndRunwayLeave: false,
				})
			);
		} catch (error) {
			console.log(error, "error");
			toastContainer(error?.response?.data?.message || "Something is wrong!", "error");
			throw error;
		} finally {
			setSaving(false);
		}
	};

	const onCloseIconClick = () => {
		if (isEdited) {
			dispatch(
				setBIDashboardData({
					openBurnAndRunwayLeave: true,
				})
			);
		} else {
			dispatch(
				setBIDashboardData({
					...(openBurnAndRunway && { openBurnAndRunway: false }),
					...(openEdit && { openEdit: false }),
				})
			);
		}
	};

	const onNoClick = () => {
		if (isEdited && setEdited) {
			setEdited(false);
		}
		if (nextEditTableName && openEdit) {
			dispatch(
				setBIDashboardData({
					openBurnAndRunwayLeave: false,
					editTableName: nextEditTableName,
					nextEditTableName: "",
				})
			);
		} else {
			dispatch(
				setBIDashboardData({
					openBurnAndRunway: false,
					openBurnAndRunwayLeave: false,
					openEdit: false,
					nextEditTableName: "",
				})
			);
		}
	};

	const onYesClick = async () => {
		try {
			await handleSave();
			onNoClick();
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<>
			<Box display="flex" justifyContent="space-between" alignItems="center" pb={2}>
				{partnerObj?.partnerType ? <PartnerDesign /> : <Typography variant="Text/md/Semibold">{title}</Typography>}
				{!(isSmallScreen && openEdit) && (
					<IconButton aria-label="close" onClick={onCloseIconClick} sx={{ color: (theme) => theme.palette.secondary.dark }}>
						<CloseIcon sx={{ color: (theme) => theme.palette.secondary.black }} />
					</IconButton>
				)}
			</Box>
			<BIDataGrid
				rows={rows}
				columns={columns.map((columns, index) => ({
					...columns,
					renderCell: index == 0 ? valueFormatterForCategory : valueFormatterForMonths,
					renderEditCell: renderEditCell,
				}))}
				disabledRowIds={disabledRowIds}
				readOnly={readOnly}
			/>
			<SaveData isEdited={isEdited} saving={saving} readOnly={readOnly} onCancelClick={onCloseIconClick} onSaveClick={handleSave} />
			<LeaveConfirm
				saving={saving}
				isOpen={openBurnAndRunwayLeave}
				onClose={() => {
					dispatch(setBIDashboardData({ openBurnAndRunwayLeave: false, nextEditTableName: "" }));
				}}
				onYesClick={onYesClick}
				onNoClick={onNoClick}
			/>
		</>
	);
}

export default memo(BurnRunDataGrid);
