import { Avatar, Skeleton } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { ProfileContext } from "../../..";
import AvatarWithFallBack from "../../../../../common/AvatarWithFallBack";
import { NEW_PROFILE_LOGO, NEW_STARTUP_LOGO } from "../../../../../constants";

function ProfileAvatar() {
	const { viewContext, profileData, profileDataLoading } = useContext(ProfileContext);
	const [loading, setloading] = useState(profileDataLoading);
	const [imageUrl, setImageUrl] = useState(null);

	useEffect(() => {
		setloading(profileDataLoading);
	}, [profileDataLoading]);

	useEffect(() => {
		if (viewContext?.component === "startup") {
			setImageUrl(profileData?.logoUrl);
		} else if (viewContext?.component === "account") {
			setImageUrl(profileData?.picture);
		}
	}, [profileData]);

	if (loading) {
		return (
			<Skeleton
				animation={"wave"}
				variant="circular"
				sx={{
					height: "120px",
					width: "120px",
				}}
			/>
		);
	} else {
		return (
			<AvatarWithFallBack
				src={imageUrl}
				sx={{
					height: "120px",
					width: "120px",
				}}
			/>
		);
	}
}

export default ProfileAvatar;
