import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { PageWrapper, HeaderArea, MainArea } from "./styles";
import AppHeader from "../../components/AppHeader/index";
import { Box, Grid } from "@mui/material";
import { useHistory } from "react-router-dom";
import ROUTES_PATH from "../../constants/routes";
import { USER_TYPES } from "../../utils/userTypes";
import jwt_decode from "jwt-decode";

export default function DefaultLayout({ children }) {
	const history = useHistory();

	const onBackHandler = () => {
		const pathname = window.location.pathname;
		if (pathname.split("/")[1] === "account-not-found") {
			history.push(ROUTES_PATH.LOG_IN);
		} else if (pathname.split("/")[1] === "login") {
			window.location.replace("https://startupos.com");
		} else {
			pathname.split("/")[1] === "" ? (window.location.href = process.env.REACT_APP_HOME_URL) : history.goBack();
		}
	};

	const hideBack = () => {
		if ([ROUTES_PATH.EMAIL_VERIFICATION, ROUTES_PATH.INVALID_INVITE].includes(window.location.pathname)) {
			return true;
		} else {
			if (ROUTES_PATH.SIGN_UP === window.location.pathname) {
				const queryParams = new URLSearchParams(window.location.search);
				const token = queryParams.get("token");
				if (token) {
					var decodedValue = jwt_decode(token);
					return [USER_TYPES.ADVISOR_USER, USER_TYPES.MENTOR_USER, USER_TYPES.STARTUP_USER, USER_TYPES.CANDIDATE_USER].includes(decodedValue.type);
				} else {
					return false;
				}
			} else {
				return false;
			}
		}
	};

	useEffect(() => {
		if (hideBack()) {
			//hidding back button if to prevent user navigate to back.

			window.history.pushState(null, document.title, window.location.href);

			window.addEventListener("popstate", function (event) {
				window.history.pushState(null, document.title, window.location.href);
			});

			return () => window.addEventListener("popstate", () => {});
		}
	}, []);

	return (
		<Box sx={{ position: "relative" }}>
			<PageWrapper>
				<HeaderArea>
					<AppHeader hideBack={hideBack()} onBack={onBackHandler} />
				</HeaderArea>
				<Grid container justifyContent={"center"} alignItems="center">
					<Grid item xs={12} sm={10} md={10} lg={8}>
						<MainArea>{children}</MainArea>
					</Grid>
				</Grid>
			</PageWrapper>
		</Box>
	);
}
DefaultLayout.propTypes = {
	children: PropTypes.element.isRequired,
};
