import React from "react";
import { Grid, Stack, Box, Typography, Avatar, Link } from "@mui/material";
import { Person } from "@mui/icons-material";
import theme from "../../../constants/Theme";

export default function CommentCard({ item }) {
  return (
    <>
      <Box
        sx={{
          py: 2,
          pl: 2,
          border: "1px solid rgba(124, 117, 127, 0.16)",
          borderRadius: 8,
          mt: 2,
        }}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 2,
              alignItems: "center",
              pb: 1,
            }}
          >
            <Avatar
              alt={"Picture"}
              sx={{ backgroundColor: "#DADCE0", color: "#9AA0A6" }}
              src={item?.userModel?.picture}
            >
              <Person />
            </Avatar>

            <Box>
              <Typography
                variant="title_medium"
                sx={{ wordBreak: "break-all", pr: 1 }}
              >
                {item?.userModel?.name
                  ? item?.userModel?.name
                  : item?.userModel?.email}
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 1,
                  alignItems: "center",
                }}
              >
                <Typography variant="onsurface_grey_medium">
                  {item?.title ? item?.title : "-"}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={12} sx={{ pr: 1 }}>
            <Typography
              variant="body_large"
              sx={{ color: theme.palette.secondary.main }}
            >
              {item?.text}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
