import React, { useState, useEffect } from "react";
import { Typography, Box, TextField, Button, Checkbox, CircularProgress } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { MentorExperienceWrapper } from "../styles";
import CustomizedMonthPicker from "../MonthPicker";
import CustomizedYearPicker from "../YearPicker";
import { DELETE_ICON } from "../../../constants";
import { REGEX } from "../../../constants/regex";
import messages from "../../../constants/messages";
import services from "../../../services";
import StartupOsTextField from "../../../common/StartupOsInput/StartupOsTextField";
import { toastContainer } from "../../../utils/toast";

const MentorExperience = ({ setShowExperience, isExperienceEdit, experienceDetails, setExpLoader, handleUserDetails = () => {} }) => {
	const [title, setTitle] = useState({ value: "", error: "" });
	const [companyName, setCompanyName] = useState({ value: "", error: "" });
	const [location, setLocation] = useState({ value: "", error: "" });
	const [startMonth, setStartMonth] = useState({ value: null, error: "" });
	const [endMonth, setEndMonth] = useState({ value: null, error: "" });
	const [startYear, setStartYear] = useState({ value: null, error: "" });
	const [endYear, setEndYear] = useState({ value: null, error: "" });
	const [checked, setChecked] = useState(false);
	const [disabled, setDisabled] = useState(false);
	const [loader, setLoader] = useState(false);

	useEffect(() => {
		if (isExperienceEdit) {
			setTitle({ value: experienceDetails.title });
			setCompanyName({ value: experienceDetails.companyName });
			setLocation({ value: experienceDetails.location });
			setStartYear({ value: new Date(experienceDetails.startYear, 0, 32) });
			setEndYear({ value: new Date(experienceDetails.endYear, 0, 32) });

			let startingMonth = new Date(`${experienceDetails.startYear}/${experienceDetails.startMonth}/1`);
			let endingMonth = experienceDetails.endMonth ? new Date(`${experienceDetails.endYear}/${experienceDetails.endMonth}/1`) : null;

			setStartMonth({ value: startingMonth });
			setEndMonth({ value: endingMonth });
			if (experienceDetails.endYear) {
				setChecked(false);
			} else {
				setChecked(true);
				setDisabled(true);
			}
		}
	}, []);

	const handleChecked = (event) => {
		if (!disabled) {
			setDisabled(true);
		} else {
			setDisabled(false);
		}
		setChecked(event.target.checked);
		setEndMonth({ value: null });
		setEndYear({ value: null });
	};

	const handleBlur = (e, type) => {
		let eventValue = e.target.value;

		switch (type) {
			case "title":
				if (!eventValue.match(REGEX.LOCATION)) {
					setTitle((prevState) => ({
						...prevState,
						error: messages.TITLE_LENGTH_INVALID,
						value: eventValue,
					}));
				} else {
					setTitle((prevState) => ({
						...prevState,
						error: "",
						value: eventValue,
					}));
				}
				break;
			case "companyName":
				if (!eventValue.match(REGEX.COMPANY_NAME)) {
					setCompanyName((prevState) => ({
						...prevState,
						error: messages.COMPANY_NAME_LENGTH_INVALID,
						value: eventValue,
					}));
				} else {
					setCompanyName((prevState) => ({
						...prevState,
						value: eventValue,
						error: "",
					}));
				}
				break;
			case "location":
				if (!eventValue.match(REGEX.LOCATION)) {
					setLocation((prevState) => ({
						...prevState,
						error: messages.LOCATION_LENGTH_INVALID,
						value: eventValue,
					}));
				} else {
					setLocation((prevState) => ({
						...prevState,
						value: eventValue,
						error: "",
					}));
				}
				break;
		}
	};
	const handleOnChange = (e, type) => {
		let eventValue = e.target.value;
		switch (type) {
			case "title":
				setTitle((prevState) => ({
					...prevState,
					error: "",
					value: eventValue,
				}));

				break;
			case "companyName":
				setCompanyName((prevState) => ({
					...prevState,
					value: eventValue,
					error: "",
				}));

				break;
			case "location":
				setLocation((prevState) => ({
					...prevState,
					value: eventValue,
					error: "",
				}));

				break;
		}
	};

	const clearAllErrors = () => {
		setStartYear((prevState) => ({
			...prevState,
			error: "",
		}));
		setEndYear((prevState) => ({
			...prevState,
			error: "",
		}));
		setStartMonth((prevState) => ({
			...prevState,
			error: "",
		}));
		setEndMonth((prevState) => ({
			...prevState,
			error: "",
		}));
	};

	const handleYearChange = (value, type) => {
		if (type === "startYear") {
			if (value.getFullYear() > endYear?.value?.getFullYear()) {
				setStartYear({
					error: messages.START_DATE_ERROR,
					value: value,
				});
				setStartMonth((prevState) => ({
					...prevState,
					error: messages.START_DATE_ERROR,
				}));
			} else if (value.getFullYear() === endYear?.value?.getFullYear()) {
				handleMonthChange(startMonth.value, "startMonth");
				setStartYear((prevState) => ({
					...prevState,
					value: value,
				}));
			} else {
				setStartYear((prevState) => ({
					...prevState,
					value: value,
				}));
				clearAllErrors();
			}
		} else {
			if (value.getFullYear() < startYear?.value?.getFullYear()) {
				setEndYear({
					error: messages.END_DATE_ERROR,
					value: value,
				});
				setEndMonth((prevState) => ({
					...prevState,
					error: messages.END_DATE_ERROR,
				}));
			} else if (value.getFullYear() === endYear?.value?.getFullYear()) {
				handleMonthChange(endMonth.value, "endMonth");
				setEndYear((prevState) => ({
					...prevState,
					value: value,
				}));
			} else {
				setEndYear((prevState) => ({
					...prevState,
					value: value,
				}));
				clearAllErrors();
			}
		}
	};
	const handleMonthChange = (value, type) => {
		if (type === "startMonth") {
			if (value.getMonth() > endMonth?.value?.getMonth() && startYear?.value?.getFullYear() === endYear?.value?.getFullYear()) {
				setStartMonth({
					error: messages.START_DATE_ERROR,
					value: value,
				});
				setStartYear((prevState) => ({
					...prevState,
					error: messages.START_DATE_ERROR,
				}));
			} else {
				setStartMonth((prevState) => ({
					...prevState,
					value: value,
					error: "",
				}));
			}
		} else {
			if (value.getMonth() < startMonth?.value?.getMonth() && startYear?.value?.getFullYear() === endYear?.value?.getFullYear()) {
				setEndMonth({
					error: messages.END_DATE_ERROR,
					value: value,
				});
				setEndYear((prevState) => ({
					...prevState,
					error: messages.END_DATE_ERROR,
				}));
			} else {
				setEndMonth((prevState) => ({
					...prevState,
					value: value,
					error: "",
				}));
			}
		}
	};

	const getButtonDisableStatus = () => {
		if (title.error || companyName.error || location.error || startYear.error || endYear.error) {
			return true;
		} else {
			return false;
		}
	};

	const addExperienceApiCall = (payload) => {
		return services
			.addMentorExperience(payload)
			.then((result) => {
				if (result.data.code === 200) {
					handleUserDetails();
					setShowExperience(false);
					setLoader(false);
					return true;
				}
			})
			.catch((error) => {
				throw error;
				setLoader(false);
			});
	};
	const updateExperienceApiCall = (payload) => {
		return services
			.updateMentorExperience(payload, experienceDetails.id)
			.then((result) => {
				if (result.data.code === 200) {
					handleUserDetails();
					setShowExperience(false);
					setLoader(false);
					return true;
				}
			})
			.catch((error) => {
				throw error;
				setLoader(false);
			});
	};

	const deleteExperienceApiCall = () => {
		let id = 1;
		setExpLoader(true);
		return services
			.deleteMentorExperience(experienceDetails.id)
			.then((result) => {
				if (result.data.code === 200) {
					handleUserDetails();
					setShowExperience(false);
					toastContainer("Experience deleted successfully");
					setExpLoader(false);
					return true;
				}
			})
			.catch((error) => {
				setExpLoader(false);
				throw error;
			});
	};
	const handleSave = () => {
		setLoader(true);
		if (!checked) {
			let payload = {
				title: title.value,
				companyName: companyName.value,
				location: location.value,
				startMonth: startMonth.value.getMonth() + 1,
				startYear: startYear.value.getFullYear(),
				endMonth: endMonth.value.getMonth() + 1,
				endYear: endYear.value.getFullYear(),
			};

			if (isExperienceEdit) {
				updateExperienceApiCall(payload);
			} else {
				addExperienceApiCall(payload);
			}
		} else {
			let payload = {
				title: title.value,
				companyName: companyName.value,
				location: location.value,
				startMonth: startMonth.value.getMonth() + 1,
				startYear: startYear.value.getFullYear(),
				endMonth: null,
				endYear: null,
			};

			if (isExperienceEdit) {
				updateExperienceApiCall(payload);
			} else {
				addExperienceApiCall(payload);
			}
		}
	};
	return (
		<MentorExperienceWrapper>
			<Box display="flex" alignItems={"center"}>
				<Typography variant="title_medium">{isExperienceEdit ? "Edit Experience" : "New Experience"}</Typography>
				<CloseIcon fontSize="small" sx={{ ml: "auto", cursor: "pointer" }} onClick={() => setShowExperience(false)} />
			</Box>
			<Box sx={{ mt: 3 }}>
				<StartupOsTextField
					spellCheck="true"
					label="Title"
					sx={{ width: 1 }}
					error={title.error}
					value={title.value}
					onBlur={(e) => {
						handleBlur(e, "title");
					}}
					onChange={(e) => {
						handleOnChange(e, "title");
					}}
					helperText={title.error}
				/>
				<StartupOsTextField
					label="Company Name"
					spellCheck="true"
					sx={{ width: 1, mt: 5 }}
					error={companyName.error}
					value={companyName.value}
					onBlur={(e) => {
						handleBlur(e, "companyName");
					}}
					onChange={(e) => {
						handleOnChange(e, "companyName");
					}}
					helperText={companyName.error}
				/>
				<StartupOsTextField
					label="Location"
					spellCheck="true"
					sx={{ width: 1, mt: 5 }}
					error={location.error}
					value={location.value}
					onBlur={(e) => {
						handleBlur(e, "location");
					}}
					onChange={(e) => {
						handleOnChange(e, "location");
					}}
					helperText={location.error}
				/>
				<Typography variant="title_small" sx={{ mt: 5, display: "block" }}>
					Start Date
				</Typography>
				<Box display="flex" sx={{ mt: 1 }}>
					<Box>
						<CustomizedMonthPicker
							value={startMonth.value}
							label="Month"
							onChange={(newValue) => {
								handleMonthChange(newValue, "startMonth");
							}}
							error={startMonth.error}
						/>
					</Box>
					<Box sx={{ ml: 1 }}>
						<CustomizedYearPicker
							value={startYear.value}
							label="Year"
							error={startYear.error}
							onChange={(newValue) => {
								handleYearChange(newValue, "startYear");
							}}
							showErrorMsg={false}
						/>
					</Box>
				</Box>
				<Box>
					<Typography variant="caption" sx={{ color: "secondary.danger" }}>
						{startYear.error}
					</Typography>
				</Box>
				<Typography variant="title_small" sx={{ mt: 5, display: "block" }}>
					End Date
				</Typography>
				<Box display="flex" sx={{ mt: 1 }}>
					<Box>
						<CustomizedMonthPicker
							value={endMonth.value}
							label="Month"
							onChange={(newValue) => {
								handleMonthChange(newValue, "endMonth");
							}}
							disabled={disabled}
							error={endMonth.error}
						/>
					</Box>

					<Box sx={{ ml: 1 }}>
						<CustomizedYearPicker
							value={endYear.value}
							label="Year"
							disabled={disabled}
							onChange={(newValue) => {
								handleYearChange(newValue, "endYear");
							}}
							error={endYear.error}
							showErrorMsg={false}
						/>
					</Box>
				</Box>

				<Box>
					<Typography variant="caption" sx={{ color: "secondary.danger" }}>
						{endYear.error}
					</Typography>
				</Box>
				<Box sx={{ mt: 2, display: "flex" }}>
					<Checkbox checked={checked} onChange={handleChecked} sx={{ padding: "0" }} size="small" />
					<Typography variant="title_small" sx={{ ml: 1, display: "block" }}>
						I am currently working in this role
					</Typography>
				</Box>

				<Box display="flex" sx={{ mt: 4 }}>
					{experienceDetails?.id && (
						<Box component="img" sx={{ cursor: "pointer" }} src={DELETE_ICON} alt="delete-icon" onClick={() => deleteExperienceApiCall()} />
					)}
					<Box sx={{ ml: "auto" }}>
						{loader ? (
							<CircularProgress
								sx={{
									display: "flex",
									justifyContent: "center",
									maxWidth: "30px",
									px: 3,
								}}
							/>
						) : (
							<Button variant="text" sx={{ maxWidth: "fit-content" }} onClick={() => handleSave()} disabled={getButtonDisableStatus()}>
								Save
							</Button>
						)}
					</Box>
				</Box>
			</Box>
		</MentorExperienceWrapper>
	);
};

export default MentorExperience;
