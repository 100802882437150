import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { STARTUP_PROFILE_BACKGROUND_2 } from "../../../../constants";

export const BoxBackground = styled(Box)(({ theme }) => ({
	backgroundImage: `url(${STARTUP_PROFILE_BACKGROUND_2})`,
	display: "flex",
	justifyContent: "center",
}));

export const BoxProfileBackground = styled(Box)(({ theme }) => ({
	width: "70%",
	height: "600px",
	backgroundColor: "rgba(255, 255, 255, 0.95)",
	opacity: 0.95,
	borderRadius: "0px 20px 20px 0px",
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "flex-end",
}));

export const BoxProfileContent = styled(Box)(({ theme }) => ({
	height: "500px",
	width: "93.6632%",
}));
