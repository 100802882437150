import React, { useContext, useEffect, useState, createRef } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

import { isEqual } from "lodash";

import PowerUps from "../../stores/powerups";

import {
	Breadcrumbs,
	Link,
	Button,
	Stack,
	Typography,
	Divider,
	Grid,
	Paper,
	Card,
	Box,
	Tabs,
	Tab,
	Rating,
	TableContainer,
	Table,
	TableHead,
	TableRow,
	TableBody,
	TableCell,
	Select,
	Menu,
	MenuItem,
	FormControl,
	TextField,
	IconButton,
	Input,
	InputAdornment,
	Tooltip,
	ListItemIcon,
	ListItemText,
} from "@mui/material";
import {
	Edit,
	Visibility,
	NavigateNext,
	Whatshot,
	Bolt,
	Toc,
	StarsRounded,
	Archive,
	Summarize,
	Ballot,
	Output,
	Inbox,
	Save,
	Add,
	Clear,
	Delete,
	ArrowDropDown,
	Done,
	ArrowDownward,
	ArrowUpward,
	CloudUpload,
	AddPhotoAlternate,
} from "@mui/icons-material";

import { PageContentWrapper } from "../../../../components/Workspace/PageContentWrapper";
import { ReviewsTab } from "../../../../components/Workspace/PowerUp/Sidebar/components/reviews-tab";
import { PowerUpAvatar } from "../../../../components/Workspace/PowerUp/Accordion/styles";
import { AttributeItem } from "../../../../components/Workspace/PowerUp/Sidebar/components/attribute-item";

import Editor from "../Editor";
import FormBuilder from "../FormBuilder";
import Outputs from "../FormBuilder/outputs";

import { POWERUPKNOWLEDGELOGO, COVER_DEFAULT_IMAGE } from "../../../../constants";
import ROUTES_PATH from "../../../../constants/routes";

const DEFAULT_IMAGE = COVER_DEFAULT_IMAGE;

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
			{value === index && <>{children}</>}
		</div>
	);
}

String.prototype.capitalize = function () {
	return this.charAt(0).toUpperCase() + this.slice(1);
};

function Status({ state, onStateChange }) {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const handleClick = event => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const handleState = state => {
		onStateChange(state);
		handleClose();
	};
	return (
		<>
			<Button
				sx={{ margin: 0 }}
				endIcon={<ArrowDropDown />}
				aria-controls={open ? "demo-customized-menu" : undefined}
				aria-haspopup="true"
				aria-expanded={open ? "true" : undefined}
				variant="contained"
				disableElevation
				onClick={handleClick}
			>
				{state.capitalize()}
			</Button>
			<Menu
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
				id="demo-customized-menu"
				MenuListProps={{
					"aria-labelledby": "demo-customized-button",
				}}
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
			>
				<MenuItem onClick={() => handleState("draft")}>
					<ListItemIcon>
						<Edit />
					</ListItemIcon>
					<ListItemText>Draft</ListItemText>
				</MenuItem>
				<MenuItem onClick={() => handleState("published")}>
					<ListItemIcon>
						<Done />
					</ListItemIcon>
					<ListItemText>Published</ListItemText>
				</MenuItem>
				<MenuItem onClick={() => handleState("archived")}>
					<ListItemIcon>
						<Archive />
					</ListItemIcon>
					<ListItemText>Archived</ListItemText>
				</MenuItem>
			</Menu>
		</>
	);
}

function OutlineItem({ index, onChange, onDelete, title: _title, description: _description }) {
	const [title, setTitle] = useState(_title);
	useEffect(() => {
		setTitle(_title);
	}, [_title]);

	const [description, setDescription] = useState(_description);
	useEffect(() => {
		setDescription(_description);
	}, [_description]);

	return (
		<Card variant={"outlined"}>
			<Stack direction={"row"} p={3} gap={3}>
				<Box mt={1}>
					<PowerUpAvatar>
						<Typography variant={"h5"}>{String(index + 1).padStart(2, "0")}</Typography>
					</PowerUpAvatar>
				</Box>
				<Stack gap={2} flex={"1 1 auto"}>
					<TextField label={"Outline Item Title"} value={title} onChange={e => setTitle(e.target.value)} onBlur={() => onChange({ title, description })} />
					<TextField
						label={"Outline Item Description"}
						multiline
						value={description}
						onChange={e => setDescription(e.target.value)}
						onBlur={() => onChange({ title, description })}
					/>
				</Stack>
				<Box mt={0.5}>
					<Tooltip title="Delete">
						<IconButton aria-label="Delete" onClick={onDelete}>
							<Delete />
						</IconButton>
					</Tooltip>
				</Box>
			</Stack>
		</Card>
	);
}

function InspirationItem({ onChange, onDelete, media: _media, description: _description }) {
	const [media, setMedia] = useState(_media);
	useEffect(() => {
		setMedia(_media);
	}, [_media]);

	const [description, setDescription] = useState(_description);
	useEffect(() => {
		setDescription(_description);
	}, [_description]);

	return (
		<Stack gap={2} component={Card} variant={"outlined"} px={2} pt={1} pb={2}>
			<Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
				<Typography variant={"h5"}>Inspiration Item</Typography>
				<IconButton size={"small"} onClick={onDelete}>
					<Clear />
				</IconButton>
			</Stack>
			<TextField
				type={"url"}
				size={"small"}
				label={"Media URL"}
				value={media}
				onChange={e => setMedia(e.target.value)}
				onBlur={() => onChange({ media, description })}
			/>
			<TextField
				multiline
				size={"small"}
				label={"Media Description"}
				value={description}
				onChange={e => setDescription(e.target.value)}
				onBlur={() => onChange({ media, description })}
			/>
		</Stack>
	);
}

function PowerUpItem({
	match: {
		params: { id },
	},
	auth,
}) {
	const history = useHistory();
	const powerUps = useContext(PowerUps.Context);
	const powerUp = powerUps.powerUp;

	useEffect(() => {
		if (id) {
			powerUps.get(id);
		}
	}, [id]);
	useEffect(() => {
		if (powerUps.errors?.status === 404) {
			history.replace(ROUTES_PATH.STARTUPS_HOME);
		}
	}, [powerUps.errors]);

	const updatedAt = powerUp?.updated?.at;
	const submissions = powerUp?.submissions;
	const submissionsSort = powerUp?.submissionsSort;

	const [state, setState] = useState(powerUp?.state || "draft");
	const [title, setTitle] = useState(powerUp?.title || "");
	const [image, setImage] = useState(powerUp?.image || "");
	const [description, setDescription] = useState(powerUp?.description || "");
	const [summary, setSummary] = useState(powerUp?.summary || "");
	const [details, setDetails] = useState(powerUp?.details || "");
	const [timeShort, setTimeShort] = useState(powerUp?.time?.short || 0);
	const [timeLong, setTimeLong] = useState(powerUp?.time?.long || 1);
	const [difficulty, setDifficulty] = useState(powerUp?.difficulty || "easy");
	const [recommendation, setRecommendation] = useState(powerUp?.recommendation || "optional");
	const [powertips, setPowertips] = useState(powerUp?.powertips || "");
	const [webhook, setWebhook] = useState(powerUp?.webhook || "");

	const [outlines, setOutlines] = useState(powerUp.outlines || []);
	const [sections, setSections] = useState(powerUp.sections || []);
	const [outputs, setOutputs] = useState(powerUp.outputs || []);
	const [inspirations, setInspirations] = useState(powerUp.inspirations || []);

	useEffect(() => {
		if (powerUp?.state !== state) {
			setState(powerUp?.state || "draft");
		}
		if (powerUp?.title !== title) {
			setTitle(powerUp?.title || "");
		}
		if (powerUp?.image !== image) {
			setImage(powerUp?.image || "");
		}
		if (powerUp?.description !== description) {
			setDescription(powerUp?.description || "");
		}
		if (powerUp?.summary !== summary) {
			setSummary(powerUp?.summary || "");
		}
		if (powerUp?.details !== details) {
			setDetails(powerUp?.details || "");
		}
		if (powerUp?.time?.short !== timeShort) {
			setTimeShort(powerUp?.time?.short || 0);
		}
		if (powerUp?.time?.long !== timeLong) {
			setTimeLong(powerUp?.time?.long || 1);
		}
		if (powerUp?.difficulty !== difficulty) {
			setDifficulty(powerUp?.difficulty || "easy");
		}
		if (powerUp?.recommendation !== recommendation) {
			setRecommendation(powerUp?.recommendation || "optional");
		}
		if (powerUp?.powertips !== powertips) {
			setPowertips(powerUp?.powertips || "");
		}
		if (!isEqual(powerUp?.outlines, outlines)) {
			setOutlines(powerUp?.outlines);
		}
		if (!isEqual(powerUp?.sections, sections)) {
			setSections(powerUp?.sections);
		}
		if (!isEqual(powerUp?.outputs, outputs)) {
			setOutputs(powerUp?.outputs);
		}
		if (!isEqual(powerUp?.inspirations, inspirations)) {
			setInspirations(powerUp?.inspirations);
		}
	}, [powerUp]);
	useEffect(() => {
		if (powerUps.imageUrl !== image) {
			setImage(powerUps.imageUrl || "");
		}
	}, [powerUps.imageUrl]);

	const updatePowerUp = ({ state, title, image, description, summary, details, timeShort, timeLong, difficulty, recommendation, powertips, webhook } = {}) => {
		powerUps.update({ id, state, title, image, description, summary, details, timeShort, timeLong, difficulty, recommendation, powertips, webhook });
	};

	useEffect(() => {
		setTimeout(() => {
			if (!isEqual(outlines, powerUp?.outlines)) {
				powerUps.updateOutlines({ id, outlines });
			}
			if (sections.length && !isEqual(sections, powerUp?.sections)) {
				powerUps.updateSections({ id, sections });
			}
			if (outputs.length && !isEqual(outputs, powerUp?.outputs)) {
				powerUps.updateOutputs({ id, outputs });
			}
		}, 1000);
	}, [outlines, sections, outputs]);

	const updateInspiration = () => {
		powerUps.updateInspirations({ id, inspirations });
	};

	const [mainTabIndex, setMainTabIndex] = useState(0);
	const [knowTabIndex, setKnowTabIndex] = useState(0);

	const scrollRef = createRef();

	return (
		<PageContentWrapper paddingBottom={0}>
			<Grid container spacing={3}>
				<Grid item xl={8} lg={7} md={12}>
					<Stack gap={3}>
						<Paper elevation={1}>
							<Stack padding={3} gap={3}>
								<Stack direction={"row"} gap={3} justifyContent={"space-between"} alignItems={"baseline"}>
									<Breadcrumbs separator={<NavigateNext fontSize="small" />}>
										<Link underline="hover" sx={{ cursor: "pointer" }} onClick={() => history.push(ROUTES_PATH.STARTUPS_HOME)}>
											<Typography variant="h5">PowerUps</Typography>
										</Link>
										<Typography variant="h5">{title}</Typography>
									</Breadcrumbs>
									<Typography variant={"caption"}>
										{state === "draft" ? "Updated" : state.capitalize()} on {new Date(updatedAt).toLocaleString()}
									</Typography>
								</Stack>
								<Stack direction={"row"} gap={3} alignItems={"flex-start"}>
									<Stack gap={3} flex={"2 1 75%"}>
										<Input
											sx={theme => ({
												fontFamily: theme.typography.h1,
												fontSize: theme.typography.h1,
											})}
											endAdornment={
												<InputAdornment position={"end"}>
													<Edit />
												</InputAdornment>
											}
											value={title}
											onChange={e => setTitle(e.target.value)}
											onBlur={() => updatePowerUp({ title })}
										/>
										<TextField
											multiline
											label={"Description"}
											value={description}
											onChange={e => setDescription(e.target.value)}
											onBlur={() => updatePowerUp({ description })}
										/>
										<Stack gap={1}>
											<Stack direction={"row"} gap={2} alignItems={"center"}>
												<TextField
													type={"url"}
													label={"Cover Image URL"}
													value={image}
													sx={{ flex: "1 1 auto" }}
													onChange={e => setImage(e.target.value)}
													onBlur={() => updatePowerUp({ image })}
												/>
												<IconButton color="primary" aria-label="upload picture" component="label">
													<input
														type="file"
														accept="image/*"
														hidden
														onChange={async e => {
															const files = e.target.files;
															if (files && files[0]) {
																const image = await powerUps.uploadMedia({ file: files[0], set: true });
																updatePowerUp({ image });
															}
														}}
													/>
													<AddPhotoAlternate />
												</IconButton>
											</Stack>
											<Typography variant={"caption"} color={"secondary"}>
												Recommended size: 800px × 400px
											</Typography>
										</Stack>
									</Stack>
									<img
										src={image || DEFAULT_IMAGE}
										alt={"PowerUp Cover"}
										style={{
											aspectRatio: "2",
											maxWidth: "50%",
											flex: "1 2 25%",
											borderRadius: 8,
											objectFit: "cover",
										}}
									/>
								</Stack>
								<Divider />
								<Stack direction="row" alignItems={"center"} justifyContent="space-between">
									<Rating value={3.4} readOnly />
									<Stack direction={"row"} gap={2}>
										<Button
											sx={{ margin: 0 }}
											variant="outlined"
											endIcon={<Visibility />}
											onClick={() => history.push(ROUTES_PATH.PARTNER_POWERUP_PREVIEW.replace(":id", id))}
										>
											Preview
										</Button>
										<Status state={state} onStateChange={state => setState(state) || updatePowerUp({ state })} />
									</Stack>
								</Stack>
							</Stack>
						</Paper>
						<Paper elevation={1}>
							<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
								<Tabs variant="fullWidth" aria-label="PowerUp Components" value={mainTabIndex} onChange={(e, i) => setMainTabIndex(i)}>
									<Tab
										icon={<Summarize />}
										label="Summary"
										iconPosition={"start"}
										{...a11yProps(0)}
										sx={theme => ({
											textTransform: "none",
											fontSize: theme.typography.label_medium,
											fontFamily: theme.typography.label_medium,
										})}
									/>
									<Tab
										icon={<Ballot />}
										label="Content"
										iconPosition={"start"}
										{...a11yProps(1)}
										sx={theme => ({
											textTransform: "none",
											fontSize: theme.typography.label_medium,
											fontFamily: theme.typography.label_medium,
										})}
									/>
									<Tab
										icon={<Output />}
										label="Outcomes"
										iconPosition={"start"}
										{...a11yProps(2)}
										sx={theme => ({
											textTransform: "none",
											fontSize: theme.typography.label_medium,
											fontFamily: theme.typography.label_medium,
										})}
									/>
									<Tab
										icon={<Inbox />}
										label="Submissions"
										iconPosition={"start"}
										{...a11yProps(3)}
										sx={theme => ({
											textTransform: "none",
											fontSize: theme.typography.label_medium,
											fontFamily: theme.typography.label_medium,
										})}
									/>
								</Tabs>
							</Box>
							<Box>
								<TabPanel value={mainTabIndex} index={0}>
									<Stack gap={3} p={3}>
										<Stack gap={2}>
											<Typography variant={"h4"}>Overview</Typography>
											<Editor
												label={"Overview content…"}
												defaultValue={summary}
												onSave={summary => setSummary(summary) || updatePowerUp({ summary })}
												getMediaUrl={file => powerUps.uploadMedia({ file })}
											/>
											<Typography variant={"h4"}>Outline</Typography>
											{outlines.map(({ title, description }, i) => (
												<OutlineItem
													key={i}
													index={i}
													title={title}
													description={description}
													onChange={({ title, description }) => {
														const outs = [...outlines];
														outs[i] = {
															title,
															description,
														};
														setOutlines(outs);
													}}
													onDelete={() => {
														const outs = [...outlines];
														outs.splice(i, 1);
														setOutlines(outs);
													}}
												/>
											))}
											<Button
												variant={"outlined"}
												sx={{ alignSelf: "center" }}
												endIcon={<Add />}
												onClick={() => setOutlines([...outlines, { title: "", description: "" }])}
											>
												Add Outline Item
											</Button>
										</Stack>
									</Stack>
								</TabPanel>
								<TabPanel value={mainTabIndex} index={1}>
									<Box p={3}>
										<FormBuilder sections={sections} setSections={setSections} outputs={outputs} powerUps={powerUps} />
									</Box>
								</TabPanel>
								<TabPanel value={mainTabIndex} index={2}>
									<Box p={3}>
										<Outputs
											outputs={outputs}
											setOutputs={setOutputs}
											webhook={webhook}
											setWebhook={webhook => setWebhook(webhook) || updatePowerUp({ webhook })}
										/>
									</Box>
								</TabPanel>
								<TabPanel value={mainTabIndex} index={3}>
									<TableContainer>
										<Table>
											<TableHead>
												<TableRow>
													<TableCell>
														<Stack direction="row" gap={1} alignItems="center">
															<Typography variant="title_small">Submission Date</Typography>
															{submissionsSort.field === "updated" && (submissionsSort.order ? <ArrowDownward /> : <ArrowUpward />)}
														</Stack>
													</TableCell>
													<TableCell>
														<Stack direction="row" gap={1} alignItems="center">
															<Typography variant="title_small">Completion Time</Typography>
															{submissionsSort.field === "created" && (submissionsSort.order ? <ArrowDownward /> : <ArrowUpward />)}
														</Stack>
													</TableCell>
													<TableCell />
												</TableRow>
											</TableHead>
											<TableBody>
												{submissions.map(({ id: sid, created, updated }) => (
													<TableRow key={sid}>
														<TableCell>{new Date(updated?.at).toLocaleString()}</TableCell>
														<TableCell>{((new Date(updated?.at) - new Date(created?.at)) / (1000 * 60 * 60)).toFixed(2)} hours</TableCell>
														<TableCell>
															{/*<Button*/}
															{/*	startIcon={<ChevronRight/>}*/}
															{/*	onClick={() => history.push(ROUTES_PATH.PARTNER_POWERUP_SUBMISSION*/}
															{/*		.replace(':id', id).replace(':sid', sid))}>View</Button>*/}
														</TableCell>
													</TableRow>
												))}
											</TableBody>
										</Table>
									</TableContainer>
								</TabPanel>
							</Box>
						</Paper>
					</Stack>
				</Grid>
				<Grid item xl={4} lg={5} md={12}>
					<Paper elevation={1} sx={{ position: "sticky", top: 76 }}>
						<Box p={3}>
							<img src={POWERUPKNOWLEDGELOGO} alt="Knowledge" style={{ maxHeight: 20 }} />
						</Box>
						<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
							<Tabs variant="fullWidth" aria-label="basic tabs example" value={knowTabIndex} onChange={(e, i) => setKnowTabIndex(i)}>
								<Tab
									icon={<Toc />}
									label="Details"
									{...a11yProps(0)}
									sx={theme => ({
										textTransform: "none",
										fontSize: theme.typography.label_medium,
										fontFamily: theme.typography.label_medium,
									})}
								/>
								<Tab
									icon={<Bolt />}
									label="Power Tips"
									{...a11yProps(1)}
									sx={theme => ({
										textTransform: "none",
										fontSize: theme.typography.label_medium,
										fontFamily: theme.typography.label_medium,
									})}
								/>
								<Tab
									icon={<Whatshot />}
									label="Inspiration"
									{...a11yProps(2)}
									sx={theme => ({
										textTransform: "none",
										fontSize: theme.typography.label_medium,
										fontFamily: theme.typography.label_medium,
									})}
								/>
								<Tab
									icon={<StarsRounded />}
									label="Reviews"
									{...a11yProps(3)}
									sx={theme => ({
										textTransform: "none",
										fontSize: theme.typography.label_medium,
										fontFamily: theme.typography.label_medium,
									})}
								/>
							</Tabs>
						</Box>
						<Box>
							<TabPanel value={knowTabIndex} index={0}>
								<Stack gap={3} p={3}>
									<Stack gap={1}>
										<Typography variant="title_medium">Details</Typography>
										<Typography variant={"caption"}>
											Let founders know what they'll accomplish by going through your PowerUp. Feel free to add any accompanying media.
										</Typography>
									</Stack>
									<Divider />
									<TextField multiline label={"Short Description"} value={details} onChange={e => setDetails(e.target.value)} />
									<Stack gap={1}>
										<AttributeItem icon="time" name="Time Estimate" />
										<Stack direction={"row"} alignItems={"baseline"} justifyContent={"space-between"}>
											<TextField type={"number"} size={"small"} label={"Short"} value={timeShort} onChange={e => setTimeShort(e.target.value)} />
											<Typography>–</Typography>
											<TextField type={"number"} size={"small"} label={"Long"} value={timeLong} onChange={e => setTimeLong(e.target.value)} />
											<Typography>Hours</Typography>
										</Stack>
									</Stack>
									<Stack gap={1}>
										<AttributeItem icon="steps" name="Difficulty" />
										<FormControl size={"small"}>
											<Select value={difficulty} onChange={e => setDifficulty(e.target.value)}>
												<MenuItem value="easy">Easy</MenuItem>
												<MenuItem value="medium">Medium</MenuItem>
												<MenuItem value="advanced">Advanced</MenuItem>
											</Select>
										</FormControl>
									</Stack>
									<Stack gap={1}>
										<AttributeItem icon="person" name="Team Recommended" />
										<FormControl size={"small"}>
											<Select value={recommendation} onChange={e => setRecommendation(e.target.value)}>
												<MenuItem value="optional">Optional</MenuItem>
												<MenuItem value="recommended">Recommended</MenuItem>
											</Select>
										</FormControl>
									</Stack>
								</Stack>
							</TabPanel>
							<TabPanel value={knowTabIndex} index={1}>
								<Stack gap={2} p={3}>
									<Typography variant="title_medium">Power Tips</Typography>
									<Editor label={"About…"} defaultValue={powertips} onSave={setPowertips} getMediaUrl={file => powerUps.uploadMedia({ file })} />
								</Stack>
							</TabPanel>
							<TabPanel value={knowTabIndex} index={2}>
								<Stack gap={2} p={3} alignItems={"stretch"}>
									<Typography variant="title_medium">Inspiration</Typography>
									{inspirations.map(({ media, description }, i) => (
										<InspirationItem
											key={i}
											media={media}
											description={description}
											onChange={({ media, description }) => {
												const ins = [...inspirations];
												ins[i] = {
													media,
													description,
												};
												setInspirations(ins);
											}}
											onDelete={() => {
												const ins = [...inspirations];
												ins.splice(i, 1);
												setInspirations(ins);
											}}
										/>
									))}
									<Button
										endIcon={<Add />}
										variant={"outlined"}
										sx={{ maxWidth: "unset" }}
										onClick={() => setInspirations([...inspirations, { media: "", description: "" }])}
									>
										Add Item
									</Button>
								</Stack>
							</TabPanel>
							<TabPanel value={knowTabIndex} index={3}>
								<Stack gap={2} p={3}>
									<ReviewsTab powerupID={powerUp?.id} scrollRef={scrollRef} />
								</Stack>
							</TabPanel>
						</Box>
						<Divider />
						{knowTabIndex < 3 && (
							<Stack p={3} alignItems={"stretch"}>
								<Button
									endIcon={<Save />}
									sx={{ maxWidth: "unset" }}
									onClick={() =>
										knowTabIndex < 2
											? updatePowerUp(
													knowTabIndex === 0 ? { details, timeShort, timeLong, difficulty, recommendation } : knowTabIndex === 1 ? { powertips } : {}
											  )
											: updateInspiration()
									}
								>
									Save Changes
								</Button>
							</Stack>
						)}
					</Paper>
				</Grid>
			</Grid>
		</PageContentWrapper>
	);
}

const mapStoreToProps = ({ auth }) => ({
	auth,
});

export default connect(mapStoreToProps)(PowerUpItem);
