import React from "react";
import GoogleTrends from "./index";

const GoogleTrendsContainer = ({ category, keywords, id, geoCountry }) => {
  return (
    <div id={`${id}`}>
      <GoogleTrends
        category={category}
        type="TIMESERIES"
        keywords={keywords}
        id={id}
        url="https://ssl.gstatic.com/trends_nrtr/2965_RC01/embed_loader.js"
        geoCountry={geoCountry}
      />
      <GoogleTrends
        category={category}
        type="GEO_MAP"
        keywords={keywords}
        id={id}
        url="https://ssl.gstatic.com/trends_nrtr/2965_RC01/embed_loader.js"
        geoCountry={geoCountry}
      />
    </div>
  );
};

export default GoogleTrendsContainer;
