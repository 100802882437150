import React, { useEffect, useState, useCallback } from "react";
import { Box, Divider, Stack, Breadcrumbs, Link, Typography, IconButton, Grid, CircularProgress, Button, Card } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ArrowBack from "@mui/icons-material/ArrowBack";
import constants, { IN_PROGRESS_POWERUPS_DATA } from "../../../constants";
import ROUTES_PATH from "../../../constants/routes";
import theme from "../../../constants/Theme";
import PowerUPHeader from "../../../components/PowerUP/Details/Header";
import ValuePropOverView from "../../../components/Workspace/PowerUp/ValueProp/Overview";
import { useParams, useHistory } from "react-router-dom";
import { toastContainer } from "../../../utils/toast";
import services from "../../../services";
import PowerUpKnowledgeSidebar from "../../../components/Workspace/PowerUp/Sidebar";
import { VALUE_PROP_SIDEBAR } from "../../../constants/powerup-data";
import CompletedWorksCard from "../../../components/Workspace/PowerUp/PersonaWorkCard";
import AdPartnerContainer from "../../../components/PowerUP/Details/AdPartnerContainer";
import InProgressPowerupsPopup from "./InProgressPowerupsPopup";
import { Add } from "@mui/icons-material";
import LoadingButton from "../../../common/Loadingbutton";
import { handleMixPanel } from "../../../utils/mixPanelEventHandling";
import { useDispatch } from "react-redux";
import { setMixpanelData } from "../../../modules/actions/mixpanelActions";
import { getPowerUpsCompletedCount } from "../../../utils/getPowerUpsCompletedCount";

export default function ValuePropPowerUp() {
	const history = useHistory();
	const [powerupDetails, setPowerupDetails] = useState("");
	const [powerupDetailsLoader, setPowerupDetailsLoader] = useState(false);
	const [powerupStepsData, setPowerupStepsData] = useState([]);
	const [latestModule, setLatestModule] = useState();
	const [inProgressPopup, setInProgressPopup] = useState(false);
	const params = useParams();
	const powerUpId = parseInt(params.id);
	const [startNewPowerupLoader, setStartNewPowerupLoader] = useState(false);
	const [ratingDetails, setRatingDetails] = useState();
	const date = new Date();
	const dispatch = useDispatch();
	const [powerUpCompletedCount, setPowerUpCompletedCount] = useState(0);

	const getDetailsApiCall = () => {
		//service call to update last visited powerup status
		services
			.updateLastVisitedPowerup({
				powerUpId: params.id,
			})
			.then(response => {})
			.catch(error => {});

		setPowerupDetailsLoader(true);

		//service call to get powerup details
		services
			.getPowerupData(params.id)
			.then(response => {
				const res = response.data;

				if (res.code === 200) {
					//service call to update last visited powerup status
					services
						.updateLastVisitedPowerup({
							powerUpId: params.id,
						})
						.then(response => {})
						.catch(error => {});

					let details = { ...res.data };

					//Puttin dummy count of versions late to be replaced by api data
					if (details.companyTask) {
						details = {
							...details,
							companyTask: {
								...details.companyTask,
								completedWorks: details.companyTask.completedWorks,
								inProgressWorks: details.companyTask.inProgressWorks,
							},
						};
					}

					setPowerupDetails(details);

					if (res.data.modules && res.data.modules.length) {
						getModuteStepsApiCall(res.data.modules[0].moduleUuid);
					}
				}
			})
			.catch(error => {
				setPowerupDetailsLoader(false);
			});
	};

	const getPowerUpsCompletedCountApiCall = powerUpId => {
		console.log("Restart PowerUp getPowerUpsCompletedCountApiCall");
		console.log("powerUpId: ", powerUpId);

		const res = getPowerUpsCompletedCount(powerUpId);

		return 0;
	};

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		});
		getDetailsApiCall();
		getRatingDetailsApiCall(params?.id);

		if (params.id == 1) {
			dispatch(
				setMixpanelData({
					powerUpId: params.id,
					powerUpName: "Value Proposition",
				})
			);
		} else if (params.id == 2) {
			dispatch(
				setMixpanelData({
					powerUpId: params.id,
					powerUpName: "Founder Story",
				})
			);
		} else if (params.id == 5) {
			dispatch(
				setMixpanelData({
					powerUpId: params.id,
					powerUpName: "Problem Solution Fit",
				})
			);
		} else if (params.id == 6) {
			dispatch(
				setMixpanelData({
					powerUpId: params.id,
					powerUpName: "Brandstorming",
				})
			);
		} else if (params.id == 7) {
			dispatch(
				setMixpanelData({
					powerUpId: params.id,
					powerUpName: "TAM/SAM/SOM",
				})
			);
		} else if (params.id == 9) {
			dispatch(
				setMixpanelData({
					powerUpId: params.id,
					powerUpName: "Pricing Modeler",
				})
			);
		} else {
			dispatch(
				setMixpanelData({
					powerUpId: params.id,
					powerUpName: "PowerUp Name",
				})
			);
		}
	}, [params]);

	const getModuteStepsApiCall = id => {
		services
			.getModulesSteps(id)
			.then(result => {
				setPowerupDetailsLoader(false);
				if (result.data?.data) {
					setPowerupStepsData(result.data?.data);
				}
			})
			.catch(err => console.log(err));
	};

	const handleWorkspaceNavigation = () => {
		history.push(ROUTES_PATH.STARTUPS_HOME);
	};

	const handleResumePowerup = (moduleId, moduleUuid, responseId) => {
		history.push(`/workspace/powerup/${moduleUuid}/${moduleId}/${responseId}/start`);
	};

	const startNewPowerupVersion = action => {
		setStartNewPowerupLoader(true);

		const moduleId = powerupDetails.modules[0].id;
		const moduleUuid = powerupDetails.modules[0].moduleUuid;
		services
			.startModule({ moduleId })
			.then(async response => {
				if (action === "NEW_VERSION") {
					
					// For some reason, all the module IDs got messed up for Restarting a PowerUp
					// and do not correspond to the correct IDs.
					// Problem Solution Fit (5) shows up as 9
					// Brandstorming (6) shows up as 8
					// TamSamSom (7) shows up as for
					// Pricing Modeler (9) shows up as 5
					switch (moduleId) {
						case 1:
							const count1 = await getPowerUpsCompletedCount(1);
							handleMixPanel("Powerup Restarted", {
								"PowerUp ID": "1",
								"PowerUp Name": "Value Proposition",
								"Times Completed Count": count1,
								"Start Date": date.toISOString(),
							});
							break;
						case 2:
							const count2 = await getPowerUpsCompletedCount(2);
							handleMixPanel("Powerup Restarted", {
								"PowerUp ID": "2",
								"PowerUp Name": "Founder Story",
								"Times Completed Count": count2,
								"Start Date": date.toISOString(),
							});
							break;
						case 4:
							const count7 = await getPowerUpsCompletedCount(7);
							handleMixPanel("Powerup Restarted", {
								"PowerUp ID": "7",
								"PowerUp Name": "TAM/SAM/SOM",
								"Times Completed Count": count7,
								"Start Date": date.toISOString(),
							});
							break;
						case 5:
							const count9 = await getPowerUpsCompletedCount(9);
							handleMixPanel("Powerup Restarted", {
								"PowerUp ID": "9",
								"PowerUp Name": "Pricing Modeler",
								"Times Completed Count": count9,
								"Start Date": date.toISOString(),
							});
							break;
						// case 6:
						// 	const count6 = await getPowerUpsCompletedCount(6);
						// 	handleMixPanel("Powerup Restarted", {
						// 		"PowerUp ID": "6",
						// 		"PowerUp Name": "Brandstorming",
						// 		"Times Completed Count": count6,
						// 		"Start Date": date.toISOString(),
						// 	});
						// 	break;
						// case 7:
						// 	const count7 = await getPowerUpsCompletedCount(7);
						// 	handleMixPanel("Powerup Restarted", {
						// 		"PowerUp ID": "7",
						// 		"PowerUp Name": "TAM/SAM/SOM",
						// 		"Times Completed Count": count7,
						// 		"Start Date": date.toISOString(),
						// 	});
						// 	break;
						case 8:
							const count6 = await getPowerUpsCompletedCount(6);
							handleMixPanel("Powerup Restarted", {
								"PowerUp ID": "6",
								"PowerUp Name": "Brandstorming",
								"Times Completed Count": count6,
								"Start Date": date.toISOString(),
							});
							break;
						case 9:
							const count5 = await getPowerUpsCompletedCount(5);
							handleMixPanel("Powerup Restarted", {
								"PowerUp ID": "5",
								"PowerUp Name": "Problem Solution Fit",
								"Times Completed Count": count5,
								"Start Date": date.toISOString(),
							});
							break;
					}
				} else if (action === "FIRST_VERSION") {
					switch (moduleId) {
						case 1:
							handleMixPanel("Powerup Started", {
								"PowerUp ID": "1",
								"PowerUp Name": "Value Proposition",
								"Start Date": date.toISOString(),
							});
							break;
						case 2:
							handleMixPanel("Powerup Started", {
								"PowerUp ID": "2",
								"PowerUp Name": "Founder Story",
								"Start Date": date.toISOString(),
							});
							break;
						case 3: // New Design
							handleMixPanel("Powerup Started", {
								"PowerUp ID": "3",
								"PowerUp Name": "Persona Builder",
								"Start Date": date.toISOString(),
							});
							break;
						case 4:
							handleMixPanel("Powerup Started", {
								"PowerUp ID": "7",
								"PowerUp Name": "TAM/SAM/SOM",
								"Start Date": date.toISOString(),
							});
							break;
						case 5:
							handleMixPanel("Powerup Started", {
								"PowerUp ID": "9",
								"PowerUp Name": "Pricing Modeler",
								"Start Date": date.toISOString(),
							});
							break;
						case 8:
							handleMixPanel("Powerup Started", {
								"PowerUp ID": "6",
								"PowerUp Name": "Brandstorming",
								"Start Date": date.toISOString(),
							});
							break;
						case 9:
							handleMixPanel("Powerup Started", {
								"PowerUp ID": "5",
								"PowerUp Name": "Problem Solution Fit",
								"Start Date": date.toISOString(),
							});
							break;
						case 20: // New Design
							handleMixPanel("Powerup Started", {
								"PowerUp ID": "20",
								"PowerUp Name": "MVP Prioritizer",
								"Start Date": date.toISOString(),
							});
							break;
					}
				}

				setStartNewPowerupLoader(false);
				const res = response.data;
				if (res.code === 200) {
					history.push(`/workspace/powerup/${moduleUuid}/${moduleId}/${res.data.id}/start`);
				}
			})
			.catch(error => {
				const message = error.response?.data?.message;
				setStartNewPowerupLoader(false);
				toastContainer(message, "error");
				console.log(error);
			});
	};

	const handleInProgressPopupClose = () => {
		setInProgressPopup(false);
	};

	const handleResumePowerupVersion = () => {
		setInProgressPopup(true);
	};

	const powerupButton = useCallback(() => {
		if (powerupDetails?.companyTask) {
			if (powerupDetails.companyTask.taskStatus === "STARTED") {
				if (powerupDetails.companyTask.inProgressWorks > 0) {
					return (
						<Box sx={{ display: "flex", gap: 2 }}>
							<LoadingButton
								onClick={() => startNewPowerupVersion("NEW_VERSION")}
								startIcon={<Add />}
								sx={{ whiteSpace: "nowrap" }}
								loading={startNewPowerupLoader}
							>
								Start new
							</LoadingButton>
							<Button onClick={() => handleResumePowerupVersion()}>Resume</Button>
						</Box>
					);
				} else {
					return (
						<LoadingButton
							onClick={() => startNewPowerupVersion("NEW_VERSION")}
							startIcon={<Add />}
							sx={{ whiteSpace: "nowrap" }}
							loading={startNewPowerupLoader}
						>
							Start new
						</LoadingButton>
					);
				}
			} else if (powerupDetails.companyTask.taskStatus === "COMPLETED") {
				if (powerupDetails.companyTask.inProgressWorks > 0) {
					return (
						<Box sx={{ display: "flex", gap: 2 }}>
							<LoadingButton
								onClick={() => startNewPowerupVersion("NEW_VERSION")}
								startIcon={<Add />}
								sx={{ whiteSpace: "nowrap" }}
								loading={startNewPowerupLoader}
							>
								Start new
							</LoadingButton>
							<Button onClick={() => handleResumePowerupVersion("resume")}>Resume</Button>
						</Box>
					);
				} else {
					return (
						<LoadingButton onClick={() => startNewPowerupVersion("NEW_VERSION")} startIcon={<Add />} loading={startNewPowerupLoader}>
							Start new
						</LoadingButton>
					);
				}
			}
		} else {
			return (
				<LoadingButton onClick={() => startNewPowerupVersion("FIRST_VERSION")} loading={startNewPowerupLoader}>
					Start
				</LoadingButton>
			);
		}
	}, [powerupDetails, startNewPowerupLoader]);

	const getRatingDetailsApiCall = powerupId => {
		return services
			.getReviewsList(10, 1, "", "", powerupId, "POWER_UP", true)
			.then(response => {
				let ratingDetails = response.data.data.ratingDetails;

				setRatingDetails(ratingDetails);
			})
			.catch(error => console.log(error));
	};
	return (
		<>
			<InProgressPowerupsPopup
				startNewPowerupLoader={startNewPowerupLoader}
				open={inProgressPopup}
				onClose={handleInProgressPopupClose}
				powerupId={powerUpId}
				handleResumePowerup={handleResumePowerup}
				handleStartPowerup={startNewPowerupVersion}
				inProgressWorks={powerupDetails?.companyTask?.inProgressWorks}
				getDetailsApiCall={getDetailsApiCall}
			/>

			<Box sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
				{powerupDetailsLoader ? (
					<Box justifyContent={"center"} sx={{ display: "flex", mt: 18 }}>
						<CircularProgress />
					</Box>
				) : (
					<Stack
						sx={theme => ({
							minHeight: "100%",
							p: 3,
						})}
					>
						<Grid container flexDirection={"column"} sx={{ maxWidth: theme.breakpoints.values.xl, margin: "auto" }}>
							<Grid container>
								<Grid
									item
									lg={8}
									md={8}
									sm={12}
									xs={12}
									sx={theme => ({
										[theme.breakpoints.up("md")]: {
											// borderRight: `1px solid ${theme.palette.secondary.dark}`,
											pr: 3,
										},
									})}
								>
									<Box>
										<PowerUPHeader valuePropsHeaderData={powerupDetails} powerupButtonComp={powerupButton()} ratingDetails={ratingDetails} />
										{/* <Divider
                      color={theme.palette.secondary.dark}
                      sx={{ mt: 3 }}
                    /> */}
										<Box sx={{ mt: 3 }}>
											<ValuePropOverView
												valuePropsDetails={constants.VALUE_PROP_DETAILS}
												valuePropsContents={powerupStepsData}
												powerupApiDetails={powerupDetails}
											/>
										</Box>
									</Box>
									{/* <Box sx={{ mt: 3 }}><AdPartnerContainer /></Box> */}
								</Grid>

								<Grid
									item
									lg={4}
									md={4}
									sm={12}
									xs={12}
									sx={theme => ({
										[theme.breakpoints.up("md")]: {
											pl: 3,
										},
										mb: 6,
									})}
								>
									{powerupDetails?.companyTask?.completedWorks > 0 && (
										<Box sx={{ mb: 3, maxWidth: "372px" }}>
											<CompletedWorksCard powerUpId={powerUpId} />
										</Box>
									)}

									<PowerUpKnowledgeSidebar sidebar_content={powerupDetails} />
								</Grid>
							</Grid>
						</Grid>
					</Stack>
				)}
			</Box>

			{/* Mobile screens*/}
			<Box sx={{ display: { xs: "block", sm: "block", md: "none" } }}>
				{powerupDetailsLoader ? (
					<Box justifyContent={"center"} sx={{ display: "flex", mt: 18 }}>
						<CircularProgress />
					</Box>
				) : (
					<Stack
						sx={{
							minHeight: "100%",
							p: 2,
							backgroundColor: "secondary.greyBg",
						}}
					>
						<Grid container flexDirection={"column"} sx={{ maxWidth: theme.breakpoints.values.xl, margin: "auto" }}>
							<Box sx={{ display: "flex", alignItems: "center" }}>
								<IconButton aria-label="ArrowBack" color="primary">
									<ArrowBack />
								</IconButton>
								<Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon sx={{ ml: 1.5, mr: 1 }} />}>
									<Link
										underline="hover"
										color="primary"
										component="button"
										onClick={() => {
											handleWorkspaceNavigation();
										}}
									>
										<Typography color="primary" variant="h5">
											Workspace
										</Typography>
									</Link>
									<Typography color="text.primary" variant="h5">
										{powerupDetails.powerUpName}
									</Typography>
								</Breadcrumbs>
							</Box>
							<Grid container>
								<Grid
									item
									lg={8}
									md={8}
									sm={12}
									xs={12}
									sx={theme => ({
										[theme.breakpoints.up("md")]: {
											borderRight: `1px solid ${theme.palette.secondary.dark}`,
											pr: 3,
										},
										mt: 4,
									})}
								>
									<Box>
										<PowerUPHeader
											valuePropsHeaderData={powerupDetails}
											valuePropsDetails={constants.VALUE_PROP_DETAILS_SIDEBAR}
											powerupApiDetails={powerupDetails}
										/>
										<Box sx={{ mt: 3 }}>{powerupDetails?.companyTask?.completedWorks > 0 && <CompletedWorksCard powerUpId={powerUpId} />}</Box>

										<Box sx={{ mt: 3 }}>
											<ValuePropOverView
												valuePropsDetails={constants.VALUE_PROP_DETAILS}
												valuePropsContents={powerupStepsData}
												powerupApiDetails={powerupDetails}
											/>
										</Box>
										<Box sx={{ mt: 3 }}>{/*<AdPartnerContainer />*/}</Box>
									</Box>
								</Grid>
							</Grid>
						</Grid>
					</Stack>
				)}
			</Box>
			{!powerupDetailsLoader ? (
				<Box
					sx={{
						display: { xs: "block", sm: "block", md: "none" },

						pl: 3,
						pr: 2,
						mb: 2,
						py: 2,
						backgroundColor: theme.palette.secondary.white,
					}}
				>
					<Grid container>
						{/*<Grid item xs={2}>
              <Typography variant="displaySmall">
                ${powerupDetails.amount}
              </Typography>
            </Grid>*/}
						<Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
							{powerupButton()}
						</Grid>
					</Grid>
				</Box>
			) : (
				""
			)}
		</>
	);
}
