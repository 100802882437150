import React, { useEffect, useState } from "react";
import { TextField, Box, Chip } from "@mui/material";
import PropTypes from "prop-types";
import ClearIcon from "@mui/icons-material/Clear";
import messages from "../../constants/messages";
import { EMAIL_PLACEHOLDER, REFERENCE_ICON } from "../../constants/image";

const MultiObjTagsInput = ({
	inputValue,
	params,
	handleInputChange,
	inputKey,
	inputValid,
	placeholder,
	errorMessage,
	typingEnabled,
	label,
	max,
	handleOnBlurEvent,
	disabled,
	isEmailField = false,
	isReferenceField = false,
	isError = false,
	handleOnChange = () => {},
	handleEnter = true,
	InputLabelProps,
	...props
}) => {
	const [errorText, setErrorText] = useState("");
	const [value, setValue] = useState("");
	const boxRef = React.useRef(null);
	const handleKeyDown = (e) => {
		if (!handleEnter) {
			return;
		} else {
			if (e.key === "Enter" && typingEnabled) {
				handleDataChange(e.target.value);
			}
		}
	};
	const handleDelete = (value) => {
		const newtags = inputValue.filter((val) => val?.value !== value);
		handleInputChange({ target: { value: newtags } }, inputKey);
	};
	const handleChange = (e) => {
		if (e.target.value === " ") {
			return;
		} else {
			handleOnChange(e.target.value);
			setValue(e.target.value);
		}
	};
	const handleBlur = (e) => {
		if (handleOnBlurEvent) {
			handleDataChange(e.target.value);
		}
	};
	useEffect(() => {
		if (max) {
			if (inputValue.length > max) {
				setErrorText(messages.INVALID_TAGS);
			} else {
				setErrorText("");
			}
		}
	}, [inputValue]);

	const handleDataChange = (value) => {
		if (inputValid(inputKey, value)) {
			inputValue.push(value);
			let inputs = [...inputValue];
			inputs = inputs.map((item) => item.trim());
			var result = inputs.map(function (item) {
				return typeof item === "string" ? (isEmailField ? item.charAt(0) : item.charAt(0).toUpperCase()) + item.slice(1) : item;
			});
			let uniqueInputs = new Set(result);
			setValue("");
			handleInputChange({ target: { value: Array.from(uniqueInputs) } }, inputKey);
			setValue("");
			setErrorText("");
		} else {
			if (value) {
				setErrorText(errorMessage);
			} else if (inputValue.length > max) {
				setErrorText(messages.INVALID_TAGS);
			} else {
				setErrorText("");
			}
		}
	};
	return (
		<TextField
			{...params}
			spellCheck="true"
			onChange={handleChange}
			ref={boxRef}
			onKeyDown={!isError ? handleKeyDown : ""}
			placeholder={inputValue.length ? null : placeholder}
			label={label}
			onBlur={!isError ? handleBlur : ""}
			disabled={disabled}
			sx={{ height: "auto" }}
			InputProps={{
				...params.InputProps,
				endAdornment: props.endAdornment,
				startAdornment: inputValue.length ? (
					<Box
						sx={{
							display: "flex",
							flexWrap: "wrap",
							gap: 1,
							width: "max-content",
						}}
					>
						{inputValue.map((data, index) => {
							return (
								<>
									{isEmailField && (
										<Chip
											avatar={<Box component={"img"} src={EMAIL_PLACEHOLDER} alt="email"></Box>}
											label={data?.value}
											variant="outlined"
											sx={(theme) => ({
												borderRadius: theme.shape.standard13.borderRadius,
												maxWidth: boxRef.current.offsetWidth - 20,
											})}
											onDelete={() => handleDelete(data?.value)}
											deleteIcon={<ClearIcon />}
										/>
									)}
									{isReferenceField && (
										<Chip
											avatar={<Box component={"img"} src={REFERENCE_ICON} alt="ref"></Box>}
											label={data?.value}
											variant="outlined"
											sx={(theme) => ({
												borderRadius: theme.shape.standard13.borderRadius,
											})}
											onDelete={() => handleDelete(data?.value)}
											deleteIcon={<ClearIcon />}
										/>
									)}
									{!isEmailField && !isReferenceField && (
										<Chip
											label={data?.value}
											variant="outlined"
											sx={(theme) => ({
												borderRadius: theme.shape.standard_small.borderRadius,
											})}
											onDelete={() => (disabled ? {} : handleDelete(data?.value))}
											deleteIcon={<ClearIcon />}
										/>
									)}
								</>
							);
						})}
					</Box>
				) : null,
			}}
			helperText={errorText ? errorText : props.helpText}
			inputProps={{ ...params.inputProps, value: value }}
			inputRef={props.inputRef}
			InputLabelProps={InputLabelProps}
			{...props}
			error={errorText || isError ? true : false}
		/>
	);
};
MultiObjTagsInput.propTypes = {
	inputValue: PropTypes.array,
	params: PropTypes.object,
	handleChange: PropTypes.func,
	handleInputChange: PropTypes.func,
	inputKey: PropTypes.string,
	inputValid: PropTypes.func,
	placeholder: PropTypes.string,
	errorMessage: PropTypes.string,
};
MultiObjTagsInput.defaultProps = {
	inputValue: [],
	params: { InputProps: {} },
	handleChange: null,
	handleInputChange: null,
	inputKey: "",
	inputValid: "",
	placeholder: "",
	errorMessage: "",
};
export default MultiObjTagsInput;
