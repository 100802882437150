import React, { Fragment, useEffect, useState } from "react";
import LoadingButton from "../../../../common/Loadingbutton";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import ReviewLoading from "./modals/loading";
import { startupProfileAI } from "../../../../utils/AI/startupProfile";
import ReviewSummary from "./modals/summary";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ROUTES_PATH from "../../../../constants/routes";
import { BELL_RINGING } from "../../../../constants";
import { Close } from "@mui/icons-material";

function StartupOSCoPilot({ handleSave, saveLoading, saveDisabled = false }) {
	const [showModal, setshowModal] = useState(false);
	const [showReviewLoading, setshowReviewLoading] = useState(false);
	const [showError, setshowError] = useState(false);
	const [showReview, setshowReview] = useState(false);
	const [reviewResponse, setreviewResponse] = useState(null);
	const [showSaveLoading, setshowSaveLoading] = useState(false);
	const [showSaveSuggestionsLoading, setshowSaveSuggestionsLoading] = useState(false);
	const [checked, setChecked] = useState(null);
	const [userValues, setuserValues] = useState(null);
	const history = useHistory();

	const handleClickOpen = async () => {
		let response = await handleSave("ai_assist");
		if (response?.data) {
			setuserValues(response?.data);
			setshowModal(true);
		}
	};

	const handleClose = () => {
		handleSave("normal_save", userValues);
	};

	const cancelReview = () => {
		handleClose();
	};

	const handleSaveSuggestions = async () => {
		setshowSaveSuggestionsLoading(true);
		setshowError(false);
		setshowReview(false);
		setshowSaveLoading(false);
		setshowReviewLoading(false);

		try {
			const newObj = { ...userValues }; // create a copy of userValues
			Object.keys(checked).forEach((key) => {
				// loop through keys of obj
				if (checked[key].checked) {
					// check if the checked property is true
					newObj[key] = checked[key].value; // update the corresponding property in newObj
				}
			});
			// Prevents loading screen 'flash'; shows animation for min. 1s
			setTimeout(() => {
				handleSave("normal_save", newObj);
			}, 1000);
		} catch (e) {
			console.log(e);
			setshowSaveSuggestionsLoading(false);
			setshowReview(true);
		}
	};

	const handleReviewProcess = async () => {
		setshowError(false);
		setshowReview(false);
		setshowSaveLoading(false);
		setshowSaveSuggestionsLoading(false);
		setshowReviewLoading(true);
		const response = await startupProfileAI({
			websiteLink: userValues?.websiteLink,
			companyName: userValues?.companyName,
			about: userValues?.about,
			tagLine: userValues?.tagLine,
			offerType: userValues?.productOffering,
		});
		setreviewResponse(response);
		setshowReviewLoading(false);
		setshowReview(true);
	};

	return (
		<Fragment>
			<LoadingButton disabled={false} sx={{ width: "max-content" }} onClick={handleClickOpen} loading={saveLoading} loadingStyles={{ px: 7 }}>
				Save Changes
			</LoadingButton>
			<Dialog
				maxWidth={"lg"}
				open={showModal}
				onClose={handleClose}
				PaperProps={{
					sx: {
						borderRadius: "20px",
						p: 4,
					},
				}}
			>
				<DialogTitle sx={{ px: 0, pt: 0 }}>
					<Box
						sx={{
							display: "flex",
							flex: 1,
							justifyContent: "space-between",
						}}
					>
						<img src={BELL_RINGING} />
						<Close sx={{ cursor: "pointer" }} onClick={handleClose} />
					</Box>
				</DialogTitle>
				<DialogContent>
					{!showReviewLoading && !showError && !showReview && !showSaveLoading && !showSaveSuggestionsLoading && (
						<Box>
							<Typography variant={"Display/xs/Semibold"} component={"p"} sx={{ mb: 1 }}>
								StartupOS Bolt AI Review
							</Typography>
							<Typography variant={"Text/md/Regular"} component={"p"} sx={{ maxWidth: "456px" }}>
								We've saved your answers. Before we return to your profile, would you like a profile checkup?
								<br />
								<br />
								StartupOS Bolt AI can review your profile and suggest changes to ensure you are putting your best foot forward. Would you like
								Bolt to look over your startup profile? It will only take a few moments.
							</Typography>
						</Box>
					)}
					{showSaveSuggestionsLoading && !showError && !showReview && !showSaveLoading && (
						<ReviewLoading message={"Bolt AI is updating your profile. Please wait."} />
					)}
					{showReviewLoading && !showError && !showReview && !showSaveLoading && (
						<ReviewLoading message={"Bolt AI is reviewing your startup profile. Please wait."} />
					)}
					{showError && !showReviewLoading && !showReview && !showSaveLoading && <></>}
					{showReview && !showReviewLoading && !showError && !showSaveLoading && (
						<ReviewSummary response={reviewResponse} setChecked={setChecked} userValues={userValues} />
					)}
					{showSaveLoading && !showReviewLoading && !showError && !showReview && <></>}
				</DialogContent>
				<DialogActions sx={{ p: 0 }}>
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							justifyContent: "flex-end",
							gap: 1,
						}}
					>
						{showReviewLoading && !showError && !showReview && !showSaveLoading && (
							<Button onClick={cancelReview} variant="RoundedTextMuted" sx={{ minWidth: "unset", maxWidth: "unset", width: "auto" }}>
								Cancel Review
							</Button>
						)}
						{showError && !showReviewLoading && !showReview && !showSaveLoading && <></>}
						{showReview && !showReviewLoading && !showError && !showSaveLoading && (
							<Fragment>
								{checked ? (
									<Fragment>
										<Button onClick={handleClose} variant="RoundedTextMuted" sx={{ minWidth: "unset", maxWidth: "unset", width: "auto" }}>
											No Thanks
										</Button>

										<Button
											variant="DS1"
											onClick={handleSaveSuggestions}
											sx={{ minWidth: "unset", maxWidth: "unset", width: "auto" }}
											disabled={Object.values(checked)?.filter((obj) => obj?.checked === true)?.length == 0}
										>
											{Object.values(checked)?.filter((obj) => obj?.checked === true)?.length > 1
												? `Use ${Object.values(checked)?.filter((obj) => obj?.checked === true)?.length} Suggestions`
												: Object.values(checked)?.filter((obj) => obj?.checked === true)?.length == 0
												? "Use Suggestions"
												: "Use Suggestion"}
										</Button>
									</Fragment>
								) : (
									<Button onClick={handleClose} variant="DS1" sx={{ minWidth: "unset", maxWidth: "unset", width: "auto" }}>
										Close
									</Button>
								)}
							</Fragment>
						)}
						{showSaveLoading && !showReviewLoading && !showError && !showReview && <></>}
						{!showReviewLoading && !showError && !showReview && !showSaveLoading && !showSaveSuggestionsLoading && (
							<Fragment>
								<Button onClick={handleClose} variant="RoundedTextMuted" sx={{ minWidth: "unset", maxWidth: "unset", width: "auto" }}>
									No Thanks
								</Button>
								<Button variant={"PurpleRounded"} onClick={handleReviewProcess} sx={{ minWidth: "unset", maxWidth: "unset", width: "auto" }}>
									Let's do it!
								</Button>
							</Fragment>
						)}
					</Box>
				</DialogActions>
			</Dialog>
		</Fragment>
	);
}

export default StartupOSCoPilot;
