import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";

import { PowerPackContext } from "../PowerPackController";
import { Box, CircularProgress, Typography, useMediaQuery } from "@mui/material";
import { Card as PowerUpCard } from "../components/cards/Card";
import PowerupCourseCard from "../components/cards/PowerupCourseCard/PowerupCourseCard";
import { GLOBE_ICON_V1, LIGHTNING_ICON_V1, POWERPACK_DETAILS_DATA, PPT_ICON_V1 } from "../../../constants";
import TopMentorMatches from "../components/TopMentorMatches";
import { Harness } from "../../PowerUP/compositions/LearnIt/PitchDeckGenerator/md-pitchdeck-generator";
import { USER_TYPES } from "../../../utils/userTypes";

function PowerPackDetails() {
	const { userType } = useSelector((store) => store?.auth);
	const { powerUpList, packDetail, packId } = useContext(PowerPackContext);
	const [loadingValues, setloadingValues] = useState(false);
	const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
	return loadingValues ? (
		<CircularProgress />
	) : (
		<Box display={"flex"} gap={2} flexWrap={isSmallScreen && "wrap"}>
			<Box>
				<PowerUpCard>
					<Box display={"flex"} alignItems={"center"} gap={1}>
						<Box component={"img"} src={GLOBE_ICON_V1} alt="globe" />
						<Typography variant="Text/md/Semibold">PowerPack Overview</Typography>
					</Box>
					<Typography variant="Text/sm/Regular">{packDetail?.overview}</Typography>

					<Box display={"flex"} alignItems={"center"} sx={{ mt: 2 }} gap={1}>
						<Box component={"img"} src={LIGHTNING_ICON_V1} alt="lightning" />
						<Typography variant="Text/sm/Semibold">Complete the following PowerUp Courses</Typography>
					</Box>
					<Box display={"flex"} flexDirection={"column"} gap={4}>
						{powerUpList.map((item, idx) => {
							return (
								<Box>
									<PowerupCourseCard cardDetails={item} />
								</Box>
							);
						})}
					</Box>
				</PowerUpCard>
			</Box>
			<Box sx={{ maxWidth: isSmallScreen ? "auto" : "430px" }}>
				{POWERPACK_DETAILS_DATA.find((item) => item.id === packId) && (
					<PowerUpCard>
						<Box display={"flex"} alignItems={"center"} gap={1}>
							<Box component={"img"} src={PPT_ICON_V1} alt="ppt" />
							<Typography variant="Text/md/Semibold">{POWERPACK_DETAILS_DATA.find((item) => item.id === packId)?.mentorSectionTitle}</Typography>
						</Box>
						<Typography variant="Text/sm/Regular" component={"p"}>
							{POWERPACK_DETAILS_DATA.find((item) => item.id === packId)?.mentorSectionDescription}
						</Typography>

						{POWERPACK_DETAILS_DATA.find((item) => item.id === packId)?.showTopMentors && <TopMentorMatches />}
					</PowerUpCard>
				)}
				{(USER_TYPES.STARTUP_OWNER === userType || USER_TYPES.STARTUP_USER === userType) && (
					<Box mt={2}>
						<Harness />
					</Box>
				)}
			</Box>
		</Box>
	);
}

export default PowerPackDetails;
