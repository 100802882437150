import React, { useState, useEffect } from "react";
import { Select, MenuItem, Box, Typography } from "@mui/material";
import SortIcon from "@mui/icons-material/Sort";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";

const SelectDrop = ({ sortOptions, onSortChange, currentSort, label, description }) => {
	const [selectedSort, setSelectedSort] = useState("");
	const [isOpen, setIsOpen] = useState(false);

	useEffect(() => {
		if (currentSort) {
			setSelectedSort(currentSort);
		} else {
			setSelectedSort(null);
		}
	}, [currentSort]);

	const handleChange = (event) => {
		setSelectedSort(event?.target?.value);
		onSortChange(event?.target?.value);
	};

    const getValueLabel = (valueObj) => {
        return <Box sx={{ display: "flex", alignItems: "center" }}>
                <SortIcon sx={{ mr: 1.5 }} fontSize="small" />{" "}
                <Typography
                    variant="Text/sm/Regular"
                    sx={{
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        opacity: valueObj ? "1" : ".5"
                    }}
                >
                    {valueObj ? valueObj : label}
                </Typography>
            </Box>
           
    }

	return (
		<Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
			<Box>
				{label && (
					<Typography component={"p"} variant="Text/xs/Medium">
						{label}
					</Typography>
				)}
				{description && (
					<Typography component={"p"} variant="Text/xs/Regular" color={"#475467"}>
						{description}
					</Typography>
				)}
			</Box>
					<Select
			IconComponent={KeyboardArrowDownRoundedIcon}
			value={selectedSort}
			onChange={handleChange}
			open={isOpen}
			onOpen={() => setIsOpen(true)}
			onClose={() => setIsOpen(false)}
			renderValue={(selected) => {
				return (
					getValueLabel(selected)
				);
			}}
			displayEmpty
			sx={{
				width: "100%",
				"& .MuiSelect-select": { padding: "10px" },
				"& .MuiOutlinedInput-notchedOutline": {
					transition: "all .2s",
					border: "none",
					boxShadow: isOpen ? "0px 3px 11px rgba(0, 0, 0, 0.05)" : "0px 0px 0px 1px #D0D5DD, 0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
					borderRadius: isOpen ? "30px 30px 0px 0px" : "30px",
				},
			}}
			MenuProps={{
				PaperProps: {
					style: {
						boxShadow: "0px 11px 11px rgba(0, 0, 0, 0.05)",
						borderRadius: "0px 0px 30px 30px",
						mt: "-6px",
					},
				},
			}}
		>
			<MenuItem value="">
				<Typography
					variant="Text/sm/Regular"
					sx={{
						textOverflow: "ellipsis",
						whiteSpace: "nowrap",
						overflow: "hidden",
					}}
				>
					- Clear -
				</Typography>
			</MenuItem>
			{sortOptions?.map((option, index) => (
				<MenuItem key={`${option}+${index}`} value={option}>
					<Box sx={{ display: "flex", justifyContent: "space-between", flex: 1, alignItems: "center" }}>
						<Typography
							variant="Text/sm/Regular"
							sx={{
								textOverflow: "ellipsis",
								whiteSpace: "nowrap",
								overflow: "hidden",
							}}
						>
							{option}
						</Typography>
					</Box>
				</MenuItem>
			))}
		</Select>
		</Box>
	);
};

export default SelectDrop;
