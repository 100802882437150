import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";

import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Typography, Divider } from "@mui/material";
import {
  StyledTableCell,
  StyledTableRow,
} from "../RevenueModelerSummary/Components/SelfServiceSubscriptionsTable/styles";
import { REVENUE_MODELER_HEADERS } from "../../../../../../constants/revenue-modeler-types";

export default function ModelTypesTable(props) {
  return (
    <TableContainer
      sx={theme => ({
        borderRadius: 4,
        border: `1px solid ${theme.palette.secondary.borderLight}`,
        width: 1,
      })}
    >
      <Table sx={{ width: 1 }}>
        <TableHead>
          <TableRow>
            {REVENUE_MODELER_HEADERS.map((item, i) => {
              return (
                <StyledTableCell
                  align="center"
                  sx={{ width: "max-content", p: 1 }}
                >
                  <Typography variant="subhead1">{item}</Typography>
                </StyledTableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {props?.data.map((item, i) => {
            return (
              <StyledTableRow>
                <StyledTableCell>
                  <Typography variant="bodyLargeBold">
                    {item.revenueModel}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell variant="bodyLarge">
                  <Typography>{item.description}</Typography>
                </StyledTableCell>
                <StyledTableCell variant="bodyLarge">
                  <Typography>{item.pros}</Typography>
                </StyledTableCell>
                <StyledTableCell variant="bodyLarge">
                  <Typography>{item.cons}</Typography>
                </StyledTableCell>
                <StyledTableCell variant="bodyLarge">
                  <Typography>{item.exampleCompanies}</Typography>
                </StyledTableCell>
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
