import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getCustomerGridData } from "../Functions/common";
import CustomerChartContainer from "../Charts/CustomerChatContainer";
import CustomersDataGrid from "../DataGrid/CustomersGrid";
import { handleMixPanel } from "../../../../utils/mixPanelEventHandling";
import { setBIDashboardData } from "../../../../modules/actions/biDashboardActions";
import BIDataGridDialog from "../DataGrid/BIDataGridDialog";

function Customers({ companyId, readOnly }) {
	const dispatch = useDispatch();
	const customers = useSelector((store) => store?.biDashboard?.customers);
	const loadingCustomers = useSelector((store) => store?.biDashboard?.loadingCustomers);
	const openCustomers = useSelector((store) => store?.biDashboard?.openCustomers);
	const [isEdited, setEdited] = useState(false);

	const gridData = customers?.data ? customers?.data : [];

	const ownCompanyId = useSelector((state) => state?.auth?.companyId);
	const usedCompanyId = companyId ? companyId : ownCompanyId;

	const fetchData = async () => {
		try {
			dispatch(setBIDashboardData({ loadingCustomers: true }));
			const responseData = await getCustomerGridData(usedCompanyId);
			if (responseData) {
				dispatch(setBIDashboardData({ customers: responseData }));
			}
			dispatch(setBIDashboardData({ loadingCustomers: false }));
		} catch (error) {
			dispatch(setBIDashboardData({ loadingCustomers: false }));
			console.log("error", error);
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	const onChartClick = () => {
		handleMixPanel("Grid Customers Viewed");
		dispatch(setBIDashboardData({ openCustomers: true }));
	};

	const handleClose = useCallback(() => {
		if (isEdited) {
			dispatch(
				setBIDashboardData({
					openCustomersLeave: true,
				})
			);
		} else {
			dispatch(setBIDashboardData({ openCustomers: false }));
		}
	}, [isEdited]);

	return (
		<>
			<CustomerChartContainer data={customers} onChartClick={onChartClick} loading={loadingCustomers} readOnly={readOnly} />
			<BIDataGridDialog title="Customers" isOpen={openCustomers} onClose={handleClose}>
				<CustomersDataGrid gridData={gridData} readOnly={readOnly} usedCompanyId={usedCompanyId} isEdited={isEdited} setEdited={setEdited} />
			</BIDataGridDialog>
		</>
	);
}

export default Customers;
