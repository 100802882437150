import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import PersonaCard from "./PersonaCard";
import NoDataPill from "./NoDataPill";
import { FormatListBulletedOutlined } from "@mui/icons-material";

export default function Job({ persona }) {
	const [personaData, setpersonaData] = useState(persona);

	useEffect(() => {
		setpersonaData(persona);
	}, [persona]);

	const ONTHEJOB = [
		{ title: "Job Responsibilities", desc: personaData?.jobResponsibilities?.join(", ") },
		{ title: "Tools Needed for JTBD", desc: personaData?.tools?.join(", ") },
		{ title: "What They Do to Add Skills to Their Job", desc: personaData?.jobActivity?.join(", ") },
	];

	return (
		<PersonaCard title="On the Job" icon={<FormatListBulletedOutlined fontSize="large" />}>
			<Box mt={"30px"}>
				{ONTHEJOB?.map((item) => (
					<Box key={item.title} mb="30px">
						<Typography variant="title_large2" sx={{ fontWeight: 600, display: "block" }}>
							{item.title}
						</Typography>
						{item.desc ? <Typography variant="card_text">{item.desc}</Typography> : <NoDataPill />}
					</Box>
				))}
			</Box>
		</PersonaCard>
	);
}
