import React from "react";
import { Box, Button, useTheme, Select, MenuItem, InputLabel, Typography } from "@mui/material";
import { useGridApiContext } from "@mui/x-data-grid";

export const OpportunityTablePaginationUI = ({ setPage, setPageSize, pageSizeOptions = [], pageSize = 10, borderNone = false, result = {} }) => {
	const apiRef = useGridApiContext();

	const paginationState = apiRef.current.state.pagination.paginationModel;
	const totalRowCount = apiRef.current.state.rows.totalRowCount;
	const pageCount = Math.ceil(totalRowCount / paginationState.pageSize);
	const currentPage = paginationState.page;
	const isFirst = result?.isFirst;
	const isLast = result?.isLast;

	if (pageCount < pageSize) {
		pageSizeOptions = [10];
		pageSize = 10;
	}

	const handleChangePage = (event, value) => {
		apiRef.current.setPage(value);
		setPage(value);
	};

	const handleChangePageSize = (e) => {
		const newPageSize = parseInt(e.target.value);

		const newPageCount = Math.ceil(totalRowCount / newPageSize);

		const newLastPage = Math.max(0, newPageCount - 1);

		if (currentPage > newLastPage) {
			apiRef.current.setPage(0);
			setPage(0);
		} else {
			setPage(currentPage);
		}

		setPageSize(newPageSize);

		if (newPageCount < pageSize) {
			pageSizeOptions = [10];
			pageSize = 10;
		}
		pages = getPaginationRange(newPageCount, currentPage);
	};

	const getPaginationRange = () => {
		const totalPages = Math.ceil(totalRowCount / pageSize);
		const current = currentPage;
		const siblingCount = 1;
		const totalDisplayedPages = 6;

		let range = [];

		if (totalPages <= totalDisplayedPages) {
			for (let i = 1; i <= totalPages; i++) {
				range.push(i);
			}
			return range;
		}

		let left = Math.max(2, current + 1 - siblingCount);
		let right = Math.min(totalPages - 1, current + 1 + siblingCount);

		range.push(1);

		if (left > 2) {
			range.push("ellipsis");
		}

		for (let i = left; i <= right; i++) {
			range.push(i);
		}

		if (right < totalPages - 1) {
			range.push("ellipsis");
		}

		range.push(totalPages);

		return range;
	};

	const pages = getPaginationRange();

	return (
		<Box sx={{ display: "flex", alignItems: "center", flex: 1, justifyContent: "space-between" }}>
			<Button
				onClick={() => handleChangePage(null, currentPage - 1)}
				disabled={isFirst}
				small={true}
				variant="DS1"
				color="gray"
				startIcon={
					<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
						<path
							d="M15.8334 9.99996H4.16675M4.16675 9.99996L10.0001 15.8333M4.16675 9.99996L10.0001 4.16663"
							stroke="#344054"
							stroke-width="1.66667"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
					</svg>
				}
			>
				Previous
			</Button>

			<Box sx={{ display: "flex", alignItems: "center", gap: "2px" }}>
				{pageSizeOptions.length > 0 && (
					<Box sx={{ display: "flex", alignItems: "center", mr: "20px" }}>
						<InputLabel id="page-size">
							<Typography variant="Text/xs/Medium">Rows per page : </Typography>
						</InputLabel>

						<Select
							labelId="page-size"
							id="demo-simple-select"
							label="pageSize"
							value={pageSize}
							onChange={handleChangePageSize}
							sx={{
								"& .MuiOutlinedInput-notchedOutline": {
									border: borderNone && "none",
								},
							}}
						>
							{pageSizeOptions.map((value) => {
								return (
									<MenuItem value={value}>
										<Typography variant="Text/xs/Medium">{value}</Typography>
									</MenuItem>
								);
							})}
						</Select>
					</Box>
				)}

				{pages.map((page, index) => {
					if (page === "ellipsis") {
						return (
							<div key={index} style={{ alignSelf: "center" }}>
								…
							</div>
						);
					}

					return (
						<Button
							key={`${page}_${index}`}
							onClick={() => handleChangePage(null, page - 1)}
							small={true}
							variant="DS1"
							iconButton={true}
							color={currentPage === page - 1 ? "gray" : "clear"}
							sx={{
								height: "40px",
								width: "40px",
							}}
						>
							{page}
						</Button>
					);
				})}
			</Box>
			<Button
				onClick={() => handleChangePage(null, currentPage + 1)}
				disabled={isLast}
				small={true}
				variant="DS1"
				color="gray"
				endIcon={
					<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
						<path
							d="M4.16675 9.99996H15.8334M15.8334 9.99996L10.0001 4.16663M15.8334 9.99996L10.0001 15.8333"
							stroke="#344054"
							stroke-width="1.66667"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
					</svg>
				}
			>
				Next
			</Button>
		</Box>
	);
};
