import React from "react";
import { Box, Typography } from "@mui/material";
import { EDIT_ICON_V2, TRASH_ICON } from "../../constants";

const EducationDisplayCard = ({ name, university, item, handleDelete, handleEdit }) => {
	const formatDateRange = (dateRange) => {
		const formattedStartDate = `${dateRange.startYear}`;
		const formattedEndDate = `${dateRange?.endYear}`;

		return `${formattedStartDate} - ${formattedEndDate}`;
	};

	return (
		<Box
			sx={{
				display: "flex",
				gap: 1,
			}}
		>
			<Box
				sx={{
					display: "flex",
					gap: 1,
					alignItems: "baseline",
				}}
			>
				<Box
					component={"img"}
					sx={{ cursor: "pointer" }}
					src={EDIT_ICON_V2}
					alt={""}
					onClick={() => {
						handleEdit(item);
					}}
				/>
				<Box
					component={"img"}
					sx={{ cursor: "pointer" }}
					src={TRASH_ICON}
					alt={""}
					onClick={() => {
						handleDelete(item.id);
					}}
				/>
			</Box>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					gap: 1,
				}}
			>
				<Typography variant="Text/sm/Semibold">{name ? name : "No Data"}</Typography>
				<Typography variant="Text/sm/Regular">{university ? university : "No Data"}</Typography>{" "}
				<Typography variant="Text/sm/Regular">
					{formatDateRange({
						endYear: item?.endYear ? item?.endYear : "",
						startYear: item?.startYear ? item?.startYear : "",
					})}
				</Typography>
			</Box>
		</Box>
	);
};

export default EducationDisplayCard;
