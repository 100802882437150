import { Box, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import OnboardingCardSelector from "../../../../components/Onboarding/OnboardingCardSelector";
import PageTitle from "../../../../components/Onboarding/PageTitle";
import { WHAT_IS_INVESTMENT_RANGE } from "../../../../constants/updated-onboarding";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

export default function WhatIsInvestmentRange({ setComplete }) {
  const history = useHistory();
  const [userResponse, setUserResponse] = useState("");
  const savedData = useSelector(state => state?.onboarding?.investmentRange);

  useEffect(() => {
    if (savedData) {
      setUserResponse(savedData);
    }
  }, [savedData]);

  const callComplete = (complete, data) => {
    if (setComplete) {
      setComplete(complete, data);
    }
  };

  useEffect(() => {
    if (userResponse) {
      callComplete(1, { investmentRange: userResponse });
    } else {
      callComplete(0, { investmentRange: null });
    }
  }, [userResponse]);

  return (
    <Box>
      <PageTitle title={"What is your typical investment range?"} />
      <Box sx={{ my: 5 }}>
        <OnboardingCardSelector
          cardItems={WHAT_IS_INVESTMENT_RANGE}
          flex={1 / 2}
          gridColWidth={5}
          setUserResponse={setUserResponse}
          userResponse={userResponse}
          savedData={savedData}
        />
      </Box>
    </Box>
  );
}
