import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { DialogTitle, Dialog, IconButton, Typography, DialogContent, DialogActions, Slide, Box, Button } from "@mui/material";
import theme from "../../constants/Theme";
import { setCommonDialogConfirmationData } from "../../modules/actions";
import LoadingButton from "../Loadingbutton";
import { CLOSE_ICON_V2 } from "../../constants";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="left" ref={ref} {...props} />;
});

export default function UpdatedDialogConfirmation() {
	const commonDialogConfirmationData = useSelector((state) => state?.commonDialogConfirmation);
	const OPEN = commonDialogConfirmationData.isOpen;
	const dispatch = useDispatch();
	const disableBackDropClick = true;
	const title = commonDialogConfirmationData?.title;
	const subTitle = commonDialogConfirmationData?.subTitle;
	const text = commonDialogConfirmationData?.text ? commonDialogConfirmationData?.text : "";

	const primaryButtonText = commonDialogConfirmationData?.primaryButtonText;
	const secondaryButtonText = commonDialogConfirmationData?.secondaryButtonText;
	const primaryButtonAction = commonDialogConfirmationData?.primaryButtonAction;
	const primaryButtonVariant = commonDialogConfirmationData?.primaryButtonVariant;
	const secondaryButtonAction = commonDialogConfirmationData?.secondaryButtonAction;
	const secondaryButtonVariant = commonDialogConfirmationData?.secondaryButtonVariant;
	const primaryButtonLoading = commonDialogConfirmationData?.primaryButtonLoading;
	const titleIcon = commonDialogConfirmationData?.titleIcon;

	const transitionDuration = 600;

	const handleClose = () => {
		dispatch(setCommonDialogConfirmationData({ text: "", title: "", isOpen: false }));
	};

	const FooterComponent = () => {
		return (
			<>
				{
					<Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
						{secondaryButtonText && (
							<Box>
								<Button variant={secondaryButtonVariant} onClick={secondaryButtonAction} sx={{ width: "max-content", whiteSpace: "nowrap" }}>
									{secondaryButtonText}
								</Button>
							</Box>
						)}

						<LoadingButton
							loadingStyles={{
								height: "60px",
								px: 4,
							}}
							loading={primaryButtonLoading}
							variant={primaryButtonVariant}
							sx={{ mr: 1 }}
							onClick={primaryButtonAction}
						>
							{primaryButtonText}
						</LoadingButton>
					</Box>
				}
			</>
		);
	};
	return (
		<Dialog
			TransitionComponent={Transition}
			transitionDuration={transitionDuration}
			aria-describedby="alert-dialog-slide-description"
			keepMounted
			open={OPEN}
			onClose={disableBackDropClick ? () => {} : handleClose}
			PaperProps={{
				sx: (theme) => ({
					borderRadius: theme.shape.standard13.borderRadius,
					boxShadow: "0px 5px 16px rgba(0, 0, 0, 0.3)",
					p: 3,
				}),
			}}
		>
			{title && (
				<DialogTitle
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: titleIcon ? "baseline" : "center",
					}}
				>
					<Box display={"flex"} gap={2} flexDirection={"column"}>
						{titleIcon && <Box component={"img"} height={"32px"} width={"32px"} src={titleIcon} alt="back" />}
						<Typography variant="Text/md/Semibold">{title}</Typography>
					</Box>

					{
						<IconButton
							aria-label="close"
							onClick={handleClose}
							sx={{
								color: theme.palette.secondary.dark,
							}}
						>
							<Box component={"img"} src={CLOSE_ICON_V2} alt="back" />
						</IconButton>
					}
				</DialogTitle>
			)}
			<DialogContent>
				<Box sx={{ pt: 2 }}>
					<Typography variant="Text/sm/Regular">{subTitle}</Typography>
				</Box>
				<Box sx={{ pt: 2 }}>
					<Typography sx={{ color: "rgba(102, 112, 133, 1)" }} variant="Text/sm/Regular">
						{text}
					</Typography>
				</Box>
			</DialogContent>
			{
				<DialogActions>
					<FooterComponent />
				</DialogActions>
			}
		</Dialog>
	);
}
