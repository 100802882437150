import React from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import { FUNDING_STAGES } from "../../../constants";
import CommonSlideDialog from "../../../common/CommonSlideDialog";
import { setInvestorDashboardData } from "../../../modules/actions";
import { useDispatch, useSelector } from "react-redux";

export default function FundingStagesDialog() {
	const investorDashboardData = useSelector((state) => state?.investorDashboard);
	const dispatch = useDispatch();

	const handleClose = () => {
		dispatch(
			setInvestorDashboardData({
				fundingStagesOpen: false,
			})
		);
	};

	return (
		<CommonSlideDialog
			open={investorDashboardData?.fundingStagesOpen}
			onClose={handleClose}
			title="Stages of Funding"
			titleVariant="poppinsSemiBold30"
			fullWidth
			PaperProps={{ sx: { position: "fixed", top: 0, right: 0, m: 0, px: 4.5, py: 2, borderRadius: "30px", maxHeight: "100vh", maxWidth: "540px" } }}
			contentStyle={{ mt: 3.5 }}
			dialogAction={
				<Box display="flex" sx={{ pr: 3 }}>
					<Button
						variant="containedNewStyle"
						onClick={() => {
							handleClose();
						}}
					>
						Close
					</Button>
				</Box>
			}
		>
			<Box>
				{FUNDING_STAGES.map((fundingStage) => (
					<Stack key={fundingStage.id} direction="column" sx={{ mb: "20px" }}>
						<Stack direction="column" sx={{ gap: "10px" }}>
							<Typography variant="poppinsSemiBold20" sx={{ lineHeight: "32px" }}>
								{fundingStage.name}
							</Typography>
							<Typography variant="sub_title_portfolio">{fundingStage.description}</Typography>
						</Stack>
					</Stack>
				))}
			</Box>
		</CommonSlideDialog>
	);
}
