import React from "react";
import { Box, Button, Typography, Divider } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

export default function About({ title, desc, previewUrl }) {
  const handlePreviewSurvey = () => {
    window.open(previewUrl, "_blank");
  };

  return (
    <Box>
      <Typography
        variant="title_large"
        sx={{ fontWeight: 600, color: "#464B4E" }}
      >
        {title}
      </Typography>
      <Divider sx={{ borderColor: "secondary.light", my: 4 }} />
      <Typography variant="body_large" sx={{ color: "#6A7575" }}>
      <span dangerouslySetInnerHTML={{__html: desc}} />
      </Typography>
      <Box display="flex">
        <Box sx={{ ml: "auto" }} mt={4}>
          <Button
            sx={{ width: 1 }}
            size="small"
            startIcon={<OpenInNewIcon />}
            onClick={handlePreviewSurvey}
          >
            Preview Survey
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
