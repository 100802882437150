import { Svg } from "./Svg";

export const Help5Icon = ({ className, fill = "#667085", width = 30, height = 25 }) => (
	<Svg className={className} width={width} height={height} viewBox="0 0 30 25" fill="none">
		<path
			d="M10.9998 12.9629L16.0368 17.9999M9.63297 25.9664C7.81052 27.7889 4.33317 27.3333 1.6665 27.3333C3.0333 24.6666 1.21086 21.1892 3.0333 19.3668C4.85575 17.5443 7.81052 17.5443 9.63297 19.3668C11.4554 21.1892 11.4554 24.144 9.63297 25.9664ZM14.8953 19.2328L27.0781 6.07533C28.1512 4.91645 28.1166 3.11673 26.9998 1.99994C25.883 0.883155 24.0833 0.848555 22.9244 1.9216L9.76695 14.1044C9.08704 14.734 8.74708 15.0487 8.54881 15.3844C8.07336 16.1895 8.05423 17.1847 8.49838 18.0074C8.68361 18.3505 9.01122 18.6781 9.66643 19.3333C10.3216 19.9885 10.6493 20.3161 10.9923 20.5013C11.815 20.9455 12.8103 20.9263 13.6153 20.4509C13.951 20.2526 14.2658 19.9127 14.8953 19.2328Z"
			stroke={fill}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</Svg>
);
