import React from "react";
import { Box, Typography } from "@mui/material";
import { EDIT_ICON_V2, TRASH_ICON } from "../../constants";

const ExperienceDisplayCard = ({ name, companyName, item, handleDelete, handleEdit }) => {
	const formatDateRange = (dateRange) => {
		const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
		const startDate = new Date(dateRange.startYear, dateRange.startMonth - 1);
		const endDate = dateRange?.endMonth && dateRange?.endYear ? new Date(dateRange.endYear, dateRange.endMonth - 1) : null;
		const formattedStartDate = `${months[startDate.getMonth()]} ${startDate.getFullYear()}`;
		const formattedEndDate = endDate ? `${months[endDate.getMonth()]} ${endDate.getFullYear()}` : "";
		return `${dateRange.startYear && dateRange.startMonth ? formattedStartDate : "No Data"} - ${endDate ? formattedEndDate : "Current"}`;
	};

	return (
		<Box
			sx={{
				display: "flex",
				gap: 1,
			}}
		>
			<Box
				sx={{
					display: "flex",
					gap: 1,
					alignItems: "baseline",
				}}
			>
				<Box
					component={"img"}
					src={EDIT_ICON_V2}
					sx={{ cursor: "pointer" }}
					alt={"edit"}
					onClick={() => {
						handleEdit(item);
					}}
				/>
				<Box
					component={"img"}
					src={TRASH_ICON}
					sx={{ cursor: "pointer" }}
					alt={"delete"}
					onClick={() => {
						handleDelete(item.id);
					}}
				/>
			</Box>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					gap: 1,
				}}
			>
				<Typography variant="Text/sm/Semibold">{name ? name : "No Data"}</Typography>
				<Typography variant="Text/sm/Regular">{companyName ? companyName : "No Data"}</Typography>
				<Typography variant="Text/sm/Regular">
					{formatDateRange({
						endMonth: item?.endMonth ? item?.endMonth : "",
						endYear: item?.endYear ? item?.endYear : "",
						startMonth: item?.startMonth ? item?.startMonth : "",
						startYear: item?.startYear ? item?.startYear : "",
					})}
				</Typography>
			</Box>
		</Box>
	);
};

export default ExperienceDisplayCard;
