import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";

import PowerUps from "../../stores/powerups";

// components
import {
	Table,
	TableContainer,
	TableBody,
	TableHead,
	TableRow,
	TableCell,
	Box,
	Typography,
	Button,
	TextField,
	Stack,
	Rating,
	FormControl,
	InputLabel,
	Select,
	Checkbox,
	MenuItem,
	ListItemText,
} from "@mui/material";
import { Search, Add, ChevronRight, ArrowUpward, ArrowDownward } from "@mui/icons-material";
import DashboardIcon from "@mui/icons-material/Dashboard";

import { PageContentWrapper } from "../../../../components/Workspace/PageContentWrapper";

import ROUTES_PATH from "../../../../constants/routes";

function BodyRow({ id, title, created, updated, submissions, rating }) {
	return (
		<TableRow>
			<TableCell>
				<Link to={ROUTES_PATH.PARTNER_POWERUP.replace(":id", id)} style={{ textDecoration: "none" }}>
					<Typography variant="h4" color={"primary"}>
						{title}
					</Typography>
				</Link>
			</TableCell>
			<TableCell>
				<Typography variant="body1">{new Date(created?.at).toLocaleString()}</Typography>
			</TableCell>
			<TableCell>
				<Typography variant="body1">{new Date(updated?.at).toLocaleString()}</Typography>
			</TableCell>
			<TableCell>
				<Typography variant="body1">{Number(submissions).toLocaleString("en", { useGrouping: true })}</Typography>
			</TableCell>
			<TableCell>
				<Rating name="read-only" value={rating} readOnly precision={0.5} />
			</TableCell>
			<TableCell>
				<Link to={ROUTES_PATH.PARTNER_POWERUP.replace(":id", id)}>
					<Button startIcon={<ChevronRight />}>View</Button>
				</Link>
			</TableCell>
		</TableRow>
	);
}

export default function PowerUpList() {
	const history = useHistory();
	const powerUps = useContext(PowerUps.Context);
	const powerups = powerUps.powerUps;

	useEffect(() => {
		powerUps.list({
			field: powerups?.sort?.field,
			order: powerups?.sort?.order,
			states: powerups?.states?.length ? powerups?.states : ["draft", "published"],
			title: powerups?.title,
		});
	}, []);

	const onTitleChange = (title) => {
		powerUps.list({
			field: powerups?.sort?.field,
			order: powerups?.sort?.order,
			states: powerUps?.states,
			title,
		});
	};

	const onStateClick = (state) => {
		powerUps.list({
			field: powerups?.sort?.field,
			order: powerups?.sort?.order,
			states: [...(powerups?.states?.filter((s) => s !== state) ?? []), !powerups?.states?.includes(state) ? state : undefined].filter((s) => s),
			title: powerups?.title,
		});
	};

	const onSortClick = ({ field, order }) => {
		powerUps.list({ field, order, states: powerups?.states, title: powerups?.title });
	};

	const onAddPowerUp = () => {
		powerUps
			.create({
				title: "New PowerUp",
			})
			.then(({ id }) => {
				powerUps.list({ field: powerups?.sort?.field, order: powerups?.sort?.order, states: powerups?.states, title: powerups?.title });
				history.push(ROUTES_PATH.PARTNER_POWERUP.replace(":id", id));
			});
	};

	return (
		<>
			<Helmet>
				<meta charSet="utf-8" />
				<title>StartupOS - PowerUp Builder</title>
			</Helmet>

			<Box
				sx={{
					px: { lg: 8, md: 4, xs: 2 },
					pb: "30px",
					display: "flex",
					flexDirection: "column",
					gap: "32px",
					margin: "0 auto",
					maxWidth: "1920px", // Figma specifies max content width
				}}
			>
				<Box sx={{ display: "flex", alignItems: "center", gap: "12px" }}>
					<DashboardIcon sx={{ width: "30px", height: "30px" }} style={{ fill: "white", stroke: "black" }} />
					<Typography variant="growth_card_subtitle" color="secondary.black">
						PowerUps
					</Typography>
				</Box>
				<PageContentWrapper paddingBottom={0} marginTop="0px">
					<Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
						<Box></Box>
						{/* <Typography variant="title_xlarge">PowerUps</Typography> */}
						<Box sx={{ alignItems: "center", display: "flex" }}>
							{/*<IconButton component="button" color="primary" aria-label="grid view"><GridView/></IconButton>*/}
							<Button sx={{ width: "max-content", ml: 2 }} startIcon={<Add />} onClick={onAddPowerUp}>
								New PowerUp
							</Button>
						</Box>
					</Box>
					<Stack direction={"row"} alignItems={"stretch"} gap={2} mt={1} mb={3}>
						<TextField
							sx={{ flex: "2 1 20rem", boxSizing: "border-box", height: "59px" }}
							InputProps={{ startAdornment: <Search /> }}
							spellCheck="true"
							fullWidth
							value={powerups?.title ?? ""}
							onChange={(e) => onTitleChange(e.target.value)}
						/>
						{/*<SortSelect/>*/}
						{/*<FilterSelect/>*/}
						<FormControl sx={{ flex: "1 2 12rem" }}>
							<InputLabel>Status</InputLabel>
							<Select label="Status">
								{/*TODO: This should have the attribute 'multiple' on it, but it doesn't like that: https://mui.com/material-ui/react-select/#MultipleSelect.js*/}
								<MenuItem onClick={() => onStateClick("published")}>
									<Checkbox checked={powerups?.states?.includes("published")} />
									<ListItemText primary={"Published"} />
								</MenuItem>
								<MenuItem onClick={() => onStateClick("draft")}>
									<Checkbox checked={powerups?.states?.includes("draft")} />
									<ListItemText primary={"Draft"} />
								</MenuItem>
								<MenuItem onClick={() => onStateClick("archived")}>
									<Checkbox checked={powerups?.states?.includes("archived")} />
									<ListItemText primary={"Archived"} />
								</MenuItem>
							</Select>
						</FormControl>
					</Stack>
					<TableContainer>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell onClick={() => onSortClick({ field: "title", order: !powerups?.sort?.order })}>
										<Stack direction="row" gap={1} alignItems="center">
											<Typography variant="title_small">Title</Typography>
											{powerups?.sort?.field === "title" && (powerups?.sort?.order ? <ArrowDownward /> : <ArrowUpward />)}
										</Stack>
									</TableCell>
									<TableCell onClick={() => onSortClick({ field: "created", order: !powerups?.sort?.order })}>
										<Stack direction="row" gap={1} alignItems="center">
											<Typography variant="title_small">Created</Typography>
											{powerups?.sort?.field === "created" && (powerups?.sort?.order ? <ArrowDownward /> : <ArrowUpward />)}
										</Stack>
									</TableCell>
									<TableCell onClick={() => onSortClick({ field: "updated", order: !powerups?.sort?.order })}>
										<Stack direction="row" gap={1} alignItems="center">
											<Typography variant="title_small">Last Modified</Typography>
											{powerups?.sort?.field === "updated" && (powerups?.sort?.order ? <ArrowDownward /> : <ArrowUpward />)}
										</Stack>
									</TableCell>
									<TableCell>
										<Stack direction="row" gap={1} alignItems="center">
											<Typography variant="title_small">Submissions</Typography>
											{powerups?.sort?.field === "submissions" && (powerups?.sort?.order ? <ArrowDownward /> : <ArrowUpward />)}
										</Stack>
									</TableCell>
									<TableCell>
										<Stack direction="row" gap={1} alignItems="center">
											<Typography variant="title_small">Rating</Typography>
											{powerups?.sort?.field === "reviews" && (powerups?.sort?.order ? <ArrowDownward /> : <ArrowUpward />)}
										</Stack>
									</TableCell>
									<TableCell />
								</TableRow>
							</TableHead>
							<TableBody>
								{powerups?.data.map((powerup) => (
									<BodyRow key={powerup.id} {...powerup} />
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</PageContentWrapper>
			</Box>
		</>
	);
}
