import services from "../../../services";

export async function getFounderDetails(id) {
	const founderObj = await services
		.getCandidateDetails(id)
		.then((result) => {
			if (result.data.code === 200) {
				return result.data?.data;
			}
		})
		.catch((err) => {
			throw err;
		});

	return founderObj;
}
