import services from "../../../../services";

export const getStartupListApiCall = (
	companiesPageIndex,
	perPage,
	searchText,
	sort,
	filter,
	collectionType,
	fundingRaisedMin,
	fundingRaisedMax,
	foundedStartYear,
	foundedEndYear,
	stages,
	financings,
	teamMemberTags,
	industries,
	states,
	foundedYearTrailingRange,
	productOffering,
	businessModel,
	revenueModel,
	incorporatedStatus,
	pitchDeckAvailable,
	isPremiumPlanAccess,
	askMin,
	askMax,
	cohorts,
	startupsJoined,
	minScore,
	maxScore,
	minMrr,
	maxMrr,
	minRunway,
	maxRunway
) => {
	return services
		.getCompanyList(
			companiesPageIndex,
			perPage,
			searchText,
			sort,
			filter,
			collectionType,
			fundingRaisedMin,
			fundingRaisedMax,
			foundedStartYear,
			foundedEndYear,
			stages,
			financings,
			teamMemberTags,
			industries,
			states,
			foundedYearTrailingRange,
			productOffering,
			businessModel,
			revenueModel,
			incorporatedStatus,
			pitchDeckAvailable,
			isPremiumPlanAccess,
			askMin,
			askMax,
			cohorts,
			startupsJoined,
			minScore,
			maxScore,
			minMrr,
			maxMrr,
			minRunway,
			maxRunway
		)
		.then((result) => {
			return result;
		})
		.catch((error) => {
			return error;
		});
};

export const addCompanyToWatchlistOrPortfolioApiCall = (companyId, type, userId) => {
	let payload = {
		companyId: companyId,
		userId: userId,
		status: true,
		type: type,
	};
	return services
		.addCompanyToWatchlistOrPorfolio(payload)
		.then((result) => {
			return result;
		})
		.catch((error) => {
			return error;
		});
};

export const removeCompanyToWatchlistOrPortfolioApiCall = (type, companyId) => {
	return services
		.removeCompanyToWatchlistOrPorfolio(type, companyId)
		.then((result) => {
			return result;
		})
		.catch((error) => {
			return error;
		});
};

export const bulkWatchlistOrPorfolioCollectionActionApiCall = (companyIds, action, type) => {
	let payload = {
		companyIds: companyIds,
		action: action,
		type: type,
	};
	return services
		.bulkWatchlistOrPorfolioCollectionActionApi(payload)
		.then((result) => {
			return result;
		})
		.catch((error) => {
			return error;
		});
};

export const createSavedFilterApiCall = (payload) => {
	return services
		.createSavedFilterApi(payload)
		.then((result) => {
			return result;
		})
		.catch((error) => {
			return error;
		});
};

export const getListOfSavedFilterApiCall = () => {
	return services
		.getListOfSavedFilterApi()
		.then((result) => {
			return result;
		})
		.catch((error) => {
			return error;
		});
};

export const updateSavedFilterApiCall = (payload) => {
	return services
		.updateSavedFilterApi(payload)
		.then((result) => {
			return result;
		})
		.catch((error) => {
			return error;
		});
};

export const deleteSavedFilterApiCall = (id) => {
	return services
		.deleteSavedFilterApi(id)
		.then((result) => {
			return result;
		})
		.catch((error) => {
			return error;
		});
};

export const getListOfCountryStateApiCall = () => {
	return services
		.getListOfCountryStateApi()
		.then((result) => {
			return result;
		})
		.catch((error) => {
			return error;
		});
};

export const getListOfCategoryApiCall = (category) => {
	return services
		.getListOfCategoryApi(category)
		.then((result) => {
			return result;
		})
		.catch((error) => {
			return error;
		});
};

export const addOrRemoveCompanyToPortfolioOrWatchlist = ({ entityIds, collectionType, entityType = "COMPANY", isRemove = false }) => {
	return services
		.updateCollections({ entityIds, collectionType, entityType }, isRemove)
		.then((result) => {
			return result.data;
		})
		.catch((error) => {
			return error;
		});
};

export const addOrRemoveCompanyToTrackSection = async ({ trackedCompanyIds, isRemove = false }) => {
	return await services
		.trackCompanyApi({ trackedCompanyIds }, isRemove)
		.then((res) => {
			return res.data;
		})
		.catch((error) => {
			return error;
		});
};

export function findStartupsJoinedNumber(arr) {
	let largest = arr[0];
	for (let i = 1; i < arr.length; i++) {
		if (arr[i] > largest) {
			largest = arr[i];
		}
	}
	return largest.split(" ")[0];
}
