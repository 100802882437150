import styled from "@emotion/styled";
import { Box, TextField } from "@mui/material";
import theme from "../../../../../constants/Theme";
import sosPrimary from "../../../../../constants/Theme";

export const GraphWrapper = styled(Box)({
  display: "flex",
  width: "100%",
  flexDirection: "row",
  height: "300px",
});

export const SelectWrapper = styled(Box)({
  "& .MuiOutlinedInput-root": {
    border: "none",
    paddingLeft: "0px",
    borderColor: theme.palette.secondary.dark,
  },

  "& label.Mui-focused": {
    color: theme.palette.secondary.black,
  },

  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: `1px solid ${theme.palette.secondary.textFieldBorder}`,
  },
  "& .MuiInputBase-input": {
    borderRadius: "0px !important",
  },
});

export const ToolTipWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),

  padding: theme.spacing(2),
  borderRadius: theme.spacing(2),
});

export const ToolTipBody = styled(Box)({});

export const DateTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-input": {
    cursor: "pointer",
  },
  "& .MuiOutlinedInput-root": {
    color: theme.palette.secondary.black,
    fontSize: 12,
    fontFamily: "RobotoRegular",
    fontWeight: 400,
    borderColor: theme.palette.secondary.dark,
  },

  "& .MuiSvgIcon-root": {
    paddingRight: theme.spacing(1),
  },
  "& .MuiFormLabel-root.MuiInputLabel-root": {
    fontFamily: "RobotoRegular",
    fontSize: "12px",
    lineHeight: "22px",
  },
}));

export const SubscriptionTextField = styled(TextField)(({ theme }) => ({
  "& label.Mui-focused": {
    color: theme.palette.secondary.black,
  },
  "& .MuiInput-underline:after": {
    border: `1px solid ${theme.palette.secondary.dark}`,
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: `1px solid ${theme.palette.secondary.dark}`,
    },
    "&:hover fieldset": {
      border: `1px solid ${theme.palette.secondary.dark}`,
    },
    "&.Mui-focused fieldset": {
      border: `1px solid ${theme.palette.secondary.dark}`,
    },
  },
}));
