import React, { useEffect } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import { getCompanyDetails } from "../../Functions/common";
import { useSelector, useDispatch } from "react-redux";
import { setBIDashboardData } from "../../../../../modules/actions/biDashboardActions";
import { useLocation } from "react-router-dom";

function Headcount() {
	const dispatch = useDispatch();
	const location = useLocation();
	const headCount = useSelector((store) => store?.biDashboard?.headCount);
	const loadingHeadCount = useSelector((store) => store?.biDashboard?.loadingHeadCount);
	const startupDetails = location?.state?.startupDetails;
	const fetchHeadCount = async () => {
		try {
			dispatch(setBIDashboardData({ loadingHeadCount: true }));
			const responseData = await getCompanyDetails();
			if (responseData) {
				dispatch(
					setBIDashboardData({ headCount: responseData?.companySize > 0 ? responseData?.companySize : 0, currentStage: responseData?.currentStage })
				);
			}
			dispatch(setBIDashboardData({ loadingHeadCount: false }));
		} catch (error) {
			dispatch(setBIDashboardData({ loadingHeadCount: false }));
			console.log("error", error);
		}
	};

	useEffect(() => {
		fetchHeadCount();
	}, []);

	return (
		<Box display="flex" flexDirection="column" gap={1.25} justifyContent="center" alignItems="center" py={3}>
			<Typography variant="Text/sm/Regular" color="sos.gray500">
				Headcount
			</Typography>
			<Box sx={{ cursor: "pointer" }}>
				{loadingHeadCount ? (
					<CircularProgress />
				) : (
					<Typography variant="Text/xl/Semibold">{startupDetails ? startupDetails?.companySize : headCount}</Typography>
				)}
			</Box>
		</Box>
	);
}

export default Headcount;
