import React from "react";
import Tooltip from "@mui/material/Tooltip";

const MuiTooltip = ({
  children,
  title,
  placement = "bottom",
  arrow = false,
}) => {
  return (
    <Tooltip title={title} placement={placement} arrow={arrow}>
      {children}
    </Tooltip>
  );
};

export default MuiTooltip;
