import React, { useEffect, useState } from "react";
import { Box, Typography, Grid, Skeleton } from "@mui/material";
import GridViewIcon from "@mui/icons-material/GridView";

import { MentorProfile, MatchList, MentorMeetingListWidget } from "./Components";

import services from "../../../services";

export default function MentorHome() {
	const [profileDataLoading, setProfileDataLoading] = useState(false);
	const [errors, setErrors] = useState({});
	const [profileData, setProfileData] = useState(null);

	useEffect(() => {
		getMyProfile();
	}, []);

	const getMyProfile = async () => {
		setProfileDataLoading(true);
		try {
			const response = await services.getUserProfile();
			setProfileData(response?.data?.data);
		} catch (error) {
			setErrors(error?.response?.data ?? { error: "Unable to successfully retrieve your startup profile." });
		} finally {
			setProfileDataLoading(false);
		}
	};

	if (!profileData) {
		return null;
	}

	return (
		<Box
			sx={{
				px: {
					lg: 8,
					md: 4,
					xs: 2,
				},
				pb: "30px",
				display: "flex",
				flexDirection: "column",
				gap: "32px",
				maxWidth: "1920px",
				margin: "0 auto",
			}}
		>
			<Box display="flex" alignItems="center">
				<GridViewIcon sx={{ fontSize: 32 }} />
				<Typography variant="growth_card_subtitle" color="secondary.black" sx={{ marginLeft: 1 }}>
					Mentor Dashboard
				</Typography>
			</Box>
			<Box>
				<Grid container rowSpacing={4} columnSpacing={4}>
					<Grid item sm={12} lg={7}>
						{profileDataLoading ? (
							<Skeleton animation="wave" variant="rectangular" width={"100%"} height={"100%"} />
						) : (
							<MentorProfile profileData={profileData} />
						)}
					</Grid>
					<Grid item sm={12} lg={5}>
						{profileDataLoading ? (
							<Skeleton animation="wave" variant="rectangular" width={"100%"} height={"100%"} />
						) : (
							<MatchList expertises={JSON.parse(profileData?.expertise || "[]")} />
						)}
					</Grid>
					<Grid item sm={12} md={8}>
						<MentorMeetingListWidget />
					</Grid>
				</Grid>
			</Box>
		</Box>
	);
}
