import { Avatar, Box, Button, Typography } from "@mui/material";
import React, { useContext } from "react";
import { ResourceCenterContext } from "../Stores/ResourceCenterStore";
import { PermMedia } from "@mui/icons-material";

//{
//    "article_attachments": [
//        {
//            "id": 15841067645325,
//            "url": "https://startupos.zendesk.com/api/v2/help_center/articles/attachments/15841067645325",
//            "article_id": 15841055149197,
//            "display_file_name": "Bolt-AI-Solutions-Review (10).pdf",
//            "file_name": "Bolt-AI-Solutions-Review__10_.pdf",
//            "content_url": "https://startupos.zendesk.com/hc/article_attachments/15841067645325/Bolt-AI-Solutions-Review__10_.pdf",
//            "relative_path": "/hc/article_attachments/15841067645325/Bolt-AI-Solutions-Review__10_.pdf",
//            "content_type": "application/pdf",
//            "size": 22688,
//            "inline": false,
//            "created_at": "2023-05-16T21:10:03Z",
//            "updated_at": "2023-05-16T21:10:11Z"
//        }
//    ],
//    "page": 1,
//    "previous_page": null,
//    "next_page": null,
//    "per_page": 100,
//    "page_count": 1,
//    "count": 1
//}

const AttachmentItem = ({ item }) => (
	<Box
		sx={{
			flex: 1,
			flexBasis: "40%",
			borderRadius: "40px",
			p: 2,
			border: "3px solid",
			borderColor: "lightgray",
			transition: "all .2s",
		}}
	>
		<Box
			sx={{
				display: "flex",
				gap: 2,
				alignItems: "center",
			}}
		>
			<Avatar>
				<PermMedia />
			</Avatar>
			<Typography
				component={"div"}
				variant="body1"
				sx={{
					flex: 1,
				}}
			>
				{item?.display_file_name}
			</Typography>
			<Button variant="DS1" sx={{ justifySelf: "flex-end" }} href={item?.content_url} target="_blank">
				View
			</Button>
		</Box>
	</Box>
);

function ArticleAttachments() {
	const { currentArticleAttachments } = useContext(ResourceCenterContext);

	return (
		<Box
			sx={{
				px: 4,
				py: 6,
				backgroundColor: "white",
				borderRadius: "40px",
				boxShadow: "0px 10px 85px rgba(0, 0, 0, 0.09)",
				mb: 3,
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				flexWrap: "wrap",
				gap: 2,
			}}
		>
			{currentArticleAttachments?.article_attachments?.map((item, index) => (
				<AttachmentItem item={item} key={item?.id} />
			))}
		</Box>
	);
}

export default ArticleAttachments;
