import React, { useState, useEffect } from "react";
import DialogComponent from "../../../containers/Workspace/PowerUP/IdeaValidation/Components/Dialog";
import { Box, Grid, Typography } from "@mui/material";
import AutocompleteCheckInput from "../../../common/AutocompleteCheckInput";
import services from "../../../services";
import LoadingButton from "../../../common/Loadingbutton";

export const AddTagPopup = ({
	open,
	onClose,
	noteId,
	selectedTags,
	setCallback,
	setResearchCuratorIdValue,
	isQuikNote = false,
	setCallBackFromMenu = () => {},
}) => {
	const [tagInputs, setTagInputs] = useState([]);
	const [tagList, setTagList] = useState("");
	const [loader, setLoader] = useState(false);

	useEffect(() => {
		services
			.getTagsList()
			.then((result) => {
				if (result.data?.code === 200) {
					setTagList(result.data?.data);
				}
			})
			.catch((error) => console.log(error));
	}, []);

	useEffect(() => {
		let data = [];
		selectedTags.map((item) => {
			data.push(item.tag);
		});
		setTagInputs(data);
	}, [selectedTags]);

	const createNewOption = (inputValue) => {
		let schema = {
			id: tagList?.length ? tagList?.length + 1 : 1,
			name: inputValue,
			type: "NOTE",
		};
		if (!inputValue.trimStart()) {
			return;
		} else {
			if (tagList?.length > 0) {
				setTagList([...tagList, schema]);
			} else {
				setTagList([schema]);
			}
			setTagInputs([...tagInputs, schema]);
		}
	};
	const handleInputChange = (data, option, details) => {
		if (option === "createOption") {
			let existIndex = tagList ? tagList.findIndex((ele) => ele.name === details.option) : -1;
			if (existIndex !== -1) {
				setTagInputs([...tagInputs, tagList[existIndex]]);
			} else {
				createNewOption(details.option);
				return;
			}
		} else if (option === "removeOption") {
			setTagInputs([
				...tagInputs.filter(function (item) {
					return item.name !== details.option;
				}),
			]);
		} else {
			if (details.option.mode === "addOption") {
				if (!details.option.inputValue.trimStart()) {
					return;
				} else {
					createNewOption(details.option.inputValue);
					return;
				}
			}
			const existIndex = tagInputs.length ? tagInputs.findIndex((ele) => ele.name === details.option.name) : -1;
			if (existIndex === -1) {
				setTagInputs([
					...tagInputs,
					...data.filter(
						(option) =>
							option.id &&
							tagInputs
								.map(function (e) {
									return e.id;
								})
								.indexOf(option.id) === -1
					),
				]);
			} else {
				setTagInputs(tagInputs.filter((_, i) => i !== existIndex));
			}
		}
	};

	const handlePayload = () => {
		tagInputs.forEach(function (item) {
			delete item.id;
		});
		handleUpdateTag();
	};

	const handleUpdateTag = () => {
		const main = (id) => {
			services
				.addTagToList(id, {
					tags: tagInputs,
				})
				.then((result) => {
					if (result.data?.code === 200) {
						setCallBackFromMenu(true);
						setCallback(true);

						onClose();
						setLoader(false);
					}
				})
				.catch((error) => {
					console.log(error);
					setLoader(false);
				});
		};

		if (noteId) {
			setLoader(true);
			main(noteId);
		} else {
			setLoader(true);
			const payload = {
				title: "",
				description: "",
				noteType: isQuikNote ? "QUICK_NOTE" : "NOTE",
				isPinned: false,
			};
			services
				.addResearchCuratorApiCall(payload)
				.then((res) => {
					setResearchCuratorIdValue(res?.data?.data?.id);
					main(res?.data?.data?.id);
				})
				.catch((e) => {
					console.log("Submit", e);
				});
		}
	};

	return (
		<Box>
			<DialogComponent
				isOpen={open}
				onClose={onClose}
				title={"Add Tag"}
				titleVariant={"title_large"}
				contentStyle={{ px: 0 }}
				maxWidth={"xs"}
				fullWidth
				PaperProps={{
					sx: (theme) => ({
						borderRadius: theme.shape.standard.borderRadius,
					}),
				}}
				dialogAction={
					<Box
						sx={(theme) => ({
							mr: 2,
							display: "flex",
							[theme.breakpoints.down("md")]: {
								mr: 0,
								display: "contents",
							},
						})}
					>
						<Box item>
							<LoadingButton
								variant="contained"
								onClick={handlePayload}
								loading={loader}
								loadingStyles={{ px: 3 }}
								disabled={tagInputs.length < 1 ? true : false}
							>
								Done
							</LoadingButton>
						</Box>
					</Box>
				}
				disableBackDropClick
			>
				<Grid container alignItems={"top"} sx={{ px: 2, pt: 2 }}>
					<Grid item xs={12} md={12} lg={12} xl={12}>
						<AutocompleteCheckInput label="Enter Tag" autoCompleteData={tagList} handleInputChange={handleInputChange} inputValue={tagInputs} />
					</Grid>
					<Grid item sx={12} md={12}>
						<Typography variant="body_small" sx={(theme) => ({ pl: 2, fill: theme.palette.secondary.main })}>
							Use enter key to separate tags.
						</Typography>
					</Grid>
				</Grid>
			</DialogComponent>
		</Box>
	);
};
