import { Box, Typography, IconButton, useTheme, Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import { CardFounderWrapper } from "../styles";
import InfoIcon from "@mui/icons-material/Info";
import SubGrowth from "./SubGrowth";
import GrowthProgress from "../Progress/GrowthProgress";
import { useSelector } from "react-redux";
import InfoHelper from "../InfoHelper";

function GrowthRate() {
	const theme = useTheme();
	const growthRateData = useSelector((state) => state?.founderDashboard?.growthRateData);
	const [percentageForGrowth, setPercentageForGrowth] = useState([]);
	const [averagePercentage, setAveragePercentage] = useState(0);

	const formatPercentage = (percentage) => {
		if (percentage) {
			return Number(percentage).toFixed(2);
		}
		return 0;
	};

	useEffect(() => {
		const tempTotal = growthRateData?.reduce((total, item) => {
			return total + item.growthrate;
		}, 0);

		const tempGrowthArray = growthRateData?.map((rate) => {
			if (rate.growthrate) {
				return tempTotal ? (100 * rate.growthrate) / tempTotal : 0;
			}
			return 0;
		});

		let firstRate = tempGrowthArray.shift();
		tempGrowthArray.push(firstRate); //set employee count in last
		setPercentageForGrowth(tempGrowthArray);

		setAveragePercentage(growthRateData?.length ? tempTotal / growthRateData?.length : 0);
	}, [growthRateData]);

	return (
		<CardFounderWrapper>
			<Box display="flex" justifyContent="center" alignItems="center">
				<Grid container columnSpacing={0} rowSpacing={4}>
					<Grid item xs={12}>
						<Box display="flex" justifyContent="space-between">
							<Box sx={{ display: "flex", alignItems: "center", gap: "12px" }}>
								<Typography variant="main_title" color="secondary.black">
									Growth Rate
								</Typography>
								<InfoHelper
									title={"Growth Rate"}
									body={
										"View your startup’s social media growth rate over the past month. <br/><br/> <strong>Note:</strong> to track your social media growth rate over the past month, update your startup profile LinkedIn URL. This can take up to 48 hours."
									}
								/>
							</Box>
						</Box>
						<Box display="flex" flexDirection="column">
							<Typography variant="growth_card_subtitle" color="secondary.black">
								Monthly Growth | 100th %ile
							</Typography>
							<Typography variant="poppinsSemiBold16" color="secondary.black">
								This information is updated on a Daily basis
							</Typography>
						</Box>
					</Grid>
					<Grid item xs={12}>
						<GrowthProgress
							score={formatPercentage(averagePercentage)}
							width="300px"
							height="300px"
							percentages={percentageForGrowth}
							colors={[
								theme.palette.primary.primaryPink,
								theme.palette.primary.primaryMelon,
								theme.palette.primary.primaryYellow,
								theme.palette.primary.primaryMint,
								theme.palette.primary.primaryBlue,
							]}
						/>
					</Grid>
					<Grid item xs={12}>
						<SubGrowth
							title="Social"
							subtitle="Twitter Followers"
							percentage={formatPercentage(growthRateData?.[1]?.growthrate)}
							color={theme.palette.primary.primaryPink}
						/>
						<SubGrowth
							title="Social"
							subtitle="Facebook Followers"
							percentage={formatPercentage(growthRateData?.[2]?.growthrate)}
							color={theme.palette.primary.primaryMelon}
						/>
						<SubGrowth
							title="Social"
							subtitle="Linkedin Followers"
							percentage={formatPercentage(growthRateData?.[3]?.growthrate)}
							color={theme.palette.primary.primaryYellow}
						/>
						<SubGrowth
							title="Social"
							subtitle="Instagram Followers"
							percentage={formatPercentage(growthRateData?.[4]?.growthrate)}
							color={theme.palette.primary.primaryMint}
						/>
						<SubGrowth
							title="Employee"
							subtitle="Employee Followers"
							percentage={formatPercentage(growthRateData?.[0]?.growthrate)}
							color={theme.palette.primary.primaryBlue}
						/>
					</Grid>
				</Grid>
			</Box>
		</CardFounderWrapper>
	);
}

export default GrowthRate;
