import React, { useState, useEffect } from "react";
import { Box, Button, Grid, Typography, useMediaQuery } from "@mui/material";
import LoadingButton from "../../../common/Loadingbutton";
import { useDispatch, useSelector } from "react-redux";
import services from "../../../services";
import { toastContainer } from "../../../utils/toast";
import messages from "../../../constants/messages";
import UpdatedVideoUploader from "../../../common/UpdatedVideoUploader";
import ReusableQuill from "../../../common/ReusableQuill";
import { useWindowSize } from "../../../utils/windowResize";
import { setUserProfileData } from "../../../modules/actions/userProfileActions";
import StartupProfileCoPilot from "../../../containers/StartupProfileEdit/StartupProfileCoPilot";
import { handleMixPanel } from "../../../utils/mixPanelEventHandling";

const FounderStoryTab = ({ handleClose, height = "400px", width = "610px" }) => {
	const userDetails = useSelector((state) => state?.userProfileData?.userData);
	const [payLoad, setPayLoad] = useState({ ...userDetails });
	const [isLoading, setIsLoading] = useState(false);
	const dispatch = useDispatch();

	const size = useWindowSize();

	const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
	const responsiveVideoWidth = size.width * 0.69;
	const responsiveVideoHeight = responsiveVideoWidth * 0.65;
	useEffect(() => {
		if (userDetails) {
			setPayLoad({ ...userDetails });
		}
	}, [userDetails]);

	const handleAISave = (handleForm = true, data) => {
		let payloadData = data ? data : payLoad;
		const updatedTagList =
			payloadData?.companyTags?.length > 0
				? payloadData?.companyTags?.map((item) => {
						return Number(item?.scoreTag?.id);
				  })
				: [];
		payloadData = {
			...payloadData,
			industry:
				payloadData?.industry?.length > 0
					? typeof payloadData?.industry === "string"
						? payloadData?.industry
						: JSON.stringify(payloadData?.industry)
					: "",
			companyTags: updatedTagList,
		};

		return services
			.updateCompanyProfile(payloadData)
			.then((response) => {
				if (response.data.message === "Success") {
					dispatch(
						setUserProfileData({
							userData: response?.data?.data,
						})
					);
					return response?.data;
				}
			})
			.catch((e) => {
				console.log("Submit Error", e);
				toastContainer(messages.API_SUBMIT_ERROR, "error");
			});
	};
	const handleUpdateUser = async (eventType, aiData) => {
		if (eventType && eventType == "ai_assist") {
			let resp = await handleAISave("", aiData ? aiData : payLoad);
			if (payLoad["incorporatedStatus"] !== resp?.data?.incorporatedStatus) {
				const companyName = resp?.data?.companyName || "";
				handleMixPanel(`${companyName} incorporated status updated`, payLoad["incorporatedStatus"]);
			}
			if (aiData && resp?.data) {
				handleClose();
			}
			return resp;
		} else {
			setIsLoading(true);
			let payloadData = payLoad ? payLoad : userDetails;
			const updatedTagList =
				payloadData?.companyTags?.length > 0
					? payloadData?.companyTags?.map((item) => {
							return Number(item?.scoreTag?.id);
					  })
					: [];
			services
				.updateCompanyProfile({
					...payloadData,
					companyTags: updatedTagList,
				})
				.then((response) => {
					setIsLoading(false);
					if (response.data.message === "Success") {
						dispatch(
							setUserProfileData({
								userData: response?.data?.data,
							})
						);
					}
				})
				.catch((e) => {
					console.log(e);
					setIsLoading(false);
				});
		}
	};
	const handleAboutChange = (value) => {
		payLoad["founderStory"] = value;
		setPayLoad({ ...payLoad });
	};
	return (
		<Box>
			<Grid container rowSpacing={3} justifyContent="center" alignItems="center">
				<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
					<UpdatedVideoUploader
						title={"Founder Story Video"}
						width={isSmallScreen ? responsiveVideoWidth : "610px"}
						height={isSmallScreen ? responsiveVideoHeight : "400px"}
						shape={"rectangle"}
						userData={payLoad}
						setPayLoad={setPayLoad}
						type={"founderStoryVideo"}
						editTitle={"Edit Video"}
						fileType={{
							"video/3gpp": [".3gp"],
							"video/mp4": [".mp4"],
							"video/mov": [".mov"],
							"video/webm": [".webm"],
							"video/ogg": [".ogg"],
						}}
					/>
				</Grid>
			</Grid>
			<Grid container sx={{ mt: 5 }} justifyContent="center" alignItems="center">
				<Grid item xs={12} sm={12} md={6} lg={6} xl={6} gap={2}>
					<Typography variant="poppinsSemiBold20" component={"p"} sx={{ textAlign: "center" }}>
						Founder Story
					</Typography>
					<Box sx={{ mt: 1, backgroundColor: "white", borderRadius: "30px", p: 2 }}>
						<ReusableQuill id={"founderStory"} value={payLoad["founderStory"]} onChange={handleAboutChange} className={"profile-container"} />
					</Box>
				</Grid>
			</Grid>
			<Box
				sx={{
					display: "flex",
					gap: 2,
					justifyContent: "end",
					alignItems: "center",
					mt: 3,
				}}
			>
				<Button
					onClick={handleClose}
					variant="noOutlineNew"
					sx={{
						height: "40px",
						m: 0,
						width: "fit-content",
						fontSize: "18px",
						lineHeight: "28px",
						fontFamily: "PoppinsSemiBold",
					}}
				>
					Cancel
				</Button>
				{/* <LoadingButton
					loaderColor={"#7B61FF"}
					loadingStyles={{
						border: "4px solid",
						backgroundColor: "white",
						borderColor: "#7B61FF",
						height: "60px",
						px: 4,
					}}
					loading={isLoading}
					onClick={!isLoading ? handleUpdateUser : () => {}}
					variant="PurpleRounded"
				>
					Save
				</LoadingButton> */}
				<StartupProfileCoPilot handleSave={handleUpdateUser} saveLoading={isLoading} saveDisabled={false} handleCloseAI={handleClose} />
			</Box>
		</Box>
	);
};

export default FounderStoryTab;
