import React from "react";
import { setInvestorDashboardData } from "../../../modules/actions";
import { useDispatch, useSelector } from "react-redux";
import IndustryDetailsView from "./IndustryDetailsView";

export default function IndustryDetailsDialog() {
	const openStartupObj = useSelector((state) => state?.investorDashboard?.openStartupObj);
	const isOpen = useSelector((state) => state?.investorDashboard?.industryDetailsOpen);

	const dispatch = useDispatch();

	const handleClose = () => {
		dispatch(
			setInvestorDashboardData({
				industryDetailsOpen: false,
			})
		);
	};

	return (
		<IndustryDetailsView
			industry={
				openStartupObj?.title === "Product Offering"
					? openStartupObj?.productOffering
					: openStartupObj?.title === "Investment Stages"
					? openStartupObj?.preferredInvestmentStage
					: openStartupObj?.industry || openStartupObj?.industries
			}
			isOpen={isOpen}
			handleClose={handleClose}
			title={openStartupObj?.title ? openStartupObj?.title : "Industry Types"}
		/>
	);
}
