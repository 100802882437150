import { styled, TextField } from "@mui/material";

export const NetworkingSerchField = styled(TextField)(({ theme }) => ({
	"& .MuiInputBase-root": {
		height: "56px",
		boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
		borderRadius: "100px",
		fontSize: "16px",
		fontFamily: '"PoppinsRegular", "Helvetica", "Arial", sans-serif',
		background: theme.palette.secondary.white,
	},

	"& .MuiOutlinedInput-notchedOutline": {
		border: "none",
	},
	input: {
		padding: "18px 20px",

		"&, &::placeholder": {
			fontSize: "16px",
			lineHeight: "24px",
			fontFamily: '"PoppinsRegular", "Helvetica", "Arial", sans-serif',
			color: theme.palette.secondary.black,
			opacity: 1,
		},
	},
}));

export const MessagesSerchField = styled(TextField)(({ theme }) => ({
	"& .MuiInputBase-root": {
		height: "60px",
		borderRadius: "30px",
		fontSize: "16px",
		fontFamily: '"PoppinsRegular", "Helvetica", "Arial", sans-serif',
		background: theme.palette.secondary.white,
		border: "1px solid",
		borderColor: theme.palette.primary.grayBorder,
	},
	"& .MuiOutlinedInput-notchedOutline": {
		border: "none",
	},
	input: {
		"&, &::placeholder": {
			fontSize: "16px",
			lineHeight: "24px",
			fontFamily: '"PoppinsRegular", "Helvetica", "Arial", sans-serif',
			color: theme.palette.secondary.black,
			opacity: 1,
		},
		"&": {
			pl: 3.25,
		},
	},
}));
