import React from "react";

import Script from "../../../../../../common/script";

const GoogleTrends = ({ type, url, category, keywords, id, geoCountry }) => {
  const getEncodedKeywords = () => {
    let queryString = "";
    keywords.forEach((keyword, idx) => {
      if (idx === keywords?.length - 1) {
        queryString += `${encodeURI(keyword)}`;
      } else {
        queryString += `${encodeURI(keyword)}` + ",";
      }
    });
    return queryString;
  };

  const handleScriptLoad = _ => {
    window.trends.embed.renderExploreWidgetTo(
      document.getElementById(id),
      type,
      {
        comparisonItem: keywords?.map(keyword => ({
          keyword: keyword,
          geo: geoCountry,
          time: "today 12-m",
        })),
        category: category,
        property: "",
      },
      {
        exploreQuery: `q=${getEncodedKeywords()}&geo=US&date=today 12-m`,
        guestPath: "https://trends.google.com:443/trends/embed/",
      }
    );
  };

  const renderGoogleTrend = _ => {
    return <Script url={url} onLoad={handleScriptLoad} />;
  };

  return <div className="googleTrend">{renderGoogleTrend()}</div>;
};

export default GoogleTrends;
