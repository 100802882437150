import React from "react";
import { Document, Page, Text, View, pdf } from "@react-pdf/renderer";
import { BodyCopy, PowerUPLetterHead, Subtitle, Title, styles } from "../../../../utils/PDF-Export/utilities";

export const BoltSolutionExport = async ({ response }) => {
	return new Promise(async (resolve, reject) => {
		try {
			const PDFDocContent = (
				<Document>
					<Page style={styles.body}>
						<PowerUPLetterHead />
						<Title>StartupOS Bolt AI Review</Title>
						<View
							wrap={false}
							style={{
								borderRadius: "20px",
								backgroundColor: "#F2EFFF",
								padding: "10px",
								marginVertical: 10,
							}}
						>
							<Subtitle>Note on AI</Subtitle>
							<BodyCopy>
								Bolt is an AI working with StartupOS. It's still getting the hang of things, so its responses may not be entirely helpful from
								time to time. We are constantly working together to improve our working relationship to deliver the best results to you. Have
								feedback? Send us an email success@startupos.com
							</BodyCopy>
						</View>

						<Subtitle>StartupOS Bolt AI Summary</Subtitle>
						<BodyCopy>{response?.summary}</BodyCopy>

						{response?.ranking?.map((item, index) => (
							<View>
								<View style={{ marginVertical: 10, width: "100%", height: 1, border: "1px solid gray" }}></View>
								<Subtitle>Your Solution</Subtitle>
								<View
									wrap={false}
									style={{
										borderRadius: "20px",
										backgroundColor: "#F5FBFF",
										paddingHorizontal: "10px",
										paddingVertical: "20px",
										marginVertical: 8,
									}}
								>
									<BodyCopy>{item?.solution_name}</BodyCopy>
								</View>
								<Subtitle>Bolt's Review</Subtitle>
								<BodyCopy>{item?.solution_description}</BodyCopy>
							</View>
						))}

						{response?.proposed_solution && (
							<View>
								<View style={{ marginVertical: 10, width: "100%", height: 1, border: "1px solid gray" }}></View>

								<Subtitle>StartupOS Bolt AI's Proposed Solution</Subtitle>
								<BodyCopy>{response?.proposed_solution}</BodyCopy>
							</View>
						)}

						<Text style={styles.footerCopy} fixed>
							2023 StartupOS Inc.
						</Text>
						<Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`} fixed />
					</Page>
				</Document>
			);

			const blob = await pdf(PDFDocContent).toBlob();
			if (!blob) {
				console.error("Failed to create PDF blob");
				return;
			}
			const url = URL.createObjectURL(blob);

			const link = document.createElement("a");
			link.href = url;
			link.download = "Bolt-AI-Solutions-Review.pdf";
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
			URL.revokeObjectURL(url);

			resolve();
		} catch (error) {
			console.error(error);
			reject(error);
		}
	});
};
