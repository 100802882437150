import React, { useEffect, useRef, useState, useMemo } from "react";
import { Stack, Grid, Box, Typography, CircularProgress } from "@mui/material";
import theme from "../../../constants/Theme";
import ProfileHeader from "../../../components/Mentor/Profile/ProfileHeader";
import ProfileAbout from "../../../components/Mentor/Profile/ProfileAbout";
import { ProfileContentWrapper } from "./styles";
import { MENTOR_ICON } from "../../../constants/image";
import EditProfile from "../EditForm";
import { REGEX } from "../../../constants/regex";
import messages from "../../../constants/messages";
import services from "../../../services";
import { toastContainer } from "../../../utils/toast";
import { useDispatch, useSelector } from "react-redux";
import { USAMAP, DEFAULT_AVATAR } from "../../../constants/image";
import { AREAS_OF_EXPERTISE } from "../../../constants/mentor-data";
import * as actions from "../../../modules/actions";
import { connect } from "react-redux";
import { getCandidateDetails } from "../../Workspace/PowerUP/IdeaValidation/Functions/common";
import { matchPath, useHistory, useLocation, useParams } from "react-router-dom";
import { setAuthData } from "../../../modules/actions";
import { getYear, getMonth } from "../../../utils/date";
// import { userOnboarding } from "../../../constants";
import MessageHandlerModal from "../../../common/modal/MessageHandler";
import { handleMixPanel } from "../../../utils/mixPanelEventHandling";
import ROUTES_PATH from "../../../constants/routes";
import { USER_TYPES } from "../../../constants/workspace-sidebar";
import { CANADA_PROVINCE_CODES, US_STATE_CODES } from "../../Onboard/Components";
import { accountProfileProgress } from "../../../utils/profilePercentageCalculation";

function MentorProfile({ mentorProfile, setMentorProfile }) {
	const [showEdit, setShowEdit] = useState(false);
	const [country, setCountry] = useState();
	const [mentorError, setMentorError] = useState({
		firstNameError: "",
		lastNameError: "",
		headlineError: "",
		professionError: "",
		zipCodeError: "",
		linkedInError: "",
		websiteError: "",
		aboutError: "",
		logoError: "",
		industryFocus: "",
		startupExperience: "",
		certifications: "",
		investorType: "",
		phoneError: "",
		phoneError: "",
		startupExpertise: "",
		startupSuperpowers: "",
	});

	const dispatch = useDispatch();
	const onboardingData = useSelector((state) => state.onboarding);
	const params = useParams();
	const urllocation = useLocation();
	const history = useHistory();
	const candidateProfileId = params.id;
	const [saveDisable, setSaveDisable] = useState(false);
	const [showDeleteButton, setShowDeleteButton] = useState(false);
	const [loctionData, setLocationData] = useState();
	const [showState, setShowState] = useState(onboardingData?.companyLocation?.state);
	const [zipCode, setZipCode] = useState(onboardingData?.companyLocation?.zipCode);
	const [state, setState] = useState(onboardingData?.companyLocation?.state);
	const [stateImage, setStateImage] = useState(onboardingData?.companyLocation?.stateImage);
	const [location, setLocation] = useState({
		loaded: false,
		coordinates: {
			latitude: onboardingData?.companyLocation?.lat,
			longitude: onboardingData?.companyLocation?.lng,
		},
	});
	const [compLocation, setCompLocation] = useState("");
	const [payload, setPayload] = useState("");
	const [mentorData, setMentorData] = useState("");
	const [isDataUpdated, setIsDataUpdated] = useState();
	const [loading, setLoading] = useState(true);
	const [saveLoading, setSaveLoading] = useState(false);
	const [imgLoading, setImgLoading] = useState(false);
	const [browseFlag, setBrowseFlag] = useState(true);
	const [browseVidFlag, setBrowseVidFlag] = useState(true);
	const [vidLoading, setVidLoading] = useState(false);
	const [inputValid, setInputValid] = useState({
		//industryFocus: "",
		startupExperience: "",
		//investorType: "",
	});
	const [openVideoUpload, setOpenVideoUpload] = useState(false);
	const [harmonicData, setHarmonicData] = useState("");
	const [openHarmonic, setHarmonicOpen] = useState(false);
	const [edu, setEdu] = useState(false);
	const [exp, setExp] = useState(false);
	const [update, setUpdate] = useState(false);
	const usersType = useSelector((store) => store?.auth.userType);
	const [discard, setDiscard] = useState(false);
	const [harmonicUpdate, setHarmonicUpdate] = useState(false);
	const userType = useSelector((store) => store?.auth?.userType);
	const [stateOptions, setStateOptions] = useState([]);
	const userData = useSelector((store) => ({
		userId: store?.auth?.userId,
		userName: store?.auth?.userName,
	}));

	// This is to track the state change of edit.
	const prevStateValue = useRef(showEdit);

	useEffect(() => {
		// If the URL is /edit then set edit as true.
		const match = matchPath(urllocation.pathname, { path: ROUTES_PATH.EDIT_MY_ACCOUNT_PROFILE });
		if (match) {
			setShowEdit(true);
		}
	}, []);
	useEffect(() => {
		// If the URL is /edit then assume to use the new read-only views created.
		const match = matchPath(urllocation.pathname, { path: ROUTES_PATH.EDIT_MY_ACCOUNT_PROFILE });
		if (match && prevStateValue.current && !showEdit) {
			// history.push(ROUTES_PATH.MY_ACCOUNT_PROFILE);
			if (userType === USER_TYPES.MENTOR_USER) {
				history.push(ROUTES_PATH.MY_MENTOR_PROFILE);
			} else {
				history.push(ROUTES_PATH.MY_ACCOUNT_PROFILE);
			}
		}
		prevStateValue.current = showEdit;
	}, [showEdit]);

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		});

		if (candidateProfileId) {
			getUserDetails();
		} else {
			handleUserDetails();
		}
	}, [onboardingData]);

	//calling get api for user details
	const handleUserDetails = () => {
		services
			.getUserProfile()
			.then((response) => {
				if (response.data.message === "Success") {
					setMentorData(response.data.data);
					setMentorProfile(response.data.data);
					setPayload(response.data.data);
					setLoading(false);
					if (response.data.data) {
					} else {
						let newObj = response.data.data;
						// email = response.data.data.email;
						// firstName = response.data.data.name;
						// userOnboarding["onboardingDetail"]["companyName"] = response.data.data.companyModel.companyName;
						let newData = { ...payload, newObj };
						setPayload(newData);
					}
					if (response.data.data.videoUrl === "") {
						setShowDeleteButton(false);
					}
				}
			})
			.catch((e) => {
				//toastContainer(messages.API_SUBMIT_ERROR, "error");
				setLoading(false);
			});
	};

	const getUserDetails = () => {
		getCandidateDetails(candidateProfileId)
			.then((response) => {
				setMentorData(response);
				setMentorProfile(response);
				setLoading(false);
			})
			.catch((e) => {
				//toastContainer(messages.API_SUBMIT_ERROR, "error");
				setLoading(false);
			});
	};

	useEffect(() => {
		if (mentorData) {
			let data = Object.assign(mentorData);
			data["picture"] = mentorData?.picture;

			setPayload(data);
			//if zip code exists, set state and city
			if (mentorData?.zipcode) {
				//handleZipCodeChange(mentorData?.zipcode.toString());
			}
		} else {
			let data = { ...payload };
			data["email"] = mentorData.email;
			data["userType"] = "MENTOR_USER";

			setPayload(data);
		}
	}, [mentorData]);

	const handleBlur = (e, key) => {
		let pass = true;
		switch (key) {
			case "firstName":
				if (!e.target.value || e.target.value == "") {
					setMentorError((prevState) => ({
						...prevState,
						firstNameError: "Please enter first name.",
					}));
					pass = false;
				} else if (!e.target.value.match(REGEX.COMPANY_NAME)) {
					setMentorError((prevState) => ({
						...prevState,
						firstNameError: messages.COMPANY_NAME_LENGTH_INVALID,
					}));
					pass = false;
				} else {
					setMentorError((prevState) => ({
						...prevState,
						firstNameError: "",
					}));
					pass = true;
				}
				break;

			case "lastName":
				if (!e.target.value || e.target.value == "") {
					setMentorError((prevState) => ({
						...prevState,
						lastNameError: "Please enter last name.",
					}));
					pass = false;
				} else if (!e.target.value.match(REGEX.COMPANY_NAME)) {
					setMentorError((prevState) => ({
						...prevState,
						lastNameError: messages.COMPANY_NAME_LENGTH_INVALID,
					}));
					pass = false;
				} else {
					setMentorError((prevState) => ({
						...prevState,
						lastNameError: "",
					}));
					pass = true;
				}
				break;

			case "zipcode":
				if (!e.target.value || e.target.value == "") {
					setMentorError((prevState) => ({
						...prevState,
						zipCodeError: messages.LOCATION_INVALID,
					}));
					pass = false;
				}

				break;

			case "city":
				if (!e.target.value || e.target.value == "") {
					setMentorError((prevState) => ({
						...prevState,
						cityError: "Please enter your city",
					}));
					pass = false;
				}

				break;

			case "state":
				if (!e.target.value || e.target.value == "") {
					setMentorError((prevState) => ({
						...prevState,
						stateError: "Please enter your state",
					}));
					pass = false;
				}

				break;

			//case "profession":
			//  if (!e.target.value || e.target.value == "") {
			//    setMentorError(prevState => ({
			//      ...prevState,
			//      professionError: messages.MENTOR_PROFESSION,
			//    }));
			//    pass = true;
			//  } else {
			//    setMentorError(prevState => ({
			//      ...prevState,
			//      professionError: "",
			//    }));
			//    pass = true;
			//  }
			//  break;

			//case "investorType":
			//  if (!e.target.value || e.target.value == "") {
			//    setMentorError(prevState => ({
			//      ...prevState,
			//      investorType: "Investor Type cannot be empty",
			//    }));
			//    setInputValid(prevState => ({
			//      ...prevState,
			//      investorType: false,
			//    }));
			//    console.log("investorType");
			//    pass = false;
			//  } else {
			//    setMentorError(prevState => ({
			//      ...prevState,
			//      investorType: "",
			//    }));
			//    setInputValid(prevState => ({
			//      ...prevState,
			//      investorType: true,
			//    }));
			//    pass = true;
			//  }
			//  break;

			case "linkedInUrl":
				if (!e.target.value || e.target.value == "") {
					setMentorError((prevState) => ({
						...prevState,
						linkedInError: messages.LINKEDIN_VALID,
					}));
					pass = false;
				} else if (!REGEX.LINKEDIN_NAME.test(e.target.value)) {
					setMentorError((prevState) => ({
						...prevState,
						linkedInError: messages.LINKEDIN_VALID,
					}));
					pass = false;
				} else {
					setMentorError((prevState) => ({
						...prevState,
						linkedInError: "",
					}));
					pass = true;
				}
				break;

			//case "industryFocus":
			//  if (!e.target.value || e.target.value == "") {
			//    setMentorError(prevState => ({
			//      ...prevState,
			//      industryFocus: "Industry Focus cannot be empty",
			//    }));
			//    setInputValid(prevState => ({
			//      ...prevState,
			//      industryFocus: false,
			//    }));
			//    pass = false;
			//  } else {
			//    setMentorError(prevState => ({
			//      ...prevState,
			//      industryFocus: "",
			//    }));
			//    setInputValid(prevState => ({
			//      ...prevState,
			//      industryFocus: true,
			//    }));
			//    pass = true;
			//  }
			//  break;

			default:
				break;
		}

		//if (pass) {
		setSaveDisable(false);
		//} else {
		//  setSaveDisable(true);
		//}
		handleValidation();
	};

	const handleCloseForm = () => {
		setShowEdit(false);
		setBrowseFlag(true);
		setBrowseVidFlag(true);
		setImgLoading(false);
		setVidLoading(false);
		if (!candidateProfileId) {
			handleUserDetails();
		} else {
			getCandidateDetails();
		}
		//Resetting error messages
		setMentorError({
			firstNameError: "",
			lastNameError: "",
			headlineError: "",
			professionError: "",
			zipCodeError: "",
			linkedInError: "",
			expertiseError: "",
			websiteError: "",
			aboutError: "",
			logoError: "",
			industryFocus: "",
			startupExperience: "",
			investorType: "",
			cityError: "",
			stateError: "",
		});
	};

	//Zipcode
	const resetState = () => {
		setState("");
		setStateImage(USAMAP);
		let data = { ...payload };
		data["state"] = "";
		data["city"] = "";
		setPayload(data);
	};

	const handleZipCodeChange = async (value) => {
		if (value.match(REGEX.ZIP_CODE)) {
			setZipCode(value);
			//setSaveDisable(true);
			if (value.length >= 5) {
				const locationData = await services.searchLocationByZipcode(value);

				if (locationData.data) {
					let usaData = locationData?.data?.filter((loc) => loc?.address?.country_code === "us");

					if (usaData.length > 0) {
						if (usaData[0].address?.state) {
							setState(usaData[0].address.state);
							setLocation(usaData[0].address.city);
							let data = { ...payload };

							data = {
								...data,
								state: usaData[0].address.state,
							};
							if (!usaData[0].address.city) {
								if (usaData[0].address.village) {
									setCompLocation(`${usaData[0].address.village}, ${usaData[0].address.state}`);
									data = {
										...data,
										city: usaData[0].address.village,
									};
								} else if (usaData[0].address.town) {
									data = {
										...data,
										city: usaData[0].address.town,
									};
									setCompLocation(`${usaData[0].address.town}, ${usaData[0].address.state}`);
								} else {
									data = {
										...data,
										city: "",
									};
									setCompLocation(usaData[0].address.state);
								}
							} else {
								data = {
									...data,
									city: usaData[0].address.city,
								};
								setCompLocation(`${usaData[0].address.city}, ${usaData[0].address.state}`);
							}
							data = {
								...data,
								zipcode: value,
							};

							setPayload(data);
							setShowState(true);
						} else {
							//If state dont found for zipcode api
							const lattitude = usaData[0]?.lat;
							const longitude = usaData[0]?.lon;

							const latLongData = await services.searchLocationByCoordinates(lattitude, longitude);
							const address = latLongData.data.address;

							setCountry(address.country_code);

							if (address.country_code === "us") {
								setState(address.state);
								setLocation(address.city);
								setCompLocation(address.state);
								let data = { ...payload };
								data["state"] = address.state;
								data["city"] = address.city ? address.city : address.village ? address.village : address.suburb;
								data["town"] = address.town;

								data = {
									...data,
									zipcode: value,
								};

								setPayload(data);
								setShowState(true);
							} else {
								setMentorError((prevState) => ({
									...prevState,
									zipCodeError: messages.LOCATION_INVALID,
								}));
								//toastContainer(messages.LOCATION_INVALID, "error");
							}
						}
					} else {
						setMentorError((prevState) => ({
							...prevState,
							zipCodeError: messages.LOCATION_INVALID,
						}));
						//setSaveDisable(true);
						//toastContainer(messages.LOCATION_INVALID, "error");
						//resetState();
					}
				} else {
					//resetState();
				}
			} else {
				//resetState();
			}
		} else {
			//resetState();
		}
	};

	const handleClear = () => {
		setCompLocation("");

		//remove from payload
		let data = { ...payload };
		data[`zipcode`] = "";
		setPayload(data);
	};

	const handleDeleteVideo = () => {
		let data = { ...payload };
		data["videoUrl"] = "";
		handleUpdateUser(data);
	};

	//const getLocationDetails = zipcode => {
	//  services
	//    .searchLocationByPostalCode(zipcode)
	//    .then(response => {
	//      if (response.data.code === 200) {
	//        const responseData = response.data.data;
	//        const data = { ...payload };
	//        data["zipcode"] =
	//          responseData?.postalCode?.longName;
	//        data["city"] =
	//          responseData?.city?.longName;
	//        data["state"] =
	//          responseData?.state?.longName;
	//        setPayload(data);
	//        setSaveDisable(false);
	//        //handleUpdateUser(data);
	//        //console.log("data for zip", data);
	//      }
	//    })
	//    .catch(e => {
	//      console.log(e);
	//      toastContainer(messages.API_SUBMIT_ERROR, "error");
	//      setLoading(false);
	//    });
	//};

	function handleChange(e, key) {
		//const data = { ...payload };
		//data["superpower"] = "";
		//data["expertise"] = "";

		if (key != "about") {
			setDiscard(true);
		} else {
			if (e) {
				setDiscard(true);
			}
		}

		if (key === "zipcode") {
			let data = { ...payload };
			if (!e.target.value.match(REGEX.SPECIAL_CHARACTERS)) {
				if (!e.target.value) {
					setMentorError((prevState) => ({
						...prevState,
						zipCodeError: messages.LOCATION_INVALID,
					}));
					setMentorError((prevState) => ({
						...prevState,
						cityError: "Please enter your city",
					}));
					setMentorError((prevState) => ({
						...prevState,
						stateError: "Please enter your state",
					}));
					setSaveDisable(false);
					data = {
						...data,
						city: "",
						state: "",
					};
					setPayload(data);
				} else {
					handleZipCodeChange(e.target.value);
					setCompLocation(e.target.value);
					setMentorError((prevState) => ({
						...prevState,
						zipCodeError: "",
					}));
					setMentorError((prevState) => ({
						...prevState,
						stateError: "",
					}));
					setMentorError((prevState) => ({
						...prevState,
						cityError: "",
					}));
					setSaveDisable(false);
				}
			} else {
				setMentorError((prevState) => ({
					...prevState,
					zipCodeError: messages.LOCATION_INVALID,
				}));
				//setSaveDisable(true);
			}
		}
		//setData to payload object
		if (key === "picture" || key === "videoUrl" || key === "about") {
			let data = { ...payload };
			if (data) {
				if (key === "about") {
					data = {
						...data,
						[key]: e === "<p><br></p>" ? "" : e,
					};
				} else {
					data = {
						...data,
						[key]: e,
					};
				}
			}
			setPayload(data);
		} else if (key === "expertise" || key === "superpower" || key === "industries" || key === "productOffering") {
			let data = { ...payload };
			data = {
				...data,
				[key]: e.target.value.length > 2 ? e.target.value : null,
			};
			setPayload(data);
			if (e.target.value.length > 2) {
				if (key === "expertise") {
					setMentorError((prevState) => ({
						...prevState,
						startupExpertise: "",
					}));
				} else {
					setMentorError((prevState) => ({
						...prevState,
						startupSuperpowers: "",
					}));
				}
			} else {
				if (key === "expertise") {
					setMentorError((prevState) => ({
						...prevState,
						startupExpertise: "Expertise cannot be empty",
					}));
				} else {
					setMentorError((prevState) => ({
						...prevState,
						startupSuperpowers: "Superpowers cannot be empty",
					}));
				}
			}

			//handleBlur(e.target.value, key);
		} else if (key === "certifications") {
			let data = { ...payload };
			data = {
				...data,
				[key]: e.target.value,
			};
			setPayload(data);
		} else if (key === "city" || key === "state") {
			let data = { ...payload };
			data = {
				...data,
				[key]: e.target.value,
			};
			setPayload(data);
			if (key === "city") {
				setMentorError((prevState) => ({
					...prevState,
					cityError: "",
				}));
			} else {
				setMentorError((prevState) => ({
					...prevState,
					stateError: "",
				}));
			}
		} else if (key === "phone") {
			let data = { ...payload };
			data.phone = e.target.value;
			//data[key] = e.target.value;
			data.phone = data.phone;
			setPayload(data);
		} else if (key === "linkedInUrl") {
			let data = { ...payload };
			data = {
				...data,
				[key]: e.target.value,
			};
			setPayload(data);
		} else {
			let data = { ...payload };
			data = {
				...data,
				[key]: e.target.value,
			};
			setPayload(data);
		}
	}

	const inputChange = () => {
		//setSaveDisable(false);
	};

	const handleValidation = () => {
		if (payload) {
			if (!payload?.firstName || payload?.firstName === "" || !payload?.lastName || payload?.lastName === "") {
				setSaveDisable(true);
				return false;
			} else {
				setSaveDisable(false);
				return true;
			}
		}
	};

	useEffect(() => {
		//save button condition
		handleValidation();
	}, [payload]);

	//function for update api calling

	//const handleSave = async (eventType, payloadObject) => {
	//	if (eventType && eventType == "ai_assist") {
	//		setSaveLoading(true);
	//		setSaveDisabled(true);
	//		if (handleValidations()) {
	//			let resp = await handleAISave();
	//			setSaveLoading(false);
	//			setSaveDisabled(false);
	//			return resp;
	//		} else {
	//			setSaveLoading(false);
	//			setSaveDisabled(false);
	//		}
	//	} else {
	//		if (handleValidations(payloadObject)) {
	//			setSaveDisabled(true);
	//			handlePayload(true, payloadObject);
	//		}
	//	}
	//};

	const handleAISave = async (data, eventType) => {
		let payloadData = null;

		if (eventType && eventType == "ai_suggestions") {
			payloadData = payload;
			payloadData = {
				...payloadData,
				about: data?.about ? data?.about : payloadData?.about,
				headline: data?.headline ? data?.headline : payloadData?.headline,
			};
		} else {
			payloadData = data ? data : payload;
		}

		handleMixPanel("Account Profile Edited", {
			userName: userData.userName,
			userId: userData.userId,
		});

		let check = false;
		if (showEdit) {
			check = handleValidation();
		} else {
			check = true;
		}

		setSaveLoading(true);

		const getLinkedInUrl = (url) => {
			if (url) {
				if (url.match(/^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(in\/)/gm)) {
					let cleanedLinkedInUrl = url.replace(/^(https:\/\/www\.linkedin\.com\/in\/)(.*)https:\/\/www\.linkedin\.com\/in\//, "$1$2");

					return cleanedLinkedInUrl;
				} else {
					return "https://www.linkedin.com/in/" + url;
				}
			} else {
				return null;
			}
		};

		if (check) {
			return services
				.updateUserProfile({
					...payloadData,
					phone: payload.phone,
					linkedInUrl: getLinkedInUrl(payloadData.linkedInUrl),
				})
				.then((response) => {
					if (response.data.message === "Success") {
						if (eventType && eventType == "ai_suggestions") {
							handleCloseForm();
						}

						setSaveLoading(false);
						setIsDataUpdated(true);
						dispatch(
							setAuthData({
								userData: {
									picture: response.data.data.picture,
								},
								userName: response.data.data.name,
							})
						);
						return response;
					}
				})
				.catch((e) => {
					console.log("Submit", e);
					toastContainer(messages.API_SUBMIT_ERROR, "error");
					handleCloseForm();
					setSaveLoading(false);
				});
		}

		//handleUserDetails();
	};

	const handleUpdateUser = (data) => {
		let payloadData = data ? data : payload;

		handleMixPanel("Account Profile Edited", {
			userName: userData.userName,
			userId: userData.userId,
		});

		let check = false;
		if (showEdit) {
			check = handleValidation();
		} else {
			check = true;
		}

		setSaveLoading(true);

		const getLinkedInUrl = (url) => {
			if (url) {
				if (url.match(/^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(in\/)/gm)) {
					let cleanedLinkedInUrl = url.replace(/^(https:\/\/www\.linkedin\.com\/in\/)(.*)https:\/\/www\.linkedin\.com\/in\//, "$1$2");

					return cleanedLinkedInUrl;
				} else {
					return "https://www.linkedin.com/in/" + url;
				}
			} else {
				return null;
			}
		};

		if (check) {
			services
				.updateUserProfile({
					...payloadData,
					phone: payload.phone,
					linkedInUrl: getLinkedInUrl(payloadData.linkedInUrl),
				})
				.then((response) => {
					if (response.data.message === "Success") {
						handleCloseForm();
						setSaveLoading(false);
						setIsDataUpdated(true);
						setLoading(true);
						if (response.data.data.picture) {
							dispatch(
								setAuthData({
									userData: {
										picture: response.data.data.picture,
									},
								})
							);
						}
					}
				})
				.catch((e) => {
					console.log("Submit", e);
					toastContainer(messages.API_SUBMIT_ERROR, "error");
					handleCloseForm();
					setSaveLoading(false);
				});
		}

		//handleUserDetails();
	};

	const getFiles = async (data, type) => {
		if (type === "img") {
			if (data.type == "image/png" || data.type == "image/jpeg" || data.type == "image/jpg") {
				setImgLoading(true);
				setMentorError((prevState) => ({
					...prevState,
					logoError: "",
				}));
				uploadFile(type, data);
				handleUserDetails();
			}
		} else if (data.type == "video/mp4" || data.type == "video/3gp" || data.type == "video/avi" || data.type == "video/webm" || data.type == "video/ogg") {
			setVidLoading(true);
			setMentorError((prevState) => ({
				...prevState,
				videoError: "",
			}));
			uploadFile(type, data);
			handleUserDetails();
		} else {
			setMentorError((prevState) => ({
				...prevState,
				logoError: "Invalid file type.",
			}));
		}
		//handleUserDetails();
	};

	const uploadFile = (type, data) => {
		var formData = new FormData();
		formData.append("file", data);
		formData.append("fileGroup", "PROFILE");

		if (type == "img") {
			setImgLoading(true);
		} else {
			setVidLoading(true);
		}
		services
			.uploadFile(formData)
			.then((response) => {
				if (type == "img") {
					setImgLoading(false);
					setBrowseFlag(true);
					setOpenVideoUpload(false);
					handleChange(response?.data?.data?.fileLocation, "picture");
				} else if (type == "video") {
					setBrowseVidFlag(true);
					setVidLoading(false);
					setOpenVideoUpload(false);
					handleChange(response?.data?.data?.fileLocation, "videoUrl");
				}
				toastContainer("Uploaded successfully", "success");
			})
			.catch((e) => {
				console.log("File upload Error", e);
				if (type == "img") {
					setImgLoading(false);
					setMentorError((prevState) => ({
						...prevState,
						logoError: messages.FILE_UPLOAD_ERROR,
					}));
				} else if (type == "video") {
					setVidLoading(false);
					setMentorError((prevState) => ({
						...prevState,
						videoError: messages.FILE_UPLOAD_ERROR,
					}));
				}
			});
	};

	const handleDeleteUploadAttachment = () => {
		setBrowseVidFlag(false);
		let data = { ...payload };
		data["videoUrl"] = "";
		setPayload(data);
		setOpenVideoUpload(false);
		setSaveDisable(false);
	};

	const handleDeleteImageUploadAttachment = () => {
		setBrowseFlag(false);
		let data = { ...payload };
		data["picture"] = "";
		setPayload(data);
		setOpenVideoUpload(false);
		setSaveDisable(false);
	};

	const handleUpdateAssetsUrl = (imageObj, key) => {
		let data = { ...payload };
		data = {
			...data,
			[key]: imageObj,
			phone: payload.phone,
		};
		setPayload(data);
		if (!showEdit) {
			handleUpdateUser(data);
		}
	};

	const deleteExperienceApiCall = () => {
		return services
			.deleteAllMentorExperience()
			.then((result) => {
				if (result.data.code === 200) {
					setExp(true);
					setUpdate(true);
					return true;
				}
			})
			.catch((error) => {
				console.log(error);
				throw error;
			});
	};

	const deleteEducationApiCall = () => {
		return services
			.deleteAllMentorEducation()
			.then((result) => {
				if (result.data.code === 200) {
					setEdu(true);
					setUpdate(true);
					return true;
				}
			})
			.catch((error) => {
				console.log(error);
				throw error;
			});
	};

	const handleGetHarmonicData = () => {
		setHarmonicData("");

		if (payload?.linkedInUrl) {
			let getLinkedInUrl = (url) => {
				if (url) {
					if (url.includes("https://www.linkedin.com/in/")) {
						let cleanedLinkedInUrl = url.replace(/^(https:\/\/www\.linkedin\.com\/in\/)(.*)https:\/\/www\.linkedin\.com\/in\//, "$1$2");
						return cleanedLinkedInUrl;
					} else {
						return "https://www.linkedin.com/in/" + url;
					}
				} else {
					return null;
				}
			};

			services
				.getHarmonicsController({
					userId: payload?.id,
					linkedinUrl: getLinkedInUrl(payload?.linkedInUrl),
				})
				.then((res) => {
					setHarmonicData(JSON.parse(res?.data?.data.harmonicData));
					if (res?.data?.data.harmonicData) {
						toastContainer("Linkedin data updated successfully.", "Suceess");
					} else if (!res?.data?.data.harmonicData) {
						toastContainer(messages.LINKEDIN_ERROR);
						setHarmonicOpen(false);
						setLoading(false);
					}
				})
				.catch((err) => {
					console.log(err);
					if (err?.response?.data?.code === 429) {
						toastContainer("Too many requests.", "error");
					}
					setLoading(false);
				});
		}
	};

	useEffect(() => {
		if (harmonicData) {
			let data = { ...payload };
			let location = harmonicData?.location?.location.split(", ");
			let edu = false;
			let exp = false;

			if (harmonicData?.experience && harmonicData?.experience?.length > 0) {
				deleteExperienceApiCall();
			}
			if (harmonicData?.education && harmonicData?.education?.length > 0) {
				deleteEducationApiCall();
			}

			if (harmonicData?.first_name || harmonicData?.last_name) {
				data = {
					...data,
					firstName: harmonicData?.first_name,
					lastName: harmonicData?.last_name,
				};
			} else {
				let nameArr = harmonicData?.full_name?.split(" ");
				data = {
					...data,
					firstName: nameArr[0],
					lastName: nameArr[2] ? nameArr[2] : nameArr[1],
				};
			}
			if (harmonicData?.profile_picture_url) {
				data = {
					...data,
					picture: harmonicData?.profile_picture_url,
				};
			}
			if (harmonicData?.location?.city) {
				data = {
					...data,
					city: harmonicData?.location?.city,
				};
			} else {
				data = {
					...data,
					city: location[0],
				};
			}
			if (harmonicData?.location?.state) {
				data = {
					...data,
					state: harmonicData?.location?.state,
				};
			} else {
				data = {
					...data,
					state: location[1],
				};
			}
			if (harmonicData?.location?.zip) {
				data = {
					...data,
					zipcode: harmonicData?.location?.zip,
				};
			} else {
				data = {
					...data,
					zipcode: "",
				};
			}

			if (harmonicData?.contact?.phone_numbers) {
				data = {
					...data,
					phone: harmonicData?.contact?.phone_numbers,
				};
			}

			setPayload(data);
			if (!showEdit) {
				handleUpdateUser(data);
			}

			//setLoading(false);
			setHarmonicOpen(false);
		}
	}, [harmonicData]);

	const handleSaveExp = (data) => {
		let payload = {
			title: data?.title,
			companyName: data?.companyName,
			location: data?.location,
			startMonth: parseInt(data?.startMonth),
			startYear: parseInt(data?.startYear),
			endMonth: parseInt(data?.endMonth),
			endYear: parseInt(data?.endYear),
		};

		//return true;
		return services
			.addMentorExperience(payload)
			.then((result) => {
				if (result.data.code === 200) {
				}
			})
			.catch((error) => {
				throw error;
			});
	};

	const handleSaveEdu = (data) => {
		let payload = {
			course: data?.course,
			university: data?.university,
			location: data?.location,
			startYear: parseInt(data?.startYear),
			endYear: parseInt(data?.endYear),
		};

		return services
			.addMentorEducation(payload)
			.then((result) => {
				if (result.data.code === 200) {
					return true;
				}
			})
			.catch((error) => {
				throw error;
			});
	};

	useEffect(() => {
		if (harmonicData?.experience && exp) {
			let data = { ...payload };
			let experienceDetails = [];
			harmonicData?.experience.map((item) => {
				const expObj = {};

				if (item?.is_current_position) {
					data[`profession`] = item?.title;
				}
				if (item.title) {
					expObj["title"] = item.title;
				}
				if (item.company_name) {
					expObj["companyName"] = item.company_name;
				}
				if (item.location) {
					expObj["location"] = item.location;
				}
				if (item.start_date) {
					expObj["startYear"] = getYear(item.start_date);
					expObj["startMonth"] = getMonth(item?.start_date);
				}
				if (item.end_date) {
					expObj["endYear"] = item.end_date ? getYear(item.end_date) : "";
					expObj["endMonth"] = item.end_date ? getMonth(item?.end_date) : "";
				}
				experienceDetails.push(expObj);
				handleSaveExp(expObj);
			});
			data.experienceDetails = experienceDetails;
			setPayload(data);
			setExp(false);
		}
	}, [exp, harmonicData]);

	useEffect(() => {
		if (harmonicData?.education && edu) {
			let data = { ...payload };

			let educationDetails = [];
			harmonicData?.education.map((item) => {
				const eduObj = {};
				if (item?.degree) {
					eduObj["course"] = item?.degree;
				}
				if (item?.school?.name) {
					eduObj["university"] = item?.school?.name;
				}
				if (item?.start_date) {
					eduObj["startYear"] = getYear(item.start_date);
				}
				if (item?.end_date) {
					eduObj["endYear"] = getYear(item.end_date);
				}

				educationDetails.push(eduObj);
				handleSaveEdu(eduObj);
			});
			data.educationDetails = educationDetails;
			setPayload(data);

			setEdu(false);
		}
	}, [edu, harmonicData]);

	useEffect(() => {
		if (update && !edu && !exp) {
			setUpdate(false);
			setLoading(false);
			setHarmonicUpdate(true);
		}
	}, [update]);

	const containsUSA = (str) => {
		let pattern = /US|USA|United States of America|America/i;
		return pattern.test(str);
	};

	const containsCanada = (str) => {
		let pattern = /CA|Canada/i;
		return pattern.test(str);
	};

	const getStateOptions = async () => {
		try {
			const res = await services.getListOfCountryStateApi();
			const countryStateData = res?.data?.data;
			if (payload?.country !== null && typeof payload?.country !== "undefined" && payload?.country !== "" && countryStateData?.length) {
				if (containsUSA(payload?.country)) {
					setStateOptions(countryStateData.filter((item) => item.countryCode === "USA")[0].states);
					return;
				}
				if (containsCanada(payload?.country)) {
					setStateOptions(countryStateData.filter((item) => item.countryCode === "CA")[0].states);
					return;
				}
				setStateOptions(countryStateData.filter((item) => item.countryCode === "USA")[0].states);
			}
		} catch (error) {
			console.log(error, "error");
		}
	};

	useEffect(() => {
		getStateOptions();
	}, [payload?.country]);

	return (
		<>
			<Stack
				sx={(theme) => ({
					minHeight: "100%",
					display: "block",
					px: 3,
				})}
			>
				<Grid
					container
					sx={{
						maxWidth: theme.breakpoints.values.xl,
						margin: "auto",
					}}
				>
					<ProfileContentWrapper>
						{!loading ? (
							<>
								{!showEdit ? (
									<>
										<Grid item xs={12} md={12} sx={{ px: { md: 0 } }}>
											<ProfileHeader
												setShowEdit={setShowEdit}
												mentorData={mentorData}
												compLocation={compLocation}
												handleUserDetails={handleUserDetails}
												getUserDetails={getUserDetails}
												handleUpdateAssetsUrl={handleUpdateAssetsUrl}
												showEdit={showEdit}
											/>
										</Grid>
										<Grid item xs={12} md={12} sx={{ px: { md: 0 } }}>
											<ProfileAbout
												handleUserDetails={handleUserDetails}
												mentorData={mentorData}
												handleDeleteVideo={handleDeleteVideo}
												setBrowseFlag={setBrowseFlag}
												setShowDeleteButton={setShowDeleteButton}
												showDeleteButton={showDeleteButton}
												inputChange={inputChange}
												candidateProfileId={candidateProfileId}
												handleUpdateAssetsUrl={handleUpdateAssetsUrl}
												payload={payload}
												setHarmonicOpen={setHarmonicOpen}
											/>
										</Grid>
									</>
								) : (
									<>
										<EditProfile
											handleBlur={handleBlur}
											mentorError={mentorError}
											saveDisable={saveDisable}
											handleCloseForm={handleCloseForm}
											handleUpdateUser={handleUpdateUser}
											handleAISave={handleAISave}
											handleZipCodeChange={handleZipCodeChange}
											handleChange={handleChange}
											compLocation={compLocation}
											handleClear={handleClear}
											payload={payload}
											expertise={AREAS_OF_EXPERTISE}
											handleUserDetails={handleUserDetails}
											saveLoading={saveLoading}
											handleUploadAction={getFiles}
											setMentorError={setMentorError}
											imgLoading={imgLoading}
											browseFlag={browseFlag}
											browseVidFlag={browseVidFlag}
											vidLoading={vidLoading}
											inputValid={inputValid}
											setSaveDisable={setSaveDisable}
											setShowDeleteButton={setShowDeleteButton}
											inputChange={inputChange}
											setOpenVideoUpload={setOpenVideoUpload}
											openVideoUpload={openVideoUpload}
											handleDeleteUploadAttachment={handleDeleteUploadAttachment}
											handleDeleteImageUploadAttachment={handleDeleteImageUploadAttachment}
											setBrowseVidFlag={setBrowseVidFlag}
											setBrowseFlag={setBrowseFlag}
											handleUpdateAssetsUrl={handleUpdateAssetsUrl}
											handleGetHarmonicData={handleGetHarmonicData}
											setHarmonicOpen={setHarmonicOpen}
											discard={discard}
											harmonicUpdate={harmonicUpdate}
											setHarmonicUpdate={setHarmonicUpdate}
											stateOptions={stateOptions}
										/>
									</>
								)}
							</>
						) : (
							<Box sx={{ display: "flex", width: 1, justifyContent: "center" }}>
								<CircularProgress size={30} />
							</Box>
						)}
					</ProfileContentWrapper>
				</Grid>
				<MessageHandlerModal
					isOpen={openHarmonic}
					onCancel={() => setHarmonicOpen(false)}
					onOk={() => handleGetHarmonicData()}
					okText="Continue"
					cancelText={"Cancel"}
					heading="Copy profile from Linkedin"
					text={
						"Continuing to copy data from Linkedin will replace all data in your Profile Intro, Experience, and Education area of the screen. It will not replace Headline, About and Media data loaded: Video, Images, etc."
					}
					messageType="primary"
				/>
			</Stack>
		</>
	);
}

const mapStoreToProps = ({ mentor }) => ({
	mentorProfile: mentor?.mentorProfile,
});

const mapDispatchToProps = (dispatch) => ({
	setMentorProfile: (updatedSteps) => dispatch(actions.setMentorProfile(updatedSteps)),
});

export default connect(mapStoreToProps, mapDispatchToProps)(MentorProfile);
