import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import PersonaCard from "./PersonaCard";
import NoDataPill from "./NoDataPill";
import { ImportContactsOutlined } from "@mui/icons-material";

export default function Pain({ persona }) {
	const [personaData, setpersonaData] = useState(persona);

	useEffect(() => {
		setpersonaData(persona);
	}, [persona]);

	const PAIN = [
		{ title: "Frustrations", desc: personaData?.frustration?.join(", ") },
		{ title: "Challenges", desc: personaData?.challenges?.join(", ") },
	];
	return (
		<PersonaCard isLarge={true} title="Pain Points & Challenges" icon={<ImportContactsOutlined fontSize="large" />}>
			<Box mt={"30px"}>
				{PAIN.map((item) => (
					<Box key={item.title} mb="30px">
						<Typography variant="title_large2" sx={{ fontWeight: 600, display: "block" }}>
							{item.title}
						</Typography>
						{item.desc ? <Typography variant="card_text">{item.desc}</Typography> : <NoDataPill />}
					</Box>
				))}
			</Box>
		</PersonaCard>
	);
}
