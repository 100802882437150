import React, { useState } from "react";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { ContentWrapper, BorderLinearProgress } from "./styles";
import CompleteYourProfile from "../CompleteYourProfile";

const ProfileProgress = ({ percentage }) => {
  return (
    <ContentWrapper>
      <Stack direction="column" spacing={1}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          spacing={2}
        >
          <Box>
            <Typography
              variant="StartupOS/label/x-large"
              color="text.lightOnsurface"
            >
              Profile Completion
            </Typography>
          </Box>
          <Box>
            <Typography variant="progress_value" color="text.lightOnsurface">
              {percentage}%
            </Typography>
          </Box>
        </Stack>
        <Box sx={{ pb: 2 }}>
          <BorderLinearProgress variant="determinate" value={percentage} />
        </Box>
        <CompleteYourProfile progressValue={percentage} />
      </Stack>
    </ContentWrapper>
  );
};

export default ProfileProgress;
