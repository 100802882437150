import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import theme from "../../../../constants/Theme";

export default function Details({ details }) {
  return (
    <Box sx={{ pl: { xs: 0, md: 2 }, mt: 2 }}>
      <Grid container>
        <Grid item xs={12} md={12}>
          <Typography variant="title_large">Details</Typography>
        </Grid>
        <Grid item xs={12} md={12} sx={{ mt: 3 }}>
          <Typography variant="title_medium">Email Address</Typography>
        </Grid>
        <Grid item xs={12} md={12} sx={{ mt: 1 }}>
          <Typography
            variant="subhead4"
            sx={{
              display: "flex",
              width: 1,
              height: "max-content",
              textDecoration: "underline",
              color: theme.palette.text.link,
              overflow: "auto",
              wordBreak: "break-all",
            }}
          >
            {details?.email}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}
