import React, { useState, useEffect } from "react";
import CropSquareOutlinedIcon from "@mui/icons-material/CropSquareOutlined";
import SquareRoundedIcon from "@mui/icons-material/SquareRounded";
import { Box } from "@mui/material";
import { getTeamMemberFilterKeys } from "../../../../../utils/commonFunctions";
import { useDispatch, useSelector } from "react-redux";
import { setInvestorDashboardData } from "../../../../../modules/actions";

const CustomCheckBox = ({ value, handleUncheck, type, checkedValue }) => {
	const [checked, setChecked] = useState(false);
	const filteredValue = useSelector((state) => state?.investorDashboard?.filteredValue) || [];

	const dispatch = useDispatch();

	const checkboxValue = value;

	useEffect(() => {
		setChecked(checkedValue ? checkedValue : false);
	}, [checkedValue]);

	const handleChecked = (event) => {
		if (checked == false) {
			setChecked(true);
			let obj = {
				type: type,
				key: getTeamMemberFilterKeys(checkboxValue),
				checkboxValue,
			};

			dispatch(
				setInvestorDashboardData({
					filteredValue: [...filteredValue, obj],
				})
			);
		} else {
			setChecked(false);
			handleUncheck(checkboxValue, type);
		}
	};

	return (
		<Box onClick={handleChecked} sx={{ display: "flex" }}>
			{checked ? (
				<SquareRoundedIcon sx={{ fontSize: "20px", color: (theme) => theme.palette.primary.primaryPurple }} />
			) : (
				<CropSquareOutlinedIcon sx={{ fontSize: "20px" }} />
			)}
		</Box>
	);
};

export default CustomCheckBox;
