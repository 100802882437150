import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import useDimensions from "react-cool-dimensions";
import { Box, Typography } from "@mui/material";
import { PRESENTATION_CHARTLINE_ICON } from "../../../../constants";
import SvgComponent from "../../../../utils/SVGColor";
import MissingAsset from "../../../Profile/Components/MissingAsset";
import PublicPitchDeckViewer from "./PublicPitchDeckViewer";
import { useSelector } from "react-redux";

function StartupPublicPitchDeckCard() {
	const history = useHistory();
	const [isSmallWidth, setIsSmallWidth] = useState(false);
	const startupData = useSelector((state) => state?.startupPublicProfileData?.startupPublicData);

	const { observe, width } = useDimensions({
		breakpoints: { SM: 850 },
		updateOnBreakpointChange: true,
	});

	useEffect(() => {
		observe();
	}, []);

	useEffect(() => {
		if (width >= 850) {
			setIsSmallWidth(false);
		} else {
			setIsSmallWidth(true);
		}
	}, [width]);

	return (
		<Box
			sx={(theme) => ({
				borderRadius: "40px",
				backgroundColor: "white",
				boxSizing: "border-box",
				padding: "32px 16px", // default padding
				[theme.breakpoints.between("sm", "md")]: {
					padding: "32px 32px", // padding between sm and md
				},
				[theme.breakpoints.up("md")]: {
					padding: "32px 40px 48px", // padding above md
				},
				boxShadow: "0px 10px 25px rgba(0, 0, 0, 0.05)",
			})}
		>
			<Box
				sx={{
					display: "flex",
					alignItems: isSmallWidth ? "flex-start" : "center",
					justifyContent: isSmallWidth ? "flex-start" : "space-between",
					flex: 1,
					mb: "36px",
					flexDirection: isSmallWidth ? "column" : "row",
					rowGap: "36px",
				}}
			>
				<Box
					sx={(theme) => ({
						display: "flex",
						alignItems: "center",
						gap: "12px",
						[theme.breakpoints.down("md")]: {
							alignItems: "flex-start",
							flexDirection: "column",
						},
					})}
				>
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							gap: "12px",
						}}
					>
						<SvgComponent svgUrl={PRESENTATION_CHARTLINE_ICON} strokeColor={"#0A141B"} style={{ height: "35px", width: "35px" }} />
						<Typography
							variant="customValue"
							component={"div"}
							sx={{
								color: "black",
								fontFamily: "PoppinsSemiBold",
								fontSize: "26px",
								lineHeight: "39px",
							}}
						>
							{startupData?.companyName} Pitch Deck
						</Typography>
					</Box>
				</Box>
			</Box>

			<Box
				sx={{
					background: "gray",
					borderRadius: "40px",
				}}
			>
				{(() => {
					if (startupData?.pitchDeckLink) {
						return <PublicPitchDeckViewer />;
					} else {
						return <MissingAsset sxProps={{ height: "450px", p: 2 }} message={"Pitch Deck Coming Soon"} />;
					}
				})()}
			</Box>
		</Box>
	);
}

export default StartupPublicPitchDeckCard;
