import React from "react";
import { Autocomplete, TextField, Box } from "@mui/material";
import MultiTagsInput from "../MultiTagsInput";
import MultiObjTagsInput from "../MultiObjTagsInput";

export default function AutocompleteInput({
	placeholder,
	inputValid,
	errorMessage,
	typingEnabled,
	inputValue,
	handleInputChange,
	inputKey,
	autoCompleteData,
	label,
	multiple,
	max,
	disabled,
	handleOnBlurEvent = false,
	isEmailField = false,
	isReferenceField = false,
	handleOnChange = () => {},
	isError = false,
	InputLabelProps = {},
	showInputValidIcons = false,
	isKeyValue = false,
	newStyle = false,
	...props
}) {
	const handleChange = (e) => {
		if (!multiple) {
			handleInputChange(e, inputKey);
		} else {
			if (e.target.value === 0 && e.target.innerText) {
				inputValue.push(e.target.value || e.target.innerText);
				let inputs = [...inputValue];
				let uniqueInputs = new Set(inputs);
				handleInputChange({ target: { value: Array.from(uniqueInputs) } }, inputKey);
			}
		}
	};
	return (
		<Autocomplete
			freeSolo
			disableClearable
			value={inputValue}
			options={isReferenceField ? autoCompleteData || [] : autoCompleteData?.map((option) => option)}
			{...props}
			renderInput={(params) => (
				<>
					{multiple ? (
						isKeyValue ? (
							<MultiObjTagsInput
								inputValue={inputValue}
								params={params}
								handleInputChange={handleInputChange}
								inputKey={inputKey}
								placeholder={placeholder}
								errorMessage={errorMessage}
								inputValid={inputValid}
								typingEnabled={typingEnabled}
								label={label}
								max={max}
								error={isError}
								isError={isError}
								handleOnBlurEvent={handleOnBlurEvent}
								endAdornment={props.endAdornment}
								inputRef={props.inputRef}
								helpText={props.helpText}
								disabled={disabled}
								isEmailField={isEmailField}
								isReferenceField={isReferenceField}
								handleOnChange={handleOnChange}
								InputLabelProps={InputLabelProps}
								newStyle={newStyle}
							/>
						) : (
							<MultiTagsInput
								inputValue={inputValue}
								params={params}
								handleInputChange={handleInputChange}
								inputKey={inputKey}
								placeholder={placeholder}
								errorMessage={errorMessage}
								inputValid={inputValid}
								typingEnabled={typingEnabled}
								label={label}
								max={max}
								error={isError}
								isError={isError}
								handleOnBlurEvent={handleOnBlurEvent}
								endAdornment={props.endAdornment}
								inputRef={props.inputRef}
								helpText={props.helpText}
								disabled={disabled}
								isEmailField={isEmailField}
								isReferenceField={isReferenceField}
								handleOnChange={handleOnChange}
								InputLabelProps={InputLabelProps}
								newStyle={newStyle}
							/>
						)
					) : (
						<TextField
							{...params}
							spellCheck="true"
							label={label}
							onChange={handleChange}
							disabled={disabled}
							placeholder={placeholder}
							InputLabelProps={InputLabelProps}
							handleOnBlurEvent={handleOnBlurEvent}
						/>
					)}
				</>
			)}
			onChange={handleChange}
			disabled={disabled}
			{...props}
		/>
	);
}
