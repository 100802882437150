import { Skeleton, Tabs } from "@mui/material";
import React, { useContext, useEffect, useState, Fragment } from "react";
import { ProfileContext } from "../../..";
import AboutTab from "./AboutTab";
import FounderAboutTabContent from "./FounderAboutTabContent";

function AboutTabs() {
	const { profileData, profileDataLoading } = useContext(ProfileContext);
	const [value, setvalue] = useState(0);
	const [loading, setloading] = useState(profileDataLoading);

	useEffect(() => {
		setloading(profileDataLoading);
	}, [profileDataLoading]);

	const handleChange = (event, newValue) => {
		setvalue(newValue);
	};

	return (
		<Fragment>
			{loading ? (
				<Fragment>
					<Skeleton animation="wave" variant="rounded" sx={{ width: 1, height: "30px", mb: "40px" }} />
					<Skeleton animation="wave" variant="rounded" sx={{ width: 1, height: "300px" }} />
				</Fragment>
			) : (
				<Fragment>
					<Tabs
						value={value}
						onChange={handleChange}
						sx={{
							mb: "20px",
							"& .MuiTab-root": {
								mr: "30px", // Set margin-right between tabs
							},
							"& .MuiTabs-indicator": {
								display: "none",
							},
						}}
					>
						<AboutTab label={"Founder Story"} />
						<AboutTab label={"Elevator Pitch"} />
					</Tabs>
					{value === 0 && <FounderAboutTabContent tab={"story"} />}
					{value === 1 && <FounderAboutTabContent tab={"about"} />}
				</Fragment>
			)}
		</Fragment>
	);
}

export default AboutTabs;
