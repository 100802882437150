import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Box, Typography, Paper } from "@mui/material";
import countryList from "country-list";
import UpdatedTextInputField from "../../common/UpdatedTextInputField";
import { styled } from "@mui/material/styles";

const countries = countryList.getData().map((country) => ({
	code: country.code,
	name: country.name,
	flag: getFlagEmoji(country.code),
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
	borderRadius: "0 0 20px 20px",
	boxShadow: theme.shadows[1],
}));

function getFlagEmoji(countryCode) {
	return countryCode.toUpperCase().replace(/./g, (char) => String.fromCodePoint(127397 + char.charCodeAt()));
}

export default function CountryAutoComplete({ onValueSet, parentValue, fieldLabel, fieldPlaceholder, name }) {
	const [value, setValue] = React.useState(null);
	const [inputValue, setInputValue] = React.useState("");

	React.useEffect(() => {
		if (parentValue) {
			const matchedCountry = countries.find(
				(country) => country.name.toLowerCase() === parentValue.toLowerCase() || country.code.toLowerCase() === parentValue.toLowerCase()
			);
			if (matchedCountry) {
				setValue(matchedCountry);
				setInputValue(matchedCountry.name);
			}
		}
	}, [parentValue]);

	return (
		<Box sx={{ minWidth: 120, width: "100%" }}>
			<Typography variant="count_title">{fieldLabel}</Typography>
			<Autocomplete
				disableClearable
				options={countries}
				getOptionLabel={(option) => option.name}
				value={value}
				onChange={(event, newValue) => {
					setValue(newValue);
					setInputValue(newValue ? newValue.name : "");
					if (onValueSet) {
						onValueSet(newValue);
					}
				}}
				name={name ?? ""}
				inputValue={inputValue}
				onInputChange={(event, newInputValue) => {
					setInputValue(newInputValue);
				}}
				sx={{
					"& .MuiAutocomplete-inputRoot": {
						height: "60px",
					},
					"& .MuiAutocomplete-input": {
						paddingLeft: "10.5px !important",
					},
					"& .MuiAutocomplete-inputBase": {
						paddingLeft: "10.5px !important",
					},
				}}
				PaperComponent={({ children }) => <StyledPaper>{children}</StyledPaper>}
				renderOption={(props, option) => (
					<Box component="li" sx={{ display: "flex", alignItems: "center" }} {...props}>
						<span style={{ marginRight: "16px", fontSize: "36px" }}>{option.flag}</span>
						<Typography variant="Text/sm/Regular">{option.name}</Typography>
					</Box>
				)}
				renderInput={(params) => (
					<UpdatedTextInputField
						{...params}
						label=""
						placeholder={fieldPlaceholder}
						fullWidth
						InputProps={{
							...params.InputProps,
							startAdornment: (
								<>
									{params.InputProps.startAdornment}
									{inputValue && (
										<span style={{ marginRight: 8, paddingLeft: "10.5px", fontSize: "36px" }}>
											{countries.find((country) => country.name === inputValue)?.flag}
										</span>
									)}
								</>
							),
						}}
					/>
				)}
			/>
		</Box>
	);
}
