const cloudFrontUrl = process.env.REACT_APP_CLOUDFRONT_URL;

export const ROADRUNNERICON = `${cloudFrontUrl}/Images/road-runner.svg`;
export const ROADRUNNER_MESSAGE_BOARD_ICON = `${cloudFrontUrl}/Icons/Roadrunner_message_board.svg`;
export const DASHBOARDIMG = `${cloudFrontUrl}/Images/HomePageHero-3-30-22.png`;

export const COMMUNITYLOGO = `${cloudFrontUrl}/Images/community-logo.png`;
export const ONEONEMENTORLOGO = `${cloudFrontUrl}/Images/one-one-mentor-logo.png`;
export const INVESTORLOGO = `${cloudFrontUrl}/Images/investor-logo.png`;
export const POWERUPSLOGO = `${cloudFrontUrl}/Images/powerups-logo.png`;

export const DIFFCULTY_ICON = `${cloudFrontUrl}/Icons/difficulty.svg`;
export const TEAM_RECOMMEND_ICON = `${cloudFrontUrl}/Icons/team-recommend.svg`;
export const GREEN_TICK_ICON = `${cloudFrontUrl}/Icons/green-tick.svg`;
export const PENCIL_ICON = `${cloudFrontUrl}/Icons/pencil-icon.svg`;
export const BACKARROW = `${cloudFrontUrl}/Icons/back-arrow.svg`;
export const LOGOICON = `${cloudFrontUrl}/Images/logo.svg`;
export const GOOGLEICON = `${cloudFrontUrl}/Icons/google-icon.svg`;
export const LINKEDINICON = `${cloudFrontUrl}/Icons/linkedIn-icon.svg`;
export const EYEICON = `${cloudFrontUrl}/Icons/eye-icon.svg`;
export const CLOSEDEYEICON = `${cloudFrontUrl}/Icons/close-eye-icon.svg`;
export const CHECKBOXICON = `${cloudFrontUrl}/Icons/checkbox.svg`;
export const FILLEDCHECKBOXICON = `${cloudFrontUrl}/Icons/filled-checkbox.svg`;
export const SIDEBAR_ACTIVE_ICON = `${cloudFrontUrl}/Icons/onboarding-sidebar-active.svg`;
export const SIDEBAR_COMPLETED_ICON = `${cloudFrontUrl}/Icons/onboarding-sidebar-completed.svg`;
export const SIDEBAR_INACTIVE_ICON = `${cloudFrontUrl}/Icons/onboarding-sidebar-inactive.svg`;
export const ROADRUNNERLOGO = `${cloudFrontUrl}/Icons/Roadrunner.svg`;
export const ROADRUNNERLOGOLARGE = `${cloudFrontUrl}/Icons/RoadrunnerLarge.svg`;
export const EMAILICON = `${cloudFrontUrl}/Icons/email.svg`;
export const TEXAS = `${cloudFrontUrl}/maps/us/Texas.svg`;
export const PINMARKER = `${cloudFrontUrl}/Icons/pin-marker-icon.svg`;
export const PINICON = `${cloudFrontUrl}/Icons/pin-icon.svg`;
export const USAMAP = `${cloudFrontUrl}/Images/US-map.svg`;
export const CUSTOMERICON = `${cloudFrontUrl}/Icons/customer-icon.svg`;
export const GLOWBULBICON = `${cloudFrontUrl}/Icons/blow-idea-icon.svg`;
export const CHARGEICON = `${cloudFrontUrl}/Icons/charge-icon.svg`;
export const FUNDINGICON = `${cloudFrontUrl}/Icons/funding-icon.svg`;
export const LAUNCHICON = `${cloudFrontUrl}/Icons/launch-icon.svg`;
export const SMILEICON = `${cloudFrontUrl}/Icons/smile-icon.svg`;
export const MARKETANALYSISICON = `${cloudFrontUrl}/Icons/market-analysis-icon.svg`;
export const BACKBUTTON = `${cloudFrontUrl}/Icons/back-button.svg`;
export const SOFTWAREICON = `${cloudFrontUrl}/Icons/softwareIcon.svg`;
export const HARDWAREICON = `${cloudFrontUrl}/Icons/hardware-icon.svg`;
export const MARKETPLACEICON = `${cloudFrontUrl}/Icons/markeplace-icon.svg`;
export const CONSUMERGOODSICON = `${cloudFrontUrl}/Icons/consumer-goods-icon.svg`;
export const SERVICEICON = `${cloudFrontUrl}/Icons/service-icon.svg`;
export const EGGICON = `${cloudFrontUrl}/Icons/egg.svg`;
export const CHICKICON = `${cloudFrontUrl}/Icons/chick-icon.svg`;
export const MINIRUNNERICON = `${cloudFrontUrl}/Icons/runnerIcon.svg`;
export const MINIROADRUNNERICON = `${cloudFrontUrl}/Icons/road-runner-icon.svg`;
export const COMPANYNAME = `${cloudFrontUrl}/Images/company-name.svg`;
export const FLOWERICON = `${cloudFrontUrl}/Icons/flower-icon.png`;
export const HEARTICON = `${cloudFrontUrl}/Icons/heart-icon.svg`;
export const GRITICON = `${cloudFrontUrl}/Icons/grit-icon.svg`;
export const NETWORKICON = `${cloudFrontUrl}/Icons/network-icon.svg`;
export const EXECUTIONICON = `${cloudFrontUrl}/Icons/execution-icon.svg`;
export const COMMUNITYICON = `${cloudFrontUrl}/Icons/community-icon.svg`;
export const ENERGYICON = `${cloudFrontUrl}/Icons/energy-icon.svg`;
export const EYEVISIONICON = `${cloudFrontUrl}/Icons/eye-vision-icon.svg`;
export const OVERTIMECON = `${cloudFrontUrl}/Icons/over-time-logo.svg`;
export const FULLTIMEICON = `${cloudFrontUrl}/Icons/full-time-logo.svg`;
export const PARTTIMEICON = `${cloudFrontUrl}/Icons/part-time-logo.svg`;
export const SIDEHUSTLEICON = `${cloudFrontUrl}/Icons/side-hustle-logo.svg`;
export const DASHBOARDICON = `${cloudFrontUrl}/Icons/dashboard-icon.svg`;
export const USERSICON = `${cloudFrontUrl}/Icons/users-icon.svg`;
export const WORKSPACEICON = `${cloudFrontUrl}/Icons/workspace-icon.svg`;
export const STARTUPOSCIRCLEICON = `${cloudFrontUrl}/Icons/startupos-circle-icon.svg`;
export const STARTUPOSICON = `${cloudFrontUrl}/Icons/startupos-minified-logo.svg`;
export const SETTINGICON = `${cloudFrontUrl}/Icons/setting-icon.svg`;
export const DRAWERICON = `${cloudFrontUrl}/Icons/drawer-icon.svg`;
export const TIMERICON = `${cloudFrontUrl}/Icons/timer-icon.svg`;
export const EDITICON = `${cloudFrontUrl}/Icons/edit-icon.svg`;
export const TIMERCOLONICON = `${cloudFrontUrl}/Icons/timer-colon.svg`;
export const POWERUP = `${cloudFrontUrl}/Icons/powerUp.svg`;
export const POWERUPTAM = `${cloudFrontUrl}/Images/powerup-tam.svg`;
export const NETWORKICON_GREY = `${cloudFrontUrl}/Icons/network-icon-grey.svg`;
export const NOTESICON = `${cloudFrontUrl}/Icons/notes-icon.svg`;
export const TASKSICON = `${cloudFrontUrl}/Icons/tasks-icon.svg`;
export const STARTUPOSDARKICON = `${cloudFrontUrl}/Icons/startupos-dark.svg`;
export const RADARDARKICON = `${cloudFrontUrl}/Icons/radar-dark.svg`;
export const CURRENTSTREAKDARKICON = `${cloudFrontUrl}/Icons/current-streak-dark.svg`;
export const PROGRESSDARKICON = `${cloudFrontUrl}/Icons/progress-dark.svg`;
export const LOCKICON = `${cloudFrontUrl}/Images/lock-icon.svg`;
export const BLUEDASHBOARDICON = `${cloudFrontUrl}/Icons/blue-dashboard-icon.svg`;
export const FEATUREDPARTNERICON = `${cloudFrontUrl}/Icons/featured-partner-icon.svg`;
export const VideoSampleImg = `${cloudFrontUrl}/Images/Video.svg`;
export const FABICON = `${cloudFrontUrl}/Icons/fab-icon.svg`;
export const NAVIGATIONCIRCLEICON = `${cloudFrontUrl}/Icons/navigationCircle.svg`;
export const TRACKCIRCLEICON = `${cloudFrontUrl}/Icons/trackCircle.svg`;
export const POWERUPSCIRCLEICON = `${cloudFrontUrl}/Icons/powerupsCircle.svg`;
export const CALCULATORCIRCLEICON = `${cloudFrontUrl}/Icons/calculatorCircle.svg`;
export const QUICKSTATCIRCLEICON = `${cloudFrontUrl}/Icons/quickStatCircle.svg`;
export const PARTNERCIRCLEICON = `${cloudFrontUrl}/Icons/partnerCircle.svg`;
export const STARTUPOSCIRCLESMALLICON = `${cloudFrontUrl}/Icons/startupos-circle-icon-small.svg`;
export const INVESTORSRADAR = `${cloudFrontUrl}/Images/investor-radar-img.png`;
export const STARTUPOSSCORE = `${cloudFrontUrl}/Images/startup-os-score-img.png`;
export const WORKSPACE_CARD_ICON = `${cloudFrontUrl}/Icons/workspace-blue-icon.svg`;
export const POWERUPLOGO = `${cloudFrontUrl}/Images/PowerUp Logo.svg`;
export const STARTUPOS_TRANSPARENT = `${cloudFrontUrl}/Icons/startupos-transparent-icon.svg`;
export const SPONSOREDBY = `${cloudFrontUrl}/Icons/sponsoredBy.svg`;
export const GRIDICON = `${cloudFrontUrl}/Icons/grid-icon.svg`;
export const LISTICON = `${cloudFrontUrl}/Icons/list-icon.svg`;
export const VALUE_PROPOSITION = `${cloudFrontUrl}/Images/media.svg`;
export const POWERUP_EMPTY_ICON = `${cloudFrontUrl}/Icons/powerup-empty.png`;
export const MY_STARTUP_CARD_ICON = `${cloudFrontUrl}/Icons/mystartup-card-icon.svg`;
export const STARTUP_LOGO = `${cloudFrontUrl}/Images/startup-logo-default.svg`;
export const VIEW_ICON = `${cloudFrontUrl}/Icons/view-icon.svg`;
export const STEP_CURRENT = `${cloudFrontUrl}/Icons/current-step.svg`;
export const STEP_COMPLETED = `${cloudFrontUrl}/Icons/step-completed.svg`;
export const BLUE_DOT_ICON = `${cloudFrontUrl}/Icons/blue-dot.svg`;
export const NETWORK_LOGO = `${cloudFrontUrl}/Icons/network-logo-icon.svg`;
export const QUESTION_ICON = `${cloudFrontUrl}/Icons/question.svg`;
export const POWERUPKNOWLEDGELOGO = `${cloudFrontUrl}/Images/PowerUP_KnowledgeLogo.png`;
export const GREEN_CHECK_ICON = `${cloudFrontUrl}/Icons/green-check.svg`;
export const FLAG_ICON = `${cloudFrontUrl}/Icons/flag.svg`;
export const MENTOR_NOTE_ICON = `${cloudFrontUrl}/Icons/mentor_note.svg`;
export const PROFILE_ICON = `${cloudFrontUrl}/Icons/profile_icon.svg`;

export const POWERUP_LOGO_WHITE = `${cloudFrontUrl}/Icons/powerup-logo-white.svg`;
export const ROADRUNNERFADEDICON = `${cloudFrontUrl}/Icons/roadrunner-faded.svg`;
export const STARTUPOSFADEDICON = `${cloudFrontUrl}/Icons/startupOS-faded.svg`;
export const POWERUP_IMAGE = `${cloudFrontUrl}/Images/dummy_card.svg`;
export const STARTUPOSFILLEDICON = `${cloudFrontUrl}/Icons/startupOS-filled-icon.svg`;
export const MINIROADRUNNERFILLED = `${cloudFrontUrl}/Icons/mini-roadrunner-filled.svg`;

export const ACTIVE_POWER_UP = `${cloudFrontUrl}/Icons/new-active-powerup.svg`;
export const ACTIVE_PLUGIN = `${cloudFrontUrl}/Icons/new-active-plugin.svg`;
export const ACTIVE_FUSION = `${cloudFrontUrl}/Icons/new-active-fusion.svg`;
export const ACTIVE_DEMODAY = `${cloudFrontUrl}/Icons/new-active-demoday.svg`;

export const COMPLETED_FUSION = `${cloudFrontUrl}/Icons/new-completed-fusion.svg`;
export const COMPLETED_PLUGIN = `${cloudFrontUrl}/Icons/new-completed-plugin.svg`;

export const DEFAULT_POWER_UP = `${cloudFrontUrl}/Icons/powerup-default.svg`;
export const DEFAULT_PLUGIN = `${cloudFrontUrl}/Icons/new-default-plugin.svg`;
export const DEFAULT_FUSION = `${cloudFrontUrl}/Icons/default-fusion2.svg`;
export const DEFAULT_DEMO_DAY = `${cloudFrontUrl}/Icons/default-demoday2.svg`;

export const DEMO_DAY_LOGO = `${cloudFrontUrl}/Icons/Demoday_Logo_Black.svg`;
export const FUSION_LOGO = `${cloudFrontUrl}/Icons/Fusion_Logo_Black.svg`;
export const PLUGIN_LOGO = `${cloudFrontUrl}/Icons/PlugIn_Logo_Black.svg`;
export const COMPLETED_DEFAULT = `${cloudFrontUrl}/Icons/new-completed-powerup-2.svg`;
export const FILLED_POWERUP = `${cloudFrontUrl}/Icons/new-half-filled-powerup.svg`;
export const PROFILE_PLACE_HOLDER = `${cloudFrontUrl}/Images/profile-placeholder.svg`;
export const AVATAR_HOVER = `${cloudFrontUrl}/Icons/avatar-hover.svg`;
export const AVATAR_FOCUS = `${cloudFrontUrl}/Icons/avatar-focus.svg`;
export const AVATAR_DEFAULT = `${cloudFrontUrl}/Icons/avatar-default.svg`;
export const AVATAR_ENABLED = `${cloudFrontUrl}/Icons/avatar-enabled.svg`;

export const PHONE_ICON = `${cloudFrontUrl}/Icons/phone.svg`;
export const EMAIL_ICON = `${cloudFrontUrl}/Icons/email-icon.svg`;
export const LIKE_ICON = `${cloudFrontUrl}/Icons/like-icon.svg`;
export const TEXT_ICON = `${cloudFrontUrl}/Icons/text-icon.svg`;
export const SMILE_ICON = `${cloudFrontUrl}/Icons/smile-icon.svg`;

export const AVATAR_1 = `${cloudFrontUrl}/Images/avatar1.svg`;
export const AVATAR_2 = `${cloudFrontUrl}/Images/avatar2.svg`;
export const AVATAR_3 = `${cloudFrontUrl}/Images/avatar3.svg`;
export const AVATAR_4 = `${cloudFrontUrl}/Images/avatar4.svg`;
export const AVATAR_5 = `${cloudFrontUrl}/Images/avatar5.svg`;
export const AVATAR_6 = `${cloudFrontUrl}/Images/avatar6.svg`;
export const AVATAR_7 = `${cloudFrontUrl}/Images/avatar7.svg`;
export const AVATAR_8 = `${cloudFrontUrl}/Images/avatar8.svg`;
export const AVATAR_9 = `${cloudFrontUrl}/Images/avatar9.svg`;
export const AVATAR_10 = `${cloudFrontUrl}/Images/avatar10.svg`;
export const AVATAR_11 = `${cloudFrontUrl}/Images/avatar11.svg`;
export const AVATAR_12 = `${cloudFrontUrl}/Images/avatar12.svg`;
export const AVATAR_13 = `${cloudFrontUrl}/Images/avatar13.svg`;
export const AVATAR_14 = `${cloudFrontUrl}/Images/avatar14.svg`;
export const AVATAR_15 = `${cloudFrontUrl}/Images/avatar15.svg`;
export const AVATAR_16 = `${cloudFrontUrl}/Images/avatar16.svg`;
export const AVATAR_17 = `${cloudFrontUrl}/Images/avatar17.svg`;
export const AVATAR_18 = `${cloudFrontUrl}/Images/avatar18.svg`;
export const FILE_DOWNLOAD = `${cloudFrontUrl}/Icons/file_download.svg`;
export const RESIZE_ICON = `${cloudFrontUrl}/Icons/resize-icon.svg`;
export const TEMPLATE_1_HEADER = `${cloudFrontUrl}/Images/Templates/Template1/Template-1-header.svg`;
export const TEMP1_IMG1 = `${cloudFrontUrl}/Images/Templates/Template1/StartupOS - Brandstorming Template 1 - 02.svg`;
export const TEMP1_IMG2 = `${cloudFrontUrl}/Images/Templates/Template1/StartupOS - Brandstorming Template 1 - 03.svg`;
export const TEMP1_IMG3 = `${cloudFrontUrl}/Images/Templates/Template1/StartupOS - Brandstorming Template 1 - 04.svg`;
export const TEMP1_IMG4 = `${cloudFrontUrl}/Images/Templates/Template1/StartupOS - Brandstorming Template 1 - 05.svg`;
export const TEMP1_IMG5 = `${cloudFrontUrl}/Images/Templates/Template1/StartupOS - Brandstorming Template 1 - 06.svg`;
export const TEMP1_IMG6 = `${cloudFrontUrl}/Images/Templates/Template1/StartupOS - Brandstorming Template 1 - 07.svg`;
export const TEMP1_IMG7 = `${cloudFrontUrl}/Images/Templates/Template1/StartupOS - Brandstorming Template 1 - 08.svg`;
export const TEMP1_IMG8 = `${cloudFrontUrl}/Images/Templates/Template1/StartupOS - Brandstorming Template 1 - 09.svg`;
export const TEMP1_IMG9 = `${cloudFrontUrl}/Images/Templates/Template1/StartupOS - Brandstorming Template 1 - 10.svg`;
export const TEMP1_IMG10 = `${cloudFrontUrl}/Images/Templates/Template1/StartupOS - Brandstorming Template 1 - 11.svg`;
export const TEMP1_IMG11 = `${cloudFrontUrl}/Images/Templates/Template1/StartupOS - Brandstorming Template 1 - 12.svg`;
export const TEMP1_IMG12 = `${cloudFrontUrl}/Images/Templates/Template1/StartupOS - Brandstorming Template 1 - 13.svg`;
export const TEMP1_IMG13 = `${cloudFrontUrl}/Images/Templates/Template1/StartupOS - Brandstorming Template 1 - 14.svg`;
export const TEMPLATE_2_HEADER = `${cloudFrontUrl}/Images/Templates/Template2/Template-2-header.svg`;
export const TEMP2_IMG1 = `${cloudFrontUrl}/Images/Templates/Template2/StartupOS - Brandstorming Template 2 - 02.svg`;
export const TEMP2_IMG2 = `${cloudFrontUrl}/Images/Templates/Template2/StartupOS - Brandstorming Template 2 - 03.svg`;
export const TEMP2_IMG3 = `${cloudFrontUrl}/Images/Templates/Template2/StartupOS - Brandstorming Template 2 - 04.svg`;
export const TEMP2_IMG4 = `${cloudFrontUrl}/Images/Templates/Template2/StartupOS - Brandstorming Template 2 - 05.svg`;
export const TEMP2_IMG5 = `${cloudFrontUrl}/Images/Templates/Template2/StartupOS - Brandstorming Template 2 - 06.svg`;
export const TEMP2_IMG6 = `${cloudFrontUrl}/Images/Templates/Template2/StartupOS - Brandstorming Template 2 - 07.svg`;
export const TEMP2_IMG7 = `${cloudFrontUrl}/Images/Templates/Template2/StartupOS - Brandstorming Template 2 - 08.svg`;
export const TEMP2_IMG8 = `${cloudFrontUrl}/Images/Templates/Template2/StartupOS - Brandstorming Template 2 - 09.svg`;
export const TEMP2_IMG9 = `${cloudFrontUrl}/Images/Templates/Template2/StartupOS - Brandstorming Template 2 - 10.svg`;
export const TEMP2_IMG10 = `${cloudFrontUrl}/Images/Templates/Template2/StartupOS - Brandstorming Template 2 - 11.svg`;
export const TEMP2_IMG11 = `${cloudFrontUrl}/Images/Templates/Template2/StartupOS - Brandstorming Template 2 - 12.svg`;
export const TEMP2_IMG12 = `${cloudFrontUrl}/Images/Templates/Template2/StartupOS - Brandstorming Template 2 - 13.svg`;
export const TEMP2_IMG13 = `${cloudFrontUrl}/Images/Templates/Template2/StartupOS - Brandstorming Template 2 - 14.svg`;
export const TEMPLATE_3_HEADER = `${cloudFrontUrl}/Images/Templates/Template3/Template-3-header.svg`;
export const TEMP3_IMG1 = `${cloudFrontUrl}/Images/Templates/Template3/StartupOS - Brandstorming Template 3 - 02.svg`;
export const TEMP3_IMG2 = `${cloudFrontUrl}/Images/Templates/Template3/StartupOS - Brandstorming Template 3 - 03.svg`;
export const TEMP3_IMG3 = `${cloudFrontUrl}/Images/Templates/Template3/StartupOS - Brandstorming Template 3 - 04.svg`;
export const TEMP3_IMG4 = `${cloudFrontUrl}/Images/Templates/Template3/StartupOS - Brandstorming Template 3 - 05.svg`;
export const TEMP3_IMG5 = `${cloudFrontUrl}/Images/Templates/Template3/StartupOS - Brandstorming Template 3 - 06.svg`;
export const TEMP3_IMG6 = `${cloudFrontUrl}/Images/Templates/Template3/StartupOS - Brandstorming Template 3 - 07.svg`;
export const TEMP3_IMG7 = `${cloudFrontUrl}/Images/Templates/Template3/StartupOS - Brandstorming Template 3 - 08.svg`;
export const TEMP3_IMG8 = `${cloudFrontUrl}/Images/Templates/Template3/StartupOS - Brandstorming Template 3 - 09.svg`;
export const TEMP3_IMG9 = `${cloudFrontUrl}/Images/Templates/Template3/StartupOS - Brandstorming Template 3 - 10.svg`;
export const TEMP3_IMG10 = `${cloudFrontUrl}/Images/Templates/Template3/StartupOS - Brandstorming Template 3 - 11.svg`;
export const TEMP3_IMG11 = `${cloudFrontUrl}/Images/Templates/Template3/StartupOS - Brandstorming Template 3 - 12.svg`;
export const TEMP3_IMG12 = `${cloudFrontUrl}/Images/Templates/Template3/StartupOS - Brandstorming Template 3 - 13.svg`;
export const TEMP3_IMG13 = `${cloudFrontUrl}/Images/Templates/Template3/StartupOS - Brandstorming Template 3 - 14.svg`;
export const TEMP1_FILE = `${cloudFrontUrl}/Images/Templates/Template1/Template-1-new.pptx`;
export const TEMP2_FILE = `${cloudFrontUrl}/Images/Templates/Template2/Template-2-new.pptx`;
export const TEMP3_FILE = `${cloudFrontUrl}/Images/Templates/Template3/Template-3-new.pptx`;
export const INTERVIEWING_ICON = `${cloudFrontUrl}/Icons/interviewing.svg`;
export const NOTES_ICON = `${cloudFrontUrl}/Icons/notes.svg`;
export const PROFILE_PLACEHOLDER = `${cloudFrontUrl}/Icons/profile-placeholder.svg`;
export const BROWSE_CANDIDATE_ICON = `${cloudFrontUrl}/Icons/browse-candidate.svg`;
export const JOURNEY_SMILE_ICON = `${cloudFrontUrl}/Icons/journey-smile-icon.svg`;
export const POWERUP_IMG = `${cloudFrontUrl}/Images/Powerup_PNG.png`;
export const PROFILE_PLACEHOLDER_PNG = `${cloudFrontUrl}/Images/profile-placeholder-png.png`;
export const ONLINE_PREDICTION_ICON = `${cloudFrontUrl}/Icons/online_prediction.svg`;
export const TUNGSTEN_ICON = `${cloudFrontUrl}/Icons/tungsten.svg`;
export const RIGHT_ARROW = `${cloudFrontUrl}/Icons/rightArrow.svg`;
export const TUNGSTEN_PNG_ICON = `${cloudFrontUrl}/Icons/purple-tungsten.png`;
export const SUCCESS_ENVELOPE = `${cloudFrontUrl}/Images/success-envelope.svg`;
export const SVB_PARTNER = `${cloudFrontUrl}/Images/svb-partner.svg`;
export const ADVANCE_PARTNER = `${cloudFrontUrl}/Images/advance-partner.svg`;
export const GUSTO_PARTNER = `${cloudFrontUrl}/Images/gusto-partner.svg`;
export const STARTEGY_PARTNER = `${cloudFrontUrl}/Images/startegy-partner.svg`;
export const CHECK_LIST = `${cloudFrontUrl}/Icons/check-list.svg`;
export const PEOPLE_PURPLE = `${cloudFrontUrl}/Icons/people-purple.svg`;
export const PEOPLE_DARK_BLUE = `${cloudFrontUrl}/Icons/people_dark_blue.svg`;
export const DEFAULT_STARTUP_LOGO = `${cloudFrontUrl}/Images/default-startup-logo.svg`;
export const MENTOR_ICON = `${cloudFrontUrl}/Images/MentorIcon.svg`;
export const DELETE_ICON = `${cloudFrontUrl}/Icons/delete-icon.svg`;
export const MENTOR_PROFILE = `${cloudFrontUrl}/Icons/MentorProfile.svg`;

export const BANNER_LG_LOGO = `${cloudFrontUrl}/Images/Rectangle_lg.svg`;
export const BANNER_MD_LOGO = `${cloudFrontUrl}/Images/Rectangle_md.svg`;
export const BANNER_SM_LOGO = `${cloudFrontUrl}/Images/Rectangle_sm.svg`;
export const RECTANGLE_HEADER = `${cloudFrontUrl}/Icons/Rectangle_header.svg`;
export const RECTANGLE_HEADER_SM = `${cloudFrontUrl}/Icons/Rectangle_header_sm.svg`;

export const PERSON_SEARCH = `${cloudFrontUrl}/Icons/person_search_svg.svg`;
export const DEFAULT_AVATAR = `${cloudFrontUrl}/Icons/default-avatar.svg`;
export const VELVET_ELECTRIC = `${cloudFrontUrl}/Icons/velvetElectric.svg`;
export const SPARK_ORANGE = `${cloudFrontUrl}/Icons/sparkOrange.svg`;
export const BLUE_FUSION = `${cloudFrontUrl}/Icons/blueFusion.svg`;
export const SCHOOL = `${cloudFrontUrl}/Icons/school.svg`;
export const POWERUP_TEXT_ICON = `${cloudFrontUrl}/Images/powerup-text-icon.svg`;
export const COMMUNITY_TEXT_ICON = `${cloudFrontUrl}/Images/community-text-icon.svg`;
export const MENTORSHIP_TEXT_ICON = `${cloudFrontUrl}/Images/mentor-ship-text-icon.svg`;
export const RADAR_TEXT_ICON = `${cloudFrontUrl}/Images/radar-text-icon.svg`;
export const SUCCESS_ICON = `${cloudFrontUrl}/Icons/sucees-icon.svg`;
export const IN_PROGRESS_ICON = `${cloudFrontUrl}/Icons/InProgressIcon.svg`;
export const LIST_VIEW_ICON = `${cloudFrontUrl}/Icons/listViewIcon.svg`;
export const GRID_VIEW_ICON = `${cloudFrontUrl}/Icons/gridViewIcon.svg`;
export const POWERUP_VERSION_ICON = `${cloudFrontUrl}/Icons/powerupVersionIcon.svg`;
export const GOOGLE_LOGO = `${cloudFrontUrl}/Icons/google_logo.svg`;
export const LINKEDIN_LOGO = `${cloudFrontUrl}/Icons/linkedin_logo.svg`;
export const GOOGLE_NAME_LOGO = `${cloudFrontUrl}/Icons/google-name-logo.svg`;
export const LINKEDIN_NAME_LOGO = `${cloudFrontUrl}/Icons/linkedin-name-logo.svg`;
export const EMAIL_PLACEHOLDER = `${cloudFrontUrl}/Icons/email-placeholder.svg`;
export const TEAM_PROFILE_PLACEHOLDER = `${cloudFrontUrl}/Images/trailing-icon.svg`;
export const POWERUP_BG_ICON = `${cloudFrontUrl}/Icons/powerup-bg-icon.svg`;
export const DEFAULT_CARD_IMAGE = `${cloudFrontUrl}/Images/default_card_image.svg`;
export const REFERENCE_ICON = `${cloudFrontUrl}/Icons/reference_tag_icon.svg`;
export const NAV_WORK = `${cloudFrontUrl}/Icons/nav-work.svg`;
export const NAV_SETTINGS = `${cloudFrontUrl}/Icons/nav-settings.svg`;
export const NAV_MESSAGES = `${cloudFrontUrl}/Icons/nav-messages.svg`;
export const NAV_LOGOUT = `${cloudFrontUrl}/Icons/nav-logout.svg`;
export const NAV_EVENT = `${cloudFrontUrl}/Icons/nav-event.svg`;
export const NAV_DISCOVERY = `${cloudFrontUrl}/Icons/nav-discovery.svg`;
export const EMAIL_CONFIRMATION_IMG = `${cloudFrontUrl}/Images/verify-email.svg`;
export const POTTED_PLANT = `${cloudFrontUrl}/Icons/pottedPlant.svg`;
export const DIVERSITY = `${cloudFrontUrl}/Icons/diversityIcon.svg`;
export const PAYMENT_CARD_ICON = `${cloudFrontUrl}/Icons/payment-card-icon.svg`;
export const PAYMENT_CARD_ICON_BLUE = `${cloudFrontUrl}/Icons/payments-card-blue.svg`;
export const RAINY_CLOUD_PRIMARY = `${cloudFrontUrl}/Icons/rainy-cloud-primary.svg`;
export const INVENTORY_YELLOW = `${cloudFrontUrl}/Icons/inventory-yellow.svg`;
export const GREET_BLUE = `${cloudFrontUrl}/Icons/greet-blue.svg`;
export const FOUR_LEAF_CLOVER = `${cloudFrontUrl}/Icons/four-leaf-clover-fill.svg`;
export const ANGEL_ICON = `${cloudFrontUrl}/Icons/angel-icon.svg`;
export const CAPITAL_ICON = `${cloudFrontUrl}/Icons/capital-icon.svg`;
export const CORPORATE_ICON = `${cloudFrontUrl}/Icons/corporate-icon.svg`;
export const FAMILY_ICON = `${cloudFrontUrl}/Icons/family-icon.svg`;
export const FOUNDATION_ICON = `${cloudFrontUrl}/Icons/foundation-icon.svg`;
export const ROADRUNNERIMG = `${cloudFrontUrl}/Images/Roadrunner1.svg`;

export const AUTOMOTIVE = `${cloudFrontUrl}/Icons/automotive.svg`;
export const MOVIE = `${cloudFrontUrl}/Icons/movie.svg`;
export const MAP = `${cloudFrontUrl}/Icons/map.svg`;
export const LOCK = `${cloudFrontUrl}/Icons/lock.svg`;
export const LOCAL_SHIPPING = `${cloudFrontUrl}/Icons/local_shipping.svg`;
export const HEALTH = `${cloudFrontUrl}/Icons/health.svg`;
export const FLIGHT = `${cloudFrontUrl}/Icons/flight.svg`;
export const FASTFOOD = `${cloudFrontUrl}/Icons/fastfood.svg`;
export const FACTORY = `${cloudFrontUrl}/Icons/factory.svg`;
export const DVR = `${cloudFrontUrl}/Icons/dvr.svg`;
export const EDUCATION = `${cloudFrontUrl}/Icons/education.svg`;
export const DURABLE_GOODS = `${cloudFrontUrl}/Icons/durable-goods.svg`;
export const CONSUMER_SERVICES = `${cloudFrontUrl}/Icons/consumerServices.svg`;
export const CONSTRUCTION = `${cloudFrontUrl}/Icons/construction.svg`;
export const COMPUTER = `${cloudFrontUrl}/Icons/computer.svg`;
export const CLEANTECH = `${cloudFrontUrl}/Icons/cleantech.svg`;
export const CHEMICAL = `${cloudFrontUrl}/Icons/chemical.svg`;
export const CALL = `${cloudFrontUrl}/Icons/call.svg`;
export const CABLE = `${cloudFrontUrl}/Icons/cable.svg`;
export const BOLT = `${cloudFrontUrl}/Icons/bolt.svg`;
export const BLOCKCHAIN = `${cloudFrontUrl}/Icons/blockchain.svg`;
export const BANK = `${cloudFrontUrl}/Icons/bank.svg`;
export const ALT_ROUTE = `${cloudFrontUrl}/Icons/alt_route.svg`;
export const AGRICULTURE = `${cloudFrontUrl}/Icons/agriculture.svg`;
export const WIFI = `${cloudFrontUrl}/Icons/wifi.svg`;
export const VERIFIED_USER = `${cloudFrontUrl}/Icons/verified_user.svg`;
export const VACCINE = `${cloudFrontUrl}/Icons/vaccines.svg`;
export const SPA = `${cloudFrontUrl}/Icons/spa.svg`;
export const SHOPPING = `${cloudFrontUrl}/Icons/shopping.svg`;
export const SAVINGS = `${cloudFrontUrl}/Icons/savings.svg`;
export const PUBLIC = `${cloudFrontUrl}/Icons/public.svg`;
export const PRECISION = `${cloudFrontUrl}/Icons/precision_manufacturing.svg`;
export const PACKAGE = `${cloudFrontUrl}/Icons/package.svg`;
export const OIL_BARREL = `${cloudFrontUrl}/Icons/oil_barrel.svg`;
export const MUSIC = `${cloudFrontUrl}/Icons/music.svg`;
export const CAPITAL = `${cloudFrontUrl}/Icons/capital.svg`;
export const MONITORING_SALES = `${cloudFrontUrl}/Icons/monitoring-sales.svg`;
export const HACKER = `${cloudFrontUrl}/Icons/hacker.svg`;
export const BLUE_GRIT_ICON = `${cloudFrontUrl}/Icons/blue-grit-icon.svg`;
export const DIVERSITY4 = `${cloudFrontUrl}/Icons/diversity4.svg`;
export const POTTED_PLANT_BLUE = `${cloudFrontUrl}/Icons/potted-plant-blue.svg`;
export const FOLLOW_RIGHT = `${cloudFrontUrl}/Icons/follow-right.svg`;
export const SVB_OUTLINED = `${cloudFrontUrl}/Icons/svb-outline-logo.svg`;
export const SVEN_LOGO = `${cloudFrontUrl}/Icons/sven-logo.svg`;

export const SVEN = `${cloudFrontUrl}/Images/SVEN.png`;
export const SVEN_SMALL = `${cloudFrontUrl}/Images/SVEN_SMALL.png`;

export const MODEL_RED_3D = `${cloudFrontUrl}/Icons/3d-model-red.svg`;
export const AI_PINK = `${cloudFrontUrl}/Icons/ai-pink.svg`;
export const AWS_BROWN = `${cloudFrontUrl}/Icons/aws-brown.svg`;
export const CYCLE_RED = `${cloudFrontUrl}/Icons/cycle-red.svg`;
export const DATABASE_PRIMARY = `${cloudFrontUrl}/Icons/database-primary.svg`;
export const WORKSPACE_TITLE_ICON = `${cloudFrontUrl}/Icons/workspace-title-icon.svg`;
export const LOGO_FULL_WHITE = `${cloudFrontUrl}/Images/StartupOS_Logo_Full(White).png`;
export const ROADRUNNER_WHITE = `${cloudFrontUrl}/Images/Roadrunner(White).png`;
export const PINK_MAGNET = `${cloudFrontUrl}/Icons/pink-magnet.svg`;
export const GOOGLE_COLORED = `${cloudFrontUrl}/Icons/google-colored.svg`;
export const LINKED_IN_UPDATED = `${cloudFrontUrl}/Icons/linkedin-updated.svg`;

export const NEW_COMMUNITY_ICON = `${cloudFrontUrl}/Icons/community-icon.svg`;
export const DISCOVERY_ICON = `${cloudFrontUrl}/Icons/discovery-icon.svg`;
export const NEW_NOTE_ICON = `${cloudFrontUrl}/Icons/note-icon.svg`;
export const MEET_ICON = `${cloudFrontUrl}/Icons/meet.svg`;
export const ZOOM_ICON = `${cloudFrontUrl}/Icons/zoom.svg`;
export const ICS_FILE_ICON = `${cloudFrontUrl}/Icons/ics-file.svg`;
export const MS_OUTLOOK_ICON = `${cloudFrontUrl}/Icons/ms-outlook.svg`;
export const MS_OFFICE_ICON = `${cloudFrontUrl}/Icons/ms-office-icon.svg`;
export const PROGRESS_PRIMARY = `${cloudFrontUrl}/Icons/progress-primary.svg`;
export const CALCULATOR_ICON = `${cloudFrontUrl}/Icons/calculator.svg`;
export const CONTINUE_JOURNEY_ICON = `${cloudFrontUrl}/Icons/continue_journey.svg`;
export const COUNTDOWN_ICON = `${cloudFrontUrl}/Icons/countdown.svg`;
export const NEW_SVB_ICON = `${cloudFrontUrl}/Icons/new_svb_logo.svg`;
export const PROGRESS_ICON = `${cloudFrontUrl}/Icons/progress.svg`;
export const SO_SCORE_ICON = `${cloudFrontUrl}/Icons/so_score.svg`;
export const VOUCH_IMG_ICON = `${cloudFrontUrl}/Icons/vouch_img.svg`;

export const ADVANCE_LOGO = `${cloudFrontUrl}/Images/partners/advance-logo.png`;
export const CARTA_LOGO = `${cloudFrontUrl}/Images/partners/carta-logo.png`;
export const GUSTO_LOGO = `${cloudFrontUrl}/Images/partners/gusto-logo.png`;
export const SVB_LOGO = `${cloudFrontUrl}/Images/partners/svb-logo.png`;
export const VOUCH_LOGO = `${cloudFrontUrl}/Images/partners/vouch-logo.png`;
export const ZB_LOGO = `${cloudFrontUrl}/Images/partners/zb-logo.png`;

export const DISABLED_LINKEDIN_LOGO = `${cloudFrontUrl}/Icons/linkedin_disabled_icon.svg`;
export const DISABLED_GOOGLE_LOGO = `${cloudFrontUrl}/Icons/google_disabled_icon.svg`;

export const SCORE_CIRCLE_IMG = `${cloudFrontUrl}/Icons/score-circle.svg`;
export const PIPEDRIVEIMG = `${cloudFrontUrl}/Images/pipedrive.svg`;

export const ICONIC_TOY_ICON = `${cloudFrontUrl}/Icons/iconic-toy.svg`;
export const MUSIC_DEVICE_ICON = `${cloudFrontUrl}/Icons/music-device.svg`;
export const SCREEN_CONTENT_ICON = `${cloudFrontUrl}/Icons/screen-content.svg`;
export const PITCH_PERFECT_2_BANNER = `${cloudFrontUrl}/Images/PitchPerfect2-Banner.jpg`;
export const PITCH_DAY_PREP_BANNER = `${cloudFrontUrl}/Images/StartupOS-Events-Page-Banner-v1b.jpg`;
export const MVP_SOLUTIONS_BANNER = `${cloudFrontUrl}/Images/mvp_solutions.svg`;
export const MVP_NO_DATA_IMG = `${cloudFrontUrl}/Images/mvp-no-data-img.svg`;

export const AUDIO_CASSETTE_ICON = `${cloudFrontUrl}/Icons/audio-cassette.svg`;
export const BICYCLE_ICON = `${cloudFrontUrl}/Icons/bicycle.svg`;
export const CINEMA_ICON = `${cloudFrontUrl}/Icons/cinema.svg`;
export const FRISBEE_ICON = `${cloudFrontUrl}/Icons/frisbee.svg`;
export const INTERNET_ICON = `${cloudFrontUrl}/Icons/internet.svg`;
export const IPOD_ICON = `${cloudFrontUrl}/Icons/ipod.svg`;
export const RECORD_PLAYER_ICON = `${cloudFrontUrl}/Icons/record-player.svg`;
export const RUBIK_CUBE_ICON = `${cloudFrontUrl}/Icons/rubik's-cube.svg`;
export const SKATES_ICON = `${cloudFrontUrl}/Icons/skates.svg`;
export const SMART_SPEAKER_ICON = `${cloudFrontUrl}/Icons/smart-speakers.svg`;
export const SPINNER_ICON = `${cloudFrontUrl}/Icons/spinner.svg`;
export const STREAMING_ICON = `${cloudFrontUrl}/Icons/streaming.svg`;
export const TV_ICON = `${cloudFrontUrl}/Icons/tv-icon.svg`;
export const VCR_ICON = `${cloudFrontUrl}/Icons/vcr-icon.svg`;
export const WALKMAN_ICON = `${cloudFrontUrl}/Icons/walkman.svg`;
export const INCOMPLETE_PROFILE_ICON = `${cloudFrontUrl}/Icons/incompleted_profile.svg`;
export const UPLOAD_IMAGE_ICON = `${cloudFrontUrl}/Icons/upload-img-icon.svg`;
export const COVER_DEFAULT_IMAGE = `${cloudFrontUrl}/Images/cover_image_default.svg`;
export const CAMERA_ICON = `${cloudFrontUrl}/Icons/camera_icon.svg`;
export const NEW_STARTUP_LOGO = `${cloudFrontUrl}/Icons/new_startup_logo.svg`;
export const NEW_PROFILE_LOGO = `${cloudFrontUrl}/Icons/new_profile_logo.svg`;
export const PITCH_DECK_LOGO = `${cloudFrontUrl}/Icons/pitch_deck_logo.svg`;
export const PPT_UPLOAD = `${cloudFrontUrl}/Icons/ppt_upload.svg`;

//Cover image template's
export const COVER_IMAGE_TEMPLATE_1 = `${cloudFrontUrl}/coverImageTemplates/10-NowFundraising.png`;
export const COVER_IMAGE_TEMPLATE_2 = `${cloudFrontUrl}/coverImageTemplates/10.2-NowFundraising.png`;
export const COVER_IMAGE_TEMPLATE_3 = `${cloudFrontUrl}/coverImageTemplates/11-MentorMagic.png`;
export const COVER_IMAGE_TEMPLATE_4 = `${cloudFrontUrl}/coverImageTemplates/11.2-MentorMagic.png`;
export const COVER_IMAGE_TEMPLATE_5 = `${cloudFrontUrl}/coverImageTemplates/12-SuccessfromAdversity.png`;
export const COVER_IMAGE_TEMPLATE_6 = `${cloudFrontUrl}/coverImageTemplates/13-FindYourInnerStrength.png`;
export const COVER_IMAGE_TEMPLATE_7 = `${cloudFrontUrl}/coverImageTemplates/14-UnstoppableDrive.png`;
export const COVER_IMAGE_TEMPLATE_8 = `${cloudFrontUrl}/coverImageTemplates/14.2-UnstoppableDrive.png`;
export const COVER_IMAGE_TEMPLATE_9 = `${cloudFrontUrl}/coverImageTemplates/17-NetPromoterScore-17.png`;
export const COVER_IMAGE_TEMPLATE_10 = `${cloudFrontUrl}/coverImageTemplates/17.2-NetPromoterScore-17.png`;
export const COVER_IMAGE_TEMPLATE_11 = `${cloudFrontUrl}/coverImageTemplates/21-SWCustomerFeedback_NPSSurvey-22.png`;
export const COVER_IMAGE_TEMPLATE_12 = `${cloudFrontUrl}/coverImageTemplates/21.2-SWCustomerFeedback_NPSSurvey-22.png`;
export const COVER_IMAGE_TEMPLATE_13 = `${cloudFrontUrl}/coverImageTemplates/3-CelebratingLatinxVoices.png`;
export const COVER_IMAGE_TEMPLATE_14 = `${cloudFrontUrl}/coverImageTemplates/4-Black-OwnedBusiness.png`;
export const COVER_IMAGE_TEMPLATE_15 = `${cloudFrontUrl}/coverImageTemplates/4.2-Black-OwnedBusiness.png`;
export const COVER_IMAGE_TEMPLATE_16 = `${cloudFrontUrl}/coverImageTemplates/5-BeanAlly.png`;
export const COVER_IMAGE_TEMPLATE_17 = `${cloudFrontUrl}/coverImageTemplates/5.2-BeanAlly.png`;
export const COVER_IMAGE_TEMPLATE_18 = `${cloudFrontUrl}/coverImageTemplates/6-Ally.png`;
export const COVER_IMAGE_TEMPLATE_19 = `${cloudFrontUrl}/coverImageTemplates/7DiversityInclusion.png`;
export const COVER_IMAGE_TEMPLATE_20 = `${cloudFrontUrl}/coverImageTemplates/7.2-DisabilityInclusion.png`;
export const COVER_IMAGE_TEMPLATE_21 = `${cloudFrontUrl}/coverImageTemplates/7.3-DisabilityInclusion.png`;
export const COVER_IMAGE_TEMPLATE_22 = `${cloudFrontUrl}/coverImageTemplates/8-FoundersWearManyHats.png`;
export const COVER_IMAGE_TEMPLATE_23 = `${cloudFrontUrl}/coverImageTemplates/9-ThinkIndependentlyTogether.png`;
export const COVER_IMAGE_TEMPLATE_24 = `${cloudFrontUrl}/coverImageTemplates/9.2-ThinkIndependentlyTogether.png`;

export const PITCH_PERFECT_TEMPLATE = `${cloudFrontUrl}/Images/Templates/Pitch Perfect 2 Pitch Deck Template_Final.pptx`;
export const EVENT_BANNER = `${cloudFrontUrl}/Images/Event_Get_Started_v2.jpg`;

export const INVESTOR_DASHBOARD_BG = `${cloudFrontUrl}/Images/InvestorDashboardBG.png`;

export const UPDATED_DASHBOARD_ICON = `${cloudFrontUrl}/Icons/updated-dashboard.svg`;
export const UPDATED_DISCOVERY_ICON = `${cloudFrontUrl}/Icons/updated-dicovery-icon.svg`;
export const UPDATED_EVENT_ICON = `${cloudFrontUrl}/Icons/updated-event-icon.svg`;
export const UPDATED_FOUNDER_ICON = `${cloudFrontUrl}/Icons/updated-founder.svg`;
export const UPDATED_INVESTOR_ICON = `${cloudFrontUrl}/Icons/updated-investor.svg`;
export const UPDATED_LOGO_ICON = `${cloudFrontUrl}/Icons/updated-logo-new.svg`;
export const UPDATED_MEETING_ICON = `${cloudFrontUrl}/Icons/updated-meeting-icon.svg`;
export const UPDATED_MENTOR_ICON = `${cloudFrontUrl}/Icons/updated-mentor.svg`;
export const UPDATED_MESSAGE_ICON = `${cloudFrontUrl}/Icons/updated-message-icon.svg`;
export const UPDATED_NETWORK_ICON = `${cloudFrontUrl}/Icons/updated-network-icon.svg`;
export const UPDATED_WORKSPACE_ICON = `${cloudFrontUrl}/Icons/updated-workspace.svg`;
export const UPDATED_STARTUP_ICON = `${cloudFrontUrl}/Icons/updated-startup.svg`;

export const DARK_NETWORK_ICON = `${cloudFrontUrl}/Icons/dark-network.svg`;
export const DARK_MESSAGE_ICON = `${cloudFrontUrl}/Icons/dark-message.svg`;
export const DARK_UPLOAD_ICON = `${cloudFrontUrl}/Icons/dark-upload.svg`;

export const NEW_LINKEDIN_ICON = `${cloudFrontUrl}/Icons/new-linkedin.svg`;
export const NEW_TWITTER_ICON = `${cloudFrontUrl}/Icons/new-twitter.svg`;

export const STACK_ICON = `${cloudFrontUrl}/Icons/stack-icon.svg`;
export const WATCHLIST_ICON = `${cloudFrontUrl}/Icons/watchlist-icon.svg`;
export const PORTFOLIO_ICON = `${cloudFrontUrl}/Icons/portfolio-icon.svg`;

export const UPDATED_LIST_VIEW_ICON = `${cloudFrontUrl}/Icons/listview-icon.svg`;
export const UPDATED_GRID_VIEW_ICON = `${cloudFrontUrl}/Icons/gridview-icon.svg`;
export const UPDATED_SEARCH_ICON = `${cloudFrontUrl}/Icons/updated-search-icon.svg`;
export const UPDATED_FILTER_ICON = `${cloudFrontUrl}/Icons/updated-filter.svg`;
export const INVESTOR_DASHBOARD_BG1 = `${cloudFrontUrl}/Images/background-mountain.jpg`;

export const UPDATED_WHITE_DASHBOARD_ICON = `${cloudFrontUrl}/Icons/white-dashboard-updated.svg`;
export const UPDATED_WHITE_WORKSPACE_ICON = `${cloudFrontUrl}/Icons/white-workspace.svg`;
export const UPDATED_WHITE_STARTUP_ICON = `${cloudFrontUrl}/Icons/white-startup.svg`;

export const UPDATED_WHITE_CALENDER_ICON = `${cloudFrontUrl}/Icons/white-calender.svg`;
export const UPDATED_WHITE_FILE_ICON = `${cloudFrontUrl}/Icons/white-file.svg`;
export const UPDATED_WHITE_MEETING_ICON = `${cloudFrontUrl}/Icons/white-meeting.svg`;
export const UPDATED_WHITE_MESSAGE_ICON = `${cloudFrontUrl}/Icons/white-message.svg`;
export const UPDATED_WHITE_NETWORK_ICON = `${cloudFrontUrl}/Icons/white-network.svg`;

export const UPDATED_GREY_GRID_ICON = `${cloudFrontUrl}/Icons/updated-grey-grid.svg`;
export const UPDATED_GREY_LIST_ICON = `${cloudFrontUrl}/Icons/updated-grey-list.svg`;

export const LOCATION_ICON = `${cloudFrontUrl}/Icons/location-icon.svg`;
export const PITCH_ICON = `${cloudFrontUrl}/Icons/updated-pitch-icon.svg`;
export const SCOUTING_ICON = `${cloudFrontUrl}/Icons/scouting-icon.svg`;

export const GREY_PITCH_ICON = `${cloudFrontUrl}/Icons/updated_grey_pitch.svg`;
export const GREY_SCOUTING_ICON = `${cloudFrontUrl}/Icons/grey-scouting.svg`;
export const CROSS_ARROW_ICON = `${cloudFrontUrl}/Icons/cross-arrow.svg`;
export const COLORED_ARROW_ICON = `${cloudFrontUrl}/Icons/colored-arrow.svg`;
export const EVENT_BANNER_1 = `${cloudFrontUrl}/Images/Event_Get_Started_v2.jpg`;
export const EVENT_BANNER_2 = `${cloudFrontUrl}/Images/Event_Coming_Soon_Placeholder.jpg`;
export const EVENT_BANNER_3 = `${cloudFrontUrl}/Images/Event_Get_Started_with_StartupOS_v2.jpg`;
export const EVENT_BANNER_4 = `${cloudFrontUrl}/Images/Event_StartupOS_Events_Page_Banner.jpg`;
export const EVENT_BANNER_5 = `${cloudFrontUrl}/Images/Event_StartupOS_Events_Page_Banner_v2.jpg`;
export const EVENT_BANNER_6 = `${cloudFrontUrl}/Images/Event_Pitch_Perfect_2.jpg`;

export const ROCKET_ICON = `${cloudFrontUrl}/Icons/rocket.svg`;
export const UPDATED_GROUP_ICON = `${cloudFrontUrl}/Icons/updated_group.svg`;
export const UPDATED_INDUSTRY_ICON = `${cloudFrontUrl}/Icons/updated_industry.svg`;
export const BLACK_DASHBOARD = `${cloudFrontUrl}/Icons/black-dashboard.svg`;

export const PROFILE_BG = `${cloudFrontUrl}/Images/profile-BG.jpg`;
export const FIRE_ICON = `${cloudFrontUrl}/Icons/fire-icon.svg`;
export const EVENT_BANNER_7 = `${cloudFrontUrl}/Images/banner-personal-20230113.png`;
export const EVENT_BANNER_8 = `${cloudFrontUrl}/Images/banner-survey-20230120.png`;

export const ACCOUNT_PROFILE_VIDEO_IMG = `${cloudFrontUrl}/Images/account-profile-video-img.svg`;
export const EYE_MASK = `${cloudFrontUrl}/Icons/eye-mask.svg`;
export const STARTUP_PROFILE_BACKGROUND = `${cloudFrontUrl}/Images/scouting-report-images/startup-profile-background.png`;
export const FOUNDER_COMMENT_BACKGROUND = `${cloudFrontUrl}/Images/scouting-report-images/founder-comment-background.png`;
export const SCOUTING_MENTOR_BACKGROUND_ONE = `${cloudFrontUrl}/Images/scouting-report-images/scouting-report-mentor-1.png`;
export const SCOUTING_MENTOR_BACKGROUND_TWO = `${cloudFrontUrl}/Images/scouting-report-images/scouting-report-mentor-2.png`;
export const SCOUTING_REPORT_STATIC_ONE = `${cloudFrontUrl}/Images/scouting-report-static-1.png`;
export const SCOUTING_REPORT_STATIC_TWO = `${cloudFrontUrl}/Images/scouting-report-static-2.png`;
export const SCOUTING_REPORT_PITCH_ONE = `${cloudFrontUrl}/Images/scouting-report-images/scouting-report-pitch-1.png`;
export const SCOUTING_REPORT_PITCH_TWO = `${cloudFrontUrl}/Images/scouting-report-images/scouting-report-pitch-2.png`;
export const SCOUTING_REPORT_PITCH_THREE = `${cloudFrontUrl}/Images/scouting-report-images/scouting-report-pitch-3.png`;
export const MENTOR_INTERACTIONS_BACKGROUND = `${cloudFrontUrl}/Images/scouting-report-images/mentor-interactions.png`;
export const ACME_COMPANY_ICON = `${cloudFrontUrl}/Icons/scouting-report-icons/acma-icon-scout.png`;
export const WORLD_ICON = `${cloudFrontUrl}/Icons/scouting-report-icons/world-earth-globe-icon-scout.png`;
export const POWER_CELL_ICON = `${cloudFrontUrl}/Icons/scouting-report-icons/power-cell-icon.png`;
export const UPLOAD_ICON = `${cloudFrontUrl}/Icons/scouting-report-icons/upload-icon-scout.png`;
export const SPEECH_BUBBLE_ICON = `${cloudFrontUrl}/Icons/scouting-report-icons/speech-bubble-icon-scout.png`;
export const CHART_ICON = `${cloudFrontUrl}/Icons/scouting-report-icons/chart-icon-scout.png`;
export const BINOCULARS_ICON = `${cloudFrontUrl}/Icons/scouting-report-icons/binoculars-icon-scout.png`;
export const PAPERCLIP_ICON = `${cloudFrontUrl}/Icons/scouting-report-icons/paperclip-icon-scout.png`;
export const ACME_VIDEO_STILL = `${cloudFrontUrl}/Images/scouting-report-images/acme-video-still.png`;
export const EDUCATION_LOGO = `${cloudFrontUrl}/Icons/educationLogo.svg`;

export const FOUNDER_STORY_VIDEO_STILL = `${cloudFrontUrl}/Images/scouting-report-images-2/founder-story-video-still.png`;
export const BACK_OFFICE_READINESS_BACKGROUND = `${cloudFrontUrl}/Images/scouting-report-images-2/back-office-readiness-background.png`;
export const PMF_BACKGROUND_CENTER = `${cloudFrontUrl}/Images/scouting-report-images-2/pmf-background-center.png`;
export const PMF_BACKGROUND_LEFT = `${cloudFrontUrl}/Images/scouting-report-images-2/pmf-background-left.png`;
export const PMF_BACKGROUND_RIGHT = `${cloudFrontUrl}/Images/scouting-report-images-2/pmf-background-right.png`;
export const SOCIAL_INSIGHTS_BACKGROUND = `${cloudFrontUrl}/Images/scouting-report-images-2/social-insights-background.png`;
export const STARTUP_PROFILE_BACKGROUND_2 = `${cloudFrontUrl}/Images/scouting-report-images-2/startup-profile-background-2.png`;
export const TAMSAMSOM_BACKGROUND = `${cloudFrontUrl}/Images/scouting-report-images-2/tamsamsom-background.png`;
export const TAMSAMSOM_LOGO_WITH_LINES = `${cloudFrontUrl}/Images/scouting-report-images-2/tamsamsom-logo-with-lines.png`;
export const TAMSAMSOM_LOGO_WITH_TEXT = `${cloudFrontUrl}/Images/scouting-report-images-2/tamsamsom-logo-with-text.png`;
export const FOUNDER_COMMENT_BACKGROUND_2 = `${cloudFrontUrl}/Images/scouting-report-images-2/founder-comment-background-2.png`;
export const MENTOR_INTERACTIONS_BACKGROUND_2 = `${cloudFrontUrl}/Images/scouting-report-images-2/mentor-interactions-background-2.png`;

export const ARROW_DOWN_ICON = `${cloudFrontUrl}/Icons/arrow-down-icon.svg`;
export const INVESTOR_FILTER_ICON = `${cloudFrontUrl}/Icons/filter-updated.svg`;
export const UPDATED_DELETE_ICON = `${cloudFrontUrl}/Icons/new-delete-icon.svg`;
export const SAVE_ICON = `${cloudFrontUrl}/Icons/save-icon.svg`;
export const COMPANY_PLACEHOLDER = `${cloudFrontUrl}/Icons/company-placeholder.svg`;

export const EVENTS_ICON_NEW = `${cloudFrontUrl}/Icons/events-new-icon.svg`;
export const INVITE_RED_ICON = `${cloudFrontUrl}/Icons/invite-red-icon.svg`;
export const MARKETPLACE_ICON_NEW = `${cloudFrontUrl}/Icons/marketplace-new-icon.svg`;
export const MEETING_ICON_NEW = `${cloudFrontUrl}/Icons/meeting-new-icon.svg`;
export const MESSAGE_ICON_NEW = `${cloudFrontUrl}/Icons/message-new-icon.svg`;
export const NOTES_ICON_NEW = `${cloudFrontUrl}/Icons/notes-new-icon.svg`;
export const NOTIFICATION_ICON_NEW = `${cloudFrontUrl}/Icons/notification-new-icon.svg`;
export const NETWORK_ICON_NEW = `${cloudFrontUrl}/Icons/network_icon_new.svg`;

export const ROCKET_LAUNCH_ICON = `${cloudFrontUrl}/Icons/rocket-launch-light.svg`;
export const LOCATION_NEW_ICON = `${cloudFrontUrl}/Icons/location-v1-icon.svg`;
export const GAUGE_LIGHT_ICON = `${cloudFrontUrl}/Icons/gauge-light.svg`;
export const STAR_FOLDER_ICON = `${cloudFrontUrl}/Icons/folder-simple-star-light.svg`;
export const PINK_CHECK_ICON = `${cloudFrontUrl}/Icons/check-circle-pink.svg`;

export const CALENDER_WHITE_ICON = `${cloudFrontUrl}/Icons/calendar-white-v1.svg`;
export const MEETING_WHITE_ICON = `${cloudFrontUrl}/Icons/meeting-white-v1.svg`;
export const MESSAGES_WHITE_ICON = `${cloudFrontUrl}/Icons/messages-white-v1.svg`;
export const NETWORK_WHITE_ICON = `${cloudFrontUrl}/Icons/network-white-v1.svg`;

export const FOUNDER_DASHBOARD_BG = `${cloudFrontUrl}/Images/Founder-dash-bg.png`;
export const UPDATED_LIST_VIEW_ICON_NEW = `${cloudFrontUrl}/Icons/Icon-tabular-grey.png`;
export const UPDATED_LIST_VIEW_PURPLE_ICON_NEW = `${cloudFrontUrl}/Icons/Icon-tabular-purple.png`;
export const UPDATED_GRID_VIEW_ICON_NEW = `${cloudFrontUrl}/Icons/icon-card-grey.png`;
export const UPDATED_GRID_VIEW_PURPLE_ICON_NEW = `${cloudFrontUrl}/Icons/icon-card-purple.png`;

export const UPDATED_ALL_STARTUP_ICON = `${cloudFrontUrl}/Icons/all-startup-v1.svg`;
export const UPDATED_PORTFOLIO_ICON = `${cloudFrontUrl}/Icons/portfolio-v1.svg`;
export const UPDATED_WATCHLIST_ICON = `${cloudFrontUrl}/Icons/watchlist-v1.svg`;
export const UPDATED_LOGO_ICON_NEW = `${cloudFrontUrl}/Icons/logo-v1.svg`;
export const UPDATED_PLATFORM_JOURNEY_ICON = `${cloudFrontUrl}/Icons/platform_journey.svg`;

export const CARTA_OFFER = `${cloudFrontUrl}/Images/carta-offer.svg`;

export const ANGULAR_LOGO = `${cloudFrontUrl}/Icons/angular-logo.svg`;
export const CLOUD_ARROW_UP_ICON = `${cloudFrontUrl}/Icons/cloud-arrow-up-.svg`;
export const USER_GROUP_ICON = `${cloudFrontUrl}/Icons/user-group.svg`;
export const USERS_THREE_ICON = `${cloudFrontUrl}/Icons/UsersThreeIcon.svg`;
export const USERS_FOUR_ICON = `${cloudFrontUrl}/Icons/users-four.svg`;

export const BUILDINGS_ICON = `${cloudFrontUrl}/Icons/buildings.svg`;
export const USER_LIGHT_ICON = `${cloudFrontUrl}/Icons/user-light.svg`;
export const ROCKET_UP_ICON = `${cloudFrontUrl}/Icons/rocket-up.svg`;
export const CROW_ICON = `${cloudFrontUrl}/Icons/crown.svg`;

export const CALCULATOR_V1_ICON = `${cloudFrontUrl}/Icons/calculator-v1.svg`;
export const BLACK_SCOUTING_ICON = `${cloudFrontUrl}/Icons/black-scouting.svg`;

export const CALCULATOR_BOTTOM_ICON = `${cloudFrontUrl}/Images/calculator-card-bottom.svg`;
export const CALCULATOR_TOP_ICON = `${cloudFrontUrl}/Images/calculator-card-top.svg`;

export const MESSAGES_BLACK_ICON = `${cloudFrontUrl}/Icons/messages-black-v1.svg`;
export const USERS_BLACK_ROUNDED_ICON = `${cloudFrontUrl}/Icons/users-black-rounded.svg`;
export const STICKER_BLACK = `${cloudFrontUrl}/Icons/sticker-black.svg`;

export const STARTUP_PROFILE_BG_DOTS = `${cloudFrontUrl}/Images/startup-profile-bg-dots.svg`;
export const STARTUP_PROFILE_BG_LINE_ONE = `${cloudFrontUrl}/Images/startup-profile-bg-line-1.svg`;
export const STARTUP_PROFILE_BG_LINE_TWO = `${cloudFrontUrl}/Images/startup-profile-bg-line-2.svg`;
export const STARTUP_PROFILE = `${cloudFrontUrl}/Icons/startup-profile.svg`;
export const CURRENCY_DOLLAR_SIMPLE_ICON = `${cloudFrontUrl}/Icons/currency-dollar-simple.svg`;
export const TREE_ICON = `${cloudFrontUrl}/Icons/tree.svg`;
export const LEAF_ICON = `${cloudFrontUrl}/Icons/leaf.svg`;
export const ARROW_FAT_LINEUP_ICON = `${cloudFrontUrl}/Icons/arrow-fat-line-up.svg`;
export const COINS_ICON = `${cloudFrontUrl}/Icons/coins.svg`;
export const CURRENCY_DOLLAR_CIRCLE_ICON = `${cloudFrontUrl}/Icons/currency-circle-dollar.svg`;
export const CODESANDBOX_ICON = `${cloudFrontUrl}/Icons/codesandbox.svg`;
export const GLOBE_HEMISPHERE_EAST_ICON = `${cloudFrontUrl}/Icons/globe-hemisphere-east.svg`;
export const MOBILE_DEVICE_ICON = `${cloudFrontUrl}/Icons/mobile-device.svg`;
export const UPLOAD_SIMPLE_ICON = `${cloudFrontUrl}/Icons/upload-simple.svg`;
export const VIDEO_CAMERA_SIMPLE_ICON = `${cloudFrontUrl}/Icons/video-camera.svg`;
export const INFO_ICON = `${cloudFrontUrl}/Icons/info_icon.svg`;

export const STARTUP_DEFAULT_LOGO = `${cloudFrontUrl}/Images/startup-default-logo-v1.png`;
export const PRESENTATION_CHARTLINE_ICON = `${cloudFrontUrl}/Icons/presentation-chart-line.svg`;
export const PPT_YELLOW_ICON = `${cloudFrontUrl}/Icons/ppt-yellow.svg`;
export const ARROWS_OUT_ICON = `${cloudFrontUrl}/Icons/arrows-out.svg`;
export const THREE_DOT_CIRCLE_ICON = `${cloudFrontUrl}/Icons/three-dot-circle.svg`;
export const VIDEO_ICON = `${cloudFrontUrl}/Icons/video-icon.svg`;
export const BOOK_ICON = `${cloudFrontUrl}/Icons/book-icon.svg`;

export const BUILDING_ICON_V1 = `${cloudFrontUrl}/Icons/building-icon-v1.svg`;
export const TEAM_ICON_V1 = `${cloudFrontUrl}/Icons/team-icon-v1.svg`;

export const CHECK_CIRCLE_OUTLINE_GRAY = `${cloudFrontUrl}/Icons/check-circle-outline-gray.svg`;
export const CHECK_CIRCLE_OUTLINE_GREEN = `${cloudFrontUrl}/Icons/check-circle-outline-green.svg`;
export const DELETE_ICON_V1 = `${cloudFrontUrl}/Icons/delete-icon-v1.svg`;
export const EYE_ICON_V1 = `${cloudFrontUrl}/Icons/eye-icon-v1.svg`;
export const EYE_ICON_OFF = `${cloudFrontUrl}/Icons/eye-off-icon.svg`;

export const STARTUP_SMALL_LOGO_ICON = `${cloudFrontUrl}/Icons/startup_small_logo.svg`;
export const STARTUP_BG_ICON = `${cloudFrontUrl}/Icons/startup_bg.svg`;

export const DEFAULT_COVER_IMG = `${cloudFrontUrl}/Images/default-cover-image-v1.svg`;
export const CAMERA_ICON_V1 = `${cloudFrontUrl}/Icons/camera-icon-v1.svg`;

export const SEAL_CHECK = `${cloudFrontUrl}/Icons/SealCheck.svg`;
export const MOUNTAIN_ICON = `${cloudFrontUrl}/Icons/MountainIcon.svg`;
export const USER_CIRCLE = `${cloudFrontUrl}/Icons/UserCircle.svg`;
export const CALENDAR_BLANK = `${cloudFrontUrl}/Icons/calendar-blank.svg`;
export const GRADUATION_CAP = `${cloudFrontUrl}/Icons/graduation-cap.svg`;
export const CHECK_CIRCLE_UNFILLED_OUTLINE_GREEN = `${cloudFrontUrl}/Icons/check-circle-unfilled-outline-green.svg`;

export const WHITE_NOTE_ICON = `${cloudFrontUrl}/Icons/white-note-icon.png`;
export const WHITE_STORE_ICON = `${cloudFrontUrl}/Icons/white-store-icon.png`;
export const WHITE_NOTIFICATION_ICON = `${cloudFrontUrl}/Icons/notification-white-icon.png`;
export const SOS_LOGO_BLACK = `${cloudFrontUrl}/Icons/StartupOSBlackBolt.svg`;

export const VOUCH_LOGO_PARTNER_CARD = `${cloudFrontUrl}/Images/vouch.png`;
export const VOUCH_CARD = `${cloudFrontUrl}/Images/vouch_card.png`;
export const TECHSTARS_CARD = `${cloudFrontUrl}/Images/techstars-card.png`;

export const ACCOUNT_PROFILE_CARD = `${cloudFrontUrl}/Images/account-profile-powerup.png`;
export const STARTUP_PROFILE_CARD = `${cloudFrontUrl}/Images/startup-profile.png`;

export const CHAT_MAIN_BLACK = `${cloudFrontUrl}/Icons/chat-black-main.svg`;
export const CALENDAR_MAIN_BLACK = `${cloudFrontUrl}/Icons/calendar-black-main.svg`;
export const NETWORK_MAIN_BLACK = `${cloudFrontUrl}/Icons/network-black.svg`;
export const DISCOVERY_MAIN_BLACK = `${cloudFrontUrl}/Icons/discovery-black.svg`;
export const BELL_RINGING = `${cloudFrontUrl}/Icons/bell-ringing-02.svg`;

export const FEED_LIKE_ICON = `${cloudFrontUrl}/Icons/like-v1.svg`;
export const FEED_COMMENT_ICON = `${cloudFrontUrl}/Icons/comment-icon.svg`;

export const CREATE_POST_ICON = `${cloudFrontUrl}/Icons/create-post.svg`;
export const DOWNLOAD_V1_ICON = `${cloudFrontUrl}/Icons/download-v1.svg`;
export const EDIT_V1_ICON = `${cloudFrontUrl}/Icons/edit-v1.svg`;
export const FEED_ICON = `${cloudFrontUrl}/Icons/feed-icon.svg`;
export const FILE_V1_ICON = `${cloudFrontUrl}/Icons/file-v1.svg`;
export const UPLOAD_V1_ICON = `${cloudFrontUrl}/Icons/upload-v1.svg`;
export const WHITE_LIKE_V1_ICON = `${cloudFrontUrl}/Icons/white-like-v1.svg`;
export const WHITE_FEED_ICON = `${cloudFrontUrl}/Icons/white-feed-icon.svg`;

export const RED_DELETE_ICON = `${cloudFrontUrl}/Icons/delete-red-icon.svg`;
export const EDIT_PURPLE_ICON = `${cloudFrontUrl}/Icons/edit-purple-icon.svg`;

export const IMPRESSION_ICON = `${cloudFrontUrl}/Icons/impression-icon.svg`;
export const LATEST_UPDATE_ICON = `${cloudFrontUrl}/Icons/latest-update-icon.svg`;
export const PLAN_ICON = `${cloudFrontUrl}/Icons/plan-icon-v1.svg`;

export const ENERGY_CIRCLE_YELLOW_ICON = `${cloudFrontUrl}/Icons/energy-circle-yellow.svg`;
export const HEART_OUTLINED_EMPTY_ICON = `${cloudFrontUrl}/Icons/heart-outlined-empty.svg`;
export const HEART_PINK_FILLED_ICON = `${cloudFrontUrl}/Icons/heart-pink-filled.svg`;
export const CALENDAR_BLANK_BLACK = `${cloudFrontUrl}/Icons/calendar-blank-black.svg`;
export const HEART_BLACK_FILLED_ICON = `${cloudFrontUrl}/Icons/heart-black-filled.svg`;
export const USERS_PURPLE_OUTLINE_ICON = `${cloudFrontUrl}/Icons/users-purple-outline.svg`;
export const EYE_OFF_BLACK_FILLED = `${cloudFrontUrl}/Icons/eye-off-black-filled.svg`;
export const MESSAGE_DOTS_PURPLE_ICON = `${cloudFrontUrl}/Icons/message-dots-circle-purple.svg`;

export const BOOK_ICON_V2 = `${cloudFrontUrl}/Icons/book-icon-v2.svg`;
export const BUILDING_ICON = `${cloudFrontUrl}/Icons/building-icon-v2.svg`;
export const INVESTOR_ICON = `${cloudFrontUrl}/Icons/investor-icon-v2.svg`;
export const LOCATION_ICON_V2 = `${cloudFrontUrl}/Icons/location-icon-v2.svg`;
export const MIGHTY_MENTOR = `${cloudFrontUrl}/Icons/mighty-mentor-icon.svg`;
export const PEN_ICON = `${cloudFrontUrl}/Icons/pen-icon-v2.svg`;
export const PRODUCT_ICON = `${cloudFrontUrl}/Icons/product-offering-v2.svg`;

export const STARS_ICON = `${cloudFrontUrl}/Icons/starts-icon-v2.svg`;
export const WATCH_ICON = `${cloudFrontUrl}/Icons/watch-icon-v2.svg`;

export const SEND_PURPLE_OUTLINED_ICON = `${cloudFrontUrl}/Icons/send-purple-outlined.svg`;
export const CALENDAR_BLANK_PURPLE = `${cloudFrontUrl}/Icons/calendar-blank-purple.svg`;

export const HEART_ICON_V2 = `${cloudFrontUrl}/Icons/heart-icon-v2.svg`;
export const MESSAGE_ICON_V2 = `${cloudFrontUrl}/Icons/message-icon-v2.svg`;
export const CALEDNER_ICON_V2 = `${cloudFrontUrl}/Icons/caleder-icon-v2.svg`;

export const PURPLE_DEGREE_ICON = `${cloudFrontUrl}/Icons/purple-degree-icon.svg`;
export const PURPLE_PROFILE_ICON = `${cloudFrontUrl}/Icons/purple-profile-icon.svg`;
export const GREY_BUILDING_ICON = `${cloudFrontUrl}/Icons/grey_building-icon.svg`;
export const HEART_WHITE_FILLED_ICON = `${cloudFrontUrl}/Icons/heart-white-filled-icon.svg`;

export const UPLOAD_ICON_V2 = `${cloudFrontUrl}/Icons/upload-icon-v2.svg`;
export const CLOCK_LIGHT_ICON = `${cloudFrontUrl}/Icons/ph_clock-light.svg`;
export const HOURGLASS_LIGHT_ICON = `${cloudFrontUrl}/Icons/ph_hourglass-light.svg`;
export const ENVELOPE_LIGHT_ICON = `${cloudFrontUrl}/Icons/ph_envelope-simple-light.svg`;
export const CHECK_CIRCLE_WAVY_LIGHT = `${cloudFrontUrl}/Icons/check-circle-wavy-light.svg`;

export const FOUNDER_NETWORKING_CARD_PLACEHOLDER = `${cloudFrontUrl}/Images/founder-networking-card-placeholder.png`;
export const INVESTOR_NETWORKING_CARD_PLACEHOLDER = `${cloudFrontUrl}/Images/investor-networking-card-placeholder.png`;
export const MENTOR_NETWORKING_CARD_PLACEHOLDER = `${cloudFrontUrl}/Images/mentor-networking-card-placeholder.png`;
export const GREY_BUILDING_ICON_V2 = `${cloudFrontUrl}/Icons/grey-building-icon-v2.svg`;

export const EDIT_ICON_V2 = `${cloudFrontUrl}/Icons/edit_v2_icon.svg`;
export const TRASH_ICON = `${cloudFrontUrl}/Icons/trash_v2_icon.svg`;
export const TRASH_ICON_V3 = `${cloudFrontUrl}/Icons/trash-03.svg`;

export const CALENDER_ICON = `${cloudFrontUrl}/Icons/calender_v2_icon.svg`;
export const CHECK_BOX_ICON_V2 = `${cloudFrontUrl}/Icons/checkbox_Icon_V2.svg`;
export const UNCHECKED_ICON_V2 = `${cloudFrontUrl}/Icons/unchecked_v2_Icon.svg`;
export const PURPLE_ADD_ICON = `${cloudFrontUrl}/Icons/purple_button.svg`;

export const ARROW_CIRCLE_BROKEN_RIGHT = `${cloudFrontUrl}/Icons/arrow-circle-broken-right.svg`;
export const CELL_SIGNAL_FULL = `${cloudFrontUrl}/Icons/cell-signal-full.svg`;
export const LIGHTNING = `${cloudFrontUrl}/Icons/lightning.svg`;
export const CODEPEN = `${cloudFrontUrl}/Icons/codepen.svg`;
export const NETWORKING_CARD_COMMON_PLACEHOLDER = `${cloudFrontUrl}/Images/networking-card-common-placeholder.png`;

export const ALERT_HEX = `${cloudFrontUrl}/Icons/alert-hex.svg`;
export const LIGHTBULB = `${cloudFrontUrl}/Icons/lightbulb.svg`;
export const GIFT = `${cloudFrontUrl}/Icons/gift.svg`;
export const SCALES = `${cloudFrontUrl}/Icons/scales.svg`;
export const ATOM = `${cloudFrontUrl}/Icons/atom.svg`;
export const USERS_TWO = `${cloudFrontUrl}/Icons/users-two.svg`;
export const STACKED_COINS = `${cloudFrontUrl}/Icons/coins-stacked.svg`;
export const BAR_CHART = `${cloudFrontUrl}/Icons/bar-chart.svg`;
export const AD_TESTING_SURVEY = `${cloudFrontUrl}/Images/AdTesting-Survey.svg`;
export const LIKERT_SURVEY = `${cloudFrontUrl}/Images/Likert-Survey.svg`;
export const LOGO_TESTING_SURVEY = `${cloudFrontUrl}/Images/LogoTesting-Survey.svg`;
export const NPS_SURVEY = `${cloudFrontUrl}/powerup-things/nps_survey_new.svg`;
export const PMF_SURVEY = `${cloudFrontUrl}/Images/PMF-Survey.svg`;
export const ROCKET_ICON_V2 = `${cloudFrontUrl}/Icons/rocket-v2.svg`;
export const PMF_PREVIEW = `${cloudFrontUrl}/powerup-things/pmf_survey.svg`;

export const MAIL_ICON = `${cloudFrontUrl}/Icons/mail-icon-v2.svg`;
export const RESULT_ICON = `${cloudFrontUrl}/Icons/result-icon-v2.svg`;

export const MEASURE_SS = `${cloudFrontUrl}/Images/measure-ss.svg`;
export const RATING_SS = `${cloudFrontUrl}/Images/rating-ss.svg`;
export const AD_TESTING_SS = `${cloudFrontUrl}/Images/Ad-testing-ss.svg`;
export const LIKERT_SS = `${cloudFrontUrl}/Images/Likert-ss.svg`;
export const LOGO_SS = `${cloudFrontUrl}/Images/Logo-testing-ss.svg`;
export const NPS_SS = `${cloudFrontUrl}/Images/NPS-ss.svg`;

export const DOWNLOAD_ARROW = `${cloudFrontUrl}/Icons/download-arrow.svg`;
export const DATABASE_ICON = `${cloudFrontUrl}/Icons/database-icon.svg`;
export const UNACTIVE_COMMUNITY_ICON = `${cloudFrontUrl}/Icons/new-side-navigation-icons/Unactive_Community.png`;
export const UNACTIVE_DASHBOARD_ICON = `${cloudFrontUrl}/Icons/new-side-navigation-icons/Unactive_Dashboard.png`;
export const UNACTIVE_DISCOVERY_ICON = `${cloudFrontUrl}/Icons/new-side-navigation-icons/Unactive_Discovery.png`;
export const UNACTIVE_HOME_ICON = `${cloudFrontUrl}/Icons/new-side-navigation-icons/Unactive_Home.png`;
export const UNACTIVE_FEED_ICON = `${cloudFrontUrl}/Icons/new-side-navigation-icons/Unactive_SocialFeed.png`;
export const UNACTIVE_STARTUP_GRID_ICON = `${cloudFrontUrl}/Icons/new-side-navigation-icons/Unactive_StartupGrid.png`;
export const UNACTIVE_STARTUP_PROFILE_ICON = `${cloudFrontUrl}/Icons/new-side-navigation-icons/Unactive_StartupProfile.png`;
export const HAMBURGER_V2 = `${cloudFrontUrl}/Icons/new-side-navigation-icons/Hamburger_V2.png`;
export const LOGO_MARK_V2 = `${cloudFrontUrl}/Icons/new-side-navigation-icons/Logomark_v2.png`;

export const ACTIVE_COMMUNITY_ICON = `${cloudFrontUrl}/Icons/new-side-navigation-icons/Active_Community.png`;
export const ACTIVE_DASHBOARD_ICON = `${cloudFrontUrl}/Icons/new-side-navigation-icons/Active_Dashboard.png`;
export const ACTIVE_DISCOVERY_ICON = `${cloudFrontUrl}/Icons/new-side-navigation-icons/Active_Discovery.png`;
export const ACTIVE_HOME_ICON = `${cloudFrontUrl}/Icons/new-side-navigation-icons/Active_Home.png`;
export const ACTIVE_MARKETPLACE_ICON = `${cloudFrontUrl}/Icons/new-side-navigation-icons/Active_Marketplace.png`;
export const ACTIVE_RESOURCE_CENTER_ICON = `${cloudFrontUrl}/Icons/new-side-navigation-icons/Active_ResourceCenter.png`;
export const ACTIVE_FEED_ICON = `${cloudFrontUrl}/Icons/new-side-navigation-icons/Active_Social_Feed.png`;
export const ACTIVE_STARTUP_GRID_ICON = `${cloudFrontUrl}/Icons/new-side-navigation-icons/Active_Startup_Grid.png`;
export const ACTIVE_STARTUP_PROFILE_ICON = `${cloudFrontUrl}/Icons/new-side-navigation-icons/Active_Startup_Profile.png`;

export const PAGE_COMMUNITY_ICON = `${cloudFrontUrl}/Icons/new-side-navigation-icons/Page_Community.png`;
export const PAGE_DASHBOARD_ICON = `${cloudFrontUrl}/Icons/new-side-navigation-icons/Page_Dashboard.png`;
export const PAGE_DISCOVERY_ICON = `${cloudFrontUrl}/Icons/new-side-navigation-icons/Page_Discovery.png`;
export const PAGE_HOME_ICON = `${cloudFrontUrl}/Icons/new-side-navigation-icons/Page_Home.png`;
export const PAGE_MARKETPLACE_ICON = `${cloudFrontUrl}/Icons/new-side-navigation-icons/Page_Marketplace.png`;
export const PAGE_RESOURCE_CENTER_ICON = `${cloudFrontUrl}/Icons/new-side-navigation-icons/Page_Resource_Center.png`;
export const PAGE_FEED_ICON = `${cloudFrontUrl}/Icons/new-side-navigation-icons/Page_SocialFeed.png`;
export const PAGE_STARTUP_GRID_ICON = `${cloudFrontUrl}/Icons/new-side-navigation-icons/Page_StartupGrid.png`;
export const PAGE_STARTUP_PROFILE_ICON = `${cloudFrontUrl}/Icons/new-side-navigation-icons/Page_StartupProfile.png`;

export const BLACK_ACCOUNT_PROFILE_ICON = `${cloudFrontUrl}/Icons/new-side-navigation-icons/Black_Account_Profile.png`;
export const ACTIVE_ACCOUNT_PROFILE_ICON = `${cloudFrontUrl}/Icons/new-side-navigation-icons/Active_Account_Profile.png`;

export const PROXY_INVESTOR_BADGE_ICON = `${cloudFrontUrl}/Icons/proxy-investor-badge.svg`;
export const UPLOAD_ICON_V3 = `${cloudFrontUrl}/Icons/upload-icon-v3.svg`;
export const UPLOAD_ICON_V4 = `${cloudFrontUrl}/Icons/no-border-upload.svg`;

export const WAITLIST_CARTA = `${cloudFrontUrl}/Images/waitlist-carta.png`;
export const WAITLIST_STRIPE = `${cloudFrontUrl}/Images/waitlist-stripe.png`;
export const WAILIST_SALESFORCE = `${cloudFrontUrl}/Images/waitlist-salesforce.png`;

export const BANNER_WEEKLY_QA = `${cloudFrontUrl}/Images/Events/Weekly Q&A.png`;
export const BANNER_MEET_STARTUP = `${cloudFrontUrl}/Images/Events/Meet the New StartupOS v2.png`;
export const BANNER_COFEE_CHILL = `${cloudFrontUrl}/Images/Events/Coffee_&_Chill_v4.png`;
export const BANNER_LIGHTNING_ROUNDS = `${cloudFrontUrl}/Images/Events/Lightning Rounds with Paul.png`;
export const BANNER_DEMO_DAY = `${cloudFrontUrl}/Images/Events/Demo Day!.png`;
export const BANNER_DEMO_DAY_NO_BUTTON = `${cloudFrontUrl}/Images/Events/Demo Day! (No Button).png`;

export const WAITLIST_CARTA_V1 = `${cloudFrontUrl}/Images/waitlist-carta-v1.png`;
export const LINK_PURPLE = `${cloudFrontUrl}/Icons/link-purple.svg`;

export const X_LOGO = `${cloudFrontUrl}/Icons/logo-black.png`;

export const FINANCIAL_EVENT_BANNER = `${cloudFrontUrl}/Images/Events/financial-event_v1.png`;

export const DRAWER_CLOSE_BLACK = `${cloudFrontUrl}/Icons/drawer-close-black.svg`;

export const CHECK_SQUARE_ICON = `${cloudFrontUrl}/Icons/check-square.svg`;
export const CHECK_SQUARE_BROKEN_ICON = `${cloudFrontUrl}/Icons/check-square-broken.svg`;
export const PLUS_FILLED_BLACK_CIRCLE_ICON = `${cloudFrontUrl}/Icons/plus-filled-black-circle.svg`;

export const EDIT_WHITE_ICON_V2 = `${cloudFrontUrl}/Icons/edit_v2_white_icon.svg`;

export const LINK_PARTNER_INFO_CIRCLE = `${cloudFrontUrl}/Icons/link-partner-info-circle.svg`;
export const LINK_PARTNER_XERO_SMALL = `${cloudFrontUrl}/Icons/link-partner-xero-small.svg`;
export const LINK_PARTNER_CARTA_SMALL = `${cloudFrontUrl}/Icons/link-partner-carta-small.svg`;
export const LINK_PARTNER_PLAID_SMALL = `${cloudFrontUrl}/Icons/link-partner-plaid-small.svg`;
export const LINK_PARTNER_MIXPANEL_SMALL = `${cloudFrontUrl}/Icons/link-partner-mixpanel-small.svg`;
export const LINK_PARTNER_SALESFORCE_SMALL = `${cloudFrontUrl}/Icons/link-partner-salesforce-small.svg`;
export const LINK_PARTNER_QUICKBOOKS_SMALL = `${cloudFrontUrl}/Icons/link-partner-quickbooks-small.svg`;
export const LINK_PARTNER_STRIPE_SMALL = `${cloudFrontUrl}/Icons/link-partner-stripe-small.svg`;
export const LINK_PARTNER_HUBSPOT_SMALL = `${cloudFrontUrl}/Icons/link-partner-hubspot-small.svg`;
export const LINK_PARTNER_EXCEL_SMALL = `${cloudFrontUrl}/Icons/excel-icon-v1.svg`;
export const LINK_PARTNER_GOOGLE_SHEET = `${cloudFrontUrl}/Icons/google-sheet-v1.svg`;

export const CALEDNER_GRAY_ICON = `${cloudFrontUrl}/Icons/calendar-gray.svg`;
export const GLOBE_ICON_V1 = `${cloudFrontUrl}/Icons/globe-icon-v1.svg`;
export const LIGHTNING_ICON_V1 = `${cloudFrontUrl}/Icons/lightning-icon-v1.svg`;
export const PPT_ICON_V1 = `${cloudFrontUrl}/Icons/presentation-chart-icon-v1.svg`;

export const Get_PREPPED_FOR_STARTUP_EVENT = `${cloudFrontUrl}/Images/Events/Get_Prepped_for_StartupOS_Pitch_Events.png`;
export const SILICON_VALLEY_BANK_SO_EVENT = `${cloudFrontUrl}/Images/Events/Silicon_Valley_Bank_&_StartupOS_Pitch_Event.png`;

export const SEARCH_ICON_V2 = `${cloudFrontUrl}/Icons/search-icon-v2.svg`;
export const LINK_PARTNER_INFO_CIRCLE_PURPLE = `${cloudFrontUrl}/Icons/link-partner-info-circle-purple.svg`;
export const LINK_PARTNER_CHECK_CIRCLE_PURPLE = `${cloudFrontUrl}/Icons/link-partner-check-circle-purple.svg`;

export const LINK_PARTNER_SUCCESS_CHECK_GREEN = `${cloudFrontUrl}/Icons/link-partner-success-check-green.svg`;
export const LINK_PARTNER_GRID_PURPLE = `${cloudFrontUrl}/Icons/link-partner-grid-purple.svg`;
export const LINK_PARTNER_CHECKBOX_CHECKED = `${cloudFrontUrl}/Icons/link_partner_checkbox_checked.svg`;
export const LINK_PARTNER_CHECKBOX_UNCHECKED = `${cloudFrontUrl}/Icons/link_partner_checkbox_unchecked.svg`;
export const EXPIRED_BANNER_LIGHTNING_ROUNDS = `${cloudFrontUrl}/Images/Events/EXPIRED_Lightning_Rounds_with_Paul.png`;

export const EXPIRED_GET_PREPPED_FOR_STARTUP_EVENT = `${cloudFrontUrl}/Images/Events/Expired_Get_Prepped_for_StartupOS_Events!.png`;

export const PITCH_PANEL_PRACTIVE_WITH_MENTORS_EVENT = `${cloudFrontUrl}/Images/Events/Pitch_Lab_with_StartupOS_Mentors.png`;

export const AVATAR_ICON_V2 = `${cloudFrontUrl}/Icons/avatar-icon-v2.svg`;
export const LOGOUT_ICON_V2 = `${cloudFrontUrl}/Icons/logout-icon-v2.svg`;
export const SETTING_ICON_V3 = `${cloudFrontUrl}/Icons/setting-icon-v3.svg`;
export const SUBSCRIPTION_ICON_V2 = `${cloudFrontUrl}/Icons/subscription-icon-v2.svg`;
export const SWITCH_ICON_V2 = `${cloudFrontUrl}/Icons/switch-icon-v2.svg`;
export const PURPLE_SWITCH_ICON_V2 = `${cloudFrontUrl}/Icons/purple-switch-icon-v2.svg`;

export const ALERT_ICON_V1 = `${cloudFrontUrl}/Icons/alert-circle-v1.svg`;
export const BACK_ARROW_ICON_V2 = `${cloudFrontUrl}/Icons/arrow-narrow-left-v2.svg`;
export const CLOSE_ICON_V2 = `${cloudFrontUrl}/Icons/x-close-v1.svg`;
export const PITCH_PANEL_PRACTIVE_WITH_MENTORS_EVENT_EXPIRED = `${cloudFrontUrl}/Images/Events/Pitch_Panel_Practice_with_Mentors_Expired.png`;

export const STARS_ICON_V2 = `${cloudFrontUrl}/Icons/stars-icon-v2.svg`;

export const JOIN_SVB_SO_ART_BASE_EVENT = `${cloudFrontUrl}/Images/Events/Join_SVB_&_StartupOS_at_Art_Basel.png`;

export const VIDEO_ICON_V2 = `${cloudFrontUrl}/Icons/video-icon-v2.svg`;

export const GRID_BLACK_ICON_V1 = `${cloudFrontUrl}/Icons/grid-black-v1.svg`;
export const GRID_PURPLE_ICON_V1 = `${cloudFrontUrl}/Icons/grid-purple-v1.svg`;
export const MENU_BLACK_ICON_V1 = `${cloudFrontUrl}/Icons/menu-black-v1.svg`;
export const MENU_PURPLE_ICON_V1 = `${cloudFrontUrl}/Icons/menu-purple-v1.svg`;

export const WRONG_ICON_V2 = `${cloudFrontUrl}/Icons/wrong-icon-v2.svg`;
export const BUILDING_ICON_V3 = `${cloudFrontUrl}/Icons/building-icon-v3.svg`;
export const BUSINESS_MODEL_ICON_V3 = `${cloudFrontUrl}/Icons/business-model-v3.svg`;
export const CALCULATION_ICON_V2 = `${cloudFrontUrl}/Icons/calculation-icon-v2.svg`;
export const CORRECT_ICON_V2 = `${cloudFrontUrl}/Icons/correct-icon-v2.svg`;
export const CURRENECY_ICON_V2 = `${cloudFrontUrl}/Icons/currency-icon-v2.svg`;
export const DOLLAR_ICON_V2 = `${cloudFrontUrl}/Icons/dollar-icon-v2.svg`;
export const INDUSTRY_ICON_V3 = `${cloudFrontUrl}/Icons/industry-icon-v3.svg`;
export const LOCATION_ICON_V3 = `${cloudFrontUrl}/Icons/location-icon-v3.svg`;
export const PRODUCT_STATE_ICON_V2 = `${cloudFrontUrl}/Icons/product-state-icon-v2.svg`;
export const REVENUE_MODEL_ICON_V3 = `${cloudFrontUrl}/Icons/revenu-model-v3.svg`;
export const ROCKET_ICON_V3 = `${cloudFrontUrl}/Icons/rocket-icon-v3.svg`;
export const START_ICON_V3 = `${cloudFrontUrl}/Icons/star-icon-v3.svg`;
export const NOTIFICATION_BELL = `${cloudFrontUrl}/Icons/bell-03.svg`;

export const GREY_EDIT_ICON_V2 = `${cloudFrontUrl}/Icons/grey-edit-icon-v2.svg`;

export const FB_ICON_V2 = `${cloudFrontUrl}/Icons/fb-icon-v2.svg`;
export const TWITTER_ICON_V2 = `${cloudFrontUrl}/Icons/twitter-icon-v2.svg`;
export const EMAIL_ICON_V2 = `${cloudFrontUrl}/Icons/email-icon-v2.svg`;
export const BIDIRECTIONAL_ICON = `${cloudFrontUrl}/Icons/bidirectional-icon.svg`;
export const TEAM_ICON_V2 = `${cloudFrontUrl}/Icons/team-icon-v2.svg`;

export const CUBE_ICON_V2 = `${cloudFrontUrl}/Icons/cube-outline-icon-v1.svg`;

export const ARROW_UP_RIGHT_ICON_V1 = `${cloudFrontUrl}/Icons/arrow-up-right-v1.svg`;

export const COMMUNITY_CARD_PLACEHOLDER = `${cloudFrontUrl}/Images/community-card-placeholder.png`;

export const PURPLE_STAR_ICON = `${cloudFrontUrl}/Icons/purple-star-icon.svg`;

export const SPEAKER_ICON = `${cloudFrontUrl}/Icons/speaker-icon.svg`;

export const GREY_USER_ICON = `${cloudFrontUrl}/Icons/light_grey_user_Icon.svg`;

export const WHITE_GRID_ICON_V2 = `${cloudFrontUrl}/Icons/white-grid-icon-v2.svg`;
export const BLACK_GRID_ICON_V2 = `${cloudFrontUrl}/Icons/black-grid-icon-v2.svg`;

export const WHITE_LIST_ICON_V2 = `${cloudFrontUrl}/Icons/white-list-icon-v2.svg`;
export const BLACK_LIST_ICON_V2 = `${cloudFrontUrl}/Icons/black-list-icon-v2.svg`;
export const CALENER_ICON_V3 = `${cloudFrontUrl}/Icons/calender-icon-v3.svg`;

export const PROSPECT_ICON_V1 = `${cloudFrontUrl}/Icons/Prospect.svg`;

export const HEART_HAND_WHITE = `${cloudFrontUrl}/Icons/heart-hand-white.svg`;
export const HEART_HAND_BLACK = `${cloudFrontUrl}/Icons/heart-hand-black.svg`;
export const HEART_HAND_GREY = `${cloudFrontUrl}/Icons/heart-hand-grey.svg`;

export const USER_WITH_TICK = `${cloudFrontUrl}/Icons/user-with-tick.svg`;
export const ARROW_UP_RIGHT_WHITE_ICON = `${cloudFrontUrl}/Icons/arrow-up-right-white.svg`;

export const DELETE_ICON_V2 = `${cloudFrontUrl}/Icons/delete-icon-v2.svg`;
export const UPLOAD_ICON_NEW = `${cloudFrontUrl}/Icons/upload-icon-v4.svg`;
export const ARE_YOU_FUNDABLE_PROMOTIONAL_BANNER = `${cloudFrontUrl}/Images/are-you-fundable-promotional.svg`;
export const PARTNER_QA_ICON = `${cloudFrontUrl}/Icons/partner_qa_icon.svg`;

export const FACE_HAPPY_BLACK_ICON = `${cloudFrontUrl}/Icons/face-happy-1.png`;
export const PITCH_DECK_BLACK_ICON = `${cloudFrontUrl}/Icons/presentation-chart-01-1.png`;
export const SWITCH_BLACK_ICON = `${cloudFrontUrl}/Icons/switch-vertical-01-1.png`;

export const MB_LOGO_ICON = `${cloudFrontUrl}/Icons/mb_logo.svg`;

export const STARTUP_ICON_V2 = `${cloudFrontUrl}/Icons/startup-Icon-v2.svg`;

export const DRAG_ICON_V1 = `${cloudFrontUrl}/Icons/drag-icon.svg`;
export const DELET_ICON_V3 = `${cloudFrontUrl}/Icons/delete-icon-v3.svg`;
export const REDIRECT_ICON_V1 = `${cloudFrontUrl}/Icons/redirect-Icon-v1.svg`;
export const ACTIVITY_ICON_V1 = `${cloudFrontUrl}/Icons/activity-Icon-v1.svg `;

export const RETURN_ICON = `${cloudFrontUrl}/Icons/return-Icon.svg`;
