import React from "react";
import { useMediaQuery } from "@mui/material";
import WebPowerUpPopup from "./components/WebPowerUpPopup";
import MobilePowerUpPopup from "./components/MobilePowerUpPopup";

export default function PowerUpPopUp({
  open,
  onClose,
  title,
  actionBar,
  mainContent,
}) {
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));

  return (
    <>
      {!isSmallScreen && (
        <WebPowerUpPopup
          open={open}
          onClose={onClose}
          title={title}
          actionBar={actionBar}
          mainContent={mainContent}
        />
      )}

      {isSmallScreen && (
        <MobilePowerUpPopup
          open={open}
          onClose={onClose}
          title={title}
          actionBar={actionBar}
          mainContent={mainContent}
        />
      )}
    </>
  );
}
