import * as actionTypes from "../../actions/actionTypes";
import { updateObject } from "../../../utils/updateObject";

const initialState = {
	hasNewUnread: false,
	connectedStatus: false,
	connectionLoading: false,
	websocketClient: null,
	lastNotification: null,
	notificationList: null,
	notificationLoading: false,
};

const notifications = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SET_NOTIFICATION_DATA: {
			return action.payload === null ? initialState : updateObject(state, action.payload);
		}
		case actionTypes.PUSH_NOTIFICATION_LIST: {
			if (action.payload === null) return state;

			if (state?.notificationList) {
				// Step 1, concat the two arrays together.
				const allItems = state.notificationList.concat(action.payload);
				// Step 2, map the above into an array of key/value entries.
				const entries = allItems.map((o) => [o.NOTIFICATION_ID, o]);
				// Step 3, create a Map on the entries. De-duping happens here.
				const map = new Map(entries);
				// Step 4, extract the map's values back into an array.
				let result = [...map.values()];
				// Step 5, sort result by createdOn date
				result.sort(function (a, b) {
					// Turn your strings into dates, and then subtract them
					// to get a value that is either negative, positive, or zero.
					return new Date(b?.createdOn) - new Date(a?.createdOn);
				});
				// Move all of the "system-message" messages to the top of the list.
				const rebuiltArray = [
					...result.filter((x) => x.type && x.type === "system-message"),
					...result.filter((x) => !x.type || x.type !== "system-message"),
				];
				return updateObject(state, { notificationList: rebuiltArray, notificationLoading: false });
			} else {
				let temp_arr = action.payload;
				temp_arr.sort(function (a, b) {
					// Turn your strings into dates, and then subtract them
					// to get a value that is either negative, positive, or zero.
					return new Date(b?.createdOn) - new Date(a?.createdOn);
				});
				// Move all of the "system-message" messages to the top of the list.
				const rebuiltArray = [
					...temp_arr.filter((x) => x.type && x.type === "system-message"),
					...temp_arr.filter((x) => !x.type || x.type !== "system-message"),
				];
				return updateObject(state, { notificationList: rebuiltArray, notificationLoading: false });
			}
		}

		default:
			return state;
	}
};

export default notifications;
