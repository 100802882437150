import { Avatar, Box, Typography } from "@mui/material";
import React, { useContext, useEffect } from "react";
import CardItem from "../Common/CardItem";
import { NOTES_ICON_NEW, UPDATED_FOUNDER_ICON, UPDATED_INVESTOR_ICON, UPDATED_MENTOR_ICON } from "../../../constants";
import SvgComponent from "../../../utils/SVGColor";
import { useHistory, useLocation } from "react-router-dom";
import { ResourceCenterContext } from "../Stores/ResourceCenterStore";
import ListSkeleton from "../Common/ListSkeleton";
import StorefrontIcon from "@mui/icons-material/Storefront";
import ForkRightIcon from "@mui/icons-material/ForkRight";
import FollowTheSignsIcon from "@mui/icons-material/FollowTheSigns";
import { handleMixPanel } from "../../../utils/mixPanelEventHandling";

const SectionItem = ({ name, description, timeout, id, catid }) => {
	const history = useHistory();
	const location = useLocation();

	const getAvatar = (type) => {
		let icon;
		let bgColor;
		switch (type) {
			case "Access to Funding":
				icon = <Box component={"img"} src={UPDATED_INVESTOR_ICON} alt="logo" />;
				bgColor = "#EC6C67";
				break;
			case "Fundraising and First Customers":
				icon = <StorefrontIcon style={{ height: "24px", width: "24px" }} />;
				bgColor = "#AFDDD0";
				break;
			case "Advice and Decision Making":
				icon = <ForkRightIcon style={{ height: "24px", width: "24px" }} />;
				bgColor = "#FFD13F";
				break;
			case "Motivation and Inspiration":
				icon = <SvgComponent strokeColor={"white"} svgUrl={UPDATED_FOUNDER_ICON} style={{ height: "24px", width: "24px" }} />;
				bgColor = "#DE0085";
				break;
			case "Networking Opportunities":
				icon = <SvgComponent strokeColor={"white"} svgUrl={UPDATED_MENTOR_ICON} style={{ height: "24px", width: "24px" }} />;
				bgColor = "#7B61FF";
				break;
			case "Mentorship & Expertise":
				icon = <FollowTheSignsIcon style={{ height: "24px", width: "24px" }} />;
				bgColor = "#000000";
				break;
			default:
				icon = <Box component={"img"} src={NOTES_ICON_NEW} alt="logo" style={{ height: "24px", width: "24px", filter: "invert(1) brightness(2)" }} />;
				bgColor = "#7B61FF";
				break;
		}
		return (
			<Avatar
				sx={{
					height: "60px",
					width: "60px",
					bgcolor: bgColor,
				}}
			>
				{icon}
			</Avatar>
		);
	};
	return (
		<CardItem
			//onClick={() => history.push(`/resource-center/categories/${catid}/sections/${id}`)}
			onClick={() => {
				handleMixPanel("Resource Center: Section Clicked", {
					"Section Name": { name },
				});

				history.push(`/resource-center/sections/${id}`);
			}}
			timeout={timeout}
		>
			<Box
				sx={{
					display: "flex",
					gap: 2,
					...(!description && { alignItems: "center" }),
				}}
			>
				{getAvatar(name)}
				<Box sx={{ flex: 1 }}>
					<Typography variant="Display/sm/Semibold" sx={{ ...(!description && { mb: 1 }) }} component={"h6"}>
						{name}
					</Typography>
					{description && (
						<Typography component={"p"} variant="Text/md/Regular">
							{description}
						</Typography>
					)}
				</Box>
			</Box>
		</CardItem>
	);
};

function SectionList({ category_id }) {
	const { sections, loadSections, listLoading } = useContext(ResourceCenterContext);

	useEffect(() => {
		loadSections();
	}, []);

	return (
		<Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
			<Typography component={"h2"} variant="Text/xl/Semibold">
				Sections
			</Typography>
			<Box
				sx={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					flexWrap: "wrap",
					gap: 2,
				}}
			>
				{listLoading ? (
					<ListSkeleton />
				) : (
					sections?.map((item, index) => (
						<SectionItem
							key={item?.id}
							id={item?.id}
							catid={item?.category_id}
							name={item?.name}
							description={item?.description}
							timeout={50 + 50 * (index + 1)}
						/>
					))
				)}
			</Box>
		</Box>
	);
}

export default SectionList;
