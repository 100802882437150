import React, { useEffect, useMemo, useState } from "react";
import moment from "moment";
import _ from "lodash";
import { Box, Typography, IconButton, CircularProgress } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useHistory, generatePath } from "react-router-dom";
import ROUTES_PATH from "../../../../constants/routes";

const HISTORY_DATA = [
	{ id: 1, title: "Completed", value: 0 },
	{ id: 2, title: "In Progress", value: 0 },
	{ id: 3, title: "Archived", value: 0 },
];

const HISTORY_TABLE = [
	{ id: 1, title: "Name" },
	{ id: 2, title: "Modified Date" },
	{ id: 3, title: "Modified By" },
];

export default function WorkspaceHistoryCard({ powerUpDetails, powerUpId, title, sort, filter, handleRedirectior }) {
	const [selectedStatus, setSelectedStatus] = useState(0);
	const [historyDetails, setHistoryDetails] = useState([]);
	const [statuses, setStatuses] = useState(HISTORY_DATA);
	const [loading, setLoading] = useState(false);
	const history = useHistory();

	useEffect(() => {
		handleDetails();
	}, []);

	const handleStatus = async (index) => {
		setSelectedStatus(index);
	};

	const handleDetails = () => {
		try {
			setLoading(true);
			statuses[0].value = powerUpDetails?.responses.filter((model) => model?.isCompleted === true && model?.isArchived === false).length;
			statuses[1].value = powerUpDetails?.responses.filter((model) => model?.isCompleted === false && model?.isArchived === false).length;
			statuses[2].value = powerUpDetails?.responses.filter((model) => model?.isArchived === true).length;
			setStatuses(_.cloneDeep(statuses));
			setHistoryDetails(_.cloneDeep(powerUpDetails?.responses));
		} catch (e) {
			console.log("---e----", e);
		}

		setLoading(false);
	};

	const handleView = (item, index) => {
		//TODO -- THIS IS A NEW POWERUP TRANSITION WORKAROUND
		if (moment(item?.modifiedDate).isBefore("08/01/2023")) {
			item["uuid"] = powerUpDetails?.uuid.replace("SURVEY_", "") || "";
			item["type"] = powerUpDetails?.type;

			handleRedirectior(title, powerUpId, item?.isCompleted, item, item?.name ? item?.name : `Version ${index + 1}`);
		} else {
			const path = generatePath(ROUTES_PATH.WORKSPACE_POWERUP_DETAILS, {
				id: powerUpId,
			});
			const pathWithQueryParams = `${path}?versionID=${item?.id}`;
			history.push(pathWithQueryParams);
		}
		//TODO -- END
	};

	const currentItems = useMemo(() => {
		if (selectedStatus === 0) {
			return historyDetails.filter((model) => model?.isCompleted === true && model?.isArchived === false);
		} else if (selectedStatus === 1) {
			return historyDetails.filter((model) => model?.isCompleted === false && model?.isArchived === false);
		} else if (selectedStatus === 2) {
			return historyDetails.filter((model) => model?.isArchived === true);
		}

		return [];
	}, [historyDetails, selectedStatus]);

	return (
		<Box
			p="40px 50px"
			sx={{
				background: "#FFFFFF",
				boxShadow: "0px 10px 85px rgba(0, 0, 0, 0.09)",
				borderRadius: "30px",
			}}
		>
			<Typography variant="card_name">{title}</Typography>
			<Box display="flex" alignItems="center" justifyContent="space-around" mt={"10px"}>
				{statuses.map((history, index) => (
					<Box
						key={history.id}
						p="12px 30px"
						sx={{ cursor: "pointer", background: index === selectedStatus ? "#F5F9FA" : "", borderRadius: index === selectedStatus ? "60px" : "0" }}
						onClick={() => handleStatus(index)}
					>
						<Typography variant="card_text" sx={{ fontWeight: 600, color: index === selectedStatus ? "#000" : "#AAAAAA" }}>
							{`${history.title} (${history.value})`}
						</Typography>
					</Box>
				))}
			</Box>
			<Box display="grid" gridTemplateColumns={"1fr 1fr 1fr"} alignItems="center" justifyContent="space-between" mt={3}>
				{HISTORY_TABLE.map((column) => (
					<Typography key={column.id} variant="card_text" sx={{ fontWeight: 600 }}>
						{column.title}
					</Typography>
				))}
			</Box>
			{currentItems.map((detail, index) => (
				<Box
					key={detail.id}
					gap="12px"
					display="grid"
					gridTemplateColumns={"1fr 1fr 1fr"}
					alignItems="center"
					justifyContent="space-between"
					mt={2}
					mb={1}
				>
					<Typography variant="card_text">{detail.name}</Typography>
					<Typography variant="card_text">{moment(detail?.modifiedDate).format("MMM D, YYYY")}</Typography>
					<Box display="flex" alignItems="center" justifyContent="space-between">
						<Typography variant="card_text">{detail.modifiedBy ? detail.modifiedBy : "-"}</Typography>
						<IconButton onClick={() => handleView(detail, index)}>
							<ArrowForwardIcon />
						</IconButton>
					</Box>
				</Box>
			))}
		</Box>
	);
}
