import React, { Fragment, useState } from "react";
import { Box, Divider, Skeleton, Typography, Avatar } from "@mui/material";
import { SEAL_CHECK, NEW_LINKEDIN_ICON } from "../../../../constants/image";
import AccountPublicBioTags from "./Components/AccountBioTags";
import SvgComponent from "../../../../utils/SVGColor";
import { useSelector } from "react-redux";

function AccountPublicBioCard() {
	const isLoading = useSelector((state) => state?.accountPublicProfileData?.loading);
	const profileData = useSelector((state) => state?.accountPublicProfileData?.accountPublicData);

	return (
		<Box
			sx={(theme) => ({
				borderRadius: "40px",
				backgroundColor: "white",
				boxSizing: "border-box",
				padding: "32px 16px", // default padding
				[theme.breakpoints.between("sm", "md")]: {
					padding: "32px 32px", // padding between sm and md
				},
				[theme.breakpoints.up("md")]: {
					padding: "32px 40px 48px", // padding above md
				},
				boxShadow: "0px 10px 25px rgba(0, 0, 0, 0.05)",
			})}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
				}}
			>
				{/* Logo/Image */}
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						gap: 3,
						my: 3,
					}}
				>
					<Avatar
						src={`${profileData?.picture}`}
						sx={{
							height: "120px",
							width: "120px",
						}}
					/>
				</Box>

				{/* Name */}
				{isLoading ? (
					<Skeleton animation="wave" variant="text" sx={{ fontSize: "60px" }} />
				) : (
					<Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
						<Typography
							component={"div"}
							variant="customValue"
							sx={{
								color: "black",
								fontFamily: "PoppinsSemiBold",
								fontSize: "26px",
								lineHeight: "39px",
							}}
						>
							{profileData?.name}
						</Typography>
						{profileData?.linkedInUrl && (
							<img
								src={NEW_LINKEDIN_ICON}
								style={{
									height: "34px",
									width: "34px",
									stroke: "#12B76A",
									cursor: "pointer",
								}}
								onClick={() => window.open(profileData?.linkedInUrl, "_blank")}
							/>
						)}
					</Box>
				)}
				{/* Profession */}

				<Box sx={{ display: "flex", gap: 1, alignItems: "center", mb: 3 }}>
					{isLoading ? (
						<Skeleton animation="wave" variant="rounded" sx={{ height: "25px", width: "100%" }} />
					) : (
						<Fragment>
							<Typography
								component={"div"}
								variant="customValue"
								sx={{
									color: "black",
									fontFamily: "PoppinsSemiBold",
									fontSize: "20px",
									lineHeight: "30px",
								}}
							>
								{profileData?.profession ? profileData?.profession : "No Data"}
							</Typography>
						</Fragment>
					)}
				</Box>

				{/* Tag Line */}
				<Box sx={{ mb: 3 }}>
					{isLoading ? (
						<Skeleton animation="wave" variant="text" sx={{ fontSize: "16px" }} />
					) : (
						<Typography
							component={"div"}
							variant="customValue"
							sx={{
								color: "black",
								fontFamily: "PoppinsMedium",
								fontSize: "16px",
								lineHeight: "24px",
							}}
						>
							{profileData?.headline}
						</Typography>
					)}
				</Box>
				<Fragment>
					<Box>{isLoading ? <Skeleton animation="wave" variant="rounded" sx={{ height: "120px", width: 1 }} /> : <AccountPublicBioTags />}</Box>
					<Divider
						sx={{
							color: "#D0D5DD",
							my: 3,
						}}
					/>
					{isLoading ? (
						<Skeleton animation="wave" variant="text" sx={{ fontSize: "40px" }} />
					) : (
						<Typography
							component={"div"}
							variant="customValue"
							sx={{
								color: "black",
								fontFamily: "PoppinsSemiBold",
								fontSize: "20px",
								lineHeight: "30px",
								mb: "12px",
							}}
						>
							{profileData?.userTypes?.includes("STARTUP_OWNER") ? "Guidance Needed" : "My Expertise"}
						</Typography>
					)}
					{isLoading ? (
						<Skeleton animation="wave" variant="text" sx={{ fontSize: "40px" }} />
					) : (
						<Typography
							component={"div"}
							variant="customValue"
							sx={{
								color: "black",
								fontFamily: "PoppinsMedium",
								fontSize: "16px",
								lineHeight: "24px",
								wordWrap: "break-word",
							}}
						>
							{(() => {
								let expertise = profileData?.expertise;
								if (expertise) {
									try {
										expertise = JSON.parse(expertise);
										return expertise.join(", ");
									} catch (e) {
										if (expertise) {
											return expertise;
										} else {
											return `${profileData?.name} has not completed their experience section of their profile.`;
										}
									}
								} else {
									return `${profileData?.name} has not completed their experience section of their profile.`;
								}
							})()}
						</Typography>
					)}
				</Fragment>
			</Box>
		</Box>
	);
}

export default AccountPublicBioCard;
