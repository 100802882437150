import React from "react";
import { useHistory } from "react-router-dom";
import { Box, Breadcrumbs, Button, Link, Typography } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

export const BreadcrumbsComponent = ({ bedcrumDetails = [], navigationButtons = {} }) => {
	const history = useHistory();

	const handleNavigation = (route) => {
		history.push(route);
	};

	return (
		<Box display="flex" alignItems="center" justifyContent="space-between">
			<Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon sx={{ mx: 0 }} />}>
				{bedcrumDetails
					.map((detail, index) => (
						<Link
							key={detail?.title}
							color="#000"
							underline={index === 0 ? "always" : "hover"}
							sx={{ cursor: detail?.route && "pointer" }}
							onClick={() => {
								detail?.route && handleNavigation(detail?.route);
							}}
							// href={detail.route}
						>
							<Typography color="#000" variant="Text/sm/Semibold">
								{detail?.title}
							</Typography>
						</Link>
					))
					.slice(0, bedcrumDetails.length === 2 ? -1 : bedcrumDetails.length)}

				{bedcrumDetails.length === 2 && (
					<Typography color="#000" variant="Text/sm/Regular">
						{bedcrumDetails[bedcrumDetails.length - 1].title}
					</Typography>
				)}
			</Breadcrumbs>

			{navigationButtons.length === 3 && (
				<Box display="flex" alignItems="center" gap={2}>
					<Link underline="none" color="#344054" href={navigationButtons[1].route}>
						<Button
							sx={{
								backgroundColor: "#F2F4F7",
								color: "#344054",
								borderRadius: "20px",
								":hover": {
									boxShadow: "none",
									backgroundColor: "#e1e3e5",
								},
								":focus": {
									boxShadow: "none",
									backgroundColor: "#e1e3e5",
								},
							}}
						>
							Previous
						</Button>
					</Link>

					<Link underline="none" color="#344054" href={navigationButtons[2].route}>
						<Button
							sx={{
								backgroundColor: "#F2F4F7",
								color: "#344054",
								borderRadius: "20px",
								":hover": {
									boxShadow: "none",
									backgroundColor: "#e1e3e5",
								},
								":focus": {
									boxShadow: "none",
									backgroundColor: "#e1e3e5",
								},
							}}
						>
							Next
						</Button>
					</Link>
				</Box>
			)}
		</Box>
	);
};
