import { Box, Button, IconButton, Typography } from "@mui/material";
import React from "react";

function NavButton({ target, href, active, isPowerupPages, image, label, onClick }) {
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "space-between",
				alignItems: "center",
			}}
			onClick={() => {
				if (href) {
					window.open(href, target);
				} else if (onClick) {
					onClick();
				}
			}}
		>
			<IconButton
				disableRipple
				sx={{
					height: "40px",
					width: "40px",
					padding: 0,
					margin: 0,
					background: (theme) => active && theme.palette.primary.main,
					"& > img": {
						maxHeight: "24px",
					},
				}}
			>
				{image}
			</IconButton>
			<Box>
				<Typography
					sx={{
						display: "grid",
						textAlign: "start",
						position: "sticky",
						fontFamily: "PoppinsSemiBold",
						fontSize: "10px",
						lineHeight: "15px",
						textAlign: "center",
						cursor: "pointer",
						color: (theme) => (isPowerupPages ? "secondary.white" : theme.palette.text.onSurfaceVariant),
					}}
					variant="customValue"
				>
					{label}
				</Typography>
			</Box>
		</Box>
	);
}

export default NavButton;
