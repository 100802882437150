import styled from "@emotion/styled";
import { INVESTOR_DASHBOARD_BG, INVESTOR_DASHBOARD_BG1 } from "../../constants";

export const PageWrapper = styled.div(({ theme, bgImage, backgroundColor = theme.palette.secondary.mutedBackground }) => {
	return {
		minHeight: "100vh",
		//display: "grid",
		//gridTemplateRows: "auto 1fr auto",
		backgroundSize: "cover",
		height: "100%",
		backgroundRepeat: "repeat",
		overflowX: "scroll",
		backgroundColor: backgroundColor,
		...(bgImage && {
			backgroundImage: `url(${bgImage})`,
		}),
	};
});
export const HeaderArea = styled.div(({ theme }) => {
	return {
		background: "var(--white-color)",
	};
});
export const MainArea = styled.div(({ theme }) => {
	return {
		height: "100%",
		//paddingTop: "50px",
		// marginLeft: "-32px",
	};
});
