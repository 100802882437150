import React, { useState } from "react";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import services from "../../../../services/index";
import { toastContainer } from "../../../../utils/toast";
import messages from "../../../../constants/messages";
import { Add } from "@mui/icons-material";

const CreateAvailability = ({ userId, selectedTimeZone, refreshAvailabilities }) => {
	const [loading, setLoading] = useState(false);

	const handleCreateAvailability = () => {
		setLoading(true);
		const payload = {
			timezone: selectedTimeZone,
		};

		services
			.createAvailabilityApi(payload)
			.then((res) => {
				setLoading(false);
				toastContainer(messages.CREATE_AVAILABILITY_SUCCESS, "success");
				refreshAvailabilities();
			})
			.catch((error) => {
				setLoading(false);
				console.error("Error creating availability:", error);
				toastContainer(messages.CREATE_AVAILABILITY_ERROR, "error");
			});
	};

	return (
		<Box>
			<Typography color="primary" variant="Text/sm/Semibold" sx={(theme) => ({ color: theme.palette.secondary.dark })}>
				Create your availability here!
				<br />
				<br />
			</Typography>
			<Button
				variant="DS1"
				startIcon={loading ? <CircularProgress size={"1.25rem"} color="inherit" /> : <Add />}
				sx={{ width: "max-content" }}
				onClick={handleCreateAvailability}
			>
				Create Availability
			</Button>
		</Box>
	);
};

export default CreateAvailability;
