import React, { useEffect, useState } from "react";
import { Box, Button, TextField, Typography, CircularProgress, Link } from "@mui/material";
import FeedCommentsCard from "../CommentsCard";
import { useDispatch, useSelector } from "react-redux";
import { setFeedData } from "../../../modules/actions";
import { createFeedPostCommentApiCall, getFeedPostCommentsList } from "../../../containers/Feed/Functions/common";
import LoadingButton from "../../../common/Loadingbutton";
import { handleMixPanel } from "../../../utils/mixPanelEventHandling";
import { getRoleValue } from "../../../utils/commonFunctions";

const FeedCommentsComponent = ({ comments, postId, type, detailsPostObjectData }) => {
	const currentCommentListData = useSelector((state) => state?.feedPost?.currentCommentList);
	const feedCommentData = currentCommentListData ? currentCommentListData : comments && [...comments];
	const [loadingComments, setLoadingComments] = useState(false);
	const [loading, setLoading] = useState(false);
	const [commentText, setCommentText] = useState("");
	const userFeedData = useSelector((state) => state?.feedPost?.feedData);
	const doGetCommentsApiCall = useSelector((state) => state?.feedPost?.getCommentsApiCall);
	const dispatch = useDispatch();
	const postData = useSelector((state) => state?.feedPost?.detailsPostObject);
	const isDetailsOpen = useSelector((state) => state?.feedPost?.detailsPopUpOpen);
	const detailsPostObject = useSelector((state) => state?.feedPost);
	const authData = useSelector((state) => state?.auth);

	const getFeedPostCommentsListApiCall = (postId, page, perPage, filter, sort) => {
		setLoadingComments(true);
		getFeedPostCommentsList(postId, page, perPage, filter, sort)
			.then((response) => {
				setLoadingComments(false);
				const newCommentsData = response?.data?.data;
				const newFeedData = [...userFeedData];
				newFeedData.forEach((post, index) => {
					if (post?.id === postId) {
						post["comments"] = newCommentsData?.models;
					}
				});
				const tempPostObj = { ...postData };
				tempPostObj["comments"] = newCommentsData?.models;
				tempPostObj["commentsCount"] = newCommentsData?.models && newCommentsData?.models.length;

				dispatch(
					setFeedData({
						feedData: newFeedData,
						getCommentsApiCall: false,
						currentCommentList: newCommentsData?.models,
						detailsPostObject: tempPostObj,
					})
				);
			})
			.catch((error) => {
				setLoadingComments(false);
				dispatch(
					setFeedData({
						getCommentsApiCall: false,
					})
				);
			});
	};

	useEffect(() => {
		if (postId && doGetCommentsApiCall) {
			getFeedPostCommentsListApiCall(postId, "", "", "", "");
		}
	}, [doGetCommentsApiCall]);

	const handleCommentTextChange = (event) => {
		setCommentText(event.target.value);
	};

	const handleCancle = () => {
		setCommentText("");
		dispatch(
			setFeedData({
				currentPostId: "",
				getCommentsApiCall: false,
			})
		);
	};
	const handleMixPanelEvent = () => {
		handleMixPanel("Replied to Feed Post", {
			"Replying user name": authData?.userName,
			"Replying user id": authData?.userId,
			"Replying user role": getRoleValue(authData?.userType),
			"Post id": postId,
		});
	};
	const handlePostComment = () => {
		setLoading(true);
		const payload = {
			text: commentText,
		};
		createFeedPostCommentApiCall(postId, payload)
			.then((response) => {
				setLoading(false);
				setCommentText("");
				handleMixPanelEvent();
				getFeedPostCommentsListApiCall(postId, "", "", "", "");
				const newFeedData = [...userFeedData];
				const tempPostObj = { ...postData };
				newFeedData.forEach((post, index) => {
					if (post?.id === postId) {
						post["commentsCount"] += 1;
					}
				});
				tempPostObj["commentsCount"] += 1;
				dispatch(
					setFeedData({
						feedData: newFeedData,
						detailsPostObject: tempPostObj,
					})
				);
			})
			.catch((error) => {
				setLoading(false);
				console.log(error);
			});
	};

	return (
		<>
			{loadingComments ? (
				<Box
					sx={(theme) => ({
						display: "flex",
						justifyContent: "center",
						ml: "66px",
					})}
				>
					<CircularProgress
						sx={(theme) => ({
							height: "50px",
							width: "50px",
							color: theme.palette.text.darkBlue,
						})}
					/>
				</Box>
			) : (
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						gap: 2,
						ml: type === "postCard" ? "66px" : "0px",
					}}
				>
					<Box
						sx={{
							width: 1,
							display: "flex",
							flexDirection: "column",
							gap: 2,
						}}
					>
						<Typography variant="flyout_text">Leave a comment</Typography>
						<TextField
							placeholder="Type Here"
							spellCheck="true"
							value={commentText}
							fullWidth
							InputProps={{
								style: {
									borderRadius: "30px",
									borderColor: "#D0D5DD",
									height: "45px",
									fontFamily: '"PoppinsRegular", "Helvetica","Arial", "sans-serif"',
									fontSize: "16px",
									lineHeight: "24px",
								},
							}}
							onChange={handleCommentTextChange}
						/>
						<Box
							sx={(theme) => ({
								width: 1,
								display: "flex",
								alignItems: "center",
								gap: 2,
								justifyContent: "right",
								[theme.breakpoints.down("md")]: {
									flexDirection: "column",
								},
							})}
						>
							<Box>
								<Button variant="noOutlineNew" sx={{ height: "48px" }} onClick={handleCancle}>
									Cancel
								</Button>
							</Box>
							<Box>
								<LoadingButton
									loaderColor={"#7B61FF"}
									loadingStyles={{
										border: "4px solid",
										backgroundColor: "white",
										borderColor: "#7B61FF",
										px: 8,
										height: "48px",
									}}
									loading={loading}
									
									onClick={!loading ? handlePostComment : () => {}}
									variant="DS1"
									disabled={commentText ? false : true}
								>
									Comment
								</LoadingButton>
							</Box>
						</Box>
					</Box>
					{feedCommentData?.map((data, index) => {
						if (isDetailsOpen) {
							return <FeedCommentsCard item={data} index={index} sectionType={type} />;
						} else {
							if (index < 3) {
								return <FeedCommentsCard item={data} index={index} sectionType={type} />;
							} else {
								return true;
							}
						}
					})}
					{!isDetailsOpen && currentCommentListData && currentCommentListData.length > 3 && (
						<Link
							target="_blank"
							underline="hover"
							variant="title_medium"
							sx={{ display: "block", textAlign: "right" }}
							onClick={() => {
								dispatch(
									setFeedData({
										detailsPopUpOpen: true,
										detailsPostObject: detailsPostObjectData,
										currentPostId: postId,
										getCommentsApiCall: false,
									})
								);
							}}
						>
							View More
						</Link>
					)}
				</Box>
			)}
		</>
	);
};

export default FeedCommentsComponent;
