import services from "../../../../services";

export async function getInvestorImpressionsValues(ID) {
	try {
		const resp = await services.getInvestorImpressionsData(ID);
		const data = resp?.data?.data;
		if (data) {
			return data;
		}
		return null;
	} catch (e) {
		console.log("API error when attempting to get Investor Impressions Data. Failed with error: ", e);
		return null;
	}
}

export async function getPowerUpPacksData() {
	const getPowerUpPacksValues = await services
		.getPowerUpPacksList()
		.then((result) => {
			if (result.data.code === 200) {
				return result.data?.data;
			}
		})
		.catch((err) => {
			throw err;
		});
	return getPowerUpPacksValues;
}

export async function getStartupCardData(companyId) {
	const getStartupCardValues = await services
		.getStartupCardDetails(companyId)
		.then((result) => {
			if (result.data.code === 200) {
				return result.data?.data;
			}
		})
		.catch((err) => {
			throw err;
		});
	return getStartupCardValues;
}

export async function getAdvertsDataById(id) {
	const advertsDataById = await services
		.getAdvertsById(id)
		.then((result) => {
			if (result.data.code === 200) {
				return result.data?.data?.[0];
			}
		})
		.catch((err) => {
			throw err;
		});
	return advertsDataById;
}

export async function getGrowthRateData(id) {
	const growthRateData = await services
		.getGrowthRateData(id)
		.then((result) => {
			if (result.data.code === 200) {
				return result.data?.data;
			}
		})
		.catch((err) => {
			throw err;
		});
	return growthRateData;
}

export async function getCurrentStreaksData(id) {
	const currentStreaksData = await services
		.getCurrentStreaksData(id)
		.then((result) => {
			if (result.data.code === 200) {
				return result.data?.data;
			}
		})
		.catch((err) => {
			throw err;
		});
	return currentStreaksData;
}