import React from "react";
import { Box, Typography, styled, Avatar } from "@mui/material";
import { Flag } from "@mui/icons-material";

export default function PersonaCard({ title, icon, isLarge, children }) {
	return (
		<Wrapper isLarge={isLarge} mb="30px">
			{title && (
				<Box display="flex" alignItems="center">
					<Avatar sx={{ bgcolor: "#DE0085", width: "50px", height: "50px" }}>{icon}</Avatar>
					<Typography variant="card_name" sx={{ fontWeight: 600, marginLeft: "8px" }}>
						{title}
					</Typography>
				</Box>
			)}
			{children}
		</Wrapper>
	);
}

export const Wrapper = styled(Box)(({ isLarge }) => ({
	background: "#FFFFFF",
	borderRadius: "30px",
	padding: isLarge ? "60px" : "60px 50px",
	boxShadow: "0px 10px 25px rgba(0, 0, 0, 0.05)",
}));
