import React, { useEffect, useState } from "react";
import CommonSlideDialog from "../../../../common/CommonSlideDialog";
import { useDispatch, useSelector } from "react-redux";
import { setBIDashboardData } from "../../../../modules/actions/biDashboardActions";
import { Box, Typography, Button, Checkbox, useTheme, Chip } from "@mui/material";
import { getPlaidLinkToken, partners, redirectUri } from "../Functions/common";
import { uid } from "uid/single";
import { usePlaidLink } from "react-plaid-link";
import LoadingButton from "../../../../common/Loadingbutton";
import { LINK_PARTNER_INFO_CIRCLE_PURPLE, LINK_PARTNER_CHECKBOX_CHECKED, LINK_PARTNER_CHECKBOX_UNCHECKED } from "../../../../constants";

const tableNameObj = {
	quickbooks: ["ARR", "Net New ARR", "Burn & Runway", "S&M Expense vs New Sales"],
	xero: ["Employee Head Count"],
	plaid: ["Burn & Runway"],
};

function ConnectConfirm() {
	const theme = useTheme();
	const dispatch = useDispatch();
	const openConnect = useSelector((store) => store?.biDashboard?.openConnect);
	const linkPartnerkey = useSelector((store) => store?.biDashboard?.linkPartnerkey);
	const handleClose = () => {
		dispatch(setBIDashboardData({ openConnect: false }));
	};
	const [agree, setAgree] = useState(false);
	const partnerDetails = partners.find((partner) => partner.key === linkPartnerkey);

	const [loadingPlaidLinkToken, setLoadingPlaidLinkToken] = useState(false);
	const [plaidLinkToken, setPlaidLinkToken] = useState("");
	const isOAuthRedirect = window.location.href.includes("/plaid?oauth_state_id=");

	// const tableDataPull = partnerDetails?.key === "plaid" ? tableNames.filter((table) => table === "Burn & Runway") : tableNames;
	const tableDataPull = tableNameObj[partnerDetails?.key];

	const onPlaidPublicTokenSuccess = (public_token, metadata) => {
		console.log(metadata, "metaData");
		dispatch(setBIDashboardData({ openPartnerIntegration: true, plaidCode: public_token }));
		setPlaidLinkToken("");
	};

	const config = {
		onSuccess: onPlaidPublicTokenSuccess,
		onExit: (err, metadata) => {
			console.log(err, "plaid exit");
		},
		token: plaidLinkToken,
		//required for OAuth; if not using OAuth, set to null or omit:
		...(isOAuthRedirect && { receivedRedirectUri: window.location.href }),
	};
	const { open: openPlaid, exit: exitPlaid, ready: readyPlaid } = usePlaidLink(config);

	const getPlaidToken = async () => {
		try {
			setLoadingPlaidLinkToken(true);
			const response = await getPlaidLinkToken();
			if (response?.data) {
				setPlaidLinkToken(response?.data);
			}
			setLoadingPlaidLinkToken(false);
		} catch (error) {
			setLoadingPlaidLinkToken(false);
			console.log(error);
		}
	};

	useEffect(() => {
		if (readyPlaid && !loadingPlaidLinkToken && plaidLinkToken) {
			openPlaid();
		}
	}, [loadingPlaidLinkToken, plaidLinkToken]);

	useEffect(() => {
		if (isOAuthRedirect && readyPlaid) {
			openPlaid();
		}
	}, [readyPlaid, openPlaid, isOAuthRedirect]);

	useEffect(() => {
		if (!openConnect) {
			setAgree(false);
		} else {
			setPlaidLinkToken("");
		}
	}, [openConnect]);

	const openLink = (href) => {
		window.open(href, "_blank");
	};

	const onAllowClick = async () => {
		if (partnerDetails?.key === "xero") {
			const xeroState = uid();
			dispatch(setBIDashboardData({ xeroState }));
			console.log(partnerDetails?.redirectUrl?.(xeroState), "redirecturl");
			openLink(partnerDetails?.redirectUrl ? partnerDetails?.redirectUrl?.(xeroState) : "");
		} else if (partnerDetails?.key === "plaid") {
			await getPlaidToken();
		} else if (partnerDetails?.key === "quickbooks") {
			const quickbooksState = uid();
			dispatch(setBIDashboardData({ quickbooksState }));
			console.log(partnerDetails?.redirectUrl?.(quickbooksState), "redirecturl");
			openLink(partnerDetails?.redirectUrl ? partnerDetails?.redirectUrl?.(quickbooksState) : "");
		} else {
			openLink(partnerDetails?.redirectUrl ? partnerDetails?.redirectUrl : "");
		}
		dispatch(setBIDashboardData({ openConnect: false, openSettings: false }));
	};

	return (
		<CommonSlideDialog
			open={openConnect}
			disableBackDropClick
			onClose={handleClose}
			slidingEffect={false}
			title={
				<Box sx={{ display: "flex", flexDirection: "column", rowGap: 1 }}>
					<Box component={"img"} src={LINK_PARTNER_INFO_CIRCLE_PURPLE} />
				</Box>
			}
			maxWidth="sm"
			titleVariant="poppinsSemiBold30"
			titleStyle={{ p: 0, alignItems: "center" }}
			contentStyle={{ p: 0, mt: 1, mb: 3, flex: 1, display: "flex" }}
			fullWidth
			PaperProps={{
				sx: {
					m: 0,
					borderRadius: "24px",
					boxShadow: "0px 10px 25px 0px rgba(0, 0, 0, 0.05)",
					width: "100%",
					// height: "",
					p: 5,
				},
			}}
			footerStyle={{ p: 0 }}
			dialogAction={
				<Box sx={{ display: "flex", alignItems: "center" }}>
					<Button
						variant="noOutlineNew"
						onClick={() => {
							handleClose();
						}}
					>
						Decline
					</Button>
					<LoadingButton
						loaderColor={theme.palette.primary.main}
						loadingStyles={{
							border: "4px solid",
							backgroundColor: theme.palette.secondary.white,
							borderColor: theme.palette.primary.main,
							px: 4,
						}}
						variant="DS1"
						onClick={onAllowClick}
						disabled={!agree}
						loading={loadingPlaidLinkToken}
					>
						Allow
					</LoadingButton>
				</Box>
			}
		>
			<Box flex={1} display={"flex"} flexDirection={"column"} rowGap={4}>
				<Typography variant="Text/md/Semibold">
					{`StartupOS will authenticate with your ${
						partnerDetails?.displayName ? partnerDetails?.displayName : ""
					} account on your behalf to access the following data:`}
				</Typography>
				<Box display="flex" alignItems="center" gap={1} flexWrap={"wrap"}>
					{tableDataPull?.map((name) => (
						<Chip
							key={name}
							sx={{ backgroundColor: theme.palette.secondary.white, border: "1px solid", borderColor: theme.palette.sos.gray300 }}
							label={
								<Typography variant="Text/xs/Regular" sx={{ color: theme.palette.sos.gray700 }}>
									{name}
								</Typography>
							}
						/>
					))}
				</Box>
				<Box>
					<Box display="flex" alignItems="center" flexWrap={"wrap"}>
						<Checkbox
							checkedIcon={<Box component="img" src={LINK_PARTNER_CHECKBOX_CHECKED} />}
							icon={<Box component="img" src={LINK_PARTNER_CHECKBOX_UNCHECKED} />}
							checked={agree}
							onChange={(e) => {
								setAgree(e.target.checked);
							}}
							inputProps={{ "aria-label": "controlled" }}
							sx={{ pl: 0 }}
						/>
						<Typography variant="Text/sm/Regular">Allow this app to use my shared access permissions.</Typography>
					</Box>

					<Box>
						<Typography variant="Text/sm/Regular" component={"p"}>
							By clicking Allow,
						</Typography>
						<ul>
							<li>
								<Typography variant="Text/sm/Regular" component={"p"}>
									You agree to{" "}
									<Typography
										variant="Text/sm/Regular"
										component={"span"}
										color="primary.main"
										sx={{ cursor: "pointer" }}
										onClick={() => {
											openLink("https://startupos.getproven.com/");
										}}
									>
										StartupOS App Marketplace Terms of Use
									</Typography>
								</Typography>
							</li>
							<li>
								<Typography variant="Text/sm/Regular" component={"p"}>
									You give permissions to this app to use the information it’s allowed to access in accordance with their{" "}
									<Typography
										variant="Text/sm/Regular"
										component={"span"}
										color="primary.main"
										sx={{ cursor: "pointer" }}
										onClick={() => {
											openLink("https://startupos.com/terms-of-service/");
										}}
									>
										Terms{" "}
									</Typography>
									&{" "}
									<Typography
										variant="Text/sm/Regular"
										component={"span"}
										color="primary.main"
										sx={{ cursor: "pointer" }}
										onClick={() => {
											openLink("https://startupos.com/privacy-policy/");
										}}
									>
										Privacy Policy
									</Typography>
								</Typography>
							</li>
						</ul>
					</Box>
				</Box>
			</Box>
		</CommonSlideDialog>
	);
}

export default ConnectConfirm;
