import React, { useEffect, useState } from "react";
import { Box, Typography, styled, Avatar } from "@mui/material";

export default function Profile({ persona }) {
	const [personaData, setpersonaData] = useState(persona);

	useEffect(() => {
		setpersonaData(persona);
	}, [persona]);

	return (
		<Wrapper display="flex" flexDirection="column" justifyContent="center" alignItems="center" mb="30px">
			<Avatar sx={{ height: "206px", width: "206px", border: "4px solid #fff" }} alt={personaData?.personaName} src={personaData?.icon} />
			<Box display="flex" flexDirection="column" alignItems="center" mt="30px">
				<Typography variant="card_name">{personaData?.personaName}</Typography>
				<Typography variant="card_text">{personaData?.jobTitle}</Typography>
				{/*<Typography variant="card_text">Co-Founder, Enkrypt</Typography>*/}
				{/* 
					// TODO -- GENERATION INFO.
				*/}
				{/*<Box display="flex" alignItems="center" mt="24px">
					<Typography variant="card_text" sx={{ fontWeight: 600, marginRight: "10px" }}>
						Generation Y:
					</Typography>
					<Typography variant="card_text">1980-1994</Typography>
				</Box>
				<Box display="flex" alignItems="center" gap="15px" mt="4px">
					<Typography variant="card_text" sx={{ fontWeight: 600, marginLeft: "8px" }}>
						Internet
					</Typography>
					<Typography variant="card_text" sx={{ fontWeight: 600, marginLeft: "8px" }}>
						BMX
					</Typography>
					<Typography variant="card_text" sx={{ fontWeight: 600, marginLeft: "8px" }}>
						iPod
					</Typography>
				</Box>*/}
			</Box>
		</Wrapper>
	);
}

export const Wrapper = styled(Box)(({ isLarge }) => ({
	background: "#FFFFFF",
	borderRadius: "30px",
	padding: isLarge ? "60px" : "60px 50px",
	boxShadow: "0px 10px 25px rgba(0, 0, 0, 0.05)",
}));
