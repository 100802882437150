import React, { useEffect, useState } from "react";
import EventCards from "../../../../components/Events/EventCards";
import { Box } from "@mui/material";
import PowerPack from "./PowerPacks";
import services from "../../../../services";
import { setCommonDialogConfirmationData, setEventData } from "../../../../modules/actions";
import { useDispatch, useSelector } from "react-redux";
import MuiCarousel from "../../../../common/MuiCarousel";
import { useHistory, generatePath } from "react-router-dom";
import ROUTES_PATH from "../../../../constants/routes";
import { handleMixPanel } from "../../../../utils/mixPanelEventHandling";
import PaymentPlanDialog from "../../../../components/PaymentPlanDialog";
import { actionApiCall } from "../functions";

function GetStarted() {
	const [eventList, setEventList] = useState([]);
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();
	const { isPremiumPlanAccess, userType, isAdmin, userId } = useSelector((store) => store?.auth);
	const [showPlanModal, setShowPlanModal] = useState(false);
	const [activeEvents, setActiveEvents] = useState([]);

	
	const selectedOption = useSelector((state) => state?.communityPartners?.selectedOption);
	const communityPartnerItems = useSelector((state) => state?.communityPartners?.communityPartnerItems);

	const selectedPartnerCode = communityPartnerItems?.find((item) => item?.partnerName == selectedOption);

	const history = useHistory();
	useEffect(() => {
		GetEventList();
	}, []);
	useEffect(() => {
		GetEventList();
	}, [selectedOption]);
	const GetEventList = async () => {
		try {
			setLoading(true);
			const response = await services.getEventListApi(null, selectedPartnerCode?.partnerCode);
			if (response) {
				setLoading(false);
				dispatch(
					setEventData({
						eventList: response?.data?.data,
					})
				);
				const tempActiveEvents = response?.data?.data?.filter((event) => event?.isUpcomingEvent && event?.isFeatureEvent);
				setActiveEvents(tempActiveEvents);
			}

			setEventList(response?.data?.data);
		} catch (error) {
			console.error("Error fetching event list:", error);
		}
	};
	const onEventClick = (event) => {
		dispatch(
			setEventData({
				currentObject: event,
			})
		);
		history.push({
			pathname: generatePath(ROUTES_PATH.EVENTS_DETAILS, {
				id: event?.uuid,
			}),
		});
	};

	// const actionApiCall = () => {
	// 	services
	// 		.updateStreakActionApi({
	// 			actionType: "EVENT",
	// 			actionOnId: userId,
	// 			actionOnType: "EVENT",
	// 			metaData: null,
	// 		})
	// 		.then()
	// 		.catch();
	// };
	const handleApplyNow = (eventDetails) => {
		let isPitchExpand = false;
		let isSVGExpand = false;
		if (eventDetails?.title === "StartupOS Pitch Day Event") {
			isPitchExpand = true;
			isSVGExpand = false;
		}

		handleMixPanel("Register Now Clicked", { subscription_status: isPremiumPlanAccess ? "Subscribed" : "Not Subscribed", user_type: userType });
		handleMixPanel("Event Clicked", {
			"Event Name": eventDetails?.title,
			"Is Premium": eventDetails?.isPremiumEvent,
			subscription_status: isPremiumPlanAccess ? "Subscribed" : "Not Subscribed",
			user_type: userType,
		});
		if (eventDetails?.isPremiumEvent === true && !isPremiumPlanAccess && !isAdmin) {
			setShowPlanModal(true);
		} else {
			actionApiCall({
				actionType: "EVENT",
				actionOnId: userId,
				actionOnType: "EVENT",
				metaData: null,
			});
			if (eventDetails?.isPitchEvent) {
				dispatch(
					setCommonDialogConfirmationData({
						applyToPitchOpen: true,
						isPitchExpand,
						isSVGExpand,
					})
				);
			} else {
				window.open(eventDetails?.redirectLink, "_blank");
			}
		}
	};

	const handleClosePlanModal = () => {
		setShowPlanModal(false);
	};

	return (
		<Box
			sx={{
				px: {
					lg: 8,
					md: 4,
					xs: 2,
				},
				pb: "30px",
				display: "flex",
				flexDirection: "column",
				gap: "32px",
				maxWidth: "1300px",
				margin: "0 auto",
			}}
			display={"flex"}
			flexDirection={"column"}
			rowGap={7}
			pt={5}
		>
			{/* <Guide videoUrl={""} videoThumbnailUrl={faker.image.abstract()} /> */}
			{activeEvents?.length > 0 && <MuiCarousel carouselItems={activeEvents} handleItemClick={onEventClick} handleApplyNow={handleApplyNow} />}
			<PowerPack />
			<EventCards events={eventList} loading={loading} />
			{showPlanModal && <PaymentPlanDialog open={showPlanModal} isRedirect={false} onClose={handleClosePlanModal} />}
		</Box>
	);
}

export default GetStarted;
