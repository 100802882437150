import React, { useState, useEffect } from "react";
import { Box, Typography, Grid, Stack, Button, Link } from "@mui/material";
import { useHistory } from "react-router-dom";
import LoadingButton from "../../../common/Loadingbutton";
import TextInput from "../../../common/input/Input";
import PasswordValidation from "../../../common/passwordValidation/PasswordValidation";
import { LOGOICON } from "../../../constants/image";
import ROUTES_PATH from "../../../constants/routes";

export const CreateAccount = ({
  email,
  emailError,
  password,
  handleEmailInputChange,
  handlePasswordInputChange,
  validationErrorArray = [true, true, true, true, true],
  handleContinue,
  signupLoading,
}) => {
  const history = useHistory();
  return (
    <>
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: "100%" }}
      >
        <Grid
          className="signup-grid-container"
          sx={{ width: 1, display: "flex", gap: 2 }}
          container
          alignItems="center"
          justifyContent="center"
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className="form-container"
            sx={{ textAlign: "center", mt: { xs: 4, md: 0 } }}
          >
            <Typography variant="h2_dark">Create Your Account</Typography>
          </Grid>
          <Grid item xs={12} sm={10} md={10} lg={10} className="form-container">
            <TextInput
              type="text"
              label="Email address"
              value={email}
              error={emailError ? "Please enter valid email address" : ""}
              onChange={e => handleEmailInputChange(e)}
            />
            <TextInput
              type="password"
              label="Password"
              value={password}
              onChange={e => handlePasswordInputChange(e)}
            />
            <PasswordValidation validationErrorArray={validationErrorArray} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box textAlign="center" sx={{ px: 3 }}>
              <LoadingButton
                sx={{ mt: 3, width: 1, maxWidth: "400px" }}
                disabled={
                  !email || emailError || validationErrorArray.includes(true)
                }
                onClick={e => handleContinue(e)}
                loading={signupLoading}
              >
                Continue
              </LoadingButton>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className="form-container"
            sx={{ textAlign: "center", mb: 2 }}
          >
            <Box textAlign={"center"} sx={{ display: "none" }}>
              <Typography
                variant="body_medium_light"
                sx={{ color: theme => theme.palette.text.primary }}
              >
                Have an account? &nbsp;
                <Typography
                  variant="linkBold"
                  color={"primary"}
                  onClick={() => {
                    history.push(ROUTES_PATH.LOG_IN);
                  }}
                  sx={{ cursor: "pointer" }}
                >
                  Log in
                </Typography>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Stack>
    </>
  );
};
