import React from "react";
import { TextField, Checkbox, Chip, stepIconClasses } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";

export default function AutocompleteCheckInput({
  placeholder,
  inputValid,
  errorMessage,
  inputValue,
  handleInputChange,
  autoCompleteData,
  label,
  multiple,
  max,
  disabled,
  handleOnBlurEvent = false,
  isEmailField = false,
  handleOnChange = () => {},
  isError = false,
  setTagInputs,
  handleDelete,
  ...props
}) {
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = (
    <CheckBoxOutlinedIcon
      fontSize="small"
      sx={theme => ({ color: theme.palette.secondary.main })}
    />
  );
  const filter = createFilterOptions();

  return (
    <Autocomplete
      multiple
      options={
        !autoCompleteData ? [{ name: "No options", id: 0 }] : autoCompleteData
      }
      getOptionLabel={option => {
        if (typeof option === "string") {
          return option;
        }
        if (option.inputValue) {
          return option.addText;
        }
        return option.name;
      }}
      freeSolo
      typingEnabled
      disableClearable
      disableCloseOnSelect
      renderTags={(value, getTagProps) =>
        value?.map((item, index) => (
          <Chip
            variant="outlined"
            label={item}
            {...getTagProps({ index })}
            deleteIcon={<ClearIcon />}
          />
        ))
      }
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={inputValue.some(function (ele) {
              return ele.id === option.id;
            })}
          />
          {typeof option === "string"
            ? option
            : option.inputValue
            ? option.addText
            : option.name}
        </li>
      )}
      renderInput={params => (
        <TextField
          {...params}
          spellCheck="true"
          label={label}
          placeholder={placeholder}
        />
      )}
      value={inputValue.map(item => item.name)}
      onChange={(event, newValue, option, details) => {
        handleInputChange(newValue, option, details);
      }}
      fullWidth
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        const { inputValue } = params;
        const isExisting = options.some(option => inputValue === option.name);
        if (inputValue !== "" && !isExisting) {
          filtered.push({
            inputValue,
            addText: `Add "${inputValue}"`,
            mode: "addOption",
          });
        }
        return filtered;
      }}
      sx={theme => ({
        "& .MuiAutocomplete-popper": {
          borderRadius: 4,
          background: theme.palette.secondary.greyBg,
        },
        "& .MuiOutlinedInput-root": {
          height: "max-content",
        },
      })}
    />
  );
}
