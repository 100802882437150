import React, { useRef, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import MessageHandlerModal from "../modal/MessageHandler";
import messages from "../../constants/messages";
import { handleMixPanel } from "../../utils/mixPanelEventHandling";

const RouterPrompt = ({ when, stepPath }) => {
  const history = useHistory();
  const [showPrompt, setShowPrompt] = useState(false);
  const [currentPath, setCurrentPath] = useState("");
  const powerUpId = useSelector((state) => state?.mixpanel?.powerUpId);
  const powerUpName = useSelector((state) => state?.mixpanel?.powerUpName);
  const powerUpPercentComplete = useSelector((state) => state?.mixpanel?.powerUpPercentComplete);
  const powerUpStep = useSelector((state) => state?.mixpanel?.powerUpStep);
  const unblockRef = useRef();

  useEffect(() => {
    //unblockRef.current = history.block(location => {
    //  if (when() && location.pathname !== stepPath) {
    //    setCurrentPath(location.pathname);
    //    setShowPrompt(true);
    //    return false;
    //  }
    //  return true;
    //});
    //return () => {
    //  unblockRef.current && unblockRef.current();
    //};

    if (when()) {
      history.block(prompt => {
        if (prompt.pathname != stepPath) {
          setCurrentPath(prompt.pathname);
          setShowPrompt(true);
          return "true";
        } else {
          history.block(() => {});
          return false;
        }
      });
    } else {
      return false;
    }

    return () => {
      history.block(() => {});
    };
  }, [when, history]);

  const handleOK = () => {
    handleMixPanel("Powerup Closed", {
      "Step": powerUpStep,
      "Percent Complete": powerUpPercentComplete,
      "Powerup ID": powerUpId,
      "Powerup Name": powerUpName
    })

    //if (unblockRef) {
    //  unblockRef.current();
    //}
    setShowPrompt(false);
    history.block(() => {});
    history.push(currentPath);
  };

  const handleCancel = () => {
    setShowPrompt(false);
  };

  return (
    <MessageHandlerModal
      text={messages.UNSAVED_CHANGES_PROMPT_MESSAGE}
      isOpen={showPrompt}
      onOk={handleOK}
      okText={"Discard"}
      heading={"Discard changes"}
      onCancel={handleCancel}
      cancelText={"No thanks"}
      messageType="primary"
    ></MessageHandlerModal>
  );
};

export default RouterPrompt;
