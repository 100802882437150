import React, { useEffect, useState } from "react";
import { Box, Stack, Typography, Grid, TextField } from "@mui/material";
import { REGEX } from "../../../constants/regex";
import messages from "../../../constants/messages";
import services from "../../../services";
import { toastContainer } from "../../../utils/toast";
import PageTitle from "../PageTitle";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

export const PersonalDetails = ({ setComplete }) => {
	const [firstName, setFirstName] = useState({ value: "", error: "" });
	const [lastName, setLastName] = useState({ value: "", error: "" });
	const [linkedInProfile, setLinkedInProfile] = useState({
		value: "",
		error: "",
	});
	const [zipCode, setZipcode] = useState({ value: "", error: "" });
	const [state, setState] = useState({ value: "" });
	const [city, setCity] = useState({ value: "" });
	const history = useHistory();
	const validGroups = ["SVEN"];
	const [affiliatedGroup, setAffiliatedGroup] = useState("");

	const handleLocationApiCall = async (value) => {
		if (value.length >= 5) {
			const locationData = await services.searchLocationByZipcode(value);

			if (locationData.data) {
				const usaData = locationData?.data?.filter((loc) => loc?.address?.country_code === "us");
				if (usaData.length > 0) {
					if (usaData[0].address?.state) {
						setState((prevState) => ({
							...prevState,
							value: usaData[0].address.state,
						}));

						if (usaData[0].address.city) {
							setCity((prevState) => ({
								...prevState,
								value: usaData[0].address?.city,
							}));
						} else {
							setCity((prevState) => ({
								...prevState,
								value: usaData[0].address?.town ? usaData[0].address?.town : "",
							}));
						}
					} else {
						//If state dont found for zipcode api
						const lattitude = usaData[0]?.lat;
						const longitude = usaData[0]?.lon;

						const latLongData = await services.searchLocationByCoordinates(lattitude, longitude);
						const address = latLongData.data.address;

						if (address.country_code === "us") {
							setState((prevState) => ({
								...prevState,
								value: address.state,
							}));

							if (address.city) {
								setCity((prevState) => ({
									...prevState,
									value: address?.city,
								}));
							} else {
								setCity((prevState) => ({
									...prevState,
									value: address?.town ? address?.town : "",
								}));
							}
						} else {
							// toastContainer(messages.LOCATION_INVALID, "error");
						}
					}
				} else {
					// toastContainer(messages.LOCATION_INVALID, "error");
				}
			}
		}
	};

	//const getLocationDetails = zipcode => {
	//  services
	//    .searchLocationByPostalCode(zipcode)
	//    .then(response => {
	//      if (response.data.code === 200) {
	//        const responseData = response.data.data;
	//        setState(prevState => ({
	//          ...prevState,
	//          value: responseData?.state?.longName
	//            ? responseData?.state?.longName
	//            : "",
	//        }));

	//        setCity(prevState => ({
	//          ...prevState,
	//          value: responseData?.city?.longName
	//            ? responseData?.city?.longName
	//            : "",
	//        }));
	//      }
	//    })
	//    .catch(e => {
	//      console.log(e);
	//      toastContainer(messages.API_SUBMIT_ERROR, "error");
	//    });
	//};

	const handleBlur = (e, type) => {
		let eventValue = e.target.value;

		switch (type) {
			case "firstName":
				if (!eventValue.match(REGEX.COMPANY_NAME)) {
					setFirstName((prevState) => ({
						...prevState,
						error: messages.COMPANY_NAME_LENGTH_INVALID,
						value: eventValue,
					}));
				} else {
					setFirstName((prevState) => ({
						...prevState,
						error: "",
						value: eventValue,
					}));
				}
				break;
			case "lastName":
				if (!eventValue.match(REGEX.COMPANY_NAME)) {
					setLastName((prevState) => ({
						...prevState,
						error: messages.COMPANY_NAME_LENGTH_INVALID,
						value: eventValue,
					}));
				} else {
					setLastName((prevState) => ({
						...prevState,
						error: "",
						value: eventValue,
					}));
				}
				break;
			case "zip":
				if (!eventValue.match(REGEX.POSTAL_CODE)) {
					setZipcode((prevState) => ({
						...prevState,
						error: messages.LOCATION_INVALID,
						value: eventValue,
					}));
					setState((prevState) => ({
						...prevState,
						value: "",
					}));
				} else {
					handleLocationApiCall(eventValue);
					//getLocationDetails(eventValue);
					setZipcode((prevState) => ({
						...prevState,
						error: "",
						value: eventValue,
					}));
				}
				break;
			case "city":
				setCity((prevState) => ({
					...prevState,
					value: eventValue,
				}));
				break;
			case "state":
				setState((prevState) => ({
					...prevState,
					value: eventValue,
				}));
				break;
			default:
				if (!REGEX.LINKEDIN_NAME.test(eventValue)) {
					setLinkedInProfile((prevState) => ({
						...prevState,
						error: messages.LINKEDIN_VALID,
						value: eventValue,
					}));
				} else {
					setLinkedInProfile((prevState) => ({
						...prevState,
						error: "",
						value: eventValue,
					}));
				}
				break;
		}
	};
	const handleOnChange = (e, type) => {
		let eventValue = e.target.value;
		switch (type) {
			case "firstName":
				setFirstName((prevState) => ({
					...prevState,
					error: "",
					value: eventValue,
				}));

				break;
			case "lastName":
				setLastName((prevState) => ({
					...prevState,
					value: eventValue,
					error: "",
				}));

				break;
			case "zip":
				setZipcode((prevState) => ({
					...prevState,
					value: eventValue,
					error: "",
				}));

				break;
			case "city":
				setCity((prevState) => ({
					...prevState,
					value: eventValue,
					error: "",
				}));

				break;
			case "state":
				setState((prevState) => ({
					...prevState,
					value: eventValue,
					error: "",
				}));

				break;
			default:
				setLinkedInProfile((prevState) => ({
					...prevState,
					value: eventValue,
					error: "",
				}));

				break;
		}
	};

	const savedData = useSelector((state) => state?.onboarding);

	useEffect(() => {
		setAffiliatedGroup(savedData.areYouAffiliate);
		if (savedData.firstName) {
			setFirstName({ ...firstName, value: savedData.firstName || "" });
			setLastName({ ...lastName, value: savedData.lastName || "" });
			setLinkedInProfile({
				...linkedInProfile,
				value: savedData.linkedInProfile,
			});
			setZipcode({ ...zipCode, value: savedData.zipCode || "" });
			setCity({ ...city, value: savedData.city || "" });
			setState({ ...state, value: savedData.state || "" });
		}
	}, [savedData]);

	useEffect(() => {
		let payload = {
			firstName: firstName.value,
			lastName: lastName.value,
			linkedInProfile: linkedInProfile.value,
			zipCode: zipCode.value,
			city: city.value,
			state: state.value,
		};
		if (firstName.value.length > 0 && lastName.value.length > 0) {
			if (validGroups.includes(affiliatedGroup)) {
				callComplete(1, payload);
			} else {
				// callComplete(1, payload);
				if (linkedInProfile.error) {
					callComplete(0, payload);
				} else {
					callComplete(1, payload);
				}
			}
		} else {
			callComplete(0, payload);
		}
	}, [firstName, lastName, zipCode, linkedInProfile, city, state]);

	/*
      Call Complete --> setComplete prop:
      This is called on the parent to toggle the 'Next' button -> active or inactive.
      The button lives in an area not directly accessible by this component.
      Therefore we need to notify the parent to unlock the button.
      If there is an error; the button will need to be disabled, so we'd need to pass 'false' instead.
    */
	const callComplete = (complete, data) => {
		if (setComplete) {
			setComplete(complete, data);
		}
	};

	return (
		<Stack alignItems="center" justifyContent="center">
			<Grid container alignItems="center" justifyContent="center">
				<Grid item xs={12} sm={12} md={12} lg={12} sx={{ px: 2 }}>
					<PageTitle title={"Tell us a little about yourself:"} />
				</Grid>
				<Grid item xs={12} sm={10} md={6} lg={6} sx={{ px: 2 }}>
					<Box>
						<TextField
							label="First Name*"
							spellCheck="true"
							value={firstName.value}
							sx={{ mt: 9, width: 1 }}
							onBlur={(e) => {
								handleBlur(e, "firstName");
							}}
							error={firstName.error}
							helperText={firstName.error}
							onChange={(e) => {
								handleOnChange(e, "firstName");
							}}
						/>
						<TextField
							label="Last Name*"
							sx={{ mt: 3, width: 1 }}
							spellCheck="true"
							value={lastName.value}
							onBlur={(e) => {
								handleBlur(e, "lastName");
							}}
							error={lastName.error}
							helperText={lastName.error}
							onChange={(e) => {
								handleOnChange(e, "lastName");
							}}
						/>

						<TextField
							spellCheck="true"
							label="LinkedIn Profile Link"
							sx={{ mt: 3, width: 1 }}
							value={linkedInProfile.value && linkedInProfile.value.replace(/^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(in\/)/gm, "")}
							onBlur={(e) => {
								handleBlur(e, "LinkedInProfile");
							}}
							error={linkedInProfile.error}
							helperText={linkedInProfile.error}
							onChange={(e) => {
								handleOnChange(e, "LinkedInProfile");
							}}
							// required={!validGroups.includes(affiliatedGroup)}
							InputProps={{
								startAdornment: <Typography>https://www.linkedin.com/in/</Typography>,
							}}
						/>
						<TextField
							spellCheck="true"
							label="ZIP/Postal Code"
							sx={{ mt: 3, width: 1 }}
							value={zipCode.value}
							onBlur={(e) => {
								handleBlur(e, "zip");
							}}
							error={zipCode.error}
							helperText={zipCode.error}
							onChange={(e) => {
								handleOnChange(e, "zip");
							}}
						/>
						<TextField
							spellCheck="true"
							label="City"
							sx={{ mt: 3, width: 1 }}
							disabled={false}
							value={city.value}
							onBlur={(e) => {
								handleBlur(e, "city");
							}}
							onChange={(e) => {
								handleOnChange(e, "city");
							}}
						/>
						<TextField
							spellCheck="true"
							label="State/Province"
							disabled={false}
							sx={{ mt: 3, width: 1 }}
							value={state.value}
							onBlur={(e) => {
								handleBlur(e, "state");
							}}
							onChange={(e) => {
								handleOnChange(e, "state");
							}}
						/>
					</Box>
				</Grid>
			</Grid>
		</Stack>
	);
};
