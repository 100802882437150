import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";

export default function PageTitle({ title, description, hideBackButton }) {
  const history = useHistory();
  const { url, path } = useRouteMatch();

  const handleBackClick = () => {
    const paths = path.split("/");
    const currentStep = paths[3];
    if (currentStep == 2) {
      history.push(path.replace("2", "1"));
    } else {
      history.goBack();
    }
  };

  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("md"));
  return (
    <>
      {isSmallScreen ? (
        <Box>
          <Box textAlign={"center"}>
            <Box sx={{ my: 2 }}>
              <Typography variant="headline4">{title}</Typography>
            </Box>
            {description && (
              <Box sx={{ mt: 2, px: 2 }}>
                <Typography variant="body_large" color={"secondary.helperText"}>
                  {description}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      ) : (
        <Box sx={{ mt: 5 }} width={1}>
          <Grid container spacing={3}>
            <Grid item md={1} xl={2}>
              {!hideBackButton && (
                <Box sx={{ ml: 2 }}>
                  <ArrowBackIcon
                    sx={{ cursor: "Pointer" }}
                    onClick={() => handleBackClick()}
                  />
                </Box>
              )}
            </Grid>

            <Grid item md={10} xl={8}>
              <Box>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Typography variant="headline4">{title}</Typography>
                </Box>
                {description && (
                  <Box
                    sx={{
                      mt: 2,
                      px: 5,
                      display: "flex",
                      justifyContent: "center",
                      textAlign: "center",
                    }}
                  >
                    <Typography
                      variant="body_large"
                      color={"secondary.helperText"}
                    >
                      {description}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Grid>
            <Grid item md={1} xl={2}></Grid>
          </Grid>
        </Box>
      )}
    </>
  );
}
