import { Box } from "@mui/material";
import React from "react";
import BenchmarkCompanyDetails from "./companyDetails";
import BenchmarkHeaderNavigation from "./navigationItems";

function BenchmarkCompanyHeader() {
	return (
		<Box
			sx={{
				display: "flex",
				justifyContent: "space-between",
				flexWrap: "wrap-reverse",
				gap: 2,
			}}
		>
			<Box sx={{}}>
				<BenchmarkCompanyDetails />
			</Box>

			{/* 
                    // TODO -- Due to time constraints and the components not being in a reusable form.
                    Need to make all of the "Add to ..." [ Watchlist, Portfolio, etc...] reusable.
                    Currently these items are baked into other components and are not portable.
                    They should be created as standalone items that are controlled by redux so that
                    they can be manipulated by anything via a single function call.
                    EX.
                    "Add to watchlist" flyout, should be added at the parent level.
                    Open/Close controlled by redux.
                    ID add controlled by redux as well.
                    ---
                    Then we'd be able to have a single sourced component triggered from anywhere.
                */}

			<Box sx={{ ml: "auto" }}>
				<BenchmarkHeaderNavigation />
			</Box>

			{/* Make a request button zone */}
		</Box>
	);
}

export default BenchmarkCompanyHeader;
