import services from "../../../../services";

export const pitchHookAI = async (companyName, valueProp, cb) => {
	cb({ key: "hookAI", values: { loading: true, complete: false } });

	let payload = {
		messages: [
			{
				role: "system",
				content: `Given the startup ${companyName} with a value proposition: ${valueProp}. 
                Considering the company name and their value proposition, Craft a concise and impactful statement related to the broader theme, without directly advertising the product.`,
			},
		],
		maxTokens: 800,
	};

	let attempts = 0;
	while (attempts < 2) {
		try {
			let AIResp = await services.getOpenAIChatResponse(payload).catch((e) => {
				console.log(e);
			});
			cb({ key: "hookAI", values: { loading: false, complete: true } });

			if (AIResp?.data?.data?.[0]?.text) {
				return AIResp?.data?.data?.[0]?.text;
			} else if (AIResp?.data?.data?.[0]?.message?.content) {
				return AIResp?.data?.data?.[0]?.message?.content;
			} else {
				attempts++;
			}
		} catch (error) {
			console.error(error);
		}
	}
	return false;
};
