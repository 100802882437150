import React, { useEffect } from "react";
import { Box, Typography, Grid } from "@mui/material";
import AccountPublicBioCard from "../../../components/PublicProfile/AccountPublicProfile/BioCard";
import AccountPublicAboutCard from "../../../components/PublicProfile/AccountPublicProfile/AboutCard";
import PublicExperienceCard from "../../../components/PublicProfile/AccountPublicProfile/ExperienceCard";
import PublicEducationCard from "../../../components/PublicProfile/AccountPublicProfile/EducationCard/index";
import { useLocation } from "react-router-dom";
// import { GetAccountDataQueryCall } from "../Functions/common";
import { getUserPublicDataApiCall } from "../Functions/common";
import { setAccountPublicPrifileData } from "../../../modules/actions/publicProfileActions";
import { useDispatch } from "react-redux";
import { toastContainer } from "../../../utils/toast";

const AccountPublicProfile = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const uuid = `${location.pathname.slice(9)}`;
	useEffect(() => {
		dispatch(
			setAccountPublicPrifileData({
				loading: true,
			})
		);
		getUserPublicDataApiCall(uuid)
			.then((response) => {
				if (response?.data?.code === 200) {
					dispatch(
						setAccountPublicPrifileData({
							loading: false,
							accountPublicData: response?.data?.data,
						})
					);
				} else if (response?.response?.data?.code === 429) {
					dispatch(
						setAccountPublicPrifileData({
							loading: false,
							accountPublicData: null,
						})
					);
					toastContainer("Too many requests.", "error");
				} else {
					console.log("error");
				}
			})
			.catch((err) => {
				dispatch(
					setAccountPublicPrifileData({
						loading: false,
					})
				);
				console.log(err);
			});
	}, []);

	// if (uuid) {
	// 	GetAccountDataQueryCall(uuid);
	// }
	return (
		<Box
			sx={(theme) => ({
				maxWidth: theme.breakpoints.values.xl,
				position: "relative",
				margin: "auto",
				my: 4,
			})}
		>
			<Grid container spacing={4}>
				<Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
					<AccountPublicBioCard />
				</Grid>
				<Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
					<AccountPublicAboutCard />
				</Grid>
				<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
					<PublicExperienceCard />
				</Grid>
				<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
					<PublicEducationCard />
				</Grid>
			</Grid>
		</Box>
	);
};

export default AccountPublicProfile;
