import styled from "@emotion/styled";
import { IconButton } from "@mui/material";
import { sosPrimary } from "../../constants/Theme/colors";

export const IconComponent = styled(props => <IconButton {...props} />)(
  ({ theme, selected, gradient }) => {
    return {
      width: "72px",
      height: "72px",
      [theme.breakpoints.down("md")]: {
        width: "40px",
        height: "40px",
      },
      borderColor: selected
        ? theme.palette.primary.main
        : theme.palette.secondary.dark,
      borderWidth: "1px",
      borderStyle: "solid",
      background: selected ? gradient : theme.palette.secondary.white,

      "&:hover": {
        borderColor: theme.palette.primary.main,
        background: selected ? gradient : sosPrimary[50],
      },
      "&:focused": {
        background: gradient,
      },

      cursor: "pointer",
    };
  }
);
