export const steps = [
  {
    label: "My Role",
    complete: false,
  },
  {
    label: "About Me",
    complete: false,
  },
  {
    label: "Industry Focus",
    complete: false,
  },

  {
    label: "Group Affiliation",
    complete: false,
  },
];
