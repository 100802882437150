import { Accordion, AccordionSummary, Avatar, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { sosPrimary } from "../../../../constants/Theme/colors";
import theme from "../../../../constants/Theme";

export const PowerUpAccordion = styled(Accordion)(({ theme }) => ({
  background: theme.palette.secondary.white,
  boxShadow: "none",
}));

export const PowerUpSummary = styled(AccordionSummary)(
  ({ theme, expanded }) => ({
    color: expanded
      ? theme.palette.secondary.black
      : theme.palette.secondary.main,
  })
);
export const PowerUpAvatar = styled(Avatar)(({ theme, expanded, active }) => ({
  width: 32,
  height: 32,
  color: expanded ? sosPrimary[500] : theme.palette.text.secondary,
  backgroundColor: "transparent",
  border: expanded
    ? `1px solid ${sosPrimary[500]}`
    : active
    ? `1px solid ${theme.palette.secondary.dark}`
    : `1px solid ${theme.palette.text.inactive}`,
  [theme.breakpoints.down("md")]: {
    background: "transparent",
  },
}));

export const AccordionWrapper = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.secondary.light}`,
  // backgroundColor: theme.palette.text.background,
  boxSizing: "border-box",
  borderRadius: theme.shape.standard.borderRadius,
  padding: theme.spacing(2),
}));
