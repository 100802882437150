import { createTheme } from "@mui/material/styles";
import { sosPrimary } from "./colors";

export const themeTypography = (themePalette) => ({
	typography: {
		customValue: {
			/// Provides a way to specify font style inline.
		},
		body1: {
			"@media (max-width:904px)": {
				fontFamily: '"RobotoRegular", "Helvetica", "Arial", sans-serif',
				fontSize: "12px",
				lineHeight: "20px",
			},
			"@media (min-width:905px)": {
				fontFamily: '"RobotoRegular", "Helvetica", "Arial", sans-serif',
				fontSize: "14px",
				lineHeight: "22px",
			},
			"@media (min-width:1440px)": {
				fontFamily: '"RobotoRegular", "Helvetica", "Arial", sans-serif',
				fontSize: "14px",
				lineHeight: "26px",
			},
		},
		bodyF16: {
			"@media (max-width:904px)": {
				fontFamily: '"PoppinsRegular", "Helvetica", "Arial", sans-serif',
				fontSize: "14px",
				lineHeight: "20px",
			},
			"@media (min-width:905px)": {
				fontFamily: '"PoppinsRegular", "Helvetica", "Arial", sans-serif',
				fontSize: "16px",
				lineHeight: "22px",
			},
			"@media (min-width:1440px)": {
				fontFamily: '"PoppinsRegular", "Helvetica", "Arial", sans-serif',
				fontSize: "16px",
				lineHeight: "26px",
			},
		},
		h1: {
			"@media (max-width:904px)": {
				fontFamily: '"PoppinsSemiBold", "Helvetica", "Arial", sans-serif',
				fontSize: "28px",
				lineHeight: "36px",
			},
			"@media (min-width:905px)": {
				fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
				fontSize: "36px",
				lineHeight: "44px",
			},
		},
		Homeh1: {
			"@media (max-width:904px)": {
				fontFamily: '"PoppinsSemiBold", "Helvetica", "Arial", sans-serif',
				fontSize: "36px",
				lineHeight: "44px",
			},
			"@media (min-width:905px)": {
				fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
				fontSize: "45px",
				lineHeight: "52px",
			},
			"@media (min-width:1440px)": {
				fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
				fontSize: "57px",
				lineHeight: "64px",
			},
		},
		h2: {
			"@media (max-width:904px)": {
				fontFamily: '"PoppinsRegular", "Helvetica", "Arial", sans-serif',
				fontSize: "24px",
				lineHeight: "32px",
			},
			"@media (min-width:905px)": {
				fontFamily: '"PoppinsRegular", "Helvetica", "Arial", sans-serif',
				fontSize: "28px",
				lineHeight: "36px",
			},
			"@media (min-width:1440px)": {
				fontFamily: '"PoppinsRegular", "Helvetica", "Arial", sans-serif',
				fontSize: "28px",
				lineHeight: "40px",
			},
		},
		h3: {
			"@media (max-width:904px)": {
				fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
				fontSize: "18px",
				lineHeight: "24px",
			},
			"@media (min-width:905px)": {
				fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
				fontSize: "24px",
				lineHeight: "32px",
			},
		},
		h4: {
			"@media (max-width:769px)": {
				fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
				fontSize: "16px",
				lineHeight: "24px",
				fontWeight: 500,
			},
			"@media (max-width:904px)": {
				fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
				fontSize: "12px",
				lineHeight: "16px",
			},
			"@media (min-width:905px)": {
				fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
				fontSize: "14px",
				fontSize: "16px",
				lineHeight: "20px",
			},
		},
		h5: {
			"@media (max-width:904px)": {
				fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
				fontSize: "12px",
				lineHeight: "16px",
			},
			"@media (min-width:905px)": {
				fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
				fontSize: "14px",
				lineHeight: "20px",
			},
			"@media (max-width:1017px)": {
				fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
				fontSize: "12px",
				lineHeight: "20px",
			},
		},
		h6: {
			"@media (max-width:904px)": {
				fontFamily: '"PoppinsRegular", "Helvetica", "Arial", sans-serif',
				fontSize: "12px",
				lineHeight: "16px",
			},
			"@media (min-width:905px)": {
				fontFamily: '"PoppinsRegular", "Helvetica", "Arial", sans-serif',
				fontSize: "12px",
				lineHeight: "16px",
			},
			"@media (min-width:1440px)": {
				fontFamily: '"PoppinsRegular", "Helvetica", "Arial", sans-serif',
				fontSize: "12px",
				lineHeight: "20px",
			},
		},
		caption: {
			fontFamily: '"RobotoRegular", "Helvetica", "Arial", sans-serif',
			fontSize: "12px",
			lineHeight: "16px",
		},
		h7: {
			"@media (max-width:904px)": {
				fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
				fontSize: "11px",
				lineHeight: "16px",
			},
			"@media (min-width:905px)": {
				fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
				fontSize: "11px",
				lineHeight: "16px",
			},
		},
		h8: {
			"@media (max-width:904px)": {
				fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
				fontSize: "8px",
				lineHeight: "8px",
			},
			"@media (min-width:905px)": {
				fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
				fontSize: "8px",
				lineHeight: "8px",
			},
		},
		h22: {
			"@media (max-width:904px)": {
				fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
				fontSize: "20px",
				lineHeight: "24px",
			},
			"@media (min-width:905px)": {
				fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
				fontSize: "22px",
				lineHeight: "28px",
			},
		},
		h22Bold: {
			"@media (max-width:904px)": {
				fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
				fontSize: "18px",
				lineHeight: "24px",
				fontWeight: 600,
			},
			"@media (min-width:905px)": {
				fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
				fontSize: "22px",
				lineHeight: "28px",
				fontWeight: 600,
			},
		},
		h2Medium: {
			"@media (max-width:904px)": {
				fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
				fontSize: "24px",
				lineHeight: "32px",
			},
			"@media (min-width:905px)": {
				fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
				fontSize: "28px",
				lineHeight: "36px",
			},
			"@media (min-width:1440px)": {
				fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
				fontSize: "28px",
				lineHeight: "40px",
			},
		},
		subtitle: {
			fontSize: "14px",
			color: "rgba(10, 20, 27, 0.8)",
		},
		link: {
			fontSize: "14px",
			color: "rgba(0, 0, 238, 1)",
		},
		linkBold: {
			fontSize: "16px",
			color: "rgba(0, 0, 238, 1)",
			fontWeight: 700,
		},
		subtitle1: {
			fontSize: "12px",
			color: "rgba(10, 20, 27, 0.8)",
			"@media (max-width:600px)": {
				fontSize: "12px",
			},
			"@media (min-width:600px)": {
				fontSize: "12px",
			},
		},
		subtitle2: {
			fontSize: "11px",
			color: "rgba(10, 20, 27, 0.8)",
			"@media (max-width:600px)": {
				fontSize: "11px",
			},
			"@media (min-width:600px)": {
				fontSize: "11px",
			},
		},
		button: {
			fontFamily: '"PoppinsRegular", "Helvetica", "Arial", sans-serif',
			fontSize: "14px",
			lineHeight: "20px",
			fontWeight: 500,
		},
		thinFont: {
			"@media (max-width:904px)": {
				fontFamily: '"PoppinsThin", "Helvetica", "Arial", sans-serif',
				fontSize: "18px",
				lineHeight: "12px",
				letterSpacing: 5,
			},
			"@media (min-width:905px)": {
				fontFamily: '"PoppinsThin", "Helvetica", "Arial", sans-serif',
				fontSize: "24px",
				lineHeight: "12px",
				letterSpacing: 5,
			},
		},
		boldFont: {
			"@media (max-width:904px)": {
				fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
				fontSize: "18px",
				lineHeight: "12px",
				letterSpacing: 5,
			},
			"@media (min-width:905px)": {
				fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
				fontSize: "24px",
				lineHeight: "12px",
				letterSpacing: 5,
			},
		},
		f18: {
			"@media (max-width:904px)": {
				fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
				fontSize: "16px",
				lineHeight: "20px",
			},
			"@media (min-width:905px)": {
				fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
				fontSize: "18px",
				lineHeight: "24px",
			},
		},
		headline6: {
			"@media (max-width:904px)": {
				fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
				fontSize: "16px",
				lineHeight: "20px",
			},
			fontFamily: '"PoppinsSemiBold", "Helvetica", "Arial", sans-serif',
			fontSize: "18px",
			lineHeight: "24px",
		},
		headline5: {
			fontFamily: '"PoppinsSemiBold", "Helvetica", "Arial", sans-serif',
			fontSize: "22px",
			lineHeight: "28px",
		},
		f16: {
			"@media (max-width:904px)": {
				fontFamily: '"RobotoRegular", "Helvetica", "Arial", sans-serif',
				fontSize: "16px",
				lineHeight: "20px",
			},
			"@media (min-width:905px)": {
				fontFamily: '"RobotoRegular", "Helvetica", "Arial", sans-serif',
				fontSize: "16px",
				lineHeight: "24px",
			},
		},

		largeTitle: {
			fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
			fontSize: "22px",
			lineHeight: "28px",
		},
		headline2: {
			fontFamily: '"PoppinsSemiBold", "Helvetica", "Arial", sans-serif',
			fontSize: "27.5",
			lineHeight: "40px",
		},
		main_title: {
			fontFamily: '"PoppinsSemiBold", "Helvetica", "Arial", sans-serif',
			fontSize: "20px",
			lineHeight: "30px",
		},
		main_title_card: {
			fontFamily: '"PoppinsSemiBold", "Helvetica", "Arial", sans-serif',
			fontSize: "20px",
			lineHeight: "30px",
		},
		headline3: {
			fontFamily: '"PoppinsSemiBold", "Helvetica", "Arial", sans-serif',
			fontSize: "28px",
			lineHeight: "36px",
		},
		headline4: {
			fontFamily: '"PoppinsSemiBold", "Helvetica", "Arial", sans-serif',
			fontSize: "24px",
			lineHeight: "24px",
		},
		subhead1: {
			fontFamily: '"PoppinsRegular", "Helvetica", "Arial", sans-serif',
			fontSize: "16px",
			lineHeight: "24px",
			fontWeight: 400,
		},
		subhead2: {
			fontFamily: '"PoppinsRegular", "Helvetica", "Arial", sans-serif',
			fontSize: "14px",
			lineHeight: "20px",
			fontWeight: 400,
		},
		subhead3: {
			fontFamily: '"PoppinsRegular", "Helvetica", "Arial", sans-serif',
			fontSize: "16px",
			lineHeight: "24px",
			fontWeight: 500,
		},
		count_title: {
			fontFamily: '"PoppinsSemiBold", "Helvetica", "Arial", sans-serif',
			fontSize: "16px",
			lineHeight: "16px",
		},
		overlineLarge: {
			fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
			fontSize: "12px",
			lineHeight: "12px",
		},
		displaySmall: {
			"@media (max-width:904px)": {
				fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
				fontSize: "32px",
				lineHeight: "36px",
			},
			"@media (min-width:905px)": {
				fontFamily: '"PoppinsSemiBold", "Helvetica", "Arial", sans-serif',
				fontSize: "36px",
				lineHeight: "40px",
			},
		},
		titleXLarge: {
			"@media (max-width:904px)": {
				fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
				fontSize: "24px",
				lineHeight: "32px",
				fontWeight: 500,
			},
			"@media (min-width:905px)": {
				fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
				fontSize: "20px",
				lineHeight: "32px",
				fontWeight: 400,
			},
		},
		bodyLarge: {
			"@media (max-width:904px)": {
				fontFamily: '"RobotoRegular", "Helvetica", "Arial", sans-serif',
				fontSize: "16px",
				lineHeight: "24px",
				fontWeight: 400,
			},
			"@media (min-width:905px)": {
				fontFamily: '"RobotoRegular", "Helvetica", "Arial", sans-serif',
				fontSize: "16px",
				lineHeight: "24px",
				fontWeight: 400,
			},
		},
		bodyMedium: {
			"@media (max-width:904px)": {
				fontFamily: '"RobotoRegular", "Helvetica", "Arial", sans-serif',
				fontSize: "14px",
				lineHeight: "20px",
				fontWeight: 400,
			},
			"@media (min-width:905px)": {
				fontFamily: '"RobotoRegular", "Helvetica", "Arial", sans-serif',
				fontSize: "14px",
				lineHeight: "22px",
				fontWeight: 400,
			},
		},
		caption: {
			"@media (max-width:904px)": {
				fontFamily: '"RobotoRegular", "Helvetica", "Arial", sans-serif',
				fontSize: "12px",
				lineHeight: "16px",
				letterSpacing: 0.4,
			},
			"@media (min-width:905px)": {
				fontFamily: '"RobotoRegular", "Helvetica", "Arial", sans-serif',
				fontSize: "12px",
				lineHeight: "16px",
				letterSpacing: 0.4,
			},
		},
		bodySubhead: {
			"@media (max-width:769px)": {
				fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
				fontSize: "14px",
				lineHeight: "20px",
				fontWeight: 300,
			},
			"@media (max-width:904px)": {
				fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
				fontSize: "16px",
				lineHeight: "24px",
				fontWeight: 300,
			},
			"@media (min-width:905px)": {
				fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
				fontSize: "16px",
				lineHeight: "28px",
				fontWeight: 300,
			},
		},
		title_xlarge: {
			fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
			fontSize: "24px",
			lineHeight: "32px",
		},
		title_xxlarge: {
			fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
			fontSize: "28px",
			lineHeight: "36px",
		},
		title_large: {
			fontSize: "22px",
			lineHeight: "28px",
			fontFamily: '"PoppinsRegular", "Helvetica", "Arial", sans-serif',
		},
		title_large2: {
			fontSize: "16px",
			lineHeight: "20px",
			fontWeight: 600,
			fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
		},
		lable_large: {
			fontSize: "14px",
			lineHeight: "20px",
			fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
		},
		title_medium: {
			fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
			fontSize: "16px",
			lineHeight: "24px",
		},
		title_small: {
			fontSize: "14px",
			lineHeight: "20px",
			fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
		},
		title_light: {
			fontFamily: '"PoppinsRegular", "Helvetica", "Arial", sans-serif',
			fontSize: "16px",
			lineHeight: "24px",
		},
		label_small: {
			fontSize: "11px",
			lineHeight: "14px",
			fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
		},
		label_large: {
			fontSize: "14px",
			lineHeight: "20px",
			fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
		},
		label_xlarge: {
			fontSize: "16px",
			lineHeight: "24px",
			fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
		},
		body_small: {
			fontFamily: '"RobotoRegular", "Helvetica", "Arial", sans-serif',
			fontSize: "12px",
			lineHeight: "150%",
		},
		body_large: {
			fontFamily: '"RobotoRegular", "Helvetica", "Arial", sans-serif',
			fontSize: "16px",
			lineHeight: "24px",
			letterSpacing: ".5px",
		},
		body_bold: {
			fontFamily: '"RobotoBold", "Helvetica", "Arial", sans-serif',
			fontSize: "14px",
			lineHeight: "20px",
		},
		error_text: {
			fontSize: "12px",
			lineHeight: "10px",
			fontFamily: '"RobotoRegular", "Helvetica", "Arial", sans-serif',
			color: themePalette.palette.error.main,
		},
		on_surface: {
			fontSize: "14px",
			lineHeight: "20px",
			fontFamily: '"PoppinsSemiBold", "Helvetica", "Arial", sans-serif',
		},
		light_on_surface: {
			fontSize: "11px",
			lineHeight: "16px",
			fontFamily: '"PoppinsBold", "Helvetica", "Arial", sans-serif',
		},
		light_on_surface_variant: {
			fontSize: "14px",
			lineHeight: "20px",
			fontFamily: '"RobotoRegular", "Helvetica", "Arial", sans-serif',
		},
		title_bold: {
			fontSize: "16px",
			lineHeight: "24px",
			fontFamily: '"PoppinsBold", "Helvetica", "Arial", sans-serif',
		},
		boldSubHeading: {
			fontSize: "14px",
			lineHeight: "20px",
			fontFamily: '"PoppinsBold", "Helvetica", "Arial", sans-serif',
		},
		boldHeading: {
			fontSize: "16px",
			lineHeight: "20px",
			fontFamily: '"PoppinsBold", "Helvetica", "Arial", sans-serif',
		},
		boldText: {
			fontSize: "12px",
			lineHeight: "16px",
			fontFamily: '"RobotoBold", "Helvetica", "Arial", sans-serif',
		},
		light_on_surface: {
			fontSize: "11px",
			lineHeight: "16px",
			fontFamily: '"PoppinsBold", "Helvetica", "Arial", sans-serif',
		},
		card_name: {
			fontSize: "20px",
			lineHeight: "24px",
			fontFamily: '"PoppinsSemiBold", "Helvetica", "Arial", sans-serif',
		},
		card_text: {
			fontSize: "16px",
			lineHeight: "24px",
			fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
		},
		card_title: {
			fontSize: "50px",
			lineHeight: "75px",
			fontFamily: '"PoppinsSemiBold", "Helvetica", "Arial", sans-serif',
		},
		chip_label: {
			fontSize: "12px",
			lineHeight: "150%",
			fontFamily: '"PoppinsSemiBold", "Helvetica", "Arial", sans-serif',
		},
		sys_light_on_surface: {
			fontSize: "12px",
			lineHeight: "18px",
			fontFamily: '"RobotoBold", "Helvetica", "Arial", sans-serif',
		},
		title_portfolio: {
			fontSize: "18px",
			lineHeight: "27px",
			fontFamily: '"PoppinsSemiBold", "Helvetica", "Arial", sans-serif',
		},
		sub_title_portfolio: {
			fontSize: "18px",
			lineHeight: "27px",
			fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
		},
		headline1: {
			"@media (max-width:769px)": {
				fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
				fontSize: "28px",
				lineHeight: "36px",
				fontWeight: 600,
			},
			"@media (min-width:905px)": {
				fontFamily: '"PoppinsSemiBold", "Helvetica", "Arial", sans-serif',
				fontSize: "36px",
				lineHeight: "44px",
				fontWeight: 600,
			},
		},
		custom070: {
			fontFamily: '"PoppinsSemiBold", "Helvetica", "Arial", sans-serif',
			fontSize: "16px",
			lineHeight: "24px",
		},
		path_text: {
			fontFamily: '"PoppinsSemiBold", "Helvetica", "Arial", sans-serif',
			fontStyle: "normal",
			fontSize: "11px",
			lineHeight: "16px",
			color: themePalette.palette.secondary.white,
		},
		ph_headline: {
			"@media (max-width:904px)": {
				fontFamily: '"PoppinsSemiBold", "Helvetica", "Arial", sans-serif',
				fontSize: "22px",
				lineHeight: "36px",
				ontWeight: 500,
			},
			"@media (min-width:905px)": {
				fontFamily: '"PoppinsSemiBold", "Helvetica", "Arial", sans-serif',
				fontSize: "28px",
				lineHeight: "32px",
				fontWeight: 500,
			},
		},
		ph_text: {
			"@media (max-width:904px)": {
				fontFamily: '"PoppinsRegular", "Helvetica", "Arial", sans-serif',
				fontSize: "14px",
				lineHeight: "20px",
			},
			"@media (min-width:905px)": {
				fontFamily: '"PoppinsRegular", "Helvetica", "Arial", sans-serif',
				fontSize: "16px",
				lineHeight: "24px",
			},
		},
		ph_text_bold: {
			"@media (max-width:904px)": {
				fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
				fontSize: "14px",
				lineHeight: "20px",
				fontWeight: 500,
			},
			"@media (min-width:905px)": {
				fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
				fontSize: "16px",
				lineHeight: "24px",
				fontWeight: 500,
			},
		},
		revenue_title: {
			"@media (max-width:904px)": {
				fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
				fontSize: "16px",
				lineHeight: "18px",
			},
			"@media (min-width:905px)": {
				fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
				fontSize: "14px",
				lineHeight: "20px",
			},
		},
		revenue_body: {
			"@media (max-width:904px)": {
				fontFamily: '"RobotoRegular", "Helvetica", "Arial", sans-serif',
				fontSize: "16px",
				lineHeight: "24px",
			},
			"@media (min-width:905px)": {
				fontFamily: '"RobotoRegular", "Helvetica", "Arial", sans-serif',
				fontSize: "16px",
				lineHeight: "20px",
			},
		},
		bodyLargeBold: {
			"@media (max-width:904px)": {
				fontFamily: '"RobotoRegular", "Helvetica", "Arial", sans-serif',
				fontSize: "16px",
				lineHeight: "24px",
				fontWeight: 500,
			},
			"@media (min-width:905px)": {
				fontFamily: '"RobotoRegular", "Helvetica", "Arial", sans-serif',
				fontSize: "16px",
				lineHeight: "24px",
				fontWeight: 500,
			},
		},
		sys_light_on_surface_heading: {
			fontFamily: '"PoppinsSemiBold", "Helvetica", "Arial", sans-serif',
			fontSize: "22px",
			lineHeight: "28px",
			fontWeight: 500,
		},
		bodySubhead2: {
			"@media (max-width:769px)": {
				fontFamily: '"RobotoRegular", "Helvetica", "Arial", sans-serif',
				fontSize: "14px",
				lineHeight: "20px",
				fontWeight: 300,
			},
			"@media (max-width:904px)": {
				fontFamily: '"RobotoRegular", "Helvetica", "Arial", sans-serif',
				fontSize: "16px",
				lineHeight: "24px",
				fontWeight: 400,
			},
			"@media (min-width:905px)": {
				fontFamily: '"RobotoRegular", "Helvetica", "Arial", sans-serif',
				fontSize: "16px",
				lineHeight: "28px",
				fontWeight: 400,
			},
		},
		onsurface_subtext: {
			fontSize: "14px",
			lineHeight: "20px",
			fontWeight: 500,
			fontFamily: '"PoppinsRegular", "Helvetica", "Arial", sans-serif',
			color: themePalette.palette.text.primary,
		},
		header3: {
			fontSize: "34px",
			lineHeight: "130%",
			fontWeight: 600,
			fontFamily: '"PoppinsSemiBold", "Helvetica", "Arial", sans-serif',
		},
		xLarge: {
			fontFamily: '"PoppinsRegular", "Helvetica", "Arial", sans-serif',
			fontSize: "64px",
			lineHeight: "76px",
		},
		progress_value: {
			fontFamily: '"PoppinsRegular", "Helvetica", "Arial", sans-serif',
			fontSize: "16px",
			lineHeight: "24px",
			letterSpacing: 0.1,
			fontWeight: 700,
		},
		onsurface_subtitle: {
			fontSize: "11px",
			lineHeight: "16px",
			fontWeight: 500,
			fontFamily: '"PoppinsRegular", "Helvetica", "Arial", sans-serif',
			color: sosPrimary[500],
		},
		onsurface_subtext_primary: {
			fontSize: "14px",
			lineHeight: "20px",
			fontWeight: 500,
			fontFamily: '"PoppinsRegular", "Helvetica", "Arial", sans-serif',
			color: sosPrimary[500],
		},

		label_medium: {
			fontSize: "12px",
			lineHeight: "16px",
			fontWeight: 500,
			color: themePalette.palette.secondary.dark,
			fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
		},
		required_text: {
			fontSize: "12px",
			lineHeight: "10px",
			fontFamily: '"RobotoRegular", "Helvetica", "Arial", sans-serif',
			color: themePalette.palette.secondary.main,
		},
		tag: {
			fontSize: "12px",
			lineHeight: "16px",
			fontWeight: 500,
			fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
		},
		subhead4: {
			fontFamily: '"PoppinsRegular", "Helvetica", "Arial", sans-serif',
			fontSize: "16px",
			lineHeight: "24px",
			fontWeight: 700,
		},
		subhead5: {
			fontFamily: '"RobotoRegular", "Helvetica", "Arial", sans-serif',
			fontSize: "16px",
			lineHeight: "24px",
			fontWeight: 400,
		},
		subhead6: {
			fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
			fontSize: "22px",
			lineHeight: "28px",
			fontWeight: 500,
		},
		onsurface_grey: {
			fontSize: "12px",
			lineHeight: "20px",
			fontWeight: 500,
			fontFamily: '"PoppinsRegular", "Helvetica", "Arial", sans-serif',
			color: themePalette.palette.secondary.main,
		},
		onsurface_grey_medium: {
			fontSize: "14px",
			lineHeight: "24px",
			fontWeight: 500,
			fontFamily: '"PoppinsRegular", "Helvetica", "Arial", sans-serif',
			color: themePalette.palette.secondary.main,
		},
		onsurface_title: {
			fontFamily: '"PoppinsRegular", "Helvetica", "Arial", sans-serif',
			fontSize: "18px",
			lineHeight: "24px",
			fontWeight: 400,
		},
		onsurface_title_thick: {
			fontFamily: '"PoppinsRegular", "Helvetica", "Arial", sans-serif',
			fontSize: "18px",
			lineHeight: "24px",
			fontWeight: 600,
		},
		onsurface_subtext_medium: {
			fontSize: "14px",
			lineHeight: "20px",
			fontWeight: 700,
			fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
		},
		onsurface_text_light: {
			fontSize: "14px",
			lineHeight: "20px",
			fontWeight: 700,
			fontFamily: '"RobotoRegular", "Helvetica", "Arial", sans-serif',
			color: themePalette.palette.text.secondary,
		},
		onsurface_subtext_light: {
			fontSize: "14px",
			lineHeight: "20px",
			fontWeight: 500,
			fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
		},
		onsurface_subtext_gray: {
			fontSize: "14px",
			lineHeight: "20px",
			fontWeight: 400,
			fontFamily: '"RobotoRegular", "Helvetica", "Arial", sans-serif',
			color: themePalette.palette.text.atomic,
		},
		nav_button: {
			fontSize: "12px",
			lineHeight: "16px",
			fontWeight: 500,
			fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
			color: themePalette.palette.text.onSurfaceVariant,
			letterSpacing: 0.5,
		},
		overlineMedium: {
			fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
			fontSize: "12px",
			lineHeight: "12px",
			fontWeight: 500,
			letterSpacing: 0.5,
			color: themePalette.palette.text.secondary,
		},
		poppinsMedium18: {
			fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
			fontSize: "18px",
		},
		poppinsMedium16: {
			fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
			fontSize: "16px",
		},
		poppinsMedium14: {
			fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
			fontSize: "14px",
		},
		poppinsMedium12: {
			fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
			fontSize: "12px",
		},
		poppinsSemiBold30: {
			fontFamily: '"PoppinsSemiBold", "Helvetica", "Arial", sans-serif',
			fontSize: "30px",
		},
		poppinsSemiBold18: {
			fontFamily: '"PoppinsSemiBold", "Helvetica", "Arial", sans-serif',
			fontSize: "18px",
		},
		poppinsSemiBold20: {
			fontFamily: '"PoppinsSemiBold", "Helvetica", "Arial", sans-serif',
			fontSize: "20px",
		},
		poppinsSemiBold16: {
			fontFamily: '"PoppinsSemiBold", "Helvetica", "Arial", sans-serif',
			fontSize: "16px",
		},
		poppinsSemiBold14: {
			fontFamily: '"PoppinsSemiBold", "Helvetica", "Arial", sans-serif',
			fontSize: "14px",
		},
		poppinsSemiBold12: {
			fontFamily: '"PoppinsSemiBold", "Helvetica", "Arial", sans-serif',
			fontSize: "12px",
		},
		poppinsSemiBold25: {
			fontFamily: '"PoppinsSemiBold", "Helvetica", "Arial", sans-serif',
			fontSize: "25px",
			fontWeight: "500",
			lineHeight: "37.5px",
		},
		"Body/16pxBold": {
			fontFamily: '"PoppinsSemiBold", "Helvetica", "Arial", sans-serif',
			fontStyle: "normal",
			fontSize: "16px",
			lineHeight: "20px",
		},
		logo_text: {
			fontFamily: '"PoppinsSemiBold", "Helvetica", "Arial", sans-serif',
			fontStyle: "normal",
			fontSize: "25px",
			lineHeight: "38px",
		},
		flyout_title: {
			fontFamily: '"PoppinsSemiBold", "Helvetica", "Arial", sans-serif',
			fontSize: "20px",
			lineHeight: "30px",
		},
		flyout_text: {
			fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
			fontSize: "16px",
			lineHeight: "24px",
		},
		heading1: {
			fontSize: "24px",
			lineHeight: "24px",
			fontFamily: '"PoppinsBold", "Helvetica", "Arial", sans-serif',
		},
		headerText: {
			fontSize: "10px",
			lineHeight: "15px",
			fontFamily: '"PoppinsBold", "Helvetica", "Arial", sans-serif',
		},
		testHeading: {
			fontSize: "20px",
			lineHeight: "24px",
			fontFamily: '"PoppinsSemiBold", "Helvetica", "Arial", sans-serif',
		},
		"StartupOS/title/large": {
			fontFamily: '"PoppinsRegular", "Helvetica", "Arial", sans-serif',
			fontWeight: 500,
			fontSize: "22px",
			lineHeight: "28px",
			color: "rgba(70, 75, 78, 1)",
		},
		"StartupOS/headline/headline1": {
			fontFamily: '"PoppinsRegular", "Helvetica", "Arial", sans-serif',
			fontWeight: 600,
			fontSize: "36px",
			lineHeight: "44px",
			color: "#464B4E",
		},
		"StartupOS/headline/headline1Black": {
			fontFamily: '"PoppinsRegular", "Helvetica", "Arial", sans-serif',
			fontWeight: 600,
			fontSize: "36px",
			lineHeight: "44px",
			color: "#000000",
		},
		"StartupOS/headline/headline2": {
			fontFamily: '"PoppinsRegular", "Helvetica", "Arial", sans-serif',
			fontWeight: 600,
			fontSize: "32px",
			lineHeight: "40px",
			letterSpacing: "0em",
			textAlign: "center",
			color: "rgba(255, 255, 255, 1)",
		},
		"StartupOS/headline/headline3": {
			fontFamily: '"PoppinsRegular", "Helvetica", "Arial", sans-serif',
			fontWeight: 600,
			fontSize: "28px",
			lineHeight: "36px",
			letterSpacing: "0em",
			color: themePalette.palette.text.primary,
		},
		"StartupOS/headline/headline5": {
			fontFamily: '"PoppinsRegular", "Helvetica", "Arial", sans-serif',
			fontWeight: 600,
			fontSize: "22px",
			lineHeight: "28px",
			letterSpacing: "0em",
			color: themePalette.palette.secondary.black,
		},

		"StartupOS/headline/headline6": {
			fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
			fontWeight: 600,
			fontSize: "18px",
			lineHeight: "24px",
			color: "#000000",
		},
		"StartupOS/headline/headline6black": {
			fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
			fontWeight: 600,
			fontSize: "18px",
			lineHeight: "24px",
			// textTransform: "uppercase",
			color: "rgba(0, 0, 0, 1)",
			// color: themePalette.palette.text.lightOnsurface, /* This is a sort of greyish dark green and is supposed to be black. Should I replace in theme? */
		},
		"StartupOS/overline/large": {
			fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
			fontWeight: 500,
			fontSize: "16px",
			lineHeight: "16px",
			letterSpacing: "0.5px",
			textTransform: "uppercase",
			color: "#000000",
			// color: themePalette.palette.text.lightOnsurface, /* This is a sort of greyish dark green and is supposed to be black. Should I replace in theme? */
		},
		"StartupOS/label/x-large": {
			fontFamily: '"PoppinsRegular", "Helvetica", "Arial", sans-serif',
			fontWeight: 500,
			fontSize: "16px",
			lineHeight: "24px",
			letterSpacing: "0.1px",
			color: "#0A141B",
		},
		"StartupOS/subhead/subhead1": {
			fontFamily: '"PoppinsRegular", "Helvetica", "Arial", sans-serif',
			fontWeight: 400,
			fontSize: "16px",
			LineHeight: "24px",
			letterSpacing: "0.1px",
			color: "#464B4E",
		},
		"StartupOS/subhead/subhead1Black": {
			fontFamily: '"PoppinsRegular", "Helvetica", "Arial", sans-serif',
			fontWeight: 400,
			fontSize: "16px",
			LineHeight: "24px",
			letterSpacing: "0.1px",
			color: "#000000",
		},
		"StartupOS/subhead/subhead2": {
			fontFamily: '"PoppinsRegular", "Helvetica", "Arial", sans-serif',
			fontWeight: 400,
			fontSize: "16px",
			LineHeight: "24px",
			letterSpacing: "0.10000000149011612px",
			color: "rgba(255, 255, 255, 1)",
		},
		"StartupOS/subhead/subhead3": {
			fontFamily: '"PoppinsRegular", "Helvetica", "Arial", sans-serif',
			fontWeight: 400,
			fontSize: "14px",
			LineHeight: "20px",
			letterSpacing: "0.10000000149011612px",
			color: "rgba(70, 75, 78, 1)",
		},
		"StartupOS/body/medium": {
			fontFamily: '"RobotoRegular", "Helvetica", "Arial", sans-serif',
			fontWeight: 400,
			fontSize: "14px",
			LineHeight: "20px",
			Letter: "0.25px",
			color: "rgba(75, 69, 78, 1)",
		},
		"StartupOS/label/medium": {
			fontFamily: '"PoppinsRegular", "Helvetica", "Arial", sans-serif',
			fontWeight: 500,
			fontSize: "12px",
			LineHeight: "16px",
			Letter: "0.5px",
			color: "#0A141B",
		},
		"StartupOS/button/button": {
			fontFamily: '"PoppinsRegular", "Helvetica", "Arial", sans-serif',
			fontWeight: 500,
			fontSize: "14px",
			LineHeight: "20px",
			Letter: "0.1px",
			Align: "Center",
			color: "rgba(70, 75, 78, 1)",
		},
		"StartupOS/button/button2": {
			fontFamily: '"PoppinsRegular", "Helvetica", "Arial", sans-serif',
			fontWeight: 500,
			fontSize: "14px",
			LineHeight: "20px",
			Letter: "0.1px",
			Align: "Center",
			color: "rgba(255, 255, 255, 1)",
		},
		"Onboarding/MobileAppheader": {
			fontSize: "12px",
			lineHeight: "24px",
			fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
		},
		"body/medium": {
			fontFamily: '"RobotoRegular", "Helvetica", "Arial", sans-serif',
			fontSize: "14px",
			lineHeight: "20px",
			letterSpacing: ".25px",
			fontWeight: 400,
		},
		large_message_title: {
			fontFamily: '"PoppinsRegular", "Helvetica", "Arial", sans-serif',
			fontWeight: 600,
			fontSize: "49px",
			lineHeight: "64px",
		},
		body_medium_light: {
			"@media (max-width:904px)": {
				fontFamily: '"RobotoRegular", "Helvetica", "Arial", sans-serif',
				fontSize: "14px",
				lineHeight: "24px",
				fontWeight: 400,
			},
			"@media (min-width:905px)": {
				fontFamily: '"RobotoRegular", "Helvetica", "Arial", sans-serif',
				fontSize: "18px",
				lineHeight: "24px",
				fontWeight: 400,
			},
		},
		PoppinsMedium100: {
			fontSize: "100px",
			lineHeight: "150px",
			fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
		},
		body_medium_small: {
			"@media (max-width:904px)": {
				fontFamily: '"RobotoRegular", "Helvetica", "Arial", sans-serif',
				fontSize: "14px",
				lineHeight: "32px",
				fontWeight: 400,
			},
			"@media (min-width:905px)": {
				fontFamily: '"RobotoRegular", "Helvetica", "Arial", sans-serif',
				fontSize: "18px",
				lineHeight: "32px",
				fontWeight: 400,
			},
		},
		h2_dark: {
			"@media (max-width:904px)": {
				fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
				fontSize: "24px",
				lineHeight: "36px",
				fontWeight: 600,
			},

			"@media (min-width:905px)": {
				fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
				fontSize: "32px",
				lineHeight: "48px",
				fontWeight: 600,
			},
		},

		body_medium: {
			fontFamily: '"RobotoRegular", "Helvetica", "Arial", sans-serif',
			fontSize: "14px",
			lineHeight: "20px",
			letterSpacing: ".25px",
			fontWeight: 400,
		},
		body_medium_light: {
			"@media (max-width:904px)": {
				fontFamily: '"RobotoRegular", "Helvetica", "Arial", sans-serif',
				fontSize: "14px",
				lineHeight: "24px",
				fontWeight: 400,
			},
			"@media (min-width:905px)": {
				fontFamily: '"RobotoRegular", "Helvetica", "Arial", sans-serif',
				fontSize: "18px",
				lineHeight: "24px",
				fontWeight: 400,
			},
		},
		body_medium_small: {
			"@media (max-width:904px)": {
				fontFamily: '"RobotoRegular", "Helvetica", "Arial", sans-serif',
				fontSize: "14px",
				lineHeight: "32px",
				fontWeight: 400,
			},
			"@media (min-width:905px)": {
				fontFamily: '"RobotoRegular", "Helvetica", "Arial", sans-serif',
				fontSize: "18px",
				lineHeight: "32px",
				fontWeight: 400,
			},
		},
		body_medium_small_20: {
			"@media (max-width:904px)": {
				fontFamily: '"RobotoRegular", "Helvetica", "Arial", sans-serif',
				fontSize: "14px",
				lineHeight: "32px",
				fontWeight: 400,
			},
			"@media (min-width:905px)": {
				fontFamily: '"RobotoRegular", "Helvetica", "Arial", sans-serif',
				fontSize: "20px",
				lineHeight: "32px",
				fontWeight: 400,
			},
		},
		body_medium_small_16: {
			"@media (max-width:904px)": {
				fontFamily: '"RobotoRegular", "Helvetica", "Arial", sans-serif',
				fontSize: "14px",
				lineHeight: "32px",
				fontWeight: 400,
			},
			"@media (min-width:905px)": {
				fontFamily: '"RobotoRegular", "Helvetica", "Arial", sans-serif',
				fontSize: "16px",
				lineHeight: "32px",
				fontWeight: 400,
			},
		},
		"Onboarding/MobileAppheader": {
			fontSize: "12px",
			lineHeight: "24px",
		},
		"Onboarding/EmailConfirmation": {
			fontSize: "24px",
			lineHeight: "36px",
			fontWeight: 600,
			fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
		},
		newPowerup_title_large: {
			"@media (max-width:904px)": {
				fontFamily: '"PoppinsSemiBold", "Helvetica", "Arial", sans-serif',
				fontSize: "20px",
				lineHeight: "24px",
				fontWeight: 700,
			},
			"@media (min-width:905px)": {
				fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
				fontSize: "36px",
				lineHeight: "44px",
				fontWeight: 600,
			},
		},
		label_medium_title: {
			"@media (max-width:904px)": {
				fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
				fontSize: "12px",
				lineHeight: "16px",
				fontWeight: 500,
			},
			"@media (min-width:905px)": {
				fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
				fontSize: "14px",
				lineHeight: "20px",
				fontWeight: 500,
			},
		},
		custom_title: {
			fontFamily: '"PoppinsSemiBold", "Helvetica", "Arial", sans-serif',
			fontSize: "68.9911px",
			lineHeight: "103px",
		},
		filter_save_text: {
			fontSize: "18px",
			lineHeight: "32px",
			fontWeight: 600,
			fontFamily: '"PoppinsSemiBold", "Helvetica", "Arial", sans-serif',
		},
		countdown_time_text: {
			fontFamily: '"PoppinsBold", "Helvetica", "Arial", sans-serif',
			fontSize: "15px",
			lineHeight: "22px",
		},
		countdown_progress_text: {
			fontFamily: '"PoppinsRegular", "Helvetica", "Arial", sans-serif',
			fontSize: "20px",
			lineHeight: "30px",
		},
		impression_title: {
			fontFamily: '"PoppinsSemiBold", "Helvetica", "Arial", sans-serif',
			fontSize: "60px",
			lineHeight: "90px",
		},
		impression_subtitle: {
			fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
			fontSize: "20px",
			lineHeight: "30px",
		},
		growth_progress_text: {
			fontFamily: '"PoppinsSemiBold", "Helvetica", "Arial", sans-serif',
			fontSize: "20px",
			lineHeight: "30px",
		},
		growth_card_subtitle: {
			fontFamily: '"PoppinsSemiBold", "Helvetica", "Arial", sans-serif',
			fontSize: "26px",
			lineHeight: "39px",
		},
		growth_mainpercentage_text: {
			fontFamily: '"PoppinsSemiBold", "Helvetica", "Arial", sans-serif',
			fontSize: "80px",
			lineHeight: "120px",
		},
		largest_logo: {
			fontFamily: '"PoppinsRegular", "Helvetica", "Arial", sans-serif',
			fontSize: "250px",
			lineHeight: "130px",
		},
		feed_title: {
			fontFamily: '"PoppinsSemiBold", "Helvetica", "Arial", sans-serif',
			fontSize: "28px",
			lineHeight: "36px",
		},
		feed_subtitle: {
			fontFamily: '"PoppinsSemiBold", "Helvetica", "Arial", sans-serif',
			fontSize: "40px",
			lineHeight: "60px",
		},
		growth_otherpercentage_text: {
			fontFamily: '"PoppinsSemiBold", "Helvetica", "Arial", sans-serif',
			fontSize: "30px",
			lineHeight: "32px",
		},
		countdown_display_text: {
			fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
			fontSize: "45px",
			lineHeight: "66px",
		},
		mentor_subtitle: {
			fontFamily: '"PoppinsSemiBold", "Helvetica", "Arial", sans-serif',
			fontSize: "24px",
			lineHeight: "36px",
		},
		"Display/xs/Semibold": {
			fontFamily: '"PoppinsSemiBold", "Helvetica", "Arial", sans-serif',
			fontSize: "24px",
			lineHeight: "32px",
			color: "black",
		},
		"Display/sm/Semibold": {
			fontFamily: '"PoppinsSemiBold", "Helvetica", "Arial", sans-serif',
			fontSize: "16px",
			lineHeight: "24px",
			color: "black",
		},
		Impressions_score_text1: {
			fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
			fontSize: "40px",
			lineHeight: "60px",
		},
		Impressions_score_text2: {
			fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
			fontSize: "30px",
			lineHeight: "45px",
		},
		BIChartSmallText: {
			fontFamily: '"PoppinsRegular", "Helvetica", "Arial", sans-serif',
			fontSize: "12px",
			lineHeight: "18px",
			color: "black",
		},
		"Title/30/Semibold": {
			fontFamily: '"PoppinsSemiBold", "Helvetica", "Arial", sans-serif',
			fontSize: "1.875rem",
		},
		"Title/20/Semibold": {
			fontFamily: '"PoppinsSemiBold", "Helvetica", "Arial", sans-serif',
			fontSize: "1.275rem",
		},
		"Body/18/Regular": {
			fontFamily: '"PoppinsRegular", "Helvetica", "Arial", sans-serif',
			fontSize: "1.125rem",
		},

		"Text/xl/Medium": {
			fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
			fontSize: "30px",
			lineHeight: "20px",
			fontWeight: 500,
		},

		// / / / / /  --- DS1 Typography Variables --- / / / / / //

		// --- 3xl --- ///
		"Text/3xl/Regular": {
			fontFamily: '"PoppinsRegular", "Helvetica", "Arial", sans-serif',
			fontSize: "4.5rem",
			fontStyle: "normal",
			fontWeight: 400,
			lineHeight: "5.625rem",
			letterSpacing: "-0.09rem",
			color: "black",
		},
		"Text/3xl/Semibold": {
			fontFamily: '"PoppinsSemiBold", "Helvetica", "Arial", sans-serif',
			fontSize: "4.5rem",
			fontStyle: "normal",
			fontWeight: 600,
			lineHeight: "5.625rem",
			letterSpacing: "-0.09rem",
			color: "black",
		},
		// --- END 3xl --- ///

		// --- 2xl --- ///
		"Text/2xl/Regular": {
			fontFamily: '"PoppinsRegular", "Helvetica", "Arial", sans-serif',
			fontSize: "3rem",
			fontStyle: "normal",
			fontWeight: 400,
			lineHeight: "3.75rem",
			letterSpacing: "-0.06rem",
			color: "black",
		},
		"Text/2xl/Semibold": {
			fontFamily: '"PoppinsSemiBold", "Helvetica", "Arial", sans-serif',
			fontSize: "3rem",
			fontStyle: "normal",
			fontWeight: 600,
			lineHeight: "3.75rem",
			letterSpacing: "-0.06rem",
			color: "black",
		},
		// --- END 2xl --- ///

		// --- xl --- ///
		"Text/xl/Regular": {
			fontFamily: '"PoppinsRegular", "Helvetica", "Arial", sans-serif',
			fontSize: "1.875rem",
			fontStyle: "normal",
			fontWeight: 400,
			lineHeight: "2.375rem",
			color: "black",
		},
		"Text/xl/Semibold": {
			fontFamily: '"PoppinsSemiBold", "Helvetica", "Arial", sans-serif',
			fontSize: "1.875rem",
			fontStyle: "normal",
			fontWeight: 600,
			lineHeight: "2.375rem",
			color: "black",
		},
		// --- END xl --- ///

		// --- lg --- ///
		"Text/lg/Regular": {
			fontFamily: '"PoppinsRegular", "Helvetica", "Arial", sans-serif',
			fontSize: "1.625rem",
			fontStyle: "normal",
			fontWeight: 400,
			lineHeight: "2rem",
			color: "black",
		},
		"Text/lg/Semibold": {
			fontFamily: '"PoppinsSemiBold", "Helvetica", "Arial", sans-serif',
			fontSize: "1.625rem",
			fontStyle: "normal",
			fontWeight: 600,
			lineHeight: "2rem",
			color: "black",
		},
		// --- END lg --- ///

		// --- md --- ///
		"Text/md/Regular": {
			fontFamily: '"PoppinsRegular", "Helvetica", "Arial", sans-serif',
			fontSize: "1.25rem",
			fontStyle: "normal",
			fontWeight: 400,
			lineHeight: "1.875rem",
			color: "black",
		},
		"Text/md/Semibold": {
			fontFamily: '"PoppinsSemiBold", "Helvetica", "Arial", sans-serif',
			fontSize: "1.25rem",
			fontStyle: "normal",
			fontWeight: 600,
			lineHeight: "1.875rem",
			color: "black",
		},
		// --- END md --- ///

		// --- sm --- ///
		"Text/sm/Regular": {
			fontFamily: '"PoppinsRegular", "Helvetica", "Arial", sans-serif',
			fontSize: "1rem",
			fontStyle: "normal",
			fontWeight: 400,
			lineHeight: "1.5rem",
			color: "black",
		},
		"Text/sm/Medium": {
			fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
			fontSize: "1rem",
			fontStyle: "normal",
			fontWeight: 500,
			lineHeight: "1.5rem",
			color: "black",
		},
		"Text/sm/Semibold": {
			fontFamily: '"PoppinsSemiBold", "Helvetica", "Arial", sans-serif',
			fontSize: "1rem",
			fontStyle: "normal",
			fontWeight: 600,
			lineHeight: "1.5rem",
			color: "black",
		},
		// --- END sm --- ///

		// --- xs --- ///
		"Text/xs/Regular": {
			fontFamily: '"PoppinsRegular", "Helvetica", "Arial", sans-serif',
			fontSize: "0.875rem",
			fontStyle: "normal",
			fontWeight: 400,
			lineHeight: "1.25rem",
			color: "black",
		},
		"Text/xs/Medium": {
			fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
			fontSize: "0.875rem",
			fontStyle: "normal",
			fontWeight: 500,
			lineHeight: "1.25rem",
			color: "black",
		},
		"Text/xs/Semibold": {
			fontFamily: '"PoppinsSemiBold", "Helvetica", "Arial", sans-serif',
			fontSize: "0.875rem",
			fontStyle: "normal",
			fontWeight: 600,
			lineHeight: "1.25rem",
			color: "black",
		},
		// --- END xs --- ///

		// / / / / /  --- END DS1 Typography Variables --- / / / / / //
	},
});
