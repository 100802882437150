import React from "react";
import { Typography } from "@mui/material";
import { ToolTipWrapper } from "../../RevenueModler/styles";
import { TOOL_TIP_DATA } from "../../../../../../constants/revenue-modeler-types";

export default function ToolTipContainer(props) {
  return (
    <ToolTipWrapper>
      <Typography>{TOOL_TIP_DATA[0].title}</Typography>
      <Typography>{TOOL_TIP_DATA[0].subTitle}</Typography>
      <Typography>{TOOL_TIP_DATA[0].description}</Typography>
    </ToolTipWrapper>
  );
}
