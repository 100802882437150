import { Chart } from "chart.js";
import React from "react";

const useChart = (nodeRef, options) => {
	React.useEffect(() => {
		const chart = Chart.getChart(nodeRef.current.id);
		if (chart) {
			chart.destroy();
		}
		const newChart = new Chart(nodeRef.current, options);
	}, [nodeRef, options]);
	return {};
};
export default useChart;
