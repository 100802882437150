import { Info } from "@mui/icons-material";
import { Box, Typography, Card } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CSSTransition } from "react-transition-group";
import ErrorBoundary from "../../../utils/ErrorBoundary";
import ScoreTrendChart from "../../Charts/ScoreTrendChart";
import FounderTrendGraphTabController from "./FounderTrendGraphTabController";

function FounderBenchmarkTrend({ showTitle = true, SCORE_OBJ_TREND, RANGE_SELECTION, handleChangeSetData }) {
	const [keys, setKeys] = useState([]);
	const [values, setValues] = useState([]);
	const [dateRangeError, setDateRangeError] = useState(null);

	function formatDate(date) {
		let month = date.toLocaleString("default", { month: "short" });
		let day = date.getDate();
		let year = date.getFullYear();
		return `${month} ${day}, ${year}`;
	}

	function isDateWithinRange(dateString, rangeSelector) {
		let today = new Date();
		let date = new Date(dateString);
		let rangeDate = new Date(today.getFullYear(), today.getMonth() - rangeSelector, today.getDate());
		let oneMonthAgo = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());

		// Check if the input date is within the range of the past month
		if (rangeSelector === 1 && date >= oneMonthAgo && date <= today) {
			return true;
		}

		// Check if the input date is within +-1 day of `rangeDate`
		let oneDayInMillis = 24 * 60 * 60 * 1000;
		let difference = Math.abs(date.getTime() - rangeDate.getTime());
		if (difference <= oneDayInMillis) {
			return true;
		}

		return false;
	}

	const handleData = () => {
		try {
			const newKeys = Object.keys(SCORE_OBJ_TREND);
			const newValues = Object.values(SCORE_OBJ_TREND);
			setKeys(newKeys);
			setValues(newValues);

			let startDate = new Date(newKeys[0]);

			if (RANGE_SELECTION === "1M") {
				if (!isDateWithinRange(startDate, 1)) {
					setDateRangeError(`The full range is not available. The first date of score history is ${formatDate(startDate)}.`);
				} else {
					setDateRangeError(null);
				}
			} else if (RANGE_SELECTION === "3M") {
				if (!isDateWithinRange(startDate, 3)) {
					setDateRangeError(`The full range is not available. The first date of score history is ${formatDate(startDate)}.`);
				} else {
					setDateRangeError(null);
				}
			} else if (RANGE_SELECTION === "6M") {
				if (!isDateWithinRange(startDate, 6)) {
					setDateRangeError(`The full range is not available. The first date of score history is ${formatDate(startDate)}.`);
				} else {
					setDateRangeError(null);
				}
			} else if (RANGE_SELECTION === "1Y") {
				if (!isDateWithinRange(startDate, 12)) {
					setDateRangeError(`The full range is not available. The first date of score history is ${formatDate(startDate)}.`);
				} else {
					setDateRangeError(null);
				}
			} else if (RANGE_SELECTION === "2Y") {
				if (!isDateWithinRange(startDate, 24)) {
					setDateRangeError(`The full range is not available. The first date of score history is ${formatDate(startDate)}.`);
				} else {
					setDateRangeError(null);
				}
			}
		} catch (e) {
			console.log("Error when preparing data and axis labels for chart component. Failed with error:", e);
		}
	};

	/* 
		This function reduces the size of the score trend object in cases of it being too large.
		When the object is too large it makes the graph (in its small form) look too cramped.
		To maintain the data integrity while improving visual display we do the following:
		If the object is larger than 20, we will attempt to reduce; otherwise return the original.
		If larger we save the 1st and last value; then reduce the values to length of 20.
		----
		If the reduction method is needed; uncomment.
	*/
	//function reduceScoreTrend(scoreTrend) {
	//	try {
	//		const numValues = Object.keys(scoreTrend).length;
	//		if (numValues > 20) {
	//			const keep = [0, numValues - 1];
	//			const numToRemove = numValues - 20 + keep.length;
	//			const keys = Object.keys(scoreTrend);
	//			const toRemove = new Set();
	//			for (let i = 0; i < numToRemove; i++) {
	//				let index;
	//				do {
	//					index = Math.floor(Math.random() * (numValues - keep.length));
	//				} while (keep.includes(index) || toRemove.has(index));
	//				toRemove.add(index);
	//			}
	//			const newScoreTrend = {};
	//			let j = 0;
	//			for (let i = 0; i < keys.length; i++) {
	//				if (keep.includes(i) || toRemove.has(j++)) {
	//					newScoreTrend[keys[i]] = scoreTrend[keys[i]];
	//				}
	//			}
	//			newScoreTrend[keys[0]] = scoreTrend[keys[0]];
	//			newScoreTrend[keys[numValues - 1]] = scoreTrend[keys[numValues - 1]];
	//			return newScoreTrend;
	//		} else {
	//			return scoreTrend;
	//		}
	//	} catch (e) {
	//		console.log("Error when attempting to reduce the size of the trend component. Failed with error:", e);
	//		return {};
	//	}
	//}

	useEffect(() => {
		if (SCORE_OBJ_TREND) {
			// If want to use reduction method: uncomment
			//handleData(reduceScoreTrend(SCORE_OBJ_TREND));
			handleData();
		}
	}, [SCORE_OBJ_TREND]);

	return (
		<>
			{SCORE_OBJ_TREND && (
				<Box
					sx={{
						borderRadius: "30px",
						p: 4,
						background: "white",
						flex: 1,
					}}
				>
					{showTitle && (
						<Typography variant="poppinsSemiBold20" color="black" component={"p"} sx={{ mb: 1 }}>
							Score Trend
						</Typography>
					)}

					<Box>
						<FounderTrendGraphTabController RANGE_SELECTION={RANGE_SELECTION} handleChangeSetData={handleChangeSetData} />
					</Box>
					<CSSTransition in={dateRangeError} timeout={300} classNames="open">
						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								gap: 1,
								transition: "all 0.3s ease-in-out",
								opacity: 0,
								transform: "translateX(50px) scale(0)",
								height: 0,
								"&.open-exit-done": {
									opacity: 0,
									transform: "translateX(50px) scale(0)",
									height: 0,
								},
								"&.open-exit-active": {
									opacity: 1,
									transform: "translateX(0px) scale(0)",
									height: "auto",
								},
								"&.open-enter-done": {
									opacity: 1,
									transform: "translateX(0px) scale(1)",
									height: "auto",
								},
								"&.open-enter-active": {
									opacity: 1,
									transform: "translateX(0) scale(1)",
									height: "auto",
								},
							}}
						>
							<Info sx={{ color: "#7B61FF" }} />
							<Typography
								variant="customValue"
								sx={{
									color: "black",
									fontFamily: "PoppinsMedium",
									fontSize: "16px",
									lineHeight: "20px",
								}}
							>
								{dateRangeError}
							</Typography>
						</Box>
					</CSSTransition>

					<Box sx={{ display: "flex", flexWrap: "wrap", columnGap: 2, rowGap: 1, alignItems: "center", justifyContent: "center" }}>
						<Box
							sx={{
								mt: 3, // TO match the bell curve... someone baked in the top margin to the component itself...
							}}
						>
							<ErrorBoundary
								messageComp={<Typography sx={{ color: "black" }}>Cannot load graph at this time. Please try again later.</Typography>}
							>
								<ScoreTrendChart label_array={keys} data_array={values} range={RANGE_SELECTION} />
							</ErrorBoundary>
						</Box>
					</Box>
				</Box>
			)}
			{!SCORE_OBJ_TREND && (
				<Card
					sx={{
						borderRadius: "40px",
						p: {
							xs: 2,
							md: 3,
							lg: 4,
						},
						boxShadow: "0px 10px 15px rgba(0, 0, 0, 0.05)",
						display: "flex",
						gap: 2,
					}}
				>
					<Box>
						<Info
							sx={{
								color: (theme) => theme.palette.primary.main,
							}}
						/>
					</Box>
					<Box>
						<Typography color="black" variant="poppinsMedium16" component={"p"}>
							This startup does not have a StartupOS score history at this time. We will continue to check for updates.
						</Typography>
					</Box>
				</Card>
			)}
		</>
	);
}

export default FounderBenchmarkTrend;
