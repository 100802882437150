import React, { useState } from "react";
import { Typography, Box, Grid } from "@mui/material";
import JsxParser from "react-jsx-parser";
import { ContentWrapper } from "./styles";
import PowerupAccordion from "../../../../../components/Workspace/PowerUp/Accordion";
import VideoPlayerComponent from "../../../../../common/videoPlayer";
import theme from "../../../../../constants/Theme";
import Overview from "./Components/Overview";
import Solutions from "./Components/Solutions";

const MvpPrioritizer = () => {
  const [nextStep, isNextStep] = useState(false);
  return (
    <>
      <Grid container>
        <Grid item xs={12} md={6}>
          <ContentWrapper sx={{ p: 3 }}>
            {nextStep ? <Solutions /> : <Overview isNextStep={isNextStep} />}
          </ContentWrapper>
        </Grid>
      </Grid>
    </>
  );
};

export default MvpPrioritizer;
