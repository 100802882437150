import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getSmExpenseVsNewSalesGridData } from "../Functions/common";
import SmExpenseVsNewSalesChart from "../Charts/SmExpenseVsNewSalesChart";
import SmExpenseVsNewSalesDataGrid from "../DataGrid/SmExpenseVsNewSalesGrid";
import { handleMixPanel } from "../../../../utils/mixPanelEventHandling";
import { setBIDashboardData } from "../../../../modules/actions/biDashboardActions";
import BIDataGridDialog from "../DataGrid/BIDataGridDialog";

function SmExpenseVsNewSales({ companyId, readOnly }) {
	const dispatch = useDispatch();
	const smExpense = useSelector((store) => store?.biDashboard?.smExpense);
	const loadingSmExpense = useSelector((store) => store?.biDashboard?.loadingSmExpense);
	const openSmExpense = useSelector((store) => store?.biDashboard?.openSmExpense);
	const [loading, setLoading] = useState(true);
	const [isEdited, setEdited] = useState(false);

	const gridData = smExpense?.data ? smExpense?.data : [];

	const ownCompanyId = useSelector((state) => state?.auth?.companyId);
	const usedCompanyId = companyId ? companyId : ownCompanyId;

	const fetchData = async () => {
		try {
			dispatch(setBIDashboardData({ loadingSmExpense: true }));
			const responseData = await getSmExpenseVsNewSalesGridData(usedCompanyId);
			if (responseData) {
				dispatch(setBIDashboardData({ smExpense: responseData }));
			}
			dispatch(setBIDashboardData({ loadingSmExpense: false }));
		} catch (error) {
			dispatch(setBIDashboardData({ loadingSmExpense: false }));
			console.log("error", error);
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	const onChartClick = () => {
		handleMixPanel("Grid SM Expense Viewed");
		dispatch(setBIDashboardData({ openSmExpense: true }));
	};

	const handleClose = useCallback(() => {
		if (isEdited) {
			dispatch(
				setBIDashboardData({
					openSmExpenseLeave: true,
				})
			);
		} else {
			dispatch(setBIDashboardData({ openSmExpense: false }));
		}
	}, [isEdited]);

	return (
		<>
			<SmExpenseVsNewSalesChart data={smExpense} onChartClick={onChartClick} loading={loadingSmExpense} readOnly={readOnly} />
			<BIDataGridDialog title="S&M Expense vs New Sales ARR" isOpen={openSmExpense} onClose={handleClose}>
				<SmExpenseVsNewSalesDataGrid gridData={gridData} readOnly={readOnly} usedCompanyId={usedCompanyId} isEdited={isEdited} setEdited={setEdited} />
			</BIDataGridDialog>
		</>
	);
}

export default SmExpenseVsNewSales;
