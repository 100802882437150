import * as actionTypes from "../../actions/actionTypes";
import { updateObject } from "../../../utils/updateObject";
import { INVESTOR_DASHBOARD_BG1 } from "../../../constants";

const initialState = {
	myWorkProfile: {},
	currentMeetingObject: {},
	backgroundImage: INVESTOR_DASHBOARD_BG1,
};

const startup = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SET_MY_WORK_PROFILE:
			return updateObject(state, { myWorkProfile: action.payload });
		case actionTypes.SET_CURRENT_MEETING_OBJECT:
			return updateObject(state, { currentMeetingObject: action.payload });
		default:
			return state;
	}
};
export default startup;
