import { Typography, Box, Card, Button, Stack, useMediaQuery, MenuItem, Menu, Grid, Chip } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import LoopIcon from "@mui/icons-material/Loop";
import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import FileDownloadRoundedIcon from "@mui/icons-material/FileDownloadRounded";
import theme from "../../../constants/Theme";
import constants, {
	AUDIO_CASSETTE_ICON,
	BICYCLE_ICON,
	CINEMA_ICON,
	FRISBEE_ICON,
	ICONIC_TOY_ICON,
	INTERNET_ICON,
	IPOD_ICON,
	MUSIC_DEVICE_ICON,
	RECORD_PLAYER_ICON,
	RUBIK_CUBE_ICON,
	SCREEN_CONTENT_ICON,
	SKATES_ICON,
	SMART_SPEAKER_ICON,
	SPINNER_ICON,
	STREAMING_ICON,
	TV_ICON,
	VCR_ICON,
	WALKMAN_ICON,
} from "../../../constants";
import { connect } from "react-redux";
import * as actions from "../../../modules/actions";
import { FormatColorResetTwoTone } from "@mui/icons-material";
import MessageHandlerModal from "../../../common/modal/MessageHandler";
import MuiTooltip from "../../../common/MuiTooltip";
import TemporaryDrawer from "../../../common/Drawer";
import { archiveApiCall, createNewVersionApiCall } from "../../../utils/api";
import { sosPrimary } from "../../../constants/Theme/colors";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import PersonaSummaryCard from "../../../components/Workspace/PowerUp/PersonaSummaryCard";
import { CONTACT_ICONS_MAP } from "../../../constants/powerup-data";
import { PersonaDetailsCard } from "../styles";
import { getBirthYear } from "../../../utils/date";
import { SliderScale } from "../PowerupExcercises/PersonaBuilder/components/SliderScale";
import IconPicker from "../../../common/IconPicker";
import SummaryExport from "../../../common/SummaryExport/index";

function PersonaBuilderSummary({
	moduleId,
	versionId,
	handleBackClick,
	moduleVersionNumber,
	handleDone,
	powerupExcercises,
	powerupStepsData,
	handleTakeAgain,
}) {
	const tabitems = constants.SUMMARY_TAB_ITEMS;
	const history = useHistory();
	const summaryGridRef = useRef(null);
	const [loader, setLoader] = useState(FormatColorResetTwoTone);
	const [currenttab, setcurrenttab] = useState(tabitems[0].key);
	const [exerciseDetails, setExerciseDetails] = useState(powerupExcercises);
	const [initialData, setInitialData] = useState(null);
	const isMediumScreen = useMediaQuery(theme.breakpoints.down("lg"));
	const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("md"));
	const params = useParams();
	const [archiveConfirmDialog, setArchiveConfirmDialog] = useState(false);
	const [archiveDrawerOpen, setArchiveDrawerOpen] = useState(false);
	const [downloadDrawerOpen, setDownloadDrawerOpen] = useState(false);
	const [bio, setBio] = useState("");
	const [editBio, setEditBio] = useState(false);
	const inputRef = useRef(null);

	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const gridContainer = {
		display: isMediumScreen ? "block" : "grid",
		gridTemplateColumns: "repeat(2, 1fr)",
	};

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		});
		setExerciseDetails(powerupExcercises);
		setLoader(true);
		//getExerciseDetailsApiCall();
		setcurrenttab(tabitems[0].key);
	}, []);

	const handleClick = event => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	useEffect(() => {
		if (powerupExcercises?.length) {
			var initialData = {};
			powerupExcercises?.map(exercise => {
				initialData = { ...initialData, ...exercise.answers };
			});
			setInitialData(initialData);
		}
	}, [powerupExcercises]);

	const handleExportClick = type => {
		summaryGridRef.current.handleExport(type);
	};

	const archiveCancelDialogHandler = () => {
		setArchiveConfirmDialog(false);
	};

	const archiveSuccessDialogHandler = () => {
		setArchiveConfirmDialog(false);
		let payload = {
			responseId: versionId,
			status: true,
		};
		archiveApiCall(payload, history);
	};
	const createNewVersion = (moduleId, uuid, history) => {
		createNewVersionApiCall(moduleId, uuid, history);
	};

	const IconLabelComp = ({ labelObj, label }) => {
		return (
			<Box sx={{ textAlign: "center" }}>
				<Box component={"img"} src={labelObj.icon} alt="toy" />
				<Typography
					sx={{
						display: "grid",
						color: theme => theme.palette.secondary.korophKoal,
					}}
					variant="label_medium"
				>
					{labelObj.name}
				</Typography>
			</Box>
		);
	};
	const getGenerationDetails = birthYear => {
		if (birthYear >= 1925 && birthYear <= 1945) {
			return {
				genaratioName: "Builders",
				dateRange: "1925 - 1945",
				iconNames: [
					{ icon: SKATES_ICON, name: "Roller Skates" },
					{ icon: RECORD_PLAYER_ICON, name: "Record Player" },
					{ icon: CINEMA_ICON, name: "Cinema" },
				],
			};
		} else if (birthYear >= 1946 && birthYear <= 1964) {
			return {
				genaratioName: "Baby Boomers",
				dateRange: "1946 - 1964",
				iconNames: [
					{ icon: FRISBEE_ICON, name: "Frisbee" },
					{ icon: AUDIO_CASSETTE_ICON, name: "Audio Cassette" },
					{ icon: TV_ICON, name: "TV" },
				],
			};
		} else if (birthYear >= 1965 && birthYear <= 1979) {
			return {
				genaratioName: "Generation X",
				dateRange: "1965 - 1979",
				iconNames: [
					{ icon: RUBIK_CUBE_ICON, name: "Rubik’s Cube" },
					{ icon: WALKMAN_ICON, name: "Walkman" },
					{ icon: VCR_ICON, name: "VCR" },
				],
			};
		} else if (birthYear >= 1980 && birthYear <= 1994) {
			return {
				genaratioName: "Generation Y",
				dateRange: "1980 - 1994",
				iconNames: [
					{ icon: BICYCLE_ICON, name: "BMX Bike" },
					{ icon: IPOD_ICON, name: "iPod" },
					{ icon: INTERNET_ICON, name: "Internet" },
				],
			};
		} else if (birthYear >= 1995 && birthYear <= 2009) {
			return {
				genaratioName: "Generation Z",
				dateRange: "1995 - 2009",
				iconNames: [
					{ icon: ICONIC_TOY_ICON, name: "Iconic Toy" },
					{ icon: MUSIC_DEVICE_ICON, name: "Music Device" },
					{ icon: SCREEN_CONTENT_ICON, name: "Screen Content" },
				],
			};
		} else if (birthYear >= 2010 && birthYear <= 2024) {
			return {
				genaratioName: "Generation Infinity",
				dateRange: "2010 - 2024",
				iconNames: [
					{ icon: SPINNER_ICON, name: "Fidget Spinner" },
					{ icon: SMART_SPEAKER_ICON, name: "Smart Speakers" },
					{ icon: STREAMING_ICON, name: "Streaming" },
				],
			};
		}
	};
	const ChipListComp = ({ title, chipList }) => {
		return (
			<Stack>
				<Typography sx={{ color: sosPrimary[500] }} variant="headline6">
					{title}
				</Typography>
				<Box sx={{ mt: 2, display: "flex", flexWrap: "wrap" }} gap="8px">
					{chipList?.map((behaviorItem, idx) => {
						return (
							<Chip
								label={<Typography variant="body_medium">{behaviorItem}</Typography>}
								key={idx}
								sx={{
									background: "linear-gradient(0deg, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95)), #0A141B",
									borderRadius: theme.shape.standard_small.borderRadius,
								}}
							/>
						);
					})}
				</Box>
			</Stack>
		);
	};

	const getFromattedIcons = arrayType => {
		const communicationMethodsArray =
			powerupExcercises && powerupExcercises.filter(exerciseItem => exerciseItem.stepNo === 4)[0]?.answers?.communicationMethods;
		let newContactArray = [];
		if (arrayType === "contactIcons") {
			CONTACT_ICONS_MAP.map(item => {
				if (communicationMethodsArray?.includes(item?.url)) {
					item.url = item?.url;
					newContactArray.push(item);
				}
			});

			return newContactArray;
		} else {
			return communicationMethodsArray;
		}
	};

	return (
		<>
			{/*{loader ? (
        <Box justifyContent={"center"} sx={{ display: "flex", mt: 18 }}>
          <CircularProgress />
        </Box>
      ) : (*/}
			<Stack>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
					}}
				>
					<Box flexDirection={"column"}>
						<Box sx={{ mt: 3, px: { md: 3, xs: 1 } }}>
							<Typography variant="largeTitle">Summary</Typography>
						</Box>
						<Box sx={{ mt: 2, px: { md: 3, xs: 1 } }}>
							<Typography variant="bodyLarge" sx={{ color: theme => theme.palette.secondary.outline }}>
								Here’s a summary of the work you completed in this PowerUp. Don’t forget to get feedback on your completed persona!
							</Typography>
						</Box>
						<Box sx={{ mt: 2, px: { md: 3, xs: 1 }, textAlign: "end" }}>
							<Button
								sx={{ width: "fit-content" }}
								startIcon={<FileUploadOutlinedIcon />}
								id="basic-button"
								aria-controls={open ? "basic-menu" : undefined}
								aria-haspopup="true"
								aria-expanded={open ? "true" : undefined}
								onClick={handleClick}
							>
								Export
							</Button>
							<Menu
								id="basic-menu"
								anchorEl={anchorEl}
								open={open}
								onClose={handleClose}
								MenuListProps={{
									"aria-labelledby": "basic-button",
								}}
							>
								<Box>
									<MenuItem onClick={() => handleExportClick("png")}>
										<Typography variant="bodyLarge">PNG</Typography>
									</MenuItem>
									<MenuItem onClick={() => handleExportClick("pdf")}>
										<Typography variant="bodyLarge">PDF</Typography>
									</MenuItem>
								</Box>
							</Menu>
						</Box>
						<Box id="summaryPage" sx={{ px: { md: 3, xs: 1 }, py: 2 }}>
							{" "}
							<Box sx={{ mt: 4 }}>
								<PersonaSummaryCard
									iconUrl={powerupExcercises && powerupExcercises.filter(exerciseItem => exerciseItem.stepNo === 1)[0]?.answers?.avatar}
									personaName={powerupExcercises && powerupExcercises.filter(exerciseItem => exerciseItem.stepNo === 1)[0]?.answers?.personaName}
									description={
										powerupExcercises && powerupExcercises.filter(exerciseItem => exerciseItem.stepNo === 2)[0]?.answers?.summarize
											? `"${powerupExcercises && powerupExcercises.filter(exerciseItem => exerciseItem.stepNo === 2)[0]?.answers?.summarize}"`
											: ""
									}
								/>
							</Box>
							<Box alignItems="center" sx={{ mt: 4, display: isMediumScreen ? "block" : "flex" }}>
								<Box>
									<Typography variant="headline4" sx={{ color: sosPrimary[500] }}>
										{
											getGenerationDetails(
												getBirthYear(powerupExcercises && powerupExcercises.filter(exerciseItem => exerciseItem.stepNo === 2)[0]?.answers?.age)
											)?.genaratioName
										}
									</Typography>
									<Typography
										variant="bodyLarge"
										sx={{
											mt: 1,
											display: "grid",
											color: theme => theme.palette.secondary.outline,
										}}
									>
										{
											getGenerationDetails(
												getBirthYear(powerupExcercises && powerupExcercises.filter(exerciseItem => exerciseItem.stepNo === 2)[0]?.answers?.age)
											)?.dateRange
										}
									</Typography>
								</Box>
								<Box
									sx={{
										ml: "auto",
										display: "flex",
										alignItems: "baseline",
										[theme.breakpoints.down("lg")]: {
											mt: 3,
										},
									}}
									gap={"24px"}
								>
									{getGenerationDetails(
										getBirthYear(powerupExcercises && powerupExcercises.filter(exerciseItem => exerciseItem.stepNo === 2)[0]?.answers?.age)
									)?.iconNames.map((item, idx) => {
										return (
											<Box key={idx}>
												<IconLabelComp labelObj={item} label={item.label} />
											</Box>
										);
									})}
								</Box>
							</Box>
							<Box sx={{ width: 1, mt: 3 }}>
								<Box sx={gridContainer} gap="24px">
									<PersonaDetailsCard>
										<Typography sx={{ color: sosPrimary[500] }} variant="headline6">
											About Me
										</Typography>

										<Grid container sx={{ mt: 3 }}>
											<Grid md={5} lg={5} sm={4} xs={4}>
												<Typography
													variant="label_medium"
													sx={{
														color: theme => theme.palette.secondary.korophKoal,
													}}
												>
													Gender:
												</Typography>
											</Grid>
											<Grid md={7} lg={7} sm={8} xs={8}>
												<Typography
													variant="body_medium"
													sx={{
														color: theme => theme.palette.secondary.korophKoal,
													}}
												>
													{powerupExcercises && powerupExcercises.filter(exerciseItem => exerciseItem.stepNo === 2)[0]?.answers?.gender}
												</Typography>
											</Grid>
											<Grid md={5} lg={5} sm={4} xs={4} sx={{ mt: 2 }}>
												<Typography
													variant="label_medium"
													sx={{
														color: theme => theme.palette.secondary.korophKoal,
													}}
												>
													Marital Status:
												</Typography>
											</Grid>
											<Grid md={7} lg={7} sm={8} xs={8} sx={{ mt: 2 }}>
												<Typography
													variant="body_medium"
													sx={{
														color: theme => theme.palette.secondary.korophKoal,
														wordWrap: "break-word",
													}}
												>
													{powerupExcercises && powerupExcercises.filter(exerciseItem => exerciseItem.stepNo === 2)[0]?.answers?.maritalStatus}
												</Typography>
											</Grid>
											<Grid md={5} lg={5} sm={4} xs={4} sx={{ mt: 2 }}>
												<Typography
													variant="label_medium"
													sx={{
														color: theme => theme.palette.secondary.korophKoal,
													}}
												>
													Age:
												</Typography>
											</Grid>
											<Grid md={7} lg={7} sm={8} xs={8} sx={{ mt: 2 }}>
												<Typography
													variant="body_medium"
													sx={{
														color: theme => theme.palette.secondary.korophKoal,
													}}
												>
													{" "}
													{powerupExcercises && powerupExcercises.filter(exerciseItem => exerciseItem.stepNo === 2)[0]?.answers?.age}
												</Typography>
											</Grid>

											<Grid
												md={5}
												lg={5}
												sm={4}
												xs={4}
												sx={{
													mt: 2,
												}}
											>
												<Typography
													variant="label_medium"
													sx={{
														color: theme => theme.palette.secondary.korophKoal,
													}}
												>
													Education:
												</Typography>
											</Grid>
											<Grid md={7} lg={7} sm={8} xs={8} sx={{ mt: 2 }}>
												<Typography
													variant="body_medium"
													sx={{
														color: theme => theme.palette.secondary.korophKoal,
														wordWrap: "break-word",
													}}
												>
													{powerupExcercises && powerupExcercises.filter(exerciseItem => exerciseItem.stepNo === 2)[0]?.answers?.educationLevel}
												</Typography>
											</Grid>
											<Grid
												md={5}
												lg={5}
												sm={4}
												xs={4}
												sx={{
													mt: 2,
												}}
											>
												<Typography
													variant="label_medium"
													sx={{
														color: theme => theme.palette.secondary.korophKoal,
														[theme.breakpoints.down("md")]: {
															wordWrap: "break-word",
														},
													}}
												>
													State/Province:
												</Typography>
											</Grid>
											<Grid md={7} lg={7} sm={8} xs={8} sx={{ mt: 2 }}>
												<Typography
													variant="body_medium"
													sx={{
														color: theme => theme.palette.secondary.korophKoal,

														wordWrap: "break-word",
													}}
												>
													{powerupExcercises && powerupExcercises.filter(exerciseItem => exerciseItem.stepNo === 2)[0]?.answers?.state}
												</Typography>
											</Grid>
											<Grid
												md={5}
												lg={5}
												sm={4}
												xs={4}
												sx={{
													mt: 2,
												}}
											>
												<Typography
													variant="label_medium"
													sx={{
														color: theme => theme.palette.secondary.korophKoal,
													}}
												>
													City:
												</Typography>
											</Grid>
											<Grid md={7} lg={7} sm={8} xs={8} sx={{ mt: 2 }}>
												<Typography
													variant="body_medium"
													sx={{
														color: theme => theme.palette.secondary.korophKoal,
														wordWrap: "break-word",
													}}
												>
													{powerupExcercises && powerupExcercises.filter(exerciseItem => exerciseItem.stepNo === 2)[0]?.answers?.city}
												</Typography>
											</Grid>
											<Grid
												md={5}
												lg={5}
												sm={4}
												xs={4}
												sx={{
													mt: 2,
												}}
											>
												<Typography
													variant="label_medium"
													sx={{
														color: theme => theme.palette.secondary.korophKoal,
													}}
												>
													Industry:
												</Typography>
											</Grid>
											<Grid md={7} lg={7} sm={8} xs={8} sx={{ mt: 2 }}>
												<Typography
													variant="body_medium"
													sx={{
														color: theme => theme.palette.secondary.korophKoal,
														wordWrap: "break-word",
													}}
												>
													{powerupExcercises && powerupExcercises.filter(exerciseItem => exerciseItem.stepNo === 2)[0]?.answers?.industry}
												</Typography>
											</Grid>
											<Grid md={5} lg={5} sm={4} xs={4} sx={{ mt: 2 }}>
												<Typography
													variant="label_medium"
													sx={{
														color: theme => theme.palette.secondary.korophKoal,
														wordWrap: "break-word",
													}}
												>
													Occupation:
												</Typography>
											</Grid>
											<Grid md={7} lg={7} sm={8} xs={8} sx={{ mt: 2 }}>
												<Typography
													variant="body_medium"
													sx={{
														color: theme => theme.palette.secondary.korophKoal,
													}}
												>
													{powerupExcercises && powerupExcercises.filter(exerciseItem => exerciseItem.stepNo === 2)[0]?.answers?.jobTitle}
												</Typography>
											</Grid>
										</Grid>
									</PersonaDetailsCard>

									<PersonaDetailsCard mt={isMediumScreen ? 4 : 0}>
										<Typography sx={{ color: sosPrimary[500] }} variant="headline6">
											Bio
										</Typography>

										<Typography
											variant="body_medium"
											display={"grid"}
											sx={{
												mt: 3,
												color: theme => theme.palette.secondary.outline,
											}}
										>
											{powerupExcercises && powerupExcercises.filter(exerciseItem => exerciseItem.stepNo === 3)[0]?.answers?.bio}
										</Typography>
									</PersonaDetailsCard>
								</Box>
							</Box>
							<Box sx={{ mt: 4 }}>
								<ChipListComp
									title={"Behavior"}
									chipList={powerupExcercises && powerupExcercises.filter(exerciseItem => exerciseItem.stepNo === 3)[0]?.answers?.behavior}
								/>
							</Box>
							<Box sx={{ mt: 4 }}>
								<ChipListComp
									title={"Goals"}
									chipList={powerupExcercises && powerupExcercises.filter(exerciseItem => exerciseItem.stepNo === 3)[0]?.answers?.goals}
								/>
							</Box>
							<Box sx={{ mt: 4 }}>
								<ChipListComp
									title={"Excitement and Inspirations"}
									chipList={powerupExcercises && powerupExcercises.filter(exerciseItem => exerciseItem.stepNo === 3)[0]?.answers?.inspiration}
								/>
							</Box>
							<Box sx={{ mt: 4 }}>
								<ChipListComp
									title={"Challenges"}
									chipList={powerupExcercises && powerupExcercises.filter(exerciseItem => exerciseItem.stepNo === 3)[0]?.answers?.challenges}
								/>
							</Box>
							<Box sx={{ mt: 4 }}>
								<ChipListComp
									title={"Frustrations"}
									chipList={powerupExcercises && powerupExcercises.filter(exerciseItem => exerciseItem.stepNo === 3)[0]?.answers?.frustration}
								/>
							</Box>
							{powerupExcercises && (
								<Grid container sx={{ mt: 4 }}>
									<Grid item md={6} lg={6} sm={12} xs={12}>
										<Typography sx={{ color: sosPrimary[500] }} variant="headline6">
											Motivations
										</Typography>

										<SliderScale
											value={powerupExcercises && powerupExcercises.filter(exerciseItem => exerciseItem.stepNo === 3)[0]?.answers?.motivations?.price}
											key="price"
											title_left="Price"
											disabled={true}
										/>
										<Box sx={{ mt: 2 }}>
											<SliderScale
												value={powerupExcercises && powerupExcercises.filter(exerciseItem => exerciseItem.stepNo === 3)[0]?.answers?.motivations?.comfort}
												key="comfort"
												title_left="Comfort"
												disabled={true}
											/>
										</Box>

										<Box sx={{ mt: 2 }}>
											{" "}
											<SliderScale
												value={powerupExcercises && powerupExcercises.filter(exerciseItem => exerciseItem.stepNo === 3)[0]?.answers?.motivations?.convenience}
												key="comfort"
												title_left="Convenience"
												disabled={true}
											/>
										</Box>

										<Box sx={{ mt: 2 }}>
											{" "}
											<SliderScale
												value={powerupExcercises && powerupExcercises.filter(exerciseItem => exerciseItem.stepNo === 3)[0]?.answers?.motivations?.speed}
												key="comfort"
												title_left="Speed"
												disabled={true}
											/>
										</Box>

										<Box sx={{ mt: 2 }}>
											{" "}
											<SliderScale
												value={powerupExcercises && powerupExcercises.filter(exerciseItem => exerciseItem.stepNo === 3)[0]?.answers?.motivations?.loyalty}
												key="comfort"
												title_left="Loyalty"
												disabled={true}
											/>
										</Box>
									</Grid>
									<Grid item md={6} lg={6} sm={12} xs={12} sx={{ mt: { xs: 3, md: 0 }, pl: { md: 3, xs: 0 } }}>
										<Typography sx={{ color: sosPrimary[500] }} variant="headline6">
											Traits
										</Typography>
										<SliderScale
											value={powerupExcercises && powerupExcercises.filter(exerciseItem => exerciseItem.stepNo === 3)[0]?.answers?.traits?.introvert}
											key="comfort"
											title_left="Introvert"
											title_right={"Extrovert"}
											disabled={true}
										/>
										<Box sx={{ mt: 2 }}>
											<SliderScale
												value={powerupExcercises && powerupExcercises.filter(exerciseItem => exerciseItem.stepNo === 3)[0]?.answers?.traits?.analytical}
												key="comfort"
												title_left="Analytical"
												title_right={"Creative"}
												disabled={true}
											/>
										</Box>

										<Box sx={{ mt: 2 }}>
											{" "}
											<SliderScale
												value={powerupExcercises && powerupExcercises.filter(exerciseItem => exerciseItem.stepNo === 3)[0]?.answers?.traits?.loyal}
												key="comfort"
												title_left="Loyal"
												title_right={"Fickle"}
												disabled={true}
											/>
										</Box>

										<Box sx={{ mt: 2 }}>
											{" "}
											<SliderScale
												value={powerupExcercises && powerupExcercises.filter(exerciseItem => exerciseItem.stepNo === 3)[0]?.answers?.traits?.passive}
												key="comfort"
												title_left="Passive"
												title_right={"Active"}
												disabled={true}
											/>
										</Box>
									</Grid>
									<Grid item md={6} lg={6} sm={12} xs={12} sx={{ mt: 4 }}>
										<Box>
											<Typography sx={{ color: sosPrimary[500] }} variant="headline6">
												Tech Saviness
											</Typography>

											<SliderScale
												value={powerupExcercises && powerupExcercises.filter(exerciseItem => exerciseItem.stepNo === 3)[0]?.answers?.techSaviness?.newbie}
												key="comfort"
												title_left="Newbie"
												title_right={"Guru"}
												disabled={true}
											/>
										</Box>
									</Grid>
									<Grid item md={12} lg={12} sm={12} xs={12} sx={{ mt: 4 }}>
										<ChipListComp
											title={"Brands Interest"}
											chipList={powerupExcercises && powerupExcercises.filter(exerciseItem => exerciseItem.stepNo === 3)[0]?.answers?.brands}
										/>
									</Grid>

									<Grid item md={12} lg={12} sx={{ mt: 4 }}>
										<ChipListComp
											title={"Job Responsibilities "}
											chipList={powerupExcercises && powerupExcercises.filter(exerciseItem => exerciseItem.stepNo === 4)[0]?.answers?.jobResponsibilities}
										/>
									</Grid>
									<Grid item md={12} lg={12} sx={{ mt: 4 }}>
										<ChipListComp
											title={"Tools Needed For JTBD  "}
											chipList={powerupExcercises && powerupExcercises.filter(exerciseItem => exerciseItem.stepNo === 4)[0]?.answers?.tools}
										/>
									</Grid>
									<Grid item md={12} lg={12} sm={12} xs={12} sx={{ mt: 4 }}>
										<ChipListComp
											title={"What They Do To Add Skills To Their Job"}
											chipList={powerupExcercises && powerupExcercises.filter(exerciseItem => exerciseItem.stepNo === 4)[0]?.answers?.jobActivity}
										/>
									</Grid>

									<Box sx={{ mt: 4 }}>
										<Typography sx={{ color: sosPrimary[500] }} variant="headline6">
											Preferred Communications
										</Typography>
										<Box>
											{" "}
											<IconPicker
												icons={getFromattedIcons("contactIcons")}
												selectedIcon={getFromattedIcons("selectedIcon")}
												setSelectedIcon={() => {}}
												iconHeight={"18px"}
												iconWidth={"18px"}
												mt={2}
												multiSelect
												isCustomIcon
											></IconPicker>
										</Box>
									</Box>
								</Grid>
							)}
						</Box>

						{/*Main Container*/}
						{/*<Box
                sx={theme => ({
                  borderRadius: theme.shape.standard.borderRadius,
                  p: 4,
                  mt: 3,
                  background: sosPrimary[25],

                  [theme.breakpoints.down("md")]: {
                    py: 2,
                    borderRadius: "0px",
                    px: 0,
                  },
                })}
              >
                {" "}
                <Box
                  sx={{
                    display: "flex",
                    mx: 1,
                    [theme.breakpoints.down("md")]: {
                      justifyContent: "flex-end",
                    },
                  }}
                >
                  {!isSmallScreen && (
                    <Box sx={{ ml: "auto" }}>
                      <LoadingButton
                        variant="contained"
                        onClick={() => setTriggerSaveAction(true)}
                        loading={saveButtonLoading}
                        loadingStyles={{ px: 7 }}
                      >
                        Save Changes
                      </LoadingButton>
                    </Box>
                  )}
                  <Box
                    sx={{
                      ml: 1,
                      display: {
                        sm: "none",
                        xs: "none",
                        md: "block",
                        lg: "block",
                        xl: "block",
                      },
                    }}
                  >
                    <DownloadMenu
                      handleExportClick={type => handleExport(type)}
                    />
                  </Box>
                  <Box
                    sx={{
                      textAlign: "end",
                      display: {
                        sm: "block",
                        xs: "block",
                        md: "none",
                        lg: "none",
                        xl: "none",
                      },
                    }}
                  >
                    <FileDownloadRoundedIcon
                      onClick={() => {
                        setDownloadDrawerOpen(true);
                      }}
                      sx={{ color: "secondary.main" }}
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    mt: 3,
                    width: 1,
                    display: { sm: "none", xs: "none", md: "block" },
                  }}
                >
                  {initialData && (
                    <>
                      <GridStackComp
                        powerUpExcercises={exerciseDetails}
                        excerciseSteps={excerciseSteps}
                        initialData={initialData}
                        triggerSaveAction={triggerSaveAction}
                        setTriggerSaveAction={setTriggerSaveAction}
                        moduleId={moduleId}
                        versionId={versionId}
                        getExerciseDetailsApiCall={getExerciseDetailsApiCall}
                        setSaveButtonLoading={setSaveButtonLoading}
                      />
                    </>
                  )}
                </Box>
                <Box>
                  {initialData && (
                    <SummaryPdf
                      ref={summaryGridRef}
                      powerUpExcercises={exerciseDetails}
                      excerciseSteps={excerciseSteps}
                      initialData={initialData}
                      autoSave={params.type === "summary"}
                      triggerSaveAction={triggerSaveAction}
                      setTriggerSaveAction={setTriggerSaveAction}
                      moduleId={moduleId}
                      versionId={versionId}
                    />
                  )}
                </Box>
              </Box>*/}

						{/*<Divider sx={{ mt: 3, borderColor: "secondary.light" }} />*/}
						{/* This section needs to revisited once feedback functionality implemented. */}
						<Box
							sx={theme => ({
								display: { sm: "none", xs: "none", md: "none" },
								background: theme.palette.secondary.white,
								borderRadius: theme.shape.standard.borderRadius,
								p: 3,
								mt: 3,
							})}
						>
							<Typography variant="title_medium">Want feedback?</Typography>
							<Box sx={{ mt: 3 }}>
								<Typography variant="subhead2">Your StartupOS Advisor is here to help if you would like feedback for this completed PowerUp.</Typography>
							</Box>
							<Box sx={{ textAlign: "center", mt: 4 }}>
								<Button>Submit for feedback</Button>
							</Box>
						</Box>
						{!isSmallScreen && (
							<Box
								sx={{
									mt: 3,
									ml: 2,
									display: "flex",
									justifyContent: "space-between",
								}}
							>
								<Box>
									<MuiTooltip title="Archive">
										<ArchiveOutlinedIcon
											sx={{
												cursor: "pointer",
												color: "secondary.main",
												p: 1,
												"&:hover": {
													background: "rgba(73, 69, 79, 0.08)",
													borderRadius: "50%",
												},
											}}
											onClick={() => {
												setArchiveConfirmDialog(true);
											}}
										/>
									</MuiTooltip>
								</Box>

								{/*<MuiTooltip title="Take again">
                  <LoopIcon
                    sx={{
                      cursor: "pointer",
                      ml: 1,
                      color: "secondary.main",
                      p: 1,
                      "&:hover": {
                        background: "rgba(73, 69, 79, 0.08)",
                        borderRadius: "50%",
                      },
                    }}
                    onClick={() => {
                      handleTakeAgain();
                    }}
                  />
                </MuiTooltip>*/}
								<Box sx={{ display: "flex", ml: "auto" }}>
									<Box sx={{ ml: "auto" }}>
										<Button
											variant="outlined"
											onClick={() => {
												handleBackClick();
											}}
										>
											Back
										</Button>
									</Box>
									<Box sx={{ ml: 2 }}>
										<Button
											variant="outlined"
											onClick={() => {
												handleTakeAgain();
											}}
										>
											Create Next Persona
										</Button>
									</Box>
									<Box sx={{ ml: 2 }}>
										<Button
											onClick={() => {
												handleDone();
											}}
										>
											Done
										</Button>
									</Box>
								</Box>
							</Box>
						)}
						{isSmallScreen && (
							<>
								<Box
									sx={{
										mt: 3,
										ml: 2,
										display: "flex",
										justifyContent: "space-between",
									}}
								>
									<>
										<MuiTooltip title="Archive">
											<ArchiveOutlinedIcon
												sx={{
													cursor: "pointer",
													color: "secondary.main",
													p: 1,
													"&:hover": {
														background: "rgba(73, 69, 79, 0.08)",
														borderRadius: "50%",
													},
												}}
												onClick={() => {
													setArchiveConfirmDialog(true);
												}}
											/>
										</MuiTooltip>
										<Box sx={{ ml: 2 }}>
											<Button
												variant="outlined"
												onClick={() => {
													handleTakeAgain();
												}}
											>
												Create Next Persona
											</Button>
										</Box>
									</>
								</Box>
								<Box
									sx={{
										mt: 3,
										ml: 2,
										display: "flex",
									}}
									gap={2}
								>
									<Box sx={{ width: "-webkit-fill-available" }}>
										<Button
											variant="outlined"
											onClick={() => {
												handleBackClick();
											}}
										>
											Back
										</Button>
									</Box>

									<Box sx={{ width: "-webkit-fill-available" }}>
										<Button
											onClick={() => {
												handleDone();
											}}
										>
											Done
										</Button>
									</Box>
								</Box>
							</>
						)}
					</Box>
				</Box>
				{/*<Box
          sx={{
            background: theme.palette.secondary.white,
            position: "fixed",
            bottom: 0,
            width: 1,
            textAlign: "center",
            alignItems: "center",
            py: 2,
            left: 0,
            display: { sm: "flex", xs: "flex", md: "none" },
          }}
        >
          <MoreHorizIcon
            sx={{
              borderRadius: "50%",
              border: `1px solid ${theme.palette.secondary.dark}`,
              mr: 1,
              ml: 2,
              p: 1,
            }}
            onClick={() => {
              setArchiveDrawerOpen(true);
            }}
          />
          <Button
            variant="outlined"
            onClick={() => {
              handleBackClick();
            }}
          >
            Back
          </Button>
          <Button
            sx={{ ml: 1, mr: 2 }}
            onClick={() => {
              history.push("/workspace");
            }}
          >
            Done
          </Button>
        </Box>*/}

				<TemporaryDrawer
					open={downloadDrawerOpen}
					onClose={() => {
						setDownloadDrawerOpen(false);
					}}
				>
					<Box sx={{ py: 3 }}>
						<MenuItem onClick={() => handleExportClick("png")}>
							<Typography variant="bodyLarge">PNG</Typography>
						</MenuItem>
						<MenuItem onClick={() => handleExportClick("pdf")}>
							<Typography variant="bodyLarge">PDF</Typography>
						</MenuItem>
					</Box>
				</TemporaryDrawer>

				{/*<TemporaryDrawer
          open={archiveDrawerOpen}
          onClose={() => {
            setArchiveDrawerOpen(false);
          }}
        >
          <Box sx={{ py: 3 }}>
            <MenuItem
              onClick={() => {
                setArchiveDrawerOpen(false);
                setArchiveConfirmDialog(true);
              }}
            >
              <ArchiveOutlinedIcon
                sx={{
                  color: "secondary.main",
                  p: 1,
                }}
              />
              <Typography variant="label_xlarge" sx={{ ml: 2 }}>
                Archive
              </Typography>
            </MenuItem>
            <MenuItem
              onClick={() => {
                createNewVersion(moduleId, "md-persona", history);
              }}
            >
              <LoopIcon
                sx={{
                  color: "secondary.main",
                  p: 1,
                }}
              />
              <Typography variant="label_xlarge" sx={{ ml: 2 }}>
                Take again
              </Typography>
            </MenuItem>
          </Box>
        </TemporaryDrawer>*/}
				<Box>
					{/*{initialData && (
            <SummaryPdfPersona
              ref={summaryGridRef}
              powerUpExcercises={powerupExcercises}
              excerciseSteps={powerupStepsData}
              initialData={initialData}
              autoSave={params.type === "summary"}
              triggerSaveAction={triggerSaveAction}
              setTriggerSaveAction={setTriggerSaveAction}
              moduleId={moduleId}
              versionId={versionId}
              moduleVersionNumber={moduleVersionNumber}
            />
          )}*/}
				</Box>
				<MessageHandlerModal
					isOpen={archiveConfirmDialog}
					heading="Archive"
					text={"Are you sure you want to archive this?"}
					messageType={"primary"}
					cancelText={"No thanks"}
					okText={"Archive"}
					style={{
						maxWidth: "450px",
					}}
					onOk={archiveSuccessDialogHandler}
					onCancel={archiveCancelDialogHandler}
				/>
			</Stack>
			<Box>
				<SummaryExport
					ref={summaryGridRef}
					name={powerupExcercises && powerupExcercises.filter(exerciseItem => exerciseItem.stepNo === 1)[0]?.answers?.personaName}
				></SummaryExport>
			</Box>
			{/*)}*/}
		</>
	);
}

const mapStoreToProps = ({ powerUp }) => ({
	powerUpExcercises: powerUp?.powerUpExcercises,
});

const mapDispatchToProps = dispatch => ({
	setPowerUpExcercises: updatedSteps => dispatch(actions.setPowerUpExcercises(updatedSteps)),
});

export default connect(mapStoreToProps, mapDispatchToProps)(PersonaBuilderSummary);
