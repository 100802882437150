import React, { useState, useEffect, useRef, Fragment } from "react";
import { Typography, Grid, Box, Divider, Button, CircularProgress, Badge, useMediaQuery, Link, IconButton } from "@mui/material";
import { Add } from "@mui/icons-material";
import PropTypes from "prop-types";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useRouteMatch, useParams } from "react-router-dom";
import { USER_TYPES } from "../../../utils/userTypes";
import * as actions from "../../../modules/actions";
import TemporaryDrawer from "../../../common/Drawer";
import HamburgerPanel from "../HamburgerPanel";
import MessageHandlerModal from "../../../common/modal/MessageHandler";
import SearchIcon from "@mui/icons-material/Search";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import PanelListView from "../Sidebar/RightSidebar/components/Carousel/PanelListView";
import CreateQuickNote from "../Sidebar/RightSidebar/components/CreateQuickNote";
import CreateResearchCurator from "../Sidebar/RightSidebar/components/CreateResearchCurator";
import CommentFeed from "../../../containers/CommentFeed";
import services from "../../../services";
import { toastContainer } from "../../../utils/toast";
import ResearchCuratorCard from "../../ResearchCuratorCard";
import ROUTES_PATH from "../../../constants/routes";
import {
	CALENDER_WHITE_ICON,
	EVENTS_ICON_NEW,
	MARKETPLACE_ICON_NEW,
	MEETING_ICON_NEW,
	MEETING_WHITE_ICON,
	MESSAGE_ICON_NEW,
	NETWORK_ICON_NEW,
	NETWORK_WHITE_ICON,
	NOTES_ICON_NEW,
	POWERUP_IDS,
	MESSAGES_WHITE_ICON,
	WHITE_NOTE_ICON,
	WHITE_STORE_ICON,
	HEART_HAND_WHITE,
	HEART_HAND_GREY,
} from "../../../constants";
import NotificationPanelButton from "../../NotificationCenter/NotificationPanelButton";
import UpdatedWorkspaceUser from "../UpdatedWorkspaceUser";
import NavButton from "./NavButton";
import MessageDialog from "../../Networking/MessageDialog";
import { setNetworkingDashboardData } from "../../../modules/actions/networkingActions";
import MeetingDialog from "../../Meetings/MeetingDialog";
import SlidingProfile from "../../../containers/SlidingProfile";
import { setSlidingProfileData } from "../../../modules/actions/slidingProfileActions";
import { handleMixPanel } from "../../../utils/mixPanelEventHandling";
import PaymentPlanDialog from "../../PaymentPlanDialog";
import InvestorApplyToPitch from "../../InvestorApplyToPitch";
import PromotionalEvents from "../../Events/PromotionalEvents";
import axios from "axios";

function UpdatedHeader({
	onLogout,
	userData,
	companyName,
	userType,
	userName,
	setAuthData,
	showAccountSettingPopup,
	hasUpdates,

	scrolled = false,
	ratingDetails,
	handleDrawerOpen,
	open,
}) {
	const history = useHistory();
	const location = useLocation();

	const [active, setActive] = useState("");
	const [openMenu, setOpenMenu] = useState(false);
	const [confirmPopup, setConfirmPopup] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const [openResearchCuratorPanel, setOpenResearchCuratorPanel] = useState(false);
	const [openNewResearchCurator, setOpenNewResearchCurator] = useState(false);
	const [powerupId, setPowerupID] = useState();
	const startupData = useSelector((store) => store?.auth?.companyData);
	const userDataObj = useState(userData);
	const drawerWidth = 400;
	const [loading, setLoading] = useState(true);
	const [cardData, setCardData] = useState("");
	const [currentCardData, setCurrentCardData] = useState({});
	const [page, setPage] = useState(1);
	const [dataLength, setDataLength] = useState(0);
	const [sort, setSort] = useState("DESC"); //ASC,DESC
	const [lastElement, setLastElement] = useState(null);
	const [isLast, setIsLast] = useState(false);
	const [marketplaceLoading, setmarketplaceLoading] = useState(false);
	const currentLocation = window.location;

	const [openQuickNote, setOpenQuickNote] = useState(false);
	const marketTestData = useSelector((data) => data?.powerUp?.marketTest);
	const dispatch = useDispatch();

	const cursorPointer = { cursor: "pointer" };
	const userDetails = useSelector((state) => state?.auth);

	const [deleteConfirmPopup, setDeleteConfirmPopup] = useState(false);
	const [showBanner, setShowBanner] = useState(true);
	const [showPlanModal, setShowPlanModal] = useState(false);

	const [researchCuratorDetails, setResearchCuratorDetails] = useState();
	const [newCuratorId, setNewCuratorId] = useState("");
	const [callback, setCallback] = useState(false);
	const { url, path } = useRouteMatch();
	const [currentPath, setCurrentPath] = useState("");
	const params = useParams();
	const updateHeaderRef = useRef();
	const openMessageDialog = useSelector((state) => state.networking?.openMessageDialog);
	const openMeetingDialog = useSelector((state) => state.networking?.openMeetingDialog);
	const slidingProfileDialog = useSelector((state) => state?.slidingProfile?.open);
	const isPremiumPlanAccess = useSelector((store) => store?.auth?.isPremiumPlanAccess);
	const isAdmin = useSelector((store) => store?.auth?.isAdmin);
	const [bannerDetails, setBannerDetails] = useState(null);
	const { isInvestorLocked } = useSelector((state) => state?.auth);

	const observer = useRef(
		new IntersectionObserver((entries) => {
			const first = entries[0];
			if (first.isIntersecting) {
				callNextPage();
			}
		})
	);
	useEffect(() => {
		if (currentLocation.pathname.split("/")[2] === "powerUp") {
			setPowerupID(params.id);
		}
	});

	useEffect(() => {
		getAdvertBannerApiCall();
	}, []);

	const getAdvertBannerApiCall = async () => {
		try {
			const response = await services.getAdvertBannerApi();
			if (response) {
				const responseData = response?.data?.data?.[0];
				setBannerDetails(responseData);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const [approvalStatus, setApprovalStatus] = useState();

	useEffect(() => {
		if (!approvalStatus) {
			services.getApprovalStatus().then((status) => {
				setApprovalStatus(status);
			});
		}
	}, [approvalStatus]);

	const handleHistory = () => {
		//history.goBack();

		if (currentLocation.pathname.split("/")[2] === "powerup") {
			if (currentLocation.pathname.split("/").length < 5) {
				history.goBack();
			} else {
				if (currentLocation.pathname.split("/")[3] === "md-ideavalidation" || currentLocation.pathname.split("/")[4] === "browse-candidates") {
					history.push(`${ROUTES_PATH.MARKET_TEST}/${marketTestData?.id}`);
				} else {
					history.push(`/workspace/powerUp/${POWERUP_IDS.filter((item) => item.value === currentLocation.pathname.split("/")[3])[0].id}`);
				}
			}
		} else {
			if (currentLocation.pathname.split("/")[2] === "market-test") {
				if (params?.id) {
					history.push(`${ROUTES_PATH.MARKET_TEST}`);
				} else {
					history.push(`${ROUTES_PATH.STARTUPS_HOME}`);
				}
			} else {
				history.goBack();
			}
		}
	};

	useEffect(() => {
		const currentElement = lastElement;
		const currentObserver = observer.current;

		if (currentElement) {
			currentObserver.observe(currentElement);
		}

		return () => {
			if (currentElement) {
				currentObserver.unobserve(currentElement);
			}
		};
	}, [lastElement]);

	useEffect(() => {
		if (openResearchCuratorPanel) {
			getCardDetails();
		}
	}, [openResearchCuratorPanel]);

	const getCardDetails = () => {
		services
			.getNotesListPanel(page, sort, null, 10)
			.then((res) => {
				if (res.data.code === 200) {
					setDataLength(res.data.data.totalElements);
					if (page === 1) {
						setCardData(res.data.data.models);
					} else {
						setCardData([...cardData, ...res.data.data.models]);
					}
					setIsLast(res.data.data.isLast);
					setLoading(false);
					setCallback(false);
				}
			})
			.catch((err) => {
				setLoading(false);
				toastContainer(err?.error_description, "error");
			});
	};

	const callNextPage = () => {
		setPage((page) => page + 1);
	};

	useEffect(() => {
		if (page !== 1) {
			getCardDetails();
		}
	}, [page]);

	const handleCardClick = (noteItem) => {
		setCurrentCardData(noteItem);
		if (noteItem.noteType === "NOTE") {
			setOpenNewResearchCurator(true);
		} else {
			setOpenQuickNote(true);
		}
	};

	const handleClosePlanModal = () => {
		setShowPlanModal(false);
	};

	const handleDeleteNote = () => {
		services
			.deleteNote(currentCardData?.id)
			.then((response) => {
				if (response.data.code === 200) {
					handleDeletePopup();
					setOpenNewResearchCurator(false);
					setOpenQuickNote(false);
					setPage(1);
					getCardDetails();
				}
			})
			.catch((e) => {
				console.log("Submit", e);
				toastContainer("Error in deleting Note", "error");
			});
	};

	const handleDeletePopup = () => {
		setDeleteConfirmPopup(!deleteConfirmPopup);
	};

	useEffect(() => {
		if (callback) {
			setPage(1);
			getCardDetails();
		}
	}, [callback]);

	const ResearchCuratorPanel = () => {
		return (
			<Box
				sx={{
					maxWidth: (theme) => theme.breakpoints.values.xl,
					margin: "auto",
					width: "100%",
				}}
			>
				<Box>
					<Box sx={{ display: "flex", p: 3, justifyContent: "space-between" }} gap={6}>
						<Typography variant="boldHeading">Discovery Board</Typography>
						<Box sx={{ display: "flex", ml: "auto" }} gap={2}>
							<SearchIcon
								sx={cursorPointer}
								onClick={() => {
									history.push(ROUTES_PATH.RESEARCH_CURATOR_EXPAND);
									setOpenResearchCuratorPanel(false);
									dispatch(setAuthData({ currentSelectedNavItem: "discovery-board" }));
								}}
							/>
							<OpenInNewIcon
								sx={cursorPointer}
								onClick={() => {
									history.push(ROUTES_PATH.RESEARCH_CURATOR_EXPAND);
									setOpenResearchCuratorPanel(false);
									dispatch(setAuthData({ currentSelectedNavItem: "discovery-board" }));
								}}
							/>
							<CloseIcon
								sx={cursorPointer}
								onClick={() => {
									setOpenResearchCuratorPanel(false);
								}}
							/>
						</Box>
					</Box>
					<Divider sx={{ background: (theme) => theme.palette.secondary.dark }} />

					<Box sx={{ pr: 3 }}>
						<Grid
							container
							//xs={12}
							sx={{
								pt: 2,
								ml: 2,
							}}
						>
							<Grid
								container
								// xs={6}
							>
								<Grid item xs={6}>
									{" "}
									<Button
										startIcon={<Add />}
										variant="outlined"
										sx={{ mr: 1 }}
										onClick={() => {
											setOpenQuickNote(true);
											setCurrentCardData({});
										}}
									>
										New&nbsp;Quick&nbsp;Note
									</Button>
								</Grid>
								<Grid item xs={6} sx={{ pl: 1 }}>
									<Button
										startIcon={<AddIcon />}
										onClick={() => {
											setOpenNewResearchCurator(true);
											setCurrentCardData({});
										}}
									>
										New PowerDot
									</Button>
								</Grid>
							</Grid>
						</Grid>
						<Box
							sx={{
								maxWidth: (theme) => theme.breakpoints.values.xl,
								margin: "auto",
								width: "100%",
							}}
						>
							{loading ? (
								<Box
									sx={{
										display: "flex",
										width: 1,
										justifyContent: "center",
										alignContent: "center",
										pt: 4,
									}}
								>
									<CircularProgress />
								</Box>
							) : (
								<>
									<Box sx={{ my: 1, pl: 3 }}>
										<Grid container>
											<PanelListView
												panelCardData={cardData}
												handleCardClick={handleCardClick}
												dataLength={dataLength}
												setCallback={setCallback}
												callback={callback}
											/>
										</Grid>
									</Box>
									{cardData?.length ? (
										<Box sx={{ my: 1, pl: 3 }}>
											<Box sx={{ pb: 2 }}>
												<Typography variant="title_medium" sx={{ pr: 1 }}>
													Other
												</Typography>{" "}
												<Typography variant="caption" sx={{ color: "secondary.dark" }}>
													{dataLength} Total
												</Typography>
											</Box>
											{cardData &&
												cardData?.map((item, i) => {
													return (
														<ResearchCuratorCard
															cardData={item}
															handleCardClick={() => handleCardClick(item)}
															setCallback={setCallback}
														/>
													);
												})}
										</Box>
									) : (
										<Box
											sx={{
												display: "flex",
												width: 1,
												justifyContent: "center",
												alignContent: "center",
												py: 5,
											}}
										>
											<Typography color="primary" variant="h5" sx={(theme) => ({ color: theme.palette.secondary.dark })}>
												No data found.
											</Typography>
										</Box>
									)}
									{!isLast && (
										<Box
											sx={{
												display: "flex",
												width: 1,
												justifyContent: "center",
												py: 4,
											}}
											ref={setLastElement}
										>
											<CircularProgress />
										</Box>
									)}
								</>
							)}
						</Box>
					</Box>
				</Box>
			</Box>
		);
	};

	useEffect(() => {
		if (currentCardData?.id) {
			setNewCuratorId(currentCardData?.id);
		}
	}, [currentCardData?.id]);

	useEffect(() => {
		if (userType === USER_TYPES.STARTUP_USER || userType === USER_TYPES.STARTUP_OWNER || userType === USER_TYPES.STUDENT_USER) {
			return history.listen((location) => {
				setActive(location.pathname.split("/")[1]);
			});
		} else {
			return history.listen((location) => {
				setActive(location.pathname.split("/")[2] ? location.pathname.split("/")[2] : location.pathname.split("/")[1]);
			});
		}
	}, [history]);

	useEffect(() => {
		if (userType === USER_TYPES.STARTUP_USER || userType === USER_TYPES.STARTUP_OWNER || userType === USER_TYPES.STUDENT_USER) {
			setActive(location.pathname.split("/")[1]);
		} else {
			setActive(location.pathname.split("/")[2]);
		}
	}, []);

	const closeAccountSettingsPopup = () => {
		setAuthData({ showAccountSettingPopup: false });
	};

	const handleOpen = () => {
		setAuthData({ showAccountSettingPopup: true });
	};

	const handleOpenMenu = () => {
		setOpenMenu(!openMenu);
	};
	const handleLogout = () => {
		setConfirmPopup(true);
		handleClose();
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const popUpClose = () => {
		setConfirmPopup(false);
		handleClose();
	};

	useEffect(() => {
		//get string between /abcd/ and convert to camel case
		let data;
		if (userType === USER_TYPES.STARTUP_OWNER) {
			data = location.pathname.split("/")[1];
			if (location.pathname.split("/")[1] === "events") {
				data = location.pathname.split("/")[2];
			}
		} else {
			data = location.pathname.split("/")[2] ? location.pathname.split("/")[2] : location.pathname.split("/")[1];
		}

		let currentPath = (" " + data).toLowerCase().replace(/[^a-zA-Z]+(.)/g, function (match, chr) {
			return chr.toUpperCase();
		});

		if (currentPath === "DiscoveryBoardExpand") {
			setCurrentPath("Discovery");
		} else if (currentPath === "Mystartup") {
			setCurrentPath("My Startup");
		} else if (currentPath === "EventSettings") {
			setCurrentPath("Event Settings");
		} else if (currentPath === "EventType") {
			setCurrentPath("Event Type");
		} else {
			setCurrentPath(currentPath);
		}
	});

	const handleListItemClick = () => {
		// history.push(ROUTES_PATH.MEETINGS_BASEPATH);
		trackTopNav("Meetings Page Viewed");
		dispatch(setNetworkingDashboardData({ openMeetingDialog: true }));
		setAuthData({ currentSelectedNavItem: "meetings" });
	};
	const checkApprovedCondition = () => {
		if (userType === USER_TYPES.INVESTOR_USER) {
			if (approvalStatus === "APPROVED") {
				return true;
			} else {
				return false;
			}
		} else {
			return true;
		}
	};

	const isDesktop = useMediaQuery((theme) => theme.breakpoints.up(theme.breakpoints.values.mobile));

	useEffect(() => {
		if (currentPath) {
			updateHeaderRef.current?.scrollIntoView(true, { behavior: "smooth" }); //load page at start
		}
	}, [currentPath]);

	const getProvenLogin = async () => {
		setmarketplaceLoading(true);
		const resp = await axios
			.get(`${process.env.REACT_APP_API_URL}/api/m1/v1/users/getproven-login`)
			.then((resp) => {
				window.open(`https://startupos.getproven.com/auth/jwt?jwt_token=${resp?.data?.data}`, "_blank");
			})
			.catch((e) => {
				window.open("https://startupos.getproven.com", "_blank");
				console.log("Could not generate login token for GetProven. Failed with error:", e);
			})
			.finally(() => {
				setmarketplaceLoading(false);
			});

		services
			.updateStreakActionApi({
				actionType: "MARKET_PLACE",
				actionOnId: userDetails?.userId,
				actionOnType: "MARKET_PLACE",
			})
			.then()
			.catch();
	};

	const trackTopNav = (label) => {
		handleMixPanel("Menu Item Clicked", {
			"Item Name": label,
			"Menu Name": "Top nav",
		});
	};

	const handleSubscribe = () => {
		if (isPremiumPlanAccess || isAdmin) {
			if (bannerDetails?.link) {
				window.open(bannerDetails?.link, "_blank");
			} else {
				dispatch(
					actions.setCommonDialogConfirmationData({
						applyToPitchOpen: true,
						isSVGExpand: false,
						isPitchExpand: true,
					})
				);
			}
		} else {
			setShowPlanModal(true);
		}
	};

	const handleCloseBanner = () => {
		setShowBanner(false);
	};

	useEffect(() => {
		if (openMessageDialog) {
			dispatch(
				setNetworkingDashboardData({
					openMessageDialog: false,
					messageWithMatch: "",
				})
			);
		}
		if (openMeetingDialog) {
			dispatch(
				setNetworkingDashboardData({
					openMeetingDialog: false,
				})
			);
		}
		if (slidingProfileDialog) {
			dispatch(
				setSlidingProfileData({
					open: false,
					profileType: "",
					profileId: null,
				})
			);
		}
	}, [currentLocation?.pathname]);
	return (
		<Fragment>
			{bannerDetails && showBanner && !isInvestorLocked && (userType === USER_TYPES.STARTUP_OWNER || userType === USER_TYPES.STARTUP_USER) && (
				<Box
					ref={updateHeaderRef}
					position="relative"
					p="20px 32px 20px 64px"
					display="flex"
					alignItems="center"
					justifyContent="center"
					sx={{ bgcolor: (theme) => theme?.palette?.primary?.main }}
				>
					<Typography variant="Text/sm/Semibold" color="#fff">
						{/* Demo Day is coming! December 5th, 2023 2PM ET | &nbsp; */}
						{/* {bannerDetails?.message} | &nbsp; */}
					</Typography>
					<Link sx={{ cursor: "pointer", textDecorationColor: "#fff" }} underline="always" onClick={handleSubscribe}>
						<Typography variant="Text/sm/Semibold" color="#fff">
							{/* {isPremiumPlanAccess || isAdmin ? `Apply Now!` : `Subscribe Now!`} */}
							{bannerDetails?.message}
						</Typography>
					</Link>
					<Box position="absolute" right="32px">
						<IconButton onClick={handleCloseBanner}>
							<CloseIcon sx={{ color: "#fff" }} />
						</IconButton>
					</Box>
				</Box>
			)}
			<Box
				ref={showBanner ? null : updateHeaderRef}
				sx={(theme) => ({
					display: "flex",
					alignItems: "center",
					justifyContent: "space-between",
					flexWrap: "wrap-reverse",
					rowGap: 2,
					px: 8,
					mb: 2,
					py: 4,
				})}
			>
				<UpdatedWorkspaceUser
					onLogout={onLogout}
					userData={userData}
					showAccountSettingPopup={showAccountSettingPopup}
					closeAccountSettingsPopup={closeAccountSettingsPopup}
					handleOpen={handleOpen}
					userName={userName}
				/>

				{isDesktop && userType && !isInvestorLocked && (
					<Box
						sx={{
							display: { xs: "none", md: "flex" },
							flexDirection: "row",
							alignItems: "stretch",
							ml: "auto",
							gap: "20px",
						}}
					>
						{/* Commenting out due to non function */}
						{/*{approvalStatus === "APPROVED" && (
							<Button
								variant="link"
								sx={(theme) => ({
									borderRadius: theme.shape.standard25.borderRadius,
									color:  theme.palette.text.onSurfaceVariant,
									// display: "block",
									display: "none", // Hided it because it is not working
									p: 0,
									height: "50px",
								})}
							>
								<Box
									sx={{
										borderRadius: active === "invite" && "60px",
										p: 1,
										height: "45px",
										textAlign: "center",
										// width: "45px",
										background: (theme) => active === "invite" && theme.palette.primary.primaryPurple,
									}}
								>
									<Box component={"img"} src={active === "invite" ? INVITE_RED_ICON : INVITE_RED_ICON} alt="network" />
								</Box>

								<Typography
									sx={{ display: "grid", textAlign: "center", position: "sticky", color: (theme) => theme.palette.text.navLabel }}
									variant="headerText"
								>
									Invite
								</Typography>
							</Button>
						)}*/}
						{/* target, href, active, image, label */}
						{/* -------------------- */}

						{userType !== USER_TYPES.PARTNER_USER && (
							<NavButton
								active={active === "marketplace"}
								image={
									marketplaceLoading ? (
										<CircularProgress size={"24px"} />
									) : (
										<Box component={"img"} src={active === "marketplace" ? WHITE_STORE_ICON : MARKETPLACE_ICON_NEW} alt="marketplace" />
									)
								}
								label={"Marketplace"}
								onClick={() => {
									trackTopNav("Marketplace Viewed");
									handleMixPanel("Marketplace Viewed");
									getProvenLogin();
								}}
							/>
						)}

						{/* New Partner Page Nav Button is added in below commented code */}
						{/* {userType !== USER_TYPES.PARTNER_USER && (
							<NavButton
								active={active === "partners"}
								image={<Box component={"img"} src={active === "partners" ? HEART_HAND_WHITE : HEART_HAND_GREY} alt="partners" />}
								label={"Partners"}
								onClick={() => {
									trackTopNav("Partners Viewed");
									handleMixPanel("Partners Viewed");
									history.push(ROUTES_PATH.PARTNER_BASEPATH);
								}}
							/>
						)} */}

						<NavButton
							onClick={() => {
								trackTopNav("Event Lists Viewed");
								history.push(ROUTES_PATH.EVENTS_BASEPATH);
							}}
							active={active === "events"}
							image={<Box component={"img"} src={active === "events" ? CALENDER_WHITE_ICON : EVENTS_ICON_NEW} alt="network" />}
							label={"Events"}
						/>
						{approvalStatus === "APPROVED" && userType !== USER_TYPES.PARTNER_USER && (
							<NavButton
								onClick={handleListItemClick}
								active={location?.pathname === "/meetings"}
								image={<Box component={"img"} src={location?.pathname === "/meetings" ? MEETING_WHITE_ICON : MEETING_ICON_NEW} alt="network" />}
								label={"Meetings"}
							/>
						)}
						{/* )} */}
						{/* {approvalStatus === "APPROVED" && ( */}
						{checkApprovedCondition() && (
							<Badge
								color="primary"
								overlap="circular"
								badgeContent={hasUpdates}
								anchorOrigin={{
									vertical: "top",
									horizontal: "right",
								}}
								invisible={hasUpdates ? false : true}
							>
								<NavButton
									onClick={() => {
										trackTopNav("Messages Viewed");

										dispatch(
											setNetworkingDashboardData({
												openMessageDialog: true,
												messageWithMatch: "",
											})
										);
										// history.push(ROUTES_PATH.MESSAGES);
									}}
									active={active === "messages"}
									image={<Box component={"img"} src={active === "messages" ? MESSAGES_WHITE_ICON : MESSAGE_ICON_NEW} alt="messages" />}
									label={"Messages"}
								/>
							</Badge>
						)}
						{/* // )} */}
						<NotificationPanelButton />
					</Box>
				)}
			</Box>

			<TemporaryDrawer
				open={openMenu}
				position={"left"}
				hideBackdrop={false}
				onClose={handleOpenMenu}
				sx={(theme) => ({
					zIndex: (theme) => theme.zIndex.drawer + 2,
					"& .MuiPaper-root": {
						width: { xs: "70%", sm: "40%" },
						background:
							"linear-gradient(0deg, rgba(106, 117, 117, 0.02), rgba(106, 117, 117, 0.02)), linear-gradient(0deg, rgba(245, 248, 250, 0.05), rgba(245, 248, 250, 0.05)), #FCFCFC",
					},
				})}
			>
				<Box>
					<HamburgerPanel
						handleOpenMenu={handleOpenMenu}
						userData={userData}
						showAccountSettingPopup={showAccountSettingPopup}
						closeAccountSettingsPopup={closeAccountSettingsPopup}
						handleOpen={handleOpen}
						handleLogout={handleLogout}
						userType={userType}
						companyName={companyName}
						userName={userName}
					/>
				</Box>
			</TemporaryDrawer>
			<MessageHandlerModal
				isOpen={confirmPopup}
				onCancel={popUpClose}
				onOk={() => onLogout()}
				okText="Ok"
				cancelText={"Cancel"}
				heading="Logout"
				text={"Are you sure you want to logout?"}
				messageType="primary"
			/>
			<TemporaryDrawer
				open={openResearchCuratorPanel}
				position={"right"}
				hideBackdrop={true}
				onClose={() => {
					setOpenResearchCuratorPanel(false);
				}}
				sx={(theme) => ({
					"& .MuiPaper-root": {
						width: drawerWidth,
						borderLeft: `1px solid ${theme.palette.secondary.outline2}`,
					},
				})}
			>
				<Box>{ResearchCuratorPanel()}</Box>
			</TemporaryDrawer>
			<TemporaryDrawer
				open={openNewResearchCurator}
				position={"right"}
				hideBackdrop={true}
				onClose={() => {
					setOpenNewResearchCurator(false);
				}}
				onBackdropClick={getCardDetails}
				sx={(theme) => ({
					"& .MuiPaper-root": {
						mt: 3,
						width: drawerWidth,
						overflowY: "scroll",
						borderLeft: `1px solid ${theme.palette.secondary.outline2}`,
					},
				})}
			>
				<CreateResearchCurator
					userDataObj={userDataObj}
					startupData={startupData}
					openNewResearchCurator={openNewResearchCurator}
					setOpenNewResearchCurator={setOpenNewResearchCurator}
					openResearchCuratorPanel={openResearchCuratorPanel}
					setOpenResearchCuratorPanel={setOpenResearchCuratorPanel}
					isExpandedView={false}
					researchCuratorId={currentCardData?.id}
					isDetailView={currentCardData?.id && !currentCardData?.isCreatedByUser}
					getCardDetails={getCardDetails}
					handleDeletePopup={handleDeletePopup}
					setResearchCuratorDetails={setResearchCuratorDetails}
					researchCuratorDetails={researchCuratorDetails}
					setNewCuratorId={setNewCuratorId}
					callback={callback}
					setCallback={setCallback}
				/>

				{newCuratorId && (
					<Box sx={{ px: 3, mb: 5, position: "relative" }}>
						<CommentFeed noteId={newCuratorId} />
					</Box>
				)}
			</TemporaryDrawer>
			<TemporaryDrawer
				open={openQuickNote}
				position={"right"}
				hideBackdrop={true}
				onClose={() => {
					setOpenQuickNote(false);
				}}
				onBackdropClick={getCardDetails}
				sx={(theme) => ({
					"& .MuiPaper-root": {
						mt: 3,
						width: drawerWidth,
						overflowY: "scroll",
						background: (theme) => theme.palette.secondary.quickNoteBg,
						borderLeft: `1px solid ${theme.palette.secondary.outline2}`,
					},
				})}
			>
				<CreateQuickNote
					userDataObj={userDataObj}
					startupData={startupData}
					openQuickNote={openQuickNote}
					setOpenQuickNote={setOpenQuickNote}
					openResearchCuratorPanel={openResearchCuratorPanel}
					setOpenResearchCuratorPanel={setOpenResearchCuratorPanel}
					isExpandedView={false}
					researchCuratorId={currentCardData?.id}
					isDetailView={currentCardData?.id && !currentCardData?.isCreatedByUser}
					getCardDetails={getCardDetails}
					handleDeletePopup={handleDeletePopup}
					setResearchCuratorDetails={setResearchCuratorDetails}
					researchCuratorDetails={researchCuratorDetails}
					setNewCuratorId={setNewCuratorId}
					callback={callback}
					setCallback={setCallback}
				/>
			</TemporaryDrawer>
			<MessageHandlerModal
				isOpen={deleteConfirmPopup}
				onCancel={handleDeletePopup}
				onOk={handleDeleteNote}
				okText="Delete"
				cancelText={"No thanks"}
				heading="Delete Note"
				text={"Are you sure you want to delete this note?"}
				messageType="primary"
			/>
			<MessageDialog />
			<MeetingDialog />
			<SlidingProfile />
			{showPlanModal && <PaymentPlanDialog open={showPlanModal} onClose={handleClosePlanModal} isRedirect={false} />}
			<InvestorApplyToPitch />
			<PromotionalEvents />
		</Fragment>
	);
}

UpdatedHeader.propTypes = {
	onLogout: PropTypes.func,
	userData: PropTypes.object,
};

UpdatedHeader.defaultProps = {
	onBack: undefined,
	userData: undefined,
};

const mapStoreToProps = ({ auth, powerUp }) => ({
	companyName: auth?.companyName,
	userType: auth?.userType,
	userName: auth?.userName,
	showAccountSettingPopup: auth?.showAccountSettingPopup,
});

const mapDispatchToProps = (dispatch) => ({
	setAuthData: (updatedData) => dispatch(actions.setAuthData(updatedData)),
});

export default connect(mapStoreToProps, mapDispatchToProps)(UpdatedHeader);
