import React, { useEffect, useState } from "react";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import styled from "@emotion/styled";
import {
  Box,
  Typography,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export default function DeleteDrawer({
  open,
  onClose,
  title,
  primaryActionText,
  secondaryActionText,
  primaryAction,
  secondaryAction,
  startIcon,
}) {
  const drawerBleeding = 0;

  const Puller = styled(Box)(({ theme }) => ({
    width: 80,
    height: 4,
    backgroundColor: "grey",
    borderRadius: 3,
    top: 8,
    cursor: "pointer",
  }));

  return (
    <>
      <SwipeableDrawer
        anchor="bottom"
        open={open}
        onClose={onClose}
        onOpen={() => {
          return;
        }}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={false}
        sx={{
          display: { xs: "flex", md: "none" },
          zIndex: 9998,
          "& .MuiPaper-root": {
            borderRadius: "16px 16px 0px 0px",
          },
        }}
      >
        <Box
          sx={{ display: "flex", width: 1, justifyContent: "center", py: 1 }}
        >
          <Puller onClick={onClose} />
        </Box>
        <Grid container sx={{ my: 4 }}>
          <Grid
            item
            xs={1}
            sx={{
              display: "flex",
              alignItems: "right",
              justifyContent: "center",
            }}
          >
            {startIcon ? startIcon : <InfoOutlinedIcon />}
          </Grid>
          <Grid
            item
            xs={11}
            sx={{
              display: "flex",
              width: "max-content",
              justifyContent: "flex-start",
              flexDirection: "column",
            }}
          >
            <Typography variant="subhead5" sx={{ pl: 2, mb: 1 }}>
              {title}
            </Typography>
            <List>
              <ListItem sx={{ p: 0 }}>
                <ListItemButton onClick={primaryAction}>
                  <ListItemText>
                    <Typography variant="subhead5" sx={{ cursor: "pointer" }}>
                      {primaryActionText}
                    </Typography>
                  </ListItemText>
                </ListItemButton>
              </ListItem>
              <ListItem sx={{ p: 0 }}>
                <ListItemButton onClick={secondaryAction}>
                  <ListItemText>
                    <Typography variant="subhead5" sx={{ cursor: "pointer" }}>
                      {secondaryActionText}
                    </Typography>
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </SwipeableDrawer>
    </>
  );
}
