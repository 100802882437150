import styled from "@emotion/styled";
import { Grid, Stack, Box, Tab, Tabs } from "@mui/material";

export const TabGroup = styled(Tabs)({
  "& .MuiTabs-indicator": {
    backgroundColor: "rgba(106, 117, 117, 0.12)",
  },
  "& .MuiTabs-flexContainer": {
    justifyContent: "center",
  },
});

export const TabComponent = styled(props => <Tab disableRipple {...props} />)(
  ({ theme, type }) => ({
    textTransform: "none",
    minWidth: 0,
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    width: "50%",
    color: "#7649A0",
    border: "1px solid #7C757F",
    boxSizing: "border-box",
    borderRadius: type === "workspace" ? "8px 0px 0px 8px" : "0px 8px 8px 0px",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      color: "#40a9ff",
      opacity: 1,
    },
    "&.Mui-selected": {
      color: "#7649A0",
      background: "rgba(106, 117, 117, 0.12)",
      fontWeight: theme.typography.fontWeightMedium,
      border: "1px solid #7649A0",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#d1eaff",
    },
  })
);
