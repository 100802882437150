//import { styled } from "@mui/material/styles";
import { Avatar } from "@mui/material";
import styled from "@emotion/styled";

export const SmallAvatar = styled(Avatar)(({ theme }) => ({
  //border: `2px solid ${theme.palette.background.paper}`,
}));

export const AddMediaWrapper = styled.div(({ theme, height }) => {
  return {
    width: 1,
    minHeight: height,
    background:
      "linear-gradient(0deg, #DADCE0, #DADCE0), linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), #DADCE0;",
    border: "1px solid transparent",
    borderRadius: 2,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 1,
    position: "relative",
  };
});
