import React, { useState, useCallback } from "react";
import { Box, IconButton, TextField, Typography, InputAdornment, Autocomplete, CircularProgress, Button, useMediaQuery } from "@mui/material";
import { AddCommentOutlined, Cancel, Search } from "@mui/icons-material";
import { Transition, SwitchTransition } from "react-transition-group";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../../../Messaging/store";
import _ from "lodash";
import services from "../../../../../services";
import ConversationView from "./ConversationView";
import { updateCurrentConversation } from "../../../../Messaging/store/action-creators";
import { handleMixPanel } from "../../../../../utils/mixPanelEventHandling";
import { PLUS_FILLED_BLACK_CIRCLE_ICON, RETURN_ICON } from "../../../../../constants";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import CreateConversationSearch from "./CreateConversationSearch";
import MessageInputField from "../message/MessageInputField";
import ReplyIcon from "@mui/icons-material/Reply";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import NewConversation from "./NewConversation";

export default function ConversationListHeader({ client }) {
	const [searchOpen, setsearchOpen] = useState(true);
	const helloRef = React.useRef(null);
	const goodbyeRef = React.useRef(null);
	const nodeRef = searchOpen ? helloRef : goodbyeRef;
	const [loading, setloading] = useState(false);
	const [options, setOptions] = useState([]);
	const [open, setOpen] = useState(false);
	const [inputValue, setInputValue] = useState("");

	const dispatch = useDispatch();
	const { updateCreatingNewConversationState, updateCurrentConversation, newConversationWith } = bindActionCreators(actionCreators, dispatch);
	const createConversationStatus = useSelector((state) => state.chatState?.createConversationStatus);
	const obj = useSelector((state) => state);

	const customPoint = "820"; //figma
	const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down(customPoint));
	const transitionStylesUp = {
		entering: { opacity: 1, transform: "translateY(0%)" },
		entered: { opacity: 1, transform: "translateY(0%)" },
		exiting: { opacity: 0, transform: "translateY(-100%)" },
		exited: { opacity: 0, transform: "translateY(-100%)" },
	};
	const transitionStylesDown = {
		entering: { opacity: 1, transform: "translateY(0%)" },
		entered: { opacity: 1, transform: "translateY(0%)" },
		exiting: { opacity: 0, transform: "translateY(100%)" },
		exited: { opacity: 0, transform: "translateY(100%)" },
	};
	const handleReturn = () => {
		// setCreateConversationStatus((prev) => !prev);
		console.log(`obj`, obj);
	};

	const searchConversations = async (e) => {
		if (e?.target?.value === "") {
			// when searchtext empty stop calling api
			setOptions([]);
			return;
		}
		setloading(true);
		await services
			.getConversationWithSearch(e?.target?.value ? e?.target?.value : "")
			.then((resp) => {
				console.log(resp);
				setOptions(resp?.data?.data);
			})
			.catch(() => {})
			.finally(() => {
				setloading(false);
			});

		handleMixPanel("Messages Searched Tag", {
			"Search Term": e?.target?.value,
		});
	};
	const debouncedSearchConversations = useCallback(_.debounce(searchConversations, 500), []);
	return (
		<SwitchTransition mode={"out-in"}>
			<Transition
				key={searchOpen}
				nodeRef={nodeRef}
				addEndListener={(done) => {
					nodeRef.current.addEventListener("transitionend", done, false);
				}}
				unmountOnExit
				mountOnEnter
			>
				{(state) => (
					<Box
						ref={nodeRef}
						sx={
							{
								// height: "76px",
								// paddingTop: 2,
								// px: 1,
								// transition: "opacity 200ms, transform 200ms",
								// ...(searchOpen
								//   ? transitionStylesUp[state]
								//   : transitionStylesDown[state]),
							}
						}
					>
						{true ? (
							<Box
								display="flex"
								flexDirection={isSmallScreen ? "column" : "row"}
								justifyContent={"space-between"}
								alignItems={"center"}
								rowGap={isSmallScreen ? 1 : "none"}
							>
								{!createConversationStatus && (
									<Box display="flex" columnGap={1} alignItems="center" width={"75%"}>
										<Box
											sx={{
												flex: 1,
												p: 1,
												border: "1px solid",
												borderColor: (theme) => theme.palette.primary.grayBorder,
												borderRadius: "30px",
											}}
										>
											<Autocomplete
												filterOptions={(x) => x}
												open={open}
												onOpen={() => {
													setOpen(true);
												}}
												onClose={() => {
													setOpen(false);
												}}
												popupIcon={false}
												disableClearable
												sx={{ ".MuiInputBase-root.MuiInput-root": { pr: 0 }, pl: 1.5 }}
												// onInput={_.debounce(searchConversations, 250)}
												inputValue={inputValue}
												onInputChange={(event, value, reason) => {
													if (reason !== "reset") {
														setInputValue(value);
														debouncedSearchConversations(event);
													}
												}}
												options={options}
												loading={loading}
												getOptionLabel={(option) => getConversationName(option)}
												renderInput={(params) => (
													<TextField
														{...params}
														placeholder={"Search Messages"}
														fullWidth
														variant="standard"
														autoFocus
														sx={{
															label: {
																"&:not(.MuiInputLabel-shrink)": (theme) => ({
																	fontFamily: theme.typography["title_medium"],
																	color: theme.palette.text.lightOnsurface,
																}),
															},
															input: {
																"&, &::placeholder": (theme) => ({
																	fontFamily: theme.typography["title_medium"],
																	color: theme.palette.text.lightOnsurface,
																}),
															},
														}}
														InputProps={{
															...params.InputProps,
															disableUnderline: true,

															// endAdornment: (
															// 	<InputAdornment position="end">
															// 		<Search />
															// 	</InputAdornment>
															// ),
														}}
													></TextField>
												)}
												renderOption={(props, option) => {
													return (
														<li {...props} key={option?.conversationId}>
															<Box
																sx={{
																	display: "flex",
																	alignItems: "center",
																	flexWrap: "no-wrap",
																	gap: 2,
																}}
															>
																<ConversationView
																	condensedView
																	convo={{
																		sid: option?.conversationId,
																		startuposMeta: option,
																	}}
																	onClick={async () => {
																		// setsearchOpen((searchOpen) => !searchOpen);
																		// Incase the user was in the process of starting a new conversation, but decided to search instead.
																		updateCreatingNewConversationState(false);
																		newConversationWith([]);
																		updateCurrentConversation(option?.conversationId);
																	}}
																/>
															</Box>
														</li>
													);
												}}
											/>
										</Box>
									</Box>
								)}

								{createConversationStatus ? (
									<Box sx={{ width: "100%" }}>
										{isSmallScreen && (
											<Box
												sx={{ cursor: "pointer" }}
												onClick={() => {
													updateCreatingNewConversationState(false);
													newConversationWith([]);
												}}
											>
												<KeyboardArrowLeftIcon style={{ width: "30px", height: "30px" }} />
											</Box>
										)}
										<Box
											sx={{
												height: "40px",
												display: "flex",
												alignItems: "center",
												justifyContent: "center",
												gap: 2,
												mb: 3,
											}}
										>
											<Box
												component={"img"}
												src={RETURN_ICON}
												alt="return"
												sx={{
													p: 1,
													borderRadius: "50%",
													backgroundColor: "gray.main",
													height: "36px",
													width: "36px",
													cursor: "pointer",
												}}
												onClick={() => updateCreatingNewConversationState(false)}
											/>
											<Typography variant="Text/md/Semibold" color={"#667085"}>
												New Message
											</Typography>
										</Box>

										<CreateConversationSearch new />

										<MessageInputField client={client} />
									</Box>
								) : (
									<Box display="flex" alignItems="center" sx={{ cursor: "pointer" }} onClick={() => updateCreatingNewConversationState(true)}>
										<Button startIcon={<AddRoundedIcon />} variant="DS1" disableRipple>
											New Message
										</Button>
									</Box>
								)}
							</Box>
						) : (
							<Box
								sx={{
									display: "flex",
									flexDirection: "row",
									alignItems: "center",
									justifyContent: "space-between",
									gap: 1,
									p: 1.25,
								}}
							>
								<Typography variant="Display/sm/Semibold">Messages</Typography>
								<Box
									sx={(theme) => ({
										display: "flex",
										flexDirection: "row",
										alignItems: "center",
									})}
								>
									{/* // TODO -- Need to add search to the list of conversations from SOS API */}
									<IconButton
										sx={(theme) => ({
											color: theme.palette.text.onSurfaceVariant,
										})}
										onClick={() => setsearchOpen((searchOpen) => !searchOpen)}
									>
										<Search />
									</IconButton>
									<IconButton
										sx={(theme) => ({
											color: theme.palette.text.onSurfaceVariant,
										})}
										onClick={() => updateCreatingNewConversationState(true)}
									>
										<AddCommentOutlined />
									</IconButton>
								</Box>
							</Box>
						)}
					</Box>
				)}
			</Transition>
		</SwitchTransition>
	);
}

const getConversationName = (convo) => {
	if (convo?.startuposMeta?.conversationParticipants) {
		let name = "";
		convo?.startuposMeta?.conversationParticipants?.map((participant, index) => {
			name += participant?.name;
			if (index < convo?.startuposMeta?.conversationParticipants?.length - 1) {
				name += " & ";
			}
		});
		return name;
	} else if (convo?.startuposMeta?.name) {
		return convo?.startuposMeta?.name;
	} else {
		return "";
	}
};
