import React, { useEffect } from "react";
import { Box, Typography, Avatar, Grid, Divider, IconButton, Link } from "@mui/material";
import CommonSlideDialog from "../../../common/CommonSlideDialog";
import UploadedPostFileDiaplayComp from "../FileUploadComp";
import FeedActionComponent from "../FeedActions";
import FeedCommentsComponent from "../FeedCommentsSection";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { setFeedData } from "../../../modules/actions";
import EditOrDeleteCommentPopUp from "../EditOrDeleteComment";
import { useHistory } from "react-router-dom";
import UpdatedMetaLink from "../../UpdatedMetaLink";
import { getRoleValue } from "../../../utils/commonFunctions";
import { handleMixPanel } from "../../../utils/mixPanelEventHandling";

const FeedPostDetailsPopUp = () => {
	const currentPostIdNew = useSelector((state) => state?.feedPost?.currentPostId);
	const isDtailsOpen = useSelector((state) => state?.feedPost?.detailsPopUpOpen);
	const postData = useSelector((state) => state?.feedPost?.detailsPostObject);
	const authData = useSelector((state) => state?.auth);
	const { isPremiumPlanAccess, userType } = useSelector((store) => store?.auth);

	const dispatch = useDispatch();
	const history = useHistory();

	useEffect(() => {
		if (postData && isDtailsOpen) {
			handleMixPanelEvent();
		}
	}, [isDtailsOpen]);

	const handleMixPanelEvent = () => {
		handleMixPanel("Viewed Feed Post", {
			"Viewing user name": authData?.userName,
			"Viewing user id": authData?.userId,
			"Viewing user role": getRoleValue(authData?.userType),
			"Post id": postData.id,
			subscription_status: isPremiumPlanAccess ? "Subscribed" : "Not Subscribed",
			user_type: userType,
		});
	};
	const handleClose = () => {
		dispatch(
			setFeedData({
				detailsPopUpOpen: false,
				currentCommentList: null,
				currentPostId: "",
			})
		);
	};
	const handleRedirection = () => {
		if (postData?.owner?.isCompanyUser) {
			history.push(`/account-profile/${postData?.owner?.id}`);
		} else {
			history.push(`/mentor-profile/${postData?.owner?.id}`);
		}
	};

	const convertLinksToHyperlinks = (htmlString) => {
		const allMatchesRegex = /<a\b[^>]*>.*?<\/a>|https?:\/\/[^\s<]+/g;

		const convertedString = htmlString?.replace(allMatchesRegex, (match) => {
			// If it's already an <a> tag, return it as is
			if (match.startsWith("<a")) return match;

			// Otherwise, wrap the URL in an <a> tag
			return `<a href="${match}" target="_blank" rel="noopener noreferrer">${match}</a>`;
		});

		return convertedString;
	};

	return (
		<Box>
			<CommonSlideDialog
				disableBackDropClick={false}
				titleVariant={"Text/xl/Semibold"}
				isOpen={isDtailsOpen}
				onClose={handleClose}
				contentStyle={{ p: 0 }}
				maxWidth={
					postData && postData?.attachments && postData?.attachments?.length > 0 && postData?.attachments[0]?.fileType !== "document" ? "lg" : "md"
				}
				fullWidth
				PaperProps={{
					sx: (theme) => ({
						borderRadius: theme.shape.standard13.borderRadius,
						boxShadow: "0px 5px 16px rgba(0, 0, 0, 0.3)",
						height: "fit-content",
						left: 0,
					}),
				}}
			>
				<Grid container>
					{postData && postData?.attachments && postData?.attachments?.length > 0 && postData?.attachments[0]?.fileType !== "document" ? (
						<Grid item lg={6} md={6} sm={12} xs={12}>
							<Box
								sx={(theme) => ({
									width: 1,
									height: 1,
									background: "black",
									position: "relative",
									[theme.breakpoints.down("md")]: {
										height: "fit-content",
										background: "none",
									},
								})}
							>
								<Box
									sx={(theme) => ({
										display: "flex",
										maxHeight: "660px",
										alignItems: "center",
										justifyContent: "center",
										[theme.breakpoints.down("md")]: {
											height: "fit-content",
										},
									})}
								>
									<UploadedPostFileDiaplayComp type={"PostDetails"} item={postData && postData} />
								</Box>
								<IconButton
									aria-label="close"
									onClick={handleClose}
									sx={(theme) => ({
										width: "25px",
										height: "25px",
										ml: "auto",
										display: "none",
										position: "absolute",
										top: "12px",
										right: "12px",
										background: "white",
										"&:hover": {
											background: "white",
										},
										[theme.breakpoints.down("md")]: {
											display: "flex",
										},
									})}
								>
									<CloseIcon
										onClick={() => {
											dispatch(setFeedData({ detailsPopUpOpen: false }));
										}}
										sx={{ color: (theme) => theme.palette.secondary.black }}
									/>
								</IconButton>
							</Box>
						</Grid>
					) : (
						<></>
					)}

					<Grid
						className="SOSShowVerticalScroll"
						item
						lg={
							postData && postData?.attachments && postData?.attachments?.length > 0 && postData?.attachments[0]?.fileType !== "document" ? 6 : 12
						}
						md={
							postData && postData?.attachments && postData?.attachments?.length > 0 && postData?.attachments[0]?.fileType !== "document" ? 6 : 12
						}
						sm={12}
						xs={12}
						sx={{
							display: "flex",
							flexDirection: "column",
							gap: 2,
							p: 3,
							maxHeight: "660px",
							overflowY: "auto",
						}}
					>
						<Box
							sx={{
								display: "flex",
								gap: 2,
							}}
						>
							<Box
								sx={{
									display: "flex",
									alignItems: "center",
									gap: 2,
								}}
							>
								<Avatar
									sx={{ height: "100px", width: "100px" }}
									src={postData && postData?.owner?.picture}
									alt="avatar"
									onClick={handleRedirection}
								/>
								<Box
									sx={{
										display: "flex",
										flexDirection: "column",
										ml: 1,
									}}
								>
									{/*  */}
									<Typography variant="flyout_title" onClick={handleRedirection}>
										{postData && postData?.owner?.name}
									</Typography>
									<Typography variant="Text/sm/Regular">
										<Link
											color={"#1A73E8"}
											onClick={() => {
												history.push(`/startup-profile/${postData && postData?.owner?.companyModel?.id}`);
											}}
										>
											{postData && postData?.owner?.companyModel?.companyName}
										</Link>
									</Typography>
								</Box>
							</Box>
							<IconButton
								aria-label="close"
								onClick={handleClose}
								sx={(theme) => ({
									width: "12px",
									height: "12px",
									ml: "auto",
									"&:hover": {
										background: "white",
									},
									[theme.breakpoints.down("md")]: {
										display:
											postData &&
											postData?.attachments &&
											postData?.attachments?.length > 0 &&
											postData?.attachments[0]?.fileType !== "document"
												? "none"
												: "flex",
									},
								})}
							>
								<CloseIcon
									onClick={() => {
										dispatch(setFeedData({ detailsPopUpOpen: false }));
									}}
									sx={{ color: (theme) => theme.palette.secondary.black }}
								/>
							</IconButton>
						</Box>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								gap: 2,
								textAlign: "left",
							}}
						>
							<Typography variant="Text/sm/Regular">
								<div className="feedHtmlContainer" dangerouslySetInnerHTML={{ __html: convertLinksToHyperlinks(postData?.description) }}></div>
							</Typography>
							{postData?.description && <UpdatedMetaLink data={postData?.description} feedObj={postData} type="OTHER" />}
						</Box>
						{postData && postData?.attachments && postData?.attachments?.length > 0 && postData?.attachments[0]?.fileType === "document" ? (
							<Box>
								<UploadedPostFileDiaplayComp type={"PostDetails"} item={postData && postData} />
								<Divider
									sx={{
										background: (theme) => theme.palette.secondary.blak,
										borderBottomWidth: "2px",
										mt: 2,
									}}
								/>
							</Box>
						) : (
							<></>
						)}

						<Box>
							<FeedActionComponent item={postData && postData} />
						</Box>
						{currentPostIdNew === postData?.id && isDtailsOpen ? (
							<FeedCommentsComponent
								comments={postData && postData?.comments}
								postId={postData && postData?.id}
								type={"detailsPopup"}
								detailsPostObjectData={postData}
							/>
						) : (
							<></>
						)}
					</Grid>
				</Grid>
			</CommonSlideDialog>
			<EditOrDeleteCommentPopUp />
		</Box>
	);
};

export default FeedPostDetailsPopUp;
