import React, { useState } from "react";
import { Box, Typography, Button, Popover } from "@mui/material";
import { LINK_PARTNER_INFO_CIRCLE } from "../../../../constants";

function LinkPartner({ name, onClick, isAvailable = true, picture }) {
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		event.stopPropagation();
		setAnchorEl(event.currentTarget);
	};
	const handleClose = (event) => {
		event.stopPropagation();
		setAnchorEl(null);
	};

	return (
		<Box display="flex" justifyContent="space-between" alignItems="center" rowGap={1} flexWrap="wrap">
			<Box display="flex" columnGap={1} alignItems="center">
				<Box component="img" src={LINK_PARTNER_INFO_CIRCLE} alt="info" sx={{ cursor: "pointer" }} onClick={handleClick} />
				{picture && <Box component="img" src={picture} alt="partner-icon" sx={{ width: "32px" }} />}
				<Typography variant="Text/sm/Regular" color="sos.gray500">
					{name}
				</Typography>
			</Box>
			<Box>
				{isAvailable ? (
					<Button variant="DS1"  onClick={onClick}>
						Connect
					</Button>
				) : (
					<Button variant="containedGrayStyle" sx={{ px: "14px", py: "5px" }} onClick={() => {}}>
						Coming Soon
					</Button>
				)}
			</Box>
			<Popover
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "center",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "left",
				}}
				PaperProps={{
					sx: {
						p: "30px",
						borderRadius: "30px",
						background: "white",
						boxShadow: "0px 10px 85px rgba(0, 0, 0, 0.1)",
					},
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						maxWidth: "350px",
						gap: 1,
						maxHeight: "350px",
						overflowY: "auto",
						pr: 1,
					}}
					className="SOSShowVerticalScroll"
				>
					<Typography component={"p"} variant="Text/sm/Semibold">
						{`Once connected, ${name} will support:`}
					</Typography>
					<ul style={{ paddingLeft: "24px" }}>
						<li>
							<Typography variant="Text/sm/Regular">Ownership & Fully Diluted Shares</Typography>
						</li>
						<li>
							<Typography variant="Text/sm/Regular">Amount Raised by Type</Typography>
						</li>
						<li>
							<Typography variant="Text/sm/Regular">Customers</Typography>
						</li>
						<li>
							<Typography variant="Text/sm/Regular">ARR</Typography>
						</li>
						<li>
							<Typography variant="Text/sm/Regular">Net New ARR</Typography>
						</li>
					</ul>
					{/* {bodyText} */}
				</Box>
			</Popover>
		</Box>
	);
}
export default LinkPartner;
