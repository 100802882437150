import styled from "@emotion/styled";
import {
  Card,
  CardActions,
  CardContent,
  Box,
  Button,
  Typography,
} from "@mui/material";

export const CardEducation = styled(Card)(({ theme }) => ({
  color: "darkslategray",
  backgroundColor: "#FFFFFF", // StartupOS/sys/light/on-primary
  borderRadius: "30px",
  boxShadow: "0px 10px 15px rgba(0, 0, 0, 0.05)",
  padding: 0,
  //height: 140,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
}));

export const CardContentPowerUp = styled(CardContent)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-end",
}));

export const CardActionsEducation = styled(CardActions)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "flex-end",
}));

export const BoxPowerUpTitle = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
}));
export const BoxPowerUpDetails = styled(Box)(({ theme }) => ({
  marginTop: "24px",
  display: "flex",
  alignItems: "center",
}));

export const ButtonPowerUp = styled(Button)(({ theme }) => ({
  width: 83,
  height: 40,
  borderRadius: 4,
  margin: 5,
}));

export const TypographyPowerUp = styled(Typography)(({ theme }) => ({
  overflow: "hidden",
  textOverFlow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: "3",
  WebkitBoxOrient: "vertical",
}));
