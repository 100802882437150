import { PersonSearch, SpeakerNotes } from "@mui/icons-material";
import {
  Box,
  Typography,
  Button,
  Card,
  useMediaQuery,
  Divider,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import VideoPlayerComponent from "../../../../common/videoPlayer";
import * as actions from "../../../../modules/actions";
import services from "../../../../services";
import BrowseCandidatesTable from "../../PowerUP/IdeaValidation/Components/BrowseCandidatesTable";
import HeaderComponent from "./HeaderComponent";
import theme from "../../../../constants/Theme";
import JsxParser from "react-jsx-parser";

const ComponentButton = ({ icon, text, action, variant, ...buttonProps }) => {
  return (
    <Button
      disabled={buttonProps.disabled}
      variant={variant}
      sx={{
        width: "100%",
        maxWidth: "unset",
        height: "200px", // Explicit height setting in this case. Does not follow reusable guidelines. -MG
        ...buttonProps.styles,
      }}
      onClick={action}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box>{icon}</Box>
        {text}
      </Box>
    </Button>
  );
};

const DesktopView = ({
  getCardDisableStatus,
  handleCardAction,
  getTitle,
  moduleInterview,
  moduleBuildScript,
  marketDetails,
  marketResponse,
}) => {
  const [marketData, setMarketData] = useState({});
  useEffect(() => {
    setMarketData(marketDetails);
  }, [marketDetails]);

  const dispatch = useDispatch();
  return (
    <Card
      size="large"
      variant="outlined"
      sx={{
        p: 3,
        border: "none",
        boxShadow:
          "rgb(0 0 0 / 30%) 0px 1px 2px, rgb(0 0 0 / 15%) 0px 1px 3px 1px",
      }}
    >
      <Box>
        <HeaderComponent
          marketData={marketData}
          marketResponse={marketResponse}
        />
        <Divider
          color={theme.palette.secondary.light}
          sx={{ my: 6, display: { xs: "none", md: "block" } }}
        />
      </Box>
      <Box>
        <VideoPlayerComponent
          videoUrl={marketData.videoUrl}
          videoThumbnailUrl={marketData?.videoThumbnailUrl}
        />
      </Box>

      <Box
        gridTemplateColumns={"repeat(12,1fr)"}
        display="grid"
        gap={2}
        sx={{ my: 3 }}
      >
        {marketResponse &&
          marketResponse.moduleResponseModels &&
          marketResponse.moduleResponseModels.map(response => {
            return (
              <Box gridColumn={"span 6"}>
                <ComponentButton
                  disabled={getCardDisableStatus(response)}
                  icon={
                    response.module.moduleUuid === "md-build-script-test" ? (
                      <SpeakerNotes />
                    ) : (
                      <PersonSearch />
                    )
                  }
                  text={getTitle(response)}
                  action={() => handleCardAction(response)}
                  variant="outlined"
                />
              </Box>
            );
          })}
      </Box>

      {/* TEMP HIDING CANDIDATES  */}
      {/* <Divider sx={{ my: 6 }} />
      <Box
        gridTemplateColumns={"repeat(12,1fr)"}
        display="grid"
        gap={2}
        sx={{ my: 3 }}
      >
        {" "}
        <Box gridColumn={"span 12"}>
          {moduleBuildScript &&
            moduleBuildScript.moduleStepResponses.length > 1 && (
              <BrowseCandidatesTable
                moduleBuildScript={moduleBuildScript}
                moduleInterview={moduleInterview}
                handleStartAction={handleCardAction}
                module={marketResponse?.moduleResponseModels[1]}
              />
            )}
        </Box>
      </Box> */}

      {/* //TODO - Somewhere in here we need to pull prereqs. */}

      <Box sx={{ my: 4 }}>
        <Box sx={{ my: 1 }}>
          <Typography variant="largeTitle" color="text.secondary">
            What you'll learn
          </Typography>
        </Box>

        <Box>
          <Typography>
            <JsxParser components={{ Typography }} jsx={marketData.learning} />
          </Typography>
        </Box>
      </Box>

      <Box sx={{ my: 2 }}>
        <Box sx={{ my: 1 }}>
          <Typography variant="largeTitle" color="text.secondary">
            Before you start
          </Typography>
        </Box>

        <Box>
          <Typography>
            <JsxParser
              components={{ Typography }}
              jsx={marketData.beforeBegin}
            />
          </Typography>
        </Box>
      </Box>
    </Card>
  );
};

const MobileView = ({
  handleCardAction,
  getCardDisableStatus,
  getTitle,
  moduleInterview,
  moduleBuildScript,
  marketDetails,
  marketResponse,
}) => {
  const [marketData, setMarketData] = useState({});
  useEffect(() => {
    setMarketData(marketDetails);
  }, [marketDetails]);

  return (
    <Box>
      <Box sx={{ my: 4 }}>
        <Box>
          <HeaderComponent
            marketData={marketData}
            marketResponse={marketResponse}
          />
          <Divider
            color={theme.palette.secondary.dark}
            sx={{ my: 6, display: { xs: "block", md: "none" } }}
          />
        </Box>
      </Box>

      <Box>
        <VideoPlayerComponent
          videoUrl={marketData?.videoUrl}
          videoThumbnailUrl={marketData?.videoThumbnailUrl}
        />
      </Box>

      <Box sx={{ my: 4, display: "flex", flexDirection: "column", gap: 4 }}>
        {marketResponse &&
          marketResponse.moduleResponseModels &&
          marketResponse.moduleResponseModels.map(response => {
            return (
              <Box gridColumn={"span 6"}>
                <ComponentButton
                  disabled={getCardDisableStatus(response)}
                  icon={
                    response.module.moduleUuid === "md-build-script-test" ? (
                      <SpeakerNotes />
                    ) : (
                      <PersonSearch />
                    )
                  }
                  variant="contained"
                  text={getTitle(response)}
                  action={() => handleCardAction(response)}
                />
              </Box>
            );
          })}
      </Box>

      {/* TEMP HIDING CANDIDATES  */}
      {/* <Divider sx={{ my: 6 }} />

      <Box
        gridTemplateColumns={"repeat(12,1fr)"}
        display="grid"
        gap={2}
        sx={{ my: 3 }}
      >
        {" "}
        <Box gridColumn={"span 12"}>
          {moduleBuildScript &&
            moduleBuildScript.moduleStepResponses.length > 1 && (
              <BrowseCandidatesTable
                moduleBuildScript={moduleBuildScript}
                moduleInterview={moduleInterview}
                handleStartAction={handleCardAction}
                module={marketResponse?.moduleResponseModels[1]}
              />
            )}
        </Box>
      </Box> */}

      <Box sx={{ my: 2 }}>
        <Box sx={{ my: 1 }}>
          <Typography variant="largeTitle" color="text.secondary">
            What you'll need
          </Typography>
        </Box>

        <Box>
          <Typography variant="subhead2" color="GrayText.secondary">
            Complete these 3 PowerUps before beginning:
          </Typography>
        </Box>
      </Box>

      {/* //TODO - Somewhere in here we need to pull prereqs. */}

      <Box sx={{ my: 4 }}>
        <Box sx={{ my: 1 }}>
          <Typography variant="largeTitle" color="text.secondary">
            What you'll learn
          </Typography>
        </Box>

        <Box>
          <Typography variant="bodyLarge">{marketData.learning}</Typography>
        </Box>
      </Box>

      <Box sx={{ my: 3 }}>
        <Box sx={{ my: 1 }}>
          <Typography variant="largeTitle" color="text.secondary">
            Before you start
          </Typography>
        </Box>

        <Box>
          <Typography variant="bodyLarge">{marketData.beforeBegin}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export const DetailsOverview = ({
  marketData,
  marketTestId,
  marketResponse,
}) => {
  const [marketDetails, setMarketDetails] = useState({});
  const [moduleBuildScript, setModuleBuildScript] = useState(null);
  const [moduleInterview, setModuleInterview] = useState(null);
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("md"));
  const history = useHistory();
  const dispatch = useDispatch();

  const powerupModuleDetails = useSelector(
    store => store?.powerUp?.powerupDetails
  );

  useEffect(() => {
    setMarketDetails(marketData);
  }, [marketData]);

  useEffect(() => {
    if (marketResponse) {
      if (marketResponse.moduleResponseModels.length) {
        const responseData = marketResponse.moduleResponseModels;
        let buildScript = responseData.find(
          item => item.module.moduleUuid === "md-build-script-test"
        );
        if (buildScript.moduleStepResponses.length) {
          buildScript.moduleStepResponses.sort((a, b) => {
            return a.moduleStep.stepNo > b.moduleStep.stepNo ? 1 : -1;
          });
        }

        let interview = responseData.find(
          item => item.module.moduleUuid === "md-interview-test"
        );

        setModuleBuildScript(buildScript);
        setModuleInterview(interview);

        if (
          powerupModuleDetails &&
          powerupModuleDetails?.data?.moduleStepResponses[0]?.id ===
            buildScript?.moduleStepResponses[0]?.id
        ) {
          let storeScriptModuleResponse =
            powerupModuleDetails?.data?.moduleStepResponses[1];
          let apiScriptModuleResponse = buildScript?.moduleStepResponses[1];

          if (apiScriptModuleResponse && storeScriptModuleResponse) {
            const responseScript = JSON.parse(apiScriptModuleResponse.answers);

            const storeScript = JSON.parse(storeScriptModuleResponse.answers);

            if (
              responseScript.interviewScript.length &&
              !storeScript.interviewScript.length
            ) {
              dispatch(
                actions.setPowerupDetails({
                  id: "build-script",
                  data: buildScript,
                })
              );
            } else {
              setModuleBuildScript(powerupModuleDetails?.data);
            }
          } else {
            dispatch(
              actions.setPowerupDetails({
                id: "build-script",
                data: buildScript,
              })
            );
          }
        } else {
          dispatch(
            actions.setPowerupDetails({
              id: "build-script",
              data: buildScript,
            })
          );
        }

        const candidates = interview?.moduleStepResponses[0]?.answers
          ? JSON.parse(interview?.moduleStepResponses[0]?.answers)?.candidates
          : [];

        if (candidates) {
          dispatch(actions.setInterviewCandidates(candidates));
        }
      }
    }
  }, [marketResponse]);

  const getCardDisableStatus = response => {
    // TEMP LOCKING Browse Interview Candidates
    // if (response.module.moduleUuid === "md-build-script-test" || response.module.title !== "Browse Interview Candidates") {
    // if (response.module.moduleUuid === "md-build-script-test") {
    //   return false;
    // } else {
    //   if (
    //     moduleBuildScript &&
    //     moduleBuildScript.moduleStepResponses.length === 2
    //   ) {
    //     const interviewScript = JSON.parse(
    //       moduleBuildScript.moduleStepResponses[1].answers
    //     ).interviewScript;
    //     if (interviewScript.length) {
    //       return false;
    //     } else {
    //       return true;
    //     }
    //   } else {
    //     return true;
    //   }
    // }
  };

  const handleCardAction = response => {
    //handle click of build script card
    if (response.module.moduleUuid === "md-build-script-test") {
      if (
        moduleBuildScript &&
        moduleBuildScript.moduleStepResponses.length === 2
      ) {
        if (
          JSON.parse(moduleBuildScript.moduleStepResponses[1].answers)
            .interviewScript.length
        ) {
          dispatch(actions.setInterviewScriptDialog(true));
          if (powerupModuleDetails) {
            dispatch(actions.setPowerupDetails(powerupModuleDetails));
          } else {
            dispatch(
              actions.setPowerupDetails({
                id: "build-script",
                data: moduleBuildScript,
              })
            );
          }
        } else {
          history.push(
            `/workspace/powerup/md-ideavalidation/${response.moduleId}/${response.id}/start`
          );
        }
      } else {
        history.push(
          `/workspace/powerup/md-ideavalidation/${response.moduleId}/${response.id}/start`
        );
      }
    } else {
      //handle click of browse candidate card
      history.push(
        `/workspace/powerup/4/browse-candidates/${response.moduleId}/${response.id}/browse`
      );
    }
  };

  const getTitle = response => {
    if (response.module.moduleUuid === "md-build-script-test") {
      if (
        moduleBuildScript &&
        moduleBuildScript.moduleStepResponses.length > 1
      ) {
        if (
          JSON.parse(moduleBuildScript.moduleStepResponses[1].answers)
            .interviewScript.length
        ) {
          return "View Completed Script";
        } else {
          return "Build a Script";
        }
      } else {
        return "Build a Script";
      }
    } else if (response.module.title === "Browse Interview Candidates") {
      // TEMP LOCKING Browse Interview Candidates
      return "Browse Interview Candidates (Coming Soon)";
    } else {
      return response.module.title;
    }
  };

  return (
    <Box>
      {isSmallScreen ? (
        <MobileView
          getCardDisableStatus={getCardDisableStatus}
          handleCardAction={handleCardAction}
          getTitle={getTitle}
          moduleBuildScript={moduleBuildScript}
          moduleInterview={moduleInterview}
          marketDetails={marketDetails}
          marketResponse={marketResponse}
        />
      ) : (
        <DesktopView
          getCardDisableStatus={getCardDisableStatus}
          handleCardAction={handleCardAction}
          getTitle={getTitle}
          moduleBuildScript={moduleBuildScript}
          moduleInterview={moduleInterview}
          marketDetails={marketDetails}
          marketResponse={marketResponse}
        />
      )}
    </Box>
  );
};
