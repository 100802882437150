import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { NumericFormat } from "react-number-format";
import { Box, Typography, Select, MenuItem, TextField, IconButton, Link, InputAdornment, Grid } from "@mui/material";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import { OnboardContainer } from "../ui";
import { CURRENT_STAGE_LIST, FUNDING_TYPES, ATTENDED_OPTIONS, INCORPORATED_LIST } from "../Options";
import OpenLinkIcon from "../../Assets/open-link-icon.svg";
import { getListOfCategoryApiCall } from "../../../Investor/Dashboard/Functions/common";
import moment from "moment";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

const schema = yup
	.object({
		companyName: yup.string().required("Startup Name is required"),
		industry: yup.string().required("Industry is required"),
		foundedDate: yup.string().nullable(),
		productOffering: yup.string().required("Product Category is required"),
		companySize: yup.number(),
		currentStage: yup.string().required(),
		websiteLink: yup.string(),
		fundingType: yup.string().required(),
		companyLinkedinUrl: yup.string(),
		capitalRaised: yup.string().required(),
		incubator: yup.string().required(),
		ask: yup.string().required(),
		mvpLaunched: yup.string().required(),
		revenueModel: yup.string().required(),
		incorporatedStatus: yup.string().required(),
		businessModel: yup.string().required(),
	})
	.required();

export const StartupInfo = ({ isNext, userType, currentStep, data, onActive, onUpdate }) => {
	const [industryList, setIndustryList] = useState([]);
	const [productOfferingList, setProductOfferingList] = useState([]);
	const [businessTypeList, setBusinessTypeList] = useState([]);
	const [isOpen, setIsOpen] = useState(false);

	const handleOnclick = () => {
		if (isOpen === false) {
			setIsOpen(true);
		}
	};

	const {
		setValue,
		getValues,
		control,
		formState: { errors, isValid },
	} = useForm({
		mode: "all",
		defaultValues: {
			companyName: "",
			industry: "",
			foundedDate: null,
			productOffering: "",
			companySize: null,
			currentStage: "",
			websiteLink: "",
			fundingType: "",
			companyLinkedinUrl: "www.linkedin.com/company",
			capitalRaised: null,
			incubator: "none",
			ask: null,
			mvpLaunched: "",
			revenueModel: "",
			incorporatedStatus: "",
			businessModel: "",
		},
		resolver: yupResolver(schema),
	});

	useEffect(() => {
		setValue("companyName", data?.companyName || "");
		setValue("industry", data?.industry ? JSON.parse(data.industry)[0] : "");
		setValue("foundedDate", data?.foundedDate || null);
		setValue("productOffering", data?.productOffering || "");
		setValue("companySize", data?.companySize || null);
		setValue("currentStage", data?.currentStage || "");
		setValue("websiteLink", data?.websiteLink || "");
		setValue("fundingType", data?.fundingType ? JSON.parse(data.fundingType)[0] : "");
		setValue("companyLinkedinUrl", data?.companyLinkedinUrl || "www.linkedin.com/company");
		setValue("capitalRaised", data?.capitalRaised || null);
		setValue("incubator", data?.incubator || "");
		setValue("ask", data?.ask || null);
		setValue("mvpLaunched", data?.mvpLaunched || "");
		setValue("revenueModel", data?.revenueModel || "");
		setValue("incorporatedStatus", data?.incorporatedStatus || "");
		setValue("businessModel", data?.businessModel || "");
	}, [data]);

	useEffect(() => {
		if (isValid) {
			onActive(true);
		} else {
			onActive(false);
		}
	}, [isValid]);

	useEffect(() => {
		if (isNext) {
			onUpdate(currentStep, {
				companyName: getValues("companyName"),
				industry: JSON.stringify([getValues("industry")]),
				foundedDate: getValues("foundedDate"),
				productOffering: getValues("productOffering"),
				companySize: parseInt(getValues("companySize")),
				websiteLink: getValues("websiteLink"),
				fundingType: JSON.stringify([getValues("fundingType")]),
				companyLinkedinUrl: getValues("companyLinkedinUrl"),
				capitalRaised: parseFloat(getValues("capitalRaised")),
				incubator: getValues([getValues("incubator")]),
				ask: parseFloat(getValues("ask")),
				mvpLaunched: getValues("mvpLaunched"),
				revenueModel: getValues("revenueModel"),
				incorporatedStatus: getValues("incorporatedStatus"),
				businessModel: getValues("businessModel"),
				currentStage: getValues("currentStage"),
			});
		}
	}, [isNext]);

	useEffect(() => {
		getCategoryList("industry");
		getCategoryList("product-offering");
		getCategoryList("business-type");
	}, []);

	const getCategoryList = (category) => {
		getListOfCategoryApiCall(category)
			.then((res) => {
				const responseData = res.data;
				let categoryArray = [];
				responseData.map((categoryItem) => {
					categoryArray.push(categoryItem?.lovValue);
				});

				if (category === "industry") {
					setIndustryList(categoryArray);
				} else if (category === "product-offering") {
					setProductOfferingList(categoryArray);
				} else if (category === "business-type") {
					setBusinessTypeList(categoryArray);
				}
			})
			.catch((e) => {
				console.log("Error", e);
			});
	};

	return (
		<OnboardContainer title="Startup Info">
			<form>
				<Grid container spacing="40px">
					<Grid item xs={12} md={6}>
						<Typography variant="poppinsSemiBold18">Startup Name*</Typography>
						<Controller
							name="companyName"
							control={control}
							render={({ field: { onChange, value, name } }) => (
								<TextField
									fullWidth
									id="companyName"
									placeholder="Enter Startup Name"
									value={value}
									name={name}
									sx={{
										mt: 1.25,
										"& .MuiOutlinedInput-notchedOutline": {
											border: errors.companyName ? "5px solid rgba(240, 68, 56, 0.3) !important" : "1px solid #DDDDDD",
										},
									}}
									InputProps={{
										style: {
											borderRadius: "30px",
											height: "60px",
											fontWeight: "500",
											fontSize: "16px",
											lineHeight: "24px",
											padding: "1.5px 6px",
										},
										startAdornment: errors.companyName ? (
											<InputAdornment position="end">
												<IconButton>
													<ErrorOutlineOutlinedIcon sx={{ color: "#F04438" }} />
												</IconButton>
											</InputAdornment>
										) : null,
									}}
									onChange={onChange}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<Typography variant="poppinsSemiBold18">Industry*</Typography>
						<Controller
							name="industry"
							control={control}
							render={({ field: { onChange, value, name } }) => (
								<Select
									fullWidth
									value={value}
									name={name}
									onChange={onChange}
									IconComponent={SelectIconComponent}
									MenuProps={MenuProps}
									sx={{
										mt: 1.25,
										"& .MuiOutlinedInput-notchedOutline": { borderColor: "#DDDDDD" },
										"& .MuiSelect-select": {
											fontWeight: "500",
											fontSize: "16px",
											lineHeight: "24px",
										},
										padding: "1.5px 6px",
										borderRadius: "40px",
									}}
								>
									{industryList.map((option) => (
										<MenuItem key={option} value={option}>
											{option}
										</MenuItem>
									))}
								</Select>
							)}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						{/* TODO: */}
						<Typography variant="poppinsSemiBold18">Date Founded</Typography>
						<Box onClick={handleOnclick}>
							<Controller
								name="foundedDate"
								control={control}
								render={({ field: { onChange, value, name } }) => (
									<LocalizationProvider dateAdapter={AdapterMoment}>
										<DatePicker
											open={isOpen}
											onClose={() => setIsOpen(false)}
											format="MM/DD/YYYY"
											value={value ? moment(value) : null}
											disableFuture={true}
											onChange={(newValue) => {
												onChange(moment(newValue).toDate());
											}}
											slots={{
												OpenPickerIcon: CalendarTodayOutlinedIcon,
											}}
											sx={{
												mt: 1.25,
												"& .MuiOutlinedInput-notchedOutline": { borderColor: "#DDDDDD" },
												"&.MuiTextField-root": { width: "100%" },
											}}
											slotProps={{
												openPickerButton: {
													sx: {
														mr: "5px",
													},
												},
												field: {
													InputProps: {
														endAdornment: (
															<InputAdornment position="end" sx={{ mr: 1.5 }}>
																<IconButton edge="end">
																	<CalendarTodayOutlinedIcon sx={{ color: "#000" }} />
																</IconButton>
															</InputAdornment>
														),
														variant: "outlined",
														sx: {
															borderRadius: "30px",
															height: "60px",
															fontWeight: "500",
															fontSize: "16px",
															lineHeight: "24px",
															padding: "1.5px 6px",
														},
													},
												},
											}}
										/>
									</LocalizationProvider>
								)}
							/>
						</Box>
					</Grid>
					<Grid item xs={12} md={6}>
						<Typography variant="poppinsSemiBold18">Product Category*</Typography>
						<Controller
							name="productOffering"
							control={control}
							render={({ field: { onChange, value, name } }) => (
								<Select
									fullWidth
									value={value}
									name={name}
									onChange={onChange}
									IconComponent={SelectIconComponent}
									MenuProps={MenuProps}
									sx={{
										mt: 1.25,
										"& .MuiOutlinedInput-notchedOutline": { borderColor: "#DDDDDD" },
										"& .MuiSelect-select": { fontWeight: "500", fontSize: "16px", lineHeight: "24px" },
										padding: "1.5px 6px",
										borderRadius: "40px",
									}}
								>
									{productOfferingList.map((option) => (
										<MenuItem key={option} value={option}>
											{option}
										</MenuItem>
									))}
								</Select>
							)}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<Typography variant="poppinsSemiBold18">Founding Team Size*</Typography>
						<Controller
							name="companySize"
							control={control}
							render={({ field: { onChange, value, name } }) => (
								<TextField
									fullWidth
									id="companySize"
									placeholder="Enter your Founding Team Size"
									value={value}
									name={name}
									sx={{
										mt: 1.25,
										"& .MuiOutlinedInput-notchedOutline": {
											border: errors.companySize ? "5px solid rgba(240, 68, 56, 0.3) !important" : "1px solid #DDDDDD",
										},
									}}
									InputProps={{
										style: {
											borderRadius: "30px",
											height: "60px",
											fontWeight: "500",
											fontSize: "16px",
											lineHeight: "24px",
											padding: "1.5px 6px",
										},
										startAdornment: errors.companySize ? (
											<InputAdornment position="end">
												<IconButton>
													<ErrorOutlineOutlinedIcon sx={{ color: "#F04438" }} />
												</IconButton>
											</InputAdornment>
										) : null,
									}}
									onChange={onChange}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<Typography variant="poppinsSemiBold18">Stage of Startup*</Typography>
						<Controller
							name="currentStage"
							control={control}
							render={({ field: { onChange, value, name } }) => (
								<Select
									fullWidth
									value={value}
									name={name}
									onChange={onChange}
									IconComponent={SelectIconComponent}
									sx={{
										mt: 1.25,
										"& .MuiOutlinedInput-notchedOutline": { borderColor: "#DDDDDD" },
										"& .MuiSelect-select": { fontWeight: "500", fontSize: "16px", lineHeight: "24px" },
										padding: "1.5px 6px",
										borderRadius: "40px",
									}}
								>
									{CURRENT_STAGE_LIST.map((option) => (
										<MenuItem key={option} value={option}>
											{option}
										</MenuItem>
									))}
								</Select>
							)}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<Typography variant="poppinsSemiBold18">Startup Website</Typography>
						<Controller
							name="websiteLink"
							control={control}
							render={({ field: { onChange, value, name } }) => (
								<TextField
									fullWidth
									id="websiteLink"
									placeholder="Enter your Startup Website"
									value={value}
									name={name}
									sx={{ mt: 1.25, "& .MuiOutlinedInput-notchedOutline": { borderColor: "#DDDDDD" } }}
									InputProps={{
										style: {
											borderRadius: "30px",
											height: "60px",
											fontWeight: "500",
											fontSize: "16px",
											lineHeight: "24px",
											padding: "1.5px 6px",
										},
									}}
									onChange={onChange}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<Typography variant="poppinsSemiBold18">What type of funding are you seeking*</Typography>
						<Controller
							name="fundingType"
							control={control}
							render={({ field: { onChange, value, name } }) => (
								<Select
									fullWidth
									value={value}
									name={name}
									onChange={onChange}
									IconComponent={SelectIconComponent}
									sx={{
										mt: 1.25,
										"& .MuiOutlinedInput-notchedOutline": { borderColor: "#DDDDDD" },
										"& .MuiSelect-select": { fontWeight: "500", fontSize: "16px", lineHeight: "24px" },
										padding: "1.5px 6px",
										borderRadius: "40px",
									}}
								>
									<MenuItem value="none">None</MenuItem>
									{FUNDING_TYPES.map((option) => (
										<MenuItem key={option} value={option}>
											{option}
										</MenuItem>
									))}
								</Select>
							)}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<Typography variant="poppinsSemiBold18">LinkedIn Company Profile URL</Typography>
						<Controller
							name="companyLinkedinUrl"
							control={control}
							render={({ field: { onChange, value, name } }) => (
								<TextField
									fullWidth
									id="companyLinkedinUrl"
									placeholder="Enter your LinkedIn Company Profile URL"
									value={value}
									name={name}
									sx={{ mt: 1.25, "& .MuiOutlinedInput-notchedOutline": { borderColor: "#DDDDDD" } }}
									InputProps={{
										style: {
											borderRadius: "30px",
											height: "60px",
											fontWeight: "500",
											fontSize: "16px",
											lineHeight: "24px",
											padding: "1.5px 6px",
										},
									}}
									onChange={onChange}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<Typography variant="poppinsSemiBold18">Funding Raised to Date*</Typography>
						<Controller
							name="capitalRaised"
							control={control}
							render={({ field: { onChange, value, name } }) => (
								<NumericFormat
									fullWidth
									placeholder="Enter your Funding Raised to Date"
									value={value ? Number(value) : null}
									customInput={TextField}
									type="text"
									thousandSeparator={true}
									prefix={"$"}
									sx={{
										mt: 1.25,
										"& .MuiOutlinedInput-notchedOutline": {
											border: errors.capitalRaised ? "5px solid rgba(240, 68, 56, 0.3) !important" : "1px solid #DDDDDD",
										},
										"& .MuiInputBase-root": {
											borderRadius: "30px",
											height: "60px",
											fontWeight: "500",
											fontSize: "16px",
											lineHeight: "24px",
											padding: "1.5px 6px",
										},
									}}
									onValueChange={(e) => onChange((e.floatValue || 0).toString())}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<Typography variant="poppinsSemiBold18">Select Accelerator/Incubator Previously Attended*</Typography>
						<Controller
							name="incubator"
							control={control}
							render={({ field: { onChange, value, name } }) => (
								<Select
									fullWidth
									value={value}
									name={name}
									onChange={onChange}
									IconComponent={SelectIconComponent}
									MenuProps={MenuProps}
									sx={{
										mt: 1.25,
										"& .MuiOutlinedInput-notchedOutline": { borderColor: "#DDDDDD" },
										"& .MuiSelect-select": { fontWeight: "500", fontSize: "16px", lineHeight: "24px" },
										padding: "1.5px 6px",
										borderRadius: "40px",
									}}
								>
									<MenuItem value="none">None</MenuItem>
									{ATTENDED_OPTIONS.map((option) => (
										<MenuItem key={option} value={option}>
											{option}
										</MenuItem>
									))}
								</Select>
							)}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<Typography variant="poppinsSemiBold18">Ask amount*</Typography>
						<Controller
							name="ask"
							control={control}
							render={({ field: { onChange, value } }) => (
								<NumericFormat
									fullWidth
									placeholder="Enter your Funding Raised to Date"
									value={value ? Number(value) : null}
									customInput={TextField}
									type="text"
									thousandSeparator={true}
									prefix={"$"}
									sx={{
										mt: 1.25,
										"& .MuiOutlinedInput-notchedOutline": {
											border: errors.capitalRaised ? "5px solid rgba(240, 68, 56, 0.3) !important" : "1px solid #DDDDDD",
										},
										"& .MuiInputBase-root": {
											borderRadius: "30px",
											height: "60px",
											fontWeight: "500",
											fontSize: "16px",
											lineHeight: "24px",
											padding: "1.5px 6px",
										},
									}}
									onValueChange={(e) => onChange((e.floatValue || 0).toString())}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<Typography variant="poppinsSemiBold18">MVP Launched?*</Typography>
						<Controller
							name="mvpLaunched"
							control={control}
							render={({ field: { onChange, value, name } }) => (
								<Select
									fullWidth
									value={value}
									name={name}
									onChange={onChange}
									IconComponent={SelectIconComponent}
									sx={{
										mt: 1.25,
										"& .MuiOutlinedInput-notchedOutline": { borderColor: "#DDDDDD" },
										"& .MuiSelect-select": { fontWeight: "500", fontSize: "16px", lineHeight: "24px" },
										padding: "1.5px 6px",
										borderRadius: "40px",
									}}
								>
									{["Yes", "No"].map((option) => (
										<MenuItem key={option} value={option}>
											{option}
										</MenuItem>
									))}
								</Select>
							)}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<Typography variant="poppinsSemiBold18">Are you generating revenue?*</Typography>
						<Controller
							name="revenueModel"
							control={control}
							render={({ field: { onChange, value, name } }) => (
								<Select
									fullWidth
									value={value}
									name={name}
									onChange={onChange}
									IconComponent={SelectIconComponent}
									MenuProps={MenuProps}
									sx={{
										mt: 1.25,
										"& .MuiOutlinedInput-notchedOutline": { borderColor: "#DDDDDD" },
										"& .MuiSelect-select": { fontWeight: "500", fontSize: "16px", lineHeight: "24px" },
										padding: "1.5px 6px",
										borderRadius: "40px",
									}}
								>
									{["Yes", "No"].map((option) => (
										<MenuItem key={option} value={option}>
											{option}
										</MenuItem>
									))}
								</Select>
							)}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<Typography variant="poppinsSemiBold18">Level of Incorporation*</Typography>
						<Controller
							name="incorporatedStatus"
							control={control}
							render={({ field: { onChange, value, name } }) => (
								<Select
									fullWidth
									value={value}
									name={name}
									onChange={onChange}
									IconComponent={SelectIconComponent}
									sx={{
										mt: 1.25,
										"& .MuiOutlinedInput-notchedOutline": { borderColor: "#DDDDDD" },
										"& .MuiSelect-select": { fontWeight: "500", fontSize: "16px", lineHeight: "24px" },
										padding: "1.5px 6px",
										borderRadius: "40px",
									}}
								>
									{INCORPORATED_LIST.map((option) => (
										<MenuItem key={option.value} value={option.value}>
											{option.name}
										</MenuItem>
									))}
								</Select>
							)}
						/>
						{/* <Box display="flex" alignItems="flex-start" gap={1.25} mt={2.5}>
							<IconButton sx={{ p: 0 }} onClick={() => window.open("https://get.firstbase.io/startupos", "_blank")}>
								<img src={OpenLinkIcon} alt="Open Link" />
							</IconButton>
							<Box>
								<Link target="_blank" underline="none" href="https://get.firstbase.io/startupos">
									<Typography variant="count_title" color={"#7B61FF"}>
										Click here to Incorporate now with Firstbase.io. &nbsp;
									</Typography>
								</Link>
								<Typography variant="subhead2">
									Use Coupon Code <Typography variant="count_title">“PARTNER10”</Typography> to receive a 10% discount
								</Typography>
							</Box>
						</Box> */}
					</Grid>
					<Grid item xs={12} md={6}>
						<Typography variant="poppinsSemiBold18">Business Model Type*</Typography>
						<Controller
							name="businessModel"
							control={control}
							render={({ field: { onChange, value, name } }) => (
								<Select
									fullWidth
									value={value}
									name={name}
									onChange={onChange}
									IconComponent={SelectIconComponent}
									MenuProps={MenuProps}
									sx={{
										mt: 1.25,
										"& .MuiOutlinedInput-notchedOutline": { borderColor: "#DDDDDD" },
										"& .MuiSelect-select": { fontWeight: "500", fontSize: "16px", lineHeight: "24px" },
										padding: "1.5px 6px",
										borderRadius: "40px",
									}}
								>
									{businessTypeList.map((option) => (
										<MenuItem key={option} value={option}>
											{option}
										</MenuItem>
									))}
								</Select>
							)}
						/>
					</Grid>
					<Grid
						item
						xs={12}
						md={6}
						sx={(theme) => ({
							display: "block",
							[theme.breakpoints.down("md")]: {
								display: "none",
							},
						})}
					/>
				</Grid>
			</form>
		</OnboardContainer>
	);
};

export const SelectIconComponent = () => (
	<KeyboardArrowDownRoundedIcon
		sx={{
			position: "absolute",
			right: "16px",
			top: "calc(50% - 0.5em)",
			color: "rgba(0, 0, 0, 0.54)",
			pointerEvents: "none",
		}}
	/>
);
