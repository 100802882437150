import services from "../../../../services";

function processProbSolPowerUP(data) {
	try {
		const completedData = data.filter((item) => item.isCompleted);

		if (!completedData) {
			return null;
		}

		//	[
		//		{
		//			"id": 3433,
		//			"moduleStep": {
		//				"id": 25,
		//				"stepNo": 1,
		//				"title": "Your Idea",
		//				"description": "Begin the PowerUp by stating your idea and who benefits from this idea as simple as possible. This is followed by some light brainstorming where you'll list some words that specify your idea, and we'll take it from there to generate some usable insights that will create a picture of the product's desirability, viability, and competitive advantages. ",
		//				"stepUuid": "your-idea"
		//			},
		//			"createdAt": "2022-10-20T21:53:31.215+00:00",
		//			"answers": "{\"idea\":\"Pillows For Hamsters\",\"customer\":[\"A paying one\"],\"category\":\"Pets & Animals\",\"type\":\"Product\",\"topicTags\":[\"Pillows\",\"Hamsters\"]}",
		//			"isLastStep": false,
		//			"isCompleted": true
		//		},
		//		{
		//			"id": 3435,
		//			"moduleStep": {
		//				"id": 27,
		//				"stepNo": 3,
		//				"title": "Solution",
		//				"description": "<b>What are the solutions?</b><br/><br/>Know that it is fairly common for your customer problems to get reprioritized or completely replaced with new ones after just a few customer conversations. For this reason, we recommend not getting carried away with fully defining your solution just yet.<br/><br/><b>Describe the simplest thing you could possibly build to address each problem listed.</b> You can have more than one solution for a problem.",
		//				"stepUuid": "solution"
		//			},
		//			"createdAt": "2022-10-20T21:54:14.302+00:00",
		//			"answers": "{\"solution1\":[\"Pillows small enough for hamsters\",\"Solution A #2\",\"Soution A #3\",\"Solution A #4\"],\"solution2\":[\"Solution # 2\",\"Solution B #2\"],\"solution3\":[\"Solution # 3\",\"Solution C #2\",\"Solution C #3\"]}",
		//			"isLastStep": true,
		//			"isCompleted": true
		//		},
		//		{
		//			"id": 3434,
		//			"moduleStep": {
		//				"id": 26,
		//				"stepNo": 2,
		//				"title": "Problem",
		//				"description": "<b>Top customer problems, symptoms, and existing alternatives</b><br/><br/>While it’s tempting to brainstorm and describe many possible problems, <b>prioritize the top one to three issues</b> that you believe are most pressing for your customers. A problem's <b>symptom</b> is an indication that something didn't work out quite as expected. If there is an <b>existing alternative</b> to the problem that exists today, describe it as well.<br/><br/>If you need help, check out <b>Power Tips in the Knowledge bar</b> for some examples.",
		//				"stepUuid": "problem"
		//			},
		//			"createdAt": "2022-10-20T21:53:58.772+00:00",
		//			"answers": "{\"problems\":[{\"problem\":\"Lack of pillows for hamsters\",\"symptoms\":\"Restless hamsters\",\"existingAlternatives\":\"Large pillows\"},{\"problem\":\"Other Problem\",\"symptoms\":\"Other Sympt\",\"existingAlternatives\":\"Other Alt\"},{\"problem\":\"ANother Prob\",\"symptoms\":\"Another Sympt\",\"existingAlternatives\":\"Another Alt\"}]}",
		//			"isLastStep": false,
		//			"isCompleted": true
		//		}
		//	]
		//]
		// JSON.parse(obj?.moduleStepResponses?.find((responseObj) => responseObj.moduleStep.stepNo === 1)?.answers)?.avatar,
		let PSMap = completedData?.map((obj) => {
			const idea = JSON.parse(obj?.moduleStepResponses?.find((responseObj) => responseObj.moduleStep.stepNo === 1)?.answers)?.idea;
			const customer = JSON.parse(obj?.moduleStepResponses?.find((responseObj) => responseObj.moduleStep.stepNo === 1)?.answers)?.customer;
			const category = JSON.parse(obj?.moduleStepResponses?.find((responseObj) => responseObj.moduleStep.stepNo === 1)?.answers)?.category;
			const type = JSON.parse(obj?.moduleStepResponses?.find((responseObj) => responseObj.moduleStep.stepNo === 1)?.answers)?.type;
			const topicTags = JSON.parse(obj?.moduleStepResponses?.find((responseObj) => responseObj.moduleStep.stepNo === 1)?.answers)?.topicTags;

			const problems = JSON.parse(obj?.moduleStepResponses?.find((responseObj) => responseObj.moduleStep.stepNo === 2)?.answers)?.problems;

			const probSol = problems?.map((prob, index) => {
				let solution = JSON.parse(obj?.moduleStepResponses?.find((responseObj) => responseObj.moduleStep.stepNo === 3)?.answers)[
					`solution${index + 1}`
				];
				return {
					problem: prob,
					solution: solution,
				};
			});

			return { idea, customer, category, type, topicTags, probSol };
		});
		return PSMap;
	} catch (e) {
		console.log("Function error when attempting to get the latest complete Problem Solution Fit PowerUP data for company. Failed with error: ", e);
		return {};
	}
}

export function getProbSolPowerUP(companyID) {
	return services
		.getProbSolPowerUPData(companyID)
		.then((resp) => {
			if (resp?.data?.data) {
				return processProbSolPowerUP(resp?.data?.data);
			}
		})
		.catch((e) => {
			console.log("API error when attempting to get TAM SAM SOM PowerUP data for company. Failed with error: ", e);
		});
}
