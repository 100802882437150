import React from "react";
import { Grid, Button, Box, Stack, Typography } from "@mui/material";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { useHistory } from "react-router-dom";
import ROUTES_PATH from "../../constants/routes";

export default function VerifyEmailVerifcation() {
  const history = useHistory();

  const handleLoginBack = () => {
    history.push(ROUTES_PATH.LOG_IN);
  };

  return (
    <Stack alignItems="center" justifyContent="center">
      <Grid container alignItems="center" justifyContent="center">
        <Grid item xs={12} sm={10} md={6} lg={6} sx={{ px: 2 }}>
          <Box display="grid" justifyContent="center" sx={{ mb: 4 }}>
            <MailOutlineIcon
              sx={{
                border: "1px solid",
                borderRadius: "50%",
                p: 1,
                color: "success.main",
              }}
            ></MailOutlineIcon>
          </Box>
          <Box display="grid" justifyContent="center">
            <Typography variant="headline1" sx={{ mb: 1 }}>
              Verify Your Email
            </Typography>
          </Box>
          <Box display="grid" sx={{ textAlign: "center" }}>
            <Typography variant="subhead1" sx={{ my: 3 }}>
              Verification email has been shared on the email <br />
              {sessionStorage.getItem("email")}.
            </Typography>

            <Typography variant="subhead1">
              Please check your mailbox for the confirmation email, where you'll
              find a link to get started. If you can't find the email, click the
              button below to resend.
            </Typography>
          </Box>
          <Box textAlign="center">
            <Button sx={{ my: 4, maxWidth: "100%" }} onClick={handleLoginBack}>
              Return to login
            </Button>
            <Button variant="nofill" sx={{ width: "max-content" }}>
              Resend email
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Stack>
  );
}
