import React, { useState, useEffect } from "react";
import { Box, Button, Chip, Divider, Grid, Typography, MenuItem } from "@mui/material";
import DialogComponent from "../../../containers/Workspace/PowerUP/IdeaValidation/Components/Dialog";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import LoadingButton from "../../../common/Loadingbutton";
import AutocompleteInput from "../../../common/AutocompleteInput";
import messages from "../../../constants/messages";
import theme from "../../../constants/Theme";
import services from "../../../services";
import { customerTagValid } from "../../../containers/Workspace/PowerUP/ProblemSolution/functions/common";
import { REFERENCE_ICON } from "../../../constants/image";
import AutoCompleteCustomRef from "../AutocompleteCustomRef";

const AddReferencePopUp = ({
	showReferencePopUp,
	setShowReferencePopUp,
	curatorId,
	onClose,
	getCardDetails,
	researchCuratorDetails,
	setCallback,
	noteId,
	selectedReferences,
	open,
	setResearchCuratorIdValue,
	isQuikNote = false,
	setCallBackFromMenu = () => {},
}) => {
	const [userInputs, setUserInputs] = useState({ references: [] });
	const [loader, setLoader] = useState(false);
	const [helpText, setHelpText] = useState(messages.MULTI_TAG_SELECT);
	const [autoCompleteData, setAutoCompleteData] = useState([]);
	const [referenceList, setReferenceList] = useState([]);
	const [referencesDetails, setReferencesDetails] = useState();

	useEffect(() => {
		getReferencesApiCall();
	}, [open]);

	const getReferencesApiCall = () => {
		services
			.getResearchCuratorReferenceList()
			.then((response) => {
				if (response.data.code === 200) {
					setAutoCompleteData(response.data?.data);
					setReferenceList(response.data?.data);
					initialReferenceHandler();
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const handleSendReferences = () => {
		let filteredList = userInputs.references.map((x) => ({
			...x,
			id: undefined,
		}));

		let refPayload = {
			references: filteredList,
		};

		if (noteId) {
			setLoader(true);
			services
				.updateReferencesApiCall(refPayload, noteId)
				.then((response) => {
					setLoader(false);
					if (response.data.code === 200) {
						setReferencesDetails(response?.data?.data);
						setCallBackFromMenu(true);

						setCallback(true);
						onClose();
					}
				})
				.catch((error) => {
					setLoader(false);
					console.log(error);
				});
			setShowReferencePopUp(false);
		} else {
			setLoader(true);
			const payload = {
				title: "",
				description: "",
				noteType: isQuikNote ? "QUICK_NOTE" : "NOTE",
				isPinned: false,
			};
			services
				.addResearchCuratorApiCall(payload)
				.then((res) => {
					setResearchCuratorIdValue(res?.data?.data?.id);
					services
						.updateReferencesApiCall(refPayload, res?.data?.data?.id)
						.then((response) => {
							setLoader(false);
							if (response.data.code === 200) {
								setReferencesDetails(response?.data?.data);
								setCallBackFromMenu(true);
								setCallback(true);
								onClose();
							}
						})
						.catch((error) => {
							setLoader(false);
							console.log(error);
						});
					setShowReferencePopUp(false);
				})
				.catch((e) => {
					console.log("Submit", e);
				});
		}
	};

	const onChangeHandler = (e, key) => {
		const value = e.target.value;
		const currentInput = { ...userInputs };
		currentInput[key] = value;
		setUserInputs(currentInput);
		for (const key of value) {
			const filterOptions = autoCompleteData.filter((item) => item.entityName !== key);
			setAutoCompleteData(filterOptions);
		}
	};

	const initialReferenceHandler = () => {
		let key = "references";
		const initialReferenceOptions = selectedReferences;
		const currentInputs = { ...userInputs };
		currentInputs[key] = initialReferenceOptions;
		setUserInputs(currentInputs);
	};

	return (
		<Box>
			<DialogComponent
				isOpen={open}
				onClose={onClose}
				title={"Add Reference"}
				titleVariant={"title_large"}
				contentStyle={{ px: 0 }}
				maxWidth={"xs"}
				fullWidth
				PaperProps={{
					sx: (theme) => ({ borderRadius: theme.shape.standard.borderRadius }),
				}}
				dialogAction={
					<Box
						sx={{
							mr: 2,
							display: "flex",
							[theme.breakpoints.down("md")]: {
								mr: 0,
								display: "contents",
							},
						}}
					>
						<LoadingButton onClick={() => handleSendReferences()} loading={loader} loadingStyles={{ px: 3 }}>
							Done
						</LoadingButton>
					</Box>
				}
				disableBackDropClick
			>
				<Grid container alignItems={"top"} sx={{ px: 3 }} spacing={2}>
					<Grid item sm={12} xs={12} md={12} lg={12} xl={12} sx={{ flex: 1 }}>
						<AutoCompleteCustomRef
							label={"Enter Reference"}
							handleInputChange={onChangeHandler}
							inputKey={"references"}
							options={autoCompleteData}
							inputValid={customerTagValid}
							inputValue={userInputs ? [...userInputs?.references] : []}
							errorMessage={messages.MULTITAG_INPUT_INVALID}
							sx={{ mt: 2 }}
						/>
						{/*<AutocompleteInput
              label={"Enter Reference"}
              handleInputChange={onChangeHandler}
              inputKey={"references"}
              autoCompleteData={autoCompleteData}
              inputValid={customerTagValid}
              inputValue={userInputs ? [...userInputs?.references] : []}
              errorMessage={messages.MULTITAG_INPUT_INVALID}
              sx={{ mt: 2 }}
              typingEnabled
              multiple
              fullWidth
              handleOnBlurEvent={false}
              isReferenceField="true"
              helpText={helpText}
              getOptionLabel={option => option.entityName}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  <img loading="lazy" width="20" src={REFERENCE_ICON} alt="" />
                  {option.entityName}
                </Box>
              )}
              handleEnter={false}
            />*/}
					</Grid>
				</Grid>
			</DialogComponent>
		</Box>
	);
};

export default AddReferencePopUp;
