import React, { useState, useEffect, useRef } from "react";
import { Box } from "@mui/material";
import MUIRichTextEditor from "mui-rte";
import { stateToMarkdown } from "draft-js-export-markdown";
import { stateFromMarkdown } from "draft-js-import-markdown";
import { convertToRaw, EditorState } from "draft-js";
import { createTheme, ThemeProvider } from "@mui/material/styles";

export default function RichTextEditor({
  label,
  value,
  toolbar,
  inlineToolbar,
  inlineToolbarControls,
  controls,
  readOnly,
  onSave,
  onChange,
  setNotes,
  triggerFocus = false,
  defaultTrigger = true,
  minHeight = "100%",
  showAutoFocus = false,
  ...props
}) {
  const [initial, setInitial] = useState("");

  useEffect(() => {
    if (!initial) {
      let init_state;
      if (value) {
        init_state = EditorState.createWithContent(stateFromMarkdown(value));
      }
      if (init_state) {
        setInitial(
          JSON.stringify(convertToRaw(init_state.getCurrentContent()))
        );
      }
    }
  }, [value]);

  const inputRef = useRef();

  const focusAdd = () => {
    inputRef?.current?.focus();
  };

  useEffect(() => {
    if (showAutoFocus) {
      focusAdd();
      window.scrollTo(0, 0);
    }
  }, [showAutoFocus]);

  const myTheme = createTheme({
    // Set up your custom MUI theme here
  });
  return (
    <ThemeProvider theme={myTheme}>
      <Box sx={{ minHeight: minHeight }} onClick={focusAdd}>
        <MUIRichTextEditor
          onChange={data => {
            onChange(data);
          }}
          label={readOnly ? "" : label}
          value={initial}
          toolbar={toolbar}
          inlineToolbar={inlineToolbar}
          inlineToolbarControls={inlineToolbarControls}
          controls={controls}
          draftEditorProps={{ spellCheck: true }}
          readOnly={readOnly}
          onSave={onSave}
          ref={inputRef}
          {...props}
        />
      </Box>
    </ThemeProvider>
  );
}
