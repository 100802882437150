export const steps = [
  {
    label: "My Role",
    complete: false,
  },
  {
    label: "Company Name",
    complete: true, // Needs to be false for prod
  },
  {
    label: "About Me",
    complete: false,
  },
  {
    label: "Industry Focus",
    complete: false,
  },
  {
    label: "Skills and Expertise",
    complete: false,
  },
];
