import React, { useMemo, useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Typography, TextField, InputAdornment, IconButton, Select, MenuItem, Checkbox, ListItemText, OutlinedInput, Grid } from "@mui/material";
import { PatternFormat } from "react-number-format";
// import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
// import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
// import moment from "moment";
import "react-phone-number-input/style.css";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";

import { OnboardContainer } from "../ui";
import { FOUNDER_GOAL_OPTIONS, MENTOR_GOAL_OPTIONS, INVESTOR_GOAL_OPTIONS } from "../Options";
import { SelectIconComponent } from "./StartupInfo";
import services from "../../../../services";
import { USER_TYPES } from "../../../../utils/userTypes";
import messages from "../../../../constants/messages";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

const schema = yup
	.object({
		country: yup.string().required("Country is required"),
		state: yup.string().required("State/Province is required"),
		city: yup.string().required("City is required"),
		zipcode: yup
			.string()
			.when("country", (country, schema) =>
				country === "CA"
					? schema.test("postal-code", "Postal Code is wrong", (value) =>
							value?.length <= 3 ? /^[A-Za-z]\d[A-Za-z]$/.test(value) : /^[A-Za-z]\d[A-Za-z] ?\d[A-Za-z]\d$/.test(value)
					  )
					: yup.string().matches(/(^\d{5}$)|(^\d{5}-\d{4}$)/, "Zip Code is wrong")
			),
		phone: yup.string(),
		// dateOfBirth: yup.string().nullable(),
		userLinkedinUrl: yup.string(),
		goals: yup.array(),
	})
	.required();

export const AboutMe = ({ userType, isNext, currentStep, data, onActive, onUpdate }) => {
	const [currentCountry, setCurrentCountry] = useState("USA");
	const [isOpen, setIsOpen] = useState(false);
	const [countryStateData, setCountryStateData] = useState([]);
	const [goalOptions, setGoalOptions] = useState([]);

	const handleOnclick = () => {
		if (isOpen === false) {
			setIsOpen(true);
		}
	};

	const {
		setValue,
		getValues,
		control,
		formState: { errors, isValid },
	} = useForm({
		mode: "all",
		defaultValues: {
			country: "us",
			state: "",
			city: "",
			zipcode: "",
			phone: "",
			// dateOfBirth: null,
			userLinkedinUrl: "https://www.linkedin.com/in/",
			goals: [],
		},
		resolver: yupResolver(schema),
	});

	useEffect(() => {
		getCountryStateData();
	}, []);

	useEffect(() => {
		if (userType === USER_TYPES.STARTUP_OWNER || userType === USER_TYPES.STARTUP_USER) {
			setGoalOptions(FOUNDER_GOAL_OPTIONS);
		} else if (userType === USER_TYPES.MENTOR_USER) {
			setGoalOptions(MENTOR_GOAL_OPTIONS);
		} else if (userType === USER_TYPES.INVESTOR_USER) {
			setGoalOptions(INVESTOR_GOAL_OPTIONS);
		}
	}, [userType]);

	useEffect(() => {
		setValue("country", data?.country || "us");
		setValue("state", data?.state || "");
		setValue("city", data?.city || "");
		setValue("zipcode", data?.zipcode || "");
		setValue("phone", data?.phone || "");
		// setValue("dateOfBirth", data?.dateOfBirth || null);
		setValue("userLinkedinUrl", data?.userLinkedinUrl || "https://www.linkedin.com/in/");
		setValue("goals", data?.goals ? JSON.parse(data?.goals) : []);
		setCurrentCountry(data?.country || "USA");
	}, [data]);

	useEffect(() => {
		if (isValid) {
			onActive(true);
		} else {
			onActive(false);
		}
	}, [isValid]);

	useEffect(() => {
		if (isNext) {
			onUpdate(currentStep, {
				country: getValues("country"),
				state: getValues("state"),
				city: getValues("city"),
				zipcode: getValues("zipcode"),
				phone: getValues("phone"),
				// dateOfBirth: getValues("dateOfBirth"),
				userLinkedinUrl: getValues("userLinkedinUrl"),
				goals: JSON.stringify(getValues("goals")),
			});
		}
	}, [isNext]);

	const getCountryStateData = async () => {
		const res = await services.getListOfCountryStateApi();
		setCountryStateData(res?.data?.data);
		setCurrentCountry(res?.data?.data[0]?.countryCode || "USA");
	};

	const handleCountry = (country, onChange) => {
		setCurrentCountry(country);
		onChange(country);
	};

	const handleGoal = (goal, onChange) => {
		if (userType === USER_TYPES.STARTUP_OWNER) {
			onChange([goal]);
		} else {
			onChange(goal);
		}
	};

	const getOptions = useMemo(() => {
		if (countryStateData.length > 0) {
			return countryStateData.filter((item) => item.countryCode === currentCountry)[0].states;
		}

		return [];
	}, [countryStateData, currentCountry]);
	console.log(getValues("zipcode").length,"check")

	return (
		<OnboardContainer title="About Me">
			<form>
				<Grid container spacing="40px">
					<Grid item xs={12} md={6}>
						<Typography variant="poppinsSemiBold18">What is your goal in joining StartupOS?</Typography>
						<Box mt={1.25}>
							<Typography variant="subhead2">Select one or more from the list below</Typography>
						</Box>
						<Controller
							name="goals"
							control={control}
							render={({ field: { onChange, value, name } }) => (
								<Select
									fullWidth
									multiple={userType === USER_TYPES.STARTUP_OWNER ? false : true}
									value={value}
									name={name}
									onChange={(e) => handleGoal(e.target.value, onChange)}
									renderValue={(selected) => (userType === USER_TYPES.STARTUP_OWNER ? selected : selected.join(", "))}
									IconComponent={SelectIconComponent}
									input={<OutlinedInput />}
									sx={{
										mt: 1.25,
										borderRadius: "40px",
										"& .MuiOutlinedInput-notchedOutline": { borderColor: "#DDDDDD" },
										"& .MuiSelect-select": { fontWeight: "500", fontSize: "16px", lineHeight: "24px" },
										padding: "1.5px 6px",
									}}
								>
									{goalOptions.map((option) => (
										<MenuItem key={option.label} value={option.label}>
											{userType !== USER_TYPES.STARTUP_OWNER && <Checkbox checked={value?.indexOf(option.label) > -1} />}
											<ListItemText primary={option.label} />
										</MenuItem>
									))}
								</Select>
							)}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<Typography variant="poppinsSemiBold18">LinkedIn Profile URL</Typography>
						<Box mt={1.25}>
							<Typography variant="subhead2">Fill in your profile faster and unlock additional features</Typography>
						</Box>
						<Controller
							name="userLinkedinUrl"
							control={control}
							render={({ field: { onChange, value, name } }) => (
								<TextField
									fullWidth
									id="userLinkedinUrl"
									placeholder="Enter your LinkedIn Profile URL"
									value={value}
									name={name}
									sx={{ mt: 1.25, "& .MuiOutlinedInput-notchedOutline": { borderColor: "#DDDDDD" } }}
									InputProps={{
										style: {
											borderRadius: "30px",
											height: "60px",
											fontWeight: "500",
											fontSize: "16px",
											lineHeight: "24px",
											padding: "1.5px 6px",
										},
									}}
									onChange={onChange}
								/>
							)}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						<Typography variant="poppinsSemiBold18">Country*</Typography>
						<Controller
							name="country"
							control={control}
							render={({ field: { onChange, value, name } }) => (
								<Select
									fullWidth
									value={value}
									name={name}
									onChange={(e) => handleCountry(e.target.value, onChange)}
									IconComponent={SelectIconComponent}
									sx={{
										mt: 1.25,
										borderRadius: "40px",
										"& .MuiOutlinedInput-notchedOutline": { borderColor: "#DDDDDD" },
										"& .MuiSelect-select": { fontWeight: "500", fontSize: "16px", lineHeight: "24px" },
										padding: "1.5px 6px",
									}}
								>
									{countryStateData.map((option) => (
										<MenuItem key={option.countryCode} value={option.countryCode}>
											{option.countryName}
										</MenuItem>
									))}
								</Select>
							)}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<Typography variant="poppinsSemiBold18">State/Province*</Typography>
						<Controller
							name="state"
							control={control}
							render={({ field: { onChange, value, name } }) => (
								<Select
									fullWidth
									value={value}
									name={name}
									onChange={onChange}
									IconComponent={SelectIconComponent}
									MenuProps={MenuProps}
									sx={{
										mt: 1.25,
										borderRadius: "40px",
										"& .MuiOutlinedInput-notchedOutline": { borderColor: "#DDDDDD" },
										"& .MuiSelect-select": { fontWeight: "500", fontSize: "16px", lineHeight: "24px" },
										padding: "1.5px 6px",
									}}
								>
									{getOptions.map((option) => (
										<MenuItem key={option.stateCode} value={option.stateCode}>
											{option.stateName}
										</MenuItem>
									))}
								</Select>
							)}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<Typography variant="poppinsSemiBold18">City*</Typography>
						<Controller
							name="city"
							control={control}
							render={({ field: { onChange, value, name } }) => (
								<TextField
									spellCheck="true"
									fullWidth
									value={value}
									name={name}
									placeholder={"City"}
									onChange={onChange}
									InputProps={{
										style: {
											borderRadius: "30px",
											height: "60px",
											fontWeight: "500",
											fontSize: "16px",
											lineHeight: "24px",
											padding: "1.5px 6px",
										},
										startAdornment: errors.city ? (
											<InputAdornment position="end">
												<IconButton>
													<ErrorOutlineOutlinedIcon sx={{ color: "#F04438" }} />
												</IconButton>
											</InputAdornment>
										) : null,
									}}
									sx={{
										mt: 1.25,
										"& .MuiOutlinedInput-notchedOutline": {
											border: errors.city ? "5px solid rgba(240, 68, 56, 0.3) !important" : "1px solid #DDDDDD",
										},
									}}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<Typography variant="poppinsSemiBold18">Zip Code/Postal Code*</Typography>
						<Controller
							name="zipcode"
							control={control}
							render={({ field: { onChange, value, name } }) => (
								<TextField
									fullWidth
									id="zipcode"
									placeholder="Enter your Zip Code/Postal Code"
									value={value.toUpperCase()}
									name={name}
									sx={{
										mt: 1.25,
										"& .MuiOutlinedInput-notchedOutline": {
											border: errors.zipcode ? "5px solid rgba(240, 68, 56, 0.3) !important" : "1px solid #DDDDDD",
										},
									}}
									InputProps={{
										style: {
											borderRadius: "30px",
											height: "60px",
											fontWeight: "500",
											fontSize: "16px",
											lineHeight: "24px",
											padding: "1.5px 6px",
										},
										startAdornment: errors.zipcode ? (
											<InputAdornment position="end">
												<IconButton>
													<ErrorOutlineOutlinedIcon sx={{ color: "#F04438" }} />
												</IconButton>
											</InputAdornment>
										) : null,
									}}
									onChange={onChange}
								/>
							)}
						/>
						{errors.zipcode && (
							<Box mt={1}>
								<Typography variant="Text/sm/Regular" sx={{ color: "error.main" }}>
									{getValues("zipcode") ? messages.LOCATION_INVALID : messages.ZIPCODE_BLANK}
								</Typography>
							</Box>
						)}
					</Grid>
					{userType !== USER_TYPES.INVESTOR_USER && (
						<Grid item xs={12} md={6}>
							<Typography variant="poppinsSemiBold18">Phone Number</Typography>
							<Controller
								name="phone"
								control={control}
								render={({ field: { onChange, value } }) => (
									<PatternFormat
										fullWidth
										placeholder="Enter your Phone Number"
										format="(###) ###-####"
										mask="_"
										value={value}
										customInput={TextField}
										sx={{
											mt: 1.25,
											"& .MuiOutlinedInput-notchedOutline": {
												border: errors.capitalRaised ? "5px solid rgba(240, 68, 56, 0.3) !important" : "1px solid #DDDDDD",
											},
											"& .MuiInputBase-root": {
												borderRadius: "30px",
												height: "60px",
												fontWeight: "500",
												fontSize: "16px",
												lineHeight: "24px",
												padding: "1.5px 6px",
											},
										}}
										onValueChange={(e) => onChange(e.formattedValue || "")}
									/>
								)}
							/>
						</Grid>
					)}
					{/* <Grid item xs={12} md={6}>
						<Typography variant="poppinsSemiBold18">Date of Birth</Typography>
						<Box onClick={handleOnclick}>
							<Controller
								name="dateOfBirth"
								control={control}
								render={({ field: { onChange, value, name } }) => (
									<LocalizationProvider dateAdapter={AdapterMoment}>
										<DatePicker
											open={isOpen}
											onClose={() => setIsOpen(false)}
											format="MM/DD/YYYY"
											value={value ? moment(value) : null}
											disableFuture={true}
											onChange={(newValue) => {
												onChange(moment(newValue).toDate());
											}}
											slots={{
												OpenPickerIcon: CalendarTodayOutlinedIcon,
											}}
											sx={{
												mt: 1.25,
												"& .MuiOutlinedInput-notchedOutline": { borderColor: "#DDDDDD" },
												"&.MuiTextField-root": { width: "100%" },
											}}
											slotProps={{
												openPickerButton: {
													sx: {
														mr: "5px",
													},
												},
												field: {
													InputProps: {
														endAdornment: (
															<InputAdornment position="end" sx={{ mr: 1.5 }}>
																<IconButton edge="end">
																	<CalendarTodayOutlinedIcon sx={{ color: "#000" }} />
																</IconButton>
															</InputAdornment>
														),
														variant: "outlined",
														sx: {
															borderRadius: "30px",
															height: "60px",
															fontWeight: "500",
															fontSize: "16px",
															lineHeight: "24px",
															padding: "1.5px 6px",
														},
													},
												},
											}}
										/>
									</LocalizationProvider>
								)}
							/>
						</Box>
					</Grid> */}
				</Grid>
			</form>
		</OnboardContainer>
	);
};
