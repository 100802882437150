import React, { useState, useEffect, useRef } from "react";
import MUIRichTextEditor from "mui-rte";
import { convertToRaw, EditorState, ContentBlock, CharacterMetadata, genKey, ContentState } from "draft-js";
import { List, Repeat } from "immutable";
import { stateFromHTML } from "draft-js-import-html";
import { stateToHTML } from "draft-js-export-html";
import { Box } from "@mui/material";
//import theme from "../../constants/Theme";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { convertHtmlToPlainText } from "../../utils/rte-utils";
import UnpublishedOutlinedIcon from "@mui/icons-material/UnpublishedOutlined";
import theme from "../../constants/Theme";
export default function ScriptEditor(props) {
	const {
		version,
		id,
		label,
		initialValue,
		onChange,
		readOnly,
		toolbar,
		inlineToolbar,
		controls,
		inlineToolbarControls,
		showValidatorIcon,
		onBlur = () => {},
		minHeight = "100%",
		...rest
	} = props;
	const [initial, setInitial] = useState("");
	const [plainText, setPlainText] = useState("");

	const inputRef = useRef();
	useEffect(() => {
		let init_state;
		if (Array.isArray(initialValue)) {
			//if initial value is array then render list of array items in unordered manner else render markdown
			const contentBlocksArray = initialValue.map((word) => {
				return new ContentBlock({
					key: genKey(),
					//type: "unordered-list-item",
					characterList: new List(Repeat(CharacterMetadata.create(), word.length)),
					text: word,
				});
			});

			init_state = EditorState.createWithContent(ContentState.createFromBlockArray(contentBlocksArray));
		} else {
			if (initialValue) {
				init_state = EditorState.createWithContent(stateFromHTML(initialValue));
			} else if (initialValue === "") {
				init_state = EditorState.createEmpty();
			}
		}
		if (init_state) {
			setInitial(JSON.stringify(convertToRaw(init_state.getCurrentContent())));
			handleScriptDataChange(init_state);
		}
	}, [version]);

	const handleScriptDataChange = (data) => {
		const inputVal = stateToHTML(data.getCurrentContent());
		let text = convertHtmlToPlainText(inputVal)
			.replace(/&nbsp;*/g, "")
			.trimStart();
		setPlainText(text);
		onChange({ key: id, value: inputVal.replace(/&nbsp;*/g, "") });
	};

	const handleFocus = () => {
		inputRef.current.focus();
	};

	Object.assign(theme, {
		overrides: {
			MUIRichTextEditor: {
				editor: {
					wordBreak: "break-word",
				},
			},
		},
	});

	return (
		<ThemeProvider theme={theme}>
			<Box sx={{ minHeight: minHeight }} onClick={() => handleFocus()}>
				<MUIRichTextEditor
					ref={inputRef}
					key={id}
					label={label}
					onChange={handleScriptDataChange}
					value={initial}
					toolbar={toolbar}
					inlineToolbar={inlineToolbar}
					inlineToolbarControls={inlineToolbarControls}
					controls={controls}
					readOnly={readOnly}
					//onFocus={() => onBlur()}
					onBlur={() => onBlur()}
					draftEditorProps={{ spellCheck: true }}
					customControls={
						showValidatorIcon && [
							{
								name: "my-block",
								icon: plainText?.length ? (
									<CheckCircleIcon sx={{ color: "success.main" }} />
								) : (
									<UnpublishedOutlinedIcon
										sx={{
											color: "warning.main",
										}}
									/>
								),
								type: "inline",
								inlineStyle: {
									float: "right",
								},
							},
						]
					}
					{...rest}
				></MUIRichTextEditor>
			</Box>
		</ThemeProvider>
	);
}
