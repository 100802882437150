import React, { Fragment, useEffect, useState } from "react";
import {
  Grid,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  IconButton,
  Box,
  Divider,
  TextField,
  TableFooter,
  Checkbox,
  Link,
  Popover,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  List,
  InputAdornment,
  Button,
  Paper,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import MuiTooltip from "../../../../../common/MuiTooltip";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import {
  riceScoreTooltip,
  reachTooltip,
  impactTooltip,
  confidenceTooltip,
  effortTooltip,
} from "../../../../../constants/powerup-data";
import SelectInput from "../../../../../common/SelectInputMvp";
import { TextFieldsOutlined } from "@mui/icons-material";
import CustomTooltipWrapper from "../../../../../common/CustomToolTip";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import FeatureDescriptionForm from "./FeatureDescriptionForm";
import SelectChipInput from "../../../../../common/SelectChip";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";

export default function CreateMatrixTable({
  answers,
  handleUpdateInput,
  moduleDetails,
  handleAddNewRow,
  newRow,
  setNewRow,
  setAnswers,
  handleDeleteRow,
  handleSortPopup,
  handleOnBlur,
}) {
  const [showDrag, setShowDrag] = useState("");
  const [openPopOver, setOpenPopOver] = useState(false);
  const [openfeatureDesctiption, setOpenFeatureDesctiption] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [solutions, setSolutions] = useState("");
  const [rowIndex, setRowIndex] = useState("");
  const [hoverIndex, setHoverIndex] = useState("");
  const [dragId, setDragId] = useState("");
  const [placeholder, setPlaceholder] = useState("+ Add New Row");

  const handleDrag = e => {
    setDragId(e.currentTarget.id);
  };

  const handleDrop = ev => {
    let clone = [...answers];

    const draggedQuestion = clone.find(
      ele => parseInt(ele.index) == parseInt(dragId)
    );
    const droppedQuestion = clone.find(
      ele => ele.index === parseInt(ev.currentTarget.id)
    );
    let draggedQuestionIndex = draggedQuestion
      ? clone.indexOf(draggedQuestion)
      : -1;
    let droppedQuestionIndex = droppedQuestion
      ? clone.indexOf(droppedQuestion)
      : -1;
    if (draggedQuestionIndex !== -1 || droppedQuestionIndex !== -1) {
      let temp = clone[draggedQuestionIndex];
      clone.splice(draggedQuestionIndex, 1);
      clone.splice(droppedQuestionIndex, 0, temp);
    }

    //console.log("sd", draggedQuestion);
    setAnswers(clone);
  };

  const handleHover = val => {
    setShowDrag(val);
  };

  const handlePopOver = event => {
    setAnchorEl(event.currentTarget);
    if (openPopOver) {
      setAnchorEl(null);
    }
    setOpenPopOver(!openPopOver);
  };

  const handleFeatureDesctiption = () => {
    if (!openfeatureDesctiption) {
      setOpenPopOver(!openPopOver);
    }
    setOpenFeatureDesctiption(!openfeatureDesctiption);
  };

  useEffect(() => {
    if (moduleDetails?.length) {
      let data = moduleDetails?.find(ele => ele?.moduleStep?.stepNo == 3);
      let answers = JSON.parse(data?.answers);
      setSolutions(
        Object.keys(answers)
          .map(k => answers[k])
          .flat()
      );
    }
  }, [moduleDetails]);

  return (
    <Fragment>
      <Paper
        sx={{
          maxWidth: "max-content",
        }}
        elevation={0}
      >
        {/*<Divider />*/}
        <TableContainer
          className="SOSShowHorizontalScroll"
          sx={{
            width: 1,
            overflow: "scroll",
            //horizontalScrollbarWidth: "auto",
            //scrollbarWidth: "auto",
          }}
        >
          <Table
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "max-content",
              overflow: "hidden",
              //horizontalScrollbarWidth: "auto",
              //scrollbarWidth: "auto",
            }}
            aria-label="a dense table"
            size="small"
          >
            <TableHead sx={{ width: 1 }}>
              <Divider />
              <TableRow
                sx={{
                  display: "flex",
                  py: 0,
                  my: 0,
                  borderTop: `1px solid ${theme =>
                    theme.palette.secondary.outline}`,
                }}
              >
                <TableCell sx={{ maxWidth: 10, px: { xl: 0 } }}>
                  {/* drag and drop */}
                </TableCell>
                <TableCell
                  align="left"
                  sx={theme => ({
                    display: "flex",
                    alignContent: "center",
                    alignItems: "center",
                    gap: 1,
                    color: theme.palette.secondary.outline,
                    minWidth: 120,
                    maxWidth: 150,
                    px: { xl: 4 },
                  })}
                >
                  <Typography
                    variant="overlineMedium"
                    sx={{ color: theme => theme.palette.secondary.outline }}
                  >
                    SOLUTION
                  </Typography>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      cursor: "pointer",
                      gap: 0,
                    }}
                    onClick={event => handleSortPopup("solution", event)}
                  >
                    <ArrowDropUpIcon fontSize={"small"} />
                    <ArrowDropDownIcon fontSize={"small"} />
                  </Box>
                </TableCell>
                <TableCell
                  sx={theme => ({
                    display: "flex",
                    alignContent: "center",
                    alignItems: "center",
                    gap: 1,
                    color: theme.palette.secondary.outline,
                    minWidth: 120,
                    maxWidth: 160,
                    px: { xl: 3 },
                  })}
                >
                  <Typography
                    variant="overlineMedium"
                    sx={{
                      color: theme => theme.palette.secondary.outline,
                    }}
                  >
                    FEATURE
                  </Typography>
                  <CustomTooltipWrapper
                    placement="top"
                    arrow={true}
                    text={
                      <React.Fragment>
                        <Typography color="inherit">
                          Features are used to rationalize solutions into
                          smaller components or chunks that can be built and
                          tested within a relatively short period of time.
                        </Typography>
                      </React.Fragment>
                    }
                  >
                    <InfoOutlinedIcon
                      sx={{ cursor: "pointer" }}
                      fontSize={"small"}
                    />
                  </CustomTooltipWrapper>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      cursor: "pointer",
                    }}
                    onClick={event => handleSortPopup("featureName", event)}
                  >
                    <ArrowDropUpIcon fontSize={"small"} />
                    <ArrowDropDownIcon fontSize={"small"} />
                  </Box>
                </TableCell>
                <TableCell
                  sx={theme => ({
                    display: "flex",
                    alignContent: "center",
                    alignItems: "center",
                    gap: 1,
                    color: theme.palette.secondary.outline,
                    minWidth: 140,
                    maxWidth: 160,
                    px: { xl: 3 },
                  })}
                >
                  <Typography
                    variant="overlineMedium"
                    sx={{ color: theme => theme.palette.secondary.outline }}
                  >
                    RICE SCORE
                  </Typography>
                  <CustomTooltipWrapper
                    placement="top"
                    arrow={true}
                    text={
                      <React.Fragment>
                        <Typography color="inherit">
                          The RICE score is calculated by multiplying your
                          Reach, Impact, and Confidence values together, and
                          then dividing that number by the Effort value.
                          <br />
                          <br />
                          Once you have your RICE scores, you can sort your
                          projects. Sort them in descending order to highlight
                          the highest RICE scores, or the projects that'll
                          generate the most value per time worked.
                        </Typography>
                      </React.Fragment>
                    }
                  >
                    <InfoOutlinedIcon
                      sx={{ cursor: "pointer" }}
                      fontSize={"small"}
                    />
                  </CustomTooltipWrapper>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      cursor: "pointer",
                    }}
                    onClick={event => handleSortPopup("riceScore", event)}
                  >
                    <ArrowDropUpIcon fontSize={"small"} />
                    <ArrowDropDownIcon fontSize={"small"} />
                  </Box>
                </TableCell>
                <TableCell
                  sx={theme => ({
                    display: "flex",
                    alignContent: "center",
                    alignItems: "center",
                    gap: 1,
                    color: theme.palette.secondary.outline,
                    minWidth: 100,
                    maxWidth: 120,
                    p: 1,
                    px: { xl: 3 },
                  })}
                  align="left"
                >
                  <Typography
                    variant="overlineMedium"
                    sx={{
                      color: theme => theme.palette.secondary.outline,
                    }}
                  >
                    REACH
                  </Typography>
                  <CustomTooltipWrapper
                    placement="top"
                    arrow={true}
                    text={
                      <React.Fragment>
                        <Typography color="inherit">
                          Reach should represent the number of customers this
                          project is likely to impact over a given time period.
                          This can be customers per month or quarter,
                          transactions per month, etc.
                          <br />
                          <br />
                          For example, if you expect your project will lead to
                          100 new customers within the next month, your reach
                          score is 100.
                          <br />
                          <br />
                          Allowable Values: Enter any whole number between 1 and
                          100,000.
                        </Typography>
                      </React.Fragment>
                    }
                  >
                    <InfoOutlinedIcon
                      sx={{ cursor: "pointer" }}
                      fontSize={"small"}
                    />
                  </CustomTooltipWrapper>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      cursor: "pointer",
                    }}
                    onClick={event => handleSortPopup("reach", event)}
                  >
                    <ArrowDropUpIcon fontSize={"small"} />
                    <ArrowDropDownIcon fontSize={"small"} />
                  </Box>
                </TableCell>
                <TableCell
                  sx={theme => ({
                    display: "flex",
                    alignContent: "center",
                    alignItems: "center",
                    gap: 1,
                    color: theme.palette.secondary.outline,
                    minWidth: 100,
                    maxWidth: 150,
                    p: 1,
                    px: { xl: 5 },
                  })}
                  align="left"
                >
                  <Typography
                    variant="overlineMedium"
                    sx={{ color: theme => theme.palette.secondary.outline }}
                  >
                    IMPACT
                  </Typography>
                  <CustomTooltipWrapper
                    placement="top"
                    arrow={true}
                    text={
                      <React.Fragment>
                        <Typography color="inherit">
                          Impact should express, in relative terms, how much the
                          feature will impact each customer that uses it. For
                          example, how much will the feature increase conversion
                          rate when a customer encounters it?
                          <br />
                          <br />
                          Allowable Values: Massive - 3x, High - 2x, Medium -
                          1x, Low - 0.5x, Minimal - 0.25x.
                        </Typography>
                      </React.Fragment>
                    }
                  >
                    <InfoOutlinedIcon
                      sx={{ cursor: "pointer" }}
                      fontSize={"small"}
                    />
                  </CustomTooltipWrapper>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      cursor: "pointer",
                    }}
                    onClick={event => handleSortPopup("impact", event)}
                  >
                    <ArrowDropUpIcon fontSize={"small"} />
                    <ArrowDropDownIcon fontSize={"small"} />
                  </Box>
                </TableCell>
                <TableCell
                  sx={theme => ({
                    display: "flex",
                    alignContent: "center",
                    alignItems: "center",
                    gap: 1,
                    color: theme.palette.secondary.outline,
                    minWidth: 130,
                    maxWidth: 150,
                    p: 1,
                    px: { xl: 1 },
                  })}
                >
                  <Typography
                    variant="overlineMedium"
                    sx={{ color: theme => theme.palette.secondary.outline }}
                  >
                    CONFIDENCE
                  </Typography>
                  <CustomTooltipWrapper
                    placement="top"
                    arrow={true}
                    text={
                      <React.Fragment>
                        <Typography color="inherit">
                          Confidence should express how confident you are about
                          the optimistic estimates for reach, impact and effort.
                          Be honest in your assessment and give additional
                          weight where hard evidence is available (research,
                          survey data, etc.). If you have data backing up your
                          reach estimate but your impact score represents more
                          of a gut feeling or anecdotal evidence, your
                          confidence score will help account for this. Generally
                          speaking,
                          <br /> data {`>`} opinion.
                        </Typography>
                      </React.Fragment>
                    }
                  >
                    <InfoOutlinedIcon
                      sx={{ cursor: "pointer" }}
                      fontSize={"small"}
                    />
                  </CustomTooltipWrapper>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      cursor: "pointer",
                    }}
                    onClick={event => handleSortPopup("confidence", event)}
                  >
                    <ArrowDropUpIcon fontSize={"small"} />
                    <ArrowDropDownIcon fontSize={"small"} />
                  </Box>
                </TableCell>
                <TableCell
                  sx={theme => ({
                    display: "flex",
                    alignContent: "center",
                    alignItems: "center",
                    gap: 1,
                    color: theme.palette.secondary.outline,
                    minWidth: 100,
                    maxWidth: 150,
                    p: 1,
                    px: { xl: 3 },
                  })}
                >
                  <Typography
                    variant="overlineMedium"
                    sx={{ color: theme => theme.palette.secondary.outline }}
                  >
                    EFFORT
                  </Typography>
                  <CustomTooltipWrapper
                    placement="top"
                    arrow={true}
                    text={
                      <React.Fragment>
                        <Typography color="inherit">
                          Effort should include the total amount of work (in
                          person months) required from all team members
                          (product, design, engineering) to deliver the feature.
                          Use whole numbers and minimum of half a month. Don’t
                          get into the weeds... this should be a very rough
                          estimate.
                          <br />
                          <br />
                          Allowable Values: 0.5 to 100,000
                        </Typography>
                      </React.Fragment>
                    }
                  >
                    <InfoOutlinedIcon
                      sx={{ cursor: "pointer" }}
                      fontSize={"small"}
                    />
                  </CustomTooltipWrapper>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      cursor: "pointer",
                    }}
                    onClick={event => handleSortPopup("effort", event)}
                  >
                    <ArrowDropUpIcon fontSize={"small"} />
                    <ArrowDropDownIcon fontSize={"small"} />
                  </Box>
                </TableCell>
                <TableCell
                  sx={theme => ({
                    display: "flex",
                    alignContent: "center",
                    alignItems: "center",
                    gap: 1,
                    color: theme.palette.secondary.outline,
                    minWidth: 150,
                    maxWidth: 180,
                    p: 1,
                    px: { xl: 3 },
                  })}
                >
                  <Typography
                    variant="overlineMedium"
                    sx={{ color: theme => theme.palette.secondary.outline }}
                  >
                    RECOMMENDATION
                  </Typography>
                  <CustomTooltipWrapper
                    placement="top"
                    arrow={true}
                    text={
                      <React.Fragment>
                        <Typography color="inherit">
                          Recommendation is the follow-up action you will take
                          on each feature after you’ve tested it within your MVP
                          and evaluated customer feedback from interviews and
                          surveys.
                        </Typography>
                      </React.Fragment>
                    }
                  >
                    <InfoOutlinedIcon
                      sx={{ cursor: "pointer" }}
                      fontSize={"small"}
                    />
                  </CustomTooltipWrapper>
                </TableCell>
                <TableCell
                  sx={theme => ({
                    display: "flex",
                    alignContent: "center",
                    alignItems: "center",
                    gap: 1,
                    color: theme.palette.secondary.outline,
                    minWidth: 80,
                    maxWidth: 100,
                    p: 1,
                    px: { xl: 2 },
                  })}
                >
                  <Typography
                    variant="overlineMedium"
                    sx={{
                      color: theme => theme.palette.secondary.outline,
                    }}
                  >
                    APPROVE
                  </Typography>
                  <CustomTooltipWrapper
                    placement="top"
                    arrow={true}
                    text={
                      <React.Fragment>
                        <Typography color="inherit">
                          Use Approve to signify which subset of features you
                          intend to deliver in your Minimum Viable Product
                          (MVP). Your MVP must solve a real problem for the user
                          but you don’t have to deliver everything at once. It
                          may make more sense to sequence the delivery of
                          features across multiple MVP iterations.
                        </Typography>
                      </React.Fragment>
                    }
                  >
                    <InfoOutlinedIcon
                      sx={{ cursor: "pointer" }}
                      fontSize={"small"}
                    />
                  </CustomTooltipWrapper>
                </TableCell>
                <TableCell sx={{ minWidth: 40, px: { xl: 2 } }}>
                  {/* more options icon */}
                </TableCell>
              </TableRow>
            </TableHead>

            {/* solutions */}
            <TableBody
              sx={{
                width: 1,
                p: 0,
                "&:last-child td, &:last-child th": { border: 0 },
              }}
            >
              {answers?.length ? (
                answers?.map((item, i) => {
                  return (
                    <TableRow
                      sx={{
                        display: "flex",
                        border: "none",
                        width: 1,
                        border: 0,
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                      onMouseOver={() => {
                        setHoverIndex(i);
                      }}
                      draggable
                      onDragOver={ev => {
                        ev.preventDefault();
                      }}
                      onDragStart={handleDrag}
                      onDrop={handleDrop}
                      id={item.index}
                      key={item.index}
                    >
                      <TableCell
                        align="left"
                        sx={{
                          "& hover": { cursor: "pointer" },
                          maxWidth: 10,
                          display: "flex",
                          alignItems: "center",
                          px: { xl: 0 },
                        }}
                        hover
                        onMouseOver={() => {
                          handleHover(true);
                        }}
                        onMouseLeave={() => handleHover(false)}
                      >
                        {showDrag && hoverIndex == i && (
                          <DragIndicatorIcon sx={{ cursor: "pointer" }} />
                        )}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          minWidth: 150,
                          maxWidth: 155,
                          p: 0,
                          px: { xl: 2 },
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <SelectInput
                          fullWidth
                          onInputChange={(e, type) =>
                            handleUpdateInput(e, "solution", i)
                          }
                          selectedValue={
                            answers[i]?.solution ? answers[i]?.solution : ""
                          }
                          inputKey="solution"
                          options={solutions ? solutions : []}
                          borderNone={true}
                          placeholder="Solution Name"
                          sx={{ width: 1 }}
                          labelId={"demo-select-small"}
                          id={"demo-select-small"}
                        />
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          minWidth: 150,
                          maxWidth: 160,
                          p: 0,
                          px: { xl: 1 },
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {/* feature */}
                        <TextField
                          fullWidth
                          placeholder="Enter feature name"
                          value={
                            answers[i]?.featureName
                              ? answers[i]?.featureName
                              : ""
                          }
                          onChange={e => handleUpdateInput(e, "featureName", i)}
                          sx={{
                            "& .MuiInputBase-input": {
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: "none",
                            },
                          }}
                          inputProps={{
                            maxLength: "100",
                            minLength: "3",
                          }}
                        />
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          minWidth: 140,
                          maxWidth: 160,
                          display: "flex",
                          alignItems: "center",
                          px: { xl: 3 },
                        }}
                      >
                        {/* rice score */}
                        <Box
                          sx={theme => ({
                            px: 2,
                            ml: 1,

                            width: "max-content",
                            height: "50px",
                            borderRadius: 1,
                            color: theme.palette.secondary.black,
                            display: "flex",
                            alignItems: "center",
                          })}
                        >
                          {answers[i]?.riceScore ? answers[i]?.riceScore : "0"}
                        </Box>
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          minWidth: 120,
                          maxWidth: 150,
                          p: 0,
                          px: { xl: 2 },
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {/* reach */}
                        <TextField
                          placeholder="0"
                          sx={{
                            maxWidth: 80,

                            input: {
                              "&, &::placeholder": theme => ({
                                color: theme.palette.secondary.black,
                                opacity: 1,
                              }),
                            },
                          }}
                          value={answers[i]?.reach ? answers[i]?.reach : ""}
                          onChange={e => {
                            handleUpdateInput(e, "reach", i);
                          }}
                          inputProps={{
                            maxLength: "6",
                            minLength: "1",
                          }}
                        />
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          minWidth: 100,
                          maxWidth: 150,
                          p: 0,
                          pl: 0,
                          px: { xl: 4 },
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {/* impact */}
                        <SelectInput
                          placeholder="0"
                          inputKey="impact"
                          options={[
                            "3 = Massive Impact",
                            "2 = High Impact",
                            "1 = Medium Impact",
                            ".5 = Low Impact",
                            ".25 = Minimal Impact",
                          ]}
                          labelId={"demo-select-small"}
                          id={"demo-select-small"}
                          displayEmpty={true}
                          fullWidth={true}
                          selectedValue={
                            answers[i]?.impact ? answers[i]?.impact : 0
                          }
                          onInputChange={e => handleUpdateInput(e, "impact", i)}
                          compact={true}
                          height={"54px"}
                        />
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          minWidth: 130,
                          maxWidth: 150,
                          p: 1,
                          px: 2,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {/* confidence */}
                        <SelectInput
                          placeholder="0"
                          inputKey="confidence"
                          options={[
                            "100% = High Confidence",
                            "80% = Medium Confidence",
                            "50% = Low Confidence",
                            "20% = Moonshot",
                          ]}
                          //displayEmpty={true}
                          fullWidth={true}
                          selectedValue={
                            answers[i]?.confidence ? answers[i]?.confidence : 0
                          }
                          onInputChange={e =>
                            handleUpdateInput(e, "confidence", i)
                          }
                          compact={true}
                          height={"54px"}
                        />
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          minWidth: 100,
                          maxWidth: 120,
                          p: 1,
                          px: { xl: 3 },
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <TextField
                          placeholder="0"
                          sx={{
                            maxWidth: 90,
                            input: {
                              "&, &::placeholder": theme => ({
                                color: theme.palette.secondary.black,
                                opacity: 1,
                              }),
                            },
                            width: "max-content",
                          }}
                          value={answers[i]?.effort ? answers[i]?.effort : ""}
                          onChange={e => {
                            handleUpdateInput(e, "effort", i);
                          }}
                          onBlur={e => handleOnBlur(e, "effort", i)}
                          inputProps={{
                            maxLength: "6",
                            minLength: "1",
                          }}
                        />
                      </TableCell>
                      {/*  RECOMMENDATION */}
                      <TableCell
                        align="left"
                        sx={{
                          minWidth: 150,
                          maxWidth: 180,
                          p: 1,
                          px: { xl: 3 },
                        }}
                      >
                        <SelectChipInput
                          fullWidth
                          //inputKey="recommandation"
                          options={["None", "Double-down", "Expand", "Ignore"]}
                          defaultValue={"None"}
                          backgroundColor={[
                            theme => theme.palette.text.textBgGrey,
                            theme => theme.palette.text.textBgGreen,
                            theme => theme.palette.text.textBgBlue,
                            theme => theme.palette.text.textBgOrange,
                          ]}
                          textColor={[
                            theme => theme.palette.text.textGrey,
                            theme => theme.palette.text.textGreen,
                            theme => theme.palette.text.textBlue,
                            theme => theme.palette.text.textOrange,
                          ]}
                          borderNone={true}
                          chip={true}
                          selectedValue={
                            answers[i]?.recommandation
                              ? answers[i]?.recommandation
                              : 0
                          }
                          onInputChange={e =>
                            handleUpdateInput(e, "recommandation", i)
                          }
                          compact={true}
                        />
                      </TableCell>
                      <TableCell
                        sx={{
                          minWidth: 100,
                          maxWidth: 110,
                          display: "flex",
                          alignItems: "center",
                          p: 0,
                          px: { xl: 1 },
                        }}
                        align="left"
                      >
                        <Checkbox
                          checked={answers[i]?.approve ? true : false}
                          onChange={e => handleUpdateInput(e, "approve", i)}
                        />
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          maxWidth: 40,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <IconButton
                          onClick={event => {
                            setRowIndex(i);
                            handlePopOver(event);
                          }}
                        >
                          <MoreHorizIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    width: 1,
                    justifyContent: "center",
                    alignContent: "center",
                    py: 5,
                  }}
                >
                  <Typography
                    color="primary"
                    variant="h5"
                    sx={theme => ({ color: theme.palette.secondary.dark })}
                  >
                    No data found.
                  </Typography>
                </Box>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={{ display: "flex", width: 1, mt: 1 }}>
          <TextField
            fullWidth
            size="small"
            placeholder={placeholder}
            value={newRow ? newRow : ""}
            onChange={e => setNewRow(e.target.value)}
            onKeyDown={e => {
              if (e.key === "Enter") {
                handleAddNewRow(newRow);
                setNewRow("");
              }
            }}
            onFocus={() => setPlaceholder("Type a ‘feature name’")}
            onBlur={() => setPlaceholder("+ Add New Row")}
            InputProps={{
              endAdornment: newRow && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Button
                    variant="nofill"
                    onClick={() => {
                      handleAddNewRow(newRow);
                      setNewRow("");
                    }}
                  >
                    Save
                  </Button>
                  <CloseIcon
                    sx={{ cursor: "pointer" }}
                    onClick={() => setNewRow("")}
                  />
                </Box>
              ),
            }}
          />
        </Box>
      </Paper>

      {/* more popover */}
      <Popover
        id={"simple-popover"}
        open={openPopOver}
        anchorEl={anchorEl}
        onClose={handlePopOver}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        PaperProps={{
          sx: {
            borderRadius: 1,
            boxShadow: theme => theme.palette.primary.elevationLight2Old,
            minWidth: "280px",
          },
        }}
      >
        <List>
          <ListItem disablePadding disabled={true}>
            <ListItemButton>
              <ListItemIcon sx={{ minWidth: 0, pr: 2 }}>
                <LocalOfferOutlinedIcon />
              </ListItemIcon>
              <ListItemText>
                <Typography variant="body_large">Apply Theme</Typography>
              </ListItemText>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={handleFeatureDesctiption}>
              <ListItemIcon sx={{ minWidth: 0, pr: 2 }}>
                <EditOutlinedIcon />
              </ListItemIcon>
              <ListItemText>
                <Typography variant="body_large">
                  Edit Feature Description
                </Typography>
              </ListItemText>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              onClick={event => {
                handleDeleteRow(rowIndex);
                handlePopOver(event);
              }}
            >
              <ListItemIcon sx={{ minWidth: 0, pr: 2 }}>
                <DeleteOutlineOutlinedIcon sx={{ color: "#BA1B1B" }} />
              </ListItemIcon>
              <ListItemText>
                <Typography variant="body_large">Delete Row</Typography>
              </ListItemText>
            </ListItemButton>
          </ListItem>
        </List>
      </Popover>
      {/* feature form */}
      <FeatureDescriptionForm
        open={openfeatureDesctiption}
        onClose={handleFeatureDesctiption}
        answers={answers}
        handleUpdateInput={handleUpdateInput}
        solutions={solutions}
        index={rowIndex}
        setAnswers={setAnswers}
      />
    </Fragment>
  );
}
