import services from "../../../services";
import React, { useRef, forwardRef, useImperativeHandle } from "react";

//MyWork page
export const getMyWorkPowerups = () => {
	return services
		.getMyWorkPowerups()
		.then((response) => {
			return response;
		})
		.catch((error) => console.log(error));
};

//Versions of selected powerup
export const getMyWorkDetails = async (page, sort, archived, status, filter, id) => {
	return services
		.getMyWorkDetails(id, page, sort, filter, false, status, "")
		.then((response) => {
			return response;
		})
		.catch((error) => console.log(error));
};

//Archive and unarchive
export const archiveModuleResponse = (data) => {
	return services
		.archiveModuleResponse(data)
		.then((response) => {
			return response;
		})
		.catch((error) => console.log(error));
};

//Archived versions list
export const getArchivedList = (page, sort, filter) => {
	return services
		.getArchivedList(page, sort, filter)
		.then((response) => {
			return response;
		})
		.catch((error) => console.log(error));
};

//Delete one
export const deleteArchiveModuleResponse = (responseId) => {
	return services
		.deleteArchiveModuleResponse(responseId)
		.then((response) => {
			return response;
		})
		.catch((error) => console.log(error));
};

//Delete all
export const deleteAllArchiveModuleResponse = () => {
	return services
		.deleteAllArchiveModuleResponse()
		.then((response) => {
			return response;
		})
		.catch((error) => console.log(error));
};
