import { Box, Skeleton } from "@mui/material";
import React, { Fragment } from "react";
import { CSSTransition } from "react-transition-group";

const SkeletonItem = ({ timeout, height }) => (
	<CSSTransition in={true} appear={true} timeout={timeout} classNames="open">
		<Box
			sx={{
				transition: "all 0.3s ease-in-out",
				opacity: 0,
				transform: "translateX(50px)",
				"&.open-exit-done": {
					opacity: 0,
					transform: "translateX(50px)",
				},
				"&.open-exit-active": {
					opacity: 1,
					transform: "translateX(0px)",
				},
				"&.open-enter-done": {
					opacity: 1,
					transform: "translateX(0px)",
				},
				"&.open-enter-active": {
					opacity: 1,
					transform: "translateX(0px)",
				},
			}}
		>
			<Skeleton
				variant="rounded"
				animation="wave"
				sx={{
					height: height,
					width: "100%",
					borderRadius: "10px",
					"&::after": {
						animationDelay: `.${timeout}s`,
					},
				}}
			/>
		</Box>
	</CSSTransition>
);

function TextAreaLoader() {
	return (
		<Box
			sx={{
				padding: "1.5rem",
				borderRadius: "1.5rem",
				background: "#F9F9F9",
				boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
				display: "flex",
				flexDirection: "column",
				width: "100%",
			}}
		>
			<Box sx={{ display: "flex", flexDirection: "column", gap: ".37rem", mb: "20px" }}>
				<SkeletonItem timeout={100} height={"50px"} />
				<SkeletonItem timeout={150} height={"150px"} />
			</Box>
			<Box sx={{ display: "flex", flexDirection: "column", gap: ".37rem", mb: "20px" }}>
				<SkeletonItem timeout={200} height={"50px"} />
				<SkeletonItem timeout={250} height={"80px"} />
			</Box>
			<Box sx={{ display: "flex", flexDirection: "column", gap: ".37rem" }}>
				<SkeletonItem timeout={300} height={"80px"} />
			</Box>
		</Box>
	);
}

export default TextAreaLoader;
