import React, { Fragment, useEffect, createRef } from "react";
import { Box } from "@mui/material";

import { TabBar } from "./components/tab-bar";
import { DetailsTab } from "./components/details-tab";
import { PowerTipsTab } from "./components/powertips-tab";
import { InspirationTab } from "./components/inspiration-tab";
import { ReviewsTab } from "./components/reviews-tab";

const TabPanel = ({ ...props }) => {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      sx={{
        height: value === index ? 1 : 0,
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {value === index && <Fragment>{children}</Fragment>}
    </Box>
  );
};

export default function SidebarTabs({ ...props }) {
  const [value, setValue] = React.useState(0);
  const [tabsContent, setTabsContent] = React.useState(props.tabs);
  const scrollRef = createRef();

  useEffect(() => {
    setTabsContent(props.tabs);
  }, [props.tabs]);

  useEffect(() => {
    setTabsContent(props.tabs);
    if (props.powerupContent) {
      setValue(-1);
    }
  }, [props.powerupContent]);

  const handleChange = (event, newValue) => {
    if (newValue !== null) {
      setValue(newValue);
    }
  };

  return (
    <Fragment>
      <Box
        sx={theme => ({
          [theme.breakpoints.up("md")]: {
            position: "relative",
            zIndex: 2,
            boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.3)",
          },
          [theme.breakpoints.down("md")]: {
            borderBottom: "1px solid",
            borderColor: theme.palette.divider,
          },
          mt: props.width > 330 ? 1 : 0,
        })}
      >
        <TabBar
          handleChange={handleChange}
          value={value}
          width={props.width}
          powerupContent={props.powerupContent}
        />
      </Box>
      <Box
        sx={theme => ({
          [theme.breakpoints.up("md")]: {
            overflow: "scroll",
            position: "relative",
            zIndex: "1",
          },
          flexGrow: 1,

          px: { md: 2, xs: 1 },
          pt: 3,
        })}
        ref={scrollRef}
      >
        {props.powerupContent && (
          <TabPanel key={-1} index={-1} value={value}>
            {props.powerupContent}
          </TabPanel>
        )}

        <TabPanel key={0} index={0} value={value}>
          {props.completedWorksComponent && (
            <Box sx={{ mb: 2 }}>{props.completedWorksComponent}</Box>
          )}
          <DetailsTab tab={tabsContent} />
        </TabPanel>
        <TabPanel key={1} index={1} value={value}>
          <PowerTipsTab tab={tabsContent.knowledgeTips} />
        </TabPanel>
        <TabPanel key={2} index={2} value={value}>
          <InspirationTab tab={tabsContent.knowledgeInspiration} />
        </TabPanel>
        <TabPanel key={3} index={3} value={value}>
          <ReviewsTab
            width={props.width}
            powerupID={tabsContent.id}
            scrollRef={scrollRef}
          />
        </TabPanel>
      </Box>
    </Fragment>
  );
}
