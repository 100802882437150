import { Box, TextField, Button, InputAdornment, IconButton } from "@mui/material";
import React, { useContext, useState } from "react";

import { Clear } from "@mui/icons-material";
import { ResourceCenterContext } from "../Stores/ResourceCenterStore";
import { LoadingButton } from "@mui/lab";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import services from "../../../services";

function SearchField() {
	const [inputValue, setInputValue] = useState("");
	const history = useHistory();
	const { currentSearchQuery, searchLoading, searchArticles } = useContext(ResourceCenterContext);
	const userDetails = useSelector((state) => state?.auth);
	const handleClearInput = () => {
		setInputValue("");
		searchArticles("");
	};

	const actionApiCall = (inputValue) => {
		services
			.updateStreakActionApi({
				actionType: "RESOURCE_CENTER",
				actionOnId: userDetails?.userId,
				actionOnType: "RESOURCE_CENTER",
				metaData: inputValue,
			})
			.then()
			.catch();
	};

	const handleSearch = async () => {
		if (inputValue && inputValue != currentSearchQuery) {
			actionApiCall(inputValue);

			const resp = await searchArticles(inputValue);
			history.push("/resource-center/search-results");
		}
	};

	return (
		<Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
			<TextField
				value={inputValue}
				onChange={(e) => setInputValue(e.target.value)}
				placeholder="Search for items..."
				InputProps={{
					variant: "TahoeOutlined",
					endAdornment: (
						<InputAdornment position="end">
							{inputValue && (
								<IconButton onClick={handleClearInput}>
									<Clear />
								</IconButton>
							)}
						</InputAdornment>
					),
				}}
				sx={{
					flex: 1,
				}}
			></TextField>
			<LoadingButton variant="DS1" onClick={handleSearch} loading={searchLoading}>
				Search
			</LoadingButton>
		</Box>
	);
}

export default SearchField;
