import React, { createContext, useEffect, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Divider, Stack } from "@mui/material";
import { useLazyQuery } from "@apollo/client";
import { setUserProfileData } from "../../../modules/actions/userProfileActions";
import { toastContainer } from "../../../utils/toast";
import services from "../../../services";
import ExperienceSection from "./ExperienceSection";
import EducationSection from "./EducationSection";
import { GET_MENTOR_DATA_QUERY } from "../../../containers/MentorProfile/Function/common";
import { setAuthData, setCommonDialogConfirmationData } from "../../../modules/actions";
import UpdatedDialogConfirmation from "../../../common/UpdatedDialogConfirmation";
import { USER_TYPES } from "../../../utils/userTypes";
import { GET_FOUNDER_DATA_QUERY } from "../../Profile/Stores/Function/common";
import { getRoleValue, handleExpAndEduSort } from "../../../utils/commonFunctions";
import { handleMixPanel } from "../../../utils/mixPanelEventHandling";
import messages from "../../../constants/messages";

const ExperienceAndEducationTabContext = createContext();

const ExperienceAndEducationTab = ({ handleClose }) => {
	const dispatch = useDispatch();
	const userDetails = useSelector((state) => state?.userProfileData?.userData);
	const [payLoad, setPayLoad] = useState({ ...userDetails });
	const [isExperienceLoading, setIsExperienceLoading] = useState(false);
	const [isEducationLoading, setIsEducationLoading] = useState(false);
	const commonDialogConfirmationData = useSelector((state) => state?.commonDialogConfirmation);
	const userType = useSelector((store) => store?.auth?.userType);
	const authData = useSelector((state) => state?.auth);

	const [experienceObject, setExperienceObject] = useState({
		title: "",
		companyName: "",
		location: "",
		startMonth: null,
		startYear: null,
		endMonth: null,
		endYear: null,
	});

	const [educationObject, setEducationObject] = useState({
		course: "",
		university: "",
		location: "",
		startYear: null,
		endYear: null,
	});
	const userId = userDetails?.id;

	const GET_USER_QUERY = userType === USER_TYPES.MENTOR_USER ? GET_MENTOR_DATA_QUERY : GET_FOUNDER_DATA_QUERY;

	const [loadUserData, { loading, error, data }] = useLazyQuery(GET_USER_QUERY, {
		errorPolicy: "all",
		fetchPolicy: "no-cache",
	});

	useEffect(() => {
		if (userDetails) {
			setPayLoad({ ...userDetails });
		}
	}, [userDetails]);

	useEffect(() => {
		if (loading) {
			dispatch(
				setUserProfileData({
					loading: loading,
				})
			);
		} else {
			if (!error && data) {
				if (!userId) {
					dispatch(
						setAuthData({
							userData: { picture: data?.userById?.picture },
							userName: data?.userById?.name,
						})
					);
				}
				let sortedData = handleExpAndEduSort(data?.userById);
				setPayLoad(sortedData);
			} else {
				console.log(error);
			}
		}
	}, [loading]);

	const setExpObjEmpty = () => {
		setExperienceObject({
			title: "",
			companyName: "",
			location: "",
			startMonth: null,
			startYear: null,
			endMonth: null,
			endYear: null,
		});
	};

	const setEduObjEmpty = () => {
		setEducationObject({
			course: "",
			university: "",
			location: "",
			startYear: null,
			endYear: null,
		});
	};
	const handleCloseConfirmation = () => {
		dispatch(
			setCommonDialogConfirmationData({
				isOpen: false,
			})
		);
	};

	const handlePrimaryButtonLoading = (primaryButtonLoading) => {
		dispatch(
			setCommonDialogConfirmationData({
				primaryButtonLoading: primaryButtonLoading,
				isOpen: true,
			})
		);
	};

	const addExperienceApiCall = (payload) => {
		return services
			.addMentorExperience(payload)
			.then((result) => {
				if (result.data.code === 200) {
					loadUserData({
						variables: { id: null },
					});
					setIsExperienceLoading(false);
					setExpObjEmpty();

					return true;
				}
			})
			.catch((error) => {
				setIsExperienceLoading(false);
				throw error;
			});
	};

	const updateExperienceApiCall = (payload, id) => {
		return services
			.updateMentorExperience(payload, id)
			.then((result) => {
				if (result.data.code === 200) {
					loadUserData({
						variables: { id: null },
					});
					setExpObjEmpty();
					setIsExperienceLoading(false);

					return true;
				}
			})
			.catch((error) => {
				setIsExperienceLoading(false);
				throw error;
			});
	};

	const deleteExperienceApiCall = (id) => {
		handlePrimaryButtonLoading(true);

		return services
			.deleteMentorExperience(id)
			.then((result) => {
				if (result.data.code === 200) {
					loadUserData({
						variables: { id: null },
					});
					toastContainer("Experience deleted successfully");
					setExpObjEmpty();

					handlePrimaryButtonLoading(false);
					handleCloseConfirmation();
					return true;
				}
			})
			.catch((error) => {
				handlePrimaryButtonLoading(false);
				throw error;
			});
	};

	const addEducationApiCall = (payload) => {
		return services
			.addMentorEducation(payload)
			.then((result) => {
				if (result.data.code === 200) {
					loadUserData({
						variables: { id: null },
					});
					setEduObjEmpty();
					setIsEducationLoading(false);
					return true;
				}
			})
			.catch((error) => {
				setIsEducationLoading(false);
				throw error;
			});
	};
	const updateEducationApiCall = (payload, id) => {
		return services
			.updateMentorEducation(payload, id)
			.then((result) => {
				if (result.data.code === 200) {
					loadUserData({
						variables: { id: null },
					});
					setEduObjEmpty();
					setIsEducationLoading(false);
					return true;
				}
			})
			.catch((error) => {
				setIsEducationLoading(false);
				throw error;
			});
	};
	const deleteEducationApiCall = (id) => {
		handlePrimaryButtonLoading(true);

		return services
			.deleteMentorEducation(id)
			.then((result) => {
				if (result.data.code === 200) {
					toastContainer("Education deleted successfully");
					handlePrimaryButtonLoading(false);
					setEduObjEmpty();
					handleCloseConfirmation();
					loadUserData({
						variables: { id: null },
					});
					return true;
				}
			})
			.catch((error) => {
				setIsEducationLoading(false);
				throw error;
			});
	};
	const handleAISave = async (aiData, eventType) => {
		let payloadData = null;

		if (eventType && eventType == "ai_suggestions") {
			payloadData = payLoad;
			payloadData = {
				...payloadData,
				about: aiData?.about ? aiData?.about : payloadData?.about,
			};
		} else {
			payloadData = aiData ? aiData : payLoad;
		}

		return services
			.updateUserProfile({
				...payloadData,
			})
			.then((response) => {
				if (response.data.message === "Success") {
					dispatch(
						setUserProfileData({
							userData: response.data.data,
						})
					);
					dispatch(
						setAuthData({
							userData: { picture: response.data.data.picture },
							userName: response.data.data.name,
						})
					);
					if (eventType === "ai_suggestions") {
						handleClose();
					}
					setPayLoad(response.data.data);
					return response;
				}
			})
			.catch((e) => {
				console.log("Submit", e);
				toastContainer(messages.API_SUBMIT_ERROR, "error");
			});
	};
	return (
		<ExperienceAndEducationTabContext.Provider
			value={{
				payLoad,
				setPayLoad,
				addExperienceApiCall,
				deleteExperienceApiCall,
				updateExperienceApiCall,
				addEducationApiCall,
				updateEducationApiCall,
				deleteEducationApiCall,
				handleClose,
				isExperienceLoading,
				setIsExperienceLoading,
				isEducationLoading,
				setIsEducationLoading,
				commonDialogConfirmationData,
				handleCloseConfirmation,
				experienceObject,
				setExperienceObject,
				educationObject,
				setEducationObject,
				setExpObjEmpty,
				setEduObjEmpty,
				handleAISave,
			}}
		>
			<Stack direction={"column"} spacing={4}>
				<ExperienceSection context={ExperienceAndEducationTabContext} />
				<Divider
					sx={{
						width: "100%",
					}}
				/>
				<EducationSection context={ExperienceAndEducationTabContext} />
			</Stack>
			<UpdatedDialogConfirmation />
		</ExperienceAndEducationTabContext.Provider>
	);
};

export default ExperienceAndEducationTab;
