import {
  Box,
  Typography,
  Link,
  Card,
  CardMedia,
  CardContent,
  Grid,
} from "@mui/material";
import React, { useEffect, useState } from "react";

export const MetaLinkCard = ({ data }) => {
  return (
    <Grid container>
      <Grid item sx={{ width: 1 }}>
        <Box sx={{ display: "flex", gap: 1, flexDirection: "column" }}>
          {data.map((item, idx) => {
            return (
              <Box key={idx}>
                <Card
                  sx={theme => ({
                    display: "flex",
                    maxWidth: 1,
                    cursor: "pointer",
                    border: `1px solid ${theme.palette.containerBorder.lightOutline}`,
                    borderRadius: 2,
                    flexDirection: { xs: "column", sm: "row" },
                    px: 1,
                  })}
                  target="_blank"
                  onClick={e => {
                    e.stopPropagation();
                    window.open(item.link);
                  }}
                >
                  <CardMedia
                    component="img"
                    sx={{
                      maxWidth: { xs: "50%", sm: "25%" },
                      objectFit: "cover",
                      alignSelf: { xs: "center", md: "" },
                      display: "flex",
                    }}
                    image={item.imageUrl}
                  />

                  <CardContent
                    sx={{
                      maxWidth: 1,
                      flexDirection: "column",
                      overflow: "hidden",
                      wordWrap: "break-word",
                    }}
                  >
                    <Typography variant="h5">{item.title}</Typography>
                    <Typography variant="subtitle1">
                      {item.description}
                    </Typography>
                    <Typography variant="subtitle1">{item.link}</Typography>
                  </CardContent>
                </Card>
              </Box>
            );
          })}
        </Box>
      </Grid>
    </Grid>
  );
};
