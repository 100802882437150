import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import TodayIcon from "@mui/icons-material/Today";
import Card from "../../../components/Workspace/Card";

import { camelCase, startCase } from "lodash";
import moment from "moment";
import { getFormattedDate } from "../../../utils/date";

const UpcomingEventCard = ({ eventData, id, ActionComponent = () => {} }) => {
  return (
    <Card key={id} variant="primary" sx={{ width: "100%" }}>
      <Box
        sx={{
          display: "flex",
        }}
      >
        <Box
          sx={theme => ({
            border: `1px solid ${theme.palette.secondary.dark}`,
            padding: 1,
            paddingBottom:
              eventData?.calendarEventStatus === "PENDING" ? 0.5 : 0, // Icon taking a bit of padding in bottom with generic padding. To keep looking consitent reduced the padding by 0.5 in bottom
            paddingTop: eventData?.calendarEventStatus === "PENDING" ? 1 : 0,
            height: "max-content",
            borderRadius: theme.shape.standard4.borderRadius,
            my: "auto",
          })}
        >
          {eventData?.calendarEventStatus === "PENDING" ? (
            <TodayIcon color="primary" />
          ) : (
            <Box sx={{ my: "auto" }}>
              <Box>
                <Typography variant="onsurface_subtitle">
                  {moment(eventData?.selectedDate).format("MMM")}
                </Typography>
              </Box>
              <Box>
                <Typography variant="title_small">
                  {moment(eventData?.selectedDate).format("DD")}
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
        <Box sx={{ ml: 2 }}>
          <Box>
            <Typography variant="headline6">
              {eventData?.calendarEvent?.calendarEventType ===
                "IDEA_VALIDATION" && "Idea Validation Interview"}
            </Typography>
          </Box>
          <Box sx={{ pt: 1 }} textAlign="left">
            <Typography variant="subhead1">
              {eventData?.companyModel?.companyName}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box sx={{ mt: 4, maxWidth: "fit-content" }}>
        {eventData?.calendarEventStatus === "PENDING" ? (
          <Typography variant="body_small">
            You have an interview request from{" "}
            {eventData?.companyModel?.companyName}. Please select or propose a
            new time.
          </Typography>
        ) : (
          <Box textAlign={"left"}>
            <Typography>
              {getFormattedDate(
                eventData?.calendarEvent?.timings[0].date,
                "dddd, MMM DD, YYYY"
              )}
            </Typography>
            <Typography>
              {moment(
                parseInt(eventData?.calendarEvent?.timings[0].times[0])
              ).format("hh:mm A")}{" "}
              -{" "}
              {moment(parseInt(eventData?.calendarEvent?.timings[0].times[0]))
                .add(eventData?.calendarEvent?.duration, "minutes")
                .format("hh:mm A")}
            </Typography>
            <Typography>{eventData?.calendarEvent?.timezone}</Typography>
          </Box>
        )}
      </Box>

      <ActionComponent />
    </Card>
  );
};

export default UpcomingEventCard;
