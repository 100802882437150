import { Typography, Grid, Box, CircularProgress } from "@mui/material";
import React, { useState } from "react";
import moment from "moment";
import { getCurrentDate } from "../../../../utils/date";

export default function HeaderComponent({ marketData, marketResponse }) {
  return (
    <>
      {!marketResponse ? (
        <Box justifyContent={"center"} sx={{ display: "flex", mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : (
        <Grid
          container
          sx={{ display: "flex", flexDirection: "column", gap: 2 }}
        >
          <Grid item xs={12} md={12}>
            <Typography variant="title_xlarge">
              {marketData.testTitle}
            </Typography>
          </Grid>

          <Grid item xs={12} md={12}>
            <Typography variant="subhead3">
              {marketData.testSubTitle}
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            md={12}
            sx={{ display: "flex", flexDirection: "row", gap: 3 }}
          >
            <Typography variant="title_medium">PowerUp:</Typography>
            <Typography variant="bodyMedium">
              {marketResponse?.powerUpModel?.powerUpName}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            sx={{ display: "flex", flexDirection: "row", gap: 2 }}
          >
            <Typography variant="title_medium">Start Date:</Typography>
            <Typography variant="bodyMedium">
              {marketResponse?.startedDate &&
              getCurrentDate(new Date(marketResponse?.startedDate))
                ? getCurrentDate(new Date(marketResponse?.startedDate))
                : ""}
            </Typography>
          </Grid>
        </Grid>
      )}
    </>
  );
}
