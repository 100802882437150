import React from "react";
import ConversationsList from "./ConversationsList";
import { Box, Card, Divider } from "@mui/material";

const ConversationsContainer = (props) => {
	return (
		<>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					backgroundColor: (theme) => theme.palette.secondary.white,
					height: "90%",
					borderRadius: "8px",

					"&": !props?.isSmallScreen
						? {
								minWidth: "360px", //TODO Mobile Handling
								width: "360px", //TODO Mobile Handling
						  }
						: {
								flex: 1,
								width: "100%",
						  },
				}}
			>
				<Box
					sx={{
						flex: 1,
					}}
				>
					<ConversationsList />
				</Box>
			</Box>
		</>
	);
};
export default ConversationsContainer;
