import { Box } from "@mui/material";
import React, { useEffect } from "react";
import theme from "../../../../../../../constants/Theme";

const GraphComponent = ({ tam = 0, sam = 0, som = 0 }) => {
  useEffect(() => {
    function sizeme() {
      var tam_circ = document.getElementById("svg_2");
      var sam_circ = document.getElementById("svg_4");
      var som_circ = document.getElementById("svg_5");

      // TAM = 100% cap.
      // 100% = 240 SVG.
      // Min ~ 5% ~ 12.5 SVG.
      // MAX ~ 95% ~ 237.5 SVG.

      // TAM = 20value
      // 20value ~ 240SVG
      // SAM = 15value
      // 15/20 -> 240*ans

      var tam_value = tam;
      var sam_value = sam;
      var som_value = som;

      if (parseInt(tam_value) === 0) {
        tam_value = 240;

        /*TAM*/
        tam_circ.style.fill = "transparent";
        tam_circ.setAttribute("stroke", theme.palette.text.custom070);
        tam_circ.setAttribute("stroke-width", "3");
        tam_circ.setAttribute("stroke-dasharray", "6 10");
        tam_circ.setAttribute("stroke-linecap", "square");
      } else {
        tam_circ.style.fill = theme.palette.text.custom070;
        tam_circ.setAttribute("stroke-width", "0");
      }

      if (parseInt(sam) === 0) {
        sam_value = parseInt(tam) > 0 ? tam / 2 : 120;

        /*SAM*/
        sam_circ.style.fill = "transparent";
        sam_circ.setAttribute("stroke", theme.palette.text.custom050);
        sam_circ.setAttribute("stroke-width", "3");
        sam_circ.setAttribute("stroke-dasharray", "6 10");
        sam_circ.setAttribute("stroke-linecap", "square");
      } else {
        sam_circ.style.fill = theme.palette.text.custom050;
        sam_circ.setAttribute("stroke-width", "0");
      }

      if (parseInt(som) === 0) {
        if (sam_value > tam_value) {
          som_value = parseInt(tam_value) / 2;
        } else if (tam_value > sam_value) {
          som_value = parseInt(sam_value) / 2;
        } else {
          som_value = 0;
        }
        /*SOM*/

        som_circ.style.fill = "transparent";
        som_circ.setAttribute("stroke", theme.palette.text.custom030);
        som_circ.setAttribute("stroke-width", "3");
        som_circ.setAttribute("stroke-dasharray", "6 10");
        som_circ.setAttribute("stroke-linecap", "square");
      } else {
        som_circ.style.fill = theme.palette.text.custom030;
        som_circ.setAttribute("stroke-width", "0");
      }

      var sam_rad = 240 * (sam_value / tam_value);
      if (sam_value > tam_value * 0.95) {
        sam_rad = 240 * 0.95;
      }
      if (sam_value < tam_value * 0.1) {
        sam_rad = 240 * 0.1;
      }

      tam_circ.setAttribute("r", 240);
      tam_circ.setAttribute("cy", 240);

      sam_circ.setAttribute("r", sam_rad);
      sam_circ.setAttribute("cy", sam_rad);

      var som_rad = 240 * (som_value / tam_value);
      if (som_value > sam_value * 0.9) {
        som_rad = sam_rad * 0.9;
      }
      if (som_value < sam_value * 0.1) {
        som_rad = sam_rad * 0.1;
      }

      som_circ.setAttribute("r", som_rad);
      som_circ.setAttribute("cy", som_rad);
    }
    sizeme();
  }, [tam, sam, som]);

  return (
    <Box
      sx={theme => ({
        maxWidth: "330px",
        maxHeight: "330px",
        ml: "auto",
        [theme.breakpoints.down("md")]: {
          mx: "auto",
        },
      })}
    >
      <svg viewBox="0 0 530 550" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <filter id="shadow">
            <feDropShadow
              dx="0"
              dy="2"
              stdDeviation="4"
              flood-color="black"
              flood-opacity=".17"
            />
          </filter>
        </defs>
        <g transform="rotate(180 260 260)">
          <circle
            r=""
            id="svg_2"
            cy=""
            cx="260"
            style={{
              fill: theme.palette.text.custom070,
              filter: "drop-shadow(0px -4px 6px rgba(0, 0, 0, 0.3))",
            }}
          />
          <circle
            r=""
            id="svg_4"
            cy=""
            cx="260"
            style={{
              fill: theme.palette.text.custom050,
              filter: "drop-shadow(0px -4px 6px rgba(0, 0, 0, 0.3))",
            }}
          />
          <circle
            r=""
            id="svg_5"
            cy=""
            cx="260"
            style={{
              fill: theme.palette.text.custom030,
              filter: "drop-shadow(0px -8px 6px rgba(0, 0, 0, 0.3))",
            }}
          />
        </g>
      </svg>
    </Box>
  );
};

export default GraphComponent;
