import React, { useEffect, useState } from "react";
import BILineChart from "./BILineChart/BILineChart";
import { Box, Typography, useTheme, CircularProgress } from "@mui/material";
import ChartCard from "./ChartCard";
import { get } from "lodash";
import { convertNumberToLocale, getGradientBgColor } from "../Functions/common";
import ZeroLineChart from "./BILineChart/EmptyLineChart";

function AcvChart({ onChartClick, data, loading, readOnly }) {
	const theme = useTheme();
	const chartData = get(data, "data", []);

	const latestBlendedACV = get(data, "latestBlendedACV", null);

	return (
		<ChartCard
			title={"ACV"}
			subheader={latestBlendedACV !== null && !loading ? `$${convertNumberToLocale(latestBlendedACV)}` : "0"}
			backgroundColor={theme.palette.secondary.white}
			onChartClick={() => onChartClick("annualRecurringRevenue", data)}
			toolTipBody={
				<Typography variant="Text/sm/Regular">
					Average Contract Value (ACV) is the average ARR per active customer. For a given month x. ACV can also be looked at separately for new
					customers and retained customers to better understand how ACV is trending.
				</Typography>
			}
			data={data?.data}
		>
			{loading ? (
				<Box sx={{ alignSelf: "center" }}>
					<CircularProgress />
				</Box>
			) : chartData.length === 0 ? (
				<ZeroLineChart readOnly={readOnly} />
			) : (
				<Box sx={{ position: "relative", width: "100%", mt: "auto" }}>
					<BILineChart
						data={chartData}
						tooltipYLable={"Blended ACV"}
						yAxisKey={"acvBlended"}
						yAxisType="currency"
						lineBorderColor={theme.palette.primary.main}
						lineBackgroundColor={(context) => {
							const ctx = context.chart.ctx;
							return getGradientBgColor(ctx, context);
						}}
					/>
				</Box>
			)}
		</ChartCard>
	);
}

export default AcvChart;
