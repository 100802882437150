import React, { Fragment, useEffect } from "react";
import {
  Box,
  Card,
  CardMedia,
  Divider,
  Stack,
  Typography,
  Link,
  Button,
} from "@mui/material";
import VideoPlayerComponent from "../../../../../common/videoPlayer";
import JsxParser from "react-jsx-parser";

export const InspirationTab = ({ ...props }) => {
  const [tab, setTab] = React.useState(props.tab);
  const [processedTab, setProcessedTab] = React.useState({});
  useEffect(() => {
    setTab(props.tab);
  }, [props.tab]);

  useEffect(() => {
    processTabData();
  }, [tab]);

  const processTabData = () => {
    try {
      let processed = JSON.parse(tab);
      setProcessedTab(processed);
    } catch (e) {
      console.log("Could not process inspiration.", e);
    }
  };

  return (
    <Fragment>
      <Typography
        variant="title_medium"
        sx={{ textTransform: "capitalize", my: 2 }}
      >
        Inspiration
      </Typography>
      <Divider />

      <Box
        sx={{
          boxSizing: "border-box",
          flexGrow: 1,
          overflow: "scroll",
          my: 2,
          pb: 2,
        }}
      >
        <Box sx={{ mb: 4 }}>
          <Typography variant="title_small">Related Media</Typography>
        </Box>
        <Stack spacing={4}>
          {processedTab &&
            processedTab.media &&
            processedTab.media.map((media, index) => (
              <Box key={index}>
                {media && media.type && media.type === "image" && (
                  <Card
                    sx={theme => ({
                      borderRadius: theme.shape.standard5.borderRadius,
                      boxShadow: "none",
                    })}
                  >
                    <CardMedia component={"img"} image={media.content_url} />
                  </Card>
                )}
                {media && media.type && media.type === "video" && (
                  <Box
                    sx={theme => ({
                      borderRadius: theme.shape.standard5.borderRadius,
                      boxShadow: "none",
                      overflow: "hidden",
                      "& > div": {
                        display: "flex",
                      },
                    })}
                  >
                    <VideoPlayerComponent videoUrl={media.content_url} />
                  </Box>
                )}
                {media && media.type && media.type === "text" && (
                  <Box>
                    <JsxParser
                      components={{ Typography, Box, Link, Button }}
                      jsx={media.html_content}
                    />
                  </Box>
                )}
                <Box sx={{ my: 2 }}>
                  <Typography variant="label_medium">{media.title}</Typography>
                </Box>
              </Box>
            ))}
        </Stack>
      </Box>
    </Fragment>
  );
};
