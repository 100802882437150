import styled from "@emotion/styled";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

export const BorderLinearProgress = styled(LinearProgress)(
  ({ theme, value }) => ({
    height: 8,
    borderRadius: 0,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      background:
        value < 80
          ? "linear-gradient(99.78deg, rgba(233, 115, 0, 0.15) 0%, rgba(255, 209, 65, 0.15) 100%);"
          : "rgba(0, 206, 125, 0.15);",
    },
    [`&.${linearProgressClasses.colorSecondary}`]: {
      background: theme.palette.secondary.light,
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 3,
      background:
        value < 80
          ? `linear-gradient(99.78deg, #E97300 0%, #FFD141 100%);`
          : "#00CE7D",
    },
  })
);

export const ContentWrapper = styled.div(({ theme }) => {
  return {
    boxSizing: "border-box",
    borderRadius: theme.shape.standard.borderRadius,
    padding: theme.spacing(3),
    boxShadow: theme.palette.primary.elevationLight2,
    padding: theme.spacing(3),
  };
});
