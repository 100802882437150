import React, { useContext, useEffect, useState } from "react";
import { ProfileContext } from "../../..";
import { CURRENCY_DOLLAR_CIRCLE_ICON, COINS_ICON, CALCULATOR_V1_ICON, ARROW_FAT_LINEUP_ICON } from "../../../../../constants";
import TagList from "./TagList";

export function formatNumberToString(number) {
	if (isNaN(number)) {
		return "N/A";
	} else if (number < 1000) {
		return "<$1k";
	} else if (number < 1000000) {
		const valueInThousands = Math.floor(number / 1000);
		return "$" + valueInThousands + "k";
	} else if (number < 10000000) {
		const valueInMillions = Math.floor(number / 1000000);
		return "$" + valueInMillions + "M";
	} else {
		return "$10M+";
	}
}

function StartupFinanceTags() {
	const { profileData } = useContext(ProfileContext);
	const [items, setitems] = useState([]);

	useEffect(() => {
		if (profileData) {
			let list = [
				{
					iconUrl: CURRENCY_DOLLAR_CIRCLE_ICON,
					label: (() => {
						let sentence = "";
						if (profileData?.fundingType) {
							try {
								let financeArr = JSON.parse(profileData?.fundingType);
								financeArr?.map((elem, index) => {
									sentence += elem;
									if (index < financeArr?.length - 1) {
										sentence += ", ";
									}
								});
							} catch (e) {
								console.log(e);
							}
						}
						return sentence;
					})(),
				},
				{
					iconUrl: COINS_ICON,
					label: `Revenue: ${profileData?.revenue ? profileData?.revenue : "No"}`,
				},
				{
					iconUrl: CALCULATOR_V1_ICON,
					label: profileData?.financing ? profileData?.financing : "No Data",
				},
				{
					iconUrl: ARROW_FAT_LINEUP_ICON,
					label: (() => {
						let amount = "";
						if (profileData?.capitalRaised) {
							try {
								amount = Intl.NumberFormat("en-US", {
									notation: "compact",
									maximumFractionDigits: 1,
								}).format(Number(profileData?.capitalRaised));
							} catch (e) {
								console.log(e);
							}
						}
						return "Raised: " + amount;
					})(),
				},
			];
			setitems(list);
		}
	}, [profileData]);

	return <TagList items={items} />;
}

export default StartupFinanceTags;
