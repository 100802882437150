import React from "react";
import { Box, Typography } from "@mui/material";
import {
  AccessTime,
  Groups,
  InfoOutlined,
  StairsOutlined,
} from "@mui/icons-material";

export const AttributeItem = ({ ...props }) => {
  return (
    <Box sx={{ display: "grid", gridTemplateColumns: "auto 1fr" }}>
      <Box sx={{ mr: 1 }}>
        {props.icon && props.icon === "time" && <AccessTime />}
        {props.icon && props.icon === "steps" && <StairsOutlined />}
        {props.icon && props.icon === "person" && <Groups />}
        {props.icon && props.icon === "info" && <InfoOutlined />}
      </Box>
      <Box>
        <Typography variant="title_small">{props.name}</Typography>
        <Typography>{props.value}</Typography>
      </Box>
    </Box>
  );
};
