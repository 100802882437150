import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { TextField, Box } from "@mui/material";
import { EyeIcon } from "./styles";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const TextInput = ({
  value,
  type,
  placeholder,
  onChange,
  className,
  label,
  error,
  disabled,
  display = "block",
  dataCy,
  ...props
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [dispalyValue, setDisplayValue] = useState("block");
  useEffect(() => {
    setDisplayValue(display);
  });
  return (
    <Box data-cy={dataCy}>
      <TextField
        {...props}
        type={type === "password" ? (showPassword ? "text" : type) : type}
        sx={{
          width: "100%",
          mt: 3,
        }}
        error={error ? true : false}
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        disabled={disabled}
        spellCheck={true}
        label={label}
      ></TextField>

      {type === "password" && (
        <EyeIcon>
          {showPassword ? (
            <VisibilityIcon
              sx={{ display: `${dispalyValue}` }}
              onClick={() => setShowPassword((prevState) => !prevState)}
            />
          ) : (
            <VisibilityOffIcon
              sx={{ display: `${dispalyValue}` }}
              onClick={() => setShowPassword((prevState) => !prevState)}
            />
          )}
        </EyeIcon>
      )}
      {error && (
        <span className="error-text" id={error}>
          {error}
        </span>
      )}
    </Box>
  );
};

//TODO - add prop-types to all components
TextInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
};

TextInput.defaultProps = {
  value: "",
  onChange: undefined,
  type: "text",
  placeholder: "",
  className: "",
  required: false,
  disabled: false,
};
export default TextInput;
