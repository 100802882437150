import React, { Suspense, useEffect } from "react";
import { Box } from "@mui/material";
import useDimensions from "react-cool-dimensions";
import ToolTwoCol from "../../components/layout/ToolTwoCol";

const Participants = React.lazy(() => import("../../components/ManageParticipants").then((module) => ({ default: module.ManageParticipants })));
const Result = React.lazy(() => import("../../components/Results").then((module) => ({ default: module.Results })));

const ImageUploader = React.lazy(() => import("../../components/ImageUploader").then((module) => ({ default: module.ImageUploader })));

function DoIt() {
	const { observe, width } = useDimensions({
		onResize: ({ width }) => {},
	});
	useEffect(() => {
		observe();
	}, [observe]);

	const getLearnItLeftComponents = () => {
		const currentPath = window.location.pathname;
		const parts = currentPath.split("/");
		const serveyId = parts[parts.length - 1];
		if (!serveyId) return null; // TODO -- Need "sorry was unable to load at this time. Message."

		switch (serveyId) {
			case "8":
				return (
					<React.Fragment>
						<ImageUploader />
						<Participants />
					</React.Fragment>
				);
			case "9":
				return (
					<React.Fragment>
						<ImageUploader />
						<Participants />
					</React.Fragment>
				);

			default:
				return (
					<React.Fragment>
						<Participants />
					</React.Fragment>
				);
		}
	};
	const getLearnItRightComponents = () => {
		return (
			<React.Fragment>
				<Result />
			</React.Fragment>
		);
	};

	return (
		<Box ref={observe}>
			<ToolTwoCol
				left={<Suspense fallback={<Box>Loading...</Box>}>{getLearnItLeftComponents()}</Suspense>}
				right={<Suspense fallback={<Box>Loading...</Box>}>{getLearnItRightComponents()}</Suspense>}
			/>
		</Box>
	);
}

export default DoIt;
