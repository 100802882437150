import React, { forwardRef, useImperativeHandle } from "react";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";

const SummaryExport = forwardRef(({ name }, ref) => {
  useImperativeHandle(ref, () => ({
    async handleExport(type) {
      const element = document.getElementById("summaryPage");
      const canvas = await html2canvas(element, {
        useCORS: true,
        proxy: "http://d3rm35snnwgfg3.cloudfront.net",
        logging: true,
        onclone: clonedDoc => {
          clonedDoc.getElementById("summaryPage").style.display = "block";
          clonedDoc.getElementById("summaryPage").style.maxHeight = "inherit";
        },
      });
      const data = canvas.toDataURL("image/png");
      if (type === "png") {
        const link = document.createElement("a");
        link.href = data;
        if (name === "Matrix") {
          link.download = `RICE Prioritization Matrix.png`;
        } else {
          link.download = `${name} Summary Page.png`;
        }

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        var imgWidth = 205;
        var pageHeight = 450;
        var imgHeight = (canvas.height * imgWidth) / canvas.width;
        var heightLeft = imgHeight;
        var doc = new jsPDF("p", "mm", "", false);
        var position = 0;
        pageHeight = doc.internal.pageSize.getHeight();
        doc.addImage(data, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(data, "PNG", 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }

        if (name === "Matrix") {
          doc.save(`RICE Prioritization Matrix.png`);
        } else {
          doc.save(`${name} Summary Page.pdf`);
        }
      }
    },
  }));

  return <></>;
});

export default SummaryExport;
