import TabsListUnstyled from "@mui/base/TabsListUnstyled";
import TabUnstyled, { tabUnstyledClasses } from "@mui/base/TabUnstyled";
import { styled } from "@mui/system";
import theme from "../../../../constants/Theme";

export const TabStyle = styled(TabUnstyled)`
  background-color: transparent;
  color: ${theme.palette.text.primary};
  padding: 10px 12px;
  border: none;
  border-radius: 12px;
  display: flex;
  justify-content: flex-start;

  &:hover {
    background: linear-gradient(
        0deg,
        rgba(118, 73, 160, 0.14),
        rgba(118, 73, 160, 0.14)
      ),
      #fcfcfc;
  }

  &.${tabUnstyledClasses.selected} {
    background: linear-gradient(
        0deg,
        rgba(118, 73, 160, 0.14),
        rgba(118, 73, 160, 0.14)
      ),
      #fcfcfc;
  }
`;

export const TabsList = styled(TabsListUnstyled)``;
