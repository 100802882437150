import React, { useState, useEffect, useMemo } from "react";
import { Box, Typography, Dialog, DialogContent, Slide, Button, Grid, Divider, Collapse } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import FilteredValueCarosualComponent from "./component/FilteredValueCarosual";
import CheckBoxComponent from "./component/Common/CheckBoxComp";
import { labelOptions } from "./dataLabel";
import FilterDropDownComponent from "./component/Common/FilterDropDown";
import { getListOfCategoryApiCall, getListOfCountryStateApiCall } from "../../../containers/Networking/Functions/common";
import { setNetworkingDashboardData } from "../../../modules/actions/networkingActions";
import FormatListBulletedOutlinedIcon from "@mui/icons-material/FormatListBulletedOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

import MultiSelectDropdown from "./component/Common/MultiSelectDropdown";
import MuiSlider from "../../../common/MuiSlider/MuiSlider";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="left" ref={ref} {...props} />;
});

function FilterDialog({ handleFilterChange, matchedName, totalMatchesCount }) {
	const dispatch = useDispatch();

	const openFilterDialog = useSelector((state) => state?.networking?.openFilterDialog ?? []);

	const filteredValue = useSelector((state) => state?.networking?.filteredValue ?? []);
	const locationList = useSelector((state) => state?.networking?.locationList ?? []);
	const partiallySelected = useSelector((state) => state?.networking?.partiallySelected ?? []);
	const selectedValues = useSelector((state) => state?.networking?.selectedValues ?? []);
	const industryList = useSelector((state) => state?.networking?.industryList ?? []);
	// const revenueTypeList = useSelector((state) => state?.networking?.revenueTypeList);
	// const businessTypeList = useSelector((state) => state?.networking?.businessTypeList);
	const productOfferingList = useSelector((state) => state?.networking?.productOfferingList ?? []);

	const totalMatches = useSelector((state) => state?.networking?.totalMatches ?? 0);
	const combinedStates = useSelector((state) => state?.networking?.combinedStates ?? []);
	const [investmentRange, setInvestmentRange] = useState([0, 0]);
	const [initialInvestmentRange, setInitialInvestmentRange] = useState(true);

	const sliderArray = [
		{
			value: 0,
			label: "$0",
		},
		{
			value: 250000,
			label: "$250",
		},
		{
			value: 500000,
			label: "$500",
		},
		{
			value: 750000,
			label: "$750",
		},
		{
			value: 1000000,
			label: "$1M+",
		},
	];
	const getListOfCountryState = () => {
		getListOfCountryStateApiCall()
			.then((res) => {
				if (res.data.code == 200) {
					let resposeData = res.data?.data || [];
					const newKey = "countryName";
					let tempPartialArray = [];
					const tempCombinedStates = [];
					const newArray = resposeData.map((array) => {
						tempPartialArray.push({ value: false, type: array.countryName });
						tempCombinedStates.push(...array.states);
						return {
							...array,
							["states"]: array?.states.map((obj) => {
								return { ...obj, [newKey]: obj.stateName };
							}),
						};
					});
					dispatch(
						setNetworkingDashboardData({
							locationList: newArray,
							partiallySelected: tempPartialArray,
							combinedStates: tempCombinedStates,
						})
					);
				}
			})
			.catch((err) => console.log(err));
	};

	const getCategoryList = (category) => {
		getListOfCategoryApiCall(category)
			.then((res) => {
				const responseData = res.data;
				let categoryArray = [];

				responseData.map((categoryItem) => {
					categoryArray.push(categoryItem?.lovValue);
				});
				if (category === "industry") {
					dispatch(setNetworkingDashboardData({ industryList: categoryArray }));
				} else if (category === "product-offering") {
					dispatch(setNetworkingDashboardData({ productOfferingList: categoryArray }));
				} else if (category === "business-type") {
					dispatch(setNetworkingDashboardData({ businessTypeList: categoryArray }));
				} else if (category === "revenue-model-type") {
					dispatch(setNetworkingDashboardData({ revenueTypeList: categoryArray }));
				}
			})
			.catch((e) => {
				console.log("Error", e);
			});
	};

	useEffect(() => {
		getCategoryList("industry");
		getCategoryList("product-offering");
		getListOfCountryState();
		// getCategoryList("business-type");
		// getCategoryList("revenue-model-type");
	}, []);

	useEffect(() => {
		const tempFilteredValue = [...filteredValue];

		if (!initialInvestmentRange) {
			let obj = {
				type: "investmentRange",
				investmentRangeLow: investmentRange[0],
				investmentRangeHigh: investmentRange[1],
				checkboxValue: `$${Intl.NumberFormat("en-US", {
					notation: "compact",
					maximumFractionDigits: 1,
				}).format(Number(investmentRange[0]))} -  $${Intl.NumberFormat("en-US", {
					notation: "compact",
					maximumFractionDigits: 1,
				}).format(Number(investmentRange[1]))}`,
			};

			let index = tempFilteredValue.findIndex((item) => item.type === obj.type);
			if (index !== -1) {
				tempFilteredValue[index] = obj;
			} else {
				tempFilteredValue.push(obj);
			}
		}
		setInitialInvestmentRange(false);

		dispatch(
			setNetworkingDashboardData({
				filteredValue: tempFilteredValue,
			})
		);
	}, [investmentRange]);

	const handleUncheck = (value) => {
		const checkedlist = [...filteredValue];
		let finalFilteredList = checkedlist.filter((index) => index?.checkboxValue !== value);
		dispatch(
			setNetworkingDashboardData({
				filteredValue: finalFilteredList,
			})
		);
	};

	const handleCheck = (newObj) => {
		const checkedlist = [...filteredValue, newObj];
		dispatch(
			setNetworkingDashboardData({
				filteredValue: checkedlist,
			})
		);
	};

	const handleDelete = (checkedValue) => {
		const templist = [...filteredValue];
		const data = selectedValues.filter((item) => item.stateName === checkedValue);
		const currentSelectedValue = selectedValues.filter((value) => value.cName === data[0]?.cName);

		let finalFilteredList = templist.filter((item) => item?.checkboxValue !== checkedValue);
		let selectedValuesList = selectedValues.filter((item) => item.stateName !== checkedValue);

		let tempPartiallySelected = partiallySelected;

		tempPartiallySelected = tempPartiallySelected.map((partialItem) => {
			if (currentSelectedValue.length > 1) {
				if (partialItem.type === data[0]?.cName) {
					partialItem.value = true;
				}
			} else {
				if (partialItem.type === data[0]?.cName) {
					partialItem.value = false;
				}
			}
			return partialItem;
		});

		dispatch(
			setNetworkingDashboardData({
				filteredValue: finalFilteredList,
				selectedValues: selectedValuesList,
				partiallySelected: [...tempPartiallySelected],
			})
		);
	};

	const handleClearAll = () => {
		let finalFilteredList = [];
		dispatch(
			setNetworkingDashboardData({
				filteredValue: finalFilteredList,
				selectedValues: [],
				// partiallySelected: [...tempPartiallySelected],
			})
		);
	};

	const handleResultClick = () => {
		handleFilterChange(filteredValue);
	};

	const handleClose = () => {
		dispatch(
			setNetworkingDashboardData({
				openFilterDialog: false,
			})
		);
		handleResultClick();
	};

	return (
		<Dialog
			maxWidth="lg"
			fullWidth
			open={openFilterDialog}
			onClose={handleClose}
			TransitionComponent={Transition}
			transitionDuration={700}
			keepMounted
			PaperProps={{ sx: { position: "fixed", top: 0, right: 0, m: 0, borderRadius: "20px", minHeight: 1, width: "100%" } }}
		>
			<Box
				sx={{
					p: 5,
					boxShadow: "0px 5px 15px rgb(0 0 0 / 10%)",
					display: "flex",
					flexDirection: "column",
					rowGap: "20px",
				}}
			>
				<Box
					sx={{
						display: "flex",
						width: "100%",
						alignItems: "center",
					}}
				>
					<Typography sx={{ color: (theme) => theme.palette.secondary.black, width: "fit-content" }} variant="card_name">
						Filters
					</Typography>

					<CloseIcon onClick={handleClose} sx={{ ml: "auto", cursor: "pointer" }} />
				</Box>
				<Box
					sx={{
						display: "flex",
						width: "100%",
						alignItems: "center",
						mt: 3,
						flexWrap: "wrap",
						justifyContent: "space-between",
						rowGap: 1.5,
					}}
				>
					<Box display="flex" gap={1} alignItems="center">
						<Typography component="span" variant="Text/sm/Semibold">
							{`${matchedName}: ${totalMatchesCount}`}
						</Typography>

						{filteredValue?.length > 0 && (
							<>
								{"  |  "}
								<Box>
									<Typography component="span" variant="Text/sm/Regular">{`${filteredValue.length} Filters : `}</Typography>
									<Typography
										component="span"
										variant="Text/sm/Semibold"
										onClick={handleClearAll}
										sx={{ cursor: "pointer", textDecoration: "underline" }}
									>
										Clear All
									</Typography>
								</Box>
							</>
						)}
					</Box>
					<Box display={"flex"} gap={1}>
						<Button variant="containedGrayStyle" onClick={handleClose} startIcon={<CancelOutlinedIcon />}>
							Close
						</Button>
						<Button variant="containedPurpleStyle" onClick={handleResultClick} startIcon={<FormatListBulletedOutlinedIcon />}>
							Results
						</Button>
					</Box>
				</Box>
				{filteredValue?.length > 0 && (
					<Box sx={{ height: "50px" }}>
						<FilteredValueCarosualComponent filteredValue={filteredValue} handleDelete={handleDelete} filterId={"networkingFilteredId"} />
					</Box>
				)}
			</Box>
			<DialogContent
				sx={{
					border: "none",
					px: { md: 7.5, sm: 5, xs: 2 },
					pt: 5,
					pb: 7.5,
				}}
				dividers
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						rowGap: "40px",
					}}
				>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={12} md={6}>
							<CheckBoxComponent
								filteredValue={filteredValue}
								companyData={labelOptions}
								handleCheck={handleCheck}
								handleUncheck={handleUncheck}
								lable={"Areas of Expertise"}
								type="expertise"
							/>
						</Grid>

						<Grid item xs={12} sm={12} md={6}>
							<CheckBoxComponent
								filteredValue={filteredValue}
								companyData={labelOptions}
								handleCheck={handleCheck}
								handleUncheck={handleUncheck}
								lable={"Stages"}
								type="currentStage"
							/>
						</Grid>
						<Grid item xs={12} sm={12} md={6}>
							<Typography
								sx={{
									color: (theme) => theme.palette.secondary.black,
									width: "fit-content",
								}}
								variant="Text/md/Semibold"
							>
								Investment Range
							</Typography>

							<MuiSlider value={investmentRange} setValue={setInvestmentRange} sliderArray={sliderArray} min={0} max={1000000} currency={true} />
						</Grid>

						<Grid item xs={12} sm={12} md={6}>
							<Box>
								<Grid container spacing={3}>
									<Grid item xs={12} sm={6} md={12}>
										<Box display="flex" flexDirection="column" gap={2}>
											<Typography
												component="div"
												variant="Text/md/Semibold"
												sx={{
													color: (theme) => theme.palette.secondary.black,
													width: "fit-content",
													lineHeight: "1.5rem",
												}}
											>
												Country
											</Typography>
											<MultiSelectDropdown
												type={"country"}
												field="countryName"
												placeholder={"countries"}
												value={filteredValue.filter((val) => val.type === "country")}
												options={locationList}
												handleCheck={handleCheck}
												handleUncheck={handleUncheck}
											/>
										</Box>
									</Grid>
									<Grid item xs={12} sm={6} md={12}>
										<Box display="flex" flexDirection="column" gap={2}>
											<Typography
												component="div"
												variant="Text/md/Semibold"
												sx={{
													color: (theme) => theme.palette.secondary.black,
													width: "fit-content",
													lineHeight: "1.5rem",
												}}
											>
												State / Province
											</Typography>
											<MultiSelectDropdown
												type={"state"}
												field="stateName"
												placeholder={"states"}
												value={filteredValue.filter((val) => val.type === "state")}
												options={combinedStates}
												handleCheck={handleCheck}
												handleUncheck={handleUncheck}
											/>
										</Box>
									</Grid>
								</Grid>
							</Box>
						</Grid>
					</Grid>

					<FilterDropDownComponent
						filteredValue={filteredValue}
						gridData={{ Industry: industryList }}
						handleCheck={handleCheck}
						handleUncheck={handleUncheck}
						lable={"Industry"}
						type={"industry"}
					/>

					<FilterDropDownComponent
						filteredValue={filteredValue}
						gridData={{ "Product Offering": productOfferingList }}
						handleCheck={handleCheck}
						handleUncheck={handleUncheck}
						lable={"Product Offering"}
						type={"productOffering"}
					/>
				</Box>
			</DialogContent>
		</Dialog>
	);
}

export default FilterDialog;
