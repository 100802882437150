import styled from "@emotion/styled";

export const TabContainer = styled.div(({ theme }) => {
	return {
		display: "flex",
		alignItems: "center",
		gap: theme.spacing(4),
		flexWrap: "wrap",
	};
});

export const TabItem = styled.div(({ theme, isActive }) => {
	return {
		display: "flex",
		alignItems: "center",
		gap: theme.spacing(1),
		cursor: "pointer",
		opacity: isActive ? "1" : "0.4",
	};
});

export const VideoContainer = styled.div(() => {
	return {
		flex: 1,
		maxHeight: "640px",
		mb: "30px",
		"& video": {
			borderRadius: "30px",
			objectFit: "contain",
			maxHeight: "640px",
		},
	};
});
