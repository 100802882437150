import React from "react";
import { Typography, Box } from "@mui/material";

const ContentDetails = ({ icon, title, description, titlePadding }) => {
  return (
    <Box sx={{ mt: 3 }}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {icon}
        <Typography sx={{ pl: titlePadding }} variant="h5" color="text.primary">
          {title}
        </Typography>
      </Box>
      <Typography sx={{ mt: 1, ml: 4 }} variant="body1" color="text.primary">
        {description}
      </Typography>
    </Box>
  );
};

export default ContentDetails;
