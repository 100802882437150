import React, { useEffect, useState } from "react";
import { LOCATION_NEW_ICON, BUILDING_ICON_V1 } from "../../../../../constants";
import PublicTagList from "../../../Common/PublicTagList";
import { useSelector } from "react-redux";
import { STATES } from "../../../../../constants/powerup-data";

function AccountPublicBioTags() {
	const [items, setitems] = useState([]);
	const profileData = useSelector((state) => state?.accountPublicProfileData?.accountPublicData);
	useEffect(() => {
		let list = [
			{
				iconUrl: BUILDING_ICON_V1,
				label: `${profileData?.companyName ? profileData?.companyName : "No Data"}`,
			},
			{
				iconUrl: LOCATION_NEW_ICON,
				label: (() => {
					let city = profileData?.city;
					let area = STATES.find((s) => s.name === profileData?.state);
					area = area ? area?.abbreviation : profileData?.state;
					if (city && area) {
						return `${city}, ${area}`;
					} else if (!city && profileData?.state) {
						return profileData?.state;
					} else return "No Data";
				})(),
			},
		];
		setitems(list);
	}, []);

	return <PublicTagList items={items} />;
}

export default AccountPublicBioTags;
