import React, { useEffect, useMemo, useState } from "react";
import JsxParser from "react-jsx-parser";
import { Box, Tabs, Tab, Typography, Card } from "@mui/material";

import { SCOUTING_REPORT_STATIC_TWO } from "../../../../constants";
import VideoPlayerComponent from "../../../../common/videoPlayer";
import services from "../../../../services";
import { useSelector } from "react-redux";
import { Info } from "@mui/icons-material";
import FoundingTeamVideoTabController from "./FoundingTeamVideoTabController";

// Endpoints needed:
// - About Us video thumbnail
// - Founder Story video thumbnail

export default function FoundingTeamVideosView() {
	const [videoData, setVideoData] = useState(null);
	const [value, setValue] = useState(0);
	const STARTUP = useSelector((state) => state?.investorDashboard?.openStartupObj);

	const fetchStartup = async () => {
		const result = await services.getStartupCardDetails(STARTUP?.id);
		setVideoData([
			{
				title: result.data?.data?.about,
				videoURL: result.data?.data?.aboutUsVideoUrl,
			},
			{
				title: result.data?.data?.founderStory,
				videoURL: result.data?.data?.founderStoryVideo,
			},
		]);
	};

	useEffect(() => {
		fetchStartup();
	}, []);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const loadVideo = useMemo(() => {
		if (videoData?.length > 0) {
			if (videoData[value]?.videoURL) {
				return (
					<Box
						sx={{
							flex: 1,
							maxHeight: "640px", // Figma value.
							"& video": {
								borderRadius: "0px 30px 30px 0px",
								objectFit: "cover",
							},
						}}
					>
						<VideoPlayerComponent videoUrl={videoData[value].videoURL} height="100%" />
					</Box>
				);
			}
		}

		return (
			<Box
				sx={{
					width: {
						lg: "30%",
						md: "20%",
						xs: "0%",
					},
					display: "flex",
					alignItems: "center",
				}}
			>
				<Box
					sx={{
						backgroundSize: "contain",
						width: "100%",
						height: {
							lg: "80%",
							md: "50%",
							xs: "0%",
						},
						backgroundImage: `url(${SCOUTING_REPORT_STATIC_TWO})`,
						backgroundRepeat: "no-repeat",
						transform: "rotate(180deg)",
						backgroundPositionX: "right",
					}}
				></Box>
			</Box>
		);
	}, [value, videoData]);

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: {
					md: "row",
					xs: "column",
				},
				py: {
					lg: 9,
					md: 6,
					xs: 3,
				},
			}}
		>
			{loadVideo}
			<Box
				sx={{
					display: "flex",
					flex: 1,
					flexDirection: "column",
					px: {
						lg: 4,
						md: 3,
						xs: 2,
					},
				}}
			>
				<FoundingTeamVideoTabController STARTUP={STARTUP} value={value} handleTabChange={handleChange} />
				{/* <Box>
					<Tabs
						value={value}
						onChange={handleChange}
						sx={{
							mb: {
								lg: 4,
								md: 3,
								xs: 2,
							},
							"& .MuiTab-root": {
								mr: 3, // Set margin-right between tabs
							},
							"& .MuiTabs-indicator": {
								backgroundColor: (theme) => theme.palette.primary.main_new,
								borderRadius: "5px",
								height: "4px",
							},
						}}
					>
						<Tab
							sx={{ textTransform: "none", px: 0 }}
							label={
								<Typography
									variant="customValue"
									sx={{
										color: value === 0 ? "#000" : "#878787",
										fontWeight: "600",
										fontFamily: "PoppinsSemiBold",
										fontSize: "20px",
										lineHeight: "20px",
									}}
								>
									About Us
								</Typography>
							}
						/>
						<Tab
							sx={{ textTransform: "none" }}
							label={
								<Typography
									variant="customValue"
									sx={{
										color: value === 1 ? "#000" : "#878787",
										fontWeight: "600",
										fontFamily: "PoppinsSemiBold",
										fontSize: "20px",
										lineHeight: "20px",
									}}
								>
									Founder Story
								</Typography>
							}
						/>
					</Tabs>
				</Box>
				<Box>
					{value === 0 ? (
						<Box>
							{STARTUP?.about ? (
								<Typography
									variant="customValue"
									sx={{
										color: "black",
										fontWeight: "500",
										fontFamily: "PoppinsMedium",
										fontSize: "16px",
										lineHeight: "24px",
									}}
									component={"p"}
								>
									<JsxParser jsx={STARTUP?.about} />
								</Typography>
							) : (
								<Card
									sx={{
										borderRadius: "40px",
										p: {
											xs: 2,
											md: 3,
											lg: 4,
										},
										boxShadow: "0px 10px 15px rgba(0, 0, 0, 0.05)",
										display: "flex",
										gap: 2,
									}}
								>
									<Box>
										<Info
											sx={{
												color: (theme) => theme.palette.primary.main_new,
											}}
										/>
									</Box>
									<Box>
										<Typography color="black" variant="poppinsMedium16" component={"p"}>
											No 'About Us' statement is available for {STARTUP?.name} at this time. We will continue to check for updates.
										</Typography>
									</Box>
								</Card>
							)}
						</Box>
					) : value === 1 ? (
						<Box>
							{STARTUP?.founderStory ? (
								<Typography color="black" variant="poppinsMedium16" component={"p"}>
									<JsxParser jsx={STARTUP?.founderStory} />
								</Typography>
							) : (
								<Card
									sx={{
										borderRadius: "40px",
										p: {
											xs: 2,
											md: 3,
											lg: 4,
										},
										mb: 4,
										boxShadow: "0px 10px 15px rgba(0, 0, 0, 0.05)",
										display: "flex",
										gap: 2,
									}}
								>
									<Box>
										<Info
											sx={{
												color: (theme) => theme.palette.primary.main_new,
											}}
										/>
									</Box>
									<Box>
										<Typography color="black" variant="poppinsMedium16" component={"p"}>
											No 'Founder Story' is available for {STARTUP?.name} at this time. We will continue to check for updates.
										</Typography>
									</Box>
								</Card>
							)}
						</Box>
					) : null}
				</Box> */}
				<Box
					sx={{
						alignSelf: "flex-end",
						mt: "auto",
					}}
				>
					<Typography variant="card_title">{value === 0 ? "About Us" : "Founder Story"}</Typography>
				</Box>
			</Box>
		</Box>
	);
}
