import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import PersonaCard from "./PersonaCard";
import NoDataPill from "./NoDataPill";
import { InfoOutlined } from "@mui/icons-material";

export default function AboutMe({ persona }) {
	const [personaData, setpersonaData] = useState(persona);

	useEffect(() => {
		setpersonaData(persona);
	}, [persona]);

	const DEMOGRAPHICS = [
		{ title: "Gender", value: personaData?.gender },
		{ title: "Marital Status:", value: personaData?.maritalStatus },
		{ title: "Age:", value: personaData?.age },
		{ title: "Education:", value: personaData?.educationLevel },
		{ title: "Industry:", value: personaData?.industry },
	];

	return (
		<PersonaCard title="About Me" icon={<InfoOutlined fontSize="large" />}>
			<Box mt={3}>
				{DEMOGRAPHICS.map((item) => (
					<Box key={item.title} display="flex" alignItems="center" mb="4px">
						<Typography variant="card_text" sx={{ fontWeight: 600, marginRight: "10px" }}>
							{item.title}
						</Typography>
						{item.value ? <Typography variant="card_text">{item.value}</Typography> : <NoDataPill />}
					</Box>
				))}
			</Box>
		</PersonaCard>
	);
}
