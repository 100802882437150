import React, { useEffect, useState } from "react";
import { Box, Typography, styled } from "@mui/material";
import FitContentCard from "./FitContentCard";
import { CARD_ITEM } from "../../MarketOpportunity/PersonaBuilderView/Bio";
import NoDataPill from "../../MarketOpportunity/PersonaBuilderView/NoDataPill";

export default function Customers({ data }) {
	const [probSolData, setProbSolData] = useState(data);

	useEffect(() => {
		setProbSolData(data);
	}, [data]);

	let CUSTOMERS = null;
	if (Array.isArray(probSolData?.customer)) {
		CUSTOMERS = probSolData?.customer?.map((item) => {
			return {
				title: item,
			};
		});
	} else {
		CUSTOMERS = probSolData?.customer;
	}

	return (
		<FitContentCard title="Customers">
			<Box mt={3}>
				<Box display="flex" gap="10px" flexWrap="wrap">
					{Array.isArray(probSolData?.customer) ? (
						CUSTOMERS?.map((item) => (
							<CARD_ITEM key={item.title}>
								<Typography variant="card_text">{item.title}</Typography>
							</CARD_ITEM>
						))
					) : (
						<CARD_ITEM>
							<Typography variant="card_text">{probSolData?.customer}</Typography>
						</CARD_ITEM>
					)}
				</Box>
				{!CUSTOMERS && <NoDataPill />}
			</Box>
		</FitContentCard>
	);
}

export const Wrapper = styled(Box)(({ isLarge }) => ({
	background: "#FFFFFF",
	borderRadius: "30px",
	padding: "42px 60px 40px",
	boxShadow: "0px 10px 25px rgba(0, 0, 0, 0.05)",
}));
