import React from "react";
import {
	Chart,
	CategoryScale,
	LineController,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Filler,
	Legend,
	TimeScale,
	TimeSeriesScale,
} from "chart.js";
import { Line } from "react-chartjs-2";
import "chartjs-adapter-date-fns";
import { useTheme } from "@mui/material";
import { convertNumberToLocale } from "../../Functions/common";
Chart.register(CategoryScale, LineController, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale, TimeSeriesScale);
function BILineChart({
	data,
	lineBorderColor,
	lineBackgroundColor,
	tooltipYLable,
	xAxisKey = "date",
	yAxisKey,
	yAxisType = "count",
	borderWidth = 2,
	datasets = [],
	fill = true,
}) {
	const sortedChartData = [...data].sort((a, b) => b.date.localeCompare(a.date));
	const yValueOfDashedLine = datasets[0]?.data[0]?.benchamark;
	const labelText = datasets[0]?.label;
	const theme = useTheme();
	const themeObj = theme.typography["Text/xs/Medium"];
	const rootRemSize = 16;
	const heightOfLine = themeObj.lineHeight * rootRemSize;
	const sizeOfText = parseFloat(theme.typography["Text/xs/Medium"].fontSize) * rootRemSize;
	const highestYValue = Math.max(...sortedChartData.map((dataPoint) => dataPoint[yAxisKey]));
	const lowestYValue = Math.min(...sortedChartData.map((dataPoint) => dataPoint[yAxisKey]));
	const isBenchmarkExceeds = yValueOfDashedLine >= highestYValue;
	const isBenchmarkFalls = yValueOfDashedLine <= lowestYValue;
	const isLowestNegative = lowestYValue < 0;
	return (
		<Line
			data={{
				// labels: labels,
				datasets: [
					{
						label: tooltipYLable,
						data: sortedChartData,
						borderWidth: 2,
						borderColor: lineBorderColor,
						backgroundColor: lineBackgroundColor,
						tension: 0.15,
						fill: fill,
						parsing: {
							xAxisKey: xAxisKey,
							yAxisKey: yAxisKey,
						},
						pointRadius: 0,
						pointHoverRadius: 3,
						clip: false,
					},
					...datasets,
				],
			}}
			options={{
				clip: false,
				maintainAspectRatio: true,
				responsive: true,
				hover: {
					mode: "index",
					intersect: false,
				},
				font: {
					family: '"PoppinsRegular", "Helvetica", "Arial", sans-serif',
				},
				plugins: {
					legend: {
						display: false,
					},
					annotation: {
						annotations: {
							label1: {
								type: "label",
								position: "end",
								xAdjust: 45,
								yAdjust: (() => {
									if (isBenchmarkExceeds) {
										return 40;
									}
									if (isBenchmarkFalls) {
										return -12;
									}
									if (isLowestNegative) {
										return 40;
									}
									return -12;
								})(),
								yValue: (() => {
									return yValueOfDashedLine;
								})(),
								content: (() => {
									if (yValueOfDashedLine) {
										if (yAxisType === "currency") {
											const localeFormatted = convertNumberToLocale(yValueOfDashedLine);
											return `${labelText}: $${localeFormatted}`;
										} else {
											return `${labelText}: ${yValueOfDashedLine} `;
										}
									} else {
										return "";
									}
								})(),
								borderRadius: 10,
								borderColor: "grey",
								borderWidth: 0.5,
								font: {
									lineHeight: heightOfLine,
									family: themeObj.fontFamily,
									size: sizeOfText,
									style: themeObj.style,
									weight: themeObj.weight,
									color: themeObj.color,
								},
							},
						},
					},
					tooltip: {
						enabled: true,
						mode: "index",
						intersect: false,
						titleAlign: "center",
						bodyAlign: "center",
						padding: 12,
						titleFont: {
							weight: "bold",
							size: 14,
						},
						bodyFont: {
							size: 14,
						},
						callbacks: {
							label: function (context) {
								let label = context.dataset.label || "";
								if (label) {
									label += ":";
								}
								let yValue = context.parsed.y;
								if ((yValue !== "") & (typeof yValue !== "undefined") && yValue !== null) {
									yValue = convertNumberToLocale(yValue);
									if (yAxisType === "currency") {
										yValue = "$ " + yValue;
									}
								}
								return ` ${label} ${yValue}`;
							},
						},
					},
					datalabels: {
						display: false,
					},
				},
				scales: {
					x: {
						type: "time",
						time: {
							unit: "month",
							tooltipFormat: "MMM yyyy",
							displayFormats: {
								month: "MMM yyyy",
							},
						},
						ticks: {
							// display: false,
							align: "inner",
							source: "data",
							maxRotation: 0,
							minRotation: 0,
							autoSkip: false,
							callback: function (value, index, values) {
								// Display only the first and last tick labels
								if (index === 0 || index === values?.length - 1) {
									return value;
								}
								return ""; // Empty string to hide other tick labels
							},
							font: {
								size: 12,
							},
							color: "black",
						},
						grid: {
							drawBorder: false,
							display: false,
						},
					},
					y: {
						beginAtZero: true,
						ticks: {
							display: false,
						},
						grid: {
							drawBorder: false,
							display: false,
						},
					},
				},
				layout: {
					padding: {
						right: 5,
					},
				},
			}}
		/>
	);
}

export default BILineChart;
