import React, { useEffect, useMemo, useState } from "react";
import { Box, styled, Button, CircularProgress, Typography, Stack, Grid } from "@mui/material";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

import services from "../../../../services";
import WorkspaceHeader, { ActionBtn } from "../../../../components/Investor/Workspace/View/Header";
import PowerUpIcon from "../../../../components/Investor/Workspace/Assets/new-home-powerup-icon.svg";
import CoursesIcon from "../../../../components/Investor/Workspace/Assets/new-home-powerup-courses-icon.svg";
import SoonIcon from "../../../../components/Investor/Workspace/Assets/new-home-tools-soon-icon.svg";
import PartnerIcon from "../../../../components/Investor/Workspace/Assets/new-home-powerup-partner-icon.svg";
import NextIcon from "../../../../components/Investor/Workspace/Assets/new-home-powerup-next-icon.svg";
import { HomeSectionTitle } from "../../../../components/Investor/Workspace/View/HomeTitle";
import { useWindowSize } from "../../../../utils/windowResize";
import { handleMixPanel } from "../../../../utils/mixPanelEventHandling";
import { useSelector } from "react-redux";
import LabsIcon from "../../../../components/Investor/Workspace/Assets//new-home-labs-icon.svg";
import HomePowerCard from "../components/HomePowerCard/HomePowerCard";
import FilterField from "../../../../components/Investor/Workspace/View/FilterField";

ChartJS.register(ArcElement, Tooltip, Legend);

export default function PowerupLibrary() {
	const size = useWindowSize();
	const [powerUpCards, setPowerUpCards] = useState([]);
	const [filteredPowerUpCards, setFilteredPowerUpCards] = useState([]);

	const [comingCards, setComingCards] = useState([]);
	const [filteredComingCards, setFilteredComingCards] = useState([]);

	const [partnerCards, setPartnerCards] = useState([]);
	const [filteredPartnerCards, setFilteredPartnerCards] = useState([]);

	const [nextCards, setNextCards] = useState([]);
	const [filteredNextCards, setFilteredNextCards] = useState([]);

	const [showAllPowerUp, setShowAllPowerUp] = useState(true);
	const [showAllComing, setShowAllComing] = useState(true);
	const [showAllPartners, setShowAllPartners] = useState(true);
	const [showAllNext, setShowAllNext] = useState(true);
	const [loading, setLoading] = useState(false);
	const [searchText, setSearchText] = useState("");
	const [likesCount, setLikesCount] = useState({});
	const [entityIds, setEntityIds] = useState();
	let flag = true;

	const authData = useSelector((state) => state?.auth);

	useEffect(() => {
		getCurrentGroup("powerup-tools");
		getCurrentGroup("partner-powerups");
	}, []);

	useEffect(() => {
		const powerUpList = powerUpCards.filter((item) => item?.title.toLowerCase().includes(searchText.toLowerCase()));
		setFilteredPowerUpCards(powerUpList);

		const comingList = comingCards.filter((item) => item?.title.toLowerCase().includes(searchText.toLowerCase()));
		setFilteredComingCards(comingList);

		const partnerList = partnerCards.filter((item) => item?.title.toLowerCase().includes(searchText.toLowerCase()));
		setFilteredPartnerCards(partnerList);

		const nextList = nextCards.filter((item) => item?.title.toLowerCase().includes(searchText.toLowerCase()));
		setFilteredNextCards(nextList);
	}, [searchText]);

	useEffect(() => {
		if (entityIds && entityIds.length > 0) {
			getLikesCount();
		}
	}, [entityIds]);

	const cardLength = useMemo(() => {
		if (size.width > 1999) {
			return 4;
		} else if (size.width > 1587) {
			return 3;
		} else if (size.width > 1110) {
			return 2;
		} else if (size.width > 980) {
			return 1;
		} else if (size.width > 828) {
			return 2;
		} else {
			return 1;
		}
	}, [size]);

	const getLikesCount = async () => {
		try {
			const updatedEntityIds = entityIds?.join(",");
			const res = await services.getLikesApi({ entityType: "ACTIVITY_CARD", collectionType: "SAVED", entityIds: updatedEntityIds });
			setLikesCount(res?.data?.data);
		} catch (error) {
			console.log(`Error in fetching likes`, error);
		}
	};

	const getCurrentGroup = async (group) => {
		setLoading(true);
		try {
			const powerUps = await services.getToDosList(group);

			if (powerUps?.data?.data) {
				if (group === "powerup-tools") {
					setPowerUpCards(powerUps?.data?.data?.filter((powerUp) => powerUp.cardStatus === "PUBLISHED"));
					setFilteredPowerUpCards(powerUps?.data?.data.filter((powerUp) => powerUp.cardStatus === "PUBLISHED"));

					setComingCards(powerUps?.data?.data.filter((powerUp) => powerUp.cardStatus === "COMING_SOON" && powerUp?.isAiPowered === true));
					setFilteredComingCards(powerUps?.data?.data.filter((powerUp) => powerUp.cardStatus === "COMING_SOON" && powerUp?.isAiPowered === true));

					setNextCards(powerUps?.data?.data.filter((powerUp) => powerUp?.cardStatus === "COMING_SOON" && powerUp?.isAiPowered !== true));
					setFilteredNextCards(powerUps?.data?.data.filter((powerUp) => powerUp?.cardStatus === "COMING_SOON" && powerUp?.isAiPowered !== true));

					const nextEntityIds = powerUps?.data?.data
						.filter((powerUp) => powerUp?.cardStatus === "COMING_SOON" && powerUp?.isAiPowered !== true)
						.map((item) => item.id);
					if (flag) {
						setEntityIds(nextEntityIds);
						flag = false;
					}
				} else {
					setPartnerCards(powerUps?.data?.data);
					setFilteredPartnerCards(powerUps?.data?.data);
				}
			}
		} catch (error) {
			console.log(error);
		}
		setLoading(false);
	};

	const powerUpItems = useMemo(() => {
		return showAllPowerUp ? filteredPowerUpCards : filteredPowerUpCards.slice(0, cardLength);
	}, [showAllPowerUp, filteredPowerUpCards, cardLength]);

	const comingItems = useMemo(() => {
		return showAllComing ? filteredComingCards : comingCards.slice(0, cardLength);
	}, [showAllComing, filteredComingCards, cardLength]);

	const partnerItems = useMemo(() => {
		return showAllPartners ? filteredPartnerCards : filteredPartnerCards.slice(0, cardLength);
	}, [showAllPartners, filteredPartnerCards, cardLength]);

	const nextItems = useMemo(() => {
		const filteredNextItems = showAllNext ? filteredNextCards : filteredNextCards.slice(0, cardLength);
		const itemsWithLikes = filteredNextItems.map((item) => ({
			...item,
			likes: likesCount[item.id] || 0,
		}));

		return itemsWithLikes;
	}, [showAllNext, filteredNextCards, cardLength, likesCount]);

	const handlePowerUpCount = () => {
		setShowAllPowerUp(!showAllPowerUp);
	};

	const handleComingCount = () => {
		setShowAllComing(!showAllComing);
	};

	const handleNextCount = () => {
		setShowAllNext(!showAllNext);
	};

	const handlePartnerCount = () => {
		setShowAllPartners(!showAllPowerUp);
	};

	const handleHeart = async (id, isRemoved = false, title = "") => {
		setLikesCount((prevLikesCount) => {
			const updatedLikesCount = { ...prevLikesCount };

			if (isRemoved) {
				if (updatedLikesCount[id]) {
					updatedLikesCount[id] -= 1;
				}
			} else {
				updatedLikesCount[id] = (updatedLikesCount[id] || 0) + 1;
			}

			return updatedLikesCount;
		});
		const heart = await services.updateCollections({ entityIds: [id], entityType: "ACTIVITY_CARD", collectionType: "SAVED" }, isRemoved);

		if (isRemoved) {
			handleMixPanel("Unloved Card", {
				"Unloving user name": authData?.userName,
				"Unloving user id": authData?.userId,
				"Unloving card title": title,
				"Unloving card id": id,
			});
		} else {
			handleMixPanel("Loved Card", {
				"Loving user name": authData?.userName,
				"Loving user id": authData?.userId,
				"Unloving card title": title,
				"Loved card id": id,
			});
		}
	};
	const HandlePowerupSearch = (e) => {
		setSearchText(e?.target?.value || "");
	};

	return (
		<Box>
			<Box mt={4}>
				<Box mb={4}>
					<Stack alignItems={"center"} spacing={1} sx={{ mb: 2 }}>
						<Box display={"flex"} alignItems={"center"} gap={2}>
							<Box component={"img"} src={LabsIcon} />
							<Typography variant="Text/md/Semibold">Complete our AI PowerUps!</Typography>
						</Box>

						<Typography variant="Text/sm/Regular">Our PowerUps are learn-by-doing exercises designed to help your startup succeed.</Typography>
					</Stack>{" "}
					<Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
						<HomeSectionTitle icon={CoursesIcon} title="PowerUps" count={filteredPowerUpCards.length} />
						<Box sx={{ width: "400px" }}>
							<FilterField placeholder={"Search"} debounceChange={HandlePowerupSearch} />
						</Box>
					</Box>
					{loading ? (
						<Box
							sx={{
								display: "flex",
								width: 1,
								justifyContent: "center",
								alignItems: "center",
								height: "50vh",
							}}
						>
							<CircularProgress />
						</Box>
					) : (
						<Box display="flex" flexWrap={"wrap"} gap="30px" sx={{ mt: 2 }}>
							{powerUpItems?.map((powerUp) => (
								<HomePowerCard
									key={powerUp?.id}
									isSpecialVariant={true}
									title={powerUp?.title}
									cardType={powerUp?.cardType}
									cardStatus={powerUp?.cardStatus}
									taskStatus={powerUp?.cardProgressDetails?.taskStatus}
									linkedEntityId={powerUp?.linkedEntityId}
									desc={powerUp?.shortDescription}
									imgSrc={powerUp?.imageUrl}
									isAI={powerUp?.isAiPowered}
									isPremium={powerUp?.isPremiumFeature}
									uuid={powerUp?.uuid}
								/>
							))}
						</Box>
						// <Grid container spacing={2}>
						// 	{powerUpItems.map((powerUp) => (
						// 		<Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
						// 			<HomePowerCard
						// 				key={powerUp?.id}
						// 				isSpecialVariant={true}
						// 				title={powerUp?.title}
						// 				cardType={powerUp?.cardType}
						// 				cardStatus={powerUp?.cardStatus}
						// 				taskStatus={powerUp?.cardProgressDetails?.taskStatus}
						// 				linkedEntityId={powerUp?.linkedEntityId}
						// 				desc={powerUp?.shortDescription}
						// 				imgSrc={powerUp?.imageUrl}
						// 				isAI={powerUp?.isAiPowered}
						// 				isPremium={powerUp?.isPremiumFeature}
						// 			/>
						// 		</Grid>
						// 	))}
						// </Grid>
					)}
					{filteredPowerUpCards.length > cardLength && <ShowCards handleShowCard={handlePowerUpCount} isShow={showAllPowerUp} />}
				</Box>
				<Box mb={4}>
					<HomeSectionTitle icon={PartnerIcon} title="Partner PowerUps" count={filteredPartnerCards.length} />
					{loading ? (
						<Box
							sx={{
								display: "flex",
								width: 1,
								justifyContent: "center",
								alignItems: "center",
								height: "50vh",
							}}
						>
							<CircularProgress />
						</Box>
					) : (
						<Box display="flex" flexWrap={"wrap"} gap="30px">
							{partnerItems.map((partner) => (
								<HomePowerCard
									key={partner?.id}
									isSpecialVariant={true}
									title={partner?.title}
									cardType={partner?.cardType}
									cardStatus={partner?.cardStatus}
									taskStatus={partner?.cardProgressDetails?.taskStatus}
									linkedEntityId={partner?.linkedEntityId}
									desc={partner?.shortDescription}
									imgSrc={partner?.imageUrl}
									isAI={partner?.isAiPowered}
									isPremium={partner?.isPremiumFeature}
								/>
							))}
						</Box>
					)}
					{filteredPartnerCards.length > cardLength && <ShowCards handleShowCard={handlePartnerCount} isShow={showAllPartners} />}
				</Box>
				<Box mb={4}>
					<HomeSectionTitle icon={SoonIcon} title="Coming Soon" count={filteredComingCards.length} />
					{loading ? (
						<Box
							sx={{
								display: "flex",
								width: 1,
								justifyContent: "center",
								alignItems: "center",
								height: "50vh",
							}}
						>
							<CircularProgress />
						</Box>
					) : (
						<Box display="flex" flexWrap={"wrap"} gap="30px">
							{comingItems.map((coming) => (
								<HomePowerCard
									key={coming?.id}
									variant="coming"
									isSpecialVariant={true}
									title={coming?.title}
									cardType={coming?.cardType}
									cardStatus={coming?.cardStatus}
									taskStatus={coming?.cardProgressDetails?.taskStatus}
									linkedEntityId={coming?.linkedEntityId}
									desc={coming?.shortDescription}
									imgSrc={coming?.imageUrl}
									isAI={coming?.isAiPowered}
									isPremium={coming?.isPremiumFeature}
								/>
							))}
						</Box>
					)}
					{filteredComingCards.length > cardLength && <ShowCards handleShowCard={handleComingCount} isShow={showAllComing} />}
				</Box>

				<Box mb={4}>
					<HomeSectionTitle icon={NextIcon} title="What we should build next? Vote with a heart!" count={filteredNextCards.length} />
					{loading ? (
						<Box
							sx={{
								display: "flex",
								width: 1,
								justifyContent: "center",
								alignItems: "center",
								height: "50vh",
							}}
						>
							<CircularProgress />
						</Box>
					) : (
						<Box display="flex" flexWrap={"wrap"} gap="30px">
							{nextItems.map((next) => (
								<HomePowerCard
									key={next?.id}
									isSpecialVariant={true}
									variant="partner"
									id={next?.id}
									isSaved={next?.isSaved && !next?.isRemoved ? true : false}
									title={next?.title}
									cardType={next?.cardType}
									cardStatus={next?.cardStatus}
									taskStatus={next?.cardProgressDetails?.taskStatus}
									linkedEntityId={next?.linkedEntityId}
									desc={next?.shortDescription}
									imgSrc={next?.imageUrl}
									isAI={next?.isAiPowered}
									isPremium={next?.isPremiumFeature}
									onHeart={handleHeart}
									likes={next?.likes}
								/>
							))}
						</Box>
					)}
					{filteredNextCards.length > cardLength && <ShowCards handleShowCard={handleNextCount} isShow={showAllNext} />}
				</Box>
			</Box>
		</Box>
	);
}

export const ShowCards = ({ handleShowCard, isShow }) => {
	return (
		<Box mt={2}>
			<BtnAll onClick={handleShowCard}>{isShow ? `Collapse All` : `See All`}</BtnAll>
		</Box>
	);
};

export const BtnAll = styled(Button)({
	background: "#000",
	height: "48px",
	fontSize: "16px",
	fontWeight: 600,
	borderRadius: "100px",
	width: "auto",
	"&:focus": {
		background: "#000",
	},
});
