import React from "react";
import { Grid, Box, row, Typography, Link } from "@mui/material";
import { ROADRUNNER_MESSAGE_BOARD_ICON } from "../../../../../constants/image";
import theme from "../../../../../constants/Theme";

export const WelcomeMessageBoard = () => {
  const websiteLink = "https://startupos.com/mentors/#mentor-magic";
  return (
    <>
      <Box
        sx={{
          width: 1,
          backgroundColor: theme.palette.primary.homeBlueBackground,
        }}
      >
        <Grid container spacing={1} sx={{ py: 8, px: 2, mt: 1, mb: 1 }}>
          <Grid item xs={12} md={12} sx={{ display: "flex" }}>
            <Box
              sx={{
                flexDirection: "row",
                gap: 2,
                width: "max-content",
              }}
            >
              <Box
                src={ROADRUNNER_MESSAGE_BOARD_ICON}
                component="img"
                sx={{
                  alignSelf: "center",
                  margin: "auto",
                }}
              ></Box>
            </Box>
            <Box sx={{ pl: 4 }}>
              <Box>
                <Typography variant="title_large" sx={{ color: "#0A141B" }}>
                  Mentors,
                </Typography>
              </Box>
              <Box sx={{ pb: 3 }}>
                <Typography
                  variant="large_message_title"
                  sx={{
                    background:
                      "linear-gradient(92.58deg, #592C82 0%, #B655CC 100%)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                  }}
                >
                  Welcome Aboard!
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={12}>
            <Box
              sx={{
                gap: 3,
                cursor: "pointer",
              }}
            >
              <Box sx={{ pb: 3, pl: 5, pr: 5 }}>
                <Typography variant="subhead1">
                  As the first on-boarders of StartupOS, we’re thrilled to
                  introduce you to our platform. It’s with your mentorship and
                  guidance that our{" "}
                  <span style={{ fontWeight: "bold" }}>diverse community</span>{" "}
                  of founders will develop and thrive as they power through
                  their lessons and journeys.
                </Typography>
              </Box>
              <Box sx={{ pb: 3, pl: 5, pr: 5 }}>
                <Typography variant="subhead1">
                  We’re all here because mentors, founders, and the StartupOS
                  team all share the same goal:
                  <span style={{ fontWeight: "bold" }}>
                    {" "}
                    to strengthen relationships + drive innovation.{" "}
                  </span>
                  We’re here to help each other grow within an inclusive,
                  welcoming environment and to make a difference, one startup at
                  a time.
                </Typography>
              </Box>
              <Box sx={{ pl: 5, pr: 5 }}>
                <Typography variant="subhead1">
                  So, take a seat and have a look around as this platform livens
                  up with ambitious founders—all hungry for your words of
                  wisdom. In the meantime, take a look at our{" "}
                  <Link
                    style={{ fontWeight: "bold" }}
                    target="_blank"
                    onClick={() => window.open(websiteLink)}
                  >
                    ‘Mentor Magic’
                  </Link>
                  , which encapsulates what being a mentor means to us here at
                  StartupOS.
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
