import React from "react";
import { Container } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import CarouselCard from "./CarouselCard";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";

function MuiCarousel({ carouselItems, handleItemClick, handleApplyNow }) {
	function RowItem({ item }) {
		return (
			<Container>
				<CarouselCard itemDetails={item} handleItemClick={handleItemClick} handleApplyNow={handleApplyNow} />
			</Container>
		);
	}
	return (
		<Carousel
			autoPlay={false}
			navButtonsAlwaysVisible={true}
			animation="slide"
			indicators={false}
			navButtonsProps={{
				style: {
					backgroundColor: "transparent",
				},
			}}
			NextIcon={<ArrowForwardIosRoundedIcon sx={{ fontSize: "1.5rem" }} />}
			PrevIcon={<ArrowBackIosRoundedIcon sx={{ fontSize: "1.5rem" }} />}
		>
			{carouselItems.map((item, i) => (
				<RowItem key={i} item={item} />
			))}
		</Carousel>
	);
}

export default MuiCarousel;
