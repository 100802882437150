import { Box, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { StartupOSStepper } from "../Steps/index";
import ROUTES_PATH from "../../../constants/routes";
import { ROADRUNNER_WHITE, LOGO_FULL_WHITE } from "../../../constants/image";
import theme from "../../../constants/Theme";

export const Sidebar = ({ steps, title, activeIndex, ...props }) => {
  const [stepsArr, setSteps] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  useEffect(() => {
    setSteps(steps);
  }, [steps]);
  useEffect(() => {
    setActiveStep(activeIndex);
  }, [activeIndex]);

  const handleClick = step => {
    if (props && props.handleClick) {
      props.handleClick(step);
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: "primary.sysLight",
        flex: 1,
        display: "flex",
        height: 1,
        flexDirection: "column",
        boxSizing: "border-box",
        py: 4,
        px: 6,
        overflowY: "scroll",
      }}
    >
      <Box sx={{ width: 1, textAlign: "center", mb: "75px" }}>
        <a href={ROUTES_PATH.HOME_PAGE_URL} draggable={false}>
          <img
            draggable={false}
            src={LOGO_FULL_WHITE}
            alt="logo"
            style={{
              maxWidth: "100%",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          />
        </a>
      </Box>
      <Typography
        textTransform={"uppercase"}
        variant="Body/16pxBold"
        component={"h5"}
        color={theme.palette.secondary.white}
      >
        {title}
      </Typography>
      <Box sx={{ my: "40px" }}>
        <StartupOSStepper
          handleClick={props && props.handleClick ? handleClick : false}
          steps={stepsArr}
          connectorColorCode={theme.palette.secondary.white}
          completeColorCode={theme.palette.secondary.white}
          iconSize="medium"
          labelColorCode={theme.palette.secondary.white}
          setStep={activeStep}
        />
      </Box>

      <Box
        sx={{
          mt: "auto",
          textAlign: "center",
        }}
      >
        <img
          style={{
            maxWidth: "100%",
            marginLeft: "auto",
            marginRight: "auto",
          }}
          src={ROADRUNNER_WHITE}
          draggable={false}
          alt="logo"
        />
      </Box>
    </Box>
  );
};
