import { Stack, Skeleton, Box } from "@mui/material";
import React, { useEffect, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import StatusButton from "../../../components/Profile/Components/StatusButton";
import PublicExperienceItem from "../../../components/PublicProfile/AccountPublicProfile/ExperienceCard/PublicExperienceItem";
import { USERS_THREE_ICON } from "../../../constants";
import { UserCardContainer } from "../UserAboutCard/style";
import UserProfileCardSection from "../UserProfileCardSection";
import ProgressCompletionCalculator from "../../../utils/progressCompletionCalculator";
import SvgComponent from "../../../utils/SVGColor";

const UserExperienceDetailsCard = ({ context }) => {
	const params = useParams();
	const userId = params?.id;
	const history = useHistory();
	const experienceDetails = useSelector((state) => state?.userProfileData?.userData?.experienceDetails);
	const isLoading = useSelector((state) => state?.userProfileData?.loading);
	const { handleEditProfileRedirections, setExperiencePercentage } = useContext(context);
	const totalFields = 1;
	const completedFields = experienceDetails && experienceDetails.length > 0 ? 1 : 0;

	useEffect(() => {
		setExperiencePercentage(ProgressCompletionCalculator(totalFields, completedFields));
	}, [completedFields]);

	return (
		<UserCardContainer>
			<Stack direction={"column"} spacing={3}>
				{userId ? (
					<></>
				) : isLoading ? (
					<Skeleton animation="wave" variant="rectangular" width={100} height={50} />
				) : (
					<StatusButton percentage={ProgressCompletionCalculator(totalFields, completedFields)} handleRedirection={handleEditProfileRedirections} />
				)}
				{isLoading ? (
					<Skeleton animation="wave" variant="rectangular" width={"100%"} height={200} />
				) : (
					<UserProfileCardSection
						icon={<SvgComponent svgUrl={USERS_THREE_ICON} strokeColor={"#7B61FF"} style={{ height: "26px", width: "26px" }} />}
						title={"Experience"}
						iconColor={""}
						iconHeight={"26px"}
						iconWidth={"26px"}
						count={experienceDetails ? experienceDetails.length : null}
						isSvg={true}
					>
						<Box
							sx={{
								display: "grid",
								gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
								gridAutoRows: "1fr",
								gridGap: "36px",
								alignItems: "stretch",
							}}
						>
							{experienceDetails && experienceDetails.length > 0
								? experienceDetails?.map((item, index) => <PublicExperienceItem item={item} key={index} />)
								: "No Data"}
						</Box>
					</UserProfileCardSection>
				)}
			</Stack>
		</UserCardContainer>
	);
};

export default UserExperienceDetailsCard;
