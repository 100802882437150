import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Button, Typography, Box, CircularProgress } from "@mui/material";

import { UPLOAD_IMAGE_ICON } from "../../constants/image";
import Dropzone from "react-dropzone";
import services from "../../services";
import LoadingButton from "../Loadingbutton";
import DialogComponent from "../../containers/Workspace/PowerUP/IdeaValidation/Components/Dialog";
import MessageHandlerModal from "../modal/MessageHandler";
import VideoPlayerComponent from "../videoPlayer";
import { toastContainer } from "../../utils/toast";
import { handleMixPanel } from "../../utils/mixPanelEventHandling";

export default function UploadVideoModal({
	open,
	onClose,
	backgroundPhoto,
	setPhoto,
	photo,
	title,
	selectFileText,
	dimensionText,
	aspectRatio,
	imageType,
	fileType,
	handleUpdateAssetsUrl,
	source,
}) {
	const [localImage, setLocalImage] = useState(null);
	const [cropData, setCropData] = useState();
	const [cropper, setCropper] = useState();
	const [uploadedImg, setUploadedImg] = useState();
	const [loader, setLoader] = useState(false);
	const [deleteConfirmPopup, setDeleteConfirmPopup] = useState(false);
	const userData = useSelector((store) => ({
		userId: store?.auth?.userId,
		userName: store?.auth?.userName,
	}));
	const { isPremiumPlanAccess, userType } = useSelector((store) => store?.auth);

	const handleImageSelect = (file) => {
		if (source) {
			if (source === "ABOUT_US") {
				handleMixPanel("Startup Profile Edited", {
					userName: userData.userName,
					userId: userData.userId,
					subscription_status: isPremiumPlanAccess ? "Subscribed" : "Not Subscribed",
					user_type: userType,
				});
			} else if (source === "FOUNDER_STORY") {
				handleMixPanel("Startup Profile Edited", {
					userName: userData.userName,
					userId: userData.userId,
					subscription_status: isPremiumPlanAccess ? "Subscribed" : "Not Subscribed",
					user_type: userType,
				});
			} else if (source === "PERSONAL_PROFILE") {
				handleMixPanel("Account Profile Edited", {
					userName: userData.userName,
					userId: userData.userId,
					subscription_status: isPremiumPlanAccess ? "Subscribed" : "Not Subscribed",
					user_type: userType,
				});
			}
		}

		if (file) {
			const fileSize = (file?.size / (1024 * 1024 * 1024)).toFixed(2);

			if (fileSize < 2) {
				setLoader(true);
				setCropData(file);
				getFiles(file, "video");
			} else {
				toastContainer("Please Upload file less than 2GB", "error");
			}
		}
	};

	const handleSave = () => {
		if (typeof cropper !== "undefined") {
			setPhoto(uploadedImg);
			setCropData("");
			setLocalImage("");
			onClose();
		}

		handleMixPanel("Account Profile Edited", {
			userName: userData.userName,
			userId: userData.userId,
			subscription_status: isPremiumPlanAccess ? "Subscribed" : "Not Subscribed",
			user_type: userType,
		});
	};

	const getFiles = async (data, type) => {
		var formData = new FormData();
		formData.append("file", data);
		formData.append("fileGroup", "PROFILE");
		services
			.uploadFile(formData)
			.then((response) => {
				setLoader(false);
				setPhoto(response.data.data?.fileLocation);
				setCropData(response.data.data?.fileLocation);
				if (imageType === "COMPANY_ABOUTUS_VIDEO") {
					handleUpdateAssetsUrl(response.data.data?.fileLocation, "aboutUsVideoUrl");
				} else if (imageType === "COMPANY_FOUNDERSTORY_VIDEO") {
					handleUpdateAssetsUrl(response.data.data?.fileLocation, "founderStoryVideo");
				} else if (imageType === "USER_STORY") {
					handleUpdateAssetsUrl(response.data.data?.fileLocation, "videoUrl");
				}
				toastContainer("Video uploaded successfully", "success");
			})
			.catch((e) => {
				setLoader(false);
			});
	};

	const deleteVideoHandler = () => {
		if (imageType === "COMPANY_ABOUTUS_VIDEO") {
			handleUpdateAssetsUrl("", "aboutUsVideoUrl");
		} else if (imageType === "COMPANY_FOUNDERSTORY_VIDEO") {
			handleUpdateAssetsUrl("", "founderStoryVideo");
		} else if (imageType === "USER_STORY") {
			handleUpdateAssetsUrl("", "videoUrl");
		}

		setLoader(false);
		setPhoto(null);
		setCropData(null);
		setDeleteConfirmPopup(false);
		onClose();
	};

	// const returnSource = (source) => {
	// 	if (source === "ABOUT_US") {
	// 		return
	// 	}
	// }

	// make a handleMixPanelSource

	const handleMixPanelSource = () => {};

	useEffect(() => {
		if (open) {
			if (source) {
				if (source === "ABOUT_US") {
					console.log("Personal Profile Complete, Edit: Elevator Pitch Video");
					console.log("Personal Profile Complete");
				}

				console.log("source: ", source);
			} else {
				console.log("no source");
			}
		}

		//
	}, [open]);

	return (
		<Box>
			{" "}
			<DialogComponent
				disableBackDropClick={true}
				closeButton={!loader}
				title={title}
				titleVariant={"title_large"}
				isOpen={open}
				onClose={() => {
					onClose();
					setCropData("");
					setLocalImage("");
				}}
				footerStyle={{
					border: loader && "none",
				}}
				contentStyle={{ px: 0 }}
				maxWidth={"md"}
				fullWidth
				PaperProps={{
					sx: (theme) => ({
						borderRadius: theme.shape.standard.borderRadius,
					}),
				}}
				dialogAction={
					<>
						{!loader &&
							(cropData || photo ? (
								<Box sx={{ mr: "auto" }}>
									{" "}
									<Button
										variant="link"
										sx={{ color: "#5630B2" }}
										onClick={() => {
											setDeleteConfirmPopup(true);
										}}
									>
										Delete
									</Button>
								</Box>
							) : (
								<Box sx={{ display: "flex", gap: 1, m: 2 }}>
									<Box>
										<Button
											variant="outlined"
											onClick={() => {
												setCropData("");
												setLocalImage("");
												onClose();
											}}
											sx={{ width: "max-content", whiteSpace: "nowrap" }}
										>
											Cancel
										</Button>
									</Box>

									<LoadingButton onClick={() => handleSave()} loading={loader} loadingStyles={{ px: 3 }}>
										Save
									</LoadingButton>
								</Box>
							))}
					</>
				}
			>
				<Box sx={{ px: 3, pt: 2 }}>
					{!loader && (!cropData || !photo) && <Typography variant="title_bold">Upload local file</Typography>}

					<Dropzone
						onDrop={(acceptedFiles) => {
							handleImageSelect(acceptedFiles[0]);
						}}
						accept={fileType}
						disabled={loader}
					>
						{({ getRootProps, getInputProps }) => (
							<section>
								<div {...getRootProps()}>
									<input {...getInputProps()} />
									{cropData || photo ? (
										loader ? (
											<Box justifyContent={"center"} sx={{ display: "flex", mt: 7, minHeight: "150px" }}>
												<Box>
													<Box
														sx={(theme) => ({
															display: "flex",
															justifyContent: "center",
														})}
													>
														<CircularProgress
															sx={(theme) => ({
																height: "80px",
																width: "80px",
																color: theme.palette.text.darkBlue,
															})}
														/>
													</Box>
													<Box
														sx={(theme) => ({
															textAlign: "center",
															pt: 5,
														})}
													>
														<Typography variant="title_medium">Video upload in progress</Typography>
													</Box>
												</Box>
											</Box>
										) : (
											<Box sx={{ display: "flex", justifyContent: "center" }}>
												<VideoPlayerComponent height="auto" width="500px" videoUrl={cropData || photo} />
											</Box>
										)
									) : (
										<Box
											sx={{
												border: "1px dashed rgba(106, 117, 117, 1)",
												minHeight: "150px",
												borderRadius: "4px",
												mt: 1,
												textAlign: "center",
												p: 5,
												cursor: "pointer",
											}}
										>
											<Box component={"img"} src={UPLOAD_IMAGE_ICON} alt="img"></Box>
											<Typography
												variant="label_xlarge"
												sx={{
													mt: 1,
													display: "grid",
													color: (theme) => theme.palette.secondary.korophKoal,
												}}
											>
												{" "}
												{selectFileText}
											</Typography>
											<Typography
												variant="lable_large"
												sx={{
													mt: 1,
													display: "grid",
													color: (theme) => theme.palette.secondary.outline,
												}}
											>
												{" "}
												or drag and drop it here
											</Typography>
											<Typography
												variant="body_small"
												sx={{
													mt: 1,
													display: "grid",
													color: (theme) => theme.palette.secondary.outline,
												}}
											>
												{dimensionText}
											</Typography>
										</Box>
									)}
								</div>
							</section>
						)}
					</Dropzone>
				</Box>
			</DialogComponent>
			<MessageHandlerModal
				isOpen={deleteConfirmPopup}
				onCancel={() => setDeleteConfirmPopup(false)}
				onOk={() => {
					deleteVideoHandler();
				}}
				okText="Delete"
				cancelText={"No thanks"}
				heading={`Delete ${title}`}
				text={`Are you sure you want to delete the ${title}?`}
				messageType="primary"
			/>
		</Box>
	);
}
