import React, { Fragment } from "react";
import { Switch, useRouteMatch, Route, Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";

import WorkspaceView from "../../../components/Investor/Workspace/View";
import WorkspaceWorkHistory from "./WorkHistory";
import PowerUps from "./PowerUps";
import PowerPacks from "./PowerPacks";
import ThingsToDo from "./ThingsToDo";
import Tools from "./Tools";

export default function InvestorWorkspace() {
	const { path } = useRouteMatch();

	return (
		<Fragment>
			<Helmet>
				<meta charSet="utf-8" />
				<title>StartupOS - Home</title>
			</Helmet>
			<Switch>
				<Route path={`${path}/to-do`}>
					<WorkspaceView content={<ThingsToDo />} activeKey={1} />
				</Route>
				<Route path={`${path}/powerpacks`}>
					<WorkspaceView content={<PowerPacks />} activeKey={2} />
				</Route>
				<Route path={`${path}/powerups`}>
					<WorkspaceView content={<PowerUps />} activeKey={3} />
				</Route>
				<Route path={`${path}/tools`}>
					<WorkspaceView content={<Tools />} activeKey={4} />
				</Route>
				<Route path={`${path}/work-history`}>
					<WorkspaceView content={<WorkspaceWorkHistory />} activeKey={5} />
				</Route>
				<Route exact path={`${path}`}>
					<Redirect to={`${path}/to-do`} />
				</Route>
			</Switch>
		</Fragment>
	);
}
