import React from "react";
import { Box, useTheme, Typography } from "@mui/material";
import { Chart, CategoryScale, LineController, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale, TimeSeriesScale } from "chart.js";
import { Line } from "react-chartjs-2";
import "chartjs-adapter-date-fns";

Chart.register(CategoryScale, LineController, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale, TimeSeriesScale);

function EmptyLineChart({
	data,
	lineBorderColor,
	lineBackgroundColor,
	tooltipYLable,
	xAxisKey = "date",
	yAxisKey,
	yAxisType = "count",
	borderWidth = 2,
	datasets = [],
	fill = true,
}) {
	return (
		<Line
			data={{
				datasets: [
					{
						label: tooltipYLable,
						data: data,
						borderWidth: 2,
						borderColor: lineBorderColor,
						backgroundColor: lineBackgroundColor,
						tension: 0.15,
						fill: fill,
						parsing: {
							xAxisKey: xAxisKey,
							yAxisKey: yAxisKey,
						},
						pointRadius: 0,
						pointHoverRadius: 3,
						clip: false,
					},
					...datasets,
				],
			}}
			options={{
				clip: false,
				maintainAspectRatio: true,
				responsive: true,
				hover: {
					mode: "index",
					intersect: false,
				},
				font: {
					family: '"PoppinsRegular", "Helvetica", "Arial", sans-serif',
				},
				plugins: {
					legend: {
						display: false,
					},
					tooltip: {
						enabled: false,
					},
					datalabels: {
						display: false,
					},
				},
				scales: {
					x: {
						type: "time",
						time: {
							unit: "month",
							tooltipFormat: "MMM yyyy",
							displayFormats: {
								month: "MMM yyyy",
							},
						},
						ticks: {
							display: false,
						},
						grid: {
							drawBorder: false,
							display: false,
						},
					},
					y: {
						beginAtZero: true,
						ticks: {
							display: false,
						},
						grid: {
							drawBorder: false,
							display: false,
						},
					},
				},
				layout: {
					padding: {
						right: 5,
					},
				},
			}}
		/>
	);
}

const chartData = [
	{
		id: 1,
		date: "2023-01-01",
		yValue: 200,
	},
	{
		id: 2,
		date: "2023-02-01",
		yValue: 205,
	},
	{
		id: 3,
		date: "2023-03-01",
		yValue: 200,
	},
	{
		id: 4,
		date: "2023-04-01",
		yValue: 205,
	},
	{
		id: 5,
		date: "2023-05-01",
		yValue: 208,
	},
	{
		id: 6,
		date: "2023-06-01",
		yValue: 210,
	},
	{
		id: 7,
		date: "2023-07-01",
		yValue: 211,
	},
	{
		id: 8,
		date: "2023-08-01",
		yValue: 212,
	},
	{
		id: 9,
		date: "2023-09-01",
		yValue: 211,
	},
	{
		id: 10,
		date: "2023-10-01",
		yValue: 212,
	},
	{
		id: 11,
		date: "2023-11-01",
		yValue: 212,
	},
	{
		id: 12,
		date: "2023-12-01",
		yValue: 212,
	},
	{
		id: 13,
		date: "2024-01-01",
		yValue: 213,
	},
	{
		id: 14,
		date: "2024-02-01",
		yValue: 215,
	},
	{
		id: 15,
		date: "2024-03-01",
		yValue: 214,
	},
	{
		id: 16,
		date: "2024-04-01",
		yValue: 210,
	},
	{
		id: 17,
		date: "2024-05-01",
		yValue: 215,
	},
	{
		id: 18,
		date: "2024-06-01",
		yValue: 218,
	},
	{
		id: 19,
		date: "2024-07-01",
		yValue: 220,
	},
	{
		id: 20,
		date: "2024-08-01",
		yValue: 222,
	},
	{
		id: 21,
		date: "2024-09-01",
		yValue: 226,
	},
	{
		id: 22,
		date: "2024-10-01",
		yValue: 230,
	},
	// {
	// 	id: 23,
	// 	date: "2024-11-01",
	// 	yValue: 380,
	// },
];

function ZeroLineChart({ readOnly = false }) {
	const theme = useTheme();
	return (
		<Box sx={{ mt: "auto" }}>
			{!readOnly && (
				<Box display="flex" alignItems="center" justifyContent="center" mt={4}>
					<Box
						sx={{
							px: "14px",
							py: "8px",
							textAlign: "center",
							backgroundColor: theme.palette.sos.gray100,
							borderRadius: "100px",
							boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
						}}
					>
						<Typography variant="Text/xs/Semibold">Add Data</Typography>
					</Box>
				</Box>
			)}
			<EmptyLineChart
				data={chartData}
				tooltipYLable={"Empty Line"}
				yAxisKey={"yValue"}
				lineBorderColor={theme.palette.sos.gray500}
				lineBackgroundColor={(context) => {
					const ctx = context?.chart?.ctx;
					const chartHeight = context?.chart?.height || 0; // Get the chart's height
					const gradient = ctx?.createLinearGradient(0, 0, 0, chartHeight);
					gradient?.addColorStop(0, "rgba(69, 69, 69, 0.08)");
					gradient?.addColorStop(0.33, "rgba(69, 69, 69, 0.05)");
					gradient?.addColorStop(0.5, "rgba(69, 69, 69, 0.03)");
					gradient?.addColorStop(0.66, "rgba(127, 86, 217, 0.01)");
					gradient?.addColorStop(0.76, "rgba(0, 0, 0, 0)");
					gradient?.addColorStop(1, "rgba(0, 0, 0, 0)");
					return gradient;
				}}
			/>
		</Box>
	);
}

export default ZeroLineChart;
