import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { CONTACT_ICONS_MAP } from "../../../../../../../constants/powerup-data";
import { TOOLS } from "../../../../../../../constants/powerup-data";
import MultiValueSelectInput from "../../../../../../../common/MultiValueSelectInput";
import services from "../../../../../../../services";
import IconPicker from "../../../../../../../common/IconPicker";
import AutocompleteInput from "../../../../../../../common/AutocompleteInput";
import messages from "../../../../../../../constants/messages";

export default function JobNeeds({
  userInputs,
  setUserInputs,
  jobNeedsInputValid,
  fieldDisable,
}) {
  const [autoCompleteData, setAutoCompleteData] = useState([]);

  useEffect(() => {
    services
      .autoCompleteData("jobActivity")
      .then(response => {
        const values = response?.data?.data?.values;
        if (values && values.length) {
          setAutoCompleteData(values);
        }
      })
      .catch(err => console.log(err));
  }, []);

  const handleInputChange = (e, key) => {
    let value = e.target.value;
    const currentInput = { ...userInputs };
    currentInput[key] = value;
    setUserInputs(currentInput);
  };

  const setIcon = url => {
    const currentIcons = userInputs.communicationMethods;
    const existingIcon = currentIcons.find(icon => icon === url);
    if (!fieldDisable) {
      if (existingIcon) {
        const filteredIcons = currentIcons.filter(icon => icon !== url);
        setUserInputs({ ...userInputs, communicationMethods: filteredIcons });
      } else {
        currentIcons.push(url);
        setUserInputs({ ...userInputs, communicationMethods: currentIcons });
      }
    }
  };

  return (
    <Box>
      <Box sx={{ mt: 8 }}>
        <Typography variant="h5">
          What tools do they need to do their job?
        </Typography>

        <AutocompleteInput
          label={"Tools"}
          handleInputChange={handleInputChange}
          inputKey={"tools"}
          autoCompleteData={TOOLS}
          inputValue={userInputs ? [...userInputs.tools] : []}
          inputValid={jobNeedsInputValid}
          errorMessage={messages.MULTITAG_INPUT_INVALID}
          sx={{ mt: 2 }}
          disabled={fieldDisable}
          typingEnabled
          multiple
          handleOnBlurEvent={true}
        />
      </Box>

      <Box sx={{ mt: 8 }}>
        <Typography variant="h5">
          What is their preferred method of communication?
        </Typography>
        <Typography variant="bodyMedium" color="secondary.main">
          Select all that apply.
        </Typography>
        <IconPicker
          icons={CONTACT_ICONS_MAP}
          selectedIcon={userInputs?.communicationMethods}
          setSelectedIcon={setIcon}
          iconHeight={"18px"}
          iconWidth={"18px"}
          multiSelect
          isCustomIcon
        ></IconPicker>
      </Box>

      <Box sx={{ mt: 8 }}>
        <Typography variant="h5">
          What do they do to add skills for their job?
        </Typography>
        <AutocompleteInput
          placeholder={"Activities"}
          handleInputChange={handleInputChange}
          inputKey={"jobActivity"}
          autoCompleteData={autoCompleteData}
          inputValue={userInputs ? [...userInputs.jobActivity] : []}
          inputValid={jobNeedsInputValid}
          disabled={fieldDisable}
          sx={{ mt: 2 }}
          typingEnabled
          multiple
          handleOnBlurEvent={true}
        />
      </Box>
    </Box>
  );
}
