import { Divider, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";
import theme from "../../../constants/Theme";

import DemoDayProgress from "./ProgressInstruments/DemodayProgress";
import SliderProgress from "./ProgressInstruments/SliderProgress";
import ScoreProgress from "./ProgressInstruments/StartupOSScoreProgress";

const StaticContent = ({
  display = "desktopContainer",
  data,
  progressDetails,
}) => {
  const textTheme =
    display === "mobileContainer"
      ? theme.palette.secondary.white
      : theme.palette.text.primary;

  const getExpectedValue = () => {
    let now = new Date();
    let distance = data.demoDate - now;
    let expectedValue = Math.ceil(distance / (1000 * 60 * 60 * 24));
    if (expectedValue >= 0) {
      return progressDetails?.maxScore - expectedValue;
    } else {
      return progressDetails?.maxScore;
    }
  };

  return (
    <Grid
      container
      alignItems={"center"}
      style={{
        color: textTheme,
      }}
    >
      <Grid item md={5} lg={5} sm={6} xs={6} textAlign="center">
        <Box sx={{ pb: 3 }}>
          <Typography variant="title_small">
            {display === "mobileContainer"
              ? "Pitch Day"
              : "Pitch Day Countdown"}
          </Typography>
        </Box>

        <DemoDayProgress
          demoDate={data.demoDate}
          demoDateRange={data.unitDetails.demoDateRange}
          id={
            display === "desktopContainer"
              ? "demoDayProgressDesktop"
              : "demoDayProgressMobile"
          }
          textTheme={textTheme}
        />
      </Grid>
      <Grid item md={1} lg={1} display={{ xs: "none", md: "flex" }}>
        <Divider
          orientation="vertical"
          flexItem
          sx={{
            height: "170px", //specifying absolute height of divider to match the container height
            mx: "auto",
          }}
        />
      </Grid>
      <Grid item md={5} lg={5} sm={6} xs={6} textAlign="center">
        <Box sx={{ pb: 3 }}>
          <Typography variant="title_small">StartupOS Score</Typography>
        </Box>
        {data.startUpScore}
        <ScoreProgress
          value={data.startUpScore}
          maxValue={data.unitDetails.startUpMaxScore}
          id={
            display === "desktopContainer"
              ? "progressScoreDesktop"
              : "progressScoreMobile"
          }
          textTheme={textTheme}
        />
      </Grid>
      <Grid item md={12} lg={12} sm={12} xs={12} sx={{ pt: 6 }}>
        <SliderProgress
          id={display === "desktopContainer" ? "sliderDesktop" : "sliderMobile"}
          value={progressDetails?.actualScore}
          expectedValue={getExpectedValue()}
          maxScore={progressDetails?.maxScore}
        />
      </Grid>
    </Grid>
  );
};

export default StaticContent;
