import React, { useEffect, useState } from "react";
import { Typography, Box, Button, Grid, InputAdornment, IconButton, TextField, Switch, CircularProgress, Stack, Item, useMediaQuery } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import moment from "moment";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import CommonSlideDialog from "../../../../common/CommonSlideDialog";
import UpdatedTextInputField from "../../../../common/UpdatedTextInputField";
import ReusableQuill from "../../../../common/ReusableQuill";
import services from "../../../../services";
import { toastContainer } from "../../../../utils/toast";
import EventImageUploader from "../EventImageUploader";
import messages from "../../../../constants/messages";
import { ConnectingAirportsOutlined } from "@mui/icons-material";

export default function AddEvent({ open, onClose, getEventList, isEdit = false, eventObj = null }) {
	const schema = yup.object({
		title: yup.string().required("Title is required"),
		description: yup.string().required("Description is required").nullable(),
		isPitchEvent: yup.boolean(),
		isPremiumEvent: yup.boolean(),
		isFeatureEvent: yup.boolean(),
		redirectLink: yup.string(),
		displayDate: yup.string().required("Display date is required"),
		showDate: yup.string().nullable(),
		eventDate: yup.string().required("Event date is required"),
		deadlineDate: yup.string().required("Deadline date is required"),
		hideDate: yup.string().nullable(),
	});

	const [loading, setLoading] = useState(false);

	const [heroImage, setHeroImage] = useState("");
	const [cardImage, setCardImage] = useState("");

	const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
	const [heroImageData, setHeroImageData] = useState({});
	const [cardImageData, setCardImageData] = useState({});
	//name and size for heroImage
	const [heroImageName, setHeroImageName] = useState("Untitled Image");
	const [heroImageSize, setHeroImageSize] = useState(0);
	//name and size for cardImage
	const [cardImageName, setCardImageName] = useState("Untitled Image");
	const [cardImageSize, setCardImageSize] = useState(0);
	const {
		handleSubmit,
		control,
		getValues,
		setValue,
		watch,
		reset,
		formState: { errors, isValid },
	} = useForm({
		mode: "all",
		defaultValues: {
			title: isEdit ? eventObj?.title : "",
			description: isEdit ? eventObj?.title : null,
			isPitchEvent: isEdit ? eventObj?.title : false,
			isPremiumEvent: isEdit ? eventObj?.title : false,
			isFeatureEvent: isEdit ? eventObj?.title : false,
			redirectLink: isEdit ? eventObj?.title : "",
			displayDate: isEdit ? eventObj?.title : "",
			showDate: isEdit ? eventObj?.title : null,
			eventDate: isEdit ? eventObj?.title : null,
			deadlineDate: isEdit ? eventObj?.title : null,
			hideDate: isEdit ? eventObj?.title : null,
		},
		resolver: yupResolver(schema),
	});
	const deadlineDate = watch("deadlineDate") || null;
	const eventDate = watch("eventDate") || null;
	const showDate = watch("showDate") || null;

	useEffect(() => {
		if (open) {
			setHeroImage(isEdit ? eventObj?.heroImageUrl || "" : "");
			setCardImage(isEdit ? eventObj?.imageUrl || "" : "");

			reset({
				title: isEdit ? eventObj?.title || "" : "",
				description: isEdit ? eventObj?.description : null,
				isPitchEvent: isEdit ? eventObj?.isPitchEvent : false,
				isPremiumEvent: isEdit ? eventObj?.isPremiumEvent : false,
				isFeatureEvent: isEdit ? eventObj?.isFeatureEvent : false,
				redirectLink: isEdit ? eventObj?.redirectLink || "" : "",
				displayDate: isEdit ? eventObj?.displayDate || "" : "",
				showDate: isEdit ? eventObj?.showDate : null,
				eventDate: isEdit ? eventObj?.eventDate : null,
				deadlineDate: isEdit ? eventObj?.deadlineDate : null,
				hideDate: isEdit ? eventObj?.hideDate : null,
			});

			if (isEdit) {
				const metaData = eventObj?.metadata && JSON.parse(eventObj?.metadata);
				if (metaData?.length > 0) {
					const heroImgData = metaData[0];
					const cardImgData = metaData[1];
					setHeroImageName(heroImgData.name);
					setHeroImageSize(heroImgData.size);
					setCardImageName(cardImgData.name);
					setCardImageSize(cardImgData.size);
				} else {
					setHeroImageName("Untitled Image");
					setHeroImageSize(0);
					setCardImageName("Untitled Image");
					setCardImageSize(0);
				}
			}
		}
	}, [open]);

	const handleChange = (event, key, onChange) => {
		if (key === "companySize" || key === "noOfCoFounders") {
			let newValue = event.target.value;
			onChange(newValue.replace(/[^0-9]/g, ""));
		} else if (key === "isPitchEvent" || key === "isPremiumEvent" || key === "isFeatureEvent") {
			onChange(event.target.checked);
		} else if (key === "displayDate" || key === "showDate" || key === "eventDate" || key === "deadlineDate" || key === "hideDate") {
			onChange(event);
		} else if (key === "description") {
			onChange(event);
		} else {
			onChange(event.target.value);
		}
	};

	const onSubmit = async () => {
		try {
			setLoading(true);
			let link = getValues("redirectLink");
			if (!getValues("redirectLink")?.startsWith("https://") && !getValues("redirectLink")?.startsWith("http://")) {
				link = "https://" + getValues("redirectLink");
			}
			const payload = {
				title: getValues("title"),
				description: getValues("description"),
				imageUrl: cardImage,
				heroImageUrl: heroImage,
				redirectLink: getValues("redirectLink") ? link : null,
				isPitchEvent: getValues("isPitchEvent"),
				isPremiumEvent: getValues("isPremiumEvent"),
				isFeatureEvent: getValues("isFeatureEvent"),
				displayDate: getValues("displayDate"),
				showDate: getValues("showDate") && moment(getValues("showDate")).format("YYYY-MM-DD"),
				eventDate: getValues("eventDate") && moment(getValues("eventDate")).format("YYYY-MM-DD"),
				deadlineDate: getValues("deadlineDate") && moment(getValues("deadlineDate")).format("YYYY-MM-DD"),
				hideDate: getValues("hideDate") && moment(getValues("hideDate")).format("YYYY-MM-DD"),
				metadata: JSON.stringify([heroImageData, cardImageData]),
			};
			let resData = null;

			if (isEdit) {
				resData = await services.updateEventApi(payload, eventObj?.uuid);
			} else {
				resData = await services.createEventApi(payload);
			}
			if (resData) {
				onClose();
				reset();
				setHeroImage("");
				setCardImage("");
				getEventList();
				toastContainer(isEdit ? messages.EVENT_UPDATED_SUCCESS : messages.EVENT_CREATED_SUCCESS);
			}
			setLoading(false);
		} catch (err) {
			setLoading(false);
			toastContainer(err?.response.data?.message, "error");
		}
	};
	const CommonDatePicker = ({ value, type, onChange, disableFuture = true, disablePast = false, maxDate, minDate }) => {
		const [openDatePicker, setOpenDatePicker] = useState(false);
		const datePickerClick = () => {
			if (openDatePicker === false) {
				setOpenDatePicker(true);
			}
		};
		return (
			<Box onClick={datePickerClick}>
				<LocalizationProvider dateAdapter={AdapterMoment}>
					<DatePicker
						open={openDatePicker}
						onClose={() => setOpenDatePicker(false)}
						format="MM/DD/YYYY"
						maxDate={maxDate}
						minDate={minDate}
						value={value ? moment(value) : null}
						disableFuture={disableFuture}
						disablePast={disablePast}
						onChange={(newValue) => {
							handleChange(moment(newValue).toDate(), type, onChange);
						}}
						slots={{
							OpenPickerIcon: CalendarTodayOutlinedIcon,
						}}
						sx={{
							cursor: "pointer",
							"& .MuiOutlinedInput-root": {
								p: "6px",
							},
							"& input::placeholder": {
								fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
								fontSize: "16px",
								lineHeight: "24px",
							},
							"& .MuiInputBase-root": {
								fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
								fontSize: "16px",
								lineHeight: "24px",
								height: "60px",
								width: "100%",
								borderRadius: "30px",
								background: "white",
							},

							"&.MuiTextField-root": {
								width: "100%",
								borderRadius: "30px",
								background: "white",
							},
						}}
						slotProps={{
							openPickerButton: {
								sx: {
									mr: "5px",
								},
							},
							field: {
								InputProps: {
									endAdornment: (
										<InputAdornment position="end" sx={{ mr: 1.5 }}>
											<IconButton edge="end">
												<CalendarTodayOutlinedIcon sx={{ color: "#000" }} />
											</IconButton>
										</InputAdornment>
									),
									variant: "outlined",
									sx: {
										borderRadius: "30px",
										height: "60px",
										fontWeight: "500",
										fontSize: "16px",
										lineHeight: "24px",
										padding: "1.5px 6px",
									},
								},
							},
						}}
					/>
				</LocalizationProvider>
			</Box>
		);
	};

	return (
		<CommonSlideDialog
			open={open}
			slidingEffect={false}
			onClose={onClose}
			title={
				<Box display="flex" flexDirection="column" p="0 0 8px">
					<Typography variant="Text/md/Semibold" sx={{ mt: 1.5 }}>
						{`New Event`}
					</Typography>
				</Box>
			}
			titleVariant="Text/md/Semibold"
			maxWidth="md"
			btnCloseStyle={{ margin: "0 24px 0 0" }}
			dialogAction={
				<Box display={"flex"} alignItems={"center"} gap={2}>
					<Button onClick={onClose} sx={{ color: (theme) => theme.palette.secondary.light }} variant="noOutlineNew">
						Cancel
					</Button>
					<Button
						startIcon={loading && <CircularProgress size={"1.25rem"} sx={{ color: "inherit" }} />}
						variant="DS1"
						type="submit"
						onClick={onSubmit}
						disabled={!isValid || !heroImage}
					>
						{isEdit ? "Save" : "Create"}
					</Button>
				</Box>
			}
			PaperProps={{
				sx: (theme) => ({
					position: "fixed",
					top: 0,
					borderRadius: "30px",
					m: 0,
					p: 2,
					[theme.breakpoints.down("md")]: {
						px: 1,
						py: 1.5,
					},
					[theme.breakpoints.down("sm")]: {
						p: 0,
						m: -1,
					},
				}),
			}}
		>
			{" "}
			<form onSubmit={handleSubmit(onSubmit)}>
				<Box>
					{" "}
					<Grid container rowSpacing={2} columnSpacing={7}>
						<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
							<Typography variant="Text/sm/Medium">{`${
								isEdit ? "Edit an existing " : "Create a new "
							}Event by filling out the details below`}</Typography>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
							<Box
								display="flex"
								gap={2}
								marginTop={"20px"}
								flexDirection={isSmallScreen ? "column" : "row"}
								alignItems={isSmallScreen ? "center" : "normal"}
							>
								<EventImageUploader
									title={"Hero Image"}
									instructions={"Your Profile photo will be displayed is a circular view."}
									shape={"rectangle"}
									uploadedImage={heroImage}
									setUploadedImage={(item) => setHeroImage(item)}
									circularCropper={false}
									aspectRatio={5 / 2}
									fileType={{ "image/png": [], "image/jpg": [], "image/jpeg": [] }}
									readOnly={false}
									sx={{ border: "1px solid #EAECF0", borderRadius: "12px", width: 1 }}
									imageSizeInstruction={"PNG, JPG or JPEG (max. 800x400px)"}
									setImageData={setHeroImageData}
									imgName={heroImageName}
									imgSize={heroImageSize}
									setImgName={setHeroImageName}
									setImgSize={setHeroImageSize}
								/>

								<EventImageUploader
									title={"Card Image"}
									instructions={"Your Profile photo will be displayed is a circular view."}
									shape={"rectangle"}
									uploadedImage={cardImage}
									setUploadedImage={(item) => setCardImage(item)}
									circularCropper={false}
									aspectRatio={5 / 3}
									fileType={{ "image/png": [], "image/jpg": [], "image/jpeg": [] }}
									readOnly={false}
									sx={{ border: "1px solid #EAECF0", width: 1 }}
									imageSizeInstruction={"PNG, JPG or JPEG (max. 338x216px)"}
									setImageData={setCardImageData}
									imgName={cardImageName}
									imgSize={cardImageSize}
									setImgName={setCardImageName}
									setImgSize={setCardImageSize}
								/>
							</Box>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
							<Typography variant="Text/sm/Medium">{"Title*"}</Typography>
							<Box sx={{ mt: 1 }}>
								<Controller
									name="title"
									control={control}
									render={({ field: { onChange, value, name } }) => (
										<UpdatedTextInputField
											control={control}
											id={"title"}
											value={value}
											inputProps={{ maxLength: 255 }}
											name={name}
											sx={{
												"& .MuiOutlinedInput-notchedOutline": {
													border: errors.name ? "1px solid #F04438 !important" : "1px solid #DDDDDD",
												},
											}}
											handleChange={onChange}
											placeholder={"Type here"}
											textFieldProps={{
												fullwidth: true,

												width: "100%",
												height: "60px",
											}}
										/>
									)}
								/>
								{errors.title && (
									<Typography variant="subhead2" sx={{ color: "#F04438" }}>
										{errors.title.message}
									</Typography>
								)}
							</Box>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
							<Typography variant="Text/sm/Medium">{"Description*"}</Typography>
							<Box sx={{ mt: 1 }}>
								<Controller
									name="description"
									control={control}
									render={({ field: { onChange, value, name } }) => (
										<Box sx={{ backgroundColor: "white", borderRadius: "30px", p: 2, border: "1px solid #D0D5DD" }}>
											<ReusableQuill
												id={"event-description"}
												value={value}
												placeholder={"Type here"}
												onChange={(e) => handleChange(e, "description", onChange)}
												className={"profile-container"}
											/>
										</Box>
									)}
								/>
								{errors.description && (
									<Typography variant="subhead2" sx={{ color: "#F04438" }}>
										{errors.description.message}
									</Typography>
								)}
							</Box>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
							<Controller
								name="isPitchEvent"
								control={control}
								render={({ field: { onChange, value, name } }) => (
									<Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
										<Typography variant="Text/sm/Medium">{"Pitch Event?"}</Typography>

										<Switch
											color="primary"
											onChange={(e) => handleChange(e, "isPitchEvent", onChange)}
											checked={value}
											inputProps={{ "aria-label": "controlled" }}
										/>
									</Box>
								)}
							/>
						</Grid>{" "}
						<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
							<Controller
								name="isPremiumEvent"
								control={control}
								render={({ field: { onChange, value, name } }) => (
									<Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
										<Typography variant="Text/sm/Medium">{"Premium Event?"}</Typography>

										<Switch
											color="primary"
											onChange={(e) => handleChange(e, "isPremiumEvent", onChange)}
											checked={value}
											inputProps={{ "aria-label": "controlled" }}
										/>
									</Box>
								)}
							/>
						</Grid>{" "}
						<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
							<Controller
								name="isFeatureEvent"
								control={control}
								render={({ field: { onChange, value, name } }) => (
									<Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
										<Typography variant="Text/sm/Medium">{"Feature Event?"}</Typography>

										<Switch
											color="primary"
											onChange={(e) => handleChange(e, "isFeatureEvent", onChange)}
											checked={value}
											inputProps={{ "aria-label": "controlled" }}
										/>
									</Box>
								)}
							/>
						</Grid>{" "}
						<Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
							<Typography variant="Text/sm/Medium">{"Redirection Link"}</Typography>
							<Controller
								name="redirectLink"
								control={control}
								render={({ field: { onChange, value, name } }) => (
									<UpdatedTextInputField
										id={name}
										handleChange={(e) => handleChange(e, "redirectLink", onChange)}
										inputValue={value && value.replace("https://", "").replace("http://", "")}
										placeholder={name}
										textFieldProps={{
											fullwidth: true,
											width: "100%",
											height: "60px",
										}}
										sx={{ mt: 1 }}
										InputProps={{
											startAdornment: (
												<Typography component={"p"} sx={{ pl: 2 }} variant="title_medium">
													https://
												</Typography>
											),
										}}
									/>
								)}
							/>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
							<Typography variant="Text/sm/Medium">{"Display Date*"}</Typography>
							<Box sx={{ mt: 1 }}>
								<Controller
									name="displayDate"
									control={control}
									render={({ field: { onChange, value, name } }) => (
										<UpdatedTextInputField
											control={control}
											id={"displayDate"}
											value={value}
											name={name}
											inputProps={{ maxLength: 255 }}
											sx={{
												"& .MuiOutlinedInput-notchedOutline": {
													border: errors.name ? "1px solid #F04438 !important" : "1px solid #DDDDDD",
												},
											}}
											handleChange={onChange}
											placeholder={"Display Date"}
											textFieldProps={{
												fullwidth: true,
												width: "100%",
												height: "60px",
											}}
										/>
									)}
								/>{" "}
								<Typography variant="Text/xs/Regular" sx={{ color: "#475467" }}>
									The ‘friendly’ date to show on the events page.{" "}
								</Typography>
							</Box>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
							<Typography variant="Text/sm/Medium">{"Event Date*"}</Typography>
							<Box sx={{ mt: 1 }}>
								<Controller
									name="eventDate"
									control={control}
									render={({ field: { onChange, value, name } }) => (
										<CommonDatePicker
											value={value}
											type={"eventDate"}
											onChange={onChange}
											disableFuture={false}
											disablePast={true}
											maxDate={null}
											minDate={deadlineDate && moment(deadlineDate)}
										/>
									)}
								/>
								<Typography variant="Text/xs/Regular" sx={{ color: "#475467" }}>
									The date to show when the event happened.{" "}
								</Typography>
							</Box>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
							<Typography variant="Text/sm/Medium">{"Start Date"}</Typography>
							<Box sx={{ mt: 1 }}>
								<Controller
									name="showDate"
									control={control}
									render={({ field: { onChange, value, name } }) => (
										<CommonDatePicker
											value={value}
											type="showDate"
											onChange={onChange}
											disableFuture={false}
											disablePast={true}
											maxDate={(deadlineDate && moment(deadlineDate)) || (eventDate && moment(eventDate))}
											minDate={null}
										/>
									)}
								/>
								<Typography variant="Text/xs/Regular" sx={{ color: "#475467" }}>
									The date to start showing this event on the events page for users.{" "}
								</Typography>
							</Box>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
							<Typography variant="Text/sm/Medium">{"Deadline to Apply to Pitch/Register for Event*"}</Typography>
							<Box sx={{ mt: 1 }}>
								<Controller
									name="deadlineDate"
									control={control}
									render={({ field: { onChange, value, name } }) => (
										<CommonDatePicker
											value={value}
											type="deadlineDate"
											onChange={onChange}
											disableFuture={false}
											disablePast={true}
											maxDate={eventDate && moment(eventDate).subtract(1, "days")}
										/>
									)}
								/>
								<Typography variant="Text/xs/Regular" sx={{ color: "#475467" }}>
									The date to disable the “Apply to Pitch” button or “Register for Event”
								</Typography>
							</Box>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
							<Typography variant="Text/sm/Medium">{"Hide Date"}</Typography>
							<Box sx={{ mt: 1 }}>
								<Controller
									name="hideDate"
									control={control}
									render={({ field: { onChange, value, name } }) => (
										<CommonDatePicker
											value={value}
											type="hideDate"
											disableFuture={false}
											disablePast={true}
											onChange={onChange}
											minDate={getValues("showDate") && moment(getValues("showDate")).add(1, "days")}
										/>
									)}
								/>
								<Typography variant="Text/xs/Regular" sx={{ color: "#475467" }}>
									The date to hide this event on the events page for users.{" "}
								</Typography>
							</Box>
						</Grid>
					</Grid>
				</Box>
			</form>
		</CommonSlideDialog>
	);
}
