import React, { useState, useEffect } from "react";
import CommonSlideDialog from "../../../../common/CommonSlideDialog";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { setBIDashboardData } from "../../../../modules/actions/biDashboardActions";
import {
	dataCollectionFromPartner,
	getAcvGridData,
	getCapTableData,
	getAnnualRevenueGridData,
	getBurnRunwayGridData,
	getCustomerGridData,
	getMonthlyRevenueGridData,
	getNetArrGridData,
	getPaybackGridData,
	getRevenueGridData,
	getSmExpenseVsNewSalesGridData,
	partners,
	fetchPlaidData,
	getCompanyDetails,
	getPlaidAccounts,
	sendPlaidAccounts,
	getBurnMultipeGridData,
	getMagicNumberGridData,
} from "../Functions/common";
import { Box, CircularProgress, IconButton, Typography, Button } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { capitalize } from "lodash";
import CloseIcon from "@mui/icons-material/Close";
import CachedIcon from "@mui/icons-material/Cached";
import ROUTES_PATH from "../../../../constants/routes";
import PlaidAccounts from "./PlaidAccounts";
import { LINK_PARTNER_CHECK_CIRCLE_PURPLE, LINK_PARTNER_GRID_PURPLE, LINK_PARTNER_SUCCESS_CHECK_GREEN } from "../../../../constants";

function PartnerIntegration({ companyId }) {
	const dispatch = useDispatch();
	const openPartnerIntegration = useSelector((store) => store?.biDashboard?.openPartnerIntegration);
	const [isCollecting, setCollecting] = useState(true);
	const [isCollected, setCollected] = useState(false);
	const [isMapping, setMapping] = useState(false);
	const [isMapped, setMapped] = useState(false);
	const [isSyncing, setSyncing] = useState(false);
	const [isSynced, setSynced] = useState(false);
	const [error, setError] = useState(false);
	const linkPartnerkey = useSelector((store) => store?.biDashboard?.linkPartnerkey);
	const history = useHistory();
	const location = useLocation();
	const pathname = location.pathname;
	const searchParams = new URLSearchParams(location.search);
	const code = searchParams.get("code");
	const state = searchParams.get("state");
	const realmId = searchParams.get("realmId");
	// Extract the value that comes after "startupgrid/"
	const startupgridValue = pathname.match(/startupgrid\/([^/]+)/);
	const valueAfterStartupgrid = startupgridValue ? startupgridValue?.[1] : "";
	const partnerDetails = partners.find((partner) => partner.key === linkPartnerkey);
	const xeroState = useSelector((store) => store?.biDashboard?.xeroState);
	const quickbooksState = useSelector((store) => store?.biDashboard?.quickbooksState);
	const plaidCode = useSelector((store) => store?.biDashboard?.plaidCode);
	const [plaidDataError, setPlaidDataError] = useState(false);

	const ownCompanyId = useSelector((state) => state?.auth?.companyId);
	const usedCompanyId = companyId ? companyId : ownCompanyId;

	useEffect(() => {
		if (valueAfterStartupgrid && code && state && partnerDetails) {
			if (partnerDetails?.key === "xero" && state !== xeroState) {
				return; //return if zero state not matches
			}
			if (partnerDetails?.key === "quickbooks" && state !== quickbooksState) {
				return; //return if quickbooks state not matches
			}
			dispatch(setBIDashboardData({ openPartnerIntegration: true }));
		}
	}, [location.pathname]);

	const dataCollection = async () => {
		try {
			setError(false);
			setCollecting(true);
			const payload = { code: code, ...(realmId && { realmId }) };
			await dataCollectionFromPartner({
				partner: valueAfterStartupgrid,
				payload,
			});
			dispatch(setBIDashboardData({ xeroState: "", quickbooksState: "" })); // onReload it won't open dialog for xero config
			setCollected(true);
			setCollecting(false);
		} catch (error) {
			setCollecting(false);
			console.log(error);
			setError(true);
		}
	};

	const pollPlaidAccountsData = ({ assetReportId, pollInterval = 2000, pollDuration = 1000 * 60 * 2 }) => {
		const pollingIntervalId = setInterval(async () => {
			const response = await getPlaidAccounts(assetReportId);
			if (response?.items?.[0]?.accounts) {
				clearInterval(pollingIntervalId);
				const filteredAccounts = response?.items?.[0]?.accounts?.filter((account) => account?.daysAvailable > 0);
				let plaidAccountsData = filteredAccounts ? filteredAccounts : [];
				setCollected(true);
				setCollecting(false);
				dispatch(setBIDashboardData({ openPlaidAccountsSelect: true, plaidCode: "", plaidAccountsData }));
				handleClose();
			}
		}, pollInterval);

		// Stop polling after the specified duration
		setTimeout(() => {
			clearInterval(pollingIntervalId);
			setPlaidDataError(true);
			setCollecting(false);
		}, pollDuration);
	};

	const dataCollectionFromPlaid = async () => {
		try {
			setError(false);
			setCollecting(true);
			const response = await fetchPlaidData({ code: plaidCode });
			if (response?.assetReportId) {
				dispatch(setBIDashboardData({ plaidAssetReportId: response?.assetReportId }));
			}
			pollPlaidAccountsData({ assetReportId: response?.assetReportId });
		} catch (error) {
			setCollecting(false);
			console.log(error);
			setError(true);
		}
	};

	const dataMapping = async () => {
		try {
			setError(false);
			setMapping(true);
			// await dataCollectionFromPartner({ partner: valueAfterStartupgrid, code: code });
			setMapped(true);
			setMapping(false);
		} catch (error) {
			setMapping(false);
			console.log(error);
			setError(true);
		}
	};

	const refreshData = async () => {
		try {
			setError(false);
			setSyncing(true);

			const acvGridDataResponse = await getAcvGridData(usedCompanyId);
			const capTableDataResponse = await getCapTableData(usedCompanyId);
			const annualRevenueGridDataResponse = await getAnnualRevenueGridData(usedCompanyId);
			const burnRunwayGridDataresponse = await getBurnRunwayGridData(usedCompanyId);
			const customerGridDataResponse = await getCustomerGridData(usedCompanyId);
			const monthlyRevenueGridDataResponse = await getMonthlyRevenueGridData(usedCompanyId);
			const netArrGridDataResponse = await getNetArrGridData(usedCompanyId);
			const paybackGridDataResponse = await getPaybackGridData(usedCompanyId);
			const revenueGridDataResponse = await getRevenueGridData(usedCompanyId);
			const smExpenseVsNewSalesGridDataResponse = await getSmExpenseVsNewSalesGridData(usedCompanyId);
			const burnMultipleGridDataResponse = await getBurnMultipeGridData(usedCompanyId);
			const magicNumberGridDataResponse = await getMagicNumberGridData(usedCompanyId);
			const companyDetailsDataResponse = await getCompanyDetails();

			dispatch(
				setBIDashboardData({
					...(acvGridDataResponse && { acv: acvGridDataResponse }),
					...(capTableDataResponse && { capData: capTableDataResponse }),
					...(annualRevenueGridDataResponse && { annualRecurringRevenue: annualRevenueGridDataResponse }),
					...(burnRunwayGridDataresponse && { burnAndRunway: burnRunwayGridDataresponse }),
					...(customerGridDataResponse && { customers: customerGridDataResponse }),
					...(monthlyRevenueGridDataResponse && { monthlyRecurringRevenue: monthlyRevenueGridDataResponse }),
					...(netArrGridDataResponse && { netNewArr: netArrGridDataResponse }),
					...(paybackGridDataResponse && { cacPayback: paybackGridDataResponse }),
					...(revenueGridDataResponse && { revenue: revenueGridDataResponse }),
					...(smExpenseVsNewSalesGridDataResponse && { smExpense: smExpenseVsNewSalesGridDataResponse }),
					...(magicNumberGridDataResponse && { magicNumber: magicNumberGridDataResponse }),
					...(burnMultipleGridDataResponse && { burnMultiple: burnMultipleGridDataResponse }),
					...(companyDetailsDataResponse && { headCount: companyDetailsDataResponse?.companySize > 0 ? companyDetailsDataResponse?.companySize : 0 }),
				})
			);

			setSynced(true);
			setSyncing(false);
		} catch (error) {
			setSyncing(false);
			console.log(error);
			setError(false);
		}
	};

	useEffect(() => {
		if (openPartnerIntegration) {
			setError(false);
			setPlaidDataError(false);
			setCollected(false);
			setMapped(false);
			setSynced(false);
			if (partnerDetails?.key === "plaid") {
				setCollecting(true);
				dataCollectionFromPlaid();
			}
			if (partnerDetails?.key === "xero" || partnerDetails?.key === "quickbooks") {
				dataCollection();
			}
		}
	}, [openPartnerIntegration]);

	useEffect(() => {
		if (isCollected && partnerDetails?.key !== "plaid") {
			dataMapping();
		}
	}, [isCollected]);

	useEffect(() => {
		if (isMapped) {
			refreshData();
		}
	}, [isMapped]);

	const handleClose = () => {
		dispatch(setBIDashboardData({ openPartnerIntegration: false, linkPartnerkey: "" }));
		history.push(ROUTES_PATH.STARTUPGRID);
	};

	return (
		<CommonSlideDialog
			open={openPartnerIntegration}
			onClose={handleClose}
			disableBackDropClick
			title={
				<Box sx={{ display: "flex", flexDirection: "column", rowGap: 1 }}>
					<Box component={"img"} src={LINK_PARTNER_GRID_PURPLE} />
				</Box>
			}
			maxWidth="sm"
			titleVariant="Text/md/Semibold"
			titleStyle={{ p: 0, alignItems: "center" }}
			contentStyle={{ p: 0, mt: 1, mb: 3, flex: 1, display: "flex" }}
			fullWidth
			PaperProps={{
				sx: {
					m: 0,
					borderRadius: "24px",
					boxShadow: "0px 10px 25px 0px rgba(0, 0, 0, 0.05)",
					width: "100%",
					// height: "",
					p: 5,
				},
			}}
			footerStyle={{ p: 0 }}
			dialogAction={
				<Box display="flex">
					<Button variant="DS1" disabled={!isSynced} onClick={handleClose}>
						Done
					</Button>
				</Box>
			}
		>
			<Box flex={1} display="flex" flexDirection={"column"} rowGap={2} pr={1}>
				<Typography variant="Text/md/Semibold">Applying Changes to StartupGrid</Typography>
				<Box display="flex" justifyContent={"space-between"} alignItems={"center"}>
					<Typography variant="Text/sm/Regular">{`Data collected from ${capitalize(partnerDetails ? partnerDetails?.displayName : "")}`}</Typography>
					{isCollecting ? (
						<CircularProgress size={"1.5rem"} thickness={4} />
					) : isCollected ? (
						<Box component={"img"} src={LINK_PARTNER_SUCCESS_CHECK_GREEN} />
					) : (
						""
					)}
				</Box>
				{partnerDetails?.key !== "plaid" && (
					<>
						<Box display="flex" justifyContent={"space-between"} alignItems={"center"}>
							<Typography variant="Text/sm/Regular">Data mapped to your Startup Grid</Typography>
							{isMapping ? (
								<CircularProgress size={"1.5rem"} thickness={4} />
							) : isMapped ? (
								<Box component={"img"} src={LINK_PARTNER_SUCCESS_CHECK_GREEN} />
							) : (
								""
							)}
						</Box>
						<Box display="flex" justifyContent={"space-between"} alignItems={"center"}>
							<Typography variant="Text/sm/Regular">Data Synced to your Startup Grid</Typography>
							{isSyncing ? (
								<CircularProgress size={"1.5rem"} thickness={4} />
							) : isSynced ? (
								<Box component={"img"} src={LINK_PARTNER_SUCCESS_CHECK_GREEN} />
							) : (
								""
							)}
						</Box>
					</>
				)}
				{error && !plaidDataError && (
					<Box display="flex" justifyContent={"space-between"} alignItems={"center"}>
						<Typography variant="Text/sm/Regular">Something went wrong. Click below icon to try again. </Typography>
						<IconButton
							aria-label="close"
							onClick={() => {
								if (!isCollected) {
									if (partnerDetails?.key === "plaid") {
										dataCollectionFromPlaid();
									}
									if (partnerDetails?.key === "xero" || partnerDetails?.key === "quickbooks") {
										dataCollection();
									}
									return;
								}
								if (!isMapped) {
									dataMapping();
									return;
								}
								if (!isSynced) {
									refreshData();
									return;
								}
							}}
							sx={{ color: (theme) => theme.palette.secondary.dark }}
						>
							<CachedIcon sx={{ color: (theme) => theme.palette.success.main, fontSize: "1.5rem" }} />
						</IconButton>
					</Box>
				)}
				{plaidDataError && (
					<Box>
						<Typography variant="Text/sm/Regular">There was an error collecting account data.</Typography>
					</Box>
				)}
			</Box>
			<PlaidAccounts />
		</CommonSlideDialog>
	);
}

export default PartnerIntegration;
