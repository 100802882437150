import React, { useEffect, useState } from "react";
import ManageGroupFlyout from "./ManageGroup";
import { Box, Button } from "@mui/material";
import axios from "axios";
import GroupsTable from "./GroupsTable";

function Groups({ partnerCode }) {
	const [manageGroup, setmanageGroup] = useState({ editMode: false, group: null, open: false });

	const [groupsTableKey, setKeyForGroupsTable] = useState(0);

	return (
		<Box>
			<GroupsTable
				partnerCode={partnerCode}
				setKeyForGroupsTable={setKeyForGroupsTable}
				groupsTableKey={groupsTableKey}
				editGroup={(id) => {
					setmanageGroup({
						open: true,
						editMode: true,
						group: id,
						partnerCode: partnerCode,
					});
				}}
			/>
			<Button
				variant="DS1"
				onClick={() => {
					setmanageGroup({
						open: true,
						editMode: false,
						group: null,
						partnerCode: partnerCode,
					});
				}}
			>
				Create Group
			</Button>

			<ManageGroupFlyout
				open={manageGroup?.open}
				editMode={manageGroup?.editMode}
				partnerCode={partnerCode}
				group={manageGroup?.group}
				onClose={() => {
					setmanageGroup({
						open: false,
						editMode: false,
						group: null,
						partnerCode: partnerCode,
					});
					setKeyForGroupsTable((prevKey) => prevKey + 1);
				}}
			/>
		</Box>
	);
}

export default Groups;
