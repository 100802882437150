import { Card, Box, Typography } from "@mui/material";
import React from "react";
import { IconComponent, StaticIconComponent } from "./styles";
import PropTypes from "prop-types";

export default function PersonaSummaryCard({
  iconUrl,
  personaName,
  description,
}) {
  return (
    <Card
      sx={{
        background: theme => theme.palette.primary.gradient,
        px: 5,
        py: 3,
      }}
    >
      <Box display="flex" alignItems="center">
        <Box
          sx={{
            gridColumn: {
              lg: "span 4",
              md: "span 4",
              sm: "span 4",
              xs: "span 4",
            },
            display: "flex",
            flexDirection: "column",
          }}
          alignItems="center"
        >
          <StaticIconComponent selected={false} width="90px" height="90px">
            <img
              src={iconUrl}
              height={"90px"}
              width={"90px"}
              style={{
                borderRadius: "55px", //use of themeShape causing the avatar to overflow hence using value in px
              }}
            />

            {/*)}*/}
          </StaticIconComponent>
        </Box>
        <Box sx={{ ml: 2 }}>
          <Typography
            variant="headline4"
            sx={{ color: theme => theme.palette.secondary.white }}
          >
            {personaName}
          </Typography>
          <Typography
            variant="subHead1"
            sx={{
              mt: 1,
              color: theme => theme.palette.secondary.white,
              display: "grid",
            }}
          >
            {description}
          </Typography>
        </Box>
      </Box>
    </Card>
  );
}
