import services from "../../../../services";

export const probSolProcessingAI = async (content, cb) => {
	cb({ key: "probSolAI", values: { loading: true, complete: false } });

	if (!content) {
		cb({ key: "probSolAI", values: { loading: false, complete: true } });
		return;
	}

	let payload = {
		messages: [
			{
				role: "system",
				content: `Using the provided problem solution statement I need you to aggregate the following information:
                - General Problem Statement; 1 - 2 sentences in length.
                - General Solution Statement; 1 - 2 sentences in length.
                - A title for the 1st problem; 1 - 3 words in length.
                - A title for the 2nd problem; 1 - 3 words in length.
                - A title for the 3rd problem; 1 - 3 words in length.
                - A 1 sentence summary of the 1st problem
                - A 1 sentence summary of the 2nd problem
                - A 1 sentence summary of the 3rd problem
                - A 1 sentence summary of the solution to the 1st problem
                - A 1 sentence summary of the solution to the 2nd problem
                - A 1 sentence summary of the solution to the 3rd problem

                Respond in the following JSON format:
                {
                    generalProblem: {GENERAL_PROBLEM_STATEMENT},
                    generalSolution: {GENERAL_SOLUTION_STATEMENT},
                    problems: [
                        {
                            title: {PROBLEM_1_TITLE},
                            summary: {PROBLEM_1_SUMMARY},
                            solution: {PROBLEM_1_SOLUTION}
                        },
                        {
                            title: {PROBLEM_2_TITLE},
                            summary: {PROBLEM_2_SUMMARY},
                            solution: {PROBLEM_2_SOLUTION}
                        },
                        {
                            title: {PROBLEM_3_TITLE},
                            summary: {PROBLEM_3_SUMMARY},
                            solution: {PROBLEM_3_SOLUTION}
                        },
                    ]
                }
                `,
			},
			{
				role: "user",
				content: `${content}`,
			},
		],
		maxTokens: 1200,
	};

	let attempts = 0;
	while (attempts < 2) {
		try {
			let AIResp = await services.getOpenAIChatResponse(payload).catch((e) => {
				console.log(e);
			});
			cb({ key: "probSolAI", values: { loading: false, complete: true } });
			if (AIResp?.data?.data?.[0]?.text) {
				return JSON.parse(AIResp?.data?.data?.[0]?.text);
			} else if (AIResp?.data?.data?.[0]?.message?.content) {
				return JSON.parse(AIResp?.data?.data?.[0]?.message?.content);
			} else {
				attempts++;
			}
		} catch (error) {
			console.error(error);
		}
	}
	return false;
};
