import { Box, Typography, Link, Card, CardMedia, CardContent, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { setFeedData } from "../../modules/actions";
import { useDispatch, useSelector } from "react-redux";

export const FeedMetaCard = ({ metaData, type = "OTHER", setMetaData = () => {} }) => {
	const feedData = useSelector((state) => state?.feedPost);
	const dispatch = useDispatch();

	const handleRemovePreview = (metaItem) => {
		let tempMetaArray = feedData?.metaLinks;
		tempMetaArray.map((item) => {
			if (item.link === metaItem.link) {
				item.title = metaItem.title;
				item.description = metaItem.description;
				item.imageUrl = metaItem.imageUrl;
				item.metaLink = metaItem.metaLink;
				item.isRemoved = true;
			}
		});
		setMetaData(tempMetaArray);
		dispatch(setFeedData({ ...feedData, metaLinks: tempMetaArray }));
	};
	return (
		<Grid container>
			<Grid item sx={{ width: 1 }}>
				<Box gap={4} display={"flex"} flexDirection={"column"}>
					{metaData.map((item, idx) => {
						if (!item.isRemoved) {
							return (
								<Box key={idx}>
									<Card
										sx={(theme) => ({
											maxWidth: 1,
											cursor: "pointer",
											borderRadius: "20px",
											position: "relative",
										})}
										target="_blank"
									>
										<CardMedia
											component="img"
											sx={{
												objectFit: "cover",
												display: "flex",
												width: "100%",
											}}
											onClick={(e) => {
												e.stopPropagation();
												window.open(item.link);
											}}
											image={item.imageUrl}
										/>
										{type === "CREATE" && (
											<Box sx={{ position: "absolute", top: "12px", right: "12px" }} onClick={() => {}}>
												<CloseIcon
													sx={{ backgroundColor: "white", borderRadius: "50%", p: "3px" }}
													onClick={() => {
														handleRemovePreview(item);
													}}
												/>
											</Box>
										)}

										<CardContent
											sx={{
												textAlign: "left",
												backgroundColor: "#F5F9FA",
											}}
											onClick={(e) => {
												e.stopPropagation();
												window.open(item.link);
											}}
										>
											<Typography variant="h5">{item.title}</Typography>
											<Typography variant="subtitle1">{item.link}</Typography>
										</CardContent>
									</Card>
								</Box>
							);
						}
					})}
				</Box>
			</Grid>
		</Grid>
	);
};
