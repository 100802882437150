import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Box, Typography, Button, Divider, TextField, Grid, useMediaQuery } from "@mui/material";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";

import MailOutlineIcon from "@mui/icons-material/MailOutline";
import AutocompleteInput from "../../../common/AutocompleteInput";
import { customerTagValid } from "../../../containers/Workspace/PowerUP/ProblemSolution/functions/common";
import messages from "../../../constants/messages";
import { REGEX } from "../../../constants/regex";
import { toastContainer } from "../../../utils/toast";
import services from "../../../services";
import LoadingButton from "../../../common/Loadingbutton";
import { COVER_DEFAULT_IMAGE, DEFAULT_STARTUP_LOGO, NEW_STARTUP_LOGO } from "../../../constants";
import UploadImageModal from "../../../common/UploadImageModal";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { handleMixPanel } from "../../../utils/mixPanelEventHandling";

export default function Participants({ surveyDetails, setMenu, handleGetSurveyApiCall }) {
	const [customText, setCustomText] = useState();
	const [emails, setEmails] = useState();
	const [sendFromEmail, setSendFromEmail] = useState();
	const [sendFromEmailError, setSendFromEmailError] = useState();

	const [isError, setIsError] = useState(false);
	const [sendLoader, setSendLoader] = useState(false);
	const [openCustomImageModal, setOpenCustomImageModal] = useState(false);
	const [customImage, setCustomImage] = useState();

	const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

	const [helpText, setHelpText] = useState("Add up to 50 emails");
	const customerItems = React.createRef();
	const params = useParams();
	const surveyId = params?.id;
	const handleSend = () => {
		const surveyConfiguration = {
			smTemplateId: surveyDetails.smTemplateId,
			surveyId: Number(surveyId),
			surveyName: surveyDetails.surveyName,
			companyName: "",
			companyId: "",
			collectorType: "email",
			collectorEmails: emails?.users,
			responseLimit: 50,
		};

		if (surveyId === "8" || surveyId === "9") {
			surveyConfiguration.question = [
				{
					action: "update",
					family: "image",
					index: "1",
					questionText: "",
					imageUrl: customImage,
				},
			];
		}
		setSendLoader(true);
		services
			.getDashboardStats()
			.then((res) => {
				surveyConfiguration.companyName = res.data.data.companyName;
				surveyConfiguration.companyId = `${res.data.data.companyId}`;
				if (customText) {
					surveyConfiguration.surveyName = surveyConfiguration.surveyName + " for " + customText + " (" + res.data.data.companyName + ")";
				} else {
					surveyConfiguration.surveyName = surveyConfiguration.surveyName + " for " + res.data.data.companyName;
				}

				services
					.sendSurvey(surveyConfiguration)
					.then((result) => {
						if (result.data.code === 200) {
							if (surveyId == 2) {
								handleMixPanel(`Plugin Net Promotor Score Survey Invite Sent`);
							} else if (surveyId == 4) {
								handleMixPanel(`Plugin Product Market Fit Survey Invite Sent`);
							} else if (surveyId == 5) {
								handleMixPanel(`Plugin Likert Scale Survey Invite Sent`);
							}

							setSendLoader(false);
							handleGetSurveyApiCall();
							setMenu("results");
							toastContainer(messages.SEND_SURVEY_SUCCESS, "success");
						}
					})
					.catch((error) => {
						console.log(error);
						toastContainer(messages.SEND_SURVEY_ERROR, "error");
					});
			})
			.catch((error) => {
				console.log(error);
				toastContainer(messages.SEND_SURVEY_ERROR, "error");
			});
	};

	let customFieldData = {
		showCustomField: false,
	};
	let customImageField = {
		showCustomField: false,
	};

	if (surveyId === "4") {
		customFieldData = {
			showCustomField: true,
			title: "What is the name of your product?",
			id: "product-name",
			label: "Enter product name",
		};
	} else if (surveyId === "5") {
		customFieldData = {
			showCustomField: true,
			title: "What is name or URL of your Website/App?",
			id: "app-name",
			label: "Enter Website/App name or URL",
		};
	} else if (surveyId === "6") {
		customFieldData = {
			showCustomField: true,
			title: "What is name of the product category you'd like to survey?",
			id: "category-name",
			label: "Enter a product category",
		};
	}

	const onChangeHandler = (e, key) => {
		const value = e.target.value;
		const currentInput = { ...emails };
		currentInput[key] = value;

		setEmails(currentInput);
	};

	const onCustomTextChange = (e, key) => {
		if (key == "sendFromEmail") {
			const value = e.target.value;
			setSendFromEmail(value);
		} else {
			const value = e.target.value;
			setCustomText(value);
		}
	};
	const handleErrorMessage = (e, key) => {
		const value = e.target.value;

		if (key == "sendFromEmail") {
			if (value && !REGEX.EMAIL.test(value)) {
				//if (value) {
				setSendFromEmailError(messages.INVALID_EMAIL);
				//}
			} else {
				setSendFromEmailError("");
			}
		}
	};

	useEffect(() => {
		if (emails && emails.users.length > 50) {
			setHelpText("Too many emails");
			setIsError(true);
		}
	}, [emails]);
	const handleOnChange = (value) => {
		if (!REGEX.EMAIL.test(value) && value !== "") {
			setIsError(true);

			setHelpText(messages.INVALID_EMAIL);
		} else {
			setHelpText("Add up to 50 emails");
			setIsError(false);
		}
	};
	return (
		<Box>
			<Typography variant="title_large" sx={{ fontWeight: 600, color: (theme) => theme.palette.text.atomic }}>
				Manage Participants
			</Typography>
			<Divider sx={{ borderColor: "secondary.light", my: 4 }} />
			{surveyDetails?.companySurvey.length === 0 ? (
				<Box>
					{" "}
					{customFieldData.showCustomField && (
						<Box>
							<Typography variant="onsurface_title_thick" sx={{ color: (theme) => theme.palette.text.atomic }}>
								{customFieldData.title}
							</Typography>
							<Grid container spacing={2} alignItems="center">
								<Grid item sm={12} xs={12} md={10} lg={10} xl={10}>
									<TextField
										id={customFieldData.id}
										label={customFieldData.label}
										variant="outlined"
										value={customText ? customText : ""}
										onChange={onCustomTextChange}
										sx={{ mt: 2, mb: 2 }}
									/>
								</Grid>
							</Grid>
						</Box>
					)}
					{(surveyId === "8" || surveyId === "9") && (
						<Grid item sm={12} xs={12} md={3} lg={3} xl={3}>
							<Box sx={{ position: "relative" }}>
								<Box
									textAlign={"center"}
									sx={(theme) => ({
										mb: 1,
										maxHeight: "300px",
										maxWidth: "300px",
									})}
								>
									<img
										style={{
											width: "100%",
											borderRadius: "8px",
										}}
										src={customImage ? customImage : NEW_STARTUP_LOGO}
									/>
								</Box>
								<Box>
									<Grid container>
										<Grid item xs={6}>
											{!isSmallScreen ? (
												<Box
													sx={{
														width: "max-content",
														mr: { xs: 1, md: 3 },
														mb: 3,
														background:
															"linear-gradient(0deg, rgba(106, 117, 117, 0.02), rgba(106, 117, 117, 0.02)), linear-gradient(0deg, rgba(245, 248, 250, 0.05), rgba(245, 248, 250, 0.05)), #FCFCFC",
														boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
														borderRadius: "4px",
														position: "absolute",
														bottom: "10px",
														left: "30px"
													}}
												>
													<Button
														variant="outlined"
														startIcon={<FileUploadOutlinedIcon />}
														onClick={() => {
															setOpenCustomImageModal(true);
														}}
													>
														Upload
													</Button>
												</Box>
											) : (
												<Box
													sx={{
														width: "max-content",
														mr: { xs: 1, md: 3 },
														mb: 1,
														position: "absolute",
														bottom: "10px",
														left: "20px",
													}}
												>
													<PhotoCameraIcon
														sx={(theme) => ({
															color: theme.palette.primary.main,
															background: "#fff",
															p: 1,
															borderRadius: "100px",
														})}
														onClick={() => {
															setOpenCustomImageModal(true);
														}}
													/>
												</Box>
											)}
										</Grid>
									</Grid>
								</Box>
							</Box>
						</Grid>
					)}
					{/*<Box>
            <Typography
              variant="onsurface_title_thick"
              sx={{ color: theme => theme.palette.text.atomic }}
            >
              Add a custom send-from email (optional)
            </Typography>{" "}
            <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
              <TextField
                label={"your@email.com"}
                variant="outlined"
                value={sendFromEmail}
                onChange={e => onCustomTextChange(e, "sendFromEmail")}
                onBlur={e => handleErrorMessage(e, "sendFromEmail")}
                sx={{ mt: 2, mb: 2 }}
                error={sendFromEmailError}
                helperText={sendFromEmailError}
              />
            </Grid>
          </Box>*/}
					<Typography variant="onsurface_title_thick" sx={{ color: (theme) => theme.palette.text.atomic }}>
						Send by email
					</Typography>
					<Grid container spacing={2} alignItems="center">
						<Grid item sm={12} xs={12} md={10} lg={10} xl={10}>
							<AutocompleteInput
								label={"Add email addresses"}
								handleInputChange={onChangeHandler}
								inputKey={"users"}
								autoCompleteData={[]}
								inputValid={customerTagValid}
								inputValue={emails ? [...emails?.users] : []}
								errorMessage={messages.MULTITAG_INPUT_INVALID}
								sx={{ mt: 2 }}
								typingEnabled
								multiple
								handleOnBlurEvent={true}
								inputRef={customerItems}
								isEmailField={true}
								helpText={helpText}
								handleOnChange={handleOnChange}
								isError={isError}
							/>
						</Grid>
						<Grid item sm={12} xs={12} md={2} lg={2} xl={2}>
							<Button
								sx={{
									[(theme) => theme.breakpoints.up("sm")]: {
										mt: 3,
										ml: "31px",
									},
									maxWidth: "fit-content",
								}}
								variant="outlined"
								onClick={() => customerItems.current.blur()}
								disabled={isError}
							>
								+ Add
							</Button>
						</Grid>
					</Grid>
					<Box display="flex">
						<Box sx={{ mt: 2 }}>
							{/*<Button
                sx={{ width: 1 }}
                size="small"
                startIcon={<MailOutlineIcon />}
                onClick={handleSend}
                disabled={
                  !emails ||
                  emails?.users?.length === 0 ||
                  emails.users.length > 50
                }
              >
                Send
              </Button>*/}

							<LoadingButton
								sx={{ width: 1 }}
								size="small"
								startIcon={<MailOutlineIcon />}
								onClick={handleSend}
								disabled={!emails || emails?.users?.length === 0 || emails.users.length > 50}
								loading={sendLoader}
								loadingStyles={{ px: 4 }}
							>
								Send
							</LoadingButton>
						</Box>
					</Box>
				</Box>
			) : (
				<Box>
					<Typography variant="onsurface_title_thick" sx={{ color: (theme) => theme.palette.text.atomic }}>
						This survey has already been sent
					</Typography>
				</Box>
			)}
			<UploadImageModal
				open={openCustomImageModal}
				onClose={() => setOpenCustomImageModal(false)}
				photo={customImage}
				setPhoto={setCustomImage}
				title="Custom Image"
				selectFileText="Select a file to upload Custom image"
				dimensionText="Recommended dimensions 1400px x 350px (Max 2GB)"
				//aspectRatio={1 / 1}
				imageType="COMPANY_PHOTO"
				fileType={{ "image/png": [], "image/jpg": [], "image/jpeg": [] }}
				circularCropper={false}
				//handleUpdateAssetsUrl={handleUpdateAssetsUrl}
			/>
		</Box>
	);
}
