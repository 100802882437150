import React, { Fragment, useEffect, useState } from "react";
import { Box, Button, Card, Divider, Grid, Typography } from "@mui/material";
import SidebarTabs from "./tabs";
import { POWERUPKNOWLEDGELOGO } from "./../../../../constants/image";
import useDimensions from "react-cool-dimensions";
import _ from "lodash";
import { useRef } from "react";

const SidebarCard = (props) => (
	<Card
		ref={(el) => {
			props.observe(el); // Set the target element for measuring
			props.SBC.current = el; // Share the element for other purposes
		}}
		sx={(theme) => ({
			//boxShadow: "none",
			//boxShadow: theme.palette.primary.elevationLight2,
			width: 1,
			[theme.breakpoints.up("md")]: {
				maxWidth: "372px", //Fixed px value as this was requested by product team to be.
				minHeight: "800px",
				maxHeight: "1vh",
			},
			boxSizing: "border-box", // Not certain why this bit is so important here.
			display: "flex",
			flexDirection: "column",
			//boxShadow:
			//  "rgb(0 0 0 / 30%) 0px 1px 2px, rgb(0 0 0 / 15%) 0px 1px 3px 1px",
			border: `1px solid ${theme.palette.secondary.outline2}`,
			borderRadius: "4px",
			[theme.breakpoints.down("md")]: {
				padding: 0,
				border: "none",
			},
		})}
	>
		{props.children}
	</Card>
);

const Sidebar = ({ ...props }) => {
	const SBC = useRef();
	const [sbContent, setSBContent] = useState(props.sidebar_content);
	const [contWidth, setContWidth] = useState("0");

	const { observe, unobserve, width, height, entry } = useDimensions({
		onResize: _.throttle(() => {
			if (width && width > 0) setContWidth(width);
		}, 500),
	});

	useEffect(() => {
		setSBContent(props.sidebar_content);
	}, [props.sidebar_content]);

	useEffect(() => {
		try {
			setContWidth(SBC.current.getBoundingClientRect().width);
		} catch (e) {
			console.log("Could not access width -- sidebar card.", e);
		}
	}, []);

	//const openIntercom = () => {
	//  try {
	//    window.Intercom("show");
	//  } catch (e) {
	//    console.log("Cannot communicate with Intercom application.", e);
	//  }
	//};

	return (
		<SidebarCard setContWidth={setContWidth} SBC={SBC} observe={observe}>
			{typeof sbContent != "undefined" && Object.keys(sbContent).length > 0 && (
				<Fragment>
					<Box
						sx={{
							"& > img": {
								maxWidth: 1,
								maxHeight: contWidth > 330 ? "30px" : "20px",
								height: 1,
								display: props.powerupContent ? "none" : "block",
							},
							p: contWidth > 330 ? 3 : 2,
						}}
					>
						<img src={POWERUPKNOWLEDGELOGO} />
					</Box>
					<SidebarTabs
						tabs={sbContent}
						width={contWidth}
						powerupContent={props.powerupContent}
						completedWorksComponent={props.completedWorksComponent}
					/>
					{/*<Box
            sx={theme => ({
              [theme.breakpoints.up("md")]: {
                position: "relative",
                zIndex: 2,
                boxShadow: "0px -1px 3px 1px rgba(0, 0, 0, 0.15)",
              },
              [theme.breakpoints.down("md")]: {
                borderTop: "1px solid",
                borderColor: theme.palette.divider,
              },
              p: contWidth > 330 ? 3 : 2,
            })}
          >
            <Typography component={"p"} variant="title_medium" sx={{ my: 1 }}>
              Need help?
            </Typography>
            <Typography variant="subhead2" sx={{ my: 1 }}>
              Your StartupOS Advisor is here to help if you would like help or
              feedback on this PowerUp.
            </Typography>
            <Button onClick={openIntercom} variant="contained" sx={{ my: 2 }}>
              Ask for help
            </Button>
          </Box>*/}
				</Fragment>
			)}
		</SidebarCard>
	);
};
export default Sidebar;
