import { Grid, Typography } from "@mui/material";
import { Box, Link } from "@mui/material";
import React, { useEffect, useState } from "react";
import theme from "../../../constants/Theme";

import Card from "../../../components/Workspace/Card";
import constants, { CALCULATOR_ICON, NEW_SVB_ICON, SPONSOREDBY, STARTUPOSICON } from "../../../constants";
import { InlineSecondaryTile } from "./style";
import InlinePrimaryTileComponent from "./InlinePrimaryTile";
import Tabs from "../../../components/Workspace/Tabs";
import services from "../../../services";
import ShowMoreText from "react-show-more-text";

const RunwayCalculator = ({ data }) => {
	const [currenttab, setcurrenttab] = useState("actual");

	const [actualData, setActualData] = useState({
		cash: 0,
		burnRate: 0,
		month: 0,
	});
	const [whatIfData, setWhatIfData] = useState({
		cash: 0,
		burnRate: 0,
		month: 0,
	});

	const calculateRunwayMonth = (cash, burnRate) => {
		if (cash > 0 && burnRate > 0) {
			return Math.floor(parseFloat(cash / burnRate));
		}
		return 0;
	};

	useEffect(() => {
		const actualGetData = data?.find((runwayData) => runwayData.runawayType === "ACTUAL");
		const whatIfGetData = data?.find((runwayData) => runwayData.runawayType === "WHAT_IF");

		if (actualGetData) {
			const actualCash = parseInt(actualGetData.cash);
			const actualBurnRate = parseInt(actualGetData.burnRate);

			const calculateActualMonth = calculateRunwayMonth(actualCash, actualBurnRate);

			setActualData({
				cash: actualCash,
				burnRate: actualBurnRate,
				month: calculateActualMonth,
			});
		}

		if (whatIfGetData) {
			const whatIfCash = parseInt(whatIfGetData.cash);
			const whatIfBurnRate = parseInt(whatIfGetData.burnRate);

			const calculateWhatIfMonth = calculateRunwayMonth(whatIfCash, whatIfBurnRate);

			setWhatIfData({
				cash: whatIfCash,
				burnRate: whatIfBurnRate,
				month: calculateWhatIfMonth,
			});
		}
	}, [data]);

	const onRunwayDataSave = (cash, burnRate) => {
		let runwayMonths = calculateRunwayMonth(cash, burnRate);

		currenttab === "actual" ? setActualData({ cash, burnRate, month: runwayMonths }) : setWhatIfData({ cash, burnRate, month: runwayMonths });

		const runwayType = currenttab === "actual" ? "ACTUAL" : "WHAT_IF";

		const runwayObject = {
			burnRate: burnRate.toString(),
			cash: cash.toString(),
			runawayType: runwayType,
		};

		const payload = [
			currenttab === "actual"
				? runwayObject
				: {
						burnRate: actualData.burnRate.toString(),
						cash: actualData.cash.toString(),
						runawayType: "ACTUAL",
				  },
			currenttab === "whatIf"
				? runwayObject
				: {
						burnRate: whatIfData.burnRate.toString(),
						cash: whatIfData.cash.toString(),
						runawayType: "WHAT_IF",
				  },
		];

		services
			.updateRunwayDetails(payload)
			.then()
			.catch((err) => {
				console.log(err);
			});
	};

	const getProTip = () => {
		const proTips = constants.PRO_TIPS;

		const cash = actualData.cash;

		if (cash >= 0 && cash <= 25000) return proTips[0];
		else if (cash > 25000 && cash < 100000) return proTips[1];
		else return proTips[2];
	};

	return (
		<Card
			sx={{
				p: 1,
				boxShadow: (theme) => theme.palette.primary.elevationLight2,
				// backgroundColor: theme => theme.palette.text.background,
				border: "0px !important", //Added important to override border property
			}}
		>
			<Box display={"flex"} alignItems="center">
				<Box component={"img"} src={CALCULATOR_ICON} alt="calulator" />
				<Typography variant="headline6" color="textPrimary" sx={{ ml: 2 }}>
					Runway Calculator
				</Typography>
			</Box>

			<Box
				sx={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					pt: 6,
				}}
			>
				<Tabs
					tabitems={constants.RUNWAY_CALCULATOR_TAB_ITEMS}
					currenttab={currenttab}
					setcurrenttab={setcurrenttab}
					buttonWidth={"82px"}
					color="primary"
				/>
			</Box>

			<Grid
				container
				alignItems="center"
				justifyContent="center"
				sx={{
					maxWidth: theme.breakpoints.values.md,
					pt: 4,
				}}
				spacing={1}
			>
				<InlinePrimaryTileComponent
					currenttabData={currenttab === "actual" ? actualData : whatIfData}
					currenttab={currenttab}
					onRunwayDataSave={onRunwayDataSave}
				/>

				<Grid item lg={12} md={12} sm={12} xs={12}>
					<InlineSecondaryTile currenttab={currenttab}>
						<Box overflow={"hidden"}>
							<Typography variant="h6" textTransform={"uppercase"}>
								RUNWAY
							</Typography>
							<Typography variant="headline1" textOverflow={"ellipsis"} overflow="hidden">
								{currenttab === "actual" ? actualData?.month : whatIfData?.month} Months
							</Typography>
						</Box>
					</InlineSecondaryTile>
				</Grid>

				<Grid item lg={12} md={12} sx={{ mt: 2 }}>
					<Card variant="bordered" sx={{ borderRadius: (theme) => theme.shape.standard_small }}>
						<Box sx={{ display: "flex" }}>
							<img src={STARTUPOSICON} />
							<Typography variant="title_medium" sx={{ ml: 1 }}>
								Pro Tips:
							</Typography>
						</Box>
						<Typography variant="body_medium" display={"grid"} sx={{ mt: 2 }}>
							<ShowMoreText
								/* Default options */
								lines={3}
								more={
									<Link
										component="button"
										display="flex"
										variant="body2"
										sx={{
											color: "#1A73E8",
											textDecorationColor: "#1A73E8",
											ml: "auto",
										}}
									>
										Read More
									</Link>
								}
								less={
									<Link
										display="flex"
										component="button"
										variant="body2"
										sx={{
											color: "#1A73E8",
											textDecorationColor: "#1A73E8",
											ml: "auto",
										}}
									>
										Read Less
									</Link>
								}
								className="content-css"
								anchorClass="my-anchor-css-class"
								expanded={false}
								//width={280}
								truncatedEndingComponent={"... "}
							>
								{/*<Typography variant="body1" sx={{ mt: 2 }}>*/}
								{getProTip()}
								{/*</Typography>*/}
							</ShowMoreText>
						</Typography>
					</Card>
				</Grid>

				<Grid item lg={12} md={12} sx={{ mt: 2, ml: "auto" }} display="flex" justifyContent={"flex-end"}>
					<Typography variant="custom070" align="right" sx={{ mr: 1 }}>
						Sponsored by
					</Typography>
					<Box component={"img"} src={NEW_SVB_ICON} alt="svb" />
				</Grid>
			</Grid>
		</Card>
	);
};

export default RunwayCalculator;
