import React, { useEffect, useState } from "react";
import { Box, Typography, IconButton } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import PersonaCard from "./PersonaCard";
import NoDataPill from "./NoDataPill";
import { FavoriteBorderOutlined } from "@mui/icons-material";

export default function Excitement({ persona }) {
	const [personaData, setpersonaData] = useState(persona);

	useEffect(() => {
		setpersonaData(persona);
	}, [persona]);

	const INSPIRATION = personaData?.inspiration?.map((item) => {
		return {
			title: item,
		};
	});

	return (
		<PersonaCard title="Excitement & Inspirations" icon={<FavoriteBorderOutlined fontSize="large" />}>
			<Box mt={3}>
				{INSPIRATION?.map((item) => (
					<Box key={item.title} display="flex" alignItems="center" mb="20px">
						<IconButton sx={{ border: "1px solid #DE0085", width: "24px", height: "24px" }}>
							<ArrowForwardIcon sx={{ color: "#DE0085", fontSize: "16px" }} />
						</IconButton>
						<Typography variant="card_text" sx={{ marginLeft: "12px" }}>
							{item.title}
						</Typography>
					</Box>
				))}
				{!INSPIRATION && <NoDataPill />}
			</Box>
		</PersonaCard>
	);
}
