import { isEmpty } from "lodash";
import { REGEX } from "../../../../../constants/regex";
export const getExcerciseConfigurations = () => {
	return {
		form_key: "problemSolution",
		power_up_id: 5,
		form_title: "Problem Solution",
		summary_path: "/workspace/problem-solution/summary",
		excercises: [
			{
				step_id: 25,
				component: "YOUR_IDEA",
				path: "your-idea",
				key: "yourIdea",
			},
			{
				step_id: 26,
				component: "PROBLEM",
				path: "problem",
				key: "problem",
			},
			{
				step_id: 27,
				component: "SOLUTION",
				path: "solution",
				key: "solution",
			},
		],
	};
};
export const getExcerciseCompletionStatus = (currentExcercise) => {
	return true;
};

export const customerTagValid = (key, input) => {
	if (input.length > 0 && input.length <= 200) {
		return true;
	} else {
		return false;
	}
};

export const getDefaultInputStates = (componet) => {
	switch (componet) {
		case "YOUR_IDEA":
			return {
				idea: "",
				customer: [],
				category: "",
				type: "",
				topicTags: [],
			};
		case "PROBLEM":
			return {
				problem1: {
					problem: "",
					symptoms: "",
					existingAlternatives: "",
				},
				problem2: {
					problem: "",
					symptoms: "",
					existingAlternatives: "",
				},
				problem3: {
					problem: "",
					symptoms: "",
					existingAlternatives: "",
				},
			};
		case "SOLUTION":
			return {
				solution1: [""],
				solution2: [""],
				solution3: [""],
			};
		default:
			return null;
	}
};

export const problemInputValid = (input) => {
	input = input?.trim();
	return true;
};

export const isProblemFilledCompletelyorBlank = (key, userInputs) => {
	if (problemInputValid(userInputs[key].problem) && problemInputValid(userInputs[key].symptoms) && problemInputValid(userInputs[key].existingAlternatives)) {
		return true;
	} else {
		if (!userInputs[key].problem && !userInputs[key].symptoms && !userInputs[key].existingAlternatives) {
			return true;
		}
		return false;
	}
};

export const isSolutionInvalid = (userInputs) => {
	for (var item of Object.values(userInputs)) {
		if (!item || item == "") {
			return true;
		}
	}
	return false;
};

export const buttonDisable = (userInputs, action) => {
	if (userInputs && action) {
		switch (action) {
			case "YOUR_IDEA":
				if (
					userInputs.category.trim() &&
					userInputs?.customer.length &&
					userInputs?.idea?.trim().length >= 3 &&
					userInputs?.idea?.trim().length <= 200 &&
					userInputs.topicTags.length > 0 &&
					userInputs.topicTags.length <= 5 &&
					userInputs.type
				) {
					if (JSON.stringify(userInputs) === JSON.stringify(getDefaultInputStates("YOUR_IDEA"))) {
						return true;
					}
					return false;
				} else {
					return true;
				}
			case "PROBLEM":
				if (
					isProblemFilledCompletelyorBlank("problem1", userInputs) &&
					isProblemFilledCompletelyorBlank("problem2", userInputs) &&
					isProblemFilledCompletelyorBlank("problem3", userInputs)
				) {
					if (JSON.stringify(userInputs) === JSON.stringify(getDefaultInputStates("PROBLEM"))) {
						return true;
					}
					return false;
				} else {
					return true;
				}

			case "SOLUTION":
				for (var key in userInputs) {
					if (isEmpty(userInputs[key])) {
						return true;
					} else {
						for (let i = 0; i < userInputs[key].length; i++) {
							if (!problemInputValid(typeof userInputs[key][i] == "string" ? userInputs[key][i] : userInputs[key][i].value)) {
								return true;
							}
						}
					}
				}

			default:
				return false;
		}
	}
};

export const ideaType = ["Product", "Service"];

export const categories = [
	{
		name: "All categories",
		id: 0,
	},
	{
		id: 3,
		name: "Arts & Entertainment",
	},
	{
		id: 47,
		name: "Autos & Vehicles",
	},
	{
		id: 44,
		name: "Beauty & Fitness",
	},
	{
		id: 22,
		name: "Books & Literature",
	},
	{
		id: 12,
		name: "Business & Industrial",
	},
	{
		id: 5,
		name: "Computers & Electronics",
	},
	{
		id: 7,
		name: "Finance",
	},
	{
		id: 71,
		name: "Food & Drink",
	},
	{
		id: 8,
		name: "Games",
	},
	{
		id: 45,
		name: "Health",
	},
	{
		id: 65,
		name: "Hobbies & Leisure",
	},
	{
		id: 11,
		name: "Home & Garden",
	},
	{
		id: 13,
		name: "Internet & Telecom",
	},
	{
		id: 958,
		name: "Jobs & Education",
	},
	{
		id: 19,
		name: "Law & Government",
	},
	{
		id: 16,
		name: "News",
	},
	{
		id: 299,
		name: "Online Communities",
	},
	{
		id: 14,
		name: "People & Society",
	},
	{
		id: 66,
		name: "Pets & Animals",
	},
	{
		id: 29,
		name: "Real Estate",
	},
	{
		id: 533,
		name: "Reference",
	},
	{
		id: 174,
		name: "Science",
	},
	{
		id: 18,
		name: "Shopping",
	},
	{
		id: 20,
		name: "Sports",
	},
	{
		id: 67,
		name: "Travel",
	},
];

export const buildProblemsInputs = (userInputs) => {
	const problems = [];
	Object.keys(userInputs).map((data) => {
		const item = userInputs[data];
		if (item.problem && item.symptoms && item.existingAlternatives)
			problems.push({
				problem: item.problem.trim(),
				symptoms: item.symptoms.trim(),
				existingAlternatives: item.existingAlternatives.trim(),
			});
	});
	return { problems: problems };
};

export const parseProblemAnswer = (answer) => {
	let inputObject = {};
	answer.map((item, index) => {
		inputObject[`problem${index + 1}`] = {
			problem: item.problem,
			symptoms: item.symptoms,
			existingAlternatives: item.existingAlternatives,
		};
	});
	const defaultObject = getDefaultInputStates("PROBLEM");
	return { ...defaultObject, ...inputObject };
};

export const topicsCoveredInputValid = (key, input) => {
	switch (key) {
		case "topics":
			if (input.trim()) {
				return true;
			} else {
				return false;
			}
	}
};

const buildIdeaInputs = (userInputs) => {
	const data = {
		...userInputs,
		idea: userInputs.idea.trim(),
	};
	return data;
};

const buildSolutionInputs = (userInputs) => {
	for (const key in userInputs) {
		if (Object.hasOwnProperty.call(userInputs, key)) {
			let element = userInputs[key];
			element = element.map((item) => item.trim());
			userInputs[key] = element;
		}
	}

	return userInputs;
};

export const cleanPayload = (component, userInputs) => {
	switch (component) {
		case "YOUR_IDEA":
			return buildIdeaInputs(userInputs);

		case "PROBLEM":
			return buildProblemsInputs(userInputs);

		case "SOLUTION":
			return buildSolutionInputs(userInputs);

		default:
			return false;
	}
};
