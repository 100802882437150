import React, { useState, useEffect } from "react";
import { STARTUP_LOGO, EDITICON, NEW_STARTUP_LOGO } from "../../../../constants/image";
import { Typography, Grid, Box, Link, Button, IconButton, MenuItem, useMediaQuery, Stack, Badge, Avatar } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import constants from "../../../../constants";
import theme from "../../../../constants/Theme";
import TurnedInNotIcon from "@mui/icons-material/TurnedInNot";
import { matchPath, useHistory, useLocation, useParams } from "react-router-dom";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import services from "../../../../services";
import { toastContainer } from "../../../../utils/toast";
import ConnectRequestPopup from "../../../Network/Components/Popups/ConnectPopup";
import SuccessPopup from "../../../Network/Components/Popups/ConnectSuccessPopup";
import MessageHandlerModal from "../../../../common/modal/MessageHandler";
import ButtonPopover from "./components/ButtonPopover";
import CheckIcon from "@mui/icons-material/Check";
import { getStartupData, saveUnsaveStartup } from "../../functions";
import SwipeableEdgeDrawer from "../../../../common/Drawer/index";
import { BookmarkBorder, ForwardToInbox } from "@mui/icons-material";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import { GetValidatedUrl } from "../../../../utils/getCorrectLink";
import ROUTES_PATH from "../../../../constants/routes";
import { setCalUser } from "../../../../modules/actions/meetingsAction";
import { getConversationWithID } from "../../../../utils/messaging-helper";
import { updateCurrentConversation } from "../../../../containers/Messaging/store/action-creators";
import ProfileProgress from "../../../../common/ProfileProgress";
import { COVER_DEFAULT_IMAGE, PITCH_PERFECT_2_BANNER, CAMERA_ICON, PIPEDRIVEIMG } from "../../../../constants/image";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { SmallAvatar } from "../../MyProfile/styles";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import UploadImageModal from "../../../../common/UploadImageModal";
import { handleMixPanel } from "../../../../utils/mixPanelEventHandling";
import { getRoleValue } from "../../../../utils/commonFunctions";
import { USER_TYPES } from "../../../../utils/userTypes";
import { startupProfileProgress } from "../../../../utils/profilePercentageCalculation";

export default function ProfileContainer(props) {
	const [openConnectRequest, setOpenConnectRequest] = useState(false);
	const [connectSuccessPopup, setConnectSuccessPopup] = useState(false);
	const [confirmPopup, setConfirmPopup] = useState(false);
	const [requestType, setRequestType] = useState("CONNECT");
	const [isConnect, setIsConnect] = useState(true);
	const [isInvite, setIsInvite] = useState(false);
	const onboardingData = useSelector((state) => state.onboarding);
	const [startupDetails, setStartupDetails] = useState(null);
	const [openDrawer, setOpenDrawer] = useState(false);
	const [linkedInLink, setLinkedInLink] = useState();
	const [popupText, setPopupText] = useState("");
	const [openBackgroundPhotoModal, setOpenBackgroundPhotoModal] = useState(false);
	const [openProfilePhotoModal, setOpenProfilePhotoModal] = useState(false);
	const [backgroundPhoto, setBackgroundPhoto] = useState(props.profileArr?.coverImgUrl);
	const [profilePhoto, setProfilePhoto] = useState(props.profileArr?.logoUrl);
	const [profileCompletion, setProfileCompletion] = useState(Math.round(startupProfileProgress(props?.profileArr).totalPercentage) || 0);
	const [connectConfirmPopup, setConnectConfirmPopup] = useState(false);
	const params = useParams();
	const companyId = params.id;
	const location = useLocation();
	const dispatch = useDispatch();
	const history = useHistory();

	const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

	const userType = useSelector((store) => store?.auth?.userType);
	const userData = useSelector((store) => ({
		userId: store?.auth?.userId,
		userName: store?.auth?.userName,
	}));

	const contRef = React.useRef();

	useEffect(() => {
		//window.scrollTo({
		//  top: 0,
		//  left: 0,
		//  behavior: "smooth",
		//});
		setStartupDetails(props.profileArr);
		handleMixPanel("Startup Profile Viewed", {
			userName: userData.userName,
			userId: userData.userId,
		});
	}, []);

	//useEffect(() => {
	//  if (props.profileArr) {
	//    setProfileCompletion(
	//      Math.round(props.profileArr?.companyProfilePercentage)
	//    );
	//  }
	//}, [props.profileArr]);

	useEffect(() => {
		if (startupDetails?.linkedInUrl) {
			const link = GetValidatedUrl(startupDetails?.linkedInUrl);
			setLinkedInLink(link);
		}
	});

	useEffect(() => {
		// If the URL is /edit then set edit as true.
		const match = matchPath(location.pathname, { path: ROUTES_PATH.EDIT_MY_STARTUP_PROFILE });
		if (match) {
			// If the route is /edit for startup profile; ensure proper user type -- otherwise reroute to account edit
			if (userType === USER_TYPES.STARTUP_OWNER || userType === USER_TYPES.STARTUP_USER || userType === USER_TYPES.STUDENT_USER) {
				props.handleEditForm();
			} else {
				history.push(ROUTES_PATH.MY_ACCOUNT_PROFILE);
			}
		}
	}, []);

	const handleConnect = () => {
		setRequestType("CONNECT");
		//setOpenConnectRequest(true);
		setConnectConfirmPopup(true);
		setPopupText("Are you sure you want to send the connection request?");
	};

	const handleConnectRequestConfirm = () => {
		setConnectConfirmPopup(false);
		handleSend({
			companyId: companyId ? companyId : "",
			message: "",
		});
	};

	const handleRequestCancelConfirm = () => {
		setConfirmPopup(false);
		handleConnectionStatusUpdate({
			connectionId: startupDetails?.connectionModel?.id ? startupDetails?.connectionModel?.id : "",
			connectionStatus: requestType,
		});
	};

	const handleCancel = () => {
		setRequestType("CANCELED");
		setConfirmPopup(true);
		setPopupText("Are you sure you want to cancel connection request?");
	};

	const handleDelete = () => {
		setRequestType("DELETED");
		setConfirmPopup(true);
		setPopupText("Are you sure you want to delete connection?");
	};

	const handleConnectionStatusUpdate = (data) => {
		services
			.updateStartUpConnection(data)
			.then((result) => {
				if (data.connectionStatus === "ACCEPTED") {
					handleMixPanel(`Connection accepted by ${getRoleValue(userType)}`);
				} else if (data.connectionStatus === "REJECTED") {
					handleMixPanel(`Connection rejected by ${getRoleValue(userType)}`);
				}
				const response = result.data;
				if (response?.code === 200) {
					setOpenConnectRequest(false);

					setStartupDetails({
						...startupDetails,
						connectionModel: response.data,
					});
				}
			})
			.catch((error) => {
				const message = error.response?.data?.message;
				toastContainer(message, "error");
				console.log(error);
			});
	};

	const handleSend = (data) => {
		services
			.handleStartUpConnection(data)
			.then((result) => {
				handleMixPanel("Network Connection Requested", {
					"User ID": data?.companyId,
				});
				// handleMixPanel(`Connection request by ${getRoleValue(userType)}`);
				const response = result.data;
				if (response?.code === 200) {
					setOpenConnectRequest(false);
					setStartupDetails({
						...startupDetails,
						connectionModel: response.data,
					});
					setConnectSuccessPopup(true);
				}
			})
			.catch((error) => {
				const message = error.response?.data?.message;
				toastContainer(message, "error");
				console.log(error);
			});
	};

	const handleSaveClick = () => {
		const data = {
			companyId: companyId,
			status: !startupDetails?.isSaved,
			type: "SAVED",
		};
		saveUnsaveStartup(data)
			.then((result) => {
				if (result) {
					setStartupDetails({
						...startupDetails,
						isSaved: !startupDetails?.isSaved,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const SaveButton = () => {
		return (
			<Button
				variant="nofill"
				color="primary"
				startIcon={startupDetails?.isSaved ? <BookmarkIcon color="primary" /> : <BookmarkBorder color="primary" />}
				sx={{
					width: "max-content",
					m: "inherit",
					pl: { xs: 0, md: 3 },
				}}
				onClick={handleSaveClick}
			>
				{startupDetails?.isSaved ? "Saved" : "Save"}
			</Button>
		);
	};

	const handleReqMeeting = () => {
		dispatch(
			setCalUser({
				id: props.userData.calUserId,
				name: props.userData?.name,
				email: props.userData?.email,
				picture: props.userData?.picture,
			})
		);

		history.push(ROUTES_PATH.MEETING_TYPES_SCHEDULE);
	};

	const handleMessaging = async () => {
		try {
			let conversationID = await getConversationWithID({
				idArray: [props?.userData?.id],
			});
			dispatch(updateCurrentConversation(conversationID));
			history.push("/messages");
		} catch (e) {
			console.log("Could not retrieve conversation.", e);
		}
	};

	const userRoleRestriction = () => {
		if (userType === USER_TYPES.INVESTOR_USER || userType === USER_TYPES.STARTUP_OWNER || userType === USER_TYPES.STARTUP_USER) {
			return false;
		} else {
			return true;
		}
	};

	return (
		<Grid container spacing={2} flexDirection={"row"}>
			{/*<ConnectRequestPopup
        openConnectRequest={openConnectRequest}
        setOpenConnectRequest={setOpenConnectRequest}
        companyId={companyId}
        onConnect={handleSend}
        startupName={startupDetails?.companyName}
      />*/}
			<SuccessPopup
				connectSuccessPopup={connectSuccessPopup}
				setConnectSuccessPopup={setConnectSuccessPopup}
				startupName={startupDetails?.companyName}
				type={requestType}
			/>

			<MessageHandlerModal
				isOpen={connectConfirmPopup}
				onCancel={() => setConnectConfirmPopup(false)}
				onOk={() => handleConnectRequestConfirm()}
				okText="Ok"
				cancelText={"Cancel"}
				heading="Confirm"
				text={popupText}
				messageType="primary"
			/>

			<MessageHandlerModal
				isOpen={confirmPopup}
				onCancel={() => setConfirmPopup(false)}
				onOk={() => handleRequestCancelConfirm()}
				okText="Ok"
				cancelText={"Cancel"}
				heading="Confirm"
				text={popupText}
				messageType="primary"
			/>

			<UploadImageModal
				open={openBackgroundPhotoModal}
				onClose={() => setOpenBackgroundPhotoModal(false)}
				photo={backgroundPhoto}
				setPhoto={setBackgroundPhoto}
				title="Background Photo"
				selectFileText="Select a file to upload background image"
				dimensionText="Recommended dimensions 1400px x 350px (Max 2GB)"
				aspectRatio={4 / 1}
				imageType="BACKGROUND_PHOTO"
				fileType={{ "image/png": [], "image/jpg": [], "image/jpeg": [] }}
				circularCropper={false}
				company
				handleUpdateAssetsUrl={props.handleUpdateAssetsUrl}
			/>
			<UploadImageModal
				open={openProfilePhotoModal}
				photo={profilePhoto}
				onClose={() => setOpenProfilePhotoModal(false)}
				setPhoto={setProfilePhoto}
				title="Startup Logo"
				selectFileText="Select a file to upload profile image"
				dimensionText="Recommended dimensions 240px x 240px (Max 2GB)"
				aspectRatio={1 / 1}
				imageType="COMPANY_PHOTO"
				circularCropper={false}
				fileType={{ "image/png": [], "image/jpg": [], "image/jpeg": [] }}
				company
				handleUpdateAssetsUrl={props.handleUpdateAssetsUrl}
			/>
			{!companyId && (
				<Grid item xs={12} sx={{ mt: 2 }}>
					<ProfileProgress percentage={profileCompletion} />
				</Grid>
			)}

			<Grid item xs={12} md={12} sx={{ pb: 6, pt: 3 }}>
				<Box sx={{ position: "relative", pt: 3 }}>
					{!matchPath(location.pathname, { path: ROUTES_PATH.EDIT_MY_STARTUP_PROFILE }) && (
						<Box
							textAlign={"center"}
							sx={(theme) => ({
								mb: 1,
							})}
						>
							<img
								style={{
									width: "100%",
									borderRadius: "8px",
								}}
								src={backgroundPhoto ? backgroundPhoto : COVER_DEFAULT_IMAGE}
							/>
						</Box>
					)}
					<Box>
						<Grid container>
							<Grid item xs={6}>
								<Box
									sx={{
										pl: { xs: 2, md: 4 },
										position: "absolute",
										bottom: "-80px",
									}}
								>
									<Badge
										overlap="circular"
										anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
										badgeContent={
											<SmallAvatar
												onClick={() => setOpenProfilePhotoModal(true)}
												src={CAMERA_ICON}
												sx={{
													height: "40px",
													width: "40px",
													border: "none",
													cursor: "pointer",
													display: !companyId ? "block" : "none",
												}}
											/>
										}
									>
										<Avatar
											alt="profile_picture"
											src={profilePhoto ? profilePhoto : NEW_STARTUP_LOGO}
											sx={{
												height: "120px",
												width: "120px",
												border: !profilePhoto ? "none" : `3px solid ${theme.palette.background.paper}`,
												borderRadius: "12px",
											}}
										/>
									</Badge>
								</Box>
							</Grid>
							{!matchPath(location.pathname, { path: ROUTES_PATH.EDIT_MY_STARTUP_PROFILE }) && (
								<Grid item xs={6}>
									{!isSmallScreen ? (
										<Box
											sx={{
												width: "max-content",
												mr: { xs: 1, md: 3 },
												mb: 3,
												background:
													"linear-gradient(0deg, rgba(106, 117, 117, 0.02), rgba(106, 117, 117, 0.02)), linear-gradient(0deg, rgba(245, 248, 250, 0.05), rgba(245, 248, 250, 0.05)), #FCFCFC",
												boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
												borderRadius: "4px",
												position: "absolute",
												bottom: "10px",
												right: 0,
											}}
										>
											{!companyId && (
												<Button
													variant="outlined"
													startIcon={<PhotoCameraIcon />}
													onClick={() => {
														setOpenBackgroundPhotoModal(true);
													}}
												>
													Edit cover image
												</Button>
											)}
										</Box>
									) : (
										<Box
											sx={{
												width: "max-content",
												mr: { xs: 1, md: 3 },
												mb: 1,
												position: "absolute",
												bottom: "10px",
												right: 0,
											}}
										>
											{!companyId && (
												<PhotoCameraIcon
													sx={(theme) => ({
														color: theme.palette.primary.main,
														background: "#fff",
														p: 1,
														borderRadius: "100px",
													})}
													onClick={() => {
														setOpenBackgroundPhotoModal(true);
													}}
												/>
											)}
										</Box>
									)}
								</Grid>
							)}
						</Grid>
					</Box>
				</Box>
			</Grid>
			<Grid item flexDirection={"column"} xs={12} sm={12} md={12} lg={12} xl={12}>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						gap: 1,
						pt: 4,
						pl: { md: 4 },
					}}
				>
					<Typography
						variant="title_xlarge"
						color="secondary.korophKoal"
						sx={{
							ml: { xs: 0 },
						}}
					>
						{startupDetails?.companyName ? startupDetails?.companyName : "Startup Name"}
					</Typography>

					<Typography
						variant="subhead1"
						color="secondary.atomic"
						sx={{
							ml: { xs: 0 },
						}}
					>
						{startupDetails?.tagLine ? startupDetails?.tagLine : "Headline"}
					</Typography>

					<Grid container flexDirection={"row"} justifyContent="space-between" sx={{ ml: { xs: 0 }, pt: 0 }}>
						<Box
							sx={{
								color: `${theme.palette.secondary.dark}`,
								display: "flex",
								flexDirection: "row",
								gap: 1,
								pb: 2,
							}}
						>
							{startupDetails?.state || startupDetails?.city || startupDetails?.zipcode ? (
								<Typography variant="label_large" color="text.lightOnsurface">
									{startupDetails?.state && startupDetails?.city && startupDetails?.zipcode
										? `${startupDetails?.city}, ${startupDetails?.state}, ${startupDetails?.zipcode}`
										: startupDetails?.state && startupDetails?.zipcode && !startupDetails?.city
										? `${startupDetails?.state}, ${startupDetails?.zipcode}`
										: startupDetails?.city && startupDetails?.zipcode && !startupDetails?.state
										? `${startupDetails?.city}, ${startupDetails?.zipcode}`
										: `${startupDetails?.zipcode}`}
								</Typography>
							) : (
								""
							)}
						</Box>
						{!companyId && (
							<Box sx={{ pr: { md: 3 } }}>
								<Button variant="contained" startIcon={<ModeEditOutlineOutlinedIcon />} onClick={props.handleEditForm}>
									Edit profile
								</Button>
							</Box>
						)}
					</Grid>
				</Box>
			</Grid>

			{companyId && !isSmallScreen && userRoleRestriction() && (
				<Box sx={{ width: 1, pl: 3 }}>
					<Box sx={{ display: "flex", pl: 3, pt: 1 }}>
						<Box sx={{ display: "flex" }}>
							{startupDetails?.connectionModel === null ? (
								<Box sx={{ cursor: "pointer", mr: 1 }}>
									<Button
										variant="contained"
										onClick={() => {
											//setIsInvite(true);
											handleConnect();
										}}
									>
										Connect
									</Button>
								</Box>
							) : startupDetails?.connectionModel?.connectionStatus === "PENDING" ? (
								<>
									{(userType === "MENTOR_USER" || userType === "PROFESSOR_USER") && startupDetails?.connectionModel?.isCreatedByStartup ? (
										<Box sx={{ display: "flex" }}>
											<Box sx={{ cursor: "pointer", pr: 1 }}>
												<Button
													variant="contained"
													onClick={() => {
														handleConnectionStatusUpdate({
															connectionId: startupDetails?.connectionModel?.id,
															connectionStatus: "ACCEPTED",
														});
													}}
												>
													Accept
												</Button>
											</Box>
											<Box sx={{ cursor: "pointer", pr: 1 }}>
												<Button
													variant="outlined"
													onClick={() => {
														handleConnectionStatusUpdate({
															connectionId: startupDetails?.connectionModel?.id,
															connectionStatus: "REJECTED",
														});
													}}
												>
													Ignore
												</Button>
											</Box>
										</Box>
									) : (
										(userType === "MENTOR_USER" || userType === "PROFESSOR_USER") &&
										!startupDetails?.connectionModel?.isCreatedByStartup && (
											<Button variant="outlined" onClick={() => handleCancel()}>
												Cancel Request
											</Button>
										)
									)}

									{["STARTUP_USER", "STARTUP_OWNER"].includes(userType) && !startupDetails?.connectionModel?.isCreatedByStartup ? (
										<Box sx={{ display: "flex" }}>
											<Box sx={{ cursor: "pointer", pr: 1 }}>
												<Button
													variant="contained"
													onClick={() => {
														handleConnectionStatusUpdate({
															connectionId: startupDetails?.connectionModel?.id,
															connectionStatus: "ACCEPTED",
														});
													}}
												>
													Accept
												</Button>
											</Box>
											<Box sx={{ cursor: "pointer", pr: 1 }}>
												<Button
													variant="outlined"
													onClick={() => {
														handleConnectionStatusUpdate({
															connectionId: startupDetails?.connectionModel?.id,
															connectionStatus: "REJECTED",
														});
													}}
												>
													Ignore
												</Button>
											</Box>
										</Box>
									) : (
										["STARTUP_USER", "STARTUP_OWNER"].includes(userType) &&
										startupDetails?.connectionModel?.isCreatedByStartup && (
											<Button variant="outlined" onClick={() => handleCancel()}>
												Cancel Request
											</Button>
										)
									)}
								</>
							) : startupDetails?.connectionModel?.connectionStatus === "ACCEPTED" ? (
								<ButtonPopover text={"Connected"} popupText={"Remove Connection"} onAction={() => handleDelete()} prefixIcon={<CheckIcon />} />
							) : (
								<Box sx={{ cursor: "pointer" }}>
									<Button
										variant="contained"
										onClick={() => {
											//setIsInvite(true);
											handleConnect();
										}}
									>
										Connect
									</Button>
								</Box>
							)}
							{startupDetails?.linkedInUrl && startupDetails?.linkedInUrl !== null && (
								<Button variant="outlined" sx={{ whiteSpace: "nowrap", ml: 2 }} href={startupDetails?.linkedInUrl} target="_blank">
									View LinkedIn profile
								</Button>
							)}

							{startupDetails?.connectionModel?.connectionStatus === "ACCEPTED" ? (
								<Box>
									<Button variant="nofill" color="primary" onClick={handleReqMeeting} sx={{ ml: 3, width: "max-content" }}>
										Request Meeting
									</Button>
								</Box>
							) : (
								""
							)}
							{startupDetails?.connectionModel?.connectionStatus === "ACCEPTED" ? (
								<Box>
									<Button variant="nofill" color="primary" onClick={handleMessaging} sx={{ display: "flex", gap: 1, width: "max-content" }}>
										Send a Message
									</Button>
								</Box>
							) : (
								""
							)}
						</Box>

						<Box sx={{ cursor: "pointer", ml: "auto" }}>{!isSmallScreen && <SaveButton />}</Box>
					</Box>
				</Box>
			)}
			{companyId && isSmallScreen && userRoleRestriction() && (
				<Box
					sx={{
						width: 1,
						pt: 2,
						mr: 1,
					}}
				>
					<Grid container flexDirection={"row"}>
						<Grid item xs={10} sx={{ pl: 2 }}>
							{startupDetails?.connectionModel === null ? (
								<Box sx={{ cursor: "pointer", mr: 1, width: 1 }}>
									<Button
										variant="contained"
										onClick={() => {
											handleConnect();
										}}
									>
										Connect
									</Button>
								</Box>
							) : startupDetails?.connectionModel?.connectionStatus === "PENDING" ? (
								<>
									{userType === "MENTOR_USER" && startupDetails?.connectionModel?.isCreatedByStartup ? (
										<Box sx={{ display: "flex" }}>
											<Box sx={{ cursor: "pointer", pr: 1 }}>
												<Button
													variant="contained"
													onClick={() => {
														handleConnectionStatusUpdate({
															connectionId: startupDetails?.connectionModel?.id,
															connectionStatus: "ACCEPTED",
														});
													}}
												>
													Accept
												</Button>
											</Box>
											<Box sx={{ cursor: "pointer", pr: 1 }}>
												<Button
													variant="outlined"
													onClick={() => {
														handleConnectionStatusUpdate({
															connectionId: startupDetails?.connectionModel?.id,
															connectionStatus: "REJECTED",
														});
													}}
												>
													Ignore
												</Button>
											</Box>
										</Box>
									) : (
										(userType === "MENTOR_USER" || userType === "PROFESSOR_USER") &&
										!startupDetails?.connectionModel?.isCreatedByStartup && (
											<Button variant="outlined" onClick={() => handleCancel()}>
												Cancel Request
											</Button>
										)
									)}

									{["STARTUP_USER", "STARTUP_OWNER"].includes(userType) && !startupDetails?.connectionModel?.isCreatedByStartup ? (
										<Box sx={{ display: "flex" }}>
											<Box sx={{ cursor: "pointer", pr: 1 }}>
												<Button
													variant="contained"
													onClick={() => {
														handleConnectionStatusUpdate({
															connectionId: startupDetails?.connectionModel?.id,
															connectionStatus: "ACCEPTED",
														});
													}}
												>
													Accept
												</Button>
											</Box>
											<Box sx={{ cursor: "pointer", pr: 1 }}>
												<Button
													variant="outlined"
													onClick={() => {
														handleConnectionStatusUpdate({
															connectionId: startupDetails?.connectionModel?.id,
															connectionStatus: "REJECTED",
														});
													}}
												>
													Ignore
												</Button>
											</Box>
										</Box>
									) : (
										["STARTUP_USER", "STARTUP_OWNER"].includes(userType) &&
										startupDetails?.connectionModel?.isCreatedByStartup && (
											<Button variant="outlined" onClick={() => handleCancel()}>
												Cancel Request
											</Button>
										)
									)}
								</>
							) : startupDetails?.connectionModel?.connectionStatus === "ACCEPTED" ? (
								<ButtonPopover text={"Connected"} popupText={"Remove Connection"} onAction={() => handleDelete()} prefixIcon={<CheckIcon />} />
							) : (
								<Box sx={{ cursor: "pointer" }}>
									<Button
										variant="contained"
										onClick={() => {
											//setIsInvite(true);
											handleConnect();
										}}
									>
										Connect
									</Button>
								</Box>
							)}
						</Grid>

						<Grid item xs={2}>
							<Box sx={{ display: "flex", justifyContent: "end" }}>
								<IconButton width={32} height={32} sx={{ border: "1px solid #6A7575" }}>
									<MoreHorizIcon sx={{ color: theme.palette.text.primary }} onClick={() => setOpenDrawer(true)} />
								</IconButton>
							</Box>
							<SwipeableEdgeDrawer
								open={openDrawer}
								onClose={() => {
									setOpenDrawer(false);
								}}
								container={contRef.current}
							>
								<Box sx={{ py: 4, px: 2 }}>
									{startupDetails?.linkedInUrl !== null && (
										<MenuItem>
											<Typography
												variant="bodyLarge"
												sx={{ whiteSpace: "nowrap" }}
												color="text.darkBlue"
												onClick={() => window.open(linkedInLink, "_blank")}
											>
												View LinkedIn Profile
											</Typography>
										</MenuItem>
									)}
									<MenuItem>
										{/*<Typography variant="bodyLarge">Save</Typography>*/}
										<SaveButton sx={{ pl: 0 }} />
									</MenuItem>
									{startupDetails?.connectionModel?.connectionStatus === "ACCEPTED" ? (
										<MenuItem onClick={handleReqMeeting}>
											<Typography color="text.darkBlue" variant="bodyLarge">
												{" "}
												Request Meeting
											</Typography>
										</MenuItem>
									) : (
										""
									)}
									{startupDetails?.connectionModel?.connectionStatus === "ACCEPTED" ? (
										<MenuItem onClick={handleMessaging}>
											<Typography color="text.darkBlue" variant="bodyLarge">
												<Box sx={{ display: "flex", gap: 1 }}>Send a Message</Box>
											</Typography>
										</MenuItem>
									) : (
										""
									)}
								</Box>
							</SwipeableEdgeDrawer>
						</Grid>
					</Grid>
				</Box>
			)}
		</Grid>
	);
}
