import React from "react";
import { Card, Divider } from "@mui/material";
import theme from "../../../../../constants/Theme";
import FormSectionHeader from "../../../../../components/Workspace/PowerUp/FormSectionHeader";

export default function CardWrapper({ heading, children }) {
  return (
    <Card
      sx={theme => ({
        borderRadius: theme.shape.standard.borderRadius,
        //border: `1px solid ${theme.palette.secondary.dark}`,
        boxShadow:
          "rgb(0 0 0 / 30%) 0px 1px 2px, rgb(0 0 0 / 15%) 0px 1px 3px 1px",
        p: 3,
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.down("md")]: {
          background: "transparent",
          border: "none",
          boxShadow: "0",
          p: 1,
          mt: 2,
        },
      })}
    >
      {/*<FormSectionHeader heading={heading} />*/}
      {/*<Divider />*/}

      {children}
    </Card>
  );
}
