import React from "react";
import CommonSlideDialog from "../../../common/CommonSlideDialog";
import { CREATE_POST_ICON } from "../../../constants";
import { Typography, Button, Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setNetworkingDashboardData } from "../../../modules/actions/networkingActions";

function RequestSentDialog() {
    const requestSentOpen = useSelector((state) => state.networking?.requestSentOpen);
    const dispatch = useDispatch();
    const handleClose = () => {
		dispatch(
			setNetworkingDashboardData({
				requestSentOpen: false,
			})
		);
	};
	return (
		<CommonSlideDialog
			title={
				<Box sx={{ display: "flex", flexDirection: "column", gap: 3, mb: 3 }}>
					<Box height={25} width={25} component={"img"} src={CREATE_POST_ICON} alt="request-sent" />
					<Typography variant={"Text/lg/Semibold"}>Request Sent</Typography>
				</Box>
			}
			titleVariant={"poppinsSemiBold30"}
			isOpen={requestSentOpen}
			onClose={handleClose}
			contentStyle={{ p: 0 }}
			footerStyle={{ p: 0, pt: 3 }}
			titleStyle={{ alignItems: "baseline", px: 0, py: 0 }}
			maxWidth={"sm"}
			fullWidth
			PaperProps={{
				sx: (theme) => ({
					borderRadius: theme.shape.standard10.borderRadius,
					boxShadow: "0px 5px 16px rgba(0, 0, 0, 0.3)",
					p: 5,
					m: 0,
					position: "fixed",
					bottom: 0,
					right: 0,
				}),
			}}
			dialogAction={
				<Box>
					<Button variant="contained" sx={{ height: "40px", borderRadius: "100px" }} onClick={handleClose}>
						Close
					</Button>
				</Box>
			}
		>
			<Typography variant="Text/sm/Regular">We will be reviewing your request. Thank you!</Typography>
		</CommonSlideDialog>
	);
}

export default RequestSentDialog;
