import React from "react";
import { TextField } from "@mui/material";

export const Text = ({ questionId, question, value, disabled = false, onChange, handleAnswer, ...rest }) => {
	const { id, label } = question;

	const handleInputChange = e => {
		onChange(e.target.value);
		handleAnswer(questionId, id, e.target.value);
	};

	return (
		<TextField
			fullWidth
			spellCheck="true"
			disabled={disabled}
			placeholder={label ? label : "Answer"}
			{...rest}
			onChange={e => handleInputChange(e)}
			value={value}
		/>
	);
};
