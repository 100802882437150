import { Box } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import { CategoryScale, Chart, BarElement, BarController } from "chart.js";

Chart.register(CategoryScale, BarElement, BarController);

export const HorizontalBarChart = ({ family, subType, heading, answers }) => {
  const [filteredXAxisData, setFilteredXAxisData] = useState([]);
  const [filteredYAxisData, setFilteredYAxisData] = useState([]);

  useEffect(() => {
    const answersArray = answers;
    const countObj = {};
    answersArray.forEach(element => {
      countObj[element] = (countObj[element] || 0) + 1;
    });

    var uniqueValues = [],
      countValues = [];

    for (var property in countObj) {
      if (!countObj.hasOwnProperty(property)) {
        continue;
      }

      uniqueValues.push(property);
      countValues.push(countObj[property]);
      setFilteredXAxisData(uniqueValues);
      setFilteredYAxisData(countValues);
    }
  }, []);

  const dataObj = {
    labels: filteredXAxisData,
    datasets: [
      {
        fill: false,
        label: heading,
        backgroundColor: "rgb(153,102,254,0.2)",
        borderColor: "rgb(153,102,254)",
        borderWidth: 1,
        borderRadius: 4,
        data: filteredYAxisData,
        
      },
    ],
  };

  options: {
    
  }


  return (
    <Box>
      <Bar
        data={dataObj}
        options={{
          indexAxis: "y",
          plugins: {
            datalabels: {
              display: false,
            },
            legend: {
              display: false
            },
            tooltips: {
              enabled: false
            },
            hover: {mode: null},
          },
          scales: {
            x: {
              ticks: {
                stepSize: 1,
              },
            },
          },
        }}
      />
    </Box>
  );
};
