import React, { useState } from "react";
import { Typography, Button, Box, Checkbox } from "@mui/material";

import CommonSlideDialog from "../../../common/CommonSlideDialog";

const HIGHT_LIGHT_ITEMS = [
	{
		id: 1,
		title: "Top University",
		value: true,
	},
	{
		id: 2,
		title: "Serial Founder",
		value: false,
	},
	{
		id: 3,
		title: "Seasoned Executive",
		value: true,
	},
	{
		id: 4,
		title: "Seasoned Operator",
		value: true,
	},
	{
		id: 5,
		title: "Top Company Alum",
		value: false,
	},
	{
		id: 6,
		title: "Major Tech Company Experience",
		value: false,
	},
	{
		id: 7,
		title: "Legacy Tech Company Experience",
		value: true,
	},
	{
		id: 8,
		title: "Major Research Institution Experience",
		value: false,
	},
	{
		id: 9,
		title: "Venture Background",
		value: false,
	},
	{
		id: 10,
		title: "Prior VC Backed Founder",
		value: true,
	},
	{
		id: 11,
		title: "Prior VC Backed Executive",
		value: true,
	},
	{
		id: 12,
		title: "Prior Exit",
		value: false,
	},
	{
		id: 13,
		title: "Elite Industry Experience",
		value: false,
	},
	{
		id: 14,
		title: "Previous Raise $5M+",
		value: false,
	},
	{
		id: 15,
		title: "Previous Raise $25M+",
		value: false,
	},
];

export default function HighlightsModal({ open, onClose }) {
	const [highLightsItems, setHightLightsItems] = useState(HIGHT_LIGHT_ITEMS);

	const handleChange = (e, index) => {
		highLightsItems[index - 1].value = e.target.checked;
		setHightLightsItems([...highLightsItems]);
	};

	return (
		<CommonSlideDialog
			disableBackDropClick={true}
			title={"Experience Highlights"}
			titleVariant={"card_name"}
			isOpen={open}
			onClose={onClose}
			maxWidth={false}
			transitionDuration={600}
			PaperProps={{
				sx: () => ({
					p: 3,
					ml: "auto",
					alignSelf: "end",
					borderRadius: "30px",
				}),
			}}
			dialogAction={
				<Box display="flex" mt="12px" pr={2}>
					<Button variant="containedNewStyle" onClick={onClose}>
						Close
					</Button>
				</Box>
			}
		>
			<Box mt={2}>
				<Typography variant="card_text" sx={{ fontWeight: 600 }}>
					Areas of score contribution [ 10 ]
				</Typography>
				<Box mt="20px" pr={4}>
					{highLightsItems.map(item => (
						<Box key={item.id} display="flex" alignItems="center">
							<Checkbox checked={item.value} onChange={e => handleChange(e, item.id)} />
							<Typography variant="card_text" sx={{ color: item.value ? "#000" : "#898989" }}>
								{item.title}
							</Typography>
						</Box>
					))}
				</Box>
			</Box>
		</CommonSlideDialog>
	);
}
