import React, { useEffect, useState } from "react";
import moment from "moment";
import { Box, Typography, TextField, styled, Link, Checkbox, Button } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useHistory, useLocation } from "react-router-dom";
import jwt_decode from "jwt-decode";
import GoogleIcon from "../../Assets/google.svg";
import FacebookIcon from "../../Assets/facebook.svg";
import LinkedinIcon from "../../Assets/linkedin.svg";
import LoadingButton from "../../../../common/Loadingbutton";
import { toastContainer } from "../../../../utils/toast";
import services from "../../../../services";
import { loginGoogle, loginLinkedIn } from "../../../../utils/auth0-spa";
import { saveIdToken, saveUserEmail } from "../../../../utils/sessionStorage";
import { handleMixPanel, handleMixPanelSetUser } from "../../../../utils/mixPanelEventHandling";
import { USER_TYPES } from "../../../../utils/userTypes";
import { setAuthData } from "../../../../modules/actions";
import ROUTES_PATH from "../../../../constants/routes";
import { useDispatch, useSelector } from "react-redux";

const schema = yup
	.object({
		firstName: yup.string().required("First Name is required"),
		lastName: yup.string().required("Last Name is required"),
		email: yup.string().email().required("Email is required"),
		password: yup.string().min(8).required("Password is required"),
		confirmPassword: yup.string().oneOf([yup.ref("password"), null], "Passwords don't match"),
	})
	.required();

export const SignUp = ({ handleExcited }) => {
	const [isLoading, setIsLoading] = useState(false);
	const [isConsentChecked, setIsconsentChecked] = useState(false);
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	// const utm_source = searchParams.get("utm_source");
	// const utm_campaign = searchParams.get("utm_campaign");
	// const utm_medium = searchParams.get("utm_medium");
	// const partner = searchParams.get("partner");
	const { utmSource, utmCampaign, utmMedium, partner } = useSelector((store) => store.utmParams);

	const [decodedValue, setDecodedValue] = useState("");
	const token = searchParams.get("token");
	const history = useHistory();
	const dispatch = useDispatch();
	const {
		handleSubmit,
		control,
		setValue,
		formState: { errors, isValid },
	} = useForm({
		mode: "all",
		defaultValues: {
			firstName: "",
			lastName: "",
			email: "",
			password: "",
			confirmPassword: "",
		},
		resolver: yupResolver(schema),
	});
	useEffect(() => {
		if (token) {
			const decodedData = jwt_decode(token);
			setDecodedValue(decodedData);
			setValue("email", decodedData?.email ? decodedData?.email : "");
		}
	}, []);

	const handleConsentChange = (event) => {
		const consentChecked = event.target.checked;
		setIsconsentChecked(consentChecked);
		console.log('Checkbox checked:', consentChecked);
	  };

	const handleDirectLogin = (signUpRes) => {
		try {
			const decodedData = jwt_decode(token);
			dispatch(
				setAuthData({
					idToken: signUpRes?.idToken,
					userType: USER_TYPES.STARTUP_USER,
					userId: signUpRes?.id,
					email: signUpRes?.email,
					calUserId: signUpRes?.calUserId,
					userName: signUpRes?.name,
					userData: {
						picture: signUpRes.picture,
					},
					isPremiumPlanAccess: signUpRes?.companyModel?.isPremiumPlanAccess ? signUpRes?.companyModel?.isPremiumPlanAccess : false,
					companyName: decodedData?.companyName,
					companyId: decodedData?.companyId,
					authenticatedAppUser: true,
					userSessionChange: false,
				})
			);

			handleMixPanel("Account Login", {
				"User ID": signUpRes?.id,
				"User Name": signUpRes?.name,
				"User Role": USER_TYPES.STARTUP_USER,
			});
			history.push(ROUTES_PATH.FOUNDER_WORKSPACE);
		} catch (error) {
			toastContainer(error?.response?.data?.message || "Something is wrong!", "error");
		}
	};

	const onSubmit = async (formData) => {
		try {
			setIsLoading(true);
			const resData = await services.createAccount({
				data: {
					firstName: formData.firstName,
					lastName: formData.lastName,
					email: formData.email,
					password: formData.password,
					emailVerified: decodedValue?.email === formData.email,
					emailToken: token ? token : "",
					timeZone: moment.tz.guess(),
				},
				queryParams: {
					...(utmSource && { utm_source: utmSource }),
					...(utmCampaign && { utm_campaign: utmCampaign }),
					...(utmMedium && { utm_medium: utmMedium }),
					...(partner && { partner }),
				},
			});
			handleMixPanelSetUser(resData?.data?.data);

			handleMixPanel("Account Created", {
				id: resData?.data?.data?.id,
				firstName: resData?.data?.data?.firstName,
				lastName: resData?.data?.data?.lastName,
				email: resData?.data?.data?.email,
				...(utmSource && { "UTM Source": utmSource }),
				...(utmCampaign && { "UTM Compaign": utmCampaign }),
				...(utmMedium && { "UTM Medium": utmMedium }),
				...(partner && { "Partner Medium": partner }),
			});
			if (decodedValue?.email === formData.email) {
				handleDirectLogin(resData?.data?.data);
			} else {
				handleExcited(resData?.data?.data);
			}
			setIsLoading(false);
		} catch (err) {
			setIsLoading(false);
			if (err.response.status === 409) {
				toastContainer(err?.response.data?.message, "error");
			} else {
				toastContainer(err?.error_description, "error");
			}
		}
	};

	const handleFacebookLogin = () => {};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Box width="400px" alignItems="center" display="flex" flexDirection="column" gap={4}>
				<Typography variant="growth_otherpercentage_text">Create an Account</Typography>
				<Box width="400px" display="flex" flexDirection="column" gap={2.5}>
					<Box>
						<Controller
							name="firstName"
							control={control}
							render={({ field: { onChange, value, name } }) => (
								<TextField
									fullWidth
									id="firstName"
									placeholder="First Name"
									value={value}
									name={name}
									sx={{
										"& .MuiOutlinedInput-notchedOutline": {
											border: errors.name ? "1px solid #F04438 !important" : "1px solid #DDDDDD",
										},
									}}
									InputProps={{ style: FormElementStyle }}
									onChange={onChange}
								/>
							)}
						/>
						{errors.firstName && (
							<Typography variant="subhead2" sx={{ color: "#F04438" }}>
								{errors.firstName.message}
							</Typography>
						)}
					</Box>

					<Box>
						<Controller
							name="lastName"
							control={control}
							render={({ field: { onChange, value, name } }) => (
								<TextField
									fullWidth
									id="lastName"
									placeholder="Last Name"
									value={value}
									name={name}
									sx={{
										"& .MuiOutlinedInput-notchedOutline": {
											border: errors.name ? "1px solid #F04438 !important" : "1px solid #DDDDDD",
										},
									}}
									InputProps={{ style: FormElementStyle }}
									onChange={onChange}
								/>
							)}
						/>
						{errors.lastName && (
							<Typography variant="subhead2" sx={{ color: "#F04438" }}>
								{errors.lastName.message}
							</Typography>
						)}
					</Box>

					<Box>
						<Controller
							name="email"
							control={control}
							render={({ field: { onChange, value, name } }) => (
								<TextField
									fullWidth
									id="email"
									placeholder="Email Address"
									value={value}
									name={name}
									sx={{
										"& .MuiOutlinedInput-notchedOutline": {
											border: errors.email ? "1px solid #F04438 !important" : "1px solid #DDDDDD",
										},
									}}
									InputProps={{ style: FormElementStyle }}
									onChange={onChange}
								/>
							)}
						/>
						{errors.email && (
							<Typography variant="subhead2" sx={{ color: "#F04438" }}>
								{errors.email.message}
							</Typography>
						)}
					</Box>

					<Box>
						<Controller
							name="password"
							control={control}
							render={({ field: { onChange, value, name } }) => (
								<TextField
									fullWidth
									id="password"
									placeholder="Create Password"
									type="password"
									value={value}
									name={name}
									autoComplete="on"
									sx={{
										"& .MuiOutlinedInput-notchedOutline": {
											border: errors.password ? "1px solid #F04438 !important" : "1px solid #DDDDDD",
										},
									}}
									InputProps={{ style: FormElementStyle }}
									onChange={onChange}
								/>
							)}
						/>
						{errors.password && (
							<Typography variant="subhead2" sx={{ color: "#F04438" }}>
								{errors.password.message}
							</Typography>
						)}
					</Box>

					<Box>
						<Controller
							name="confirmPassword"
							control={control}
							render={({ field: { onChange, value, name } }) => (
								<TextField
									fullWidth
									id="confirmPassword"
									placeholder="Confirm Password"
									type="password"
									value={value}
									name={name}
									autoComplete="on"
									sx={{
										"& .MuiOutlinedInput-notchedOutline": {
											border: errors.password ? "1px solid #F04438 !important" : "1px solid #DDDDDD",
										},
									}}
									InputProps={{ style: FormElementStyle }}
									onChange={onChange}
								/>
							)}
						/>
						{errors.confirmPassword && (
							<Typography variant="subhead2" sx={{ color: "#F04438" }}>
								{errors.confirmPassword.message}
							</Typography>
						)}
					</Box>

					<LoadingButton 
						fullWidth 
						type="submit" 
						variant="DS1" 
						disabled={!isValid || isLoading} loading={isLoading}
						onClick={(event) => {
							if (!isConsentChecked) {
								event.preventDefault();
								toastContainer("You must agree to the StartupOS Privacy Policy and Terms of Service to create an account", "error");
							}
						}}	
					>
						Create Account
					</LoadingButton>
				</Box>

				<Typography variant="Text/sm/Semibold">OR</Typography>

				<Box width="400px" display="flex" flexDirection="column" gap={2.5}>
					<SocialBtn
						title="Sign up with Google"
						icon={GoogleIcon}
						disabled={!isConsentChecked}
						onClick={() => {
							if (isConsentChecked) {
								loginGoogle({
									utm_source: utmSource,
									utm_medium: utmMedium,
									utm_campaign: utmCampaign,
									partner: partner,
								});
							} else {
								toastContainer("You must agree to the StartupOS Privacy Policy and Terms of Service to create an account", "error");
							}
						}}
					/>
					{/* <SocialBtn title="Sign up with Facebook" icon={FacebookIcon} onClick={handleFacebookLogin} /> */}
					<SocialBtn
						title="Sign up with LinkedIn"
						icon={LinkedinIcon}
						onClick={() => {
							if (isConsentChecked) {
								loginLinkedIn({
									utm_source: utmSource,
									utm_medium: utmMedium,
									utm_campaign: utmCampaign,
									partner: partner,
								});
							} else {
								toastContainer("You must agree to the StartupOS Privacy Policy and Terms of Service to create an account", "error");
							}
						}}
					/>
				</Box>

				<Typography
					variant="Text/sm/Regular"
					color="#667085"
					sx={{
						textAlign: "center",
						padding: "0 49px",
					}}
				>
					<Checkbox
						onChange={handleConsentChange}
						inputProps={{ 'aria-label': 'controlled' }}
					/>
					I agree to StartupOS' &nbsp;
					<Link sx={{ cursor: "pointer", color: "#667085" }} target="_blank" href="https://startupos.com/terms-of-service/">
						Privacy Policy
					</Link>
					&nbsp; & &nbsp;
					<Link sx={{ cursor: "pointer", color: "#667085" }} target="_blank" href="https://startupos.com/privacy-policy/">
						Terms of Service
					</Link>
				</Typography>
			</Box>
		</form>
	);
};

export const SocialBtn = ({ icon, title, onClick }) => {
	return (
		<Box
			position="relative"
			height="48px"
			p="0 20px"
			display="flex"
			alignItems="center"
			justifyContent="center"
			sx={{ cursor: "pointer", background: "#fff", borderRadius: "100px", boxShadow: "0px 10px 25px 0px #0000000D" }}
			onClick={() => onClick()}
		>
			<Box position="absolute" left="20px" top="14px">
				<img src={icon} alt={title} />
			</Box>
			<Typography color="#667085" variant="Text/sm/Semibold">
				{title}
			</Typography>
		</Box>
	);
};

export const FormElementStyle = {
	borderRadius: "30px",
	fontWeight: "500",
	fontSize: "16px",
	lineHeight: "24px",
	fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
	padding: "1.5px 6px",
	background: "#fff",
	boxShadow: "0px 10px 25px 0px #0000000D",
};

export const BtnLogin = styled(LoadingButton)({
	background: "#7B61FF",
	color: "#FFFFFF",
	borderRadius: "100px",
	height: "48px",
	maxWidth: "100%",
	fontWeight: "600",
	fontSize: "16px",
	lineHeight: "24px",
	"&.MuiButton-root.Mui-disabled": {
		color: "#fff",
		background: "#B7A8FF",
	},
});
