import React, { useEffect, useState, useRef } from "react";
import { generatePath, useHistory } from "react-router-dom";
import PowerUpTable from "../PowerUpTable";
import { CircularProgress, Box, Button, Typography, useMediaQuery, Divider, Grid } from "@mui/material";
import { getMyWorkDetails, archiveModuleResponse } from "../../functions";
import messages from "../../../../../constants/messages";
import { toastContainer } from "../../../../../utils/toast";
import MessageHandlerModal from "../../../../../common/modal/MessageHandler";
import MyWorkBreadCrumbs from "../MyWorkBreadcrumbs";
import GridListView from "../../../../../components/Workspace/GridListView";
import theme from "../../../../../constants/Theme";
import PowerUpGrid from "../PowerUpGrid";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../../modules/actions";
import DialogComponent from "../../../../Workspace/PowerUP/IdeaValidation/Components/Dialog";
import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import services from "../../../../../services";
import DetailsContainer from "../../../../../components/MyStartup/StaticSummary/DetailsContainer";
import SummaryContainer from "../../../../../components/MyStartup/StaticSummary/SummaryContainer";
import EditIcon from "@mui/icons-material/Edit";
import TocIcon from "@mui/icons-material/Toc";
import MenuDrawerCommon from "../../../../../common/MenuDrawerCommon";
import DeleteDrawer from "../../../../../components/ExternalCandidates/Drawers/DeleteDrawer";
import DownloadIcon from "@mui/icons-material/Download";
import SummaryExport from "../../../../../common/SummaryExport/index";
import ROUTES_PATH from "../../../../../constants/routes";
import { handleMixPanel } from "../../../../../utils/mixPanelEventHandling";

export default function VersionsPage({ id, isListView, setIsListView, handleArchivedBack, subpath, handleBreadcrumbBack, handleViewPowerUp }) {
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
	const history = useHistory();
	const [details, setDetails] = useState([]);
	const [isAscendingName, setIsAscendingName] = useState(true);
	const [isAscendingDate, setIsAscendingDate] = useState(true);
	const [loading, setLoading] = useState(true);

	const [sort, setSort] = useState("ASC"); //ASC,DESC
	const [archived, setArchived] = useState(false);
	const [filter, setFilter] = useState("name"); //NAME,DATE
	const [open, setOpen] = useState(false);
	const [versionId, setVersionId] = useState("");

	const [page, setPage] = useState(1);
	const [totalPages, setTotalPages] = useState(0);
	const [isLast, setIsLast] = useState(false);
	const dispatch = useDispatch();

	const [openSummary, setOpenSummary] = useState(false);
	const [moduleDetails, setModuleDetails] = useState(null);
	const [moduleId, setModuleId] = useState(null);
	const [powerupName, setPowerupName] = useState(null);
	const [itemId, setItemId] = useState(null);
	const [pUDetails, setPuDetails] = useState(null);
	const [openDrawer, setOpenDrawer] = useState(false);
	const [showDeleteDrawer, setShowDeleteDrawer] = useState(false);
	const [powerUpsLoaded, setPowerUpsLoaded] = useState(false);
	const summaryGridRef = useRef(null);
	const powerUpPercentComplete = useSelector((state) => state?.mixpanel?.powerUpPercentComplete);

	useEffect(() => {
		handleGetDetails();
	}, [id, sort, filter]);

	const handleGetDetails = () => {
		getMyWorkDetails("", sort, archived, "", filter, id).then((res) => {
			if (res?.status === 200) {
				// if (page === 1) {
				const filteredData = res?.data?.data.filter((item) => !item.isArchived);
				setDetails(filteredData);
				// } else {
				// setDetails([...details, ...res.data.data]);
				// }

				// setTotalPages(res.data.data.totalPages);
				// setIsLast(res.data.data.isLast);
				setLoading(false);
				setPowerUpsLoaded(true);
			} else {
				toastContainer(messages.API_SUBMIT_ERROR, "error");
			}
		});

		//sorting
		if (filter === "name") {
			if (sort === "ASC") {
				setIsAscendingName(true);
			} else {
				setIsAscendingName(false);
			}
		} else {
			if (sort === "ASC") {
				setIsAscendingDate(true);
			} else {
				setIsAscendingDate(false);
			}
		}
	};

	// useEffect(() => {
	// 	if (page !== 1) {
	// 		//To Skip initial render
	// 		handleGetDetails();
	// 	}
	// }, [page]);

	useEffect(() => {
		let powerUpName = "";

		if (id == 1) {
			powerUpName = "Value Proposition";
		} else if (id == 2) {
			powerUpName = "Founder Story";
		} else if (id == 3) {
			powerUpName = "Persona Builder";
		} else if (id == 5) {
			powerUpName = "Problem Solution Fit";
		} else if (id == 6) {
			powerUpName = "Brandstorming";
		} else if (id == 7) {
			powerUpName = "TAM/SAM/SOM";
		} else if (id == 9) {
			powerUpName = "Pricing Modeler";
		} else if (id == 20) {
			powerUpName = "MVP Prioritizer";
		} else {
			powerUpName = "PowerUp";
		}

		if (details.length > 0) {
			handleMixPanel("Work History Details Viewed", {
				"Powerup ID": id,
				"Powerup Name": powerUpName,
				"Works Count": details.length,
			});
		}
	}, [powerUpsLoaded]);

	const callNextPage = () => {
		// setPage((page) => page + 1);
	};

	const handleArchive = (id) => {
		setVersionId(id);
		handleModal();
	};

	const updateArchive = (responseId) => {
		let powerUpName = "";

		// this needs to be a common method that spits out a powerup name given a number
		if (id == 1) {
			powerUpName = "Value Proposition";
		} else if (id == 2) {
			powerUpName = "Founder Story";
		} else if (id == 3) {
			powerUpName = "Persona Builder";
		} else if (id == 5) {
			powerUpName = "Problem Solution Fit";
		} else if (id == 6) {
			powerUpName = "Brandstorming";
		} else if (id == 7) {
			powerUpName = "TAM/SAM/SOM";
		} else if (id == 9) {
			powerUpName = "Pricing Modeler";
		} else if (id == 20) {
			powerUpName = "MVP Prioritizer";
		} else {
			powerUpName = "PowerUp";
		}

		handleMixPanel("PowerUp Archived", {
			"Powerup ID": id,
			"Powerup Name": powerUpName,
			"Powerup Complete": powerUpPercentComplete,
		});

		archiveModuleResponse({ responseId: responseId, status: true }).then((res) => {
			if (res?.status === 200) {
				setLoading(true);
				handleGetDetails();
			} else {
				toastContainer(messages.API_SUBMIT_ERROR, "error");
			}
			handleModal();
			setOpenSummary(false);
		});
	};

	const handleModal = () => {
		setOpen(!open);
	};

	const getSummaryDetails = (id, moduleId) => {
		services
			.getModuleStepResponses(id)
			.then((result) => {
				if (result?.status === 200) {
					setModuleDetails(result?.data?.data?.find((obj) => obj?.id == moduleId)?.moduleStepResponses);
					setOpenSummary(true);
				}
			})
			.catch((err) => console.log(err));
	};

	const handleRedirectior = (status, item, name) => {
		setPuDetails(item);
		dispatch(actions.setWorkProfile(item));

		if (status) {
			if (isSmallScreen) {
				if (subpath === "MVP Prioritizer") {
					history.push({
						pathname: "/create-matrix",
						state: {
							started: "COMPLETED",
						},
					});
				} else {
					getSummaryDetails(item?.module?.id, item?.id);
					setModuleId(item?.module?.id);
					setPowerupName(subpath);
					setItemId(item?.id);
				}
			} else {
				if (subpath === "MVP Prioritizer") {
					history.push({
						pathname: "/create-matrix",
						state: {
							started: "COMPLETED",
						},
					});
				} else {
					history.push({
						pathname: `/workspace/mywork/${item?.module?.id}/summary`,
						state: {
							powerup: subpath,
							name: name,
							moduleId: item?.id,
							powerupId: id,
						},
					});
				}
			}
		} else {
			if (subpath === "Persona Builder") {
				history.push({
					pathname: generatePath(ROUTES_PATH.WORKSPACE_POWERUP_DETAILS, {
						id: 3,
					}),
				});
				dispatch(
					actions.setPowerupHeaderDetails({
						currentModuleResponseId: item?.id,
					})
				);
			} else if (subpath === "MVP Prioritizer") {
				history.push({
					pathname: "/create-matrix",
					state: {
						started: "STARTED",
					},
				});
			} else {
				history.push(`/workspace/powerup/${item?.module?.moduleUuid}/${item?.module?.id}/${item?.id}/start`);
			}
		}
	};

	const handleSort = (sort, filter) => {
		setSort(sort); //ASC,DESC
		setFilter(filter); //NAME,DATE
		setPage(1);
		setLoading(true);
	};

	const FooterComponent = () => {
		return (
			<>
				<Box
					sx={{
						display: "flex",
						gap: 1,
						alignItems: "center",
					}}
				>
					<DownloadIcon sx={{ cursor: "pointer", fill: theme.palette.secondary.main }} onClick={() => setShowDeleteDrawer(true)} />

					<MoreVertIcon sx={{ cursor: "pointer", fill: theme.palette.secondary.main }} onClick={() => setOpenDrawer(true)} />
				</Box>
			</>
		);
	};
	const handleDrawerClose = () => {
		setOpenDrawer(false);
	};

	const handleEditInSummary = () => {
		if (id === 3) {
			dispatch(
				actions.setPowerupHeaderDetails({
					currentModuleResponseId: pUDetails.id,
				})
			);
			history.push({
				pathname: generatePath(ROUTES_PATH.WORKSPACE_POWERUP_DETAILS, {
					id: 3,
				}),
			});
		} else {
			history.push(`/workspace/powerup/${pUDetails?.module?.moduleUuid}/${pUDetails?.module?.id}/${pUDetails?.id}/start`);
		}
	};
	const handleViewDetails = () => {
		history.push(`/workspace/powerup/${pUDetails?.module?.id}/start`);
	};

	const updateArchiveStatus = () => {
		handleArchive(pUDetails?.id);
	};

	const handleDeleteDrawerClose = () => {
		setShowDeleteDrawer(false);
	};

	const handleExportClick = (type) => {
		summaryGridRef.current.handleExport(type);
		setShowDeleteDrawer(false);
	};

	const handleSwitchView = (view) => {
		setIsListView(view);
		setPage(1);
	};

	useEffect(() => {
		if (isSmallScreen) {
			setIsListView("GRID");
		}
	}, [isSmallScreen]);

	//keep above return
	let summaryOptions = [
		{
			icon: <EditIcon />,
			text: "Edit in Summary",
			action: handleEditInSummary,
		},
		{
			icon: <TocIcon />,
			text: "View PowerUp Details",
			action: handleViewDetails,
		},
		{
			icon: <ArchiveOutlinedIcon />,
			text: "Archive",
			action: updateArchiveStatus,
		},
	];
	return (
		<>
			<Box>
				<SummaryExport ref={summaryGridRef} name={powerupName}></SummaryExport>
			</Box>
			<Box
				sx={{
					width: 1,
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-between",
					alignItems: "center",
					alignContent: "center",
					textAlign: "center",
					pt: 2,
				}}
			>
				<MyWorkBreadCrumbs subpath={subpath} handleBreadcrumbBack={handleBreadcrumbBack} />
				<Box
					sx={{
						display: { xs: "none", md: "flex" },
						flexDirection: "row",
						gap: 1,
						pr: 2,
					}}
				>
					<GridListView view={isListView} setView={handleSwitchView} />
					<Button onClick={handleViewPowerUp}>View PowerUp details</Button>
				</Box>
			</Box>
			{loading ? (
				<Box sx={{ display: "flex", width: 1, justifyContent: "center", py: 4 }}>
					<CircularProgress />
				</Box>
			) : (
				<>
					{details?.length > 0 ? (
						<>
							{!isSmallScreen && isListView === "LIST" && (
								/* List */
								<PowerUpTable
									details={details}
									isAscendingName={isAscendingName}
									isAscendingDate={isAscendingDate}
									handleRedirectior={handleRedirectior}
									handleArchive={handleArchive}
									totalPages={totalPages}
									handleGetDetails={handleGetDetails}
									handleSort={handleSort}
									page={page}
									setPage={setPage}
									callNextPage={callNextPage}
									isLast={isLast}
								/>
							)}
							{isListView === "GRID" && (
								<PowerUpGrid
									details={details}
									handleRedirectior={handleRedirectior}
									handleArchive={handleArchive}
									callNextPage={callNextPage}
									isLast={isLast}
								/>
							)}
						</>
					) : (
						<Box
							sx={{
								display: "flex",
								width: 1,
								justifyContent: "center",
								alignContent: "center",
								py: 5,
							}}
						>
							<Typography color="primary" variant="h5" sx={{ color: theme.palette.secondary.dark }}>
								No data found
							</Typography>
						</Box>
					)}

					<MessageHandlerModal
						isOpen={open}
						onCancel={handleModal}
						onOk={() => updateArchive(versionId)}
						text="Are you sure you want to archive this?"
						okText="Archive"
						cancelText="No Thanks"
						messageType="primary"
						heading={"Archive"}
					/>
				</>
			)}
			{/* summary dialog responsive */}
			<DialogComponent
				isOpen={openSummary}
				onClose={() => setOpenSummary(false)}
				title={"Completed Summary"}
				dialogAction={<FooterComponent />}
				titleVariant="largeTitle"
				sx={(theme) => ({
					display: "flex",
					maxWidth: 1,
					flexDirection: "column",
					borderRadius: theme.shape.standard23.borderRadius,
					height: 1,
					"& .MuiDialog-paper": {
						width: 1,
						borderRadius: 3,
					},
					[theme.breakpoints.down("md")]: {
						maxHeight: "90%",
					},
				})}
				contentStyle={{
					mt: 2,
				}}
				footerStyle={{
					py: 2,
				}}
			>
				<Grid container id="summaryPage">
					<Grid item xs={12} sm={12}>
						<DetailsContainer item={pUDetails} powerup={subpath} version={powerupName} />
						<Divider
							sx={{
								mt: 3,
								background: `${theme.palette.text.background}`,
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={12} sx={{ maxWidth: 1 }}>
						<Box
							sx={(theme) => ({
								display: "flex",
								maxWidth: 1,
								maxHeight: 1,
								py: 4,
								background: subpath !== "Persona Builder" ? theme.palette.secondary.greyBg : theme.palette.secondary.white,
								borderRadius: theme.shape.standard5.borderRadius,
								mt: 2,
								px: 2,
							})}
						>
							<SummaryContainer powerup={subpath} response={moduleDetails} versionId={itemId} moduleId={moduleId} />
						</Box>
					</Grid>
				</Grid>
			</DialogComponent>
			{/* Mob Drawer */}
			<MenuDrawerCommon open={openDrawer} onClose={handleDrawerClose} menuOptions={summaryOptions} />
			<DeleteDrawer
				open={showDeleteDrawer}
				onClose={() => handleDeleteDrawerClose()}
				title={"Download as..."}
				startIcon={<DownloadIcon sx={{ fill: theme.palette.secondary.main }} />}
				primaryActionText={"PNG"}
				secondaryActionText={"PDF"}
				primaryAction={() => handleExportClick("png")}
				secondaryAction={() => handleExportClick("pdf")}
			/>
		</>
	);
}
