import React, { useState } from "react";
import { Box, Button, Typography, Divider, useMediaQuery } from "@mui/material";
import theme from "../../../../../constants/Theme";
import InterviewScript from "../Components/InterviewScript";
import { getDefaultProblemSolutionScriptData } from "../Functions/common";
import { useEffect } from "react";
import services from "../../../../../services";
import BuildScriptHeader from "../Components/BuildScriptHeader";
import { useSelector } from "react-redux";
import { getScriptDescriptionData } from "../../../../../utils/commonFunctions";

const BuildInterviewScript = ({
  userInputs,
  handleSave,
  buttonLoading,
  triggerSave,
  setTriggerSave,
}) => {
  const [scriptData, setScriptData] = useState();
  const marketTest = useSelector(store => store.powerUp?.marketTest);

  const [defaultData, setDefaultData] = useState(
    getScriptDescriptionData(marketTest.powerUpModel.powerUpName)
  );
  const [currentPowerup, setCurrentPowerup] = useState({});

  const storeItems = useSelector(store => store.powerUp?.powerUpExcercises);

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    const currentItem = storeItems.find(
      excercise => excercise.stepNo === 2
    )?.answers;

    if (currentItem?.interviewScript) {
      if (currentItem?.interviewScript.length) {
        setScriptData(currentItem?.interviewScript);
      } else {
        setScriptData(
          getScriptDescriptionData(marketTest.powerUpModel.powerUpName)
        );
      }
    } else {
      services
        .getCompanyProfile()
        .then(response => {
          const resData = response.data.data;
          let questions = [];
          if (userInputs?.questionsList) {
            userInputs?.questionsList?.map(question => {
              if (question.selected) {
                questions.push(question?.goal);
              }
            });
          }
          if (resData) {
            if (userInputs) {
              if (currentItem?.interviewScript.length) {
                setScriptData(currentItem?.interviewScript);
              } else {
                setScriptData(
                  getScriptDescriptionData(marketTest.powerUpModel.powerUpName)
                );
              }
            }
            setDefaultData(
              getScriptDescriptionData(marketTest.powerUpModel.powerUpName)
            );
          }
        })
        .catch(err => console.log(err));
    }
    getPowerupTasks();
  }, []);
  //api call for powerup packs details
  const getPowerupTasks = async () => {
    try {
      const details = await services.getPoweupPacksDetails(1);
      const data = details.data;

      let resObj = data.data.manifest.tasks;
      let newData = resObj.find(
        item => item?.powerUp?.id == marketTest?.powerUpModel?.id
      );
      setCurrentPowerup(newData);
    } catch (error) {
      console.log(error);
    }
  };
  const handleSaveAction = updatedScriptData => {
    handleSave({ interviewScript: [...updatedScriptData] });
  };

  const resetAction = () => {
    setScriptData(defaultData);
  };

  return (
    <Box>
      <BuildScriptHeader
        marketTest={marketTest}
        currentPowerup={currentPowerup}
      />
      <Divider
        sx={{
          background: theme.palette.secondary.dark,
        }}
      />

      {isSmallScreen ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 3,
          }}
        >
          <Typography variant="title_large">Your Script </Typography>
          <Button
            variant="nofill"
            onClick={() => {
              resetAction();
            }}
            sx={{ width: "max-content", mx: 0 }}
          >
            Reset to default
          </Button>
        </Box>
      ) : (
        ""
      )}
      <Box
        sx={theme => ({
          mt: 3,
          border: `1px solid ${theme.palette.secondary.dark}`,
          borderRadius: 3,
          py: 2,
          [theme.breakpoints.up("md")]: {
            px: 3,
          },
          [theme.breakpoints.down("md")]: {
            px: 2,
            background: theme.palette.text.background,
          },
        })}
      >
        <InterviewScript
          scriptConfigData={scriptData || defaultData}
          defaultData={defaultData}
          handleSaveAction={handleSaveAction}
          resetAction={resetAction}
          buttonLoading={buttonLoading}
          triggerSave={triggerSave}
          setTriggerSave={setTriggerSave}
          hideRestoreOption={isSmallScreen ? true : false}
        />
      </Box>
      <Box sx={{ pt: 3 }}>
        <Typography variant="subhead1">
          If you’re satisfied with the script, you can begin searching for
          candidates that match your target profile.
        </Typography>
      </Box>

      <Button
        variant="nofill"
        sx={{
          display: { sm: "flex", md: "none" },
          justifyContent: "center",
          mt: 4,
        }}
      >
        Return to PowerUp Details
      </Button>
    </Box>
  );
};

export default BuildInterviewScript;
