import { Box, CircularProgress, Typography } from "@mui/material";
import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import LineGraph from "./LineGraph";

function ScoreTrendChart({ label_array, data_array, range }) {
	const loading = useSelector((state) => state?.investorDashboard?.scoreTrendRangeLoading);
	const start = label_array[0] ? new Date(label_array[0]) : "";
	const end = label_array[label_array?.length - 1] ? new Date(label_array[label_array?.length - 1]) : "";

	const monthOption = range === "1M" ? { month: "short", day: "numeric" } : { month: "short" };

	const startMonth = start.toLocaleString("en-US", monthOption);
	const endMonth = end.toLocaleString("en-US", monthOption);

	const startYear = start.toLocaleString("en-US", { year: "numeric" });
	const endYear = end.toLocaleString("en-US", { year: "numeric" });

	const yearText = startYear === endYear ? startYear : `${startYear} - ${endYear}`;

	return (
		<Box>
			{loading ? (
				<Box sx={{ display: "flex", flex: 1, alignItems: "center", justifyContent: "center" }}>
					<CircularProgress />
				</Box>
			) : (
				<Fragment>
					<LineGraph label_array={label_array} data_array={data_array} />
					<Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
						<Typography
							variant="customValue"
							sx={{
								color: "black",
								fontFamily: "PoppinsMedium",
								fontSize: "18px",
								lineHeight: "27px",
							}}
						>
							{startMonth}
						</Typography>
						<Typography
							variant="customValue"
							sx={{
								color: "black",
								fontFamily: "PoppinsMedium",
								fontSize: "18px",
								lineHeight: "27px",
							}}
						>
							{yearText}
						</Typography>
						<Typography
							variant="customValue"
							sx={{
								color: "black",
								fontFamily: "PoppinsMedium",
								fontSize: "18px",
								lineHeight: "27px",
							}}
						>
							{endMonth}
						</Typography>
					</Box>
				</Fragment>
			)}
		</Box>
	);
}

export default ScoreTrendChart;
