import React from "react";
import { Box, Button, Typography } from "@mui/material";
import CommonSlideDialog from "../../../../common/CommonSlideDialog";
import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";

const AreYouEnterpriseDialog = ({ startup, openAreYouEnterpriseDialog, setOpenAreYouEnterpriseDialog, enterpriseReadyScore }) => {
	const enterpriseReadyObj = startup?.enterpriseReadyJson && JSON.parse(startup?.enterpriseReadyJson);
	const RISK = [
		{
			question: "Does your startup have a clear, long-term strategic plan?",
			answer: enterpriseReadyObj?.["strategic-plan"],
		},
		{
			question: "Does your startup have strategic partnerships or alliances that enhance its value proposition?",
			answer: enterpriseReadyObj?.["strategic-partnerships"],
		},
		{
			question: "Does your startup hold at least one patent that protects your unique product or IP?",
			answer: enterpriseReadyObj?.patent,
		},
		{
			question: "Do you have a strategy for leveraging your unique product or IP for competitive advantage?",
			answer: enterpriseReadyObj?.["strategy-for-leveraging"],
		},
		{
			question: "Does your startup own cash or collateral equal to at least 80% of its current financial liability?",
			answer: enterpriseReadyObj?.["startup-own-cash"],
		},
		{
			question: "Are there any liens against your startup, or is your startup involved in any form of litigation?",
			answer: enterpriseReadyObj?.["any-litigation"],
		},
		{
			question: "Are there any legal liabilities or exceptional risks involved with using your startup or product?",
			answer: enterpriseReadyObj?.["legal-liabilities"],
		},
	];

	const PRODUCT_SERVICES = [
		{
			question: "Does your product/service offering scale?",
			answer: enterpriseReadyObj?.["product-offering-scale"],
		},
		{
			question: "Does your product/service offer a unique value proposition compared to competitors?",
			answer: enterpriseReadyObj?.["unique-value-proposition"],
		},
		{
			question: "Do you have a clear understanding of the competitive landscape?",
			answer: enterpriseReadyObj?.["competitive-landscape"],
		},
		{
			question: "Is there a roadmap for future product/service development and innovation?",
			answer: enterpriseReadyObj?.roadmap,
		},
		{
			question: "Does your startup have a well-defined process for soliciting and incorporating customer feedback?",
			answer: enterpriseReadyObj?.["process-for-soliciting"],
		},
		{
			question: "Do you have clear processes to support customers?",
			answer: enterpriseReadyObj?.["support-customers"],
		},
		{
			question: "Do you have a flagship customer?",
			answer: enterpriseReadyObj?.["flagship-customer"],
		},
		{
			question: "Do you have a proven pricing strategy, and saleable, packaged product/service menu?",
			answer: enterpriseReadyObj?.["proven-pricing-strategy"],
		},
		{
			question: "Does your team have a proven (profitable) sales and marketing strategy?",
			answer: enterpriseReadyObj?.["proven-sales"],
		},
		{
			question: "Are there metrics in place to measure the success of sales and marketing efforts?",
			answer: enterpriseReadyObj?.["measure-the-success"],
		},
		{
			question: "Do you have endorsements, testimonials and referrals outside your friends & family circle?",
			answer: enterpriseReadyObj?.endorsements,
		},
	];

	const BUSINESS_OPERATIONS = [
		{
			question:
				"Does your business meet operational, regional and industry-specific compliance requirements? (Ex. PCI for payment processing, GDPR for EU data, HIPPA for healthcare)",
			answer: enterpriseReadyObj?.["business-meet-operational"],
		},
		{
			question: "Are there clear policies for regulatory compliance in all operational regions?",
			answer: enterpriseReadyObj?.["clear-policies-for-regulatory"],
		},
		{
			question: "Are you ready for procurement, contracts, pricing, NDAs?",
			answer: enterpriseReadyObj?.["ready-for-procurement"],
		},
		{
			question: "Does your startup have a clear, ongoing strategic initiative to attract and retain talent?",
			answer: enterpriseReadyObj?.["attract-and-retain-talent"],
		},
		{
			question: "Are there effective HR policies in place, including diversity and inclusion initiatives?",
			answer: enterpriseReadyObj?.["clear-HR-policies"],
		},
		{
			question: "Does the startup have policies regarding environmental sustainability?",
			answer: enterpriseReadyObj?.["clear-environmental-sustainability-policies"],
		},
		{
			question: "Does the startup engage in social responsibility initiatives?",
			answer: enterpriseReadyObj?.["social-responsibility-initiatives"],
		},
	];

	const SOFTWARE_PLATOFORM = [
		{
			question: "Does your startup depend on a Web / SaaS platform?",
			answer: enterpriseReadyObj?.["depend-on-a-Web"],
		},
		{
			question: "Is your platform SOC 2 compliant?",
			answer: enterpriseReadyObj?.["SOC-2-compliant"],
		},
		{
			question: "Does your platform meet operational, regional and industry-specific compliance requirements?",
			answer: enterpriseReadyObj?.["platform-meet-compliance"],
		},
		{
			question: "Can the startup's technology infrastructure scale to meet increasing demands?",
			answer: enterpriseReadyObj?.["technology-infrastructure-scale"],
		},
		{
			question: "Have you implemented system monitoring, alerting, and support protocols?",
			answer: enterpriseReadyObj?.["system-monitoring"],
		},
		{
			question: "Are there robust data analytics and reporting capabilities?",
			answer: enterpriseReadyObj?.["robust-data-analytics"],
		},
		{
			question: "Have you implemented a disaster recovery plan?",
			answer: enterpriseReadyObj?.["disaster-recovery-plan"],
		},
		{
			question: "Is there a plan for technological upgrades as the business grows?",
			answer: enterpriseReadyObj?.["technological-upgrades"],
		},
	];

	const HARDWARE = [
		{
			question: "Does your startup have a hardware product?",
			answer: enterpriseReadyObj?.["hardware-product"],
		},
		{
			question: "Does your startup deliver the hardware?",
			answer: enterpriseReadyObj?.["deliver-the-hardware"],
		},
		{
			question: "Does your startup maintain the hardware?",
			answer: enterpriseReadyObj?.["maintain-the-hardware"],
		},
		{
			question: "Is the hardware returned when the contract or service ends?",
			answer: enterpriseReadyObj?.["hardware-returned"],
		},
	];
	const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
		height: 7,
		borderRadius: 5,
		[`&.${linearProgressClasses.colorPrimary}`]: {
			backgroundColor: theme.palette.grey,
		},
		[`& .${linearProgressClasses.bar}`]: {
			borderRadius: 5,
			backgroundColor: theme.palette.secondary,
		},
	}));
	const LeadingIndicatorCard = ({ title, responses }) => {
		return (
			<Box sx={{ backgroundColor: "rgba(242, 244, 247, 1)", borderRadius: "20px", p: 2 }}>
				<Typography component={"p"} variant="Text/sm/Semibold" sx={{ mb: 2 }}>
					{title}
				</Typography>

				{responses?.length > 0 ? (
					responses?.map((item, idx) => {
						return (
							<Box display={"flex"} alignItems={"center"} gap={1} sx={{ mb: 1 }} justifyContent={"space-between"}>
								<Box>
									<Typography variant="Text/sm/Regular"> {idx + 1}. </Typography>
									<Typography variant="Text/sm/Regular">{item?.question}</Typography>
								</Box>
								<Typography
									sx={{
										p: "4px 10px 4px 10px",
										borderRadius: "100px",
										border: "1px solid #D0D5DD",
										color: item?.answer === "Yes" ? "#00CE7E" : "#F04438",
									}}
									variant="Text/xs/Regular"
								>
									{" "}
									{item?.answer || "No"}
								</Typography>
							</Box>
						);
					})
				) : (
					<Box>
						<Typography variant="Text/sm/Regular"> No Data</Typography>
					</Box>
				)}
			</Box>
		);
	};

	const handleCloseAreYouEnterpriseDialog = () => {
		setOpenAreYouEnterpriseDialog(false);
	};
	return (
		<CommonSlideDialog
			disableBackDropClick={false}
			title={"Are You Enterprise Ready?"}
			titleVariant={"Text/md/Semibold"}
			isOpen={openAreYouEnterpriseDialog}
			maxWidth={"xl"}
			onClose={handleCloseAreYouEnterpriseDialog}
			transitionDuration={600}
			PaperProps={{
				sx: () => ({
					p: 3,
					ml: "auto",
					alignSelf: "end",
					borderRadius: "30px",
				}),
			}}
			dialogAction={
				<Box display="flex" mt="12px" pr={2}>
					<Button variant="containedNewStyle" onClick={handleCloseAreYouEnterpriseDialog}>
						Close
					</Button>
				</Box>
			}
		>
			<Box display={"flex"} flexDirection={"row"} alignItems="center" gap={2}>
				<Typography variant="Text/sm/Regular">Score</Typography>
				<Box width="100%">
					<BorderLinearProgress variant="determinate" value={enterpriseReadyScore ? Number(enterpriseReadyScore) : 0} maxValue={100} />
				</Box>
				<Box>
					<Typography variant="Text/sm/Regular">{100 / enterpriseReadyScore ? Number(enterpriseReadyScore) : 0}%</Typography>
				</Box>
			</Box>
			<Box display={"flex"} flexDirection={"column"} gap={2} mt={2}>
				<LeadingIndicatorCard title={"Risk"} responses={RISK} />
				<LeadingIndicatorCard title={"Product/Service"} responses={PRODUCT_SERVICES} />
				<LeadingIndicatorCard title={"Business Operations"} responses={BUSINESS_OPERATIONS} />
				<LeadingIndicatorCard title={"Software/Platform"} responses={SOFTWARE_PLATOFORM} />
				<LeadingIndicatorCard title={"Hardware"} responses={HARDWARE} />
			</Box>
		</CommonSlideDialog>
	);
};
export default AreYouEnterpriseDialog;
