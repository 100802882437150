import { AddBoxSharp, ChevronRight } from "@mui/icons-material";
import { Box, Typography, Button } from "@mui/material";
import React, { Fragment } from "react";
import { useHistory } from "react-router-dom";
import { DEFAULT_STARTUP_LOGO } from "../../../../../constants";
import ROUTES_PATH from "../../../../../constants/routes";

export const StartupItem = ({ startupObj, type, ...props }) => {
	const history = useHistory();

	const handleClick = (id) => {
		if (type && type === "invites") {
			history.push(ROUTES_PATH.STARTUP_PROFILE.replace(":id", id));
		} else if (type && type === "connections") {
			history.push(ROUTES_PATH.STARTUP_PROFILE.replace(":id", id));
		}
	};

	return (
		<Box
			sx={{
				display: "flex",
				alignItems: "center",
				p: 2,
				gap: 2,
				cursor: type === "connections" && "pointer",
			}}
			onClick={() => {
				if (type === "connections" && handleClick) {
					handleClick(startupObj && startupObj.company && startupObj.company.id);
				}
			}}
		>
			<Box
				sx={(theme) => ({
					flex: "0 0 32px",
					height: "32px",
					width: "32px",
					backgroundColor: theme.palette.secondary.outline2,
					borderRadius: theme.shape.avatar.circle,
					backgroundImage: `url(${
						(type &&
							type === "invites" &&
							startupObj &&
							startupObj.company &&
							startupObj.company.founderDetails &&
							startupObj.company.founderDetails.picture) ||
						(type && type === "connections" && startupObj && startupObj.company && (startupObj.company.logoUrl || DEFAULT_STARTUP_LOGO))
					})`,
					backgroundRepeat: "no-repeat",
					backgroundPosition: "center",
					backgroundSize: "cover",
				})}
			></Box>
			<Box>
				{type && type === "invites" && startupObj && startupObj.company && startupObj.company.founderDetails && (
					<Box
						sx={{
							width: { xs: "100%", md: "120px", lg: "130px", xl: "150px" },
						}}
					>
						<Typography
							variant="body_large"
							sx={{
								display: "block",
								overflow: "hidden",
								textOverflow: "ellipsis",
								maxWidth: "100px",
								whiteSpace: "nowrap",
							}}
						>
							{startupObj.company.founderDetails.name}
						</Typography>
					</Box>
				)}
				{type && type === "connections" && startupObj && startupObj.company && startupObj.company.founderDetails && (
					<Box
						sx={{
							width: { sm: "100%", md: "220px", lg: "250px", xl: "280px" },
						}}
					>
						<Box>
							<Typography
								variant="body_large"
								sx={{
									display: "block",
									overflow: "hidden",
									textOverflow: "ellipsis",
									maxWidth: "90%",
									whiteSpace: "nowrap",
								}}
							>
								{startupObj && startupObj.company && startupObj.company.companyName}
							</Typography>
						</Box>
						<Box>
							<Typography
								variant="body_medium"
								sx={{
									display: "block",
									overflow: "hidden",
									textOverflow: "ellipsis",
									maxWidth: "100px",
									color: "text.onSurfaceVariant",
									whiteSpace: "nowrap",
								}}
							>
								{startupObj.company.founderDetails.name}
							</Typography>
						</Box>
					</Box>
				)}
			</Box>
			{type && type === "invites" && (
				<Box
					sx={{
						width: { xs: "100%", md: "0px" },
						display: { xs: "grid", md: "block" },
						justifyContent: "flex-end",
					}}
				>
					<Button
						variant="outlined"
						startIcon={<ChevronRight />}
						sx={{
							display: "flex",
							alignItems: "center",
							justifyContent: "space-between",

							p: 2,
							width: "max-content",
						}}
						onClick={() => {
							if (handleClick) {
								handleClick(startupObj && startupObj.company && startupObj.company.id);
							}
						}}
					>
						View
					</Button>
				</Box>
			)}
		</Box>
	);
};
