import createAuth0Client from "@auth0/auth0-spa-js";
import messages from "../constants/messages";
import services from "../services";
import { saveSocialSignupType } from "./sessionStorage";
import { toastContainer } from "./toast";
import { persistor, store } from "../modules";
import { setAuthData } from "../modules/actions";
import { login } from "../containers/Messaging/store/action-creators";
let auth0Client;

async function initClient() {
	try {
		if (!auth0Client) {
			auth0Client = await createAuth0Client({
				domain: process.env.REACT_APP_AUTH0_DOMAIN,
				client_id: process.env.REACT_APP_AUTH0_CLIENT,
				redirect_uri: process.env.REACT_APP_REDIRECTION_SUCCESS,
				useRefreshTokens: true,
				scope: "openid profile email offline_access",
				audience: process.env.REACT_APP_AUTH0_USER_INFO,
			});
		}

		return auth0Client;
	} catch (e) {
		console.error(e);
	}
}
initClient().then();

export async function authLogin(params) {
	const auth0 = await initClient();

	await auth0.loginWithRedirect(params);
}

export async function passwordlesslogin(params) {
	const auth0 = await initClient();

	await auth0.passwordlessStart(params);
}

export async function loginGoogle(params) {
	const auth0 = await initClient();
	saveSocialSignupType("google");
	await auth0.loginWithRedirect({
		connection: "google-oauth2",
		redirect_uri: `${process.env.REACT_APP_REDIRECTION_SUCCESS}?utm_source=${params?.utm_source}&utm_medium=${params?.utm_medium}&utm_campaign=${
			params?.utm_campaign
		}&partner=${params?.partner}&sso=${true}`,
	});
}

export async function loginLinkedIn(params) {
	const auth0 = await initClient();
	saveSocialSignupType("linkedin");

	await auth0.loginWithRedirect({
		connection: "linkedin",
		redirect_uri: `${process.env.REACT_APP_REDIRECTION_SUCCESS}?utm_source=${params?.utm_source}&utm_medium=${params?.utm_medium}&utm_campaign=${
			params?.utm_campaign
		}&partner=${params?.partner}&sso=${true}`,
	});
}

export async function getToken(params) {
	const auth0 = await initClient();
	let token;
	const pathName = window?.location?.pathname;
	const isPublicRoute = pathName?.includes("/public") || pathName?.includes("/lean-canvas");

	try {
		token = await auth0.getTokenSilently(params);
		return token;
	} catch (e) {
		if (e.message === "Login required") {
			//clear auth data when inactivity timeout as persitor.purge is not working
			store.dispatch(setAuthData(null));
			store.dispatch(login(""));

			/* User not able to see session expiry message when previous loggedIn session expired for public routes */
			if (!isPublicRoute) {
				toastContainer(messages.SESSION_LOGOUT, "error");
			}
		} else {
			console.log(e);
		}
	}
}

export async function getIdToken(params) {
	const auth0 = await initClient();
	let token;

	try {
		token = await auth0.getIdTokenClaims();
		return token.__raw;
	} catch (e) {
		console.log(e);
	}
}

export async function getUser(params) {
	const auth0 = await initClient();
	const user = await auth0.getUser();
	return user;
}

export async function getCompany(params) {
	const companyData = await services
		.getDashboardStats()
		.then((response) => {
			console.log("response.data.data:", response.data.data);
			return response.data.data;
		})
		.catch((e) => {
			console.log("Could not grab company information.", e);
			return false;
		});
	return companyData;
}

export async function logout(returnTo = process.env.REACT_APP_REDIRECTION_SIGNOUT_URL) {
	const auth0 = await initClient();
	await auth0.logout({
		returnTo: returnTo,
	});
}

export async function handleCallback() {
	const auth0 = await initClient();
	return await auth0.handleRedirectCallback();
}

export default {
	getToken,
	getIdToken,
	logout,
	authLogin,
	getUser,
	loginGoogle,
};
