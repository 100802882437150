import * as actionTypes from "./actionTypes";

export const setStartupProfileData = (payload) => {
	return (dispatch) => {
		dispatch({
			type: actionTypes.SET_STARTUP_PROFILE_DATA,
			payload,
		});
	};
};
