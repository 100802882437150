import React, { useState, useEffect } from "react";
import { Typography, Box, CircularProgress, Divider } from "@mui/material";
import theme from "../../../../constants/Theme";
import { toastContainer } from "../../../../utils/toast";
import messages from "../../../../constants/messages";
import { InviteTeamCard } from "../../../../components/MyStartup/InviteTeamCard";
import { getTeamMembersDetails } from "../functions";
import services from "../../../../services";

const EditTeam = props => {
  const [details, setDetails] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    handleGetDetails();
  }, []);

  const handleGetDetails = () => {
    getTeamMembersDetails(null).then(res => {
      if (res?.status === 200) {
        setDetails(res.data.data.models);
        setLoading(false);
      } else {
        toastContainer(messages.API_SUBMIT_ERROR, "error");
        setLoading(false);
      }
    });
  };

  const handleHideActionApiCall = payload => {
    services
      .updateCompanyUserHideStatusApiCall(payload)
      .then(response => {
        if (response.data.message === "Success") {
          handleGetDetails();
        }
      })
      .catch(e => {
        console.log("Submit", e);
      });
  };

  const handleEyeIconAction = (isHidden, id) => {
    const detailsList = [...details];
    detailsList.map(item => {
      if (item.id === id) {
        return (item.isHidden = isHidden);
      }
    });
    let payload = {
      flag: isHidden,
      userId: id,
    };
    handleHideActionApiCall(payload);
    setDetails(detailsList);
  };
  return (
    <Box sx={{ pl: { md: 1, lg: 1 }, pr: 2 }}>
      <Box sx={{ display: "flex", pb: 3, pt: 2 }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="title_large">Team</Typography>
        </Box>
      </Box>
      {loading ? (
        <Box
          sx={{ display: "flex", width: 1, justifyContent: "center", py: 4 }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          {details?.length > 0 ? (
            <>
              <InviteTeamCard
                details={details.filter(item => !item.isHidden)}
                loading={loading}
                setLoading={setLoading}
                isEdit={true}
                handleEyeIconAction={(isHidden, id) =>
                  handleEyeIconAction(isHidden, id)
                }
              />

              {details.filter(item => !item.isHidden).length ? <Divider /> : ""}

              <Box sx={{ mt: 2 }}>
                <InviteTeamCard
                  details={details.filter(item => item.isHidden === true)}
                  loading={loading}
                  setLoading={setLoading}
                  isEdit={true}
                  disabled={true}
                  handleEyeIconAction={(isHidden, id) =>
                    handleEyeIconAction(isHidden, id)
                  }
                />
              </Box>
            </>
          ) : (
            <Box
              sx={{
                display: "flex",
                width: 1,
                justifyContent: "center",
                alignContent: "center",
                py: 5,
              }}
            >
              <Typography
                color="primary"
                variant="h5"
                sx={{ color: theme.palette.secondary.dark }}
              >
                Invite your team to start collaborating.
              </Typography>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default EditTeam;
