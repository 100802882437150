import React from "react";
import { Box, Grid, Typography, Divider, Chip, Button, IconButton, MenuItem, useMediaQuery, Badge, Avatar, Tooltip } from "@mui/material";
import { useHistory } from "react-router-dom";
import { STARTUP_LOGO, NEW_PROFILE_LOGO } from "../../../constants/image";
import theme from "../../../constants/Theme";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import BreadcrumbsComp from "../../../containers/Workspace/PowerUP/IdeaValidation/Components/BreadcrumbsComp";
import ROUTES_PATH from "../../../constants/routes";
import { useDispatch, useSelector } from "react-redux";
import { USER_TYPES } from "../../../utils/userTypes";
import { ROADRUNNERIMG } from "../../../constants/image";
import BadgeIcon from "@mui/icons-material/Badge";
import SchoolIcon from "@mui/icons-material/School";
import StarIcon from "@mui/icons-material/Star";
import CoffeeIcon from "@mui/icons-material/Coffee";
import PsychologyIcon from "@mui/icons-material/Psychology";
import HandshakeIcon from "@mui/icons-material/Handshake";
import SwipeableEdgeDrawer from "../../../common/Drawer/index";
import { PottedPlantIcon, DiversityIcon } from "../../../common/icons";
import TurnedInNotIcon from "@mui/icons-material/TurnedInNot";
import { useLocation, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import services from "../../../services";
import { toastContainer } from "../../../utils/toast";
import ConnectRequestPopup from "../../../containers/Network/Components/Popups/ConnectPopup";
import SuccessPopup from "../../../containers/Network/Components/Popups/ConnectSuccessPopup";
import MessageHandlerModal from "../../../common/modal/MessageHandler";
import ButtonPopover from "../../../containers/MyStartup/MyProfile/Profile/components/ButtonPopover";
import CheckIcon from "@mui/icons-material/Check";
import { BookmarkBorder, ForwardToInbox } from "@mui/icons-material";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import { saveUnsaveStartup } from "../../../containers/Mentor/Network/StartupProfile/functions/functions";
import { GetValidatedUrl } from "../../../utils/getCorrectLink";
import { setCalUser } from "../../../modules/actions/meetingsAction";
import { getConversationWithID } from "../../../utils/messaging-helper";
import { updateCurrentConversation } from "../../../containers/Messaging/store/action-creators";
import UploadImageModal from "../../../common/UploadImageModal";
import ProfileProgress from "../../../common/ProfileProgress";
import { COVER_DEFAULT_IMAGE, PITCH_PERFECT_2_BANNER, CAMERA_ICON, PIPEDRIVEIMG } from "../../../constants/image";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { SmallAvatar } from "./styles";
import { handleMixPanel } from "../../../utils/mixPanelEventHandling";
import { getRoleValue } from "../../../utils/commonFunctions";
import { accountProfileProgress } from "../../../utils/profilePercentageCalculation";

export default function ProfileHeader({ avatar, setShowEdit, mentorData, compLocation, handleUserDetails, getUserDetails, handleUpdateAssetsUrl }) {
	const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
	const userData = useSelector((store) => store?.auth);
	const usersType = useSelector((store) => store?.auth.userType);
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();
	const params = useParams();
	const paramsId = params.id;
	const [isConnect, setIsConnect] = useState(true);
	const [isInvite, setIsInvite] = useState(false);
	const [openConnectRequest, setOpenConnectRequest] = useState(false);
	const [connectSuccessPopup, setConnectSuccessPopup] = useState(false);
	const [confirmPopup, setConfirmPopup] = useState(false);
	const [requestType, setRequestType] = useState("CONNECT");
	const [mentorDetails, setMentorDetails] = useState(mentorData);
	const [isAccepted, setIsAccepted] = useState(false);
	const [openDrawer, setOpenDrawer] = useState(false);
	const [linkedInLink, setLinkedInLink] = useState();
	const [popupText, setPopupText] = useState("");
	const [openBackgroundPhotoModal, setOpenBackgroundPhotoModal] = useState(false);
	const [openProfilePhotoModal, setOpenProfilePhotoModal] = useState(false);
	const [backgroundPhoto, setBackgroundPhoto] = useState(mentorData?.coverImgUrl || mentorData?.coverImgUrl);
	const [profilePhoto, setProfilePhoto] = useState(mentorData?.picture);
	const [profileCompletion, setProfileCompletion] = useState(Math.round(accountProfileProgress(mentorData).totalPercentage || 0));
	const [connectConfirmPopup, setConnectConfirmPopup] = useState(false);
	const [approvalStatus, setApprovalStatus] = useState();
	const contRef = React.useRef();

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		});
		if (paramsId) {
			getUserDetails();
		} else {
			handleUserDetails();
		}
	}, [paramsId]);

	useEffect(() => {
		setBackgroundPhoto(mentorData?.coverImgUrl || mentorData?.coverImgUrl);
		setProfilePhoto(mentorData?.picture);
		setMentorDetails(mentorData);
		setProfileCompletion(Math.round(accountProfileProgress(mentorData).totalPercentage || 0));
	}, [mentorData, paramsId]);

	useEffect(() => {
		if (mentorDetails?.linkedInUrl) {
			const link = GetValidatedUrl(mentorDetails?.linkedInUrl);
			setLinkedInLink(link);
		} else {
			return;
		}
	});

	useEffect(() => {
		if (!approvalStatus) {
			services.getApprovalStatus().then((status) => {
				setApprovalStatus(status);
			});
		} else {
			return;
		}
	}, [approvalStatus]);

	useEffect(() => {
		handleMixPanel("Account Profile Viewed", {
			userName: mentorDetails?.name,
			userId: mentorDetails?.id,
		});
	}, [mentorDetails]);

	const userType = mentorDetails?.userTypes[0];

	const verifyUser = () => {
		setApprovalStatus("PENDING");
		services
			.verifyUser({
				userRoles: mentorData?.userTypes,
			})
			.then((res) => {
				toastContainer("Your verification request was sent.", "success");
			})
			.catch((err) => {
				toastContainer("There was an erros sending your verification request.", "error");
				// console.log(err);
			});
	};

	// const handleConnectionAccept = isAccepted => {
	//   if (mentorDetails.connectionModel) {
	//     services
	//       .updateStartUpConnection({
	//         connectionId: mentorDetails.connectionModel.id,
	//         connectionStatus: isAccepted ? "ACCEPTED" : "REJECTED",
	//       })
	//       .then(res => {
	//         if (res.data.code == 200) {
	//           setIsInvite(false);
	//           if (isAccepted) {
	//             setIsAccepted(true);
	//           } else {
	//             setIsAccepted(false);
	//             setIsConnect(true);
	//           }
	//         }
	//       })
	//       .catch(err => console.log(err));
	//   }
	// };

	const handleConnect = () => {
		setRequestType("CONNECT");
		//setOpenConnectRequest(true);
		setConnectConfirmPopup(true);
		setPopupText("Are you sure you want to send the connection request?");
	};

	const handleRequestCancelConfirm = () => {
		setConfirmPopup(false);
		handleConnectionStatusUpdate({
			connectionId: mentorDetails?.connectionModel?.id ? mentorDetails?.connectionModel?.id : "",
			connectionStatus: requestType,
		});
	};

	const handleConnectRequestConfirm = () => {
		setConnectConfirmPopup(false);
		handleSend({
			userId: mentorDetails?.id ? mentorDetails?.id : "",
			message: "",
		});
		handleMixPanel("Network Connection Requested", {
			"User ID": mentorDetails?.id,
		});
	};

	const handleSaveClick = () => {
		const data = {
			userId: mentorDetails?.id,
			status: !mentorDetails?.isSaved,
			type: "SAVED",
		};
		saveUnsaveStartup(data)
			.then((result) => {
				if (result) {
					setMentorDetails({
						...mentorDetails,
						isSaved: !mentorDetails?.isSaved,
					});
				}
			})
			.catch((err) => {
				// console.log(err);
			});
	};
	const renderExpertiseType = (data) => {
		if (data && data.expertise) {
			try {
				return JSON.parse(data?.expertise).join(", ");
			} catch (error) {
				return data.expertise;
			}
		} else {
			return "";
		}
	};
	const SaveButton = () => {
		return (
			<Button
				variant="nofill"
				color="primary"
				startIcon={mentorDetails.isSaved ? <BookmarkIcon color="primary" /> : <BookmarkBorder color="primary" />}
				sx={{ width: "max-content", m: "inherit", pl: { xs: 0, md: 3 } }}
				onClick={handleSaveClick}
			>
				{mentorDetails.isSaved ? "Saved" : "Save"}
			</Button>
		);
	};

	const handleCancel = () => {
		setRequestType("CANCELED");
		setConfirmPopup(true);
		setPopupText("Are you sure you want to cancel connection request?");
	};

	const handleDelete = () => {
		setRequestType("DELETED");
		setConfirmPopup(true);
		setPopupText("Are you sure you want to delete connection?");
	};

	const handleConnectionStatusUpdate = (data) => {
		services
			.updateStartUpConnection(data)
			.then((result) => {
				if (data.connectionStatus === "ACCEPTED") {
					handleMixPanel("Network Connection Accepted", {
						"User ID": mentorDetails?.id,
					});
				} else if (data.connectionStatus === "REJECTED") {
					handleMixPanel("Network Connection Rejected", {
						"User ID": mentorDetails?.id,
					});
				}
				const response = result.data;
				if (response?.code === 200) {
					setOpenConnectRequest(false);

					setMentorDetails({
						...mentorDetails,
						connectionModel: response.data,
					});
					if (data.connectionStatus !== "ACCEPTED" || data.connectionStatus !== "REJECTED") {
						//setConnectSuccessPopup(true);
					}
				}
			})
			.catch((error) => {
				const message = error.response?.data?.message;
				toastContainer(message, "error");
				// console.log(error);
			});
	};

	const handleSend = (data) => {
		services
			.handleStartUpConnection(data)
			.then((result) => {
				const response = result.data;
				handleMixPanel(`Connection request by ${getRoleValue(usersType)}`);
				if (response?.code === 200) {
					setOpenConnectRequest(false);
					setMentorDetails({
						...mentorDetails,
						connectionModel: response.data,
					});
					setConnectSuccessPopup(true);
				}
			})
			.catch((error) => {
				const message = error.response?.data?.message;
				toastContainer(message, "error");
				// console.log(error);
			});
	};

	const getChipData = (type) => {
		if (userType === USER_TYPES.MENTOR_USER && mentorDetails?.isVerified) {
			return type === "label" ? "Verified Mentor" : type === "icon" && <Box component={"img"} width={"30px"} src={ROADRUNNERIMG}></Box>;
		} else if (userType === USER_TYPES.MENTOR_USER) {
			return type === "label" ? "Mentor" : type === "color" ? "success.main" : <CoffeeIcon fontSize="small" />;
		} else if (userType === USER_TYPES.ADVISOR_USER) {
			return type === "label" ? "Advisor" : type === "color" ? "text.purple" : <PsychologyIcon fontSize="small" />;
		} else if (userType === USER_TYPES.INVESTOR_USER) {
			return type === "label" ? "Investor" : type === "icon" && <PottedPlantIcon />;
		} else if (userType === USER_TYPES.PARTNER_USER) {
			return type === "label" ? "Accelerator" : type === "color" ? "warning.main" : <HandshakeIcon fontSize="small" />;
		} else if (userType === USER_TYPES.PROFESSOR_USER) {
			return type === "label" ? "Instructor" : type === "color" ? "text.pinkDark" : <BadgeIcon fontSize="small" />;
		} else if (userType === USER_TYPES.STUDENT_USER) {
			return type === "label" ? "Student" : type === "color" ? "success.light" : <SchoolIcon fontSize="small" />;
		} else if (userType === USER_TYPES.STARTUP_USER) {
			return type === "label" ? "Teammate" : type === "icon" && <DiversityIcon />;
		} else if (userType === USER_TYPES.CANDIDATE_USER) {
			return type === "label" ? "Candidate" : type === "icon" && <DiversityIcon />;
		} else {
			return type === "label" ? "Founder" : type === "color" ? "text.darkBlue" : <StarIcon fontSize="small" />;
		}
	};

	const handleMessaging = async () => {
		try {
			let conversationID = await getConversationWithID({
				idArray: [mentorDetails?.id],
			});
			dispatch(updateCurrentConversation(conversationID));
			history.push("/messages");
		} catch (e) {
			console.log("Could not retrieve conversation.", e);
		}
	};

	const handleReqMeeting = () => {
		dispatch(
			setCalUser({
				id: mentorDetails.calUserId,
				name: mentorDetails?.name,
				email: mentorDetails?.email,
				picture: mentorDetails?.picture,
			})
		);

		history.push(ROUTES_PATH.MEETING_TYPES_SCHEDULE);
	};

	return (
		<>
			<Grid
				container
				sx={{
					display: "flex",
					flexDirection: "column",
					gap: 1,
				}}
			>
				<SuccessPopup
					connectSuccessPopup={connectSuccessPopup}
					setConnectSuccessPopup={setConnectSuccessPopup}
					userName={mentorDetails?.name}
					type={requestType}
				/>

				<MessageHandlerModal
					isOpen={confirmPopup}
					onCancel={() => setConfirmPopup(false)}
					onOk={() => handleRequestCancelConfirm()}
					okText="Ok"
					cancelText={"Cancel"}
					heading="Confirm"
					text={popupText}
					messageType="primary"
					handleUpdateAssetsUrl={handleUpdateAssetsUrl}
				/>

				<MessageHandlerModal
					isOpen={connectConfirmPopup}
					onCancel={() => setConnectConfirmPopup(false)}
					onOk={() => handleConnectRequestConfirm()}
					okText="Ok"
					cancelText={"Cancel"}
					heading="Confirm"
					text={popupText}
					messageType="primary"
					handleUpdateAssetsUrl={handleUpdateAssetsUrl}
				/>

				<UploadImageModal
					open={openBackgroundPhotoModal}
					onClose={() => setOpenBackgroundPhotoModal(false)}
					photo={backgroundPhoto}
					setPhoto={setBackgroundPhoto}
					title="Background Photo"
					selectFileText="Select a file to upload background image"
					dimensionText="Recommended dimensions 1400px x 350px (Max 2GB)"
					aspectRatio={4 / 1}
					imageType="BACKGROUND_PHOTO"
					fileType={{ "image/png": [], "image/jpg": [], "image/jpeg": [] }}
					circularCropper={false}
					handleUpdateAssetsUrl={handleUpdateAssetsUrl}
					mentorData={mentorData}
				/>
				<UploadImageModal
					open={openProfilePhotoModal}
					photo={profilePhoto}
					onClose={() => setOpenProfilePhotoModal(false)}
					setPhoto={setProfilePhoto}
					title="Profile Photo"
					selectFileText="Select a file to upload profile image"
					dimensionText="Recommended dimensions 240px x 240px (Max 2GB)"
					aspectRatio={1 / 1}
					imageType="PROFILE_PHOTO"
					fileType={{ "image/png": [], "image/jpg": [], "image/jpeg": [] }}
					circularCropper={true}
					handleUpdateAssetsUrl={handleUpdateAssetsUrl}
				/>

				{!paramsId && (
					<Grid item xs={12} sx={{ mt: 2 }}>
						<ProfileProgress percentage={Math.round(profileCompletion)} />
					</Grid>
				)}

				<Box
					sx={{
						my: 3,
						width: 1,
					}}
				>
					<Box
						sx={{
							flex: 1,
							position: "relative",
						}}
					>
						<img
							style={{
								width: "100%",
								borderRadius: "8px",
							}}
							src={backgroundPhoto ? backgroundPhoto : COVER_DEFAULT_IMAGE}
						/>
						{!isSmallScreen ? (
							<Box
								sx={{
									width: "max-content",
									mr: { xs: 1, md: 3 },
									mb: 3,
									background:
										"linear-gradient(0deg, rgba(106, 117, 117, 0.02), rgba(106, 117, 117, 0.02)), linear-gradient(0deg, rgba(245, 248, 250, 0.05), rgba(245, 248, 250, 0.05)), #FCFCFC",
									boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
									borderRadius: "4px",
									position: "absolute",
									bottom: "10px",
									right: 0,
								}}
							>
								{!paramsId && (
									<Button
										variant="outlined"
										startIcon={<PhotoCameraIcon />}
										onClick={() => {
											setOpenBackgroundPhotoModal(true);
										}}
									>
										Edit cover image
									</Button>
								)}
							</Box>
						) : (
							<Box
								sx={{
									width: "max-content",
									mr: { xs: 1, md: 3 },
									mb: 1,
									position: "absolute",
									bottom: "10px",
									right: 0,
								}}
							>
								{!paramsId && (
									<PhotoCameraIcon
										sx={(theme) => ({
											color: theme.palette.primary.main,
											background: "#fff",
											p: 1,
											borderRadius: "100px",
										})}
										onClick={() => {
											setOpenBackgroundPhotoModal(true);
										}}
									/>
								)}
							</Box>
						)}
					</Box>
					<Box
						sx={{
							pl: { xs: 2, md: 4 },
							mt: "-60px", // Profile circle overlap
						}}
					>
						<Badge
							overlap="circular"
							anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
							badgeContent={
								<SmallAvatar
									onClick={() => setOpenProfilePhotoModal(true)}
									src={CAMERA_ICON}
									sx={{
										height: "40px",
										width: "40px",
										border: "none",
										cursor: "pointer",
										display: !paramsId ? "block" : "none",
									}}
								/>
							}
						>
							<Avatar
								alt="profile_picture"
								src={profilePhoto ? profilePhoto : NEW_PROFILE_LOGO}
								sx={{
									height: "120px",
									width: "120px",
									border: !profilePhoto ? "none" : `3px solid ${theme.palette.background.paper}`,
									borderRadius: "100px",
								}}
							/>
						</Badge>
					</Box>
				</Box>
				{/* 
          Head text
        */}
				<Grid item xs={12} md={12} sx={{ px: { md: 4 } }}>
					<Typography variant="title_xlarge" color="secondary.korophKoal">
						{mentorData?.firstName && mentorData?.lastName
							? `${mentorData?.firstName} ${mentorData?.lastName}`
							: mentorData?.name
							? mentorData?.name
							: "Name"}
					</Typography>
				</Grid>
				<Grid item xs={12} md={12} sx={{ px: { md: 4 } }}>
					<Typography variant="subhead1" color="secondary.atomic" sx={(theme) => ({ color: theme.palette.text.lightOnsurface })}>
						{mentorData?.headline ? mentorData?.headline : "Headline"}
					</Typography>
				</Grid>
				<Grid item xs={12} md={12} sx={{ px: { md: 4 } }}>
					<Typography variant="label_large" color="text.lightOnsurface">
						{mentorData?.profession ? mentorData?.profession : "Position"}
					</Typography>
				</Grid>
				<Grid
					item
					xs={12}
					md={12}
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
						flexDirection: "row",
						pl: { md: 4 },
					}}
				>
					{/*<Typography
            variant="onsurface_subtext"
            sx={{ color: theme.palette.secondary.dark }}
          >
            {mentorData?.profession
              ? mentorData?.profession
              : "Title"}

            {renderExpertiseType(
              mentorData?
            ) && (
              <FiberManualRecordIcon
                fontSize="small"
                sx={{
                  maxHeight: "8px",
                  fill: theme.palette.secondary.dark,
                  alignSelf: "center",
                }}
              />
            )}
          </Typography>*/}

					<Box>
						{/* COMPANY NAME */}
						<Typography variant="label_large" sx={{ color: theme.palette.secondary.subTitle }}>
							{mentorDetails?.companyModel?.companyName ? mentorDetails?.companyModel?.companyName : "Startup Name"}
							{/*{renderExpertiseType(mentorData)}*/}

							{/*{mentorDetails?.companyModel?.companyName ? (
                <FiberManualRecordIcon
                  fontSize="small"
                  sx={{
                    maxHeight: "8px",
                    fill: theme.palette.secondary.dark,
                    alignSelf: "center",
                  }}
                />
              ) : (
                ""
              )}*/}
							{mentorData?.state || mentorData?.city || mentorData?.zipcode || mentorData?.zipcode ? (
								<FiberManualRecordIcon
									fontSize="small"
									sx={{
										maxHeight: "8px",
										fill: theme.palette.secondary.dark,
										alignSelf: "center",
									}}
								/>
							) : (
								" "
							)}
						</Typography>

						{/* COMPANY LOCATION */}
						{mentorData?.state || mentorData?.city || mentorData?.zipcode ? (
							<Typography variant="label_large" color="text.lightOnsurface">
								{mentorData?.state && mentorData?.city
									? `${mentorData?.city}, ${mentorData?.state}, ${mentorData?.zipcode}`
									: mentorData?.state && !mentorData?.city
									? `${mentorData?.state}, ${mentorData?.zipcode}`
									: mentorData?.city && !mentorData?.state
									? `${mentorData?.city}, ${mentorData?.zipcode}`
									: `${mentorData?.zipcode}`}
							</Typography>
						) : (
							" "
						)}
					</Box>

					{/* MENTOR APPLICATION BUTTON */}

					{(approvalStatus === "PENDING" || approvalStatus === "RESTRICTED") && (
						<Tooltip title="Apply for verified status when profile is at 100% complete" placement="top">
							<Box
								sx={{
									ml: "auto",
									pr: { md: "47.5px" },
									display: "flex",
									// maxWidth: "385px"
								}}
							>
								<Typography variant="label_large" color="text.lightOnsurface" sx={{ padding: 2, maxWidth: "360px", textAlign: "center" }}>
									You need to be verified witIhin our system to mentor our startup teams
								</Typography>

								<Button
									sx={{ maxWidth: "185px" }}
									disabled={approvalStatus === "PENDING" || profileCompletion < 100}
									variant="contained"
									onClick={() => {
										verifyUser(mentorData);
									}}
								>
									{approvalStatus === "PENDING" ? "Pending Approval" : "Apply"}
								</Button>
							</Box>
						</Tooltip>
					)}
				</Grid>

				<Box
					sx={{
						display: "flex",
						alignItem: "end",
						mt: 2,
						pb: 2,
						px: { md: 4 },
					}}
				>
					<Chip
						icon={getChipData("icon")}
						sx={(theme) => ({
							boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
							borderRadius: theme.shape.standard_small.borderRadius,
							"& .MuiChip-icon": {
								color: getChipData("color"),
							},
							pl: 1,
						})}
						label={
							<Typography variant="label_large" color="text.korophKoal">
								{getChipData("label")}
							</Typography>
						}
						variant="outlined"
					/>
					{!paramsId && (
						<Box sx={{ ml: "auto", pr: { md: 2 } }}>
							{/*<ModeEditOutlineOutlinedIcon
                sx={{
                  cursor: "pointer",
                  fill: theme.palette.secondary.main,
                }}
                onClick={() => setShowEdit(true)}
              />*/}
							<Button variant="contained" startIcon={<ModeEditOutlineOutlinedIcon />} onClick={() => setShowEdit(true)}>
								Edit profile
							</Button>
						</Box>
					)}
				</Box>

				{paramsId && !isSmallScreen && (
					<Box sx={{ width: 1 }}>
						<Box sx={{ display: "flex", pt: 1, px: { md: 4 } }}>
							<Box sx={{ display: "flex" }}>
								{mentorDetails?.connectionModel === null ? (
									<Box sx={{ cursor: "pointer", mr: 1 }}>
										<Button
											variant="contained"
											onClick={() => {
												handleConnect();
											}}
										>
											Connect
										</Button>
									</Box>
								) : mentorDetails?.connectionModel?.connectionStatus === "PENDING" ? (
									<>
										{["STARTUP_USER", "STARTUP_OWNER", "STUDENT_USER"].includes(usersType) &&
										!mentorDetails?.connectionModel?.isCreatedByStartup ? (
											<Box sx={{ display: "flex" }}>
												<Box sx={{ cursor: "pointer", pr: 1 }}>
													<Button
														variant="contained"
														onClick={() => {
															handleConnectionStatusUpdate({
																connectionId: mentorDetails?.connectionModel?.id,
																connectionStatus: "ACCEPTED",
															});
														}}
													>
														Accept
													</Button>
												</Box>
												<Box sx={{ cursor: "pointer", pr: 1 }}>
													<Button
														variant="outlined"
														onClick={() => {
															handleConnectionStatusUpdate({
																connectionId: mentorDetails?.connectionModel?.id,
																connectionStatus: "REJECTED",
															});
														}}
													>
														Ignore
													</Button>
												</Box>
											</Box>
										) : (
											["STARTUP_USER", "STARTUP_OWNER", "STUDENT_USER"].includes(usersType) &&
											mentorDetails?.connectionModel?.isCreatedByStartup && (
												<Button variant="outlined" onClick={() => handleCancel()}>
													Cancel Request
												</Button>
											)
										)}
										{["MENTOR_USER", "PROFESSOR_USER"].includes(usersType) && mentorDetails?.connectionModel?.isCreatedByStartup ? (
											<Box sx={{ display: "flex" }}>
												<Box sx={{ cursor: "pointer", pr: 1 }}>
													<Button
														variant="contained"
														onClick={() => {
															handleConnectionStatusUpdate({
																connectionId: mentorDetails?.connectionModel?.id,
																connectionStatus: "ACCEPTED",
															});
														}}
													>
														Accept
													</Button>
												</Box>
												<Box sx={{ cursor: "pointer", pr: 1 }}>
													<Button
														variant="outlined"
														onClick={() => {
															handleConnectionStatusUpdate({
																connectionId: mentorDetails?.connectionModel?.id,
																connectionStatus: "REJECTED",
															});
														}}
													>
														Ignore
													</Button>
												</Box>
											</Box>
										) : (
											["MENTOR_USER", "PROFESSOR_USER"].includes(usersType) &&
											!mentorDetails?.connectionModel?.isCreatedByStartup && (
												<Button variant="outlined" onClick={() => handleCancel()}>
													Cancel Request
												</Button>
											)
										)}
									</>
								) : mentorDetails?.connectionModel?.connectionStatus === "ACCEPTED" ? (
									<>
										<ButtonPopover
											text={"Connected"}
											popupText={"Remove Connection"}
											onAction={() => handleDelete()}
											prefixIcon={<CheckIcon />}
										/>
									</>
								) : (
									(mentorDetails?.userTypes[0] === "MENTOR_USER" || mentorDetails?.userTypes[0] === "PROFESSOR_USER") && (
										<Box sx={{ cursor: "pointer" }}>
											<Button
												variant="contained"
												onClick={() => {
													//setIsInvite(true);
													handleConnect();
												}}
											>
												Connect
											</Button>
										</Box>
									)
								)}
								{mentorDetails?.linkedInUrl && mentorDetails?.linkedInUrl !== null && (
									<Button variant="outlined" sx={{ whiteSpace: "nowrap", ml: 2 }} href={mentorDetails?.linkedInUrl} target="_blank">
										View LinkedIn profile
									</Button>
								)}

								{mentorDetails?.connectionModel?.connectionStatus === "ACCEPTED" && (
									<Box>
										<Button variant="nofill" color="primary" onClick={handleReqMeeting} sx={{ ml: 3, width: "max-content" }}>
											Request Meeting
										</Button>
									</Box>
								)}
								{mentorDetails?.connectionModel?.connectionStatus === "ACCEPTED" && (
									<Box>
										<Button variant="nofill" sx={{ display: "flex", gap: 1, width: "max-content" }} onClick={() => handleMessaging()}>
											Send a Message
										</Button>
									</Box>
								)}
							</Box>
							<Box sx={{ cursor: "pointer", ml: "auto" }}>{!isSmallScreen && <SaveButton />}</Box>
						</Box>
					</Box>
				)}
				{paramsId && isSmallScreen && (
					<Box
						sx={{
							width: 1,
							pt: 2,
							px: { md: 2 },
						}}
					>
						<Grid container flexDirection={"row"}>
							<Grid item xs={10}>
								{mentorDetails?.connectionModel === null ? (
									<Box sx={{ cursor: "pointer", mr: 1, width: 1 }}>
										<Button
											variant="contained"
											onClick={() => {
												handleConnect();
											}}
										>
											Connect
										</Button>
									</Box>
								) : !mentorDetails?.connectionModel?.connectionStatus === "PENDING" ? (
									<>
										{["STARTUP_USER", "STARTUP_OWNER"].includes(usersType) && !mentorDetails?.connectionModel?.isCreatedByStartup ? (
											<Box sx={{ display: "flex" }}>
												<Box sx={{ cursor: "pointer", pr: 1 }}>
													<Button
														variant="contained"
														onClick={() => {
															handleConnectionStatusUpdate({
																connectionId: mentorDetails?.connectionModel?.id,
																connectionStatus: "ACCEPTED",
															});
														}}
													>
														Accept
													</Button>
												</Box>
												<Box sx={{ cursor: "pointer", pr: 1 }}>
													<Button
														variant="outlined"
														onClick={() => {
															handleConnectionStatusUpdate({
																connectionId: mentorDetails?.connectionModel?.id,
																connectionStatus: "REJECTED",
															});
														}}
													>
														Ignore
													</Button>
												</Box>
											</Box>
										) : (
											["STARTUP_USER", "STARTUP_OWNER"].includes(usersType) &&
											mentorDetails?.connectionModel?.isCreatedByStartup && (
												<Button variant="outlined" onClick={() => handleCancel()}>
													Cancel Request
												</Button>
											)
										)}

										{["MENTOR_USER", "PROFESSOR_USER"].includes(usersType) && mentorDetails?.connectionModel?.isCreatedByStartup ? (
											<Box sx={{ display: "flex" }}>
												<Box sx={{ cursor: "pointer", pr: 1 }}>
													<Button
														variant="contained"
														onClick={() => {
															handleConnectionStatusUpdate({
																connectionId: mentorDetails?.connectionModel?.id,
																connectionStatus: "ACCEPTED",
															});
														}}
													>
														Accept
													</Button>
												</Box>
												<Box sx={{ cursor: "pointer", pr: 1 }}>
													<Button
														variant="outlined"
														onClick={() => {
															handleConnectionStatusUpdate({
																connectionId: mentorDetails?.connectionModel?.id,
																connectionStatus: "REJECTED",
															});
														}}
													>
														Ignore
													</Button>
												</Box>
											</Box>
										) : (
											["MENTOR_USER", "PROFESSOR_USER"].includes(usersType) &&
											!mentorDetails?.connectionModel?.isCreatedByStartup && (
												<Button variant="outlined" onClick={() => handleCancel()}>
													Cancel Request
												</Button>
											)
										)}
									</>
								) : mentorDetails?.connectionModel?.connectionStatus === "ACCEPTED" ? (
									<>
										<ButtonPopover
											text={"Connected"}
											popupText={"Remove Connection"}
											onAction={() => handleDelete()}
											prefixIcon={<CheckIcon />}
										/>
									</>
								) : (
									(mentorDetails?.userTypes[0] === "MENTOR_USER" || mentorDetails?.userTypes[0] === "PROFESSOR_USER") && (
										<Box sx={{ cursor: "pointer" }}>
											<Button
												variant="contained"
												onClick={() => {
													handleConnect();
												}}
											>
												Connect
											</Button>
										</Box>
									)
								)}
							</Grid>
							<Grid item xs={2}>
								<Box sx={{ display: "flex", justifyContent: "end" }}>
									<IconButton width={32} height={32} sx={{ border: "1px solid #6A7575" }}>
										<MoreHorizIcon sx={{ color: theme.palette.text.primary }} onClick={() => setOpenDrawer(true)} />
									</IconButton>
								</Box>
								<SwipeableEdgeDrawer
									open={openDrawer}
									onClose={() => {
										setOpenDrawer(false);
									}}
									container={contRef.current}
								>
									<Box sx={{ py: 4, px: 2 }}>
										{mentorDetails?.linkedInUrl !== null && (
											<MenuItem sx={{ pr: 13 }}>
												<Typography
													variant="bodyLarge"
													sx={{ whiteSpace: "nowrap" }}
													color="text.darkBlue"
													onClick={() => {
														setOpenDrawer(false);
														window.open(linkedInLink, "_blank");
													}}
												>
													View LinkedIn Profile
												</Typography>
											</MenuItem>
										)}
										{mentorDetails?.connectionModel?.connectionStatus === "ACCEPTED" && (
											<MenuItem sx={{ pr: 13 }}>
												<Typography
													variant="bodyLarge"
													sx={{ whiteSpace: "nowrap" }}
													color="text.darkBlue"
													onClick={() => {
														handleReqMeeting();
													}}
												>
													Request Meeting
												</Typography>
											</MenuItem>
										)}
										{mentorDetails?.connectionModel?.connectionStatus === "ACCEPTED" && (
											<MenuItem sx={{ pr: 13 }}>
												<Typography
													variant="bodyLarge"
													sx={{ whiteSpace: "nowrap" }}
													color="text.darkBlue"
													onClick={() => {
														handleMessaging();
													}}
												>
													Send Message
												</Typography>
											</MenuItem>
										)}
										<MenuItem sx={{ pr: 13 }}>
											<SaveButton />
										</MenuItem>
									</Box>
								</SwipeableEdgeDrawer>
							</Grid>
						</Grid>
					</Box>
				)}
				<Box sx={{ pl: { md: 4 }, pr: { md: 6 } }}>
					<Divider sx={{ py: 1 }} />
				</Box>
			</Grid>
		</>
	);
}
