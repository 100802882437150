import React from "react";
import { Box, Divider, Typography, Chip } from "@mui/material";
import { useSelector } from "react-redux";

import { LOGOICON } from "../../../../constants";
import {
  convertFormattedStringToDate,
  getCharDay,
  getCurrentDate,
  getCurrentTime,
  getLocalTimeZone,
} from "../../../../utils/date";
import theme from "../../../../constants/Theme";
import JsxParser from "react-jsx-parser";

export default function PreviewInviteCandidate({
  selectedDateTimes,
  duration,
  descriptionContent,
}) {
  const userData = useSelector(state => state.auth);

  const getEndTime = startTime => {
    let date = new Date();
    date.setHours(parseInt(startTime.split(":")[0]));
    date.setMinutes(parseInt(startTime.split(":")[1]));
    date.setMinutes(date.getMinutes() + duration);

    return `${date.getHours()} : ${date.getMinutes()}`;
  };
  return (
    <Box>
      <Typography variant="headline6">Preview Email</Typography>
      <Typography variant="bodyLarge" sx={{ mt: 2, display: "grid" }}>
        Take a look at the email you’re sending and make sure everything’s
        correct.
      </Typography>
      <Divider sx={{ mt: 3 }} />

      <Box component={"img"} sx={{ mt: 3 }} src={LOGOICON} />
      <Typography variant="headline2" sx={{ mt: 5, display: "grid" }}>
        {userData.companyName} wants to interview you
      </Typography>
      <Typography variant="bodyLarge" sx={{ mt: 2, display: "grid" }}>
        <JsxParser components={{ Typography }} jsx={descriptionContent} />
      </Typography>
      <Typography variant="title_large" sx={{ mt: 5, display: "grid" }}>
        Select a time slot or propose a new time
      </Typography>
      <Typography variant="bodyLarge" sx={{ mt: 5, display: "grid" }}>
        Please reply to this email to confirm the time or propose a new time.
      </Typography>
      <Box sx={{ mt: 3 }}>
        {selectedDateTimes.map(item => (
          <Box sx={{ mt: 2 }}>
            <Typography variant="title_medium">
              {getCharDay(convertFormattedStringToDate(item.date))}{" "}
              {getCurrentDate(convertFormattedStringToDate(item.date))} -{" "}
              {getLocalTimeZone()}
            </Typography>
            <br />
            <Box sx={{ mt: 1 }}>
              {item.times.map(time => {
                return (
                  <Chip
                    label={
                      <Typography variant="label_large">
                        {getCurrentTime(time)} -{" "}
                        {getCurrentTime(getEndTime(time))}
                      </Typography>
                    }
                    variant="outlined"
                    sx={theme => ({
                      borderRadius: theme.shape.standard.borderRadius,
                      border: `1px solid ${theme.palette.secondary.dark}`,
                      my: 1,
                      mr: 1,
                    })}
                  />
                );
              })}
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
}
