import { Badge, Button, IconButton, Popover, Typography } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import React, { Fragment, useEffect, useRef, useState } from "react";
import NotificationPanel from "./NotificationPanel";
import { useSelector } from "react-redux";
import "./styles.css";
import { hasTimePassed } from "../../utils/epochTime";
import { DeleteAllNotifications, DeleteNotifications } from "../../utils/notificationControl";
import { USER_TYPES } from "../../utils/userTypes";
import { NOTIFICATION_BELL, NOTIFICATION_ICON_NEW, WHITE_NOTIFICATION_ICON } from "../../constants";
import { Box } from "@mui/system";
import { handleMixPanel } from "../../utils/mixPanelEventHandling";
import { useLocation } from "react-router-dom";
import NavButton from "../Workspace/UpdatedHeader/NavButton";
import FullNotificationList from "./FullNotificationList";
import CommonSlideDialog from "../../common/CommonSlideDialog";


function NotificationPanelButton() {
	const notificationList = useSelector((state) => state?.notifications?.notificationList);
	const notificationData = useSelector((state) => state?.notifications);
	const lastNotification = useSelector((state) => state?.notifications?.lastNotification);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [open, setOpen] = React.useState(false);
	const [unread, setUnread] = useState(0);
	const bellRef = useRef();
	const userType = useSelector((store) => store?.auth?.userType);
	const location = useLocation();

	useEffect(() => {
		if (lastNotification && notificationList) {
			const containsValue = notificationList?.some((obj) => obj.read === false);
			if (containsValue) {
				addClass();
				setTimeout(() => {
					removeClass();
				}, 1000);
			}
		}
	}, [lastNotification]);

	const getUnreadCount = () => {
		let count = notificationList?.reduce((acc, obj) => {
			// Always show system-messages as unread.
			if (obj?.type === "system-message") {
				// This is a glass-break attempt to bust cache of expired notification objects.
				try {
					if (hasTimePassed(obj?.expireAt)) {
						DeleteNotifications([obj?.NOTIFICATION_ID]);
					} else {
						acc++;
					}
				} catch (e) {
					console.log("Error when attempting to check the expireAt string of system notifications.", e);
				}
			} else if (obj?.read === false && obj?.type !== "system-message") {
				acc++;
			}
			return acc;
		}, 0);
		return count ? count : 0;
	};

	useEffect(() => {
		const count = getUnreadCount();
		setUnread(count);
	}, [notificationData]);

	const addClass = () => {
		bellRef.current?.classList.add("jingle-enter-active");
	};
	const removeClass = () => {
		bellRef.current?.classList.remove("jingle-enter-active");
	};

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);

		handleMixPanel("Notifications Viewed", {
			"Notification Bell Count": unread,
		});
	};

	const handleClose = () => {
		setOpen(false);
	};

	useEffect(() => {
		setOpen(Boolean(anchorEl));
	}, [anchorEl]);

	useEffect(() => {
		if (!open) {
			setAnchorEl(null);
		}
	}, [open]);

	return (
		<Fragment>
			<Box sx={{ textAlign: "center", cursor: "pointer" }} onClick={handleClick}>
				<Badge
					color="primary"
					overlap="circular"
					badgeContent={unread <= 9 ? unread : "9+"}
					anchorOrigin={{
						vertical: "top",
						horizontal: "right",
					}}
				>
					<NavButton active={false} image={<Box component={"img"} src={NOTIFICATION_ICON_NEW} alt="notifications" />} label={"Notifications"} />
				</Badge>
			</Box>

			<CommonSlideDialog
			open={open}
			disableBackDropClick
			onClose={handleClose}
			title={
				<Box sx={{ display: "flex", flexDirection: "column", rowGap: 1 }}>
					<Box component={"img"} src={NOTIFICATION_BELL} sx={{ width: "32px", height: "32px" }} />
					<Typography variant="Text/md/Semibold">Notifications</Typography>
				</Box>
			}
			maxWidth="md"
			titleVariant="poppinsSemiBold30"
			titleStyle={{ px: { xs: 2, md: 5 }, pt: { xs: 2, md: 5 }, pb: 3, alignItems: "baseline" }}
			contentStyle={{ px: { xs: 2, md: 5 }, pb: 1, flex: 1, display: "flex" }}
			fullWidth
			PaperProps={{
				sx: {
					position: "fixed",
					bottom: 0,
					right: 0,
					top: 0,
					m: 0,
					borderRadius: "24px 0px 0px 24px",
					boxShadow: "0px 10px 25px 0px rgba(0, 0, 0, 0.05)",
					minHeight: 1,
					width: "100%",
				},
			}}
			footerStyle={{ px: { xs: 2, md: 5 }, pb: { xs: 2, md: 2 }, pt: 0 }}
			dialogAction={
				<Box sx={{ display: "flex" }}>
					<Button
						variant="DS1"
						onClick={() => {
							handleClose();
						}}
					>
						Close
					</Button>
				</Box>
			}
		>
			<FullNotificationList open={open} asPanel clickCallback={() => {
				handleClose();
			}}/>
		</CommonSlideDialog>
		</Fragment>
	);
}

export default NotificationPanelButton;
