import React, { useEffect, useState } from "react";
import { useHistory, generatePath } from "react-router-dom";
import { Box, Typography, Avatar } from "@mui/material";
import services from "../../../../services";
import { USER_TYPES } from "../../../../utils/userTypes";
import ROUTES_PATH from "../../../../constants/routes";

export const MatchList = ({ expertises = [] }) => {
	const history = useHistory();
	const [data, setData] = useState([]);
	const [errors, setErrors] = useState({});
	const [dataLoading, setDataLoading] = useState(false);

	useEffect(() => {
		getMatchList();
	}, []);

	const getMatchList = async () => {
		setDataLoading(true);
		try {
			const response = await services.getNetworkMatchApi({
				page: 1,
				perPage: 10000000,
				userTypes: "STARTUP_OWNER",
				expertise: expertises.join(","),
			});
			setData(response?.data?.data?.models);
		} catch (error) {
			setErrors(error?.response?.data ?? { error: "Unable to successfully retrieve your startup profile." });
		} finally {
			setDataLoading(false);
		}
	};

	const redirectToMatched = (userId, userType) => {
		const path = userType === USER_TYPES.MENTOR_USER ? ROUTES_PATH.MENTOR_PROFILE_NEW : ROUTES_PATH.ACCOUNT_PROFILE;
		history.push({
			pathname: generatePath(path, {
				id: userId,
			}),
		});
	};

	return (
		<Box
			sx={{
				backgroundColor: "#fff",
				borderRadius: "40px",
				boxShadow: "0px 10px 85px rgba(0, 0, 0, 0.09)",
				padding: "45px",
			}}
		>
			<Typography variant="title_portfolio">All Mentor Matches</Typography>

			<Box mt={3}>
				<Box display="grid" gridTemplateColumns="2fr 1fr" gap={2} mb={2}>
					<Typography variant="poppinsSemiBold14">Mentor Name</Typography>
					<Typography variant="poppinsSemiBold14">Company</Typography>
				</Box>
				<Box display="flex" flexDirection="column" gap={2}>
					{data?.map((match) => (
						<MatchItem
							key={match?.id}
							userId={match?.id}
							userType={match?.userTypes?.[0]}
							logo={match?.picture}
							name={match?.name}
							role={match?.userTypes?.[0]}
							rating={match?.rating}
							company={match?.companyModel?.companyName}
							redirectToMatched={redirectToMatched}
						/>
					))}
				</Box>
			</Box>
		</Box>
	);
};

const MatchItem = ({ logo, userId, userType, name, role, rating, company, redirectToMatched }) => {
	return (
		<Box display="grid" gridTemplateColumns="2fr 1fr" alignItems="center" gap={2}>
			<Box display="flex" alignItems="center" gap={2}>
				<Avatar sx={{ width: 60, height: 60 }} src={logo}></Avatar>
				<Box display="flex" flexDirection="column">
					<Typography variant="poppinsSemiBold14">{name}</Typography>
					<Typography variant="body/medium">{role}</Typography>
					<Typography
						variant="body/medium"
						sx={{ textDecoration: "underline", cursor: "pointer" }}
						onClick={() => redirectToMatched(userId, userType)}
					>
						View Full Profile
					</Typography>
				</Box>
			</Box>
			<Box>
				<Typography variant="body/medium">{company}</Typography>
			</Box>
		</Box>
	);
};
