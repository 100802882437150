import {
  Button,
  TextField,
  Box,
  Typography,
  Grid,
  useMediaQuery,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import DialogComponent from "../../../../../common/CommonDialog";
import { Add } from "@mui/icons-material";
import {
  addCandidatesButtonDisable,
  addExternalCandidatesValid,
} from "../Functions/functions";
import messages from "../../../../../constants/messages";
import theme from "../../../../../constants/Theme";
export default function AddExternalCandidate({ open, onClose, addCandidate }) {
  const [userInputs, setUserInputs] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    if (!open) {
      setUserInputs({
        firstName: "",
        lastName: "",
        email: "",
      });
    }
  }, [open]);

  const handleAdd = type => {
    addCandidate(userInputs, type);
  };
  const ActionComponent = () => {
    return (
      <Box
        sx={{
          py: 2,
          gap: 2,
          px: 2,
          [theme.breakpoints.down("md")]: {
            px: 1,
          },
          display: "flex",
        }}
      >
        <Button
          startIcon={<Add sx={{ fontSize: 16 }} />}
          size="sm"
          variant="outlined"
          disabled={addCandidatesButtonDisable(userInputs)}
          onClick={() => handleAdd("INVITE_LATER")}
          sx={{
            whiteSpace: "nowrap",
            [theme.breakpoints.down("md")]: { px: 1 },
          }}
        >
          Add & invite later
        </Button>
        <Button
          startIcon={<Add sx={{ fontSize: 16 }} />}
          size="sm"
          disabled={addCandidatesButtonDisable(userInputs)}
          onClick={() => handleAdd("INVITE_NOW")}
          sx={{
            whiteSpace: "nowrap",
            [theme.breakpoints.down("md")]: { px: 1 },
          }}
        >
          Add & invite now
        </Button>
      </Box>
    );
  };

  const handleInputChange = (e, key) => {
    const value = e.target.value;
    const currentInput = { ...userInputs };

    currentInput[key] = value;

    setUserInputs(currentInput);
  };

  return (
    <DialogComponent
      title={"Add External Candidate"}
      titleVariant={"title_large"}
      isOpen={open}
      onClose={onClose}
      dialogAction={<ActionComponent />}
      PaperProps={{
        style: {
          borderRadius: 30,
          ...(!isSmallScreen && {
            width: theme.breakpoints.values.sm,
          }),
        },
      }}
      maxWidth="lg"
      handleCloseBackDropClick={() => {}}
      disableBackDropClick
    >
      <Box sx={{ mt: 3, px: 1, pt: 1 }}>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <TextField
              spellCheck="true"
              label="First Name"
              value={userInputs.firstName}
              fullWidth
              onChange={e => handleInputChange(e, "firstName")}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              label="Last Name"
              spellCheck="true"
              value={userInputs.lastName}
              fullWidth
              onChange={e => handleInputChange(e, "lastName")}
            />
          </Grid>
        </Grid>

        <Box sx={{ mt: 5 }}>
          <TextField
            label="Email Address"
            spellCheck="true"
            value={userInputs.email}
            fullWidth
            onChange={e => handleInputChange(e, "email")}
            required
          />
          <Box>
            <Typography variant="body1" sx={{ color: "text.secondary", ml: 1 }}>
              {!userInputs.email && "*required"}
            </Typography>
          </Box>
        </Box>
      </Box>
    </DialogComponent>
  );
}
