import styled from "@emotion/styled";
import { sosPrimary } from "../../../constants/Theme/colors";

export const ProfileContentWrapper = styled.div(({ theme, borderWidth }) => {
  return {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      backgroundColor: "none",
      padding: theme.spacing(0),
      marginTop: theme.spacing(0),
    },
    [theme.breakpoints.up("md")]: {
      backgroundColor: theme.palette.background.default,
    },
  };
});
