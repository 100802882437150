import { Svg } from "./Svg";

export const FounderIcon = ({ className, fill = "#667085", width = 30, height = 30 }) => (
	<Svg className={className} width={width} height={height} viewBox="0 0 30 30" fill="none">
		<path
			d="M12.3332 22.5447V25.6666C12.3332 27.1394 13.5271 28.3333 14.9998 28.3333C16.4726 28.3333 17.6665 27.1394 17.6665 25.6666V22.5447M14.9998 1.66663V2.99996M2.99984 15H1.6665M6.33317 6.33329L5.53304 5.53316M23.6665 6.33329L24.4668 5.53316M28.3332 15H26.9998M22.9998 15C22.9998 19.4182 19.4181 23 14.9998 23C10.5816 23 6.99984 19.4182 6.99984 15C6.99984 10.5817 10.5816 6.99996 14.9998 6.99996C19.4181 6.99996 22.9998 10.5817 22.9998 15Z"
			stroke={fill}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</Svg>
);
