import "./styles.css";

function InfiniteAnimation() {
	return (
		<div className="card-stack">
			<div class="card"></div>
			<div class="card"></div>
			<div class="card"></div>
			<div class="card"></div>
		</div>
	);
}

export default InfiniteAnimation;
