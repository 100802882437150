import { Svg } from "./Svg";

export const MentorIcon = ({ className, fill = "#667085", width = 30, height = 30 }) => (
	<Svg className={className} width={width} height={height} viewBox="0 0 30 30" fill="none">
		<path
			d="M7.12566 13.9717C7.04278 13.4376 6.99979 12.8904 6.99979 12.3333C6.99979 6.44225 11.8068 1.66663 17.7366 1.66663C23.6664 1.66663 28.4735 6.44225 28.4735 12.3333C28.4735 13.6641 28.2282 14.9379 27.78 16.1126C27.687 16.3566 27.6404 16.4786 27.6193 16.5738C27.5984 16.6682 27.5903 16.7346 27.588 16.8312C27.5857 16.9288 27.5989 17.0362 27.6254 17.2511L28.1622 21.6113C28.2203 22.0833 28.2493 22.3193 28.1708 22.4909C28.102 22.6412 27.9798 22.7607 27.828 22.826C27.6546 22.9005 27.4193 22.866 26.9488 22.797L22.7018 22.1745C22.48 22.142 22.3691 22.1258 22.2682 22.1263C22.1683 22.1269 22.0991 22.1343 22.0014 22.1548C21.9026 22.1756 21.7763 22.2229 21.5238 22.3175C20.3461 22.7586 19.0697 23 17.7366 23C17.179 23 16.6314 22.9577 16.0967 22.8763M9.17528 28.3333C13.1285 28.3333 16.3332 25.05 16.3332 21C16.3332 16.9499 13.1285 13.6666 9.17528 13.6666C5.22208 13.6666 2.01738 16.9499 2.01738 21C2.01738 21.8141 2.14687 22.5972 2.3859 23.3289C2.48694 23.6382 2.53747 23.7929 2.55405 23.8985C2.57136 24.0088 2.57439 24.0708 2.56794 24.1822C2.56177 24.289 2.53506 24.4097 2.48163 24.651L1.6665 28.3333L5.65957 27.788C5.87752 27.7582 5.9865 27.7433 6.08166 27.744C6.18186 27.7446 6.23505 27.7501 6.33331 27.7697C6.42664 27.7883 6.56538 27.8372 6.84287 27.9352C7.57396 28.1932 8.35865 28.3333 9.17528 28.3333Z"
			stroke={fill}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</Svg>
);
