import React from "react";
import { useHistory } from "react-router-dom";
import { Box, Typography, Link } from "@mui/material";

import { BtnLogin } from "./SignUp";
import { toastContainer } from "../../../../utils/toast";
import messages from "../../../../constants/messages";
import * as url from "../../../../utils/urls";
import ROUTES_PATH from "../../../../constants/routes";

export const Excited = ({ signUpData }) => {
	const history = useHistory();

	const handleResendVerificationEmail = async () => {
		try {
			const resp = await fetch(url.resendEmailUrl, {
				method: "POST",
				headers: { "Content-Type": "application/json", Authorization: "Bearer " + signUpData?.idToken },
				body: JSON.stringify({ email: signUpData?.email }),
			});
			const respData = await resp.json();
			if (!respData?.error) {
				toastContainer(messages.VERIFICATION_EMAIL, "success");
			}
		} catch (error) {
			toastContainer(error?.response?.data?.message || "Something is wrong!", "error");
		}
	};

	return (
		<Box width="422px" display="flex" alignItems="center" flexDirection="column" gap={4}>
			<Typography variant="Text/2xl/Semibold">We are excited to have you join us!</Typography>
			<Typography variant="Text/sm/Regular" textAlign="center">
				Before we get started, we need you verify your account via email.
			</Typography>

			<BtnLogin fullWidth onClick={handleResendVerificationEmail}>
				Resend verification email
			</BtnLogin>

			<Typography variant="Text/sm/Regular" color="#667085">
				I am already verified.{" "}
				<Link
					sx={{ cursor: "pointer", color: "#667085" }}
					onClick={() => {
						history.push(ROUTES_PATH.LOG_IN);
					}}
				>
					Click here to login.
				</Link>
			</Typography>
		</Box>
	);
};
