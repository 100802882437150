import React, { useEffect, useState } from 'react'

import { cloneDeep } from 'lodash'
import { uid } from 'uid/single'

import {
	Box,
	Button,
	Card, Checkbox,
	Divider,
	FormControlLabel,
	IconButton,
	Input,
	InputAdornment,
	Stack,
	TextField,
	Typography
} from '@mui/material'
import { ContentCopy, CopyAll, Delete, Edit } from '@mui/icons-material'

import Editor from '../Editor'

function OutputItem ({ title: _title, contents, defacto, disabled, onStateChange, onDuplicate, onDelete, powerUps }) {

	const [ title, setTitle ] = useState(_title)
	useEffect(() => {
		setTitle(_title)
	}, [ _title ])

	return <Card variant={'outlined'}>
		<Stack gap={3} p={3}>
			<Stack direction="row" gap={2} alignItems="center" justifyContent={'space-between'}>
				<Stack direction={'row'} gap={2}>
					<Input
						sx={theme => ({ fontFamily: theme.typography.h3, fontSize: theme.typography.h3 })}
						endAdornment={
							<InputAdornment position={'end'}>
								<Edit/>
							</InputAdornment>
						}
						value={title}
						onChange={(e) => setTitle(e.target.value)}
						onBlur={() => _title !== title && onStateChange({ title, contents, defacto })}
					/>
					<FormControlLabel
						sx={{ whiteSpace: 'nowrap' }}
						label="Default Output"
						control={<Checkbox checked={!!defacto} disabled={!!disabled}
										   onChange={() => !defacto && onStateChange({ title, contents, defacto: true })}/>}
					/>
				</Stack>
				<Stack direction="row" gap={2} alignItems={'center'}>
					<Button sx={{ whiteSpace: 'nowrap' }}
							variant="outlined"
							startIcon={<CopyAll/>}
							onClick={onDuplicate}
					>Duplicate</Button>
					<Button startIcon={<Delete/>}
							disabled={!!disabled}
							onClick={onDelete}
					>Delete</Button>
				</Stack>
			</Stack>
			<Stack gap={1}>
				<Editor
					label={'Outcome content…'}
					defaultValue={contents}
					onSave={(contents) => onStateChange({ title, contents, defacto })}
					getMediaUrl={(file) => powerUps.uploadMedia({ file })}
				/>
			</Stack>
		</Stack>
	</Card>
}

export default function Outputs ({ outputs: _outputs, setOutputs, webhook: _webhook, setWebhook: _setWebhook }) {
	const outputs = Array.isArray(_outputs) && _outputs.length ? _outputs : [ {
		id: uid(),
		title: 'Output 1',
		contents: '',
		defacto: true
	} ]
	useEffect(() => {
		if (_outputs !== outputs) {
			setOutputs(outputs)
		}
	}, [ outputs ])
	const [ webhook, setWebhook ] = useState(_webhook || '')
	useEffect(() => {
		if (_webhook !== webhook) {
			setWebhook(_webhook)
		}
	}, [ _webhook ])

	const onAddOutput = () => {
		setOutputs([ ...outputs, { id: uid(), title: `Output ${outputs.length + 1}`, contents: '', defacto: false } ])
	}

	return <Stack gap={3}>
		<Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
			<Typography variant={'h3'}>Outcomes</Typography>
			<Button sx={{ margin: 0, width: 'unset' }} onClick={onAddOutput}>Add Output</Button>
		</Stack>
		<Divider/>
		{outputs?.map((output, i) => <OutputItem key={output.id} {...output} disabled={outputs.length <= 1} onStateChange={({ title, contents, defacto }) => {
			const outs = [ ...outputs ]
			outs[i] = {
				...output,
				title,
				contents
			}
			if (defacto) {
				for (const out of outs) {
					out.defacto = false
				}
				outs[i].defacto = true
			}
			setOutputs(outs)
		}} onDuplicate={() => {
			const newOutput = cloneDeep(output)
			newOutput.id = uid()
			newOutput.defacto = false
			const outs = [ ...outputs ]
			outs.splice(i + 1, 0, newOutput)
			setOutputs(outs)
		}} onDelete={() => {
			const outs = [ ...outputs ]
			const oldOutput = outs.splice(i, 1)[0]
			if (oldOutput.defacto) {
				outs[outs.length === i ? outs.length - 1 : i].defacto = true
			}
			setOutputs(outs)
		}}/>)}
		<Card variant={'outlined'}>
			<Stack p={3} gap={2}>
				<Typography variant={'h5'}>Advanced</Typography>
				<Stack direction={'row'} gap={1} alignItems={'center'}>
					<TextField
						fullWidth
						label="Webhook URL"
						value={webhook}
						onChange={(e) => setWebhook(e.target.value)}
						onBlur={(e) => _setWebhook && _setWebhook(webhook)}
					/>
					<IconButton color={'primary'}>
						<ContentCopy/>
					</IconButton>
				</Stack>
			</Stack>
		</Card>
	</Stack>
}
