import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import ClearIcon from "@mui/icons-material/Clear";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { getCurrentTime, getFormattedDate } from "../../../utils/date";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ROUTES_PATH from "../../../constants/routes";
import { handleMixPanel } from "../../../utils/mixPanelEventHandling";

export default function MyBookingCard({ data, isLast, handleCancelBooking }) {
	const history = useHistory();
	const getTitleDate = (timestamp) => {
		const dateTime = new Date(timestamp);

		return getFormattedDate(dateTime, "dddd, MMM DD");
	};

	const userData = useSelector((state) => state?.auth);

	const getTimeBetweenString = (start, end) => {
		const timeFrom = new Date(start);
		const timeFromStr = getCurrentTime(timeFrom);
		const timeTill = new Date(end);
		const timeTillStr = getCurrentTime(timeTill);
		return `${timeFromStr} - ${timeTillStr}`;
	};

	const getName = () => {
		if (userData.calUserId == data.userId) {
			return data?.attendees[0]?.name || "";
		} else {
			return data?.user?.name || "";
		}
	};

	return (
		<Box
			sx={(theme) => ({
				p: 2,
				...(!isLast && {
					borderBottom: `1px solid ${theme.palette.secondary.outline2}`,
				}),
			})}
		>
			<Grid container sx={{ overflowX: "scroll", p: 1 }} spacing={2}>
				{/*Grid to show time*/}
				<Grid item xs={12} sm={12} md={2} lg={2}>
					<Box sx={{ my: 1 }}>
						<Typography variant="body_large">{getTitleDate(data.startTime)}</Typography>
					</Box>
					<Box>
						<Typography variant="body_small" sx={{ color: "secondary.outline" }}>
							{getTimeBetweenString(data.startTime, data.endTime)}
						</Typography>
					</Box>
				</Grid>

				{/*Grid to show meeting between users*/}
				<Grid
					item
					xs={12}
					sm={12}
					md={5}
					lg={5}
					sx={{ pl: { md: 1 }, cursor: "pointer" }}
					onClick={() => {
						if (data?.status === "CANCELLED") {
						} else {
							history.push(ROUTES_PATH.MEETING_SCHEDULED.replace(":id", data.id));
						}
					}}
				>
					<Box sx={{ my: 1 }}>
						<Typography variant="body_large">{data.title}</Typography>
					</Box>
					{data.attendees.length && data.user ? (
						<Box>
							<Typography variant="body_medium" sx={{ color: "secondary.outline" }}>
								You and {getName()}
							</Typography>
						</Box>
					) : (
						""
					)}
				</Grid>

				{/*grid for action buttons*/}
				<Grid item sm={12} xs={12} md={5} lg={5}>
					{data.status === "CANCELLED" ? (
						<Box textAlign={"center"} sx={{ mt: 1 }}>
							{" "}
							<Typography variant="title_medium" sx={{ color: "secondary.outline" }}>
								Cancelled
							</Typography>{" "}
						</Box>
					) : (
						<Box
							sx={{
								display: "flex",
								justifyContent: { md: "flex-end", sm: "flex-start" },
								mt: { xs: 2, md: 1 },
								gap: { lg: 1, md: 2, xs: 2 },
								flexDirection: { md: "column", lg: "row", sm: "row" },
							}}
						>
							<Box>
								<Button
									sx={{ width: { lg: "max-content", md: "100%" } }}
									variant="outlined"
									startIcon={<ClearIcon />}
									onClick={() => handleCancelBooking(data)}
								>
									Cancel
								</Button>
							</Box>
							<Box>
								<Button
									sx={{ width: { lg: "max-content", md: "100%" } }}
									variant="outlined"
									startIcon={<AccessTimeIcon />}
									onClick={() => {
										history.push(ROUTES_PATH.SCHEDULE_MEETING_CALENDAR.replace(":id", data.id));
										handleMixPanel("Meeting Rescheduled", {
											Source: "Bookings",
										});
									}}
								>
									Reschedule
								</Button>
							</Box>
						</Box>
					)}
				</Grid>
			</Grid>
		</Box>
	);
}
