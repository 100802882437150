import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Redirect, Route, useLocation, useHistory } from "react-router-dom";
import services from "../services";
import { useSelector, useDispatch } from "react-redux";
import jwt_decode from "jwt-decode";
import DefaultLayout from "../layout/Default";
import WorkspaceLayout from "../layout/Workspace";
import AppHomeLayout from "../layout/_AppHome";
import ROUTES_PATH from "../constants/routes";
import constants from "../constants";
//import OnboardingLayout from "../layout/ARCHIVEOnboarding";
import { Layout as OnboardingLayout } from "../components/Onboarding/Layout/index";
import { logout } from "../utils/auth0-spa";
import { authWebLogout } from "../utils/auth0";
import { USER_TYPES } from "../utils/userTypes";
import * as actions from "../modules/actions";
import CommonLayout from "../layout/CommonLayout";
import PublicLayout from "../layout/Public";
import { OnboardLayout } from "../containers/NewOnboard/Components";
import { saveToLocalStorage } from "../constants/Theme/ThemeContext";

export default function RouteWrapper({ component: Component, isPrivate, isRestricted, ...rest }) {
	/**
	 * As a non authed user (not logged in / login token not valid)
	 * If they try to access a private route (one requiring authentication)
	 * Then redirect user to non private route -> ie. 'Home Page', 'Sign In', etc...
	 *
	 * Ex:
	 *    if (isPrivate && !signed) {
	 *        return <Redirect to="/" />;
	 *    }
	 */

	/**
	 * As an authed user (already logged in)
	 * If they try to access a non private route ie. Homepage or Sign In
	 * Redirect them to main page as an authed user -> ie. 'dashboard', 'profile' etc...
	 *
	 * Ex:
	 *    if (!isPrivate && signed) {
	 *        return <Redirect to="/dashboard" />;
	 *    }
	 */

	/**
	 * If when we have pages that require use of a different layout other than default.
	 * We will implemnt some function to determine which layout file to load.
	 * Right now we only have one so it is static set to DefauletLayout.
	 *
	 * Ex:
	 * const Layout = signed ? <<Different Layout File>> : DefaultLayout;
	 */

	const authData = useSelector((state) => state?.auth);
	const { utmSource, utmCampaign, utmMedium, partner } = useSelector((state) => state?.utmParams);

	window.StonlyWidget("identify", authData?.userId?.toString(), {
		user_type: authData?.userType,
	});

	const signed = authData?.idToken;
	const history = useHistory();
	const location = useLocation();

	const dispatch = useDispatch();

	const [approvalStatus, setApprovalStatus] = useState();

	const searchParams = new URLSearchParams(window.location.search);
	const utm_source = searchParams.get("utm_source");
	const utm_campaign = searchParams.get("utm_campaign");
	const utm_medium = searchParams.get("utm_medium");
	const partner_params = searchParams.get("partner");
	const params = new URLSearchParams(location.search);
	useEffect(() => {
		// const params = new URLSearchParams(location.search);
		// const token = params.get("token");
		// console.log(`token`, token);
		// if (token) setEmailToken(token);
		if (!approvalStatus && signed) {
			services.getApprovalStatus().then((status) => {
				setApprovalStatus(status);
			});
		}
	}, [approvalStatus, signed]);

	useEffect(() => {
		dispatch(
			actions.setUtmParams({
				utmSource: utm_source ? utm_source : utmSource,
				utmCampaign: utm_campaign ? utm_campaign : utmCampaign,
				utmMedium: utm_medium ? utm_medium : utmMedium,
				partner: partner_params ? partner_params : partner,
			})
		);
	}, []);

	//useEffect(() => {
	//	try {
	//		window.Intercom("boot", {
	//			app_id: "hzw5ibms",
	//			email: authData?.userData?.email,
	//		});
	//	} catch (e) {
	//		console.log(e);
	//	}
	//}, [authData]);

	//if (signed && rest.path === "/login") {
	//  async function customLogout() {
	//    await logout();
	//    await authWebLogout();
	//  }
	//  customLogout();
	//}

	let Layout = OnboardLayout;

	if (location.pathname.includes("/startup/") || location.pathname.includes("/account/") || location.pathname.includes("/public/powerups/")) {
		Layout = PublicLayout;
	} else {
		// if (signed && isPrivate) {
		// 	console.log("loca", location.pathname);
		// 	return <Redirect to={location.pathname} />;
		// }
		// if (!signed && isPrivate) {
		// 	console.log("loca", location.pathname);
		// 	return <Redirect to={location.pathname} />;
		// }
		if (signed) {
			//if (authData?.userType === USER_TYPES.INVESTOR_USER) {
			//	Layout = CommonLayout;
			//} else {
			//	if (location.pathname === ROUTES_PATH.FOUNDER_DASHBOARD) {
			//		Layout = WorkspaceLayout;
			//	} else if (location.pathname === ROUTES_PATH.STARTUP_PROFILE || location.pathname === ROUTES_PATH.STARTUP_PROFILE_PATH) {
			//		Layout = CommonLayout;
			//	} else {
			//		Layout = WorkspaceLayout;
			//	}
			//}

			Layout = CommonLayout;

			if (location.pathname === "/invalid-invite") {
				let Layout = DefaultLayout;
			}

			if (location.pathname === "/home/startups/startups") {
				return <Redirect to={ROUTES_PATH.STARTUPS_HOME} />;
			}

			if (location.pathname === "/signup") {
				const queryParams = new URLSearchParams(location.search);
				const token = queryParams.get("token");
				if (token) {
					var decodedValue = jwt_decode(token);
					if (authData?.userData?.email !== decodedValue.email && decodedValue.type === USER_TYPES.STARTUP_USER) {
						Layout = DefaultLayout;
						return <Redirect to={ROUTES_PATH.INVALID_INVITE + "?token=" + token} />;
					}
				}
			} else if (location.pathname === "/invalid-invite") {
				Layout = DefaultLayout;
			}
		}

		if (isPrivate && !signed) {
			if (location.pathname.includes("/discovery-board/note/")) {
				dispatch(actions.setAuthData({ routeAfterLogin: location.pathname }));
			}
			if (location.pathname.includes("/profile/")) {
				dispatch(actions.setAuthData({ routeAfterLogin: location.pathname }));
			}
			if (location.pathname.includes("/startup/") || location.pathname.includes("/account/") || location.pathname.includes("/public/powerups/")) {
				return <Redirect to={location.pathname} />;
			}
			if (location.pathname.includes("/network")) {
				const params = new URLSearchParams(location.search);
				const networkToken = params.get("token");

				if (networkToken) {
					saveToLocalStorage("emailToken", networkToken);
					saveToLocalStorage("routeAfterLogin", location.pathname);
					history.push("/login");
					window.location.reload();
				}
			}
			return <Redirect to="/login" />;
		}

		if (signed && isRestricted && approvalStatus && approvalStatus !== "APPROVED") {
			return <Redirect to={ROUTES_PATH.MY_ACCOUNT_PROFILE} />;
		}

		if (authData.socialConnectFlow || authData.userSessionChange) {
		} else {
			// TODO: impersonate redirection to impersonate route instead of current workspace
			if (!isPrivate && authData.authenticatedAppUser && signed) {
				return (
					<Redirect
						to={
							constants?.REQUEST_USER_TYPE?.find((user) => user?.type === authData?.userType)
								? constants?.REQUEST_USER_TYPE?.find((user) => user?.type === authData?.userType)?.path
								: ROUTES_PATH.INVESTOR_WORKSPACE
						}
					/>
				);
			}
		}

		if (
			location &&
			location.pathname &&
			(location.pathname.includes("onboarding") || location.pathname.includes("onboard") || location.pathname.includes("teammate"))
		) {
			Layout = null;
		} else if (location && location.state && location.state.onboarding) {
			Layout = null;
		}

		//if (
		//  location &&
		//  location.pathname &&
		//  location.pathname.includes("new-onboarding")
		//) {
		//  Layout = UpdatedOnboardingLayout;
		//} else if (location && location.state && location.state.onboarding) {
		//  Layout = UpdatedOnboardingLayout;
		//}

		if (window.location.pathname.includes("authorize") || window.location.pathname.includes("success")) {
			Layout = Component;
		}

		if ((location.pathname.includes("email-verification") || location.pathname.includes("social-signup")) && signed) {
			Layout = ({ children }) => <div style={{ height: "100vh" }}>{children}</div>;
		}

		if (
			//location.pathname === "/" ||
			location.pathname === "/terms" ||
			location.pathname === "/privacy"
		) {
			Layout = AppHomeLayout;
		}

		if (location.pathname.includes("/startup/") || location.pathname.includes("/account/") || location.pathname.includes("/public/powerups/")) {
			Layout = PublicLayout;
		}
	}

	return (
		<Route
			{...rest}
			render={(props) =>
				Layout ? (
					<Layout>
						<Component {...props} />
					</Layout>
				) : (
					<Component {...props} />
				)
			}
		/>
	);
}

RouteWrapper.propTypes = {
	isPrivate: PropTypes.bool,
	isRestricted: PropTypes.bool,
	//component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
	//  .isRequired,
};
RouteWrapper.defaultProps = {
	isPrivate: false,
	isRestricted: false,
};
