import React, { useEffect, useState } from "react";
import {
  Button,
  Stack,
  Box,
  Typography,
  Divider,
  Grid,
  Link,
  CircularProgress,
  useMediaQuery,
} from "@mui/material";
import { Bookmark, BookmarkBorder } from "@mui/icons-material";
import theme from "../../../../constants/Theme";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { AVATAR_DEFAULT } from "../../../../constants";
import FiberManualRecordSharpIcon from "@mui/icons-material/FiberManualRecordSharp";
import ButtonPopover from "./Components/ButtonPopover";
import { getStartupData, saveUnsaveStartup } from "./functions/functions";
import { useHistory, useParams } from "react-router-dom";
import CheckIcon from "@mui/icons-material/Check";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import { toastContainer } from "../../../../utils/toast";
import messages from "../../../../constants/messages";
import ConnectRequestPopup from "../../../Network/Components/Popups/ConnectPopup";
import SuccessPopup from "../../../Network/Components/Popups/ConnectSuccessPopup";
import services from "../../../../services";
import MessageHandlerModal from "../../../../common/modal/MessageHandler";
import ROUTES_PATH from "../../../../constants/routes";
import JsxParser from "react-jsx-parser";

export default function StartupProfile() {
  const [openConnectRequest, setOpenConnectRequest] = useState(false);
  const [connectSuccessPopup, setConnectSuccessPopup] = useState(false);
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [requestType, setRequestType] = useState("CONNECT");
  const [startupDetails, setStartupDetails] = useState(null);
  const params = useParams();
  const companyId = params.id;
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const history = useHistory();

  useEffect(() => {
    getStartupData(companyId)
      .then(result => {
        setStartupDetails({ ...result });
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  const handleSaveClick = () => {
    const data = {
      companyId: companyId,
      status: !startupDetails.isSaved,
      type: "SAVED",
    };
    saveUnsaveStartup(data)
      .then(result => {
        if (result) {
          setStartupDetails({
            ...startupDetails,
            isSaved: !startupDetails.isSaved,
          });
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  const handleConnect = () => {
    setRequestType("CONNECT");
    setOpenConnectRequest(true);
  };

  const handleRequestCancelConfirm = () => {
    setConfirmPopup(false);
    handleConnectionStatusUpdate({
      connectionId: startupDetails?.connectionModel?.id,
      connectionStatus: "CANCELED",
    });
  };

  const handleCancel = () => {
    setRequestType("CANCELED");
    setConfirmPopup(true);
  };

  const handleConnectionStatusUpdate = data => {
    services
      .updateStartUpConnection(data)
      .then(result => {
        const response = result.data;
        if (response?.code === 200) {
          setOpenConnectRequest(false);

          setStartupDetails({
            ...startupDetails,
            connectionModel: response.data,
          });
          setConnectSuccessPopup(true);
        }
      })
      .catch(error => {
        const message = error.response?.data?.message;
        toastContainer(message, "error");
        console.log(error);
      });
  };

  const handleSend = data => {
    services
      .handleStartUpConnection(data)
      .then(result => {
        const response = result.data;
        if (response?.code === 200) {
          setOpenConnectRequest(false);
          setStartupDetails({
            ...startupDetails,
            connectionModel: response.data,
          });
          setConnectSuccessPopup(true);
        }
      })
      .catch(error => {
        const message = error.response?.data?.message;
        toastContainer(message, "error");
        console.log(error);
      });
  };

  const SaveButton = () => {
    return (
      <Button
        variant="nofill"
        color="primary"
        startIcon={
          startupDetails.isSaved ? (
            <BookmarkIcon color="primary" />
          ) : (
            <BookmarkBorder color="primary" />
          )
        }
        sx={{ width: "max-content", m: "inherit" }}
        onClick={handleSaveClick}
      >
        {startupDetails.isSaved ? "Saved" : "Save"}
      </Button>
    );
  };

  const linkedInUrl = "https://in.linkedin.com/";
  return (
    <Stack
      sx={theme => ({
        width: 1,
        borderRadius: theme.shape.standard.borderRadius,
        background:
          "linear-gradient(0deg, rgba(118, 73, 160, 0.05), rgba(118, 73, 160, 0.05)), #FCFCFC",
        [theme.breakpoints.down("md")]: {
          borderRadius: 0,
        },
      })}
    >
      <Box
        sx={{
          maxWidth: theme.breakpoints.values.xl,
          margin: "auto",
          width: "100%",
        }}
      >
        <ConnectRequestPopup
          openConnectRequest={openConnectRequest}
          setOpenConnectRequest={setOpenConnectRequest}
          companyId={companyId}
          onConnect={handleSend}
          startupName={startupDetails?.companyName}
        />
        <SuccessPopup
          connectSuccessPopup={connectSuccessPopup}
          setConnectSuccessPopup={setConnectSuccessPopup}
          startupName={startupDetails?.companyName}
          type={requestType}
        />

        <MessageHandlerModal
          isOpen={confirmPopup}
          onCancel={() => setConfirmPopup(false)}
          onOk={() => handleRequestCancelConfirm()}
          okText="Ok"
          cancelText={"Cancel"}
          heading="Confirm"
          text={"Are you sure you want to cancel connection request?"}
          messageType="primary"
        />

        <Box
          sx={theme => ({
            background: theme.palette.secondary.white,
            borderRadius: theme.shape.standard.borderRadius,
            m: 3,
            p: 4,
          })}
        >
          <Box>
            <Button
              variant="nofill"
              color="primary"
              startIcon={<ArrowBackIcon />}
              sx={{ width: "max-content" }}
              onClick={() => history.push(ROUTES_PATH.MENTOR_NETWORK)}
            >
              Back
            </Button>
          </Box>

          {startupDetails ? (
            <Box>
              <Box sx={{ mt: 3 }}>
                <Box>
                  {isSmallScreen ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <img
                        src={startupDetails.logoUrl || AVATAR_DEFAULT}
                        height={100}
                        width={100}
                        style={{ borderRadius: "50%" }}
                      />
                      <SaveButton />
                    </Box>
                  ) : (
                    <Box>
                      <img
                        src={startupDetails.logoUrl || AVATAR_DEFAULT}
                        height={100}
                        width={100}
                        style={{ borderRadius: "50%" }}
                      />
                    </Box>
                  )}

                  <Box>
                    <Typography variant="titleXLarge">
                      {startupDetails?.companyName}
                    </Typography>
                  </Box>
                  <Box sx={{ my: 2 }}>
                    <Typography variant="subhead1" color={"secondary"}>
                      {startupDetails.tagLine}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="link">
                      {startupDetails?.companyType}{" "}
                    </Typography>
                    <FiberManualRecordSharpIcon
                      sx={{ width: "7px", height: "7px", mx: 1 }}
                    />
                    <Typography
                      variant="label_large"
                      sx={{ color: "secondary.dark" }}
                    >
                      {startupDetails?.state}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      my: 2,
                      display: "flex",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection:
                          isSmallScreen &&
                            startupDetails.connectionModel?.connectionStatus ===
                            ("PENDING" || "ACCEPTED")
                            ? "column"
                            : "row",
                        gap: 2,
                      }}
                    >
                      <Box>
                        {startupDetails.connectionModel?.connectionStatus ===
                          "PENDING" ? (
                          <ButtonPopover
                            text={"Connect Requested"}
                            onAction={() => handleCancel()}
                            popupText={"Cancel Connection Request"}
                            prefixIcon={<CheckIcon />}
                          />
                        ) : startupDetails.connectionModel?.connectionStatus ===
                          "ACCEPTED" ? (
                          <ButtonPopover
                            text={"Connected"}
                            popupText={"Remove Connection"}
                            onAction={() => handleCancel()}
                            prefixIcon={<CheckIcon />}
                          />
                        ) : (
                          <Button onClick={() => handleConnect()}>
                            Connect
                          </Button>
                        )}
                      </Box>
                      <Box>
                        <Button
                          variant="outlined"
                          onClick={() => window.open(linkedInUrl, "_blank")}
                          sx={{ whiteSpace: "nowrap" }}
                        >
                          View LinkedIn profile
                        </Button>
                      </Box>
                    </Box>
                    <Box sx={{ ml: "auto" }}>
                      {!isSmallScreen && <SaveButton />}
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Divider />
              <Box sx={{ my: 4 }}>
                <Grid container spacing={2}>
                  <Grid item md={8}>
                    <Box sx={{ my: 3 }}>
                      <Typography variant="title_large">About</Typography>
                    </Box>
                    <Box>
                      <Typography variant="bodyLarge">
                        <JsxParser
                          jsx={startupDetails.about}
                          autoCloseVoidElements
                          showWarnings
                        />
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Divider orientation="vertical" />
                  </Grid>
                  <Grid item md={3}>
                    <Box sx={{ mt: 3 }}>
                      <Typography variant="title_large">Details</Typography>
                    </Box>
                    <Box sx={{ my: 1 }}>
                      <Box>
                        <Typography variant="title_medium">Website</Typography>
                      </Box>
                      <Link
                        sx={{ mt: 1 }}
                        href={startupDetails.websiteLink}
                        target="_blank"
                      >
                        {" "}
                        <Typography variant="link" sx={{ cursor: "pointer" }}>
                          {startupDetails.websiteLink}
                        </Typography>{" "}
                      </Link>
                    </Box>

                    <Box sx={{ my: 1 }}>
                      <Box>
                        <Typography variant="title_medium">Industry</Typography>
                      </Box>
                      <Typography variant="bodyLarge">
                        {startupDetails?.companyType}
                      </Typography>
                    </Box>

                    <Box sx={{ my: 1 }}>
                      <Box>
                        <Typography variant="title_medium">
                          Startup Size
                        </Typography>
                      </Box>
                      <Typography variant="bodyLarge">
                        {startupDetails.startUpSize}
                      </Typography>
                    </Box>
                    <Box sx={{ my: 1 }}>
                      <Box>
                        <Typography variant="title_medium">Founded</Typography>
                      </Box>
                      <Typography variant="bodyLarge">
                        {startupDetails.foundedYear}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          ) : (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              {" "}
              <CircularProgress />{" "}
            </Box>
          )}
        </Box>
      </Box>
    </Stack>
  );
}
