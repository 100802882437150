import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import services from "../../../services";
import { toastContainer } from "../../../utils/toast";
import messages from "../../../constants/messages";
import { CircularProgress, Box } from "@mui/material";
import ROUTES_PATH from "../../../constants/routes";

export default function VerifyEmail() {
  const location = useLocation();
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  useEffect(() => {
    if (location?.search) {
      setToken(location?.search?.substr(7));
    }
  }, [location]);

  useEffect(() => {
    console.log(token);
    if (token?.length > 0) {
      services
        .callbackChangeEmailRequest({ token: token })
        .then(res => {
          toastContainer(messages.EMAIL_ADDRESS_UPDATED);
          handleExit();
        })
        .catch(err => {
          toastContainer(messages.API_SUBMIT_ERROR, "error");
          console.log(err);
          handleExit();
        });
    }
  }, [token]);

  const handleExit = () => {
    setLoading(false);
    history.push(ROUTES_PATH.STARTUPS_HOME);
  };
  return (
    <>
      {loading && (
        <Box
          sx={{
            display: "flex",
            width: 1,
            height: 1,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress size={30} />
        </Box>
      )}
    </>
  );
}
