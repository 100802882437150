import React, { useState } from "react";
import { Grid, Typography, Box } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useHistory } from "react-router-dom";
import theme from "../../../../../constants/Theme";
import { toastContainer } from "../../../../../utils/toast";
import messages from "../../../../../constants/messages";
import ROUTES_PATH from "../../../../../constants/routes";
import ResearchCuratorCard from "../../../../../components/ResearchCuratorCard";

const ListView = ({
  listItems,
  handleCardClick,
  pageData,
  nextStep,
  prevStep,
  index,
  setIndex,
  setCallback,
}) => {
  const history = useHistory();

  return (
    <>
      <Grid container rowGap={2}>
        {/*<Grid
          container
          sx={{
            mt: 3,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            pt: 2,
          }}
        >
          <Grid item lg={8} md={8} sm={6} xs={6}>
            <Typography variant="title_medium">Pinned</Typography>{" "}
            <Typography variant="caption" sx={{ color: "secondary.dark" }}>
              {pageData?.totalElements > 0
                ? pageData.totalElements + " total"
                : ""}
            </Typography>
          </Grid>
          <Grid
            item
            lg={3}
            md={3}
            sm={6}
            xs={6}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Box sx={{ gap: theme.spacing(2), ml: { xs: "0", md: "auto" } }}>
              {index !== 0 ? (
                <ArrowBackIosIcon
                  onClick={prevStep}
                  sx={{ cursor: "pointer" }}
                />
              ) : (
                <ArrowBackIosIcon
                  onClick={prevStep}
                  sx={{ color: "secondary.light" }}
                />
              )}
              {index !== listItems.length - 1 ? (
                <ArrowForwardIosIcon
                  onClick={nextStep}
                  sx={{ cursor: "pointer" }}
                />
              ) : (
                <ArrowForwardIosIcon
                  onClick={nextStep}
                  sx={{ color: "secondary.light" }}
                />
              )}
            </Box>
          </Grid>
        </Grid>*/}

        {listItems?.slice(index, index + 1).map((item, idx) => (
          <Grid container key={idx} justifyContent="center">
            <Grid item md={5} lg={6} sm={12} xs={12}>
              <Grid
                container
                sx={{
                  mt: 3,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  pl: 1,
                  mb: 2,
                }}
              >
                <Grid item lg={8} md={8} sm={6} xs={6}>
                  <Typography variant="title_medium" mr={1}>Pinned</Typography>{" "}
                  <Typography
                    variant="caption"
                    sx={{ color: "secondary.dark" }}
                  >
                    {pageData?.totalElements > 0
                      ? pageData.totalElements + " total"
                      : ""}
                  </Typography>
                </Grid>
                <Grid
                  item
                  lg={3}
                  md={3}
                  sm={6}
                  xs={6}
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Box
                    sx={{ gap: theme.spacing(2), ml: { xs: "0", md: "auto" } }}
                  >
                    {index !== 0 ? (
                      <ArrowBackIosIcon
                        onClick={prevStep}
                        sx={{ cursor: "pointer" }}
                      />
                    ) : (
                      <ArrowBackIosIcon
                        onClick={prevStep}
                        sx={{ color: "secondary.light" }}
                      />
                    )}
                    {index !== listItems.length - 1 ? (
                      <ArrowForwardIosIcon
                        onClick={nextStep}
                        sx={{ cursor: "pointer" }}
                      />
                    ) : (
                      <ArrowForwardIosIcon
                        onClick={nextStep}
                        sx={{ color: "secondary.light" }}
                      />
                    )}
                  </Box>
                </Grid>
              </Grid>
              <ResearchCuratorCard
                cardData={item}
                handleCardClick={() => handleCardClick(item)}
                setCallback={setCallback}
              />
            </Grid>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default ListView;
