import React from "react";
import { Box, Typography } from "@mui/material";

const TamSamSomMUIGraph = ({ TamSamSomData }) => {
	return (
		<Box display="flex" justifyContent="center" alignItems="flex-end" height="380px">
			<Box sx={{ position: "relative" }}>
				<Box sx={circleStyle(380, "rgba(0, 0, 0, 1)")}>
					<Typography variant="Text/xs/Medium" style={labelStyle("#fff", "30px")}>
						TAM
					</Typography>
					<Typography variant="Text/xl/Medium" sx={labelStyle("#fff", "60px")}>
						{"$" +
							Intl.NumberFormat("en-US", {
								notation: "compact",
								maximumFractionDigits: 1,
							}).format(Number(TamSamSomData.tam))}
					</Typography>
				</Box>
				<Box sx={circleStyle(280, "rgba(222, 0, 133, 1)")}>
					<Typography variant="Text/xs/Medium" style={labelStyle("#fff", "30px")}>
						SAM
					</Typography>
					<Typography variant="Text/xl/Medium" sx={labelStyle("#fff", "60px")}>
						{"$" +
							Intl.NumberFormat("en-US", {
								notation: "compact",
								maximumFractionDigits: 1,
							}).format(Number(TamSamSomData.sam))}
					</Typography>
				</Box>
				<Box sx={circleStyle(170, "rgba(252, 206, 238, 1)")}>
					<Typography variant="Text/xs/Medium" style={labelStyle("rgba(0, 0, 0, 1)", "50px")}>
						SOM
					</Typography>
					<Typography variant="Text/xl/Medium" sx={labelStyle("rgba(0, 0, 0, 1)", "80px")}>
						{"$" +
							Intl.NumberFormat("en-US", {
								notation: "compact",
								maximumFractionDigits: 1,
							}).format(Number(TamSamSomData.som))}
					</Typography>
				</Box>
			</Box>
		</Box>
	);
};

const circleStyle = (size, color) => ({
	width: size + "px",
	height: size + "px",
	borderRadius: "50%",
	backgroundColor: color,
	position: "absolute",
	left: `calc(50% - ${size / 2}px)`,
	bottom: 0, // Updated line
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	border: "5px solid",
});
const labelStyle = (color, top) => ({
	position: "absolute",
	fontWeight: 500,
	top: top,
	color: color,
});

export default TamSamSomMUIGraph;
