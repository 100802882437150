export function displayVersion() {
  const { version } = require("./../../package.json");
  console.log(
    "%cStartup%cOS",
    `
    text-transform: uppercase;
    background: #7649a0;
    color: #FFF;
    font-weight: bold;
    padding: 5px 0px 5px 20px;
    text-shadow: 2px 2px 1px rgba(233,115,0, 0.5);
    font-size: 35px;
  `,
    `
  text-transform: uppercase;
  background: #7649a0;
  color: #FFF;
  font-weight: light;
  padding: 5px 20px 5px 0px;
  text-shadow: 2px 2px 1px rgba(233,115,0, 0.5);
  font-size: 35px;
  `
  );
  console.log(
    `%cVersion %c${version}`,
    `background: #7649a0;
    color: #FFF;
    padding: 2px 0px 2px 20px;
    font-size: 20px;
    `,
    `background: #7649a0;
    color: #FFF;
    padding: 2px 20px 2px 0px;
    text-decoration: underline;
    font-size: 20px;
    `
  );
}
