import React, { useMemo, useState, useEffect } from "react";
import { Controller } from "react-hook-form";
import { Box, Typography, TextField, Select, MenuItem, Checkbox, ListItemText, OutlinedInput, Grid, InputLabel, FormControl } from "@mui/material";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";

import { FOUNDER_GOAL_OPTIONS, MENTOR_GOAL_OPTIONS, INVESTOR_GOAL_OPTIONS } from "../../../Onboard/Components/Options";
import services from "../../../../services";
import { USER_TYPES } from "../../../../utils/userTypes";
import messages from "../../../../constants/messages";
import { StepTitle, Stepper } from "../ui";
import Icon from "../../Assets/onboarding-about.svg";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import AddressAutoCompleteField from "../../../../components/LocationAutoCompleteFields/AddressAutoCompleteField";
import CountryAutoComplete from "../../../../components/LocationAutoCompleteFields/CountryAutoCompleteField";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

export const About = ({ step, userType, totalStep, errors, control, getValues, goalErrorMsg, companyErrMsg, setValue }) => {
	const [currentCountry, setCurrentCountry] = useState("USA");
	const [countryStateData, setCountryStateData] = useState([]);
	const [goalOptions, setGoalOptions] = useState([]);

	useEffect(() => {
		getCountryStateData();
	}, []);

	console.log(`currentCountry`, currentCountry);
	useEffect(() => {
		if (userType === USER_TYPES.STARTUP_OWNER || userType === USER_TYPES.STARTUP_USER) {
			setGoalOptions(FOUNDER_GOAL_OPTIONS);
		} else if (userType === USER_TYPES.MENTOR_USER) {
			setGoalOptions(MENTOR_GOAL_OPTIONS);
		} else if (userType === USER_TYPES.INVESTOR_USER || userType === USER_TYPES.CORPORATE_USER) {
			setGoalOptions(INVESTOR_GOAL_OPTIONS);
		}
	}, [userType]);

	const getCountryStateData = async () => {
		const res = await services.getListOfCountryStateApi();
		setCountryStateData(res?.data?.data);
		setCurrentCountry(res?.data?.data[0]?.countryCode || "USA");
	};

	const handleCountry = (country, onChange) => {
		setCurrentCountry(country);
		onChange(country);
	};

	const handleGoal = (goal, onChange) => {
		if (userType === USER_TYPES.STARTUP_OWNER) {
			onChange([goal]);
		} else {
			onChange(goal);
		}
	};

	const getOptions = useMemo(() => {
		if (countryStateData.length > 0) {
			return countryStateData.filter((item) => item.countryCode === currentCountry)[0].states;
		}

		return [];
	}, [countryStateData, currentCountry]);

	const autoFillAddress = (values) => {
		if (values?.streetAddress) {
			setValue("address1", values?.streetAddress);
		}
		if (values?.city) {
			setValue("city", values?.city);
		}
		if (values?.state) {
			setValue("state", values?.state);
		}
		if (values?.zipcode) {
			setValue("zipcode", values?.zipcode);
		}
		if (values?.country_code) {
			setValue("country", values?.country_code);
		}
	};

	const countryChanged = (e) => {
		setValue("country", e?.code ?? "US", { shouldDirty: true });
		setValue("state", "");
		setValue("zipcode", "");
	};

	return (
		<Box width="100%">
			<Stepper step={step} totalStep={totalStep} />
			<StepTitle title="Tell us about yourself" icon={Icon} tooltip="" />
			<Box display="flex" justifyContent="center">
				<Grid
					container
					spacing="32px"
					sx={{
						maxWidth: "1100px",
					}}
				>
					{userType === USER_TYPES.CORPORATE_USER && (
						<Grid item xs={12} md={6}>
							<Controller
								name="companyName"
								control={control}
								render={({ field: { onChange, value, name } }) => (
									<TextField
										fullWidth
										id="companyName"
										label="Company name*"
										value={value}
										name={name}
										sx={{
											"& .MuiOutlinedInput-notchedOutline": {
												border: companyErrMsg ? "1px solid #F04438 !important" : "1px solid #DDDDDD",
											},
										}}
										InputProps={{ style: InputPropsStyle }}
										InputLabelProps={InputLabelStyle}
										onChange={onChange}
									/>
								)}
							/>
							{companyErrMsg && (
								<Box mt={1}>
									<Typography variant="subhead2" sx={{ color: "#F04438" }}>
										{companyErrMsg}
									</Typography>
								</Box>
							)}
						</Grid>
					)}
					{/* Joining Goal */}
					<Grid item xs={12} md={6}>
						<FormControl sx={{ width: "100%" }}>
							<InputLabel id="goals" sx={LabelStyle}>
								What is your goal in joining StartupOS?*
							</InputLabel>
							<Controller
								name="goals"
								control={control}
								render={({ field: { onChange, value, name } }) => (
									<Select
										labelId="goals"
										fullWidth
										multiple={userType === USER_TYPES.STARTUP_OWNER ? false : true}
										value={value}
										name={name}
										onChange={(e) => handleGoal(e.target.value, onChange)}
										renderValue={(selected) => (userType === USER_TYPES.STARTUP_OWNER ? selected : selected.join(", "))}
										IconComponent={SelectIconComponent}
										input={<OutlinedInput label="What is your goal in joining StartupOS?*" />}
										sx={SelectPropsStyle(goalErrorMsg)}
									>
										{goalOptions.map((option) => (
											<MenuItem key={option.label} value={option.label}>
												{userType !== USER_TYPES.STARTUP_OWNER && <Checkbox checked={value?.indexOf(option.label) > -1} />}
												<ListItemText primary={option.label} />
											</MenuItem>
										))}
									</Select>
								)}
							/>
							{goalErrorMsg && (
								<Box mt={1}>
									<Typography variant="subhead2" sx={{ color: "#F04438" }}>
										{goalErrorMsg}
									</Typography>
								</Box>
							)}
						</FormControl>
					</Grid>

					{/* LinkedinUrl */}
					<Grid item xs={12} md={6}>
						<Controller
							name="userLinkedinUrl"
							control={control}
							render={({ field: { onChange, value, name } }) => (
								<TextField
									fullWidth
									id="userLinkedinUrl"
									label="Your LinkedIn Profile URL"
									value={value}
									name={name}
									sx={{ "& .MuiOutlinedInput-notchedOutline": { borderColor: "#DDDDDD" } }}
									InputProps={{ style: InputPropsStyle }}
									InputLabelProps={InputLabelStyle}
									onChange={onChange}
								/>
							)}
						/>
						{errors.userLinkedinUrl && (
							<Box mt={1}>
								<Typography variant="subhead2" sx={{ color: "#F04438" }}>
									{errors.userLinkedinUrl.message}
								</Typography>
							</Box>
						)}
					</Grid>

					{/* Address 1 */}
					<Grid item xs={12} md={6}>
						<Controller
							name="address1"
							control={control}
							render={({ field: { onChange, value, name } }) => (
								<AddressAutoCompleteField
									onValueSet={(e) => autoFillAddress(e)}
									fieldLabel={"Street Address"}
									fieldPlaceholder={"Street Address"}
									parentValue={value}
									name={name}
								/>
							)}
						/>
						{errors.address1 && (
							<Box mt={1}>
								<Typography variant="subhead2" sx={{ color: "#F04438" }}>
									{errors.address1.message}
								</Typography>
							</Box>
						)}
					</Grid>

					{/* State */}
					<Grid item xs={12} md={6}>
						<FormControl sx={{ width: "100%" }}>
							<InputLabel id="state" sx={LabelStyle}>
								{getValues("country") && (getValues("country") == "US" || getValues("country") == "USA")
									? "State *"
									: getValues("country") && getValues("country") == "CA"
									? "Province *"
									: "Region *"}
							</InputLabel>
							<Controller
								name="state"
								control={control}
								render={({ field: { onChange, value, name } }) =>
									!getValues("country") || (getValues("country") && (getValues("country") == "US" || getValues("country") == "USA")) ? (
										<Select
											labelId="state"
											fullWidth
											value={value}
											name={name}
											onChange={onChange}
											IconComponent={SelectIconComponent}
											input={<OutlinedInput label="State/Province*" />}
											MenuProps={MenuProps}
											sx={SelectPropsStyle(errors?.state?.message)}
										>
											{getOptions.map((option) => (
												<MenuItem key={option.stateCode} value={option.stateCode}>
													{option.stateName}
												</MenuItem>
											))}
										</Select>
									) : (
										<TextField
											fullWidth
											value={value}
											name={name}
											sx={{ "& .MuiOutlinedInput-notchedOutline": { borderColor: "#DDDDDD" } }}
											InputProps={{ style: InputPropsStyle }}
											InputLabelProps={InputLabelStyle}
											onChange={onChange}
										/>
									)
								}
							/>
							{errors.state && (
								<Box mt={1}>
									<Typography variant="subhead2" sx={{ color: "#F04438" }}>
										{errors.state.message}
									</Typography>
								</Box>
							)}
						</FormControl>
					</Grid>

					{/* Address 2 */}
					<Grid item xs={12} md={6}>
						<Controller
							name="address2"
							control={control}
							render={({ field: { onChange, value, name } }) => (
								<TextField
									fullWidth
									id="address2"
									label="Apartment/Suite/Unit, District, Neighborhood, etc."
									value={value}
									name={name}
									sx={{ "& .MuiOutlinedInput-notchedOutline": { borderColor: "#DDDDDD" } }}
									InputProps={{ style: InputPropsStyle }}
									InputLabelProps={InputLabelStyle}
									onChange={onChange}
								/>
							)}
						/>
						{errors.address2 && (
							<Box mt={1}>
								<Typography variant="subhead2" sx={{ color: "#F04438" }}>
									{errors.address2.message}
								</Typography>
							</Box>
						)}
					</Grid>

					{/* ZipCode */}
					<Grid item xs={12} md={6}>
						<Controller
							name="zipcode"
							control={control}
							render={({ field: { onChange, value, name } }) => (
								<TextField
									fullWidth
									id="zipcode"
									label="Zip Code/Postal Code*"
									value={value.toUpperCase()}
									name={name}
									sx={{
										"& .MuiOutlinedInput-notchedOutline": {
											border: errors.zipcode ? "1px solid #F04438 !important" : "1px solid #DDDDDD",
										},
									}}
									InputProps={{ style: InputPropsStyle }}
									InputLabelProps={InputLabelStyle}
									onChange={onChange}
								/>
							)}
						/>
						{errors.zipcode && (
							<Box mt={1}>
								<Typography variant="subhead2" sx={{ color: "#F04438" }}>
									{getValues("zipcode") ? messages.LOCATION_INVALID : messages.ZIPCODE_BLANK}
								</Typography>
							</Box>
						)}
					</Grid>
					{/* Country */}
					<Grid item xs={12} md={6}>
						<FormControl sx={{ width: "100%" }}>
							<Controller
								name="country"
								control={control}
								render={({ field: { onChange, value, name } }) => (
									<CountryAutoComplete
										onValueSet={(e) => countryChanged(e)}
										fieldLabel={"Country"}
										fieldPlaceholder={"Country"}
										parentValue={value ?? "US"}
										name={name}
									/>
								)}
							/>
							{errors.country && (
								<Box mt={1}>
									<Typography variant="subhead2" sx={{ color: "#F04438" }}>
										{errors.country.message}
									</Typography>
								</Box>
							)}
						</FormControl>
					</Grid>

					{/* Phone number */}
					<Grid item xs={12} md={6}>
						<Controller
							name="phone"
							control={control}
							render={({ field: { onChange, value } }) => (
								<PhoneInput country={"us"} value={value} onChange={onChange} placeholder="Phone Number" />
							)}
						/>
						{errors.phone && (
							<Box mt={1}>
								<Typography variant="subhead2" sx={{ color: "#F04438" }}>
									{errors.phone.message}
								</Typography>
							</Box>
						)}
					</Grid>

					{/* City */}
					<Grid item xs={12} md={6}>
						<Controller
							name="city"
							control={control}
							render={({ field: { onChange, value, name } }) => (
								<TextField
									spellCheck="true"
									fullWidth
									value={value}
									name={name}
									label={"City*"}
									onChange={onChange}
									InputProps={{ style: InputPropsStyle }}
									InputLabelProps={InputLabelStyle}
									sx={{
										"& .MuiOutlinedInput-notchedOutline": {
											border: errors.city ? "1px solid #F04438 !important" : "1px solid #DDDDDD",
										},
									}}
								/>
							)}
						/>
						{errors.city && (
							<Box mt={1}>
								<Typography variant="subhead2" sx={{ color: "#F04438" }}>
									{errors.city.message}
								</Typography>
							</Box>
						)}
					</Grid>
				</Grid>
			</Box>
		</Box>
	);
};

export const SelectIconComponent = () => (
	<KeyboardArrowDownRoundedIcon
		sx={{
			position: "absolute",
			right: "16px",
			top: "calc(50% - 0.5em)",
			color: "rgba(0, 0, 0, 0.54)",
			pointerEvents: "none",
		}}
	/>
);

export const InputPropsStyle = {
	borderRadius: "30px",
	height: "60px",
	fontWeight: "500",
	fontSize: "16px",
	lineHeight: "24px",
	padding: "1.5px 6px",
	fontFamily: '"PoppinsRegular", "Helvetica", "Arial", sans-serif',
	background: "#fff",
	boxShadow: "0px 10px 25px 0px #0000000D",
};

export const InputLabelStyle = {
	style: {
		fontFamily: '"PoppinsRegular", "Helvetica", "Arial", sans-serif',
		color: "#667085",
		zIndex: 9,
		padding: "2px 0 0 4px",
	},
};

export const LabelStyle = {
	fontFamily: '"PoppinsRegular", "Helvetica", "Arial", sans-serif !important',
	color: "#667085",
	fontSize: "16px  !important",
	padding: "2px 0 0 4px",
	zIndex: 9,
};

export const SelectPropsStyle = (error = false) => {
	return {
		borderRadius: "40px",
		padding: "1.5px 6px",
		background: "#fff",
		boxShadow: "0px 10px 25px 0px #0000000D",
		"& .MuiOutlinedInput-notchedOutline": { borderColor: error ? "#F04438" : "#DDDDDD" },
		"& .MuiSelect-select": { fontWeight: "500", fontSize: "16px", lineHeight: "24px" },
	};
};
