import React from "react";
import { Typography, Box } from "@mui/material";
import { EventCard, IconBox } from "./styles";
import Icon from "@mui/material/Icon";
import { sosPrimary } from "../../constants/Theme/colors";
import theme from "../../constants/Theme";

export default function EventsContainer({
  heading,
  customIcon,
  title,
  variantType,
  subText,
  titleVariant,
  margin,
  height = "auto",
}) {
  return (
    <EventCard
      variant={variantType}
      sx={{ m: margin, minHeight: height, display: "grid" }}
    >
      <Typography variant={titleVariant}>{heading}</Typography>

      <IconBox>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Icon
            sx={{
              borderRadius: "50%",
              p: 2,
              color: sosPrimary[500],
            }}
          >
            {customIcon}
          </Icon>
        </Box>

        <Box>
          <Typography
            variant="lable_large"
            align="center"
            sx={{
              display: "block",
              pt: 3,
              color: theme.palette.text.onSurfaceVariant,
            }}
          >
            {title}
          </Typography>
        </Box>
        <Box>
          <Typography
            variant="caption"
            align="center"
            sx={{
              display: "block",
              pt: 1,
              color: theme.palette.text.onSurfaceVariant,
            }}
          >
            {subText}
          </Typography>
        </Box>
      </IconBox>
    </EventCard>
  );
}
