import styled from "@emotion/styled";

export const PageWrapper = styled.div(({ theme }) => {
  return {
    minHeight: "100vh",
    display: "grid",
    gridTemplateRows: "auto 1fr auto",
    //background: "var(--home-blue-background)",
  };
});
export const HeaderArea = styled.div(({ theme }) => {
  return {
    [theme.breakpoints.down("md")]: {
      //background: "var(--home-blue-background)",
    },
  };
});
export const MainArea = styled.div(({ theme }) => {
  return {
    // [theme.breakpoints.down('md')]: {
    //   background: 'var(--home-blue-background)',
    // },
  };
});
