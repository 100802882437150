import React from "react";

const SemiCircleProgress = ({ maxValue, progressValue }) => {
	const radius = 80;
	const strokeWidth = 5;
	const centerX = radius + strokeWidth / 2;
	const centerY = radius + strokeWidth / 2;
	const circumference = Math.PI * radius;

	const progressPercentage = (progressValue / maxValue) * 100;

	let color;
	if (300 <= progressValue && progressValue <= 500) {
		color = "#F04438";
	} else if (451 <= progressValue && progressValue <= 800) {
		color = "#F79009";
	} else if (801 <= progressValue && progressValue <= 1000) {
		color = "#12B76A";
	}

	const progressDashOffset = ((100 - progressPercentage) / 100) * circumference;

	return (
		<svg width={2.2 * radius} height={radius} viewBox={`0 0 ${2 * radius} ${radius}`}>
			<circle cx={centerX} cy={centerY} r={radius} fill="none" strokeWidth={strokeWidth} stroke="#ccc" />
			<circle
				cx={centerX}
				cy={centerY}
				r={radius}
				fill="none"
				strokeWidth={strokeWidth}
				stroke={color}
				strokeDasharray={`${circumference} ${circumference}`}
				strokeDashoffset={progressDashOffset}
				strokeLinecap="round"
				transform={`rotate(180 ${centerX} ${centerY})`}
			/>
			<text x={centerX} y={60} textAnchor="middle" dy=".3em" fontSize="48" fill="#000">
				{progressValue}
			</text>
		</svg>
	);
};

export default SemiCircleProgress;
