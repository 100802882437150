import React, { useState } from "react";
import { Box, Typography, TextField } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { BtnLogin, FormElementStyle } from "./SignUp";
import { toastContainer } from "../../../../utils/toast";
import { saveUserEmail, saveVerificationType } from "../../../../utils/sessionStorage";
import { passwordReset } from "../../../../utils/auth0";
import messages from "../../../../constants/messages";

const schema = yup
	.object({
		email: yup.string().email().required("Email is required"),
	})
	.required();

export const ForgotPwd = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [step, setStep] = useState(0);

	const {
		handleSubmit,
		control,
		formState: { errors, isValid },
	} = useForm({
		mode: "all",
		defaultValues: {
			email: "",
		},
		resolver: yupResolver(schema),
	});

	const onSubmit = (formData) => {
		try {
			setIsLoading(true);
			const { email } = formData;
			saveUserEmail(email);

			//call auth0 /change-password api and send the reset-password email to user
			passwordReset({ email })
				.then((data) => {
					setIsLoading(false);
					setStep(1);
					saveVerificationType("forgotPassword");
					toastContainer(messages.RESET_PASSWORD, "success");
				})
				.catch((err) => {
					toastContainer(err?.error_description, "error");
				});
		} catch (err) {
			setIsLoading(false);
			toastContainer(err?.error_description, "error");
		}
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Box width="400px" alignItems="center" display="flex" flexDirection="column" gap={4}>
				<Typography variant="growth_otherpercentage_text" textAlign="center">
					{step === 0 ? `Forgot your password?` : "Thanks! Go ahead and check your email."}
				</Typography>
				<Typography variant="Text/sm/Regular" textAlign="center">
					{step === 0
						? `Enter your email address and we will send you instructions to reset your password.`
						: `If your email is registered with us, you will get an email at johndoe@startupos.com for instructions to reset your password. If you haven’t recevied anything in 10 minutes, please try resending.`}
				</Typography>
				{step === 0 && (
					<Box width="100%">
						<Controller
							name="email"
							control={control}
							render={({ field: { onChange, value, name } }) => (
								<TextField
									fullWidth
									id="email"
									placeholder="Email Address"
									value={value}
									name={name}
									sx={{
										"& .MuiOutlinedInput-notchedOutline": {
											border: errors.email ? "1px solid #F04438 !important" : "1px solid #DDDDDD",
										},
									}}
									InputProps={{ style: FormElementStyle }}
									onChange={onChange}
								/>
							)}
						/>
						{errors.email && (
							<Typography variant="subhead2" sx={{ color: "#F04438" }}>
								{errors.email.message}
							</Typography>
						)}
					</Box>
				)}

				<BtnLogin fullWidth type="submit" variant="contained" disabled={!isValid} loading={isLoading}>
					{step === 0 ? `Continue` : `Resend Email`}
				</BtnLogin>
			</Box>
		</form>
	);
};
