import { Stack, Typography, Box, Avatar } from "@mui/material";
import React from "react";
import { CardStreaks } from "../styles";

function Streaks({ icon, title, color, subtitle, value = 0 }) {
	return (
		<Box sx={{ position: "relative", width: 1, display: "flex" }}>
			<CardStreaks sx={{ width: 1 }}>
				<Stack sx={{ mb: 1 }}>
					<Box component={"img"} src={icon} sx={{ width: "42px", height: "42px" }} alt="icon" />
					<Typography variant="headline5" color={color}>
						{title}
					</Typography>
					<Typography variant="lable_large" color="secondary.black">
						{subtitle}
					</Typography>
				</Stack>
				<Avatar
					sx={{
						borderRadius: "50%",
						width: "100px",
						height: "100px",
						position: "absolute",
						bottom: -40,
						right: 25,
						bgcolor: color,
					}}
				>
					<Typography variant="StartupOS/headline/headline1" color="secondary.white">
						{`${value}`}
					</Typography>
				</Avatar>
			</CardStreaks>
		</Box>
	);
}

export default Streaks;
