import React, { Suspense, useContext, useEffect } from "react";
import { Box } from "@mui/material";

import { PowerUPContext } from "../../PowerUPController";
import Expectations from "./Expectations";
import Contents from "./Contents";
import KnowledgeCard from "./KnowledgeCard";
import useDimensions from "react-cool-dimensions";
import { Harness } from "./PitchDeckGenerator/md-pitchdeck-generator";

const FounderStoryExpectations = React.lazy(() => import("./FounderStory/md-story").then((module) => ({ default: module.Expectations })));
const FounderStoryContents = React.lazy(() => import("./FounderStory/md-story").then((module) => ({ default: module.Contents })));
const FounderStoryImportanceForStartup = React.lazy(() => import("./FounderStory/md-story").then((module) => ({ default: module.ImportanceForStartup })));

const PersonaBuilderExpectations = React.lazy(() => import("./PersonaBuilder/md-persona").then((module) => ({ default: module.Expectations })));
const PersonaBuilderContents = React.lazy(() => import("./PersonaBuilder/md-persona").then((module) => ({ default: module.Contents })));
const PersonaBuilderImportanceForStartup = React.lazy(() => import("./PersonaBuilder/md-persona").then((module) => ({ default: module.ImportanceForStartup })));

const ElevatorPitchExpectations = React.lazy(() => import("./ElevatorPitch/md-elevator-pitch").then((module) => ({ default: module.Expectations })));
const ElevatorPitchContents = React.lazy(() => import("./ElevatorPitch/md-elevator-pitch").then((module) => ({ default: module.Contents })));
const ElevatorPitchImportanceForStartup = React.lazy(() =>
	import("./ElevatorPitch/md-elevator-pitch").then((module) => ({ default: module.ImportanceForStartup }))
);

const ValuePropExpectations = React.lazy(() => import("./ValueProp/md-value-prop").then((module) => ({ default: module.Expectations })));
const ValuePropContents = React.lazy(() => import("./ValueProp/md-value-prop").then((module) => ({ default: module.Contents })));
const ValuePropImportanceForStartup = React.lazy(() => import("./ValueProp/md-value-prop").then((module) => ({ default: module.ImportanceForStartup })));

const LeanCanvasExpectations = React.lazy(() => import("./LeanCanvas/md-lean-canvas").then((module) => ({ default: module.Expectations })));
const LeanCanvasContents = React.lazy(() => import("./LeanCanvas/md-lean-canvas").then((module) => ({ default: module.Contents })));
const LeanCanvasImportanceForStartup = React.lazy(() => import("./LeanCanvas/md-lean-canvas").then((module) => ({ default: module.ImportanceForStartup })));

const ExitStrategyExpectations = React.lazy(() => import("./ExitStrategy/md-exit-strategy").then((module) => ({ default: module.Expectations })));
const ExitStrategyContents = React.lazy(() => import("./ExitStrategy/md-exit-strategy").then((module) => ({ default: module.Contents })));
const ExitStrategyImportanceForStartup = React.lazy(() =>
	import("./ExitStrategy/md-exit-strategy").then((module) => ({ default: module.ImportanceForStartup }))
);

const AreYouFundableExpectations = React.lazy(() => import("./AreYouFundable/md-are-you-fundable").then((module) => ({ default: module.Expectations })));
const AreYouFundableContents = React.lazy(() => import("./AreYouFundable/md-are-you-fundable").then((module) => ({ default: module.Contents })));

const DiscoveryInterviewExpectations = React.lazy(() =>
	import("./DiscoveryInterview/md-discovery-interview").then((module) => ({ default: module.Expectations }))
);
const DiscoveryInterviewContents = React.lazy(() => import("./DiscoveryInterview/md-discovery-interview").then((module) => ({ default: module.Contents })));
const DiscoveryInterviewImportanceForStartup = React.lazy(() =>
	import("./DiscoveryInterview/md-discovery-interview").then((module) => ({ default: module.ImportanceForStartup }))
);

const MoatExpectations = React.lazy(() => import("./Moat/md-moat").then((module) => ({ default: module.Expectations })));
const MoatContents = React.lazy(() => import("./Moat/md-moat").then((module) => ({ default: module.Contents })));
const MoatImportanceForStartup = React.lazy(() => import("./Moat/md-moat").then((module) => ({ default: module.ImportanceForStartup })));

const ProblemSolutionFitExpectations = React.lazy(() => import("./ProblemSolutionFit/md-prob-sol").then((module) => ({ default: module.Expectations })));
const ProblemSolutionFitContents = React.lazy(() => import("./ProblemSolutionFit/md-prob-sol").then((module) => ({ default: module.Contents })));
const ProblemSolutionFitImportanceForStartup = React.lazy(() =>
	import("./ProblemSolutionFit/md-prob-sol").then((module) => ({ default: module.ImportanceForStartup }))
);

const SaaSPricingExpectations = React.lazy(() => import("./SaaSPricingModeler/md-pricing-model-saas").then((module) => ({ default: module.Expectations })));
const SaaSPricingContents = React.lazy(() => import("./SaaSPricingModeler/md-pricing-model-saas").then((module) => ({ default: module.Contents })));
const SaaSPricingModelerImportanceForStartup = React.lazy(() =>
	import("./SaaSPricingModeler/md-pricing-model-saas").then((module) => ({ default: module.ImportanceForStartup }))
);

const TamSamSomExpectations = React.lazy(() => import("./TamSamSom/md-tam").then((module) => ({ default: module.Expectations })));
const TamSamSomContents = React.lazy(() => import("./TamSamSom/md-tam").then((module) => ({ default: module.Contents })));
const TamSamSomImportanceForStartup = React.lazy(() => import("./TamSamSom/md-tam").then((module) => ({ default: module.ImportanceForStartup })));

const MVPPrioritizerExpectations = React.lazy(() => import("./MVPPrioritizer/md-mv-prioritizer").then((module) => ({ default: module.Expectations })));
const MVPPrioritizerContents = React.lazy(() => import("./MVPPrioritizer/md-mv-prioritizer").then((module) => ({ default: module.Contents })));
const MVPPrioritizerImportanceForStartup = React.lazy(() =>
	import("./MVPPrioritizer/md-mv-prioritizer").then((module) => ({ default: module.ImportanceForStartup }))
);
const MVPPrioritizerStartupDescription = React.lazy(() =>
	import("./MVPPrioritizer/md-mv-prioritizer").then((module) => ({ default: module.StartupDescription }))
);

const RetailPricingExpectations = React.lazy(() =>
	import("./RetailPricingModeler/md-retail-pricing-modeler").then((module) => ({ default: module.Expectations }))
);
const RetailPricingContents = React.lazy(() => import("./RetailPricingModeler/md-retail-pricing-modeler").then((module) => ({ default: module.Contents })));
const RetailPricingModelerImportanceForStartup = React.lazy(() =>
	import("./RetailPricingModeler/md-retail-pricing-modeler").then((module) => ({ default: module.ImportanceForStartup }))
);

const ServicesPricingExpectations = React.lazy(() =>
	import("./ServicesPricingModeler/md-services-pricing-modeler").then((module) => ({ default: module.Expectations }))
);
const ServicesPricingContents = React.lazy(() =>
	import("./ServicesPricingModeler/md-services-pricing-modeler").then((module) => ({ default: module.Contents }))
);
const ServicesPricingModelerImportanceForStartup = React.lazy(() =>
	import("./ServicesPricingModeler/md-services-pricing-modeler").then((module) => ({ default: module.ImportanceForStartup }))
);

const HardwarePricingExpectations = React.lazy(() =>
	import("./HardwarePricingModeler/md-hardware-pricing-modeler").then((module) => ({ default: module.Expectations }))
);
const HardwarePricingContents = React.lazy(() =>
	import("./HardwarePricingModeler/md-hardware-pricing-modeler").then((module) => ({ default: module.Contents }))
);
const HardwarePricingModelerImportanceForStartup = React.lazy(() =>
	import("./HardwarePricingModeler/md-hardware-pricing-modeler").then((module) => ({ default: module.ImportanceForStartup }))
);

const PitchDeckGeneratorExpectations = React.lazy(() =>
	import("./PitchDeckGenerator/md-pitchdeck-generator").then((module) => ({ default: module.Expectations }))
);
const PitchDeckGeneratorContents = React.lazy(() => import("./PitchDeckGenerator/md-pitchdeck-generator").then((module) => ({ default: module.Contents })));
const PitchDeckGeneratorImportanceForStartup = React.lazy(() =>
	import("./PitchDeckGenerator/md-pitchdeck-generator").then((module) => ({ default: module.ImportanceForStartup }))
);

const PitchDeckGeneratorHarness = React.lazy(() => import("./PitchDeckGenerator/md-pitchdeck-generator").then((module) => ({ default: module.Harness })));

const AreYouEnterpriseExpectations = React.lazy(() => import("./AreYouEnterprise/md-are-you-enterprise").then((module) => ({ default: module.Expectations })));
const AreYouEnterpriseContents = React.lazy(() => import("./AreYouEnterprise/md-are-you-enterprise").then((module) => ({ default: module.Contents })));

//const TemplateExpectations = React.lazy(() => import("./PowerUpTemplate/md-powerup-template-module").then((module) => ({ default: module.Expectations })));
//const TemplateContents = React.lazy(() => import("./PowerUpTemplate/md-powerup-template-module").then((module) => ({ default: module.Contents })));
//const TemplateImportanceForStartup = React.lazy(() => import("./PowerUpTemplate/md-powerup-template-module").then((module) => ({ default: module.ImportanceForStartup })));

function LearnIt() {
	const { powerupDetails, moduleSteps } = useContext(PowerUPContext);
	const { observe, width } = useDimensions({
		onResize: ({ width }) => {},
	});
	useEffect(() => {
		observe();
	}, [observe]);

	const getLearnItComponents = () => {
		const currentModuleID = powerupDetails?.modules?.[0]?.moduleUuid;
		if (!currentModuleID) return null; // TODO -- Need "sorry was unable to load at this time. Message."

		switch (currentModuleID) {
			case "md-story":
				return (
					<React.Fragment>
						<FounderStoryExpectations />
						<FounderStoryContents />
					</React.Fragment>
				);
			case "md-persona":
				return (
					<React.Fragment>
						<PersonaBuilderExpectations />
						<PersonaBuilderContents />
					</React.Fragment>
				);
			case "md-elevator-pitch":
				return (
					<React.Fragment>
						<ElevatorPitchExpectations />
						<ElevatorPitchContents />
					</React.Fragment>
				);
			case "md-value-prop":
				return (
					<React.Fragment>
						<ValuePropExpectations />
						<ValuePropContents />
					</React.Fragment>
				);
			case "md-lean-canvas":
				return (
					<React.Fragment>
						<LeanCanvasExpectations />
						<LeanCanvasContents />
					</React.Fragment>
				);
			case "md-exit-strategy":
				return (
					<React.Fragment>
						<ExitStrategyExpectations />
						<ExitStrategyContents />
					</React.Fragment>
				);
			case "md-are-you-fundable":
				return (
					<React.Fragment>
						<AreYouFundableExpectations />
						<AreYouFundableContents />
					</React.Fragment>
				);
			case "md-enterprise-ready":
				return (
					<React.Fragment>
						<AreYouEnterpriseExpectations />
						<AreYouEnterpriseContents />
					</React.Fragment>
				);
			case "md-discovery-interview":
				return (
					<React.Fragment>
						<DiscoveryInterviewExpectations />
						<DiscoveryInterviewContents />
					</React.Fragment>
				);
			case "md-moat":
				return (
					<React.Fragment>
						<MoatExpectations />
						<MoatContents />
					</React.Fragment>
				);
			case "md-prob-sol":
				return (
					<React.Fragment>
						<ProblemSolutionFitExpectations />
						<ProblemSolutionFitContents />
					</React.Fragment>
				);

			case "md-pricing-model-saas":
				return (
					<React.Fragment>
						<SaaSPricingExpectations />
						<SaaSPricingContents />
					</React.Fragment>
				);
			case "md-tam":
				return (
					<React.Fragment>
						<TamSamSomExpectations />
					</React.Fragment>
				);
			case "md-mv-prioritizer":
				return (
					<React.Fragment>
						<MVPPrioritizerExpectations />
						<MVPPrioritizerContents />
					</React.Fragment>
				);
			case "md-retail-pricing-modeler":
				return (
					<React.Fragment>
						<RetailPricingExpectations />
						<RetailPricingContents />
					</React.Fragment>
				);
			case "md-services-pricing-modeler":
				return (
					<React.Fragment>
						<ServicesPricingExpectations />
						<ServicesPricingContents />
					</React.Fragment>
				);
			case "md-hardware-pricing-modeler":
				return (
					<React.Fragment>
						<HardwarePricingExpectations />
						<HardwarePricingContents />
					</React.Fragment>
				);
			case "md-pitchdeck-generator":
				return (
					<React.Fragment>
						<PitchDeckGeneratorExpectations />
						<PitchDeckGeneratorContents />
					</React.Fragment>
				);

			//case "md-powerup-template-module":
			//	return (
			//		<React.Fragment>
			//			<TemplateExpectations />
			//			<TemplateContents />
			//		</React.Fragment>
			//	);
			default:
				return null; // TODO -- Need "sorry was unable to load at this time. Message."
		}
	};

	const getImportanceForStartupComponents = () => {
		const currentModuleID = powerupDetails?.modules?.[0]?.moduleUuid;
		if (!currentModuleID) return null; // TODO -- Need "sorry was unable to load at this time. Message."

		switch (currentModuleID) {
			case "md-exit-strategy":
				return (
					<Suspense fallback={<Box>Loading...</Box>}>
						<ExitStrategyImportanceForStartup />
					</Suspense>
				);
			case "md-discovery-interview":
				return (
					<Suspense fallback={<Box>Loading...</Box>}>
						<DiscoveryInterviewImportanceForStartup />
					</Suspense>
				);
			case "md-moat":
				return (
					<Suspense fallback={<Box>Loading...</Box>}>
						<MoatImportanceForStartup />
					</Suspense>
				);
			case "md-prob-sol":
				return (
					<Suspense fallback={<Box>Loading...</Box>}>
						<ProblemSolutionFitImportanceForStartup />
					</Suspense>
				);
			case "md-pricing-model-saas":
				return (
					<Suspense fallback={<Box>Loading...</Box>}>
						<SaaSPricingModelerImportanceForStartup />
					</Suspense>
				);

			case "md-story":
				return (
					<Suspense fallback={<Box>Loading...</Box>}>
						<FounderStoryImportanceForStartup />
					</Suspense>
				);
			case "md-persona":
				return (
					<Suspense fallback={<Box>Loading...</Box>}>
						<PersonaBuilderImportanceForStartup />
					</Suspense>
				);
			case "md-elevator-pitch":
				return (
					<Suspense fallback={<Box>Loading...</Box>}>
						<ElevatorPitchImportanceForStartup />
					</Suspense>
				);
			case "md-lean-canvas":
				return (
					<Suspense fallback={<Box>Loading...</Box>}>
						<LeanCanvasImportanceForStartup />
					</Suspense>
				);
			case "md-value-prop":
				return (
					<Suspense fallback={<Box>Loading...</Box>}>
						<ValuePropImportanceForStartup />
					</Suspense>
				);
			case "md-tam":
				return (
					<Suspense fallback={<Box>Loading...</Box>}>
						<TamSamSomImportanceForStartup />
					</Suspense>
				);
			case "md-mv-prioritizer":
				return (
					<Suspense fallback={<Box>Loading...</Box>}>
						<MVPPrioritizerImportanceForStartup />
						<MVPPrioritizerStartupDescription />
					</Suspense>
				);
			case "md-retail-pricing-modeler":
				return (
					<Suspense fallback={<Box>Loading...</Box>}>
						<RetailPricingModelerImportanceForStartup />
					</Suspense>
				);
			case "md-services-pricing-modeler":
				return (
					<Suspense fallback={<Box>Loading...</Box>}>
						<ServicesPricingModelerImportanceForStartup />
					</Suspense>
				);
			case "md-hardware-pricing-modeler":
				return (
					<Suspense fallback={<Box>Loading...</Box>}>
						<HardwarePricingModelerImportanceForStartup />
					</Suspense>
				);
			case "md-pitchdeck-generator":
				return (
					<Suspense fallback={<Box>Loading...</Box>}>
						<PitchDeckGeneratorImportanceForStartup />
					</Suspense>
				);
			//case "md-powerup-template-module":
			//	return (
			//			<TemplateImportanceForStartup />
			//	);
			default:
				return null; // TODO -- Need "sorry was unable to load at this time. Message."
		}
	};

	return (
		<Box ref={observe} sx={{ display: "flex", alignItems: "flex-start", gap: "1.875rem", alignSelf: "stretch", flexWrap: "wrap" }}>
			<Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "1.875rem", flex: 1 }}>
				<Suspense fallback={<Box>Loading...</Box>}>{getLearnItComponents()}</Suspense>
			</Box>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "flex-start",
					gap: "1.875rem",
					...(width > 1000
						? {
								width: "30rem",
								maxWidth: "50%",
						  }
						: {
								width: "100%",
						  }),
				}}
			>
				{powerupDetails && <KnowledgeCard />}
				{getImportanceForStartupComponents()}
			</Box>
		</Box>
	);
}

export default LearnIt;
