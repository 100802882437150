import React, { useEffect, useState, useRef } from "react";
import { Scatter } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Box, useMediaQuery } from "@mui/material";
import SummaryExport from "../../common/SummaryExport";

ChartJS.register(
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  annotationPlugin,
  ChartDataLabels
);
export default function RiceMatrixGraph({
  matrixData,
  graphFilterValue,
  hideBackButton,
  graphRef,
}) {
  const [matrixFilteredData, setMatrixFilteredData] = useState([]);
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("md"));

  const data = {
    labels: ["Scatter"],
    datasets: [
      //It contains co-ordinates setting
      {
        label: "My First dataset",
        fill: false,
        backgroundColor: "rgba(75,192,192,0.4)",
        color: "#0A141B",
        pointBackgroundColor: "gray",
        pointBorderWidth: 2,
        pointHoverRadius: 9,
        pointBorderColor: "white",
        pointHoverBorderWidth: 0,
        pointRadius: 8,
        pointHitRadius: 10,

        data: matrixFilteredData,
        // [
        //  { x: 0, y: 3, label: "label 2" },
        //  { x: 30, y: 40, label: "label 3" },

        //  { x: 56, y: 36, label: "label 5" },

        //  { x: 40, y: 80, label: "label 7" },
        //],
      },
    ],
  };
  const options = {
    //width: 200,
    //height: 200,

    legend: {
      display: false,
    },
    scales: {
      y: {
        stacked: false,
        title: {
          text: "IMPACT",
          display: true,
          color: "#0A141B",
          font: {
            size: 18,
            //color: "#0A141B",
          },
        },
        //ticks: {
        //  display: false,
        //},
        grid: {
          display: false,
        },
        min: -10,

        min: -0.4,
        max: 4,
        ticks: {
          display: false,
          // forces step size to be 50 units
          stepSize: 0.5,
        },
      },
      xAxis: {
        stacked: false,
        title: {
          text: "EFFORT",
          color: "#0A141B",
          display: true,
          font: {
            size: 18,
          },
        },
        //ticks: {
        //  display: false,
        //},
        grid: {
          display: false,
        },
        //min: -2.5,

        min: -5000,
        max: 102000,
        ticks: {
          display: false,
          // forces step size to be 50 units
          stepSize: 10000,
        },
      },
    },
    events: ["click"],
    plugins: {
      datalabels: {
        align: "bottom",
        anchor: "center",
        color: "black",
        padding: { top: 20 },
      },
      legend: {
        display: false,
      },
      autocolors: false,
      annotation: {
        annotations: {
          box1: {
            drawTime: "beforeDraw",
            type: "box",
            xMin: -100,
            xMax: 50000,
            yMin: 0,
            yMax: 2,
            backgroundColor: "#EEF0F4",
            borderWidth: 0,
          },
          box2: {
            drawTime: "beforeDraw",
            type: "box",
            xMin: 50000,
            xMax: 102000,
            yMin: 0,
            yMax: 2,
            backgroundColor: "#FFB4C1",
            borderWidth: 0,
          },
          box3: {
            drawTime: "beforeDraw",
            type: "box",
            xMin: -100,
            xMax: 50000,
            yMin: 2,
            yMax: 4,
            backgroundColor: "#D3F0DF",
            borderWidth: 0,
          },
          box4: {
            drawTime: "beforeDraw",
            type: "box",
            xMin: 50000,
            xMax: 102000,
            yMin: 2,
            yMax: 4,
            backgroundColor: "#EEF0F4",
            borderWidth: 0,
          },
          //label1: {
          //  type: "label",
          //  xValue: 2500,
          //  yValue: 0.15,
          //  backgroundColor: "white",
          //  color: "#0A141B",
          //  content: ["INCREMENTAL"],
          //  font: {
          //    size: 14,
          //  },
          //},
          //label2: {
          //  type: "label",
          //  xValue: 2500,
          //  yValue: 3.85,
          //  backgroundColor: "white",
          //  color: "#0A141B",
          //  content: ["EASY WINS"],
          //  height: "500",
          //  font: {
          //    size: 14,
          //  },
          //},
          //label3: {
          //  type: "label",
          //  xValue: 7500,
          //  yValue: 0.15,
          //  backgroundColor: "white",
          //  content: ["MONEY PIT"],
          //  color: "#0A141B",
          //  font: {
          //    size: 14,
          //  },
          //},
          //label4: {
          //  type: "label",
          //  xValue: 7500,
          //  yValue: 3.85,
          //  backgroundColor: "white",
          //  content: ["BIG BETS"],
          //  height: "500",
          //  color: "#0A141B",
          //  font: {
          //    size: 14,
          //  },
          //},
          label1: {
            type: "label",
            xValue: 25000,
            yValue: 1,
            //backgroundColor: "white",
            color: "rgba(10, 20, 27, 0.25)",
            content: ["INCREMENTAL"],
            font: {
              size: isSmallScreen ? 15 : 24,
              weight: 600,
            },
          },
          label2: {
            type: "label",
            xValue: 25000,
            yValue: 3,
            //backgroundColor: "white",
            color: "rgba(10, 20, 27, 0.25)",
            content: ["EASY WINS"],
            height: "500",
            font: {
              size: isSmallScreen ? 15 : 24,
              weight: 600,
            },
          },
          label3: {
            type: "label",
            xValue: 75000,
            yValue: 1,
            //backgroundColor: "white",
            content: ["MONEY PIT"],
            color: "rgba(10, 20, 27, 0.25)",
            font: {
              size: isSmallScreen ? 15 : 24,
              weight: 600,
            },
          },
          label4: {
            type: "label",
            xValue: 75000,
            yValue: 3,
            //backgroundColor: "white",
            content: ["BIG BETS"],
            height: "500",
            color: "rgba(10, 20, 27, 0.25)",
            font: {
              size: isSmallScreen ? 15 : 24,
              weight: 600,
            },
          },
          high1: {
            type: "label",
            xValue: -3000,
            yValue: 3,
            backgroundColor: isSmallScreen ? "transparent" : "white",
            content: ["High"],
            rotation: "-90",
            font: {
              size: isSmallScreen ? 10 : 15,
            },
          },
          low1: {
            type: "label",
            xValue: -3000,
            yValue: 1,
            backgroundColor: isSmallScreen ? "transparent" : "white",
            content: ["Low"],
            rotation: "-90",
            font: {
              size: isSmallScreen ? 10 : 15,
            },
          },
          low2: {
            type: "label",
            xValue: 25000,
            yValue: -0.17,
            backgroundColor: isSmallScreen ? "transparent" : "white",
            content: ["Low"],
            font: {
              size: isSmallScreen ? 10 : 15,
            },
          },
          high2: {
            type: "label",
            xValue: 75000,
            yValue: -0.17,
            backgroundColor: isSmallScreen ? "transparent" : "white",
            content: ["High"],
            font: {
              size: isSmallScreen ? 10 : 15,
            },
          },
        },
      },
    },
  };

  useEffect(() => {
    matrixData.map(matrixItem => {
      matrixItem.x = matrixItem.effort;
      matrixItem.y = matrixItem.impact;
      matrixItem.label = matrixItem.featureName;
      return matrixItem;
    });
    let filterValues = [];
    if (graphFilterValue === "Approved") {
      filterValues = matrixData.filter(filterItem => filterItem.approve);
    } else {
      filterValues = matrixData;
    }
    setMatrixFilteredData(filterValues);
  }, [matrixData, graphFilterValue]);

  return (
    <>
      <Box
        sx={{
          cursor: "pointer",

          //height: "fit-content",
          margin: "auto",
        }}
        id="summaryPage"
      >
        <Scatter data={data} options={options} />
      </Box>
      <Box>
        <SummaryExport ref={graphRef} name={"Matrix"}></SummaryExport>
      </Box>
    </>
  );
}
