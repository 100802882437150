import { Select, Typography, ListItemText, MenuItem, InputAdornment, IconButton, Box } from '@mui/material'
import React, { useRef } from 'react'
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";

// const options = ["op1", "op2"]
// const options = [{ title: "t1", description: "desc1", value: value, separator: "-" }]

export function CustomSelect({ error, value, onChange, options, placeholder, MenuProps, sx, renderValue, children, ...otherProps }) {
    const selectRef = useRef(null);
    const getSelectWidth = () => {
        return selectRef.current ? selectRef.current?.clientWidth : 0;
    };

    return (
        <Box>
            <Select
                fullWidth
                sx={{
                    borderRadius: "1rem", background: "#F9F9F9",
                    boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                    "& .MuiOutlinedInput-notchedOutline": {
                        border: error ? "1px solid rgba(240, 68, 56, 0.3) !important" : "none",
                    },
                    "& .MuiListItemText-primary": {
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'pre'
                    },
                    ...sx
                }}
                renderValue={(value) => {
                    if (options && typeof options?.[0] === 'string') {
                        return (
                            <Box sx={{ width: '100%', height: '100%', overflow: "hidden", whiteSpace: "pre-line", textOverflow: "ellipsis" }}>
                                <Typography variant="Text/sm/Regular" sx={{ overflow: "hidden", whiteSpace: "pre-line", textOverflow: "ellipsis" }}>
                                    {value}
                                </Typography>
                            </Box>
                        )
                    } else {
                        const selected = options?.find(option => option.value === value)
                        return (
                            <Box sx={{ width: '100%', height: '100%', overflow: "hidden", whiteSpace: "pre-line", textOverflow: "ellipsis" }}>
                                <Typography variant="Text/sm/Regular" component={"p"}>
                                    <Typography variant="Text/sm/Semibold" component={"span"}>{`${selected?.title}${selected?.separator === "-" ? ` ${selected?.separator}`:  selected?.separator}`}</Typography>
                                    {selected?.description}
                                </Typography>
                            </Box>
                        )
                    }
                }}
                error={error}
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                MenuProps={{
                    sx: {
						".MuiMenu-paper": { boxShadow: (theme) => theme.palette.secondary.selectNoOutlineShadow },
					},
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                    },
                    transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                    },
                    PaperProps: {
                        sx: {
                            whiteSpace: 'normal', // Allow text wrapping
                            maxHeight: 300, // Set the max height for the dropdown if needed
                            width: getSelectWidth(),
                        }
                      },
                    ...MenuProps
                }}
                startAdornment={error && (
                    <InputAdornment position="end">
                        <IconButton>
                            <ErrorOutlineOutlinedIcon sx={{ color: "#F04438" }} />
                        </IconButton>
                    </InputAdornment>
                )}
                {...otherProps}
            >

                {options &&
                    options.map((item) => {
                        if (typeof item === 'string') {
                            return (
                                <MenuItem key={item} value={item} style={{ whiteSpace: 'normal' }}>
                                    <ListItemText primary={item} primaryTypographyProps={{ variant: 'Text/sm/Regular' }} />
                                </MenuItem>
                            );
                        } else {
                            return (
                                <MenuItem key={item?.value} value={item?.value} style={{ whiteSpace: 'normal' }}>
                                    <Typography variant="Text/sm/Regular" component={"p"}>
                                        <Typography variant="Text/sm/Semibold" component={"span"}>{`${item?.title}${item?.separator === "-" ? ` ${item?.separator}`:  item?.separator}`}</Typography>
                                        {item?.description}
                                    </Typography>
                                </MenuItem>
                            );

                        }
                        
                    })}
            </Select>
        </Box>
    )
}
