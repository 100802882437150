import services from "../../../../../services";

export const getStartupData = async companyId => {
  return services
    .getStartupDetails(companyId)
    .then(result => {
      if (result.data.code === 200) {
        return result.data?.data;
      }
    })
    .catch(err => {
      throw err;
    });
};

export const saveUnsaveStartup = data => {
  return services
    .saveStartup(data)
    .then(result => {
      if (result.data.code === 200) {
        return true;
      }
    })
    .catch(error => {
      throw error;
    });
};
