import React, { useState, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useHistory, generatePath } from "react-router-dom";
import { Typography, CardContent, Box, CardMedia, Grid } from "@mui/material";
import ROUTES_PATH from "../../constants/routes";
import PowerStatus from "../PowerStatus";
import {
  CardPlugIn,
  CardActionsPlugIn,
  ButtonPlugIn,
  CardContentPlugIn,
  BoxPlugInTitle,
  BoxPlugInDetails,
  TypographyPlugIn,
} from "./styles";
import { IN_PROGRESS_ICON, COMPLETED_DEFAULT } from "../../constants/image";
import { setComingFromPlugin } from "../../modules/actions/meetingsAction";
import { handleMixPanel } from "../../utils/mixPanelEventHandling";

export default function PlugInCard({ plugInData }) {
  const history = useHistory();
  const [powerStatusData, setPowerStatusData] = useState({
    statusFlag: plugInData?.powerUpStatus,
    statusType: "PLUGIN",
    isStarted: false,
    isCompleted: false,
  });

  useEffect(() => {
    setPowerStatusData({
      ...powerStatusData,
      statusFlag: plugInData?.powerUpStatus,
      isStarted:
        plugInData?.companyTask?.taskStatus === "STARTED" ? true : false,
      isCompleted:
        plugInData?.companyTask?.taskStatus === "COMPLETED" ? true : false,
    });
  }, [plugInData]);

  const dispatch = useDispatch();
  const getSurveyId = plugInData => {
    if (
      plugInData &&
      plugInData.pluginUuid &&
      plugInData.pluginUuid.includes("SURVEY_")
    ) {
      let surveyId = plugInData.pluginUuid.replace("SURVEY_", "");
      return surveyId;
    }
    return "";
  };

  const cardClick = cardData => {
    const pluginName = plugInData.pluginName;
    if (plugInData?.pluginUuid === "SURVEY_2") {
      handleMixPanel(`Plugin Net Promotor Score Survey Started`);
    } else if (plugInData?.pluginUuid === "SURVEY_4") {
      handleMixPanel(`Plugin Product Market Fit Survey Started`);
    } else if (plugInData?.pluginUuid === "SURVEY_5") {
      handleMixPanel(`Plugin Likert Scale Survey Started`);
    }

    /*

    Link to Market Test
    Product Market Fit Survey
    Persona Interview
    Discovery Interview
    Idea Validation Interview

    Link to Mentor Checkin
    Mentor Check In
    Mentor Plugin: Fine Tuning

    Link to Discovery Board
    Discovery Board (PowerDots)

    NA (coming soon)
    Pitch Day
    Practice Your Pitch
    Lean eXperimenter

    */
    if (
      plugInData &&
      plugInData.pluginUuid &&
      plugInData.pluginUuid.includes("SURVEY_")
    ) {
      history.push({
        pathname: generatePath(ROUTES_PATH.SURVEY, {
          id: plugInData.pluginUuid.replace("SURVEY_", ""),
        }),
      });
    } else if (
      pluginName === "Product Market Fit Survey" ||
      pluginName === "Market Test: Discovery Interview"
    ) {
      history.push({
        pathname: generatePath("/workspace/market-test"),
      });
    } else if (pluginName === "Market Test: Idea Validation Interview") {
      history.push({
        pathname: generatePath("/workspace/market-test"),
        message: "Plugin Market Test: Idea Validation Interview Test Created",
      });
    } else if (pluginName === "Market Test: Persona Interview") {
      history.push({
        pathname: generatePath("/workspace/market-test"),
        message: "Plugin Market Test: Personal Interview Test Created",
      });
    } else if (pluginName === "Discovery Board (PowerDots)") {
      history.push({
        pathname: generatePath("/discovery-board-expand"),
        message: "Plugin Discovery Board Started",
      });
    } else if (pluginName === "Mentor Check In") {
      dispatch(
        setComingFromPlugin({
          message: "Plugin Mentor Check In Meeting Scheduled",
        })
      );
      history.push({
        pathname: generatePath(ROUTES_PATH.MENTOR_CHECKIN_DETAILS),
      });
    } else if (pluginName === "Mentor Plugin: Fine Tuning") {
      dispatch(
        setComingFromPlugin({
          message: "Plugin Mentor Plugin: Fine Tuning Meeting Scheduled",
        })
      );
      history.push({
        pathname: generatePath(ROUTES_PATH.MENTOR_CHECKIN_DETAILS),
      });
    } else {
      history.push({
        pathname: generatePath(ROUTES_PATH.MENTOR_CHECKIN_DETAILS),
      });
    }
  };

  const isDisabled = plugInData => {
    return plugInData.pluginUuid &&
      plugInData.pluginUuid.includes("COMING_SOON")
      ? true
      : false;
  };

  const taskStatus = () => {
    return (
      <Box
        sx={{
          display: "flex",
          gap: 1,
          width: "120px;",
          height: "20px",
          p: 1,
        }}
      >
        <Box
          src={
            powerStatusData.isCompleted ? COMPLETED_DEFAULT : IN_PROGRESS_ICON
          }
          component="img"
          sx={{ height: "20px" }}
        />
        {powerStatusData.isCompleted ? "Complete" : "In Progress"}
      </Box>
    );
  };

  const taskButtonText = useMemo(() => {
    if (powerStatusData.isCompleted) {
      return "Restart";
    } else if (powerStatusData.isStarted) {
      return "Continue";
    }
    return "Start";
  }, [powerStatusData.isCompleted, powerStatusData.isStarted]);

  return (
    <CardPlugIn size="large">
      <CardContent sx={{ padding: 0 }}>
        <CardMedia
          component="img"
          height="184"
          image={plugInData.imageUrl}
          alt="card image"
        />
        <CardContentPlugIn>
          <Box>
            <BoxPlugInTitle>
              <PowerStatus powerStatusData={powerStatusData} />
              <Typography variant="StartupOS/headline/headline6">
                <span
                  dangerouslySetInnerHTML={{ __html: plugInData.pluginName }}
                />
              </Typography>
            </BoxPlugInTitle>
            <BoxPlugInDetails>
              <TypographyPlugIn variant="StartupOS/body/medium">
                {plugInData.shortDescription}
              </TypographyPlugIn>
            </BoxPlugInDetails>
          </Box>
        </CardContentPlugIn>
      </CardContent>
      <CardActionsPlugIn>
        <Grid alignItems="center" justify="space-between" container>
          <Grid item xs={6} container>
            {(powerStatusData.isStarted || powerStatusData.isCompleted) &&
              taskStatus()}
          </Grid>
          <Grid item xs={6} container justifyContent="flex-end">
            {!isDisabled(plugInData) && (
              <ButtonPlugIn
                onClick={() => {
                  cardClick(plugInData);
                }}
              >
                {taskButtonText}
              </ButtonPlugIn>
            )}
            {isDisabled(plugInData) && (
              <Box sx={{ padding: 1 }}>
                <Typography variant="StartupOS/body/medium">
                  COMING SOON
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      </CardActionsPlugIn>
    </CardPlugIn>
  );
}
