const ValidationValueType = {
	STRING: "STRING",
	INTEGER: "INTEGER",
	REGEX: "REGEX",
};

const ValidationType = {
	EMAIL: "EMAIL",
	URL: "URL",
	POSTAL_CODE: "POSTAL_CODE",
	MAX: "MAX",
	MIN: "MIN",
	MAX_LENGTH: "MAX_LENGTH",
	MIN_LENGTH: "MIN_LENGTH",
	ID: "ID",
};

export const DECISION_STATUS = {
	APPROVED: "APPROVED",
	DECLINED: "DECLINED",
	REQUIRES_REVIEW: "REQUIRES_REVIEW",
};

export const parseValidationValue = (value, valueType) => {
	switch (valueType) {
		case ValidationValueType.INTEGER:
			return parseInt(value, 10);
		case ValidationValueType.REGEX:
			return new RegExp(value);
		case ValidationValueType.STRING:
			return value;
	}
};

export const evaluateValidation = (validationType, validationValue) => {
	switch (validationType) {
		case ValidationType.MIN:
			return { min: validationValue };
		case ValidationType.MAX:
			return { max: validationValue };
		case ValidationType.MIN_LENGTH:
			return { minLength: validationValue };
		case ValidationType.MAX_LENGTH:
			return { maxLength: validationValue };
		case ValidationType.EMAIL:
		case ValidationType.ID:
		case ValidationType.POSTAL_CODE:
		case ValidationType.URL:
			return { pattern: validationValue };
	}
};

export const formatCompactNumber = number => {
	if (number < 1000) {
		return number;
	} else if (number >= 1000 && number < 1_000_000) {
		return (number / 1000).toFixed(1).replace(/\.0$/, "") + "K";
	} else if (number >= 1_000_000 && number < 1_000_000_000) {
		return (number / 1_000_000).toFixed(1).replace(/\.0$/, "") + "M";
	} else if (number >= 1_000_000_000 && number < 1_000_000_000_000) {
		return (number / 1_000_000_000).toFixed(1).replace(/\.0$/, "") + "B";
	} else if (number >= 1_000_000_000_000 && number < 1_000_000_000_000_000) {
		return (number / 1_000_000_000_000).toFixed(1).replace(/\.0$/, "") + "T";
	}
};

export const getRules = (required, validations) => {
	let validationRule = {
		required: required,
	};

	if (required) {
		if (validations.length > 0) {
			let messages = [];
			validations.forEach(validation => {
				const validationValue = parseValidationValue(validation.value, validation.value_type);
				const rule = evaluateValidation(validation.validation_type, validationValue);

				if (validation.message) {
					messages.push({ type: validation.validation_type, message: validation.message });
					validationRule = { ...validationRule, ...rule, message: validation.message };
				} else {
					validationRule = { ...validationRule, ...rule, message: "This field is required" };
				}
			});

			if (validations.length > 1) {
				validationRule.message = messages;
			}

			return validationRule;
		}

		return { ...validationRule, message: "This field is required" };
	}

	return validationRule;
};

export const getInitAnswers = (questions = []) => {
	const initAnswers = questions.map(question => {
		const fields = question.fields.map(field => {
			let value = "";
			if (field.provided_value) {
				if (typeof field.provided_value === "string") {
					value = field.provided_value;
				} else if (typeof field.provided_value === "object") {
					value = field.provided_value.join(", ");
				} else {
					value = "";
				}
			}

			return {
				id: field?.id,
				value: [value],
			};
		});

		return {
			id: question.id,
			fields,
		};
	});

	return initAnswers;
};

export const getFieldValue = value => {
	if (typeof value === "boolean") {
		return value === true ? "true" : "false";
	}

	return value === null ? "" : typeof value === "number" ? value.toString() : value;
};

export const getPreQuestionSetId = (questionSets, order = 10000000, currentId = "", isPre = true) => {
	try {
		const sortedData = Object.keys(questionSets)
			.map(id => {
				return { ...questionSets[id], questionSetId: id };
			})
			.sort((a, b) => a.order - b.order);

		let currentOrderIndex = order;
		if (currentId !== "" && currentId !== undefined) {
			currentOrderIndex = sortedData.filter(item => item.questionSetId === currentId)[0].order;
		}

		if (isPre === false) {
			const filterredData = sortedData.filter(item => item.order > currentOrderIndex);
			return filterredData.length > 0 ? filterredData[0].questionSetId : "";
		}

		const filterredData = sortedData.filter(item => item.order < currentOrderIndex);
		return filterredData[filterredData.length - 1].questionSetId;
	} catch (e) {
		console.log("getPreQuestionSetId error", e);
		return "";
	}
};

export const getFilledAnswers = (questions, answers) => {
	const filledAnswers = questions.map(question => {
		const answerIndex = answers.questions.findIndex(answer => answer.id === question.id);

		const fields = question.fields.map(field => {
			if (answerIndex === -1) {
				// TODO: mapping questions again, not 0.
				const answerMultiIndex = answers.questions[0].findIndex(answer => answer.id === question.id);
				const fieldIndex = answers.questions[0][answerMultiIndex].fields.findIndex(answerField => answerField.id === field.id);
				return {
					...field,
					provided_value: getFieldValue(answers.questions[0][answerMultiIndex].fields[fieldIndex].provided_value),
					editable: answers.editable,
				};
			} else {
				const fieldIndex = answers.questions[answerIndex].fields.findIndex(answerField => answerField.id === field.id);
				return {
					...field,
					provided_value: getFieldValue(answers.questions[answerIndex].fields[fieldIndex].provided_value),
					editable: answers.editable,
				};
			}
		});

		return { ...question, fields: fields };
	});

	return filledAnswers;
};
