import React, { useEffect, useMemo, useState } from "react";
import { Box, styled, Button, CircularProgress, Typography, Stack, Grid } from "@mui/material";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

import { Route, Switch, useRouteMatch } from "react-router-dom";
import { PowerUPController as PowerUP_V2 } from "../../../../containers/PowerUP/PowerUPController";

ChartJS.register(ArcElement, Tooltip, Legend);

export default function TryDemo() {
	const { path } = useRouteMatch();

	return (
		<Box>
			<PowerUP_V2 />
		</Box>
	);
}
