import React, { useEffect, useState } from "react";
import { Box, Typography, Chip } from "@mui/material";
import "swiper/swiper-bundle.css";
import "swiper/css";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { getFilterValues } from "../../../../../utils/commonFunctions";

export default function FilteredValueCarosualComponent({ filteredValue = [], handleDelete, filterId = "filter-id" }) {
	const [displayValue, setDisplayValue] = useState("none");
	const slideLeft = () => {
		document.getElementById(filterId).scrollTo({
			left: 0,
			behavior: "smooth",
		});
	};
	const slideRight = () => {
		document.getElementById(filterId).scrollLeft += 200;
	};

	useEffect(() => {
		const element = document.getElementById(filterId);
		function isEllipsisActive(e) {
			return e.offsetWidth < e.scrollWidth;
		}
		if (isEllipsisActive(element)) {
			setDisplayValue("flex");
		} else {
			setDisplayValue("none");
		}
	}, [filteredValue]);

	return (
		<Box
			sx={{
				height: "50px",
				// width: "inherit",
				// maxWidth: "70%",
				display: "flex",
				alignItems: "center",
			}}
		>
			<Box
				id={filterId}
				sx={{
					height: "50px",
					overflow: "hidden",
					display: "flex",
					alignItems: "center",
					mr: 1,
				}}
			>
				{filteredValue &&
					filteredValue.map((item, index) => {
						return (
							<Box
								sx={{
									mr: 1,
								}}
								key={index}
							>
								<Chip
									sx={{
										"& .MuiChip-deleteIcon": {
											color: (theme) => theme.palette.sos.gray400,
											"&:hover": { color: (theme) => theme.palette.sos.gray400 },
										},
										backgroundColor: (theme) => theme.palette.sos.gray100,
										"& .MuiChip-label": {
											color: (theme) => theme.palette.sos.gray700,
										},
									}}
									label={
										<Typography variant="Text/xs/Regular" sx={{ color: (theme) => theme.palette.sos.gray700 }}>
											{getFilterValues(item?.type, item?.checkboxValue)}
										</Typography>
									}
									onDelete={() => handleDelete?.(item?.checkboxValue)}
									deleteIcon={<CloseIcon id={item?.checkboxValue} />}
								/>
							</Box>
						);
					})}
			</Box>
			<Box sx={{ display: displayValue, alignItems: "center", ml: "auto" }}>
				<ArrowCircleLeftOutlinedIcon
					fontSize="medium"
					sx={{ color: (theme) => theme.palette.secondary.black, cursor: "pointer" }}
					onClick={slideLeft}
				/>
				<ArrowCircleRightOutlinedIcon
					sx={{ color: (theme) => theme.palette.secondary.black, cursor: "pointer" }}
					fontSize="medium"
					onClick={slideRight}
				/>
				<Typography variant="Text/sm/Semibold">[{filteredValue.length}]</Typography>
			</Box>
		</Box>
	);
}
