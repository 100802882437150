import React, { createContext, useEffect, useState } from "react";
import { useParams, useLocation, useRouteMatch } from "react-router-dom";
import {
	getArticlesFromSectionByID,
	getCategories,
	getCategoryInfoByID,
	getSectionInfoByID,
	getArticleInfoByID,
	getSectionsFromCategoryByID,
	getAllSections,
	getArticleAttachmentsByID,
	searchZendesk,
} from "../Common/functions";

export const ResourceCenterContext = createContext();

export const ResourceCenterStore = ({ children }) => {
	const match = useRouteMatch([
		"/resource-center/sections/:section_id/articles/:article_id",
		//"/resource-center/categories/:category_id/sections/:section_id/articles/:article_id",
		//"/resource-center/categories/:category_id/sections/:section_id",
		"/resource-center/categories/:category_id",
		"/resource-center/sections/:section_id",
		"/resource-center/articles/:article_id",
		"/resource-center/search-results/articles/:article_id",
	]);

	const { category_id, section_id, article_id } = match?.params || {};

	const [pageDataLoading, setPageDataLoading] = useState(false);
	const [categories, setcategories] = useState([]);
	const [sections, setsections] = useState([]);
	const [articles, setarticles] = useState([]);
	const [currentCategory, setcurrentCategory] = useState({});
	const [currentSection, setcurrentSection] = useState({});
	const [currentArticle, setcurrentArticle] = useState({});
	const [currentArticleAttachments, setcurrentArticleAttachments] = useState({});
	const [listLoading, setlistLoading] = useState(false);
	const [searchLoading, setsearchLoading] = useState(false);
	const [currentSearchQuery, setcurrentSearchQuery] = useState(null);
	const [searchResults, setsearchResults] = useState({});

	const loadCategories = async () => {
		try {
			setlistLoading(true);
			const resp = await getCategories();
			setcategories(resp?.data?.categories);
		} catch (e) {
			console.log(e, "There was an error grabbing Categories.");
		} finally {
			setTimeout(() => {
				setlistLoading(false);
			}, 500);
		}
	};
	const loadSections = async () => {
		try {
			setlistLoading(true);
			//const resp = await getSectionsFromCategoryByID(category_id);
			const resp = await getAllSections();
			setsections(resp?.data?.sections);
		} catch (e) {
			console.log(e, "There was an error grabbing Sections.");
		} finally {
			setTimeout(() => {
				setlistLoading(false);
			}, 500);
		}
	};
	const loadArticles = async () => {
		try {
			setlistLoading(true);
			const resp = await getArticlesFromSectionByID(section_id);
			setarticles(resp?.data?.articles);
		} catch (e) {
			console.log(e, "There was an error grabbing Articles.");
		} finally {
			setTimeout(() => {
				setlistLoading(false);
			}, 500);
		}
	};

	const getCategoryInfo = async () => {
		try {
			const resp = await getCategoryInfoByID(category_id);
			setcurrentCategory(resp?.data?.category);
		} catch (e) {
			console.log(e, "There was an error grabbing Category info for ID:" + category_id);
		}
	};
	const getSectionInfo = async () => {
		try {
			const resp = await getSectionInfoByID(section_id);
			setcurrentSection(resp?.data?.section);
		} catch (e) {
			console.log(e, "There was an error grabbing Section info for ID:" + section_id);
		}
	};
	const getArticleInfo = async () => {
		try {
			const resp = await getArticleInfoByID(article_id);
			setcurrentArticle(resp?.data?.article);
		} catch (e) {
			console.log(e, "There was an error grabbing Article info for ID:" + article_id);
		}
	};
	const getArticleAttachments = async () => {
		try {
			const resp = await getArticleAttachmentsByID(article_id);
			setcurrentArticleAttachments(resp?.data);
		} catch (e) {
			console.log(e, "There was an error grabbing Article Attachments for ID:" + article_id);
		}
	};

	const searchArticles = async (query) => {
		try {
			if (query) {
				setsearchLoading(true);
				setcurrentSearchQuery(query);
				const resp = await searchZendesk(query);
				console.log(resp?.data);
				setsearchResults(resp?.data);
			} else {
				setcurrentSearchQuery("");
			}
		} catch (e) {
			console.log(e, "There was an error searching Articles.");
		} finally {
			setTimeout(() => {
				setsearchLoading(false);
			}, 500);
		}
	};

	useEffect(() => {
		if (typeof category_id == "undefined") {
			setcurrentCategory({});
		} else {
			getCategoryInfo();
		}
	}, [category_id]);

	useEffect(() => {
		if (typeof section_id == "undefined") {
			setcurrentSection({});
		} else {
			getSectionInfo();
		}
	}, [section_id]);

	useEffect(() => {
		if (typeof article_id == "undefined") {
			setcurrentArticle({});
		} else {
			getArticleInfo();
		}
	}, [article_id]);

	useEffect(() => {
		if (currentArticle && currentArticle?.id) {
			getArticleAttachments();
		}
	}, [currentArticle]);

	const contextValue = {
		pageDataLoading,
		categories,
		sections,
		articles,
		currentCategory,
		currentSection,
		currentArticle,
		currentArticleAttachments,
		currentSearchQuery,
		searchArticles,
		loadCategories,
		loadSections,
		loadArticles,
		listLoading,
		searchLoading,
		searchResults,
		article_id,
	};

	return <ResourceCenterContext.Provider value={contextValue}>{children}</ResourceCenterContext.Provider>;
};
