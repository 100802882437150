import { Box, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import OnboardingCardSelector from "../../../../components/Onboarding/OnboardingCardSelector";
import PageTitle from "../../../../components/Onboarding/PageTitle";
import {
  HAVE_YOU_RECIEVED_ANY_FUNDING,
  WHAT_BRINGS_YOU_HERE,
} from "../../../../constants/updated-onboarding";

export default function ReceivedFunding({ setComplete }) {
  const [userResponse, setUserResponse] = useState("");
  const savedData = useSelector(state => state?.onboarding?.receivedAnyFunding);

  useEffect(() => {
    if (savedData) {
      setUserResponse(savedData);
    }
  }, [savedData]);

  const callComplete = (complete, data) => {
    if (setComplete) {
      setComplete(complete, data);
    }
  };

  useEffect(() => {
    if (userResponse) {
      callComplete(1, { receivedAnyFunding: userResponse });
    } else {
      callComplete(0, { receivedAnyFunding: null });
    }
  }, [userResponse]);
  return (
    <Box>
      <PageTitle
        title={"Have you received any funding?"}
        description={
          "This will help us understand your journey a little bit better—it's OK if you're starting from square one! "
        }
      />

      <Box sx={{ my: 5 }}>
        <OnboardingCardSelector
          cardItems={HAVE_YOU_RECIEVED_ANY_FUNDING}
          flex={1 / 2}
          gridColWidth={7}
          setUserResponse={setUserResponse}
          userResponse={userResponse}
          savedData={savedData}
        />
      </Box>
    </Box>
  );
}
