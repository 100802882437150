import React from "react";
import { useSelector } from "react-redux";
import { Box, Stack, Grid, Typography, useMediaQuery } from "@mui/material";
import services from "../../../services";
import TeammateCardItem from "../../Profile/Components/TeamCard/TeammateCardItem";
import InviteTeamButton from "../../Profile/Components/InviteTeamButton";
import SvgComponent from "../../../utils/SVGColor";
import { USERS_THREE_ICON } from "../../../constants";

const TeamSectionTab = ({ handleClose }) => {
	const teamArray = useSelector((state) => state?.userProfileData?.teamArray);
	const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

	const handleHideActionApiCall = (payload) => {
		services
			.updateCompanyUserHideStatusApiCall(payload)
			.then((response) => {
				if (response.data.message === "Success") {
					console.log("Success");
				}
			})
			.catch((e) => {
				console.log("Error", e);
			});
	};
	const handleEyeIconAction = (isHidden, id) => {
		const detailsList = [...teamArray];
		detailsList.map((item) => {
			if (item.id === id) {
				return (item.isHidden = isHidden);
			}
		});
		let payload = {
			flag: isHidden,
			userId: id,
		};
		handleHideActionApiCall(payload);
	};

	return (
		<Stack sx={{ px: isSmallScreen ? 0 : 2 }} direction={"column"} spacing={8}>
			<Box>
				<Box
					sx={{
						display: isSmallScreen ? "block" : "flex",
						alignItems: "center",
						justifyContent: "space-between",
						flex: 1,
						mb: "20px",
					}}
				>
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							gap: "12px",
						}}
					>
						<SvgComponent svgUrl={USERS_THREE_ICON} strokeColor={"#0A141B"} style={{ height: "35px", width: "35px" }} />
						<Typography
							variant="customValue"
							component={"div"}
							sx={{
								color: "black",
								fontFamily: "PoppinsSemiBold",
								fontSize: "26px",
								lineHeight: "39px",
							}}
						>
							Meet The Team
						</Typography>
					</Box>
					<Typography
						variant="customValue"
						component={"div"}
						sx={{
							color: "#98A2B3",
							fontFamily: "PoppinsSemiBold",
							fontSize: "20px",
							lineHeight: "30px",
						}}
					>
						Teammates [{teamArray?.length ? teamArray?.length : 0}]
					</Typography>
				</Box>
				<Box
					sx={{
						display: "flex",
						flex: 1,
						mb: "20px",
					}}
				>
					<InviteTeamButton />
				</Box>

				<Grid container spacing={4}>
					<Grid item xs={12} sm={12} md={6} lg={6} xl={8}>
						<Grid container spacing={4}>
							{teamArray.filter((item) => !item.isHidden)?.length > 0 &&
								teamArray
									.filter((item) => !item.isHidden)
									.map((teamItem, index) => {
										return (
											<Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
												<TeammateCardItem teamItem={teamItem} key={index} handleEyeIconAction={handleEyeIconAction} />
											</Grid>
										);
									})}
						</Grid>
					</Grid>

					<Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
						<Grid container spacing={4}>
							{teamArray.filter((item) => item.isHidden)?.length > 0 && (
								<>
									{teamArray
										.filter((item) => item.isHidden)
										.map((teamItem, index) => {
											return (
												<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
													<TeammateCardItem teamItem={teamItem} key={index} handleEyeIconAction={handleEyeIconAction} />
												</Grid>
											);
										})}
								</>
							)}
						</Grid>
					</Grid>
				</Grid>
			</Box>
		</Stack>
	);
};

export default TeamSectionTab;
