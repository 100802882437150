import moment from "moment";
import { MEETING_DAYS_OBJECT_LIST } from "../../../../constants";
import { getFormattedDate } from "../../../../utils/date";

export const convertToTime = (time) => {
	return "1970-01-01" + "T" + time;
};
export const convertToISOTime = (time, timeZone) => {
	//return moment(time).tz(timeZone).format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]");
	return "1970-01-01" + "T" + getFormattedDate(time, "HH:mm") + ":00.000Z";
};

export const createAvailabilityArray = (availabilities) => {
	let eventDaysObject = [...MEETING_DAYS_OBJECT_LIST];
	let dayTimesMap = {};
	for (let availability of availabilities) {
		const day = availability.day;
		if (dayTimesMap.hasOwnProperty(day)) {
			dayTimesMap[day].push({
				availabilityId: availability.id,
				startTime: availability.startTime,
				endTime: availability.endTime,
			});
		} else {
			dayTimesMap[day] = [
				{
					availabilityId: availability.id,
					startTime: availability.startTime,
					endTime: availability.endTime,
				},
			];
		}
	}

	for (const day in dayTimesMap) {
		if (Object.hasOwnProperty.call(dayTimesMap, day)) {
			const currentDayObjectIndex = eventDaysObject.findIndex((x) => x.day == day);
			if (currentDayObjectIndex >= 0) {
				let currentDayObject = { ...eventDaysObject[currentDayObjectIndex] };
				currentDayObject["timeList"] = [...dayTimesMap[day].map((x) => ({ ...x }))];
				currentDayObject.timeList.sort((a, b) => (a.startTime > b.startTime ? 1 : b.startTime > a.startTime ? -1 : 0));

				// Check if the current day is present in availabilities
				const isDayAvailable = availabilities.some((avail) => avail.day == day);

				// Set value to true only if the day is present in availabilities
				currentDayObject["value"] = isDayAvailable;

				eventDaysObject[currentDayObjectIndex] = currentDayObject;
			}
		}
	}
	for (let i = 0; i < eventDaysObject.length; i++) {
		const day = eventDaysObject[i].day;
		const isDayAvailable = availabilities.some((availability) => availability.day === day);
		eventDaysObject[i].value = isDayAvailable;
	}
	return eventDaysObject;
};
