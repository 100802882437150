import React, { useState, useEffect } from "react";
import { Button, Box, Grid } from "@mui/material";

import DialogComponent from "../../../containers/Workspace/PowerUP/IdeaValidation/Components/Dialog";
import BasicCheckBoxTree from "../../../common/CheckBoxTree";
import RadioButtonTree from "../../../common/RadioButtonTree";
import services from "../../../services";

export default function SelectIndustryModal({ open, selectedIndustries, setSelectedIndustries, onClose, title }) {
	const [treeDataList, settreeDataList] = useState([]);

	useEffect(() => {
		selectedIndustries.map((industryItem) => {
			if (industryItem) {
			}
		});
	}, [selectedIndustries]);

	useEffect(() => {
		apiCallToGetIndustryData();
	}, []);

	const apiCallToGetIndustryData = () => {
		services
			.getIndustryData()
			.then((res) => {
				if (res.status == 200) {
					let responseData = res?.data;
					const arrayOfObj = Object.entries(responseData).map((e) => ({ [e[0]]: e[1] }));
					settreeDataList(arrayOfObj);
				}
			})
			.catch((err) => {
				console.log(err?.error_description);
			});
	};

	const handleSelectedIndustry = (industryObj, selectedIndustryList) => {
		let objList = [...selectedIndustries];

		selectedIndustryList.forEach((item) => {
			let obj = {};
			if (objList.indexOf(item) === -1) {
				obj.type = industryObj?.industryname;
				obj.value = item;
				objList.push(obj);
			}
		});
		let uniqueArray = [...new Map(objList.map((item) => [item["type"], item])).values()];

		setSelectedIndustries(uniqueArray);
	};
	return (
		<Box>
			<DialogComponent
				disableBackDropClick={true}
				title={title}
				titleVariant={"title_large"}
				isOpen={open}
				footerStyle={{
					border: "none",
				}}
				onClose={() => {
					onClose();
				}}
				contentStyle={{ px: 3 }}
				maxWidth={"md"}
				fullWidth
				PaperProps={{
					sx: (theme) => ({
						borderRadius: theme.shape.standard.borderRadius,
					}),
				}}
			>
				<Box sx={{ py: 2 }}>
					<Grid container>
						{treeDataList?.map((treeData) => {
							const treeDataArray = Object.entries(treeData).map((e) => ({ [e[0]]: e[1] }));
							const treeDataObj = Object.values(treeDataArray[0])[0];

							return (
								<Grid item lg={6}>
									<RadioButtonTree
										treeData={treeDataObj}
										handleSelectedIndustry={(selected) => handleSelectedIndustry(treeDataObj, selected)}
										selectedIndustries={selectedIndustries.filter((itm) => itm?.type === treeDataObj?.industryname)}
									/>
								</Grid>
							);
						})}
					</Grid>
				</Box>
			</DialogComponent>
		</Box>
	);
}
