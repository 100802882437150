import React, { useContext } from "react";
import { ScheduleMeetingContext } from ".";
import MeetingLengthOptionList from "../../../containers/Meetings/MeetingLengthSchedule/components/MeetingLengthOptionList";
import { Box } from "@mui/material";

function MeetingLengthSelection({ withUserType }) {
	const { userPayload, setMeetingLength } = useContext(ScheduleMeetingContext);

	return (
		<Box>
			<MeetingLengthOptionList calUser={userPayload?.id} setMeetingObject={(payload) => setMeetingLength(payload)} withUserType={withUserType} />
		</Box>
	);
}

export default MeetingLengthSelection;
