import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import OnboardingCardSelector from "../../../../components/Onboarding/OnboardingCardSelector";
import PageTitle from "../../../../components/Onboarding/PageTitle";
import { WHICH_STARTUPS_YOU_HELP } from "../../../../constants/updated-onboarding";

export default function StartupsYouHelp({ setComplete }) {
  const history = useHistory();
  const [userResponse, setUserResponse] = useState("");
  const savedData = useSelector(state => state?.onboarding?.startupInterest);

  useEffect(() => {
    if (savedData) {
      setUserResponse(savedData);
    }
  }, [savedData]);

  const callComplete = (complete, data) => {
    if (setComplete) {
      setComplete(complete, data);
    }
  };

  useEffect(() => {
    if (userResponse && userResponse.length) {
      callComplete(1, { startupInterest: userResponse });
    } else {
      callComplete(0, { startupInterest: null });
    }
  }, [userResponse]);

  return (
    <Box>
      <PageTitle
        title={"Which startups can you help the most?"}
        description={"Select all that apply."}
        onBackClick={() => history.goBack()}
      />
      <Box sx={{ my: 5 }}>
        <OnboardingCardSelector
          cardItems={WHICH_STARTUPS_YOU_HELP}
          flex={1 / 3}
          gridColWidth={10}
          multiSelect
          setUserResponse={setUserResponse}
          userResponse={userResponse}
          savedData={savedData}
        />
      </Box>
    </Box>
  );
}
