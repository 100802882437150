import { Box, Divider, Typography } from "@mui/material";
import React from "react";
import { POWERUPLOGO } from "../../../../constants/image";

export default function FormSectionHeader({ heading }) {
  return (
    <Box sx={{pb:3}}>
      <Box display={"flex"} justifyContent={"space-between"} alignItems="center">
        <img src={POWERUPLOGO} alt="logo" />
        <Typography variant="overlineLarge" color="text.secondary">
          {heading}
        </Typography>
      </Box>
    </Box>
  );
}
