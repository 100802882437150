import { Box, Skeleton } from "@mui/material";
import React, { Fragment } from "react";
import { CSSTransition } from "react-transition-group";

const SkeletonItem = ({ timeout, height }) => (
	<CSSTransition in={true} appear={true} timeout={timeout} classNames="open">
		<Box
			sx={{
				transition: "all 0.3s ease-in-out",
				opacity: 0,
				transform: "translateX(10rem)",
				"&.open-exit-done": {
					opacity: 0,
					transform: "translateX(10rem)",
				},
				"&.open-exit-active": {
					opacity: 1,
					transform: "translateX(0px)",
				},
				"&.open-enter-done": {
					opacity: 1,
					transform: "translateX(0px)",
				},
				"&.open-enter-active": {
					opacity: 1,
					transform: "translateX(0px)",
				},
			}}
		>
			<Skeleton
				variant="rounded"
				animation="wave"
				sx={{
					height: height,
					width: "100%",
					borderRadius: "10px",
					"&::after": {
						animationDelay: `.${timeout}s`,
					},
				}}
			/>
		</Box>
	</CSSTransition>
);

function PersonaBuilderLoader({ useStacked }) {
	return (
		<Box sx={{ display: "flex", flexDirection: "column", gap: ".5rem" }}>
			<Box
				sx={{
					gap: ".5rem",
					...(useStacked
						? {
								display: "flex",
								flexDirection: "column",
						  }
						: {
								display: "grid",
								gridTemplateColumns: "218px 1fr 1fr 1fr",
								gridTemplateRows: "repeat(2, auto)",
						  }),
				}}
			>
				<Box sx={{ gridArea: "1 / 1 / 1 / 2" }}>
					<SkeletonItem timeout={100} height={useStacked ? "3rem" : "5rem"} />
				</Box>
				<Box sx={{ gridArea: "1 / 2 / 1 / 3" }}>
					<SkeletonItem timeout={250} height={useStacked ? "3rem" : "5rem"} />
				</Box>
				<Box sx={{ gridArea: "1 / 3 / 1 / 4" }}>
					<SkeletonItem timeout={400} height={useStacked ? "3rem" : "5rem"} />
				</Box>
				<Box sx={{ gridArea: "1 / 4 / 1 / 5" }}>
					<SkeletonItem timeout={400} height={useStacked ? "3rem" : "5rem"} />
				</Box>
			</Box>
			<Box
				sx={{
					gap: ".5rem",
					...(useStacked
						? {
								display: "flex",
								flexDirection: "column",
						  }
						: {
								display: "grid",
								gridTemplateColumns: "218px 1fr 1fr 1fr",
								gridTemplateRows: "repeat(2, auto)",
						  }),
				}}
			>
				<Box sx={{ gridArea: "1 / 1 / 1 / 2" }}>
					<SkeletonItem timeout={100} height={useStacked ? "5rem" : "10rem"} />
				</Box>
				<Box sx={{ gridArea: "1 / 2 / 1 / 3" }}>
					<SkeletonItem timeout={250} height={useStacked ? "5rem" : "10rem"} />
				</Box>
				<Box sx={{ gridArea: "1 / 3 / 1 / 4" }}>
					<SkeletonItem timeout={400} height={useStacked ? "5rem" : "10rem"} />
				</Box>
				<Box sx={{ gridArea: "1 / 4 / 1 / 5" }}>
					<SkeletonItem timeout={400} height={useStacked ? "5rem" : "10rem"} />
				</Box>
			</Box>
		</Box>
	);
}

export default PersonaBuilderLoader;
