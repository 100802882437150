import * as React from "react";
import { Box, Typography, MenuItem, FormControl, Select, Chip, ListItemText } from "@mui/material";
import { StyledSelect } from "./style";
import CloseIcon from "@mui/icons-material/Close";
import { Check } from "@mui/icons-material";

const UpdatedSelectField = ({ id, lable, options, handleChange, handleDelete, inputValue, placeholder, selectProps }) => {
	return (
		<Box
			sx={{
				minWidth: 120,
				display: "flex",
				flexDirection: "column",
				gap: 1,
			}}
		>
			<Typography variant="Display/sm/Semibold" mb={1}>
				{lable}
			</Typography>
			<StyledSelect
				id={id}
				multiple={selectProps?.multiple}
				placeholder={placeholder}
				fullWidth={selectProps?.fullWidth}
				IconComponent={selectProps?.IconComponent}
				height={selectProps?.height}
				width={selectProps?.width}
				onChange={(event) => handleChange(event, id)}
				renderValue={() => {}}
				value={inputValue}
				PaperProps={selectProps.PaperProps}
				MenuProps={selectProps.MenuProps}
			>
				{options &&
					options.map((item) => {
						return (
							<MenuItem value={item}>
								<ListItemText primary={item} />
								{inputValue && Array.isArray(inputValue) && inputValue?.indexOf(item) > -1 && <Check />}
							</MenuItem>
						);
					})}
			</StyledSelect>
			{inputValue && (
				<Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mt: 1 }}>
					{inputValue?.map((value) => (
						<Chip
							key={value}
							label={value}
							deleteIcon={<CloseIcon />}
							onDelete={() => handleDelete(value, id)}
							sx={{
								fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
								fontSize: "16px",
								lineHeight: "24px",
								background: "white",
								color: "#344054",
								boxShadow: "0px 0px 0px 4px rgba(242, 244, 247, 1)",
							}}
						/>
					))}
				</Box>
			)}
		</Box>
	);
};

export default UpdatedSelectField;
