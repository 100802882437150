import React from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";

const PasswordValidation = ({ className, validationErrorArray, disabled, formType }) => {
	const ValidationRow = ({ isValid, label }) => {
		return (
			<Box sx={{ mt: 1 }} gap={2} className="d-flex" alignItems={"center"}>
				<DoneRoundedIcon fontSize="large" sx={{ color: isValid ? "rgba(0, 206, 126, 1)" : "rgba(208, 213, 221, 1)" }} />
				<Typography sx={{ color: isValid ? "rgba(0, 0, 0, 1)" : "rgba(152, 162, 179, 1)" }} variant="Text/sm/Regular">
					{label}
				</Typography>
			</Box>
		);
	};

	return (
		<Box sx={{ mt: 1 }}>
			<Typography variant="Text/sm/Regular">Your password must contain:</Typography>
			<ValidationRow isValid={!validationErrorArray[0]} label={"At least 8 characters"} />
			<ValidationRow isValid={!validationErrorArray[1]} label={"Lower case letters (a-z)"} />
			<ValidationRow isValid={!validationErrorArray[2]} label={"Upper case letters (A-Z)"} />
			<ValidationRow isValid={!validationErrorArray[3]} label={"Number (0-9)"} />
			<ValidationRow isValid={!validationErrorArray[4]} label={"One special character"} />
		</Box>
	);
};

PasswordValidation.propTypes = {
	label: PropTypes.string,
	className: PropTypes.string,
	type: PropTypes.string,
};

PasswordValidation.defaultProps = {
	className: undefined,
	type: "info",
};

export default PasswordValidation;
