import services from "../../../../services";
import moment from "moment";
import { get, groupBy } from "lodash";
import {
	LINK_PARTNER_CARTA_SMALL,
	LINK_PARTNER_PLAID_SMALL,
	LINK_PARTNER_HUBSPOT_SMALL,
	LINK_PARTNER_STRIPE_SMALL,
	LINK_PARTNER_QUICKBOOKS_SMALL,
	LINK_PARTNER_MIXPANEL_SMALL,
	LINK_PARTNER_SALESFORCE_SMALL,
	WAILIST_SALESFORCE,
	LINK_PARTNER_XERO_SMALL,
	LINK_PARTNER_EXCEL_SMALL,
	LINK_PARTNER_GOOGLE_SHEET,
} from "../../../../constants";

export async function getAllBIChartsData(companyID) {
	const allBIChartsDataValues = await services
		.getAllBIChartData(companyID)
		.then((result) => {
			if (result.data.code === 200) {
				return result.data?.data;
			}
		})
		.catch((err) => {
			throw err;
		});
	return allBIChartsDataValues;
}

export function convertNumberToLocale(num, maximumFractionDigits = 2) {
	return Intl.NumberFormat("en-US", {
		notation: "compact",
		maximumFractionDigits: maximumFractionDigits,
	}).format(Number(num));
}

export async function updateAnnualRevenueGridData(data) {
	const annualRevenueGridData = await services
		.updateAnnualRevenueGridData(data)
		.then((result) => {
			if (result.data.code === 200) {
				return result.data?.data;
			}
		})
		.catch((err) => {
			throw err;
		});
	return annualRevenueGridData;
}

export async function updateNetArrGridData(data) {
	const netArrData = await services
		.updateNetArrGridData(data)
		.then((result) => {
			if (result.data.code === 200) {
				return result.data?.data;
			}
		})
		.catch((err) => {
			throw err;
		});
	return netArrData;
}

export async function updateMonthlyRevenueGridData(data) {
	const monthlyRevenueGridData = await services
		.updateMonthlyRevenueGridData(data)
		.then((result) => {
			if (result.data.code === 200) {
				return result.data?.data;
			}
		})
		.catch((err) => {
			throw err;
		});
	return monthlyRevenueGridData;
}

export async function updateBurnRunwayGridData(data) {
	const burnRunwayGridData = await services
		.updateBurnRunwayGridData(data)
		.then((result) => {
			if (result.data.code === 200) {
				return result.data?.data;
			}
		})
		.catch((err) => {
			throw err;
		});
	return burnRunwayGridData;
}

export async function updatePaybackGridData(data) {
	const paybackGridData = await services
		.updatePaybackGridData(data)
		.then((result) => {
			if (result.data.code === 200) {
				return result.data?.data;
			}
		})
		.catch((err) => {
			throw err;
		});
	return paybackGridData;
}

export async function updateCustomerGridData(data) {
	const customerGridData = await services
		.updateCustomerGridData(data)
		.then((result) => {
			if (result.data.code === 200) {
				return result.data?.data;
			}
		})
		.catch((err) => {
			throw err;
		});
	return customerGridData;
}

export function getRequestPayloadFromRows(rows, gridData) {
	const transformedData = Object.keys(rows[0])
		.filter((key) => !["id", "name"].includes(key))
		.map((date) => {
			return rows.reduce((acc, curr) => {
				// acc.id = curr.id; //To Do Uniq id
				// acc.companyId = companyId;
				acc.date = date;
				acc[curr.name] = curr[date];
				return acc;
			}, {});
		})
		.filter((item) => {
			return Object.values(item)
				.slice(1) // remove date
				.some((val) => val !== null);
		});

	transformedData.forEach((td) => {
		const match = gridData.find((d2) => {
			return td.date === d2.date;
		});
		if (match) {
			td.id = match.id;
		}
	});

	return transformedData;
}

export function getStartAndEndDate(dates = []) {
	dates.sort(function (a, b) {
		var dateA = new Date(a);
		var dateB = new Date(b);

		return dateA - dateB;
	});

	const earliestDate = dates[0] || moment().set("date", 1).format("YYYY-MM-DD");
	// const latestDate = dates[dates.length - 1] || moment().set("date", 1).format("YYYY-MM-DD");

	const startDate = moment(earliestDate).subtract(12, "months");
	const endDate = moment().set("date", 1); //current month

	return { startDate, endDate };
}

export function getMomGrowth(transformedData = [], field) {
	if (transformedData?.length > 1) {
		const latestElem = transformedData[transformedData.length - 1];
		const secondLastElm = transformedData[transformedData.length - 2];

		// it should be successive months with years like march-2023 and april-2023
		if (moment(latestElem["date"]).subtract(1, "months").format("YYYY-MM-DD") === secondLastElm["date"]) {
			if (latestElem[field] === null || secondLastElm[field] === null || secondLastElm[field] === 0) {
				return null;
			}
			return ((latestElem[field] - secondLastElm[field]) / secondLastElm[field]) * 100;
		}
		return null;
	}
	return null;
}

export function calculateNetArr(transformedData, previousNetArrData) {
	const netArrData = transformedData.map((dataItem, index) => {
		const currentARR = dataItem.annualRecurringRevenue;
		const currentDate = dataItem["date"];
		const currentDateMoment = moment(currentDate);
		const previousDateMoment = currentDateMoment?.subtract(1, "months");
		const previousDate = previousDateMoment?.format("YYYY-MM-DD");
		let previousARR = 0;
		if (transformedData?.[index - 1]?.["date"] === previousDate) {
			previousARR = transformedData?.[index - 1]?.annualRecurringRevenue;
		}
		return {
			date: dataItem["date"],
			netNewArr: currentARR ? currentARR - (previousARR ? previousARR : 0) : null,
		};
	});

	netArrData.forEach((td) => {
		const match = previousNetArrData.find((d2) => {
			return td.date === d2.date;
		});
		if (match) {
			td.id = match.id;
		}
	});

	return netArrData;
}

export function calculateSmExpeneseNewSalesArr(transformedData, previousNetArrData) {
	const newSalesArrData = transformedData.map((dataItem, index) => {
		const currentARR = dataItem.annualRecurringRevenue;
		const currentDate = dataItem["date"];
		const currentDateMoment = moment(currentDate);
		const previousDateMoment = currentDateMoment?.subtract(1, "months");
		const previousDate = previousDateMoment?.format("YYYY-MM-DD");
		let previousARR = 0;
		if (transformedData?.[index - 1]?.["date"] === previousDate) {
			previousARR = transformedData?.[index - 1]?.annualRecurringRevenue;
		}
		return {
			date: dataItem["date"],
			newSalesArr: currentARR ? currentARR - (previousARR ? previousARR : 0) : null,
			salesAndMarketing: null,
		};
	});

	newSalesArrData.forEach((td) => {
		const match = previousNetArrData.find((d2) => {
			return td.date === d2.date;
		});
		if (match) {
			td.id = match.id;
			td.salesAndMarketing = match?.salesAndMarketing;
		}
	});

	return newSalesArrData;
}

export function compareAndSortDate(date1, date2) {
	const momentDate1 = moment(date1);
	const momentDate2 = moment(date2);
	if (momentDate1.isBefore(momentDate2)) {
		return [momentDate1, momentDate2];
	} else if (momentDate1.isAfter(momentDate2)) {
		return [momentDate2, momentDate1];
	}
	return [momentDate1, momentDate2];
}

export function calculateAcvUpdateData(customersData, revenueData, acvData) {
	const groupedCustomersData = groupBy(customersData, "date");
	const groupedRevenueData = groupBy(revenueData, "date");
	const groupedAcvData = groupBy(acvData, "date");

	const customersEarliestDate = get(customersData, "0.date", null) || moment().set("date", 1).format("YYYY-MM-DD");
	const customersLatestDate = get(customersData, `${customersData.length - 1}.date`, null) || moment().set("date", 1).format("YYYY-MM-DD");

	const revenueEarliestDate = get(revenueData, "0.date", null) || moment().set("date", 1).format("YYYY-MM-DD");
	const revenueLatestDate = get(revenueData, `${revenueData.length - 1}.date`, null) || moment().set("date", 1).format("YYYY-MM-DD");

	const [startDate] = compareAndSortDate(customersEarliestDate, revenueEarliestDate);
	const [_, endDate] = compareAndSortDate(customersLatestDate, revenueLatestDate);

	const updatedData = [];

	let currentDate = moment(startDate);
	while (currentDate <= endDate) {
		const currentDateString = moment(currentDate).format("YYYY-MM-DD");

		const id = get(groupedAcvData, `${currentDateString}[0].id`, null);

		const currentMonthNewCustomers = get(groupedCustomersData, `${currentDateString}[0].newCustomers`, null);
		const currentMonthTotalCustomers = get(groupedCustomersData, `${currentDateString}[0].totalCustomers`, null);

		const currentMonthRevenue = get(groupedRevenueData, `${currentDateString}[0].revenue`, null);
		const previousMonthDateString = moment(currentDate).subtract(1, "months").format("YYYY-MM-DD");
		const PreviousMonthRevenue = get(groupedRevenueData, `${previousMonthDateString}[0].revenue`, null);

		const currentMonthRevenueDiff = currentMonthRevenue !== null && PreviousMonthRevenue !== null ? currentMonthRevenue - PreviousMonthRevenue : null;
		const currentMonthNonNewCustomers =
			currentMonthNewCustomers !== null && currentMonthTotalCustomers !== null ? currentMonthTotalCustomers - currentMonthNewCustomers : null;

		const acvNew = currentMonthRevenue !== null && currentMonthNewCustomers ? (currentMonthRevenue * 12) / currentMonthNewCustomers : null;
		const acvRetained =
			currentMonthRevenueDiff !== null && currentMonthNonNewCustomers ? (currentMonthRevenueDiff * 12) / currentMonthNonNewCustomers : null;

		const acvBlended = currentMonthRevenue !== null && currentMonthTotalCustomers ? (currentMonthRevenue * 12) / currentMonthTotalCustomers : null;

		let acvTotal = 0;

		acvTotal = acvNew + acvRetained + acvBlended;

		if (acvNew === null && acvRetained === null && acvBlended === null) {
			acvTotal = null;
		}

		const dataItem = {
			...(id !== null && { id: id }),
			date: moment(currentDate).format("YYYY-MM-DD"),
			acvNew: acvNew !== null ? parseFloat(Number(acvNew).toFixed(2)) : null,
			acvRetained: acvRetained !== null ? parseFloat(Number(acvRetained).toFixed(2)) : null,
			acvBlended: acvBlended !== null ? parseFloat(Number(acvBlended).toFixed(2)) : null,
			acvTotal: acvTotal !== null ? parseFloat(Number(acvTotal).toFixed(2)) : null,
		};

		if (acvNew !== null || acvRetained !== null || acvBlended !== null || acvTotal !== null) {
			updatedData.push(dataItem);
		}

		currentDate = moment(currentDate).add(1, "months");
	}

	return updatedData;
}

export function calculateBurnMultipleUpdateData(burnMultipleOldData, burnAndRunwayData, netNewArrData) {
	const burnMultipleData = [];

	const burnMultipleDict = {};
	burnMultipleOldData?.forEach((item) => {
		burnMultipleDict[item?.date] = item?.id;
	});

	const netNewDict = {};
	netNewArrData.forEach((item) => {
		netNewDict[item.date] = item.netNewArr;
	});

	burnAndRunwayData.map((item) => {
		const date = item.date;
		const burn = item.burn;

		const netNew = netNewDict[date];
		if (burn !== null && netNew !== undefined) {
			const newValue = (burn / netNew) * -1;
			burnMultipleData.push({
				date: item.date,
				id: burnMultipleDict?.[date],
				burnMultiple: newValue,
			});
		}
	});

	// ---------=========================

	return burnMultipleData;
}

export function calculateMagicNumberUpdateData(magicNumberOldData, smExpenseData, netNewArrData) {
	const magicNumberData = [];

	const magicNumberDict = {};
	magicNumberOldData?.forEach((item) => {
		magicNumberDict[item?.date] = item?.id;
	});

	const netNewDict = {};
	netNewArrData.forEach((item) => {
		netNewDict[item.date] = item.netNewArr;
	});

	smExpenseData.map((smExpenseItem) => {
		const date = smExpenseItem.date;
		const smExpenseObj = smExpenseItem.salesAndMarketing;
		const netNew = netNewDict[date];

		if (smExpenseObj !== null && netNew !== undefined) {
			const newValue = netNew / smExpenseObj;
			magicNumberData.push({
				date: smExpenseItem.date,
				id: magicNumberDict?.[date],
				magicNumber: newValue,
			});
		}
	});

	return magicNumberData;
}

export async function getCapTableData(companyID) {
	const capTableData = await services
		.getCapTableData(companyID)
		.then((result) => {
			if (result.data.code === 200) {
				return result.data?.data;
			}
		})
		.catch((err) => {
			throw err;
		});
	return capTableData;
}

export async function updateCapGridData(data) {
	const capGridData = await services
		.updateCapGridData(data)
		.then((result) => {
			if (result.data.code === 200) {
				return result.data?.data;
			}
		})
		.catch((err) => {
			throw err;
		});
	return capGridData;
}

export function getGradientBgColor(ctx, context) {
	const chartHeight = context?.chart?.height || 0; // Get the chart's height
	const gradient = ctx?.createLinearGradient(0, 0, 0, chartHeight);
	gradient?.addColorStop(0, "rgba(123, 97, 255, 0.08)");
	gradient?.addColorStop(0.33, "rgba(123, 97, 255, 0.05)");
	gradient?.addColorStop(0.5, "rgba(123, 97, 255, 0.03)");
	gradient?.addColorStop(0.66, "rgba(127, 86, 217, 0.01)");
	gradient?.addColorStop(0.76, "rgba(0, 0, 0, 0)");
	gradient?.addColorStop(1, "rgba(0, 0, 0, 0)");
	return gradient;
}

export async function getAnnualRevenueGridData(companyID) {
	const annualRevenueGridData = await services
		.getAnnualRevenueGridData(companyID)
		.then((result) => {
			if (result.data.code === 200) {
				return result.data?.data;
			}
		})
		.catch((err) => {
			throw err;
		});
	return annualRevenueGridData;
}

export async function getNetArrGridData(companyID) {
	const netArrGridData = await services
		.getNetArrGridData(companyID)
		.then((result) => {
			if (result.data.code === 200) {
				return result.data?.data;
			}
		})
		.catch((err) => {
			throw err;
		});
	return netArrGridData;
}

export async function getBurnMultipeGridData(companyID) {
	const netArrGridData = await services
		.getBurnMultipleGridData(companyID)
		.then((result) => {
			if (result.data.code === 200) {
				return result.data?.data;
			}
		})
		.catch((err) => {
			throw err;
		});
	return netArrGridData;
}

export async function getMagicNumberGridData(companyID) {
	const netArrGridData = await services
		.getMagicNumberGridData(companyID)
		.then((result) => {
			if (result.data.code === 200) {
				return result.data?.data;
			}
		})
		.catch((err) => {
			throw err;
		});
	return netArrGridData;
}

export async function getMonthlyRevenueGridData(companyID) {
	const monthlyRevenueGridData = await services
		.getMonthlyRevenueGridData(companyID)
		.then((result) => {
			if (result.data.code === 200) {
				return result.data?.data;
			}
		})
		.catch((err) => {
			throw err;
		});
	return monthlyRevenueGridData;
}

export async function getBurnRunwayGridData(companyID) {
	const burnRunwayGridData = await services
		.getBurnRunwayGridData(companyID)
		.then((result) => {
			if (result.data.code === 200) {
				return result.data?.data;
			}
		})
		.catch((err) => {
			throw err;
		});
	return burnRunwayGridData;
}

export async function getPaybackGridData(companyID) {
	const paybackGridData = await services
		.getPaybackGridData(companyID)
		.then((result) => {
			if (result.data.code === 200) {
				return result.data?.data;
			}
		})
		.catch((err) => {
			throw err;
		});
	return paybackGridData;
}

export async function getCustomerGridData(companyID) {
	const customerGridData = await services
		.getCustomerGridData(companyID)
		.then((result) => {
			if (result.data.code === 200) {
				return result.data?.data;
			}
		})
		.catch((err) => {
			throw err;
		});
	return customerGridData;
}

export const eventBus = {
	on(event, callback) {
		document.addEventListener(event, (e) => callback(e.detail));
	},
	dispatch(event, data) {
		document.dispatchEvent(new CustomEvent(event, { detail: data }));
	},
	remove(event, callback) {
		document.removeEventListener(event, callback);
	},
};

export const events = {
	arrUpdated: "ArrUpdate",
	amountRaisedUpdated: "AmountRaisedUpdate",
	ownerShipUpdated: "OwnerShipUpdate",
	customersUpdated: "CustomersUpdated",
	revenueUpdated: "RevenueUpdated",
};

export async function getRevenueGridData(companyID) {
	const revenueGridData = await services
		.getRevenueGridData(companyID)
		.then((result) => {
			if (result.data.code === 200) {
				return result.data?.data;
			}
		})
		.catch((err) => {
			throw err;
		});
	return revenueGridData;
}

export async function updateRevenueGridData(data) {
	const revenueGridData = await services
		.updateRevenueGridData(data)
		.then((result) => {
			if (result.data.code === 200) {
				return result.data?.data;
			}
		})
		.catch((err) => {
			throw err;
		});
	return revenueGridData;
}

export async function updateBurnMultipleGridData(data) {
	const burnMultipleGridData = await services
		.updateBurnMultipleGridData(data)
		.then((result) => {
			if (result.data.code === 200) {
				return result.data?.data;
			}
		})
		.catch((err) => {
			throw err;
		});
	return burnMultipleGridData;
}

export async function updateMagicNumberGridData(data) {
	const burnMultipleGridData = await services
		.updateMagicNumberGridData(data)
		.then((result) => {
			if (result.data.code === 200) {
				return result.data?.data;
			}
		})
		.catch((err) => {
			throw err;
		});
	return burnMultipleGridData;
}

export async function getAcvGridData(companyID) {
	const acvGridData = await services
		.getAcvGridData(companyID)
		.then((result) => {
			if (result.data.code === 200) {
				return result.data?.data;
			}
		})
		.catch((err) => {
			throw err;
		});
	return acvGridData;
}

export async function updateAcvGridData(data) {
	const acvGridData = await services
		.updateAcvGridData(data)
		.then((result) => {
			if (result.data.code === 200) {
				return result.data?.data;
			}
		})
		.catch((err) => {
			throw err;
		});
	return acvGridData;
}

export async function getSmExpenseVsNewSalesGridData(companyID) {
	const acvGridData = await services
		.getSmExpenseVsNewSalesGridData(companyID)
		.then((result) => {
			if (result.data.code === 200) {
				return result.data?.data;
			}
		})
		.catch((err) => {
			throw err;
		});
	return acvGridData;
}

export async function updateSmExpenseVsNewSalesGridData(data) {
	const acvGridData = await services
		.updateSmExpenseVsNewSalesGridData(data)
		.then((result) => {
			if (result.data.code === 200) {
				return result.data?.data;
			}
		})
		.catch((err) => {
			throw err;
		});
	return acvGridData;
}

export const toolTipInfo = {
	arr: `
		Annual Recurring Revenue (ARR) is the total annualized value of a company’s customer contracts.		
		ARR = Sum of revenue from all customers x 12
	`,
	netArr: "Net New ARR is how much total ARR a company adds in a period, net of churn.",
	mrr: "Monthly Recurring Revenue (MRR) is the total monthly value of a company’s customer contracts.",
	revenue: "Revenue is the sum of revenue in a period across all customers. Revenue is an important KPI for usage/transaction based businesses.",
	customers: "The Customers chart tracks the number of active, paying customers a company has over time.",
	acv: "Average Contract Value (ACV) is the average ARR per active customer.",
	smExpenseVsNewSales: "Comparing Sales & Marketing (S&M) spend to ARR from new customers helps show how efficiently S&M spend translates to new revenue.",
	cacPayback:
		"CAC Payback Period measures how many months it takes a cohort of customers to produce enough Gross Profit to pay back the sales and marketing expenses it took to acquire those customers.",
	burnAndRunway: "Burn measures the change in a company’s cash balance in a month.",
	capTable: "capTable",
};

export async function getWaitList() {
	const waitlist = await services
		.getWaitListApi()
		.then((result) => {
			if (result.data.code === 200) {
				return result.data;
			}
		})
		.catch((err) => {
			throw err;
		});
	return waitlist;
}

export async function updateWaitList(data) {
	const updatedWaitlist = await services
		.updateWaitListApi(data)
		.then((result) => {
			if (result.data.code === 200) {
				return result.data;
			}
		})
		.catch((err) => {
			throw err;
		});
	return updatedWaitlist;
}

export async function dataCollectionFromPartner({ partner, payload }) {
	const dataCollected = await services
		.collectingDataFromPartnerApi({ partner, payload })
		.then((result) => {
			if (result.data.code === 200) {
				return result.data;
			}
		})
		.catch((err) => {
			throw err;
		});
	return dataCollected;
}

export const redirectUri = process.env.REACT_APP_PARTNER_REDIRECTION_SUCCESS;
const partnerXeroClientId = process.env.REACT_APP_PARTNER_XERO_CLIENT_ID;
const partnerIntuitClientId = process.env.REACT_APP_PARTNER_INTUIT_CLIENT_ID;

export const partners = [
	{
		displayName: "Carta",
		key: "carta",
		title: "CARTA",
		picture: LINK_PARTNER_CARTA_SMALL,
		redirectUrl: "",
		isComplete: false,
	},
	{
		displayName: "Xero",
		key: "xero",
		title: "XERO",
		picture: LINK_PARTNER_XERO_SMALL,
		redirectUrl: (state) =>
			`https://login.xero.com/identity/connect/authorize?response_type=code&client_id=${partnerXeroClientId}&redirect_uri=${redirectUri}/xero&scope=openid profile email accounting.transactions accounting.settings.read&state=${state}`,
		clientId: process.env.REACT_APP_PARTNER_XERO_CLIENT_ID,
		isComplete: true,
	},
	{
		displayName: "Stripe",
		key: "stripe",
		title: "STRIPE",

		picture: LINK_PARTNER_STRIPE_SMALL,
		redirectUrl: "",
		isComplete: false,
	},
	{
		displayName: "Intuit Quickbooks",
		key: "quickbooks",
		title: "QUICKBOOKS",
		picture: LINK_PARTNER_QUICKBOOKS_SMALL,
		redirectUrl: (state) =>
			`https://appcenter.intuit.com/connect/oauth2?client_id=${partnerIntuitClientId}&scope=com.intuit.quickbooks.accounting&response_type=code&state=${state}&redirect_uri=${redirectUri}/quickbooks`,
		isComplete: true,
	},
	{
		displayName: "Salesforce",
		key: "salesforce",
		title: "SALESFORCE",

		picture: LINK_PARTNER_SALESFORCE_SMALL,
		redirectUrl: "",
		isComplete: false,
	},
	{
		displayName: "Hubspot",
		key: "hubspot",
		title: "HUBSPOT",

		picture: LINK_PARTNER_HUBSPOT_SMALL,
		redirectUrl: "",
		isComplete: false,
	},
	{
		displayName: "Plaid",
		key: "plaid",
		title: "PLAID",
		picture: LINK_PARTNER_PLAID_SMALL,
		redirectUrl: "",
		isComplete: true,
	},
	{
		displayName: "Mixpanel",
		key: "mixpanel",
		title: "MIXPANEL",
		picture: LINK_PARTNER_MIXPANEL_SMALL,
		redirectUrl: "",
		isComplete: false,
	},
	{
		displayName: "Excel",
		key: "excel",
		title: "EXCEL",
		picture: LINK_PARTNER_EXCEL_SMALL,
		redirectUrl: "",
		isComplete: true,
	},
	{
		displayName: "Google Sheets",
		key: "googleSheets",
		title: "GOOGLE_SHEET",
		picture: LINK_PARTNER_GOOGLE_SHEET,
		redirectUrl: "",
		isComplete: true,
	},
];

export async function getPlaidLinkToken() {
	const plaidPublicToken = await services
		.getPlaidLinkTokenApi()
		.then((result) => {
			if (result.data.code === 200) {
				return result.data;
			}
		})
		.catch((err) => {
			throw err;
		});
	return plaidPublicToken;
}

export async function fetchPlaidData(payload) {
	const plaidPublicToken = await services
		.fetchPlaidDataApi(payload)
		.then((result) => {
			if (result?.data?.code === 200) {
				return result?.data?.data;
			}
		})
		.catch((err) => {
			throw err;
		});
	return plaidPublicToken;
}

export async function getCompanyDetails() {
	const companyDetails = await services
		.getCompanyProfile()
		.then((result) => {
			if (result?.data?.code === 200) {
				return result?.data?.data;
			}
		})
		.catch((err) => {
			throw err;
		});
	return companyDetails;
}

export async function getPlaidAccounts(assetReportId) {
	const plaidAccounts = await services
		.getPlaidAccounts(assetReportId)
		.then((result) => {
			if (result?.data?.code === 200) {
				return result?.data?.data;
			}
		})
		.catch((err) => {
			throw err;
		});
	return plaidAccounts;
}

export async function sendPlaidAccounts({ assetReportId, accountIds }) {
	const plaidAccounts = await services
		.sendPlaidAccounts({ assetReportId, accountIds })
		.then((result) => {
			if (result?.data?.code === 200) {
				return result?.data?.data;
			}
		})
		.catch((err) => {
			throw err;
		});
	return plaidAccounts;
}
