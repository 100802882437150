import styled from "@emotion/styled";

export const ContentWrapper = styled.div(({ theme, value }) => {
  return {
    background:
      value < 80
        ? `linear-gradient(white, white) padding-box,
              linear-gradient(to right, #E97300, #FFD141) border-box`
        : "transparent",
    borderRadius: "4px",
    border: value < 80 ? "2px solid transparent" : "2px solid #00CE7D",
    padding: theme.spacing(2),
  };
});
