import React from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import theme from "../../constants/Theme";
import { IconComponent } from "./styles";
import PropTypes from "prop-types";

const IconPicker = ({
  selectedIcon,
  setSelectedIcon,
  icons,
  iconHeight,
  iconWidth,
  imageStyle,
  isCustomIcon,
  mt = 5,
}) => {
  const isSelected = icon => {
    if (isCustomIcon) {
      return selectedIcon && selectedIcon.includes(icon.url);
    } else {
      return selectedIcon && selectedIcon.includes(icon);
    }
  };
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("md"));
  return (
    <Box
      display="grid"
      //  gridTemplateColumns="repeat(12, 1fr)"
      gridAutoRows="1fr"
      gap={2}
      sx={{
        mt: mt,
        gridTemplateColumns: isCustomIcon
          ? "repeat(10, 1fr)"
          : "repeat(12, 1fr)",

        [theme.breakpoints.down("md")]: {
          width: 1,
          gridTemplateColumns: isCustomIcon
            ? "repeat(10, 1fr)"
            : "repeat(12, 1fr)",
        },
      }}
    >
      {icons.map((icon, index) => {
        return (
          <Box
            sx={{
              gridColumn: {
                lg: "span 2",
                md: "span 2",
                sm: "span 2",
                xs: "span 2",
              },
              display: "flex",
              flexDirection: "column",
              "&:hover": {
                color: "primary.main",
              },
            }}
            alignItems="center"
          >
            <IconComponent
              selected={isSelected(icon)}
              onClick={e =>
                isCustomIcon ? setSelectedIcon(icon.url) : setSelectedIcon(icon)
              }
              gradient={
                isCustomIcon
                  ? theme.palette.primary.gradient
                  : theme.palette.primary.sys_light_inverse
              }
            >
              {isCustomIcon ? (
                <icon.component
                  sx={{
                    "&:hover": { color: "primary.main" },
                    color: isSelected(icon)
                      ? "secondary.white"
                      : "secondary.main",
                    fontSize: isSmallScreen ? "16px" : "24px",
                  }}
                />
              ) : (
                <img
                  src={icon}
                  height={isSmallScreen ? "30px" : iconHeight}
                  width={isSmallScreen ? "30px" : iconWidth}
                  style={imageStyle}
                />
              )}
            </IconComponent>
            <Typography
              sx={{
                mt: 1,
                color: isSelected(icon) ? "primary.main" : "inherit",
              }}
              variant="title_small"
              align="center"
            >
              {isCustomIcon ? icon.text : ""}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
};

{
  IconPicker.propTypes = {
    selectedIcon: PropTypes.array || PropTypes.string,
    setSelectedIcon: PropTypes.func,
    icons: PropTypes.array,
    iconHeight: PropTypes.string,
    iconWidth: PropTypes.string,
    imageStyle: PropTypes.object,
    isCustomIcon: PropTypes.bool,
  };

  IconPicker.defaultProps = {
    selectedIcon: [],
    setSelectedIcon: null,
    //can be array of strings or array of objects
    icons: [],
    iconHeight: "50px",
    iconWidth: "50px",
    imageStyle: null,
    isCustomIcon: false,
  };
}

export default IconPicker;
