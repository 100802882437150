import React, { Fragment, useEffect, useState, useRef } from "react";
import {
  Box,
  Typography,
  CircularProgress,
  useMediaQuery,
  Button,
  Divider,
  Icon,
  MenuList,
  Grid,
  MenuItem,
  Card,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import PowerUPLayout from "../../../../layout/PowerUP";
import Sidebar from "../../../../components/Workspace/PowerUp/Sidebar";
import BreadcrumbsComp from "./Components/BreadcrumbsComp";
import { getPowerUPData, getSidebarContent } from "./Functions/common";
import { NOTES_ICON } from "../../../../constants/image";
import constants from "../../../../constants";
import Tabs from "../../../../components/Workspace/Tabs";
import theme from "../../../../constants/Theme";
import CompletedInterviews from "../../../../components/Workspace/PowerUp/CompletedInterviews";
import { PROFILE_PLACEHOLDER } from "../../../../constants/image";
import { getCurrentDate } from "../../../../utils/date";
import AggregateNotesPdf from "../../../../components/Workspace/AggregateNotesPdf";
import ROUTES_PATH from "../../../../constants/routes";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import ExportPopover from "../../../../common/ExportPopover";
import ScriptEditor from "../../../../common/ScriptEditor";
import CardWrapper from "./Components/CardWrapper";

const AggregatedNotes = () => {
  const [powerupDetails, setPowerupDetails] = useState("");
  const [powerupDetailsLoader, setPowerupDetailsLoader] = useState(false);
  const [SIDEBARDATA, setSidebarData] = useState({});
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("md"));
  const tabitems = constants.EXCERCISE_TAB_ITEMS;
  const [currenttab, setcurrenttab] = useState(tabitems[0].key);
  const [isAscending, setIsAscending] = useState(true);
  const history = useHistory();
  const summaryGridRef = useRef(null);
  const powerUpId = "4";
  const marketTest = useSelector(store => store.powerUp?.marketTest);
  const [selectedRecords, setSelectedRecords] = useState([]);

  const [candidateRecords, setCandidateRecords] = useState([]);

  const powerupModuleDetails = useSelector(
    store => store?.powerUp?.powerupDetails
  );
  useEffect(() => {
    let lastStepData = powerupModuleDetails.data.moduleStepResponses.filter(
      item => item.isLastStep
    );

    let answersObj;
    if (lastStepData) {
      answersObj = JSON.parse(lastStepData[0].answers);
    }
    if (answersObj.candidateRecords) {
      answersObj.candidateRecords.map((item, idx) => {
        item.selected = false;
        item.id = idx + 1;
        return item;
      });

      setCandidateRecords(answersObj.candidateRecords);
    }
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    setPowerupDetailsLoader(true);
    getPowerUPData(powerUpId).then(res => {
      setPowerupDetails(res);
    });
    getSidebarContent(powerUpId).then(res => {
      setSidebarData(res);
    });
    setPowerupDetailsLoader(false);
  }, []);

  const handleExportClick = type => {
    summaryGridRef.current.handleExport(type);
  };
  const FooterComponent = () => {
    return (
      <>
        <Box
          justifyContent={"center"}
          sx={{ display: { xs: "flex", sm: "flex", md: "none" } }}
        >
          <Button
            onClick={() =>
              history.push(`${ROUTES_PATH.MARKET_TEST}/${marketTest.id}`)
            }
            sx={{ m: 2 }}
          >
            Done
          </Button>
        </Box>
        <Divider
          sx={{
            background: `${theme.palette.secondary.outlineXLight}`,
            mt: 3,
            display: { xs: "none", sm: "none", md: "block" },
          }}
        />
        <Box
          justifyContent={"right"}
          sx={{ display: { xs: "none", sm: "none", md: "flex" }, mt: 3 }}
        >
          <Box>
            <Button
              onClick={() =>
                history.push(`${ROUTES_PATH.MARKET_TEST}/${marketTest.id}`)
              }
            >
              Return to PowerUp Details
            </Button>
          </Box>
        </Box>
      </>
    );
  };

  const TabsComponent = () => {
    return (
      <Tabs
        tabitems={tabitems}
        currenttab={currenttab}
        setcurrenttab={setcurrenttab}
        color="primary"
        spacing={0}
        gridColumn={{
          lg: "none",
          md: "none",
          sm: "span 6",
          xs: "span 6",
        }}
        width={1}
      />
    );
  };
  const HandleOnChange = (e, id, list, ascending) => {
    let listData = [...list];
    listData.map(item => {
      if (item.id === id) {
        item.selected = !item.selected;
        return item;
      }
    });
    setIsAscending(ascending);
    setCandidateRecords(listData);
    const selectedData = listData.filter(item => item.selected === true);

    setSelectedRecords(selectedData);
  };

  const MainContent = () => {
    return (
      <Box>
        <Box sx={{ mt: 2 }}>
          {isSmallScreen && (
            <Typography
              variant="overlineLarge"
              color={"GrayText.secondary"}
              textTransform={"uppercase"}
            >
              {marketTest?.powerUpModel?.powerUpName}
            </Typography>
          )}
        </Box>

        <Box display={"flex"} alignItems={"center"} sx={{ mt: 3 }} gap={3}>
          <Icon
            sx={{
              borderRadius: "50%",
              borderColor: "primary.main",
              borderStyle: "solid",
              p: 1,
            }}
          >
            <ManageSearchIcon sx={{ color: "primary.main" }} />
          </Icon>

          <Typography variant="headline5" color="primary">
            Aggregate Notes
          </Typography>
        </Box>

        <Box sx={{ mt: 3 }}>
          <Typography variant="subhead1">
            Bring the gems, verbatims and insights together from all the
            interviews. Look for strong negative and positive patterns and
            highlight the key insights that you can act on.
          </Typography>
        </Box>

        <Box sx={{ width: 1 }}>
          <AggregateNotesPdf
            interviewList={candidateRecords}
            ref={summaryGridRef}
          />
        </Box>

        <Box
          sx={{
            mt: 3,
            [theme.breakpoints.down("md")]: {
              background: theme.palette.text.background,
            },
          }}
        >
          <Card
            sx={theme => ({
              [theme.breakpoints.up("md")]: {
                borderRadius: theme.shape.standard.borderRadius,
                p: 2,

                boxShadow: theme.palette.primary.elevationLight3,
              },
            })}
          >
            <Typography variant="title_medium">
              Your Completed Interviews
            </Typography>
            <CompletedInterviews
              completedInterviewList={candidateRecords}
              HandleOnChange={HandleOnChange}
              isAscendingData={isAscending}
            />
          </Card>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            my: 3,
          }}
        >
          <Typography variant="title_medium">
            Review your selected notes
          </Typography>

          <ExportPopover text={"Export"} variant="outlined">
            <MenuList id="split-button-menu" autoFocusItem>
              <MenuItem onClick={event => handleExportClick("pdf")}>
                Export to PDF
              </MenuItem>
              <MenuItem onClick={event => handleExportClick("png")}>
                Export to PNG
              </MenuItem>
            </MenuList>
          </ExportPopover>
        </Box>

        <Box
          sx={theme => ({
            //border: `1px solid ${theme.palette.secondary.dark}`,
            borderRadius: theme.shape.standard.borderRadius,
            boxShadow: theme.palette.primary.elevationLight3,
            px: 3,
            py: selectedRecords.length ? 1 : 3,
            mt: 1,
            //background: theme.palette.text.background,
          })}
        >
          {selectedRecords.map((interview, index) => {
            return (
              <Box>
                <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
                  <Box
                    component="img"
                    sx={{ borderRadius: "50%" }}
                    height="44px"
                    src={
                      interview?.avatar
                        ? interview?.avatar
                        : PROFILE_PLACEHOLDER
                    }
                  ></Box>
                  <Box sx={{ ml: 2 }}>
                    <Typography
                      variant="bodyLarge"
                      sx={{ color: "secondary.black" }}
                    >
                      {interview.name?.trim() || interview.email}
                    </Typography>
                    <br />
                    <Typography
                      variant="bodyMedium"
                      sx={{ color: "secondary.main" }}
                    >
                      {interview.email}
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ my: 2 }}>
                  <Grid container sx={{ mt: 2 }} alignItems="center">
                    <Grid item xs={5}>
                      <Typography variant={"labelLarge"}>PowerUp</Typography>:
                    </Grid>
                    <Grid item xs={7}>
                      <Typography variant={"bodyMedium"}>
                        {marketTest?.powerUpModel?.powerUpName}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container sx={{ mt: 2 }} alignItems="center">
                    <Grid item xs={5}>
                      <Typography variant={"labelLarge"}>
                        Interview Date
                      </Typography>
                      :
                    </Grid>
                    <Grid item xs={7}>
                      <Typography variant={"bodyMedium"}>
                        {getCurrentDate(interview.scheduledDate)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Box sx={{ mt: 5 }}>
                  <Typography variant="largeTitle">Notes Recorded</Typography>
                </Box>
                <Box sx={{ mt: 2 }}>
                  <Typography variant="bodyMedium">
                    <ScriptEditor
                      initialValue={
                        interview.records.filter(itm => itm.key === "notes")[0]
                          .value
                      }
                      readOnly
                      inlineToolbar={false}
                      inlineToolbarControls={[]}
                      controls={[]}
                      onChange={() => {}}
                    />
                  </Typography>
                </Box>

                {selectedRecords.length !== 1 &&
                selectedRecords.length - 1 !== index ? (
                  <Divider
                    sx={{
                      background: `${theme.palette.secondary.outlineXLight}`,
                      mt: 3,
                    }}
                  />
                ) : (
                  ""
                )}

                {/*<Divider sx={{ my: 2 }} />*/}
              </Box>
            );
          })}
        </Box>
        <Box sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
          <FooterComponent />
        </Box>
      </Box>
    );
  };

  return (
    <PowerUPLayout
      main={
        <Fragment>
          {powerupDetailsLoader ? (
            <Box justifyContent={"center"} sx={{ display: "flex", mt: 5 }}>
              <CircularProgress />
            </Box>
          ) : (
            <CardWrapper>
              <MainContent />
            </CardWrapper>
          )}
        </Fragment>
      }
      sidebar={
        isSmallScreen ? (
          <Box display={"flex"} flexDirection={"column"}>
            <Box sx={{ my: 3 }}>
              <TabsComponent />
            </Box>
            <Sidebar sidebar_content={powerupDetails} />
          </Box>
        ) : (
          <Sidebar sidebar_content={powerupDetails} />
        )
      }
      breadcrumbs={<BreadcrumbsComp powerupDetails={powerupDetails} />}
      tab={currenttab}
      footerComponent={<FooterComponent />}
      showDivider={true}
    />
  );
};

export default AggregatedNotes;
