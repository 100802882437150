import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { ProfileContext } from "../..";
import { StyledProfileCard } from "../../style";
import { PARTNER_QA_ICON } from "../../../../constants";
import { setCommonDialogConfirmationData } from "../../../../modules/actions";
import { useDispatch, useSelector } from "react-redux";

function PartnerQA() {
	const { partnerQA, profileData, founderDetails } = useContext(ProfileContext);
	const userId = useSelector((store) => store?.auth?.userId);

	const dispatch = useDispatch();
	const [seeAll, setSeeAll] = useState(false);
	const openEditQADialog = () => {
		dispatch(setCommonDialogConfirmationData({ isOpen: true, title: "Edit Q&A" }));
	};
	return (
		<StyledProfileCard>
			<Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
				<Box display={"flex"} alignItems={"center"} gap={2}>
					<Box component={"img"} src={PARTNER_QA_ICON} sx={{ height: "24px", width: "24px" }} />
					<Typography
						variant="Text/sm/Semibold"
						component={"div"}
						sx={{
							color: "black",
						}}
					>
						Partner Q&A
					</Typography>
				</Box>
				<Box>
					<Button sx={{ maxWidth: "fit-content" }} variant="noOutlineNew" onClick={() => setSeeAll(!seeAll)}>
						{seeAll ? "Collapse" : "See All"}
					</Button>
					{founderDetails?.id == userId && (
						<Button variant="DS1" onClick={() => openEditQADialog()}>
							Edit Q&A
						</Button>
					)}
				</Box>
			</Box>

			<Grid container mt={2} rowSpacing={2} spacing={2}>
				<Grid item sm={12} md={5} lg={5} xl={5}>
					<Typography variant="Text/sm/Semibold" sx={{ color: "#667085" }}>
						Question
					</Typography>
				</Grid>
				<Grid item sm={12} md={7} lg={7} xl={7}>
					<Typography variant="Text/sm/Semibold">Answer</Typography>
				</Grid>
				{partnerQA
					?.filter((item, idx) => (seeAll ? true : idx < 3))
					?.map((partnerItem, idx) => {
						return (
							<>
								<Grid item sm={12} md={5} lg={5} xl={5}>
									<Typography variant="Text/sm/Regular" sx={{ color: "#667085" }}>
										{`${idx + 1}. ${partnerItem?.question?.name}`}
									</Typography>
								</Grid>
								<Grid item sm={12} md={7} lg={7} xl={7}>
									<Typography sx={{ wordBreak: "break-word" }} variant="Text/sm/Semibold">
										{" "}
										{partnerItem?.answers?.[0]?.answerText || "No Data"}
									</Typography>
								</Grid>
							</>
						);
					})}
			</Grid>
		</StyledProfileCard>
	);
}

export default PartnerQA;
