import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Button, Box, MenuItem, Link, useMediaQuery, Chip } from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { setInvestorDashboardData } from "../../../../modules/actions";
import SelectInputNoOutline from "../../../../common/SelectInputNoOutline";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import FormatListBulletedOutlinedIcon from "@mui/icons-material/FormatListBulletedOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { getFilterValues } from "../../../../utils/commonFunctions";

const FilterHeader = ({
	handleClose,
	handdleDelete,
	handleOpenSaveAs,
	disableSave,
	filterResult,
	setFilterResult,
	isFilterApplied,
	setIsFilterApplied,
	totalCompanyCount,
	handleClearAll,
}) => {
	const [displayValue, setDisplayValue] = useState("flex");
	const { filteredValue, savedSearchesData, savedFilterEditData } = useSelector((state) => state?.investorDashboard) || [];

	const dispatch = useDispatch();
	const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

	useEffect(() => {
		if (isSmallScreen) {
			setDisplayValue("block");
		} else {
			setDisplayValue("flex");
		}
	});

	const handleChangeSavedSearch = (e) => {
		const selectedSavedSearchForEdit = savedSearchesData.find((savedSearch) => savedSearch.id === e.target.value);
		if (selectedSavedSearchForEdit) {
			dispatch(
				setInvestorDashboardData({
					savedFilterEditData: {
						id: selectedSavedSearchForEdit?.id,
						name: selectedSavedSearchForEdit?.name,
						userId: selectedSavedSearchForEdit?.userId,
						isEdit: true,
					},
					filteredValue: selectedSavedSearchForEdit?.savedFilterArray,
				})
			);
		}
	};

	return (
		<Box
			sx={{
				p: 4,
				boxShadow: "0px 5px 15px rgb(0 0 0 / 10%)",
				mb: 1,
				...(savedFilterEditData?.isEdit && { minHeight: "200px" }),
			}}
		>
			<Box
				sx={{
					display: "flex",
					width: "100%",
					alignItems: "center",
				}}
			>
				<Typography sx={{ color: (theme) => theme.palette.secondary.black, width: "fit-content" }} variant="card_name">
					Filters
				</Typography>

				<CloseIcon onClick={handleClose} sx={{ ml: "auto", cursor: "pointer" }} />
			</Box>
			{savedFilterEditData?.isEdit && (
				<Box sx={{ mt: 4 }}>
					<Typography
						variant="testHeading"
						sx={{
							mr: "auto",
						}}
					>
						{`Saved Searches [${savedSearchesData?.length}]: `}
					</Typography>
					<SelectInputNoOutline
						selectedValue={savedFilterEditData?.id}
						onInputChange={handleChangeSavedSearch}
						IconComponent={KeyboardArrowDown}
						autoWidth
					>
						{savedSearchesData.map((savedSearch) => (
							<MenuItem key={savedSearch.id} value={savedSearch.id}>
								<Typography variant="testHeading">{savedSearch.name}</Typography>
							</MenuItem>
						))}
					</SelectInputNoOutline>
				</Box>
			)}
			<Box
				sx={{
					display: displayValue,
					width: "100%",
					alignItems: "center",
					mt: 3,
				}}
			>
				<Box display={"flex"} gap={1} justifyContent={"left"} alignItems={"center"}>
					<Typography variant="Text/sm/Semibold" component={"p"}>
						Startups found: {totalCompanyCount} |
					</Typography>

					<Typography variant="Text/sm/Semibold" component={"p"}>
						{filteredValue?.length ? filteredValue?.length : 0} Filters:
					</Typography>
					<Box>
						<Link variant="Text/sm/Semibold" onClick={handleClearAll} sx={{ cursor: "pointer" }} underline="hover" color={"#7B61FF"}>
							Clear All
						</Link>
					</Box>
				</Box>
				<Box display={"flex"} gap={1} justifyContent={"right"} alignItems={"center"} sx={{ ml: "auto", mt: isSmallScreen && 2 }}>
					<Box>
						<Button
							sx={{
								width: "max-content",
							}}
							variant="containedGrayStyle"
							onClick={() => {
								setFilterResult(true);
								handleClose();
								setIsFilterApplied(true);
							}}
							startIcon={<CancelOutlinedIcon />}
						>
							Close
						</Button>
					</Box>
					{filteredValue.length > 0 && (
						<Box>
							<Button
								sx={{
									width: "max-content",
								}}
								variant="containedPurpleStyle"
								onClick={handleOpenSaveAs}
								startIcon={<FavoriteBorderOutlinedIcon />}
							>
								{savedFilterEditData?.isEdit ? "Save" : "Save As"}
							</Button>
						</Box>
					)}
					<Box>
						{" "}
						<Button
							variant="containedPurpleStyle"
							sx={{
								width: "max-content",
							}}
							startIcon={<FormatListBulletedOutlinedIcon />}
							onClick={() => {
								setFilterResult(true);
								handleClose();
								setIsFilterApplied(true);
							}}
						>
							Results
						</Button>
					</Box>
				</Box>
			</Box>
			<Box
				sx={{
					display: "flex",
					width: "100%",
					alignItems: "center",
					mt: 3,
				}}
			>
				<Box
					className="SOSShowVerticalScroll"
					display={"flex"}
					flexWrap={"wrap"}
					sx={{ maxHeight: "100px", width: "100%", overflowY: "scroll", mt: 3 }}
				>
					{filteredValue &&
						filteredValue.map((item, index) => {
							return (
								<Box
									sx={{
										mr: 1,
									}}
								>
									<Chip
										sx={{
											m: 1,
											backgroundColor: "var(--gray-100, #F2F4F7)",
											"& .MuiChip-deleteIcon": {
												color: "rgba(52, 64, 84, 1)",
												"&:hover": { color: "rgba(52, 64, 84, 1)" },
											},
										}}
										label={
											<Typography sx={{ color: "rgba(52, 64, 84, 1)" }} variant="Text/xs/Regular">
												{getFilterValues(item?.type, item?.checkboxValue)}
											</Typography>
										}
										onDelete={() => handdleDelete(item?.checkboxValue, item?.type)}
										deleteIcon={<CloseIcon id={item?.checkboxValue} />}
									/>
								</Box>
							);
						})}
				</Box>
			</Box>
		</Box>
	);
};

export default FilterHeader;
