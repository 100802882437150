import React, { useEffect, useMemo, useRef, useState } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Slide, Typography } from "@mui/material";
import useDimensions from "react-cool-dimensions";
import { stateFromHTML } from "draft-js-import-html";
import { stateToHTML } from "draft-js-export-html";
import { convertFromRaw, ContentState, convertToRaw } from "draft-js";
import MUIRichTextEditor from "mui-rte";
import { Close } from "@mui/icons-material";

import { ALERT_HEX, CURRENCY_DOLLAR_CIRCLE_ICON } from "../../../../../constants";
import { TextArea } from "../../../components/inputs/TextArea";
import LeanCanvasLoader from "./PersonaLoader";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="left" ref={ref} {...props} />;
});

const BUILDER_TITLE = ["Role & Common Titles", "Name", "Background", "Environment", "Potential Objections", "Goals", "Pain Points", "Demographics", "Traits"];

const CanvasItemEditor = ({ value, onSave, title, icon }) => {
	const [initialContent, setInitialContent] = useState("");
	const [open, setopen] = useState(false);

	const editorRef = useRef();

	// Convert the provided value (HTML or plain text) to a format that MUIRichTextEditor understands
	useEffect(() => {
		if (value) {
			let contentState;
			if (/<[a-z][\s\S]*>/i.test(value)) {
				// Check if the value has HTML tags
				contentState = stateFromHTML(value); // Convert HTML to draft-js ContentState
			} else {
				contentState = ContentState.createFromText(value); // Convert plain text to ContentState
			}
			if (contentState) {
				setInitialContent(JSON.stringify(convertToRaw(contentState)));
			}
		}
	}, [value]);

	const handleSave = (data) => {
		const currentContent = typeof data === "string" ? JSON.parse(data) : data;
		if (currentContent) {
			const contentState = convertFromRaw(currentContent);
			if (contentState) {
				const htmlContent = stateToHTML(contentState);
				if (typeof onSave === "function") onSave(htmlContent);
			}
		}
		setopen(false);
	};

	const saveContent = () => {
		if (editorRef.current) {
			editorRef?.current?.save();
		} else {
			setopen(false);
		}
	};

	return (
		<React.Fragment>
			<TextArea usePrintable tight value={value} onClick={() => setopen(true)} />
			<Dialog
				TransitionComponent={Transition}
				transitionDuration={600}
				fullWidth
				open={open}
				disableBackDropClick
				PaperProps={{
					sx: (theme) => ({
						borderRadius: theme.shape.standard13.borderRadius,
						boxShadow: "0px 5px 16px rgba(0, 0, 0, 0.3)",
						p: 3,
					}),
				}}
			>
				<DialogTitle
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<Box
						sx={{
							display: "flex",
							gap: ".75rem",
							alignItems: "center",
						}}
					>
						<Box component={"img"} src={icon} sx={{ height: "2rem", width: "2rem" }} />
						<Typography variant="Text/sm/Semibold">{title}</Typography>
					</Box>
					<IconButton
						aria-label="close"
						onClick={() => saveContent()}
						sx={{
							color: (theme) => theme.palette.secondary.dark,
						}}
					>
						<Close sx={{ color: (theme) => theme.palette.secondary.black }} />
					</IconButton>
				</DialogTitle>

				<DialogContent
					sx={{
						px: 4,
					}}
				>
					<MUIRichTextEditor
						ref={editorRef}
						defaultValue={initialContent}
						controls={["numberList", "bulletList"]}
						onSave={(data) => {
							handleSave(data);
						}}
					/>
				</DialogContent>
				<DialogActions>
					<Box display={"flex"} justifyContent={"flex-end"}>
						<Button variant="DS1" small onClick={() => saveContent()}>
							Close
						</Button>
					</Box>
				</DialogActions>
			</Dialog>
		</React.Fragment>
	);
};

const PersonaCard = ({ title, icon, children, sx }) => (
	<Box
		sx={{
			display: "flex",
			padding: "1.5rem",
			flexDirection: "column",
			alignItems: "flex-start",
			gap: "1.5rem",
			flex: "1 0 0",
			alignSelf: "stretch",
			borderRadius: "2rem",
			background: "#fff",
			boxShadow: "0px 10px 25px 0px rgba(0, 0, 0, 0.05)",
			...sx,
		}}
	>
		<Box
			sx={{
				display: "flex",
				gap: ".75rem",
				alignItems: "center",
			}}
		>
			{icon && <Box component={"img"} src={icon} sx={{ height: "2rem", width: "2rem" }} />}
			{title && <Typography variant="Text/sm/Semibold">{title}</Typography>}
		</Box>
		{children}
	</Box>
);

const PersonaGrid = ({ showLoader, gridData, updateInput, usePrintable, isPlatformActivity = false }) => {
	const [parsedData, setParsedData] = useState(null);
	const [saveReady, setsaveReady] = useState(false);
	// useRef to store the latest version of parsedData
	const parsedDataRef = useRef(parsedData);

	const { observe, width } = useDimensions({
		onResize: ({ width }) => {},
	});

	useEffect(() => {
		observe();
	}, [observe]);

	useEffect(() => {
		try {
			if (gridData) {
				const parsed = JSON.parse(gridData);
				setParsedData(parsed);
			} else {
				setParsedData(null);
			}
		} catch (e) {
			console.log("Unable to parse JSON, failed with error:", gridData, e);
		}
	}, [gridData]);

	useEffect(() => {
		parsedDataRef.current = parsedData;
		if (saveReady) saveToApi(parsedData);
	}, [parsedData]);

	const saveToApi = (data) => {
		setsaveReady(false);
		try {
			const stringified = JSON.stringify(data);
			if (typeof updateInput === "function") updateInput(stringified);
		} catch (e) {
			console.log("Unable to stringify JSON, failed with error:", data, e);
		}
	};

	const handleInputChange = (fieldName, data) => {
		setsaveReady(true);
		setParsedData({ ...parsedData, [fieldName]: data });
	};

	const getGridArea = (index) => {
		let gridArea = "1 / 1 / 1 / 1";

		if (index === 1) {
			gridArea = "1 / 2 / 1 / 3";
		} else if (index === 2) {
			gridArea = "1 / 3 / 1 / 4";
		} else if (index === 3) {
			gridArea = "1 / 4 / 1 / 5";
		}

		return gridArea;
	};

	const isString = (str) => {
		if (typeof str === "string" || str instanceof String) {
			return str;
		}

		return "";
	};

	const builderRender = () => {
		return Object.keys(revertedGridData).map((builder, key) => (
			<Box
				key={`row-${builder}`}
				sx={{
					gap: ".5rem",
					...(!usePrintable && width <= 950
						? {
								display: "flex",
								flexDirection: "column",
						  }
						: {
								display: "grid",
								gridTemplateColumns: "218px 1fr 1fr 1fr",
								gridTemplateRows: "1fr",
						  }),
				}}
			>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						background: "#FFFFFF",
						padding: "24px",
						borderRadius: "32px",
					}}
				>
					<Typography variant="main_title" color="#667085">
						{BUILDER_TITLE[key]}
					</Typography>
				</Box>
				{Object.keys(revertedGridData[builder]).map((row, index) => (
					<PersonaCard
						key={`card-${index}`}
						sx={{
							gridArea: getGridArea(index + 1),
							...(isPlatformActivity ? { border: "2px solid black" } : usePrintable && { border: "2px solid black" }),
						}}
					>
						{/* <TextArea usePrintable tight value={isString(revertedGridData[builder][row])} /> */}
						{usePrintable ? (
							<TextArea usePrintable tight value={isString(revertedGridData[builder][row])} />
						) : (
							<CanvasItemEditor
								value={isString(revertedGridData[builder][row])}
								onSave={(e) => handleInputChange(BUILDER_TITLE[key], e)}
								title={BUILDER_TITLE[key]}
								icon={ALERT_HEX}
							/>
						)}
					</PersonaCard>
				))}
			</Box>
		));
	};

	const revertedGridData = useMemo(() => {
		if (parsedData) {
			const reversedObject = {};
			for (const persona in parsedData) {
				const personaData = parsedData[persona];

				for (const property in personaData) {
					if (!reversedObject[property]) {
						reversedObject[property] = {};
					}
					reversedObject[property][persona] = personaData[property];
				}
			}

			return reversedObject;
		}

		return {};
	}, [parsedData]);

	return (
		<Box
			ref={observe}
			sx={{
				display: "flex",
				flexDirection: "column",
				gap: ".5rem",
				...(isPlatformActivity
					? { width: "1500px" }
					: usePrintable && {
							width: "1500px",
					  }),
			}}
		>
			{showLoader ? (
				<LeanCanvasLoader useStacked={width <= 950} />
			) : revertedGridData ? (
				<React.Fragment>
					<Box
						sx={{
							gap: ".5rem",
							...(!usePrintable && width <= 950
								? {
										display: "flex",
										flexDirection: "column",
								  }
								: {
										display: "grid",
										gridTemplateColumns: "218px 1fr 1fr 1fr",
										gridTemplateRows: "1fr",
								  }),
						}}
					>
						<Box />
						{["Persona Buyer", "Persona User", "Persona Influencer"].map((item, index) => (
							<PersonaCard
								key={item}
								sx={{
									gridArea: getGridArea(index + 1),
									...(isPlatformActivity ? { border: "2px solid black" } : usePrintable && { border: "2px solid black" }),
								}}
								title={item}
								icon={CURRENCY_DOLLAR_CIRCLE_ICON}
							/>
						))}
					</Box>
					{builderRender()}
				</React.Fragment>
			) : (
				<PersonaCard title={"Unable to load canvas"} icon={ALERT_HEX}></PersonaCard>
			)}
		</Box>
	);
};

export default PersonaGrid;
