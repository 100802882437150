import {
  Button,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  Link,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect } from "react";
import { PLUGIN_LOGO } from "../../../../../constants";
import ReplayIcon from "@mui/icons-material/Replay";
import PrerequisitesSteps from "../../../../../components/Workspace/PowerUp/PrerequisitesSteps";
import EventCard from "../Components/EventCard";
import EventDetails from "../Components/EventDetails";
import DialogComponent from "../../../PowerUP/IdeaValidation/Components/Dialog";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { useState } from "react";
import services from "../../../../../services";
import ScheduleSession from "../ScheduleSession";
import theme from "../../../../../constants/Theme";
import ScheduleMobileSession from "../ScheduleSession/scheduleMobileResposiveUI";
import { sosPrimary } from "../../../../../constants/Theme/colors";

import ROUTES_PATH from "../../../../../constants/routes";
import { ListController } from "../../../../Network/Components/Sidebar";
import { useHistory } from "react-router-dom";

export default function AdvisorPluginDetails() {
  const [showDetailsDialog, setShowDetailsDialog] = useState(false);
  const [pluginDetails, setPluginDetails] = useState({});
  const [pluginEventDetails, setPluginEventDetails] = useState(null);
  const [showAdvisorPluginPopup, setShowAdvisorPluginPopup] = useState(false);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const history = useHistory();

  const pluginUUID = "1";

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    services
      .getAdvisorPluginDetails(pluginUUID)
      .then(res => {
        setPluginDetails(res.data.data);
      })
      .catch(err => console.log(err));

    services
      .getAdvisorPlugineEventDetails(pluginUUID)
      .then(res => {
        setPluginEventDetails(res?.data?.data[0]);
      })
      .catch(err => console.log(err));
  }, []);

  const checkPrequisitiesValidity = () => {
    let valid = true;

    pluginDetails?.preRequisites?.forEach(prerequisite => {
      if (prerequisite?.companyTask?.taskStatus !== "COMPLETED") {
        valid = false;
      }
    });
    return valid;
  };

  const EventCardActionComponent = () => {
    return (
      <Box sx={{ mt: 4, display: "flex" }}>
        <Button
          sx={{ width: "auto", mr: "initial" }}
          onClick={() => setShowDetailsDialog(true)}
        >
          View
        </Button>
      </Box>
    );
  };

  const FooterComponent = () => {
    return (
      <Box
        display={"flex"}
        justifyContent={"flex-end"}
        sx={{ width: 1, px: 2 }}
      >
        <Box>
          <Button
            startIcon={<AccessTimeIcon />}
            sx={{ width: "max-content" }}
            onClick={() => {
              setShowAdvisorPluginPopup(true);
              setShowDetailsDialog(false);
            }}
          >
            Propose new time
          </Button>
        </Box>
      </Box>
    );
  };

  return (
    <>
      <DialogComponent
        isOpen={showDetailsDialog}
        onClose={() => setShowDetailsDialog(false)}
        title={"Event Details"}
        dialogAction={<FooterComponent />}
        titleVariant="largeTitle"
        sx={theme => ({
          display: "flex",
          width: 1,
          flexDirection: "column",
          borderRadius: theme.shape.standard23.borderRadius,
          "& .MuiDialog-paper": {
            width: "800px",
            borderRadius: 3,
          },
        })}
        contentStyle={{
          mt: 2,
        }}
        footerStyle={{
          py: 2,
        }}
      >
        <EventDetails
          sessionCycle={"Cycle 1 Session"}
          eventDetails={pluginEventDetails}
          pluginName={pluginDetails?.pluginName}
        />
      </DialogComponent>
      {showAdvisorPluginPopup &&
        (isSmallScreen ? (
          <ScheduleMobileSession
            showAdvisorPluginPopup={showAdvisorPluginPopup}
            setShowAdvisorPluginPopup={setShowAdvisorPluginPopup}
          />
        ) : (
          <ScheduleSession
            showAdvisorPluginPopup={showAdvisorPluginPopup}
            setShowAdvisorPluginPopup={setShowAdvisorPluginPopup}
          />
        ))}

      <Box
        sx={{
          p: { xs: 2, md: 4 },
          backgroundColor: {
            xs: sosPrimary[25],
            md: theme.palette.secondary.white,
          },
        }}
      >
        <Box
          flexDirection={"column"}
          sx={{ maxWidth: theme.breakpoints.values.xl, margin: "auto" }}
        >
          <Box
            sx={theme => ({
              border: {
                xs: "none",
                //md: `1px solid ${theme.palette.secondary.dark}`,
              },
              borderRadius: theme.shape.standard.borderRadius,
              p: { xs: 0, md: 2 },
              mt: 1,
            })}
          >
            <Grid container>
              <Grid item md={9} lg={10}>
                <img src={PLUGIN_LOGO} />
                <Box sx={{ mt: 1 }}>
                  <Typography variant="headline1">
                    {pluginDetails?.pluginName}
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                sx={{
                  display: "flex",
                  gap: 3,
                  my: 3,
                  flexDirection: { xs: "column-reverse", md: "column" },
                }}
              >
                <Grid item md={9} lg={10}>
                  <Typography variant="subhead1">
                    {pluginDetails?.shortDescription}
                  </Typography>
                </Grid>
                {/*<Grid item md={3} lg={2}>
                  <Button
                    startIcon={<ReplayIcon />}
                    variant="contained"
                    sx={{ width: "max-content" }}
                  >
                    Current Cycle: 1
                  </Button>
                </Grid>*/}
              </Grid>
            </Grid>
            {/*<Box sx={{ mt: 3 }}>
              {!pluginEventDetails ? (
                <Button
                  variant="contained"
                  sx={{ width: "max-content" }}
                  disabled={!checkPrequisitiesValidity()}
                >
                  Schedule a session
                </Button>
              ) : (
                pluginEventDetails && (
                  <EventCard
                    sessionCycle={"Cycle 1 Session"}
                    eventDetails={pluginEventDetails}
                    pluginName={pluginDetails?.pluginName}
                    ActionComponent={() => <EventCardActionComponent />}
                  />
                )
              )}
            </Box>*/}

            <Divider sx={{ borderColor: "secondary.light" }} />

            <Box>
              <Box sx={{ mt: 3 }}>
                <Box>
                  <Typography variant="title_large">Overview</Typography>
                </Box>
                <Box sx={{ mt: 3 }}>
                  <Typography variant="bodyMedium">
                    {pluginDetails?.overview}
                  </Typography>
                </Box>
              </Box>

              <Box sx={{ mt: 3 }}>
                <Box>
                  <Typography variant="title_large">
                    What you'll need
                  </Typography>
                </Box>
                <Box sx={{ mt: 3 }}>
                  <Typography variant="bodyMedium">
                    <PrerequisitesSteps
                      customHeading={
                        "Prepare to discuss and receive feedback for the work you've done up to this point:"
                      }
                      prerequisitesData={pluginDetails?.preRequisites}
                    />
                  </Typography>
                </Box>
              </Box>
            </Box>

            {/*Show mentor connection widgets data*/}
            {checkPrequisitiesValidity() && (
              <Box sx={{ my: 3 }}>
                <Box sx={{ mb: 2 }}>
                  <Typography variant="title_large">
                    Schedule your Mentor Check In
                  </Typography>
                </Box>
                <Box sx={{ mb: 2 }}>
                  <Typography variant="bodyMedium">
                    If you don't have a Mentor yet, don't worry about it. Just
                    click over to the
                    <Link
                      underline="none"
                      onClick={() => history.push(ROUTES_PATH.NETWORK)}
                      sx={{ cursor: "pointer" }}
                    >
                      {" "}
                      Network Page
                    </Link>{" "}
                    and connect to one of our great Mentors.
                  </Typography>
                </Box>

                <Grid container>
                  <Grid item md={5} xs={12}>
                    <ListController
                      title={"Your Mentors"}
                      type={"connections"}
                      mentors
                      routeToScheduling
                    />
                  </Grid>
                </Grid>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
}
