import ROUTES_PATH from "../constants/routes";
import services from "../services";
import { handleMixPanel } from "./mixPanelEventHandling";

export const createNewVersionApiCall = (moduleId, uuid, history) => {
  services
    .startModule({ moduleId })
    .then(result => {
      switch (result.data.data.module.id) {
        case 1:
          handleMixPanel("Powerup Value Proposition New Version Created");
          break;
        case 2:
          handleMixPanel("Powerup Founder Story New Version Created");
          break;
        case 4:
          handleMixPanel("Powerup TAM/SAM/SOM New Version Created");
          break;
        case 5:
          handleMixPanel("Powerup Pricing Modeler New Version Created");
          break;
        case 8:
          handleMixPanel("Powerup Brandstorming New Version Created");
          break;
        case 9:
          handleMixPanel("Powerup Problem Solution Fit New Version Created");
          break;
      }

      history.push(
        `/workspace/powerup/${uuid}/${moduleId}/${result.data.data.id}/start`
      );
    })
    .catch(err => console.log(err));
};

export const archiveApiCall = (payload, history) => {
  services
    .archiveApiCall(payload)
    .then(() => {
      history.push({
        pathname: ROUTES_PATH.WORKSPACE_WORK_HISTORY,
        state: { archived: true },
      });
    })
    .catch(err => console.log(err));
};
