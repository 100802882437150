import services from "../../../../services";

export const getPowerupDetails = powerUpId => {
  return services
    .getPowerupData(powerUpId)
    .then(response => {
      const res = response.data;

      if (res.code === 200) {
        //service call to update last visited powerup status
        services
          .updateLastVisitedPowerup({
            powerUpId: powerUpId,
          })
          .then(response => {})
          .catch(error => {});

        let details = { ...res.data };

        return details;
      }
    })
    .catch(err => {
      console.log(err);
      return err;
    });
};
