import { Typography, Box, Button, ListItemAvatar } from "@mui/material";
import React from "react";
import { listItemTheme, PowerUpListItem } from "./styles";
import PropTypes from "prop-types";
import { ThemeProvider } from "@mui/material/styles";

const ListItemComponent = ({ powerupLogo, title, buttonText, onClick }) => {
  return (
    <ThemeProvider theme={listItemTheme}>
      <PowerUpListItem color="primary" variant="outlined">
        <Box>
          <ListItemAvatar align="left">
            <img src={powerupLogo} alt="powerup logo" />
          </ListItemAvatar>
          <Typography variant="headline6">{title}</Typography>
        </Box>
        <Box sx={{ marginLeft: "auto" }}>
          <Button variant="outlined" onClick={onClick}>
            {buttonText}
          </Button>
        </Box>
      </PowerUpListItem>
    </ThemeProvider>
  );
};

{
  ListItemComponent.propTypes = {
    title: PropTypes.string,
    buttonText: PropTypes.string,
  };

  ListItemComponent.defaultProps = {
    title: "",
    buttonText: "",
  };
}

export default ListItemComponent;
