import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, useTheme, Popover, Typography } from "@mui/material";

const commonFonts = {
	fontFamily: '"PoppinsSemiBold", "Helvetica", "Arial", sans-serif',
	fontSize: "16px",
	lineHeight: "24px",
};

function BIDataGrid({ rows, columns, disabledRowIds = [], disabledFields = [], disabledCells = [], firstColumnwidth = "200px", readOnly, ...otherProps }) {
	const theme = useTheme();
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [anchorEl2, setAnchorEl2] = React.useState(null);
	// const [value, setValue] = React.useState("");

	const handlePopoverOpen = (event) => {
		const field = event.currentTarget.dataset.field;
		const id = event.currentTarget.parentElement.dataset.id;
		// const row = data.rows.find((r) => r.id === id);
		// setValue(row[field]);
		if (disabledRowIds?.includes(Number(id))) {
			setAnchorEl(event.currentTarget);
		}
		if (disabledFields && disabledFields?.includes(field)) {
			setAnchorEl(event.currentTarget);
		}
		if (disabledCells && disabledCells?.some((cell) => cell?.id === Number(id) && cell?.field === field)) {
			setAnchorEl(null);
			setAnchorEl2(event.currentTarget);
		}
	};

	const handlePopoverClose = () => {
		setAnchorEl(null);
		setAnchorEl2(null);
	};

	const open = Boolean(anchorEl);
	const getRowClassName = (params) => {
		if (disabledRowIds.includes(params.id)) {
			return "disabled-row";
		}
		return "";
	};
	return (
		<Box sx={{ width: "100%", position: "relative" }}>
			<Box sx={{ position: "absolute", zIndex: 10, width: firstColumnwidth, display: { xs: "none", md: "block" } }}>
				<DataGrid
					rows={rows}
					columns={columns.slice(0, 1)}
					hideFooterPagination
					hideFooter
					disableColumnMenu={true}
					disableColumnSelector={true}
					density="compact"
					autoHeight
					showCellVerticalBorder
					showColumnVerticalBorder
					getRowClassName={getRowClassName}
					sx={{
						...commonFonts,
						color: theme.palette.secondary.black,
						borderColor: theme.palette.sos.gray400,
						"& .MuiDataGrid-columnHeader, & .MuiDataGrid-cell": {
							backgroundColor: theme.palette.bidashboard.cellBg,
							color: theme.palette.primary.main,
							borderColor: theme.palette.sos.gray400,
							borderRight: "none",
							borderBottomColor: theme.palette.sos.gray400,
						},
						"& .MuiDataGrid-withBorderColor": {
							borderColor: theme.palette.sos.gray400,
						},
						"& .disabled-row.MuiDataGrid-row .MuiDataGrid-cell": {
							backgroundColor: theme.palette.bidashboard.disbledRow,
							cursor: "not-allowed",
						},
					}}
					{...otherProps}
				/>
			</Box>
			<DataGrid
				columns={columns}
				rows={rows}
				hideFooterPagination
				hideFooter
				autoHeight
				disableColumnMenu={true}
				disableColumnSelector={true}
				density="compact"
				isCellEditable={(params) => !disabledRowIds.includes(params.id)}
				getRowClassName={getRowClassName}
				showCellVerticalBorder
				showColumnVerticalBorder
				scrollbarSize={15}
				sx={{
					...commonFonts,
					color: theme.palette.secondary.black,
					borderColor: theme.palette.sos.gray400,
					// borderRight: { md: "none" }, //fix
					// borderLeft: { md: "none" }, //fix
					"& .MuiDataGrid-columnHeader": {
						backgroundColor: theme.palette.bidashboard.cellBg,
						color: theme.palette.primary.main,
						borderColor: theme.palette.sos.gray400,
						borderBottomColor: theme.palette.sos.gray400,
					},
					"& .MuiDataGrid-cell, & .MuiDataGrid-withBorderColor": {
						borderColor: theme.palette.sos.gray400,
					},
					"& .MuiDataGrid-cell--editing .MuiOutlinedInput-root": {
						...commonFonts,
						color: theme.palette.secondary.black,
						"& .MuiTypography-root": {
							...commonFonts,
							color: theme.palette.secondary.black,
						},
					},
					"& .disabled-row.MuiDataGrid-row,& .disabled--cell": {
						backgroundColor: theme.palette.bidashboard.disbledRow,
						cursor: "not-allowed",
					},
					"& .MuiDataGrid-row": {
						".MuiDataGrid-cell:first-child": {
							backgroundColor: theme.palette.bidashboard.cellBg,
							color: theme.palette.primary.main,
						},
					},
					//for horizontal scrollbar
					"& ::-webkit-scrollbar": {
						borderTop: "1px solid",
						borderColor: theme.palette.primary.main,
						width: 0,
						height: "10px",
					},
					"& ::-webkit-scrollbar-track": {
						WebkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.2)",
						borderRadius: "10px",
					},
					"& ::-webkit-scrollbar-thumb": {
						WebkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.2)",
						borderRadius: "10px",
						backgroundColor: theme.palette.primary.main,
					},
					// "& .MuiDataGrid-cell:focus": {
					// 	border: "2px solid",
					// 	borderColor: theme.palette.primary.main,
					// }
				}}
				slotProps={{
					...(!readOnly && {
						cell: {
							onMouseEnter: handlePopoverOpen,
							onMouseLeave: handlePopoverClose,
						},
					}),
				}}
				{...otherProps}
			/>
			<Popover
				sx={{
					pointerEvents: "none",
				}}
				open={open}
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "left",
				}}
				onClose={handlePopoverClose}
				disableRestoreFocus
			>
				<Typography sx={{ p: 1 }}>{`Auto Calculated`}</Typography>
			</Popover>
			<Popover
				sx={{
					pointerEvents: "none",
				}}
				open={Boolean(anchorEl2)}
				anchorEl={anchorEl2}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "left",
				}}
				onClose={handlePopoverClose}
				disableRestoreFocus
			>
				<Typography sx={{ p: 1 }}>{`Not Allowed`}</Typography>
			</Popover>
		</Box>
	);
}

export default BIDataGrid;
