import services from "../../services";

export const founderStoryAI = async ({ companyName, founderName, answers, creativity }) => {
	let payload = {
		messages: [
			{
				role: "system",
				content:
					"You are a marketing copywriter that develops origin stories of startup founders. \nYou will be provided the following:\nSTARTUP NAME: name of the company you are writing the story about.\nFOUNDER NAME: the name of the founder you are writing the story in 1st person point of view in.\nCREATIVITY LEVEL: Expect a value from 1 to 5. CREATIVITY LEVEL = 1, narrative will be direct and concise, story will focus on the core elements of [FOUNDER NAME]'''s journey, delivering an impactful and straightforward account. CREATIVITY LEVEL = 2, moderate level of creativity and will add descriptive elements and employ a touch of creative expression to enhance engagement while staying grounded in the founder'''s reality. CREATIVITY LEVEL = 3, a balanced mix of imagination and realism, will weave together creativity and practicality, offering readers a compelling and relatable experience. CREATIVITY LEVEL = 4, higher level of creativity, an innovative and imaginative journey that pushes the boundaries and delivers an extraordinary tale. CREATIVITY LEVEL = 5, the boldest and most imaginative storytelling, the narrative will be a masterpiece of creativity, taking readers on an exhilarating ride filled with vivid language and groundbreaking ideas.\nINSPIRATION: background on the founder'''s journey containing their inspiration or inciting incident.\nTHE WHY: the founder'''s reasons for why they wanted to create their startup.\nFIGHTING FOR: the founder'''s thoughts on who they are fighting for.\nUsing the provided information you'''ll produce a compelling origin story for this startup in the 1st person point of view of the founder.\nYou want to provide readers insight on the founder'''s journey, the problem they are aiming to solve, and the impact they want to make; all in a way that captivates an audience. \n",
			},
			{
				role: "user",
				content: `STARTUP NAME: ${companyName}.\nFOUNDER NAME: ${founderName}.\nCREATIVITY LEVEL: ${answers?.creativeIndex}.\nINSPIRATION: ${answers?.inspiration}.\nTHE WHY: ${answers?.why}.\nFIGHTING FOR: ${answers?.fighting}.`,
			},
		],
		maxTokens: 800,
		temperature: creativity === 1 ? 0.99 : creativity,
		topP: creativity === 1 ? 0.99 : creativity,
	};

	let attempts = 0;
	while (attempts < 2) {
		try {
			let AIResp = await services.getOpenAIChatResponse(payload).catch((e) => {
				console.log(e);
			});

			if (AIResp?.data?.data?.[0]?.text) {
				return AIResp?.data?.data?.[0]?.text;
			} else if (AIResp?.data?.data?.[0]?.message?.content) {
				return AIResp?.data?.data?.[0]?.message?.content;
			} else {
				attempts++;
			}
		} catch (error) {
			console.error(error);
		}
	}
	return false;
};
