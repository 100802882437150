import React from "react";
import { Box } from "@mui/material";

import { STARTUPOSCIRCLEICON } from "../../../../constants";
import MeetTheTeamCarousel from "./MeetTheTeamCarousel";

export default function MeetTheTeam({ startup }) {
	const carouselData = {
		type: "EXPERIENCE_SLIDER",
		title: "Meet the Team",
		icon: <img src={STARTUPOSCIRCLEICON} alt="team member" />,
		// startup?.logoUrl ? startup?.logoUrl :
		swiperSlideData: [
			...Array(20).map((item, i) => {
				return {
					image: "blanka",
					title: "title",
					companyName: "Name",
					location: "cate",
					startYear: "1948",
					endYear: "1984",
				};
			}),
		],
	};

	return (
		<Box
			sx={{
				display: "flex",
				flex: 1,
				alignItems: "center",
				backgroundColor: "#F5F9FA",
				p: {
					lg: 5,
					md: 3,
					xs: 2,
				},
			}}
		>
			<MeetTheTeamCarousel carouselData={carouselData} startup={startup} />
		</Box>
	);
}
