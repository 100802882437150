import React from "react";
import { Grid, Box, Stack, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { useSelector } from "react-redux";

export default function RequestSubmitted() {
  const userType = useSelector(state => state.auth.userType);
  return (
    <Stack alignItems="center" justifyContent="center">
      <Grid container alignItems="center" justifyContent="center">
        <Grid item xs={12} sm={10} md={6} lg={6} sx={{ px: 2 }}>
          <Box display="grid" justifyContent="center" sx={{ mb: 4 }}>
            <CheckIcon
              sx={{
                border: "1px solid",
                borderRadius: "50%",
                p: 1,
                color: "success.main",
              }}
            ></CheckIcon>
          </Box>
          <Box display="grid" justifyContent="center">
            <Typography variant="headline1" align="center">
              Your{" "}
              {userType === "MENTOR_USER" || userType === "PROFESSOR_USER"
                ? "mentor"
                : "advisor"}{" "}
              request has been submitted.
            </Typography>
          </Box>
          <Box display="grid" sx={{ textAlign: "center" }}>
            <Typography variant="subhead1" sx={{ my: 3 }}>
              A confirmation email has been sent to the email address you
              provided. Expect to hear back from us within 72 hours if you have
              been approved to join StartupOS as a{" "}
              {userType === "MENTOR_USER" || userType === "PROFESSOR_USER"
                ? "mentor"
                : "advisor"}
              .
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Stack>
  );
}
