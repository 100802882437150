import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getNetArrGridData } from "../Functions/common";
import NewArrChartContainer from "../Charts/NewArrChartContainer";
import NetArrDataGrid from "../DataGrid/NetArrGrid";
import { handleMixPanel } from "../../../../utils/mixPanelEventHandling";
import { setBIDashboardData } from "../../../../modules/actions/biDashboardActions";
import BIDataGridDialog from "../DataGrid/BIDataGridDialog";

function NetArr({ companyId, readOnly }) {
	const dispatch = useDispatch();
	const netNewArr = useSelector((store) => store?.biDashboard?.netNewArr);
	const loadingNetNewArr = useSelector((store) => store?.biDashboard?.loadingNetNewArr);
	const openNetNewArr = useSelector((store) => store?.biDashboard?.openNetNewArr);
	const [isEdited, setEdited] = useState(false);

	const gridData = netNewArr?.data ? netNewArr?.data : [];

	const ownCompanyId = useSelector((state) => state?.auth?.companyId);
	const usedCompanyId = companyId ? companyId : ownCompanyId;

	const fetchData = async () => {
		try {
			dispatch(setBIDashboardData({ loadingNetNewArr: true }));
			const responseData = await getNetArrGridData(usedCompanyId);
			if (responseData) {
				dispatch(setBIDashboardData({ netNewArr: responseData }));
			}
			dispatch(setBIDashboardData({ loadingNetNewArr: false }));
		} catch (error) {
			dispatch(setBIDashboardData({ loadingNetNewArr: false }));
			console.log("error", error);
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	const onChartClick = () => {
		handleMixPanel("Grid New ARR Viewed");
		dispatch(setBIDashboardData({ openNetNewArr: true }));
	};

	const handleClose = useCallback(() => {
		if (isEdited) {
			dispatch(
				setBIDashboardData({
					openNetNewArrLeave: true,
				})
			);
		} else {
			dispatch(setBIDashboardData({ openNetNewArr: false }));
		}
	}, [isEdited]);

	return (
		<>
			<NewArrChartContainer data={netNewArr} onChartClick={onChartClick} loading={loadingNetNewArr} readOnly={readOnly} />
			<BIDataGridDialog title="Net New ARR" isOpen={openNetNewArr} onClose={handleClose}>
				<NetArrDataGrid gridData={gridData} readOnly={readOnly} usedCompanyId={usedCompanyId} isEdited={isEdited} setEdited={setEdited} />
			</BIDataGridDialog>
		</>
	);
}

export default NetArr;
