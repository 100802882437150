import styled from "@emotion/styled";
import { BREAKPOINT_XXL } from "../../../../constants/Theme/layout";
import MuiAppBar from "@mui/material/AppBar";
import MuiDrawer from "@mui/material/Drawer";
const drawerWidth = 255;

export const ItemIcon = styled.div(({ theme, selected }) => {
	return {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		borderRadius: theme.shape.standard25.borderRadius,
		height: "24px",
		width: "24px",
		backgroundColor: selected ? theme.palette.secondary.light : theme.palette.secondary.white,
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
		[theme.breakpoints.down("md")]: {
			paddingTop: theme.spacing(0.5),
			paddingBottom: theme.spacing(0.5),
			paddingLeft: theme.spacing(1),
			paddingRight: theme.spacing(1),
			backgroundColor: selected ? theme.palette.secondary.light : "transparent",
		},
		"&:hover": {
			backgroundColor: theme.palette.grey[200],
		},
	};
});

export const LeftSideBarWrapper = styled.div(({ theme }) => {
	return {
		background:
			"linear-gradient(0deg, rgba(106, 117, 117, 0.02), rgba(106, 117, 117, 0.02)), linear-gradient(0deg, rgba(245, 248, 250, 0.05), rgba(245, 248, 250, 0.05)), #FCFCFC",
		borderRight: `1px solid ${theme.palette.secondary.outline2}`,
		height: "100%",
		[theme.breakpoints.up("md")]: {
			position: "fixed",
		},

		[theme.breakpoints.down("md")]: {
			background: theme.palette.secondary.backgroundGradient,
		},
		//[theme.breakpoints.between(theme.breakpoints.values.xl, BREAKPOINT_XXL)]: {
		//  width: "64px",
		//},
	};
});

export const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
	zIndex: theme.zIndex.drawer,
	transition: theme.transitions.create(["width", "margin"], {
		easing: theme.transitions.easing.sharp,
		duration: 500,
	}),
	color: "black",
	paddingTop: "25px",
	paddingBottom: "20px",
	background: "transparent",
	...(open && {
		// marginLeft: drawerWidth,
		// width: `calc(100% - ${257}px)`,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: 500,
		}),
	}),
	...(!open && {
		// marginLeft: drawerWidth,
		width: `calc(100% + ${-25}px)`,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,

			duration: 500,
		}),
	}),
}));

export const Drawer = styled(MuiDrawer, {
	shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
	width: drawerWidth,
	flexShrink: 0,
	whiteSpace: "nowrap",

	backgroundColor: "transparent !important",

	boxSizing: "border-box",
	...(open && {
		...openedMixin(theme),
		"& .MuiDrawer-paper": openedMixin(theme),
	}),
	...(!open && {
		...closedMixin(theme),
		"& .MuiDrawer-paper": closedMixin(theme),
	}),
}));
export const DrawerHeader = styled("div")(({ theme, open }) => ({
	display: "flex",
	alignItems: "center",
	justifyContent: open ? "flex-end" : "center",
	marginLeft: open ? "0px" : "16px",
	padding: theme.spacing(0, 1),
	// necessary for content to be below app bar
	...theme.mixins.toolbar,
}));

export const openedMixin = (theme) => ({
	background: "black",

	color: "white",
	"& .MuiDrawer-docked": { background: "green" },
	width: drawerWidth,
	paddingLeft: theme.spacing(1),
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: 500,
	}),
	overflowX: "hidden",
});

export const closedMixin = (theme) => ({
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: 500,
	}),
	background: "black",
	color: "white",
	marginLeft: "20px",
	overflowX: "hidden",
	width: `calc(${theme.spacing(12)} + 1px)`,
	[theme.breakpoints.up("sm")]: {
		width: `calc(${theme.spacing(12)} + 1px)`,
	},
});
