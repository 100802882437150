import React, { useState, useLayoutEffect, createRef } from "react";
import { Button, Popover, TextField } from "@mui/material";
import { Box } from "@mui/system";
import MessageFile from "./MessageFile";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { InsertEmoticon } from "@mui/icons-material";
import styles from "../../styles";

function useWindowSize() {
	const [size, setSize] = useState(0);
	useLayoutEffect(() => {
		function updateSize() {
			setSize(window.innerWidth);
		}
		window.addEventListener("resize", updateSize);
		updateSize();
		return () => window.removeEventListener("resize", updateSize);
	}, []);
	return size;
}

const MessageInput = (props) => {
	const [cursorPosition, setCursorPositions] = useState(0);
	const [anchorEl, setAnchorEl] = useState(null);
	const inputRef = createRef();
	const width = useWindowSize();

	const addEmoji = (e) => {
		try {
			let sym = e.unified.split("-");
			let codesArray = [];
			sym.forEach((el) => codesArray.push("0x" + el));
			let emoji = String.fromCodePoint(...codesArray);
			if (typeof inputRef.current.value !== "undefined") {
				let message = props.message;
				let textBeforeCursorPosition = message && message.length > 0 ? message.substring(0, cursorPosition) : "";
				let textAfterCursorPosition = message && message.length > 0 ? message.substring(cursorPosition, message.length) : "";
				let combinedText = textBeforeCursorPosition + emoji + textAfterCursorPosition;

				setAnchorEl(null);
				props.onChange(combinedText);
				setCursorPositions(cursorPosition + 2); // Set 2, to set the cursor position 'after' the newly placed emoji.
			}
		} catch (err) {
			console.log("Having an issue with emoji picker.", err);
		}
	};

	return (
		<Box display="flex" alignItems="center" width="100%" gap={2}>
			<Box flexGrow={1}>
				<TextField
					multiline
					inputRef={inputRef}
					value={props.message}
					placeholder="Type here"
					fullWidth
					variant="outlined"
					sx={{
						label: {
							"&:not(.MuiInputLabel-shrink)": (theme) => ({
								fontFamily: theme.typography["Text/sm/Regular"],
								color: theme.palette.text.lightOnsurface,
							}),
						},
						input: {
							"&, &::placeholder": (theme) => ({
								fontFamily: theme.typography["Text/sm/Regular"],
								pl: 1,
							}),
						},
						"& .MuiOutlinedInput-root": {
							position: "relative",
							borderRadius: "30px",
							borderColor: (theme) => theme.palette.primary.grayBorder,
							backgroundColor: (theme) => theme.palette.secondary.white,
						},
						"& .MuiOutlinedInput-input": (theme) => ({
							fontFamily: theme.typography["Text/sm/Regular"],
							pl: 1,
						}),
					}}
					InputProps={{
						onKeyPress: props.onKeyPress,
						onKeyDown: (e) => {
							if (typeof e.target?.selectionStart !== "undefined") {
								setCursorPositions(e.target.selectionStart);
							}
							if (e.key === "Enter" && !e.shiftKey) {
								e.preventDefault();
								if (e.target.value && e.target.value.length > 0) {
									props.onKeyPress(e);
								}
							}
						},
						onClick: (e) => {
							if (typeof e.target?.selectionStart !== "undefined") {
								setCursorPositions(e.target.selectionStart);
							}
						},
						onChange: (e) => {
							props.onChange(e.target.value);
							if (typeof e.target?.selectionStart !== "undefined") {
								setCursorPositions(e.target.selectionStart);
							}
						},
						endAdornment: (
							<>
								<InsertEmoticon
									sx={{ cursor: "pointer" }}
									onClick={(event) => {
										setAnchorEl(anchorEl ? null : event.currentTarget);
									}}
								/>
								<Popover
									elevation={1}
									PaperProps={{
										sx: {
											background: "none",
										},
									}}
									open={Boolean(anchorEl)}
									anchorEl={anchorEl}
									anchorOrigin={{
										vertical: "top",
										horizontal: "center",
									}}
									transformOrigin={{
										vertical: "bottom",
										horizontal: "center",
									}}
									onClose={() => {
										setAnchorEl(null);
									}}
								>
									<Picker theme="light" data={data} onEmojiSelect={addEmoji} />
								</Popover>
							</>
						),
					}}
				/>
			</Box>

			<Box>
				<Button
					variant="DS1"
					onClick={() => props.onMessageSend?.()}
					sx={{
						backgroundColor: "#F2F4F7",
						color: "#344054",
						borderRadius: "22px",
						":hover": {
							boxShadow: "none",
							backgroundColor: "#e1e3e5",
						},
						":focus": {
							boxShadow: "none",
							backgroundColor: "#e1e3e5",
						},
						width: "100px",
						height: "55px",
					}}
				>
					Send
				</Button>
			</Box>

			{props.assets.length ? (
				<Box display="flex" flexWrap="wrap">
					{props.assets.map(({ name, size }) => (
						<MessageFile key={`${name}_${size}`} media={{ filename: name, size }} onRemove={() => props.onFileRemove(name + "_" + size)} />
					))}
				</Box>
			) : null}
		</Box>
	);
};

export default MessageInput;
