import * as actionTypes from "../../actions/actionTypes";
import { updateObject } from "../../../utils/updateObject";

const initialState = {
  companyName: "",
  companyLocation: {
    stateImage: "",
    state: "",
    zipCode: "",
    lat: "",
    lng: "",
  },
  productService: "",
  journeyDetails: [],
  experienceDetails: "",
  founderSuperpower: "",
  currentCommitment: "",
  fetchedAutoCompleteDetails: {
    companyType: [],
    journeyDetails: [],
  },
  autoCompleteDetails: {
    companyType: "",
    journeyDetails: "",
  },
  role: "",
  whatBringHere: [],
  firstName: "",
  lastName: "",
  linkedInProfile: "",
  zipCode: "",
  city: "",
  state: "",
  yourSuperPower: [],
  yourExpertise: [],
  startupName: "",
  startupWebsite: "",
  foundedDate: null,
  incorporatedStatus: "",
  receivedAnyFunding: "",
  companyLinkedInUrl: "",
};

const onboarding = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_ONBOARDING_DATA:
      return action.payload === null ? {} : updateObject(state, action.payload);
    case actionTypes.SET_COMPANY_NAME:
      return updateObject(state, { companyName: action.payload });
    case actionTypes.SET_COMPANY_LOCATION:
      return updateObject(state, { companyLocation: action.payload });
    case actionTypes.SET_AUTOCOMPLETE_DATA: {
      return updateObject(state, {
        fetchedAutoCompleteDetails: {
          ...state.fetchedAutoCompleteDetails,
          ...action.payload,
        },
      });
    }
    case actionTypes.SET_PRODUCT_SERVICE: {
      return updateObject(state, {
        productService: action.payload.productService,
        autoCompleteDetails: action?.payload?.autoCompleteData
          ? {
              ...state.autoCompleteDetails,
              ...action?.payload?.autoCompleteData,
            }
          : { ...state.autoCompleteDetails },
      });
    }
    case actionTypes.SET_EXPERIENCE_LEVELS: {
      return updateObject(state, {
        experienceDetails: action.payload.experienceDetails,
      });
    }
    case actionTypes.SET_FOUNDER_SUPER_POWER: {
      return updateObject(state, {
        founderSuperpower: action.payload.founderSuperpower,
      });
    }
    case actionTypes.SET_COMMITMENT_LEVELS: {
      return updateObject(state, {
        currentCommitment: action.payload.currentCommitment,
      });
    }

    case actionTypes.SET_STARTUP_JOURNEY: {
      return updateObject(state, {
        journeyDetails: action.payload.journeyDetails,
        autoCompleteDetails: action?.payload?.autoCompleteData
          ? {
              ...state.autoCompleteDetails,
              ...action?.payload?.autoCompleteData,
            }
          : { ...state.autoCompleteDetails },
      });
    }
    case actionTypes.CLEAR_ONBOARDING_DATA:
      return updateObject(state, initialState);
    default:
      return state;
  }
};

export default onboarding;
