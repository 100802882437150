import React from "react";
import { CircularProgress, Box } from "@mui/material";

export default function CustomCircularProgressBar({ inputData }) {
	return (
		<Box width={inputData?.width} height={inputData?.height} sx={{ position: "relative" }}>
			<CircularProgress
				variant="determinate"
				sx={{
					color: (theme) => theme.palette.secondary.chipGrayBg,
				}}
				size={inputData?.size}
				thickness={inputData?.thickness}
				value={100}
			/>
			<CircularProgress
				variant="determinate"
				disableShrink
				sx={{
					color: inputData?.color,
					position: "absolute",
					left: 0,
					strokeLinecap: "round",
				}}
				size={inputData?.size}
				thickness={inputData?.thickness}
				value={inputData?.percentage}
			/>
		</Box>
	);
}
