import React, { useEffect, useRef, useState } from "react";
import CommonSlideDialog from "../../../../common/CommonSlideDialog";
import { Box, Button, Typography, CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setBIDashboardData } from "../../../../modules/actions/biDashboardActions";
import { ALERT_ICON_V1 } from "../../../../constants";
import services from "../../../../services";
import { toastContainer } from "../../../../utils/toast";
import {
	getAcvGridData,
	getAnnualRevenueGridData,
	getBurnMultipeGridData,
	getBurnRunwayGridData,
	getCapTableData,
	getCompanyDetails,
	getCustomerGridData,
	getMagicNumberGridData,
	getMonthlyRevenueGridData,
	getNetArrGridData,
	getPaybackGridData,
	getRevenueGridData,
	getSmExpenseVsNewSalesGridData,
} from "../Functions/common";
import messages from "../../../../constants/messages";

function ExcelDialog() {
	const dispatch = useDispatch();
	const { openExcelDialog } = useSelector((store) => store?.biDashboard);
	const [loading, setLoading] = useState(false);
	const [uploadLoading, setUploadLoading] = useState(false);
	const [selectedFile, setSelectedFile] = useState(null);
	const ownCompanyId = useSelector((state) => state?.auth?.companyId);
	const { companyName } = useSelector((state) => state?.auth);
	const fileInputRef = useRef(null);

	useEffect(() => {
		if (selectedFile) {
			handleUploadExcelFile();
		}
	}, [selectedFile]);

	const handleClose = () => {
		dispatch(setBIDashboardData({ openExcelDialog: false }));
	};

	const refreshData = async () => {
		try {
			const acvGridDataResponse = await getAcvGridData(ownCompanyId);
			const capTableDataResponse = await getCapTableData(ownCompanyId);
			const annualRevenueGridDataResponse = await getAnnualRevenueGridData(ownCompanyId);
			const burnRunwayGridDataresponse = await getBurnRunwayGridData(ownCompanyId);
			const customerGridDataResponse = await getCustomerGridData(ownCompanyId);
			const monthlyRevenueGridDataResponse = await getMonthlyRevenueGridData(ownCompanyId);
			const netArrGridDataResponse = await getNetArrGridData(ownCompanyId);
			const paybackGridDataResponse = await getPaybackGridData(ownCompanyId);
			const revenueGridDataResponse = await getRevenueGridData(ownCompanyId);
			const smExpenseVsNewSalesGridDataResponse = await getSmExpenseVsNewSalesGridData(ownCompanyId);
			const burnMultipleGridDataResponse = await getBurnMultipeGridData(ownCompanyId);
			const magicNumberGridDataResponse = await getMagicNumberGridData(ownCompanyId);
			const companyDetailsDataResponse = await getCompanyDetails();

			dispatch(
				setBIDashboardData({
					...(acvGridDataResponse && { acv: acvGridDataResponse }),
					...(capTableDataResponse && { capData: capTableDataResponse }),
					...(annualRevenueGridDataResponse && { annualRecurringRevenue: annualRevenueGridDataResponse }),
					...(burnRunwayGridDataresponse && { burnAndRunway: burnRunwayGridDataresponse }),
					...(customerGridDataResponse && { customers: customerGridDataResponse }),
					...(monthlyRevenueGridDataResponse && { monthlyRecurringRevenue: monthlyRevenueGridDataResponse }),
					...(netArrGridDataResponse && { netNewArr: netArrGridDataResponse }),
					...(paybackGridDataResponse && { cacPayback: paybackGridDataResponse }),
					...(revenueGridDataResponse && { revenue: revenueGridDataResponse }),
					...(smExpenseVsNewSalesGridDataResponse && { smExpense: smExpenseVsNewSalesGridDataResponse }),
					...(magicNumberGridDataResponse && { magicNumber: magicNumberGridDataResponse }),
					...(burnMultipleGridDataResponse && { burnMultiple: burnMultipleGridDataResponse }),
					...(companyDetailsDataResponse && { headCount: companyDetailsDataResponse?.companySize > 0 ? companyDetailsDataResponse?.companySize : 0 }),
				})
			);
		} catch (error) {
			console.log(error);
		}
	};

	const handleDownloadExcel = async () => {
		try {
			setLoading(true);
			const excelResponse = await services.downloadSheetApi({ partner: "sheet" });

			if (excelResponse?.status === 200) {
				const blob = new Blob([excelResponse?.data], { type: "application/octet-stream" });

				const link = document.createElement("a");
				link.href = window.URL.createObjectURL(blob);
				link.download = `startupgrid_template.xls`;
				link.click();

				setLoading(false);
			}
		} catch (error) {
			console.error("Error:", error);
			setLoading(false);

			throw error;
		}
	};
	const handleFileInputChange = (e) => {
		const file = e.target.files[0];
		fileInputRef.current.value = "";

		const validExtensions = [".xls", ".xlsx"];

		if (file && validExtensions.includes(file.name.slice(-4).toLowerCase())) {
			setSelectedFile(file);
		} else {
			setSelectedFile(null);
			toastContainer(messages?.INVALID_FILE_TYPE, "error");
		}
	};

	const handleUploadExcelFile = async () => {
		try {
			setUploadLoading(true);
			var formData = new FormData();
			formData.append("file", selectedFile);
			formData.append("fileGroup", "EXCEL");

			const excelResponse = await services.collectingDataFromPartnerApi({ partner: "sheet", payload: formData });

			if (excelResponse?.status === 200) {
				setUploadLoading(false);
				setSelectedFile(null);
				dispatch(setBIDashboardData({ openExcelDialog: false }));
				refreshData();
				toastContainer(messages?.FILE_UPLOAD_SUCCESS, "success");
			}
		} catch (error) {
			setSelectedFile(null);

			if (error?.response && error?.response?.status === 400) {
				// Handle 400 Bad Request error here
				const errorMessage = error.response.data?.message; // Assuming your backend sends an error message
				toastContainer(errorMessage, "error");
			} else {
				// Handle other errors here
				toastContainer(error, messages?.FILE_UPLOAD_ERROR);
			}
			setUploadLoading(false);

			throw error;
		}
	};
	const handleBrowserExcelFile = async () => {
		const fileInput = document.getElementById("fileInput");
		fileInput.click();
	};

	const ExcelSection = ({ title, subTitle }) => {
		return (
			<Box display={"flex"} flexDirection={"column"} gap={"12px"}>
				<Typography component={"p"} sx={{ color: "rgba(0, 0, 0, 1)" }} variant="Text/sm/Regular">
					{" "}
					{title}
				</Typography>
				<Typography component={"p"} sx={{ color: "rgba(102, 112, 133, 1)" }} variant="Text/sm/Regular">
					{subTitle}
				</Typography>
			</Box>
		);
	};

	return (
		<CommonSlideDialog
			open={openExcelDialog}
			onClose={handleClose}
			disableBackDropClick
			title={
				<Box sx={{ display: "flex", flexDirection: "column", rowGap: 1 }}>
					<Box component={"img"} src={ALERT_ICON_V1} />
				</Box>
			}
			maxWidth="sm"
			titleVariant="Text/md/Semibold"
			titleStyle={{ p: 0, alignItems: "center" }}
			contentStyle={{ p: 0, flex: 1, display: "flex" }}
			fullWidth
			PaperProps={{
				sx: {
					m: 0,
					borderRadius: "24px",
					boxShadow: "0px 10px 25px 0px rgba(0, 0, 0, 0.05)",
					width: "100%",
					p: 5,
				},
			}}
			footerStyle={{ p: 0 }}
		>
			<Box>
				<Typography variant="Text/md/Semibold">Get Started with Excel</Typography>
				<Box sx={{ mt: 4 }} flex={1} display="flex" flexDirection={"column"} rowGap={4}>
					<Box display="flex" flexDirection={"column"} rowGap={"12px"}>
						<ExcelSection title={"1. Download Template"} subTitle={"Use this template to upload your data."} />
						<Button
							sx={{ maxWidth: "fit-content" }}
							startIcon={loading && <CircularProgress size={"1.25rem"} sx={{ color: "inherit" }} />}
							variant="DS1"
							disabled={loading}
							onClick={() => {
								handleDownloadExcel();
							}}
						>
							Download
						</Button>
					</Box>

					<ExcelSection
						title={"2. Fill in Template"}
						subTitle={"Fill in the template with your customers revenue expenses, cash balance and custom customer attributes."}
					/>
					<Box display="flex" flexDirection={"column"} rowGap={"12px"}>
						<ExcelSection title={"3. Upload Completed Template"} subTitle={"Upload your completed template here."} />
						<Button
							sx={{ maxWidth: "fit-content" }}
							startIcon={uploadLoading && <CircularProgress size={"1.25rem"} sx={{ color: "inherit" }} />}
							variant="DS1"
							onClick={handleBrowserExcelFile}
						>
							{uploadLoading ? "Uploading" : "Browse Files"}
						</Button>
						<input
							type="file"
							ref={fileInputRef}
							id="fileInput"
							accept=".xls, .xlsx"
							style={{ display: "none" }}
							onChange={handleFileInputChange}
						/>
					</Box>
				</Box>
			</Box>
		</CommonSlideDialog>
	);
}

export default ExcelDialog;
