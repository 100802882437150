import { useEffect, useMemo, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { AttachIcon } from "@twilio-paste/icons/esm/AttachIcon";
import { Box, Button } from "@twilio-paste/core";
import { useTheme } from "@twilio-paste/theme";
import { Text } from "@twilio-paste/text";
import { actionCreators } from "../../../../Messaging/store";
import { MAX_FILE_SIZE, UNEXPECTED_ERROR_MESSAGE } from "../../../../Messaging/constants";
import { getTypingMessage, unexpectedErrorNotification } from "../../../../Messaging/helpers";
import MessageInput from "./MessageInput";
import SendMessageButton from "./SendMessageButton";
import { getSdkConversationObject } from "../../../../Messaging/conversations-objects";
import { getConversationWithID } from "../../../../../utils/messaging-helper";
import { handleMixPanel } from "../../../../../utils/mixPanelEventHandling";
import { getRoleValue } from "../../../../../utils/commonFunctions";
import { Typography } from "@mui/material";
const MessageInputField = (props) => {
	const conversationState = props?.convo?.startuposMeta?.conversationState;
	const [message, setMessage] = useState("");
	const [files, setFiles] = useState([]);
	const [loading, setloading] = useState(false);
	const usersType = useSelector((store) => store?.auth.userType);
	const userId = useSelector((store) => store?.auth.userId);
	// Needed to load new input field when creating a new conversation
	const createConversationStatus = useSelector((state) => state.chatState?.createConversationStatus);
	const newConversationWith = useSelector((state) => state.chatState?.newConversationWith);
	const authData = useSelector((state) => state?.auth);
	const { isPremiumPlanAccess, userType } = useSelector((store) => store?.auth);

	// needed to clear input type=file
	const [filesInputKey, setFilesInputKey] = useState("input-key");
	const theme = useTheme();
	const typingInfo = getTypingMessage(props.typingData);
	const dispatch = useDispatch();
	const { addMessages, addNotifications, addAttachment, updateCurrentConversation, updateParticipants, resetChatState } = bindActionCreators(
		actionCreators,
		dispatch
	);
	const chatContainerRef = useRef(null);

	useEffect(() => {
		setMessage("");
		setFiles([]);
		setFilesInputKey(Date.now().toString());
	}, [props.convo]);
	useEffect(() => {
		if (!files.length) {
			setFilesInputKey(Date.now().toString());
		}
	}, [files]);
	const scrollToBottom = () => {
		if (chatContainerRef.current) {
			chatContainerRef.current.scrollBottom = chatContainerRef.current.scrollHeight;
		}
	};

	const sdkConvo = useMemo(() => {
		if (props?.convo) getSdkConversationObject(props?.convo);
	}, [props?.convo?.sid]);
	const onFilesChange = (event) => {
		const { files: assets } = event.target;
		if (!assets?.length) {
			return;
		}
		const validFiles = Array.from(assets).filter(({ size }) => size < MAX_FILE_SIZE + 1);
		if (validFiles.length < assets.length) {
			// TODO: show error
		}
		setFiles([...files, ...validFiles]);
	};
	const onFileRemove = (file) => {
		const fileIdentityArray = file.split("_");
		const fileIdentity = fileIdentityArray.slice(0, fileIdentityArray.length - 1).join();
		const existentFiles = files.filter(({ name, size }) => name !== fileIdentity && size !== Number(fileIdentityArray[fileIdentityArray.length - 1]));
		setFiles(existentFiles);
	};

	const onMessageSend = async () => {
		const { convo, client } = props;
		const currentDate = new Date();

		let sdkConvo = null;
		let newMessageBuilder = null;
		if (convo) sdkConvo = getSdkConversationObject(convo);
		else {
			setloading(true);
			// ensure that 'createConversation' and 'startWithIDS' is set
			if (createConversationStatus && newConversationWith) {
				// call create conversation method to get convo SID from API
				const convoSID = await getConversationWithID({
					idArray: newConversationWith,
				});
				sdkConvo = await client.getConversationBySid(convoSID);

				try {
					// once we have conversation ID returned set as the 'selected' conversation.
					setloading(false);
					resetChatState();
					updateCurrentConversation(convoSID);
				} catch {
					setloading(false);
					unexpectedErrorNotification(addNotifications);
				}
			}
		}
		if (sdkConvo) console.log(message);
		newMessageBuilder = sdkConvo.prepareMessage().setBody(message);
		// const newMessage: ReduxMessage = {
		//   author: client.user.identity,
		//   body: message,
		//   attributes: {},
		//   dateCreated: currentDate,
		//   index: -1,
		//   participantSid: "",
		//   sid: "-1",
		//   aggregatedDeliveryReceipt: null,
		//   attachedMedia: [],
		// } as ReduxMessage;
		for (const [key, file] of files.entries()) {
			const fileData = new FormData();
			fileData.set(file.name, file, file.name);
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			// newMessage.attachedMedia.push({
			//   sid: key + "",
			//   size: file.size,
			//   filename: file.name,
			//   contentType: file.type,
			// });
			// addAttachment(convo.sid, "-1", key + "", file);
			newMessageBuilder.addMedia(fileData);
		}
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		// addMessages(convo.sid, [newMessage]);
		setMessage("");
		setFiles([]);
		let messageIndex = null;
		if (newMessageBuilder) messageIndex = await newMessageBuilder.build().send();

		handleMixPanel("User sent a message", {
			"Sending user name": authData?.userName,
			"Sending user id": authData?.userId,
			"Sending user role": getRoleValue(authData?.userType),
			"Receiving user name": props?.convo?.startuposMeta?.conversationParticipants[0]?.name,
			"Receiving user id": props?.convo?.startuposMeta?.conversationParticipants[0]?.id,
			"Receiving user role": getRoleValue(props?.convo?.startuposMeta?.conversationParticipants[0]?.userTypes[0]),
			subscription_status: isPremiumPlanAccess ? "Subscribed" : "Not Subscribed",
			user_type: userType,
		});

		try {
			if (messageIndex) await sdkConvo.updateLastReadMessageIndex(messageIndex);
			scrollToBottom();
		} catch (e) {
			unexpectedErrorNotification(addNotifications);
			return Promise.reject(UNEXPECTED_ERROR_MESSAGE);
		}
	};
	return conversationState === "DISABLED" ? (
		<Box display={"flex"} justifyContent={"center"} alignItems={"center"} marginTop={2} backgroundColor={"#FBDDDD"} borderRadius={"30px"}>
			<Typography variant="Text/md/Semibold" color={"#B03F40"} p={1}>
				User no longer exists
			</Typography>
		</Box>
	) : (
		<Box
			display="flex"
			flexBasis="20px"
			flexGrow={10}
			flexDirection="column"
			style={{
				borderRadius: "10px",

				// background:
				//   "linear-gradient(0deg, rgba(106, 117, 117, 0.02), rgba(106, 117, 117, 0.02)), linear-gradient(0deg, rgba(245, 248, 250, 0.05), rgba(245, 248, 250, 0.05)), #FCFCFC",
			}}
			ref={chatContainerRef}
		>
			<Box paddingBottom="space20" paddingTop="space50" paddingLeft="space150" hidden={!props?.typingData?.length}>
				<Text as="p" color="colorTextIcon">
					{typingInfo}
				</Text>
			</Box>
			<Box display="flex" flexDirection="row" height="100%" flexGrow={10} paddingBottom="space30" paddingTop="space40">
				<Box flexGrow={10}>
					<MessageInput
						assets={files}
						message={message}
						onChange={(e) => {
							if (sdkConvo) sdkConvo.typing();
							setMessage(e);
						}}
						onKeyPress={async (e) => {
							if (e.key === "Enter" && !e.shiftKey) {
								await onMessageSend();
							}
						}}
						onFileRemove={onFileRemove}
						onMessageSend={onMessageSend}
						loading={loading}
					/>
				</Box>
			</Box>
		</Box>
	);
};
export default MessageInputField;
