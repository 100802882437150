import { useState, forwardRef, useCallback } from "react";
import { makeStyles } from "@mui/styles";
import { useSnackbar, SnackbarContent } from "notistack";
import Collapse from "@mui/material/Collapse";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Box } from "@mui/material";

const useStyles = makeStyles((theme) => ({
	root: {
		"@media (min-width:600px)": {
			width: "480px",
		},
	},
	card: {
		width: "100%",
		borderRadius: "20px",
		padding: "20px",
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-start",
		gap: "12px",
		border: "2px solid",
		backgroundColor: "#FFFFFF",
	},
	typography: {
		color: "#000",
	},
	actionRoot: {
		padding: "8px 8px 8px 16px",
		justifyContent: "space-between",
	},
	icons: {
		marginLeft: "auto",
	},
	expand: {
		padding: "8px 8px",
		transform: "rotate(0deg)",
		color: "#000",
		transition: "all .2s",
	},
	expandOpen: {
		transform: "rotate(180deg)",
	},
	paper: {
		backgroundColor: "#fff",
		padding: 16,
	},
	checkIcon: {
		fontSize: 20,
		paddingRight: 4,
	},
	button: {
		padding: 0,
		textTransform: "none",
	},
}));

const DS1Notification = forwardRef((props, ref) => {
	const classes = useStyles();
	const { closeSnackbar } = useSnackbar();
	const [expanded, setExpanded] = useState(false);

	const handleExpandClick = useCallback(() => {
		setExpanded((oldExpanded) => !oldExpanded);
	}, []);

	const handleDismiss = useCallback(() => {
		closeSnackbar(props.id);
		if (props?.actions && props?.actions?.deeplink && typeof props?.actions?.deeplink == "function") props?.actions?.deeplink();
	}, [props.id, closeSnackbar]);

	const getIcon = () => {
		switch (props?.severity) {
			case "error":
				return (
					<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
						<path
							d="M20 12L12 20M12 12L20 20M29.3333 16C29.3333 23.3638 23.3638 29.3333 16 29.3333C8.63616 29.3333 2.66663 23.3638 2.66663 16C2.66663 8.63616 8.63616 2.66663 16 2.66663C23.3638 2.66663 29.3333 8.63616 29.3333 16Z"
							stroke="#F04438"
							stroke-width="2"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
					</svg>
				);
			case "success":
				return (
					<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
						<path
							d="M10.0001 16L14.0001 20L22.0001 12M29.3334 16C29.3334 23.3638 23.3639 29.3333 16.0001 29.3333C8.63628 29.3333 2.66675 23.3638 2.66675 16C2.66675 8.63616 8.63628 2.66663 16.0001 2.66663C23.3639 2.66663 29.3334 8.63616 29.3334 16Z"
							stroke="#00CE7E"
							stroke-width="2"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
					</svg>
				);
			default:
				return (
					<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
						<path
							d="M16 21.3333V16M16 10.6666H16.0133M29.3333 16C29.3333 23.3638 23.3638 29.3333 16 29.3333C8.63616 29.3333 2.66663 23.3638 2.66663 16C2.66663 8.63616 8.63616 2.66663 16 2.66663C23.3638 2.66663 29.3333 8.63616 29.3333 16Z"
							stroke="#095CED"
							stroke-width="2"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
					</svg>
				);
		}
	};
	return (
		<SnackbarContent ref={ref} className={classes.root}>
			<Box
				className={classes.card}
				sx={{
					borderColor: "#095CED",
					...(props?.severity == "error" && {
						borderColor: "#F04438 !important",
					}),
					...(props?.severity == "success" && {
						borderColor: "#00CE7E !important",
					}),
				}}
			>
				{getIcon()}
				<Typography
					variant="Text/md/Semibold"
					component={"p"}
					sx={{
						color: "#095CED",
						...(props?.severity == "error" && {
							color: "#F04438 !important",
						}),
						...(props?.severity == "success" && {
							color: "#00CE7E !important",
						}),
					}}
				>
					{props?.message?.title}
				</Typography>
				<Typography variant="Text/sm/Regular" component={"p"}>
					{props?.message?.body}
				</Typography>
				<Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: 1, width: "100%" }}>
					<Button variant="DS1" onClick={() => handleDismiss()} fullWidth>
						Ok
					</Button>
				</Box>
				{/*<CardActions classes={{ root: classes.actionRoot }}>
					<Typography variant="body2" className={classes.typography}>
						{props.message}
					</Typography>
					<div className={classes.icons}>
						<IconButton
							aria-label="Show more"
							size="small"
							className={`${classes.expand} ${expanded ? classes.expandOpen : ""}`}
							onClick={handleExpandClick}
						>
							<ExpandMoreIcon />
						</IconButton>
						<IconButton size="small" className={classes.expand} onClick={handleDismiss}>
							<CloseIcon fontSize="small" />
						</IconButton>
					</div>
				</CardActions>*/}
				{/*<Collapse in={expanded} timeout="auto" unmountOnExit>
					<Paper className={classes.paper}>
						<Typography gutterBottom variant="caption" style={{ color: "#000", display: "block" }}>
							PDF ready
						</Typography>
						<Button size="small" color="primary" className={classes.button}>
							<CheckCircleIcon className={classes.checkIcon} />
							Download now
						</Button>
					</Paper>
				</Collapse>*/}
			</Box>
		</SnackbarContent>
	);
});

DS1Notification.displayName = "DS1Notification";

export default DS1Notification;
