import { Svg } from "./Svg";

export const CorporateIcon = ({ className, fill = "#667085", width = 50, height = 50 }) => (
	<Svg className={className} width={width} height={height} viewBox="0 0 30 30" fill="none">
		<path
			d="M8.99984 11.6667H5.13317C4.38643 11.6667 4.01307 11.6667 3.72785 11.812C3.47697 11.9398 3.27299 12.1438 3.14516 12.3947C2.99984 12.6799 2.99984 13.0533 2.99984 13.8V25M20.9998 11.6667H24.8665C25.6132 11.6667 25.9866 11.6667 26.2718 11.812C26.5227 11.9398 26.7267 12.1438 26.8545 12.3947C26.9998 12.6799 26.9998 13.0533 26.9998 13.8V25M20.9998 25V5.26667C20.9998 3.77319 20.9998 3.02646 20.7092 2.45603C20.4535 1.95426 20.0456 1.54631 19.5438 1.29065C18.9734 1 18.2266 1 16.7332 1H13.2665C11.773 1 11.0263 1 10.4559 1.29065C9.9541 1.54631 9.54615 1.95426 9.29049 2.45603C8.99984 3.02646 8.99984 3.77319 8.99984 5.26667V25M28.3332 25H1.6665M13.6665 6.33333H16.3332M13.6665 11.6667H16.3332M13.6665 17H16.3332"
			stroke={fill}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</Svg>
);
