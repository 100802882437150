import React from "react";

import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { SliderComponent } from "./styles";

import constants, {
  MINIROADRUNNERFILLED,
  MINIROADRUNNERICON,
} from "../../../../constants";

const LabelComponent = ({ id }) => {
  return (
    <Box textAlign={"center"}>
      <img
        src={id === "sliderDesktop" ? MINIROADRUNNERICON : MINIROADRUNNERFILLED}
      />

      <Typography
        sx={theme => ({
          display: "none",
          whiteSpace: "pre-wrap",
          [theme.breakpoints.up("md")]: {
            display: "block",
          },
        })}
        variant="label_medium"
      >
        Expected Progress
      </Typography>
    </Box>
  );
};

export default function CustomizedSlider({
  id,
  value,
  expectedValue,
  maxScore,
}) {
  const getProgressTitle = () => {
    const progressTitle = constants.PROGRESS_TITLES;

    if (value < expectedValue) {
      return progressTitle[0];
    } else if (value === expectedValue) {
      return progressTitle[1];
    } else {
      return progressTitle[2];
    }
  };

  return (
    <Box>
      <Box
        sx={theme => ({
          [theme.breakpoints.down("md")]: {
            textAlign: "center",
          },
          pb: 2,
        })}
      >
        <Typography variant="title_small">{getProgressTitle()}</Typography>
      </Box>
      <SliderComponent
        value={value}
        max={maxScore}
        marks={[
          {
            value: expectedValue,
            label: <LabelComponent id={id} />,
          },
        ]}
      />
    </Box>
  );
}
