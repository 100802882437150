import { Avatar, Box } from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import { NEW_PROFILE_LOGO } from "../../constants";
import { GravatarURL } from "../../constants/profile-data";

export function AvatarWithFallBack({ src, sx, placeHolder = NEW_PROFILE_LOGO, imgProps, ...otherProps }) {
	const [isError, setIsError] = useState(false); // if avatar fallback dont work
	useEffect(() => {
		if (src) {
			setIsError(false);
		}
	}, [src]);
	return !isError ? (
		<Avatar
			src={src ? (!src.includes(GravatarURL) ? src : placeHolder) : placeHolder}
			sx={{ ...sx, backgroundColor: (theme) => theme.palette.secondary.white }}
			imgProps={{
				...imgProps,
				onError: (e) => {
					e.target.src = placeHolder;
					setIsError(true);
				},
			}}
			{...otherProps}
		>
			<Box
				component={"img"}
				className="avatar-fallback"
				onError={(e) => {
					e.target.src = placeHolder;
					setIsError(true);
				}}
				src={src ? (!src.includes(GravatarURL) ? src : placeHolder) : placeHolder}
				sx={{ ...sx, objectFit: "cover", borderRadius: "50%" }}
			/>
		</Avatar>
	) : (
		<Box component={"img"} className="error-fallback" src={placeHolder} sx={{ ...sx, objectFit: "cover", borderRadius: "50%" }} {...otherProps} />
	);
}

export default AvatarWithFallBack;