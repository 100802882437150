import { ChevronLeft, ChevronRight, FirstPage, LastPage } from "@mui/icons-material";
import { Box, Card, Table, TableBody, TableHead, TableCell, TableContainer, TableRow, Typography, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { EmptyItem } from "./RowElementTypes/empty";
import { MentorItem } from "./RowElementTypes/mentor";
import { StartupItem } from "./RowElementTypes/startup";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setCalUser } from "../../../../modules/actions/meetingsAction";
import ROUTES_PATH from "../../../../constants/routes";

export const ListCard = ({
	mentors,
	startups,
	title,
	data,
	type,
	currentPage,
	totalPages,
	getFirstPage,
	getLastPage,
	getPrevPage,
	getNextPage,
	pageSize,
	totalElements,
	routeToScheduling,
	...props
}) => {
	const [rows, setrows] = useState(null);
	const [firstPageButton, setFirstPageButton] = useState({ active: false });
	const [previousPageButton, setPreviousPageButton] = useState({
		active: false,
	});
	const [nextPageButton, setNextPageButton] = useState({ active: false });
	const [lastPageButton, setLastPageButton] = useState({ active: false });
	const history = useHistory();
	const dispatch = useDispatch();

	useEffect(() => {
		setrows(data);
	}, [data]);

	useEffect(() => {
		if (currentPage === 1) {
			setPreviousPageButton({ active: false });
			setFirstPageButton({ active: false });
			setLastPageButton({ active: true });
			setNextPageButton({ active: true });

			if (totalPages === 1) {
				setLastPageButton({ active: false });
				setNextPageButton({ active: false });
			}
		} else if (currentPage === totalPages) {
			setLastPageButton({ active: false });
			setNextPageButton({ active: false });
			setFirstPageButton({ active: true });
			setPreviousPageButton({ active: true });
		} else {
			setFirstPageButton({ active: true });
			setLastPageButton({ active: true });
			setNextPageButton({ active: true });
			setPreviousPageButton({ active: true });
		}
	}, [currentPage, totalPages]);

	const getRowElement = (row) => {
		const handleClick = () => {
			if (mentors) {
				if (routeToScheduling) {
					const user = row.user;

					dispatch(
						setCalUser({
							id: user.calUserId,
							name: user?.name,
							email: user?.email,
							picture: user?.picture,
						})
					);

					history.push(ROUTES_PATH.MEETING_TYPES_SCHEDULE);
				} else {
					history.push(`${ROUTES_PATH.MY_ACCOUNT_PROFILE}/${row?.user?.id}`);
				}
			}
			if (startups) {
				history.push(`${ROUTES_PATH.STARTUP_PROFILE}/${row?.company?.id}`);
			}
		};
		if (mentors) {
			return <MentorItem mentorObj={row} type={type} handleClick={handleClick} routeToScheduling={routeToScheduling} />;
		} else if (startups) {
			return <StartupItem startupObj={row} type={type} handleClick={handleClick} />;
		} else {
			return <></>;
		}
	};

	return (
		<Card elevation={1} sx={{ mx: "4px", mt: "4px" }}>
			<Box
				sx={(theme) => ({
					p: 2,
					display: "flex",
					flexDirection: "column",
					gap: 2,
				})}
			>
				<Box>
					<Typography variant="title_medium">{title}</Typography>
				</Box>
				<Box>
					<TableContainer>
						<Table>
							<TableHead>
								<TableCell>Name</TableCell>
							</TableHead>
							<TableBody>
								{rows && rows.length > 0 ? (
									rows.map((row) => (
										<TableRow key={row.id}>
											<TableCell
												sx={{
													p: 0,
												}}
											>
												{getRowElement(row)}
											</TableCell>
										</TableRow>
									))
								) : (
									<TableRow>
										<TableCell
											sx={{
												p: 1,
											}}
										>
											<EmptyItem message={"No records found"} />
										</TableCell>
									</TableRow>
								)}
							</TableBody>
						</Table>
					</TableContainer>
				</Box>
				<Box
					sx={{
						display: "flex",
						justifyContent: "flex-end",
						alignItems: "center",
					}}
				>
					<Box sx={{ flex: 1 }}>
						{totalPages > 0 && (
							<Typography variant="body_medium">
								{(currentPage - 1) * pageSize + 1} - {(currentPage - 1) * pageSize + rows.length} of {totalElements}
							</Typography>
						)}
					</Box>
					<Box sx={{ display: "flex", alignItems: "center" }}>
						<IconButton disabled={!firstPageButton.active} onClick={getFirstPage}>
							<FirstPage />
						</IconButton>
						<IconButton disabled={!previousPageButton.active} onClick={getPrevPage}>
							<ChevronLeft />
						</IconButton>
						<IconButton disabled={!nextPageButton.active} onClick={getNextPage}>
							<ChevronRight />
						</IconButton>
						<IconButton disabled={!lastPageButton.active} onClick={getLastPage}>
							<LastPage />
						</IconButton>
					</Box>
				</Box>
			</Box>
		</Card>
	);
};
