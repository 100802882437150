import styled from "@emotion/styled";
import { Card, CardActions, Button, Box, CardContent, Typography } from "@mui/material";

export const CardPlugIn = styled(Card)(({ theme }) => ({
  color: "darkslategray",
  backgroundColor: "rgba(255, 255, 255, 1)", // StartupOS/sys/light/on-primary
  borderRadius: 8,
  boxShadow: "0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px rgba(0, 0, 0, 0.3)",
  padding: 0,
  width: 381.33,
  height: 480,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between"
}));

export const CardContentPlugIn = styled(CardContent)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-end"
}));

export const CardActionsPlugIn = styled(CardActions)(({ theme }) => ({
  display: "flex", 
  justifyContent: "flex-end", 
  alignItems: "flex-end"
}));

export const BoxPlugInTitle = styled(Box) (({theme}) => ({
  height: 95,
  maxHeight: 95,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center"
}))

export const BoxPlugInDetails = styled(Box) (({theme}) => ({
  height: 95,
  maxHeight: 95,
  display: "flex",
  alignItems: "center"
}))

export const ButtonPlugIn = styled(Button)(({ theme }) => ({
  width: 83,
  height: 40,
  borderRadius: 4,
  margin: 5
}));

export const TypographyPlugIn = styled(Typography)(({ theme }) => ({
  overflow: "hidden",
  textOverFlow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: "3",
  WebkitBoxOrient: "vertical"
}));