import { Box, Grid, Stack } from "@mui/material";
import React, { useState, useEffect } from "react";
import WorkspaceHeader from "../../../../components/Workspace/Header";

import { getUser, logout } from "../../../../utils/auth0-spa";
import { authWebLogout } from "../../../../utils/auth0";
import { useSelector } from "react-redux";
import { useWindowSize } from "../../../../utils/windowResize";
import CreateMatrix from "./CreateMatrix";
import { getPowerupDetails } from "./functions";

export default function MVPPrioritizer() {
	const onLogoutHandler = async () => {
		await logout();
		await authWebLogout();
	};

	const size = useWindowSize();
	const powerUpId = 20;
	const authData = useSelector((state) => state?.auth);

	const [scrolled, setScrolled] = useState(false);
	const [powerupDetails, setPowerupDetails] = useState("");

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		});
		getDetailsApiCall();
	}, []);

	useEffect(() => {
		window.addEventListener("scroll", handleScroll);
	}, []);

	const handleScroll = () => {
		const scrollTop = document.documentElement.scrollTop;
		if (scrollTop > 80) {
			setScrolled(true);
		} else {
			setScrolled(false);
		}
	};

	const getDetailsApiCall = () => {
		getPowerupDetails(powerUpId)
			.then((details) => {
				setPowerupDetails(details);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	return (
		<>
			<Box
				sx={(theme) => ({
					// mb: 33,
					maxWidth: size.width - 232,
					[theme.breakpoints.down("md")]: {
						// mb: 23,
						maxWidth: "100%",
					},
				})}
			>
				{/* <WorkspaceHeader
          onLogout={onLogoutHandler}
          userData={authData.userData}
          isPowerupPages
          headerDetails={{
            show: true,
            title: powerupDetails?.powerUpName || "",
            ratings: powerupDetails?.ratings || "",
            description: powerupDetails?.shortDescription || "",
          }}
          scrolled={scrolled}
        /> */}
			</Box>
			<CreateMatrix
				powerupDetails={powerupDetails}
				started={powerupDetails?.companyTask?.taskStatus ? powerupDetails?.companyTask?.taskStatus : "NOT_STARTED"}
			/>
		</>
	);
}
