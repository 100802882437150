import React, { useEffect, useState, useRef } from "react";
import { PowerUpCard } from "../PowerUpCard";

export default function PowerUpGrid({ details, handleRedirectior, handleArchive, callNextPage, isLast }) {
	const [lastElement, setLastElement] = useState(null);
	const [loading, setLoading] = useState(false);

	const observer = useRef(
		new IntersectionObserver((entries) => {
			const first = entries[0];
			if (first.isIntersecting) {
				callNextPage();
			}
		})
	);

	useEffect(() => {
		const currentElement = lastElement;
		const currentObserver = observer.current;

		if (currentElement) {
			currentObserver.observe(currentElement);
		}

		return () => {
			if (currentElement) {
				currentObserver.unobserve(currentElement);
			}
		};
	}, [lastElement]);

	return (
		<>
			<PowerUpCard details={details} type="version" loading={loading} handleArchive={handleArchive} handleRedirectior={handleRedirectior} />
			{/* {!isLast && (
				<Box
					sx={{
						display: "flex",
						width: 1,
						justifyContent: "center",
						py: 4,
					}}
					ref={setLastElement}
				>
					<CircularProgress />
				</Box>
			)} */}
		</>
	);
}
