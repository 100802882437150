import moment from "moment";
import { CANADA_STATES, TIMEZONE_LIST, USA_STATES } from "../constants";
import { REGEX } from "../constants/regex";
import {
	getDefaultPersonalScriptData,
	getDefaultProblemSolutionScriptData,
	getDefaultValuePropScriptData,
} from "../containers/Workspace/PowerUP/IdeaValidation/Functions/common";
import { USER_TYPES } from "./userTypes";

export const getScriptDescriptionData = (powerUpName) => {
	if (powerUpName === "Problem Solution Fit") {
		return getDefaultProblemSolutionScriptData({});
	} else if (powerUpName === "Value Proposition") {
		return getDefaultValuePropScriptData({});
	} else {
		return getDefaultPersonalScriptData({});
	}
};

export const getRoleValue = (userType) => {
	if (userType === USER_TYPES.MENTOR_USER) {
		return "Mentor";
	} else if (userType === USER_TYPES.ADVISOR_USER) {
		return "Advisor";
	} else if (userType === USER_TYPES.CANDIDATE_USER) {
		return "Candidate";
	} else if (userType === USER_TYPES.INVESTOR_USER) {
		return "Investor";
	} else if (userType === USER_TYPES.PARTNER_USER) {
		return "Accelerator";
	} else if (userType === USER_TYPES.PROFESSOR_USER) {
		return "Instructor";
	} else if (userType === USER_TYPES.STUDENT_USER) {
		return "Student";
	} else if (userType === USER_TYPES.STARTUP_USER) {
		return "Teammate";
	} else if (userType === USER_TYPES.CORPORATE_USER) {
		return "Corporate";
	} else {
		return "Founder";
	}
};

export const getFilterValues = (filterType, filterValue) => {
	if (filterType === "incorporatedStatus") {
		return `Incorporation Status: ${filterValue}`;
	} else if (filterType === "pitchDeckAvailable") {
		return `Pitch Deck Available: ${filterValue}`;
	} else if (filterType === "isPremiumPlanAccess") {
		return `Premium Subscriber: ${filterValue}`;
	} else if (filterType === "scoreRange") {
		return `Score Range: ${filterValue}`;
	} else if (filterType === "stages" || filterType === "currentStage") {
		return `Stage: ${filterValue}`;
	} else if (filterType === "financings") {
		return `Financing: ${filterValue}`;
	} else if (filterType === "startupsJoined") {
		return `Joined Within: ${filterValue}`;
	} else if (filterType === "businessModel") {
		return `Business Model: ${filterValue}`;
	} else if (filterType === "revenueModel") {
		return `Revenue: ${filterValue}`;
	} else if (filterType === "fundingRaised") {
		return `Funding Raised: ${filterValue}`;
	} else if (filterType === "foundedYear" || filterType === "foundedYearTrailingRange") {
		return `Founded Range: ${filterValue}`;
	} else if (filterType === "ask") {
		return `Ask: ${filterValue}`;
	} else if (filterType === "productOffering") {
		return `Product Offering: ${filterValue}`;
	} else if (filterType === "industry") {
		return `Industry: ${filterValue}`;
	} else if (filterType === "investmentRange") {
		return `Range: ${filterValue}`;
	} else if (filterType === "expertise") {
		return `Expertise: ${filterValue}`;
	} else if (filterType === "industry") {
		return `Industry: ${filterValue}`;
	} else if (filterType === "productOfferingelse") {
		return `Product Offering: ${filterValue}`;
	} else if (filterType === "mrr") {
		return `MRR: ${filterValue}`;
	} else if (filterType === "runway") {
		return `Runway: ${filterValue} Months`;
	}
	{
		return filterValue;
	}
};

export const getTeamMemberFilterKeys = (teamMemberValue) => {
	switch (teamMemberValue) {
		case "Top University":
			return "DIM_TOP_U";

		case "First Time Founder":
			return "";

		case "Serial Founder":
			return "";

		case "Female Founder(s)":
			return "";

		case "Diverse Team":
			return "";

		case "Executive Experience":
			return "DIM_EXECUTV";

		case "Seasoned Operator":
			return "DIM_OPR";

		case "Top Company Alum":
			return "DIM_TOP_CMPNY";

		case "Major Tech Company Experience":
			return "DIM_MJR_CMPNY";

		case "Legacy Tech Company Experience":
			return "DIM_LEG_CMPNY";

		case "Major Research Institution Experience":
			return "DIM_MJR_RSRCH";

		case "Venture Background":
			return "DIM_VENT_TAG";

		case "Previous VC Funding":
			return "";

		case "Prior VC Backed Executive":
			return "DIM_PRIOR_EXECTVE";

		case "Prior Exit":
			return "DIM_PRIOR_EXIT";

		case "Multiple Exits":
			return "";

		case "Elite Industry Experience":
			return "DIM_ELITE_IND";

		case "Raised Over $5M Funding Round":
			return "";

		case "Raised Over $25M Funding Round":
			return "";
	}
};

export const getTeamMemberValuesByFilterKeys = (teamMemberKeys) => {
	switch (teamMemberKeys) {
		case "DIM_TOP_U":
			return "Top University ";

		case "First Time Founder":
			return "";

		case "Serial Founder":
			return "";

		case "Female Founder(s)":
			return "";

		case "Diverse Team":
			return "";

		case "DIM_EXECUTV":
			return "Executive Experience";

		case "DIM_OPR":
			return "Seasoned Operator ";

		case "DIM_TOP_CMPNY":
			return "Top Company Alum";

		case "DIM_MJR_CMPNY":
			return "Major Tech Company Experience";

		case "DIM_LEG_CMPNY":
			return "Legacy Tech Company Experience";

		case "DIM_MJR_RSRCH":
			return "Major Research Institution Experience";

		case "DIM_VENT_TAG":
			return "Venture Background";

		case "Previous VC Funding":
			return "";

		case "DIM_PRIOR_EXECTVE":
			return "Prior VC Backed Executive";

		case "DIM_PRIOR_EXIT":
			return "Prior Exit";

		case "Multiple Exits":
			return "";

		case "DIM_ELITE_IND":
			return "Elite Industry Experience";

		case "Raised Over $5M Funding Round":
			return "";

		case "Raised Over $25M Funding Round":
			return "";
	}
};
export const numberToCommaSeparatedString = (number) => {
	if (!number) return "";
	return Number(number).toLocaleString("en-US");
};

export function formatUrl(url) {
	// Check if the URL has "http://" or "https://" at the beginning
	if (/^https?:\/\//i.test(url)) {
		return url; // If the URL already has a valid scheme, return the URL as is
	} else {
		// Otherwise, prepend "https://" to the URL and return the formatted URL
		return "https://" + url;
	}
}

export function timeDifferenceToEvent(futureEvent) {
	let now = new Date();
	let remainingTime = futureEvent - now;

	// Stop the timer when the event is over
	if (remainingTime < 0) {
		return { days: 0, hours: 0, minutes: 0 };
	}

	// Calculate remaining days, hours, and minutes
	let remainingDays = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
	let remainingHours = Math.floor((remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
	let remainingMinutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));

	// Return the remaining time

	return { days: remainingDays, hours: remainingHours, minutes: remainingMinutes, remainingTime: remainingTime };
}

export function openInFullScreen(elem) {
	if (elem) {
		if (elem.requestFullscreen) {
			elem.requestFullscreen();
		} else if (elem.webkitRequestFullscreen) {
			/* Safari */
			elem.webkitRequestFullscreen();
		} else if (elem.msRequestFullscreen) {
			/* IE11 */
			elem.msRequestFullscreen();
		}
	}
}

export function downloadURI(uri, name) {
	if (uri) {
		let link = document.createElement("a");
		// If you don't know the name or want to use
		// the webserver default set name = ''
		link.setAttribute("download", name);
		link.setAttribute("target", "_blank");
		link.href = uri;
		document.body.appendChild(link);
		link.click();
		link.remove();
	}
}

export const convertURIToImageData = (dataurl) => {
	let arr = dataurl.split(","),
		mime = arr[0].match(/:(.*?);/)[1],
		bstr = atob(arr[1]),
		n = bstr.length,
		u8arr = new Uint8Array(n);
	while (n--) {
		u8arr[n] = bstr.charCodeAt(n);
	}
	let filename = Date.now() + "." + mime.split("/")[1];
	return new File([u8arr], filename, {
		type: mime,
	});
};

export const getUserLinkedInUrl = (url) => {
	if (url) {
		if (url.includes("https://www.linkedin.com/in/")) {
			let cleanedLinkedInUrl = url.replace(/^(https:\/\/www\.linkedin\.com\/in\/)(.*)https:\/\/www\.linkedin\.com\/in\//, "$1$2");
			return cleanedLinkedInUrl;
		} else {
			return "https://www.linkedin.com/in/" + url;
		}
	} else {
		return null;
	}
};

export const getCompanyLinkedInUrl = (url) => {
	if (url) {
		if (url.match(/^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(company\/)/gm)) {
			let cleanedLinkedInUrl = url.replace(
				/^(https:\/\/www\.linkedin\.com\/company\/.*?\/)(.*)https:\/\/www\.linkedin\.com\/company\/.*?\/(.*)$/,
				"$1$2"
			);

			return cleanedLinkedInUrl;
		} else {
			return "https://www.linkedin.com/company/" + url;
		}
	} else {
		return null;
	}
};

export const handleExpAndEduSort = (details) => {
	const data = { ...details };
	let exp = details?.experienceDetails;
	let edu = details?.educationDetails;

	data.experienceDetails = exp?.sort((a, b) => (a.startYear < b.startYear ? 1 : -1));
	data.educationDetails = edu?.sort((a, b) => (a.startYear < b.startYear ? 1 : -1));

	return data;
};

export const getJasonFormatedValue = (value) => {
	try {
		if (value) {
			return JSON.parse(value);
		}
	} catch (e) {
		if (value) {
			return value.split(",");
		}
	}
};

export const getIndustryDetailsByName = (filterName) => {
	if (filterName === "Top University") {
		return "Graduate of top 30 undergraduate and graduate programs, as well as the Top 20 international programs and Top 10 business schools.";
	} else if (filterName === "Executive Experience") {
		return "Prior executive position at a company with 100+ employees";
	} else if (filterName === "Seasoned Operator") {
		return "Prior director, manager, or head position at a company with 150+ people";
	} else if (filterName === "Top Company Alum") {
		return "Alumni of a highly pedigreed company";
	} else if (filterName === "Major Tech Company Experience") {
		return "Alumni of any FAANG company or other modern-day tech giants";
	} else if (filterName === "Legacy Tech Company Experience") {
		return "Alumni of a historically successful tech company (ex. Cisco, IBM)";
	} else if (filterName === "Major Research Institution Experience") {
		return "Phd recipient from a major research institution";
	} else if (filterName === "Venture Background") {
		return "Current founder who was a prior founder experience at a company that raised under $5M.";
	} else if (filterName === "Prior VC Backed Executive") {
		return "Prior exec experience at a company that raised any amount of funding.";
	} else if (filterName === "Prior Exit") {
		return "Prior exec/founder experience at a company that was acquired";
	} else if (filterName === "Elite Industry Experience") {
		return "Prior position in an “elite” company (consulting, banking)";
	} else {
		return "";
	}
};

export function formatPhoneNumberString(input) {
	try {
		// Remove any non-digit characters from the input string
		const digitsOnly = input.replace(/\D/g, "");

		// Check if the remaining string contains exactly 10 digits
		if (digitsOnly.length === 10) {
			// Format the phone number as (###) ###-####
			return `(${digitsOnly.slice(0, 3)}) ${digitsOnly.slice(3, 6)}-${digitsOnly.slice(6)}`;
		} else {
			// Return the original input if it doesn't contain 10 digits
			return input;
		}
	} catch (e) {
		console.log("Error when attempting to format phone number. Failed with error:", e);
		return input;
	}
}
export function getKeyByValue(object, value) {
	for (const key in object) {
		if (object[key] === value) {
			return key;
		}
	}
	return null;
}

export const parseStringArray = (stringArray) => {
	try {
		let parsedArray = JSON.parse(stringArray);
		let sentence = "";
		parsedArray?.map((elem, index) => {
			sentence += elem;
			if (index < parsedArray?.length - 1) {
				sentence += ", ";
			}
		});
		return sentence;
	} catch (error) {
		console.error("Error parsing JSON:", error);
		return ""; // or some other appropriate value
	}
};

export const getStatesByRegion = (regionName, filteredStates) => {
	if (regionName === "Mid Atlantic") {
		filteredStates.push("Delaware", "District of Columbia", "Maryland", "New Jersey", "New York", "Pennsylvania", "Virginia", "West Virginia");
	} else if (regionName === "New England") {
		filteredStates.push("Connecticut", "Maine", "Massachusetts", "New Hampshire", "Rhode Island", "Vermont");
	} else if (regionName === "Southeast") {
		filteredStates.push(
			"Alabama",
			"Florida",
			"Georgia",
			"Kentucky",
			"Mississippi",
			"North Carolina",
			"Puerto Rico",
			"South Carolina",
			"Tennessee",
			"Virgin Islands"
		);
	} else if (regionName === "South") {
		filteredStates.push("Arkansas", "Louisiana", "Oklahoma", "Texas");
	} else if (regionName === "Midwest") {
		filteredStates.push("Iowa", "Kansas", "Missouri", "Nebraska", "North Dakota", "South Dakota");
	} else if (regionName === "Great Lakes") {
		filteredStates.push("Illinois", "Indiana", "Michigan", "Minnesota", "Ohio", "Wisconsin");
	} else if (regionName === "Mountain") {
		filteredStates.push("Arizona", "Colorado", "Idaho", "Montana", "Nevada", "New Mexico", "Utah", "Wyoming");
	} else if (regionName === "West Coast") {
		filteredStates.push("Alaska", "California", "Guam", "Hawaii", "Oregon", "Washington");
	}
	return filteredStates;
};

export const validateEmail = (value) => {
	if (REGEX.EMAIL.test(value)) {
		return true;
	}
	return false;
};

export function replaceCountryWithStates(countryName) {
	const usaStates = Object.keys(USA_STATES);
	const canadaStates = Object.keys(CANADA_STATES);

	switch (countryName) {
		case "United States of America":
			return usaStates;
		case "USA Regions":
			return usaStates;
		case "Canada":
			return canadaStates;
		default:
			return [];
	}
}
export function getTimeZoneName(timezone) {
	const timzoneName = TIMEZONE_LIST?.filter((item) => item.zone === timezone)?.[0]?.name;
	return timzoneName;
}

export const isEduEmail = (email) => {
	// Regular expression to match email ending with ".edu"
	let pattern = /\.edu$/i;

	return pattern.test(email);
};

export function replaceStateNamesWithCodes(stateName, stateObject = { ...USA_STATES, ...CANADA_STATES }) {
	for (const code in stateObject) {
		if (stateObject.hasOwnProperty(code) && stateObject[code] === stateName) {
			return code;
		}
	}
	return stateName;
}

export const convertTimeToTargetTimezone = (value, timeZone = moment.tz.guess()) => {
	return moment.utc(value, "HH:mm:ss").tz(timeZone).format("HH:mm:ss");
};
