import styled from "@emotion/styled";
import { Card, CardMedia, CardActionArea, CardContent, Button, Box } from "@mui/material";

export const CardPowerPack = styled(Card)(({ theme }) => ({
  color: "darkslategray",
  backgroundColor: "aliceblue",
  borderRadius: 8,
  boxShadow: "0px 1px 2px rgb(0 0 0 / 30%), 0px 1px 3px 1px rgb(0 0 0 / 15%)",
  padding: 0,
  width: 365,
  height: 480,
  display: "flex",
  flexDirection: "column",
  position: "relative"
}));

export const CardActionAreaPowerPack = styled(CardActionArea) (({ theme }) => ({
  position: "relative",
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "flex-end",
  height: 480
}));

export const CardMediaPowerPack = styled(CardMedia)(({ theme }) => ({
  position: "absolute",
  borderRadius: 8,
  height: "480",
  width: "100%"
}));

export const CardContentPowerPack = styled(CardContent) (({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  position: "relative",
  backgroundColor: "transparent",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-end",
  alignItems: "center",
  width: 365,
  height: 480,
  background: "linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0))"
}));

export const BoxPowerPack = styled(Box) (({ theme }) => ({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  marginBottom: 30
}));

// Need to fix ALL fonts in Button
// Need option to make Button go away on Conditional
// Need to make button not be purple when highlighted
export const ButtonPowerPack = styled(Button) (({ theme }) => ({
  position: "relative",
  width: 150,
  height: 50,
  color: "white",
  borderColor: "white",
  borderRadius: 6,
  marginTop: 15
  // strokeOpacity: 0
}));
