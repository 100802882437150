import React, { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { Box, Select, MenuItem, TextField, IconButton, InputAdornment, Grid, Typography, Link, FormControl, InputLabel, OutlinedInput } from "@mui/material";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";

import { CURRENT_STAGE_LIST, ATTENDED_OPTIONS, INCORPORATED_LIST, STATE_OF_PRODUCT } from "../../../Onboard/Components/Options";
import { getListOfCategoryApiCall } from "../../../Investor/Dashboard/Functions/common";

import { StepTitle, Stepper } from "../ui";
import { InputLabelStyle, InputPropsStyle, LabelStyle, SelectIconComponent, SelectPropsStyle } from "./about";
import Icon from "../../Assets/onboarding-startup.svg";
import { NumericFormat } from "react-number-format";
import { handleMixPanel } from "../../../../utils/mixPanelEventHandling";
import UpdatedSelectField from "../../../../common/UpdatedSelectField";
import OutlinedMultiSelect from "../../../../common/OutlinedMultiSelect";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

export const Startup = ({ step, totalStep, errors, control, setValue, getValues }) => {
	const [industryList, setIndustryList] = useState([]);
	const [productOfferingList, setProductOfferingList] = useState([]);
	const [businessTypeList, setBusinessTypeList] = useState([]);
	const [isOpen, setIsOpen] = useState(false);

	const handleOnclick = () => {
		if (isOpen === false) {
			setIsOpen(true);
		}
	};

	useEffect(() => {
		getCategoryList("industry");
		getCategoryList("product-offering");
		getCategoryList("business-type");
	}, []);

	const getCategoryList = (category) => {
		getListOfCategoryApiCall(category)
			.then((res) => {
				const responseData = res.data;
				let categoryArray = [];
				responseData.map((categoryItem) => {
					categoryArray.push(categoryItem?.lovValue);
				});

				if (category === "industry") {
					setIndustryList(categoryArray);
				} else if (category === "product-offering") {
					setProductOfferingList(categoryArray);
				} else if (category === "business-type") {
					setBusinessTypeList(categoryArray);
				}
			})
			.catch((e) => {
				console.log("Error", e);
			});
	};
	const getJasonFormatedValue = (value) => {
		try {
			if (value) {
				let data = JSON.parse(value);
				if (data.length > 0) {
					return data;
				} else {
					return "No Data";
				}
			}
		} catch (e) {
			if (value) {
				let data = value.split(",");
				if (data.length > 0) {
					return data;
				} else {
					return "No Data";
				}
			} else {
				return "No Data";
			}
		}
	};
	const handleDeleteIncubator = (value) => {
		let tempArray = getJasonFormatedValue([getValues("incubator")]);
		let filteredArray = tempArray.filter((item) => item !== value);
		let incubatorValue = "";
		if (filteredArray.length > 0) {
			incubatorValue = JSON.stringify(tempArray.filter((item) => item !== value));
		} else {
			incubatorValue = null;
		}
		setValue("incubator", incubatorValue || "");
	};

	return (
		<Box width="100%">
			<Stepper step={step} totalStep={totalStep} />
			<StepTitle title="Tell us about your startup" icon={Icon} tooltip="" />
			<Box display="flex" justifyContent="center">
				<Grid
					container
					spacing="32px"
					sx={{
						maxWidth: "1100px",
					}}
				>
					<Grid item xs={12} md={6}>
						<Controller
							name="companyName"
							control={control}
							render={({ field: { onChange, value, name } }) => (
								<TextField
									fullWidth
									id="companyName"
									label="Startup name*"
									value={value}
									name={name}
									sx={{
										"& .MuiOutlinedInput-notchedOutline": {
											border: errors.companyName ? "1px solid #F04438 !important" : "1px solid #DDDDDD",
										},
									}}
									InputProps={{ style: InputPropsStyle }}
									InputLabelProps={InputLabelStyle}
									onChange={onChange}
								/>
							)}
						/>
						{errors.companyName && (
							<Box mt={1}>
								<Typography variant="subhead2" sx={{ color: "#F04438" }}>
									{errors.companyName.message}
								</Typography>
							</Box>
						)}
					</Grid>
					<Grid item xs={12} md={6}>
						<FormControl sx={{ width: "100%" }}>
							<InputLabel id="currentStage" sx={LabelStyle}>
								Stage of startup*
							</InputLabel>
							<Controller
								name="currentStage"
								control={control}
								render={({ field: { onChange, value, name } }) => (
									<Select
										fullWidth
										labelId="currentStage"
										value={value}
										name={name}
										onChange={onChange}
										IconComponent={SelectIconComponent}
										input={<OutlinedInput label="Stage of startup*" />}
										sx={SelectPropsStyle(errors?.currentStage?.message)}
									>
										{CURRENT_STAGE_LIST.map((option) => (
											<MenuItem key={option} value={option}>
												{option}
											</MenuItem>
										))}
									</Select>
								)}
							/>
							{errors?.currentStage && (
								<Box mt={1}>
									<Typography variant="subhead2" sx={{ color: "#F04438" }}>
										{errors?.currentStage?.message}
									</Typography>
								</Box>
							)}
						</FormControl>
					</Grid>

					<Grid item xs={12} md={6}>
						<Box onClick={handleOnclick}>
							<Controller
								name="foundedDate"
								control={control}
								render={({ field: { onChange, value, name } }) => (
									<LocalizationProvider dateAdapter={AdapterMoment}>
										<DatePicker
											open={isOpen}
											onClose={() => setIsOpen(false)}
											format="MM/DD/YYYY"
											label="Date Founded"
											value={value ? moment(value) : null}
											disableFuture={true}
											onChange={(newValue) => {
												onChange(moment(newValue).toDate());
											}}
											slots={{
												OpenPickerIcon: CalendarTodayOutlinedIcon,
											}}
											sx={{
												"& .MuiOutlinedInput-notchedOutline": { borderColor: "#DDDDDD" },
												"&.MuiTextField-root": { width: "100%" },
											}}
											slotProps={{
												openPickerButton: {
													sx: {
														mr: "5px",
													},
												},
												field: {
													InputProps: {
														endAdornment: (
															<InputAdornment label="foundedDate" position="end" sx={{ mr: 1.5 }}>
																<IconButton edge="end">
																	<CalendarTodayOutlinedIcon sx={{ color: "#000" }} />
																</IconButton>
															</InputAdornment>
														),
														variant: "outlined",
														sx: InputPropsStyle,
													},
													InputLabelProps: InputLabelStyle,
												},
											}}
										/>
									</LocalizationProvider>
								)}
							/>
						</Box>
						{errors.foundedDate && (
							<Box mt={1}>
								<Typography variant="subhead2" sx={{ color: "#F04438" }}>
									{errors.foundedDate.message}
								</Typography>
							</Box>
						)}
					</Grid>
					<Grid item xs={12} md={6}>
						<FormControl sx={{ width: "100%" }}>
							<InputLabel id="businessModel" sx={LabelStyle}>
								Business model type*
							</InputLabel>
							<Controller
								name="businessModel"
								control={control}
								render={({ field: { onChange, value, name } }) => (
									<Select
										fullWidth
										labelId="businessModel"
										value={value}
										name={name}
										onChange={onChange}
										IconComponent={SelectIconComponent}
										input={<OutlinedInput label="Business model type*" />}
										MenuProps={MenuProps}
										sx={SelectPropsStyle(errors?.businessModel?.message)}
									>
										{businessTypeList.map((option) => (
											<MenuItem key={option} value={option}>
												{option}
											</MenuItem>
										))}
									</Select>
								)}
							/>
							{errors?.businessModel && (
								<Box mt={1}>
									<Typography variant="subhead2" sx={{ color: "#F04438" }}>
										{errors?.businessModel.message}
									</Typography>
								</Box>
							)}
						</FormControl>
					</Grid>

					<Grid item xs={12} md={6}>
						<Controller
							name="companySize"
							control={control}
							render={({ field: { onChange, value, name } }) => (
								<NumericFormat
									fullWidth
									label="Founding team size"
									value={value}
									customInput={TextField}
									type="text"
									InputLabelProps={InputLabelStyle}
									sx={{
										"& .MuiOutlinedInput-notchedOutline": { border: "1px solid #DDDDDD" },
										"& .MuiInputBase-root": InputPropsStyle,
									}}
									onValueChange={(e) => onChange((e.floatValue || 0).toString())}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<FormControl sx={{ width: "100%" }}>
							<InputLabel id="productOffering" sx={LabelStyle}>
								Product Category*
							</InputLabel>
							<Controller
								name="productOffering"
								control={control}
								render={({ field: { onChange, value, name } }) => (
									<Select
										fullWidth
										labelId="productOffering"
										value={value}
										name={name}
										onChange={onChange}
										IconComponent={SelectIconComponent}
										input={<OutlinedInput label="Product Category*" />}
										MenuProps={MenuProps}
										sx={SelectPropsStyle(errors?.productOffering?.message)}
									>
										{productOfferingList.map((option) => (
											<MenuItem key={option} value={option}>
												{option}
											</MenuItem>
										))}
									</Select>
								)}
							/>
							{errors?.productOffering && (
								<Box mt={1}>
									<Typography variant="subhead2" sx={{ color: "#F04438" }}>
										{errors?.productOffering.message}
									</Typography>
								</Box>
							)}
						</FormControl>
					</Grid>

					<Grid item xs={12} md={6}>
						<Controller
							name="websiteLink"
							control={control}
							render={({ field: { onChange, value, name } }) => (
								<TextField
									fullWidth
									id="websiteLink"
									label="Startup website if applicable"
									value={value}
									name={name}
									sx={{ "& .MuiOutlinedInput-notchedOutline": { borderColor: "#DDDDDD" } }}
									InputProps={{ style: InputPropsStyle }}
									InputLabelProps={InputLabelStyle}
									onChange={onChange}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<FormControl sx={{ width: "100%" }}>
							<Controller
								name="capitalRaised"
								control={control}
								render={({ field: { onChange, value, name } }) => (
									<NumericFormat
										fullWidth
										label="Funding Raised to Date*"
										value={value ? Number(value) : null}
										customInput={TextField}
										type="text"
										thousandSeparator={true}
										prefix={"$"}
										sx={{
											"& .MuiOutlinedInput-notchedOutline": {
												border: errors?.capitalRaised?.message ? "1px solid #F04438" : "1px solid #DDDDDD",
											},
											"& .MuiInputBase-root": InputPropsStyle,
										}}
										InputLabelProps={InputLabelStyle}
										onValueChange={(e) => onChange((e.floatValue || 0).toString())}
									/>
								)}
							/>
							{errors?.capitalRaised && (
								<Box mt={1}>
									<Typography variant="subhead2" sx={{ color: "#F04438" }}>
										{errors?.capitalRaised?.message}
									</Typography>
								</Box>
							)}
						</FormControl>
					</Grid>
					<Grid item xs={12} md={6}>
						<Controller
							name="companyLinkedinUrl"
							control={control}
							render={({ field: { onChange, value, name } }) => (
								<TextField
									fullWidth
									id="companyLinkedinUrl"
									label="Startup company LinkedIn URL if applicable"
									value={value}
									name={name}
									sx={{ "& .MuiOutlinedInput-notchedOutline": { borderColor: "#DDDDDD" } }}
									InputProps={{ style: InputPropsStyle }}
									InputLabelProps={InputLabelStyle}
									onChange={onChange}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<FormControl sx={{ width: "100%" }}>
							<Controller
								name="ask"
								control={control}
								render={({ field: { onChange, value, name } }) => (
									<NumericFormat
										fullWidth
										label="How much money are you trying to raise?"
										value={value ? Number(value) : null}
										customInput={TextField}
										type="text"
										thousandSeparator={true}
										prefix={"$"}
										sx={{
											"& .MuiOutlinedInput-notchedOutline": { border: "1px solid #DDDDDD" },
											"& .MuiInputBase-root": InputPropsStyle,
										}}
										InputLabelProps={InputLabelStyle}
										onValueChange={(e) => onChange((e.floatValue || 0).toString())}
									/>
								)}
							/>
						</FormControl>
					</Grid>
					<Grid item xs={12} md={6}>
						<FormControl sx={{ width: "100%" }}>
							<InputLabel id="incubator" sx={LabelStyle}>
								Have you attended an Accelerator/Incubator?*{" "}
							</InputLabel>
							<Controller
								name="incubator"
								control={control}
								render={({ field: { onChange, value, name } }) => (
									<OutlinedMultiSelect
										id={"incubator"}
										lable={"Have you attended an Accelerator/Incubator?*"}
										options={ATTENDED_OPTIONS}
										handleChange={(event) => {
											let selectedValues = event.target.value;

											if (selectedValues.length > 0 && selectedValues[selectedValues.length - 1] === "None") {
												onChange(JSON.stringify(["None"]));
											} else {
												const newSelectedValues = selectedValues.filter((item) => item !== "None");
												onChange(newSelectedValues.length > 0 ? JSON.stringify(newSelectedValues) : null);
											}
										}}
										handleDelete={handleDeleteIncubator}
										inputValue={getJasonFormatedValue(value) ? getJasonFormatedValue(value) : []}
										error={errors?.incubator?.message}
										selectProps={{
											multiple: true,
											fullWidth: true,
											IconComponent: SelectIconComponent,
											height: "60px",
											width: "100%",
										}}
									/>
								)}
							/>
							{errors?.incubator && (
								<Box>
									<Typography variant="subhead2" sx={{ color: "#F04438" }}>
										{errors?.incubator?.message}
									</Typography>
								</Box>
							)}
						</FormControl>
					</Grid>
					<Grid item xs={12} md={6}>
						{/* <FormControl sx={{ width: "100%" }}>
							<InputLabel id="revenueModel" sx={LabelStyle}>
								What is your monthly revenue if applicable?
							</InputLabel>
							<Controller
								name="revenueModel"
								control={control}
								render={({ field: { onChange, value, name } }) => (
									<Select
										fullWidth
										labelId="revenueModel"
										value={value}
										name={name}
										onChange={onChange}
										IconComponent={SelectIconComponent}
										input={<OutlinedInput label="What is your monthly revenue if applicable?" />}
										MenuProps={MenuProps}
										sx={SelectPropsStyle(errors?.revenueModel?.message)}
									>
										{["Yes", "No"].map((option) => (
											<MenuItem key={option} value={option}>
												{option}
											</MenuItem>
										))}
									</Select>
								)}
							/>
							{errors?.revenueModel && (
								<Box mt={1}>
									<Typography variant="subhead2" sx={{ color: "#F04438" }}>
										{errors?.revenueModel.message}
									</Typography>
								</Box>
							)}
						</FormControl> */}
						<FormControl sx={{ width: "100%" }}>
							<Controller
								name="monthlyRevenue"
								control={control}
								render={({ field: { onChange, value, name } }) => (
									<NumericFormat
										fullWidth
										label="What is your monthly revenue if applicable?"
										value={value ? Number(value) : null}
										customInput={TextField}
										type="text"
										thousandSeparator={true}
										prefix={"$"}
										sx={{
											"& .MuiOutlinedInput-notchedOutline": { border: "1px solid #DDDDDD" },
											"& .MuiInputBase-root": InputPropsStyle,
										}}
										InputLabelProps={InputLabelStyle}
										onValueChange={(e) => onChange((e.floatValue || 0).toString())}
									/>
								)}
							/>
						</FormControl>
					</Grid>
					<Grid item xs={12} md={6}>
						<FormControl sx={{ width: "100%" }}>
							<InputLabel id="stateOfProduct" sx={LabelStyle}>
								What is the state of your product or service?*
							</InputLabel>
							<Controller
								name="stateOfProduct"
								control={control}
								render={({ field: { onChange, value, name } }) => (
									<Select
										fullWidth
										labelId="stateOfProduct"
										value={value}
										name={name}
										onChange={onChange}
										IconComponent={SelectIconComponent}
										input={<OutlinedInput label="What is the state of your product or service?*" />}
										sx={SelectPropsStyle(errors?.stateOfProduct?.message)}
									>
										{STATE_OF_PRODUCT.map((option) => (
											<MenuItem key={option} value={option}>
												{option}
											</MenuItem>
										))}
									</Select>
								)}
							/>
							{errors?.stateOfProduct && (
								<Box mt={1}>
									<Typography variant="subhead2" sx={{ color: "#F04438" }}>
										{errors?.stateOfProduct.message}
									</Typography>
								</Box>
							)}
						</FormControl>
					</Grid>
					<Grid item xs={12} md={6}>
						<FormControl sx={{ width: "100%" }}>
							<InputLabel id="incorporatedStatus" sx={LabelStyle}>
								Level of Incorporation*
							</InputLabel>
							<Controller
								name="incorporatedStatus"
								control={control}
								render={({ field: { onChange, value, name } }) => (
									<Select
										fullWidth
										labelId="incorporatedStatus"
										value={value}
										name={name}
										onChange={onChange}
										IconComponent={SelectIconComponent}
										input={<OutlinedInput label="Level of Incorporation*" />}
										sx={SelectPropsStyle(errors?.incorporatedStatus?.message)}
									>
										{INCORPORATED_LIST.map((option) => (
											<MenuItem key={option.value} value={option.value}>
												{option.name}
											</MenuItem>
										))}
									</Select>
								)}
							/>
							{errors?.incorporatedStatus && (
								<Box mt={1}>
									<Typography variant="subhead2" sx={{ color: "#F04438" }}>
										{errors?.incorporatedStatus.message}
									</Typography>
								</Box>
							)}
						</FormControl>
					</Grid>
					<Grid item xs={12} md={6}>
						<FormControl sx={{ width: "100%" }}>
							<InputLabel id="industry" sx={LabelStyle}>
								Industry*
							</InputLabel>
							<Controller
								name="industry"
								control={control}
								render={({ field: { onChange, value, name } }) => (
									<Select
										fullWidth
										labelId="industry"
										value={value}
										name={name}
										onChange={onChange}
										IconComponent={SelectIconComponent}
										input={<OutlinedInput label="Industry*" />}
										MenuProps={MenuProps}
										sx={SelectPropsStyle(errors?.industry?.message)}
									>
										{industryList.map((option) => (
											<MenuItem key={option} value={option}>
												{option}
											</MenuItem>
										))}
									</Select>
								)}
							/>
							{errors.industry && (
								<Box mt={1}>
									<Typography variant="subhead2" sx={{ color: "#F04438" }}>
										{errors.industry.message}
									</Typography>
								</Box>
							)}
						</FormControl>
					</Grid>
					<Grid item xs={12} md={6}>
						<Box>
							<Link target="_blank" href="https://get.firstbase.io/startupos" onClick={() => handleMixPanel("Incorporate Clicked")}>
								<Typography variant="Text/sm/Regular" color="#000">
									Click here to Incorporate now with Firstbase.io. &nbsp;
								</Typography>
							</Link>
							<Typography variant="subhead2">
								Use Coupon Code <Typography variant="count_title">“PARTNER10”</Typography> to receive a 10% discount
							</Typography>
						</Box>
					</Grid>
				</Grid>
			</Box>
		</Box>
	);
};
