import { Box } from "@mui/material";
import React from "react";
import { CardWrapper } from "..";
import { CSSTransition } from "react-transition-group";

function CardItem({ timeout, children, onClick, open = true }) {
	return (
		<CSSTransition in={open} appear={open} timeout={timeout} classNames="open">
			<CardWrapper
				onClick={onClick}
				sx={{
					flex: 1,
					flexBasis: "40%",
					transition: "all 0.3s ease-in-out",
					opacity: 0,
					transform: "translateX(50px)",
					cursor: "pointer",
					"&.open-exit-done": {
						opacity: 0,
						transform: "translateX(50px)",
					},
					"&.open-exit-active": {
						opacity: 1,
						transform: "translateX(0px)",
					},
					"&.open-enter-done": {
						opacity: 1,
						transform: "translateX(0px)",
					},
					"&.open-enter-active": {
						opacity: 1,
						transform: "translateX(0px)",
					},
					"&:hover": {
						transform: "scale(1.01)",
						boxShadow: "0px 10px 85px rgba(0, 0, 0, 0.2)",
					},
				}}
			>
				<CSSTransition in={open} timeout={300} classNames="open">
					<Box
						sx={{
							transition: "opacity 0.5s ease-in-out",
							opacity: open ? 1 : 0,
							"&.open-enter-done": {
								opacity: 1,
							},

							"&.open-exit-done": {
								opacity: 0,
							},
							"&.open-enter-active": {
								opacity: 1,
							},
						}}
					>
						{children}
					</Box>
				</CSSTransition>
			</CardWrapper>
		</CSSTransition>
	);
}

export default CardItem;
