import { Box, Skeleton } from "@mui/material";
import React, { useMemo } from "react";
import VideoPlayerComponent from "../../videoPlayer";
import { UserAboutVideoCardContainer } from "./style";
import MissingAsset from "../../../components/Profile/Components/MissingAsset";
import { useSelector } from "react-redux";

const BoxVideoElem = ({ children }) => (
	<Box
		sx={{
			flex: 1,
			maxWidth: "600px",
			"& video": {
				borderRadius: "30px",
				objectFit: "cover",
			},
		}}
	>
		{children}
	</Box>
);
const UserAboutVideoCard = ({}) => {
	const videoUrlValue = useSelector((state) => state?.userProfileData?.userData?.videoUrl);
	const isLoading = useSelector((state) => state?.userProfileData?.loading);

	return (
		<UserAboutVideoCardContainer>
			{isLoading ? (
				<Skeleton animation="wave" variant="rounded" width={"100%"} height={650} sx={{ borderRadius: "40px" }} />
			) : videoUrlValue ? (
				<BoxVideoElem>
					<VideoPlayerComponent className={"about-video-player"} videoUrl={videoUrlValue} width={"100%"} height="auto" />
				</BoxVideoElem>
			) : (
				<MissingAsset sxProps={{ height: "650px", width: "100%", backgroundColor: "white" }} message={"About Video Coming Soon"} />
			)}
		</UserAboutVideoCardContainer>
	);
};

export default UserAboutVideoCard;
