import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Divider,
  Checkbox,
  CircularProgress,
  TextField,
  MenuItem,
} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import { sosPrimary } from "../../../../../constants/Theme/colors";
import theme from "../../../../../constants/Theme";
import services from "../../../../../services";
import GridLayoutComp from "../../../../../common/GridLayout";
import constants from "../../../../../constants";

const IdentifyInterviewGoals = ({ userInputs, setUserInputs }) => {
  const [loader, setLoader] = useState(false);
  const [personaList, setPersonaList] = useState([]);
  const [questionList, setQuestionList] = useState([]);
  const [selectedPersona, setSelectedPersona] = useState({});

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    setLoader(true);
    getExerciseDetailsApiCall();
  }, []);
  useEffect(() => {
    getExerciseDetailsApiCall();
  }, [userInputs]);

  const getExerciseDetailsApiCall = () => {
    if (userInputs?.selectedPersona) {
      setSelectedPersona(userInputs.selectedPersona);
    }
    if (userInputs?.questionsList) {
      setQuestionList(userInputs.questionsList);
    } else {
      setQuestionList(constants.QUESTIONS_DATA);
    }
    services
      .getPowerupData(3)
      .then(response => {
        const res = response.data;

        if (res.code === 200) {
          let moduleId = res.data?.modules[0].id;
          services
            .getModuleStepResponses(moduleId)
            .then(result => {
              const modStepResponses = result.data?.data;
              setLoader(false);
              const completedVersions = modStepResponses?.filter(module =>
                module.hasOwnProperty("completionDate")
              );

              const completedModulesFormatted = completedVersions.map(
                version => ({
                  answers: JSON.parse(
                    version.moduleStepResponses.sort(
                      (a, b) => a.moduleStep.stepNo - b.moduleStep.stepNo
                    )[version.moduleStepResponses.length - 1].answers
                  ),
                  moduleId: version.moduleId,
                  versionId: version.id,
                  completionDate: version.completionDate,
                })
              );

              completedModulesFormatted.map((itm, idx) => {
                itm.personaName = `Persona ${idx + 1}`;

                return itm;
              });

              setPersonaList(completedModulesFormatted);
            })
            .catch(err => console.log(err));
        }
      })
      .catch(error => {});
  };
  const handleInputChange = e => {
    let value = e.target.value;
    let personaObj = personaList.filter(
      (itm, idx) => itm.personaName === value
    );
    if (personaObj[0]?.answers?.gridData) {
      personaObj[0]?.answers?.gridData.map(item => {
        item.isDraggable = false;
        item.isResizable = false;
        return item;
      });
    }
    setSelectedPersona(personaObj[0]);

    setUserInputs({ ...userInputs, selectedPersona: personaObj[0] });
  };

  const handleQuestionSelection = (selected, id) => {
    let listData = [...questionList];
    listData.map(item => {
      if (item.id === id) {
        item.selected = !item.selected;
        return item;
      }
    });
    setQuestionList(listData);
    setUserInputs({ ...userInputs, questionsList: listData });
  };

  return (
    <>
      {loader ? (
        <Box justifyContent={"center"} sx={{ display: "flex", mt: 12 }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box>
          <Box>
            <Typography variant="title_small">Your Problem/Solution</Typography>
          </Box>
          <Box
            sx={{
              mt: 1,
              [theme.breakpoints.down("md")]: {
                ml: 2,
              },
            }}
          >
            <Typography variant="label_large" color={sosPrimary[500]}>
              Problem:
            </Typography>
          </Box>

          <Box
            sx={{
              mt: 1,
              [theme.breakpoints.down("md")]: {
                ml: 2,
              },
            }}
          >
            <Typography variant="bodyMedium">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ac dictum
              ornare cursus ac ut at elit nam. Metus risus iaculis nibh augue
              viverra. Vivamus non est cursus pulvinar ultricies nibh tincidunt
              et blandit. Morbi molestie quam etiam habitasse vulputate donec
              phasellus.
            </Typography>
          </Box>

          <Box
            sx={{
              mt: 1,
              [theme.breakpoints.down("md")]: {
                ml: 2,
              },
            }}
          >
            <Typography variant="label_large" color={sosPrimary[500]}>
              Solution:
            </Typography>
          </Box>

          <Box
            sx={{
              mt: 1,
              [theme.breakpoints.down("md")]: {
                ml: 2,
              },
            }}
          >
            <Typography variant="bodyMedium">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ac dictum
              ornare cursus ac ut at elit nam. Metus risus iaculis nibh augue
              viverra. Vivamus non est cursus pulvinar ultricies nibh tincidunt
              et blandit. Morbi molestie quam etiam habitasse vulputate donec
              phasellus.
            </Typography>
          </Box>
          <Box sx={{ mt: 5 }}>
            <Grid container>
              <Grid xs={8} sm={8} lg={4} md={4}>
                <Box>
                  <Typography variant="title_small">
                    Your User Persona
                  </Typography>
                </Box>

                <TextField
                  label="Persona"
                  fullWidth
                  spellCheck="true"
                  select
                  sx={{ mt: 2 }}
                  onChange={handleInputChange}
                  value={selectedPersona.personaName || ""}
                >
                  {personaList.map(option => (
                    <MenuItem
                      key={option.personaName}
                      value={option.personaName}
                    >
                      {option.personaName}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          </Box>
          <Box
            sx={{
              mt: 2,
              display: { xs: "none", sm: "none", md: "block", lg: "block" },
            }}
          >
            {selectedPersona ? (
              <GridLayoutComp
                gridData={selectedPersona?.answers?.gridData}
                contentData={selectedPersona?.answers?.contentData}
                profileData={selectedPersona?.answers?.profileData}
              />
            ) : (
              ""
            )}
          </Box>
          <Divider
            sx={{ mt: 3 }}
            color={theme.palette.secondary.outlineXLight}
          />
          <Box sx={{ mt: 3 }}>
            <Typography variant="subhead1">
              What would you like to learn from your conversations? Each theme
              will populate a number of questions in your generated script.
              Given the amount of time each question will take to answer during
              the interview, we recommend you choose no more than 6.
            </Typography>
          </Box>
          <Box>
            {questionList.map((que, idx) => {
              return (
                <Box sx={{ mt: 5 }} key={idx}>
                  <FormControlLabel
                    sx={{ fontSize: 14, mt: -1 }}
                    control={
                      <Checkbox
                        sx={{ color: theme.palette.text.primary }}
                        size="small"
                        onChange={e => handleQuestionSelection(e, que.id)}
                        checked={que.selected}
                      />
                    }
                    label={
                      <Box sx={{ display: "contents" }}>
                        <Typography variant="body_bold">
                          {que.statement}&nbsp;
                        </Typography>
                        <Typography variant="bodyMedium">{que.goal}</Typography>
                      </Box>
                    }
                  />
                </Box>
              );
            })}
          </Box>
        </Box>
      )}
    </>
  );
};

export default IdentifyInterviewGoals;
