import { Box } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import { CategoryScale, Chart, BarElement, BarController } from "chart.js";

Chart.register(CategoryScale, BarElement, BarController);

export const BarChart = ({ family, subType, heading, answers }) => {
  const [filteredXAxisData, setFilteredXAxisData] = useState([]);
  const [filteredYAxisData, setFilteredYAxisData] = useState([]);

  useEffect(() => {
    const answersArray = answers || [];
    const countObj = {};
    answersArray.forEach(element => {
      countObj[element] = (countObj[element] || 0) + 1;
    });
    var uniqueValues = [],
      countValues = [];
    for (var property in countObj) {
      if (!countObj.hasOwnProperty(property)) {
        continue;
      }
      uniqueValues.push(property);
      countValues.push(countObj[property]);
      uniqueValues.map((uniqueItem, uniqueIdx) => {
        if (uniqueItem.includes("Extremely likely - 10")) {
          let temp = uniqueItem.replace("Extremely likely - ", "");
          uniqueValues[uniqueIdx] = temp;
        }
      });

      setFilteredXAxisData(uniqueValues);
      setFilteredYAxisData(countValues);
    }
  }, []);

  const dataObj = {
    labels: filteredXAxisData,
    datasets: [
      {
        label: heading,
        backgroundColor: "rgb(153,102,254,0.2)",
        borderColor: "rgb(153,102,254)",
        borderWidth: 1,
        borderRadius: 4,
        data: filteredYAxisData,
      },
    ],
  };

  return (
    <Box>
      <Bar
        data={dataObj}
        options={{
          plugins: {
            datalabels: {
              display: false,
            },
            legend: {
              display: false,
            },
            tooltips: {
              enabled: false,
            },
            hover: { mode: null },
          },
          scales: {
            y: {
              ticks: {
                stepSize: 1,
              },
            },
          },
        }}
      />
    </Box>
  );
};
