import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Box, Button, Divider, IconButton, Typography } from "@mui/material";
import PageTitle from "../../common/PageTitle";
import { USER_WITH_TICK } from "../../constants";

const Quicksight = () => {
	return (
		<>
			<Helmet>
				<meta charSet="utf-8" />
				<title>StartupOS - Startup Stats</title>
			</Helmet>
			<Box
				sx={{
					px: {
						lg: 8,
						md: 4,
						xs: 2,
					},
					pb: "30px",
					display: "flex",
					flexDirection: "column",
					gap: "32px",
					maxWidth: "1920px",
					margin: "0 auto",
				}}
			>
				{" "}
				<PageTitle icon={USER_WITH_TICK} label={"Startup Stats"} />
				<Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
					<Box sx={{ width: 1 }}>
						<iframe
							style={{ width: "inherit", border: "none" }}
							height="720"
							src="https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/328214258397/dashboards/e15bca75-1078-4e1b-a874-c345973420ca?directory_alias=swilley"
						></iframe>
					</Box>
				</Box>
			</Box>
		</>
	);
};

export default Quicksight;
