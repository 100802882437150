import React, { useEffect, useMemo, useState } from "react";
import { Box, styled, Button, CircularProgress } from "@mui/material";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

import services from "../../../../services";
import WorkspaceHeader, { ActionBtn } from "../../../../components/Investor/Workspace/View/Header";
import PowerUpIcon from "../../../../components/Investor/Workspace/Assets/new-home-powerup-icon.svg";
import CoursesIcon from "../../../../components/Investor/Workspace/Assets/new-home-powerup-courses-icon.svg";
import SoonIcon from "../../../../components/Investor/Workspace/Assets/new-home-tools-soon-icon.svg";
import PartnerIcon from "../../../../components/Investor/Workspace/Assets/new-home-powerup-partner-icon.svg";
import NextIcon from "../../../../components/Investor/Workspace/Assets/new-home-powerup-next-icon.svg";
import { HomeSectionTitle } from "../../../../components/Investor/Workspace/View/HomeTitle";
import HomeCard from "../../../../components/Investor/Workspace/View/HomeCard";
import { useWindowSize } from "../../../../utils/windowResize";
import { handleMixPanel } from "../../../../utils/mixPanelEventHandling";
import { useSelector } from "react-redux";

ChartJS.register(ArcElement, Tooltip, Legend);

export default function PowerUps() {
	const size = useWindowSize();
	const [powerUpCards, setPowerUpCards] = useState([]);
	const [comingCards, setComingCards] = useState([]);
	const [partnerCards, setPartnerCards] = useState([]);
	const [nextCards, setNextCards] = useState([]);
	const [showAllPowerUp, setShowAllPowerUp] = useState(true);
	const [showAllComing, setShowAllComing] = useState(true);
	const [showAllPartners, setShowAllPartners] = useState(true);
	const [showAllNext, setShowAllNext] = useState(true);
	const [loading, setLoading] = useState(false);
	const authData = useSelector((state) => state?.auth);

	useEffect(() => {
		getCurrentGroup("powerups");
		getCurrentGroup("partner-powerups");
	}, []);

	const cardLength = useMemo(() => {
		if (size.width > 1999) {
			return 4;
		} else if (size.width > 1587) {
			return 3;
		} else if (size.width > 1110) {
			return 2;
		} else if (size.width > 980) {
			return 1;
		} else if (size.width > 828) {
			return 2;
		} else {
			return 1;
		}
	}, [size]);

	const getCurrentGroup = async (group) => {
		setLoading(true);
		try {
			const powerUps = await services.getToDosList(group);
			if (powerUps?.data?.data) {
				if (group === "powerups") {
					setPowerUpCards(powerUps?.data?.data.filter((powerUp) => powerUp.cardStatus === "PUBLISHED" && powerUp?.isAiPowered === true));
					setComingCards(powerUps?.data?.data.filter((powerUp) => powerUp.cardStatus === "COMING_SOON" && powerUp?.isAiPowered === true));
					setNextCards(powerUps?.data?.data?.filter((powerUp) => powerUp?.cardStatus === "COMING_SOON" && powerUp?.isAiPowered !== true));
				} else {
					setPartnerCards(powerUps?.data?.data);
				}
			}
		} catch (error) {
			console.log(error);
		}
		setLoading(false);
	};

	const Actions = (
		<Box display="flex" gap="30px">
			<ActionBtn color="#7B61FF" title="PowerUps" avatar={PowerUpIcon} tabIndex={2} actionIndex={0} onAction={() => {}} />
		</Box>
	);

	const powerUpItems = useMemo(() => {
		return showAllPowerUp ? powerUpCards : powerUpCards.slice(0, cardLength);
	}, [showAllPowerUp, powerUpCards, cardLength]);

	const comingItems = useMemo(() => {
		return showAllComing ? comingCards : comingCards.slice(0, cardLength);
	}, [showAllComing, comingCards, cardLength]);

	const partnerItems = useMemo(() => {
		return showAllPartners ? partnerCards : partnerCards.slice(0, cardLength);
	}, [showAllPartners, partnerCards, cardLength]);

	const nextItems = useMemo(() => {
		return showAllNext ? nextCards : nextCards.slice(0, cardLength);
	}, [showAllNext, nextCards, cardLength]);

	const handlePowerUpCount = () => {
		setShowAllPowerUp(!showAllPowerUp);
	};

	const handleComingCount = () => {
		setShowAllComing(!showAllComing);
	};

	const handleNextCount = () => {
		setShowAllNext(!showAllNext);
	};

	const handlePartnerCount = () => {
		setShowAllPartners(!showAllPowerUp);
	};

	const handleHeart = async (id, isRemoved = false, title = "") => {
		const heart = await services.updateCollections({ entityIds: [id], entityType: "ACTIVITY_CARD", collectionType: "SAVED" }, isRemoved);
		if (isRemoved) {
			handleMixPanel("Unloved Card", {
				"Unloving user name": authData?.userName,
				"Unloving user id": authData?.userId,
				"Unloving card title": title,
				"Unloving card id": id,
			});
		} else {
			handleMixPanel("Loved Card", {
				"Loving user name": authData?.userName,
				"Loving user id": authData?.userId,
				"Unloving card title": title,
				"Loved card id": id,
			});
		}
	};

	return (
		<Box>
			<WorkspaceHeader id={2} actions={Actions} />
			<Box mt={4}>
				<Box mb={4}>
					<HomeSectionTitle icon={CoursesIcon} title="PowerUps" count={powerUpCards.length} />
					{loading ? (
						<Box
							sx={{
								display: "flex",
								width: 1,
								justifyContent: "center",
								alignItems: "center",
								height: "50vh",
							}}
						>
							<CircularProgress />
						</Box>
					) : (
						<Box display="flex" flexWrap={"wrap"} gap="30px">
							{powerUpItems.map((powerUp) => (
								<HomeCard
									key={powerUp?.id}
									isSpecialVariant={true}
									title={powerUp?.title}
									cardType={powerUp?.cardType}
									cardStatus={powerUp?.cardStatus}
									taskStatus={powerUp?.cardProgressDetails?.taskStatus}
									linkedEntityId={powerUp?.linkedEntityId}
									desc={powerUp?.shortDescription}
									imgSrc={powerUp?.imageUrl}
									isAI={powerUp?.isAiPowered}
									isPremium={powerUp?.isPremiumFeature}
								/>
							))}
						</Box>
					)}
					{powerUpCards.length > cardLength && <ShowCards handleShowCard={handlePowerUpCount} isShow={showAllPowerUp} />}
				</Box>
				<Box mb={4}>
					<HomeSectionTitle icon={SoonIcon} title="Coming Soon" count={comingCards.length} />
					{loading ? (
						<Box
							sx={{
								display: "flex",
								width: 1,
								justifyContent: "center",
								alignItems: "center",
								height: "50vh",
							}}
						>
							<CircularProgress />
						</Box>
					) : (
						<Box display="flex" flexWrap={"wrap"} gap="30px">
							{comingItems.map((coming) => (
								<HomeCard
									key={coming?.id}
									variant="coming"
									isSpecialVariant={true}
									title={coming?.title}
									cardType={coming?.cardType}
									cardStatus={coming?.cardStatus}
									taskStatus={coming?.cardProgressDetails?.taskStatus}
									linkedEntityId={coming?.linkedEntityId}
									desc={coming?.shortDescription}
									imgSrc={coming?.imageUrl}
									isAI={coming?.isAiPowered}
									isPremium={coming?.isPremiumFeature}
								/>
							))}
						</Box>
					)}
					{comingCards.length > cardLength && <ShowCards handleShowCard={handleComingCount} isShow={showAllComing} />}
				</Box>
				<Box mb={4}>
					<HomeSectionTitle icon={PartnerIcon} title="Partner PowerUps" count={partnerCards.length} />
					{loading ? (
						<Box
							sx={{
								display: "flex",
								width: 1,
								justifyContent: "center",
								alignItems: "center",
								height: "50vh",
							}}
						>
							<CircularProgress />
						</Box>
					) : (
						<Box display="flex" flexWrap={"wrap"} gap="30px">
							{partnerItems.map((partner) => (
								<HomeCard
									key={partner?.id}
									isSpecialVariant={true}
									title={partner?.title}
									cardType={partner?.cardType}
									cardStatus={partner?.cardStatus}
									taskStatus={partner?.cardProgressDetails?.taskStatus}
									linkedEntityId={partner?.linkedEntityId}
									desc={partner?.shortDescription}
									imgSrc={partner?.imageUrl}
									isAI={partner?.isAiPowered}
									isPremium={partner?.isPremiumFeature}
								/>
							))}
						</Box>
					)}
					{partnerCards.length > cardLength && <ShowCards handleShowCard={handlePartnerCount} isShow={showAllPartners} />}
				</Box>
				<Box mb={4}>
					<HomeSectionTitle icon={NextIcon} title="What we should build next? Vote with a heart!" count={nextCards.length} />
					{loading ? (
						<Box
							sx={{
								display: "flex",
								width: 1,
								justifyContent: "center",
								alignItems: "center",
								height: "50vh",
							}}
						>
							<CircularProgress />
						</Box>
					) : (
						<Box display="flex" flexWrap={"wrap"} gap="30px">
							{nextItems.map((next) => (
								<HomeCard
									key={next?.id}
									isSpecialVariant={true}
									variant="partner"
									id={next?.id}
									isSaved={next?.isSaved && !next?.isRemoved ? true : false}
									title={next?.title}
									cardType={next?.cardType}
									cardStatus={next?.cardStatus}
									taskStatus={next?.cardProgressDetails?.taskStatus}
									linkedEntityId={next?.linkedEntityId}
									desc={next?.shortDescription}
									imgSrc={next?.imageUrl}
									isAI={next?.isAiPowered}
									isPremium={next?.isPremiumFeature}
									onHeart={handleHeart}
								/>
							))}
						</Box>
					)}
					{nextCards.length > cardLength && <ShowCards handleShowCard={handleNextCount} isShow={showAllNext} />}
				</Box>
			</Box>
		</Box>
	);
}

export const ShowCards = ({ handleShowCard, isShow }) => {
	return (
		<Box mt={2}>
			<BtnAll onClick={handleShowCard}>{isShow ? `Collapse All` : `See All`}</BtnAll>
		</Box>
	);
};

export const BtnAll = styled(Button)({
	background: "#000",
	height: "48px",
	fontSize: "16px",
	fontWeight: 600,
	borderRadius: "100px",
	width: "auto",
	"&:focus": {
		background: "#000",
	},
});
