import mixpanel from "mixpanel-browser";

const MIX_PANEL_TOKEN = process.env.REACT_APP_MIX_PANEL_TOKEN;

mixpanel.init(MIX_PANEL_TOKEN, {
  //debug: true, // only for Dev
  ignore_dnt: true,
});

export const handleMixPanel = (eventName, data) => {
  mixpanel.track(eventName, data);
};

export const handleMixPanelSetUser = userDetails => {
  mixpanel.identify(userDetails?.id);
  mixpanel.people.set(userDetails);
};
