import React, { useEffect, useMemo, useState } from "react";
import { Box, Typography, Tabs, Tab, Stack, Link } from "@mui/material";
import LoadingButton from "../../../common/Loadingbutton";
import { formatCompactNumber } from "../validation";

// Does not show these. Maybe these should be shown in the future
const MOCK_HIGHTLIGHTS = [
	{ title: "Product and Completed Operations Liability", desc: "$X Limit" },
	{ title: "Personal and Advertising Injury", desc: "$X Limit" },
	{ title: "Damage to Rented Premises", desc: "$X Limit" },
	{ title: "Medical Liability Pay", desc: "$X Limit" },
];

export const CustomizePackage = ({ quotes, applicationId, onCheckout }) => {
	const vouchEnv = process.env.REACT_APP_VOUCH_ENV;
	const quote = quotes[0];
	const yearPrice = quote?.pricing.filter(price => price.billing_interval?.interval === "annual");
	const monthPrice = quote?.pricing.filter(price => price.billing_interval?.interval === "monthly");
	const [quoteType, setQuoteType] = useState("BOP");
	const [currenttab, setTab] = useState("General Liability");
	const [coverages, setCoverages] = useState([]);

	useEffect(() => {
		if (quote?.products) {
			setQuoteType(quote?.products[0]?.label);

			if (quote?.products[0]?.coverage_groups[0]?.coverages) {
				if (quote?.products[0]?.coverage_groups[0]?.coverages.length > 0) {
					if (quote?.products[0]?.label === "BOP") {
						setCoverages(quote?.products[0]?.coverage_groups[0]?.coverages.slice(0, 2));
					} else if (quote?.products[0]?.label === "CPP") {
						const generalData = quote?.products[0]?.coverage_groups[0]?.coverages.filter(coverage => coverage.label === "Products and Completed Operations");
						const businessData = quote?.products[0]?.coverage_groups[0]?.coverages.filter(coverage => coverage.label === "Business Personal Property");
						const coverageData = [];
						if (generalData.length > 0) {
							coverageData.push({ ...generalData[0], label: "General Liability" });
						}

						if (businessData.length > 0) {
							coverageData.push({ ...businessData[0], label: "Business Property" });
						}

						setCoverages(coverageData);
					} else {
						setCoverages([]);
						setQuoteType("");
					}
				}
			}
		}
	}, [quote]);

	const handleCheckout = () => {
		window.open(
			`https://${vouchEnv === "sandbox" ? "uat-quote.vouch-stg.us" : "app.vouch.us"}/application/billing?applicationId=${applicationId}&packageSlug=basic`,
			"_blank"
		);
	};

	const handleCustomize = () => {
		window.open(
			`https://${vouchEnv === "sandbox" ? "uat-quote.vouch-stg.us" : "app.vouch.us"}/quote?applicationId=${applicationId}&packageSlug=basic`,
			"_blank"
		);
	};

	const handleVouchSignIn = () => {
		window.open(`https://${vouchEnv === "sandbox" ? "uat-quote.vouch-stg.us" : "app.vouch.us"}/loginportal/send`, "_blank");
	};

	const menuButtonClick = (e, newValue) => {
		setTab(newValue);
	};

	const coverageData = useMemo(() => {
		const data = coverages.filter(coverage => coverage?.label === currenttab)[0];

		return data;
	}, [currenttab, coverages]);

	const coverageTitle = useMemo(() => {
		const labels = coverages.map(coverage => coverage?.label);

		return labels.flat().join(" & ");
	}, [coverages]);

	const PackageDetail = (title, price) => {
		return (
			<Box display="flex" alignItems="center" justifyContent={"space-between"} my="4px">
				<Typography variant="subhead2">{title}:</Typography>
				<Typography variant="subhead2" sx={{ marginLeft: "20px" }}>
					{price}
				</Typography>
			</Box>
		);
	};

	if (coverages.length === 0 && quoteType === "") {
		return (
			<Box>
				<Typography variant="main_title" sx={{ display: "block", marginBottom: "20px" }}>
					Something is wrong on Vouch Side
				</Typography>
			</Box>
		);
	}

	return (
		<Box>
			<Box display="flex" alignItems={"flex-start"} justifyContent={"space-between"} sx={{ paddingBottom: 3, borderBottom: "1px solid #eee" }}>
				<Box display="flex" flex={1} flexDirection="column" justifyContent={"flex-start"}>
					<Typography variant="main_title" sx={{ display: "block", marginBottom: "20px" }}>
						Basic Package
					</Typography>
					<Box display="flex" alignItems="center" mb={2}>
						<Typography variant="title_small" sx={{ marginRight: "4px" }}>
							Effective Date: {quote?.available_effective_date?.effective_date}
						</Typography>
					</Box>

					<Typography variant="subhead1" sx={{ marginBottom: "4px", color: "#afb899" }}>
						Including
					</Typography>
					<Typography variant="subhead1" sx={{ marginBottom: "4px" }}>
						{coverageTitle}
					</Typography>
				</Box>
				<Box flex={1}>
					<Box mb={"20px"} display="flex" flexDirection="column" alignItems={"flex-end"}>
						<Box display="flex" flexDirection="column" mb={"40px"}>
							{PackageDetail(
								"Subtotal",
								`$${(
									(quote?.pricing[0]?.total_premium_cents - quote?.pricing[0]?.adjustments?.amount_cents - quote?.pricing[0]?.discount?.amount_cents) /
									100
								).toFixed(2)}`
							)}
							{PackageDetail("Taxes & other fees", `$${((quote?.pricing[0].taxes.amount_cents + quote?.pricing[0].fees.amount_cents) / 100).toFixed(2)}`)}
							{PackageDetail("Adjustments", `$${(quote?.pricing[0].adjustments?.amount_cents / 100).toFixed(2)}`)}
							{PackageDetail("Total savings", `$${(quote?.pricing[0]?.discount?.amount_cents / 100).toFixed(2)}`)}
						</Box>

						<Typography variant="title_xxlarge" sx={{ marginBottom: "4px", fontWeight: 500 }}>
							${yearPrice.length > 0 ? (yearPrice[0]?.due_at_checkout_cents / 100).toFixed(2) : (quote?.pricing[0]?.total_premium_cents / 100).toFixed(2)}
						</Typography>
						<Typography variant="subtitle1">PER YEAR</Typography>
						{monthPrice.length > 0 && (
							<Typography variant="subtitle1" sx={{ color: "#afb899" }}>
								${(monthPrice[0]?.due_at_checkout_cents / 100).toFixed(2)}
							</Typography>
						)}
						{monthPrice.length > 0 && (
							<Typography variant="subtitle1" sx={{ color: "#afb899" }}>
								(Save more by paying annually)
							</Typography>
						)}
					</Box>

					{/* <Box display="flex" justifyContent="flex-end" gap={2}>
						<Box>
							<LoadingButton variant="outlined" color="primary" onClick={handleCustomize}>
								Customize Package
							</LoadingButton>
						</Box>

						<Box>
							<LoadingButton variant="contained" color="primary" onClick={handleCheckout}>
								Proceed to Checkout
							</LoadingButton>
						</Box>
					</Box> */}
					<Box mt={1}>
						<Typography variant="subtitle1" sx={{ display: "inline" }}>
							In order to proceed, please check your inbox and verify your Vouch Account. Already have an account?
						</Typography>
						<Link underline="always" sx={{ cursor: "pointer" }} onClick={handleVouchSignIn}>
							<Typography variant="body_small"> Sign In</Typography>
						</Link>
					</Box>
				</Box>
			</Box>
			<Box mt={4}>
				<Typography variant="subhead2">Commercial Package Policy</Typography>

				<Box mt="20px" display="flex" sx={{ border: 1, borderColor: "divider" }}>
					<Box p="20px" sx={{ borderRight: 1, borderColor: "divider" }} flex={1}>
						<Typography variant="title_large2" sx={{ display: "block", marginBottom: "20px", marginTop: "20px" }}>
							{coverageTitle}
						</Typography>
						<Typography variant="count_title" sx={{ display: "block" }}>
							${yearPrice.length > 0 ? (yearPrice[0]?.due_at_checkout_cents / 100).toFixed(2) : (quote?.pricing[0]?.total_premium_cents / 100).toFixed(2)}
						</Typography>
						<Typography variant="subtitle1" sx={{ display: "block", marginBottom: "16px" }}>
							PER YEAR
						</Typography>
					</Box>
					<Box p="20px" flex={2}>
						<Tabs value={currenttab} onChange={menuButtonClick}>
							{coverages.length > 0 &&
								coverages.map(coverage => (
									<Tab
										key={coverage.label}
										sx={{ textTransform: "none", flex: 1 }}
										label={<Typography variant="StartupOS/button/button">{coverage?.label}</Typography>}
										value={coverage?.label}
									/>
								))}
						</Tabs>
						<Box py={4}>
							<Typography variant="body_small" sx={{ marginBottom: "4px" }}>
								{currenttab === "General Liability"
									? `General Liability offers baseline protection against the common risks of running a company, such as injuries on your property or a lawsuit
								brought against you for a trademark infringement.`
									: `Business Property insurance is crafted to cover repairing or replacing equipment your business owns like laptops, desks, chairs, and other hardware.`}
							</Typography>
							{/* <Box mt={4}>
								<Typography variant="body_small" sx={{ marginBottom: "8px" }}>
									Key Highlights
								</Typography>
								{MOCK_HIGHTLIGHTS.map(highlight => (
									<Box display="flex" mt={2}>
										<CheckIcon color="primary" />
										<Box display="flex" flexDirection={"column"} ml={2}>
											<Typography variant="title_small">{highlight.title}</Typography>
											<Typography variant="body_small">{highlight.desc}</Typography>
										</Box>
									</Box>
								))}
							</Box> */}

							{coverageData && (
								<Stack mt={3} direction="row" justifyContent="space-around" spacing={2}>
									{coverageData.limits.map(limit => (
										<LimitBox key={limit?.frequency} frequency={limit?.frequency} amount={limit?.amount_cents} label={limit?.label} />
									))}
									{coverageData.out_of_pockets.map(out_of_pocket => (
										<LimitBox
											key={out_of_pocket?.frequency}
											frequency={out_of_pocket?.frequency}
											amount={out_of_pocket?.amount_cents}
											label={out_of_pocket?.label}
										/>
									))}
								</Stack>
							)}
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

const LimitBox = ({ label, frequency, amount }) => {
	return (
		<Box display="flex" alignItems="center" justifyContent={"center"} flexDirection={"column"}>
			<Typography variant="body_small" sx={{ marginBottom: "8px", fontWeight: 500, textTransform: "uppercase" }}>
				{label}
			</Typography>
			<Typography variant="label_Xlarge" sx={{ marginBottom: "8px", fontWeight: 500 }}>
				${formatCompactNumber(amount)}
			</Typography>
			<Typography variant="body_small" sx={{ fontWeight: 500 }}>
				maximum {frequency.replace("_", " ")}
			</Typography>
		</Box>
	);
};
