import React, { useState, useEffect, useContext, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Box, Fab, Typography, Tooltip, styled, tooltipClasses, Button, CircularProgress, Modal } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import useDimensions from "react-cool-dimensions";
import _ from "lodash";
import { DRAWER_CLOSE_BLACK, PAGE_STARTUP_PROFILE_ICON, USER_CIRCLE } from "../../constants";
import ROUTES_PATH from "../../constants/routes";
import SvgComponent from "../../utils/SVGColor";
import { useWindowSize } from "../../utils/windowResize";
import { setFounderDashboardData } from "../../modules/actions";
import ColumnControl from "../ScoreBenchmarkDetails/Components/ScoreInsights/ColumnControl";
import AboutCard from "./Components/AboutCard";
import BioCard from "./Components/BioCard/Index";
import TeamCard from "./Components/TeamCard";
import ExperienceCard from "./Components/ExperienceCard";
import EducationCard from "./Components/EducationCard";
import PaymentPlanDialog from "../PaymentPlanDialog";
import { ProfileContext } from ".";
import Journey from "../Founder/Journey";
import { setUserProfileData } from "../../modules/actions/userProfileActions";
import UserProfileEditPopup from "../../containers/UserProfileEditPopUp";
import { setSlidingProfileData } from "../../modules/actions/slidingProfileActions";
import { handleMixPanel } from "../../utils/mixPanelEventHandling";
import GridView from "../../containers/Investor/Dashboard/AllStartups/GridView";
import SocialMediaShareButtons from "../../common/SocialMediaShareButtons";
import { InvestorToggleSwitch } from "../../containers/MyStartup/styles";
import UpdatedPitchDeckSection from "./Components/UpdatedPitchDeckSection";
import IndustryDetailsDialog from "../Investor/IndustryDetails";
import PartnerQA from "./Components/PartnerQA";
import { useThemeContext } from "../../constants/Theme/ThemeContext";

export const TourTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
	[`& .${tooltipClasses.tooltip}`]: {
		maxWidth: 200,
		textAlign: "center",
		backgroundColor: "#000",
		fontSize: "14px",
		padding: "4px 8px",
	},
});

function ProfileContent() {
	const dispatch = useDispatch();
	const size = useWindowSize();
	const { viewContext, profileData, updateProfile, loading, partnerQA } = useContext(ProfileContext);
	const [isSmallWidth, setIsSmallWidth] = useState(false);
	const userData = useSelector((store) => ({ companyId: store?.auth?.companyId, userId: store?.auth?.userId, userEmail: store?.auth?.email }));
	const { isAdmin, partnerCode } = useSelector((store) => store?.auth);
	const [showPlanModal, setShowPlanModal] = useState(false);
	const homeApiVersion = process.env.REACT_APP_HOME_URL;
	const startupShareUrl = `${homeApiVersion}/startup/${profileData?.uuid}`;
	const { clearThemeObjects } = useThemeContext();

	const [bioPercentage, setBioPercentage] = useState(0);
	const [aboutPercentage, setAboutPercentage] = useState(0);
	const [educationPercentage, setEducationPercentage] = useState(0);
	const [experiencePercentage, setExperiencePercentage] = useState(0);
	const [pitchDeckPercentage, setPitchDeckPercentage] = useState(0);
	const [investerToggle, setIsInvestorView] = useState(false);
	const [invited, setInvited] = useState(false);

	const slidingProfileData = useSelector((store) => ({
		open: store?.slidingProfile?.open,
		profileType: store?.slidingProfile?.profileType,
		profileId: store?.slidingProfile?.profileId,
	}));
	const location = useLocation();
	const [mixPanelCalled, setMixPanelCalled] = useState(false); //to makesure mixpanel call once
	const { isPremiumPlanAccess, userType } = useSelector((store) => store?.auth);
	const [colCount, setColCount] = useState(3);

	const { observe, unobserve, width, height, entry } = useDimensions({
		breakpoints: { SM: 0, MD: 750, LG: 1100 },
		updateOnBreakpointChange: true,
		onResize: ({ currentBreakpoint }) => {
			if (currentBreakpoint === "LG") {
				setColCount(3);
			} else if (currentBreakpoint === "MD") {
				setColCount(2);
			} else if (currentBreakpoint === "SM") {
				setColCount(1);
			}
		},
	});

	useEffect(() => {
		observe();
	}, []);

	useEffect(() => {
		if (viewContext && Object.keys(profileData)?.length && !mixPanelCalled) {
			setMixPanelCalled(true);
			if (viewContext?.component === "startup") {
				setIsInvestorView(profileData?.investorView);
				const data = {
					"Viewed Startup id": profileData?.id,
					"Viewed Startup name": profileData?.companyName,
					"User Id": userData?.userId,
					subscription_status: isPremiumPlanAccess ? "Subscribed" : "Not Subscribed",
					user_type: userType,
				};
				// Only if personal account.
				if (
					(location.pathname.includes(ROUTES_PATH.MY_STARTUP_PROFILE) && !slidingProfileData?.open && !viewContext?.id) ||
					viewContext?.id === userData?.companyId
				) {
					handleMixPanel("Company Profile Viewed", data);
				}
				if (location.pathname.includes("/investor/dashboard")) {
					handleMixPanel("Opportunity DB Startup Viewed", data);
				}
				if (location.pathname.includes(ROUTES_PATH.NETWORK)) {
					handleMixPanel("Community Startup Viewed", data);
				}
			} else if (viewContext?.component === "account") {
				const data = {
					"Viewed Account id": profileData?.id,
					"Viewed Account name": profileData?.name,
					"User Id": userData?.userId,
					subscription_status: isPremiumPlanAccess ? "Subscribed" : "Not Subscribed",
					user_type: userType,
				};
				// Only if personal account.
				if (
					(location.pathname.includes(ROUTES_PATH.MY_ACCOUNT_PROFILE) && !slidingProfileData?.open && !viewContext?.id) ||
					viewContext?.id == userData?.userId
				) {
					handleMixPanel("Account Profile Viewed", data);
				}
				if (location.pathname.includes(ROUTES_PATH.NETWORK)) {
					handleMixPanel("Community Account Profile Viewed", data);
				}
			}
		}
	}, [viewContext, profileData, invited]);

	useEffect(() => {
		if (width >= 650) {
			setIsSmallWidth(false);
		} else {
			setIsSmallWidth(true);
		}
	}, [width]);

	const handleClosePlanModal = () => {
		setShowPlanModal(false);
	};

	const EditButton = () => (
		<Fab
			sx={{
				bgcolor: (theme) => theme.palette.primary.main,
				color: (theme) => theme.palette.secondary.white,
				"&:hover": {
					bgcolor: (theme) => theme.palette.primary.dark,
				},
			}}
			aria-label="Edit"
			color="secondary.white"
			onClick={() => {
				if (viewContext?.component === "startup") {
					dispatch(
						setUserProfileData({
							userProfileEditOpen: true,
						})
					);
				} else if (viewContext?.component === "account") {
					dispatch(
						setUserProfileData({
							userProfileEditOpen: true,
						})
					);
				}
			}}
		>
			<EditIcon />
		</Fab>
	);

	const handlePitchModal = () => {
		if (isPremiumPlanAccess || isAdmin) {
			dispatch(
				setFounderDashboardData({
					applyToPitchOpen: true,
				})
			);
		} else {
			setShowPlanModal(true);
		}
	};

	const overAllPercentage = () => {
		let totalCardsPercentage = 0;
		if (viewContext?.component === "startup") {
			totalCardsPercentage = bioPercentage + aboutPercentage + pitchDeckPercentage;
		} else {
			totalCardsPercentage = bioPercentage + aboutPercentage + educationPercentage + experiencePercentage;
		}
		const totalCardsCount = viewContext?.component === "startup" ? 3 : 4;
		const totalPercentage = Math.round(totalCardsPercentage / totalCardsCount);
		return totalPercentage;
	};

	const In_progress = () => {
		return (
			<>
				<Box
					display="flex"
					alignItems="center"
					sx={{ "&:hover": { cursor: "pointer" } }}
					onClick={() => {
						if (viewContext?.component === "startup") {
							if (!slidingProfileData?.open) {
								dispatch(
									setUserProfileData({
										userProfileEditOpen: true,
									})
								);
							}
						} else if (viewContext?.component === "account") {
							if (!slidingProfileData?.open) {
								dispatch(
									setUserProfileData({
										userProfileEditOpen: true,
									})
								);
							}
						}
					}}
				>
					<Typography variant="main_title" sx={{ fontWeight: 600, mr: 1, whiteSpace: "nowrap" }}>
						{`${overAllPercentage()}% complete`}
					</Typography>
					<ArrowForwardIcon />
				</Box>
			</>
		);
	};

	const CloseDrawer = () => (
		<Fab
			sx={{
				bgcolor: (theme) => theme.palette.secondary.black,
				color: (theme) => theme.palette.secondary.white,
				"&:hover": {
					bgcolor: (theme) => theme.palette.secondary.black,
				},
			}}
			aria-label="close"
			color="secondary.white"
			onClick={() => {
				dispatch(
					setSlidingProfileData({
						open: false,
						profileType: "",
						profileId: null,
						profileObj: null,
					})
				);
				// if (invited) {
				// 	window.location.reload();
				// }
			}}
		>
			<Box component={"img"} src={DRAWER_CLOSE_BLACK} alt="close-drawer" />
		</Fab>
	);

	return viewContext && viewContext?.component ? (
		<Box
			ref={observe}
			sx={{
				px: {
					lg: 8,
					md: 4,
					xs: 2,
				},
				pb: "30px",
				display: "flex",
				flexDirection: "column",
				gap: "32px",
				maxWidth: "1920px", // Figma specifies max content width
				...(!slidingProfileData?.open && { margin: "0 auto" }),
			}}
		>
			<Box
				display="flex"
				alignItems={size.width > 1000 ? "center" : "baseline"}
				justifyContent="space-between"
				flexDirection={size.width > 1000 ? "row" : "column"}
			>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						gap: "12px",
					}}
				>
					{viewContext?.component === "account" ? (
						<SvgComponent
							svgUrl={viewContext?.component === "account" ? USER_CIRCLE : ""}
							strokeColor={"black"}
							style={{ height: "30px", width: "30px" }}
						/>
					) : (
						<Box component={"img"} src={PAGE_STARTUP_PROFILE_ICON} alt="dashbaord" sx={{ width: "30px", height: "30px" }} />
					)}

					<Typography
						variant="Text/lg/Semibold"
						component={"div"}
						sx={{
							color: "black",
							whiteSpace: "nowrap",
						}}
					>
						{(() => {
							let type = viewContext?.component;
							let id = viewContext?.id;
							return (!id ? "My " : "") + (type === "startup" ? "Startup Profile" : type === "account" ? "Account Profile" : "");
						})()}
					</Typography>
					{/* <TourTooltip title="Click to restart the tour" placement="top-end">
						<Box sx={{ width: "50px", cursor: "pointer" }} component={"img"} src={HomeTourIcon} alt="tour" />
					</TourTooltip> */}

					{!viewContext?.id && viewContext?.component === "account" && size.width > 1000 && In_progress()}
				</Box>

				<Box
					display="flex"
					justifyContent="space-between"
					alignItems={size.width > 500 ? "center" : "baseline"}
					flexDirection={size.width > 500 ? "row" : "column"}
					width={size.width > 1000 ? "auto" : "100%"}
				>
					{!viewContext?.id && viewContext?.component === "account" && size.width < 1000 && In_progress()}
				</Box>

				<Box
					sx={{
						ml: "auto",
						position: "fixed",
						right: 20,
						display: "flex",
						gap: "8px",
					}}
				>
					{viewContext &&
						!loading &&
						(() => {
							if (viewContext?.component === "startup") {
								// Only render component if personal account.
								if (
									(location.pathname.includes(ROUTES_PATH.MY_STARTUP_PROFILE) && !slidingProfileData?.open && !viewContext?.id) ||
									viewContext?.id === userData?.companyId
								) {
									return <EditButton />;
								}
							} else if (viewContext?.component === "account") {
								// Only render component if personal account.
								if (
									(location.pathname.includes(ROUTES_PATH.MY_ACCOUNT_PROFILE) && !slidingProfileData?.open && !viewContext?.id) ||
									viewContext?.id == userData?.userId
								) {
									return <EditButton />;
								}
							}
						})()}
					{viewContext &&
						(() => {
							if (slidingProfileData?.open) {
								return <CloseDrawer />;
							}
						})()}
				</Box>
			</Box>
			{viewContext?.component === "account" && (
				<ColumnControl minWidth={1000} wrapperSX={{ style: { display: "flex", gap: "32px" }, afterStyle: { flexDirection: "column" } }}>
					<BioCard beforeStyle={{ width: "500px" }} setBioPercentage={setBioPercentage} invited={invited} setInvited={setInvited} />

					<AboutCard style={{ flex: 1 }} setAboutPercentage={setAboutPercentage} />
				</ColumnControl>
			)}
			{viewContext?.component === "startup" && (
				<Box display={"flex"} alignItems={"center"} gap={4}>
					<SocialMediaShareButtons
						sharePlatformsList={["FB", "TW", "EM"]}
						url={startupShareUrl}
						title={`Check out my company profile on StartupOS`}
						description={`Check out my profile on StartupOS`}
						windowWidth={300}
						windowHeight={300}
						emailSeparator="\n"
						email={profileData?.founderDetails?.email}
					/>
					{!loading && !viewContext?.id && (
						<Box display={"flex"} alignItems={"center"} gap={1}>
							<Typography variant="Text/sm/Semibold" sx={{ color: "rgba(102, 112, 133, 1)" }}>
								Show Profile to Investors
							</Typography>
							<Box
								sx={{
									cursor: "pointer",
								}}
							>
								<InvestorToggleSwitch
									checked={investerToggle}
									setIsInvestorView={(investorView) => {
										setIsInvestorView(investorView);
										const data = profileData;
										data["investorView"] = investorView;

										updateProfile(data);
									}}
								/>
							</Box>
						</Box>
					)}
				</Box>
			)}

			{viewContext?.component === "startup" && (
				<GridView colCount={colCount} startup={profileData} isOpportunityDashboard={false} isPublicProfile={viewContext?.id} />
			)}
			{viewContext?.component === "startup" && (
				<Fragment>
					<Box>
						<UpdatedPitchDeckSection />
					</Box>

					{(isAdmin || partnerCode !== "sos") && partnerQA?.length > 0 && (
						<Box>
							<PartnerQA />
						</Box>
					)}
					<Box>
						<TeamCard />
					</Box>
				</Fragment>
			)}

			{viewContext?.component === "account" && (
				<Fragment>
					<Box>
						<ExperienceCard setExperiencePercentage={setExperiencePercentage} />
					</Box>
					<Box>
						<EducationCard setEducationPercentage={setEducationPercentage} />
					</Box>
				</Fragment>
			)}

			{showPlanModal && <PaymentPlanDialog open={showPlanModal} onClose={handleClosePlanModal} />}
			<Journey />
			<UserProfileEditPopup disableBackDropClick={true} />
			<IndustryDetailsDialog />
		</Box>
	) : (
		<></>
	);
}

export default ProfileContent;
