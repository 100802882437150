import * as actionTypes from "./actionTypes";

export const setInvestorDashboardData = (payload) => {
	return (dispatch) => {
		dispatch({
			type: actionTypes.SET_INVESTOR_DASHBOARD_DATA,
			payload,
		});
	};
};
