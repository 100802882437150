/*
  This file is a 'bandaid' for components in the app incorrectly accessing 
  theme variables. 

  They 're-import' the theme object directly to reference values rather than
  properly access via MUI API.

  Ex Incorrect:
  import theme from "...."
  ...
  <Box sx={{color: theme.palette.primary.dark}}/>

  Ex Correct:
  ...
  <Box sx={{color: (theme) => theme.palette.primary.dark}}/>

  Using this file these components can still import the theme object. 
  However will need to be updated to discontinue the bad practice.
*/


import { createTheme } from "@mui/material/styles";
import { themeTypography } from "./typography";
import { themeButtons } from "./buttons";
import { themeLayout } from "./layout";
import { themeInputs } from "./inputs";
import { themeSelect } from "./select";
import { themeFlag } from "./flag";
import { themeShape } from "./shape";
import { themeAccordion } from "./accordion";
import { getThemePalette } from "./colors";

const themePalette = getThemePalette();
const theme = createTheme(themePalette, themeButtons(themePalette), themeTypography(themePalette), themeLayout(themePalette), themeInputs(themePalette), themeSelect(themePalette), themeFlag(themePalette), themeShape(themePalette), themeAccordion(themePalette));

export default theme;
