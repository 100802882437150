import React, { useContext, useEffect, useMemo, useState } from "react";
import { Typography, Box, Button, Grid, CircularProgress, Stack } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import UpdatedTextInputField from "../../../../common/UpdatedTextInputField";
import ReusableQuill from "../../../../common/ReusableQuill";
import services from "../../../../services";
import { toastContainer } from "../../../../utils/toast";
import EventImageUploader from "../EventImageUploader";
import messages from "../../../../constants/messages";
import { useHistory } from "react-router-dom";
import { AdminPowerpackContext } from "..";
import AddIcon from "@mui/icons-material/Add";
import DraggablePowerups from "../DraggablePowerups";
import { useLocation } from "react-router-dom";
import { BreadcrumbsComponent } from "../../../../components/Breadcrumbs";
import PowerupActivitiesModal from "../PowerupActivitiesModal";
import DeletePowerupPackModal from "../DeletePowerupPackModal";
import { CustomToggleSwitch } from "../../../PowerUP/compositions/DoIt/AreYouEnterprise/md-are-you-enterprise";
import ROUTES_PATH from "../../../../constants/routes";

export default function AddPowerPack() {
	const schema = yup.object({
		title: yup.string().required("Title is required"),
		shortDescription: yup.string().required("Description is required").nullable(),
	});
	const location = useLocation();
	const isEdit = location.pathname.includes(ROUTES_PATH.ADMIN_POWERPACK_EDIT);
	const { powerPackDetails, powerups, setPowerups } = useContext(AdminPowerpackContext);
	const [loading, setLoading] = useState(false);
	const [powerupActivities, setPowerupActivities] = useState([]);
	const [powerupActivitiesLoading, setPowerupActivitiesLoading] = useState(false);
	const [openPowerupActicitiesDialog, setOpenPowerupActicitiesDialog] = useState(false);
	const [openDeletePowerupPackDialog, setOpenDeletePowerupPackDialog] = useState(false);
	const [deletePowerupPackLoading, setDeletePowerupPackLoading] = useState(false);

	const [heroImage, setHeroImage] = useState("");
	const [cardImage, setCardImage] = useState("");

	const [heroImageData, setHeroImageData] = useState({});
	const [cardImageData, setCardImageData] = useState({});

	//name and size for heroImage
	const [heroImageName, setHeroImageName] = useState("Untitled Image");
	const [heroImageSize, setHeroImageSize] = useState(0);

	//name and size for cardImage
	const [cardImageName, setCardImageName] = useState("Untitled Image");
	const [cardImageSize, setCardImageSize] = useState(0);

	const [hide, setHide] = useState(false);
	const [entityStatus, setEntityStatus] = useState(isEdit ? powerPackDetails?.entityStatus : false);
	const [cardId, setCardId] = useState(null);

	const history = useHistory();

	const {
		handleSubmit,
		control,
		getValues,
		setValue,
		watch,
		reset,
		formState: { errors, isValid },
	} = useForm({
		mode: "all",
		defaultValues: {
			title: isEdit ? powerPackDetails?.title : "",
			shortDescription: isEdit ? powerPackDetails?.shortDescription : null,
		},
		resolver: yupResolver(schema),
	});

	const onClose = () => {
		history.push(ROUTES_PATH.ADMIN_POWERPACK_DASHBOARD);
		setPowerups([]);
	};
	const getNestedRoutes = useMemo(() => {
		let bedcrumDetails = [
			{ title: "PowerPack Management Dashboard", route: ROUTES_PATH.ADMIN_POWERPACK_DASHBOARD },
			{ title: isEdit ? "Edit PowerPack" : "Create New PowerPack", route: "" },
		];

		return { bedcrumDetails };
	}, [powerPackDetails]);
	useEffect(() => {
		if (openPowerupActicitiesDialog) {
			fetchPowerupActivities();
		}
	}, [openPowerupActicitiesDialog]);

	useEffect(() => {
		console.log(`poweruop pack detals`, powerPackDetails);
		!isEdit && setPowerups([]);

		setHeroImage(isEdit ? powerPackDetails?.thumbnailUrl : "");
		setCardImage(isEdit ? powerPackDetails?.imageUrl : "");
		setEntityStatus(isEdit ? powerPackDetails?.entityStatus === "ACTIVE" : false);

		setCardId(isEdit ? powerPackDetails?.cardId : null);
		reset({
			title: isEdit ? powerPackDetails?.title : "",
			shortDescription: isEdit ? powerPackDetails?.shortDescription : null,
		});

		if (isEdit) {
			const metaData = powerPackDetails?.metadata && JSON.parse(powerPackDetails?.metadata);
			if (metaData?.length > 0) {
				const heroImgData = metaData[0];
				const cardImgData = metaData[1];
				setHeroImageName(heroImgData?.name ? heroImgData?.name : "Untitled Image");
				setHeroImageSize(heroImgData?.size);
				setCardImageName(cardImgData?.name ? cardImgData?.name : "Untitled Image");
				setCardImageSize(cardImgData?.size);
				setHeroImageData(heroImgData);
				setCardImageData(cardImgData);
			} else {
				setHeroImageName("Untitled Image");
				setHeroImageSize(0);
				setCardImageName("Untitled Image");
				setCardImageSize(0);
			}
		}
	}, []);

	const handleChange = (event, key, onChange) => {
		if (key === "shortDescription") {
			onChange(event);
		} else {
			onChange(event.target.value);
		}
	};
	const handleClickOpen = () => {
		setOpenPowerupActicitiesDialog(true);
	};

	const handleClose = () => {
		setOpenPowerupActicitiesDialog(false);
	};

	const fetchPowerupActivities = async () => {
		try {
			setPowerupActivitiesLoading(true);
			const resData = await services.getActivityCardsAPI();

			if (resData) {
				const updatedPowerUps = resData?.data?.data?.map((item) => ({
					...item,
					selected: false,
				}));

				const updatedPowerUpsWithSelection = updatedPowerUps?.map((updatedPowerUp) => {
					const existingPowerUp = powerups?.find((powerUp) => powerUp.uuid === updatedPowerUp.uuid);
					if (existingPowerUp) {
						return {
							...updatedPowerUp,
							selected: true,
						};
					}

					return updatedPowerUp;
				});

				setPowerupActivities(updatedPowerUpsWithSelection);
				setPowerupActivitiesLoading(false);
			}
		} catch (error) {
			setPowerupActivitiesLoading(false);
			console.log(`Error fetching in Activity Cards`, error);
		}
	};

	const onSubmit = async () => {
		try {
			setLoading(true);

			const activityCards = powerups?.map((powerUp, index) => ({
				activityCardId: powerUp?.activityCardId ? powerUp?.activityCardId : powerUp?.id,
				orderNo: index + 1,
			}));

			const payload = {
				title: getValues("title"),
				description: getValues("shortDescription"),
				imageUrl: cardImage,
				thumbnailUrl: heroImage,
				entityStatus: entityStatus ? "ACTIVE" : "DEACTIVE",
				activityCards: activityCards,
				metadata: JSON.stringify([heroImageData, cardImageData]),
			};
			let resData = null;

			if (isEdit) {
				resData = await services.updatePowerUpPacks(payload, powerPackDetails?.uuid, cardId);
			} else {
				resData = await services.createPowerUpPacks(payload);
			}
			if (resData) {
				reset();
				setHeroImage("");
				setCardImage("");
				toastContainer(isEdit ? messages.POWERUP_PACK_UPDATED_SUCCESS : messages.POWERUP_PACK_CREATED_SUCCESS);
				setLoading(false);

				onClose();
			}
		} catch (err) {
			setLoading(false);
			// toastContainer(err?.response?.data?.message, "error");
		}
	};

	const handleDeletePowerupPack = async (powerUpPackUuid, cardId) => {
		try {
			setDeletePowerupPackLoading(true);
			const res = await services.deletePowerUpPack(powerUpPackUuid, cardId);
			if (res.status === 200) {
				setDeletePowerupPackLoading(false);
				reset();
				setHeroImage("");
				setCardImage("");
				toastContainer(messages.POWERUP_PACK_DELETED_SUCCESS);
				handleCloseConfirmDialog();
				onClose();
			} else {
				console.log(`Error while deleting powerup pack`);
				onClose();
				setDeletePowerupPackLoading(false);
			}
		} catch (error) {
			console.log(`Error deleting powerup pack`, error);
			setDeletePowerupPackLoading(false);
		}
	};

	const handleOpenConfirmDialog = () => {
		setOpenDeletePowerupPackDialog(true);
	};

	const handleCloseConfirmDialog = () => {
		setOpenDeletePowerupPackDialog(false);
	};

	const handleConfirmDelete = () => {
		handleDeletePowerupPack(powerPackDetails?.uuid, cardId);
	};

	const handlePowerupPackStatus = () => {
		setEntityStatus((prev) => !prev);
	};
	return (
		<Box
			sx={{
				px: {
					lg: 8,
					md: 4,
					xs: 2,
				},
				pb: "30px",
				display: "flex",
				flexDirection: "column",
				gap: "32px",
				maxWidth: "1920px",
				margin: "0 auto",
			}}
		>
			<BreadcrumbsComponent bedcrumDetails={getNestedRoutes?.bedcrumDetails} />

			<Box sx={{ backgroundColor: "#FFFFFF", borderRadius: "24px", p: 3 }} mt={3}>
				{" "}
				<Grid container rowSpacing={2} columnSpacing={7}>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Stack>
							{" "}
							<Typography variant="Text/md/Semibold">{isEdit ? "Edit PowerPack" : "New PowerPack"}</Typography>
							<Typography variant="Text/sm/Regular">
								{isEdit ? "Edit your PowerPack by revising the details below!" : "Create a new PowerPack by filling out the details below!"}
							</Typography>
						</Stack>
					</Grid>
					<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
						<EventImageUploader
							title={"Hero Image"}
							instructions={"Your Profile photo will be displayed is a circular view."}
							shape={"rectangle"}
							uploadedImage={heroImage}
							setUploadedImage={(item) => setHeroImage(item)}
							circularCropper={false}
							aspectRatio={5 / 2}
							fileType={{ "image/png": [], "image/jpg": [], "image/jpeg": [] }}
							readOnly={false}
							sx={{ border: "1px solid #EAECF0", borderRadius: "12px", width: "100%" }}
							imageSizeInstruction={"PNG, JPG or JPEG (max. 800x400px)"}
							setImageData={setHeroImageData}
							imgName={heroImageName}
							imgSize={heroImageSize}
							setImgName={setHeroImageName}
							setImgSize={setHeroImageSize}
							width={"100%"}
						/>
					</Grid>
					<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
						{" "}
						<EventImageUploader
							title={"Card Image"}
							instructions={"Your Profile photo will be displayed is a circular view."}
							shape={"rectangle"}
							uploadedImage={cardImage}
							setUploadedImage={(item) => setCardImage(item)}
							circularCropper={false}
							aspectRatio={5 / 3}
							fileType={{ "image/png": [], "image/jpg": [], "image/jpeg": [] }}
							readOnly={false}
							sx={{ border: "1px solid #EAECF0", width: 1 }}
							imageSizeInstruction={"PNG, JPG or JPEG (max. 338x216px)"}
							setImageData={setCardImageData}
							imgName={cardImageName}
							imgSize={cardImageSize}
							setImgName={setCardImageName}
							setImgSize={setCardImageSize}
							width={"100%"}
						/>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Typography variant="Text/sm/Semibold" color={"#667085"}>
							{"Title*"}
						</Typography>
						<Box sx={{ mt: 1 }}>
							<Controller
								name="title"
								control={control}
								render={({ field: { onChange, value, name } }) => (
									<UpdatedTextInputField
										control={control}
										id={"title"}
										value={value}
										inputProps={{ maxLength: 255 }}
										name={name}
										sx={{
											"& .MuiOutlinedInput-notchedOutline": {
												border: errors.name ? "1px solid #F04438 !important" : "1px solid #DDDDDD",
											},
										}}
										handleChange={onChange}
										placeholder={"Type here"}
										textFieldProps={{
											fullwidth: true,

											width: "100%",
											height: "60px",
										}}
									/>
								)}
							/>
							{errors.title && (
								<Typography variant="subhead2" sx={{ color: "#F04438" }}>
									{errors.title.message}
								</Typography>
							)}
						</Box>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Typography variant="Text/sm/Semibold" color={"#667085"}>
							{"Description*"}
						</Typography>
						<Box sx={{ mt: 1 }}>
							<Controller
								name="shortDescription"
								control={control}
								render={({ field: { onChange, value, name } }) => (
									<Box sx={{ backgroundColor: "white", borderRadius: "30px", p: 2, border: "1px solid #D0D5DD" }}>
										<ReusableQuill
											id={"event-description"}
											value={value}
											placeholder={"Type here"}
											onChange={(e) => handleChange(e, "shortDescription", onChange)}
											className={"profile-container"}
										/>
									</Box>
								)}
							/>
							{errors.shortDescription && (
								<Typography variant="subhead2" sx={{ color: "#F04438" }}>
									{errors.shortDescription.message}
								</Typography>
							)}
						</Box>
					</Grid>

					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Box display={"flex"} flexDirection={"column"}>
							<Typography variant="Text/sm/Semibold" color={"#667085"}>
								{"Select PowerUps*"}
							</Typography>
							<Typography variant="Text/xs/Regular" color={"#475467"}>
								{
									"Select which PowerUps you would like to include in your PowerPack. You can specify the order in which the PowerUps are shown in the PowerPack by dragging with icon on the left-hand side on each individual PowerUp."
								}
							</Typography>
						</Box>
						<Box sx={{ mt: 2 }}>
							<Box sx={{ backgroundColor: "white", borderRadius: "30px", p: 2, border: "1px solid #D0D5DD" }}>
								<Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
									<Typography variant="Text/sm/Semibold" color={"#667085"}>
										Included PowerUps
									</Typography>
									<Box>
										<Button startIcon={<AddIcon size={"1.25rem"} sx={{ color: "white" }} />} variant="DS1" onClick={handleClickOpen}>
											{"Add PowerUp"}
										</Button>
									</Box>
								</Box>

								<Box
									sx={{
										mt: 2,
										backgroundColor: "white",
										borderRadius: "30px",
										p: powerups?.length > 0 ? 0 : 2,
										border: powerups?.length > 0 ? "none" : "1px solid #D0D5DD",
										minHeight: "180px",
									}}
								>
									{powerups?.length > 0 ? (
										<DraggablePowerups powerups={powerups} setPowerups={setPowerups} />
									) : (
										<Box display={"flex"} justifyContent={"center"} mt={9}>
											<Typography variant="Text/xs/Regular" color={"#475467"}>
												{"No PowerUps Added Yet!"}
											</Typography>
										</Box>
									)}
								</Box>
							</Box>
						</Box>
					</Grid>

					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Box sx={{ mt: 1 }} gap={1}>
							<Typography variant="Text/sm/Semibold" color={"#667085"} mr={2}>
								{"Publish Powerup Pack"}
							</Typography>{" "}
							<CustomToggleSwitch checked={entityStatus} onChange={handlePowerupPackStatus} />
						</Box>
					</Grid>

					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
							<Box>
								{isEdit && (
									<Button
										sx={{
											backgroundColor: (theme) => theme.palette.error.main,
											maxWidth: "fit-content",
											color: "white",
											"&:hover": { backgroundColor: (theme) => theme.palette.error.main },
										}}
										variant="DS1"
										onClick={handleOpenConfirmDialog}
									>
										Delete
									</Button>
								)}
							</Box>

							<Box display={"flex"} alignItems={"center"} justifyContent={"flex-end"}>
								<Box>
									<Button onClick={onClose} sx={{ color: (theme) => theme.palette.secondary.light }} variant="noOutlineNew">
										Cancel
									</Button>
								</Box>
								<Box>
									<Button
										startIcon={loading && <CircularProgress size={"1.25rem"} sx={{ color: "inherit" }} />}
										variant="DS1"
										type="submit"
										onClick={onSubmit}
										disabled={!isValid || !heroImage || powerups?.length === 0}
									>
										{isEdit ? "Save" : "Create"}
									</Button>
								</Box>
							</Box>
						</Box>
					</Grid>
				</Grid>
			</Box>
			<PowerupActivitiesModal
				openPowerUpPackDialog={openPowerupActicitiesDialog}
				handleClose={handleClose}
				loading={loading}
				powerUps={powerupActivities}
				setPowerUps={setPowerupActivities}
				powerUpLoading={powerupActivitiesLoading}
				setSelectedPowerups={setPowerups}
				selectedPowerups={powerups}
				isEdit={isEdit}
			/>
			<DeletePowerupPackModal
				open={openDeletePowerupPackDialog}
				handleClose={handleCloseConfirmDialog}
				handleConfirm={handleConfirmDelete}
				deletePowerupPackLoading={deletePowerupPackLoading}
			/>
		</Box>
	);
}
