import React, { useState } from "react";
import { Box, Button, Typography, Divider, Grid, Stack, CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import theme from "../../../constants/Theme";
import UpdatedDialogConfirmation from "../../../common/UpdatedDialogConfirmation";
import { setCommonDialogConfirmationData } from "../../../modules/actions";
import services from "../../../services";
import { toastContainer } from "../../../utils/toast";
import { ALERT_ICON_V1 } from "../../../constants";
import { DeleteUserProfileDataConfirmationDialog } from "./DeleteProfileDataConfirmation";
import { logout } from "../../../utils/auth0-spa";
import { authWebLogout } from "../../../utils/auth0";
import { useThemeContext } from "../../../constants/Theme/ThemeContext";

export default function AccoutDetails({ data, handleShowEmail, handleShowPassword }) {
	const dispatch = useDispatch();
	const { isSocialLogin, userName } = useSelector((state) => state.auth);
	const [userPofileDataLoading, setUserPofileDataLoading] = useState(false);
	const [openDeleteUserProfileDataConfirmationDialog, setOpenDeleteUserProfileDataConfirmationDialog] = useState(false);
	const { clearThemeObjects } = useThemeContext();

	const handleCloseConfirmation = () => {
		dispatch(
			setCommonDialogConfirmationData({
				isOpen: false,
				text: "",
			})
		);
	};
	const handleDeactiveAccountApiCall = () => {
		services
			.deactivateAccountApi()
			.then((response) => {
				toastContainer("Account deactivation request sent", "success");

				handleCloseConfirmation();
			})
			.catch((e) => {
				console.log("e", e);
			});
	};

	const handleDeactiveAccount = () => {
		dispatch(
			setCommonDialogConfirmationData({
				isOpen: true,
				title: "Deactivate Account",
				subTitle: `Are you sure you want to deactivate your account for ${data?.email} ?`,
				text: "Note: This request can take up to 24 hours. Please reach out to support@startupos.com if you need further support.",
				primaryButtonText: "Yes",
				secondaryButtonText: "Cancel",
				primaryButtonAction: () => handleDeactiveAccountApiCall(),
				secondaryButtonAction: () => handleCloseConfirmation(),
				primaryButtonVariant: "PurpleRounded",
				secondaryButtonVariant: "noOutlineNew",
				primaryButtonLoading: false,
				titleIcon: ALERT_ICON_V1,
			})
		);
	};

	const downloadUserProfileDataApiCall = () => {
		setUserPofileDataLoading(true);

		services
			.downloadUserProfileDataAPi()
			.then((response) => {
				const blob = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
				const fileName = `${userName ? userName : "profile"}.xlsx`;

				const url = URL.createObjectURL(blob);
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("download", fileName);

				document.body.appendChild(link);

				link.click();

				link.parentNode.removeChild(link);
				URL.revokeObjectURL(url);

				setUserPofileDataLoading(false);
			})
			.catch((error) => {
				setUserPofileDataLoading(false);
				console.log(error);
			});
	};

	const onClose = () => {
		setOpenDeleteUserProfileDataConfirmationDialog(false);
	};
	const handleLogout = async () => {
		await logout();
		await authWebLogout();
		try {
			clearThemeObjects();
		} catch (e) {
			console.log("Failed to clear partner theme localstorage values.", e);
		}
	};
	return (
		<Box sx={{ my: 1, mt: 1 }}>
			<Grid container rowSpacing={2}>
				<Grid
					item
					xs={12}
					md={12}
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
						alignItems: "center",
						flexWrap: "wrap",
						rowGap: 1,
					}}
				>
					<Stack spacing={1}>
						<Typography variant="Text/sm/Semibold">Email</Typography>
						<Typography variant="Text/sm/Regular">{data?.email ? data?.email : "Email address"}</Typography>
					</Stack>
					{!isSocialLogin && (
						<Box alignSelf={"flex-start"}>
							<Button variant="DS1" color="gray" sx={{ width: "max-content", padding: "8px 12px" }} onClick={handleShowEmail}>
								Change email
							</Button>
						</Box>
					)}
				</Grid>

				{!isSocialLogin && (
					<Grid
						item
						xs={12}
						md={12}
						sx={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "space-between",
							alignItems: "center",
							overflow: "hidden",
							flexWrap: "wrap",
							rowGap: 1,
						}}
					>
						<Typography variant="Text/sm/Semibold">Password</Typography>
						<Box>
							<Button variant="DS1" color="gray" sx={{ width: "max-content", padding: "8px 12px" }} onClick={handleShowPassword}>
								Change password
							</Button>
						</Box>
					</Grid>
				)}
				<Grid
					item
					xs={12}
					md={12}
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
						alignItems: "center",
						overflow: "hidden",
						flexWrap: "wrap",
						rowGap: 1,
					}}
				>
					<Typography variant="Text/sm/Semibold">Account Status</Typography>
					<Box>
						<Button
							variant="DS1"
							color="gray"
							sx={{ width: "max-content", padding: "8px 12px" }}
							onClick={() => downloadUserProfileDataApiCall()}
							startIcon={userPofileDataLoading && <CircularProgress size={"1.25rem"} sx={{ color: "inherit" }} />}
						>
							Download My Data
						</Button>
					</Box>
				</Grid>
				<Grid
					item
					xs={12}
					md={12}
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
						alignItems: "center",
						overflow: "hidden",
						flexWrap: "wrap",
						rowGap: 1,
					}}
				>
					<Typography variant="Text/sm/Semibold">Deactivate Account</Typography>
					<Box>
						<Button variant="DS1" color="gray" sx={{ width: "max-content", padding: "8px 12px" }} onClick={handleDeactiveAccount}>
							Deactivate My Account
						</Button>
					</Box>
				</Grid>
				<Grid
					item
					xs={12}
					md={12}
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
						alignItems: "center",
						overflow: "hidden",
						flexWrap: "wrap",
						rowGap: 1,
					}}
				>
					<Typography variant="Text/sm/Semibold">Delete Account</Typography>
					<Box>
						<Button
							variant="DS1"
							color="gray"
							sx={{ width: "max-content", padding: "8px 12px" }}
							onClick={() => {
								setOpenDeleteUserProfileDataConfirmationDialog(true);
							}}
						>
							Delete My Account
						</Button>
					</Box>
				</Grid>
			</Grid>{" "}
			<UpdatedDialogConfirmation />
			<DeleteUserProfileDataConfirmationDialog open={openDeleteUserProfileDataConfirmationDialog} handleClose={onClose} handleLogout={handleLogout} />
		</Box>
	);
}
