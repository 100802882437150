import React, { useState, useEffect, useMemo, useRef } from "react";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import VideoPlayerComponent from "../../../../common/videoPlayer";
import FoundingTeamVideoTabController from "../../../../containers/ScoutingReportView/FoundingTeam/FoundingTeamVideos/FoundingTeamVideoTabController";
import { percentageDependOnFilledFields } from "../../../../containers/Profile/StartupProfile/Functions/common";

function StartupAboutStoryDetails() {
	const [videoData, setVideoData] = useState(null);
	const [value, setValue] = useState(0);
	const STARTUP = useSelector((state) => state?.startupProfile?.startupCardObj);
	const openStartupProfileObj = useSelector((state) => state?.startupProfile?.openStartupProfileObj);
	const params = useParams();
	const companyIdParams = params.id;
	const [completionPercentage, setCompletionPercentage] = useState(companyIdParams ? undefined : 0);
	const tabContainerRef = useRef();

	const fetchStartup = () => {
		setVideoData([
			{
				title: openStartupProfileObj?.about,
				videoURL: openStartupProfileObj?.aboutUsVideoUrl,
			},
			{
				title: openStartupProfileObj?.founderStory,
				videoURL: openStartupProfileObj.founderStoryVideo,
			},
		]);
		if (!companyIdParams) {
			const tempPercentage = percentageDependOnFilledFields([
				openStartupProfileObj?.aboutUsVideoUrl,
				openStartupProfileObj.founderStoryVideo,
				openStartupProfileObj?.about,
				openStartupProfileObj?.founderStory,
			]);
			setCompletionPercentage(tempPercentage);
		}
	};

	useEffect(() => {
		if (openStartupProfileObj) {
			fetchStartup();
		}
	}, [openStartupProfileObj]);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const loadVideo = useMemo(() => {
		if (videoData?.length > 0) {
			if (videoData[value]?.videoURL) {
				return (
					<Box
						sx={{
							flex: 1,
							maxHeight: { md: tabContainerRef?.current?.clientHeight || "450px", xs: "640px" }, // Figma value.
							"& video": {
								borderRadius: "0px 30px 30px 0px",
								objectFit: "cover",
							},
						}}
					>
						<VideoPlayerComponent videoUrl={videoData[value].videoURL} height="100%" />
					</Box>
				);
			}
		}
		return null;
	}, [value, videoData]);

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: {
					md: "row",
					xs: "column",
				},
				alignItems: "center",
				py: {
					lg: 5,
					md: 3,
					xs: 2,
				},
			}}
		>
			<Box
				sx={{
					display: "flex",
					flex: 1,
					width: { md: "48%", xs: "100%" },
					position: { md: "absolute", xs: "static" },
				}}
			>
				{loadVideo}
			</Box>
			<Box
				sx={{
					display: "flex",
					width: { md: "60%", xs: "100%" },
					flexDirection: "column",
					ml: "auto",
					bgcolor: (theme) => theme.palette.secondary.white,
					borderRadius: "30px 0px 0px 30px",
					boxShadow: "0px 10px 40px rgba(0, 0, 0, 0.05)",
				}}
				ref={tabContainerRef}
			>
				<Box sx={{ pl: { md: "15%" }, py: { md: 6 }, flex: 1, display: "flex", flexDirection: "column" }}>
					<Box sx={{ px: 6, py: 3, flex: 1 }}>
						<FoundingTeamVideoTabController
							STARTUP={STARTUP}
							value={value}
							handleTabChange={handleChange}
							completionPercentage={completionPercentage}
						/>
					</Box>
				</Box>
			</Box>
		</Box>
	);
}

export default StartupAboutStoryDetails;
