import { Box, IconButton, useMediaQuery } from "@mui/material";
import React from "react";
import { FacebookShareButton, EmailShareButton, TwitterShareButton } from "react-share";
import { FacebookIcon, EmailIcon, TwitterIcon } from "react-share";
import { EMAIL_ICON_V2, FB_ICON_V2, TWITTER_ICON_V2, X_LOGO } from "../../constants";
import { useWindowSize } from "../../utils/windowResize";

const SocialMediaShareButtons = ({
	url = "",
	title = "",
	description = "",
	hashtag = "#StartupOS",
	sharePlatformsList = [],
	windowWidth = 300,
	windowHeight = 300,
	emailSeparator = "",
	disabledList = [],
	disabledStyle = {},
	linkedinOrTwitterSource = "StartupOS",
	email = "",
}) => {
	const size = useWindowSize();
	const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
	const responsiveWidth = isSmallScreen ? 450 : size.width * 0.3;

	const ShareViaEmail = () => {
		return (
			<Box>
				<EmailShareButton
					windowHeight={windowHeight}
					windowWidth={windowWidth}
					url={url}
					// body={description}
					subject={title}
					separator={emailSeparator}
					// openShareDialogOnClick={true}
					onClick={(e) => {
						e.preventDefault();
						const mailtoLink = `mailto:${encodeURIComponent(email)}?subject=${encodeURIComponent(title)}&body=${encodeURIComponent(url)}`;
						const windowWidth = responsiveWidth; // Width of the email window
						const windowHeight = 550; // Height of the email window
						const screenWidth = window.screen.width;
						const screenHeight = window.screen.height;
						const left = (screenWidth - windowWidth) / 2;
						const top = (screenHeight - windowHeight) / 2;
						// Open a new window with the mailtoLink in the center
						const emailWindow = window.open(mailtoLink, "_blank", `width=${windowWidth},height=${windowHeight},left=${left},top=${top}`);
						emailWindow.focus();
					}}
				>
					{/* <EmailIcon
						style={{
							width: "34px",
							height: "34px",
						}}
						round
					/> */}
					<Box
						component={"img"}
						src={EMAIL_ICON_V2}
						alt="email"
						sx={{
							width: "24px",
							height: "24px",
						}}
					/>
					{/* <IconButton aria-label="email">
						<Box
							component={"img"}
							src={EMAIL_ICON_V2}
							alt="email"
							sx={{
								width: "24px",
								height: "24px",
							}}
						/>
					</IconButton> */}
				</EmailShareButton>
			</Box>
		);
	};
	const ShareViaFaceBook = () => {
		return (
			<Box>
				<FacebookShareButton windowHeight={windowHeight} windowWidth={windowWidth} url={url} quote={description} hashtag={hashtag}>
					{/* <FacebookIcon
						style={{
							width: "34px",
							height: "34px",
						}}
						round
					/> */}{" "}
					<Box
						component={"img"}
						src={FB_ICON_V2}
						alt="fb"
						sx={{
							width: "24px",
							height: "24px",
						}}
					/>
					{/* <IconButton aria-label="fb">
						<Box
							component={"img"}
							src={FB_ICON_V2}
							alt="fb"
							sx={{
								width: "24px",
								height: "24px",
							}}
						/>
					</IconButton> */}
				</FacebookShareButton>
			</Box>
		);
	};

	const ShareViaTwitter = () => {
		return (
			<Box>
				<TwitterShareButton windowHeight={windowHeight} windowWidth={windowWidth} url={url} title={title} hashtag={hashtag}>
					{/* <TwitterIcon
						style={{
							width: "34px",
							height: "34px",
						}}
						round
					/> */}
					<Box
						component={"img"}
						src={TWITTER_ICON_V2}
						alt="x"
						sx={{
							width: "24px",
							height: "24px",
						}}
					/>
					{/* <IconButton aria-label="x">
						<Box
							component={"img"}
							src={TWITTER_ICON_V2}
							alt="x"
							sx={{
								width: "24px",
								height: "24px",
							}}
						/>
					</IconButton> */}
				</TwitterShareButton>
			</Box>
		);
	};

	const sharePlatformsOptions = {
		EM: <ShareViaEmail />,
		FB: <ShareViaFaceBook />,
		TW: <ShareViaTwitter />,
	};

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "row",
				alignItems: "center",
				gap: "12px",
			}}
		>
			{sharePlatformsList &&
				sharePlatformsList?.length > 0 &&
				sharePlatformsList?.map((element) => {
					return sharePlatformsOptions[element];
				})}
		</Box>
	);
};

export default SocialMediaShareButtons;
