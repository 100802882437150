import React from "react";
import { Box, Typography } from "@mui/material";

function MeetingLengthOption({ setSelected, title, description, index }) {
	return (
		<Box
			onClick={() => {
				setSelected();
			}}
			p={3}
			display="flex"
			flexDirection="column"
			rowGap={1}
			sx={{ boxShadow: "0px 10px 25px rgba(0, 0, 0, 0.05)", borderRadius: "24px", cursor: "pointer" }}
		>
			<Box
				sx={{
					width: "30px",
					height: "30px",
					borderRadius: "50%",
					bgcolor: (theme) => (index % 2 === 0 ? theme.palette.primary.main : theme.palette.primary.primaryPink),
				}}
			/>
			<Typography variant="Text/md/Semibold">{title}</Typography>
			<Typography variant="Text/sm/Regular">{description}</Typography>
		</Box>
	);
}

export default MeetingLengthOption;
