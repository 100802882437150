import React, { useState, useEffect } from "react";
import { Button, Typography, Box, CircularProgress, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Dropzone from "react-dropzone";
import services from "../../services";
import VideoPlayerComponent from "../videoPlayer";
import { Cropper } from "react-cropper";
import { toastContainer } from "../../utils/toast";
import { UPLOAD_V1_ICON } from "../../constants/image";
import { useDispatch, useSelector } from "react-redux";
import { setFeedData } from "../../modules/actions";
import CommonSlideDialog from "../CommonSlideDialog";
import FileCardSectionComponent from "../FileCardSection";

const DragDropFileUploader = ({
	postFileLink,
	setPostFileLink,
	aspectRatio,
	fileGroup,
	fileType,
	documentName,
	setDocumentName,
	documentSize,
	setDocumentSize,
	uploadingFileType,
	setUploadingFileType,
}) => {
	const openCropper = useSelector((state) => state?.feedPost?.openCropper);
	const dispatch = useDispatch();
	const [localFile, setLocalFile] = useState(false);
	const [loading, setLoading] = useState(false);
	const [cropper, setCropper] = useState();
	const [cropData, setCropData] = useState(false);
	const [cropperLoader, setCropperLoader] = useState(false);

	useEffect(() => {
		if (!postFileLink) {
			setCropData("");
			setDocumentName("");
			setDocumentSize("");
		}
	}, [postFileLink]);

	const handleFileSelect = (file) => {
		if (file) {
			if (file.type == "image/png" || file.type == "image/jpeg" || file.type == "image/jpg") {
				setUploadingFileType("image");

				const fileSize = (file?.size / (1024 * 1024 * 1024)).toFixed(2);

				if (fileSize < 2) {
					const reader = new FileReader();
					reader.addEventListener("load", () => setLocalFile(reader.result?.toString() || ""));
					reader.readAsDataURL(file);
					dispatch(setFeedData({ openCropper: true }));
				} else {
					toastContainer("Please Upload file less than 2GB", "error");
				}
			} else if (
				file.type == "video/3gpp" ||
				file.type == "video/mp4" ||
				file.type == "video/mov" ||
				file.type == "video/webm" ||
				file.type == "video/ogg"
			) {
				setUploadingFileType("video");
				setLoading(true);
				const fileSize = (file?.size / (1024 * 1024 * 1024)).toFixed(2);

				if (fileSize < 2) {
					const reader = new FileReader();
					reader.addEventListener("load", () => setLocalFile(reader.result?.toString() || ""));
					reader.readAsDataURL(file);
					setCropData(file);
					getFiles(file);
				} else {
					toastContainer("Please Upload file less than 2GB", "error");
				}
			} else {
				setUploadingFileType("document");
				setLoading(true);
				const fileSize = (file?.size / 1024 / 1024).toFixed(2);

				if (fileSize < 500) {
					setDocumentSize(fileSize);
					setDocumentName(file.name);
					const reader = new FileReader();
					reader.addEventListener("load", () => setLocalFile(reader.result?.toString() || ""));
					reader.readAsDataURL(file);
					setCropData(file);
					getFiles(file);
				} else {
					toastContainer("Please Upload file less than 500MB", "error");
				}
			}
		}
	};

	const convertURIToImageData = (dataurl) => {
		let arr = dataurl.split(","),
			mime = arr[0].match(/:(.*?);/)[1],
			bstr = atob(arr[1]),
			n = bstr.length,
			u8arr = new Uint8Array(n);
		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}
		let filename = Date.now() + "." + mime.split("/")[1];
		return new File([u8arr], filename, {
			type: mime,
		});
	};

	const getCropData = () => {
		setCropperLoader(true);

		if (typeof cropper !== "undefined") {
			var fileUrl = convertURIToImageData(cropper.getCroppedCanvas().toDataURL(), "");
			setCropData(cropper.getCroppedCanvas().toDataURL());
			getFiles(fileUrl);
		}
	};

	const getFiles = async (data) => {
		var formData = new FormData();
		formData.append("file", data);
		formData.append("fileGroup", fileGroup);
		services
			.uploadFile(formData)
			.then((response) => {
				setLoading(false);
				setCropperLoader(false);
				setPostFileLink(response.data.data?.fileLocation);
				setDocumentSize(response?.data?.data?.size);
				setDocumentName(response?.data?.data?.fileName);

				dispatch(setFeedData({ openCropper: false }));
			})
			.catch((e) => {
				setCropperLoader(false);
				dispatch(setFeedData({ openCropper: false }));
			});
	};

	const handleRemove = () => {
		setCropData("");
		setDocumentName("");
		setDocumentSize("");
		setPostFileLink("");
	};

	return (
		<Box
			sx={{
				height: "130px",
				position: "relative",
			}}
		>
			{postFileLink ? (
				<>
					{uploadingFileType === "image" ? (
						<Box
							sx={{
								position: "relative",
							}}
						>
							<Box
								component={"img"}
								src={postFileLink}
								alt="postFile"
								sx={(theme) => ({
									width: 1,
									objectFit: "cover",
									borderRadius: theme.shape.standard26.borderRadius,
								})}
							/>
							<Box
								sx={{
									mr: 1,
									position: "absolute",
									top: "8px",
									right: 0,
								}}
							>
								<IconButton
									aria-label="close"
									onClick={handleRemove}
									sx={{
										background: "white",
										"&:hover": {
											background: "white",
										},
									}}
								>
									<CloseIcon sx={{ color: (theme) => theme.palette.secondary.black }} />
								</IconButton>
							</Box>
						</Box>
					) : uploadingFileType === "video" ? (
						<Box
							sx={{
								position: "relative",
							}}
						>
							<VideoPlayerComponent height={"300px"} videoUrl={postFileLink} />
							<Box
								sx={{
									mr: 1,
									position: "absolute",
									top: "8px",
									right: 0,
								}}
							>
								<IconButton
									aria-label="close"
									onClick={handleRemove}
									sx={{
										background: "white",
										"&:hover": {
											background: "white",
										},
									}}
								>
									<CloseIcon sx={{ color: (theme) => theme.palette.secondary.black }} />
								</IconButton>
							</Box>
						</Box>
					) : (
						<Box sx={{ mt: 2 }}>
							<FileCardSectionComponent
								type={"upload"}
								fileLink={postFileLink}
								fileName={documentName}
								fileSize={documentSize}
								removeFile={handleRemove}
							/>
						</Box>
					)}
				</>
			) : (
				<Dropzone onDrop={!cropData ? (acceptedFiles) => handleFileSelect(acceptedFiles[0]) : () => {}} accept={fileType}>
					{({ getRootProps, getInputProps }) => (
						<section>
							<Box {...getRootProps()}>
								<input {...getInputProps()} />
								<Box
									sx={(theme) => ({
										border: "1px solid #D0D5DD",
										borderRadius: "8px",
										textAlign: "center",
										p: 3,
										cursor: "pointer",
										"&:hover": {
											border: "1px solid black",
										},
										[theme.breakpoints.down("sm")]: {
											p: 1,
										},
									})}
								>
									<Box component={"img"} src={UPLOAD_V1_ICON} alt="img"></Box>
									<Box
										sx={(theme) => ({
											display: "flex",
											gap: 1,
											alignItems: "center",
											justifyContent: "center",
											[theme.breakpoints.down("sm")]: {
												flexDirection: "column",
												gap: 0,
											},
										})}
									>
										<Typography
											variant="countdown_time_text"
											sx={{
												mt: 1,
												display: "grid",
												color: (theme) => theme.palette.primary.primaryPurple,
											}}
										>
											{" "}
											Click To Upload
										</Typography>
										<Typography
											variant="bodyF16"
											sx={{
												mt: 1,
												display: "grid",
												color: (theme) => theme.palette.secondary.outline,
											}}
										>
											{" "}
											or drag and drop it here
										</Typography>
									</Box>
									<Typography
										variant="body_small"
										sx={{
											mt: 1,
											display: "grid",
											color: (theme) => theme.palette.secondary.outline,
										}}
									>
										Image, Video, or Document
									</Typography>
								</Box>
							</Box>
						</section>
					)}
				</Dropzone>
			)}
			<CommonSlideDialog
				disableBackDropClick={true}
				title={"Crop Image"}
				titleVariant={"title_large"}
				isOpen={openCropper}
				closeButton={!cropperLoader}
				onClose={() => {
					dispatch(setFeedData({ openCropper: false }));
				}}
				contentStyle={{ px: 0 }}
				maxWidth={cropperLoader ? "sm" : "md"}
				fullWidth
				footerStyle={{
					border: cropperLoader && "none",
				}}
				PaperProps={{
					sx: (theme) => ({
						borderRadius: theme.shape.standard13.borderRadius,
						boxShadow: "0px 5px 16px rgba(0, 0, 0, 0.3)",
						p: 3,
					}),
				}}
				dialogAction={
					<>
						{!cropperLoader && (
							<Box sx={{ display: "flex", gap: 1, m: 2 }}>
								<Box>
									<Button
										variant="DS1"
										color="clear"
										onClick={() => {
											dispatch(setFeedData({ openCropper: false }));
										}}
										disabled={cropperLoader}
									>
										Cancel
									</Button>
								</Box>
								<Button variant="DS1" onClick={() => getCropData()}>
									Crop
								</Button>
							</Box>
						)}
					</>
				}
				cropperLoader={cropperLoader}
			>
				{cropperLoader ? (
					<Box justifyContent={"center"} sx={{ display: "flex", mt: 7, minHeight: "150px" }}>
						<Box>
							<Box
								sx={(theme) => ({
									display: "flex",
									justifyContent: "center",
								})}
							>
								<CircularProgress
									sx={(theme) => ({
										height: "80px",
										width: "80px",
										color: theme.palette.text.darkBlue,
									})}
								/>
							</Box>
							<Box
								sx={(theme) => ({
									textAlign: "center",
									pt: 5,
								})}
							>
								<Typography variant="title_medium">File upload in progress</Typography>
							</Box>
						</Box>
					</Box>
				) : (
					<>
						<Box sx={{ px: 3, pt: 2 }}>
							<Typography variant="title_bold">Crop Image</Typography>
						</Box>
						<Box
							className={"normal-cropper"}
							sx={{
								minHeight: "130px",
								borderRadius: "4px",
								width: 1,
								mt: 1,
								textAlign: "center",
								p: 5,
								cursor: "pointer",
							}}
						>
							<Cropper
								style={{ height: 400, width: "820px" }}
								// aspectRatio={aspectRatio}
								preview=".img-preview"
								src={localFile}
								viewMode={2}
								movable={false}
								background={false}
								responsive={true}
								autoCropArea={0}
								checkOrientation={false}
								onInitialized={(instance) => {
									setCropper(instance);
								}}
								guides={false}
							/>
						</Box>
					</>
				)}
			</CommonSlideDialog>
			<CommonSlideDialog
				disableBackDropClick={true}
				title={""}
				titleVariant={"title_large"}
				isOpen={loading}
				closeButton={!loading}
				onClose={() => {}}
				contentStyle={{ px: 0 }}
				maxWidth={"sm"}
				fullWidth
				footerStyle={{
					border: loading && "none",
				}}
				PaperProps={{
					sx: (theme) => ({
						borderRadius: theme.shape.standard13.borderRadius,
						boxShadow: "0px 5px 16px rgba(0, 0, 0, 0.3)",
						p: 3,
					}),
				}}
				dialogAction={<></>}
				cropperLoader={loading}
			>
				<Box justifyContent={"center"} sx={{ display: "flex", mt: 7, minHeight: "150px" }}>
					<Box>
						<Box
							sx={(theme) => ({
								display: "flex",
								justifyContent: "center",
							})}
						>
							<CircularProgress
								sx={(theme) => ({
									height: "80px",
									width: "80px",
									color: theme.palette.text.darkBlue,
								})}
							/>
						</Box>
						<Box
							sx={(theme) => ({
								textAlign: "center",
								pt: 5,
							})}
						>
							<Typography variant="title_medium">File upload in progress</Typography>
						</Box>
					</Box>
				</Box>
			</CommonSlideDialog>
		</Box>
	);
};

export default DragDropFileUploader;
