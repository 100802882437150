import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const MarketTestCard = styled(Box)(({ theme }) => ({
  //padding: theme.spacing(6),
  display: "flex",
  marginTop: theme.spacing(3),
  flexDirection: "column",
  [theme.breakpoints.down("md")]: {
    background: "transparent",
    border: "none",
    boxShadow: "0",
    padding: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
}));
