import React, { useState, useEffect } from "react";
import MUIRichTextEditor from "mui-rte";
import { convertToRaw, EditorState } from "draft-js";
import { stateFromMarkdown } from "draft-js-import-markdown"; // using module to convert markdown to draft-js state markdown as draft-js don't provide any conversion solution for markdown to rawData
import { Box, Typography } from "@mui/material";
import theme from "../../constants/Theme";
import { createTheme, ThemeProvider } from "@mui/material/styles";

export default function TextEditorComp(props) {
  const { label, editorValue, readOnly, displayValue, onChange } = props;
  const [initial, setInitial] = useState("");

  useEffect(() => {
    let init_state;
    if (editorValue) {
      init_state = EditorState.createWithContent(
        stateFromMarkdown(editorValue)
      );
    }
    if (init_state) {
      setInitial(JSON.stringify(convertToRaw(init_state.getCurrentContent())));
    }
  }, []);
  const myTheme = createTheme({
    // Set up your custom MUI theme here
  });
  return (
    <ThemeProvider theme={myTheme}>
      <Box display={displayValue} sx={{ alignItems: "baseline" }}>
        <Box>
          <Typography variant="body_bold">{label} &nbsp;</Typography>
        </Box>
        <Box sx={{ overflow: "auto" }}>
          <MUIRichTextEditor
            onChange={data => onChange(data, label ? "subject" : "description")}
            value={initial}
            toolbar={false}
            inlineToolbar={false}
            readOnly={readOnly}
            draftEditorProps={{ spellCheck: true }}
          ></MUIRichTextEditor>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
