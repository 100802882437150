import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Box, IconButton, SwipeableDrawer, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import theme from "../../constants/Theme";
import { COLORED_ARROW_ICON } from "../../constants";
import { useWindowSize } from "../../utils/windowResize";
import FounderTeamView from "../../containers/ScoutingReportView/FoundingTeam";
import { setInvestorDashboardData } from "../../modules/actions";
import { Close } from "@mui/icons-material";

export default function ScoutingReportDrawer() {
	const size = useWindowSize();
	const dispatch = useDispatch();
	const openStartupObj = useSelector((state) => state?.investorDashboard?.openStartupObj);
	const SCOUTING_OPEN = useSelector((state) => state?.investorDashboard?.scoutingOpen);
	const handleClose = () => {
		dispatch(setInvestorDashboardData({ scoutingOpen: false }));
	};

	const responsiveDrawerWidth = size.width * 0.85;

	return (
		<SwipeableDrawer transitionDuration={{ enter: 600, exit: 600 }} anchor="right" open={SCOUTING_OPEN} onClose={handleClose}>
			<Box width={responsiveDrawerWidth}>
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						px: "60px",
						pt: "60px",
						pb: "30px",
					}}
				>
					<Box
						sx={{
							color: "rgba(0, 0, 0, 1)",
							fontFamily: '"PoppinsSemiBold", "Helvetica", "Arial", sans-serif',
							fontWeight: "600",
							fontSize: "30px",
							lineHeight: "45px",
						}}
					>
						Scouting Report
					</Box>
					<Box sx={{ flex: 1 }}>
						<Button
							variant="text"
							sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								gap: 1,
								width: "100%",
								maxWidth: "unset",
							}}
							onClick={() =>
								dispatch(
									setInvestorDashboardData({
										benchmarkDetailsOpen: true,
									})
								)
							}
						>
							<Typography variant="card_text" color={"black"} sx={{ fontWeight: 600 }}>
								StartupOS Benchmark Score
							</Typography>
							<Typography variant="card_name" color={"black"} sx={{ fontWeight: 600 }}>
								{openStartupObj?.currentScore ? openStartupObj?.currentScore : "-"}
							</Typography>

							<Box component={"img"} sx={{ height: "18px", width: "18px", mb: 1 }} src={COLORED_ARROW_ICON} alt="scouting"></Box>
						</Button>
					</Box>
					<IconButton size="large" onClick={handleClose}>
						<Close fontSize="inherit" />
					</IconButton>
				</Box>
				<FounderTeamView onClose={handleClose} />
			</Box>
		</SwipeableDrawer>
	);
}
