import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  Card,
  Divider,
  Typography,
  CircularProgress,
  Button,
} from "@mui/material";
import theme from "../../../../constants/Theme";
import { PowerUpAvatar } from "../Accordion/styles";
import FormSectionHeader from "../FormSectionHeader";
import Tabs from "../../Tabs";
import messages from "../../../../constants/messages";
import constants, { GREEN_CHECK_ICON, FLAG_ICON } from "../../../../constants";
import WorkspaceStepper from "../../Stepper";
import JsxParser from "react-jsx-parser";
import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined";
import LoadingButton from "../../../../common/Loadingbutton";

export default function ExcerciseSection({
  currentExcercise,
  excerciseConfigurations,
  currenttab,
  setcurrenttab,
  children,
  NextButton,
  BackButton,
  excerciseSteps,
  showSummary,
  powerUpExcercises,
  handleStepperStepChange,
  version,
  isSummaryExists = true,
  titleBoxComponent,
  hideStepper,
  FooterComponent = () => {},
  summaryComponent,
  customFooter,
  handleBackClick,
  handleNextClick,
  nextButtonLoading,
  handleButtonDisable,
  shouldShowPrompt,
}) {
  const [activeStep, setActiveStep] = useState(0);
  const [updatedExcerciseSteps, setUpdatedExcerciseSteps] = useState([]);

  const getSerialNumber = number => {
    if (number > 9) {
      return number;
    } else {
      return `0${number}`;
    }
  };

  const completedStepIcon = () => {
    return (
      <PowerUpAvatar active>
        <img src={GREEN_CHECK_ICON} />
      </PowerUpAvatar>
    );
  };

  const activeStepIcon = ({ number }) => {
    return (
      <PowerUpAvatar active>
        <Typography variant="on_surface" sx={{ color: "text.dark" }}>
          {getSerialNumber(number)}
        </Typography>
      </PowerUpAvatar>
    );
  };

  const defaultStepIcon = ({ number }) => {
    return (
      <PowerUpAvatar>
        <Typography variant="on_surface" sx={{ color: "text.inactive" }}>
          {getSerialNumber(number)}
        </Typography>
      </PowerUpAvatar>
    );
  };

  const lastStepIcon = ({ active }) => {
    return (
      <PowerUpAvatar>
        <FlagOutlinedIcon
          sx={{ color: active ? "success.main" : "secondary.dark" }}
        />
      </PowerUpAvatar>
    );
  };

  useEffect(() => {
    if (excerciseSteps) {
      if (isSummaryExists) {
        setUpdatedExcerciseSteps([
          ...excerciseSteps,
          { isLast: true, title: "Complete" },
        ]);
      } else {
        setUpdatedExcerciseSteps([...excerciseSteps]);
      }
    }
  }, [excerciseSteps]);

  useEffect(() => {
    if (currentExcercise && excerciseSteps) {
      const excerciseStepIndex = excerciseSteps.findIndex(
        step => currentExcercise.stepNo === step.stepNo
      );
      setActiveStep(excerciseStepIndex);
    } else if (excerciseSteps && !currentExcercise && showSummary) {
      setActiveStep(updatedExcerciseSteps.length - 1);
    }
  }, [currentExcercise]);

  useEffect(() => {
    if (activeStep === updatedExcerciseSteps.length - 1) {
      handleStepperStepChange("summary");
    } else {
      handleStepperStepChange(excerciseSteps[activeStep]?.id);
    }
  }, [activeStep]);

  const tabitems = constants.EXCERCISE_TAB_ITEMS;
  return (
    <Grid
      item
      md={8}
      sm={12}
      xs={12}
      sx={theme => ({
        [theme.breakpoints.up("md")]: {
          // borderRight: `1px solid ${theme.palette.secondary.main}`,
          pr: 3,
          // mt: 4,
        },
        [theme.breakpoints.down("md")]: {
          p: 2,
        },
        display: "flex",
        flexDirection: "column",
        minHeight: "100%",
      })}
    >
      <Card
        sx={theme => ({
          borderRadius: theme.shape.standard.borderRadius,
          boxShadow:
            "0px 1px 2px rgb(0 0 0 / 30%), 0px 1px 3px 1px rgb(0 0 0 / 15%)",
          p: 3,
          display: "flex",
          flexDirection: "column",
          [theme.breakpoints.down("md")]: {
            background: "transparent",
            border: "none",
            boxShadow: "0",
            p: 1,
            mt: 2,
          },
        })}
      >
        <FormSectionHeader
          heading={excerciseConfigurations.form_title.toUpperCase()}
        />
        <Divider />

        <Box
          sx={{
            display: { xs: "block", sm: "block", md: "none" },
            mt: 3,
          }}
        >
          <Tabs
            tabitems={tabitems}
            currenttab={currenttab}
            setcurrenttab={setcurrenttab}
            color="primary"
            spacing={0}
            buttonWidth="50%"
            width="100%"
          />
        </Box>

        {currentExcercise && !showSummary ? (
          <>
            <Box
              sx={{
                mt: 3,

                [theme.breakpoints.down("md")]: {
                  mb: 3,
                  position: "relative",
                  maxWidth: 1,
                },
              }}
            >
              {!hideStepper ? (
                <WorkspaceStepper
                  shouldShowPrompt={shouldShowPrompt}
                  steps={updatedExcerciseSteps}
                  activeStep={activeStep}
                  setActiveStep={setActiveStep}
                  ActiveIcon={activeStepIcon}
                  CompletedIcon={completedStepIcon}
                  DefaultIcon={defaultStepIcon}
                  type="POWERUP"
                  fixedWidth={1}
                  linear={false}
                  nonLinear={true}
                  LastStepIcon={lastStepIcon}
                  powerUpExcercises={powerUpExcercises}
                  isSummaryExists={isSummaryExists}
                />
              ) : (
                ""
              )}
            </Box>

            {version && (
              <Box
                sx={{ mt: 3, display: { sm: "none", xs: "none", md: "block" } }}
              >
                <Divider />
                <Box sx={{ mt: 3 }}>
                  <Typography variant="title_small">
                    Version:{version}
                  </Typography>
                </Box>
              </Box>
            )}

            {titleBoxComponent && <Box sx={{ mt: 3 }}>{titleBoxComponent}</Box>}

            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                flexDirection: "column",
                display: {
                  sm: currenttab === "excercise" ? "block" : "none",
                  xs: currenttab === "excercise" ? "block" : "none",
                  md: "flex",
                },
              }}
            >
              {!hideStepper ? (
                <Box
                  alignItems={"center"}
                  sx={{
                    display: {
                      xs: "none",
                      sm: "none",
                      md: "none",
                      lg: "none",
                      xl: "none",
                    },
                    mt: 3,
                  }}
                >
                  <PowerUpAvatar sx={{ mb: 2 }}>
                    <Typography variant="title_medium">
                      {getSerialNumber(currentExcercise.stepNo)}
                    </Typography>
                  </PowerUpAvatar>
                  <Typography variant="title_large">
                    {currentExcercise.title}
                  </Typography>
                </Box>
              ) : (
                ""
              )}

              <Box sx={{ mt: 3 }}>
                {!hideStepper ? (
                  <Typography variant={"subhead1"}>
                    <JsxParser
                      components={{ Typography }}
                      jsx={currentExcercise.description}
                    />
                  </Typography>
                ) : (
                  ""
                )}
              </Box>

              <Box
                sx={{ mt: 3 }}
                flexGrow={1}
                display="flex"
                flexDirection={"column"}
              >
                {children}
              </Box>

              <Divider
                sx={{ my: 3, display: { sm: "none", xs: "none", md: "block" } }}
              />

              <Box
                sx={{
                  display: { xs: "none", sm: "none", md: "block" },
                }}
              >
                {isSummaryExists ? (
                  <Grid container>
                    <Grid
                      item
                      md={8}
                      display={{
                        xs: "none",
                        sm: "none",
                        md: "block",
                      }}
                    ></Grid>
                    <Grid item md={4} sm={12} xs={12}>
                      <Box
                        display={"flex"}
                        alignItems={"center"}
                        letterSpacing={2}
                      >
                        {!customFooter ? (
                          <>
                            <BackButton />
                            <NextButton />
                          </>
                        ) : (
                          <>
                            <Button
                              variant="outlined"
                              color="primary"
                              sx={{ mx: 1 }}
                              onClick={handleBackClick}
                            >
                              Back
                            </Button>
                            <LoadingButton
                              loading={nextButtonLoading}
                              onClick={handleNextClick}
                              disabled={handleButtonDisable()}
                            >
                              Next
                            </LoadingButton>
                          </>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                ) : (
                  <FooterComponent />
                )}
              </Box>
            </Box>
          </>
        ) : showSummary && !currentExcercise ? (
          <Box sx={{ mt: 3 }}>
            <Box
              sx={{
                display: {
                  sm: currenttab === "excercise" ? "block" : "none",
                  xs: currenttab === "excercise" ? "block" : "none",
                  md: "block",
                },
              }}
            >
              <Box
                sx={{
                  [theme.breakpoints.down("md")]: {
                    pb: 1,
                    position: "relative",
                    maxWidth: 1,
                  },
                }}
              >
                <WorkspaceStepper
                  shouldShowPrompt={shouldShowPrompt}
                  steps={updatedExcerciseSteps}
                  activeStep={activeStep}
                  setActiveStep={setActiveStep}
                  ActiveIcon={activeStepIcon}
                  CompletedIcon={completedStepIcon}
                  DefaultIcon={defaultStepIcon}
                  type="POWERUP"
                  fixedWidth={1}
                  linear={false}
                  nonLinear={true}
                  LastStepIcon={lastStepIcon}
                  powerUpExcercises={powerUpExcercises}
                  isSummaryExists={isSummaryExists}
                />
              </Box>

              <Box sx={{ [theme.breakpoints.down("md")]: { mt: 3 } }}>
                {summaryComponent}
              </Box>
            </Box>
          </Box>
        ) : (
          <Box
            display={"flex"}
            justifyContent="center"
            alignItems={"center"}
            flexGrow={1}
          >
            <CircularProgress />
          </Box>
        )}
      </Card>
    </Grid>
  );
}
