import * as actionTypes from "../../actions/actionTypes";
import { updateObject } from "../../../utils/updateObject";

const initialState = {
    profileType: "",
    open: false,
    profileId: null,
};

const slidingProfileReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_SLIDING_PROFILE_DIALOG_DATA: {
            return action.payload === null ? initialState : updateObject(state, action.payload);
        }
        default:
            return state;
    }
};

export default slidingProfileReducer;
