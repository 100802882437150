import { Box, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import OnboardingCardSelector from "../../../../components/Onboarding/OnboardingCardSelector";
import PageTitle from "../../../../components/Onboarding/PageTitle";
import { WHAT_BRINGS_YOU_HERE } from "../../../../constants/updated-onboarding";

export default function WhatBringsYouHere({ setComplete }) {
  const [userResponse, setUserResponse] = useState("");
  const callComplete = (complete, data) => {
    if (setComplete) {
      setComplete(complete, data);
    }
  };
  const savedData = useSelector(state => state?.onboarding?.whatBringHere);

  useEffect(() => {
    if (typeof savedData !== "undefined") {
      if (savedData.length) {
        setUserResponse(savedData);
      }
    }
  }, [savedData]);

  useEffect(() => {
    if (userResponse && userResponse.length) {
      callComplete(1, { whatBringHere: userResponse });
    } else {
      callComplete(0, { whatBringHere: [] });
    }
  }, [userResponse]);
  return (
    <Box>
      <PageTitle
        title={"What brings you here?"}
        description={"Select what you're most excited to gain from StartupOS."}
      />

      <Box sx={{ my: 5 }}>
        <OnboardingCardSelector
          cardItems={WHAT_BRINGS_YOU_HERE}
          flex={1 / 2}
          gridColWidth={6}
          multiSelect
          setUserResponse={setUserResponse}
          userResponse={userResponse}
          savedData={savedData}
        />
      </Box>
    </Box>
  );
}
