import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { SCOUTING_REPORT_STATIC_ONE, SCOUTING_REPORT_STATIC_TWO } from "../../../../constants";
import { useSelector, useDispatch } from "react-redux";
import { getInvestorImpressionsDetails } from "../../functions";
import { setInvestorDashboardData } from "../../../../modules/actions/investorDashboardActions";

const ITEMS = [
	{
		title: "Viewed by Investors",
		value: 0,
	},
	{
		title: "Conversations with Investors",
		value: 0,
	},
	{
		title: "Investors Watching",
		value: 0,
	},
	{
		title: "In Investors Portfolio",
		value: 0,
	},
	{
		title: "Investor Meetings Scheduled",
		value: 0,
	},
];

const Section = ({ title, value }) => {
	return (
		<Box display="flex" flexDirection={"column"}>
			<Typography variant="heading1" sx={{ color: "#888888" }}>
				{title}
			</Typography>
			<Typography variant="heading1" sx={{ fontSize: "60px", lineHeight: "90px" }}>
				{value}
			</Typography>
		</Box>
	);
};

export default function InvestorImpressions() {
	const STARTUP = useSelector((state) => state?.investorDashboard?.openStartupObj);
	const dispatch = useDispatch();
	const investorImpressionsData = useSelector((state) => state?.investorDashboard?.investorImpressionsData);
	const [totalImpressionValue, setTotalImpressionValue] = useState();

	const getInvestorImpressions = async () => {
		let investorImpressionsValues = await getInvestorImpressionsDetails(STARTUP?.id);
		if (investorImpressionsValues) {
			dispatch(setInvestorDashboardData({ investorImpressionsData: investorImpressionsValues }));
		}
	};

	useEffect(() => {
		if (STARTUP?.id) {
			getInvestorImpressions();
		}
	}, []);

	useEffect(() => {
		let sum = 0;
		for (const key in investorImpressionsData) {
			if (Object.hasOwnProperty.call(investorImpressionsData, key)) {
				const impression = investorImpressionsData[key];
				if (impression !== null) {
					sum = sum + impression;
				}
			}
		}
		setTotalImpressionValue(sum);
	}, [investorImpressionsData]);

	return (
		<Box position="relative" p="0px 60px 80px" mt="40px">
			<Box
				p="60px"
				sx={{
					position: "relative",
					background: "#FFFFFF",
					boxShadow: "0px 10px 25px rgba(0, 0, 0, 0.05)",
					borderRadius: "30px",
					zIndex: 99,
				}}
			>
				<Typography variant="card_title">Investor Impressions</Typography>
				<Box display="flex" gap="40px" mt="40px">
					<Box display="flex" flex={1} flexDirection={"column"}>
						<Typography variant="heading1" sx={{ color: "#888888" }}>
							Impressions
						</Typography>
						<Typography variant="heading1" sx={{ fontSize: "100px", lineHeight: "150px" }}>
							{totalImpressionValue}
						</Typography>
					</Box>
					<Box display="grid" flex={2} gridTemplateColumns={"1fr 1fr"} gap="40px">
						<Section title="Viewed by Investors" value={investorImpressionsData?.investorsViewed ?? 0} />
						<Section title="Conversations with Investors" value={investorImpressionsData?.investorsConversations ?? 0} />
						<Section title="Investors Watching" value={investorImpressionsData?.investorsWatching ?? 0} />
						<Section title="In Investors Portfolio" value={investorImpressionsData?.investorsPortfolio ?? 0} />
						<Section title="Investor Meetings Scheduled" value={investorImpressionsData?.investorsMeetingScheduled ?? 0} />
					</Box>
				</Box>
			</Box>
			<Box position={"absolute"} top="-110px" left={"0"}>
				<Box component={"img"} src={SCOUTING_REPORT_STATIC_ONE} alt="SCOUTING_REPORT_STATIC_ONE" />
			</Box>
			<Box position={"absolute"} top="-260px" right={"0"}>
				<Box component={"img"} src={SCOUTING_REPORT_STATIC_TWO} alt="SCOUTING_REPORT_STATIC_TWO" />
			</Box>
		</Box>
	);
}
