import React, { useState, useEffect } from "react";
import DialogComponent from "../../../../common/CommonDialog";
import { Box, Button, TextField, Typography } from "@mui/material";
import services from "../../../../services";
import messages from "../../../../constants/messages";

export default function ConnectRequestPopup({
  openConnectRequest,
  setOpenConnectRequest,
  onConnect,
  companyId,
  startupName,
  userId,
  userName,
}) {
  useEffect(() => {
    if (!openConnectRequest) {
      setMessage("");
    }
  }, [openConnectRequest]);

  let data = {};

  const handleConnect = () => {
    if (userName) {
      data = {
        userId,
        message,
      };
    } else {
      data = {
        companyId,
        message,
      };
    }
    onConnect(data);
  };
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const FooterComponent = () => {
    return (
      <Box display={"flex"} gap={2} sx={{ mx: 4, my: 2 }}>
        <Button
          variant="outlined"
          onClick={() => {
            setMessage("");
            setOpenConnectRequest(false);
          }}
        >
          Cancel
        </Button>
        <Button
          fullWidth
          onClick={() => handleConnect()}
          disabled={message?.trim().length > 200}
        >
          Send
        </Button>
      </Box>
    );
  };

  const handleTextChange = e => {
    setMessage(e.target.value);
    if (e.target.value?.trim().length > 200) {
      setError(messages.MESSAGE_EXCEED);
    } else {
      setError("");
    }
  };
  return (
    <DialogComponent
      isOpen={openConnectRequest}
      onClose={() => setOpenConnectRequest(false)}
      dialogAction={<FooterComponent />}
      title={`Connect with ${userName ? userName : startupName}`}
      titleVariant="title_large"
      PaperProps={{
        sx: theme => ({ borderRadius: theme.shape.standard.borderRadius }),
      }}
      disableBackDropClick
    >
      <Box sx={{ m: 3 }}>
        <Typography variant="subhead1">
          Add a message to describe why you’d like to connect with{" "}
          {userName ? userName : startupName}.
        </Typography>
        <Box sx={{ mt: 3, mb: 2 }}>
          <TextField
            label={"Message (optional)"}
            spellCheck="true"
            multiline
            rows={5}
            fullWidth
            value={message}
            onChange={handleTextChange}
            error={!!error}
            helperText={error}
          />
        </Box>
      </Box>
    </DialogComponent>
  );
}
