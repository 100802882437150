import styled from "@emotion/styled";
import { BREAKPOINT_XXL } from "../../../../constants/Theme/layout";
export const ItemIcon = styled.div(({ theme, selected }) => {
  return {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: theme.shape.standard25.borderRadius,
    height: "24px",
    width: "24px",
    backgroundColor: selected
      ? theme.palette.secondary.light
      : theme.palette.secondary.white,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      backgroundColor: selected ? theme.palette.secondary.light : "transparent",
    },
    "&:hover": {
      backgroundColor: theme.palette.grey[200],
    },
  };
});

export const LeftSideBarWrapper = styled.div(({ theme }) => {
  return {
    background:
      "linear-gradient(0deg, rgba(106, 117, 117, 0.02), rgba(106, 117, 117, 0.02)), linear-gradient(0deg, rgba(245, 248, 250, 0.05), rgba(245, 248, 250, 0.05)), #FCFCFC",
    borderRight: `1px solid ${theme.palette.secondary.outline2}`,
    height: "100%",
    [theme.breakpoints.up("md")]: {
      position: "fixed",
    },

    [theme.breakpoints.down("md")]: {
      background: theme.palette.secondary.backgroundGradient,
    },
    //[theme.breakpoints.between(theme.breakpoints.values.xl, BREAKPOINT_XXL)]: {
    //  width: "64px",
    //},
  };
});
