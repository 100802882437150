import { Box, Grid, Typography, useTheme } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { BUILDINGS_ICON, CLOUD_ARROW_UP_ICON, CROW_ICON, ROCKET_UP_ICON, USER_GROUP_ICON, USER_LIGHT_ICON } from "../../../constants";
import InfoHelper from "../InfoHelper";
import { CardFounderWrapper } from "../styles";
import Streaks from "./Streaks";

function CurrentStreaks() {
	const theme = useTheme();
	const currentStreaksData = useSelector((state) => state?.founderDashboard?.currentStreaksData);
	const swagStoreLink = "https://store.covver.io/startupos/collections/startupos-swag-store";
	const redirectSwagStore = () => {
		window.open(swagStoreLink, "_blank");
	};
	return (
		<CardFounderWrapper>
			<Box display="flex" flexDirection="column">
				<Box display="flex" alignItems="center" justifyContent="space-between">
					<Box sx={{ display: "flex", alignItems: "center", gap: "12px" }}>
						<Typography variant="main_title" color="secondary.black">
							Current Streaks
						</Typography>
						<InfoHelper
							title={"Current Streaks"}
							body={
								"Check out your current platform activity <br/><br/> <strong>Note:</strong> Your score will improve as you increase your activity within the StartupOS platform. Get to the top of the leaderboard now!"
							}
						/>
					</Box>
					<Typography variant="impression_subtitle" color="secondary.black">
						Past 30 days
					</Typography>
				</Box>
				<Box mb={6} display="flex" alignItems="center" justifyContent="space-between">
					<Box display="flex" columnGap={1} flexWrap="wrap">
						<Typography variant="poppinsSemiBold16" color="secondary.black">
							Earn credits towards your{" "}
						</Typography>
						<Typography
							onClick={redirectSwagStore}
							sx={{
								cursor: "pointer",
								transition: "all .2s ease-in-out",
								"&:hover": {
									transform: "translateY(-1.5px)",
								},
							}}
							component="span"
							variant="poppinsSemiBold16"
							color="primary.primaryPink"
						>
							StartupOS store
						</Typography>{" "}
						<Typography variant="poppinsSemiBold16" color="secondary.black">
							swag!
						</Typography>
					</Box>
					{/* <Typography variant="count_title" color="secondary.black">
						Current Bank{" "}
						<Typography component="span" variant="growth_progress_text" color="primary.primaryPink">
							100
						</Typography>
					</Typography>  */}
				</Box>
				<Box mb={6}>
					<Grid container columnSpacing={2} rowSpacing={6} alignItems="stretch">
						<Grid item xs={6} md={6} lg={4} display="flex">
							<Streaks
								title="Within 30 days"
								subtitle="Login completed"
								icon={CLOUD_ARROW_UP_ICON}
								value={currentStreaksData?.login ? currentStreaksData?.login : 0}
								color={theme.palette.primary.main}
							/>
						</Grid>
						<Grid item xs={6} md={6} lg={4} display="flex">
							<Streaks
								title="Top 3"
								subtitle="Completed"
								icon={CROW_ICON}
								value={currentStreaksData?.powerup ? currentStreaksData?.powerup : 0}
								color={theme.palette.primary.primaryPink}
							/>
						</Grid>
						<Grid item xs={6} md={6} lg={4} display="flex">
							<Streaks
								title="One Step"
								subtitle="Completed"
								icon={ROCKET_UP_ICON}
								value={currentStreaksData?.packCompletion ? currentStreaksData?.packCompletion : 0}
								color={theme.palette.primary.primaryMelon}
							/>
						</Grid>
						<Grid item xs={6} md={6} lg={4} display="flex">
							<Streaks
								title="Account Profile"
								subtitle="Completed"
								icon={USER_LIGHT_ICON}
								value={currentStreaksData?.accountProfile ? currentStreaksData?.accountProfile : 0}
								color={theme.palette.primary.primaryMint}
							/>
						</Grid>
						<Grid item xs={6} md={6} lg={4} display="flex">
							<Streaks
								title="Startup Profile"
								subtitle="Completed"
								icon={BUILDINGS_ICON}
								value={currentStreaksData?.startupProfile ? currentStreaksData?.startupProfile : 0}
								color={theme.palette.primary.primaryYellow}
							/>
						</Grid>
						<Grid item xs={6} md={6} lg={4} display="flex">
							<Streaks
								title="One Mentor"
								subtitle="Completed"
								icon={USER_GROUP_ICON}
								value={currentStreaksData?.meeting ? currentStreaksData?.meeting : 0}
								color={theme.palette.primary.primaryBlue}
							/>
						</Grid>
					</Grid>
				</Box>
			</Box>
		</CardFounderWrapper>
	);
}

export default CurrentStreaks;
