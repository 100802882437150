import * as actionTypes from "./actionTypes";

export const setSlidingProfileData = (payload) => {
	return (dispatch) => {
		dispatch({
			type: actionTypes.SET_SLIDING_PROFILE_DIALOG_DATA,
			payload,
		});
	};
};
