import React, { useEffect, useState } from "react";
import BILineChart from "./BILineChart/BILineChart";
import { Box, Typography, useTheme, CircularProgress } from "@mui/material";
import ChartCard from "./ChartCard";
import { get } from "lodash";
import { convertNumberToLocale, getGradientBgColor } from "../Functions/common";
import ZeroLineChart from "./BILineChart/EmptyLineChart";

function NewArrChartContainer({ onChartClick, data, loading, readOnly }) {
	const theme = useTheme();
	const chartData = get(data, "data", []);

	const latestNetNewArr = get(data, "latestNetNewArr", null);

	return (
		<ChartCard
			title={"Net New ARR"}
			subheader={latestNetNewArr !== null && !loading ? `$${convertNumberToLocale(latestNetNewArr)}` : "0"}
			backgroundColor={theme.palette.secondary.white}
			onChartClick={() => onChartClick("netNewArr", data)}
			toolTipBody={
				<>
					<Typography variant="Text/sm/Regular">Net New ARR is how much total ARR a company adds in a period, net of churn.</Typography>
					<Typography variant="Text/sm/Regular" sx={{ fontStyle: "italic" }}>
						Net New ARR for month x = ARR in month x - ARR in month (x-1)
					</Typography>
				</>
			}
			data={data?.data}
		>
			{loading ? (
				<Box sx={{ alignSelf: "center" }}>
					<CircularProgress />
				</Box>
			) : chartData.length === 0 ? (
				<ZeroLineChart readOnly={readOnly} />
			) : (
				<Box sx={{ position: "relative", width: "100%", mt: "auto" }}>
					<BILineChart
						data={chartData}
						tooltipYLable={"Net New ARR"}
						yAxisKey={"netNewArr"}
						yAxisType="currency"
						lineBorderColor={theme.palette.primary.main}
						lineBackgroundColor={(context) => {
							const ctx = context.chart.ctx;
							return getGradientBgColor(ctx, context);
						}}
					/>
				</Box>
			)}
		</ChartCard>
	);
}

export default NewArrChartContainer;
