import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
	Button,
	Typography,
	Box,
	Badge,
	Avatar,
	CircularProgress,
	Divider,
	ImageList,
	ImageListItem,
	ImageListItemBar,
	RadioGroup,
	FormControlLabel,
	Radio,
} from "@mui/material";

import { UPLOAD_IMAGE_ICON } from "../../constants/image";
import Dropzone from "react-dropzone";
import services from "../../services";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import LoadingButton from "../Loadingbutton";
import DialogComponent from "../../containers/Workspace/PowerUP/IdeaValidation/Components/Dialog";
import MessageHandlerModal from "../modal/MessageHandler";
import { toastContainer } from "../../utils/toast";
import { COVER_IMAGE_TEMPLATE_LIST } from "../../constants";
import { handleMixPanel } from "../../utils/mixPanelEventHandling";
import AvatarWithFallBack from "../AvatarWithFallBack";

export default function UploadImageModal({
	open,
	onClose,
	backgroundPhoto,
	setPhoto,
	photo,
	title,
	selectFileText,
	dimensionText,
	aspectRatio,
	imageType,
	fileType,
	circularCropper,
	handleUpdateAssetsUrl,
	company,
	mentorData,
}) {
	const [openCropper, setOpenCropper] = useState(false);
	const [localImage, setLocalImage] = useState(null);
	const [loading, setLoading] = useState(false);
	const [cropData, setCropData] = useState();
	const [cropper, setCropper] = useState();
	const [uploadedImg, setUploadedImg] = useState();
	const [cropperLoader, setCropperLoader] = useState(false);
	const [deleteConfirmPopup, setDeleteConfirmPopup] = useState(false);
	const [selectedTemplate, setSelectedTemplate] = useState();
	const userData = useSelector((store) => ({
		userId: store?.auth?.userId,
		userName: store?.auth?.userName,
	}));
	const { isPremiumPlanAccess, userType } = useSelector((store) => store?.auth);

	const handleImageSelect = (file) => {
		if (file) {
			const fileSize = (file?.size / (1024 * 1024 * 1024)).toFixed(2);

			if (fileSize < 2) {
				const reader = new FileReader();
				reader.addEventListener("load", () => setLocalImage(reader.result?.toString() || ""));
				reader.readAsDataURL(file);
				setOpenCropper(true);
			} else {
				toastContainer("Please Upload file less than 2GB", "error");
			}
		}
	};
	const handleTemplateSelect = (file) => {
		let template = COVER_IMAGE_TEMPLATE_LIST.filter((imgItem) => imgItem.title === selectedTemplate);

		setPhoto(template[0].img);
		onClose();

		if (imageType === "BACKGROUND_PHOTO") {
			handleUpdateAssetsUrl(template[0].img, "coverImgUrl");
		}
	};

	const convertURIToImageData = (dataurl) => {
		let arr = dataurl.split(","),
			mime = arr[0].match(/:(.*?);/)[1],
			bstr = atob(arr[1]),
			n = bstr.length,
			u8arr = new Uint8Array(n);
		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}
		let filename = Date.now() + "." + mime.split("/")[1];
		return new File([u8arr], filename, {
			type: mime,
		});
	};

	const getCropData = () => {
		setCropperLoader(true);

		if (typeof cropper !== "undefined") {
			var fileUrl = convertURIToImageData(cropper.getCroppedCanvas().toDataURL(), "");
			//setCropperLoader(true);
			setCropData(cropper.getCroppedCanvas().toDataURL());
			handleUpload(fileUrl, "img");
		}
	};
	const handleSave = () => {
		if (typeof cropper !== "undefined") {
			setPhoto(uploadedImg);
			setCropData("");
			setLocalImage("");
			onClose();
		}
	};

	const handleUpload = (data, type) => {
		if (type === "img") {
			if (data.type == "image/png" || data.type == "image/jpeg" || data.type == "image/jpg") {
				//setCropperLoader(true);
			}
		}
		getFiles(data, type);
	};

	const getFiles = async (data, type) => {
		var formData = new FormData();
		formData.append("file", data);
		formData.append("fileGroup", "LOGO");
		services
			.uploadFile(formData)
			.then((response) => {
				if (type == "img") {
					setLoading(false);
					setCropperLoader(false);
					setPhoto(response.data.data?.fileLocation);
					setUploadedImg(response.data.data?.fileLocation);
					setOpenCropper(false);
					setSelectedTemplate("");
					if (imageType === "BACKGROUND_PHOTO") {
						handleUpdateAssetsUrl(response.data.data?.fileLocation, "coverImgUrl");
					} else if (imageType === "PROFILE_PHOTO") {
						handleUpdateAssetsUrl(response.data.data?.fileLocation, "picture");
						handleMixPanel("Account Profile Edited", {
							userName: userData.userName,
							userId: userData.userId,
							subscription_status: isPremiumPlanAccess ? "Subscribed" : "Not Subscribed",
							user_type: userType,
						});
					} else if (imageType === "COMPANY_PHOTO") {
						handleUpdateAssetsUrl(response.data.data?.fileLocation, "logoUrl");
						handleMixPanel("Startup Profile Edited", {
							userName: userData.userName,
							userId: userData.userId,
							subscription_status: isPremiumPlanAccess ? "Subscribed" : "Not Subscribed",
							user_type: userType,
						});
					}
				}
			})
			.catch((e) => {
				setCropperLoader(false);
			});
	};

	const deleteVideoHandler = () => {
		if (imageType === "BACKGROUND_PHOTO") {
			handleUpdateAssetsUrl("", "coverImgUrl");
		} else if (imageType === "PROFILE_PHOTO") {
			handleUpdateAssetsUrl("", "picture");
		} else if (imageType === "COMPANY_PHOTO") {
			handleUpdateAssetsUrl("", "logoUrl");
		}
		setLoading(false);
		setCropperLoader(false);
		setPhoto(null);
		setUploadedImg(null);
		setOpenCropper(false);
		setDeleteConfirmPopup(false);
		onClose();
	};
	const FooterComponent = () => {
		return (
			<>
				{selectedTemplate && (
					<Box sx={{ display: "flex", gap: 1, m: 2 }}>
						<Box>
							<Button
								variant="outlined"
								onClick={() => {
									setCropData("");
									setLocalImage("");
									onClose();
									setSelectedTemplate("");
								}}
								sx={{ width: "max-content", whiteSpace: "nowrap" }}
							>
								Cancel
							</Button>
						</Box>

						<Button
							sx={{ mr: 1 }}
							onClick={() => {
								selectedTemplate ? handleTemplateSelect() : handleSave();
								handleMixPanel("Account Profile Edited", {
									userName: userData.userName,
									userId: userData.userId,
									subscription_status: isPremiumPlanAccess ? "Subscribed" : "Not Subscribed",
									user_type: userType,
								});
							}}
						>
							Save
						</Button>
					</Box>
				)}
			</>
		);
	};
	return (
		<Box>
			{" "}
			<DialogComponent
				disableBackDropClick={true}
				title={title}
				titleVariant={"title_large"}
				isOpen={open}
				onClose={() => {
					onClose();
					setCropData("");
					setLocalImage("");
					setSelectedTemplate("");
				}}
				contentStyle={{ px: 0 }}
				maxWidth={"md"}
				fullWidth
				PaperProps={{
					sx: (theme) => ({
						borderRadius: theme.shape.standard.borderRadius,
					}),
				}}
				footerStyle={{
					border: cropData || photo ? "" : "none",
				}}
				dialogAction={
					<>
						{(cropData || photo) && (
							<Box sx={{ mr: "auto" }}>
								{" "}
								<Button
									variant="link"
									sx={{ color: "#5630B2" }}
									onClick={() => {
										setDeleteConfirmPopup(true);
									}}
								>
									Delete
								</Button>
							</Box>
						)}
						{imageType === "BACKGROUND_PHOTO" ? <FooterComponent /> : cropData || photo ? "" : <FooterComponent />}
					</>
				}
			>
				<Box sx={{ px: 3, pt: 2 }}>
					{(!cropData || !photo) && <Typography variant="title_bold">Upload local file</Typography>}

					<Dropzone onDrop={(acceptedFiles) => handleImageSelect(acceptedFiles[0])} accept={fileType}>
						{({ getRootProps, getInputProps }) => (
							<section>
								<Box {...getRootProps()}>
									<input {...getInputProps()} />
									{cropData || photo ? (
										imageType === "PROFILE_PHOTO" ? (
											<Box sx={{ textAlign: "center", cursor: "pointer" }}>
												{" "}
												<Badge
													overlap="circular"
													anchorOrigin={{
														vertical: "bottom",
														horizontal: "right",
													}}
												>
													<AvatarWithFallBack src={cropData || photo} sx={{ height: "120px", width: "120px" }} />
												</Badge>
											</Box>
										) : imageType === "COMPANY_PHOTO" ? (
											<>
												{title === "Custom Image" ? (
													<Box>
														<Box sx={{ width: 1 }} component={"img"} src={cropData || photo} alt="profile_picture"></Box>
													</Box>
												) : (
													<Box sx={{ textAlign: "center", cursor: "pointer" }}>
														{" "}
														<Badge
															overlap="rectangular"
															//anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
															//transformOrigin={{ vertical: "200px", horizontal: "200px" }}
														>
															<Avatar
																alt="profile_picture"
																src={cropData || photo}
																sx={(theme) => ({
																	height: "120px",
																	width: "120px",
																	border: `3px solid ${theme.palette.background.paper}`,
																	borderRadius: "12px",
																})}
															/>
														</Badge>
													</Box>
												)}
											</>
										) : (
											<Box
												component={"img"}
												src={cropData || photo}
												alt="img"
												sx={{
													minHeight: "150px",
													borderRadius: "4px",
													mt: 1,
													width: 1,
													textAlign: "center",

													cursor: "pointer",
												}}
											/>
										)
									) : (
										<Box
											sx={{
												border: "1px dashed rgba(106, 117, 117, 1)",
												minHeight: "150px",
												borderRadius: "4px",
												mt: 1,
												textAlign: "center",
												p: 5,
												cursor: "pointer",
											}}
										>
											<Box component={"img"} src={UPLOAD_IMAGE_ICON} alt="img"></Box>
											<Typography
												variant="label_xlarge"
												sx={{
													mt: 1,
													display: "grid",
													color: (theme) => theme.palette.secondary.korophKoal,
												}}
											>
												{" "}
												{selectFileText}
											</Typography>
											<Typography
												variant="lable_large"
												sx={{
													mt: 1,
													display: "grid",
													color: (theme) => theme.palette.secondary.outline,
												}}
											>
												{" "}
												or drag and drop it here
											</Typography>
											<Typography
												variant="body_small"
												sx={{
													mt: 1,
													display: "grid",
													color: (theme) => theme.palette.secondary.outline,
												}}
											>
												{dimensionText}
											</Typography>
										</Box>
									)}
								</Box>
							</section>
						)}
					</Dropzone>
				</Box>
				{imageType === "BACKGROUND_PHOTO" && (!cropData || !photo) && (
					<Box>
						<Divider
							sx={{
								m: "auto",
								mt: 3,
								width: "30%",
								"&::before, &::after": {
									borderColor: "rgba(106, 117, 117, 1)",
								},
							}}
							role="presentation"
						>
							<Typography variant="label_large">OR</Typography>
						</Divider>
						<Box sx={{ mx: 3 }}>
							{" "}
							<Box sx={{ mt: 1 }}>
								{" "}
								<Typography variant="title_bold"> Select a custom image</Typography>
							</Box>
							<ImageList sx={{ width: "100%", height: 1500 }} gap={12}>
								{COVER_IMAGE_TEMPLATE_LIST.map((item, idx) => (
									<RadioGroup
										key={idx}
										aria-labelledby="demo-radio-buttons-group-label"
										defaultValue={"Template1"}
										name="radio-buttons-group"
									>
										<ImageListItem key={item.img} sx={{ cursor: "pointer" }} onClick={() => setSelectedTemplate(item.title)}>
											<img src={`${item.img}`} srcSet={`${item.img}`} alt={item.title} loading="lazy" />

											{/*<ImageListItemBar title={item.title} position="below" />*/}
										</ImageListItem>
										<FormControlLabel
											value={item.title}
											control={<Radio sx={{ ml: 2 }} checked={selectedTemplate === item.title} />}
											label={item.title}
											onChange={(event) => {
												setSelectedTemplate(event.target.value);
											}}
										/>
									</RadioGroup>
								))}
							</ImageList>
						</Box>
					</Box>
				)}
			</DialogComponent>
			<DialogComponent
				disableBackDropClick={true}
				title={title}
				titleVariant={"title_large"}
				isOpen={openCropper}
				closeButton={!cropperLoader}
				onClose={() => {
					setOpenCropper(false);
				}}
				contentStyle={{ px: 0 }}
				maxWidth={"md"}
				fullWidth
				footerStyle={{
					border: cropperLoader && "none",
				}}
				PaperProps={{
					sx: (theme) => ({
						borderRadius: theme.shape.standard.borderRadius,
					}),
				}}
				dialogAction={
					<>
						{!cropperLoader && (
							<Box sx={{ display: "flex", gap: 1, m: 2 }}>
								<Box>
									<Button
										variant="outlined"
										onClick={() => {
											setOpenCropper(false);
										}}
										disabled={cropperLoader}
										sx={{ width: "max-content", whiteSpace: "nowrap" }}
									>
										Cancel
									</Button>
								</Box>

								<LoadingButton onClick={() => getCropData()} loading={cropperLoader} loadingStyles={{ px: 3 }}>
									Crop
								</LoadingButton>
							</Box>
						)}
					</>
				}
			>
				{cropperLoader ? (
					<Box justifyContent={"center"} sx={{ display: "flex", mt: 7, minHeight: "150px" }}>
						<Box>
							<Box
								sx={(theme) => ({
									display: "flex",
									justifyContent: "center",
								})}
							>
								<CircularProgress
									sx={(theme) => ({
										height: "80px",
										width: "80px",
										color: theme.palette.text.darkBlue,
									})}
								/>
							</Box>
							<Box
								sx={(theme) => ({
									textAlign: "center",
									pt: 5,
								})}
							>
								<Typography variant="title_medium">File upload in progress</Typography>
							</Box>
						</Box>
					</Box>
				) : (
					<>
						{" "}
						<Box sx={{ px: 3, pt: 2 }}>
							<Typography variant="title_bold">Crop Image</Typography>
						</Box>
						<Box
							className={circularCropper ? "circular-cropper" : "normal-cropper"}
							sx={{
								minHeight: "150px",
								borderRadius: "4px",

								width: 1,
								mt: 1,
								textAlign: "center",
								p: 5,
								cursor: "pointer",
							}}
						>
							<Cropper
								style={{
									width: "820px",
									height: 400,
								}}
								aspectRatio={aspectRatio}
								preview=".img-preview"
								src={localImage}
								viewMode={2}
								movable={false}
								background={false}
								responsive={true}
								autoCropArea={0}
								checkOrientation={false}
								onInitialized={(instance) => {
									setCropper(instance);
								}}
								guides={false}
							/>
						</Box>
					</>
				)}
			</DialogComponent>
			<MessageHandlerModal
				isOpen={deleteConfirmPopup}
				onCancel={() => setDeleteConfirmPopup(false)}
				onOk={() => {
					deleteVideoHandler();
				}}
				okText="Delete"
				cancelText={"No thanks"}
				heading={`Delete ${title}`}
				text={`Are you sure you want to delete the ${title}?`}
				messageType="primary"
			/>
		</Box>
	);
}
