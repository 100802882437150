import { Card, styled, Button } from "@mui/material";

export const CardFounderWrapper = styled(Card)(({ theme }) => ({
	backgroundColor: theme.palette.secondary.white,
	borderRadius: "40px",
	boxShadow: "0px 10px 85px rgba(0, 0, 0, 0.09)",
	padding: "45px",
}));

export const CartaOfferCard = styled(Card)(({ theme }) => ({
	backgroundColor: theme.palette.secondary.white,
	borderRadius: "100px",
	boxShadow: "0px 10px 85px rgba(0, 0, 0, 0.09)",
	padding: "30px 60px",
}));

export const AnimatedBtn = styled(Button)`
	width: 230px;
	white-space: nowrap;
	overflow: hidden;
	transition: padding 0.5s;
	padding-right: 50px;

	&:hover {
		padding-right: 0;
		padding-left: 20px;
		transition: padding 0.5s;
	}
`;

export const PitchProgressCard = styled(Card)(({ theme }) => ({
	backgroundColor: theme.palette.secondary.white,
	borderRadius: "30px",
	boxShadow: "0px 10px 25px rgba(0, 0, 0, 0.05)",
	padding: "20px",
}));

export const CardStreaks = styled(Card)(({ theme }) => ({
	backgroundColor: theme.palette.secondary.white,
	borderRadius: "23px",
	boxShadow: "0px 10px 25px rgba(0, 0, 0, 0.05)",
	padding: "40px 20px 60px 30px",
}));

export const CardFounderSmallWrapper = styled(Card)(({ theme }) => ({
	backgroundColor: theme.palette.secondary.white,
	borderRadius: "30px",
	boxShadow: "0px 10px 85px rgba(0, 0, 0, 0.09)",
	padding: "30px",
}));
