import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import styled from "@emotion/styled";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(4),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(3),
  },
}));

const BootstrapDialogTitle = props => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function DeleteHandlerModal({
  isOpen,
  onCancel,
  onOk,
  text,
  okText,
  cancelText,
  messageType,
  style,
  heading,
}) {
  const handleClose = () => {
    onCancel();
  };

  return (
    <div>
      <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={isOpen}
        PaperProps={{
          style: { borderRadius: 30, ...style, maxWidth: "400px" },
        }}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          sx={{ my: heading ? 0 : 1 }}
        >
          <Typography variant="title_large">{heading}</Typography>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Typography variant="title_medium">{text}</Typography>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            spacing={1}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Grid item xs={6} sm={3}>
              <Button
                variant="nofill"
                onClick={onCancel}
                sx={{
                  width: "max-content",

                  whiteSpace: "nowrap",
                }}
              >
                {cancelText}
              </Button>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Button
                color={messageType}
                onClick={onOk}
                sx={{ width: "max-content" }}
              >
                {okText}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
