import * as actionTypes from "./actionTypes";

export const setEmailToken = (payload) => {
	return (dispatch) => {
		dispatch({
			type: actionTypes.SET_EMAIL_TOKEN,
			payload,
		});
	};
};
