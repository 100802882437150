import React from "react";
import { Stack, Grid, useMediaQuery, Box } from "@mui/material";
import { useSelector } from "react-redux";
import CreateResearchCurator from "../../../components/Workspace/Sidebar/RightSidebar/components/CreateResearchCurator";
import CommentFeed from "../../CommentFeed";

export default function ResearchCuratorNoteDetails() {
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("md"));
  const startupData = useSelector(store => store?.auth?.companyData);
  const userDataObj = useSelector(store => store?.auth?.userData);
  const pathname = window.location.pathname;
  return (
    <Stack
      sx={theme => ({
        height: "100%",
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        borderRadius: isSmallScreen ? 0 : "8px",
        background: theme.palette.secondary.white,
      })}
    >
      <Grid
        sx={theme => ({
          maxWidth: theme.breakpoints.values.xl,
          flexGrow: 1,
          margin: "auto",
          display: "flex",
          background: theme.palette.secondary.white,
          borderRadius: isSmallScreen ? 0 : "8px",
          p: 4,
        })}
        flexDirection={"column"}
        container
      >
        <Box>
          <CreateResearchCurator
            userDataObj={userDataObj}
            startupData={startupData}
            isExpandedView={true}
            isDetailView={true}
            researchCuratorId={pathname.split("/")[3]}
          />
          <Box sx={{ px: 3, pt: 2 }}>
            <CommentFeed noteId={pathname.split("/")[3]} />
          </Box>
        </Box>
      </Grid>
    </Stack>
  );
}
