import React, { Fragment, useEffect, useState } from "react";
import { Box, Tabs, Tab, Typography } from "@mui/material";
import { useSelector } from "react-redux";

import services from "../../services";
import StartupProfile from "../../containers/ScoutingReportView/FoundingTeam/StartupProfile";
import FoundingTeamVideosView from "../../containers/ScoutingReportView/FoundingTeam/FoundingTeamVideos/FoundingTeamVideosView";
import MeetTheTeam from "../../containers/ScoutingReportView/FoundingTeam/MeetTheTeam";
import FounderComment from "../../containers/ScoutingReportView/FoundingTeam/FounderComment";
import InvestorInteractions from "../../containers/ScoutingReportView/FoundingTeam/InvestorInteractions";
import InvestorImpressions from "../../containers/ScoutingReportView/FoundingTeam/InvestorImpressions";
import MentorInteractions from "../../containers/ScoutingReportView/FoundingTeam/MentorComments";
import TamSamSomCard from "../../containers/ScoutingReportView/MarketOpportunity/TamSamSomCard";
import ProductMarketFitCard from "../../containers/ScoutingReportView/ProductMarketFit/ProductMarketFitCard";
import BackOfficeCard from "../../containers/ScoutingReportView/BackOfficeReadiness/BackOfficeCard";
import BusinessContent from "../../containers/ScoutingReportView/BackOfficeReadiness/BusinessContent";
import PersonaBuilderMarketOpportunity from "../../containers/ScoutingReportView/MarketOpportunity/PersonaBuilderView";
import FitContent from "../../containers/ScoutingReportView/ProductMarketFit/FitContent";
import TooltipWrapper from "../../common/Tooltip";
import MarketOppTab from "../../containers/ScoutingReportView/MarketOpportunity";

const SCOUTING_REPORT_TABS = [
	{ id: 0, title: "Founding Team", disabled: false },
	{ id: 1, title: "Market Opportunity", disabled: false },
	{ id: 2, title: "Product Market Fit", disabled: false },
	{ id: 3, title: "Back Office Readiness", disabled: true },
	{ id: 4, title: "Growth", disabled: true },
];

export default function ScoutingReportController({ onClose }) {
	const [currentReport, setCurrentReport] = useState(0);
	const openStartupObj = useSelector((state) => state?.investorDashboard?.openStartupObj);

	const handleChange = (event, newValue) => {
		setCurrentReport(newValue);
	};

	return (
		<Box>
			<Box onClose={onClose} px="60px" mb="29px">
				<Tabs
					variant="scrollable"
					scrollButtons="auto"
					allowScrollButtonsMobile
					value={currentReport}
					onChange={handleChange}
					sx={{
						alignItems: "stretch",
						"& .MuiTabs-flexContainer": {
							gap: 5,
						},
						"& .MuiTabs-indicator": {
							backgroundColor: (theme) => theme.palette.primary.main,
							borderRadius: "5px",
							height: "4px",
						},
						"& .MuiTabs-scrollButtons": {
							color: (theme) => theme.palette.primary.main,
							"& > svg": {
								height: "30px",
								width: "30px",
							},
						},
					}}
				>
					{SCOUTING_REPORT_TABS.map((item, index) => {
						const tabItem = (
							<Tab
								selected={currentReport === item.id}
								onClick={(event) => {
									handleChange(event, index);
								}}
								disabled={item.disabled}
								disableRipple
								key={item.id}
								sx={{
									textTransform: "unset",
									textAlign: "left",
									p: 0,
									pb: 1,
									whiteSpace: "nowrap",
									maxWidth: "unset",
									opacity: ".6",
									filter: "grayscale(100%)",
									"&.Mui-selected": {
										opacity: 1,
										filter: "grayscale(0%)",
									},
								}}
								label={
									<Typography
										variant="count_title"
										sx={{
											color: "black",
											fontWeight: "600",
										}}
									>
										{item.title}
									</Typography>
								}
							/>
						);
						return <Fragment key={index}>{item.disabled ? <TooltipWrapper>{tabItem}</TooltipWrapper> : tabItem}</Fragment>;
					})}
				</Tabs>
			</Box>
			{currentReport === 0 && (
				<Box>
					<StartupProfile />
					<FounderComment />
					<FoundingTeamVideosView />
					<MeetTheTeam startup={openStartupObj} />
					<InvestorImpressions />
					{/*<InvestorInteractions />*/}
					{/* 
						// TODO -- We do not have the data to load any mentor interactions at this time.
					*/}
					{/*<MentorInteractions />*/}
				</Box>
			)}
			{currentReport === 1 && (
				<Box>
					<MarketOppTab />
				</Box>
			)}
			{currentReport === 2 && (
				<Box>
					<ProductMarketFitCard startup={openStartupObj} />
					<FitContent />
				</Box>
			)}
			{currentReport === 3 && (
				<Box>
					<BackOfficeCard startup={openStartupObj} />
					<BusinessContent />
				</Box>
			)}
			{currentReport === 4 && <h1>Social Insights</h1>}
		</Box>
	);
}
