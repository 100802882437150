import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";

import { WorkspaceStepIcon } from "../../../common/icons";
import theme from "../../../constants/Theme";
import MessageHandlerModal from "../../../common/modal/MessageHandler";
import messages from "../../../constants/messages";
import { useSelector, useDispatch } from "react-redux";
import { handleMixPanel } from "../../../utils/mixPanelEventHandling";
import { setMixpanelData } from "../../../modules/actions";

const WorkspaceStepper = ({
  activeStep,
  setActiveStep,
  steps,
  nonLinear,
  linear,
  type,
  ActiveIcon,
  DefaultIcon,
  CompletedIcon,
  fixedWidth,
  LastStepIcon,
  powerUpExcercises,
  isSummaryExists,
  shouldShowPrompt,
}) => {

  const [hoveredStep, setHoveredStep] = useState(null);
  const [showPromptDialog, setShowPromptDialog] = useState(false);
  const [nextStepId, setNextStepId] = useState(null);
  const stepperRef = useRef();
  const powerUpId = useSelector((state) => state?.mixpanel?.powerUpId);
  const powerUpName = useSelector((state) => state?.mixpanel?.powerUpName);
  const dispatch = useDispatch();

  const StepIcon = props => {
    const { active, completed, error, icon } = props;
    if (type === "POWERUP") {
      if (icon === steps.length && isSummaryExists) {
        return <LastStepIcon active={allStepsFilled()} />;
      } else {
        if (active && !isStepFilled(steps[icon - 1])) {
          return <ActiveIcon active number={icon} />;
        } else if (isStepFilled(steps[icon - 1])) {
          return <CompletedIcon active />;
        } else {
          return <DefaultIcon number={icon} />;
        }
      }
    } else {
      if (active) {
        return <ActiveIcon active number={icon} />;
      } else {
        return <DefaultIcon number={icon} />;
      }
    }
  };

  const isStepFilled = step => {
    const filledStep = powerUpExcercises.find(
      excercise => excercise.stepNo === step.stepNo
    );
    if (filledStep) {
      return true;
    } else {
      return false;
    }
  };

  const allStepsFilled = () => {
    return powerUpExcercises.length === steps.length - 1;
  };

  const handleStep = (index, step) => {
    if (!shouldShowPrompt()) {
      if (index === steps.length - 1 && allStepsFilled()) {
        setActiveStep(index);
      } else {
        if (isStepFilled(step)) {
          setActiveStep(index);
        }
      }
    } else {
      if (isStepFilled(step)) {
        setNextStepId(index);
        setShowPromptDialog(true);
      }
    }
  };

  const handleCancelPrompt = () => {
    setShowPromptDialog(false);
  };

  const handleDiscard = () => {
    setActiveStep(nextStepId);
    setNextStepId(null);
    setShowPromptDialog(false);
  };

  const handleStepHover = index => {
    setHoveredStep(index);
  };
  const handleHoverRevert = () => {
    setHoveredStep(activeStep);
  };

  useEffect(() => {
    setHoveredStep(activeStep);
    if (steps[activeStep]?.title === "Complete") {
      stepperRef.current.scrollLeft = stepperRef.current.scrollWidth - 10;
    } else {
      stepperRef.current.scrollLeft =
        activeStep * (stepperRef.current.scrollWidth / (steps.length + 1));
    }
    
    let step = activeStep + 1;

    dispatch(
      setMixpanelData({
        powerUpStep: step,
        powerUpPercentComplete: activeStep / steps.length
      })
    );

    handleMixPanel("Powerup Viewed", {
      "Step": step,
      "Powerup ID": powerUpId,
      "Powerup Name": powerUpName
    });
  }, [activeStep]);

  return (
    <>
      <MessageHandlerModal
        text={messages.UNSAVED_CHANGES_PROMPT_MESSAGE}
        isOpen={showPromptDialog}
        onOk={handleDiscard}
        okText={"Discard"}
        heading={"Discard changes"}
        onCancel={handleCancelPrompt}
        cancelText={"No thanks"}
        messageType="primary"
      />
      <Box
        sx={{
          [theme.breakpoints.down("md")]: {
            position: "absolute",
            overflowX: "scroll",
            width: "100%",
            "scrollbar-width": "none",
          },
        }}
        ref={stepperRef}
      >
        <Stepper
          nonLinear={nonLinear}
          linear={linear}
          activeStep={activeStep}
          connector={<StepConnector />}
          sx={{
            [theme.breakpoints.down("md")]: {
              width: "200%",
            },
          }}
        >
          {steps.map((step, index) => (
            <Step
              key={index}
              onClick={() => handleStep(index, step)}
              sx={{ cursor: "pointer", pl: index === 0 ? 0 : 1 }}
              onMouseEnter={() => handleStepHover(index)}
              onMouseLeave={() => handleHoverRevert()}
            >
              <StepLabel StepIconComponent={StepIcon}>
                {activeStep === index &&
                (hoveredStep === activeStep || hoveredStep === null) ? (
                  <Typography variant={"title_medium"} color={"text.primary"}>
                    {step.title}
                  </Typography>
                ) : (
                  ""
                )}
                {hoveredStep === index && hoveredStep !== activeStep ? (
                  <Typography variant={"title_medium"} color={"secondary.dark"}>
                    {step.title}
                  </Typography>
                ) : (
                  ""
                )}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
    </>
  );
};

{
  WorkspaceStepper.propTypes = {
    nonLinear: PropTypes.bool,
    linear: PropTypes.bool,
    ActiveIcon: PropTypes.func,
    DefaultIcon: PropTypes.func,
    CompletedIcon: PropTypes.func,
    steps: PropTypes.array,
    type: PropTypes.string,
    activeStep: PropTypes.number,
    setActiveStep: PropTypes.func,
    fixedWidth: PropTypes.number,
    LastStepIcon: PropTypes.func,
  };
  WorkspaceStepper.defaultProps = {
    nonLinear: true,
    linear: false,
    ActiveIcon: WorkspaceStepIcon,
    DefaultIcon: WorkspaceStepIcon,
    CompletedIcon: null,
    steps: [],
    type: "POWERUP",
    activeStep: 0,
    setActiveStep: null,
    fixedWidth: 1300,
    LastStepIcon: null,
  };
}

export default WorkspaceStepper;
