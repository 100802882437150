import React, { useState, useEffect } from 'react'

import { convertFromRaw } from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'

// components
import {
	Card,
	Stack,
	Typography,
	TextField,
	FormControl,
	FormGroup,
	FormControlLabel,
	RadioGroup,
	Radio,
	Checkbox,
	Select,
	MenuItem,
	TableContainer,
	Table,
	TableHead,
	TableRow,
	TableBody,
	TableCell, InputLabel, Slider as Slide
} from '@mui/material'

import Editor from '../Editor'

import stateToHTMLOptions from './state2html'

function InputGroup ({ title, description, children }) {
	description = description ? JSON.parse(description) : ''
	return <Stack gap={2}>
		<Typography variant={'h4'}>{title}</Typography>
		{description && !(description?.blocks?.length === 1 && description?.blocks[0]?.text === '' && description?.blocks[0]?.type === 'unstyled') && <div
			dangerouslySetInnerHTML={{
				__html: stateToHTML(convertFromRaw(description), stateToHTMLOptions)
			}}
		/>}
		{children}
	</Stack>
}

export function ShortAnswer ({ id, title, description, required, prop: { validation, placeholder }, answer, onAnswerChange }) {
	let type
	switch (validation) {
		case 'none':
			type = 'text'
			break
		default:
			type = validation
	}

	const [ value, setValue ] = useState(answer?.value ?? '')
	useEffect(() => {
		setValue(answer?.value ?? '')
	}, [ answer?.value ?? '' ])

	const onChange = (value) => {
		onAnswerChange({
			type: 'line', id, title, description,
			value
		})
	}

	return <InputGroup title={title + (!!required ? ' *' : '')} description={description}>
		<TextField
			type={type} label={'Your Answer'} placeholder={placeholder} required={!!required}
			value={value} onChange={(e) => setValue(e.target.value)} onBlur={(e) => onChange(e.target.value)}
		/>
	</InputGroup>
}

export function Paragraph ({ id, title, description, required, prop, answer, onAnswerChange, uploadMedia }) {
	//const [ value, setValue ] = useState(answer?.value ?? '')
	// useEffect(() => {
	// 	setValue(answer?.value ?? '')
	// }, [ answer?.value ?? '' ])

	const onChange = (value) => {
		onAnswerChange({
			type: 'field', id, title, description,
			value
		})
	}

	return <InputGroup title={title + (!!required ? ' *' : '')} description={description}>
		<Editor
			label={'Your Answer'}
			defaultValue={answer?.value}
			onSave={onChange}
			getMediaUrl={(file) => uploadMedia && uploadMedia({ file })}
		/>
		{/*<TextField*/}
		{/*	multiline type="text" label={'Your Answer'} required={!!required}*/}
		{/*	value={value} onChange={(e) => setValue(e.target.value)} onBlur={(e) => onChange(e.target.value)}*/}
		{/*/>*/}
	</InputGroup>
}

const opposite = (display) => {
	switch (display) {
		case 'required':
			return 'optional'
		case 'optional':
			return 'required'
		case 'visible':
			return 'hidden'
		case 'hidden':
			return 'visible'
		default:
			return display
	}
}

export function Dropdown ({ id, title, description, required, prop: { options }, answer, onAnswerChange, onActionChange }) {
	const value = answer?.value ?? ''

	const onChange = (oldIndex, index) => {
		if (oldIndex !== '') {
			const option = options[oldIndex]
			if (option.type !== 'none') {
				let { type, target, display } = option
				onActionChange({ type, target, display: opposite(display) })
			}
		}

		const option = options[index]
		if (option.type !== 'none') {
			const { type, target, display } = option
			onActionChange({ type, target, display })
		}
		onAnswerChange({
			type: 'dropdown', id, title, description,
			optionTitle: option.title,
			value: index
		})
	}

	return <InputGroup title={title + (!!required ? ' *' : '')} description={description}>
		<FormControl>
			<InputLabel id={`label-${id}`}>Your Answer{!!required && ' *'}</InputLabel>
			<Select
				labelId={`label-${id}`}
				label={`Your Answer${!!required && ' *'}`}
				required={!!required}
				value={value}
				onChange={(e) => onChange(value, Number(e.target.value))}>
				{options.map(({ title }, index) => <MenuItem key={index} value={index} selected={index === value}>{title}</MenuItem>)}
			</Select>
		</FormControl>
	</InputGroup>
}

export function MultipleChoice ({ id, title, description, required, prop: { options }, answer, onAnswerChange, onActionChange }) {
	const value = answer?.value ?? ''

	const onChange = (oldIndex, index) => {
		if (oldIndex !== '') {
			const option = options[oldIndex]
			if (option.type !== 'none') {
				let { type, target, display } = option
				onActionChange({ type, target, display: opposite(display) })
			}
		}

		const option = options[index]
		if (option.type !== 'none') {
			const { type, target, display } = option
			onActionChange({ type, target, display })
		}
		onAnswerChange({
			type: 'choices', id, title, description,
			optionTitle: option.title,
			value: index
		})
	}

	return <InputGroup title={title + (!!required ? ' *' : '')} description={description}>
		<RadioGroup name={id} value={value} onChange={(e) => onChange(value, Number(e.target.value))}
					sx={options.length > 6 && { display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(12rem, 1fr))', gap: '2rem' }}>
			{options?.map(({ title }, index) => <FormControlLabel key={index} value={index} label={title} control={<Radio/>}/>)}
		</RadioGroup>
	</InputGroup>
}

export function Checkboxes ({ id, title, description, required, prop: { options }, answer, onAnswerChange, onActionChange }) {
	const onChange = (index, checked) => {
		const option = options[index]
		if (option.type !== 'none') {
			const { type, target, display } = option
			onActionChange({ type, target, display: checked ? display : opposite(display) })
		}
		onAnswerChange({
			type: 'checkboxes', id, title, description,
			options: options.map(({ title }, i) => ({
				title,
				value: i === index ? checked : !!answer?.options[i]?.value
			}))
		})
	}

	return <InputGroup title={title + (!!required ? ' *' : '')} description={description}>
		<FormGroup sx={options.length > 6 && {
			display: 'grid',
			gridTemplateColumns: 'repeat(auto-fill, minmax(12rem, 1fr))',
			gap: '2rem'
		}}>
			{options.map(({ title }, index) => <FormControlLabel key={index} value={index} label={title} control={<Checkbox
				checked={!!answer?.options[index]?.value}
				onChange={(e) => onChange(index, e.target.checked)}
			/>}/>)}
		</FormGroup>
	</InputGroup>
}

export function MultipleChoiceGrid ({ id, title, description, required, prop: { rows, cols }, answer, onAnswerChange }) {
	const onChange = (si, sj) => {
		onAnswerChange({
			type: 'choicegrid', id, title, description,
			rows: rows.map(({ title }, i) => ({
				title,
				cols: cols.map(({ title }, j) => ({
					title,
					value: i === si ? j === sj : !!answer?.rows[i]?.cols[j]?.value
				}))
			}))
		})
	}

	return <InputGroup title={title + (!!required ? ' *' : '')} description={description}>
		<TableContainer component={Card} variant={'outlined'}>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell/>
						{cols.map(({ title }, index) => <TableCell key={index} align={'center'}><Typography>{title}</Typography></TableCell>)}
					</TableRow>
				</TableHead>
				<TableBody>
					{rows.map(({ title }, i) => <TableRow key={i}>
						<TableCell>
							<Typography>{title}</Typography>
						</TableCell>
						{cols.map((_, j) => <TableCell key={j} align={'center'}>
							<Radio checked={!!answer?.rows[i]?.cols[j]?.value} onChange={() => onChange(i, j)}/>
						</TableCell>)}
					</TableRow>)}
				</TableBody>
			</Table>
		</TableContainer>
	</InputGroup>
}

export function CheckboxGrid ({ id, title, description, required, prop: { rows, cols }, answer, onAnswerChange }) {
	const onChange = (si, sj) => {
		onAnswerChange({
			type: 'checkboxgrid', id, title, description,
			rows: rows.map(({ title }, i) => ({
				title,
				cols: cols.map(({ title }, j) => ({
					title,
					value: i === si && j === sj ? !answer?.rows[i]?.cols[j]?.value : !!answer?.rows[i]?.cols[j]?.value
				}))
			}))
		})
	}

	return <InputGroup title={title + (!!required ? ' *' : '')} description={description}>
		<TableContainer component={Card} variant={'outlined'}>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell/>
						{cols.map(({ title }, index) => <TableCell key={index} align={'center'}><Typography>{title}</Typography></TableCell>)}
					</TableRow>
				</TableHead>
				<TableBody>
					{rows.map(({ title }, i) => <TableRow key={i}>
						<TableCell>
							<Typography>{title}</Typography>
						</TableCell>
						{cols.map((_, j) => <TableCell key={j} align={'center'}>
							<Checkbox checked={!!answer?.rows[i]?.cols[j]?.value} onChange={() => onChange(i, j)}/>
						</TableCell>)}
					</TableRow>)}
				</TableBody>
			</Table>
		</TableContainer>
	</InputGroup>
}

export function Slider ({ id, title, description, required, answer, onAnswerChange, prop: { min, max, unit } }) {
	const [ value, setValue ] = useState(answer?.value ?? ((min + max) / 2))
	useEffect(() => {
		setValue(answer?.value ?? ((min + max) / 2))
	}, [ answer?.value ])

	const onChange = () => {
		onAnswerChange({
			type: 'slider', id, title, description,
			value
		})
	}

	function valuetext (value) {
		return value + ' ' + unit
	}

	return <InputGroup title={title + (!!required ? ' *' : '')} description={description}>
		<Slide min={min} max={max} value={value} getAriaValueText={valuetext} valueLabelDisplay="on"
			   onChange={(e) => setValue(e.target.value)} onBlur={onChange}/>
		<Stack direction={'row'} mt={-2} justifyContent={'space-between'}>
			<Typography>{min} {unit}</Typography>
			<Typography>{max} {unit}</Typography>
		</Stack>
	</InputGroup>
}

export function Blank ({ id, title, description }) {
	return <InputGroup title={title} description={description}/>
}
