import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Stack, Grid, Box, CircularProgress, Button } from "@mui/material";
import { ProfileContentWrapper, WorkContentWrapper } from "../../components/Workspace/PageContentWrapper";
import MyProfile from "./MyProfile";
import MyWork from "./MyWork";
import EditForm from "./EditForm";
import constants from "../../constants";
import theme from "../../constants/Theme";
import { REGEX } from "../../constants/regex";
import messages from "../../constants/messages";
import { USAMAP } from "../../constants/image";
import services from "../../services";
import { toastContainer } from "../../utils/toast";
import { matchPath, useHistory, useLocation, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setAuthData } from "../../modules/actions/authActions";
import { USER_TYPES } from "../../utils/userTypes";
import { getStartupData } from "./functions";
import MessageHandlerModal from "../../common/modal/MessageHandler";
import moment from "moment/moment";
import { handleMixPanel } from "../../utils/mixPanelEventHandling";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ROUTES_PATH from "../../constants/routes";

export default function MyStartup() {
	const [currenttab, setcurrenttab] = useState(constants.MY_STARTUP_TAB_ITEMS[0].key);
	const tabitems = constants.MY_STARTUP_TAB_ITEMS;
	const [isEditOpen, setIsEditOpen] = useState(false);
	const [isInvestorView, setIsInvestorView] = useState(false);
	const [isPitchDeckView, setispitchdeckview] = useState(false);
	const [companyData, setCompanyData] = useState("");
	const [about, setAbout] = useState("");
	const [logo, setLogo] = useState("");
	const [file, setFile] = useState("");
	const onboardingData = useSelector((state) => state.onboarding);
	const userType = useSelector((store) => store?.auth?.userType);
	const [isUploading, setIsUploading] = useState(false);
	const [openVideoUpload, setOpenVideoUpload] = useState(false);
	const [harmonicUpdate, setHarmonicUpdate] = useState(false);

	const [link, setLink] = useState("");
	const [companyDataError, setCompanyDataError] = useState({
		companyNameError: "",
		taglineError: "",
		productOfferingError: "",
		locationError: "",
		startupSizeError: "",
		fundingTypeError: "",
		companySiteError: "",
		aboutError: "",
		logoError: "",
		videoError: "",
		pitchDeckError: "",
		linkedinError: "",
		targetMarket: "",
		stateIncorporation: "",
		stateError: "",
		cityError: "",
		phoneError: "",
	});
	const [showOther, setShowOther] = useState(false);
	const [showState, setShowState] = useState(onboardingData?.companyLocation?.state);
	const [zipCode, setZipCode] = useState(onboardingData?.companyLocation?.zipCode);
	const [state, setState] = useState(onboardingData?.companyLocation?.state);
	const [stateImage, setStateImage] = useState(onboardingData?.companyLocation?.stateImage);
	const [location, setLocation] = useState({
		loaded: false,
		coordinates: {
			latitude: onboardingData?.companyLocation?.lat,
			longitude: onboardingData?.companyLocation?.lng,
		},
	});
	const [showInsights, setShowInsights] = useState(true);
	const [saveDisabled, setSaveDisabled] = useState(true);
	const [loading, setLoading] = useState(false);
	const [compLocation, setCompLocation] = useState("");
	const [value, setValue] = React.useState(0);
	const [saveLoading, setSaveLoading] = useState(false);
	const [browseFlag, setBrowseFlag] = useState(false);
	const [score, setScore] = useState("");
	const urllocation = useLocation();
	const history = useHistory();
	const pathName = useLocation();
	const [showArchivedPage, setShowArchivedPage] = useState(false);
	const [showVersionsPage, setShowVersionsPage] = useState(false);
	const [showDeleteButton, setShowDeleteButton] = useState();
	const [formLoading, setFormLoading] = useState(true);
	const dispatch = useDispatch();

	const [videoUrl, setVideoUrl] = useState("");
	const [payload, setPayload] = useState("");
	const [vidLoading, setVidLoading] = useState(false);
	const [browseVidFlag, setBrowseVidFlag] = useState(false);
	const [postalCode, setPostalCode] = useState("");
	const params = useParams();
	const companyId = params.id;
	const [userData, setUserData] = useState(null);
	const [harmonicData, setHarmonicData] = useState("");
	const [openHarmonic, setHarmonicOpen] = useState(false);
	const [discard, setDiscard] = useState(false);
	const [incorporatedStatusInDb, setIncorporatedStatusInDb] = useState("");
	const authUserData = useSelector((store) => ({
		userId: store?.auth?.userId,
		userName: store?.auth?.userName,
	}));

	// This is to track the state change of edit.
	const prevStateValue = useRef(isEditOpen);

	useEffect(() => {
		// If the URL is /edit then set edit as true.
		const match = matchPath(urllocation.pathname, { path: ROUTES_PATH.EDIT_MY_STARTUP_PROFILE });
		if (match) {
			// If the route is /edit for startup profile; ensure proper user type -- otherwise reroute to account edit
			if (userType === USER_TYPES.STARTUP_OWNER || userType === USER_TYPES.STARTUP_USER || userType === USER_TYPES.STUDENT_USER) {
				setValue(0);
			} else {
				history.push(ROUTES_PATH.MY_ACCOUNT_PROFILE);
			}
		} else {
			if (pathName?.pathname?.split("/")[2] === "myprofile") {
				setValue(0);
			} else {
				if (pathName?.state?.archived) {
					setShowArchivedPage(true);
				} else if (pathName?.state?.showVersionsPage) {
					setShowVersionsPage(true);
				}
				setValue(1);
			}
		}
	}, []);

	useEffect(() => {
		// If the URL is /edit then assume to use the new read-only views created.
		const match = matchPath(urllocation.pathname, { path: ROUTES_PATH.EDIT_MY_STARTUP_PROFILE });
		if (match && prevStateValue.current && !isEditOpen) {
			history.push(ROUTES_PATH.MY_STARTUP_PROFILE);
		}
		prevStateValue.current = isEditOpen;
	}, [isEditOpen]);

	useEffect(() => {
		// Endpoint to get investor impression for a startup
		if (companyId && userType === USER_TYPES.INVESTOR_USER) {
			services
				.updateStreakActionApi({
					actionType: "INVESTOR_VIEW",
					actionOnId: companyId,
					actionOnType: "STARTUP",
				})
				.then()
				.catch();
		}

		//Get the tab from url params
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		});
	}, []);

	useEffect(() => {
		if (companyId) {
			getStartupData(companyId)
				.then((result) => {
					setCompanyData(result);
					setPayload(result);
					setAbout(result.about);
					setispitchdeckview(result.pitchDeckView);
					setIsInvestorView(result.investorView);
					setShowInsights(result.showInsights);
					setIncorporatedStatusInDb(result?.incorporatedStatus);
					setFormLoading(false);
					services.getCandidateDetails(result.founderDetails.id).then((res) => {
						const data = res?.data?.data;

						setUserData(data);
					});
				})
				.catch((error) => {
					setFormLoading(false);
					console.log(error);
				});
		} else {
			handleStartupDetails();
		}
	}, []);

	const handleStartupDetails = () => {
		//Get data from API

		if (userType === USER_TYPES.STARTUP_OWNER || userType === USER_TYPES.STARTUP_USER || userType === USER_TYPES.STUDENT_USER) {
			setFormLoading(true);
			services
				.getCompanyProfile()
				.then((response) => {
					let services = response.data;
					if (services.message === "Success") {
						setCompanyData(services.data);
						setPayload(services.data);
						setAbout(services.data.about);
						setispitchdeckview(services.data.pitchDeckView);
						setIsInvestorView(services.data.investorView);
						setShowInsights(services.data.showInsights);
						setPostalCode(services.data.zipcode);
						setIncorporatedStatusInDb(response?.data?.data?.incorporatedStatus);

						setFormLoading(false);
					}
				})
				.catch((error) => {
					setFormLoading(false);
				});
		} else {
			setFormLoading(false);
		}
	};

	useEffect(() => {
		if (payload) {
			if (
				payload["companyName"] &&
				payload["zipcode"] &&
				payload["fundingType"]
				// payload["state"] &&
				// payload["stateOfIncorporation"] &&
				// payload["targetMarket"]
			) {
				setSaveDisabled(false);
			} else {
				setSaveDisabled(true);
			}
		}
	}, [payload]);

	const handleEditForm = () => {
		setIsEditOpen(!isEditOpen);
		setBrowseFlag(false);
		setBrowseVidFlag(false);
		//handleStartupDetails();
		setCompanyDataError({
			companyNameError: "",
			taglineError: "",
			productOfferingError: "",
			locationError: "",
			startupSizeError: "",
			fundingTypeError: "",
			companySiteError: "",
			aboutError: "",
			logoError: "",
			videoError: "",
			pitchDeckError: "",
			linkedinError: "",
			targetMarket: "",
			stateIncorporation: "",
			stateError: "",
			cityError: "",
			phoneError: "",
		});
		if (isEditOpen) {
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: "smooth",
			});
		}

		handleMixPanel("Startup Profile Edited", {
			userName: authUserData.userName,
			userId: authUserData.userId,
		});
	};

	const handleinvestorsView = () => {
		setIsInvestorView(!isInvestorView);
	};

	const handlePitchDeckView = () => {
		setispitchdeckview(!isPitchDeckView);
	};

	const handleChange = (e, type) => {
		const currentYear = new Date().getFullYear();

		setDiscard(true);
		if (type === "productOffering" && e.target.value === "Other") {
			setShowOther(true);
		}
		if (type === "about" || type === "picture" || type === "video" || type === "foundedYear") {
			if (type === "foundedYear") {
				const data = { ...payload };

				data["foundedYear"] = e.getFullYear();
				data["foundedDate"] = e;
				setPayload(data);
			} else {
				const data = { ...payload };
				if (type === "about") {
					data[type] = e === "<p><br></p>" ? "" : e;
				} else {
					data[type] = e;
				}
				setPayload(data);
			}
		} else if (type == "zipcode") {
			if (!e.target.value.match(REGEX.SPECIAL_CHARACTERS)) {
				const data = { ...payload };
				if (!e.target.value) {
					setCompanyDataError((prevState) => ({
						...prevState,
						locationError: messages.LOCATION_INVALID,
					}));
					setCompanyDataError((prevState) => ({
						...prevState,
						stateError: messages.LOCATION_INVALID,
					}));
					setCompanyDataError((prevState) => ({
						...prevState,
						cityError: messages.LOCATION_INVALID,
					}));
					data["state"] = "";
					data["city"] = "";
					data["zipcode"] = e.target.value;
					setPayload(data);
				} else {
					handleZipCodeChange(e);
					setCompLocation(e.target.value);
					data["zipcode"] = e.target.value;
					setPayload(data);

					setCompanyDataError((prevState) => ({
						...prevState,
						locationError: "",
					}));
					setCompanyDataError((prevState) => ({
						...prevState,
						stateError: "",
					}));
					setCompanyDataError((prevState) => ({
						...prevState,
						cityError: "",
					}));
				}
			} else {
				setCompanyDataError((prevState) => ({
					...prevState,
					locationError: messages.LOCATION_INVALID,
				}));
				setCompanyDataError((prevState) => ({
					...prevState,
					stateError: messages.LOCATION_INVALID,
				}));
				setCompanyDataError((prevState) => ({
					...prevState,
					cityError: messages.LOCATION_INVALID,
				}));
				//setSaveDisabled(true);
			}
		} else if (type == "fundingType") {
			const data = { ...payload };
			data[type] = e.target.value.length > 2 ? e.target.value : "";
			setPayload(data);
			if (e.target.value.length > 2) {
				setCompanyDataError((prevState) => ({
					...prevState,
					fundingTypeError: "",
				}));
				setSaveDisabled(false);
			} else {
				setCompanyDataError((prevState) => ({
					...prevState,
					fundingTypeError: "Funding Type cannot be empty.",
				}));
				setSaveDisabled(true);
			}
		} else if (type == "industry") {
			const data = { ...payload };
			data[type] = e.target.value;
			setPayload(data);
		} else if (type == "city" || type == "state" || type === "incorporatedStatus") {
			const data = { ...payload };
			data[type] = e.target.value;
			setPayload(data);
		} else if (type === "revenue" || type === "financing" || type === "currentStage" || type === "seeking") {
			const data = { ...payload };
			data[type] = e.target.value;
			setPayload(data);
		} else if (type == "companyName") {
			const data = { ...payload };
			data[type] = e.target.value;
			data[type] = e.target.value;
			setPayload(data);
		} else if (type == "expertise") {
			const data = { ...payload };
			data[type] = e.target.value;
			setPayload(data);
		} else if (type == "capitalRaised" || type == "ask" || type == "companySize") {
			const data = { ...payload };
			const x = e.target.value;

			var regex = /^[0-9]+$/;
			if (x.match(regex)) {
				data[type] = x;
			} else {
				data[type] = "";
			}
			setPayload(data);
		} else if (type === "phone") {
			const data = { ...payload };
			data[type] = e.target.value;
			setPayload(data);
		} else if (type === "websiteLink") {
			const data = { ...payload };
			let webStr = e.target.value;

			if (webStr) {
				webStr = webStr.replace("https://", "").replace("http://", "");
				data[type] = "https://" + webStr;
			} else {
				data[type] = "";
			}
			setPayload(data);
		} else {
			const data = { ...payload };
			data[type] = e.target.value;
			setPayload(data);
		}
	};

	const inputChange = () => {
		//setSaveDisabled(false);
	};

	const handleSave = async (eventType, payloadObject) => {
		if (eventType && eventType == "ai_assist") {
			console.log("Inside handle save");
			setSaveLoading(true);
			// setSaveDisabled(true);
			// if (handleValidations()) {
			let resp = await handleAISave();
			if (incorporatedStatusInDb !== resp?.data?.incorporatedStatus) {
				const companyName = resp?.data?.companyName || "";
				const incorporatedStatus = resp?.data?.incorporatedStatus || "";
				setIncorporatedStatusInDb(resp?.data?.incorporatedStatus);
				handleMixPanel(`${companyName} incorporated status updated`, {
					incorporatedStatus,
				});
			}
			setSaveLoading(false);
			setSaveDisabled(false);
			return resp;
			// } else {
			// 	setSaveLoading(false);
			// 	setSaveDisabled(false);
			// }
		} else {
			// if (handleValidations(payloadObject)) {
			// 	setSaveDisabled(true);
			handlePayload(true, payloadObject);
			// }
		}
	};

	const handleValidations = (data) => {
		let payloadData = data ? data : payload;
		let pass = true;
		// if (
		//   !payload["companyName"] ||
		//   payload["companyName"] == ""
		// ) {
		//   alert("co nanme");
		//   setCompanyDataError(prevState => ({
		//     ...prevState,
		//     companyNameError: messages.COMPANY_NAME_BLANK,
		//   }));
		//   pass = false;
		// } else if (
		//   !payload["companyName"].match(REGEX.COMPANY_NAME)
		// ) {
		//   setCompanyDataError(prevState => ({
		//     ...prevState,
		//     companyNameError: messages.COMPANY_NAME_LENGTH_INVALID,
		//   }));
		//   pass = false;
		// } else {
		//   setCompanyDataError(prevState => ({
		//     ...prevState,
		//     companyNameError: "",
		//   }));
		// }

		// if (!payload["productOffering"] || payload["productOffering"] == "") {
		//   setCompanyDataError(prevState => ({
		//     ...prevState,
		//     productOfferingError: messages.PRODUCT_OFFERING_BLANK,
		//   }));
		//   pass = false;
		// } else {
		//   setCompanyDataError(prevState => ({
		//     ...prevState,
		//     productOfferingError: "",
		//   }));
		// }

		//if (
		//  !payload["state"] ||
		//  payload["state"] == ""
		//) {
		//  setCompanyDataError(prevState => ({
		//    ...prevState,
		//    locationError: messages.LOCATION_BLANK,
		//  }));
		//  pass = false;
		//} else {
		//  setCompanyDataError(prevState => ({
		//    ...prevState,
		//    locationError: "",
		//  }));
		//}

		if (!payloadData["fundingType"] || payloadData["fundingType"] == "") {
			//setCompanyDataError(prevState => ({
			//  ...prevState,
			//  fundingTypeError: "Funding type cannot be empty",
			//}));
			pass = false;
		} else {
			setCompanyDataError((prevState) => ({
				...prevState,
				fundingTypeError: "",
			}));
		}

		// if (!payloadData["companyName"] || payloadData["companyName"] == "") {
		// 	setCompanyDataError((prevState) => ({
		// 		...prevState,
		// 		companyNameError: messages.COMPANY_NAME_BLANK,
		// 	}));
		// 	pass = false;
		// } else {
		// 	setCompanyDataError((prevState) => ({
		// 		...prevState,
		// 		companyNameError: "",
		// 	}));
		// }

		// if (!payloadData["zipcode"] || payloadData["zipcode"] == "") {
		// 	setCompanyDataError((prevState) => ({
		// 		...prevState,
		// 		locationError: messages.LOCATION_INVALID,
		// 	}));
		// 	pass = false;
		// } else {
		// 	setCompanyDataError((prevState) => ({
		// 		...prevState,
		// 		locationError: "",
		// 	}));
		// }

		if (payloadData["foundedDate"]) {
			if (payloadData["foundedDate"].length > 0 && payloadData["foundedDate"].length < 4) {
				setCompanyDataError((prevState) => ({
					...prevState,
					foundingDateError: messages.FOUNDING_DATE_BLANK,
				}));
				pass = false;
			}
		} else {
			setCompanyDataError((prevState) => ({
				...prevState,
				foundingDateError: "",
			}));
		}

		if (!payloadData["incorporatedStatus"] || payloadData["incorporatedStatus"] == "") {
			setCompanyDataError((prevState) => ({
				...prevState,
				stateIncorporationError: messages.INCORPORATION_BLANK,
			}));
			pass = false;
		} else {
			setCompanyDataError((prevState) => ({
				...prevState,
				stateIncorporationError: "",
			}));
		}

		//if (!payload["linkedInUrl"] || payload["linkedInUrl"] == "") {
		//  setCompanyDataError(prevState => ({
		//    ...prevState,
		//    linkedinError: messages.INVALID_URL,
		//  }));
		//  pass = false;
		//} else if (
		//  payload["linkedInUrl"] &&
		//  !payload["linkedInUrl"].match(REGEX.VALID_URL)
		//) {
		//  setCompanyDataError(prevState => ({
		//    ...prevState,
		//    linkedinError: messages.INVALID_URL,
		//  }));
		//  pass = false;
		//} else {
		//  setCompanyDataError(prevState => ({
		//    ...prevState,
		//    linkedinError: "",
		//  }));
		//}

		if (!pass) {
			setSaveDisabled(true);
		} else {
			setSaveDisabled(false);
			return true;
		}
	};

	const handlePayload = (handleForm = true, data) => {
		let payloadData = data ? data : payload;

		setDiscard(false);

		const getLinkedInUrl = (url) => {
			if (url) {
				if (url.match(/^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(company\/)/gm)) {
					let cleanedLinkedInUrl = url.replace(
						/^(https:\/\/www\.linkedin\.com\/company\/.*?\/)(.*)https:\/\/www\.linkedin\.com\/company\/.*?\/(.*)$/,
						"$1$2"
					);

					return cleanedLinkedInUrl;
				} else {
					return "https://www.linkedin.com/company/" + url;
				}
			} else {
				return null;
			}
		};
		payloadData = {
			...payloadData,
			industry:
				payloadData?.industry?.length > 0
					? typeof payloadData?.industry === "string"
						? payloadData?.industry
						: JSON.stringify(payloadData?.industry)
					: "",
			linkedInUrl: getLinkedInUrl(payloadData.linkedInUrl),
			noOfCoFounders: payloadData?.noOfCoFounders ? payloadData?.noOfCoFounders : "0",
			investorView: isInvestorView,
		};

		setSaveLoading(true);
		services
			.updateCompanyProfile(payloadData)
			.then((response) => {
				if (response.data.message === "Success") {
					setSaveLoading(false);

					if (handleForm) {
						handleEditForm();
					}
					if (harmonicUpdate) {
						setHarmonicUpdate(true);
					}
					if (formLoading) {
						setFormLoading(false);
					}

					if (incorporatedStatusInDb !== response?.data?.data?.incorporatedStatus) {
						const companyName = response?.data?.data?.companyName || "";
						const incorporatedStatus = response?.data?.data?.incorporatedStatus || "";
						setIncorporatedStatusInDb(response?.data?.data?.incorporatedStatus);
						handleMixPanel(`${companyName} incorporated status updated`, {
							incorporatedStatus,
						});
					}

					//update store
					dispatch(
						setAuthData({
							companyName: response.data.data.companyName,
							companyLogoUrl: response.data.data.logoUrl,
						})
					);
					handleStartupDetails();
				}
			})
			.catch((e) => {
				setSaveLoading(false);
				if (handleForm) {
					handleEditForm();
				}
				console.log("Submit Error", e);
				toastContainer(messages.API_SUBMIT_ERROR, "error");
			});
	};

	const handleAISave = (handleForm = true, data) => {
		let payloadData = data ? data : payload;
		const getLinkedInUrl = (url) => {
			if (url) {
				if (url.match(/^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(company\/)/gm)) {
					let cleanedLinkedInUrl = url.replace(
						/^(https:\/\/www\.linkedin\.com\/company\/.*?\/)(.*)https:\/\/www\.linkedin\.com\/company\/.*?\/(.*)$/,
						"$1$2"
					);

					return cleanedLinkedInUrl;
				} else {
					return "https://www.linkedin.com/company/" + url;
				}
			} else {
				return null;
			}
		};
		payloadData = {
			...payloadData,
			industry:
				payloadData?.industry?.length > 0
					? typeof payloadData?.industry === "string"
						? payloadData?.industry
						: JSON.stringify(payloadData?.industry)
					: "",
			linkedInUrl: getLinkedInUrl(payloadData.linkedInUrl),
			noOfCoFounders: payloadData?.noOfCoFounders ? payloadData?.noOfCoFounders : "0",
			investorView: isInvestorView,
		};

		setSaveLoading(true);
		return services
			.updateCompanyProfile(payloadData)
			.then((response) => {
				if (response.data.message === "Success") {
					return response?.data;
				}
			})
			.catch((e) => {
				setSaveLoading(false);
				console.log("Submit Error", e);
				toastContainer(messages.API_SUBMIT_ERROR, "error");
			});
	};

	const handleUpload = (data, type) => {
		//const fileSize = (data?.size / 1024 / 1024).toFixed(2);
		if (type === "img") {
			if (data.type == "image/png" || data.type == "image/jpeg" || data.type == "image/jpg") {
				setLoading(true);
				setCompanyDataError((prevState) => ({
					...prevState,
					logoError: "",
				}));
			} else {
				setCompanyDataError((prevState) => ({
					...prevState,
					logoError: "Invalid file type.",
				}));
			}
		} else if (type === "video") {
			if (
				data.type == "video/mp4" ||
				data.type == "video/3gp" ||
				data.type == "video/avi" ||
				data.type == "video/webm" ||
				data.type == "video/ogg" ||
				data.type == "audio/ogg" ||
				data.type == "video/3gpp"
			) {
				setVidLoading(true);
				setCompanyDataError((prevState) => ({
					...prevState,
					videoError: "",
				}));
			} else {
				setCompanyDataError((prevState) => ({
					...prevState,
					videoError: "Invalid file type.",
				}));
			}
		}
		getFiles(data, type);
	};

	const getFiles = async (data, type) => {
		let pass;
		let fileGroup;
		var formData = new FormData();
		setIsUploading(true);
		formData.append("file", data);
		formData.append("fileGroup", "LOGO");
		services
			.uploadFile(formData)
			.then((response) => {
				setLink(response.data.data);
				setFile("");
				if (type == "img") {
					setLoading(false);
					setBrowseFlag(true);
					setOpenVideoUpload(false);
					setLogo(response.data.data.fileLocation);
					const data = { ...payload };
					data["logoUrl"] = response.data.data.fileLocation;
					setPayload(data);
					setSaveDisabled(false);
				} else {
					setIsUploading(false);
					setOpenVideoUpload(false);
					setVidLoading(false);
					setBrowseVidFlag(true);
					const data = { ...payload };
					data["videoUrl"] = response.data.data.fileLocation;
					setPayload(data);
					setSaveDisabled(false);
				}
				toastContainer("Uploaded successfully", "success");
			})
			.catch((e) => {
				console.log("File upload Error", e);
				setLoading(false);
				if (type == "img") {
					setCompanyDataError((prevState) => ({
						...prevState,
						logoError: messages.FILE_UPLOAD_ERROR,
					}));
				}
			});
	};

	const resetState = () => {
		setState("");
		setStateImage(USAMAP);
	};

	const handleDeleteUploadAttachment = () => {
		setBrowseVidFlag(false);
		const data = { ...payload };
		data["videoUrl"] = "";
		setPayload(data);
		setOpenVideoUpload(false);
		setSaveDisabled(false);
	};

	const handleDeleteImageUploadAttachment = () => {
		setBrowseFlag(false);
		const data = { ...payload };
		data["logoUrl"] = "";
		setPayload(data);
		setOpenVideoUpload(false);
		setSaveDisabled(false);
	};

	const handleZipCodeChange = async (e) => {
		const value = e.target.value;
		if (value.match(REGEX.ZIP_CODE)) {
			setZipCode(value);
			if (value.length >= 5) {
				const locationData = await services.searchLocationByZipcode(value);

				if (locationData.data) {
					const usaData = locationData?.data?.filter((loc) => loc?.address?.country_code === "us");
					if (usaData.length > 0) {
						if (usaData[0].address?.state) {
							setState(usaData[0].address.state);
							setLocation(usaData[0].address.city);
							const data = { ...payload };
							data["state"] = usaData[0].address.state;
							if (!usaData[0].address.city) {
								if (usaData[0].address.village) {
									setCompLocation(`${usaData[0].address.village}, ${usaData[0].address.state}`);
									data["city"] = usaData[0].address.village;
								} else if (usaData[0].address.town) {
									data["city"] = usaData[0].address.town;
									setCompLocation(`${usaData[0].address.town}, ${usaData[0].address.state}`);
								} else {
									data["city"] = "";
									setCompLocation(usaData[0].address.state);
								}
							} else {
								data["city"] = usaData[0].address.city;
								setCompLocation(`${usaData[0].address.city}, ${usaData[0].address.state}`);
							}
							setPayload(data);
							setShowState(true);
						} else {
							//If state dont found for zipcode api
							const lattitude = usaData[0]?.lat;
							const longitude = usaData[0]?.lon;

							const latLongData = await services.searchLocationByCoordinates(lattitude, longitude);
							const address = latLongData.data.address;

							if (address.country_code === "us") {
								setState(address.state);
								setLocation(address.city);
								setCompLocation(address.state);

								const data = { ...payload };
								data["state"] = address.state;
								data["city"] = address.city ? address.city : address.village ? address.village : address.suburb;
								setPayload(data);
								setShowState(true);
							} else {
								//toastContainer(messages.LOCATION_INVALID, "error");
							}
						}
					} else {
						//toastContainer(messages.LOCATION_INVALID, "error");
						//resetState();
					}
				}
			} else {
				//resetState();
			}
		} else {
			//resetState();
		}
	};

	const handleTabChange = (event, newValue) => {
		setcurrenttab(newValue);
		setValue(newValue);
	};

	const handleClear = () => {
		setCompLocation("");
		const data = { ...payload };
		data["state"] = "";
		setPayload(data);
	};

	useEffect(() => {
		if (userType === USER_TYPES.STARTUP_OWNER || userType === USER_TYPES.STARTUP_USER || userType === USER_TYPES.STUDENT_USER) {
			services
				.getDashboardStats()
				.then((res) => {
					setScore(res.data.data);
				})
				.catch((err) => {
					console.log(err);
					//toastContainer(err?.error_description, "error");
				});
		}
	}, []);

	const handlePitchDeckViewUpdate = (value, type) => {
		handlePitchDeckView();
		const data = { ...payload };
		data[type] = value;
		setPayload(data);
		handlePayload(false, data);
	};

	const handleDeleteVideo = () => {
		const data = { ...payload };
		data["videoUrl"] = "";
		setPayload(data);
		handlePayload(false, data);
	};

	const handleUpdateAssetsUrl = (imgObject, key) => {
		const data = { ...payload };
		data[key] = imgObject;
		setPayload(data);
		if (!isEditOpen) {
			handlePayload(false, data);
		}
	};

	const handleUploadPitchDeck = (value) => {
		const data = { ...payload };
		data["pitchDeckLink"] = value;
		setPayload(data);
		if (!isEditOpen) {
			handlePayload(false, data);
		}
	};

	const handleGetHarmonicData = () => {
		setFormLoading(true);

		setHarmonicData("");
		if (payload?.linkedInUrl) {
			const getLinkedInUrl = (url) => {
				if (url) {
					if (url.includes("https://www.linkedin.com/company/")) {
						let cleanedLinkedInUrl = url.replace(
							/^(https:\/\/www\.linkedin\.com\/company\/.*?\/)(.*)https:\/\/www\.linkedin\.com\/company\/.*?\/(.*)$/,
							"$1$2"
						);
						return cleanedLinkedInUrl;
					} else {
						return "https://www.linkedin.com/company/" + url;
					}
				} else {
					return null;
				}
			};

			services
				.getHarmonicsController({
					userId: payload?.founderDetails?.id,
					linkedinUrl: getLinkedInUrl(payload?.linkedInUrl),
				})
				.then((res) => {
					setHarmonicData(JSON.parse(res?.data?.data.harmonicData));

					if (!res?.data?.data.harmonicData) {
						toastContainer(messages.LINKEDIN_ERROR);
					}
					setFormLoading(false);
					setHarmonicOpen(false);
				})
				.catch((err) => {
					console.log(err);
					if (err?.response?.data?.code === 429) {
						toastContainer("Too many requests.", "error");
					}
					setFormLoading(false);
					setHarmonicOpen(false);
				});
		} else {
			setFormLoading(false);
			setHarmonicOpen(false);
		}
	};

	useEffect(() => {
		if (harmonicData) {
			const data = { ...payload };

			if (harmonicData?.name) {
				data["companyName"] = harmonicData?.name;
			}
			if (harmonicData?.website?.url) {
				data["websiteLink"] = harmonicData?.website?.url.replace(/^https?:\/\//, "");
			}
			if (harmonicData?.logo_url) {
				data["logoUrl"] = harmonicData?.logo_url;
			}

			if (harmonicData?.description) {
				data["about"] = harmonicData?.description;
				setHarmonicUpdate(true);
			}

			if (harmonicData?.founding_date?.date) {
				data["foundedDate"] = harmonicData?.founding_date?.date;
				data["foundedYear"] = moment(harmonicData?.founding_date?.date).format("Y");
			}
			if (harmonicData?.location?.city) {
				data["city"] = harmonicData?.location?.city;
			}
			if (harmonicData?.location?.state) {
				data["state"] = harmonicData?.location?.state;
			}
			if (harmonicData?.location?.zip) {
				data["zipcode"] = harmonicData?.location?.zip;
			}
			if (harmonicData?.contact?.phone_numbers) {
				data["phone"] = harmonicData?.contact?.phone_numbers[0];
			}

			setPayload(data);
			setCompanyData(data);

			if (!isEditOpen) {
				handlePayload(false, data);
			}
		}
	}, [harmonicData]);

	return (
		<>
			<Stack
				sx={(theme) => ({
					minHeight: "100%",
					borderRadius: theme.shape.standard.borderRadius,
					display: "block",
				})}
			>
				<Grid
					container
					sx={{
						maxWidth: theme.breakpoints.values.xl,
						m: "auto",
						px: 3,
					}}
				>
					<Grid item md={12} xs={12}>
						{userType === USER_TYPES.INVESTOR_USER && (
							<Box>
								<Button
									onClick={() => history.goBack()}
									variant="link"
									sx={{
										color: (theme) => theme.palette.secondary.korophKoal,
										ml: 0,
										maxWidth: "fit-content",
										p: 0,
									}}
									startIcon={<ArrowBackIcon />}
								>
									Back
								</Button>
							</Box>
						)}
						<ProfileContentWrapper value={value}>
							{!isEditOpen ? (
								<>
									{/*<Grid item md={12} sm={12} justifyContent="center">
                    <Box>
                      <Box
                        sx={{
                          pb: 1,
                        }}
                      >
                        <AdBanner
                          title="Back Office"
                          subtitle="Start and Grow Your Side Hustle or Dream Business"
                          image={ZB_LOGO}
                          backgroundSize="cover"
                          action={{
                            text: "View Services",
                            props: {
                              href: constants.ZB_PARTNER_URL,
                              target: "_blank",
                            },
                          }}
                        />
                      </Box>
                    </Box>
                  </Grid>*/}

									{formLoading ? (
										<Box
											sx={{
												display: "flex",
												width: 1,
												justifyContent: "center",
												my: 4,
											}}
										>
											<CircularProgress />
										</Box>
									) : (
										value === 0 && (
											<MyProfile
												handleEditForm={handleEditForm}
												pitchDeckToggle={handlePitchDeckView}
												isPitchDeckView={isPitchDeckView}
												profileArr={companyData}
												showInsights={showInsights}
												setShowInsights={setShowInsights}
												score={score}
												setispitchdeckview={handlePitchDeckViewUpdate}
												handleDeleteVideo={handleDeleteVideo}
												compLocation={compLocation}
												setBrowseVidFlag={setBrowseVidFlag}
												userData={userData}
												handleUpdateAssetsUrl={handleUpdateAssetsUrl}
												handleUploadPitchDeck={handleUploadPitchDeck}
												handleStartupDetails={handleStartupDetails}
												setHarmonicOpen={setHarmonicOpen}
											/>
										)
									)}

									{value === 1 && (
										<WorkContentWrapper>
											<MyWork
												showArchivedPage={showArchivedPage}
												showVersionsPage={showVersionsPage}
												powerupId={pathName?.state?.powerupId}
											/>
										</WorkContentWrapper>
									)}
								</>
							) : (
								<>
									<EditForm
										handleCloseForm={handleEditForm}
										handleChange={handleChange}
										handleUploadAction={handleUpload}
										handleValidations={handleValidations}
										companyDataError={companyDataError}
										setCompanyDataError={setCompanyDataError}
										showOther={showOther}
										setShowOther={setShowOther}
										handleZipCodeChange={handleZipCodeChange}
										companyData={companyData}
										about={about}
										logo={logo}
										saveDisabled={saveDisabled}
										loading={loading}
										setLoading={setLoading}
										handleClear={handleClear}
										saveLoading={saveLoading}
										browseFlag={browseFlag}
										setSaveDisabled={setSaveDisabled}
										handleSave={handleSave}
										videoUrl={videoUrl}
										payload={payload}
										vidLoading={vidLoading}
										browseVidFlag={browseVidFlag}
										compLocation={compLocation}
										postalCode={postalCode}
										inputChange={inputChange}
										setBrowseFlag={setBrowseFlag}
										isUploading={isUploading}
										setOpenVideoUpload={setOpenVideoUpload}
										openVideoUpload={openVideoUpload}
										setBrowseVidFlag={setBrowseVidFlag}
										handleDeleteUploadAttachment={handleDeleteUploadAttachment}
										handleDeleteImageUploadAttachment={handleDeleteImageUploadAttachment}
										investerToggle={isInvestorView}
										setIsInvestorView={handleinvestorsView}
										handleUpdateAssetsUrl={handleUpdateAssetsUrl}
										handleUploadPitchDeck={handleUploadPitchDeck}
										handleGetHarmonicData={handleGetHarmonicData}
										harmonicUpdate={harmonicUpdate}
										setHarmonicUpdate={setHarmonicUpdate}
										setHarmonicOpen={setHarmonicOpen}
										discard={discard}
									/>
								</>
							)}
						</ProfileContentWrapper>
					</Grid>
				</Grid>
				<MessageHandlerModal
					isOpen={openHarmonic}
					onCancel={() => setHarmonicOpen(false)}
					onOk={() => handleGetHarmonicData()}
					okText="Continue"
					cancelText={"Cancel"}
					heading="Copy profile from Linkedin"
					text={`Continuing to copy data from Linkedin will replace all data in your Profile Intro, Startup Logo, About and Details area of the screen.
            It will not replace Media data loaded:Video, Images, Pitch perfect deck, etc.`}
					messageType="primary"
				/>
			</Stack>
		</>
	);
}
