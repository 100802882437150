import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Box, Stack, Breadcrumbs, Typography, Link, Grid, Paper, Divider, Rating, Button } from "@mui/material";
import { NavigateNext } from "@mui/icons-material";
import axios from "axios";

import ROUTES_PATH from "../../constants/routes";
import { POWERUPLOGO } from "../../constants";
import ExcerciseWrapper from "../../components/Workspace/PowerUp/ExcerciseWrapper";
import { ConfirmStep, QuestionsStep, ReviewStep, QuoteStep, KnowledgeBar } from "./components";
import DashboardIcon from '@mui/icons-material/Dashboard';

const APIHOST = process.env.REACT_APP_POWERUPS_URL;

const Vouch = () => {
	const history = useHistory();
	const [step, setStep] = useState(0);
	const [applicationId, setApplicationId] = useState("");
	const [powerUp, setPowerUp] = useState(null);
	const [powerUpSubmissions, setPowerUpSubmissions] = useState({ page: {}, sort: {}, id: null, data: [] });
	const [powerUpInspirations, setPowerUpInspirations] = useState({ id: null, data: [] });
	const [editQuestionSetId, setEditQuestionSetId] = useState("");
	const [editPreQuestions, setEditPreQuestions] = useState();

	const apiVersion = process.env.REACT_APP_API_URL;
	const VOUCH_PARTNER_ID = apiVersion === "https://api.dev.startupos.com" ? 100001 : 100026;

	useEffect(() => {
		loadData();
	}, [VOUCH_PARTNER_ID]);

	const loadData = async () => {
		try {
			const powerUpRes = await axios.get(`${APIHOST}/powerups/${VOUCH_PARTNER_ID}`);
			setPowerUp(powerUpRes.data);

			const submissions = await axios.get(`${APIHOST}/powerups/${VOUCH_PARTNER_ID}/submissions`);
			setPowerUpSubmissions(submissions?.data);

			const inspirationsRes = await axios.get(`${APIHOST}/powerups/${VOUCH_PARTNER_ID}/inspirations`);
			setPowerUpInspirations(inspirationsRes.data);
		} catch (e) {
			console.log("---loadData error---", e);
		}
	};

	const handleConfirm = newAppId => {
		setStep(step + 1);

		if (newAppId) {
			setApplicationId(newAppId);
		}
	};

	const handleBeforeStep = (questionSetId, editQuestions) => {
		setStep(1);
		setEditQuestionSetId(questionSetId);
		setEditPreQuestions(editQuestions);
	};

	const startSection = () => {
		history.push(ROUTES_PATH.PARTNER_POWERUP_VIEW.replace(":id", VOUCH_PARTNER_ID));
	};

	const resume = false;

	return (
		<Box sx={{ px: { lg: 8, md: 4, xs: 2,}, pb: "30px", display: "flex", flexDirection: "column", gap: "32px", margin: "0 auto",
			maxWidth: "1920px", // Figma specifies max content width
		}}>
			<Box
				sx={{
					display: "flex",
					alignItems: "center",
					gap: "12px",
				}}
			>
				<DashboardIcon sx={{ width: "30px", height: "30px" }} style={{ fill:'white', stroke:'black'}} />
				<Typography variant="growth_card_subtitle" color="secondary.black">Business Insurance for Startups</Typography>
			</Box>
			<Box>
			<Stack px={3} pb={3} direction={"row"} gap={3} alignItems={"center"} justifyContent={"space-between"}>
				<Breadcrumbs separator={<NavigateNext fontSize="small" />}>
					<Link underline="hover" sx={{ cursor: "pointer" }} onClick={() => history.push(ROUTES_PATH.STARTUPS_HOME)}>
						<Typography variant="h5">PowerUps</Typography>
					</Link>
					<Typography variant="h5">Business Insurance for Startups</Typography>
				</Breadcrumbs>
			</Stack>
			<ExcerciseWrapper>
				<Grid
					item
					md={8}
					sx={theme => ({
						[theme.breakpoints.up("md")]: {
							pr: 3,
						},
						[theme.breakpoints.down("md")]: {
							p: 2,
						},
						display: "flex",
						flexDirection: "column",
						minHeight: "100%",
					})}
				>
					<Stack gap={3} mb={3}>
						<Paper elevation={1}>
							<Stack p={3} gap={2}>
								<img src={POWERUPLOGO} alt="" style={{ alignSelf: "flex-start" }} />
								<Typography variant={"h1"}>Business Insurance for Startups</Typography>
								{/* <Typography>
									Startup business insurance protects your company, investors, board, property, and customers from lawsuits. Think of insurance as an investment
									in your business designed to cover the financial cost of unforeseen liabilities and business losses. It is a way to help transfer the
									financial risks of running a competitive startup from you and your company to another entity. In the event that something does go wrong, you
									can file a claim, and your insurance provider takes it from there. It’s a smart business move that signals to potential customers, investors,
									and board members that you take risk seriously. Many require insurance in place before they will work with you, which means having your
									insurance program ready ahead of time can speed up negotiations. In the fast-paced world of startups, that can mean the difference between
									making the deal or getting passed over for a more prepared competitor. This might explain why roughly two-thirds of repeat founders start
									purchasing insurance policies at the formation of their startups.
								</Typography> */}
							</Stack>
							{/* <Divider />
							<Stack direction={"row"} p={3} alignItems={"center"} justifyContent={"space-between"}>
								<Rating name="read-only" value={0} readOnly precision={0.5} />
								<Button sx={{ margin: 0, width: "unset" }} onClick={startSection}>
									{resume ? "Resume" : "Start"}
								</Button>
							</Stack> */}
						</Paper>
						<Paper elevation={1}>
							<Box position="relative" p={3}>
								{step === 0 && <ConfirmStep handleNextStep={handleConfirm} />}
								{step === 1 && <QuestionsStep editQuestionSetId={editQuestionSetId} editPreQuestions={editPreQuestions} handleNextStep={handleConfirm} />}
								{step === 2 && <ReviewStep applicationId={applicationId} handleNextStep={handleConfirm} handleBeforeStep={handleBeforeStep} />}
								{step === 3 && <QuoteStep applicationId={applicationId} handleNextStep={handleConfirm} />}
							</Box>
						</Paper>
					</Stack>
				</Grid>
				<Grid item md={4}>
					<KnowledgeBar
						title={powerUp?.title ?? ""}
						details={powerUp?.details ?? ""}
						timeShort={powerUp?.time?.short ?? 0}
						timeLong={powerUp?.time?.long ?? 0}
						difficulty={powerUp?.difficulty ?? "easy"}
						recommended={powerUp?.recommended ?? "optional"}
						powertips={powerUp?.powertips ?? ""}
						inspirations={powerUpInspirations?.data || []}
						submissions={powerUpSubmissions?.data || []}
						sort={powerUpSubmissions?.sort}
						id={VOUCH_PARTNER_ID}
					/>
				</Grid>
			</ExcerciseWrapper>
			</Box>
		</Box>
	);
};

export default Vouch;
