import React, { Fragment, useContext, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { debounce } from "lodash";
import { ArrowDropDown, Check } from "@mui/icons-material";
import {
	Box,
	Button,
	CircularProgress,
	IconButton,
	InputAdornment,
	ListItemText,
	MenuItem,
	Select,
	Slider,
	Stack,
	Switch,
	TextField,
	ToggleButtonGroup,
	Typography,
} from "@mui/material";
import { NumericFormat } from "react-number-format";
import { PowerUPContext } from "../../../PowerUPController";
import { Card as PowerUpCard } from "../../../components/cards/Card";
import StandardTwoCol from "../../../components/layout/StandardTwoCol";
import { TextArea } from "../../../components/inputs/TextArea";
import {
	CHECK_SQUARE_BROKEN_ICON,
	CHECK_SQUARE_ICON,
	CODEPEN,
	CURRENT_PRODUCT_STATE_DICT,
	FOUNDING_TEAM_EXP_LIST,
	FOUNDING_TEAM_SKILLS_LIST,
	HISTORY_OF_SUCCESS_LIST,
	INVESTOR_PITCH_PRESENTATION_DICT,
} from "../../../../../constants";
import { generatePDFContent, getAnswersByStepUuid, getModuleStepByUUID, getStartupProfile, saveModuleResponses } from "../../../functions";
import TextAreaLoader from "../../../components/inputs/TextAreaLoader";
import InfoHelper from "../../../../../components/Founder/InfoHelper";
import ToggleButtons from "../../../../../common/ToggleButtons";
import { CustomSelect } from "../../../components/inputs/CustomSelect";
import TextDocDownload from "../../../../../utils/PDF-Export/TextDocDownload";
import { handleMixPanel } from "../../../../../utils/mixPanelEventHandling";
import { CURRENT_STAGE_LIST } from "../../../../Onboard/Components/Options";
import CheckboxAccordion from "../../../../../common/CustomCheckboxTree";
import services from "../../../../../services";
import ROUTES_PATH from "../../../../../constants/routes";
import PowerupRatingModal from "../../../../../common/PowerupRatingModal";
import { areYouEnterpriseAI } from "../../../../../utils/AI/areYouEnterprise";
import EnterpriseSemiCircle from "../../../../../common/EnterpriseSemiCircle";
import CustomAccordion from "../../../../../common/CustomAccordion";
import { once } from "events";
import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import { InvestorToggleSwitch } from "../../../../MyStartup/styles";
import { disable } from "mixpanel-browser";
import { PanoramaFishEye } from "@mui/icons-material";
import ToggleButton from "@mui/material/ToggleButton";
import { MUIAccordion, MUIAccordionDetails, MUIAccordionSummary } from "../../../../../common/CustomCheckboxTree/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { NewCustomSelect } from "../../../components/select/NewCustomSelect";
import { CustomCheckboxList } from "../../../components/checkboxlist/CustomCheckboxList";

export const INITIAL_VALUE_STATE = [
	{ id: "md-enterprise-ready-currentStage", value: "", error: "" },
	{ id: "md-enterprise-ready-monthlyRevenue", value: "", error: "" },
	{ id: "md-enterprise-ready-capitalRaised", value: "", error: "" },
	{ id: "md-enterprise-ready-current-runway", value: "", error: "" },
	{ id: "md-enterprise-ready-companySize", value: "", error: "" },

	{ id: "md-enterprise-ready-strategic-plan", value: "No", error: "" },
	{ id: "md-enterprise-ready-strategic-partnerships", value: "No", error: "" },
	{ id: "md-enterprise-ready-patent", value: "No", error: "" },
	{ id: "md-enterprise-ready-strategy-for-leveraging", value: "No", error: "" },
	{ id: "md-enterprise-ready-startup-own-cash", value: "No", error: "" },
	{ id: "md-enterprise-ready-any-litigation", value: "No", error: "" },
	{ id: "md-enterprise-ready-legal-liabilities", value: "No", error: "" },
	{ id: "md-enterprise-ready-product-offering-scale", value: "No", error: "" },
	{ id: "md-enterprise-ready-unique-value-proposition", value: "No", error: "" },

	{ id: "md-enterprise-ready-competitive-landscape", value: "No", error: "" },
	{ id: "md-enterprise-ready-roadmap", value: "No", error: "" },

	{ id: "md-enterprise-ready-process-for-soliciting", value: "No", error: "" },
	{ id: "md-enterprise-ready-support-customers", value: "No", error: "" },
	{ id: "md-enterprise-ready-flagship-customer", value: "No", error: "" },
	{ id: "md-enterprise-ready-proven-pricing-strategy", value: "No", error: "" },
	{ id: "md-enterprise-ready-proven-sales", value: "No", error: "" },

	{ id: "md-enterprise-measure-the-success", value: "No", error: "" },
	{ id: "md-enterprise-endorsements", value: "No", error: "" },
	{ id: "md-enterprise-ready-business-meet-operational", value: "No", error: "" },
	{ id: "md-enterprise-ready-clear-policies-for-regulatory", value: "No", error: "" },

	{ id: "md-enterprise-ready-ready-for-procurement", value: "No", error: "" },
	{ id: "md-enterprise-ready-attract-and-retain-talent", value: "No", error: "" },
	{ id: "md-enterprise-ready-clear-HR-policies", value: "No", error: "" },
	{ id: "md-enterprise-ready-clear-environmental-sustainability-policies", value: "No", error: "" },

	{ id: "md-enterprise-ready-social-responsibility-initiatives", value: "No", error: "" },
	{ id: "md-enterprise-ready-depend-on-a-Web", value: "No", error: "" },
	{ id: "md-enterprise-ready-SOC-2-compliant", value: "No", error: "" },
	{ id: "md-enterprise-ready-platform-meet-compliance", value: "No", error: "" },
	{ id: "md-enterprise-ready-technology-infrastructure-scale", value: "No", error: "" },
	{ id: "md-enterprise-ready-system-monitoring", value: "No", error: "" },
	{ id: "md-enterprise-ready-robust-data-analytics", value: "No", error: "" },
	{ id: "md-enterprise-ready-disaster-recovery-plan", value: "No", error: "" },
	{ id: "md-enterprise-ready-technological-upgrades", value: "No", error: "" },

	{ id: "md-enterprise-ready-hardware-product", value: "No", error: "" },
	{ id: "md-enterprise-ready-deliver-the-hardware", value: "No", error: "" },
	{ id: "md-enterprise-ready-maintain-the-hardware", value: "No", error: "" },
	{ id: "md-enterprise-ready-hardware-returned", value: "No", error: "" },

	// { id: "md-enterprise-ready-scalable", value: "", error: "" },
	{ id: "md-enterprise-ready-creativeScale", value: 2 },
	{ id: "md-enterprise-ready-finalOutput", value: "" },
];

export const TEXT_MAP = [
	{ id: "md-enterprise-ready-currentStage", title: "Select stage of startup", description: "" },
	{ id: "md-enterprise-ready-monthlyRevenue", title: "Do you have any monthly recurring revenue (MRR)?", description: "" },
	{ id: "md-enterprise-ready-capitalRaised", title: "How much capital have you raised to date?", description: "" },
	{ id: "md-enterprise-ready-current-runway", title: "What is your Current Runway?", description: "" },
	{ id: "md-enterprise-ready-companySize", title: "What is your company size", description: "" },

	{ id: "md-enterprise-ready-strategic-plan", title: "Does your startup have a clear, long-term strategic plan?", description: "" },
	{
		id: "md-enterprise-ready-strategic-partnerships",
		title: "Does your startup have strategic partnerships or alliances that enhance its value proposition?",
		description: "",
	},
	{ id: "md-enterprise-ready-patent", title: "Does your startup hold at least one patent that protects your unique product or IP?", description: "" },
	{
		id: "md-enterprise-ready-strategy-for-leveraging",
		title: "Do you have a strategy for leveraging your unique product or IP for competitive advantage?",
		description: "",
	},
	{
		id: "md-enterprise-ready-startup-own-cash",
		title: "Does your startup own cash or collateral equal to at least 80% of its current financial liability?",
		description: "",
	},
	{
		id: "md-enterprise-ready-any-litigation",
		title: "Are there any liens against your startup, or is your startup involved in any form of litigation?",
		description: "",
	},
	{
		id: "md-enterprise-ready-legal-liabilities",
		title: "Are there any legal liabilities or exceptional risks involved with using your startup or product?",
		description: "",
	},
	{ id: "md-enterprise-ready-product-offering-scale", title: "Does your product / service offering scale?", description: "" },
	{
		id: "md-enterprise-ready-unique-value-proposition",
		title: "Does your product / service offer a unique value proposition compared to competitors?",
		description: "",
	},
	{ id: "md-enterprise-ready-competitive-landscape", title: "Do you have a clear understanding of the competitive landscape?", description: "" },
	{ id: "md-enterprise-ready-roadmap", title: "Is there a roadmap for future product / service development and innovation?", description: "" },
	{
		id: "md-enterprise-ready-process-for-soliciting",
		title: "Does your startup have a well-defined process for soliciting and incorporating customer feedback?",
		description: "",
	},
	{ id: "md-enterprise-ready-support-customers", title: "Do you have clear processes to support customers?", description: "" },
	{ id: "md-enterprise-ready-flagship-customer", title: "Do you have a flagship customer?", description: "" },
	{
		id: "md-enterprise-ready-proven-pricing-strategy",
		title: "Do you have a proven pricing strategy, and saleable, packaged product / service menu?",
		description: "",
	},
	{ id: "md-enterprise-ready-proven-sales", title: "Does your team have a proven (profitable) sales and marketing strategy?", description: "" },
	{ id: "md-enterprise-measure-the-success", title: "Are there metrics in place to measure the success of sales and marketing efforts?", description: "" },
	{ id: "md-enterprise-endorsements", title: "Do you have endorsements, testimonials and referrals outside your friends & family circle?", description: "" },
	{
		id: "md-enterprise-ready-business-meet-operational",
		title: "Does your business meet operational, regional and industry-specific compliance requirements?",
		description: "",
	},
	{
		id: "md-enterprise-ready-clear-policies-for-regulatory",
		title: "Are there clear policies for regulatory compliance in all operational regions?",
		description: "",
	},
	{ id: "md-enterprise-ready-ready-for-procurement", title: "Are you ready for procurement, contracts, pricing, NDAs?", description: "" },
	{
		id: "md-enterprise-ready-attract-and-retain-talent",
		title: "Does your startup have a clear, ongoing strategic  initiative to attract and retain talent?",
		description: "",
	},
	{
		id: "md-enterprise-ready-clear-HR-policies",
		title: "Are there effective HR policies in place, including diversity and inclusion initiatives?",
		description: "",
	},
	{
		id: "md-enterprise-ready-clear-environmental-sustainability-policies",
		title: "Does the startup have policies regarding environmental sustainability?",
		description: "",
	},
	{ id: "md-enterprise-ready-social-responsibility-initiatives", title: "Does the startup engage in social responsibility initiatives?", description: "" },
	{ id: "md-enterprise-ready-depend-on-a-Web", title: "Does your startup depend on a Web / SaaS platform?", description: "" },
	{ id: "md-enterprise-ready-SOC-2-compliant", title: "Is your platform SOC 2 compliant?", description: "" },
	{
		id: "md-enterprise-ready-platform-meet-compliance",
		title: "Does your platform meet operational, regional and industry-specific compliance requirements?",
		description: "",
	},
	{
		id: "md-enterprise-ready-technology-infrastructure-scale",
		title: "Can the startup's technology infrastructre scale to meet increasing demands?",
		description: "",
	},
	{ id: "md-enterprise-ready-system-monitoring", title: "Have you implemented system monitoring, alerting, and support protocols?", description: "" },
	{ id: "md-enterprise-ready-robust-data-analytics", title: "Are there robust data analytics and reporting capabilities?", description: "" },
	{ id: "md-enterprise-ready-disaster-recovery-plan", title: "Have you implemented a disaster recovery plan?", description: "" },
	{ id: "md-enterprise-ready-technological-upgrades", title: "Is there a plan for technological upgrades as the business grows?", description: "" },
	{ id: "md-enterprise-ready-hardware-product", title: "Does your startup have a hardware product?", description: "" },
	{ id: "md-enterprise-ready-deliver-the-hardware", title: "Does your startup deliver the hardware?", description: "" },
	{ id: "md-enterprise-ready-maintain-the-hardware", title: "Does your startup maintain the hardware?", description: "" },
	{ id: "md-enterprise-ready-hardware-returned", title: "Is the hardware returned when the contract or service ends?", description: "" },

	// { id: "md-enterprise-ready-scalable", title: "Is your startup scalable?", description: "" },
	{ id: "md-enterprise-ready-creativeScale", title: "Selected Creativity Level", description: "" },
	{ id: "md-enterprise-ready-finalOutput", title: "AI Assisted Output", description: "" },
];

export const getPDFDownload = async (data, powerUpName, setStateCallback) => {
	if (!data) return;
	const documentData = {
		title: powerUpName,
		sections: [
			{
				title: TEXT_MAP?.find((input) => input.id === "md-enterprise-ready-currentStage")?.title,
				response: data?.find((input) => input.id === "md-enterprise-ready-currentStage")?.value,
			},
			{
				title: TEXT_MAP?.find((input) => input.id === "md-enterprise-ready-monthlyRevenue")?.title,
				response: data?.find((input) => input.id === "md-enterprise-ready-monthlyRevenue")?.value,
			},

			{
				title: TEXT_MAP?.find((input) => input.id === "md-enterprise-ready-capitalRaised")?.title,
				response: data?.find((input) => input.id === "md-enterprise-ready-capitalRaised")?.value,
			},

			{
				title: TEXT_MAP?.find((input) => input.id === "md-enterprise-ready-current-runway")?.title,
				response: data?.find((input) => input.id === "md-enterprise-ready-current-runway")?.value,
			},
			{
				title: TEXT_MAP?.find((input) => input.id === "md-enterprise-ready-companySize")?.title,
				response: data?.find((input) => input.id === "md-enterprise-ready-companySize")?.value,
			},
			//--------------------------------
			{
				title: TEXT_MAP?.find((input) => input.id === "md-enterprise-ready-strategic-plan")?.title,
				response: data?.find((input) => input.id === "md-enterprise-ready-strategic-plan")?.value,
			},
			{
				title: TEXT_MAP?.find((input) => input.id === "md-enterprise-ready-strategic-partnerships")?.title,
				response: data?.find((input) => input.id === "md-enterprise-ready-strategic-partnerships")?.value,
			},
			{
				title: TEXT_MAP?.find((input) => input.id === "md-enterprise-ready-patent")?.title,
				response: data?.find((input) => input.id === "md-enterprise-ready-patent")?.value,
			},
			{
				title: TEXT_MAP?.find((input) => input.id === "md-enterprise-ready-strategy-for-leveraging")?.title,
				response: data?.find((input) => input.id === "md-enterprise-ready-strategy-for-leveraging")?.value,
			},
			{
				title: TEXT_MAP?.find((input) => input.id === "md-enterprise-ready-startup-own-cash")?.title,
				response: data?.find((input) => input.id === "md-enterprise-ready-startup-own-cash")?.value,
			},

			{
				title: TEXT_MAP?.find((input) => input.id === "md-enterprise-ready-any-litigation")?.title,
				response: data?.find((input) => input.id === "md-enterprise-ready-any-litigation")?.value,
			},
			{
				title: TEXT_MAP?.find((input) => input.id === "md-enterprise-ready-legal-liabilities")?.title,
				response: data?.find((input) => input.id === "md-enterprise-ready-legal-liabilities")?.value,
			},

			{
				title: TEXT_MAP?.find((input) => input.id === "md-enterprise-ready-product-offering-scale")?.title,
				response: data?.find((input) => input.id === "md-enterprise-ready-product-offering-scale")?.value,
			},
			{
				title: TEXT_MAP?.find((input) => input.id === "md-enterprise-ready-unique-value-proposition")?.title,
				response: data?.find((input) => input.id === "md-enterprise-ready-unique-value-proposition")?.value,
			},
			//--------------------------------------------------------------------------------

			{
				title: TEXT_MAP?.find((input) => input.id === "md-enterprise-ready-competitive-landscape")?.title,
				response: data?.find((input) => input.id === "md-enterprise-ready-competitive-landscape")?.value,
			},
			{
				title: TEXT_MAP?.find((input) => input.id === "md-enterprise-ready-roadmap")?.title,
				response: data?.find((input) => input.id === "md-enterprise-ready-roadmap")?.value,
			},
			{
				title: TEXT_MAP?.find((input) => input.id === "md-enterprise-ready-process-for-soliciting")?.title,
				response: data?.find((input) => input.id === "md-enterprise-ready-process-for-soliciting")?.value,
			},
			{
				title: TEXT_MAP?.find((input) => input.id === "md-enterprise-ready-support-customers")?.title,
				response: data?.find((input) => input.id === "md-enterprise-ready-support-customers")?.value,
			},
			{
				title: TEXT_MAP?.find((input) => input.id === "md-enterprise-ready-flagship-customer")?.title,
				response: data?.find((input) => input.id === "md-enterprise-ready-flagship-customer")?.value,
			},
			{
				title: TEXT_MAP?.find((input) => input.id === "md-enterprise-ready-proven-pricing-strategy")?.title,
				response: data?.find((input) => input.id === "md-enterprise-ready-proven-pricing-strategy")?.value,
			},
			{
				title: TEXT_MAP?.find((input) => input.id === "md-enterprise-ready-proven-sales")?.title,
				response: data?.find((input) => input.id === "md-enterprise-ready-proven-sales")?.value,
			},
			{
				title: TEXT_MAP?.find((input) => input.id === "md-enterprise-measure-the-success")?.title,
				response: data?.find((input) => input.id === "md-enterprise-measure-the-success")?.value,
			},
			{
				title: TEXT_MAP?.find((input) => input.id === "md-enterprise-endorsements")?.title,
				response: data?.find((input) => input.id === "md-enterprise-endorsements")?.value,
			},
			{
				title: TEXT_MAP?.find((input) => input.id === "md-enterprise-ready-business-meet-operational")?.title,
				response: data?.find((input) => input.id === "md-enterprise-ready-business-meet-operational")?.value,
			},
			{
				title: TEXT_MAP?.find((input) => input.id === "md-enterprise-ready-clear-policies-for-regulatory")?.title,
				response: data?.find((input) => input.id === "md-enterprise-ready-clear-policies-for-regulatory")?.value,
			},
			{
				title: TEXT_MAP?.find((input) => input.id === "md-enterprise-ready-ready-for-procurement")?.title,
				response: data?.find((input) => input.id === "md-enterprise-ready-ready-for-procurement")?.value,
			},
			{
				title: TEXT_MAP?.find((input) => input.id === "md-enterprise-ready-attract-and-retain-talent")?.title,
				response: data?.find((input) => input.id === "md-enterprise-ready-attract-and-retain-talent")?.value,
			},
			{
				title: TEXT_MAP?.find((input) => input.id === "md-enterprise-ready-clear-HR-policies")?.title,
				response: data?.find((input) => input.id === "md-enterprise-ready-clear-HR-policies")?.value,
			},
			{
				title: TEXT_MAP?.find((input) => input.id === "md-enterprise-ready-clear-environmental-sustainability-policies")?.title,
				response: data?.find((input) => input.id === "md-enterprise-ready-clear-environmental-sustainability-policies")?.value,
			},
			{
				title: TEXT_MAP?.find((input) => input.id === "md-enterprise-ready-social-responsibility-initiatives")?.title,
				response: data?.find((input) => input.id === "md-enterprise-ready-social-responsibility-initiatives")?.value,
			},
			{
				title: TEXT_MAP?.find((input) => input.id === "md-enterprise-ready-depend-on-a-Web")?.title,
				response: data?.find((input) => input.id === "md-enterprise-ready-depend-on-a-Web")?.value,
			},
			{
				title: TEXT_MAP?.find((input) => input.id === "md-enterprise-ready-SOC-2-compliant")?.title,
				response: data?.find((input) => input.id === "md-enterprise-ready-SOC-2-compliant")?.value,
			},
			{
				title: TEXT_MAP?.find((input) => input.id === "md-enterprise-ready-platform-meet-compliance")?.title,
				response: data?.find((input) => input.id === "md-enterprise-ready-platform-meet-compliance")?.value,
			},
			{
				title: TEXT_MAP?.find((input) => input.id === "md-enterprise-ready-technology-infrastructure-scale")?.title,
				response: data?.find((input) => input.id === "md-enterprise-ready-technology-infrastructure-scale")?.value,
			},
			{
				title: TEXT_MAP?.find((input) => input.id === "md-enterprise-ready-system-monitoring")?.title,
				response: data?.find((input) => input.id === "md-enterprise-ready-system-monitoring")?.value,
			},
			{
				title: TEXT_MAP?.find((input) => input.id === "md-enterprise-ready-robust-data-analytics")?.title,
				response: data?.find((input) => input.id === "md-enterprise-ready-robust-data-analytics")?.value,
			},
			{
				title: TEXT_MAP?.find((input) => input.id === "md-enterprise-ready-disaster-recovery-plan")?.title,
				response: data?.find((input) => input.id === "md-enterprise-ready-disaster-recovery-plan")?.value,
			},
			{
				title: TEXT_MAP?.find((input) => input.id === "md-enterprise-ready-technological-upgrades")?.title,
				response: data?.find((input) => input.id === "md-enterprise-ready-technological-upgrades")?.value,
			},
			{
				title: TEXT_MAP?.find((input) => input.id === "md-enterprise-ready-hardware-product")?.title,
				response: data?.find((input) => input.id === "md-enterprise-ready-hardware-product")?.value,
			},
			{
				title: TEXT_MAP?.find((input) => input.id === "md-enterprise-ready-deliver-the-hardware")?.title,
				response: data?.find((input) => input.id === "md-enterprise-ready-deliver-the-hardware")?.value,
			},
			{
				title: TEXT_MAP?.find((input) => input.id === "md-enterprise-ready-maintain-the-hardware")?.title,
				response: data?.find((input) => input.id === "md-enterprise-ready-maintain-the-hardware")?.value,
			},
			{
				title: TEXT_MAP?.find((input) => input.id === "md-enterprise-ready-hardware-returned")?.title,
				response: data?.find((input) => input.id === "md-enterprise-ready-hardware-returned")?.value,
			},

			//--------------------------------------------------------------------------------
			{
				type: "image",
				title: TEXT_MAP.find((input) => input.id === "md-enterprise-ready-creativeScale")?.title,
				response: (
					<Box sx={{ width: "500px" }}>
						<Slider
							value={data.find((input) => input.id === "md-enterprise-ready-creativeScale")?.value}
							step={1}
							min={1}
							max={5}
							valueLabelDisplay="on"
						/>
					</Box>
				),
			},
			{
				title: TEXT_MAP?.find((input) => input.id === "md-enterprise-ready-finalOutput")?.title,
				response: data?.find((input) => input.id === "md-enterprise-ready-finalOutput")?.value,
				type: "AI",
			},
		],
	};
	const processedItems = await generatePDFContent(documentData);
	if (typeof setStateCallback === "function") setStateCallback(processedItems);
};

function MdPowerUpModule() {
	const {
		powerupDetails,
		moduleSteps,
		lastSaved,
		setLastSaved,
		currentVersion,
		getResponses,
		resetConfirm,
		getVersions,
		startupProfileDetails,
		setStartupProfileDetails,
	} = useContext(PowerUPContext);

	const { companyName, userName } = useSelector((state) => state?.auth);

	const [inputValues, setInputValues] = useState(INITIAL_VALUE_STATE);
	const [loadingValues, setloadingValues] = useState(false);
	const [saveReady, setsaveReady] = useState(false);
	const [generatingOutput, setgeneratingOutput] = useState(false);
	const [pdfContent, setPdfContent] = useState(null);
	const [historySuccessList, setHistorySuccessList] = useState(HISTORY_OF_SUCCESS_LIST);
	const [foundingTeamExpList, setFoundingTeamExpList] = useState(FOUNDING_TEAM_EXP_LIST);
	const [foundingTeamSkillsList, setFoundingTeamSkillsList] = useState(FOUNDING_TEAM_SKILLS_LIST);
	const [scoreLoader, setScoreLoader] = useState(false);
	const [openIndicatorsDialog, setOpenIndicatorsDialog] = useState(false);

	const [selectedhistorySuccessList, setSelectedHistorySuccessList] = useState({});
	const [selectedfoundingTeamList, setSelectedFoundingTeamList] = useState({});
	const [selectedfoundingSkillList, setSelectedFoundingSkillList] = useState({});
	const [openRatingModal, setOpenRatingModal] = useState(false);
	const [responseId, setResponseId] = useState(null);
	const [enterpriseScore, setEnterpriseScore] = useState(0);
	const [one, setOne] = useState(false);
	const history = useHistory();

	// useRef to store the latest version of inputValues
	const inputValuesRef = useRef(inputValues);
	const historySuccessListRef = useRef(historySuccessList);
	const foundingExpListRef = useRef(foundingTeamExpList);
	const foundingSkillsRef = useRef(foundingTeamSkillsList);

	useEffect(() => {
		setsaveReady(false); // Prevent loop on autosave function.
		setInputValues(INITIAL_VALUE_STATE);
		updateInputValues();
	}, [currentVersion]);

	useEffect(() => {
		inputValuesRef.current = inputValues;
		historySuccessListRef.current = historySuccessList;
		foundingExpListRef.current = foundingTeamExpList;
		foundingSkillsRef.current = foundingTeamSkillsList;

		if (saveReady) debouncedSave(inputValues);
		debouncedStartupDataSave(inputValues, historySuccessList, foundingTeamExpList, foundingTeamSkillsList);
	}, [inputValues, historySuccessList, foundingTeamExpList, foundingTeamSkillsList]);

	useEffect(() => {
		inputValuesRef.current = inputValues;
		historySuccessListRef.current = historySuccessList;
		foundingExpListRef.current = foundingTeamExpList;
		foundingSkillsRef.current = foundingTeamSkillsList;

		if (!one) {
			if (saveReady) debouncedSave(inputValues);
			debouncedStartupDataSave(inputValues, historySuccessList, foundingTeamExpList, foundingTeamSkillsList);
			setOne(true);
		}
	}, []);
	// useEffect(() => {
	// 	inputValuesRef.current = inputValues;
	// 	historySuccessListRef.current = historySuccessList;
	// 	foundingExpListRef.current = foundingTeamExpList;
	// 	foundingSkillsRef.current = foundingTeamSkillsList;

	// 	if (saveReady) debouncedSave(inputValues);
	// 	debouncedStartupDataSave(inputValues, historySuccessList, foundingTeamExpList, foundingTeamSkillsList);
	// }, [historySuccessList, foundingTeamExpList, foundingTeamSkillsList]);

	useEffect(() => {
		if (startupProfileDetails) initLeadingIndicators(startupProfileDetails);
	}, [startupProfileDetails]);

	// useEffect(() => {
	// 	if (startupProfileDetails) initLeadingIndicators(startupProfileDetails);
	// }, []);

	const initLeadingIndicators = (startupObj) => {
		const tempHistorySuccessList = [];
		HISTORY_OF_SUCCESS_LIST?.forEach((successItem) => {
			if (startupObj?.companyTags?.find((item) => String(item?.scoreTag?.id) === String(successItem?.id))) {
				tempHistorySuccessList.push(successItem);
			}
		});
		setSelectedHistorySuccessList(tempHistorySuccessList);

		const tempFoundingTeamList = [];
		FOUNDING_TEAM_EXP_LIST?.forEach((foundingItem) => {
			if (startupObj?.companyTags?.find((item) => String(item?.scoreTag?.id) === String(foundingItem?.id))) {
				tempFoundingTeamList.push(foundingItem);
			}
		});
		setSelectedFoundingTeamList(tempFoundingTeamList);

		const tempFoundingSkillList = [];
		FOUNDING_TEAM_SKILLS_LIST?.forEach((skillItem) => {
			if (startupObj?.companyTags?.find((item) => String(item?.scoreTag?.id) === String(skillItem?.id))) {
				tempFoundingSkillList.push(skillItem);
			}
		});
		setSelectedFoundingSkillList(tempFoundingSkillList);
	};

	const getCompletionStatus = (data) => {
		const currentStage = data.find((input) => input.id === "md-enterprise-ready-currentStage");

		const monthlyRevenue = data.find((input) => input.id === "md-enterprise-ready-monthlyRevenue");

		const capRaised = data.find((input) => input.id === "md-enterprise-ready-capitalRaised");
		const currentRunway = data.find((input) => input.id === "md-enterprise-ready-current-runway");

		const companySize = data.find((input) => input.id === "md-enterprise-ready-companySize");
		const readyStartegicPlan = data.find((input) => input.id === "md-enterprise-ready-strategic-plan");
		const readyStrategicPartnerships = data.find((input) => input.id === "md-enterprise-ready-strategic-partnerships");
		const patent = data.find((input) => input.id === "md-enterprise-ready-patent");
		const strategy_for_leveraging = data.find((input) => input.id === "md-enterprise-ready-strategy-for-leveraging");
		const startup_own_cash = data.find((input) => input.id === "md-enterprise-ready-startup-own-cash");
		const any_litigation = data.find((input) => input.id === "md-enterprise-ready-any-litigation");
		const legal_liabilities = data.find((input) => input.id === "md-enterprise-ready-legal-liabilities");

		const product_offering_scale = data.find((input) => input.id === "md-enterprise-ready-product-offering-scale");
		const unique_value_proposition = data.find((input) => input.id === "md-enterprise-ready-unique-value-proposition");

		//-------------------------------
		const competitive_landscape = data.find((input) => input.id === "md-enterprise-ready-competitive-landscape");
		const ready_roadmap = data.find((input) => input.id === "md-enterprise-ready-roadmap");
		const process_for_soliciting = data.find((input) => input.id === "md-enterprise-ready-process-for-soliciting");
		const support_customers = data.find((input) => input.id === "md-enterprise-ready-support-customers");
		const flagship_customer = data.find((input) => input.id === "md-enterprise-ready-flagship-customer");
		const proven_pricing_strategy = data.find((input) => input.id === "md-enterprise-ready-proven-pricing-strategy");
		const proven_sales = data.find((input) => input.id === "md-enterprise-ready-proven-sales");
		const measure_the_success = data.find((input) => input.id === "md-enterprise-measure-the-success");
		const endorsements = data.find((input) => input.id === "md-enterprise-endorsements");
		const business_meet_operational = data.find((input) => input.id === "md-enterprise-ready-business-meet-operational");
		const clear_policies_for_regulatory = data.find((input) => input.id === "md-enterprise-ready-clear-policies-for-regulatory");
		const ready_for_procurement = data.find((input) => input.id === "md-enterprise-ready-ready-for-procurement");
		const attract_and_retain_talent = data.find((input) => input.id === "md-enterprise-ready-attract-and-retain-talent");
		const clear_HR_policies = data.find((input) => input.id === "md-enterprise-ready-clear-HR-policies");
		const clear_environmental_sustainability_policies = data.find(
			(input) => input.id === "md-enterprise-ready-clear-environmental-sustainability-policies"
		);
		const social_responsibility_initiatives = data.find((input) => input.id === "md-enterprise-ready-social-responsibility-initiatives");
		const depend_on_a_Web = data.find((input) => input.id === "md-enterprise-ready-depend-on-a-Web");
		const SOC2_compliant = data.find((input) => input.id === "md-enterprise-ready-SOC-2-compliant");
		const platform_meet_compliance = data.find((input) => input.id === "md-enterprise-ready-platform-meet-compliance");
		const technology_infrastructure_scale = data.find((input) => input.id === "md-enterprise-ready-technology-infrastructure-scale");
		const system_monitoring = data.find((input) => input.id === "md-enterprise-ready-system-monitoring");
		const robust_data_analytics = data.find((input) => input.id === "md-enterprise-ready-robust-data-analytics");
		const disaster_recovery_plan = data.find((input) => input.id === "md-enterprise-ready-disaster-recovery-plan");
		const technological_upgrades = data.find((input) => input.id === "md-enterprise-ready-technological-upgrades");
		const hardwareproduct = data.find((input) => input.id === "md-enterprise-ready-hardware-product");
		const deliver_the_hardware = data.find((input) => input.id === "md-enterprise-ready-deliver-the-hardware");
		const maintain_the_hardware = data.find((input) => input.id === "md-enterprise-ready-maintain-the-hardware");
		const hardware_returned = data.find((input) => input.id === "md-enterprise-ready-hardware-returned");

		// TODO --  add addtl. field objects here. One for each field.
		const output = data.find((input) => input.id === "md-enterprise-ready-finalOutput");
		if (
			monthlyRevenue?.value?.length > 0 &&
			monthlyRevenue?.error?.length === 0 &&
			capRaised?.value?.length > 0 &&
			capRaised?.error?.length === 0 &&
			currentRunway?.value?.length > 0 &&
			currentRunway?.error?.length === 0 &&
			companySize?.value?.length > 0 &&
			companySize?.error?.length === 0 &&
			readyStartegicPlan?.value?.length > 0 &&
			readyStartegicPlan?.error?.length === 0 &&
			readyStrategicPartnerships?.value?.length > 0 &&
			readyStrategicPartnerships?.error?.length === 0 &&
			strategy_for_leveraging?.value?.length > 0 &&
			strategy_for_leveraging?.error?.length === 0 &&
			patent?.value?.length > 0 &&
			patent?.error?.length === 0 &&
			startup_own_cash?.value?.length > 0 &&
			startup_own_cash?.error?.length === 0 &&
			any_litigation?.value?.length > 0 &&
			any_litigation?.error?.length === 0 &&
			legal_liabilities?.value?.length > 0 &&
			legal_liabilities?.error?.length === 0 &&
			product_offering_scale?.value?.length > 0 &&
			product_offering_scale?.error?.length === 0 &&
			unique_value_proposition?.value?.length > 0 &&
			unique_value_proposition?.error?.length === 0 &&
			//----
			competitive_landscape?.value?.length > 0 &&
			competitive_landscape?.error?.length === 0 &&
			ready_roadmap?.value?.length > 0 &&
			ready_roadmap?.error?.length === 0 &&
			process_for_soliciting?.value?.length > 0 &&
			process_for_soliciting?.error?.length === 0 &&
			support_customers?.value?.length > 0 &&
			support_customers?.error?.length === 0 &&
			flagship_customer?.value?.length > 0 &&
			flagship_customer?.error?.length === 0 &&
			proven_pricing_strategy?.value?.length > 0 &&
			proven_pricing_strategy?.error?.length === 0 &&
			proven_sales?.value?.length > 0 &&
			proven_sales?.error?.length === 0 &&
			measure_the_success?.value?.length > 0 &&
			measure_the_success?.error?.length === 0 &&
			endorsements?.value?.length > 0 &&
			endorsements?.error?.length === 0 &&
			business_meet_operational?.value?.length > 0 &&
			business_meet_operational?.error?.length === 0 &&
			clear_policies_for_regulatory?.value?.length > 0 &&
			clear_policies_for_regulatory?.error?.length === 0 &&
			attract_and_retain_talent?.value?.length > 0 &&
			attract_and_retain_talent?.error?.length === 0 &&
			clear_HR_policies?.value?.length > 0 &&
			clear_HR_policies?.error?.length === 0 &&
			clear_environmental_sustainability_policies?.value?.length > 0 &&
			clear_environmental_sustainability_policies?.error?.length === 0 &&
			social_responsibility_initiatives?.value?.length > 0 &&
			social_responsibility_initiatives?.error?.length === 0 &&
			depend_on_a_Web?.value?.length > 0 &&
			depend_on_a_Web?.error?.length === 0 &&
			SOC2_compliant?.value?.length > 0 &&
			SOC2_compliant?.error?.length === 0 &&
			platform_meet_compliance?.value?.length > 0 &&
			platform_meet_compliance?.error?.length === 0 &&
			technology_infrastructure_scale?.value?.length > 0 &&
			technology_infrastructure_scale?.error?.length === 0 &&
			system_monitoring?.value?.length > 0 &&
			system_monitoring?.error?.length === 0 &&
			robust_data_analytics?.value?.length > 0 &&
			robust_data_analytics?.error?.length === 0 &&
			disaster_recovery_plan?.value?.length > 0 &&
			disaster_recovery_plan?.error?.length === 0 &&
			technological_upgrades?.value?.length > 0 &&
			technological_upgrades?.error?.length === 0 &&
			hardwareproduct?.value?.length > 0 &&
			hardwareproduct?.error?.length === 0 &&
			deliver_the_hardware?.value?.length > 0 &&
			deliver_the_hardware?.error?.length === 0 &&
			maintain_the_hardware?.value?.length > 0 &&
			maintain_the_hardware?.error?.length === 0 &&
			hardware_returned?.value?.length > 0 &&
			hardware_returned?.error?.length === 0 &&
			// TODO --  add addtl. field objects here. check length of value and error
			output?.value?.length > 0
		)
			return true;
		else return false;
	};

	const noErrors = () => {
		const currentStage = inputValues.find((input) => input.id === "md-enterprise-ready-currentStage");

		const monthlyRevenue = inputValues.find((input) => input.id === "md-enterprise-ready-monthlyRevenue");
		const capRaised = inputValues.find((input) => input.id === "md-enterprise-ready-capitalRaised");
		const currentRunway = inputValues.find((input) => input.id === "md-enterprise-ready-current-runway");

		const companySize = inputValues.find((input) => input.id === "md-enterprise-ready-companySize");
		const readyStartegicPlan = inputValues.find((input) => input.id === "md-enterprise-ready-strategic-plan");
		const readyStrategicPartnerships = inputValues.find((input) => input.id === "md-enterprise-ready-strategic-partnerships");
		const patent = inputValues.find((input) => input.id === "md-enterprise-ready-patent");
		const strategy_for_leveraging = inputValues.find((input) => input.id === "md-enterprise-ready-strategy-for-leveraging");
		const startup_own_cash = inputValues.find((input) => input.id === "md-enterprise-ready-startup-own-cash");
		const any_litigation = inputValues.find((input) => input.id === "md-enterprise-ready-any-litigation");
		const legal_liabilities = inputValues.find((input) => input.id === "md-enterprise-ready-legal-liabilities");
		const product_offering_scale = inputValues.find((input) => input.id === "md-enterprise-ready-product-offering-scale");
		const unique_value_proposition = inputValues.find((input) => input.id === "md-enterprise-ready-unique-value-proposition");

		const competitive_landscape = inputValues.find((input) => input.id === "md-enterprise-ready-competitive-landscape");
		const ready_roadmap = inputValues.find((input) => input.id === "md-enterprise-ready-roadmap");
		const process_for_soliciting = inputValues.find((input) => input.id === "md-enterprise-ready-process-for-soliciting");
		const support_customers = inputValues.find((input) => input.id === "md-enterprise-ready-support-customers");
		const flagship_customer = inputValues.find((input) => input.id === "md-enterprise-ready-flagship-customer");
		const proven_pricing_strategy = inputValues.find((input) => input.id === "md-enterprise-ready-proven-pricing-strategy");
		const proven_sales = inputValues.find((input) => input.id === "md-enterprise-ready-proven-sales");
		const measure_the_success = inputValues.find((input) => input.id === "md-enterprise-measure-the-success");
		const endorsements = inputValues.find((input) => input.id === "md-enterprise-endorsements");
		const business_meet_operational = inputValues.find((input) => input.id === "md-enterprise-ready-business-meet-operational");
		const clear_policies_for_regulatory = inputValues.find((input) => input.id === "md-enterprise-ready-clear-policies-for-regulatory");
		const ready_for_procurement = inputValues.find((input) => input.id === "md-enterprise-ready-ready-for-procurement");
		const attract_and_retain_talent = inputValues.find((input) => input.id === "md-enterprise-ready-attract-and-retain-talent");
		const clear_HR_policies = inputValues.find((input) => input.id === "md-enterprise-ready-clear-HR-policies");
		const clear_environmental_sustainability_policies = inputValues.find(
			(input) => input.id === "md-enterprise-ready-clear-environmental-sustainability-policies"
		);
		const social_responsibility_initiatives = inputValues.find((input) => input.id === "md-enterprise-ready-social-responsibility-initiatives");
		const depend_on_a_Web = inputValues.find((input) => input.id === "md-enterprise-ready-depend-on-a-Web");
		const SOC2_compliant = inputValues.find((input) => input.id === "md-enterprise-ready-SOC-2-compliant");
		const platform_meet_compliance = inputValues.find((input) => input.id === "md-enterprise-ready-platform-meet-compliance");
		const technology_infrastructure_scale = inputValues.find((input) => input.id === "md-enterprise-ready-technology-infrastructure-scale");
		const system_monitoring = inputValues.find((input) => input.id === "md-enterprise-ready-system-monitoring");
		const robust_data_analytics = inputValues.find((input) => input.id === "md-enterprise-ready-robust-data-analytics");
		const disaster_recovery_plan = inputValues.find((input) => input.id === "md-enterprise-ready-disaster-recovery-plan");
		const technological_upgrades = inputValues.find((input) => input.id === "md-enterprise-ready-technological-upgrades");
		const hardwareproduct = inputValues.find((input) => input.id === "md-enterprise-ready-hardware-product");
		const deliver_the_hardware = inputValues.find((input) => input.id === "md-enterprise-ready-deliver-the-hardware");
		const maintain_the_hardware = inputValues.find((input) => input.id === "md-enterprise-ready-maintain-the-hardware");
		const hardware_returned = inputValues.find((input) => input.id === "md-enterprise-ready-hardware-returned");

		if (
			monthlyRevenue?.value?.length > 0 &&
			monthlyRevenue?.error?.length === 0 &&
			capRaised?.value?.length > 0 &&
			capRaised?.error?.length === 0 &&
			currentRunway?.value?.length > 0 &&
			currentRunway?.error?.length === 0 &&
			companySize?.value?.length > 0 &&
			companySize?.error?.length === 0 &&
			readyStartegicPlan?.value?.length > 0 &&
			readyStartegicPlan?.error?.length === 0 &&
			readyStrategicPartnerships?.value?.length > 0 &&
			readyStrategicPartnerships?.error?.length === 0 &&
			strategy_for_leveraging?.value?.length > 0 &&
			strategy_for_leveraging?.error?.length === 0 &&
			patent?.value?.length > 0 &&
			patent?.error?.length === 0 &&
			startup_own_cash?.value?.length > 0 &&
			startup_own_cash?.error?.length === 0 &&
			any_litigation?.value?.length > 0 &&
			any_litigation?.error?.length === 0 &&
			legal_liabilities?.value?.length > 0 &&
			legal_liabilities?.error?.length === 0 &&
			product_offering_scale?.value?.length > 0 &&
			product_offering_scale?.error?.length === 0 &&
			unique_value_proposition?.value?.length > 0 &&
			unique_value_proposition?.error?.length === 0 &&
			//----
			ready_for_procurement?.value?.length > 0 &&
			ready_for_procurement?.error?.length === 0 &&
			competitive_landscape?.value?.length > 0 &&
			competitive_landscape?.error?.length === 0 &&
			ready_roadmap?.value?.length > 0 &&
			ready_roadmap?.error?.length === 0 &&
			process_for_soliciting?.value?.length > 0 &&
			process_for_soliciting?.error?.length === 0 &&
			support_customers?.value?.length > 0 &&
			support_customers?.error?.length === 0 &&
			flagship_customer?.value?.length > 0 &&
			flagship_customer?.error?.length === 0 &&
			proven_pricing_strategy?.value?.length > 0 &&
			proven_pricing_strategy?.error?.length === 0 &&
			proven_sales?.value?.length > 0 &&
			proven_sales?.error?.length === 0 &&
			measure_the_success?.value?.length > 0 &&
			measure_the_success?.error?.length === 0 &&
			endorsements?.value?.length > 0 &&
			endorsements?.error?.length === 0 &&
			business_meet_operational?.value?.length > 0 &&
			business_meet_operational?.error?.length === 0 &&
			clear_policies_for_regulatory?.value?.length > 0 &&
			clear_policies_for_regulatory?.error?.length === 0 &&
			attract_and_retain_talent?.value?.length > 0 &&
			attract_and_retain_talent?.error?.length === 0 &&
			clear_HR_policies?.value?.length > 0 &&
			clear_HR_policies?.error?.length === 0 &&
			clear_environmental_sustainability_policies?.value?.length > 0 &&
			clear_environmental_sustainability_policies?.error?.length === 0 &&
			social_responsibility_initiatives?.value?.length > 0 &&
			social_responsibility_initiatives?.error?.length === 0 &&
			depend_on_a_Web?.value?.length > 0 &&
			depend_on_a_Web?.error?.length === 0 &&
			SOC2_compliant?.value?.length > 0 &&
			SOC2_compliant?.error?.length === 0 &&
			platform_meet_compliance?.value?.length > 0 &&
			platform_meet_compliance?.error?.length === 0 &&
			technology_infrastructure_scale?.value?.length > 0 &&
			technology_infrastructure_scale?.error?.length === 0 &&
			system_monitoring?.value?.length > 0 &&
			system_monitoring?.error?.length === 0 &&
			robust_data_analytics?.value?.length > 0 &&
			robust_data_analytics?.error?.length === 0 &&
			disaster_recovery_plan?.value?.length > 0 &&
			disaster_recovery_plan?.error?.length === 0 &&
			technological_upgrades?.value?.length > 0 &&
			technological_upgrades?.error?.length === 0 &&
			hardwareproduct?.value?.length > 0 &&
			hardwareproduct?.error?.length === 0 &&
			deliver_the_hardware?.value?.length > 0 &&
			deliver_the_hardware?.error?.length === 0 &&
			maintain_the_hardware?.value?.length > 0 &&
			maintain_the_hardware?.error?.length === 0 &&
			hardware_returned?.value?.length > 0 &&
			hardware_returned?.error?.length === 0 &&
			//----
			currentStage?.value?.length > 0 &&
			currentStage?.error?.length === 0
		)
			return true;
		else return false;
	};

	const saveDataToApi = async (data) => {
		// TODO --  make sure to update the name of the do-it ID here to match the do-it ID of the DB step field.
		const moduleStep = getModuleStepByUUID(moduleSteps, "md-enterprise_ready_do-it");
		if (!moduleStep) return;
		const autoSaveTime = await saveModuleResponses(currentVersion, moduleStep, data, true, getCompletionStatus(data));
		if (!currentVersion?.isCompleted && getCompletionStatus(data)) {
			getVersions();
		}
		setLastSaved(autoSaveTime);
		setsaveReady(false);
	};

	const getStartupDetails = async () => {
		const startupProfileRes = await getStartupProfile();

		if (startupProfileRes) {
			setScoreLoader(false);
			setStartupProfileDetails(startupProfileRes);
		}
	};

	const enterpriseReadyScoreCalculationApi = async (data, historySuccessData, foundingTeamExpData, foundingTeamSkillsData) => {
		const companyTags = [...historySuccessData, ...foundingTeamExpData, ...foundingTeamSkillsData];

		const companyTagObj = companyTags.reduce((acc, item) => {
			if (item.checked) {
				acc[item.id] = item.checked;
			}
			return acc;
		}, {});

		const monthlyRevenue = data.find((input) => input.id === "md-enterprise-ready-monthlyRevenue")?.value;
		const capRaised = data.find((input) => input.id === "md-enterprise-ready-capitalRaised")?.value;
		const currentRunway = data.find((input) => input.id === "md-enterprise-ready-current-runway")?.value;

		const companySize = data.find((input) => input.id === "md-enterprise-ready-companySize")?.value;
		const readyStartegicPlan = data.find((input) => input.id === "md-enterprise-ready-strategic-plan")?.value;
		const readyStrategicPartnerships = data.find((input) => input.id === "md-enterprise-ready-strategic-partnerships")?.value;
		const patent = data.find((input) => input.id === "md-enterprise-ready-patent")?.value;
		const strategy_for_leveraging = data.find((input) => input.id === "md-enterprise-ready-strategy-for-leveraging")?.value;
		const startup_own_cash = data.find((input) => input.id === "md-enterprise-ready-startup-own-cash")?.value;
		const any_litigation = data.find((input) => input.id === "md-enterprise-ready-any-litigation")?.value;
		const legal_liabilities = data.find((input) => input.id === "md-enterprise-ready-legal-liabilities")?.value;
		const product_offering_scale = data.find((input) => input.id === "md-enterprise-ready-product-offering-scale")?.value;
		const unique_value_proposition = data.find((input) => input.id === "md-enterprise-ready-unique-value-proposition")?.value;

		const competitive_landscape = data.find((input) => input.id === "md-enterprise-ready-competitive-landscape")?.value;
		const ready_roadmap = data.find((input) => input.id === "md-enterprise-ready-roadmap")?.value;
		const process_for_soliciting = data.find((input) => input.id === "md-enterprise-ready-process-for-soliciting")?.value;
		const support_customers = data.find((input) => input.id === "md-enterprise-ready-support-customers")?.value;
		const flagship_customer = data.find((input) => input.id === "md-enterprise-ready-flagship-customer")?.value;
		const proven_pricing_strategy = data.find((input) => input.id === "md-enterprise-ready-proven-pricing-strategy")?.value;
		const proven_sales = data.find((input) => input.id === "md-enterprise-ready-proven-sales")?.value;
		const measure_the_success = data.find((input) => input.id === "md-enterprise-measure-the-success")?.value;
		const endorsements = data.find((input) => input.id === "md-enterprise-endorsements")?.value;
		const business_meet_operational = data.find((input) => input.id === "md-enterprise-ready-business-meet-operational")?.value;
		const clear_policies_for_regulatory = data.find((input) => input.id === "md-enterprise-ready-clear-policies-for-regulatory")?.value;
		const ready_for_procurement = data.find((input) => input.id === "md-enterprise-ready-ready-for-procurement")?.value;
		const attract_and_retain_talent = data.find((input) => input.id === "md-enterprise-ready-attract-and-retain-talent")?.value;
		const clear_HR_policies = data.find((input) => input.id === "md-enterprise-ready-clear-HR-policies")?.value;
		const clear_environmental_sustainability_policies = data.find(
			(input) => input.id === "md-enterprise-ready-clear-environmental-sustainability-policies"
		)?.value;
		const social_responsibility_initiatives = data.find((input) => input.id === "md-enterprise-ready-social-responsibility-initiatives")?.value;
		const depend_on_a_Web = data.find((input) => input.id === "md-enterprise-ready-depend-on-a-Web")?.value;
		const SOC2_compliant = data.find((input) => input.id === "md-enterprise-ready-SOC-2-compliant")?.value;
		const platform_meet_compliance = data.find((input) => input.id === "md-enterprise-ready-platform-meet-compliance")?.value;
		const technology_infrastructure_scale = data.find((input) => input.id === "md-enterprise-ready-technology-infrastructure-scale")?.value;
		const system_monitoring = data.find((input) => input.id === "md-enterprise-ready-system-monitoring")?.value;
		const robust_data_analytics = data.find((input) => input.id === "md-enterprise-ready-robust-data-analytics")?.value;
		const disaster_recovery_plan = data.find((input) => input.id === "md-enterprise-ready-disaster-recovery-plan")?.value;
		const technological_upgrades = data.find((input) => input.id === "md-enterprise-ready-technological-upgrades")?.value;
		const hardwareproduct = data.find((input) => input.id === "md-enterprise-ready-hardware-product")?.value;
		const deliver_the_hardware = data.find((input) => input.id === "md-enterprise-ready-deliver-the-hardware")?.value;
		const maintain_the_hardware = data.find((input) => input.id === "md-enterprise-ready-maintain-the-hardware")?.value;
		const hardware_returned = data.find((input) => input.id === "md-enterprise-ready-hardware-returned")?.value;
		const payloadData = {
			financials: {
				"current-runway": currentRunway,
				"monthly-revenue": monthlyRevenue,
				"capital-raised": capRaised,
				"team-size": companySize,
			},
			risk: {
				"strategic-plan": readyStartegicPlan,
				"strategic-partnership": readyStrategicPartnerships,
				has_patent: patent,
				"strategy-for-leverging-product": strategy_for_leveraging,
				"startup-own-cash": startup_own_cash,
				"liens-against-startup": any_litigation,
				"legal-liabilities": legal_liabilities,
			},
			"product-service": {
				"is-your-startup-scalable": product_offering_scale,
				"startup-offers-unqiue-value": unique_value_proposition,
				"competitive-landscape": competitive_landscape,
				"roadmap-for-development": ready_roadmap,
				"process-for-customer-feedback": process_for_soliciting,
				"process-for-customer-support": support_customers,
				"flagship-customer": flagship_customer,
				"pricing-strategy": proven_pricing_strategy,
				"sales-marketing-strategy": proven_sales,
				"success-metrics-sales-marketing": measure_the_success,
				"endorsements-testimonials": endorsements,
			},
			"business-operations": {
				"business-meet-compilance-requirement": business_meet_operational,
				"policies-for-regulatory-compliance": clear_policies_for_regulatory,
				"procurement-contracts": ready_for_procurement,
				"attract-retain-talent": attract_and_retain_talent,
				"hr-policies": clear_HR_policies,
				"policies-environmental-sustainability": clear_environmental_sustainability_policies,
				"social-responsibility-initiatives": social_responsibility_initiatives,
			},
			"software-platform":
				depend_on_a_Web === "Yes"
					? {
							"depend-on-saas-platform": depend_on_a_Web,
							"soc-compliant": SOC2_compliant,
							"meet-compliance-requirements": platform_meet_compliance,
							"technology-infrastructure": technology_infrastructure_scale,
							"system-monitoring-alets": system_monitoring,
							"data-analytics-reporting": robust_data_analytics,
							"disaster-recovery-plan": disaster_recovery_plan,
							"technological-upgrade": technological_upgrades,
					  }
					: {
							"depend-on-saas-platform": depend_on_a_Web,
					  },
			hardware:
				hardwareproduct === "Yes"
					? {
							"have-hardware-product": hardwareproduct,
							"deliver-hardware": deliver_the_hardware,
							"maintain-hardware": maintain_the_hardware,
							"hardware-returned": hardware_returned,
					  }
					: {
							"have-hardware-product": hardwareproduct,
					  },
			"startup-credentials": companyTagObj,
		};

		setScoreLoader(true);
		return services
			.enterpriseReadyScoreApi(payloadData)
			.then((response) => {
				if (response.data.message === "Success") {
					setEnterpriseScore(response?.data?.data?.totalScore);
					return `${response?.data?.data?.totalScore}`;
				}
			})
			.catch((e) => {
				setScoreLoader(false);
				return "error";
			});
	};

	const saveStartupDataToApi = async (data, historySuccessData, foundingTeamExpData, foundingTeamSkillsData) => {
		const companyTags = [
			...historySuccessData.filter((historyItem) => historyItem.checked),
			...foundingTeamExpData.filter((historyItem) => historyItem.checked),
			...foundingTeamSkillsData.filter((historyItem) => historyItem.checked),
		];

		const updatedSuccessList = companyTags.map((item) => {
			return item.id;
		});
		const enterpriseScoreRes = await enterpriseReadyScoreCalculationApi(data, historySuccessData, foundingTeamExpData, foundingTeamSkillsData);

		const monthlyRevenue = data.find((input) => input.id === "md-enterprise-ready-monthlyRevenue")?.value;
		const capRaised = data.find((input) => input.id === "md-enterprise-ready-capitalRaised")?.value;
		const currentRunway = data.find((input) => input.id === "md-enterprise-ready-current-runway")?.value;

		const companySize = data.find((input) => input.id === "md-enterprise-ready-companySize")?.value;
		const readyStartegicPlan = data.find((input) => input.id === "md-enterprise-ready-strategic-plan")?.value;
		const readyStrategicPartnerships = data.find((input) => input.id === "md-enterprise-ready-strategic-partnerships")?.value;
		const patent = data.find((input) => input.id === "md-enterprise-ready-patent")?.value;
		const strategy_for_leveraging = data.find((input) => input.id === "md-enterprise-ready-strategy-for-leveraging")?.value;
		const startup_own_cash = data.find((input) => input.id === "md-enterprise-ready-startup-own-cash")?.value;
		const any_litigation = data.find((input) => input.id === "md-enterprise-ready-any-litigation")?.value;
		const legal_liabilities = data.find((input) => input.id === "md-enterprise-ready-legal-liabilities")?.value;
		const product_offering_scale = data.find((input) => input.id === "md-enterprise-ready-product-offering-scale")?.value;
		const unique_value_proposition = data.find((input) => input.id === "md-enterprise-ready-unique-value-proposition")?.value;

		const competitive_landscape = data.find((input) => input.id === "md-enterprise-ready-competitive-landscape")?.value;
		const ready_roadmap = data.find((input) => input.id === "md-enterprise-ready-roadmap")?.value;
		const process_for_soliciting = data.find((input) => input.id === "md-enterprise-ready-process-for-soliciting")?.value;
		const support_customers = data.find((input) => input.id === "md-enterprise-ready-support-customers")?.value;
		const flagship_customer = data.find((input) => input.id === "md-enterprise-ready-flagship-customer")?.value;
		const proven_pricing_strategy = data.find((input) => input.id === "md-enterprise-ready-proven-pricing-strategy")?.value;
		const proven_sales = data.find((input) => input.id === "md-enterprise-ready-proven-sales")?.value;
		const measure_the_success = data.find((input) => input.id === "md-enterprise-measure-the-success")?.value;
		const endorsements = data.find((input) => input.id === "md-enterprise-endorsements")?.value;
		const business_meet_operational = data.find((input) => input.id === "md-enterprise-ready-business-meet-operational")?.value;
		const clear_policies_for_regulatory = data.find((input) => input.id === "md-enterprise-ready-clear-policies-for-regulatory")?.value;
		const ready_for_procurement = data.find((input) => input.id === "md-enterprise-ready-ready-for-procurement")?.value;
		const attract_and_retain_talent = data.find((input) => input.id === "md-enterprise-ready-attract-and-retain-talent")?.value;
		const clear_HR_policies = data.find((input) => input.id === "md-enterprise-ready-clear-HR-policies")?.value;
		const clear_environmental_sustainability_policies = data.find(
			(input) => input.id === "md-enterprise-ready-clear-environmental-sustainability-policies"
		)?.value;
		const social_responsibility_initiatives = data.find((input) => input.id === "md-enterprise-ready-social-responsibility-initiatives")?.value;
		const depend_on_a_Web = data.find((input) => input.id === "md-enterprise-ready-depend-on-a-Web")?.value;
		const SOC2_compliant = data.find((input) => input.id === "md-enterprise-ready-SOC-2-compliant")?.value;
		const platform_meet_compliance = data.find((input) => input.id === "md-enterprise-ready-platform-meet-compliance")?.value;
		const technology_infrastructure_scale = data.find((input) => input.id === "md-enterprise-ready-technology-infrastructure-scale")?.value;
		const system_monitoring = data.find((input) => input.id === "md-enterprise-ready-system-monitoring")?.value;
		const robust_data_analytics = data.find((input) => input.id === "md-enterprise-ready-robust-data-analytics")?.value;
		const disaster_recovery_plan = data.find((input) => input.id === "md-enterprise-ready-disaster-recovery-plan")?.value;
		const technological_upgrades = data.find((input) => input.id === "md-enterprise-ready-technological-upgrades")?.value;
		const hardwareproduct = data.find((input) => input.id === "md-enterprise-ready-hardware-product")?.value;
		const deliver_the_hardware = data.find((input) => input.id === "md-enterprise-ready-deliver-the-hardware")?.value;
		const maintain_the_hardware = data.find((input) => input.id === "md-enterprise-ready-maintain-the-hardware")?.value;
		const hardware_returned = data.find((input) => input.id === "md-enterprise-ready-hardware-returned")?.value;
		const creativeScale = data.find((input) => input.id === "md-enterprise-ready-creativeScale")?.value;

		const enterpriseReadyJson = {
			"current-runway": currentRunway,
			"strategic-plan": readyStartegicPlan,
			"strategic-partnerships": readyStrategicPartnerships,
			patent: patent,
			"strategy-for-leveraging": strategy_for_leveraging,
			"startup-own-cash": startup_own_cash,
			"any-litigation": any_litigation,
			"legal-liabilities": legal_liabilities,
			"product-offering-scale": product_offering_scale,
			"unique-value-proposition": unique_value_proposition,
			"competitive-landscape": competitive_landscape,
			roadmap: ready_roadmap,
			"process-for-soliciting": process_for_soliciting,
			"support-customers": support_customers,
			"flagship-customer": flagship_customer,
			"proven-pricing-strategy": proven_pricing_strategy,
			"proven-sales": proven_sales,
			"measure-the-success": measure_the_success,
			endorsements: endorsements,
			"business-meet-operational": business_meet_operational,
			"clear-policies-for-regulatory": clear_policies_for_regulatory,
			"ready-for-procurement": ready_for_procurement,
			"attract-and-retain-talent": attract_and_retain_talent,
			"clear-HR-policies": clear_HR_policies,
			"clear-environmental-sustainability-policies": clear_environmental_sustainability_policies,
			"social-responsibility-initiatives": social_responsibility_initiatives,
			"depend-on-a-Web": depend_on_a_Web,
			"SOC-2-compliant": SOC2_compliant,
			"platform-meet-compliance": platform_meet_compliance,
			"technology-infrastructure-scale": technology_infrastructure_scale,
			"system-monitoring": system_monitoring,
			"robust-data-analytics": robust_data_analytics,
			"disaster-recovery-plan": disaster_recovery_plan,
			"technological-upgrades": technological_upgrades,
			"hardware-product": hardwareproduct,
			"deliver-the-hardware": deliver_the_hardware,
			"maintain-the-hardware": maintain_the_hardware,
			"hardware-returned": hardware_returned,
			creativeScale: creativeScale,
		};
		const payloadData = {
			...startupProfileDetails,
			capitalRaised: data.find((input) => input.id === "md-enterprise-ready-capitalRaised")?.value,
			runway: data.find((input) => input.id === "md-enterprise-ready-current-runway")?.value,
			companySize: data.find((input) => input.id === "md-enterprise-ready-companySize")?.value,
			monthlyRevenue: data.find((input) => input.id === "md-enterprise-ready-monthlyRevenue")?.value,
			companyTags: updatedSuccessList,
			currentStage: data.find((input) => input.id === "md-enterprise-ready-currentStage")?.value,
			hasPatent: data.find((input) => input.id === "md-enterprise-ready-patent")?.value,
			startupScalable: data.find((input) => input.id === "md-enterprise-ready-product-offering-scale")?.value,
			enterpriseReadyScore: enterpriseScoreRes,
			enterpriseReadyJson: enterpriseReadyJson && JSON.stringify(enterpriseReadyJson),
		};
		if (enterpriseScoreRes) {
			return services
				.updateCompanyProfile(payloadData)
				.then((response) => {
					if (response?.data?.message === "Success") {
						setTimeout(() => {
							getStartupDetails();
						}, [2000]);

						return response?.data;
					}
				})
				.catch((e) => {
					setScoreLoader(false);
					console.log("Submit Error", e);
				});
		}
	};

	// Create a debounced version of the API function
	const debouncedSave = useRef(
		debounce((data) => {
			saveDataToApi(data);
		}, 1000)
	).current;

	// Create a debounced version of the API function
	const debouncedStartupDataSave = useRef(
		debounce((data, historySuccessData, foundingTeamExpData, foundingTeamSkillsData) => {
			saveStartupDataToApi(data, historySuccessData, foundingTeamExpData, foundingTeamSkillsData);
		}, 1000)
	).current;

	// Function to handle input changes and trigger the debounced API call
	const handleInputChange = (id, value) => {
		setsaveReady(true);
		if (
			value?.length > 500 &&
			(id === "md-enterprise-ready-fulltime" ||
				id === "md-enterprise-ready-incorporated" ||
				// id === "md-enterprise-ready-captable" ||
				id === "md-enterprise-ready-website" ||
				id === "md-enterprise-ready-investorPitch" ||
				id === "md-enterprise-ready-currentStage" ||
				id === "md-enterprise-ready-currentState" ||
				id === "md-enterprise-ready-monthlyRevenue")
			// || id === "md-enterprise-ready-growthRate"
			// ||id === "md-enterprise-ready-scalable"
			// TODO --  add addtl. field objects here. One for each field. where you want to establish a max input value
			// || id === "FIELD ID" || id === "FIELD ID"
		) {
			setInputValues((prevInputValues) =>
				prevInputValues.map((input) => (input.id === id ? { ...input, value, error: "Cannot exceed 500 characters." } : input))
			);
		} else if (
			(id === "md-enterprise-ready-capitalRaised" || id === "md-enterprise-ready-monthlyRevenue" || id === "md-enterprise-ready-current-runway") &&
			parseInt(value) > 999999999
		) {
			setInputValues((prevInputValues) =>
				prevInputValues.map((input) =>
					input.id === id
						? {
								...input,
								value,
								error: `Value must be under $1B`,
						  }
						: input
				)
			);
		} else {
			setInputValues((prevInputValues) => prevInputValues.map((input) => (input.id === id ? { ...input, value, error: "" } : input)));
			// setInputValues((prevInputValues) => prevInputValues.map((input) => (input.id === id ? { ...input, value, error: "" } : input)));
		}
	};

	const updateInputValues = async () => {
		setloadingValues(true);
		const updatedResponses = await getResponses();

		setResponseId(updatedResponses?.id);

		// TODO --  make sure to update the name of the do-it ID here to match the do-it ID of the DB step field.
		const stepResponse = getAnswersByStepUuid(updatedResponses, "md-enterprise_ready_do-it");

		const updatedInputValues = inputValues?.map((input) => {
			const apiResponseEntry = stepResponse?.find((apiEntry) => apiEntry?.id === input?.id);
			if (apiResponseEntry) {
				return { ...input, value: apiResponseEntry.value, error: apiResponseEntry.value?.length > 500 ? "Cannot exceed 500 characters." : "" };
			}
			return input;
		});

		const capitalRaisedIndex = updatedInputValues.findIndex((input) => input.id === "md-enterprise-ready-capitalRaised");
		let capRaisedVal = startupProfileDetails?.capitalRaised;
		updatedInputValues[capitalRaisedIndex].value = capRaisedVal ? capRaisedVal?.toString() : "";

		const currentRunwayIndex = updatedInputValues.findIndex((input) => input.id === "md-enterprise-ready-current-runway");
		let currentRunwayVal = startupProfileDetails?.runway;
		updatedInputValues[currentRunwayIndex].value = currentRunwayVal ? currentRunwayVal?.toString() : "";

		const companySizeIndex = updatedInputValues.findIndex((input) => input.id === "md-enterprise-ready-companySize");
		let companySizeVal = startupProfileDetails?.companySize;
		updatedInputValues[companySizeIndex].value = companySizeVal ? companySizeVal?.toString() : "";

		const currentStageIndex = updatedInputValues.findIndex((input) => input.id === "md-enterprise-ready-currentStage");
		updatedInputValues[currentStageIndex].value = startupProfileDetails?.currentStage;

		const monthlyRevenueIndex = updatedInputValues.findIndex((input) => input.id === "md-enterprise-ready-monthlyRevenue");
		let monthlyRevenueVal = startupProfileDetails?.monthlyRevenue;
		updatedInputValues[monthlyRevenueIndex].value = monthlyRevenueVal ? monthlyRevenueVal?.toString() : "";

		const hasPatentIndex = updatedInputValues.findIndex((input) => input.id === "md-enterprise-ready-patent");
		updatedInputValues[hasPatentIndex].value = startupProfileDetails?.hasPatent || "No";

		const readyStartupScalableIndex = updatedInputValues.findIndex((input) => input.id === "md-enterprise-ready-product-offering-scale");
		updatedInputValues[readyStartupScalableIndex].value = startupProfileDetails?.startupScalable || "No";

		// New Enterprise ready json object

		const enterpriseJson = startupProfileDetails?.enterpriseReadyJson && JSON.parse(startupProfileDetails?.enterpriseReadyJson);

		const startegicPlanIndex = updatedInputValues.findIndex((input) => input.id === "md-enterprise-ready-strategic-plan");
		updatedInputValues[startegicPlanIndex].value = enterpriseJson?.["strategic-plan"] || startegicPlanIndex?.value || "No";

		const startegicPartnershipIndex = updatedInputValues.findIndex((input) => input.id === "md-enterprise-ready-strategic-partnerships");
		updatedInputValues[startegicPartnershipIndex].value = enterpriseJson?.["strategic-partnerships"] || startegicPartnershipIndex?.value || "No";

		const startupOwnCashIndex = updatedInputValues.findIndex((input) => input.id === "md-enterprise-ready-startup-own-cash");
		updatedInputValues[startupOwnCashIndex].value = enterpriseJson?.["startup-own-cash"] || startupOwnCashIndex?.value || "No";

		const anyLitigationIndex = updatedInputValues.findIndex((input) => input.id === "md-enterprise-ready-any-litigation");
		updatedInputValues[anyLitigationIndex].value = enterpriseJson?.["any-litigation"] || anyLitigationIndex?.value || "No";

		const legalLiabilitiesIndex = updatedInputValues.findIndex((input) => input.id === "md-enterprise-ready-legal-liabilities");
		updatedInputValues[legalLiabilitiesIndex].value = enterpriseJson?.["legal-liabilities"] || legalLiabilitiesIndex?.value || "No";

		const productOfferingScaleIndex = updatedInputValues.findIndex((input) => input.id === "md-enterprise-ready-product-offering-scale");
		updatedInputValues[productOfferingScaleIndex].value = enterpriseJson?.["product-offering-scale"] || productOfferingScaleIndex?.value || "No";

		const uniqueValuepropositionIndex = updatedInputValues.findIndex((input) => input.id === "md-enterprise-ready-unique-value-proposition");
		updatedInputValues[uniqueValuepropositionIndex].value = enterpriseJson?.["unique-value-proposition"] || uniqueValuepropositionIndex?.value || "No";

		const roadmapIndex = updatedInputValues.findIndex((input) => input.id === "md-enterprise-ready-roadmap");
		updatedInputValues[roadmapIndex].value = enterpriseJson?.["roadmap"] || roadmapIndex?.value || "No";

		const readyProcessSolicitingIndex = updatedInputValues.findIndex((input) => input.id === "md-enterprise-ready-process-for-soliciting");
		updatedInputValues[readyProcessSolicitingIndex].value = enterpriseJson?.["process-for-soliciting"] || readyProcessSolicitingIndex?.value || "No";

		const supportCustomerIndex = updatedInputValues.findIndex((input) => input.id === "md-enterprise-ready-support-customers");
		updatedInputValues[supportCustomerIndex].value = enterpriseJson?.["support-customers"] || supportCustomerIndex?.value || "No";

		const flagshipCustomerIndex = updatedInputValues.findIndex((input) => input.id === "md-enterprise-ready-flagship-customer");
		updatedInputValues[flagshipCustomerIndex].value = enterpriseJson?.["flagship-customer"] || flagshipCustomerIndex?.value || "No";

		const provenPricingStrategyIndex = updatedInputValues.findIndex((input) => input.id === "md-enterprise-ready-proven-pricing-strategy");
		updatedInputValues[provenPricingStrategyIndex].value = enterpriseJson?.["proven-pricing-strategy"] || provenPricingStrategyIndex?.value || "No";

		const provenSalesIndex = updatedInputValues.findIndex((input) => input.id === "md-enterprise-ready-proven-sales");
		updatedInputValues[provenSalesIndex].value = enterpriseJson?.["proven-sales"] || provenSalesIndex?.value || "No";

		const measureTheSuccessIndex = updatedInputValues.findIndex((input) => input.id === "md-enterprise-measure-the-success");
		updatedInputValues[measureTheSuccessIndex].value = enterpriseJson?.["measure-the-success"] || measureTheSuccessIndex?.value || "No";

		const endorsementsIndex = updatedInputValues.findIndex((input) => input.id === "md-enterprise-endorsements");
		updatedInputValues[endorsementsIndex].value = enterpriseJson?.["endorsements"] || endorsementsIndex?.value || "No";

		const businessMeetOperationalIndex = updatedInputValues.findIndex((input) => input.id === "md-enterprise-ready-business-meet-operational");
		updatedInputValues[businessMeetOperationalIndex].value = enterpriseJson?.["business-meet-operational"] || businessMeetOperationalIndex?.value || "No";

		const clearPoliciesIndex = updatedInputValues.findIndex((input) => input.id === "md-enterprise-ready-clear-policies-for-regulatory");
		updatedInputValues[clearPoliciesIndex].value = enterpriseJson?.["clear-policies-for-regulatory"] || clearPoliciesIndex?.value || "No";

		const readyForProcurementIndex = updatedInputValues.findIndex((input) => input.id === "md-enterprise-ready-ready-for-procurement");
		updatedInputValues[readyForProcurementIndex].value = enterpriseJson?.["ready-for-procurement"] || readyForProcurementIndex?.value || "No";

		const attrectAndRetainTalentIndex = updatedInputValues.findIndex((input) => input.id === "md-enterprise-ready-attract-and-retain-talent");
		updatedInputValues[attrectAndRetainTalentIndex].value = enterpriseJson?.["attract-and-retain-talent"] || attrectAndRetainTalentIndex?.value || "No";

		const clearHrPoliciesIndex = updatedInputValues.findIndex((input) => input.id === "md-enterprise-ready-clear-HR-policies");
		updatedInputValues[clearHrPoliciesIndex].value = enterpriseJson?.["clear-HR-policies"] || clearHrPoliciesIndex?.value || "No";

		const environmentalSustainabilityIndex = updatedInputValues.findIndex(
			(input) => input.id === "md-enterprise-ready-clear-environmental-sustainability-policies"
		);
		updatedInputValues[environmentalSustainabilityIndex].value =
			enterpriseJson?.["clear-environmental-sustainability-policies"] || environmentalSustainabilityIndex?.value || "No";

		const socialResponsibilityIndex = updatedInputValues.findIndex((input) => input.id === "md-enterprise-ready-social-responsibility-initiatives");
		updatedInputValues[socialResponsibilityIndex].value = enterpriseJson?.["social-responsibility-initiatives"] || socialResponsibilityIndex?.value || "No";

		const dependOnWebIndex = updatedInputValues.findIndex((input) => input.id === "md-enterprise-ready-depend-on-a-Web");
		updatedInputValues[dependOnWebIndex].value = enterpriseJson?.["depend-on-a-Web"] || dependOnWebIndex?.value || "No";

		const SOC2ComplilanceIndex = updatedInputValues.findIndex((input) => input.id === "md-enterprise-ready-SOC-2-compliant");
		updatedInputValues[SOC2ComplilanceIndex].value = enterpriseJson?.["SOC-2-compliant"] || SOC2ComplilanceIndex?.value || "No";

		const platformMeetCompilanceIndex = updatedInputValues.findIndex((input) => input.id === "md-enterprise-ready-platform-meet-compliance");
		updatedInputValues[platformMeetCompilanceIndex].value = enterpriseJson?.["platform-meet-compliance"] || platformMeetCompilanceIndex?.value || "No";

		const technologyInfrastructureIndex = updatedInputValues.findIndex((input) => input.id === "md-enterprise-ready-technology-infrastructure-scale");
		updatedInputValues[technologyInfrastructureIndex].value =
			enterpriseJson?.["technology-infrastructure-scale"] || technologyInfrastructureIndex?.value || "No";

		const systemMonitoringIndex = updatedInputValues.findIndex((input) => input.id === "md-enterprise-ready-system-monitoring");
		updatedInputValues[systemMonitoringIndex].value = enterpriseJson?.["system-monitoring"] || systemMonitoringIndex?.value || "No";

		const robustDataAnylysisIndex = updatedInputValues.findIndex((input) => input.id === "md-enterprise-ready-robust-data-analytics");
		updatedInputValues[robustDataAnylysisIndex].value = enterpriseJson?.["robust-data-analytics"] || robustDataAnylysisIndex?.value || "No";

		const disasterRecoveryPlanIndex = updatedInputValues.findIndex((input) => input.id === "md-enterprise-ready-disaster-recovery-plan");
		updatedInputValues[disasterRecoveryPlanIndex].value = enterpriseJson?.["disaster-recovery-plan"] || disasterRecoveryPlanIndex?.value || "No";

		const technologicalUpgradesIndex = updatedInputValues.findIndex((input) => input.id === "md-enterprise-ready-technological-upgrades");
		updatedInputValues[technologicalUpgradesIndex].value = enterpriseJson?.["technological-upgrades"] || technologicalUpgradesIndex?.value || "No";

		const hardwareProductIndex = updatedInputValues.findIndex((input) => input.id === "md-enterprise-ready-hardware-product");
		updatedInputValues[hardwareProductIndex].value = enterpriseJson?.["hardware-product"] || hardwareProductIndex?.value || "No";

		const deliverTheHardwareIndex = updatedInputValues.findIndex((input) => input.id === "md-enterprise-ready-deliver-the-hardware");
		updatedInputValues[deliverTheHardwareIndex].value = enterpriseJson?.["deliver-the-hardware"] || deliverTheHardwareIndex?.value || "No";

		const maintainTheHardwareIndex = updatedInputValues.findIndex((input) => input.id === "md-enterprise-ready-maintain-the-hardware");
		updatedInputValues[maintainTheHardwareIndex].value = enterpriseJson?.["maintain-the-hardware"] || maintainTheHardwareIndex?.value || "No";

		const hardwareReturnedIndex = updatedInputValues.findIndex((input) => input.id === "md-enterprise-ready-hardware-returned");
		updatedInputValues[hardwareReturnedIndex].value = enterpriseJson?.["hardware-returned"] || hardwareReturnedIndex?.value || "No";

		// ------------------------------------
		const companyTags = startupProfileDetails?.companyTags;

		const updatedSuccessList = historySuccessList.map((item) => {
			const foundScoreTag = companyTags.find((tag) => tag.scoreTag.id === item.id);
			if (foundScoreTag) {
				return { ...item, checked: true };
			}
			return item;
		});
		setHistorySuccessList(updatedSuccessList);

		const updatedFoundingTeamList = foundingTeamExpList.map((item) => {
			const foundScoreTag = companyTags.find((tag) => tag.scoreTag.id === item.id);
			if (foundScoreTag) {
				return { ...item, checked: true };
			}
			return item;
		});
		setFoundingTeamExpList(updatedFoundingTeamList);

		const updatedTeamSkillList = foundingTeamSkillsList.map((item) => {
			const foundScoreTag = companyTags.find((tag) => tag.scoreTag.id === item.id);
			if (foundScoreTag) {
				return { ...item, checked: true };
			}
			return item;
		});
		setFoundingTeamSkillsList(updatedTeamSkillList);

		setInputValues(updatedInputValues);
		setloadingValues(false);
		// getPDFDownload(updatedInputValues, powerupDetails?.powerUpName, (resp) => setPdfContent(resp));
	};
	const handleOpenIndicatorsDialog = () => {
		setOpenIndicatorsDialog(true);
	};
	const handleViewStartupGrid = () => {
		history.push(ROUTES_PATH.STARTUPGRID);
	};
	const generateAI = async () => {
		setgeneratingOutput(true);

		const response = await areYouEnterpriseAI({
			companyName: companyName,
			founderName: userName,
			companyIndustries: startupProfileDetails?.industry,
			creativity: inputValues.find((input) => input.id === "md-enterprise-ready-creativeScale")?.value * 0.2,
			answers: {
				currentStage: inputValues.find((input) => input.id === "md-enterprise-ready-currentStage")?.value,
				monthlyRevenue: inputValues.find((input) => input.id === "md-enterprise-ready-monthlyRevenue")?.value,
				capitalRaised: inputValues.find((input) => input.id === "md-enterprise-ready-capitalRaised")?.value,
				runway: inputValues.find((input) => input.id === "md-enterprise-ready-current-runway")?.value,
				companySize: inputValues.find((input) => input.id === "md-enterprise-ready-companySize")?.value,

				readyStartegicPlan: inputValues.find((input) => input.id === "md-enterprise-ready-strategic-plan")?.value,
				readyStrategicPartnerships: inputValues.find((input) => input.id === "md-enterprise-ready-strategic-partnerships")?.value,
				patent: inputValues.find((input) => input.id === "md-enterprise-ready-patent")?.value,
				strategy_for_leveraging: inputValues.find((input) => input.id === "md-enterprise-ready-strategy-for-leveraging")?.value,
				startup_own_cash: inputValues.find((input) => input.id === "md-enterprise-ready-startup-own-cash")?.value,
				any_litigation: inputValues.find((input) => input.id === "md-enterprise-ready-any-litigation")?.value,
				legal_liabilities: inputValues.find((input) => input.id === "md-enterprise-ready-legal-liabilities")?.value,
				product_offering_scale: inputValues.find((input) => input.id === "md-enterprise-ready-product-offering-scale")?.value,
				unique_value_proposition: inputValues.find((input) => input.id === "md-enterprise-ready-unique-value-proposition")?.value,

				competitive_landscape: inputValues.find((input) => input.id === "md-enterprise-ready-competitive-landscape")?.value,
				ready_roadmap: inputValues.find((input) => input.id === "md-enterprise-ready-roadmap")?.value,

				process_for_soliciting: inputValues.find((input) => input.id === "md-enterprise-ready-process-for-soliciting")?.value,
				support_customers: inputValues.find((input) => input.id === "md-enterprise-ready-support-customers")?.value,

				flagship_customer: inputValues.find((input) => input.id === "md-enterprise-ready-flagship-customer")?.value,
				proven_pricing_strategy: inputValues.find((input) => input.id === "md-enterprise-ready-proven-pricing-strategy")?.value,
				proven_sales: inputValues.find((input) => input.id === "md-enterprise-ready-proven-sales")?.value,
				measure_the_success: inputValues.find((input) => input.id === "md-enterprise-measure-the-success")?.value,

				endorsements: inputValues.find((input) => input.id === "md-enterprise-endorsements")?.value,

				business_meet_operational: inputValues.find((input) => input.id === "md-enterprise-ready-business-meet-operational")?.value,
				clear_policies_for_regulatory: inputValues.find((input) => input.id === "md-enterprise-ready-clear-policies-for-regulatory")?.value,
				ready_for_procurement: inputValues.find((input) => input.id === "md-enterprise-ready-ready-for-procurement")?.value,
				attract_and_retain_talent: inputValues.find((input) => input.id === "md-enterprise-ready-attract-and-retain-talent")?.value,
				clear_HR_policies: inputValues.find((input) => input.id === "md-enterprise-ready-clear-HR-policies")?.value,
				clear_environmental_sustainability_policies: inputValues.find(
					(input) => input.id === "md-enterprise-ready-clear-environmental-sustainability-policies"
				)?.value,
				social_responsibility_initiatives: inputValues.find((input) => input.id === "md-enterprise-ready-social-responsibility-initiatives")?.value,
				depend_on_a_Web: inputValues.find((input) => input.id === "md-enterprise-ready-depend-on-a-Web")?.value,
				SOC2_compliant: inputValues.find((input) => input.id === "md-enterprise-ready-SOC-2-compliant")?.value,
				platform_meet_compliance: inputValues.find((input) => input.id === "md-enterprise-ready-platform-meet-compliance")?.value,
				technology_infrastructure_scale: inputValues.find((input) => input.id === "md-enterprise-ready-technology-infrastructure-scale")?.value,
				system_monitoring: inputValues.find((input) => input.id === "md-enterprise-ready-system-monitoring")?.value,
				robust_data_analytics: inputValues.find((input) => input.id === "md-enterprise-ready-robust-data-analytics")?.value,
				disaster_recovery_plan: inputValues.find((input) => input.id === "md-enterprise-ready-disaster-recovery-plan")?.value,
				technological_upgrades: inputValues.find((input) => input.id === "md-enterprise-ready-technological-upgrades")?.value,
				hardwareproduct: inputValues.find((input) => input.id === "md-enterprise-ready-hardware-product")?.value,
				deliver_the_hardware: inputValues.find((input) => input.id === "md-enterprise-ready-deliver-the-hardware")?.value,
				maintain_the_hardware: inputValues.find((input) => input.id === "md-enterprise-ready-maintain-the-hardware")?.value,
				hardware_returned: inputValues.find((input) => input.id === "md-enterprise-ready-hardware-returned")?.value,
				creativeIndex: inputValues.find((input) => input.id === "md-enterprise-ready-creativeScale")?.value,
			},
		});
		if (response) {
			setOpenRatingModal(true);
		}
		handleInputChange("md-enterprise-ready-finalOutput", response);
		setgeneratingOutput(false);
		handleMixPanel("PowerUp Regenerated", {
			"Powerup ID": powerupDetails?.id,
			"Powerup Name": powerupDetails?.powerUpName,
		});
		// getPDFDownload(inputValuesRef?.current, powerupDetails?.powerUpName, (resp) => setPdfContent(resp));
	};

	const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
		height: 7,
		borderRadius: 5,
		[`&.${linearProgressClasses.colorPrimary}`]: {
			backgroundColor: theme.palette.grey,
		},
		[`& .${linearProgressClasses.bar}`]: {
			borderRadius: 5,
			backgroundColor: theme.palette.secondary,
		},
	}));

	const numericQuestionDataList = [
		{
			id: "md-enterprise-ready-monthlyRevenue",
			label: "What is your monthly revenue (MRR)?",
			infoTitle: "Do you have any monthly recurring revenue (MRR)?",
			infoBody:
				"Monthly Recurring Revenue (MRR) is essential to investors as it offers a predictable revenue stream and reflects the company's financial stability. It is a key metric in assessing the business's performance and growth potential, particularly for subscription-based businesses. High MRR can indicate a strong value proposition and good customer retention, both attractive features for investors.",
			type: "text",
			prefix: "$",
		},
		{
			id: "md-enterprise-ready-capitalRaised",
			label: "How much capital have you raised to date?",
			infoTitle: "How much capital have you raised to date?",
			infoBody:
				"It is acceptable to provide a ballpark estimate. We will source this value from your startup profile; changing it here will not overwrite the value on your profile.",
			type: "text",
			prefix: "$",
		},
		{
			id: "md-enterprise-ready-current-runway",
			label: "What is your current Runway (months)?",
			type: "text",
		},
		{
			id: "md-enterprise-ready-companySize",
			label: "What is your team size?",
			type: "text",
		},
	];

	return loadingValues ? (
		<CircularProgress />
	) : (
		<StandardTwoCol
			left={
				<PowerUpCard>
					<Box sx={{ display: "flex", flexDirection: "column", gap: "2.5rem" }}>
						<Box sx={{ display: "flex", flexDirection: "column", gap: "1.5rem" }}>
							<Typography variant="Text/md/Semibold">Input</Typography>
						</Box>
						<CustomAccordion label={"Financials"}>
							<Box sx={{ display: "flex", flexDirection: "column", gap: "1.5rem" }}>
								{" "}
								<Box>
									<Box sx={{ display: "flex", alignItems: "center", gap: "12px", mb: ".37rem" }}>
										<Typography variant="Text/sm/Medium" color="#344054" component={"p"}>
											Select stage of startup
										</Typography>
									</Box>
									<NewCustomSelect
										placeholder={"Select stage of startup"}
										error={inputValues.find((input) => input.id === "md-enterprise-ready-currentStage")?.error}
										value={
											<Typography variant="Text/Sm/Regular" color="#667085">
												{inputValues.find((input) => input.id === "md-enterprise-ready-currentStage")?.value}
											</Typography>
										}
										onChange={(e) => handleInputChange("md-enterprise-ready-currentStage", e.target.value)}
										options={CURRENT_STAGE_LIST}
									/>
									{inputValues.find((input) => input.id === "md-enterprise-ready-currentStage")?.error && (
										<Typography
											variant="Text/sm/Regular"
											component={"p"}
											sx={{ mt: ".37rem", color: (theme) => theme.palette.secondary.danger, textAlign: "right" }}
										>
											{inputValues.find((input) => input.id === "md-enterprise-ready-currentStage")?.error}
										</Typography>
									)}
								</Box>
								{/* --------------------****------------------------ */}
								{numericQuestionDataList?.map((questionData, index) => (
									<Box key={questionData?.id}>
										<Box sx={{ display: "flex", alignItems: "center", gap: "12px", mb: ".37rem" }}>
											<Typography variant="Text/sm/Medium" color="#344054" component="p">
												{index + 1}. {questionData?.label}
											</Typography>
											<InfoHelper title={questionData?.infoTitle} body={questionData?.infoBody} iconType="gray" />
										</Box>
										<NumericFormat
											fullWidth
											value={
												inputValues.find((input) => input.id === questionData?.id)?.value
													? Number(inputValues.find((input) => input.id === questionData?.id)?.value)
													: null
											}
											customInput={TextField}
											type={questionData?.type}
											thousandSeparator={questionData?.prefix ? true : false}
											prefix={questionData?.prefix}
											sx={{
												mt: 1.25,
												"& .MuiOutlinedInput-notchedOutline": {
													border:
														inputValues.find((input) => input.id === questionData?.id)?.error &&
														"5px solid rgba(240, 68, 56, 0.3) !important",
												},
												"& .MuiInputBase-root": {
													borderRadius: "30px",
													height: "60px",
													fontWeight: "500",
													fontSize: "16px",
													lineHeight: "24px",
													padding: "1.5px 6px",
												},
											}}
											onValueChange={(e) => handleInputChange(questionData?.id, (e.floatValue || 0).toString())}
										/>
										{inputValues.find((input) => input.id === questionData?.id)?.error && (
											<Typography
												variant="Text/sm/Regular"
												component="p"
												sx={{ mt: ".37rem", color: (theme) => theme.palette.secondary.danger, textAlign: "right" }}
											>
												{inputValues.find((input) => input.id === questionData.id)?.error}
											</Typography>
										)}
									</Box>
								))}
							</Box>
						</CustomAccordion>

						<CustomAccordion label={"Risk"} open={false}>
							<NewPlatformComponent category="Risk" inputValues={inputValues} handleInputChange={handleInputChange} />
						</CustomAccordion>

						<CustomAccordion label={"Startup Credentials"} open={false}>
							<Box sx={{ display: "flex", flexDirection: "column", gap: "1.5rem" }}>
								<CustomCheckboxList label="History of Success" ItemList={historySuccessList} setItemList={setHistorySuccessList} />
								<CustomCheckboxList label="Founding Team Experience" ItemList={foundingTeamExpList} setItemList={setFoundingTeamExpList} />
								<CustomCheckboxList label="Founding Team Skills" ItemList={foundingTeamSkillsList} setItemList={setFoundingTeamSkillsList} />
							</Box>
						</CustomAccordion>

						<CustomAccordion label={"Product / Service"} open={false}>
							<NewPlatformComponent category="Product-Service" inputValues={inputValues} handleInputChange={handleInputChange} />
						</CustomAccordion>

						<CustomAccordion label={"Business Operations"} open={false}>
							<NewPlatformComponent category="Business-Operations" inputValues={inputValues} handleInputChange={handleInputChange} />
						</CustomAccordion>

						<CustomAccordion label={"Software / Platform"} open={false}>
							<NewPlatformComponent category="Software" inputValues={inputValues} handleInputChange={handleInputChange} />
						</CustomAccordion>

						<CustomAccordion label={"Hardware"} open={false}>
							<NewPlatformComponent category="Hardware" inputValues={inputValues} handleInputChange={handleInputChange} />
						</CustomAccordion>
					</Box>

					<Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
						{lastSaved && (
							<Typography variant="Text/sm/Regular" sx={{ display: "flex", alignItems: "center", flex: 1 }}>
								<Check color="success" /> Auto Saved: {lastSaved}
							</Typography>
						)}

						<Button
							startIcon={generatingOutput && <CircularProgress size={"1.25rem"} sx={{ color: "white" }} />}
							variant="DS1"
							small
							onClick={() => generateAI()}
							disabled={generatingOutput || !noErrors()}
						>
							Generate
						</Button>
					</Box>
				</PowerUpCard>
			}
			right={
				<>
					<Box sx={{ position: "sticky", width: "100%", display: "flex", flexDirection: "column", gap: "1.75rem" }}>
						<PowerUpCard>
							<Box sx={{ display: "flex", width: "100%" }}>
								{scoreLoader ? (
									<CircularProgress sx={{ m: "auto" }} />
								) : (
									<Box display={"flex"} flexDirection={"column"} sx={{ height: 1, width: 1 }} gap={1} justifyContent={"center"}>
										<Typography display={"block"} variant="Text/md/Semibold" noWrap color="secondary.black">
											Enterprise Ready Score
										</Typography>
										<Box display={"flex"} flexDirection={"row"} alignItems="center" gap={2}>
											<Box width="95%">
												<BorderLinearProgress
													variant="determinate"
													value={enterpriseScore ? Number(enterpriseScore) : 0}
													maxValue={100}
												/>
											</Box>
											<Box>
												<Typography variant="Text/sm/Regular">{100 / enterpriseScore ? Number(enterpriseScore) : 0}%</Typography>
											</Box>
										</Box>
									</Box>
								)}
							</Box>
						</PowerUpCard>

						<PowerUpCard>
							<Box sx={{ display: "flex", flexDirection: "column", gap: "1.5rem", width: "100%" }}>
								<Box sx={{ display: "flex", gap: ".75rem", alignItems: "center" }}>
									<Box component={"img"} src={CODEPEN} sx={{ width: "2rem", height: "2rem" }} />
									<Typography variant="Text/md/Semibold" component={"p"}>
										AI Assisted Output
									</Typography>
								</Box>
								<Typography variant="Text/sm/Regular" component={"p"}>
									Experience AI magic as we generate your response!{" "}
								</Typography>
								<Box sx={{ display: "flex", flex: 1 }}>
									{generatingOutput ? (
										<TextAreaLoader />
									) : (
										<TextArea
											value={inputValues.find((input) => input.id === "md-enterprise-ready-finalOutput")?.value}
											onChange={(e) => handleInputChange("md-enterprise-ready-finalOutput", e.target.value)}
											minRows={10}
										/>
									)}
								</Box>
								<Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%", gap: "1rem", flexWrap: "wrap" }}>
									{pdfContent && getCompletionStatus(inputValuesRef.current) && (
										<TextDocDownload
											type={"preProcessed"}
											buttonText={"Download Your Responses"}
											fileName={`${currentVersion?.name}-Responses`}
											preProcessedArray={pdfContent}
											buttonVariant="DS1"
											buttonProps={{
												color: "secondary",
												small: true,
												onClick: () => {
													handleMixPanel("PowerUp Download Responses", {
														"Powerup ID": powerupDetails?.id,
														"Powerup Name": powerupDetails?.powerUpName,
													});
												},
											}}
										/>
									)}
									<Button
										variant="DS1"
										color="gray"
										small
										onClick={() => generateAI()}
										startIcon={generatingOutput && <CircularProgress size={"1.25rem"} sx={{ color: "inherit" }} />}
										disabled={generatingOutput || !inputValues.find((input) => input.id === "md-enterprise-ready-finalOutput")?.value}
									>
										Re-Generate
									</Button>
								</Box>
							</Box>
						</PowerUpCard>
						<PowerupRatingModal
							openRatingModal={openRatingModal}
							setOpenRatingModal={setOpenRatingModal}
							responseId={responseId}
							entityId={powerupDetails?.id}
						/>
					</Box>
				</>
			}
		/>
	);
}

export default MdPowerUpModule;

export const NewPlatformComponent = ({ category = "", inputValues = [], handleInputChange = () => {} }) => {
	const [platformQuestions, setPlatformQuestions] = useState([]);

	useEffect(() => {
		const softwarePlatformQuestions = [
			{
				id: "md-enterprise-ready-depend-on-a-Web",
				label: "Does your startup depend on a Web / SaaS platform?",
				disabled: false,
			},
			{
				id: "md-enterprise-ready-SOC-2-compliant",
				label: "Is your platform SOC 2 compliant?",
				disabled: inputValues.find((input) => input.id === "md-enterprise-ready-depend-on-a-Web")?.value !== "Yes",
			},
			{
				id: "md-enterprise-ready-platform-meet-compliance",
				label: "Does your platform meet operational, regional and industry-specific compliance requirements?",
				disabled: inputValues.find((input) => input.id === "md-enterprise-ready-depend-on-a-Web")?.value !== "Yes",
			},
			{
				id: "md-enterprise-ready-technology-infrastructure-scale",
				label: "Can the startup's technology infrastructure scale to meet increasing demands?",
				disabled: inputValues.find((input) => input.id === "md-enterprise-ready-depend-on-a-Web")?.value !== "Yes",
			},
			{
				id: "md-enterprise-ready-system-monitoring",
				label: "Have you implemented system monitoring, alerting, and support protocols?",
				disabled: inputValues.find((input) => input.id === "md-enterprise-ready-depend-on-a-Web")?.value !== "Yes",
			},
			{
				id: "md-enterprise-ready-robust-data-analytics",
				label: "Are there robust data analytics and reporting capabilities?",
				disabled: inputValues.find((input) => input.id === "md-enterprise-ready-depend-on-a-Web")?.value !== "Yes",
			},
			{
				id: "md-enterprise-ready-disaster-recovery-plan",
				label: "Have you implemented a disaster recovery plan?",
				disabled: inputValues.find((input) => input.id === "md-enterprise-ready-depend-on-a-Web")?.value !== "Yes",
			},
			{
				id: "md-enterprise-ready-technological-upgrades",
				label: "Is there a plan for technological upgrades as the business grows?",
				disabled: inputValues.find((input) => input.id === "md-enterprise-ready-depend-on-a-Web")?.value !== "Yes",
			},
		];

		const hardwarePlatformQuestions = [
			{
				id: "md-enterprise-ready-hardware-product",
				label: "Does your startup have a hardware product?",
				disabled: false,
			},
			{
				id: "md-enterprise-ready-deliver-the-hardware",
				label: "Does your startup deliver the hardware?",
				disabled: inputValues.find((input) => input.id === "md-enterprise-ready-hardware-product")?.value !== "Yes",
			},
			{
				id: "md-enterprise-ready-maintain-the-hardware",
				label: "Does your startup maintain the hardware?",
				disabled: inputValues.find((input) => input.id === "md-enterprise-ready-hardware-product")?.value !== "Yes",
			},
			{
				id: "md-enterprise-ready-hardware-returned",
				label: "Is the hardware returned when the contract or service ends?",
				disabled: inputValues.find((input) => input.id === "md-enterprise-ready-hardware-product")?.value !== "Yes",
			},
		];

		switch (category) {
			case "Risk":
				setPlatformQuestions(riskQuestions);
				break;
			case "Product-Service":
				setPlatformQuestions(productServiceQuestions);
				break;
			case "Business-Operations":
				setPlatformQuestions(businessOperationsQuestions);
				break;
			case "Software":
				setPlatformQuestions(softwarePlatformQuestions);
				break;
			case "Hardware":
				setPlatformQuestions(hardwarePlatformQuestions);
				break;
			default:
				break;
		}
	}, [category, inputValues]);

	return (
		<Box sx={{ display: "flex", flexDirection: "column", gap: "1.75rem", ml: "13px" }}>
			{platformQuestions?.map((questionData, index) => {
				return (
					<Box key={questionData?.id} sx={{ display: "flex", justifyContent: "space-between", alignItems: "start", gap: "10px" }}>
						<Box>
							<Typography variant="Text/sm/Medium" color="#344054" component={"p"}>
								{index + 1}. {questionData?.label}
							</Typography>
						</Box>
						<CustomToggleSwitch
							checked={inputValues.find((input) => input?.id === questionData?.id)?.value === "Yes"}
							onChange={(e, value) => {
								const newValue = value ? "Yes" : "No";
								handleInputChange(questionData?.id, newValue);
							}}
							disabled={questionData?.disabled}
						/>
						{inputValues.find((input) => input.id === questionData?.id)?.error && (
							<Typography
								variant="Text/sm/Regular"
								component={"p"}
								sx={{ mt: ".37rem", color: (theme) => theme.palette.secondary.danger, textAlign: "right" }}
							>
								{inputValues.find((input) => input.id === questionData?.id)?.error}
							</Typography>
						)}
					</Box>
				);
			})}
		</Box>
	);
};

const riskQuestions = [
	{
		id: "md-enterprise-ready-strategic-plan",
		label: "Does your startup have a clear, long-term strategic plan?",
	},
	{
		id: "md-enterprise-ready-strategic-partnerships",
		label: "Does your startup have strategic partnerships or alliances that enhance its value proposition?",
	},
	{
		id: "md-enterprise-ready-patent",
		label: "Does your startup hold at least one patent that protects your unique product or IP?",
	},
	{
		id: "md-enterprise-ready-strategy-for-leveraging",
		label: "Do you have a strategy for leveraging your unique product or IP for competitive advantage?",
	},
	{
		id: "md-enterprise-ready-startup-own-cash",
		label: "Does your startup own cash or collateral equal to at least 80% of its current financial liability?",
	},
	{
		id: "md-enterprise-ready-any-litigation",
		label: "Are there any liens against your startup, or is your startup involved in any form of litigation?",
	},
	{
		id: "md-enterprise-ready-legal-liabilities",
		label: "Are there any legal liabilities or exceptional risks involved with using your startup or product?",
	},
];

const productServiceQuestions = [
	{
		id: "md-enterprise-ready-product-offering-scale",
		label: "Does your product / service offering scale?",
	},
	{
		id: "md-enterprise-ready-unique-value-proposition",
		label: "Does your product / service offer a unique value proposition compared to competitors?",
	},
	{
		id: "md-enterprise-ready-competitive-landscape",
		label: "Do you have a clear understanding of the competitive landscape?",
	},
	{
		id: "md-enterprise-ready-roadmap",
		label: "Is there a roadmap for future product / service development and innovation?",
	},
	{
		id: "md-enterprise-ready-process-for-soliciting",
		label: "Does your startup have a well-defined process for soliciting and incorporating customer feedback?",
	},
	{
		id: "md-enterprise-ready-support-customers",
		label: "Do you have clear processes to support customers?",
	},
	{
		id: "md-enterprise-ready-flagship-customer",
		label: "Do you have a flagship customer?",
	},
	{
		id: "md-enterprise-ready-proven-pricing-strategy",
		label: "Do you have a proven pricing strategy, and saleable, packaged product / service menu?",
	},
	{
		id: "md-enterprise-ready-proven-sales",
		label: "Does your team have a proven (profitable) sales and marketing strategy?",
	},
	{
		id: "md-enterprise-measure-the-success",
		label: "Are there metrics in place to measure the success of sales and marketing efforts?",
	},
	{
		id: "md-enterprise-endorsements",
		label: "Do you have endorsements, testimonials and referrals outside your friends & family circle?",
	},
];

const businessOperationsQuestions = [
	{
		id: "md-enterprise-ready-business-meet-operational",
		label: "Does your business meet operational, regional and industry-specific compliance requirements?",
		description: "Ex. PCI for payment processing, GDPR for EU data, HIPPA for healthcare",
	},
	{
		id: "md-enterprise-ready-clear-policies-for-regulatory",
		label: "Are there clear policies for regulatory compliance in all operational regions?",
		description: "",
	},
	{
		id: "md-enterprise-ready-ready-for-procurement",
		label: "Are you ready for procurement, contracts, pricing, NDAs?",
		description: "",
	},
	{
		id: "md-enterprise-ready-attract-and-retain-talent",
		label: "Does your startup have a clear, ongoing strategic initiative to attract and retain talent?",
		description: "",
	},
	{
		id: "md-enterprise-ready-clear-HR-policies",
		label: "Are there effective HR policies in place, including diversity and inclusion initiatives?",
		description: "",
	},
	{
		id: "md-enterprise-ready-clear-environmental-sustainability-policies",
		label: "Does the startup have policies regarding environmental sustainability?",
		description: "",
	},
	{
		id: "md-enterprise-ready-social-responsibility-initiatives",
		label: "Does the startup engage in social responsibility initiatives?",
		description: "",
	},
];

export const CustomToggleSwitch = styled((props) => (
	<Switch focusVisibleClassName=".Mui-focusVisible" checked={props.checked} disableRipple disabled={props.disabled ? props.disabled : false} {...props} />
))(({ theme }) => ({
	width: 44,
	height: 24,
	padding: 0,
	"& .MuiSwitch-switchBase": {
		padding: 0,
		margin: 2,
		transitionDuration: "300ms",
		"&.Mui-checked": {
			transform: "translateX(20px)",
			color: "#fff",
			"& + .MuiSwitch-track": {
				backgroundColor: theme.palette.primary.main,
				opacity: 1,
				border: 0,
			},
			"&.Mui-disabled + .MuiSwitch-track": {
				opacity: 0.5,
			},
		},
		"&.Mui-focusVisible .MuiSwitch-thumb": {
			color: "#33cf4d",
			border: "6px solid #fff",
		},
		"&.Mui-disabled .MuiSwitch-thumb": {
			color: theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[600],
		},
		"&.Mui-disabled + .MuiSwitch-track": {
			opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
		},
	},
	"& .MuiSwitch-thumb": {
		boxSizing: "border-box",
		width: 20,
		height: 20,
		boxShadow: "unset",
	},
	"& .MuiSwitch-track": {
		borderRadius: 26 / 2,
		backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
		opacity: 1,
		transition: theme.transitions.create(["background-color"], {
			duration: 500,
		}),
	},
}));
