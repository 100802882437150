import React, { Fragment, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Box, Typography, Divider, Button, CircularProgress, useMediaQuery } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import theme from "../../../constants/Theme";
import SelectInput from "../../../common/SelectInput";
import { TEST_TYPE } from "../../../constants/powerup-data";
import { ONLINE_PREDICTION_ICON } from "../../../constants/image";
import { MarketTestCard } from "./styles";
import DialogComponent from "../PowerUP/IdeaValidation/Components/Dialog";
import TestTable from "../../../components/Workspace/PowerUp/TestTable";
import services from "../../../services";
import { toastContainer } from "../../../utils/toast";
import { sosPrimary } from "../../../constants/Theme/colors";
import { handleMixPanel } from "../../../utils/mixPanelEventHandling";

const MarketTest = () => {
	const [modelOpen, setModelOpen] = useState(false);
	const [newTest, setNewTest] = useState({});
	const [marketTest, setMarketTest] = useState([]);
	const [powerUps, setPowerUps] = useState([]);
	const [completedPowerUps, setCompletedPowerUps] = useState([]);
	const [marketLoader, setMarketLoader] = useState(false);
	const [createTestLoader, setCreateTestLoader] = useState(false);
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

	const marketTestUuid = TEST_TYPE[0].uuid;
	const location = useLocation();

	//Api call to get completed powerups list
	const getCompletedPowerUps = () => {
		services
			.getCompletedPowerUps(marketTestUuid)
			.then((res) => {
				if (res.data.code === 200) {
					const resData = res.data.data;
					const data = resData.map((data) => data.powerUpName);
					setPowerUps(data);
					setCompletedPowerUps(resData);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	//Api call to Get the market test list
	const getMarketTest = () => {
		services
			.getMarketTestList(marketTestUuid)
			.then((res) => {
				if (res.data.code === 200 && res.data.data !== null) {
					const resData = res.data.data;
					setMarketTest(resData);
				}
				setMarketLoader(false);
			})
			.catch((err) => {
				setMarketLoader(false);
				console.log(err);
			});
	};

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		});
		getCompletedPowerUps();
		setMarketLoader(true);
		getMarketTest();
	}, [modelOpen]);

	//Delete a market test from list
	const handleRemove = (testId) => {
		services
			.deleteMarketTest(marketTestUuid, testId)
			.then((res) => {
				const resData = res.data;
				getCompletedPowerUps();

				if (!resData.error) {
					const tests = marketTest.filter((test) => test.id !== testId);
					setMarketTest(tests);
				}
			})
			.catch((err) => {
				if (err.response.data.error) {
					toastContainer(err.response.data.message, "error");
				}
			});
	};

	const NoTestsComponent = () => {
		return (
			<>
				<Box sx={{ textAlign: "center" }}>
					<Box
						component={"img"}
						sx={{
							backgroundColor: sosPrimary[50],
							borderRadius: "var(--icon-border-radius)",
							p: 2,
						}}
						src={ONLINE_PREDICTION_ICON}
					/>
					<Box sx={{ mt: 3 }}>
						<Typography variant="label_large" color={"secondary.dark"}>
							No tests yet
						</Typography>
					</Box>
					<Box sx={{ mt: 1 }}>
						<Typography variant="label_large" color={"secondary.dark"}>
							We recommend that you complete Problem Solution Fit, Value Proposition, or Persona Builder before you Create a Market Test.
						</Typography>
					</Box>
					<Box sx={{ mt: 3 }}>
						<Button
							sx={{ maxWidth: "fit-content" }}
							startIcon={<AddIcon />}
							onClick={handleCreateTest}
							//disabled={powerUps.length === 0}
						>
							Create new test
						</Button>
					</Box>
				</Box>
			</>
		);
	};

	const MainContent = () => {
		return (
			<Box>
				<Box sx={{ alignItems: "center", display: "flex" }}>
					<Box component={"img"} src={ONLINE_PREDICTION_ICON} />
					<Box sx={{ ml: 2 }}>
						<Typography variant="headline1">Market Test</Typography>
					</Box>
				</Box>
				<Box
					sx={{
						mt: 2,
						ml: 8,
						[theme.breakpoints.down("md")]: {
							ml: 0,
						},
					}}
				>
					<Typography variant="subhead1" color={theme.palette.secondary.main}>
						Identify solutions with a higher chance of adoption through speaking with potential future users.
					</Typography>
				</Box>
				<Divider
					sx={{
						background: `${theme.palette.secondary.dark}`,
						mt: 6,
						display: { xs: "block", sm: "block", md: "block" },
					}}
				/>
				<Box
					sx={(theme) => ({
						mt: 6,
						borderRadius: theme.shape.standard.borderRadius,
						p: 3,
						boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)",
						backgroundColor: "secondary.white",
					})}
				>
					<Box sx={{ display: "flex", alignItems: "center" }}>
						<Typography variant="title_large">Your Tests</Typography>
						<Box
							sx={{
								ml: "auto",

								[theme.breakpoints.down("md")]: {
									display: "none",
								},
							}}
						>
							{marketTest?.length ? (
								<Button
									startIcon={<AddIcon />}
									onClick={handleCreateTest}
									//disabled={powerUps.length === 0}
								>
									Create new test
								</Button>
							) : (
								""
							)}
						</Box>
					</Box>
					<Box sx={{ mt: 1 }}>
						{marketTest?.length === 0 ? <NoTestsComponent /> : <TestTable marketTest={marketTest} handleRemove={handleRemove} />}
					</Box>
					<Box sx={{ display: "flex", alignItems: "center" }}>
						<Box
							sx={{
								ml: "auto",
								mt: 3,
								[theme.breakpoints.up("md")]: {
									display: "none",
								},
							}}
						>
							{marketTest?.length ? (
								<Button
									startIcon={<AddIcon />}
									onClick={handleCreateTest}
									//disabled={powerUps.length === 0}
								>
									Create new test
								</Button>
							) : (
								""
							)}
						</Box>
					</Box>
				</Box>
			</Box>
		);
	};

	const createNewTest = (data) => {
		const powerUpId = completedPowerUps.find((powerUp) => powerUp?.powerUpName === data?.powerUp);
		const payload = {
			powerUpId: powerUpId?.id,
		};
		setCreateTestLoader(true);
		services
			.createMarketTest(marketTestUuid, payload)
			.then((res) => {
				if (location?.message) {
					handleMixPanel(location?.message);
				}
				setCreateTestLoader(false);
				setModelOpen(false);
				setNewTest({});
			})
			.catch((err) => {
				setCreateTestLoader(false);

				toastContainer(err?.response.data.message, "error");
			});
	};

	const ActionComponent = () => {
		return (
			<Box sx={{ px: 3, py: 1 }}>
				<Button startIcon={<AddIcon />} disabled={!newTest.powerUp || !newTest.testType} onClick={() => createNewTest(newTest)}>
					{createTestLoader ? (
						<CircularProgress
							size={30}
							sx={{
								color: `${theme.palette.secondary.white}`,
								p: 4,
							}}
						/>
					) : (
						"Create Test"
					)}
				</Button>
			</Box>
		);
	};

	//Open create test modal
	const handleCreateTest = (event) => {
		setModelOpen(true);
	};

	//Selecting values from the list
	const handleSelectChange = (event, key) => {
		const { value } = event.target;
		setNewTest({
			...newTest,
			[key]: value,
		});
	};
	const handleCloseModal = (value) => {
		setModelOpen(value);
		setNewTest({});
	};
	return (
		<>
			<DialogComponent
				title={"Create new test"}
				titleVariant={"title_large"}
				fullWidth
				isOpen={modelOpen}
				onClose={() => handleCloseModal(false)}
				dialogAction={<ActionComponent />}
				PaperProps={{
					style: { borderRadius: isSmallScreen ? 16 : 28 },
				}}
				disableBackDropClick
			>
				<Box sx={{ my: isSmallScreen ? 1 : 2, p: isSmallScreen ? 1 : 2 }}>
					<Typography variant="title_small">What type of test are you conducting?</Typography>
					<Box>
						<SelectInput
							label="Test Type"
							fullWidth
							wrapperstyle={{ mt: 2 }}
							onInputChange={(e) => handleSelectChange(e, "testType")}
							selectedValue={newTest.testType}
							inputKey="testType"
							options={[TEST_TYPE[0].name]}
						/>
					</Box>
				</Box>
				<Box sx={{ my: isSmallScreen ? 1 : 2, p: isSmallScreen ? 1 : 2 }}>
					<Typography variant="title_small">What PowerUp are you testing?</Typography>
					<Box>
						<SelectInput
							label="PowerUp"
							fullWidth
							wrapperstyle={{ mt: 2 }}
							onInputChange={(e) => handleSelectChange(e, "powerUp")}
							selectedValue={newTest.powerUp}
							inputKey="powerUp"
							options={powerUps}
						/>
					</Box>
				</Box>
			</DialogComponent>
			{marketLoader ? (
				<Box justifyContent={"center"} sx={{ display: "flex", mt: 30 }}>
					<CircularProgress />
				</Box>
			) : (
				<Box
					sx={{
						//border: `1px solid ${theme.palette.secondary.outline2}`,
						px: 4,
						background: theme.palette.secondary.white,
						//borderRadius: theme.shape.standard.borderRadius,

						[theme.breakpoints.down("md")]: { p: 1, height: "100%" },
					}}
				>
					<Box
						sx={(theme) => ({
							minHeight: 1,
							background: theme.palette.secondary.white,
							maxWidth: theme.breakpoints.values.xl,
							m: "auto",
							[theme.breakpoints.down("md")]: {
								p: 1,
								borderRadius: 0,
								backgroundColor: "transparent",
							},
						})}
					>
						<Fragment>
							<MarketTestCard>
								<MainContent />
							</MarketTestCard>
						</Fragment>
					</Box>
				</Box>
			)}
		</>
	);
};

export default MarketTest;
