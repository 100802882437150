import React, { useState } from "react";
import { Box, Typography, Grid } from "@mui/material";
import DialogComponent from "../../Workspace/PowerUP/IdeaValidation/Components/Dialog";
import theme from "../../../constants/Theme";
import DateTimeSlotPicker from "../../../common/DateTimeslotPicker";
import LoadingButton from "../../../common/Loadingbutton";
import services from "../../../services";
import { sanitizeDateTimePayload } from "../../Workspace/Plugins/AdvisorPlugin/Functions/common";
import SuccessPopup from "../../Workspace/Plugins/AdvisorPlugin/Components/SuccessPopup";
import MobileDatePopover from "../../Workspace/Plugins/AdvisorPlugin/Components/MobileDatePopover";
import { getLocalTimeZone } from "../../../utils/date";

const ProposeNewTimeMobile = ({
  openProposeNewTime,
  setOpenProposeNewTime,
  responseObj,
  onClose,
}) => {
  const [responses, setResponses] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [timeDrawer, setTimeDrawer] = useState(false);
  const [openSuccessPopup, setOpenSuccessPopup] = useState(false);

  const handleconfirmDisable = () => {
    return !responses.length;
  };

  const sendInviteToCandidate = () => {
    const candidateData = {
      calendarEventDetails: sanitizeDateTimePayload(responses),
      calendarEventStatus: "NEW_TIME_SUGGESTED",
      timezone: getLocalTimeZone(),
    };
    setButtonLoading(true);

    services
      .updateCalendarInviteEvent(responseObj.id, candidateData)
      .then(data => {
        setButtonLoading(false);
        setOpenProposeNewTime(false);
        setOpenSuccessPopup(true);
      })
      .catch(err => {
        console.log(err);
        setButtonLoading(false);
      });
  };

  const handlePopupsClose = () => {
    setOpenSuccessPopup(false);
    onClose();
  };

  const successContentComponent = () => {
    return (
      <Box textAlign={"center"} sx={{ px: 3 }}>
        <Box sx={{ my: 2 }}>
          <Typography variant="headline6">
            {`You’ve sent new time suggestions to interview with ${responseObj?.companyModel?.companyName}.`}
          </Typography>
        </Box>

        <Box sx={{ my: 3 }}>
          <Typography variant="subhead1">
            {`${responseObj?.companyModel?.companyName} will be notified of your
            new time proposals.`}
          </Typography>
        </Box>
      </Box>
    );
  };

  return (
    <>
      <DialogComponent
        isOpen={openProposeNewTime}
        onClose={() => setOpenProposeNewTime(false)}
        title={"Invite Candidate"}
        titleVariant={"title_large"}
        contentStyle={{ px: 0 }}
        maxWidth={"lg"}
        fullWidth
        PaperProps={{
          style: { borderRadius: 16 },
        }}
        footerStyle={{
          p: 3,
        }}
        dialogAction={
          <Box display="flex">
            <Box sx={{ mr: 1 }}>
              <MobileDatePopover
                responses={responses}
                setResponses={setResponses}
                mobileUI
              />
            </Box>
            <Box item>
              <LoadingButton
                disabled={handleconfirmDisable()}
                loading={buttonLoading}
                onClick={() => sendInviteToCandidate()}
              >
                Confirm
              </LoadingButton>
            </Box>
          </Box>
        }
        disableBackDropClick
      >
        <Box>
          <Box sx={{ mt: 3, px: 3 }}>
            <Grid container spacing={2}>
              <Grid item md={12} xs={12}>
                <>
                  <Box>
                    <Box>
                      <Typography variant="headline6">
                        Select dates & times you are available
                      </Typography>
                      <br />
                      <Typography variant="bodyMedium">
                        We recommend choosing your available time slots no more
                        than 7 days out from the day you send the invite.
                      </Typography>
                    </Box>
                    <DateTimeSlotPicker
                      responses={responses}
                      setResponses={setResponses}
                      minTimeSlots={1}
                      maxTimeSlots={5}
                      containerStyles={theme => ({
                        background:
                          "linear-gradient(0deg, rgba(118, 73, 160, 0.05), rgba(118, 73, 160, 0.05)), #FCFCFC",
                        border: `1px solid ${theme.palette.secondary.light}`,
                        borderRadius: theme.shape.standard3.borderRadius,
                        p: 1,
                        mt: 3,
                      })}
                      selectedDate={selectedDate}
                      setSelectedDate={setSelectedDate}
                      mobileUI={true}
                      timeDrawer={timeDrawer}
                      setTimeDrawer={setTimeDrawer}
                    />
                  </Box>
                </>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </DialogComponent>
      <SuccessPopup
        title={"Invite Sent"}
        open={openSuccessPopup}
        close={handlePopupsClose}
        ContentComponent={successContentComponent}
      />
    </>
  );
};

export default ProposeNewTimeMobile;
