import { Avatar, Box, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { BuildingOffice2Icon, RocketLaunchIcon, MapPinIcon } from "@heroicons/react/24/outline";
import { BINOCULARS_ICON, UPDATED_GROUP_ICON, UPDATED_INDUSTRY_ICON } from "../../../../constants/image";
import { UploadLogo } from "../../../Profile/style";

function BenchmarkCompanyDetails() {
	const STARTUP = useSelector((state) => state?.investorDashboard?.openStartupObj);
	const SCORE_DETAILS = useSelector((state) => state?.investorDashboard?.ScoreReportObj);
	const getIndustry = () => {
		try {
			if (STARTUP?.industry) {
				let parsedIndustry = JSON.parse(STARTUP?.industry);
				if (typeof parsedIndustry[0] !== "undefined") {
					if (parsedIndustry[0].value) {
						return parsedIndustry[0].value;
					} else {
						return "-";
					}
				} else {
					return "-";
				}
			} else {
				return "-";
			}
		} catch (e) {
			console.log(e);
			return "-";
		}
	};
	return (
		<Box sx={{ display: "flex", flexDirection: "column", color: "black", gap: 1 }}>
			<Box sx={{ display: "flex", columnGap: 2, alignItems: "center", flexWrap: "wrap" }}>
				<Box sx={{ display: "flex", alignItems: "center" }}>
					{STARTUP?.logoUrl ? (
						<Avatar
							alt={STARTUP?.companyName}
							src={STARTUP?.logoUrl}
							sx={{
								height: "40px",
								width: "40px",
							}}
						/>
					) : (
						<UploadLogo borderWidth="4px" variant="poppinsSemiBold30" id={STARTUP?.companyName} width={40} height={40}>
							{STARTUP?.companyName?.charAt(0)?.toUpperCase()}
						</UploadLogo>
					)}
				</Box>
				<Box>
					<Typography variant="poppinsSemiBold30">{STARTUP?.companyName}</Typography>
				</Box>
				<Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
					<Box>
						<Typography variant="poppinsMedium18">{STARTUP?.currentStage}</Typography>
					</Box>
					<Box>
						<Typography variant="poppinsMedium18">|</Typography>
					</Box>
					<Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
						<Box>
							<img src={BINOCULARS_ICON} width="30" />
						</Box>
						<Box>
							<Typography variant="poppinsMedium18">
								{SCORE_DETAILS?.investorsWatching ? SCORE_DETAILS?.investorsWatching : 0} Investors Watching
							</Typography>
						</Box>
					</Box>
				</Box>
			</Box>
			<Box>
				<Typography variant="poppinsSemiBold18">{STARTUP?.tagLine}</Typography>
			</Box>
			<Box sx={{ display: "flex", columnGap: 3, alignItems: "center", flexWrap: "wrap" }}>
				<Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
					<Box sx={{ width: "24px" }}>
						<MapPinIcon />
					</Box>
					<Box>
						<Typography variant="poppinsMedium18">
							{STARTUP?.city ? STARTUP?.city + ", " : ""}
							{STARTUP?.state}
							{!STARTUP?.city && !STARTUP?.state ? "-" : ""}
						</Typography>
					</Box>
				</Box>
				<Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
					<Box sx={{ width: "24px" }}>
						<RocketLaunchIcon />
					</Box>
					<Box>
						<Typography variant="poppinsMedium18">{STARTUP?.foundedYear || "-"}</Typography>
					</Box>
				</Box>
				<Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
					<Box sx={{ width: "24px" }}>
						<img src={UPDATED_GROUP_ICON} />
					</Box>
					<Box>
						<Typography variant="poppinsMedium18">{STARTUP?.employeeCount || "-"}</Typography>
					</Box>
				</Box>
				<Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
					<Box sx={{ width: "20px" }}>
						<img src={UPDATED_INDUSTRY_ICON} />
					</Box>
					<Box>
						<Typography variant="poppinsMedium18">{getIndustry()}</Typography>
					</Box>
				</Box>
			</Box>
		</Box>
	);
}

export default BenchmarkCompanyDetails;
