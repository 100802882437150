import * as React from "react";
import { Box, Chip, Typography } from "@mui/material";

const CustomMuiChip = ({ startIcon, endIcon, label, onDelete, disabled, borderRadius = "16px", border = "1px solid" }) => {
	return (
		<Box>
			{disabled ? (
				<Chip
					icon={startIcon}
					sx={{
						width: 1,
						mt: 3,
						px: 1,
						py: 3,
						display: "flex",
						justifyContent: "flex-start",
					}}
					label={<Typography variant="label_large">{label}</Typography>}
					variant="outlined"
				/>
			) : (
				<Chip
					icon={startIcon}
					sx={{ width: 1, mt: 3, px: 1, py: 3, borderRadius: borderRadius, border: border }}
					label={<Typography variant="label_large">{label}</Typography>}
					variant="outlined"
					deleteIcon={endIcon}
					onDelete={() => {
						onDelete();
					}}
				/>
			)}
		</Box>
	);
};

export default CustomMuiChip;
