import { Avatar, Box, Typography } from "@mui/material";
import React from "react";
import { CSSTransition } from "react-transition-group";

function ListItem({ menuItem, open, active, handleClick, timeout, fontSX, isLabel, isSubCat }) {
	return (
		<CSSTransition in={open} appear={open} timeout={timeout} classNames="open">
			<Box
				onClick={() => handleClick()}
				sx={{
					cursor: "pointer",
					...(isSubCat
						? {
								pl: "65px",
								pr: "40px",
						  }
						: {
								px: "40px",
						  }),

					py: "10px",
					transition: "all 0.3s ease-in-out",
					opacity: 0,
					transform: "translateX(50px)",
					"&.open-exit-done": {
						opacity: 0,
						transform: "translateX(50px)",
					},
					"&.open-exit-active": {
						opacity: 1,
						transform: "translateX(0px)",
						borderLeft: open ? "3px solid" : "none",
						borderRight: open ? "3px solid" : "none",
						borderColor: (theme) => (open && active ? theme.palette.primary.main : "transparent"),
					},
					"&.open-enter-done": {
						opacity: 1,
						transform: "translateX(0px)",
						borderLeft: open ? "3px solid" : "none",
						borderRight: open ? "3px solid" : "none",
						borderColor: (theme) => (open && active ? theme.palette.primary.main : "transparent"),
					},
					"&.open-enter-active": {
						opacity: 1,
						transform: "translateX(0)",
						borderLeft: open ? "3px solid" : "none",
						borderRight: open ? "3px solid" : "none",
						borderColor: (theme) => (open && active ? theme.palette.primary.main : "transparent"),
					},
				}}
			>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						gap: 2,
					}}
				>
					<Avatar
						sx={{
							width: 47,
							height: 47,
							background: (theme) => active ? theme.palette.primary.main : "none",
						}}
					>
						{menuItem.icon}
					</Avatar>
					<CSSTransition in={open} timeout={300} classNames="open">
						<Box
							sx={{
								transition: "opacity 0.5s ease-in-out",
								opacity: open ? 1 : 0,
								"&:not(.open-enter-active), &:not(.open-enter-done), &:not(.open-exit-done), &:not(.open-exit-active)": {
									width: "0px",
								},
								"&.open-enter-done": {
									opacity: 1,
								},

								"&.open-exit-done": {
									opacity: 0,
								},
								"&.open-enter-active": {
									opacity: 1,
								},
							}}
						>
							<Typography
								variant="customValue"
								sx={
									isLabel
										? {
												fontFamily: "PoppinsSemiBold",
												fontSize: "14px",
												lineHeight: "21px",
												textTransform: "uppercase",
												color: "white",
										  }
										: {
												...fontSX,
												...(!fontSX && {
													fontFamily: "PoppinsMedium",
													fontSize: "18px",
													lineHeight: "27px",
												}),
												color: "white",
												cursor: "pointer",
										  }
								}
							>
								{menuItem.label}
							</Typography>
						</Box>
					</CSSTransition>
				</Box>
			</Box>
		</CSSTransition>
	);
}

export default ListItem;
