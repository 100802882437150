import { Box, useMediaQuery } from "@mui/material";
import React, { createRef, useEffect } from "react";

export const Layout = ({ sidebar, main, footer, mobileSteps, currentStep }) => {
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("md"));

  const myRef = createRef();

  useEffect(() => {
    myRef?.current?.scrollTo(0, 0);
  }, [currentStep]);

  return (
    <Box
      sx={{
        height: "100vh",
        width: "100%",
        boxSizing: "border-box",
      }}
    >
      <Box
        sx={theme => ({
          display: "flex",
          height: 1,
          [theme.breakpoints.down("md")]: {
            flexDirection: "column",
          },
          [theme.breakpoints.up("md")]: {
            flexDirection: "row",
          },
        })}
      >
        {isSmallScreen ? (
          <Box>{mobileSteps}</Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              flex: "0 0 360px",
              boxSizing: "border-box",
            }}
          >
            {sidebar}
          </Box>
        )}

        <Box
          sx={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              flex: 1,
              overflowY: "scroll",
              boxSizing: "border-box",
            }}
            ref={myRef}
          >
            {main}
          </Box>
          <Box
            sx={{
              flex: "0 0 100px",
              boxSizing: "border-box",
              borderTop: "1px solid rgba(51, 51, 66, 0.1)",
            }}
          >
            {footer}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
