import * as actionTypes from "./actionTypes";

export const setWorkProfile = payload => {
  return dispatch => {
    dispatch({
      type: actionTypes.SET_MY_WORK_PROFILE,
      payload,
    });
  };
};
