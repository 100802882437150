import React, { useEffect, useState } from "react";
import { Box, useTheme, Grid, Typography, CircularProgress } from "@mui/material";
import { get } from "lodash";
import { convertNumberToLocale } from "../Functions/common";
import BIDoughnutChart from "./BIDoughnutChart";
import ChartCard from "./ChartCard";
import LabelList from "./ChartCard/LabelList";
import { shareClassName } from "../DataGrid/CapGrid";

function OwnerShipChart({ onChartClick, data = [], loading, readOnly = false }) {
	const theme = useTheme();
	const [totalShare, setTotalShare] = useState(null);
	const [chartData, setChartData] = useState([]);

	const labels = [
		{
			name: "Common Shares",
			color: theme.palette.primary.primaryPurple,
		},
		{
			name: "Preferred Shares",
			color: theme.palette.bidashboard.paramLightGreen,
		},
		{
			name: "Outstanding (All Plans)",
			color: theme.palette.bidashboard.cardTitle,
		},
		{
			name: "Available (All Plans)",
			color: theme.palette.bidashboard.grey,
		},
	];

	const calculateChartData = () => {
		let common = 0;
		let preferred = 0;
		let outstanding = 0;
		let available = 0;
		let totalAuthorizedShares = 0;
		let totalFullyDiluted = 0;
		let seriesSeed = 0;
		let seriesA = 0;
		let allFullDilutedNull = true;
		for (let index = 0; index < data.length; index++) {
			const element = data[index];
			if (get(element, "shareClassName") === shareClassName.common) {
				common = get(element, "fullyDiluted", 0);
			}
			if (get(element, "shareClassName") === shareClassName.seriesSeed) {
				seriesSeed = get(element, "fullyDiluted", 0);
			}
			if (get(element, "shareClassName") === shareClassName.seriesA) {
				seriesA = get(element, "fullyDiluted", 0);
			}
			if (get(element, "shareClassName") === shareClassName.poolOutstanding) {
				outstanding = get(element, "fullyDiluted", 0);
			}
			if (get(element, "shareClassName") === shareClassName.poolAvailable) {
				available = get(element, "fullyDiluted", 0);
			}
			if (element["authorizedShares"]) {
				totalAuthorizedShares = totalAuthorizedShares + element["authorizedShares"];
			}
			if (element["fullyDiluted"] && get(element, "shareClassName") !== "Total") {
				allFullDilutedNull = false;
				totalFullyDiluted = totalFullyDiluted + element["fullyDiluted"];
			}
		}

		preferred = seriesSeed + seriesA;
		if (allFullDilutedNull) {
			totalFullyDiluted = null;
		}

		setTotalShare(totalFullyDiluted);
		setChartData([common, preferred, outstanding, available]);
	};

	useEffect(() => {
		calculateChartData();
	}, [data]);

	return (
		<ChartCard
			title={"OwnerShip & Fully Diluted Shares"}
			subheader={totalShare !== null && !loading ? `${convertNumberToLocale(totalShare)}` : "0"}
			backgroundColor={theme.palette.secondary.white}
			onChartClick={() => onChartClick("cap")}
			toolTipBody={
				<>
					<Typography variant="Text/sm/Regular">
						A cap table (or capitalization table) is a document, like a spreadsheet or a table, that details who has ownership in a company. It
						lists all the securities or number of shares of a company.
					</Typography>
					<Typography variant="Text/sm/Regular">
						Common stock in a private company is generally directly issued to founders and early employees.
					</Typography>
					<Typography variant="Text/sm/Regular">
						Preferred stock is primarily issued to investors when they finance funding rounds. It is considered less risky than common stock since
						preferred stockholders get priority on company assets over common stockholders.
					</Typography>
					<Typography variant="Text/sm/Regular">
						A warrant is an agreement between two parties that gives one party the right to buy the other party’s stock at a set price, over a
						specified period of time. Once a warrant holder exercises their warrant, they get shares of stock in the issuing party’s company.
					</Typography>
				</>
			}
			data={data}
		>
			<Box sx={{ width: "100%" }}>
				{loading ? (
					<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
						<CircularProgress />
					</Box>
				) : (
					<Grid container spacing={1}>
						<Grid item xs={12} md={12} lg={6}>
							<Box display="flex" flexDirection="column" justifyContent={{ lg: "center" }} height={{ lg: "300px" }}>
								<LabelList labels={labels} />
							</Box>
						</Grid>
						<Grid item xs={12} md={12} lg={6}>
							<Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
								<Box sx={{ position: "relative", maxWidth: "370px", width: "100%", height: "370px" }}>
									<BIDoughnutChart labels={labels} yLable="OwnerShip & Fully Diluted Shares" data={chartData} />
									{chartData.every((dataItem) => dataItem === 0) && !readOnly && (
										<Box
											sx={{
												position: "absolute",
												top: "50%",
												left: "50%",
												transform: "translate(-50%, -50%)",
												px: "14px",
												py: "8px",
												textAlign: "center",
												backgroundColor: theme.palette.sos.gray100,
												borderRadius: "100px",
												boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
											}}
										>
											<Typography variant="Text/xs/Semibold">Add Data</Typography>
										</Box>
									)}
								</Box>
							</Box>
						</Grid>
					</Grid>
				)}
			</Box>
		</ChartCard>
	);
}

export default OwnerShipChart;
