import { Box } from "@mui/material";
import React from "react";

export function Card({ children, sxOverride }) {
	return (
		<Box
			sx={{
				borderRadius: "2rem",
				background: "var(--sos-white, #FFF)",
				boxShadow: "0px 10px 25px 0px rgba(0, 0, 0, 0.05)",
				display: "flex",
				padding: "2rem",
				flexDirection: "column",
				alignItems: "flex-start",
				gap: "1.5rem",
				alignSelf: "stretch",
				...sxOverride,
			}}
		>
			{children}
		</Box>
	);
}
