import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import { Box, Button, Typography, TextField, IconButton, InputAdornment, CircularProgress, Link } from "@mui/material";
import moment from "moment";
import CommonSlideDialog from "../../../../common/CommonSlideDialog";
import { setBIDashboardData } from "../../../../modules/actions/biDashboardActions";
import { ALERT_ICON_V1, GREY_EDIT_ICON_V2 } from "../../../../constants";
import services from "../../../../services";
import { toastContainer } from "../../../../utils/toast";
import {
	getAcvGridData,
	getAnnualRevenueGridData,
	getBurnMultipeGridData,
	getBurnRunwayGridData,
	getCapTableData,
	getCompanyDetails,
	getCustomerGridData,
	getMagicNumberGridData,
	getMonthlyRevenueGridData,
	getNetArrGridData,
	getPaybackGridData,
	getRevenueGridData,
	getSmExpenseVsNewSalesGridData,
} from "../Functions/common";
import messages from "../../../../constants/messages";
import ROUTES_PATH from "../../../../constants/routes";

function GoogleSheetDialog() {
	const [sheetName, setSheetName] = useState("");
	const [startDate, setStartDate] = useState({ value: "", error: "" });
	const [endDate, setEndDate] = useState({ value: "", error: "" });
	const [openStartDate, setOpenStartDate] = useState("");
	const [openEndDate, setOpenEndDate] = useState("");
	const [createGoogleSheetLoading, setCreateGoogleSheetLoading] = useState(false);
	const [fetchGoogleSheetLoading, setFetchGoogleSheetLoading] = useState(false);
	const [intialLoading, setIntialLoading] = useState(false);
	const [googleFile, setGoogleFile] = useState(null);

	const { openGoogleSheetDialog } = useSelector((store) => store?.biDashboard);
	const ownCompanyId = useSelector((state) => state?.auth?.companyId);

	const location = useLocation();
	const history = useHistory();
	const dispatch = useDispatch();

	const urlSearchParams = new URLSearchParams(location?.search);

	const GOOGLE_SHEET_AUTH_TOKEN = urlSearchParams.get("code");

	useEffect(() => {
		if (openGoogleSheetDialog) {
			handleGoogleSheetsTokenApiCall();
			handleGetGoogleSheetsApiCall();
		}
	}, [openGoogleSheetDialog]);

	const handleClose = () => {
		dispatch(setBIDashboardData({ openGoogleSheetDialog: false }));
	};

	const refreshData = async () => {
		try {
			setFetchGoogleSheetLoading(true);

			const gridDataResponse = await services.fetchGoogleSheetGridDataApi();

			if (gridDataResponse?.status === 200) {
				handleClose();
				history.push(ROUTES_PATH?.STARTUPGRID);
				toastContainer(messages?.GOOGLE_SHEET_DATA_FECTHED, "success");

				setFetchGoogleSheetLoading(false);
				const acvGridDataResponse = await getAcvGridData(ownCompanyId);
				const capTableDataResponse = await getCapTableData(ownCompanyId);
				const annualRevenueGridDataResponse = await getAnnualRevenueGridData(ownCompanyId);
				const burnRunwayGridDataresponse = await getBurnRunwayGridData(ownCompanyId);
				const customerGridDataResponse = await getCustomerGridData(ownCompanyId);
				const monthlyRevenueGridDataResponse = await getMonthlyRevenueGridData(ownCompanyId);
				const netArrGridDataResponse = await getNetArrGridData(ownCompanyId);
				const paybackGridDataResponse = await getPaybackGridData(ownCompanyId);
				const revenueGridDataResponse = await getRevenueGridData(ownCompanyId);
				const smExpenseVsNewSalesGridDataResponse = await getSmExpenseVsNewSalesGridData(ownCompanyId);
				const burnMultipleGridDataResponse = await getBurnMultipeGridData(ownCompanyId);
				const magicNumberGridDataResponse = await getMagicNumberGridData(ownCompanyId);
				const companyDetailsDataResponse = await getCompanyDetails();
				dispatch(
					setBIDashboardData({
						...(acvGridDataResponse && { acv: acvGridDataResponse }),
						...(capTableDataResponse && { capData: capTableDataResponse }),
						...(annualRevenueGridDataResponse && { annualRecurringRevenue: annualRevenueGridDataResponse }),
						...(burnRunwayGridDataresponse && { burnAndRunway: burnRunwayGridDataresponse }),
						...(customerGridDataResponse && { customers: customerGridDataResponse }),
						...(monthlyRevenueGridDataResponse && { monthlyRecurringRevenue: monthlyRevenueGridDataResponse }),
						...(netArrGridDataResponse && { netNewArr: netArrGridDataResponse }),
						...(paybackGridDataResponse && { cacPayback: paybackGridDataResponse }),
						...(revenueGridDataResponse && { revenue: revenueGridDataResponse }),
						...(smExpenseVsNewSalesGridDataResponse && { smExpense: smExpenseVsNewSalesGridDataResponse }),
						...(magicNumberGridDataResponse && { magicNumber: magicNumberGridDataResponse }),
						...(burnMultipleGridDataResponse && { burnMultiple: burnMultipleGridDataResponse }),
						...(companyDetailsDataResponse && {
							headCount: companyDetailsDataResponse?.companySize > 0 ? companyDetailsDataResponse?.companySize : 0,
						}),
					})
				);
			}
		} catch (error) {
			setFetchGoogleSheetLoading(false);
			if (error?.response && error?.response?.status === 404) {
				toastContainer(error.response.data?.message, "error");
			} else {
				toastContainer(messages?.API_SUBMIT_ERROR, "error");
			}
			console.log(error);
		}
	};

	const handleGoogleSheetsTokenApiCall = async () => {
		try {
			const payload = {
				code: GOOGLE_SHEET_AUTH_TOKEN,
			};
			setIntialLoading(true);
			const googleSheetResponse = await services.googleSheetTokenApi(payload);

			if (googleSheetResponse?.status === 200) {
				setIntialLoading(false);
			}
		} catch (error) {
			setIntialLoading(false);

			throw error;
		}
	};

	const handleGetGoogleSheetsApiCall = async () => {
		try {
			const googleSheetResponse = await services.getGoogleSheetApi();

			if (googleSheetResponse?.status === 200) {
				setGoogleFile(googleSheetResponse?.data?.data);
			}
		} catch (error) {
			toastContainer(messages?.API_SUBMIT_ERROR, "error");

			throw error;
		}
	};

	const handleCreateGoogleSheet = async () => {
		try {
			setCreateGoogleSheetLoading(true);

			const payload = {
				name: sheetName,
				startDate: startDate?.value,
				endDate: endDate?.value,
			};
			const response = await services.createGoogleSheetApi({ payload: payload });

			if (response?.status === 200) {
				setCreateGoogleSheetLoading(false);
				handleGetGoogleSheetsApiCall();
				toastContainer(messages?.FILE_CREATED_SUCCESS, "success");
			}
		} catch (error) {
			setCreateGoogleSheetLoading(false);
			toastContainer(messages?.API_SUBMIT_ERROR, "error");

			throw error;
		}
	};

	const ExcelSection = ({ title, subTitle }) => {
		return (
			<Box display={"flex"} flexDirection={"column"} gap={"12px"}>
				<Typography component={"p"} sx={{ color: "rgba(0, 0, 0, 1)" }} variant="Text/sm/Regular">
					{title}
				</Typography>
				<Typography component={"p"} sx={{ color: "rgba(102, 112, 133, 1)" }} variant="Text/sm/Regular">
					{subTitle}
				</Typography>
			</Box>
		);
	};

	const FileDisplayCard = () => {
		return (
			<Box
				sx={{ border: "1px solid rgba(208, 213, 221, 1)", p: "12px", borderRadius: "8px" }}
				alignItems={"center"}
				justifyContent={"space-between"}
				display={"flex"}
			>
				<Typography component={"p"} variant="Text/sm/Regular" sx={{ color: "rgba(102, 112, 133, 1)" }}>
					{" "}
					{googleFile?.metaData?.spreadSheetName}
				</Typography>
				<Box display={"flex"} gap={1} alignItems={"center"}>
					<Typography component={"p"} variant="Text/sm/Regular" sx={{ color: "rgba(102, 112, 133, 1)" }}>
						{googleFile?.lastModifiedAt}
					</Typography>
					<IconButton>
						<Box
							component={"img"}
							onClick={() => {
								window.open(googleFile?.metaData?.spreadSheetUrl, "blank");
							}}
							src={GREY_EDIT_ICON_V2}
							alt="edit"
						/>
					</IconButton>
				</Box>
			</Box>
		);
	};

	const CustomDatePicker = ({ openDatePicker, setOpenDatePicker, dateValue, setDateValue, type }) => {
		const handleValueChange = (value, key) => {
			let currentDate = moment(value).format("YYYY-MM-DD");
			if (type === "START_DATE") {
				if (!endDate?.value || new Date(currentDate) <= new Date(endDate?.value)) {
					setDateValue({ value: currentDate, error: "" });
				} else {
					setDateValue({ value: currentDate, error: "Start Date should be less than end date" });
				}
			} else if (type === "END_DATE") {
				if (!startDate?.value || new Date(currentDate) >= new Date(startDate?.value)) {
					setDateValue({ value: currentDate, error: "" });
				} else {
					setDateValue({ value: currentDate, error: "End Date should be greater than start date" });
				}
			}
		};
		const datePickerClick = () => {
			if (openDatePicker === false) {
				setOpenDatePicker(true);
			}
		};
		return (
			<Box onClick={datePickerClick}>
				<LocalizationProvider dateAdapter={AdapterMoment}>
					<DatePicker
						open={openDatePicker}
						onClose={() => setOpenDatePicker(false)}
						format="MM/DD/YYYY"
						value={dateValue?.value ? moment(dateValue?.value) : null}
						disableFuture={true}
						onChange={(newValue) => {
							handleValueChange(moment(newValue).toDate());
						}}
						slots={{
							OpenPickerIcon: CalendarTodayOutlinedIcon,
						}}
						sx={{
							cursor: "pointer",
							mt: 1.25,
							"& .MuiOutlinedInput-notchedOutline": { border: "none" },
							"& .MuiOutlinedInput-root": {
								fontFamily: (theme) => theme.typography["Text/sm/Regular"],
								color: "rgba(102, 112, 133, 1)",
							},
							"&.MuiTextField-root": {
								width: "100%",
								borderRadius: "100px",
								background: "white",
								boxShadow: "0px 10px 25px 0px rgba(0, 0, 0, 0.05)",
								border: "none",
							},
						}}
						slotProps={{
							openPickerButton: {
								sx: {
									mr: "5px",
								},
							},
							field: {
								InputProps: {
									endAdornment: (
										<InputAdornment position="end" sx={{ mr: 1.5 }}>
											<IconButton edge="end">
												<CalendarTodayOutlinedIcon sx={{ color: "#000" }} />
											</IconButton>
										</InputAdornment>
									),
									variant: "outlined",
									sx: {
										borderRadius: "100px",
										height: "48px",
										padding: "1.5px 6px",
									},
								},
							},
						}}
					/>
				</LocalizationProvider>
			</Box>
		);
	};
	const handleSheetNameChange = (event) => {
		setSheetName(event.target.value);
	};

	return (
		<CommonSlideDialog
			open={openGoogleSheetDialog}
			onClose={handleClose}
			disableBackDropClick
			title={
				<Box sx={{ display: "flex", flexDirection: "column", rowGap: 1 }}>
					<Box component={"img"} src={ALERT_ICON_V1} />
				</Box>
			}
			maxWidth="sm"
			titleVariant="Text/md/Semibold"
			titleStyle={{ p: 0, alignItems: "center" }}
			contentStyle={{ p: 0, flex: 1, display: "flex" }}
			fullWidth
			PaperProps={{
				sx: {
					m: 0,
					borderRadius: "24px",
					boxShadow: "0px 10px 25px 0px rgba(0, 0, 0, 0.05)",
					width: "100%",
					p: 5,
				},
			}}
			footerStyle={{ p: 0 }}
		>
			<Box sx={{ width: 1 }}>
				<Typography variant="Text/md/Semibold">Get Started with Google Sheets</Typography>
				<Box sx={{ mt: 2 }}>
					<Typography variant="Text/sm/Regular" sx={{ color: "rgba(102, 112, 133, 1)" }}>
						StartupOS' use and transfer to any other app of information received from Google APIs will adhere to{" "}
						<Link
							href={"https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"}
							target="_blank"
						>
							Google API Services User Data Policy
						</Link>{" "}
						, including the Limited Use requirements.
					</Typography>
				</Box>
				{intialLoading ? (
					<Box
						sx={(theme) => ({
							display: "flex",
							justifyContent: "center",
							mt: 4,
						})}
					>
						<CircularProgress
							sx={(theme) => ({
								height: "80px",
								width: "80px",
							})}
						/>
					</Box>
				) : (
					<Box sx={{ mt: 4 }} flex={1} display="flex" flexDirection={"column"} rowGap={4}>
						<Box display="flex" flexDirection={"column"} rowGap={"12px"}>
							<ExcelSection title={"1. Create Template & Select Date Range"} subTitle={"Name your Sheets file and select your date range."} />
							<Box display="flex" sx={{ pl: 1 }} gap={2}>
								<TextField
									placeholder="File Name"
									spellCheck="true"
									value={sheetName}
									fullWidth
									sx={{
										"& .MuiOutlinedInput-notchedOutline": {
											border: "none",
										},
										"& .MuiOutlinedInput-root": {
											boxShadow: "0px 10px 25px 0px rgba(0, 0, 0, 0.05)",
											fontFamily: (theme) => theme.typography["Text/sm/Regular"],
											color: "rgba(102, 112, 133, 1)",
										},
										"& input::placeholder": {
											fontFamily: (theme) => theme.typography["Text/sm/Regular"],
											color: "rgba(102, 112, 133, 1)",
										},
									}}
									InputProps={{
										style: {
											borderRadius: "100px",
											border: "none",
										},
									}}
									onChange={handleSheetNameChange}
								/>{" "}
								<Button
									sx={{ maxWidth: "fit-content" }}
									startIcon={createGoogleSheetLoading && <CircularProgress size={"1.25rem"} sx={{ color: "inherit" }} />}
									onClick={() => {
										handleCreateGoogleSheet();
									}}
									variant="DS1"
									disabled={
										!startDate?.value || !endDate?.value || !sheetName || startDate.error || endDate?.error || createGoogleSheetLoading
									}
								>
									{" "}
									Create
								</Button>
							</Box>
							<Box display={"flex"} alignItems={"center"} sx={{ pl: 1 }} gap={2}>
								<CustomDatePicker
									openDatePicker={openStartDate}
									setOpenDatePicker={setOpenStartDate}
									dateValue={startDate}
									setDateValue={setStartDate}
									type="START_DATE"
								/>
								<CustomDatePicker
									openDatePicker={openEndDate}
									setOpenDatePicker={setOpenEndDate}
									dateValue={endDate}
									setDateValue={setEndDate}
									type="END_DATE"
								/>
							</Box>
							<Typography sx={{ color: "red", pl: 1 }} variant="Text/sm/Regular">
								{startDate?.error || endDate?.error}{" "}
							</Typography>
						</Box>
						{googleFile?.metaData && (
							<>
								<ExcelSection
									title={"2. Fill in Template"}
									subTitle={"Fill in the template with your customers revenue expenses, cash balance and custom customer attributes."}
								/>
								<FileDisplayCard />

								<Button
									sx={{ maxWidth: "fit-content" }}
									startIcon={fetchGoogleSheetLoading && <CircularProgress size={"1.25rem"} sx={{ color: "inherit" }} />}
									onClick={() => {
										refreshData();
									}}
									variant="DS1"
									disabled={fetchGoogleSheetLoading}
								>
									Fetch Data
								</Button>
							</>
						)}
					</Box>
				)}
			</Box>
		</CommonSlideDialog>
	);
}

export default GoogleSheetDialog;
