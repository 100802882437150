import React from "react";
import { Box, Typography, Grid } from "@mui/material";

import FitContentCard from "../../ProductMarketFit/FitContent/FitContentCard";

const MOCK_SOLUTION_DATA = [
	{
		problem: {
			title: "Subheading",
			desc: "Real-time remote monitoring keeps the member and provider connected to beat chronic diseases together. Symptoms: Dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim. Existing Alternatives: Dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex. ",
		},
		solution: {
			title: "Subheading",
			values: [
				"House calls for comprehensive contextual healthcare on unmatched view into patient real life and SDoH.",
				"Telemedicine and teletherapy to care for the mind and body on your member’s terms.Telemedicine and teletherapy to care for the mind and body on your member’s terms.",
				"Real-time remote monitoring keeps the member and provider connected to beat chronic diseases together.",
			],
		},
	},
];

export default function BusinessContent() {
	return (
		<Box p="60px" sx={{ background: "#F9F9F9" }}>
			<Typography variant="card_name">Title FPO</Typography>
			{MOCK_SOLUTION_DATA.map(solution => (
				<Grid key={solution?.problem?.title} container spacing="60px" sx={{ marginTop: "-20px" }}>
					<Grid item xs={6}>
						<FitContentCard title={solution?.problem?.title}>
							<Box>
								<Typography variant="card_text">{solution?.problem?.desc}</Typography>
							</Box>
						</FitContentCard>
					</Grid>
					<Grid item xs={6}>
						<FitContentCard title={solution?.solution?.title}>
							{solution?.solution?.values.map((value, index) => (
								<Box key={value} display="flex" alignItems="flex-start" mb="30px">
									<Typography variant="card_name" sx={{ fontWeight: 600 }}>
										{index + 1}
									</Typography>
									<Typography variant="card_text" sx={{ marginLeft: "30px" }}>
										{value}
									</Typography>
								</Box>
							))}
						</FitContentCard>
					</Grid>
				</Grid>
			))}
		</Box>
	);
}
