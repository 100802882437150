import { PDFViewer, Document, Page, Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import { marked } from "marked";
import React from "react";
import { LetterHead, parseHtmlString, renderElement, styles } from "./utilities";
import moment from "moment";

function TextDocument({ type, contentString }) {
	if (type !== "markdown" && type !== "html") {
		throw new Error(`Invalid type prop: ${type}. Type must be "markdown" or "html".`);
	}

	const content = type == "markdown" ? parseHtmlString(marked(contentString)) : parseHtmlString(contentString);

	return (
		<Document>
			<Page style={styles.body}>
				<LetterHead />
				{content.map((element, index) => renderElement(element, index))}
				<Text style={styles.footerCopy} fixed>
					{moment().format("YYYY")} StartupOS Inc.
				</Text>
				<Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`} fixed />
			</Page>
		</Document>
	);
}

export default TextDocument;
