import { Box, Chip, Typography } from "@mui/material";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { setUserProfileData } from "../../../modules/actions/userProfileActions";
import CommonSlideDialog from "../../CommonSlideDialog";

const MultiTagDisplayPopUp = () => {
	const dispatch = useDispatch();
	const tagValue = useSelector((state) => state?.userProfileData.popUpData);
	const isOpen = useSelector((state) => state?.userProfileData.isPopUpOpen);
	const type = useSelector((state) => state?.userProfileData.popUpType);

	const handleClose = () => {
		dispatch(
			setUserProfileData({
				isPopUpOpen: false,
			})
		);
	};
	return (
		<CommonSlideDialog
			type={"EditPost"}
			disableBackDropClick={true}
			title={<Typography variant="feed_title">{type}</Typography>}
			titleVariant={"poppinsSemiBold30"}
			isOpen={isOpen}
			onClose={handleClose}
			contentStyle={{ px: 3 }}
			footerStyle={{ p: 0, pt: 3 }}
			maxWidth={"sm"}
			fullWidth
			PaperProps={{
				sx: (theme) => ({
					position: "fixed",
					bottom: 0,
					right: 0,
					m: 0,
					borderRadius: theme.shape.standard10.borderRadius,
					boxShadow: "0px 5px 16px rgba(0, 0, 0, 0.3)",
					p: 3,
				}),
			}}
			dialogAction={<></>}
		>
			<Box>
				{tagValue &&
					tagValue?.map((item) => {
						return <Chip variant="outlined" label={<Typography variant="Text/sm/Regular">{item}</Typography>} sx={{ mr: 1, mt: 1 }} />;
					})}
			</Box>
		</CommonSlideDialog>
	);
};

export default MultiTagDisplayPopUp;
