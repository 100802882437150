import React, { useContext } from "react";
import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import { CLOSEDEYEICON, DELETE_ICON_V1, EYE_ICON_OFF, EYE_ICON_V1, EYE_OFF_BLACK_FILLED, STARTUP_DEFAULT_LOGO } from "../../../../constants/image";
import SvgComponent from "../../../../utils/SVGColor";
import { ProfileContext } from "../..";
import { generatePath, useHistory } from "react-router-dom";
import ROUTES_PATH from "../../../../constants/routes";
import AvatarWithFallBack from "../../../../common/AvatarWithFallBack";
import { useDispatch } from "react-redux";
import { setSlidingProfileData } from "../../../../modules/actions/slidingProfileActions";

const TeammateCardItem = ({ teamItem, handleEyeIconAction }) => {
	const { viewContext } = useContext(ProfileContext);
	const history = useHistory();
	const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
	const dispatch = useDispatch();

	const handleProfileRedirection = () => {
		dispatch(setSlidingProfileData({ profileType: "", open: false }));
		history.push({
			pathname: generatePath(ROUTES_PATH.ACCOUNT_PROFILE, {
				id: teamItem?.id,
			}),
		});
	};
	return (
		<Box
			sx={{
				minWidth: 0,
				cursor: "pointer",
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "flex-start",
					background: "rgba(252, 252, 253, 1)",
					boxShadow: "0px 10px 15px rgba(0, 0, 0, 0.05)",
					p: 3,
					borderRadius: "16px",
					gap: "16px",
					opacity: teamItem?.isHidden ? 0.5 : 1,
					maxWidth: "100%",
					overflow: "hidden",
				}}
			>
				<Box display={"flex"} alignItems={"center"} gap={2}>
					{" "}
					<AvatarWithFallBack
						onClick={() => handleProfileRedirection()}
						sx={{ height: 64, width: 64, borderRadius: "50%", objectFit: "cover" }}
						src={teamItem?.picture}
					/>
					<Box sx={{ maxWidth: "100%", overflow: "hidden" }}>
						<Box onClick={() => handleProfileRedirection()}>
							{" "}
							<Typography
								variant="Text/sm/Regular"
								component={"div"}
								sx={{
									color: "black",
									textOverflow: "ellipsis",
									overflow: "hidden",
									whiteSpace: "nowrap",
									maxWidth: "200px",
								}}
							>
								{teamItem?.name}
							</Typography>
							{/* <Typography
							variant="customValue"
							component={"h6"}
							sx={{
								fontFamily: "PoppinsSemiBold",
								fontSize: "20px",
								lineHeight: "30px",
								color: "black",
								mb: "4px",
								textOverflow: "ellipsis",
								overflow: "hidden",
								whiteSpace: "nowrap",
								maxWidth: "100%",
								mb: 2,
							}}
						>
							{teamItem?.profession}
						</Typography> */}
						</Box>
					</Box>
				</Box>
				<Box>
					{viewContext?.isEditable && (
						<Button
							variant="containedGrayStyle"
							sx={{
								height: "36px",
								fontFamily: (theme) => theme.typography["Text/xs/Semibold"],
								color: "rgba(52, 64, 84, 1)",
								backgroundColor: "rgba(242, 244, 247, 1)",
							}}
							startIcon={
								teamItem?.isHidden ? (
									<Box component={"img"} sx={{ width: "16px" }} src={CLOSEDEYEICON} />
								) : (
									<Box component={"img"} sx={{ width: "16px" }} src={EYE_ICON_V1} />
								)
							}
							onClick={() => handleEyeIconAction(!teamItem?.isHidden, teamItem?.id)}
						>
							{" "}
							{`${teamItem?.isHidden ? "Unhide" : "Hide"} `}
						</Button>
					)}
				</Box>
			</Box>
		</Box>
	);
};

export default TeammateCardItem;
