import { Box } from "@mui/material";
import { get } from "lodash";
import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import services from "../../../../services";
import { ListCard } from "../ListCard";

export const ListController = ({
  title,
  type,
  mentors,
  startups,
  ...props
}) => {
  const [totalPages, settotalPages] = useState(0);
  const [totalElements, settotalElements] = useState(0);
  const [currentPage, setcurrentPage] = useState(1);
  const [currentCount, setcurrentCount] = useState(0);
  const [isFirstPage, setisFirstPage] = useState(false);
  const [isLastPage, setisLastPage] = useState(false);
  const [itemList, setitemList] = useState(null);
  const pageSize = 3;

  const getPendingInvites = page => {
    services
      .getPendingConnections({ perPage: pageSize, page: page })
      .then(resp => {
        if (resp.data?.data?.models) {
          setitemList(resp.data.data.models);
          setisLastPage(resp.data.data.isLast);
          setisFirstPage(resp.data.data.isFirst);
          settotalElements(resp.data.data.totalElements);
          settotalPages(resp.data.data.totalPages);
          if (resp.data.data.totalPages === 0) {
            setcurrentPage(0);
          } else {
            setcurrentPage(page);
          }
        }
      })
      .catch(e => {
        console.log("Could not retrieve list of pending connections.", e);
      });
  };

  const getConnections = page => {
    services
      .getStartupConnections({ perPage: pageSize, page: page })
      .then(resp => {
        if (resp.data?.data?.models) {
          setitemList(resp.data.data.models);
          setisLastPage(resp.data.data.isLast);
          setisFirstPage(resp.data.data.isFirst);
          settotalElements(resp.data.data.totalElements);
          settotalPages(resp.data.data.totalPages);
          if (resp.data.data.totalPages === 0) {
            setcurrentPage(0);
          } else {
            setcurrentPage(page);
          }
        }
      })
      .catch(e => {
        console.log("Could not retrieve list of startup connections.", e);
      });
  };

  const processMentors = resp => {};

  const processStartups = resp => {};

  const getYourMentees = page => {};

  const getFirstPage = () => {
    if (!isFirstPage) {
      if (type === "connections") {
        getConnections(1);
      } else if (type === "invites") {
        getPendingInvites(1);
      }
    }
  };

  const getLastPage = () => {
    if (!isLastPage) {
      if (type === "connections") {
        getConnections(totalPages);
      } else if (type === "invites") {
        getPendingInvites(totalPages);
      }
    }
  };

  const getNextPage = () => {
    if (!isLastPage) {
      if (type === "connections") {
        getConnections(currentPage + 1);
      } else if (type === "invites") {
        getPendingInvites(currentPage + 1);
      }
    }
  };

  const getPrevPage = () => {
    if (!isFirstPage) {
      if (type === "connections") {
        getConnections(currentPage - 1);
      } else if (type === "invites") {
        getPendingInvites(currentPage - 1);
      }
    }
  };

  useEffect(() => {
    if (type === "connections") {
      getConnections(1);
    } else if (type === "invites") {
      getPendingInvites(1);
    }
  }, []);

  useEffect(() => {
    if (isLastPage) {
      setcurrentCount(totalElements);
    } else {
      setcurrentCount(currentPage * pageSize);
    }
  }, [currentPage]);

  if (mentors) {
    return (
      <ListCard
        title={title}
        data={itemList}
        mentors
        type={type}
        currentPage={currentPage}
        totalPages={totalPages}
        getLastPage={getLastPage}
        getNextPage={getNextPage}
        getPrevPage={getPrevPage}
        getFirstPage={getFirstPage}
        pageSize={pageSize}
        totalElements={totalElements}
        routeToScheduling={props.routeToScheduling}
      />
    );
  } else if (startups) {
    return (
      <ListCard
        title={title}
        data={itemList}
        startups
        type={type}
        currentPage={currentPage}
        totalPages={totalPages}
        getLastPage={getLastPage}
        getNextPage={getNextPage}
        getPrevPage={getPrevPage}
        getFirstPage={getFirstPage}
        pageSize={pageSize}
        totalElements={totalElements}
      />
    );
  } else {
    return <ListCard title={title} />;
  }
};

function NetworkSidebar({ mentors, startups }) {
  return (
    <Fragment>
      {mentors && (
        <Fragment>
          <ListController
            title={startups ? "Connections you are mentoring" : "Your Mentors"}
            type={"connections"}
            mentors
          />
          <ListController
            mentors
            title="Invitations to Connect"
            type={"invites"}
          />
        </Fragment>
      )}
      {startups && (
        <Fragment>
          <ListController
            title={startups ? "Connections you are mentoring" : "Your Mentors"}
            type={"connections"}
            startups
          />
          <ListController
            startups
            title="Invitations to Connect"
            type={"invites"}
          />
        </Fragment>
      )}
    </Fragment>
  );
}

export default NetworkSidebar;
