import services from "../../services";

export const areYouEnterpriseAI = async ({ companyName, companyIndustries, answers, creativity }) => {
	const userValues = {
		startup_name: companyName,
		company_stage: answers.currentStage,
		monthly_recurring_revenue: answers?.monthlyRevenue,
		capital_raised_to_date: answers?.capitalRaised,
		current_runway_in_months: answers?.runway,
		team_size: answers?.companySize,

		long_term_strategic_plan: answers?.readyStartegicPlan,
		strategic_partnerships_or_alliances_to_enhance_value_proposition: answers?.readyStrategicPartnerships,
		hold_at_least_one_patent: answers?.patent,
		strategy_for_leveraging_unique_product: answers?.strategy_for_leveraging,
		startup_own_cash_or_collateral_equal_at_least_80_perc: answers?.startup_own_cash,
		any_form_of_litigation: answers?.any_litigation,
		exceptional_risks: answers?.legal_liabilities,
		offering_scale: answers?.product_offering_scale,
		unique_value_proposition: answers?.unique_value_proposition,
		understanding_of_landscape: answers?.competitive_landscape,
		roadmap_for_future_product: answers?.ready_roadmap,
		process_for_soliciting_and_incorporating_customer_feedback: answers?.process_for_soliciting,
		processes_to_support_customers: answers?.support_customers,
		flagship_customer: answers?.flagship_customer,
		proven_pricing_strategy_and_saleable_packaged_product: answers?.proven_pricing_strategy,
		profitable_sales_and_marketing_strategy: answers?.proven_sales,
		metrics_in_place_to_measure_success_of_sales: answers?.measure_the_success,
		endorsements_testimonials_and_referrals_outside_friends_family: answers?.endorsements,
		business_meet_operational_regional_and_industry_compliance: answers?.business_meet_operational,
		clear_policies_for_regulatory_compliance_in_operational_regions: answers?.clear_policies_for_regulatory,
		ready_for_procurement_contracts_pricing_NDAs: answers?.ready_for_procurement,
		attract_and_retain_talent: answers?.attract_and_retain_talent,
		clear_HR_policies: answers?.clear_HR_policies,
		clear_environmental_sustainability_policies: answers?.clear_environmental_sustainability_policies,
		social_responsibility_initiatives: answers?.social_responsibility_initiatives,
		depend_on_a_Web: answers?.depend_on_a_Web,
		SOC2_compliant: answers?.SOC2_compliant,
		platform_meet_compliance: answers?.platform_meet_compliance,
		technology_infrastructure_scale: answers?.technology_infrastructure_scale,
		system_monitoring: answers?.system_monitoring,
		robust_data_analytics: answers?.robust_data_analytics,
		disaster_recovery_plan: answers?.disaster_recovery_plan,
		technological_upgrades: answers?.technological_upgrades,
		hardwareproduct: answers?.hardwareproduct,
		deliver_the_hardware: answers?.deliver_the_hardware,
		maintain_the_hardware: answers?.maintain_the_hardware,
		hardware_returned: answers?.hardware_returned,
	};
	let payload = {
		messages: [
			{
				role: "system",
				content: `
			Based on these answers evaluate this company’s readiness to engage with and meet the demands of enterprise-level buyers. Respond in100 words, or less
				`,
			},
			{
				role: "user",
				content: JSON.stringify(userValues),
			},
		],

		maxTokens: 1000,
		temperature: creativity === 1 ? 0.99 : creativity,
		topP: creativity === 1 ? 0.99 : creativity,
	};

	let attempts = 0;
	while (attempts < 2) {
		try {
			let AIResp = await services.getOpenAIChatResponse(payload).catch((e) => {
				console.log(e);
			});
			if (AIResp?.data?.data?.[0]?.text) {
				return AIResp?.data?.data?.[0]?.text;
			} else if (AIResp?.data?.data?.[0]?.message?.content) {
				return AIResp?.data?.data?.[0]?.message?.content;
			} else {
				attempts++;
			}
		} catch (error) {
			console.error(error);
		}
	}
	return false;
};

/*

Assumptions:

Question#1: Are you working full-time on your startup? [working_fulltime]

Question#2: Are you a properly incorporated entity? [properly_incorporated]

Question#3: Do you have a cap table? [captable]

Question#4: Do you have a website? [website]

Question#5: How strong is your investor pitch presentation? [investor_pitch_strength]

Question#6: What is the current state of your product or service? [current_product_state]

Question#7: Do you have any monthly recurring revenue (MRR)? [monthly_recurring_revenue]

Question#8: What is your growth rate? [growth_rate]

Question#9: Is your startup scalable? [scalable]

Gather this data, but do not display the answers. Instead, use the data to complete the recommendation and answer questions below:

{

  company_stage (ex. pre-seed, seed, series A),

  next_investment_round_minimum (ex. $1m),
  
  next_investment_round_maximum (ex. $10m),
  
  minium_company_valuation (ex. $750k),
  
  maximum_company_valuation (ex. $4m),
  
  investors_available_at_company_stage (ex. Friends and Family, Seed Accelerators, Angels, Pre-Seed/Micro VC Funds, Seed Accelerators, Angels, Seed VC Funds, Series A VC Funds),
  
  typical_investment_vehicle_at_company_stage (ex. SAFE Agreement, Convertible Note, Priced Round)

}

Provide a recommendation in the following format:

Based on your answers, we believe you are a {company_stage} stage company that should seek a round size of {next_investment_round_minimum} - {next_investment_round_maximum} at a company valuation of {minium_company_valuation} -{maximum_company_valuation}.  Investors available to you in this stage are {investors_available_at_company_stage}.  A typical investment vehicle at this stage is a {typical_investment_vehicle_at_company_stage}. 

Provide additional recommendations:

If Question#5 answer is not “Strong” then, list reasons why a compelling pitch is important. 

If Question#1 answer is “No” then, recommend a full-time commitment to the startup, and list reasons why this is good.

If Question#3 answer is “No” then, recommend building a cap table, and list reasons why this is good.

If Question#4 answer is “No” then, recommend building a website before meeting with investors, and list reasons why this is good.
*/
