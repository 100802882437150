import React from "react";
import {
  Box,
  Breadcrumbs,
  Link,
  Typography,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import theme from "../../../../../constants/Theme";
import ArrowBack from "@mui/icons-material/ArrowBack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useHistory } from "react-router-dom";
import ROUTES_PATH from "../../../../../constants/routes";

const BreadcrumbsComp = ({
  powerupDetails,
  sectionTitle,
  mainTitle = "Workspace",
  handleMainTitleAction = () => {},
  handleBackButtonAction = () => {},
  hideBackButton,
}) => {
  const history = useHistory();
  const handleWorkspaceNavigation = () => {
    history.push(ROUTES_PATH.STARTUPS_HOME);
  };

  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("md"));

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      {!hideBackButton && (
        <IconButton
          aria-label="ArrowBack"
          color="primary"
          onClick={handleBackButtonAction}
        >
          <ArrowBack />
        </IconButton>
      )}

      <Breadcrumbs
        aria-label="breadcrumb"
        separator={
          <NavigateNextIcon
            sx={{
              ml: 1.5,
              mr: 1,
              [theme.breakpoints.down("md")]: {
                ml: 0,
              },
            }}
          />
        }
      >
        <Link
          underline="hover"
          color="primary"
          component="button"
          onClick={() => {
            mainTitle === "Workspace"
              ? handleWorkspaceNavigation()
              : handleMainTitleAction();
          }}
        >
          <Typography color="primary" variant="h5">
            {mainTitle}
          </Typography>
        </Link>
        {!isSmallScreen && powerupDetails?.powerUpName && (
          <Typography color="text.primary" variant="h5">
            {powerupDetails?.powerUpName}
          </Typography>
        )}

        {!isSmallScreen && sectionTitle && (
          <Typography color="text.primary" variant="h5">
            {sectionTitle}
          </Typography>
        )}
      </Breadcrumbs>
    </Box>
  );
};

export default BreadcrumbsComp;
