import React, { useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
} from "@mui/material";
import { BarChart } from "../../../components/Charts/BarChart";
import { HorizontalBarChart } from "../../../components/Charts/HorizontalBarChart";
import DownloadResultCsv from "../../../common/DopwnloadCsvButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function Results({
  surveyResponseDetails,
  isCompanySurvey,
  surveyId,
}) {
  return (
    <>
      {isCompanySurvey ? (
        surveyResponseDetails?.answers_for_charts ? (
          <Box>
            <Box display="flex" mb={8}>
              <Box display="flex" flexDirection="column">
                <Typography
                  variant="title_large"
                  sx={{ fontWeight: 600, color: "#464B4E" }}
                >
                  Analyze Results
                </Typography>
                <Typography
                  variant="body_large"
                  sx={{ color: "#6A7575", mt: 1 }}
                >
                  {surveyResponseDetails?.responses}{" "}
                  {surveyResponseDetails?.responses === 1
                    ? "response"
                    : "responses"}
                </Typography>
              </Box>

              <Box sx={{ ml: "auto" }}>
                <DownloadResultCsv
                  text={"Download full results (.csv)"}
                  data={
                    surveyResponseDetails?.answers_for_csv
                      ? [
                          surveyResponseDetails.answers_for_csv.header,
                          ...surveyResponseDetails.answers_for_csv.rows,
                        ]
                      : []
                  }
                  filename={"SurveyResult.csv"}
                  surveyId={surveyId}
                />
              </Box>
            </Box>
            <Box>
              {surveyResponseDetails?.answers_for_charts &&
                surveyResponseDetails?.answers_for_charts?.map(
                  (surveyItem, idx) => {
                    return (
                      <Box
                        display="flex"
                        flexDirection="column"
                        key={idx}
                        sx={{ mt: 2 }}
                      >
                        <Typography
                          variant="body_large"
                          sx={{ color: "#0A141B" }}
                        >
                          {idx + 1 + ". "}
                          {surveyItem?.heading}
                        </Typography>

                        <Box sx={{ mt: 2, maxWidth: "800px" }}>
                          {surveyItem?.family === "matrix" && (
                            <BarChart
                              family={surveyItem?.family}
                              heading={surveyItem?.heading}
                              answers={surveyItem?.answers}
                            />
                          )}
                          {surveyItem?.family === "single_choice" && (
                            <HorizontalBarChart
                              family={surveyItem?.family}
                              heading={surveyItem?.heading}
                              answers={surveyItem?.answers}
                            />
                          )}
                          {surveyItem?.family === "multiple_choice" && (
                            <HorizontalBarChart
                              family={surveyItem?.family}
                              heading={surveyItem?.heading}
                              answers={surveyItem?.answers}
                            />
                          )}
                          {surveyItem?.family === "open_ended" && (
                            <Box>
                              <Accordion>
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls={{ idx }}
                                  id={{ idx }}
                                >
                                  <Typography>
                                    {surveyItem?.answers.length + " answer(s)"}
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Typography>
                                    {surveyItem?.answers.map(
                                      (surveyAnswer, idx) => {
                                        return (
                                          <ul key={idx}>{surveyAnswer}</ul>
                                        );
                                      }
                                    )}
                                  </Typography>
                                </AccordionDetails>
                              </Accordion>
                            </Box>
                          )}
                        </Box>
                        {/* {surveyResponseDetails?.answers_for_charts?.length - 1 !==
                      idx && <Divider sx={{ mt: 2 }} />} */}
                      </Box>
                    );
                  }
                )}
            </Box>
          </Box>
        ) : surveyResponseDetails ? (
          <Typography
            variant="title_large"
            sx={{ fontWeight: 600, color: "#464B4E" }}
          >
            This survey has been sent
          </Typography>
        ) : (
          <Box justifyContent={"center"} sx={{ display: "flex", mt: 4 }}>
            <CircularProgress />
          </Box>
        )
      ) : (
        <Typography
          variant="title_large"
          sx={{ fontWeight: 600, color: "#464B4E" }}
        >
          Send a survey to collect and analyze results
        </Typography>
      )}
    </>
  );
}
