import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { saveAs } from "file-saver";
import { useTheme } from "@twilio-paste/theme";
import { getBlobFile, getConversationParticipants, getMessageStatus } from "../../../../Messaging/api";
import MessageView from "./MessageView";
import { actionCreators } from "../../../../Messaging/store";
import ImagePreviewModal from "../../../../Messaging/components/modals/ImagePreviewModal";
import Horizon from "./Horizon";
import { successNotification, unexpectedErrorNotification } from "../../../../Messaging/helpers";
import MessageMedia from "./MessageMedia";
import { getSdkMediaObject, getSdkMessageObject } from "../../../../Messaging/conversations-objects";
import { getSdkConversationObject } from "../../../../Messaging/conversations-objects";
import TimeAgo from "javascript-time-ago";
import { STARTUPOSCIRCLEICON } from "../../../../../constants";
import { Box } from "@mui/material";
// function getMessageTime(message) {
// 	console.log(`message`, message);
// 	const dateCreated = message.dateCreated;

// 	return dateCreated ? new TimeAgo("en-US").format(new Date(dateCreated), "twitter-now") : "";
// }
function getMessageTime(message) {
	const dateCreated = message.dateCreated;

	if (!dateCreated) return "";

	const date = new Date(dateCreated);

	const dateOptions = { month: "short", day: "numeric", weekday: "long" };
	const timeOptions = { hour: "numeric", minute: "numeric", hour12: true };

	const formattedDate = new Intl.DateTimeFormat("en-US", dateOptions).format(date);
	const formattedTime = new Intl.DateTimeFormat("en-US", timeOptions).format(date);

	const formattedMessageTime = `${formattedDate}   ${formattedTime}`;

	return formattedMessageTime;
}
const MessageList = (props) => {
	const { messages, conversation, lastReadIndex } = props;
	const theme = useTheme();
	const myRef = useRef(null);
	const messagesLength = messages.length;
	const dispatch = useDispatch();
	const { addAttachment, addNotifications, updateCurrentConversation } = bindActionCreators(actionCreators, dispatch);
	const conversationAttachments = useSelector((state) => state.attachments[conversation.sid]);
	const [imagePreview, setImagePreview] = useState(null);
	const [horizonMessageCount, setHorizonMessageCount] = useState(0);
	const [showHorizonIndex, setShowHorizonIndex] = useState(0);
	const [scrolledToHorizon, setScrollToHorizon] = useState(false);
	const currentUser = useSelector((state) => state?.auth);
	const lastMessageRef = useRef(null);

	useEffect(() => {
		// if (scrolledToHorizon || !myRef.current) {
		// 	return;
		// }
		// myRef.current.scrollIntoView({
		// 	top: myRef.current.scrollHeight,
		// 	behavior: "smooth",
		// });
		// setScrollToHorizon(true);

		if (lastMessageRef.current) {
			lastMessageRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
		}
	}, [messagesLength]);
	useEffect(() => {
		if (lastReadIndex === -1 || horizonMessageCount) {
			return;
		}
		const showIndex = 0;
		getSdkConversationObject(conversation)
			.getUnreadMessagesCount()
			.then((count) => {
				setHorizonMessageCount(count ?? 0);
				setShowHorizonIndex(showIndex);
			});
	}, [messages, lastReadIndex]);
	function setTopPadding(index) {
		if (
			props.messages[index] !== undefined &&
			props.messages[index - 1] !== undefined &&
			props.messages[index].author === props.messages[index - 1].author
		) {
			return theme.space.space20;
		}
		return theme.space.space50;
	}
	const onDownloadAttachments = async (message) => {
		const attachedMedia = message.attachedMedia?.map(getSdkMediaObject);
		if (message.index === -1) {
			return undefined;
		}
		if (!attachedMedia?.length) {
			return new Error("No media attached");
		}
		for (const media of attachedMedia) {
			const blob = await getBlobFile(media, addNotifications);
			addAttachment(props.conversation.sid, message.sid, media.sid, blob);
		}
		return;
	};
	const onFileOpen = (file, { filename }) => {
		saveAs(file, filename ?? "");
	};

	const getAuthorMeta = (id) => {
		if (id == currentUser?.userId) {
			return {
				id: currentUser?.userId,
				email: currentUser?.email,
				name: currentUser?.userName,
				picture: currentUser?.userData?.picture,
				userType: currentUser?.userType,
				participantState: "ACTIVE",
			};
		} else if (id == "system") {
			return {
				id: "system",
				email: "",
				name: "System Message",
				picture: STARTUPOSCIRCLEICON,
				userType: "SYSTEM",
				participantState: "ACTIVE",
			};
		} else {
			try {
				return conversation?.startuposMeta?.conversationParticipants?.find((obj) => obj.id == id);
			} catch (e) {
				console.log(`Issue processing conversation meta for this author: ${id}.`, e);
				return {};
			}
		}
	};
	return (
		<Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
			{messages.map((message, index) => {
				const messageImages = [];
				const messageFiles = [];
				(message.attachedMedia || []).forEach((file) => {
					const { contentType } = file;
					if (contentType.includes("image")) {
						messageImages.push(file);
						return;
					}
					messageFiles.push(file);
				});
				const attributes = message.attributes;

				return (
					<Box key={message.sid + "message"} ref={lastMessageRef}>
						{/* Hide missed count */}
						{/*{lastReadIndex !== -1 &&
            horizonMessageCount &&
            showHorizonIndex === message.index ? (
              <Horizon ref={myRef} messageCount={horizonMessageCount} />
            ) : null}*/}
						<MessageView
							reactions={attributes["reactions"]}
							text={message.body ?? ""}
							media={
								message.attachedMedia?.length ? (
									<MessageMedia
										key={message.sid}
										attachments={conversationAttachments?.[message.sid]}
										onDownload={async () => await onDownloadAttachments(message)}
										images={messageImages}
										files={messageFiles}
										sending={message.index === -1}
										onOpen={(mediaSid, image, file) => {
											if (file) {
												onFileOpen(conversationAttachments?.[message.sid][mediaSid], file);
												return;
											}
											if (image) {
												setImagePreview({
													message,
													file: conversationAttachments?.[message.sid][mediaSid],
													sid: mediaSid,
												});
											}
										}}
									/>
								) : null
							}
							author={message.author ?? ""}
							authorMeta={getAuthorMeta(message.author)}
							getStatus={getMessageStatus(message, props.participants)}
							onDeleteMessage={async () => {
								try {
									await getSdkMessageObject(message).remove();
									successNotification({
										message: "Message deleted.",
										addNotifications,
									});
								} catch {
									unexpectedErrorNotification(addNotifications);
								}
							}}
							topPadding={setTopPadding(index)}
							lastMessageBottomPadding={index === messagesLength - 1 ? 16 : 0}
							sameAuthorAsPrev={setTopPadding(index) !== theme.space.space20}
							messageTime={getMessageTime(message)}
							updateAttributes={(attribute) =>
								getSdkMessageObject(message).updateAttributes({
									...attributes,
									...attribute,
								})
							}
						/>
					</Box>
				);
			})}
			{imagePreview
				? (function () {
						const dateString = imagePreview?.message.dateCreated;
						const date = dateString ? new Date(dateString) : "";
						return (
							<ImagePreviewModal
								image={imagePreview.file}
								isOpen={!!imagePreview}
								author={imagePreview?.message.author ?? ""}
								date={date ? date.toDateString() + ", " + date.getHours() + ":" + (date.getMinutes() < 10 ? "0" : "") + date.getMinutes() : ""}
								handleClose={() => setImagePreview(null)}
								onDownload={() => {
									saveAs(imagePreview.file, imagePreview.message.attachedMedia?.find(({ sid }) => sid === imagePreview.sid)?.filename ?? "");
								}}
							/>
						);
				  })()
				: null}
		</Box>
	);
};
export default MessageList;
