import { CircularProgress, useMediaQuery, Box, Button, Typography, IconButton, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import DialogComponent from "../../../common/CommonDialog";
import theme from "../../../constants/Theme";
import SocialAccountConnect from "./SocialAccountConnect";
import SocialConnect from "../../../components/Workspace/SocialConnect";
import services from "../../../services";
import AccoutDetails from "../AccountSettings/AccoutDetails";
import ChangePopup from "../../../components/AccountSettings/ChangeEmail";
import MuiSnackBar from "../../../common/MuiSnackBar";
import ChangePasswordContainer from "../../../components/AccountSettings/ChangePassword";
import { useHistory, useLocation } from "react-router-dom";
import ROUTES_PATH from "../../../constants/routes";
import { toastContainer } from "../../../utils/toast";
import messages from "../../../constants/messages";
import { getUser, logout } from "../../../utils/auth0-spa";
import { authWebLogout } from "../../../utils/auth0";
import { handleMixPanel } from "../../../utils/mixPanelEventHandling";
import { CLOSE_ICON_V2, LINK_PARTNER_GRID_PURPLE, PURPLE_SWITCH_ICON_V2, SWITCH_ICON_V2 } from "../../../constants";
import CommonSlideDialog from "../../../common/CommonSlideDialog";
import CloseIcon from "@mui/icons-material/Close";
import SvgComponent from "../../../utils/SVGColor";

export default function AccountSettingsPopup({ open, onClose }) {
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
	const [profileData, setProfileData] = useState(null);
	const [showInitial, setShowInitial] = useState(true);
	const [showEmail, setShowEmail] = useState(false);
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [updateEnable, setUpdateEnable] = useState(true);
	const [verificationPending, setVerificationPending] = useState(false);
	const [openSnackbar, setOpenSnackbar] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const [saveDisable, setSaveDisable] = useState(true);
	const [currentPassword, setCurrentPassword] = useState("");
	const [newPassword, setNewPassword] = useState("");
	const history = useHistory();
	const [message, setMessage] = useState("");
	const [emailNew, setEmailNew] = useState("");
	const [passwordError, setPasswordError] = useState(false);
	const [verificationData, setVerificationData] = useState("");
	const [requestId, setRequestId] = useState("");
	const [changeEamil, setChangeEmail] = useState(false);
	const [callLogout, setCallLogout] = useState(false);

	const handleShowEmail = () => {
		setShowInitial(false);
		setShowPassword(false);
		setShowEmail(true);
		setChangeEmail(true);
		setVerificationPending(false);
		setEmail("");
	};

	const handleBack = () => {
		setShowEmail(false);
		setChangeEmail(false);
		setShowPassword(false);
		setShowInitial(true);
	};

	const handleClose = () => {
		handleBack();
		onClose();
	};

	const getProfileData = () => {
		try {
			services
				.getUserProfile()
				.then((res) => {
					setProfileData(res.data?.data);
				})
				.catch((err) => {
					console.log(err);
				});
		} catch (e) {
			console.log("Account Settings: Attempted to open but failed to get user data. Failed with error:", e);
		}
	};

	useEffect(() => {
		if (open) {
			handleMixPanel("Account Settings Viewed", {
				Page: "Overview",
			});
			getProfileData();
		}
	}, [open]);

	const handleShowPassword = () => {
		handleMixPanel("Account Settings Viewed", {
			Page: "Change Password",
		});

		setShowInitial(false);
		setShowEmail(false);
		setChangeEmail(false);
		setShowPassword(true);
	};

	const handleSnackBar = () => {
		if (showEmail || verificationPending) {
			setMessage("Verification email sent.");
		} else if (showPassword) {
			setMessage("Changes saved.");
		}

		setOpenSnackbar(true);
	};

	//update password
	const handleChangePassword = () => {
		handleMixPanel("Password Changed");

		let payload = {
			currentPassword: currentPassword,
			newPassword: newPassword,
		};

		if (payload) {
			services
				.changePassword(payload)
				.then((res) => {
					handleSnackBar();
					handleBack();
				})
				.catch((err) => {
					toastContainer(err.response.data.message, "error");
					handleBack();
				});
		}
	};

	const handleVerificationPending = () => {
		setShowInitial(false);
		setShowPassword(false);
		setShowEmail(true);
		setChangeEmail(false);
		setVerificationPending(true);
	};

	//check if change email is initiated
	const handleEmailVerificationStatus = () => {
		handleMixPanel("Account Settings Viewed", {
			Page: "Change Email",
		});

		services
			.changeEmailRequestDetails()
			.then((res) => {
				if (res?.data?.data?.requestStatus === "PENDING") {
					setVerificationData(res?.data?.data);
					setRequestId(res?.data?.data.id);
					handleVerificationPending();
				} else {
					handleShowEmail();
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const onLgoutHandler = async () => {
		await logout();
		await authWebLogout();
	};

	//update new email id
	const updateCurrentEmail = () => {
		let payload = { newEmail: emailNew, password: password };

		if (payload) {
			services
				.changeEmailRequest(payload)
				.then((res) => {
					if (res.status === 200) {
						setMessage("Verification email sent", "success");
						setCallLogout(true);
						//  handleEmailVerificationStatus();
						//  if (verificationPending) {
						//    setChangeEmail(false);
						//  }
						//} else {
						//  setPasswordError(true);
					}

					//handleClose();
					//setOpenSnackbar(true);
				})
				.catch((err) => {
					console.log(err);
					toastContainer(err.response.data.message, "error");
				});
		}
	};

	useEffect(() => {
		if (callLogout) {
			onLgoutHandler();
		}
	}, [callLogout]);

	//send verification email
	const handleVerificationEmail = () => {
		handleMixPanel("Password Changed");

		services
			.resendChangeEmailRequest({ id: requestId })
			.then((res) => {
				setCallLogout(true);
				//handleSnackBar();
				//handleClose();
			})
			.catch((err) => {
				console.log(err);
			});
	};

	//Accout Settings FooterComponent
	const FooterComponent = () => {
		return (
			<>
				{showEmail && verificationPending && (
					<Box
						sx={{
							display: "flex",
							width: "auto",
							mb: 2,
							pr: { md: 4 },
						}}
					>
						{changeEamil ? (
							<Button variant="DS1" sx={{ width: "max-content", mr: 4 }} onClick={updateCurrentEmail} disabled={updateEnable}>
								Update Email
							</Button>
						) : (
							<Button variant="DS1" sx={{ width: "max-content" }} onClick={handleVerificationEmail}>
								Resend Verification Email
							</Button>
						)}
					</Box>
				)}
				{showEmail && !verificationPending && (
					<Box
						sx={{
							display: "flex",
							width: { md: "auto" },
							// mb: 2,
							pr: { md: 2 },
						}}
					>
						<Button
							variant="DS1"
							sx={{ width: "max-content" }}
							disabled={updateEnable}
							onClick={() => {
								updateCurrentEmail();
							}}
						>
							Update Email
						</Button>
					</Box>
				)}

				{!showEmail && showPassword && (
					<Box
						sx={{
							display: "flex",
							width: { md: "auto" },
							// mb: 2,
							pr: { md: 1 },
						}}
					>
						<Button variant="DS1" sx={{ width: "max-content" }} disabled={saveDisable} onClick={handleChangePassword}>
							Update Password
						</Button>
					</Box>
				)}
			</>
		);
	};

	return (
		<>
			<CommonSlideDialog
				open={open}
				onClose={handleClose}
				titleStyle={{ p: 0, alignItems: "center" }}
				titleVariant="title_medium"
				maxWidth="sm"
				dialogAction={showEmail || showPassword ? <FooterComponent /> : ""}
				// contentStyle={{ px: 0 }}
				contentStyle={{ p: 0, my: 1, flex: 1, pr: 1 }}
				footerStyle={{
					border: "none",
					p: 0,
					mt: 2,
				}}
				PaperProps={{
					sx: {
						m: 0,
						borderRadius: "24px",
						boxShadow: "0px 10px 25px 0px rgba(0, 0, 0, 0.05)",
						width: "100%",
						// height: "",
						p: 4,
					},
					// style: {
					// 	borderRadius: 8,
					// 	width: isSmallScreen ? "100%" : theme.breakpoints.values.sm,
					// },
				}}
				disableBackDropClick
			>
				{profileData ? (
					<>
						{showInitial && (
							<>
								<Box display={"flex"} justifyContent={"space-between"} alignItems={"baseline"}>
									<Stack spacing={2}>
										<Box component={"img"} src={PURPLE_SWITCH_ICON_V2} sx={{ width: "30px", height: "30px" }} />
										<Typography variant="Text/md/Semibold">Account Settings</Typography>
									</Stack>

									<IconButton
										aria-label="close"
										onClick={handleClose}
										sx={{
											color: theme.palette.secondary.dark,
										}}
									>
										<Box component={"img"} src={CLOSE_ICON_V2} alt="back" />
									</IconButton>
								</Box>
								<AccoutDetails data={profileData} handleShowEmail={handleEmailVerificationStatus} handleShowPassword={handleShowPassword} />
								{/* Hide Social account connections as per exec. team request 10/25 */}

								{/*<SocialAccountConnect
                  closePopup={handleClose}
                  profileData={profileData}
                />*/}
							</>
						)}
						{!showInitial && showEmail && (
							<ChangePopup
								setEmail={setEmailNew}
								setPassword={setPassword}
								verificationPending={verificationPending}
								userProfileData={profileData}
								handleBack={handleBack}
								password={password}
								email={emailNew}
								setUpdateEnable={setUpdateEnable}
								updateEnable={updateEnable}
								passwordError={passwordError}
								verificationData={verificationData}
								setChangeEmail={setChangeEmail}
								changeEamil={changeEamil}
								showEmail={showEmail}
							/>
						)}

						{!showInitial && showPassword && (
							<ChangePasswordContainer
								handleBack={handleBack}
								setSaveDisable={setSaveDisable}
								setCurrentPassword={setCurrentPassword}
								setNew={setNewPassword}
							/>
						)}
					</>
				) : (
					<Box sx={{ p: 5, display: "flex", justifyContent: "center" }}>
						<CircularProgress />
					</Box>
				)}
				<MuiSnackBar message={message} vertical={"bottom"} horizontal={"left"} open={openSnackbar} setOpenSnackbar={setOpenSnackbar} />
			</CommonSlideDialog>
		</>
	);
}
