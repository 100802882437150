import React, { Fragment, useEffect } from "react";
import { Box, Divider, Typography } from "@mui/material";
import { AttributeItem } from "./attribute-item";

export const DetailsTab = ({ ...props }) => {
  const [tab, setTab] = React.useState(props.tab);
  useEffect(() => {
    setTab(props.tab);
  }, [props.tab]);
  return (
    <Fragment>
      <Box
        sx={theme => ({
          //boxShadow: theme.palette.primary.elevationLight2,
          [theme.breakpoints.down("md")]: {
            p: 1,
            border: `1px solid ${theme.palette.secondary.outline2}`,
            borderRadius: "4px",
          },
        })}
      >
        <Typography
          variant="title_medium"
          sx={{ textTransform: "capitalize", my: 2 }}
        >
          Details
        </Typography>
        <Divider sx={{ mt: 2 }} />

        <Box
          sx={{
            boxSizing: "border-box",
            flexGrow: 1,
            overflow: "scroll",
          }}
        >
          {tab && tab.knowledgeDetail && (
            <Box sx={{ my: 2 }}>
              <Typography>{tab.knowledgeDetail}</Typography>
            </Box>
          )}

          {tab && tab.timeEstimate && (
            <Box sx={{ my: 4 }}>
              <AttributeItem
                name={"Time Estimate"}
                value={tab.timeEstimate}
                icon={"time"}
              />
            </Box>
          )}
          {tab && tab.powerUpDifficulty && (
            <Box sx={{ my: 4 }}>
              <AttributeItem
                name={"Difficulty"}
                value={String(tab.powerUpDifficulty)
                  .toLowerCase()
                  .replace(/(^|\s)\S/g, function (t) {
                    return t.toUpperCase();
                  })}
                icon={"steps"}
              />
            </Box>
          )}
          {tab && tab.teamRecommended && (
            <Box sx={{ my: 4 }}>
              <AttributeItem
                name={"Team Recommended"}
                value={tab.teamRecommended}
                icon={"person"}
              />
            </Box>
          )}
        </Box>
      </Box>
    </Fragment>
  );
};
