import React from "react";
import { useHistory } from "react-router-dom";
import { List, Typography } from "@mui/material";
import { ListItemComp } from "./styles";

const NAV_ITEMS = [
	{ id: 1, title: "Things to Do", route: "/home/to-do" },
	{ id: 2, title: "PowerPacks", route: "/home/powerpacks" },
	{ id: 3, title: "PowerUps", route: "/home/powerups" },
	{ id: 4, title: "Tools", route: "/home/tools" },
	// { id: 5, title: "Work History", route: "/home/work-history" },
];

export default function WorkspaceNavbar({ activeKey }) {
	const history = useHistory();

	return (
		<List
			sx={(theme) => ({
				display: "flex",
				flexWrap: "wrap",
				gap: 3,
			})}
		>
			{NAV_ITEMS.map((item) => (
				<ListItemComp key={item.id} onClick={() => history.push(item.route)} selected={activeKey === item.id}>
					<Typography color={activeKey === item.id ? "#FFFFFF" : "#344054"} variant="title_large2">
						{item.title}
					</Typography>
				</ListItemComp>
			))}
		</List>
	);
}
