import React from "react";
import {
  List,
  ListItem,
  ListItemText,
  Typography,
  ListItemIcon,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setAuthData } from "../../modules/actions";
import { sosPrimary } from "../../constants/Theme/colors";

function NavItemsList({ active, listItems }) {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleListItemClick = listItem => {
    if (listItem.external) {
      if (listItem.target) {
        window.open(listItem.link, listItem.target);
      } else {
        window.open(listItem.link);
      }
    } else {
      dispatch(setAuthData({ currentSelectedNavItem: listItem.key }));
      history.push(listItem.link);
    }
  };

  return (
    <List>
      {listItems.map((item, i) => {
        return (
          <ListItem
            key={i}
            sx={theme => ({
              cursor: "pointer",
              p: 2,
              mb: 1,
              borderRadius: theme.shape.standard25.borderRadius,
              "&:hover": {
                border: `1px solid ${theme.palette.primary.sysLight}`,
                backgroundColor: sosPrimary[50],
              },
              border:
                active === item.key
                  ? `1px solid ${theme.palette.primary.sysLight}`
                  : "1px solid transparent",
            })}
            onClick={() => handleListItemClick(item)}
            disablePadding
          >
            <ListItemIcon
              sx={theme => ({
                color:
                  active === item.key
                    ? theme.palette.text.darkBlue
                    : theme.palette.text.onSurfaceVariant,
                minWidth: 0,
                pr: 2,
              })}
            >
              {item.icon}
            </ListItemIcon>

            <ListItemText>
              <Typography
                variant="nav_button"
                sx={theme => ({
                  color: active === item.key && theme.palette.primary.sysLight,
                })}
              >
                {item.label}
              </Typography>
            </ListItemText>
          </ListItem>
        );
      })}
    </List>
  );
}

export default NavItemsList;
