import styled from "@emotion/styled";
import theme from "../../../../constants/Theme";
import { Box } from "@mui/material";

export const BoxBrowse = styled(Box)(({theme}) => ({
    display: "flex", 
    marginTop: "30px",
    marginBottom: "24px"
}));

// export const BoxSearch = styled(Box)(({theme}) => ({
//     width: '43.3871%', 
//     height: "56px",
//     display: "flex",
//     borderColor: "black",
//     border: 1,
//     borderRadius: "4px"
// }));