import { Box, Typography, Divider, Chip, Stack } from "@mui/material";
import React from "react";
import styled from "@emotion/styled";
import ClearIcon from "@mui/icons-material/Clear";
import { BLUE_DOT_ICON } from "../../../../constants";
import theme from "../../../../constants/Theme";
const MultiValueWrapper = styled.div(({ theme }) => {
  return {
    border: `1px solid ${theme.palette.secondary.dark}`,
    borderRadius: theme.shape.standard.borderRadius,
    minHeight: "300px",
    flexGrow: 1,
  };
});

export default function MultiValueContainer({ title, tags, handleDelete }) {
  return (
    <MultiValueWrapper>
      <Box sx={{ m: 2 }} display="flex" alignItems={"center"}>
        <Box
          sx={{ display: { lg: "none", sm: "block", md: "none", xl: "none" } }}
        >
          <img src={BLUE_DOT_ICON} alt="dot" width="14px" height="14px" />
        </Box>
        <Typography
          variant="h4"
          sx={{
            [theme.breakpoints.down("md")]: {
              ml: 1,
              color: "secondary.main",
            },
            color: "primary.main",
          }}
        >
          {title}
        </Typography>
      </Box>

      <Divider sx={{ background: theme => theme.palette.secondary.dark }} />

      <Box direction="row" sx={{ mt: 3, px: 2, boxSizing: "border-box" }}>
        {tags.map((tag, index) => (
          <Chip
            key={index}
            label={<Typography variant="h5">{tag}</Typography>}
            variant="outlined"
            sx={theme => ({
              borderRadius: theme.shape.standard_small.borderRadius,
              m: 1,
              "& .MuiChip-deleteIcon": {
                color: "text.primary",
              },
            })}
            onDelete={() => handleDelete(tag)}
            deleteIcon={<ClearIcon />}
          />
        ))}
      </Box>
    </MultiValueWrapper>
  );
}
