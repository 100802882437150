import { Svg } from "./Svg";

export const Help3Icon = ({ className, fill = "#667085", width = 30, height = 26 }) => (
	<Svg className={className} width={width} height={height} viewBox="0 0 30 26" fill="none">
		<path
			d="M13.6665 11.6667H7.2665C5.77303 11.6667 5.02629 11.6667 4.45586 11.9573C3.9541 12.213 3.54615 12.6209 3.29049 13.1227C2.99984 13.6931 2.99984 14.4399 2.99984 15.9333V25M26.9998 25V5.26667C26.9998 3.77319 26.9998 3.02646 26.7092 2.45603C26.4535 1.95426 26.0456 1.54631 25.5438 1.29065C24.9734 1 24.2266 1 22.7332 1H17.9332C16.4397 1 15.693 1 15.1225 1.29065C14.6208 1.54631 14.2128 1.95426 13.9572 2.45603C13.6665 3.02646 13.6665 3.77319 13.6665 5.26667V25M28.3332 25H1.6665M18.3332 6.33333H22.3332M18.3332 11.6667H22.3332M18.3332 17H22.3332"
			stroke={fill}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</Svg>
);
