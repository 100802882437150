import React, { useContext, useState } from "react";
import { Typography, Box, Button, CircularProgress, Stack, useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setCommonDialogConfirmationData } from "../../../../modules/actions";
import CommonSlideDialog from "../../../../common/CommonSlideDialog";
import { PARTNER_QA_ICON } from "../../../../constants";
import UpdatedTextInputField from "../../../../common/UpdatedTextInputField";
import { ProfileContext } from "../..";
import services from "../../../../services";
import { toastContainer } from "../../../../utils/toast";
import messages from "../../../../constants/messages";

export default function EditPartnerQADialog() {
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();
	const { isOpen, title } = useSelector((store) => store?.commonDialogConfirmation);
	const { partnerQA, setPartnerQA } = useContext(ProfileContext);
	const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

	const handleClose = () => {
		dispatch(setCommonDialogConfirmationData({ text: "", title: "", isOpen: false }));
	};

	const handleAnswerChange = (index, answer) => {
		let QAs = [...partnerQA];

		QAs.forEach((qa, idx) => {
			if (idx === index) {
				let QAObj = {
					...qa.answers?.[0],
				};
				if (qa.answers?.lenth > 0) {
					QAObj.answerText = answer;

					qa.answers[0] = QAObj;
				} else {
					qa.answers = [{ answerText: answer, id: QAObj?.id }];
				}
			}
		});
		setPartnerQA(QAs);
	};

	const handleSave = async () => {
		try {
			let payload = [];
			partnerQA.forEach((qa, idx) => {
				let QAObj = {
					questionId: qa.question.id,
					answerText: qa.answers?.[0].answerText,
					answerId: qa.answers?.[0].id,
				};
				payload.push(QAObj);
			});
			setLoading(true);
			let response = await services.updateParterQAAPI(payload);
			if (response) {
				setLoading(false);
				toastContainer(messages.CHANGES_SAVE_SUCCESS);
				handleClose();
			}
		} catch (error) {
			setLoading(false);

			console.log(error, "Unable to successfully retrieve partner questions and answers");
		}
	};

	return (
		<CommonSlideDialog
			open={isOpen}
			slidingEffect={false}
			onClose={handleClose}
			title={
				<Box display="flex" flexDirection="column" p="0 0 8px">
					<Box component={"img"} src={PARTNER_QA_ICON} sx={{ height: "24px", width: "24px" }} />

					<Typography variant="Text/md/Semibold" sx={{ mt: 1.5 }}>
						{title}
					</Typography>
				</Box>
			}
			titleVariant="Text/md/Semibold"
			maxWidth="md"
			btnCloseStyle={{ margin: "0 24px 0 0" }}
			dialogAction={
				<Box display={"flex"} alignItems={"center"} gap={2}>
					{" "}
					<Button onClick={handleClose} variant="noOutlineNew">
						Cancel
					</Button>
					<Button onClick={handleSave} startIcon={loading && <CircularProgress size={"1.25rem"} sx={{ color: "inherit" }} />} variant="DS1">
						Save
					</Button>
				</Box>
			}
			PaperProps={{
				sx: (theme) => ({
					position: "fixed",
					top: 0,
					borderRadius: "30px",
					m: 0,
					p: 2,
					[theme.breakpoints.down("md")]: {
						px: 1,
						py: 1.5,
					},
					[theme.breakpoints.down("sm")]: {
						p: 0,
						m: -1,
					},
				}),
			}}
		>
			<Stack spacing={4} sx={{ px: 2 }}>
				{partnerQA?.map((partnerItem, idx) => {
					return (
						<Stack>
							<Typography sx={{ color: "#344054" }} component={"p"} variant="Text/sm/Semibold">
								{idx + 1}. {partnerItem?.question?.name}{" "}
							</Typography>
							<UpdatedTextInputField
								label={""}
								handleChange={(e) => {
									handleAnswerChange(idx, e.target.value);
								}}
								inputProps={{ maxLength: partnerItem?.question?.maxAnswerLength || 5000 }}
								handleBlur={() => {}}
								inputValue={partnerItem?.answers?.[0]?.answerText}
								placeholder={""}
								textFieldProps={{
									fullwidth: true,
									width: isSmallScreen ? "100%" : "600px",
									height: "48px",
								}}
							/>
						</Stack>
					);
				})}
			</Stack>
		</CommonSlideDialog>
	);
}
