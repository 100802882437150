import React, { useState, useEffect } from "react";
import {
  Typography,
  CardContent,
  Box,
  Card,
  Grid,
  Divider,
  Checkbox,
  useMediaQuery,
} from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import theme from "../../../../constants/Theme";
import { TableHeader, TableCell, TableRow } from "../PersonaWorkCard/styles";
import { getCurrentDate } from "../../../../utils/date";
import { PROFILE_PLACEHOLDER } from "../../../../constants/image";

const CompletedInterviews = ({
  completedInterviewList,
  HandleOnChange,
  isAscendingData,
}) => {
  const [personaItems, setPersonaItems] = useState(completedInterviewList);
  const [isAscending, setIsAscending] = useState(isAscendingData);
  const handelSort = () => {
    setIsAscending(!isAscending);
    sortPersonaItems();
  };
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("md"));

  const sortPersonaItems = () => {
    let data = [...personaItems];
    if (isAscending) {
      data.sort((a, b) => {
        return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
      });
    } else {
      data.sort((a, b) => {
        return a.name.toLowerCase() < b.name.toLowerCase() ? 1 : -1;
      });
    }

    setPersonaItems(data);
  };

  const handleCheckBoxChange = (e, item, personaItems) => {
    HandleOnChange(e, item, personaItems, isAscending);
  };

  return (
    <Box>
      <Box>
        <Grid
          item
          xs={12}
          md={12}
          xl={12}
          sx={{
            width: 1,
            mt: 2,
            display: { xs: "none", sm: "none", md: "block" },
          }}
        >
          <TableHeader>
            <TableCell onClick={handelSort}>
              <Typography variant="lable_large">Name</Typography>
              <Box sx={{ ml: 1 }}>
                {isAscending ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />}
              </Box>
            </TableCell>
            {!isSmallScreen && (
              <TableCell>
                <Typography variant="lable_large">Completed On</Typography>
              </TableCell>
            )}
          </TableHeader>
          <Divider
            sx={{
              mt: 2,
              background: `${theme.palette.secondary.outlineXLight}`,
            }}
          />
        </Grid>
        {personaItems.filter(itm => itm.selected)[0] ? (
          <Box sx={{ mt: 1 }}>
            <Typography variant="label_large">Selected</Typography>
          </Box>
        ) : (
          ""
        )}
        <Divider
          sx={{
            mt: 2,
            background: `${theme.palette.secondary.outlineXLight}`,
            display: { xs: "block", sm: "block", md: "none" },
          }}
        />
        <Grid item xs={12} md={12} sx={{ width: 1 }}>
          {personaItems?.map((item, index) => {
            if (item) {
              return (
                <Box key={index}>
                  <TableRow>
                    <Box
                      sx={{
                        py: 2,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Box>
                        <Checkbox
                          color="secondary"
                          checkedIcon={<CheckBoxOutlinedIcon />}
                          sx={{ color: theme.palette.secondary.black }}
                          onChange={e => {
                            handleCheckBoxChange(e, item.id, personaItems);
                          }}
                          checked={item.selected}
                        />
                      </Box>

                      <Box
                        component="img"
                        sx={{ ml: 3, borderRadius: "50%" }}
                        height="44px"
                        src={item?.avatar ? item?.avatar : PROFILE_PLACEHOLDER}
                      ></Box>
                      <Box sx={{ ml: 3 }}>
                        <Typography
                          variant="bodyLarge"
                          sx={{ color: "secondary.black" }}
                        >
                          {item.name?.trim() || item.email}
                        </Typography>
                      </Box>
                    </Box>
                    {!isSmallScreen && (
                      <Box>
                        <Typography variant="bodyMedium" sx={{ pl: 2 }}>
                          {getCurrentDate(new Date(item.scheduledDate))}
                        </Typography>
                      </Box>
                    )}
                  </TableRow>
                  {isSmallScreen && (
                    <Box
                      sx={{
                        pl: 8,
                        pb: 2,
                      }}
                    >
                      <Typography variant="lable_large">Completed:</Typography>
                      <Typography variant="bodyMedium" sx={{ pl: 2 }}>
                        {getCurrentDate(new Date(item.scheduledDate))}
                      </Typography>
                    </Box>
                  )}

                  <Divider
                    sx={{
                      background: `${theme.palette.secondary.outlineXLight}`,
                      display: { xs: "block", sm: "block", md: "none" },
                    }}
                  />
                  {personaItems.length - 1 !== index ? (
                    <Divider
                      sx={{
                        background: `${theme.palette.secondary.outlineXLight}`,
                        display: { xs: "none", sm: "none", md: "block" },
                      }}
                    />
                  ) : (
                    ""
                  )}
                </Box>
              );
            }
          })}
        </Grid>
      </Box>
    </Box>
  );
};

export default CompletedInterviews;
