import React, { useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Avatar, Box, Typography, Button, Chip, Alert, AlertTitle } from "@mui/material";
import { UPDATED_LOGO_ICON_NEW } from "../../../constants/image";
import { responseFormatter } from "./functions";
import BoltLoader from "./BoltLoader";
import JsxParser from "react-jsx-parser";

import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import BoltCompanyCompare from "./BoltCompanyCompare";

const BoltToolTip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: "white",
		maxWidth: 400,
		border: "1px solid white",
		borderRadius: "100px",
		boxShadow: "0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)",
		padding: "12px 16px 12px 16px",
	},
}));

function convertMarkdownToHTML(text) {
	// This regex finds all occurrences of **text**
	const boldRegex = /\*\*(.*?)\*\*/g;
	// Replace **text** with <span class="bold">text</span>
	return text.replace(boldRegex, "<strong>$1</strong>");
}

function transformOutput(output, metaPayload) {
	// Regular expression to match the tag format [tag_type:uuid:fallback_text]
	const tagRegex = /\[(\w+):([0-9a-fA-F\-]+):([^\]]+)\]/g;
	let transformedOutput = convertMarkdownToHTML(output);

	// Replace each tag found with the appropriate anchor tag or fallback
	transformedOutput = transformedOutput.replace(tagRegex, (match, tagType, uuid, fallbackText) => {
		if (tagType == "company_link") {
			// Find the corresponding entity tag in meta_payload by type and uuid
			const tagData = metaPayload.entity_tags["companies"]?.find((tag) => tag.id === uuid);

			if (tagData) {
				// Construct the anchor tag if tag data exists
				return tagData?.company?.id ? `<a href="/startup-profile/${tagData?.company?.id}">${tagData?.company?.name}</a>` : fallbackText;
			} else {
				// Use fallback text if no tag data is found
				return fallbackText;
			}
		} else if (tagType == "article_link") {
			// Find the corresponding entity tag in meta_payload by type and uuid
			const tagData = metaPayload.entity_tags["articles"]?.find((tag) => tag.id === uuid);

			if (tagData) {
				// Construct the anchor tag if tag data exists
				return tagData?.article?.id ? `<a href="/resource-center/articles/${tagData?.article?.id}">${tagData?.article?.name}</a>` : fallbackText;
			} else {
				// Use fallback text if no tag data is found
				return fallbackText;
			}
		} else if (tagType == "powerup_link") {
			// Find the corresponding entity tag in meta_payload by type and uuid
			const tagData = metaPayload.entity_tags["powerups"]?.find((tag) => tag.id === uuid);

			if (tagData) {
				// Construct the anchor tag if tag data exists
				let link_element = `<BoltToolTip placement={"bottom"} title={
					<Box sx={{display:"flex", alignItems: "center", justifyContent:"center"}}>
					  <Typography variant="Text/sm/Semibold" sx={{textAlign:"center", color: "#7B61FF"}}>${tagData?.powerup?.powerup_name} PowerUP</Typography>
					</Box>
				  } ><a href="/workspace/powerup/${tagData?.powerup?.id}">${tagData?.powerup?.replacement}</a></BoltToolTip>
				`;

				return tagData?.powerup?.id ? link_element : fallbackText;
			} else {
				// Use fallback text if no tag data is found
				return fallbackText;
			}
		} else {
			// Use fallback text if no tag data is found
			return fallbackText;
		}
	});

	// Final Clean-up ~ sometimes the regex functions will leave behind markdown tags, trailing brackets, etc...
	// This function removes them so the response is clean.
	transformedOutput.replace(/<[^>]*>|{[^}]*}|[#\*\[\]]/g, (match) => {
		// If the match is one of the unwanted characters and not part of a tag or block, replace it, otherwise keep it
		if (match === "#" || match === "*" || match === "[" || match === "]") {
			return "";
		} else {
			// It's part of a tag or block, so return the whole tag/block unchanged
			return match;
		}
	});

	return transformedOutput;
}

function append_badge_elements(metaPayload) {
	try {
		if (metaPayload && metaPayload?.entity_tags) {
			const badges = metaPayload?.entity_tags["ui_badge"];
			if (badges?.length > 0) {
				return (
					<span style={{ marginTop: "8px", display: "flex", gap: "4px", flexWrap: "wrap" }}>
						{badges?.map((badge, index) => (
							<Chip key={`badge_${badge?.badge?.name}_${index}`} label={badge?.badge?.name} size="small" />
						))}
					</span>
				);
			}
		}
	} catch (e) {
		console.log("Unable to process UI badges. Failed with:", e);
	}
}

function formatDateAndTime(timestamp) {
	const date = new Date(timestamp);
	const today = new Date();

	if (today.getFullYear() === date.getFullYear() && today.getMonth() === date.getMonth() && today.getDate() === date.getDate()) {
		return `Today at ${date.toLocaleTimeString([], { minute: "2-digit", hour: "2-digit" })}`;
	} else {
		return date.toLocaleDateString() + " at " + date.toLocaleTimeString([], { minute: "2-digit", hour: "2-digit" });
	}
}

const chatBubbleSX = (lorr) => {
	let jssObj = {
		marginBottom: "12px",
		lineHeight: "24px",
		flex: 1,
		padding: "10px 20px",
		borderRadius: "25px",

		...(lorr === "left"
			? {
					background: "#F2F4F7",
					borderRadius: "0px 8px 8px 8px",
			  }
			: {
					background: "#7B61FF",
					borderRadius: "8px 0px 8px 8px",
			  }),
	};
	return jssObj;
};

export const BoltTyping = () => (
	<Box sx={{ display: "flex" }}>
		<Box sx={{ background: "#F2F4F7", borderRadius: "0px 8px 8px 8px", mb: 2 }}>
			<Box
				sx={{
					height: "50px",
					width: "70px",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					gap: "4px",
					".dot": {
						borderRadius: "9999px",
						height: "0.5rem",
						width: "0.5rem",
						background: "rgba(148 163 184 / 1)",
						animation: "wave 1s infinite",
					},
					".dot:nth-child(1)": { animationDelay: "0.3333s" },
					".dot:nth-child(2)": { animationDelay: "0.6666s" },
					".dot:nth-child(3)": { animationDelay: "0.9999s" },
					"@keyframes wave": {
						"0%": { transform: "translateY(0px)", background: "#475467" },
						"50%": {
							transform: "translateY(-0.5rem)",
							//background: "rgba(148 163 184 / 0.8)",
						},
						"100%": {
							transform: "translateY(0px)",
							background: "#98A2B3",
						},
					},
				}}
			>
				<Box className="dot"></Box>
				<Box className="dot"></Box>
				<Box className="dot"></Box>
			</Box>
		</Box>
	</Box>
);

export const chatGroup = (groupObj, key, exampleMessage, feedback) => (
	<Box sx={{ display: "flex", flexDirection: "column", marginBottom: 2 }}>
		{groupObj?.userRequest && (
			<Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
				<Box sx={{ display: "flex", justifyContent: "space-between" }}>
					<Typography variant="Text/xs/Medium" sx={{ color: "#344054 !important" }}>
						You
					</Typography>
					<Typography variant="Text/xs/Medium" sx={{ color: "#475467 !important" }}>
						{formatDateAndTime(groupObj?.timestamp)}
					</Typography>
				</Box>
				<Box sx={{ ...chatBubbleSX("right") }}>
					<Typography
						variant="Text/sm/Regular"
						dangerouslySetInnerHTML={{ __html: responseFormatter(groupObj?.userRequest) }}
						sx={{ whiteSpace: "break-spaces", color: "white" }}
					></Typography>
				</Box>
			</Box>
		)}
		{groupObj?.boltResponse && (
			<Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
				<Box sx={{ display: "flex", justifyContent: "space-between" }}>
					<Typography variant="Text/xs/Medium" sx={{ color: "#344054 !important" }}>
						Bolt AI
					</Typography>
					<Typography variant="Text/xs/Medium" sx={{ color: "#475467 !important" }}>
						{formatDateAndTime(groupObj?.timestamp)}
					</Typography>
				</Box>
				<Box sx={{ ...chatBubbleSX("left") }}>
					<Typography
						variant="Text/sm/Regular"
						//dangerouslySetInnerHTML={{
						//	__html: transformOutput(
						//		groupObj?.boltResponse?.output ? groupObj?.boltResponse?.output : groupObj?.boltResponse,
						//		groupObj?.boltResponse?.meta_payload
						//	),
						//}}
						sx={{
							whiteSpace: "pre-line",
							"& .MuiChip-root": {
								m: "3px",
								backgroundColor: "#9AA0A6",
								padding: "4px 12px",
								borderRadius: "16px",

								"& .MuiChip-label": (theme) => ({
									...theme.typography["Text/xs/Regular"],
									color: "white",
								}),
							},
						}}
					>
						{/*{groupObj?.boltResponse?.output ? groupObj?.boltResponse?.output : groupObj?.boltResponse}*/}
						<JsxParser
							renderInWrapper={false}
							showWarnings={true}
							autoCloseVoidElements={true}
							components={{ Chip, Box, BoltToolTip, Typography }}
							jsx={transformOutput(
								groupObj?.boltResponse?.output ? groupObj?.boltResponse?.output : groupObj?.boltResponse,
								groupObj?.boltResponse?.meta_payload
							)}
						/>
						{append_badge_elements(groupObj?.boltResponse?.meta_payload)}
					</Typography>
				</Box>

				{groupObj?.boltResponse?.meta_payload?.entity_tags?.compare_companies &&
					groupObj?.boltResponse?.meta_payload?.entity_tags?.compare_companies?.length > 1 && (
						<BoltCompanyCompare companies={groupObj?.boltResponse?.meta_payload?.entity_tags?.compare_companies} />
					)}

				{groupObj?.action_type == "FINAL" && groupObj?.boltResponse?.run_id && typeof feedback == "function" && (
					<Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center", gap: 1, px: 1 }}>
						{(typeof groupObj?.boltResponse?.feedback_sent == "undefined" ||
							groupObj?.boltResponse?.feedback_sent == null ||
							groupObj?.boltResponse?.feedback_sent == 1) && (
							<Button
								variant="DS1"
								color="gray"
								iconButton={true}
								disabled={groupObj?.boltResponse?.feedback_sent == 1}
								onClick={() => {
									if (typeof feedback == "function") {
										feedback({
											run_id: groupObj?.boltResponse?.run_id,
											message_index: key,
											value: 1,
										});
									}
								}}
							>
								<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
									<path
										d="M5.83317 18.3333V9.16663M1.6665 10.8333V16.6666C1.6665 17.5871 2.4127 18.3333 3.33317 18.3333H14.5217C15.7556 18.3333 16.805 17.433 16.9926 16.2134L17.8901 10.3801C18.1231 8.86571 16.9514 7.49996 15.4191 7.49996H12.4998C12.0396 7.49996 11.6665 7.12686 11.6665 6.66663V3.72149C11.6665 2.58662 10.7465 1.66663 9.61164 1.66663C9.34095 1.66663 9.09565 1.82604 8.98572 2.0734L6.05312 8.67174C5.91937 8.97268 5.62093 9.16663 5.29161 9.16663H3.33317C2.4127 9.16663 1.6665 9.91282 1.6665 10.8333Z"
										stroke="#344054"
										stroke-width="1.66667"
										stroke-linecap="round"
										stroke-linejoin="round"
									/>
								</svg>
							</Button>
						)}
						{(typeof groupObj?.boltResponse?.feedback_sent == "undefined" ||
							groupObj?.boltResponse?.feedback_sent == null ||
							groupObj?.boltResponse?.feedback_sent == 0) && (
							<Button
								variant="DS1"
								color="gray"
								iconButton={true}
								disabled={groupObj?.boltResponse?.feedback_sent == 0}
								onClick={() => {
									if (typeof feedback == "function") {
										feedback({
											run_id: groupObj?.boltResponse?.run_id,
											message_index: key,
											value: 0,
										});
									}
								}}
							>
								<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
									<path
										d="M14.1665 1.66663V10.8333M18.3332 8.16663V4.33329C18.3332 3.39987 18.3332 2.93316 18.1516 2.57664C17.9918 2.26304 17.7368 2.00807 17.4232 1.84828C17.0667 1.66663 16.6 1.66663 15.6665 1.66663H6.76489C5.54699 1.66663 4.93804 1.66663 4.4462 1.88949C4.01271 2.08591 3.6443 2.40198 3.38425 2.80056C3.08919 3.25279 2.99659 3.85466 2.8114 5.0584L2.37551 7.89173C2.13125 9.47939 2.00912 10.2732 2.24472 10.8909C2.4515 11.433 2.84042 11.8864 3.34482 12.1732C3.91949 12.5 4.72266 12.5 6.32899 12.5H6.99988C7.46659 12.5 7.69995 12.5 7.87821 12.5908C8.03501 12.6707 8.16249 12.7982 8.24239 12.955C8.33321 13.1332 8.33321 13.3666 8.33321 13.8333V16.2784C8.33321 17.4133 9.25321 18.3333 10.3881 18.3333C10.6588 18.3333 10.9041 18.1739 11.014 17.9265L13.8146 11.6251C13.942 11.3385 14.0057 11.1952 14.1064 11.0901C14.1954 10.9972 14.3047 10.9262 14.4257 10.8826C14.5626 10.8333 14.7194 10.8333 15.033 10.8333H15.6665C16.6 10.8333 17.0667 10.8333 17.4232 10.6516C17.7368 10.4918 17.9918 10.2369 18.1516 9.92328C18.3332 9.56676 18.3332 9.10005 18.3332 8.16663Z"
										stroke="#344054"
										stroke-width="1.66667"
										stroke-linecap="round"
										stroke-linejoin="round"
									/>
								</svg>
							</Button>
						)}
					</Box>
				)}

				{groupObj?.boltResponse?.meta_payload?.entity_tags?.action_links && (
					<Box sx={{ mb: "8px" }}>
						<Box sx={{ display: "flex", flexDirection: "column", gap: 1, flex: 1 }}>
							{groupObj?.boltResponse?.meta_payload?.entity_tags?.action_links?.map((option, index) => {
								if (option?.link_type === "info") {
									return (
										<Alert
											key={index}
											severity="info"
											onClick={() => {
												if (option?.action_type === "route" && option?.link) {
													window.location.pathname = option?.link;
												}
											}}
										>
											<AlertTitle>
												<strong>{option?.title}</strong>
											</AlertTitle>
											{option?.description}
										</Alert>
									);
								} else if (option?.link_type === "button") {
									return (
										<Button
											key={index}
											variant="DS1"
											sx={{ width: "fit-content" }}
											small={true}
											onClick={() => {
												if (option?.action_type === "route" && option?.link) {
													window.location.pathname = option?.link;
												}
											}}
										>
											{option?.button_text}
										</Button>
									);
								} else {
									return null;
								}
							})}
						</Box>
					</Box>
				)}

				{groupObj?.boltResponse?.meta_payload?.suggested_questions && (
					<Box sx={{ mb: "8px" }}>
						<Typography variant="Text/sm/Regular">Here are some other things you can try:</Typography>
						<br />
						<br />
						<Box sx={{ display: "flex", flexDirection: "column", gap: 1, flex: 1 }}>
							{groupObj?.boltResponse?.meta_payload?.suggested_questions?.map((option, index) => (
								<Button
									key={`suggested_option_${groupObj?.timestamp}_${index}`}
									variant="DS1"
									color="black"
									sx={{ width: "fit-content" }}
									small={true}
									onClick={() => exampleMessage(option)}
								>
									{option}
								</Button>
							))}
						</Box>
					</Box>
				)}
			</Box>
		)}
		{groupObj?.systemMessage && (
			<Box sx={{ display: "flex", flexDirection: "column", gap: 1, flex: 1 }}>
				<Box>{groupObj?.systemMessage}</Box>
			</Box>
		)}
	</Box>
);

function BoltHistory({ history }) {
	useEffect(() => {
		console.log(history);
	}, [history]);

	return (
		<Accordion elevation={0}>
			<AccordionSummary expandIcon={<ExpandMoreIcon />}>
				<Typography variant="Text/sm/Semibold">Bolt Request History</Typography>
			</AccordionSummary>
			<AccordionDetails>
				<Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>{history?.toReversed()?.map((item, index) => chatGroup(item, index))}</Box>
			</AccordionDetails>
		</Accordion>
	);
}

export default BoltHistory;
