import React from "react";
import { Typography, Box } from "@mui/material";
import CustomCheckBox from "./CustomCheckbox";
import TreeItem from "@mui/lab/TreeItem";
import TreeView from "@mui/lab/TreeView";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";

const CheckBoxComponent = ({ companyData, lable, handleCheck, handleUncheck, type, filteredValue = [] }) => {
	const data = companyData[lable];
	return (
		<Box>
			<Box
				sx={{
					display: "flex",
					alignItems: "center",
					// mt: 1,
				}}
			>
				<Box display={"flex"} flexDirection={"column"} rowGap={1}>
					<Box>
						<Typography variant="Text/md/Semibold" sx={{ pt: "4px", display: "inline-block", lineHeight: "1.5rem" }}>
							{lable}{" "}
						</Typography>
					</Box>
					<Box sx={{ display: "grid" }}>
						{data.map((item, index) => {
							return (
								<Box key={index} sx={{ display: "flex", alignItems: "center", mt: 1 }}>
									<CustomCheckBox
										value={item}
										handleUncheck={handleUncheck}
										handleCheck={handleCheck}
										lable={lable}
										type={type}
										checkedValue={filteredValue.some((filterItem) => filterItem?.checkboxValue === item)}
									/>
									<Typography variant="Text/sm/Regular" sx={{ ml: 1 }}>
										{item}
									</Typography>
								</Box>
							);
						})}
					</Box>
				</Box>

				{/* <TreeView
					defaultCollapseIcon={
						<KeyboardArrowDownOutlinedIcon
							sx={{
								fontSize: "large",
								border: "2px solid",
								borderRadius: "50%",
							}}
						/>
					}
					defaultExpandIcon={
						<KeyboardArrowRightOutlinedIcon
							sx={{
								fontSize: "large",
								border: "2px solid",
								borderRadius: "50%",
							}}
						/>
					}
					sx={{ mt: 2 }}
					defaultExpanded={[`${lable}`]}
				>
					<TreeItem
						nodeId={`${lable}`}
						label={
							<Typography variant="Text/md/Semibold" sx={{ pt: "4px", display: "inline-block", lineHeight: "1.5rem" }}>
								{lable}{" "}
							</Typography>
						}
						sx={{
							"& .MuiTreeItem-content.Mui-selected": {
								alignItems: "center",
								background: (theme) => theme.palette.secondary.white,
								"&:hover": {
									background: (theme) => theme.palette.secondary.white,
								},
								"&:active": {
									background: (theme) => theme.palette.secondary.white,
								},
								"&:focused": {
									background: (theme) => theme.palette.secondary.white,
								},
								"&:selected": {
									background: (theme) => theme.palette.secondary.white,
								},
							},

							"& .MuiTreeItem-content.Mui-selected.Mui-focused": {
								background: (theme) => theme.palette.secondary.white,
								"&:hover": {
									background: (theme) => theme.palette.secondary.white,
								},
								"&:active": {
									background: (theme) => theme.palette.secondary.white,
								},
								"&:focused": {
									background: (theme) => theme.palette.secondary.white,
								},
								"&:selected": {
									background: (theme) => theme.palette.secondary.white,
								},
							},
						}}
					>
						<Box sx={{ display: "grid", ml: 2 }}>
							{data.map((item, index) => {
								return (
									<Box key={index} sx={{ display: "flex", alignItems: "center", mt: 1 }}>
										<CustomCheckBox
											value={item}
											handleUncheck={handleUncheck}
											handleCheck={handleCheck}
											lable={lable}
											type={type}
											checkedValue={filteredValue.some((filterItem) => filterItem?.checkboxValue === item)}
										/>
										<Typography variant="Text/sm/Regular" sx={{ ml: 1 }}>
											{item}
										</Typography>
									</Box>
								);
							})}
						</Box>
					</TreeItem>
				</TreeView> */}
			</Box>
		</Box>
	);
};

export default CheckBoxComponent;
