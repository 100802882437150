import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import { Box, Grid, Stack, Typography, Button, Checkbox } from "@mui/material";
import LoadingButton from "../../../common/Loadingbutton";
import ExperienceDisplayCard from "../../../common/ExperienceDisplayCard";
import UpdatedTextInputField from "../../../common/UpdatedTextInputField";
import CustomizedMonthPicker from "../../MentorProfile/MonthPicker";
import CustomizedYearPicker from "../../MentorProfile/YearPicker";
import { setCommonDialogConfirmationData } from "../../../modules/actions";
import { CHECK_BOX_ICON_V2, PURPLE_ADD_ICON, UNCHECKED_ICON_V2 } from "../../../constants";
import messages from "../../../constants/messages";
import ProfileCoPilot from "../../ProfileCoPilot";

const ExperienceSection = ({ context }) => {
	const {
		payLoad,
		setPayLoad,
		handleClose,
		addExperienceApiCall,
		deleteExperienceApiCall,
		updateExperienceApiCall,
		isExperienceLoading,
		setIsExperienceLoading,
		commonDialogConfirmationData,
		handleCloseConfirmation,
		experienceObject,
		setExperienceObject,
		setExpObjEmpty,
		handleAISave,
	} = useContext(context);

	const fieldsList = [{ Title: "title" }, { Company: "companyName" }, { Location: "location" }];
	const [experienceId, setExperienceId] = useState(0);
	const [isEdited, setIsEdited] = useState(false);
	const [currentlyWorking, setCurrentlyWorking] = React.useState(false);

	const dispatch = useDispatch();
	const [startYearError, setStartYearError] = useState("");
	const [endYearError, setEndYearError] = useState("");
	const [startMonthError, setStartMonthError] = useState("");
	const [endMonthError, setEndMonthError] = useState("");

	const clearAllErrors = () => {
		setStartYearError("");
		setStartMonthError("");
		setEndMonthError("");
		setEndYearError("");
	};

	const isValid = () => {
		if (startYearError || startMonthError) {
			return false;
		}
		if (!currentlyWorking) {
			if (endMonthError || endYearError) {
				return false;
			}
		}
		return true;
	};

	useEffect(() => {
		if (isEdited) {
			if (experienceObject.endMonth || experienceObject.endYear) {
				setCurrentlyWorking(false);
			} else {
				setCurrentlyWorking(true);
			}
		}
	}, [isEdited]);

	const handleCheckBoxChange = (event) => {
		setCurrentlyWorking(event.target.checked);
		setExperienceObject((prevState) => ({
			...prevState,
			endMonth: null,
			endYear: null,
		}));
		clearAllErrors();
	};
	const handleChange = (event) => {
		let key = event.target.id;
		let newValue = event.target.value;
		experienceObject[key] = newValue;
		setExperienceObject({ ...experienceObject });
	};

	const handleYearChange = (value, type) => {
		if (type === "startYear") {
			if (experienceObject?.endYear && value.getFullYear() > (experienceObject?.endYear ? new Date(experienceObject?.endYear)?.getFullYear() : null)) {
				setExperienceObject((prevState) => ({
					...prevState,
					startYear: value,
				}));
				setStartYearError(messages.START_DATE_ERROR);
			} else if (
				experienceObject?.endYear &&
				value.getFullYear() === (experienceObject?.endYear ? new Date(experienceObject?.endYear)?.getFullYear() : null)
			) {
				setExperienceObject((prevState) => ({
					...prevState,
					startYear: value,
				}));
				setStartYearError("");
				setEndYearError("");
				if (experienceObject?.startMonth) {
					handleMonthChange(experienceObject?.startMonth, "startMonth", true);
				}
			} else {
				setExperienceObject((prevState) => ({
					...prevState,
					startYear: value,
				}));
				clearAllErrors();
			}
		} else if (type === "endYear") {
			if (
				experienceObject?.startYear &&
				value.getFullYear() < (experienceObject?.startYear ? new Date(experienceObject?.startYear)?.getFullYear() : null)
			) {
				setExperienceObject((prevState) => ({
					...prevState,
					endYear: value,
				}));
				setEndYearError(messages.END_DATE_ERROR);
			} else if (
				experienceObject?.startYear &&
				value.getFullYear() === (experienceObject?.startYear ? new Date(experienceObject?.startYear)?.getFullYear() : null)
			) {
				setExperienceObject((prevState) => ({
					...prevState,
					endYear: value,
				}));
				setStartYearError("");
				setEndYearError("");
				if (experienceObject?.endMonth) {
					handleMonthChange(experienceObject?.endMonth, "endMonth", true);
				}
			} else {
				setExperienceObject((prevState) => ({
					...prevState,
					endYear: value,
				}));
				clearAllErrors();
			}
		}
	};

	const handleMonthChange = (value, type, sameYear = false) => {
		if (type === "startMonth") {
			const compareYear = sameYear
				? sameYear
				: (experienceObject?.startYear ? new Date(experienceObject?.startYear)?.getFullYear() : null) ===
				  (experienceObject?.endYear ? new Date(experienceObject?.endYear)?.getFullYear() : null);
			if (value.getMonth() > experienceObject?.endMonth?.getMonth() && compareYear) {
				setExperienceObject((prevState) => ({
					...prevState,
					startMonth: value,
				}));
				setStartMonthError(messages.START_DATE_ERROR);
			} else {
				setExperienceObject((prevState) => ({
					...prevState,
					startMonth: value,
				}));
				setStartMonthError("");
				setEndMonthError("");
			}
		} else {
			const compareYear = sameYear
				? sameYear
				: new Date(experienceObject?.startYear)?.getFullYear() === new Date(experienceObject?.endYear)?.getFullYear();
			if (value.getMonth() < experienceObject?.startMonth?.getMonth() && compareYear) {
				setExperienceObject((prevState) => ({
					...prevState,
					endMonth: value,
				}));
				setEndMonthError(messages.END_DATE_ERROR);
				// setEndYearError(messages.END_DATE_ERROR);
			} else {
				setExperienceObject((prevState) => ({
					...prevState,
					endMonth: value,
				}));
				setStartMonthError("");
				setEndMonthError("");
			}
		}
	};

	const handleEdit = (item) => {
		setExperienceObject({
			title: item.title,
			companyName: item.companyName,
			location: item.location,
			startMonth: item.startYear ? new Date(item.startYear, item.startMonth - 1) : null,
			startYear: item.startYear,
			endMonth: item.endYear ? new Date(item.endYear, item.endMonth - 1) : null,
			endYear: item.endYear,
		});
		if (item.endMonth || item.endYear) {
			setCurrentlyWorking(false);
		} else {
			setCurrentlyWorking(true);
		}
		setExperienceId(item.id);
		setIsEdited(true);
	};

	const handleDelete = (id) => {
		setIsEdited(false);

		dispatch(
			setCommonDialogConfirmationData({
				isOpen: true,
				title: "Delete Experience",
				subTitle: "Are you sure you want to delete this experience?",
				primaryButtonText: "Delete",
				secondaryButtonText: "Cancel",
				primaryButtonAction: () => deleteExperienceApiCall(id),
				secondaryButtonAction: () => handleCloseConfirmation(),
				primaryButtonVariant: "RedRounded",
				secondaryButtonVariant: "noOutlineNew",
				primaryButtonLoading: false,
			})
		);
	};

	const isFormEmpty = () => {
		if (
			experienceObject.title === "" ||
			experienceObject.companyName === "" ||
			experienceObject.location === "" ||
			experienceObject.startMonth === null ||
			experienceObject.startYear === null ||
			(!currentlyWorking && experienceObject.endMonth === null) ||
			(!currentlyWorking && experienceObject.endYear === null)
		) {
			return true;
		} else {
			return false;
		}
	};

	const handleSave = () => {
		setIsEdited(false);
		setCurrentlyWorking(false);
		setIsExperienceLoading(true);
		const experiencePayload = {
			title: experienceObject.title,
			companyName: experienceObject.companyName,
			location: experienceObject.location,
			startMonth: new Date(experienceObject.startMonth).getMonth() + 1,
			startYear: new Date(experienceObject.startYear).getFullYear(),
			endMonth: experienceObject.endMonth ? new Date(experienceObject.endMonth).getMonth() + 1 : null,
			endYear: experienceObject.endYear ? new Date(experienceObject.endYear).getFullYear() : null,
		};
		setPayLoad({ ...payLoad });
		if (isEdited) {
			updateExperienceApiCall(experiencePayload, experienceId);
		} else {
			addExperienceApiCall(experiencePayload);
		}
	};

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				gap: 3,
			}}
		>
			<Grid container spacing={3}>
				<Grid item xs={12} sm={12} md={6} lg={6} xl={6} order={{ xs: 2, md: 1 }}>
					<Stack direction={"column"} spacing={2}>
						<Typography sx={{ mb: 3 }} variant="poppinsSemiBold20">
							Experience
						</Typography>
						{payLoad?.experienceDetails?.length > 0 ? (
							payLoad?.experienceDetails?.map((object) => {
								return (
									<ExperienceDisplayCard
										name={object?.title}
										companyName={object?.companyName}
										item={object}
										handleEdit={handleEdit}
										handleDelete={handleDelete}
									/>
								);
							})
						) : (
							<Typography variant="Text/sm/Regular">No Data</Typography>
						)}
					</Stack>
				</Grid>
				<Grid item xs={12} sm={12} md={6} lg={6} xl={6} order={{ xs: 1, md: 2 }}>
					<Stack direction={"column"} spacing={2}>
						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								mb: 3,
							}}
						>
							<Typography variant="poppinsSemiBold20">{isEdited ? "Edit Experience" : "Add New Experience "} </Typography>
							{isEdited && (
								<Box
									sx={{
										display: "flex",
										alignItems: "center",
										ml: "auto",
									}}
									gap={1}
								>
									<Typography variant="poppinsSemiBold20">Add New</Typography>
									<Box
										onClick={() => {
											setExpObjEmpty();
											setIsEdited(false);
											setCurrentlyWorking(false);
										}}
										sx={{ cursor: "pointer" }}
										component={"img"}
										src={PURPLE_ADD_ICON}
										alt="add"
									/>
								</Box>
							)}
						</Box>
						<Stack direction={"column"} spacing={3}>
							{fieldsList?.map((item) => {
								return (
									<UpdatedTextInputField
										label={Object.keys(item)[0]}
										id={Object.values(item)[0]}
										handleChange={handleChange}
										inputValue={experienceObject[Object.values(item)[0]]}
										placeholder={Object.keys(item)[0]}
										textFieldProps={{
											fullwidth: true,
											width: "100%",
											height: "60px",
										}}
									/>
								);
							})}

							<Box width={"100%"} sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
								<Typography variant="count_title">Start Date</Typography>
								<Stack direction={"row"} spacing={3}>
									<CustomizedMonthPicker
										value={experienceObject.startMonth}
										label=""
										onChange={(newValue) => {
											handleMonthChange(newValue, "startMonth");
										}}
										slotIcon={CalendarTodayOutlinedIcon}
										fieldStyleProps={{
											borderRadius: "30px",
											height: "50px",
											fontWeight: "500",
											fontSize: "16px",
											lineHeight: "24px",
											padding: "5px 6px",
											background: "white",
											boxShadow: "0px 0px 0px 4px #F2F4F7, 0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
											border: "none",
											"& .MuiOutlinedInput-notchedOutline": { borderColor: "#DDDDDD", border: "none" },
											"&.MuiTextField-root": {
												width: "100%",
												border: (theme) =>
													!!startMonthError ? `5px solid ${theme.palette.secondary.errorTextFieldRed} !important` : "none",
											},
										}}
										error={startMonthError}
									/>
									<CustomizedYearPicker
										value={experienceObject.startYear}
										label=""
										onChange={(newValue) => {
											handleYearChange(newValue, "startYear");
										}}
										slotIcon={CalendarTodayOutlinedIcon}
										fieldStyleProps={{
											borderRadius: "30px",
											height: "50px",
											fontWeight: "500",
											fontSize: "16px",
											lineHeight: "24px",
											padding: "5px 6px",
											background: "white",
											boxShadow: "0px 0px 0px 4px #F2F4F7, 0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
											border: "none",
											"& .MuiOutlinedInput-notchedOutline": { borderColor: "#DDDDDD", border: "none" },
											"&.MuiTextField-root": {
												width: "100%",
												border: (theme) =>
													!!startYearError ? `5px solid ${theme.palette.secondary.errorTextFieldRed} !important` : "none",
											},
										}}
										error={startYearError}
									/>
								</Stack>
								{(startMonthError || startYearError) && (
									<Box>
										<Typography variant="Text/sm/Regular" sx={{ color: "error.main" }}>
											{startMonthError || startYearError}
										</Typography>
									</Box>
								)}
							</Box>
							<Box width={"100%"} sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
								<Typography variant="count_title">End Date</Typography>
								<Stack direction={"row"} spacing={3}>
									<CustomizedMonthPicker
										disabled={currentlyWorking}
										value={experienceObject.endMonth}
										label=""
										onChange={(newValue) => {
											handleMonthChange(newValue, "endMonth");
										}}
										slotIcon={CalendarTodayOutlinedIcon}
										fieldStyleProps={{
											borderRadius: "30px",
											height: "50px",
											fontWeight: "500",
											fontSize: "16px",
											lineHeight: "24px",
											padding: "5px 6px",
											background: "white",
											boxShadow: "0px 0px 0px 4px #F2F4F7, 0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
											border: "none",
											"& .MuiOutlinedInput-notchedOutline": { borderColor: "#DDDDDD", border: "none" },
											"&.MuiTextField-root": {
												width: "100%",
												border: (theme) =>
													!!endMonthError ? `5px solid ${theme.palette.secondary.errorTextFieldRed} !important` : "none",
											},
										}}
										error={endMonthError}
									/>
									<CustomizedYearPicker
										disabled={currentlyWorking}
										value={experienceObject.endYear}
										label=""
										onChange={(newValue) => {
											handleYearChange(newValue, "endYear");
										}}
										slotIcon={CalendarTodayOutlinedIcon}
										fieldStyleProps={{
											borderRadius: "30px",
											height: "50px",
											fontWeight: "500",
											fontSize: "16px",
											lineHeight: "24px",
											padding: "5px 6px",
											background: "white",
											boxShadow: "0px 0px 0px 4px #F2F4F7, 0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
											border: "none",
											"& .MuiOutlinedInput-notchedOutline": { borderColor: "#DDDDDD", border: "none" },
											"&.MuiTextField-root": {
												width: "100%",
												border: (theme) =>
													!!endYearError ? `5px solid ${theme.palette.secondary.errorTextFieldRed} !important` : "none",
											},
										}}
										error={endYearError}
									/>
								</Stack>
								{(endMonthError || endYearError) && (
									<Box>
										<Typography variant="Text/sm/Regular" sx={{ color: "error.main" }}>
											{endMonthError || endYearError}
										</Typography>
									</Box>
								)}
							</Box>
						</Stack>
						<Box
							sx={{
								display: "flex",
								gap: 1,
								justifyContent: "start",
								alignItems: "center",
							}}
						>
							<Checkbox
								icon={<Box component={"img"} sx={{ height: "20px", width: "20px" }} src={UNCHECKED_ICON_V2} alt="checkbox" />}
								checkedIcon={<Box component={"img"} sx={{ height: "20px", width: "20px" }} src={CHECK_BOX_ICON_V2} alt="checkbox" />}
								sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
								onChange={handleCheckBoxChange}
								checked={currentlyWorking}
							/>
							<Typography variant="count_title">I am currently working this role</Typography>
						</Box>
						<Box
							sx={{
								display: "flex",
								gap: 2,
								justifyContent: "end",
								alignItems: "center",
							}}
						>
							<Button
								onClick={handleClose}
								variant="noOutlineNew"
								sx={{
									height: "40px",
									m: 0,
									width: "fit-content",
									fontSize: "18px",
									lineHeight: "28px",
									fontFamily: "PoppinsSemiBold",
								}}
							>
								Cancel
							</Button>
							<LoadingButton
								loaderColor={"#7B61FF"}
								loadingStyles={{
									border: "4px solid",
									backgroundColor: "white",
									borderColor: "#7B61FF",
									height: "60px",
									px: 4,
								}}
								disabled={isFormEmpty() || !isValid()}
								loading={isExperienceLoading}
								onClick={!isExperienceLoading ? handleSave : () => {}}
								variant="DS1"
							>
								Save
							</LoadingButton>
							{/* <ProfileCoPilot
								handleSave={handleSave}
								handleAISave={handleExpAISave}
								saveLoading={isExperienceLoading}
								saveDisabled={isFormEmpty() || !isValid()}
								handleCloseAI={handleClose}
							/> */}
						</Box>
					</Stack>
				</Grid>
			</Grid>
		</Box>
	);
};

export default ExperienceSection;
