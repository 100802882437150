import React, { useState, useEffect } from "react";
import CropSquareOutlinedIcon from "@mui/icons-material/CropSquareOutlined";
import SquareRoundedIcon from "@mui/icons-material/SquareRounded";
import { Box } from "@mui/material";
import { getTeamMemberFilterKeys } from "../../../../../utils/commonFunctions";
import { CHECK_SQUARE_BROKEN_ICON, CHECK_SQUARE_ICON } from "../../../../../constants";

const CustomCheckBox = ({ value, handleCheck, handleUncheck, type, checkedValue, filteredValue = [] }) => {
	const [checked, setChecked] = useState(false);

	const checkboxValue = value;

	useEffect(() => {
		setChecked(checkedValue ? checkedValue : false);
	}, [checkedValue]);

	const handleChecked = (event) => {
		if (checked == false) {
			setChecked(true);
			let obj = {
				type: type,
				key: getTeamMemberFilterKeys(checkboxValue),
				checkboxValue,
			};
			handleCheck?.(obj);
		} else {
			setChecked(false);
			handleUncheck(checkboxValue, type);
		}
	};

	return (
		<Box onClick={handleChecked} sx={{ display: "flex" }}>
			{checked ? (
				<Box component="img" src={CHECK_SQUARE_BROKEN_ICON} />
			) : (
				// <SquareRoundedIcon sx={{ fontSize: "20px", color: (theme) => theme.palette.primary.primaryPurple }} />
				<Box component="img" src={CHECK_SQUARE_ICON} />
				// <CropSquareOutlinedIcon sx={{ fontSize: "20px" }} />
			)}
		</Box>
	);
};

export default CustomCheckBox;
