import React, { useState, useEffect, memo } from "react";
import CommonSlideDialog from "../../../common/CommonSlideDialog";
import { USERS_PURPLE_OUTLINE_ICON } from "../../../constants";
import { useDispatch, useSelector } from "react-redux";
import { setNetworkingDashboardData } from "../../../modules/actions/networkingActions";
import { MeetingList } from "../../../containers/Meetings/Bookings/BookedMeetings";
import { Box, Typography } from "@mui/material";
import { handleMixPanel } from "../../../utils/mixPanelEventHandling";

function MeetingDialog() {
	const dispatch = useDispatch();
	const openMeetingDialog = useSelector((state) => state.networking?.openMeetingDialog);
	const [showMeetings, setShowMeetings] = useState(false);
	const { isPremiumPlanAccess, userType } = useSelector((store) => store?.auth);

	useEffect(() => {
		if (openMeetingDialog) {
			handleMixPanel("Meetings Page Viewed", { subscription_status: isPremiumPlanAccess ? "Subscribed" : "Not Subscribed", user_type: userType });
			setTimeout(() => {
				setShowMeetings(true);
			}, 600);
		} else {
			setTimeout(() => {
				setShowMeetings(false);
			}, 600);
		}
	}, [openMeetingDialog]);

	const handleClose = () => {
		dispatch(
			setNetworkingDashboardData({
				openMeetingDialog: false,
			})
		);
	};

	useEffect(() => {
		return () => {
			handleClose();
		};
	}, []);

	return (
		<CommonSlideDialog
			open={openMeetingDialog}
			disableBackDropClick
			onClose={handleClose}
			title={
				<Box sx={{ display: "flex", flexDirection: "column", rowGap: 1 }}>
					<Box component={"img"} src={USERS_PURPLE_OUTLINE_ICON} alt="dashbaord" sx={{ width: "30px", height: "30px" }} />
					<Typography variant="Text/md/Semibold">Meetings</Typography>
				</Box>
			}
			maxWidth="md"
			titleVariant="poppinsSemiBold30"
			titleStyle={{ px: { xs: 2, md: 5 }, pt: { xs: 2, md: 5 }, pb: 2, alignItems: "baseline" }}
			contentStyle={{ p: { xs: 2, md: 5 } }}
			fullWidth
			PaperProps={{
				sx: {
					position: "fixed",
					bottom: 0,
					right: 0,
					top: 0,
					m: 0,
					borderRadius: "24px 0px 0px 24px",
					boxShadow: "0px 10px 25px 0px rgba(0, 0, 0, 0.05)",
					minHeight: 1,
					width: "100%",
				},
			}}
		>
			{showMeetings && <MeetingList />}
		</CommonSlideDialog>
	);
}

export default memo(MeetingDialog);
