import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { updateCurrentConversation } from "../containers/Messaging/store/action-creators";
import { pushNotificationList, setNotificationData } from "../modules/actions/notificationActions";
import { store } from "../modules/index";
import services from "../services";
import { hasTimePassed } from "./epochTime";
import { ProcessPayload } from "./notificationPayloadProcess";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { SnackbarProvider, useSnackbar } from "notistack";

import { makeStyles } from "@mui/styles";
import DS1Notification from "../components/NotificationCenter/DS1Notification";

const InitClient = (authDataKey) => {
	const ws = new WebSocket(`${process.env.REACT_APP_BOLT_WEBSOCKET_BASE}?access_token=${encodeURI(`Bearer ${authDataKey}`)}`);

	ws.addEventListener("open", (event) => {
		console.log("Connection Opened");
		PullNotifications();
		setInterval(() => {
			ws.send("ping");
		}, 540000); // Ping to keep socket active. 10min the connection will close, so ping at 9min intervals.
	});

	ws.addEventListener("close", (event) => {
		// connection was closed handle state
		console.log("WebSocket Closed");
		store.dispatch(setNotificationData({ websocketClient: null }));
	});
	// Listen for messages

	ws.addEventListener("message", (event) => {
		ProcessPayload(event);
		PullNotifications();
	});

	return ws;
};

export function NotificationServiceClose() {
	const wsClient = store?.getState()?.notifications?.websocketClient;
	if (wsClient) {
		wsClient.close();
	} else {
		console.log("Connection with SOSNS does not currently exist. Nothing to close.");
	}
}

function NotificationHandling() {
	const authDataKey = useSelector((state) => state?.auth?.idToken);
	const [notifications, setNotifications] = useState([]);
	const { enqueueSnackbar } = useSnackbar();
	const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocket(
		`${process.env.REACT_APP_BOLT_WEBSOCKET_BASE}?access_token=${encodeURI(`Bearer ${authDataKey}`)}`,
		{
			share: true, // Enable the share option to use a single WebSocket connection
			shouldReconnect: (closeEvent) => true, // Function to determine whether to attempt reconnection
			retryOnError: true, // Enable automatic retry on connection errors
			reconnectInterval: 5000, // Interval (in milliseconds) between reconnection attempts
			onOpen: () => PullNotifications(),
		}
	);

	const connectionStatus = {
		[ReadyState.CONNECTING]: "Connecting",
		[ReadyState.OPEN]: "Open",
		[ReadyState.CLOSING]: "Closing",
		[ReadyState.CLOSED]: "Closed",
		[ReadyState.UNINSTANTIATED]: "Uninstantiated",
	}[readyState];

	// Function to handle incoming messages with a 'notificationPayload'
	const handleNotification = (notification) => {
		const message = notification?.notificationPayload;

		let severity = "info";
		if (message?.job_status && message?.job_status == "COMPLETED") severity = "success";
		else if (message?.job_status && message?.job_status == "FAILED") severity = "error";

		enqueueSnackbar({
			anchorOrigin: { horizontal: "center", vertical: "top" },
			...(severity == "success"
				? {
						persist: true,
				  }
				: {
						autoHideDuration: 3000,
				  }),
			variant: "ds1Notification",
			severity: severity,
			message: {
				title: message?.title,
				body: message?.message,
			},
			actions: {
				dismissible: (severity == "success" || severity == "error") && !message?.link ? true : false,
				deeplink: message?.link
					? () => {
							let link = HandleNotificationNavigation(notification);
							// If something was returned, this means the link was determined to be internal.
							// Else, the link was determined to be external and opened in a new tab.
							if (link) {
								if (link !== window.location.pathname)
									// Prevent routing on the current page
									window.location.href = link;
							}
					  }
					: null,
			},
		});
	};

	useEffect(() => {
		// Check if there's a last JSON message and it has a 'notificationPayload'
		if (lastJsonMessage && lastJsonMessage.notificationPayload) {
			if (lastJsonMessage?.notificationPayload?.type && lastJsonMessage?.notificationPayload?.type == "pitch-deck-review")
				handleNotification(lastJsonMessage);
		}
	}, [lastJsonMessage]);

	return <React.Fragment></React.Fragment>;
}

export function WebSocketControl() {
	const useStyles = makeStyles({
		root: {
			position: "relative",
			"& > div": {
				position: "absolute",
				zIndex: 2,
			},
			"& > div:not(:last-child)": {
				transform: "translateY(-17px) scale(.95)",
				zIndex: 1,
			},
		},
	});

	const classes = useStyles();

	return (
		<SnackbarProvider
			maxSnack={2}
			Components={{
				ds1Notification: DS1Notification,
			}}
			classes={{
				containerRoot: classes.root,
			}}
		>
			<NotificationHandling />
		</SnackbarProvider>
	);
}

export async function PullNotifications() {
	// Disable pull notification
	try {
		store.dispatch(setNotificationData({ notificationLoading: true }));
		await services.getNotifications({ lastIndex: 0, perPage: 0 }).then((resp) => {
			try {
				const filtered = resp?.data?.filter((obj) => {
					if (obj?.expireAt) {
						return !hasTimePassed(obj?.expireAt);
					} else {
						return true;
					}
				});
				store.dispatch(pushNotificationList(filtered));
			} catch (e) {
				console.log("Error when attempting to filter out expired notifications pulled from DB.", e);
			}
		});
	} catch (e) {
		console.log("Error pulling notifications.", e);
		store.dispatch(setNotificationData({ notificationLoading: false }));
	}
}

export async function DeleteAllNotifications(callback) {
	await services.getNotifications({ lastIndex: 0, perPage: 0 }).then((resp) => {
		try {
			const idMap = resp?.data?.map((item) => {
				return item?.NOTIFICATION_ID;
			});
			DeleteNotifications(idMap, callback);
		} catch (e) {
			console.log("Error when attempting to filter out expired notifications pulled from DB.", e);
		}
	});
}

export async function DeleteNotifications(delete_array, callback) {
	try {
		if (!delete_array) throw Error("Missing array of notification IDs, Ex: ['1234','5678','9101']");
		if (!Array.isArray(delete_array)) throw Error("Passed in object is not an array. Even single IDs need to be in array form. Ex: ['1234']");

		// This block pre-updates the local state to reflect the expected action.
		// The API will be called in the background and the state will match DB state on complete.
		let currentList = store?.getState()?.notifications?.notificationList;
		currentList.forEach((object, index) => {
			if (delete_array.includes(object.NOTIFICATION_ID)) {
				currentList.splice(index, 1);
			}
		});
		store.dispatch(setNotificationData({ notificationList: currentList }));

		// This calls the service to update the DB value.
		await services.deleteNotifications({ delete_array });
		if (typeof callback == "function") callback();
	} catch (e) {
		console.log("Error pulling notifications.", e);
		store.dispatch(setNotificationData({ notificationLoading: false }));
	}
}

export async function MarkReadNotifications(read_array) {
	try {
		if (!read_array) throw Error("Missing array of notification IDs, Ex: ['1234','5678','9101']");
		if (!Array.isArray(read_array)) throw Error("Passed in object is not an array. Even single IDs need to be in array form. Ex: ['1234']");

		// This block pre-updates the local state to reflect the expected action.
		// The API will be called in the background and the state will match DB state on complete.
		let currentList = store?.getState()?.notifications?.notificationList;
		const updatedArray = currentList.map((b) => {
			const a = read_array.find((a) => a.NOTIFICATION_ID === b.NOTIFICATION_ID);
			if (a) {
				return { ...b, read: a.read };
			}
			return b;
		});
		store.dispatch(setNotificationData({ notificationList: updatedArray }));

		// This calls the service to update the DB value.
		await services.markReadNotifications({ read_array });
	} catch (e) {
		console.log("Error pulling notifications.", e);
		store.dispatch(setNotificationData({ notificationLoading: false }));
	}
}

export function HandleNotificationNavigation(notification) {
	if (!notification) return;

	const type = notification?.notificationPayload?.type;

	switch (type) {
		case "conversation": {
			// set current conversation and route to conversations route
			// expect to return a link to a conversation
			return processConversation(notification);
		}
		default: {
			// if the link matches an external form, then open a new tab and navigate
			// if the link is an internal form, route in app within same tab
			return processLink(notification);
		}
	}
}

function processConversation(notification) {
	const conversationId = notification?.notificationPayload?.conversationId;
	if (!conversationId) return;

	try {
		store.dispatch(updateCurrentConversation(conversationId));
		return "/messages";
	} catch (e) {
		console.log(e, "Cannot link to conversation.");
	}
}

function processLink(notification) {
	const link = notification?.notificationPayload?.link;
	if (!link) return;

	try {
		if (link.includes("http://") || link.includes("https://")) {
			// potential external link
			if (link.includes("app.startupos.com") || link.includes("app.dev.startupos.com") || link.includes("app.stage.startupos.com")) {
				// Internal link formatted as an external link.
				// Take the "path" of the link and route internally.
				let processLink = new URL(link);
				processLink = processLink.pathname;
				return processLink;
			} else {
				// assume external
				// open link in a new tab
				window.open(link, "_blank");
				return;
			}
		} else if (link && link.length > 0) {
			// assume internal link ~ ex: /my-route/1234
			return link;
		} else {
			// no link attached
			return;
		}
	} catch (e) {
		console.log(e, "Unable to process notification link.");
	}
}
