import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Box, Button, ButtonGroup, Typography } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

const SimpleDropDown = ({ handleAction }) => {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [label, setLabel] = React.useState("Resend");

	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const handleMenuItemClick = (type) => {
		handleAction(type);
		setLabel(type);
		setAnchorEl(null);
	};
	return (
		<Box>
			<Button
				id="basic-button"
				aria-controls={open ? "basic-menu" : undefined}
				aria-haspopup="true"
				aria-expanded={open ? "true" : undefined}
				variant="PurpleRoundedOutline"
				onClick={handleClick}
				sx={{ height: "40px", fontFamily: (theme) => theme.typography["Text/sm/Semibold"], color: "#7B61FF" }}
				ref={anchorEl}
				endIcon={open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
			>
				{label}
			</Button>
			{/* <Button
				variant="PurpleRoundedOutline"
				ref={anchorEl}
				aria-label="split button"
				aria-controls={open ? "split-button-menu" : undefined}
				aria-expanded={open ? "true" : undefined}
				onClick={handleClick}
			>
				<Button
					id="basic-button"
					aria-controls={open ? "basic-menu" : undefined}
					aria-haspopup="true"
					aria-expanded={open ? "true" : undefined}
					variant="PurpleRoundedOutline"
				>
					{label}
				</Button>

				<Button
					variant={"PurpleRoundedOutline"}
					size="small"
					aria-controls={open ? "split-button-menu" : undefined}
					aria-expanded={open ? "true" : undefined}
					aria-label="select merge strategy"
					aria-haspopup="menu"
					sx={{
						width: "max-content",
					}}
				>
					{open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
				</Button>
			</Button> */}

			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					"aria-labelledby": "basic-button",
				}}
				PaperProps={{
					sx: {
						background: "white",
						boxShadow: "0px 10px 25px 0px rgba(0, 0, 0, 0.05)",
					},
				}}
				transformOrigin={{ vertical: 140, horizontal: 0 }}
			>
				<Box>
					<MenuItem onClick={() => handleMenuItemClick("Resend")} sx={{ pr: 13 }}>
						<Typography variant="Text/sm/Regular">Resend</Typography>
					</MenuItem>
					<MenuItem onClick={() => handleMenuItemClick("Delete")} sx={{ pr: 13 }}>
						<Typography variant="Text/sm/Regular">Delete</Typography>
					</MenuItem>
				</Box>
			</Menu>
		</Box>
	);
};

export default SimpleDropDown;
