import * as actionTypes from "../../actions/actionTypes";
import { updateObject } from "../../../utils/updateObject";

const initialState = {
	loading: false,
	applyToPitchOpen: false,
	investorImpressionsData: {},
	powerUpPacks: [],
	startUpCardData: {},
	ScoreReportObj: {},
	scoreTrendRangeSelection: "1M",
	scoreTrendRangeLoading: false,
	advertsDetails: {},
	growthRateData: [],
	currentStreaksData: {},
};

const founderDashboard = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SET_FOUNDER_DASHBOARD_DATA: {
			return action.payload === null ? initialState : updateObject(state, action.payload);
		}
		default:
			return state;
	}
};
export default founderDashboard;
