import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Box, Divider, List, Stack, Typography } from "@mui/material";
import { Helmet } from "react-helmet";

import SavedSearches from "./SavedSearches";
import {
	GAUGE_LIGHT_ICON,
	PAGE_DASHBOARD_ICON,
	UPDATED_ALL_STARTUP_ICON,
	UPDATED_PORTFOLIO_ICON,
	UPDATED_WATCHLIST_ICON,
	VIDEO_ICON_V2,
} from "../../../../constants";
import { handleMixPanel } from "../../../../utils/mixPanelEventHandling";
import { useSelector } from "react-redux";

export default function DashboardNavabar({ activeKey }) {
	const history = useHistory();
	const { isPremiumPlanAccess, userType, isInvestorLocked, isAdmin } = useSelector((store) => store?.auth);

	useEffect(() => {
		handleMixPanel("Opportunity Dashboard Viewed", { subscription_status: isPremiumPlanAccess ? "Subscribed" : "Not Subscribed", user_type: userType });
	}, []);

	const handleTab = (link, title) => {
		if (isInvestorLocked && !isAdmin) {
			if (title === "Opportunity Dashboard: Watchlist Clicked") {
				history.push(link);
				handleMixPanel(title);
			}
		} else {
			handleMixPanel(title);

			history.push(link);
		}
	};

	return (
		<>
			<Helmet>
				<meta charSet="utf-8" />
				<title>StartupOS - Opportunity Dashboard</title>
			</Helmet>

			<Box>
				<List
					sx={(theme) => ({
						display: "flex",
						alignItems: "flex-start",
						flexDirection: "column",
						gap: 2,
						// pt: 0,
						// mb: 1.5,
						"@media (min-width: 1450px)": {
							flexDirection: "row",
						},
					})}
				>
					{/* <Box sx={{ display: "flex", alignItems: "center" }} gap={1}>
						<Box component={"img"} src={PAGE_DASHBOARD_ICON} alt="dashbaord" sx={{ width: "30px", height: "30px" }} />
						<Typography variant="growth_card_subtitle" color="secondary.black">
							Opportunity Dashboard
						</Typography>
						<Box component={"img"} src={VIDEO_ICON_V2} alt="dashbaord" sx={{ width: "30px", height: "30px" }} />
					</Box> */}
					<Divider
						orientation="vertical"
						sx={(theme) => ({
							height: "30px",
							display: "none",
							"@media (min-width: 1450px)": {
								display: "block",
							},
						})}
					/>
					<Box
						sx={(theme) => ({
							display: "flex",
							cursor: "pointer",
							[theme.breakpoints.up("sm")]: {
								gap: 2,
								flexDirection: "row",
								alignItems: "center",
							},
							"@media (max-width: 790px)": {
								gap: 2,
								flexDirection: "column",
								alignItems: "flex-start",
							},

							gap: 2,
						})}
					>
						<Box
							sx={{
								background: activeKey === 1 ? "rgba(0, 0, 0, 1)" : "rgba(242, 244, 247, 1)",
								borderRadius: "100px",
							}}
							display={"block"}
							onClick={() => handleTab("/investor/dashboard/startups", "Opportunity Dashboard: Startup Clicked")}
						>
							<Stack>
								<Box
									display={"flex"}
									alignItems={"center"}
									gap={1.5}
									sx={{
										opacity: isInvestorLocked && !isAdmin ? "0.6" : "1",
										p: "12px 20px 12px 20px",
									}}
								>
									{/* <Box component={"img"} src={UPDATED_ALL_STARTUP_ICON} alt="startups" sx={{ width: "23px", height: "23px" }} /> */}
									<Typography variant="Text/sm/Semibold" sx={{ color: activeKey === 1 ? "white" : "black" }}>
										All Startups
									</Typography>
								</Box>
								{/* <Box
									sx={{
										background: activeKey === 1 ? (theme) => theme.palette.primary.primaryPurple : "transparent",
										height: "4px",
										mt: 1,
										borderRadius: (theme) => theme.shape.standard_small,
									}}
								/> */}
							</Stack>
						</Box>
						<Box
							display={"block"}
							sx={{
								background: activeKey === 2 ? "rgba(0, 0, 0, 1)" : "rgba(242, 244, 247, 1)",
								borderRadius: "100px",
							}}
							onClick={() => handleTab("/investor/dashboard/my-portfolio", "Opportunity Dashboard: My Portfolio Clicked")}
							selected={true}
						>
							<Stack>
								<Box
									display={"flex"}
									alignItems={"center"}
									gap={1.5}
									sx={{
										opacity: isInvestorLocked && !isAdmin ? "0.6" : "1",
										p: "12px 20px 12px 20px",
									}}
								>
									{/* <Box component={"img"} src={UPDATED_PORTFOLIO_ICON} alt="portfolio" sx={{ width: "25px", height: "25px" }} /> */}
									<Typography variant="Text/sm/Semibold" sx={{ color: activeKey === 2 ? "white" : "black" }}>
										My Portfolio
									</Typography>{" "}
								</Box>
								{/* <Box
									sx={{
										background: activeKey === 2 ? (theme) => theme.palette.primary.primaryPurple : "none",
										height: "4px",
										mt: 1,
										borderRadius: (theme) => theme.shape.standard_small,
									}}
								/> */}
							</Stack>
						</Box>
						<Box
							display={"block"}
							onClick={() => handleTab("/investor/dashboard/watchlist", "Opportunity Dashboard: Watchlist Clicked")}
							selected={true}
							sx={{
								background: activeKey === 3 ? "rgba(0, 0, 0, 1)" : "rgba(242, 244, 247, 1)",
								borderRadius: "100px",
							}}
						>
							<Stack>
								<Box
									display={"flex"}
									sx={{
										opacity: isInvestorLocked && !isAdmin ? "0.6" : "1",
										p: "12px 20px 12px 20px",
									}}
									alignItems={"center"}
									gap={1.5}
								>
									{/* <Box component={"img"} src={UPDATED_WATCHLIST_ICON} alt="watchlist" sx={{ width: "25px", height: "25px" }} /> */}
									<Typography variant="Text/sm/Semibold" sx={{ color: activeKey === 3 ? "white" : "black" }}>
										Watchlist
									</Typography>{" "}
								</Box>
								{/* <Box
									sx={{
										background: activeKey === 3 ? (theme) => theme.palette.primary.primaryPurple : "transparent",
										height: "4px",
										mt: 1,
										borderRadius: (theme) => theme.shape.standard_small,
									}}
								/> */}
							</Stack>
						</Box>
						<SavedSearches />
					</Box>
				</List>
			</Box>
		</>
	);
}
