import { useState, useEffect } from "react";
import { IconButton, Typography, Drawer, Avatar, Box, Divider } from "@mui/material";
import { Menu, Person } from "@mui/icons-material";
import { UPDATED_INVESTOR_ICON, UPDATED_LOGO_ICON_NEW } from "../../../../constants";
import { useSelector, useDispatch } from "react-redux";
import services from "../../../../services";
import {
	ADMIN_SIDEBAR_ITEMS,
	GLOBAL_NAVIGATION_ITEMS,
	INVESTOR_LEFT_SIDEBAR_ITEMS,
	MENTOR_LEFT_SIDEBAR_ITEMS,
	PARTNER_LEFT_SIDEBAR_ITEMS,
	STARTUP_LEFT_SIDEBAR_ITEMS,
} from "../../../../constants/workspace-sidebar";
import ListItem from "./ListItem";
import { CSSTransition } from "react-transition-group";
import { matchPath, useHistory, useLocation, useParams } from "react-router-dom";
import { USER_TYPES } from "../../../../utils/userTypes";
import { getRoleValue } from "../../../../utils/commonFunctions";
import ROUTES_PATH from "../../../../constants/routes";
import { setNetworkingDashboardData } from "../../../../modules/actions/networkingActions";

const MobileNav = () => {
	const [active, setActive] = useState();
	const [open, setOpen] = useState(false);
	const history = useHistory();
	const [leftSidebarItems, setLeftSidebarItems] = useState(null);
	const [globalNavItems, setGlobalSidebarItems] = useState(null);
	const userPicture = useSelector((store) => store?.auth?.userData?.picture);
	const currentSelectedNavItem = useSelector((store) => store?.auth?.currentSelectedNavItem);
	const [approvalStatus, setApprovalStatus] = useState();
	const userType = useSelector((store) => store?.auth?.userType);
	const isAdmin = useSelector((store) => store?.auth?.isAdmin);
	const dispatch = useDispatch();

	const location = useLocation();
	const params = useParams();

	useEffect(() => {
		let sidebarItems = [];
		let globalItems = GLOBAL_NAVIGATION_ITEMS;

		switch (userType) {
			case USER_TYPES.INVESTOR_USER || USER_TYPES.CORPORATE_USER:
				sidebarItems = INVESTOR_LEFT_SIDEBAR_ITEMS;
				break;
			case USER_TYPES.STARTUP_OWNER:
				sidebarItems = STARTUP_LEFT_SIDEBAR_ITEMS;
				break;
			case USER_TYPES.STARTUP_USER:
				sidebarItems = STARTUP_LEFT_SIDEBAR_ITEMS;
				break;
			case USER_TYPES.MENTOR_USER:
				sidebarItems = MENTOR_LEFT_SIDEBAR_ITEMS;
				break;
			case USER_TYPES.PARTNER_USER:
				sidebarItems = PARTNER_LEFT_SIDEBAR_ITEMS;
				break;
			default:
				console.log(userType);
				sidebarItems = [];
				break;
		}

		if (isAdmin) {
			sidebarItems = [...sidebarItems, ...ADMIN_SIDEBAR_ITEMS];
		}

		const dedupeGlobal = globalItems.filter((globeItem) => !sidebarItems.some((sbItem) => sbItem.link === globeItem.link));

		if (userType !== USER_TYPES.PARTNER_USER) setGlobalSidebarItems(dedupeGlobal);
		setLeftSidebarItems(sidebarItems);
	}, []);

	useEffect(() => {
		if (!approvalStatus) {
			services.getApprovalStatus().then((status) => {
				setApprovalStatus(status);
			});
		}
	}, [approvalStatus]);

	useEffect(() => {
		setActive(currentSelectedNavItem);
	}, [currentSelectedNavItem]);

	useEffect(() => {
		if (["discovery-board-expand", "discovery-board", "create-discovery-item", "create-quick-note"].includes(location.pathname.split("/")[1])) {
			setActive("discovery-board");
		} else if (["powerpack"].includes(location.pathname.split("/")[1]) && params.id) {
			setActive("workspace");
		} else if (
			(["profile"].includes(location.pathname.split("/")[1]) && params.id) ||
			(["myprofile"].includes(location.pathname.split("/")[2]) && params.id) ||
			["network"].includes(location.pathname.split("/")[2])
		) {
			setActive("network");
		} else {
			if (["dashboard"].includes(location.pathname.split("/")[2]) && ["mentor"].includes(location.pathname.split("/")[1])) {
				setActive("mentor-dashboard");
			} else if (["dashboard"].includes(location.pathname.split("/")[2]) && ["investor"].includes(location.pathname.split("/")[1])) {
				setActive("investor-dashboard");
			} else if (["dashboard"].includes(location.pathname.split("/")[2])) {
				setActive("dashboard");
			} else {
				setActive(location.pathname.split("/")[1]);
			}
		}
	}, [location]);

	return (
		<Box style={{ display: "flex" }}>
			<Box
				sx={{
					position: "sticky",
					zIndex: (theme) => theme.zIndex.drawer + 1,
					height: "64px",
					background: (theme) => theme?.palette?.secondary?.main,
					alignItems: "center",
					display: "flex",
					flex: 1,
					justifyContent: "space-between",
					px: 2,
				}}
			>
				<IconButton sx={{ color: "white" }} aria-label="menu" onClick={() => setOpen(!open)}>
					<Menu />
				</IconButton>
				<Box
					sx={{
						flex: 1,
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						gap: 2,
					}}
				>
					<Box component={"img"} src={UPDATED_LOGO_ICON_NEW} alt="logo" />
					<Typography
						variant="customValue"
						sx={{
							fontFamily: "PoppinsSemiBold",
							fontSize: "25px",
							color: "white",
						}}
					>
						StartupOS
					</Typography>
				</Box>
				<Avatar src={userPicture} />
			</Box>
			<Drawer
				PaperProps={{
					elevation: 1,
				}}
				variant="temporary"
				anchor="left"
				open={open}
				onClose={() => setOpen(false)}
				sx={{
					flexShrink: 0,

					"& .MuiDrawer-paper": {
						borderTop: "1px solid gray",
						boxSizing: "border-box",
						maxWidth: "430px",
						width: "100%",
						marginTop: "64px",
						background: (theme) => theme.palette.secondary.main,
						boxSizing: "border-box",
						paddingBottom: "64px",
					},
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
					}}
				>
					{globalNavItems?.map((menuItem, index) =>
						menuItem?.approvalRequired && approvalStatus === "APPROVED" ? (
							<ListItem
								key={menuItem.label}
								menuItem={menuItem}
								active={matchPath(location?.pathname, { path: menuItem?.link })?.isExact}
								open={open}
								timeout={50 + 50 * (index + 1)}
								handleClick={() => {
									if (menuItem.link === ROUTES_PATH.MEETINGS_BASEPATH) {
										dispatch(setNetworkingDashboardData({ openMeetingDialog: true }));
										setOpen(false);
										return;
									}
									if (menuItem.link === ROUTES_PATH.MESSAGES) {
										dispatch(
											setNetworkingDashboardData({
												openMessageDialog: true,
												messageWithMatch: "",
											})
										);
										setOpen(false);
										return;
									}
									history.push(`${menuItem.link}`);
									setOpen(false);
								}}
								fontSX={{
									fontFamily: "PoppinsSemiBold",
									fontSize: "14px",
									lineHeight: "21px",
									textTransform: "uppercase",
								}}
							/>
						) : !menuItem?.approvalRequired ? (
							<ListItem
								key={menuItem.label}
								menuItem={menuItem}
								active={matchPath(location?.pathname, { path: menuItem?.link })?.isExact}
								open={open}
								timeout={50 + 50 * (index + 1)}
								handleClick={() => {
									history.push(`${menuItem.link}`);
									setOpen(false);
								}}
								fontSX={{
									fontFamily: "PoppinsSemiBold",
									fontSize: "14px",
									lineHeight: "21px",
									textTransform: "uppercase",
								}}
							/>
						) : null
					)}
					<Box sx={{ px: "40px", py: "20px" }}>
						<Divider sx={{ borderColor: "white" }} />
					</Box>

					<ListItem
						menuItem={{
							icon: <Person />,
							label: getRoleValue(userType),
						}}
						open={open}
						timeout={100}
						isLabel={true}
					/>

					{leftSidebarItems?.map((menuItem, index) =>
						menuItem?.approvalRequired && approvalStatus === "APPROVED" ? (
							<ListItem
								key={menuItem.label}
								menuItem={menuItem}
								index={index}
								active={matchPath(location?.pathname, { path: menuItem?.link })?.isExact}
								open={open}
								timeout={50 + 50 * (index + 1)}
								handleClick={() => {
									history.push(`${menuItem.link}`);
									setOpen(false);
								}}
								isSubCat={true}
							/>
						) : !menuItem?.approvalRequired ? (
							<ListItem
								key={menuItem.label}
								menuItem={menuItem}
								active={matchPath(location?.pathname, { path: menuItem?.link })?.isExact}
								open={open}
								timeout={50 + 50 * (index + 1)}
								handleClick={() => {
									history.push(`${menuItem.link}`);
									setOpen(false);
								}}
								isSubCat={true}
							/>
						) : null
					)}
				</Box>
			</Drawer>
		</Box>
	);
};

export default MobileNav;
