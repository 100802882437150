import React, { useEffect, useState } from "react";
import { Box, Typography, Chip } from "@mui/material";
import "swiper/swiper-bundle.css";
import "swiper/css";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import { getFilterValues } from "../../utils/commonFunctions";

export default function FilteredValueCarosualComponent({ handdleDelete, filterId, maxWidth = "70%" }) {
	const [displayValue, setDisplayValue] = useState("none");
	const filteredValue = useSelector((state) => state?.investorDashboard?.filteredValue) || [];
	const slideLeft = () => {
		document.getElementById(filterId).scrollTo({
			left: 0,
			behavior: "smooth",
		});
	};
	const slideRight = () => {
		document.getElementById(filterId).scrollLeft += 200;
	};

	useEffect(() => {
		const element = document.getElementById(filterId);
		function isEllipsisActive(e) {
			return e.offsetWidth < e.scrollWidth;
		}
		if (isEllipsisActive(element)) {
			setDisplayValue("flex");
		} else {
			setDisplayValue("none");
		}
	}, [filteredValue]);

	return (
		<Box
			sx={{
				height: "50px",
				width: "inherit",
				maxWidth: maxWidth,
				display: "flex",
				alignItems: "center",
			}}
		>
			<Box
				id={filterId}
				sx={{
					height: "50px",
					overflow: "hidden",
					display: "flex",
					alignItems: "center",
				}}
			>
				{filteredValue &&
					filteredValue.map((item, index) => {
						return (
							<Box
								sx={{
									mr: 1,
								}}
							>
								<Chip
									sx={{
										"& .MuiChip-deleteIcon": {
											color: (theme) => theme.palette.secondary.black,
											"&:hover": { color: (theme) => theme.palette.secondary.black },
										},
									}}
									label={`${getFilterValues(item?.type, item?.checkboxValue)}`}
									onDelete={() => handdleDelete(item?.checkboxValue)}
									deleteIcon={<CloseIcon id={item?.checkboxValue} />}
								/>
							</Box>
						);
					})}
			</Box>
			<Box sx={{ display: displayValue, alignItems: "center", ml: "auto" }}>
				<ArrowCircleLeftOutlinedIcon fontSize="medium" sx={{ color: (theme) => theme.palette.secondary.black }} onClick={slideLeft} />
				<ArrowCircleRightOutlinedIcon sx={{ color: (theme) => theme.palette.secondary.black }} fontSize="medium" onClick={slideRight} />
				<Typography variant="title_medium">[{filteredValue?.length > 0 ? filteredValue?.length : 0}]</Typography>
			</Box>
		</Box>
	);
}
