import { Grid } from "@mui/material";
import React from "react";
import GraphComponent from "./GraphComponent";
import GraphLegends from "./GraphLegends";

const TAMSAMSOMGraph = ({ tam, sam, som }) => {
  return (
    <Grid container alignItems={"center"}>
      <Grid item lg={7} md={6} sm={12} xs={12}>
        <GraphComponent tam={tam} sam={sam} som={som} />
      </Grid>
      <Grid item lg={5} md={6} sm={12} xs={12}>
        <GraphLegends tam={tam} sam={sam} som={som} />
      </Grid>
    </Grid>
  );
};

export default TAMSAMSOMGraph;
