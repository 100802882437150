import { Box, Typography, useTheme, Button, Fab, Badge, Autocomplete, Select, OutlinedInput, MenuItem, InputLabel, FormControl } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import TableController from "../../../../components/Tables/TableController";
import { StartupSortOptions, StartupTableColumns } from "./StartupTableColumns";
import { FounderSortOptions, FounderTableColumns } from "./FounderTableColumns";
import EditIcon from "@mui/icons-material/Edit";
import { getPartnerFounders, getPartnerGroups, getPartnerStartups, removePartnerFromFounder } from "./functions";
import { useDispatch, useSelector } from "react-redux";
import UpdatedTextInputField from "../../../../common/UpdatedTextInputField";
import { setSlidingProfileData } from "../../../../modules/actions/slidingProfileActions";
import ThemeEditFlyout from "./ThemeEditFlyout";
import services from "../../../../services";
import { useHistory } from "react-router-dom";
import ROUTES_PATH from "../../../../constants/routes";
import SortComponent from "../../../../components/Tables/SortComponent";
import FilterDrawer from "./FilterDrawer";
import SelectDrop from "./SelectDrop";
import ToggleButtons from "./ToggleButtons";
import FormHelperText from "@mui/material/FormHelperText";
import { SelectIconComponent } from "../../../NewOnboard/Components";
import { setCommonDialogConfirmationData } from "../../../../modules/actions";
import UpdatedDialogConfirmation from "../../../../common/UpdatedDialogConfirmation";
import { ALERT_ICON_V1 } from "../../../../constants";
import Groups from "./Groups";

function GroupsTable({ partnerCode, groupsTableKey, setKeyForGroupsTable, editGroup }) {
	const theme = useTheme();
	const history = useHistory();
	const dispatch = useDispatch();
	const [partnerCodeSelect, setPartnerCodeSelect] = useState(null);
	const [loadedPartnerCode, setloadedPartnerCode] = useState(null);
	const isAdmin = useSelector((store) => store?.auth?.isAdmin);
	const userType = useSelector((store) => store?.auth?.userType);

	//const [groupsTableKey, setKeyForGroupsTable] = useState(0);

	const [foundersTableKey, setKeyForFoundersTable] = useState(0);
	const [sortCriteriaStartups, setSortCriteriaStartups] = useState({ colName: "", direction: "" });
	const [filterCriteriaStartups, setFilterCriteriaStartups] = useState({});
	const [filterDrawerOpen, setFilterDrawerOpen] = useState({ startups: false, founders: false });
	const [options, setOptions] = useState(false);
	const [buttonLoader, setButtonLoader] = useState(false);

	const groupColumns = [
		{
			field: "groupName",
			headerName: "Group Name",
			sortable: false,
			filterable: false,
			wrapText: true,
			width: 200,
			renderCell: (params) => <Typography variant="Text/xs/Regular">{params.value}</Typography>,
			renderHeader: (params) => <Typography variant="Text/xs/Medium">{params.colDef.headerName}</Typography>,
		},
		{
			field: "totalCompanies",
			headerName: "Group Size",
			sortable: false,
			filterable: false,
			wrapText: true,
			width: 150,
			renderCell: (params) => <Typography variant="Text/xs/Regular">{params.value}</Typography>,
			renderHeader: (params) => <Typography variant="Text/xs/Medium">{params.colDef.headerName}</Typography>,
		},
		{
			field: "groupDescription",
			headerName: "Description",
			sortable: false,
			filterable: false,
			wrapText: true,
			width: 400,
			renderCell: (params) => <Typography variant="Text/xs/Regular">{params.value}</Typography>,
			renderHeader: (params) => <Typography variant="Text/xs/Medium">{params.colDef.headerName}</Typography>,
		},
	];

	const handleSortChange = (newSortCriteria, type) => {
		if (type === "startups") setSortCriteriaStartups(newSortCriteria);
	};

	const reloadStartupsTable = () => {
		setKeyForGroupsTable((prevKey) => prevKey + 1);
	};

	const reloadFoundersTable = () => {
		setKeyForFoundersTable((prevKey) => prevKey + 1);
	};

	const reloadTables = (pc) => {
		setloadedPartnerCode(pc); //Prevent table flash on field clear.
		reloadStartupsTable();
		reloadFoundersTable();
	};

	useEffect(() => {
		if (partnerCode) {
			setloadedPartnerCode(partnerCode); //Prevent table flash on field clear.
			setPartnerCodeSelect(partnerCode);
		}
	}, [partnerCode]);

	useEffect(() => {
		sortfilterStartups();
	}, [sortCriteriaStartups]);

	useEffect(() => {
		sortfilterStartups();
	}, [filterCriteriaStartups]);

	const sortfilterStartups = async () => {
		reloadStartupsTable();
	};

	const getGroups = async (currentPage, pageSize) => {
		console.log("HERE GETTING GROUPS!");
		const result = await getPartnerGroups(
			currentPage,
			pageSize,
			partnerCodeSelect,
			sortCriteriaStartups?.direction,
			sortCriteriaStartups?.colName,
			formatFiltersForQuery(filterCriteriaStartups)
		);
		console.log("WE GOT:", result);
		return result;
	};

	const openStartupProfile = (id) => {
		dispatch(
			setSlidingProfileData({
				open: true,
				profileType: "STARTUP_PROFILE",
				profileId: id,
			})
		);
	};

	const formatFiltersForQuery = (filters) => {
		try {
			return Object.entries(filters)
				.map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
				.join("&");
		} catch (e) {
			console.log("Unable to process filter for query string, failed with ", e);
			return "";
		}
	};

	const handleStartupFilter = (column, value) => {
		setFilterCriteriaStartups((prevFilters) => {
			const newFilters = { ...prevFilters };
			// If the value is not empty, blank, or null, set the filter
			if (value !== "" && value !== null && value !== undefined) {
				newFilters[column] = value;
			} else {
				// If the value is empty, blank, or null, delete the filter
				delete newFilters[column];
			}
			return newFilters;
		});
	};

	const clearStartupFilters = () => {
		setFilterCriteriaStartups({});
	};

	return (
		<Box
			sx={{
				//px: { lg: 8, md: 4, xs: 2 },
				pb: "30px",
				display: "flex",
				flexDirection: "column",
				gap: "32px",
				margin: "0 auto",
				maxWidth: "1920px", // Figma specifies max content width
			}}
		>
			{(partnerCodeSelect || loadedPartnerCode) && (
				<TableController
					key={`groups_${groupsTableKey}`}
					requestData={(currentPage, pageSize) => getGroups(currentPage, pageSize)}
					columnDefs={groupColumns}
					title="Groups"
					showCount
					countLabel={{ singular: "Group", plural: "Groups" }}
					icon={
						<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
							<path
								d="M28 6.66651L13.3333 6.6665M28 25.3332L13.3333 25.3332M28 15.9998L13.3333 15.9998M8 6.6665C8 7.77107 7.10457 8.6665 6 8.6665C4.89543 8.6665 4 7.77107 4 6.6665C4 5.56193 4.89543 4.6665 6 4.6665C7.10457 4.6665 8 5.56193 8 6.6665ZM8 25.3332C8 26.4377 7.10457 27.3332 6 27.3332C4.89543 27.3332 4 26.4377 4 25.3332C4 24.2286 4.89543 23.3332 6 23.3332C7.10457 23.3332 8 24.2286 8 25.3332ZM8 15.9998C8 17.1044 7.10457 17.9998 6 17.9998C4.89543 17.9998 4 17.1044 4 15.9998C4 14.8953 4.89543 13.9998 6 13.9998C7.10457 13.9998 8 14.8953 8 15.9998Z"
								stroke={theme?.palette?.primary?.main}
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
						</svg>
					}
					noResultsMessage={
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
								gap: "16px",
								alignSelf: "stretch",
								flex: 1,
								justifyContent: "center",
							}}
						>
							<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
								<path
									d="M28 28L23.3335 23.3333M26.6667 15.3333C26.6667 21.5926 21.5926 26.6667 15.3333 26.6667C9.07411 26.6667 4 21.5926 4 15.3333C4 9.07411 9.07411 4 15.3333 4C21.5926 4 26.6667 9.07411 26.6667 15.3333Z"
									stroke={theme?.palette?.primary?.main}
									stroke-width="1.5"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
							</svg>
							<Typography variant="Text/sm/Semibold">No Groups found</Typography>
						</Box>
					}
					//sortComponent={
					//	<SortComponent
					//		sortOptions={StartupSortOptions}
					//		onSortChange={(obj) => handleSortChange(obj, "startups")}
					//		currentSort={sortCriteriaStartups}
					//	/>
					//}
					//filterComponent={
					//	<Fragment>
					//		<Badge
					//			badgeContent={Object.keys(filterCriteriaStartups).length ?? 0}
					//			color={"tertiary"}
					//			overlap="circular"
					//			sx={{
					//				"& .MuiBadge-badge": {
					//					fontFamily: (theme) => theme.typography["Text/xs/Semibold"],
					//					fontSize: ".8rem",
					//				},
					//			}}
					//		>
					//			<Button
					//				variant="DS1"
					//				iconButton={true}
					//				onClick={() =>
					//					setFilterDrawerOpen((prevState) => ({
					//						...prevState,
					//						startups: true,
					//					}))
					//				}
					//			>
					//				<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 26 26" fill="none">
					//					<path
					//						d="M17.0673 9H4.33333C2.49238 9 1 7.50762 1 5.66667C1 3.82572 2.49238 2.33333 4.33333 2.33333H17.0673M8.93266 23.6667H21.6667C23.5076 23.6667 25 22.1743 25 20.3333C25 18.4924 23.5076 17 21.6667 17H8.93266M1 20.3333C1 22.9107 3.08934 25 5.66667 25C8.244 25 10.3333 22.9107 10.3333 20.3333C10.3333 17.756 8.244 15.6667 5.66667 15.6667C3.08934 15.6667 1 17.756 1 20.3333ZM25 5.66667C25 8.244 22.9107 10.3333 20.3333 10.3333C17.756 10.3333 15.6667 8.244 15.6667 5.66667C15.6667 3.08934 17.756 1 20.3333 1C22.9107 1 25 3.08934 25 5.66667Z"
					//						stroke="white"
					//						stroke-width="1.5"
					//						stroke-linecap="round"
					//						stroke-linejoin="round"
					//					/>
					//				</svg>
					//			</Button>
					//		</Badge>
					//		<FilterDrawer
					//			isFilterOpen={filterDrawerOpen?.startups}
					//			closeDrawer={() =>
					//				setFilterDrawerOpen((prevState) => ({
					//					...prevState,
					//					startups: false,
					//				}))
					//			}
					//			clearAll={() => clearStartupFilters()}
					//			title={"Startup Filters"}
					//			filters={
					//				<Fragment>
					//					<ToggleButtons
					//						label={"Revenue"}
					//						selectOptions={["Yes", "No"]}
					//						onOptionChange={(value) => handleStartupFilter("revenue", value)}
					//						currentOption={filterCriteriaStartups?.revenue}
					//					/>
					//					<SelectDrop
					//						label={"Financing"}
					//						sortOptions={["None", "Priced Round", "Safe", "Conv. Note"]}
					//						onSortChange={(value) => handleStartupFilter("financing", value)}
					//						currentSort={filterCriteriaStartups?.financing}
					//					/>
					//					<SelectDrop
					//						label={"Current Round"}
					//						sortOptions={["Pre-Seed", "Seed", "Series A", "Growth"]}
					//						onSortChange={(value) => handleStartupFilter("currentStage", value)}
					//						currentSort={filterCriteriaStartups?.currentStage}
					//					/>
					//					input
					//					<SelectDrop
					//						label={"Seeking"}
					//						sortOptions={["Seed", "Series A"]}
					//						onSortChange={(value) => handleStartupFilter("seeking", value)}
					//						currentSort={filterCriteriaStartups?.seeking}
					//					/>
					//				</Fragment>
					//			}
					//		/>
					//	</Fragment>
					//}
					onRowClick={(res) => {
						if (typeof editGroup === "function") editGroup(res?.id);
					}}
					dataGridProps={{ disableColumnMenu: true }}
				/>
			)}
		</Box>
	);
}

export default GroupsTable;
