import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import useDimensions from "react-cool-dimensions";
import { Box, Button, Typography } from "@mui/material";
import { ProfileContext } from "../..";
import constants, { LIGHTNING_ICON_V1, PITCH_DECK_SECTION_TAB_ITEMS, PRESENTATION_CHARTLINE_ICON } from "../../../../constants";
import { StyledProfileCard } from "../../style";
import ROUTES_PATH from "../../../../constants/routes";
import { TabContainer, TabItem } from "./style";
import PitchDeckViewer from "../PitchDeckCard/PitchDeckViewer";
import MissingAsset from "../MissingAsset";
import FounderStorySection from "./FounderStorySection";
import ElevatorPitchSection from "./ElevatorPitchSection";

import PlatformActivitySection from "./PlatformActivitySection";
import { useSelector } from "react-redux";
import BoltPitchdeckReviewSection from "./BoltPitchdeckReviewSection";

function UpdatedPitchDeckSection() {
	const history = useHistory();
	const { profileData, viewContext, updateProfile } = useContext(ProfileContext);
	const userData = useSelector((store) => ({ userType: store?.auth?.userType, isAdmin: store?.auth?.isAdmin }));
	const [isSmallWidth, setIsSmallWidth] = useState(false);
	const [activeTab, setActiveTab] = useState(1);
	const [runPDReportButton, setrunPDReportButton] = useState(null);

	const { observe, unobserve, width, height, entry } = useDimensions({
		breakpoints: { SM: 850 },
		updateOnBreakpointChange: true,
	});
	useEffect(() => {
		observe();
	}, []);

	useEffect(() => {
		if (width >= 850) {
			setIsSmallWidth(false);
		} else {
			setIsSmallWidth(true);
		}
	}, [width]);

	const handleRedirection = () => {
		if (viewContext?.component === "startup") {
			history.push(ROUTES_PATH.EDIT_MY_STARTUP_PROFILE);
		}
	};

	return (
		<StyledProfileCard>
			<Box display={"flex"} flexDirection={"column"} gap={4}>
				<Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
					<TabContainer>
						{PITCH_DECK_SECTION_TAB_ITEMS?.map((pitchDeckItem, index) => {
							const shouldRenderPlatformActivites = userData?.isAdmin || index !== 3;
							return (
								shouldRenderPlatformActivites && (
									<TabItem key={index} isActive={activeTab === pitchDeckItem?.id} onClick={() => setActiveTab(pitchDeckItem?.id)}>
										<Box component={"img"} sx={{ height: "24px", width: "24px" }} src={pitchDeckItem?.icon} alt={pitchDeckItem?.label} />
										<Typography sx={{ color: "rgba(0, 0, 0, 1)" }} variant="Text/sm/Semibold">
											{pitchDeckItem.id === 1 && profileData?.companyName} {pitchDeckItem?.label}
										</Typography>
									</TabItem>
								)
							);
						})}
						{!viewContext?.id &&
							(profileData?.pitchDeckLink?.endsWith(".pdf") ||
								profileData?.pitchDeckLink?.endsWith(".PDF") ||
								profileData?.pitchDeckLink?.endsWith(".pptx") ||
								profileData?.pitchDeckLink?.endsWith(".PPTX")) && (
								<TabItem key={"bolt_pd_review"} isActive={activeTab === "bolt_pd_review"} onClick={() => setActiveTab("bolt_pd_review")}>
									<Box component={"img"} sx={{ height: "24px", width: "24px" }} src={LIGHTNING_ICON_V1} />
									<Typography sx={{ color: "rgba(0, 0, 0, 1)" }} variant="Text/sm/Semibold">
										Bolt AI Pitch Deck Review
									</Typography>
								</TabItem>
							)}
					</TabContainer>
					{!viewContext?.id &&
						(profileData?.pitchDeckLink?.endsWith(".pdf") ||
							profileData?.pitchDeckLink?.endsWith(".PDF") ||
							profileData?.pitchDeckLink?.endsWith(".pptx") ||
							profileData?.pitchDeckLink?.endsWith(".PPTX")) &&
						runPDReportButton}
				</Box>
				<Box>
					{activeTab === 1 && (
						<Box
							sx={{
								background: "gray",
								borderRadius: "40px",
							}}
						>
							{(() => {
								if (profileData?.pitchDeckLink) {
									if (profileData?.investorView === "true" || profileData?.investorView === true) {
										return <PitchDeckViewer />;
									} else {
										if (viewContext?.isEditable) {
											return (
												<MissingAsset
													sxProps={{ height: "450px", p: 2 }}
													message={"To display your pitch deck on your profile, please toggle its visibility from hidden to visible."}
												/>
											);
										} else {
											return <MissingAsset sxProps={{ height: "450px", p: 2 }} message={"Pitch Deck Coming Soon"} />;
										}
									}
								} else {
									return <MissingAsset sxProps={{ height: "450px", p: 2 }} message={"Pitch Deck Coming Soon"} />;
								}
							})()}
						</Box>
					)}

					{activeTab === 2 && <FounderStorySection />}
					{activeTab === 3 && <ElevatorPitchSection />}
					{userData?.isAdmin && activeTab === 4 && <PlatformActivitySection companyId={profileData?.id} />}
					{activeTab === "bolt_pd_review" && <BoltPitchdeckReviewSection setRunReportButton={(comp) => setrunPDReportButton(comp)} />}
				</Box>
			</Box>
		</StyledProfileCard>
	);
}

export default UpdatedPitchDeckSection;
