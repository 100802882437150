import React, { useEffect, useState } from "react";
import BILineChart from "./BILineChart/BILineChart";
import { Box, Typography, Chip, useTheme, CircularProgress } from "@mui/material";
import ChartCard from "./ChartCard";
import DisplayLastestParams from "./DisplayLastestParams";
import { get } from "lodash";
import { convertNumberToLocale, getGradientBgColor } from "../Functions/common";
import ZeroLineChart from "./BILineChart/EmptyLineChart";

function CustomerChartContainer({ onChartClick, data, loading, readOnly }) {
	const theme = useTheme();
	const chartData = get(data, "data", []);

	const latestTotalCustomers = get(data, "customers", null);
	const momGrowth = get(data, "momGrowth", null);

	const latestRetainedCustomers = get(data, "latestRetainedCustomers", null);
	const latestResurrectedCustomers = get(data, "latestResurrectedCustomers", null);
	const latestNewCustomers = get(data, "latestNewCustomers", null);
	const latestChurnedCustomers = get(data, "latestChurnedCustomers", null);

	const latestArributes = {
		Retained: latestRetainedCustomers,
		Resurrected: latestResurrectedCustomers,
		New: latestNewCustomers,
		Churned: latestChurnedCustomers,
	};

	return (
		<ChartCard
			title={"Customers"}
			subheader={latestTotalCustomers !== null && !loading ? `${convertNumberToLocale(latestTotalCustomers)}` : "0"}
			backgroundColor={theme.palette.secondary.white}
			onChartClick={() => onChartClick("customers", data)}
			momGrowth={momGrowth !== null ? `${convertNumberToLocale(momGrowth)}%` : "N/A"}
			toolTipBody={
				<>
					<Typography variant="Text/sm/Regular">
						The Customers chart tracks the number of active, paying customers a company has over time. Similar to MRR growth accounting, customers
						are broken into categories for a given month x:
					</Typography>
					<Typography variant="Text/sm/Regular" sx={{ fontStyle: "italic" }}>
						Total Customers = Retained Customers + New Customers + Resurrected Customers, where:
					</Typography>
					<Typography variant="Text/sm/Regular" sx={{ fontStyle: "italic" }}>
						Retained Customers = Count of customers with revenue in month x and month x-1
					</Typography>
					<Typography variant="Text/sm/Regular" sx={{ fontStyle: "italic" }}>
						New Customers = Count of customers with revenue in month but not in month x-1
					</Typography>
					<Typography variant="Text/sm/Regular" sx={{ fontStyle: "italic" }}>
						Resurrected Customers = Count of customers who are not new and who had no revenue in month x-1
					</Typography>
				</>
			}
			data={data?.data}
		>
			{loading ? (
				<Box sx={{ alignSelf: "center" }}>
					<CircularProgress />
				</Box>
			) : chartData.length === 0 ? (
				<ZeroLineChart readOnly={readOnly} />
			) : (
				<>
					<Box sx={{ mb: 3 }}>
						<DisplayLastestParams latestArributes={latestArributes} />
					</Box>
					<Box sx={{ position: "relative", width: "100%", mt: "auto" }}>
						<BILineChart
							data={chartData}
							tooltipYLable={"Total Customers"}
							yAxisKey={"totalCustomers"}
							lineBorderColor={theme.palette.primary.main}
							lineBackgroundColor={(context) => {
								const ctx = context.chart.ctx;
								return getGradientBgColor(ctx, context);
							}}
						/>
					</Box>
				</>
			)}
		</ChartCard>
	);
}

export default CustomerChartContainer;
