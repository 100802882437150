export const capitalizeWords = (phrase, exclusions) => {
	const capitalizedPhrase = phrase
		?.toLowerCase()
		.split(" ")
		.map((word, index) => {
			if (index === 0 || !exclusions.includes(word)) {
				return word.charAt(0).toUpperCase() + word.slice(1);
			} else {
				return word;
			}
		})
		.join(" ");

	return capitalizedPhrase;
};
