import React, { Fragment, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import TooltipWrapper from "../../common/Tooltip";
import { PROFILE_EDIT_LIST, STARTUP_EDIT_LIST } from "../../constants";
import { EditTabs, EditTabsItem } from "./style";
import StartupDetailsTab from "../../components/UpdatedStartupProfile/StartupDetailsSection";
import PhotosSection from "../../components/UpdatedStartupProfile/PhotosSection";
import TeamSectionTab from "../../components/UpdatedStartupProfile/TeamSection";
import ElevatorPitchTab from "../../components/UpdatedStartupProfile/ElevatorPitchSection";
import FounderStoryTab from "../../components/UpdatedStartupProfile/FounderStorySection";
import PitchDeckTab from "../../components/UpdatedStartupProfile/PitchDeckSection";

const EditStartupProfileController = ({ handleClose }) => {
	const [currentTab, setCurrentTab] = useState(0);

	const handleChange = (event, newValue) => {
		setCurrentTab(newValue);
	};

	return (
		<>
			<Box>
				<EditTabs variant="scrollable" scrollButtons="auto" allowScrollButtonsMobile value={currentTab} onChange={handleChange}>
					<Box display="flex" flexWrap="wrap" gap={3}>
						{" "}
						{STARTUP_EDIT_LIST.map((item, index) => {
							const tabItem = (
								<EditTabsItem
									selected={currentTab === item.id}
									onClick={(event) => {
										handleChange(event, index);
									}}
									disabled={item.disabled}
									disableRipple
									key={item.id}
									label={
										<Typography
											variant="Text/sm/Semibold"
											sx={{
												color: "inherit",
												background: "inherit",
												borderRadius: "100px",
											}}
										>
											{item.title}
										</Typography>
									}
								/>
							);
							return <Fragment key={index}>{item.disabled ? <TooltipWrapper>{tabItem}</TooltipWrapper> : tabItem}</Fragment>;
						})}
					</Box>
				</EditTabs>
			</Box>
			<Box>
				{currentTab === 0 && <StartupDetailsTab handleClose={handleClose} />}
				{currentTab === 1 && <PhotosSection handleClose={handleClose} />}
				{currentTab === 2 && <FounderStoryTab handleClose={handleClose} />}
				{currentTab === 3 && <ElevatorPitchTab handleClose={handleClose} />}
				{currentTab === 4 && <PitchDeckTab handleClose={handleClose} />}
				{currentTab === 5 && <TeamSectionTab handleClose={handleClose} />}
			</Box>
		</>
	);
};

export default EditStartupProfileController;
