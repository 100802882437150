import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Box, Typography, CircularProgress, FormControlLabel, Checkbox, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

import LoadingButton from "../../../common/Loadingbutton";
import services from "../../../services";
import { toastContainer } from "../../../utils/toast";
import { Question } from ".";
import { DECISION_STATUS, getFilledAnswers } from "../validation";

export const ReviewStep = ({ applicationId, handleNextStep, handleBeforeStep }) => {
	const [loading, setLoading] = useState(false);
	const [review, setReview] = useState({
		answered_question_sets: [],
		next_question_set: { id: "", title: "", description: "" },
	});
	const [reviewQuestions, setReviewQuestions] = useState([]);
	const [attestAnswers, setAttestAnswers] = useState([]);
	const [isAttested, setIsAttested] = useState(false);
	const [isVerified, setIsVerified] = useState(false);

	const {
		reset,
		setError,
		handleSubmit,
		control,
		formState: { errors, isValid },
	} = useForm({
		mode: "all",
	});

	const onSubmit = (data) => {
		reset(data);
		handleAttest();
	};

	useEffect(() => {
		if (applicationId && review.answered_question_sets.length === 0) {
			getDecision();
		}
	}, [applicationId, review.answered_question_sets.length]);

	useEffect(() => {
		if (Object.keys(review.answered_question_sets).length > 0) {
			if (reviewQuestions.length === 0) {
				getQuestionDetails();
			}
		}
	}, [review.answered_question_sets, reviewQuestions]);

	const handleVerify = (e) => {
		setIsVerified(e.target.checked);
	};

	const reviewAnswers = async () => {
		setLoading(true);
		try {
			const answers = await services.reviewVouchAnswers(applicationId);
			if (answers.data.data.has_more_questions === false) {
				setReview(answers.data.data);
			}
		} catch (error) {
			toastContainer(error?.data?.message || "Something is wrong for API side", "error");
		}
		setLoading(false);
	};

	const getDecision = async () => {
		setLoading(true);
		try {
			const decision = await services.getVouchDecision(applicationId);
			handleStatus(decision.data.data?.decision);
		} catch (error) {
			reviewAnswers();
		}
		setLoading(false);
	};

	const handleStatus = (decision) => {
		if (decision === DECISION_STATUS.REQUIRES_REVIEW) {
			setReview({
				...review,
				next_question_set: { id: "", title: "This application is under review.", description: "Please wait a little or contact to Vouch team." },
			});
		} else if (decision === DECISION_STATUS.APPROVED) {
			handleNextStep();
		} else if (decision === DECISION_STATUS.DECLINED) {
			setReview({
				...review,
				next_question_set: {
					id: "",
					title: "This application is declined.",
					description: "Please contact to Vouch team if you want to know the reason.",
				},
			});
		}
	};

	const handleAttest = async () => {
		setLoading(true);
		try {
			const attest = await services.attestVouchApplication(applicationId, {
				question_set_id: review.next_question_set.id,
				questions: attestAnswers,
			});
			setIsAttested(true);
			handleStatus(attest.data.data?.application_decision);
		} catch (error) {
			toastContainer(error?.data?.message || "Something is wrong for API side", "error");
		}
		setLoading(false);
	};

	const handleAnswer = (questionId, fieldId, value, type) => {
		// Since all questions are answered and those questions are set to readonly, this function always handles the questions of next_question_set from review API
		const answerIndex = attestAnswers.findIndex((answer) => answer.id === questionId);

		if (answerIndex !== -1) {
			const fieldIndex = attestAnswers[answerIndex].fields.findIndex((field) => field.id === fieldId);
			attestAnswers[answerIndex].fields[fieldIndex].value = type === "Multi-Select" ? value : [value];
		} else {
			attestAnswers.push({
				id: questionId,
				fields: [{ id: fieldId, value: type === "Multi-Select" ? value : [value] }],
			});
		}

		setAttestAnswers(attestAnswers);
	};

	const getQuestionDetails = async () => {
		setLoading(true);
		try {
			const promises = Object.keys(review.answered_question_sets).map(async (questionSet) => await services.getVouchQuestionSetsById(questionSet));
			const questionsRes = await Promise.all(promises);
			if (questionsRes.length > 0) {
				const all = questionsRes.map((res) => res.data.data);
				const data = Object.keys(review.answered_question_sets).map((setId) => {
					const questionSetIndex = all.findIndex((question) => question.id === setId);
					const filledAnswers = getFilledAnswers(all[questionSetIndex].questions, review.answered_question_sets[setId]);

					return filledAnswers;
				});

				setReviewQuestions(data.flat());
			}
		} catch (error) {
			toastContainer(error?.data?.message || "Something is wrong.", "error");
		}
		setLoading(false);
	};

	const handleEditAnswer = (questionId) => {
		Object.keys(review.answered_question_sets).forEach((setId) => {
			const questionIndex = review.answered_question_sets[setId].questions.findIndex((question) => question.id === questionId);

			if (questionIndex !== -1) {
				handleBeforeStep(setId, review.answered_question_sets);
			}
		});
	};

	const handleDisabled = (question) => {
		let isDisabled = false;
		if (question?.fields) {
			if (question.fields.length > 0) {
				question.fields.forEach((field) => {
					if (field.editable === false) {
						isDisabled = true;
					}
				});
			}
		}
		return isDisabled;
	};

	const handleInComplete = (question) => {
		let isComplete = false;
		if (question?.fields) {
			if (question.fields.length > 0) {
				question.fields.forEach((field) => {
					if ((field.provided_value === null || field.provided_value === "") && field.required === true) {
						isComplete = true;
					}
				});
			}
		}
		return isComplete;
	};

	const reviewQuestion = (question) => {
		try {
			const isCompleted = handleInComplete(question);
			if (isCompleted && !errors.inCompleted) {
				setError("inCompleted", {
					type: "400",
				});
			}

			return (
				<Box key={question.id} display="flex" alignItems="center" justifyContent="space-between" gap={3} mb={3}>
					<Typography
						variant="subtitle"
						sx={{
							color: isCompleted === true ? "#ff0000" : "#000",
						}}
					>
						{question.title}
					</Typography>

					<Box display="flex" alignItems="center">
						{<Question question={question} control={control} isReadlyOnly={true} errors={errors} handleAnswer={handleAnswer} />}
						<IconButton
							variant="contained"
							disabled={handleDisabled(question)}
							sx={{
								background: isCompleted ? "#ff0000" : "#5630B2",
								marginLeft: 1.5,
								"&:hover": { background: isCompleted ? "#ff0000" : "#5630B2" },
								"&.Mui-disabled": {
									background: "#5630B2 !important",
									opacity: 0.3,
								},
							}}
							onClick={() => handleEditAnswer(question.id)}
						>
							<EditIcon sx={{ color: "#fff", fontSize: "10px" }} />
						</IconButton>
					</Box>
				</Box>
			);
		} catch (e) {
			console.log("---reviewQuestion---error---", e);
		}
	};

	if (loading && review.next_question_set.title === "") {
		return (
			<Box display={"flex"} justifyContent="center" alignItems={"center"} height="600px">
				<CircularProgress />
			</Box>
		);
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Box py={4} textAlign={"center"}>
				<Typography variant="title_xlarge" sx={{ display: "block", marginBottom: "8px" }}>
					{review?.next_question_set.id !== "" ? "Get Business Owner Insurance from Vouch" : review.next_question_set?.title}
				</Typography>
				<Typography variant="body_large" sx={{ fontWeight: 600 }}>
					{review?.next_question_set.id !== ""
						? `Review your information below, and we'll create your custom quoute:`
						: review.next_question_set?.description}
				</Typography>
			</Box>

			{isAttested === false && reviewQuestions.length > 0 && (
				<>
					{reviewQuestions.map((question) => reviewQuestion(question))}
					<Box display="flex" justifyContent="space-between" gap={3}>
						{review?.next_question_set?.questions.map((question) => (
							<Box key={question.id} flex={1}>
								<Typography variant="caption">{question.title}</Typography>

								{<Question question={question} isSpacing={false} control={control} errors={errors} handleAnswer={handleAnswer} />}
							</Box>
						))}
					</Box>
					<FormControlLabel
						control={<Checkbox checked={isVerified} onChange={handleVerify} />}
						label={<Typography variant="body_small">{review.next_question_set?.description}</Typography>}
					/>
					<Box mt={5}>
						<LoadingButton
							type="submit"
							variant="contained"
							color="primary"
							disabled={!isValid || !isVerified || (errors?.inCompleted ? true : false)}
							loading={loading}
						>
							Submit
						</LoadingButton>
					</Box>
				</>
			)}
		</form>
	);
};
