import React from "react";
import { Box, Button } from "@mui/material";

export const Boolean = ({ questionId, question, value, disabled = false, onChange, handleAnswer }) => {
	const { id, label } = question;

	const handleRadioChange = value => {
		onChange(value);
		handleAnswer(questionId, id, value);
	};

	return (
		<Box display="flex">
			<Box mr={2}>
				<Button variant={value === "true" ? "contained" : "outlined"} disabled={disabled} onClick={() => handleRadioChange("true")}>
					Yes
				</Button>
			</Box>
			<Box>
				<Button variant={value === "false" ? "contained" : "outlined"} disabled={disabled} color="info" onClick={() => handleRadioChange("false")}>
					No
				</Button>
			</Box>
		</Box>
	);
};
