import services from "../../../../services";

function processPersonaBuilderPowerUP(data) {
	try {
		const completedData = data.filter((item) => item.isCompleted);

		if (!completedData) {
			return null;
		}

		let personaMap = completedData?.map((obj) => ({
			objName: obj?.name,
			icon: JSON.parse(obj?.moduleStepResponses?.find((responseObj) => responseObj.moduleStep.stepNo === 1)?.answers)?.avatar,
			personaName: JSON.parse(obj?.moduleStepResponses?.find((responseObj) => responseObj.moduleStep.stepNo === 1)?.answers)?.personaName,
			description: JSON.parse(obj?.moduleStepResponses?.find((responseObj) => responseObj.moduleStep.stepNo === 2)?.answers)?.summarize,
			age: JSON.parse(obj?.moduleStepResponses?.find((responseObj) => responseObj.moduleStep.stepNo === 2)?.answers)?.age,
			gender: JSON.parse(obj?.moduleStepResponses?.find((responseObj) => responseObj.moduleStep.stepNo === 2)?.answers)?.gender,
			maritalStatus: JSON.parse(obj?.moduleStepResponses?.find((responseObj) => responseObj.moduleStep.stepNo === 2)?.answers)?.maritalStatus,
			educationLevel: JSON.parse(obj?.moduleStepResponses?.find((responseObj) => responseObj.moduleStep.stepNo === 2)?.answers)?.educationLevel,
			state: JSON.parse(obj?.moduleStepResponses?.find((responseObj) => responseObj.moduleStep.stepNo === 2)?.answers)?.state,
			city: JSON.parse(obj?.moduleStepResponses?.find((responseObj) => responseObj.moduleStep.stepNo === 2)?.answers)?.city,
			industry: JSON.parse(obj?.moduleStepResponses?.find((responseObj) => responseObj.moduleStep.stepNo === 2)?.answers)?.industry,
			jobTitle: JSON.parse(obj?.moduleStepResponses?.find((responseObj) => responseObj.moduleStep.stepNo === 2)?.answers)?.jobTitle,
			bio: JSON.parse(obj?.moduleStepResponses?.find((responseObj) => responseObj.moduleStep.stepNo === 3)?.answers)?.bio,
			behavior: JSON.parse(obj?.moduleStepResponses?.find((responseObj) => responseObj.moduleStep.stepNo === 3)?.answers)?.behavior,
			goals: JSON.parse(obj?.moduleStepResponses?.find((responseObj) => responseObj.moduleStep.stepNo === 3)?.answers)?.goals,
			inspiration: JSON.parse(obj?.moduleStepResponses?.find((responseObj) => responseObj.moduleStep.stepNo === 3)?.answers)?.inspiration,
			challenges: JSON.parse(obj?.moduleStepResponses?.find((responseObj) => responseObj.moduleStep.stepNo === 3)?.answers)?.challenges,
			frustration: JSON.parse(obj?.moduleStepResponses?.find((responseObj) => responseObj.moduleStep.stepNo === 3)?.answers)?.frustration,
			motivations: {
				price: JSON.parse(obj?.moduleStepResponses?.find((responseObj) => responseObj.moduleStep.stepNo === 3)?.answers)?.motivations?.price,
				comfort: JSON.parse(obj?.moduleStepResponses?.find((responseObj) => responseObj.moduleStep.stepNo === 3)?.answers)?.motivations?.comfort,
				convenience: JSON.parse(obj?.moduleStepResponses?.find((responseObj) => responseObj.moduleStep.stepNo === 3)?.answers)?.motivations
					?.convenience,
				speed: JSON.parse(obj?.moduleStepResponses?.find((responseObj) => responseObj.moduleStep.stepNo === 3)?.answers)?.motivations?.speed,
				loyalty: JSON.parse(obj?.moduleStepResponses?.find((responseObj) => responseObj.moduleStep.stepNo === 3)?.answers)?.motivations?.loyalty,
			},
			traits: {
				introvert: JSON.parse(obj?.moduleStepResponses?.find((responseObj) => responseObj.moduleStep.stepNo === 3)?.answers)?.traits?.introvert,
				analytical: JSON.parse(obj?.moduleStepResponses?.find((responseObj) => responseObj.moduleStep.stepNo === 3)?.answers)?.traits?.analytical,
				loyal: JSON.parse(obj?.moduleStepResponses?.find((responseObj) => responseObj.moduleStep.stepNo === 3)?.answers)?.traits?.loyal,
				passive: JSON.parse(obj?.moduleStepResponses?.find((responseObj) => responseObj.moduleStep.stepNo === 3)?.answers)?.traits?.passive,
			},

			techSaviness: JSON.parse(obj?.moduleStepResponses?.find((responseObj) => responseObj.moduleStep.stepNo === 3)?.answers)?.techSaviness,
			brands: JSON.parse(obj?.moduleStepResponses?.find((responseObj) => responseObj.moduleStep.stepNo === 3)?.answers)?.brands,
			jobResponsibilities: JSON.parse(obj?.moduleStepResponses?.find((responseObj) => responseObj.moduleStep.stepNo === 4)?.answers)?.jobResponsibilities,
			tools: JSON.parse(obj?.moduleStepResponses?.find((responseObj) => responseObj.moduleStep.stepNo === 4)?.answers)?.tools,
			jobActivity: JSON.parse(obj?.moduleStepResponses?.find((responseObj) => responseObj.moduleStep.stepNo === 4)?.answers)?.jobActivity,
		}));

		return personaMap;
	} catch (e) {
		console.log("Function error when attempting to get the latest complete Persona Builder PowerUP data for company. Failed with error: ", e);
		return {};
	}
}

export function getPersonaBuilderPowerUP(companyID) {
	return services
		.getPersonaBuilderPowerUPData(companyID)
		.then((resp) => {
			if (resp?.data?.data) {
				return processPersonaBuilderPowerUP(resp?.data?.data);
			}
		})
		.catch((e) => {
			console.log("API error when attempting to get Persona Builder PowerUP data for company. Failed with error: ", e);
		});
}
