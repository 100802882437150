import { Box, Typography } from "@mui/material";
import React from "react";
import { PAGE_DASHBOARD_ICON, VIDEO_ICON_V2 } from "../../../constants";

export default function OpportunityDashTitle() {
	return (
		<Box sx={{ display: "flex", alignItems: "center", pl: 0 }} gap={1}>
			<Box component={"img"} src={PAGE_DASHBOARD_ICON} alt="dashbaord" sx={{ width: "30px", height: "30px" }} />
			<Typography variant="growth_card_subtitle" color="secondary.black">
				Opportunity Dashboard
			</Typography>
			<Box component={"img"} src={VIDEO_ICON_V2} alt="dashbaord" sx={{ width: "30px", height: "30px" }} />
		</Box>
	);
}
