import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Typography, TextField } from "@mui/material";
import LoadingButton from "../../../common/Loadingbutton";
import services from "../../../services";
import { toastContainer } from "../../../utils/toast";
import * as actions from "../../../modules/actions";

export const ConfirmStep = ({ handleNextStep }) => {
	const dispatch = useDispatch();
	const authData = useSelector((state) => state?.auth);
	const [loading, setLoading] = useState(false);
	const [useEmail, setUserEmail] = useState("");

	useEffect(() => {
		setUserEmail(authData?.email);
	}, [authData]);

	const handleConfirm = async () => {
		setLoading(true);
		try {
			const tokenData = await services.getVouchToken(useEmail);
			dispatch(
				actions.setAuthData({
					vouchToken: JSON.parse(tokenData.data.data),
				})
			);

			handleNextStep();
		} catch (error) {
			toastContainer(error?.data?.message || "Something is wrong for API side", "error");
		}
		setLoading(false);
	};

	return (
		<Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
			<Typography variant="subhead4">Enter an email address to start the application process</Typography>
			<Box mt={2}>
				<TextField
					spellCheck="true"
					fullWidth
					type="email"
					placeholder={"Email Address"}
					onChange={(e) => setUserEmail(e.target.value)}
					value={useEmail}
				/>
			</Box>
			<Box mt={2}>
				<LoadingButton variant="contained" color="primary" loading={loading} onClick={handleConfirm}>
					Confirm
				</LoadingButton>
			</Box>
		</Box>
	);
};
