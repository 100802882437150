import React, { useEffect, useState } from "react";
import CommonSlideDialog from "../../../../common/CommonSlideDialog";
import { Box, Button, Typography, Stack, FormGroup, FormControlLabel, Checkbox, useTheme, CircularProgress } from "@mui/material";
import LoadingButton from "../../../../common/Loadingbutton";
import { useDispatch, useSelector } from "react-redux";
import { setBIDashboardData } from "../../../../modules/actions/biDashboardActions";
import {
	sendPlaidAccounts,
	getAcvGridData,
	getCapTableData,
	getAnnualRevenueGridData,
	getBurnRunwayGridData,
	getCustomerGridData,
	getMonthlyRevenueGridData,
	getNetArrGridData,
	getPaybackGridData,
	getRevenueGridData,
	getSmExpenseVsNewSalesGridData,
	getCompanyDetails,
} from "../Functions/common";
import { LINK_PARTNER_CHECKBOX_CHECKED, LINK_PARTNER_CHECKBOX_UNCHECKED, LINK_PARTNER_GRID_PURPLE } from "../../../../constants";

function PlaidAccounts({ companyId }) {
	const theme = useTheme();
	const dispatch = useDispatch();
	const openPlaidAccountsSelect = useSelector((store) => store?.biDashboard?.openPlaidAccountsSelect);
	const plaidAccountsData = useSelector((store) => store?.biDashboard?.plaidAccountsData);
	const plaidAssetReportId = useSelector((store) => store?.biDashboard?.plaidAssetReportId);
	const [accounts, setAccounts] = useState([]);
	const [loading, setLoading] = useState(false);
	// const [refreshingData, setRefreshingData] = useState(false);

	const ownCompanyId = useSelector((state) => state?.auth?.companyId);
	const usedCompanyId = companyId ? companyId : ownCompanyId;

	const handleClose = () => {
		dispatch(setBIDashboardData({ openPlaidAccountsSelect: false }));
	};

	useEffect(() => {
		if (plaidAccountsData) {
			const depositoryAccounts = plaidAccountsData?.filter((plaidAccount) => plaidAccount?.type === "DEPOSITORY");
			const tempAccounts = depositoryAccounts?.map((plaidAccount) => ({ ...plaidAccount, selected: false }));
			setAccounts(tempAccounts ? tempAccounts : []);
		}
	}, [plaidAccountsData]);

	const handleChange = (event, accountId) => {
		const index = accounts.findIndex((account) => account.accountId === accountId);
		if (index !== -1) {
			const tempAccounts = [...accounts];
			tempAccounts[index] = { ...tempAccounts[index], selected: event.target.checked };
			setAccounts(tempAccounts);
		}
	};

	const handlePlaidAccountsSelect = async () => {
		try {
			setLoading(true);
			const selectedAccounts = accounts?.filter((account) => account?.selected);
			await sendPlaidAccounts({ assetReportId: plaidAssetReportId, accountIds: selectedAccounts?.map((account) => account?.accountId) });
			const burnRunwayGridDataresponse = await getBurnRunwayGridData(usedCompanyId);

			dispatch(
				setBIDashboardData({
					...(burnRunwayGridDataresponse && { burnAndRunway: burnRunwayGridDataresponse }),
				})
			);
			setLoading(false);
			handleClose();
		} catch (error) {
			console.log(error);
			setLoading(false);
		}
	};

	const isSelected = accounts.some((account) => account?.selected);

	return (
		<CommonSlideDialog
			open={openPlaidAccountsSelect}
			onClose={handleClose}
			disableBackDropClick
			title={
				<Box sx={{ display: "flex", flexDirection: "column", rowGap: 1 }}>
					<Box component={"img"} src={LINK_PARTNER_GRID_PURPLE} />
				</Box>
			}
			maxWidth="sm"
			titleVariant="Text/md/Semibold"
			titleStyle={{ p: 0, alignItems: "center" }}
			contentStyle={{ p: 0, flex: 1, display: "flex" }}
			fullWidth
			PaperProps={{
				sx: {
					m: 0,
					borderRadius: "24px",
					boxShadow: "0px 10px 25px 0px rgba(0, 0, 0, 0.05)",
					width: "100%",
					// minHeight: "450px",
					// height: "",
					p: 5,
				},
			}}
			footerStyle={{ p: 0 }}
			dialogAction={
				<Box display="flex">
					<LoadingButton
						loaderColor={theme.palette.primary.main}
						loadingStyles={{
							border: "4px solid",
							backgroundColor: theme.palette.secondary.white,
							borderColor: theme.palette.primary.main,
							height: "40px",
							px: 4,
						}}
						loading={loading}
						variant="DS1"
						
						disabled={!isSelected}
						onClick={() => handlePlaidAccountsSelect(accounts)}
					>
						Done
					</LoadingButton>
				</Box>
			}
		>
			<Box flex={1} display="flex" flexDirection={"column"} rowGap={2}>
				<Typography variant="Text/md/Semibold">Select Accounts for Burn & Runway Calculation</Typography>
				<Box>
					<FormGroup sx={{ pl: "2px" }}>
						{accounts.map((account) => {
							return (
								<FormControlLabel
									key={account.id}
									control={
										<Checkbox
											checkedIcon={<Box component="img" src={LINK_PARTNER_CHECKBOX_CHECKED} />}
											icon={<Box component="img" src={LINK_PARTNER_CHECKBOX_UNCHECKED} />}
											checked={account?.selected}
											onChange={(e) => handleChange(e, account.accountId)}
											name={account.id}
										/>
									}
									label={
										<Typography variant="Text/sm/Regular">{`${account?.name ?? ""} (${account?.type ?? ""}, ${
											account?.subtype ?? ""
										})`}</Typography>
									}
								/>
							);
						})}
					</FormGroup>
				</Box>
				<Box display="flex" columnGap={2}>
					{openPlaidAccountsSelect && accounts?.length === 0 && (
						<Typography variant="Text/sm/Regular">No depository accounts available with balance to select</Typography>
					)}
				</Box>
			</Box>
		</CommonSlideDialog>
	);
}

export default PlaidAccounts;
