import styled from "@emotion/styled";

export const UserCardContainer = styled.div(({ theme }) => {
	return {
		borderRadius: "40px",
		backgroundColor: "white",
		width: "100%",
		boxSizing: "border-box",
		display: "flex",
		flexDirection: "column",
		gap: 3,
		padding: "32px",
		[theme.breakpoints.between("sm", "md")]: {
			padding: "32px",
		},
		[theme.breakpoints.up("md")]: {
			padding: "32px",
		},
		boxShadow: "0px 10px 25px rgba(0, 0, 0, 0.05)",
	};
});
