import React from "react";
import { Box, Typography, Chip } from "@mui/material";
import { get } from "lodash";
import { CHECK_CIRCLE_WAVY_LIGHT } from "../../../../constants";

function MentorInfo({ matched }) {
	const about = get(matched, "about", "");

	function findApprovedUserTypes() {
		const approvedUserTypes = [];

		approvedUserTypes.push({ type: matched?.userTypes?.[0], isVerified: matched?.isVerified });

		return approvedUserTypes;
	}

	function getVerificationBadges() {
		const approvedTypes = findApprovedUserTypes();
		const appbadges = [];
		if (approvedTypes) {
			approvedTypes.forEach(({ type, isVerified }, index) => {
				switch (type) {
					case "MENTOR_USER":
						appbadges.push(
							<Box key={`${index}_${type}`} display="flex" alignItems="center" gap={1}>
								<Box component="img" src={CHECK_CIRCLE_WAVY_LIGHT} sx={{ width: "30px", height: "30px" }} />
								<Typography variant="Text/sm/Regular" sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
									{isVerified ? "Verified Mentor" : "Mentor"}
								</Typography>
							</Box>
						);
						break;
					case "STARTUP_OWNER":
						appbadges.push(
							<Box key={`${index}_${type}`} display="flex" alignItems="center" gap={1}>
								<Box component="img" src={CHECK_CIRCLE_WAVY_LIGHT} sx={{ width: "30px", height: "30px" }} />
								<Typography variant="Text/sm/Regular" sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
									{isVerified ? "Verified Founder" : "Founder"}
								</Typography>
							</Box>
						);
						break;
					case "INVESTOR_USER":
						appbadges.push(
							<Box key={`${index}_${type}`} display="flex" alignItems="center" gap={1} sx={{ minHeight: "30px", height: "30px" }}>
								<Box component="img" src={CHECK_CIRCLE_WAVY_LIGHT} sx={{ width: "30px", height: "30px" }} />
								<Typography variant="Text/sm/Regular" sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
									{isVerified ? "Verified Investor" : "Investor"}
								</Typography>
							</Box>
						);
						break;
					default:
						console.log("No matching user type.");
				}
			});
		}
		return appbadges;
	}
	return (
		<Box display="flex" flexDirection="column" sx={{ gap: 2 }}>
			<Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>{getVerificationBadges()}</Box>
			<Box display="flex" sx={{ minHeight: "48px", height: "48px" }}>
				<Typography
					variant="Text/sm/Regular"
					sx={{
						overflow: "hidden",
						whiteSpace: "pre-line",
						textOverflow: "ellipsis",
						WebkitLineClamp: "2",
						MozLineClamp: "2",
						display: ["-webkit-box", "-moz-box", "box"],
						WebkitBoxOrient: "vertical",
						MozBoxOrient: "vertical",
						boxOrient: "vertical",
						color: "#667085",
					}}
				>
					{about && about?.replace(/(<([^>]+)>)/gi, "")?.replace(/&amp;/g, "&")}
				</Typography>
			</Box>
			<Box sx={{ minHeight: "32px", height: "32px" }} overflow="hidden">
				{matched?.expertise && (
					<Box display="flex" alignItems="center" gap={1} flexWrap="wrap" overflow="hidden">
						{(() => {
							const value = matched?.expertise;
							const regex = /^\[.*\]$/; // Regex to check if the string is in array format
							const regexCommaSeparated = /^[^,\[\]]+(,[^,\[\]]+)*$/; // Regex to check if the string is comma-separated

							if (regex.test(value) || regexCommaSeparated.test(value)) {
								try {
									let parsedValue = null;
									if (regex.test(value)) {
										parsedValue = JSON.parse(value);
									} else {
										parsedValue = value.split(",").map((item) => item.trim());
									}

									if (Array.isArray(parsedValue)) {
										return parsedValue?.map((item, index) => (
											<Box
												key={index}
												sx={{
													whiteSpace: "nowrap",
													flexShrink: 0,
													overflow: "hidden",
													textOverflow: "ellipsis",
													maxWidth: "100%",
												}}
											>
												<Chip
													key={index}
													variant="outlined"
													sx={{
														border: "1px solid",
														borderColor: (theme) => theme.palette.bidashboard.grey,
													}}
													label={
														<Typography
															variant="Text/xs/Regular"
															color="sos.gray700"
															sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
														>
															{item}
														</Typography>
													}
												/>
											</Box>
										));
									}
								} catch {
									console.error("Invalid JSON format");
								}
							} else if (value)
								return (
									<Chip
										variant="outlined"
										sx={{
											border: "1px solid",
											borderColor: (theme) => theme.palette.bidashboard.grey,
											maxWidth: "100%",
										}}
										label={
											<Typography
												variant="Text/xs/Regular"
												color="sos.gray700"
												sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
											>
												{value}
											</Typography>
										}
									/>
								);
						})()}
					</Box>
				)}
			</Box>
		</Box>
	);
}

export default MentorInfo;
