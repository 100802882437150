import { Close } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setInvestorDashboardData } from "../../modules/actions";

import BenchmarkCompanyHeader from "./Components/HeaderComponents/header";
import BenchmarkGraphHero from "./Components/HeroSection/graphHero";
import ScoreInsights from "./Components/ScoreInsights";

function BenchmarkMainContent() {
	const dispatch = useDispatch();
	const STARTUP = useSelector((state) => state?.investorDashboard?.openStartupObj);
	return (
		<Box>
			<Box
				sx={{
					display: "flex",
					alignItems: "center",
					justifyContent: "space-between",
					px: {
						lg: 7,
						md: 5,
						xs: 3,
					},
					py: {
						lg: 5,
						md: 3,
						xs: 3,
					},
				}}
			>
				<Typography component={"h3"} variant="headline3" sx={{ flex: 1, color: "black" }}>
					{STARTUP?.companyName ? `${STARTUP?.companyName} -` : null} Score Details
				</Typography>
				<Button
					variant="text"
					sx={{ minWidth: "unset", width: "auto", fontSize: "40px" }}
					onClick={() =>
						dispatch(
							setInvestorDashboardData({
								benchmarkDetailsOpen: false,
							})
						)
					}
				>
					<Close />
				</Button>
			</Box>
			<Box
				sx={{
					p: {
						lg: 7,
						md: 5,
						xs: 3,
					},
					py: {
						lg: 5,
						md: 3,
						xs: 3,
					},
				}}
			>
				<BenchmarkCompanyHeader />
			</Box>
			<Box>
				<BenchmarkGraphHero />
			</Box>
			<Box>
				<ScoreInsights />
			</Box>
		</Box>
	);
}

export default BenchmarkMainContent;
