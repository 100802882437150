import React from "react";
import DialogComponent from "../../../common/CommonDialog";
import { Box, Button, Chip, Typography } from "@mui/material";
import { Add } from "@mui/icons-material";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import {
  convertFormattedStringToDate,
  getCharDay,
  getCurrentDate,
  getCurrentTime,
} from "../../../utils/date";
import { getEndTime } from "../Functions/common";
import AddToCalendar from "../../../common/AddToCalendar";

export default function TimeSelectedSuccessPopup({
  open,
  onClose,
  selectedDateTime,
  startupName,
}) {
  const event = {
    title: `Interview with ${startupName}`,
    description: "Interview Scheduled",
    address: selectedDateTime.timezone,
    startDate: selectedDateTime.newTime,
    endDate: selectedDateTime.newEndTime,
    durationInMinutes: selectedDateTime.duration,
  };
  return (
    <DialogComponent
      isOpen={open}
      onClose={onClose}
      title={`Confirmed`}
      titleVariant={"title_large"}
      contentStyle={{ px: 0 }}
      maxWidth={"xs"}
      fullWidth
      PaperProps={{
        style: { borderRadius: 28 },
      }}
      dialogAction={
        <Box display="flex" sx={{ mr: 2, py: 2 }}>
          <Box sx={{ mr: 1 }}>
            <AddToCalendar calendarEvent={event} />
          </Box>
        </Box>
      }
    >
      <Box sx={{ mx: 4, my: 4 }} textAlign="center">
        <CheckCircleOutlinedIcon
          sx={{ height: "56px", width: "56px" }}
          color="success"
        />
        <Box sx={{ my: 3 }}>
          <Typography variant="headline6">
            You scheduled your interview with {startupName}.
          </Typography>
        </Box>
        <Box sx={{ p: 1 }}>
          <Typography variant="label_xlarge" sx={{ color: "secondary.dark" }}>
            {getCurrentTime(selectedDateTime.time)} -{" "}
            {getCurrentTime(
              getEndTime(selectedDateTime.time, selectedDateTime.duration)
            )}{" "}
            ,{getCharDay(convertFormattedStringToDate(selectedDateTime.date))} ,
            {getCurrentDate(
              convertFormattedStringToDate(selectedDateTime.date)
            )}
          </Typography>
        </Box>
        <Box sx={{ p: 1, my: 2 }}>
          <Typography variant="label_xlarge" sx={{ color: "secondary.dark" }}>
            {selectedDateTime.timezone}
          </Typography>
        </Box>

        <Box sx={{ p: 1, my: 1 }}>
          <Typography variant="caption" sx={{ color: "text.primary" }}>
            A calendar invitation has been sent to your email address.
          </Typography>
        </Box>
      </Box>
    </DialogComponent>
  );
}
