import React from "react";
import Select, { components } from "react-select";
import { withStyles } from "@mui/styles";
import { ArrowDropDown } from "@mui/icons-material";
import ClearIcon from "@mui/icons-material/Clear";
import theme from "../../constants/Theme";
import {
  OutlinedInput,
  MenuItem,
  InputLabel,
  FormControl,
  Typography,
} from "@mui/material";

class Option extends React.Component {
  handleClick = event => {
    this.props.selectOption(this.props.data, event);
  };

  render() {
    const { children, isFocused, isSelected, onFocus } = this.props;
    return (
      <MenuItem
        onFocus={onFocus}
        selected={isFocused}
        onClick={this.handleClick}
        component="div"
        style={{
          fontWeight: isSelected ? 500 : 400,
        }}
      >
        {children}
      </MenuItem>
    );
  }
}

function SelectWrapped(props) {
  const { classes, ...other } = props;

  const MultiValue = props => (
    <components.MultiValue {...props}>
      <Typography variant="body_small" sx={{ color: "text.primary" }}>
        {props.data.label}
      </Typography>
    </components.MultiValue>
  );

  const MultiValueRemove = props => (
    <components.MultiValueRemove {...props}>
      <ClearIcon sx={{ color: "secondary.outlineXLight" }} />
    </components.MultiValueRemove>
  );

  const CustomInput = props => (
    <components.Input {...props} autoComplete="off"></components.Input>
  );

  return (
    <Select
      components={{
        Option: Option,
        DropdownIndicator: ArrowDropDown,
        MultiValue,
        MultiValueRemove,
        Input: CustomInput,
      }}
      menuPortalTarget={document?.body}
      menuPosition={"absolute"}
      styles={customStyles}
      isClearable={false}
      isMulti
      isSearchable={false}
      placeholder=" "
      {...other}
    />
  );
}

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
});

const customStyles = {
  control: () => ({
    display: "flex",
    alignItems: "center",
    border: 0,
    background: "transparent",
    "&:hover": {
      boxShadow: "none",
    },
    padding: "6px",
    cursor: "Pointer",
  }),
  menu: styles => ({
    ...styles,
    backgroundColor: theme.palette.secondary.white,
    position: "absolute",
    left: 0,
    top: `calc(100% + 1px)`,
    width: "100%",
    zIndex: 1,
  }),
  menuList: styles => {
    return {
      ...styles,
      overflowY: "auto",
    };
  },
  valueContainer: styles => ({
    ...styles,
    gap: "8px",
    padding: "2px 1px",
  }),
  multiValue: styles => ({
    ...styles,
    padding: "2px",
    backgroundColor: "transparent",
    border: "1px solid " + theme.palette.secondary.outlineXLight,
    borderRadius: theme.shape.standard_small.borderRadius,
    margin: 0,
  }),
  multiValueRemove: styles => ({
    ...styles,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "transparent",
      color: theme.palette.secondary.dark,
    },
  }),
  indicatorSeparator: styles => ({
    display: "none",
  }),
  placeholder: styles => ({
    ...styles,
    display: "none",
  }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
};

function MultiValueSelectInput({
  label,
  fullWidth,
  options,
  inputKey,
  selectedValues,
  handleFieldChange,
  ...props
}) {
  const handleChange = value => {
    const data = value.map(item => item.value);
    handleFieldChange({ target: { value: data } }, inputKey);
  };

  const { classes } = props;

  const mappedOptions = options.map(item => ({
    label: item,
    value: item,
  }));

  return (
    //<div className={classes.root}>
    <FormControl fullWidth sx={{ mt: 2 }}>
      <InputLabel
        id="select-label"
        variant="outlined"
        sx={{ background: theme.palette.text.white, zIndex: 1, p: 0 }}
      >
        {label}
      </InputLabel>
      <OutlinedInput
        fullWidth
        aria-label="select-label"
        inputComponent={SelectWrapped}
        value={selectedValues.map(item => ({ label: item, value: item }))}
        onChange={handleChange}
        id="react-select-single"
        inputProps={{
          options: mappedOptions,
          variant: "outlined",
          sx: { height: "auto", p: selectedValues.length ? "4px" : 1 },
          autoComplete: "off",
          "aria-label": "lll",
        }}
        label={label}
      />
    </FormControl>
    //</div>
  );
}

export default withStyles(styles, { withTheme: true })(MultiValueSelectInput);
