import React, { useCallback, useEffect, useState } from "react";
import CapGrid from "../DataGrid/CapGrid";
import { getCapTableData } from "../Functions/common";
import OwnerShipChart from "../Charts/OwnerShipChart";
import { useSelector, useDispatch } from "react-redux";
import { handleMixPanel } from "../../../../utils/mixPanelEventHandling";
import { setBIDashboardData } from "../../../../modules/actions/biDashboardActions";
import BIDataGridDialog from "../DataGrid/BIDataGridDialog";
import { useLocation } from "react-router-dom";
import ROUTES_PATH from "../../../../constants/routes";

function Ownership({ companyId, readOnly }) {
	const dispatch = useDispatch();
	const capData = useSelector((store) => store?.biDashboard?.capData);
	const openCapData = useSelector((store) => store?.biDashboard?.openCapData);
	// const [loading, setLoading] = useState(true);
	const [isEdited, setEdited] = useState(false);
	const loadingCapData = useSelector((store) => store?.biDashboard?.loadingCapData);

	const ownCompanyId = useSelector((state) => state?.auth?.companyId);
	const usedCompanyId = companyId ? companyId : ownCompanyId;

	const location = useLocation();

	const fetchData = async () => {
		try {
			dispatch(setBIDashboardData({ loadingCapData: true }));
			const responseData = await getCapTableData(usedCompanyId);
			if (responseData) {
				dispatch(setBIDashboardData({ capData: responseData }));
			}
			dispatch(setBIDashboardData({ loadingCapData: false }));
		} catch (error) {
			dispatch(setBIDashboardData({ loadingCapData: false }));
			console.log("error", error);
		}
	};

	useEffect(() => {
		if (location.pathname !== ROUTES_PATH.STARTUPGRID) {
			// don't call on startupgrid as other graph component will call this api
			dispatch(setBIDashboardData({ loadingCapData: true }));
			fetchData();
		}
	}, []);

	const onChartClick = () => {
		handleMixPanel("Grid Ownership Viewed");
		dispatch(setBIDashboardData({ openCapData: true, capTableName: "OwnerShip & Fully Diluted Shares" }));
	};

	const handleClose = useCallback(() => {
		if (isEdited) {
			dispatch(
				setBIDashboardData({
					openCapDataLeave: true,
				})
			);
		} else {
			dispatch(setBIDashboardData({ openCapData: false }));
		}
	}, [isEdited]);

	return (
		<>
			<OwnerShipChart data={capData} onChartClick={onChartClick} loading={loadingCapData} readOnly={readOnly} />
			<BIDataGridDialog title="OwnerShip & Fully Diluted Shares" isOpen={openCapData} onClose={handleClose}>
				<CapGrid
					title="OwnerShip & Fully Diluted Shares"
					gridData={capData}
					readOnly={readOnly}
					usedCompanyId={usedCompanyId}
					isEdited={isEdited}
					setEdited={setEdited}
				/>
			</BIDataGridDialog>
		</>
	);
}

export default Ownership;
