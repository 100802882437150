import React from "react";
import { Box, Typography } from "@mui/material";
import { get } from "lodash";
import { BUILDING_ICON_V3, CUBE_ICON_V2, CURRENECY_ICON_V2, PRODUCT_STATE_ICON_V2, STARTUP_ICON_V2 } from "../../../../constants";
import { useDispatch } from "react-redux";
import { setInvestorDashboardData } from "../../../../modules/actions";
import { USER_TYPES } from "../../../../utils/userTypes";

const getSelectedIndustryFormatted = (input) => {
	if (input) {
		try {
			let inputParsed = JSON.parse(input);
			if (Array.isArray(inputParsed)) {
				return inputParsed;
			} else {
				return [inputParsed];
			}
		} catch (error) {
			return [];
		}
	} else {
		return [];
	}
};

function InvestorInfo({ matched }) {
	const productOffering = getSelectedIndustryFormatted(get(matched, "productOffering", []));
	const industries = getSelectedIndustryFormatted(get(matched, "industries", []));
	const preferredInvestmentStage = getSelectedIndustryFormatted(get(matched, "preferredInvestmentStage", []));
	const userTypes = get(matched, "userTypes", []) ?? [];
	const companyName = get(matched, "companyName", "");

	const investmentRangeLow = matched?.investmentRangeLow;
	const investmentRangeHigh = matched?.investmentRangeHigh;
	const dispatch = useDispatch();

	const openSectionDetails = (title) => {
		dispatch(
			setInvestorDashboardData({
				openStartupObj: { ...matched, title },
				industryDetailsOpen: true,
				title: title,
			})
		);
	};

	function SmallDetails({ icon, details, title }) {
		return (
			<Box
				onClick={() => title !== "Company Name" && openSectionDetails(title)}
				display="flex"
				gap={"12px"}
				alignItems="center"
				sx={{ minHeight: "30px", height: "30px", overflow: "hidden", cursor: "pointer" }}
			>
				<Box component={"img"} src={icon} alt="icon" sx={{ width: "24px", height: "24px" }} />
				{Array.isArray(details) ? (
					<Typography variant="Text/sm/Regular" sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
						{details.length > 0 ? details?.join(", ") : "No Data"}
					</Typography>
				) : (
					<Typography variant="Text/sm/Regular">{details?.length > 0 ? details : "No Data"}</Typography>
				)}
			</Box>
		);
	}

	return (
		<Box display="flex" flexDirection="column" sx={{ gap: 2, pb: 3 }}>
			<Box display="flex" flexDirection="column" sx={{ gap: 1 }}>
				{userTypes?.includes(USER_TYPES.CORPORATE_USER) && <SmallDetails details={companyName} icon={STARTUP_ICON_V2} title="Company Name" />}

				<SmallDetails details={productOffering} icon={CUBE_ICON_V2} title="Product Offering" />
				<SmallDetails details={industries} icon={BUILDING_ICON_V3} />
				<SmallDetails details={preferredInvestmentStage} icon={PRODUCT_STATE_ICON_V2} title="Investment Stages" />

				{userTypes?.includes(USER_TYPES.INVESTOR_USER) && (
					<Box display="flex" gap={"12px"} alignItems="center" sx={{ minHeight: "30px", height: "30px", overflow: "hidden" }}>
						<Box component={"img"} src={CURRENECY_ICON_V2} alt="icon" sx={{ width: "24px", height: "24px" }} />
						{investmentRangeLow && investmentRangeHigh ? (
							<Typography variant="Text/sm/Regular" sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
								{`$${
									Intl.NumberFormat("en-US", {
										notation: "compact",
										maximumFractionDigits: 1,
									}).format(investmentRangeLow) || 0
								} - $${
									Intl.NumberFormat("en-US", {
										notation: "compact",
										maximumFractionDigits: 1,
									}).format(investmentRangeHigh) || 0
								}`}
							</Typography>
						) : (
							<Typography variant="Text/sm/Regular" sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
								No Data
							</Typography>
						)}
					</Box>
				)}

				{/* <SmallDetails details={investmentRange} icon={CURRENECY_ICON_V2} /> */}
			</Box>
		</Box>
	);
}

export default InvestorInfo;
