import React, { useContext, useEffect, useMemo, useState } from "react";
import moment from "moment";
import _ from "lodash";
import { Box, Typography, IconButton, CircularProgress, Button, Tooltip } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { PowerUPContext } from "../../../PowerUPController";
import useDimensions from "react-cool-dimensions";

const HISTORY_DATA = [
	{ id: 1, title: "Completed", value: 0 },
	{ id: 2, title: "In Progress", value: 0 },
	{ id: 3, title: "Archived", value: 0 },
];

const HISTORY_TABLE = [
	{ id: 1, title: "Name" },
	{ id: 2, title: "Modified Date" },
	{ id: 3, title: "Modified By" },
];

export default function VersionHistory({ showOnlyCompleted, showOnlyInProgress, selectedVersionCallback }) {
	const { powerupDetails, versions, currentVersion, setcurrentVersion } = useContext(PowerUPContext);

	const [selectedStatus, setSelectedStatus] = useState(showOnlyInProgress ? 1 : showOnlyCompleted ? 0 : 0); //seems redundant, but we want to future proof this, so we need to ensure that onlycomplete == 0
	const [historyDetails, setHistoryDetails] = useState([]);
	const [statuses, setStatuses] = useState(HISTORY_DATA);
	const [loading, setLoading] = useState(false);

	const { observe, width } = useDimensions({
		onResize: ({ width }) => {},
	});

	useEffect(() => {
		observe();
	}, [observe]);

	useEffect(() => {
		handleDetails();
	}, []);

	const handleStatus = async (index) => {
		setSelectedStatus(index);
	};

	const handleDetails = () => {
		try {
			setLoading(true);
			statuses[0].value = versions?.filter((model) => model?.isCompleted === true && model?.isArchived === false).length;
			statuses[1].value = versions?.filter((model) => model?.isCompleted === false && model?.isArchived === false).length;
			statuses[2].value = versions?.filter((model) => model?.isArchived === true).length;
			setStatuses(_.cloneDeep(statuses));
			if (statuses[0].value < 1) {
				setSelectedStatus(1); // If complete is empty, disable the button and set the default tab to in progress
			}
			setHistoryDetails(_.cloneDeep(versions));
		} catch (e) {
			console.log("---e----", e);
		}

		setLoading(false);
	};

	const handleView = (item, index) => {
		//item["uuid"] = powerupDetails?.uuid.replace("SURVEY_", "") || "";
		//item["type"] = powerupDetails?.type;
		//handleRedirectior(title, powerUpId, item?.isCompleted, item, item?.name ? item?.name : `Version ${index + 1}`);
		if (typeof selectedVersionCallback === "function") selectedVersionCallback(item);
	};

	const currentItems = useMemo(() => {
		if (selectedStatus === 0) {
			return historyDetails.filter((model) => model?.isCompleted === true && model?.isArchived === false);
		} else if (selectedStatus === 1) {
			return historyDetails.filter((model) => model?.isCompleted === false && model?.isArchived === false);
		} else if (selectedStatus === 2) {
			return historyDetails.filter((model) => model?.isArchived === true);
		}

		return [];
	}, [historyDetails, selectedStatus]);

	return (
		<Box ref={observe}>
			{!showOnlyInProgress && !showOnlyCompleted && (
				<Box
					sx={{
						py: "1.25rem",
						display: "flex",
						alignItems: "flex-start",
						gap: "1rem",
					}}
				>
					{statuses.map((history, index) =>
						history.value >= 1 ? (
							<Button
								key={history.id}
								variant="DS1"
								color={index === selectedStatus ? "secondary" : "gray"}
								small
								onClick={() => handleStatus(index)}
								disabled={history.value < 1}
							>
								{`${history.title} (${history.value})`}
							</Button>
						) : (
							<Tooltip title={`No ${history.title} Versions`}>
								<span>
									<Button
										key={history.id}
										variant="DS1"
										color={index === selectedStatus ? "secondary" : "gray"}
										small
										onClick={() => handleStatus(index)}
										disabled={history.value < 1}
									>
										{`${history.title} (${history.value})`}
									</Button>
								</span>
							</Tooltip>
						)
					)}
				</Box>
			)}
			{width > 600 ? (
				<Box display="grid" gridTemplateColumns={"1fr 1fr 1fr"} alignItems="center" justifyContent="space-between" gap={"12px"} mt={3} mb={1}>
					{HISTORY_TABLE.map((column) => (
						<Typography key={column.id} variant="Text/sm/Semibold" sx={{ fontWeight: 600 }}>
							{column.title}
						</Typography>
					))}
				</Box>
			) : (
				<Box display="grid" gridTemplateColumns={"1fr 1fr"} alignItems="center" justifyContent="space-between" gap={"12px"} mt={3} mb={1}>
					<Typography variant="Text/sm/Semibold" sx={{ fontWeight: 600 }}>
						Versions
					</Typography>
					<Typography variant="Text/sm/Semibold" sx={{ fontWeight: 600 }}>
						Modified By
					</Typography>
				</Box>
			)}
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
				}}
			>
				{currentItems.map((detail, index) => (
					<Box
						key={detail.id}
						sx={{
							...(width > 600 && {
								gap: "12px",
								display: "grid",
								gridTemplateColumns: "1fr 1fr 1fr",
								alignItems: "center",
								justifyContent: "space-between",
							}),
							...(width <= 600 && {
								columnGap: "12px",
								display: "grid",
								gridTemplateColumns: "repeat(2, 1fr)",
								gridTemplateRows: "repeat(2, 1fr)",
								alignItems: "center",
								justifyContent: "space-between",
								mb: 1,
							}),
						}}
					>
						<Typography
							variant="Text/sm/Regular"
							sx={{
								...(width <= 600 && { gridArea: "1 / 1 / 2 / 2" }),
							}}
						>
							{detail.name}
						</Typography>
						<Typography
							variant="Text/sm/Regular"
							sx={{
								...(width <= 600 && { gridArea: "2 / 1 / 3 / 2" }),
							}}
						>
							{moment(detail?.lastModifiedAt).format("MMM D, YYYY")}
						</Typography>
						<Box
							display="flex"
							alignItems="center"
							justifyContent="space-between"
							sx={{
								...(width <= 600 && { gridArea: "1 / 2 / 3 / 3" }),
							}}
						>
							<Typography variant="Text/sm/Regular">{detail.modifiedBy ? detail.modifiedBy : "-"}</Typography>
							{detail?.id === currentVersion?.id ? (
								<Tooltip title={"Current Version"}>
									<span>
										<IconButton disabled>
											<ArrowForwardIcon />
										</IconButton>
									</span>
								</Tooltip>
							) : (
								<IconButton onClick={() => handleView(detail, index)}>
									<ArrowForwardIcon />
								</IconButton>
							)}
						</Box>
					</Box>
				))}
			</Box>
		</Box>
	);
}
