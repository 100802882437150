import { Box, Typography } from "@mui/material";
import React, { useRef, useEffect, useState } from "react";

const CircleGraphic = ({ tam, sam, som }) => {
	const ref = useRef(null);

	useEffect(() => {
		const resizeHandler = () => {
			const { width, height } = ref.current.getBoundingClientRect();
			const circleSize = Math.min(width, height);
			const smallestSize = circleSize * (5 / 12);
			const middleSize = circleSize * (9 / 12);
			const largestSize = circleSize;
			setCircleSizes(smallestSize, middleSize, largestSize);

			// Calculated scale value based on a starting size of 400px. since the prototype is ~ 400px.
			if (width < 400) {
				let scale = Math.abs(width / 400);
				setTextScale(scale);
			} else {
				setTextScale(1);
			}
		};

		window.addEventListener("resize", resizeHandler);
		resizeHandler();

		return () => window.removeEventListener("resize", resizeHandler);
	}, []);

	const setCircleSizes = (smallestSize, middleSize, largestSize) => {
		setSmallestSize(smallestSize);
		setMiddleSize(middleSize);
		setLargestSize(largestSize);
	};

	const [smallestSize, setSmallestSize] = useState(0);
	const [middleSize, setMiddleSize] = useState(0);
	const [largestSize, setLargestSize] = useState(0);
	// This is the calculated scale value based on a starting size of 400px. since the prototype is ~ 400px.
	const [textScale, setTextScale] = useState(1);

	return (
		<div ref={ref} style={{ position: "relative", display: "flex", justifyContent: "center", alignItems: "flex-end", height: "100%", width: "100%" }}>
			<div
				style={{
					position: "absolute",
					bottom: 0,
					width: smallestSize,
					height: smallestSize,
					borderRadius: "50%",
					background: "linear-gradient(45deg, rgba(255,189,149,1) 0%, rgba(252,147,162,1) 70%)",
					border: "10px solid black",
					zIndex: 3,
				}}
			>
				<div
					style={{
						position: "absolute",
						top: "50%",
						left: "50%",
						transform: "translate(-50%, -50%)",
					}}
				>
					<Box sx={{ display: "flex", flexDirection: "column", gap: 1, justifyContent: "center", transform: `scale(${textScale})` }}>
						<Typography
							variant="customValue"
							sx={{
								fontFamily: "PoppinsBold",
								fontSize: "18px",
								lineHeight: "18px",
								textAlign: "center",
							}}
						>
							SOM
						</Typography>
						<Typography
							variant="customValue"
							sx={{
								fontFamily: "PoppinsBold",
								fontSize: "30px",
								lineHeight: "30px",
								textAlign: "center",
							}}
						>
							{som}
						</Typography>
					</Box>
				</div>
			</div>
			<div
				style={{
					position: "absolute",
					bottom: 0,
					width: middleSize,
					height: middleSize,
					borderRadius: "50%",
					border: "10px solid black",
					backgroundColor: "#DE0085",
					zIndex: 2,
				}}
			>
				<div
					style={{
						position: "absolute",
						top: smallestSize / 4,
						left: "50%",
						transform: "translateX(-50%)",
					}}
				>
					<Box sx={{ display: "flex", flexDirection: "column", gap: 1, justifyContent: "center", transform: `scale(${textScale})` }}>
						<Typography
							variant="customValue"
							sx={{
								fontFamily: "PoppinsBold",
								fontSize: "18px",
								lineHeight: "18px",
								color: "white",
								textAlign: "center",
							}}
						>
							SAM
						</Typography>
						<Typography
							variant="customValue"
							sx={{
								fontFamily: "PoppinsBold",
								fontSize: "30px",
								lineHeight: "30px",
								color: "white",
								textAlign: "center",
							}}
						>
							{sam}
						</Typography>
					</Box>
				</div>
			</div>
			<div
				style={{
					position: "absolute",
					bottom: 0,
					width: largestSize,
					height: largestSize,
					borderRadius: "50%",
					backgroundColor: "black",
					border: "10px solid black",
					zIndex: 1,
				}}
			>
				<div
					style={{
						position: "absolute",
						top: smallestSize / 8,
						left: "50%",
						transform: "translateX(-50%)",
					}}
				>
					<Box sx={{ display: "flex", flexDirection: "column", gap: 1, justifyContent: "center", transform: `scale(${textScale})` }}>
						<Typography
							variant="customValue"
							sx={{
								fontFamily: "PoppinsBold",
								fontSize: "18px",
								lineHeight: "18px",
								textAlign: "center",
								color: "white",
							}}
						>
							TAM
						</Typography>
						<Typography
							variant="customValue"
							sx={{
								fontFamily: "PoppinsBold",
								fontSize: "30px",
								lineHeight: "30px",
								textAlign: "center",
								color: "white",
							}}
						>
							{tam}
						</Typography>
					</Box>
				</div>
			</div>
		</div>
	);
};

export default CircleGraphic;
