import React, { useEffect, useState } from "react";
import { Stack, Grid, Box, CircularProgress } from "@mui/material";
import theme from "../../constants/Theme";
import ProfileHeader from "./ProfileHeader";
import { ProfileContentWrapper } from "./styles";
import EditProfile from "./EditForm";
import { REGEX } from "../../constants/regex";
import messages from "../../constants/messages";
import services from "../../services";
import { toastContainer } from "../../utils/toast";
import { useSelector } from "react-redux";
import { USAMAP } from "../../constants/image";
import { AREAS_OF_EXPERTISE } from "../../constants/mentor-data";
import * as actions from "../../modules/actions";
import { connect } from "react-redux";

function ExternalCandidateProfile({ candidateProfile, setCandidateProfile }) {
  const [showEdit, setShowEdit] = useState(false);
  const [candidateError, setCandidateError] = useState({
    firstNameError: "",
    lastNameError: "",
    zipCodeError: "",
    logoError: "",
  });

  const onboardingData = useSelector(state => state.onboarding);
  const [saveDisable, setSaveDisable] = useState(true);
  const [showState, setShowState] = useState(
    onboardingData?.companyLocation?.state
  );
  const [zipCode, setZipCode] = useState(
    onboardingData?.companyLocation?.zipCode
  );
  const [state, setState] = useState(onboardingData?.companyLocation?.state);
  const [stateImage, setStateImage] = useState(
    onboardingData?.companyLocation?.stateImage
  );
  const [location, setLocation] = useState({
    loaded: false,
    coordinates: {
      latitude: onboardingData?.companyLocation?.lat,
      longitude: onboardingData?.companyLocation?.lng,
    },
  });
  const [compLocation, setCompLocation] = useState("");
  const [payload, setPayload] = useState("");
  const [candidateData, setCandidateData] = useState("");
  const [loading, setLoading] = useState(true);
  const [saveLoading, setSaveLoading] = useState(false);
  const [imgLoading, setImgLoading] = useState(false);
  const [browseFlag, setBrowseFlag] = useState(false);

  useEffect(() => {
    handleUserDetails();
  }, [onboardingData]);

  //calling get api for user details
  const handleUserDetails = () => {
    services
      .getUserProfile()
      .then(response => {
        if (response.data.message === "Success") {
          setCandidateData(response.data.data);
          setLoading(false);
        }
      })
      .catch(e => {
        toastContainer(messages.API_SUBMIT_ERROR, "error");
        setLoading(false);
      });
  };

  useEffect(() => {
    if (candidateData?.info) {
      const data = Object.assign(candidateData?.info);
      data["picture"] = candidateData?.picture;
      setPayload(data);

      //if zip code exists, set state and city
      if (candidateData?.info?.zipcode) {
        handleZipCodeChange(candidateData?.info?.zipcode.toString());
      }
    } else {
      const data = { ...payload };
      data["email"] = candidateData.email;
      data["userType"] = "CANDIDATE_USER";
      setPayload(data);
    }
  }, [candidateData]);

  const handleBlur = (e, key) => {
    let pass;

    switch (key) {
      case "firstName":
        if (!e.target.value || e.target.value == "") {
          setCandidateError(prevState => ({
            ...prevState,
            firstNameError: messages.CANDIDATE_FIRST_NAME_BLANK,
          }));
          pass = false;
        } else if (!e.target.value.match(REGEX.COMPANY_NAME)) {
          setCandidateError(prevState => ({
            ...prevState,
            firstNameError: messages.COMPANY_NAME_LENGTH_INVALID,
          }));
          pass = false;
        } else {
          setCandidateError(prevState => ({
            ...prevState,
            firstNameError: "",
          }));
          pass = true;
        }
        break;

      case "lastName":
        if (!e.target.value || e.target.value == "") {
          setCandidateError(prevState => ({
            ...prevState,
            lastNameError: messages.CANDIDATE_LAST_NAME_BLANK,
          }));
          pass = false;
        } else if (!e.target.value.match(REGEX.COMPANY_NAME)) {
          setCandidateError(prevState => ({
            ...prevState,
            lastNameError: messages.COMPANY_NAME_LENGTH_INVALID,
          }));
          pass = false;
        } else {
          setCandidateError(prevState => ({
            ...prevState,
            lastNameError: "",
          }));
          pass = true;
        }
        break;

      case "zipcode":
        if (!e.target.value || e.target.value == "") {
          setCandidateError(prevState => ({
            ...prevState,
            zipCodeError: messages.LOCATION_BLANK,
          }));
          pass = false;
        }
        break;
      default:
        break;
    }

    if (pass === false) {
      setSaveDisable(true);
    }
  };

  const handleCloseForm = () => {
    setShowEdit(false);
    setBrowseFlag(false);
    setImgLoading(false);
    //Resetting error messages
    setCandidateError({
      firstNameError: "",
      lastNameError: "",
      zipCodeError: "",
      logoError: "",
    });

    //recalling the apis
    handleUserDetails();
  };

  //Zipcode
  const resetState = () => {
    setState("");
    setStateImage(USAMAP);
  };

  const handleZipCodeChange = async value => {
    if (value.match(REGEX.ZIP_CODE)) {
      setZipCode(value);
      if (value.length >= 5) {
        const locationData = await services.searchLocationByZipcode(value);

        if (locationData.data) {
          const usaData = locationData?.data?.filter(
            loc => loc?.address?.country_code === "us"
          );
          if (usaData.length > 0) {
            if (usaData[0].address?.state) {
              setState(usaData[0].address.state);
              setLocation(usaData[0].address.city);
              if (!usaData[0].address.city) {
                if (usaData[0].address.village) {
                  setCompLocation(
                    `${usaData[0].address.village}, ${usaData[0].address.state}`
                  );
                } else if (usaData[0].address.town) {
                  setCompLocation(
                    `${usaData[0].address.town}, ${usaData[0].address.state}`
                  );
                } else {
                  setCompLocation(usaData[0].address.state);
                }
              } else {
                setCompLocation(
                  `${usaData[0].address.city}, ${usaData[0].address.state}`
                );
              }

              setShowState(true);
            } else {
              //If state dont found for zipcode api
              const lattitude = usaData[0]?.lat;
              const longitude = usaData[0]?.lon;

              const latLongData = await services.searchLocationByCoordinates(
                lattitude,
                longitude
              );
              const address = latLongData.data.address;

              if (address.country_code === "us") {
                setState(address.state);
                setLocation(address.city);
                setCompLocation(address.state);

                setShowState(true);
              } else {
                setCandidateError(prevState => ({
                  ...prevState,
                  zipCodeError: messages.LOCATION_INVALID,
                }));
                toastContainer(messages.LOCATION_INVALID, "error");
              }
            }
          } else {
            setCandidateError(prevState => ({
              ...prevState,
              zipCodeError: messages.LOCATION_INVALID,
            }));
            toastContainer(messages.LOCATION_INVALID, "error");
            resetState();
          }
        }
      } else {
        resetState();
      }
    } else {
      resetState();
    }
  };

  const handleClear = () => {
    setCompLocation("");

    //remove from payload
    const data = { ...payload };
    data[`zipcode`] = "";
    setPayload(data);
  };

  const handleChange = (e, key) => {
    if (key === "zipcode") {
      if (!e.target.value.match(REGEX.SPECIAL_CHARACTERS)) {
        handleZipCodeChange(e.target.value);
        setCompLocation(e.target.value);
        setCandidateError(prevState => ({
          ...prevState,
          zipCodeError: "",
        }));
      } else {
        setCandidateError(prevState => ({
          ...prevState,
          zipCodeError: messages.LOCATION_INVALID,
        }));
        setSaveDisable(true);
      }
    }
    //setData to payload object
    if (key === "picture") {
      const data = { ...payload };
      data[key] = e;
      setPayload(data);
    } else {
      const data = { ...payload };
      data[key] = e.target.value;
      setPayload(data);
    }
  };

  useEffect(() => {
    //save button condition
    if (
      !payload[`firstName`] ||
      payload[`firstName`] === "" ||
      !payload[`lastName`] ||
      payload[`lastName`] === ""
    ) {
      setSaveDisable(true);
    } else {
      setSaveDisable(false);
    }
  }, [payload]);

  //function for update api calling
  const handleUpdateUser = () => {
    setSaveLoading(true);
    services
      .updateUserProfile(payload)
      .then(response => {
        if (response.data.message === "Success") {
          handleCloseForm();
          setSaveLoading(false);
        }
      })
      .catch(e => {
        console.log("Submit", e);
        toastContainer(messages.API_SUBMIT_ERROR, "error");
        handleCloseForm();
        setSaveLoading(false);
      });
  };

  const getFiles = async (data, type) => {
    if (
      data.type == "image/png" ||
      data.type == "image/jpeg" ||
      data.type == "image/jpg"
    ) {
      setImgLoading(true);
      setCandidateError(prevState => ({
        ...prevState,
        logoError: "",
      }));

      var formData = new FormData();
      formData.append("file", data);
      formData.append("fileGroup", "PROFILE");

      setImgLoading(true);
      services
        .uploadFile(formData)
        .then(response => {
          setImgLoading(false);
          setBrowseFlag(true);
          if (type == "img") {
            handleChange(response.data.data.fileLocation, "picture");
            setSaveDisable(false);
          }
        })
        .catch(e => {
          console.log("File upload Error", e);
          setImgLoading(false);
          if (type == "img") {
            setCandidateError(prevState => ({
              ...prevState,
              logoError: messages.FILE_UPLOAD_ERROR,
            }));
          }
        });
    } else {
      setCandidateError(prevState => ({
        ...prevState,
        logoError: "Invalid file type.",
      }));
    }
  };

  return (
    <>
      <Stack
        sx={{
          minHeight: "100%",
          borderRadius: { md: "28px" },
          display: "block",
          background:
            "linear-gradient(0deg, rgba(118, 73, 160, 0.05), rgba(118, 73, 160, 0.05)), #FCFCFC",
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          sx={{
            px: { md: 4 },
            maxWidth: theme.breakpoints.values.xl,
            margin: "auto",
          }}
        >
          <ProfileContentWrapper>
            {!loading ? (
              <>
                {!showEdit ? (
                  <>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      sx={{ px: { xs: 4, md: 0 }, mt: { xs: 4, md: 0 } }}
                    >
                      <ProfileHeader
                        setShowEdit={setShowEdit}
                        candidateData={candidateData}
                        compLocation={compLocation}
                      />
                    </Grid>
                  </>
                ) : (
                  <>
                    <EditProfile
                      handleBlur={handleBlur}
                      candidateError={candidateError}
                      saveDisable={saveDisable}
                      handleCloseForm={handleCloseForm}
                      handleUpdateUser={handleUpdateUser}
                      handleZipCodeChange={handleZipCodeChange}
                      handleChange={handleChange}
                      compLocation={compLocation}
                      handleClear={handleClear}
                      payload={payload}
                      expertise={AREAS_OF_EXPERTISE}
                      handleUserDetails={handleUserDetails}
                      saveLoading={saveLoading}
                      handleUploadAction={getFiles}
                      setCandidateError={setCandidateError}
                      imgLoading={imgLoading}
                      browseFlag={browseFlag}
                    />
                  </>
                )}
              </>
            ) : (
              <Box sx={{ display: "flex", width: 1, justifyContent: "center" }}>
                <CircularProgress size={30} />
              </Box>
            )}
          </ProfileContentWrapper>
        </Grid>
      </Stack>
    </>
  );
}

const mapStoreToProps = ({ candidate }) => ({
  candidateProfile: candidate?.candidateProfile,
});

const mapDispatchToProps = dispatch => ({
  setCandidateProfile: updatedSteps =>
    dispatch(actions.setCandidateProfile(updatedSteps)),
});

export default connect(
  mapStoreToProps,
  mapDispatchToProps
)(ExternalCandidateProfile);
