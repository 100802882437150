import React, { useState, useEffect } from "react";
import { Box, Typography, Grid } from "@mui/material";
import PresentationTemplate from "../../../../../../../components/Workspace/PowerUp/PresentationTemplate";
import { TEMPLATE_TYPES } from "../../../../../../../constants/brandstorming-data";
import PresentationPreview from "../../../../../../../components/Workspace/PowerUp/PresentationPreview";

export default function UnlockYourDeck({ userInputs, setUserInputs }) {
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  const [previewIndex, setPreviewIndex] = useState(0);
  const [selected, setSelected] = useState(0);

  const handleInputChange = (value, key) => {
    const currentInput = { ...userInputs };
    currentInput[key] = value;
    setUserInputs(currentInput);
  };

  const handleDialog = () => {
    setIsPopUpOpen(!isPopUpOpen);
  };

  useEffect(() => {
    setSelected(0);
    handleInputChange(0, "template");
  }, []);

  useEffect(() => {
    setSelected(userInputs?.template);
  }, [userInputs]);

  return (
    <Box>
      <PresentationPreview
        open={isPopUpOpen}
        close={handleDialog}
        data={TEMPLATE_TYPES[previewIndex]}
      />
      <Grid container spacing={0} sx={{ pt: 0, display: "flex", gap: 4 }}>
        <Grid item xs={12} md={12}>
          <Grid
            container
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              gap: { xs: 2, md: 0 },
            }}
          >
            {/*{TEMPLATE_TYPES.map((item, index) => {
              return (
                <Grid item xs={12} md={6} lg={3}>
                  <PresentationTemplate
                    data={item}
                    handleInputChange={handleInputChange}
                    handlePreview={handleDialog}
                    setPreviewIndex={setPreviewIndex}
                    setSelected={setSelected}
                    selected={selected}
                  />
                </Grid>
              );
            })}*/}

            <Box
              sx={{
                display: "flex",
                width: 1,
                height: 1,
                border: "1px solid rgba(124, 117, 127, 0.16)",
                borderRadius: 3,
                mb: 1,
              }}
            >
              <div
                className="App"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <iframe
                  src={`https://view.officeapps.live.com/op/embed.aspx?src=${TEMPLATE_TYPES[0].fileUrl}`}
                  width="100%"
                  height="500px"
                  frameBorder="0"
                  title="slides"
                ></iframe>
              </div>
            </Box>
          </Grid>
        </Grid>

        <Grid item xs={12} md={12} sx={{ pt: 0 }}>
          <Typography variant="title_medium">
            Customize with your brand
          </Typography>
        </Grid>

        <Grid item xs={12} md={12}>
          <Typography variant="subtext_medium">
            Update the template with your logo, font styles, and brand color
            scheme to make it yours.
          </Typography>
        </Grid>

        <Grid item xs={12} md={12}>
          <Typography variant="subtext_medium">
            If you need help choosing any brand related material, your chosen
            deck template will guide you and recommend resources to finalize
            your brand assets and styles.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}
