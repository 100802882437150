import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import { ContentWrapper } from "./styles";

const LinkedInProfileWarning = ({ value }) => {
  return (
    <ContentWrapper value={value}>
      <Box>
        <Stack direction="row" alignItems={"center"} spacing={1}>
          <ErrorIcon sx={{ color: "warning.yellow" }} />

          <Box variant="title_medium">Complete your profile faster</Box>
        </Stack>
        <Box sx={{ pt: 2, pl: "5px" }}>
          <Typography variant="bodyMedium" sx={{ color: "#464B4E" }}>
            Providing your LinkedIn Company profile link will autofill key
            information needed to complete your Startup Profile.
          </Typography>
        </Box>
      </Box>
    </ContentWrapper>
  );
};

export default LinkedInProfileWarning;
