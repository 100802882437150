import React, { useState, useEffect, useRef } from "react";
import { Typography, Grid, Divider, TextField, Button, Box, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

import { BELL_RINGING } from "../../../../constants";
import { Close } from "@mui/icons-material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import AddIcon from "@mui/icons-material/Add";
import theme from "../../../../constants/Theme";
import sosPrimary from "../../../../constants/Theme";
import { ProblemWrapper, SolutionInput } from "./styles";
import messages from "../../../../constants/messages";
import { problemInputValid } from "../../../../containers/Workspace/PowerUP/ProblemSolution/functions/common";
import { UPDATED_LOGO_ICON_NEW } from "../../../../constants";
import { ArrowForward, OpenInNew } from "@mui/icons-material";

import InfoHelper from "../../../../components/Founder/InfoHelper";
import { problemSolutionEvaluation } from "../../../../utils/AI/problemSolution";
import BoltResponseModal from "./BoltResponseModal";
import { BoltSolutionExport } from "./BoltSolutionReviewExport";

export default function SolutionList({ index, problem, handleSolutionAddition, handleInputChange, userInputs, yourIdea }) {
	const [errorMessages, setErrorMessages] = useState("");

	const [currentProblemObj, setcurrentProblemObj] = useState(problem);

	const [BoltResponseObj, setBoltResponseObj] = useState(null);

	const [BoltLoading, setBoltLoading] = useState(false);

	const [showBoltResponse, setshowBoltResponse] = useState(false);

	const [confirmOpen, setConfirmOpen] = useState(false);

	useEffect(() => {
		setcurrentProblemObj(problem);
	}, [problem]);

	const boltAIButtonRef = useRef(null);

	const handleBlur = (e) => {
		if (problemInputValid(e.target.value)) {
			setErrorMessages("");
		} else {
			setErrorMessages(messages.PROBLEM_INPUT_INVALID);
		}
	};

	const conditionalReturn = (id) => {
		if (userInputs[`solution${index}`] && userInputs[`solution${index}`][id] !== null) {
			return userInputs[`solution${index}`][id];
		} else {
			return "";
		}
	};

	// Necessary to avoid <InfoHelper/> from triggering button click
	const clickBoltAIReviewRequest = (event) => {
		if (event.target === boltAIButtonRef.current) {
			// If there's a report already generated -- then confirm with user before replacing.
			if (BoltResponseObj) {
				setConfirmOpen(true);
			} else {
				requestBoltEval();
			}
		}
	};

	const requestBoltEval = async () => {
		setConfirmOpen(false);
		setBoltLoading(true);

		let response = await problemSolutionEvaluation({
			problem: currentProblemObj?.problem,
			solutions: currentProblemObj?.solution?.toString(),
			idea: yourIdea,
		});

		setBoltResponseObj(response);

		setBoltLoading(false);
	};

	const handleDownload = async () => {
		await BoltSolutionExport({ response: BoltResponseObj });
		setConfirmOpen(false);
		requestBoltEval();
	};

	const clickOpenReport = () => {
		setshowBoltResponse(true);
	};

	return (
		<Grid container sx={{ minHeight: "100px", gap: { xs: 2, md: 0 } }}>
			<Divider sx={{ width: 1, mt: 2, mb: 2 }} />

			<Grid
				item
				xs={12}
				md={5}
				sx={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					flexDirection: "column",
				}}
			>
				<ProblemWrapper>
					<Typography variant="onsurface_subtitle">Problem {index}</Typography>
					<Typography variant="onsurface_subtext">{problem.problem}</Typography>
				</ProblemWrapper>
				<Button
					ref={boltAIButtonRef}
					fullWidth
					disabled={BoltLoading}
					sx={{
						width: "100%",
						margin: "16px auto",
						minWidth: "unset",
						backgroundColor: "black",
						borderRadius: "10px",
						"&:hover": {
							backgroundColor: "black",
						},
						position: "relative",
					}}
					startIcon={
						BoltLoading ? (
							<CircularProgress color="secondary" size={"20px"} />
						) : (
							<Box
								component={"img"}
								src={UPDATED_LOGO_ICON_NEW}
								alt="logo"
								sx={{
									height: "20px",
									width: "20px",
								}}
							/>
						)
					}
					endIcon={<ArrowForward />}
					onClick={clickBoltAIReviewRequest}
				>
					{BoltResponseObj && !BoltLoading ? "Re-Run Bolt AI Review" : "Bolt AI Review"}
					<Box
						sx={{
							position: "absolute",
							top: "-15px",
							right: "-15px",
						}}
					>
						<InfoHelper
							title={
								<span style={{ display: "flex", alignItems: "center", gap: "8px" }}>
									<Box
										component={"img"}
										src={UPDATED_LOGO_ICON_NEW}
										alt="logo"
										sx={{
											height: "20px",
											width: "20px",
											filter: "invert(1)",
										}}
									/>
									Bolt AI
								</span>
							}
							body={"StartupOS Bolt AI can review your solutions to see if they are a good fit for your problem."}
						/>
					</Box>
				</Button>
				{BoltResponseObj && !BoltLoading && (
					<>
						<Button
							fullWidth
							sx={{
								width: "100%",
								margin: "8px auto",
								minWidth: "unset",
								borderRadius: "10px",
							}}
							endIcon={<OpenInNew />}
							onClick={clickOpenReport}
						>
							View Bolt AI Report
						</Button>
						<BoltResponseModal onClose={() => setshowBoltResponse(false)} open={showBoltResponse} responseObj={BoltResponseObj} />
					</>
				)}
			</Grid>
			<Grid
				item
				md={1}
				sx={{
					display: { xs: "none", md: "flex" },
					justifyContent: "center",
					alignItems: "center",
					height: "inherit",
				}}
			>
				<ArrowForwardIcon sx={{ fill: theme.palette.secondary.dark }} />
			</Grid>
			<Grid item xs={12} md={6} sx={{ display: "flex", height: "inherit" }}>
				<Grid container spacing={1}>
					<Grid item xs={12} md={12} sx={{ display: "flex", gap: 2, flexDirection: "column" }}>
						{problem?.solution?.map((item, id) => {
							return (
								<SolutionInput
									label="Solution"
									value={conditionalReturn(id)}
									fullWidth
									multiline
									onChange={(e) => handleInputChange(e, index - 1, id, `solution${index}`)}
									onBlur={(e) => handleBlur(e)}
									error={!!errorMessages && !problemInputValid(conditionalReturn(id))}
									helperText={!!errorMessages && !problemInputValid(conditionalReturn(id)) ? errorMessages : ""}
								/>
							);
						})}
					</Grid>
				</Grid>
			</Grid>

			<Grid item xs={12} md={12} sx={{ mt: 1 }}>
				<Button
					variant="nofill"
					disabled={problem?.solution?.length === 5 ? true : false}
					startIcon={<AddIcon />}
					sx={{ width: "max-content", float: "right" }}
					onClick={() => handleSolutionAddition(problem, `problem${index}`)}
				>
					Add another solution
				</Button>
			</Grid>
			<Dialog
				maxWidth={"sm"}
				open={confirmOpen}
				onClose={() => setConfirmOpen(false)}
				PaperProps={{
					sx: {
						borderRadius: "20px",
						p: 4,
					},
				}}
			>
				<DialogTitle sx={{ px: 0, pt: 0 }}>
					<Box
						sx={{
							display: "flex",
							flex: 1,
							justifyContent: "space-between",
						}}
					>
						<img src={BELL_RINGING} />
						<Close sx={{ cursor: "pointer" }} onClick={() => setConfirmOpen(false)} />
					</Box>
				</DialogTitle>
				<DialogContent sx={{ px: 0 }}>
					<Typography variant={"Display/xs/Semibold"} component={"p"} sx={{ mb: 1 }}>
						Bolt Has A Report Already
					</Typography>
					<Typography variant={"Text/md/Regular"} component={"p"}>
						You have a report from Bolt already. If you run another it will replace the current one. We recommend you download the current report if
						you do not want to lose it.
					</Typography>
				</DialogContent>
				<DialogActions sx={{ p: 0 }}>
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							justifyContent: "flex-end",
							gap: 1,
						}}
					>
						<Button onClick={requestBoltEval} variant="RoundedTextMuted" sx={{ minWidth: "unset", maxWidth: "unset", width: "auto" }}>
							Replace it
						</Button>
						<Button variant={"PurpleRounded"} onClick={handleDownload} sx={{ minWidth: "unset", maxWidth: "unset", width: "auto" }}>
							Download Report and Run Bolt
						</Button>
					</Box>
				</DialogActions>
			</Dialog>
		</Grid>
	);
}
