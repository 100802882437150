import React from "react";
import { Grid, Button, Box, Stack, Typography } from "@mui/material";
import LoadingButton from "../../common/Loadingbutton";
import { useHistory } from "react-router-dom";
import ROUTES_PATH from "../../constants/routes";

export default function InvalidEmail() {
  const history = useHistory();

  const handleBackToAccountSetup = () => {
    history.push(ROUTES_PATH.REQUEST_FORM);
  };

  const handleLogin = () => {
    history.push(ROUTES_PATH.LOG_IN);
  };

  return (
    <Stack alignItems="center" justifyContent="center">
      <Grid container alignItems="center" justifyContent="center">
        <Grid item xs={12} sm={10} md={6} lg={6} sx={{ px: 2 }}>
          <Box display="grid" justifyContent="center">
            <Typography variant="headline1">Email Already Exists</Typography>
          </Box>
          <Box display="grid" sx={{ textAlign: "center" }}>
            <Typography variant="subhead1" sx={{ my: 3 }}>
              This email address is already associated with an existing
              StartupOS account and cannot be used to sign on.
            </Typography>

            <Typography variant="subhead1">
              Go back to Account Setup and provide a different email address or
              log in to the existing account.
            </Typography>
          </Box>
          <Box display="grid" align="center">
            <Button
              sx={{ my: 4, maxWidth: 1 }}
              onClick={handleBackToAccountSetup}
            >
              Back to Account Setup
            </Button>
            <Button variant="nofill" onClick={handleLogin} sx={{ maxWidth: 1 }}>
              Log in
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Stack>
  );
}
