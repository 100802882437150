import React, { Fragment, useEffect, useState, useCallback } from "react";
import {
  Box,
  Typography,
  CircularProgress,
  useMediaQuery,
  Button,
  Divider,
  Icon,
  Grid,
} from "@mui/material";
import PowerUPLayout from "../../../../layout/PowerUP";
import Sidebar from "../../../../components/Workspace/PowerUp/Sidebar";
import BreadcrumbsComp from "./Components/BreadcrumbsComp";
import {
  getDefaultProblemSolutionScriptData,
  getNotesScriptData,
  getPowerUPData,
  getSidebarContent,
} from "./Functions/common";
import VideoCameraFrontIcon from "@mui/icons-material/VideoCameraFront";
import { INTERVIEWING_ICON, NOTES_ICON } from "../../../../constants/image";
import constants from "../../../../constants";
import Tabs from "../../../../components/Workspace/Tabs";
import CardWrapper from "./Components/CardWrapper";
import theme from "../../../../constants/Theme";
import { useHistory } from "react-router-dom";

import JsxParser from "react-jsx-parser";
import SelectInput from "../../../../common/SelectInput";
import CustomizedDatePicker from "../../../../components/Workspace/PowerUp/Datepicker";
import { DateTextField } from "./styles";
import InterviewScript from "./Components/InterviewScript";
import { useSelector } from "react-redux";
import services from "../../../../services";
import { useDispatch } from "react-redux";
import * as actions from "../../../../modules/actions";
import LoadingButton from "../../../../common/Loadingbutton";
import ROUTES_PATH from "../../../../constants/routes";
import { toastContainer } from "../../../../utils/toast";
import messages from "../../../../constants/messages";
import { TEST_TYPE } from "../../../../constants/powerup-data";
import { FooterWrapper } from "../../../../layout/PowerUP/styles";
import TemporaryDrawer from "../../../../common/Drawer";
import { getCurrentDate } from "../../../../utils/date";
import EditIcon from "@mui/icons-material/Edit";
import { getScriptDescriptionData } from "../../../../utils/commonFunctions";

const InterviewingYourCandidate = () => {
  const [powerupDetails, setPowerupDetails] = useState("");
  const [powerupDetailsLoader, setPowerupDetailsLoader] = useState(false);
  const [SIDEBARDATA, setSidebarData] = useState({});
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("md"));
  const tabitems = constants.EXCERCISE_TAB_ITEMS;
  const [currenttab, setcurrenttab] = useState(tabitems[0].key);
  const [buttonLoading, setButtonLoading] = useState(false);
  const marketTest = useSelector(store => store.powerUp?.marketTest);
  const history = useHistory();
  const marketTestUuid = TEST_TYPE[0].uuid;
  const powerUpId = "4";

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    setPowerupDetailsLoader(true);
    getPowerUPData(powerUpId).then(res => {
      setPowerupDetails(res);
    });
    getSidebarContent(powerUpId).then(res => {
      setSidebarData(res);
    });
    setPowerupDetailsLoader(false);
  }, []);

  const FooterComponent = () => {
    return (
      <Box display={"flex"} justifyContent={"center"}>
        <Button
          onClick={() =>
            history.push(`${ROUTES_PATH.MARKET_TEST}/${marketTest.id}`)
          }
          sx={{ m: 2 }}
        >
          Return to PowerUp Details
        </Button>
      </Box>
    );
  };

  const TabsComponent = () => {
    return (
      <Tabs
        tabitems={tabitems}
        currenttab={currenttab}
        setcurrenttab={setcurrenttab}
        color="primary"
        spacing={0}
        gridColumn={{
          lg: "none",
          md: "none",
          sm: "span 6",
          xs: "span 6",
        }}
        width={1}
      />
    );
  };

  const StatusAndDateComponent = ({
    interviewStatus,
    inviteStatus,
    scheduledDate,
    handleSelectChange,
    handleDateChange,
    handleInviteChange,
  }) => {
    const MobileTitle = () => {
      return (
        <Box sx={{ backgroundColor: "primary.main", color: "secondary.white" }}>
          <Box sx={{ mx: 1, my: 2 }}>
            <Typography variant="overlineLarge">SELECT DATE</Typography>
          </Box>
          <Box
            sx={{
              mx: 1,
              my: 2,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="headline6">
              {getCurrentDate(scheduledDate)}
            </Typography>

            <EditIcon sx={{ color: "secondary.white" }} />
          </Box>
        </Box>
      );
    };
    return (
      <Box>
        {isSmallScreen && (
          <Box sx={{ my: 3 }}>
            <Typography variant="title_medium">
              What is the invite status?
            </Typography>
          </Box>
        )}

        <SelectInput
          label="Invite Status"
          fullWidth
          wrapperstyle={{ my: 2 }}
          onInputChange={handleInviteChange}
          selectedValue={inviteStatus}
          inputKey="invite_status"
          options={constants.INVITE_STATUS}
        />

        {isSmallScreen && (
          <Box sx={{ my: 4 }}>
            <Typography variant="title_medium">
              What is the status of this interview?
            </Typography>
          </Box>
        )}

        <Grid container spacing={2}>
          <Grid item md={7} xs={12}>
            <SelectInput
              label="Interview Status"
              fullWidth
              wrapperstyle={{ my: 2 }}
              onInputChange={handleSelectChange}
              selectedValue={interviewStatus}
              inputKey="interview_status"
              options={constants.INTERVIEW_STATUS}
              sx={{
                "& .MuiOutlinedInput-input": {
                  height: 0,
                },
              }}
            />
          </Grid>

          <Grid item md={5} xs={12}>
            <CustomizedDatePicker
              disabled={!interviewStatus}
              value={
                !scheduledDate
                  ? interviewStatus === "Completed"
                    ? new Date()
                    : scheduledDate
                  : scheduledDate
              }
              label={
                <Typography
                  variant="overlineLarge"
                  sx={{
                    color: isSmallScreen
                      ? "secondary.white"
                      : "secondary.black",
                  }}
                >{` ${
                  interviewStatus === "Completed" ? "Completed" : "Scheduled"
                } Date`}</Typography>
              }
              onChange={e => handleDateChange(e)}
              toolbarComp={isSmallScreen && <MobileTitle />}
              Component={params => (
                <DateTextField
                  {...params}
                  fullWidth
                  variant="outlined"
                  label={"Interview Date"}
                  value={scheduledDate}
                  sx={{
                    width: 1,
                    my: 2,
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
      </Box>
    );
  };

  const EditorComponent = ({
    interviewScript,
    notesData,
    getChildData,
    getChildEventData,
    notesDrawerOpen,
    handleDrawerClose,
    setNotesData,
    saveNotesState,
  }) => {
    const getCustomNotes = data => {
      setNotesData(data);
    };
    return (
      <>
        <Box>
          <InterviewScript
            scriptConfigData={interviewScript}
            readOnly={true}
            hideRestoreOption={true}
            containerStyle={{ pb: 0 }}
          />
          <Divider
            sx={{
              background: `${theme.palette.secondary.outlineXLight}`,
              display: { xs: "none", sm: "none", md: "block" },
            }}
          />
          <Box sx={{ mt: 2, display: { xs: "none", sm: "none", md: "block" } }}>
            <Box sx={{ my: 2 }}>
              <Typography variant="largeTitle">Notes</Typography>
            </Box>

            <InterviewScript
              hideRestoreOption={true}
              scriptConfigData={notesData}
              containerStyle={{ py: 0 }}
              customSaveButton={true}
              getChildData={getChildData}
              getChildEventData={getChildEventData}
            />
          </Box>
        </Box>

        <TemporaryDrawer open={notesDrawerOpen} onClose={handleDrawerClose}>
          <Box sx={{ background: theme.palette.primary.surface3 }}>
            <Box sx={{ p: 2 }}>
              <Box sx={{ my: 2 }}>
                <Typography variant="title_medium">Notes</Typography>
              </Box>
              <Divider sx={{ mb: 2 }} />

              <Box
                sx={theme => ({
                  backgroundColor: "secondary.white",
                  border: `1px solid ${theme.palette.secondary.dark}`,
                  p: 2,
                  borderRadius: theme.shape.standard.borderRadius,
                })}
              >
                <Typography variant="largeTitle">Notes</Typography>
                <InterviewScript
                  hideRestoreOption={true}
                  scriptConfigData={notesData}
                  containerStyle={{ py: 0 }}
                  customSaveButton={true}
                  getCustomNotes={getCustomNotes}
                  saveNotesState={saveNotesState}
                />
              </Box>
            </Box>

            <Box sx={{ my: 2, display: "flex", justifyContent: "center" }}>
              <Button onClick={handleDrawerClose}>Close</Button>
            </Box>
          </Box>
        </TemporaryDrawer>
      </>
    );
  };

  const MainContent = () => {
    const marketTest = useSelector(store => store.powerUp?.marketTest);

    const [interviewScript, setInterviewScript] = useState(
      getScriptDescriptionData(marketTest.powerUpModel.powerUpName)
    );
    const [notesData, setNotesData] = useState(getNotesScriptData({}));
    const [currentModule, setCurrentModule] = useState();
    const [candidateDetails, setCandidateDetails] = useState();
    const [getChildScriptData, setChildScriptData] = useState(false);
    const [interviewStatus, setInterviewStatus] = useState("");
    const [inviteStatus, setInviteStatus] = useState("Sent");
    const [scheduledDate, setScheduledDate] = useState(null);
    const [notesDrawerOpen, setNotesDrawerOpen] = useState(false);
    const [saveNotesState, setSaveNotesState] = useState(false);

    const handleDrawerClose = () => {
      setSaveNotesState(!saveNotesState);
      setNotesDrawerOpen(false);
    };

    const powerupModuleDetails = useSelector(
      store => store?.powerUp?.powerupDetails
    );

    const interviewCandidates = useSelector(
      store => store?.powerUp?.interviewCandidates
    );

    const dispatch = useDispatch();

    const candidateEmail = history.location.state.email;

    useEffect(() => {
      const fetchedPowerupScriptDetails =
        powerupModuleDetails?.data?.moduleStepResponses?.find(
          moduleStep => moduleStep?.isLastStep === true
        );

      if (fetchedPowerupScriptDetails) {
        setCurrentModule({
          ...fetchedPowerupScriptDetails,
          responseId: powerupModuleDetails?.data?.id,
        });
        if (fetchedPowerupScriptDetails?.answers) {
          setInterviewScript(
            JSON.parse(fetchedPowerupScriptDetails?.answers)?.interviewScript
          );

          const candidates = JSON.parse(
            fetchedPowerupScriptDetails?.answers
          )?.candidateRecords;

          const currentNotesScriptData = candidates?.find(
            candidateData => candidateData?.email === candidateEmail
          );

          currentNotesScriptData?.interviewStatus &&
            setInterviewStatus(currentNotesScriptData?.interviewStatus);

          currentNotesScriptData?.scheduledDate &&
            setScheduledDate(currentNotesScriptData?.scheduledDate);

          currentNotesScriptData?.inviteStatus &&
            setInviteStatus(currentNotesScriptData?.inviteStatus);

          currentNotesScriptData?.records &&
            setNotesData(currentNotesScriptData?.records);
        }
      }
    }, [powerupModuleDetails]);

    useEffect(() => {
      const candidate = interviewCandidates?.find(
        candidate => candidate.email === candidateEmail
      );

      setCandidateDetails(candidate);
    }, [candidateEmail]);

    const handleSaveAction = updatedScriptData => {
      const currentModuleAnswers = JSON.parse(currentModule?.answers);

      const currentModuleCandidates = currentModuleAnswers?.candidateRecords;

      const idx = currentModuleCandidates?.findIndex(
        candidate => candidate?.email === candidateDetails?.email
      );

      const currentCandidatePayload = {
        ...candidateDetails,
        interviewStatus,
        scheduledDate,
        inviteStatus,
        records: updatedScriptData,
      };

      let updatedCandidateDetails;

      if (idx !== -1 && currentModuleCandidates) {
        updatedCandidateDetails = [...currentModuleCandidates];
        updatedCandidateDetails[idx] = {
          ...currentCandidatePayload,
        };
      } else {
        updatedCandidateDetails = currentModuleCandidates
          ? [...currentModuleCandidates, currentCandidatePayload]
          : [currentCandidatePayload];
      }

      const answers = {
        ...currentModuleAnswers,
        candidateRecords: updatedCandidateDetails,
      };

      const data = {
        moduleResponseId: currentModule?.responseId,
        stepNo: 2,
        answers: JSON.stringify(answers || currentModuleAnswers),
        isLastStep: true,
        isCompleted: true,
        moduleStepId: currentModule?.moduleStep?.id,
      };

      updateModuleStepResponseApiCall(currentModule.responseId, data);
    };

    const updateModuleStepResponseApiCall = (moduleId, data, cb = () => {}) => {
      setButtonLoading(true);
      services
        .storeModuleStepResponses(moduleId, data)
        .then(result => {
          setButtonLoading(false);
          toastContainer(messages.SAVE_SUCCESS, "success");

          dispatch(
            actions.setPowerupDetails({
              id: "build-script",
              data: result?.data?.data,
            })
          );
          cb();
        })
        .catch(error => {
          toastContainer(messages.SAVE_ERROR, "error");
          setButtonLoading(false);
          console.log(error);
        });
    };

    const getChildData = useCallback(
      async data => {
        if (currentModule) {
          handleSaveAction(data);
        }
      },
      [getChildScriptData]
    );

    const handleMarketTestComplete = () => {
      const uuid = marketTestUuid;
      services
        .completeMarketTest(uuid)
        .then(result => {
          if (result?.data?.code == 200) {
            console.log("Completed market test");
          }
        })
        .catch(err => {
          console.log("Error completing market test", err);
        });
    };

    const handleSelectChange = event => {
      const value = event.target.value;
      setInterviewStatus(value);
    };

    const handleInviteChange = event => {
      const value = event.target.value;
      setInviteStatus(value);
    };

    const handleDateChange = value => {
      setScheduledDate(value);
    };

    const FooterComponentPC = () => {
      return (
        <>
          <Box
            justifyContent={"center"}
            sx={{ display: { xs: "flex", sm: "flex", md: "none" }, p: 2 }}
            gap={2}
          >
            <Button variant="outlined" onClick={() => setNotesDrawerOpen(true)}>
              Notes
            </Button>

            <LoadingButton
              loading={buttonLoading}
              onClick={() => {
                handleSaveAction(notesData);
                handleMarketTestComplete();
              }}
              loadingStyles={{ px: 7 }}
            >
              Save Changes
            </LoadingButton>
          </Box>
          <Divider
            sx={{
              background: `${theme.palette.secondary.outlineXLight}`,
              mt: 3,
              display: { xs: "none", sm: "none", md: "block" },
            }}
          />
          <Box
            justifyContent={"right"}
            sx={{ display: { xs: "none", sm: "none", md: "flex" }, mt: 3 }}
          >
            <Box
              sx={{
                mr: 1,
              }}
            >
              <Button
                variant="outlined"
                onClick={() =>
                  history.push(`${ROUTES_PATH.MARKET_TEST}/${marketTest.id}`)
                }
              >
                Return to PowerUp Details
              </Button>
            </Box>
            <Box>
              <LoadingButton
                loading={buttonLoading}
                onClick={() => {
                  setChildScriptData(!getChildScriptData);
                  handleMarketTestComplete();
                }}
                loadingStyles={{ px: 7 }}
              >
                Save Changes
              </LoadingButton>
            </Box>
          </Box>
        </>
      );
    };

    const handleCandidateProfile = () => {
      dispatch(actions.setCandidateDetails(candidateDetails));

      history.push(
        `/workspace/browse-candidates/view-profile/${candidateDetails.id}`
      );
    };

    return (
      <Box>
        {isSmallScreen ? (
          <Box display={"flex"} alignItems={"center"} sx={{ mt: 3 }} gap={3}>
            <Icon
              sx={{
                borderRadius: "50%",
                borderColor: "primary.main",
                borderStyle: "solid",
                p: 1,
              }}
            >
              <VideoCameraFrontIcon sx={{ color: "primary.main" }} />
            </Icon>

            <Typography variant="headline5" color="primary">
              Interviewing Your Candidate
            </Typography>
          </Box>
        ) : (
          <Box display={"flex"} alignItems={"center"} sx={{ mt: 3 }}>
            <img src={INTERVIEWING_ICON} />

            <Typography variant="title_medium" sx={{ ml: 2 }}>
              Interviewing Your Candidate
            </Typography>
          </Box>
        )}
        <Box sx={{ mt: 3 }}>
          <Typography variant="subhead1">
            <JsxParser
              components={[Typography]}
              jsx={
                "Scan the participant profile before starting the call, then take a moment to <b>relax</b>. Remember, you’re not here to sell your idea...you’re on a search for <b>truth.</b>"
              }
            />
          </Typography>
        </Box>

        <Box display={{ xs: "block", lg: "none" }}>
          <StatusAndDateComponent
            interviewStatus={interviewStatus}
            scheduledDate={scheduledDate}
            handleSelectChange={handleSelectChange}
            handleDateChange={handleDateChange}
            inviteStatus={inviteStatus}
            handleInviteChange={handleInviteChange}
          />
        </Box>
        <Box
          sx={theme => ({
            [theme.breakpoints.up("md")]: {
              p: 3,
              mt: 3,
            },
            [theme.breakpoints.down("md")]: {
              background: theme.palette.text.background,
              p: 2,
              mb: 10,
            },
            //border: `1px solid ${theme.palette.secondary.dark}`,
            borderRadius: theme.shape.standard.borderRadius,
            boxShadow: theme.palette.primary.elevationLight3,
          })}
        >
          <Box display={{ xs: "none", lg: "block" }}>
            <StatusAndDateComponent
              interviewStatus={interviewStatus}
              scheduledDate={scheduledDate}
              handleSelectChange={handleSelectChange}
              handleDateChange={handleDateChange}
              inviteStatus={inviteStatus}
              handleInviteChange={handleInviteChange}
            />
          </Box>

          <Box sx={{ my: 2 }}>
            <Typography variant="title_medium">Interview Details</Typography>
          </Box>

          <Box
            sx={theme => ({
              [theme.breakpoints.up("md")]: {
                //border: `1px solid ${theme.palette.secondary.dark}`,
                borderRadius: theme.shape.standard.borderRadius,
                p: 3,
                backgroundColor: "secondary.white",
                boxShadow: theme.palette.primary.elevationLight3,
              },
            })}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box
                component="img"
                src={candidateDetails?.avatar}
                sx={{ borderRadius: "50%" }}
              ></Box>

              <Box>
                <Box sx={{ ml: 2 }}>
                  <Typography
                    variant="title_large"
                    sx={{ color: "secondary.black" }}
                  >
                    {candidateDetails?.name}
                  </Typography>
                </Box>
                <Box sx={{ ml: 2 }}>
                  <Typography
                    variant="lable_large"
                    sx={{ color: "secondary.black" }}
                  >
                    {candidateDetails?.email}
                  </Typography>
                </Box>
              </Box>
              {!isSmallScreen && (
                <Box display={"flex"} flex={1}>
                  <Box sx={{ ml: "auto", order: 2 }}>
                    <Button
                      sx={{ width: "max-content" }}
                      variant="contained"
                      onClick={handleCandidateProfile}
                    >
                      View Profile
                    </Button>
                  </Box>
                </Box>
              )}
            </Box>

            <Box sx={{ my: 2 }}>
              <Grid container sx={{ mt: 2 }}>
                <Grid item xs={5}>
                  <Typography variant={"labelLarge"}>PowerUp</Typography>:
                </Grid>

                <Grid item xs={7}>
                  <Typography variant={"bodyMedium"}>
                    {marketTest?.powerUpModel?.powerUpName}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container sx={{ mt: 2 }}>
                <Grid item xs={5}>
                  <Typography variant={"labelLarge"}>Invite Status</Typography>:
                </Grid>

                <Grid item xs={7}>
                  <Typography variant={"bodyMedium"}>Sent</Typography>
                </Grid>
              </Grid>

              <Grid container sx={{ mt: 2 }}>
                <Grid item xs={5}>
                  <Typography variant={"labelLarge"}>Interview Date</Typography>
                  :
                </Grid>

                <Grid item xs={7}>
                  <Typography variant={"bodyMedium"}>
                    {getCurrentDate(scheduledDate)}
                  </Typography>
                </Grid>
              </Grid>

              <Box sx={{ my: 2, display: "flex", justifyContent: "flex-end" }}>
                {isSmallScreen && (
                  <Box>
                    <Button
                      sx={{ width: "max-content" }}
                      variant="contained"
                      onClick={handleCandidateProfile}
                    >
                      View Profile
                    </Button>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
          <Typography variant="title_medium" sx={{ mt: 3, display: "grid" }}>
            Script & Interview Notes
          </Typography>
          <Box
            sx={theme => ({
              //border: `1px solid ${theme.palette.secondary.dark}`,
              borderRadius: theme.shape.standard.borderRadius,
              boxShadow: theme.palette.primary.elevationLight3,
              pt: 2,
              px: 3,
              mt: 3,
            })}
          >
            <Typography variant="headline5" sx={{ mt: 3, display: "grid" }}>
              Script
            </Typography>
            <EditorComponent
              interviewScript={interviewScript}
              getChildData={getChildData}
              getChildEventData={getChildScriptData}
              notesData={notesData}
              notesDrawerOpen={notesDrawerOpen}
              handleDrawerClose={handleDrawerClose}
              setNotesData={setNotesData}
              saveNotesState={saveNotesState}
            />
          </Box>
        </Box>

        <Box sx={{ display: { xs: "block", sm: "block", md: "none" } }}>
          <FooterWrapper isFixed>
            <FooterComponentPC />
          </FooterWrapper>
        </Box>

        <Box sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
          <FooterComponentPC />
        </Box>
      </Box>
    );
  };

  return (
    <PowerUPLayout
      main={
        <Fragment>
          {powerupDetailsLoader ? (
            <Box justifyContent={"center"} sx={{ display: "flex", mt: 5 }}>
              <CircularProgress />
            </Box>
          ) : (
            <CardWrapper heading={"IDEA VALIDATION INTERVIEW"}>
              <MainContent />
            </CardWrapper>
          )}
        </Fragment>
      }
      sidebar={
        isSmallScreen ? (
          <Box display={"flex"} flexDirection={"column"}>
            <Box sx={{ my: 3 }}>
              <TabsComponent />
            </Box>
            <Sidebar sidebar_content={powerupDetails} />
          </Box>
        ) : (
          <Sidebar sidebar_content={powerupDetails} />
        )
      }
      breadcrumbs={<BreadcrumbsComp powerupDetails={powerupDetails} />}
      tab={currenttab}
      cardWrapped
      //footerComponent={isSmallScreen && footerComponent}
    />
  );
};

export default InterviewingYourCandidate;
