import { Box } from "@mui/material";
import React, { useEffect } from "react";
import useDimensions from "react-cool-dimensions";

function ToolTwoCol({ left, right }) {
	const { observe, width } = useDimensions({
		onResize: ({ width }) => {},
	});
	useEffect(() => {
		observe();
	}, [observe]);

	return (
		<Box ref={observe} sx={{ display: "flex", alignItems: "flex-start", gap: "1.875rem", alignSelf: "stretch", flexWrap: "wrap" }}>
			<Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "1.875rem", flex: 1 }}>{left}</Box>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "flex-start",
					gap: "1.875rem",
					...(width > 1000
						? {
								width: "100%",
								maxWidth: "calc(50% - 1.875rem)",
								alignSelf: "stretch",
						  }
						: {
								width: "100%",
								alignSelf: "stretch",
						  }),
				}}
			>
				{right}
			</Box>
		</Box>
	);
}

export default ToolTwoCol;
