import { Typography, Box, Divider, Button, MenuItem, CircularProgress, Alert } from "@mui/material";
import React, { useState, useEffect, Fragment } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import LoopIcon from "@mui/icons-material/Loop";
import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import theme from "../../../constants/Theme";
import constants from "../../../constants";
import { NumberContainer } from "../../../components/Workspace/PowerUp/SummaryStepList/styles";
import * as actions from "../../../modules/actions";
import RatingCard from "../../../components/Workspace/PowerUp/Rating";
import TooltipWrapper from "../../../common/Tooltip";
import JsxParser from "react-jsx-parser";
import RichTextEditor from "../../../common/RichTextEditor";
import MessageHandlerModal from "../../../common/modal/MessageHandler";
import MuiTooltip from "../../../common/MuiTooltip";
import services from "../../../services";
import TemporaryDrawer from "../../../common/Drawer";
import { archiveApiCall, createNewVersionApiCall } from "../../../utils/api";
import ROUTES_PATH from "../../../constants/routes";
import { RICH_TEXT_EDITOR_INLINE_CONTROLS, RICH_TEXT_EDITOR_MAIN_CONTROLS } from "../../../constants/plugin-constants";
import { founderStoryAI } from "../../../utils/AI/founderStory";
import { stateToMarkdown } from "draft-js-export-markdown";
import CopyToProfileModal from "./CopyToProfileModal";

function StorytellerSummary({ powerUpExcercises, handleBackClick, moduleId, setExcerciseConfigs, versionId }) {
	const tabitems = constants.SUMMARY_TAB_ITEMS;
	const history = useHistory();

	const [currenttab, setcurrenttab] = useState(tabitems[0].key);
	const [archiveConfirmDialog, setArchiveConfirmDialog] = useState(false);
	const [archiveDrawerOpen, setArchiveDrawerOpen] = useState(false);
	const [AIResponse, setAIResponse] = useState(null);
	const [AIResponseLoading, setAIResponseLoading] = useState(false);

	useEffect(() => {
		setTimeout(() => {
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: "smooth",
			});
		}, 100);
		setcurrenttab(tabitems[0].key);
		getAIResponse();
	}, []);

	const getExerciseTitle = (stepNumber) => {
		let storyTellercontents = constants.STORYTELLER_CONTENTS;

		if (stepNumber === 1) {
			return storyTellercontents[0].title;
		} else if (stepNumber === 2) {
			return storyTellercontents[1].title;
		} else {
			return storyTellercontents[2].title;
		}
	};
	const archiveCancelDialogHandler = () => {
		setArchiveConfirmDialog(false);
	};

	const archiveSuccessDialogHandler = () => {
		setArchiveConfirmDialog(false);
		let payload = {
			responseId: versionId,
			status: true,
		};

		archiveApiCall(payload, history);
	};
	const createNewVersion = (moduleId, uuid, history) => {
		setExcerciseConfigs(null);

		createNewVersionApiCall(moduleId, uuid, history);
	};

	const getAIResponse = async () => {
		setAIResponseLoading(true);
		let profileObj = await services.getUserProfile();
		profileObj = profileObj?.data?.data;
		console.log(profileObj);
		const answers = powerUpExcercises
			.map((item) => {
				return item?.answers?.[0];
			})
			.join(" ");
		const response = await founderStoryAI({
			companyName: profileObj?.companyModel?.companyName,
			founderName: profileObj?.name,
			tagline: profileObj?.headline,
			answers: answers,
		});
		setAIResponse(response);
		setAIResponseLoading(false);
	};

	return (
		<>
			<Box flexGrow={1} flexDirection={"column"}>
				<Box
					sx={{
						mt: 3,
					}}
				>
					<Typography variant="largeTitle">Summary</Typography>
				</Box>

				<Box display={"grid"} sx={{ mt: 1 }}>
					<Typography variant="subhead1">
						Congratulations! Below is a summary of the work you’ve completed in this PowerUp. To edit or review your completed steps, select the
						steps above to navigate back. You can take this PowerUp as many times as you’d like. Don’t forget to get feedback on your completed
						work.
					</Typography>
				</Box>
				{/*Main Container*/}
				<Box
					sx={{
						mt: 3,
					}}
				>
					<Typography variant="title_medium">Your Answers</Typography>
				</Box>
				<Box
					sx={(theme) => ({
						borderRadius: theme.shape.standard5.borderRadius,
						p: 4,
						mt: 3,
						background: theme.palette.secondary.greyBg,

						[theme.breakpoints.down("md")]: {
							borderRadius: "0px",
						},
					})}
				>
					{" "}
					{powerUpExcercises.map((item, idx) => {
						return (
							<Box key={idx} sx={{ mt: 3 }}>
								<Box sx={{ display: "flex", alignItems: "center" }}>
									<NumberContainer bordercolor={theme.palette.secondary.dark} bgColor={theme.palette.secondary.greyBg}>
										<Typography variant="title_medium">0{item.stepNo}</Typography>
									</NumberContainer>
									<Typography sx={{ ml: 2 }} variant="title_medium">
										{getExerciseTitle(item.stepNo)}
									</Typography>
								</Box>
								<Box sx={{ mt: 2 }}>
									<Typography variant="bodyMedium">
										<RichTextEditor
											value={item.answers[0]}
											readOnly
											inlineToolbar={false}
											inlineToolbarControls={[]}
											controls={[]}
											onChange={() => { }}
										/>
									</Typography>
								</Box>
							</Box>
						);
					})}
				</Box>

				{/* The lower content is the AI generated information */}
				{AIResponse && !AIResponseLoading ? (
					<Fragment>
						<Box
							sx={{
								mt: 3,
							}}
						>
							<Typography variant="title_medium" component={"h6"}>
								StartupOS AI Assistant
							</Typography>
							<Box
								sx={{
									display: "flex",
									gap: 2,
								}}
							>
								<Typography variant="title_small" component={"p"}>
									StartupOS AI Assistant has generated a founder story for you based on your profile and answers. Feel free to edit the
									response to make it your own. If you like the response, consider using it as your founder story on your startup profile.
								</Typography>
								<CopyToProfileModal newValue={AIResponse} />
							</Box>
						</Box>
						<Box
							sx={(theme) => ({
								minHeight: "500px",
								border: `1px solid ${theme.palette.secondary.dark}`,
								borderRadius: theme.shape.standard3.borderRadius,
								px: 2,
								mt: 3,
							})}
						>
							<RichTextEditor
								label=""
								value={AIResponse}
								toolbar={true}
								inlineToolbar={false}
								inlineToolbarControls={RICH_TEXT_EDITOR_INLINE_CONTROLS}
								controls={RICH_TEXT_EDITOR_MAIN_CONTROLS}
								readOnly={false}
								onChange={(value) => {
									const inputVal = stateToMarkdown(value.getCurrentContent());
									setAIResponse(inputVal);
								}}
								onSave={() => { }}
								onBlur={() => { }}
								minHeight={"500px"}
								showAutoFocus
							/>
						</Box>
					</Fragment>
				) : !AIResponse && AIResponseLoading ? (
					<Box
						sx={{
							height: "100px",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							gap: 2,
						}}
					>
						<Typography variant="title_medium" component={"h6"}>
							StartupOS AI Assistant is Reviewing Your Answers
						</Typography>
						<CircularProgress />
					</Box>
				) : (
					<Box
						sx={{
							mt: 2,
						}}
					>
						<Alert severity="info">StartupOS AI Assistant did not return any suggestions based on your answers.</Alert>
					</Box>
				)}

				{/* END AI GENERATED INFO */}

				<Divider
					sx={{
						background: (theme) => theme.palette.secondary.dark,
						mt: 6,
						[theme.breakpoints.down("md")]: {
							background: (theme) => theme.palette.secondary.borderLight,
						},
						display: { sm: "none", xs: "none", md: "flex" },
					}}
				/>
				<Box
					sx={{
						mt: 3,
						display: { sm: "none", xs: "none", md: "flex" },
					}}
				>
					{" "}
					<MuiTooltip title="Archive">
						<ArchiveOutlinedIcon
							sx={{
								cursor: "pointer",
								color: "secondary.main",
								p: 1,
								"&:hover": {
									background: "rgba(73, 69, 79, 0.08)",
									borderRadius: "50%",
								},
							}}
							onClick={() => {
								setArchiveConfirmDialog(true);
							}}
						/>
					</MuiTooltip>
					<MuiTooltip title="Take again">
						<LoopIcon
							sx={{
								cursor: "pointer",
								ml: 1,
								color: "secondary.main",
								p: 1,
								"&:hover": {
									background: "rgba(73, 69, 79, 0.08)",
									borderRadius: "50%",
								},
							}}
							onClick={() => {
								createNewVersion(moduleId, "md-story", history);
							}}
						/>
					</MuiTooltip>
					<Box sx={{ ml: "auto" }}>
						<Button
							variant="outlined"
							onClick={() => {
								handleBackClick();
							}}
						>
							Back
						</Button>
					</Box>
					<Box sx={{ ml: 1 }}>
						<Button
							onClick={() => {
								history.push(ROUTES_PATH.STARTUPS_HOME);
							}}
						>
							Done
						</Button>
					</Box>
				</Box>
				{/* This section needs to revisited once feedback functionality implemented. */}
				<Box
					sx={(theme) => ({
						display: { sm: "none", xs: "none", md: "none" },
						background: theme.palette.secondary.white,
						borderRadius: theme.shape.standard.borderRadius,
						p: 3,
						mt: 3,
					})}
				>
					<Typography variant="title_medium">Want feedback?</Typography>
					<Box sx={{ mt: 3 }}>
						<Typography variant="subhead2">
							Your StartupOS Advisor is here to help if you would like feedback for this completed PowerUp.
						</Typography>
					</Box>
					<Box sx={{ textAlign: "center", mt: 4 }}>
						<Button>Submit for feedback</Button>
					</Box>
				</Box>
			</Box>
			<Box
				sx={{
					background: theme.palette.secondary.white,
					position: "fixed",
					bottom: 72,
					width: 1,
					textAlign: "center",
					alignItems: "center",
					py: 2,
					left: 0,
					display: { sm: "flex", xs: "flex", md: "none" },
				}}
			>
				<MoreHorizIcon
					sx={{
						borderRadius: "50%",
						border: `1px solid ${theme.palette.secondary.dark}`,
						mr: 1,
						ml: 2,
						p: 1,
					}}
					onClick={() => {
						setArchiveDrawerOpen(true);
					}}
				/>
				<Button
					variant="outlined"
					onClick={() => {
						handleBackClick();
					}}
				>
					Back
				</Button>
				<Button
					sx={{ ml: 1, mr: 2 }}
					onClick={() => {
						history.push(ROUTES_PATH.STARTUPS_HOME);
					}}
				>
					Done
				</Button>
			</Box>
			<TemporaryDrawer
				open={archiveDrawerOpen}
				onClose={() => {
					setArchiveDrawerOpen(false);
				}}
			>
				<Box sx={{ py: 3 }}>
					<MenuItem
						onClick={() => {
							setArchiveDrawerOpen(false);
							setArchiveConfirmDialog(true);
						}}
					>
						<ArchiveOutlinedIcon
							sx={{
								color: "secondary.main",
								p: 1,
							}}
						/>
						<Typography variant="label_xlarge" sx={{ ml: 2 }}>
							Archive
						</Typography>
					</MenuItem>
					<MenuItem
						onClick={() => {
							createNewVersion(moduleId, "md-story", history);
						}}
					>
						<LoopIcon
							sx={{
								color: "secondary.main",
								p: 1,
							}}
						/>
						<Typography variant="label_xlarge" sx={{ ml: 2 }}>
							Take again
						</Typography>
					</MenuItem>
				</Box>
			</TemporaryDrawer>
			<MessageHandlerModal
				isOpen={archiveConfirmDialog}
				heading="Archive"
				text={"Are you sure you want to archive this?"}
				messageType={"primary"}
				cancelText={"No thanks"}
				okText={"Archive"}
				style={{
					maxWidth: "450px",
				}}
				onOk={archiveSuccessDialogHandler}
				onCancel={archiveCancelDialogHandler}
			/>
		</>
	);
}

const mapStoreToProps = ({ powerUp }) => ({
	powerUpExcercises: powerUp?.powerUpExcercises,
});

const mapDispatchToProps = (dispatch) => ({
	setPowerUpExcercises: (updatedSteps) => dispatch(actions.setPowerUpExcercises(updatedSteps)),
});

export default connect(mapStoreToProps, mapDispatchToProps)(StorytellerSummary);
