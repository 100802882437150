import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { Spinner } from "@twilio-paste/core";
import InfiniteScroll from "react-infinite-scroll-component";
import MessageList from "./MessageList";
import styles from "../../styles";
import { getMessages } from "../../../../Messaging/api";
import { CONVERSATION_PAGE_SIZE } from "../../../../Messaging/constants";
import { getSdkConversationObject } from "../../../../Messaging/conversations-objects";
import { Box, Divider, Typography } from "@mui/material";
import MessageListHeader from "./MessageListHeader";
export async function loadMessages(conversation, addMessage, currentMessages = []) {
	const convoSid = conversation.sid;
	const sidExists = !!currentMessages.filter(({ sid }) => sid === convoSid).length;
	if (!sidExists) {
		const paginator = await getMessages(getSdkConversationObject(conversation)).catch((e) => {
			console.log("Error grabbing conversation object.", e);
		});
		const messages = paginator.items;
		//save to redux
		addMessage(convoSid, messages);
	}
}
const MessagesBox = (props) => {
	const { messages, convo, loadingState, lastReadIndex, addMessage } = props;
	const [hasMore, setHasMore] = useState(messages?.length === CONVERSATION_PAGE_SIZE);
	const [loading, setLoading] = useState(false);
	const [height, setHeight] = useState(0);
	const [paginator, setPaginator] = useState(null);
	const listRef = useRef(null);
	const sdkConvo = useMemo(() => getSdkConversationObject(convo), [convo.sid]);
	useEffect(() => {
		if (!messages && convo && !loadingState) {
			loadMessages(convo, addMessage);
		}
	}, []);
	useLayoutEffect(() => {
		const currentHeight = listRef.current?.clientHeight;
		if (currentHeight && currentHeight > height && loading) {
			// for preventing immediate downloading of the next messages page
			setTimeout(() => {
				setHeight(currentHeight ?? 0);
				setLoading(false);
				listRef.current.scrollTo({
					top: listRef.current.scrollHeight,
					behavior: "smooth",
				});
			}, 2000);
		}
	}, [listRef.current?.clientHeight]);
	useEffect(() => {
		getMessages(sdkConvo).then((paginator) => {
			setHasMore(paginator.hasPrevPage);
			setPaginator(paginator);
		});
	}, [convo]);
	useEffect(() => {
		if (messages?.length && messages[messages.length - 1].index !== -1) {
			sdkConvo.updateLastReadMessageIndex(messages[messages.length - 1].index);
		}
	}, [messages, convo]);
	const lastConversationReadIndex = useMemo(
		() => (messages?.length && messages[messages.length - 1].author !== localStorage.getItem("username") ? lastReadIndex : -1),
		[lastReadIndex, messages]
	);
	const fetchMore = async () => {
		if (!paginator) {
			return;
		}
		const result = await paginator?.prevPage();
		if (!result) {
			return;
		}
		const moreMessages = result.items;
		setLoading(true);
		setPaginator(result);
		setHasMore(result.hasPrevPage);
		addMessage(convo.sid, moreMessages);
	};
	return (
		<Box
			key={convo.sid}
			id="scrollable"
			paddingRight="space50"
			style={{
				display: "flex",
				flexDirection: "column",

				height: "100%",
			}}
		>
			<InfiniteScroll
				dataLength={messages?.length ?? 0}
				next={fetchMore}
				hasMore={!loading && hasMore}
				// loader={
				// 	<div style={styles.paginationSpinner}>
				// 		<Spinner decorative={false} size="sizeIcon50" title="Loading" />
				// 	</div>
				// }
				scrollableTarget="scrollable"
				style={{
					display: "flex",
					overflow: "hidden",
					flexDirection: "column",
				}}
				inverse={true}
				scrollThreshold="20px"
			>
				<div ref={listRef} style={{}}>
					<MessageList messages={messages ?? []} conversation={convo} participants={props.participants} lastReadIndex={lastConversationReadIndex} />
				</div>
			</InfiniteScroll>
			{/* 
        When the message list is at the top; so "hasMore" is false.
        "hasMore" means 'has more' previous page results. If there are
        no more previous pages, we consider this the 'beginning' of the conversation.
      */}
			{!hasMore && (
				<Box
					sx={{
						mb: "auto",
					}}
				>
					{/* <Box>
            <MessageListHeader conversation={convo} /> 
          </Box> */}
					{/* <Divider sx={{ borderColor: (theme) => theme.palette.sos.gray200 }}>
						<Typography variant="Text/xs/Medium" color={"sos.gray600"} textTransform={"uppercase"}>
							{messages && messages[0] && messages[0].dateCreated
								? new Date(messages[0].dateCreated).toLocaleDateString("en-US", {
										// weekday: "long",
										year: "numeric",
										month: "long",
										day: "numeric",
								  })
								: convo &&
								  convo?.dateUpdated &&
								  new Date(convo.dateUpdated).toLocaleDateString("en-US", {
										// weekday: "long",
										year: "numeric",
										month: "long",
										day: "numeric",
								  })}
						</Typography>
					</Divider> */}
				</Box>
			)}
		</Box>
	);
};
export default MessagesBox;
