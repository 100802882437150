export default {
	entityStyleFn: (entity) => {
		switch (entity.type.toLowerCase()) {
			case 'link':
				return {
					element: 'a',
					attributes: { href: entity.data.url, target: '_blank' },
					style: {}
				}
			case 'image':
				if (entity.data.type === 'video') {
					return {
						//<iframe src={media} style={{ maxWidth: '100%', aspectRatio: '16 / 9', border: 0 }}/>
						element: 'iframe',
						attributes: { src: entity.data.url },
						style: { width: '100%', 'aspect-ratio': '16 / 9', border: 0 }
					}
				} else {
					return {
						element: 'img',
						attributes: { src: entity.data.url, width: entity.data.width, height: entity.data.height },
						style: { width: '100%', 'text-align': 'center' }
					}
				}
			case 'video':
				return {
					element: 'video',
					attributes: { src: entity.data.url, width: entity.data.width, height: entity.data.height },
					style: {}
				}
			default:
				return {
					element: 'div',
					attributes: {},
					style: {}
				}
		}
	},
	inlineStyleFn: (styles) => {
		const highlight = styles.find(key => 'HIGHLIGHT' === key)
		if (highlight) {
			return {
				element: 'mark'
			}
		}
	}
}
