import React, { Fragment, useContext, useEffect, useState } from "react";
import { Box, Divider, Skeleton, Typography, Avatar } from "@mui/material";
import { SEAL_CHECK, NEW_LINKEDIN_ICON } from "../../../../constants/image";
import StartupPublicBioTags from "./Components/StartupPublicBioTags";
import SvgComponent from "../../../../utils/SVGColor";
import StartupPublicFinanceTags from "./Components/StartupPublicFinanceTags";
import StartupPublicFundingTags from "./Components/StartupPublicFundingTags";
import { useSelector } from "react-redux";

function StartupPublicBioCard() {
	const isLoading = useSelector((state) => state?.startupPublicProfileData?.loading);
	const startupData = useSelector((state) => state?.startupPublicProfileData?.startupPublicData);

	return (
		<Box
			sx={(theme) => ({
				borderRadius: "40px",
				backgroundColor: "white",
				boxSizing: "border-box",
				padding: "32px 16px", // default padding
				[theme.breakpoints.between("sm", "md")]: {
					padding: "32px 32px", // padding between sm and md
				},
				[theme.breakpoints.up("md")]: {
					padding: "32px 40px 48px", // padding above md
				},
				boxShadow: "0px 10px 25px rgba(0, 0, 0, 0.05)",
			})}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
				}}
			>
				{/* Logo/Image */}
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						gap: 3,
						my: 3,
					}}
				>
					<Avatar
						src={`${startupData?.logoUrl}`}
						sx={{
							height: "120px",
							width: "120px",
						}}
					/>
				</Box>

				{/* Name */}
				{isLoading ? (
					<Skeleton animation="wave" variant="text" sx={{ fontSize: "60px" }} />
				) : (
					<Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
						<Typography
							component={"div"}
							variant="customValue"
							sx={{
								color: "black",
								fontFamily: "PoppinsSemiBold",
								fontSize: "26px",
								lineHeight: "39px",
							}}
						>
							{startupData?.companyName}
						</Typography>
						{startupData?.linkedInUrl && (
							<img
								src={NEW_LINKEDIN_ICON}
								style={{
									height: "34px",
									width: "34px",
									stroke: "#12B76A",
									cursor: "pointer",
								}}
								onClick={() => window.open(startupData?.linkedInUrl, "_blank")}
							/>
						)}
					</Box>
				)}
				{/* Incorporated */}

				<Box sx={{ display: "flex", gap: 1, alignItems: "center", mb: 3 }}>
					{isLoading ? (
						<Skeleton animation="wave" variant="rounded" sx={{ height: "25px", width: "100%" }} />
					) : (
						<Fragment>
							<Typography
								component={"div"}
								variant="customValue"
								sx={{
									color: "black",
									fontFamily: "PoppinsSemiBold",
									fontSize: "20px",
									lineHeight: "30px",
								}}
							>
								Incorporated? {startupData?.incorporatedStatus}
							</Typography>

							<SvgComponent strokeColor={"#12B76A"} svgUrl={SEAL_CHECK} style={{ height: "34px", width: "34px" }} />
						</Fragment>
					)}
				</Box>

				{/* Tag Line */}
				<Box sx={{ mb: 3 }}>
					{isLoading ? (
						<Skeleton animation="wave" variant="text" sx={{ fontSize: "16px" }} />
					) : (
						<Typography
							component={"div"}
							variant="customValue"
							sx={{
								color: "black",
								fontFamily: "PoppinsMedium",
								fontSize: "16px",
								lineHeight: "24px",
							}}
						>
							{startupData?.tagline}
						</Typography>
					)}
				</Box>
				<Fragment>
					<Box>{isLoading ? <Skeleton animation="wave" variant="rounded" sx={{ height: "120px", width: 1 }} /> : <StartupPublicBioTags />}</Box>
					<Divider
						sx={{
							color: "#D0D5DD",
							my: 3,
						}}
					/>
					{isLoading ? (
						<Skeleton animation="wave" variant="text" sx={{ fontSize: "40px" }} />
					) : (
						<Typography
							component={"div"}
							variant="customValue"
							sx={{
								color: "black",
								fontFamily: "PoppinsSemiBold",
								fontSize: "20px",
								lineHeight: "30px",
								mb: "12px",
							}}
						>
							Financial
						</Typography>
					)}
					<Box>{isLoading ? <Skeleton animation="wave" variant="rounded" sx={{ height: "120px", width: 1 }} /> : <StartupPublicFinanceTags />}</Box>
					<Divider
						sx={{
							color: "#D0D5DD",
							my: 3,
						}}
					/>
					{isLoading ? (
						<Skeleton animation="wave" variant="text" sx={{ fontSize: "40px" }} />
					) : (
						<Typography
							component={"div"}
							variant="customValue"
							sx={{
								color: "black",
								fontFamily: "PoppinsSemiBold",
								fontSize: "20px",
								lineHeight: "30px",
								mb: "12px",
							}}
						>
							Funding
						</Typography>
					)}
					<Box>{isLoading ? <Skeleton animation="wave" variant="rounded" sx={{ height: "120px", width: 1 }} /> : <StartupPublicFundingTags />}</Box>
				</Fragment>
			</Box>
		</Box>
	);
}

export default StartupPublicBioCard;
