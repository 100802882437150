import React from "react";
import { Box, Typography } from "@mui/material";
import GridViewIcon from "@mui/icons-material/GridView";

import DashboardLayout from "../../Dashboard/Layout";
import WorkspaceNavbar from "../Navbar";
import { PAGE_HOME_ICON } from "../../../../constants";
import HomeTourIcon from "../Assets/new-home-tour.png";
import { TourTooltip } from "../../../Profile/ProfileContent";

export default function WorkspaceView({ activeKey, content }) {
	return (
		<Box
			sx={{
				px: {
					lg: 8,
					md: 4,
					xs: 2,
				},
				pb: "30px",
				display: "flex",
				flexDirection: "column",
				gap: "32px",
				maxWidth: "1920px", // Figma specifies max content width
				margin: "0 auto",
			}}
		>
			<Box display="flex" alignItems="center">
				{/* <GridViewIcon sx={{ fontSize: 32 }} /> */}
				<Box sx={{ width: "30px" }} component={"img"} src={PAGE_HOME_ICON} alt="home" />
				<Typography variant="growth_card_subtitle" color="secondary.black" sx={{ marginLeft: 1, marginRight: 1 }}>
					Home
				</Typography>
				<TourTooltip title="Click to restart the tour" placement="top-end">
					<Box sx={{ width: "50px", cursor: "pointer" }} component={"img"} src={HomeTourIcon} alt="tour" />
				</TourTooltip>
			</Box>
			<DashboardLayout topbar={<WorkspaceNavbar activeKey={activeKey} />} main={<Box>{content}</Box>} isFull={true} />
		</Box>
	);
}
