import React, { useState, useEffect, useContext } from "react";
import { Box, Button } from "@mui/material";
import { ARROW_CIRCLE_BROKEN_RIGHT, BOOK_ICON } from "../../../../constants/image";
import { PowerUPContext } from "../../PowerUPController";

const PowerUPDefaultTabGroup = ({ handleSelect, activeTab }) => (
	<Box
		sx={{
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			gap: "2rem",
		}}
	>
		<Button
			onClick={() => handleSelect(0)}
			small={true}
			variant="DS1"
			color={activeTab === 0 ? "secondary" : "gray"}
			startIcon={
				<Box
					component={"img"}
					src={BOOK_ICON}
					sx={{
						...(activeTab === 0 && {
							filter: "invert(1) brightness(2)",
						}),
					}}
				/>
			}
		>
			Learn It
		</Button>
		<Button
			onClick={() => handleSelect(1)}
			small={true}
			variant="DS1"
			color={activeTab === 1 ? "secondary" : "gray"}
			startIcon={
				<Box
					component={"img"}
					src={ARROW_CIRCLE_BROKEN_RIGHT}
					sx={{
						...(activeTab === 1 && {
							filter: "invert(1) brightness(2)",
						}),
					}}
				/>
			}
		>
			Do It
		</Button>
	</Box>
);

const KnowledgeCardTabGroup = ({ handleSelect, activeTab }) => {
	const { powerupDetails } = useContext(PowerUPContext);

	return (
		<Box
			sx={{
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				gap: "2rem",
			}}
		>
			<Button onClick={() => handleSelect(0)} small={true} variant="DS1" color={activeTab === 0 ? "secondary" : "gray"}>
				Details
			</Button>
			{powerupDetails?.knowledgeTips && (
				<Button onClick={() => handleSelect(1)} small={true} variant="DS1" color={activeTab === 1 ? "secondary" : "gray"}>
					Power Tips
				</Button>
			)}
			{powerupDetails?.knowledgeInspiration && (
				<Button onClick={() => handleSelect(2)} small={true} variant="DS1" color={activeTab === 2 ? "secondary" : "gray"}>
					Inspiration
				</Button>
			)}

			{/*<Button
			onClick={() => handleSelect(3)}
			small={true}
			variant="DS1"
			color={activeTab === 1 ? "secondary" : "gray"}
		>
			Reviews
		</Button>*/}
		</Box>
	);
};

function TabBar({ selectedTabIndex, onSelect, tabGroup, details }) {
	const [activeTab, setactiveTab] = useState(null);

	useEffect(() => {
		setactiveTab(selectedTabIndex);
	}, [selectedTabIndex]);

	const handleSelect = (index) => {
		if (typeof onSelect === "function") onSelect(index);
	};

	if (tabGroup === "knowledge") {
		return <KnowledgeCardTabGroup handleSelect={handleSelect} activeTab={activeTab} />;
	} else {
		return <PowerUPDefaultTabGroup handleSelect={handleSelect} activeTab={activeTab} />;
	}
}

export default TabBar;
