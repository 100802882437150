import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

export const Accordion = styled(props => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme, disabled }) => ({
  ":root": {
    ".Mui-disabled": {
      backgroundColor: "none",
    },
  },

  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

export const AccordionSummary = styled(props => (
  <MuiAccordionSummary {...props} />
))(({ theme, disabled }) => ({
  backgroundColor: "#FCFCFC",

  flexDirection: "row",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(180deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
    alignItems: "center",
  },
}));

export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(4),
  [theme.breakpoints.down("md")]: {
    padding: theme.spacing(2),
  },
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));
export const SummarytAccordionDetails = styled(MuiAccordionDetails)(
  ({ theme }) => ({
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),

    //[theme.breakpoints.down("md")]: {
    //  padding: theme.spacing(2),
    //},
    borderTop: "1px solid rgba(0, 0, 0, .125)",
  })
);
