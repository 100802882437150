import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import { getListOfSavedFilterApiCall } from "../../../containers/Investor/Dashboard/Functions/common";
import { setInvestorDashboardData } from "../../../modules/actions";
import services from "../../../services";
import { getTeamMemberValuesByFilterKeys } from "../../../utils/commonFunctions";
import SavedSearchesDetailsComponent from "./SavedSearchesDetails";

const SavedSearchesComponent = ({ open }) => {
	const [expanded, setExpanded] = useState([]);
	const [cohortList, setCohortList] = useState([]);

	const dispatch = useDispatch();
	const savedSearchesData = useSelector((state) => state?.investorDashboard?.savedSearchesData) || [];
	const getCohortsList = async () => {
		const list = await services.getCohorts();
		setCohortList(list.data?.data || []);
	};
	useEffect(() => {
		if (cohortList.length === 0) {
			getCohortsList();
		}
	}, [cohortList]);

	useEffect(() => {
		if (open) {
			getListOfSavedFilterApiCall()
				.then((result) => {
					const data = result?.data?.data;
					data.map((dataItem, idx) => {
						let updatedFilterArray = [];

						Object.keys(dataItem?.searchFilters).map((key) => {
							if (key === "foundedStartYear" && !dataItem?.searchFilters["foundedYearTrailingRange"]) {
								let foundedStartYear = dataItem?.searchFilters[key];
								let foundedEndYear = dataItem?.searchFilters["foundedEndYear"];
								if (foundedStartYear !== null && foundedEndYear !== null) {
									updatedFilterArray.push({
										type: "foundedYear",
										foundedStartYear: foundedStartYear,
										foundedEndYear: foundedEndYear,
										checkboxValue: `${foundedStartYear} - ${foundedEndYear}`,
									});
								}
							} else if (key === "fundingRaisedMin") {
								let fundingRaisedMin = dataItem?.searchFilters[key];
								let fundingRaisedMax = dataItem?.searchFilters["fundingRaisedMax"];
								if (fundingRaisedMax !== null && fundingRaisedMin !== null) {
									updatedFilterArray.push({
										type: "fundingRaised",
										fundingRaisedMin: fundingRaisedMin,
										fundingRaisedMax: fundingRaisedMax,
										checkboxValue: `${Intl.NumberFormat("en-US", {
											notation: "compact",
											maximumFractionDigits: 1,
										}).format(Number(fundingRaisedMin))} - ${Intl.NumberFormat("en-US", {
											notation: "compact",
											maximumFractionDigits: 1,
										}).format(Number(fundingRaisedMax))}`,
									});
								}
							} else if (key === "askMin") {
								let askMin = dataItem?.searchFilters[key];
								let askMax = dataItem?.searchFilters["askMax"];
								if (askMax !== null && askMin !== null) {
									updatedFilterArray.push({
										type: "ask",
										askMin: askMin,
										askMax: askMax,
										checkboxValue: `${Intl.NumberFormat("en-US", {
											notation: "compact",
											maximumFractionDigits: 1,
										}).format(Number(askMin))} - ${Intl.NumberFormat("en-US", {
											notation: "compact",
											maximumFractionDigits: 1,
										}).format(Number(askMax))}`,
									});
								}
							} else if (key === "minMrr") {
								let minMrr = dataItem?.searchFilters[key];
								let maxMrr = dataItem?.searchFilters["maxMrr"];
								if (maxMrr !== null && minMrr !== null) {
									updatedFilterArray.push({
										type: "mrr",
										minMrr: minMrr,
										maxMrr: maxMrr,
										checkboxValue: `${Intl.NumberFormat("en-US", {
											notation: "compact",
											maximumFractionDigits: 1,
										}).format(Number(minMrr))} - ${Intl.NumberFormat("en-US", {
											notation: "compact",
											maximumFractionDigits: 1,
										}).format(Number(maxMrr))}`,
									});
								}
							} else if (key === "minRunway") {
								let minRunway = dataItem?.searchFilters[key];
								let maxRunway = dataItem?.searchFilters["maxRunway"];
								if (maxRunway !== null && minRunway !== null) {
									updatedFilterArray.push({
										type: "runway",
										minRunway: minRunway,
										maxRunway: maxRunway,
										checkboxValue: `${Intl.NumberFormat("en-US", {
											notation: "compact",
											maximumFractionDigits: 1,
										}).format(Number(minRunway))} - ${Intl.NumberFormat("en-US", {
											notation: "compact",
											maximumFractionDigits: 1,
										}).format(Number(maxRunway))}`,
									});
								}
							} else if (key === "minScore") {
								let minScore = dataItem?.searchFilters[key];
								let maxScore = dataItem?.searchFilters["maxScore"];
								if (maxScore !== null && minScore !== null) {
									updatedFilterArray.push({
										type: "ask",
										minScore: minScore,
										maxScore: maxScore,
										checkboxValue: `${Intl.NumberFormat("en-US", {
											notation: "compact",
											maximumFractionDigits: 1,
										}).format(Number(minScore))} - ${Intl.NumberFormat("en-US", {
											notation: "compact",
											maximumFractionDigits: 1,
										}).format(Number(maxScore))}`,
									});
								}
							} else if (
								key === "financings" ||
								key === "industries" ||
								key === "stages" ||
								key === "businessModel" ||
								key === "revenueModel" ||
								key === "productOffering" ||
								key === "incorporatedStatus" ||
								key === "pitchDeckAvailable" ||
								key === "isPremiumPlanAccess"
							) {
								let filterArray = dataItem?.searchFilters && dataItem?.searchFilters[key];
								filterArray &&
									filterArray.map((filterItem) => {
										updatedFilterArray.push({
											type: key,
											checkboxValue: filterItem,
										});
									});
							} else if (key === "startupsJoined") {
								let filterArray = dataItem?.searchFilters && dataItem?.searchFilters[key];

								filterArray &&
									updatedFilterArray.push({
										type: key,
										checkboxValue: `${filterArray} days`,
									});
							} else if (key === "teamMemberTags") {
								let filterArray = dataItem?.searchFilters[key];
								filterArray.map((filterItem) => {
									updatedFilterArray.push({
										type: key,
										key: filterItem,
										checkboxValue: getTeamMemberValuesByFilterKeys(filterItem),
									});
								});
							} else if (key === "states") {
								let filterArray = dataItem?.searchFilters[key];

								filterArray.map((filterItem) => {
									updatedFilterArray.push({
										type: "location",
										checkboxValue: filterItem,
									});
								});
							} else if (key === "foundedYearTrailingRange" && dataItem?.searchFilters["foundedYearTrailingRange"]) {
								let foundedStartYear = dataItem?.searchFilters["foundedStartYear"];
								let foundedEndYear = dataItem?.searchFilters["foundedEndYear"];
								if (foundedStartYear !== null && foundedEndYear !== null) {
									updatedFilterArray.push({
										type: "foundedYearTrailingRange",
										foundedStartYear: foundedStartYear,
										foundedEndYear: foundedEndYear,
										checkboxValue: dataItem?.searchFilters[key],
									});
								}
							} else if (key === "cohorts" && dataItem?.searchFilters["cohorts"] && dataItem?.searchFilters["cohorts"].length > 0) {
								updatedFilterArray.push({
									type: "cohorts",
									cohortIds: dataItem?.searchFilters["cohorts"],
									checkboxValue: cohortList
										.filter((cohort) => dataItem?.searchFilters["cohorts"].includes(cohort.id))
										.map((item) => item.name),
								});
							}
						});

						let filterObj = {
							savedFilterArray: updatedFilterArray,
							name: dataItem?.name,
							count: dataItem?.count,
							userId: dataItem?.userId,
							id: dataItem?.id,
							isEmailNotificationEnabled: dataItem?.isEmailNotificationEnabled || false,
						};

						let uniqueArray = savedSearchesData;

						let existingObjIndex = uniqueArray.findIndex((obj) => obj.id === filterObj.id);

						if (existingObjIndex >= 0) {
							uniqueArray[existingObjIndex] = filterObj;
						} else {
							uniqueArray.push(filterObj);
						}
						let sortedList = uniqueArray.sort((a, b) => b?.id - a?.id);

						dispatch(
							setInvestorDashboardData({
								savedSearchesData: sortedList,
							})
						);
					});
				})
				.catch((error) => {
					console.log(error);
				});
		}
	}, [open]);

	return (
		<Box>
			<SavedSearchesDetailsComponent expanded={expanded} setExpanded={setExpanded} />
		</Box>
	);
};

export default SavedSearchesComponent;
