import { Box } from "@mui/material";
import React from "react";
import { INVESTOR_DASHBOARD_BG1 } from "../../../../constants";
import BenchmarkCompare from "./benchmarkCompare";
import BenchmarkTrend from "./benchmarkTrend";

function BenchmarkGraphHero() {
	return (
		<Box
			sx={{
				py: 12,
				px: 6,
				backgroundImage: `url(${INVESTOR_DASHBOARD_BG1})`,
				backgroundPosition: "100% 10%",
				backgroundSize: "cover",
				backgroundRepeat: "no-repeat",
			}}
		>
			<Box
				sx={{
					display: "flex",
					columnGap: 6,
					rowGap: 2,
					justifyContent: "center",
					alignItems: "stretch",
					flex: 1,
					flexWrap: "wrap",
				}}
			>
				<BenchmarkCompare />
				<BenchmarkTrend />
			</Box>
		</Box>
	);
}

export default BenchmarkGraphHero;
