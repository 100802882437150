import styled from "@emotion/styled";

export const ContentWrapper = styled.div(({ theme }) => {
  return {
    backgroundColor: "var(--gray-bg)",
    border: "1px solid #7C757F",
    boxSizing: "border-box",
    borderRadius: theme.shape.standard.borderRadius,
    padding: theme.spacing(3),
  };
});

export const ContentHeader = styled.div(({ theme }) => {
  return {
    display: "flex",
    alignItems: "center",
  };
});
