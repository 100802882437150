import React from "react";
import { Card, CardContent, CardHeader, CardActions, Box, Typography, Chip, useTheme, Tooltip } from "@mui/material";
import InfoTip from "./InfoTip";
import moment from "moment";
import { partners } from "../../Functions/common";

function ChartCard({ title, subheader, backgroundColor, onChartClick, children, titleTypographyProps, momGrowth, toolTipBody, data = [] }) {
	const theme = useTheme();

	const partnerObj = Array.isArray(data) && data.sort((a, b) => b?.lastModifiedAt - a?.lastModifiedAt)?.[0];

	const displayName = partners.filter((item) => item?.title === partnerObj?.partnerType)?.[0]?.displayName;
	const picture = partners.filter((item) => item?.title === partnerObj?.partnerType)?.[0]?.picture;

	function PartnerDesign() {
		return (
			<Box display="flex" alignItems="flex-start" flexDirection={"column"} gap={"4px"} sx={{ ml: "auto" }}>
				<Box sx={{ width: "70px" }} component={"img"} src={picture} alt="partner" />
				<Typography component={"p"} sx={{ textAlign: "left" }} variant="Text/sm/Medium" color="sos.gray600" {...titleTypographyProps}>
					{`Connected to ${displayName ? displayName : ""} `}
				</Typography>
				<Typography variant="Text/xs/Regular" color="sos.gray600">
					{`${moment(partnerObj?.lastModifiedAt)?.format("MMMM D, YYYY h:mm A")} EST`}{" "}
				</Typography>
			</Box>
		);
	}

	return (
		<Card
			sx={{
				backgroundColor: backgroundColor,
				cursor: "pointer",
				display: "flex",
				justifyContent: "space-between",
				flexDirection: "column",
				height: "100%",
				borderRadius: "20px",
			}}
			onClick={onChartClick}
		>
			<CardHeader
				title={
					<>
						{" "}
						<Box display="flex" alignItems="center" gap={1}>
							<Typography variant="Text/sm/Regular" color="sos.gray600" {...titleTypographyProps}>
								{title}
							</Typography>
							<InfoTip title={title} bodyText={toolTipBody} />
							{/* Commented temporary needed good UI design as per tim */}
							{/* {typeof momGrowth === "undefined" && partnerObj?.partnerType && <PartnerDesign />} */}
						</Box>
					</>
				}
				subheader={subheader}
				subheaderTypographyProps={{ variant: "poppinsSemiBold30", lineHeight: "38px" }}
				sx={{ flexWrap: "wrap", padding: "20px" }}
				action={
					typeof momGrowth !== "undefined" && (
						<>
							{" "}
							<Chip
								label={
									<Typography variant="poppinsMedium12" color={theme.palette.bidashboard.cardChipText}>
										{`MoM Growth: ${momGrowth} `}
									</Typography>
								}
								size="small"
								sx={{ bgcolor: theme.palette.bidashboard.cardChipBg }}
							/>
							{/* Commented temporary needed good UI design as per tim */}
							{/* <Box sx={{ mt: 2 }}>{partnerObj?.partnerType && <PartnerDesign />}</Box> */}
						</>
					)
				}
			/>
			<CardContent
				sx={{
					padding: "20px",
					pt: 0,
					flex: 1,
					display: "flex",
					flexDirection: "column",
					// minHeight: "370px", //figma value
				}}
			>
				{children}
			</CardContent>
		</Card>
	);
}

export default ChartCard;
