import React, { useState, useEffect } from "react";
import { Typography, Box, List } from "@mui/material";
import MuiSlider from "../../../../../common/MuiSlider/MuiSlider";
import CustomizedYearPicker from "../../../../MentorProfile/YearPicker";
import { useDispatch, useSelector } from "react-redux";
import { setInvestorDashboardData } from "../../../../../modules/actions";
import RoundedSelectInput from "../../../../../common/RoundedSelectInput";
import { ASK_SLIDER_OPTIONS, FUNDING_RAISED_SLIDER_OPTIONS, OPP_DASH_SLIDER_OPTIONS, RUNWAY_SLIDER_OPTIONS, TRAILING_RANGES } from "../../../../../constants";

const FoundedYearComponent = () => {
	const filteredValue = useSelector((state) => state?.investorDashboard?.filteredValue) || [];
	const filterDrawerOpen = useSelector((state) => state?.investorDashboard?.FilterDrawerOpen);
	const dispatch = useDispatch();

	let trailingRangesFilter = filteredValue.filter((filteredItem) => filteredItem.type === "foundedYearTrailingRange");

	let dateFilter = filteredValue.filter((filteredItem) => filteredItem.type === "foundedYear");

	const [startDate, setStartDate] = useState(null);

	const [endDate, setEndDate] = useState(null);
	const [fundingValue, setFundingValue] = useState([0, 0]);
	const [askValue, setAskValue] = useState([0, 0]);
	const [mrrValue, setMrrValue] = useState([0, 0]);
	const [runwayValue, setRunwayValue] = useState([0, 0]);

	const [initialFunding, setInitialFunding] = useState(true);
	const [initialAsk, setInitialAsk] = useState(true);
	const [initialRunway, setInitialRunway] = useState(true);

	const [active, setActive] = useState(1);
	const [trailingRanges, setTrailingRanges] = useState(null);

	useEffect(() => {
		if (dateFilter.length === 0) {
			setStartDate(null);
			setEndDate(null);
		}
	}, [dateFilter]);

	useEffect(() => {
		if (dateFilter.length > 0 && trailingRangesFilter.length === 0) {
			setStartDate(new Date(dateFilter[0]?.foundedStartYear, 1, 1));
			setEndDate(new Date(dateFilter[0]?.foundedEndYear, 1, 1));
		}
		if (trailingRangesFilter.length > 0) {
			setTrailingRanges(trailingRangesFilter[0].checkboxValue);
			setActive(2);
		} else {
			setActive(1);
		}
	}, [filterDrawerOpen]);

	useEffect(() => {
		if (startDate || endDate) {
			let tempStartDate = startDate ? startDate?.getFullYear() : endDate?.getFullYear();
			let tempEndDate = endDate ? endDate?.getFullYear() : startDate?.getFullYear();
			if (!startDate) {
				setStartDate(endDate);
			}
			if (!endDate) {
				setEndDate(startDate);
			}
			let newObj = {
				type: "foundedYear",
				foundedStartYear: tempStartDate,
				foundedEndYear: tempEndDate,
				checkboxValue: `${tempStartDate} -  ${tempEndDate}`,
			};
			const tempFilteredValue = [...filteredValue];
			let index = tempFilteredValue.findIndex((item) => item.type === newObj.type);
			if (index !== -1) {
				tempFilteredValue[index] = newObj;
			} else {
				tempFilteredValue.push(newObj);
			}
			const finalFilteredValue = tempFilteredValue.filter((filteredValue) => filteredValue.type !== "foundedYearTrailingRange");
			setTrailingRanges("");
			dispatch(
				setInvestorDashboardData({
					filteredValue: finalFilteredValue,
				})
			);
		}
	}, [startDate, endDate]);

	useEffect(() => {
		const tempFilteredValue = [...filteredValue];

		if (!initialFunding) {
			let obj = {
				type: "fundingRaised",
				fundingRaisedMin: fundingValue[0],
				fundingRaisedMax: fundingValue[1],
				checkboxValue: `$${Intl.NumberFormat("en-US", {
					notation: "compact",
					maximumFractionDigits: 1,
				}).format(Number(fundingValue[0]))} -  $${Intl.NumberFormat("en-US", {
					notation: "compact",
					maximumFractionDigits: 1,
				}).format(Number(fundingValue[1]))}`,
			};

			let index = tempFilteredValue.findIndex((item) => item.type === obj.type);
			if (index !== -1) {
				tempFilteredValue[index] = obj;
			} else {
				tempFilteredValue.push(obj);
			}
		}
		setInitialFunding(false);

		dispatch(
			setInvestorDashboardData({
				filteredValue: tempFilteredValue,
			})
		);
	}, [fundingValue]);

	useEffect(() => {
		const tempFilteredValue = [...filteredValue];

		if (!initialAsk) {
			let obj = {
				type: "ask",
				askMin: askValue[0],
				askMax: askValue[1],
				checkboxValue: `$${Intl.NumberFormat("en-US", {
					notation: "compact",
					maximumFractionDigits: 1,
				}).format(Number(askValue[0]))} -  $${Intl.NumberFormat("en-US", {
					notation: "compact",
					maximumFractionDigits: 1,
				}).format(Number(askValue[1]))}`,
			};

			let index = tempFilteredValue.findIndex((item) => item.type === obj.type);
			if (index !== -1) {
				tempFilteredValue[index] = obj;
			} else {
				tempFilteredValue.push(obj);
			}
		}
		setInitialAsk(false);

		dispatch(
			setInvestorDashboardData({
				filteredValue: tempFilteredValue,
			})
		);
	}, [askValue]);

	useEffect(() => {
		const tempFilteredValue = [...filteredValue];

		if (!initialAsk) {
			let obj = {
				type: "mrr",
				minMrr: mrrValue[0],
				maxMrr: mrrValue[1],
				checkboxValue: `$${Intl.NumberFormat("en-US", {
					notation: "compact",
					maximumFractionDigits: 1,
				}).format(Number(mrrValue[0]))} -  $${Intl.NumberFormat("en-US", {
					notation: "compact",
					maximumFractionDigits: 1,
				}).format(Number(mrrValue[1]))}`,
			};

			let index = tempFilteredValue.findIndex((item) => item.type === obj.type);
			if (index !== -1) {
				tempFilteredValue[index] = obj;
			} else {
				tempFilteredValue.push(obj);
			}
		}
		setInitialAsk(false);

		dispatch(
			setInvestorDashboardData({
				filteredValue: tempFilteredValue,
			})
		);
	}, [mrrValue]);

	useEffect(() => {
		const tempFilteredValue = [...filteredValue];

		if (!initialRunway) {
			let obj = {
				type: "runway",
				minRunway: runwayValue[0],
				maxRunway: runwayValue[1],
				checkboxValue: `${Intl.NumberFormat("en-US", {
					notation: "compact",
					maximumFractionDigits: 1,
				}).format(Number(runwayValue[0]))} -  ${Intl.NumberFormat("en-US", {
					notation: "compact",
					maximumFractionDigits: 1,
				}).format(Number(runwayValue[1]))}`,
			};

			let index = tempFilteredValue.findIndex((item) => item.type === obj.type);
			if (index !== -1) {
				tempFilteredValue[index] = obj;
			} else {
				tempFilteredValue.push(obj);
			}
		}
		setInitialRunway(false);

		dispatch(
			setInvestorDashboardData({
				filteredValue: tempFilteredValue,
			})
		);
	}, [runwayValue]);

	const handleTrailingChange = (value) => {
		setTrailingRanges(value);
		const tempFilteredValue = [...filteredValue];
		let currentYear = new Date().getFullYear();
		let startYear = Math.ceil(currentYear - 1);
		let obj = {
			type: "foundedYearTrailingRange",
			foundedStartYear: startYear,
			foundedEndYear: currentYear,
			checkboxValue: value,
		};

		let index = tempFilteredValue.findIndex((item) => item.type === obj.type);
		if (index !== -1) {
			tempFilteredValue[index] = obj;
		} else {
			tempFilteredValue.push(obj);
		}

		const finalFilteredValue = tempFilteredValue.filter((filteredValue) => filteredValue.type !== "foundedYear");

		dispatch(
			setInvestorDashboardData({
				filteredValue: finalFilteredValue,
			})
		);
		setStartDate(null);
		setEndDate(null);
	};

	return (
		<Box sx={{ mt: 2 }}>
			<Box>
				<Typography
					sx={{
						color: (theme) => theme.palette.secondary.black,
						width: "fit-content",
					}}
					variant="testHeading"
				>
					Year Founded
				</Typography>
			</Box>
			<List
				sx={{
					display: "flex",
					flexDirection: "row",

					gap: 2,
				}}
			>
				<Box onClick={() => setActive(1)} sx={(theme) => active === 1 && { borderBottom: `3px solid ${theme.palette.primary.primaryPurple}` }}>
					<Typography
						sx={{
							color: (theme) => theme.palette.secondary.black,
							width: "fit-content",
							cursor: "pointer",
							display: "grid",
						}}
						variant="title_medium"
					>
						Custom Dates
					</Typography>
				</Box>
				<Box onClick={() => setActive(2)} sx={(theme) => active === 2 && { borderBottom: `3px solid ${theme.palette.primary.primaryPurple}` }}>
					<Typography
						sx={{
							width: "fit-content",
							color: (theme) => theme.palette.secondary.black,
							cursor: "pointer",
							display: "grid",
						}}
						variant="title_medium"
					>
						Trailing Ranges
					</Typography>
				</Box>
			</List>
			<Box>
				{active === 1 ? (
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							mt: 2,
						}}
					>
						<Typography variant="title_medium" sx={{ mr: 1 }}>
							From
						</Typography>
						<CustomizedYearPicker value={startDate} onChange={setStartDate} filter={true} />
						<Typography variant="title_medium" sx={{ ml: 1, mr: 1 }}>
							To
						</Typography>
						<CustomizedYearPicker value={endDate} onChange={setEndDate} filter={true} />
					</Box>
				) : (
					<RoundedSelectInput
						wrapperstyle={{ width: "50%" }}
						label="Trailing Ranges"
						fullWidth
						onInputChange={(e, type) => handleTrailingChange(e.target.value)}
						// onInput={inputChange}
						// onBlur={handleValidations}
						selectedValue={trailingRanges}
						inputKey="trailingRanges"
						options={TRAILING_RANGES}
					/>
				)}
			</Box>

			<Box
				sx={{
					mt: 4,
				}}
			>
				<Typography
					sx={{
						color: (theme) => theme.palette.secondary.black,
						width: "fit-content",
					}}
					variant="testHeading"
				>
					Funding Raise to Date
				</Typography>

				<MuiSlider
					maxString="3M"
					value={fundingValue}
					setValue={setFundingValue}
					sliderArray={FUNDING_RAISED_SLIDER_OPTIONS}
					min={0}
					max={3000000}
					currency={true}
				/>
			</Box>

			<Box
				sx={{
					mt: 2,
				}}
			>
				<Typography
					sx={{
						color: (theme) => theme.palette.secondary.black,
						width: "fit-content",
					}}
					variant="testHeading"
				>
					Startup Ask Amount
				</Typography>

				<MuiSlider maxString="15M" value={askValue} setValue={setAskValue} sliderArray={ASK_SLIDER_OPTIONS} min={0} max={15000000} currency={true} />
			</Box>

			<Box
				sx={{
					mt: 2,
				}}
			>
				<Typography
					sx={{
						color: (theme) => theme.palette.secondary.black,
						width: "fit-content",
					}}
					variant="testHeading"
				>
					Monthly Revenue (MRR)
				</Typography>

				<MuiSlider value={mrrValue} setValue={setMrrValue} sliderArray={OPP_DASH_SLIDER_OPTIONS} min={0} max={1000000} currency={true} />
			</Box>
			<Box
				sx={{
					mt: 2,
				}}
			>
				<Typography
					sx={{
						color: (theme) => theme.palette.secondary.black,
						width: "fit-content",
					}}
					variant="testHeading"
				>
					Runway in Months
				</Typography>

				<MuiSlider maxString="36" value={runwayValue} setValue={setRunwayValue} sliderArray={RUNWAY_SLIDER_OPTIONS} min={0} max={36} currency={false} />
			</Box>
		</Box>
	);
};

export default FoundedYearComponent;
