import React from "react";

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(error) {
		return { hasError: true };
	}

	componentDidCatch(error, errorInfo) {
		console.error("Error caught by boundary:", error, errorInfo);
	}

	render() {
		if (this.state.hasError) {
			return (
				<div style={{ color: "black" }}>
					{this.props && this.props.messageComp ? this.props.messageComp : "Something went wrong. Please try again later."}
				</div>
			);
		}

		return this.props.children;
	}
}

export default ErrorBoundary;
