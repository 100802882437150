import React, { useState, useEffect, useMemo } from "react";
import { useHistory, generatePath } from "react-router-dom";
import { Typography, CardContent, CardMedia, Box, Grid } from "@mui/material";
import ROUTES_PATH from "../../constants/routes";
import PowerStatus from "../PowerStatus";
import { IN_PROGRESS_ICON, COMPLETED_DEFAULT } from "../../constants/image";
import { CardPowerUp, CardActionsPowerUp, ButtonPowerUp, CardContentPowerUp, BoxPowerUpTitle, BoxPowerUpDetails, TypographyPowerUp } from "./styles";

export default function PowerUpCard({ powerUpData }) {
	const history = useHistory();
	const [isDisabled, setIsDisabled] = useState(false);
	const [powerStatusData, setPowerStatusData] = useState({
		statusFlag: powerUpData?.powerUpStatus,
		statusType: "POWER_UP",
		isStarted: false,
		isCompleted: false,
	});

	const cardClick = cardData => {
		if (cardData.powerupType === "POWERUP_BUILDER") {
			history.push({
				pathname: generatePath(ROUTES_PATH.WORKSPACE_POWERUP_BUILDER_DETAILS, {
					id: cardData.id,
				}),
			});
		} else {
			history.push({
				pathname: generatePath(ROUTES_PATH.WORKSPACE_POWERUP_DETAILS, {
					id: cardData.id,
				}),
			});
		}
	};

	useEffect(() => {
		setPowerStatusData({
			...powerStatusData,
			statusFlag: powerUpData?.powerUpStatus,
			isStarted: powerUpData?.companyTask?.taskStatus === "STARTED" ? true : false,
			isCompleted: powerUpData?.companyTask?.taskStatus === "COMPLETED" ? true : false,
		});
	}, [powerUpData]);

	useEffect(() => {
		if (powerUpData.powerUpStatus === "NOT_READY") {
			setIsDisabled(true);
		} else {
			setIsDisabled(false);
		}
	}, [powerUpData?.powerUpStatus]);

	const taskStatus = () => {
		return (
			<Box
				sx={{
					display: "flex",
					gap: 1,
					// border: `1px solid ${theme.palette.secondary.dark}`,
					// borderRadius: 1,
					width: "120px;",
					height: "20px",
					p: 1,
				}}
			>
				<Box src={powerStatusData.isCompleted ? COMPLETED_DEFAULT : IN_PROGRESS_ICON} component="img" sx={{ height: "20px" }} />
				{powerStatusData.isCompleted ? "Complete" : "In Progress"}
			</Box>
		);
	};

	const taskButtonText = useMemo(() => {
		if (powerStatusData.isCompleted) {
			return "Restart";
		} else if (powerStatusData.isStarted) {
			return "Continue";
		}
		return "Start";
	}, [powerStatusData.isCompleted, powerStatusData.isStarted]);

	return (
		<CardPowerUp size="large">
			<CardContent sx={{ padding: 0 }}>
				<CardMedia component="img" height="184" image={powerUpData.imageUrl} alt={powerUpData.title} />
				<CardContentPowerUp>
					<Box>
						<BoxPowerUpTitle>
							<PowerStatus powerStatusData={powerStatusData} />
							<Typography variant="StartupOS/headline/headline6">{powerUpData.powerUpName}</Typography>
						</BoxPowerUpTitle>
						<BoxPowerUpDetails>
							<TypographyPowerUp variant="StartupOS/body/medium">{powerUpData.shortDescription}</TypographyPowerUp>
						</BoxPowerUpDetails>
					</Box>
				</CardContentPowerUp>
			</CardContent>
			<CardActionsPowerUp>
				<Grid alignItems="center" justify="space-between" container>
					<Grid item xs={6} container>
						{(powerStatusData.isStarted || powerStatusData.isCompleted) && taskStatus()}
					</Grid>
					<Grid item xs={6} container justifyContent="flex-end">
						{!isDisabled && (
							<ButtonPowerUp
								size="small"
								color="primary"
								onClick={() => {
									cardClick(powerUpData);
								}}
							>
								{taskButtonText}
							</ButtonPowerUp>
						)}
						{isDisabled && (
							<Box sx={{ padding: 1 }}>
								<TypographyPowerUp variant="StartupOS/body/medium">COMING SOON</TypographyPowerUp>
							</Box>
						)}
					</Grid>
				</Grid>
			</CardActionsPowerUp>
		</CardPowerUp>
	);
}
