export const REGEX = {
	EMAIL: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
	LOWERCASELETTERS: /[a-z]/,
	UPPERCASELETTERS: /[A-Z]/,
	SPECIAL_CHARACTERS: /[!@#\$%\^\&*\)\(+=._-]/,
	NUMBERS: /[0-9]/,
	ZIP_CODE: /^[0-9]{0,6}$/,
	POSTAL_CODE: /^.*$/,
	// POSTAL_CODE: /^.{1,6}$/,
	COMPANY_NAME: /^.{2,225}$/,
	//COMPANY_NAME: /^[a-zA-Z_0-9,.& ]{2,225}$/,
	LINK_TITLE: /^.{0,500}$/,

	LOCATION: /^[a-zA-Z_0-9,()-.\/|&: ]{2,100}$/,

	POWERUP_INPUT: /(?!\s*[=+@-]|\s)+^[a-zA-Z_0-9, ]{1,30}$/,
	POWERUP_INPUT_LENGTH: /^.{1,30}$/,
	GOAL_INPUT_LENGTH: /^.{1,100}$/,
	FOUNDING_DATE: /^[0-9]{0,4}$/,
	LETTERS: /^[a-zA-Z]/,
	LETTERS_WITH_SPACES: /^[a-zA-Z\s]*$/,
	LETTERS_ONLY: /^[A-Za-z]+$/,
	NUMBERS_ONLY: /^[0-9]+$/,
	QUOTE: /^[a-zA-Z" ']/,
	WEBSITE: /^[http(s?))\://]/,
	STORYTELLER_INPUTBOX: /^[\s\S]{0,2000}$/,
	INPUTBOX_300: /^[^-\s][a-zA-Z0-9\s-!@:#\$%\^\&*\)\(+ =,"'’?._-]{29,299}$/,
	PERSONANAME_INPUTBOX: /^.{3,300}$/,
	ACV_INPUT: /^([1-9])[0-9]{3,9}$/,
	PROBLEM_INPUT_BOX: /^[^-\s][a-zA-Z0-9\s-!@#\$%\^\&*\)\(+ =,"'’?._-]{2,199}$/,
	REMOVE_ARTICLES: /(?:(a|and|the) +)/g,
	TAGS_INPUT: /(?!\s*[=+@-]|\s)+^[a-zA-Z_0-9, ]+$/,
	VALID_URL:
		/((?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
	DESCRIPTION: /^[^-\s][a-zA-Z0-9\s-!@#\$%\^\&*\)\(+ =,"'’?._-]{0,499}$/,
	DECIMAL_INPUT: /^\d+(\.\d+)?$/,
	EMAIL_SUBJECT: /^.{3,80}$/,
	EMAIL_MESSAGE: /^.{3,500}$/,
	NO_SPACE: /^\S*$/,
	BIO_LENGTH: /^.{3,1000}$/,
	VALID_LINKEDIN_COMPANY_URL: /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(company\/.{1,}$)/gm,
	VALID_LINKEDIN_PERSONAL_URL: /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(in\/.{1,}$)/gm,
	US_PHONE: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{3,})$/,
	ZOOM_MEET: /https:\/\/[\w-]*\.?zoom.us\/(j|my)\/[\d\w?=-]+/g,
	PHONE_REGEX: /^(\+1)?[2-9]\d{2}[2-9]\d{6}$/,
	VALID_WEBSITE_REGEX: /^https?:\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?$/,
	AMOUNT: /^\d{1,3}(,\d{3})*(\.\d{2})?$/,
	LINKEDIN_NAME: /^[a-zA-Z0-9-]+\/?$/,
};
