import React from "react";
import { Box, Divider, Grid, Typography, Dialog } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import theme from "../../../../../constants/Theme";
import { SUCCESS_ENVELOPE } from "../../../../../constants/image";

export default function SuccessPopup({
  open,
  close,
  title = "Plugin Times Sent",
  successIcon = SUCCESS_ENVELOPE,
  ContentComponent = () => {},
}) {
  return (
    <>
      <Dialog
        open={open}
        onClose={close}
        sx={{
          display: "flex",
          width: 1,
          flexDirection: "column",

          "& .MuiDialog-paper": {
            width: 1,
            maxWidth: "500px",
            borderRadius: 3,
          },
        }}
      >
        <Box sx={{ display: "flex", width: 1, flexDirection: "column" }}>
          <Grid item xs={12} md={12}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                height: "max-content",
                mt: 2,
              }}
            >
              <Grid item xs={10} md={10}>
                <Typography variant="largeTitle" sx={{ ml: 3 }}>
                  {title}
                </Typography>
              </Grid>

              <Grid
                item
                xs={2}
                md={2}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <CloseIcon
                  sx={{
                    cursor: "pointer",
                    color: theme.palette.secondary.dark,
                    mr: 4,
                  }}
                  onClick={close}
                />
              </Grid>
            </Box>
            <Divider sx={{ mt: 3 }} />
          </Grid>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: 2,
              pt: 4,
              mb: 4,
            }}
          >
            <Box
              src={successIcon}
              component="img"
              sx={{ maxWidth: "120px" }}
            ></Box>
            {ContentComponent()}
          </Box>
        </Box>
      </Dialog>
    </>
  );
}
