import React, { useEffect, useState } from "react";
import {
  Grid,
  IconButton,
  Box,
  Divider,
  TextField,
  TableFooter,
  Checkbox,
  Typography,
  Select,
  Link,
  Popover,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  List,
  InputAdornment,
  Button,
  SwipeableDrawer,
  Chip,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import SelectInput from "../../../../../common/SelectInputMvp";
import MuiTooltip from "../../../../../common/MuiTooltip";
import CustomTooltipWrapper from "../../../../../common/CustomToolTip";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import FeatureDescriptionForm from "./FeatureDescriptionForm";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import SelectChipInput from "../../../../../common/SelectChip";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

export default function CreateMatrixCard({
  answers,
  handleUpdateInput,
  moduleDetails,
  handleAddNewRow,
  newRow,
  setNewRow,
  setAnswers,
  handleSort,
  openDrawer,
  setOpenDrawer,
  handleSortDrawer,
  setSortField,
  handleDeleteRow,
  handleOnBlur,
}) {
  const [openPopOver, setOpenPopOver] = useState(false);
  const [openfeatureDesctiption, setOpenFeatureDesctiption] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [solutions, setSolutions] = useState("");
  //const [openDrawer, setOpenDrawer] = useState(false);
  const [rowIndex, setRowIndex] = useState("");
  const [sortDrawer, setSortDrawer] = useState(false);
  const [selectedField, setSelectedField] = useState("");
  const [sortType, setSortType] = useState("");
  const [placeholder, setPlaceholder] = useState("+ Add New Row");

  useEffect(() => {
    if (moduleDetails?.length) {
      let data = moduleDetails?.find(ele => ele?.moduleStep?.stepNo == 3);
      let answers = JSON.parse(data?.answers);
      setSolutions(
        Object.keys(answers)
          .map(k => answers[k])
          .flat()
      );
    }
  }, [moduleDetails]);

  const handlePopOver = event => {
    setAnchorEl(event.currentTarget);
    if (openPopOver) {
      setAnchorEl(null);
    }
    setOpenPopOver(!openPopOver);
  };

  const handleFeatureDesctiption = () => {
    if (!openfeatureDesctiption) {
      setOpenPopOver(!openPopOver);
    }
    setOpenFeatureDesctiption(!openfeatureDesctiption);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };
  const handleSelectdField = event => {
    setSelectedField(event.target.value);
    setSortField(event.target.value);
  };

  const handleSortType = event => {
    setSortType(event.target.value);
  };

  return (
    <>
      <Box>
        {answers?.length ? (
          answers?.map((item, i) => {
            return (
              <>
                <Divider sx={{ mt: 1 }} />
                <Grid
                  container
                  spacing={2}
                  sx={{ color: theme => theme.palette.secondary.outline, p: 2 }}
                >
                  <Grid
                    item
                    xs={6}
                    sx={{ display: "flex", gap: 1, alignItems: "center" }}
                  >
                    <Typography
                      variant="overlineMedium"
                      sx={{ color: theme => theme.palette.secondary.outline }}
                    >
                      SOLUTION
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Grid item xs={10} sx={{ width: 1, display: "grid" }}>
                      <SelectInput
                        fullWidth={true}
                        onInputChange={(e, type) =>
                          handleUpdateInput(e, "solution", i)
                        }
                        selectedValue={
                          answers[i]?.solution ? answers[i]?.solution : ""
                        }
                        inputKey="solution"
                        options={solutions ? solutions : []}
                        borderNone={true}
                        placeholder="Solution Name"
                        sx={{ width: 1 }}
                        labelId={"demo-select-small"}
                        id={"demo-select-small"}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    xs={6}
                    sx={{ display: "flex", gap: 1, alignItems: "center" }}
                  >
                    <Typography
                      variant="overlineMedium"
                      sx={{ color: theme => theme.palette.secondary.outline }}
                    >
                      FEATURE
                    </Typography>
                    <CustomTooltipWrapper
                      placement="bottom"
                      arrow={true}
                      text={
                        <React.Fragment>
                          <Typography color="inherit">
                            Features are used to rationalize solutions into
                            smaller components or chunks that can be built and
                            tested within a relatively short period of time.
                          </Typography>
                        </React.Fragment>
                      }
                    >
                      <InfoOutlinedIcon
                        sx={{ cursor: "pointer" }}
                        fontSize={"small"}
                      />
                    </CustomTooltipWrapper>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      placeholder="Enter feature name"
                      value={
                        answers[i]?.featureName ? answers[i]?.featureName : ""
                      }
                      onChange={e => handleUpdateInput(e, "featureName", i)}
                      fullwidth
                      sx={{
                        width: 1,
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                      }}
                      inputProps={{
                        maxLength: "100",
                        minLength: "3",
                      }}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={6}
                    sx={{ display: "flex", gap: 1, alignItems: "center" }}
                  >
                    <Typography
                      variant="overlineMedium"
                      sx={{ color: theme => theme.palette.secondary.outline }}
                    >
                      RICE SCORE
                    </Typography>
                    <CustomTooltipWrapper
                      placement="bottom"
                      arrow={true}
                      text={
                        <React.Fragment>
                          <Typography color="inherit">
                            The RICE score is calculated by multiplying your
                            Reach, Impact, and Confidence values together, and
                            then dividing that number by the Effort value.
                            <br />
                            <br />
                            Once you have your RICE scores, you can sort your
                            projects. Sort them in descending order to highlight
                            the highest RICE scores, or the projects that'll
                            generate the most value per time worked.
                          </Typography>
                        </React.Fragment>
                      }
                    >
                      <InfoOutlinedIcon
                        sx={{ cursor: "pointer" }}
                        fontSize={"small"}
                      />
                    </CustomTooltipWrapper>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid item xs={6}>
                      {/*<TextField
                        sx={{ ml: 2 }}
                        placeholder="0"
                        value={
                          answers[i]?.riceScore ? answers[i]?.riceScore : ""
                        }
                        disabled
                      />*/}
                      <Box
                        sx={theme => ({
                          py: 1,
                          px: 2,

                          width: "max-content",
                          borderRadius: 1,
                          color: theme.palette.secondary.black,
                        })}
                      >
                        {answers[i]?.riceScore ? answers[i]?.riceScore : "0"}
                      </Box>{" "}
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    xs={6}
                    sx={{ display: "flex", gap: 1, alignItems: "center" }}
                  >
                    <Typography
                      variant="overlineMedium"
                      sx={{ color: theme => theme.palette.secondary.outline }}
                    >
                      REACH
                    </Typography>
                    <CustomTooltipWrapper
                      placement="bottom"
                      arrow={true}
                      text={
                        <React.Fragment>
                          <Typography color="inherit">
                            The RICE score is calculated by multiplying your
                            Reach, Impact, and Confidence values together, and
                            then dividing that number by the Effort value.
                            <br />
                            <br />
                            Once you have your RICE scores, you can sort your
                            projects. Sort them in descending order to highlight
                            the highest RICE scores, or the projects that'll
                            generate the most value per time worked.
                          </Typography>
                        </React.Fragment>
                      }
                    >
                      <InfoOutlinedIcon
                        sx={{ cursor: "pointer" }}
                        fontSize={"small"}
                      />
                    </CustomTooltipWrapper>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid item xs={10}>
                      <TextField
                        sx={{
                          input: {
                            "&, &::placeholder": theme => ({
                              color: theme.palette.secondary.black,
                              opacity: 1,
                            }),
                          },
                        }}
                        placeholder="0"
                        value={answers[i]?.reach ? answers[i]?.reach : ""}
                        onChange={e => handleUpdateInput(e, "reach", i)}
                        inputProps={{
                          maxLength: "6",
                          minLength: "1",
                        }}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    xs={6}
                    sx={{ display: "flex", gap: 1, alignItems: "center" }}
                  >
                    <Typography
                      variant="overlineMedium"
                      sx={{ color: theme => theme.palette.secondary.outline }}
                    >
                      IMPACT
                    </Typography>
                    <CustomTooltipWrapper
                      placement="bottom"
                      arrow={true}
                      text={
                        <React.Fragment>
                          <Typography color="inherit">
                            Impact should express, in relative terms, how much
                            the feature will impact each customer that uses it.
                            For example, how much will the feature increase
                            conversion rate when a customer encounters it?
                            <br />
                            <br />
                            Allowable Values: Massive - 3x, High - 2x, Medium -
                            1x, Low - 0.5x, Minimal - 0.25x.
                          </Typography>
                        </React.Fragment>
                      }
                    >
                      <InfoOutlinedIcon
                        sx={{ cursor: "pointer" }}
                        fontSize={"small"}
                      />
                    </CustomTooltipWrapper>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid item xs={10}>
                      <SelectInput
                        placeholder="0"
                        inputKey="impact"
                        options={[
                          "3 = Massive Impact",
                          "2 = High Impact",
                          "1 = Medium Impact",
                          ".5 = Low Impact",
                          ".25 = Minimal Impact",
                        ]}
                        labelId={"demo-select-small"}
                        id={"demo-select-small"}
                        displayEmpty={true}
                        fullWidth
                        selectedValue={
                          answers[i]?.impact ? answers[i]?.impact : 0
                        }
                        onInputChange={e => handleUpdateInput(e, "impact", i)}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    xs={6}
                    sx={{ display: "flex", gap: 1, alignItems: "center" }}
                  >
                    <Typography
                      variant="overlineMedium"
                      sx={{ color: theme => theme.palette.secondary.outline }}
                    >
                      CONFIDENCE
                    </Typography>
                    <CustomTooltipWrapper
                      placement="bottom"
                      arrow={true}
                      text={
                        <React.Fragment>
                          <Typography color="inherit">
                            Confidence should express how confident you are
                            about the optimistic estimates for reach, impact and
                            effort. Be honest in your assessment and give
                            additional weight where hard evidence is available
                            (research, survey data, etc.). If you have data
                            backing up your reach estimate but your impact score
                            represents more of a gut feeling or anecdotal
                            evidence, your confidence score will help account
                            for this. Generally speaking, data {`>`} opinion.
                          </Typography>
                        </React.Fragment>
                      }
                    >
                      <InfoOutlinedIcon
                        sx={{ cursor: "pointer" }}
                        fontSize={"small"}
                      />
                    </CustomTooltipWrapper>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid item xs={10}>
                      <SelectInput
                        placeholder="0"
                        inputKey="confidence"
                        options={[
                          "100% = High Confidence",
                          "80% = Medium Confidence",
                          "50% = Low Confidence",
                          "20% = Moonshot",
                        ]}
                        labelId={"demo-select-small"}
                        id={"demo-select-small"}
                        displayEmpty={true}
                        fullWidth={true}
                        selectedValue={
                          answers[i]?.confidence ? answers[i]?.confidence : 0
                        }
                        onInputChange={e =>
                          handleUpdateInput(e, "confidence", i)
                        }
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    xs={6}
                    sx={{ display: "flex", gap: 1, alignItems: "center" }}
                  >
                    <Typography
                      variant="overlineMedium"
                      sx={{ color: theme => theme.palette.secondary.outline }}
                    >
                      EFFORT
                    </Typography>
                    <CustomTooltipWrapper
                      placement="top"
                      arrow={true}
                      text={
                        <React.Fragment>
                          <Typography color="inherit">
                            Effort should include the total amount of work (in
                            person months) required from all team members
                            (product, design, engineering) to deliver the
                            feature. Use whole numbers and minimum of half a
                            month. Don’t get into the weeds... this should be a
                            very rough estimate.
                            <br />
                            <br />
                            Allowable Values: 0.5 to 100,000
                          </Typography>
                        </React.Fragment>
                      }
                    >
                      <InfoOutlinedIcon
                        sx={{ cursor: "pointer" }}
                        fontSize={"small"}
                      />
                    </CustomTooltipWrapper>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid item xs={10}>
                      <TextField
                        sx={{
                          input: {
                            "&, &::placeholder": theme => ({
                              color: theme.palette.secondary.black,
                              opacity: 1,
                            }),
                          },
                        }}
                        placeholder="0"
                        value={answers[i]?.effort ? answers[i]?.effort : ""}
                        onChange={e => handleUpdateInput(e, "effort", i)}
                        onBlur={e => handleOnBlur(e, "effort", i)}
                        inputProps={{
                          maxLength: "6",
                          minLength: "1",
                        }}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    xs={6}
                    sx={{
                      display: "flex",
                      gap: 1,
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      variant="overlineMedium"
                      sx={{ color: theme => theme.palette.secondary.outline }}
                    >
                      RECOMMENDATION
                    </Typography>
                    <CustomTooltipWrapper
                      placement="top"
                      arrow={true}
                      text={
                        <React.Fragment>
                          <Typography color="inherit">
                            Recommendation is the follow-up action you will take
                            on each feature after you’ve tested it within your
                            MVP and evaluated customer feedback from interviews
                            and surveys.
                          </Typography>
                        </React.Fragment>
                      }
                    >
                      <InfoOutlinedIcon
                        sx={{ cursor: "pointer" }}
                        fontSize={"small"}
                      />
                    </CustomTooltipWrapper>
                  </Grid>
                  <Grid item xs={6} sx={{ pr: { xs: 1, sm: 0 } }}>
                    <SelectChipInput
                      inputKey="recommandation"
                      options={["None", "Double-down", "Expand", "Ignore"]}
                      backgroundColor={[
                        theme => theme.palette.text.textBgGrey,
                        theme => theme.palette.text.textBgGreen,
                        theme => theme.palette.text.textBgBlue,
                        theme => theme.palette.text.textBgOrange,
                      ]}
                      textColor={[
                        theme => theme.palette.text.textGrey,
                        theme => theme.palette.text.textGreen,
                        theme => theme.palette.text.textBlue,
                        theme => theme.palette.text.textOrange,
                      ]}
                      borderNone={true}
                      chip={true}
                      selectedValue={
                        answers[i]?.recommandation
                          ? answers[i]?.recommandation
                          : "None"
                      }
                      onInputChange={e =>
                        handleUpdateInput(e, "recommandation", i)
                      }
                    />
                  </Grid>

                  <Grid
                    item
                    xs={6}
                    sx={{ display: "flex", gap: 1, alignItems: "center" }}
                  >
                    <Typography
                      variant="overlineMedium"
                      sx={{ color: theme => theme.palette.secondary.outline }}
                    >
                      APPROVE
                    </Typography>
                    <CustomTooltipWrapper
                      placement="top"
                      arrow={true}
                      text={
                        <React.Fragment>
                          <Typography color="inherit">
                            Use Approve to signify which subset of features you
                            intend to deliver in your Minimum Viable Product
                            (MVP). Your MVP must solve a real problem for the
                            user but you don’t have to deliver everything at
                            once. It may make more sense to sequence the
                            delivery of features across multiple MVP iterations.
                          </Typography>
                        </React.Fragment>
                      }
                    >
                      <InfoOutlinedIcon
                        sx={{ cursor: "pointer" }}
                        fontSize={"small"}
                      />
                    </CustomTooltipWrapper>
                  </Grid>
                  <Grid item xs={6}>
                    <Checkbox
                      checked={answers[i]?.approve ? true : false}
                      onChange={e => handleUpdateInput(e, "approve", i)}
                      sx={{ color: theme => theme.palette.secondary.outline }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <IconButton
                      onClick={event => {
                        setRowIndex(i);
                        handlePopOver(event);
                      }}
                    >
                      <MoreHorizIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </>
            );
          })
        ) : (
          <Box
            sx={{
              display: "flex",
              width: 1,
              justifyContent: "center",
              alignContent: "center",
              py: 5,
            }}
          >
            <Typography
              color="primary"
              variant="h5"
              sx={theme => ({ color: theme.palette.secondary.dark })}
            >
              No data found.
            </Typography>
          </Box>
        )}

        <Grid container>
          <Grid item xs={12}>
            <TextField
              fullWidth
              size="small"
              placeholder={placeholder}
              value={newRow ? newRow : ""}
              onChange={e => setNewRow(e.target.value)}
              onKeyDown={e => {
                if (e.key === "Enter") {
                  handleAddNewRow(newRow);
                  setNewRow("");
                }
              }}
              onFocus={() => setPlaceholder("Type a ‘feature name’")}
              onBlur={() => setPlaceholder("+ Add New Row")}
              InputProps={{
                endAdornment: newRow && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      variant="nofill"
                      size="small"
                      onClick={() => {
                        handleAddNewRow(newRow);
                        setNewRow("");
                      }}
                      sx={{ maxWidth: "inherit" }}
                    >
                      Save
                    </Button>
                    <CloseIcon
                      size="small"
                      sx={{ cursor: "pointer" }}
                      onClick={() => setNewRow("")}
                    />
                  </Box>
                ),
              }}
            />
          </Grid>
        </Grid>
        {/* more popover */}
        <Popover
          id={"simple-popover"}
          open={openPopOver}
          anchorEl={anchorEl}
          onClose={handlePopOver}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          PaperProps={{
            sx: {
              borderRadius: 1,
              boxShadow:
                "0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)",
              minWidth: "280px",
            },
          }}
        >
          <List>
            <ListItem disablePadding disabled={true}>
              <ListItemButton>
                <ListItemIcon sx={{ minWidth: 0, pr: 2 }}>
                  <LocalOfferOutlinedIcon />
                </ListItemIcon>
                <ListItemText>
                  <Typography variant="body_large">Apply Theme</Typography>
                </ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={handleFeatureDesctiption}>
                <ListItemIcon sx={{ minWidth: 0, pr: 2 }}>
                  <EditOutlinedIcon />
                </ListItemIcon>
                <ListItemText>
                  <Typography variant="body_large">
                    Edit Feature Description
                  </Typography>
                </ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  handleDeleteRow(rowIndex);
                  setAnchorEl(null);
                  setOpenPopOver(false);
                }}
              >
                <ListItemIcon sx={{ minWidth: 0, pr: 2 }}>
                  <DeleteOutlineOutlinedIcon
                    sx={{ color: theme => theme.palette.error.main }}
                  />
                </ListItemIcon>
                <ListItemText>
                  <Typography variant="body_large">Delete Row</Typography>
                </ListItemText>
              </ListItemButton>
            </ListItem>
          </List>
        </Popover>
        {/* feature form */}
        <FeatureDescriptionForm
          open={openfeatureDesctiption}
          onClose={handleFeatureDesctiption}
          answers={answers}
          handleUpdateInput={handleUpdateInput}
          solutions={solutions}
          index={rowIndex}
          setAnswers={setAnswers}
        />
        {/* drawer */}
        <SwipeableDrawer
          onOpen={() => {
            return;
          }}
          anchor="bottom"
          open={openDrawer}
          onClose={handleSortDrawer}
          swipeAreaWidth={0}
          disableSwipeToOpen={false}
          sx={{
            display: { xs: "flex", md: "none" },
            zIndex: 9998,
            width: 1,

            "& .MuiPaper-root": {
              borderRadius: "16px 16px 0px 0px",
            },
          }}
        >
          {sortDrawer && (
            <Grid
              item
              xs={12}
              sx={{
                display: "grid",
                alignItems: "flex-start",
                alignContent: "flex-start",
                justifyContent: "left",
                mt: 2,
                px: 0,
              }}
            >
              <Button
                variant="nofill"
                startIcon={<ArrowBackIosIcon />}
                onClick={() => {
                  setSortDrawer(false);
                  setSortType("");
                  setSortDrawer(false);
                }}
                size="small"
                xs={{ maxWidth: "max-content" }}
              >
                Back
              </Button>
            </Grid>
          )}
          <Grid
            container
            sx={{
              display: "flex",
              width: 1,
              flexDirection: "column",
              mt: 1,
              mb: 2,
              px: 2,
            }}
            spacing={2}
          >
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                maxWidth: 1,
                alignItems: "center",
              }}
            >
              <Typography variant="onsurface_title_thick">Filter</Typography>
              {sortDrawer ? (
                <Box>
                  <Button
                    variant="nofill"
                    onClick={() => {
                      handleSort(sortType);
                      setOpenDrawer(false);
                    }}
                  >
                    Apply Filter
                  </Button>
                </Box>
              ) : (
                <Box>
                  <Button variant="nofill" onClick={() => setSortDrawer(true)}>
                    Next
                  </Button>
                </Box>
              )}
            </Grid>
            <Grid item xs={12}>
              {!sortDrawer && (
                <Box sx={{ px: 0 }}>
                  {selectedField ? (
                    <Box>
                      <Chip
                        label={
                          selectedField === "featureName"
                            ? "Feature"
                            : selectedField === "riceScore"
                            ? "RICE Score"
                            : selectedField
                        }
                        sx={{ borderRadius: 1 }}
                        deleteIcon={<CloseIcon />}
                        onDelete={() => setSelectedField("")}
                      />
                    </Box>
                  ) : (
                    <Typography
                      variant="overlineMedium"
                      sx={{ color: theme => theme.palette.secondary.outline }}
                    >
                      Choose a category and apply your filter
                    </Typography>
                  )}
                </Box>
              )}
            </Grid>
            <Grid item xs={12}>
              {!sortDrawer ? (
                <Box sx={{ width: 1 }}>
                  <FormControl sx={{ width: "inherit" }}>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="female"
                      name="radio-buttons-group"
                      sx={{
                        width: 1,
                        display: "flex",
                        flexDirection: "column",
                        gap: 1,
                      }}
                      value={selectedField}
                      onChange={handleSelectdField}
                    >
                      {[
                        { value: "Solution", key: "solution" },
                        { value: "Feature", key: "featureName" },
                        { value: "RICE Score", key: "riceScore" },
                        { value: "Reach", key: "reach" },
                        { value: "Impact", key: "impact" },
                        { value: "Confidence", key: "confidence" },
                        { value: "Effort", key: "effort" },
                      ].map(item => {
                        return (
                          <Box
                            sx={{
                              width: 1,
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              variant="body_medium"
                              sx={{
                                color: theme =>
                                  theme.palette.secondary.korophKoal,
                              }}
                            >
                              {item.value}
                            </Typography>
                            <FormControlLabel
                              value={item.key}
                              key={item.value}
                              control={<Radio />}
                            />
                          </Box>
                        );
                      })}
                    </RadioGroup>
                  </FormControl>
                </Box>
              ) : (
                <Box sx={{ width: 1 }}>
                  <FormControl sx={{ width: "inherit" }}>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="female"
                      name="radio-buttons-group"
                      sx={{
                        width: 1,
                        display: "flex",
                        flexDirection: "column",

                        gap: 1,
                      }}
                      value={sortType}
                      onChange={handleSortType}
                    >
                      {selectedField === "solution" ||
                      selectedField === "featureName"
                        ? [
                            { value: "Sort from A-Z", key: "asc" },
                            { value: "Sort from Z-A", key: "desc" },
                          ].map(item => {
                            return (
                              <Box
                                sx={{
                                  width: 1,
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <Typography
                                  variant="body_medium"
                                  sx={{
                                    color: theme =>
                                      theme.palette.secondary.korophKoal,
                                  }}
                                >
                                  {item.value}
                                </Typography>
                                <FormControlLabel
                                  value={item.key}
                                  control={<Radio />}
                                />
                              </Box>
                            );
                          })
                        : [
                            {
                              value: "Sort from ascending to descending order",
                              key: "asc",
                            },
                            {
                              value:
                                "Sort from descending Order to ascending order",
                              key: "desc",
                            },
                          ].map(item => {
                            return (
                              <Box
                                sx={{
                                  width: 1,
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  gap: 2,
                                }}
                              >
                                <Typography
                                  variant="body_medium"
                                  sx={{
                                    color: theme =>
                                      theme.palette.secondary.korophKoal,
                                  }}
                                >
                                  {item.value}
                                </Typography>
                                <FormControlLabel
                                  value={item.key}
                                  control={<Radio />}
                                />
                              </Box>
                            );
                          })}
                    </RadioGroup>
                  </FormControl>
                </Box>
              )}
            </Grid>
          </Grid>
        </SwipeableDrawer>
      </Box>
    </>
  );
}
