import React from "react";
import { useHistory, generatePath } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Box, Button, Chip, Stack, Typography } from "@mui/material";
import CommonSlideDialog from "../../../common/CommonSlideDialog";
import { setCommonDialogConfirmationData } from "../../../modules/actions";
import ROUTES_PATH from "../../../constants/routes";
import { ARE_YOU_FUNDABLE_PROMOTIONAL_BANNER, SPEAKER_ICON } from "../../../constants";

export default function PromotionalEvents() {
	const dispatch = useDispatch();
	const { open } = useSelector((state) => state?.commonDialogConfirmation);

	const history = useHistory();
	const handleClose = () => {
		dispatch(
			setCommonDialogConfirmationData({
				open: false,
			})
		);
	};

	return (
		<>
			<CommonSlideDialog
				open={open}
				onClose={handleClose}
				title={<Box component={"img"} src={SPEAKER_ICON} />}
				titleVariant="Text/md/Semibold"
				maxWidth="sm"
				disableBackDropClick={false}
				PaperProps={{
					sx: {
						px: 2,
						py: 3,
						borderRadius: "30px",
						width: 1,
					},
				}}
				contentStyle={{ mt: 0 }}
				dialogAction={
					<Box display="flex" alignItems="center" gap={1.5} sx={{ pr: 3, my: 1 }}>
						<Box>
							<Button
								variant="noOutlineNew"
								onClick={() => {
									handleClose();
								}}
							>
								No Thanks
							</Button>
						</Box>
						<Box>
							<Button
								variant="DS1"
								onClick={() => {
									history.push({
										pathname: generatePath(ROUTES_PATH.WORKSPACE_POWERUP_DETAILS, {
											id: 58,
										}),
									});
									handleClose();
								}}
							>
								Get Started
							</Button>
						</Box>
					</Box>
				}
			>
				<Box>
					<Stack mb="20px" spacing={2}>
						<Typography component={"p"} variant="Text/md/Semibold">
							Boost Your Startup's Success in Minutes!
						</Typography>

						<Box
							component={"img"}
							src={ARE_YOU_FUNDABLE_PROMOTIONAL_BANNER}
							sx={(theme) => ({
								borderRadius: theme.shape.standard10.borderRadius,
							})}
						/>

						<Typography variant="Text/sm/Regular" sx={{ "& p": { margin: 0 }, "& ul": { marginBlockStart: 1, marginBlockEnd: 1, ml: "0px" } }}>
							<ul>
								<li>
									<Typography variant="Text/sm/Semibold">Unlock your startup valuation with our expert analysis in seconds.</Typography>
								</li>
								<li>
									<Typography variant="Text/sm/Semibold">Supercharge your growth and discover your raise potential.</Typography>
								</li>
								<li>
									<Typography variant="Text/sm/Semibold">Become visible to investors.</Typography>
								</li>
								<li>
									<Typography variant="Text/sm/Semibold">
										Gain essential insights that can significantly boost your chances of success.
									</Typography>
								</li>
							</ul>
						</Typography>
					</Stack>
				</Box>
				{/* Not in used currently */}
				{/* <Box>
					<Stack mb="20px" spacing={2}>
						<Typography component={"p"} variant="Text/sm/Semibold">
							StartupOS Pitch Day Event!
						</Typography>
						<Chip
							sx={{ maxWidth: "fit-content", backgroundColor: (theme) => theme.palette.secondary.white, "& .MuiChip-label": { px: "10px" } }}
							variant="outlined"
							size="small"
							label={
								<Typography
									variant="Text/xs/Regular"
									sx={(theme) => ({ color: theme.palette.secondary.black, borderColor: theme.palette.secondary.grey })}
								>
									{getFormattedDate(promotionalEventDetails?.eventDate, "MMMM DD, YYYY")}
								</Typography>
							}
						/>
						<Box
							component={"img"}
							src={promotionalEventDetails?.imageUrl}
							sx={(theme) => ({
								borderRadius: theme.shape.standard10.borderRadius,
							})}
						/>
						<Typography variant="Text/sm/Regular" sx={{ "& p": { margin: 0 }, "& ul": { marginBlockStart: 0, marginBlockEnd: 0 } }}>
							<span
								dangerouslySetInnerHTML={{
									__html: promotionalEventDetails?.pitchEventDescription,
								}}
							/>
						</Typography>
					</Stack>
				</Box> */}
			</CommonSlideDialog>
		</>
	);
}
