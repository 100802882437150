import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";

export function PwdValidate({ pwd }) {
	const [hasLength, setHasLength] = useState(false);
	const [hasUppercase, setHasUppercase] = useState(false);
	const [hasLowercase, setHasLowercase] = useState(false);
	const [hasNumber, setHasNumber] = useState(false);

	useEffect(() => {
		if (pwd.length > 7) {
			setHasLength(true);
		} else {
			setHasLength(false);
		}

		const containsUpperCase = (str) => str !== str.toLowerCase();
		setHasUppercase(containsUpperCase(pwd));

		const containsLowerCase = (str) => str !== str.toUpperCase();
		setHasLowercase(containsLowerCase(pwd));

		const containsNumber = (str) => /\d/.test(str);
		setHasNumber(containsNumber(pwd));
	}, [pwd, pwd.length]);

	return (
		<Box display="flex" flexDirection={"column"} gap={1.25}>
			<Typography variant="">Your password must contain:</Typography>
			<Box display="flex" alignItems="center" gap={1}>
				{hasUppercase ? <CheckCircleIcon sx={{ color: "#7B61FF" }} /> : <CheckCircleOutlinedIcon sx={{ color: "#AAAAAA" }} />}
				<Typography variant="">Upper case letters (A-Z)</Typography>
			</Box>
			<Box display="flex" alignItems="center" gap={1}>
				{hasLowercase ? <CheckCircleIcon sx={{ color: "#7B61FF" }} /> : <CheckCircleOutlinedIcon sx={{ color: "#AAAAAA" }} />}
				<Typography variant="">Lower case letters (a-z)</Typography>
			</Box>
			<Box display="flex" alignItems="center" gap={1}>
				{hasNumber ? <CheckCircleIcon sx={{ color: "#7B61FF" }} /> : <CheckCircleOutlinedIcon sx={{ color: "#AAAAAA" }} />}
				<Typography variant="">Number (0-9)</Typography>
			</Box>
			<Box display="flex" alignItems="center" gap={1}>
				{hasLength ? <CheckCircleIcon sx={{ color: "#7B61FF" }} /> : <CheckCircleOutlinedIcon sx={{ color: "#AAAAAA" }} />}
				<Typography variant="">Min. 8 characters</Typography>
			</Box>
		</Box>
	);
}
