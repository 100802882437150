import * as React from "react";
import parse from "html-react-parser";
import {
  Box,
  IconButton,
  Typography,
  Link,
  Divider,
  DialogTitle,
  Grid,
  DialogActions,
  Button,
} from "@mui/material";
import SAMPLEDATA from "../../assets/text";
import { terms, privacy } from "./terms-content";
import { Close } from "@mui/icons-material";
import theme from "../../../../constants/Theme";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { useHistory, useLocation } from "react-router";

export default function TermsAndServiceModal(props) {
  const [open, setOpen] = React.useState(props.openModal || false);
  const [scroll, setScroll] = React.useState("paper");
  const [content, setContent] = React.useState("");
  const [contentType, setContentType] = React.useState(props.type || "");
  const [title, setTitle] = React.useState();

  const [triggerType, setTriggerType] = React.useState(props.triggerType || "");
  const location = useLocation();
  const history = useHistory();

  const pathname = location.pathname;
  // manage currentUrl in state.
  const [currentUrl, setCurrentUrl] = React.useState(pathname);
  React.useEffect(() => {
    setCurrentUrl(pathname);
  }, [pathname]);

  React.useEffect(() => {
    setOpen(props.openModal);
  }, [props.openModal]);

  React.useEffect(() => {
    setContentType(props.type);
  }, [props.type]);

  React.useEffect(() => {
    if (open) {
      handleContent(contentType);
    }
  }, [open]);

  const handleClickOpen = scrollType => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
    if (triggerType === "url") {
      history.push("/");
    }
    if (props && props.onClose) {
      props.onClose();
    }
  };

  React.useEffect(() => {
    if (location && location.pathname == "/terms") {
      handleContent(SAMPLEDATA.TERMSANDSERVICETITLE);
    } else if (location && location.pathname == "/privacy") {
      handleContent(SAMPLEDATA.PRIVACYPOLICYTITLE);
    }
  }, [currentUrl]);

  const handleContent = type => {
    handleClickOpen("paper");
    if (type === SAMPLEDATA.TERMSANDSERVICETITLE) {
      setContent(terms);
    }
    if (type === SAMPLEDATA.PRIVACYPOLICYTITLE) {
      setContent(privacy);
    }
  };

  return (
    <>
      {triggerType && triggerType == "url" && (
        <Box>
          <Link
            onClick={() => history.push("/terms")}
            sx={{
              color: theme.palette.secondary.white,
              cursor: "pointer",
            }}
          >
            Terms
          </Link>
          <Link
            onClick={() => history.push("/privacy")}
            sx={{
              color: theme.palette.secondary.white,
              cursor: "pointer",
              ml: 5,
            }}
          >
            Privacy Policy
          </Link>
        </Box>
      )}

      <Typography>
        <Dialog
          open={open}
          onClose={handleClose}
          scroll={"body"}
          fullWidth
          maxWidth={"xl"}
        >
          <DialogTitle sx={{ background: `${theme.palette.primary.main}` }}>
            <Grid
              container
              justifyContent={"space-between"}
              alignItems="center"
            >
              <Grid item>
                <Typography variant="h3" color={"white"}>
                  {title}
                </Typography>
              </Grid>
              <Grid item>
                <IconButton sx={{ p: 3 }} onClick={handleClose}>
                  <Close
                    sx={{
                      color: theme.palette.secondary.white,
                    }}
                  />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {parse(content, {
                replace: domNode => {
                  if (domNode.attribs && domNode.attribs.id === "privacyUrl") {
                    return (
                      <Link
                        sx={{
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          handleContent(SAMPLEDATA.PRIVACYPOLICYTITLE)
                        }
                      >
                        Privacy Policy
                      </Link>
                    );
                  }
                  if (domNode.attribs && domNode.attribs.id === "termsUrl") {
                    return (
                      <Link
                        sx={{
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          handleContent(SAMPLEDATA.TERMSANDSERVICETITLE)
                        }
                      >
                        Terms and Services
                      </Link>
                    );
                  }
                },
              })}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={handleClose}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Typography>
    </>
  );
}
