import React, { useEffect, useState } from "react";
import { Typography, Box, Grid } from "@mui/material";
import TreeItem from "@mui/lab/TreeItem";
import TreeView from "@mui/lab/TreeView";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import BasicCheckBoxTree from "../../../../../common/CheckBoxTree";
import CustomCheckBox from "./CustomCheckbox";

const FilterDropDownComponent = ({ gridData, lable, handleCheck, handleUncheck, disabled = false, type, filteredValue = [] }) => {
	const displayData = gridData[lable];

	const [industryData, setIndustryData] = useState([]);

	return (
		<>
			{disabled ? (
				<Box display="flex" alignItems="center" gap={1} sx={{ ml: "6px", mt: 2 }}>
					<Typography variant="Text/md/Semibold" sx={{ pt: "4px", display: "inline-block", lineHeight: "1.5rem" }}>
						{`${lable} (coming soon)`}
					</Typography>
				</Box>
			) : (
				<Box display={"flex"} flexDirection={"column"} rowGap={1}>
					<Box>
						<Typography variant="Text/md/Semibold" sx={{ pt: "4px", display: "inline-block", lineHeight: "1.5rem" }}>
							{lable}{" "}
						</Typography>
					</Box>
					<Grid container>
						{displayData.map((item, index) => {
							return (
								<Grid key={index} item xs={12} sm={6} md={6}>
									<Box>
										<Box sx={{ display: "grid" }}>
											<Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
												<CustomCheckBox
													value={item}
													handleCheck={handleCheck}
													handleUncheck={handleUncheck}
													type={type}
													checkedValue={filteredValue.some((filterItem) => filterItem?.checkboxValue === item)}
												/>
												<Typography variant="Text/sm/Regular" sx={{ ml: 1 }}>
													{item}
												</Typography>
											</Box>
										</Box>
									</Box>
								</Grid>
							);
						})}
					</Grid>
				</Box>
				// <TreeView
				// 	defaultCollapseIcon={
				// 		<KeyboardArrowDownOutlinedIcon
				// 			sx={{
				// 				fontSize: "large",
				// 				border: "2px solid",
				// 				borderRadius: "50%",
				// 			}}
				// 		/>
				// 	}
				// 	defaultExpandIcon={
				// 		<KeyboardArrowRightOutlinedIcon
				// 			sx={{
				// 				fontSize: "large",
				// 				border: "2px solid",
				// 				borderRadius: "50%",
				// 			}}
				// 		/>
				// 	}
				// 	sx={{ mt: 2 }}
				// 	defaultExpanded={[`${lable}`]}
				// >
				// 	<TreeItem
				// 		nodeId={`${lable}`}
				// 		label={
				// 			<Typography variant="Text/md/Semibold" sx={{ pt: "4px", display: "inline-block", lineHeight: "1.5rem" }}>
				// 				{lable}
				// 			</Typography>
				// 		}
				// 		sx={{
				// 			"& .MuiTreeItem-content.Mui-selected": {
				// 				alignItems: "center",
				// 				background: (theme) => theme.palette.secondary.white,
				// 				"&:hover": {
				// 					background: (theme) => theme.palette.secondary.white,
				// 				},
				// 				"&:active": {
				// 					background: (theme) => theme.palette.secondary.white,
				// 				},
				// 				"&:focused": {
				// 					background: (theme) => theme.palette.secondary.white,
				// 				},
				// 				"&:selected": {
				// 					background: (theme) => theme.palette.secondary.white,
				// 				},
				// 			},
				// 			"& .MuiTreeItem-content.Mui-selected.Mui-focused": {
				// 				background: (theme) => theme.palette.secondary.white,
				// 				"&:hover": {
				// 					background: (theme) => theme.palette.secondary.white,
				// 				},
				// 				"&:active": {
				// 					background: (theme) => theme.palette.secondary.white,
				// 				},
				// 				"&:focused": {
				// 					background: (theme) => theme.palette.secondary.white,
				// 				},
				// 				"&:selected": {
				// 					background: (theme) => theme.palette.secondary.white,
				// 				},
				// 			},
				// 		}}
				// 	>
				// 		{lable == "Verticals" ? (
				// 			<Grid container>
				// 				{industryData.map((item, index) => {
				// 					return (
				// 						<Grid key={index} item xs={12} sm={6} md={6}>
				// 							<BasicCheckBoxTree
				// 								treeIndex={index}
				// 								treeData={item[`${index + 1}`]}
				// 								handleUncheck={handleUncheck}
				// 								lable={lable}
				// 								type={"verticals"}
				// 							/>
				// 						</Grid>
				// 					);
				// 				})}
				// 			</Grid>
				// 		) : (
				// 			<Grid container>
				// 				{displayData.map((item, index) => {
				// 					return (
				// 						<Grid key={index} item xs={12} sm={6} md={6}>
				// 							<Box>
				// 								<Box sx={{ display: "grid", ml: 2 }}>
				// 									<Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
				// 										<CustomCheckBox
				// 											value={item}
				// 											handleCheck={handleCheck}
				// 											handleUncheck={handleUncheck}
				// 											type={type}
				// 											checkedValue={filteredValue.some((filterItem) => filterItem?.checkboxValue === item)}
				// 										/>
				// 										<Typography variant="Text/sm/Regular" sx={{ ml: 1 }}>
				// 											{item}
				// 										</Typography>
				// 									</Box>
				// 								</Box>
				// 							</Box>
				// 						</Grid>
				// 					);
				// 				})}
				// 			</Grid>
				// 		)}
				// 	</TreeItem>
				// </TreeView>
			)}
		</>
	);
};

export default FilterDropDownComponent;
