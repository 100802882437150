import { Svg } from "./Svg";

export const Help6Icon = ({ className, fill = "#667085", width = 30, height = 24 }) => (
	<Svg className={className} width={width} height={height} viewBox="0 0 30 24" fill="none">
		<path
			d="M2.99984 17.3334V5.60004C2.99984 4.10657 2.99984 3.35983 3.29049 2.7894C3.54615 2.28763 3.9541 1.87969 4.45586 1.62402C5.02629 1.33337 5.77303 1.33337 7.2665 1.33337H22.7332C24.2266 1.33337 24.9734 1.33337 25.5438 1.62402C26.0456 1.87969 26.4535 2.28763 26.7092 2.7894C26.9998 3.35983 26.9998 4.10657 26.9998 5.60004V17.3334H19.8835C19.5574 17.3334 19.3943 17.3334 19.2409 17.3702C19.1048 17.4029 18.9748 17.4567 18.8555 17.5299C18.7209 17.6123 18.6056 17.7276 18.375 17.9582L18.2913 18.0419C18.0607 18.2725 17.9454 18.3878 17.8109 18.4702C17.6916 18.5433 17.5615 18.5972 17.4255 18.6299C17.272 18.6667 17.109 18.6667 16.7828 18.6667H13.2168C12.8907 18.6667 12.7276 18.6667 12.5742 18.6299C12.4381 18.5972 12.3081 18.5433 12.1888 18.4702C12.0542 18.3878 11.9389 18.2725 11.7083 18.0419L11.6247 17.9582C11.3941 17.7276 11.2788 17.6123 11.1442 17.5299C11.0249 17.4567 10.8949 17.4029 10.7588 17.3702C10.6054 17.3334 10.4423 17.3334 10.1162 17.3334H2.99984ZM2.99984 17.3334C2.26346 17.3334 1.6665 17.9303 1.6665 18.6667V19.1112C1.6665 19.9378 1.6665 20.3511 1.75737 20.6902C2.00395 21.6105 2.72274 22.3293 3.64299 22.5758C3.9821 22.6667 4.39542 22.6667 5.22206 22.6667H24.7776C25.6043 22.6667 26.0176 22.6667 26.3567 22.5758C27.2769 22.3293 27.9957 21.6105 28.2423 20.6902C28.3332 20.3511 28.3332 19.9378 28.3332 19.1112C28.3332 18.6978 28.3332 18.4912 28.2877 18.3216C28.1644 17.8615 27.805 17.5021 27.3449 17.3788C27.1754 17.3334 26.9687 17.3334 26.5554 17.3334H25.6665"
			stroke={fill}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</Svg>
);
