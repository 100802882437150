import React, { useContext, useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { NewPlatformComponent } from "../../../containers/PowerUP/compositions/DoIt/AreYouEnterprise/md-are-you-enterprise";
import CustomAccordion from "../../../common/CustomAccordion";

const EnterpriseReadySection = ({ context }) => {
	const { payLoad, setPayLoad } = useContext(context);

	const [inputValues, setInputValues] = useState();

	useEffect(() => {
		if (payLoad["enterpriseReadyJson"]) {
			const enterpriseParsed = JSON.parse(payLoad["enterpriseReadyJson"]);
			const formattedInput = [
				{ id: "md-enterprise-ready-strategic-plan", value: enterpriseParsed?.["strategic-plan"], error: "" },
				{ id: "md-enterprise-ready-strategic-partnerships", value: enterpriseParsed?.["strategic-partnerships"], error: "" },
				{ id: "md-enterprise-ready-patent", value: enterpriseParsed?.["patent"], error: "" },
				{ id: "md-enterprise-ready-strategy-for-leveraging", value: enterpriseParsed?.["strategy-for-leveraging"], error: "" },
				{ id: "md-enterprise-ready-startup-own-cash", value: enterpriseParsed?.["startup-own-cash"], error: "" },
				{ id: "md-enterprise-ready-any-litigation", value: enterpriseParsed?.["any-litigation"], error: "" },
				{ id: "md-enterprise-ready-legal-liabilities", value: enterpriseParsed?.["legal-liabilities"], error: "" },
				{ id: "md-enterprise-ready-product-offering-scale", value: enterpriseParsed?.["product-offering-scale"], error: "" },
				{ id: "md-enterprise-ready-unique-value-proposition", value: enterpriseParsed?.["unique-value-proposition"], error: "" },

				{ id: "md-enterprise-ready-competitive-landscape", value: enterpriseParsed?.["competitive-landscape"], error: "" },
				{ id: "md-enterprise-ready-roadmap", value: enterpriseParsed?.["roadmap"], error: "" },

				{ id: "md-enterprise-ready-process-for-soliciting", value: enterpriseParsed?.["process-for-soliciting"], error: "" },
				{ id: "md-enterprise-ready-support-customers", value: enterpriseParsed?.["support-customers"], error: "" },
				{ id: "md-enterprise-ready-flagship-customer", value: enterpriseParsed?.["flagship-customer"], error: "" },
				{ id: "md-enterprise-ready-proven-pricing-strategy", value: enterpriseParsed?.["proven-pricing-strategy"], error: "" },
				{ id: "md-enterprise-ready-proven-sales", value: enterpriseParsed?.["proven-sales"], error: "" },

				{ id: "md-enterprise-measure-the-success", value: enterpriseParsed?.["measure-the-success"], error: "" },
				{ id: "md-enterprise-endorsements", value: enterpriseParsed?.["endorsements"], error: "" },
				{ id: "md-enterprise-ready-business-meet-operational", value: enterpriseParsed?.["business-meet-operational"], error: "" },
				{ id: "md-enterprise-ready-clear-policies-for-regulatory", value: enterpriseParsed?.["clear-policies-for-regulatory"], error: "" },

				{ id: "md-enterprise-ready-ready-for-procurement", value: enterpriseParsed?.["ready-for-procurement"], error: "" },
				{ id: "md-enterprise-ready-attract-and-retain-talent", value: enterpriseParsed?.["attract-and-retain-talent"], error: "" },
				{ id: "md-enterprise-ready-clear-HR-policies", value: enterpriseParsed?.["clear-HR-policies"], error: "" },
				{
					id: "md-enterprise-ready-clear-environmental-sustainability-policies",
					value: enterpriseParsed?.["clear-environmental-sustainability-policies"],
					error: "",
				},

				{ id: "md-enterprise-ready-social-responsibility-initiatives", value: enterpriseParsed?.["social-responsibility-initiatives"], error: "" },
				{ id: "md-enterprise-ready-depend-on-a-Web", value: enterpriseParsed?.["depend-on-a-Web"], error: "" },
				{ id: "md-enterprise-ready-SOC-2-compliant", value: enterpriseParsed?.["SOC-2-compliant"], error: "" },
				{ id: "md-enterprise-ready-platform-meet-compliance", value: enterpriseParsed?.["platform-meet-compliance"], error: "" },
				{ id: "md-enterprise-ready-technology-infrastructure-scale", value: enterpriseParsed?.["technology-infrastructure-scale"], error: "" },
				{ id: "md-enterprise-ready-system-monitoring", value: enterpriseParsed?.["system-monitoring"], error: "" },
				{ id: "md-enterprise-ready-robust-data-analytics", value: enterpriseParsed?.["robust-data-analytics"], error: "" },
				{ id: "md-enterprise-ready-disaster-recovery-plan", value: enterpriseParsed?.["disaster-recovery-plan"], error: "" },
				{ id: "md-enterprise-ready-technological-upgrades", value: enterpriseParsed?.["technological-upgrades"], error: "" },

				{ id: "md-enterprise-ready-hardware-product", value: enterpriseParsed?.["hardware-product"], error: "" },
				{ id: "md-enterprise-ready-deliver-the-hardware", value: enterpriseParsed?.["deliver-the-hardware"], error: "" },
				{ id: "md-enterprise-ready-maintain-the-hardware", value: enterpriseParsed?.["maintain-the-hardware"], error: "" },
				{ id: "md-enterprise-ready-hardware-returned", value: enterpriseParsed?.["hardware-returned"], error: "" },
			];
			setInputValues(formattedInput);
		}
	}, []);

	useEffect(() => {
		const readyStartegicPlan = inputValues?.find((input) => input.id === "md-enterprise-ready-strategic-plan")?.value;
		const readyStrategicPartnerships = inputValues?.find((input) => input.id === "md-enterprise-ready-strategic-partnerships")?.value;
		const patent = inputValues?.find((input) => input.id === "md-enterprise-ready-patent")?.value;
		const strategy_for_leveraging = inputValues?.find((input) => input.id === "md-enterprise-ready-strategy-for-leveraging")?.value;
		const startup_own_cash = inputValues?.find((input) => input.id === "md-enterprise-ready-startup-own-cash")?.value;
		const any_litigation = inputValues?.find((input) => input.id === "md-enterprise-ready-any-litigation")?.value;
		const legal_liabilities = inputValues?.find((input) => input.id === "md-enterprise-ready-legal-liabilities")?.value;
		const product_offering_scale = inputValues?.find((input) => input.id === "md-enterprise-ready-product-offering-scale")?.value;
		const unique_value_proposition = inputValues?.find((input) => input.id === "md-enterprise-ready-unique-value-proposition")?.value;

		const competitive_landscape = inputValues?.find((input) => input.id === "md-enterprise-ready-competitive-landscape")?.value;
		const ready_roadmap = inputValues?.find((input) => input.id === "md-enterprise-ready-roadmap")?.value;
		const process_for_soliciting = inputValues?.find((input) => input.id === "md-enterprise-ready-process-for-soliciting")?.value;
		const support_customers = inputValues?.find((input) => input.id === "md-enterprise-ready-support-customers")?.value;
		const flagship_customer = inputValues?.find((input) => input.id === "md-enterprise-ready-flagship-customer")?.value;
		const proven_pricing_strategy = inputValues?.find((input) => input.id === "md-enterprise-ready-proven-pricing-strategy")?.value;
		const proven_sales = inputValues?.find((input) => input.id === "md-enterprise-ready-proven-sales")?.value;
		const measure_the_success = inputValues?.find((input) => input.id === "md-enterprise-measure-the-success")?.value;
		const endorsements = inputValues?.find((input) => input.id === "md-enterprise-endorsements")?.value;
		const business_meet_operational = inputValues?.find((input) => input.id === "md-enterprise-ready-business-meet-operational")?.value;
		const clear_policies_for_regulatory = inputValues?.find((input) => input.id === "md-enterprise-ready-clear-policies-for-regulatory")?.value;
		const ready_for_procurement = inputValues?.find((input) => input.id === "md-enterprise-ready-ready-for-procurement")?.value;
		const attract_and_retain_talent = inputValues?.find((input) => input.id === "md-enterprise-ready-attract-and-retain-talent")?.value;
		const clear_HR_policies = inputValues?.find((input) => input.id === "md-enterprise-ready-clear-HR-policies")?.value;
		const clear_environmental_sustainability_policies = inputValues?.find(
			(input) => input.id === "md-enterprise-ready-clear-environmental-sustainability-policies"
		)?.value;
		const social_responsibility_initiatives = inputValues?.find((input) => input.id === "md-enterprise-ready-social-responsibility-initiatives")?.value;
		const depend_on_a_Web = inputValues?.find((input) => input.id === "md-enterprise-ready-depend-on-a-Web")?.value;
		const SOC2_compliant = inputValues?.find((input) => input.id === "md-enterprise-ready-SOC-2-compliant")?.value;
		const platform_meet_compliance = inputValues?.find((input) => input.id === "md-enterprise-ready-platform-meet-compliance")?.value;
		const technology_infrastructure_scale = inputValues?.find((input) => input.id === "md-enterprise-ready-technology-infrastructure-scale")?.value;
		const system_monitoring = inputValues?.find((input) => input.id === "md-enterprise-ready-system-monitoring")?.value;
		const robust_data_analytics = inputValues?.find((input) => input.id === "md-enterprise-ready-robust-data-analytics")?.value;
		const disaster_recovery_plan = inputValues?.find((input) => input.id === "md-enterprise-ready-disaster-recovery-plan")?.value;
		const technological_upgrades = inputValues?.find((input) => input.id === "md-enterprise-ready-technological-upgrades")?.value;
		const hardwareproduct = inputValues?.find((input) => input.id === "md-enterprise-ready-hardware-product")?.value;
		const deliver_the_hardware = inputValues?.find((input) => input.id === "md-enterprise-ready-deliver-the-hardware")?.value;
		const maintain_the_hardware = inputValues?.find((input) => input.id === "md-enterprise-ready-maintain-the-hardware")?.value;
		const hardware_returned = inputValues?.find((input) => input.id === "md-enterprise-ready-hardware-returned")?.value;
		const creativeScale = inputValues?.find((input) => input.id === "md-enterprise-ready-creativeScale")?.value;

		const enterpriseReadyJson = {
			"strategic-plan": readyStartegicPlan,
			"strategic-partnerships": readyStrategicPartnerships,
			patent: patent,
			"strategy-for-leveraging": strategy_for_leveraging,
			"startup-own-cash": startup_own_cash,
			"any-litigation": any_litigation,
			"legal-liabilities": legal_liabilities,
			"product-offering-scale": product_offering_scale,
			"unique-value-proposition": unique_value_proposition,
			"competitive-landscape": competitive_landscape,
			roadmap: ready_roadmap,
			"process-for-soliciting": process_for_soliciting,
			"support-customers": support_customers,
			"flagship-customer": flagship_customer,
			"proven-pricing-strategy": proven_pricing_strategy,
			"proven-sales": proven_sales,
			"measure-the-success": measure_the_success,
			endorsements: endorsements,
			"business-meet-operational": business_meet_operational,
			"clear-policies-for-regulatory": clear_policies_for_regulatory,
			"ready-for-procurement": ready_for_procurement,
			"attract-and-retain-talent": attract_and_retain_talent,
			"clear-HR-policies": clear_HR_policies,
			"clear-environmental-sustainability-policies": clear_environmental_sustainability_policies,
			"social-responsibility-initiatives": social_responsibility_initiatives,
			"depend-on-a-Web": depend_on_a_Web,
			"SOC-2-compliant": SOC2_compliant,
			"platform-meet-compliance": platform_meet_compliance,
			"technology-infrastructure-scale": technology_infrastructure_scale,
			"system-monitoring": system_monitoring,
			"robust-data-analytics": robust_data_analytics,
			"disaster-recovery-plan": disaster_recovery_plan,
			"technological-upgrades": technological_upgrades,
			"hardware-product": hardwareproduct,
			"deliver-the-hardware": deliver_the_hardware,
			"maintain-the-hardware": maintain_the_hardware,
			"hardware-returned": hardware_returned,
			creativeScale: creativeScale,
		};

		payLoad["enterpriseReadyJson"] = enterpriseReadyJson && JSON.stringify(enterpriseReadyJson);

		setPayLoad({ ...payLoad });
	}, [inputValues]);

	const handleEnterpriseChange = (id, value) => {
		setInputValues((prevInputValues) => prevInputValues.map((input) => (input.id === id ? { ...input, value, error: "" } : input)));
	};

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				rowGap: "30px",
			}}
		>
			<Box>
				<Typography variant="poppinsSemiBold20">Are You Enterprise Ready?</Typography>
			</Box>
			<Grid container rowSpacing={4} columnSpacing={7}>
				<Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
					<CustomAccordion label={"Risk"} open={true} background="#F5F9FA">
						<NewPlatformComponent category="Risk" inputValues={inputValues} handleInputChange={handleEnterpriseChange} />
					</CustomAccordion>
				</Grid>
				<Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
					<CustomAccordion label={"Product/Service"} open={true} background="#F5F9FA">
						<NewPlatformComponent category="Product-Service" inputValues={inputValues} handleInputChange={handleEnterpriseChange} />
					</CustomAccordion>
				</Grid>
				<Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
					<CustomAccordion label={"Business/Operations"} open={true} background="#F5F9FA">
						<NewPlatformComponent category="Business-Operations" inputValues={inputValues} handleInputChange={handleEnterpriseChange} />
					</CustomAccordion>
				</Grid>
				<Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
					<CustomAccordion label={"Software/Platform"} open={true} background="#F5F9FA">
						<NewPlatformComponent category="Software" inputValues={inputValues} handleInputChange={handleEnterpriseChange} />
					</CustomAccordion>
				</Grid>
				<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
					<CustomAccordion label={"Hardware"} open={true} background="#F5F9FA">
						<NewPlatformComponent category="Hardware" inputValues={inputValues} handleInputChange={handleEnterpriseChange} />
					</CustomAccordion>
				</Grid>
			</Grid>
		</Box>
	);
};

export default EnterpriseReadySection;
