import {
  CircularProgress,
  useMediaQuery,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  InputAdornment,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DialogComponent from "../../../../../common/CommonDialog";
import SelectInput from "../../../../../common/SelectInput";
import RichTextEditor from "../../../../../common/RichTextEditor";
import { stateToMarkdown } from "draft-js-export-markdown";
import {
  RICH_TEXT_EDITOR_MAIN_CONTROLS,
  RICH_TEXT_EDITOR_INLINE_CONTROLS,
} from "../../../../../constants/plugin-constants";
import { convertDraftJsToPlainText } from "../../../../../utils/rte-utils";
import messages from "../../../../../constants/messages";
import MessageHandlerModal from "../../../../../common/modal/MessageHandler";

export default function FeatureDescriptionForm({
  open,
  onClose,
  answers,
  setAnswers,
  handleUpdateInput,
  solutions,
  index,
}) {
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("md"));
  const id = "F1000302";
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [data, setData] = useState("");
  const [description, setDescription] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);
  const [count, setCount] = useState(0);
  const [hasChanges, setHasCanges] = useState(false);
  const [discardPopup, setDiscardPopup] = useState(false);

  useEffect(() => {
    if (answers) {
      setData(answers);
      if (answers[index]?.featureDescription) {
        setDescription(answers[index]?.featureDescription);
      }
    }
  }, [answers, index]);

  const handleOnChange = (e, inputKey) => {
    let obj = [...data];
    obj[index][inputKey] = e.target.value;
    setData(obj);
  };

  useEffect(() => {
    if (data[index]?.featureDescription) {
      if (
        data[index]?.featureDescription?.length < 4 ||
        data[index]?.featureDescription?.length > 301
      ) {
        setSaveDisabled(true);
      } else {
        setSaveDisabled(false);
      }
    }
  }, [data]);

  const handleChange = value => {
    const inputVal = stateToMarkdown(value.getCurrentContent());
    setCount(inputVal?.trim().length);
    if (inputVal?.trim().length < 301) {
      let obj = [...data];
      obj[index].featureDescription = inputVal;
      setData(obj);
      if (inputVal == description) {
        setHasCanges(false);
      } else {
        setHasCanges(true);
      }
      setErrorMessage("");
    } else {
      setErrorMessage(true);
      setSaveDisabled(true);
    }
  };

  const handleSave = () => {
    setAnswers(data);

    setData("");
    onClose();
  };

  return (
    <>
      <DialogComponent
        isOpen={open}
        onClose={onClose}
        maxWidth="sm"
        dialogAction={
          <>
            <Box
              sx={{
                display: "flex",
                gap: { xs: 1, sm: 2 },
                flexDirection: "row",
                px: 2,
                maxWidth: 1,
              }}
            >
              <Button
                variant="outlined"
                sx={{ width: "max-content" }}
                onClick={() => {
                  hasChanges ? setDiscardPopup(true) : onClose();
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                sx={{ width: "max-content" }}
                onClick={() => handleSave()}
                disabled={saveDisabled}
              >
                Save Changes
              </Button>
            </Box>
          </>
        }
        footerStyle={{
          border: "none",
        }}
        PaperProps={{
          style: {
            borderRadius: 0,
            width: isSmallScreen
              ? "100%"
              : theme => theme.breakpoints.values.sm,
          },
        }}
        disableBackDropClick
      >
        <Grid container sx={{ display: "flex", gap: 3 }}>
          <Grid item xs={12}>
            <Typography variant="onsurface_title_thick">
              Feature Description
            </Typography>
          </Grid>
          <Grid xs={12} md={12}>
            <TextField
              label="Unique ID"
              fullWidth
              disabled
              value={"F" + index}
            />
          </Grid>
          <Grid xs={12}>
            <SelectInput
              fullWidth={true}
              label="Solution Name"
              inputKey="solution"
              options={solutions ? solutions : []}
              selectedValue={data[index]?.solution ? data[index]?.solution : ""}
              onInputChange={e => handleOnChange(e, "solution", index)}
              sx={{ width: 1 }}
            />
          </Grid>
          <Grid xs={12}>
            <TextField
              fullWidth
              label="Feature Name"
              inputProps={{ maxLength: "30" }}
              value={data[index]?.featureName ? data[index]?.featureName : ""}
              onChange={e => handleOnChange(e, "featureName", index)}
            />
          </Grid>
          <Grid xs={12}>
            <Box
              sx={theme => ({
                border: `1px solid ${theme.palette.secondary.borderLightNew}`,
                borderRadius: theme.shape.standard_small.borderRadius,
                px: 2,
                minHeight: "250px",
              })}
            >
              <RichTextEditor
                label="Enter description..."
                value={data[index]?.featureDescription}
                toolbar={true}
                inlineToolbar={false}
                inlineToolbarControls={RICH_TEXT_EDITOR_INLINE_CONTROLS}
                controls={RICH_TEXT_EDITOR_MAIN_CONTROLS}
                readOnly={false}
                onChange={handleChange}
                //onSave={handleChange}
                minHeight={"250px"}
                showAutoFocus
              />
            </Box>
            <Box
              sx={{
                width: 1,
                pt: 0.5,
                display: "flex",

                color: theme => theme.palette.text.onSurfaceVariant,
                justifyContent: errorMessage ? "space-between" : "flex-end",
              }}
            >
              {errorMessage && (
                <Typography variant="error_text">
                  Input should less than 300 characters
                </Typography>
              )}
              <Typography variant="caption">{count}/300</Typography>
            </Box>
          </Grid>
          <Grid xs={12}>
            <SelectInput
              fullWidth={true}
              label={"Recommendation"}
              inputKey="solution"
              options={["None", "Double-down", "Expand", "Ignore"]}
              sx={{ width: 1 }}
              selectedValue={
                data[index]?.recommandation ? data[index]?.recommandation : ""
              }
              onInputChange={e => handleOnChange(e, "recommandation", index)}
            />
          </Grid>
        </Grid>
      </DialogComponent>
      <MessageHandlerModal
        isOpen={discardPopup}
        onCancel={() => {
          setDiscardPopup(false);
        }}
        onOk={() => {
          onClose();
          setHasCanges(false);
          setDiscardPopup(false);
        }}
        okText="Discard"
        cancelText={"No thanks"}
        heading="Discard changes"
        text={"Are you sure you want to discard your changes?"}
        messageType="primary"
      />
    </>
  );
}
