import { Box, Typography } from "@mui/material";
import React from "react";
import SvgComponent from "../../../../../utils/SVGColor";

const Item = ({ iconComp, textComp, isSvg }) => (
	<Box
		sx={{
			display: "flex",
			gap: "10px",
			//width: "168px", // Explicit width set to ensure 2 col forced layout to match prototype.
		}}
	>
		{!isSvg ? (
			<Box
				sx={{
					height: "24px",
					width: "24px",
					color: "#98A2B3",
				}}
			>
				{iconComp}
			</Box>
		) : (
			<Box>
				<SvgComponent strokeColor={"#98A2B3"} svgUrl={iconComp} style={{ height: "24px", width: "24px" }} />
			</Box>
		)}
		<Typography
			variant="customValue"
			component={"div"}
			sx={{
				color: "black",
				fontFamily: "PoppinsMedium",
				fontSize: "16px",
				lineHeight: "24px",
			}}
		>
			{textComp}
		</Typography>
	</Box>
);

function TagList({ items }) {
	return (
		<Box sx={{ display: "flex", columnGap: "40px", rowGap: "20px", flexWrap: "wrap" }}>
			{items.map((item, index) => {
				const eventHandler = {};
				if (item?.onClick) {
					eventHandler.onClick = item?.onClick;
				}
				return (
					<Box key={index} sx={{ flex: "1 1 45%", ...(Object.keys(eventHandler)?.length > 0 && { cursor: "pointer" }) }} {...eventHandler}>
						<Item iconComp={item.iconUrl} textComp={item.label} isSvg={true} />
					</Box>
				);
			})}
		</Box>
	);
}

export default TagList;
