import React, { useState, useEffect } from "react";
import { Box, Button, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import services from "../../../services";
import { toastContainer } from "../../../utils/toast";
import messages from "../../../constants/messages";
import UpdatedVideoUploader from "../../../common/UpdatedVideoUploader";
import { setUserProfileData } from "../../../modules/actions/userProfileActions";
import { setAuthData } from "../../../modules/actions";
import ProfileCoPilot from "../../ProfileCoPilot";
import { getRoleValue } from "../../../utils/commonFunctions";

const MyVideoTab = ({ handleClose, height, width }) => {
	const userDetails = useSelector((state) => state?.userProfileData?.userData);
	const [payLoad, setPayLoad] = useState({ ...userDetails });
	const [isLoading, setIsLoading] = useState(false);
	const dispatch = useDispatch();

	useEffect(() => {
		if (userDetails) {
			setPayLoad({ ...userDetails });
		}
	}, [userDetails]);

	const handleUpdateUser = () => {
		setIsLoading(true);
		let payloadData = payLoad ? payLoad : userDetails;

		services
			.updateUserProfile({
				...payloadData,
			})
			.then((response) => {
				setIsLoading(false);
				if (response.data.message === "Success") {
					dispatch(
						setUserProfileData({
							userData: response?.data?.data,
						})
					);
				}
			})
			.catch((e) => {
				console.log(e);
				setIsLoading(false);
			});
	};
	const handleAISave = async (aiData, eventType) => {
		let payloadData = null;

		if (eventType && eventType == "ai_suggestions") {
			payloadData = payLoad;
			payloadData = {
				...payloadData,
				about: aiData?.about ? aiData?.about : payloadData?.about,
			};
		} else {
			payloadData = aiData ? aiData : payLoad;
		}

		return services
			.updateUserProfile({
				...payloadData,
			})
			.then((response) => {
				if (response.data.message === "Success") {
					dispatch(
						setUserProfileData({
							userData: response.data.data,
						})
					);
					if (eventType === "ai_suggestions") {
						handleClose();
					}
					dispatch(
						setAuthData({
							userData: { picture: response.data.data.picture },
							userName: response.data.data.name,
						})
					);
					setPayLoad(response.data.data);
					return response;
				}
			})
			.catch((e) => {
				console.log("Submit", e);
				toastContainer(messages.API_SUBMIT_ERROR, "error");
			});
	};

	return (
		<Box>
			<Grid container>
				<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
					<UpdatedVideoUploader
						title={"My Video"}
						instructions={"Your video photo will be displayed vertically."}
						width={width}
						height={height}
						shape={"rectangle"}
						userData={payLoad}
						setPayLoad={setPayLoad}
						type={"videoUrl"}
						editTitle={"Edit Video"}
						fileType={{
							"video/3gpp": [".3gp"],
							"video/mp4": [".mp4"],
							"video/mov": [".mov"],
							"video/webm": [".webm"],
							"video/ogg": [".ogg"],
						}}
					/>
				</Grid>
			</Grid>
			<Box
				sx={{
					display: "flex",
					gap: 2,
					justifyContent: "end",
					alignItems: "center",
					mt: 3,
				}}
			>
				<Button
					onClick={handleClose}
					variant="noOutlineNew"
					sx={{
						height: "40px",
						m: 0,
						width: "fit-content",
						fontSize: "18px",
						lineHeight: "28px",
						fontFamily: "PoppinsSemiBold",
					}}
				>
					Cancel
				</Button>

				<ProfileCoPilot
					handleSave={handleUpdateUser}
					handleAISave={handleAISave}
					saveLoading={isLoading}
					saveDisabled={false}
					handleCloseAI={handleClose}
				/>
			</Box>
		</Box>
	);
};

export default MyVideoTab;
