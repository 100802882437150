import React, { useEffect, useState, useRef } from "react";
import { Box, Grid, ImageList, ImageListItem, CircularProgress, Typography, useMediaQuery, Button } from "@mui/material";
import Masonry from "@mui/lab/Masonry";

import ROUTES_PATH from "../../../../constants/routes";
import { useHistory } from "react-router-dom";
import ResearchCuratorCard from "../../../../components/ResearchCuratorCard";
import services from "../../../../services";
import { Add } from "@mui/icons-material";
import { handleMixPanel } from "../../../../utils/mixPanelEventHandling";

export default function OtherNotesSection({ view, sortBy, searchText, filter, setCallbackRefresh, callbackRefresh }) {
	const history = useHistory();
	const perPage = 10;
	const [pageData, setPageData] = useState({ isLast: false });
	const [loading, setLoading] = useState(false);
	const [notesPageIndex, setNotesPageIndex] = useState(1);
	const [listItems, setListItems] = useState([]);
	const [lastElement, setLastElement] = useState(null);
	const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
	const [callback, setCallback] = useState(false);

	const getColumns = (width) => {
		if (width < 1000) {
			return 1;
		} else if (width < 1300) {
			return 2;
		} else {
			return 3;
		}
	};

	const [columns, setColumns] = useState(getColumns(window.innerWidth));
	const updateDimensions = () => {
		setColumns(getColumns(window.innerWidth));
	};

	useEffect(() => {
		window.addEventListener("resize", updateDimensions);
		return () => window.removeEventListener("resize", updateDimensions);
	}, []);

	const getNotesApiCall = () => {
		const tags = filter.tags;
		const types = filter.type;
		services
			.getNotesList(notesPageIndex, perPage, sortBy.order, sortBy.key, searchText, tags, types)
			.then((result) => {
				const data = result?.data?.data;
				setLoading(false);
				setPageData({
					totalPages: data?.totalPages || 0,
					totalElements: data.totalElements || 0,
					isFirst: data.isFirst,
					isLast: data.isLast,
				});

				const items = data?.models || [];

				if (searchText || filter || sortBy) {
					if (!data.isFirst) {
						setListItems((prev) => [...prev, ...items]);
					} else {
						setListItems(items);
					}
				} else {
					setListItems((prev) => [...prev, ...items]);
				}
				setCallback(false);
				setCallbackRefresh(false);
			})
			.catch((error) => {
				setLoading(false);
				console.log(error);
			});
	};

	useEffect(() => {
		setLoading(true);
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		});
		getNotesApiCall();
	}, []);

	const handleCardClick = (noteItem) => {
		let routePath = "";

		if (noteItem.noteType === "NOTE") {
			routePath = noteItem.isCreatedByUser
				? ROUTES_PATH.CREATE_RESEARCH_CURATOR + "/" + noteItem.id
				: ROUTES_PATH.RESEARCH_CURATOR_NOTE.replace(":id", noteItem.id);
				handleMixPanel("PowerDot Viewed", {
					"PowerDot ID": noteItem.id
				});
		} else {
			routePath = noteItem.isCreatedByUser 
				? ROUTES_PATH.CREATE_QUICK_NOTE + "/" + noteItem.id 
				: ROUTES_PATH.QUICK_NOTE_DETAILS + "/" + noteItem.id;
				handleMixPanel("Quick Note Viewed", {
					"Quick Note ID": noteItem.id
				});
		}

		history.push(routePath);
	};

	useEffect(() => {
		setLoading(true);
		if (!searchText) {
			setListItems([]);
			setPageData({ ...pageData, isLast: false, isFirst: true });

			setNotesPageIndex(1);
		} else {
			setPageData({ ...pageData, isLast: false, isFirst: true });
			setNotesPageIndex(1);
		}
	}, [searchText, filter, sortBy]);

	useEffect(() => {
		if (pageData.totalPages >= 0) {
			if (searchText || filter || sortBy) {
				if (pageData.totalPages === 0) {
					getNotesApiCall();
				} else {
					if (pageData.totalPages >= notesPageIndex) {
						getNotesApiCall();
					}
				}
			} else {
				if (!pageData.isLast) {
					getNotesApiCall();
				}
			}
		}
	}, [notesPageIndex, searchText, filter, sortBy]);

	const observer = useRef(
		new IntersectionObserver((entries) => {
			const first = entries[0];
			if (first.isIntersecting) {
				setNotesPageIndex((notesPageIndex) => notesPageIndex + 1);
			}
		})
	);

	useEffect(() => {
		const currentElement = lastElement;
		const currentObserver = observer.current;

		if (currentElement) {
			currentObserver.observe(currentElement);
		}

		return () => {
			if (currentElement) {
				currentObserver.unobserve(currentElement);
			}
		};
	}, [lastElement]);

	useEffect(() => {
		if (callback || callbackRefresh) {
			setCallbackRefresh(true);
			setNotesPageIndex(1);
			getNotesApiCall();
		}
	}, [callback, callbackRefresh]);

	return (
		<>
			<Box sx={{ px: { md: 1, lg: 1 } }}>
				<Box>
					{view !== "LIST" && (
						<Box
							sx={{
								mt: 3,
								mx: isSmallScreen ? 0 : 1,
								display: "flex",
								gap: 1,
								alignItems: "baseline",
								mb: 2,
							}}
						>
							<Typography variant="title_medium">Other</Typography>{" "}
							<Typography variant="caption" sx={{ color: "secondary.dark" }}>
								{pageData?.totalElements > 0 ? pageData.totalElements + " total" : ""}
							</Typography>
						</Box>
					)}
					{view === "LIST" ? (
						<Box
							sx={{
								//mt: 2,
								px: isSmallScreen ? 0 : 1,
								pb: isSmallScreen ? 0 : 2,
							}}
						>
							{listItems.map((item, index) => {
								return (
									<Grid container key={index} justifyContent="center">
										<Grid
											item
											md={5}
											lg={6}
											sm={12}
											xs={12}
											sx={{
												mx: isSmallScreen ? 0 : 2,
												mt: isSmallScreen ? 0 : 1,
												mb: 1,
											}}
										>
											{index === 0 && (
												<Box
													sx={{
														mt: 3,
														mx: 1,
														mb: 2,
														display: "flex",
														gap: 1,
														alignItems: "baseline",
													}}
												>
													<Typography variant="title_medium">Other</Typography>{" "}
													<Typography variant="caption" sx={{ color: "secondary.dark" }}>
														{pageData?.totalElements > 0 ? pageData.totalElements + " total" : ""}
													</Typography>
												</Box>
											)}
											<ResearchCuratorCard cardData={item} handleCardClick={() => handleCardClick(item)} setCallback={setCallback} />
										</Grid>
									</Grid>
								);
							})}
						</Box>
					) : (
						<Box
							sx={{
								px: isSmallScreen ? 1 : 0,
							}}
						>
							{listItems.length > 0 && (
								<Masonry
									columns={columns}
									spacing={2}
									// variant="masonry"
									// cols={columns}

									// gap={20}
									// sx={{ mt: 1, overflowY: "initial" }}
									sx={{ width: "auto" }} // so it don't add negative margin fix
								>
									{listItems.map((item, index) => (
										<ImageListItem key={item.id} sx={{ width: "-webkit-fill-available" }}>
											<ResearchCuratorCard
												index={index}
												cardData={item}
												handleCardClick={() => handleCardClick(item)}
												setCallback={setCallback}
											/>
										</ImageListItem>
									))}
								</Masonry>
							)}
						</Box>
					)}
					{listItems && listItems.length === 0 ? (
						<Box sx={{ py: 1, textAlign: "center" }}>
							{loading ? (
								<CircularProgress />
							) : (
								<>
									<Box
										sx={{
											display: "flex",
											width: 1,
											justifyContent: "center",
											alignContent: "center",
											py: 5,
										}}
									>
										<Typography color="primary" variant="h5" sx={(theme) => ({ color: theme.palette.secondary.dark })}>
											Add a PowerDot.
										</Typography>
									</Box>
									<Box>
										<Typography color="primary" variant="h5" sx={(theme) => ({ color: theme.palette.secondary.dark })}>
											Add your own PowerDots and Quick Notes here!
											<br />
											<br />
											<Button
												startIcon={<Add />}
												sx={{ width: "max-content", ml: 2 }}
												onClick={() => history.push(ROUTES_PATH.CREATE_RESEARCH_CURATOR)}
											>
												New PowerDot
											</Button>
										</Typography>
									</Box>
								</>
							)}
						</Box>
					) : (
						""
					)}
				</Box>
			</Box>
			{!loading && !pageData.isLast && (
				<Box
					sx={{
						display: "flex",
						width: 1,
						justifyContent: "center",
						mt: 1,
						py: 4,
					}}
					ref={setLastElement}
				></Box>
			)}
		</>
	);
}
