import { removeFromLocalStorage } from "../../../constants/Theme/ThemeContext";
import services from "../../../services";
import { toastContainer } from "../../../utils/toast";

export const getListOfCategoryApiCall = (category) => {
	return services
		.getListOfCategoryApi(category)
		.then((result) => {
			return result;
		})
		.catch((error) => {
			return error;
		});
};

export const getListOfCountryStateApiCall = () => {
	return services
		.getListOfCountryStateApi()
		.then((result) => {
			return result;
		})
		.catch((error) => {
			return error;
		});
};

export const saveOrHideMatchedCollections = ({ entityIds, collectionType, entityType = "USER", isRemove = false }) => {
	return services
		.updateCollections({ entityIds, collectionType, entityType }, isRemove)
		.then((result) => {
			return result;
		})
		.catch((error) => {
			return error;
		});
};

export const getProxyInvstors = () => {
	return services
		.getProxyInvestorsApi()
		.then((result) => {
			return result;
		})
		.catch((error) => {
			return error;
		});
};

export const requestProxyInvestorConnect = (data) => {
	return services
		.requestProxyInvestorConnectApi(data)
		.then((result) => {
			return result;
		})
		.catch((error) => {
			return error;
		});
};

export const verifyInvitationToken = async (emailToken) => {
	try {
		const res = await services.verifyInvitationToken({ token: emailToken });
		console.log(`res----------------->`, res);
		if (res.status === 200) {
			toastContainer("You are successfully added", "success");
			removeFromLocalStorage("emailToken");
			removeFromLocalStorage("routeAfterLogin");
			return true;
		}
	} catch (error) {
		toastContainer("Token is expired", "error");
		removeFromLocalStorage("emailToken");
		removeFromLocalStorage("routeAfterLogin");
		console.log(`Error verify invitation token`, error);
	}
	return false;
};
