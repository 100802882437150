import React from "react";
import { FormControl, Select, MenuItem, InputLabel, Box, TextField, InputAdornment } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import UnpublishedOutlinedIcon from "@mui/icons-material/UnpublishedOutlined";

export default function SelectCheckedInput({
	label,
	onInputChange,
	selectedValue,
	fullWidth,
	options,
	inputKey,
	placeholder,
	labelStyle = {},
	borderNone = false,
	children,
	labelId = "select-label",
	id = "demo-simple-select",
	displayEmpty = false,
	compact = false,
	multiple = false,
	height,
	...props
}) {
	return (
		<FormControl fullWidth={fullWidth} sx={{ ...props.wrapperstyle }}>
			{label && (
				<InputLabel id="select-label" sx={labelStyle}>
					{label}
				</InputLabel>
			)}
			<svg width={0} height={0}>
				<linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
					<stop offset={0} stopColor="#FFD141" />
					<stop offset={1} stopColor="#E97300" />
				</linearGradient>
			</svg>
			<Select
				multiple={multiple}
				displayEmpty
				placeholder={placeholder}
				labelId={labelId}
				id={id}
				label={label}
				fullWidth={fullWidth}
				IconComponent={selectedValue ? CheckCircleIcon : UnpublishedOutlinedIcon}
				select
				onChange={(e) => onInputChange(e, inputKey)}
				value={selectedValue || []}
				{...props}
				sx={{
					width: compact ? "max-content" : 1,
					height: height,

					"& .MuiOutlinedInput-notchedOutline": {
						border: borderNone && "none",
					},
					"& .MuiSelect-icon": {
						color: selectedValue && `success.main`,
						fill: !selectedValue && "url(#linearColors)",
						transform: "none",
					},
				}}
				//renderValue={
				//  !multiple &&
				//  (selectedValue => {
				//    if (selectedValue?.length === 0) {
				//      return placeholder;
				//    }
				//    return selectedValue;
				//  })
				//}
			>
				{children
					? children
					: options.map((option) => (
							<MenuItem key={option} value={option}>
								{option}
							</MenuItem>
					  ))}
			</Select>
		</FormControl>
	);
}
