import services from "../../../../services";
import numeral from "numeral";

function processTSSPowerUP(data) {
	try {
		const latestCompletedObj = data.reduce((acc, curr) => {
			if (curr.isCompleted && (!acc || new Date(curr.completionDate) > new Date(acc.completionDate))) {
				return curr;
			}
			return acc;
		}, null);

		if (!latestCompletedObj || !latestCompletedObj?.moduleStepResponses) {
			return null;
		}

		const tamValues = JSON.parse(latestCompletedObj?.moduleStepResponses?.find((responseObj) => responseObj.moduleStep.stepNo === 1)?.answers);
		const samValues = JSON.parse(latestCompletedObj?.moduleStepResponses?.find((responseObj) => responseObj.moduleStep.stepNo === 2)?.answers);
		const somValues = JSON.parse(latestCompletedObj?.moduleStepResponses?.find((responseObj) => responseObj.moduleStep.stepNo === 3)?.answers);
		const growthValues = JSON.parse(latestCompletedObj?.moduleStepResponses?.find((responseObj) => responseObj.moduleStep.stepNo === 4)?.answers);

		let TSSValues = {};
		let calculatedTam, calculatedSam, calculatedSom;

		if (tamValues) {
			const tamAcv = parseInt(tamValues?.acv);
			const tamNoOfPeople = parseInt(numeral(tamValues?.noOfPeople)?.value()?.toString());
			const tamTimesPerYear = parseInt(tamValues?.timesPerYear);
			calculatedTam = tamAcv * tamNoOfPeople * tamTimesPerYear;

			TSSValues = {
				...TSSValues,
				TAM: {
					tamAcv: tamAcv,
					tamNoOfPeople: tamNoOfPeople,
					tamTimesPerYear: tamTimesPerYear,
					calculatedTam: isNaN(calculatedTam) ? 0 : calculatedTam,
				},
			};
		}

		if (samValues) {
			const tamValue = parseInt(calculatedTam);
			const samPercentageOfPopulation = parseFloat(samValues?.percentageOfPopulation);
			const samPercentageOfSam = parseFloat(samValues?.percentageOfSam);
			if (tamValue > 0) {
				calculatedSam = tamValue * (samPercentageOfPopulation / 100) * (samPercentageOfSam / 100);
			}
			TSSValues = {
				...TSSValues,
				SAM: {
					focusOn: samValues?.focusOn,
					samPercentageOfPopulation: samPercentageOfPopulation,
					furtherFocusOn: samValues?.furtherFocusOn,
					basedIn: samValues?.basedIn,
					percentageOfSam: samPercentageOfSam,
					calculatedSam: isNaN(calculatedSam) ? 0 : calculatedSam,
				},
			};
		}

		if (somValues) {
			const samValue = parseInt(calculatedSam);
			const somPercentageOfSam = parseFloat(somValues?.percentageOfSam);
			calculatedSom = samValue * ((100 - somPercentageOfSam) / 100);
			TSSValues = {
				...TSSValues,
				SOM: {
					noFocusOn: somValues?.noFocusOn,
					percentageOfSam: somPercentageOfSam,
					calculatedSom: isNaN(calculatedSom) ? 0 : calculatedSom,
				},
			};
		}

		if (growthValues) {
			TSSValues = {
				...TSSValues,
				GROWTH: {
					largerMarket: growthValues?.largerMarket,
					largerMarketValue: growthValues?.largerMarketValue,
					projectedGrowth: growthValues?.projectedGrowth,
				},
			};
		}

		return TSSValues;
	} catch (e) {
		console.log("Function error when attempting to get the latest complete TAM SAM SOM PowerUP data for company. Failed with error: ", e);
		return {};
	}
}

export function getTSSPowerUP(companyID) {
	return services
		.getTSSPowerUPData(companyID)
		.then((resp) => {
			if (resp?.data?.data) {
				return processTSSPowerUP(resp?.data?.data);
			}
		})
		.catch((e) => {
			console.log("API error when attempting to get TAM SAM SOM PowerUP data for company. Failed with error: ", e);
		});
}

export function formatNumberToString(number) {
	if (isNaN(number)) {
		return "N/A";
	} else if (number < 1000) {
		return "<$1k";
	} else if (number < 1000000) {
		const valueInThousands = Math.floor(number / 1000);
		return "$" + valueInThousands + "k";
	} else if (number < 1000000000) {
		const valueInMillions = Math.floor(number / 1000000);
		return "$" + valueInMillions + "M";
	} else if (number < 1000000000000) {
		const valueInBillions = (number / 1000000000).toFixed(2);
		return "$" + valueInBillions + "B";
	} else {
		return ">$1T";
	}
}
