import React, { Fragment, useState } from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import TooltipWrapper from "../../common/Tooltip";
import { PROFILE_EDIT_LIST } from "../../constants";
import { EditTabs, EditTabsItem } from "./style";
import MyDetailsTab from "../../components/UpdatedMentorProfile/MyDetailsSection";
import MyPhotosSection from "../../components/UpdatedMentorProfile/MyPhotosSection";
import MyVideoTab from "../../components/UpdatedMentorProfile/MyVideoSection";
import ExperienceAndEducationTab from "../../components/UpdatedMentorProfile/ExperienceAndEducationSection";
import Availability from "../Meetings/Availability";
import { useWindowSize } from "../../utils/windowResize";

const EditMentorProfileController = ({ handleClose }) => {
	const [currentTab, setCurrentTab] = useState(0);
	const size = useWindowSize();
	const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
	const responsiveVideoHeight = size.width * 0.8;
	const responsiveVideoWidth = responsiveVideoHeight * 0.85;
	const handleChange = (event, newValue) => {
		setCurrentTab(newValue);
	};

	return (
		<>
			<Box>
				<EditTabs variant="scrollable" scrollButtons="auto" allowScrollButtonsMobile value={currentTab} onChange={handleChange}>
					<Box display="flex" flexWrap="wrap" gap={3}>
						{PROFILE_EDIT_LIST.map((item, index) => {
							const tabItem = (
								<EditTabsItem
									selected={currentTab === item.id}
									onClick={(event) => {
										handleChange(event, index);
									}}
									disabled={item.disabled}
									disableRipple
									key={item.id}
									label={
										<Typography
											variant="Text/sm/Semibold"
											sx={{
												color: "inherit",
												background: "inherit",
												borderRadius: "100px",
											}}
										>
											{item.title}
										</Typography>
									}
								/>
							);
							return <Fragment key={index}>{item.disabled ? <TooltipWrapper>{tabItem}</TooltipWrapper> : tabItem}</Fragment>;
						})}
					</Box>
				</EditTabs>
			</Box>
			<Box>
				{currentTab === 0 && <MyDetailsTab handleClose={handleClose} />}
				{currentTab === 1 && <Availability />}
				{currentTab === 2 && <MyPhotosSection handleClose={handleClose} />}
				{currentTab === 3 && (
					<MyVideoTab
						handleClose={handleClose}
						height={isSmallScreen ? responsiveVideoHeight : "610px"}
						width={isSmallScreen ? responsiveVideoWidth : "400px"}
					/>
				)}
				{currentTab === 4 && <ExperienceAndEducationTab handleClose={handleClose} />}
			</Box>
		</>
	);
};

export default EditMentorProfileController;
