import React from "react";
import { Box, Drawer, Typography, useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useWindowSize } from "../../utils/windowResize";
import { setUserProfileData } from "../../modules/actions/userProfileActions";
import { Close } from "@mui/icons-material";
import EditMentorProfileController from "../MentorProfile/EditMentorProfileController";
import { USER_TYPES } from "../../utils/userTypes";
import EditFounderProfileController from "../FounderProfileEdit/EditFounderProfileController";
import { useLocation } from "react-router-dom";
import ROUTES_PATH from "../../constants/routes";
import EditStartupProfileController from "../StartupProfileEdit/EditStartupProfileController";
import EditInvestorProfileController from "../InvestorProfileEdit/EditInvestorProfileController";

const UserProfileEditPopup = ({ disableBackDropClick }) => {
	const size = useWindowSize();
	const dispatch = useDispatch();
	const DRAWER_OPEN = useSelector((state) => state?.userProfileData?.userProfileEditOpen);
	const userType = useSelector((store) => store?.auth?.userType);
	const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
	const responsiveDrawerWidth = isSmallScreen ? size.width * 1 : size.width * 0.9;

	const maxWidth = "2000px";
	const location = useLocation();
	const handleClose = () => {
		dispatch(
			setUserProfileData({
				userProfileEditOpen: false,
			})
		);
	};

	return (
		<Drawer
			sx={{
				"& .MuiDrawer-paper": {
					borderRadius: "30px 0px 0px 30px",
					background: "#F5F9FA",
					boxShadow: "0px 5px 16px rgba(0, 0, 0, 0.3)",
				},
			}}
			anchor={"right"}
			transitionDuration={{ enter: 600, exit: 600 }}
			open={DRAWER_OPEN}
			onClose={disableBackDropClick ? () => {} : handleClose}
		>
			<Box
				width={responsiveDrawerWidth}
				sx={{
					maxWidth: maxWidth,
					// px: { xs: "16px", md: "60px" },
					// pt: { xs: "32px", md: "60px" },
					// pb: "40px",
					// display: "flex",
					// flexDirection: "column",
					// rowGap: "60px",
				}}
			>
				<Box
					sx={{
						px: { xs: "24px", md: "60px" },
						pt: { xs: "32px", md: "60px" },
						pb: "40px",
						display: "flex",
						flexDirection: "column",
						rowGap: "60px",
					}}
				>
					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
						}}
					>
						<Typography variant="poppinsSemiBold30">Edit Profile</Typography>
						<Box onClick={handleClose}>
							<Close onClick={handleClose} fontSize="medium" sx={{ color: (theme) => theme.palette.secondary.black, cursor: "pointer" }} />
						</Box>
					</Box>
					{userType === USER_TYPES.MENTOR_USER ? (
						<EditMentorProfileController handleClose={handleClose} />
					) : userType === USER_TYPES.INVESTOR_USER || userType === USER_TYPES.CORPORATE_USER ? (
						<EditInvestorProfileController handleClose={handleClose} />
					) : location.pathname === ROUTES_PATH.MY_STARTUP_PROFILE ? (
						<EditStartupProfileController handleClose={handleClose} />
					) : (
						<EditFounderProfileController handleClose={handleClose} />
					)}
				</Box>
			</Box>
		</Drawer>
	);
};

export default UserProfileEditPopup;
