import React, { useState } from "react";
import { ClickAwayListener } from "@mui/base";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Box } from "@mui/material";
import moment from "moment";

export default function CustomizedDatePicker({
	Component,
	label,
	value,
	onChange,
	toolbarComp,
	OpenPickerIcon = ArrowDropDownIcon,
	disablePast = true,
	disableFuture = false,
	views = ["month", "day", "year"],
}) {
	const [isOpen, setIsOpen] = useState(false);
	const handleOnclick = () => {
		if (isOpen === false) {
			setIsOpen(true);
		}
	};
	const handleClickAway = () => {
		if (isOpen) {
			setIsOpen(false);
		}
	};

	return (
		<ClickAwayListener onClickAway={handleClickAway}>
			<Box onClick={handleOnclick}>
				<LocalizationProvider dateAdapter={AdapterMoment}>
					<DatePicker
						open={isOpen}
						views={views}
						onClose={() => setIsOpen(false)}
						label={label}
						value={value ? moment(value) : null}
						onChange={(newValue) => {
							onChange(moment(newValue).toDate());
						}}
						disablePast={disablePast}
						disableFuture={disableFuture}
						slots={{
							OpenPickerIcon: OpenPickerIcon,
							field: Component,
							toolbar: toolbarComp,
						}}
						slotProps={{
							desktopPaper: {
								sx: {
									cursor: "pointer",
									boxShadow: (theme) => theme.palette.primary.elevationLight3,
									border: (theme) => `1px solid ${theme.palette.secondary.outline2}`,
									borderRadius: (theme) => theme.shape.standard_small.borderRadius,
								},
							},
							mobilePaper: {
								sx: {
									boxShadow: (theme) => theme.palette.primary.elevationLight3,
									border: (theme) => `1px solid ${theme.palette.secondary.outline2}`,
									borderRadius: (theme) => theme.shape.standard_small.borderRadius,
								},
							},
							openPickerButton: {
								sx: {
									pr: "5px", //adding absolute padding to position the button icon element in picker field. This way element should always be align with other pickers element
								},
							},
						}}
					/>
				</LocalizationProvider>
			</Box>
		</ClickAwayListener>
	);
}
