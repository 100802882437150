import styled from "@emotion/styled";
import { sosPrimary } from "../../constants/Theme/colors";

export const MentorExperienceWrapper = styled.div(({ theme }) => {
  return {
    padding: theme.spacing(2),
    background: sosPrimary[50],
    borderRadius: theme.spacing(1),
  };
});
