import React, { Fragment, useEffect, useState } from "react";
import { Box, Typography, Avatar, CircularProgress, Button } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import services from "../../../services";
import { useSelector } from "react-redux";
import moment from "moment";
import useDimensions from "react-cool-dimensions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ROUTES_PATH from "../../../constants/routes";
import { CALENDAR_BLANK_BLACK } from "../../../constants";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import { handleMixPanel } from "../../../utils/mixPanelEventHandling";
import CancelMeetingPopup from "../../../components/Meetings/CancelMeetingPopup";
import { toastContainer } from "../../../utils/toast";
import MeetingDetailsPopup from "../../../components/Meetings/MeetingDetailsPopup";
import ScheduleMeetingDialog from "../../../components/Networking/ScheduleMeetingDialog";
import { useLocation } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";

export const MeetingList = ({ limit, condensed = false }) => {
	const { userId } = useSelector((state) => state?.auth);
	const location = useLocation();
	const urlSearchParams = new URLSearchParams(location?.search);
	const meetingAction = urlSearchParams.get("action");
	const meetingId = urlSearchParams.get("id");
	const history = useHistory();

	const [bookings, setBookings] = useState([]);
	const [loading, setloading] = useState(false);
	const [currentBookingObj, setcurrentBookingObj] = useState(null);
	const [cancelPopupOpen, setCancelPopupOpen] = useState(false);
	const [detailsPopupOpen, setDetailsPopupOpen] = useState(false);
	const [reschedulePopupOpen, setReschedulePopupOpen] = useState(false);

	const [currentPage, setCurrentPage] = useState(1);
	const [isLastPage, setIsLastPage] = useState(false);
	const [totalCount, setTotalCount] = useState(0);
	const [isFirstPage, setIsFirstPage] = useState(false);

	const { observe, unobserve, width, height, entry } = useDimensions({});

	useEffect(() => {
		if (bookings && meetingId) {
			if (bookings.length > 0) {
				let tempBookingList = bookings.filter((item) => item.id == meetingId);
				if (tempBookingList.length > 0) {
					if (meetingAction === "cancel") {
						handleCancelBooking(tempBookingList?.[0]);
					} else if (meetingAction === "reschedule") {
						handleReschedule(tempBookingList?.[0]);
					} else {
						throw "Action type for meeting not found";
					}
				}
			}
		}
	}, [bookings]);

	const handleNextPage = () => {
		setCurrentPage(currentPage + 1);
	};

	useEffect(() => {
		if (!isLastPage) {
			getAllBookings(currentPage);
		}
	}, [currentPage]);

	const getAllBookings = async (pageNo) => {
		if (!userId) return;

		try {
			setloading(true);
			await services
				.getAllBookingsApi(userId, pageNo, 10, moment.tz.guess())
				.then((res) => {
					if (res?.data.data) {
						const response = res?.data?.data?.models;
						setIsLastPage(res?.data?.data?.isLast);
						setIsFirstPage(res?.data?.data?.isFirst);

						setTotalCount(res?.data?.data?.totalElements);

						if (!res?.data?.data?.isFirst) {
							setBookings((bl) => bl.concat([...response]));
						} else {
							setBookings(response);
						}
						setloading(false);
					}
				})
				.catch((err) => {
					console.log(err);
				});
		} catch (e) {
			console.log("Unable to map over booking data. Failed with error:", e);
			setloading(false);
		}
	};

	const getSliceIndices = () => {
		const start = 0;
		const end = limit && limit < bookings.length ? limit : bookings.length;
		return [start, end];
	};

	const handleCancelBooking = (data) => {
		setDetailsPopupOpen(false);
		setReschedulePopupOpen(false);
		setCancelPopupOpen(true);
		setcurrentBookingObj(data);

		handleMixPanel("Meeting Cancelled", {
			"Meeting ID": data?.id,
			Source: "Bookings",
		});
	};

	const handleBookingDetails = (data) => {
		setDetailsPopupOpen(true);
		setcurrentBookingObj(data);
	};

	const closePopup = () => {
		setCancelPopupOpen(false);
		setDetailsPopupOpen(false);
		setReschedulePopupOpen(false);
		setcurrentBookingObj(null);
	};

	const handleReschedule = (data) => {
		setDetailsPopupOpen(false);
		setCancelPopupOpen(false);
		setReschedulePopupOpen(true);
		setcurrentBookingObj({ ...data, rescheduling: true });
	};

	const handleRescheduleSuccess = () => {
		history.replace(location.pathname);

		setloading(true);
		closePopup();
		getAllBookings();
	};

	const handleCancelApiCall = (reason) => {
		handleMixPanel("Meeting Cancelled", {
			"Meeting ID": currentBookingObj.id,
			Source: "Confirmation",
		});

		services
			.cancelBookingApi(currentBookingObj.id, reason)
			.then((res) => {
				setloading(true);
				history.replace(location.pathname);
				toastContainer("Cancelled successfully", "success");
				closePopup();
				getAllBookings();
			})
			.catch((err) => {
				toastContainer(err.response?.data?.message || "Error", "error");
				closePopup();
				setloading(false);
			});
	};

	return (
		<Box sx={{ flex: 1, display: "flex", flexDirection: "column", mt: 1, gap: "24px" }}>
			{<Typography component={"div"} variant="Text/sm/Regular">{`Meetings scheduled [${totalCount ?? 0}]`}</Typography>}
			<Box display="flex" flexDirection="column" gap={"24px"} ref={observe}>
				<Fragment>
					{" "}
					<Box
						id="scrollableDiv"
						sx={{
							height: bookings?.length > 0 && "600px",
							overflow: "auto",
							display: "flex",
							gap: 2,
						}}
					>
						{/*Put the scroll bar always on the bottom*/}
						<InfiniteScroll
							dataLength={bookings?.length || 0}
							next={handleNextPage}
							style={{ display: "flex", flexDirection: "column", gap: "16px" }} //To put endMessage and loader to the top.
							hasMore={!isLastPage}
							loader={loading ? <h4>Loading...</h4> : <Typography variant="Text/md/Regular">You do not have any scheduled meetings.</Typography>}
							scrollableTarget="scrollableDiv"
						>
							{bookings?.length > 0
								? bookings.slice(...getSliceIndices()).map((meeting, index) => (
										<Fragment>
											<MeetingListItem
												key={meeting?.id}
												userData={meeting?.attendees[0]?.user?.id === userId ? meeting?.organizer : meeting?.attendees[0]?.user}
												status={meeting?.status}
												title={meeting?.title}
												date={{
													startTime: meeting?.startTime,
													endTime: meeting?.endTime,
													meetingDate: meeting?.meetingDate,
												}}
												containerWidth={width}
												condensed={condensed}
												meetingID={meeting?.id}
												handleDetailsPopup={() => handleBookingDetails(meeting)}
												handleCancelBooking={() => handleCancelBooking(meeting)}
												handleRescheduleBooking={() => handleReschedule(meeting)}
											/>
											{((condensed && bookings?.length > 5 && index != 4) || (!condensed && index != bookings?.length - 1)) && (
												<Box key={`barID${index}`} sx={{ width: "100%", height: "1px", background: "#EAECF0" }}></Box>
											)}
										</Fragment>
								  ))
								: !loading && <Typography variant="Text/md/Regular">You do not have any scheduled meetings.</Typography>}
						</InfiniteScroll>
					</Box>
					{/* {!bookings ||
						(bookings?.length < 1 && (
							<Box
								sx={{
									p: "24px",
								}}
							>
								<Typography variant="Text/md/Regular">You do not have any scheduled meetings.</Typography>
							</Box>
						))} */}
					{bookings?.length > limit && condensed && (
						<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 2 }}>
							<Typography variant="Text/sm/Regular">
								Showing <strong>{limit}</strong> out of <strong>{bookings?.length}</strong> events
							</Typography>
						</Box>
					)}
					{cancelPopupOpen && currentBookingObj && (
						<CancelMeetingPopup
							open={cancelPopupOpen}
							onClose={closePopup}
							bookingData={currentBookingObj}
							handleReschedule={(data) => handleReschedule(data)}
							handleCancel={handleCancelApiCall}
						/>
					)}
					{detailsPopupOpen && currentBookingObj && (
						<MeetingDetailsPopup
							open={detailsPopupOpen}
							onClose={closePopup}
							bookingData={currentBookingObj}
							handleReschedule={(data) => handleReschedule(data)}
							handleCancel={(data) => handleCancelBooking(data)}
						/>
					)}
					{reschedulePopupOpen && currentBookingObj && (
						<ScheduleMeetingDialog
							open={reschedulePopupOpen}
							handleClose={closePopup}
							userInfo={currentBookingObj?.userData}
							reschedulingEvent={currentBookingObj}
							handleReschedule={handleRescheduleSuccess}
						/>
					)}
				</Fragment>
			</Box>
		</Box>
	);
};

export const MentorMeetingListWidget = () => {
	const history = useHistory();
	return (
		<Box
			sx={{
				backgroundColor: "#fff",
				borderRadius: "40px",
				boxShadow: "0px 10px 85px rgba(0, 0, 0, 0.09)",
				padding: "45px",
			}}
		>
			<Box display="flex" alignItems="center" justifyContent="space-between" mb={3}>
				<Typography variant="title_portfolio">Mentor Meetings</Typography>
				<Box display="flex" alignItems="center" sx={{ "&:hover": { cursor: "pointer" } }} onClick={() => history.push(ROUTES_PATH.BOOKED_MEETING_LIST)}>
					<Typography variant="count_title">View All Events</Typography>
					<ArrowForwardIcon />
				</Box>
			</Box>
			<MeetingList limit={5} condensed={true} />
		</Box>
	);
};

// Function to convert UTC date to local time zone
export function convertUTCtoLocal(utcDate) {
	const localTimeZone = moment.tz.guess(); // Get the local time zone
	return moment.utc(utcDate).tz(localTimeZone).format("dddd MMMM D, YYYY");
}

// Function to convert UTC time to local time and format as 'h:mm A'
export function convertUTCtoLocalTime(utcTime) {
	const localTimeZone = moment.tz.guess(); // Get the local time zone
	return moment.utc(utcTime).tz(localTimeZone).format("h:mm A");
}

// Function to get the abbreviation of the user's local time zone
export function getTimeZoneAbbreviation() {
	const localTimeZone = moment.tz.guess(); // Get the local time zone
	return moment.tz(localTimeZone).format("z");
}

// Function to format start and end times
export function formatTimeRange(startTime, endTime) {
	const localTimeZoneAbbreviation = getTimeZoneAbbreviation();
	const localStartTime = convertUTCtoLocalTime(startTime);
	const localEndTime = convertUTCtoLocalTime(endTime);
	return `${localStartTime} ${localTimeZoneAbbreviation} - ${localEndTime} ${localTimeZoneAbbreviation}`;
}

const MeetingListItem = ({
	status,
	date,
	title,
	containerWidth,
	userData,
	condensed,
	meetingID,
	handleCancelBooking,
	handleRescheduleBooking,
	handleDetailsPopup,
}) => {
	if (userData)
		return (
			<Box
				sx={{
					...(!condensed && {
						...(containerWidth <= 600 && {
							display: "flex",
							flexWrap: "wrap",
							gap: "24px",
						}),
						...(containerWidth <= 800 &&
							containerWidth > 600 && {
								display: "grid",
								gridTemplateColumns: "auto repeat(2, 1fr)",
								gridTemplateRows: "repeat(2, auto)",
								gridColumnGap: "24px",
								gridRowGap: "8px",
							}),
						...(containerWidth > 800 && {
							display: "grid",
							gridTemplateColumns: "auto repeat(3, 1fr)",
							gridTemplateRows: "1fr",
							gridColumnGap: "24px",
							gridRowGap: "8px",
						}),
					}),
					...(condensed && {
						display: "flex",
						flexWrap: "wrap",
						gap: "24px",
					}),
				}}
			>
				<Box
					display="flex"
					sx={{
						...(!condensed && {
							...(containerWidth <= 600 && {
								flex: "0 0 auto",
								alignItems: "center",
							}),
							...(containerWidth <= 800 &&
								containerWidth > 600 && {
									gridArea: "1 / 1 / 3 / 2",
									alignItems: "flexStart",
								}),
							...(containerWidth > 800 && {
								gridArea: "1 / 1 / 2 / 2",
								alignItems: "center",
							}),
						}),
						...(condensed && {
							flex: "0 0 auto",
							alignItems: "center",
						}),
					}}
				>
					<Avatar sx={{ width: 80, height: 80 }} src={userData?.picture}></Avatar>
				</Box>
				<Box
					display="flex"
					sx={{
						...(!condensed && {
							...(containerWidth <= 600 && {
								flexBasis: "100%",
								// flex: "1 1 auto",
								// alignItems: "center",
							}),
							...(containerWidth <= 800 &&
								containerWidth > 600 && {
									gridArea: "1 / 2 / 2 / 3",
									alignItems: "flexStart",
								}),
							...(containerWidth > 800 && {
								gridArea: "1 / 2 / 2 / 3",
								alignItems: "center",
							}),
						}),
						...(condensed && {
							flex: "1",
							alignItems: "center",
						}),
					}}
				>
					<Box display="flex" flexDirection="column">
						{condensed ? (
							<Fragment>
								<Typography variant="Text/sm/Semibold">{title}</Typography>
								<Typography variant="Text/sm/Regular">{userData?.name}</Typography>
								<Typography variant="Text/sm/Regular">{date?.startTime}</Typography>
							</Fragment>
						) : (
							<Fragment>
								<Typography variant="Text/sm/Semibold">{userData?.name}</Typography>
								<Typography variant="Text/sm/Semibold">{userData?.profession}</Typography>
								<Typography variant="Text/sm/Semibold">{userData?.companyModel?.companyName}</Typography>
							</Fragment>
						)}
					</Box>
				</Box>
				{!condensed && (
					<Box
						display="flex"
						flexDirection="column"
						sx={{
							...(containerWidth <= 600 && {
								flexBasis: "100%",
							}),
							...(containerWidth <= 800 &&
								containerWidth > 600 && {
									gridArea: "2 / 2 / 3 / 3",
									alignItems: "flexStart",
								}),
							...(containerWidth > 800 && {
								gridArea: "1 / 3 / 2 / 4",
							}),
						}}
					>
						<Typography variant="Text/sm/Semibold">{title}</Typography>
						<Typography variant="Text/sm/Regular">{moment(date?.startTime?.split("T")[0]).format("LL")}</Typography>
						<Typography variant="Text/sm/Regular">{`${moment(date?.startTime?.split("T")[1], "HH:mm:ss").format("LT")} - ${moment(
							date?.endTime?.split("T")[1],
							"HH:mm:ss"
						).format("LT")}`}</Typography>
					</Box>
				)}

				{condensed ? (
					<Box
						display="flex"
						sx={{
							justifyContent: "flex-end",
							...(containerWidth <= 600 && {
								flexBasis: "100%",
							}),
							alignItems: "center",
							justifyContent: "flex-end",
							"&:hover": { cursor: "pointer" },
						}}
						onClick={handleDetailsPopup}
					>
						<Typography variant="Text/sm/Semibold">View Details</Typography>
						<ArrowForwardIcon />
					</Box>
				) : (
					<Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", gap: 1 }}>
						{/* <Button variant="containedGrayStyle" onClick={() => handleCancelBooking()} sx={{ display: "flex", width: "max-content", gap: 1, m: 0 }}>
							<HighlightOffRoundedIcon />
							<span>Cancel</span>
						</Button> */}
						<Button
							variant="containedPurpleStyle"
							sx={{ display: "flex", width: "max-content", gap: 1, m: 0 }}
							// onClick={() => {
							// 	handleRescheduleBooking();
							// }}
							onClick={() => handleCancelBooking()}
						>
							<Box component={"img"} sx={{ height: "20px", width: "20px", filter: "invert(1)" }} src={CALENDAR_BLANK_BLACK} />{" "}
							<span>View Event</span>
						</Button>
					</Box>
				)}
			</Box>
		);
	else return null;
};
