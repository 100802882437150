import { ArrowBack, Close } from "@mui/icons-material";
import { IconButton, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { HorizontalAppBar } from "../../HorizontalAppBar/index";

export const MobileControl = ({ steps, currentStep }) => {
  const [stepsState, setStepsState] = useState(null);
  const [step, setStep] = useState(null);
  const history = useHistory();

  useEffect(() => {
    setStep(currentStep);
  }, [currentStep]);

  useEffect(() => {
    setStepsState(steps);
  }, [steps]);

  const goBack = () => {
    history.goBack();
  };

  return (
    <HorizontalAppBar
      right={
        <>
          {step != 1 && (
            <IconButton onClick={() => goBack()}>
              <ArrowBack />
            </IconButton>
          )}
        </>
      }
      center={
        <Typography
          variant="Onboarding/MobileAppheader"
          sx={theme => ({
            textTransform: "uppercase",
            color: theme.palette.secondary.appBarGray,
          })}
        >
          Step {Number(step)} of {stepsState ? stepsState.length : ""}
        </Typography>
      }
    />
  );
};
