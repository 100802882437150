import React, { useContext, useEffect, useState } from "react";
import { CURRENCY_DOLLAR_CIRCLE_ICON, COINS_ICON, CALCULATOR_V1_ICON, ARROW_FAT_LINEUP_ICON } from "../../../../../constants";
import PublicTagList from "../../../Common/PublicTagList";
import { useSelector } from "react-redux";

export function formatNumberToString(number) {
	if (isNaN(number)) {
		return "N/A";
	} else if (number < 1000) {
		return "<$1k";
	} else if (number < 1000000) {
		const valueInThousands = Math.floor(number / 1000);
		return "$" + valueInThousands + "k";
	} else if (number < 10000000) {
		const valueInMillions = Math.floor(number / 1000000);
		return "$" + valueInMillions + "M";
	} else {
		return "$10M+";
	}
}

function StartupPublicFinanceTags() {
	const [items, setitems] = useState([]);
	const startupData = useSelector((state) => state?.startupPublicProfileData?.startupPublicData);

	useEffect(() => {
		let list = [
			{
				iconUrl: CURRENCY_DOLLAR_CIRCLE_ICON,
				label: (() => {
					let sentence = "";
					if (startupData?.fundingType) {
						try {
							let financeArr = JSON.parse(startupData?.fundingType);
							financeArr?.map((elem, index) => {
								sentence += elem;
								if (index < financeArr?.length - 1) {
									sentence += ", ";
								}
							});
						} catch (e) {
							console.log(e);
						}
					}
					return sentence;
				})(),
			},
			{
				iconUrl: COINS_ICON,
				label: `Revenue: ${startupData?.revenue ? startupData?.revenue : "No"}`,
			},
			{
				iconUrl: CALCULATOR_V1_ICON,
				label: startupData?.financing ? startupData?.financing : "No Data",
			},
			{
				iconUrl: ARROW_FAT_LINEUP_ICON,
				label: (() => {
					let amount = "";
					if (startupData?.capitalRaised) {
						try {
							amount = Intl.NumberFormat("en-US", {
								notation: "compact",
								maximumFractionDigits: 1,
							}).format(Number(startupData?.capitalRaised));
						} catch (e) {
							console.log(e);
						}
					}
					return "Raised: " + amount;
				})(),
			},
		];
		setitems(list);
	}, []);

	return <PublicTagList items={items} />;
}

export default StartupPublicFinanceTags;
