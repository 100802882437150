import { Box, Button, CircularProgress, Modal, Typography } from "@mui/material";
import { useState } from "react";
import services from "../../../../../services";
import { toastContainer } from "../../../../../utils/toast";
import { getRoleValue } from "../../../../../utils/commonFunctions";

export const ConfirmationModal = ({ open, handleClose, setInvited, invitedUserId, userRole = "", partnerName = "" }) => {
	const [invitationLoader, setInvitationLoader] = useState(false);
	const handleInvitationApiCall = async () => {
		let resp;
		try {
			setInvitationLoader(true);
			resp = await services.inviteUserApi({ invitedUserId });
			console.log(`res--------->`, resp);
			if (resp.status === 200) {
				setInvited(true);
				setInvitationLoader(false);
				toastContainer("User invited successfully", "success");
				handleClose();
			} else {
				toastContainer(resp.message, "error");
			}
		} catch (error) {
			if (error.response?.status?.code == 400) {
				setInvitationLoader(false);
				toastContainer(error.response?.status?.message, "error");
			}
		}
	};

	return (
		<Modal open={open} onClose={handleClose}>
			<Box
				sx={{
					position: "fixed",
					top: "50%",
					left: "50%",
					transform: "translate(-50%, -50%)",
					width: "30vw",
					maxHeight: "90vh",
					overflowY: "auto",
					backgroundColor: "white",
					borderRadius: "20px",
					p: 4,
					zIndex: 9999,
				}}
			>
				<Box display="flex" flexDirection="column" gap={2}>
					<Typography variant="Text/md/Semibold" display={"block"}>
						Invite
					</Typography>
					<Typography variant="Text/sm/Semibold" display={"block"}>
						Invite Confirmation
					</Typography>

					<Typography variant="Text/sm/Regular" display={"block"}>
						{`An email will invite this ${getRoleValue(userRole)} to join your community. This ${getRoleValue(
							userRole
						)} will have access to ${partnerName} exclusive events and community members.`}
					</Typography>
				</Box>

				<Box display={"flex"} flexDirection={"row"} alignItems={"center"} mt={3} gap={2} justifyContent={"flex-end"}>
					<Box onClick={handleClose}>
						<Button sx={{ color: (theme) => "black" }} variant="noOutlineNew">
							Cancel
						</Button>
					</Box>
					<Box>
						<Button
							startIcon={invitationLoader && <CircularProgress size={"1.25rem"} sx={{ color: "inherit" }} />}
							variant="DS1"
							onClick={() => {
								handleInvitationApiCall();
							}}
						>
							Invite
						</Button>
					</Box>
				</Box>
			</Box>
		</Modal>
	);
};
