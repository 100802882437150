import React, { useContext, useEffect } from 'react'

import PowerUps from '../../stores/powerups'

// components
import {
	Stack,
	Button,
	Breadcrumbs,
	Link,
	Typography,
	Grid,
	Paper,
	Divider,
	Table, TableHead, TableRow, TableCell, TableBody,
	Avatar,
	List, ListItem, ListItemButton, ListItemIcon, ListItemText, Checkbox
} from '@mui/material'
import { Email, FileDownload, NavigateNext, Phone } from '@mui/icons-material'

import { PageContentWrapper } from '../../../../components/Workspace/PageContentWrapper'

import ROUTES_PATH from '../../../../constants/routes'

function Responses ({ children }) {
	return <Paper elevation={1}>
		<Stack p={3} gap={3}>
			<Typography variant={'h4'}>Submission Responses</Typography>
			<Stack gap={3} component={'ol'} pl={3}>
				{children}
			</Stack>
		</Stack>
	</Paper>
}

function ResponseItem ({ question, type, answer, children }) {
	return <li>
		<Stack gap={2}>
			<Stack direction={'row'} gap={3} alignItems={'baseline'} justifyContent={'space-between'}>
				<Typography variant={'h5'} pl={2}>{question}</Typography>
				<Typography variant={'caption'} color={'secondary'}>{type}</Typography>
			</Stack>
			{answer &&
				<Typography variant={'body1'} pl={2} pb={3}>{answer}</Typography>
			}
			{children}
			<Divider sx={{ marginTop: '0 !important' }}/>
		</Stack>
	</li>
}

function Participant ({ img, name, title, email, phone }) {
	return <Stack pt={3}>
		<Stack direction={'row'} gap={3} alignItems={'center'}>
			<Avatar src={img}/>
			<Stack gap={0.25}>
				<Typography variant={'h4'}>{name}</Typography>
				{title && <Typography variant={'caption'} color={'secondary'}>{title}</Typography>}
			</Stack>
		</Stack>
		{email || phone ? <List sx={{ marginInline: -1 }}>
			{email && <ListItem disablePadding>
				<ListItemButton>
					<ListItemIcon>
						<Email/>
					</ListItemIcon>
					<ListItemText primary={email}/>
				</ListItemButton>
			</ListItem>}
			{phone && <ListItem disablePadding>
				<ListItemButton>
					<ListItemIcon>
						<Phone/>
					</ListItemIcon>
					<ListItemText primary={phone}/>
				</ListItemButton>
			</ListItem>}
		</List> : ''}
	</Stack>
}

function Details ({ img, name, location, children }) {
	return <Paper elevation={1}>
		<Stack p={3} gap={2}>
			<Typography variant={'h4'}>Submitter Details</Typography>
			<Stack direction={'row'} gap={3} alignItems={'center'}>
				<Avatar variant={'rounded'} alt={'Company Logo'} src={img}/>
				<Stack>
					<Typography variant={'h3'}>{name}</Typography>
					<Typography variant={'caption'} color={'secondary'}>{location}</Typography>
				</Stack>
			</Stack>
			<Stack>
				<Typography variant={'h5'}>Participants</Typography>
				{children}
			</Stack>
		</Stack>
	</Paper>
}

export default function Submission ({ match: { params: { id, sid } }, auth }) {
	const powerUps = useContext(PowerUps.Context)

	useEffect(() => {
		powerUps.getSubmission({ id, sid })
	})

	const title = powerUps.powerUp.submission?.title

	return <PageContentWrapper>
		<Stack gap={1} mb={3}>
			<Stack direction="row" alignItems="center" justifyContent="space-between">
				<Typography variant="h3">PowerUp Submission</Typography>
				<Button sx={{ margin: 'unset', width: 'unset' }} endIcon={<FileDownload/>}>Export Responses</Button>
			</Stack>
			<Breadcrumbs separator={<NavigateNext fontSize="small"/>}>
				<Link underline="hover" sx={{ cursor: 'pointer' }} href={ROUTES_PATH.PARTNER_POWERUPS}>
					<Typography variant="h5">PowerUps</Typography>
				</Link>
				<Link underline="hover" sx={{ cursor: 'pointer' }} href={ROUTES_PATH.PARTNER_POWERUP}>
					<Typography variant="h5">{title}</Typography>
				</Link>
				<Typography variant="h5">Submission</Typography>
			</Breadcrumbs>
		</Stack>
		<Grid container spacing={3}>
			<Grid item md={8}>
				<Responses>
					<ResponseItem question={'How many roads must a man walk down?'} type={'Short Answer'}
								  answer={'42'}/>
					<ResponseItem question={'Who you gonna call?'} type={'Paragraph'} answer={'Ghostbusters'}/>
					<ResponseItem question={'What\'s that?'} type={'Checkboxes Grid'}>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>
										&nbsp;
									</TableCell>
									<TableCell align={'center'}>
										<Typography variant={'h5'}>Mo Problems</Typography>
									</TableCell>
									<TableCell align={'center'}>
										<Typography variant={'h5'}>Mo Betta</Typography>
									</TableCell>
									<TableCell align={'center'}>
										<Typography variant={'h5'}>Mos Def</Typography>
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								<TableRow>
									<TableCell align={'right'}>
										<Typography variant={'h5'}>Mo Money</Typography>
									</TableCell>
									<TableCell align={'center'}>
										<Checkbox checked readOnly/>
									</TableCell>
									<TableCell align={'center'}>
										<Checkbox disabled/>
									</TableCell>
									<TableCell align={'center'}>
										<Checkbox disabled/>
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell align={'right'}>
										<Typography variant={'h5'}>Mo Lizards</Typography>
									</TableCell>
									<TableCell align={'center'}>
										<Checkbox disabled/>
									</TableCell>
									<TableCell align={'center'}>
										<Checkbox checked readOnly/>
									</TableCell>
									<TableCell align={'center'}>
										<Checkbox disabled/>
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell align={'right'}>
										<Typography variant={'h5'}>Momofuku</Typography>
									</TableCell>
									<TableCell align={'center'}>
										<Checkbox disabled/>
									</TableCell>
									<TableCell align={'center'}>
										<Checkbox disabled/>
									</TableCell>
									<TableCell align={'center'}>
										<Checkbox checked readOnly/>
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</ResponseItem>
				</Responses>
			</Grid>
			<Grid item md={4}>
				<Details name={'Facc'} location={'Pleasanton, CA'} img={'https://d3t5mq2ngh06je.cloudfront.net/uploads/logos/16703789068311670378906073.png'}>
					<Participant name={'Jodie Tanner'} title={'Chief of Beef'} phone={'(925) 555-0134'} email={'jodie@example.com'}/>
					<Divider/>
					<Participant name={'Benny Branzino'} title={'Cee Eff Oh'} email={'benny@example.com'}/>
				</Details>
			</Grid>
		</Grid>
	</PageContentWrapper>
}
