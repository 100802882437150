import React, { useState, useEffect } from "react";
import { Grid, Box, Typography, TextField, InputAdornment } from "@mui/material";
import StartupOsTextField from "../../../../common/StartupOsInput/StartupOsTextField";

export const ReturnOnInvestmentCalculator = () => {
	const [netProfilt, setNetProfilt] = useState();
	const [costOfInvestment, setCostOfInvestment] = useState();
	const [returnOfInvestment, setReturnOfInvestment] = useState(0);

	useEffect(() => {
		setReturnOfInvestment(Math.floor((netProfilt / costOfInvestment) * 100));
	}, [netProfilt, costOfInvestment]);

	const handleInput = (e, type) => {
		let value = e.target.value.replace(/[^0-9]/g, "");
		if (type === "netProfilt") {
			setNetProfilt(value);
		} else if (type === "costOfInvestment") {
			setCostOfInvestment(value);
		}
	};
	return (
		<Grid container display={"flex"} alignItems="center" sx={{ mt: 5 }}>
			<Grid item xs={12} lg={12}>
				<Typography variant="testHeading">Formula</Typography>
				<Box display={"flex"} alignItems="center" sx={{ mt: 3 }}>
					<Box>
						<Typography variant="title_portfolio" component={"p"}>
							Net Profit
						</Typography>
						<Box display={"flex"} alignItems="center" gap={1}>
							<StartupOsTextField
								value={netProfilt}
								fullWidth
								InputProps={{
									inputMode: "numeric",
									pattern: "[0-9]*",
									onChange: (e) => {
										handleInput(e, "netProfilt");
									},
									startAdornment: <InputAdornment position="start">$</InputAdornment>,
								}}
								isEndAdornment={false}
								sx={{
									width: "90%",
									color: "black",
									height: "60px",
									mt: 1,
									".MuiOutlinedInput-root": {
										borderRadius: "30px",
										height: "60px",
									},
									".MuiInputBase-input": (theme) => {
										return { ...theme.typography.subhead3, color: theme.palette.secondary.black, pl: 0, pr: 2 };
									},
								}}
							/>
							<Typography sx={{ mt: 1 }} variant="title_portfolio">
								/
							</Typography>
						</Box>
					</Box>
					<Box>
						<Typography variant="title_portfolio" component={"p"}>
							Cost of Investment
						</Typography>

						<StartupOsTextField
							value={costOfInvestment}
							fullWidth
							InputProps={{
								inputMode: "numeric",
								pattern: "[0-9]*",
								onChange: (e) => {
									handleInput(e, "costOfInvestment");
								},
								startAdornment: <InputAdornment position="start">$</InputAdornment>,
							}}
							isEndAdornment={false}
							sx={{
								width: "90%",
								color: "black",
								height: "60px",
								mt: 1,
								".MuiOutlinedInput-root": {
									borderRadius: "30px",
									height: "60px",
								},
								".MuiInputBase-input": (theme) => {
									return { ...theme.typography.subhead3, color: theme.palette.secondary.black, pl: 0, pr: 2 };
								},
							}}
						/>
					</Box>
					<Box display={"flex"} alignItems="center" gap={2} sx={{ mt: 4 }}>
						<Typography sx={{ mt: 1 }} variant="title_portfolio">
							*
						</Typography>
						<Typography variant="title_portfolio" component={"p"}>
							100%
						</Typography>
					</Box>
				</Box>
			</Grid>
			<Grid item xs={12} lg={12} sx={{ mt: 3 }}>
				<Typography variant="card_text" component={"p"}>
					Return on Investment
				</Typography>
				<Box display={"flex"} alignItems="center">
					<Typography variant="title_portfolio" component={"p"}>
						=
					</Typography>
					<Typography variant="PoppinsMedium100" component={"p"} noWrap overflow="hidden">
						{!isFinite(returnOfInvestment)
							? 0
							: Intl.NumberFormat("en-US", {
									notation: "compact",
									maximumFractionDigits: 1,
							  }).format(Number(returnOfInvestment))}
						%
					</Typography>
				</Box>
			</Grid>
		</Grid>
	);
};
