import * as actionTypes from "../../actions/actionTypes";
import { updateObject } from "../../../utils/updateObject";

const initialState = {
	loading: false,
	feedData: [],
	createPostOpen: false,
	fileType: {
		"application/vnd.openxmlformats-officedocument.presentationml.presentation": [],
		"application/vnd.ms-powerpoint": [],
		"application/pdf": [],
		"image/png": [],
		"image/jpg": [],
		"image/jpeg": [],
		"video/3gpp": [".3gp"],
		"video/mp4": [".mp4"],
		"video/mov": [".mov"],
		"video/webm": [".webm"],
		"video/ogg": [".ogg"],
	},
	detailsPopUpOpen: false,
	editOrDeletePopUpOpen: false,
	getCommentsApiCall: false,
	detailsPostObject: null,
	currentPostId: "",
	currentPostObj: null,
	postOperationType: "",
	currentCommentId: "",
	detailsCommentObject: null,
	commentOperationType: "",
	uploadingFileType: "",
	currentCommentList: null,
	metaLinks: [],
};

const feedPost = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SET_FEED_DATA: {
			return action.payload === null ? initialState : updateObject(state, action.payload);
		}
		default:
			return state;
	}
};
export default feedPost;
