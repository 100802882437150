import React, { useEffect, useState } from "react";
import { Stack, Box, Typography, Button } from "@mui/material";
import { PMF_BACKGROUND_CENTER, PMF_BACKGROUND_LEFT, PMF_BACKGROUND_RIGHT } from "../../../../constants";
import VALUEPROPIMAGE from "./ValueProp.svg";
import BRANDIMAGE from "./BrandValues.svg";
import CHEVRONIMG from "./ChevronGroup.svg";
import MISSIONIMG from "./Mission.svg";
import { useSelector } from "react-redux";
import { getHeroData } from "./functions";
import NoDataPill from "../../MarketOpportunity/PersonaBuilderView/NoDataPill";
export default function ProductMarketFitCard() {
	const STARTUP = useSelector((state) => state?.investorDashboard?.openStartupObj);
	const [HeroValues, setHeroValues] = useState(null);

	const getHeroValues = async () => {
		try {
			let HeroData = await getHeroData(STARTUP?.id);
			setHeroValues(HeroData);
		} catch (e) {
			console.log("Error when attempting to pull Brandstorming and Value Prop PowerUP Data. Failed with error: ", e);
		}
	};

	useEffect(() => {
		if (STARTUP && STARTUP?.id) {
			getHeroValues();
		}
	}, []);
	//
	return (
		<Box>
			<Box
				sx={{
					display: "flex",
					height: "750px",
				}}
			>
				<Box
					sx={{
						flex: 1,
						position: "relative",
						display: "flex",
						backgroundColor: "black",
					}}
				>
					<Box
						sx={{
							alignSelf: "flex-end",
							padding: "20px 40px",
							backgroundColor: "#7B61FF",
							borderRadius: "0px 20px 0px 0px",
							zIndex: 1,
						}}
					>
						<Typography
							variant="customValue"
							sx={{
								color: "white",
								fontFamily: "PoppinsSemiBold",
								fontSize: "50px",
								lineHeight: "75px",
							}}
						>
							Mission
						</Typography>
					</Box>
					<img
						src={MISSIONIMG}
						style={{
							position: "absolute",
							bottom: 0,
							zIndex: 0,
						}}
					/>
					<img
						src={CHEVRONIMG}
						style={{
							position: "absolute",
							top: 0,
							right: 0,
							zIndex: 0,
						}}
					/>
					<img
						src={CHEVRONIMG}
						style={{
							position: "absolute",
							bottom: 0,
							right: 0,
							zIndex: 0,
							transform: "rotate(180deg)",
						}}
					/>
				</Box>
				<Box
					sx={{
						flex: 1,
						position: "relative",
						display: "flex",
						backgroundColor: "white",
						backgroundImage: `url(${VALUEPROPIMAGE})`,
						backgroundRepeat: "no-repeat",
						backgroundPosition: "center",
						backgroundSize: "cover",
					}}
				>
					<Box
						sx={{
							alignSelf: "flex-end",
							padding: "20px 40px",
							backgroundColor: "#DE0085",
							borderRadius: "0px 20px 0px 0px",
						}}
					>
						<Typography
							variant="customValue"
							sx={{
								color: "white",
								fontFamily: "PoppinsSemiBold",
								fontSize: "50px",
								lineHeight: "75px",
							}}
						>
							Value Prop
						</Typography>
					</Box>
				</Box>
				<Box
					sx={{
						flex: 1,
						position: "relative",
						display: "flex",
						backgroundColor: "white",
						backgroundImage: `url(${BRANDIMAGE})`,
						backgroundRepeat: "no-repeat",
						backgroundPosition: "center",
						backgroundSize: "cover",
					}}
				>
					<Box
						sx={{
							alignSelf: "flex-end",
							padding: "20px 40px",
							backgroundColor: "#EC6C67",
							borderRadius: "0px 20px 0px 0px",
						}}
					>
						<Typography
							variant="customValue"
							sx={{
								color: "white",
								fontFamily: "PoppinsSemiBold",
								fontSize: "50px",
								lineHeight: "75px",
							}}
						>
							Brand Values
						</Typography>
					</Box>
				</Box>
			</Box>
			<Box mt={1} display="flex">
				<Box flex={1} p="60px" sx={{ background: "#7B61FF" }}>
					{HeroValues?.brandstorm?.mission ? (
						<Typography variant="title_large2" color="#FAFCFE">
							{HeroValues?.brandstorm?.mission}
						</Typography>
					) : (
						<NoDataPill background={"white"} />
					)}
				</Box>
				<Box flex={1} p="60px" sx={{ background: "#DE0085" }}>
					{HeroValues?.valueprop ? (
						<Typography variant="title_large2" color="#FAFCFE">
							{HeroValues?.valueprop}
						</Typography>
					) : (
						<NoDataPill background={"white"} />
					)}
				</Box>
				<Box flex={1} p="60px" sx={{ background: "#EC6C67" }}>
					{HeroValues?.brandstorm?.values ? (
						<Typography variant="title_large2" color="#FAFCFE">
							{HeroValues?.brandstorm?.values}
						</Typography>
					) : (
						<NoDataPill background={"white"} />
					)}
				</Box>
			</Box>
		</Box>
	);
}
