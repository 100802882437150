import React, { useState, useEffect } from "react";
import { Box, Divider, Typography, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import MeetingDetailComponent from "../../../../components/Workspace/PowerUp/MeetingDetails";
import { REGEX } from "../../../../constants/regex";
import messages from "../../../../constants/messages";
import RichTextEditor from "../../../../common/RichTextEditor";
import { stateToMarkdown } from "draft-js-export-markdown";
import {
  RICH_TEXT_EDITOR_INLINE_CONTROLS,
  RICH_TEXT_EDITOR_MAIN_CONTROLS,
} from "../../../../constants/plugin-constants";
import theme from "../../../../constants/Theme";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";
import { EditorState, ContentState } from "draft-js";
import { stateFromMarkdown } from "draft-js-import-markdown";

export default function InviteMeetingDetails({
  userInputs,
  selectedDateTimes,
  setUserInputs,
  boxPadding,
  onContentChanged,
  durationValue,
  subjectContent,
  descriptionContent,
}) {
  const [emailSubject, setEmailSubject] = useState({
    value: subjectContent,
    error: "",
  });
  const [emailMessage, setEmailMessage] = useState({
    value: "",
    error: "",
  });
  const [duration, setDuration] = useState(durationValue);

  const userData = useSelector(state => state.auth);

  useEffect(() => {
    let subject = `${userData.companyName} Interview - ${duration}`;
    let message = `My name is ${userData.userData.name} with ${userData.companyName} and would like to interview you. We would like to interview you for our product research. Your feedback will help us refine our idea and find the right audience. If you’re available and would like to participate, please reply to this email to confirm the time or propose a new time. If you have any questions, my email is ${userData.userData.email}.`;
    if (!emailSubject.value) {
      setEmailSubject({
        value: subject,
      });
      onContentChanged(subject, message);
    }
    if (!emailMessage.value) {
      const contentState = stateFromHTML(descriptionContent || message);
      const editorState = EditorState.createWithContent(contentState);
      setEmailMessage({
        value: stateToMarkdown(editorState.getCurrentContent()),
      });
      onContentChanged(subject, message);
    }
  }, []);

  useEffect(() => {
    let subject = `${userData.companyName} Interview - ${duration}`;

    setEmailSubject({
      value: subject,
    });
    const editorState = EditorState.createWithContent(
      stateFromMarkdown(emailMessage.value)
    );

    onContentChanged(subject, stateToHTML(editorState.getCurrentContent()));
  }, [duration]);

  const handleBlur = (e, type) => {
    let eventValue;
    if (type === "emailSubject") {
      eventValue = e.target.value;
    } else {
      const editorState = EditorState.createWithContent(
        stateFromMarkdown(emailMessage.value)
      );
      eventValue = editorState.getCurrentContent().getPlainText("\u0001");
    }
    switch (type) {
      case "emailSubject":
        if (!eventValue.match(REGEX.EMAIL_SUBJECT)) {
          setEmailSubject(prevState => ({
            ...prevState,
            error: messages.EMAIL_SUBJECT_LENGTH_INVALID,
            value: eventValue,
          }));
        } else {
          setEmailSubject(prevState => ({
            ...prevState,
            error: "",
            value: eventValue,
          }));
        }

        break;
      case "emailMessage":
        if (eventValue.length < 3 || eventValue.length > 500) {
          setEmailMessage(prevState => ({
            ...prevState,
            error: messages.EMAIL_MESSAGE_LENGTH_INVALID,
          }));
        } else {
          setEmailMessage(prevState => ({
            ...prevState,
            error: "",
          }));
        }

        break;
    }
  };
  const handleOnChange = (e, type) => {
    let eventValue = e.target.value;
    switch (type) {
      case "emailSubject":
        onContentChanged(eventValue, emailMessage.value);

        setEmailSubject(prevState => ({
          ...prevState,
          error: "",
          value: eventValue,
        }));

        break;
      case "emailMessage":
        const editorState = EditorState.createWithContent(
          stateFromMarkdown(eventValue)
        );

        onContentChanged(
          emailSubject.value,
          stateToHTML(editorState.getCurrentContent())
        );
        break;
    }
  };

  const handleEmailMessageChange = value => {
    const inputVal = stateToMarkdown(value.getCurrentContent());
    setEmailMessage({ ...emailMessage, value: inputVal });
    onContentChanged(
      emailSubject.value,
      stateToHTML(value.getCurrentContent())
    );
  };

  return (
    <>
      <Box>
        <MeetingDetailComponent
          selectedDateTimes={selectedDateTimes}
          userInputs={userInputs}
          setUserInputs={setUserInputs}
          boxPadding={boxPadding}
          getDuration={value => setDuration(value?.duration)}
        />
      </Box>
      <Box sx={{ mt: 6 }}>
        <Typography variant="headline6">
          Your email message to candidate
        </Typography>
        <Divider sx={{ mt: 3 }} />
        <Typography varinat="bodyLarge" sx={{ mt: 3 }}>
          Craft a brief introductory message and email subject line to the
          interview participants. Include your Zoom scheduling link to have the
          participant schedule a time if they agree to participate. You can edit
          any part of this message, and we’ve prefilled some areas as well.
        </Typography>
        <TextField
          spellCheck="true"
          label="Email Subject"
          sx={{ width: 1, mt: 4, mb: 4 }}
          error={emailSubject.error}
          value={emailSubject.value}
          onBlur={e => {
            handleBlur(e, "emailSubject");
          }}
          onChange={e => {
            handleOnChange(e, "emailSubject");
          }}
          helperText={emailSubject.error}
        />

        <Typography variant="title_medium">Email Message</Typography>
        <Box
          sx={theme => ({
            minHeight: "300px",
            border: `1px solid ${theme.palette.secondary.dark}`,
            px: 2,
            mt: 2,
            borderRadius: theme.shape.standard3.borderRadius,
          })}
        >
          <RichTextEditor
            value={emailMessage.value}
            onChange={handleEmailMessageChange}
            label="Email Message"
            toolbar={true}
            inlineToolbar={false}
            inlineToolbarControls={RICH_TEXT_EDITOR_INLINE_CONTROLS}
            controls={RICH_TEXT_EDITOR_MAIN_CONTROLS}
            readOnly={false}
            onBlur={() => handleBlur(emailMessage.value, "emailMessage")}
            minHeight={"300px"}
          />
        </Box>
        <Box>
          {emailMessage.error && (
            <Typography sx={{ color: "error.main" }}>
              {emailMessage.error}
            </Typography>
          )}
        </Box>
      </Box>
    </>
  );
}
