import React, { useEffect } from "react";
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { useToaster } from "@twilio-paste/core";
import { Toaster } from "@twilio-paste/toast";
import { actionCreators } from "../../../Messaging/store";
import { toastContainer } from "../../../../utils/toast";
const Notifications = () => {
  const toaster = useToaster();
  const notifications = useSelector(state => state.notifications);
  const dispatch = useDispatch();
  const { removeNotifications } = bindActionCreators(actionCreators, dispatch);
  useEffect(() => {
    if (!notifications?.length) {
      return;
    }
    // Use StartupOS Style Toast. Commented out Twilio Toast incase we want to revert down the line.
    //notifications.forEach(notification => toaster.push(notification));
    notifications.forEach(notification =>
      toastContainer(notification?.message, notification?.variant)
    );
    removeNotifications(notifications?.length);
  }, [notifications]);
  // By using StartupOS toast over Twilio, we only need the function call and do not need to return a component here.
  //return <Toaster {...toaster} />;
  return null;
};
export default Notifications;
