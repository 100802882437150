import { Avatar, Typography, Box, Stack } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import React, { Fragment } from "react";
import { EventTooltip } from "../style";

const columns = [
	{
		field: "name",
		headerName: "Company",
		sortable: false,
		filterable: false,
		wrapText: true,
		width: 200,
		renderCell: (params) => (
			<Fragment>
				<Avatar src={params.value?.img} sx={{ mr: 1 }} />
				<Typography variant="Text/xs/Regular">{params.value?.name}</Typography>
			</Fragment>
		),
		renderHeader: (params) => <Typography variant="Text/xs/Medium">{params.colDef.headerName}</Typography>,
	},
	{
		field: "foundedYear",
		headerName: "Founded",
		sortable: false,
		filterable: false,
		wrapText: true,
		width: 150,
		renderCell: (params) => <Typography variant="Text/xs/Regular">{params.value}</Typography>,
		renderHeader: (params) => <Typography variant="Text/xs/Medium">{params.colDef.headerName}</Typography>,
	},
	{
		field: "industry",
		headerName: "Industry",
		sortable: false,
		filterable: false,
		wrapText: true,
		width: 150,
		renderCell: (params) => <Typography variant="Text/xs/Regular">{params.value?.[0]}</Typography>,
		renderHeader: (params) => <Typography variant="Text/xs/Medium">{params.colDef.headerName}</Typography>,
	},
	{
		field: "productOffering",
		headerName: "Product Category",
		sortable: false,
		filterable: false,
		wrapText: true,
		width: 150,
		renderCell: (params) => <Typography variant="Text/xs/Regular">{params.value}</Typography>,
		renderHeader: (params) => <Typography variant="Text/xs/Medium">{params.colDef.headerName}</Typography>,
	},
	{
		field: "incorporatedStatus",
		headerName: "Incorporated",
		sortable: false,
		filterable: false,
		wrapText: true,
		width: 150,
		renderCell: (params) => <Typography variant="Text/xs/Regular">{params.value}</Typography>,
		renderHeader: (params) => <Typography variant="Text/xs/Medium">{params.colDef.headerName}</Typography>,
	},
	{
		field: "currentScore",
		headerName: "StartupOS Score",
		sortable: false,
		filterable: false,
		wrapText: true,
		width: 150,
		renderCell: (params) => <Typography variant="Text/xs/Regular">{params.value}</Typography>,
		renderHeader: (params) => (
			<Typography variant="Text/xs/Medium" sx={{ whiteSpace: "wrap" }}>
				{params.colDef.headerName}
			</Typography>
		),
	},
];
const formatCreatedAt = (createdAt) => {
	const date = new Date(createdAt);
	const options = { month: "2-digit", day: "2-digit", year: "numeric" };
	const dateString = date.toLocaleDateString("en-US", options);
	const timeString = date.toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit", hour12: true });
	return `${dateString} at ${timeString}`;
};
const getEventDetails = (eventNames, createdDates) => (
	<Box sx={{ padding: "5px" }}>
		<Typography variant="Text/sm/Semibold">Invite Dates & Time</Typography>
		<Stack spacing={1} mt={1}>
			{eventNames.map((eventName, index) => (
				<Box display={"flex"} alignItems={"center"} gap={3}>
					<Typography component={"p"} variant="Text/sm/Regular" sx={{ width: "400px" }}>
						{eventName}
					</Typography>
					<Typography variant="Text/sm/Regular">{createdDates[index]}</Typography>
				</Box>
			))}
		</Stack>
	</Box>
);
const prospectColumns = [
	{
		field: "name",
		headerName: "Company",
		sortable: false,
		filterable: false,
		wrapText: true,
		width: 200,
		renderCell: (params) => (
			<Fragment>
				<Avatar src={params.value?.img} sx={{ mr: 1 }} />
				<Typography variant="Text/xs/Regular">{params.value?.name}</Typography>
			</Fragment>
		),
		renderHeader: (params) => <Typography variant="Text/xs/Medium">{params.colDef.headerName}</Typography>,
	},
	{
		field: "foundedYear",
		headerName: "Founded",
		sortable: false,
		filterable: false,
		wrapText: true,
		width: 150,
		renderCell: (params) => <Typography variant="Text/xs/Regular">{params.value}</Typography>,
		renderHeader: (params) => <Typography variant="Text/xs/Medium">{params.colDef.headerName}</Typography>,
	},
	{
		field: "industry",
		headerName: "Industry",
		sortable: false,
		filterable: false,
		wrapText: true,
		width: 150,
		renderCell: (params) => <Typography variant="Text/xs/Regular">{params.value?.[0]}</Typography>,
		renderHeader: (params) => <Typography variant="Text/xs/Medium">{params.colDef.headerName}</Typography>,
	},
	{
		field: "productOffering",
		headerName: "Product Category",
		sortable: false,
		filterable: false,
		wrapText: true,
		width: 150,
		renderCell: (params) => <Typography variant="Text/xs/Regular">{params.value}</Typography>,
		renderHeader: (params) => <Typography variant="Text/xs/Medium">{params.colDef.headerName}</Typography>,
	},
	{
		field: "incorporatedStatus",
		headerName: "Incorporated",
		sortable: false,
		filterable: false,
		wrapText: true,
		width: 150,
		renderCell: (params) => <Typography variant="Text/xs/Regular">{params.value}</Typography>,
		renderHeader: (params) => <Typography variant="Text/xs/Medium">{params.colDef.headerName}</Typography>,
	},
	{
		field: "currentScore",
		headerName: "StartupOS Score",
		sortable: false,
		filterable: false,
		wrapText: true,
		width: 150,
		renderCell: (params) => <Typography variant="Text/xs/Regular">{params.value}</Typography>,
		renderHeader: (params) => (
			<Typography variant="Text/xs/Medium" sx={{ whiteSpace: "wrap" }}>
				{params.colDef.headerName}
			</Typography>
		),
	},
	{
		field: "invitedTo",
		headerName: "Invited To",
		sortable: false,
		filterable: false,
		wrapText: true,
		width: 500,
		renderCell: (params) => {
			const eventNames = params?.row?.pitchEventInvitationModels.map((invitation) => invitation.eventName);
			const createdDates = params?.row?.pitchEventInvitationModels.map((invitation) => formatCreatedAt(invitation.createdAt));

			return (
				<>
					{eventNames.length > 0 ? (
						<>
							<EventTooltip title={getEventDetails(eventNames, createdDates)} placement={"right"} arrow={true}>
								<Typography
									variant="Text/xs/Regular"
									sx={{ maxWidth: "400px", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}
								>
									{eventNames.join(", ")}
								</Typography>
							</EventTooltip>

							{/* <EventTooltip title={getEventDetails(eventNames, createdDates)} placement={"right"} arrow={true}>
								<MoreVertIcon sx={(theme) => ({ fontSize: 18, color: theme.palette.secondary.main, ml: "auto" })} />
							</EventTooltip> */}
						</>
					) : (
						<Typography variant="Text/xs/Regular">No Data</Typography>
					)}
				</>
			);
		},
		renderHeader: (params) => <Typography variant="Text/xs/Medium">{params.colDef.headerName}</Typography>,
	},
];
export function AppliedStartupColumns() {
	return columns;
}
export function ProspectsColumns() {
	return prospectColumns;
}
export const AppliedStartupSortOptions = [
	{ name: "Company Name", colName: "companyName" },
	{ name: "Founded Year", colName: "foundedYear" },
	{ name: "Score", colName: "currentScore" },
];
