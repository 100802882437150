import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Box, Skeleton, Typography } from "@mui/material";

import { ProfileContext } from "../..";
import { USERS_THREE_ICON } from "../../../../constants";
import SvgComponent from "../../../../utils/SVGColor";

import { StyledProfileCard } from "../../style";
import ExperienceList from "./ExperienceList";
import StatusButton from "../StatusButton";
import ROUTES_PATH from "../../../../constants/routes";
import progressCompletionCalculator from "../../../../utils/progressCompletionCalculator";
import { setUserProfileData } from "../../../../modules/actions/userProfileActions";
import { useDispatch } from "react-redux";

function ExperienceCard({ setExperiencePercentage }) {
	const history = useHistory();
	const { viewContext, profileData, profileDataLoading } = useContext(ProfileContext);
	const [loading, setloading] = useState(profileDataLoading);
	const [data, setdata] = useState(profileData);
	const totalFields = 1;
	const completedFields = profileData?.experienceDetails && profileData?.experienceDetails?.length > 0 ? 1 : 0;
	const dispatch = useDispatch();
	useEffect(() => {
		setExperiencePercentage(progressCompletionCalculator(totalFields, completedFields));
	}, [profileData]);
	useEffect(() => {
		setloading(profileDataLoading);
	}, [profileDataLoading]);
	useEffect(() => {
		setdata(profileData);
	}, [profileData]);

	const handleRedirection = () => {
		if (viewContext?.component === "account") {
			// history.push(ROUTES_PATH.EDIT_MY_ACCOUNT_PROFILE);
			dispatch(
				setUserProfileData({
					userProfileEditOpen: true,
				})
			);
		}
	};

	return (
		<StyledProfileCard>
			{!viewContext?.id && viewContext?.component === "account" && (
				<Box mb={4.5}>
					{/* <StatusButton percentage={progressCompletionCalculator(totalFields, completedFields)} handleRedirection={handleRedirection} /> */}
				</Box>
			)}
			<Box
				sx={{
					display: "flex",
					alignItems: "center",
					justifyContent: "space-between",
					flex: 1,
					mb: "36px",
				}}
			>
				{loading ? (
					<Skeleton
						variant="rounded"
						animation="wave"
						sx={{
							height: "40px",
							width: 1,
						}}
					/>
				) : (
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							gap: "12px",
						}}
					>
						<SvgComponent svgUrl={USERS_THREE_ICON} strokeColor={"#0A141B"} style={{ height: "35px", width: "35px" }} />
						<Typography
							variant="customValue"
							component={"div"}
							sx={{
								color: "black",
								fontFamily: "PoppinsSemiBold",
								fontSize: "26px",
								lineHeight: "39px",
							}}
						>
							Experience [{data?.experienceDetails?.length ? data?.experienceDetails?.length : 0}]
						</Typography>
					</Box>
				)}
			</Box>
			<Box>
				<ExperienceList />
			</Box>
		</StyledProfileCard>
	);
}

export default ExperienceCard;
