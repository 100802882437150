import React, { useState } from "react";
import {
  Autocomplete,
  TextField,
  Grid,
  Typography,
  Box,
  Avatar,
  Chip,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useEffect } from "react";
import userConnections from "../../../../../utils/userConnections";
import { useDispatch } from "react-redux";
import { actionCreators } from "../../../../Messaging/store";
import { bindActionCreators } from "redux";
import _ from "lodash";
import services from "../../../../../services";
import { useSelector } from "react-redux";
import { Check } from "@mui/icons-material";
import { handleMixPanel } from "../../../../../utils/mixPanelEventHandling";

export default function CreateConversationSearch(props) {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [loading, setloading] = useState(false);
  const authData = useSelector(state => state?.auth);
  const selectedOptions = useSelector(
    state => state.chatState?.newConversationWith
  );
  const dispatch = useDispatch();
  const { newConversationWith } = bindActionCreators(actionCreators, dispatch);

  const Connections = new userConnections();

  const initConnections = async () => {
    setloading(true);
    await Connections.getFirstPage()
      .then(resp => {
        setOptions(resp);
      })
      .catch(() => {})
      .finally(() => {
        setloading(false);
      });
  };

  useEffect(() => {
    initConnections();
  }, []);

  const getContacts = async e => {
    if( e?.target?.value === '' ){ // when searchtext empty stop calling api
      setOptions([]);
      return 
    }
    setloading(true);
    // TODO -- Issues with search endpoint
    await services
      .getContactsWithSearch(e?.target?.value ? e?.target?.value : "")
      .then(resp => {
        setOptions(resp?.data?.data);
      })
      .catch(() => {})
      .finally(() => {
        setloading(false);
      });
  };

  const processSelection = value => {
    // value.forEach((name) => {
    //   handleMixPanel("Message Conversation Created tag", {
    //     "Sender User ID": authData?.userId,
    //     "Recipient User ID": name.id
    //   })
    // })
    
    //single select
    handleMixPanel("Message Conversation Created tag", {
      "Sender User ID": authData?.userId,
      "Recipient User ID": value?.id
    })

    try {
      if (value && Object.keys(value)?.length > 0) {
        // let result = value?.map(elem => {
        //   return elem?.id;
        // });
        let result = [value.id]   //single select
        if (props.new) {
          newConversationWith(result);
        } else if (props.add) {
          props.addConversation(result);
        }
      } else {
        if (props.new) {
          newConversationWith([]);
        } else if (props.add) {
          props.addConversation([]);
        }
      }
    } catch (err) {
      console.log("Error processing selected users.", err);
    }
  };

  return (
    <Autocomplete
      // TODO -- Allow group messaging. Adding multiple users to a conversation.
      // multiple
      open={open}
      filterOptions={x => x}
      onOpen={() => {
        setOpen(true);
        // getContacts(); stop calling when search text empty
      }}
      onClose={() => {
        setOpen(false);
        setOptions([]);
      }}
      forcePopupIcon={false}
      onChange={(event, value) => {
        processSelection(value);
        //getContacts();
        //if (value?.user?.name) newConversationWith(value?.user?.id);
        //else if (value?.company?.founderDetails?.id)
        //  newConversationWith(value?.company?.founderDetails?.id);
      }}
      // TODO -- Allow user to type in name. Filter based on input. If input === result in list, select the option automatically.
      //isOptionEqualToValue={(option, value) => option?.user?.name === value}
      //getOptionLabel={option => {
      //  if (option?.user?.name) return option.user.name;
      //  else if (option?.company?.founderDetails?.name)
      //    return option.company.founderDetails.name;
      //  else return "";
      //}}
      // TODO -- Issues with search endpoint
      onInput={_.debounce(getContacts, 600)}
      options={options}
      loading={loading}
      renderInput={params => (
        <TextField
          {...params}
          // label="Type a name"
          placeholder="Type a name"
          fullWidth
          variant="outlined"
          sx={{
            label: {
              "&:not(.MuiInputLabel-shrink)": theme => ({
                fontFamily: theme.typography["Text/sm/Regular"],
                color: theme.palette.text.lightOnsurface,
              }),
            },
            input: {
              "&, &::placeholder": theme => ({
                fontFamily: theme.typography["Text/sm/Regular"],
                color: theme.palette.text.lightOnsurface,
              }),
            },
            "& .MuiOutlinedInput-input": theme => ({
              fontFamily: theme.typography["Text/sm/Regular"],
            })
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        ></TextField>
      )}
      renderOption={(props, option) => {
        return (
          <li
            {...props}
            key={option.id}
            onClick={
              selectedOptions.indexOf(option?.id) == -1
                ? props.onClick
                : function () {}
            }
            style={{
              background:
                selectedOptions.indexOf(option?.id) == -1
                  ? "initial"
                  : "rgba(124, 117, 127, 0.08)",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: 2,
                flex: 1,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "no-wrap",
                  gap: 2,
                }}
              >
                <Avatar alt={option?.name} src={option?.picture} />
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography variant="Text/sm/Semibold">
                    {option?.name}
                  </Typography>
                  <Typography variant="Text/sm/Regular">
                    {option?.companyName}
                  </Typography>
                </Box>
              </Box>
              <Box>
                {selectedOptions.indexOf(option?.id) !== -1 && <Check />}
              </Box>
            </Box>
          </li>
        );
      }}
      getOptionLabel={(option) => option?.name ? option?.name : "" }
      sx={{
        "& .MuiOutlinedInput-root": {
          borderRadius: "30px",
          ".MuiAutocomplete-input": {
            pl: 2
          }
        },
      }}
      // renderTags={(value, getTagProps) =>
      //   value.map((option, index) => (
      //     <Chip
      //       variant="outlined"
      //       avatar={<Avatar alt={option?.name} src={option?.picture} />}
      //       label={option?.name}
      //       {...getTagProps({ index })}
      //     />
      //   ))
      // }
      /* 
        TODO -- Scroll pagination within the list.
        Foresee the issue with # of results shown == page size; how to trigger scroll event to load more results?
        Is there an 'attempt' scroll?
        Is there a way to force 'overscroll' to show a "load more" to trigger?
        Ex: if the max show is 10, page size is 10
        There would be no scroll here as the option list == the page size. However how would we get it to get the next 10?
      */
      //ListboxProps={{
      //  onScroll: event => {
      //    const threshold = 0.05; // Once the scroll percent is within this percent of the total height.
      //    const listboxNode = event.currentTarget;
      //    const currentPosition =
      //      listboxNode.scrollTop + listboxNode.clientHeight;
      //    const percentScrolled = Math.abs(
      //      ((currentPosition - listboxNode.scrollHeight) * 100) /
      //        listboxNode.scrollHeight
      //    );
      //    console.log(percentScrolled);
      //    // If the scroll position is within threshold % of the bottom, attempt to load more results.
      //    if (percentScrolled <= threshold) {
      //      alert("at bottom");
      //    }
      //  },
      //}}
    />
  );
}
