import React, { useMemo, useState } from "react";
import { Box, Divider, Skeleton, Typography, Avatar, Button, IconButton } from "@mui/material";
import MissingAsset from "../../../Profile/Components/MissingAsset";
import VideoPlayerComponent from "../../../../common/videoPlayer";
import { useSelector } from "react-redux";
import JsxParser from "react-jsx-parser";
import ShareIcon from "@mui/icons-material/Share";
import { toastContainer } from "../../../../utils/toast";
import SocialMediaShareButtons from "../../../../common/SocialMediaShareButtons";

const BoxVideoElem = ({ children }) => (
	<Box
		sx={{
			flex: 1,
			maxHeight: "640px", // Figma value.
			maxWidth: "1000px",
			"& video": {
				borderRadius: "30px",
				objectFit: "cover",
			},
		}}
	>
		{children}
	</Box>
);

function AccountPublicAboutCard() {
	const isLoading = useSelector((state) => state?.accountPublicProfileData?.loading);
	const profileData = useSelector((state) => state?.accountPublicProfileData?.accountPublicData);
	const homeApiVersion = process.env.REACT_APP_HOME_URL;
	const shareUrl = `${homeApiVersion}/account/${profileData?.uuid}`;

	const VideoComp = useMemo(() => {
		if (profileData?.videoUrl) {
			return (
				<BoxVideoElem>
					<VideoPlayerComponent videoUrl={profileData?.videoUrl} height="100%" />
				</BoxVideoElem>
			);
		} else {
			return <MissingAsset sxProps={{ height: "450px" }} message={"About Video Coming Soon"} />;
		}
	}, []);

	return (
		<Box
			sx={(theme) => ({
				borderRadius: "40px",
				backgroundColor: "white",
				boxSizing: "border-box",
				display: "flex",
				flexDirection: "column",
				gap: 3,
				padding: "32px 16px",
				[theme.breakpoints.between("sm", "md")]: {
					padding: "32px 32px",
				},
				[theme.breakpoints.up("md")]: {
					padding: "32px 40px 48px",
				},
				boxShadow: "0px 10px 25px rgba(0, 0, 0, 0.05)",
			})}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
				}}
			>
				<Typography
					variant="customValue"
					component={"div"}
					sx={{
						fontFamily: "PoppinsSemiBold",
						fontSize: "26px",
						lineHeight: "39px",
						color: "black",
					}}
				>
					About User
				</Typography>
				<Box
					ml="auto"
					sx={{
						display: "flex",
						flexDirection: "row",
						gap: 1,
						alignItems: "center",
					}}
				>
					<Typography variant="testHeading" component={"div"}>
						Share
					</Typography>
					<SocialMediaShareButtons
						sharePlatformsList={["FB", "TW", "EM"]}
						url={shareUrl}
						title={`Check out my profile on StartupOS`}
						description={`Check out my profile on StartupOS`}
						windowWidth={500}
						windowHeight={500}
						emailSeparator={`\n`}
					/>
				</Box>
			</Box>
			{VideoComp}
			<Box>
				<Typography
					variant="customValue"
					component={"div"}
					sx={{
						fontFamily: "PoppinsSemiBold",
						fontSize: "26px",
						lineHeight: "39px",
						color: "black",
					}}
				>
					About {profileData?.name}
				</Typography>
			</Box>
			<Box>
				<Typography
					variant="customValue"
					component={"div"}
					sx={{
						fontFamily: "PoppinsMedium",
						fontSize: "16px",
						lineHeight: "24px",
						color: "black",
					}}
				>
					<JsxParser autoCloseVoidElements components={{ Typography }} jsx={profileData?.about ? `${profileData?.about}` : `No Data`} />
				</Typography>
			</Box>
		</Box>
	);
}

export default AccountPublicAboutCard;
