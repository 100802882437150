import React from "react";
import "./styles.css";
import { Box, Typography } from "@mui/material";

function LoadingAnimation({ message, messageProps, animationSX, wrapperSX }) {
	return (
		<Box sx={wrapperSX}>
			<Box sx={animationSX} className="loadingAnimation">
				<div className="loadingAnimation--dot"></div>
				<div className="loadingAnimation--dot"></div>
				<div className="loadingAnimation--dot"></div>
				<div className="loadingAnimation--dot"></div>
				<div className="loadingAnimation--dot"></div>
				<div className="loadingAnimation--dot"></div>
			</Box>
			<Typography {...messageProps}>{message && message.length > 0 ? message : "Loading"}</Typography>
		</Box>
	);
}

export default LoadingAnimation;
