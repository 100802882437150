import { Box } from "@mui/material";
import React from "react";
import PersonaBuilderMarketOpportunity from "./PersonaBuilderView";
import TamSamSomCard from "./TamSamSomCard";

function MarketOppTab() {
	return (
		<Box>
			<TamSamSomCard />
			<PersonaBuilderMarketOpportunity />
		</Box>
	);
}

export default MarketOppTab;
