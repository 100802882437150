import { Box, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import SemiCircleProgress from "../../../common/SemiCircleProgress";

export const TableRow = styled(Box)(({ theme, gray }) => ({
	display: "flex",
	justifyContent: "space-between",
	alignItems: "center",
	flex: 1,
	padding: "12px 24px",
	borderBottom: "1px solid var(--Gray-200, #EAECF0)",
	...(gray && {
		background: "#F9FAFB",
	}),
}));

export const TableCell = styled(Box)(({ theme }) => ({
	display: "flex",
	flex: 1,
	justifyContent: "center",
	alignItems: "center",
}));

export default function BoltCompanyCompare({ companies }) {
	const ownCompanyId = useSelector((state) => state?.auth?.companyId);

	const getCompanyName = (company) => {
		if (ownCompanyId == company?.id) {
			return "Your Company";
		} else {
			return company?.company_name;
		}
	};

	const CheckCircle = (
		<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
			<path
				d="M10 16L14 20L22 12M29.3333 16C29.3333 23.3638 23.3638 29.3333 16 29.3333C8.63621 29.3333 2.66667 23.3638 2.66667 16C2.66667 8.63616 8.63621 2.66663 16 2.66663C23.3638 2.66663 29.3333 8.63616 29.3333 16Z"
				stroke="#00CE7E"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);

	const NoCircle = (
		<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
			<path
				d="M20 12.0001L12 20.0001M12 12.0001L20 20.0001M29.3334 16.0001C29.3334 23.3639 23.3638 29.3334 16 29.3334C8.63622 29.3334 2.66669 23.3639 2.66669 16.0001C2.66669 8.63628 8.63622 2.66675 16 2.66675C23.3638 2.66675 29.3334 8.63628 29.3334 16.0001Z"
				stroke="#F13636"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);

	const MissingInfo = (
		<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="#F2F4F7">
			<path
				d="M12.12 12C12.4335 11.1088 13.0522 10.3574 13.8666 9.8788C14.681 9.40017 15.6385 9.22521 16.5696 9.38491C17.5006 9.54461 18.3451 10.0287 18.9534 10.7513C19.5618 11.474 19.8947 12.3887 19.8933 13.3333C19.8933 16 15.8933 17.3333 15.8933 17.3333M16 22.6666H16.0133M29.3333 16C29.3333 23.3638 23.3638 29.3333 16 29.3333C8.63619 29.3333 2.66666 23.3638 2.66666 16C2.66666 8.63616 8.63619 2.66663 16 2.66663C23.3638 2.66663 29.3333 8.63616 29.3333 16Z"
				stroke="#667085"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);

	return (
		<Box
			sx={{
				display: "flex",
				flex: 1,
				filter: "drop-shadow(0px 1px 3px rgba(16, 24, 40, 0.10)) drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.06))",
				padding: "3px",
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					flex: 1,
					background: "#FFF",
					clipPath: "border-box",
					borderRadius: "12px",
				}}
			>
				<TableRow gray={true}>
					<TableCell>
						<Typography variant="Text/xs/Semibold">{getCompanyName(companies?.[0])}</Typography>
					</TableCell>
					<TableCell>
						<Typography variant="Text/xs/Semibold">{getCompanyName(companies?.[1])}</Typography>
					</TableCell>
				</TableRow>

				{/* Score */}
				<TableRow>
					<TableCell>
						<SemiCircleProgress maxValue={1000} progressValue={companies?.[0]?.current_score ? Number(companies?.[0]?.current_score) : 0} />
					</TableCell>
					<TableCell>
						<SemiCircleProgress maxValue={1000} progressValue={companies?.[1]?.current_score ? Number(companies?.[1]?.current_score) : 0} />
					</TableCell>
				</TableRow>

				{/* Founded */}
				<TableRow gray={true}>
					<TableCell>
						<Typography variant="Text/xs/Semibold">Founded</Typography>
					</TableCell>
				</TableRow>

				<TableRow>
					<TableCell>
						{companies?.[0]?.founded_year ? <Typography variant="Text/xs/Regular">{companies?.[0]?.founded_year}</Typography> : MissingInfo}
					</TableCell>
					<TableCell>
						{companies?.[1]?.founded_year ? <Typography variant="Text/xs/Regular">{companies?.[1]?.founded_year}</Typography> : MissingInfo}
					</TableCell>
				</TableRow>

				{/* Business Model */}
				<TableRow gray={true}>
					<TableCell>
						<Typography variant="Text/xs/Semibold">Business Model</Typography>
					</TableCell>
				</TableRow>

				<TableRow>
					<TableCell>
						{companies?.[0]?.business_model ? <Typography variant="Text/xs/Regular">{companies?.[0]?.business_model}</Typography> : MissingInfo}
					</TableCell>
					<TableCell>
						{companies?.[1]?.business_model ? <Typography variant="Text/xs/Regular">{companies?.[1]?.business_model}</Typography> : MissingInfo}
					</TableCell>
				</TableRow>

				{/* Investment Stage */}
				<TableRow gray={true}>
					<TableCell>
						<Typography variant="Text/xs/Semibold">Investment Stage</Typography>
					</TableCell>
				</TableRow>

				<TableRow>
					<TableCell>
						{companies?.[0]?.current_investment_round ? (
							<Typography variant="Text/xs/Regular">{companies?.[0]?.current_investment_round}</Typography>
						) : (
							MissingInfo
						)}
					</TableCell>
					<TableCell>
						{companies?.[1]?.current_investment_round ? (
							<Typography variant="Text/xs/Regular">{companies?.[1]?.current_investment_round}</Typography>
						) : (
							MissingInfo
						)}
					</TableCell>
				</TableRow>

				{/* Monthly Revenue (MRR) */}
				<TableRow gray={true}>
					<TableCell>
						<Typography variant="Text/xs/Semibold">Monthly Revenue (MRR)</Typography>
					</TableCell>
				</TableRow>
				<TableRow>
					<TableCell>
						<Typography variant="Text/xs/Regular">
							{companies?.[0]?.monthly_revenue
								? new Intl.NumberFormat("en-US", {
										style: "currency",
										currency: "USD",
								  }).format(companies?.[0]?.monthly_revenue)
								: "$0"}
						</Typography>
					</TableCell>
					<TableCell>
						<Typography variant="Text/xs/Regular">
							{companies?.[1]?.monthly_revenue
								? new Intl.NumberFormat("en-US", {
										style: "currency",
										currency: "USD",
								  }).format(companies?.[1]?.monthly_revenue)
								: "$0"}
						</Typography>
					</TableCell>
				</TableRow>

				{/* Capital Raised */}
				<TableRow gray={true}>
					<TableCell>
						<Typography variant="Text/xs/Semibold">Capital Raised</Typography>
					</TableCell>
				</TableRow>

				<TableRow>
					<TableCell>
						<Typography variant="Text/xs/Regular">
							{companies?.[0]?.capital_raised
								? new Intl.NumberFormat("en-US", {
										style: "currency",
										currency: "USD",
								  }).format(companies?.[0]?.capital_raised)
								: "$0"}
						</Typography>
					</TableCell>
					<TableCell>
						<Typography variant="Text/xs/Regular">
							{companies?.[1]?.capital_raised
								? new Intl.NumberFormat("en-US", {
										style: "currency",
										currency: "USD",
								  }).format(companies?.[1]?.capital_raised)
								: "$0"}
						</Typography>
					</TableCell>
				</TableRow>
			</Box>
		</Box>
	);
}
