import React, { useEffect, useState } from "react";
import DialogComponent from "../../../PowerUP/IdeaValidation/Components/Dialog";
import {
  Box,
  Divider,
  Typography,
  CircularProgress,
  Grid,
  Button,
} from "@mui/material";
import { PLUGIN_LOGO } from "../../../../../constants/image";
import theme from "../../../../../constants/Theme";
import {
  getDetails,
  validateAdvisorPluginInput,
  getPluginResponse,
  addPluginResponse,
  updatePluginResponse,
  sanitizeDateTimePayload,
  parseDateTimePayload,
} from "../Functions/common";
import PrerequisitesSteps from "../../../../../components/Workspace/PowerUp/PrerequisitesSteps";
import DateTimeSlotPicker from "../../../../../common/DateTimeslotPicker";
import SelectedDatesCard from "../Components/SelectedDatePopover";
import { convertFormattedStringToDate } from "../../../../../utils/date";
import LoadingButton from "../../../../../common/Loadingbutton";
import MeetingDetailComponent from "../../../../../components/Workspace/PowerUp/MeetingDetails";
import SuccessPopup from "../Components/SuccessPopup";
import DatePickerWarning from "../Components/DatePickerWarning";

const ScheduleSession = ({
  showAdvisorPluginPopup,
  setShowAdvisorPluginPopup,
}) => {
  const currentEnv = process.env.NODE_ENV;
  const recipient = ["staging", "development", "qa"].includes(currentEnv)
    ? "support@startupos.com"
    : "jmessina@startupos.com";
  const [details, setDetails] = useState(null);
  const [showDatePicker, setShowDatePicker] = useState(true);

  const [selectedDate, setSelectedDate] = useState(null);
  const [responses, setResponses] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [userInputs, setUserInputs] = useState({
    agenda: "",
    duration: "",
    email: recipient,
  });
  const [resultId, setResultId] = useState(null);
  const [openSuccessPopup, setOpenSuccessPopup] = useState(false);

  useEffect(() => {
    getPluginDetails();

    getPluginResponse()
      .then(result => {
        if (result.code === 200) {
          if (result.data.length) {
            const data = result.data[0];
            setResultId(data.id);
            setUserInputs({
              agenda: data.agenda,
              duration: data.duration + " min",
              email: recipient,
            });
            setResponses(parseDateTimePayload(data.responseDetails));
          }
        }
      })
      .catch(err => console.log(err));
  }, []);

  useEffect(() => {
    if (details) {
      for (const item of details.preRequisites) {
        if (item?.companyTask?.taskStatus !== "COMPLETED") {
          setShowDatePicker(false);
        }
      }
    }
  }, [details]);

  const getPluginDetails = () => {
    getDetails()
      .then(data => {
        setDetails(data.data);
      })
      .catch(err => console.log(err));
  };

  const handleconfirmDisable = () => {
    if (currentStep === 1) {
      return !responses.length;
    } else {
      if (validateAdvisorPluginInput(userInputs)) {
        return false;
      } else {
        return true;
      }
    }
  };

  const handleNext = () => {
    if (currentStep === 1) {
      setCurrentStep(2);
    } else {
      //Api call & close popup
      setButtonLoading(true);
      if (resultId) {
        updatePluginResponse(resultId, {
          ...userInputs,
          duration: parseInt(userInputs.duration.slice(0, 2)),
          responseDetails: sanitizeDateTimePayload(responses),
        })
          .then(result => {
            if (result.code === 200) {
              setButtonLoading(false);
              setOpenSuccessPopup(true);
            }
          })
          .catch(err => {
            setButtonLoading(false);
            console.log(err);
          });
        getPluginDetails();
      } else {
        addPluginResponse({
          ...userInputs,
          duration: parseInt(userInputs.duration.slice(0, 2)),
          responseDetails: sanitizeDateTimePayload(responses),
        })
          .then(result => {
            if (result.code === 200) {
              setButtonLoading(false);

              setOpenSuccessPopup(true);
            }
          })
          .catch(err => {
            setButtonLoading(false);
            console.log(err);
          });
        getPluginDetails();
      }
    }
  };

  const handleBack = () => {
    setCurrentStep(1);
  };

  const handlePopupsClose = () => {
    setOpenSuccessPopup(false);
    setShowAdvisorPluginPopup(false);
  };

  const successContentComponent = () => {
    return (
      <Box>
        <Typography sx={{ textAlign: "center", px: 10 }}>
          Your advisor will be notified and select a time that works or will
          suggest a new time.
        </Typography>
      </Box>
    );
  };

  return (
    <DialogComponent
      isOpen={showAdvisorPluginPopup}
      onClose={() => setShowAdvisorPluginPopup(false)}
      title={"Schedule your Advisor Plugin"}
      titleVariant={"title_large"}
      contentStyle={{ px: 0 }}
      maxWidth={currentStep === 1 ? (selectedDate ? "lg" : "md") : "md"}
      fullWidth
      sx={{ display: openSuccessPopup ? "none" : "block" }}
      dialogAction={
        <Box display={"flex"} gap={2}>
          {currentStep === 1 ? (
            <SelectedDatesCard
              responses={responses}
              setResponses={setResponses}
            />
          ) : (
            <Button variant="outlined" onClick={handleBack}>
              Back
            </Button>
          )}

          <LoadingButton
            disabled={handleconfirmDisable()}
            loading={buttonLoading}
            onClick={() => handleNext()}
            sx={{ px: 4 }}
          >
            Confirm
          </LoadingButton>
        </Box>
      }
      disableBackDropClick
    >
      {details ? (
        <Box>
          <Box sx={{ p: 3 }}>
            <Box display={"flex"}>
              <img src={PLUGIN_LOGO} />
            </Box>
            <Box sx={{ my: 1 }}>
              <Box>
                <Typography variant="headline1">
                  {details.pluginName}
                </Typography>
              </Box>
              <Box>
                <Typography variant="subhead1">
                  {details.shortDescription}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Divider />

          {currentStep === 1 ? (
            <Box sx={{ mt: 3, px: 3 }}>
              <Grid container spacing={2}>
                {/*change col width once added datetime and select a date*/}
                <Grid item md={selectedDate ? 4 : 5}>
                  <Typography variant="title_medium">Overview</Typography>
                  <br />
                  <Typography variant="bodyMedium">
                    {details.overview}
                  </Typography>

                  <Box sx={{ mt: 3 }}>
                    {/*Prerequistes come here*/}
                    <Box>
                      <Typography variant="title_medium">
                        What you’ll need
                      </Typography>
                    </Box>
                    <Box sx={{ mt: 3 }}>
                      <PrerequisitesSteps
                        powerupType={details.pluginName}
                        prerequisitesData={details.preRequisites}
                        customHeading={
                          "Prepare to discuss and receive feedback for the work you’ve done up to this point:"
                        }
                        customButtonVariant={"contained"}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item md={1} display="flex" justifyContent={"center"}>
                  <Divider orientation="vertical" textAlign="center" />
                </Grid>

                {/*change col width once added datetime and select a date*/}
                <Grid item md={selectedDate ? 7 : 6}>
                  <Box>
                    <Typography variant="headline6">
                      Select dates & times you are available
                    </Typography>
                    <br />
                    <Typography variant="bodyMedium">
                      We recommend choosing your available time slots no more
                      than 7 days out from the day you send the invite.
                    </Typography>
                  </Box>
                  {showDatePicker ? (
                    <DateTimeSlotPicker
                      responses={responses}
                      setResponses={setResponses}
                      minTimeSlots={1}
                      maxTimeSlots={5}
                      selectedDate={selectedDate}
                      setSelectedDate={setSelectedDate}
                      containerStyles={theme => ({
                        background:
                          "linear-gradient(0deg, rgba(118, 73, 160, 0.05), rgba(118, 73, 160, 0.05)), #FCFCFC",
                        border: `1px solid ${theme.palette.secondary.light}`,
                        borderRadius: theme.shape.standard3.borderRadius,
                        p: 1,
                        mt: 3,
                      })}
                    />
                  ) : (
                    <DatePickerWarning
                      containerStyles={theme => ({
                        background:
                          "linear-gradient(0deg, rgba(118, 73, 160, 0.05), rgba(118, 73, 160, 0.05)), #FCFCFC",
                        border: `1px solid ${theme.palette.secondary.light}`,
                        borderRadius: theme.shape.standard3.borderRadius,
                        p: 1,
                        mt: 3,
                      })}
                    />
                  )}
                </Grid>
              </Grid>
            </Box>
          ) : (
            <MeetingDetailComponent
              selectedDateTimes={responses}
              userInputs={userInputs}
              setUserInputs={setUserInputs}
            />
          )}
        </Box>
      ) : (
        <Box justifyContent={"center"} sx={{ display: "flex", my: 5 }}>
          <CircularProgress />
        </Box>
      )}

      <SuccessPopup
        open={openSuccessPopup}
        close={handlePopupsClose}
        ContentComponent={successContentComponent}
      />
    </DialogComponent>
  );
};

export default ScheduleSession;
