import React, { useState, useEffect, useRef } from "react";
import { Button } from "@mui/material";
import * as actions from "../../../../modules/actions";
import { connect } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import services from "../../../../services";
import RouterPrompt from "../../../../common/RouterPrompt";
import messages from "../../../../constants/messages";
import constants from "../../../../constants";
import { TUNGSTEN_ICON } from "../../../../constants/image";
import { toastContainer } from "../../../../utils/toast";
import LoadingButton from "../../../../common/Loadingbutton";
import ExcerciseWrapper from "../../../../components/Workspace/PowerUp/ExcerciseWrapper";
import ExcerciseSection from "../../../../components/Workspace/PowerUp/ExcerciseSection";
import KnowledgeSidebar from "../../../../components/Workspace/PowerUp/KnowledgeSidebar";
import {
	getExcerciseConfigurations,
	getExcerciseCompletionStatus,
	buttonDisable,
	getDefaultInputStates,
	parseProblemAnswer,
	cleanPayload,
} from "./functions/common";
import YourIdea from "./Excercises/YourIdea";
import Problem from "./Excercises/Problem";
import Solution from "./Excercises/Solution";
import PowerupExcerciseTitleBox from "../../../../components/Workspace/PowerUp/PowerupTitleBox";
import ProblemSolutionSummary from "../../Summary/ProblemSolutionSummary";
import { handleMixPanel } from "../../../../utils/mixPanelEventHandling";

function ProblemSolution({ powerUpExcercises, setPowerUpExcercises }) {
	const [userInputs, setUserInputs] = useState(null);
	const [excerciseConfigs, setExcerciseConfigs] = useState();
	const [excerciseSteps, setExcerciseSteps] = useState([]);
	const [currentExcercise, setCurrentExcercise] = useState();
	const params = useParams();
	const history = useHistory();
	const moduleId = params.id;
	const moduleUuid = params.moduleUuid;
	const version = parseInt(params.version);
	const excerciseParam = params.excercise;
	const tabitems = constants.EXCERCISE_TAB_ITEMS;
	const [currenttab, setcurrenttab] = useState(tabitems[0].key);
	const [nextButtonLoading, setNextButtonLoading] = useState(false);
	const [titleBoxContents, setTitleBoxContents] = useState({
		title: "",
		subTitle: "",
	});
	const [showSummary, setShowSummary] = useState(false);
	const date = new Date();
	const [productOfferingList, setProductOfferingList] = useState([]);

	const handleSummaryBackClick = () => {
		const newExcerciseData = excerciseConfigs.excercises[excerciseConfigs.excercises.length - 1];

		setCurrentExcercise(syncData(newExcerciseData));
	};
	const summaryComponent = <ProblemSolutionSummary moduleId={moduleId} versionId={version} handleBackClick={handleSummaryBackClick} />;

	const handleBackClick = () => {
		if (currentExcercise.stepNo === 1) {
			//send to details page
			history.push(`/workspace/powerup/${5}`);
		} else {
			setUserInputs(null);
			let currentStep = excerciseSteps.find((excercise) => excercise.stepNo === currentExcercise.stepNo - 1);
			const newExcerciseData = excerciseConfigs.excercises.find((excercise) => excercise.step_id === currentStep.id);
			setCurrentExcercise(syncData(newExcerciseData));
		}
	};
	const updatepowerUpExcercises = (data) => {
		return new Promise((resolve, reject) => {
			try {
				let existingPowerUpExcercises = [...powerUpExcercises];
				let currentIndex = existingPowerUpExcercises.findIndex((excercise) => excercise.stepNo === data.stepNo);

				// if excercise already exists update
				let requestBody = null;
				if (currentIndex >= 0) {
					var excercise = existingPowerUpExcercises[currentIndex];
					excercise = {
						...excercise,
						answers: data.answers,
						isLastStep: data.isLastStep,
						isCompleted: data.isCompleted,
					};
					existingPowerUpExcercises[currentIndex] = excercise;
					requestBody = excercise.answers;
				} else {
					//Else insert new one
					existingPowerUpExcercises.push(data);
					requestBody = data.answers;
				}
				services
					.storeModuleStepResponses(moduleId, {
						...data,
						answers: JSON.stringify(requestBody),
					})
					.then((response) => {
						if (response.data.code === 200) {
							if (response.data?.data?.isCompleted && data?.isLastStep && powerUpExcercises?.length !== excerciseConfigs?.excercises.length) {
								handleMixPanel(`Powerup Completed`, {
									"Powerup ID": "5",
									"Powerup Name": "Problem Solution Fit",
									"Time to Complete": "",
									"Completion Date": date.toISOString(),
								});
							}

							setPowerUpExcercises(existingPowerUpExcercises);
							resolve(true);
						}
					})
					.catch((error) => {
						reject(error);
						console.log(error);
					});
			} catch (error) {
				reject(error);
			}
		});
	};
	const handleNextClick = () => {
		setNextButtonLoading(true);
		const isLastExcercise = currentExcercise.stepNo === excerciseConfigs.excercises.length;
		let data = {
			moduleResponseId: version,
			stepNo: currentExcercise.stepNo,
			answers: cleanPayload(currentExcercise.component, userInputs),
			isLastStep: isLastExcercise,
			isCompleted: getExcerciseCompletionStatus(currentExcercise, userInputs),
			moduleStepId: currentExcercise.id,
		};
		updatepowerUpExcercises(data)
			.then((response) => {
				if (response) {
					setUserInputs(null);
					setNextButtonLoading(false);
					if (!data.isLastStep) {
						let currentStep = excerciseSteps.find((excercise) => excercise.stepNo === currentExcercise.stepNo + 1);
						const newExcerciseData = excerciseConfigs.excercises.find((excercise) => excercise.step_id === currentStep.id);
						setCurrentExcercise(syncData(newExcerciseData));
					} else {
						history.push("summary");
						setShowSummary(true);
						setCurrentExcercise(null);
					}
				}
			})
			.catch((error) => {
				console.log(error);
				setNextButtonLoading(false);
				toastContainer(messages.EXCERCISE_STORE_ERROR, "error");
			});
	};

	function usePrevious(value) {
		const ref = useRef();
		useEffect(() => {
			ref.current = value; //assign the value of ref to the argument
		}, [value]); //this code will run when the value of 'value' changes
		return ref.current; //in the end, return the current ref value.
	}

	const prevInput = usePrevious(userInputs);

	const shouldShowPrompt = () => {
		if (userInputs && powerUpExcercises && currentExcercise) {
			const currentSavedStep = powerUpExcercises.find((ex) => ex.stepNo === currentExcercise.stepNo);

			if (currentSavedStep) {
				let answers = currentSavedStep.answers;
				if (currentSavedStep.stepNo === 2) {
					answers = parseProblemAnswer(currentSavedStep.answers.problems);
				}

				if (JSON.stringify(answers) === JSON.stringify(userInputs)) {
					return false;
				} else {
					return true;
				}
			} else {
				if (Object.keys(userInputs).length === 0) {
					return false;
				} else {
					if (currentExcercise.stepNo === 3) {
						for (let key in userInputs) {
							const value = userInputs[key];
							if (value?.length && value[0] && value[0].length) {
								return true;
							} else {
								return false;
							}
						}
					} else {
						if (JSON.stringify(getDefaultInputStates(currentExcercise.component)) === JSON.stringify(userInputs)) {
							return false;
						} else {
							return true;
						}
					}
				}
			}
		}
	};

	const BackButton = () => {
		return (
			<Button variant="outlined" color="primary" sx={{ mx: 1 }} onClick={handleBackClick}>
				Back
			</Button>
		);
	};

	const NextButton = () => {
		return (
			<LoadingButton
				variant="contained"
				color="primary"
				sx={{ mx: 1 }}
				disabled={buttonDisable(userInputs, currentExcercise?.component)}
				loading={nextButtonLoading}
				onClick={handleNextClick}
			>
				Next
			</LoadingButton>
		);
	};

	const syncData = (jsonData) => {
		const stepDetailsData = excerciseSteps.find((step) => step.id === jsonData.step_id);
		return { ...jsonData, ...stepDetailsData };
	};

	const getLastExcercise = (excercises) => {
		excercises.sort((a, b) => (a.stepNo > b.stepNo ? 1 : -1));
		return excercises[excercises.length - 1].moduleStepId;
	};

	useEffect(() => {
		if (excerciseConfigs) {
			services
				.getModulesSteps("md-prob-sol")
				.then((result) => {
					if (result.data?.data) {
						const steps = result.data.data;
						steps.sort((a, b) => (a.stepNo > b.stepNo ? 1 : -1));
						setExcerciseSteps(steps);
					}
				})
				.catch((err) => console.log(err));
		}
	}, [excerciseConfigs]);

	useEffect(() => {
		if (excerciseConfigs && excerciseSteps && excerciseSteps.length) {
			services
				.getModuleStepResponses(moduleId)
				.then((result) => {
					const orderedData = result.data?.data.sort((a, b) => (a.id > b.id ? 1 : -1));

					const versionIndex = orderedData.findIndex((excercise) => excercise.id === version);

					const tempExcerciseDetails = result.data?.data[versionIndex];
					if (tempExcerciseDetails) {
						const excerciseDetails = tempExcerciseDetails.moduleStepResponses;

						if (!excerciseDetails || !excerciseDetails.length) {
							setPowerUpExcercises([]);
							setCurrentExcercise(syncData(excerciseConfigs.excercises[0]));
						} else {
							const mappedExcerciseDetails = excerciseDetails.map((excercise) => ({
								moduleResponseId: version,
								moduleStepId: excercise.moduleStep.id,
								answers: JSON.parse(excercise.answers),
								stepNo: excercise.moduleStep.stepNo,
							}));

							setPowerUpExcercises(mappedExcerciseDetails);
							if (excerciseParam === "start") {
								const lastExcerciseStepId = getLastExcercise(mappedExcerciseDetails);
								const latestExcercise = excerciseConfigs.excercises.find((excercise) => excercise.step_id === lastExcerciseStepId + 1);
								if (latestExcercise) {
									setCurrentExcercise(syncData(latestExcercise));
								} else {
									history.push("summary");
									setShowSummary(true);
									setCurrentExcercise(null);
								}
							} else if (excerciseParam === "retake") {
								setPowerUpExcercises([]);
								setCurrentExcercise(syncData(excerciseConfigs.excercises[0]));
							} else if (excerciseParam === "summary") {
								setShowSummary(true);
								setCurrentExcercise(null);
							} else {
								setCurrentExcercise(syncData(excerciseConfigs.excercises.find((excercise) => excercise.path === excerciseParam)));
							}
						}
					}
				})
				.catch((err) => console.log(err));
		}
	}, [excerciseSteps]);

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		});

		if (currentExcercise) {
			const currentItem = powerUpExcercises.find((excercise) => excercise.stepNo === currentExcercise.stepNo);

			//Check if there is previously filled step and see its input
			if (currentItem) {
				if (currentItem.answers && Object.keys(currentItem.answers).length) {
					if (currentExcercise.stepNo === 2) {
						setUserInputs(parseProblemAnswer(currentItem.answers?.problems));
					} else if (currentExcercise.stepNo === 1) {
						let customer = currentItem.answers?.customer;
						//Pre parsing of answer as till now this was a text input
						if (typeof customer === "string" || customer instanceof String) {
							customer = [customer];
						}
						setUserInputs({ ...currentItem.answers, customer: customer });
					} else if (currentExcercise.stepNo === 3) {
						let ans = {};
						Object.entries(currentItem.answers).map(([key, value], index) => {
							ans[key] = [...value];
						});
						setUserInputs(ans);
					} else {
						setUserInputs({ ...currentItem.answers });
					}
				} else {
					setUserInputs(getDefaultInputStates(currentExcercise.component));
				}
			} else {
				setUserInputs(getDefaultInputStates(currentExcercise.component));
			}
			setcurrenttab(tabitems[0].key);

			if (currentExcercise.stepNo !== 1) {
				const yourIdea = powerUpExcercises.find((excercise) => excercise.stepNo === 1);
				if (yourIdea && yourIdea.answers.idea) {
					setTitleBoxContents({
						title: yourIdea.answers.idea,
						subTitle: "Your idea",
					});
				}
			}

			history.push(currentExcercise.path);
		}
	}, [currentExcercise]);

	useEffect(() => {
		if (excerciseParam === "start") {
			setPowerUpExcercises([]);
			setExcerciseConfigs(getExcerciseConfigurations());
		}
	}, [excerciseParam]);

	useEffect(() => {
		setExcerciseConfigs(getExcerciseConfigurations());
	}, []);

	const handleStepperStepChange = (stepId) => {
		if (stepId === "summary") {
			handleSummaryStep();
			return;
		}

		setUserInputs(null);
		let changedStep = excerciseConfigs.excercises.find((excercise) => excercise.step_id === stepId);

		if (changedStep) {
			setCurrentExcercise(syncData(changedStep));
			setShowSummary(false);
		}
	};

	const handleSummaryStep = () => {
		setShowSummary(true);
		setCurrentExcercise(null);
		history.push("summary");
	};

	const getCategoryList = () => {
		services
			.getListOfCategoryApi("product-offering")
			.then((res) => {
				const responseData = res.data;
				let categoryArray = [];
				responseData.map((categoryItem) => {
					categoryArray.push(categoryItem?.lovValue);
				});
				setProductOfferingList(categoryArray);
			})
			.catch((e) => {
				console.log("Error", e);
			});
	};

	useEffect(() => {
		getCategoryList();
	}, []);

	return (
		<>
			{showSummary}
			<RouterPrompt when={shouldShowPrompt} stepPath={`/workspace/powerup/md-prob-sol/${moduleId}/${version}/${currentExcercise?.path}`} />
			<ExcerciseWrapper NextButton={NextButton} BackButton={BackButton} excerciseConfigs={excerciseConfigs} currentExcercise={currentExcercise}>
				{excerciseConfigs && (
					<ExcerciseSection
						shouldShowPrompt={shouldShowPrompt}
						setcurrenttab={setcurrenttab}
						currenttab={currenttab}
						excerciseConfigurations={excerciseConfigs}
						currentExcercise={currentExcercise}
						excerciseSteps={excerciseSteps}
						NextButton={NextButton}
						BackButton={BackButton}
						showSummary={showSummary}
						summaryComponent={summaryComponent}
						powerUpExcercises={powerUpExcercises}
						handleStepperStepChange={handleStepperStepChange}
						titleBoxComponent={
							currentExcercise &&
							currentExcercise?.stepNo !== 1 && (
								<PowerupExcerciseTitleBox
									iconUrl={TUNGSTEN_ICON}
									title={titleBoxContents.title}
									subTitle={titleBoxContents.subTitle}
									iconBackgroundColor="primary.main"
									contentBackgroundColor={"primary.main"}
									contentTextColor={"secondary.white"}
								/>
							)
						}
					>
						{currentExcercise &&
							(currentExcercise.component === "YOUR_IDEA" ? (
								<YourIdea userInputs={userInputs} setUserInputs={setUserInputs} productOfferingList={productOfferingList} />
							) : currentExcercise.component === "PROBLEM" ? (
								<Problem userInputs={userInputs} setUserInputs={setUserInputs} />
							) : currentExcercise.component === "SOLUTION" ? (
								<Solution
									userInputs={userInputs}
									setUserInputs={setUserInputs}
									powerUpExcercises={powerUpExcercises}
									yourIdea={titleBoxContents?.title}
								/>
							) : null)}
					</ExcerciseSection>
				)}
				<KnowledgeSidebar currenttab={currenttab} powerUpId={"5"} />
			</ExcerciseWrapper>
		</>
	);
}

const mapStoreToProps = ({ powerUp }) => ({
	powerUpExcercises: powerUp?.powerUpExcercises,
});

const mapDispatchToProps = (dispatch) => ({
	setPowerUpExcercises: (updatedSteps) => dispatch(actions.setPowerUpExcercises(updatedSteps)),
});

export default connect(mapStoreToProps, mapDispatchToProps)(ProblemSolution);
