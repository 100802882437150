import { Box, Button, Grid, Stack, Typography, Card } from "@mui/material";
import ROUTES_PATH from "../../../../../constants/routes";
import { useSelector } from "react-redux";
import React from "react";
import { useHistory } from "react-router-dom";
import {
  getTimeBetweenString,
  getFormattedDate,
} from "../../../../../utils/date";

export default function BookingCard({ data }) {
  const calUserId = useSelector(state => state?.auth?.calUserId);
  const getName = () => {
    if (calUserId == data.userId) {
      return data?.attendees[0]?.name || "";
    } else {
      return data?.user?.name || "";
    }
  };
  const history = useHistory();

  const getTitleDate = timestamp => {
    const dateTime = new Date(timestamp);

    return getFormattedDate(dateTime, "ddd, DD MMM");
  };
  return (
    <Card
      sx={theme => ({
        boxShadow:
          "rgb(0 0 0 / 30%) 0px 1px 2px, rgb(0 0 0 / 15%) 0px 1px 3px 1px",
        borderRadius: theme.shape.standard.borderRadius,
        backgroundColor: theme => theme.palette.text.background,
        p: 2,
      })}
    >
      <Grid
        container
        sx={{ overflowX: "scroll", p: 1, alignItems: "center" }}
        spacing={2}
      >
        {/*Grid to show time*/}
        <Grid item xs={12} sm={12} md={2} lg={2}>
          <Box sx={{ mb: 1 }}>
            <Typography variant="body_large">
              {getTitleDate(data.startTime)}
            </Typography>
          </Box>
          <Box>
            <Typography
              variant="body_small"
              sx={{ color: "secondary.outline" }}
            >
              {getTimeBetweenString(data.startTime, data.endTime)}
            </Typography>
          </Box>
        </Grid>

        {/*Grid to show meeting between users*/}
        <Grid
          item
          xs={12}
          sm={12}
          md={7}
          lg={8}
          sx={{ pl: { md: 1 }, cursor: "pointer" }}
        >
          <Box sx={{ mb: 1 }}>
            <Typography variant="body_large">{data.title}</Typography>
          </Box>
          {data.attendees.length && data.user ? (
            <Box>
              <Typography variant="body_medium">You and {getName()}</Typography>
            </Box>
          ) : (
            ""
          )}
        </Grid>

        {/*grid for action buttons*/}
        <Grid item sm={12} xs={12} md={3} lg={2}>
          {data.status === "CANCELLED" ? (
            <Box
              textAlign={"center"}
              sx={{
                display: "flex",
                justifyContent: { md: "flex-end", sm: "flex-start" },
                alignItems: "center",
                mt: { xs: 2, md: 0 },
                width: "max-content",
                alignItems: "center",
              }}
            >
              {" "}
              <Typography
                variant="title_medium"
                sx={{ color: "secondary.outline" }}
              >
                Cancelled
              </Typography>{" "}
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: { md: "flex-end", sm: "flex-start" },
                alignItems: "center",
                mt: { xs: 2, md: 0 },
                width: "max-content",
                alignItems: "center",
              }}
            >
              <Button
                variant="outlined"
                onClick={() => {
                  history.push(
                    ROUTES_PATH.MEETING_SCHEDULED.replace(":id", data.id)
                  );
                }}
              >
                View details
              </Button>
            </Box>
          )}
        </Grid>
      </Grid>
    </Card>
  );
}
