import React, { useState, useContext, useMemo, useEffect } from "react";
import { Box, Grid, MenuItem, Select, Stack, Typography, Button, TextField } from "@mui/material";
import UpdatedTextInputField from "../../../common/UpdatedTextInputField";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import services from "../../../services";
import { StyledSelect } from "../../../common/UpdatedSelectField/style";
import CompleteYourProfile from "../../../common/CompleteYourProfile";
import ReusableQuill from "../../../common/ReusableQuill";
import AddressAutoCompleteField from "../../LocationAutoCompleteFields/AddressAutoCompleteField";
import CountryAutoComplete from "../../LocationAutoCompleteFields/CountryAutoCompleteField";

const AboutMeSection = ({ context }) => {
	const fieldsList = [
		{ "First Name": "firstName" },
		{ "Last Name": "lastName" },
		{ Position: "profession" },
		{ TagLine: "headline" },
		{ "Street Address": "address1" },
		{ "Apartment/Suite/Unit, District, Neighborhood, etc.": "address2" },
		{ City: "city" },
		{ State: "state" },
		{ Country: "country" },
	];
	const { payLoad, setPayLoad, setOpenHarmonic, errors, setErrors } = useContext(context);
	const [countryStateData, setCountryStateData] = useState([]);
	const handleChange = (event) => {
		let key = event.target.id || event.target.name;
		let newValue = event.target.value;
		payLoad[key] = newValue;
		setPayLoad({ ...payLoad });
	};

	const handleBlur = (event) => {
		let key = event.target.id || event.target.name;
		let newValue = event.target.value;
		if (key === "firstName") {
			if (!newValue) {
				setErrors((prevState) => ({
					...prevState,
					firstNameError: "Please enter first name.",
				}));
			} else {
				setErrors((prevState) => ({
					...prevState,
					firstNameError: "",
				}));
			}
		}
		if (key === "lastName") {
			if (!newValue) {
				setErrors((prevState) => ({
					...prevState,
					lastNameError: "Please enter last name.",
				}));
			} else {
				setErrors((prevState) => ({
					...prevState,
					lastNameError: "",
				}));
			}
		}
	};

	const handleAboutChange = (value) => {
		payLoad["about"] = value;
		setPayLoad({ ...payLoad });
	};

	const getCountryStateData = async () => {
		const res = await services.getListOfCountryStateApi();
		setCountryStateData(res?.data?.data);
	};

	const getOptions = useMemo(() => {
		let selectedCountry = "USA";
		let stateList = [];

		if (countryStateData.length > 0) {
			stateList = countryStateData.filter((item) => item.countryCode === "USA")[0]?.states;
		}
		return stateList;
	}, [countryStateData]);

	useEffect(() => {
		if (!payLoad["country"]) {
			payLoad["country"] = "US";
			setPayLoad({ ...payLoad });
		}
		getCountryStateData();
	}, []);

	const autoFillAddress = (values) => {
		if (values?.streetAddress) {
			payLoad["address1"] = values?.streetAddress;
		}
		if (values?.city) {
			payLoad["city"] = values?.city;
		}
		if (values?.state) {
			payLoad["state"] = values?.state;
		}
		if (values?.zipcode) {
			payLoad["zipcode"] = values?.zipcode;
		}
		if (values?.country_code) {
			payLoad["country"] = values?.country_code;
		}
		setPayLoad({ ...payLoad });
	};

	const copyFromLinkedInClick = () => {
		setOpenHarmonic(true);
	};

	const countryChanged = (e) => {
		payLoad["country"] = e?.code ?? "US";
		payLoad["state"] = "";
		payLoad["zipcode"] = "";
		setPayLoad({ ...payLoad });
	};
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				rowGap: "60px",
			}}
		>
			<Box>
				<Typography variant="poppinsSemiBold20">About Me</Typography>
			</Box>
			<Box>
				<Grid container rowSpacing={4} columnSpacing={7}>
					<Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
						<Stack direction={"column"} spacing={3}>
							{fieldsList?.map((item) => {
								if (Object.keys(item)[0] === "State") {
									return (
										<Box>
											<Typography variant="poppinsSemiBold18">
												{payLoad["country"] && (payLoad["country"] == "US" || payLoad["country"] == "USA")
													? "State"
													: payLoad["country"] && payLoad["country"] == "CA"
													? "Province"
													: "Region"}
											</Typography>
											{!payLoad["country"] || (payLoad["country"] && (payLoad["country"] == "US" || payLoad["country"] == "USA")) ? (
												<StyledSelect
													fullWidth
													value={payLoad["state"]}
													name={Object.values(item)[0]}
													onChange={handleChange}
													IconComponent={KeyboardArrowDownRoundedIcon}
													sx={{
														mt: 1,
														"& .MuiSelect-select": {
															fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
															fontSize: "16px",
															lineHeight: "24px",
														},
													}}
													MenuProps={{ PaperProps: { sx: { maxHeight: "300px" } } }}
												>
													{getOptions?.map((option) => (
														<MenuItem key={option.stateCode} value={option.stateCode}>
															{option.stateName}
														</MenuItem>
													))}
												</StyledSelect>
											) : (
												<UpdatedTextInputField
													id={"state"}
													handleChange={handleChange}
													handleBlur={handleBlur}
													inputValue={payLoad["state"]}
													placeholder={payLoad["country"] && payLoad["country"] == "CA" ? "Province" : "Region"}
													textFieldProps={{
														fullWidth: true,
														width: "100%",
														height: "60px",
													}}
												/>
											)}
										</Box>
									);
								} else if (Object.keys(item)[0] === "Street Address") {
									return (
										<AddressAutoCompleteField
											onValueSet={(e) => autoFillAddress(e)}
											fieldLabel={"Street Address"}
											fieldPlaceholder={"Street Address"}
											parentValue={payLoad["address1"]}
										/>
									);
								} else if (Object.keys(item)[0] === "Country") {
									return (
										<CountryAutoComplete
											onValueSet={(e) => countryChanged(e)}
											fieldLabel={"Country"}
											fieldPlaceholder={"Country"}
											parentValue={payLoad["country"] ?? "US"}
										/>
									);
								} else {
									return (
										<UpdatedTextInputField
											label={Object.keys(item)[0]}
											id={Object.values(item)[0]}
											handleChange={handleChange}
											handleBlur={handleBlur}
											inputValue={payLoad[Object.values(item)[0]]}
											placeholder={Object.keys(item)[0]}
											textFieldProps={{
												fullwidth: true,
												width: "100%",
												height: "60px",
											}}
											error={
												Object.values(item)[0] === "lastName"
													? errors.lastNameError
													: Object.values(item)[0] === "firstName"
													? errors.firstNameError
													: ""
											}
										/>
									);
								}
							})}
							<Box display={"flex"} alignItems={"center"} gap={2}>
								{" "}
								<UpdatedTextInputField
									label={payLoad["country"] && (payLoad["country"] == "US" || payLoad["country"] == "USA") ? "Zipcode" : "Postal Code"}
									id={"zipcode"}
									handleChange={handleChange}
									handleBlur={handleBlur}
									inputValue={payLoad["zipcode"]}
									placeholder={payLoad["country"] && (payLoad["country"] == "US" || payLoad["country"] == "USA") ? "Zipcode" : "Postal Code"}
									textFieldProps={{
										fullWidth: true,
										width: "100%",
										height: "60px",
									}}
								/>{" "}
								<UpdatedTextInputField
									label={"Phone"}
									id={"phone"}
									handleChange={handleChange}
									handleBlur={handleBlur}
									inputValue={payLoad["phone"]}
									placeholder={"Phone"}
									textFieldProps={{
										fullWidth: true,
										width: "100%",
										height: "60px",
									}}
								/>{" "}
							</Box>
						</Stack>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
						<Stack direction={"column"} spacing={3}>
							<Box>
								<Typography variant="count_title" component={"p"}>
									Bio
								</Typography>
								<Box
									sx={{
										mt: 2,
										backgroundColor: "white",
										borderRadius: "30px",
										p: 2,
										boxShadow: "0px 0px 0px 4px #F2F4F7, 0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
									}}
								>
									<ReusableQuill
										id={"founder-profile"}
										value={payLoad["about"]}
										onChange={handleAboutChange}
										className={"profile-container"}
									/>
								</Box>
							</Box>
							<UpdatedTextInputField
								label={"LinkedIn URL"}
								id={"linkedInUrl"}
								handleChange={handleChange}
								handleBlur={handleBlur}
								inputValue={payLoad["linkedInUrl"]}
								placeholder={"LinkedIn URL"}
								textFieldProps={{
									fullwidth: true,
									width: "100%",
									height: "60px",
								}}
							/>{" "}
							<Box sx={{ mt: 1 }}>
								<Button variant="DS1" onClick={copyFromLinkedInClick} disabled={!payLoad["linkedInUrl"]}>
									Copy profile from Linkedin
								</Button>
							</Box>
						</Stack>
					</Grid>
				</Grid>
			</Box>
		</Box>
	);
};

export default AboutMeSection;
