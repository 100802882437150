import React from "react";
import { Box, Stack, row, Typography } from "@mui/material";
import { SliderComponent } from "./styles";

export const SliderScale = ({
  title_left,
  title_right,
  handleChange,
  key,
  disabled = false,
  value,
}) => {
  return (
    <Box>
      <SliderComponent
        defaultValue={value}
        step={1}
        min={0}
        max={10}
        disabled={disabled}
        onChange={e => {
          handleChange(e, key);
        }}
        valueLabelDisplay="off"
      />
      <Stack direction="row" justifyContent="space-between">
        <Box>
          <Typography
            color={theme => theme.palette.secondary.korophKoal}
            variant="StartupOS/body/medium"
          >
            {title_left}
          </Typography>
        </Box>
        <Box>
          <Typography
            color={theme => theme.palette.secondary.korophKoal}
            variant="StartupOS/body/medium"
          >
            {title_right}
          </Typography>
        </Box>
      </Stack>
    </Box>
  );
};
