import React, { useState, useEffect, Fragment } from "react";
import { Box, CircularProgress, Button } from "@mui/material";
import formatTime from "./Functions/FormatTime";
import groupDays from "./Functions/GroupDays";
import moment from "moment";
import { useSelector } from "react-redux";
import services from "../../../services";
import { createAvailabilityArray, convertToISOTime } from "./Functions/availablityUtils";
import { toastContainer } from "../../../utils/toast";
import messages from "../../../constants/messages";
import LoadingButton from "../../../common/Loadingbutton";
import { handleMixPanel } from "../../../utils/mixPanelEventHandling";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import TimezoneSelection from "./components/TimezoneSelection";
import DayCard from "./components/DayCard";
import GoogleCalendarConnection from "../../../components/GoogleCalendarConnection";
import useDimensions from "react-cool-dimensions";
import _ from "lodash";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUserProfileData } from "../../../modules/actions/userProfileActions";
import CreateAvailability from "./components/CreateAvailability";
import { convertTimeToTargetTimezone } from "../../../utils/commonFunctions";

export default function Availability() {
	const [daySwitchList, setDaySwitchList] = React.useState([]);
	const [initialList, setInitialList] = React.useState([]);

	const [selectedTimeZone, setSelectedTimeZone] = useState(moment.tz.guess());
	const [availabilities, setAvailabilities] = useState([]);
	const [scheduleId, setScheduleid] = useState(null);
	const [displayData, setDisplayData] = useState([]);
	const [availabilitiesToDelete, setAvailabilitiesToDelete] = useState([]);
	const [loading, setLoading] = useState(false);
	const [dataLoading, setDataLoading] = useState(false);
	const [scheduleData, setScheduleData] = useState(null);
	const [schedulesObj, setSchedulesObj] = useState([]);

	const [availabilitySaved, setAvailabilitySaved] = useState(false);

	const [flexBasis, setflexBasis] = useState({});

	const userData = useSelector((state) => state?.auth);

	const colGap = 20;
	//
	const location = useLocation();
	const dispatch = useDispatch();

	const { observe, unobserve, width, height, entry } = useDimensions({
		onResize: _.throttle(() => {
			const newFlexBasis = calculateFlexBasis();
			setflexBasis(newFlexBasis);
		}, 500),
	});

	const calculateFlexBasis = () => {
		const itemWidth = 450; // Minimum width of each item
		const calcMax = Math.floor((width + colGap) / (itemWidth + colGap));
		const maxItemsPerRow = calcMax > 3 ? 3 : calcMax;

		switch (maxItemsPerRow) {
			case 1:
				return {
					display: "grid",
					gridTemplateColumns: "1fr",
					gridGap: `${colGap}px`,
					rowGap: "32px",
				};
			case 2:
				return {
					display: "grid",
					gridTemplateColumns: "repeat(2, minmax(300px, 1fr))",
					gridGap: `${colGap}px`,
					rowGap: "32px",
				};
			case 3:
				return {
					display: "grid",
					gridTemplateColumns: "repeat(3, minmax(300px, 1fr))",
					gridGap: `${colGap}px`,
					rowGap: "32px",
				};
			default:
				return {
					display: "grid",
					gridTemplateColumns: "1fr",
					gridGap: `${colGap}px`,
					rowGap: "32px",
				};
		}
	};

	const refreshAvailabilities = () => {
		setAvailabilitiesToDelete([]);
		setAvailabilities([]);
		setDataLoading(true);
		services
			.getSchedulesByUserIdApi(userData.userId)
			.then((res) => {
				const schedules = res?.data?.data;

				if (schedules.length) {
					setSchedulesObj(schedules);
					const currentSchedule = schedules[0];
					// currentSchedule?.availabilities.forEach((scheduleItem) => {
					// 	scheduleItem.startTime = convertTimeToTargetTimezone(scheduleItem.startTime);
					// 	scheduleItem.endTime = convertTimeToTargetTimezone(scheduleItem.endTime);
					// });
					setScheduleid(currentSchedule.id);
					setSelectedTimeZone(currentSchedule.timezone);
					setAvailabilities(currentSchedule.availabilities);
					setDaySwitchList(createAvailabilityArray(currentSchedule.availabilities)?.sort((a, b) => a.day - b.day));
					setScheduleData(currentSchedule);
					setInitialList(createAvailabilityArray(currentSchedule.availabilities));
					setDataLoading(false);
				}
			})
			.catch((err) => console.log(err));
	};

	useEffect(() => {
		displayDayandTime();
	}, [daySwitchList]);

	useEffect(() => {
		observe();
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		});

		if (userData.userId) {
			setDataLoading(true);
			services
				.getSchedulesByUserIdApi(userData.userId)
				.then((res) => {
					const schedules = res?.data?.data;

					if (schedules.length === 0) {
						setSelectedTimeZone(moment.tz.guess() ? moment.tz.guess() : "US/Eastern");
						setDataLoading(false);
					} else if (schedules.length) {
						const currentSchedule = schedules[0];
						// currentSchedule?.availabilities.forEach((scheduleItem) => {
						// 	scheduleItem.startTime = convertTimeToTargetTimezone(scheduleItem.startTime);
						// 	scheduleItem.endTime = convertTimeToTargetTimezone(scheduleItem.endTime);
						// });
						setSchedulesObj(schedules);

						setScheduleData(currentSchedule);
						setScheduleid(currentSchedule.id);
						setSelectedTimeZone(currentSchedule.timezone);
						setAvailabilities(currentSchedule.availabilities);
						setDaySwitchList(createAvailabilityArray(currentSchedule.availabilities)?.sort((a, b) => a.day - b.day));
						setInitialList(createAvailabilityArray(currentSchedule.availabilities));
						setDataLoading(false);
					}
				})
				.catch((err) => {
					setDataLoading(false);
					console.log(err);
				});
		}
		handleMixPanel("Meetings Page Viewed", {
			Page: "My Availability",
		});
	}, []);

	const handleSwitchChange = (checkedValue, index, label) => {
		handleMixPanel("Meeting Availability Edited", {
			Edit: "Day",
		});

		const list = [...daySwitchList];
		if (!checkedValue) {
			let deleteIds = [];

			list[index].timeList.map((x) => {
				if (x.availabilityId) {
					deleteIds.push(x.availabilityId);
				}
			});
			setAvailabilitiesToDelete((prev) => [...prev, ...deleteIds]);
		} else {
			list[index].timeList.map((x) => {
				const index = availabilitiesToDelete.findIndex((i) => i === x.availabilityId);

				if (index >= 0) {
					availabilitiesToDelete.splice(index, 1);
					setAvailabilitiesToDelete(availabilitiesToDelete);
				}
			});
			list[index].timeList.forEach((listItem) => {
				listItem.startTime = "09:00:00";
				listItem.endTime = "17:00:00";
			});
		}

		if (list[index].label === label) {
			list[index].value = !list[index].value;
		}
		setDaySwitchList(list?.sort((a, b) => a.day - b.day));
	};

	const displayDayandTime = () => {
		const data = daySwitchList;
		const newData = [];
		const dData = [];
		const newDdata = [];
		data.map((item) => {
			if (item.value === true) {
				const l = item.label;
				for (let i of item.timeList) {
					const startTime = i.startTime && moment(i.startTime).format("HH:mm:ss");
					const endTime = i.endTime && moment(i.endTime).format("HH:mm:ss");
					const strn = "" + startTime + " - " + endTime;
					newData.push({ timeList: { strn, l } });
				}
			}
		});
		const groupBy = (key1, key2, array) =>
			array.reduce(
				(objectsByKeyValue, obj) => ({
					...objectsByKeyValue,
					[obj[key1][key2]]: (objectsByKeyValue[obj[key1][key2]] || []).concat(obj),
				}),
				{}
			);

		const groupByStartTime = groupBy("timeList", "strn", newData);

		for (let i = 0; i < Object.values(groupByStartTime).length; i++) {
			if (Object.values(groupByStartTime)[i].length > 1) {
				dData.push(Object.values(groupByStartTime)[i]);
			} else {
				dData.push(Object.values(groupByStartTime)[i]);
			}
		}

		dData.map((item) => {
			if (item.length > 1) {
				// const day1 = `${item[0].timeList.l.slice(0, 3)}`;
				// const day2 = `${item[item.length - 1].timeList.l.slice(0, 3)}`;
				const time = `${item[0].timeList.strn}`;
				// const str = "" + day1 + "-" + day2 + " , " + time;
				const str = groupDays(item) + time;
				newDdata.push(str);
			} else {
				const day = `${item[0].timeList.l.slice(0, 3)}`;
				const time = `${item[0].timeList.strn}`;
				const str = "" + day + " , " + time;
				newDdata.push(str);
			}
		});
		setDisplayData(newDdata);
	};

	const handleSave = () => {
		let availabilitiesToAdd = [];
		let availabilitiesToUpdate = [];

		daySwitchList.forEach((item, index) => {
			if (item.value) {
				[...item.timeList].map((checkItem) => {
					if (!checkItem.availabilityId) {
						availabilitiesToAdd.push({
							scheduleId,
							day: item.day,
							startTime: checkItem.startTime && moment(checkItem.startTime, "HH:mm:ss").format("HH:mm:ss"),
							endTime: checkItem.endTime && moment(checkItem.endTime, "HH:mm:ss").format("HH:mm:ss"),
						});
					} else {
						const isUpdated = (data) => {
							const initialData = initialList[index];
							if (initialData) {
								const timeObj = initialData.timeList.find((x) => x.availabilityId === data.availabilityId);
								if (timeObj) {
									if (timeObj.startTime == data.startTime && timeObj.endTime == data.endTime) {
										return false;
									} else {
										return true;
									}
								} else {
									return true;
								}
							}
						};
						if (!availabilitiesToDelete.includes(checkItem.availabilityId) && isUpdated(checkItem)) {
							availabilitiesToUpdate.push({
								id: checkItem.availabilityId,
								day: item.day,
								startTime: checkItem.startTime && moment(checkItem.startTime, "HH:mm:ss").format("HH:mm:ss"),
								endTime: checkItem.endTime && moment(checkItem.endTime, "HH:mm:ss").format("HH:mm:ss"),
							});
						}
					}
				});
			}
		});

		// Function to create a promise for updating a single item
		const createUpdatePromise = () => {
			const payload = {
				name: scheduleData?.name,
				timezone: selectedTimeZone ? selectedTimeZone : scheduleData?.timezone,
				availabilities: availabilitiesToAdd.concat(availabilitiesToUpdate),
			};
			return new Promise((resolve, reject) => {
				services
					.updateScheduleApi(scheduleId, payload)
					.then((res) => {
						resolve(true);
					})
					.catch((err) => reject(err));
			});
		};

		// Using Promise.all to wait for all promises to resolve
		const allUpdate = Promise.resolve(createUpdatePromise());

		function replaceDayNames(inputString) {
			let days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

			// Regular expression to match "Day 1", "Day 2", ..., "Day 7"
			let regex = /Day (\d)/g;

			// Replace matches with corresponding day names
			let replacedString = inputString.replace(regex, function (match, dayNumber) {
				// Convert dayNumber to integer and subtract 1 to match array index
				let index = parseInt(dayNumber) - 1;
				return days[index];
			});

			return replacedString;
		}

		setLoading(true);
		allUpdate
			.then((res) => {
				setLoading(false);
				refreshAvailabilities();
				setAvailabilitySaved(true);
				toastContainer(messages.SAVE_SUCCESS, "success");
			})
			.catch((err) => {
				setLoading(false);
				console.log(err);
				if (err.response && err.response.data && err.response.data.message) {
					const errorMessage = err.response.data.message;
					var modifiedString = replaceDayNames(errorMessage);

					toastContainer(modifiedString, "error");
				} else {
					toastContainer("An error occurred while saving.", "error");
				}
			});
	};

	const updateDayData = (data) => {
		// Retrieve the current state array
		const currentItems = [...daySwitchList];

		// Find the index of the object you want to update
		const indexToUpdate = currentItems.findIndex((item) => item.label === data?.label);

		if (indexToUpdate !== -1) {
			// Create a new array with the updated object
			const updatedItems = [...currentItems.slice(0, indexToUpdate), data, ...currentItems.slice(indexToUpdate + 1)];

			// Update the state with the new array
			setDaySwitchList(updatedItems);
		}
	};

	return (
		<Box>
			<LocalizationProvider dateAdapter={AdapterMoment}>
				{dataLoading ? (
					<Box sx={{ display: "flex", justifyContent: "center", py: 5 }}>
						<CircularProgress />
					</Box>
				) : (
					<Fragment>
						<Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", flexWrap: "wrap", mb: "60px", gap: 2 }}>
							<TimezoneSelection selectedTimeZone={selectedTimeZone} setSelectedTimeZone={setSelectedTimeZone} />
							{/*
							// Comment out until backend flow has completed.
							<GoogleCalendarConnection />*/}
						</Box>

						<>
							{schedulesObj.length > 0 ? (
								<Box ref={observe} sx={{ pb: "32px" }}>
									{width && flexBasis ? (
										<Fragment>
											<Box sx={{ ...flexBasis }}>
												{daySwitchList?.map((dayItem, dayIdx) => {
													if (dayItem?.value) {
														return (
															<DayCard
																dayData={dayItem}
																selected={dayItem?.value}
																selectChange={(value) => handleSwitchChange(value, dayIdx, dayItem?.label)}
																updateDayData={(data) => {
																	updateDayData(data);
																}}
																updateDeleteArray={(id) => {
																	setAvailabilitiesToDelete((prev) => [...prev, id]);
																}}
															/>
														);
													}
												})}
											</Box>
											<Box sx={{ ...flexBasis, mt: "32px" }}>
												{daySwitchList?.map((dayItem, dayIdx) => {
													if (!dayItem?.value) {
														return (
															<DayCard
																dayData={dayItem}
																selected={dayItem?.value}
																selectChange={(value) => handleSwitchChange(value, dayIdx, dayItem?.label)}
															/>
														);
													}
												})}
											</Box>
											{/* Added cancel button conditionally on availability tab in edit drawer did chnages in sx of box below */}
											<Box sx={{ mt: 4, justifyContent: "flex-end", gap: 1, alignItems: "center" }} display="flex">
												{location?.pathname === "/mentor-profile" ? (
													<Button
														onClick={() => {
															dispatch(
																setUserProfileData({
																	userProfileEditOpen: false,
																})
															);
														}}
														variant="noOutlineNew"
														sx={{
															height: "40px",
															m: 0,
															width: "fit-content",
															fontSize: "18px",
															lineHeight: "28px",
															fontFamily: "PoppinsSemiBold",
														}}
													>
														Cancel
													</Button>
												) : (
													<></>
												)}

												{/* if availabilites not found disable the save button */}
												{schedulesObj.length > 0 && (
													// <LoadingButton variant="DS1" loading={loading} onClick={handleSave}>
													// 	Save
													// 		</LoadingButton>
													<Button
														startIcon={loading && <CircularProgress size={"1.25rem"} sx={{ color: "inherit" }} />}
														variant="DS1"
														onClick={handleSave}
													>
														Save
													</Button>
												)}
											</Box>
										</Fragment>
									) : (
										<Box sx={{ display: "flex", justifyContent: "center", py: 5 }}>
											<CircularProgress />
										</Box>
									)}
								</Box>
							) : (
								<Box sx={{ display: "flex", justifyContent: "center", mb: "20px" }}>
									<CreateAvailability
										userId={userData.userId}
										selectedTimeZone={selectedTimeZone}
										refreshAvailabilities={refreshAvailabilities}
									/>
								</Box>
							)}
						</>
					</Fragment>
				)}
			</LocalizationProvider>
		</Box>
	);
}
