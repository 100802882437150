import { useEffect, useState } from "react";

import { useTheme } from "@twilio-paste/theme";
import { MessageStatus } from "../../../../Messaging/store/reducers/messageListReducer";
import SendingIcon from "../../../../Messaging/components/icons/Sending";
import DeliveredIcon from "../../../../Messaging/components/icons/Delivered";
import ReadIcon from "../../../../Messaging/components/icons/Read";
import FailedIcon from "../../../../Messaging/components/icons/Failed";
import BellMuted from "../../../../Messaging/components/icons/BellMuted";
import { NOTIFICATION_LEVEL } from "../../../../Messaging/constants";
import { getMessageStatus } from "../../../../Messaging/api";
import * as _ from "lodash";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import {
	Avatar,
	AvatarGroup,
	Badge,
	Box,
	Button,
	Chip,
	IconButton,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Menu,
	MenuItem,
	Popover,
	Skeleton,
	Typography,
} from "@mui/material";
import services from "../../../../../services";
import { Announcement, AnnouncementOutlined, CallReceived, Circle, MoreHoriz } from "@mui/icons-material";
import { getSdkConversationObject } from "../../../../Messaging/conversations-objects";
import DialogComponent from "../../../../../common/CommonDialog";
import { LoadingButton } from "@mui/lab";
import LeaveConversation from "./LeaveConversation";
import AvatarWithFallBack from "../../../../../common/AvatarWithFallBack";
import { USER_TYPES } from "../../../../../utils/userTypes";
import { setSlidingProfileData } from "../../../../../modules/actions/slidingProfileActions";
import { useDispatch } from "react-redux";
import { getRoleValue } from "../../../../../utils/commonFunctions";

TimeAgo.addDefaultLocale(en);
const measureWidth = (text) => {
	const canvas = document.createElement("canvas");
	const context = canvas.getContext("2d");
	if (!context) {
		return 0;
	}
	context.font = "bold 14px Inter";
	return context.measureText(text).width;
};
function calculateUnreadMessagesWidth(count) {
	if (count === 0 || !count) {
		return 0;
	}
	return measureWidth(count.toString()) + 32;
}
function truncateMiddle(text, countWidth) {
	const width = measureWidth(text);
	if (width > 288 - countWidth) {
		const textLength = text.length;
		const avgLetterSize = width / textLength;
		const nrOfLetters = (288 - countWidth) / avgLetterSize;
		const delEachSide = (textLength - nrOfLetters + 1) / 2;
		const endLeft = Math.floor(textLength / 2 - delEachSide);
		const startRight = Math.ceil(textLength / 2 + delEachSide);
		return text.substr(0, endLeft) + "..." + text.substr(startRight);
	}
	return text;
}

function getLastMessageTime(messages) {
	const lastMessageDate = _.last(messages)?.dateCreated;

	if (!lastMessageDate) {
		return "";
	}

	const today = new Date();
	const yesterday = new Date(today);
	yesterday.setDate(yesterday.getDate() - 1);

	const lastDate = new Date(lastMessageDate);

	if (isSameDate(lastDate, today)) {
		return "Today";
	} else if (isSameDate(lastDate, yesterday)) {
		return "Yesterday";
	} else {
		const formattedDate = `${lastDate.getMonth() + 1}/${lastDate.getDate()}/${lastDate.getFullYear() % 100}`;
		return formattedDate;
	}
}

function isSameDate(date1, date2) {
	return date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth() && date1.getFullYear() === date2.getFullYear();
}
const ConversationView = (props) => {
	const { convo, convoId, myMessage, lastMessage, unreadMessagesCount } = props;
	const participantUserType = convo?.startuposMeta?.conversationParticipants[0]?.userTypes[0];
	const [anchorEl, setAnchorEl] = useState(null);
	const [confOpen, setConfOpen] = useState(false);
	const [loading, setloading] = useState(false);
	const [backgroundActiveColor, setBackgroundActiveColor] = useState(false);
	const title = truncateMiddle(convo.friendlyName ?? convo.sid, calculateUnreadMessagesWidth(unreadMessagesCount));
	const twiltheme = useTheme();
	const textColor = unreadMessagesCount > 0 ? twiltheme.textColors.colorText : twiltheme.textColors.colorTextIcon;
	const muted = convo.notificationLevel === NOTIFICATION_LEVEL.MUTED;
	const [lastMsgStatus, setLastMsgStatus] = useState("");
	const time = getLastMessageTime(props.messages);
	const dispatch = useDispatch();

	useEffect(() => {
		if (props.currentConvoSid === convo.sid) {
			setBackgroundActiveColor(true);
			return;
		}
		setBackgroundActiveColor(false);
	}, [props.currentConvoSid, convo.sid]);
	useEffect(() => {
		if (myMessage && !props.typingInfo.length) {
			getMessageStatus(myMessage, props.participants).then((statuses) => {
				if (statuses[MessageStatus.Read]) {
					setLastMsgStatus(MessageStatus.Read);
					return;
				}
				if (statuses[MessageStatus.Delivered]) {
					setLastMsgStatus(MessageStatus.Delivered);
					return;
				}
				if (statuses[MessageStatus.Failed]) {
					setLastMsgStatus(MessageStatus.Failed);
					return;
				}
				if (statuses[MessageStatus.Sending]) {
					setLastMsgStatus(MessageStatus.Sending);
					return;
				}
			});
		}
	}, [convo, myMessage, lastMessage, props.participants, props.typingInfo]);

	const getConversationName = () => {
		if (convo?.startuposMeta?.conversationParticipants) {
			let name = "";
			convo?.startuposMeta?.conversationParticipants?.map((participant, index) => {
				name += participant?.name;
				if (index < convo?.startuposMeta?.conversationParticipants?.length - 1) {
					name += " & ";
				}
			});
			return name;
		} else if (convo?.startuposMeta?.name) {
			return convo?.startuposMeta?.name;
		} else {
			return "";
		}
	};

	return (
		<ListItem
			id={convoId}
			sx={(theme) =>
				!props.condensedView
					? {
							alignSelf: "stretch",
							p: "0 0 0 0px",
							gap: 1,
							cursor: "pointer",
							borderRadius: theme.shape.standard13,
							backgroundColor: backgroundActiveColor ? theme.palette.sos.gray100 : "inherit",
							":hover": {
								backgroundColor: backgroundActiveColor ? theme.palette.sos.gray100 : theme.palette.secondary.lighter,
							},
					  }
					: { alignSelf: "stretch", p: "0 0 0 0px", gap: 1, cursor: "pointer" }
			}
			// onClick={props.onClick}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					gap: 1,
				}}
				onClick={props.onClick}
			>
				{!props.condensedView && (
					<Circle
						sx={{
							fontSize: "10px",
							color: (theme) => (unreadMessagesCount && unreadMessagesCount > 0 ? theme.palette.error.main : "transparent"),
						}}
					/>
				)}

				{convo?.startuposMeta?.conversationParticipants && (
					<AvatarGroup
						max={1}
						componentsProps={{
							additionalAvatar: {
								sx: {
									background: (theme) => theme.palette.text.onSurfaceVariant,
									borderWidth: "1px",
									height: "30px",
									width: "30px",
									fontSize: "80%",
									position: "absolute",
									right: "0",
									bottom: "0",
									zIndex: "1",
								},
							},
						}}
						sx={{
							width: "50px",
							height: "50px",
							"&": () => {
								if (convo?.startuposMeta?.conversationType === "GROUP" || convo?.startuposMeta?.conversationParticipants?.length > 1) {
									return {
										position: "relative",
										overflow: "hidden",
									};
								}
							},
						}}
					>
						{convo?.startuposMeta?.conversationParticipants.map((item, index) => {
							return (
								<AvatarWithFallBack
									key={index}
									src={item?.picture}
									sx={{
										height: "50px",
										width: "50px",
										"&": () => {
											if (
												convo?.startuposMeta?.conversationType === "GROUP" ||
												convo?.startuposMeta?.conversationParticipants?.length > 1
											) {
												let positionOb = index == 0 ? { left: "0", top: "0", zIndex: "0" } : { right: "0", bottom: "0", zIndex: "1" };
												return {
													height: "30px",
													width: "30px",
													borderWidth: "1px !important", // Override the Avatar circle only for groups. Low ROI to figure out global variant for this case. !important is acceptable inline.
													position: "absolute",
													...positionOb,
												};
											}
										},
									}}
								/>
							);
						})}
					</AvatarGroup>
				)}
			</Box>
			<Box
				// Text content box
				sx={{
					display: "flex",
					flexDirection: "column",
					padding: "12px 4px 12px 0px",
					minWidth: 0,
					flex: 1,
				}}
			>
				<Box
					// Name and Date Box
					sx={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						padding: "0px 8px 0px 0px",
						gap: "10px",
						flex: 1,
					}}
				>
					<Box
						// Name Box
						sx={{ flex: 1, minWidth: 0 }}
						onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
							if (!props.condensedView) {
								const participant = convo?.startuposMeta?.conversationParticipants?.[0];
								if (participant) {
									dispatch(
										setSlidingProfileData({
											open: true,
											profileType: participant?.userTypes?.includes(USER_TYPES.MENTOR_USER) ? "MENTOR_PROFILE" : "ACCOUNT_PROFILE",
											profileId: participant?.id,
										})
									);
								}
							}
						}}
					>
						<Typography
							component="span"
							variant={props.condensedView ? "Text/sm/Semibold" : "Text/sm/Semibold"}
							sx={
								props.condensedView
									? {}
									: {
											whiteSpace: "nowrap",
											overflow: "hidden",
											textOverflow: "ellipsis",
											display: "block",
									  }
							}
							color={"#667085"}
						>
							{getConversationName()}

							{/* {convo?.startuposMeta?.name ? convo?.startuposMeta?.name : title} */}
						</Typography>
					</Box>
					{!props.condensedView && (
						<Box
							// Date Box
							onClick={props.onClick}
							sx={{ backgroundColor: "#F2F4F7", borderRadius: "3px", border: "1px", borderColor: "#F2F4F7", p: time && "2px" }}
						>
							<Typography variant="Text/sm/Regular" color="#475467" sx={{ p: time && "3px" }}>
								{time}
							</Typography>
						</Box>
					)}
				</Box>
				{!props.condensedView && (
					<Box
						// Message Content + Action
						sx={{
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
							gap: 1,
							flex: 1,
						}}
						onClick={props.onClick}
					>
						<Box
							// Message Content Box
							sx={{ flex: 1, minWidth: 0 }}
						>
							<Typography
								component="p"
								variant="Text/sm/Regular"
								sx={{
									display: "-webkit-box",
									"-webkit-line-clamp": "2",
									"-webkit-box-orient": "vertical",
									overflow: "hidden",
								}}
								color={"secondary.outline"}
							>
								{getRoleValue(participantUserType)}
							</Typography>
						</Box>
						<Box
						// Action Box
						>
							<LeaveConversation
								open={confOpen}
								conversationID={convoId}
								close={() => {
									setAnchorEl(false);
									setloading(false);
									setConfOpen(false);
								}}
							/>
							<Menu
								elevation={1}
								PaperProps={{
									sx: {
										background: (theme) => theme.palette.primary.read_only_light_surface2,
									},
								}}
								open={Boolean(anchorEl)}
								anchorEl={anchorEl}
								anchorOrigin={{
									vertical: "bottom",
									horizontal: "left",
								}}
								transformOrigin={{
									vertical: "top",
									horizontal: "left",
								}}
								onClose={() => {
									setAnchorEl(null);
								}}
							>
								<MenuItem
									sx={{
										minWidth: "200px",
										display: "flex",
										gap: 2,
										alignItems: "center",
									}}
									// TODO -- On click need to 'leave' conversation
									onClick={async () => {
										setConfOpen(true);
									}}
								>
									<CallReceived />
									<Typography variant="body_large">Leave</Typography>
								</MenuItem>
							</Menu>

							{/*Commented Icon as per Scott's requirement SOS-506*/}

							{/*<IconButton
                sx={{
                  color: theme => theme.palette.text.lightOnsurface,
                }}
                onClick={event => {
                  setAnchorEl(anchorEl ? null : event.currentTarget);
                }}
              >
                <MoreHoriz />
              </IconButton>*/}
						</Box>
					</Box>
				)}

				{/*<ListItemText
          primary={convoMeta?.name ? convoMeta.name : title}
          secondary={
            <Box
              style={{
                paddingTop: 4,
                color: textColor,
                fontWeight: twiltheme.fontWeights.fontWeightNormal,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {!props.typingInfo.length ? (
                  <Box>
                    {lastMsgStatus === MessageStatus.Sending &&
                      props.myMessage && (
                        <Box style={{ paddingRight: 6 }}>
                          <SendingIcon />
                        </Box>
                      )}
                    {lastMsgStatus === MessageStatus.Delivered &&
                      props.myMessage && (
                        <Box style={{ paddingRight: 6 }}>
                          <DeliveredIcon />
                        </Box>
                      )}
                    {lastMsgStatus === MessageStatus.Failed && props.myMessage && (
                      <Box style={{ paddingRight: 6 }}>
                        <FailedIcon />
                      </Box>
                    )}
                    {lastMsgStatus === MessageStatus.Read && props.myMessage && (
                      <Box style={{ paddingRight: 6 }}>
                        <ReadIcon />
                      </Box>
                    )}
                  </Box>
                ) : null}
                <Box
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {lastMessage}
                </Box>
              </Box>
              <Box style={{ whiteSpace: "nowrap", paddingLeft: 4 }}>{time}</Box>
            </Box>
          }
        />*/}
			</Box>
		</ListItem>
	);
	//return (
	//  <Box
	//    style={{
	//      width: 320,
	//      paddingTop: 14,
	//      paddingBottom: 14,
	//      paddingLeft: 16,
	//      paddingRight: 16,
	//      cursor: "pointer",
	//      backgroundColor: backgroundColor,
	//      border: "2px solid red",
	//    }}
	//id={convoId}
	//className="name"
	//onMouseOver={() => {
	//  if (convo.sid === props.currentConvoSid) {
	//    return;
	//  }
	//  setBackgroundActiveColor(theme.backgroundColors.colorBackgroundStrong);
	//}}
	//onMouseOut={() => {
	//  if (convo.sid === props.currentConvoSid) {
	//    return;
	//  }
	//  setBackgroundActiveColor(theme.backgroundColors.colorBackgroundRowStriped);
	//}}
	//onClick={props.onClick}
	//  >
	//    <Box
	//      style={{
	//        backgroundColor: backgroundColor,
	//      }}
	//    >
	//      <Box display="flex">
	//        <Box
	//          style={{
	//            width: 288,
	//            fontFamily: "sans-serif",
	//            fontWeight: theme.fontWeights.fontWeightMedium,
	//            fontSize: 14,
	//            color: muted
	//              ? theme.textColors.colorTextInverseWeaker
	//              : theme.textColors.colorText,
	//          }}
	//        >
	//          {muted ? <BellMuted /> : null}
	//          <span style={{ verticalAlign: "top", paddingLeft: muted ? 4 : 0 }}>
	//            {title}
	//          </span>
	//        </Box>
	//{unreadMessagesCount > 0 && (
	//  <Box paddingLeft="space30">
	//    <Box
	//      backgroundColor="colorBackgroundBrandStronger"
	//      color="colorTextInverse"
	//      fontFamily="fontFamilyText"
	//      fontWeight="fontWeightBold"
	//      fontSize="fontSize30"
	//      lineHeight="lineHeight30"
	//      paddingLeft="space30"
	//      paddingRight="space30"
	//      style={{ borderRadius: 12, opacity: muted ? 0.2 : 1 }}
	//    >
	//      {unreadMessagesCount}
	//    </Box>
	//  </Box>
	//)}
	//      </Box>
	//<Box
	//  style={{
	//    paddingTop: 4,
	//    color: textColor,
	//    fontWeight: theme.fontWeights.fontWeightNormal,
	//    display: "flex",
	//    flexDirection: "row",
	//    justifyContent: "space-between",
	//  }}
	//>
	//  <Box
	//    style={{
	//      display: "flex",
	//      flexDirection: "row",
	//      whiteSpace: "nowrap",
	//      overflow: "hidden",
	//      textOverflow: "ellipsis",
	//    }}
	//  >
	//    {!props.typingInfo.length ? (
	//      <Box>
	//        {lastMsgStatus === MessageStatus.Sending && props.myMessage && (
	//          <Box style={{ paddingRight: 6 }}>
	//            <SendingIcon />
	//          </Box>
	//        )}
	//        {lastMsgStatus === MessageStatus.Delivered && props.myMessage && (
	//          <Box style={{ paddingRight: 6 }}>
	//            <DeliveredIcon />
	//          </Box>
	//        )}
	//        {lastMsgStatus === MessageStatus.Failed && props.myMessage && (
	//          <Box style={{ paddingRight: 6 }}>
	//            <FailedIcon />
	//          </Box>
	//        )}
	//        {lastMsgStatus === MessageStatus.Read && props.myMessage && (
	//          <Box style={{ paddingRight: 6 }}>
	//            <ReadIcon />
	//          </Box>
	//        )}
	//      </Box>
	//    ) : null}
	//    <Box
	//      style={{
	//        whiteSpace: "nowrap",
	//        overflow: "hidden",
	//        textOverflow: "ellipsis",
	//      }}
	//    >
	//      {lastMessage}
	//    </Box>
	//  </Box>
	//  <Box style={{ whiteSpace: "nowrap", paddingLeft: 4 }}>{time}</Box>
	//</Box>
	//    </Box>
	//  </Box>
	//);
};
export default ConversationView;
