import { Box, CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CancelMeetingPopup from "../../../components/Meetings/CancelMeetingPopup";
import MyBookingCard from "../../../components/Meetings/MyBookingCard";
import { setCurrentMeetingObject } from "../../../modules/actions/meetingsAction";
import services from "../../../services";
import { toastContainer } from "../../../utils/toast";
import { handleMixPanel } from "../../../utils/mixPanelEventHandling";

export default function Bookings() {
	const [cancelPopupOpen, setCancelPopupOpen] = useState(false);
	const [bookingData, setBookingData] = useState(null);
	const [bookings, setBookings] = useState([]);
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);

	const { userId } = useSelector((state) => state?.auth);

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		});
		if (userId) {
			setLoading(true);
			services
				.getAllBookingsApi(userId)
				.then((res) => {
					const allBookings = res?.data?.bookings;
					setBookings(allBookings);

					setLoading(false);
				})
				.catch((err) => {
					setLoading(false);
					console.log(err);
				});
		}
		dispatch(setCurrentMeetingObject(null));
		handleMixPanel("Meetings Page Viewed", {
			Page: "My Bookings",
		});
	}, []);

	const handleCancelBooking = (data) => {
		setCancelPopupOpen(true);
		setBookingData(data);

		handleMixPanel("Meeting Cancelled", {
			"Meeting ID": data?.id,
			Source: "Bookings",
		});
	};

	const closePopup = () => {
		setCancelPopupOpen(false);
		setBookingData(null);
	};

	const handleReschedule = () => {};

	const handleCancelApiCall = (reason) => {
		handleMixPanel("Meeting Cancelled", {
			"Meeting ID": bookingData.id,
			Source: "Confirmation",
		});

		services
			.cancelBookingApi(bookingData.id, reason)
			.then((res) => {
				console.log(res);
				const bookingsArr = [...bookings];
				toastContainer("Cancelled successfully", "success");
				const currentIndex = bookingsArr.findIndex((x) => x.id == bookingData.id);
				closePopup();
				if (currentIndex >= 0) {
					let updatedBooking = bookingsArr[currentIndex];
					updatedBooking.status = "CANCELLED";
					bookingsArr[currentIndex] = updatedBooking;
					setBookings(bookingsArr);
				}
			})
			.catch((err) => {
				toastContainer(err.response?.data?.message || "Error", "error");
				console.log(err);
				closePopup();
			});
	};

	return (
		<Box>
			<Box
				sx={(theme) => ({
					border: `1px solid ${theme.palette.secondary.outline2}`,
					borderRadius: theme.shape.standard.borderRadius,
				})}
			>
				{!loading &&
					bookings.map((item, index) => (
						<MyBookingCard data={item} isLast={index === bookings.length - 1} handleCancelBooking={handleCancelBooking} />
					))}

				{loading && (
					<Box sx={{ display: "flex", justifyContent: "center", py: 5 }}>
						{" "}
						<CircularProgress />{" "}
					</Box>
				)}
				{!loading && bookings.length === 0 && (
					<Box sx={{ display: "flex", justifyContent: "center", py: 5 }}>
						<Typography>No Bookings Found</Typography>
					</Box>
				)}
			</Box>

			{cancelPopupOpen && bookingData && (
				<CancelMeetingPopup
					open={cancelPopupOpen}
					onClose={closePopup}
					bookingData={bookingData}
					handleReschedule={handleReschedule}
					handleCancel={handleCancelApiCall}
				/>
			)}
		</Box>
	);
}
