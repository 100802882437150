import React from "react";
import { Box, Typography, useTheme, Grid } from "@mui/material";
import { PitchProgressCard } from "../styles";
import CustomCircularProgressBar from "../../../common/CustomCircularProgressBar";

function PitchProgress({ title, percentage, handlePitchProgressClick }) {
	const theme = useTheme();
	return (
		<PitchProgressCard
			sx={{
				cursor: "pointer",
				transition: "all .2s ease-in-out",
				"&:hover": {
					transform: "scale(1.08)",
				},
			}}
			onClick={handlePitchProgressClick}
		>
			<Box display="flex" justifyContent="center" alignItems="center">
				<Grid container alignItems="center" spacing={2}>
					<Grid item xs={4}>
						<CustomCircularProgressBar
							inputData={{ percentage: percentage, color: theme.palette.primary.main, width: "50px", height: "50px", size: 50, thickness: 7 }}
						/>
					</Grid>
					<Grid item xs={8}>
						<Box>
							<Typography variant="subhead1" color="secondary.black">
								{title}
							</Typography>
						</Box>
					</Grid>
				</Grid>
			</Box>
		</PitchProgressCard>
	);
}

export default PitchProgress;
