import React, { useEffect } from "react";
import { Box, Button, styled } from "@mui/material";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import { generatePath, useHistory } from "react-router-dom";
import ROUTES_PATH from "../../constants/routes";
import { setInvestorDashboardData } from "../../modules/actions";
import { useDispatch } from "react-redux";

export default function FullProfileWidget({ teammateData, toggleOpenFullProfile }) {
	// pass in the teammate
	const history = useHistory();
	const dispatch = useDispatch();
	const buttonClick = (teammateData) => {
		if (teammateData.id) {
			// history.push(ROUTES_PATH.ACCOUNT_PROFILE);
			history.push({
				pathname: generatePath(ROUTES_PATH.ACCOUNT_PROFILE, {
					id: teammateData.id,
				}),
				state: { teammateData: teammateData },
			});
		} else {
			console.error("No id found in teammate data: ", teammateData);
		}
	};

	return (
		<Box
			sx={{ mt: 2 }}
			onClick={
				() => {
					buttonClick(teammateData);
					dispatch(
						setInvestorDashboardData({
							scoutingOpen: false,
						})
					);
				}
				// toggleOpenFullProfile
			}
		>
			<AnimatedBtn
				variant="link"
				sx={{
					color: (theme) => theme.palette.secondary.black,
					fontFamily: '"PoppinsSemiBold", "Helvetica", "Arial", sans-serif',
					fontSize: "18px",
				}}
				startIcon={<ArrowForwardOutlinedIcon fontSize="large" />}
				endIcon={<ArrowForwardOutlinedIcon fontSize="large" />}
				// onClick={
				// 	// toggleOpenFullProfile
				// 	// () => {console.log("toggleOpenFullProfile", toggleOpenFullProfile);}
				// }
			>
				Full Profile
			</AnimatedBtn>
		</Box>
	);
}

const AnimatedBtn = styled(Button)`
	width: 152px;
	white-space: nowrap;
	overflow: hidden;
	transition: padding 0.5s;
	padding-right: 56px;

	&:hover {
		padding-right: 0;
		padding-left: 50px;
		transition: padding 0.5s;
	}
`;
