import React from "react";
import { Box, Typography, Divider, Button } from "@mui/material";
import theme from "../../../../../../../constants/Theme";
import VideoPlayerComponent from "../../../../../../../common/videoPlayer";
import JsxParser from "react-jsx-parser";
import constants from "../../../../../../../constants";

export const Overview = ({ isNextStep, powerupApiDetails }) => {
  const nextStepHandler = () => {
    isNextStep(true);
  };
  return (
    <Box>
      <Box sx={{ mt: 1 }}>
        <Typography variant="body_large" color="secondary.outline">
          <JsxParser
            components={{ Typography }}
            jsx={powerupApiDetails.overview}
          />
        </Typography>
      </Box>
      <Box
        sx={theme => ({
          [theme.breakpoints.down("md")]: {
            mt: 6,
          },
          mt: 4,
        })}
      >
        <Typography
          variant="title_large"
          color="secondary.korophKoal"
          sx={theme => ({ fontWeight: 700 })}
        >
          What you'll learn
        </Typography>
      </Box>
      <Box sx={{ mt: 2 }}>
        <Typography variant="body_large" color="secondary.outline">
          <JsxParser
            components={{ Typography }}
            jsx={powerupApiDetails?.learning}
          />
        </Typography>
      </Box>
      {/*<Box sx={{ my: 4 }}>
        <VideoPlayerComponent
          videoUrl={powerupApiDetails.videoUrl}
          videoThumbnailUrl={powerupApiDetails.videoThumbnailUrl}
        />
      </Box>*/}
    </Box>
  );
};

export default Overview;
