import React from 'react'
import CommonSlideDialog from "../../../../../common/CommonSlideDialog";
import { Box, Typography, Button, useTheme, IconButton } from "@mui/material";
import LoadingButton from '../../../../../common/Loadingbutton';
import CloseIcon from "@mui/icons-material/Close";

export default function LeaveConfirm({ isOpen, onClose, saving, onNoClick, onYesClick }) {
	const theme = useTheme();
	return (
		<CommonSlideDialog
			isOpen={isOpen}
			title={
				<Box sx={{ display: "flex", flexDirection: "column" }}>
					<Typography variant={"Text/md/Semibold"}>Would you like to save your changes?</Typography>
				</Box>
			}
			titleVariant={"Text/md/Semibold"}
			onClose={onClose}
			maxWidth="xs"
			keepMounted
			titleStyle={{ p: 0, pb: 2, alignItems: "center" }}
			contentStyle={{ flex: 1, p: 0, pb: 2 }}
			fullWidth
			PaperProps={{ sx: { borderRadius: "20px", m: 0, p: 4, width: "100%" } }}
			footerStyle={{ pt: 2 }}
			dialogAction={
				<Box flex={1} display="flex" justifyContent={"space-between"} alignItems="center">
					<Button onClick={onClose} variant="DS1" >
						Cancel
					</Button>
					<Box display="flex">
						<Button onClick={onNoClick} variant="noOutlineNew" sx={{ height: "40px" }}>
							No
						</Button>
						<LoadingButton
							loaderColor={theme.palette.primary.main}
							loadingStyles={{
								border: "4px solid",
								backgroundColor: theme.palette.secondary.white,
								borderColor: theme.palette.primary.main,
								height: "40px",
								px: 4,
							}}
							loading={saving}
							onClick={!saving ? onYesClick : () => {}}
							variant="DS1"
							
						>
							Yes
						</LoadingButton>
					</Box>
				</Box>
			}
		>
		</CommonSlideDialog>
	);
}
