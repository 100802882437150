import React from "react";
import { Stepper, Step, StepLabel, Typography, Box, Chip, stepConnectorClasses, StepConnector, IconButton } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { STAGE_STEPPER_LIST } from "../../constants";
import { styled } from "@mui/material/styles";

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
	[`& .${stepConnectorClasses.line}`]: {
		marginTop: "-11px",
		marginBottom: "-11px",
	},
}));

const StageStepper = ({ activeStep = "Pre-Seed" }) => {
	return (
		<Box style={{ maxWidth: 360 }}>
			<Stepper
				connector={<ColorlibConnector />}
				activeStep={activeStep}
				orientation="vertical"
				style={{
					padding: 0,
					"& .MuiStepConnector-line": {
						my: "-4px",
					},
				}}
			>
				{STAGE_STEPPER_LIST.map((stepperItem, index) => (
					<Step key={stepperItem?.label} style={{ padding: 0 }}>
						<StepLabel
							icon={
								<IconButton aria-label="delete" sx={{ width: "25px", height: "25px" }}>
									<FiberManualRecordIcon
										style={{ color: stepperItem?.label === activeStep ? stepperItem?.color : "rgba(234, 236, 240, 1)", fontSize: "16px" }}
									/>
								</IconButton>
							}
							sx={{
								padding: 0,
								"& .MuiStepConnector-line": {
									m: "-8px",
								},
							}}
						>
							<Chip
								sx={{ background: stepperItem?.label === activeStep ? stepperItem?.bgColor : "transparent" }}
								label={
									<Typography
										sx={{ color: stepperItem?.label === activeStep ? stepperItem?.color : "rgba(152, 162, 179, 1)" }}
										variant="Text/xs/Regular"
									>
										{stepperItem?.label}
									</Typography>
								}
							/>
						</StepLabel>
					</Step>
				))}
			</Stepper>
		</Box>
	);
};

export default StageStepper;
