import React, { useState, useEffect, useRef, useMemo, useCallback, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import useDimensions from "react-cool-dimensions";
import { VariableSizeList as List } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import { debounce } from "lodash";
import { Stack, Box, Grid, TextField, Typography, CircularProgress, useMediaQuery, Button, Menu, MenuItem } from "@mui/material";
import {
	GRID_BLACK_ICON_V1,
	GRID_PURPLE_ICON_V1,
	INVESTOR_FILTER_DATA,
	INVESTOR_FILTER_ICON,
	MENU_BLACK_ICON_V1,
	MENU_PURPLE_ICON_V1,
	PORTFOLIO_ICON,
	UPDATED_SEARCH_ICON,
	WATCHLIST_ICON,
	regionStateObj,
} from "../../../../constants";
import { useWindowSize } from "../../../../utils/windowResize";
import FilteredValueCarosualComponent from "../../../../common/FilteredValueCarosual";
import { setInvestorDashboardData } from "../../../../modules/actions/investorDashboardActions";
import {
	addCompanyToWatchlistOrPortfolioApiCall,
	addOrRemoveCompanyToPortfolioOrWatchlist,
	addOrRemoveCompanyToTrackSection,
	getStartupListApiCall,
} from "../Functions/common";
import SortComponent from "./SortComponent";
import GridView from "./GridView";
import ListView from "./ListView";
import { InvestorDashboardContext } from "..";
import DashboardNavabar from "../../../../components/Investor/Dashboard/Navbar";
import OpportunityDashTitle from "../../../../components/Investor/OpportunityDashTitle";
import services from "../../../../services";
import { numberToCommaSeparatedString, replaceCountryWithStates, replaceStateNamesWithCodes } from "../../../../utils/commonFunctions";
import TableController from "../../../../components/Tables/TableController";
import SearchComponent from "../../../../components/Tables/SearchComponent";
import { AllStartupColumnDefs } from "./AllStartupsColumnsDefs";
import NewTableController from "./OpportunityTableController";
import OpportunityTableController from "./OpportunityTableController";
import { setSlidingProfileData } from "../../../../modules/actions/slidingProfileActions";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { AddIconComp } from "./styles";
import AddIcon from "@mui/icons-material/Add";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";
import { USER_TYPES } from "../../../../utils/userTypes";

export default function InvestorStartups({ activeKey }) {
	const { isFilterApplied, setTotalCompanyCount, isSavedSearchApplied, setIsSavedSearchApplied, contextKey, setContextKey } =
		useContext(InvestorDashboardContext);
	const { partiallySelected, FilterDrawerOpen, filteredValue, selectedValues } = useSelector((state) => state?.investorDashboard);
	const investorDashboardData = useSelector((state) => state?.investorDashboard);
	const { isAdmin, isInvestorLocked, userType, partnerCode } = useSelector((state) => state?.auth);

	const size = useWindowSize();
	const dispatch = useDispatch();
	const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

	const [viewType, setViewType] = useState("GRID");
	const [pageData, setPageData] = useState({ isLast: false });
	const [loading, setLoading] = useState(false);
	const [companiesPageIndex, setCompaniesPageIndex] = useState(1);
	const [initialRender, setInitialRender] = useState(true);
	const [searchText, setSearchText] = useState("");
	const [companyFilterObj, setCompanyFilterObj] = useState({});
	const [columnFilterValue, setColumnFilterValue] = useState(INVESTOR_FILTER_DATA);
	const [exportLoading, setExportLoading] = useState(false);
	const [colCount, setColCount] = useState(3);
	const [anchorElActionMenu, setAnchorElActionMenu] = React.useState(null);
	const openActionMenu = Boolean(anchorElActionMenu);

	const [selectAllStatus, setSelectAllStatus] = useState(false);

	const perPage = companiesPageIndex === 1 ? 40 : 10;

	const { observe, unobserve, width, height, entry } = useDimensions({
		// The "currentBreakpoint" will be the object key based on the target's width
		// for instance, 0px - 319px (currentBreakpoint = XS), 320px - 479px (currentBreakpoint = SM) and so on
		breakpoints: { SM: 0, MD: 750, LG: 1100 },
		// Will only update the state on breakpoint changed, default is false
		updateOnBreakpointChange: true,
		onResize: ({ currentBreakpoint }) => {
			if (currentBreakpoint === "LG") {
				setColCount(3);
			} else if (currentBreakpoint === "MD") {
				setColCount(2);
			} else if (currentBreakpoint === "SM") {
				setColCount(1);
			}
		},
	});

	useEffect(() => {
		observe();
	}, []);

	const handleInitialApiCall = () => {
		let tempParamsPayload = handleFilterPayloadFromatting(filteredValue);

		setCompanyFilterObj(tempParamsPayload);
		setCompaniesPageIndex(1);
		getCompanyListApiCall(searchText, 1, "", "", tempParamsPayload);
		setIsSavedSearchApplied(false);
	};

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		});
	}, []);

	useEffect(() => {
		if (!FilterDrawerOpen) {
			handleInitialApiCall();
		}
	}, [FilterDrawerOpen, activeKey]);

	useEffect(() => {
		if (isSavedSearchApplied) {
			handleInitialApiCall();
		}
	}, [isSavedSearchApplied]);

	useEffect(() => {
		setCompaniesPageIndex(1);
	}, [searchText]);

	useEffect(() => {
		let currentValue = columnFilterValue.filter((item) => item.active)[0];
		if (initialRender) {
			setInitialRender(false);
		} else {
			if (companiesPageIndex > 1) {
				getCompanyListApiCall(searchText, companiesPageIndex, currentValue?.sort, currentValue?.filter, companyFilterObj);
			}
		}
	}, [companiesPageIndex]);

	function findStartupsJoinedNumber(arr) {
		let largest = arr[0];
		for (let i = 1; i < arr.length; i++) {
			if (arr[i] > largest) {
				largest = arr[i];
			}
		}
		return largest.split(" ")[0];
	}

	const handleFilterPayloadFromatting = (filteredValue) => {
		let fundingRaisedObj = filteredValue && filteredValue.filter((filterItem) => filterItem?.type === "fundingRaised")[0];
		let askObj = filteredValue.filter((filterItem) => filterItem?.type === "ask")[0];

		let mrrObj = filteredValue.filter((filterItem) => filterItem?.type === "mrr")[0];

		let runwayObj = filteredValue.filter((filterItem) => filterItem?.type === "runway")[0];

		let foundedYearObj = filteredValue?.filter((filterItem) => filterItem?.type === "foundedYear")[0];
		let scoreRangeObj = filteredValue?.filter((filterItem) => filterItem?.type === "scoreRange")[0];

		let stagesObj = filteredValue?.filter((filterItem) => filterItem?.type === "stages");
		let stagesArray = stagesObj.map((item) => item["checkboxValue"]);

		let financingsObj = filteredValue?.filter((filterItem) => filterItem?.type === "financings");
		let financingsArray = financingsObj.map((item) => item["checkboxValue"]);

		let teamMemberObj = filteredValue?.filter((filterItem) => filterItem?.type === "teamMember");
		let teamMemberArray = teamMemberObj.map((item) => item["key"]);

		let industryObj = filteredValue?.filter((filterItem) => filterItem?.type === "industry");
		let industryArray = industryObj.map((item) => item["checkboxValue"]);

		let statesObj = filteredValue?.filter((filterItem) => filterItem?.type === "location");
		let statesArray = statesObj.map((item) => item["checkboxValue"]);

		let replacedCountries = statesArray
			.map((country) => {
				const states = replaceCountryWithStates(country);
				return states?.length > 0 ? states : replaceStateNamesWithCodes(country);
			})
			.flat();

		for (let key in regionStateObj) {
			if (replacedCountries.includes(key)) {
				replacedCountries = replacedCountries?.concat(regionStateObj[key]);
			}
		}

		let trailingRangesObj = filteredValue && filteredValue.filter((filterItem) => filterItem?.type === "foundedYearTrailingRange")[0];

		let productOfferingObj = filteredValue?.filter((filterItem) => filterItem?.type === "productOffering");
		let productOfferingArray = productOfferingObj.map((item) => item["checkboxValue"]);

		let businessModelTypeObj = filteredValue?.filter((filterItem) => filterItem?.type === "businessModel");
		let businessModelTypeArray = businessModelTypeObj.map((item) => item["checkboxValue"]);

		let revenueModelTypeObj = filteredValue?.filter((filterItem) => filterItem?.type === "revenueModel");
		let revenueModelTypeArray = revenueModelTypeObj.map((item) => item["checkboxValue"]);

		let stateOfIncorporationObj = filteredValue?.filter((filterItem) => filterItem?.type === "incorporatedStatus");
		let stateOfIncorporationArray = stateOfIncorporationObj.map((item) => item["checkboxValue"]);

		let pitchDeckAvailableObj = filteredValue?.filter((filterItem) => filterItem?.type === "pitchDeckAvailable");
		let pitchDeckAvailableArray = pitchDeckAvailableObj.map((item) => item["checkboxValue"]);

		let isPremiumPlanAccessObj = filteredValue?.filter((filterItem) => filterItem?.type === "isPremiumPlanAccess");
		let isPremiumPlanAccessArray = isPremiumPlanAccessObj.map((item) => item["checkboxValue"]);

		let cohortsObj = filteredValue?.filter((filterItem) => filterItem?.type === "cohorts");
		let cohortIds = cohortsObj.map((item) => item["cohortIds"]);

		let startupsJoinedObj = filteredValue?.filter((filterItem) => filterItem?.type === "startupsJoined");
		let startupsJoinedArray = startupsJoinedObj.map((item) => item["checkboxValue"]);
		let finalStartupsJoinedValue = [];
		if (startupsJoinedArray?.length > 0) {
			finalStartupsJoinedValue = findStartupsJoinedNumber(startupsJoinedArray);
		}

		const searchParams = new URLSearchParams();
		stagesArray.forEach((item) => {
			searchParams.append("itemIds", item);
		});
		return {
			fundingRaisedMin: fundingRaisedObj?.fundingRaisedMin,
			fundingRaisedMax: fundingRaisedObj?.fundingRaisedMax === 3000000 ? null : fundingRaisedObj?.fundingRaisedMax,
			foundedStartYear: foundedYearObj?.foundedStartYear || trailingRangesObj?.foundedStartYear,
			foundedEndYear: foundedYearObj?.foundedEndYear || trailingRangesObj?.foundedEndYear,
			stages: stagesArray.join(),
			financings: financingsArray.join(),
			teamMemberTags: teamMemberArray.join(),
			industries: industryArray.join(),
			states: replacedCountries.join(),
			foundedYearTrailingRange: trailingRangesObj?.checkboxValue,
			productOffering: productOfferingArray.join(),
			businessModel: businessModelTypeArray.join(),
			revenueModel: revenueModelTypeArray.join(),
			incorporatedStatus: stateOfIncorporationArray.join(),
			pitchDeckAvailable: pitchDeckAvailableArray.join(),
			isPremiumPlanAccess: isPremiumPlanAccessArray.join(),
			askMin: askObj?.askMin,
			askMax: askObj?.askMax === 15000000 ? null : askObj?.askMax,
			cohorts: cohortIds.join(),
			startupsJoined: finalStartupsJoinedValue,
			minScore: scoreRangeObj?.minScore,
			maxScore: scoreRangeObj?.maxScore,
			minMrr: mrrObj?.minMrr,
			maxMrr: mrrObj?.maxMrr === 1000000 ? null : mrrObj?.maxMrr,
			minRunway: runwayObj?.minRunway,
			maxRunway: runwayObj?.maxRunway === 36 ? null : runwayObj?.maxRunway,
		};
	};

	const DownloadStartupListApiCall = (searchText, companiesPageIndex, sort = "DESC", filter = "currentScore", companyFilterObj) => {
		const activeFilterData = investorDashboardData?.sortFilteredValues?.filter((item) => item.active)?.[0];
		const defaultSort = sort ? sort : "DESC";
		const defaultFilter = filter ? filter : "currentScore";
		setExportLoading(true);
		services
			.downloadCompanyList(
				searchText,
				activeFilterData?.sort ? activeFilterData?.sort : defaultSort,
				activeFilterData?.filter ? activeFilterData?.filter : defaultFilter,
				activeKey === 2 ? "PORTFOLIO" : activeKey === 3 ? "WATCHLIST" : null,
				companyFilterObj?.fundingRaisedMin,
				companyFilterObj?.fundingRaisedMax,
				companyFilterObj?.foundedStartYear,
				companyFilterObj?.foundedEndYear,
				companyFilterObj?.stages,
				companyFilterObj?.financings,
				companyFilterObj?.teamMemberTags,
				companyFilterObj?.industries,
				companyFilterObj?.states,
				companyFilterObj?.foundedYearTrailingRange,
				companyFilterObj?.productOffering,
				companyFilterObj?.businessModel,
				companyFilterObj?.revenueModel,
				companyFilterObj?.incorporatedStatus,
				companyFilterObj?.pitchDeckAvailable,
				companyFilterObj?.isPremiumPlanAccess,
				companyFilterObj?.askMin,
				companyFilterObj?.askMax,
				companyFilterObj?.cohorts,
				companyFilterObj?.startupsJoined,
				companyFilterObj?.minScore,
				companyFilterObj?.maxScore,
				companyFilterObj?.minMrr,
				companyFilterObj?.maxMrr,
				companyFilterObj?.minRunway,
				companyFilterObj?.maxRunway
			)
			.then((result) => {
				setExportLoading(false);
				const blob = new Blob([result?.data], { type: "text/csv" });
				const link = document.createElement("a");
				link.href = window.URL.createObjectURL(blob);
				link.download = "startup-list.csv";
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
				setExportLoading(false);
			})
			.catch((error) => {
				setExportLoading(false);

				console.log(error);
			});
	};

	const getCompanyListApiCall = (searchText, companiesPageIndex, sort = "DESC", filter = "currentScore", companyFilterObj) => {
		if (companiesPageIndex === 1) {
			dispatch(setInvestorDashboardData({ startupList: [] }));
			setLoading(true);
		}
		const activeFilterData = investorDashboardData?.sortFilteredValues?.filter((item) => item.active)?.[0];
		const defaultSort = sort ? sort : "DESC";
		const defaultFilter = filter ? filter : "currentScore";
		setColumnFilterValue(investorDashboardData?.sortFilteredValues ? investorDashboardData?.sortFilteredValues : columnFilterValue);
		getStartupListApiCall(
			companiesPageIndex,
			perPage,
			searchText,
			activeFilterData?.sort ? activeFilterData?.sort : defaultSort,
			activeFilterData?.filter ? activeFilterData?.filter : defaultFilter,
			activeKey === 2 ? "PORTFOLIO" : activeKey === 3 ? "WATCHLIST" : null,
			companyFilterObj?.fundingRaisedMin,
			companyFilterObj?.fundingRaisedMax,
			companyFilterObj?.foundedStartYear,
			companyFilterObj?.foundedEndYear,
			companyFilterObj?.stages,
			companyFilterObj?.financings,
			companyFilterObj?.teamMemberTags,
			companyFilterObj?.industries,
			companyFilterObj?.states,
			companyFilterObj?.foundedYearTrailingRange,
			companyFilterObj?.productOffering,
			companyFilterObj?.businessModel,
			companyFilterObj?.revenueModel,
			companyFilterObj?.incorporatedStatus,
			companyFilterObj?.pitchDeckAvailable,
			companyFilterObj?.isPremiumPlanAccess,
			companyFilterObj?.askMin,
			companyFilterObj?.askMax,
			companyFilterObj?.cohorts,
			companyFilterObj?.startupsJoined,
			companyFilterObj?.minScore,
			companyFilterObj?.maxScore,
			companyFilterObj?.minMrr,
			companyFilterObj?.maxMrr,
			companyFilterObj?.minRunway,
			companyFilterObj?.maxRunway
		)
			.then((result) => {
				const data = result?.data?.data;

				setLoading(false);

				/*
					Need to offset the calculated total elements to compensate for recommended companies.
					This is a stop gap solution to avoid a total refactor of various components.
				*/
				const boltRecommendedCount = data?.models?.filter((model) => model.boltRecommended === true).length;
				// Correct the totalElements count by adding the count of boltRecommended objects
				const correctedTotalElements = data?.totalElements + boltRecommendedCount;

				setPageData({
					totalPages: data?.totalPages || 0,
					totalElements: correctedTotalElements || 0,
					isFirst: data?.isFirst,
					isLast: data?.isLast,
					overAllTotalElements: data?.overAllTotalElements,
				});

				const items = data?.models || [];
				items.map((companyItem) => {
					companyItem.checked = false;
					return companyItem;
				});
				setTotalCompanyCount(correctedTotalElements || 0);
				if (!data.isFirst) {
					let tempList = investorDashboardData?.startupList;
					dispatch(setInvestorDashboardData({ startupList: [...tempList, ...items] }));
				} else {
					dispatch(setInvestorDashboardData({ startupList: items }));
				}
			})
			.catch((error) => {
				setLoading(false);
				console.log(error);
			});
	};

	const getNext = () => {
		if (!pageData?.isLast) {
			if (companiesPageIndex === 1) {
				setCompaniesPageIndex(5);
			} else {
				setCompaniesPageIndex(companiesPageIndex + 1);
			}
		}
	};

	const debounceCompanyListApiCall = debounce(
		(checkedValue) => {
			const templist = [...filteredValue];
			const data = selectedValues.filter((item) => item.stateName === checkedValue);
			const currentSelectedValue = selectedValues.filter((value) => value.cName === data[0]?.cName);
			let finalFilteredList = templist.filter((item) => item?.checkboxValue !== checkedValue);
			let selectedValuesList = selectedValues.filter((item) => item.stateName !== checkedValue);
			let tempPartiallySelected = partiallySelected;
			tempPartiallySelected.map((partialItem) => {
				if (currentSelectedValue.length > 1) {
					if (partialItem.type === data[0]?.cName) {
						partialItem.value = true;
					}
				} else {
					if (partialItem.type === data[0]?.cName) {
						partialItem.value = false;
					}
				}
			});
			dispatch(
				setInvestorDashboardData({
					filteredValue: finalFilteredList,
					selectedValues: selectedValuesList,
					partiallySelected: [...tempPartiallySelected],
				})
			);
			let tempParamsPayload = handleFilterPayloadFromatting(finalFilteredList);
			setCompanyFilterObj(tempParamsPayload);
			setCompaniesPageIndex(1);
			getCompanyListApiCall(searchText, 1, "", "", tempParamsPayload);
		},
		600,
		{
			maxWait: 1000,
		}
	);

	const debounceChange = debounce(
		(e) => {
			console.log(`e`, e?.target.value);
			getCompanyListApiCall(e?.target?.value, 1, "", "", companyFilterObj);
			setSearchText(e?.target?.value);
		},
		600,
		{
			maxWait: 1000,
		}
	);

	let listRef = useRef({});
	const rowHeights = useRef({});

	const setRowHeight = (index, size) => {
		listRef?.current?.resetAfterIndex?.(0);
		rowHeights.current = { ...rowHeights.current, [index]: size };
	};

	const getRowHeight = useCallback((index) => {
		return rowHeights.current[index] + 32 || 273;
	}, []);

	const Row = ({ index, style }) => {
		const callBackRef = useCallback((node) => {
			if (node) {
				setRowHeight(index, node.clientHeight);
			}
		}, []);

		return (
			<Box key={`row-${index}`} style={style}>
				<Box ref={callBackRef}>
					<GridView
						colCount={colCount}
						startup={investorDashboardData?.startupList[index]}
						companiesPageIndex={companiesPageIndex}
						setCompaniesPageIndex={setCompaniesPageIndex}
					/>
				</Box>
			</Box>
		);
	};

	const listHeight = useMemo(() => {
		if (size.width > 1200) {
			return size.height - 320;
		} else if (size.width > 1045) {
			return size.height - 380;
		} else if (size.width > 980) {
			return size.height - 410;
		} else if (size.width > 900) {
			return size.height - 340;
		} else if (size.width > 600) {
			return size.height - 470;
		} else {
			return size.height - 550 > 900 ? size.height - 550 : 900;
		}
	}, [size]);

	const renderVirtualList = (onItemsRendered, ref) => {
		return (
			<List
				className="SOSShowVerticalScroll"
				height={listHeight}
				itemCount={pageData?.totalElements}
				itemSize={getRowHeight}
				onItemsRendered={onItemsRendered}
				ref={listRef}
				width="100%"
			>
				{({ index, style }) => <Row key={`row-${index}`} index={index} style={style} />}
			</List>
		);
	};

	const isItemLoaded = (index) => !!investorDashboardData?.startupList[index];

	const loadMoreItems = (startIndex, stopIndex) => {
		getNext();
	};

	const getCompaniesData = async (page, perPage, sort = "DESC", filter = "currentScore") => {
		const activeFilterData = investorDashboardData?.sortFilteredValues?.filter((item) => item.active)?.[0];
		const defaultSort = sort ? sort : "DESC";
		const defaultFilter = filter ? filter : "currentScore";

		setColumnFilterValue(investorDashboardData?.sortFilteredValues ? investorDashboardData?.sortFilteredValues : columnFilterValue);

		try {
			const result = await getStartupListApiCall(
				page + 1,
				perPage,
				searchText,
				activeFilterData?.sort ? activeFilterData?.sort : defaultSort,
				activeFilterData?.filter ? activeFilterData?.filter : defaultFilter,
				activeKey === 2 ? "PORTFOLIO" : activeKey === 3 ? "WATCHLIST" : null,
				companyFilterObj?.fundingRaisedMin,
				companyFilterObj?.fundingRaisedMax,
				companyFilterObj?.foundedStartYear,
				companyFilterObj?.foundedEndYear,
				companyFilterObj?.stages,
				companyFilterObj?.financings,
				companyFilterObj?.teamMemberTags,
				companyFilterObj?.industries,
				companyFilterObj?.states,
				companyFilterObj?.foundedYearTrailingRange,
				companyFilterObj?.productOffering,
				companyFilterObj?.businessModel,
				companyFilterObj?.revenueModel,
				companyFilterObj?.incorporatedStatus,
				companyFilterObj?.pitchDeckAvailable,
				companyFilterObj?.isPremiumPlanAccess,
				companyFilterObj?.askMin,
				companyFilterObj?.askMax,
				companyFilterObj?.cohorts,
				companyFilterObj?.startupsJoined,
				companyFilterObj?.minScore,
				companyFilterObj?.maxScore,
				companyFilterObj?.minMrr,
				companyFilterObj?.maxMrr,
				companyFilterObj?.minRunway,
				companyFilterObj?.maxRunway
			);
			const data = result?.data?.data;

			const items = data?.models || [];

			items.map((companyItem) => {
				companyItem.checked = false;
				return companyItem;
			});

			/*
				Need to offset the calculated total elements to compensate for recommended companies.
				This is a stop gap solution to avoid a total refactor of various components.
			*/
			const boltRecommendedCount = items.filter((model) => model.boltRecommended === true).length;
			// Correct the totalElements count by adding the count of boltRecommended objects
			const correctedTotalElements = data?.totalElements + boltRecommendedCount;

			setTotalCompanyCount(correctedTotalElements || 0);

			if (!data.isFirst) {
				let tempList = investorDashboardData?.startupList;
				dispatch(setInvestorDashboardData({ startupList: [...tempList, ...items] }));
			} else {
				dispatch(setInvestorDashboardData({ startupList: items }));
			}

			return data;
		} catch (error) {
			console.log(`Error occured`, error);
		}
	};

	const onCellClick = (params, event, details) => {
		const { field } = params;

		if (field === "currentScore") {
			(!isInvestorLocked || isAdmin) &&
				dispatch(
					setInvestorDashboardData({
						benchmarkDetailsOpen: true,
						openStartupObj: params.row,
					})
				);
		} else if (field === "portfolio") {
			if (!isInvestorLocked || isAdmin) {
				handlePortfolioAndWatchlistDrawerActions("PORTFOLIO", params.row);
			}
		} else if (field === "watchlist") {
			if (!isInvestorLocked || isAdmin) {
				handlePortfolioAndWatchlistDrawerActions("WATCHLIST", params.row);
			}
		} else if (field === "companyName") {
			dispatch(
				setSlidingProfileData({
					open: true,
					profileType: "STARTUP_PROFILE",
					profileId: params.row?.id,
					profileObj: params.row,
				})
			);
		} else if (field === "checkbox") {
			handleCheckBoxClick(event, params.row.id);
		} else if (field === "track") {
			handleTrackDrawerActions("TRACK", params.row);
		}
	};

	const addCompanyTrackApiCall = (companyId, startup) => {
		addOrRemoveCompanyToTrackSection({ trackedCompanyIds: [companyId], isRemove: false })
			.then((result) => {
				const tempStartupList = investorDashboardData?.startupList;
				tempStartupList.map((startupItem) => {
					if (startupItem.id === startup.id) startupItem.inTracked = true;
				});
				dispatch(setInvestorDashboardData({ startupList: [...tempStartupList] }));
			})
			.catch((err) => console.log(err));
	};
	const handleTrackDrawerActions = async (type, startup) => {
		if (type === "TRACK") {
			if (!startup?.inTracked) {
				addCompanyTrackApiCall(startup?.id, startup);
			} else {
				let trackObj = {
					title: "Remove from Track",
					text: startup?.inTracked ? "Removal Confirmation" : "Confirmation",
					subText: startup?.inTracked
						? "Please confirm that you are removing this startup from Track Section."
						: "Please confirm that you would like to add it to you Track.",
					primaryButtonText: startup?.inTracked ? "Remove" : "Add",
					secondaryButtonText: "Cancel",
					type: type,
				};
				dispatch(
					setInvestorDashboardData({
						slidingConfirmationObject: trackObj,
						openStartupObj: startup,
						removeMyPortfolioOpen: startup?.inTracked,
						addMyPortfolioOpen: !startup?.inTracked,
					})
				);
			}
		} else if (type === "BULK_ADD_TRACK" || type === "BULK_REMOVE_TRACK") {
			let trackObj = {
				title: "My Tracks",
				text: type === "BULK_REMOVE_TRACK" ? "Removal Confirmation" : "Confirmation",
				subText:
					type === "BULK_REMOVE_TRACK"
						? "Please confirm that you are removing this startup from Track Section."
						: "Please confirm that you would like to add it to your Track Section.",
				primaryButtonText: type === "BULK_REMOVE_TRACK" ? "Remove" : "Add",
				secondaryButtonText: "Cancel",
				type: type,
			};
			dispatch(
				setInvestorDashboardData({
					slidingConfirmationObject: trackObj,
					openStartupObj: startup,
					removeWatchlistOpen: type === "BULK_REMOVE_TRACK",
					addWatchlistOpen: type === "BULK_ADD_TRACK",
				})
			);
		}
	};
	const handlePortfolioAndWatchlistDrawerActions = async (type, startup) => {
		if (type === "PORTFOLIO") {
			if (!startup?.inPortfolio) {
				await addCompanyToWatchlistOrPortfolioApiCall(startup, startup?.id, "PORTFOLIO");
			} else {
				let portfolioObj = {
					title: "My Portfolio",
					text: startup.inPortfolio ? "Removal Confirmation" : "Confirmation",
					subText: startup.inPortfolio
						? "Please confirm that you are removing this startup from your portfolio."
						: "Please confirm that you have invested in this startup, and would like to add it to you portfolio.",
					primaryButtonText: startup.inPortfolio ? "Remove" : "Add",
					secondaryButtonText: "Cancel",
					type: type,
				};
				dispatch(
					setInvestorDashboardData({
						slidingConfirmationObject: portfolioObj,
						openStartupObj: startup,
						removeMyPortfolioOpen: startup.inPortfolio,
						addMyPortfolioOpen: !startup.inPortfolio,
					})
				);
			}
		} else if (type === "WATCHLIST") {
			if (!startup?.inWatchlist) {
				await addCompanyToWatchlistOrPortfolioApiCall(startup, startup?.id, "WATCHLIST");
			} else {
				let watchlistObj = {
					title: "My Watchlist",
					text: startup.inWatchlist ? "Removal Confirmation" : "Confirmation",
					subText: startup.inWatchlist
						? "Please confirm that you are removing this startup from your watchlist."
						: "Please confirm that you have invested in this startup, and would like to add it to you watchlist.",
					primaryButtonText: startup.inWatchlist ? "Remove" : "Add",
					secondaryButtonText: "Cancel",
					type: type,
				};
				dispatch(
					setInvestorDashboardData({
						slidingConfirmationObject: watchlistObj,
						openStartupObj: startup,
						removeWatchlistOpen: startup.inWatchlist,
						addWatchlistOpen: !startup.inWatchlist,
					})
				);
			}
		} else if (type === "BULK_ADD_WATCHLIST" || type === "BULK_REMOVE_WATCHLIST") {
			let watchlistObj = {
				title: "My Watchlist",
				text: type === "BULK_REMOVE_WATCHLIST" ? "Removal Confirmation" : "Confirmation",
				subText:
					type === "BULK_REMOVE_WATCHLIST"
						? "Please confirm that you are removing this startup from your watchlist."
						: "Please confirm that you have invested in this startup, and would like to add it to you watchlist.",
				primaryButtonText: type === "BULK_REMOVE_WATCHLIST" ? "Remove" : "Add",
				secondaryButtonText: "Cancel",
				type: type,
			};
			dispatch(
				setInvestorDashboardData({
					slidingConfirmationObject: watchlistObj,
					openStartupObj: startup,
					removeWatchlistOpen: type === "BULK_REMOVE_WATCHLIST",
					addWatchlistOpen: type === "BULK_ADD_WATCHLIST",
				})
			);
		} else if (type === "BULK_ADD_PORTFOLIO" || type === "BULK_REMOVE_PORTFOLIO") {
			let portfolioObj = {
				title: "My Portfolio",
				text: type === "BULK_REMOVE_PORTFOLIO" ? "Removal Confirmation" : "Confirmation",
				subText:
					type === "BULK_REMOVE_PORTFOLIO"
						? "Please confirm that you are removing this startup from your portfolio."
						: "Please confirm that you have invested in this startup, and would like to add it to you portfolio.",
				primaryButtonText: type === "BULK_REMOVE_PORTFOLIO" ? "Remove" : "Add",
				secondaryButtonText: "Cancel",
				type: type,
			};

			dispatch(
				setInvestorDashboardData({
					slidingConfirmationObject: portfolioObj,
					openStartupObj: startup,
					removeMyPortfolioOpen: type === "BULK_REMOVE_PORTFOLIO",
					addMyPortfolioOpen: type === "BULK_ADD_PORTFOLIO",
				})
			);
		}
	};

	const addCompanyToWatchlistOrPortfolioApiCall = async (startup, companyId, type) => {
		addOrRemoveCompanyToPortfolioOrWatchlist({ entityIds: [companyId], collectionType: type, isRemove: false })
			.then((result) => {
				const tempStartupList = investorDashboardData?.startupList;
				tempStartupList.map((startupItem) => {
					if (startupItem.id === startup.id) {
						if (type === "PORTFOLIO") {
							startupItem.inPortfolio = true;
						} else {
							startupItem.inWatchlist = true;
						}
					}
				});

				dispatch(setInvestorDashboardData({ startupList: [...tempStartupList] }));
			})
			.catch((err) => console.log(err));
	};

	const handleActionMenuClick = (event) => {
		setAnchorElActionMenu(event.currentTarget);
	};

	const handleCloseActionMenu = () => {
		setAnchorElActionMenu(null);
	};

	const BulkActionMenu = () => {
		let isAddedtoWatchlist = investorDashboardData?.startupList.some((item) => !item.inWatchlist && item.checked);
		let isAddedtoPortfolio = investorDashboardData?.startupList.some((item) => !item.inPortfolio && item.checked);
		let isRemovedFromWatchlist = investorDashboardData?.startupList.some((item) => item.inWatchlist && item.checked);
		let isRemovedFromPortfolio = investorDashboardData?.startupList.some((item) => item.inPortfolio && item.checked);
		let isAddedtoTrack = investorDashboardData?.startupList.some((item) => !item.inTracked && item.checked);
		let isRemovedFromTrack = investorDashboardData?.startupList.some((item) => item.inTracked && item.checked);
		let hasDifferentPartnerCode = investorDashboardData?.startupList.some((item) => item.checked && item.partnerCode !== partnerCode);
		return (
			<Menu
				id="basic-menu"
				anchorEl={anchorElActionMenu}
				open={openActionMenu}
				onClose={handleCloseActionMenu}
				MenuListProps={{
					"aria-labelledby": "basic-button",
				}}
				PaperProps={{
					sx: {
						boxShadow: "0px 10px 25px rgba(0, 0, 0, 0.05)",
						borderRadius: "30px",
						p: 2,
					},
				}}
			>
				<MenuItem
					onClick={() => {
						if (isAddedtoWatchlist) {
							handlePortfolioAndWatchlistDrawerActions("BULK_ADD_WATCHLIST", "");
							handleCloseActionMenu();
						}
					}}
					sx={{ mt: 2, cursor: isAddedtoWatchlist ? "pointer" : "default" }}
				>
					<Box
						display={"flex"}
						alignItems="center"
						gap={2}
						sx={{
							position: "relative",
							opacity: isAddedtoWatchlist ? "1" : "0.3",
							cursor: isAddedtoWatchlist ? "pointer" : "default",
						}}
					>
						<Box component={"img"} sx={{ height: "26px", width: "26px" }} src={WATCHLIST_ICON} alt="portfolio" />
						<AddIconComp className="hovered" isActive={isAddedtoWatchlist}>
							<AddIcon className="add-icon-hover" fontSize="12px" />
						</AddIconComp>
						<Typography variant="title_portfolio">Add to Watchlist</Typography>
					</Box>
				</MenuItem>
				<MenuItem
					sx={{ mt: 2, cursor: isRemovedFromWatchlist ? "pointer" : "default" }}
					onClick={() => {
						if (isRemovedFromWatchlist) {
							handlePortfolioAndWatchlistDrawerActions("BULK_REMOVE_WATCHLIST", "");
							handleCloseActionMenu();
						}
					}}
				>
					{" "}
					<Box
						display={"flex"}
						alignItems="center"
						gap={2}
						sx={{
							position: "relative",
							opacity: isRemovedFromWatchlist ? "1" : "0.3",
							cursor: isRemovedFromWatchlist ? "pointer" : "default",
						}}
					>
						<Box component={"img"} sx={{ height: "26px", width: "26px" }} src={WATCHLIST_ICON} alt="portfolio" />
						<AddIconComp className="hovered" isActive={isRemovedFromWatchlist}>
							<RemoveRoundedIcon className="add-icon-hover" fontSize="12px" />
						</AddIconComp>
						<Typography variant="title_portfolio">Remove From Watchlist</Typography>
					</Box>
				</MenuItem>
				<MenuItem
					sx={{ mt: 2, cursor: isAddedtoPortfolio ? "pointer" : "default" }}
					onClick={() => {
						if (isAddedtoPortfolio) {
							handlePortfolioAndWatchlistDrawerActions("BULK_ADD_PORTFOLIO", "");
							handleCloseActionMenu();
						}
					}}
				>
					<Box
						display={"flex"}
						alignItems="center"
						gap={2}
						sx={{
							position: "relative",
							opacity: isAddedtoPortfolio ? "1" : "0.3",
							cursor: isAddedtoPortfolio ? "pointer" : "default",
						}}
					>
						<Box component={"img"} sx={{ height: "26px", width: "26px" }} src={PORTFOLIO_ICON} alt="portfolio" />
						<AddIconComp className="hovered" isActive={isAddedtoPortfolio}>
							<AddIcon className="add-icon-hover" fontSize="12px" />
						</AddIconComp>
						<Typography variant="title_portfolio">Add to Portfolio</Typography>
					</Box>
				</MenuItem>
				<MenuItem
					sx={{ mt: 2, cursor: isRemovedFromPortfolio ? "pointer" : "default" }}
					onClick={() => {
						if (isRemovedFromPortfolio) {
							handlePortfolioAndWatchlistDrawerActions("BULK_REMOVE_PORTFOLIO", "");
							handleCloseActionMenu();
						}
					}}
				>
					<Box
						display={"flex"}
						alignItems="center"
						gap={2}
						sx={{
							position: "relative",
							opacity: isRemovedFromPortfolio ? "1" : "0.3",
							cursor: isRemovedFromPortfolio ? "pointer" : "default",
						}}
					>
						<Box component={"img"} sx={{ height: "26px", width: "26px" }} src={PORTFOLIO_ICON} alt="portfolio" />
						<AddIconComp className="hovered" isActive={isRemovedFromPortfolio}>
							<RemoveRoundedIcon className="add-icon-hover" fontSize="12px" />
						</AddIconComp>
						<Typography variant="title_portfolio">Remove From Portfolio</Typography>
					</Box>
				</MenuItem>

				{(isAdmin || userType === USER_TYPES.PARTNER_USER) && hasDifferentPartnerCode && (
					<>
						<MenuItem
							onClick={() => {
								if (isAddedtoTrack) {
									handleTrackDrawerActions("BULK_ADD_TRACK", "");
									handleCloseActionMenu();
								}
							}}
							sx={{ mt: 2, cursor: isAddedtoTrack ? "pointer" : "default" }}
						>
							<Box
								display={"flex"}
								alignItems="center"
								gap={2}
								sx={{
									position: "relative",
									opacity: isAddedtoTrack ? "1" : "0.3",
									cursor: isAddedtoTrack ? "pointer" : "default",
								}}
							>
								{/* <Box component={"img"} sx={{ height: "26px", width: "26px" }} src={WATCHLIST_ICON} alt="portfolio" /> */}
								<GpsFixedIcon sx={{ position: "relative", height: "25px", width: "25px", color: (theme) => theme.palette.grey }} />
								<AddIconComp className="hovered" isActive={isAddedtoTrack}>
									<AddIcon className="add-icon-hover" fontSize="12px" />
								</AddIconComp>
								<Typography variant="title_portfolio">Add to Track</Typography>
							</Box>
						</MenuItem>
						<MenuItem
							sx={{ mt: 2, cursor: isRemovedFromTrack ? "pointer" : "default" }}
							onClick={() => {
								if (isRemovedFromTrack) {
									handleTrackDrawerActions("BULK_REMOVE_TRACK", "");
									handleCloseActionMenu();
								}
							}}
						>
							{" "}
							<Box
								display={"flex"}
								alignItems="center"
								gap={2}
								sx={{
									position: "relative",
									opacity: isRemovedFromTrack ? "1" : "0.3",
									cursor: isRemovedFromTrack ? "pointer" : "default",
								}}
							>
								<GpsFixedIcon sx={{ position: "relative", height: "25px", width: "25px", color: (theme) => theme.palette.grey }} />
								<AddIconComp className="hovered" isActive={isRemovedFromTrack}>
									<RemoveRoundedIcon className="add-icon-hover" fontSize="12px" />
								</AddIconComp>
								<Typography variant="title_portfolio">Remove From Track</Typography>
							</Box>
						</MenuItem>
					</>
				)}
			</Menu>
		);
	};

	const handleSelectAll = (e) => {
		let checkedStatus = e.target.checked;
		const tempStartupList = investorDashboardData?.startupList;

		tempStartupList.map((startupItem) => {
			startupItem.checked = checkedStatus;
		});

		setSelectAllStatus(checkedStatus);
		dispatch(setInvestorDashboardData({ startupList: [...tempStartupList] }));
	};

	const handleCheckBoxClick = (e, startupId) => {
		let checkStatus = e.target.checked;
		const tempStartupList = investorDashboardData?.startupList;

		tempStartupList.map((startupItem, startupIdx) => {
			if (startupItem.id === startupId) {
				startupItem.checked = checkStatus;
			}
		});

		if (!tempStartupList.some((item) => item.checked)) {
			setSelectAllStatus(false);
		}
		if (!tempStartupList.some((item) => !item.checked)) {
			setSelectAllStatus(true);
		}
		dispatch(setInvestorDashboardData({ startupList: [...tempStartupList] }));
	};
	return (
		<Stack
			sx={(theme) => ({
				width: 1,
				borderRadius: theme.shape.standard.borderRadius,

				[theme.breakpoints.down("md")]: {
					borderRadius: 0,
				},
			})}
		>
			<Box
				sx={(theme) => ({
					maxWidth: "2000px",
					margin: "auto",
					width: 1,
				})}
			>
				<Box gap={2} sx={{ mb: 4 }} display={"flex"} flexDirection={isSmallScreen ? "column" : "row"} alignItems={"center"}>
					{" "}
					<OpportunityDashTitle />
					{isAdmin && (
						<Button
							startIcon={exportLoading && <CircularProgress size={"1.25rem"} sx={{ color: "inherit" }} />}
							variant="DS1"
							onClick={() => {
								let currentValue = columnFilterValue.filter((item) => item.active)[0];
								DownloadStartupListApiCall(searchText, companiesPageIndex, currentValue?.sort, currentValue?.filter, companyFilterObj);
							}}
						>
							{" "}
							Download Startups
						</Button>
					)}
				</Box>

				<Box
					display={"flex"}
					flexDirection={isSmallScreen ? "column" : "row"}
					sx={{ pl: { lg: 0, md: 0, xs: 2 } }}
					flexWrap={"wrap"}
					gap={2}
					alignItems={"center"}
				>
					<Box flexGrow={0}>
						<DashboardNavabar activeKey={activeKey} />
					</Box>
					<Box flexGrow={0}>
						<TextField
							spellCheck="true"
							onChange={debounceChange}
							InputProps={{
								endAdornment: (
									<Box sx={{ display: "flex", alignItems: "center" }}>
										<Box component={"img"} src={UPDATED_SEARCH_ICON} sx={{ height: "18px", width: "18px" }} alt="search" />
									</Box>
								),
							}}
							fullWidth
							placeholder={"Search"}
							sx={{
								"& .MuiInputBase-root": {
									height: "auto",
									boxShadow: "0px 3px 11px rgba(0, 0, 0, 0.05)",
									borderRadius: "40px",
									fontFamily: (theme) => theme.typography["Text/sm/Regular"],
									minWidth: "270px",
									background: "white",
									"&:hover": {
										borderColor: "red",
									},
								},
								"& .MuiOutlinedInput-notchedOutline": {
									border: "none",
								},
								input: {
									"&, &::placeholder": (theme) => ({
										fontFamily: (theme) => theme.typography["Text/sm/Regular"],

										color: (theme) => theme.palette.secondary.black,
										opacity: 1,
									}),
									"&": {
										pl: 3.25,
									},
								},
							}}
						/>
					</Box>
					<Box flexGrow={0}>
						<SortComponent
							columnFilterValue={columnFilterValue}
							setColumnFilterValue={setColumnFilterValue}
							setCompaniesPageIndex={setCompaniesPageIndex}
							isFilterApplied={isFilterApplied}
							searchText={searchText}
							getCompanyListApiCall={getCompanyListApiCall}
							companyFilterObj={companyFilterObj}
							defaultSearchStr={investorDashboardData?.sortFilter ? investorDashboardData?.sortFilter : "Score"}
						/>
					</Box>
					<Box flexGrow={1} gap={1} sx={{ display: "flex", alignItems: "center", justifyContent: "right", pr: 2 }}>
						<Box
							component={"img"}
							sx={{ cursor: "pointer", height: "24px", width: "24px" }}
							src={viewType === "GRID" ? GRID_PURPLE_ICON_V1 : GRID_BLACK_ICON_V1}
							alt="grid"
							onClick={() => setViewType("GRID")}
						/>
						<Box
							component={"img"}
							sx={{ cursor: "pointer", height: "24px", width: "24px" }}
							src={viewType === "LIST" ? MENU_PURPLE_ICON_V1 : MENU_BLACK_ICON_V1}
							onClick={() => setViewType("LIST")}
							alt="list"
						/>
					</Box>
				</Box>

				<Box sx={{ display: "flex", justifyContent: "end", pr: 2 }}>
					<Typography variant="Text/sm/Regular" sx={{ color: "rgba(102, 112, 133, 1)" }}>{`${
						investorDashboardData?.startupList?.length > 0 ? 1 : 0
					} - ${
						investorDashboardData?.startupList?.length < 10
							? investorDashboardData?.startupList?.length
							: numberToCommaSeparatedString(investorDashboardData?.startupList.length)
					} of ${numberToCommaSeparatedString(pageData?.totalElements)} (${
						(pageData?.totalElements / pageData?.overAllTotalElements) * 100 < 1
							? ((pageData?.totalElements / pageData?.overAllTotalElements) * 100).toFixed(2)
							: Math.floor((pageData?.totalElements / pageData?.overAllTotalElements) * 100) || 0
					}% of all startups) `}</Typography>
				</Box>

				<Grid container spacing={2} sx={{ px: 0, mt: 1 }}>
					<Grid container sx={{ px: 2, pt: 1, pb: 2 }}>
						<Grid item md={10} lg={10}>
							<Box>
								{filteredValue?.length > 0 && (
									<Box>
										<Box display={"flex"} alignItems="center">
											<Typography sx={{ borderRight: "1px solid #AAAAAA", pr: 1 }} variant="testHeading">
												Search Results
											</Typography>
											<Box
												sx={{
													p: 2,
													display: "flex",
													gap: 2,
												}}
											>
												<Box
													sx={{
														display: "flex",
														cursor: "pointer",
														alignItems: "center",
														gap: 1,
													}}
												>
													<Box component={"img"} src={INVESTOR_FILTER_ICON} alt="filter" />
													<Typography
														variant="custom070"
														onClick={() => {
															dispatch(
																setInvestorDashboardData({
																	FilterDrawerOpen: true,
																	savedFilterEditData: { isEdit: false, name: null },
																})
															);
														}}
													>
														View filters
													</Typography>
												</Box>
											</Box>
										</Box>

										<FilteredValueCarosualComponent
											handdleDelete={(checkedValue) => {
												debounceCompanyListApiCall(checkedValue);
											}}
											filterId={`${activeKey}`}
										/>
									</Box>
								)}
							</Box>
						</Grid>

						{loading ? (
							<Box
								sx={{
									display: "flex",
									justifyContent: "center",
									mt: 10,
									width: "inherit",
								}}
							>
								<CircularProgress
									sx={(theme) => ({
										height: "80px",
										width: "80px",
										color: theme.palette.primary.primaryPurple,
									})}
								/>
							</Box>
						) : investorDashboardData?.startupList?.length === 0 ? (
							<Box
								sx={{
									display: "flex",
									justifyContent: "center",
									mt: 10,
									width: "inherit",
								}}
							>
								<Typography variant="headline5">No records found</Typography>
							</Box>
						) : (
							<Grid item md={12} sm={12} xs={12}>
								{viewType === "GRID" ? (
									<Box ref={observe}>
										{investorDashboardData?.startupList.length > 0 && (
											<InfiniteLoader itemCount={pageData?.totalElements} isItemLoaded={isItemLoaded} loadMoreItems={loadMoreItems}>
												{({ onItemsRendered, ref }) => renderVirtualList(onItemsRendered, ref)}
											</InfiniteLoader>
										)}
									</Box>
								) : (
									<Box>
										{(!isInvestorLocked || isAdmin) && investorDashboardData?.startupList.some((item) => item.checked) && (
											<Box sx={{ mb: 2 }}>
												<Box
													display={"flex"}
													alignItems="center"
													gap={1}
													id="basic-button"
													aria-controls={openActionMenu ? "basic-menu" : undefined}
													aria-haspopup="true"
													sx={{ cursor: "pointer", ml: 2 }}
													aria-expanded={openActionMenu ? "true" : undefined}
													onClick={handleActionMenuClick}
												>
													<Typography variant="testHeading"> Actions</Typography>
													<KeyboardArrowDownRoundedIcon fontSize="medium" />
												</Box>
											</Box>
										)}
										<BulkActionMenu />
										<OpportunityTableController
											autoHeight={false}
											requestData={(page, perPage) => {
												return getCompaniesData(page, perPage);
											}}
											columnDefs={AllStartupColumnDefs(
												handleSelectAll,
												selectAllStatus,
												handleCheckBoxClick,
												isAdmin,
												userType,
												partnerCode
											)}
											onCellClick={(params, event, details) => onCellClick(params, event, details)}
											title="All Startups"
											icon={
												<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
													<path
														d="M20 28V20.8C20 20.0533 20 19.6799 19.8547 19.3947C19.7268 19.1438 19.5229 18.9398 19.272 18.812C18.9868 18.6667 18.6134 18.6667 17.8667 18.6667H14.1333C13.3866 18.6667 13.0132 18.6667 12.728 18.812C12.4771 18.9398 12.2732 19.1438 12.1453 19.3947C12 19.6799 12 20.0533 12 20.8V28M25.3333 28V8.26667C25.3333 6.77319 25.3333 6.02646 25.0427 5.45603C24.787 4.95426 24.3791 4.54631 23.8773 4.29065C23.3069 4 22.5601 4 21.0667 4H10.9333C9.43986 4 8.69312 4 8.12269 4.29065C7.62093 4.54631 7.21298 4.95426 6.95732 5.45603C6.66667 6.02646 6.66667 6.77319 6.66667 8.26667V28M28 28H4M12.6667 10.6667H12.68M19.3333 10.6667H19.3467M13.3333 10.6667C13.3333 11.0349 13.0349 11.3333 12.6667 11.3333C12.2985 11.3333 12 11.0349 12 10.6667C12 10.2985 12.2985 10 12.6667 10C13.0349 10 13.3333 10.2985 13.3333 10.6667ZM20 10.6667C20 11.0349 19.7015 11.3333 19.3333 11.3333C18.9651 11.3333 18.6667 11.0349 18.6667 10.6667C18.6667 10.2985 18.9651 10 19.3333 10C19.7015 10 20 10.2985 20 10.6667Z"
														stroke={(theme) => theme?.palette?.primary?.main}
														stroke-width="1.5"
														stroke-linecap="round"
														stroke-linejoin="round"
													/>
												</svg>
											}
											noResultsMessage={
												<Box
													sx={{
														display: "flex",
														flexDirection: "column",
														alignItems: "center",
														gap: "16px",
														alignSelf: "stretch",
														flex: 1,
														justifyContent: "center",
													}}
												>
													<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
														<path
															d="M28 28L23.3335 23.3333M26.6667 15.3333C26.6667 21.5926 21.5926 26.6667 15.3333 26.6667C9.07411 26.6667 4 21.5926 4 15.3333C4 9.07411 9.07411 4 15.3333 4C21.5926 4 26.6667 9.07411 26.6667 15.3333Z"
															stroke={(theme) => theme?.palette?.primary?.main}
															stroke-width="1.5"
															stroke-linecap="round"
															stroke-linejoin="round"
														/>
													</svg>
													<Typography variant="Text/sm/Semibold">No Startups found</Typography>
												</Box>
											}
											dataGridProps={{
												disableColumnMenu: true,
												checkboxSelection: false,
											}}
										/>
									</Box>
								)}
							</Grid>
						)}
					</Grid>
				</Grid>
			</Box>
		</Stack>
	);
}
