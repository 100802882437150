import React, { useState, useEffect } from "react";
import { Grid, Box, Typography, InputAdornment } from "@mui/material";
import StartupOsTextField from "../../../../common/StartupOsInput/StartupOsTextField";

export const BreakEvenAnalysisCalculator = () => {
	const [fixedCost, setFixedCost] = useState();
	const [unitSellingPrice, setUnitSellingPrice] = useState();
	const [variableCostPerUnit, setVariableCostPerUnit] = useState();
	const [breakUpPoint, setBreakUpPoint] = useState(0);

	useEffect(() => {
		setBreakUpPoint(Math.floor(fixedCost / (unitSellingPrice - variableCostPerUnit)));
	}, [fixedCost, unitSellingPrice, variableCostPerUnit]);

	const handleInput = (e, type) => {
		let value = e.target.value.replace(/[^0-9]/g, "");
		if (type === "fixedCost") {
			setFixedCost(value);
		} else if (type === "unitSellingPrice") {
			setUnitSellingPrice(value);
		} else if (type === "variableCostPerUnit") {
			setVariableCostPerUnit(value);
		}
	};

	return (
		<Grid container display={"flex"} alignItems="center" sx={{ mt: 5 }}>
			<Grid item xs={12} lg={12}>
				<Typography variant="testHeading">Formula</Typography>
				<Box display={"flex"} alignItems="center" sx={{ mt: 3 }}>
					<Box>
						<Typography variant="title_portfolio" component={"p"}>
							Fixed Cost
						</Typography>
						<Box display={"flex"} alignItems="center" gap={1}>
							<StartupOsTextField
								value={fixedCost}
								fullWidth
								InputProps={{
									inputMode: "numeric",
									pattern: "[0-9]*",
									onChange: (e) => {
										handleInput(e, "fixedCost");
									},
									startAdornment: <InputAdornment position="start">$</InputAdornment>,
								}}
								isEndAdornment={false}
								sx={{
									width: "90%",
									color: "black",
									height: "60px",
									mt: 1,
									".MuiOutlinedInput-root": {
										borderRadius: "30px",
										height: "60px",
									},
									".MuiInputBase-input": (theme) => {
										return { ...theme.typography.subhead3, color: theme.palette.secondary.black, pl: 0, pr: 2 };
									},
								}}
							/>
							<Typography sx={{ mt: 1 }} variant="title_portfolio">
								/
							</Typography>
						</Box>
					</Box>
				</Box>
			</Grid>
			<Grid item xs={12} lg={12} sx={{ mt: 1 }}>
				<Box display={"flex"} alignItems="center" gap={2}>
					<Box>
						<Typography variant="title_portfolio" component={"p"}>
							Unit Selling Price
						</Typography>
						<Box display={"flex"} alignItems="center" gap={2}>
							{" "}
							<StartupOsTextField
								value={unitSellingPrice}
								fullWidth
								InputProps={{
									inputMode: "numeric",
									pattern: "[0-9]*",
									onChange: (e) => {
										handleInput(e, "unitSellingPrice");
									},
									startAdornment: <InputAdornment position="start">$</InputAdornment>,
								}}
								isEndAdornment={false}
								sx={{
									width: "90%",
									color: "black",
									height: "60px",
									mt: 1,
									".MuiOutlinedInput-root": {
										borderRadius: "30px",
										height: "60px",
									},
									".MuiInputBase-input": (theme) => {
										return { ...theme.typography.subhead3, color: theme.palette.secondary.black, pl: 0, pr: 2 };
									},
								}}
							/>
							<Typography alignSelf={"center"} variant="title_portfolio">
								-
							</Typography>
						</Box>
					</Box>
					<Box>
						<Typography variant="title_portfolio" component={"p"}>
							Variable Costs per Unit
						</Typography>

						<StartupOsTextField
							value={variableCostPerUnit}
							fullWidth
							InputProps={{
								inputMode: "numeric",
								pattern: "[0-9]*",
								onChange: (e) => {
									handleInput(e, "variableCostPerUnit");
								},
								startAdornment: <InputAdornment position="start">$</InputAdornment>,
							}}
							isEndAdornment={false}
							sx={{
								width: "90%",
								color: "black",
								height: "60px",
								mt: 1,
								".MuiOutlinedInput-root": {
									borderRadius: "30px",
									height: "60px",
								},
								".MuiInputBase-input": (theme) => {
									return { ...theme.typography.subhead3, color: theme.palette.secondary.black, pl: 0, pr: 2 };
								},
							}}
						/>
					</Box>
				</Box>
			</Grid>
			<Grid item xs={12} lg={12} sx={{ mt: 3 }}>
				<Typography variant="card_text" component={"p"}>
					Break-Even Point
				</Typography>
				<Box display={"flex"} alignItems="center">
					<Typography variant="title_portfolio" component={"p"}>
						=
					</Typography>
					<Typography variant="PoppinsMedium100" component={"p"} noWrap overflow="hidden">
						$
						{!isFinite(breakUpPoint)
							? 0
							: Intl.NumberFormat("en-US", {
									notation: "compact",
									maximumFractionDigits: 1,
							  }).format(Number(breakUpPoint))}
					</Typography>
				</Box>
			</Grid>
		</Grid>
	);
};
