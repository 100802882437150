import React, { useContext, useEffect, useState } from "react";
import { ProfileContext } from "../../..";
import {
	LOCATION_NEW_ICON,
	ROCKET_LAUNCH_ICON,
	MOBILE_DEVICE_ICON,
	GLOBE_HEMISPHERE_EAST_ICON,
	CODESANDBOX_ICON,
	UPDATED_INDUSTRY_ICON,
} from "../../../../../constants";
import { STATES } from "../../../../../constants/powerup-data";
import TagList from "./TagList";

function StartupBioTags() {
	const { profileData } = useContext(ProfileContext);
	const [items, setitems] = useState([]);
	const getValidatedUrl = (link) => {
		let prefix = "https://";

		if (link.indexOf("http://") == 0 || link.indexOf("https://") == 0) {
			return link;
		} else {
			return prefix + link;
		}
	};
	useEffect(() => {
		if (profileData) {
			let list = [
				{
					iconUrl: LOCATION_NEW_ICON,
					label: (() => {
						let city = profileData?.city;
						let area = STATES.find((s) => s.name === profileData?.state);
						area = area ? area?.abbreviation : profileData?.state;
						if (city && area) {
							return `${city}, ${area}`;
						} else if (!city && profileData?.state) {
							return profileData?.state;
						} else if (city && !profileData?.state) {
							return city;
						} else return "No Data";
					})(),
				},
				{ iconUrl: ROCKET_LAUNCH_ICON, label: profileData?.foundedYear ? profileData?.foundedYear : "No Data" },
				{
					iconUrl: MOBILE_DEVICE_ICON,
					label: (() => {
						const digits = profileData?.phone?.replace(/\D/g, ""); // removes all non-digit characters
						const digitMatch = digits?.match(/^(\d{3})(\d{3})(\d{4})$/); // matches groups of 3 digits
						if (digitMatch) {
							return `${digitMatch[1]}-${digitMatch[2]}-${digitMatch[3]}`;
						} else {
							return profileData?.phone ? profileData?.phone : "No Data"; // return original if phone number cannot be masked
						}
					})(),
				},
				{
					iconUrl: GLOBE_HEMISPHERE_EAST_ICON,
					label: (() => {
						return profileData?.websiteLink ? (
							<span style={{ cursor: "pointer" }} onClick={() => window.open(getValidatedUrl(profileData?.websiteLink), "_blank")}>
								{profileData?.websiteLink.replace(/^(?:https?:\/\/)?(?:[^\/]+\.)?([^.\/]+\.[^.\/]+).*$/, "$1")}
							</span>
						) : (
							"No Data"
						);
					})(),
				},
				{
					iconUrl: CODESANDBOX_ICON,
					label: (() => {
						return profileData?.productOffering ? profileData?.productOffering : "No Data";
					})(),
				},
				{
					iconUrl: UPDATED_INDUSTRY_ICON,
					label: (() => {
						let sentence = "";

						if (profileData?.industry) {
							try {
								let financeArr = JSON.parse(profileData?.industry);
								financeArr?.map((elem, index) => {
									sentence += elem;
									if (index < financeArr?.length - 1) {
										sentence += ", ";
									}
								});
							} catch (e) {
								console.log(e);
							}
						}
						return sentence;
					})(),
				},
			];
			setitems(list);
		}
	}, [profileData]);

	return <TagList items={items} />;
}

export default StartupBioTags;
