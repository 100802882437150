import React from "react";
import { Box, Avatar, Typography, Grid, IconButton, Link } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import EditOrDeleteMenuComponent from "./EditOrDeleteMenu";
import { useHistory } from "react-router-dom";

const FeedCommentsCard = ({ item, index }) => {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const isOpen = Boolean(anchorEl);
	const history = useHistory();

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleRedirection = () => {
		if (item?.owner?.isCompanyUser) {
			history.push(`/account-profile/${item?.owner?.id}`);
		} else {
			history.push(`/mentor-profile/${item?.owner?.id}`);
		}
	};
	return (
		<Grid container display={"flex"}>
			<Grid item xl={5} lg={6} md={6} sm={5} xs={12}>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						gap: 2,
					}}
				>
					<Avatar
						sx={{
							width: "70px",
							height: "70px",
						}}
						src={item?.owner?.picture}
						alt={"Profile Photo"}
						onClick={handleRedirection}
					></Avatar>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
						}}
					>
						<Typography
							sx={(theme) => ({
								overflow: "hidden",
								maxWidth: "95%",
								textOverflow: "ellipsis",
								[theme.breakpoints.down("lg")]: {
									maxWidth: 1,
								},
							})}
							variant="flyout_title"
							onClick={handleRedirection}
						>
							{item?.owner?.name}
						</Typography>
						<Typography
							sx={(theme) => ({
								overflow: "hidden",
								maxWidth: "95%",
								textOverflow: "ellipsis",
								[theme.breakpoints.down("lg")]: {
									maxWidth: 1,
								},
							})}
							variant="Text/sm/Regular"
						>
							<Link
								color={"#1A73E8"}
								onClick={() => {
									history.push(`/startup-profile/${item?.owner?.companyModel?.id}`);
								}}
							>
								{item?.owner?.companyModel?.companyName}
							</Link>
						</Typography>
					</Box>
				</Box>
			</Grid>
			<Grid item xl={6} lg={5} md={5} sm={5} xs={12} sx={{ textAlign: "justify", paddingTop: "4px", wordBreak: "break-word" }} display={"flex"}>
				<Typography variant="Text/sm/Regular">{item?.text}</Typography>
			</Grid>
			<Grid item xl={1} lg={1} md={1} sm={1} xs={12} display={"flex"}>
				{item?.isCreatedByUser && (
					<IconButton
						onClick={handleClick}
						sx={{
							width: "40px",
							height: "40px",
							background: "#F2F4F7",
							ml: "auto",
							"&:hover": {
								background: "#F2F4F7",
							},
						}}
					>
						<MoreHorizIcon />
					</IconButton>
				)}
			</Grid>

			<EditOrDeleteMenuComponent
				isOpen={isOpen}
				anchorEl={anchorEl}
				onClose={handleClose}
				commentObj={item}
				commentIndex={index}
				setAnchorEl={setAnchorEl}
			/>
		</Grid>
	);
};

export default FeedCommentsCard;
