import { Box } from "@mui/material";
import React, { useRef, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const ReusableQuill = ({ value, onChange, className, id }) => {
	return (
		<Box component="div" className={className}>
			<ReactQuill
				id={id}
				className="quill-editor"
				value={value}
				onChange={(value) => onChange(value)}
				modules={{
					toolbar: [["bold", "italic", "underline"], [{ list: "ordered" }, { list: "bullet" }], ["link"]],
				}}
				formats={[
					"header",
					"bold",
					"italic",
					"underline",
					"strike",
					"blockquote",
					"code-block",
					"list",
					"bullet",
					"script",
					"indent",
					"direction",
					"size",
					"color",
					"background",
					"font",
					"align",
					"link",
				]}
			/>
		</Box>
	);
};

export default ReusableQuill;
