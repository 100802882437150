import React, { useState, useEffect, memo, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, useMediaQuery, Slide, Dialog, DialogContent } from "@mui/material";
import { useWindowSize } from "../../utils/windowResize";
import { setSlidingProfileData } from "../../modules/actions/slidingProfileActions";
import AccountAndStartupProfile from "../../components/Profile";
import MentorProfile from "../MentorProfile";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="left" ref={ref} {...props} />;
});

function SlidingProfile() {
	const size = useWindowSize();
	const dispatch = useDispatch();
	const DRAWER_OPEN = useSelector((state) => state?.slidingProfile?.open);
	const userType = useSelector((store) => store?.auth?.userType);
	const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
	const responsiveDrawerWidth = isSmallScreen ? size.width * 1 : size.width * 0.85;
	const [show, setShow] = useState(false);
	const profileType = useSelector((state) => state?.slidingProfile?.profileType);

	const maxWidth = "1920px"; // Figma specifies max content width

	useEffect(() => {
		if (DRAWER_OPEN) {
			setShow(true);
		} else {
			setTimeout(() => {
				setShow(false);
			}, 700);
		}
	}, [DRAWER_OPEN]);

	const handleClose = () => {
		dispatch(
			setSlidingProfileData({
				open: false,
				profileType: "",
				profileId: null,
			})
		);
	};

	const getSlidingProfileComponent = useMemo(() => {
		if (profileType === "STARTUP_PROFILE" || profileType === "ACCOUNT_PROFILE") {
			return <AccountAndStartupProfile />;
		} else if (profileType === "MENTOR_PROFILE") {
			return <MentorProfile />;
		}
		return "";
	}, [profileType]);

	return (
		<Dialog
			fullWidth
			TransitionComponent={Transition}
			transitionDuration={700}
			open={DRAWER_OPEN}
			onClose={() => {}} //close button is in dialog content
			PaperProps={{
				sx: {
					position: "fixed",
					right: 0,
					top: 0,
					bottom: 0,
					borderRadius: "30px 0px 0px 30px",
					boxShadow: "0px 5px 16px rgba(0, 0, 0, 0.3)",
					background: "#F5F9FA",
					width: `${responsiveDrawerWidth}px`,
					maxWidth: maxWidth, // Set your width here
					m: 0,
					maxHeight: "100%",
					minHeight: "100%",
				},
			}}
		>
			<DialogContent
				sx={{
					border: "none",
					p: 0,
					flex: 1,
					display: "flex",
					mb: "30px",
					pt: "60px",
				}}
			>
				<Box width={responsiveDrawerWidth} sx={{ maxWidth: maxWidth, display: "flex", flexDirection: "column", rowGap: "60px" }}>
					{show && getSlidingProfileComponent}
				</Box>
			</DialogContent>
		</Dialog>
	);
}

export default memo(SlidingProfile);
