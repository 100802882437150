import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { MetaLinkCard } from "../../common/MetaLinkCard";
import services from "../../services";
import messages from "../../constants/messages";
import { toastContainer } from "../../utils/toast";
import { REGEX } from "../../constants/regex";

export default function MetaLink({ data, url }) {
  const [links, setLinks] = useState([]);
  const [metaData, setMetaData] = useState([]);
  const [splitSentence, setSplitSentence] = useState([]);

  useEffect(() => {
    if (url) {
      //handleLink(url);
      setLinks([...links, { link: checkUrl(url) }]);
    } else {
      if (data) {
        handleChange(data.replace(/(<([^>]+)>)/gi, ""));
      } else {
        setMetaData([]);
      }
    }
  }, [url, data]);

  const checkUrl = data => {
    let prefix = "https://";

    if (data.indexOf("http://") === 0 || data.indexOf("https://") === 0) {
      return data;
    } else {
      return prefix + data;
    }
  };

  const isValidUrl = urlString => {
    var urlPattern =
      /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm;
    return urlPattern.test(urlString);
  };

  const handleLink = link => {
    setLinks([...links, { link: checkUrl(link) }]);
  };

  const handleChange = data => {
    let splitSentence = data
      .replace(/,/g, " ")
      .replace(/\n/g, " ")
      .split(" ")
      .filter(n => n);

    setSplitSentence(splitSentence);
  };

  useEffect(() => {
    splitSentence.forEach((ele, index) => {
      if (isValidUrl(ele)) {
        if (!links.some(url => url.link === checkUrl(ele))) {
          setLinks([
            ...removeDuplicates(),
            { link: checkUrl(ele).replace(/&nbsp;/g, "") },
          ]);
        }
      }
    });
  }, [splitSentence]);

  const removeDuplicates = () => {
    let filtered = links.filter(
      item => splitSentence.indexOf(item.link) !== -1
    );
    return filtered;
  };

  useEffect(() => {
    handleGetMetaData();
  }, [links]);

  //handle api for fetching metaData
  const handleGetMetaData = () => {
    services
      .getMetadataDetails(links)
      .then(res => {
        if (res.data.code === 200) {
          setMetaData(res.data.data);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  return (
    <Box sx={{ width: 1, display: "flex", my: 1 }}>
      {metaData && <MetaLinkCard data={metaData} />}
    </Box>
  );
}
