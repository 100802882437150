import React, { useEffect, useState } from "react";
import moment from "moment";
import { Button, Box, Divider, Grid, TextField, Typography } from "@mui/material";
import DialogComponent from "../../../common/CommonDialog";
import { handleMixPanel } from "../../../utils/mixPanelEventHandling";

export default function CancelMeetingPopup({ open, onClose, bookingData, handleReschedule, handleCancel }) {
	const [cancellingReason, setCancellingReason] = useState("");
	const [cancelSelected, setCancelSelected] = useState(false);

	useEffect(() => {
		handleMixPanel("Meeting Cancellation Confirmation Viewed", {
			"Meeting ID": bookingData.id,
		});
	}, []);

	return (
		<DialogComponent
			isOpen={open}
			onClose={() => {
				onClose();
			}}
			footerStyle={{
				border: "none",
			}}
			contentStyle={{ px: 0 }}
			maxWidth={"sm"}
			fullWidth
			PaperProps={{
				sx: (theme) => ({
					borderRadius: theme.shape.standard10.borderRadius,
					boxShadow: "0px 5px 16px rgba(0, 0, 0, 0.3)",
				}),
			}}
			title={
				<Box sx={{ display: "flex", flexDirection: "column" }}>
					<Typography variant={"Text/md/Semibold"}>{cancelSelected ? "Cancel Event" : "View Event"}</Typography>
				</Box>
			}
			isHeadeBottom={false}
			dialogAction={
				<Box sx={{ display: "flex", gap: 1, m: 2 }}>
					<Button
						variant="containedGrayStyle"
						onClick={() => {
							cancelSelected ? handleCancel(cancellingReason) : setCancelSelected(true);
						}}
						sx={{ mr: 1, width: "max-content", whiteSpace: "nowrap" }}
					>
						Cancel Event{" "}
					</Button>{" "}
					{!cancelSelected && (
						<Box>
							<Button
								variant="containedPurpleStyle"
								sx={{ width: "max-content", whiteSpace: "nowrap" }}
								onClick={() => {
									handleReschedule(bookingData);
									handleMixPanel("Meeting Rescheduled", {
										Source: "Cancellation Confirmation",
									});
								}}
							>
								Reschedule Event{" "}
							</Button>
						</Box>
					)}
				</Box>
			}
		>
			<Box sx={{ p: 2 }}>
				<Divider />

				<Box sx={{ m: 2 }}>
					<Grid container>
						<Grid item xs={3}>
							<Box>
								<Typography sx={{ color: "#667085" }} variant="Text/sm/Regular">
									What
								</Typography>
							</Box>
						</Grid>
						<Grid item xs={9}>
							<Typography variant="Text/sm/Regular">{bookingData.title}</Typography>
						</Grid>
					</Grid>
				</Box>

				<Box sx={{ m: 2 }}>
					<Grid container>
						<Grid item xs={3}>
							<Box>
								<Typography sx={{ color: "#667085" }} variant="Text/sm/Regular">
									When
								</Typography>
							</Box>
						</Grid>
						<Grid item xs={9}>
							<Typography variant="Text/sm/Regular">
								{`${moment(bookingData?.startTime?.split("T")?.[0]).format("LL")} , ${moment(
									bookingData?.startTime?.split("T")?.[1],
									"HH:mm:ss"
								).format("LT")} - ${moment(bookingData?.endTime?.split("T")?.[1], "HH:mm:ss").format("LT")}`}{" "}
							</Typography>
						</Grid>
					</Grid>
				</Box>
				<Box sx={{ m: 2 }}>
					<Grid container>
						<Grid item xs={3}>
							<Box>
								<Typography sx={{ color: "#667085" }} variant="Text/sm/Regular">
									Meeting Link
								</Typography>
							</Box>
						</Grid>
						<Grid item xs={9}>
							<Typography variant="Text/sm/Regular">
								<a href={bookingData?.meetingUrl} target="_blank">
									{bookingData?.meetingUrl}
								</a>
							</Typography>
						</Grid>
					</Grid>
				</Box>

				{cancelSelected && (
					<Box sx={{ mx: 2, mt: 4 }}>
						<Typography variant="Text/sm/Semibold">Why are you cancelling? (optional)</Typography>
						<TextField
							placeholder="Type here"
							value={cancellingReason}
							multiline
							inputProps={{ maxLength: 100 }}
							sx={{
								mt: 1,

								"& .MuiOutlinedInput-notchedOutline": {
									height: "auto",
									border: "1px solid #D0D5DD",
									borderRadius: "100px",
								},
							}}
							fullWidth
							onChange={(e) => {
								setCancellingReason(e.target.value);
							}}
						/>
					</Box>
				)}
			</Box>
		</DialogComponent>
	);
}
