export const REVENUE_MODELER_TYPES = [
  {
    revenueModel: "Transaction",
    description: "One-time sale of goods or services.",
    pros: "Can have low barrier to trial.",
    cons: "Need to continuously generate new sales.",
    exampleCompanies: "American Eagle",
  },
  {
    revenueModel: "Project",
    description: "One-time project.",
    pros: "Can have large payouts. Can have low startup cost.",
    cons: "Scalability issues. May have long sales oycle. May require large upfront investment.",
    exampleCompanies: "Construction company",
  },
  {
    revenueModel: "Subscription",
    description:
      "Customer pays for access to a product or service in installments (usually monthly or yearly).",
    pros: "Do not have to resell customer for each revenue installment.",
    cons: "May require large upfront investment. May have long sales cycle.",
    exampleCompanies: "Dropbox, Salesforce",
  },
  {
    revenueModel: "Service",
    description:
      "Service provided to customer. Customer pays for time or expertise.",
    pros: "May not require large upfront investment.",
    cons: "Scalability issues.",
    exampleCompanies: "Law firm, Supercuts",
  },
  {
    revenueModel: "Freemium",
    description:
      "Offer subset of features for free. Try to convert free customers to paying customers.",
    pros: "Can have low barrier to trial.",
    cons: "Hard to convert to paid. Hard to determine paid/unpaid.",
    exampleCompanies: "Dropbox",
  },
  {
    revenueModel: "Marketplace, platform",
    description: "Platform or marketplace to connect buyers to producers.",
    pros: "May not require large upfront investment. Sometimes are winner-tale-all markets.",
    cons: "Two sided market.",
    exampleCompanies: "Etsy",
  },
];

export const STAGE_MENU = [
  "I have an idea",
  "I’ve received some funding",
  "I know my customer",
  "I’ve sized my market",
  "I’ve started hiring",
  "I’m building a brand",
  "I’ve launched a product",
];

export const REVENUE_MODELER_HEADERS = [
  "Revenue Model",
  "Description",
  "Pros",
  "Cons",
  "Example companies",
];

export const MODELS_DATA = [
  "Transaction",
  "Project",
  "Subscription",
  "Service",
  "Freemium",
  "Marketplace Platform",
];

export const PRIMARY_BILL = ["Monthly", "Quarterly", "Annually"];

export const ACQUIRE_CUSTOMERS = [
  "Self Service—They sign up and subscribe on their own",
  "Inbound Sales—They become leads + our sales team manages the closing",
  "Outbound Sales—Our team finds prospects + closes sales",
];
export const BUSINESS_TYPE = ["B2B", "B2C"];

export const TOOL_TIP_DATA = [
  {
    title:
      "We're looking for your Average Revenue Per User (ARPU) per month. If you have multiple plans, use an average of those totals. To find your ARPU: Divide your Monthly Recurring Revenue (MRR) by the number of active users.",
    subTitle:
      "By crunching the numbers now, you'll eliminate financial projections guesswork and show prospective investors how they stand to gain from investing in your company, and put yourself in the best possible position to achieve your goals.",
    description: "",
  },
];
