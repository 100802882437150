import services from "../../../../services";

export const competitorLandscapeAI = async (companyName, valueProp, cb) => {
	cb({ key: "competitorsAI", values: { loading: true, complete: false } });

	if (!companyName && !valueProp) {
		cb({ key: "competitorsAI", values: { loading: false, complete: true } });
		return;
	}

	let payload = {
		messages: [
			{
				role: "system",
				content: `Based on the provided company name and value proposition, determine the relevant industry. Identify three actual, recognized, and established American competitors from that industry that were known as of 2021. Do not create or speculate company names; they must be genuine and notable. Using the value proposition, specify five distinct features related to the provided company. Compare these features across the identified competitors. 
				Only respond with the following JSON format:
				{
					"competitors": ["Provided Company", "Real Competitor 1", "Real Competitor 2", "Real Competitor 3"],
					"features": [
					  {
						"name": "Feature 1",
						"availability": ["Provided Company", "Real Competitor 3"]
					  },
					  ...
					]
				  }
				  Ensure varied and non-identical feature availability across the companies.
				`,
			},
			{
				role: "user",
				content: `The company ${companyName} with a value proposition: ${valueProp}.`,
			},
		],
		maxTokens: 1500,
		model: "gpt-4",
		topP: 0.9,
		temp: 0.2
	};

	let attempts = 0;
	while (attempts < 2) {
		try {
			let AIResp = await services.getOpenAIChatResponse(payload).catch((e) => {
				console.log(e);
			});

			cb({ key: "competitorsAI", values: { loading: false, complete: true } });

			if (AIResp?.data?.data?.[0]?.text) {
				return processPayload(AIResp?.data?.data?.[0]?.text);
			} else if (AIResp?.data?.data?.[0]?.message?.content) {
				return processPayload(AIResp?.data?.data?.[0]?.message?.content);
			} else {
				attempts++;
			}
		} catch (error) {
			console.error(error);
		}
	}
	return false;
};

const processPayload = (payload) => {
	try {

		const data = JSON.parse(payload);

		let transformed = {};
		const baseObj = {
			COMPETITOR_1: "",
			COMPETITOR_2: "",
			COMPETITOR_3: "",
			COMPETITOR_4: "",
			FEATURE_1: "",
			FEATURE_2: "",
			FEATURE_3: "",
			FEATURE_4: "",
			FEATURE_5: "",
			C1_F1: "",
			C1_F2: "",
			C1_F3: "",
			C1_F4: "",
			C1_F5: "",
			C2_F1: "",
			C2_F2: "",
			C2_F3: "",
			C2_F4: "",
			C2_F5: "",
			C3_F1: "",
			C3_F2: "",
			C3_F3: "",
			C3_F4: "",
			C3_F5: "",
			C4_F1: "",
			C4_F2: "",
			C4_F3: "",
			C4_F4: "",
			C4_F5: "",
		};

		// Mapping competitors
		data.competitors.forEach((competitor, index) => {
			transformed[`COMPETITOR_${index + 1}`] = competitor;
		});

		// Mapping features and their availability
		data.features.forEach((feature, fIndex) => {
			transformed[`FEATURE_${fIndex + 1}`] = feature.name;
			data.competitors.forEach((competitor, cIndex) => {
				if (feature.availability.includes(competitor)) {
					transformed[`C${cIndex + 1}_F${fIndex + 1}`] = "Yes";
				} else {
					transformed[`C${cIndex + 1}_F${fIndex + 1}`] = "No";
				}
			});
		});

		Object.keys(baseObj).forEach((key) => {
			if (!transformed.hasOwnProperty(key)) {
				transformed[key] = baseObj[key];
			}
		});

		return transformed;
	} catch (e) {
		console.log(e);
	}
};

