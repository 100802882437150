import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Box, CircularProgress } from "@mui/material";
import { sosPrimary } from "../../constants/Theme/colors";
import theme from "../../constants/Theme";
import Header from "./Header";
import SurveyMenu from "./SurveyMenu";
import About from "./About";
import Participants from "./Participants";
import Results from "./Results";
import services from "../../services";

const MOCK_DATA = {
  title: "Net Promoter® Score Survey",
  subHeader: "subheader",
  aboutTitle: "About",
  aboutDesc:
    "Net Promoter® Score (NPS) is considered the gold standard for measuring customer loyalty. When you earn customer loyalty, a customer will choose your brand over any competitor's brand regardless of convenience or price. Loyal customers don’t feel motivated to try alternatives because your solution meets all of their needs. They’re also comfortable recommending your brand to others which creates traction at a more attractive customer acquisition cost (CAC). Brand loyalty must be earned and directly affects your bottom line with consistent sales and word-of-mouth bringing in new customers. The NPS survey gets you the data you need to quickly understand how customers feel about your organization and provides a clear signal that you need to react to negative feedback. An NPS score also makes it easy to set both internal performance benchmarks, as well as external benchmarks to compare against competitors in your industry. Set a schedule to collect NPS scores twice a year — or more often if you have a large customer base. Track changes over time and make action plans to address needed improvements.",
};

export default function Survey() {
  const MOCK_SURVEY_RESPONSE = {
    responses: 2,
    answers_for_charts: [
      {
        family: "matrix",
        subtype: "rating",
        heading:
          "How likely is it that you would recommend this company to a friend or colleague?",
        answers: ["10", "6", "3", "6", "2", "6", "3", "5", "1"],
      },
      {
        family: "single_choice",
        subtype: "vertical_two_col",
        heading: "In what country do you currently reside?",
        answers: [
          "United States",
          "Other",
          "United States",
          "United States",
          "Other",
        ],
      },
    ],
    answers_for_csv: {
      header: ["", "{email_address}", "{email_address}"],
      Rows: [
        [
          "How likely is it that you would recommend companyName to a friend or colleague?",
          "10",
          "6",
        ],
        [
          "What changes would this company have to make for you to give it an even higher rating?",
          "This is an answer",
          "",
        ],
      ],
    },
  };
  const params = useParams();
  const surveyId = params?.id;
  const [currentMenu, setMenu] = useState("about");
  const [loader, setLoader] = useState(false);
  const [surveyDetails, setSurveyDetails] = useState({});
  const [surveyResponseDetails, setSurveyResponseDetails] = useState();

  const handleMenu = newMenu => {
    setMenu(newMenu);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    handleGetSurveyApiCall();
  }, []);

  const handleGetSurveyResponseApiCall = smCollectorId => {
    services
      .getSurveyResponse(smCollectorId)
      .then(res => {
        const responseData = res?.data?.data;
        responseData?.answers_for_csv?.rows[0].map((uniqueItem, uniqueIdx) => {
          if (
            uniqueItem.includes("Extremely likely - 10") &&
            responseData.answers_for_csv.rows[0][0] ===
              "How likely is it that you would recommend this company to a friend or colleague?"
          ) {
            let temp = uniqueItem.replace("Extremely likely - ", "");
            responseData.answers_for_csv.rows[0][uniqueIdx] = temp;
          }
        });

        setSurveyResponseDetails(responseData);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const handleGetSurveyApiCall = () => {
    setLoader(true);
    services
      .getSurveyDetails(surveyId)
      .then(res => {
        const responseData = res?.data?.data;
        if (responseData?.companySurvey?.length > 0) {
          setMenu("results");
          handleGetSurveyResponseApiCall(
            responseData?.companySurvey[0]?.smCollectorId
          );
        }
        setSurveyDetails(responseData);
        setLoader(false);
      })
      .catch(err => {
        setLoader(false);
        console.log(err);
      });
  };

  return (
    <>
      {loader ? (
        <Box
          justifyContent={"center"}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box
          sx={{
            p: { xs: 2, md: 4 },
            backgroundColor: {
              //xs: sosPrimary[25],
              md: theme.palette.secondary.white,
            },
          }}
        >
          <Box
            flexDirection={"column"}
            sx={{ maxWidth: theme.breakpoints.values.xl, margin: "auto" }}
          >
            <Box
              sx={theme => ({
                border: {
                  xs: "none",
                },
                borderRadius: theme.shape.standard.borderRadius,
                p: { xs: 0, md: 2 },
                mt: 1,
              })}
            >
              <Header
                title={surveyDetails?.surveyName}
                subHeader={MOCK_DATA.subHeader}
              />
              <SurveyMenu currentMenu={currentMenu} handleMenu={handleMenu} />
              <Box
                sx={{
                  borderRadius: 1,
                  p: 4,
                  border: `1px solid ${theme.palette.secondary.borderLight}`,
                }}
              >
                {currentMenu === "about" && (
                  <About
                    title={MOCK_DATA.aboutTitle}
                    desc={surveyDetails?.surveyAbout}
                    previewUrl={surveyDetails?.previewUrl}
                  />
                )}
                {currentMenu === "participants" && (
                  <Participants
                    surveyDetails={surveyDetails}
                    setMenu={setMenu}
                    handleGetSurveyApiCall={handleGetSurveyApiCall}
                  />
                )}
                {currentMenu === "results" && (
                  <Results
                    surveyResponseDetails={surveyResponseDetails}
                    isCompanySurvey={surveyDetails?.companySurvey.length > 0}
                    surveyId={surveyId}
                  />
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}
