import theme from "../../constants/Theme";
import TabsListUnstyled from "@mui/base/TabsListUnstyled";
import TabUnstyled, { tabUnstyledClasses } from "@mui/base/TabUnstyled";
import { styled } from "@mui/system";

export const TabStyle = styled(TabUnstyled)`
  cursor: pointer;
  background-color: transparent;
  width: 100%;
  color: ${theme.palette.text.primary};
  padding: ${theme.spacing(6)};
  border: none;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  background: linear-gradient(
      0deg,
      rgba(118, 73, 160, 0.05),
      rgba(118, 73, 160, 0.05)
    ),
    #fcfcfc;
  boxshadow: 0px 8px 12px 6px rgba(0, 0, 0, 0.15),
    0px 4px 4px rgba(0, 0, 0, 0.3);
  margin-right: ${theme.spacing(1)};
  position: relative;

  &:hover {
    background: ${theme.palette.secondary.primaryContainerLight};
  }

  &:focus {
    outline-offset: 2px;
  }

  &.${tabUnstyledClasses.selected} {
    background: ${theme.palette.secondary.primaryContainerLight};
  }
`;

export const TabsList = styled(TabsListUnstyled)`
  min-width: 320px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
`;
