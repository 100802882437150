import React, { useState, useEffect } from "react";
import { Box, Button, CircularProgress, Divider, IconButton, Typography } from "@mui/material";
import PageTitle from "../../common/PageTitle";
import { USER_WITH_TICK } from "../../constants";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import { FileDownload } from "@mui/icons-material";

const FileHandler = ({ selectedFile, description, label, disabled }) => {
	const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
		// Disable click and keydown behavior
		noClick: true,
		noKeyboard: true,
		accept: {
			"text/csv": [],
		},
	});

	const files = acceptedFiles.map((file) => <li key={file.path}>{file.path}</li>);
	useEffect(() => {
		selectedFile(acceptedFiles);
	}, [acceptedFiles]);
	return (
		<div className="container">
			<div {...getRootProps({ className: "dropzone" })}>
				<input {...getInputProps()} />
				<p>{description}</p>
				<Button variant="DS1" startIcon={<FileUploadIcon />} onClick={open} disabled={disabled}>
					{label}
				</Button>
			</div>
			{files?.length > 0 && (
				<aside>
					<h4>Selected File</h4>
					<ul>{files}</ul>
				</aside>
			)}
		</div>
	);
};

const CompanyUsersImport = () => {
	const [companyCsv, setCompanyCsv] = useState([]);
	const [usersCsv, setUsersCsv] = useState([]);
	const [uploading, setuploading] = useState(false);

	const uploadFiles = async () => {
		if (companyCsv?.[0]) {
			setuploading(true);
			var formdata = new FormData();
			formdata.append("companyFile", companyCsv[0]);

			try {
				const response = await axios.post(process.env.REACT_APP_API_URL + "/api/m1/v1/user-company-import/upload", formdata, {
					headers: {
						"Content-Type": "multipart/form-data",
					},
					responseType: "blob",
				});
				if (response) {
					const url = window.URL.createObjectURL(new Blob([response.data]));
					const link = document.createElement("a");
					link.href = url;
					link.setAttribute("download", "output.csv");
					document.body.appendChild(link);
					link.click();
					setuploading(false);
				} else {
					setuploading(false);
				}
			} catch (error) {
				console.error("Error uploading file:", error);
				setuploading(false);
			}
		}
	};

	const handleDownload = () => {
		const csvString = `email,name,phone,city,country,first_name,last_name,linked_in_url,state,zipcode,address1,address2,about,capital_raised,company_name,founded_year,funding_type,headline,linked_in_url,product_offering,tag_line,website_link,ask,current_stage,financing,industry,revenue,seeking,company_size,founder_story,business_model,revenue_model,twitter_url,cap_table,founded_date,incubator,incorporated_status,state_of_product,monthly_revenue,growth_rate
    ,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,`;

		// Create a Blob from the CSV string
		const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
		const url = URL.createObjectURL(blob);

		// Create a link element, set its href and download attribute, and trigger the download
		const link = document.createElement("a");
		link.href = url;
		link.setAttribute("download", "users_companies_import_template.csv");
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};
	return (
		<>
			<Box
				sx={{
					px: {
						lg: 8,
						md: 4,
						xs: 2,
					},
					pb: "30px",
					display: "flex",
					flexDirection: "column",
					gap: "32px",
					maxWidth: "1920px",
					margin: "0 auto",
				}}
			>
				{" "}
				<Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between", alignItems: "center", rowGap: 2, columnGap: 1 }}>
					<PageTitle icon={USER_WITH_TICK} label={"Company and Users Import"} />
					<Button color="tertiary" variant="DS1" onClick={() => handleDownload()} endIcon={<FileDownload />} sx={{ ml: "auto" }}>
						Download Template
					</Button>
				</Box>
				<Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
					<Box sx={{ width: 1, display: "flex", flexDirection: "column", gap: 2 }}>
						<FileHandler
							description={"Select Users and Companies Details CSV"}
							label={"Details CSV"}
							selectedFile={(file) => setCompanyCsv(file)}
						/>
						<Box sx={{ mt: 2 }}>
							<Button
								color="black"
								variant="DS1"
								disabled={uploading || !companyCsv?.length > 0}
								onClick={() => uploadFiles()}
								startIcon={uploading ? <CircularProgress /> : <FileUploadIcon />}
							>
								Upload
							</Button>
						</Box>
					</Box>
				</Box>
			</Box>
		</>
	);
};

export default CompanyUsersImport;
