import React, { createContext, useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { ProfileContext } from "..";
import services from "../../../services";
import { useLazyQuery } from "@apollo/client";
import { GET_COMPANY_DATA_QUERY, GET_FOUNDER_DATA_QUERY } from "./Function/common";
import { setUserProfileData } from "../../../modules/actions/userProfileActions";
import { USER_TYPES } from "../../../utils/userTypes";
import ROUTES_PATH from "../../../constants/routes";
import { PARTNER_QA_DATA } from "../../../constants";

export const StartupProfile = ({ children }) => {
	const [errors, setErrors] = useState({});
	const [viewContext, setViewContext] = useState({ component: null, id: null, isEditable: false });
	const [profileDataLoading, setProfileDataLoading] = useState(true);
	const [teamDataLoading, setTeamDataLoading] = useState(false);
	const [profileData, setProfileData] = useState({});
	const [teamArray, setTeamArray] = useState([]);
	const editorOpen = useSelector((state) => state?.userProfileData?.userProfileEditOpen);
	const userType = useSelector((store) => store?.auth?.userType);
	const isAdmin = useSelector((store) => store?.auth?.isAdmin);
	const [founderDetails, setFounderDetails] = useState(false);
	const [partnerQA, setPartnerQA] = useState(null);

	const userData = useSelector((store) => ({ userType: store?.auth?.userType, companyId: store?.auth?.companyId, userId: store?.auth?.userId }));
	const dispatch = useDispatch();
	const params = useParams();
	const slidingProfileData = useSelector((store) => ({
		open: store?.slidingProfile?.open,
		profileType: store?.slidingProfile?.profileType,
		profileId: store?.slidingProfile?.profileId,
	}));
	const userId = slidingProfileData?.open ? slidingProfileData?.profileId : params?.id ? params?.id : null;

	const [loadUserData, { loading, error, data }] = useLazyQuery(GET_COMPANY_DATA_QUERY, {
		onCompleted: function (data) {
			if (data?.companyById) {
				setProfileData(data?.companyById);
				loadFounderData({
					variables: { id: data?.companyById?.founderDetails?.id },
				});
			}
		},
		onError: (err) => {
			setErrors({
				error: viewContext?.id
					? "Unable to successfully retrieve startup profile by ID:" + viewContext?.id
					: "Unable to successfully retrieve your startup profile.",
			});
		},
		fetchPolicy: "no-cache",
	});

	const [loadFounderData, { founderLoading, founderError, founderData }] = useLazyQuery(GET_FOUNDER_DATA_QUERY, {
		onCompleted: function (data) {
			if (data?.userById) {
				setFounderDetails(data?.userById);
			}
		},
		onError: (err) => {
			console.log("Unable to successfully retrieve founder profile by ID", err);
		},
		fetchPolicy: "no-cache",
	});

	useEffect(() => {
		if (!editorOpen) {
			loadUserData({
				variables: { id: userId },
			});
		}
	}, [editorOpen]);

	useEffect(() => {
		if (!slidingProfileData?.open) {
			loadUserData({
				variables: { id: userId },
			});
		}
	}, [slidingProfileData?.open]);

	const initilizer = () => {
		const { id } = params;
		if (slidingProfileData?.open && slidingProfileData?.profileId) {
			if (userData?.companyId === parseInt(slidingProfileData?.profileId)) {
				setViewContext({ component: "startup", id: null, isEditable: true });
			} else {
				setViewContext({
					component: "startup",
					id: isNaN(slidingProfileData?.profileId) ? null : parseInt(slidingProfileData?.profileId),
					isEditable: !slidingProfileData?.profileId ? true : false,
				});
			}
			return;
		}
		// Prevent loading of public profile when viewing your own via ID
		if (userData?.companyId === parseInt(id)) {
			setViewContext({ component: "startup", id: null, isEditable: true });
		} else {
			setViewContext({ component: "startup", id: isNaN(id) ? null : parseInt(id), isEditable: !id ? true : false });
		}
	};

	const getPartnerQAApiCall = async (entityId) => {
		try {
			let response;

			if ((isAdmin || userType === USER_TYPES.PARTNER_USER) && userId !== null) {
				response = await services.getAdminQuestionAnswersAPI({ entityId: founderDetails?.id });
			} else {
				response = await services.getPartnerQAAPI();
			}

			if (response?.data?.data) {
				setPartnerQA(response?.data?.data);
			}
		} catch (error) {
			console.log(error, "Unable to successfully retrieve partner questions and answers");
		}
	};

	useEffect(() => {
		// Endpoint to get investor impression for a startup
		if (userId && userType === USER_TYPES.INVESTOR_USER) {
			services
				.updateStreakActionApi({
					actionType: "INVESTOR_VIEW",
					actionOnId: userId,
					actionOnType: "STARTUP",
				})
				.then()
				.catch();
		}
		initilizer();
		if (founderDetails?.id) {
			getPartnerQAApiCall(founderDetails.id);
		}
	}, [founderDetails?.id]);

	useEffect(() => {
		initilizer();
	}, [params]);

	useEffect(() => {
		if (viewContext && viewContext?.component === "startup") {
			if (viewContext?.id) {
				getById(viewContext?.id);
				getTeam(viewContext?.id);
			} else {
				getMyStartup(userData?.companyId);
				getTeam(userData?.companyId);
			}
		}
	}, [viewContext]);

	useEffect(() => {
		setProfileDataLoading(loading);
		if (loading) {
			dispatch(
				setUserProfileData({
					loading: loading,
				})
			);
		} else {
			if (!error && data) {
				dispatch(
					setUserProfileData({
						loading: loading,
						userData: data?.companyById,
						teamArray: teamArray,
					})
				);
			} else {
				console.log(error);
			}
		}
	}, [loading]);

	const getById = async (id) => {
		loadUserData({ variables: { id } });
		// setProfileDataLoading(true);
		// try {
		// 	let response = await services.getStartupDetails(id);
		// 	setProfileData(response?.data?.data);
		// } catch (error) {
		// 	setErrors(error?.response?.data ?? { error: "Unable to successfully retrieve startup profile by ID:" + id });
		// } finally {
		// 	setProfileDataLoading(false);
		// }
	};

	const getMyStartup = async (id) => {
		loadUserData({ variables: { id: null } }); //send null for own profile
		// setProfileDataLoading(true);
		// try {
		// 	let response = await services.getCompanyProfile();
		// 	setProfileData(response?.data?.data);
		// } catch (error) {
		// 	setErrors(error?.response?.data ?? { error: "Unable to successfully retrieve your startup profile." });
		// } finally {
		// 	setProfileDataLoading(false);
		// }
	};

	const getTeam = async (companyId) => {
		setTeamDataLoading(true);
		try {
			let response = await services.getCompanyUser(companyId);
			setTeamArray(response?.data?.data);
		} catch (error) {
			setErrors(error?.response?.data ?? { error: "Unable to successfully retrieve startup team by ID:" + companyId });
		} finally {
			setTeamDataLoading(false);
		}
	};

	const updateProfile = async (payload) => {
		setProfileDataLoading(true);
		try {
			const updatedTagList =
				payload?.companyTags?.length > 0
					? payload?.companyTags?.map((item) => {
							return Number(item?.scoreTag?.id);
					  })
					: [];
			let response = await services.updateCompanyProfile({ ...payload, companyTags: updatedTagList });
			if (response?.data?.message === "Success") {
				setProfileData(response?.data?.data);
			} else {
				setErrors({ error: "Unable to successfully update your startup profile." });
			}
		} catch (error) {
			setErrors(error?.response?.data ?? { error: "Unable to successfully update your startup profile." });
		} finally {
			setProfileDataLoading(false);
		}
	};

	const contextValue = {
		errors,
		viewContext,
		profileDataLoading,
		teamDataLoading,
		profileData,
		teamArray,
		setViewContext,
		setProfileDataLoading,
		setTeamDataLoading,
		setProfileData,
		setErrors,
		setTeamArray,
		getById,
		getMyStartup,
		getTeam,
		updateProfile,
		founderDetails,
		loading,
		partnerQA,
		setPartnerQA,
	};

	return <ProfileContext.Provider value={contextValue}>{children}</ProfileContext.Provider>;
};
