import * as React from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Chip, Box } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import {
  convertFormattedStringToDate,
  getCharDay,
  getCurrentDate,
  getCurrentTime,
} from "../../../../../utils/date";
import theme from "../../../../../constants/Theme";
import SwipeableEdgeDrawer from "../../../../../common/Drawer/index";

export default function MobileDatePopover({ responses, setResponses }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const contRef = React.useRef();

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleDelete = (time, date) => {
    const responsesData = [...responses];
    const responsesIndex = responsesData.findIndex(item => item.date === date);
    if (responsesIndex >= 0) {
      let element = responsesData[responsesIndex];
      const filteredData = element.times.filter(item => item !== time);
      if (filteredData.length === 0) {
        element.times = filteredData;
        responsesData.splice(responsesIndex, 1);
      } else {
        element.times = filteredData;
        responsesData[responsesIndex] = element;
      }

      if (!responsesData.length) {
        handleClearAll();
      } else {
        setResponses(responsesData);
      }
    }
  };

  const getSelectedTimesNo = () => {
    let number = 0;
    responses.forEach(element => {
      number += element.times.length;
    });
    return number;
  };

  const handleClearAll = () => {
    setResponses([]);
    handleClose();
  };
  const handleTimeDrawerClose = () => {
    handleClose();
  };
  return (
    <Box ref={contRef}>
      <Button
        aria-describedby={id}
        variant="nofill"
        onClick={handleClick}
        disabled={!responses.length}
        fullWidth
        sx={{ whiteSpace: "nowrap" }}
      >
        {responses.length ? (
          <>
            <ArrowDropUpIcon /> {getSelectedTimesNo()} Times selected{" "}
          </>
        ) : (
          "Select times to share"
        )}
      </Button>

      <SwipeableEdgeDrawer
        open={open}
        onClose={() => {
          handleTimeDrawerClose();
        }}
        container={contRef.current}
      >
        <Box
          sx={{
            p: 3,
            background: theme.palette.primary.surface3,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mt: 2,
              mb: 3,
            }}
          >
            <Typography variant="title_small" sx={{ whiteSpace: "nowrap" }}>
              Selected Times
            </Typography>
            <Box sx={{ ml: "auto" }}>
              <Button
                variant="nofill"
                sx={{ justifyContent: "end" }}
                onClick={handleClearAll}
              >
                Clear all
              </Button>
            </Box>
          </Box>

          <Box>
            {responses.map(item => (
              <Box sx={{ my: 2 }}>
                <Typography variant="title_small">
                  {getCharDay(convertFormattedStringToDate(item.date))}{" "}
                  {getCurrentDate(convertFormattedStringToDate(item.date))}
                </Typography>
                <br />
                <Box>
                  {item.times.map(time => {
                    return (
                      <Chip
                        label={getCurrentTime(time)}
                        variant="outlined"
                        sx={theme => ({
                          borderRadius: theme.shape.standard_small.borderRadius,
                          m: 1,
                        })}
                        onDelete={() => handleDelete(time, item.date)}
                        deleteIcon={<ClearIcon />}
                      />
                    );
                  })}
                </Box>
              </Box>
            ))}
            {
              <Box sx={{ textAlign: "center" }}>
                <Button sx={{ width: 1 }} onClick={handleClose}>
                  Close
                </Button>
              </Box>
            }
          </Box>
        </Box>
      </SwipeableEdgeDrawer>
    </Box>
  );
}
