import React from "react";
import { Grid, Box, Button } from "@mui/material";
import { LOGOICON } from "../../../../constants/image";
import styled from "@emotion/styled";
import { useHistory } from "react-router-dom";

const HeaderComp = styled.div(({ theme }) => {
  return {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      padding: "8px 48px",
    },
    [theme.breakpoints.down("md")]: {
      padding: "16px",
    },
  };
});
const HeaderContent = styled.div(({ theme }) => {
  return {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      justifyContent: "space-between",
      flexDirection: "row",
      marginBottom: "8px",
    },
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column-reverse",
      "& > div": {
        marginBottom: "16px",
      },
    },
  };
});

const NavigateButtons = styled.div(({ theme }) => {
  return {
    display: "flex",
    alignItems: "center",
    gap: "20px",
  };
});

const LOGO = styled.div(({ theme }) => {
  return {
    display: "flex",
    alignItems: "center",
    gap: "16px",
  };
});

export default function AppHeader(props) {
  const history = useHistory();
  return (
    <header className="main-header MuiAppBar-colorPrimary">
      {/* For desktop screens */}
      <HeaderComp
        sx={{
          display: { xs: "none", md: "block" },
        }}
      >
        <Box
          sx={{
            maxWidth: "1500px",
            width: "100%",
            display: { xs: "none", md: "block" },
          }}
        >
          <HeaderContent>
            <Box
              sx={{
                maxWidth: "180px",
                width: "100%",
              }}
            ></Box>
            <LOGO>
              <Grid item xs={4}>
                <Grid container justifyContent="center">
                  <img className="cursor-pointer" src={LOGOICON} alt="logo" />
                </Grid>
              </Grid>
            </LOGO>
            <NavigateButtons>
              <Grid
                container
                justifyContent="flex-end"
                alignItems="center"
                spacing={1}
              >
                <Grid item>
                  <Button
                    onClick={() => history.push("/login")}
                    variant="outlined"
                  >
                    Log in
                  </Button>
                </Grid>
                <Grid item>
                  <Button onClick={() => history.push("/onboarding")}>
                    Create Your Account
                  </Button>
                </Grid>
              </Grid>
            </NavigateButtons>
          </HeaderContent>
        </Box>
      </HeaderComp>

      {/* For mobile screens */}
      <Box
        sx={{
          pt: 2,
          px: 3,
          paddingBottom: "10px",
          display: { xs: "block", md: "none" },
        }}
      >
        <Box
          display={"grid"}
          sx={{
            gridTemplateColumns: "auto 1fr",
            gridTemplateRows: "1fr",
            gridTemplateAreas: `"logo login"`,
            columnGap: 2,
          }}
        >
          <Box
            sx={{
              gridArea: "logo",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <img
              className="cursor-pointer"
              src={LOGOICON}
              alt="logo"
              style={{ maxWidth: "100%" }}
            />
          </Box>
          <Box
            sx={{
              gridArea: "login",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Button
              sx={{ width: "auto", margin: 0 }}
              variant="outlined"
              onClick={() => history.push("/login")}
            >
              Log in
            </Button>
          </Box>
        </Box>
      </Box>
    </header>
  );
}
