import { Box, styled, Typography } from "@mui/material";
import React from "react";

export const CARD_ITEM = styled(Box)(({ background }) => ({
	display: "flex",
	alignItems: "center",
	background: background ? background : "rgba(123, 97, 255, 0.05)",
	padding: "10px 30px",
	borderRadius: "30px",
}));

function NoDataPill({ background }) {
	return (
		<CARD_ITEM background={background}>
			<Typography variant="card_text">No Data</Typography>
		</CARD_ITEM>
	);
}

export default NoDataPill;
