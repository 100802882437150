import services from "../services";

export default class userConnections {
  constructor() {
    this._list = [];
    this._isFirstPage = false;
    this._isLastPage = false;
    this._totalElements = 0;
    this._totalPages = 0;
    this._currentPage = 1;
    this._perPage = 10;
  }

  getList(page) {
    return new Promise((resolve, reject) => {
      services
        .getStartupConnections({ perPage: this._perPage, page: page })
        .then(resp => {
          if (resp.data?.data?.models) {
            this._list = resp.data.data.models || [];
            this._isLastPage = resp.data.data.isLast;
            this._isFirstPage = resp.data.data.isFirst;
            this._totalElements = resp.data.data.totalElements;
            this._totalPages = resp.data.data.totalPages;
            this._currentPage = resp.data.data.totalPages === 0 ? 0 : page;
          }
          resolve(true);
        })
        .catch(e => {
          console.log("Could not retrieve list of startup connections.", e);
          reject(e);
        });
    });
  }

  async getFirstPage() {
    if (!this._isFirstPage) {
      return await this.getList(1)
        .then(() => {
          return this._list;
        })
        .catch(e => {
          console.log("Couldn't get userConnections 1st page.", e);
          return [];
        });
    } else {
      return [];
    }
  }

  async getLastPage() {
    if (!this._isLastPage) {
      return await this.getList(this._totalPages)
        .then(() => {
          return this._list;
        })
        .catch(e => {
          console.log("Couldn't get userConnections last page.", e);
          return [];
        });
    } else {
      return [];
    }
  }

  async getNextPage() {
    if (!this._isLastPage) {
      return await this.getList(this._currentPage + 1)
        .then(() => {
          return this._list;
        })
        .catch(e => {
          console.log("Couldn't get userConnections next page.", e);
          return [];
        });
    } else {
      return [];
    }
  }

  async getPreviousPage() {
    if (!this._isFirstPage) {
      return await this.getList(this._currentPage - 1)
        .then(() => {
          return this._list;
        })
        .catch(e => {
          console.log("Couldn't get userConnections previous page.", e);
          return [];
        });
    } else {
      return [];
    }
  }
}
