import React, { useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import SelectInput from "../../../../common/SelectInput";
import FilterListIcon from "@mui/icons-material/FilterList";
import {
  TextField,
  Box,
  ListSubheader,
  Divider,
  Typography,
  OutlinedInput,
} from "@mui/material";
import theme from "../../../../constants/Theme";
import services from "../../../../services";
import { sosPrimary } from "../../../../constants/Theme/colors";
import SelectInputControlled from "../../../../common/SelectInput/SelectInputControlled";
import { handleMixPanel } from "../../../../utils/mixPanelEventHandling";

const ITEM_HEIGHT = 50;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 7 + ITEM_PADDING_TOP,
      borderRadius: theme.shape.standard_small.borderRadius,
    },
  },
};

const types = [
  {
    label: "Dot",
    value: "NOTE",
  },
  { label: "Quick Note", value: "QUICK_NOTE" },
];

export default function FilterSelect({ filter, setFilter }) {
  const [personName, setPersonName] = useState([]);
  const [tagsList, setTagsList] = useState([]);

  useEffect(() => {
    services
      .getNotesFilters()
      .then(res => {
        const data = res.data?.data?.tags || [];

        const tags = data.map(item => ({
          value: item.id,
          label: item.name,
          type: item.type,
        }));
        setTagsList(tags);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  const handleChange = event => {
    const {
      target: { value },
    } = event;

    var type = [];
    var filterTags = [];

    for (const item of value) {
      if (Number.isInteger(item)) {
        const itemData = tagsList.find(tag => tag.value === item);
        if (itemData) {
          filterTags.push(itemData.value);
        }
      } else {
        const itemData = types.find(tag => tag.value === item);
        if (itemData) {
          type.push(itemData.value);
        }
      }
    }

    setFilter({
      type: type.length ? type.join(",") : null,
      tags: filterTags.length ? filterTags.join(",") : null,
    });

    setPersonName(value);
  };

  return (
    <SelectInputControlled
      onInputChange={handleChange}
      multiple
      inputKey="filter"
      displayEmpty
      wrapperstyle={{ width: "100%" }}
      MenuProps={MenuProps}
      selectedValue={personName}
      //input={<TextField />}
      renderValue={selected => {
        if (selected.length === 0) {
          return (
            <Box sx={{ my: 0, py: 0 }} display={"flex"}>
              <FilterListIcon sx={{ mr: 1 }} fontSize="small" />
              Filters
            </Box>
          );
        }

        let labels = [];

        for (const item of selected) {
          if (Number.isInteger(item)) {
            const itemData = tagsList.find(tag => tag.value === item);
            if (itemData) {
              labels.push(itemData.label);
            }
          } else {
            const itemData = types.find(tag => tag.value === item);
            if (itemData) {
              labels.push(itemData.label);
            }
          }
        }

        return (
          <Box sx={{ display: "flex" }}>
            <FilterListIcon sx={{ mr: 1 }} fontSize="small" />{" "}
            <Typography
              sx={{
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              {labels.join(", ")}
            </Typography>{" "}
          </Box>
        );
      }}
      inputProps={{ "aria-label": "Without label" }}
      InputProps={{}}
    >
      <ListSubheader
        sx={theme => ({
          lineHeight: "40px",
        })}
        disabled
      >
        <Typography variant="overlineLarge"> TYPE</Typography>
      </ListSubheader>
      {types.map((type, index) => (
        <MenuItem
          onClick={() => { 
            if (type.label === "Quick Note") {
              handleMixPanel("Discovery Board Filtered", {
                "Filter Method": "Quick Note"
              });
            } else {
              handleMixPanel("Discovery Board Filtered", {
                "Filter Method": "Dot"
              });
            }
          }}
          key={type.value}
          value={type.value}
          sx={{
            px: 0,
            py: 1,
            "&.Mui-selected:hover": {
              backgroundColor: sosPrimary[25],
            },
            "&.Mui-focusVisible": {
              backgroundColor: sosPrimary[25],
            },
            "&.Mui-selected:active": {
              backgroundColor: sosPrimary[25],
            },
            "&.Mui-selected:focus": {
              backgroundColor: sosPrimary[25],
            },
          }}
        >
          <Checkbox
            checked={personName.indexOf(type.value) > -1}
            sx={{ px: 2 }}
          />
          <ListItemText primary={type.label} />
        </MenuItem>
      ))}

      <Divider sx={{ my: 2 }} />
      <ListSubheader
        sx={theme => ({
          lineHeight: "40px",
        })}
      >
        <Typography variant="overlineLarge"> TAG</Typography>
      </ListSubheader>
      {tagsList.map((type, index) => (
        <MenuItem
          onClick={() => { 
            handleMixPanel("Discovery Board Filtered", {
              "Filter Method": "Tags"
            });
          }}
          key={type.value}
          value={type.value}
          sx={{
            wordBreak: "break-word",
            px: 0,
            py: 1,
            "&.Mui-selected:hover": {
              backgroundColor: sosPrimary[25],
            },
            "&.Mui-selected:active": {
              backgroundColor: sosPrimary[25],
            },
            "&.Mui-selected:focus": {
              backgroundColor: sosPrimary[25],
            },
          }}
        >
          <Checkbox
            checked={personName.indexOf(type.value) > -1}
            sx={{ px: 2 }}
          />
          <ListItemText primary={type.label} />
        </MenuItem>
      ))}
    </SelectInputControlled>
  );
}
