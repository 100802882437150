import React, { useEffect, useState } from "react";
import { useLocation, useHistory, matchPath } from "react-router-dom";
import {
	Grid,
	Box,
	Typography,
	Button,
	TextField,
	Divider,
	CircularProgress,
	MenuItem,
	Autocomplete,
	useMediaQuery,
	Stack,
	Badge,
	Avatar,
} from "@mui/material";
import theme from "../../../constants/Theme";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import FileDownloadDoneOutlinedIcon from "@mui/icons-material/FileDownloadDoneOutlined";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import SelectInput from "../../../common/SelectInput";
import MentorExperience from "../../../components/Mentor/Profile/MentorExperience";
import CloseIcon from "@mui/icons-material/Close";
import AutocompleteInput from "../../../common/AutocompleteInput";
import messages from "../../../constants/messages";
import LoadingButton from "../../../common/Loadingbutton";
import RichTextEditor from "../../../common/RichTextEditor";
import { stateToMarkdown } from "draft-js-export-markdown";
import { RICH_TEXT_EDITOR_INLINE_CONTROLS, RICH_TEXT_EDITOR_MAIN_CONTROLS } from "../../../constants/plugin-constants";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";
import { EditorState, ContentState } from "draft-js";
import { INDUSTRY_FOCUS, TYPE_OF_INVESTOR, FOUNDER_SUPER_POWER_DATA } from "../../../constants/profile-data";
import TextInputField from "../../../common/TextInputField";
import ScriptEditor from "../../../common/ScriptEditor";
import MessageHandlerModal from "../../../common/modal/MessageHandler";
import { EXPERTISE_LIST, INDUSTRY_LIST, PRODUCT_OFFERING_LIST } from "../../../constants/updated-onboarding";
import DialogComponent from "../../../common/CommonDialog";
import CustomMuiChip from "../../../common/CustomMuiChip";
import ProfileProgress from "../../../common/ProfileProgress";
import CompleteYourProfile from "../../../common/CompleteYourProfile";
import { MultiSelectInput } from "../../../common/MultiSelect";
import AutocompleteNew from "../../../common/AutocompleteNew";
import UploadImageModal from "../../../common/UploadImageModal";
import { COVER_DEFAULT_IMAGE, CAMERA_ICON } from "../../../constants/image";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { SmallAvatar } from "../../../components/Mentor/Profile/styles";
import VideoPlayerComponent from "../../../common/videoPlayer";
import AddVideo from "../../../common/AddVideo";
import UploadVideoModal from "../../../common/UploadVideoModal";
import VideocamRoundedIcon from "@mui/icons-material/VideocamRounded";
import { ConstructionOutlined } from "@mui/icons-material";
import RouterPrompt from "../../../common/RouterPrompt";
import ROUTES_PATH from "../../../constants/routes";
import StartupOSCoPilot from "./StartupOSCoPilot";
import StateSelectInput from "../../../common/StateSelectInput";
import AvatarWithFallBack from "../../../common/AvatarWithFallBack";
import { accountProfileProgress } from "../../../utils/profilePercentageCalculation";

function EditProfile({
	handleBlur,
	mentorError,
	saveDisable,
	handleCloseForm,
	handleUpdateUser,
	handleAISave,
	handleChange,
	compLocation,
	handleClear,
	payload,
	expertise,
	handleUserDetails,
	saveLoading,
	handleUploadAction,
	setMentorError,
	imgLoading,
	vidLoading,
	browseFlag,
	browseVidFlag,
	inputValid,
	setSaveDisable,
	setShowDeleteButton,
	inputChange,
	openVideoUpload,
	setOpenVideoUpload,
	handleDeleteUploadAttachment,
	handleDeleteImageUploadAttachment,
	setBrowseVidFlag,
	setBrowseFlag,
	handleUpdateAssetsUrl,
	handleGetHarmonicData,
	setHarmonicOpen,
	discard,
	harmonicUpdate,
	setHarmonicUpdate,
	stateOptions,
}) {
	let FileType = {
		img: ".png, .jpg, .jpeg",
		video: ".mp4, .3gp, .avi, .webm, .ogg, .wmv, .mkv",
	};

	const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
	const [aboutText, setAboutText] = useState("");
	const [confirmPopup, setConfirmPopup] = useState(false);
	const [maxFile, setMaxFile] = useState(false);
	const [version, setVersion] = useState(1);
	const [attachmentName, setAttachmentName] = useState("");
	const [lastImageSegment, setLastImageSegment] = useState("");
	const [lastVideoSegment, setLastVideoSegment] = useState("");
	const [openBackgroundPhotoModal, setOpenBackgroundPhotoModal] = useState(false);
	const [openProfilePhotoModal, setOpenProfilePhotoModal] = useState(false);
	const [backgroundPhoto, setBackgroundPhoto] = useState(payload?.coverImgUrl);
	const [profilePhoto, setProfilePhoto] = useState();
	const [openVideoModal, setOpenVideoModal] = useState(false);
	const [videoUploadUrl, setVideoUploadUrl] = useState(payload?.videoUrl);
	const [profileCompletion, setProfileCompletion] = useState(Math.round(accountProfileProgress(payload).totalPercentage || 0));

	const location = useLocation();
	const history = useHistory();
	const [currentPath, setCurrentPath] = useState();

	useEffect(() => {
		setCurrentPath(location.pathname);
	}, []);

	const shouldShowPrompt = () => {
		return discard;
	};

	//useEffect(() => {
	//  history.block(prompt => {
	//    if (prompt.pathname != currentPath) {
	//      setCurrentPath(prompt.pathname);
	//      setConfirmPopup(true);
	//      return "true";
	//    } else {
	//      history.block(() => {});
	//      return false;
	//    }
	//  });
	//}, [history]);

	const getUrlParams = (url) => {
		const finalUrl = url;
		return finalUrl.split("/").pop();
	};

	useEffect(() => {
		if (payload?.picture) {
			setProfilePhoto(payload?.picture);
		}
		setProfileCompletion(Math.round(accountProfileProgress(payload).totalPercentage || 0))
	}, [payload]);

	useEffect(() => {
		if (payload?.videoUrl) {
			setLastVideoSegment(getUrlParams(payload?.videoUrl));
		} else {
			setBrowseVidFlag(false);
		}
	}, []);

	useEffect(() => {
		if (payload?.picture) {
			setLastImageSegment(getUrlParams(payload?.["picture"]));
		} else {
			setBrowseFlag(false);
		}
	}, []);

	useEffect(() => {
		setAboutText(payload?.[`about`]);
		setVersion((version) => version + 1);
	}, []);
	const getSelectedExpertiseFormatted = (input) => {
		if (input) {
			try {
				let inputParsed = JSON.parse(input);
				if (Array.isArray(inputParsed)) {
					return inputParsed;
				} else {
					return [inputParsed];
				}
			} catch (error) {
				//return [input];
				return input.split(",");
			}
		} else {
			return [];
		}
	};
	const handleAboutChange = (value) => {
		if (version > 1) {
			setAboutText(value.value);
			handleChange(value.value, "about");
			inputChange();
		}
	};

	const handleUploadClose = () => {
		setOpenVideoUpload(false);
	};

	const handleRequestCancelConfirm = (action) => {
		//if (action === "cancel") {
		//  handleCloseForm();
		//} else {
		//  history.block(() => {});
		//  history.push(currentPath);
		//}
		handleCloseForm();
		setConfirmPopup(false);
		setSaveDisable(true);
	};

	const handleCancel = () => {
		if (!saveDisable) {
			setConfirmPopup(true);
			setShowDeleteButton(false);
		} else {
			handleCloseForm();
		}
	};

	const handleFileChange = (type) => (event) => {
		const file = event.target.files[0];
		setAttachmentName(file?.name);
		const fileSize = (file?.size / 1024 / 1024).toFixed(2);

		if (file === undefined) {
			handleUploadAction("", "");
			if (type == "img") {
				setMentorError((prevState) => ({
					...prevState,
					logoError: "Please attach a logo",
				}));
			} else if (type == "video") {
				setMentorError((prevState) => ({
					...prevState,
					videoError: "Please attach a video",
				}));
			}
		} else if (fileSize >= 100) {
			setMaxFile(true);
		} else {
			setMaxFile(false);
			setOpenVideoUpload(true);
			handleUploadAction(file, type);
			setSaveDisable(false);
		}
	};

	return (
		<Box sx={{ px: { lg: 6, md: 2, xs: 0 } }}>
			<RouterPrompt when={shouldShowPrompt} stepPath={""} />
			<Grid container spacing={2} sx={{ px: { md: 0 }, pb: { md: "30px" } }}>
				<UploadImageModal
					open={openBackgroundPhotoModal}
					onClose={() => setOpenBackgroundPhotoModal(false)}
					photo={backgroundPhoto}
					setPhoto={setBackgroundPhoto}
					title="Background Photo"
					selectFileText="Select a file to upload background image"
					dimensionText="Recommended dimensions 1400px x 350px (Max 2GB)"
					aspectRatio={4 / 1}
					imageType="BACKGROUND_PHOTO"
					fileType={{ "image/png": [], "image/jpg": [], "image/jpeg": [] }}
					circularCropper={false}
					handleUpdateAssetsUrl={handleUpdateAssetsUrl}
				/>
				<UploadImageModal
					open={openProfilePhotoModal}
					photo={profilePhoto}
					onClose={() => setOpenProfilePhotoModal(false)}
					setPhoto={setProfilePhoto}
					title="Profile Photo"
					selectFileText="Select a file to upload profile image"
					dimensionText="Recommended dimensions 240px x 240px (Max 2GB)"
					aspectRatio={1 / 1}
					imageType="PROFILE_PHOTO"
					fileType={{ "image/png": [], "image/jpg": [], "image/jpeg": [] }}
					circularCropper={true}
					handleUpdateAssetsUrl={handleUpdateAssetsUrl}
				/>
				<UploadVideoModal
					open={openVideoModal}
					photo={videoUploadUrl}
					onClose={() => setOpenVideoModal(false)}
					setPhoto={setVideoUploadUrl}
					title="My Story Video"
					selectFileText="Select a file to upload My Story video"
					dimensionText="Accepted file types .mp4, .mov, .3gp, .webm, .ogg (Max 2GB)"
					aspectRatio={1 / 1}
					imageType="USER_STORY"
					fileType={{
						"video/3gpp": [".3gp"],
						"video/mp4": [".mp4"],
						"video/mov": [".mov"],
						"video/webm": [".webm"],
						"video/ogg": [".ogg"],
					}}
					handleUpdateAssetsUrl={handleUpdateAssetsUrl}
				/>
				<Grid
					item
					xs={12}
					md={12}
					sx={{
						display: "flex",
						width: "100%",
						flexDirection: "row",
						justifyContent: "space-between!important",
					}}
				>
					<Typography variant="headline3" pt={2}>
						Edit My Profile
					</Typography>
				</Grid>

				<Grid item xs={12} md={12}>
					<ProfileProgress percentage={profileCompletion} />
				</Grid>

				<Grid item xs={12} md={12} mt={3}>
					<Box sx={{ position: "relative", pt: 3 }}>
						{!matchPath(location.pathname, { path: ROUTES_PATH.EDIT_MY_ACCOUNT_PROFILE }) && (
							<Box
								textAlign={"center"}
								sx={(theme) => ({
									mb: 1,
								})}
							>
								<img
									style={{
										width: "100%",
										borderRadius: "8px",
									}}
									src={backgroundPhoto ? backgroundPhoto : COVER_DEFAULT_IMAGE}
								/>
							</Box>
						)}
						<Box>
							<Grid container>
								<Grid item xs={6}>
									<Box
										sx={{
											pl: { xs: 2, md: 4 },
											position: "absolute",
											bottom: "-80px",
										}}
									>
										<Badge
											overlap="circular"
											anchorOrigin={{
												vertical: "bottom",
												horizontal: "right",
											}}
											badgeContent={
												<SmallAvatar
													onClick={() => setOpenProfilePhotoModal(true)}
													src={CAMERA_ICON}
													sx={{
														height: "40px",
														width: "40px",
														border: "none",
														cursor: "pointer",
													}}
												/>
											}
										>
											<AvatarWithFallBack
												src={profilePhoto}
												sx={{
													height: "120px",
													width: "120px",
													border: !profilePhoto ? "none" : `3px solid ${theme.palette.background.paper}`,
													borderRadius: "100px",
												}}
											/>
										</Badge>
									</Box>
								</Grid>
								{!matchPath(location.pathname, { path: ROUTES_PATH.EDIT_MY_ACCOUNT_PROFILE }) && (
									<Grid item xs={6}>
										{!isSmallScreen ? (
											<Box
												sx={{
													width: "max-content",
													mr: { xs: 1, md: 3 },
													mb: 3,
													background:
														"linear-gradient(0deg, rgba(106, 117, 117, 0.02), rgba(106, 117, 117, 0.02)), linear-gradient(0deg, rgba(245, 248, 250, 0.05), rgba(245, 248, 250, 0.05)), #FCFCFC",
													boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
													borderRadius: "4px",
													position: "absolute",
													bottom: "10px",
													right: 0,
												}}
											>
												<Button
													variant="outlined"
													startIcon={<PhotoCameraIcon />}
													onClick={() => {
														setOpenBackgroundPhotoModal(true);
													}}
												>
													Edit cover image
												</Button>
											</Box>
										) : (
											<Box
												sx={{
													width: "max-content",
													mr: { xs: 1, md: 3 },
													mb: 1,
													position: "absolute",
													bottom: "10px",
													right: 0,
												}}
											>
												<PhotoCameraIcon
													sx={(theme) => ({
														color: theme.palette.primary.main,
														background: "#fff",
														p: 1,
														borderRadius: "100px",
													})}
													onClick={() => {
														setOpenBackgroundPhotoModal(true);
													}}
												/>
											</Box>
										)}
									</Grid>
								)}
							</Grid>
						</Box>
						<Box
							sx={{
								display: "flex",
								flexDirection: { xs: "column-reverse", md: "row" },
								gap: 2,
								float: "right",
								alignItems: "center",
								display: { xs: "none", md: "flex" },
								mt: 5,
							}}
						>
							<StartupOSCoPilot handleSave={handleUpdateUser} handleAISave={handleAISave} saveLoading={saveLoading} saveDisabled={saveDisable} />

							<CloseIcon
								onClick={handleCancel}
								sx={{
									fill: theme.palette.secondary.main,
									cursor: "pointer",
								}}
							/>
							<MessageHandlerModal
								isOpen={confirmPopup}
								onCancel={() => setConfirmPopup(false)}
								onOk={() => handleRequestCancelConfirm("cancel")}
								okText="Ok"
								cancelText={"Cancel"}
								heading="Confirm"
								text={"Are you sure you want to abandon your changes?"}
								messageType="primary"
							/>
						</Box>
					</Box>
				</Grid>

				<Grid item xs={12} md={12}>
					<Box
						sx={{
							pt: { xs: 7, md: 1 },
							pl: { xs: 0, md: 4 },
							pr: { xs: 0, md: 3 },
						}}
					>
						<Divider
							sx={{
								mt: 3,
								borderColor: "secondary.light",
							}}
						/>
					</Box>
				</Grid>

				<Grid item xs={12} md={12} sx={{ mt: 2 }}>
					<Box sx={{ pl: { xs: 0, md: 4 } }}>
						<Typography variant="subhead6">Profile Intro</Typography>
					</Box>
				</Grid>
				<Grid item xs={12} md={12}>
					{/*{uploadButton("img")}*/}
					{/*{browseFlag ? (
              <Box sx={{ width: "max-content", mb: 2, mt: "-10px" }}>
                <CustomMuiChip
                  startIcon={
                    <ImageOutlinedIcon
                      sx={theme => ({
                        fill: theme.palette.primary.main,
                      })}
                    />
                  }
                  endIcon={<CloseIcon style={{ marginLeft: "auto" }} />}
                  label={
                    <Typography
                      variant="onsurface_subtext_medium"
                      sx={theme => ({
                        color: theme.palette.secondary.main,
                      })}
                    >
                      {`${attachmentName}`
                        ? `${attachmentName}`
                        : `${lastImageSegment}`}
                    </Typography>
                  }
                  onDelete={handleDeleteImageUploadAttachment}
                />
              </Box>
            ) : (
              <Button
                variant="outlined"
                startIcon={
                  !imgLoading &&
                  (browseFlag ? (
                    <FileDownloadDoneOutlinedIcon />
                  ) : (
                    <FileUploadOutlinedIcon />
                  ))
                }
                sx={{
                  width: "max-content",
                  mb: 2,
                }}
                component="label"
              >
                Upload
                <input
                  type="file"
                  accept={FileType["img"]}
                  hidden
                  multiple={false}
                  onChange={handleFileChange("img")}
                />
              </Button>
            )}*/}
				</Grid>
				<Grid item xs={12} md={6}>
					<Box sx={{ pl: { xs: 0, md: 4 } }}>
						<TextInputField
							label={"First Name*"}
							placeholder={"First Name"}
							required
							inputKey={"firstName"}
							handleBlur={handleBlur}
							handleChange={handleChange}
							helperText={mentorError.firstNameError}
							value={payload?.[`firstName`]}
							maxLength={"25"}
							minLength={"2"}
						/>
					</Box>
				</Grid>
				<Grid item xs={12} md={6}>
					<Box sx={{ pr: { xs: 0, md: 3 } }}>
						<TextInputField
							label={"Last Name*"}
							placeholder={"Last Name"}
							required
							handleBlur={handleBlur}
							handleChange={handleChange}
							helperText={mentorError.lastNameError}
							value={payload?.[`lastName`]}
							inputKey={"lastName"}
							maxLength={"25"}
							minLength={"2"}
						/>
					</Box>
				</Grid>
				<Grid item xs={12} md={12}>
					<Box sx={{ pl: { xs: 0, md: 4 }, pr: { xs: 0, md: 3 } }}>
						<TextInputField
							label={"Tagline"}
							maxLength={"100"}
							handleBlur={handleBlur}
							handleChange={handleChange}
							helperText={mentorError.headlineError}
							value={payload?.[`headline`]}
							inputKey={"headline"}
						/>
					</Box>
				</Grid>
				<Grid item xs={12} md={12}>
					<Box sx={{ pl: { xs: 0, md: 4 }, pr: { xs: 0, md: 3 } }}>
						<TextInputField
							label={"Position"}
							handleBlur={handleBlur}
							handleChange={handleChange}
							//helperText={mentorError.professionError}
							value={payload?.[`profession`]}
							inputKey={"profession"}
						/>
					</Box>
				</Grid>
				<Grid
					item
					xs={12}
					md={12}
					sx={{
						display: "flex",
						flexDirection: "row",
						gap: 3,
						pr: { xs: 0, md: 3 },
					}}
				>
					<Grid item xs={12} md={4} sx={{ pl: { xs: 0, md: 4 } }}>
						<TextInputField
							label={"City*"}
							placeholder={"City"}
							handleBlur={handleBlur}
							handleChange={handleChange}
							helperText={mentorError.cityError}
							value={payload?.[`city`]}
							// handleClick={handleClear}
							//maxLength={"5"}
							//minLength={"5"}
							inputKey={"city"}
							required
						/>
					</Grid>
					<Grid item xs={12} md={4}>
						<StateSelectInput
							label={"State*"}
							placeholder={"State"}
							required
							handleBlur={handleBlur}
							handleChange={handleChange}
							helperText={mentorError.stateError}
							value={payload?.[`state`]}
							inputKey={"state"}
							id="account-profile-state-dropdown"
							options={stateOptions}
							// options={stateOptions?.length ? stateOptions : [...US_STATE_CODES, ...CANADA_PROVINCE_CODES]}
						>
							{stateOptions?.map((option) => (
								<MenuItem key={option.stateCode} value={option.stateCode}>
									{option.stateName}
								</MenuItem>
							))}
						</StateSelectInput>
					</Grid>
					<Grid item xs={12} md={4}>
						<TextInputField
							label={"Zip Code*"}
							required
							handleBlur={handleBlur}
							handleChange={handleChange}
							helperText={mentorError.zipCodeError}
							value={payload[`zipcode`]}
							// handleClick={handleClear}
							//maxLength={"5"}
							//minLength={"5"}
							inputKey={"zipcode"}
						/>
					</Grid>
				</Grid>

				{/* Right */}
				{/*<Grid item xs={12} md={6}>
            <Grid container spacing={3}>
              
              <Grid item xs={12} md={12} onClick={inputChange}>
                <TextInputField
                  label="Personal Website/Portfolio"
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  helperText={mentorError.websiteError}
                  value={payload[`website1`]}
                  inputKey={"website1"}
                />
              </Grid>
            </Grid>
          </Grid>*/}

				<Grid item xs={12} md={12}>
					<Box sx={{ pl: { xs: 0, md: 4 }, pr: { xs: 0, md: 3 } }}>
						<Divider
							sx={{
								mt: 3,
								borderColor: "secondary.light",
							}}
						/>
					</Box>
				</Grid>

				<Grid item xs={12} md={7} sx={{ display: "flex", flexDirection: "column" }}>
					<Grid item xs={12} md={12}>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								gap: 2,
								mt: 1,
								pl: { xs: 0, md: 4 },
							}}
						>
							<Typography variant="largeTitle">About</Typography>
							{/*<Box
                  sx={theme => ({
                    minHeight: "400px",
                    border: `1px solid ${theme.palette.containerBorder.lightOutline}`,
                    px: 2,
                    //mt: 2,
                    borderRadius: theme.shape.standard.borderRadius,
                  })}
                  onClick={inputChange}
                >
                  <ScriptEditor
                    version={version}
                    minHeight="400px"
                    label={"About..."}
                    initialValue={aboutText}
                    onChange={value => handleAboutChange(value)}
                    readOnly={false}
                    toolbar={true}
                    inlineToolbar={false}
                    inlineToolbarControls={RICH_TEXT_EDITOR_INLINE_CONTROLS}
                    controls={RICH_TEXT_EDITOR_MAIN_CONTROLS}
                    showValidatorIcon
                  />
                </Box>*/}

							<Box
								sx={(theme) => ({
									// minHeight: "300px",
									// maxHeight: "400px",

									border: `1px solid ${theme.palette.containerBorder.lightOutline}`,
									px: 2,
									mt: 4,
									borderRadius: theme.shape.standard.borderRadius,
								})}
							>
								<ScriptEditor
									version={version}
									minHeight={"300px"}
									label={"About..."}
									initialValue={aboutText}
									onChange={(value) => handleAboutChange(value)}
									readOnly={false}
									toolbar={true}
									inlineToolbar={false}
									inlineToolbarControls={RICH_TEXT_EDITOR_INLINE_CONTROLS}
									controls={RICH_TEXT_EDITOR_MAIN_CONTROLS}
									showValidatorIcon
								/>
							</Box>
						</Box>
						<Box sx={{ pl: { xs: 0, md: 4 }, pt: 2 }}>
							{!videoUploadUrl && (
								<>
									<Box sx={{ mt: 2 }}>
										<Box>
											<Typography
												variant="Body/16pxBold"
												sx={(theme) => ({
													color: theme.palette.secondary.korophKoal,
												})}
											>
												My Story
											</Typography>
										</Box>
										<Box sx={{ pt: 1 }}>
											<Typography
												variant="body_large"
												sx={(theme) => ({
													color: theme.palette.secondary.outline,
												})}
											>
												Upload a video about your story so other StartupOS members can get to know you better.
											</Typography>
										</Box>
									</Box>
									<AddVideo
										height={400}
										handelAddMedia={() => {
											setOpenVideoModal(true);
										}}
									/>
								</>
							)}

							{videoUploadUrl && (
								<>
									<Box sx={{ mt: 2 }}>
										<Box>
											<Typography
												variant="Body/16pxBold"
												sx={(theme) => ({
													color: theme.palette.secondary.korophKoal,
												})}
											>
												My Story
											</Typography>
										</Box>
										<Box sx={{ pt: 1 }}>
											<Typography
												variant="body_large"
												sx={(theme) => ({
													color: theme.palette.secondary.outline,
												})}
											>
												Upload a video about your story so other StartupOS members can get to know you better.
											</Typography>
										</Box>
									</Box>

									<Box sx={{ position: "relative" }}>
										<Box
											sx={(theme) => ({
												borderRadius: theme.shape.standard.borderRadius,
												boxShadow: "none",
												overflow: "hidden",
												mt: 3,
											})}
										>
											<Box>
												<VideoPlayerComponent height="auto" videoUrl={videoUploadUrl} />
											</Box>
										</Box>
										<Box
											sx={{
												mr: 3,
												background:
													"linear-gradient(0deg, rgba(106, 117, 117, 0.02), rgba(106, 117, 117, 0.02)), linear-gradient(0deg, rgba(245, 248, 250, 0.05), rgba(245, 248, 250, 0.05)), #FCFCFC",
												boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
												borderRadius: "4px",
												width: "max-content",
												position: "absolute",
												top: "24px",
												right: 0,
											}}
										>
											<Button variant="outlined" startIcon={<VideocamRoundedIcon />} onClick={() => setOpenVideoModal(true)}>
												{videoUploadUrl ? "Edit Media" : "Add media"}
											</Button>
										</Box>
									</Box>
								</>
							)}
						</Box>
					</Grid>

					{/* <Grid item xs={12} md={12} sx={{ pt: 5, pl: { xs: 0, md: 4 } }}>
							{!videoUploadUrl && (
								<>
									<Box sx={{ mt: 2 }}>
										<Box>
											<Typography
												variant="Body/16pxBold"
												sx={(theme) => ({
													color: theme.palette.secondary.korophKoal,
												})}
											>
												My Story
											</Typography>
										</Box>
										<Box sx={{ pt: 1 }}>
											<Typography
												variant="body_large"
												sx={(theme) => ({
													color: theme.palette.secondary.outline,
												})}
											>
												Upload a video about your story so other StartupOS members can get to know you better.
											</Typography>
										</Box>
									</Box>
									<AddVideo
										height={400}
										handelAddMedia={() => {
											setOpenVideoModal(true);
										}}
									/>
								</>
							)}

							{videoUploadUrl && (
								<>
									<Box sx={{ mt: 2 }}>
										<Box>
											<Typography
												variant="Body/16pxBold"
												sx={(theme) => ({
													color: theme.palette.secondary.korophKoal,
												})}
											>
												My Story
											</Typography>
										</Box>
										<Box sx={{ pt: 1 }}>
											<Typography
												variant="body_large"
												sx={(theme) => ({
													color: theme.palette.secondary.outline,
												})}
											>
												Upload a video about your story so other StartupOS members can get to know you better.
											</Typography>
										</Box>
									</Box>

									<Box sx={{ position: "relative" }}>
										<Box
											sx={(theme) => ({
												borderRadius: theme.shape.standard.borderRadius,
												boxShadow: "none",
												overflow: "hidden",
												mt: 3,
											})}
										>
											<Box>
												<VideoPlayerComponent height="auto" videoUrl={videoUploadUrl} />
											</Box>
										</Box>
										<Box
											sx={{
												mr: 3,
												background:
													"linear-gradient(0deg, rgba(106, 117, 117, 0.02), rgba(106, 117, 117, 0.02)), linear-gradient(0deg, rgba(245, 248, 250, 0.05), rgba(245, 248, 250, 0.05)), #FCFCFC",
												boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
												borderRadius: "4px",
												width: "max-content",
												position: "absolute",
												top: "24px",
												right: 0,
											}}
										>
											<Button variant="outlined" startIcon={<VideocamRoundedIcon />} onClick={() => setOpenVideoModal(true)}>
												{videoUploadUrl ? "Edit Media" : "Add media"}
											</Button>
										</Box>
									</Box>
								</>
							)}
						</Grid> */}
					{/*<Grid item xs={12} md={12}>
              <Grid item xs={12} md={12} sx={{ mt: 3 }}>
                <Typography variant="title_medium">Video</Typography>
              </Grid>
              {maxFile && (
                <Box sx={{ pt: 1 }}>
                  <Typography
                    variant="caption"
                    sx={theme => ({ color: theme.palette.error.main })}
                  >
                    Maximum upload file size : 100 MB
                  </Typography>
                </Box>
              )}
              <Grid item xs={12} md={6}>
                {/*{uploadButton("video")}
                {browseVidFlag ? (
                  <Box sx={{ width: "max-content" }}>
                    <CustomMuiChip
                      startIcon={
                        <ImageOutlinedIcon
                          sx={theme => ({
                            fill: theme.palette.primary.main,
                          })}
                        />
                      }
                      endIcon={<CloseIcon style={{ marginLeft: "auto" }} />}
                      label={
                        <Typography
                          variant="onsurface_subtext_medium"
                          sx={theme => ({
                            color: theme.palette.secondary.main,
                          })}
                        >
                          {`${lastVideoSegment}`
                            ? `${lastVideoSegment}`
                            : `${attachmentName}`}
                        </Typography>
                      }
                      onDelete={handleDeleteUploadAttachment}
                      mt={0}
                    />
                  </Box>
                ) : (
                  <Button
                    variant="outlined"
                    startIcon={
                      !vidLoading &&
                      (browseVidFlag ? (
                        <FileDownloadDoneOutlinedIcon />
                      ) : (
                        <FileUploadOutlinedIcon />
                      ))
                    }
                    sx={{
                      width: "max-content",
                      mb: 2,
                      mt: 2,
                    }}
                    component="label"
                  >
                    {/*{vidLoading ? (
                      <CircularProgress
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          maxWidth: "30px",
                          px: 3,
                        }}
                      />
                    ) : browseVidFlag ? (
                      "Uploaded"
                    ) : (
                      "Upload"
                    )}
                    Upload
                    <input
                      type="file"
                      accept={FileType["video"]}
                      hidden
                      multiple={false}
                      onChange={handleFileChange("video")}
                    />
                  </Button>
                )}

                <DialogComponent
                  isOpen={openVideoUpload}
                  onClose={handleUploadClose}
                  title=""
                  titleVariant="title_medium"
                  maxWidth="xs"
                  dialogAction={() => {}}
                  contentStyle={{ px: 0 }}
                  footerStyle={{
                    border: "none",
                  }}
                  PaperProps={{
                    style: {
                      borderRadius: 8,
                      width: "100%",
                    },
                  }}
                  disableBackDropClick
                >
                  <Box
                    sx={theme => ({
                      p: 2,
                      pb: 0,
                    })}
                  >
                    <Box
                      sx={theme => ({
                        display: "flex",
                        justifyContent: "center",
                      })}
                    >
                      <CircularProgress
                        sx={theme => ({
                          height: "80px",
                          width: "80px",
                          color: theme.palette.text.darkBlue,
                        })}
                      />
                    </Box>
                    <Box
                      sx={theme => ({
                        textAlign: "center",
                        pt: 5,
                      })}
                    >
                      <Typography variant="title_medium">
                        File upload in progress
                      </Typography>
                    </Box>
                  </Box>
                </DialogComponent>
              </Grid>
            </Grid>*/}
				</Grid>

				<Grid item xs={12} md={5} sx={{ display: "flex", flexDirection: "row", pr: { xs: 0, md: 3 } }}>
					<Divider orientation="vertical" sx={{ display: { xs: "none", md: "flex" }, mx: 1 }} />
					<Grid item xs={12} md={12}>
						<Box
							sx={{
								pl: {
									md: 1,
								},
								gap: 3,
								display: "flex",
								flexDirection: "column",
							}}
						>
							<Grid item xs={12} md={12}>
								<Typography variant="largeTitle">Details</Typography>
							</Grid>
							<Grid item xs={12} md={12} onClick={inputChange}>
								<TextInputField
									label="LinkedIn Profile"
									handleBlur={handleBlur}
									handleChange={handleChange}
									helperText={mentorError?.linkedInError}
									value={payload?.linkedInUrl ? payload?.linkedInUrl?.replace(/https:\/\/www\.linkedin\.com\/in\//g, "") || "" : ""}
									inputKey={"linkedInUrl"}
									InputProps={{
										startAdornment: <Typography>https://www.linkedin.com/in/</Typography>,
									}}
								/>
								{payload[`linkedInUrl`] && (
									<Box sx={{ mt: 1 }}>
										<Button variant="outlined" onClick={() => setHarmonicOpen(true)}>
											Copy profile from Linkedin
										</Button>
									</Box>
								)}
							</Grid>
							{payload[`linkedInUrl`] === "" && (
								<Grid item xs={12} md={12} sx={{ pb: 3 }}>
									<CompleteYourProfile
										title={"Complete your profile faster"}
										sub_title={
											"Providing your startup’s LinkedIn profile link will autofill key information here needed to complete your profile."
										}
									/>
								</Grid>
							)}

							<Grid item xs={12} md={12} onClick={inputChange}>
								{/*<MultiSelectInput
                    label={"Startup Superpowers*"}
                    handleChange={handleChange}
                    options={FOUNDER_SUPER_POWER_DATA}
                    inputKey={"superpower"}
                    errorMessage={mentorError.startupExperience}
                    inputValid={inputValid.startupExperience}
                    helpText={"*required"}
                    fullWidth={true}
                    selectedValue={
                      payload["superpower"]
                        ? payload[
                            "superpower"
                          ]
                        : []
                    }
                  />*/}
								<AutocompleteInput
									label="Startup Superpowers*"
									fullWidth
									handleInputChange={(e, type) => handleChange({ target: { value: JSON.stringify(e.target.value) } }, "superpower")}
									inputKey="superpower"
									autoCompleteData={FOUNDER_SUPER_POWER_DATA}
									typingEnabled={false}
									multiple
									inputValue={payload ? getSelectedExpertiseFormatted(payload?.superpower) : []}
									inputValid={() => true}
									helpText={"*required"}
									sx={{ display: "grid" }}
									errorMessage={mentorError.startupSuperpowers}
									isError={mentorError.startupSuperpowers}
								/>
							</Grid>

							<Grid item xs={12} md={12} onClick={inputChange}>
								<AutocompleteInput
									label="Guidance Needed*"
									fullWidth
									handleInputChange={(e, type) => handleChange({ target: { value: JSON.stringify(e.target.value) } }, "expertise")}
									inputKey="expertise"
									autoCompleteData={EXPERTISE_LIST}
									typingEnabled={false}
									multiple
									inputValue={payload ? getSelectedExpertiseFormatted(payload?.expertise) : []}
									inputValid={() => true}
									helpText={"*required"}
									sx={{ display: "grid" }}
									errorMessage={mentorError.startupExpertise}
									isError={mentorError.startupExpertise}
								/>
							</Grid>
							<Grid item xs={12} md={12} onClick={inputChange}>
								<AutocompleteInput
									label="Industry"
									fullWidth
									handleInputChange={(e, type) => handleChange({ target: { value: JSON.stringify(e.target.value) } }, "industries")}
									inputKey="industries"
									autoCompleteData={INDUSTRY_LIST}
									typingEnabled={false}
									multiple
									inputValue={payload ? getSelectedExpertiseFormatted(payload?.industries) : []}
									inputValid={() => true}
									sx={{ display: "grid" }}
								/>
							</Grid>
							<Grid item xs={12} md={12} onClick={inputChange}>
								<AutocompleteInput
									label="Product Offering"
									fullWidth
									handleInputChange={(e, type) => handleChange({ target: { value: JSON.stringify(e.target.value) } }, "productOffering")}
									inputKey="productOffering"
									autoCompleteData={PRODUCT_OFFERING_LIST}
									typingEnabled={false}
									multiple
									inputValue={payload ? getSelectedExpertiseFormatted(payload?.productOffering) : []}
									inputValid={() => true}
									sx={{ display: "grid" }}
								/>
							</Grid>
							<Grid item xs={12} md={12} onClick={inputChange}>
								<TextInputField
									label="Phone"
									placeholder={"000-000-0000"}
									handleBlur={handleBlur}
									handleChange={handleChange}
									helperText={mentorError.phoneError}
									value={payload?.phone || payload?.phone}
									inputKey={"phone"}
								/>
							</Grid>
						</Box>

						<Grid item xs={12} md={12}>
							<MentorExperience
								isEdit={true}
								inputChange={inputChange}
								harmonicData={payload}
								setHarmonicUpdate={setHarmonicUpdate}
								harmonicUpdate={harmonicUpdate}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>

			{/* Mob: form actions */}
			<Grid
				item
				xs={12}
				md={12}
				sx={{
					display: { xs: "block", md: "none" },
					pb: { xs: "30px" },
				}}
			>
				<Divider sx={{ my: 2 }} />
				<Grid container spacing={2} sx={{ px: 2 }}>
					<Grid item xs={6} md={6} sx={{ display: "flex", alignItems: "center" }}>
						<Button variant="contained" sx={{ width: 1 }} onClick={handleCancel}>
							Discard
						</Button>
					</Grid>
					<Grid item xs={6} md={6} sx={{ display: "flex", alignItems: "center" }}>
						<Button sx={{ width: 1 }} disabled={saveDisable} onClick={handleUpdateUser}>
							{saveLoading ? (
								<CircularProgress
									sx={(theme) => ({
										display: "flex",
										justifyContent: "center",
										color: theme.palette.secondary.white,
										maxWidth: theme.shape.standard10.borderRadius,
									})}
								/>
							) : (
								"Save"
							)}
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</Box>
	);
}

export default EditProfile;
