import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import moment from "moment";

export default function CustomizedMonthPicker({ Component, label, value, onChange, error, disabled, slotIcon = ArrowDropDownIcon, fieldStyleProps }) {
	const [open, setOpen] = useState(false);

	const handleOpen = () => {
		if (disabled) {
			setOpen(false);
		} else {
			setOpen(true);
		}
	};

	return (
		<LocalizationProvider dateAdapter={AdapterMoment}>
			<DatePicker
				open={open}
				onOpen={() => handleOpen()}
				onClose={() => setOpen(false)}
				views={["month"]}
				openTo="month"
				value={value ? moment(value) : null}
				label={label}
				onChange={(newValue) => {
					onChange(moment(newValue).toDate());
				}}
				disabled={disabled}
				slots={{
					openPickerIcon: slotIcon,
				}}
				slotProps={{
					desktopPaper: {
						sx: {
							cursor: "pointer",
							boxShadow: (theme) => theme.palette.primary.elevationLight3,
							border: (theme) => `1px solid ${theme.palette.secondary.outline2}`,
							borderRadius: (theme) => theme.shape.standard_small.borderRadius,
						},
					},
					mobilePaper: {
						sx: {
							boxShadow: (theme) => theme.palette.primary.elevationLight3,
							border: (theme) => `1px solid ${theme.palette.secondary.outline2}`,
							borderRadius: (theme) => theme.shape.standard_small.borderRadius,
						},
					},
					field: {
						inputProps: {
							value:
								value === null
									? ""
									: value.toLocaleString("en-us", {
											month: "short",
									  }),
							readOnly: true,
							placeholder: "MMM",
						},
						error: error,
						InputLabelProps: {
							...(value === null && { shrink: false }), // To Fix SOS-1837 as datepicker does not provide only month selector, when click it show different format
							...(value === null && { color: "secondary" }),
						},
						sx: fieldStyleProps && { ...fieldStyleProps },
					},
				}}
			/>
		</LocalizationProvider>
	);
}
