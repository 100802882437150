import { Avatar, Typography, Box, Stack, Radio, Divider, Tooltip, Button, Checkbox } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import React, { Fragment, useEffect, useState } from "react";
import { CROSS_ARROW_ICON, PINK_CHECK_ICON, PROSPECT_ICON_V1, UPDATED_PORTFOLIO_ICON, UPDATED_WATCHLIST_ICON } from "../../../../constants";
import { AddCircleOutlineRounded, ArrowForwardOutlined } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { setInvestorDashboardData } from "../../../../modules/actions";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";
import SquareRoundedIcon from "@mui/icons-material/SquareRounded";
import CropSquareRoundedIcon from "@mui/icons-material/CropSquareRounded";
import InfoHelper from "../../../../components/Founder/InfoHelper";
import { USER_TYPES } from "../../../../utils/userTypes";

export function AllStartupColumnDefs(handleSelectAll, selectAllStatus, handleCheckBoxClick, isAdmin, userType, partnerCode) {
	const dispatch = useDispatch();
	console.log(`isAdmin`, isAdmin);
	console.log(`userType`, userType);
	console.log(`partnerCode`, partnerCode);

	const getSelectedIndustryFormatted = (input) => {
		if (input) {
			try {
				let inputParsed = JSON.parse(input);
				if (Array.isArray(inputParsed)) {
					return inputParsed;
				} else {
					return [inputParsed];
				}
			} catch (error) {
				return [];
			}
		} else {
			return [];
		}
	};

	const allStartupColumns = [
		{
			field: "boltRecommended",
			headerName: "",
			sortable: false,
			filterable: false,
			wrapText: true,
			width: 30,
			renderCell: (params) =>
				params?.row?.boltRecommended ? (
					<InfoHelper
						title={"Bolt AI Recommended Startup"}
						body={"Bolt AI is recommending this startup based on others included in your watchlist."}
					/>
				) : (
					<></>
				),
			renderHeader: (params) => (
				<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 32 32" fill="none">
					<path
						d="M5.99984 29.3334V22.6667M5.99984 9.33341V2.66675M2.6665 6.00008H9.33317M2.6665 26.0001H9.33317M17.3332 4.00008L15.0209 10.0119C14.6449 10.9895 14.4569 11.4784 14.1645 11.8895C13.9054 12.2539 13.587 12.5723 13.2226 12.8315C12.8114 13.1238 12.3226 13.3118 11.345 13.6878L5.33317 16.0001L11.345 18.3123C12.3226 18.6883 12.8114 18.8763 13.2226 19.1687C13.587 19.4278 13.9054 19.7462 14.1645 20.1106C14.4569 20.5218 14.6449 21.0106 15.0209 21.9883L17.3332 28.0001L19.6454 21.9883C20.0214 21.0106 20.2094 20.5218 20.5018 20.1106C20.7609 19.7462 21.0793 19.4278 21.4437 19.1687C21.8549 18.8763 22.3437 18.6883 23.3214 18.3123L29.3332 16.0001L23.3214 13.6878C22.3437 13.3118 21.8549 13.1238 21.4437 12.8315C21.0793 12.5723 20.7609 12.2539 20.5018 11.8895C20.2094 11.4784 20.0214 10.9895 19.6454 10.0119L17.3332 4.00008Z"
						stroke="#7B61FF"
						stroke-width="2"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
				</svg>
			),
		},
		{
			field: "checkbox",
			headerName: "",
			sortable: false,
			filterable: false,
			wrapText: true,
			width: 50,
			renderCell: (params) => (
				<Fragment>
					<Checkbox
						icon={<CropSquareRoundedIcon />}
						checkedIcon={<SquareRoundedIcon />}
						checked={params.row?.checked}
						sx={{
							color: (theme) => theme.palette.primary.primaryPurple,
							"&.Mui-checked": {
								color: (theme) => theme.palette.primary.primaryPurple,
							},
						}}
					/>
				</Fragment>
			),
			renderHeader: (params) => (
				<Fragment>
					<Checkbox
						onChange={handleSelectAll}
						checked={selectAllStatus}
						icon={<CropSquareRoundedIcon />}
						checkedIcon={<SquareRoundedIcon />}
						sx={{
							color: (theme) => theme.palette.primary.primaryPurple,
							"&.Mui-checked": {
								color: (theme) => theme.palette.primary.primaryPurple,
							},
						}}
					/>
				</Fragment>
			),
		},
		{
			field: "currentScore",
			headerName: "Score",
			sortable: false,
			filterable: false,
			wrapText: true,
			width: 80,
			renderCell: (params) => (
				<Fragment>
					<Box display="flex" flexDirection="row" onClick={() => {}}>
						<Typography variant="Text/xs/Medium" sx={{ mr: 1 }}>
							{params.row.currentScore ? params.row.currentScore : 0}
						</Typography>
						<Box
							sx={{
								background: (theme) => theme.palette.primary.primaryPurple,
								height: "24px",
								width: "24px",
								borderRadius: "50%",
								textAlign: "center",
								cursor: "pointer",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<Box component={"img"} src={CROSS_ARROW_ICON} alt="arrow" />
						</Box>
					</Box>
				</Fragment>
			),
			renderHeader: (params) => (
				<Typography
					variant="custom070"
					sx={{
						color: (theme) => theme.palette.secondary.black,
					}}
				>
					{params.colDef.headerName}
				</Typography>
			),
		},
		{
			field: "companyName",
			headerName: "Name",
			sortable: false,
			filterable: false,
			wrapText: true,
			width: 300,
			renderCell: (params) => (
				<Fragment>
					<Typography variant="Text/xs/Medium">{params.row.companyName ? params.row.companyName : "-"}</Typography>
				</Fragment>
			),
			renderHeader: (params) => (
				<Typography
					variant="custom070"
					sx={{
						color: (theme) => theme.palette.secondary.black,
					}}
				>
					{params.colDef.headerName}
				</Typography>
			),
		},
		{
			field: "fundable",
			headerName: "",
			sortable: false,
			filterable: false,
			wrapText: true,
			width: 60,
			renderCell: (params) => (
				<Fragment>
					<Divider orientation="vertical" sx={{ height: "30px" }} />
					{params.row?.companySource && (
						<Tooltip title={params.row?.companySource} arrow>
							<Box component={"img"} sx={{ height: "50px", width: "40px", cursor: "pointer" }} src={PROSPECT_ICON_V1} alt="prospect" />
						</Tooltip>
					)}
				</Fragment>
			),
			renderHeader: (params) => {},
		},
		{
			field: "portfolio",
			headerName: "",
			sortable: false,
			filterable: false,
			wrapText: true,
			width: 70,
			renderCell: (params) => (
				<Fragment>
					<Box sx={{ display: "flex", cursor: "pointer", alignItems: "center", gap: "5px" }}>
						{params.row?.inPortfolio ? (
							<Box component={"img"} src={PINK_CHECK_ICON} sx={{ width: "24px", height: "24px" }} />
						) : (
							<AddCircleOutlineRounded
								sx={{ fontSize: "23px", color: (theme) => theme.palette.text.investorDashGrayLabelMuted }}
								className="add-icon-hover"
							/>
						)}
						<Box
							component={"img"}
							className="addHovered"
							sx={{
								position: "relative",
								height: "25px",
								width: "25px",
							}}
							src={UPDATED_PORTFOLIO_ICON}
							alt="icon"
						/>
					</Box>
				</Fragment>
			),
			renderHeader: (params) => {},
		},
		{
			field: "watchlist",
			headerName: "",
			sortable: false,
			filterable: false,
			wrapText: true,
			width: 70,
			renderCell: (params) => (
				<Fragment>
					<Box sx={{ display: "flex", cursor: "pointer", alignItems: "center", gap: "5px" }}>
						{params.row.inWatchlist ? (
							<Box component={"img"} src={PINK_CHECK_ICON} sx={{ width: "24px", height: "24px" }} />
						) : (
							<AddCircleOutlineRounded
								sx={{ fontSize: "23px", color: (theme) => theme.palette.text.investorDashGrayLabelMuted }}
								className="add-icon-hover"
							/>
						)}
						<Box
							component={"img"}
							className="addHovered"
							sx={{
								position: "relative",
								height: "25px",
								width: "25px",
							}}
							src={UPDATED_WATCHLIST_ICON}
							alt="icon"
						/>
					</Box>
				</Fragment>
			),
			renderHeader: (params) => {},
		},
		{
			field: "track",
			headerName: "",
			sortable: false,
			filterable: false,
			wrapText: true,
			width: isAdmin || userType === USER_TYPES.PARTNER_USER ? 100 : 0,
			renderCell: (params) => (
				<Fragment>
					{(isAdmin || userType === USER_TYPES.PARTNER_USER) && params.row?.partnerCode !== partnerCode && (
						<Box sx={{ display: "flex", cursor: "pointer", alignItems: "center", gap: "5px" }}>
							{/* {console.log(`params`, params.row)} */}
							{params.row?.inTracked ? (
								<Box component={"img"} src={PINK_CHECK_ICON} sx={{ width: "24px", height: "24px" }} />
							) : (
								<AddCircleOutlineRounded
									sx={{ fontSize: "23px", color: (theme) => theme.palette.text.investorDashGrayLabelMuted }}
									className="add-icon-hover"
								/>
							)}

							<GpsFixedIcon sx={{ position: "relative", height: "25px", width: "25px", color: (theme) => theme.palette.grey }} />
						</Box>
					)}

					<Divider orientation="vertical" sx={{ height: "30px" }} />
				</Fragment>
			),
			renderHeader: (params) => {},
		},

		{
			field: "city",
			headerName: "Location",
			sortable: false,
			filterable: false,
			wrapText: true,
			width: 150,
			renderCell: (params) => (
				<Typography variant="Text/xs/Medium" align="center">
					{params.row.city ? params.row.city : "-"}
				</Typography>
			),
			renderHeader: (params) => (
				<Typography
					variant="custom070"
					sx={{
						color: (theme) => theme.palette.secondary.black,
					}}
				>
					{params.colDef.headerName}
				</Typography>
			),
		},
		{
			field: "foundedYear",
			headerName: "Founded",
			sortable: false,
			filterable: false,
			wrapText: true,
			width: 150,
			renderCell: (params) => <Typography variant="Text/xs/Medium">{params.value ? params.value : "-"}</Typography>,
			renderHeader: (params) => (
				<Typography
					variant="custom070"
					sx={{
						color: (theme) => theme.palette.secondary.black,
					}}
				>
					{params.colDef.headerName}
				</Typography>
			),
		},
		{
			field: "industry",
			headerName: "Industry",
			sortable: false,
			filterable: false,
			wrapText: true,
			width: 150,
			renderCell: (params) => {
				return (
					<Fragment>
						{getSelectedIndustryFormatted(params.row?.industry).length > 0 && (
							<Tooltip
								title={getSelectedIndustryFormatted(params.row?.industry).map((item, index) => {
									if (index < getSelectedIndustryFormatted(params.row?.industry).length - 1) {
										return `${item} `;
									} else {
										return `${item}`;
									}
								})}
								arrow
							>
								<Box
									display={"flex"}
									alignItems="center"
									sx={{ cursor: "pointer" }}
									onClick={() => {
										dispatch(
											setInvestorDashboardData({
												openStartupObj: params.row,
												industryDetailsOpen: true,
											})
										);
									}}
								>
									<Button
										variant="link"
										sx={{
											width: "50px",
											ml: -2, // make button text in center with "-"
											whiteSpace: "nowrap",
											overflow: "hidden",
											pr: 6,
											transition: "padding 0.5",
											color: (theme) => theme.palette.secondary.black,
											"&:hover": {
												pr: 0,
												pl: 3,
												transition: "padding 0.5",
											},
										}}
										startIcon={<ArrowForwardOutlined fontSize="large" />}
										endIcon={<ArrowForwardOutlined fontSize="large" />}
									>
										<Box display={"flex"} alignItems="center">
											<Typography variant="poppinsMedium16" color="secondary.black">
												[{getSelectedIndustryFormatted(params.row?.industry).length}]
											</Typography>
										</Box>
									</Button>
								</Box>
							</Tooltip>
						)}
						{!(getSelectedIndustryFormatted(params.row?.industry).length > 0) && (
							<Typography variant="poppinsMedium16" display={"block"} noWrap>
								{" - "}
							</Typography>
						)}
					</Fragment>
				);
			},
			renderHeader: (params) => (
				<Typography
					variant="custom070"
					sx={{
						color: (theme) => theme.palette.secondary.black,
					}}
				>
					{params.colDef.headerName}
				</Typography>
			),
		},
		{
			field: "companySize",
			headerName: "Co Size",
			sortable: false,
			filterable: false,
			wrapText: true,
			width: 150,
			renderCell: (params) => <Typography variant="Text/xs/Medium">{params.value ? params.value : "-"}</Typography>,
			renderHeader: (params) => (
				<Typography
					variant="custom070"
					sx={{
						color: (theme) => theme.palette.secondary.black,
					}}
				>
					{params.colDef.headerName}
				</Typography>
			),
		},
		{
			field: "revenue",
			headerName: "Rev.",
			sortable: false,
			filterable: false,
			wrapText: true,
			width: 150,
			renderCell: (params) => <Typography variant="Text/xs/Medium">{params.value ? params.value : "-"}</Typography>,
			renderHeader: (params) => (
				<Typography
					variant="custom070"
					sx={{
						color: (theme) => theme.palette.secondary.black,
					}}
				>
					{params.colDef.headerName}
				</Typography>
			),
		},
		{
			field: "capitalRaised",
			headerName: "Fin. TD",
			sortable: false,
			filterable: false,
			wrapText: true,
			width: 150,
			renderCell: (params) => (
				<Typography variant="poppinsMedium16" display={"block"} noWrap>
					{params.row?.capitalRaised
						? "$" +
						  Intl.NumberFormat("en-US", {
								notation: "compact",
								maximumFractionDigits: 1,
						  }).format(Number(params.row?.capitalRaised))
						: "-"}
				</Typography>
			),
			renderHeader: (params) => (
				<Typography
					variant="custom070"
					sx={{
						color: (theme) => theme.palette.secondary.black,
					}}
				>
					{params.colDef.headerName}
				</Typography>
			),
		},
		{
			field: "financing",
			headerName: "Financing",
			sortable: false,
			filterable: false,
			wrapText: true,
			width: 150,
			renderCell: (params) => <Typography variant="Text/xs/Medium">{params.value ? params.value : "-"}</Typography>,
			renderHeader: (params) => (
				<Typography
					variant="custom070"
					sx={{
						color: (theme) => theme.palette.secondary.black,
					}}
				>
					{params.colDef.headerName}
				</Typography>
			),
		},
		{
			field: "currentStage",
			headerName: "Current",
			sortable: false,
			filterable: false,
			wrapText: true,
			width: 150,
			renderCell: (params) => <Typography variant="Text/xs/Medium">{params.value ? params.value : "-"}</Typography>,
			renderHeader: (params) => (
				<Typography
					variant="custom070"
					sx={{
						color: (theme) => theme.palette.secondary.black,
					}}
				>
					{params.colDef.headerName}
				</Typography>
			),
		},
		{
			field: "seeking",
			headerName: "Seeking",
			sortable: false,
			filterable: false,
			wrapText: true,
			width: 150,
			renderCell: (params) => <Typography variant="Text/xs/Medium">{params.value ? params.value : "-"}</Typography>,
			renderHeader: (params) => (
				<Typography
					variant="custom070"
					sx={{
						color: (theme) => theme.palette.secondary.black,
					}}
				>
					{params.colDef.headerName}
				</Typography>
			),
		},

		{
			field: "ask",
			headerName: "Asking",
			sortable: false,
			filterable: false,
			wrapText: true,
			width: 150,
			renderCell: (params) => (
				<Typography variant="poppinsMedium16" display={"block"} noWrap>
					{params.row?.ask
						? "$" +
						  Intl.NumberFormat("en-US", {
								notation: "compact",
								maximumFractionDigits: 1,
						  }).format(Number(params.row?.ask))
						: "-"}
				</Typography>
			),
			renderHeader: (params) => (
				<Typography
					variant="custom070"
					sx={{
						color: (theme) => theme.palette.secondary.black,
					}}
				>
					{params.colDef.headerName}
				</Typography>
			),
		},
	];

	return allStartupColumns;
}
