import React, { useState, useEffect } from "react";
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  Grid,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import theme from "../../../../constants/Theme";

export default function PresentationPreview({ open, close, data }) {
  const [scroll, setScroll] = React.useState("paper");
  return (
    <>
      <Dialog
        open={open}
        scroll={scroll}
        onClose={close}
        sx={theme => ({
          display: "flex",
          maxWidth: 1,
          flexDirection: "column",
          borderRadius: theme.shape.standard23.borderRadius,
          height: 1,
          "& .MuiDialog-paper": {
            width: 1,
            borderRadius: 3,
          },
          [theme.breakpoints.down("md")]: {
            maxHeight: "90%",
          },
        })}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <Grid
          container
          sx={{
            display: "flex",
            width: 1,
            flexDirection: "column",
            pt: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              height: "max-content",
              pl: 2,
              pr: 2,
            }}
          >
            <Grid item xs={10} md={10}>
              <Typography variant="sys_light_on_surface_heading">
                Template Preview
              </Typography>
            </Grid>

            <Grid
              item
              xs={2}
              md={2}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <CloseIcon
                sx={{ cursor: "pointer", color: theme.palette.secondary.dark }}
                onClick={close}
              />
            </Grid>
          </Box>

          <DialogContent dividers={scroll === "paper"}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: 1,
                height: 1,
              }}
            >
              <Grid item xs={12} md={12} sx={{ width: 1 }}>
                <Box
                  sx={{
                    display: "flex",
                    width: 1,
                    height: 1,
                    border: "1px solid rgba(124, 117, 127, 0.16)",
                    borderRadius: 3,
                    mb: 1,
                  }}
                >
                  <div
                    className="App"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <iframe
                      src={`https://view.officeapps.live.com/op/embed.aspx?src=${data?.fileUrl}`}
                      width="100%"
                      height="400px"
                      frameBorder="0"
                      title="slides"
                    ></iframe>
                  </div>
                </Box>
              </Grid>
            </Box>
          </DialogContent>
        </Grid>
      </Dialog>
    </>
  );
}
