import { Avatar, Radio, Typography } from "@mui/material";
import { Fragment } from "react";

const startupAnswerColumns = (selectedRowId) => [
	{
		field: "select",
		headerName: "Select",
		sortable: false,
		filterable: false,
		width: 100,
		renderCell: (params) => (
			<Fragment>
				<Radio checked={params.row.id === selectedRowId} value={params.row.id} />
			</Fragment>
		),
		renderHeader: (params) => <Typography variant="Text/xs/Medium">{params.colDef.headerName}</Typography>,
	},

	{
		field: "companyName",
		headerName: "Company",
		sortable: true,
		filterable: true,
		width: 250,
		renderCell: (params) => (
			<Fragment>
				<Avatar src={params.value?.logoUrl} alt="logo" />
				<Typography variant="Text/xs/Regular">{params?.value?.company}</Typography>
			</Fragment>
		),
		renderHeader: (params) => <Typography variant="Text/xs/Medium">{params.colDef.headerName}</Typography>,
	},
	{
		field: "foundedYear",
		headerName: "Founded",
		sortable: true,
		filterable: true,
		width: 250,
		renderCell: (params) => (
			<Fragment>
				<Typography variant="Text/xs/Regular">{params.value ? params.value : "-"}</Typography>
			</Fragment>
		),
		renderHeader: (params) => <Typography variant="Text/xs/Medium">{params.colDef.headerName}</Typography>,
	},
	{
		field: "industry",
		headerName: "Industry",
		sortable: false,
		filterable: false,
		width: 300,
		renderCell: (params) => (
			<Fragment>
				<Typography variant="Text/xs/Regular">{params.value}</Typography>
			</Fragment>
		),
		renderHeader: (params) => <Typography variant="Text/xs/Medium">{params.colDef.headerName}</Typography>,
	},
	{
		field: "productOffering",
		headerName: "Product Category",
		sortable: false,
		filterable: false,
		width: 250,
		renderCell: (params) => (
			<Fragment>
				<Typography variant="Text/xs/Regular">{params.value}</Typography>
			</Fragment>
		),
		renderHeader: (params) => <Typography variant="Text/xs/Medium">{params.colDef.headerName}</Typography>,
	},
	{
		field: "currentScore",
		headerName: "StartupOS Score",
		sortable: true,
		filterable: true,
		width: 250,
		renderCell: (params) => (
			<Fragment>
				<Typography variant="Text/xs/Regular">{params.value}</Typography>
			</Fragment>
		),
		renderHeader: (params) => <Typography variant="Text/xs/Medium">{params.colDef.headerName}</Typography>,
	},
];

export function StartupAnswerColumns(selectedRowId) {
	return startupAnswerColumns(selectedRowId);
}

export const StartupAnswerSortOptions = [
	{ name: "Company Name", colName: "companyName" },
	{ name: "Founded Year", colName: "foundedYear" },
	{ name: "Score", colName: "currentScore" },
];
