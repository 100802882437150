import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Button, useMediaQuery, Typography } from "@mui/material";
import messages from "../../../../../constants/messages";
import { toastContainer } from "../../../../../utils/toast";
import MessageHandlerModal from "../../../../../common/modal/MessageHandler";
import ArchivedTable from "../ArchivedTable";
import { useHistory } from "react-router-dom";
import { getArchivedList, archiveModuleResponse, deleteArchiveModuleResponse, deleteAllArchiveModuleResponse } from "../../functions";
import MyWorkBreadCrumbs from "../MyWorkBreadcrumbs";
import GridListView from "../../../../../components/Workspace/GridListView";
import theme from "../../../../../constants/Theme";
import ArchivedGrid from "../ArchivedGrid";
import { handleMixPanel } from "../../../../../utils/mixPanelEventHandling";

export default function ArchivePage({ handleArchivedBack, setDeleteEnable }) {
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
	const history = useHistory();
	const [details, setDetails] = useState("");
	const [isAscendingName, setIsAscendingName] = useState(true);
	const [isAscendingDate, setIsAscendingDate] = useState(true);
	const [loading, setLoading] = useState(true);

	const [page, setPage] = useState(1);
	const [sort, setSort] = useState("ASC");
	const [archived, setArchived] = useState(false);
	const [filter, setFilter] = useState("name");
	const [open, setOpen] = useState(false);
	const [versionId, setVersionId] = useState("");
	const [deleteOpen, setDeleteOpen] = useState(false);
	const [deleteAllOpen, setDeleteAllOpen] = useState(false);
	const [isListView, setIsListView] = useState("LIST");

	const [totalPages, setTotalPages] = useState(0);
	const [isLast, setIsLast] = useState(false);
	const [archivesLoaded, setArchivesLoaded] = useState(false);

	useEffect(() => {
		handleGetDetails();
	}, [sort, filter]);

	const handleGetDetails = () => {
		getArchivedList(page, sort, filter).then((res) => {
			if (res?.status === 200) {
				if (page === 1) {
					setDetails(res.data.data.models);
					if (!res.data.data.models) {
						setDeleteEnable(false);
					}
				} else {
					setDetails([...details, ...res.data.data.models]);
				}
				setTotalPages(res.data.data.totalPages);
				setIsLast(res.data.data.isLast);
				setLoading(false);
				setArchivesLoaded(true);
			} else {
				toastContainer(messages.API_SUBMIT_ERROR, "error");
			}
		});

		//sorting
		if (filter === "name") {
			if (sort === "ASC") {
				setIsAscendingName(true);
			} else {
				setIsAscendingName(false);
			}
		} else {
			if (sort === "ASC") {
				setIsAscendingDate(true);
			} else {
				setIsAscendingDate(false);
			}
		}
	};

	useEffect(() => {
		if (details?.length > 0) {
			handleMixPanel("Work History Archive Viewed", {
				"Archive Count": details?.length,
			});
		}
	}, [archivesLoaded]);

	useEffect(() => {
		if (page !== 1) {
			handleGetDetails();
		}
	}, [page]);

	// const callNextPage = () => {
	// 	setPage((page) => page + 1);
	// };

	const handleArchive = (id, event) => {
		setVersionId(id);
		handleModal(event);
	};

	const updateUnArchive = (responseId) => {
		const selectedArchiveArr = details.filter((powerUp) => powerUp.id == responseId);

		handleMixPanel("Powerup Unarchived", {
			"Powerup ID": selectedArchiveArr[0].module.powerUpModel.id,
			"Powerup Name": selectedArchiveArr[0].module.powerUpModel.powerUpName,
			"Powerup Complete": selectedArchiveArr[0].isCompleted,
		});

		archiveModuleResponse({ responseId: responseId, status: false }).then((res) => {
			if (res?.status === 200) {
				setLoading(true);
				handleGetDetails();
			} else {
				toastContainer(messages.API_SUBMIT_ERROR, "error");
			}
			handleModal("Unarchive");
		});
	};

	const handleModal = (event) => {
		if (event === "Unarchive") {
			setOpen(!open);
		} else if (event === "deleteOne") {
			setDeleteOpen(!deleteOpen);
		} else if (event === "deleteAll") {
			setDeleteAllOpen(!deleteAllOpen);
		}
	};

	const handleDeleteModule = (id, event) => {
		setVersionId(id);
		handleModal(event);
	};

	const deleteModule = (responseId) => {
		const selectedArchiveArr = details.filter((powerUp) => powerUp.id == responseId);

		handleMixPanel("Deleted Archived Work", {
			"Powerup ID": selectedArchiveArr[0].module.powerUpModel.id,
			"Powerup Name": selectedArchiveArr[0].module.powerUpModel.powerUpName,
			"Powerup Complete": selectedArchiveArr[0].isCompleted,
		});

		deleteArchiveModuleResponse(responseId).then((res) => {
			if (res?.status === 200) {
				setLoading(true);
				handleGetDetails();
			} else {
				toastContainer(messages.API_SUBMIT_ERROR, "error");
			}
			handleModal("deleteOne");
		});
	};

	const deleteAllArchived = () => {
		deleteAllArchiveModuleResponse().then((res) => {
			if (res?.status === 200) {
				setLoading(true);
				handleGetDetails();
			} else {
				toastContainer(messages.API_SUBMIT_ERROR, "error");
			}
		});
		handleModal("deleteAll");
	};

	const handleSort = (sort, filter) => {
		setSort(sort); //ASC,DESC
		setFilter(filter); //NAME,DATE
		setPage(1);
		setLoading(true);
	};

	const handleSwitchView = (view) => {
		setIsListView(view);
		setPage(1);
	};

	useEffect(() => {
		if (isSmallScreen) {
			setIsListView("GRID");
		}
	}, [isSmallScreen]);

	return (
		<>
			<Box
				sx={{
					width: 1,
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-between",
					alignItems: "center",
					alignContent: "center",
					textAlign: "center",
					pt: 2,
				}}
			>
				<MyWorkBreadCrumbs subpath={"Archived"} handleBreadcrumbBack={handleArchivedBack} />
				<Box
					sx={{
						display: { xs: "none", md: "flex" },
						flexDirection: "row",
						gap: 1,
						pr: 2,
					}}
				>
					{details?.length > 0 ? <GridListView view={isListView} setView={handleSwitchView} /> : ""}

					<Button onClick={() => handleModal("deleteAll")} disabled={details?.length > 0 ? false : true}>
						Delete all
					</Button>
				</Box>
			</Box>
			{details?.length > 0 ? (
				<>
					{loading ? (
						<Box
							sx={{
								display: "flex",
								width: 1,
								justifyContent: "center",
								py: 4,
							}}
						>
							<CircularProgress />
						</Box>
					) : (
						<>
							{!isSmallScreen && isListView === "LIST" && (
								<ArchivedTable
									details={details}
									isAscendingName={isAscendingName}
									isAscendingDate={isAscendingDate}
									handleArchive={handleArchive}
									handleDeleteModule={handleDeleteModule}
									handleSort={handleSort}
									totalPages={totalPages}
									page={page}
									setPage={setPage}
									// callNextPage={callNextPage}
									isLast={isLast}
								/>
							)}
							{isListView === "GRID" && (
								<ArchivedGrid
									details={details}
									handleArchive={handleArchive}
									// callNextPage={callNextPage}
									isLast={isLast}
									handleDeleteModule={handleDeleteModule}
								/>
							)}
						</>
					)}
				</>
			) : (
				<Box
					sx={{
						display: "flex",
						width: 1,
						justifyContent: "center",
						alignContent: "center",
						py: 5,
					}}
				>
					{loading ? (
						<Box
							sx={{
								display: "flex",
								width: 1,
								justifyContent: "center",
								py: 4,
							}}
						>
							<CircularProgress />
						</Box>
					) : (
						<Typography color="primary" variant="h5" sx={{ color: theme.palette.secondary.dark }}>
							No data found
						</Typography>
					)}
				</Box>
			)}
			{/* unarchive */}
			<MessageHandlerModal
				isOpen={open}
				onCancel={() => handleModal("Unarchive")}
				onOk={() => updateUnArchive(versionId)}
				text="Are you sure you want to unarchive this?"
				okText="Unarchive"
				cancelText="No Thanks"
				messageType="primary"
				heading={"Unarchive"}
			/>
			{/* delete */}
			<MessageHandlerModal
				isOpen={deleteOpen}
				onCancel={() => handleModal("deleteOne")}
				onOk={() => deleteModule(versionId)}
				text="Are you sure you want to delete this?"
				okText="Delete"
				cancelText="No Thanks"
				messageType="primary"
				heading={"Delete"}
			/>
			{/*delete all*/}
			<MessageHandlerModal
				isOpen={deleteAllOpen}
				onCancel={() => handleModal("deleteAll")}
				onOk={deleteAllArchived}
				text="Are you sure you want to delete all?"
				okText="Delete"
				cancelText="No Thanks"
				messageType="primary"
				heading={"Delete"}
			/>
		</>
	);
}
