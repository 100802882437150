import { Divider, Grid, Typography, Box } from "@mui/material";
import React from "react";
import { POWERUP_EMPTY_ICON, POWERUP } from "../../../../constants/image";
import PropTypes from "prop-types";

const PowerupHeader = ({
  heading,
  description,
  totalPowerups,
  completedPowerups,
}) => {
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Box>
          <Typography variant="h22" noWrap>
            {heading}
          </Typography>
        </Box>

        <Divider
          sx={{
            mx: 1,
            position: "relative",
            width: "100%",
            flexShrink: 1,
            borderWidth: 1,
            borderColor: "secondary.light",
          }}
        />

        <Box display={"flex"} alignItems={"center"}>
          {totalPowerups !== completedPowerups ? (
            <img src={POWERUP_EMPTY_ICON} alt="powerup" />
          ) : (
            <img src={POWERUP} alt="powerup" />
          )}

          <Typography variant="h6" color="secondary" sx={{ ml: 1 }} noWrap>
            {completedPowerups}/{totalPowerups} Complete
          </Typography>
        </Box>
      </Box>
      <Box sx={{ mt: 3 }}>
        <Typography variant="f16" wrap sx={{ whiteSpace: "pre-wrap" }}>
          {description}
        </Typography>
      </Box>
    </Box>
  );
};
{
  PowerupHeader.propTypes = {
    heading: PropTypes.string,
    description: PropTypes.string,
    totalPowerups: PropTypes.number,
    completedPowerups: PropTypes.number,
  };

  PowerupHeader.defaultProps = {
    heading: "",
    description: "",
    totalPowerups: 10,
    completedPowerups: 0,
  };
}
export default PowerupHeader;
