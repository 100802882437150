import React, { useMemo, useState } from "react";
import { Box, Divider, Skeleton, Typography, Avatar, IconButton } from "@mui/material";
import MissingAsset from "../../../Profile/Components/MissingAsset";
import VideoPlayerComponent from "../../../../common/videoPlayer";
import { useSelector } from "react-redux";
import JsxParser from "react-jsx-parser";
import ShareIcon from "@mui/icons-material/Share";
import { toastContainer } from "../../../../utils/toast";
import SocialMediaShareButtons from "../../../../common/SocialMediaShareButtons";

const BoxVideoElem = ({ children }) => (
	<Box
		sx={{
			flex: 1,
			maxHeight: "640px", // Figma value.
			maxWidth: "1000px",
			mb: 3,
			mt: 3,
			"& video": {
				borderRadius: "30px",
				objectFit: "cover",
			},
		}}
	>
		{children}
	</Box>
);

function StartupPublicAboutStoryCard() {
	const isLoading = useSelector((state) => state?.startupPublicProfileData?.loading);
	const startupData = useSelector((state) => state?.startupPublicProfileData?.startupPublicData);
	const homeApiVersion = process.env.REACT_APP_HOME_URL;
	const shareUrl = `${homeApiVersion}/startup/${startupData?.uuid}`;

	const VideoComp = useMemo(() => {
		if (startupData?.founderStoryVideo) {
			return (
				<BoxVideoElem>
					<VideoPlayerComponent videoUrl={startupData?.founderStoryVideo} height="500px" />
				</BoxVideoElem>
			);
		} else {
			return <MissingAsset sxProps={{ height: "450px", p: 2, mb: "30px" }} message={"Founder Story Video Coming Soon"} />;
		}
	}, []);

	return (
		<Box
			sx={(theme) => ({
				borderRadius: "40px",
				backgroundColor: "white",
				boxSizing: "border-box",
				padding: "32px 16px", // default padding
				[theme.breakpoints.between("sm", "md")]: {
					padding: "32px 32px", // padding between sm and md
				},
				[theme.breakpoints.up("md")]: {
					padding: "32px 40px 48px", // padding above md
				},
				boxShadow: "0px 10px 25px rgba(0, 0, 0, 0.05)",
			})}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
					}}
				>
					<Typography
						variant="customValue"
						component={"div"}
						sx={{
							fontFamily: "PoppinsSemiBold",
							fontSize: "26px",
							lineHeight: "39px",
							color: "black",
						}}
					>
						Founder Story
					</Typography>
					{/* <Box
						ml="auto"
						sx={{
							display: "flex",
							flexDirection: "row",
							gap: 1,
							alignItems: "center",
						}}
					>
						<Typography variant="testHeading" component={"div"}>
							Share
						</Typography>
						<SocialMediaShareButtons
							sharePlatformsList={["FB", "TW", "EM"]}
							url={shareUrl}
							title={`Check out my company profile on StartupOS`}
							description={`Check out my profile on StartupOS`}
							windowWidth={500}
							windowHeight={500}
							emailSeparator="\n"
						/>
					</Box> */}
				</Box>
				{isLoading ? (
					<></>
				) : startupData?.founderStoryVideo ? (
					<BoxVideoElem>
						<VideoPlayerComponent videoUrl={startupData?.founderStoryVideo} height="500px" />
					</BoxVideoElem>
				) : (
					<MissingAsset sxProps={{ height: "450px", p: 2, mb: "30px" }} message={"Founder Story Video Coming Soon"} />
				)}

				<Box
					sx={{
						mb: "20px",
					}}
				>
					<Typography
						variant="customValue"
						component={"div"}
						sx={{
							fontFamily: "PoppinsSemiBold",
							fontSize: "26px",
							lineHeight: "39px",
							color: "black",
						}}
					>
						{`The ${startupData?.companyName} Story`}
					</Typography>
				</Box>
				<Box>
					<Typography
						variant="customValue"
						component={"div"}
						sx={{
							fontFamily: "PoppinsMedium",
							fontSize: "16px",
							lineHeight: "24px",
							color: "black",
						}}
					>
						<JsxParser
							components={{ Typography }}
							jsx={startupData?.founderStory ? startupData?.founderStory : `${startupData?.companyName} has not completed their founder story.`}
						/>
					</Typography>
				</Box>
			</Box>
		</Box>
	);
}

export default StartupPublicAboutStoryCard;
