import services from "../../services";
import JSON5 from "json5";

const convertImageToBase64 = async (imageUrl) => {
	try {
		const response = await fetch(imageUrl);
		const blob = await response.blob();
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onloadend = () => resolve(reader.result); // This will return the base64 string
			reader.onerror = reject;
			reader.readAsDataURL(blob);
		});
	} catch (error) {
		console.error("Error fetching and converting image:", error);
		return ""; // or handle the error accordingly
	}
};

export const profileImageReview = async ({ name, about, headline, image }) => {
	if (!image) return null;

	const image_data_b64 = await convertImageToBase64(image).then((b64) => {
		return b64;
	});

	if (!image_data_b64) return null;

	let payload = {
		messages: [
			{
				role: "system",
				content: [
					{
						type: "text",
						text: "You are a professional content reviewer that focuses on effective marketing and communication of user profiles. Your role is to determine if their profile image is an appropriate fit for their profile. You will provide a review of their profile image and if necessary provide feedback on what they could do to improve it to better fit their profile. You will only respond in the format:\n{\ncomments: 'your 1-2 sentence review overview of the photo',suggested_edit: true/false,suggested_value: 'your suggestions to the user on how they could improve their profile picture if determined to not be an appropriate fit for their profile.'}",
					},
				],
			},
			{
				role: "user",
				content: [
					{
						type: "text",
						text: `User Profile Content:
                        Name: ${name}
                        User Bio Statement: ${about ? about : "This user did not have a bio on their profile."}
                        Headline: ${headline ? headline : "This user did not have a headline on their profile."}
                    `,
					},
					{
						type: "image_url",
						image_url: {
							url: image_data_b64,
						},
					},
				],
			},
		],
		maxTokens: 3000,
		model: "gpt-4o",
		temperature: 0.5,
	};

	let attempts = 0;
	while (attempts < 2) {
		try {
			let AIResp = await services.getOpenAIChatResponse(payload).catch((e) => {
				console.log(e);
			});

			if (AIResp?.data?.data?.[0]?.text) {
				return AIResp?.data?.data?.[0]?.text;
			} else if (AIResp?.data?.data?.[0]?.message?.content) {
				let parsed = JSON5.parse(AIResp?.data?.data?.[0]?.message?.content);
				if (parsed) {
					return parsed;
				} else {
					attempts++;
				}
			} else {
				attempts++;
			}
		} catch (error) {
			attempts++;
			console.error(error);
		}
	}
	return false;
};

export const companyLogoReview = async ({ companyName, about, tagLine, image }) => {
	if (!image) return null;

	const image_data_b64 = await convertImageToBase64(image).then((b64) => {
		return b64;
	});

	if (!image_data_b64) return null;

	let payload = {
		messages: [
			{
				role: "system",
				content: [
					{
						type: "text",
						text: "You are a professional content reviewer that focuses on effective marketing and communication of startup profiles. Your role is to determine if their logo image is an appropriate fit for their company. You will provide a review of their company logo image and if necessary provide feedback on what they could do to improve it to better fit their company/brand/offering. You will only respond in the format:\n{\ncomments: 'your 1-2 sentence review overview of the company logo',suggested_edit: true/false,suggested_value: 'your suggestions to the user on how they could improve their company logo if determined to not be an appropriate fit for their company/brand/offering.'}",
					},
				],
			},
			{
				role: "user",
				content: [
					{
						type: "text",
						text: `Company Profile Content:
                        Company Name: ${companyName}
                        Company About Statement: ${about ? about : "This company did not have a about statement on their profile."}
                        Tagline: ${tagLine ? tagLine : "This company did not have a tagline on their profile."}
                        `,
					},
					{
						type: "image_url",
						image_url: {
							url: image_data_b64,
						},
					},
				],
			},
		],
		maxTokens: 3000,
		model: "gpt-4o",
		temperature: 0.5,
	};

	let attempts = 0;
	while (attempts < 2) {
		try {
			let AIResp = await services.getOpenAIChatResponse(payload).catch((e) => {
				console.log(e);
			});

			if (AIResp?.data?.data?.[0]?.text) {
				return AIResp?.data?.data?.[0]?.text;
			} else if (AIResp?.data?.data?.[0]?.message?.content) {
				let parsed = JSON5.parse(AIResp?.data?.data?.[0]?.message?.content);
				if (parsed) {
					return parsed;
				} else {
					attempts++;
				}
			} else {
				attempts++;
			}
		} catch (error) {
			attempts++;
			console.error(error);
		}
	}
	return false;
};
