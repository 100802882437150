import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import SelectInput from "../../../../common/SelectInput";
import SortIcon from "@mui/icons-material/Sort";
import {
  TextField,
  Box,
  ListSubheader,
  Divider,
  Typography,
  OutlinedInput,
} from "@mui/material";
import theme from "../../../../constants/Theme";
import CheckIcon from "@mui/icons-material/Check";
import { EXPANDVIEW_SORT_TYPES } from "../../../../constants/research-curator-data";
import { sosPrimary } from "../../../../constants/Theme/colors";
import { handleMixPanel } from "../../../../utils/mixPanelEventHandling";

const ITEM_HEIGHT = 40;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 7 + ITEM_PADDING_TOP,
      //background: theme.palette.secondary.hoverBg,
      borderRadius: theme.shape.standard_small.borderRadius,
    },
  },
};

export default function SortSelect({ setSortBy }) {
  const [selectedSort, setSelectedSort] = React.useState(
    EXPANDVIEW_SORT_TYPES.CREATED_DATE_OLDEST
  );

  const handleChange = event => {
    const {
      target: { value },
    } = event;

    switch (value) {
      case EXPANDVIEW_SORT_TYPES.CREATED_DATE_NEWEST:
        handleMixPanel("Discovery Board Sorted", {
          "Sort Method": "Created Date Newest"
        });
        setSortBy({ key: "createdAt", order: "DESC" });
        break;
      case EXPANDVIEW_SORT_TYPES.CREATED_DATE_OLDEST:
        handleMixPanel("Discovery Board Sorted", {
          "Sort Method": "Created Date Oldest"
        });
        setSortBy({ key: "createdAt", order: "ASC" });
        break;
      case EXPANDVIEW_SORT_TYPES.TITLE_ASCENDING:
        handleMixPanel("Discovery Board Sorted", {
          "Sort Method": "Title A to Z"
        });
        setSortBy({ key: "title", order: "ASC" });
        break;
      case EXPANDVIEW_SORT_TYPES.TITLE_DESCENDING:
        handleMixPanel("Discovery Board Sorted", {
          "Sort Method": "Title Z to A"
        });
        setSortBy({ key: "title", order: "DESC" });
        break;
    }

    setSelectedSort(value);
  };

  const sortOptions = [
    EXPANDVIEW_SORT_TYPES.CREATED_DATE_NEWEST,
    EXPANDVIEW_SORT_TYPES.CREATED_DATE_OLDEST,
    EXPANDVIEW_SORT_TYPES.TITLE_ASCENDING,
    EXPANDVIEW_SORT_TYPES.TITLE_DESCENDING,
  ];

  return (
    <SelectInput
      onInputChange={handleChange}
      inputKey="sort"
      wrapperstyle={{ width: "100%" }}
      MenuProps={MenuProps}
      selectedValue={selectedSort}
      //input={<TextField spellCheck="true" />}
      renderValue={selected => {
        if (!selected) {
          return (
            <Box sx={{ my: 0, py: 0 }} display={"flex"}>
              <SortIcon sx={{ mr: 1 }} fontSize="small" />
              Sort
            </Box>
          );
        }

        return (
          <Box sx={{ display: "flex" }}>
            <SortIcon sx={{ mr: 1 }} fontSize="small" />{" "}
            <Typography
              sx={{
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              {selected}
            </Typography>{" "}
          </Box>
        );
      }}
      inputProps={{ "aria-label": "Without label" }}
      InputProps={{}}
    >
      {sortOptions.map(option => (
        <MenuItem
          key={option}
          value={option}
          sx={{
            py: 1,
            "&.Mui-selected:hover": {
              backgroundColor: sosPrimary[25],
            },
            "&.Mui-selected:active": {
              backgroundColor: sosPrimary[25],
            },
            "&.Mui-selected:focus": {
              backgroundColor: sosPrimary[25],
            },
          }}
        >
          <ListItemText primary={option} />{" "}
          {selectedSort === option && (
            <CheckIcon
              sx={theme => ({
                color: theme.palette.primary.main,
              })}
              fontSize={"small"}
            />
          )}
        </MenuItem>
      ))}
    </SelectInput>
  );
}
