import { createTheme } from "@mui/material/styles";
import { sosPrimary } from "./colors";


export const themeSelect = (themePalette) => ({
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            backgroundColor: "#F5F8FA",
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          "&.Mui-selected:hover": {
            backgroundColor: sosPrimary[25],
          },
          "&.Mui-focusVisible": {
            backgroundColor: sosPrimary[25],
          },
          "&.Mui-selected:active": {
            backgroundColor: sosPrimary[25],
          },
          "&.Mui-selected:focus": {
            backgroundColor: sosPrimary[25],
          },
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: "outlined",
        color: "primary",
      },
    },
  },
});
