import styled from "@emotion/styled";

export const ProfileContentWrapper = styled.div(({ theme, borderWidth }) => {
  return {
    marginTop: theme.spacing(3),
    border: "4px solid red",
    minHeight: "100%",
    borderRadius: theme.shape.standard.borderRadius,
    flexGrow: 1,
    borderWidth: borderWidth || 0,
    borderColor: theme.palette.secondary.dark,
    borderStyle: "solid",
    padding: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      backgroundColor: "none",
      padding: theme.spacing(0),
      marginTop: theme.spacing(0),
    },
    [theme.breakpoints.up("md")]: {
      backgroundColor: theme.palette.background.default,
    },
  };
});
