import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import StartupOsTextField from "../StartupOsInput/StartupOsTextField";

export default function TextInputField({
  label,
  handleBlur,
  inputKey,
  handleChange,
  helperText,
  value,
  maxLength,
  minLength,
  handleClick,
  required,
  placeholder,
  InputProps,
}) {
  return (
    <>
      <StartupOsTextField
        label={label}
        placeholder={placeholder || ""}
        spellCheck="true"
        fullWidth
        onBlur={(e, type) => {
          handleBlur(e, inputKey);
        }}
        onChange={(e, type) => {
          handleChange(e, inputKey);
        }}
        InputProps={InputProps}
        error={!!helperText}
        helperText={helperText || (required && "*required")}
        value={value}
        inputProps={{ maxLength: maxLength, minLength: minLength }}
        sx={{
          "& .MuiOutlinedInput-notchedOutline": { borderRadius: 1 },
        }}
        onClick={handleClick}
      />
    </>
  );
}
