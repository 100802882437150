import { Box, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import OnboardingCardSelector from "../../../../components/Onboarding/OnboardingCardSelector";
import PageTitle from "../../../../components/Onboarding/PageTitle";
import {
  WHO_ARE_YOU_BACKDOOR_CARD_ITEMS,
  WHO_ARE_YOU_CARD_ITEMS,
} from "../../../../constants/updated-onboarding";
import { useSelector } from "react-redux";

export default function WhoAreYouBackdoor({ setComplete }) {
  const [userResponse, setUserResponse] = useState("");
  const savedData = useSelector(state => state?.onboarding?.role);

  useEffect(() => {
    if (savedData) {
      setUserResponse(savedData);
    }
  }, [savedData]);

  const callComplete = (complete, data) => {
    if (setComplete) {
      setComplete(complete, data);
    }
  };

  useEffect(() => {
    if (userResponse) {
      callComplete(1, { role: userResponse });
    } else {
      callComplete(0, { role: null });
    }
  }, [userResponse]);

  return (
    <Box>
      <PageTitle
        title={"Who are you?"}
        description={
          "We are open today for founders only. Check back soon to signup for the role that best describes you on your current journey. (If you have multiple roles, don't worry— you'll be able to add more later.)"
        }
        hideBackButton
      />
      <Box sx={{ my: 5 }}>
        <OnboardingCardSelector
          cardItems={WHO_ARE_YOU_BACKDOOR_CARD_ITEMS}
          flex={1 / 3}
          gridColWidth={7}
          setUserResponse={setUserResponse}
          userResponse={userResponse}
          savedData={savedData}
        />
      </Box>
    </Box>
  );
}
