import React, { Suspense, useEffect } from "react";
import { Box } from "@mui/material";
import useDimensions from "react-cool-dimensions";
import ToolTwoCol from "../../components/layout/ToolTwoCol";

// Product Market Fit Content
const ProductMarketFitExpectations = React.lazy(() => import("./ProductMarketFit/product-market-fit").then((module) => ({ default: module.Expectations })));
const ProductMarketFitContents = React.lazy(() => import("./ProductMarketFit/product-market-fit").then((module) => ({ default: module.Contents })));
const ProductMarketFitBestPractices = React.lazy(() => import("./ProductMarketFit/product-market-fit").then((module) => ({ default: module.BestPractices })));
const ProductMarketFitImportanceForStartup = React.lazy(() =>
	import("./ProductMarketFit/product-market-fit").then((module) => ({ default: module.ImportanceForStartup }))
);
const ProductMarketFitPreview = React.lazy(() => import("./ProductMarketFit/product-market-fit").then((module) => ({ default: module.Preview })));

// Net Promoter Score (NPS) Survey Content
const NPSExpectations = React.lazy(() => import("./NPS/nps").then((module) => ({ default: module.Expectations })));
const NPSContents = React.lazy(() => import("./NPS/nps").then((module) => ({ default: module.Contents })));
const NPSBestPractices = React.lazy(() => import("./NPS/nps").then((module) => ({ default: module.BestPractices })));
const NPSImportanceForStartup = React.lazy(() => import("./NPS/nps").then((module) => ({ default: module.ImportanceForStartup })));
const NPSPreview = React.lazy(() => import("./NPS/nps").then((module) => ({ default: module.Preview })));

//Likert Scale Survey Content
const LikertScaleExpectations = React.lazy(() => import("./LikertScale/likert-scale").then((module) => ({ default: module.Expectations })));
const LikertScaleContents = React.lazy(() => import("./LikertScale/likert-scale").then((module) => ({ default: module.Contents })));
const LikertScaleBestPractices = React.lazy(() => import("./LikertScale/likert-scale").then((module) => ({ default: module.BestPractices })));
const LikertScaleImportanceForStartup = React.lazy(() => import("./LikertScale/likert-scale").then((module) => ({ default: module.ImportanceForStartup })));
const LikertScalePreview = React.lazy(() => import("./LikertScale/likert-scale").then((module) => ({ default: module.Preview })));

//Ad Testing Content
const AdTestingExpectations = React.lazy(() => import("./AdTesting/ad-testing").then((module) => ({ default: module.Expectations })));
const AdTestingContents = React.lazy(() => import("./AdTesting/ad-testing").then((module) => ({ default: module.Contents })));
const AdTestingBestPractices = React.lazy(() => import("./AdTesting/ad-testing").then((module) => ({ default: module.BestPractices })));
const AdTestingImportanceForStartup = React.lazy(() => import("./AdTesting/ad-testing").then((module) => ({ default: module.ImportanceForStartup })));
const AdTestingPreview = React.lazy(() => import("./AdTesting/ad-testing").then((module) => ({ default: module.Preview })));

//Logo Testing Content
const LogoTestingExpectations = React.lazy(() => import("./LogoTesting/logo-testing").then((module) => ({ default: module.Expectations })));
const LogoTestingContents = React.lazy(() => import("./LogoTesting/logo-testing").then((module) => ({ default: module.Contents })));
const LogoTestingBestPractices = React.lazy(() => import("./LogoTesting/logo-testing").then((module) => ({ default: module.BestPractices })));
const LogoTestingImportanceForStartup = React.lazy(() => import("./LogoTesting/logo-testing").then((module) => ({ default: module.ImportanceForStartup })));
const LogoTestingPreview = React.lazy(() => import("./LogoTesting/logo-testing").then((module) => ({ default: module.Preview })));

function LearnIt() {
	const { observe, width } = useDimensions({
		onResize: ({ width }) => {},
	});
	useEffect(() => {
		observe();
	}, [observe]);

	const getLearnItLeftComponents = () => {
		const currentPath = window.location.pathname;
		const parts = currentPath.split("/");
		const serveyId = parts[parts.length - 1];
		if (!serveyId) return null; // TODO -- Need "sorry was unable to load at this time. Message."

		switch (serveyId) {
			case "2":
				return (
					<React.Fragment>
						<NPSExpectations />
						<NPSContents />
						<NPSBestPractices />
					</React.Fragment>
				);
			case "4":
				return (
					<React.Fragment>
						<ProductMarketFitExpectations />
						<ProductMarketFitContents />
						<ProductMarketFitBestPractices />
					</React.Fragment>
				);

			case "5":
				return (
					<React.Fragment>
						<LikertScaleExpectations />
						<LikertScaleContents />
						<LikertScaleBestPractices />
					</React.Fragment>
				);
			case "8":
				return (
					<React.Fragment>
						<LogoTestingExpectations />
						<LogoTestingContents />
						<LogoTestingBestPractices />
					</React.Fragment>
				);
			case "9":
				return (
					<React.Fragment>
						<AdTestingExpectations />
						<AdTestingContents />
						<AdTestingBestPractices />
					</React.Fragment>
				);

			default:
				return null; // TODO -- Need "sorry was unable to load at this time. Message."
		}
	};
	const getLearnItRightComponents = () => {
		const currentPath = window.location.pathname;
		const parts = currentPath.split("/");
		const serveyId = parts[parts.length - 1];
		if (!serveyId) return null; // TODO -- Need "sorry was unable to load at this time. Message."

		switch (serveyId) {
			case "2":
				return (
					<React.Fragment>
						<NPSImportanceForStartup />
						<NPSPreview />
					</React.Fragment>
				);
			case "4":
				return (
					<React.Fragment>
						<ProductMarketFitImportanceForStartup />
						<ProductMarketFitPreview />
					</React.Fragment>
				);

			case "5":
				return (
					<React.Fragment>
						<LikertScaleImportanceForStartup />
						<LikertScalePreview />
					</React.Fragment>
				);
			case "8":
				return (
					<React.Fragment>
						<LogoTestingImportanceForStartup />
						<LogoTestingPreview />
					</React.Fragment>
				);
			case "9":
				return (
					<React.Fragment>
						<AdTestingImportanceForStartup />
						<AdTestingPreview />
					</React.Fragment>
				);

			default:
				return null; // TODO -- Need "sorry was unable to load at this time. Message."
		}
	};

	return (
		<Box ref={observe}>
			<ToolTwoCol
				left={<Suspense fallback={<Box>Loading...</Box>}>{getLearnItLeftComponents()}</Suspense>}
				right={<Suspense fallback={<Box>Loading...</Box>}>{getLearnItRightComponents()}</Suspense>}
			/>
		</Box>
	);
}

export default LearnIt;
