import { Box } from "@mui/material";
import React, { useEffect } from "react";

import { ROADRUNNERFADEDICON } from "../../../../constants";
import theme from "../../../../constants/Theme";
import { getCharDateMonth, getRemainingDays } from "../../../../utils/date";

const DemoDayProgress = ({ value, demoDate, id, textTheme, demoDateRange }) => {
  const gradientTransform = `rotate(90)`;

  useEffect(() => {
    var max = -219.99078369140625;
    var ele =
      document.querySelectorAll(".progress-bar")[
        id === "demoDayProgressMobile" ? 1 : 0
      ];

    var percent = ele.getAttribute("data-progress");

    const fill = ele.querySelector(".fill");

    fill.setAttribute(
      "style",
      "stroke-dashoffset: " + ((100 - percent) / 100) * max
    );

    fill.setAttribute("stroke", `url(#${id})`);

    var svgTextElement = document.getElementById(id + "-progresstext");
    var textNode = svgTextElement.childNodes[0];
    textNode.nodeValue =
      getRemainingDays(demoDate) >= 0 ? getRemainingDays(demoDate) : 0;

    var svgProgressTextElement = document.getElementById(id + "-progressdate");
    var progressTextNode = svgProgressTextElement.childNodes[0];
    svgProgressTextElement.style.textTransform = "uppercase";
    progressTextNode.nodeValue = getCharDateMonth(demoDate);
  }, [value, demoDate]);

  const getProgressValue = () => {
    const updateValue =
      100 - (getRemainingDays(demoDate) / demoDateRange) * 100;

    return updateValue <= 100 ? updateValue : 100;
  };

  return (
    <Box sx={{ maxWidth: "120px", maxHeight: "120px", mx: "auto" }}>
      <svg style={{ height: 0, width: 0 }}>
        <defs>
          <linearGradient id={id} gradientTransform={gradientTransform}>
            <stop
              offset="0%"
              stopColor={theme.palette.secondary.progressGradient1}
            />
            <stop
              offset="100%"
              stopColor={theme.palette.secondary.progressGradient2}
            />
          </linearGradient>
        </defs>
      </svg>

      <svg
        class="progress-bar"
        data-progress={getProgressValue()}
        x="0px"
        y="0px"
        viewBox="0 0 80 80"
      >
        <path class="track" d="M5,40a35,35 0 1,0 70,0a35,35 0 1,0 -70,0" />
        <path class="fill" d="M5,40a35,35 0 1,0 70,0a35,35 0 1,0 -70,0" />
        <image
          href={ROADRUNNERFADEDICON}
          style={{
            width: "80%",
            height: "80%",
          }}
          x="10%"
          y="10"
        ></image>
        <text
          x="40"
          y="22"
          text-anchor="middle"
          font-size="8px"
          font-family="PoppinsRegular"
          id={`${id}-progressdate`}
          fill={textTheme}
        >
          MAY 8
        </text>
        <text
          x="40"
          y="51"
          text-anchor="middle"
          id={`${id}-progresstext`}
          font-size="28px"
          font-family="PoppinsSemiBold"
          fill={textTheme}
        >
          0
        </text>
        <text
          x="40"
          y="65"
          text-anchor="middle"
          font-size="7px"
          font-family="PoppinsRegular"
          fill={textTheme}
        >
          Days Left
        </text>
      </svg>
    </Box>
  );
};

export default DemoDayProgress;
