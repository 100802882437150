import React, { useState, useEffect } from "react";
import {
  Typography,
  ListItem,
  ListItemIcon,
  ListItemText,
  List,
} from "@mui/material";
import TooltipWrapper from "../Tooltip";

export default function HamburgerList({ listItem, active, handleClick }) {
  return (
    <List>
      {listItem.map((item, i) => {
        return (
          <>
            {item.link ? (
              <ListItem
                sx={theme => ({
                  cursor: "pointer",
                  p: 2,
                  borderRadius: theme.shape.standard25.borderRadius,
                  color: theme.palette.primary.sysLight,
                  border:
                    active == item.key &&
                    `1px solid ${theme.palette.primary.sysLight}`,
                  "&:hover": {
                    border: `1px solid ${theme.palette.primary.sysLight}`,
                    borderRadius: theme.shape.standard25.borderRadius,
                    color: theme.palette.primary.sysLight,
                  },
                })}
                disablePadding
                onClick={() => handleClick(item)}
              >
                <ListItemIcon
                  sx={theme => ({
                    color:
                      active === item.key
                        ? theme.palette.primary.sysLight
                        : theme.palette.text.onSurfaceVariant,
                  })}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText>
                  <Typography
                    variant="nav_button"
                    sx={theme => ({
                      color:
                        active === item.key && theme.palette.primary.sysLight,
                    })}
                  >
                    {item.label}
                  </Typography>
                </ListItemText>
              </ListItem>
            ) : (
              <TooltipWrapper>
                <ListItem
                  sx={theme => ({
                    cursor: "pointer",
                    p: 2,
                    borderRadius: theme.shape.standard.borderRadius,
                    color: theme.palette.primary.sysLight,
                    "&:hover": {
                      border: `1px solid ${theme.palette.primary.sysLight}`,
                      borderRadius: theme.shape.standard25.borderRadius,
                      color: theme.palette.primary.sysLight,
                    },
                  })}
                  disablePadding
                >
                  <ListItemIcon
                    sx={theme => ({
                      color: theme => theme.palette.text.onSurfaceVariant,
                    })}
                  >
                    {item.icon}
                  </ListItemIcon>

                  <ListItemText>
                    <Typography
                      variant="nav_button"
                      sx={theme => ({
                        color:
                          active === item.key && theme.palette.primary.sysLight,
                      })}
                    >
                      {item.label}
                    </Typography>
                  </ListItemText>
                </ListItem>
              </TooltipWrapper>
            )}
          </>
        );
      })}
    </List>
  );
}
