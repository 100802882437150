import React, { useState } from "react";
import { Box, Grid } from "@mui/material";

import { NewOnboardCard, StepTitle, Stepper } from "../ui";
import { USER_TYPES } from "../../../../utils/userTypes";
import Icon from "../../Assets/onboarding-step.svg";
import { FounderIcon, MentorIcon, InvestorIcon, CorporateIcon } from "../icons";

const ACCOUNT_TYPES = [
	{ id: 0, icon: FounderIcon, title: "Founder", type: USER_TYPES.STARTUP_OWNER, desc: "I am the owner or co-owner of a startup or startup idea!" },
	{ id: 1, icon: MentorIcon, title: "Mentor", type: USER_TYPES.MENTOR_USER, desc: "I am here to guide startups & help them grow!" },
	{ id: 2, icon: InvestorIcon, title: "Investor", type: USER_TYPES.INVESTOR_USER, desc: "I am here to help startups grow financially." },
	{ id: 3, icon: CorporateIcon, title: "Corporate", type: USER_TYPES.CORPORATE_USER, desc: "I’m here to partner with startups." },
];

export const SelectRole = ({ step, totalStep, onType }) => {
	const [selectedType, setSelectedType] = useState(null);

	const handleAccountType = (index) => {
		setSelectedType(index);
		onType(ACCOUNT_TYPES.filter((item) => item.id === index)[0].type);
	};

	return (
		<Box width="100%">
			<Stepper step={step} totalStep={totalStep} />
			<StepTitle title="Define and select your role" icon={Icon} tooltip="" />
			<Box display="flex" justifyContent="center">
				<Grid container justifyContent="center" spacing="32px">
					{ACCOUNT_TYPES.map((account) => (
						<Grid key={account.id} item display="flex" justifyContent="center" xs={12} md={3}>
							<NewOnboardCard
								key={account.id}
								id={account.id}
								title={account.title}
								desc={account.desc}
								selectedId={selectedType}
								CardIcon={account.icon}
								onSelect={handleAccountType}
							/>
						</Grid>
					))}
				</Grid>
			</Box>
		</Box>
	);
};
