import * as actionTypes from "../../actions/actionTypes";
import { updateObject } from "../../../utils/updateObject";

const initialState = {
	sidebarWidth: 0,
};

const applicationProps = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SET_SIDEBAR_WIDTH: {
			if (action.payload === null) return state;
			return updateObject(state, { sidebarWidth: action.payload });
		}
		case actionTypes.SET_SIDEBAR_OPEN_PREF: {
			if (action.payload === null) return state;
			return updateObject(state, { sidebarOpenPref: action.payload });
		}
		default:
			return state;
	}
};
export default applicationProps;
