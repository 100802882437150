import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import OnboardingCardSelector from "../../../../components/Onboarding/OnboardingCardSelector";
import PageTitle from "../../../../components/Onboarding/PageTitle";
import { WHAT_TYPE_OF_INVESTOR } from "../../../../constants/updated-onboarding";
import { useSelector } from "react-redux";

export default function WhatTypeOfInvestor({ setComplete }) {
  const [userResponse, setUserResponse] = useState("");
  const savedData = useSelector(state => state?.onboarding?.investorType);

  useEffect(() => {
    if (savedData) {
      setUserResponse(savedData);
    }
  }, [savedData]);

  const callComplete = (complete, data) => {
    if (setComplete) {
      setComplete(complete, data);
    }
  };

  useEffect(() => {
    if (userResponse) {
      callComplete(1, { investorType: userResponse });
    } else {
      callComplete(0, { investorType: null });
    }
  }, [userResponse]);

  return (
    <Box>
      <PageTitle title={"What type of investor are you?"} />
      <Box sx={{ my: 5 }}>
        <OnboardingCardSelector
          cardItems={WHAT_TYPE_OF_INVESTOR}
          flex={1 / 3}
          gridColWidth={8}
          setUserResponse={setUserResponse}
          userResponse={userResponse}
          savedData={savedData}
        />
      </Box>

      <Box sx={{ mt: 15 }} textAlign={"center"}>
        <Box sx={{ px: 20, mb: 6 }}>
          <Typography
            variant="body_large"
            sx={theme => ({
              fontStyle: "italic",
              color: theme.palette.secondary.helperText,
            })}
          >
            All StartupOS investors are required to be accredited. By continuing
            to create an investor account, you agree to submit an accredited
            investor application.
          </Typography>{" "}
        </Box>
      </Box>
    </Box>
  );
}
