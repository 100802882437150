import React, { useContext } from "react";
import { Card as PowerUpCard } from "../../components/cards/Card";
import { PowerUPContext } from "../../PowerUPController";
import JsxParser from "react-jsx-parser";
import { Box, Typography } from "@mui/material";

const ContentBlock = ({ key, title, description }) => (
	<Box
		key={key}
		sx={{
			display: "flex",
			padding: "1.5rem",
			flexDirection: "column",
			alignItems: "flex-start",
			gap: "1.5rem",
			alignSelf: "stretch",
			borderRadius: "1.5rem",
			background: "#F5F9FA",
		}}
	>
		<Typography variant="Text/sm/Semibold" component={"p"}>
			<JsxParser components={{ Typography }} jsx={title} />
		</Typography>
		<Typography variant="Text/sm/Regular" component={"p"}>
			<JsxParser components={{ Typography }} jsx={description} />
		</Typography>
	</Box>
);

function Contents() {
	const { moduleSteps } = useContext(PowerUPContext);

	return (
		<PowerUpCard>
			<Typography variant="Text/md/Semibold" component={"p"}>
				Contents
			</Typography>

			{moduleSteps?.map((stepObj) => (
				<ContentBlock key={stepObj?.id} title={stepObj?.title} description={stepObj?.description} />
			))}
		</PowerUpCard>
	);
}

export default Contents;
