import React, { forwardRef, useImperativeHandle } from "react";
import { Typography, Box, Divider } from "@mui/material";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import theme from "../../../constants/Theme";
import { PROFILE_PLACEHOLDER } from "../../../constants/image";
import { getCurrentDate } from "../../../utils/date";
import { LOGOICON } from "../../../constants/image";
import FormSectionHeader from "../PowerUp/FormSectionHeader";
import ScriptEditor from "../../../common/ScriptEditor";

const AggregateNotesPdf = forwardRef(({ interviewList }, ref) => {
  useImperativeHandle(ref, () => ({
    async handleExport(type) {
      const element = document.getElementById("aggregateNotes");
      const canvas = await html2canvas(element, {
        useCORS: true,
        proxy: "http://d3rm35snnwgfg3.cloudfront.net",
        logging: true,
        onclone: clonedDoc => {
          clonedDoc.getElementById("aggregateNotes").style.display = "block";
          clonedDoc.getElementById("aggregateNotes").style.maxHeight =
            "inherit";
        },
      });
      const data = canvas.toDataURL("image/png");
      if (type === "img") {
        const link = document.createElement("a");
        link.href = data;
        link.download = "Aggregated Notes.png";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        var imgWidth = 205;

        var pageHeight = 450;

        var imgHeight = (canvas.height * imgWidth) / canvas.width;

        var heightLeft = imgHeight;

        var doc = new jsPDF("p", "mm", "", false);

        var position = 0;

        pageHeight = doc.internal.pageSize.getHeight();

        doc.addImage(data, "PNG", 0, position, imgWidth, imgHeight);

        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;

          doc.addPage();

          doc.addImage(data, "PNG", 0, position, imgWidth, imgHeight);

          heightLeft -= pageHeight;
        }

        doc.save("Aggregated Notes.pdf");
      }
    },
  }));

  return (
    <Box
      ref={ref}
      id="aggregateNotes"
      sx={{
        display: "none",
        maxHeight: 0,
        p: 5,
      }}
    >
      <Box>
        <FormSectionHeader heading="Idea Validation Interview" />
        <Divider
          sx={{
            background: theme => theme.palette.secondary.dark,
            [theme.breakpoints.down("md")]: {
              background: theme => theme.palette.secondary.borderLight,
            },
          }}
        />
      </Box>
      {interviewList.map((interview, index) => {
        return interview.selected ? (
          <Box>
            <Box sx={{ display: "flex", alignItems: "center", mt: 5 }}>
              <Box
                component="img"
                id="profile_img"
                src={PROFILE_PLACEHOLDER}
              ></Box>

              <Box sx={{ ml: 2 }}>
                <Typography
                  variant="bodyLarge"
                  sx={{ color: "secondary.black" }}
                >
                  {interview.name}
                </Typography>
              </Box>
              <Box sx={{ py: 1, ml: "auto" }}>
                <Typography variant="bodyMedium">
                  {getCurrentDate(new Date(interview.scheduledDate))}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ mt: 5 }}>
              <Typography variant="largeTitle">Notes Recorded</Typography>
            </Box>
            <Box sx={{ mt: 2 }}>
              <Typography variant="bodyMedium">
                <ScriptEditor
                  initialValue={
                    interview.records.filter(itm => itm.key === "notes")[0]
                      .value
                  }
                  readOnly
                  inlineToolbar={false}
                  inlineToolbarControls={[]}
                  controls={[]}
                  onChange={() => {}}
                />
              </Typography>
            </Box>

            {interviewList.length !== 1 &&
            interviewList.length - 1 !== index ? (
              <Divider
                sx={{
                  background: `${theme.palette.secondary.outlineXLight}`,
                  mt: 3,
                }}
              />
            ) : (
              ""
            )}
          </Box>
        ) : (
          ""
        );
      })}
      <Box style={{ textAlign: "center", padding: "20px" }}>
        <Box src={LOGOICON} component="img"></Box>
      </Box>
    </Box>
  );
});

export default AggregateNotesPdf;
