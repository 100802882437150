import * as actionTypes from "../../actions/actionTypes";
import { updateObject } from "../../../utils/updateObject";

const initialState = {
	loading: false,
	startupList: [],
	openStartupObj: {},
	scoutingOpen: false,
	benchmarkDetailsOpen: false,
	addToWatchlistOpen: false,
	addToPortfolioOpen: false,
	removeFromWatchlistOpen: false,
	removeFromPortfolioOpen: false,
	slidingConfirmationObject: {},
	savedSearchesData: [],
	filteredValue: [],
	savedFilterEditData: {},
	FilterDrawerOpen: false,
	SavedSearchesOpen: false,
	ScoreReportObj: {},
	fundingStagesOpen: false,
	industryList: [],
	businessTypeList: [],
	revenueTypeList: [],
	productOfferingList: [],
	industryDetailsOpen: false,
	selectedValues: [],
	partiallySelected: [],
	scoreTrendRangeSelection: "1M",
	scoreTrendRangeLoading: false,
	investorImpressionsData: {},
};

const investorDashboard = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SET_INVESTOR_DASHBOARD_DATA: {
			return action.payload === null ? initialState : updateObject(state, action.payload);
		}
		default:
			return state;
	}
};
export default investorDashboard;
