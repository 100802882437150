import { Cancel, Check, CheckCircle, CloseOutlined, CloseRounded, Info, PauseCircle, Warning } from "@mui/icons-material";
import { Box, CircularProgress, Divider, Skeleton, Typography } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { CSSTransition } from "react-transition-group";
import InfoHelper from "../../../../../components/Founder/InfoHelper";

const LoadingItemParent = ({ title, timeout, complete, children, loading, warning, warningText }) => (
	<CSSTransition in={true} appear={true} timeout={timeout} classNames="open">
		<Box
			sx={{
				transition: "all 0.3s ease-in-out",
				opacity: 0,
				transform: "translateX(50px)",
				"&.open-exit-done": {
					opacity: 0,
					transform: "translateX(50px)",
				},
				"&.open-exit-active": {
					opacity: 1,
					transform: "translateX(0px)",
				},
				"&.open-enter-done": {
					opacity: 1,
					transform: "translateX(0px)",
				},
				"&.open-enter-active": {
					opacity: 1,
					transform: "translateX(0px)",
				},
			}}
		>
			<Box
				sx={{
					padding: "1.5rem",
					borderRadius: "1.5rem",
					background: "#F9F9F9",
					boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
				}}
			>
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						gap: "16px",
						alignItems: "center",
					}}
				>
					<Box sx={{ flex: 1 }}>
						<Typography variant="Text/sm/Semibold">{title}</Typography>
					</Box>
					<Box>
						{loading && !complete ? (
							<CircularProgress size={25} />
						) : !loading && complete && !warning ? (
							<CheckCircle sx={{ height: "25px", width: "25px" }} color="success" />
						) : !loading && complete && warning ? (
							<Warning sx={{ height: "25px", width: "25px" }} color="warning" />
						) : (
							<PauseCircle sx={{ height: "25px", width: "25px", color: "lightgray" }} />
						)}
					</Box>
				</Box>
				{!loading && complete && warning && warningText && (
					<Box>
						<Typography variant="Text/sm/Regular" color="error">
							{warningText}
						</Typography>
					</Box>
				)}

				<Divider sx={{ my: 1, color: "darkgray" }} />
				{children}
			</Box>
		</Box>
	</CSSTransition>
);

const LoadingItemChild = ({ title, timeout, complete, loading, warning, message }) => (
	<CSSTransition in={true} appear={true} timeout={timeout} classNames="open">
		<Box
			sx={{
				transition: "all 0.3s ease-in-out",
				opacity: 0,
				transform: "translateX(50px)",
				"&.open-exit-done": {
					opacity: 0,
					transform: "translateX(50px)",
				},
				"&.open-exit-active": {
					opacity: 1,
					transform: "translateX(0px)",
				},
				"&.open-enter-done": {
					opacity: 1,
					transform: "translateX(0px)",
				},
				"&.open-enter-active": {
					opacity: 1,
					transform: "translateX(0px)",
				},
			}}
		>
			<Box
				sx={{
					display: "flex",
					justifyContent: "space-between",
					gap: "16px",
					alignItems: "center",
				}}
			>
				<Box sx={{ flex: 1 }}>
					<Typography variant="Text/xs/Regular">{title}</Typography>
				</Box>
				<Box>
					{loading && !complete ? (
						<CircularProgress size={25} />
					) : !loading && complete && !warning ? (
						<CheckCircle sx={{ height: "25px", width: "25px" }} color="success" />
					) : !loading && complete && warning ? (
								message ? <InfoHelper
								title={"Missing Latest PowerUP Version"}
								body={message}
							/> : <Warning sx={{ height: "25px", width: "25px" }} color="warning" />
					) : (
						<PauseCircle sx={{ height: "25px", width: "25px", color: "lightgray" }} />
					)}
				</Box>
			</Box>
		</Box>
	</CSSTransition>
);

function PitchDeckGenerationAnimation({ stateObj, type }) {
	console.log(stateObj)
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				width: "100%",
			}}
		>
			{type == "powerupdata" && (stateObj?.loadingData?.loading || stateObj?.loadingData?.complete) && (
				<Box sx={{ display: "flex", flexDirection: "column", gap: ".37rem", mb: "20px" }}>
					<LoadingItemParent
						title={"Profile and PowerUP Data"}
						timeout={100}
						loading={stateObj?.loadingData?.loading}
						complete={stateObj?.loadingData?.complete}
						warning={!stateObj?.story?.versionID || !stateObj?.valueProp?.versionID || !stateObj?.tss?.versionID || !stateObj?.probSol?.versionID}
						warningText={
							"Some data is missing. You can still generate a pitch deck, but please note that important slides will be empty. We strongly encourage completing the missing PowerUPs before continuing."
						}
					>
						<LoadingItemChild
							title={"Startup Profile"}
							timeout={100}
							loading={stateObj?.profile?.loading}
							complete={!stateObj?.loadingData?.loading && stateObj?.profile?.complete}
						/>
						<LoadingItemChild
							title={"Founder Story"}
							timeout={150}
							loading={stateObj?.story?.loading}
							complete={!stateObj?.loadingData?.loading && stateObj?.story?.complete}
							warning={!stateObj?.loadingData?.loading && !stateObj?.story?.versionID}
							message={!stateObj?.loadingData?.loading && !stateObj?.story?.versionID && stateObj?.story?.message}
						/>
						<LoadingItemChild
							title={"Value Proposition"}
							timeout={200}
							loading={stateObj?.valueProp?.loading}
							complete={!stateObj?.loadingData?.loading && stateObj?.valueProp?.complete}
							warning={!stateObj?.loadingData?.loading && !stateObj?.valueProp?.versionID}
							message={!stateObj?.loadingData?.loading && !stateObj?.valueProp?.versionID && stateObj?.valueProp?.message}

						/>
						<LoadingItemChild
							title={"TamSamSom"}
							timeout={250}
							loading={stateObj?.tss?.loading}
							complete={!stateObj?.loadingData?.loading && stateObj?.tss?.complete}
							warning={!stateObj?.loadingData?.loading && !stateObj?.tss?.versionID}
							message={!stateObj?.loadingData?.loading && !stateObj?.tss?.versionID && stateObj?.tss?.message}

						/>
						<LoadingItemChild
							title={"Problem Solution Fit"}
							timeout={300}
							loading={stateObj?.probSol?.loading}
							complete={!stateObj?.loadingData?.loading && stateObj?.probSol?.complete}
							warning={!stateObj?.loadingData?.loading && !stateObj?.probSol?.versionID}
							message={!stateObj?.loadingData?.loading && !stateObj?.probSol?.versionID && stateObj?.probSol?.message}

						/>
						<LoadingItemChild
							title={"Persona Builder Personas"}
							timeout={350}
							loading={stateObj?.persona?.loading}
							complete={!stateObj?.loadingData?.loading && stateObj?.persona?.complete}
							warning={!stateObj?.loadingData?.loading && !stateObj?.persona?.versionID}
							message={!stateObj?.loadingData?.loading && !stateObj?.persona?.versionID && stateObj?.persona?.message}
						/>
					</LoadingItemParent>
				</Box>
			)}

			{type == "AI" && (stateObj?.AILoading?.loading || stateObj?.AILoading?.complete) && (
				<Box sx={{ display: "flex", flexDirection: "column", gap: ".37rem", mb: "20px" }}>
					<LoadingItemParent title={"AI Enhancement"} timeout={100} loading={stateObj?.AILoading?.loading} complete={stateObj?.AILoading?.complete}>
						<LoadingItemChild
							title={"Attention Grabbing Hook"}
							timeout={100}
							loading={stateObj?.hookAI?.loading}
							complete={stateObj?.hookAI?.complete}
						/>
						<LoadingItemChild
							title={"Summarizing Founder Story"}
							timeout={150}
							loading={stateObj?.storyAI?.loading}
							complete={stateObj?.storyAI?.complete}
						/>
						<LoadingItemChild
							title={"Breaking Down Problem Solution Fit"}
							timeout={200}
							loading={stateObj?.probSolAI?.loading}
							complete={stateObj?.probSolAI?.complete}
						/>
						<LoadingItemChild
							title={"Summarizing Value Proposition"}
							timeout={250}
							loading={stateObj?.valuePropAI?.loading}
							complete={stateObj?.valuePropAI?.complete}
						/>
						<LoadingItemChild
							title={"Breaking Down Personas"}
							timeout={250}
							loading={stateObj?.personaAI?.loading}
							complete={stateObj?.personaAI?.complete}
						/>
						<LoadingItemChild
							title={"Summarizing TAM SAM SOM"}
							timeout={300}
							loading={stateObj?.tssAI?.loading}
							complete={stateObj?.tssAI?.complete}
						/>
						<LoadingItemChild
							title={"Finding Competitors"}
							timeout={350}
							loading={stateObj?.competitorsAI?.loading}
							complete={stateObj?.competitorsAI?.complete}
						/>
						<LoadingItemChild
							title={"Coordinating Financial Overview"}
							timeout={400}
							loading={stateObj?.fundingAI?.loading}
							complete={stateObj?.fundingAI?.complete}
						/>
						<LoadingItemChild
							title={"Refining Your Moat"}
							timeout={450}
							loading={stateObj?.moatAI?.loading}
							complete={stateObj?.moatAI?.complete}
						/>
					</LoadingItemParent>
				</Box>
			)}
		</Box>
	);
}

export default PitchDeckGenerationAnimation;
