import { Box, Skeleton } from "@mui/material";
import React, { Fragment } from "react";
import { CSSTransition } from "react-transition-group";

const ListSkeletonItem = ({ timeout }) => (
	<CSSTransition in={true} appear={true} timeout={timeout} classNames="open">
		<Box
			sx={{
				flex: 1,
				flexBasis: "40%",
				transition: "all 0.3s ease-in-out",
				opacity: 0,
				transform: "translateX(50px)",
				"&.open-exit-done": {
					opacity: 0,
					transform: "translateX(50px)",
				},
				"&.open-exit-active": {
					opacity: 1,
					transform: "translateX(0px)",
				},
				"&.open-enter-done": {
					opacity: 1,
					transform: "translateX(0px)",
				},
				"&.open-enter-active": {
					opacity: 1,
					transform: "translateX(0px)",
				},
			}}
		>
			<Skeleton
				variant="rounded"
				animation="wave"
				sx={{
					height: "150px",
					minWidth: "200px",
					borderRadius: "40px",
					"&::after": {
						animationDelay: `.${timeout}s`,
					},
				}}
			/>
		</Box>
	</CSSTransition>
);

function ListSkeleton() {
	return (
		<Fragment>
			<ListSkeletonItem timeout={100} />
			<ListSkeletonItem timeout={150} />
			<ListSkeletonItem timeout={200} />
			<ListSkeletonItem timeout={250} />
			<ListSkeletonItem timeout={300} />
			<ListSkeletonItem timeout={350} />
			<ListSkeletonItem timeout={400} />
			<ListSkeletonItem timeout={450} />
		</Fragment>
	);
}

export default ListSkeleton;
