import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  Box,
  IconButton,
} from "@mui/material";

import numeral from "numeral";
import { InlinePrimaryTile } from "./style";
import { EDITICON } from "../../../constants/image";
import { handleMixPanel } from "../../../utils/mixPanelEventHandling";

const InlinePrimaryTileComponent = ({
  currenttab,
  currenttabData,
  onRunwayDataSave,
}) => {
  const [edit, setEdit] = useState();

  const [formattedCash, setFormattedCash] = useState(currenttabData.cash);
  const [formattedBurnRate, setFormattedBurnRate] = useState(
    currenttabData.burnRate
  );

  useEffect(() => {
    setFormattedCash(numeral(currenttabData.cash)?.format("0,0"));
    setFormattedBurnRate(numeral(currenttabData.burnRate)?.format("0,0"));
  }, [currenttab, currenttabData]);

  const onChange = (e, type) => {
    const formattedNumber = numeral(e.target.value).value();

    const number = numeral(formattedNumber)?.format("0,0");

    if (type === "cash") {
      setFormattedCash(number);
    } else {
      setFormattedBurnRate(number);
    }
  };

  const onSaveHandler = () => {
    if (currenttab === "actual") {
      handleMixPanel(`Dashboard Calculator Used`, {
        "Calculator": "Actual"
      });
    }
    else if (currenttab === "whatIf") {
      handleMixPanel(`Dashboard Calculator Used`, {
        "Calculator": "What If"
      });
    }
    
    const cash = numeral(formattedCash).value();

    const burnRate = numeral(formattedBurnRate).value();
    onRunwayDataSave(cash, burnRate);

    setEdit(false);
  };

  return (
    <>
      {edit ? (
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <InlinePrimaryTile borderRadius="both" currenttab={currenttab}>
            <Grid lg={10} md={10} xs={10} sm={10} sx={{ px: 1 }}>
              <TextField
                sx={{ background: "white", width: "100%" }}
                variant="standard"
                label={edit === "cash" ? "Cash" : "Burn Rate"}
                value={edit === "cash" ? formattedCash : formattedBurnRate}
                onChange={e => onChange(e, edit)}
                spellCheck="true"
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      sx={{ fontWeight: "bold", fontSize: 40 }}
                    >
                      <Typography
                        variant="body_medium"
                        sx={{ pl: "2px" }}
                        color="secondary"
                      >
                        $
                      </Typography>
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  style: {
                    marginTop: 2,
                    marginLeft: 18,
                  },
                }}
                onKeyPress={event => {
                  if (event?.key === "-") {
                    event.preventDefault();
                  }
                }}
              />
            </Grid>
            <Grid lg={2} md={2} xs={2} sm={2} sx={{ pr: 9 }}>
              <Button variant="nofill" onClick={onSaveHandler}>
                Save
              </Button>
            </Grid>
          </InlinePrimaryTile>
        </Grid>
      ) : (
        <>
          <Grid item lg={6} md={6} sm={6} xs={6}>
            <InlinePrimaryTile
              currenttab={currenttab}
              borderRadius="left"
              onClick={() => setEdit("cash")}
            >
              <Box overflow={"hidden"}>
                <Typography>
                  <IconButton
                    sx={{ position: "absolute", top: 10, right: 10 }}
                    className="edit"
                  >
                    <img src={EDITICON} />
                  </IconButton>
                </Typography>
                <Typography variant="h6" textTransform={"uppercase"}>
                  CASH
                </Typography>
                <Typography
                  variant="headline1"
                  textOverflow={"ellipsis"}
                  overflow="hidden"
                  textTransform={"uppercase"}
                >
                  {numeral(formattedCash).format("$0,0[.]0a")}{" "}
                </Typography>
              </Box>
            </InlinePrimaryTile>
          </Grid>

          <Grid item lg={6} md={6} sm={6} xs={6}>
            <InlinePrimaryTile
              currenttab={currenttab}
              borderRadius="right"
              onClick={() => setEdit("burnRate")}
            >
              <Box overflow={"hidden"}>
                <IconButton
                  sx={{ position: "absolute", top: 10, right: 10 }}
                  className="edit"
                >
                  <img src={EDITICON} />
                </IconButton>
                <Typography variant="h6" textTransform={"uppercase"}>
                  BURN RATE
                </Typography>
                <Typography
                  variant="headline1"
                  textOverflow={"ellipsis"}
                  overflow="hidden"
                  textTransform={"uppercase"}
                >
                  {numeral(formattedBurnRate).format("$0,0[.]0a")}
                </Typography>
              </Box>
            </InlinePrimaryTile>
          </Grid>
        </>
      )}
    </>
  );
};

export default InlinePrimaryTileComponent;
