import React, { useState } from "react";
import { Grid, Typography, Box } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { generatePath, useHistory } from "react-router-dom";
import theme from "../../../../../constants/Theme";
//import StartupCard from "./StartupCard";
import CardController from "../../../../../containers/Network/Components/CardController";
import { saveUnsaveStartup } from "../../../Network/StartupProfile/functions/functions";
import { toastContainer } from "../../../../../utils/toast";
import messages from "../../../../../constants/messages";
import ROUTES_PATH from "../../../../../constants/routes";

const BrowseStartups = ({ startups, setStartups }) => {
	const history = useHistory();
	const [index, setIndex] = useState(0);

	const nextStep = () => {
		if (index < startups.length - 1) {
			setIndex(index + 1);
		}
	};

	const prevStep = () => {
		if (index > 0) {
			setIndex(index - 1);
		}
	};

	const updateStartupsObject = (startup) => {
		const currentStartupIndex = startups.findIndex((item) => item.id === startup.id);

		if (currentStartupIndex >= 0) {
			let allStartups = [...startups];
			let currentStartup = allStartups[currentStartupIndex];
			currentStartup = startup;
			allStartups[currentStartupIndex] = currentStartup;
			setStartups(allStartups);
		}
	};

	const handleViewProfile = (startup) => {
		if (startup?.id) {
			history.push({
				pathname: generatePath(ROUTES_PATH.STARTUP_PROFILE, {
					id: startup?.id,
				}),
			});
		} else {
			history.push(ROUTES_PATH.MY_STARTUP_PROFILE);
		}
	};

	const handleViewUserProfile = (startup) => {
		if (startup?.founderDetails?.id) {
			history.push({
				pathname: generatePath(ROUTES_PATH.ACCOUNT_PROFILE, {
					id: startup?.founderDetails?.id,
				}),
			});
		} else {
			history.push(ROUTES_PATH.MY_ACCOUNT_PROFILE);
		}
	};

	const handleBookmark = (startup) => {
		const data = {
			companyId: startup.id,
			status: !startup.isSaved,
			type: "SAVED",
		};
		saveUnsaveStartup(data)
			.then((result) => {
				if (result) {
					let newData = { ...startup, isSaved: !startup.isSaved };
					updateStartupsObject(newData);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	return (
		<>
			<Grid
				container
				sx={{
					maxWidth: theme.breakpoints.values.md,
					pt: 4,
				}}
				rowGap={2}
			>
				<Grid container>
					{startups?.slice(index, index + 1).map((startup, index) => (
						<Grid item xs={12} sm={12} md={12} lg={12} key={index}>
							<CardController
								startups
								cardObject={startup}
								bookmarked={false}
								handleViewProfile={handleViewProfile}
								handleViewFounderProfile={handleViewUserProfile}
								handleBookmark={handleBookmark}
								updateStartupsObject={updateStartupsObject}
							/>
						</Grid>
					))}
				</Grid>

				<Grid container justifyContent={"flex-end"} sx={{ mt: 1, display: { xs: "none", md: "none", lg: "flex" } }} columnGap={2}>
					{startups && startups.length > 0 && (
						<Grid item lg={3} md={3} sm={3} xs={4} display="flex">
							<Box sx={{ display: "flex", gap: theme.spacing(2), ml: 5 }}>
								{index !== 0 ? (
									<ArrowBackIosIcon onClick={prevStep} sx={{ cursor: "pointer" }} />
								) : (
									<ArrowBackIosIcon onClick={prevStep} sx={{ color: "secondary.light" }} />
								)}
								{index !== startups?.length - 1 ? (
									<ArrowForwardIosIcon onClick={nextStep} sx={{ cursor: "pointer" }} />
								) : (
									<ArrowForwardIosIcon onClick={nextStep} sx={{ color: "secondary.light" }} />
								)}
							</Box>
						</Grid>
					)}
				</Grid>
			</Grid>
		</>
	);
};

export default BrowseStartups;
