import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Box, Typography, TextField, FormControlLabel, Checkbox, Link, Button } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import GoogleIcon from "../../Assets/google.svg";
import FacebookIcon from "../../Assets/facebook.svg";
import LinkedinIcon from "../../Assets/linkedin.svg";
import NewOnboardLogo from "../../Assets/new-onboard-logo.svg";

import { BtnLogin, SocialBtn, FormElementStyle } from "./SignUp";
import ROUTES_PATH from "../../../../constants/routes";
import services from "../../../../services";
import { startupProfileProgress } from "../../../../utils/profilePercentageCalculation";
import { toastContainer } from "../../../../utils/toast";
import { manualLogin } from "../../../../utils/auth0";
import { loginGoogle, loginLinkedIn } from "../../../../utils/auth0-spa";
import LoadingButton from "../../../../common/Loadingbutton";
import { useThemeContext } from "../../../../constants/Theme/ThemeContext";
import { useDispatch, useSelector } from "react-redux";
import { setAuthData } from "../../../../modules/actions";

const schema = yup
	.object({
		email: yup.string().email().required("Email is required"),
		password: yup.string().min(8).required("Password is required"),
	})
	.required();

export const NewLogin = ({ onForgotPwd }) => {
	const history = useHistory();
	const [isRemember, setIsRemember] = useState(false);
	const [loginLoading, setLoginLoading] = useState(false);
	const [isUserDeactivated, setIsUserDeactivated] = useState(false);
	// const searchParams = new URLSearchParams(window.location.search);
	// const utm_source = searchParams.get("utm_source");
	// const utm_campaign = searchParams.get("utm_campaign");
	// const utm_medium = searchParams.get("utm_medium");
	// const partner = searchParams.get("partner");

	const { utmSource, utmCampaign, utmMedium, partner } = useSelector((store) => store.utmParams);

	const { appNameOverride, logoOverride, clearThemeObjects } = useThemeContext();

	useEffect(() => {
		partnerReset();
	}, []);
	const dispatch = useDispatch();
	const {
		handleSubmit,
		control,
		formState: { errors, isValid },
	} = useForm({
		mode: "all",
		defaultValues: {
			email: "",
			password: "",
		},
		resolver: yupResolver(schema),
	});
	useEffect(() => {
		dispatch(
			setAuthData({
				idToken: null,
			})
		);
	}, []);
	const onSubmit = (formData) => {
		try {
			setLoginLoading(true);
			const { email, password } = formData;

			if (isRemember) {
				localStorage.setItem("remember_me", email);
			} else {
				localStorage.removeItem("remember_me");
			}

			services
				.getControlRoleAccessApi(email)
				.then((result) => {
					window.dataLayer = window.dataLayer || [];
					window.dataLayer.push({
						event: "login",
						id: result?.data?.data?.id,
						name: result?.data?.data?.name,
						userType: result?.data?.data?.userType,
						email: result?.data?.data?.email,
						phone: result?.data?.data?.phone,
						primaryAccountId: result?.data?.data?.primaryAccountId,
						companyId: result?.data?.data?.companyModel?.id,
						companyName: result?.data?.data?.companyModel?.companyName,
						companyProfilePercentage: startupProfileProgress(result?.data?.data?.companyModel).totalPercentage,
						emailVerified: result?.data?.data?.emailVerified,
					});

					if (result.data?.code === 200) {
						let resultData = result?.data;

						if (resultData?.data?.companyModel?.entityStatus === "DEACTIVE" && resultData?.data?.emailVerified) {
							setIsUserDeactivated(true);
							setLoginLoading(false);
						} else {
							setIsUserDeactivated(false);

							handleAuth0Login({ email, password });
						}
					}
				})
				.catch((error) => {
					setLoginLoading(false);
					toastContainer(error?.response?.data?.message, "error");
				});
		} catch (err) {
			setLoginLoading(false);
			toastContainer(err?.error_description, "error");
		}
	};

	const handleAuth0Login = async (data) => {
		try {
			await manualLogin(data);
		} catch (err) {
			setLoginLoading(false);
			toastContainer(err?.error_description, "error");
		}
	};

	const handleRemember = (e) => {
		setIsRemember(e.target.checked);
	};

	const partnerReset = async () => {
		// Checks to see if there's a partner param.
		// If one is not found, cleanup any old session objects.

		try {
			const queryParams = new URLSearchParams(window.location.search);
			const partner = queryParams.get("partner");
			if (!partner) clearThemeObjects();
		} catch (e) {
			console.log("Unable to reset partner theme object. Failed with error:", e);
		}
	};

	const handleFacebookLogin = () => {};

	return (
		<Box width="400px" alignItems="center" display="flex" flexDirection="column" gap={4}>
			{appNameOverride ? (
				<Box sx={{ display: "flex", flexDirection: "column", gap: 1, alignItems: "center" }}>
					<Typography variant="Text/xl/Semibold" component={"h1"} textAlign={"center"}>
						Login to {appNameOverride}
					</Typography>
					<Box
						sx={{
							padding: "0.25rem 0.875rem",
							borderRadius: "6.25rem",
							backgroundColor: "#000",
							fontSize: "0.875rem",
							lineHeight: "1.25rem",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							width: "fit-content",
						}}
					>
						<Typography variant="Text/xs/Regular" sx={{ mr: 1, color: "white" }}>
							Powered by
						</Typography>
						<Box component={"img"} src={NewOnboardLogo} width={25} height={25} />
						<Typography variant="Text/xs/Semibold" sx={{ ml: "2px", color: "white" }}>
							StartupOS
						</Typography>
					</Box>
				</Box>
			) : (
				<Typography variant="growth_otherpercentage_text">Login to StartupOS</Typography>
			)}
			<form onSubmit={handleSubmit(onSubmit)}>
				<Box width="400px" display="flex" flexDirection="column" gap={2.5}>
					<Box>
						<Controller
							name="email"
							control={control}
							render={({ field: { onChange, value, name } }) => (
								<TextField
									fullWidth
									id="email"
									placeholder="Email Address"
									value={value}
									name={name}
									sx={{
										"& .MuiOutlinedInput-notchedOutline": {
											border: errors.email ? "1px solid #F04438 !important" : "1px solid #DDDDDD",
										},
									}}
									InputProps={{ style: FormElementStyle }}
									onChange={onChange}
								/>
							)}
						/>
						{errors.email && (
							<Typography variant="subhead2" sx={{ color: "#F04438" }}>
								{errors.email.message}
							</Typography>
						)}
					</Box>

					<Box>
						<Controller
							name="password"
							control={control}
							render={({ field: { onChange, value, name } }) => (
								<TextField
									fullWidth
									id="password"
									placeholder="Password"
									type="password"
									value={value}
									name={name}
									autoComplete="on"
									sx={{
										"& .MuiOutlinedInput-notchedOutline": {
											border: errors.password ? "1px solid #F04438 !important" : "1px solid #DDDDDD",
										},
									}}
									InputProps={{ style: FormElementStyle }}
									onChange={onChange}
								/>
							)}
						/>
						{errors.password && (
							<Typography variant="subhead2" sx={{ color: "#F04438" }}>
								{errors.password.message}
							</Typography>
						)}
					</Box>

					<LoadingButton type="submit" variant="DS1" disabled={!isValid} loading={loginLoading}>
						Login
					</LoadingButton>

					<FormControlLabel
						control={<Checkbox checked={isRemember} onChange={handleRemember} />}
						label={
							<Typography variant="Text/sm/Regular" color="#667085">
								Remember me
							</Typography>
						}
						sx={{ display: "flex", justifyContent: "center" }}
					/>

					<Typography variant="Text/sm/Regular" color="#667085" sx={{ textAlign: "center" }}>
						Forgot your password? &nbsp;
						<Link
							sx={{ cursor: "pointer", color: "#667085" }}
							onClick={() => {
								history.push(ROUTES_PATH.FORGOT_PASSWORD);
							}}
						>
							Click here
						</Link>
					</Typography>
				</Box>
			</form>

			<Typography variant="Text/sm/Semibold">OR</Typography>

			<Box width="400px" display="flex" flexDirection="column" gap={2.5}>
				<SocialBtn
					title="Continue with Google"
					icon={GoogleIcon}
					onClick={() => {
						loginGoogle({
							utm_source: utmSource,
							utm_medium: utmMedium,
							utm_campaign: utmCampaign,
							partner: partner,
						});
					}}
				/>
				{/* <SocialBtn title="Continue with Facebook" icon={FacebookIcon} onClick={handleFacebookLogin} /> */}
				<SocialBtn
					title="Continue with LinkedIn"
					icon={LinkedinIcon}
					onClick={() =>
						loginLinkedIn({
							utm_source: utmSource,
							utm_medium: utmMedium,
							utm_campaign: utmCampaign,
							partner: partner,
						})
					}
				/>
			</Box>

			<Typography
				variant="Text/sm/Regular"
				color="#667085"
				sx={{
					textAlign: "center",
					padding: "0 49px",
				}}
			>
				<Link sx={{ cursor: "pointer", color: "#667085" }} target="_blank" href="https://startupos.com/privacy-policy/">
					Privacy Policy
				</Link>
				&nbsp; | &nbsp;
				<Link sx={{ cursor: "pointer", color: "#667085" }} target="_blank" href="https://startupos.com/terms-of-service/">
					Terms of Service
				</Link>
			</Typography>
		</Box>
	);
};
