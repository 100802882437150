import * as actionTypes from "./actionTypes";

export const setStartupPublicProfileData = (payload) => {
	return (dispatch) => {
		dispatch({
			type: actionTypes.SET_STARTUP_PUBLIC_DATA,
			payload,
		});
	};
};

export const setAccountPublicPrifileData = (payload) => {
	return (dispatch) => {
		dispatch({
			type: actionTypes.SET_ACCOUNT_PUBLIC_DATA,
			payload,
		});
	};
};
