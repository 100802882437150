import { Box, TextField } from "@mui/material";
import styled from "@emotion/styled";

export const ProblemWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  minHeight: "100px",
  backgroundColor: theme.palette.secondary.greyBg,
  padding: theme.spacing(2),
  justifyContent: "center",
  borderRadius: theme.spacing(2),
  [theme.breakpoints.down("md")]: {
    border: `1px solid ${theme.palette.secondary.dark}`,
  },
}));

export const SolutionInput = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-input.MuiOutlinedInput-input": {
    display: "flex",
    width: "100%",
    minHeight: "100px",
    justifyContent: "center",
  },
  "& .css-1rxca4y-MuiFormLabel-root-MuiInputLabel-root": {
    marginTop: theme.spacing(5),
  },
  [theme.breakpoints.down("md")]: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: theme.palette.secondary.dark,
      },
    },
  },
}));
