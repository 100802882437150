import React from "react";
import { Switch, Route } from "react-router-dom";
import { ToolContainer } from "../../../containers/Tools/ToolContainer";

export default function ToolController() {
	return (
		<Switch>
			<Route path={`/workspace/tool/:id`} exact>
				<ToolContainer />
			</Route>
		</Switch>
	);
}
