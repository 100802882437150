import React from "react";
import { Switch, useRouteMatch, Route } from "react-router-dom";
import MVPPrioritizer from "../../../containers/NewPowerup/MVPPrioritizer";
import PowerupDetails from "../../../containers/Workspace/PowerupDetails";
import { PowerUPController as PowerUP_V2 } from "../../../containers/PowerUP/PowerUPController";
import { PowerPackController as PowerPack } from "../../../containers/PowerPack/PowerPackController";

export default function PowerupController({ userData, onLogout }) {
	const { url, path } = useRouteMatch();
	return (
		<Switch>
			{/* <Route path={`/workspace/powerUp/20`} exact>
				<MVPPrioritizer />
			</Route> */}
			{/* PowerUP Facelift -- All will eventually use simplified controller */}
			{/* Founder Story */}
			<Route path={`/workspace/powerUp/2`} exact>
				<PowerUP_V2 />
			</Route>
			{/* Elevator Pitch */}
			<Route path={`/workspace/powerUp/53`} exact>
				<PowerUP_V2 />
			</Route>
			{/* Value Prop */}
			<Route path={`/workspace/powerUp/1`} exact>
				<PowerUP_V2 />
			</Route>
			{/* Lean Canvas */}
			<Route path={`/workspace/powerUp/41`} exact>
				<PowerUP_V2 />
			</Route>
			{/* Exit Strategy & The Ask  */}
			<Route path={`/workspace/powerUp/13`} exact>
				<PowerUP_V2 />
			</Route>
			{/* Get Funded */}
			<Route path={`/workspace/powerUp/58`} exact>
				<PowerUP_V2 />
			</Route>
			{/* Discovery Interview */}
			<Route path={`/workspace/powerUp/63`} exact>
				<PowerUP_V2 />
			</Route>
			{/* Developing a Business Moat */}
			<Route path={`/workspace/powerUp/10`} exact>
				<PowerUP_V2 />
			</Route>
			{/* Problem Solution Fit */}
			<Route path={`/workspace/powerUp/5`} exact>
				<PowerUP_V2 />
			</Route>
			{/* SaaS Pricing Modeler */}
			<Route path={`/workspace/powerUp/9`} exact>
				<PowerUP_V2 />
			</Route>
			{/* Tam Sam Som */}
			<Route path={`/workspace/powerUp/7`} exact>
				<PowerUP_V2 />
			</Route>
			<Route path={`/workspace/powerUp/3`} exact>
				<PowerUP_V2 />
			</Route>
			{/* Pitch Deck PowerPoint Presentation Generator */}
			<Route path={`/workspace/powerUp/64`} exact>
				<PowerUP_V2 />
			</Route>
			{/* MVP Prioritizer */}
			<Route path={`/workspace/powerUp/20`} exact>
				<PowerUP_V2 />
			</Route>
			{/* Are-you-enterprise  TODO */}
			<Route path={`/workspace/powerup/65`} exact>
				<PowerUP_V2 />
			</Route>
			{/* Services Pricing Modeler TODO */}
			<Route path={`/workspace/powerup/60`} exact>
				<PowerUP_V2 />
			</Route>
			{/* Hardware Pricing Modeler TODO */}
			<Route path={`/workspace/powerup/61`} exact>
				<PowerUP_V2 />
			</Route>
			{/* Retail Pricing Modeler TODO */}
			<Route path={`/workspace/powerup/62`} exact>
				<PowerUP_V2 />
			</Route>

			{/*<Route path={``} exact>
        <PowerUP_V2/>
      </Route>*/}
			{/* END --- PowerUP Facelift items */}
			{/* Power Packs items */}
			<Route path={`/workspace/powerPack/are-you-fundable`} exact>
				<PowerPack />
			</Route>
			<Route path={`/workspace/powerPack/perfect-your-pitch`} exact>
				<PowerPack />
			</Route>
			<Route path={`/workspace/powerPack/startup-health`} exact>
				<PowerPack />
			</Route>
			<Route path={`/workspace/powerPack/build-mvp`} exact>
				<PowerPack />
			</Route>
			{/* END --- PowerPack items */}
			<Route path={`${path}`} exact>
				<PowerupDetails />
			</Route>
		</Switch>
	);
}
