import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ImageIcon from "@mui/icons-material/Image";
import CloseIcon from "@mui/icons-material/Close";
import { Button, Typography, Box, CircularProgress, Paper, IconButton } from "@mui/material";
import { PPT_UPLOAD } from "../../constants/image";
import Dropzone from "react-dropzone";
import services from "../../services";
import "cropperjs/dist/cropper.css";
import { toastContainer } from "../../utils/toast";
import { handleMixPanel } from "../../utils/mixPanelEventHandling";
import CommonSlideDialog from "../CommonSlideDialog";
import { setStartupProfileData } from "../../modules/actions";
import MessageSlidingHandler from "../modal/MessageSlidingHandler";

export default function UploadFileSlidingModal() {
	const [openPreview, setOpenPreview] = useState(false);
	const [localFile, setLocalFile] = useState();
	const [loading, setLoading] = useState(false);
	const [cropData, setCropData] = useState("");
	const [cropper, setCropper] = useState();
	const [loader, setLoader] = useState(false);
	const [uploadedImg, setUploadedImg] = useState();
	const [fileData, setFileData] = useState();

	const [deleteConfirmPopup, setDeleteConfirmPopup] = useState(false);
	const [pitchType, setPitchType] = useState("");
	const dispatch = useDispatch();
	const openStartupProfileObj = useSelector((state) => state?.startupProfile?.openStartupProfileObj);

	const openUploadFileModalData = useSelector((state) => state?.startupProfile?.openUploadFileModalData);
	const OPEN_FILE_MODAL = useSelector((state) => state?.startupProfile?.uploadFileSlidingModalOpen);
	const { isPremiumPlanAccess, userType } = useSelector((store) => store?.auth);

	const userData = useSelector((store) => ({
		userId: store?.auth?.userId,
		userName: store?.auth?.userName,
	}));

	const handleCloseFileModal = () => {
		dispatch(
			setStartupProfileData({
				uploadFileSlidingModalOpen: false,
			})
		);
	};

	useEffect(() => {
		if (openStartupProfileObj?.pitchDeckLink) {
			let type = openStartupProfileObj?.pitchDeckLink.substr(openStartupProfileObj?.pitchDeckLink?.length - 3);

			if (type === "pdf") {
				setPitchType("pdf");
			} else {
				setPitchType("ppt");
			}
		}
	}, [openStartupProfileObj]);

	const handleFileSelect = (file) => {

		if (openUploadFileModalData?.title === "Pitch Perfect Pitch Deck") {
			handleMixPanel("Startup Profile Edited", {
				userName: userData.userName,
				userId: userData.userId,
				subscription_status: isPremiumPlanAccess ? "Subscribed" : "Not Subscribed",
				user_type: userType,
			});
		}

		if (file) {
			setLoader(true);
			setLoading(true);
			const fileSize = (file?.size / 1024 / 1024).toFixed(2);

			const sanitizeFilename = (filename) => {
				try{
					return filename.replace(/[^a-z0-9.]+/gi, '_');
				}catch(e){
					console.log("Unable to rename file. Failed with error:", e);
				}
			};

			if (fileSize < 500) {
				const sanitizedFilename = sanitizeFilename(file.name) ?? file.name;
				const newFile = new File([file], sanitizedFilename, { type: file.type });

				setLocalFile(newFile);
				setCropData(newFile);
				setOpenPreview(true);
				getFiles(newFile, "pitch");
			} else {
				toastContainer("Please Upload file less than 500MB", "error");
				setLoader(false);
				setLoading(false);
			}
		} else {
			toastContainer("Invalid file format.Please upload a file in one of the following formats: pdf,ppt,pptx.", "error");
		}
	};

	const handleSave = () => {
		if (typeof cropper !== "undefined") {
			dispatch(
				setStartupProfileData({
					openStartupProfileObj: { ...openStartupProfileObj, pitchDeckLink: uploadedImg },
				})
			);
			setCropData("");
			setLocalFile("");
			handleCloseFileModal();
		} else {
			handleCloseFileModal();
			setCropData("");
			setLocalFile("");
		}
		handleMixPanel("Account Profile Edited", {
			userName: userData.userName,
			userId: userData.userId,
			subscription_status: isPremiumPlanAccess ? "Subscribed" : "Not Subscribed",
			user_type: userType,
		});
	};

	const getFiles = async (data, type) => {
		var formData = new FormData();
		formData.append("file", data);
		formData.append("fileGroup", "LOGO");

		if (formData) {
			services
				.uploadFile(formData)
				.then((response) => {
					dispatch(
						setStartupProfileData({
							openStartupProfileObj: { ...openStartupProfileObj, pitchDeckLink: response.data.data?.fileLocation },
						})
					);
					setFileData(response.data.data);
					setUploadedImg(response.data.data?.fileLocation);
					setLoading(false);
					setLoader(false);

					toastContainer("File uploaded successfully", "success");
				})
				.catch((e) => {
					const message = e.response?.data?.message;
					toastContainer(message, "error");
					setLoader(false);
				});
		}
	};

	const deleteFileHandler = () => {
		dispatch(
			setStartupProfileData({
				openStartupProfileObj: { ...openStartupProfileObj, pitchDeckLink: null },
			})
		);
		setLocalFile("");
		setCropData("");
		setDeleteConfirmPopup(false);
	};

	const removeLink = (e) => {
		e.stopPropagation();
		deleteFileHandler();
		// updatePayload("");
	};

	const FooterComponent = () => {
		return (
			<>
				{
					<Box sx={{ display: "flex", alignItems: "center", gap: 1, m: 2 }}>
						<Box>
							<Button
								variant="noOutlineNew"
								onClick={() => {
									setCropData("");
									setLocalFile("");
									// onClose();
									handleCloseFileModal();
								}}
								sx={{ width: "max-content", whiteSpace: "nowrap" }}
							>
								Cancel
							</Button>
						</Box>

						<Button
							sx={{ mr: 1 }}
							onClick={() => {
								handleSave();
							}}
							variant="DS1"
						>
							Save
						</Button>
					</Box>
				}
			</>
		);
	};

	return (
		<Box>
			{" "}
			<CommonSlideDialog
				disableBackDropClick={true}
				closeButton={!loader}
				title={openUploadFileModalData?.title}
				titleVariant={"poppinsSemiBold30"}
				isOpen={OPEN_FILE_MODAL}
				onClose={() => {
					// onClose();
					setCropData("");
					setLocalFile("");
					handleCloseFileModal();
				}}
				footerStyle={{
					border: loader && "none",
				}}
				contentStyle={{ px: 0 }}
				maxWidth={"md"}
				fullWidth
				PaperProps={{
					sx: (theme) => ({
						borderRadius: theme.shape.standard13.borderRadius,
						boxShadow: "0px 5px 16px rgba(0, 0, 0, 0.3)",
						p: 3,
					}),
				}}
				dialogAction={
					<>
						{!loader &&
							(cropData || openStartupProfileObj?.pitchDeckLink ? (
								<Box sx={{ mr: "auto" }}>
									{" "}
									<Button
										variant="link"
										sx={{ color: "#5630B2" }}
										onClick={() => {
											setDeleteConfirmPopup(true);
										}}
									>
										Delete
									</Button>
								</Box>
							) : (
								<FooterComponent />
							))}
					</>
				}
			>
				<Box sx={{ px: 3, pt: 2 }}>
					{!loader && !cropData && !openStartupProfileObj?.pitchDeckLink && (
						<Typography variant="poppinsSemiBold20" sx={{ color: (theme) => theme.palette.secondary.black }}>
							Upload local file
						</Typography>
					)}

					<Dropzone
						onDrop={(acceptedFiles) => {
							handleFileSelect(acceptedFiles[0]);
						}}
						accept={openUploadFileModalData?.fileType}
						disabled={loader}
					>
						{({ getRootProps, getInputProps }) => (
							<section>
								<div {...getRootProps()}>
									<input {...getInputProps()} />
									{cropData || openStartupProfileObj?.pitchDeckLink ? (
										loader ? (
											<Box justifyContent={"center"} sx={{ display: "flex", mt: 7, minHeight: "150px" }}>
												<Box>
													<Box
														sx={(theme) => ({
															display: "flex",
															justifyContent: "center",
														})}
													>
														<CircularProgress
															sx={(theme) => ({
																height: "80px",
																width: "80px",
																color: theme.palette.text.darkBlue,
															})}
														/>
													</Box>
													<Box
														sx={(theme) => ({
															textAlign: "center",
															pt: 5,
														})}
													>
														<Typography variant="title_medium">Pitch Deck upload is in progress</Typography>
													</Box>
												</Box>
											</Box>
										) : (
											<Box
												sx={{
													display: "flex",
													justifyContent: "center",
													flexDirection: "column",
													gap: 3,
												}}
											>
												<div
													className="App"
													style={{
														display: "flex",
														justifyContent: "center",
														width: "100%",
													}}
												>
													{pitchType === "ppt" && (
														<iframe
															src={`https://view.officeapps.live.com/op/embed.aspx?src=${openStartupProfileObj?.pitchDeckLink}`}
															width="100%"
															height="400px"
															frameBorder="0"
															title="slides"
														></iframe>
													)}

													{pitchType === "pdf" && (
														<iframe src={openStartupProfileObj?.pitchDeckLink} width="100%" height="400px" frameBorder="0"></iframe>
													)}
												</div>

												{localFile && (
													<Paper
														sx={{
															display: "flex",
															flexDirection: "row",
															width: 1,
															justifyContent: "space-between",
															alignItems: "center",
														}}
														elevation={1}
													>
														<Box
															sx={{
																display: "flex",
																flexDirection: "row",
																gap: 2,
																alignItems: "center",
																p: 1,
															}}
														>
															<Box
																sx={{
																	background: (theme) => theme.palette.primary.sys_light_background,
																	borderRadius: (theme) => theme.shape.standard22,
																}}
															>
																<ImageIcon
																	sx={{
																		color: (theme) => theme.palette.primary.sysLight,
																		p: 1,
																	}}
																/>
															</Box>
															<Box
																sx={{
																	display: "flex",
																	flexDirection: "column",
																	gap: 1,
																}}
															>
																<Typography variant="lable_large">{fileData?.fileName}</Typography>
																<Typography variant="required_text">{fileData.size}</Typography>
															</Box>
														</Box>
														<Box>
															<IconButton onClick={removeLink}>
																<CloseIcon />
															</IconButton>
														</Box>
													</Paper>
												)}
											</Box>
										)
									) : (
										<Box
											sx={(theme) => ({
												border: "1px dashed rgba(106, 117, 117, 1)",
												minHeight: "150px",
												borderRadius: theme.shape.standard13.borderRadius,
												mt: 1,
												textAlign: "center",
												p: 5,
												cursor: "pointer",
											})}
										>
											<Box component={"img"} src={PPT_UPLOAD} alt="img"></Box>
											<Typography
												variant="sub_title_portfolio"
												sx={{
													mt: 1,
													display: "grid",
													color: (theme) => theme.palette.secondary.korophKoal,
												}}
											>
												{" "}
												{openUploadFileModalData?.selectFileText}
											</Typography>
											<Typography
												variant="sub_title_portfolio"
												sx={{
													mt: 1,
													display: "grid",
													color: (theme) => theme.palette.secondary.outline,
												}}
											>
												{" "}
												or drag and drop it here
											</Typography>
											<Typography
												variant="sub_title_portfolio"
												sx={{
													mt: 1,
													display: "grid",
													color: (theme) => theme.palette.secondary.outline,
												}}
											>
												{openUploadFileModalData?.dimensionText}
											</Typography>
										</Box>
									)}
								</div>
							</section>
						)}
					</Dropzone>
				</Box>
			</CommonSlideDialog>
			<MessageSlidingHandler
				isOpen={deleteConfirmPopup}
				title={`Delete ${openUploadFileModalData?.title}`}
				text="Confirmation"
				subText={`Are you sure you want to delete the ${openUploadFileModalData?.title}?`}
				primaryButtonText="Delete"
				secondaryButtonText="No Thanks"
				handleClose={() => setDeleteConfirmPopup(false)}
				handlePrimarybtnClick={() => deleteFileHandler()}
			/>
		</Box>
	);
}
