import React, { useContext } from "react";

import { Typography } from "@mui/material";
import { ProfileContext } from "../..";
import VideoPlayerComponent from "../../../../common/videoPlayer";
import MissingAsset from "../MissingAsset";
import GridLayout from "./GridLayout";
import { VideoContainer } from "./style";

function FounderStorySection() {
	const { profileData, viewContext, updateProfile } = useContext(ProfileContext);

	const VideoSection = () => {
		return (
			<>
				{profileData?.founderStoryVideo ? (
					<VideoContainer>
						<VideoPlayerComponent videoUrl={profileData?.founderStoryVideo} height="100%" />
					</VideoContainer>
				) : (
					<MissingAsset sxProps={{ height: "450px", p: 2 }} message={"Founder Story Video Coming Soon"} />
				)}
			</>
		);
	};
	const TextSection = () => {
		return (
			<Typography
				component={"div"}
				variant="Text/sm/Regular"
				sx={{
					color: "black",
				}}
			>
				<span
					dangerouslySetInnerHTML={{
						__html: profileData?.founderStory ? profileData?.founderStory : `${profileData?.companyName} has not completed their founder story.`,
					}}
				/>
			</Typography>
		);
	};

	return <GridLayout left={<TextSection />} right={<VideoSection />} />;
}

export default FounderStorySection;
