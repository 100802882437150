export function hasTimePassed(epochTimeString) {
	if (epochTimeString) {
		// convert the epoch time string to a number representing milliseconds since 1970
		const epochTime = Number(epochTimeString) * 1000;
		// get the current time as a Date object
		const currentTime = new Date();
		// check if the current time is after the epoch time
		return currentTime > epochTime;
	} else {
		return false;
	}
}
