import { Box, Skeleton, Typography } from "@mui/material";
import React, { Fragment, useContext, useEffect, useMemo, useState } from "react";
import JsxParser from "react-jsx-parser";
import { ProfileContext } from "../../..";
import VideoPlayerComponent from "../../../../../common/videoPlayer";
import MissingAsset from "../../MissingAsset";

const BoxVideoElem = ({ children }) => (
	<Box
		sx={{
			flex: 1,
			maxHeight: "640px", // Figma value.
			maxWidth: "1000px",
			mb: "40px",
			"& video": {
				borderRadius: "30px",
				objectFit: "contain",
				maxHeight: "640px",
			},
		}}
	>
		{children}
	</Box>
);

function UserAbout({ tab }) {
	const { profileData, profileDataLoading } = useContext(ProfileContext);
	const [loading, setloading] = useState(profileDataLoading);
	const [data, setdata] = useState(profileData);

	useEffect(() => {
		setloading(profileDataLoading);
	}, [profileDataLoading]);
	useEffect(() => {
		setdata(profileData);
	}, [profileData]);

	const VideoComp = useMemo(() => {
		if (data?.videoUrl) {
			return (
				<BoxVideoElem>
					<VideoPlayerComponent videoUrl={data?.videoUrl} />
				</BoxVideoElem>
			);
		} else {
			return <MissingAsset sxProps={{ height: "450px", p: 2, mb: "30px" }} message={"About Video Coming Soon"} />;
		}
	}, [data]);

	return (
		<Box>
			{loading ? <Skeleton variant="rounded" animation="wave" sx={{ height: "240px", width: 1 }} /> : VideoComp}

			{data?.about && (
				<>
					<Box
						sx={{
							mb: "20px",
						}}
					>
						{loading ? (
							<Skeleton variant="text" animation="wave" sx={{ fontSize: "46px" }} />
						) : (
							<Typography
								variant="customValue"
								component={"div"}
								sx={{
									fontFamily: "PoppinsSemiBold",
									fontSize: "26px",
									lineHeight: "39px",
									color: "black",
								}}
							>
								About {data?.name}
							</Typography>
						)}
					</Box>
					<Box>
						{loading ? (
							<Fragment>
								<Skeleton variant="rounded" animation="wave" sx={{ height: "24px", width: 1, mb: 2 }} />
								<Skeleton variant="rounded" animation="wave" sx={{ height: "24px", width: 1, mb: 2 }} />
								<Skeleton variant="rounded" animation="wave" sx={{ height: "24px", width: 1, mb: 2 }} />
								<Skeleton variant="rounded" animation="wave" sx={{ height: "24px", width: "65%" }} />
							</Fragment>
						) : (
							<Typography
								variant="customValue"
								component={"div"}
								sx={{
									fontFamily: "PoppinsMedium",
									fontSize: "16px",
									lineHeight: "24px",
									color: "black",
								}}
							>
								<JsxParser autoCloseVoidElements components={{ Typography }} jsx={data?.about ? `${data?.about}` : `No Data`} />
							</Typography>
						)}
					</Box>
				</>
			)}
		</Box>
	);
}

export default UserAbout;
