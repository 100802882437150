import React from "react";
import { Button, Grid, Typography, Box } from "@mui/material";
import { useHistory } from "react-router-dom";
import Card from "../../../components/Workspace/Card";
import theme from "../../../constants/Theme";
import ROUTES_PATH from "../../../constants/routes";
import { CONTINUE_JOURNEY_ICON } from "../../../constants";

const PowerUp = ({ data }) => {
  const history = useHistory();

  const handleResume = () => {
    const path = `/powerup/${data.id}`;

    history.push("/workspace" + path);
  };

  return (
    <Card
      sx={{
        p: 1,
        boxShadow: theme => theme.palette.primary.elevationLight2,
      }}
    >
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        sx={{
          maxWidth: theme.breakpoints.values.md,
        }}
      >
        <Grid
          item
          lg={12}
          display="flex"
          alignItems={"center"}
          justifyContent="flex-start"
        >
          <Box component={"img"} src={CONTINUE_JOURNEY_ICON} alt="powerup" />
          <Typography
            variant="headline6"
            sx={{ ml: 2, color: theme => theme.palette.secondary.korophKoal }}
          >
            Continue your Journey
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        sx={{
          maxWidth: theme.breakpoints.values.md,
          pt: 4,
        }}
        rowGap={2}
      >
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Box
            component="img"
            src={data.imageUrl}
            alt="powerup"
            sx={{
              borderRadius: theme => theme.shape.standard_small.borderRadius,
            }}
            width={"100%"}
            height={"100%"}
          />
          <img />
        </Grid>

        <Grid item lg={12}>
          <Typography
            sx={{ color: theme => theme.palette.secondary.korophKoal }}
            variant="title_medium"
          >
            {data.powerUpName}
          </Typography>
        </Grid>

        <Grid item lg={12}>
          <Typography
            sx={{ color: theme => theme.palette.secondary.korophKoal }}
            variant="body_medium"
          >
            {data.shortDescription}
          </Typography>
        </Grid>
        <Box
          sx={{
            marginLeft: "auto",
            display: { xs: "flex", md: "flex", lg: "none" },
          }}
        >
          <Button
            sx={{ mt: 3, width: "auto", ml: 1.2 }}
            variant="contained"
            onClick={handleResume}
          >
            {(data?.companyTask?.taskStatus && "Resume") || "Start"}
          </Button>
        </Box>
        <Grid
          container
          justifyContent={"flex-end"}
          sx={{ mt: 1, display: { xs: "none", md: "none", lg: "flex" } }}
          columnGap={2}
        >
          <Grid item lg={4} md={4} sm={4} xs={4} display="flex">
            <Button variant="contained" onClick={handleResume}>
              {(data?.companyTask?.taskStatus && "Resume") || "Start"}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export default PowerUp;
