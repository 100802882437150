import React, { useEffect, useState, useRef } from "react";
import {
	Box,
	CircularProgress,
	Divider,
	Grid,
	Button,
	Typography,
	useMediaQuery,
	TextField,
	InputAdornment,
	Popover,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	Menu,
	MenuItem,
} from "@mui/material";
import { MVP_NO_DATA_IMG } from "../../../../constants/image";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import DialogComponent from "../../../../common/CommonDialog";
import TextInputField from "../../../../common/TextInputField";
import SelectInput from "../../../../common/SelectInput";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import SearchIcon from "@mui/icons-material/Search";
import SortIcon from "@mui/icons-material/Sort";
import services from "../../../../services";
import { Co2Sharp } from "@mui/icons-material";
import { toastContainer } from "../../../../utils/toast";
import messages from "../../../../constants/messages";
import CreateMatrixTable from "./Components/CreateMatrixTable";
import CreateMatrixCard from "./Components/CreateMatrixCard";
import LaunchIcon from "@mui/icons-material/Launch";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import BookmarkOutlinedIcon from "@mui/icons-material/BookmarkOutlined";
import { WorkspacePopoverBtn } from "../../../../components/Workspace/WorkspaceUser/styles";
import MessageHandlerModal from "../../../../common/modal/MessageHandler";
import MatrixMenuDrawer from "./Components/MatrixMenuDrawer";
import CloseIcon from "@mui/icons-material/Close";
import RiceMatrixGraph from "../../../../components/RiceMatrixGraph";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { getModuleSteps, getPowerupDetails } from "../../../NewPowerup/Common/functions";
import { useLocation, useParams, useHistory } from "react-router-dom";
import { find } from "lodash";
import { REGEX } from "../../../../constants/regex";
import RouterPrompt from "../../../../common/RouterPrompt";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import FileSaver from "file-saver";
import { handleMixPanel } from "../../../../utils/mixPanelEventHandling";
import { getPowerUpsCompletedCount } from "../../../../utils/getPowerUpsCompletedCount";

export default function CreateMatrix({ showPrioritizer, showRiceMatrix, powerupDetails, started }) {
	const history = useHistory();
	const [open, setOpen] = useState("");
	const [openRename, setOpenRename] = useState("");
	const [openArchive, setOpenArchive] = useState("");
	const [openSaveAs, setOpenSaveAs] = useState("");
	const [openDelete, setOpenDelete] = useState("");
	const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
	const [showVersion, setShowVersion] = useState(false);
	const [version, setVersion] = useState("");
	const [title, setTitle] = useState("");
	const [searchText, setSearchText] = useState("");
	const [openActions, setOpenActions] = useState("");
	const [openRiceExport, setOpenRiceExport] = useState("");
	const [versionOptions, setVersionOptions] = useState([]);
	const [powerupId, setPowerUpId] = useState(5);
	const [pages, setPages] = useState(0);
	const [sort, setSort] = useState("ASC");
	const [filter, setFilter] = useState("createdAt");
	const [archive, setArchive] = useState(false);
	const [status, setStatus] = useState(true);
	const [versionType, setVersionType] = useState();
	const [answers, setAnswers] = useState([
		{
			index: 0,
			featureName: "",
			recommandation: "None",
			effort: 0,
			riceScore: 0,
			reach: 0,
			impact: 0,
			confidence: 0,
		},
	]); //map the table
	const [moduleDetails, setModuleDetails] = useState("");
	const [disableCreate, setDisableCreate] = useState(true);
	const [anchorEl, setAnchorEl] = useState(null);
	const [sortanchorEl, setSortAnchorEl] = useState(null);
	const [openDrawerMenu, setOpenDrawerMenu] = useState(false);
	const openPopup = Boolean(anchorEl);
	const id = openPopup ? "simple-popover" : undefined;
	const [newRow, setNewRow] = useState("");
	const [sortPopup, setSortPopup] = useState(false);
	const [sortFiled, setSortField] = useState("");
	const [openDrawer, setOpenDrawer] = useState(false);
	const [deletePopup, setDeletePopup] = useState(false);
	const [deleteIndex, setDeleteIndex] = useState("");
	const [matrixData, setMatrixData] = useState("");
	const [versionId, setVersionId] = useState("");
	const [allVersions, setAllVersions] = useState("");
	const [createLoader, setCreateLoader] = useState(false);

	const [confirmation, setConfirmation] = useState(false);
	const [saveLoading, setSaveLoading] = useState(false);
	const [newTitle, setNewTitle] = useState("");
	const [discardPopup, setDiscardPopup] = useState(false);
	const [graphAnchorEl, setGraphAnchorEl] = useState(null);
	const [graphFilterValue, setGraphFilterValue] = useState("All");

	const openGraphFilter = Boolean(graphAnchorEl);
	const [powerupDetailsLoader, setPowerupDetailsLoader] = useState(false);
	const [powerupStepsData, setPowerupStepsData] = useState("");
	const [versionsLoading, setVersionsLoading] = useState(false);
	const [versionData, setVersionData] = useState("");
	const [moduleResponseId, setModuleResponseId] = useState(null);
	const [powerupExcercises, setPowerupExcercises] = useState("");
	const [createNew, setCreateNew] = useState(false);
	const [versionNew, setVersionNew] = useState("");
	const [psVersion, setPsVersion] = useState("");
	const [versionIdNew, setVersionIdNew] = useState("");
	const [titleNew, setTitleNew] = useState("");
	const [type, setType] = useState("");
	const [mvpVersions, setMvpVersions] = useState("");
	const location = useLocation();
	const [matrixLoader, setMatrixLoader] = useState(true);
	const graphRef = useRef(null);
	const [generateLoader, setGenerateLoader] = useState(false);
	const [isCompleted, setIsCompleted] = useState(false);
	const [isCreate, setIsCreate] = useState(false);
	const [saved, setSaved] = useState(false);
	const [deleted, setDeleted] = useState(false);
	const date = new Date();

	useEffect(() => {
		clearAnswers();
	}, []);

	useEffect(() => {
		if (powerupDetails) {
			getModuteStepsApiCall(powerupDetails?.modules[0].moduleUuid);
		}
	}, [powerupDetails]);

	const getModuteStepsApiCall = (id) => {
		getModuleSteps(id)
			.then((result) => {
				setPowerupDetailsLoader(false);
				if (result) {
					setPowerupStepsData(result);
					//handleGetMVPVersions();
				}
			})
			.catch((err) => console.log(err));
	};

	const startNewPowerupVersion = () => {
		const moduleId = powerupDetails.modules[0].id;
		const moduleUuid = powerupDetails.modules[0].moduleUuid;

		setIsCreate(true);
		services
			.startModule({ moduleId })
			.then(async (response) => {
				if (response.data.code === 200) {
					if (versionType === "NEW_VERSION") {
						const count = await getPowerUpsCompletedCount(20);
						handleMixPanel("Powerup Restarted", {
							"PowerUp ID": "20",
							"PowerUp Name": "MVP Prioritizer",
							"Times Completed Count": count,
							"Start Date": date.toISOString(),
						});
					} else if (versionType === "FIRST_VERSION") {
						handleMixPanel("Powerup Started", {
							"PowerUp ID": "20",
							"PowerUp Name": "MVP Prioritizer",
							"Start Date": date.toISOString(),
						});
					}
					setVersionData(response.data);
					setModuleResponseId(response?.data?.id);
					handleGetMVPVersions();
					if (location?.state?.started === "NOT_STARTED") {
						handleGetSolutions(); //get solutions of the selected versions
					}
					if (open) {
						handlePopup();
					}
					setCreateLoader(false);
					setIsCompleted(false);
				}
			})
			.catch((error) => {
				const message = error.response?.data?.message;
				toastContainer(message, "error");
				console.log(error);
			});
	};

	useEffect(() => {
		if (moduleResponseId) {
			handleVersionsData();
			if (isCreate) {
				handleNext(); //to save the PS source
			}
		}
	}, [moduleResponseId]);

	useEffect(() => {
		if ((version && location?.state?.started === "STARTED") || location?.state?.started === "COMPLETED") {
			handleGetSolutions();
		}
	}, [version]);

	const handlePopup = () => {
		if (open) {
			setPsVersion("");
		}
		setOpen(!open);
		setCreateNew(true);
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		});
	};

	useEffect(() => {
		//check if started is true or false
		handleGetDetails();

		if (location?.state?.started === "STARTED" || location?.state?.started === "COMPLETED") {
			//get list of all the versions and select the recent most version
			setShowVersion(true);
			handleGetMVPVersions();
			setOpen(false);
		}
	}, []);

	//Api call to get versionsList
	const handleGetDetails = () => {
		setVersionsLoading(true);
		services
			.getMyWorkDetails(powerupId, pages, sort, filter, archive, status)
			.then((result) => {
				const options = result?.data?.data?.map((item) => {
					return item.name;
				});
				const filteredData = result?.data?.data.filter((item) => !item.isArchived && item.isCompleted);

				setAllVersions(filteredData);
				setVersionOptions(options ? options : []);
				setVersionsLoading(false);
				if (location?.state?.started === "STARTED" || location?.state?.started === "COMPLETED") {
					setMatrixLoader(false);
				}
			})
			.catch((error) => {
				toastContainer(messages.API_SUBMIT_ERROR, "error");
				setVersionsLoading(false);
				setMatrixLoader(false);
			});
	};

	const handleDrawerClose = () => {
		setOpenDrawerMenu(false);
	};

	const handleArchivePopup = () => {
		setOpenArchive(!openArchive);
		handleClose();
	};

	const handleRenamePopup = () => {
		setOpenRename(!openRename);
		handleClose();
	};

	const handleDeletePopup = () => {
		setOpenDelete(!openDelete);
		handleClose();
	};

	const handleSaveAsPopup = () => {
		setOpenSaveAs(!openSaveAs);
		handleClose();
	};

	const handlePopupOpen = (event) => {
		if (!isSmallScreen) {
			setAnchorEl(event.currentTarget);
		} else {
			setOpenDrawerMenu(true);
		}
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const createPopup = () => {
		handleClose();
		handlePopup();
	};
	const handleExportClick = (type) => {
		graphRef.current.handleExport(type);
	};

	const FooterComponent = ({ title }) => {
		return (
			<>
				<Box sx={{ display: "flex", gap: 2, pr: { xs: 2, sm: 3 } }}>
					{title === "create" && (
						<>
							<Button
								variant="outlined"
								onClick={() => {
									handlePopup();
								}}
							>
								Cancel
							</Button>
							<Button onClick={() => handleCreateMatrix("new")} disabled={disableCreate}>
								{createLoader ? (
									<CircularProgress
										sx={{
											display: "flex",
											justifyContent: "center",
											maxWidth: "30px",
											px: 3,
											color: (theme) => theme.palette.secondary.white,
										}}
									/>
								) : (
									"Create"
								)}
							</Button>
						</>
					)}
					{title === "saveAs" && (
						<>
							<Button
								disabled={titleNew.length < 3 || titleNew.length > 100}
								onClick={() => {
									handleCreateMatrix("duplicate");
								}}
							>
								Save As
							</Button>
						</>
					)}
					{title === "rename" && (
						<>
							<Button
								disabled={newTitle.length < 3 || newTitle.length > 100}
								onClick={() => {
									handleRename();
									handleRenamePopup();
								}}
							>
								Save Changes
							</Button>
						</>
					)}
				</Box>
			</>
		);
	};

	useEffect(() => {
		if (location?.state?.started === "STARTED" || location?.state?.started === "COMPLETED" || createNew) {
			if (titleNew && versionNew && titleNew.length >= 3 && titleNew.length <= 100) {
				setDisableCreate(false);
			} else {
				setDisableCreate(true);
			}
		} else {
			if (title && version && title.length >= 3 && title.length <= 100) {
				setDisableCreate(false);
			} else {
				setDisableCreate(true);
			}
		}
	}, [title, version, titleNew, versionNew]);

	const HandlePopOver = () => {
		return (
			<Popover
				id={id}
				open={openPopup}
				anchorEl={anchorEl}
				onClose={handleClose}
				transformOrigin={{ horizontal: "right", vertical: "top" }}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				PaperProps={{
					sx: {
						borderRadius: 1,
						boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)",
						minWidth: "245px",
					},
				}}
			>
				<WorkspacePopoverBtn>
					<List disablePadding>
						<ListItem disablePadding sx={{ minHeight: "32px" }}>
							<ListItemButton
								onClick={() => {
									setCreateNew(true);
									createPopup();
									setVersionType("NEW_VERSION");
								}}
							>
								<ListItemText>
									<Typography variant="bodyMedium">Create New Matrix</Typography>
								</ListItemText>
							</ListItemButton>
						</ListItem>
						<ListItem disablePadding sx={{ minHeight: "32px" }}>
							<ListItemButton>
								<ListItemText onClick={handleSaveAsPopup}>
									<Typography variant="bodyMedium">Save As..</Typography>
								</ListItemText>
							</ListItemButton>
						</ListItem>
						<ListItem disablePadding sx={{ minHeight: "32px" }}>
							<ListItemButton onClick={handleRenamePopup}>
								<ListItemText>
									<Typography variant="bodyMedium">Rename RICE Matrix</Typography>
								</ListItemText>
							</ListItemButton>
						</ListItem>

						<ListItem disablePadding sx={{ minHeight: "32px" }}>
							<ListItemButton onClick={handleDeletePopup}>
								<ListItemText>
									<Typography variant="bodyMedium" color="error.main">
										Delete RICE Matrix
									</Typography>
								</ListItemText>
							</ListItemButton>
						</ListItem>
					</List>
				</WorkspacePopoverBtn>
			</Popover>
		);
	};

	const handleVersionTypeForPopUp = () => {
		handlePopup();
		setVersionType("NEW_VERSION");
	};

	let mvpOptions = [
		{
			text: "Create New Matrix",
			action: handleVersionTypeForPopUp,
		},
		{
			text: "Save As...",
			action: handleSaveAsPopup,
		},
		{
			text: "Rename RICE Matrix",
			action: handleRenamePopup,
		},
		//{
		//  text: "Archive",
		//  action: handleArchivePopup,
		//},
		{
			text: "Delete RICE Matrix",
			action: handleDeletePopup,
		},
	];

	//api call to get soultions of selected version
	const handleGetSolutions = () => {
		services
			.getModuleStepResponses(9)
			.then((result) => {
				if (result?.status === 200) {
					//if create new
					setModuleDetails(result?.data?.data?.find((obj) => obj?.name === version)?.moduleStepResponses);

					if (open) {
						handlePopup();
					}

					if (!showVersion) {
						setShowVersion(true);
					}
					setCreateLoader(false);
					clearNewData();
				}
			})
			.catch((err) => {
				console.log(err);
				setCreateLoader(false);
			});
	};

	const handleGetMVPVersions = () => {
		let mvpModuleId = 12;

		services
			.getModuleStepResponses(mvpModuleId)
			.then((result) => {
				//handleGetSolutions();
				if (result?.status === 200) {
					let allData = result.data.data.sort((a, b) => (a.lastModifiedAt > b.lastModifiedAt ? -1 : 1));

					//sort as per last modified
					setMvpVersions(allData); //all versions

					setVersionData(allData[0]);
					setModuleResponseId(allData[0]?.id);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const handleVersionsData = () => {
		const excerciseDetails = versionData?.moduleStepResponses?.sort((a, b) => (a.id > b.id ? 1 : -1));

		if (!excerciseDetails?.length) {
			//length =0 means  new version started => so go to 1st step
			setPowerupExcercises([]);
			//clearAnswers();
		} else {
			//map data as our needed format parse string answer to json
			const mappedExcerciseDetails = excerciseDetails?.map((excercise) => ({
				moduleResponseId: moduleResponseId,
				moduleStepId: excercise?.moduleStep?.id,
				answers: JSON.parse(excercise?.answers),
				stepNo: excercise?.moduleStep?.stepNo,
			}));
			setPowerupExcercises(mappedExcerciseDetails);
		}
	};

	useEffect(() => {
		if (powerupExcercises) {
			if (powerupExcercises.length > 0) {
				let ans = powerupExcercises?.[0]?.answers?.MatrixDataModel?.length
					? powerupExcercises?.[0]?.answers?.MatrixDataModel
					: [
							{
								index: 0,
								featureName: "",
								effort: 0,
								riceScore: 0,
								reach: 0,
								impact: 0,
								confidence: 0,
								recommandation: "None",
							},
					  ];
				setAnswers(ans);
				setTitle(powerupExcercises?.[0]?.answers?.riceMatrixName);
				setVersion(powerupExcercises?.[0]?.answers?.problemSolutionFitName);
			}
			if (location?.state?.started === "STARTED" || location?.state?.started === "COMPLETED") {
				//handleGetSolutions(); //get solutions of the selected versions
			}
		}
	}, [powerupExcercises]);

	//edit
	//const handleGetModuleResponse = () => {
	//  //get the data of the selected version and setAnswers(data)
	//  const moduleId = powerupDetails?.modules[0]?.id;
	//  let id = versionData?.id ? versionData?.id : versionData?.data?.id;
	//  services
	//    .getModuleResponseDetailsByResponseId(moduleId, id)
	//    .then(res => {
	//      if (res.data?.data?.moduleStepResponses) {
	//        const excerciseDetails = res.data?.data?.moduleStepResponses.sort(
	//          (a, b) => (a.id > b.id ? 1 : -1)
	//        );

	//        //sort api data
	//        if (!excerciseDetails.length) {
	//          //length =0 means  new version started => so go to 1st step
	//          setPowerupExcercises([]);
	//          clearAnswers();
	//        } else {
	//          //map data as our needed format parse string answer to json
	//          const mappedExcerciseDetails = excerciseDetails.map(excercise => ({
	//            moduleResponseId: moduleResponseId,
	//            moduleStepId: excercise.moduleStep.id,
	//            answers: JSON.parse(excercise.answers),
	//            stepNo: excercise.moduleStep.stepNo,
	//          }));
	//          setPowerupExcercises(mappedExcerciseDetails);
	//        }
	//      }
	//    })
	//    .catch(err => console.log(err));
	//};

	//add new row

	const handleUpdateInput = (e, inputKey, index) => {
		let data = [...answers];
		if (!confirmation) {
			setConfirmation(true);
		}
		if (inputKey === "reach") {
			if (e.target.value.match(REGEX.NUMBERS_ONLY) && e.target.value >= 1 && e.target.value <= 100000) {
				data[index][inputKey] = e.target.value;
			} else {
				data[index][inputKey] = "";
			}
		} else if (inputKey === "effort") {
			if (e.target.value > 100000 || !e.target.value.match(REGEX.NUMBERS)) {
				data[index][inputKey] = "";
			} else {
				data[index][inputKey] = e.target.value;
			}
		} else if (inputKey === "approve") {
			data[index][inputKey] = e.target.checked;
		} else if (inputKey === "riceScore" || inputKey === "matrixName" || inputKey === "matrixSource") {
			data[index][inputKey] = e;
		} else if (inputKey === "confidence" || inputKey === "impact") {
			data[index][inputKey] = e.target.value.substr(0, e.target.value.indexOf(" "));
		} else {
			data[index][inputKey] = e.target.value;
		}

		setAnswers(data);
		handleRiceScore();
	};

	const handleOnBlur = (e, inputKey, index) => {
		let data = [...answers];
		if (e.target.value < 0.5) {
			data[index][inputKey] = "";
		}
		setAnswers(data);
		handleRiceScore();
	};

	const handleAddNewRow = (value) => {
		let data = [...answers];
		let obj = {
			index: answers.length,
			featureName: value,
			recommandation: "None",
			effort: 0,
			riceScore: 0,
			reach: 0,
			impact: 0,
			confidence: 0,
		};
		data.push(obj);
		setAnswers(data);
	};

	//sorting popup web
	const handleSortPopup = (field, event) => {
		setSortAnchorEl(event.currentTarget);
		if (sortPopup) {
			setSortAnchorEl(null);
		}
		setSortField(field);
		setSortPopup(!sortPopup);
	};

	//sorting drawer mobile
	const handleSortDrawer = () => {
		setOpenDrawer(!openDrawer);
	};

	const handleSort = (sort) => {
		let data = [...answers];
		if (sortPopup) {
			setSortPopup(!sortPopup);
		}

		if (sort === "asc") {
			if (sortFiled === "solution" || sortFiled === "featureName") {
				//text
				data?.sort((a, b) => {
					return a[sortFiled].toLowerCase() > b[sortFiled].toLowerCase() ? 1 : -1;
				});
			} else if (sortFiled === "confidence") {
				data.sort((a, b) => {
					//remove %
					let x = a[sortFiled].substr(0, a[sortFiled].indexOf("%"));
					let y = b[sortFiled].substr(0, b[sortFiled].indexOf("%"));

					return x - y;
				});
			} else {
				data.sort((a, b) => {
					return a[sortFiled] - b[sortFiled];
				});
			}
		} else {
			if (sortFiled === "solution" || sortFiled === "featureName") {
				//text
				data?.sort((a, b) => {
					return a[sortFiled].toLowerCase() < b[sortFiled].toLowerCase() ? 1 : -1;
				});
			}
			if (sortFiled === "confidence") {
				data.sort((a, b) => {
					//remove %
					let x = a[sortFiled].substr(0, a[sortFiled].indexOf("%"));
					let y = b[sortFiled].substr(0, b[sortFiled].indexOf("%"));

					return y - x;
				});
			} else {
				data.sort((a, b) => {
					return b[sortFiled] - a[sortFiled];
				});
			}
		}

		setAnswers(data);
	};

	const handleDeleteRow = (index) => {
		setDeletePopup(!deletePopup);
		if (index) {
			setDeleteIndex(index);
		}
	};

	const deleteMatrixRow = () => {
		let data = [...answers];

		if (data.length > 1) {
			data.splice(deleteIndex, 1);

			setAnswers(data);
		} else {
			clearAnswers();
		}
		handleDeleteRow();
		setDeleted(true);
	};

	useEffect(() => {
		if (deleted) {
			handleNext();
		}
	}, [deleted]);

	useEffect(() => {
		if (searchText?.length > 0) {
			let data = answers?.filter((obj) => {
				return (
					obj?.solution?.toLowerCase()?.includes(searchText?.toLowerCase()) || obj?.featureName?.toLowerCase()?.includes(searchText?.toLowerCase())
				);
			});

			setMatrixData(data);
		} else {
			let data = [...answers];
			setMatrixData(data);
		}
	}, [searchText]);

	const handleGraphFilter = (event) => {
		setGraphAnchorEl(event.currentTarget);
	};

	const handleGraphFilterClose = () => {
		setGraphAnchorEl(null);
	};

	const handleGraphFilterValueUpdate = (value) => {
		setGraphFilterValue(value);

		setGraphAnchorEl(null);
	};
	useEffect(() => {
		let data = [...answers];
		setMatrixData(data);
		//check the status of isCompleted
		if (answers) {
			if (answers[0].riceScore > 0 && answers[0].featureName && answers[0].solution) {
				setIsCompleted(true);
			} else {
				setIsCompleted(false);
			}
		}
	}, [answers]);

	const handleRiceScore = () => {
		let i = 0;
		if (answers) {
			//calculate RICE score
			answers.map((ans, i) => {
				if (ans?.reach && ans?.impact && ans?.confidence && ans?.effort) {
					let rice = Math.round(((parseInt(ans?.reach) * ans?.impact * parseInt(ans?.confidence)) / ans?.effort) * 100) / 100;
					if (ans?.riceScore != rice) {
						handleUpdateInput(rice, "riceScore", i);
					}
				} else {
					handleUpdateInput(0, "riceScore", i);
				}
			});
		}
	};

	const handleVersion = (versionName) => {
		let obj = allVersions.filter((ele) => ele.name === versionName);

		if (createNew) {
			setVersionIdNew(obj[0]?.id);
			setVersionNew(versionName);
		} else {
			setVersionId(obj[0]?.id);
			setVersion(versionName);
		}
	};

	const handleNext = () => {
		setSaveLoading(true);
		let data = {
			moduleResponseId: versionData?.data?.id ? versionData?.data?.id : versionData?.id,
			stepNo: 1,
			answers: JSON.stringify({
				riceMatrixName: title,
				problemSolutionFitName: version,
				MatrixDataModel: answers,
			}),
			isLastStep: true,
			isCompleted: isCompleted,
			moduleStepId: powerupStepsData[0]?.id,
			name: title,
		};
		services
			.storeModuleStepResponses(powerupDetails.modules[0]?.id, {
				...data,
				name: title,
				answers: JSON.stringify({
					riceMatrixName: title,
					problemSolutionFitName: version,
					MatrixDataModel: answers,
				}),
			})
			.then((response) => {
				if (response.data.code === 200) {
					if (
						response.data?.data?.isCompleted &&
						data?.isLastStep &&
						response?.data?.data?.completionDate?.split(".")[0] + "Z" === response?.data?.timestamp?.split(".")[0] + "Z"
					) {
						handleMixPanel(`Powerup Completed`, {
							"Powerup ID": "20",
							"Powerup Name": "MVP Prioritizer",
							"Time to Complete": "",
							"Completion Date": date.toISOString(),
						});
					}

					setSaved(true);
					setSaveLoading(false);
					window.scrollTo({
						top: 0,
						left: 0,
						behavior: "smooth",
					});
					setConfirmation(false);
					toastContainer("Response saved successfully");
					if (isCreate) {
						setIsCreate(false);
						setMatrixLoader(true);
					}
					updateAllMvpVersions(response.data.data); //update the response of current version
				}
			})
			.catch((error) => {
				console.log(error);
				setSaveLoading(false);
				toastContainer(messages.API_SUBMIT_ERROR, "error");
			});
	}; //save changes

	const handleRename = () => {
		setTitle(newTitle);
		updateAllMvpVersions("", true); //update the response of current version
	};

	const handleDeleteMatrix = () => {
		//delete the whole moduleResponse object **use api from archive table**
		//redirect to the overview page
		let id = versionData?.id ? versionData?.id : versionData?.data?.id;
		services
			.deleteModuleController(id)
			.then((response) => {
				if (response?.status == 200) {
					//route to overview page
					handleDeletePopup();
					history.goBack();
				}
			})
			.catch((error) => {
				console.log("error", error);
				toastContainer(messages.API_SUBMIT_ERROR, "error");
				handleDeletePopup();
			});
	};

	//type = new/duplicate
	const handleCreateMatrix = (type) => {
		setType(type);
		if (confirmation) {
			//prompt to save answers if not already
			setDiscardPopup(true);
		} else {
			if (type === "new") {
				clearAnswers();
				if (versionNew && versionIdNew && titleNew) {
					setTitle(titleNew);
					setVersion(versionNew);
					setVersionId(versionIdNew);
				}
			} else if (type === "duplicate") {
				//set new title
				setTitle(titleNew);
				handleSaveAsPopup();
			}
			setCreateLoader(true);
			startNewPowerupVersion();
		}
	};

	const clearAnswers = () => {
		setAnswers([
			{
				index: 0,
				featureName: "",
				effort: 0,
				riceScore: 0,
				reach: 0,
				impact: 0,
				confidence: 0,
				recommandation: "None",
			},
		]);
	};

	const clearNewData = () => {
		setVersionNew("");
		setTitleNew("");
		setVersionIdNew("");
	};

	const handleConfirmation = () => {
		setConfirmation(false);
	};

	const handlediscardPopup = () => {
		setDiscardPopup(!discardPopup);
	};

	const handleGenerateLaunchPlan = () => {
		setGenerateLoader(true);
		let allVersions = [];
		//remove all the versions who's moduleStepResponses is []

		mvpVersions?.map((ele, i) => {
			if (ele?.moduleStepResponses?.length === 0 || ele?.moduleStepResponses === []) {
				allVersions?.splice(i, 1);
			} else {
				let ans = JSON.parse(ele?.moduleStepResponses?.[0]?.answers);
				let matrixData = ans?.MatrixDataModel;

				let obj = {
					riceMatrixName: ans?.riceMatrixName,
					solutionName: ans?.problemSolutionFitName,
					data: ans?.MatrixDataModel,
				};
				allVersions.push(obj);
			}
		});

		if (allVersions || allVersions.length > 0) {
			handleGenerateCall(allVersions);
		}
	};

	const handleDownload = (url, filename) => {
		var a = document.createElement("a");
		a.setAttribute("href", url);
		a.setAttribute("download", filename || "");
		document.body.appendChild(a);
		a.onclick = function () {
			setTimeout(function () {
				document.body.removeChild(a);
			}, 100);
		};
		a.click();
	};

	const handleGenerateCall = (data) => {
		services
			.fileController(data)
			.then((response) => {
				if (response.data.code === 200) {
					handleDownload(response?.data?.data?.fileLocation, response?.data?.data?.fileName);
					setGenerateLoader(false);
				}
			})
			.catch((error) => {
				const message = error.response?.data?.message;
				toastContainer(message, "error");
				console.log(error);
				setGenerateLoader(false);
			});
	};

	const handleVersionChange = (value) => {
		let data = mvpVersions?.find((obj) => obj?.name === value);

		if (data) {
			setVersionData(data);
			setModuleResponseId(data?.id);
		} else {
			setVersionData("");
			setModuleResponseId("");
		}
	};

	const updateAllMvpVersions = (newResponses, saveAs = false) => {
		let vId = versionData.id;
		let name = newTitle ? newTitle : title;

		let data = JSON.stringify({
			riceMatrixName: saveAs ? newTitle : title,
			problemSolutionFitName: version,
			MatrixDataModel: answers,
		});
		let allData = mvpVersions;
		let x;
		if (newResponses) {
			x = newResponses?.moduleStepResponses;
		} else {
			x = allData?.find((obj) => obj.id == vId).moduleStepResponses[0].answers;
			x = data;
		}

		allData.map((ele, i) => {
			if (ele.id == vId) {
				if (x) {
					ele.moduleStepResponses = x;
				}
				ele.name = name;
			}
		});

		setMatrixLoader(false);
		setMvpVersions(allData);
	};

	const shouldShowPrompt = () => {
		if (confirmation) {
			return true;
		} else {
			return false;
		}
	};

	return (
		<>
			<Grid container>
				<Grid
					item
					xs={12}
					sx={{
						my: 2,
						display: "flex",
						flexDirection: "row",
						justifyContent: "flex-end",
						width: 1,
						px: { xs: 1, md: 3 },

						position: "-webkit-sticky",
						position: "sticky",
						top: 0,

						//zIndex: 5,
					}}
				>
					{showVersion && (
						<Box
							sx={{
								display: "flex",
								gap: 1,
								position: "-webkit-sticky",
								position: "sticky",
								top: 0,
							}}
						>
							<Button
								variant="outlined"
								sx={{
									color: (theme) => theme.palette.text.onSurfaceVariant,
									width: "max-content",
								}}
								size="small"
								onClick={handlePopupOpen}
							>
								<MoreHorizIcon />
							</Button>
							<Box sx={{ pt: 2 }}>{!isSmallScreen && <HandlePopOver />}</Box>

							<Button
								variant="outlined"
								sx={{
									maxWidth: "max-content",
									display: "flex",
								}}
								startIcon={!generateLoader && <LaunchIcon />}
								size="small"
								onClick={handleGenerateLaunchPlan}
							>
								{generateLoader ? (
									<>
										<CircularProgress
											sx={{
												display: "flex",
												justifyContent: "center",
												maxWidth: "30px",
												px: 3,
											}}
										/>
									</>
								) : (
									"Generate Launch Plan"
								)}
							</Button>

							<Button
								sx={{
									display: { xs: "none", md: "flex" },
									maxWidth: "max-content",
								}}
								onClick={() => {
									handleNext();
								}}
							>
								{saveLoading ? (
									<CircularProgress
										sx={{
											display: "flex",
											justifyContent: "center",
											maxWidth: "30px",
											px: 3,
											color: (theme) => theme.palette.secondary.white,
										}}
									/>
								) : (
									"Save Changes"
								)}
							</Button>
						</Box>
					)}
				</Grid>
				<Grid
					item
					xs={12}
					sx={{
						mb: 2,
						display: { xs: "grid", md: "none" },
						flexDirection: "row",
						justifyContent: "flex-end",
						alignItems: "flex-end",
						alignContent: "flex-end",
						width: 1,
						mx: 1,
					}}
				>
					<Box>
						<Button
							sx={{
								px: 8,
							}}
							size="large"
							onClick={() => {
								handleNext();
							}}
						>
							{saveLoading ? (
								<CircularProgress
									sx={{
										display: "flex",
										justifyContent: "center",
										px: 4,
										color: (theme) => theme.palette.secondary.white,
									}}
								/>
							) : (
								"Save Changes"
							)}
						</Button>
					</Box>
				</Grid>
			</Grid>
			{!showVersion ? (
				<Grid container>
					<Grid item xs={12} sx={{ display: "flex", flexDirection: "column", gap: 1, px: 2 }}>
						<Typography variant="h22Bold" sx={(theme) => ({ color: theme.palette.secondary.korophKoal })}>
							RICE Prioritization Matrix
						</Typography>
						<Typography variant="bodyMedium" sx={(theme) => ({ color: theme.palette.secondary.outline })}>
							It’s time to generate and prioritize features based on a version of your Problem-Solution Fit PowerUp. Remember to prioritize based
							on the Reach, Impact, Confidence and Effort of each potential feature, which will then be used to auto-populate your RICE score.{" "}
						</Typography>
					</Grid>
					<Grid
						item
						xs={12}
						sx={{
							display: "flex",
							justifyContent: "center",
							flexDirection: "column",
							alignItems: "center",
							textAlign: "center",
							gap: 2,
							mt: { xs: 4, md: 10 },
						}}
					>
						<Box component="img" src={MVP_NO_DATA_IMG} />
						<Typography variant="ph_text_bold" sx={(theme) => ({ color: theme.palette.secondary.korophKoal })}>
							It’s a bit empty in here... Click the button below <br /> to start your first matrix.
						</Typography>
						<Button
							sx={{
								maxWidth: "max-content",
							}}
							onClick={() => {
								if (!versionsLoading) {
									handlePopup();
									setVersionType("FIRST_VERSION");
								}
							}}
						>
							{versionsLoading ? (
								<CircularProgress
									sx={{
										display: "flex",
										justifyContent: "center",
										maxWidth: "30px",
										px: 3,
										color: (theme) => theme.palette.secondary.white,
									}}
								/>
							) : (
								"Create Matrix"
							)}
						</Button>
					</Grid>
				</Grid>
			) : (
				<>
					<RouterPrompt when={shouldShowPrompt} stepPath={""} />
					{matrixLoader ? (
						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								mt: 5,
							}}
						>
							<CircularProgress
								sx={{
									display: "flex",
									justifyContent: "center",

									//maxWidth: "30px",
									//px: 3,
								}}
							/>
						</Box>
					) : (
						<>
							<Grid container sx={{ px: 2 }}>
								<Grid item xs={12} md={12} sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
									<Typography
										variant="h22Bold"
										sx={(theme) => ({
											color: theme.palette.secondary.korophKoal,
										})}
									>
										RICE Prioritization Matrix
									</Typography>
									<Typography variant="bodyMedium" sx={(theme) => ({ color: theme.palette.secondary.outline })}>
										It’s time to generate and prioritize features based on a version of your Problem-Solution Fit PowerUp. Remember to
										prioritize based on the Reach, Impact, Confidence and Effort of each potential feature, which will then be used to
										auto-populate your RICE score.
									</Typography>
								</Grid>

								<Grid
									item
									xs={12}
									md={6}
									sx={{
										display: "flex",
										flexDirection: "row",
										mt: 3,
										alignItems: "center",
									}}
								>
									<Grid item xs={3}>
										<Typography
											variant="label_medium_title"
											sx={(theme) => ({
												color: theme.palette.secondary.outline,
												maxWidth: "max-content",
												display: "flex",
											})}
										>
											Select RICE matrix:
										</Typography>
									</Grid>
									<Grid item xs={9} sx={{ maxWidth: 1, display: "grid" }}>
										<SelectInput
											fullWidth
											onInputChange={(e, type) => handleVersionChange(e.target.value)}
											selectedValue={title}
											inputKey="riceMatrix"
											options={
												mvpVersions.length
													? mvpVersions?.map((ele, i) => {
															return ele?.name;
													  })
													: []
											}
											sx={{ width: 1, overflow: "hidden" }}
											labelId={"demo-select-small"}
											id={"demo-select-small"}
										/>
									</Grid>
								</Grid>
								<Grid
									item
									md={6}
									sx={{
										display: { xs: "none", md: "flex" },
										flexDirection: "row",
										justifyContent: "right",
										alignSelf: "right",
										mt: 3,
									}}
								>
									<Grid item md={9} sx={{ width: 1 }}>
										<TextField
											fullwidth
											placeholder="Search features and solutions..."
											value={searchText}
											onChange={(e) => setSearchText(e.target.value)}
											InputProps={{
												startAdornment: (
													<InputAdornment position="start">
														<SearchIcon />
													</InputAdornment>
												),
												endAdornment: searchText && (
													<InputAdornment position="end" onClick={() => setSearchText("")}>
														<CloseIcon />
													</InputAdornment>
												),
											}}
											sx={{ width: 1 }}
										/>
									</Grid>
								</Grid>
								<Grid
									item
									xs={12}
									md={6}
									sx={{
										display: "flex",
										flexDirection: "row",
										mt: 2,
										alignItems: "center",
										gap: 2,
									}}
								>
									<Grid item xs={4}>
										<Typography variant="label_medium_title" sx={(theme) => ({ color: theme.palette.secondary.outline })}>
											Problem Solution Fit Source:
										</Typography>
									</Grid>
									<Grid item xs={8}>
										<TextField
											fullWidth
											value={version}
											disabled
											sx={{
												background: (theme) => theme.palette.secondary.disabled,
											}}
										/>
									</Grid>
								</Grid>
								<Grid item xs={12} sx={{ display: { xs: "flex", md: "none" }, mt: 3, gap: 1 }}>
									<Grid item xs={3}>
										<Button
											variant="outlined"
											sx={{
												height: 1,
												color: (theme) => theme.palette.text.onSurfaceVariant,
											}}
											startIcon={<SortIcon />}
											onClick={handleSortDrawer}
										>
											Filter
										</Button>
									</Grid>
									<Grid item xs={9}>
										<TextField
											fullwidth
											placeholder="Search features and solutions..."
											value={searchText}
											onChange={(e) => setSearchText(e.target.value)}
											InputProps={{
												startAdornment: (
													<InputAdornment position="start">
														<SearchIcon />
													</InputAdornment>
												),
												endAdornment: searchText && (
													<InputAdornment position="end" onClick={() => setSearchText("")}>
														<CloseIcon />
													</InputAdornment>
												),
											}}
											sx={{ width: 1 }}
										/>
									</Grid>
								</Grid>
								<Grid
									item
									sm={12}
									md={12}
									sx={{
										mt: 4,
										display: { xs: "none", md: "block" },
										overflowX: "hidden",
									}}
								>
									{/* web */}
									<CreateMatrixTable
										answers={matrixData}
										handleUpdateInput={handleUpdateInput}
										moduleDetails={moduleDetails}
										handleAddNewRow={handleAddNewRow}
										newRow={newRow}
										setNewRow={setNewRow}
										setAnswers={setAnswers}
										handleSortPopup={handleSortPopup}
										handleDeleteRow={handleDeleteRow}
										handleOnBlur={handleOnBlur}
									/>
								</Grid>
								<Grid item xs={12} sx={{ mt: 3, display: { xs: "block", md: "none" } }}>
									{/* mobile */}
									<CreateMatrixCard
										answers={matrixData}
										handleUpdateInput={handleUpdateInput}
										moduleDetails={moduleDetails}
										handleAddNewRow={handleAddNewRow}
										newRow={newRow}
										setNewRow={setNewRow}
										setAnswers={setAnswers}
										handleSort={handleSort}
										openDrawer={openDrawer}
										setOpenDrawer={setOpenDrawer}
										handleSortDrawer={handleSortDrawer}
										setSortField={setSortField}
										handleDeleteRow={handleDeleteRow}
										handleOnBlur={handleOnBlur}
									/>
									{/* Add new row text field */}
								</Grid>
								{/* Graph */}
							</Grid>
							<Box sx={{ mt: 6, ml: 2 }}>
								<Box>
									{" "}
									<Box display={"flex"}>
										{" "}
										<Typography
											variant="h22Bold"
											sx={(theme) => ({
												color: theme.palette.secondary.korophKoal,
											})}
										>
											Impact-Effort Matrix
										</Typography>
										<FileDownloadOutlinedIcon
											sx={{
												ml: 2,
												color: (theme) => theme.palette.text.atomic,
												cursor: "pointer",
											}}
											onClick={() => handleExportClick("png")}
										/>
									</Box>
									<Typography
										variant="bodyMedium"
										display={"grid"}
										sx={(theme) => ({
											color: theme.palette.secondary.outline,
											mt: 3,
										})}
									>
										It’s time to generate and prioritize features based on a version of your Problem-Solution Fit PowerUp. Remember to
										prioritize based on the Reach, Impact, Confidence and Effort of each potential feature, which will then be used to
										auto-populate your RICE score.{" "}
									</Typography>
								</Box>
								<Box sx={{ mt: 3 }}>
									<Typography variant="subhead2" sx={{ color: (theme) => theme.palette.secondary.outline }}>
										Filter by:
									</Typography>

									<Button
										id="demo-positioned-button"
										aria-controls={open ? "demo-positioned-menu" : undefined}
										aria-haspopup="true"
										aria-expanded={open ? "true" : undefined}
										variant="outlined"
										onClick={handleGraphFilter}
										sx={{
											maxWidth: "fit-content",
											color: (theme) => theme.palette.secondary.korophKoal,
											ml: 1,
										}}
										endIcon={<ArrowDropDownIcon />}
									>
										{graphFilterValue}
									</Button>
									<Menu
										id="demo-positioned-menu"
										aria-labelledby="demo-positioned-button"
										anchorEl={graphAnchorEl}
										open={openGraphFilter}
										onClose={() => handleGraphFilterClose}
										anchorOrigin={{
											vertical: "bottom",
											horizontal: "left",
										}}
										transformOrigin={{
											vertical: "top",
											horizontal: "left",
										}}
									>
										{" "}
										<MenuItem onClick={() => handleGraphFilterValueUpdate("All")}>All</MenuItem>
										<MenuItem onClick={() => handleGraphFilterValueUpdate("Approved")}>Approved</MenuItem>
									</Menu>
								</Box>
								<Box sx={{ mt: 5, mx: isSmallScreen ? 0 : 10 }}>
									<RiceMatrixGraph matrixData={matrixData} graphFilterValue={graphFilterValue} graphRef={graphRef} />
								</Box>
							</Box>
						</>
					)}
				</>
			)}

			{/* create dialog */}
			<DialogComponent
				isOpen={open}
				onClose={handlePopup}
				maxWidth="sm"
				dialogAction={<FooterComponent title="create" />}
				contentStyle={{ px: 0 }}
				footerStyle={{
					border: "none",
					mb: 2,
				}}
				PaperProps={(theme) => ({
					style: {
						borderRadius: 0,
						width: isSmallScreen && "100%",
					},
				})}
			>
				<Grid container sx={{ px: 4, display: "flex", flexDirection: "column", gap: 2 }}>
					<Grid item xs={12}>
						<Typography variant="onsurface_title_thick">Create New Matrix</Typography>
					</Grid>
					<Grid item xs={12} sx={{ pr: { xs: 0, md: 3 } }}>
						<Typography variant="bodyMedium" sx={(theme) => ({ color: theme.palette.secondary.outline })}>
							Select a name for your matrix and the version of Problem Solution Fit you want to use. Once your features are prioritized in the
							RICE matrix, this information will be used to generate your MVP Launch Plan. <br />
							<br />
							Remember, you can always create new versions as your product needs evolve!
						</Typography>
					</Grid>
					<Grid item xs={12} sx={{ pt: 2 }}>
						<TextField
							label={"RICE Matrix Title"}
							inputKey={"matrixTitle"}
							fullWidth
							//value={createNew ? titleNew : title}
							inputProps={{
								maxLength: "100",
								minLength: "3",
							}}
							onChange={(e) => {
								createNew ? setTitleNew(e.target.value) : setTitle(e.target.value);
								//handleUpdateInput(e.target.value , 'matrixName',)
							}}
							error={
								(titleNew.length > 0 && titleNew.length < 3) ||
								titleNew.length > 100 ||
								(title.length > 0 && title.length < 3) ||
								title.length > 100
							}
							helperText={"Input should between 3-100 characters"}
						/>
					</Grid>
					<Grid item xs={12} sx={{ pt: 2 }}>
						<SelectInput
							label="Problem Solution Fit version:"
							fullWidth
							onInputChange={(e, type) => {
								setPsVersion(e.target.value);
								handleVersion(e.target.value);
							}}
							//  onInput={inputChange}
							//  onBlur={handleValidations}
							//selectedValue={createNew ? versionNew : version}
							selectedValue={psVersion}
							inputKey="probleSolutionVersion"
							options={versionOptions}
						/>
					</Grid>
				</Grid>
			</DialogComponent>

			<DialogComponent
				isOpen={openSaveAs}
				onClose={handleSaveAsPopup}
				maxWidth="sm"
				dialogAction={<FooterComponent title="saveAs" />}
				contentStyle={{ px: 0 }}
				footerStyle={{
					border: "none",
					mb: 2,
				}}
				PaperProps={(theme) => ({
					style: {
						borderRadius: 0,
						width: isSmallScreen && "100%",
					},
				})}
			>
				<Grid container sx={{ px: 4, display: "flex", flexDirection: "column", gap: 2 }}>
					<Grid item xs={12}>
						<Typography variant="onsurface_title_thick">Save As</Typography>
					</Grid>
					<Grid item xs={12} sx={{ pr: { xs: 0, md: 3 } }}>
						<Typography variant="bodyMedium" sx={(theme) => ({ color: theme.palette.secondary.outline })}>
							This action will save the current state as a duplicate in the list of versions available. To continue, enter a distinguishable name
							you want to save this version as:
						</Typography>
					</Grid>
					<Grid item xs={12} sx={{ pt: 2 }}>
						<TextField
							label={"Name of Version*"}
							inputKey={"Name of Version*"}
							fullWidth
							//value={titleNew}
							onChange={(e) => {
								setTitleNew(e.target.value);
							}}
							error={(titleNew.length > 0 && titleNew.length < 3) || titleNew.length > 100}
							helperText={"Input should between 3-100 characters"}
						/>
					</Grid>
				</Grid>
			</DialogComponent>

			{/* rename dialog */}
			<DialogComponent
				isOpen={openRename}
				onClose={handleRenamePopup}
				maxWidth="sm"
				dialogAction={<FooterComponent title="rename" />}
				contentStyle={{ px: 0 }}
				footerStyle={{
					border: "none",
					mb: 2,
				}}
				PaperProps={(theme) => ({
					style: {
						borderRadius: 0,
						width: isSmallScreen && "100%",
					},
				})}
			>
				<Grid container sx={{ px: 4, display: "flex", flexDirection: "column", gap: 2 }}>
					<Grid item xs={12}>
						<Typography variant="onsurface_title_thick">Rename RICE Matrix</Typography>
					</Grid>
					<Grid item xs={12} sx={{ pr: { xs: 0, md: 3 } }}>
						<Typography variant="bodyMedium" sx={(theme) => ({ color: theme.palette.secondary.outline })}>
							This action will rename the current version into a new one:
						</Typography>
					</Grid>
					<Grid item xs={12} sx={{ pt: 2 }}>
						<TextField
							label={"Current Version Title"}
							inputKey={"Current Version Title"}
							fullWidth
							value={title}
							//onChange={e => {
							//  setTitle(e.target.value);
							//}}
						/>
					</Grid>
					<Grid item xs={12} sx={{ pt: 2 }}>
						<TextField
							label={"New Version Title*"}
							inputKey={"New Version Title*"}
							fullWidth
							onChange={(e) => {
								setNewTitle(e.target.value);
							}}
						/>
					</Grid>
				</Grid>
			</DialogComponent>

			{/* delete dialog*/}
			<MessageHandlerModal
				isOpen={openDelete}
				onCancel={() => {
					handleDeletePopup();
				}}
				onOk={() => {
					handleDeleteMatrix();
				}}
				text="Are you sure you want to delete this matrix?"
				okText="Delete"
				cancelText="No Thanks"
				messageType="primary"
				heading={"Delete"}
			/>

			{/* archive dialog*/}
			<MessageHandlerModal
				isOpen={openArchive}
				onCancel={() => {
					handleArchivePopup();
				}}
				onOk={() => {
					handleArchivePopup();
				}}
				text="Are you sure you want to archive this?"
				okText="Archive"
				cancelText="No Thanks"
				messageType="primary"
				heading={"Archive"}
			/>

			{isSmallScreen && <MatrixMenuDrawer open={openDrawerMenu} onClose={handleDrawerClose} menuOptions={mvpOptions} />}
			{/* sortpopper */}
			<Popover
				id={"simple-popover"}
				open={sortPopup}
				anchorEl={sortanchorEl}
				onClose={handleSortPopup}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				PaperProps={{
					sx: {
						borderRadius: 1,
						boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)",
						minWidth: "280px",
					},
				}}
			>
				<List>
					<ListItem disablePadding>
						<ListItemButton onClick={() => handleSort("asc")}>
							<ListItemText>
								<Typography variant="body_large">
									{sortFiled === "solution" || sortFiled === "featureName" ? "Sort from A-Z" : `Ascending to Descending Order`}
								</Typography>
							</ListItemText>
						</ListItemButton>
					</ListItem>
					<ListItem disablePadding>
						<ListItemButton onClick={() => handleSort("desc")}>
							<ListItemText>
								<Typography variant="body_large">
									{sortFiled === "solution" || sortFiled === "featureName" ? "Sort from Z-A" : `Descending to Ascending Order`}
								</Typography>
							</ListItemText>
						</ListItemButton>
					</ListItem>
				</List>
			</Popover>
			<MessageHandlerModal
				isOpen={deletePopup}
				onCancel={handleDeleteRow}
				onOk={deleteMatrixRow}
				okText="Delete"
				cancelText={"No thanks"}
				heading="Delete Row"
				text={"Are you sure you want to delete this row?"}
				messageType="primary"
			/>
			<MessageHandlerModal
				isOpen={discardPopup}
				onCancel={handlediscardPopup}
				onOk={() => {
					handleConfirmation();
					handlediscardPopup();
				}}
				okText="Discard"
				cancelText={"No thanks"}
				heading="Discard chnages"
				text={"Are you sure you want to discard your changes?"}
				messageType="primary"
			/>
		</>
	);
}
