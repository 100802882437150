import * as actionTypes from "../../actions/actionTypes";
import { updateObject } from "../../../utils/updateObject";
import { USER_TYPES } from "../../../utils/userTypes";

const initialState = {
	loading: false,
	currentTab: USER_TYPES.MENTOR_USER,
	networkMatches: [],
	totalMatches: 0,
	appliedFilteredValue: [],
	searchedText: "",
	filteredValue: [],
	locationList: [],
	partiallySelected: [],
	selectedValues: [],
	industryList: [],
	combinedStates: [],
	productOfferingList: [],
	businessTypeList: [],
	revenueTypeList: [],
	openFilterDialog: false,
	openMessageDialog: false,
	hiddenConfirmOpen: false,
	slidingConfirmationObject: {},
	messageWithMatch: {},
	requestSentOpen: false,
	openMeetingDialog: false,
	isAlumni: false,
};

const networkingDashboardReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SET_NETWORKING_DASHBOARD_DATA: {
			return action.payload === null ? initialState : updateObject(state, action.payload);
		}
		default:
			return state;
	}
};

export default networkingDashboardReducer;
