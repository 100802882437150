import {
  Box,
  Button,
  TextField,
  InputAdornment,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import OnboardingCardSelector from "../../../../components/Onboarding/OnboardingCardSelector";
import { WHATS_YOUR_INDUSTRY_FOCUS } from "../../../../constants/updated-onboarding";
import SearchIcon from "@mui/icons-material/Search";
import PageTitle from "../../../../components/Onboarding/PageTitle";
import { useSelector, useDispatch } from "react-redux";
import { setOnboardingData } from "../../../../modules/actions";

export default function IndustryFocus({ setComplete }) {
  const [industryOptions, setIndustryOptions] = useState(
    WHATS_YOUR_INDUSTRY_FOCUS
  );
  const [filterInput, setFilterInput] = useState("");
  const [remove, setRemove] = useState(false);

  const [userResponse, setUserResponse] = useState("");
  const savedState = useSelector(state => state?.onboarding?.industryFocus);
  const [savedData, setSavedData] = useState(savedState || []);
  const dispatch = useDispatch();

  useEffect(() => {
    if (savedData) {
      setUserResponse(savedData);
    }
  }, [savedData]);

  const callComplete = (complete, data) => {
    if (setComplete) {
      setComplete(complete, data);
    }
  };

  useEffect(() => {
    if (userResponse) {
      let itemToStore = [];
      if (filterInput) {
        itemToStore = [...savedState, ...userResponse];
        const set = new Set(itemToStore);
        itemToStore = Array.from(set);

        if (remove) {
          const existingIndex = savedData.findIndex(
            item => item === remove.text
          );

          if (existingIndex >= 0) {
            const data = [...savedData];
            data.splice(existingIndex, 1);
            itemToStore = data;
          }
        }
      } else {
        itemToStore = [...userResponse];
      }

      dispatch(setOnboardingData({ industryFocus: [...itemToStore] }));

      callComplete(1, { industryFocus: [...itemToStore] });
    } else {
      callComplete(0, { industryFocus: null });
    }
  }, [userResponse, remove]);

  const handleFilter = e => {
    //save selected data

    setFilterInput(e.target.value);

    //filter
    let dummyData = WHATS_YOUR_INDUSTRY_FOCUS.filter(
      obj => obj.text.toLowerCase().indexOf(e.target.value.toLowerCase()) >= 0
    );

    dummyData = dummyData.map(item => ({
      ...item,
      selected: savedData.includes(item.text),
    }));

    setIndustryOptions(dummyData);
    setSavedData(savedState);
  };

  return (
    <Box>
      <PageTitle
        title={"What’s your industry focus?"}
        description={"Select all that apply."}
      />
      {/* Search  */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          my: 5,
        }}
      >
        <TextField
          fullWidth
          placeholder="Search community created badges"
          autoComplete="off"
          spellCheck="true"
          type="text"
          variant="standard"
          InputProps={{
            disableUnderline: true,
            style: { height: "50px" },
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onChange={event => handleFilter(event)}
          sx={{
            maxWidth: "400px",
            px: 2,
            "&.MuiTextField-root": {
              backgroundColor: theme => theme.palette.containers.backgroundGray,
            },
          }}
        />
      </Box>

      <Box sx={{ my: 5 }}>
        {industryOptions.length ? (
          <OnboardingCardSelector
            cardItems={industryOptions}
            flex={1 / 3}
            gridColWidth={7}
            multiSelect
            setUserResponse={setUserResponse}
            userResponse={userResponse}
            savedData={savedData}
            search={true}
            setRemove={setRemove}
          />
        ) : (
          <Box
            sx={{
              display: "flex",
              width: 1,
              justifyContent: "center",
              alignContent: "center",
              py: 5,
            }}
          >
            <Typography
              color="primary"
              variant="h5"
              sx={theme => ({ color: theme.palette.secondary.dark })}
            >
              No data found.
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}
