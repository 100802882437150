import { Box, Divider, Grid, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import messages from "../../../../../constants/messages";
import {
  isProblemFilledCompletelyorBlank,
  problemInputValid,
} from "../functions/common";

export default function Problem({ userInputs, setUserInputs }) {
  const [errorMessages, setErrorMessages] = useState({
    problem1: {
      problem: "",
      symptoms: "",
      existingAlternatives: "",
    },
    problem2: {
      problem: "",
      symptoms: "",
      existingAlternatives: "",
    },
    problem3: {
      problem: "",
      symptoms: "",
      existingAlternatives: "",
    },
  });
  const handleInputChange = (e, key1, key2) => {
    const value = e.target.value.trimStart();
    const currentInput = { ...userInputs };
    currentInput[key1][key2] = value;
    setUserInputs(currentInput);
  };

  const handleBlur = (e, key1, key2) => {
    const value = e.target.value;
    if (
      problemInputValid(value) ||
      isProblemFilledCompletelyorBlank(key1, userInputs)
    ) {
      const newErrorMessages = { ...errorMessages };
      newErrorMessages[key1][key2] = "";
      setErrorMessages(newErrorMessages);
    } else {
      const newErrorMessages = { ...errorMessages };
      newErrorMessages[key1][key2] = messages.PROBLEM_INPUT_INVALID;
      setErrorMessages(newErrorMessages);
    }
  };

  return (
    <Box>
      <Divider />
      {/*Problem1*/}
      <Box sx={{ my: 2 }}>
        <Grid container>
          <Grid item md={1} sm={12} xs={12}>
            <Box sx={{ my: 2 }}>
              <Typography variant="title_small">1.</Typography>
            </Box>
          </Grid>
          <Grid item md={11} sm={12} xs={12}>
            <Grid container spacing={3}>
              <Grid item sm={12} xs={12}>
                <TextField
                  fullWidth
                  spellCheck="true"
                  label="Problem"
                  value={userInputs?.problem1.problem}
                  onChange={e => handleInputChange(e, "problem1", "problem")}
                  onBlur={e => handleBlur(e, "problem1", "problem")}
                  error={!!errorMessages.problem1.problem}
                  helperText={errorMessages.problem1.problem}
                />
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <TextField
                  fullWidth
                  spellCheck="true"
                  label="Symptoms"
                  value={userInputs?.problem1.symptoms}
                  onChange={e => handleInputChange(e, "problem1", "symptoms")}
                  onBlur={e => handleBlur(e, "problem1", "symptoms")}
                  error={!!errorMessages.problem1.symptoms}
                  helperText={errorMessages.problem1.symptoms}
                />
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <TextField
                  fullWidth
                  spellCheck="true"
                  label="Existing Alternatives"
                  value={userInputs?.problem1.existingAlternatives}
                  onChange={e =>
                    handleInputChange(e, "problem1", "existingAlternatives")
                  }
                  onBlur={e =>
                    handleBlur(e, "problem1", "existingAlternatives")
                  }
                  error={!!errorMessages.problem1.existingAlternatives}
                  helperText={errorMessages.problem1.existingAlternatives}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <Divider />

      {/*Problem2*/}
      <Box sx={{ my: 2 }}>
        <Grid container>
          <Grid item md={1} sm={12} xs={12}>
            <Box sx={{ my: 2 }}>
              <Typography variant="title_small">2.</Typography>
            </Box>
          </Grid>
          <Grid item md={11} sm={12} xs={12}>
            <Grid container spacing={3}>
              <Grid item sm={12} xs={12}>
                <TextField
                  fullWidth
                  spellCheck="true"
                  label="Problem"
                  value={userInputs?.problem2.problem}
                  onChange={e => handleInputChange(e, "problem2", "problem")}
                  onBlur={e => handleBlur(e, "problem2", "problem")}
                  error={!!errorMessages.problem2.problem}
                  helperText={errorMessages.problem2.problem}
                />
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <TextField
                  fullWidth
                  spellCheck="true"
                  label="Symptoms"
                  value={userInputs?.problem2.symptoms}
                  onChange={e => handleInputChange(e, "problem2", "symptoms")}
                  onBlur={e => handleBlur(e, "problem2", "symptoms")}
                  error={!!errorMessages.problem2.symptoms}
                  helperText={errorMessages.problem2.symptoms}
                />
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <TextField
                  fullWidth
                  spellCheck="true"
                  label="Existing Alternatives"
                  value={userInputs?.problem2.existingAlternatives}
                  onChange={e =>
                    handleInputChange(e, "problem2", "existingAlternatives")
                  }
                  onBlur={e =>
                    handleBlur(e, "problem2", "existingAlternatives")
                  }
                  error={!!errorMessages.problem2.existingAlternatives}
                  helperText={errorMessages.problem2.existingAlternatives}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <Divider />

      {/*Problem3*/}
      <Box sx={{ my: 2 }}>
        <Grid container>
          <Grid item md={1} sm={12} xs={12}>
            <Box sx={{ my: 2 }}>
              <Typography variant="title_small">3.</Typography>
            </Box>
          </Grid>
          <Grid item md={11} sm={12} xs={12}>
            <Grid container spacing={3}>
              <Grid item sm={12} xs={12}>
                <TextField
                  fullWidth
                  label="Problem"
                  spellCheck="true"
                  value={userInputs?.problem3.problem}
                  onChange={e => handleInputChange(e, "problem3", "problem")}
                  onBlur={e => handleBlur(e, "problem3", "problem")}
                  error={!!errorMessages.problem3.problem}
                  helperText={errorMessages.problem3.problem}
                />
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <TextField
                  fullWidth
                  label="Symptoms"
                  spellCheck="true"
                  value={userInputs?.problem3.symptoms}
                  onChange={e => handleInputChange(e, "problem3", "symptoms")}
                  onBlur={e => handleBlur(e, "problem3", "symptoms")}
                  error={!!errorMessages.problem3.symptoms}
                  helperText={errorMessages.problem3.symptoms}
                />
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <TextField
                  fullWidth
                  spellCheck="true"
                  label="Existing Alternatives"
                  value={userInputs?.problem3.existingAlternatives}
                  onChange={e =>
                    handleInputChange(e, "problem3", "existingAlternatives")
                  }
                  onBlur={e =>
                    handleBlur(e, "problem3", "existingAlternatives")
                  }
                  error={!!errorMessages.problem3.existingAlternatives}
                  helperText={errorMessages.problem3.existingAlternatives}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
