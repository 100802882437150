import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getPaybackGridData } from "../Functions/common";
import PaybackChartContainer from "../Charts/PaybackChatContainer";
import PaybackDataGrid from "../DataGrid/PayBackGrid";
import { handleMixPanel } from "../../../../utils/mixPanelEventHandling";
import { setBIDashboardData } from "../../../../modules/actions/biDashboardActions";
import BIDataGridDialog from "../DataGrid/BIDataGridDialog";

function Payback({ companyId, readOnly }) {
	const dispatch = useDispatch();
	const cacPayback = useSelector((store) => store?.biDashboard?.cacPayback);
	const loadingCacPayback = useSelector((store) => store?.biDashboard?.loadingCacPayback);
	const openCacPayback = useSelector((store) => store?.biDashboard?.openCacPayback);
	const [isEdited, setEdited] = useState(false);

	const gridData = cacPayback?.data ? cacPayback?.data : [];

	const ownCompanyId = useSelector((state) => state?.auth?.companyId);
	const usedCompanyId = companyId ? companyId : ownCompanyId;

	const fetchData = async () => {
		try {
			dispatch(setBIDashboardData({ loadingCacPayback: true }));
			const responseData = await getPaybackGridData(usedCompanyId);
			if (responseData) {
				dispatch(setBIDashboardData({ cacPayback: responseData }));
			}
			dispatch(setBIDashboardData({ loadingCacPayback: false }));
		} catch (error) {
			dispatch(setBIDashboardData({ loadingCacPayback: false }));
			console.log("error", error);
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	const onChartClick = () => {
		handleMixPanel("Grid CAC Payback Viewed");
		dispatch(setBIDashboardData({ openCacPayback: true }));
	};

	const handleClose = useCallback(() => {
		if (isEdited) {
			dispatch(
				setBIDashboardData({
					openCacPaybackLeave: true,
				})
			);
		} else {
			dispatch(setBIDashboardData({ openCacPayback: false }));
		}
	}, [isEdited]);

	return (
		<>
			<PaybackChartContainer data={cacPayback} onChartClick={onChartClick} loading={loadingCacPayback} readOnly={readOnly} />
			<BIDataGridDialog title="CAC Payback Period" isOpen={openCacPayback} onClose={handleClose}>
				<PaybackDataGrid
					gridData={gridData}
					readOnly={readOnly}
					usedCompanyId={usedCompanyId}
					onCancelClick={handleClose}
					isEdited={isEdited}
					setEdited={setEdited}
				/>
			</BIDataGridDialog>
		</>
	);
}

export default Payback;
