import React from "react";
import { Autocomplete, TextField, Chip, Box, Typography, Checkbox } from "@mui/material";
import { get } from "lodash";

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { CHECK_SQUARE_BROKEN_ICON, CHECK_SQUARE_ICON } from "../../../../../constants";

const checkBoxBlankIcon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkBoxCheckedIcon = <CheckBoxIcon fontSize="small" />;
function MultiSelectDropdown({ handleCheck, handleUncheck, value, options, placeholder, field, type }) {
	return (
		<Autocomplete
			multiple
			disableClearable
			sx={{
				"& .MuiOutlinedInput-root": {
					borderRadius: "30px",
					".MuiAutocomplete-input": {
						pl: 2,
					},
				},
			}}
			value={value ? value : []}
			isOptionEqualToValue={(option, value) => {
				if (!value) return false;
				return get(option, `${field}`, null) === value.checkboxValue;
			}}
			options={options ? options : []}
			disableCloseOnSelect
			getOptionLabel={(option) => get(option, `${field}`, null)}
			renderOption={(props, option, { selected }) => (
				<Box
					{...props}
					sx={{ display: "flex", alignItems: "center" }}
					onClick={() => {
						if (!selected) {
							let code = "";
							if (field === "countryName") {
								code = option?.countryCode;
							}
							if (field === "stateName") {
								code = option?.stateCode;
							}
							const newObj = {
								type: type,
								checkboxValue: get(option, `${field}`, null),
								code: code,
							};
							handleCheck(newObj);
						} else {
							handleUncheck(get(option, `${field}`, null));
						}
					}}
				>
					<Box component={"img"} src={selected ? CHECK_SQUARE_BROKEN_ICON : CHECK_SQUARE_ICON} mr={1} />
					<Typography variant="Text/sm/Regular">{get(option, `${field}`, null)}</Typography>
				</Box>
			)}
			fullWidth
			renderInput={(params) => <TextField {...params} placeholder={placeholder} />}
			renderTags={(value, getTagProps) => {
				return value.map((option, index) => (
					<Chip
						key={index}
						label={<Typography variant="Text/sm/Regular">{option.checkboxValue}</Typography>}
						{...getTagProps({ index })}
						onDelete={() => handleUncheck(option.checkboxValue, type)}
					/>
				));
			}}
		/>
	);
}

export default MultiSelectDropdown;
