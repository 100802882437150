export const TEXT_MAP = [
	{ id: "md-prob-sol-targetCustomer", title: "Who is your target customer? Outline their key traits.", description: "" },
	{ id: "md-prob-sol-cutomerProblems", title: "What are your customer's top 3 problems? Describe them briefly.", description: "" },
	{ id: "md-prob-sol-solution", title: "How does your product solve those key problems? Summarize the benefits.", description: "" },
	{ id: "md-prob-sol-solutionScale", title: "Example: 4 out of 5. Most customers see increased foot traffic and lower CAC.", description: "" },
	{ id: "md-prob-sol-solutionLimit", title: "What limitations or weaknesses exist in your solution's fit to the problems?", description: "" },
	{ id: "md-prob-sol-creativeScale", title: "Selected Creativity Level", description: "" },
	{ id: "md-prob-sol-finalOutput", title: "AI Assisted Output", description: "" },

	{ id: "md-story-act1", title: "Act One - Share your inspiration", description: "" },
	{ id: "md-story-act2", title: "Act Two - State your why", description: "" },
	{ id: "md-story-act3", title: "Act Three - Say who you’re battling for", description: "" },
	{ id: "md-story-creativeScale", title: "Selected Creativity Level", description: "" },
	{ id: "md-story-finalOutput", title: "AI Assisted Output", description: "" },

	{ id: "md-elevator-pitch-hook", title: "Your Captivating Hook", description: "" },
	{ id: "md-elevator-pitch-problem", title: "Your Concise Problem Statement", description: "" },
	{ id: "md-elevator-pitch-solution", title: "Your Enter your Compelling Solution", description: "" },
	{ id: "md-elevator-pitch-valueProp", title: "Your Unique Value Proposition", description: "" },
	{ id: "md-elevator-pitch-cta", title: "Your Clear Call to Action", description: "" },
	{ id: "md-elevator-pitch-creativeScale", title: "Selected Creativity Level", description: "" },
	{ id: "md-elevator-pitch-finalOutput", title: "AI Assisted Output", description: "" },

	{ id: "md-lean-canvas-companyDescription", title: "Description of your company", description: "" },
	{ id: "md-lean-canvas-creativeScale", title: "Selected Creativity Level", description: "" },
	{ id: "md-lean-canvas-finalOutput", title: "AI Generated Lean Canvas", description: "" },

	{ id: "md-pitchdeck-generator-finalOutput", title: "AI Assisted Output", description: "" },

	{
		id: "md-value-prop-hook",
		title: "Enter your captivating hook",
		info: {
			title: "Captivating hook",
			body: "An effective and innovative approach that directly addresses a pressing problem or challenge. By clearly defining the problem's impact and significance, you create a sense of urgency. Introducing your unique solution with its distinct benefits and advantages captures the attention and convinces your audience that your solution is the answer they've been looking for.",
		},
		placeholder: `Example: "Think Different" - Apple's iconic slogan serves as a captivating hook that captures their focus on innovation and creativity`,
	},
	{
		id: "md-value-prop-benefits",
		title: "Enter your key benefits",
		info: {
			title: "Key benefits",
			body: `<ul>
				<li>Identify the unique advantages and solutions your platform offers. </li>
				<li>Highlight the specific value users gain, such as time savings, cost savings, convenience, productivity improvements, or specific outcomes.</li>
				<li>Differentiate your platform by emphasizing its core benefits or solutions compared to alternatives in the market.</li>
			</ul>
			`,
		},
		placeholder: `Example: Apple provides beautifully designed, user-friendly devices and seamlessly integrated ecosystems for consumers and businesses.`,
	},
	{
		id: "md-value-prop-alternatives",
		title: "Enter your relevant alternatives",
		info: {
			title: "Relevant alternatives",
			body: `<ul>
				<li>Identify other platforms, services, or solutions that provide similar functionalities or address similar needs as your startup. </li>
				<li>Research competitors or alternatives in the market catering to your target audience with comparable offerings.</li>
				<li>Identify the main players or options that users might consider when looking for similar solutions.</li>
			</ul>
			`,
		},
		placeholder: `Example: Relevant alternatives include Samsung, Google, Microsoft, and Amazon, which also offer hardware devices and software services.`,
	},
	{
		id: "md-value-prop-differentiator",
		title: "Enter your unique differentiator",
		info: {
			title: "Unique differentiator ",
			body: `<ul>
				<li>Identify specific features, capabilities, or approaches that differentiate your startup from alternatives. </li>
				<li>Highlight the unique selling points or key strengths of your startup that make it a compelling choice.</li>
				<li>Emphasize any aspects that provide a superior user experience, better outcomes, or added benefits compared to the alternatives.</li>
			</ul>
			`,
		},
		placeholder: `Example: Apple's key differentiator is their tightly integrated hardware, software, and services ecosystem anchored around their aesthetic of design simplicity.`,
	},
	{
		id: "md-value-prop-features",
		title: "Enter your specific features/services",
		info: {
			title: "Specific features",
			body: `<ol>
				<li>Identify the key features or services that set your platform apart.</li>
				<li>Focus on the unique aspects or functionalities that provide value to your users. </li>
				<li>Use a bullet list format to highlight the features or services, keeping it clear and easy to read.</li>
			</ol>
			`,
		},
		placeholder: `Example: Key offerings include iPhone, iPad, Mac, Apple Watch, Apple TV, and software like iOS, Safari, iWork, iCloud, and Apple Music.`,
	},
	{
		id: "md-value-prop-audience",
		title: "Enter your target audience",
		info: {
			title: "Target audience",
			body: `<ol>
				<li>Identify the specific demographic or professional characteristics of your target audience.</li>
				<li>Highlight the relevant industries, job roles, or educational backgrounds that align with your platform's offerings.</li>
				<li>Consider any specific skill levels or experience levels that your platform caters to.</li>
			</ol>
			`,
		},
		placeholder: `Example: Apple targets mainstream consumers and creative professionals seeking premium, easy-to-use technology products and services.`,
	},
	{
		id: "md-value-prop-outcome",
		title: "Enter your desired outcome",
		info: {
			title: "Desired outcome",
			body: `<ol>
				<li>Identify the specific goals, improvements, or transformations that your platform aims to help your target audience achieve.</li>
				<li>Highlight the potential benefits, enhanced capabilities, or positive impact that can be attained through your platform.</li>
				<li>Consider any specific outcomes or transformations that are unique to your platform's offerings.</li>
			</ol>
			`,
		},
		placeholder: `Example: Apple aims to empower people to unleash their creativity, pursue their passions, and do their best work using Apple products and services.`,
	},
	{
		id: "md-value-prop-strengths",
		title: "Enter your core strengths/technologies",
		info: {
			title: "Core strengths",
			body: `<ol>
				<li>Identify the key strengths, expertise, or resources that form the foundation of your platform's capabilities.</li>
				<li>Highlight any innovative technologies, methodologies, or approaches that differentiate your platform from others.</li>
				<li>Consider any specific partnerships, collaborations, or unique assets that contribute to the core strengths of your platform. </li>
			</ol>
			`,
		},
		placeholder: `Example: Key strengths include Apple's silicon chip design, intuitive UX/UI, and seamless integration between hardware and software.`,
	},
	{
		id: "md-value-prop-valueProp",
		title: "Enter your overall value proposition",
		info: {
			title: "Overall value proposition",
			body: `<ol>
				<li>Identify the unique combination of benefits, solutions, or outcomes that your startup or solution offers to its target audience.</li>
				<li>Highlight the specific ways in which your startup or solution addresses the needs, challenges, or aspirations of its users.</li>
				<li>Consider the overall value or competitive advantage that your startup or solution provides compared to other alternatives.</li>
			</ol>
			`,
		},
		placeholder: `Example: Apple delivers intuitive, beautifully designed technology ecosystems that empower people to create, connect, and express themselves.`,
	},
	{
		id: "md-value-prop-additionalInfo",
		title: "Enter any additional details or metrics (Optional)",
		info: {
			title: "Additional details or metrics",
			body: `<ol>
				<li>Identify any specific metrics, statistics, or tangible outcomes that demonstrate the success or impact of your startup.</li>
				<li>Highlight any notable achievements, recognition, or customer testimonials that validate the effectiveness and value of your startup.</li>
				<li>Consider any unique features, guarantees, or performance indicators that enhance the overall value proposition of your startup.</li>
			</ol>
			`,
		},
		placeholder: `Example: Apple has over 1 billion active devices worldwide and is the world's most valuable brand, exemplifying their leadership in technology and innovation.`,
	},
	{ id: "md-value-prop-creativeScale", title: "Selected Creativity Level", description: "" },
	{ id: "md-value-prop-finalOutput", title: "AI Assisted Output", description: "" },

	{ id: "md-exit-strategy-business", title: "Describe your business", description: "" },
	{ id: "md-exit-strategy-valueProposition", title: "Describe your unique value proposition", description: "" },
	{ id: "md-exit-strategy-creativeScale", title: "Selected Creativity Level", description: "" },
	{ id: "md-exit-strategy-finalOutput", title: "AI Assisted Output", description: "" },

	{ id: "md-are-you-fundable-startup-name", title: "Startup name", description: "" },
	{ id: "md-are-you-fundable-about", title: "Describe your startup", description: "" },
	{ id: "md-are-you-fundable-email", title: "Email address", description: "" },
	{ id: "md-are-you-fundable-fulltime", title: "Are you working full-time on your startup?", description: "" },
	{ id: "md-are-you-fundable-incorporated", title: "Are you a properly incorporated entity?", description: "" },
	{ id: "md-are-you-fundable-captable", title: "Do you have a cap table?", description: "" },
	{ id: "md-are-you-fundable-website", title: "Do you have a website?", description: "" },
	{ id: "md-are-you-fundable-investorPitch", title: "How strong is your investor pitch presentation?", description: "" },
	{ id: "md-are-you-fundable-currentStage", title: "Select stage of startup", description: "" },
	{ id: "md-are-you-fundable-currentState", title: "What is the current state of your product or service?", description: "" },
	{ id: "md-are-you-fundable-monthlyRevenue", title: "Do you have any monthly recurring revenue (MRR)?", description: "" },
	{ id: "md-are-you-fundable-capitalRaised", title: "How much capital have you raised to date?", description: "" },
	// { id: "md-are-you-fundable-monthly-burnRate", title: "What is your monthly burn rate?", description: "" },
	{ id: "md-are-you-fundable-current-runway", title: "What is your Current Runway?", description: "" },

	{ id: "md-are-you-fundable-companySize", title: "What is your company size", description: "" },

	{ id: "md-are-you-fundable-businessModel", title: "What is your current business model?", description: "" },
	{ id: "md-are-you-fundable-incubator", title: "Have you Attended an accelerator?", description: "" },

	{ id: "md-are-you-fundable-growthRate", title: "What is your growth rate?", description: "" },
	{ id: "md-are-you-fundable-scalable", title: "Is your startup scalable?", description: "" },
	{ id: "md-are-you-fundable-creativeScale", title: "Selected Creativity Level", description: "" },
	{ id: "md-are-you-fundable-finalOutput", title: "AI Assisted Output", description: "" },

	{ id: "md-discovery-interview-stageValidated", title: "What stage have you already validated?", description: "" },
	{ id: "md-discovery-interview-researchPurpose", title: "What is the purpose of your research?", description: "" },
	{ id: "md-discovery-interview-creativeScale", title: "Selected Creativity Level", description: "" },
	{ id: "md-discovery-interview-finalOutput", title: "AI Assisted Output", description: "" },

	{ id: "md-moat-business", title: "Describe your business", description: "" },
	{ id: "md-moat-valueProposition", title: "Describe your unique value proposition", description: "" },
	{ id: "md-moat-creativeScale", title: "Selected Creativity Level", description: "" },
	{ id: "md-moat-finalOutput", title: "AI Assisted Output", description: "" },

	{
		id: "md-pricing-model-saas-valueProp",
		title: "What is the value proposition of your service?",
		description: "Understanding the unique value your service provides to customers is crucial in determining what they might be willing to pay for it.",
	},
	{
		id: "md-pricing-model-saas-whoCustomers",
		title: "Who are your customers, and what can they afford? ",
		description:
			"Pricing must take into account your target customer's budget and willingness to pay. Different customer segments may require different pricing models.",
	},
	{
		id: "md-pricing-model-saas-costs",
		title: "What are the costs associated with providing your service?",
		description:
			"You should understand both the direct costs such as hosting or transaction fees and indirect costs like sales and marketing expenses associated with your service.",
	},
	{
		id: "md-pricing-model-saas-howCustomers",
		title: "How do your customers use our service? ",
		description:
			"The usage patterns of your customers can inform your pricing model. If usage varies widely, a usage-based model might make sense. If most customers use a core set of features, a tiered model could work.",
	},
	{ id: "md-pricing-model-saas-objectives", title: "What are your business objectives?", description: "" },
	{
		id: "md-pricing-model-saas-competition",
		title: "What is the competitive landscape?",
		description: "Understanding how similar services are priced can give you a benchmark and help position your offering within the market.",
	},
	{ id: "md-pricing-model-saas-creativeScale", title: "Selected Complexity Level", description: "" },
	{ id: "md-pricing-model-saas-finalOutput", title: "AI Assisted Output", description: "" },

	{
		id: "md-hardware-pricing-modeler-valueProp",
		title: "What is the unique value your hardware product offers to customers?",
		description: "Identifying the primary benefits your hardware brings can guide pricing decisions based on perceived value.",
	},
	{
		id: "md-hardware-pricing-modeler-whoCustomers",
		title: "Who are your customers, and what can they afford? ",
		description:
			"Pricing must take into account your target customer's budget and willingness to pay. Different customer segments may require different pricing models.",
	},
	{
		id: "md-hardware-pricing-modeler-costs",
		title: "What are the costs associated with producing your hardware?",
		description:
			"Consider direct costs such as manufacturing, materials, quality control, and indirect costs like research & development, distribution, or marketing.",
	},
	{
		id: "md-hardware-pricing-modeler-howCustomers",
		title: "How do customers primarily use your hardware?",
		description: "Understanding the primary application or function can inform bundling, tiering, or accessory pricing.",
	},
	{ id: "md-hardware-pricing-modeler-objectives", title: "What are your business objectives?", description: "" },
	{
		id: "md-hardware-pricing-modeler-competition",
		title: "What is the competitive landscape?",
		description: "Understanding how similar services are priced can give you a benchmark and help position your offering within the market.",
	},
	{ id: "md-hardware-pricing-modeler-creativeScale", title: "Selected Complexity Level", description: "" },
	{ id: "md-hardware-pricing-modeler-finalOutput", title: "AI Assisted Output", description: "" },

	{
		id: "md-retail-pricing-modeler-valueProp",
		title: "What is the value proposition of your product?",
		description: "Understanding the unique value your product provides to customers is crucial in determining what they might be willing to pay for it.",
	},
	{
		id: "md-retail-pricing-modeler-whoCustomers",
		title: "Who are your customers, and what can they afford? ",
		description:
			"Pricing must take into account your target customer's budget and willingness to pay. Different customer segments may require different pricing models.",
	},
	{
		id: "md-retail-pricing-modeler-costs",
		title: "What are the costs involved in producing and distributing your product?",
		description: "Grasp both the direct costs such as manufacturing and procurement, and indirect costs like marketing, branding, and overheads.",
	},
	{
		id: "md-retail-pricing-modeler-howCustomers",
		title: "How do your customers typically engage with your product?",
		description: "Knowing how frequently and in what manner your product is utilized can provide insights into its value and pricing potential.",
	},
	{ id: "md-retail-pricing-modeler-objectives", title: "What are your business objectives?", description: "" },
	{
		id: "md-retail-pricing-modeler-competition",
		title: "What is the competitive landscape?",
		description: "Understanding how similar products are priced can give you a benchmark and help position your offering within the market.",
	},
	{ id: "md-retail-pricing-modeler-creativeScale", title: "Selected Complexity Level", description: "" },
	{ id: "md-retail-pricing-modeler-finalOutput", title: "AI Assisted Output", description: "" },

	{
		id: "md-services-pricing-modeler-valueProp",
		title: "What is the value proposition of your service?",
		description: "Understanding the unique value your service provides to customers is crucial in determining what they might be willing to pay for it.",
	},
	{
		id: "md-services-pricing-modeler-whoCustomers",
		title: "Who are your customers, and what can they afford? ",
		description:
			"Pricing must take into account your target customer's budget and willingness to pay. Different customer segments may require different pricing models.",
	},
	{
		id: "md-services-pricing-modeler-costs",
		title: "What are the costs associated with providing your service?",
		description:
			"What are the costs incurred in delivering your service? It's essential to account for both direct costs, such as labor and materials, and indirect costs like overhead, administration, and marketing.",
	},
	{
		id: "md-services-pricing-modeler-howCustomers",
		title: "How do your customers use our service? ",
		description:
			"How do clients typically engage with your service? Understanding their engagement patterns can influence the pricing strategy, whether it's a one-time project, recurring service, or subscription-based model.",
	},
	{ id: "md-services-pricing-modeler-objectives", title: "What are your business objectives?", description: "" },
	{
		id: "md-services-pricing-modeler-competition",
		title: "What is the competitive landscape?",
		description: "Understanding how similar services are priced can give you a benchmark and help position your offering within the market.",
	},
	{ id: "md-services-pricing-modeler-creativeScale", title: "Selected Complexity Level", description: "" },
	{ id: "md-services-pricing-modeler-finalOutput", title: "AI Assisted Output", description: "" },

	{
		id: "md-tam-industry",
		title: "Industry",
		info: {
			title: "Industry",
			body: "Different industries have varying market sizes. The TAM for a fintech startup targeting the global remittance market will differ vastly from a local artisanal bakery aiming at the neighborhood it's located in. By knowing the industry, businesses (and our AI) can better gauge the overall potential market size.",
		},
	},
	{
		id: "md-tam-acv",
		title: "Estimated Annual Contract Value (ACV)",
		info: {
			title: "Estimated Annual Contract Value (ACV)",
			body: `The "Estimated Annual Contract Value (ACV)" refers to the anticipated yearly revenue that a company expects to receive from a customer under a contract, typically for B2B (business-to-business) companies with recurring revenue models, such as subscription-based services.                                                      Estimated: This implies that the figure is a projection or forecast based on available data and assumptions.Annual: The figure relates to a one-year period. So, even if a contract is for multiple years or for a duration shorter than one year, the ACV gives the revenue value normalized to a year.Contract Value (ACV): Refers to the value or revenue from a specific agreement or contract with a customer.`,
		},
	},
	{
		id: "md-tam-number-of-people",
		title: "Number of people who will buy my product",
		info: {
			title: "Number of people who will buy my product",
			body: `Number of People doesn't necessarily mean individuals. Depending on your product or service, "people" could refer to businesses, households, or individual consumers. The important thing is to identify the relevant "unit" for your product or service.`,
		},
	},
	{
		id: "md-tam-average",
		title: "How many times a year (average)",
		info: {
			title: "How many times a year (average) ",
			body: `Estimate how often your offering will be purchased by each buyer within a one year period. For someone that's purchased an annual identity protection service or SaaS plan, it would likely be once a year. For a consumer that gets their car washed, it will likely happen several times a year.`,
		},
	},
	{
		id: "md-tam-audience-type",
		title: "Of the TAM, I will focus on (audience type)",
		info: {
			title: "Of the TAM, I will focus on (audience type)",
			body: `While TAM provides a broad view of everyone who might benefit from your product or service, SAM refines this to focus on the population that your business is truly positioned to capture and serve in its current or immediate future state.  That audience might be describe in a variety of ways (e.g., demographic, psychographic, behavioral, distribution channels, regulatory, etc.) that define the business's ideal customers. For instance, a luxury brand would target a wealthier segment of the population.`,
		},
	},
	{
		id: "md-tam-populaion-percentage",
		title: "Their percentage of the population",
	},
	{
		id: "md-tam-percentage-of-sam",
		title: "Their percentage of the SAM",
		info: {
			title: "Their percentage of the SAM",
			body: `It boils down to this question/note and perhaps similar questions where we ask the user to exclude certain populations as they walk it down from TAM to SAM and then SOM.  We also ask them to calculate the percentage. It seems to me that if the user says exclude "UI Designers, Hair Stylists, etc.", the AI would have greater intelligence at it's fingertips to assess what percentage of TAM that audience represents.`,
		},
	},
	{
		id: "md-tam-target-market-located",
		title: "Where is your target market located?",
		info: {
			title: "Where is your target market located?",
			body: `While TAM gives you the entire global potential of customers who might need your product or service, SAM narrows this down to the segment that you can realistically serve given your current (or planned) operational capacities, channels, and strategies. Target market location is used to denote geographical areas which could range from specific neighborhoods in a city, to entire cities, regions, countries, or even continents, depending on the scope and reach of your business.  Location could refer to physical (or even virtual) spaces where this audience is found.`,
		},
	},
	{
		id: "md-tam-not-focused-on",
		title: "Of the TAM, I will focus on (audience type)",
		info: {
			title: "Of the TAM, I will focus on (audience type)",
			body: `While TAM provides a broad view of everyone who might benefit from your product or service, SAM refines this to focus on the population that your business is truly positioned to capture and serve in its current or immediate future state.  That audience might be describe in a variety of ways (e.g., demographic, psychographic, behavioral, distribution channels, regulatory, etc.) that define the business's ideal customers. For instance, a luxury brand would target a wealthier segment of the population`,
		},
	},
	{
		id: "md-tam-exclude-populaion-percentage",
		title: "Which excludes (their percentage of the population)",
	},
	{ id: "md-tam-creativeScale", title: "Selected Creativity Level", description: "" },
	{ id: "md-tam-graphOutput", title: "Your TAM SAM SOM Bottom-Up Visualized", description: "" },
	{ id: "md-tam-finalOutput", title: "Our AI Analysis Comparison & Recommendation", description: "" },
	{ id: "md-tam-tableOutput", title: "AI Generated TAM SAM SOM Top-Down Analysis", description: "" },

	{ id: "md-persona-builder-personaDescription", title: "Description of your persona", description: "" },
	{ id: "md-persona-builder-finalOutput", title: "AI Generated Persona Builder", description: "" },

	{ id: "md-enterprise-ready-currentStage", title: "Select stage of startup", description: "" },
	{ id: "md-enterprise-ready-monthlyRevenue", title: "Do you have any monthly recurring revenue (MRR)?", description: "" },
	{ id: "md-enterprise-ready-capitalRaised", title: "How much capital have you raised to date?", description: "" },
	{ id: "md-enterprise-ready-current-runway", title: "What is your Current Runway?", description: "" },
	{ id: "md-enterprise-ready-companySize", title: "What is your company size", description: "" },

	{ id: "md-enterprise-ready-strategic-plan", title: "Does your startup have a clear, long-term strategic plan?", description: "" },
	{
		id: "md-enterprise-ready-strategic-partnerships",
		title: "Does your startup have strategic partnerships or alliances that enhance its value proposition?",
		description: "",
	},
	{ id: "md-enterprise-ready-patent", title: "Does your startup hold at least one patent that protects your unique product or IP?", description: "" },
	{
		id: "md-enterprise-ready-strategy-for-leveraging",
		title: "Do you have a strategy for leveraging your unique product or IP for competitive advantage?",
		description: "",
	},
	{
		id: "md-enterprise-ready-startup-own-cash",
		title: "Does your startup own cash or collateral equal to at least 80% of its current financial liability?",
		description: "",
	},
	{
		id: "md-enterprise-ready-any-litigation",
		title: "Are there any liens against your startup, or is your startup involved in any form of litigation?",
		description: "",
	},
	{
		id: "md-enterprise-ready-legal-liabilities",
		title: "Are there any legal liabilities or exceptional risks involved with using your startup or product?",
		description: "",
	},
	{ id: "md-enterprise-ready-product-offering-scale", title: "Does your product / service offering scale?", description: "" },
	{
		id: "md-enterprise-ready-unique-value-proposition",
		title: "Does your product / service offer a unique value proposition compared to competitors?",
		description: "",
	},
	{ id: "md-enterprise-ready-competitive-landscape", title: "Do you have a clear understanding of the competitive landscape?", description: "" },
	{ id: "md-enterprise-ready-roadmap", title: "Is there a roadmap for future product / service development and innovation?", description: "" },
	{
		id: "md-enterprise-ready-process-for-soliciting",
		title: "Does your startup have a well-defined process for soliciting and incorporating customer feedback?",
		description: "",
	},
	{ id: "md-enterprise-ready-support-customers", title: "Do you have clear processes to support customers?", description: "" },
	{ id: "md-enterprise-ready-flagship-customer", title: "Do you have a flagship customer?", description: "" },
	{
		id: "md-enterprise-ready-proven-pricing-strategy",
		title: "Do you have a proven pricing strategy, and saleable, packaged product / service menu?",
		description: "",
	},
	{ id: "md-enterprise-ready-proven-sales", title: "Does your team have a proven (profitable) sales and marketing strategy?", description: "" },
	{ id: "md-enterprise-measure-the-success", title: "Are there metrics in place to measure the success of sales and marketing efforts?", description: "" },
	{ id: "md-enterprise-endorsements", title: "Do you have endorsements, testimonials and referrals outside your friends & family circle?", description: "" },
	{
		id: "md-enterprise-ready-business-meet-operational",
		title: "Does your business meet operational, regional and industry-specific compliance requirements?",
		description: "",
	},
	{
		id: "md-enterprise-ready-clear-policies-for-regulatory",
		title: "Are there clear policies for regulatory compliance in all operational regions?",
		description: "",
	},
	{ id: "md-enterprise-ready-ready-for-procurement", title: "Are you ready for procurement, contracts, pricing, NDAs?", description: "" },
	{
		id: "md-enterprise-ready-attract-and-retain-talent",
		title: "Does your startup have a clear, ongoing strategic  initiative to attract and retain talent?",
		description: "",
	},
	{
		id: "md-enterprise-ready-clear-HR-policies",
		title: "Are there effective HR policies in place, including diversity and inclusion initiatives?",
		description: "",
	},
	{
		id: "md-enterprise-ready-clear-environmental-sustainability-policies",
		title: "Does the startup have policies regarding environmental sustainability?",
		description: "",
	},
	{ id: "md-enterprise-ready-social-responsibility-initiatives", title: "Does the startup engage in social responsibility initiatives?", description: "" },
	{ id: "md-enterprise-ready-depend-on-a-Web", title: "Does your startup depend on a Web / SaaS platform?", description: "" },
	{ id: "md-enterprise-ready-SOC-2-compliant", title: "Is your platform SOC 2 compliant?", description: "" },
	{
		id: "md-enterprise-ready-platform-meet-compliance",
		title: "Does your platform meet operational, regional and industry-specific compliance requirements?",
		description: "",
	},
	{
		id: "md-enterprise-ready-technology-infrastructure-scale",
		title: "Can the startup's technology infrastructre scale to meet increasing demands?",
		description: "",
	},
	{ id: "md-enterprise-ready-system-monitoring", title: "Have you implemented system monitoring, alerting, and support protocols?", description: "" },
	{ id: "md-enterprise-ready-robust-data-analytics", title: "Are there robust data analytics and reporting capabilities?", description: "" },
	{ id: "md-enterprise-ready-disaster-recovery-plan", title: "Have you implemented a disaster recovery plan?", description: "" },
	{ id: "md-enterprise-ready-technological-upgrades", title: "Is there a plan for technological upgrades as the business grows?", description: "" },
	{ id: "md-enterprise-ready-hardware-product", title: "Does your startup have a hardware product?", description: "" },
	{ id: "md-enterprise-ready-deliver-the-hardware", title: "Does your startup deliver the hardware?", description: "" },
	{ id: "md-enterprise-ready-maintain-the-hardware", title: "Does your startup maintain the hardware?", description: "" },
	{ id: "md-enterprise-ready-hardware-returned", title: "Is the hardware returned when the contract or service ends?", description: "" },

	// { id: "md-enterprise-ready-scalable", title: "Is your startup scalable?", description: "" },
	{ id: "md-enterprise-ready-creativeScale", title: "Selected Creativity Level", description: "" },
	{ id: "md-enterprise-ready-finalOutput", title: "AI Assisted Output", description: "" },
];
