import React, { useState, useEffect } from "react";
import { Grid, Box, Typography, TextField, InputAdornment } from "@mui/material";
import StartupOsTextField from "../../../../common/StartupOsInput/StartupOsTextField";

export const CustomerAcquisitionCost = () => {
	const [totalCost, setTotalCost] = useState();
	const [numberOfCustomers, setNumberOfCustomers] = useState();
	const [cacValue, setCacValue] = useState(0);
	useEffect(() => {
		setCacValue(Math.floor(totalCost / numberOfCustomers));
	}, [totalCost, numberOfCustomers]);

	const handleInput = (e, type) => {
		let value = e.target.value.replace(/[^0-9]/g, "");
		if (type === "totalCost") {
			setTotalCost(value);
		} else if (type === "numberOfCustomers") {
			setNumberOfCustomers(value);
		}
	};
	return (
		<Grid container display={"flex"} alignItems="center" sx={{ mt: 5 }}>
			<Grid item xs={12} lg={12}>
				<Typography variant="testHeading">Formula</Typography>
				<Box display={"flex"} alignItems="center">
					<Box sx={{ mt: 3 }}>
						<Typography variant="title_portfolio" component={"p"}>
							Total Cost of Sales & Marketing Efforts
						</Typography>
						<Box display={"flex"} alignItems="center" gap={1}>
							<StartupOsTextField
								value={totalCost}
								fullWidth
								InputProps={{
									inputMode: "numeric",
									pattern: "[0-9]*",
									onChange: (e) => {
										handleInput(e, "totalCost");
									},
									startAdornment: <InputAdornment position="start">$</InputAdornment>,
								}}
								isEndAdornment={false}
								sx={{
									width: "90%",
									color: "black",
									height: "60px",
									mt: 1,
									".MuiOutlinedInput-root": {
										borderRadius: "30px",
										height: "60px",
									},
									".MuiInputBase-input": (theme) => {
										return { ...theme.typography.subhead3, color: theme.palette.secondary.black, pl: 0, pr: 2 };
									},
								}}
							/>
							<Typography sx={{ mt: 1 }} variant="title_portfolio">
								/
							</Typography>
						</Box>
					</Box>
					<Box sx={{ mt: 3 }}>
						<Typography variant="title_portfolio" component={"p"}>
							Number of New Customers Acquired
						</Typography>
						<TextField
							InputProps={{
								inputMode: "numeric",
								pattern: "[0-9]*",
								onChange: (e) => {
									handleInput(e, "numberOfCustomers");
								},
							}}
							value={numberOfCustomers}
							sx={{
								width: "90%",
								mt: 1,
								color: "black",
								height: "60px",
								".MuiOutlinedInput-root": {
									borderRadius: "30px",
									height: "60px",
								},
								".MuiInputBase-input": (theme) => {
									return { ...theme.typography.subhead3, color: theme.palette.secondary.black, px: 2.5 };
								},
							}}
						/>
					</Box>
				</Box>
			</Grid>
			<Grid item xs={12} lg={12} sx={{ mt: 3 }}>
				<Typography variant="card_text" component={"p"}>
					CAC
				</Typography>
				<Box display={"flex"} alignItems="center">
					<Typography variant="title_portfolio" component={"p"}>
						=
					</Typography>
					<Typography variant="PoppinsMedium100" component={"p"} noWrap overflow="hidden">
						$
						{!isFinite(cacValue)
							? 0
							: Intl.NumberFormat("en-US", {
									notation: "compact",
									maximumFractionDigits: 1,
							  }).format(Number(cacValue))}
					</Typography>
				</Box>
			</Grid>
		</Grid>
	);
};
