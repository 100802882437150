export const USER_TYPES = {
	STARTUP_USER: "STARTUP_USER",
	ADVISOR_USER: "ADVISOR_USER",
	MENTOR_USER: "MENTOR_USER",
	VERIFIED_MENTOR_USER: "VERIFIED_MENTOR_USER",

	INVESTOR_USER: "INVESTOR_USER",
	CANDIDATE_USER: "CANDIDATE_USER",
	STARTUP_OWNER: "STARTUP_OWNER",
	PARTNER_USER: "PARTNER_USER",
	PROFESSOR_USER: "PROFESSOR_USER",
	STUDENT_USER: "STUDENT_USER",
	ALUMNI_USER: "ALUMNI_USER",
	CORPORATE_USER: "CORPORATE_USER",
};
