import React from "react";
import { Typography, Box, Button, useMediaQuery } from "@mui/material";

import theme from "../../constants/Theme";
import CommonSlideDialog from "../../common/CommonSlideDialog";

export default function PaymentSuccessDialog({ open, onClose }) {
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

	return (
		<CommonSlideDialog
			open={open}
			onClose={onClose}
			title={
				<Box display="flex" flexDirection="column" p="0 0 8px">
					<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M11.0003 15.0003L13.667 17.667L19.667 11.667M22.8686 5.665C23.1431 6.32904 23.6701 6.85686 24.3337 7.13242L26.6606 8.09629C27.3247 8.37137 27.8523 8.89898 28.1274 9.56307C28.4024 10.2272 28.4024 10.9733 28.1274 11.6374L27.1642 13.9627C26.889 14.6271 26.8886 15.374 27.1651 16.0381L28.1266 18.3627C28.2629 18.6916 28.3331 19.0442 28.3332 19.4003C28.3332 19.7563 28.2631 20.1089 28.1269 20.4379C27.9906 20.7668 27.7909 21.0657 27.5391 21.3174C27.2873 21.5692 26.9884 21.7688 26.6594 21.905L24.3341 22.8681C23.6701 23.1427 23.1423 23.6697 22.8668 24.3334L21.9029 26.6604C21.6279 27.3244 21.1003 27.8521 20.4362 28.1271C19.7721 28.4022 19.026 28.4022 18.3619 28.1271L16.0367 27.1639C15.3726 26.8896 14.6268 26.8901 13.9631 27.1655L11.6362 28.128C10.9725 28.4024 10.227 28.4022 9.56348 28.1274C8.89996 27.8525 8.37266 27.3255 8.09741 26.6621L7.13328 24.3344C6.85873 23.6704 6.33172 23.1426 5.66812 22.867L3.34121 21.9032C2.67744 21.6282 2.15 21.1009 1.87484 20.4372C1.59967 19.7735 1.5993 19.0277 1.8738 18.3637L2.83694 16.0384C3.11133 15.3743 3.11077 14.6284 2.83539 13.9647L1.87362 11.636C1.73726 11.3071 1.66705 10.9546 1.66699 10.5985C1.66694 10.2424 1.73704 9.88986 1.87329 9.5609C2.00954 9.23195 2.20928 8.93306 2.46108 8.68133C2.71289 8.4296 3.01182 8.22995 3.34081 8.0938L5.66605 7.13061C6.32949 6.85629 6.85697 6.32993 7.13273 5.66707L8.09657 3.34007C8.37163 2.67599 8.89923 2.14837 9.56329 1.8733C10.2274 1.59822 10.9735 1.59822 11.6375 1.8733L13.9628 2.83648C14.6269 3.11088 15.3727 3.11032 16.0364 2.83492L18.3643 1.87479C19.0283 1.59987 19.7742 1.59993 20.4382 1.87495C21.1021 2.14997 21.6296 2.67743 21.9047 3.34134L22.8689 5.66903L22.8686 5.665Z"
							stroke="#7B61FF"
							stroke-width="1.5"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
					</svg>
				</Box>
			}
			titleVariant="title_medium"
			maxWidth="sm"
			btnCloseStyle={{ margin: "0 24px 0 0" }}
			PaperProps={{
				sx: {
					m: 0,
					px: 2,
					py: 2,
					borderRadius: "30px",
					width: isSmallScreen ? "100%" : theme.breakpoints.values.sm,
				},
			}}
			dialogAction={
				<Box display="flex" sx={{ pr: 3 }} alignItems="center">
					<Box>
						<Button
							sx={{
								borderRadius: "100px",
								background: "#7B61FF",
								fontWeight: 600,
								fontSize: 16,
								height: "48px",
							}}
							onClick={onClose}
						>
							Done
						</Button>
					</Box>
				</Box>
			}
		>
			<Box mt={-1}>
				<Typography variant="growth_card_subtitle" sx={{ fontWeight: 600, mb: 2, display: "block" }}>
					Woo! You’ve gone Premium!
				</Typography>
				<Typography variant="label_xlarge">
					Congrats! You’re now a premium user! Take advantage of all of the great benefits like connecting with mentors, reaching out to other fellow
					founders, and getting in front of investors!
				</Typography>
			</Box>
		</CommonSlideDialog>
	);
}
