import { useState } from "react";
import {
  SIDEBAR_ACTIVE_ICON,
  SIDEBAR_INACTIVE_ICON,
  SIDEBAR_COMPLETED_ICON,
  BACKARROW,
  CUSTOMERICON,
  LAUNCHICON,
  PROGRESS_PRIMARY,
  GLOWBULBICON,
  CHARGEICON,
  FUNDINGICON,
  SCHOOL,
  MARKETANALYSISICON,
  HARDWAREICON,
  SOFTWAREICON,
  MARKETPLACEICON,
  SERVICEICON,
  CONSUMERGOODSICON,
  EGGICON,
  MINIROADRUNNERICON,
  MINIRUNNERICON,
  MONITORING_SALES,
  CHICKICON,
  FLOWERICON,
  GRITICON,
  ENERGYICON,
  EYEVISIONICON,
  COMMUNITYICON,
  HEARTICON,
  EXECUTIONICON,
  OVERTIMECON,
  FULLTIMEICON,
  PARTTIMEICON,
  SIDEHUSTLEICON,
  NETWORKICON,
  NETWORKICON_GREY,
  NOTESICON,
  TASKSICON,
  PROGRESSDARKICON,
  STARTUPOSDARKICON,
  CURRENTSTREAKDARKICON,
  RADARDARKICON,
  LOCKICON,
  BLUEDASHBOARDICON,
  FEATUREDPARTNERICON,
  DASHBOARDICON,
  POWERUP,
  WORKSPACEICON,
  USERSICON,
  POWERUPSCIRCLEICON,
  NAVIGATIONCIRCLEICON,
  CALCULATORCIRCLEICON,
  PARTNERCIRCLEICON,
  TRACKCIRCLEICON,
  QUICKSTATCIRCLEICON,
  STARTUPOSCIRCLESMALLICON,
  STEP_COMPLETED,
  STEP_CURRENT,
  DIFFCULTY_ICON,
  TEAM_RECOMMEND_ICON,
  GREEN_TICK_ICON,
  PENCIL_ICON,
  GREEN_CHECK_ICON,
  DEFAULT_POWER_UP,
  DEFAULT_DEMO_DAY,
  DEFAULT_PLUGIN,
  DEFAULT_FUSION,
  ACTIVE_POWER_UP,
  ACTIVE_DEMODAY,
  ACTIVE_PLUGIN,
  ACTIVE_FUSION,
  COMPLETED_FUSION,
  COMPLETED_PLUGIN,
  COMPLETED_DEFAULT,
  FILLED_POWERUP,
  FILE_DOWNLOAD,
  JOURNEY_SMILE_ICON,
  POTTED_PLANT,
  DIVERSITY,
  PAYMENT_CARD_ICON,
  PAYMENT_CARD_ICON_BLUE,
  RAINY_CLOUD_PRIMARY,
  GREET_BLUE,
  INVENTORY_YELLOW,
  FOUR_LEAF_CLOVER,
  ANGEL_ICON,
  CAPITAL_ICON,
  CORPORATE_ICON,
  FAMILY_ICON,
  FOUNDATION_ICON,
  HACKER,
  BLUE_GRIT_ICON,
  FOLLOW_RIGHT,
  DIVERSITY4,
  POTTED_PLANT_BLUE,
  SVB_OUTLINED,
  SVEN_LOGO,
  MODEL_RED_3D,
  AI_PINK,
  AWS_BROWN,
  CYCLE_RED,
  DATABASE_PRIMARY,
  AUTOMOTIVE,
  MOVIE,
  MAP,
  LOCK,
  LOCAL_SHIPPING,
  HEALTH,
  FLIGHT,
  FASTFOOD,
  FACTORY,
  DVR,
  EDUCATION,
  DURABLE_GOODS,
  CONSUMER_SERVICES,
  CONSTRUCTION,
  COMPUTER,
  CLEANTECH,
  CHEMICAL,
  CALL,
  CABLE,
  BOLT,
  BLOCKCHAIN,
  BANK,
  ALT_ROUTE,
  AGRICULTURE,
  WIFI,
  VERIFIED_USER,
  VACCINE,
  SPA,
  SHOPPING,
  SAVINGS,
  PUBLIC,
  PRECISION,
  PACKAGE,
  OIL_BARREL,
  MUSIC,
  CAPITAL,
  PINK_MAGNET,
  GOOGLE_COLORED,
  LINKED_IN_UPDATED,
  ICS_FILE_ICON,
  MS_OUTLOOK_ICON,
  MS_OFFICE_ICON,
} from "../../constants/image";

import styled from "@emotion/styled";

import { Box, StepButton, StepIcon } from "@mui/material";

export const SidebarIconCompleted = ({ cursor }) => {
  return (
    <img
      src={SIDEBAR_COMPLETED_ICON}
      alt="completed"
      style={{ cursor: cursor }}
    />
  );
};

export const SidebarIconActive = ({ cursor }) => {
  return (
    <img src={SIDEBAR_ACTIVE_ICON} alt="active" style={{ cursor: cursor }} />
  );
};

export const SidebarIconInActive = ({ cursor }) => {
  return (
    <img
      src={SIDEBAR_INACTIVE_ICON}
      alt="inactive"
      style={{ cursor: cursor }}
    />
  );
};

export const BackIcon = () => {
  return <img src={BACKARROW} alt="back-arrow" />;
};

export const CustomerIcon = () => {
  return <img src={CUSTOMERICON} alt="customer-icon" />;
};

export const LaunchIcon = () => {
  return <img src={LAUNCHICON} alt="launch-icon" />;
};
export const GlowBulbIcon = () => {
  return <img src={GLOWBULBICON} alt="glob-bulb" />;
};

export const ChargeIcon = () => {
  return <img src={CHARGEICON} alt="charge" />;
};

export const FundingIcon = () => {
  return <img src={FUNDINGICON} alt="funding" />;
};

export const SmileIcon = () => {
  return <img src={JOURNEY_SMILE_ICON} alt="smile" />;
};

export const MarketAnalysisIcon = () => {
  return <img src={MARKETANALYSISICON} alt="market" />;
};

export const SoftwareIcon = () => {
  return <img src={SOFTWAREICON} alt="software" />;
};

export const HardwareIcon = () => {
  return <img src={HARDWAREICON} alt="hardware" />;
};

export const MarketPlaceIcon = () => {
  return <img src={MARKETPLACEICON} alt="marketplace" />;
};

export const ServiceIcon = () => {
  return <img src={SERVICEICON} alt="service" />;
};

export const ConsumerGoodsIcon = () => {
  return <img src={CONSUMERGOODSICON} alt="consumer" />;
};

export const EggIcon = () => {
  return <img src={EGGICON} alt="egg" />;
};

export const ChickIcon = () => {
  return <img src={CHICKICON} alt="chick" />;
};

export const MiniRunnerIcon = () => {
  return <img src={MINIRUNNERICON} alt="runner" />;
};

export const MiniRoaderRunnerIcon = () => {
  return <img src={MINIROADRUNNERICON} alt="runner" />;
};

export const FlowerIcon = () => {
  return <img src={FLOWERICON} alt="flower" />;
};

export const GritIcon = () => {
  return <img src={GRITICON} alt="grit" />;
};

export const EnergyIcon = () => {
  return <img src={ENERGYICON} alt="energy" />;
};

export const CommunityIcon = () => {
  return <img src={COMMUNITYICON} alt="community" />;
};

export const ExecutionIcon = () => {
  return <img src={EXECUTIONICON} alt="execution" />;
};

export const EyeVisionIcon = () => {
  return <img src={EYEVISIONICON} alt="eyevision" />;
};

export const NetworkIcon = () => {
  return <img src={NETWORKICON} alt="network" />;
};

export const HeartIcon = () => {
  return <img src={HEARTICON} alt="heart" />;
};

export const OverTimeIcon = () => {
  return <img src={OVERTIMECON} alt="overtime" />;
};

export const FullTimeIcon = () => {
  return <img src={FULLTIMEICON} alt="fulltime" />;
};

export const PartTimeIcon = () => {
  return <img src={PARTTIMEICON} alt="parttime" />;
};

export const SideHustleIcon = () => {
  return <img src={SIDEHUSTLEICON} alt="sidehustle" />;
};

export const PowerUpIcon = () => {
  return <img src={POWERUP} alt="powerup" />;
};
export const DashboardIcon = () => {
  return <img src={DASHBOARDICON} alt="dashboard" />;
};
export const WorkspaceIcon = () => {
  return <img src={WORKSPACEICON} alt="workspace" />;
};
export const SidebarNetworkIcon = () => {
  return <img src={NETWORKICON_GREY} alt="newtwork" />;
};
export const UsersIcon = () => {
  return <img src={USERSICON} alt="users" />;
};

export const NotesIcon = () => {
  return <img src={NOTESICON} alt="notes" />;
};

export const TasksIcon = () => {
  return <img src={TASKSICON} alt="tasks" />;
};

export const CurrentStreakIcon = () => {
  return <img src={CURRENTSTREAKDARKICON} alt="currentStreak" />;
};
export const ProgressDarkIcon = () => {
  return <img src={PROGRESSDARKICON} alt="progressDark" />;
};

export const ProgressPrimaryIcon = () => {
  return <img src={PROGRESS_PRIMARY} alt="progressDark" />;
};

export const StartuposDarkIcon = () => {
  return <img src={STARTUPOSDARKICON} alt="startupos" />;
};

export const RadarDarkIcon = () => {
  return <img src={RADARDARKICON} alt="radar" />;
};

export const LockIcon = () => {
  return <img src={LOCKICON} alt="lock" />;
};
export const BlueDashboardIcon = () => {
  return <img src={BLUEDASHBOARDICON} alt="dashboard" />;
};
export const FeaturedPartnerIcon = () => {
  return <img src={FEATUREDPARTNERICON} alt="partner" />;
};

export const PowerUpsCircleIcon = () => {
  return <img src={POWERUPSCIRCLEICON} alt="circle" />;
};

export const NavigationCircleIcon = () => {
  return <img src={NAVIGATIONCIRCLEICON} alt="navigation" />;
};

export const CalculatorCircleIcon = () => {
  return <img src={CALCULATORCIRCLEICON} alt="calculator" />;
};

export const PartnerCircleIcon = () => {
  return <img src={PARTNERCIRCLEICON} alt="partner" />;
};

export const TrackCicleIcon = () => {
  return <img src={TRACKCIRCLEICON} alt="track" />;
};

export const QuickStatCircleIcon = () => {
  return <img src={QUICKSTATCIRCLEICON} alt="quick" />;
};

export const StartupOSCircleSmallIcon = () => {
  return <img src={STARTUPOSCIRCLESMALLICON} alt="circle" />;
};

export const WorkspaceStepIcon = ({ active, style }) => {
  return (
    <img
      width={32}
      height={32}
      src={active ? STEP_COMPLETED : STEP_CURRENT}
      style={{ ...style }}
      alt="steps"
    />
  );
};
export const TeamRecommendIcon = () => {
  return <img src={TEAM_RECOMMEND_ICON} alt="team" />;
};

export const DifficultylIcon = () => {
  return <img src={DIFFCULTY_ICON} alt="difficulty" />;
};
export const GreenTicklIcon = () => {
  return <img src={GREEN_TICK_ICON} alt="tick" />;
};

export const PencilIcon = () => {
  return <img src={PENCIL_ICON} alt="pencil" />;
};

export const GreenCheckIcon = () => {
  return <img src={GREEN_CHECK_ICON} alt="check" />;
};
export const CounterCircleWrapperIcon = styled.div(({ theme }) => {
  return {
    height: "32px",
    width: "32px",
    borderRadius: theme.shape.standard23.borderRadius,
    border: `1px solid ${theme.palette.secondary?.dark}`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
});

export const PowerUpStepIcon = ({ active, style }) => {
  const [hovered, setHovered] = useState(false);
  return (
    <Box
      src={active || hovered ? ACTIVE_POWER_UP : DEFAULT_POWER_UP}
      sx={{
        ...style,
        width: 32,
        height: 32,
        fill: "white",
        borderRadius: "50%",
      }}
      alt="powerup"
      component="img"
      onMouseEnter={() => {
        setHovered(true);
      }}
      onMouseLeave={() => {
        setHovered(false);
      }}
    />
  );
};
export const PluginStepIcon = ({ active, style, completed }) => {
  const [hovered, setHovered] = useState(false);
  return (
    <Box
      src={
        active || hovered
          ? ACTIVE_PLUGIN
          : completed
          ? COMPLETED_PLUGIN
          : DEFAULT_PLUGIN
      }
      sx={{ ...style, width: 32, height: 32 }}
      component="img"
      alt="plugin"
      onMouseEnter={() => {
        setHovered(true);
      }}
      onMouseLeave={() => {
        setHovered(false);
      }}
    />
  );
};
export const FusionStepIcon = ({ active, style, completed }) => {
  const [hovered, setHovered] = useState(false);
  return (
    <Box
      src={
        active || hovered
          ? ACTIVE_FUSION
          : completed
          ? COMPLETED_FUSION
          : DEFAULT_FUSION
      }
      sx={{ ...style, width: 32, height: 32 }}
      component="img"
      alt="fusion"
      onMouseEnter={() => {
        setHovered(true);
      }}
      onMouseLeave={() => {
        setHovered(false);
      }}
    />
  );
};
export const DemoStepIcon = ({ active, style }) => {
  const [hovered, setHovered] = useState(false);
  return (
    <Box
      src={active || hovered ? ACTIVE_DEMODAY : DEFAULT_DEMO_DAY}
      sx={{ ...style, width: 32, height: 32 }}
      component="img"
      alt="demo"
      onMouseEnter={() => {
        setHovered(true);
      }}
      onMouseLeave={() => {
        setHovered(false);
      }}
    />
  );
};
export const CompletedIcon = ({ style, number }) => {
  return (
    <Box
      src={COMPLETED_DEFAULT}
      sx={{ ...style, width: 32, height: 32 }}
      component="img"
      alt="completed"
    />
  );
};

export const PowerUpFilledIcon = ({ style }) => {
  const [hovered, setHovered] = useState(false);
  return (
    <Box
      src={hovered ? ACTIVE_POWER_UP : FILLED_POWERUP}
      sx={{ ...style, width: 32, height: 32 }}
      component="img"
      alt="filled"
      onMouseEnter={() => {
        setHovered(true);
      }}
      onMouseLeave={() => {
        setHovered(false);
      }}
    />
  );
};

export const FileDownload = () => {
  return <img src={FILE_DOWNLOAD} alt="file_download" />;
};

export const MentorIcon = () => {
  return <img src={NETWORKICON} alt="mentor-icon" />;
};

export const AdvisorIcon = () => {
  return <img src={SCHOOL} alt="mentor-icon" />;
};

export const DiversityIcon = () => <img src={DIVERSITY} />;

export const PottedPlantIcon = () => <img src={POTTED_PLANT} />;

export const PaymentCardIcon = () => <img src={PAYMENT_CARD_ICON} />;

export const PaymentCardIconBlue = () => <img src={PAYMENT_CARD_ICON_BLUE} />;

export const RainyCloudPrimary = () => <img src={RAINY_CLOUD_PRIMARY} />;

export const GreetBlueIcon = () => <img src={GREET_BLUE} />;

export const InventoryYellowIcon = () => <img src={INVENTORY_YELLOW} />;

export const FourLeafIcon = () => <img src={FOUR_LEAF_CLOVER} />;
export const AngelIcon = () => <img src={ANGEL_ICON} />;

export const CapitalIcon = () => <img src={CAPITAL_ICON} />;

export const FamilyIcon = () => <img src={FAMILY_ICON} />;

export const FoundationIcon = () => <img src={FOUNDATION_ICON} />;

export const CorporateIcon = () => <img src={CORPORATE_ICON} />;

export const SalesMonitoringIcon = () => <img src={MONITORING_SALES} />;

export const HackerIcon = () => <img src={HACKER} />;
export const BlueGritIcon = () => <img src={BLUE_GRIT_ICON} />;

export const FollowRightIcon = () => <img src={FOLLOW_RIGHT} />;

export const PottedPlantBlueIcon = () => <img src={POTTED_PLANT_BLUE} />;

export const Diversity4 = () => <img src={DIVERSITY4} />;

export const SvbOutlineIcon = () => <img src={SVB_OUTLINED} />;

export const SvenIcon = () => <img src={SVEN_LOGO} />;

export const ModelRed3D = () => <img src={MODEL_RED_3D} />;

export const AiPink = () => <img src={AI_PINK} />;

export const AwsBrown = () => <img src={AWS_BROWN} />;

export const CycleRed = () => <img src={CYCLE_RED} />;

export const DatabasePrimary = () => <img src={DATABASE_PRIMARY} />;
export const AutomotiveIcon = () => <img src={AUTOMOTIVE} />;
export const MovieIcon = () => <img src={MOVIE} />;
export const MapIcon = () => <img src={MAP} />;
export const LockBlueIcon = () => <img src={LOCK} />;
export const LocalShippingIcon = () => <img src={LOCAL_SHIPPING} />;
export const HealthIcon = () => <img src={HEALTH} />;
export const FlightIcon = () => <img src={FLIGHT} />;
export const FastFoodIcon = () => <img src={FASTFOOD} />;
export const FactoryIcon = () => <img src={FACTORY} />;
export const DVRIcon = () => <img src={DVR} />;
export const EducationIcon = () => <img src={EDUCATION} />;
export const DurableGoodsIcon = () => <img src={DURABLE_GOODS} />;
export const ConsumerServicesIcon = () => <img src={CONSUMER_SERVICES} />;
export const ConstructionIcon = () => <img src={CONSTRUCTION} />;
export const ComputerIcon = () => <img src={COMPUTER} />;
export const CleantechIcon = () => <img src={CLEANTECH} />;
export const ChemicalIcon = () => <img src={CHEMICAL} />;
export const CallIcon = () => <img src={CALL} />;
export const CableIcon = () => <img src={CABLE} />;
export const BoltBlueIcon = () => <img src={BOLT} />;
export const BlockchainIcon = () => <img src={BLOCKCHAIN} />;
export const BankIcon = () => <img src={BANK} />;
export const ALTRouteIcon = () => <img src={ALT_ROUTE} />;
export const AgricultureIcon = () => <img src={AGRICULTURE} />;
export const WifiIcon = () => <img src={WIFI} />;
export const VerifiedUserIcon = () => <img src={VERIFIED_USER} />;
export const VaccineNewIcon = () => <img src={VACCINE} />;
export const SpaIcon = () => <img src={SPA} />;
export const ShoppingIcon = () => <img src={SHOPPING} />;
export const SavingIcon = () => <img src={SAVINGS} />;
export const PublicIcon = () => <img src={PUBLIC} />;
export const PrecisionIcon = () => <img src={PRECISION} />;
export const PackageIcon = () => <img src={PACKAGE} />;
export const OilBarrelIcon = () => <img src={OIL_BARREL} />;
export const MusicIcon = () => <img src={MUSIC} />;
export const CapitalNewIcon = () => <img src={CAPITAL} />;
export const PinkMagnetIcon = () => (
  <img src={PINK_MAGNET} height={24} width={24} />
);
export const GoogleColoredIcon = ({ size = 32 }) => (
  <img src={GOOGLE_COLORED} height={size} width={size} />
);
export const LinkedInIcon = (size = 32) => (
  <img src={LINKED_IN_UPDATED} height={size} width={size} />
);

export const IcsFileIcon = () => <img src={ICS_FILE_ICON} />;

export const MsOutlookIcon = () => <img src={MS_OUTLOOK_ICON} />;

export const MsOfficeIcon = () => <img src={MS_OFFICE_ICON} />;
