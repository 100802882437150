import React from "react";
import { Box, Typography } from "@mui/material";

import TooltipIcon from "../../Assets/info-circle.svg";

export const StepTitle = ({ title, icon, tooltip, errorMsg = "" }) => {
	return (
		<Box display="flex" alignItems="center" gap={1.5} mb="25px">
			<img src={icon} alt={title} />
			<Typography variant="main_title">{title}</Typography>
			<img src={TooltipIcon} alt="Tooltip" />
			{errorMsg !== "" && (
				<Typography variant="subhead2" sx={{ color: "#F04438" }}>
					{errorMsg}
				</Typography>
			)}
		</Box>
	);
};
