import React, { useEffect, useState, useRef } from "react";
import {
  useHistory,
  useParams,
  useLocation,
  generatePath,
} from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import {
  Stack,
  CircularProgress,
  Box,
  Button,
  Grid,
  Typography,
  Divider,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DownloadIcon from "@mui/icons-material/Download";
import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import theme from "../../../../constants/Theme";
import { PageContentWrapper } from "../../../../components/Workspace/PageContentWrapper";
import DetailsContainer from "../../../../components/MyStartup/StaticSummary/DetailsContainer";
import services from "../../../../services";
import SummaryContainer from "../../../../components/MyStartup/StaticSummary/SummaryContainer";
import ROUTES_PATH from "../../../../constants/routes";
import DownloadMenu from "../../../../common/DownloadMenu";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import MuiTooltip from "../../../../common/MuiTooltip";
import { archiveModuleResponse } from "../functions";
import { toastContainer } from "../../../../utils/toast";
import messages from "../../../../constants/messages";
import MessageHandlerModal from "../../../../common/modal/MessageHandler";
import SummaryExport from "../../../../common/SummaryExport/index";
import { sosPrimary } from "../../../../constants/Theme/colors";
import { handleMixPanel } from "../../../../utils/mixPanelEventHandling";
import {
  setPowerupDetails,
  setPowerupHeaderDetails,
} from "../../../../modules/actions";

function StaticSummary({ powerUpDetails }) {
  const history = useHistory();
  const [moduleDetails, setModuleDetails] = useState(null);
  const params = useParams();
  const location = useLocation();
  const summaryGridRef = useRef(null);
  const [archiveConfirmDialog, setArchiveConfirmDialog] = useState(false);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    handleMixPanel("Work History Completed Summary Viewed", {
      "Powerup ID": powerUpDetails.module.id,
      "Powerup Name": location.state.powerup
    });
    
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    services
      .getModuleStepResponses(params.id)
      .then(result => {
        if (result?.status === 200) {
          setModuleDetails(
            result?.data?.data?.find(
              obj => obj?.id == location?.state?.moduleId
            )?.moduleStepResponses
          );
        }
      })
      .catch(err => console.log(err));
  }, [params]);

  const handleExportClick = type => {
    summaryGridRef.current.handleExport(type);
  };

  const handleArchive = () => {
    setArchiveConfirmDialog(!archiveConfirmDialog);
  };

  const updateArchive = () => {
    archiveModuleResponse({
      responseId: location?.state?.moduleId,
      status: true,
    }).then(res => {
      if (res?.status === 200) {
        handleBack();
      } else {
        toastContainer(messages.API_SUBMIT_ERROR, "error");
      }
    });
    handleArchive();
  };

  const handleBack = () => {
    history.push({
      pathname: ROUTES_PATH.WORKSPACE_WORK_HISTORY,
      state: {
        showVersionsPage: true,
        powerupId: location.state.powerupId,
      },
    });
  };

  return (
    <Stack
      sx={theme => ({
        width: 1,
        background: theme.palette.secondary.white,
      })}
    >
      <Grid
        sx={{
          px: 2,
          py: 1,
          maxWidth: theme.breakpoints.values.xl,
          flexGrow: 1,
          margin: "auto",
          display: "flex",
          height: "max-content",
        }}
        id="summaryPage"
        container
      >
        <PageContentWrapper padding="0px" paddingBottom={3}>
          <Grid
            item
            xs={12}
            sx={{
              display: { xs: "none", md: "flex" },
              width: 1,
              flexDirection: "row",
              justifyContent: "space-between",
              px: 3,
              //py: 2,
            }}
          >
            <Typography variant="subhead6">Completed Summary</Typography>
            <Box sx={{ display: { xs: "none", md: "flex" }, gap: 3 }}>
              <DownloadMenu
                handleExportClick={type => handleExportClick(type)}
              />
              <MuiTooltip title="Archive">
                <ArchiveOutlinedIcon
                  sx={{
                    cursor: "pointer",
                    color: "secondary.main",
                    p: 1,
                    "&:hover": {
                      background: "rgba(73, 69, 79, 0.08)",
                      borderRadius: "50%",
                    },
                  }}
                  onClick={() => {
                    setArchiveConfirmDialog(true);
                  }}
                />
              </MuiTooltip>
            </Box>
          </Grid>

          {/* Reusable components */}
          <Grid item xs={12} sx={{ m: { xs: 1, md: 3 } }}>
            <DetailsContainer
              item={powerUpDetails}
              powerup={location.state.powerup}
              version={location.state.name}
            />
            <Divider
              sx={{
                mt: 3,
                background: `${theme.palette.text.background}`,
              }}
            />
          </Grid>
          <Box
            sx={theme => ({
              mx: { xs: 0, md: 3 },
              display: "grid",
              height: 1,
              maxWidth: 1,

              boxShadow: `0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)`,
              borderRadius: theme.shape.standard.borderRadius,
              px: 4,
              py: 3,
            })}
          >
            <Grid item xs={12} sx={{ maxWidth: 1 }}>
              <Box
                sx={{
                  display: { xs: "none", md: "block" },
                  width: 1,

                  justifySelf: "flex-end",
                }}
              >
                <Button
                  startIcon={<EditOutlinedIcon />}
                  variant="outlined"
                  sx={{
                    display: "flex",
                    width: "max-content",
                    float: "right",
                    ml: 2,
                  }}
                  onClick={
                    () => {
                      if (powerUpDetails?.module?.id === 3) {
                        history.push({
                          pathname: generatePath(
                            ROUTES_PATH.WORKSPACE_POWERUP_DETAILS,
                            { id: 3 }
                          ),
                        });
                      } else {
                        history.push(
                          `/workspace/powerup/${powerUpDetails?.module?.moduleUuid}/${powerUpDetails?.module?.id}/${powerUpDetails?.id}/start`
                        );
                      }
                      dispatch(
                        setPowerupHeaderDetails({
                          currentModuleResponseId: location?.state?.moduleId,
                        })
                      );
                    }
                    //history.push(
                    //  `/workspace/powerup/${powerUpDetails?.module?.moduleUuid}/${powerUpDetails?.module?.id}/${powerUpDetails?.id}/start`
                    //)
                  }
                >
                  Edit in Summary
                </Button>

                <Button
                  startIcon={<FormatListBulletedIcon />}
                  variant="nofill"
                  sx={{
                    display: "flex",
                    width: "max-content",
                    float: "right",
                  }}
                  onClick={() =>
                    history.push(
                      `/workspace/powerup/${location.state.powerupId}`
                    )
                  }
                >
                  View PowerUp details
                </Button>
              </Box>
            </Grid>

            <Grid item xs={12} md={12} sx={{ maxWidth: 1 }}>
              <Box
                sx={{
                  display: "flex",
                  width: 1,
                  py: 4,
                  height: 1,
                }}
              >
                <SummaryContainer
                  powerup={location?.state?.powerup}
                  response={moduleDetails}
                  versionId={location?.state?.moduleId}
                  moduleId={params.id}
                />
              </Box>
            </Grid>
          </Box>
        </PageContentWrapper>
        <MessageHandlerModal
          isOpen={archiveConfirmDialog}
          onCancel={handleArchive}
          onOk={updateArchive}
          text="Are you sure you want to archive this?"
          okText="Archive"
          cancelText="No Thanks"
          messageType="primary"
          heading={"Archive"}
        />
      </Grid>
      <Box>
        <SummaryExport
          ref={summaryGridRef}
          name={location?.state?.name}
        ></SummaryExport>
      </Box>
    </Stack>
  );
}

const mapStoreToProps = ({ startup }) => ({
  powerUpDetails: startup.myWorkProfile,
});

export default connect(mapStoreToProps, undefined)(StaticSummary);
