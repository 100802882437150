import React, { useEffect, useState, useMemo } from "react";
import { Box, CircularProgress } from "@mui/material";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

import services from "../../../../services";
import WorkspaceHeader, { ActionBtn } from "../../../../components/Investor/Workspace/View/Header";
import StartupLabsIcon from "../../../../components/Investor/Workspace/Assets/new-home-startuplabs-icon.svg";
import PowerPacksIcon from "../../../../components/Investor/Workspace/Assets/new-home-powerpacks-icon.svg";
import { HomeSectionTitle } from "../../../../components/Investor/Workspace/View/HomeTitle";
import HomeCard from "../../../../components/Investor/Workspace/View/HomeCard";
import { ShowCards } from "../PowerUps";
import SoonIcon from "../../../../components/Investor/Workspace/Assets/new-home-tools-soon-icon.svg";
import PowerPackDetailModal from "../../../../components/Investor/Workspace/View/PowerPackDetailModal";
import { useWindowSize } from "../../../../utils/windowResize";

ChartJS.register(ArcElement, Tooltip, Legend);

export default function PowerPacks() {
	const size = useWindowSize();
	const [packCards, setPackCards] = useState([]);
	const [comingCards, setComingCards] = useState([]);
	const [loading, setLoading] = useState(false);
	const [showAllPack, setShowAllPack] = useState(true);
	const [showAllComing, setShowAllComing] = useState(true);
	const [openPowerPackModal, setOpenPowerPackModal] = useState(false);
	const [packDetail, setPackDetail] = useState({ title: "", desc: "", items: [] });

	useEffect(() => {
		getGroupInfo();
	}, []);

	const cardLength = useMemo(() => {
		if (size.width > 1999) {
			return 4;
		} else if (size.width > 1587) {
			return 3;
		} else if (size.width > 1110) {
			return 2;
		} else if (size.width > 980) {
			return 1;
		} else if (size.width > 828) {
			return 2;
		} else {
			return 1;
		}
	}, [size]);

	const getGroupInfo = async () => {
		setLoading(true);
		try {
			const res = await services.getToDosList("powerpacks");
			setPackCards(res?.data?.data.filter((pack) => pack?.cardStatus !== "COMING_SOON"));
			setComingCards(res?.data?.data.filter((pack) => pack?.cardStatus === "COMING_SOON"));
		} catch (error) {
			console.log(error);
		}
		setLoading(false);
	};

	const getPackDetailInfoById = async (packId) => {
		try {
			const packInfo = packCards.filter((packCard) => packCard?.uuid === packId)[0];
			const packItemsInfo = await services.getToDosList(packId);
			setPackDetail({ title: packInfo?.title, desc: packInfo?.shortDescription, items: packItemsInfo?.data?.data || [] });
			setOpenPowerPackModal(true);
		} catch (error) {
			console.log(error);
		}
	};

	const packItems = useMemo(() => {
		return showAllPack ? packCards : packCards.slice(0, cardLength);
	}, [showAllPack, packCards, cardLength]);

	const comingItems = useMemo(() => {
		return showAllComing ? comingCards : comingCards.slice(0, cardLength);
	}, [showAllComing, comingCards, cardLength]);

	const handlePackCount = () => {
		setShowAllPack(!showAllPack);
	};

	const handleComingCount = () => {
		setShowAllComing(!showAllComing);
	};

	const handlePowerPackDetail = () => {
		setOpenPowerPackModal(false);
	};

	const Actions = (
		<Box display="flex" gap="30px">
			<ActionBtn color="#095CED" title="PowerPacks" avatar={StartupLabsIcon} tabIndex={1} actionIndex={0} onAction={() => {}} />
		</Box>
	);

	return (
		<Box>
			<WorkspaceHeader id={1} actions={Actions} />
			<Box mt={4}>
				<Box mb={4}>
					<HomeSectionTitle icon={PowerPacksIcon} title="PowerPacks" count={packCards.length} />
					{loading ? (
						<Box
							sx={{
								display: "flex",
								width: 1,
								justifyContent: "center",
								alignItems: "center",
								height: "50vh",
							}}
						>
							<CircularProgress />
						</Box>
					) : (
						<Box display="flex" flexWrap={"wrap"} gap="30px">
							{packItems.map((pack) => (
								<HomeCard
									key={pack.id}
									variant="secondary"
									uuid={pack.uuid}
									title={pack.title}
									imgSrc={pack.imageUrl}
									isAI={pack?.isAiPowered}
									isPremium={pack?.isPremiumFeature}
									onDetail={getPackDetailInfoById}
								/>
							))}
						</Box>
					)}
					{packCards.length > cardLength && <ShowCards handleShowCard={handlePackCount} isShow={showAllPack} />}
				</Box>
				<Box mb={4}>
					<HomeSectionTitle icon={SoonIcon} title="Coming Soon" count={comingCards.length} />
					{loading ? (
						<Box
							sx={{
								display: "flex",
								width: 1,
								justifyContent: "center",
								alignItems: "center",
								height: "50vh",
							}}
						>
							<CircularProgress />
						</Box>
					) : (
						<Box display="flex" flexWrap={"wrap"} gap="30px">
							{comingItems.map((coming) => (
								<HomeCard
									key={coming.id}
									variant="coming"
									uuid={coming.uuid}
									title={coming.title}
									desc={coming.shortDescription}
									imgSrc={coming.imageUrl}
									isAI={coming?.isAiPowered}
									isPremium={coming?.isPremiumFeature}
								/>
							))}
						</Box>
					)}
					{comingCards.length > cardLength && <ShowCards handleShowCard={handleComingCount} isShow={showAllComing} />}
				</Box>
			</Box>
			<PowerPackDetailModal open={openPowerPackModal} onClose={handlePowerPackDetail} data={packDetail} />
		</Box>
	);
}
