import React, { useState, useEffect, useContext, Suspense, useRef, useMemo } from "react";
import { Box, Button, Container } from "@mui/material";
import HeroCard from "./components/cards/HeroCard/HeroCard";
import TabBar from "./components/buttons/TabBar";
import LearnIt from "./compositions/LearnIt/LearnIt";
import {
	NESTED_POWER_UPS,
	getDetailsById,
	getModuleResponses,
	getModuleStepsById,
	getStartupProfile,
	getVersionByID,
	getVersionsByModuleId,
	getVersionsByPowerUPId,
	startNewVersion,
} from "./functions";
import PowerUPVersionModal from "./components/modals/PowerUPVersionModal";
import DoIt from "./compositions/DoIt/DoIt";
import ResetConfirmModal from "./components/modals/ResetConfirmModal";
import WorkViewModal from "./components/modals/WorkViewModal";
import { handleMixPanel } from "../../utils/mixPanelEventHandling";
import { useLocation } from "react-router-dom";
import UpdatedDialogConfirmation from "../../common/UpdatedDialogConfirmation";
import { BreadcrumbsComponent } from "../../components/Breadcrumbs";

// PowerUPContext to provide state and methods to sub-components
export const PowerUPContext = React.createContext();

export const PowerUPContainer = () => {
	const {
		activeTab,
		setActiveTab,
		currentVersion,
		setVersionModalOpen,
		versionModalOpen,
		versions,
		startNew,
		resetConfirmModalOpen,
		setResetConfirmModalOpen,
		workViewModalOpen,
		setWorkViewModalOpen,
		isPublicPowerup,
		powerupDetails,
		setcurrentVersion,
		startupProfileDetails,
	} = useContext(PowerUPContext);
	const location = useLocation();

	const tabSelected = (ind) => {
		if (ind === 0) setActiveTab(ind);
		else {
			/* 
			set tab => when set do-it
				is a version set?
					+ if a version ID exists then do not create new version
					+ if not a version
						check to see if there are incomplete versions
							display versions modal
								+ user selects the version and loads the data if available
								or
								+ user selects new version		
			*/

			if (isPublicPowerup) {
				setActiveTab(ind);
			} else {
				if (currentVersion?.id) {
					setActiveTab(ind);
				} else {
					// If no versions exist ie. not started; create a new one on 'Do It' click
					if (!versions) {
						//startNew();
					} else {
						// set version to latest version created
						if (versions?.[0]) setcurrentVersion(versions?.[0]);
						else {
							console.log("Did not find a version to set. Showing modal.");
							setVersionModalOpen(true);
						}
					}
				}
			}
		}
	};

	const getNestedRoutes = useMemo(() => {
		if (powerupDetails?.powerUpName) {
			const nestedIndex = NESTED_POWER_UPS.findIndex((powerUp) => powerUp.title === powerupDetails?.powerUpName);

			let navigationButtons = [];

			let bedcrumDetails = [
				{ title: "Home", route: "/" },
				{ title: powerupDetails?.powerUpName || "", route: "" },
			];

			if (nestedIndex !== -1) {
				navigationButtons = [
					{ title: "Home", route: "/" },
					{ title: NESTED_POWER_UPS[nestedIndex]?.previous?.title, route: NESTED_POWER_UPS[nestedIndex]?.previous?.route },
					{ title: NESTED_POWER_UPS[nestedIndex]?.next?.title, route: NESTED_POWER_UPS[nestedIndex]?.next?.route },
				];

				return { navigationButtons, bedcrumDetails };
			}
			return { bedcrumDetails };
		}

		return [];
	}, [powerupDetails?.powerUpName]);

	return (
		<Container sx={{ p: 3 }}>
			<Box pb={4}>
				{!isPublicPowerup && !location.pathname.includes("/home/try-a-demo") && (
					<BreadcrumbsComponent bedcrumDetails={getNestedRoutes?.bedcrumDetails} navigationButtons={getNestedRoutes?.navigationButtons} />
				)}
			</Box>

			<HeroCard />
			<Box
				sx={{
					my: "2.5rem",
				}}
			>
				<TabBar onSelect={(ind) => tabSelected(ind)} selectedTabIndex={activeTab} tabGroup={"default"} />
			</Box>
			{activeTab === 0 && <LearnIt />}
			{activeTab === 1 && isPublicPowerup ? <DoIt /> : activeTab === 1 && startupProfileDetails && <DoIt />}
			{versionModalOpen && <PowerUPVersionModal />}
			{resetConfirmModalOpen && <ResetConfirmModal />}
			<WorkViewModal />
		</Container>
	);
};

export const PowerUPController = () => {
	// State and methods for controlling sub-components
	const [activeTab, setActiveTab] = useState(0);
	const [knowledgeActiveTab, setKnowledgeTab] = useState(0);
	const [powerupDetails, setpowerupDetails] = useState(null);
	const [startupProfileDetails, setStartupProfileDetails] = useState(null);
	const [moduleSteps, setmoduleSteps] = useState(null);
	const [videoPlaying, setVideoPlaying] = useState(false);
	const [responses, setResponses] = useState(null);
	const [versions, setVersions] = useState(null);
	const [currentVersion, setcurrentVersion] = useState(null);
	const [versionModalOpen, setVersionModalOpen] = useState(false);
	const [versionPopoverOpen, setVersionPopoverOpen] = useState(false);
	const [resetConfirmModalOpen, setResetConfirmModalOpen] = useState(false);
	const [lastSaved, setLastSaved] = useState(null);
	const [workViewModalOpen, setWorkViewModalOpen] = useState(false);
	const [pdfContent, setPdfContent] = useState(null);
	const [tryDemoInitial, setTryDemoInitial] = useState(true);

	const location = useLocation();
	const isPublicPowerup = location.pathname.includes("/public/powerups");
	const currentVersionRef = useRef(currentVersion);
	const currentDetailsRef = useRef(powerupDetails);

	function getQueryParamValue(param, queryString) {
		const startIndex = queryString.indexOf(`?${param}=`);
		if (startIndex === -1) return null;
		const endIndex = queryString.indexOf("&", startIndex);
		const value = endIndex === -1 ? queryString.slice(startIndex + param.length + 2) : queryString.slice(startIndex + param.length + 2, endIndex);
		return decodeURIComponent(value);
	}

	useEffect(() => {
		getDetails();
	}, []);

	useEffect(() => {
		if (currentVersion?.id !== currentVersionRef?.current?.id) {
			getResponses();
			setPdfContent(null);
			setVersionModalOpen(false);
			setVersionPopoverOpen(false);

			if (currentVersion?.id) {
				// Set DoIt by default
				setActiveTab(1);
			} else {
				setActiveTab(0);
			}
		}
		currentVersionRef.current = currentVersion;
	}, [currentVersion]);

	useEffect(() => {
		if (powerupDetails?.id !== currentDetailsRef?.current?.id) {
			getSteps();
			getVersions();
			const versionID = getQueryParamValue("versionID", window.location.search);
			if (versionID) loadVersionFromURL(versionID);
		}
		currentDetailsRef.current = powerupDetails;
	}, [powerupDetails]);

	const handleAutoRedirectToDoIT = async (ind) => {
		/* 
			set tab => when set do-it
				is a version set?
					+ if a version ID exists then do not create new version
					+ if not a version
						check to see if there are incomplete versions
							display versions modal
								+ user selects the version and loads the data if available
								or
								+ user selects new version		
			*/
		const currentPath = location.pathname;
		const parts = currentPath.split("/");
		const lastPart = parts[parts.length - 1];
		const details = await getDetailsById(lastPart);
		if (details) {
			setTryDemoInitial(false);

			const versionList = await getVersionsByPowerUPId(details?.id);
			setVersions(versionList);
			let completedList = versionList?.filter((item) => !item?.isCompleted);

			// If no versions exist ie. not started; create a new one on 'Do It' click
			if (!versionList || completedList?.length === 0) {
				setActiveTab(ind);
				const version = await startNewVersion(details?.modules?.[0]?.id);
				if (version && version?.id) {
					setVersionModalOpen(false);
					setResetConfirmModalOpen(false);
					setcurrentVersion(version);
					getVersions();
				}
			}
			// else {
			// 	console.log("else");

			// 	setVersionModalOpen(true);
			// }
		}
	};

	useEffect(() => {
		if (tryDemoInitial && location.pathname.includes("/home/try-a-demo")) {
			handleAutoRedirectToDoIT(1);
		}
		if (tryDemoInitial && isPublicPowerup) {
			setActiveTab(1);
			setTryDemoInitial(false);
		}
	}, [activeTab]);

	useEffect(() => {
		if (activeTab === 1 && powerupDetails?.id) {
			handleMixPanel("Powerup Viewed", {
				Step: "Do It",
				"Powerup ID": powerupDetails?.id,
				"Powerup Name": powerupDetails?.powerUpName,
			});
		}
	}, [activeTab]);

	useEffect(() => {
		const foundVersion = versions?.find((item) => item?.id === currentVersionRef?.current?.id);
		if (currentVersionRef?.current?.isCompleted == false && foundVersion?.isCompleted == true) {
			getDetails(); // Update details to set the companyTask value to the current state.
			handleMixPanel(`Powerup Completed`, {
				"Powerup ID": powerupDetails?.id,
				"Powerup Name": powerupDetails?.powerUpName,
				"Time to Complete": "",
				"Completion Date": new Date(foundVersion?.completionDate)?.toISOString(),
			});
		}
	}, [versions]);

	const getVersions = async () => {
		if (powerupDetails && powerupDetails?.modules?.[0]?.id) {
			const versions = await getVersionsByPowerUPId(powerupDetails?.id);
			// Set current version and set the active tab to doit
			if (!currentVersionRef?.current?.id) {
				if (versions?.[0]) {
					setcurrentVersion(versions[0]);
				} else {
					startNew();
				}
			}
			setVersions(versions);
		}
	};

	const getResponses = async () => {
		if (!currentVersion?.id) return;

		if (powerupDetails && powerupDetails?.modules?.[0]?.id) {
			const responses = await getModuleResponses(powerupDetails?.modules?.[0]?.id, currentVersion?.id);
			setResponses(responses);
			return responses;
		}
	};

	const loadVersionFromURL = async (ID) => {
		const foundVersion = await getVersionByID(powerupDetails?.id, ID);
		if (foundVersion) setcurrentVersion(foundVersion);
	};

	const getDetails = async () => {
		// This is a workaround due to how the application is controlling powerups.
		// The :id param is not passed so unable to use hooks to detect.
		const currentPath = window.location.pathname;
		const parts = currentPath.split("/");
		const lastPart = parts[parts.length - 1];
		const details = await getDetailsById(lastPart);
		if (!isPublicPowerup) {
			const startupProfile = await getStartupProfile();
			setStartupProfileDetails(startupProfile);
		}
		setpowerupDetails(details);
		handleMixPanel("Powerup Viewed", {
			Step: "Learn It",
			"Powerup ID": details?.id,
			"Powerup Name": details?.powerUpName,
		});
	};

	const getSteps = async () => {
		if (powerupDetails && powerupDetails?.modules?.[0]?.moduleUuid) {
			const steps = await getModuleStepsById(powerupDetails?.modules?.[0]?.moduleUuid);
			setmoduleSteps(steps);
		} else {
			setmoduleSteps(null);
		}
	};

	const startNew = async (loadingCallback) => {
		if (powerupDetails && powerupDetails?.modules?.[0]?.id) {
			if (typeof loadingCallback === "function") loadingCallback(true);
			if (!versions || versions?.length < 1) {
				handleMixPanel("Powerup Started", {
					"PowerUp ID": powerupDetails?.id,
					"PowerUp Name": powerupDetails?.powerUpName,
					"Start Date": new Date()?.toISOString(),
				});
			} else {
				handleMixPanel("Powerup Resumed", {
					"PowerUp ID": powerupDetails?.id,
					"PowerUp Name": powerupDetails?.powerUpName,
					"Times Completed Count": powerupDetails?.companyTask?.completedWorks ?? 0,
					"Start Date": new Date()?.toISOString(),
				});
			}
			const version = await startNewVersion(powerupDetails?.modules?.[0]?.id);
			if (version && version?.id) {
				setVersionModalOpen(false);
				setResetConfirmModalOpen(false);
				setcurrentVersion(version);
				getVersions();
			} else {
				if (typeof loadingCallback === "function") loadingCallback(false);
			}
		}
	};

	const resetConfirm = () => {
		setResetConfirmModalOpen(true);
	};

	return (
		<PowerUPContext.Provider
			value={{
				activeTab,
				setActiveTab,
				powerupDetails,
				moduleSteps,
				knowledgeActiveTab,
				setKnowledgeTab,
				videoPlaying,
				setVideoPlaying,
				responses,
				setResponses,
				versions,
				currentVersion,
				setcurrentVersion,
				versionModalOpen,
				setVersionModalOpen,
				startNew,
				versionPopoverOpen,
				setVersionPopoverOpen,
				lastSaved,
				setLastSaved,
				getResponses,
				resetConfirm,
				resetConfirmModalOpen,
				setResetConfirmModalOpen,
				getVersions,
				workViewModalOpen,
				setWorkViewModalOpen,
				pdfContent,
				setPdfContent,
				startupProfileDetails,
				isPublicPowerup,
				setStartupProfileDetails,
			}}
		>
			<PowerUPContainer />
			<UpdatedDialogConfirmation />
		</PowerUPContext.Provider>
	);
};
