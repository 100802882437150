import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar";
import { Grid, Box } from "@mui/material";
import theme from "../../../../constants/Theme";
import services from "../../../../services";

export default function KnowledgeSidebar({ currenttab, powerUpId }) {
  const [powerupDetails, setPowerupDetails] = useState({});

  useEffect(() => {
    services
      .getPowerupData(powerUpId)
      .then(result => {
        if (result.data?.code === 200) {
          setPowerupDetails(result.data?.data);
        }
      })
      .catch(error => console.log(error));
  }, []);

  return (
    <Grid
      item
      md={4}
      sm={12}
      sx={{
        pl: 3,
        [theme.breakpoints.down("md")]: {
          pl: 0,
          mt: 0,
        },
      }}
    >
      <Box
        sx={{
          display: {
            sm: currenttab === "knowledge" ? "block" : "none",
            xs: currenttab === "knowledge" ? "block" : "none",
            md: "block",
          },
        }}
      >
        <Sidebar sidebar_content={powerupDetails} />
      </Box>
    </Grid>
  );
}
