import React, { useEffect, useState } from "react";
import { Typography, Box, Button } from "@mui/material";
import EastIcon from "@mui/icons-material/East";
import { useHistory } from "react-router-dom";

import { Card as PowerUpCard } from "../../../components/cards/Card";
import { ContentBlock } from "../../../components/cards/ContentBlock";
import { ROCKET_ICON_V2 } from "../../../../../constants";
import { getReadiness } from "../../../../../utils/PPTXGEN/Lambda/functions/pitchDeckGen";
import PitchDeckGenerationAnimation from "../../DoIt/PitchDeckGenerator/loadingAnimation";
import ROUTES_PATH from "../../../../../constants/routes";

export const Expectations = () => {
	return (
		<PowerUpCard>
			<Typography variant="Text/md/Semibold" component={"p"}>
				What should you expect?
			</Typography>
			<Typography my={"1rem"} component={"p"} variant="Text/sm/Regular">
				Using your startup profile and the following PowerUPs; we will generate a starter pitch deck presentation for you to make your own. It is highly
				recommended for you to have completed the following PowerUPs:
				<ul>
					<li>Founder Story</li>
					<li>Problem Solution Fit</li>
					<li>Value Proposition</li>
					<li>TAM SAM SOM</li>
					<li>Persona Builder</li>
				</ul>
			</Typography>
		</PowerUpCard>
	);
};

export const Contents = () => {
	const INITIAL_PROCESS_STATE = {
		loadingData: { loading: false, complete: false },
		profile: { loading: false, complete: false },
		story: { loading: false, complete: false },
		valueProp: { loading: false, complete: false },
		tss: { loading: false, complete: false },
		probSol: { loading: false, complete: false },
		persona: { loading: false, complete: false },
		AILoading: { loading: false, complete: false },
		hookAI: { loading: false, complete: false },
		storyAI: { loading: false, complete: false },
		probSolAI: { loading: false, complete: false },
		valuePropAI: { loading: false, complete: false },
		personaAI: { loading: false, complete: false },
		competitorsAI: { loading: false, complete: false },
	};
	const [processState, setProcessState] = useState(INITIAL_PROCESS_STATE);
	useEffect(() => {
		getReadiness(updateProcessState, null);
	}, []);
	const updateProcessState = (key, values) => {
		setProcessState((prevState) => ({
			...prevState,
			[key]: {
				...prevState[key],
				...values,
			},
		}));
	};
	return (
		<PowerUpCard>
			<Typography variant="Text/md/Semibold" component={"p"}>
				Contents
			</Typography>
			<ContentBlock>
				<Typography variant="Text/sm/Semibold" component={"p"}>
					Pitch Deck Readiness Check
				</Typography>
				<Typography variant="Text/sm/Regular" component={"p"}>
					We're checking to see if you have completed the recommended PowerUPs. You are able to generate a pitch deck presentation using this tool,
					however please note that it will be missing some key information that would have been sourced from the work you completed on these PowerUPs.
				</Typography>
			</ContentBlock>
			<Box sx={{ display: "flex", flex: 1, width: "100%" }}>
				<PitchDeckGenerationAnimation stateObj={processState} type="powerupdata" />
			</Box>
		</PowerUpCard>
	);
};

export const ImportanceForStartup = () => {
	return (
		<PowerUpCard>
			{" "}
			<Typography variant="Text/md/Semibold" component={"p"}>
				Why This Is Important for Your Startup
			</Typography>
			<Box display={"flex"} alignItems="flex-start" gap={1}>
				<Box component={"img"} src={ROCKET_ICON_V2} alt="icon" />
				<Box>
					<Typography variant="Text/sm/Semibold">Clarity and Direction -</Typography>
					<Typography my={"1rem"} variant="Text/sm/Regular">
						A pitch deck helps in clarifying the business idea, mission, vision, and strategy, allowing startups to maintain focus and direction.
					</Typography>
				</Box>
			</Box>
			<Box display={"flex"} alignItems="flex-start" gap={1}>
				<Box component={"img"} src={ROCKET_ICON_V2} alt="icon" />
				<Box>
					<Typography variant="Text/sm/Semibold">Investor Attraction -</Typography>
					<Typography my={"1rem"} variant="Text/sm/Regular">
						It is an essential tool for presenting the business idea in a concise and attractive manner to potential investors, helping in securing
						funding.
					</Typography>
				</Box>
			</Box>
			<Box display={"flex"} alignItems="flex-start" gap={1}>
				<Box component={"img"} src={ROCKET_ICON_V2} alt="icon" />
				<Box>
					<Typography variant="Text/sm/Semibold">Market Validation -</Typography>
					<Typography my={"1rem"} variant="Text/sm/Regular">
						The deck demonstrates market demand and validity, illustrating that the product or service meets a significant need or solves a problem
						within the target market.
					</Typography>
				</Box>
			</Box>
			<Box display={"flex"} alignItems="flex-start" gap={1}>
				<Box component={"img"} src={ROCKET_ICON_V2} alt="icon" />
				<Box>
					<Typography variant="Text/sm/Semibold">Team Presentation -</Typography>
					<Typography my={"1rem"} variant="Text/sm/Regular">
						It offers an opportunity to introduce the team and showcase the collective skills, experience, and passion, building credibility among
						potential investors or partners.
					</Typography>
				</Box>
			</Box>
		</PowerUpCard>
	);
};

export const Harness = () => {
	const history = useHistory();

	const handleGenrate = () => {
		history.push(ROUTES_PATH.PITCH_DECK_COMPILER);
	};

	return (
		<PowerUpCard>
			<Box display={"flex"} alignItems="flex-start" gap={1}>
				<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M7.66675 16.3333L8.71269 18.4252C9.06668 19.1332 9.24367 19.4871 9.48012 19.7939C9.68994 20.0661 9.93396 20.3101 10.2062 20.5199C10.5129 20.7564 10.8669 20.9334 11.5749 21.2873L13.6667 22.3333L11.5749 23.3792C10.8669 23.7332 10.5129 23.9102 10.2062 24.1467C9.93396 24.3565 9.68994 24.6005 9.48012 24.8727C9.24367 25.1794 9.06668 25.5334 8.71269 26.2414L7.66675 28.3333L6.6208 26.2414C6.26682 25.5334 6.08983 25.1794 5.85337 24.8727C5.64356 24.6005 5.39954 24.3565 5.12734 24.1467C4.82059 23.9102 4.46661 23.7332 3.75864 23.3792L1.66675 22.3333L3.75864 21.2873C4.46661 20.9334 4.82059 20.7564 5.12734 20.5199C5.39954 20.3101 5.64356 20.0661 5.85337 19.7939C6.08983 19.4871 6.26682 19.1332 6.6208 18.4252L7.66675 16.3333Z"
						stroke="#677085"
						stroke-width="2.66667"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						d="M19.0001 1.66663L20.5716 5.75252C20.9476 6.73016 21.1356 7.21898 21.428 7.63015C21.6871 7.99456 22.0055 8.31295 22.3699 8.57207C22.7811 8.86444 23.2699 9.05245 24.2475 9.42846L28.3334 11L24.2475 12.5715C23.2699 12.9475 22.7811 13.1355 22.3699 13.4278C22.0055 13.687 21.6871 14.0054 21.428 14.3698C21.1356 14.7809 20.9476 15.2698 20.5716 16.2474L19.0001 20.3333L17.4286 16.2474C17.0526 15.2698 16.8646 14.7809 16.5722 14.3698C16.3131 14.0054 15.9947 13.687 15.6303 13.4278C15.2191 13.1355 14.7303 12.9475 13.7526 12.5715L9.66675 11L13.7526 9.42846C14.7303 9.05245 15.2191 8.86444 15.6303 8.57207C15.9947 8.31295 16.3131 7.99456 16.5722 7.63015C16.8646 7.21898 17.0526 6.73016 17.4286 5.75252L19.0001 1.66663Z"
						stroke="#677085"
						stroke-width="2.66667"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
				</svg>
				<Typography variant="Text/md/Semibold" component={"p"}>
					Harness the power of our Pitch Deck Generator!
				</Typography>
			</Box>

			<Box
				display="flex"
				flexDirection="column"
				gap={2}
				sx={{
					p: "24px",
					borderRadius: "20px",
					backgroundColor: "#f2efff",
				}}
			>
				<Typography variant="Text/sm/Regular">
					Leverage our cutting-edge Pitch Deck Generator that compiles insights from your completed PowerUp resources into a compelling investor
					pitch.
				</Typography>
				<Typography variant="Text/sm/Regular">
					Effortlessly transform your knowledge into a professional, visually appealing deck, saving your time and boosting your confidence.
				</Typography>

				<Box>
					<Button variant={"PurpleRounded"} endIcon={<EastIcon />} onClick={handleGenrate}>
						Generate Pitch Deck
					</Button>
				</Box>
			</Box>
		</PowerUpCard>
	);
};
