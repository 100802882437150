import { Avatar, Box, Typography } from "@mui/material";
import React from "react";
import { CSSTransition } from "react-transition-group";

function ListItem({ menuItem, open, active, handleClick }) {
	return (
		<CSSTransition in={open} timeout={500} classNames="open">
			<Box
				onClick={() => handleClick()}
				sx={{
					cursor: "pointer",
					transition: "all 0.5s ease-in-out",
					px: "20px",
					display: "flex",
					alignSelf: "stretch",
					...(!open && {
						"&:not(.open-exit-active)": {
							alignItems: "center",
							margin: "0 auto",
						},
					}),
					...(open && {
						"&:not(.open-enter-active), &:not(.open-enter-done), &:not(.open-exit-done), &:not(.open-exit-active)": {
							pl: "40px",
							alignItems: "flex-start",
							borderLeft: open ? "3px solid" : "none",
							borderRight: open ? "3px solid" : "none",
							borderColor: (theme) => (open && active ? theme.palette.primary.main : "transparent"),
						},
					}),
					"&.open-exit-done": {
						alignItems: "center",
						margin: "0 auto",
					},
					"&.open-exit-active": { alignItems: "flex-start" },
					"&.open-enter-done": {
						pl: "40px",
						alignItems: "flex-start",
						borderLeft: open ? "3px solid" : "none",
						borderRight: open ? "3px solid" : "none",
						borderColor: (theme) => (open && active ? theme.palette.primary.main : "transparent"),
					},
					"&.open-enter-active": {
						pl: "40px",
						alignItems: "flex-start",
						borderLeft: open ? "3px solid" : "none",
						borderRight: open ? "3px solid" : "none",
						borderColor: (theme) => (open && active ? theme.palette.primary.main : "transparent"),
					},
				}}
			>
				<CSSTransition in={open} timeout={500} classNames="open">
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							...(open && {
								"&:not(.open-enter-active), &:not(.open-enter-done), &:not(.open-exit-done), &:not(.open-exit-active)": {
									gap: "20px",
								},
							}),
							"&.open-exit-done": {
								gap: "0px",
							},
							"&.open-exit-active": { gap: "20px" },
							"&.open-enter-done": {
								gap: "20px",
							},
							"&.open-enter-active": {
								gap: "20px",
							},
						}}
					>
						<Avatar
							sx={{
								width: 47,
								height: 47,
								background:(theme) => active ? theme.palette.primary.main : "none",
								opacity: active ? "1" : "0.7",
							}}
						>
							{menuItem.icon}
						</Avatar>
						<CSSTransition in={open} timeout={300} classNames="open">
							<Box
								sx={{
									transition: "opacity 0.5s ease-in-out",
									opacity: open ? 1 : 0,
									"&:not(.open-enter-active), &:not(.open-enter-done), &:not(.open-exit-done), &:not(.open-exit-active)": {
										width: "0px",
									},
									"&.open-enter-done": {
										opacity: 1,
									},

									"&.open-exit-done": {
										opacity: 0,
									},
									"&.open-enter-active": {
										opacity: 1,
									},
								}}
							>
								<Typography
									variant="customValue"
									sx={{
										fontFamily: "PoppinsMedium",
										fontSize: "16px",
										lineHeight: "24px",
										color: "white",
										opacity: active ? 1 : 0.7,
										cursor: "pointer",
									}}
								>
									{menuItem.label}
								</Typography>
							</Box>
						</CSSTransition>
					</Box>
				</CSSTransition>
			</Box>
		</CSSTransition>
	);
}

export default ListItem;
