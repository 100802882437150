import React, { useState, useEffect, useMemo } from "react";
import { Box, Typography, CircularProgress, FormControlLabel, Checkbox, Link } from "@mui/material";
import { useForm } from "react-hook-form";
import LoadingButton from "../../../common/Loadingbutton";
import services from "../../../services";
import { toastContainer } from "../../../utils/toast";
import { Question } from "./";
import { getInitAnswers, getPreQuestionSetId, getFilledAnswers } from "../validation";

export const QuestionsStep = ({ editQuestionSetId, editPreQuestions, handleNextStep }) => {
	const [loading, setLoading] = useState(false);
	const [isReview, setIsReview] = useState(false);
	const [isConfirm, setIsConfirm] = useState(false);
	const [questions, setQuestions] = useState([]);
	const [answers, setAnswers] = useState([]);
	const [preQuestions, setPreQuestions] = useState();
	const [preQuestionSetId, setPreQuestionSetId] = useState("");
	const [questionSetId, setQuestionSetId] = useState("");
	const [applicationId, setApplicationId] = useState("");

	const {
		handleSubmit,
		control,
		formState: { errors, isValid },
	} = useForm({
		mode: "all",
	});

	const onSubmit = data => {};

	useEffect(() => {
		if (editPreQuestions) {
			setPreQuestions(editPreQuestions);
		}
	}, [editPreQuestions]);

	useEffect(() => {
		getApplications();
	}, []);

	useEffect(() => {
		if (editQuestionSetId !== "") {
			setQuestionSetId(editQuestionSetId);
		}
	}, [editQuestionSetId]);

	useEffect(() => {
		if (applicationId !== "") {
			if (isReview) {
				handleNextStep(applicationId);
			} else {
				if (editQuestionSetId !== "") {
					handleBeforeClick(editQuestionSetId);
				} else {
					getMoreQuestions();
				}
			}
		}
	}, [applicationId, editQuestionSetId, isReview]);

	// Format Answers with the required format for API integration
	useEffect(() => {
		if (questions.length > 0) {
			setAnswers(getInitAnswers(questions));
		}
	}, [questions]);

	const handleConfirm = e => {
		setIsConfirm(e.target.checked);
	};

	const getApplications = async () => {
		setLoading(true);
		try {
			const applications = await services.getVouchApplications();
			if (applications?.data?.data.length > 0) {
				setApplicationId(applications?.data?.data[0].application_id);
			} else {
				getNewApplication();
			}
		} catch (error) {
			getNewApplication();
			// toastContainer(error?.data?.message || "Something is wrong for API side", "error");
		}
		setLoading(false);
	};

	const getNewApplication = async () => {
		setLoading(true);
		try {
			const newApplication = await services.getVouchNewApplication();
			setQuestionSetId(newApplication?.data?.data?.id);
			setQuestions(newApplication?.data?.data?.questions);
		} catch (error) {
			toastContainer(error?.data?.message || "Something is wrong for API side", "error");
		}
		setLoading(false);
	};

	const getMoreQuestions = async () => {
		setLoading(true);
		try {
			const moreQuestions = await services.getVouchMoreQuestions(applicationId);
			setPreQuestions(moreQuestions.data.data?.answered_question_sets);
			const preId = getPreQuestionSetId(moreQuestions.data.data?.answered_question_sets);
			setPreQuestionSetId(preId);

			if (moreQuestions.data.data?.has_more_questions) {
				setQuestions(moreQuestions.data.data?.next_question_set?.questions);
				setQuestionSetId(moreQuestions.data.data?.next_question_set?.id);
			} else {
				setIsReview(true);
			}
		} catch (error) {
			toastContainer(error?.data?.message || "Something is wrong for API side", "error");
		}
		setLoading(false);
	};

	const handleNextClick = () => {
		if (applicationId) {
			handleNewQuestion();
		} else {
			createNewApplication();
		}
	};

	const createNewApplication = async () => {
		setLoading(true);
		try {
			const newApplication = await services.createVouchApplication({
				question_set_id: questionSetId,
				questions: answers,
			});

			setApplicationId(newApplication?.data?.data?.id);
		} catch (error) {
			toastContainer(error?.data?.data?.message || "Something is wrong.", "error");
		}
		setLoading(false);
	};

	const handleBeforeClick = async editSetId => {
		try {
			let questionSetId = preQuestionSetId;
			if (editSetId !== "" && editSetId !== undefined) {
				questionSetId = editSetId;
			}
			const beforeQuestionRes = await services.getVouchQuestionSetsById(questionSetId);
			const beforeQuestionData = beforeQuestionRes.data.data;

			const filledAnswers = getFilledAnswers(beforeQuestionData.questions, preQuestions[questionSetId]);
			setQuestions(filledAnswers);

			const preId = getPreQuestionSetId(preQuestions, null, questionSetId);
			setPreQuestionSetId(preId);
			setQuestionSetId(questionSetId);
		} catch (e) {
			toastContainer(e?.data?.data?.message || "Something is wrong.", "error");
		}
	};

	const handleAnswer = (questionId, fieldId, value, type) => {
		const answerIndex = answers.findIndex(answer => answer.id === questionId);
		const fieldIndex = answers[answerIndex].fields.findIndex(field => field.id === fieldId);
		if (type === "Multi-Select") {
			answers[answerIndex].fields[fieldIndex].value = value;
		} else {
			answers[answerIndex].fields[fieldIndex].value[0] = value;
		}

		setAnswers(answers);
	};

	const handleNewQuestion = async () => {
		setLoading(true);
		try {
			const newAnswers = await services.setVouchNewAnswers(applicationId, {
				question_set_id: questionSetId,
				questions: answers,
			});

			setPreQuestions(newAnswers.data.data?.answered_question_sets);
			setPreQuestionSetId(questionSetId);

			if (newAnswers.data.data?.has_more_questions) {
				const checkNextId = getPreQuestionSetId(newAnswers.data.data?.answered_question_sets, null, questionSetId, false);
				if (checkNextId === "") {
					setQuestions(newAnswers.data.data?.next_question_set?.questions);
					setQuestionSetId(newAnswers.data.data?.next_question_set?.id);
				} else {
					setQuestionSetId(checkNextId);
					handleBeforeClick(checkNextId);
				}
			} else {
				if (editQuestionSetId !== "") {
					const nextId = getPreQuestionSetId(newAnswers.data.data?.answered_question_sets, null, questionSetId, false);
					if (nextId !== "") {
						setQuestionSetId(nextId);
						handleBeforeClick(nextId);
					} else {
						setIsReview(true);
					}
				} else {
					setIsReview(true);
				}
			}
		} catch (error) {
			toastContainer(error?.data?.data?.message || "Something is wrong.", "error");
		}
		setLoading(false);
	};

	const isValidForm = useMemo(() => {
		if (applicationId) {
			return !isValid;
		} else {
			if (isConfirm && isValid) {
				return false;
			}
			return true;
		}
	}, [isConfirm, isValid, applicationId]);

	if (questions.length === 0 && loading) {
		return (
			<Box display={"flex"} justifyContent="center" alignItems={"center"} height="600px">
				<CircularProgress />
			</Box>
		);
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			{questions.map(question => (
				<Box key={question.id}>
					<Typography variant="subhead4" sx={{ display: "block", marginBottom: "8px" }}>
						{question.title}
					</Typography>
					{question.description !== "" && (
						<Typography variant="body_small" sx={{ display: "block", marginBottom: "8px" }}>
							{question.description}
						</Typography>
					)}
					{<Question question={question} control={control} errors={errors} handleAnswer={handleAnswer} />}
				</Box>
			))}
			{!applicationId && (
				<FormControlLabel
					control={<Checkbox checked={isConfirm} onChange={handleConfirm} />}
					label={
						<Box>
							<Typography variant="body_small">By checking this box, I affirm that I have read and understand Vouch’s </Typography>
							<Link underline="always" sx={{ cursor: "pointer" }} onClick={() => window.open("https://www.vouch.us/terms", "_blank")}>
								<Typography variant="body_small">Terms of Service </Typography>
							</Link>
							<Typography variant="body_small">and </Typography>
							<Link underline="always" sx={{ cursor: "pointer" }} onClick={() => window.open("https://www.vouch.us/privacy", "_blank")}>
								<Typography variant="body_small">Privacy Policy</Typography>
							</Link>

							<Typography variant="body_small">.</Typography>
						</Box>
					}
				/>
			)}
			{questions.length > 0 && (
				<Box>
					<Box display="flex" mt={5}>
						{preQuestionSetId !== "" && (
							<Box mr={2}>
								<LoadingButton variant="outlined" color="primary" onClick={e => handleBeforeClick()}>
									Previous
								</LoadingButton>
							</Box>
						)}
						<Box>
							<LoadingButton variant="contained" color="primary" disabled={isValidForm} loading={loading} onClick={handleNextClick}>
								{applicationId ? "Next" : "Create Application"}
							</LoadingButton>
						</Box>
					</Box>
					<Box mt={4}>
						<Box mb={0.5}>
							<Typography variant="body_small">Questions about insurance or this question? Contact Vouch at </Typography>
							<Link
								underline="always"
								sx={{ cursor: "pointer" }}
								onClick={() => window.open("mailto:email@example.com?subject=Subject&body=Body%20goes%20here")}
							>
								<Typography variant="body_small">partner.support@vouch.us</Typography>
							</Link>
						</Box>

						<Typography variant="body_small">Insurance services provided by Vouch Insurance Services, LLC (</Typography>
						<Link underline="always" sx={{ cursor: "pointer" }} onClick={() => window.open("http://vouch.us/licenses", "_blank")}>
							<Typography variant="body_small">vouch.us/licenses</Typography>
						</Link>
						<Typography variant="body_small">
							). Descriptions are general in nature only. Please refer to the terms and conditions of any policies offered or purchased. Insurance products
							offered are subject to application and underwriting requirements. Not all products available in all states. Pricing depends on a variety of
							factors including policyholder location. Not all discounts available in all states.
						</Typography>
					</Box>
				</Box>
			)}
		</form>
	);
};
