import React from "react";
import { Box, useTheme, Typography, Divider } from "@mui/material";
import { VisibilityOffOutlined } from "@mui/icons-material";
import { HEART_WHITE_FILLED_ICON, HEART_BLACK_FILLED_ICON } from "../../../constants";
import { USER_TYPES } from "../../../utils/userTypes";

function TabButton({ selected, onTabClick, disabled, children }) {
	const theme = useTheme();
	return (
		<Box
			display="flex"
			justifyContent="center"
			alignItems="center"
			gap={1}
			sx={{
				bgcolor: selected ? theme.palette.secondary.black : theme.palette.sos.gray100,
				color: selected ? theme.palette.secondary.white : theme.palette.sos.gray700,
				padding: "12px 20px",
				boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
				cursor: "pointer",
				borderRadius: "100px",
				"& .MuiTypography-root": {
					color: selected ? theme.palette.secondary.white : theme.palette.sos.gray700,
				},
			}}
			onClick={!disabled ? onTabClick : () => {}}
		>
			{children}
		</Box>
	);
}

function NetworkingTab({ handleTabChange, currentTab }) {
	return (
		<Box display="flex" alignItems="center" columnGap={2.5} rowGap={{ xs: 3, md: 2 }} flexWrap="wrap">
			<TabButton onTabClick={() => handleTabChange(USER_TYPES.MENTOR_USER)} selected={currentTab === USER_TYPES.MENTOR_USER}>
				<Typography variant="Text/sm/Semibold">Mentors</Typography>
			</TabButton>
			<TabButton onTabClick={() => handleTabChange(USER_TYPES.INVESTOR_USER)} selected={currentTab === USER_TYPES.INVESTOR_USER}>
				<Typography variant="Text/sm/Semibold">Investors</Typography>
			</TabButton>
			<TabButton onTabClick={() => handleTabChange(USER_TYPES.CORPORATE_USER)} selected={currentTab === USER_TYPES.CORPORATE_USER}>
				<Typography variant="Text/sm/Semibold">Corporate</Typography>
			</TabButton>
			<TabButton onTabClick={() => handleTabChange(USER_TYPES.STARTUP_OWNER)} selected={currentTab === USER_TYPES.STARTUP_OWNER}>
				<Typography variant="Text/sm/Semibold">Founders</Typography>
			</TabButton>
			<TabButton onTabClick={() => handleTabChange(USER_TYPES.ALUMNI_USER)} selected={currentTab === USER_TYPES.ALUMNI_USER}>
				<Typography variant="Text/sm/Semibold">Alumni</Typography>
			</TabButton>
			<Typography variant="Text/sm/Semibold">|</Typography>
			<TabButton onTabClick={() => handleTabChange("SAVED")} selected={currentTab === "SAVED"}>
				<Box component="img" src={currentTab === "SAVED" ? HEART_WHITE_FILLED_ICON : HEART_BLACK_FILLED_ICON} alt="saved" />
				<Typography variant="Text/sm/Semibold">Saved</Typography>
			</TabButton>
			<TabButton onTabClick={() => handleTabChange("HIDDEN")} selected={currentTab === "HIDDEN"}>
				<VisibilityOffOutlined sx={{ fontSize: "20px" }} />
				<Typography variant="Text/sm/Semibold">Blocked</Typography>
			</TabButton>
		</Box>
	);
}

export default NetworkingTab;
