import React, { useState, useEffect } from "react";
import { Grid, Box, Typography, InputAdornment, TextField } from "@mui/material";
import StartupOsTextField from "../../../../common/StartupOsInput/StartupOsTextField";

export const LifetimeValueCalculator = () => {
	const [avgPerchaseValue, setAvgPerchaseValue] = useState();
	const [numberOfPurchasesPerYear, setNumberOfPurchasesPerYear] = useState();
	const [avgCustomerLifeSpan, setAvgCustomerLifeSpan] = useState();
	const [lifeTimeSpan, setLifeTimeSpan] = useState(0);

	useEffect(() => {
		setLifeTimeSpan(Math.floor(avgPerchaseValue * numberOfPurchasesPerYear * avgCustomerLifeSpan));
	}, [avgPerchaseValue, numberOfPurchasesPerYear, avgCustomerLifeSpan]);

	const handleInput = (e, type) => {
		let value = e.target.value.replace(/[^0-9]/g, "");
		if (type === "avgPerchaseValue") {
			setAvgPerchaseValue(value);
		} else if (type === "numberOfPurchasesPerYear") {
			setNumberOfPurchasesPerYear(value);
		} else if (type === "avgCustomerLifeSpan") {
			setAvgCustomerLifeSpan(value);
		}
	};

	return (
		<Grid container display={"flex"} alignItems="center" sx={{ mt: 5 }}>
			<Grid item xs={12} lg={12}>
				<Typography variant="testHeading">Formula</Typography>
				<Box display={"flex"} alignItems="center" gap={2} sx={{ mt: 3 }}>
					<Box>
						<Typography variant="title_portfolio" component={"p"}>
							Average Purchase Value
						</Typography>
						<Box display={"flex"} alignItems="center" gap={1}>
							<StartupOsTextField
								value={avgPerchaseValue}
								fullWidth
								InputProps={{
									inputMode: "numeric",
									pattern: "[0-9]*",
									onChange: (e) => {
										handleInput(e, "avgPerchaseValue");
									},
									startAdornment: <InputAdornment position="start">$</InputAdornment>,
								}}
								isEndAdornment={false}
								sx={{
									width: "90%",
									color: "black",
									height: "60px",
									mt: 1,
									".MuiOutlinedInput-root": {
										borderRadius: "30px",
										height: "60px",
									},
									".MuiInputBase-input": (theme) => {
										return { ...theme.typography.subhead3, color: theme.palette.secondary.black, pl: 0, pr: 2 };
									},
								}}
							/>
							<Typography sx={{ mt: 1, ml: 1 }} variant="title_portfolio">
								*
							</Typography>
						</Box>
					</Box>
					<Box>
						<Typography variant="title_portfolio" component={"p"}>
							Number of Purchases per Year
						</Typography>
						<Box display={"flex"} alignItems="center" gap={2}>
							{" "}
							<TextField
								InputProps={{
									inputMode: "numeric",
									pattern: "[0-9]*",
									onChange: (e) => {
										handleInput(e, "numberOfPurchasesPerYear");
									},
								}}
								value={numberOfPurchasesPerYear}
								sx={{
									width: "90%",
									mt: 1,
									color: "black",
									height: "60px",
									".MuiOutlinedInput-root": {
										borderRadius: "30px",
										height: "60px",
									},
									".MuiInputBase-input": (theme) => {
										return { ...theme.typography.subhead3, color: theme.palette.secondary.black, px: 2.5 };
									},
								}}
							/>
							<Typography variant="title_portfolio">*</Typography>
						</Box>
					</Box>
				</Box>
			</Grid>
			<Grid item xs={12} lg={12} sx={{ mt: 1 }}>
				<Box display={"flex"} alignItems="center" gap={2}>
					<Box>
						<Typography variant="title_portfolio" component={"p"}>
							Average Customer Lifespan (Yrs)
						</Typography>
						<TextField
							InputProps={{
								inputMode: "numeric",
								pattern: "[0-9]*",
								onChange: (e) => {
									handleInput(e, "avgCustomerLifeSpan");
								},
							}}
							value={avgCustomerLifeSpan}
							sx={{
								width: "90%",
								mt: 1,
								color: "black",
								height: "60px",
								".MuiOutlinedInput-root": {
									borderRadius: "30px",
									height: "60px",
								},
								".MuiInputBase-input": (theme) => {
									return { ...theme.typography.subhead3, color: theme.palette.secondary.black, px: 2.5 };
								},
							}}
						/>
					</Box>
				</Box>
			</Grid>
			<Grid item xs={12} lg={12} sx={{ mt: 3 }}>
				<Typography variant="card_text" component={"p"}>
					Life Time Value
				</Typography>
				<Box display={"flex"} alignItems="center">
					<Typography variant="title_portfolio" component={"p"}>
						=
					</Typography>
					<Typography variant="PoppinsMedium100" component={"p"} noWrap overflow="hidden">
						$
						{!isFinite(lifeTimeSpan)
							? 0
							: Intl.NumberFormat("en-US", {
									notation: "compact",
									maximumFractionDigits: 1,
							  }).format(Number(lifeTimeSpan))}
					</Typography>
				</Box>
			</Grid>
		</Grid>
	);
};
