// A common utility which will convert draftjs to plain text

import { EditorState, ContentState } from "draft-js";
import { stateFromMarkdown } from "draft-js-import-markdown";

export const convertDraftJsToPlainText = inputValue => {
  if (inputValue) {
    const editorState = EditorState.createWithContent(
      stateFromMarkdown(inputValue)
    );

    return editorState.getCurrentContent().getPlainText("\u0001");
  } else {
    return "";
  }
};

export const convertHtmlToPlainText = html => {
  var htmlString = html;
  const plainString = htmlString.replace(/<[^>]+>/g, "");

  return plainString;
};
