import { updateObject } from "../../../../utils/updateObject";
import { ActionType } from "../action-types";

const initialState = {
  createConversationStatus: false,
  newConversationWith: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.CREATING_NEW_CONVERSATION: {
      return updateObject(state, { createConversationStatus: action.payload });
    }
    case ActionType.NEW_CONVERSATION_WITH: {
      return updateObject(state, { newConversationWith: action.payload });
    }
    case ActionType.RESET_CHAT_STATE: {
      return updateObject(state, initialState);
    }
    default:
      return state;
  }
};
export default reducer;
