import React from "react";
import { Stack, Grid, Box } from "@mui/material";
import theme from "../../../../constants/Theme";
import useDetectKeyboardOpen from "../../../../utils/virtualKeyboarDetect";
import { sosPrimary } from "../../../../constants/Theme/colors";

export default function ExcerciseWrapper({
  children,
  NextButton,
  BackButton,
  excerciseConfigs,
  currentExcercise,
  isSummaryExists = true,
  FooterComponent = () => {},
}) {
  const isKeyboardOpen = useDetectKeyboardOpen();
  return (
    <Stack
      sx={theme => ({
        minHeight: "100%",
        borderRadius: theme.shape.standard.borderRadius,
        px: 3,
        [theme.breakpoints.down("md")]: {
          pb: 15,
          p: 0,
          borderRadius: 0,
        },
        // display: { xs: "none", sm: "none", md: "block" },
      })}
    >
      <Grid
        container
        sx={{ maxWidth: theme.breakpoints.values.xl, margin: "auto" }}
        flexGrow={1}
        flexDirection="column"
      >
        {/* {excerciseConfigs && (
          <WorkspaceBreadCrumbs subpath={excerciseConfigs.form_title} />
        )} */}
        <Grid
          container
          sx={{
            minHeight: "100%",
            [theme.breakpoints.down("md")]: {
              pb: 10,
            },
          }}
          flexGrow={1}
        >
          {children}
        </Grid>
        {/*Show button after Excercise loads*/}

        {currentExcercise && (
          <Box
            sx={{
              display: { xs: "block", sm: "block", md: "none" },
              py: 3,
              mt: 5,
              [theme.breakpoints.down("md")]: {
                position: "sticky",
                bottom: 0,
                backgroundColor: theme.palette.text.background,
                zIndex: 1,
                display: isKeyboardOpen ? "none" : "block",
              },
            }}
          >
            {isSummaryExists ? (
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                letterSpacing={2}
              >
                <BackButton />
                <NextButton />
              </Box>
            ) : (
              <FooterComponent />
            )}
          </Box>
        )}
      </Grid>
    </Stack>
  );
}
