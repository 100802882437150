import React, { useState } from "react";
import { Typography, Box, Grid } from "@mui/material";
import TreeItem from "@mui/lab/TreeItem";
import TreeView from "@mui/lab/TreeView";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import BasicCheckBoxTree from "../../../../../common/CheckBoxTree";
import CustomCheckBox from "./CustomCheckbox";
import { useSelector } from "react-redux";
import InfoHelper from "../../../../Founder/InfoHelper";
import { getIndustryDetailsByName } from "../../../../../utils/commonFunctions";

const FilterDropDownComponent = ({ gridData, lable, handleUncheck, disabled = false, type, altLabel }) => {
	const displayData = gridData[lable];

	const filteredValue = useSelector((state) => state?.investorDashboard?.filteredValue) || [];

	const [industryData, setIndustryData] = useState([]);

	return (
		<>
			{disabled ? (
				<Box display="flex" alignItems="center" gap={1} sx={{ ml: "6px", mt: 2 }}>
					<KeyboardArrowRightOutlinedIcon
						sx={{
							fontSize: "large",
							border: "2px solid",
							borderRadius: "50%",
							mt: "5px",
						}}
					/>{" "}
					<Typography sx={{ pt: "4px", display: "inline-block" }} variant="testHeading">
						{`${lable} (coming soon)`}
					</Typography>
				</Box>
			) : (
				<TreeView
					defaultCollapseIcon={
						<KeyboardArrowDownOutlinedIcon
							sx={{
								fontSize: "large",
								border: "2px solid",
								borderRadius: "50%",
							}}
						/>
					}
					defaultExpandIcon={
						<KeyboardArrowRightOutlinedIcon
							sx={{
								fontSize: "large",
								border: "2px solid",
								borderRadius: "50%",
							}}
						/>
					}
					sx={{ mt: 2 }}
					defaultExpanded={[`${lable}`]}
				>
					<TreeItem
						nodeId={`${lable}`}
						label={
							<Typography sx={{ pt: "4px", display: "inline-block" }} variant="testHeading">
								{altLabel || lable}
							</Typography>
						}
						sx={{
							"& .MuiTreeItem-content.Mui-selected": {
								background: (theme) => theme.palette.secondary.white,
								"&:hover": {
									background: (theme) => theme.palette.secondary.white,
								},
								"&:active": {
									background: (theme) => theme.palette.secondary.white,
								},
								"&:focused": {
									background: (theme) => theme.palette.secondary.white,
								},
								"&:selected": {
									background: (theme) => theme.palette.secondary.white,
								},
							},
							"& .MuiTreeItem-content.Mui-selected.Mui-focused": {
								background: (theme) => theme.palette.secondary.white,
								"&:hover": {
									background: (theme) => theme.palette.secondary.white,
								},
								"&:active": {
									background: (theme) => theme.palette.secondary.white,
								},
								"&:focused": {
									background: (theme) => theme.palette.secondary.white,
								},
								"&:selected": {
									background: (theme) => theme.palette.secondary.white,
								},
							},
						}}
					>
						{lable == "Verticals" ? (
							<Grid container>
								{industryData.map((item, index) => {
									return (
										<Grid item xs={6} sm={6} md={6}>
											<BasicCheckBoxTree
												treeIndex={index}
												treeData={item[`${index + 1}`]}
												handleUncheck={handleUncheck}
												lable={lable}
												type={"verticals"}
											/>
										</Grid>
									);
								})}
							</Grid>
						) : (
							<Grid container>
								{displayData.map((item) => {
									return (
										<Grid item xs={6} sm={6} md={6}>
											<Box>
												<Box sx={{ display: "grid", ml: 2 }}>
													<Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
														<CustomCheckBox
															value={item}
															handleUncheck={handleUncheck}
															type={type}
															checkedValue={filteredValue.some((filterItem) => filterItem?.checkboxValue === item)}
														/>
														<Typography variant="title_medium" sx={{ mx: 1 }}>
															{item}
														</Typography>
														{type === "teamMember" && <InfoHelper title={item} body={getIndustryDetailsByName(item)} />}
													</Box>
												</Box>
											</Box>
										</Grid>
									);
								})}
							</Grid>
						)}
					</TreeItem>
				</TreeView>
			)}
		</>
	);
};

export default FilterDropDownComponent;
