import React from "react";
import {
  Grid,
  Box,
  Typography,
  Button,
  TextField,
  Divider,
  CircularProgress,
} from "@mui/material";
import theme from "../../../constants/Theme";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import FileDownloadDoneOutlinedIcon from "@mui/icons-material/FileDownloadDoneOutlined";
import CloseIcon from "@mui/icons-material/Close";
import LoadingButton from "../../../common/Loadingbutton";
import { height } from "@mui/system";

function EditProfile({
  handleBlur,
  candidateError,
  saveDisable,
  handleCloseForm,
  handleUpdateUser,
  handleChange,
  compLocation,
  handleClear,
  payload,
  saveLoading,
  handleUploadAction,
  setCandidateError,
  imgLoading,
  browseFlag,
}) {
  let FileType = {
    img: ".png, .jpg, .jpeg",
  };

  const handleFileChange = type => event => {
    const file = event.target.files[0];

    if (file === undefined) {
      handleUploadAction("", "");
      if (type == "img") {
        setCandidateError(prevState => ({
          ...prevState,
          logoError: "Please attach a logo",
        }));
      }
    } else {
      handleUploadAction(file, type);
    }
  };

  const uploadButton = (type, handler) => {
    return (
      <Button
        variant="outlined"
        startIcon={
          !imgLoading &&
          (browseFlag ? (
            <FileDownloadDoneOutlinedIcon />
          ) : (
            <FileUploadOutlinedIcon />
          ))
        }
        sx={{
          width: "max-content",
          mb: 2,
        }}
        component="label"
      >
        {imgLoading ? (
          <CircularProgress
            sx={{
              display: "flex",
              justifyContent: "center",
              maxWidth: "30px",
              px: 3,
            }}
          />
        ) : browseFlag ? (
          "Uploaded"
        ) : (
          "Upload"
        )}
        <input
          type="file"
          accept={FileType[type]}
          hidden
          multiple={false}
          onChange={handleFileChange(type)}
        />
      </Button>
    );
  };

  return (
    <>
      <Box
        sx={{
          pt: 2,
          display: "flex",
          height: "100%",
          flexDirection: { xs: "column" },
        }}
      >
        <Grid container spacing={2} sx={{ px: { xs: 4, md: 0 } }}>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
              justifyContent: "space-between!important",
            }}
          >
            <Typography variant="headline3">Edit Profile</Typography>
          </Grid>

          <Grid
            item
            xs={6}
            md={6}
            sx={{ display: { xs: "none", md: "block" } }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column-reverse", md: "row" },
                gap: 2,
                float: "right",
                alignItems: "center",
              }}
            >
              <LoadingButton
                variant="contained"
                sx={{ width: 1 }}
                disabled={saveDisable}
                onClick={handleUpdateUser}
                loading={saveLoading}
                loadingStyles={{ px: 7 }}
              >
                Save Changes
              </LoadingButton>
              <CloseIcon
                onClick={handleCloseForm}
                sx={{ fill: theme.palette.secondary.main, cursor: "pointer" }}
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={12} sx={{ mt: 2 }}>
            <Typography variant="title_medium">Profile Image</Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            {uploadButton("img")}
          </Grid>

          {/* Left */}
          <Grid
            item
            xs={12}
            md={6}
            sx={{ display: "flex", flexDirection: "row" }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <TextField
                  label="First Name*"
                  spellCheck="true"
                  fullWidth
                  onBlur={(e, type) => {
                    handleBlur(e, "firstName");
                  }}
                  onChange={(e, type) => {
                    handleChange(e, "firstName");
                  }}
                  error={!!candidateError.firstNameError}
                  helperText={candidateError.firstNameError}
                  value={payload[`firstName`]}
                  inputProps={{ maxLength: "25", minLength: "2" }}
                />
                {candidateError.firstNameError == "" && (
                  <Typography variant="required_text" sx={{ pl: 2 }}>
                    *required
                  </Typography>
                )}
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  label="Last Name*"
                  spellCheck="true"
                  fullWidth
                  onBlur={(e, type) => {
                    handleBlur(e, "lastName");
                  }}
                  onChange={(e, type) => {
                    handleChange(e, "lastName");
                  }}
                  error={!!candidateError.lastNameError}
                  helperText={candidateError.lastNameError}
                  value={payload[`lastName`]}
                  inputProps={{ maxLength: "25", minLength: "2" }}
                />
                {candidateError.lastNameError == "" && (
                  <Typography variant="required_text" sx={{ pl: 2 }}>
                    *required
                  </Typography>
                )}
              </Grid>

              <Grid
                item
                xs={12}
                md={12}
                sx={{
                  mt: { md: 2 },
                }}
              >
                <TextField
                  label="Profession"
                  spellCheck="true"
                  fullWidth
                  onBlur={(e, type) => {
                    handleBlur(e, "profession");
                  }}
                  onChange={(e, type) => {
                    handleChange(e, "profession");
                  }}
                  error={!!candidateError.professionError}
                  helperText={candidateError.professionError}
                  value={payload[`profession`]}
                />
              </Grid>

              <Grid
                item
                xs={12}
                md={12}
                sx={{
                  mt: { md: 2 },
                }}
              >
                <TextField
                  label="Zip Code"
                  fullWidth
                  spellCheck="true"
                  value={compLocation}
                  onChange={(e, type) => {
                    handleChange(e, "zipcode");
                  }}
                  onBlur={(e, type) => {
                    handleBlur(e, "zipcode");
                  }}
                  onClick={handleClear}
                  error={!!candidateError.zipCodeError}
                  helperText={candidateError.zipCodeError}
                  inputProps={{ maxLength: "5", minLength: "5" }}
                />
              </Grid>
            </Grid>
            <Divider sx={{ py: 1 }} />
          </Grid>
        </Grid>

        {/* Mob: form actions */}
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            display: { xs: "block", md: "none" },
            mt: 2,
          }}
        >
          <Divider sx={{ mx: 2 }} />

          <Box
            sx={{
              display: "flex",
              width: 1,
              position: "fixed",
              bottom: "0%",
              background: theme.palette.secondary.greyBg,
              flexDirection: "column",
            }}
          >
            <Divider />
            <Grid container spacing={2} sx={{ p: 2 }}>
              <Grid
                item
                xs={6}
                md={6}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Button
                  variant="contained"
                  sx={{ width: 1 }}
                  onClick={handleCloseForm}
                >
                  Discard
                </Button>
              </Grid>
              <Grid
                item
                xs={6}
                md={6}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Button
                  sx={{ width: 1 }}
                  disabled={saveDisable}
                  onClick={handleUpdateUser}
                >
                  {saveLoading ? (
                    <CircularProgress
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        color: theme.palette.secondary.white,
                      }}
                    />
                  ) : (
                    "Save Changes"
                  )}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Box>
    </>
  );
}

export default EditProfile;
