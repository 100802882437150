import { Typography, Box, Divider, Button, MenuItem } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import _debounce from "lodash/debounce";
import { CSVLink } from "react-csv";
import FileDownloadRoundedIcon from "@mui/icons-material/FileDownloadRounded";
import LoopIcon from "@mui/icons-material/Loop";
import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import theme from "../../../../../../constants/Theme";
import constants from "../../../../../../constants";
import { connect } from "react-redux";
import * as actions from "../../../../../../modules/actions";
import SelfServiceSubscriptionsTable from "./Components/SelfServiceSubscriptionsTable";
import { getFormattedDate } from "../../../../../../utils/date";
import numeral from "numeral";
import services from "../../../../../../services";
import MessageHandlerModal from "../../../../../../common/modal/MessageHandler";
import MuiTooltip from "../../../../../../common/MuiTooltip";
import TemporaryDrawer from "../../../../../../common/Drawer";
import {
  archiveApiCall,
  createNewVersionApiCall,
} from "../../../../../../utils/api";
import ROUTES_PATH from "../../../../../../constants/routes";

function RevenueModelerSummary({
  moduleId,
  handleBackClick,
  setExcerciseConfigs,
  versionId,
}) {
  const tabitems = constants.SUMMARY_TAB_ITEMS;
  const history = useHistory();
  const [currenttab, setcurrenttab] = useState(tabitems[0].key);

  const [currentModule, setCurrentModule] = useState(null);

  const [subscription, setSubscription] = useState({
    arpu: 0,
    primarilyBill: "yearly",
    expectedLaunchDate: new Date(),
    churnRate: 2,
    expectedLifetimeValue: 0,
    businessType: "b2b",
  });
  const [archiveConfirmDialog, setArchiveConfirmDialog] = useState(false);
  const [archiveDrawerOpen, setArchiveDrawerOpen] = useState(false);

  const getModuleStepResponsesApiCall = () => {
    services
      .getModuleStepResponses(moduleId)
      .then(result => {
        const moduleStepsResponses = result.data.data;

        const currentModuleResponse =
          moduleStepsResponses[moduleStepsResponses?.length - 1];

        const lastStep = currentModuleResponse.moduleStepResponses.find(
          step => step.moduleStep.stepNo === 2
        );

        setCurrentModule({
          ...lastStep,
          answers: JSON.parse(lastStep.answers),
          responseId: currentModuleResponse.id,
        });

        const currentAnswer = JSON.parse(lastStep.answers)?.summaryObj;

        setSubscription({
          ...subscription,
          arpu: currentAnswer.subscriptionPrice,
          primarilyBill: currentAnswer.billingCadence,
          expectedLaunchDate: currentAnswer.launchDate || new Date(),
          churnRate:
            currentAnswer.businessType?.toLowerCase() === "b2b" ? 2 : 7,
          businessType: currentAnswer.businessType?.toLowerCase(),
        });
      })
      .catch(err => console.log(err));
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    setcurrenttab(tabitems[0].key);
    getModuleStepResponsesApiCall();
  }, []);

  const handleSave = () => {
    const answer = {
      ...currentModule.answers,
      summaryObj: {
        ...currentModule.answers.stepObj,
        subscriptionPrice: subscription.arpu,
        billingCadence: subscription.primarilyBill,
        launchDate: subscription.expectedLaunchDate,
      },
    };
    const data = {
      moduleResponseId: currentModule.responseId,
      stepNo: 2,
      answers: JSON.stringify(answer),
      isLastStep: true,
      isCompleted: true,
      moduleStepId: currentModule.moduleStep?.id,
    };
    updateModuleStepResponseApiCall(data);
  };

  useEffect(() => {
    let expectedLifetimeValue = 0;
    let formattedArpuValue = parseInt(numeral(subscription.arpu).value());

    if (formattedArpuValue > 0) {
      let avgCustomerLifetimeMonths = 1 / (subscription.churnRate / 100);
      if (subscription.primarilyBill === "Monthly") {
        let lifetimeNumBillings = avgCustomerLifetimeMonths / 1 + 1;
        expectedLifetimeValue =
          formattedArpuValue * 1 * Math.floor(lifetimeNumBillings);
      } else if (subscription.primarilyBill === "Quarterly") {
        let lifetimeNumBillings = avgCustomerLifetimeMonths / 3 + 1;
        expectedLifetimeValue =
          formattedArpuValue * 3 * Math.floor(lifetimeNumBillings);
      } else if (subscription.primarilyBill === "Yearly") {
        let lifetimeNumBillings = avgCustomerLifetimeMonths / 12 + 1;
        expectedLifetimeValue =
          formattedArpuValue * 12 * Math.floor(lifetimeNumBillings);
      }

      if (currentModule) {
        const debouncedSave = _debounce(() => handleSave(), 2000);
        debouncedSave();
      }
    }

    setSubscription({
      ...subscription,
      expectedLifetimeValue: expectedLifetimeValue,
    });
  }, [subscription.arpu, subscription.primarilyBill]);

  const getNotesChurnRate = () => {
    if (subscription.businessType === "b2b") {
      return "Most B2B companies aim for 0-2% monthly churn. We’ve assigned you the average, 2%.";
    } else if (subscription.businessType === "b2c") {
      return "Most B2C companies aim for 6-8% monthly churn. We’ve assigned you the average, 7%.";
    }
  };

  const tableHeaders = [
    { label: "Business Model", key: "businessModel" },
    { label: "Output", key: "output" },
    { label: "Notes", key: "notes" },
  ];
  const tableData = [
    {
      businessModel: "Average Monthly Subscription Price (ARPU)",
      output: "$" + subscription.arpu,
      notes:
        "The average revenue per user, per month. If you have multiple plans, provide an average of the plan price.",
    },

    {
      businessModel: "How you will primarily bill your customers",
      output: subscription?.primarilyBill?.toUpperCase(),
      notes: "",
    },
    {
      businessModel: "Expected Launch",
      output: getFormattedDate(subscription.expectedLaunchDate, "MM/DD/YYYY"),
      notes: "",
    },
    {
      businessModel: "Expected Yearly Churn Rate",
      output: subscription.churnRate + "%",
      notes: getNotesChurnRate(),
    },
    {
      businessModel: "Expected Lifetime Value",
      output: "$" + subscription.expectedLifetimeValue,
      notes: "ARPU divided by (Expected Yearly Churn Rate divided by 12)",
    },
  ];
  const archiveCancelDialogHandler = () => {
    setArchiveConfirmDialog(false);
  };

  const archiveSuccessDialogHandler = () => {
    setArchiveConfirmDialog(false);
    let payload = {
      responseId: versionId,
      status: true,
    };
    archiveApiCall(payload, history);
  };
  const updateModuleStepResponseApiCall = data => {
    services
      .storeModuleStepResponses(moduleId, data)
      .then(result => {})
      .catch(error => console.log(error));
  };
  const createNewVersion = (moduleId, uuid, history) => {
    setExcerciseConfigs(null);

    createNewVersionApiCall(moduleId, uuid, history);
  };
  return (
    <>
      <Box flexGrow={1} flexDirection={"column"} sx={{ maxWidth: "100%" }}>
        <Box
          sx={{
            mt: 3,
          }}
        >
          <Typography variant="largeTitle">Summary</Typography>
        </Box>

        <Box display={"grid"} sx={{ mt: 1 }}>
          <Typography variant="subhead1">
            Congratulations! Below is a summary of the work you’ve completed in
            this PowerUp. To edit or review your completed steps, select the
            options above to navigate back. You can take this PowerUp as many
            times as you’d like. Don’t forget to download your results and get
            feedback on your completed work.
          </Typography>
        </Box>

        {/*Main Container*/}
        <Box
          sx={theme => ({
            [theme.breakpoints.up("md")]: {
              background: "rgba(106, 117, 117, 0.08)",
              borderRadius: theme.shape.standard5.borderRadius,
              p: 4,
              mt: 3,
            },
          })}
        >
          <Box display="flex" alignItems={"center"}>
            <Box sx={{ ml: "auto" }}>
              <CSVLink
                data={tableData}
                headers={tableHeaders}
                className="disable-hyperlink"
                filename="Self-Service Subscriptions.csv"
              >
                <MuiTooltip title="Download">
                  <FileDownloadRoundedIcon
                    sx={{
                      color: "secondary.main",
                      p: 1,
                      "&:hover": {
                        background: "rgba(73, 69, 79, 0.08)",
                        borderRadius: "50%",
                      },
                    }}
                  />
                </MuiTooltip>
              </CSVLink>
            </Box>
          </Box>
          <Box
            sx={{
              mt: 4,
            }}
          >
            <SelfServiceSubscriptionsTable
              subscription={subscription}
              setSubscription={setSubscription}
              getNotesChurnRate={getNotesChurnRate}
            />
          </Box>
        </Box>

        <Divider
          sx={{
            background: theme => theme.palette.secondary.dark,
            mt: 6,
            [theme.breakpoints.down("md")]: {
              background: theme => theme.palette.secondary.borderLight,
            },
            display: { sm: "none", xs: "none", md: "flex" },
          }}
        />
        <Box
          sx={{
            mt: 3,
            display: { sm: "none", xs: "none", md: "flex" },
          }}
        >
          <MuiTooltip title="Archive">
            <ArchiveOutlinedIcon
              sx={{
                cursor: "pointer",
                color: "secondary.main",
                p: 1,
                "&:hover": {
                  background: "rgba(73, 69, 79, 0.08)",
                  borderRadius: "50%",
                },
              }}
              onClick={() => {
                setArchiveConfirmDialog(true);
              }}
            />
          </MuiTooltip>

          <MuiTooltip title="Take again">
            <LoopIcon
              sx={{
                cursor: "pointer",
                ml: 1,
                color: "secondary.main",
                p: 1,
                "&:hover": {
                  background: "rgba(73, 69, 79, 0.08)",
                  borderRadius: "50%",
                },
              }}
              onClick={() => {
                createNewVersion(moduleId, "md-revenue", history);
              }}
            />
          </MuiTooltip>
          <Box sx={{ ml: "auto" }}>
            <Button
              variant="outlined"
              onClick={() => {
                handleBackClick();
              }}
            >
              Back
            </Button>
          </Box>
          <Box sx={{ ml: 1 }}>
            <Button
              onClick={() => {
                history.push(ROUTES_PATH.STARTUPS_HOME);
              }}
            >
              Done
            </Button>
          </Box>
        </Box>
        {/* This section needs to revisited once feedback functionality implemented. */}
        <Box
          sx={theme => ({
            display: { sm: "none", xs: "none", md: "none" },
            background: theme.palette.secondary.white,
            borderRadius: theme.shape.standard.borderRadius,
            p: 3,
            mt: 3,
          })}
        >
          <Typography variant="title_medium">Want feedback?</Typography>
          <Box sx={{ mt: 3 }}>
            <Typography variant="subhead2">
              Your StartupOS Advisor is here to help if you would like feedback
              for this completed PowerUp.
            </Typography>
          </Box>
          <Box sx={{ textAlign: "center", mt: 4 }}>
            <Button>Submit for feedback</Button>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          background: theme.palette.secondary.white,
          position: "fixed",
          bottom: 72,
          width: 1,
          textAlign: "center",
          alignItems: "center",
          py: 2,
          left: 0,
          display: { sm: "flex", xs: "flex", md: "none" },
        }}
      >
        <MoreHorizIcon
          sx={{
            borderRadius: "50%",
            border: `1px solid ${theme.palette.secondary.dark}`,
            mr: 1,
            ml: 2,
            p: 1,
          }}
          onClick={() => {
            setArchiveDrawerOpen(true);
          }}
        />
        <Button
          variant="outlined"
          onClick={() => {
            handleBackClick();
          }}
        >
          Back
        </Button>
        <Button
          sx={{ ml: 1, mr: 2 }}
          onClick={() => {
            history.push(ROUTES_PATH.STARTUPS_HOME);
          }}
        >
          Done
        </Button>
      </Box>
      <TemporaryDrawer
        open={archiveDrawerOpen}
        onClose={() => {
          setArchiveDrawerOpen(false);
        }}
      >
        <Box sx={{ py: 3 }}>
          <MenuItem
            onClick={() => {
              setArchiveDrawerOpen(false);
              setArchiveConfirmDialog(true);
            }}
          >
            <ArchiveOutlinedIcon
              sx={{
                color: "secondary.main",
                p: 1,
              }}
            />
            <Typography variant="label_xlarge" sx={{ ml: 2 }}>
              Archive
            </Typography>
          </MenuItem>
          <MenuItem
            onClick={() => {
              createNewVersion(moduleId, "md-revenue", history);
            }}
          >
            <LoopIcon
              sx={{
                color: "secondary.main",
                p: 1,
              }}
            />
            <Typography variant="label_xlarge" sx={{ ml: 2 }}>
              Take again
            </Typography>
          </MenuItem>
        </Box>
      </TemporaryDrawer>
      <MessageHandlerModal
        isOpen={archiveConfirmDialog}
        heading="Archive"
        text={"Are you sure you want to archive this?"}
        messageType={"primary"}
        cancelText={"No thanks"}
        okText={"Archive"}
        style={{
          maxWidth: "450px",
        }}
        onOk={archiveSuccessDialogHandler}
        onCancel={archiveCancelDialogHandler}
      />
    </>
  );
}

const mapStoreToProps = ({ powerUp }) => ({
  powerUpExcercises: powerUp?.powerUpExcercises,
});

const mapDispatchToProps = dispatch => ({
  setPowerUpExcercises: updatedSteps =>
    dispatch(actions.setPowerUpExcercises(updatedSteps)),
});

export default connect(
  mapStoreToProps,
  mapDispatchToProps
)(RevenueModelerSummary);
