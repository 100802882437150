import React, { useEffect } from "react";
import { Box, Grid, Button, Typography, Divider } from "@mui/material";
import theme from "../../../../constants/Theme";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

export default function CandidateAbout({ details }) {
  return (
    <>
      <Grid container sx={{ mt: 2 }}>
        <Grid
          item
          xs={12}
          md={8}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            pr: { xs: 1, md: 6 },
          }}
        >
          <Typography variant="title_medium">About</Typography>

          <Typography variant="bodyLargeBold" sx={{ whiteSpace: "pre-wrap" }}>
            {details?.info?.about?.split("<br/>").map(str => (
              <p>{str}</p>
            ))}
          </Typography>
        </Grid>

        <Grid item xs={12} md={1} sx={{ display: "flex" }}>
          <Divider
            orientation="vertical"
            flexItem
            sx={{ display: { xs: "none", md: "block" } }}
          />
        </Grid>

        <Grid
          item
          xs={12}
          md={3}
          sx={{ display: "flex", flexDirection: "column", gap: 2 }}
        >
          <Divider sx={{ display: { xs: "block", md: "none" }, py: 2 }} />

          <Typography variant="title_medium">Experience</Typography>

          {details?.info?.experienceDetails?.map(function (item, index) {
            return (
              <>
                <Typography variant="lable_large">{item?.title}</Typography>
                {/* Web */}
                <Box
                  sx={{
                    width: 1,
                    display: { xs: "none", md: "flex" },
                    flexDirection: "column",
                    gap: 1,
                    textAlign: "baseline",
                  }}
                >
                  <Typography
                    variant="label_medium"
                    sx={{
                      display: "flex",
                      gap: 1,
                      width: "max-content",
                      flexDirection: "column",
                    }}
                  >
                    {item?.companyName}
                    {item?.location && (
                      <FiberManualRecordIcon
                        fontSize="small"
                        sx={{
                          maxHeight: "8px",
                          fill: theme.palette.secondary.dark,
                          alignSelf: "center",
                        }}
                      />
                    )}
                    {item?.location}
                  </Typography>
                </Box>

                {item?.startDate ||
                  (item?.endDate && (
                    <Typography
                      variant="label_medium"
                      sx={{
                        display: { xs: "none", md: "flex" },
                        flexDirection: "column",
                      }}
                    >
                      {item?.startDate} - {item?.endDate}
                    </Typography>
                  ))}

                {/* Mob */}
                <Box
                  sx={{
                    display: { xs: "flex", md: "none" },
                    flexDirection: "row",
                    gap: 1,
                  }}
                >
                  <Typography variant="label_medium">
                    {item?.companyName}
                  </Typography>
                  {item?.location && (
                    <FiberManualRecordIcon
                      fontSize="small"
                      sx={{
                        maxHeight: "8px",
                        fill: theme.palette.secondary.dark,
                        alignSelf: "center",
                      }}
                    />
                  )}
                  <Typography variant="label_medium">
                    {item?.location}
                  </Typography>
                </Box>

                <Typography
                  variant="label_medium"
                  sx={{ display: { xs: "flex", md: "none" } }}
                >
                  {item?.startDate} - {item?.endDate}
                </Typography>
              </>
            );
          })}

          <Divider sx={{ p: 1 }} />

          <Typography variant="title_medium">Education</Typography>

          {details?.info?.educationDetails?.map(function (item, index) {
            return (
              <>
                <Typography variant="lable_large">{item?.course}</Typography>
                {/* Web */}
                <Box
                  sx={{
                    display: { xs: "none", md: "flex" },
                    flexDirection: "column",
                    textAlign: "baseline",
                    gap: 1,
                  }}
                >
                  <Typography
                    variant="label_medium"
                    sx={{
                      display: "flex",
                      gap: 1,
                      flexDirection: "column",
                    }}
                  >
                    {item?.university}

                    {item?.location && (
                      <FiberManualRecordIcon
                        fontSize="small"
                        sx={{
                          maxHeight: "8px",
                          fill: theme.palette.secondary.dark,
                          alignSelf: "center",
                        }}
                      />
                    )}

                    {item?.location}
                  </Typography>
                </Box>

                {/* Mob */}
                <Box
                  sx={{
                    display: { xs: "flex", md: "none" },
                    flexDirection: "row",
                    textAlign: "baseline",
                    gap: 1,
                  }}
                >
                  <Typography
                    variant="label_medium"
                    sx={{
                      display: "flex",
                      gap: 1,
                      flexDirection: "column",
                    }}
                  >
                    {item?.university}
                  </Typography>

                  {item?.location && (
                    <FiberManualRecordIcon
                      fontSize="small"
                      sx={{
                        maxHeight: "8px",
                        fill: theme.palette.secondary.dark,
                        alignSelf: "center",
                      }}
                    />
                  )}

                  <Typography variant="label_medium">
                    {item?.location}
                  </Typography>
                </Box>

                <Typography variant="label_medium">{item?.year}</Typography>
              </>
            );
          })}
        </Grid>
      </Grid>
    </>
  );
}
