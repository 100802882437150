import React from "react";
import { useEffect, useState } from "react";
import services from "../../../services";
import { useHistory, useParams, useLocation } from "react-router-dom";
import theme from "../../../constants/Theme";
import { Box, Grid, CircularProgress } from "@mui/material";
import AdBanner from "../../../components/AdBanner";
import PowerPackHero from "../../../components/PowerPackHero";
import PowerUpCard from "../../../components/PowerUpCard";
import PlugInCard from "../../../components/PlugInCard";
import PowerPackSelectedTitles from "./PowerPackSelectedTitles";
import constants from "../../../constants";
import { GUSTO_LOGO } from "../../../constants/image";

export default function PowerPackSelectedView({}) {
  const params = useParams();

  // Viewstate
  const [powerPackData, setPowerPackData] = React.useState({
    powerPack: {},
    tasks: [],
  });
  const [workspaceTitles, setWorkspaceTitles] = React.useState({
    headline: "",
    subhead:
      "Learn-by-doing exercises designed to build confidence, competence, and outcomes that create forward momentum.",
  });

  const [advertsDetails, setAdvertsDetails] = useState();
  const [loading, setLoading] = useState(true);

  const overridePowerPack = powerPack => {
    // Moved this override to plugin card

    // powerPack.manifest.tasks.map(task => {
    //   if (task.taskName === "PLUGIN") {
    //     if (task.plugin.pluginName === "Discovery Interview" || task.plugin.pluginName === "Idea Validation Interview" || task.plugin.pluginName === "Persona Interview") {
    //       task.plugin.route = "/workspace/market-test";
    //     }
    //   }
    // });

    return powerPack;
  };

  const overrideTasks = tasks => {
    // Temporarily filtering Startup Profile
    return tasks.filter(function (task) {
      if (task.powerUp) {
        return task.powerUp.powerUpName !== "Startup Profile";
      } else {
        return true;
      }
    });
  };

  // Get PowerUp
  const getPowerPack = async () => {
    try {
      const powerPack = await services.getPoweupPacksDetails(params.id);
      let powerPackOverride = overridePowerPack(powerPack.data.data);

      setPowerPackData(prevState => ({
        powerPack: powerPackOverride,
        tasks: overrideTasks(powerPackOverride.manifest.tasks),
      }));
      setWorkspaceTitles(prevState => ({
        ...workspaceTitles,
        headline: powerPackOverride.powerUpPackName,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const getAdvertsById = id => {
    services
      .getAdvertsById(id)
      .then(res => {
        setLoading(false);
        const resData = res.data.data;
        if (res.data.code === 200) {
          setAdvertsDetails(resData[0]);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    getPowerPack();
    getAdvertsById(6);
  }, []);

  return (
    <Box
      sx={{
        maxWidth: theme.breakpoints.values.xl,
        margin: "auto",
        px: 3,
      }}
    >
      {loading ? (
        <Box
          sx={{
            display: "flex",
            width: 1,
            justifyContent: "center",
            py: 4,
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box
          sx={{
            margin: "0 0 30px 0",
          }}
        >
          <AdBanner
            title={advertsDetails?.adName}
            subtitle={advertsDetails?.adCopy}
            image={advertsDetails?.adUrl}
            backgroundSize="cover"
            action={{
              text: "View Services",
              props: {
                href: advertsDetails?.serviceUrl,
                target: "_blank",
              },
            }}
          />
        </Box>
      )}

      <Box
        sx={{
          margin: "0px 0px 30px 0px",
        }}
      >
        <PowerPackHero
          powerPackData={powerPackData.powerPack}
          cardType="marketTest"
        />
      </Box>

      <PowerPackSelectedTitles workspaceTitles={workspaceTitles} />

      <Grid container spacing={{ xs: 2, md: 3 }} justifyContent="center">
        {powerPackData.tasks.map((item, index) => {
          if (item.taskName === "POWER_UP") {
            return (
              <Grid item key={index}>
                <PowerUpCard powerUpData={item.powerUp} />
              </Grid>
            );
          }
          if (item.taskName === "PLUGIN") {
            return (
              <Grid item key={index}>
                <PlugInCard plugInData={item.plugin} />
              </Grid>
            );
          }
          return <></>;
        })}
      </Grid>
    </Box>
  );
}
