import React, { useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import SortIcon from "@mui/icons-material/Sort";
import { Box, Typography } from "@mui/material";
import UpdatedSelectInput from "../../../../common/UpdatedSelectInput";
import { INVESTOR_DASHBOARD_SORT_LIST } from "../../../../constants";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { useDispatch } from "react-redux";
import { setInvestorDashboardData } from "../../../../modules/actions";
import { handleMixPanel } from "../../../../utils/mixPanelEventHandling";

const ITEM_HEIGHT = 30;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 7 + ITEM_PADDING_TOP,
			borderRadius: "0px 0px 40px 40px",
			boxShadow: "0px 5px 24px rgba(0, 0, 0, 0.15)",
		},
	},
};

export default function SortComponent({
	columnFilterValue,
	setColumnFilterValue,
	setCompaniesPageIndex,
	isFilterApplied,
	searchText,
	getCompanyListApiCall,
	companyFilterObj,
	defaultSearchStr = "Score",
}) {
	const [selectedSort, setSelectedSort] = useState(defaultSearchStr);
	const [menuOpen, setMenuOpen] = useState(false);
	const dispatch = useDispatch();

	const handleChange = (event) => {
		const {
			target: { value },
		} = event;

		setSelectedSort(value);
	};

	const sortOptions = INVESTOR_DASHBOARD_SORT_LIST;
	const handleMenuOpen = (value) => {
		setMenuOpen(value);
		handleMixPanel("Opportunity Dashboard: Startup Score Clicked");
	};

	const handleColumnFilter = (sort, filter) => {
		const tempColumnFilterValue = [...columnFilterValue];
		tempColumnFilterValue.map((filterItem) => {
			if (filterItem.filter === filter) {
				filterItem.sort = sort;
				filterItem.active = true;
			} else {
				filterItem.sort = "";
				filterItem.active = false;
			}
		});
		dispatch(
			setInvestorDashboardData({
				sortFilteredValues: tempColumnFilterValue,
				sortFilter: sortOptions.filter((item) => item.key === filter)[0]?.value,
			})
		);
		setColumnFilterValue(tempColumnFilterValue);
		setCompaniesPageIndex(1);
		if (isFilterApplied) {
			getCompanyListApiCall(searchText, 1, sort, filter, companyFilterObj);
		} else {
			getCompanyListApiCall(searchText, 1, sort, filter, "");
		}
	};

	return (
		<UpdatedSelectInput
			onInputChange={handleChange}
			inputKey="sort"
			MenuProps={MenuProps}
			selectedValue={selectedSort}
			handleMenuOpen={handleMenuOpen}
			handleMenuClose={handleMenuOpen}
			wrapperstyle={{
				"& .MuiInputBase-root": {
					height: "auto",
					boxShadow: "0px 3px 11px rgba(0, 0, 0, 0.05)",
					borderRadius: menuOpen ? "40px 40px 0px 0px" : "40px",
					fontSize: "16px",
					fontFamily: '"PoppinsSemiBold", "Helvetica", "Arial", sans-serif',
					background: (theme) => theme.palette.secondary.white,
					width: "200px",
				},
				"& .MuiOutlinedInput-notchedOutline": {
					border: "none",
				},
				"& .MuiOutlinedInput-input": { pl: 3 },
				width: 1,
			}}
			renderValue={(selected) => {
				if (!selected) {
					return (
						<Box sx={{ my: 0, py: 0 }} display={"flex"}>
							<SortIcon sx={{ mr: 1 }} fontSize="small" />
							Sort
						</Box>
					);
				}

				return (
					<Box sx={{ display: "flex" }} alignItems="center">
						<SortIcon sx={{ mr: 1.5 }} fontSize="small" />{" "}
						<Typography
							variant="poppinsMedium16"
							sx={{
								lineHeight: "19px",
								textOverflow: "ellipsis",
								whiteSpace: "nowrap",
								overflow: "hidden",
							}}
							color="secondary.black"
						>
							{selected}
						</Typography>{" "}
					</Box>
				);
			}}
			inputProps={{ "aria-label": "Without label" }}
			InputProps={{}}
		>
			{sortOptions.map((option) => (
				<MenuItem
					display={"flex"}
					alignItems="center"
					key={option.key}
					value={option.value}
					sx={{
						py: 1,
						px: 2,
						"&.Mui-selected": {
							backgroundColor: "rgba(106, 117, 117, 0.08)",
						},
					}}
					onClick={() => {
						handleColumnFilter(
							columnFilterValue.filter((columnItem) => columnItem.filter === option.key)[0]?.sort === "DESC" ? "ASC" : "DESC",
							option.key
						);
					}}
				>
					<ListItemText sx={{ ml: 3 }} primary={<Typography variant="custom070">{option?.value}</Typography>} />{" "}
					{columnFilterValue.filter((columnItem) => columnItem.filter === option.key)[0]?.sort === "DESC" ? (
						<Box display={"flex"} alignItems="center">
							<ArrowDownwardIcon sx={{ cursor: "pointer" }} className="arrow" onClick={() => handleColumnFilter("ASC", option.key)} />
						</Box>
					) : columnFilterValue.filter((columnItem) => columnItem.filter === option.key)[0]?.sort === "ASC" ? (
						<Box display={"flex"} alignItems="center">
							<ArrowUpwardIcon sx={{ cursor: "pointer" }} className="arrow" onClick={() => handleColumnFilter("DESC", option.key)} />
						</Box>
					) : (
						""
					)}
				</MenuItem>
			))}
		</UpdatedSelectInput>
	);
}
