import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel, { stepLabelClasses } from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Typography from "@mui/material/Typography";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { StepButton } from "@mui/material";

export const StartupOSStepper = ({
  setStep,
  steps,
  direction,
  iconSize,
  labelColorCode,
  connectorColorCode,
  completeColorCode,
  ...props
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [stepsArray, setStepArray] = useState(null);
  const [orientation, setOrientation] = useState(null);

  const [size, setSize] = useState(null);
  const [labelColor, setLabelColor] = useState(null);
  const [connectorColor, setConnectorColor] = useState(null);
  const [completeColor, setCompleteColor] = useState(null);

  useEffect(() => {
    setLabelColor(labelColorCode);
  }, [labelColorCode]);
  useEffect(() => {
    setCompleteColor(completeColorCode);
  }, [completeColorCode]);
  useEffect(() => {
    setConnectorColor(connectorColorCode);
  }, [connectorColorCode]);

  useEffect(() => {
    switch (iconSize) {
      case "large":
        setSize(50);
        break;
      case "medium":
        setSize(40);
        break;
      case "small":
        setSize(25);
        break;
      default:
        setSize(25);
        break;
    }
  }, [iconSize]);

  useEffect(() => {
    setStepArray(steps);
  }, [steps]);

  useEffect(() => {
    setActiveStep(setStep);
  }, [setStep]);

  useEffect(() => {
    setOrientation(direction);
  }, [direction]);

  const Connector = styled(StepConnector)(({ theme }) => ({
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: connectorColor,
      opacity: 0.4,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        opacity: 1,
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        opacity: 1,
      },
    },
    [`&.${stepConnectorClasses.vertical}`]: {
      marginLeft: `${size / 2}px`,
    },
  }));

  const handleClick = step => {
    if (props && props.handleClick) {
      props.handleClick(step);
    }
  };

  return (
    <Stepper
      //  alternativeLabel -- for label below
      nonLinear
      activeStep={activeStep}
      orientation={orientation || "vertical"}
      connector={<Connector />}
    >
      {stepsArray &&
        stepsArray.map((step, index) => (
          <Step
            key={index}
            completed={step.complete ? step.complete : false}
            sx={theme => ({
              opacity: activeStep == index || step.complete ? 1 : 0.4,
            })}
          >
            <StepButton
              disabled={
                props && props.handleClick
                  ? step.complete
                    ? false
                    : true
                  : true
              }
              onClick={() => handleClick(index)}
              disableRipple
            >
              <StepLabel
                StepIconProps={{
                  sx: theme => ({
                    padding: "2px",
                    boxSizing: "border-box",
                    ...(!step.complete && {
                      border: "1px solid",
                      borderColor: connectorColor,
                      borderRadius: "50%",
                    }),

                    backgroundClip: "content-box",
                    width: `${size}px`,
                    height: `${size}px`,
                    color: "transparent",
                    "&.Mui-completed": {
                      color: completeColor,
                      borderColor: "transparent",
                    },
                    "&.Mui-active": {
                      color: "transparent",
                    },
                    "&.Mui-completed.Mui-active": {
                      borderColor: connectorColor,
                      color: completeColor,
                    },
                  }),
                }}
                sx={{
                  [`& .${stepLabelClasses.iconContainer}`]: {
                    padding: 0,
                    marginRight: "16px",
                  },
                  [`& .${stepLabelClasses.label},& .${stepLabelClasses.label}.${stepLabelClasses.completed}, & .${stepLabelClasses.label}.${stepLabelClasses.active} `]:
                    {
                      color: labelColor,
                    },
                }}
              >
                <Typography variant="bodyF16"> {step.label}</Typography>
              </StepLabel>
            </StepButton>
            {orientation === "vertical" && (
              <StepContent
                sx={{
                  color: labelColor,
                  marginLeft: `${size / 2}px`,
                  paddingLeft: `calc(${size / 2}px + 16px)`,
                  borderLeftColor: connectorColor,
                }}
              >
                <Typography>{step.description}</Typography>
              </StepContent>
            )}
          </Step>
        ))}
    </Stepper>
  );
};
