import { Tab, Avatar, Box, Tabs } from "@mui/material";
import { styled } from "@mui/material/styles";
import { BREAKPOINT_SM } from "../../../../constants/Theme/layout";

export const PowerUpTab = styled(Tab)(({ theme }) => ({
  color: theme.palette.secondary.dark,
}));

export const PowerUpTabs = styled(Tabs)(({ theme }) => ({
  borderBottom: "1px solid rgba(29, 27, 30, 0.12)",
  [theme.breakpoints.down(BREAKPOINT_SM)]: {
    overflowX: "scroll",
    maxWidth: "321px",
  },
}));

export const TabWrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  bgcolor: "background.paper",
  border: `1px solid ${theme.palette.secondary.dark}`,
  borderRadius: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));
