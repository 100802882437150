export const PAYMENT_DESC = [
	{ id: 0, desc: "We notice you're interested in accessing our premium functionality. This is available exclusively to our Premium subscribers." },
	{
		id: 1,
		desc: "We notice you're interested in accessing our Community functionality which allows for you to connect with Mentors who can provide invaluable guidance and support throughout your startup journey. This is currently available exclusively to our Premium subscribers.",
	},
	{
		id: 2,
		desc: "We notice you're interested in accessing our Messaging functionality which allows for you to directly message Mentors and Investors on the StartupOS platform. This is currently available exclusively to our Premium subscribers.",
	},
	{
		id: 3,
		desc: "We notice you're interested in accessing our Meeting functionality which allows for you to schedule meetings with Mentors that can provide invaluable guidance and support throughout your startup journey. This is currently available exclusively to our Premium subscribers.",
	},
];

export const PAYMENT_PLANS = [
	// {
	// 	id: 1,
	// 	plan: "Free Plan",
	// 	title: "Become visible to Investors, access PowerUps (learn-by-doing exercises), and more!",
	// 	amount: "0",
	// 	amountTitle: "Per month",
	// 	items: [
	// 		{ title: "Founder Dashboard" },
	// 		{ title: "PowerUps" },
	// 		{ title: "AI with our SOS Assistant “Bolt”" },
	// 		{ title: "Social Feed" },
	// 		{ title: "Discovery Board" },
	// 		{ title: "Public Webinars & Events" },
	// 		{ title: "Calculators" },
	// 		{ title: "Support: In-app Chat" },
	// 	],
	// },
	{
		id: 2,
		plan: "Premium Plan",
		title: "Access to Investors and Mentors to help your startup secure investment!",
		amount: "600.00",
		amountTitle: "Per month billed annually: $348/yr",
		items: [
			{ title: "Access investors and mentors to help secure funding for your startup." },
			{ title: "Apply for Pitch Events to showcase your innovation." },
			{ title: "Request up to three investor connections monthly to expand your network." },
			{ title: "Enjoy exclusive webinars and sponsored events for valuable industry insights and connections within the StartupOS community." },
		],
	},
];
