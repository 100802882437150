import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import services from "../../../../services";
import TableController from "../../../../components/Tables/TableController";
import { StartupAnswerColumns, StartupAnswerSortOptions } from "./StartupAnswerColumns";
import SortComponent from "../../../../components/Tables/SortComponent";
import { debounce } from "lodash";

const PartnerEntitiesTable = ({ selectedRowId, setSelectedRowId, handleRowClick }) => {
	const [sortCriteriaStartups, setSortCriteriaStartups] = useState({ colName: "", direction: "" });
	const [tableKey, setTableKey] = useState(0);
	const [searchText, setSearchText] = useState("");

	const handleSortChange = (newSortCriteria) => {
		setSortCriteriaStartups(newSortCriteria);
		setTableKey((prevKey) => prevKey + 1);
	};

	const getPartnerEntities = async (pageNo, perPage, searchText = "") => {
		try {
			let page = pageNo + 1;

			const response = await services.getPartnerEntitiesAPI({
				pageNo: page,
				pageSize: perPage,
				filterBy: sortCriteriaStartups?.colName,
				sortBy: sortCriteriaStartups?.direction,
				searchText,
			});

			if (response) {
				const { totalElements, models } = response?.data?.data;

				if (!models) {
					return {
						data: {
							totalElements: totalElements,
							models: [],
						},
					};
				}
				setSelectedRowId(models[0]?.id);

				const newModels = models.map((model) => ({
					id: model?.id,
					companyName: {
						company: model?.companyName,
						logoUrl: model?.logoUrl,
					},

					foundedYear: model?.foundedYear,
					industry: JSON.parse(model?.industry)[0],
					productOffering: model?.productOffering,
					currentScore: model?.currentScore,
				}));

				return {
					data: {
						totalElements: totalElements,
						models: newModels,
					},
				};
			}
		} catch (error) {
			console.error("Error fetching partner entities:", error);
		}
	};

	return (
		<TableController
			key={`partner_entities_${tableKey}`}
			requestData={(currentPage, pageSize, searchText) => getPartnerEntities(currentPage, pageSize, searchText)}
			columnDefs={StartupAnswerColumns(selectedRowId)}
			title={
				<Box>
					<Typography variant="Text/xs/SemiBold">Startups</Typography>
				</Box>
			}
			description={<Typography variant="Text/xs/Regular">These companies have answered your partner questions</Typography>}
			showCount
			dataGridProps={{ autoHeight: true, disableColumnMenu: true }}
			contextKey="partner_entities"
			noResultsMessage={
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						gap: "16px",
						alignSelf: "stretch",
						flex: 1,
						justifyContent: "center",
					}}
				>
					<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
						<path
							d="M28 28L23.3335 23.3333M26.6667 15.3333C26.6667 21.5926 21.5926 26.6667 15.3333 26.6667C9.07411 26.6667 4 21.5926 4 15.3333C4 9.07411 9.07411 4 15.3333 4C21.5926 4 26.6667 9.07411 26.6667 15.3333Z"
							stroke={(theme) => theme?.palette?.primary?.main}
							stroke-width="1.5"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
					</svg>
					<Typography variant="Text/sm/Semibold">No Data found</Typography>
				</Box>
			}
			onRowClick={(params) => handleRowClick(params.row.id)}
			sortComponent={
				<SortComponent sortOptions={StartupAnswerSortOptions} onSortChange={(obj) => handleSortChange(obj)} currentSort={sortCriteriaStartups} />
			}
			searchText={searchText}
		/>
	);
};

export default PartnerEntitiesTable;
