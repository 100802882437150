import styled from "@emotion/styled";
import { TextField } from "@mui/material";

export const NewTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-input": {
    cursor: "pointer",
  },
}));

export const ContentWrapper = styled.div(({ theme }) => {
  return {
    [theme.breakpoints.up("md")]: {
      boxSizing: "border-box",
      borderRadius: "4px",
      borderWidth: "2px",
      borderStyle: "solid",
      borderColor: theme.palette.warning.yellow,
      //borderRadius: theme.shape.standard.borderRadius,
      padding: theme.spacing(2),
    },
    padding: theme.spacing(3),
  };
});
