import React, { useState, useEffect } from "react";
import { Box, Grid, CircularProgress } from "@mui/material";
import SolutionList from "../../../../../components/Workspace/PowerUp/SolutionList";
import { problemInputValid } from "../functions/common";
import { cloneDeep } from "lodash";
import { problemSolutionEvaluation } from "../../../../../utils/AI/problemSolution";

export default function Solution({ userInputs, setUserInputs, powerUpExcercises, yourIdea }) {
	const [problems, setProblems] = useState([]);
	const [loading, setLoading] = useState(true);

	const handleInputChange = (e, index, sol_id, key) => {
		//setting problems
		const currentInput = { ...problems };
		currentInput[index].solution[sol_id] = e.target.value;
		setProblems(currentInput);

		//setting userInputs
		const input = { ...userInputs };
		input[key] = currentInput[index].solution;
		setUserInputs(input);
	};

	const handleSolutionAddition = (item, k) => {
		if (item.solution.length < 5) {
			let newSolution = "";
			for (var key of Object.keys(problems)) {
				if (JSON.stringify(problems[key]) == JSON.stringify(item)) {
					let newObj = problems[key].solution.push(newSolution);
					let input = { ...userInputs };
					input[`solution${Number(key) + 1}`] = problems[key].solution;
					setUserInputs(input);
					setProblems({ ...problems, ...newObj });
				}
			}
		}
	};

	const getInitialProblems = () => {
		let answers = powerUpExcercises.find((item) => item.stepNo == 2); //Find Problem Solution from power up

		for (var [index, el] of Object.entries(answers.answers.problems)) {
			if (typeof el.solution == "undefined") {
				//If .solution is undefined we need to make it [""]
				answers.answers.problems[index]["solution"] = [""]; //Adding solution [""] to render one input
			}
		}
		return answers.answers.problems;
	};

	const updateProblems = (problems, inputs) => {
		let clonedProblem = [...problems];

		problems.forEach((element, index) => {
			clonedProblem[index].solution = inputs[`solution${index + 1}`]; //Updating Solutions inside the problems with solutions from User Inputs
		});

		setProblems(clonedProblem);
	};
	useEffect(() => {
		setLoading(true); //Loader Set to control rendering
		if (powerUpExcercises && userInputs) {
			let problems = getInitialProblems(); //This function returns the problems and adds a solution key
			const input = { ...userInputs };
			let len = problems.length;
			if (Object.keys(input).length > len) {
				//If default userInputs(sol1,sol2,sol3) are GREATER than problems we need to remove the extra solutions
				Object.entries(input).map((item, index) => {
					if (index >= len) {
						delete input[`solution${index + 1}`];
					}
				});
				setUserInputs(input);
			}
			if (Object.keys(input).length < len) {
				//If default userInputs(sol1,sol2,sol3) are LESS than problems we need to add the extra solutions
				input[`solution${Object.keys(input).length + 1}`] = [""];
				setUserInputs(input);
			}

			if (input && Object.values(input)[0][0]?.length) {
				//This step is for backwards flow where we show prefilled data, we need to append the userinput in problem since problems is mapped.
				updateProblems(problems, input);
			} else {
				//If New Flow where we do not need to prefill solutions
				setProblems(problems);
			}
		}
		setLoading(false);
	}, [userInputs]); //When User Input Changes

	const evaluateSolution = async () => {
		const response = await problemSolutionEvaluation({
			problem: "",
			solutions: "", // Stringified array of solutions
		});
	};

	return (
		<Box>
			<Grid container>
				{!loading && userInputs ? (
					<Grid item xs={12} md={12}>
						{Object.entries(problems).map((item, index) => {
							return (
								<SolutionList
									index={index + 1}
									problem={item[1]}
									handleSolutionAddition={handleSolutionAddition}
									handleInputChange={handleInputChange}
									userInputs={userInputs}
									yourIdea={yourIdea}
								/>
							);
						})}
					</Grid>
				) : (
					<Box
						sx={{
							display: "flex",
							width: 1,
							justifyContent: "center",
						}}
					>
						<CircularProgress />
					</Box>
				)}
			</Grid>
		</Box>
	);
}
