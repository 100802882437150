import { Typography, Box, Chip } from "@mui/material";
import React, { useState } from "react";
import { PowerUpTab, PowerUpTabs, TabWrapper } from "./styles";

const PowerupRecapTab = ({
  valuePropContent,
  powerUpExcercisesData,
  option,
}) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  return (
    <TabWrapper>
      <PowerUpTabs value={value} onChange={handleChange} variant="fullWidth">
        {valuePropContent.map((powerup, i) => {
          return (
            <PowerUpTab
              label={
                <Typography
                  variant="h5"
                  sx={{ textTransform: "none" }}
                  value={i + 1}
                >
                  {powerup.title}
                </Typography>
              }
            />
          );
        })}
      </PowerUpTabs>

      {option === 2
        ? Object.entries(powerUpExcercisesData.answers).map((item, idx) => {
            return (
              <TabPanel value={value} index={idx}>
                {item[1].map(tag => {
                  return (
                    <Chip
                      label={<Typography variant="h5">{tag}</Typography>}
                      variant="outlined"
                      sx={theme => ({
                        borderRadius: theme.shape.standard_small.borderRadius,
                        m: 1,
                        "& .MuiChip-deleteIcon": {
                          color: "text.primary",
                        },
                      })}
                    />
                  );
                })}
              </TabPanel>
            );
          })
        : powerUpExcercisesData.map((item, idx) => {
            return (
              <TabPanel value={value} index={idx}>
                {item.answers.map(tag => {
                  return (
                    <Chip
                      label={<Typography variant="h5">{tag}</Typography>}
                      variant="outlined"
                      sx={theme => ({
                        borderRadius: theme.shape.standard_small.borderRadius,
                        m: 1,
                        "& .MuiChip-deleteIcon": {
                          color: "text.primary",
                        },
                      })}
                    />
                  );
                })}
              </TabPanel>
            );
          })}
    </TabWrapper>
  );
};

export default PowerupRecapTab;
