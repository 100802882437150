import { Grid, Stack, Typography, Skeleton } from "@mui/material";
import React, { useContext, useEffect } from "react";
import UserProfileCardSection from "../UserProfileCardSection";
import { BOOK_ICON_V2 } from "../../../constants";
import { UserCardContainer } from "./style";
import StatusButton from "../../../components/Profile/Components/StatusButton";
import { useSelector } from "react-redux";
import ProgressCompletionCalculator from "../../../utils/progressCompletionCalculator";
import { useParams } from "react-router-dom";

const UserAboutCard = ({ context }) => {
	const userDetails = useSelector((state) => state?.userProfileData?.userData);
	const homeApiVersion = process.env.REACT_APP_HOME_URL;
	const shareUrl = `${homeApiVersion}/account/${userDetails?.uuid}`;
	const isLoading = useSelector((state) => state?.userProfileData?.loading);
	const { handleEditProfileRedirections, setBioPercentage } = useContext(context);
	const params = useParams();
	const userId = params?.id;
	const totalFields = 1;
	let completedFields = 0;
	if (userDetails?.about) {
		completedFields += 1;
	}

	useEffect(() => {
		setBioPercentage(ProgressCompletionCalculator(totalFields, completedFields));
	}, [completedFields]);

	return (
		<UserCardContainer>
			<Stack spacing={3}>
				{isLoading ? (
					<Skeleton animation="wave" variant="rectangular" width={100} height={50} />
				) : (
					!userId && (
						<StatusButton
							percentage={ProgressCompletionCalculator(totalFields, completedFields)}
							handleRedirection={handleEditProfileRedirections}
						/>
					)
				)}

				<Grid container>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						{isLoading ? (
							<Skeleton animation="wave" variant="rectangular" width={"100%"} height={100} />
						) : (
							<UserProfileCardSection
								icon={BOOK_ICON_V2}
								title={"My Bio"}
								iconColor={""}
								iconHeight={"26px"}
								iconWidth={"26px"}
								shareUrl={shareUrl}
								email={userDetails?.email}
							>
								<Typography variant="Text/sm/Regular">
									<span
										dangerouslySetInnerHTML={{
											__html: userDetails?.about ? userDetails?.about : `No Data`,
										}}
									/>
								</Typography>
							</UserProfileCardSection>
						)}
					</Grid>
				</Grid>
			</Stack>
		</UserCardContainer>
	);
};

export default UserAboutCard;
