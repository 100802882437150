import { Box, useMediaQuery } from "@mui/material";
import React from "react";

export default function DashboardLayout({ topbar, main, isFull, height = "100vh" }) {
	return (
		<Box
			sx={{
				height: "100vh",
				width: 1,
				boxSizing: "border-box",
				height: height,
			}}
		>
			<Box
				sx={(theme) => ({
					minHeight: 1,
					position: "relative",
				})}
			>
				<Box
					sx={(theme) => ({
						display: "flex",
						boxSizing: "border-box",
						position: "sticky",
						top: 0,
						py: 1,
						zIndex: 1000,
						backgroundColor: theme.palette.secondary.bgGray,
					})}
				>
					{topbar}
				</Box>

				<Box
					sx={{
						display: "flex",
						flex: 1,
						flexDirection: "column",
					}}
				>
					<Box
						sx={(theme) => ({
							flex: 1,
							mx: isFull ? 0 : 3,
							boxSizing: "border-box",
							[theme.breakpoints.down("md")]: {
								mt: 0,
							},
						})}
					>
						{main}
					</Box>
				</Box>
			</Box>
		</Box>
	);
}
