import React, { useContext, useEffect } from "react";
import { PowerUPContext } from "../../PowerUPController";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, Typography } from "@mui/material";
import VersionHistory from "../cards/VersionHistory";
import { Add } from "@mui/icons-material";
import { handleMixPanel } from "../../../../utils/mixPanelEventHandling";

/* 
    set tab => when set do-it
        is a version set?
            + if a version ID exists then do not create new version
            + if not a version
                check to see if there are incomplete versions
                    display versions modal
                        + user selects the version and loads the data if available
                        or
                        + user selects new version		
*/

const PowerUPVersionModal = () => {
	const { setActiveTab, versions, setcurrentVersion, setVersionModalOpen, versionModalOpen, startNew, powerupDetails } = useContext(PowerUPContext);
	const [loading, setLoading] = React.useState(false);

	const loadingCallback = (value) => {
		setLoading(value);
	};

	useEffect(() => {
		if (versionModalOpen) {
			handleMixPanel("PowerUp Work History Viewed", {
				"Powerup ID": powerupDetails?.id,
				"Powerup Name": powerupDetails?.powerUpName,
			})
		}
	}, [versionModalOpen])

	return (
		<Dialog maxWidth="md" fullWidth open={versionModalOpen} onClose={() => setVersionModalOpen(false)} PaperProps={{ sx: { borderRadius: "24px" } }}>
			<Box sx={{ px: "2rem", pt: "2rem", display: "flex", justifyContent: "flex-end" }}>
				<Typography variant="Text/md/Semibold">Work History</Typography>
				<CloseIcon onClick={() => setVersionModalOpen(false)} sx={{ ml: "auto", cursor: "pointer" }} />
			</Box>
			<DialogContent
				sx={{
					border: "none",
					py: 1,
					px: "2rem",
				}}
			>
				<Typography variant="Text/sm/Regular">Please select an in-progress version below or you may start a new version.</Typography>
				<VersionHistory showOnlyInProgress={true} selectedVersionCallback={(version) => setcurrentVersion(version)} />
			</DialogContent>
			<DialogActions sx={{ padding: "2rem" }}>
				<Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", gap: 1 }}>
					<Button
						disabled={loading}
						variant="DS1"
						color="secondary"
						small
						startIcon={loading ? <CircularProgress size={"1.25rem"} sx={{ color: "white" }} /> : <Add />}
						onClick={() => startNew(loadingCallback)}
					>
						Start New Version
					</Button>
				</Box>
			</DialogActions>
		</Dialog>
	);
};
export default PowerUPVersionModal;
