//Auth Actions
export const SET_AUTH_DATA = "SET_AUTH_DATA";

//Onboarding Actions
export const SET_ONBOARDING_DATA = "SET_ONBOARDING_DATA";
export const CLEAR_ONBOARDING_DATA = "CLEAR_ONBOARDING_DATA";

export const SET_COMPANY_NAME = "SET_COMPANY_NAME";
export const SET_COMPANY_LOCATION = "SET_COMPANY_LOCATION";
export const SET_AUTOCOMPLETE_DATA = "SET_AUTOCOMPLETE_DATA";
export const SET_PRODUCT_SERVICE = "SET_PRODUCT_SERVICE";
export const SET_EXPERIENCE_LEVELS = "SET_EXPERIENCE_LEVELS";
export const SET_FOUNDER_SUPER_POWER = "SET_FOUNDER_SUPER_POWER";
export const SET_COMMITMENT_LEVELS = "SET_COMMITMENT_LEVELS";
export const SET_STARTUP_JOURNEY = "SET_STARTUP_JOURNEY";
export const SET_POWERUP_ExcerciseS = "SET_POWERUP_ExcerciseS";
export const SET_INTERVIEW_SCRIPT_DIALOG = "SET_INTERVIEW_SCRIPT_DIALOG";
export const SET_POWERUP_DETAILS = "SET_POWERUP_DETAILS";
export const SET_INTERVIEW_CANDIDATES = "SET_INTERVIEW_CANDIDATES";
export const SET_CANDIDATE_DETAILS = "SET_CANDIDATE_DETAILS";
export const SET_MARKET_TEST_ID = "SET_MARKET_TEST_ID";
export const SET_EXTERNAL_CANDIDATE_DETAILS = "SET_EXTERNAL_CANDIDATE_DETAILS";

//mentor Actions
export const SET_MENTOR_PROFILE = "SET_MENTOR_PROFILE";
//Candidate Actions
export const SET_CANDIDATE_PROFILE = "SET_CANDIDATE_PROFILE";
export const SET_TAMSAMSOM_DATA = "SET_TAMSAMSOM_DATA";

//myStartup
export const SET_MY_WORK_PROFILE = "SET_MY_WORK_PROFILE";
export const SET_CURRENT_MEETING_OBJECT = "SET_CURRENT_MEETING_OBJECT";
export const SET_CAL_USER = "SET_CAL_USER";
export const SET_COMING_FROM_PLUGIN = "SET_COMING_FROM_PLUGIN";

export const SET_POWERUP_HEADER_DETAILS = "SET_POWERUP_HEADER_DETAILS";

export const SET_NOTIFICATION_DATA = "SET_NOTIFICATION_DATA";

export const PUSH_NOTIFICATION_LIST = "PUSH_NOTIFICATION_LIST";

export const OPEN_SCORE_BENCHMARK_DETAILS = "OPEN_SCORE_BENCHMARK_DETAILS";

export const CLOSE_SCORE_BENCHMARK_DETAILS = "CLOSE_SCORE_BENCHMARK_DETAILS";

export const SET_INVESTOR_DASHBOARD_DATA = "SET_INVESTOR_DASHBOARD_DATA";

export const SET_SIDEBAR_WIDTH = "SET_SIDEBAR_WIDTH";
//Mixpanel Actions
export const SET_MIXPANEL_DATA = "SET_MIXPANEL_DATA";

export const SET_FOUNDER_DASHBOARD_DATA = "SET_FOUNDER_DASHBOARD_DATA";

export const SET_STARTUP_PROFILE_DATA = "SET_STARTUP_PROFILE_DATA";

export const SET_POWERUP_LAYOUT_DETAILS = "SET_POWERUP_LAYOUT_DETAILS";

export const SET_SIDEBAR_OPEN_PREF = "SET_SIDEBAR_OPEN_PREF";

export const SET_WORK_HISTORY_TASK_DETAILS = "SET_WORK_HISTORY_TASK_DETAILS";

export const SET_FEED_DATA = "SET_FEED_DATA";

export const SET_USER_PROFILE_DATA = "SET_USER_PROFILE_DATA";

export const SET_STARTUP_PUBLIC_DATA = "SET_STARTUP_PUBLIC_DATA";

export const SET_ACCOUNT_PUBLIC_DATA = "SET_ACCOUNT_PUBLIC_DATA";

export const SET_BI_DASHBOARD_DATA = "SET_BI_DASHBOARD_DATA";

export const SET_NETWORKING_DASHBOARD_DATA = "SET_NETWORKING_DASHBOARD_DATA";

export const SET_COMMON_DIALOG_CONFIRMATION_DATA = "SET_COMMON_DIALOG_CONFIRMATION_DATA";

export const SET_SLIDING_PROFILE_DIALOG_DATA = "SET_SLIDING_PROFILE_DIALOG_DATA";

export const SET_EVENT_DATA = "SET_EVENT_DATA";

//Auth Actions
export const SET_UTM_PARAMS = "SET_UTM_PARAMS";

export const SET_SLACK_WORKSPACE_DATA = "SET_SLACK_WORKSPACE_DATA";

export const SET_EMAIL_TOKEN = "SET_EMAIL_TOKEN";

export const SET_COMMUNITY_PARTNERS = "SET_EMAIL_TOKEN";
