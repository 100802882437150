import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";

import { NewOnboardCard, StepTitle, Stepper } from "../ui";
import Icon from "../../Assets/onboarding-help.svg";
import { Help1Icon, Help2Icon, Help3Icon, Help4Icon, Help5Icon, Help6Icon } from "../icons";
import { EXPERTISE_SUB_OPTIONS } from "../../../Onboard/Components/Options";
import { USER_TYPES } from "../../../../utils/userTypes";

export const Help = ({ step, userType, totalStep, errorMsg, handleHelpData }) => {
	const HELP_OPTIONS = [
		{ id: 0, icon: Help1Icon, title: "Startup Strategy & Execution" },
		{ id: 1, icon: Help2Icon, title: "Marketing & Growth" },
		{ id: 2, icon: Help3Icon, title: userType === USER_TYPES.STARTUP_OWNER ? "Networking" : "Operations & Infrastructure" },
		{ id: 3, icon: Help4Icon, title: "User Experience & Design" },
		{ id: 4, icon: Help5Icon, title: "Team Building & Leadership" },
		{ id: 5, icon: Help6Icon, title: "Engineering & Technical" },
	];
	const [selectedOptions, setSelectedOptions] = useState([]);

	const handleExpertiseOption = (index) => {
		setSelectedOptions([...index]);
	};

	useEffect(() => {
		setSelectedOptions([]);
	}, [userType]);

	useEffect(() => {
		if (selectedOptions.length > 0) {
			handleHelpData(
				{
					superpower: JSON.stringify(selectedOptions.map((id) => HELP_OPTIONS.filter((option) => option.id === id)[0].title)),
				},
				true
			);
		} else {
			handleHelpData({}, false);
		}
	}, [selectedOptions]);
	return (
		<Box width="100%">
			<Stepper step={step} totalStep={totalStep} />
			<StepTitle
				title={userType === USER_TYPES.MENTOR_USER ? "Where can you help the most?" : "Where do you need the most help?"}
				icon={Icon}
				tooltip=""
				errorMsg={errorMsg}
			/>
			<Box display="flex" justifyContent="center">
				<Grid
					container
					spacing="32px"
					sx={{
						maxWidth: "1100px",
					}}
				>
					{HELP_OPTIONS.map((account, index) => (
						<Grid key={account.id} item display="flex" justifyContent="center" xs={12} md={6} xl={4}>
							<NewOnboardCard
								key={account.id}
								id={account.id}
								title={account.title}
								CardIcon={account.icon}
								selectedId={selectedOptions}
								multiSelect={true}
								isExpertise={true}
								subOptions={EXPERTISE_SUB_OPTIONS[index].content}
								onSelect={handleExpertiseOption}
							/>
						</Grid>
					))}
				</Grid>
			</Box>
		</Box>
	);
};
