import React, { useState } from "react";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import Dropzone from "react-dropzone";
import { UPLOAD_ICON_V2 } from "../../constants";
import { toastContainer } from "../../utils/toast";
import services from "../../services";
import CommonSlideDialog from "../CommonSlideDialog";
import VideoPlayerComponent from "../videoPlayer";

const UpdatedVideoUploader = ({ title, instructions, width, height, shape, type, userData, setPayLoad, fileType, editTitle }) => {
	const [localFile, setLocalFile] = useState(false);
	const [loading, setLoading] = useState(false);
	const [cropData, setCropData] = useState(false);
	const [isDeleteOpen, setIsDeleteOpen] = useState("");
	const [deleteConfirmation, setDeleteConfirmation] = useState(false);

	const handleFileSelect = (file) => {
		if (file) {
			const fileSize = (file?.size / (1024 * 1024 * 1024)).toFixed(2);
			if (fileSize < 2) {
				const reader = new FileReader();
				reader.addEventListener("load", () => setLocalFile(reader.result?.toString() || ""));
				reader.readAsDataURL(file);
				getFiles(file);
			} else {
				toastContainer("Please Upload file less than 2GB", "error");
			}
		} else {
			toastContainer(`Invalid file format for '${title}'. Please upload a file in one of the following formats: 3gp, mp4, mov, webm, ogg.`, "error");
		}
	};

	const getFiles = async (data) => {
		setLoading(true);
		var formData = new FormData();
		formData.append("file", data);
		formData.append("fileGroup", "PROFILE");
		services
			.uploadFile(formData)
			.then((response) => {
				userData[type] = response?.data?.data?.fileLocation;
				setPayLoad(...userData);
				setLocalFile("");
				setLoading(false);
			})
			.catch((e) => {
				setLocalFile("");
				setLoading(false);
			});
	};

	const handleDelete = () => {
		setDeleteConfirmation(false);
		setIsDeleteOpen(false);
		userData[type] = "";
		setPayLoad(...userData);
	};

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				gap: 3,
				justifyContent: "center",
			}}
		>
			<Typography variant="poppinsSemiBold20" sx={{ textAlign: "center" }}>
				{title}
			</Typography>
			{/* <Typography variant="flyout_text" sx={{ textAlign: "center" }}>
				{instructions}
			</Typography> */}
			<Box
				sx={{
					width: { width },
					height: { height },

					borderRadius: shape == "circular" ? "100%" : "30px",
					boxShadow: "0px 10px 25px 0px rgba(0, 0, 0, 0.05)",
					background: "white",
					alignSelf: "center",
					cursor: "pointer",
				}}
			>
				{userData && userData[type] ? (
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						<Box
							sx={{
								flex: 1,
								"& video": {
									borderRadius: "30px",
									border: "none",
									objectFit: "cover",
								},
							}}
						>
							<VideoPlayerComponent height={height} videoUrl={userData[type] ? userData[type] : ""} />
						</Box>
						<Box
							component={"img"}
							src={UPLOAD_ICON_V2}
							width={50}
							height={50}
							alt="img"
							sx={{ position: "absolute" }}
							onClick={() => {
								setIsDeleteOpen(true);
							}}
						/>
					</Box>
				) : (
					<Dropzone onDrop={!cropData ? (acceptedFiles) => handleFileSelect(acceptedFiles[0]) : () => {}} accept={fileType}>
						{({ getRootProps, getInputProps }) => (
							<section>
								<Box {...getRootProps()}>
									<input {...getInputProps()} />
									<Box
										sx={(theme) => ({
											width: { width },
											height: { height },

											borderRadius: shape == "circular" ? "100%" : "30px",
											justifyContent: "center",
											display: "flex",
											flexDirection: "column",
											alignItems: "center",
										})}
									>
										<Typography
											variant="bodyF16"
											sx={{
												mt: 1,
												display: "grid",
												color: (theme) => theme.palette.secondary.outline,
											}}
										>
											{" "}
											Drag file to upload or browse
										</Typography>
										<Box component={"img"} src={UPLOAD_ICON_V2} width={50} height={50} alt="img"></Box>
									</Box>
								</Box>
							</section>
						)}
					</Dropzone>
				)}
			</Box>
			<CommonSlideDialog
				disableBackDropClick={true}
				title={editTitle}
				titleVariant={"poppinsSemiBold20"}
				isOpen={isDeleteOpen}
				onClose={() => {
					setIsDeleteOpen(false);
				}}
				contentStyle={{ px: 0 }}
				footerStyle={{ p: 0 }}
				maxWidth={"md"}
				fullWidth
				PaperProps={{
					sx: (theme) => ({
						borderRadius: theme.shape.standard13.borderRadius,
						boxShadow: "0px 5px 16px rgba(0, 0, 0, 0.3)",
						p: 3,
					}),
				}}
				dialogAction={
					<Box sx={{ display: "flex", gap: 1, pt: 3, alignItems: "center" }}>
						<Box>
							<Button
								variant="noOutlineNew"
								onClick={() => {
									setDeleteConfirmation(true);
								}}
								sx={{
									fontSize: "18px",
									lineHeight: "28px",
									fontFamily: "PoppinsSemiBold",
								}}
							>
								Delete
							</Button>
						</Box>
						<Box>
							<Dropzone onDrop={!cropData ? (acceptedFiles) => handleFileSelect(acceptedFiles[0]) : () => {}} accept={fileType}>
								{({ getRootProps, getInputProps }) => (
									<section>
										<Box {...getRootProps()}>
											<input {...getInputProps()} />
											<Button variant="DS1">Edit</Button>
										</Box>
									</section>
								)}
							</Dropzone>
						</Box>
					</Box>
				}
			>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						flex: 1,
						"& video": {
							borderRadius: "30px",
							border: "none",
						},
					}}
				>
					<VideoPlayerComponent height={height} videoUrl={userData[type] ? userData[type] : ""} />
				</Box>
				<CommonSlideDialog
					disableBackDropClick={true}
					title={"Uploading Video"}
					titleVariant={"poppinsSemiBold20"}
					isOpen={loading}
					onClose={() => {}}
					closeButton={false}
					titleStyle={{ px: 0 }}
					contentStyle={{ px: 0 }}
					maxWidth={"sm"}
					fullWidth
					PaperProps={{
						sx: (theme) => ({
							borderRadius: theme.shape.standard13.borderRadius,
							boxShadow: "0px 5px 16px rgba(0, 0, 0, 0.3)",
							p: 3,
						}),
					}}
					dialogAction={<></>}
				>
					<Box
						sx={(theme) => ({
							textAlign: "center",
							pt: 2,
							display: "flex",
							flexDirection: "column",
							gap: 3,
							alignItems: "center",
						})}
					>
						<CircularProgress
							sx={(theme) => ({
								height: "80px",
								width: "80px",
								color: theme.palette.text.darkBlue,
							})}
						/>
						<Typography variant="title_medium">File upload in progress</Typography>
					</Box>
				</CommonSlideDialog>
			</CommonSlideDialog>
			<CommonSlideDialog
				disableBackDropClick={true}
				title={"Delete Video"}
				titleVariant={"poppinsSemiBold20"}
				isOpen={deleteConfirmation}
				onClose={() => {
					setDeleteConfirmation(false);
				}}
				titleStyle={{ px: 0 }}
				contentStyle={{ px: 0 }}
				footerStyle={{ p: 0 }}
				maxWidth={"sm"}
				fullWidth
				PaperProps={{
					sx: (theme) => ({
						borderRadius: theme.shape.standard13.borderRadius,
						boxShadow: "0px 5px 16px rgba(0, 0, 0, 0.3)",
						p: 3,
					}),
				}}
				dialogAction={
					<Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
						<Box>
							<Button variant="RedRounded" onClick={handleDelete}>
								Delete
							</Button>
						</Box>
						<Box>
							<Button
								variant="noOutlineNew"
								onClick={() => {
									setDeleteConfirmation(false);
								}}
								sx={{
									fontSize: "18px",
									lineHeight: "28px",
									fontFamily: "PoppinsSemiBold",
								}}
							>
								Cancel
							</Button>
						</Box>
					</Box>
				}
			>
				<Box
					sx={(theme) => ({
						textAlign: "left",
					})}
				>
					<Typography variant="title_medium">Are You sure you want to delete this video?</Typography>
				</Box>
			</CommonSlideDialog>
		</Box>
	);
};

export default UpdatedVideoUploader;
