import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormControl, InputLabel, Typography, Box, Select, MenuItem, Checkbox, OutlinedInput, ListItemText } from "@mui/material";

import services from "../../../../../services";
import { setInvestorDashboardData } from "../../../../../modules/actions";
import { KeyboardArrowDown } from "@mui/icons-material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

const CohortFilter = () => {
	const filteredValue = useSelector((state) => state?.investorDashboard?.filteredValue) || [];
	const [cohortList, setCohortList] = useState([]);
	const [currentCohorts, setCurrentCohorts] = useState([]);
	const dispatch = useDispatch();
	let cohortFilter = filteredValue.filter((filteredItem) => filteredItem.type === "cohorts");

	useEffect(() => {
		if (cohortList.length === 0) {
			getCohortsList();
		}
	}, [cohortList]);

	useEffect(() => {
		if (cohortList.length > 0 && cohortFilter.length > 0) {
			setCurrentCohorts(cohortFilter[0]?.checkboxValue);
		}
	}, [cohortList, cohortFilter]);

	useEffect(() => {
		if (cohortFilter.length === 0) {
			setCurrentCohorts([]);
		}
	}, [filteredValue]);

	const getCohortsList = async () => {
		const list = await services.getCohorts();
		setCohortList(list.data?.data || []);
	};

	const handleOnChange = (e) => {
		setCurrentCohorts(e.target.value);

		const currentCohortIds = [];
		e.target.value.forEach((item) => {
			currentCohortIds.push(cohortList.filter((cohort) => cohort.name === item)[0]?.id);
		});

		let newObj = {
			type: "cohorts",
			cohortIds: currentCohortIds,
			checkboxValue: e.target.value,
		};
		const tempFilteredValue = [...filteredValue];
		let index = tempFilteredValue.findIndex((item) => item.type === newObj.type);
		if (index !== -1) {
			tempFilteredValue[index] = newObj;
		} else {
			tempFilteredValue.push(newObj);
		}

		dispatch(
			setInvestorDashboardData({
				filteredValue: tempFilteredValue,
			})
		);
	};

	return (
		<Box mb={5}>
			<Typography
				sx={{
					color: (theme) => theme.palette.secondary.black,
					width: "fit-content",
				}}
				variant="testHeading"
			>
				Cohort
			</Typography>
			<FormControl fullWidth sx={{ width: 1, mt: 2 }}>
				<Select
					labelId="Cohort"
					multiple
					fullWidth
					displayEmpty
					value={currentCohorts}
					onChange={handleOnChange}
					IconComponent={KeyboardArrowDown}
					input={
						<OutlinedInput
							placeholder="Select Multiple"
							sx={{
								borderRadius: "100px",
								border: "1px solid",
								borderColor: "#D0D5DD",
								"&.Mui-focused fieldset.MuiOutlinedInput-notchedOutline": {
									borderColor: "transparent", // Remove border color when focused or expanded
								},
								"& .MuiSelect-icon": {
									right: "20px",
									fontSize: "25px",
								},
								"& .MuiInputBase-input": {
									padding: "12px 20px",
									fontSize: "16px",
									fontFamily: '"PoppinsRegular", "Helvetica", "Arial", sans-serif',
								},
							}}
						/>
					}
					renderValue={(selected) => {
						if (selected.length === 0) {
							return (
								<span
									style={{
										color: "#667085",
										fontFamily: '"PoppinsSemibold", "Helvetica", "Arial", sans-serif',
									}}
								>
									Select Multiple
								</span>
							);
						}

						return selected.join(", ");
					}}
					MenuProps={MenuProps}
				>
					{cohortList.map((option) => (
						<MenuItem key={option.id} value={option.name}>
							<Checkbox checked={currentCohorts.indexOf(option.name) > -1} />
							<ListItemText primary={option.name} />
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</Box>
	);
};

export default CohortFilter;
