import { Box, Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import OnboardingCard from "../../../common/card/OnboardingCard";

export default function OnboardingCardSelector({
  cardItems,
  flex,
  gridColWidth,
  multiSelect,
  setUserResponse,
  userResponse,
  savedData,
  titleComp,
  setRemove,
}) {
  const [cardsData, setCardsData] = useState(cardItems);

  useEffect(() => {
    setCardsData(cardItems);
  }, [cardItems]);

  //Use this function for getting the input filled by users in Other blocks here check if input doent matching the card text and that input is the other one
  const getOtherText = () => {
    var otherText = null;
    if (multiSelect) {
      const selectableTexts = cardItems.map(item => item.text);
      savedData.map(saved => {
        if (!selectableTexts.includes(saved)) {
          otherText = saved;
        }
      });
    } else {
      const selectableTexts = cardItems.map(item => item.text);
      if (!selectableTexts.includes(savedData)) {
        otherText = savedData;
      }
    }

    return otherText;
  };

  // This function returns other value and selected flags
  const getSelectedKeys = item => {
    if (multiSelect) {
      if (savedData.includes(item.text)) {
        return {
          selected: true,
          otherValue: null,
        };
      } else {
        if (item.text === "Other" || item.text === "Other Group") {
          return {
            selected: getOtherText() ? true : false,
            otherValue: getOtherText(),
          };
        } else {
          return {
            selected: false,
            otherValue: null,
          };
        }
      }
    } else {
      if (item.text === savedData) {
        return {
          selected: true,
          otherValue: null,
        };
      } else {
        if (item.text === "Other" || item.text === "Other Group") {
          return {
            selected: getOtherText() ? true : false,
            otherValue: getOtherText,
          };
        } else {
          return {
            selected: false,
            otherValue: null,
          };
        }
      }
    }
  };

  //Update the cards JOSN array once we get reduc saved data
  useEffect(() => {
    if (savedData) {
      const updatedItem = cardItems.map((item, index) => ({
        ...item,
        selected: getSelectedKeys(item).selected,
        otherText: getSelectedKeys(item).otherValue,
      }));

      setCardsData(updatedItem);
    }
  }, [savedData]);

  const handleSelect = (index, answer) => {
    //For single select
    if (!multiSelect) {
      const data = [...cardsData];
      data.map((item, idx) => {
        item = {
          ...item,
          selected: false,
        };
        data[idx] = item;
      });
      setCardsData(data);

      var selectedAnswer = data[index];
      selectedAnswer = {
        ...selectedAnswer,
        selected: answer ? true : false,
      };
      data[index] = selectedAnswer;

      setCardsData(data);
      setUserResponse(answer);
    } else {
      //For multi select
      const data = [...cardsData];
      var selectedAnswer = data[index];

      //To select all of above
      if (selectedAnswer.selectAll) {
        data.map((item, idx) => {
          item = {
            ...item,
            selected: answer ? true : false,
          };
          data[idx] = item;
        });
        setCardsData(data);
      } else {
        const selectAllBlockIndex = data.findIndex(
          item => item.selectAll === true && item.selected === true
        );

        if (selectAllBlockIndex >= 0) {
          let selectAllBlockObj = data[selectAllBlockIndex];
          selectAllBlockObj = {
            ...selectAllBlockObj,
            selected: false,
          };
          data[selectAllBlockIndex] = selectAllBlockObj;
        }
        selectedAnswer = {
          ...selectedAnswer,
          selected: answer ? true : false,
        };
        if (setRemove) {
          if (!answer) {
            setRemove(data[index]);
          } else {
            setRemove(false);
          }
        }

        data[index] = selectedAnswer;
        setCardsData(data);
      }
      let answersArr = [];
      for (const item of data) {
        if (item.selected) {
          if (item.text === "Other" || item.text === "Other Group") {
            answersArr.push(answer);
          } else {
            answersArr.push(item.text);
          }
        }
      }
      setUserResponse(answersArr);
    }

    //Add answers to redux stores
  };
  return (
    <Box>
      <Grid container display="flex" justifyContent="center">
        <Grid item md={gridColWidth} sm={12} xs={12}>
          {titleComp && titleComp}
          <Box
            sx={{
              display: "flex",
              flexFlow: "row wrap",
              justifyContent: "center",
            }}
          >
            {cardsData.map((item, index) => {
              return (
                <Box
                  sx={theme => ({
                    width: `${flex * 100}%`,
                    [theme.breakpoints.down("md")]: {
                      width: "50%",
                    },
                    m: 0,
                    py: 1,
                  })}
                >
                  <Box
                    sx={{
                      px: 1,
                      display: "flex",
                      flexDirection: "column",
                      height: "100%",
                    }}
                  >
                    <OnboardingCard
                      updatedText={item.text}
                      updatedIcon={item.icon}
                      selected={item.selected}
                      onSelect={handleSelect}
                      disabled={item.disabled}
                      index={index}
                      isOtherEnabled={item.isOtherEnabled}
                      inputPopupTitle={item.inputPopupTitle}
                      inputPlaceHolder={item.inputPlaceHolder}
                      updatedTextColor={item.textColor}
                      otherText={item.otherText}
                      hoverText={item.hoverText}
                    />
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
