import React from "react";
import { DialogTitle, Dialog, IconButton, Typography, Divider, DialogContent, DialogActions, Slide, Button, Box, CircularProgress } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import theme from "../../constants/Theme";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="left" ref={ref} {...props} />;
});

export default function MessageSlidingHandler({
	isOpen,
	title,
	text,
	subText,
	primaryButtonText,
	secondaryButtonText,
	handlePrimarybtnClick,
	handleSecondarybtnClick,
	handleClose,
	disableBackDropClick = true,
	loading = false,
}) {
	return (
		<Dialog
			TransitionComponent={Transition}
			transitionDuration={600}
			aria-describedby="alert-dialog-slide-description"
			keepMounted
			fullWidth
			open={isOpen}
			onClose={disableBackDropClick ? () => {} : () => handleClose()}
			PaperProps={{
				sx: (theme) => ({
					borderRadius: theme.shape.standard13.borderRadius,
					boxShadow: "0px 5px 16px rgba(0, 0, 0, 0.3)",
					p: 3,
				}),
			}}
		>
			{title && (
				<DialogTitle
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<Typography variant={"card_name"}>{title}</Typography>

					<IconButton
						aria-label="close"
						onClick={!loading && handleClose}
						sx={{
							color: theme.palette.secondary.dark,
						}}
					>
						<CloseIcon sx={{ color: (theme) => theme.palette.secondary.black }} />
					</IconButton>
				</DialogTitle>
			)}
			<DialogContent
				sx={{
					px: 4,
				}}
			>
				<>
					<Box sx={{ pt: 2 }}>
						<Typography variant="on_surface">{text}</Typography>
					</Box>
					<Box sx={{ mt: 1 }}>
						<Typography variant="sub_title_portfolio">{subText} </Typography>
					</Box>
				</>
			</DialogContent>
			<DialogActions>
				<Box display={"flex"} gap={1} sx={{ pr: 2 }} alignItems="center">
					<Button
						variant="noOutlineNew"
						onClick={() => {
							if (handleSecondarybtnClick) {
								handleSecondarybtnClick?.();
							} else {
								handleClose?.();
							}
						}}
						disabled={loading}
						sx={{ whiteSpace: "nowrap", minWidth: "max-content" }}
					>
						{secondaryButtonText}
					</Button>
					<Button
						variant="containedNewStyle"
						disabled={loading}
						onClick={() => {
							handlePrimarybtnClick?.();
						}}
						startIcon={loading && <CircularProgress sx={{ color: "inherit" }} size={"1.25rem"} />}
					>
						{primaryButtonText}
					</Button>
				</Box>
			</DialogActions>
		</Dialog>
	);
}
