import { ChevronRight } from "@mui/icons-material";
import { Box, Typography, Button, Grid } from "@mui/material";
import React from "react";

export const MentorItem = ({ mentorObj, handleClick, type, ...props }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        p: 2,
        gap: 2,
      }}
    >
      <Box
        sx={theme => ({
          flex: "0 0 32px",
          height: "32px",
          width: "32px",
          backgroundColor: theme.palette.secondary.outline2,
          borderRadius: "50%",
          backgroundImage: `url(${mentorObj && mentorObj?.user?.picture})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
        })}
      ></Box>
      <Box
        sx={{ width: { sm: "100%", md: "120px", lg: "130px", xl: "150px" } }}
      >
        <Typography
          variant="body_large"
          sx={{
            display: "block",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "90%",
            whiteSpace: "nowrap",
          }}
        >
          {mentorObj?.user?.name}
        </Typography>
      </Box>
      <Box sx={{ dispaly: "flex", ml: "auto" }}>
        {props.routeToScheduling ? (
          <Button
            variant={"nofill"}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              p: 2,
            }}
            onClick={() => {
              if (handleClick) {
                handleClick();
              }
            }}
          >
            Schedule
          </Button>
        ) : (
          <Button
            variant={"outlined"}
            startIcon={<ChevronRight />}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              p: 2,
            }}
            onClick={() => {
              if (handleClick) {
                handleClick();
              }
            }}
          >
            View
          </Button>
        )}
      </Box>
    </Box>
  );
};
