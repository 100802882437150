import React, { useEffect } from "react";
import CommonSlideDialog from "../../../../common/CommonSlideDialog";
import { Box, Typography, Button, Grid } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { setBIDashboardData } from "../../../../modules/actions/biDashboardActions";
import LinkPartner from "./LinkPartner";
import ManageCards from "./ManageCards";
import { partners } from "../Functions/common";
import { USER_TYPES } from "../../../../utils/userTypes";
import { useLocation } from "react-router-dom";

function SettingDialog() {
	const dispatch = useDispatch();
	const openSettings = useSelector((store) => store?.biDashboard?.openSettings);
	const GOOGLE_SHEET_CLIENT_ID = process.env.REACT_APP_PARTNER_GOOGLE_SHEET_CLIENT_ID;
	const SUCCESS_REDIRECTION_URL = process.env.REACT_APP_PARTNER_REDIRECTION_SUCCESS;
	const location = useLocation();
	const urlSearchParams = new URLSearchParams(location?.search);
	const GOOGLE_SHEET_AUTH_TOKEN = urlSearchParams.get("code");
	const gridType = location.pathname.split("/").pop();

	const handleClose = () => {
		dispatch(setBIDashboardData({ openSettings: false }));
	};

	const handleGoogleSheetAuthentication = () => {
		const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${GOOGLE_SHEET_CLIENT_ID}&redirect_uri=${SUCCESS_REDIRECTION_URL}/google-sheet&response_type=code&scope=https://www.googleapis.com/auth/spreadsheets&include_granted_scopes=true&access_type=offline`;

		window.open(authUrl, "_blank");
	};

	useEffect(() => {
		if (gridType === "google-sheet" && GOOGLE_SHEET_AUTH_TOKEN) {
			dispatch(setBIDashboardData({ openGoogleSheetDialog: true, openSettings: false }));
		} else {
			dispatch(setBIDashboardData({ openGoogleSheetDialog: false, openSettings: false, openExcelDialog: false }));
		}
	}, []);

	const onConnectClick = (partnerKey) => {
		if (partnerKey === "excel") {
			dispatch(setBIDashboardData({ openExcelDialog: true, openSettings: false }));
		} else if (partnerKey === "googleSheets") {
			handleGoogleSheetAuthentication();
		} else {
			dispatch(setBIDashboardData({ openConnect: true, linkPartnerkey: partnerKey }));
		}
	};
	return (
		<CommonSlideDialog
			open={openSettings}
			disableBackDropClick
			onClose={handleClose}
			title={
				<Box sx={{ display: "flex", flexDirection: "column", rowGap: 1 }}>
					<Typography variant="Text/md/Semibold">Settings</Typography>
				</Box>
			}
			maxWidth="md"
			titleVariant="poppinsSemiBold30"
			titleStyle={{ px: { xs: 2, md: 5 }, pt: { xs: 2, md: 5 }, pb: 3, alignItems: "baseline" }}
			contentStyle={{ px: { xs: 2, md: 5 }, pb: 3, flex: 1, display: "flex" }}
			fullWidth
			PaperProps={{
				sx: {
					position: "fixed",
					bottom: 0,
					right: 0,
					top: 0,
					m: 0,
					borderRadius: "24px 0px 0px 24px",
					boxShadow: "0px 10px 25px 0px rgba(0, 0, 0, 0.05)",
					minHeight: 1,
					width: "100%",
				},
			}}
			footerStyle={{ px: { xs: 2, md: 5 }, pb: { xs: 2, md: 2 }, pt: 3 }}
			dialogAction={
				<Box sx={{ display: "flex" }}>
					<Button
						variant="DS1"
						onClick={() => {
							handleClose();
						}}
					>
						Close
					</Button>
				</Box>
			}
		>
			<Box display="flex" flexDirection="column" rowGap={3} flex={1}>
				<Box>
					<Typography variant="Text/sm/Semibold">Linked Partners</Typography>
					<Box display="flex" flexDirection="column" rowGap={2} mt={3}>
						{partners.map((partner) => {
							const isAvailable = partner.isComplete;
							return (
								<LinkPartner
									key={partner.key}
									name={partner.displayName}
									picture={partner.picture}
									onClick={() => onConnectClick(partner.key)}
									isAvailable={isAvailable}
								/>
							);
						})}
					</Box>
				</Box>
				{/* <Box>
						<Typography variant="Text/sm/Semibold">Manage Data</Typography>
						<Box mt={3}>
							<Grid container spacing={2}>
								<Grid item xs={12} sm={6}>
									<ManageCards name="Ownership & Fully Diluted Shares" />
								</Grid>
								<Grid item xs={12} sm={6}>
									<ManageCards name="Amount Raised by Type" />
								</Grid>
								<Grid item xs={12} sm={6} md={4}>
									<ManageCards name="Customers" />
								</Grid>
								<Grid item xs={12} sm={6} md={4}>
									<ManageCards name="ARR" />
								</Grid>
								<Grid item xs={12} sm={6} md={4}>
									<ManageCards name="Net New ARR" />
								</Grid>
								<Grid item xs={12} sm={6} md={4}>
									<ManageCards name="MRR" />
								</Grid>
								<Grid item xs={12} sm={6} md={4}>
									<ManageCards name="CAC Payback Period" />
								</Grid>
								<Grid item xs={12} sm={6} md={4}>
									<ManageCards name="Burn & Runway" />
								</Grid>
							</Grid>
						</Box>
					</Box> */}
			</Box>
		</CommonSlideDialog>
	);
}

export default SettingDialog;
