import { Tab, Tabs, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

function FounderTrendGraphTabController({ RANGE_SELECTION, handleChangeSetData }) {
	const [value, setValue] = useState("1M");
	const dispatch = useDispatch();

	const handleChange = (event, newValue) => {
		setValue(newValue);
        handleChangeSetData?.(newValue);
	};

	useEffect(() => {
		setValue(RANGE_SELECTION);
	}, [RANGE_SELECTION]);

	return (
		<Tabs
			value={value}
			onChange={handleChange}
			sx={{
				alignItems: "stretch",
				"& .MuiTabs-flexContainer": {
					gap: 2,
				},
				"& .MuiTabs-indicator": {
					backgroundColor: (theme) => theme.palette.primary.main,
					borderRadius: "5px",
					height: "4px",
				},
			}}
		>
			<Tab
				value="1M"
				disableRipple
				sx={{
					textTransform: "unset",
					textAlign: "center",
					p: 0,
					minWidth: "unset",
				}}
				label={
					<Typography
						variant="customValue"
						sx={{
							color: "black",
							fontFamily: "PoppinsMedium",
							fontSize: "18px",
							lineHeight: "27px",
						}}
					>
						1M
					</Typography>
				}
			/>
			<Tab
				value="3M"
				disableRipple
				sx={{
					textTransform: "unset",
					textAlign: "center",
					p: 0,
					minWidth: "unset",
				}}
				label={
					<Typography
						variant="customValue"
						sx={{
							color: "black",
							fontFamily: "PoppinsMedium",
							fontSize: "18px",
							lineHeight: "27px",
						}}
					>
						3M
					</Typography>
				}
			/>
			<Tab
				value="6M"
				disableRipple
				sx={{
					textTransform: "unset",
					textAlign: "center",
					p: 0,
					minWidth: "unset",
				}}
				label={
					<Typography
						variant="customValue"
						sx={{
							color: "black",
							fontFamily: "PoppinsMedium",
							fontSize: "18px",
							lineHeight: "27px",
						}}
					>
						6M
					</Typography>
				}
			/>
			<Tab
				value="1Y"
				disableRipple
				sx={{
					textTransform: "unset",
					textAlign: "center",
					p: 0,
					minWidth: "unset",
				}}
				label={
					<Typography
						variant="customValue"
						sx={{
							color: "black",
							fontFamily: "PoppinsMedium",
							fontSize: "18px",
							lineHeight: "27px",
						}}
					>
						1Y
					</Typography>
				}
			/>
			<Tab
				value="2Y"
				disableRipple
				sx={{
					textTransform: "unset",
					textAlign: "center",
					p: 0,
					minWidth: "unset",
				}}
				label={
					<Typography
						variant="customValue"
						sx={{
							color: "black",
							fontFamily: "PoppinsMedium",
							fontSize: "18px",
							lineHeight: "27px",
						}}
					>
						2Y
					</Typography>
				}
			/>
		</Tabs>
	);
}

export default FounderTrendGraphTabController;
