import services from "../services";

export function getConversationWithID({ friendlyName, idArray }) {
  return new Promise((resolve, reject) => {
    try {
      services
        .getConversationByUserIDServiceCall({ friendlyName, idArray })
        .then(resp => {
          if (resp?.data?.data?.conversationId) {
            resolve(resp?.data?.data?.conversationId);
          } else {
            reject("No conversation ID");
          }
        })
        .catch(e => {
          throw e;
        });
    } catch (e) {
      console.log("Error in message helper.", e);
      reject(e);
    }
  });
}
