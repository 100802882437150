import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Button, Typography, Box, Badge, Avatar, CircularProgress, Paper, IconButton } from "@mui/material";

import { PPT_UPLOAD, PITCH_DECK_LOGO } from "../../constants/image";
import Dropzone from "react-dropzone";
import services from "../../services";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import LoadingButton from "../Loadingbutton";
import DialogComponent from "../../containers/Workspace/PowerUP/IdeaValidation/Components/Dialog";
import MessageHandlerModal from "../modal/MessageHandler";
import { toastContainer } from "../../utils/toast";
import ImageIcon from "@mui/icons-material/Image";
import CloseIcon from "@mui/icons-material/Close";
import { handleMixPanel } from "../../utils/mixPanelEventHandling";

export default function UploadFileModal({
	open,
	onClose,
	backgroundPhoto,
	setFile,
	file,
	title,
	selectFileText,
	dimensionText,
	aspectRatio,
	fileType,
	circularCropper,
	updatePayload,
}) {
	const [openPreview, setOpenPreview] = useState(false);
	const [localFile, setLocalFile] = useState();
	const [loading, setLoading] = useState(false);
	const [cropData, setCropData] = useState("");
	const [cropper, setCropper] = useState();
	const [loader, setLoader] = useState(false);
	const [uploadedImg, setUploadedImg] = useState();
	const [fileData, setFileData] = useState();

	const [deleteConfirmPopup, setDeleteConfirmPopup] = useState(false);
	const [pitchType, setPitchType] = useState("");
	const userData = useSelector((store) => ({
		userId: store?.auth?.userId,
		userName: store?.auth?.userName,
	}));
	const { isPremiumPlanAccess, userType } = useSelector((store) => store?.auth);

	useEffect(() => {
		if (file) {
			let type = file.substr(file.length - 3);
			if (type === "pdf") {
				setPitchType("pdf");
			} else {
				setPitchType("ppt");
			}
		}
	}, [file]);

	const handleFileSelect = (file) => {
		if (title === "Pitch Perfect Pitch Deck") {
			handleMixPanel("Startup Profile Edited", {
				userName: userData.userName,
				userId: userData.userId,
				subscription_status: isPremiumPlanAccess ? "Subscribed" : "Not Subscribed",
				user_type: userType,
			});
		}

		if (file) {
			setLoader(true);
			setLoading(true);
			const fileSize = (file?.size / 1024 / 1024).toFixed(2);

			if (fileSize < 500) {
				setLocalFile(file);
				setCropData(file);
				setOpenPreview(true);
				getFiles(file, "pitch");
			} else {
				toastContainer("Please Upload file less than 500MB", "error");
				setLoader(false);
				setLoading(false);
			}
		}
	};

	const handleSave = () => {
		if (typeof cropper !== "undefined") {
			setFile(uploadedImg);
			setCropData("");
			setLocalFile("");
			onClose();
		} else {
			onClose();
			setCropData("");
			setLocalFile("");
		}
	};

	const getFiles = async (data, type) => {
		var formData = new FormData();
		formData.append("file", data);
		formData.append("fileGroup", "LOGO");

		if (formData) {
			services
				.uploadFile(formData)
				.then((response) => {
					setFileData(response.data.data);
					setFile(response.data.data?.fileLocation);
					updatePayload(response.data.data?.fileLocation);
					setUploadedImg(response.data.data?.fileLocation);
					setLoading(false);
					setLoader(false);

					toastContainer("File uploaded successfully", "success");
				})
				.catch((e) => {
					const message = e.response?.data?.message;
					toastContainer(message, "error");
					setLoader(false);
				});
		}
	};

	const deleteFileHandler = () => {
		setFile("");
		setLocalFile("");
		updatePayload("");
		setCropData("");
		setDeleteConfirmPopup(false);
	};

	const removeLink = (e) => {
		e.stopPropagation();
		deleteFileHandler();
		updatePayload("");
	};

	return (
		<Box>
			{" "}
			<DialogComponent
				disableBackDropClick={true}
				title={title}
				titleVariant={"title_large"}
				isOpen={open}
				closeButton={!loader}
				onClose={() => {
					onClose();
					setCropData("");
					setLocalFile("");
				}}
				contentStyle={{ px: 0 }}
				maxWidth={"md"}
				fullWidth
				footerStyle={{
					border: loader && "none",
				}}
				PaperProps={{
					sx: (theme) => ({
						borderRadius: theme.shape.standard.borderRadius,
					}),
				}}
				dialogAction={
					<>
						{!loader &&
							(cropData || file ? (
								<Box sx={{ mr: "auto" }}>
									{" "}
									<Button
										variant="link"
										sx={{ color: (theme) => theme.palette.primary.sysLight }}
										onClick={() => {
											setDeleteConfirmPopup(true);
										}}
									>
										Delete
									</Button>
								</Box>
							) : (
								<Box sx={{ display: "flex", gap: 1, m: 2 }}>
									<Box>
										<Button
											variant="outlined"
											onClick={() => {
												setCropData("");
												setLocalFile("");
												onClose();
											}}
											sx={{ width: "max-content", whiteSpace: "nowrap" }}
										>
											Cancel
										</Button>
									</Box>

									<Button
										sx={{ mr: 1 }}
										onClick={() => {
											handleSave();
										}}
									>
										Save
									</Button>
								</Box>
							))}
					</>
				}
			>
				<Box sx={{ px: 3, pt: 2 }}>
					{!loader && !cropData && !file && (
						<Typography variant="onsurface_subtext_light" sx={{ color: (theme) => theme.palette.secondary.black }}>
							Upload local file
						</Typography>
					)}

					<Dropzone
						onDrop={(acceptedFiles) => {
							handleFileSelect(acceptedFiles[0]);
						}}
						accept={fileType}
						disabled={loader}
					>
						{({ getRootProps, getInputProps }) => (
							<section>
								<div {...getRootProps()}>
									<input {...getInputProps()} />
									{cropData || file ? (
										loader ? (
											<Box justifyContent={"center"} sx={{ display: "flex", mt: 7, minHeight: "150px" }}>
												<Box>
													<Box
														sx={(theme) => ({
															display: "flex",
															justifyContent: "center",
														})}
													>
														<CircularProgress
															sx={(theme) => ({
																height: "80px",
																width: "80px",
																color: theme.palette.text.darkBlue,
															})}
														/>
													</Box>
													<Box
														sx={(theme) => ({
															textAlign: "center",
															pt: 5,
														})}
													>
														<Typography variant="title_medium">Pitch Deck upload is in progress</Typography>
													</Box>
												</Box>
											</Box>
										) : (
											<Box
												sx={{
													display: "flex",
													justifyContent: "center",
													flexDirection: "column",
													gap: 3,
												}}
											>
												<div
													className="App"
													style={{
														display: "flex",
														justifyContent: "center",
														width: "100%",
													}}
												>
													{pitchType === "ppt" && (
														<iframe
															src={`https://view.officeapps.live.com/op/embed.aspx?src=${file}`}
															width="100%"
															height="400px"
															frameBorder="0"
															title="slides"
														></iframe>
													)}

													{pitchType === "pdf" && <iframe src={file} width="100%" height="400px" frameBorder="0"></iframe>}
												</div>

												{localFile && (
													<Paper
														sx={{
															display: "flex",
															flexDirection: "row",
															width: 1,
															justifyContent: "space-between",
															alignItems: "center",
														}}
														elevation={1}
													>
														<Box
															sx={{
																display: "flex",
																flexDirection: "row",
																gap: 2,
																alignItems: "center",
																p: 1,
															}}
														>
															<Box
																sx={{
																	background: (theme) => theme.palette.primary.sys_light_background,
																	borderRadius: (theme) => theme.shape.standard22,
																}}
															>
																<ImageIcon
																	sx={{
																		color: (theme) => theme.palette.primary.sysLight,
																		p: 1,
																	}}
																/>
															</Box>
															<Box
																sx={{
																	display: "flex",
																	flexDirection: "column",
																	gap: 1,
																}}
															>
																<Typography variant="lable_large">{fileData?.fileName}</Typography>
																<Typography variant="required_text">{fileData.size}</Typography>
															</Box>
														</Box>
														<Box>
															<IconButton onClick={removeLink}>
																<CloseIcon />
															</IconButton>
														</Box>
													</Paper>
												)}
											</Box>
										)
									) : (
										<Box
											sx={(theme) => ({
												border: "1px dashed rgba(106, 117, 117, 1)",
												minHeight: "150px",
												borderRadius: theme.shape.standard.standard_small,
												mt: 1,
												textAlign: "center",
												p: 5,
												cursor: "pointer",
											})}
										>
											<Box component={"img"} src={PPT_UPLOAD} alt="img"></Box>
											<Typography
												variant="label_xlarge"
												sx={{
													mt: 1,
													display: "grid",
													color: (theme) => theme.palette.secondary.korophKoal,
												}}
											>
												{" "}
												{selectFileText}
											</Typography>
											<Typography
												variant="lable_large"
												sx={{
													mt: 1,
													display: "grid",
													color: (theme) => theme.palette.secondary.outline,
												}}
											>
												{" "}
												or drag and drop it here
											</Typography>
											<Typography
												variant="lable_large"
												sx={{
													mt: 1,
													display: "grid",
													color: (theme) => theme.palette.secondary.outline,
												}}
											>
												{dimensionText}
											</Typography>
										</Box>
									)}
								</div>
							</section>
						)}
					</Dropzone>
				</Box>
			</DialogComponent>
			<MessageHandlerModal
				isOpen={deleteConfirmPopup}
				onCancel={() => setDeleteConfirmPopup(false)}
				onOk={() => {
					deleteFileHandler();
				}}
				okText="Delete"
				cancelText={"No thanks"}
				heading={`Delete ${title}`}
				text={`Are you sure you want to delete the ${title}?`}
				messageType="primary"
			/>
		</Box>
	);
}
