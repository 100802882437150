import React from "react";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { Box } from "@mui/material";
import { useDispatch } from "react-redux";
import { setBIDashboardData } from "../../../../modules/actions/biDashboardActions";
import ConnectConfirm from "./ConnectConfirm";
import SettingDialog from "./SettingDialog";
import ExcelDialog from "./ExcelDialog";
import GoogleSheetDialog from "./GoogleSheetDialog";

function StartupGridSettings() {
	const dispatch = useDispatch();
	return (
		<>
			<Box
				sx={{
					borderRadius: "100px",
					width: "48px",
					height: "48px",
					backgroundColor: (theme) => theme.palette.primary.main,
					cursor: "pointer",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					ml: 1,
				}}
				onClick={() => {
					dispatch(setBIDashboardData({ openSettings: true }));
				}}
			>
				<SettingsOutlinedIcon sx={{ color: (theme) => theme.palette.secondary.white }} />
			</Box>
			<SettingDialog />
			<ConnectConfirm />
			<ExcelDialog />
			<GoogleSheetDialog />
		</>
	);
}

export default StartupGridSettings;
