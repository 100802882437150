import styled from "@emotion/styled";
import { TextField } from "@mui/material";

export const DateTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    color: theme.palette.text.primary,
    fontSize: 14,
    fontFamily: "RobotoRegular",
    fontWeight: 400,
    lineHeight: 22,
    borderColor: theme.palette.secondary.dark,
  },
  "& .css-dktpqr-MuiInputBase-input-MuiOutlinedInput-input": {
    cursor: "pointer",
  },
}));
