import React, { useState, useEffect } from "react";
import { Box, CircularProgress } from "@mui/material";

import services from "../../../services";
import { toastContainer } from "../../../utils/toast";
import { CustomizePackage } from "./CustomizePackage";
import { Checkout } from "./Checkout";

export const QuoteStep = ({ applicationId }) => {
	const [loading, setLoading] = useState(false);
	const [quotes, setQuotes] = useState([]);
	const [isCheckout, setIsCheckout] = useState(false);

	useEffect(() => {
		if (applicationId !== "" && quotes.length === 0) {
			getQuotes();
		}
	}, [applicationId, quotes.length]);

	const getQuotes = async () => {
		setLoading(true);
		try {
			const quotes = await services.getVouchQuotes(applicationId);
			setQuotes(quotes.data.data?.quotes);
		} catch (error) {
			toastContainer(error?.data?.message || "Something is wrong for API side", "error");
		}
		setLoading(false);
	};

	const handleCheckout = handler => {
		setIsCheckout(handler);
	};

	if (loading || quotes.length === 0) {
		return (
			<Box display={"flex"} justifyContent="center" alignItems={"center"} height="600px">
				<CircularProgress />
			</Box>
		);
	}

	return (
		<>
			{!isCheckout ? (
				<CustomizePackage quotes={quotes} applicationId={applicationId} onCheckout={handleCheckout} />
			) : (
				<Checkout quotes={quotes} onBack={handleCheckout} />
			)}
		</>
	);
};
