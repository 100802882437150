import React, { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { Box, Popover, Stack, Typography } from "@mui/material";
import SavedSearchesComponent from "../../../SavedSearches/SavedSearchesDialog";
import { InvestorDashboardContext } from "../../../../../containers/Investor/Dashboard";
import { handleMixPanel } from "../../../../../utils/mixPanelEventHandling";

function SavedSearches() {
	const { savedFilterPopoverOpen, setSavedFilterPopoverOpen } = useContext(InvestorDashboardContext);
	const { isInvestorLocked, isAdmin } = useSelector((state) => state?.auth);

	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleClick = (event) => {
		if (!isInvestorLocked || isAdmin) {
			handleMixPanel("Opportunity Dashboard: Saved Searches Clicked");
			setAnchorEl(event.currentTarget);
		}
	};

	const handleClose = () => {
		setSavedFilterPopoverOpen(false);
	};

	useEffect(() => {
		setSavedFilterPopoverOpen(Boolean(anchorEl));
	}, [anchorEl]);

	useEffect(() => {
		if (!savedFilterPopoverOpen) {
			setAnchorEl(null);
		}
	}, [savedFilterPopoverOpen]);

	return (
		<Box display={"block"} onClick={handleClick}>
			<Stack>
				<Box
					display={"flex"}
					alignItems={"center"}
					gap={1.5}
					sx={{
						opacity: isInvestorLocked && !isAdmin ? "0.6" : "1",
						background: savedFilterPopoverOpen ? "rgba(0, 0, 0, 1)" : "rgba(242, 244, 247, 1)",
						borderRadius: "100px",
						p: "12px 20px 12px 20px",
					}}
				>
					<Typography variant="Text/sm/Semibold" sx={{ color: savedFilterPopoverOpen ? "white" : "black" }}>
						Saved Searches
					</Typography>{" "}
				</Box>
			</Stack>
			<Popover
				elevation={1}
				open={savedFilterPopoverOpen}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "center",
				}}
				PaperProps={{
					sx: {
						boxShadow: "0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)",
						borderRadius: "20px",
						padding: "20px",
						width: "350px",
					},
				}}
			>
				<SavedSearchesComponent open={savedFilterPopoverOpen} closePopover={handleClose} />
			</Popover>
		</Box>
	);
}

export default SavedSearches;
