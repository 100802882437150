import React, { createContext, useState, useEffect } from "react";
import { Box, Grid, Button, Stack } from "@mui/material";
import ProfilePhotoSection from "./ProfilePhotoSection";
import { useSelector, useDispatch } from "react-redux";
import LoadingButton from "../../../common/Loadingbutton";
import services from "../../../services";
import { setUserProfileData } from "../../../modules/actions/userProfileActions";
import { toastContainer } from "../../../utils/toast";
import messages from "../../../constants/messages";
import { setAuthData } from "../../../modules/actions";
import ProfileCoPilot from "../../ProfileCoPilot";
import { getRoleValue } from "../../../utils/commonFunctions";

const MyPhotosTabContext = createContext();

const MyPhotosSection = ({ handleClose }) => {
	const dispatch = useDispatch();
	const userDetails = useSelector((state) => state?.userProfileData?.userData);
	const [payLoad, setPayLoad] = useState({ ...userDetails });
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (userDetails) {
			setPayLoad({ ...userDetails });
		}
	}, [userDetails]);

	const handleUpdateUser = () => {
		setIsLoading(true);
		let payloadData = payLoad ? payLoad : userDetails;

		services
			.updateUserProfile({
				...payloadData,
				phone: payLoad.phone,
			})
			.then((response) => {
				setIsLoading(false);
				if (response.data.message === "Success") {
					dispatch(
						setUserProfileData({
							userData: response?.data?.data,
						})
					);
				}
			})
			.catch((e) => {
				console.log("Submit", e);
				setIsLoading(false);
			});
	};
	const handleAISave = async (aiData, eventType) => {
		let payloadData = null;

		if (eventType && eventType == "ai_suggestions") {
			payloadData = payLoad;
			payloadData = {
				...payloadData,
				about: aiData?.about ? aiData?.about : payloadData?.about,
			};
		} else {
			payloadData = aiData ? aiData : payLoad;
		}

		return services
			.updateUserProfile({
				...payloadData,
			})
			.then((response) => {
				if (response.data.message === "Success") {
					dispatch(
						setUserProfileData({
							userData: response.data.data,
						})
					);
					if (eventType === "ai_suggestions") {
						handleClose();
					}
					dispatch(
						setAuthData({
							userData: { picture: response.data.data.picture },
							userName: response.data.data.name,
						})
					);
					setPayLoad(response.data.data);
					return response;
				}
			})
			.catch((e) => {
				console.log("Submit", e);
				toastContainer(messages.API_SUBMIT_ERROR, "error");
			});
	};
	return (
		<MyPhotosTabContext.Provider
			value={{
				payLoad,
				setPayLoad,
				setIsLoading,
			}}
		>
			<Box>
				<Grid container>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<ProfilePhotoSection context={MyPhotosTabContext} />
					</Grid>
				</Grid>
				<Box
					sx={{
						display: "flex",
						gap: 2,
						justifyContent: "end",
						alignItems: "center",
						// px: 5,
						mt: 3,
					}}
				>
					<Button
						onClick={handleClose}
						variant="noOutlineNew"
						sx={{
							height: "40px",
							m: 0,
							width: "fit-content",
							fontSize: "18px",
							lineHeight: "28px",
							fontFamily: "PoppinsSemiBold",
						}}
					>
						Cancel
					</Button>

					<ProfileCoPilot
						handleSave={handleUpdateUser}
						handleAISave={handleAISave}
						saveLoading={isLoading}
						saveDisabled={false}
						handleCloseAI={handleClose}
					/>
				</Box>
			</Box>
		</MyPhotosTabContext.Provider>
	);
};

export default MyPhotosSection;
