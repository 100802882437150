import { Box, Divider, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DoneIcon from "@mui/icons-material/Done";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PORTFOLIO_ICON } from "../../../../constants/image";
import { GREY_SCOUTING_ICON, SCOUTING_ICON, WATCHLIST_ICON, UPDATED_PORTFOLIO_ICON, UPDATED_WATCHLIST_ICON } from "../../../../constants";
import { AddIconComp } from "../../../../containers/Investor/Dashboard/AllStartups/styles";
import { setInvestorDashboardData } from "../../../../modules/actions";
import { generatePath, useHistory } from "react-router-dom";
import ROUTES_PATH from "../../../../constants/routes";
import ProfileWatchlistSection from "../../../Investor/ProfileWatchlistSection";
import services from "../../../../services";
import { addOrRemoveCompanyToPortfolioOrWatchlist } from "../../../../containers/Investor/Dashboard/Functions/common";
import { setSlidingProfileData } from "../../../../modules/actions/slidingProfileActions";

function BenchmarkHeaderNavigation() {
	const STARTUP = useSelector((state) => state?.investorDashboard?.openStartupObj);

	// Declaring values for component to watch. On change will update component UI.
	const WATCHLIST = useSelector((state) => state?.investorDashboard?.openStartupObj?.inWatchlist);
	const PORTFOLIO = useSelector((state) => state?.investorDashboard?.openStartupObj?.inPortfolio);
	const investorDashboardData = useSelector((state) => state?.investorDashboard);
	const userData = useSelector((store) => store?.auth);

	const dispatch = useDispatch();
	const history = useHistory();

	const toggleOpenScoutingReport = () => {
		dispatch(
			setInvestorDashboardData({
				scoutingOpen: true,
				benchmarkDetailsOpen: false,
				openStartupObj: STARTUP,
			})
		);
	};

	const addCompanyToWatchlistOrPortfolioApiCall = (companyId, type) => {
		addOrRemoveCompanyToPortfolioOrWatchlist({ entityIds: [companyId], collectionType: type, isRemove: false })
			.then((result) => {
				const tempStartupList = investorDashboardData?.startupList;
				tempStartupList.map((startupItem) => {
					if (startupItem.id === STARTUP.id) {
						if (type === "PORTFOLIO") {
							startupItem.inPortfolio = true;
						} else {
							startupItem.inWatchlist = true;
						}
					}
				});
				dispatch(setInvestorDashboardData({ startupList: [...tempStartupList] }));
			})
			.catch((err) => console.log(err));
	};

	const handlePortfolioAndWatchlistDrawerActions = (type) => {
		if (type === "PORTFOLIO") {
			if (!STARTUP?.inPortfolio) {
				addCompanyToWatchlistOrPortfolioApiCall(STARTUP?.id, "PORTFOLIO");
			} else {
				let portfolioObj = {
					title: "My Portfolio",
					text: STARTUP?.inPortfolio ? "Removal Confirmation" : "Confirmation",
					subText: STARTUP?.inPortfolio
						? "Please confirm that you are removing this startup from your portfolio."
						: "Please confirm that you have invested in this startup, and would like to add it to you portfolio.",
					primaryButtonText: STARTUP?.inPortfolio ? "Remove" : "Add",
					secondaryButtonText: "Cancel",
					type: type,
				};
				dispatch(
					setInvestorDashboardData({
						slidingConfirmationObject: portfolioObj,
						openStartupObj: STARTUP,
						removeMyPortfolioOpen: STARTUP?.inPortfolio,
						addMyPortfolioOpen: !STARTUP?.inPortfolio,
					})
				);
			}
		} else {
			if (!STARTUP?.inWatchlist) {
				addCompanyToWatchlistOrPortfolioApiCall(STARTUP?.id, "WATCHLIST");
			} else {
				let watchlistObj = {
					title: "My Watchlist",
					text: STARTUP?.inWatchlist ? "Removal Confirmation" : "Confirmation",
					subText: STARTUP?.inWatchlist
						? "Please confirm that you are removing this startup from your watchlist."
						: "Please confirm that you have invested in this startup, and would like to add it to you watchlist.",
					primaryButtonText: STARTUP?.inWatchlist ? "Remove" : "Add",
					secondaryButtonText: "Cancel",
					type: type,
				};
				dispatch(
					setInvestorDashboardData({
						slidingConfirmationObject: watchlistObj,
						openStartupObj: STARTUP,
						removeWatchlistOpen: STARTUP?.inWatchlist,
						addWatchlistOpen: !STARTUP?.inWatchlist,
					})
				);
			}
		}
	};

	return (
		<Box
			sx={(theme) => ({
				display: "flex",
				flexWrap: "nowrap",
			})}
			gap={2}
		>
			<Box display={"flex"} gap={1}>
				{/* MY PORTFOLIO */}
				{/* MY PORTFOLIO */}
				{/* MY PORTFOLIO */}
				<ProfileWatchlistSection
					status={STARTUP?.inPortfolio}
					iconSrc={UPDATED_PORTFOLIO_ICON}
					name="My Portfolio"
					handleOnClickSection={() => {
						handlePortfolioAndWatchlistDrawerActions("PORTFOLIO");
					}}
				/>
				<Divider
					orientation="vertical"
					sx={{
						height: "40px",
					}}
				/>
				<ProfileWatchlistSection
					status={STARTUP?.inWatchlist}
					iconSrc={UPDATED_WATCHLIST_ICON}
					name="Watchlist"
					handleOnClickSection={() => {
						handlePortfolioAndWatchlistDrawerActions("WATCHLIST");
					}}
				/>
				{/* <Box
					display={"flex"}
					alignItems="center"
					gap={1}
					onClick={() => {
						handlePortfolioAndWatchlistDrawerActions("PORTFOLIO");
					}}
					sx={{
						position: "relative",
						opacity: STARTUP?.inPortfolio ? "1" : "0.3",
						cursor: "pointer",
						"&:hover": {
							opacity: "1",
							"& .add-icon-hover": {
								color: "white",
							},
							"& .hovered": {
								background: (theme) => theme.palette.primary.primaryPurple,
							},
						},
					}}
				>
					<Box
						component={"img"}
						sx={{
							position: "relative",
							height: "26px",
							width: "26px",
						}}
						src={PORTFOLIO_ICON}
						alt="portfolio"
					/>
					<AddIconComp className="hovered" isActive={STARTUP?.inPortfolio}>
						{STARTUP?.inPortfolio ? (
							<DoneIcon
								sx={{ background: STARTUP?.inPortfolio && ((theme) => theme.palette.primary.primaryPurple) }}
								className="add-icon-hover"
								fontSize="12px"
							/>
						) : (
							<AddIcon
								sx={{ color: "#797979", background: STARTUP?.inPortfolio && ((theme) => theme.palette.primary.primaryPurple) }}
								className="add-icon-hover"
								fontSize="12px"
							/>
						)}
					</AddIconComp>
					<Typography variant="title_portfolio" color="#797979">
						My Portfolio
					</Typography>{" "}
				</Box> */}
				{/* MY PORTFOLIO */}
				{/* MY PORTFOLIO */}
				{/* MY PORTFOLIO */}

				{/* <Box
					display={"flex"}
					alignItems="center"
					gap={1}
					onClick={() => {
						handlePortfolioAndWatchlistDrawerActions("WATCHLIST");
					}}
					sx={{
						position: "relative",
						opacity: STARTUP?.inWatchlist ? "1" : "0.3",
						cursor: "pointer",

						"&:hover": {
							opacity: "1",
							"& .add-icon-hover": {
								color: "white",
							},
							"& .hovered": {
								background: (theme) => theme.palette.primary.primaryPurple,
							},
						},
					}}
				>
					<Box component={"img"} sx={{ height: "26px", width: "26px" }} src={WATCHLIST_ICON} alt="portfolio" />
					<AddIconComp className="hovered" isActive={STARTUP?.inWatchlist}>
						{STARTUP?.inWatchlist ? (
							<DoneIcon
								sx={{ background: STARTUP?.inWatchlist && ((theme) => theme.palette.primary.primaryPurple) }}
								className="add-icon-hover"
								fontSize="12px"
							/>
						) : (
							<AddIcon className="add-icon-hover" fontSize="12px" sx={{ color: "#797979" }} />
						)}
					</AddIconComp>
					<Typography variant="title_portfolio" color="#797979">
						Watchlist
					</Typography>
				</Box> */}
			</Box>
			<Divider
				orientation="vertical"
				sx={(theme) => ({
					height: "40px",
				})}
			/>
			<Box display={"flex"} gap={1} alignItems="center">
				<Box
					display={"flex"}
					alignItems={"center"}
					gap={1}
					onClick={() => {
						dispatch(
							setSlidingProfileData({
								open: true,
								profileType: "STARTUP_PROFILE",
								profileId: STARTUP?.id,
							})
						);
						// toggleOpenScoutingReport(true);
					}}
					sx={{
						cursor: "pointer",
						"&:hover": {
							opacity: "1",
							"& .scouting-icon": {
								background: (theme) => theme.palette.primary.primaryPurple,
								borderRadius: "50%",
								opacity: "1",
								height: "36px",
								width: "36px",
								textAlign: "center",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							},
							"& .scouting-text": {
								opacity: "1",
								color: (theme) => theme.palette.primary.primaryPurple,
							},
							"& .scouting-icon img": {
								filter: "none",
							},
						},
						"& .scouting-icon img": {
							filter: "invert(1) blur(0px)",
						},
					}}
					onMouseEnter={() => {
						let scoutingImg = document.getElementById(`scoutingImg${STARTUP?.id}`);
						scoutingImg.src = SCOUTING_ICON;
					}}
					onMouseLeave={() => {
						let scoutingImg = document.getElementById(`scoutingImg${STARTUP?.id}`);
						scoutingImg.src = GREY_SCOUTING_ICON;
					}}
				>
					<Box
						className="scouting-icon"
						sx={{
							borderRadius: "50%",
							height: "36px",
							width: "36px",
							textAlign: "center",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<Box
							id={`scoutingImg${STARTUP?.id}`}
							component={"img"}
							sx={{ height: "24px", width: "24px" }}
							src={GREY_SCOUTING_ICON}
							alt="scouting"
						/>
					</Box>

					<Typography className="scouting-text" variant="card_text" color="secondary.black">
						View Startup
						{/* Scouting */}
					</Typography>
				</Box>
			</Box>
		</Box>
	);
}

export default BenchmarkHeaderNavigation;
