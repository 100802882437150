import { Accordion, AccordionDetails, AccordionSummary, Avatar, Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const MUIAccordion = styled(Accordion)(({ theme, background = theme.palette.secondary.white }) => ({
	background: background,
	boxShadow: "none",
}));

export const MUIAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
	margin: "0px",
	minHeight: "20px !important",
	height: "0px",
	paddingLeft: "0px",
	"& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
		transform: "rotate(0deg)",
	},
	"& .MuiAccordionSummary-expandIconWrapper": {
		transform: "rotate(90deg)",
	},
}));

export const MUIAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
	paddingLeft: "0px",
}));
