import { ApolloClient, HttpLink, InMemoryCache, from } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { store } from "../modules/index";

let apiVersion = process.env.REACT_APP_API_URL;

const errorLink = onError(({ graphQLErrors, networkError }) => {
	if (graphQLErrors)
		graphQLErrors.forEach(({ message, locations, path }) => console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`));
	if (networkError) console.log(`[Network error]: ${networkError}`);
});

const httpLink = new HttpLink({ uri: `${apiVersion}/api/m1/v1/graphql` });

const authLink = setContext((_, { headers }) => {
	// get the authentication token from redux store if it exists
	const token = store?.getState()?.auth?.idToken;
	const isAdmin = store?.getState()?.auth?.isAdmin;
	const impersonateProviderId = store?.getState()?.auth?.impersonateProviderId;

	// return the headers to the context so httpLink can read them
	if (isAdmin && impersonateProviderId) {
		return {
			headers: {
				...headers,
				authorization: token ? `Bearer ${token}` : "",
				impersonateUser: impersonateProviderId,
			},
		};
	}

	return {
		headers: {
			...headers,
			authorization: token ? `Bearer ${token}` : "",
		},
	};
});

const client = new ApolloClient({
	link: from([errorLink, authLink.concat(httpLink)]),
	cache: new InMemoryCache(),
});

export default client;
