import { conversationsMap } from "../../conversations-objects";
import { ActionType } from "../action-types";
const initialState = [];
const convoSorter = (a, b) =>
  (b.lastMessage?.dateCreated?.getTime() ?? b.dateUpdated?.getTime() ?? 0) -
  (a.lastMessage?.dateCreated?.getTime() ?? a.dateUpdated?.getTime() ?? 0);
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.ADD_CONVERSATION:
      const {
        sid,
        friendlyName,
        dateUpdated,
        notificationLevel,
        lastReadMessageIndex,
        lastMessage,
      } = action.payload.convo;
      const filteredClone = state.filter(
        conversation => conversation.sid !== action.payload.convo.sid
      );
      conversationsMap.set(action.payload.convo.sid, action.payload.convo);

      return [
        ...filteredClone,
        {
          sid,
          friendlyName,
          dateUpdated,
          notificationLevel,
          lastReadMessageIndex,
          lastMessage: {
            ...lastMessage,
          },
          startuposMeta: action.payload.startuposMeta,
        },
      ].sort(convoSorter);
    case ActionType.UPDATE_CONVERSATION: {
      const stateCopy = [...state];
      const target = stateCopy.find(
        convo => convo.sid === action.payload.channelSid
      );
      if (target) {
        Object.assign(target, {
          ...action.payload.parameters,
          startuposMeta: action.payload.startuposMeta,
        });
      }
      return stateCopy;
    }
    case ActionType.REMOVE_CONVERSATION: {
      const stateCopy = [...state];
      conversationsMap.delete(action.payload);
      return stateCopy.filter(convo => convo.sid !== action.payload);
    }
    case ActionType.SORT_CONVERSATIONS: {
      let pos = state.findIndex(conv => conv.sid === action.payload);
      if (pos > -1) {
        state.splice(0, 0, state[pos++]);
        state.splice(pos, 1);
      }
      return state;
    }
    case ActionType.CLEAR_ALL_CONVERSATION: {
      return initialState;
    }
    default:
      return state;
  }
};
export default reducer;
