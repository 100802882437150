import React, { useState, useEffect } from "react";
import { Grid, Typography, Box, useMediaQuery } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useHistory } from "react-router-dom";
import theme from "../../../../../../../constants/Theme";
//import { toastContainer } from "../../../../../utils/toast";
//import messages from "../../../../../../../constants/messages";
//import ROUTES_PATH from "../../../../../../../constants/routes";
import ResearchCuratorCard from "../../../../../../ResearchCuratorCard";
import services from "../../../../../../../services";

const PanelListView = ({
  panelCardData,
  handleCardClick,
  dataLength,
  setCallback,
  callback,
}) => {
  const history = useHistory();
  const [index, setIndex] = useState(0);
  const [activeStep, setActiveStep] = useState(0);
  const [savedPageIndex, setSavedPageIndex] = useState(1);
  const isMediumScreen = useMediaQuery(theme.breakpoints.only("md"));
  const perPage = 10;
  const [pageData, setPageData] = useState({ isLast: false });
  const [loading, setLoading] = useState(false);
  const [notesPageIndex, setNotesPageIndex] = useState(1);
  const [listItems, setListItems] = useState([]);
  const [lastElement, setLastElement] = useState(null);
  const [isPinned, setIsPinned] = useState(true);
  const [view, setView] = useState("MOSAIC");
  const [filter, setFilter] = useState({ type: "", tags: "" });
  const [sortBy, setSortBy] = useState({ key: "createdAt", order: "DESC" });
  const [searchText, setSearchText] = useState(null);
  const [refresh, setRefresh] = useState(callback);
  let slicedListItems = listItems.slice(activeStep, activeStep + 1);

  const remainingItems = listItems.length - (activeStep + 1);

  useEffect(() => {
    //Horizontal pagination on carousel
    if (
      activeStep === listItems.length - 1 &&
      notesPageIndex < pageData["totalPages"]
    ) {
      handlePaginationNext();
    }
  }, [activeStep]);

  const nextStep = () => {
    if (activeStep < listItems.length - 1) {
      setActiveStep(activeStep + 1);
    }
  };

  const prevStep = () => {
    if (activeStep > 0) {
      setActiveStep(activeStep - 1);
    }
  };

  const handlePaginationNext = () => {
    setSavedPageIndex(savedPageIndex => savedPageIndex + 1);
    setNotesPageIndex(notesPageIndex => notesPageIndex + 1);
    //setAppend(true);
  };

  const getPinnedNotesApiCall = () => {
    const tags = filter.tags;
    const types = filter.type;
    services
      .getPinnedNotesList(
        notesPageIndex,
        perPage,
        sortBy.order,
        sortBy.key,
        searchText,
        tags,
        types,
        isPinned
      )
      .then(result => {
        const data = result?.data?.data;
        setLoading(false);
        setPageData({
          totalPages: data?.totalPages || 0,
          totalElements: data.totalElements || 0,
          isFirst: data.isFirst,
          isLast: data.isLast,
        });

        const items = data?.models || [];

        if (searchText || filter || sortBy) {
          if (!data.isFirst) {
            setListItems(prev => [...prev, ...items]);
          } else {
            setListItems(items);
          }
        } else {
          setListItems(prev => [...prev, ...items]);
        }
        setRefresh(false);
        setCallback(false);
      })
      .catch(error => {
        setLoading(false);
        console.log(error);
      });
  };

  useEffect(() => {
    setLoading(true);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    getPinnedNotesApiCall();
  }, [panelCardData]);

  useEffect(() => {
    setLoading(true);
    if (!searchText) {
      setListItems([]);
      setPageData({ ...pageData, isLast: false, isFirst: true });

      setNotesPageIndex(1);
    } else {
      setPageData({ ...pageData, isLast: false, isFirst: true });
      setNotesPageIndex(1);
    }
  }, [searchText, filter, sortBy]);

  useEffect(() => {
    if (pageData.totalPages >= 0) {
      if (searchText || filter || sortBy) {
        if (pageData.totalPages === 0) {
          getPinnedNotesApiCall();
        } else {
          if (pageData.totalPages >= notesPageIndex) {
            getPinnedNotesApiCall();
          }
        }
      } else {
        if (!pageData.isLast) {
          getPinnedNotesApiCall();
        }
      }
    }
  }, [notesPageIndex, searchText, filter, sortBy]);

  useEffect(() => {
    if (refresh) {
      setNotesPageIndex(1);
      getPinnedNotesApiCall();
    }
  }, [refresh]);

  useEffect(() => {
    if (callback != refresh) {
      setRefresh(callback);
    }
  }, [callback]);

  return (
    <>
      {slicedListItems.length > 0 && (
        <Grid
          container
          sx={{
            maxWidth: theme.breakpoints.values.md,
          }}
          rowGap={2}
        >
          <Grid
            container
            sx={{ mt: 1, display: { md: "flex", lg: "flex" } }}
            columnGap={2}
          >
            <Grid item xs={8} lg={8} md={8}>
              <Typography variant="title_medium" sx={{ pr: 1 }}>
                Pinned
              </Typography>{" "}
              <Typography variant="caption" sx={{ color: "secondary.dark" }}>
                {listItems.length > 0 ? listItems.length + " total" : ""}
              </Typography>
            </Grid>
            {listItems.length > 1 && (
              <Grid item lg={3} md={3} sm={3} xs={3} display="flex">
                <Box
                  sx={{
                    display: "flex",
                    width: 1,
                    alignContent: "flex-end",
                    justifyContent: "flex-end",
                  }}
                >
                  <ArrowBackIosIcon
                    onClick={prevStep}
                    sx={{ cursor: activeStep > 0 ? "pointer" : "" }}
                    color={activeStep > 0 ? "" : "disabled"}
                  />
                  <ArrowForwardIosIcon
                    onClick={nextStep}
                    sx={{
                      cursor: remainingItems > 0 ? "pointer" : "",
                    }}
                    color={remainingItems > 0 ? "" : "disabled"}
                  />
                </Box>
              </Grid>
            )}
          </Grid>
          <Grid container key={index} justifyContent="center" sx={{ pb: 2 }}>
            {slicedListItems?.map((item, index) => (
              <Grid item md={12} lg={12} sm={12} xs={12} key={index}>
                <ResearchCuratorCard
                  index={index}
                  cardData={item}
                  handleCardClick={() => handleCardClick(item)}
                  setCallback={setRefresh}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default PanelListView;
