export const labelOptions = {
	"Areas of Expertise": [
		"Startup Strategy & Execution",
		"Marketing & Growth",
		"Operations & Infrastructure",
		"User Experience & Design",
		"Team Building & Leadership",
		"Engineering & Technical",
	],
	Stages: ["Pre-Seed", "Seed", "Series A", "Growth"],
};
