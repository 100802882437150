import React, { useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { ContentWrapper } from "./styles";
import { INCOMPLETE_PROFILE_ICON } from "../../constants";

const CompleteYourProfile = ({
  progressValue = 0,
  title = "Completing your profiles",
  sub_title = "Fill up your profiles to at least 80% completion in order to be seen by investors.",
}) => {
  const [value, setValue] = useState(progressValue);
  return (
    <ContentWrapper value={value}>
      <Box sx={{ borderRadius: "4px" }}>
        <Stack direction="row" spacing={1} alignItems="center">
          {value < 80 ? (
            <Box
              component={"img"}
              src={INCOMPLETE_PROFILE_ICON}
              alt="profile"
            ></Box>
          ) : (
            <CheckCircleRoundedIcon
              sx={{ color: "#00CE7D", height: "20px", width: "20px" }}
            />
          )}
          <Box>
            {" "}
            <Typography variant="title_medium" color="text.primary">
              {title}
            </Typography>
          </Box>
        </Stack>
        <Box sx={{ pt: 2 }}>
          <Typography variant="bodyMedium" color="secondary.korophKoal">
            {sub_title}
          </Typography>
        </Box>
      </Box>
    </ContentWrapper>
  );
};

export default CompleteYourProfile;
