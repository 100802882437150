import React, { useState } from "react";
import { Grid, Box, Typography, Select, styled, InputBase, MenuItem } from "@mui/material";
import { CALCULATOR_BOTTOM_ICON, CALCULATOR_LIST, CALCULATOR_TOP_ICON, CALCULATOR_V1_ICON } from "../../../constants";
import { CardFounderWrapper } from "../styles";
import { KeyboardArrowDown } from "@mui/icons-material";
import { ProfitMarginCalculator } from "./Calculators/ProfitMarginCalculator";
import { CustomerAcquisitionCost } from "./Calculators/CustomerAcquisitionCost";
import { BreakEvenAnalysisCalculator } from "./Calculators/BreakEvenAnalysisCalculator";
import { SalesMarginCalculator } from "./Calculators/SalesMarginCalculator";
import { NetMarginCalculator } from "./Calculators/NetMarginCalculator";
import { LifetimeValueCalculator } from "./Calculators/LifetimeValueCalculator";
import { ReturnOnInvestmentCalculator } from "./Calculators/ReturnOnInvestmentCalculator";
import { CashRunWayCalculator } from "./Calculators/CashRunWayCalculator";
import InfoHelper from "../InfoHelper";
import { handleMixPanel } from "../../../utils/mixPanelEventHandling";

function CalculatorCard() {
	const calculatorList = CALCULATOR_LIST.map((obj) => obj.name);

	const [calculatorValue, setCalculatorValue] = useState(0);

	const BootstrapInput = styled(InputBase)(({ theme }) => ({
		"label + &": {
			marginTop: theme.spacing(3),
		},
		"& .MuiInputBase-input": {
			border: "none",
			position: "relative",
			backgroundColor: theme.palette.background.paper,
			fontSize: "20px",
			lineHeight: "30px",
			paddingLeft: "12px",
			paddingTop: "10px",
			paddingBottom: "10px",
			paddingRight: "40px !important",
			transition: theme.transitions.create(["border-color", "box-shadow"]),
			// Use the system font instead of the default Roboto font.
			fontFamily: "PoppinsSemiBold",
			"&:focus": {
				borderRadius: 4,
				borderColor: "#80bdff",
				boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
			},
		},
	}));

	const handleSelectChange = (event) => {
		setCalculatorValue(event.target.value);

		handleMixPanel("Founder Dashboard: Calculator Selected", {
			"Calculator Name": event.target.value,
		});
	};

	const FormulaHandling = () => {
		switch (calculatorValue) {
			case 0:
				return <CustomerAcquisitionCost />;
			case 1:
				return <ProfitMarginCalculator />;
			case 2:
				return <BreakEvenAnalysisCalculator />;
			case 3:
				return <SalesMarginCalculator />;
			case 4:
				return <NetMarginCalculator />;
			case 5:
				return <LifetimeValueCalculator />;
			case 6:
				return <ReturnOnInvestmentCalculator />;
			case 7:
				return <CashRunWayCalculator />;
			default:
				return null;
		}
	};

	return (
		<CardFounderWrapper>
			<Box>
				<Grid container rowSpacing={5} columnSpacing={0}>
					<Grid item xs={12} lg={12}>
						<Box display={"flex"} alignItems="center" gap={2}>
							<Box component={"img"} src={CALCULATOR_V1_ICON} alt="calculator" />

							<Box sx={{ display: "flex", alignItems: "center", gap: "12px" }}>
								<Typography variant="main_title" color="secondary.black">
									Calculators
								</Typography>
								<InfoHelper title={"Calculators"} body={"Select from a variety of calculators for your startup business metrics."} />
							</Box>
						</Box>
						<Select
							sx={{
								"& .MuiSelect-icon": {
									fontSize: "30px",
									color: "black",
									opacity: !calculatorList || calculatorList?.length <= 0 ? ".38" : "1",
								},

								"& .MuiInputBase-input.MuiSelect-select": {
									pl: 0,
								},
							}}
							MenuProps={{
								sx: {
									".MuiMenu-paper": { boxShadow: (theme) => theme.palette.secondary.selectNoOutlineShadow },
								},
							}}
							value={calculatorValue}
							onChange={handleSelectChange}
							input={<BootstrapInput />}
							IconComponent={KeyboardArrowDown}
							disabled={!calculatorList || calculatorList?.length <= 0}
						>
							{calculatorList?.map((obj, index) => {
								return (
									<MenuItem key={index} value={index}>
										{obj}
									</MenuItem>
								);
							})}
							{!calculatorList || (calculatorList?.length <= 0 && <MenuItem value={0}>No Persona Data Found</MenuItem>)}
						</Select>

						<Box sx={{ mt: 2 }}>
							<Typography variant="subhead1">
								{CALCULATOR_LIST.filter((calcultorItem) => calcultorItem.id == calculatorValue)[0].description}
							</Typography>
						</Box>
					</Grid>

					<FormulaHandling />
				</Grid>
			</Box>
		</CardFounderWrapper>
	);
}

export default CalculatorCard;
