import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import messages from "../../../../../../../constants/messages";
import AutocompleteInput from "../../../../../../../common/AutocompleteInput";

export default function BrandPersonality({
  userInputs,
  setUserInputs,
  brandPersonalityInputValid,
}) {
  const handleInputChange = (e, key) => {
    let value = e.target.value;
    const currentInput = { ...userInputs };
    currentInput[key] = value;
    setUserInputs(currentInput);
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} sx={{ mt: 6 }}>
          <Typography variant="title_medium">Brand Descriptors</Typography>
        </Grid>
        <Grid item xs={12} md={12}>
          <Typography variant="onsurface_subtext">
            What words describe your startup? For example, words like
            “trustworthy”, “modern”, “SaaS”, “inclusive”.
          </Typography>
        </Grid>
        <Grid item xs={12} md={12}>
          <AutocompleteInput
            label={"Description"}
            handleInputChange={handleInputChange}
            inputKey={"brandDescription"}
            autoCompleteData={["Item 1", "Item 2", "Item 3"]}
            inputValue={userInputs ? [...userInputs.brandDescription] : []}
            inputValid={brandPersonalityInputValid}
            errorMessage={messages.MULTITAG_INPUT_INVALID}
            sx={{ mt: 2 }}
            typingEnabled
            multiple
            handleOnBlurEvent={true}
          />
        </Grid>

        <Grid item xs={12} md={12} sx={{ mt: 6 }}>
          <Typography variant="title_medium">Brand Detractors</Typography>
        </Grid>
        <Grid item xs={12} md={12}>
          <Typography variant="onsurface_subtext">
            What words DO NOT describe your startup? For example, words like
            “consumer”, “exclusive”.
          </Typography>
        </Grid>
        <Grid item xs={12} md={12}>
          <AutocompleteInput
            label={"Description"}
            handleInputChange={handleInputChange}
            inputKey={"brandDetractors"}
            autoCompleteData={["Item 1", "Item 2", "Item 3"]}
            inputValue={userInputs ? [...userInputs.brandDetractors] : []}
            inputValid={brandPersonalityInputValid}
            errorMessage={messages.MULTITAG_INPUT_INVALID}
            sx={{ mt: 2 }}
            typingEnabled
            multiple
            handleOnBlurEvent={true}
          />
        </Grid>

        <Grid item xs={12} md={12} sx={{ mt: 6 }}>
          <Typography variant="title_medium">Ideal Customer</Typography>
        </Grid>
        <Grid item xs={12} md={12}>
          <Typography variant="onsurface_subtext">
            What describes your ideal customer? For example, words like “B2B
            buyer”, “low-income”, “startup”.
          </Typography>
        </Grid>
        <Grid item xs={12} md={12}>
          <AutocompleteInput
            label={"Description"}
            handleInputChange={handleInputChange}
            inputKey={"idealCustomer"}
            autoCompleteData={["Item 1", "Item 2", "Item 3"]}
            inputValue={userInputs ? [...userInputs.idealCustomer] : []}
            inputValid={brandPersonalityInputValid}
            errorMessage={messages.MULTITAG_INPUT_INVALID}
            sx={{ mt: 2 }}
            typingEnabled
            multiple
            handleOnBlurEvent={true}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
