import React from "react";
import { Autocomplete, TextField, Chip } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

export default function AutocompleteNew({
  placeholder,
  inputValid,
  errorMessage,
  typingEnabled,
  inputValue,
  handleInputChange,
  inputKey,
  autoCompleteData,
  label,
  multiple,
  max,
  disabled,
  handleOnBlurEvent = false,
  isEmailField = false,
  isReferenceField = false,
  handleOnChange = () => {},
  isError = false,
  ...props
}) {
  const handleChange = e => {
    if (e.target.value === 0 && e.target.innerText) {
      inputValue.push(e.target.value || e.target.innerText);
      let inputs = [...inputValue];
      let uniqueInputs = new Set(inputs);
      handleInputChange(
        { target: { value: Array.from(uniqueInputs) } },
        inputKey
      );
    }
  };
  const handleDelete = value => {
    const newtags = inputValue.filter(val => val !== value);
    handleInputChange({ target: { value: newtags } }, inputKey);
  };
  return (
    <Autocomplete
      forcePopupIcon={true}
      freeSolo
      disableClearable
      multiple={true}
      value={inputValue}
      options={
        isReferenceField
          ? autoCompleteData || []
          : autoCompleteData?.map(option => option)
      }
      {...props}
      renderTags={(inputValue, getTagProps) =>
        inputValue.map((option, index) => (
          <Chip
            label={option}
            {...getTagProps({ index })}
            variant="outlined"
            sx={theme => ({
              borderRadius: theme.shape.standard_small.borderRadius,
            })}
            deleteIcon={<ClearIcon />}
            disabled={false}
            onDelete={() => (disabled ? {} : handleDelete(option))}
          />
        ))
      }
      renderInput={params => (
        <TextField
          {...params}
          spellCheck="true"
          label={label}
          onChange={handleChange}
          disabled={disabled}
        />
      )}
      onChange={handleChange}
      {...props}
      sx={{
        "& .MuiOutlinedInput-notchedOutline": { borderRadius: 1 },
      }}
    />
  );
}
