import { Card, Slider } from "@mui/material";
import { styled } from "@mui/material/styles";
import emotionStyled from "@emotion/styled";

export const SliderComponent = styled(Slider)(({ theme }) => ({
  height: 6,
  padding: "15px 0",
  "& .MuiSlider-thumb": {},
  "& .MuiSlider-track": {
    border: "none",
    //background:
    //  "linear-gradient(270deg, #00CE7D -140.88%, #478FC8 -66.42%, #7B61FF 43.07%, #F32836 100%), #7649A0;",
    background:
      "linear-gradient(0deg, rgba(245, 248, 250, 0.85), rgba(245, 248, 250, 0.85)), #6A7575",
  },
  "& .MuiSlider-rail": {
    background:
      "linear-gradient(0deg, rgba(245, 248, 250, 0.85), rgba(245, 248, 250, 0.85)), #6A7575",
  },
  "& .MuiSlider-mark": {
    backgroundColor: theme.palette.text.primary,
    height: 17,
    transform: "translate(-1px, -10%)",
  },
  [theme.breakpoints.down("md")]: {
    "& .MuiSlider-mark": {
      backgroundColor: theme.palette.secondary.white,
    },
  },
}));

export const TipComponent = emotionStyled.div(({ theme }) => {
  return {
    border: `1px solid ${theme.palette.secondary.dark}`,
    borderRadius: 2,
    boxSizing: "border-box",
    boxShadow:
      "0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
    maxWidth: "130px",
    height: "max-content",
    fontSize: 11,
    display: "flex",
    paddingTop: "5px",
    paddingBottom: "5px",
    paddingRight: "8px",
    paddingLeft: "8px",
    gap: "8px",
  };
});
