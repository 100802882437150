import React, { useState, useEffect, useContext } from "react";
import { Box, Stack, Typography, Grow, Fade, Divider, Popover } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import CloseIcon from "@mui/icons-material/Close";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FilterDrawer from "../../FilterDrawer";
import SavedSearchesComponent from "../../SavedSearches/SavedSearchesDialog";
import { INVESTOR_FILTER_ICON } from "../../../../constants";
import DashboardLayout from "../Layout";
import DashboardNavabar from "../Navbar";
import { useDispatch, useSelector } from "react-redux";
import { setInvestorDashboardData } from "../../../../modules/actions";
import { getListOfCategoryApiCall } from "../../../../containers/Investor/Dashboard/Functions/common";
import { InvestorDashboardContext } from "../../../../containers/Investor/Dashboard";
import { Filter } from "@mui/icons-material";

export default function DashboardView({ content, activeKey }) {
	const { filterResult, setFilterResult, filterPayload, setFilterPayload, isFilterApplied, setIsFilterApplied, totalCompanyCount } =
		useContext(InvestorDashboardContext);

	const [checked, setChecked] = useState(false);

	const dispatch = useDispatch();

	useEffect(() => {
		getCategoryList("industry");
		getCategoryList("product-offering");
		getCategoryList("business-type");
		getCategoryList("revenue-model-type");
	}, []);

	const getCategoryList = (category) => {
		getListOfCategoryApiCall(category)
			.then((res) => {
				const responseData = res.data;
				let categoryArray = [];

				responseData.map((categoryItem) => {
					categoryArray.push(categoryItem?.lovValue);
				});
				if (category === "industry") {
					dispatch(setInvestorDashboardData({ industryList: categoryArray }));
				} else if (category === "product-offering") {
					dispatch(setInvestorDashboardData({ productOfferingList: categoryArray }));
				} else if (category === "business-type") {
					dispatch(setInvestorDashboardData({ businessTypeList: categoryArray }));
				} else if (category === "revenue-model-type") {
					dispatch(setInvestorDashboardData({ revenueTypeList: categoryArray }));
				}
			})
			.catch((e) => {
				console.log("Error", e);
			});
	};

	const handdleChecked = () => {
		if (checked == false) {
			setChecked(true);
		} else {
			setChecked(false);
		}
	};

	const handleClick = () => {
		dispatch(setInvestorDashboardData({ FilterDrawerOpen: true, savedFilterEditData: { isEdit: false, name: null } }));
		//handdleChecked();
	};

	const handleOpenSavedSearch = () => {
		dispatch(setInvestorDashboardData({ SavedSearchesOpen: true }));
		handdleChecked();
	};

	return (
		<Stack
			sx={(theme) => ({
				borderRadius: theme.shape.standard.borderRadius,
				[theme.breakpoints.down("md")]: {
					borderRadius: 0,
				},
			})}
		>
			<Box
				sx={(theme) => ({
					maxWidth: "1900px",
					margin: "auto",
					width: 1,
				})}
			>
				<Box display={"flex"}>
					<Box
						sx={{
							ml: "auto",
							display: "flex",
							position: "relative",
						}}
					>
						<Box
							display={"flex"}
							onClick={handleClick}
							sx={{
								// mt: 1,
								height: "70px",
								width: "70px",
								background: (theme) => theme.palette.primary.primaryPurple,
								alignItems: "center",
								borderRadius: "50%",
								justifyContent: "center",
								cursor: "pointer",
								position: "absolute",
								right: "46px",
								top: "0px",
							}}
						>
							<Box component={"img"} src={INVESTOR_FILTER_ICON} alt="filter" sx={{ filter: "invert(1)", height: "20px" }} />
						</Box>
					</Box>
				</Box>
				<FilterDrawer
					disableBackDropClick={true}
					filterResult={filterResult}
					setFilterResult={setFilterResult}
					filterPayload={filterPayload}
					setFilterPayload={setFilterPayload}
					isFilterApplied={isFilterApplied}
					setIsFilterApplied={setIsFilterApplied}
					totalCompanyCount={totalCompanyCount}
				/>
				<DashboardLayout topbar={<DashboardNavabar activeKey={activeKey} />} main={<Box>{content}</Box>} height={"100%"} />
			</Box>
		</Stack>
	);
}
