import styled from "@emotion/styled";
import { sosPrimary } from "../../../constants/Theme/colors";

export const PageContentWrapper = styled.div(
  ({ theme, borderWidth, padding, marginTop, paddingBottom, bgColor }) => {
    return {
      marginTop: marginTop || theme.spacing(3),
      borderRadius: theme.shape.standard.borderRadius,
      flexGrow: 1,
      borderWidth: borderWidth || 0,
      borderColor: theme.palette.secondary.dark,
      borderStyle: "solid",
      padding: padding || theme.spacing(3),
      display: "flex",
      flexDirection: "column",
      paddingBottom: theme.spacing(paddingBottom),

      [theme.breakpoints.down("md")]: {
        backgroundColor: bgColor ? bgColor : theme.palette.secondary.white,
      },
      [theme.breakpoints.up("md")]: {
        backgroundColor: bgColor ? bgColor : theme.palette.background.default,
      },
    };
  }
);

export const WorkContentWrapper = styled.div(({ theme, borderWidth }) => {
  return {
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(3),
      borderRadius: "0px",
      flexGrow: 1,
      borderWidth: borderWidth || 0,
      borderColor: theme.palette.secondary.dark,
      borderStyle: "solid",
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(0),
      display: "flex",
      flexDirection: "column",
    },
  };
});

export const ProfileContentWrapper = styled.div(
  ({ theme, borderWidth, value }) => {
    return {
      flexGrow: 1,
      borderWidth: borderWidth || 0,
      borderColor: theme.palette.secondary.dark,
      borderStyle: "solid",
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.down("md")]: {
        backgroundColor:
          value === 0 ? "none" : theme.palette.background.default,
        borderRadius: value === 0 ? "28px" : "0px",
      },
    };
  }
);
