import { REGEX } from "../../../../../constants/regex";
export const getExcerciseConfigurations = () => {
  return {
    form_key: "revenueModeler",
    power_up_id: 9,
    form_title: "Revenue Modeler",
    summary_path: "/workspace/revenuemodeler/summary",
    excercises: [
      {
        step_id: 17,
        component: "Evaluate",
        path: "evaluate-revenue-modeler",
        key: "evaluate-revenue-modeler",
        description:
          "Understanding your market size is critical (market beats all) and will be a core component of any of your fundraising conversations.",
        stepNo: 1,
        stepUuid: "eva-revenue",
        title: "Evaluate revenue model options",
      },
      {
        step_id: 18,
        component: "AdaptAdjust",
        path: "adapt-and-adjust",
        key: "adapt-and-adjust",
        description:
          "Understanding your market size is critical (market beats all) and will be a core component of any of your fundraising conversations.",
        stepNo: 2,
        stepUuid: "adpt-adjst",
        title: "Adapt and adjust",
      },
    ],
  };
};
export const getExcerciseCompletionStatus = currentExcercise => {
  return true;
};

export const evaluateInputValid = (key, input) => {
  switch (key) {
    case "stage":
      if (input.match(REGEX.POWERUP_INPUT_LENGTH)) {
        return true;
      } else {
        return false;
      }
    case "model":
      if (input.match(REGEX.POWERUP_INPUT_LENGTH)) {
        return true;
      } else {
        return false;
      }
  }
};

export const adaptAdjestInputValid = (key, input) => {
  switch (key) {
    case "subscriptionPrice":
      if (!input) {
        return true;
      } else {
        return false;
      }

    case "billingCadence":
      if (!input) {
        return true;
      } else {
        return false;
      }

    case "primarySource":
      if (!input.length) {
        return true;
      } else {
        return false;
      }

    case "businessType":
      if (!input.length) {
        return true;
      } else {
        return false;
      }

    case "launchDate":
      if (!input.length) {
        return true;
      } else {
        return false;
      }
  }
};

export const buttonDisable = (userInputs, action) => {
  switch (action) {
    case "Evaluate":
      for (let key in userInputs) {
        if (!userInputs[key]?.length) {
          return true;
        }
      }
      return false;
    case "AdaptAdjust":
      for (let key in userInputs) {
        if (key == "subscriptionPrice") {
          if (!userInputs[key] || !userInputs[key].match(REGEX.DECIMAL_INPUT)) {
            return true;
          }
        } else if (!userInputs[key]) {
          return true;
        }
      }
      return false;
    default:
      return true;
  }
};

export const getDefaultInputStates = componet => {
  switch (componet) {
    case "Evaluate":
      return {
        stage: "",
        model: "",
      };

    case "AdaptAdjust":
      return {
        subscriptionPrice: "",
        launchDate: "",
        billingCadence: "",
        primarySource: "",
        businessType: "",
      };

    default:
      return true;
  }
};

export const cleanPayload = (componet, userInputs) => {
  switch (componet) {
    case "Evaluate":
      return {
        userInputs,
      };

    case "AdaptAdjust":
      return {
        userInputs,
      };

    default:
      return true;
  }
};
