import * as actionTypes from "./actionTypes";

export const setEventData = (payload) => {
	return (dispatch) => {
		dispatch({
			type: actionTypes.SET_EVENT_DATA,
			payload,
		});
	};
};
