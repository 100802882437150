import { Box, Chip, Rating, Typography } from "@mui/material";
import React, { useContext, useEffect, useRef } from "react";
import useDimensions from "react-cool-dimensions";
import { Helmet } from "react-helmet";

import { PowerUPContext } from "../../../ToolContainer";
import { AD_TESTING_SURVEY, LIKERT_SURVEY, LOGO_TESTING_SURVEY, NPS_SURVEY, PMF_SURVEY } from "../../../../../constants";

const InfoCorner = ({ title, estimatedEffort, whoShouldDoIt, ratingValue, barStyle, isCompanySurvey }) => (
	<Box
		sx={{
			...(barStyle
				? {
						borderRadius: "30px",
						px: "2.5rem",
						py: "1.5rem",
						gap: ".25rem",
				  }
				: {
						borderRadius: "0px 100px 0px 0px",
						padding: "2.5rem",
						gap: "0.625rem",
				  }),
			background: "#000",
			display: "flex",
			flexDirection: "column",
			alignItems: "flex-start",
		}}
	>
		<Typography variant={barStyle ? "Title/20/Semibold" : "Title/30/Semibold"} sx={{ color: "white" }}>
			{title}
		</Typography>
		<Rating
			name="read-only"
			value={ratingValue}
			readOnly
			sx={{
				fontSize: barStyle ? "1.275rem" : "1.875rem",
			}}
		/>
		<Typography variant="Body/18/Regular" sx={{ color: "white" }}>
			Estimated Effort: {estimatedEffort}
			{whoShouldDoIt && (
				<React.Fragment>
					<br />
					Who Should Do It: {whoShouldDoIt}
				</React.Fragment>
			)}
		</Typography>
		<Typography variant={"Text/md/Semibold"} sx={{ color: "white" }}>
			{isCompanySurvey ? "Completed" : "Not Started"}
		</Typography>
	</Box>
);

function ToolHeroCard() {
	const { toolDetails, videoPlaying, isCompanySurvey } = useContext(PowerUPContext);

	const { observe, width } = useDimensions({
		onResize: ({ width }) => {},
	});

	const calculateHeight = (width) => {
		const maxWidth = 1630;
		const maxHeight = 750;
		const aspectRatio = maxWidth / maxHeight;
		const calculatedHeight = width / aspectRatio;

		return calculatedHeight > maxHeight ? maxHeight : calculatedHeight;
	};

	const height = calculateHeight(width);
	const infoCornerRef = useRef(null);
	const minHeight = infoCornerRef.current ? infoCornerRef.current.clientHeight : "auto";

	useEffect(() => {
		observe();
	}, [observe]);

	const getSurveyPlaceholderImage = () => {
		const currentPath = window.location.pathname;
		const parts = currentPath.split("/");
		const serveyId = parts[parts.length - 1];
		switch (serveyId) {
			case "2":
				return NPS_SURVEY;
			case "4":
				return PMF_SURVEY;
			case "5":
				return LIKERT_SURVEY;
			case "8":
				return LOGO_TESTING_SURVEY;
			case "9":
				return AD_TESTING_SURVEY;
			default:
				return null; // TODO -- Need "sorry was unable to load at this time. Message."
		}
	};

	return (
		<React.Fragment>
			<Helmet>
				<meta charSet="utf-8" />
				<title>StartupOS - {toolDetails?.surveyName || ""}</title>
			</Helmet>
			<Box
				ref={observe}
				sx={{
					position: "relative",
					width: "100%",
					height: `${height}px`,
					minHeight: `${minHeight}px`,
					maxWidth: "1630px",
					maxHeight: "750px",
					backgroundColor: "gray",
					borderRadius: "1.875rem",
					overflow: "hidden",
					...(!toolDetails?.videoThumbnailUrl && {
						backgroundImage: `url(${getSurveyPlaceholderImage()})`,
						backgroundSize: "contain",
						backgroundPosition: "center",
					}),
				}}
			>
				{width > 1000 && (
					<Box
						ref={infoCornerRef}
						sx={{
							position: "absolute",
							bottom: "0",
							left: "0",
							maxWidth: "35.3125rem",
							width: "100%",
						}}
					>
						<InfoCorner
							title={toolDetails?.surveyName}
							estimatedEffort={"15 mins"}
							ratingValue={5}
							barStyle={videoPlaying}
							whoShouldDoIt={"Founder/Co-Founder"}
							isCompanySurvey={isCompanySurvey}
						/>
					</Box>
				)}
			</Box>
		</React.Fragment>
	);
}

export default ToolHeroCard;
