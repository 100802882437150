import services from "../../../../services";

export const contentSummaryAI = async (content, context, cb, id) => {
	cb({ key: id, values: { loading: true, complete: false } });

	if (!content) {
		cb({ key: id, values: { loading: false, complete: true } });

		return;
	}

	let payload = {
		messages: [
			{
				role: "system",
				content: `Summarize text the user sends, ensuring it's concise while retaining the most important information. ${context}`,
			},
			{
				role: "user",
				content: `${content}`,
			},
		],
		maxTokens: 1200,
	};

	let attempts = 0;
	while (attempts < 2) {
		try {
			let AIResp = await services.getOpenAIChatResponse(payload).catch((e) => {
				console.log(e);
			});
			cb({ key: id, values: { loading: false, complete: true } });

			if (AIResp?.data?.data?.[0]?.text) {
				return AIResp?.data?.data?.[0]?.text;
			} else if (AIResp?.data?.data?.[0]?.message?.content) {
				return AIResp?.data?.data?.[0]?.message?.content;
			} else {
				attempts++;
			}
		} catch (error) {
			console.error(error);
		}
	}
	return false;
};
