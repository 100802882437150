import React, { useEffect, useState } from "react";
import { Box, Grid, Button, Typography, Stack } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { CompletedWrapper, PointsWrapper } from "../styles";
import theme from "../../../../constants/Theme";
import {
  POWERUP_LOGO_WHITE,
  POWERUPLOGO,
  POWERUP_IMAGE,
  DEMO_DAY_LOGO,
  FUSION_LOGO,
  PLUGIN_LOGO,
} from "../../../../constants";
import { useHistory } from "react-router-dom";
import ROUTES_PATH from "../../../../constants/routes";
import { useDispatch } from "react-redux";
import { isEmpty } from "lodash";

export default function ProgressHeroCard(props) {
  const history = useHistory();

  const [taskImgUrl, setTaskImgUrl] = useState("");
  const [taskTitle, setTaskTitle] = useState("");
  const [taskDescription, setTaskDescription] = useState("");

  useEffect(() => {
    let data = props.data;
    if (props.data.taskName == "POWER_UP") {
      setTaskImgUrl(data.powerUp.imageUrl);
      setTaskTitle(data.powerUp.powerUpName);
      setTaskDescription(data.powerUp.shortDescription);
    } else if (props.data?.taskName == "PLUGIN") {
      setTaskImgUrl(data.plugin.imageUrl);
      setTaskTitle(data.plugin.pluginName);
      setTaskDescription(data.plugin.shortDescription);
    } else if (props.data?.taskName == "FUSION") {
      setTaskImgUrl(data.fusion.imageUrl);
      setTaskTitle(data.fusion.fusionName);
      setTaskDescription(data.fusion.shortDescription);
    } else {
      setTaskImgUrl(data.demo.imageUrl);
      setTaskTitle(data.demo.demoName);
      setTaskDescription(data.demo.shortDescription);
    }
  }, [props.data]);

  const handleCompletedWork = () => {
    const totalLength = props?.moduleResponse?.length;
    const lastIndex = totalLength ? totalLength - 1 : 0;
    if (lastIndex >= 0 && props?.moduleResponse[lastIndex]) {
      history.push(
        `/workspace/powerup/${props?.moduleResponse[lastIndex]?.module?.moduleUuid}/${props?.moduleResponse[lastIndex]?.moduleId}/${props?.moduleResponse[lastIndex]?.id}/start`
      );
    }
  };

  return (
    <Stack sx={{ minHeight: { md: "300px" } }}>
      <Grid container sx={{ display: "flex", flexDirection: "row", height: 1 }}>
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              width: 1,
              height: 1,
              display: "block",
              objectFit: "cover",
              borderRadius: theme.spacing(2),
            }}
            src={taskImgUrl ? taskImgUrl : POWERUP_IMAGE}
            component="img"
          ></Box>
        </Grid>
        <Grid item xs={12} md={8} sx={{ p: 2 }}>
          <Grid container sx={{ pt: 2 }} flexDirection="row">
            <Grid item xs={12} md={12}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  pb: 1,
                }}
              >
                <Box
                  src={
                    props.data?.taskName == "POWER_UP"
                      ? POWERUPLOGO
                      : props.data?.taskName == "PLUGIN"
                      ? PLUGIN_LOGO
                      : props.data?.taskName == "FUSION"
                      ? FUSION_LOGO
                      : DEMO_DAY_LOGO
                  }
                  component="img"
                ></Box>
                {props.data.taskName === "POWER_UP" ||
                props.data.taskName === "PLUGIN" ? (
                  props.data?.companyTask?.taskStatus == "STARTED" ? (
                    <CompletedWrapper sx={{ p: 1 }}>
                      <Typography variant="overlineLarge">
                        In Progress
                      </Typography>
                    </CompletedWrapper>
                  ) : props.data?.companyTask?.taskStatus == "COMPLETED" ? (
                    <CompletedWrapper sx={{ pr: 1 }}>
                      <CheckCircleIcon />
                      <Typography variant="overlineLarge">Complete</Typography>
                    </CompletedWrapper>
                  ) : (
                    <PointsWrapper>
                      <Typography variant="overlineLarge">25 Points</Typography>
                    </PointsWrapper>
                  )
                ) : (
                  ""
                )}
              </Box>
            </Grid>

            <Grid item xs={12} md={12}>
              <Typography variant="ph_headline">{taskTitle}</Typography>
            </Grid>
            <Grid item xs={12} md={10} sx={{ pt: 1 }}>
              <Typography variant="ph_text">{taskDescription}</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                mt: { xs: 6, md: 10 },
                pb: 2,
                bottom: 0,
                right: 0,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  float: "right",
                }}
              >
                {props.data?.companyTask?.taskStatus == "STARTED" ? (
                  props.data.taskName !== "PLUGIN" ? (
                    <Button
                      onClick={() =>
                        props.handleStartPowerUp(props.data.powerUpId)
                      }
                    >
                      Resume
                    </Button>
                  ) : (
                    <Button
                      sx={{ width: "max-content", float: "right" }}
                      onClick={() =>
                        history.push(ROUTES_PATH.MENTOR_CHECKIN_DETAILS)
                      }
                    >
                      View Plugin details
                    </Button>
                  )
                ) : props.data?.companyTask?.taskStatus == "COMPLETED" ? (
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      display: "flex",
                      float: "right",
                    }}
                  >
                    {props.data.powerUpId === 3 ? (
                      <Grid item xs={12} md={12} lg={12} xl={12}>
                        <Button
                          sx={{ float: "right" }}
                          onClick={() =>
                            props.handleStartPowerUp(props.data.powerUpId)
                          }
                        >
                          Revisit
                        </Button>
                      </Grid>
                    ) : (
                      <>
                        <Grid item xs={12} md={12} lg={4} xl={4}>
                          <Button
                            variant="outlined"
                            sx={{ float: "right" }}
                            onClick={() =>
                              props.handleStartPowerUp(props.data.powerUpId)
                            }
                          >
                            Revisit
                          </Button>
                        </Grid>
                        <Grid item xs={12} md={12} lg={8} xl={8}>
                          <Button
                            onClick={handleCompletedWork}
                            disabled={isEmpty(props?.moduleResponse)}
                          >
                            View completed work
                          </Button>
                        </Grid>
                      </>
                    )}
                  </Grid>
                ) : props.data.taskName == "POWER_UP" ? (
                  <Button
                    onClick={() =>
                      props.handleStartPowerUp(props.data.powerUpId)
                    }
                  >
                    Start
                  </Button>
                ) : props.data.taskName == "PLUGIN" ? (
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      display: "flex",
                      float: "right",
                      width: 1,
                    }}
                  >
                    <Grid item xs={12} md={12} lg={6} xl={6}>
                      <Button
                        variant="contained"
                        sx={{ width: "max-content", float: "right" }}
                        onClick={() => props.setShowAdvisorPluginPopup(true)}
                      >
                        Schedule session
                      </Button>
                    </Grid>
                    <Grid item xs={12} md={12} lg={6} xl={6}>
                      <Button
                        sx={{ width: "max-content", float: "right" }}
                        onClick={() =>
                          history.push(ROUTES_PATH.MENTOR_CHECKIN_DETAILS)
                        }
                      >
                        View Plugin details
                      </Button>
                    </Grid>
                  </Grid>
                ) : props.data.taskName == "FUSION" ? (
                  <Grid
                    container
                    spacing={1}
                    sx={{
                      display: "flex",
                      float: "right",
                    }}
                  >
                    <Grid item xs={12} md={12} lg={12} xl={12}>
                      <Button
                        sx={{ width: "max-content", float: "right" }}
                        disabled={props?.downloadDisable}
                        href={props?.templateUrl}
                      >
                        Download deck
                      </Button>
                    </Grid>
                  </Grid>
                ) : (
                  <Button disabled>Schedule meeting</Button>
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Stack>
  );
}
