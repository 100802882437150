import React, { Fragment, useEffect } from "react";
import { Switch, useRouteMatch, Route, Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";

import GetStarted from "./GetStarted";
import PowerupLibrary from "./PowerupLibrary";
import TryDemo from "./TryDemo";
import WorkspaceView from "./components/WorkspaceView";
import { handleMixPanel } from "../../../utils/mixPanelEventHandling";
import { useSelector } from "react-redux";

export default function NewWorkspace() {
	const { path } = useRouteMatch();
	const { isPremiumPlanAccess, userType } = useSelector((store) => store?.auth);

	useEffect(() => {
		handleMixPanel("Home Page Viewed", {
			subscription_status: isPremiumPlanAccess ? "Subscribed" : "Not Subscribed",
			user_type: userType,
		});
	}, []);

	return (
		<Fragment>
			<Helmet>
				<meta charSet="utf-8" />
				<title>StartupOS - Home</title>
			</Helmet>
			<Switch>
				<Route path={`${path}/get-started`}>
					<WorkspaceView content={<GetStarted />} activeKey={1} />
				</Route>
				<Route path={`${path}/powerup-library`}>
					<WorkspaceView content={<PowerupLibrary />} activeKey={2} />
				</Route>
				<Route path={`${path}/try-a-demo/41`}>
					<WorkspaceView content={<TryDemo />} activeKey={3} />
				</Route>

				<Route exact path={`${path}`}>
					<Redirect to={`${path}/get-started`} />
				</Route>
			</Switch>
		</Fragment>
	);
}
