import React, { Fragment, useEffect, useState } from "react";
import { Switch, useRouteMatch, Route, Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import PowerPackDashboard from "./Dashboard";
import { handleMixPanel } from "../../../utils/mixPanelEventHandling";
import AddPowerPack from "./AddPowerPack";
import { createContext } from "react";
import services from "../../../services";
import PowerupPackSettings from "./PowerupPackSettings";
import { useLocation } from "react-router-dom";
import ROUTES_PATH from "../../../constants/routes";
import { update } from "lodash";

export const AdminPowerpackContext = createContext();

export default function AdminPowerPackController() {
	const { path } = useRouteMatch();
	const { isPremiumPlanAccess, userType } = useSelector((store) => store?.auth);
	const [powerpacks, setPowerPacks] = useState([]);
	const [loading, setLoading] = useState(false);
	const [powerpacksLoading, setPowerpacksLoading] = useState(false);
	const [powerups, setPowerups] = useState([]);
	const location = useLocation();
	const [powerPackDetails, setPowerPackDetails] = useState(null);

	const isDashboard = location.pathname.includes("/admin-powerpack/dashboard");

	useEffect(() => {
		handleMixPanel("Admin PowerPack Dashboard  Viewed", {
			subscription_status: isPremiumPlanAccess ? "Subscribed" : "Not Subscribed",
			user_type: userType,
		});
	}, [powerups]);

	const getPackDetailInfoById = async (packId) => {
		try {
			setLoading(true);

			const packDetails = await services.getAllGroupCardsApi({ group: packId });

			if (packDetails) {
				const updatedPowerups = packDetails?.data?.data?.filter((item) => item?.cardType !== "POWERUP_PACK");

				setLoading(false);
				setPowerups(updatedPowerups);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const getPowerPacks = async () => {
		try {
			setPowerpacksLoading(true);
			const response = await services.getPowerPacksAPI();

			if (response) {
				setPowerPacks(response?.data?.data);
				setPowerPackDetails(response?.data?.data?.[0]);
				setPowerpacksLoading(false);
				if (isDashboard) getPackDetailInfoById(response?.data?.data?.[0]?.uuid);
			}
		} catch (error) {
			console.error("Error fetching event list:", error);
			setPowerpacksLoading(false);
		}
	};

	return (
		<AdminPowerpackContext.Provider
			value={{
				powerpacks,
				setPowerPacks,
				powerups,
				setPowerups,
				powerPackDetails,
				setPowerPackDetails,
				loading,
				setLoading,
				getPackDetailInfoById,
				getPowerPacks,
				powerpacksLoading,
			}}
		>
			<Fragment>
				<Helmet>
					<meta charSet="utf-8" />
					<title>StartupOS - PowerPack Dashboard</title>
				</Helmet>
				<Switch>
					<Route path={ROUTES_PATH.ADMIN_POWERPACK_DASHBOARD} component={PowerPackDashboard}>
						{/* <PowerPackDashboard /> */}
					</Route>
					<Route path={ROUTES_PATH.ADMIN_POWERPACK_ADD} component={AddPowerPack}></Route>
					<Route path={ROUTES_PATH.ADMIN_POWERPACK_EDIT} component={AddPowerPack}></Route>
					<Route path={ROUTES_PATH.ADMIN_POWERPACK_SETTINGS} component={PowerupPackSettings}></Route>

					<Route exact path={`${path}`}>
						<Redirect to={ROUTES_PATH.ADMIN_POWERPACK_DASHBOARD} />
					</Route>
				</Switch>
			</Fragment>
		</AdminPowerpackContext.Provider>
	);
}
