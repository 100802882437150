import React from "react";
import { Box, Typography, Button } from "@mui/material";
import TodayIcon from "@mui/icons-material/Today";
import Countdown from "react-countdown";
import Divider from "@mui/material/Divider";
import { COUNTDOWN_ICON, CHECK_LIST } from "../../../constants/image";
import { StayOnTrackTitle } from "./styles";
import CountDownTimer from "../../../common/countDownTimer/CountDownTimer";
import constants from "../../../constants";
import theme from "../../../constants/Theme";
import { sosPrimary } from "../../../constants/Theme/colors";
import Card from "../../../components/Workspace/Card";
import { getShortCharDay, getCurrentDate } from "../../../utils/date";

export default function StayOnTrack({ data, stayOnTrackData }) {
  const getPadStart = number => {
    return (Number(number) < 10 ? "0" : "") + number;
  };

  const timerRenderer = ({ days, hours, minutes, seconds }) => {
    return (
      <Box>
        <CountDownTimer
          days={getPadStart(days)}
          hours={getPadStart(hours)}
          minutes={getPadStart(minutes)}
          seconds={getPadStart(seconds)}
        />
      </Box>
    );
  };

  let newDate = (
    data?.demoDate ? new Date(data?.demoDate) : new Date()
  ).toISOString();
  return (
    <Card
      sx={{
        p: 1,
        boxShadow: theme => theme.palette.primary.elevationLight2,
        // backgroundColor: theme => theme.palette.text.background,
        border: "0px !important", //Added important to override border property,
        width: 1,
      }}
    >
      <Box
      //sx={theme => ({
      //  borderRadius: theme.shape.standard.borderRadius,
      //  //p: 3,
      //  width: 1,
      //  boxShadow: theme => theme.palette.primary.elevationLight3,
      //  backgroundColor: theme => theme.palette.text.background,
      //  border: "0px !important", //Added important to override border property
      //})}
      //alignItems="center"
      >
        <StayOnTrackTitle>
          <Box component={"img"} src={COUNTDOWN_ICON} alt="countdown" />
          <Typography
            sx={{
              ml: 2,
            }}
            variant="headline6"
          >
            Stay on Track
          </Typography>
        </StayOnTrackTitle>
        <Box display={"flex"} sx={{ mt: 6 }}>
          <Typography variant="title_light" sx={{ textTransform: "uppercase" }}>
            today’s date:
          </Typography>
          <Typography
            variant="title_light"
            sx={{ ml: "auto", textTransform: "uppercase" }}
          >
            {getShortCharDay(new Date())}, {getCurrentDate()}
          </Typography>
        </Box>
        <Box display={"flex"} sx={{ mt: 3 }}>
          <Typography
            variant="title_light"
            sx={{ textTransform: "uppercase", textTransform: "uppercase" }}
          >
            Pitch date:
          </Typography>
          <Typography
            variant="title_light"
            sx={{ ml: "auto", textTransform: "uppercase" }}
          >
            THUR, DEC 8, 2022
          </Typography>
        </Box>
        <Box
          sx={{ alignItems: "center", display: "flex", mt: 4 }}
          justifyContent="center"
        >
          <Typography
            variant="title_medium"
            sx={{ color: theme => theme.palette.secondary.korophKoal }}
          >
            Countdown to Pitch
          </Typography>
        </Box>
        <Box sx={{ mt: 4 }}>
          <Countdown
            daysInHours={false}
            autoStart={true}
            date={new Date("8 December 2022")}
            renderer={timerRenderer}
          />
        </Box>
        {/*<Divider sx={{ mt: 3, background: theme.palette.secondary.dark }} />
      <Typography
        sx={{
          mt: 3,
        }}
        variant="h4"
      >
        Today’s Top 3
      </Typography>
      <NoTopThreeComponent />*/}
        {/*<Divider sx={{ mt: 3, background: theme.palette.secondary.dark }} />*/}

        {/*Commented code is useful in future*/}

        {/* {stayOnTrackData?.todayTopDetails?.map((topData, i) => (
        <>
          <FormControlLabel
            className="parser-control"
            sx={{ fontSize: 14, mt: 3 }}
            control={
              <TooltipWrapper placement="left" open={open}>
                <Checkbox
                  key={i}
                  sx={{
                    color: theme.palette.primary.main,
                    mr: 2,
                  }}
                  disabled={true}
                  size="small"
                />
              </TooltipWrapper>
            }
            label={
              <JsxParser components={{ Typography }} jsx={topData?.text} />
            }
          />
          {i === stayOnTrackData?.todayTopDetails.length - 1 ? (
            <Divider sx={{ mt: 3, background: theme.palette.secondary.dark }} />
          ) : (
            <Divider sx={{ mt: 3, background: "rgba(118, 73, 160, 0.08)" }} />
          )}
        </>
      ))} */}

        {/*<Box sx={{ mt: 4, display: "flex" }}>
        <Typography variant="h4" color="secondary">
          Next Key Milestones
        </Typography>
      </Box>*/}
        <Box sx={{ mt: 2.7 }}>
          {/*<NoMileStoneComponent />*/}
          {/*Commented code is useful in future*/}

          {/* {stayOnTrackData?.milestones.map((item, index) => {
          return (
            <>
              <Box
                sx={{ display: "flex", alignItems: "baseline" }}
                className="parser-control"
              >
                <CalenderBox>
                  <Typography
                    variant="h7"
                    sx={{ textAlign: "center" }}
                    color="primary"
                  >
                    {getCharMonth(item.date)}
                  </Typography>
                  <Typography variant="h5" sx={{ textAlign: "center" }}>
                    {getDate(item.date)}
                  </Typography>
                </CalenderBox>
                <Typography sx={{ ml: 2 }}>
                  <JsxParser component={{ Typography }} jsx={item?.title} />

                  <Typography
                    color="primary"
                    sx={{ fontSize: "12px !important" }}
                  >
                    <JsxParser
                      component={{ Typography }}
                      jsx={item?.subTitle}
                    />
                  </Typography>
                </Typography>
              </Box>
              {index !== stayOnTrackData?.milestones.length - 1 ? (
                <Divider
                  sx={{
                    mt: 1,
                    ml: 8.2,
                    background: "rgba(118, 73, 160, 0.08)",
                  }}
                />
              ) : (
                ""
              )}
            </>
          );
        })}
        <Box sx={{ textAlign: "end", display: { xs: "block", md: "none" } }}>
          <Button sx={{ mt: 3, width: "auto" }}> View all Milestones</Button>
        </Box> */}
        </Box>
        <Box sx={{ display: "none" }}>
          <Divider sx={{ mt: 3, background: theme.palette.secondary.dark }} />
          <Typography
            sx={{
              mt: 3,
            }}
            variant="h4"
            color="secondary"
          >
            Current Streaks
          </Typography>

          {constants.CURRENT_STREAKS.map((item, index) => {
            const streak = data.streaks?.find(
              ele => ele?.streakType?.streakName === item.type
            )?.daysMaintained;

            return (
              <Box key={index}>
                {" "}
                <Box sx={{ mt: 3.6, display: "flex", alignItems: "baseline" }}>
                  <Typography color="secondary">{item.text}</Typography>
                  &nbsp;
                  <Typography color="secondary" variant="h5">
                    {item.boldText}
                  </Typography>
                  <Typography
                    color="primary"
                    variant="h5"
                    sx={theme => ({
                      marginLeft: "auto",
                      background: sosPrimary[50],
                      borderRadius: theme.shape.standard_small.borderRadius,
                      padding: "4px",
                    })}
                  >
                    {streak || 0}
                  </Typography>
                </Box>{" "}
                {index !== constants.CURRENT_STREAKS.length - 1 ? (
                  <Divider
                    sx={{
                      mt: 2,

                      background: "rgba(118, 73, 160, 0.08)",
                    }}
                  />
                ) : (
                  ""
                )}
              </Box>
            );
          })}
        </Box>
      </Box>
    </Card>
  );
}
