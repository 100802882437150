import React, { useEffect, useState } from "react";
import { useHistory, generatePath } from "react-router-dom";
import { Typography, CardContent, Box, Stack, Chip, Avatar } from "@mui/material";
import JsxParser from "react-jsx-parser";
import SellOutlinedIcon from "@mui/icons-material/SellOutlined";
import VideoCameraBackOutlinedIcon from "@mui/icons-material/VideoCameraBackOutlined";
import EmojiObjectsOutlinedIcon from "@mui/icons-material/EmojiObjectsOutlined";

import { DARK_MESSAGE_ICON, DARK_NETWORK_ICON, DARK_UPLOAD_ICON, NEW_LINKEDIN_ICON, NEW_TWITTER_ICON } from "../../../constants";
import FullProfileWidget from "../../../components/ScoutingReport/FullProfileWidget";
import ROUTES_PATH from "../../../constants/routes";
import HighlightsModal from "./HighlightsModal";
import { CardEducation, CardContentPowerUp, BoxPowerUpTitle } from "./styles";
import { formatUrl } from "../../../utils/commonFunctions";
import { useDispatch } from "react-redux";
import { getFounderDetails } from "./functions";
import { getConversationWithID } from "../../../utils/messaging-helper";
import { updateCurrentConversation } from "../../../containers/Messaging/store/action-creators";
import { setCalUser } from "../../../modules/actions/meetingsAction";

export default function TeamMateCard({ teammate, teammateData, openFullProfile, toggleOpenFullProfile }) {
	const history = useHistory();
	const about = teammateData.about ? teammateData.about : "-";
	const [openHighlightsModal, setOpenHighlightsModal] = useState(false);
	const dispatch = useDispatch();

	const [powerStatusData, setPowerStatusData] = useState({
		statusType: "POWER_UP",
		isStarted: false,
		isCompleted: false,
	});

	const connectClick = () => {
		if (teammate.id) {
			history.push({
				pathname: generatePath(ROUTES_PATH.FOUNDER_PROFILE, {
					id: teammate.id,
				}),
			});
		} else {
			console.error("No id found in teammate data: ", teammate.id, teammate);
		}
	};

	const linkedinClick = () => {
		if (teammate.linkedinUrl) {
			window.open(teammate.linkedinUrl, "_blank");
		} else {
			console.log("linkedin URL undefined", teammate.linkedinUrl);
		}
	};

	const handleHighlightsModal = () => {
		setOpenHighlightsModal(!openHighlightsModal);
	};

	const handleReqMeeting = async () => {
		let founder = await getFounderDetails(teammateData?.id);
		if (founder) {
			dispatch(
				setCalUser({
					id: founder?.calUserId,
					name: founder?.name,
					email: founder?.email,
					picture: founder?.picture,
				})
			);
			history.push(ROUTES_PATH.MEETING_TYPES_SCHEDULE);
		}
	};

	const handleMessaging = async () => {
		let founder = await getFounderDetails(teammateData?.id);
		if (founder) {
			try {
				let conversationID = await getConversationWithID({
					idArray: [founder?.id],
				});
				dispatch(updateCurrentConversation(conversationID));
				history.push("/messages");
			} catch (e) {
				console.log("Could not retrieve conversation.", e);
			}
		}
	};

	return (
		<Box sx={{ boxSizing: "border-box", display: "flex", flexDirection: "column", rowGap: 4 }}>
			<Box display="flex" alignItems="center" justifyContent="center">
				<Avatar sx={{ height: "250px", width: "250px" }} src={teammateData.imageUrl} alt={teammateData.title} />
			</Box>

			<Box sx={{ px: 5, py: 4, backgroundColor: "white", borderRadius: "30px" }}>
				<Box display={"flex"} alignItems="center">
					{/*<Chip
						onClick={handleHighlightsModal}
						icon={<SellOutlinedIcon fontSize="small" sx={{ color: (theme) => theme.palette.secondary.white }} />}
						sx={{
							p: 2,
							color: (theme) => theme.palette.secondary.white,
							background: (theme) => theme.palette.secondary.black,
							"& .MuiChip-icon": {
								color: (theme) => theme.palette.secondary.white,
							},
							"&:hover": {
								cursor: "pointer",
							},
						}}
						label={<Typography variant="chip_label">9 Exp. Highlights</Typography>}
					/>*/}
					{/*<Box ml="auto" gap={1} alignItems="center" display={"flex"} sx={{ alignSelf: "flex-end" }}>
						<VideoCameraBackOutlinedIcon sx={{ color: (theme) => theme.palette.secondary.black }} />
						<Box
							component={"img"}
							src={DARK_NETWORK_ICON}
							alt="network"
							onClick={() => {
								connectClick();
							}}
						/>
						
								// TODO -- We do not have the 'share' functionality defined
							<Box component={"img"} src={DARK_UPLOAD_ICON} alt="network" />
						<Box component={"img"} src={DARK_MESSAGE_ICON} alt="network" />
					</Box>*/}
				</Box>
				<Box display="flex" flexDirection="column" justifyContent="space-between" height="400px" mt={2}>
					<Box>
						<Box display="flex" alignItems="center">
							<BoxPowerUpTitle>
								<Typography variant="card_name" sx={{ color: (theme) => theme.palette.secondary.black }}>
									{teammateData.name}
								</Typography>
							</BoxPowerUpTitle>
							<Box ml="auto" gap={1} alignItems="center" display={"flex"} sx={{ alignSelf: "stretch", alignItems: "flex-start", pt: 1 }}>
								<VideoCameraBackOutlinedIcon sx={{ color: (theme) => theme.palette.secondary.black }} onClick={handleReqMeeting} />
								<Box
									component={"img"}
									src={DARK_NETWORK_ICON}
									alt="network"
									onClick={() => {
										connectClick();
									}}
								/>
								{/*// TODO -- We do not have the 'share' functionality defined
									<Box component={"img"} src={DARK_UPLOAD_ICON} alt="network" />*/}
								<Box component={"img"} src={DARK_MESSAGE_ICON} alt="network" onClick={handleMessaging} />
							</Box>
							<Box display={"flex"} gap={1} alignItems="center" sx={{ ml: "auto" }} onClick={linkedinClick}>
								{teammateData?.linkedinUrl && (
									<Box
										onClick={() => window.open(formatUrl(teammateData?.linkedinUrl), "_blank")}
										component={"img"}
										src={NEW_LINKEDIN_ICON}
										alt="linkedin"
										sx={{ height: "35px", width: "35px" }}
									/>
								)}
								{teammateData?.twitterUrl && (
									<Box
										onClick={() => window.open(formatUrl(teammateData?.twitterUrl), "_blank")}
										component={"img"}
										src={NEW_TWITTER_ICON}
										alt="twitter"
										sx={{ height: "35px", width: "35px" }}
									/>
								)}
								<Box />
							</Box>
						</Box>

						<Box display={"flex"} gap={2} alignItems="center" sx={{ mt: 2 }}>
							<Typography variant="testHeading" sx={{ color: (theme) => theme.palette.secondary.black }}>
								{teammateData && teammateData?.role === "STARTUP_OWNER" ? "Founder" : "Teammate"}
							</Typography>
							{/* 
									// TODO -- We do not have role information at this time.
								*/}
							{/*<Box display="flex" alignItems="center" sx={{ color: theme => theme.palette.secondary.black }}>
									<EmojiObjectsOutlinedIcon sx={{ color: theme => theme.palette.secondary.black }} fontSize="large" />
									<Typography variant="custom070">Co-Founder</Typography>
								</Box>*/}
						</Box>
						<Box mt={2} maxHeight="192px">
							<Typography
								variant="title_medium"
								sx={{
									color: (theme) => theme.palette.secondary.black,
									overflow: "hidden",
									textOverFlow: "ellipsis",
									display: "-webkit-box",
									WebkitLineClamp: "8",
									WebkitBoxOrient: "vertical",
								}}
							>
								{/* {teammateData.about ? teammateData.message : "-"} */}
								<JsxParser components={{ Typography }} jsx={about} />
							</Typography>
						</Box>
					</Box>
					<FullProfileWidget teammateData={teammateData} toggleOpenFullProfile={toggleOpenFullProfile} />
				</Box>
			</Box>
			{openHighlightsModal && <HighlightsModal open={openHighlightsModal} onClose={handleHighlightsModal} />}
		</Box>
	);
}
