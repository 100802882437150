import { DialogTitle, Dialog, IconButton, Typography, Divider, DialogContent, DialogActions } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import theme from "../../constants/Theme";

export default function DialogComponent({
	isOpen,
	onClose,
	title,
	children,
	dialogAction,
	titleVariant,
	contentStyle,
	footerStyle,
	disableBackDropClick,
	isHeadeBottom = true,
	btnCloseStyle,
	...props
}) {
	const handleClose = () => {
		onClose();
	};

	return (
		<Dialog open={isOpen} onClose={disableBackDropClick ? () => {} : onClose} {...props}>
			{title && (
				<DialogTitle
					sx={{
						borderBottom: isHeadeBottom ? `1px solid ${theme.palette.secondary.dark}` : "0",
						display: "flex",
						justifyContent: "space-between",
						alignItems: isHeadeBottom ? "center" : "baseline",
					}}
				>
					<Typography variant={titleVariant}>{title}</Typography>

					<IconButton
						aria-label="close"
						onClick={handleClose}
						sx={{
							color: theme.palette.secondary.dark,
							...btnCloseStyle,
						}}
					>
						<CloseIcon />
					</IconButton>
				</DialogTitle>
			)}
			<DialogContent
				sx={{
					...contentStyle,
				}}
			>
				{children}
			</DialogContent>
			{dialogAction && (
				<DialogActions
					sx={{
						borderTop: `1px solid ${theme.palette.secondary.greyLight}`,
						...footerStyle,
					}}
				>
					{dialogAction}
				</DialogActions>
			)}
		</Dialog>
	);
}
