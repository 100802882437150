import services from "../services";

export const getPowerUpsCompletedCount = async (powerUpId) => {
	try {
		const res = await services.getMyWorkDetails(powerUpId, 1, "", "");
		const powerUps = res?.data?.data;
		try {
			const powerUpsCompletedCount = powerUps.filter((powerUp) => powerUp?.isCompleted === true).length; // ===
			return powerUpsCompletedCount;
		} catch (err) {
			console.error("getPowerUpsCompletedCount error: ", err);
			return 0;
		}
	} catch (err) {
		console.error("getPowerUpsCompletedCount error: ", err);
		return 0;
	}
};

export const getTotalPowerUpsCompletedCount = async () => {
	let powerUpsCompletedCount = 0;
	const count1 = await getPowerUpsCompletedCount(1);
	const count2 = await getPowerUpsCompletedCount(2);
	const count3 = await getPowerUpsCompletedCount(3);
	const count5 = await getPowerUpsCompletedCount(5);
	const count6 = await getPowerUpsCompletedCount(6);
	const count7 = await getPowerUpsCompletedCount(7);
	const count9 = await getPowerUpsCompletedCount(9);
	const count20 = await getPowerUpsCompletedCount(20);
	powerUpsCompletedCount = count1 + count2 + count3 + count5 + count6 + count7 + count9 + count20;
	return powerUpsCompletedCount;
};
