import React, { useEffect } from "react";
import { Box } from "@mui/system";

import theme from "../../../../constants/Theme";
import { STARTUPOSFADEDICON, STARTUPOSFILLEDICON } from "../../../../constants";
import { getColorForPercentage } from "../../../../utils/getPercentageColor";

const ScoreProgress = ({ id, maxValue, textTheme, value = 250 }) => {
  useEffect(() => {
    function positionTheDot(percentage) {
      // Get path length
      var path = document.getElementById("theMotionPath");
      var pathLen = path.getTotalLength();

      var pt = path.getPointAtLength(percentage * pathLen);

      // Position the red dot at this point
      var dot = document.getElementById(id + "-dot");
      dot.setAttribute("transform", "translate(" + pt.x + "," + pt.y + ")");
      dot.setAttribute("stroke", getColorForPercentage(percentage));

      var svgTextElement = document.getElementById(id + "-dialtext");
      var textNode = svgTextElement.childNodes[0];
      textNode.nodeValue = Math.round(850 * percentage);

      const svgImage =
        id === "progressScoreMobile" ? STARTUPOSFILLEDICON : STARTUPOSFADEDICON;

      document.getElementById(id + "-icon").setAttribute("href", svgImage);
    }
    value && maxValue && positionTheDot(value / maxValue);
  }, [value, id]);

  return (
    <Box
      sx={{
        maxWidth: "120px",
        maxHeight: "120px",
        mx: "auto",
      }}
    >
      <svg id="mainarc" viewBox="88 82 200 200">
        <defs>
          <linearGradient
            spreadMethod="pad"
            id={`${id}-gradient`}
            x1="0%"
            y1="0%"
            x2="100%"
            y2="0%"
          >
            <stop offset="0" stop-color="#F32836" />
            <stop offset=".20" stop-color="#7B61FF" />
            <stop offset=".35" stop-color="#478FC8" />
            <stop offset=".70" stop-color="#00CE7D" />
          </linearGradient>
          <font>
            <font-face font-family="PoppinsSemiBold" />
          </font>
        </defs>

        <g transform="rotate(-40 200 190)">
          <path
            d="M 100 200 A 100 100 180 0 1 300 200"
            stroke={`url(#${id}-gradient)`}
            stroke-width="10"
            fill="none"
            id="theMotionPath"
            stroke-linecap="round"
          />
          <circle
            cx="0"
            cy="0"
            r="8"
            fill="white"
            stroke="red"
            stroke-width="6"
            id={id + "-dot"}
          />

          <g transform="rotate(40 230 200)">
            <image
              href={STARTUPOSFADEDICON}
              style={{
                width: "50%",
                height: "50%",
              }}
              x="150"
              y="150"
              id={id + "-icon"}
            ></image>

            <text
              x="200"
              y="230"
              text-anchor="middle"
              id={`${id}-dialtext`}
              font-size="65px"
              font-family="PoppinsSemiBold"
              fill={textTheme}
            >
              0
            </text>
            <text
              x="200"
              y="270"
              text-anchor="middle"
              font-size="18px"
              font-family="PoppinsRegular"
              fill={textTheme}
            >
              Points
            </text>
          </g>
        </g>
      </svg>
    </Box>
  );
};

export default ScoreProgress;
