import { createTheme } from "@mui/material/styles";

export const sosPrimary = {
	900: "#311b65",
	800: "#402484",
	700: "#472893",
	600: "#4f2ca3",
	500: "#5630B2",
	400: "#5630B2",
	300: "#744ecf",
	200: "#c9baec",
	100: "#d5c9f1",
	50: "rgba(106, 117, 117, 0.12)",
	25: "rgba(106, 117, 117, 0.08)",
};

export const getThemePalette = (colorUpdates = {}) => {
	const defaultPalette = {
		palette: {
			type: "light",
			primary: {
				main: "#7B61FF",
				light: "#B7A8FF",
				dark: "#5644B2",
				// ********* //
				primary25: "#f2efff",
				main_new: "#7B61FF",
				sysLight: "#5630B2",
				gray74: "#bdbdbd",
				gradient: "linear-gradient(92.58deg, #592C82 0%, #B655CC 100%)",
				surface1: "linear-gradient(0deg, rgba(118, 73, 160, 0.05), rgba(118, 73, 160, 0.05)),#FCFCFC",
				surface2: "linear-gradient(0deg, rgba(118, 73, 160, 0.08), rgba(118, 73, 160, 0.08)), #FCFCFC",
				surface3: "linear-gradient(0deg, rgba(118, 73, 160, 0.11), rgba(118, 73, 160, 0.11)), #FCFCFC",
				surface4: "linear-gradient(0deg, rgba(118, 73, 160, 0.12), rgba(118, 73, 160, 0.12)), #FCFCFC",
				homeBlueBackground: "#f5f8fa",
				homeBackground: "linear-gradient(92.58deg, #592c82 0%, #b655cc 100%)",
				elevationLight2: "0px 1px 2px rgb(0 0 0 / 30%), 0px 1px 3px 1px rgb(0 0 0 / 15%)",
				elevationLight2Old: "0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)",
				elevationLight3: "0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
				surfaceDp04: "0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2)",
				founderWorkspace: "linear-gradient(180deg, rgba(255, 255, 255, 0.351351) 0%, #FFFFFF 30.67%)",
				founderWorkspaceFlair:
					"linear-gradient(180deg, rgba(255, 255, 255, 0) 60.42%, rgba(255, 255, 255, 0.351351) 67.71%, #FFFFFF 76.04%), linear-gradient(90deg, rgba(255, 255, 255, 0.93) 32.29%, rgba(255, 255, 255, 0.74504) 54.69%, rgba(255, 255, 255, 0.504592) 81.25%, rgba(255, 255, 255, 0.35) 100%)",
				surface1_light:
					"linear-gradient(0deg, rgba(106, 117, 117, 0.02), rgba(106, 117, 117, 0.02)), linear-gradient(0deg, rgba(245, 248, 250, 0.05), rgba(245, 248, 250, 0.05)), #FCFCFC",
				sys_light_outline: "linear-gradient(0deg, rgba(245, 248, 250, 0.6), rgba(245, 248, 250, 0.6)), #6A7575",
				read_only_light_surface2:
					"linear-gradient(0deg, rgba(106, 117, 117, 0.02), rgba(106, 117, 117, 0.02)), linear-gradient(0deg, rgba(245, 248, 250, 0.08), rgba(245, 248, 250, 0.08)), #FCFCFC",
				sys_light_inverse: "#E0B7FF",
				sys_light_background: "rgba(86, 48, 178, 0.05)",
				primaryPurple: "#7B61FF",
				primaryPink: "#DE0085",
				primaryMelon: "#EC6C67",
				primaryMint: "#AFDDD0",
				primaryYellow: "#FFD13F",
				primaryBlue: "#335AB4",
				bgGreen: "#01CF7E",
				grayBorder: "#DDDDDD",
			},
			secondary: {
				main: "#000",
				light: "#667085",
				dark: "#000",

				// ****** //
				white: "#FFFFFF",
				mutedBackground: "#EEEEEE",
				//
				secondaryPurple: "#DED7FF",
				//main: "#4B454E",
				//light: "rgba(124, 117, 127, 0.16)",
				lighter: "rgba(124, 117, 127, 0.08)",
				//dark: "#7C757F",
				black: "#000000",
				borderLight: "#D3D3D3",
				danger: "#BD3D21",
				greyBg: "rgba(106, 117, 117, 0.08)",
				grey: "#9AA0A6",
				hoverBg: "#f3f4f4",
				blueChalk: "#F2DAFF",
				borderLight: "#E9DFEB",
				outline: "#6A7575",
				outline2: "#EAEAEF",
				outlineLight: "#7C757F29",
				outlineXLight: "#C4C4C4",
				textFieldBorder: "#CFD0D7",
				backgroundGradient: "#f5f2f7",
				progressGradient1: "#FFD141",
				progressGradient2: "#E97300",
				primaryContainerLight: "#F2DAFF",
				lightOnPrimaryContainer: "#2C0051",
				subTitle: "#1A73E8",
				redLight: "#FFDAD6",
				quickNoteBg: "#FCF0C8",
				helperText: "#666C7E",
				appBarGray: "#666C7E",
				cuteCrab: "#DC4141",
				purpleDark: "#05A097",
				cyanBlue: "#0079E9",
				customOrange: "#E97300",
				greyLight: "rgba(106, 117, 117, 1)",
				lavender: "#E9E9EA",
				korophKoal: "#0A141B",
				disabled: "linear-gradient(0deg, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95)), #0A141B",
				borderLightNew: "#D3D3D3",
				greyTitle: "rgba(170, 170, 170, 1)",
				lightBg: "rgba(255, 255, 255, 0.6)",
				filterBg: "linear-gradient(90deg, #7B61FF -0.87%, #A32FFF 99.13%)",
				lightGrey: "#FAFAFA",
				gridGrey: "#797979",
				selectNoOutlineShadow: "rgb(0 0 0 / 15%) 0px 5px 24px",
				filterGray: "#CCCCCC",
				chipBg: "#F5F5F5",
				chipGrayBg: "#EEEEEE",
				bgGray: "#F5F9FA",
				disabledColor: "#1F1F1F1F",
				errorTextFieldRed: "rgba(240, 68, 56, 0.30)",
				errorTextFieldInfoRed: "#F04438",
				blue500: "#095CED",
			},
			tertiary: {
				main: "#DE0085",
				light: "#FCCEEE",
				dark: "#DE0085",
			},
			gray: {
				main: "#F2F4F7",
				light: "#F2F4F7",
				dark: "#EAECF0",
			},
			warning: {
				main: "#F79009",
				light: "#FEDF89",
				dark: "#F79009",

				// ***** //
				yellow: "#FBC000",
			},
			success: {
				main: "#12B76A",
				light: "#A6F4C5",
				dark: "#12B76A",

				// ***** //
				dark: "#006D3E",
				xDark: "#00894F",
				xLight: "#B2ED09",
				xxLight: "#C1FFD6",
			},
			error: {
				main: "#F04438",
				light: "#FECDCA",
				dark: "#F04438",

				// ****** //
				xLight: "#FFDAD4",
			},

			text: {
				primary: "#1D1B1E",
				secondary: "#4B454E",
				link: "#1A73E8",
				background: "#FCFCFC",
				formPrimary: "#5F6377",
				inactive: "#AFAAAE",
				readOnlyGreen: "#5BFFA8",
				custom070: "#89A8FF",
				custom050: "#4270E4",
				custom030: "#003DAA",
				custom370: "#00C475",
				custom3: "#006D3E",
				custom0: "#2156CA",
				blue: "#1A73E8",
				dark: "#211829",
				lightGray: "#666C7E",
				gray: "#666C7E",
				darkBlue: "#5630B2",
				red: "#F81E33",
				purple: "#FF00C7",
				pinkDark: "#D63062",
				yellowDark: "#E9A800",
				yellowLight: "#E9D200",
				waterBlue: "#00E9B1",
				ceruleanBlue: "#612FD7",
				screaminGreen: "#2BA917",
				Pelorous: "#B89E19",
				rgbred: "#AE2A2A",
				pinkLight: "#ED82EF",
				blueDarkShade: "#2E899D",
				voilet: "#9D078E",
				pinkGlow: "#FF4676",
				onSurfaceVariant: "#464B4E",
				atomic: "#464B4E",
				slateGray: "#322F33",
				lightOnsurface: "#464B4E",
				blueFaint: "#8293EF",
				voiletDark: "#9A00E7",
				grayPrimary: "#616161",
				textGrey: "#6F6F6F",
				textOrange: "#D6840B",
				textGreen: "#32961A",
				textBlue: "#615FE3",
				textBgGrey: "rgba(111, 111, 111, 0.1)",
				textBgOrange: "rgba(214, 132, 11, 0.1)",
				textBgGreen: "rgba(50, 150, 26, 0.1)",
				textBgBlue: "rgba(97, 95, 227, 0.1)",
				investorDashGrayLabel: "#6B6B6B",
				investorDashGrayLabelMuted: "#AAAAAA",
				navLabel: "#5F5F5F",
				textGreyMid: "#888888",
			},

			focus: {
				main: "#d1eaff",
			},

			hover: {
				main: "#40a9ff",
			},
			tabIndicator: { main: "#F32836" },
			button: {
				main: "#E0B7FF",
				dark: "#45166E",
				borderLight: "#968E99",
				light: "#E7E1E5",
				link: "#0047BB",
				lightSecondary: "#EDDDF6",
				success: "#006D3E",
				greyLight: "rgba(70, 75, 78, 0.8)",
			},
			containerBorder: {
				light: "#968E99",
				blue: "#2156CA",
				background1: "#DAE2FF",
				lightOutline: "rgba(106, 117, 117, 0.5)",
			},
			containers: {
				surface5: "rgba(118, 73, 160, 0.14)",
				onSurfaceHighEmphasis: "rgba(0, 0, 0, 0.87)",
				custom0Container: "#DAE2FF",
				backgroundGray: "#FBFBFB",
				//SOSBlueLight: "#F5F9FA",
			},
			sos: {
				blueLight: "#F5F9FA",
				gray400: "#98A2B3",
				gray100: "#F2F4F7",
				gray700: "#344054",
				gray600: "#475467",
				gray200: "#EAECF0",
				gray500: "#667085",
				gray300: "#D0D5DD",
			},
			bidashboard: {
				cardTitle: "#475467",
				cardChipText: "#027A48",
				cardChipBg: "#ECFDF3",
				cardLatestParamsText: "#98A2B3",
				paramLightBlue: "#2E90FA",
				paramLightGreen: "#66C61C",
				paramRed: "#F63D68",
				paramBlue: "#444CE7",
				paramOrange: "#EF6820",
				pink: "#EE46BC",
				grey: "#D0D5DD",
				skyBlue: "#22CCEE",
				cellBg: "#F2EFFF",
				gradientStart: "#7F56D9",
				gradientStop: "rgba(127, 86, 217, 0)",
				btnPurple: "#B7A8FF",
				disbledRow: "#EAECF0",
			},
		},
		shadows: {
			0: "none",
			1: "0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
			4: "none",
			8: "none",
			24: "none",
		},
	};

	// Deep merge the default palette with any color updates
	return {
		...deepMerge(defaultPalette, colorUpdates),
	};
};

// Utility function for deep merging objects
const deepMerge = (target, source) => {
	Object.keys(source).forEach((key) => {
		if (source[key] && typeof source[key] === "object") {
			if (!target[key]) {
				Object.assign(target, { [key]: source[key] });
			} else {
				deepMerge(target[key], source[key]);
			}
		} else {
			Object.assign(target, { [key]: source[key] });
		}
	});
	return target;
};

//export const themePalette = createTheme({
//	palette: {
//		type: "light",
//		primary: {
//			main: "#7B61FF",
//			light: "#B7A8FF",
//			dark: "#5644B2",

//			// ********* //
//			main_new: "#7B61FF",
//			sysLight: "#5630B2",
//			gray74: "#bdbdbd",
//			gradient: "linear-gradient(92.58deg, #592C82 0%, #B655CC 100%)",
//			surface1: "linear-gradient(0deg, rgba(118, 73, 160, 0.05), rgba(118, 73, 160, 0.05)),#FCFCFC",
//			surface2: "linear-gradient(0deg, rgba(118, 73, 160, 0.08), rgba(118, 73, 160, 0.08)), #FCFCFC",
//			surface3: "linear-gradient(0deg, rgba(118, 73, 160, 0.11), rgba(118, 73, 160, 0.11)), #FCFCFC",
//			surface4: "linear-gradient(0deg, rgba(118, 73, 160, 0.12), rgba(118, 73, 160, 0.12)), #FCFCFC",
//			homeBlueBackground: "#f5f8fa",
//			homeBackground: "linear-gradient(92.58deg, #592c82 0%, #b655cc 100%)",
//			elevationLight2: "0px 1px 2px rgb(0 0 0 / 30%), 0px 1px 3px 1px rgb(0 0 0 / 15%)",
//			elevationLight2Old: "0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)",
//			elevationLight3: "0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
//			surfaceDp04: "0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2)",
//			founderWorkspace: "linear-gradient(180deg, rgba(255, 255, 255, 0.351351) 0%, #FFFFFF 30.67%)",
//			founderWorkspaceFlair:
//				"linear-gradient(180deg, rgba(255, 255, 255, 0) 60.42%, rgba(255, 255, 255, 0.351351) 67.71%, #FFFFFF 76.04%), linear-gradient(90deg, rgba(255, 255, 255, 0.93) 32.29%, rgba(255, 255, 255, 0.74504) 54.69%, rgba(255, 255, 255, 0.504592) 81.25%, rgba(255, 255, 255, 0.35) 100%)",
//			surface1_light:
//				"linear-gradient(0deg, rgba(106, 117, 117, 0.02), rgba(106, 117, 117, 0.02)), linear-gradient(0deg, rgba(245, 248, 250, 0.05), rgba(245, 248, 250, 0.05)), #FCFCFC",
//			sys_light_outline: "linear-gradient(0deg, rgba(245, 248, 250, 0.6), rgba(245, 248, 250, 0.6)), #6A7575",
//			read_only_light_surface2:
//				"linear-gradient(0deg, rgba(106, 117, 117, 0.02), rgba(106, 117, 117, 0.02)), linear-gradient(0deg, rgba(245, 248, 250, 0.08), rgba(245, 248, 250, 0.08)), #FCFCFC",
//			sys_light_inverse: "#E0B7FF",
//			sys_light_background: "rgba(86, 48, 178, 0.05)",
//			primaryPurple: "#7B61FF",
//			primaryPink: "#DE0085",
//			primaryMelon: "#EC6C67",
//			primaryMint: "#AFDDD0",
//			primaryYellow: "#FFD13F",
//			primaryBlue: "#335AB4",
//			bgGreen: "#01CF7E",
//			grayBorder: "#DDDDDD",
//		},
//		secondary: {
//			main:"#000",
//			light: "#667085",
//			dark:"#000",

//			// ****** //
//			white: "#FFFFFF",
//			mutedBackground: "#EEEEEE",
//			//
//			secondaryPurple: "#DED7FF",
//			//main: "#4B454E",
//			//light: "rgba(124, 117, 127, 0.16)",
//			lighter: "rgba(124, 117, 127, 0.08)",
//			//dark: "#7C757F",
//			black: "#000000",
//			borderLight: "#D3D3D3",
//			danger: "#BD3D21",
//			greyBg: "rgba(106, 117, 117, 0.08)",
//			grey: "#9AA0A6",
//			hoverBg: "#f3f4f4",
//			blueChalk: "#F2DAFF",
//			borderLight: "#E9DFEB",
//			outline: "#6A7575",
//			outline2: "#EAEAEF",
//			outlineLight: "#7C757F29",
//			outlineXLight: "#C4C4C4",
//			textFieldBorder: "#CFD0D7",
//			backgroundGradient: "#f5f2f7",
//			progressGradient1: "#FFD141",
//			progressGradient2: "#E97300",
//			primaryContainerLight: "#F2DAFF",
//			lightOnPrimaryContainer: "#2C0051",
//			subTitle: "#1A73E8",
//			redLight: "#FFDAD6",
//			quickNoteBg: "#FCF0C8",
//			helperText: "#666C7E",
//			appBarGray: "#666C7E",
//			cuteCrab: "#DC4141",
//			purpleDark: "#05A097",
//			cyanBlue: "#0079E9",
//			customOrange: "#E97300",
//			greyLight: "rgba(106, 117, 117, 1)",
//			lavender: "#E9E9EA",
//			korophKoal: "#0A141B",
//			disabled: "linear-gradient(0deg, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95)), #0A141B",
//			borderLightNew: "#D3D3D3",
//			greyTitle: "rgba(170, 170, 170, 1)",
//			lightBg: "rgba(255, 255, 255, 0.6)",
//			filterBg: "linear-gradient(90deg, #7B61FF -0.87%, #A32FFF 99.13%)",
//			lightGrey: "#FAFAFA",
//			gridGrey: "#797979",
//			selectNoOutlineShadow: "rgb(0 0 0 / 15%) 0px 5px 24px",
//			filterGray: "#CCCCCC",
//			chipBg: "#F5F5F5",
//			chipGrayBg: "#EEEEEE",
//			bgGray: "#F5F9FA",
//			disabledColor: "#1F1F1F1F",
//			errorTextFieldRed: "rgba(240, 68, 56, 0.30)",
//			errorTextFieldInfoRed: "#F04438",
//		},
//		tertiary:{
//			main:"#DE0085",
//			light: "#FCCEEE",
//			dark:"#DE0085",
//		},
//		gray: {
//			main:"#F2F4F7",
//			light: "#F2F4F7",
//			dark:"#EAECF0",
//		},
//		warning: {
//			main: "#F79009",
//			light: "#FEDF89",
//			dark: "#F79009",

//			// ***** //
//			yellow: "#FBC000",
//		},
//		success: {
//			main: "#12B76A",
//			light: "#A6F4C5",
//			dark: "#12B76A",

//			// ***** //
//			dark: "#006D3E",
//			xDark: "#00894F",
//			xLight: "#B2ED09",
//			xxLight: "#C1FFD6",
//		},
//		error: {
//			main: "#F04438",
//			light: "#FECDCA",
//			dark: "#F04438",

//			// ****** //
//			xLight: "#FFDAD4",
//		},

//		text: {
//			primary: "#1D1B1E",
//			secondary: "#4B454E",
//			link: "#1A73E8",
//			background: "#FCFCFC",
//			formPrimary: "#5F6377",
//			inactive: "#AFAAAE",
//			readOnlyGreen: "#5BFFA8",
//			custom070: "#89A8FF",
//			custom050: "#4270E4",
//			custom030: "#003DAA",
//			custom370: "#00C475",
//			custom3: "#006D3E",
//			custom0: "#2156CA",
//			blue: "#1A73E8",
//			dark: "#211829",
//			lightGray: "#666C7E",
//			gray: "#666C7E",
//			darkBlue: "#5630B2",
//			red: "#F81E33",
//			purple: "#FF00C7",
//			pinkDark: "#D63062",
//			yellowDark: "#E9A800",
//			yellowLight: "#E9D200",
//			waterBlue: "#00E9B1",
//			ceruleanBlue: "#612FD7",
//			screaminGreen: "#2BA917",
//			Pelorous: "#B89E19",
//			rgbred: "#AE2A2A",
//			pinkLight: "#ED82EF",
//			blueDarkShade: "#2E899D",
//			voilet: "#9D078E",
//			pinkGlow: "#FF4676",
//			onSurfaceVariant: "#464B4E",
//			atomic: "#464B4E",
//			slateGray: "#322F33",
//			lightOnsurface: "#464B4E",
//			blueFaint: "#8293EF",
//			voiletDark: "#9A00E7",
//			grayPrimary: "#616161",
//			textGrey: "#6F6F6F",
//			textOrange: "#D6840B",
//			textGreen: "#32961A",
//			textBlue: "#615FE3",
//			textBgGrey: "rgba(111, 111, 111, 0.1)",
//			textBgOrange: "rgba(214, 132, 11, 0.1)",
//			textBgGreen: "rgba(50, 150, 26, 0.1)",
//			textBgBlue: "rgba(97, 95, 227, 0.1)",
//			investorDashGrayLabel: "#6B6B6B",
//			investorDashGrayLabelMuted: "#AAAAAA",
//			navLabel: "#5F5F5F",
//			textGreyMid: "#888888",
//		},

//		focus: {
//			main: "#d1eaff",
//		},

//		hover: {
//			main: "#40a9ff",
//		},
//		tabIndicator: { main: "#F32836" },
//		button: {
//			main: "#E0B7FF",
//			dark: "#45166E",
//			borderLight: "#968E99",
//			light: "#E7E1E5",
//			link: "#0047BB",
//			lightSecondary: "#EDDDF6",
//			success: "#006D3E",
//			greyLight: "rgba(70, 75, 78, 0.8)",
//		},
//		containerBorder: {
//			light: "#968E99",
//			blue: "#2156CA",
//			background1: "#DAE2FF",
//			lightOutline: "rgba(106, 117, 117, 0.5)",
//		},
//		containers: {
//			surface5: "rgba(118, 73, 160, 0.14)",
//			onSurfaceHighEmphasis: "rgba(0, 0, 0, 0.87)",
//			custom0Container: "#DAE2FF",
//			backgroundGray: "#FBFBFB",
//			//SOSBlueLight: "#F5F9FA",
//		},
//		sos: {
//			blueLight: "#F5F9FA",
//			gray400: "#98A2B3",
//			gray100: "#F2F4F7",
//			gray700: "#344054",
//			gray600: "#475467",
//			gray200: "#EAECF0",
//			gray500: "#667085",
//			gray300: "#D0D5DD",
//		},
//		bidashboard: {
//			cardTitle: "#475467",
//			cardChipText: "#027A48",
//			cardChipBg: "#ECFDF3",
//			cardLatestParamsText: "#98A2B3",
//			paramLightBlue: "#2E90FA",
//			paramLightGreen: "#66C61C",
//			paramRed: "#F63D68",
//			paramBlue: "#444CE7",
//			paramOrange: "#EF6820",
//			pink: "#EE46BC",
//			grey: "#D0D5DD",
//			skyBlue: "#22CCEE",
//			cellBg: "#F2EFFF",
//			gradientStart: "#7F56D9",
//			gradientStop: "rgba(127, 86, 217, 0)",
//			btnPurple: "#B7A8FF",
//			disbledRow: "#EAECF0",
//		},
//	},
//	shadows: {
//		0: "none",
//		1: "0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
//		4: "none",
//		8: "none",
//		24: "none",
//	},
//});
