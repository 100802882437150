import { Text, StyleSheet, Image as ReactPDFImage, Font, View } from "@react-pdf/renderer";
import PoppinsRegular from "./Poppins-Regular.ttf";
import PoppinsSemiBold from "./Poppins-SemiBold.ttf";

import Logo from "./LetterHeadLogo.png";
import PowerUPLogo from "./PowerUpLogo.png";
import html2canvas from "html2canvas";
import ReactDOM from "react-dom";
import React from "react";
import theme from "../../constants/Theme";
import { ThemeProvider } from "@mui/material/styles";
import { Box } from "@mui/material";

Font.register({
	family: "PoppinsRegular",
	format: "truetype",
	src: PoppinsRegular,
});

Font.register({
	family: "PoppinsSemiBold",
	format: "truetype",
	src: PoppinsSemiBold,
});

export const styles = StyleSheet.create({
	body: {
		paddingTop: 35,
		paddingBottom: 65,
		paddingHorizontal: 35,
	},
	title: {
		textAlign: "justify",
		fontSize: 16,
		fontFamily: "PoppinsSemiBold",
	},
	subtitle: {
		textAlign: "justify",
		marginVertical: 12,
		fontSize: 14,
		fontFamily: "PoppinsSemiBold",
	},
	subtitleH2: {
		textAlign: "justify",
		marginVertical: 14,
		fontSize: 16,
		fontFamily: "PoppinsSemiBold",
	},
	subtitleH3: {
		textAlign: "justify",
		marginVertical: 12,
		fontSize: 14,
		fontFamily: "PoppinsSemiBold",
	},
	subtitleH5: {
		textAlign: "justify",
		marginVertical: 8,
		fontSize: 12,
		fontFamily: "PoppinsSemiBold",
	},
	text: {
		fontSize: 12,
		textAlign: "justify",
		fontFamily: "PoppinsRegular",
	},
	image: {
		opacity: 0.5,
		marginVertical: 5,
		width: 150,
	},
	header: {
		fontSize: 10,
		marginBottom: 20,
		textAlign: "center",
		color: "grey",
	},
	footerCopy: {
		fontFamily: "PoppinsRegular",
		textAlign: "right",
		position: "absolute",
		fontSize: 10,
		bottom: 30,
		right: 35,
		color: "grey",
	},
	pageNumber: {
		fontFamily: "PoppinsRegular",
		position: "absolute",
		fontSize: 12,
		bottom: 30,
		left: 0,
		right: 0,
		textAlign: "center",
		color: "grey",
	},
	divider: {
		marginTop: 10,
		marginBottom: 10,
		borderBottom: "1px solid black",
	},
});

export const parseHtmlString = (htmlString) => {
	htmlString = htmlString.replace(/::-webkit-scrollbar[\s\S]*?\{[\s\S]*?\}/g, "");

	const doc = new DOMParser().parseFromString(htmlString, "text/html");
	const elements = doc.body.children;
	const result = [];

	for (let i = 0; i < elements.length; i++) {
		const element = elements[i];
		const tagName = element.tagName.toLowerCase();
		if (tagName === "pdfimg") {
			// <pdfimg/> was used to maintain the legacy code, but allow for component image processing.
			// We now need to remove and separate
			const htmlElement = element.outerHTML.replace(/pdfimg/g, "div"); //remove the <pdfimg/> tags
			result.push({ tagName, htmlElement });
		} else {
			const textContent = element.textContent.trim();
			result.push({ tagName, textContent });
		}
	}
	return result;
};

export const LetterHead = () => (
	<View
		style={{
			position: "fixed",
			top: 0,
			left: 0,
			right: 0,
			marginBottom: 20,
			borderBottom: "2px solid gray",
		}}
		fixed
	>
		<ReactPDFImage style={styles.image} src={Logo} />
	</View>
);

export const PowerUPLetterHead = () => (
	<View
		style={{
			position: "fixed",
			top: 0,
			left: 0,
			right: 0,
			marginBottom: 20,
			borderBottom: "2px solid gray",
			flexDirection: "row",
			justifyContent: "space-between",
			alignItems: "center",
		}}
		fixed
	>
		<ReactPDFImage style={styles.image} src={Logo} />
		<ReactPDFImage style={[styles.image, { width: 75, opacity: 1 }]} src={PowerUPLogo} />
	</View>
);

export const Title = ({ children }) => <Text style={styles.title}>{children}</Text>;

// Example of style merging if we need it
//export const Subtitle = ({ children, styleProps }) => {
//	const mergedStyles = styleProps ? [styles.subtitle, styleProps] : styles.subtitle;
//	return <Text style={mergedStyles}>{children}</Text>;
//};
export const Subtitle = ({ children }) => <Text style={styles.subtitle}>{children}</Text>;
export const SubtitleH2 = ({ children }) => <Text style={styles.subtitleH2}>{children}</Text>;
export const SubtitleH3 = ({ children }) => <Text style={styles.subtitleH3}>{children}</Text>;
export const SubtitleH5 = ({ children }) => <Text style={styles.subtitleH5}>{children}</Text>;

export const BodyCopy = ({ children }) => <Text style={styles.text}>{children}</Text>;

export const PDFDivider = () => <View style={styles.divider} />;

//export const renderElement = ({ tagName, textContent }, index) => {
//	switch (tagName) {
//		case "h1":
//			return <Title key={index}>{textContent}</Title>;
//		case "h2":
//			return <SubtitleH2 key={index}>{textContent}</SubtitleH2>;
//		case "h3":
//			return <SubtitleH3 key={index}>{textContent}</SubtitleH3>;
//		case "h5":
//			return <SubtitleH5 key={index}>{textContent}</SubtitleH5>;
//		case "p":
//			return <BodyCopy key={index}>{textContent}</BodyCopy>;
//		case "hr":
//			return <View style={styles.divider} key={index} />;
//		default:
//			return <BodyCopy key={index}>{textContent}</BodyCopy>;

export const renderElement = async ({ tagName, textContent, htmlElement }, index) => {
	//&& React.isValidElement(htmlElement)
	if (htmlElement) {
		try {
			//htmlElement
			const compImg = await getComponentImage({
				component: htmlElement,
			});
			if (compImg?.imageSrc) {
				return <ReactPDFImage src={compImg?.imageSrc} />;
			}
		} catch (e) {
			console.log(e);
		}
	} else {
		switch (tagName) {
			case "h1":
				return <Title key={index}>{textContent}</Title>;
			case "h2":
				return <SubtitleH2 key={index}>{textContent}</SubtitleH2>;
			case "h3":
				return <SubtitleH3 key={index}>{textContent}</SubtitleH3>;
			case "h5":
				return <SubtitleH5 key={index}>{textContent}</SubtitleH5>;
			case "p":
				return <BodyCopy key={index}>{textContent}</BodyCopy>;
			case "hr":
				return <View style={styles.divider} key={index} />;
			default:
				return <BodyCopy key={index}>{textContent}</BodyCopy>;
		}
	}
};

export const ComponentImage = ({ imageSrc, styleProps }) => (
	<ReactPDFImage
		src={imageSrc}
		style={{
			width: styleProps?.imageWidth,
			height: styleProps?.imageHeight,
			maxWidth: styleProps?.maxAllowedWidth,
			maxHeight: styleProps?.maxAllowedHeight,
		}}
	/>
);

export async function generateImageFromComponent(component) {
	const offscreenDiv = document.createElement("div");
	offscreenDiv.style.position = "absolute";
	offscreenDiv.style.left = "-9999px";
	offscreenDiv.style.top = "-9999px";
	document.body.appendChild(offscreenDiv);

	const renderComponent = () =>
		new Promise((resolve) => {
			if (typeof component === "string") {
				offscreenDiv.innerHTML = component;
				resolve();
			} else {
				ReactDOM.render(<ThemeProvider theme={theme}>{component}</ThemeProvider>, offscreenDiv, () => {
					resolve();
				});
			}
		});

	await renderComponent();

	// Add a delay to allow time for the CSS grid layout to be applied properly
	await new Promise((resolve) => setTimeout(resolve, 100));

	const canvas = await html2canvas(offscreenDiv, {
		useCORS: true,
		backgroundColor: "transparent",
		scrollX: 0, // Set scrollX to 0 to handle CSS grid layouts correctly
		scrollY: 0, // Set scrollY to 0 to handle CSS grid layouts correctly
	});

	const imageDataUrl = canvas.toDataURL();

	ReactDOM.unmountComponentAtNode(offscreenDiv);
	document.body.removeChild(offscreenDiv);

	return imageDataUrl;
}

function getImageDimensionsFromDataURI(dataURI) {
	return new Promise((resolve, reject) => {
		const img = new Image();
		img.onload = () => {
			resolve({
				width: img.naturalWidth,
				height: img.naturalHeight,
			});
		};
		img.onerror = () => {
			reject(new Error("Failed to load image"));
		};
		img.src = dataURI;
	});
}

export const getComponentImage = async ({ component, documentSize = { dh: 595, dw: 842 }, maxWidth, maxHeight }) => {
	const imageSrc = await generateImageFromComponent(component);

	const imageDimensions = await getImageDimensionsFromDataURI(imageSrc)
		.then((dimensions) => {
			return dimensions;
		})
		.catch((error) => {
			console.error(error);
		});

	const availableWidth = documentSize?.dw;
	const availableHeight = documentSize?.dh;

	const maxAllowedWidth = Math.min(availableWidth, maxWidth ? Math.min(maxWidth, availableWidth) : availableWidth);

	const maxAllowedHeight = Math.min(availableHeight, maxHeight ? Math.min(maxHeight, availableHeight) : availableHeight);

	const scaleFactor = Math.min(maxAllowedWidth / imageDimensions.width, maxAllowedHeight / imageDimensions.height);

	const imageWidth = Math.round(scaleFactor * imageDimensions.width);
	const imageHeight = Math.round(scaleFactor * imageDimensions.height);

	return {
		imageSrc: imageSrc,
		styleProps: {
			imageWidth: imageWidth,
			imageHeight: imageHeight,
			maxAllowedWidth: maxAllowedWidth,
			maxAllowedHeight: maxAllowedHeight,
		},
	};
};
