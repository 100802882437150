import * as React from "react";
import { Box, Typography } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { MUIAccordion, MUIAccordionDetails, MUIAccordionSummary } from "./styles";

export default function CustomAccordion({ label, children, open = true, background = "white", disableAccordian = false }) {
	const [expanded, setExpanded] = React.useState(open && "panel1");

	const handleChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};

	return (
		<div>
			<MUIAccordion background={background} expanded={expanded === "panel1"} onChange={!disableAccordian && handleChange("panel1")}>
				<MUIAccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
					<Typography variant="Text/md/Semibold" color="#667085" component={"p"} sx={{ ml: "15px" }}>
						{label}
					</Typography>
				</MUIAccordionSummary>
				<MUIAccordionDetails>{children}</MUIAccordionDetails>
			</MUIAccordion>
		</div>
	);
}
