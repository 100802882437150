import { Box, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import OnboardingCardSelector from "../../../../components/Onboarding/OnboardingCardSelector";
import PageTitle from "../../../../components/Onboarding/PageTitle";
import { ARE_YOU_AFFILIATED } from "../../../../constants/updated-onboarding";

export default function AreYouAffiliate({ setComplete }) {
  const [userResponse, setUserResponse] = useState("");
  const savedData = useSelector(state => state?.onboarding?.areYouAffiliate);

  useEffect(() => {
    if (savedData) {
      setUserResponse(savedData);
    }
  }, [savedData]);

  const callComplete = (complete, data) => {
    if (setComplete) {
      setComplete(complete, data);
    }
  };

  useEffect(() => {
    if (userResponse) {
      callComplete(1, { areYouAffiliate: userResponse });
    } else {
      callComplete(0, { areYouAffiliate: null });
    }
  }, [userResponse]);
  return (
    <Box>
      <PageTitle title={"Are you affiliated with any group?"} />

      <Box sx={{ my: 5 }}>
        <OnboardingCardSelector
          cardItems={ARE_YOU_AFFILIATED}
          flex={1 / 3}
          gridColWidth={7}
          setUserResponse={setUserResponse}
          userResponse={userResponse}
          savedData={savedData}
        />
      </Box>
    </Box>
  );
}
