import React from "react";
import { Button, Box, Modal, Typography, CircularProgress } from "@mui/material";

const DeletePowerupPackModal = ({ open, handleClose, handleConfirm, deletePowerupPackLoading }) => {
	return (
		<Modal open={open} onClose={handleClose}>
			<Box
				sx={{
					position: "fixed",
					top: "50%",
					left: "50%",
					transform: "translate(-50%, -50%)",
					width: "25vw",
					maxHeight: "90vh",
					overflowY: "auto",
					backgroundColor: "white",
					borderRadius: "20px",
					p: 4,
					zIndex: 9999,
				}}
			>
				<Typography variant="Text/sm/Semibold">Delete PowerPack</Typography>

				<Typography component={"p"} mt={2} variant="Text/sm/Regular">
					Are you sure you want to delete this PowerPack?
				</Typography>
				<Box display={"flex"} alignItems={"center"} flexDirection={"row"} mt={4} justifyContent={"flex-end"}>
					<Box>
						<Button onClick={handleClose} sx={{ color: (theme) => theme.palette.secondary.light }} variant="noOutlineNew">
							Cancel
						</Button>
					</Box>
					<Box>
						<Button
							onClick={handleConfirm}
							startIcon={deletePowerupPackLoading && <CircularProgress size={"1.25rem"} sx={{ color: "inherit" }} />}
							variant="DS1"
						>
							Confirm
						</Button>
					</Box>
				</Box>
			</Box>
		</Modal>
	);
};

export default DeletePowerupPackModal;
