import React from "react";
import { Box, Grid, Typography } from "@mui/material";

export default function PowerPackSelectedTitles({
  workspaceTitles
}) {

  return (
    <Box sx={{
      padding: "0px 0px 24px 0px",
      display: "flex",
      justifyContent: "center",
      flexGrow: 1,
    }}>
      <Grid sx={{
        textAlign: "left",
        flexGrow: 1,
      }} containercolumns={1}>
        <Grid>
          <Typography variant="StartupOS/headline/headline6">{workspaceTitles.headline} PowerUps</Typography>
        </Grid>
        <Grid>
          <Typography variant="StartupOS/subhead/subhead3">{workspaceTitles.subhead}</Typography>
        </Grid>
      </Grid>
    </Box>
  );
}