import * as actionTypes from "../../actions/actionTypes";
import { updateObject } from "../../../utils/updateObject";

const initialState = {
	powerUpExcercises: [],
	showInterviewScriptDialog: false,
	powerupDetails: {},
	interviewCandidates: [],
	candidateDetails: {},
	marketTest: {},
	tamSamSomData: [],
	headerDetails: {},
	powerupLayoutData: {},
	workHistoryTaskData: {},
};

const powerUp = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SET_POWERUP_ExcerciseS:
			return updateObject(state, { powerUpExcercises: action.payload });
		case actionTypes.SET_INTERVIEW_SCRIPT_DIALOG:
			return updateObject(state, { showInterviewScriptDialog: action.payload });

		case actionTypes.SET_POWERUP_DETAILS:
			return updateObject(state, { powerupDetails: action.payload });
		case actionTypes.SET_INTERVIEW_CANDIDATES:
			return updateObject(state, { interviewCandidates: action.payload });
		case actionTypes.SET_CANDIDATE_DETAILS:
			return updateObject(state, { candidateDetails: action.payload });
		case actionTypes.SET_MARKET_TEST_ID:
			return updateObject(state, { marketTest: action.payload });
		case actionTypes.SET_EXTERNAL_CANDIDATE_DETAILS:
			return updateObject(state, { externalCandidateDetails: action.payload });
		case actionTypes.SET_TAMSAMSOM_DATA:
			return updateObject(state, { tamSamSomData: action.payload });
		case actionTypes.SET_POWERUP_HEADER_DETAILS:
			return updateObject(state, { headerDetails: action.payload });
		case actionTypes.SET_POWERUP_LAYOUT_DETAILS:
			return updateObject(state, { powerupLayoutData: action.payload });
		case actionTypes.SET_WORK_HISTORY_TASK_DETAILS:
			return updateObject(state, { workHistoryTaskData: action.payload });
		default:
			return state;
	}
};

export default powerUp;
