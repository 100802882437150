import {
  Typography,
  CardContent,
  CardMedia,
  CardActions,
  Box,
  Button,
} from "@mui/material";
import React from "react";
import {
  cardTheme,
  PowerUpCard,
} from "../../../../components/Workspace/PowerUp/Card/styles.js";
import PropTypes from "prop-types";
import { ThemeProvider } from "@mui/material/styles";

const AdPartnerCard = ({
  image,
  title,
  subTitle,
  description,
  buttonText,
  onClick,
  buttonVariant,
}) => {
  return (
    <ThemeProvider theme={cardTheme}>
      <PowerUpCard
        sx={{ display: "flex", flexDirection: "column" }}
        alignment={"block"}
        color="primary"
        variant="outlined"
      >
        <CardMedia
          component="img"
          height="auto"
          image={image}
          alt="hero image"
        />

        <CardContent>
          <>
            <Typography
              sx={{ display: "block" }}
              align="left"
              variant="title_medium"
            >
              {title}
            </Typography>
            <Box sx={{ textAlign: "left" }}>
              <Typography variant="subhead2">{subTitle}</Typography>
            </Box>
            <Box sx={{ textAlign: "left", mt: 5 }}>
              <Typography variant="subhead2" color={"text.secondary"}>
                {description}
              </Typography>
            </Box>
          </>
        </CardContent>

        <CardActions
          sx={{
            pr: 2,
            pb: 2,
            mt: "auto",
            ml: "auto",
          }}
        >
          <Button
            variant={buttonVariant}
            onClick={onClick}
            sx={{ width: 1 }}
            size="small"
          >
            View Sponsor Site
          </Button>
        </CardActions>
      </PowerUpCard>
    </ThemeProvider>
  );
};

{
  AdPartnerCard.propTypes = {
    image: PropTypes.string,
    title: PropTypes.string,
    subText: PropTypes.string,
    buttonText: PropTypes.string,
  };

  AdPartnerCard.defaultProps = {
    image: "",
    title: "",
    subText: "",
    buttonText: "",
  };
}

export default AdPartnerCard;
