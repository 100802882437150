import React from "react";
import theme from "../../../../../constants/Theme";
import { Box, Typography, Grid } from "@mui/material";
import UpcomingEventCard from "../../../Components/UpcomingEventCard";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useSpringCarousel } from "react-spring-carousel";

export function EventWebCarousel({ eventsData, EventCardActionComponent }) {
  const {
    carouselFragment,
    slideToPrevItem,
    slideToNextItem,
    useListenToCustomEvent,
  } = useSpringCarousel({
    gutter: 24,
    itemsPerSlide: eventsData.length > 3 ? 3 : eventsData.length,
    withLoop: false,
    items: eventsData?.map((event, index) => ({
      id: index,
      renderItem: (
        <Grid md={4} lg={4}>
          <UpcomingEventCard
            id={index}
            eventData={event}
            ActionComponent={() => (
              <EventCardActionComponent eventData={event} />
            )}
          />
        </Grid>
      ),
    })),
  });

  return (
    <>
      <Grid
        container
        alignItems={"center"}
        sx={{
          mb: 3,
          [theme.breakpoints.down("md")]: {
            display: "none",
          },
        }}
      >
        <Grid textAlign={"left"} item xs={11}>
          <Typography variant="title_large">Upcoming Events</Typography>
        </Grid>
        <Grid
          xs={1}
          item
          sx={{
            justifyContent: "flex-end",
          }}
        >
          <Box sx={{ gap: theme.spacing(2) }}>
            <ArrowBackIosIcon
              onClick={slideToPrevItem}
              sx={{ cursor: "pointer" }}
            />
            <ArrowForwardIosIcon
              onClick={slideToNextItem}
              sx={{ cursor: "pointer" }}
            />
          </Box>
        </Grid>
      </Grid>

      <Box
        sx={{
          [theme.breakpoints.down("md")]: {
            display: "none",
          },
        }}
      >
        {carouselFragment}
      </Box>
    </>
  );
}
