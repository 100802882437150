import React from "react";
import { Box, TextField } from "@mui/material";
import { SEARCH_ICON_V2, UPDATED_SEARCH_ICON } from "../../../../constants";

export default function FilterField({ placeholder, debounceChange }) {
	return (
		<TextField
			spellCheck="true"
			onChange={debounceChange}
			InputProps={{
				endAdornment: (
					<Box sx={{ display: "flex", alignItems: "center", mx: 1 }}>
						<Box component={"img"} src={SEARCH_ICON_V2} alt="search" />
					</Box>
				),
			}}
			fullWidth
			placeholder={placeholder}
			sx={{
				"& .MuiInputBase-root": {
					height: "48px",
					boxShadow: "0px 3px 11px rgba(0, 0, 0, 0.05)",
					borderRadius: "40px",
					fontSize: "16px",
					fontFamily: '"PoppinsRegular", "Helvetica", "Arial", sans-serif',
					background: "white",
					pl: 1,
					"&:hover": {
						borderColor: "red",
					},
				},
				"& .MuiOutlinedInput-notchedOutline": {
					border: "none",
				},
				input: {
					"&, &::placeholder": (theme) => ({
						fontSize: "16px",
						fontFamily: '"PoppinsRegular", "Helvetica", "Arial", sans-serif',
						color: "rgba(102, 112, 133, 1)",
						opacity: 1,
					}),
				},
			}}
		/>
	);
}
