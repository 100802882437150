import React from "react";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { PageWrapper } from "./styles";
import { Box, Slide } from "@mui/material";
import { getUser, logout } from "../../utils/auth0-spa";
import { authWebLogout } from "../../utils/auth0";
import LayoutWrapper from "./UpdatedLayoutWrapper";
import * as actions from "../../modules/actions";
import UpdatedLayoutWrapper from "./UpdatedLayoutWrapper";
import { matchPath, useLocation } from "react-router-dom";
import { FOUNDER_DASHBOARD_BG, PROFILE_BG, INVESTOR_DASHBOARD_BG1 } from "../../constants";
import theme from "../../constants/Theme";
import ROUTES_PATH from "../../constants/routes";
import { handleMixPanel } from "../../utils/mixPanelEventHandling";
import { useThemeContext } from "../../constants/Theme/ThemeContext";

function CommonLayout({ children, userData, setAuthData, showAccountSettingPopup, companyLogoUrl }) {
	const startupData = useSelector((state) => state?.startup);
	const location = useLocation();
	const { clearThemeObjects } = useThemeContext();
 
	const { isPremiumPlanAccess, userType } = useSelector((store) => store?.auth);

	const onLgoutHandler = async () => {
		handleMixPanel("Account Logout", { subscription_status: isPremiumPlanAccess ? "Subscribed" : "Not Subscribed", user_type: userType });

		await logout();
		await authWebLogout();

		// Clear Partner Theme
		try{
			clearThemeObjects();
		}catch(e){
			console.log("Failed to clear partner theme localstorage values.", e);
		}
	};

	const closeAccountSettingsPopup = () => {
		setAuthData({ showAccountSettingPopup: false });
	};
	const handleOpen = () => {
		setAuthData({ showAccountSettingPopup: true });
	};

	const getBGImg = () => {
		if (matchPath(location.pathname, { path: ROUTES_PATH.FOUNDER_DASHBOARD })) {
			return FOUNDER_DASHBOARD_BG;
		} else if (matchPath(location.pathname, { path: ROUTES_PATH.RESOURCE_CENTER })) {
			return FOUNDER_DASHBOARD_BG;
		} else if (matchPath(location.pathname, { path: ROUTES_PATH.INVESTOR_DASHBOARD })) {
			return INVESTOR_DASHBOARD_BG1;
		} else {
			return "";
		}
	};

	return (
		<>
			<Box>
				<PageWrapper bgImage={getBGImg()} backgroundColor={theme.palette.secondary.bgGray}>
					<UpdatedLayoutWrapper
						onLogout={onLgoutHandler}
						userData={userData}
						showAccountSettingPopup={showAccountSettingPopup}
						closeAccountSettingsPopup={closeAccountSettingsPopup}
						handleOpen={handleOpen}
						companyLogoUrl={companyLogoUrl}
					>
						{children}
					</UpdatedLayoutWrapper>
				</PageWrapper>
			</Box>
		</>
	);
}

CommonLayout.propTypes = {
	children: PropTypes.element.isRequired,
};

const mapStoreToProps = ({ auth }) => ({
	userData: auth.userData,
	companyLogoUrl: auth.companyLogoUrl,
	showAccountSettingPopup: auth?.showAccountSettingPopup,
});
const mapDispatchToProps = (dispatch) => ({
	setAuthData: (updatedData) => dispatch(actions.setAuthData(updatedData)),
});

export default connect(mapStoreToProps, mapDispatchToProps)(CommonLayout);
