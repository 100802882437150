import React, { Fragment, useEffect, useState } from "react";
import {
  Box,
  Divider,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  Tabs,
  Tab,
  styled,
} from "@mui/material";
import { Bolt, StarsRounded, Toc, Whatshot } from "@mui/icons-material";
import ContentCopyTwoToneIcon from "@mui/icons-material/ContentCopyTwoTone";
import { useSelector } from "react-redux";
import { handleMixPanel } from "../../../../../utils/mixPanelEventHandling";

export const TabBar = ({ ...props }) => {
  const powerUpId = useSelector((state) => state?.mixpanel?.powerUpId);
  const powerUpName = useSelector((state) => state?.mixpanel?.powerUpName);

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
      value: index,
    };
  }

  return (
    <Tabs
      value={props.value}
      onChange={props.handleChange}
      aria-label="basic tabs example"
      textColor="primary"
      indicatorColor="primary"
      variant="fullWidth"
      centered
    >
      {props.powerupContent && (
        <Tab
          disableRipple
          icon={<ContentCopyTwoToneIcon />}
          label={
            props.width > 330 ? (
              <Typography variant="label_medium" textTransform={"capitalize"}>
                Content
              </Typography>
            ) : (
              ""
            )
          }
          key={-1}
          {...a11yProps(-1)}
          sx={{ px: 0, minWidth: "unset" }}
        />
      )}

      <Tab
        onClick={() => { 
          handleMixPanel("Powerup Details Viewed", {
            "Detail": "Details",
            "Powerup ID": powerUpId,
            "Powerup Name": powerUpName
          })
        }}
        disableRipple
        icon={<Toc />}
        label={
          props.width > 330 ? (
            <Typography variant="label_medium" textTransform={"capitalize"}>
              Details
            </Typography>
          ) : (
            ""
          )
        }
        key={0}
        {...a11yProps(0)}
        sx={{ px: 0, minWidth: "unset" }}
      />
      <Tab
        onClick={() => { 
          handleMixPanel("Powerup Details Viewed", {
            "Detail": "Power Tips",
            "Powerup ID": powerUpId,
            "Powerup Name": powerUpName
          })
        }}
        disableRipple
        icon={<Bolt />}
        label={
          props.width > 330 ? (
            <Typography variant="label_medium" textTransform={"capitalize"}>
              Power Tips
            </Typography>
          ) : (
            ""
          )
        }
        key={1}
        {...a11yProps(1)}
        sx={{ px: 0, minWidth: "unset" }}
      />
      <Tab
        onClick={() => { 
          handleMixPanel("Powerup Details Viewed", {
            "Detail": "Inspiration",
            "Powerup ID": powerUpId,
            "Powerup Name": powerUpName
          })
        }}
        disableRipple
        icon={<Whatshot />}
        label={
          props.width > 330 ? (
            <Typography variant="label_medium" textTransform={"capitalize"}>
              Inspiration
            </Typography>
          ) : (
            ""
          )
        }
        key={2}
        {...a11yProps(2)}
        sx={{ px: 0, minWidth: "unset" }}
      />
      <Tab
        onClick={() => { 
          handleMixPanel("Powerup Details Viewed", {
            "Detail": "Reviews",
            "Powerup ID": powerUpId,
            "Powerup Name": powerUpName
          })
        }}
        disableRipple
        icon={<StarsRounded />}
        label={
          props.width > 330 ? (
            <Typography variant="label_medium" textTransform={"capitalize"}>
              Reviews
            </Typography>
          ) : (
            ""
          )
        }
        key={3}
        {...a11yProps(3)}
        sx={{ px: 0, minWidth: "unset" }}
      />
    </Tabs>
  );
};
