import React, { useState, useEffect } from "react";
import services from "../../../../services";
import {
	WORKSPACE_LEFT_SIDEBAR_ITEMS,
	DEFAULT_WORKSPACE_LEFT_SIDEBAR_ITEMS,
	MENTOR_WORKSPACE_LEFT_SIDEBAR_ITEMS,
	ADVISOR_WORKSPACE_LEFT_SIDEBAR_ITEMS,
	CANDIDATE_WORKSPACE_LEFT_SIDEBAR_ITEMS,
	INVESTOR_WORKSPACE_LEFT_SIDEBAR_ITEMS,
	PARTNER_WORKSPACE_LEFT_SIDEBAR_ITEMS,
	INSTRUCTOR_WORKSPACE_LEFT_SIDEBAR_ITEMS,
	STUDENT_WORKSPACE_LEFT_SIDEBAR_ITEMS,
	ADMIN_WORKSPACE_LEFT_SIDEBAR_ITEMS,
	MENTOR_APPROVED_WORKSPACE_LEFT_SIDEBAR_ITEMS,
	INVESTOR_APPROVED_WORKSPACE_LEFT_SIDEBAR_ITEMS,
} from "../../../../constants/workspace-sidebar";
import { useLocation, useParams } from "react-router-dom";

import { USER_TYPES } from "../../../../utils/userTypes";
import NavItemsList from "../../../../common/NavItemsList/NavItemsList";
import { useSelector } from "react-redux";
import { LOGOICON } from "../../../../constants/image";
import { Typography, Box, Divider } from "@mui/material";
import { LeftSideBarWrapper } from "./styles";

export default function LeftSidebar({ userData, companyName, userName, companyLogoUrl }) {
	const userType = useSelector((store) => store?.auth?.userType);
	// TODO: Check admin by using userType
	const isAdmin = useSelector((store) => store?.auth?.isAdmin);
	const impersonateProviderId = useSelector((store) => store?.auth?.impersonateProviderId);
	const [active, setActive] = useState();

	const location = useLocation();
	const params = useParams();

	const currentSelectedNavItem = useSelector((store) => store?.auth?.currentSelectedNavItem);

	// let listItem = WORKSPACE_LEFT_SIDEBAR_ITEMS;

	// 	if (userType === USER_TYPES.MENTOR_USER) {
	// 		listItem = MENTOR_APPROVED_WORKSPACE_LEFT_SIDEBAR_ITEMS;
	// 	} else if (userType === USER_TYPES.INVESTOR_USER) {
	// 		listItem = INVESTOR_APPROVED_WORKSPACE_LEFT_SIDEBAR_ITEMS;
	// 	} else if (userType === USER_TYPES.ADVISOR_USER) {
	// 		listItem = ADVISOR_WORKSPACE_LEFT_SIDEBAR_ITEMS;
	// 	} else if (userType === USER_TYPES.CANDIDATE_USER) {
	// 		listItem = CANDIDATE_WORKSPACE_LEFT_SIDEBAR_ITEMS;
	// 	} else if (userType === USER_TYPES.PARTNER_USER) {
	// 		listItem = PARTNER_WORKSPACE_LEFT_SIDEBAR_ITEMS;
	// 	} else if (userType === USER_TYPES.PROFESSOR_USER) {
	// 		listItem = INSTRUCTOR_WORKSPACE_LEFT_SIDEBAR_ITEMS;
	// 	} else if (userType === USER_TYPES.STUDENT_USER) {
	// 		listItem = STUDENT_WORKSPACE_LEFT_SIDEBAR_ITEMS;
	// 	} else if (isAdmin && !impersonateProviderId) {
	// 		// TODO: Check admin by using userType
	// 		listItem = ADMIN_WORKSPACE_LEFT_SIDEBAR_ITEMS;
	// 	}

	const [listItem, setListItem] = useState(DEFAULT_WORKSPACE_LEFT_SIDEBAR_ITEMS);
	const [approvalStatus, setApprovalStatus] = useState();

	useEffect(() => {
		if (!approvalStatus) {
			services.getApprovalStatus().then((status) => {
				setApprovalStatus(status);
				setListItem(getListItem(status));
			});
		}
	}, [approvalStatus, setListItem]);

	const getListItem = (approvalStatus) => {
		if (approvalStatus === "APPROVED") {
			if (userType === USER_TYPES.MENTOR_USER) {
				return MENTOR_APPROVED_WORKSPACE_LEFT_SIDEBAR_ITEMS;
			} else if (userType === USER_TYPES.INVESTOR_USER) {
				return INVESTOR_APPROVED_WORKSPACE_LEFT_SIDEBAR_ITEMS;
			} else if (userType === USER_TYPES.ADVISOR_USER) {
				return ADVISOR_WORKSPACE_LEFT_SIDEBAR_ITEMS;
			} else if (userType === USER_TYPES.CANDIDATE_USER) {
				return CANDIDATE_WORKSPACE_LEFT_SIDEBAR_ITEMS;
			} else if (userType === USER_TYPES.PARTNER_USER) {
				return PARTNER_WORKSPACE_LEFT_SIDEBAR_ITEMS;
			} else if (userType === USER_TYPES.PROFESSOR_USER) {
				return INSTRUCTOR_WORKSPACE_LEFT_SIDEBAR_ITEMS;
			} else if (userType === USER_TYPES.STUDENT_USER) {
				return STUDENT_WORKSPACE_LEFT_SIDEBAR_ITEMS;
			} else if (isAdmin && !impersonateProviderId) {
				// TODO: Check admin by using userType
				return ADMIN_WORKSPACE_LEFT_SIDEBAR_ITEMS;
			}

			return WORKSPACE_LEFT_SIDEBAR_ITEMS;
		} else {
			if (userType === USER_TYPES.MENTOR_USER) {
				return MENTOR_WORKSPACE_LEFT_SIDEBAR_ITEMS;
			} else if (userType === USER_TYPES.INVESTOR_USER) {
				return INVESTOR_WORKSPACE_LEFT_SIDEBAR_ITEMS;
			}

			return DEFAULT_WORKSPACE_LEFT_SIDEBAR_ITEMS;
		}
	};

	useEffect(() => {
		setActive(currentSelectedNavItem);
	}, [currentSelectedNavItem]);

	useEffect(() => {
		if (["discovery-board-expand", "discovery-board", "create-discovery-item", "create-quick-note"].includes(location.pathname.split("/")[1])) {
			setActive("discovery-board");
		} else if (["dashboard"].includes(location.pathname.split("/")[2])) {
			if (location.pathname.split("/")[1] === "partner") {
				setActive("powerup-builder");
			} else if (location.pathname.split("/")[1] === "investor") {
				setActive("investor-dashboard");
			} else {
				setActive(location.pathname.split("/")[2]);
			}
		} else if (["powerpack"].includes(location.pathname.split("/")[1]) && params.id) {
			setActive("workspace");
		} else if (
			(["profile"].includes(location.pathname.split("/")[1]) && params.id) ||
			(["myprofile"].includes(location.pathname.split("/")[2]) && params.id) ||
			["network"].includes(location.pathname.split("/")[2])
		) {
			setActive("network");
		} else {
			if (["dashboard"].includes(location.pathname.split("/")[2])) {
				setActive("dashboard");
			} else if (["user-support-access"].includes(location.pathname.split("/")[1])) {
				setActive("impersonate");
			} else {
				setActive(location.pathname.split("/")[1]);
			}
		}
	});

	return (
		<LeftSideBarWrapper>
			<Box
				sx={{
					pb: 4,
				}}
			>
				<Box
					sx={(theme) => ({
						pt: 8,
						display: "block",
						height: "100%",
					})}
				>
					<Box sx={{ pl: 2, pb: 1, pr: 2 }}>
						<img draggable={false} src={LOGOICON} alt="Logo" />
					</Box>
					<Divider />
				</Box>
				{/*<Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 2,
              py: 2,
              px: 4,
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Box
              component="img"
              src={
                userType === USER_TYPES.STARTUP_USER ||
                userType === USER_TYPES.STARTUP_OWNER ||
                userType === USER_TYPES.STUDENT_USER
                  ? companyLogoUrl
                  : userData?.picture
              }
              width={30}
              height={30}
              sx={{
                borderRadius: "50%",
                objectFit: "cover",
              }}
            />
            <Typography
              variant="tag"
              sx={theme => ({
                display: "flex",
                overflow: "hidden",
                wordBreak: "break-word",
                color: theme.palette.text.lightOnsurface,
              })}
            >
              {userType === USER_TYPES.STARTUP_USER ||
              userType === USER_TYPES.STARTUP_OWNER ||
              userType === USER_TYPES.STUDENT_USER
                ? companyName || "The Roadrunner.corp"
                : userName || "User"}
            </Typography>
          </Box>
        </Box>*/}
				{/*<Divider sx={{ mx: 4 }} />*/}

				<Box sx={{ px: 2 }}>
					{listItem && (
						<NavItemsList
							listItems={listItem}
							direction="column"
							userType={userType}
							active={active}
							currentSelectedNavItem={currentSelectedNavItem}
						/>
					)}
				</Box>
			</Box>
		</LeftSideBarWrapper>
	);
}
