import styled from "@emotion/styled";

const getRunwayTheme = (type, currenttab) => {
  let theme;

  if (type === "primary") {
    currenttab === "actual"
      ? (theme = {
          background:
            "linear-gradient(0deg, rgba(0, 160, 226, 0.15), rgba(0, 160, 226, 0.15)), #FFFFFF",
          color: "#00A0E2",
        })
      : (theme = {
          background:
            "linear-gradient(0deg, rgba(0, 91, 127, 0.15), rgba(0, 91, 127, 0.15)), #FFFFFF;",
          color: "#005B7F",
        });
  } else {
    currenttab === "actual"
      ? (theme = {
          background: "linear-gradient(0deg, #00A0E2, #00A0E2), #FFFFFF",
          color: "#ffffff",
        })
      : (theme = {
          background: "linear-gradient(0deg, #005B7F, #005B7F), #FFFFFF",
          color: "#ffffff",
        });
  }

  return theme;
};

export const InlineTileWrapper = styled.div(({ theme }) => {
  return {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 8,
  };
});
export const InlinePrimaryTile = styled.div(
  ({ theme, borderRadius, currenttab }) => {
    const runwayTheme = getRunwayTheme("primary", currenttab);

    return {
      ...runwayTheme,
      position: "relative",
      width: "100%",
      height: 130,
      borderRadius:
        borderRadius === "both"
          ? "2px 2px 8px 8px"
          : borderRadius === "right"
          ? "2px 8px 2px 2px"
          : "8px 2px 2px 2px",

      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      ".edit": {
        display: "none",
      },
      "&:hover .edit": {
        display: "block",
      },
    };
  }
);

export const InlineSecondaryTile = styled.div(({ theme, currenttab }) => {
  const runwayTheme = getRunwayTheme("secondary", currenttab);
  return {
    ...runwayTheme,
    width: "100%",
    height: 130,
    borderRadius: "2px 2px 8px 8px",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
});
