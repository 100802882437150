import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Grid,
  ImageList,
  ImageListItem,
  useMediaQuery,
  Typography,
} from "@mui/material";
import ResearchCuratorCard from "../../../../components/ResearchCuratorCard";
import theme from "../../../../constants/Theme";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import services from "../../../../services";
import ROUTES_PATH from "../../../../constants/routes";
import { useHistory } from "react-router-dom";
import ListView from "./ListView";
import { handleMixPanel } from "../../../../utils/mixPanelEventHandling";

export const PinnedNotesComponent = ({
  view,
  sortBy,
  searchText,
  filter,
  callbackRefresh,
  setCallbackRefresh,
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [savedPageIndex, setSavedPageIndex] = useState(1);
  const isMediumScreen = useMediaQuery(theme.breakpoints.only("md"));
  const perPage = 10;
  const [pageData, setPageData] = useState({ isLast: false });
  const [loading, setLoading] = useState(false);
  const [notesPageIndex, setNotesPageIndex] = useState(1);
  const [listItems, setListItems] = useState([]);
  const [lastElement, setLastElement] = useState(null);
  const [isPinned, setIsPinned] = useState(true);
  const history = useHistory();
  const [callback, setCallback] = useState(false);

  let slicedListItems = isMediumScreen
    ? listItems.slice(activeStep, activeStep + 2)
    : listItems.slice(activeStep, activeStep + 3);

  const remainingItems = listItems.length - (activeStep + 3);

  useEffect(() => {
    //Horizontal pagination on carousel

    if (
      activeStep >= listItems.length - 3 &&
      notesPageIndex < pageData["totalPages"]
    ) {
      handlePaginationNext();
    }
  }, [activeStep]);

  const nextStep = () => {
    if (view === "LIST") {
      if (activeStep < listItems.length - 1) {
        setActiveStep(activeStep + 1);
      }
    } else {
      if (isMediumScreen) {
        if (activeStep < listItems.length - 2) {
          setActiveStep(activeStep + 2);
        }
      } else {
        if (activeStep < listItems.length - 3) {
          setActiveStep(activeStep + 3);
        }
      }
    }
  };

  const prevStep = () => {
    if (view === "LIST") {
      if (activeStep > 0) {
        setActiveStep(activeStep - 1);
      }
    } else {
      if (isMediumScreen) {
        if (activeStep > 0) {
          setActiveStep(activeStep - 2);
        }
      } else {
        if (activeStep > 0) {
          setActiveStep(activeStep - 3);
        }
      }
    }
  };

  const handlePaginationNext = () => {
    setSavedPageIndex(savedPageIndex => savedPageIndex + 1);
    setNotesPageIndex(notesPageIndex => notesPageIndex + 1);
    //setAppend(true);
  };

  const getPinnedNotesApiCall = () => {
    const tags = filter.tags;
    const types = filter.type;
    services
      .getPinnedNotesList(
        notesPageIndex,
        perPage,
        sortBy.order,
        sortBy.key,
        searchText,
        tags,
        types,
        isPinned
      )
      .then(result => {
        const data = result?.data?.data;
        setLoading(false);
        setPageData({
          totalPages: data?.totalPages || 0,
          totalElements: data.totalElements || 0,
          isFirst: data.isFirst,
          isLast: data.isLast,
        });
        setCallback(false);
        setCallbackRefresh(false);

        const items = data?.models || [];

        if (searchText || filter || sortBy) {
          if (!data.isFirst) {
            setListItems(prev => [...prev, ...items]);
          } else {
            setListItems(items);
          }
        } else {
          setListItems(prev => [...prev, ...items]);
        }
      })
      .catch(error => {
        setLoading(false);
        console.log(error);
      });
  };

  useEffect(() => {
    setLoading(true);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    getPinnedNotesApiCall();
  }, []);

  const handleCardClick = noteItem => {
    let routePath = "";
    if (noteItem.noteType === "NOTE") {
      routePath = ROUTES_PATH.CREATE_RESEARCH_CURATOR;
      handleMixPanel("PowerDot Viewed", {
        "PowerDot ID": noteItem.id
      });
    } else {
      routePath = ROUTES_PATH.CREATE_QUICK_NOTE;
      handleMixPanel("Quick Note Viewed", {
        "Quick Note ID": noteItem.id
      });
    }

    if (noteItem.isCreatedByUser) {
      history.push(routePath + "/" + noteItem.id);
    } else {
      history.push(routePath.replace(":id", noteItem.id));
    }
  };

  useEffect(() => {
    setLoading(true);
    if (!searchText) {
      setListItems([]);
      setPageData({ ...pageData, isLast: false, isFirst: true });

      setNotesPageIndex(1);
    } else {
      setPageData({ ...pageData, isLast: false, isFirst: true });
      setNotesPageIndex(1);
    }
  }, [searchText, filter, sortBy]);

  useEffect(() => {
    if (pageData.totalPages >= 0) {
      if (searchText || filter || sortBy) {
        if (pageData.totalPages === 0) {
          getPinnedNotesApiCall();
        } else {
          if (pageData.totalPages >= notesPageIndex) {
            getPinnedNotesApiCall();
          }
        }
      } else {
        if (!pageData.isLast) {
          getPinnedNotesApiCall();
        }
      }
    }
  }, [notesPageIndex, searchText, filter, sortBy]);

  useEffect(() => {
    if (callback || callbackRefresh) {
      setActiveStep(0);
      setNotesPageIndex(1);
      setCallbackRefresh(true);
      getPinnedNotesApiCall();
    }
  }, [callback, callbackRefresh]);

  return (
    <>
      {slicedListItems.length > 0 && (
        <>
          <Box
            sx={{
              pl: { md: 2, lg: 2 },
              pr: { md: 1, lg: 1 },
              maxWidth: "100vw",
            }}
          >
            <Grid container>
              {view !== "LIST" && (
                <Grid container sx={{ pb: 2, pt: 2 }}>
                  <Grid item xs={8}>
                    <Typography variant="title_medium" mr={1}>Pinned</Typography>{" "}
                    <Typography
                      variant="caption"
                      sx={{ color: "secondary.dark" }}
                    >
                      {pageData?.totalElements > 0
                        ? pageData.totalElements + " total"
                        : ""}
                    </Typography>
                  </Grid>
                  {listItems.length > 3 && (
                    <Grid
                      item
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        [theme.breakpoints.down("md")]: {
                          display: "none",
                        },
                      }}
                      xs={4}
                    >
                      <Box sx={{ display: "flex" }}>
                        <ArrowBackIosIcon
                          onClick={prevStep}
                          sx={{ cursor: activeStep > 0 ? "pointer" : "" }}
                          color={activeStep > 0 ? "" : "disabled"}
                        />
                        <ArrowForwardIosIcon
                          onClick={nextStep}
                          sx={{
                            cursor: remainingItems > 0 ? "pointer" : "",
                          }}
                          color={remainingItems > 0 ? "" : "disabled"}
                        />
                      </Box>
                    </Grid>
                  )}
                </Grid>
              )}

              {/* WEB */}
              {view !== "LIST" && (
                <>
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      [theme.breakpoints.down("md")]: {
                        display: "none",
                      },
                      pl: 1,
                      mt: 0,
                    }}
                  >
                    {slicedListItems?.map((item, index) => (
                      <Box key={index} sx={{ pl: 1, display: "contents" }}>
                        {" "}
                        <Grid item xs={12} sm={12} md={6} lg={4} key={index}>
                          {" "}
                          <ResearchCuratorCard
                            index={index}
                            cardData={item}
                            handleCardClick={() => handleCardClick(item)}
                            setCallback={setCallback}
                          />
                        </Grid>
                      </Box>
                    ))}
                  </Grid>
                </>
              )}

              {/* LIST */}

              {view === "LIST" && (
                <Grid container>
                  <ListView
                    handleCardClick={handleCardClick}
                    listItems={listItems}
                    pageData={pageData}
                    nextStep={nextStep}
                    prevStep={prevStep}
                    index={activeStep}
                    setIndex={setActiveStep}
                    setCallback={setCallback}
                  />
                </Grid>
              )}
            </Grid>
          </Box>

          {/*<Divider sx={{ my: 3, borderColor: "secondary.light" }} />*/}
        </>
      )}
    </>
  );
};
