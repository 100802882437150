import styled from "@emotion/styled";
import { Card } from "@mui/material";

export const ConnectedStartupCard = styled(props => <Card {...props} />)(
  ({ theme, variant }) => ({
    borderRadius: theme.shape.standard.borderRadius,
    ...(variant !== "bordered" && {
      boxShadow:
        "0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
    }),
    margin: "16px 0px",
  })
);
