import React from "react";
import DialogComponent from "../../../../../common/CommonDialog";

export default function MobilePowerUpPopup({
  open,
  onClose,
  title,
  mainContent,
  actionBar,
}) {
  return (
    <DialogComponent
      isOpen={open}
      onClose={onClose}
      title={title}
      titleVariant={"title_large"}
      contentStyle={{ px: 0 }}
      fullWidth
      fullScreen
      dialogAction={actionBar}
      disableBackDropClick
    >
      {mainContent}
    </DialogComponent>
  );
}
