import styled from "@emotion/styled";
import { IconButton } from "@mui/material";
import { sosPrimary } from "../../../../constants/Theme/colors";

export const IconComponent = styled(props => <IconButton {...props} />)(
  ({ theme, selected, width = "72px", height = "72px" }) => {
    return {
      width: width,
      height: height,
      borderColor: theme.palette.secondary.dark,
      borderWidth: "1px",
      borderStyle: "solid",
      background: selected
        ? theme.palette.primary.gradient
        : theme.palette.secondary.white,

      "&:hover": {
        borderColor: theme.palette.primary.main,
        background: selected ? theme.palette.primary.gradient : sosPrimary[50],
      },
      "&:focused": {
        background: theme.palette.primary.gradient,
      },

      cursor: "pointer",
    };
  }
);

export const StaticIconComponent = styled(props => <IconButton {...props} />)(
  ({ theme, selected, width = "72px", height = "72px" }) => {
    return {
      width: width,
      height: height,
      borderColor: theme.palette.secondary.dark,
      borderWidth: "1px",
      borderStyle: "solid",
      background: selected
        ? theme.palette.primary.gradient
        : theme.palette.secondary.white,

      "&:hover": {
        background: theme.palette.secondary.white,
      },
      "&:focused": {
        background: theme.palette.primary.gradient,
      },

      cursor: "pointer",
    };
  }
);
