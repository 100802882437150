import { Info } from "@mui/icons-material";
import { Box, Tabs, CircularProgress, Card, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import InsightTabContent from "./InsightTabContent";
import InsightTabItem from "./InsightTabItem";

export default function ScoreInsights() {
	const SCORE_OBJ = useSelector((state) => state?.investorDashboard?.ScoreReportObj);
	const REPORT_LOADING = useSelector((state) => state?.investorDashboard?.scoreTrendRangeLoading);

	const [value, setValue] = useState(0);

	useEffect(() => {
		console.log(SCORE_OBJ);
	}, [SCORE_OBJ]);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<Box sx={{ py: 5 }}>
			{SCORE_OBJ && !REPORT_LOADING && (
				<Tabs
					value={value}
					onChange={handleChange}
					variant="scrollable"
					scrollButtons
					allowScrollButtonsMobile
					sx={{
						alignItems: "stretch",
						"& .MuiTabs-flexContainer": {
							gap: 5,
						},
						"& .MuiTabs-indicator": {
							display: "none", // Manually handled on the <Tab> component.
						},
						"& .MuiTabs-scrollButtons": {
							color: (theme) => theme.palette.primary.main,
							"& > svg": {
								height: "30px",
								width: "30px",
							},
						},
					}}
				>
					{SCORE_OBJ?.segmentReports?.map((obj, index) => (
						<InsightTabItem
							key={index}
							index={index}
							name={obj?.name}
							maxPoints={obj?.maxPoints}
							currentPoints={obj?.currentPoints}
							tabID={obj?.key}
							previousPoints={obj?.previousPoints}
							pointDifference={obj?.difference}
							currentSelected={value === index}
						/>
					))}
				</Tabs>
			)}

			<Box
				sx={{
					p: {
						xs: 2,
						md: 3,
						lg: 4,
					},
				}}
			>
				{REPORT_LOADING ? (
					<Box
						sx={{
							p: 8,
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						<CircularProgress />
					</Box>
				) : (
					SCORE_OBJ?.segmentReports?.map((obj, index) => (
						<InsightTabContent
							key={index}
							index={index}
							tabID={obj?.key}
							selectedTabID={value}
							subSegments={obj?.subSegments}
							segmentName={obj?.name}
						/>
					)) || (
						<Card
							sx={{
								borderRadius: "40px",
								p: {
									xs: 2,
									md: 3,
									lg: 4,
								},
								boxShadow: "0px 10px 15px rgba(0, 0, 0, 0.05)",
								display: "flex",
								gap: 2,
							}}
						>
							<Box>
								<Info
									sx={{
										color: (theme) => theme.palette.primary.main,
									}}
								/>
							</Box>
							<Box>
								<Typography color="black" variant="poppinsMedium16" component={"p"}>
									This startup does not have a StartupOS score history at this time. We will continue to check for updates.
								</Typography>
							</Box>
						</Card>
					)
				)}
			</Box>
		</Box>
	);
}
