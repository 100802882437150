import React, { useEffect, useState } from "react";
import ScoutingReportController from "../../../components/ScoutingReport/ScoutingReportController";
import { Box } from "@mui/material";
import FullProfileDrawer from "../../../components/ScoutingReport/FullProfileDrawer";

export default function FounderTeamView({ onClose }) {
	return (
		<Box>
			<ScoutingReportController onClose={onClose} />
		</Box>
	);
}
