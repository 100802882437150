import * as React from "react";
import Button from "@mui/material/Button";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { Chip, Box, Typography } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import {
  convertFormattedStringToDate,
  getCharDay,
  getCurrentDate,
  getCurrentTime,
  getLocalTimeZone,
} from "../../../../../utils/date";
import theme from "../../../../../constants/Theme";

export default function SelectedDatesCard({
  responses,
  setResponses,
  mobileUI,
}) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };
  const handleDelete = (time, date) => {
    const responsesData = [...responses];
    const responsesIndex = responsesData.findIndex(item => item.date === date);
    if (responsesIndex >= 0) {
      let element = responsesData[responsesIndex];
      const filteredData = element.times.filter(item => item !== time);
      if (filteredData.length === 0) {
        element.times = filteredData;
        responsesData.splice(responsesIndex, 1);
      } else {
        element.times = filteredData;
        responsesData[responsesIndex] = element;
      }

      if (!responsesData.length) {
        handleClearAll();
      } else {
        setResponses(responsesData);
      }
    }
  };

  const getSelectedTimesNo = () => {
    let number = 0;
    responses.forEach(element => {
      number += element.times.length;
    });
    return number;
  };

  const handleClearAll = () => {
    setResponses([]);
    handleToggle();
  };

  return (
    <>
      <Button
        variant="nofill"
        aria-controls={open ? "split-button-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-label="select merge strategy"
        aria-haspopup="menu"
        onClick={handleToggle}
        disabled={!responses.length}
        fullWidth
        ref={anchorRef}
        sx={{ whiteSpace: "nowrap" }}
      >
        {responses.length ? (
          <>
            <ArrowDropUpIcon /> {getSelectedTimesNo()} Times selected{" "}
          </>
        ) : (
          "Select times to share"
        )}
      </Button>

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement="top-end"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
              boxShadow: "2px",
              borderRadius: theme => theme.shape.standard5.borderRadius,
            }}
          >
            <Paper sx={{ p: 1 }}>
              <ClickAwayListener onClickAway={handleClose}>
                <Box
                  sx={{
                    p: 1,
                    width: "300px",
                    background: mobileUI
                      ? theme.palette.primary.surface3
                      : theme.palette.secondary.white,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      mt: 1,
                      mb: 1,
                    }}
                  >
                    <Typography
                      variant="title_small"
                      sx={{ whiteSpace: "nowrap" }}
                    >
                      Selected Times
                    </Typography>
                    <Button
                      variant="nofill"
                      color="primary"
                      sx={{ ml: 10 }}
                      onClick={handleClearAll}
                    >
                      Clear all
                    </Button>
                  </Box>
                  <Box>
                    {responses.map(item => (
                      <Box sx={{ my: 1 }}>
                        <Typography variant="title_small">
                          {getCharDay(convertFormattedStringToDate(item.date))},{" "}
                          {getCurrentDate(
                            convertFormattedStringToDate(item.date)
                          )}{" "}
                          -{getLocalTimeZone()}
                        </Typography>
                        <br />
                        <Box>
                          {item.times.map(time => {
                            return (
                              <Chip
                                label={getCurrentTime(time)}
                                variant="outlined"
                                sx={theme => ({
                                  borderRadius:
                                    theme.shape.standard_small.borderRadius,
                                  m: 1,
                                })}
                                onDelete={() => handleDelete(time, item.date)}
                                deleteIcon={<ClearIcon />}
                              />
                            );
                          })}
                        </Box>
                      </Box>
                    ))}
                    {mobileUI && (
                      <Box sx={{ my: 2 }}>
                        <Button
                          sx={{ width: "max-content", px: 15 }}
                          onClick={handleClose}
                        >
                          Close
                        </Button>
                      </Box>
                    )}
                  </Box>
                </Box>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
