import React from "react";
import { ArrowRightAlt } from "@mui/icons-material";
import { Box, Button, Card, Typography } from "@mui/material";
import { handleMixPanel } from "../../utils/mixPanelEventHandling";

class AdBanner extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <Card
        elevation={1}
        sx={theme => ({
          [theme.breakpoints.up("md")]: {
            pr: 5,
          },
        })}
      >
        <Box
          sx={theme => ({
            width: 1,
            display: "grid",
            gridTemplateColumns: {
              xs: "120px 1fr",
              md: "240px 1fr auto",
            },
            gridTemplateRows: {
              xs: "1fr auto",
              md: "1fr",
            },
            gridColumnGap: theme => ({
              xs: "0",
              md: theme.spacing(5),
            }),
            gridRowGap: "0px",
          })}
          alignItems="center"
        >
          <Box
            sx={{
              gridArea: {
                xs: "1 / 1 / 3 / 2",
                md: "1 / 1 / 2 / 2",
              },
              backgroundImage: {
                xs: this.props.smallImage
                  ? `url(${this.props.smallImage})`
                  : `url(${this.props.image})`,
                md: `url(${this.props.image})`,
              },
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundSize: this.props.backgroundSize
                ? this.props.backgroundSize
                : "contain",
              alignSelf: "stretch",
            }}
          ></Box>
          <Box
            sx={theme => ({
              gridArea: {
                xs: "1 / 2 / 2 / 3",
                md: "1 / 2 / 2 / 3",
              },
              flex: 1,
              [theme.breakpoints.down("md")]: {
                p: 2,
                pb: 0,
              },
              [theme.breakpoints.up("md")]: {
                py: 3,
              },
              display: "flex",
              flexDirection: "column",
              gap: "4px",
            })}
          >
            <Typography
              component={"h1"}
              variant={"title_large"}
              color={"text.onSurfaceVariant"}
            >
              {this.props.title}
            </Typography>
            <Typography
              component={"h5"}
              variant={"subhead2"}
              color={"text.onSurfaceVariant"}
            >
              {this.props.subtitle}
            </Typography>
          </Box>
          <Box
            sx={theme => ({
              [theme.breakpoints.down("md")]: {
                m: 2,
              },
              textAlign: "right",
              gridArea: {
                xs: "2 / 2 / 3 / 3",
                md: "1 / 3 / 2 / 4",
              },
            })}
          >
            {this.props.action && (
              <Button
                variant="text"
                {...this.props.action.props}
                sx={{
                  gap: 1,
                  minWidth: "unset",
                  width: "auto",
                }}
                onClick={() => {
                  handleMixPanel("Advertisement Clicked", {
                    "Link": this.props.action.props.href,
                    "Source": this.props.title
                  });
                }}
              >
                <ArrowRightAlt />
                {this.props.action.text}
              </Button>
            )}
          </Box>
        </Box>
      </Card>
    );
  }
}

export default AdBanner;
