import React, { useEffect, useState } from "react";
import { Button, Box, useTheme } from "@mui/material";
import { setBIDashboardData } from "../../../../../modules/actions/biDashboardActions";
import {
	getAcvGridData,
	getCapTableData,
	getAnnualRevenueGridData,
	getBurnRunwayGridData,
	getCustomerGridData,
	getMonthlyRevenueGridData,
	getNetArrGridData,
	getPaybackGridData,
	getRevenueGridData,
	getSmExpenseVsNewSalesGridData,
	partners,
	fetchPlaidData,
	getCompanyDetails,
	getBurnMultipeGridData,
	getMagicNumberGridData,
} from "../../Functions/common";
import { useDispatch, useSelector } from "react-redux";
import LoadingButton from "../../../../../common/Loadingbutton";

function RefreshData({ companyId }) {
	const theme = useTheme();
	const [refreshing, setRefreshing] = useState(false);
	const dispatch = useDispatch();
	const ownCompanyId = useSelector((state) => state?.auth?.companyId);
	const usedCompanyId = companyId ? companyId : ownCompanyId;

	const refreshData = async () => {
		try {
			setRefreshing(true);
			loadingForTables(true);
			const acvGridDataResponse = await getAcvGridData(usedCompanyId);
			const capTableDataResponse = await getCapTableData(usedCompanyId);
			const annualRevenueGridDataResponse = await getAnnualRevenueGridData(usedCompanyId);
			const burnRunwayGridDataresponse = await getBurnRunwayGridData(usedCompanyId);
			const customerGridDataResponse = await getCustomerGridData(usedCompanyId);
			const monthlyRevenueGridDataResponse = await getMonthlyRevenueGridData(usedCompanyId);
			const netArrGridDataResponse = await getNetArrGridData(usedCompanyId);
			const paybackGridDataResponse = await getPaybackGridData(usedCompanyId);
			const revenueGridDataResponse = await getRevenueGridData(usedCompanyId);
			const smExpenseVsNewSalesGridDataResponse = await getSmExpenseVsNewSalesGridData(usedCompanyId);
			const burnMultipleGridDataResponse = await getBurnMultipeGridData(usedCompanyId);
			const magicNumberGridDataResponse = await getMagicNumberGridData(usedCompanyId);
			const companyDetailsDataResponse = await getCompanyDetails();

			dispatch(
				setBIDashboardData({
					...(acvGridDataResponse && { acv: acvGridDataResponse }),
					...(capTableDataResponse && { capData: capTableDataResponse }),
					...(annualRevenueGridDataResponse && { annualRecurringRevenue: annualRevenueGridDataResponse }),
					...(burnRunwayGridDataresponse && { burnAndRunway: burnRunwayGridDataresponse }),
					...(customerGridDataResponse && { customers: customerGridDataResponse }),
					...(monthlyRevenueGridDataResponse && { monthlyRecurringRevenue: monthlyRevenueGridDataResponse }),
					...(netArrGridDataResponse && { netNewArr: netArrGridDataResponse }),
					...(paybackGridDataResponse && { cacPayback: paybackGridDataResponse }),
					...(revenueGridDataResponse && { revenue: revenueGridDataResponse }),
					...(smExpenseVsNewSalesGridDataResponse && { smExpense: smExpenseVsNewSalesGridDataResponse }),
					...(magicNumberGridDataResponse && { magicNumber: magicNumberGridDataResponse }),
					...(burnMultipleGridDataResponse && { burnMultiple: burnMultipleGridDataResponse }),
					...(companyDetailsDataResponse && { headCount: companyDetailsDataResponse?.companySize > 0 ? companyDetailsDataResponse?.companySize : 0 }),
				})
			);
			setRefreshing(false);
			loadingForTables(false);
		} catch (error) {
			console.log(error);
			setRefreshing(false);
			loadingForTables(false);
		}
	};

	const loadingForTables = (value) => {
		dispatch(
			setBIDashboardData({
				loadingAcv: value,
				loadingCapData: value,
				loadingAnnualRecurringRevenue: value,
				loadingBurnAndRunway: value,
				loadingCustomers: value,
				loadingMonthlyRecurringRevenue: value,
				loadingNetNewArr: value,
				loadingCacPayback: value,
				loadingRevenue: value,
				loadingSmExpense: value,
				loadingHeadCount: value,
			})
		);
	};

	return (
		<Box>
			<LoadingButton
				loaderColor={theme.palette.primary.main}
				loadingStyles={{
					border: "4px solid",
					backgroundColor: theme.palette.secondary.white,
					borderColor: theme.palette.primary.main,
					height: "40px",
					px: 4,
					width: "110px",
				}}
				loading={refreshing}
				onClick={!refreshing ? refreshData : () => {}}
				variant="DS1"
			
				// disabled={!isEdited || readOnly}
			>
				Refresh Data
			</LoadingButton>
		</Box>
	);
}

export default RefreshData;
