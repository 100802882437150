import { Card } from "@mui/material";
import { styled, createTheme } from "@mui/material/styles";
import { sosPrimary } from "../../../../constants/Theme/colors";
import theme from "../../../../constants/Theme";

export const cardTheme = createTheme(theme, {
  components: {
    PowerUpCard: {
      styleOverrides: {
        centered: {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        block: {
          display: "block",
        },
      },
      variants: [
        {
          props: { variant: "outlined" },
          style: {
            border: `1px solid ${theme.palette.secondary.light}`,
          },
        },
        {
          props: { variant: "outlined", selected: true },
          style: {
            border: `1px solid ${theme.palette.primary.main}`,
            backgroundColor: sosPrimary[50],
          },
        },
        {
          props: { variant: "filled" },
          style: {
            "&:hover": {
              border: "5px solid red",
            },
          },
        },
        {
          props: { variant: "nofill" },
          style: {
            "&:hover": {
              border: "5px solid red",
            },
          },
        },
      ],
    },
  },
});

export const PowerUpCard = styled(Card, {
  // Configure which props should be forwarded on DOM
  shouldForwardProp: prop =>
    prop !== "disabled" &&
    prop !== "alignment" &&
    prop !== "variant" &&
    prop !== "selected" /* && prop !== "customProp"  */,
  name: "PowerUpCard",
  overridesResolver: (props, styles) => [
    props.disabled && styles.disabled,
    props.alignment == "centered" && styles.centered,
  ],
})(({ theme }) => ({
  // Applies to All PowerUpCard Components
  borderRadius: theme.shape.standard.borderRadius,
  transition: "all .2s",
  textAlign: "center",
  boxShadow:
    "0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
  height: "100%",
  width: "100%",
}));

export const PowerUpCardGrid = styled(Card, {
  // Configure which props should be forwarded on DOM
  shouldForwardProp: prop =>
    prop !== "disabled" &&
    prop !== "alignment" &&
    prop !== "variant" &&
    prop !== "selected" /* && prop !== "customProp"  */,
  name: "PowerUpCard",
  overridesResolver: (props, styles) => [
    props.disabled && styles.disabled,
    props.alignment == "centered" && styles.centered,
  ],
})(({ theme }) => ({
  // Applies to All PowerUpCard Components
  borderRadius: theme.shape.standard.borderRadius,
  transition: "all .2s",
  textAlign: "center",
  boxShadow: theme.shadows[0],
  height: "100%",
}));
