import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Dialog, DialogContent } from "@mui/material";
import Slide from "@mui/material/Slide";

import FilterHeader from "./Components/FilterHeader";
import FilterCompanySection from "./Components/CompanyDiv/FilterCompanySection";
import FilterTeamSection from "./Components/TeamDiv/FilterTeamSection";
import FilterIndustrySection from "./Components/IndustryDiv/FilterIndustrySection";
import SaveAsComponent from "./Components/SaveAs/SaveAsComponent";
import { setInvestorDashboardData } from "../../../modules/actions";
import DemofilterData from "./demoData";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="left" ref={ref} {...props} />;
});

const FilterDrawer = (props) => {
	const { disableBackDropClick } = props;
	const [scroll, setScroll] = useState("paper");
	const [openSaveas, setOpenSaveas] = useState(false);
	const [disableSave, setDisableSave] = useState(true);
	const partiallySelected = useSelector((state) => state?.investorDashboard?.partiallySelected);

	const filteredValue = useSelector((state) => state?.investorDashboard?.filteredValue) || [];

	const open = useSelector((state) => state?.investorDashboard?.FilterDrawerOpen);
	const selectedValues = useSelector((state) => state?.investorDashboard?.selectedValues) || [];
	const dispatch = useDispatch();

	useEffect(() => {
		if (filteredValue.length > 0) {
			setDisableSave(false);
		} else {
			setDisableSave(true);
		}
	});

	const handleClose = () => {
		if (open == true) {
			dispatch(setInvestorDashboardData({ FilterDrawerOpen: false }));
		} else {
			dispatch(setInvestorDashboardData({ FilterDrawerOpen: true }));
		}
	};

	useEffect(() => {
		props.setFilterPayload(filteredValue);
	}, [props.filterResult]);

	const handleDelete = (checkedValue, type = "") => {
		const templist = [...filteredValue];
		const data = selectedValues.filter((item) => item.stateName === checkedValue);
		const currentSelectedValue = selectedValues.filter((value) => value.cName === data[0]?.cName);

		let finalFilteredList = templist.filter((item) => !(item?.checkboxValue === checkedValue && item?.type === type));
		let selectedValuesList = selectedValues.filter((item) => item.stateName !== checkedValue);

		let tempPartiallySelected = partiallySelected;

		tempPartiallySelected.map((partialItem) => {
			partialItem.value = false;
			if (currentSelectedValue.length > 1) {
				if (partialItem.type === data[0]?.cName) {
					partialItem.value = false;
				}
			} else {
				if (partialItem.type === data[0]?.cName) {
					partialItem.value = false;
				}
			}
		});

		dispatch(
			setInvestorDashboardData({
				filteredValue: finalFilteredList,
				selectedValues: selectedValuesList,
				partiallySelected: [...tempPartiallySelected],
			})
		);
	};

	const handleUncheck = (value, type) => {
		const checkedlist = [...filteredValue];
		let finalFilteredList = checkedlist.filter((index) => !(index?.checkboxValue === value && index?.type === type));
		dispatch(
			setInvestorDashboardData({
				filteredValue: finalFilteredList,
			})
		);
	};

	const handleOpenSaveAs = () => {
		setOpenSaveas(true);
	};

	const handleCloseSaveas = () => {
		setOpenSaveas(false);
	};

	const handleClearAll = () => {
		dispatch(
			setInvestorDashboardData({
				filteredValue: [],
				selectedValues: [],
			})
		);
	};

	return (
		<Box>
			<Dialog
				maxWidth="lg"
				fullWidth={true}
				open={open}
				onClose={disableBackDropClick ? () => {} : handleClose}
				TransitionComponent={Transition}
				transitionDuration={700}
				keepMounted
				PaperProps={{ sx: { position: "fixed", top: 0, right: 0, m: 0, borderRadius: "20px", minHeight: 1 } }}
			>
				<FilterHeader
					{...props}
					handleClose={handleClose}
					handdleDelete={handleDelete}
					disableSave={disableSave}
					handleOpenSaveAs={handleOpenSaveAs}
					open={openSaveas}
					handleCloseSaveas={handleCloseSaveas}
					disableBackDropClick={disableBackDropClick}
					handleClearAll={handleClearAll}
				/>
				<DialogContent
					sx={{
						border: "none",
					}}
					dividers={scroll === "paper"}
				>
					<FilterCompanySection filterData={DemofilterData} handleUncheck={handleUncheck} lable={"Company"} />
					<FilterTeamSection filterData={DemofilterData} handleUncheck={handleUncheck} />
					<FilterIndustrySection filterData={DemofilterData} handleUncheck={handleUncheck} lable={"Industry"} />
				</DialogContent>
			</Dialog>
			<SaveAsComponent
				open={openSaveas}
				handleOpenSaveAs={handleOpenSaveAs}
				handleCloseSaveas={handleCloseSaveas}
				disableBackDropClick={disableBackDropClick}
				{...props}
			/>
		</Box>
	);
};
export default FilterDrawer;
