import { Box, Typography } from "@mui/material";
import React from "react";
import SvgComponent from "../../../utils/SVGColor";

const Item = ({ iconComp, textComp, isSvg }) => (
	<Box
		sx={{
			display: "flex",
			gap: "10px",
		}}
	>
		{!isSvg ? (
			<Box
				sx={{
					height: "24px",
					width: "24px",
					color: "#98A2B3",
				}}
			>
				{iconComp}
			</Box>
		) : (
			<SvgComponent strokeColor={"#98A2B3"} svgUrl={iconComp} style={{ height: "24px", width: "24px" }} />
		)}
		<Typography
			variant="customValue"
			component={"div"}
			sx={{
				color: "black",
				fontFamily: "PoppinsMedium",
				fontSize: "16px",
				lineHeight: "24px",
				maxWidth: "200px",
				overflow: "hidden",
				overflowWrap: "break-word",
			}}
		>
			{textComp}
		</Typography>
	</Box>
);

function PublicTagList({ items }) {
	return (
		<Box sx={{ display: "flex", columnGap: "40px", rowGap: "20px", flexWrap: "wrap" }}>
			{items.map((item, index) => (
				<Box key={index} sx={{ flex: "1 1 45%" }}>
					<Item iconComp={item.iconUrl} textComp={item.label} isSvg={true} />
				</Box>
			))}
		</Box>
	);
}

export default PublicTagList;
