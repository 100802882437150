import React, { useContext } from "react";
import { Box } from "@twilio-paste/core";
import UpdatedImageUploader from "../../../common/UpdatedImageUploader";
import { useWindowSize } from "../../../utils/windowResize";
import { useMediaQuery } from "@mui/material";

const ProfilePhotoSection = ({ context }) => {
	const { payLoad, setPayLoad } = useContext(context);
	const size = useWindowSize();
	const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
	const responsiveDrawerWidth = isSmallScreen ? size.width * 0.69 : size.width * 0.85;

	return (
		<Box>
			<UpdatedImageUploader
				title={"Your Startup Logo"}
				editTitle={"Edit Startup Logo"}
				instructions={""}
				width={responsiveDrawerWidth}
				height={responsiveDrawerWidth}
				maxWidth="450px"
				maxHeight="450px"
				shape={"circular"}
				userData={payLoad}
				setPayLoad={setPayLoad}
				type={"logoUrl"}
				circularCropper={true}
				aspectRatio={1 / 1}
				fileType={{ "image/png": [], "image/jpg": [], "image/jpeg": [] }}
			/>
		</Box>
	);
};

export default ProfilePhotoSection;
