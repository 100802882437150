import { Typography, Box } from "@mui/material";
import React from "react";
import { getCharDateMonth } from "../../../../../utils/date";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
export default function DatePickerWarning({ containerStyles }) {
  return (
    <Box
      sx={{
        ...containerStyles,
      }}
    >
      <Box
        sx={{
          p: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          variant="label_large"
          sx={{ color: "secondary.outlineLight" }}
        >
          {getCharDateMonth(new Date())}
        </Typography>
        <Box sx={{ display: "flex" }}>
          <ArrowBackIosIcon sx={{ color: "secondary.outlineLight" }} />
          <ArrowForwardIosIcon sx={{ color: "secondary.outlineLight" }} />
        </Box>
      </Box>

      <Box
        sx={{
          my: 10,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          p: 5,
        }}
      >
        <Typography
          variant="label_large"
          sx={{ color: "secondary.outlineXLight" }}
          align="center"
        >
          Complete all required work to schedule your Advisor Plugin.
        </Typography>
      </Box>
    </Box>
  );
}
