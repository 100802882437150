import { Box, Stack, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { PITCH_PERFECT_2_BANNER, USERS_BLACK_ROUNDED_ICON } from "../../constants";
import MeetingsLayout from "./MeetingsLayout";
import MeetingSidebar from "./Sidebar";
import MyAvailability from "./MyAvailability";
import { MeetingList } from "../../containers/Meetings/Bookings/BookedMeetings";
import PaymentPlanDialog from "../PaymentPlanDialog";
import { USER_TYPES } from "../../utils/userTypes";

export default function MeetingsView({ content, activeKey }) {
	const isAdmin = useSelector((store) => store?.auth?.isAdmin);
	const isPremiumPlanAccess = useSelector((store) => store?.auth?.isPremiumPlanAccess);
	const userType = useSelector((store) => store?.auth?.userType);
	const [showPlanModal, setShowPlanModal] = useState(false);

	// useEffect(() => {
	// 	if (isPremiumPlanAccess || isAdmin || userType === USER_TYPES.MENTOR_USER || userType === USER_TYPES.INVESTOR_USER) {
	// 	} else {
	// 		setShowPlanModal(true);
	// 	}
	// }, [isPremiumPlanAccess, isAdmin, userType]);

	const handleClosePlanModal = () => {
		setShowPlanModal(false);
	};

	return (
		<Stack
			sx={(theme) => ({
				borderRadius: theme.shape.standard.borderRadius,
				[theme.breakpoints.down("md")]: {
					borderRadius: 0,
				},
			})}
		>
			<Box
				sx={(theme) => ({
					maxWidth: theme.breakpoints.values.xl,
					margin: "auto",
					width: "100%",
					//border: "1px solid #EAEAEF",
					//borderRadius: theme.shape.standard.borderRadius,
					pb: "30px",
					display: "flex",
					flexDirection: "column",
					gap: "32px",
					maxWidth: "1920px", // Figma specifies max content width
				})}
			>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						gap: "12px",
						px: {
							lg: 8,
							md: 4,
							xs: 2,
						},
					}}
				>
					<Box component={"img"} src={USERS_BLACK_ROUNDED_ICON} alt="dashbaord" sx={{ width: "30px", height: "30px" }} />
					<Typography variant="growth_card_subtitle" color="secondary.black">
						Meetings
					</Typography>
				</Box>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						px: {
							lg: 8,
							md: 4,
							xs: 2,
						},
					}}
				>
					<Box
						sx={{
							backgroundColor: "#fff",
							borderRadius: "40px",
							boxShadow: "0px 10px 85px rgba(0, 0, 0, 0.09)",
							padding: "45px",
							flex: 1,
							overflow: "auto",
						}}
					>
						<MeetingList />
					</Box>
				</Box>
				{/*<MeetingsLayout sidebar={<MeetingSidebar activeKey={activeKey} />} main={<Box>{content}</Box>} />*/}
			</Box>
			{showPlanModal && <PaymentPlanDialog open={showPlanModal} onClose={handleClosePlanModal} />}
		</Stack>
	);
}
