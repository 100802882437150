import React, { useState } from "react";
import { Box } from "@mui/material";
import ScoutingReportController from "../../components/ScoutingReport/ScoutingReportController";

// http://localhost:3000/scouting-report
export default function ScoutingReportHardcode() {
    const [openScoutingReport, setOpenScoutingReport] = useState(false);

    const startup1 = {
        about: null,
        ask: null,
        capitalRaised: "2000000",
        abcheckedout: false,
        city: "The Colony",
        companyName: "71scott",
        currentStage: "Pre-Seed",
        employeeCount: 1,
        entityStatus: "ACTIVE",
        financing: "None",
        foundedYear: 2022,
        fundingType: "[\"Angel\"]",
        id: 454,
        inPortfolio: false,
        inWatchlist: false,
        industry: null,
        logoUrl: "https://d3rm35snnwgfg3.cloudfront.net/uploads/logos/1666387312869screen-shot-2022-10-21-at-4.17.58-pm.png",
        productOffering: "Software",
        revenue: "No",
        scorecard: {
            companyId: 454,
            flag: "Good",
            lastMonthScore: 0,
            peerGroupCount: 627,
            peerGroupPercentile: 31.578945,
            scoreDiffInPercentage: 0,
            comptotalScoreanyId: 36
            
        },
        seeking: "Seed",
        state: "Texas",
        tagLine: "hello test test test test test test test test",
        
    }

    const startup2 = {
        about: null,
        ask: null,
        capitalRaised: "123450",
        abcheckedout: false,
        city: "Canton",
        companyName: "Bear Food",
        currentStage: "Pre-Seed",
        employeeCount: 1,
        entityStatus: "ACTIVE",
        financing: "None",
        foundedYear: 2013,
        fundingType: "[\"Angel\"]",
        id: 455,
        inPortfolio: false,
        inWatchlist: false,
        industry: null,
        logoUrl: "https://media.istockphoto.com/id/1158342659/vector/bear-head-mascot-vector-for-emblem-design-with-color-grey.jpg?s=612x612&w=0&k=20&c=52_wgeMA5A5mS4JGuddIcnMzw7G9IRCnAalKC-jlNJY=",
        productOffering: "Software",
        revenue: "No",
        scorecard: {
            companyId: 455,
            flag: "Good",
            lastMonthScore: 0,
            peerGroupCount: 456,
            peerGroupPercentile: 34,
            scoreDiffInPercentage: 0,
            comptotalScoreanyId: 37
            
        },
        seeking: "Seed",
        state: "Michigan",
        tagLine: "BEar traps and food etc",
    }

    const startupList = { startup1, startup2 };

    const toggleOpenScoutingReport = (newOpen) => {
		setOpenScoutingReport(newOpen);
	};


    return(
        <Box>
            <ScoutingReportController startup={startup1} onClose={() => { toggleOpenScoutingReport(false); }} startupList={startupList} />
        </Box>
    );
}

