import React, { useState, useEffect } from "react";
import { Typography, Box, TextField, Button, CircularProgress } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { MentorExperienceWrapper } from "../styles";
import CustomizedYearPicker from "../YearPicker";
import { DEGREE_TYPES, DELETE_ICON } from "../../../constants";
import { REGEX } from "../../../constants/regex";
import messages from "../../../constants/messages";
import services from "../../../services";
import StartupOsTextField from "../../../common/StartupOsInput/StartupOsTextField";
import { toastContainer } from "../../../utils/toast";
import SelectInput from "../../../common/SelectInput";

const MentorEducation = ({ setShowEducation, isEducationEdit, educationDetails, setEduLoader, handleUserDetails = () => {} }) => {
	const [accolade, setAccolade] = useState({ value: "", error: "" });
	const [schoolName, setSchoolName] = useState({ value: "", error: "" });
	const [location, setLocation] = useState({ value: "", error: "" });
	const [degreeType, setDegreeType] = useState({ value: "", error: "" });

	const [startYear, setStartYear] = useState({ value: null, error: "" });
	const [endYear, setEndYear] = useState({ value: null, error: "" });
	const [loader, setLoader] = useState(false);

	useEffect(() => {
		if (isEducationEdit) {
			setAccolade({ value: educationDetails.course });
			setSchoolName({ value: educationDetails.university });
			setLocation({ value: educationDetails.location });
			setDegreeType({ value: educationDetails?.degreeType ? educationDetails?.degreeType : "" });

			setStartYear({ value: new Date(educationDetails.startYear, 0, 32) });
			setEndYear({ value: new Date(educationDetails.endYear, 0, 32) });
		}
	}, []);
	const handleBlur = (e, type) => {
		let eventValue = e.target.value;

		switch (type) {
			case "accolade":
				if (eventValue.match(REGEX.LOCATION)) {
					setAccolade((prevState) => ({
						...prevState,
						error: "",
						value: eventValue,
					}));
				} else {
					setAccolade((prevState) => ({
						...prevState,
						error: messages.ACCOLADE_LENGTH_INVALID,
						value: eventValue,
					}));
				}
				break;
			case "schoolName":
				if (!eventValue.match(REGEX.LOCATION)) {
					setSchoolName((prevState) => ({
						...prevState,
						error: messages.SCHOOL_LENGTH_INVALID,
						value: eventValue,
					}));
				} else {
					setSchoolName((prevState) => ({
						...prevState,
						value: eventValue,
						error: "",
					}));
				}
				break;
			case "location":
				if (!eventValue.match(REGEX.LOCATION)) {
					setLocation((prevState) => ({
						...prevState,
						error: messages.LOCATION_LENGTH_INVALID,
						value: eventValue,
					}));
				} else {
					setLocation((prevState) => ({
						...prevState,
						value: eventValue,
						error: "",
					}));
				}
				break;
		}
	};
	const handleOnChange = (e, type) => {
		let eventValue = e.target.value;
		switch (type) {
			case "accolade":
				setAccolade((prevState) => ({
					...prevState,
					error: "",
					value: eventValue,
				}));

				break;
			case "schoolName":
				setSchoolName((prevState) => ({
					...prevState,
					value: eventValue,
					error: "",
				}));

				break;
			case "location":
				setLocation((prevState) => ({
					...prevState,
					value: eventValue,
					error: "",
				}));

				break;
			case "degreeType":
				setDegreeType((prevState) => ({
					...prevState,
					value: eventValue,
					error: "",
				}));

				break;
		}
	};
	const handleDateChange = (value, type) => {
		if (type === "startYear" && endYear) {
			if (value.getFullYear() > endYear?.value?.getFullYear()) {
				setStartYear({
					error: messages.START_DATE_ERROR,
					value: value,
				});
			} else {
				setStartYear((prevState) => ({
					...prevState,
					value: value,
					error: "",
				}));
			}
		} else {
			if (value.getFullYear() < startYear?.value?.getFullYear()) {
				setEndYear({
					error: messages.END_DATE_ERROR,
					value: value,
				});
			} else {
				setEndYear((prevState) => ({
					...prevState,
					value: value,
					error: "",
				}));
			}
		}
	};
	const getButtonDisableStatus = () => {
		if (accolade.error || schoolName.error || location.error || startYear.error || endYear.error) {
			return true;
		} else {
			return false;
		}
	};
	const addEducationApiCall = (payload) => {
		return services
			.addMentorEducation(payload)
			.then((result) => {
				if (result.data.code === 200) {
					handleUserDetails();
					setShowEducation(false);
					setLoader(false);
					return true;
				}
			})
			.catch((error) => {
				throw error;
				setLoader(false);
			});
	};
	const updateEducationApiCall = (payload) => {
		return services
			.updateMentorEducation(payload, educationDetails.id)
			.then((result) => {
				if (result.data.code === 200) {
					handleUserDetails();
					setShowEducation(false);
					setLoader(false);
					return true;
				}
			})
			.catch((error) => {
				throw error;
				setLoader(false);
			});
	};
	const deleteEducationApiCall = () => {
		setEduLoader(true);
		return services
			.deleteMentorEducation(educationDetails.id)
			.then((result) => {
				if (result.data.code === 200) {
					handleUserDetails();
					setShowEducation(false);
					toastContainer("Education deleted successfully");
					setEduLoader(false);
					return true;
				}
			})
			.catch((error) => {
				setEduLoader(false);
				throw error;
			});
	};
	const handleSave = () => {
		setLoader(true);
		let payload = {
			course: accolade.value,
			university: schoolName.value,
			location: location.value,
			degreeType: degreeType?.value ? degreeType?.value : "",
			startYear: startYear.value.getFullYear(),
			endYear: endYear.value.getFullYear(),
		};
		if (isEducationEdit) {
			updateEducationApiCall(payload);
		} else {
			addEducationApiCall(payload);
		}
	};
	return (
		<MentorExperienceWrapper>
			<Box display="flex" alignItems={"center"}>
				<Typography variant="title_medium">{isEducationEdit ? "Edit Education" : "New Education"}</Typography>
				<CloseIcon fontSize="small" sx={{ ml: "auto", cursor: "pointer" }} onClick={() => setShowEducation(false)} />
			</Box>
			<Box sx={{ mt: 3 }}>
				<StartupOsTextField
					label="Accolade"
					spellCheck="true"
					sx={{ width: 1 }}
					error={accolade.error}
					value={accolade.value}
					onBlur={(e) => {
						handleBlur(e, "accolade");
					}}
					onChange={(e) => {
						handleOnChange(e, "accolade");
					}}
					helperText={accolade.error}
				/>
				<StartupOsTextField
					label="School Name"
					sx={{ width: 1, mt: 5 }}
					spellCheck="true"
					error={schoolName.error}
					value={schoolName.value}
					onBlur={(e) => {
						handleBlur(e, "schoolName");
					}}
					onChange={(e) => {
						handleOnChange(e, "schoolName");
					}}
					helperText={schoolName.error}
				/>
				<SelectInput
					label="Degree Type"
					wrapperstyle={{ width: 1, mt: 5 }}
					fullWidth
					onInputChange={(e) => {
						handleOnChange(e, "degreeType");
					}}
					selectedValue={degreeType?.value}
					inputKey="degreeType"
					options={DEGREE_TYPES}
				/>
				<StartupOsTextField
					label="Location"
					sx={{ width: 1, mt: 5 }}
					spellCheck="true"
					error={location.error}
					value={location.value}
					onBlur={(e) => {
						handleBlur(e, "location");
					}}
					onChange={(e) => {
						handleOnChange(e, "location");
					}}
					helperText={location.error}
				/>
				<Typography variant="title_small" sx={{ mt: 5, display: "block" }}>
					Start Date
				</Typography>

				<Box sx={{ mt: 2 }}>
					<CustomizedYearPicker
						value={startYear.value}
						label="Year"
						error={startYear.error}
						onChange={(newValue) => {
							handleDateChange(newValue, "startYear");
						}}
					/>
				</Box>
				<Typography variant="title_small" sx={{ mt: 5, display: "block" }}>
					End Date
				</Typography>

				<Box sx={{ mt: 2 }}>
					<CustomizedYearPicker
						value={endYear.value}
						label="Year"
						error={endYear.error}
						onChange={(newValue) => {
							handleDateChange(newValue, "endYear");
						}}
					/>
				</Box>
				<Box display="flex" sx={{ mt: 4 }}>
					{educationDetails?.id && (
						<Box component="img" sx={{ cursor: "pointer" }} src={DELETE_ICON} alt="delete-icon" onClick={() => deleteEducationApiCall()} />
					)}
					<Box sx={{ ml: "auto" }}>
						{loader ? (
							<CircularProgress
								sx={{
									display: "flex",
									justifyContent: "center",
									maxWidth: "30px",
									px: 3,
								}}
							/>
						) : (
							<Button variant="text" sx={{ maxWidth: "fit-content" }} disabled={getButtonDisableStatus()} onClick={() => handleSave()}>
								Save
							</Button>
						)}
					</Box>
				</Box>
			</Box>
		</MentorExperienceWrapper>
	);
};

export default MentorEducation;
