import React, { useContext } from "react";
import { Box, Button, Stack, Typography, Avatar } from "@mui/material";
import { PowerPackContext } from "../../PowerPackController";
import { CALENDAR_BLANK_BLACK, MESSAGES_BLACK_ICON } from "../../../../constants";
import { useDispatch } from "react-redux";
import { setSlidingProfileData } from "../../../../modules/actions/slidingProfileActions";
import { handleMixPanel } from "../../../../utils/mixPanelEventHandling";

function TopMentorMatches() {
	const { topMentorList, onCalendarClick, onMessageClick, allMentorRedirection } = useContext(PowerPackContext);
	const dispatch = useDispatch();

	const redirectToMatched = (mentorItem) => {
		handleMixPanel(`Mentor Clicked`, {
			"Mentor Id": mentorItem?.id,
		});
		dispatch(
			setSlidingProfileData({
				open: true,
				profileType: "MENTOR_PROFILE",
				profileId: mentorItem?.id,
			})
		);
	};
	const MentorCard = ({ mentorItem }) => {
		return (
			<Box display={"flex"} alignItems={"center"} gap={2}>
				<Avatar sx={{ cursor: "pointer" }} onClick={() => redirectToMatched(mentorItem)} alt="Remy Sharp" src={mentorItem?.picture} />
				<Box sx={{ cursor: "pointer" }} onClick={() => redirectToMatched(mentorItem)}>
					{" "}
					<Typography variant="Text/sm/Semibold" component={"p"}>
						{mentorItem?.name}
					</Typography>
					<Typography variant="Text/sm/Regular" component={"p"}>
						Mentor
					</Typography>
				</Box>

				<Box display={"flex"} alignItems={"center"} gap={1} sx={{ ml: "auto" }}>
					<Box
						component="img"
						src={CALENDAR_BLANK_BLACK}
						sx={{ width: "24px", height: "24px", cursor: "pointer" }}
						onClick={() => onCalendarClick?.(mentorItem)}
					/>
					<Box
						component="img"
						src={MESSAGES_BLACK_ICON}
						sx={{ width: "24px", height: "24px", cursor: "pointer" }}
						onClick={() => onMessageClick?.(mentorItem)}
					/>
				</Box>
			</Box>
		);
	};

	return (
		<Stack width={"100%"} spacing={2}>
			<Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
				<Typography component={"p"} variant="Text/md/Semibold">
					Top Mentor Matches
				</Typography>
				<Button sx={{ fontSize: "14px" }} variant="DS1" onClick={() => allMentorRedirection?.()}>
					See all Mentors
				</Button>
			</Box>

			{topMentorList?.map((mentorItem) => (
				<MentorCard mentorItem={mentorItem} />
			))}
		</Stack>
	);
}

export default TopMentorMatches;
