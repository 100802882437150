import { Box, Typography } from "@mui/material";
import React from "react";
import { POWERUP_LOGO_WHITE } from "../../../constants/image";
import RatingComponent from "../../../common/rating";

export default function PowerupHeaderComp({ data, ratingDetails }) {
  return (
    <Box sx={{ px: { md: 4, xs: 3 }, py: { md: 3, xs: 2 } }}>
      <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
        <Box sx={{ height: "23px", pt: "2px" }}>
          <img src={POWERUP_LOGO_WHITE} />{" "}
        </Box>
        <Box
          sx={theme => ({
            [theme.breakpoints.down("md")]: {
              display: "none",
            },
          })}
        >
          <RatingComponent
            Readonly={true}
            rating={5}
            //rating={ratingDetails?.rating}
          />
        </Box>
        <Box
          sx={theme => ({
            [theme.breakpoints.up("md")]: {
              display: "none",
            },
          })}
        >
          <Typography variant="headline2">{data.title}</Typography>
        </Box>
      </Box>

      <Box
        sx={theme => ({
          [theme.breakpoints.down("md")]: {
            display: "none",
          },
        })}
      >
        <Typography variant="headline1">{data.title}</Typography>
      </Box>
      <Box
        sx={theme => ({
          mt: 1,
          mb: 2,
          [theme.breakpoints.down("md")]: {
            display: "none",
          },
        })}
      >
        <Typography variant="subhead1">{data.description}</Typography>
      </Box>
    </Box>
  );
}
