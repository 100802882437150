import React, { useState, createRef } from "react";
import { Box, Stack, Typography, Paper, Tabs, Tab, Divider, Button, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import { Bolt, StarsRounded, Toc, Whatshot, ArrowDownward, ArrowUpward } from "@mui/icons-material";

import { stateToHTML } from "draft-js-export-html";
import { convertFromRaw } from "draft-js";

import { POWERUPKNOWLEDGELOGO } from "../../../constants";
import { AttributeItem } from "../../../components/Workspace/PowerUp/Sidebar/components/attribute-item";
import { ReviewsTab } from "../../../components/Workspace/PowerUp/Sidebar/components/reviews-tab";
import stateToHTMLOptions from "../../../containers/Partner/PowerUpBuilder/PowerUpPreview/state2html";

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
			{value === index && <>{children}</>}
		</div>
	);
}

function InspirationItem({ media, description }) {
	return (
		<Stack gap={2}>
			<iframe src={media} style={{ maxWidth: "100%", aspectRatio: "16 / 9", border: 0 }} />
			<Typography variant={"body1"}>{description}</Typography>
		</Stack>
	);
}

export const KnowledgeBar = ({ title, details, timeShort, timeLong, difficulty, recommended, powertips, inspirations, submissions, sort, id }) => {
	const [knowTabIndex, setKnowTabIndex] = useState(0);
	const scrollRef = createRef();

	return (
		<Stack gap={2}>
			{/* <Paper elevation={1}>
				<Stack p={3} gap={3}>
					<Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
						<Typography variant={"largeTitle"}>Completed Work</Typography>
						<Button variant={"text"} color={"primary"} sx={{ width: "unset", margin: "unset" }}>
							View All
						</Button>
					</Stack>
					<TableContainer>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>
										<Stack direction={"row"} alignItems={"center"} gap={1}>
											<Typography variant={"title_small"}>Name</Typography>
											{sort?.field === "id" && (sort.order ? <ArrowDownward /> : <ArrowUpward />)}
										</Stack>
									</TableCell>
									<TableCell>
										<Stack direction={"row"} alignItems={"center"} justifyContent={"flex-end"} gap={1}>
											<Typography variant={"title_small"}>Last Modified</Typography>
											{sort?.field === "updated" && (sort.order ? <ArrowDownward /> : <ArrowUpward />)}
										</Stack>
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{submissions.map(({ id, updated }) => (
									<TableRow key={id}>
										<TableCell>
											<Typography variant={"body1"}>
												{title} {id}
											</Typography>
										</TableCell>
										<TableCell align={"right"}>
											<Typography variant={"body1"}>{new Date(updated?.at).toLocaleString()}</Typography>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</Stack>
			</Paper> */}
			<Paper elevation={1} sx={{ position: "sticky", top: 76 }}>
				<Box p={3}>
					<img src={POWERUPKNOWLEDGELOGO} alt="Knowledge" style={{ maxHeight: 20 }} />
				</Box>
				<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
					<Tabs variant="fullWidth" aria-label="basic tabs example" value={knowTabIndex} onChange={(e, i) => setKnowTabIndex(i)}>
						<Tab
							icon={<Toc />}
							label="Details"
							{...a11yProps(0)}
							sx={theme => ({
								textTransform: "none",
								fontSize: theme.typography.label_medium,
								fontFamily: theme.typography.label_medium,
							})}
						/>
						<Tab
							icon={<Bolt />}
							label="Power Tips"
							{...a11yProps(1)}
							sx={theme => ({
								textTransform: "none",
								fontSize: theme.typography.label_medium,
								fontFamily: theme.typography.label_medium,
							})}
						/>
						<Tab
							icon={<Whatshot />}
							label="Inspiration"
							{...a11yProps(2)}
							sx={theme => ({
								textTransform: "none",
								fontSize: theme.typography.label_medium,
								fontFamily: theme.typography.label_medium,
							})}
						/>
						<Tab
							icon={<StarsRounded />}
							label="Reviews"
							{...a11yProps(3)}
							sx={theme => ({
								textTransform: "none",
								fontSize: theme.typography.label_medium,
								fontFamily: theme.typography.label_medium,
							})}
						/>
					</Tabs>
				</Box>
				<Box>
					<TabPanel value={knowTabIndex} index={0}>
						<Stack gap={3} p={3}>
							<Typography variant="title_medium">Details</Typography>
							<Divider />
							<Typography variant={"body1"}>{details}</Typography>
							<Stack>
								<AttributeItem icon="time" name="Time Estimate" />
								<Stack direction={"row"} alignItems={"baseline"} pl={4}>
									<Typography variant={"body1"}>{timeShort}</Typography>
									<Typography variant={"body1"}> – </Typography>
									<Typography variant={"body1"}>{timeLong}</Typography>
									<Typography variant={"body1"}>&nbsp;hours</Typography>
								</Stack>
							</Stack>
							<Stack>
								<AttributeItem icon="steps" name="Difficulty" />
								<Typography variant={"body1"} pl={4}>
									{difficulty.capitalize()}
								</Typography>
							</Stack>
							<Stack>
								<AttributeItem icon="person" name="Team Recommended" />
								<Typography variant={"body1"} pl={4}>
									{recommended.capitalize()}
								</Typography>
							</Stack>
						</Stack>
					</TabPanel>
					<TabPanel value={knowTabIndex} index={1}>
						<Stack gap={2} p={3}>
							<Typography variant="title_medium">Power Tips</Typography>
							<div dangerouslySetInnerHTML={{ __html: powertips ? stateToHTML(convertFromRaw(JSON.parse(powertips)), stateToHTMLOptions) : "" }} />
						</Stack>
					</TabPanel>
					<TabPanel value={knowTabIndex} index={2}>
						<Stack gap={2} p={3} alignItems={"stretch"}>
							<Typography variant="title_medium">Inspiration</Typography>
							<Divider />
							<Stack gap={4}>
								{inspirations?.map(({ media, description }, i) => (
									<InspirationItem key={i} media={media} description={description} />
								))}
							</Stack>
						</Stack>
					</TabPanel>
					<TabPanel value={knowTabIndex} index={3}>
						<Stack gap={2} p={3}>
							<ReviewsTab scrollRef={scrollRef} powerupID={id} />
						</Stack>
					</TabPanel>
				</Box>
				<Divider />
			</Paper>
		</Stack>
	);
};
