import React, { forwardRef, useImperativeHandle } from "react";
import { Typography, Box, Divider, Grid, useMediaQuery } from "@mui/material";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import theme from "../../../../constants/Theme";
import { sosPrimary } from "../../../../constants/Theme/colors";
import ProblemSolutionSummaryBox from "../../../../components/Workspace/PowerUp/ProblemSolutionSummaryBox";
import { TUNGSTEN_ICON } from "../../../../constants/image";
import FormSectionHeader from "../../PowerUp/FormSectionHeader";
import PowerupExcerciseTitleBox from "../../../../components/Workspace/PowerUp/PowerupTitleBox";
import GoogleTrendsContainer from "../../../../containers/Workspace/PowerUP/ProblemSolution/Components/GoogleTrends/Container";
import { Document, Image, Line, Page, Text, View, pdf, usePDF } from "@react-pdf/renderer";
import { PowerUPLetterHead, getComponentImage, styles } from "../../../../utils/PDF-Export/utilities";

export const ProblemSolutionExportPDF = async ({ problemSolutionList, answersData }) => {
	return new Promise(async (resolve, reject) => {
		try {
			const IntroAsImageSrc = await getComponentImage({
				component: (
					<Box flexDirection={"column"}>
						<Box>
							<PowerupExcerciseTitleBox
								iconUrl={TUNGSTEN_ICON}
								title={answersData.idea}
								subTitle={"Your Idea"}
								iconBackgroundColor={sosPrimary[500]}
								contentBackgroundColor={"secondary.white"}
								contentTextColor={sosPrimary[500]}
							/>
						</Box>
						<Divider
							sx={{
								background: "rgba(124, 117, 127, 0.16)",
								mt: 1,
							}}
						/>
						<Box sx={{ mt: 2 }}>
							<Grid container>
								<Grid item sm={4} xs={4} md={2} lg={2}>
									<Typography variant="label_medium">Type</Typography>
								</Grid>
								<Grid item sm={8} xs={8} md={10} lg={10}>
									<Typography
										sx={{
											border: `1px solid ${sosPrimary[500]}`,
											borderRadius: theme.shape.standard25.borderRadius,
											p: "2px 8px",
										}}
										variant="label_medium"
										color={sosPrimary[500]}
									>
										{answersData.type}
									</Typography>
								</Grid>
								<Grid item sm={4} xs={4} md={2} lg={2} sx={{ mt: 2 }}>
									<Typography variant="label_medium">Category</Typography>
								</Grid>
								<Grid item sm={8} xs={8} md={10} lg={10} sx={{ mt: 2 }}>
									<Typography
										sx={{
											border: `1px solid ${sosPrimary[500]}`,
											borderRadius: theme.shape.standard25.borderRadius,
											p: "2px 8px",
										}}
										variant="label_medium"
										color={sosPrimary[500]}
									>
										{answersData.category}
									</Typography>
								</Grid>
							</Grid>
						</Box>
						<Divider
							sx={{
								background: "rgba(124, 117, 127, 0.16)",
								mt: 3,
							}}
						/>
						<Box sx={{ mt: 3 }}>
							<Typography variant="label_large" color={sosPrimary[500]}>
								Your Customer
							</Typography>
							<Box sx={{ mt: 1 }}>
								<Typography variant="bodyMedium">{answersData.customer.join(", ")}</Typography>
							</Box>
						</Box>
					</Box>
				),
			});

			// Function to convert the prob/sol objects to images.
			//const probSolObjs = await Promise.all(
			//	problemSolutionList?.map(async (item) => {
			//		const result = await getComponentImage({
			//			component: <ProblemSolutionSummaryBox problemSolutionObj={item} />,
			//		});
			//		return result;
			//	})
			//);

			const ProbSolCard = ({ obj }) => (
				<>
					<View
						wrap={false}
						style={{
							borderRadius: "20px",
							backgroundColor: "lightgray",
							padding: "20px",
							marginVertical: 10,
						}}
					>
						<Text style={[styles.subtitle, { marginVertical: 8, fontSize: 11 }]}>Problem</Text>
						<Text style={[styles.text, { fontSize: 10 }]}>{obj?.problem}</Text>
						<Text style={[styles.subtitle, { marginVertical: 8, fontSize: 11 }]}>Symptoms</Text>
						<Text style={[styles.text, { fontSize: 10 }]}>{obj?.symptoms}</Text>
						<Text style={[styles.subtitle, { marginVertical: 8, fontSize: 11 }]}>Existing Alternatives</Text>
						<Text style={[styles.text, { fontSize: 10 }]}>{obj?.existingAlternatives}</Text>
					</View>
					<View style={{ paddingHorizontal: "20px" }}>
						<Text style={[styles.subtitle, { marginVertical: 8, fontSize: 11 }]}>Possible Solutions</Text>
						{obj?.solutionList?.map((item) => (
							<View
								style={{ flexDirection: "row", gap: 8, alignItems: "flex-start", justifyContent: "flex-start", marginVertical: 4 }}
								wrap={false}
							>
								<Text style={[styles.subtitle, { fontSize: 10, marginVertical: 0 }]}>+</Text>
								<Text style={[styles.text, { fontSize: 10, marginVertical: 0 }]}>{item}</Text>
							</View>
						))}
					</View>
				</>
			);

			const PDFDocContent = (
				<Document>
					<Page style={styles.body}>
						<PowerUPLetterHead />
						<Image
							src={IntroAsImageSrc?.imageSrc}
							style={{ maxWidth: 400, backgroundColor: "rgba(106, 117, 117, 0.08)", borderRadius: "16px", padding: "32px" }}
						/>
						{/* Render list of Prob/Sol images */}
						{/*{probSolObjs?.map((obj) => (
					<Image src={obj?.imageSrc} style={{ maxWidth: "80%", backgroundColor: "rgba(106, 117, 117, 0.05)", marginVertical: 10 }} />
				))}*/}
						{problemSolutionList?.map((obj, index) => (
							<>
								<ProbSolCard obj={obj} />
								{index + 1 < problemSolutionList?.length && (
									<View style={{ marginVertical: 10, width: "100%", height: 1, border: "1px solid gray" }}></View>
								)}
							</>
						))}
						<Text style={styles.footerCopy} fixed>
							2023 StartupOS Inc.
						</Text>
						<Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`} fixed />
					</Page>
				</Document>
			);

			const blob = await pdf(PDFDocContent).toBlob();
			if (!blob) {
				console.error("Failed to create PDF blob");
				return;
			}
			const url = URL.createObjectURL(blob);

			const link = document.createElement("a");
			link.href = url;
			link.download = answersData?.idea ? `${answersData?.idea}-Problem-Solution.pdf` : "Problem-Solution-Report.pdf";
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
			URL.revokeObjectURL(url);

			resolve();
		} catch (error) {
			console.error(error);
			reject(error);
		}
	});
};

const ProblemSolutionExport = forwardRef(({ problemSolutionList, answersData }, ref) => {
	const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

	useImperativeHandle(ref, () => ({
		async handleExport(type) {
			const element = document.getElementById("problemSolution");
			const canvas = await html2canvas(element, {
				useCORS: true,
				proxy: "http://d3rm35snnwgfg3.cloudfront.net",
				logging: true,
				onclone: (clonedDoc) => {
					clonedDoc.getElementById("problemSolution").style.display = "block";
					clonedDoc.getElementById("problemSolution").style.maxHeight = "inherit";
				},
			});
			const data = canvas.toDataURL("image/png");
			if (type === "png") {
				const link = document.createElement("a");
				link.href = data;
				link.download = "Problem Solution.png";
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			} else {
				var imgWidth = 205;

				var pageHeight = 450;

				var imgHeight = (canvas.height * imgWidth) / canvas.width;

				var heightLeft = imgHeight;

				var doc = new jsPDF("p", "mm", "", false);

				var position = 0;

				pageHeight = doc.internal.pageSize.getHeight();

				doc.addImage(data, "PNG", 0, position, imgWidth, imgHeight);

				heightLeft -= pageHeight;

				while (heightLeft >= 0) {
					position = heightLeft - imgHeight;

					doc.addPage();

					doc.addImage(data, "PNG", 0, position, imgWidth, imgHeight);

					heightLeft -= pageHeight;
				}

				doc.save("Problem Solution.pdf");
			}
		},
	}));

	return (
		<Box
			ref={ref}
			id="problemSolution"
			sx={{
				display: "none",
				maxHeight: 0,
				p: 5,
			}}
		>
			<FormSectionHeader heading="PROBLEM SOLUTION" />
			<Divider
				sx={{
					background: (theme) => theme.palette.secondary.dark,
					[theme.breakpoints.down("md")]: {
						background: (theme) => theme.palette.secondary.borderLight,
					},
				}}
			/>
			<Box flexDirection={"column"}>
				<Box sx={{ mt: 2 }}>
					<PowerupExcerciseTitleBox
						iconUrl={TUNGSTEN_ICON}
						title={answersData.idea}
						subTitle={"Your Idea"}
						iconBackgroundColor={sosPrimary[500]}
						contentBackgroundColor={isSmallScreen ? "secondary.backgroundGradient" : "secondary.white"}
						contentTextColor={sosPrimary[500]}
					/>
				</Box>
				<Divider
					sx={{
						background: "rgba(124, 117, 127, 0.16)",
						mt: 1,
					}}
				/>
				<Box sx={{ mt: 2 }}>
					<Grid container>
						<Grid item sm={4} xs={4} md={2} lg={2}>
							<Typography variant="label_medium">Type</Typography>
						</Grid>
						<Grid item sm={8} xs={8} md={10} lg={10}>
							<Typography
								sx={(theme) => ({
									border: `1px solid ${sosPrimary[500]}`,
									borderRadius: theme.shape.standard25.borderRadius,
									p: "2px 8px",
								})}
								variant="label_medium"
								color={sosPrimary[500]}
							>
								{answersData.type}
							</Typography>
						</Grid>
						<Grid item sm={4} xs={4} md={2} lg={2} sx={{ mt: 2 }}>
							<Typography variant="label_medium">Category</Typography>
						</Grid>
						<Grid item sm={8} xs={8} md={10} lg={10} sx={{ mt: 2 }}>
							<Typography
								sx={(theme) => ({
									border: `1px solid ${sosPrimary[500]}`,
									borderRadius: theme.shape.standard25.borderRadius,
									p: "2px 8px",
								})}
								variant="label_medium"
								color={sosPrimary[500]}
							>
								{answersData.category}
							</Typography>
						</Grid>
					</Grid>
				</Box>
				<Divider
					sx={{
						background: "rgba(124, 117, 127, 0.16)",
						mt: 3,
					}}
				/>
				<Box sx={{ mt: 3 }}>
					<Typography variant="label_large" color={sosPrimary[500]}>
						Your Customer
					</Typography>
					<Box sx={{ mt: 1 }}>
						<Typography variant="bodyMedium">{answersData.customer.join(", ")}</Typography>
					</Box>
				</Box>
				<Divider
					sx={{
						background: "rgba(124, 117, 127, 0.16)",
						mt: 3,
					}}
				/>
				<Box sx={{ mt: 3 }}>
					<Typography variant="label_large" color={sosPrimary[500]}>
						Problem/Solution
					</Typography>
					<Box>
						{problemSolutionList.map((item) => {
							return (
								<Box sx={{ mt: 3 }}>
									<ProblemSolutionSummaryBox problemSolutionObj={item} />
									<Divider
										sx={{
											background: theme.palette.text.primary,
											mt: 3,
											display: {
												xs: "block",
												sm: "block",
												md: "none",
												lg: "none",
											},
										}}
									/>
								</Box>
							);
						})}
					</Box>
				</Box>
				<Box sx={{ pt: 2 }}>
					<Typography variant="onsurface_subtext_primary">Insight Results</Typography>
					<Box sx={{ mt: 3 }}>
						<GoogleTrendsContainer category={answersData.categoryId} keywords={answersData.topicTags} />
					</Box>
				</Box>
			</Box>
		</Box>
	);
});

export default ProblemSolutionExport;
