import React from "react";
import { Rating } from "@mui/material";
import StarBorderRoundedIcon from "@mui/icons-material/StarBorderRounded";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import PropTypes from "prop-types";

const RatingComponent = ({
  onChange,
  rating,
  isReadOnly,
  iconColor,
  onChangeActive,
  getLabelText,
  size,
  precision,
  starPadding,
}) => {
  return (
    <Rating
      value={rating}
      icon={
        <StarRoundedIcon
          sx={{
            color: iconColor ? iconColor : "",
            px: starPadding ? starPadding : "",
          }}
        />
      }
      emptyIcon={
        <StarBorderRoundedIcon
          sx={{
            color: iconColor ? iconColor : "",
            px: starPadding ? starPadding : "",
          }}
        />
      }
      readOnly={isReadOnly}
      onChange={onChange}
      onChangeActive={onChangeActive}
      getLabelText={getLabelText}
      size={size}
      precision={precision || 1}
    />
  );
};

{
  RatingComponent.propTypes = {
    isReadOnly: PropTypes.bool,
    onChange: PropTypes.func,
    index: PropTypes.number,
    iconColor: PropTypes.string,
  };

  RatingComponent.defaultProps = {
    isReadOnly: true,
    onChange: null,
    iconColor: null,
  };
}

export default RatingComponent;
