import React, { useEffect, useState } from "react";
import { Grid, Button, Box, Stack, Typography, TextField } from "@mui/material";
import FileUploadButton from "../../common/FileUploadButton";
import services from "../../services";
import { REGEX } from "../../constants/regex";
import messages from "../../constants/messages";
import { toastContainer } from "../../utils/toast";
import LoadingButton from "../../common/Loadingbutton";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setAuthData } from "../../modules/actions/authActions";
import ROUTES_PATH from "../../constants/routes";

const TellUsMore = () => {
  const [imgLoading, setImgLoading] = useState(false);
  const [browseFlag, setBrowseFlag] = useState(false);
  const [saveDisable, setSaveDisable] = useState(true);
  const userData = useSelector(store => store?.auth?.tellUsMoreData);
  const history = useHistory();
  const [payload, setPayload] = useState({
    firstName: userData?.firstName ? userData?.firstName : "",
    lastName: userData?.lastName ? userData?.lastName : "",
    title: userData?.title ? userData?.title : "",
  });
  const [fieldError, setFieldError] = useState({
    firstNameError: "",
    lastNameError: "",
    titleError: "",
  });
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      !payload[`firstName`] ||
      payload[`firstName`] === "" ||
      !payload[`lastName`] ||
      payload[`lastName`] === "" ||
      !payload[`title`] ||
      payload[`title`] === ""
    ) {
      setSaveDisable(true);
    } else {
      setSaveDisable(false);
    }
  }, [payload]);

  const handleChange = (e, key) => {
    if (key === "picture") {
      const data = { ...payload };
      data[key] = e;
      setPayload(data);
    } else {
      const data = { ...payload };
      data[key] = e.target.value;
      setPayload(data);
    }
  };

  const getFiles = async (data, type) => {
    if (
      data.type == "image/png" ||
      data.type == "image/jpeg" ||
      data.type == "image/jpg"
    ) {
      setImgLoading(true);

      let formData = new FormData();
      formData.append("file", data);
      formData.append("fileGroup", "PROFILE");

      setImgLoading(true);
      services
        .uploadFile(formData)
        .then(response => {
          setImgLoading(false);
          setBrowseFlag(true);
          if (type == "img") {
            handleChange(response.data.data.fileLocation, "picture");
          }
        })
        .catch(e => {
          console.log("File upload Error", e);
          setImgLoading(false);
          if (type == "img") {
          }
        });
    } else {
      toastContainer(`Invalid file type.`, "error");
    }
  };

  const handleFileChange = type => event => {
    const file = event.target.files[0];

    if (file === undefined) {
      getFiles("", "");
    } else {
      getFiles(file, type);
    }
  };

  const handleBlur = (e, key) => {
    let pass;

    switch (key) {
      case "firstName":
        if (!e.target.value || e.target.value == "") {
          setFieldError(prevState => ({
            ...prevState,
            firstNameError: messages.COMPANY_NAME_LENGTH_INVALID,
          }));
          pass = false;
        } else if (!e.target.value.match(REGEX.COMPANY_NAME)) {
          setFieldError(prevState => ({
            ...prevState,
            firstNameError: messages.COMPANY_NAME_LENGTH_INVALID,
          }));
          pass = false;
        } else {
          setFieldError(prevState => ({
            ...prevState,
            firstNameError: "",
          }));
          pass = true;
        }
        break;

      case "lastName":
        if (!e.target.value || e.target.value == "") {
          setFieldError(prevState => ({
            ...prevState,
            lastNameError: messages.COMPANY_NAME_LENGTH_INVALID,
          }));
          pass = false;
        } else if (!e.target.value.match(REGEX.COMPANY_NAME)) {
          setFieldError(prevState => ({
            ...prevState,
            lastNameError: messages.COMPANY_NAME_LENGTH_INVALID,
          }));
          pass = false;
        } else {
          setFieldError(prevState => ({
            ...prevState,
            lastNameError: "",
          }));
          pass = true;
        }
        break;
      case "title":
        if (!e.target.value || e.target.value == "") {
          setFieldError(prevState => ({
            ...prevState,
            titleError: messages.TITLE_LENGTH_INVALID,
          }));
          pass = false;
        } else if (!e.target.value.match(REGEX.LOCATION)) {
          setFieldError(prevState => ({
            ...prevState,
            titleError: messages.TITLE_LENGTH_INVALID,
          }));
          pass = false;
        } else {
          setFieldError(prevState => ({
            ...prevState,
            titleError: "",
          }));
          pass = true;
        }
        break;

      default:
        break;
    }

    if (pass === false) {
      setSaveDisable(true);
    }
  };

  const handleSaveData = () => {
    dispatch(setAuthData({ tellUsMoreData: payload }));
    history.push(ROUTES_PATH.CREATE_PASSWORD);
  };

  return (
    <Stack alignItems="center" justifyContent="center">
      <Grid container alignItems="center" justifyContent="center">
        <Grid item xs={12} sm={10} md={6} lg={6} sx={{ px: 2 }}>
          <Box display="grid" justifyContent="left">
            <Typography variant="header3">Tell us more about you</Typography>
          </Box>
          <Box display="grid" sx={{ textAlign: "left" }}>
            <Typography variant="label_xlarge" sx={{ mt: 4 }}>
              Profile Image - Optional
            </Typography>
          </Box>
          <Box sx={{ mt: 2 }}>
            <FileUploadButton
              imgLoading={imgLoading}
              browseFlag={browseFlag}
              type={"img"}
              handleFileChange={handleFileChange}
            />
          </Box>
          <Box>
            <TextField
              label="First Name"
              spellCheck="true"
              sx={{ mt: 4, width: 1 }}
              onChange={(e, type) => {
                handleChange(e, "firstName");
              }}
              error={!!fieldError.firstNameError}
              helperText={fieldError.firstNameError}
              value={payload["firstName"]}
              onBlur={e => {
                handleBlur(e, "firstName");
              }}
            />
            <TextField
              label="Last Name"
              sx={{ mt: 4, width: 1 }}
              onChange={e => {
                handleChange(e, "lastName");
              }}
              spellCheck="true"
              error={!!fieldError.lastNameError}
              helperText={fieldError.lastNameError}
              value={payload["lastName"]}
              onBlur={e => {
                handleBlur(e, "lastName");
              }}
            />

            <TextField
              onChange={e => {
                handleChange(e, "title");
              }}
              spellCheck="true"
              error={!!fieldError.titleError}
              helperText={fieldError.titleError}
              label="Title"
              sx={{ mt: 4, width: 1 }}
              value={payload["title"]}
              onBlur={e => {
                handleBlur(e, "title");
              }}
            />
          </Box>
          <Box display="grid" align="center">
            <LoadingButton
              sx={{ my: 4, maxWidth: 1 }}
              disabled={saveDisable}
              loadingStyles={{ px: 7 }}
              onClick={handleSaveData}
            >
              Continue
            </LoadingButton>

            <Button
              variant="nofill"
              sx={{ maxWidth: 1 }}
              onClick={() => history.push(ROUTES_PATH.CREATE_PASSWORD)}
            >
              Set up later
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default TellUsMore;
