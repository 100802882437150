import React from "react";
import { Box, Grid, Typography, Divider } from "@mui/material";
import { PROFILE_PLACEHOLDER_PNG } from "../../../constants/image";
import theme from "../../../constants/Theme";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";

export default function ProfileHeader({
  setShowEdit,
  candidateData,
  compLocation,
}) {
  return (
    <>
      <Grid
        container
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        <Grid item xs={12} md={12}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: 1,
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <img
              src={
                candidateData?.picture
                  ? candidateData?.picture
                  : PROFILE_PLACEHOLDER_PNG
              }
              height="56"
              width="56"
              style={{
                borderRadius: "50%",
                paddingTop: 4,
                objectFit: "cover",
              }}
            />
            <ModeEditOutlineOutlinedIcon
              sx={{ cursor: "pointer", fill: theme.palette.secondary.main }}
              onClick={() => setShowEdit(true)}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={12}>
          <Typography variant="largeTitle">
            {candidateData?.info?.firstName && candidateData?.info?.lastName
              ? `${candidateData?.info?.firstName} ${candidateData?.info?.lastName}`
              : "Name"}
          </Typography>
        </Grid>
        <Grid item xs={12} md={12}>
          <Typography
            variant="subhead1"
            sx={{ color: theme.palette.secondary.main }}
          >
            {candidateData?.email ? candidateData?.email : "abc@email.com"}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          sx={{ display: "flex", flexDirection: "row" }}
        >
          <Typography
            variant="onsurface_subtext"
            sx={{ color: theme.palette.secondary.dark }}
          >
            {candidateData?.info?.profession
              ? candidateData?.info?.profession
              : "Title"}
            <FiberManualRecordIcon
              fontSize="small"
              sx={{
                maxHeight: "8px",
                fill: theme.palette.secondary.dark,
                alignSelf: "center",
              }}
            />
          </Typography>

          <Typography
            variant="onsurface_subtext"
            sx={{ color: theme.palette.secondary.dark }}
          >
            {compLocation ? compLocation : "City, State"}
          </Typography>
        </Grid>

        <Divider sx={{ py: 1 }} />
      </Grid>
    </>
  );
}
