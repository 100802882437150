import React, { useState, useEffect } from "react";
import {
  Typography,
  Divider,
  Box,
  Button,
  CircularProgress,
} from "@mui/material";
import { getTeamMembersDetails } from "./functions";
import theme from "../../../constants/Theme";
import { toastContainer } from "../../../utils/toast";
import messages from "../../../constants/messages";
import AddIcon from "@mui/icons-material/Add";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { InviteTeamCard } from "../../../components/MyStartup/InviteTeamCard";
import InviteTeam from "../../../components/MyStartup/InviteTeam";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { useSelector } from "react-redux";
import { USER_TYPES } from "../../../utils/userTypes";

const TeamContainer = props => {
  const [details, setDetails] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [isLast, setIsLast] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showInviteTeamPopup, setShowInviteTeamPopup] = useState(false);
  const [noOfelements, setNoOfelements] = useState(0);
  const userType = useSelector(store => store?.auth?.userType);

  useEffect(() => {
    handleGetDetails();
  }, [page]);

  const handleGetDetails = () => {
    if (
      userType === USER_TYPES.STARTUP_OWNER ||
      userType === USER_TYPES.STARTUP_USER ||
      userType === USER_TYPES.STUDENT_USER
    ) {
      getTeamMembersDetails(page).then(res => {
        if (res?.status === 200) {
          if (page === 1) {
            setDetails(res.data.data.models);
          } else {
            setDetails([...details, ...res.data.data.models]);
          }
          setNoOfelements(res.data.data.totalElements);
          setTotalPages(res.data.data.totalPages);
          setIsLast(res.data.data.isLast);
          setLoading(false);
        } else {
          toastContainer(messages.API_SUBMIT_ERROR, "error");
          setLoading(false);
        }
      });
    } else {
      setLoading(false);
    }
  };

  const callNextPage = () => {
    if (details?.length < 5) {
      return;
    }
    setPage(page => page + 1);
  };

  const callFirstPage = () => {
    setPage(1);
  };

  return (
    <Box sx={{ pl: { md: 1, lg: 1 }, pr: { md: 2, xs: 1 } }}>
      <Box>
        <Divider sx={{ borderColor: "secondary.light" }} />
      </Box>
      <Box sx={{ display: "flex", pb: 3, pt: 2 }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography
            variant="largeTitle"
            sx={theme => ({
              color: theme.palette.secondary.korophKoal,
            })}
          >
            Team
          </Typography>
        </Box>
        <Box sx={{ ml: "auto" }}>
          <Button
            variant="nofill"
            startIcon={<AddIcon />}
            sx={{ width: "max-content" }}
            onClick={() => setShowInviteTeamPopup(true)}
          >
            Invite Team
          </Button>
        </Box>
      </Box>
      {loading ? (
        <Box
          sx={{ display: "flex", width: 1, justifyContent: "center", py: 4 }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          {details?.length > 0 ? (
            <>
              <InviteTeamCard
                details={details}
                loading={loading}
                setLoading={setLoading}
              />
              {noOfelements > 5 ? (
                <Box>
                  {isLast && details?.length > 5 ? (
                    <Button
                      variant="nofill"
                      startIcon={<ExpandLessIcon />}
                      sx={{ width: "max-content", display: "flex" }}
                      onClick={callFirstPage}
                    >
                      Fewer
                    </Button>
                  ) : (
                    <Button
                      variant="nofill"
                      startIcon={<ExpandMoreIcon />}
                      sx={{ width: "max-content", display: "flex" }}
                      onClick={callNextPage}
                    >
                      More
                    </Button>
                  )}
                </Box>
              ) : (
                ""
              )}
            </>
          ) : (
            <Box
              sx={{
                display: "flex",
                width: 1,
                justifyContent: "center",
                alignContent: "center",
                py: 5,
              }}
            >
              <Typography
                color="primary"
                variant="h5"
                sx={{ color: theme.palette.secondary.dark }}
              >
                Invite your team to start collaborating.
              </Typography>
            </Box>
          )}
        </>
      )}
      <InviteTeam
        showInviteTeamPopup={showInviteTeamPopup}
        setShowInviteTeamPopup={setShowInviteTeamPopup}
      />
    </Box>
  );
};

export default TeamContainer;
