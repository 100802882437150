import React, { useEffect, useState } from "react";
import { generatePath, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import _, { debounce } from "lodash";
import { Box, Typography, useMediaQuery, Grid, CircularProgress } from "@mui/material";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import ImportContactsOutlinedIcon from "@mui/icons-material/ImportContactsOutlined";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";

import * as actions from "../../../../modules/actions";
import services from "../../../../services";
import ROUTES_PATH from "../../../../constants/routes";
import theme from "../../../../constants/Theme";
import FilterField from "../../../../components/Investor/Workspace/View/FilterField";
import FilterDropdown from "../../../../components/Investor/Workspace/View/FilterDropdown";
import WorkspaceHeader from "../../../../components/Investor/Workspace/View/Header";
import WorkspaceGroupCard from "../../../../components/Investor/Workspace/View/WorkspaceGroupCard";
import WorkspaceHistoryCard from "../../../../components/Investor/Workspace/View/WorkspaceHistoryCard";
import ColumnControl from "../../../../components/ScoreBenchmarkDetails/Components/ScoreInsights/ColumnControl";

const MOCK_POWERUP_RESULT = [
	{ id: 1, title: "PowerUps", value: 0, icon: ImportContactsOutlinedIcon },
	{ id: 2, title: "Surveys", value: 0, icon: AssignmentOutlinedIcon },
	{ id: 3, title: "Interviews", value: 0, icon: PeopleAltIcon },
];

export default function WorkspaceWorkHistory() {
	const dispatch = useDispatch();
	const history = useHistory();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
	const [selectedItem, setSelectedItem] = useState(0); //this filter removed
	const [searchText, setSearchText] = useState("");
	const [filterOptions, setFilterOptions] = useState([]);
	const [currentFilterValue, setCurrentFilterValue] = useState(0);
	const [powerUps, setPowerUps] = useState([]);
	const [result, setResult] = useState(MOCK_POWERUP_RESULT);
	// For Navigation
	const [moduleId, setModuleId] = useState(null);
	const [powerupName, setPowerupName] = useState(null);
	const [itemId, setItemId] = useState(null);
	const [pUDetails, setPuDetails] = useState(null);
	const [moduleDetails, setModuleDetails] = useState(null);
	const [openSummary, setOpenSummary] = useState(false);
	const [workHistoryData, setWorkHistoryData] = useState(null);
	const workHistoryTasks = useSelector((history) => history?.powerUp?.workHistoryTaskData);
	const [pageLoader, setPageLoader] = useState(false);

	useEffect(() => {
		getSteps();
		getWorkHistoryApiCall(currentFilterValue === 0 ? null : currentFilterValue);
	}, []);

	const getWorkHistoryApiCall = async (packId) => {
		setPageLoader(true);

		const response = await services.getWorkHistoryTasksApi(packId);
		if (response) {
			setPageLoader(false);

			const responseData = response?.data?.data;
			const powerUpData = responseData.filter((item) => item.type === "POWER_UP" || item.type === "POWER_UP_BUILDER");

			dispatch(
				actions.setWorkHistoryTaskDetails({
					workHistoryData: responseData,
					powerUpData: powerUpData,
					serveyData: responseData.filter((item) => item.type === "SURVEY"),
					interviewData: responseData.filter((item) => item.type === "MAKET_TEST"),
				})
			);
			result[0].value = responseData.filter((item) => item.type === "POWER_UP" || item.type === "POWER_UP_BUILDER").length || 0;
			result[1].value = responseData.filter((item) => item.type === "SURVEY").length || 0;
			result[2].value = responseData.filter((item) => item.type === "MAKET_TEST").length || 0;
			setResult(result);
			setPowerUps(responseData.filter((item) => item.type === "POWER_UP" || item.type === "POWER_UP_BUILDER"));
		}
	};

	const getSteps = async () => {
		try {
			const powerupsPacks = await services.getPowerUpPacksList();
			const powerupPacksData = powerupsPacks?.data?.data;
			const tempOptions = [
				{
					value: 0,
					name: "All",
				},
			];
			powerupPacksData?.packs.forEach((pack) => {
				tempOptions.push({
					value: pack.id,
					name: pack.powerUpPackName,
				});
			});
			setFilterOptions(tempOptions);
		} catch (error) {
			console.log(error);
		}
	};

	const handleFilter = async (packId) => {
		setCurrentFilterValue(packId);
		getWorkHistoryApiCall(packId === 0 ? null : packId);
	};

	const handleSelectedItem = (index) => {
		setSelectedItem(index);
	};

	useEffect(() => {
		const powerUpData = workHistoryTasks?.workHistoryData?.filter((item) => item.type === "POWER_UP" || item.type === "POWER_UP_BUILDER");
		const serveyData = workHistoryTasks?.workHistoryData?.filter((item) => item.type === "SURVEY");
		const interviewData = workHistoryTasks?.workHistoryData?.filter((item) => item.type === "MAKET_TEST");

		if (searchText !== "") {
			const filteredPowerUpData = powerUpData?.filter((powerUp) => powerUp.name?.toLowerCase().includes(searchText?.toLowerCase()));
			const filteredServeyData = serveyData?.filter((powerUp) => powerUp.name?.toLowerCase().includes(searchText?.toLowerCase()));
			const filteredInterviewData = interviewData?.filter((powerUp) => powerUp.name?.toLowerCase().includes(searchText?.toLowerCase()));

			dispatch(
				actions.setWorkHistoryTaskDetails({
					...workHistoryTasks,
					powerUpData: filteredPowerUpData,
					serveyData: filteredServeyData,
					interviewData: filteredInterviewData,
				})
			);
		} else {
			dispatch(
				actions.setWorkHistoryTaskDetails({
					...workHistoryTasks,
					powerUpData: powerUpData,
					serveyData: serveyData,
					interviewData: interviewData,
				})
			);
		}

		// if (selectedItem === 1) {
		// 	if (searchText !== "") {
		// 		const filteredPowerUpData = workHistoryTasks?.powerUpData?.filter((powerUp) => powerUp.name?.toLowerCase().includes(searchText?.toLowerCase()));

		// 		dispatch(
		// 			actions.setWorkHistoryTaskDetails({
		// 				...workHistoryTasks,
		// 				powerUpData: filteredPowerUpData,
		// 			})
		// 		);
		// 	} else {
		// 		const powerUpData = workHistoryTasks?.workHistoryData?.filter((item) => item.type === "POWER_UP" || item.type === "POWER_UP_BUILDER");

		// 		dispatch(
		// 			actions.setWorkHistoryTaskDetails({
		// 				...workHistoryTasks,
		// 				powerUpData: powerUpData,
		// 			})
		// 		);
		// 	}
		// } else if (selectedItem === 2) {
		// 	if (searchText !== "") {
		// 		const filteredPowerUpData = workHistoryTasks?.serveyData?.filter((powerUp) => powerUp.name?.toLowerCase().includes(searchText?.toLowerCase()));

		// 		dispatch(
		// 			actions.setWorkHistoryTaskDetails({
		// 				...workHistoryTasks,
		// 				serveyData: filteredPowerUpData,
		// 			})
		// 		);
		// 	} else {
		// 		const serveyData = workHistoryTasks?.workHistoryData?.filter((item) => item.type === "SURVEY");

		// 		dispatch(
		// 			actions.setWorkHistoryTaskDetails({
		// 				...workHistoryTasks,
		// 				serveyData: serveyData,
		// 			})
		// 		);
		// 	}
		// } else if (selectedItem === 3) {
		// 	if (searchText !== "") {
		// 		const filteredPowerUpData = workHistoryTasks?.interviewData?.filter((powerUp) =>
		// 			powerUp.name?.toLowerCase().includes(searchText?.toLowerCase())
		// 		);

		// 		dispatch(
		// 			actions.setWorkHistoryTaskDetails({
		// 				...workHistoryTasks,
		// 				interviewData: filteredPowerUpData,
		// 			})
		// 		);
		// 	} else {
		// 		const interviewData = workHistoryTasks?.workHistoryData?.filter((item) => item.type === "MAKET_TEST");

		// 		dispatch(
		// 			actions.setWorkHistoryTaskDetails({
		// 				...workHistoryTasks,
		// 				interviewData: interviewData,
		// 			})
		// 		);
		// 	}
		// } else {
		// 	return [];
		// }
	}, [powerUps, selectedItem, searchText]);

	const debounceChange = debounce((e) => {
		setSearchText(e?.target?.value || "");
	}, 200);

	const getCompanyListApiCall = (searchText, companiesPageIndex, sort = "DESC", filter = "currentScore", companyFilterObj) => {};

	const getSummaryDetails = (id, moduleId) => {
		services
			.getModuleStepResponses(id)
			.then((result) => {
				if (result?.status === 200) {
					setModuleDetails(result?.data?.data?.find((obj) => obj?.id == moduleId)?.moduleStepResponses);
					setOpenSummary(true);
				}
			})
			.catch((err) => console.log(err));
	};

	const handleRedirectior = (subpath, powerUpId, status, item, name) => {
		setPuDetails(item);

		dispatch(actions.setWorkProfile(item));
		if (item?.type === "POWER_UP" || item?.type === "POWER_UP_BUILDER") {
			item["module"] = item?.moduleModel;
			if (item?.type === "POWER_UP_BUILDER") {
				history.push(`/partner/powerup/${powerUpId}/preview`);
			} else {
				if (status) {
					if (isSmallScreen) {
						if (subpath === "MVP Prioritizer") {
							history.push({
								pathname: "/create-matrix",
								state: {
									started: "COMPLETED",
								},
							});
						} else {
							getSummaryDetails(item?.module?.id, item?.id);
							setModuleId(item?.module?.id);
							setPowerupName(subpath);
							setItemId(item?.id);
						}
					} else {
						if (subpath === "MVP Prioritizer") {
							history.push({
								pathname: "/create-matrix",
								state: {
									started: "COMPLETED",
								},
							});
						} else {
							history.push({
								pathname: `/workspace/mywork/${item?.module?.id}/summary`,

								state: {
									powerup: subpath,
									name: name,
									moduleId: item?.id,
									powerupId: powerUpId,
								},
							});
						}
					}
				} else {
					if (subpath === "Persona Builder") {
						history.push({
							pathname: generatePath(ROUTES_PATH.WORKSPACE_POWERUP_DETAILS, {
								id: 3,
							}),
						});
						dispatch(
							actions.setPowerupHeaderDetails({
								currentModuleResponseId: item?.id,
							})
						);
					} else if (subpath === "MVP Prioritizer") {
						history.push({
							pathname: "/create-matrix",
							state: {
								started: "STARTED",
							},
						});
					} else {
						history.push(`/workspace/powerup/${item?.module?.moduleUuid}/${item?.module?.id}/${item?.id}/start`);
					}
				}
			}
		} else if (item?.type === "SURVEY") {
			history.push(`/survey/${item?.uuid}`);
		} else if (item?.type === "MAKET_TEST") {
			history.push(ROUTES_PATH.MARKET_TEST);
		}
	};

	return (
		<Box
			sx={(theme) => ({
				marginBottom: "100px",
			})}
		>
			<WorkspaceHeader id={4} />

			<Box
				sx={(theme) => ({
					paddingRight: "0px",
					[theme.breakpoints.up("md")]: {
						// paddingRight: "60px",
					},
				})}
			>
				<Grid container justifyContent="space-between" alignItems="flex-end" rowGap={{ xs: 4, xl: 1 }} sx={{ marginTop: "52px" }}>
					{/* <Grid container xs={12} md={12} xl={6} rowGap={{ xs: 3 }} spacing={{ md: 2, xl: 3 }}>
						{result.map((item) => (
							<Grid item xs={12} md={4}>
								<WorkspaceGroupCard
									id={item.id}
									value={item.value}
									title={item.title}
									Icon={item.icon}
									selectedItem={selectedItem}
									handleSelectedItem={handleSelectedItem}
								/>
							</Grid>
						))}
					</Grid> */}
					<Grid container xs={12} md={12} xl={6} spacing={2}>
						<Grid item xs={12} md={8}>
							<FilterField placeholder={"Search by Name"} debounceChange={debounceChange} />
						</Grid>
						{/* <Grid item xs={12} md={6}>
							<FilterDropdown options={filterOptions} value={currentFilterValue} onChange={handleFilter} />
						</Grid> */}
					</Grid>
				</Grid>
				<Box my="30px"></Box>
				<ColumnControl
					minWidth={1000}
					wrapperSX={{ afterStyle: { columnCount: 1 }, beforeStyle: { columnCount: 2 }, style: { columnGap: "36px" } }}
					itemSX={{ style: { mb: "36px", breakInside: "avoid-column" } }}
				>
					{[
						...(workHistoryTasks && workHistoryTasks?.powerUpData
							? workHistoryTasks?.powerUpData?.map((powerUp, index) => (
									<WorkspaceHistoryCard
										powerUpDetails={powerUp}
										key={`${powerUp?.type}${powerUp?.id}-${index}`}
										powerUpId={powerUp.id}
										title={powerUp.name}
										sort={""}
										filter={""}
										handleRedirectior={handleRedirectior}
									/>
							  ))
							: []),
						...(workHistoryTasks && workHistoryTasks?.serveyData
							? workHistoryTasks?.serveyData?.map((powerUp, index) => (
									<WorkspaceHistoryCard
										powerUpDetails={powerUp}
										key={`${powerUp?.type}${powerUp?.id}-${index}`}
										powerUpId={powerUp.id}
										title={powerUp.name}
										sort={""}
										filter={""}
										handleRedirectior={handleRedirectior}
									/>
							  ))
							: []),
						...(workHistoryTasks && workHistoryTasks?.interviewData
							? workHistoryTasks?.interviewData?.map((powerUp, index) => (
									<WorkspaceHistoryCard
										powerUpDetails={powerUp}
										key={`${powerUp?.type}${powerUp?.id}-${index}`}
										powerUpId={powerUp.id}
										title={powerUp.name}
										sort={""}
										filter={""}
										handleRedirectior={handleRedirectior}
									/>
							  ))
							: []),
					]}
				</ColumnControl>

				{/* {selectedItem === 1 && (
					<>
						<Box display="flex" justifyContent="flex-end" my="30px">
							{workHistoryTasks?.powerUpData?.length > 0 && (
								<Typography
									variant="card_text"
									sx={{ fontWeight: 500 }}
								>{`1 - ${workHistoryTasks?.powerUpData?.length} of ${workHistoryTasks?.powerUpData?.length}`}</Typography>
							)}
						</Box>

						<ColumnControl
							minWidth={1000}
							wrapperSX={{ afterStyle: { columnCount: 1 }, beforeStyle: { columnCount: 2 }, style: { columnGap: "36px" } }}
							itemSX={{ style: { mb: "36px", breakInside: "avoid-column" } }}
						>
							{workHistoryTasks &&
								workHistoryTasks?.powerUpData?.map((powerUp) => (
									<WorkspaceHistoryCard
										powerUpDetails={powerUp}
										key={powerUp.id}
										powerUpId={powerUp.id}
										title={powerUp.name}
										sort={""}
										filter={""}
										handleRedirectior={handleRedirectior}
									/>
								))}
						</ColumnControl> */}

				{/*<Box
							sx={(theme) => ({
								display: "grid",
								gridGap: "35px",
								gridTemplateColumns: "1fr",
								[theme.breakpoints.up("lg")]: {
									gridTemplateColumns: "repeat(2, 1fr)",
								},
							})}
						>
							
						</Box>*/}
				{/* </>
				)}
				{selectedItem === 2 && (
					<>
						<Box display="flex" justifyContent="flex-end" my="30px">
							{workHistoryTasks?.serveyData?.length > 0 && (
								<Typography
									variant="card_text"
									sx={{ fontWeight: 500 }}
								>{`1 - ${workHistoryTasks?.serveyData?.length} of ${workHistoryTasks?.serveyData?.length}`}</Typography>
							)}
						</Box>
						<ColumnControl
							minWidth={1000}
							wrapperSX={{ afterStyle: { columnCount: 1 }, beforeStyle: { columnCount: 2 }, style: { columnGap: "36px" } }}
							itemSX={{ style: { mb: "36px", breakInside: "avoid-column" } }}
						>
							{workHistoryTasks &&
								workHistoryTasks?.serveyData?.map((powerUp) => (
									<WorkspaceHistoryCard
										powerUpDetails={powerUp}
										key={powerUp.id}
										powerUpId={powerUp.id}
										title={powerUp.name}
										sort={""}
										filter={""}
										handleRedirectior={handleRedirectior}
									/>
								))}
						</ColumnControl>
					</>
				)}
				{selectedItem === 3 && (
					<>
						<Box display="flex" justifyContent="flex-end" my="30px">
							{workHistoryTasks?.interviewData?.length > 0 && (
								<Typography
									variant="card_text"
									sx={{ fontWeight: 500 }}
								>{`1 - ${workHistoryTasks?.serveyData?.length} of ${workHistoryTasks?.serveyData?.length}`}</Typography>
							)}
						</Box>
						<ColumnControl
							minWidth={1000}
							wrapperSX={{ afterStyle: { columnCount: 1 }, beforeStyle: { columnCount: 2 }, style: { columnGap: "36px" } }}
							itemSX={{ style: { mb: "36px", breakInside: "avoid-column" } }}
						>
							{workHistoryTasks &&
								workHistoryTasks?.interviewData?.map((powerUp) => (
									<WorkspaceHistoryCard
										powerUpDetails={powerUp}
										key={powerUp.id}
										powerUpId={powerUp.id}
										title={powerUp.name}
										sort={""}
										filter={""}
										handleRedirectior={handleRedirectior}
									/>
								))}
						</ColumnControl>
					</>
				)} */}
			</Box>
		</Box>
	);
}
