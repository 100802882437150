import { useState, useEffect, useMemo, useRef } from "react";
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Client } from "@twilio/conversations";
import { Box, Divider, useMediaQuery } from "@mui/material";
import { Spinner } from "@twilio-paste/core";

import services from "../../../../services";
import PaymentPlanDialog from "../../../../components/PaymentPlanDialog";
import { actionCreators } from "../../../Messaging/store";
import { getConversationParticipants, getToken } from "../../../Messaging/api";
import useAppAlert from "../../../Messaging/hooks/useAppAlerts";
import { handlePromiseRejection } from "../../../Messaging/helpers";
import stylesheet from "../styles";
import ConversationContainer from "./conversations/ConversationContainer";
import ConversationsContainer from "./conversations/ConversationsContainer";
import Notifications from "./Notifications";
import NewConversation from "./conversations/NewConversation";
import { USER_TYPES } from "../../../../utils/userTypes";
import ConversationListHeader from "./conversations/ConversationListHeader";

//import {
//  initFcmServiceWorker,
//  subscribeFcmNotifications,
//  showNotification,
//} from "../firebase-support";

async function loadUnreadMessagesCount(convo, updateUnreadMessages) {
	let count = 0;
	try {
		count = (await convo.getUnreadMessagesCount()) ?? (await convo.getMessagesCount());
	} catch (e) {
		console.error("getUnreadMessagesCount threw an error", e);
	}
	updateUnreadMessages(convo.sid, count);
}

async function handleParticipantsUpdate(participant, updateParticipants) {
	const result = await getConversationParticipants(participant.conversation);
	updateParticipants(result, participant.conversation.sid);
}

async function getSubscribedConversations(client) {
	let subscribedConversations = await client.getSubscribedConversations();
	console.log(subscribedConversations);
	let conversations = subscribedConversations.items;
	while (subscribedConversations.hasNextPage) {
		subscribedConversations = await subscribedConversations.nextPage();
		conversations = [...conversations, ...subscribedConversations.items];
	}
	return conversations;
}

const AppContainer = () => {
	/* eslint-disable */
	const token = useSelector((state) => state.token);
	const conversations = useSelector((state) => state.convos);
	const createConversationStatus = useSelector((state) => state.chatState?.createConversationStatus);
	const sid = useSelector((state) => state.sid);
	const sidRef = useRef("");
	const [alertsExist, AlertsView] = useAppAlert();
	sidRef.current = sid;
	//  const username = localStorage.getItem("username");
	//  const password = localStorage.getItem("password");
	const userID = String(useSelector((state) => state?.auth?.userId));
	const userType = useSelector((store) => store?.auth?.userType);
	const customPoint = "820"; //figma
	const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down(customPoint));
	const dispatch = useDispatch();

	const [client, setClient] = useState();
	const [clientReady, setClientReady] = useState(false);
	const [clientState, setClientState] = useState("");
	const isAdmin = useSelector((store) => store?.auth?.isAdmin);
	const isPremiumPlanAccess = useSelector((store) => store?.auth?.isPremiumPlanAccess);
	const [showPlanModal, setShowPlanModal] = useState(false);
	const location = useLocation();

	// useEffect(() => {
	// 	if (isPremiumPlanAccess || isAdmin || userType === USER_TYPES.MENTOR_USER || userType === USER_TYPES.INVESTOR_USER) {
	// 	} else {
	// 		setShowPlanModal(true);
	// 	}
	// }, [isPremiumPlanAccess, isAdmin, userType]);

	const handleClosePlanModal = () => {
		setShowPlanModal(false);
	};

	const {
		addMessages,
		updateLoadingState,
		updateParticipants,
		updateUnreadMessages,
		startTyping,
		endTyping,
		addConversation,
		updateConversation,
		login,
		removeMessages,
		removeConversation,
		updateCurrentConversation,
		addNotifications,
		logout,
		clearAttachments,
		updateCreatingNewConversationState,
		resetChatState,
		sortConversations,
	} = bindActionCreators(actionCreators, dispatch);

	const updateTypingIndicator = (participant, sid, callback) => {
		const {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			attributes: { friendlyName },
			identity,
		} = participant;
		if (identity === String(userID)) {
			return;
		}
		callback(sid, identity || friendlyName || "");
	};

	const enhanceConversationObject = async (conversation, dispatchAction) => {
		let convoMeta = {};
		await services
			.getConversationByConvoIDServiceCall(conversation?.sid)
			.then((resp) => {
				convoMeta = resp?.data?.data;
			})
			.catch((e) => {
				console.log("Error enhancing conversation object.", e);
			})
			.finally(() => {
				switch (dispatchAction) {
					case "add":
						addConversation(conversation, convoMeta);
						break;
					case "update":
						updateConversation(conversation.sid, conversation, convoMeta);
						break;
					default:
						console.log("Could not deduce action to enhance.");
						break;
				}
			});
	};

	useEffect(() => {
		const client = new Client(token);
		setClient(client);
		//const fcmInit = async () => {
		//  await initFcmServiceWorker();
		//  await subscribeFcmNotifications(client);
		//};
		//fcmInit().catch(() => {
		//  console.error(
		//    "FCM initialization failed: no push notifications will be available"
		//  );
		//});

		client.on("stateChanged", (state) => {
			if (state === "failed") {
				setClientReady(false);
				return;
			}
			if (state === "initialized") {
				setClientReady(true);
			}
		});

		client.on("connectionStateChanged", (state) => {
			setClientState(state);
		});

		client.on("conversationJoined", (conversation) => {
			/*
        Takes conversation object from Twilio and then appends StartupOS meta.
        StartupOS meta contains information about users, as well as business logic not 
        passed/included on Twilio. 
      */
			enhanceConversationObject(conversation, "add");
			conversation.on("typingStarted", (participant) => {
				handlePromiseRejection(() => updateTypingIndicator(participant, conversation.sid, startTyping), addNotifications);
			});
			conversation.on("typingEnded", (participant) => {
				handlePromiseRejection(() => updateTypingIndicator(participant, conversation.sid, endTyping), addNotifications);
			});
			handlePromiseRejection(async () => {
				if (conversation.status === "joined") {
					const result = await getConversationParticipants(conversation);
					updateParticipants(result, conversation.sid);
					const messages = await conversation.getMessages();
					addMessages(conversation.sid, messages.items);
					loadUnreadMessagesCount(conversation, updateUnreadMessages);
				}
			}, addNotifications);
		});

		client.on("conversationUpdated", ({ conversation }) => {
			/*
        Takes conversation object from Twilio and then appends StartupOS meta.
        StartupOS meta contains information about users, as well as business logic not 
        passed/included on Twilio. 
      */
			// TODO -- Establish if required? This is fired every time a message is sent/received.
			//enhanceConversationObject(conversation, "update");
			handlePromiseRejection(() => {}, addNotifications);
		});

		client.on("conversationRemoved", (conversation) => {
			updateCurrentConversation("");
			handlePromiseRejection(() => {
				removeConversation(conversation.sid);
				updateParticipants([], conversation.sid);
			}, addNotifications);
		});

		client.on("messageAdded", (message) => {
			sortConversations(message.conversation.sid);
			addMessage(message, addMessages, updateUnreadMessages);
			if (message.author === String(userID)) {
				clearAttachments(message.conversation.sid, "-1");
			}
		});

		client.on("participantLeft", (participant) => {
			handlePromiseRejection(() => handleParticipantsUpdate(participant, updateParticipants), addNotifications);
		});
		client.on("participantUpdated", (event) => {
			handlePromiseRejection(() => handleParticipantsUpdate(event.participant, updateParticipants), addNotifications);
		});
		client.on("participantJoined", (participant) => {
			handlePromiseRejection(() => handleParticipantsUpdate(participant, updateParticipants), addNotifications);
		});

		client.on("messageUpdated", ({ message }) => {
			handlePromiseRejection(() => {}, addNotifications);
		});
		client.on("messageRemoved", (message) => {
			handlePromiseRejection(() => removeMessages(message.conversation.sid, [message]), addNotifications);
		});
		client.on("pushNotification", (event) => {
			// @ts-ignore
			if (event.type != "twilio.conversations.new_message") {
				return;
			}
			if (Notification.permission === "granted") {
				//showNotification(event);
				console.log("Push notification is noOP in app", Notification.permission);
			} else {
				console.log("Push notification is skipped", Notification.permission);
			}
		});
		client.on("tokenAboutToExpire", () => {
			if (userID && userID.length > 0) {
				getToken(userID)
					.then((token) => {
						if (token?.accessToken?.length > 0) login(token.accessToken);
						else throw "accessToken missing from getToken call.";
					})
					.catch((e) => {
						console.log("Something went wrong with messaging login.", e);
					});
			}
		});
		updateLoadingState(false);
		resetChatState();
		getSubscribedConversations(client);

		return () => {
			client?.removeAllListeners();
		};
	}, []);

	function addMessage(message, addMessages, updateUnreadMessages) {
		//transform the message and add it to redux
		handlePromiseRejection(() => {
			if (sidRef.current === message.conversation.sid) {
				message.conversation.updateLastReadMessageIndex(message.index);
			}
			addMessages(message.conversation.sid, [message]);
			loadUnreadMessagesCount(message.conversation, updateUnreadMessages);
		}, addNotifications);
	}

	const openedConversation = useMemo(() => conversations.find((convo) => convo.sid === sid), [sid, conversations]);

	if (clientReady && clientState === "connected") {
		return (
			<Box style={stylesheet.appWrapper}>
				<AlertsView />
				<Notifications />
				<ConversationListHeader client={client} />
				<Box style={stylesheet.appContainer(alertsExist, location.pathname)} sx={{ mt: 3 }}>
					{/* List */}
					{isSmallScreen ? (
						<>{!createConversationStatus && !openedConversation && <ConversationsContainer isSmallScreen client={client} />}</>
					) : (
						<ConversationsContainer client={client} />
					)}

					{isSmallScreen ? (
						<>
							{(createConversationStatus || openedConversation) && (
								<>
									{/* Message Wrap */}
									<Box style={stylesheet.messagesWrapper}>
										{conversations?.length == 0 || createConversationStatus ? (
											<NewConversation client={client} />
										) : (
											<ConversationContainer conversation={openedConversation} client={client} />
										)}
									</Box>
								</>
							)}
						</>
					) : (
						<>
							{/* Message Wrap */}
							<Box style={stylesheet.messagesWrapper}>
								{conversations?.length === 0 || createConversationStatus ? (
									<NewConversation client={client} />
								) : (
									<ConversationContainer conversation={openedConversation} client={client} />
								)}
							</Box>
						</>
					)}
				</Box>

				{showPlanModal && <PaymentPlanDialog open={showPlanModal} onClose={handleClosePlanModal} />}
			</Box>
		);
	} else {
		return (
			<Box style={stylesheet.appWrapper}>
				<Box display="flex" justifyContent="center" alignItems="center" position="absolute" height="100%" width="100%">
					<Spinner size="sizeIcon110" decorative={false} title="Loading" />
				</Box>
			</Box>
		);
	}
};

export default AppContainer;
