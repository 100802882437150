import services from "../../../../../services";
import {
  convertFormattedStringToDate,
  getCurrentDate,
  getCurrentTime,
  getFormattedDate,
  getGlobalTime,
} from "../../../../../utils/date";

export const getDetails = () => {
  return services
    .getPluginsDetails("advisor-plugin-1")
    .then(result => {
      return result.data;
    })
    .catch(err => {
      return err;
    });
};

export const getPluginResponse = () => {
  return services
    .getPluginsResponses("advisor-plugin-1")
    .then(result => {
      return result.data;
    })
    .catch(err => {
      return err;
    });
};

export const addPluginResponse = data => {
  return services
    .addPluginsResponses("advisor-plugin-1", data)
    .then(result => {
      return result.data;
    })
    .catch(err => {
      return err;
    });
};

export const updatePluginResponse = (responseId, data) => {
  return services
    .updatePluginsResponses("advisor-plugin-1", responseId, data)
    .then(result => {
      return result.data;
    })
    .catch(err => {
      return err;
    });
};

export const validateAdvisorPluginInput = userInputs => {
  if (userInputs.agenda && userInputs.duration) {
    return true;
  } else {
    return false;
  }
};

export const sanitizeDateTimePayload = responses => {
  const responsesData = [...responses].map(element => ({
    ...element,
    times: [
      ...element.times.map(time => {
        let ns = convertFormattedStringToDate(element.date).setHours(
          time.split(":")[0]
        );
        ns = new Date(ns).setMinutes(time.split(":")[1]);

        return ns;
      }),
    ],
  }));

  return responsesData;
};

export const parseDateTimePayload = responses => {
  let responsesData = [...responses];
  responsesData.forEach(element => {
    element.date = getFormattedDate(new Date(element.date), "YYYY-MM-DD");
    element.times = [
      ...element.times.map(time => {
        let ns = new Date(time).getHours() + ":" + new Date(time).getMinutes();
        ns = getCurrentTime(ns);
        ns = getGlobalTime(ns);
        console.log(ns);
        return ns;
      }),
    ];
  });
  return responsesData;
};
