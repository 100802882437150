import React, { useState, useEffect } from "react";
import { Select, MenuItem, Box, Typography, useTheme } from "@mui/material";
import SortIcon from "@mui/icons-material/Sort";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";

const SortComponent = ({ sortOptions, onSortChange, currentSort }) => {
	const [selectedSort, setSelectedSort] = useState("");
	const [isOpen, setIsOpen] = useState(false);
	const theme = useTheme();

	useEffect(() => {
		if (currentSort.colName) {
			setSelectedSort(currentSort);
		} else {
			setSelectedSort(null);
		}
	}, [currentSort]);

	const toggleSortDirection = (currentOption) => {
		if (currentOption.colName !== currentSort.colName) {
			return "ASC";
		} else {
			if (currentSort.direction === "ASC") return "DESC";
			if (currentSort.direction === "DESC") return null;
			return "ASC";
		}
	};

	const handleChange = (event) => {
		if (event?.target?.value === "") {
			setSelectedSort("");
			onSortChange({ colName: "", direction: "" });
		} else {
			const selectedOption = sortOptions.find((option) => option.colName === event.target.value);
			const newDirection = toggleSortDirection(selectedOption);
			if (newDirection) {
                setSelectedSort(newDirection ? { colName: event.target.value, direction: newDirection } : null)
				onSortChange({ colName: event.target.value, direction: newDirection });
			} else {
				setSelectedSort(null);
				onSortChange({ colName: "", direction: "" });
			}
		}
	};

	const getDirectionIcon = (direction) => {
		if (direction === "ASC")
			return (
				<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
					<path
						d="M20.3333 15L15 9.66663M15 9.66663L9.66663 15M15 9.66663V20.3333M28.3333 15C28.3333 22.3638 22.3638 28.3333 15 28.3333C7.63616 28.3333 1.66663 22.3638 1.66663 15C1.66663 7.63616 7.63616 1.66663 15 1.66663C22.3638 1.66663 28.3333 7.63616 28.3333 15Z"
						stroke={theme?.palette?.tertiary?.main ? theme?.palette?.tertiary?.main : theme?.palette?.primary?.main}
						stroke-width="1.5"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
				</svg>
			);
		if (direction === "DESC")
			return (
				<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
					<path
						d="M10.6666 16L16 21.3333M16 21.3333L21.3333 16M16 21.3333V10.6666M29.3333 16C29.3333 23.3638 23.3638 29.3333 16 29.3333C8.63616 29.3333 2.66663 23.3638 2.66663 16C2.66663 8.63616 8.63616 2.66663 16 2.66663C23.3638 2.66663 29.3333 8.63616 29.3333 16Z"
						stroke={theme?.palette?.tertiary?.main ? theme?.palette?.tertiary?.main : theme?.palette?.primary?.main}
						stroke-width="1.5"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
				</svg>
			);
		return null;
	};

    const getValueLabel = (valueObj) => {
        const selectedOption = sortOptions.find((option) => option.colName === valueObj?.colName);
            return <Box sx={{ display: "flex", alignItems: "center" }}>
                <SortIcon sx={{ mr: 1.5 }} fontSize="small" />{" "}
                <Typography
                    variant="Text/sm/Regular"
                    sx={{
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        opacity: selectedOption?.name ? "1" : ".5"
                    }}
                >
                    {selectedOption?.name ? selectedOption?.name : "Sort"}
                </Typography>
                {selectedOption && valueObj?.direction && <Box sx={{display:"flex", justifyContent:"flex-end", flex: 1, "& > svg":{height:"24px"}}}>{getDirectionIcon(valueObj?.direction)}</Box>}
            </Box>
    }

	return (
		<Select
			IconComponent={KeyboardArrowDownRoundedIcon}
			value={selectedSort}
			onChange={handleChange}
			open={isOpen}
			onOpen={() => setIsOpen(true)}
			onClose={() => setIsOpen(false)}
			renderValue={(selected) => {
				return (
					getValueLabel(selected)
				);
			}}
			displayEmpty
			sx={{
				width: "200px",
				"& .MuiSelect-select": { padding: "10px" },
				"& .MuiOutlinedInput-notchedOutline": {
					transition: "all .2s",
					border: "none",
					boxShadow: isOpen ? "0px 3px 11px rgba(0, 0, 0, 0.05)" : "0px 0px 0px 1px #D0D5DD, 0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
					borderRadius: isOpen ? "30px 30px 0px 0px" : "30px",
				},
			}}
			MenuProps={{
				PaperProps: {
					style: {
						boxShadow: "0px 11px 11px rgba(0, 0, 0, 0.05)",
						borderRadius: "0px 0px 30px 30px",
						mt: "-6px",
					},
				},
			}}
		>
			<MenuItem value="">
				<Typography
					variant="Text/sm/Regular"
					sx={{
						textOverflow: "ellipsis",
						whiteSpace: "nowrap",
						overflow: "hidden",
					}}
				>
					None
				</Typography>
			</MenuItem>
			{sortOptions.map((option) => (
				<MenuItem key={option.colName} value={option.colName}>
					<Box sx={{ display: "flex", justifyContent: "space-between", flex: 1, alignItems: "center" }}>
						<Typography
							variant="Text/sm/Regular"
							sx={{
								textOverflow: "ellipsis",
								whiteSpace: "nowrap",
								overflow: "hidden",
							}}
						>
							{option.name}
						</Typography>
						{currentSort.colName === option.colName && getDirectionIcon(currentSort.direction)}
					</Box>
				</MenuItem>
			))}
		</Select>
	);
};

export default SortComponent;
