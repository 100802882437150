import styled from "@emotion/styled";
import { Box, Tabs, Tab } from "@mui/material";

export const ProfileContainer = styled.div(({ theme }) => {
	return {
		[theme.breakpoints.up("sm")]: {
			width: "95%",
		},
		width: "fit-content",
		overflowY: "scroll",
		height: "100vh",
		maxWidth: theme.breakpoints.values.xl,
		display: "flex",
		flexDirection: "column",
		gap: "32px",
		margin: "0 auto",
		padding: "0 16px",
	};
});

export const EditTabs = styled(Tabs)(({ theme }) => ({
	alignItems: "stretch",
	"& .MuiTabs-flexContainer": {
		gap: "24px",
	},
	"& .MuiTabs-indicator": {
		display: "none",
	},
	"& .MuiTabs-scrollButtons": {
		color: theme.palette.primary.main,
		"& > svg": {
			height: "30px",
			width: "30px",
		},
	},
}));

export const EditTabsItem = styled(Tab)(({ theme }) => ({
	textTransform: "unset",
	textAlign: "left",
	padding: "16px",
	whiteSpace: "nowrap",
	maxWidth: "unset",
	opacity: ".6",
	color: "black",
	borderRadius: "100px",
	backgroundColor: "rgba(242, 244, 247, 1)",
	filter: "grayscale(100%)",
	"&.Mui-selected": {
		opacity: "1",
		color: "white",
		backgroundColor: "black",
		// filter: "grayscale(0%)",
	},
}));
