import React, { useEffect, useMemo, useState } from "react";
import { Box, styled, Button, CircularProgress, Typography, Stack, Grid } from "@mui/material";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

import { PowerUPController as PowerUP_V2 } from "../../PowerUP/PowerUPController";

ChartJS.register(ArcElement, Tooltip, Legend);

export default function PublicAreYouFundable() {
	return (
		<Box>
			<PowerUP_V2 />
		</Box>
	);
}
