import { Box, IconButton } from "@mui/material";
import React from "react";
import moment from "moment";
import { Add, Close } from "@mui/icons-material";
import TimeSelection from "./TimeSelection";
import { handleMixPanel } from "../../../../utils/mixPanelEventHandling";

function TimeItem({ timeList, updateDayTimeData, setAvailabilitiesToDelete }) {
	const handleTimeChange = (value, index, type) => {
		const localList = [...timeList];
		localList[index][type] = value;

		// Convert moment objects to JavaScript Date objects

		// const startTime = moment(localList[index]?.startTime)?.toDate();
		// const endTime = moment(localList[index].endTime)?.toDate();

		// // Check if start time is after end time
		// if (type === "startTime" && startTime >= endTime) {
		// 	// Update the end time to be 1 hour after the start time
		// 	localList[index].endTime = moment(startTime).add(1, "h");
		// } else if (type === "endTime" && endTime <= startTime) {
		// 	// Update the start time to be 1 hour before the end time
		// 	localList[index].startTime = moment(endTime).subtract(1, "h");
		// }

		// // Handle edge cases for midnight times
		// if (moment(localList[index].startTime)?.get("hour") === 0 && moment(localList[index].endTime)?.get("hour") === 0) {
		// 	// Start and end times are both midnight
		// 	localList[index].startTime = moment(localList[index].startTime).subtract(1, "h");
		// 	localList[index].endTime = moment(localList[index].endTime).add(1, "h");
		// } else if (moment(localList[index].startTime)?.get("hour") === 0) {
		// 	// Start time is midnight
		// 	localList[index].endTime = moment(localList[index].endTime).add(1, "h");
		// } else if (moment(localList[index].endTime)?.get("hour") === 0) {
		// 	console.log("End time is midnight");
		// 	// End time is midnight
		// 	localList[index].startTime = moment(localList[index].endTime).subtract(1, "h");
		// }

		updateDayTimeData(localList);
	};

	const handleAddTime = () => {
		const localList = [...timeList];

		const newStartTime = moment(timeList[timeList.length - 1]?.endTime, "HH:mm:ss").clone();
		const newEndTime = newStartTime.clone().add(0.5, "hour");

		if (newEndTime.isAfter(moment("23:30:00", "HH:mm:ss"))) {
			newEndTime.set("hour", 23).set("minute", 30).set("second", 0);
		}
		localList.push({
			startTime: newStartTime.toDate(),
			endTime: newEndTime.toDate(),
		});

		handleMixPanel("Meeting Availability Edited", {
			Edit: "Time",
		});

		updateDayTimeData(localList);
	};

	const handleRemoveTime = (index) => {
		if (timeList?.[index]?.availabilityId) {
			const updatedTimeList = timeList.filter((item) => item.availabilityId !== timeList?.[index]?.availabilityId);
			updateDayTimeData(updatedTimeList);
			setAvailabilitiesToDelete(timeList?.[index]?.availabilityId);
		} else {
			const updatedTimeList = [...timeList];
			updatedTimeList.splice(index, 1);
			updateDayTimeData(updatedTimeList);
		}
		handleMixPanel("Meeting Availability Edited", {
			Edit: "Time",
		});
	};

	return (
		<Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
			{timeList?.map((timeItem, index) => (
				<Box key={index} sx={{ display: "flex", flex: 1, alignItems: "center", justifyContent: "space-between", gap: 1 }}>
					{/*
						50px force to keep symmetry on function unavailability 
					*/}
					<Box sx={{ width: "50px" }}>
						<IconButton
							sx={{
								border: "3px solid",
								borderColor: "transparent",
								backgroundColor: "#F2F4F7",
								color: "#344054",
								transition: "all .2s",
								"&:hover": {
									color: "#344054",
									borderColor: "#F2F4F7",
									backgroundColor: "white",
								},
							}}
							disabled={timeList?.length == 1}
							onClick={() => handleRemoveTime(index)}
						>
							<Close />
						</IconButton>
					</Box>
					<Box sx={{ display: "flex", flex: 1, alignItems: "center", justifyContent: "center", gap: 1 }}>
						<TimeSelection time={moment(timeItem?.startTime, "HH:mm:ss")} onChange={(val) => handleTimeChange(val, index, "startTime")} />
						<Box
							sx={{
								width: "15px",
								height: "2px",
								backgroundColor: "black",
							}}
						></Box>
						<TimeSelection time={moment(timeItem?.endTime, "HH:mm:ss")} onChange={(val) => handleTimeChange(val, index, "endTime")} />
					</Box>

					{/*
						50px force to keep symmetry on function unavailability 
					*/}
					<Box sx={{ width: "50px" }}>
						{((index == 0 && timeList?.length == 1) || (index > 0 && index == timeList?.length - 1)) && (
							<IconButton
								sx={{
									border: "3px solid",
									borderColor: "transparent",
									backgroundColor: "#7B61FF",
									color: "white",
									transition: "all .2s",
									"&:hover": {
										color: "#7B61FF",
										borderColor: "#7B61FF",
										backgroundColor: "white",
									},
								}}
								onClick={handleAddTime}
							>
								<Add />
							</IconButton>
						)}
					</Box>
				</Box>
			))}
		</Box>
	);
}

export default TimeItem;
