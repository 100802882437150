import * as React from "react";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import startOfDay from "date-fns/startOfDay";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import { Box, IconButton, Typography } from "@mui/material";
import TimePicker from "./TimePicker";
import { convertFormattedStringToDate, getFormattedDate } from "../../utils/date";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { GLOBE_HEMISPHERE_EAST_ICON } from "../../constants";

export default function DateTimeSlotPicker({
	responses,
	setResponses,
	minTimeSlots,
	maxTimeSlots,
	selectedDate,
	setSelectedDate,
	mobileUI,
	setTimeDrawer,
	buttonsPerRow = 2,
	startTime,
	endTime,
	duration,
	customSlot,
	singleDateSelect,
	timeButtonVariant = "outlined",
	availableTimes,
	disableTimesAsPerAvailability,
	monthChanged = () => {},
	setSelectedTimeData = () => {},
	timeStampsLoading = false,
}) {
	const [times, setTimes] = React.useState([]);
	const calendarRef = React.useRef(null);
	const [calendarWidth, setCalendarWidth] = React.useState(null);
	const [currentMonth, setCurrentMonth] = React.useState(moment());
	const [prevDisabled, setPrevDisabled] = React.useState(true);

	React.useEffect(() => {
		const previousMonth = moment(currentMonth).subtract(1, "month");
		if (previousMonth.isBefore(moment(), "month")) {
			setPrevDisabled(true);
		} else {
			setPrevDisabled(false);
		}
	}, [currentMonth]);

	const handlePrevMonth = () => {
		const previousMonth = moment(currentMonth).subtract(1, "month");
		if (previousMonth.isBefore(moment(), "month")) {
			return;
		}
		setCurrentMonth(previousMonth);
	};

	const handleNextMonth = () => {
		const nextMonth = moment(currentMonth).add(1, "month");
		setCurrentMonth(nextMonth);
	};

	React.useEffect(() => {
		if (calendarRef.current) {
			setCalendarWidth(calendarRef.current.offsetWidth);
		}
	}, []);

	const findDate = (dates, date) => {
		const dateTime = date.getTime();
		return dates.find((item) => item.getTime() === dateTime);
	};
	const dateEnabled = (date) => {
		return date.getTime() >= startOfDay(new Date()).getTime();
	};
	const renderPickerDay = (props) => {
		const { day, outsideCurrentMonth, ...other } = props;
		const date = new Date(day);
		const dayNumber = date.getUTCDay() == 0 ? 7 : date.getUTCDay();

		let isDisabled = false;

		isDisabled = !dateEnabled(date);

		const values = responses.map((item) => convertFormattedStringToDate(item.date, "YYYY-MM-DD"));
		const selected = singleDateSelect ? selectedDate?.getTime() === moment(day)?.toDate()?.getTime() : findDate(values, moment(day)?.toDate());

		return (
			<PickersDay
				{...other}
				selected={selected}
				disabled={isDisabled}
				outsideCurrentMonth={outsideCurrentMonth}
				day={day}
				sx={{
					"&": (theme) => ({
						...theme.typography["Text/xs/Regular"],
					}),
					"&.Mui-selected:focus": {
						backgroundColor: (theme) => (!isDisabled && selected ? theme.palette.primary.main : "initial"),
					},
					"&.Mui-disabled": {
						color: dateEnabled(date) ? "black !important" : "initial",
					},
					backgroundColor: !isDisabled ? "#F2F4F7" : "none",
				}}
			/>
		);
	};

	const handleResponseUpdates = (date) => {
		const responsesData = [...responses];
		const responseIndex = responsesData.findIndex((item) => item.date === date);
		if (responseIndex >= 0) {
			if (responsesData[responseIndex].times.length) {
				setTimes(responsesData[responseIndex].times);
			} else {
				responsesData.splice(responseIndex, 1);
			}
		} else {
			setTimes([]);
		}
		setResponses(responsesData);
		if (mobileUI) {
			setTimeDrawer(true);
		}
	};

	const handleTimesUpdate = (currentDate, selectedTime) => {
		setSelectedTimeData({ meetingDate: currentDate, startTime: selectedTime?.startTime });
	};

	React.useEffect(() => {
		if (!selectedDate) {
			if (!responses.length) {
				setTimes([]);
			} else {
				setSelectedDate(convertFormattedStringToDate(responses[0].date));
				setTimes(responses[0].times);
			}
		} else {
			if (!responses.length) {
				setTimes([]);
			} else {
				let responsesData = [...responses];
				const index = responsesData.findIndex((item) => item.date === getFormattedDate(selectedDate, "YYYY-MM-DD"));
				if (index >= 0) {
					let element = responsesData[index];
					setTimes(element.times);
				} else {
					setTimes([]);
				}
			}
		}
	}, [responses]);

	React.useEffect(() => {
		if (monthChanged) {
			monthChanged(currentMonth);
		}
	}, [currentMonth]);

	return (
		<Box
			sx={{
				display: "flex",
				gap: 2,
				justifyContent: "space-between",
				alignItems: "flex-start",
				flexWrap: "wrap",
			}}
		>
			<Box sx={{ margin: "0 auto" }}>
				<Box
					sx={{
						borderRadius: "24px",
						boxShadow: "0px 10px 25px 0px rgba(0, 0, 0, 0.05)",
					}}
				>
					<LocalizationProvider dateAdapter={AdapterMoment}>
						<Box
							sx={{
								m: "0 auto",
								display: "flex",
								flexWrap: "nowrap",
								justifyContent: "space-between",
								alignItems: "center",
								maxWidth: calendarWidth,
							}}
						>
							<IconButton sx={{ ml: "20px" }} disabled={prevDisabled} onClick={handlePrevMonth}>
								<ChevronLeft />
							</IconButton>
							<Typography component={"span"} variant="Text/sm/Semibold" sx={{ color: "#344054" }}>
								{currentMonth.format("MMMM YYYY")}
							</Typography>
							<IconButton sx={{ mr: "20px" }} onClick={handleNextMonth}>
								<ChevronRight />
							</IconButton>
						</Box>
						<DateCalendar
							ref={calendarRef}
							views={["day"]}
							value={currentMonth || moment()}
							disablePast
							showDaysOutsideCurrentMonth
							sx={{
								"& .MuiPickersCalendarHeader-root": {
									display: "none",
								},
								"& .MuiDayCalendar-header span": (theme) => ({ ...theme.typography["Text/xs/Regular"] }),
							}}
							slots={{
								day: renderPickerDay,
							}}
							onChange={(newValue) => {
								const date = startOfDay(moment(newValue).toDate());
								if (dateEnabled(date)) {
									setSelectedDate(date);
									handleResponseUpdates(getFormattedDate(date, "YYYY-MM-DD"));
								}
							}}
						/>
					</LocalizationProvider>
				</Box>

				<Box sx={{ display: "flex", gap: 2, mt: 3, alignItems: "center" }}>
					<Box component={"img"} src={GLOBE_HEMISPHERE_EAST_ICON} sx={{ height: "30px", width: "30px" }} />
					<Typography variant="Text/sm/Regular">{moment.tz.guess()}</Typography>
				</Box>
			</Box>
			<Box
				sx={{
					flex: 1,
				}}
			>
				{selectedDate ? (
					<TimePicker
						selectedDate={selectedDate}
						times={times}
						handleTimesUpdate={handleTimesUpdate}
						minTimeSlots={minTimeSlots}
						maxTimeSlots={maxTimeSlots}
						buttonsPerRow={buttonsPerRow}
						startTime={startTime}
						endTime={endTime}
						duration={duration}
						customSlot={customSlot}
						timeButtonVariant={timeButtonVariant}
						availableTimes={availableTimes}
						disableTimesAsPerAvailability={disableTimesAsPerAvailability}
						timeStampsLoading={timeStampsLoading}
					/>
				) : (
					<></>
				)}
			</Box>
		</Box>
	);
}
