import services from "../../../../services";

export const personaProcessingAI = async (content, cb) => {
	cb({ key: "personaAI", values: { loading: true, complete: false } });

	if (!content) {
		cb({ key: "personaAI", values: { loading: false, complete: true } });
		return;
	}

	let payload = {
		messages: [
			{
				role: "system",
				content: `Using the provided object I need you to aggregate the following information:
                - persona_buyer.name 
				- persona_user.name
				- persona_influencer.name
				- persona_buyer income level
				- persona_buyer education
				- persona_user income level
				- persona_user education
				- persona_influencer income level
				- persona_influencer education
				- persona_buyer.traits ; any HTML lists should be broken down into an array of items
				- persona_user.traits ; any HTML lists should be broken down into an array of items
				- persona_influencer.traits ; any HTML lists should be broken down into an array of items
				- persona_buyer.goals ; any HTML lists should be broken down into an array of items
				- persona_user.goals ; any HTML lists should be broken down into an array of items
				- persona_influencer.goals ; any HTML lists should be broken down into an array of items
				- persona_buyer.pain_points ; any HTML lists should be broken down into an array of items
				- persona_user.pain_points ; any HTML lists should be broken down into an array of items
				- persona_influencer.pain_points ; any HTML lists should be broken down into an array of items

                Respond in the following JSON format:
                {
                    personas: [
                        {
                            name: {persona_buyer.name},
							age: {persona_buyer.age},
							income_level: {persona_buyer.income_level},
							education: {persona_buyer.education},
							traits: [{persona_buyer.traits}],
							goals: [{persona_buyer.goals}],
							pain_points: [{persona_buyer.pain_points}],
                        },
						{
                            name: {persona_user.name},
							age: {persona_user.age},
							income_level: {persona_user.income_level},
							education: {persona_user.education},
							traits: [{persona_user.traits}],
							goals: [{persona_user.goals}],
							pain_points: [{persona_user.pain_points}],
                        },
						{
                            name: {persona_influencer.name},
							age: {persona_influencer.age},
							income_level: {persona_influencer.income_level},
							education: {persona_influencer.education},
							traits: [{persona_influencer.traits}],
							goals: [{persona_influencer.goals}],
							pain_points: [{persona_influencer.pain_points}],
                        },
                       
                    ]
                }
                `,
			},
			{
				role: "user",
				content: `${content}`,
			},
		],
		maxTokens: 1200,
	};

	let attempts = 0;
	while (attempts < 2) {
		try {
			let AIResp = await services.getOpenAIChatResponse(payload).catch((e) => {
				console.log(e);
			});
			cb({ key: "personaAI", values: { loading: false, complete: true } });
			if (AIResp?.data?.data?.[0]?.text) {
				return processPayload(AIResp?.data?.data?.[0]?.text);
			} else if (AIResp?.data?.data?.[0]?.message?.content) {
				return processPayload(AIResp?.data?.data?.[0]?.message?.content);
			} else {
				attempts++;
			}
		} catch (error) {
			console.error(error);
		}
	}
	return false;
};

const processPayload = (payload) => {
	try {

		const parsed = JSON.parse(payload);

		const processedItems = [];
		parsed?.personas?.forEach((item) => {
			let traits = "";
			let goals = "";
			let pain_points = "";

			if (item?.traits)
				item?.traits?.forEach((traitItem, index) => {
					if(index + 1 < item?.traits?.length){
						traits += `${traitItem}\n`
					} else {
						traits += `${traitItem}`
					}
				});
			if (item?.goals)
				item?.goals?.forEach((goalItem, index) => {
					if(index + 1 < item?.goals?.length){
						goals += `${goalItem}\n`
					} else {
						goals += `${goalItem}`
					}
				});
			if (item?.pain_points)
				item?.pain_points?.forEach((painItem, index) => {
					if(index + 1 < item?.pain_points?.length){
						pain_points += `${painItem}\n`
					} else {
						pain_points += `${painItem}`
					}
				});

			processedItems.push({
				name: item?.name,
				age: item?.age,
				income_level: item?.income_level,
				education: item?.education,
				traits: traits,
				goals: goals,
				pain_points: pain_points,
			});
		});

		return processedItems;
	} catch (e) {
		console.log(e);
	}
};
