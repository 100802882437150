import React, { useEffect, useState } from "react";
import { Box, Typography, styled } from "@mui/material";
import Slider from "@mui/material/Slider";

import PersonaCard from "./PersonaCard";
import { SensorOccupiedOutlined } from "@mui/icons-material";

const PrettoSlider = styled(Slider)({
	color: "#F2F2F2",
	height: 12,
	"& .MuiSlider-track": {
		border: "none",
	},
	"& .MuiSlider-thumb": {
		height: 24,
		width: 24,
		backgroundColor: "#DE0085",
		border: "2px solid currentColor",
		"&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
			boxShadow: "inherit",
		},
		"&:before": {
			display: "none",
		},
	},
	"& .MuiSlider-valueLabel": {
		lineHeight: 1.2,
		fontSize: 12,
		padding: 0,
		width: 32,
		height: 32,
		borderRadius: "50% 50% 50% 0",
		backgroundColor: "#DE0085",
		transformOrigin: "bottom left",
		transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
		"&:before": { display: "none" },
		"&.MuiSlider-valueLabelOpen": {
			transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
		},
		"& > *": {
			transform: "rotate(45deg)",
		},
	},
});

export default function Taits({ persona }) {
	const [personaData, setpersonaData] = useState(persona);

	useEffect(() => {
		setpersonaData(persona);
	}, [persona]);

	const TRAITS = [
		{ start: "Introvert", end: "Extrovert", value: personaData?.traits?.introvert || 0 },
		{ start: "Analytical", end: "Creative", value: personaData?.traits?.analytical || 0 },
		{ start: "Loyal", end: "Fickle", value: personaData?.traits?.loyal || 0 },
		{ start: "Passive", end: "Active", value: personaData?.traits?.passive || 0 },
	];

	return (
		<PersonaCard isLarge={true} title="Traits" icon={<SensorOccupiedOutlined fontSize="large" />}>
			<Box mt={3}>
				{TRAITS.map((item) => (
					<Box key={item.title} px="60px" mb="30px">
						<Box display="flex" justifyContent="space-between" mb="30px">
							<Typography variant="card_text">{item.start}</Typography>
							<Typography variant="card_text">{item.end}</Typography>
						</Box>
						<PrettoSlider valueLabelDisplay="auto" value={item.value} defaultValue={0} min={0} max={10} />
					</Box>
				))}
			</Box>
		</PersonaCard>
	);
}
