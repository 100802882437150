import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import PersonaCard from "./PersonaCard";
import CHEVRONIMG from "./ChevronGroup.svg";
import HEARTIMG from "./Heart.svg";
import { zIndex } from "styled-system";

export default function Description({ persona }) {
	const [personaData, setpersonaData] = useState(persona);

	useEffect(() => {
		setpersonaData(persona);
	}, [persona]);

	return (
		<Box
			sx={{
				background: "black",
				borderRadius: "30px",
				padding: "60px 50px",
				boxShadow: "0px 10px 25px rgba(0, 0, 0, 0.05)",
				position: "relative",
				overflow: "hidden",
				mb: "30px",
			}}
		>
			<img
				src={CHEVRONIMG}
				style={{
					position: "absolute",
					left: 0,
					top: "50%",
					transform: "translateY(-50%)",
					zIndex: 0,
				}}
			/>
			<img
				src={HEARTIMG}
				style={{
					position: "absolute",
					right: 0,
					top: 0,
					zIndex: 0,
				}}
			/>
			<Box
				sx={{
					zIndex: 1,
					display: "flex",
					alignItems: "flex-start",
				}}
			>
				<Box>
					<Typography
						variant="customValue"
						sx={{
							color: "#DE0085",
							fontFamily: "PoppinsSemiBold",
							fontSize: "78px",
							lineHeight: "47px",
						}}
					>
						&#8220;
					</Typography>
				</Box>
				<Box>
					<Typography
						variant="customValue"
						sx={{
							color: "white",
							fontFamily: "PoppinsMedium",
							fontSize: "18px",
							lineHeight: "27px",
						}}
					>
						{personaData?.description}
					</Typography>
				</Box>
			</Box>
		</Box>
	);
}
