import React from "react";
import ReactPlayer from "react-player";

const VideoPlayerComponent = ({
	videoUrl,
	className,
	videoThumbnailUrl,
	height = "auto",
	width = "100%",
	// source: PERSONAL_PROFILE, POWERUP, STARTUP_PROFILE
	// === POWERUP, SEND TAG
}) => {
	return (
		<ReactPlayer
			className={className ? className : "react-player"}
			controls={true}
			width={width}
			height={height}
			maxHeight="400px"
			url={videoUrl}
			config={{
				file: {
					attributes: { poster: videoThumbnailUrl },
				},
			}}
		/>
	);
};

export default VideoPlayerComponent;
