import React from "react";
import ConnectButton from "./components/ConnectButton";
import { GoogleOAuthProvider } from "@react-oauth/google";

/*
1. App checks if user has connected calendars previously
	a. If app shows that the user has previously connected calendars:
		1. check to see if calendars can be pulled, ie. check to see if the users token still valid/does not req. consent.
		2. if the cal needs addtl. consent -> then show the connect calendar button. Otherwise show the modify calendar connection button
2. If user has not connected calendar previously, ie. no new records, then show the connect cal button.
3. User clicks the connect cal button
4. Info modal shows up explaining that this is for google calendars only. The next screen will ask for consent. You must check all boxes to work. Ready to continue?...
5. User is successful.
	- DB records:
		- access token
		- refresh token 
6. Prep for next modal -> service requests the user's calendar list using saved tokens.
	- If successful pass back list of calendars
	- If not successful due to error -> report error back to frontend. Delete the record from DB.
7. Assume successful/received payload: Next modal opens: "select your calendars"
8. User selects which calendars they want to track.
	- If user selects 0, then pass back empty and delete the DB record (assume disconnect status)
	- If the user selects at least 1, then pass back array of IDs
		- DB record updated with cal IDs
9. Show success message.


When the current user goes to their 'availability' page, 
the system should pull the user's "schedule for today"
which should show a list of events:
- SOS scheduled items
- SOS set available times
- SOS set blocked times 
- Google Cal blocked times


When any user attempts to book time with another user,
on the get availability call for a user:
1. check to see if this user has a connected google cal.
	If not, just pull the current cal.com api availability minus the current booked times.
2. Assuming they do have a cal. pull the busy times for all the selected calendars
	Pass all of the cal IDs (the user selected cals from their account) to the freebusy 
	query for the date selected (or a range of days depending on view -- just follow the standard cal.com api uses to get availability)
3. Remove any available slots that cal.com reports as available if theres a 'busy' time block from Google. 
4. Pass the payload forward.

Ex. of freebusy query
	{
  "timeMin": "2023-06-11T00:00:19-04:00", 	// Start date/time
  "timeMax": "2023-06-14T23:59:19-04:00", 	// End date/time
  "items": [								// Array of cal IDs. These are the calendars the user selected when they connected their calendar.
    {
      "id": "mgarrison@startupos.com" 
    }
  ]
}

*/

function GoogleCalendarConnection() {
	return (
		<GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CAL_CLIENT_ID}>
			<ConnectButton />
		</GoogleOAuthProvider>
	);
}

export default GoogleCalendarConnection;
