import React, { useState, useContext, useMemo, useEffect } from "react";
import { Box, Grid, MenuItem, Select, Stack, Typography, Button } from "@mui/material";
import UpdatedRichTextEditor from "../../../common/UpdatedRichtextEditor";
import UpdatedTextInputField from "../../../common/UpdatedTextInputField";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import services from "../../../services";
import { StyledSelect } from "../../../common/UpdatedSelectField/style";
import CompleteYourProfile from "../../../common/CompleteYourProfile";
import ReusableQuill from "../../../common/ReusableQuill";
const AboutMeSection = ({ context }) => {
	const fieldsList = [
		{ "Last Name": "lastName" },
		{ City: "city" },
		{ State: "state" },
		{ "Zip Code": "zipcode" },
		{ "LinkedIn Url": "linkedInUrl" },
		{ "Street Address": "address1" },
		{ "Apartment/Suite/Unit, District, Neighborhood, etc.": "address2" },
	];
	const { payLoad, setPayLoad, setOpenHarmonic, errors, setErrors } = useContext(context);
	const [countryStateData, setCountryStateData] = useState([]);

	const handleChange = (event) => {
		let key = event.target.id || event.target.name;
		let newValue = event.target.value;
		payLoad[key] = newValue;
		setPayLoad({ ...payLoad });
	};

	const handleBlur = (event) => {
		let key = event.target.id || event.target.name;
		let newValue = event.target.value;
		if (key === "firstName") {
			if (!newValue) {
				setErrors((prevState) => ({
					...prevState,
					firstNameError: "Please enter first name.",
				}));
			} else {
				setErrors((prevState) => ({
					...prevState,
					firstNameError: "",
				}));
			}
		}
		if (key === "lastName") {
			if (!newValue) {
				setErrors((prevState) => ({
					...prevState,
					lastNameError: "Please enter last name.",
				}));
			} else {
				setErrors((prevState) => ({
					...prevState,
					lastNameError: "",
				}));
			}
		}
	};

	const handleAboutChange = (value) => {
		payLoad["about"] = value;
		setPayLoad({ ...payLoad });
	};

	const getCountryStateData = async () => {
		const res = await services.getListOfCountryStateApi();
		setCountryStateData(res?.data?.data);
	};

	const getOptions = useMemo(() => {
		let selectedCountry = "USA";
		let stateList = [];
		if (payLoad?.country === null || typeof payLoad?.country === "undefined" || payLoad?.country === "") {
			selectedCountry = "USA";
		} else {
			selectedCountry = payLoad?.country;
		}
		if (countryStateData.length > 0) {
			stateList = countryStateData.filter((item) => item.countryCode === selectedCountry)[0]?.states;
		}
		if (stateList?.length > 0) {
			return stateList;
		} else {
			return countryStateData.filter((item) => item.countryCode === "USA")[0]?.states;
		}
	}, [countryStateData]);

	useEffect(() => {
		getCountryStateData();
	}, []);

	const copyFromLinkedInClick = () => {
		setOpenHarmonic(true);
	};
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				rowGap: "60px",
			}}
		>
			<Box>
				<Typography variant="poppinsSemiBold20">About Me</Typography>
			</Box>
			<Box>
				<Grid container rowSpacing={4} columnSpacing={7}>
					<Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
						<Stack direction={"column"} spacing={3}>
							<UpdatedTextInputField
								label={"First Name"}
								id={"firstName"}
								handleChange={handleChange}
								handleBlur={handleBlur}
								inputValue={payLoad["firstName"]}
								placeholder={"First Name"}
								textFieldProps={{
									fullwidth: true,
									width: "100%",
									height: "60px",
								}}
								error={errors.firstNameError}
							/>

							<Box>
								<Typography variant="count_title" component={"p"}>
									Bio
								</Typography>
								<Box
									sx={{
										mt: 2,
										backgroundColor: "white",
										borderRadius: "30px",
										p: 2,
										boxShadow: "0px 0px 0px 4px #F2F4F7, 0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
									}}
								>
									<ReusableQuill
										id={"mentor-profile"}
										value={payLoad["about"]}
										onChange={handleAboutChange}
										className={"profile-container"}
									/>
								</Box>
							</Box>
						</Stack>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
						<Stack direction={"column"} spacing={3}>
							{fieldsList?.map((item) => {
								if (Object.keys(item)[0] === "State") {
									return (
										<Box>
											<Typography variant="poppinsSemiBold18">State</Typography>
											<StyledSelect
												fullWidth
												value={payLoad[Object.values(item)[0]]}
												name={Object.values(item)[0]}
												onChange={handleChange}
												IconComponent={KeyboardArrowDownRoundedIcon}
												sx={{
													mt: 1,

													"& .MuiSelect-select": {
														fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
														fontSize: "16px",
														lineHeight: "24px",
													},
												}}
											>
												{getOptions?.map((option) => (
													<MenuItem key={option.stateCode} value={option.stateCode}>
														{option.stateName}
													</MenuItem>
												))}
											</StyledSelect>
										</Box>
									);
								} else if (Object.keys(item)[0] === "LinkedIn Url") {
									return (
										<>
											<UpdatedTextInputField
												label={Object.keys(item)[0]}
												id={Object.values(item)[0]}
												handleChange={handleChange}
												inputValue={
													payLoad[Object.values(item)[0]]
														? payLoad[Object.values(item)[0]]?.replace(/https:\/\/www\.linkedin\.com\/in\//g, "") || ""
														: ""
												}
												placeholder={Object.keys(item)[0]}
												textFieldProps={{
													fullwidth: true,
													width: "100%",
													height: "60px",
												}}
												InputProps={{
													startAdornment: (
														<Typography component={"p"} sx={{ pl: 2 }} variant="title_medium">
															https://www.linkedin.com/in/
														</Typography>
													),
												}}
											/>
											{payLoad[Object.values(item)[0]] && (
												<Box sx={{ mt: 1 }}>
													<Button variant="DS1" disabled={!payLoad[Object.values(item)[0]]} onClick={copyFromLinkedInClick}>
														Copy profile from Linkedin
													</Button>
												</Box>
											)}
											{payLoad[Object.values(item)[0]] === "" && (
												<Grid item xs={12} md={12} sx={{ pb: 3 }}>
													<CompleteYourProfile
														title={"Complete your profile faster"}
														sub_title={
															"Providing your startup’s LinkedIn profile link will autofill key information here needed to complete your profile."
														}
													/>
												</Grid>
											)}
										</>
									);
								} else {
									return (
										<UpdatedTextInputField
											label={Object.keys(item)[0]}
											id={Object.values(item)[0]}
											handleChange={handleChange}
											handleBlur={handleBlur}
											inputValue={payLoad[Object.values(item)[0]]}
											placeholder={Object.keys(item)[0]}
											textFieldProps={{
												fullwidth: true,
												width: "100%",
												height: "60px",
											}}
											error={Object.values(item)[0] === "lastName" ? errors.lastNameError : ""}
										/>
									);
								}
							})}
						</Stack>
					</Grid>
				</Grid>
			</Box>
		</Box>
	);
};

export default AboutMeSection;
