import { createTheme } from "@mui/material/styles";
import { sosPrimary } from "./colors";

export const themeInputs = (themePalette) => ({
	components: {
		//
		// Using !important tags to override the typogtaphy theme styles.
		// These elements want to use body1, but the prototype calls for these values.
		//
		MuiInputBase: {
			styleOverrides: {
				input: {
					"&:-webkit-autofill": {
						transitionDelay: "9999s",
						transitionProperty: "background-color, transparent",
					},
				},
			},
		},
		MuiInputLabel: {
			styleOverrides: {
				root: {
					//fontFamily:
					//  '"PoppinsMedium", "Helvetica", "Arial"," sans-serif" !important',
					//fontSize: "16px !important",
					zIndex: 0,
				},
			},
		},

		MuiOutlinedInput: {
			defaultProps: {
				color: "primary",
				variant: "outlined",
				//Chromes autofill css not getting overriden we can change bg-color but cant set spacing and zindex so making autocomplete off
				autoComplete: "off",
			},
			styleOverrides: {
				root: {
					borderRadius: (theme) => theme.shape.standard_small.borderRadius,
				},
			},
			variants: [
				{
					props: { variant: "TahoeOutlined" },
					style: {
						"&, & .MuiInputBase-input::placeholder": {
							borderRadius: "20px",
							fontFamily: "'PoppinsMedium' !important",
							fontSize: "16px",
							lineHeight: "24px",
							transition: "all .2s",
						},
					},
				},
			],
		},

		MuiFilledInput: {
			defaultProps: {
				color: "primary",
			},
			variants: [
				{
					props: { color: "primary", error: false, disabled: false },
					style: {
						backgroundColor: sosPrimary[500],
						"&:hover": {
							backgroundColor: sosPrimary[200],
						},
						"&:focus-within": {
							backgroundColor: sosPrimary[500],
						},
					},
				},
			],
		},

		MuiTextField: {
			defaultProps: {
				variant: "outlined",
				color: "primary",
			},

			//variants: [
			//  {
			//    props: { error: true },
			//    style: {
			//      "& .MuiInputAdornment-root": {
			//        color: themePalette.palette.error.main,
			//      },
			//    },
			//  },
			//],
		},
	},
});
