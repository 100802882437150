/**
	This file is to coordinate the Profile component based on how it is accessed.
**/
import React, { useState, useEffect, createContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, matchPath, useParams, useHistory } from "react-router-dom";
import _ from "lodash";
import { Helmet } from "react-helmet";

import ROUTES_PATH from "../../constants/routes";
import messages from "../../constants/messages";
import { setAuthData } from "../../modules/actions";
import { USER_TYPES } from "../../utils/userTypes";
import { toastContainer } from "../../utils/toast";
import { AccountProfile } from "./Stores/AccountProfile";
import { StartupProfile } from "./Stores/StartupProfile";
import ProfileContent from "./ProfileContent";
import { handleMixPanel } from "../../utils/mixPanelEventHandling";
import PaymentSuccessDialog from "../PaymentPlanDialog/PaymentSuccessDialog";
import EditPartnerQADialog from "./Components/EditPartnerQA";

// Create a context object
export const ProfileContext = createContext();

// Define the Profile component that renders different content based on the route and context
const Profile = () => {
	const location = useLocation();
	const history = useHistory();
	const params = useParams();
	const dispatch = useDispatch();
	const [openPaymentSuccessModal, setOpenPaymentSuccessModal] = useState(false);

	const [contextState, setContextState] = useState({ component: null, id: null });

	const userData = useSelector((store) => ({ userType: store?.auth?.userType, companyId: store?.auth?.companyId, userId: store?.auth?.userId }));
	const slidingProfileData = useSelector((store) => ({
		open: store?.slidingProfile?.open,
		profileType: store?.slidingProfile?.profileType,
		profileId: store?.slidingProfile?.profileId,
	}));

	const initilizer = () => {
		if (slidingProfileData?.open && slidingProfileData?.profileId) {
			if (slidingProfileData?.profileType === "STARTUP_PROFILE") {
				if (slidingProfileData?.profileId === userData?.companyId) {
					if (
						userData?.userType === USER_TYPES.STARTUP_OWNER ||
						userData?.userType === USER_TYPES.STARTUP_USER ||
						userData?.userType === USER_TYPES.STUDENT_USER
					) {
						// Update the local state with the current view context
						setContextState({ component: "startup", id: null });
					} else {
						// User is of type that does not have a startup profile. Reroute to their personal account profile.
						history.push(ROUTES_PATH.MY_ACCOUNT_PROFILE);
					}
				} else {
					// Update the local state with the current view context
					setContextState({ component: "startup", id: isNaN(slidingProfileData?.profileId) ? null : parseInt(slidingProfileData?.profileId) });
				}
			} else if (slidingProfileData?.profileType === "ACCOUNT_PROFILE") {
				if (userData?.userId === parseInt(slidingProfileData?.profileId)) {
					// Update the local state with the current view context
					setContextState({ component: "account", id: null });
				} else {
					// Update the local state with the current view context
					setContextState({ component: "account", id: isNaN(slidingProfileData?.profileId) ? null : parseInt(slidingProfileData?.profileId) });
				}
			}
			return;
		}

		// Define an array of objects containing route patterns and their corresponding context components
		const paths = [
			{ pattern: ROUTES_PATH.MY_STARTUP_PROFILE, component: "startup" },
			{ pattern: ROUTES_PATH.MY_ACCOUNT_PROFILE, component: "account" },
			{ pattern: ROUTES_PATH.STARTUP_PROFILE, component: "startup" },
			{ pattern: ROUTES_PATH.ACCOUNT_PROFILE, component: "account" },
		];

		// Loop through the array of objects to find a match with the current route
		for (let i = 0; i < paths.length; i++) {
			const { pattern, component } = paths[i];
			const match = matchPath(location.pathname, { path: pattern });

			if (match) {
				const { id } = params;

				// Update the view context of the appropriate context component
				if (component === "startup") {
					// Load the personal startup profile, not the public profile, only if the user has one.
					if (!id) {
						if (
							userData?.userType === USER_TYPES.STARTUP_OWNER ||
							userData?.userType === USER_TYPES.STARTUP_USER ||
							userData?.userType === USER_TYPES.STUDENT_USER
						) {
							// Update the local state with the current view context
							setContextState({ component, id: null });
						} else {
							// User is of type that does not have a startup profile. Reroute to their personal account profile.
							history.push(ROUTES_PATH.MY_ACCOUNT_PROFILE);
						}
					} else {
						// Update the local state with the current view context
						setContextState({ component, id: isNaN(id) ? null : parseInt(id) });
					}
				} else if (component === "account") {
					if (userData?.userId === parseInt(id)) {
						// Update the local state with the current view context
						setContextState({ component, id: null });
					} else {
						// Update the local state with the current view context
						setContextState({ component, id: isNaN(id) ? null : parseInt(id) });
					}
				}

				return;
			}
		}

		// If no route matches, set the local state to null
		setContextState(null);
	};
	// Set View Context
	useEffect(() => {
		initilizer();
	}, [location]);

	useEffect(() => {
		if (location?.search === "?stripe=success") {
			handleMixPanel("Stripe Success");
			dispatch(setAuthData({ isPremiumPlanAccess: true }));
			setOpenPaymentSuccessModal(true);
		} else if (location?.search === "?stripe=failure") {
			handleMixPanel("Stripe Fail");
			toastContainer(messages.CHECKOUT_ERROR, "error");
		}
	}, [location?.search]);

	useEffect(() => {
		const unlisten = history.listen((location, action) => {
			initilizer();
		});
		return () => {
			unlisten();
		};
	}, [history]);

	const handlePaymentSuccessModal = () => {
		setOpenPaymentSuccessModal(false);
	};

	// Conditionally render a child context provider based on the view context
	return (
		<>
			{contextState && contextState?.component && (
				<>
					{contextState?.component === "startup" ? (
						<StartupProfile>
							<Helmet>
								<meta charSet="utf-8" />
								<title>StartupOS - My Startup</title>
							</Helmet>
							<ProfileContent />
							<EditPartnerQADialog />
						</StartupProfile>
					) : contextState?.component === "account" ? (
						<AccountProfile>
							<Helmet>
								<meta charSet="utf-8" />
								<title>StartupOS - My Profile</title>
							</Helmet>
							<ProfileContent />
						</AccountProfile>
					) : (
						<></>
					)}
				</>
			)}
			{openPaymentSuccessModal && <PaymentSuccessDialog open={openPaymentSuccessModal} onClose={handlePaymentSuccessModal} />}
		</>
	);
};

export default Profile;
