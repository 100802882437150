import { Box, CircularProgress, Slider, Typography } from "@mui/material";
import React, { Fragment, useState } from "react";
import moment from "moment";
import { Document, Page, Text, pdf } from "@react-pdf/renderer";
import { generatePDFContent } from "../../../../containers/PowerUP/functions";
import { LetterHead, styles } from "../../../../utils/PDF-Export/utilities";
import LeanCanvasGrid from "../../../../containers/PowerUP/compositions/DoIt/LeanCanvas/LeanCanvasGrid";
import PersonaGrid from "../../../../containers/PowerUP/compositions/DoIt/PersonaBuilder/PersonaGrid";
import TamSamSomMUIGraph from "../../../../containers/PowerUP/components/graph/TamSamSomMUIGraph";
import { REDIRECT_ICON_V1 } from "../../../../constants";
import { TEXT_MAP } from "./textMaps";
import { saveAs } from "file-saver";

export const DownloadPdfCell = (params) => {
	const powerUpName = params?.value?.value?.powerUpName;
	const resData = params?.value?.value?.answers ? JSON.parse(params?.value?.value?.answers) : [];
	const modelUuid = params?.value?.value?.modelUuid;
	const [pdfLoading, setPdfLoading] = useState(false);

	const capitalizeFirstWord = (str) => {
		const words = str.split(" ");
		const capitalizedWords = words.map((word) => {
			if (!isNaN(word[0])) {
				return word;
			} else {
				return word.charAt(0).toUpperCase() + word.slice(1);
			}
		});
		return capitalizedWords.join(" ");
	};

	const getPDFDownload = async (data, powerUpName, textMap, modelUuid) => {
		if (!modelUuid) {
			const processedItems = await generatePDFContent({
				title: powerUpName,
				sections: [
					{
						title: "No Result",
					},
				],
			});
			return processedItems;
		}

		if (modelUuid === "md-mv-prioritizer") {
			let mvpPrioritizerData = {
				title: powerUpName,
				sections: data
					? [].concat(
							...data?.map((item) => {
								const filteredKeys = Object.keys(item).filter((key) => !["index", "x", "y"].includes(key));

								return filteredKeys.map((key) => ({
									title: capitalizeFirstWord(key),
									response: item[key] ? item[key] : "None",
								}));
							})
					  )
					: [],
			};

			const processedItems = await generatePDFContent(mvpPrioritizerData);

			return processedItems;
		}

		if (modelUuid === "md-brandstorm") {
			const sections = [];
			for (const key in data) {
				if (Object.hasOwnProperty.call(data, key)) {
					const value = data[key];
					console.log(`${key}: ${value}`);
					const updatedValue = value?.join();
					const obj = {};
					obj[key] = updatedValue;
					sections.push(obj);
				}
			}

			let mdBrandStromData = {
				title: powerUpName,
				sections: sections
					? [].concat(
							...sections?.map((item) => {
								const filteredKeys = Object.keys(item);
								return filteredKeys.map((key) => ({
									title: capitalizeFirstWord(key),
									response: item[key],
								}));
							})
					  )
					: [],
			};

			const processedItems = await generatePDFContent(mdBrandStromData);

			return processedItems;
		}

		const documentData = {
			title: powerUpName,
			sections:
				!data || data?.length === 0
					? [{ title: "No Result" }]
					: data &&
					  data?.map((input) => {
							let id = input?.id;
							let type = input?.type;
							let title = textMap?.find((item) => item.id === id)?.title ?? "No Result";
							let response = input?.value ?? "";

							if (
								id === "md-lean-canvas-creativeScale" ||
								id === "md-tam-creativeScale" ||
								id === "md-are-you-fundable-creativeScale" ||
								id === "md-enterprise-ready-creativeScale" ||
								id === "md-discovery-interview-creativeScale" ||
								id === "md-elevator-pitch-creativeScale" ||
								id === "md-value-prop-creativeScale" ||
								id === "md-prob-sol-creativeScale" ||
								id === "md-story-creativeScale" ||
								id === "md-value-prop-creativeScale" ||
								id === "md-exit-strategy-creativeScale" ||
								id === "md-moat-creativeScale" ||
								id === "md-pricing-model-saas-creativeScale" ||
								id === "md-hardware-pricing-modeler-creativeScale" ||
								id === "md-services-pricing-modeler-creativeScale" ||
								id === "md-retail-pricing-modeler-creativeScale"
							) {
								type = "image";
								response = (
									<Box sx={{ width: "500px" }}>
										<Slider value={data.find((item) => item.id === id)?.value} step={1} min={1} max={5} valueLabelDisplay="on" />
									</Box>
								);
							}

							if (id === "md-lean-canvas-finalOutput") {
								type = "image";
								response = (
									<Box sx={{ padding: "1rem" }}>
										<LeanCanvasGrid showLoader={false} gridData={data?.find((item) => item.id === id)?.value} usePrintable={true} />
									</Box>
								);
							}

							if (id === "md-persona-builder-finalOutput") {
								type = "image";
								response = (
									<Box sx={{ padding: "1rem" }}>
										<PersonaGrid showLoader={false} gridData={data?.find((item) => item.id === id)?.value} usePrintable={true} />
									</Box>
								);
							}

							if (id === "md-tam-graphOutput") {
								type = "image";
								response = (
									<Box sx={{ width: "500px" }}>
										<TamSamSomMUIGraph TamSamSomData={data?.find((item) => item.id === id)?.value} />
									</Box>
								);
							}

							if (
								id === "md-tam-finalOutput" ||
								id === "md-are-you-fundable-finalOutput" ||
								id === "md-enterprise-ready-finalOutput" ||
								id === "md-discovery-interview-finalOutput" ||
								id === "md-elevator-pitch-finalOutput" ||
								id === "md-value-prop-finalOutput" ||
								id === "md-prob-sol-finalOutput" ||
								id === "md-story-finalOutput" ||
								id === "md-value-prop-finalOutput" ||
								id === "md-exit-strategy-finalOutput" ||
								id === "md-moat-finalOutput" ||
								id === "md-pricing-model-saas-finalOutput" ||
								id === "md-hardware-pricing-modeler-finalOutput" ||
								id === "md-services-pricing-modeler-finalOutput" ||
								id === "md-retail-pricing-modeler-finalOutput"
							) {
								type = "AI";
							}

							if (id === "md-tam-tableOutput") {
								type = "image";
							}

							return {
								title,
								response,
								type,
							};
					  }),
		};

		const processedItems = await generatePDFContent(documentData);

		return processedItems;
	};

	const handleClick = async () => {
		try {
			setPdfLoading(true);
			const value = await getPDFDownload(resData, powerUpName, TEXT_MAP, modelUuid);
			const blob = await pdf(
				<Document>
					<Page style={styles.body}>
						<LetterHead />
						{value && value?.map((item, index) => <Fragment key={index}>{item}</Fragment>)}
						<Text style={styles.footerCopy} fixed>
							{moment().format("YYYY")} StartupOS Inc.
						</Text>
						<Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`} fixed />
					</Page>
				</Document>
			).toBlob();
			saveAs(blob, `${powerUpName}.pdf`);
			setTimeout(() => {}, 2000);
			setPdfLoading(false);
		} catch (error) {
			console.log(`Error generating pdf`, error);
			setPdfLoading(false);
		}
	};

	return (
		<Box
			display="flex"
			justifyContent="center"
			alignItems="center"
			cursor="pointer"
			sx={(theme) => ({
				color: theme.palette.primary,
				"&:hover": {
					color: theme.palette.secondary,
				},
				backgroundColor: !pdfLoading ? "#F2F4F7" : "none",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				height: "30px",
				width: "30px",
				borderRadius: "15px",
			})}
			onClick={handleClick}
		>
			{!pdfLoading ? <Box component={"img"} src={REDIRECT_ICON_V1} alt="redirect" /> : <CircularProgress size={25} />}
		</Box>
	);
};
