import React, { useState } from "react";
import {
  Box,
  Divider,
  Grid,
  Typography,
  Button,
  TextField,
  Chip,
} from "@mui/material";
import theme from "../../../../constants/Theme";
import SelectInput from "../../../../common/SelectInput";
import {
  getCurrentDate,
  convertFormattedStringToDate,
  getCurrentTime,
  getCharDay,
  getLocalTimeZone,
} from "../../../../utils/date";
import RichTextEditor from "../../../../common/RichTextEditor";
import { stateToMarkdown } from "draft-js-export-markdown";
import {
  RICH_TEXT_EDITOR_INLINE_CONTROLS,
  RICH_TEXT_EDITOR_MAIN_CONTROLS,
} from "../../../../constants/plugin-constants";
import { stateToHTML } from "draft-js-export-html";
import { EditorState, ContentState } from "draft-js";
import { stateFromHTML } from "draft-js-import-html";

export default function MeetingDetailComponent({
  userInputs,
  selectedDateTimes,
  setUserInputs,
  boxPadding = 6,
  getDuration = () => {},
}) {
  const contentState = stateFromHTML(userInputs?.agenda);

  const editorState = EditorState.createWithContent(contentState);
  const [agenda, setAgenda] = useState(
    stateToMarkdown(editorState.getCurrentContent())
  );
  const handleInputChange = (e, key) => {
    const value = e.target.value;
    const currentInputs = { ...userInputs };
    currentInputs[key] = value;
    setUserInputs(currentInputs);
    getDuration(currentInputs);
  };

  const handleAgendaChange = value => {
    const inputVal = stateToMarkdown(value.getCurrentContent());
    setAgenda(inputVal);
    setUserInputs({
      ...userInputs,
      agenda: stateToHTML(value.getCurrentContent()),
    });
  };

  return (
    <Box sx={{ px: boxPadding }}>
      <Grid item xs={12} md={12} sx={{ pt: 4 }}>
        <Typography variant="headline6">Add Meeting Details</Typography>
        <Divider sx={{ pt: 2 }} />
      </Grid>
      <Grid
        item
        xs={12}
        md={12}
        sx={{
          pt: 2,
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <Typography variant="title_medium">Selected Times</Typography>
        {selectedDateTimes.map(item => (
          <Box sx={{ mb: 1 }}>
            <Typography variant="title_small">
              {getCharDay(convertFormattedStringToDate(item.date))}{" "}
              {getCurrentDate(convertFormattedStringToDate(item.date))}
              {" - "}
              {getLocalTimeZone()}
            </Typography>
            <br />
            <Box>
              {item.times.map(time => {
                return (
                  <Chip
                    label={getCurrentTime(time)}
                    variant="outlined"
                    sx={theme => ({
                      borderRadius: theme.shape.standard_small.borderRadius,
                      my: 1,
                      mr: 1,
                    })}
                  />
                );
              })}
            </Box>
          </Box>
        ))}
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <Typography variant="title_medium">Duration</Typography>
        <SelectInput
          label="Duration"
          options={["30 min", "60 min"]}
          onInputChange={handleInputChange}
          selectedValue={userInputs.duration || ""}
          inputKey="duration"
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={12}
        sx={{
          pt: 2,
          pr: 2,
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <Typography variant="title_medium">Add Agenda</Typography>
        <Box
          sx={theme => ({
            width: 1,
            minHeight: "200px",
            border: `1px solid ${theme.palette.secondary.dark}`,
            px: 1,
            mr: 2,
            borderRadius: theme.shape.standard3.borderRadius,
          })}
        >
          <RichTextEditor
            label="Agenda"
            value={agenda}
            toolbar={true}
            inlineToolbar={false}
            inlineToolbarControls={RICH_TEXT_EDITOR_INLINE_CONTROLS}
            controls={RICH_TEXT_EDITOR_MAIN_CONTROLS}
            readOnly={false}
            onChange={handleAgendaChange}
            minHeight={"200px"}
          />
        </Box>
      </Grid>
    </Box>
  );
}
