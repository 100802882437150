import { Box, Divider, Typography } from '@mui/material'
import React from 'react'

function Sections({ title, subTitle, isActive, handleClick }) {
	return (
		<Box display="flex" flexDirection="column" rowGap={2} sx={{ cursor: "pointer", opacity: !isActive ? 0.4 : 1 }} onClick={handleClick}>
			<Divider sx={{ border: "4px solid", borderRadius: "30px", ...(isActive && { borderColor: (theme) => theme.palette.primary.primaryPink }) }} />
			<Box>
				<Typography component="div" variant="Text/sm/Semibold">
					{title}
				</Typography>
				<Typography component="div" variant="Text/sm/Regular">
					{subTitle}
				</Typography>
			</Box>
		</Box>
	);
}

export default Sections