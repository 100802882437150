import React from "react";
import { NumericFormat } from "react-number-format";
import { TextField } from "@mui/material";

export const Integer = ({ questionId, question, value, disabled = false, type, onChange, handleAnswer }) => {
	const { id, label } = question;

	const handleInputChange = e => {
		onChange((e.floatValue || 0).toString());
		handleAnswer(questionId, id, (e.floatValue || 0).toString());
	};

	return (
		<NumericFormat
			disabled={disabled}
			fullWidth
			placeholder={label ? label : "Number"}
			value={Number(value)}
			customInput={TextField}
			type="text"
			thousandSeparator={type === "CURRENCY" ? "," : ""}
			onValueChange={handleInputChange}
		/>
	);
};
