import moment from "moment";

export const createDayTimesMap = (availabilities, timezone, availTimezone) => {
	const dayTimesMap = {
		1: [],
		2: [],
		3: [],
		4: [],
		5: [],
		6: [],
		7: [],
	};
	for (const availability of availabilities) {
		const day = availability?.day;
		let start = availability?.startTime?.substring(0, 5);
		let end = availability?.endTime?.substring(0, 5);

		const utcOffAvail = moment().tz(availTimezone).utcOffset();

		const utcOffCurrent = moment().tz(timezone).utcOffset();

		let start2 = null;
		let end2 = null;
		if (timezone) {
			start = moment(availability?.startTime.substring(0, 5), "HH:mm")
				.subtract(utcOffAvail - utcOffCurrent, "minutes")
				.format("HH:mm");

			end = moment(availability?.endTime?.substring(0, 5), "HH:mm")
				.subtract(utcOffAvail - utcOffCurrent, "minutes")
				.format("HH:mm");

			if (moment(end, "HH:mm").isBefore(moment(start, "HH:mm"))) {
				const temp = end;
				end = "24:00";
				start2 = "00:00";
				end2 = temp;
			}
		}
		if (start2 && end2) {
			if (day != 1) {
				dayTimesMap[day - 1] = [
					...dayTimesMap[day - 1],
					{
						startTime: start,
						endTime: end,
					},
				];
				dayTimesMap[day] = [
					...dayTimesMap[day],
					{
						startTime: start2,
						endTime: end2,
					},
				];
			} else {
				dayTimesMap[7] = [
					...dayTimesMap[7],
					{
						startTime: start,
						endTime: end,
					},
				];
				dayTimesMap[day] = [
					...dayTimesMap[day],
					{
						startTime: start2,
						endTime: end2,
					},
				];
			}
		} else {
			dayTimesMap[day] = [
				...(dayTimesMap && dayTimesMap[day]),
				{
					startTime: start,
					endTime: end,
				},
			];
		}
	}

	return dayTimesMap;
};

export const createTimesSlotMap = (availabilities, timezone, availTimezone) => {
	const dayTimesMap = {
		1: [],
		2: [],
		3: [],
		4: [],
		5: [],
		6: [],
		7: [],
	};
	for (const availability of availabilities) {
		const day = availability?.day;
		let start = availability?.startTime?.substring(0, 5);
		let end = availability?.endTime?.substring(0, 5);

		const utcOffAvail = moment().tz(availTimezone).utcOffset();

		const utcOffCurrent = moment().tz(timezone).utcOffset();

		let start2 = null;
		let end2 = null;
		if (timezone) {
			start = moment(availability?.startTime?.substring(0, 5), "HH:mm")
				.subtract(utcOffAvail - utcOffCurrent, "minutes")
				.format("HH:mm");

			end = moment(availability?.endTime?.substring(0, 5), "HH:mm")
				.subtract(utcOffAvail - utcOffCurrent, "minutes")
				.format("HH:mm");

			if (moment(end, "HH:mm").isBefore(moment(start, "HH:mm"))) {
				const temp = end;
				end = "24:00";
				start2 = "00:00";
				end2 = temp;
			}
		}
		if (start2 && end2) {
			if (day != 1) {
				dayTimesMap[day - 1] = [
					...dayTimesMap[day - 1],
					{
						startTime: start,
						endTime: end,
					},
				];
				dayTimesMap[day] = [
					...dayTimesMap[day],
					{
						startTime: start2,
						endTime: end2,
					},
				];
			} else {
				dayTimesMap[7] = [
					...dayTimesMap[7],
					{
						startTime: start,
						endTime: end,
					},
				];
				dayTimesMap[day] = [
					...dayTimesMap[day],
					{
						startTime: start2,
						endTime: end2,
					},
				];
			}
		} else {
			dayTimesMap[day] = [
				...dayTimesMap[day],
				{
					startTime: start,
					endTime: end,
				},
			];
		}
	}

	return dayTimesMap;
};

function convertToUTC(isoDate, time, timezone) {
	// Ensure time is in 'HH:mm' format
	time = time.split(":").slice(0, 2).join(":");
	// Combine the date and time strings
	const localDateTimeStr = isoDate.split("T")[0] + "T" + time + ":00";
	// Convert to UTC
	const utcDateTime = moment.tz(localDateTimeStr, timezone).utc();
	// Format as an ISO string and return
	return utcDateTime.format();
}

function generateMonthAvailability(month, timezone, availability) {
	const daysArray = [];
	const startDate = new Date(moment().year(), month, 1);
	const endDate = new Date(moment().year(), month + 1, 0);

	for (let date = startDate; date <= endDate; date.setDate(date.getDate() + 1)) {
		const isoDate = date.toISOString();
		const dayOfWeek = date.toLocaleString("en-US", { weekday: "long" });
		const daysID = date.getDay();

		daysArray.push({
			date: isoDate,
			dayOfWeek: dayOfWeek,
			daysID: daysID === 0 ? 7 : daysID,
		});
	}

	for (const day of daysArray) {
		const matchingEvents = availability?.filter((event) => (event.day.daysID === 0 ? 7 : day.daysID));

		day.availability = matchingEvents.map((event) => {
			return {
				startTime: convertToUTC(day.date, event.startTime, timezone),
				endTime: convertToUTC(day.date, event.endTime, timezone),
			};
		});
	}

	return daysArray;
}

function mergeIntervals(arr) {
	// Sort the intervals based on start times
	arr.sort((a, b) => a.startTime - b.startTime);

	let merged = [];
	let currentInterval = arr[0];

	for (let i = 1; i < arr.length; i++) {
		if (currentInterval.endTime >= arr[i].startTime) {
			currentInterval.endTime = Math.max(currentInterval.endTime, arr[i].endTime);
		} else {
			merged.push(currentInterval);
			currentInterval = arr[i];
		}
	}
	merged.push(currentInterval);

	return merged;
}

function removeOverlappingAvailabilities(day, busyArray) {
	const busyIntervals = mergeIntervals(
		busyArray.map((interval) => ({
			startTime: moment.utc(interval.start),
			endTime: moment.utc(interval.end),
		}))
	);

	let newAvailability = [];
	for (const availability of day.availability) {
		const availabilityStart = moment.utc(availability.startTime);
		const availabilityEnd = moment.utc(availability.endTime);

		let segments = [{ start: moment.utc(availabilityStart), end: moment.utc(availabilityEnd) }];
		for (const busy of busyIntervals) {
			let temp = [];
			for (const segment of segments) {
				if (segment.start.isBefore(busy.endTime) && segment.end.isAfter(busy.startTime)) {
					if (segment.start.isBefore(busy.startTime)) {
						temp.push({ start: moment.utc(segment.start), end: moment.utc(busy.startTime) });
					}
					if (segment.end.isAfter(busy.endTime)) {
						temp.push({ start: moment.utc(busy.endTime), end: moment.utc(segment.end) });
					}
				} else {
					temp.push({ start: moment.utc(segment.start), end: moment.utc(segment.end) });
				}
			}
			segments = temp;
		}

		newAvailability = newAvailability.concat(
			segments.map((segment) => ({
				startTime: segment.start.toISOString(),
				endTime: segment.end.toISOString(),
			}))
		);
	}

	day.availability = newAvailability;

	return day;
}

function removeOverlapsFromDays(days, busyArray) {
	const daysMap = days.map((day) => removeOverlappingAvailabilities(day, busyArray));
	return daysMap;
}

function transformAvailability(data, timeZone) {
	let result = {};

	data.forEach((item) => {
		if (item.availability.length === 0) return; // skip if availability is empty

		item.availability.forEach((timeSlot) => {
			let startTime = moment(timeSlot.startTime);
			let endTime = moment(timeSlot.endTime);

			let startDate = startTime.tz(timeZone).format("YYYY-MM-DD");
			let endDate = endTime.tz(timeZone).format("YYYY-MM-DD");

			let startHourMinute = startTime.tz(timeZone).format("HH:mm");
			let endHourMinute = endTime.tz(timeZone).format("HH:mm");

			// if the start date and end date are the same
			if (startDate === endDate) {
				if (!result[startDate]) result[startDate] = [];
				result[startDate].push({ startTime: startHourMinute, endTime: endHourMinute });
			} else {
				// handle the case where the time slot spans across two days
				if (!result[startDate]) result[startDate] = [];
				if (!result[endDate]) result[endDate] = [];

				result[startDate].push({ startTime: startHourMinute, endTime: "24:00" });
				result[endDate].push({ startTime: "00:00", endTime: endHourMinute });
			}
		});
	});

	return result;
}

function transformObjectToArray(obj) {
	const daysOfWeek = Object.keys(obj);
	const availability = [];

	for (const day of daysOfWeek) {
		const slots = obj[day];

		for (const slot of slots) {
			const startTime = slot.startTime;
			const endTime = slot.endTime;

			availability.push({
				day: Number(day),
				startTime: startTime,
				endTime: endTime,
			});
		}
	}

	return availability;
}

export const createBusyTimeMap = ({ month, availability, busyTimes, myTimeZone, guestTimeZone }) => {
	const monthAvailability = generateMonthAvailability(month, myTimeZone, transformObjectToArray(availability));
	const newDaysArray = busyTimes && busyTimes?.length > 0 ? removeOverlapsFromDays(monthAvailability, busyTimes) : monthAvailability;
	const transformedData = transformAvailability(newDaysArray, myTimeZone);
	return transformedData;
};
