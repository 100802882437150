import React, { Component } from "react";
import { Grid, Box, Stack } from "@mui/material";
import { connect } from "react-redux";
import "./createAccount.css";
import ROUTES_PATH from "../../constants/routes";
import { REGEX } from "../../constants/regex";
import { getUserEmail, saveUserEmail, saveUserId, saveVerificationType, getEmailToken, setPrimaryAccountId } from "../../utils/sessionStorage";
import { manualLogin } from "../../utils/auth0";
import services from "../../services";
import { toastContainer } from "../../utils/toast";
import messages from "../../constants/messages";
import * as actions from "../../modules/actions";
import { CreateAccount as CreateAccountContainer } from "../../components/Onboarding/CreateAccount/index";
import { LOGOICON } from "../../constants/image";
import { USER_TYPES } from "../../utils/userTypes";
import moment from "moment";
import { getCompanyLinkedInUrl, getRoleValue, getUserLinkedInUrl } from "../../utils/commonFunctions";
import { handleMixPanel, handleMixPanelSetUser } from "../../utils/mixPanelEventHandling";

class CreateAccount extends Component {
	constructor(props) {
		super(props);

		this.state = {
			disabled: true,
			email: "",
			pasword: "",
			validationErrorArray: [true, true, true, true, true],
			emailError: false,
			signupLoading: false,
		};
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		this.setState({ email: getUserEmail() });
	}
	ValidateEmail(value) {
		if (REGEX.EMAIL.test(value)) {
			return true;
		}
		return false;
	}
	handleEmailInputChange = (event) => {
		this.setState({
			email: event,
		});
		if (this.ValidateEmail(event)) {
			this.setState({ emailError: false });
		} else {
			this.setState({ emailError: true });
		}
	};
	handlePasswordInputChange = (event) => {
		this.setState({
			password: event,
		});

		let validationErrorArray = [...this.state.validationErrorArray];
		if (event.length >= 8) {
			validationErrorArray[0] = false;
		} else {
			validationErrorArray[0] = true;
		}
		if (event.match(REGEX.LOWERCASELETTERS)) {
			validationErrorArray[1] = false;
		} else {
			validationErrorArray[1] = true;
		}
		if (event.match(REGEX.UPPERCASELETTERS)) {
			validationErrorArray[2] = false;
		} else {
			validationErrorArray[2] = true;
		}
		if (event.match(REGEX.NUMBERS)) {
			validationErrorArray[3] = false;
		} else {
			validationErrorArray[3] = true;
		}
		if (event.match(REGEX.SPECIAL_CHARACTERS)) {
			validationErrorArray[4] = false;
		} else {
			validationErrorArray[4] = true;
		}
		this.setState({ validationErrorArray });
	};
	getUserType = (roleType) => {
		if (roleType === "Founder") {
			return "STARTUP_OWNER";
		} else if (roleType === "Mentor") {
			return "MENTOR_USER";
		} else if (roleType === "Advisor") {
			return "ADVISOR_USER";
		} else if (roleType === "Teammate") {
			return "STARTUP_USER";
		} else if (roleType === "Investor") {
			return "INVESTOR_USER";
		} else if (roleType === "Partner") {
			return "PARTNER_USER";
		} else if (roleType === "Student") {
			return "STUDENT_USER";
		} else if (roleType === "Instructor") {
			return "PROFESSOR_USER";
		} else {
			const decodedPayload = this.props.auth.decodedPayload;
			if (decodedPayload && decodedPayload.type) {
				if (decodedPayload.type === "CANDIDATE_USER") {
					return "CANDIDATE_USER";
				}
			}
		}
	};
	handleContinue = async () => {
		const { email, password } = this.state;
		const onboardingData = this.getOnboardingData();

		let onboardingPayload = {
			companyName: onboardingData.startupName,
			companyType: null,
			currentCommitment: onboardingData.currentCommitment,
			experienceDetails: "",
			founderSuperpower: onboardingData?.yourSuperPower?.join(),
			superpower: onboardingData?.yourSuperPower?.join(),
			journeyDetails: "",
			expertise: onboardingData?.yourExpertise?.join(),
			whyStartupOs: onboardingData?.whatBringHere?.join(),
			fundingDetails: onboardingData.receivedAnyFunding,
			interests: onboardingData.startupInterest?.join(),
			industryFocus: onboardingData.industryFocus?.join(),
			group: onboardingData.areYouAffiliate,
			state: onboardingData.state,
			city: onboardingData.city,
			zipcode: onboardingData.zipCode,
			firstName: onboardingData.firstName,
			lastName: onboardingData.lastName,
			linkedInUrl: getUserLinkedInUrl(onboardingData.linkedInProfile),

			incorporatedStatus: onboardingData.incorporatedStatus,
			foundedDate: onboardingData.foundedDate,
			websiteLink: onboardingData.startupWebsite ? "https://" + onboardingData.startupWebsite : "",
			investorType: onboardingData.investorType,
			investments: onboardingData.totalDeals,
			investmentRange: onboardingData.investmentRange,
			firstThingInStartup: onboardingData.firstThingInStartup,
			skillsAndExpertise: onboardingData.skillsAndExpertise?.join(),
			linkedInCompanyUrl: getCompanyLinkedInUrl(onboardingData.companyLinkedInUrl),
		};
		let emailVerified = false;

		const authData = this.props.auth;
		let decodedObject;
		if (authData && !this.isEmpty(authData?.decodedPayload)) {
			decodedObject = authData?.decodedPayload;
			if (email === decodedObject.email) {
				emailVerified = true;
			}
		}
		if (this.isEmpty(onboardingData) && this.isEmpty(authData?.decodedPayload)) {
			toastContainer(messages.COMPLETE_ONBOARDING_WARNING, "error");

			setTimeout(() => {
				this.props.history.push(ROUTES_PATH.ONBOARDING_BASEPATH);
			}, 1000);
		} else {
			this.setState({ signupLoading: true });

			let date = new Date();
			let payload = {};

			const onboardingData = this.getOnboardingData();

			payload = {
				email: this.state.email,
				token: null,
				onboardingDetails: onboardingPayload,
				emailVerified,
				userType: this.getUserType(onboardingData.role),
				emailToken: getEmailToken(),
				password: this.state.password,
				timeZone: moment.tz.guess(),
			};

			services
				.userSignup(payload)
				.then((res) => {
					const result = res?.data?.data;
					let userDetails = {
						id: result?.id,
						name: result?.name,
						$email: result?.email,
						email: result?.email,

						userType: result?.userType,
						phone: result?.phone,
						$avatar: result?.picture,
						powerUpsCompleted: 0,
						companyName: result?.companyModel?.companyName,
						companyRole: result?.profession,
						accountCreatedDate: date.toISOString(),
					};
					handleMixPanelSetUser(userDetails);

					handleMixPanel(`Signup as ${getRoleValue(result?.userType)}`, userDetails);

					this.props.clearOnboardingData();
					saveUserId(res?.data?.data?.id);
					setPrimaryAccountId(res?.data?.data?.primaryAccountId);

					this.setState({ signupLoading: false });
					this.props.setAuthData({ decodedPayload: {} });
					saveUserEmail(email);
					saveVerificationType("signUp");
					if (result.emailVerified) {
						if (payload.userType === USER_TYPES.STARTUP_USER) {
							try {
								manualLogin({
									email: this.state.email,
									password: this.state.password,
								});
							} catch (err) {
								toastContainer(err?.error_description, "error");

								console.log(err?.error_description);
							}
						} else {
							this.props.history.push(ROUTES_PATH.LOG_IN);
						}
					} else {
						this.props.history.push(ROUTES_PATH.EMAIL_VERIFICATION);
					}
				})
				.catch((err) => {
					this.setState({ signupLoading: false });
					const errData = err.response?.data;
					if (errData?.code == "409") {
						toastContainer(errData.message, "error");
					} else {
						toastContainer(err?.error_description, "error");
					}
				});
		}
	};

	getOnboardingData = () => {
		const onboarding = this.props.onboarding;

		//Setup your onboarding details object here for sending onboarding info to backend
		const onboardingDetails = onboarding;

		return onboardingDetails;
	};

	isEmpty = (o) => Object.entries(o).every(([k, v]) => v === "");

	render() {
		let { email, password, validationErrorArray, emailError } = this.state;
		return (
			<>
				<Box
					sx={{
						display: "flex",
						width: 1,
						justifyContent: "center",
						alignItems: "center",
						mt: 3,
						cursor: "pointer",
					}}
					onClick={() => (window.location.href = process.env.REACT_APP_HOME_URL)}
				>
					<Box component="img" src={LOGOICON} />
				</Box>
				<Stack alignItems="center" justifyContent="center" sx={{ minHeight: "100%" }}>
					<Grid className="signup-grid-container" sx={{ mt: 9 }} container alignItems="center" justifyContent="center">
						<Grid item xs={12} sm={10} md={6} lg={6} className="form-container">
							<CreateAccountContainer
								email={email}
								emailError={emailError}
								password={password}
								handleEmailInputChange={this.handleEmailInputChange}
								handlePasswordInputChange={this.handlePasswordInputChange}
								validationErrorArray={validationErrorArray}
								handleContinue={this.handleContinue}
								signupLoading={this.state.signupLoading}
							/>
						</Grid>

						{/*
          <Grid item xs={12} sm={10} md={6} lg={6} className="form-container">
            <div className="signup-title">Create Your Account</div>
            <TextInput
              type="text"
              label="Email address"
              value={email}
              error={emailError ? "Please enter valid email address" : ""}
              onChange={e => this.handleEmailInputChange(e)}
            />
            <TextInput
              type="password"
              label="Password"
              value={password}
              onChange={e => this.handlePasswordInputChange(e)}
            />
            <PasswordValidation validationErrorArray={validationErrorArray} />
            <Grid item>
              <Box textAlign="center">
                <LoadingButton
                  sx={{ mt: 3, maxWidth: "100%" }}
                  disabled={
                    !email || emailError || validationErrorArray.includes(true)
                  }
                  onClick={e => this.handleContinue(e)}
                  loading={this.state.signupLoading}
                >
                  Continue
                </LoadingButton>
              </Box>
            </Grid>
            <Box sx={{ py: 2 }} className="signup-sub-text align-center">
              Have an account? &nbsp;
              <span
                onClick={() => {
                  this.props.history.push(ROUTES_PATH.LOG_IN);
                }}
              >
                Log in.
              </span>
            </Box>
          </Grid>*/}
					</Grid>
				</Stack>
			</>
		);
	}
}

const mapStoreToProps = ({ auth, onboarding }) => ({
	onboarding: onboarding,
	auth: auth,
});

const mapDispatchToProps = (dispatch) => ({
	clearOnboardingData: () => dispatch(actions.clearOnboardingData()),
	setAuthData: (data) => dispatch(actions.setAuthData(data)),
});

export default connect(mapStoreToProps, mapDispatchToProps)(CreateAccount);
