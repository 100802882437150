import React from "react";
import { Box, Typography, Divider } from "@mui/material";
import TodayIcon from "@mui/icons-material/Today";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import VideoCameraFrontIcon from "@mui/icons-material/VideoCameraFront";
import NotesIcon from "@mui/icons-material/Notes";
import moment from "moment";
import JsxParser from "react-jsx-parser";

const EventDetails = ({
  sessionCycle,
  eventDetails,
  pluginName = "Advisor Plugin",
  userType,
  inviteStatus = false,
  inviteDate,
  inviteTime,
}) => {
  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Box
          sx={theme => ({
            border: `1px solid ${theme.palette.secondary.dark}`,
            height: "max-content",
            borderRadius: theme.shape.standard4.borderRadius,
          })}
        >
          {userType == "external" ? (
            <Box sx={{ px: 1.5 }}>
              <Box>
                <Typography variant="onsurface_subtitle">
                  {moment(inviteDate).format("MMM")}
                </Typography>
              </Box>
              <Box sx={{ justifyContent: "center", display: "flex" }}>
                <Typography variant="title_small">
                  {moment(inviteDate).format("DD")}
                </Typography>
              </Box>
            </Box>
          ) : (
            <Box sx={{ p: 1 }}>
              <TodayIcon color="primary" />
            </Box>
          )}
        </Box>
        <Box
          sx={{
            ml: 2,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: 1,
          }}
        >
          <Box>
            <Typography variant="headline6">
              {userType == "external" ? pluginName : pluginName + `Session`}
            </Typography>
          </Box>
          {sessionCycle && (
            <Box>
              <Typography variant="subhead1">{sessionCycle}</Typography>
            </Box>
          )}
        </Box>
      </Box>
      <Divider sx={{ mt: { xs: 1, md: 3 }, borderColor: "secondary.light" }} />
      <Box
        sx={{
          mt: { xs: 2, md: 4 },
          color: theme => theme.palette.text.secondary,
        }}
      >
        <Box display={"flex"} alignItems="center">
          <AccessTimeIcon
            sx={{ mr: 3, color: theme => theme.palette.secondary.dark }}
          />

          {eventDetails?.responseDetails || eventDetails?.timings ? (
            <Typography variant="body_small">
              {!inviteStatus ? (
                "Invitation sent & pending Advisor confirmation"
              ) : inviteDate && inviteTime ? (
                <>
                  <Box>{inviteDate}</Box>
                  <Box>{inviteTime}</Box>
                </>
              ) : (
                "New time proposals sent & pending Startup confirmation"
              )}
            </Typography>
          ) : null}
        </Box>

        <Box display={"flex"} alignItems="center" sx={{ mt: 3 }}>
          <VideoCameraFrontIcon
            sx={{ mr: 3, color: theme => theme.palette.secondary.dark }}
          />
          <Typography variant="body_small">
            {userType == "external"
              ? "Startup to provide link"
              : "Advisor to provide link"}
          </Typography>
        </Box>

        <Box display={"flex"} sx={{ mt: 3 }}>
          <NotesIcon
            sx={{ mr: 3, color: theme => theme.palette.secondary.dark }}
          />
          <Box sx={{ display: "block" }}>
            <Typography variant="body_small">Agenda</Typography>
            <Box sx={{ mt: 2 }}>
              <Typography variant="body_small">
                <JsxParser
                  components={{ Typography }}
                  jsx={eventDetails?.agenda}
                />
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default EventDetails;
