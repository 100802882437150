import { Box, Typography } from "@mui/material";
import React from "react";
import InfiniteAnimation from "./InfiniteAnimation";

function ReviewLoading({ message }) {
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				gap: 4,
				alignItems: "center",
			}}
		>
			<Typography variant={"Display/xs/Semibold"} component={"p"} sx={{ mb: 1 }}>
				{message ? message : "Bolt AI is reviewing your profile. Please wait."}
			</Typography>

			<InfiniteAnimation />
		</Box>
	);
}

export default ReviewLoading;
