import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Avatar, Box, Button, CircularProgress, TableCell, Tooltip, Typography } from "@mui/material";
import { ARROW_UP_RIGHT_ICON_V1, BUILDING_ICON_V3, CUBE_ICON_V2, CURRENECY_ICON_V2, PRODUCT_STATE_ICON_V2 } from "../../../constants";
import { handleMixPanel } from "../../../utils/mixPanelEventHandling";
import { USER_TYPES } from "../../../utils/userTypes";
import { StyledTableCell, StyledTableHead, StyledTableRow } from "./style";
import InfiniteScroll from "react-infinite-scroll-component";
import { setInvestorDashboardData } from "../../../modules/actions";

export default function CommunityTable({
	rows = [],
	onReqProxyInvestorConnectClick = () => {},
	redirectToMatched = () => {},
	currentTab,
	paginationLoader = false,
	setShowPlanModal,
	networkMatches,
	handleLoadMore,
	isLast,
	loading,
	currentPage,
	viewType,
}) {
	const userData = useSelector((store) => ({ userId: store?.auth?.userId, userEmail: store?.auth?.email }));
	const dispatch = useDispatch();
	const { isPremiumPlanAccess, isAdmin } = useSelector((store) => store?.auth);
	const getSelectedIndustryFormatted = (input) => {
		if (input) {
			try {
				let inputParsed = JSON.parse(input);
				if (Array.isArray(inputParsed)) {
					return inputParsed;
				} else {
					return [inputParsed];
				}
			} catch (error) {
				return [];
			}
		} else {
			return [];
		}
	};

	const ProfileCell = ({ row }) => {
		const firstName = row?.name?.split("")?.[0]?.capitalize()?.charAt(0);
		const lastName = row?.name?.split("")?.[1]?.capitalize()?.charAt(0);
		return (
			<Box
				sx={{ cursor: "pointer" }}
				display={"flex"}
				alignItems={"center"}
				gap={1}
				onClick={() => {
					row.userTypes[0] === USER_TYPES.INVESTOR_USER && redirectToMatched(currentTab, row?.id, row);
				}}
			>
				<Box sx={{ cursor: "pointer" }}>
					{row?.picture || row?.imageUrl ? (
						<Box component={"img"} sx={{ height: "64px", width: "64px", borderRadius: "50%" }} src={row?.picture || row?.imageUrl} alt="startup" />
					) : (
						<Avatar sx={{ height: "64px", width: "64px" }}>{`${firstName ?? ""}${lastName ?? ""}`}</Avatar>
					)}
				</Box>{" "}
				<Typography sx={{ textOverflow: "ellipsis", overflow: "hidden", textWrap: "nowrap", maxWidth: "180px" }} variant="Text/sm/Semibold">
					{row?.name}
				</Typography>
				<Box component={"img"} sx={{ height: "25px", width: "25px" }} src={ARROW_UP_RIGHT_ICON_V1} />
			</Box>
		);
	};

	const HeaderCellLabel = ({ label }) => {
		return (
			<Typography variant="Text/sm/Regular" sx={{ color: "rgba(102, 112, 133, 1)" }}>
				{label}
			</Typography>
		);
	};
	const openSectionDetails = (title, row) => {
		dispatch(
			setInvestorDashboardData({
				openStartupObj: { ...row, title },
				industryDetailsOpen: true,
				title: title,
			})
		);
	};
	const CommunityRow = ({ idx, row }) => {
		const [sendingReq, setSendingReq] = useState(false);
		const formattedProductOffering = getSelectedIndustryFormatted(row?.productOffering);
		const formattedIndustries = getSelectedIndustryFormatted(row?.industries);
		const formattedInvestmentStage = getSelectedIndustryFormatted(row?.preferredInvestmentStage);

		return (
			<TableRow key={idx}>
				<StyledTableCell component="th" scope="row" sx={{ borderRadius: "16px 0px 0px 16px", py: 1 }}>
					<ProfileCell row={row} />
				</StyledTableCell>
				<StyledTableCell component="th" scope="row" sx={{ py: 1 }} onClick={() => openSectionDetails("Product Offering", row)}>
					<Typography
						variant="Text/sm/Regular"
						display={"block"}
						sx={{ maxWidth: "200px", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", cursor: "pointer" }}
					>
						{formattedProductOffering?.length > 0 ? formattedProductOffering?.join(", ") : "No Data"}
					</Typography>
				</StyledTableCell>
				<StyledTableCell component="th" scope="row" sx={{ py: 1 }} onClick={() => openSectionDetails("Industry Types", row)}>
					<Typography
						variant="Text/sm/Regular"
						display={"block"}
						sx={{ maxWidth: "100px", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", cursor: "pointer" }}
					>
						{formattedIndustries?.length > 0 ? formattedIndustries?.join(", ") : "No Data"}{" "}
					</Typography>
				</StyledTableCell>
				<StyledTableCell component="th" scope="row" sx={{ py: 1 }} onClick={() => openSectionDetails("Investment Stages", row)}>
					<Typography
						variant="Text/sm/Regular"
						display={"block"}
						sx={{ maxWidth: "200px", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", cursor: "pointer" }}
					>
						{formattedInvestmentStage?.length > 0 ? formattedInvestmentStage?.join(", ") : "No Data"}{" "}
					</Typography>
				</StyledTableCell>
				<StyledTableCell component="th" scope="row" sx={{ py: 1 }}>
					<Box display={"flex"} direction={"row"} gap={1} alignItems={"center"}>
						{row?.investmentRangeLow && row?.investmentRangeHigh ? (
							<>
								<Box component={"img"} src={CURRENECY_ICON_V2} />
								<Typography
									variant="Text/sm/Regular"
									sx={{ maxWidth: "200px", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}
								>
									{" "}
									{`$${
										Intl.NumberFormat("en-US", {
											notation: "compact",
											maximumFractionDigits: 1,
										}).format(row?.investmentRangeLow) || 0
									} - $${
										Intl.NumberFormat("en-US", {
											notation: "compact",
											maximumFractionDigits: 1,
										}).format(row?.investmentRangeHigh) || 0
									}`}
								</Typography>
							</>
						) : (
							<Typography
								variant="Text/sm/Regular"
								sx={{ maxWidth: "200px", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}
							>
								No Data
							</Typography>
						)}
					</Box>
				</StyledTableCell>{" "}
				<StyledTableCell component="th" scope="row" sx={{ borderRadius: "0px 16px 16px 0px", py: 1 }}>
					{row.connectionStatus === "REQUEST_SENT" ? (
						<Box>
							<Button
								variant="GreyRounded"
								sx={{
									borderRadius: "100px",
									boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
								}}
							>
								<Typography variant="Text/xs/Semibold" color="inherit">
									Request Sent
								</Typography>
							</Button>
						</Box>
					) : (
						<Box>
							<Button
								variant="GreyRounded"
								startIcon={sendingReq && <CircularProgress sx={{ color: "inherit" }} size={"1.25rem"} />}
								sx={{ borderRadius: "100px" }}
								onClick={async () => {
									if (isPremiumPlanAccess || isAdmin) {
										handleMixPanel(`Community: Investor Request Connection clicked`, {
											userName: userData.userName,
											userId: userData.userId,
										});
										setSendingReq(true);
										await onReqProxyInvestorConnectClick(row);
										setSendingReq(false);
									} else {
										setShowPlanModal(true);
									}
								}}
							>
								<Typography variant="Text/xs/Semibold" color="inherit">
									Request Connection
								</Typography>
							</Button>
						</Box>
					)}
				</StyledTableCell>
			</TableRow>
		);
	};

	return (
		<Box sx={{ height: "calc(100vh - 280px)", overflowX: "hidden", overflowY: "hidden", pb: "50px" }}>
			<Box>
				<TableContainer component={Paper} elevation={0} className="SOSShowHorizontalScroll" sx={{ overflowY: "hidden", mb: 4 }}>
					<Table
						sx={{
							minWidth: 700,
							borderCollapse: "separate",
							borderSpacing: "0px 10px",
							backgroundColor: "#F5F9FA",
							overflow: "auto",
						}}
					>
						<StyledTableHead>
							<StyledTableRow>
								<StyledTableCell
									sx={{
										borderRadius: "16px 0px 0px 16px",
										minWidth: 300,
										borderLeft: "1px solid rgba(208, 213, 221, 1)",
									}}
								>
									<HeaderCellLabel label={"Name"} />
								</StyledTableCell>
								<StyledTableCell sx={{ minWidth: 200 }}>
									<HeaderCellLabel label={"Product Offering"} />
								</StyledTableCell>
								<StyledTableCell sx={{ minWidth: 250 }}>
									<HeaderCellLabel label={"Industry"} />
								</StyledTableCell>
								<StyledTableCell sx={{ minWidth: 200 }}>
									<HeaderCellLabel label={"Stage Investment"} />
								</StyledTableCell>
								<StyledTableCell sx={{ minWidth: 150 }}>
									<HeaderCellLabel label={"Investment Range"} />
								</StyledTableCell>
								<StyledTableCell
									sx={{ minWidth: 250, borderRadius: "0px 16px 16px 0px", borderRight: "1px solid rgba(208, 213, 221, 1)" }}
								></StyledTableCell>
							</StyledTableRow>
						</StyledTableHead>
					</Table>

					<InfiniteScroll
						dataLength={networkMatches?.length || 0}
						next={handleLoadMore}
						hasMore={!isLast && !loading}
						height={"calc(100vh - 280px)"}
						style={{
							overflowX: "hidden",
							display: "flex",
							flexDirection: "column",
							gap: "24px",
							maxWidth: "100%",
							minWidth: "fit-content",
						}}
						loader={null}
						endMessage={
							!loading &&
							isLast && (
								<Box sx={{ display: "flex", justifyContent: "center", mb: 3 }}>
									<Typography variant="Text/sm/Regular">No more records</Typography>
								</Box>
							)
						}
					>
						<Table
							stickyHeader={true}
							sx={{
								minWidth: 700,
								borderCollapse: "separate",
								borderSpacing: "0px 10px",
								backgroundColor: "#F5F9FA",
								overflow: "auto",
							}}
							aria-label="customized table"
						>
							{" "}
							<StyledTableHead sx={{ visibility: "collapse" }}>
								<StyledTableRow>
									<StyledTableCell
										sx={{
											borderRadius: "16px 0px 0px 16px",
											minWidth: 300,
											borderLeft: "1px solid rgba(208, 213, 221, 1)",
										}}
									>
										<HeaderCellLabel label={"Name"} />
									</StyledTableCell>
									<StyledTableCell sx={{ minWidth: 200 }}>
										<HeaderCellLabel label={"Product Offering"} />
									</StyledTableCell>
									<StyledTableCell sx={{ minWidth: 250 }}>
										<HeaderCellLabel label={"Industry"} />
									</StyledTableCell>
									<StyledTableCell sx={{ minWidth: 200 }}>
										<HeaderCellLabel label={"Stage Investment"} />
									</StyledTableCell>
									<StyledTableCell sx={{ minWidth: 150 }}>
										<HeaderCellLabel label={"Investment Range"} />
									</StyledTableCell>
									<StyledTableCell
										sx={{ minWidth: 250, borderRadius: "0px 16px 16px 0px", borderRight: "1px solid rgba(208, 213, 221, 1)" }}
									></StyledTableCell>
								</StyledTableRow>
							</StyledTableHead>
							<TableBody>
								{rows.map((row, idx) => (
									<CommunityRow row={row} idx={idx} />
								))}

								{paginationLoader && (
									<TableRow>
										<TableCell
											colSpan={5}
											sx={{
												"&:last-child": {
													borderBottom: "none",
												},
											}}
										>
											<Box display={"flex"} justifyContent="center" sx={{ my: "4px" }}>
												<CircularProgress
													sx={{ height: "10px", width: "10px", color: (theme) => theme.palette.primary.primaryPurple }}
												/>
											</Box>
										</TableCell>
									</TableRow>
								)}
							</TableBody>
						</Table>
					</InfiniteScroll>
				</TableContainer>
			</Box>
			{loading && currentPage !== 1 && viewType === "GRID" && (
				<Box display={"flex"} justifyContent="center" sx={{ my: "4px" }}>
					<CircularProgress sx={{ height: "10px", width: "10px", color: (theme) => theme.palette.primary.primaryPurple }} />
				</Box>
			)}
		</Box>
	);
}
