import React, { useContext, useEffect } from "react";
import { Card, Card as PowerUpCard } from "./../../components/cards/Card";
import { PowerUPContext } from "../../PowerUPController";
import JsxParser from "react-jsx-parser";
import { Box, CardMedia, Typography, Link, Button } from "@mui/material";
import TabBar from "../../components/buttons/TabBar";
import { CELL_SIGNAL_FULL, USERS_FOUR_ICON, LIGHTNING } from "../../../../constants/image";
import VideoPlayerComponent from "../../../../common/videoPlayer";

const KnowledgeDetailsTab = ({ details, team, difficulty }) => (
	<Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
		{details && (
			<Typography component={"p"} variant="Text/sm/Regular">
				{details}
			</Typography>
		)}
		{difficulty && (
			<Box sx={{ display: "flex", gap: "0.5rem" }}>
				<Box component={"img"} src={CELL_SIGNAL_FULL} sx={{ height: "2.25rem", width: "2.25rem" }} />
				<Box sx={{ display: "flex", flexDirection: "column" }}>
					<Typography variant="Text/sm/Regular">Difficulty</Typography>
					<Typography variant="Text/sm/Regular">{difficulty?.charAt(0)?.toUpperCase() + difficulty?.slice(1)?.toLowerCase()}</Typography>
				</Box>
			</Box>
		)}
		{team && (
			<Box sx={{ display: "flex", gap: "0.5rem" }}>
				<Box component={"img"} src={USERS_FOUR_ICON} sx={{ height: "2.25rem", width: "2.25rem" }} />
				<Box sx={{ display: "flex", flexDirection: "column" }}>
					<Typography variant="Text/sm/Regular">Team Recommended</Typography>
					<Typography variant="Text/sm/Regular">{team}</Typography>
				</Box>
			</Box>
		)}
	</Box>
);
const KnowledgeInspirationTab = ({ inspiration }) => {
	const [processedTab, setProcessedTab] = React.useState({});
	useEffect(() => {
		processTabData();
	}, [inspiration]);

	const processTabData = () => {
		try {
			let processed = JSON.parse(inspiration);
			setProcessedTab(processed);
		} catch (e) {
			console.log("Could not process inspiration.", e);
		}
	};

	return (
		<Box>
			<Box sx={{ mb: "1.25rem" }}>
				<Typography variant="Text/sm/Regular">Related Media</Typography>
			</Box>
			<Box sx={{ display: "flex", flexDirection: "column", gap: "1.25rem" }}>
				{processedTab &&
					processedTab.media &&
					processedTab.media.map((media, index) => (
						<Box key={index}>
							{media && media.type && media.type === "image" && (
								<Card
									sx={(theme) => ({
										borderRadius: theme.shape.standard5.borderRadius,
										boxShadow: "none",
									})}
								>
									<CardMedia component={"img"} image={media.content_url} />
								</Card>
							)}
							{media && media.type && media.type === "video" && (
								<Box
									sx={(theme) => ({
										borderRadius: theme.shape.standard5.borderRadius,
										boxShadow: "none",
										overflow: "hidden",
										"& > div": {
											display: "flex",
										},
									})}
								>
									<VideoPlayerComponent videoUrl={media.content_url} />
								</Box>
							)}
							{media && media.type && media.type === "text" && (
								<Box>
									<Typography
										component={"p"}
										sx={{
											"& *": {
												fontFamily: '"PoppinsRegular", "Helvetica", "Arial", sans-serif',
												fontSize: "1rem",
												fontStyle: "normal",
												fontWeight: 400,
												lineHeight: "1.5rem",
												color: "black",
											},
										}}
										variant="Text/sm/Regular"
									>
										<JsxParser components={{ Typography, Box, Link, Button }} jsx={media.html_content} />
									</Typography>
								</Box>
							)}
							<Box sx={{ my: 2 }}>
								<Typography component={"p"} variant="Text/sm/Semibold">
									{media.title}
								</Typography>
							</Box>
						</Box>
					))}
			</Box>
		</Box>
	);
};
const KnowledgeTipsTab = ({ tips }) => (
	<Box>
		<Typography
			component={"p"}
			variant="Text/md/Regular"
			sx={{
				"& *": {
					fontFamily: '"PoppinsRegular", "Helvetica", "Arial", sans-serif',
					fontSize: "1rem",
					fontStyle: "normal",
					fontWeight: 400,
					lineHeight: "1.5rem",
					color: "black",
				},
				"& ul": {
					padding: 0,
					my: ".37rem",
				},
				"& li": {
					listStyleType: "none",
					paddingLeft: "20px",
					background: `url(${LIGHTNING}) no-repeat`,
					paddingLeft: "30px",
					backgroundSize: "25px",
					marginBottom: "1.25rem",
				},
			}}
		>
			<JsxParser components={{ Typography, Box }} jsx={tips} />
		</Typography>
	</Box>
);

function KnowledgeCard() {
	const { powerupDetails, knowledgeActiveTab, setKnowledgeTab } = useContext(PowerUPContext);

	return (
		<PowerUpCard>
			<Typography variant="Text/md/Semibold" component={"p"}>
				Knowledge
			</Typography>
			<TabBar onSelect={(ind) => setKnowledgeTab(ind)} selectedTabIndex={knowledgeActiveTab} tabGroup={"knowledge"} details={powerupDetails} />
			{knowledgeActiveTab === 0 && (
				<KnowledgeDetailsTab
					details={powerupDetails?.knowledgeDetail}
					team={powerupDetails?.teamRecommended}
					difficulty={powerupDetails?.powerUpDifficulty}
				/>
			)}
			{knowledgeActiveTab === 1 && powerupDetails?.knowledgeTips && <KnowledgeTipsTab tips={powerupDetails?.knowledgeTips} />}
			{knowledgeActiveTab === 2 && powerupDetails?.knowledgeInspiration && <KnowledgeInspirationTab inspiration={powerupDetails?.knowledgeInspiration} />}
		</PowerUpCard>
	);
}

export default KnowledgeCard;
