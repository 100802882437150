import { Box, Button } from "@mui/material";
import React, { useState, useEffect } from "react";
import OnboardingCardSelector from "../../../../components/Onboarding/OnboardingCardSelector";
import PageTitle from "../../../../components/Onboarding/PageTitle";
import { YOUR_SUPERPOWER } from "../../../../constants/updated-onboarding";
import { useSelector } from "react-redux";

export default function YourSuperPower({ setComplete }) {
  const [userResponse, setUserResponse] = useState("");
  const savedData = useSelector(state => state?.onboarding?.yourSuperPower);
  useEffect(() => {
    if (savedData) {
      setUserResponse(savedData);
    }
  }, [savedData]);

  const callComplete = (complete, data) => {
    if (setComplete) {
      setComplete(complete, data);
    }
  };

  useEffect(() => {
    if (userResponse && userResponse.length) {
      callComplete(1, { yourSuperPower: userResponse });
    } else {
      callComplete(0, { yourSuperPower: [] });
    }
  }, [userResponse]);
  return (
    <Box>
      <PageTitle
        title={"What’s your superpower?"}
        description={"Select the strength that makes you stand out."}
      />
      <Box sx={{ my: 5 }}>
        <OnboardingCardSelector
          cardItems={YOUR_SUPERPOWER}
          flex={1 / 3}
          gridColWidth={7}
          setUserResponse={setUserResponse}
          userResponse={userResponse}
          savedData={savedData}
          multiSelect
        />
      </Box>
    </Box>
  );
}
