import { gql } from "@apollo/client";

export const GET_FOUNDER_DATA_QUERY = gql`
	query userById($id: ID) {
		userById(id: $id) {
			id
			uuid
			email
			phone
			partnerCode
			address1
			address2
			emailVerified
			invitationStatus
			primaryAccountId
			isTourCompleted
			companyName
			preferredInvestmentStage
			website
			preferredGeography
			name
			userSource
			picture
			userTypes
			title
			isVerified
			verificationStatus
			userBadges
			isHidden
			isSaved
			calUserId
			userProfilePercentage
			participantState
			admin
			userInfoPercent
			userAboutPercent
			experiencePercent
			educationPercent
			timezone
			dateOfBirth
			isCompanyUser
			idToken
			firstName
			lastName
			profession
			expertise
			superpower
			linkedInUrl
			about
			headline
			zipcode
			city
			state
			investmentRangeLow
			investmentRangeHigh
			country
			videoUrl
			investorType
			investmentRange
			goals
			experienceDetails {
				id
				createdAt
				title
				companyName
				location
				startMonth
				startYear
				endMonth
				endYear
				isCurrentCompany
			}
			companyModel {
				id
				uuid
				companyName
				logoUrl
				about
				isSaved
				founderDetails {
					id
					uuid
					name
					firstName
					lastName
					phone
					picture
					timezone
					headline
					expertise
					videoUrl
					about
					linkedInUrl
					profession
					city
					state
					zipcode
					industries
					superpower
				}
				tagLine
				entityStatus
				companyProfilePercentage
				revenue
				financing
				currentStage
				seeking
				ask
				industry
				city
				state
				currentScore
				companySize
				founderStory
				curretElevatorPitch
				businessModel
				revenueModel
				founderStoryVideo
				twitterUrl
				country
				address1
				videoUrl
				websiteLink
				pitchDeckLink
				startUpSize
				foundedYear
				investorView
				linkedInUrl
				noOfCoFounders
				headline
				productOffering
				targetMarket
				fundingType
				capitalRaised
				phone
				aboutUsVideoUrl
				pitchDeckVideoUrl
				pitchDeckView
				zipcode
				foundedDate
				fundingDetails
				whyStartupOs
				expertise
				productCategory
				mvpLaunched
				incubator
				companyType
				registraionState
				incorporationCountry
				isPremiumPlanAccess
				isInvestorReady
				incorporatedStatus
				incorporationState
				supportAreas
			}
			educationDetails {
				id
				course
				university
				location
				startYear
				endYear
				degreeType
				createdAt
			}
			industries
			productOffering
		}
	}
`;

export const GET_COMPANY_DATA_QUERY = gql`
	query companyById($id: ID) {
		companyById(id: $id) {
			id
			uuid
			companyName
			logoUrl
			about
			isSaved
			companySource
			enterpriseReadyJson
			founderDetails {
				id
				uuid
				name
				calUserId
				firstName
				lastName
				email
				phone
				picture
				timezone
				headline
				expertise
				videoUrl
				about
				linkedInUrl
				profession
				city
				state
				zipcode
				industries
				superpower
			}
			tagLine
			entityStatus
			companyProfilePercentage
			revenue
			financing
			currentStage
			seeking
			ask
			industry
			city
			state
			currentScore
			companySize
			founderStory
			curretElevatorPitch
			businessModel
			revenueModel
			founderStoryVideo
			twitterUrl
			country
			address1
			videoUrl
			websiteLink
			pitchDeckLink
			startUpSize
			foundedYear
			investorView
			linkedInUrl
			noOfCoFounders
			headline
			productOffering
			targetMarket
			fundingType
			capitalRaised
			phone
			aboutUsVideoUrl
			pitchDeckVideoUrl
			pitchDeckView
			zipcode
			foundedDate
			fundingDetails
			whyStartupOs
			expertise
			productCategory
			mvpLaunched
			incubator
			companyType
			registraionState
			incorporationCountry
			isPremiumPlanAccess
			isInvestorReady
			capTable
			pitchPresentation
			stateOfProduct
			monthlyRevenue
			burnRate
			runway
			growthRate
			startupScalable
			companyTags {
				id
				scoreTag {
					id
					name
				}
			}
			workingFullTime
			incorporatedStatus
			incorporationState
			supportAreas
		}
	}
`;
